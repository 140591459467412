import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 5 </title>
	<meta
          name="description"
          content="Zechariah 5 | சகரியா 5 | Sageria 5 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் திரும்பவும் என் கண்களை ஏறெடுத்துப் பார்க்கையில், இதோ, பறக்கிற ஒரு புஸ்தகச்சுருளைக் கண்டேன்.
	<br /><br />
	2. தூதன்: நீ காண்கிறது என்னவென்று கேட்டார்; பறக்கிற ஒரு புஸ்தகச்சுருளைக் காண்கிறேன், அதின் நீளம் இருபது முழமும் அதின் அகலம் பத்து முழமுமாயிருக்கிறது என்றேன்.
	<br /><br />
	3. அப்பொழுது அவர்: இது பூமியின்மீதெங்கும் புறப்பட்டுப்போகிற சாபம்; எந்தத் திருடனும் அதின் ஒரு புறத்திலிருக்கிறதின்படியே அழிக்கப்பட்டுப்போவான்; ஆணையிடுகிற எவனும், அதின் மறுபுறத்தில் இருக்கிறதின்படியே அழிக்கப்பட்டுப்போவான்.
	<br /><br />
	4. அது திருடன் வீட்டிலும், என் நாமத்தைக்கொண்டு பொய்யாணையிடுகிறவன் வீட்டிலும் வந்து, அவனவன் வீட்டின் நடுவிலே தங்கி, அதை அதின் மரங்களோடும் அதின் கல்லுகளோடுங்கூட நிர்மூலமாக்கும்படி அதைப் புறப்பட்டுப்போகப்பண்ணுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றார்.
	<br /><br />
	5. பின்பு என்னோடே பேசின தூதன் வெளியே வந்து என்னை நோக்கி: நீ உன் கண்களை ஏறெடுத்து, புறப்பட்டுவருகிறதை என்னவென்று பார் என்றார்.
	<br /><br />
	6. அது என்னவென்று கேட்டேன்; அதற்கு அவர்: அது புறப்பட்டுவருகிறதாகிய ஒரு மரக்கால் என்றார். பின்னும் அவர்: பூமியெங்கும் இதுதான் அவர்களுடைய கண்ணோக்கம் என்றார்.
	<br /><br />
	7. இதோ, ஒரு தாலந்து நிறையான ஈயமூடி தூக்கிவரப்பட்டது; மரக்காலின் நடுவிலே ஒரு ஸ்திரீ உட்கார்ந்திருந்தாள்.
	<br /><br />
	8. அப்பொழுது அவர்: இவள் அக்கிரமக்காரி என்று சொல்லி, அவளை மரக்காலுக்குள்ளே தள்ளி ஈயக்கட்டியை அதின் வாயிலே போட்டார்.
	<br /><br />
	9. அப்பொழுது நான் என் கண்களை ஏறெடுத்து, இதோ, புறப்பட்டுவருகிற இரண்டு ஸ்திரீகளைக் கண்டேன்; அவர்களுக்கு நாரையின் செட்டைகளுக்கொத்த செட்டைகள் இருந்தது; அவர்கள் செட்டைகளில் காற்றிருந்தது; இவர்கள் மரக்காலைப் பூமிக்கும் வானத்துக்கும் நடுவாய்த் தூக்கிக்கொண்டு போனார்கள்.
	<br /><br />
	10. நான் என்னோடே பேசின தூதனை நோக்கி: இவர்கள் மரக்காலை எங்கே கொண்டுபோகிறார்கள் என்று கேட்டேன்.
	<br /><br />
	11. அதற்கு அவர்: சிநெயார் தேசத்திலே அதற்கு ஒரு வீட்டைக் கட்டும்படிக்கு அதைக் கொண்டுபோகிறார்கள்; அங்கே அது ஸ்தாபிக்கப்பட்டு, தன் நிலையிலே வைக்கப்படும் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah5