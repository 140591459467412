import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 18 </title>
	<meta
          name="description"
          content="Luke 18 | லூக்கா 18 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சோர்ந்துபோகாமல் எப்பொழுதும் ஜெபம்பண்ணவேண்டும் என்பதைக் குறித்து அவர்களுக்கு அவர் ஒரு உவமையைச் சொன்னார்.
	<br /><br />
	2. ஒரு பட்டணத்திலே ஒரு நியாயாதிபதி இருந்தான்; அவன் தேவனுக்குப் பயப்படாதவனும் மனுஷரை மதியாதவனுமாயிருந்தான்.
	<br /><br />
	3. அந்தப் பட்டணத்திலே ஒரு விதவையும் இருந்தாள்; அவள் அவனிடத்தில் போய்: எனக்கும் என் எதிராளிக்கும் இருக்கிற காரியத்தில் எனக்கு நியாயஞ்செய்யவேண்டும் என்று விண்ணப்பம்பண்ணினாள்.
	<br /><br />
	4. வெகுநாள்வரைக்கும் அவனுக்கு மனதில்லாதிருந்தது. பின்பு அவன்: நான் தேவனுக்குப் பயப்படாமலும் மனுஷரை மதியாமலும் இருந்தும்,
	<br /><br />
	5. இந்த விதவை என்னை எப்பொழுதும் தொந்தரவு செய்கிறபடியினால், இவள் அடிக்கடி வந்து என்னை அலட்டாதபடி இவளுக்கு நியாயஞ்செய்யவேண்டும் என்று தனக்குள்ளே சொல்லிக்கொண்டான் என்றார்.
	<br /><br />
	6. பின்னும் கர்த்தர் அவர்களை நோக்கி: அநீதியுள்ள அந்த நியாயாதிபதி சொன்னதைச் சிந்தித்துப்பாருங்கள்.
	<br /><br />
	7. அந்தப்படியே தேவன் தம்மை நோக்கி இரவும் பகலும் கூப்பிடுகிறவர்களாகிய தம்மால் தெரிந்துகொள்ளப்பட்டவர்களின் விஷயத்தில் நீடிய பொறுமையுள்ளவராயிருந்து அவர்களுக்கு நியாயஞ்செய்யாமலிருப்பாரோ?
	<br /><br />
	8. சீக்கிரத்திலே அவர்களுக்கு நியாயஞ்செய்வார் என்று உங்களுக்குச் சொல்லுகிறேன். ஆகிலும் மனுஷகுமாரன் வரும்போது பூமியிலே விசுவாசத்தைக் காண்பாரோ என்றார்.
	<br /><br />
	9. அன்றியும், தங்களை நீதிமான்களென்று நம்பி, மற்றவர்களை அற்பமாயெண்ணின சிலரைக்குறித்து, அவர் ஒரு உவமையைச் சொன்னார்.
	<br /><br />
	10. இரண்டு மனுஷர் ஜெபம்பண்ணும்படி தேவாலயத்துக்குப் போனார்கள்; ஒருவன் பரிசேயன், மற்றவன் ஆயக்காரன்.
	<br /><br />
	11. பரிசேயன் நின்று: தேவனே! நான் பறிகாரர், அநியாயக்காரர், விபசாரக்காரர் ஆகிய மற்ற மனுஷரைப்போலவும், இந்த ஆயக்காரனைப்போலவும் இராததனால் உம்மை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	12. வாரத்தில் இரண்டுதரம் உபவாசிக்கிறேன்; என் சம்பாத்தியத்திலெல்லாம் தசமபாகம் செலுத்தி வருகிறேன் என்று, தனக்குள்ளே ஜெபம்பண்ணினான்.
	<br /><br />
	13. ஆயக்காரன் தூரத்திலே நின்று, தன் கண்களையும் வானத்துக்கு ஏறெடுக்கத் துணியாமல், தன் மார்பிலே அடித்துக்கொண்டு: தேவனே! பாவியாகிய என்மேல் கிருபையாயிரும் என்றான்.
	<br /><br />
	14. அவனல்ல, இவனே நீதிமானாக்கப்பட்டவனாய்த் தன் வீட்டுக்குத் திரும்பிப்போனான் என்று உங்களுக்குச் சொல்லுகிறேன்; ஏனெனில் தன்னை உயர்த்துகிறவனெவனும் தாழ்த்தப்படுவான், தன்னைத் தாழ்த்துகிறவன் உயர்த்தப்படுவான் என்றார்.
	<br /><br />
	15. பின்பு குழந்தைகளையும் அவர் தொடும்படிக்கு அவர்களை அவரிடத்தில் கொண்டுவந்தார்கள். சீஷர்கள் அதைக் கண்டு, கொண்டுவந்தவர்களை அதட்டினார்கள்.
	<br /><br />
	16. இயேசுவோ அவர்களைக் கொண்டுவரும்படி கட்டளையிட்டு: சிறு பிள்ளைகள் என்னிடத்தில் வருகிறதற்கு இடங்கொடுங்கள், அவர்களைத் தடைபண்ணாதிருங்கள்; தேவனுடைய ராஜ்யம் அப்படிப்பட்டவர்களுடையது.
	<br /><br />
	17. எவனாகிலும் சிறு பிள்ளையைப்போல் தேவனுடைய ராஜ்யத்தை ஏற்றுக்கொள்ளாவிட்டால், அதில் பிரவேசிக்கமாட்டான் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	18. அப்பொழுது தலைவன் ஒருவன் அவரை நோக்கி: நல்ல போதகரே, நித்தியஜீவனைச் சுதந்தரித்துக்கொள்வதற்கு நான் என்ன செய்யவேண்டும் என்று கேட்டான்.
	<br /><br />
	19. அதற்கு இயேசு: நீ என்னை நல்லவன் என்று சொல்வானேன்? தேவன் ஒருவர் தவிர நல்லவன் ஒருவனும் இல்லையே.
	<br /><br />
	20. விபசாரஞ் செய்யாதிருப்பாயாக, கொலை செய்யாதிருப்பாயாக, களவு செய்யாதிருப்பாயாக, பொய்ச்சாட்சி சொல்லாதிருப்பாயாக, உன் தகப்பனையும் உன் தாயையும் கனம் பண்ணுவாயாக என்கிற கற்பனைகளை நீ அறிந்திருக்கிறாயே என்றார்.
	<br /><br />
	21. அதற்கு அவன்: இவைகளையெல்லாம் என் சிறு வயதுமுதல் கைக்கொண்டிருக்கிறேன் என்றான்.
	<br /><br />
	22. இயேசு அதைக் கேட்டு: இன்னும் உன்னிடத்தில் ஒரு குறைவு உண்டு; உனக்கு உண்டானவைகளையெல்லாம் விற்றுத் தரித்திரருக்குக் கொடு, அப்பொழுது பரலோகத்திலே உனக்குப் பொக்கிஷம் உண்டாயிருக்கும்; பின்பு என்னைப் பின்பற்றிவா என்றார்.
	<br /><br />
	23. அவன் அதிக ஐசுவரியமுள்ளவனானபடியினால், இதைக் கேட்டபொழுது, மிகுந்த துக்கமடைந்தான்.
	<br /><br />
	24. அவன் மிகுந்த துக்கமடைந்ததை இயேசு கண்டு: ஐசுவரியமுள்ளவர்கள் தேவனுடைய ராஜ்யத்தில் பிரவேசிப்பது எவ்வளவு அரிதாயிருக்கிறது.
	<br /><br />
	25. ஐசுவரியவான் தேவனுடைய ராஜ்யத்தில் பிரவேசிப்பதைப்பார்க்கிலும், ஒட்டகமானது ஊசியின் காதிலே நுழைவது எளிதாயிருக்கும் என்றார்.
	<br /><br />
	26. அதைக் கேட்டவர்கள்: அப்படியானால் யார் இரட்சிக்கப்படக்கூடும் என்றார்கள்.
	<br /><br />
	27. அதற்கு அவர்: மனுஷரால் கூடாதவைகள் தேவனால் கூடும் என்றார்.
	<br /><br />
	28. அப்பொழுது பேதுரு அவரை நோக்கி: இதோ, நாங்கள் எல்லாவற்றையும் விட்டு, உம்மைப் பின்பற்றினோமே என்றான்.
	<br /><br />
	29. அதற்கு அவர்: தேவனுடைய ராஜ்யத்தினிமித்தம் வீட்டையாவது, பெற்றாரையாவது, சகோதரரையாவது, மனைவியையாவது, பிள்ளைகளையாவது விட்டுவிட்ட எவனும்,
	<br /><br />
	30. இம்மையிலே அதிகமானவைகளையும், மறுமையிலே நித்திய ஜீவனையும் அடையாமற்போவதில்லையென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	31. பின்பு அவர் பன்னிருவரையும் தம்மிடத்தில் அழைத்து: இதோ, எருசலேமுக்குப் போகிறோம், மனுஷகுமாரனைக் குறித்துத் தீர்க்கதரிசிகளால் எழுதப்பட்டவைகளெல்லாம் நிறைவேறும்.
	<br /><br />
	32. எப்படியெனில், அவர் புறஜாதியாரிடத்தில் ஒப்புக்கொடுக்கப்பட்டு, பரியாசமும் நிந்தையும் அடைந்து, துப்பப்படுவார்.
	<br /><br />
	33. அவரை வாரினால் அடித்து, கொலை செய்வார்கள்; மூன்றாம் நாளிலே அவர் உயிரோடே எழுந்திருப்பார் என்றார்.
	<br /><br />
	34. இவைகளில் ஒன்றையும் அவர்கள் உணரவில்லை; அவைகளின் பொருள் அவர்களுக்கு மறைவாயிருந்தது, அவர் சொன்னவைகளை அவர்கள் அறிந்துகொள்ளவில்லை.
	<br /><br />
	35. பின்பு அவர் எரிகோவுக்குச் சமீபமாய் வரும்போது, ஒரு குருடன் வழியருகே உட்கார்ந்து பிச்சை கேட்டுக்கொண்டிருந்தான்.
	<br /><br />
	36. ஜனங்கள் நடக்கிற சத்தத்தை அவன் கேட்டு, இதென்ன என்று விசாரித்தான்.
	<br /><br />
	37. நசரேயனாகிய இயேசு போகிறார் என்று அவனுக்கு அறிவித்தார்கள். அப்பொழுது அவன்: இயேசுவே, தாவீதின் குமாரனே, எனக்கு இரங்கும் என்று கூப்பிட்டான்.
	<br /><br />
	38. முன் நடப்பவர்கள் அவன் பேசாமலிருக்கும்படி அவனை அதட்டினார்கள். அவனோ: தாவீதின் குமாரனே, எனக்கு இரங்கும் என்று மிகவும் அதிகமாய்க் கூப்பிட்டான்.
	<br /><br />
	39. இயேசு நின்று, அவனைத் தம்மிடத்தில் கொண்டுவரும்படி சொன்னார்.
	<br /><br />
	40. அவன் கிட்டவந்தபோது, அவர் அவனை நோக்கி:
	<br /><br />
	41. நான் உனக்கு என்ன செய்யவேண்டும் என்றிருக்கிறாய் என்று கேட்டார். அதற்கு அவன்: ஆண்டவரே, நான் பார்வையடையவேண்டும் என்றான்.
	<br /><br />
	42. இயேசு அவனை நோக்கி: நீ பார்வையடைவாயாக, உன் விசுவாசம் உன்னை இரட்சித்தது என்றார்.
	<br /><br />
	43. உடனே அவன் பார்வையடைந்து, தேவனை மகிமைப்படுத்திக்கொண்டே, அவருக்குப் பின்சென்றான். ஜனங்களெல்லாரும் அதைக் கண்டு, தேவனைப் புகழ்ந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke18