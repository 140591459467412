import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-11 </title>
	<meta
          name="description"
          content="Ecclesiastes 1111 | பிரசங்கி 11 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உன் ஆகாரத்தைத் தண்ணீர்கள்மேல் போடு; அநேக நாட்களுக்குப் பின்பு அதின் பலனைக் காண்பாய்.
	<br /><br />
	2. ஏழுபேருக்கும் எட்டுபேருக்கும் பங்கிட்டுக்கொடு; பூமியின்மேல் என்ன ஆபத்து நேரிடுமோ உனக்குத் தெரியாது.
	<br /><br />
	3. மேகங்கள் நிறைந்திருந்தால் மழையைப் பூமியின்மேல் பொழியும்; மரமானது தெற்கே விழுந்தாலும் வடக்கே விழுந்தாலும், விழுந்த இடத்திலேயே மரம் கிடக்கும்.
	<br /><br />
	4. காற்றைக் கவனிக்கிறவன் விதைக்கமாட்டான்; மேகங்களை நோக்குகிறவன் அறுக்கமாட்டான்.
	<br /><br />
	5. ஆவியின் வழி இன்னதென்றும், கர்ப்பவதியின் வயிற்றில் எலும்புகள் உருவாகும் விதம் இன்னதென்றும் நீ அறியாதிருக்கிறதுபோலவே, எல்லாவற்றையும் செய்கிற தேவனுடைய செயல்களையும் நீ அறியாய்.
	<br /><br />
	6. காலையிலே உன் விதையை விதை; மாலையிலே உன் கையை நெகிழவிடாதே; அதுவோ, இதுவோ, எது வாய்க்குமோ என்றும், இரண்டும் சரியாய்ப் பயன்படுமோ என்றும் நீ அறியாயே.
	<br /><br />
	7. வெளிச்சம் இன்பமும், சூரியனைக் காண்பது கண்களுக்குப் பிரியமுமாமே.
	<br /><br />
	8. மனுஷன் அநேக வருஷம் ஜீவித்து, அவைகளிலெல்லாம் மகிழ்ச்சியாயிருந்தாலும், அவன் இருளின் நாட்களையும் நினைக்கவேண்டும்; அவைகள் அநேகமாயிருக்கும்; வந்து சம்பவிப்பதெல்லாம் மாயையே.
	<br /><br />
	9. வாலிபனே! உன் இளமையிலே சந்தோஷப்படு, உன் வாலிப நாட்களிலே உன் இருதயம் உன்னைப் பூரிப்பாக்கட்டும்; உன் நெஞ்சின் வழிகளிலும், உன் கண்ணின் காட்சிகளிலும் நட; ஆனாலும் இவையெல்லாவற்றினிமித்தமும் தேவன் உன்னை நியாயத்திலே கொண்டுவந்து நிறுத்துவார் என்று அறி.
	<br /><br />
	10. நீ உன் இருதயத்திலிருந்து சஞ்சலத்தையும், உன் மாம்சத்திலிருந்து தீங்கையும் நீக்கிப்போடு; இளவயதும் வாலிபமும் மாயையே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes11