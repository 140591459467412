import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah58 = () => {
  const verseNumber = 58;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 58 </title>
	<meta
          name="description"
          content="Isaiah 58 | ஏசாயா 58 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சத்தமிட்டுக் கூப்பிடு; அடக்கிக்கொள்ளாதே; எக்காளத்தைப்போல் உன் சத்தத்தை உயர்த்தி, என் ஜனத்துக்கு அவர்கள் மீறுதலையும், யாக்கோபின் வம்சத்தாருக்கு அவர்கள் பாவங்களையும் தெரிவி.
	<br /><br />
	2. தங்கள் தேவனுடைய நியாயத்தை விட்டு விலகாமல் நீதியைச் செய்துவருகிற ஜாதியாரைப்போல் அவர்கள் நாடோறும் என்னைத் தேடி, என் வழிகளை அறிய விரும்புகிறார்கள்; நீதிநியாயங்களை என்னிடத்தில் விசாரித்து, தேவனிடத்தில் சேர விரும்புகிறார்கள்.
	<br /><br />
	3. நாங்கள் உபவாசம்பண்ணும்போது நீர் நோக்காமலிருக்கிறதென்ன? நாங்கள் எங்கள் ஆத்துமாக்களை ஒடுக்கும்போது நீர் அதை அறியாமலிருக்கிறதென்ன என்கிறார்கள்; இதோ, நீங்கள் உபவாசிக்கும் நாளிலே உங்கள் இச்சையின்படி நடந்து, உங்கள் வேலைகளையெல்லாம் கட்டாயமாய்ச் செய்கிறீர்கள்.
	<br /><br />
	4. இதோ, வழக்குக்கும் வாதுக்கும் துஷ்டத்தனத்தையுடைய கையினால் குத்துகிறதற்கும் உபவாசிக்கிறீர்கள்; நீங்கள் உங்கள் கூக்குரலை உயரத்திலே கேட்கப்பண்ணும்படியாய், இந்நாளில் உபவாசிக்கிறதுபோல் உபவாசியாதிருங்கள்.
	<br /><br />
	5. மனுஷன் தன் ஆத்துமாவை ஒடுக்குகிறதும், தலைவணங்கி நாணலைப்போல் இரட்டிலும் சாம்பலிலும் படுத்துக்கொள்ளுகிறதும், எனக்குப் பிரியமான உபவாச நாளாயிருக்குமோ? இதையா உபவாசமென்றும் கர்த்தருக்குப் பிரியமான நாளென்றும் சொல்லுவாய்?
	<br /><br />
	6. அக்கிரமத்தின் கட்டுகளை அவிழ்க்கிறதும், நுகத்தடியின் பிணையல்களை நெகிழ்க்கிறதும், நெருக்கப்பட்டிருக்கிறவர்களை விடுதலையாக்கிவிடுகிறதும், சகல நுகத்தடிகளையும் உடைத்துப்போடுகிறதும்,
	<br /><br />
	7. பசியுள்ளவனுக்கு உன் ஆகாரத்தைப் பகிர்ந்துகொடுக்கிறதும், துரத்துண்ட சிறுமையானவர்களை வீட்டிலே சேர்த்துக்கொள்ளுகிறதும், வஸ்திரமில்லாதவனைக் கண்டால் அவனுக்கு வஸ்திரங் கொடுக்கிறதும், உன் மாம்சமானவனுக்கு உன்னை ஒளிக்காமலிருக்கிறதும் அல்லவோ எனக்கு உகந்த உபவாசம்.
	<br /><br />
	8. அப்பொழுது விடியற்கால வெளுப்பைப்போல உன் வெளிச்சம் எழும்பி, உன் சுகவாழ்வு சீக்கிரத்தில் துளிர்த்து, உன் நீதி உனக்கு முன்னாலே செல்லும்; கர்த்தருடைய மகிமை உன்னைப் பின்னாலே காக்கும்.
	<br /><br />
	9. அப்பொழுது நீ கூப்பிடுவாய், கர்த்தர் மறுஉத்தரவு கொடுப்பார்; நீ சத்தமிடுவாய்: இதோ, நான் இருக்கிறேன் என்று சொல்லுவார். நுகத்தடியையும், விரல் நீட்டுதலையும், நிபச்சொல்லையும், நீ உன் நடுவிலிருந்து அகற்றி,
	<br /><br />
	10. பசியுள்ளவனிடத்தில் உன் ஆத்துமாவைச் சாய்த்து, சிறுமைப்பட்ட ஆத்துமாவைத் திருப்தியாக்கினால், அப்பொழுது இருளில் உன் வெளிச்சம் உதித்து, உன் அந்தகாரம் மத்தியானத்தைப்போலாகும்.
	<br /><br />
	11. கர்த்தர் நித்தமும் உன்னை நடத்தி, மகா வறட்சியான காலங்களில் உன் ஆத்துமாவைத் திருப்தியாக்கி, உன் எலும்புகளை நிணமுள்ளதாக்குவார்; நீ நீர்ப்பாய்ச்சலான தோட்டத்தைப்போலவும், வற்றாத நீரூற்றைப்போலவும் இருப்பாய்.
	<br /><br />
	12. உன்னிடத்திலிருந்து தோன்றினவர்கள் பூர்வமுதல் பாழாய்க்கிடந்த ஸ்தலங்களைக் கட்டுவார்கள்; தலைமுறை தலைமுறையாக இருக்கும் அஸ்திபாரங்கள்மேல் நீ கட்டுவாய்; திறப்பானதை அடைக்கிறவன் என்றும், குடியிருக்கும்படி பாதைகளைத் திருத்துகிறவன் என்றும் நீ பெயர் பெறுவாய்.
	<br /><br />
	13. என் பரிசுத்தநாளாகிய ஓய்வுநாளிலே உனக்கு இஷ்டமானதைச் செய்யாதபடி, உன் காலை விலக்கி, உன் வழிகளின்படி நடவாமலும், உனக்கு இஷ்டமானதைச் செய்யாமலும், உன் சொந்தப்பேச்சைப் பேசாமலிருந்து, ஓய்வுநாளை மனமகிழ்ச்சியின் நாளென்றும், கர்த்தருடைய பரிசுத்த நாளை மகிமையுள்ள நாளென்றும் சொல்லி, அதை மகிமையாக எண்ணுவாயானால்,
	<br /><br />
	14. அப்பொழுது கர்த்தரில் மனமகிழ்ச்சியாயிருப்பாய்; பூமியின் உயர்ந்த இடங்களில் உன்னை ஏறியிருக்கும்படி பண்ணி, உன் தகப்பனாகிய யாக்கோபுடைய சுதந்தரத்தால் உன்னைப் போஷிப்பேன்; கர்த்தருடைய வாய் இதைச் சொல்லிற்று.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah58