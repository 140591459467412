import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 12 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 12 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 12 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அன்றியும், சகோதரரே, ஆவிக்குரிய வரங்களைக்குறித்து நீங்கள் அறியாதிருக்க எனக்கு மனதில்லை.
	<br /><br />
	2. நீங்கள் அஞ்ஞானிகளாயிருந்தபோது ஏவப்பட்டபடியே, ஊமையான விக்கிரகங்களிடத்தில் மனதைச் செலுத்தினீர்களென்று உங்களுக்குத் தெரியுமே.
	<br /><br />
	3. ஆதலால், தேவனுடைய ஆவியினாலே பேசுகிற எவனும் இயேசுவைச் சபிக்கப்பட்டவனென்று சொல்லமாட்டானென்றும், பரிசுத்த ஆவியினாலேயன்றி இயேசுவைக் கர்த்தரென்று ஒருவனும் சொல்லக்கூடாதென்றும், உங்களுக்குத் தெரிவிக்கிறேன்.
	<br /><br />
	4. வரங்களில் வித்தியாசங்கள் உண்டு, ஆவியானவர் ஒருவரே.
	<br /><br />
	5. ஊழியங்களிலேயும் வித்தியாசங்கள் உண்டு, கர்த்தர் ஒருவரே.
	<br /><br />
	6. கிரியைகளிலேயும் வித்தியாசங்கள் உண்டு, எல்லாருக்குள்ளும் எல்லாவற்றையும் நடப்பிக்கிற தேவன் ஒருவரே.
	<br /><br />
	7. ஆவியினுடைய அநுக்கிரகம் அவனவனுடைய பிரயோஜனத்திற்கென்று அளிக்கப்பட்டிருக்கிறது.
	<br /><br />
	8. எப்படியெனில், ஒருவனுக்கு ஆவியினாலே ஞானத்தைப் போதிக்கும் வசனமும், வேறொருவனுக்கு அந்த ஆவியினாலேயே அறிவை உணர்த்தும் வசனமும்,
	<br /><br />
	9. வேறொருவனுக்கு அந்த ஆவியினாலேயே விசுவாசமும், வேறொருவனுக்கு அந்த ஆவியினாலேயே குணமாக்கும் வரங்களும்,
	<br /><br />
	10. வேறொருவனுக்கு அற்புதங்களைச் செய்யும் சக்தியும், வேறொருவனுக்குத் தீர்க்கதரிசனம் உரைத்தலும், வேறொருவனுக்கு ஆவிகளைப் பகுத்தறிதலும், வேறொருவனுக்குப் பற்பல பாஷைகளைப் பேசுதலும், வேறொருவனுக்குப் பாஷைகளை வியாக்கியானம்பண்ணுதலும் அளிக்கப்படுகிறது.
	<br /><br />
	11. இவைகளையெல்லாம் அந்த ஒரே ஆவியானவர் நடப்பித்து, தமது சித்தத்தின்படியே அவனவனுக்குப் பகிர்ந்துகொடுக்கிறார்.
	<br /><br />
	12. எப்படியெனில், சரீரம் ஒன்று, அதற்கு அவயவங்கள் அநேகம்; ஒரே சரீரத்தின் அவயவங்களெல்லாம் அநேகமாயிருந்தும், சரீரம் ஒன்றாகவேயிருக்கிறது; அந்தப்பிரகாரமாகக் கிறிஸ்துவும் இருக்கிறார்.
	<br /><br />
	13. நாம் யூதராயினும், கிரேக்கராயினும், அடிமைகளாயினும், சுயாதீனராயினும், எல்லாரும் ஒரே ஆவியினாலே ஒரே சரீரத்திற்குள்ளாக ஞானஸ்நானம்பண்ணப்பட்டு, எல்லாரும் ஒரே ஆவிக்குள்ளாகவே தாகந்தீர்க்கப்பட்டோம்.
	<br /><br />
	14. சரீரமும் ஒரே அவயவமாயிராமல் அநேக அவயவங்களாயிருக்கிறது.
	<br /><br />
	15. காலானது நான் கையாயிராதபடியினாலே, நான் சரீரத்தின் அவயவமல்லவென்றால், அதினாலே அது சரீரத்தின் அவயவமாயிராதோ?
	<br /><br />
	16. காதானது நான் கண்ணாயிராதபடியினாலே, நான் சரீரத்தின் அவயவமல்லவென்றால், அதினாலே அது சரீரத்தின் அவயவமாயிராதோ?
	<br /><br />
	17. சரீரம் முழுவதும் கண்ணானால், செவி எங்கே? அது முழுவதும் செவியானால், மோப்பம் எங்கே?
	<br /><br />
	18. தேவன் தமது சித்தத்தின்படி, அவயவங்கள் ஒவ்வொன்றையும் சரீரத்திலே வைத்தார்.
	<br /><br />
	19. அவையெல்லாம் ஒரே அவயவமாயிருந்தால், சரீரம் எங்கே?
	<br /><br />
	20. அவயவங்கள் அநேகமாயிருந்தும், சரீரம் ஒன்றே.
	<br /><br />
	21. கண்ணானது கையைப்பார்த்து: நீ எனக்கு வேண்டுவதில்லையென்றும்; தலையானது கால்களை நோக்கி: நீங்கள் எனக்கு வேண்டுவதில்லையென்றும் சொல்லக்கூடாது.
	<br /><br />
	22. சரீர அவயவங்களில் பலவீனமுள்ளவைகளாய்க் காணப்படுகிறவைகளே மிகவும் வேண்டியவைகளாயிருக்கிறது.
	<br /><br />
	23. மேலும், சரீர அவயவங்களில் கனவீனமாய்க் காணப்படுகிறவைகளுக்கே அதிக கனத்தைக் கொடுக்கிறோம்; நம்மில் இலட்சணமில்லாதவைகளே அதிக அலங்காரம் பெறும்;
	<br /><br />
	24. நம்மில் இலட்சணமுள்ளவைகளுக்கு அலங்கரிப்பு வேண்டியதில்லை.
	<br /><br />
	25. சரீரத்திலே பிரிவினையுண்டாயிராமல், அவயவங்கள் ஒன்றைக்குறித்து ஒன்று கவலையாயிருக்கும்படிக்கு, தேவன் கனத்தில் குறைவுள்ளதற்கு அதிக கனத்தைக் கொடுத்து, இப்படிச் சரீரத்தை அமைத்திருக்கிறார்.
	<br /><br />
	26. ஆதலால் ஒரு அவயவம் பாடுபட்டால் எல்லா அவயவங்களும் கூடப் பாடுபடும்; ஒரு அவயவம் மகிமைப்பட்டால் எல்லா அவயவங்களும் கூடச் சந்தோஷப்படும்.
	<br /><br />
	27. நீங்களே கிறிஸ்துவின் சரீரமாயும், தனித்தனியே அவயவங்களாயுமிருக்கிறீர்கள்.
	<br /><br />
	28. தேவனானவர் சபையிலே முதலாவது அப்போஸ்தலரையும், இரண்டாவது தீர்க்கதரிசிகளையும், மூன்றாவது போதகர்களையும், பின்பு அற்புதங்களையும், பின்பு குணமாக்கும் வரங்களையும், ஊழியங்களையும், ஆளுகைகளையும், பலவித பாஷைகளையும் ஏற்படுத்தினார்.
	<br /><br />
	29. எல்லாரும் அப்போஸ்தலர்களா? எல்லாரும் தீர்க்கதரிசிகளா? எல்லாரும் போதகர்களா? எல்லாரும் அற்புதங்களைச் செய்கிறவர்களா?
	<br /><br />
	30. எல்லாரும் குணமாக்கும் வரங்களுடையவர்களா? எல்லாரும் அந்நியபாஷைகளைப் பேசுகிறார்களா? எல்லாரும் வியாக்கியானம் பண்ணுகிறார்களா?
	<br /><br />
	31. இப்படியிருக்க, முக்கியமான வரங்களை நாடுங்கள்; இன்னும் அதிக மேன்மையான வழியையும் உங்களுக்குக் காண்பிக்கிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians12