import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 3 </title>
	<meta
          name="description"
          content="Amos 3 | ஆமோஸ் 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேல் புத்திரரே, கர்த்தராகிய நான் எகிப்துதேசத்திலிருந்து வரப்பண்ணின முழுக்குடும்பமாகிய உங்களுக்கு விரோதமாய்ச் சொல்லிய இந்த வசனத்தைக் கேளுங்கள்.
	<br /><br />
	2. பூமியின் எல்லா வம்சங்களுக்குள்ளும் உங்களைமாத்திரம் அறிந்துகொண்டேன்; ஆகையால் உங்களுடைய எல்லா அக்கிரமங்களினிமித்தமும் உங்களைத் தண்டிப்பேன்.
	<br /><br />
	3. இரண்டுபேர் ஒருமனப்பட்டிருந்தாலொழிய ஒருமித்து நடந்துபோவார்களோ?
	<br /><br />
	4. தனக்கு இரை அகப்படாமலிருக்கக் காட்டிலே சிங்கம் கெர்ச்சிக்குமோ? இரைபிடியாமல் இருக்கும்போது பாலசிங்கம் தன் கெபியிலிருந்து சத்தமிடுமோ?
	<br /><br />
	5. குருவிக்குத் தரையிலே சுருக்குப் போடப்படாதிருந்தால், அது கண்ணியில் அகப்படுமோ? ஒன்றும் படாதிருக்கையில், கண்ணி தரையிலிருந்து எடுக்கப்படுமோ?
	<br /><br />
	6. ஊரில் எக்காளம் ஊதினால், ஜனங்கள் கலங்காதிருப்பார்களோ? கர்த்தருடைய செயல் இல்லாமல் ஊரில் தீங்கு உண்டாகுமோ?
	<br /><br />
	7. கர்த்தராகிய ஆண்டவர் தீர்க்கதரிசிகளாகிய தம்முடைய ஊழியக்காரருக்குத் தமது இரகசியத்தை வெளிப்படுத்தாமல் ஒரு காரியத்தையும் செய்யார்.
	<br /><br />
	8. சிங்கம் கெர்ச்சிக்கிறது, யார் பயப்படாதிருப்பான்? கர்த்தராகிய ஆண்டவர் பேசுகிறார், யார் தீர்க்கதரிசனம் சொல்லாதிருப்பான்?
	<br /><br />
	9. நீங்கள் சமாரியாவின் பர்வதங்களில் வந்து கூடி, அதின் நடுவில் நடக்கிற பெரிய கலகங்களையும் அதற்குள் செய்யப்படுகிற இடுக்கண்களையும் பாருங்கள் என்று அஸ்தோத்தின் அரமனைகள்மேலும், எகிப்துதேசத்தின் அரமனைகள்மேலும் கூறுங்கள்.
	<br /><br />
	10. அவர்கள் செம்மையானதைச் செய்ய அறியாமல், தங்கள் அரமனைகளில் கொடுமையையும் கொள்ளையையும் குவித்துக்கொள்ளுகிறார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. ஆகையால் சத்துரு வந்து, தேசத்தைச் சூழ்ந்துகொண்டு, உன் பெலத்தை உன்னிலிருந்து அற்றுப்போகப்பண்ணுவான்; அப்பொழுது உன் அரமனைகள் கொள்ளையிடப்படும் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	12. மேலும்: ஒரு மேய்ப்பன் இரண்டு கால்களையாவது ஒரு காதின் துண்டையாவது சிங்கத்தின் வாயிலிருந்து பிடுங்கித் தப்புவிக்குமாப்போல, சமாரியாவில் குடியிருக்கிற இஸ்ரவேல் புத்திரர் ஒரு படுக்கையின் மூலையிலிருந்தும், ஒரு மஞ்சத்தின்மேலிருந்தும் தப்புவிக்கப்படுவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. நீங்கள் கேட்டு யாக்கோபு வம்சத்தாருக்குள்ளே சாட்சியாக அறிவிக்கவேண்டியது: சேனைகளின் தேவனாயிருக்கிற கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்,
	<br /><br />
	14. நான் இஸ்ரவேலுடைய பாதகங்களினிமித்தம் அவனை விசாரிக்கும் நாளிலே நான் பெத்தேலின் பலிபீடங்களை விசாரிப்பேன்; பலிபீடத்தின் கொம்புகள் வெட்டுண்டு தரையிலே விழும்.
	<br /><br />
	15. மாரிகாலத்து வீட்டையும் கோடைகாலத்து வீட்டையும் அழிப்பேன்; அப்பொழுது யானைத்தந்தத்தால் செய்யப்பட்ட வீடுகள் அழியும்; பெரிய வீடுகளுக்கும் முடிவு வரும் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos3