import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah29 = () => {
  const verseNumber = 29;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 29 </title>
	<meta
          name="description"
          content="Isaiah 29 | ஏசாயா 29 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தாவீது வாசம்பண்ணின நகரமாகிய அரியேலே, அரியேலே, ஐயோ! வருஷாவருஷம் பண்டிகைகளை அனுசரித்துவந்தாலும்,
	<br /><br />
	2. அரியேலுக்கு இடுக்கம் உண்டாக்குவேன்; அப்பொழுது துக்கமும் சலிப்பும் உண்டாகும்; அது எனக்கு அரியேலாகத்தான் இருக்கும்.
	<br /><br />
	3. உன்னைச் சூழப் பாளயமிறங்கி, உன்னைத் தெற்றுகளால் முற்றிக்கைபோட்டு, உனக்கு விரோதமாகக் கொத்தளங்களை எடுப்பிப்பேன்.
	<br /><br />
	4. அப்பொழுது நீ தாழ்த்தப்பட்டுத் தரையிலிருந்து பேசுவாய்; உன் பேச்சுப் பணிந்ததாய் மண்ணிலிருந்து புறப்பட்டு, உன் சத்தம் அஞ்சனம் பார்க்கிறவனுடைய சத்தத்தைப்போல் தரையிலிருந்து முணுமுணுத்து, உன் வாக்கு மண்ணிலிருந்து கசுகுசு என்று உரைக்கும்.
	<br /><br />
	5. உன்மேல் வருகிற அந்நியரின் திரள் பொடித்தூளத்தனையாகவும், பலவந்தரின் திரள் பறக்கும் பதர்களத்தனையாகவும் இருக்கும்; அது திடீரென்று சடிதியாய்ச் சம்பவிக்கும்.
	<br /><br />
	6. இடிகளினாலும், பூமி அதிர்ச்சியினாலும், பெரிய இரைச்சலினாலும், பெருங்காற்றினாலும், புசலினாலும், பட்சிக்கிற அக்கினிஜுவாலையினாலும், சேனைகளின் கர்த்தராலே விசாரிக்கப்படுவாய்.
	<br /><br />
	7. அரியேலின்மேல் யுத்தம்பண்ணுகிற திரளான சகல ஜாதிகளும், அதின்மேலும் அதின் அரண்மேலும் யுத்தம்பண்ணி, அதற்கு இடுக்கண் செய்கிற அனைவரும், இராக்காலத்தரிசனமாகிய சொப்பனத்தைக் காண்கிறவர்களுக்கு ஒப்பாயிருப்பார்கள்.
	<br /><br />
	8. அது, பசியாயிருக்கிறவன் தான் புசிக்கிறதாகச் சொப்பனம் கண்டும், விழிக்கும்போது அவன் வெறுமையாயிருக்கிறதுபோலவும், தாகமாயிருக்கிறவன், தான் குடிக்கிறதாகச் சொப்பனம் கண்டும், விழிக்கும்போது அவன் விடாய்த்துத் தவனத்தோடிருக்கிறதுபோலவும் சீயோன் மலைக்கு விரோதமாக யுத்தம்பண்ணுகிற திரளான சகல ஜாதிகளும் இருக்கும்.
	<br /><br />
	9. தரித்துநின்று திகையுங்கள்; பிரமித்துக் கூப்பிடுங்கள்; வெறித்திருக்கிறார்கள், திராட்சரசத்தினால் அல்ல; தள்ளாடுகிறார்கள், மதுபானத்தினால் அல்ல.
	<br /><br />
	10. கர்த்தர் உங்கள்மேல் கனநித்திரையின் ஆவியை வரப்பண்ணி, உங்கள் கண்களை அடைத்து, ஞானதிருஷ்டிக்காரராகிய உங்கள் தீர்க்கதரிசிகளுக்கும் தலைவர்களுக்கும் முக்காடுபோட்டார்.
	<br /><br />
	11. ஆதலால் தரிசனமெல்லாம் உங்களுக்கு முத்திரிக்கப்பட்ட புஸ்தகத்தின் வசனங்களைப்போலிருக்கும்; வாசிக்க அறிந்திருக்கிற ஒருவனுக்கு அதைக் கொடுத்து: நீ இதை வாசி என்றால், அவன்: இது என்னால் கூடாது, இது முத்திரித்திருக்கிறது என்பான்.
	<br /><br />
	12. அல்லது வாசிக்கத் தெரியாதவனிடத்தில் புஸ்தகத்தைக் கொடுத்து: நீ இதை வாசி என்றால், அவன்: எனக்கு வாசிக்கத் தெரியாது என்பான்.
	<br /><br />
	13. இந்த ஜனங்கள் தங்கள் வாயினால் என்னிடத்தில் சேர்ந்து, தங்கள் உதடுகளினால் என்னைக் கனம்பண்ணுகிறார்கள்; அவர்கள் இருதயமோ எனக்குத் தூரமாய் விலகியிருக்கிறது; அவர்கள் எனக்குப் பயப்படுகிற பயம் மனுஷராலே போதிக்கப்பட்ட கற்பனையாயிருக்கிறது.
	<br /><br />
	14. ஆதலால் இதோ, நான் அற்புதமும் ஆச்சரியமுமான பிரகாரமாக இந்த ஜனங்களுக்குள்ளே ஒரு அதிசயத்தைச் செய்வேன்; அவர்களுடைய ஞானிகளின் ஞானம் கெட்டு, அவர்களுடைய விவேகிகளின் விவேகம் மறைந்துபோகும் என்று ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. தங்கள் ஆலோசனையைக் கர்த்தருக்கு மறைக்கும்படிக்கு மறைவிடங்களில் ஒளித்து, தங்கள் கிரியைகளை அந்தகாரத்தில் நடப்பித்து: நம்மைக் காண்கிறவர் யார்? நம்மை அறிகிறவர் யார்? என்கிறவர்களுக்கு ஐயோ!
	<br /><br />
	16. ஆ, நீங்கள் எவ்வளவு மாறுபாடுள்ளவர்கள்! குயவன் களிமண்ணுக்குச் சமானமாக எண்ணப்படலாமோ? உண்டாக்கப்பட்டது தன்னை உண்டாக்கினவரைக்குறித்து: அவர் என்னை உண்டாக்கினதில்லை என்றும்; உருவாக்கப்பட்டது தன்னை உருவாக்கினவரைக்குறித்து: அவருக்குப் புத்தியில்லையென்றும் சொல்லத்தகுமோ?
	<br /><br />
	17. இன்னும் கொஞ்சக்காலத்திலல்லவோ லீபனோன் செழிப்பான வயல்வெளியாக மாறும்; செழிப்பான வயல்வெளி காடாக எண்ணப்படும்.
	<br /><br />
	18. அக்காலத்திலே செவிடர் புஸ்தகத்தின் வசனங்களைக் கேட்பார்கள்; குருடரின் கண்கள் இருளுக்கும் அந்தகாரத்துக்கும் நீங்கலாகிப் பார்வையடையும்.
	<br /><br />
	19. சிறுமையானவர்கள் கர்த்தருக்குள் மிகவும் மகிழ்ந்து, மனுஷரில் எளிமையானவர்கள் இஸ்ரவேலின் பரிசுத்தருக்குள் களிகூருவார்கள்.
	<br /><br />
	20. கொடியன் அற்றுப்போவான், சக்கந்தக்காரன் இல்லாமற்போவான்.
	<br /><br />
	21. ஒரு வார்த்தையினிமித்தம் மனுஷனைக் குற்றப்படுத்தி, நியாயவாசலில் தங்களைக் கடிந்துகொள்ளுகிறவனுக்குக் கண்ணிவைத்து, நீதிமானை நிர்நிமித்தமாய்த் துரத்தி, இப்படி அக்கிரமஞ்செய்ய வகைதேடுகிற யாவரும் சங்கரிக்கப்படுவார்கள்.
	<br /><br />
	22. ஆகையால், ஆபிரகாமை மீட்டுக்கொண்ட கர்த்தர் யாக்கோபின் வம்சத்தைக் குறித்து: இனி யாக்கோபு வெட்கப்படுவதில்லை; இனி அவன் முகம் செத்துப்போவதுமில்லை.
	<br /><br />
	23. அவன் என் கரங்களின் செயலாகிய தன் பிள்ளைகளை தன் நடுவிலே காணும்போது, என் நாமத்தைப் பரிசுத்தப்படுத்துவார்கள்; யாக்கோபின் பரிசுத்தரை அவர்கள் பரிசுத்தப்படுத்தி, இஸ்ரவேலின் தேவனுக்குப் பயப்படுவார்கள்.
	<br /><br />
	24. வழுவிப்போகிற மனதை உடையவர்கள் புத்திமான்களாகி, முறுமுறுக்கிறவர்கள் உபதேசம் கற்றுக்கொள்ளுவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah29