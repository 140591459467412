import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Colossians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Book of Colossians Bible in Tamil | கொலோசெயர் 4 </title>
	<meta
          name="description"
          content="Colossians 4 | கொலோசெயர் 4 |
          Book of Colossians | Tamil Bible Verse | Tamil Bible | கொலோசெயருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. எஜமான்களே, உங்களுக்கும் பரலோகத்தில் எஜமான் இருக்கிறாரென்று அறிந்து, வேலைக்காரருக்கு நீதியும் செவ்வையுமானதைச் செய்யுங்கள்.
	<br /><br />
	2. இடைவிடாமல் ஜெபம்பண்ணுங்கள், ஸ்தோத்திரத்துடன் ஜெபத்தில் விழித்திருங்கள்.
	<br /><br />
	3. கிறிஸ்துவினுடைய இரகசியத்தினிமித்தம் கட்டப்பட்டிருக்கிற நான் அந்த இரகசியத்தைக்குறித்துப் பேசவேண்டியபிரகாரமாய்ப் பேசி, அதை வெளிப்படுத்துவதற்கு,
	<br /><br />
	4. திருவசனம் செல்லும்படியான வாசலைத் தேவன் திறந்தருளும்படி எங்களுக்காகவும் வேண்டிக்கொள்ளுங்கள்.
	<br /><br />
	5. புறம்பேயிருக்கிறவர்களுக்கு முன்பாக ஞானமாய் நடந்து, காலத்தைப் பிரயோஜனப்படுத்திக்கொள்ளுங்கள்.
	<br /><br />
	6. அவனவனுக்கு இன்னின்னபடி உத்தரவு சொல்லவேண்டுமென்று நீங்கள் அறியும்படிக்கு, உங்கள் வசனம் எப்பொழுதும் கிருபை பொருந்தினதாயும் உப்பால் சாரமேறினதாயுமிருப்பதாக.
	<br /><br />
	7. பிரியமான சகோதரனும், உண்மையுள்ள ஊழியக்காரனும், கர்த்தருக்குள் எனக்கு உடன்வேலையாளுமாயிருக்கிற தீகிக்கு என்பவன் என் செய்திகளையெல்லாம் உங்களுக்கு அறிவிப்பான்.
	<br /><br />
	8. உங்கள் செய்திகளை அறியவும், உங்கள் இருதயங்களைத் தேற்றவும்,
	<br /><br />
	9. அவனையும், உங்களிலொருவனாயிருக்கிற உண்மையும் பிரியமுமுள்ள சகோதரனாகிய ஒநேசிமு என்பவனையும், உங்களிடத்தில் அனுப்பியிருக்கிறேன்; அவர்கள் இவ்விடத்துச் செய்திகளையெல்லாம் உங்களுக்கு அறிவிப்பார்கள்.
	<br /><br />
	10. என்னோடேகூடக் காவலிலிருக்கிற அரிஸ்தர்க்கு உங்களுக்கு வாழ்த்துதல் சொல்லுகிறான்; பர்னபாவுக்கு இனத்தானாகிய மாற்கும் வாழ்த்துதல் சொல்லுகிறான், இவனைக்குறித்துக் கட்டளைபெற்றீர்களே; இவன் உங்களிடத்தில் வந்தால் இவனை அங்கிகரித்துக்கொள்ளுங்கள்.
	<br /><br />
	11. யுஸ்து என்னப்பட்ட இயேசுவும் வாழ்த்துதல் சொல்லுகிறான். விருத்தசேதனமுள்ளவர்களில் இவர்கள்மாத்திரம் தேவனுடைய ராஜ்யத்தின்பொருட்டு என் உடன்வேலையாட்களாயிருந்து, எனக்கு ஆறுதல் செய்துவந்தவர்கள்.
	<br /><br />
	12. எப்பாப்பிராவும் உங்களுக்கு வாழ்த்துதல் சொல்லுகிறான்; உங்களைச் சேர்ந்தவனும் கிறிஸ்துவின் ஊழியக்காரனுமாகிய இவன், நீங்கள் தேவனுக்குச் சித்தமானவைகளெல்லாவற்றிலும் தேறினவர்களாயும் பூரண நிச்சயமுள்ளவர்களாயும் நிலைநிற்கவேண்டுமென்று, தன் ஜெபங்களில் உங்களுக்காக எப்பொழுதும் போராடுகிறான்.
	<br /><br />
	13. இவன் உங்களுக்காகவும், லவோதிக்கேயருக்காகவும், எராப்போலியருக்காகவும், மிகுந்த ஜாக்கிரதையுள்ளவனாயிருக்கிறானென்பதற்கு நான் சாட்சியாயிருக்கிறேன்.
	<br /><br />
	14. பிரியமான வைத்தியனாகிய லூக்காவும், தேமாவும், உங்களுக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	15. லவோதிக்கேயாவிலிருக்கிற சகோதரரையும், நிம்பாவையும், அவன் வீட்டில் கூடுகிற சபையையும் வாழ்த்துங்கள்.
	<br /><br />
	16. இந்த நிருபம் உங்களிடத்தில் வாசிக்கப்பட்டபின்பு இது லவோதிக்கேயா சபையிலும் வாசிக்கப்படும்படி செய்யுங்கள்; லவோதிக்கேயாவிலிருந்து வரும் நிருபத்தை நீங்களும் வாசியுங்கள்.
	<br /><br />
	17. அர்க்கிப்பைக் கண்டு: நீ கர்த்தரிடத்தில் பெற்ற ஊழியத்தை நிறைவேற்றும்படி கவனமாயிருப்பாயாகவென்று சொல்லுங்கள்.
	<br /><br />
	18. பவுலாகிய நான் என் கையினால் எழுதி, உங்களை வாழ்த்துகிறேன். நான் கட்டப்பட்டிருக்கிறதை நினைத்துக்கொள்ளுங்கள். கிருபை உங்களோடிருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Colossians4