import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew24 = () => {
  const verseNumber = 24;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 24 </title>
	<meta
          name="description"
          content="Matthew 24 | மத்தேயு 24 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு தேவாலயத்தை விட்டுப் புறப்பட்டுப்போகையில், அவருடைய சீஷர்கள் தேவாலயத்தின் கட்டடங்களை அவருக்குக் காண்பிக்க அவரிடத்தில் வந்தார்கள்.
	<br /><br />
	2. இயேசு அவர்களை நோக்கி: இவைகளையெல்லாம் பார்க்கிறீர்களே, இவ்விடத்தில் ஒரு கல்லின்மேல் ஒரு கல்லிராதபடிக்கு எல்லாம் இடிக்கப்பட்டுப்போகும் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	3. பின்பு, அவர் ஒலிவமலையின்மேல் உட்கார்ந்திருக்கையில், சீஷர்கள் அவரிடத்தில் தனித்துவந்து: இவைகள் எப்பொழுது சம்பவிக்கும்? உம்முடைய வருகைக்கும், உலகத்தின் முடிவுக்கும் அடையாளம் என்ன? எங்களுக்குச் சொல்லவேண்டும் என்றார்கள்.
	<br /><br />
	4. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: ஒருவனும் உங்களை வஞ்சியாதபடிக்கு எச்சரிக்கையாயிருங்கள்;
	<br /><br />
	5. ஏனெனில், அநேகர் வந்து, என் நாமத்தைத் தரித்துக்கொண்டு: நானே கிறிஸ்து என்று சொல்லி, அநேகரை வஞ்சிப்பார்கள்.
	<br /><br />
	6. யுத்தங்களையும் யுத்தங்களின் செய்திகளையும் கேள்விப்படுவீர்கள்; கலங்காதபடி எச்சரிக்கையாயிருங்கள்; இவைகளெல்லாம் சம்பவிக்கவேண்டியதே; ஆனாலும், முடிவு உடனே வராது.
	<br /><br />
	7. ஜனத்துக்கு விரோதமாய் ஜனமும், ராஜ்யத்துக்கு விரோதமாய் ராஜ்யமும் எழும்பும்; பஞ்சங்களும், கொள்ளைநோய்களும், பூமியதிர்ச்சிகளும் பல இடங்களில் உண்டாகும்.
	<br /><br />
	8. இவைகளெல்லாம் வேதனைகளுக்கு ஆரம்பம்.
	<br /><br />
	9. அப்பொழுது, உங்களை உபத்திரவங்களுக்கு ஒப்புக்கொடுத்து, உங்களைக் கொலைசெய்வார்கள்; என் நாமத்தினிமித்தம் நீங்கள் சகல ஜனங்களாலும் பகைக்கப்படுவீர்கள்.
	<br /><br />
	10. அப்பொழுது, அநேகர் இடறலடைந்து, ஒருவரையொருவர் காட்டிக்கொடுத்து, ஒருவரையொருவர் பகைப்பார்கள்.
	<br /><br />
	11. அநேகங் கள்ளத்தீர்க்கதரிசிகளும் எழும்பி, அநேகரை வஞ்சிப்பார்கள்.
	<br /><br />
	12. அக்கிரமம் மிகுதியாவதினால் அநேகருடைய அன்பு தணிந்துபோம்.
	<br /><br />
	13. முடிவுபரியந்தம் நிலைநிற்பவனே இரட்சிக்கப்படுவான்.
	<br /><br />
	14. ராஜ்யத்தினுடைய இந்தச் சுவிசேஷம் பூலோகமெங்குமுள்ள சகல ஜாதிகளுக்கும் சாட்சியாகப் பிரசங்கிக்கப்படும், அப்போது முடிவு வரும்.
	<br /><br />
	15. மேலும், பாழாக்குகிற அருவருப்பைக்குறித்துத் தானியேல் தீர்க்கதரிசி சொல்லியிருக்கிறானே. வாசிக்கிறவன் சிந்திக்கக்கடவன். நீங்கள் அதைப் பரிசுத்த ஸ்தலத்தில் நிற்கக் காணும்போது,
	<br /><br />
	16. யூதேயாவில் இருக்கிறவர்கள் மலைகளுக்கு ஓடிப்போகக்கடவர்கள்.
	<br /><br />
	17. வீட்டின்மேல் இருக்கிறவன் தன் வீட்டிலே எதையாகிலும் எடுப்பதற்கு இறங்காதிருக்கக்கடவன்.
	<br /><br />
	18. வயலில் இருக்கிறவன் தன் வஸ்திரங்களை எடுப்பதற்குத் திரும்பாதிருக்கக்கடவன்.
	<br /><br />
	19. அந்நாட்களிலே கர்ப்பவதிகளுக்கும் பால்கொடுக்கிறவர்களுக்கும் ஐயோ.
	<br /><br />
	20. நீங்கள் ஓடிப்போவது மாரிகாலத்திலாவது, ஓய்வுநாளிலாவது, சம்பவியாதபடிக்கு வேண்டிக்கொள்ளுங்கள்.
	<br /><br />
	21. ஏனெனில், உலகமுண்டானதுமுதல் இதுவரைக்கும் சம்பவித்திராததும், இனிமேலும் சம்பவியாததுமான மிகுந்த உபத்திரவம் அப்பொழுது உண்டாயிருக்கும்.
	<br /><br />
	22. அந்நாட்கள் குறைக்கப்படாதிருந்தால், ஒருவனாகிலும் தப்பிப்போவதில்லை; தெரிந்துகொள்ளப்பட்டவர்களினிமித்தமோ அந்த நாட்கள் குறைக்கப்படும்.
	<br /><br />
	23. அப்பொழுது, இதோ, கிறிஸ்து இங்கே இருக்கிறார், அதோ, அங்கே இருக்கிறார் என்று எவனாகிலும் சொன்னால் நம்பாதேயுங்கள்.
	<br /><br />
	24. ஏனெனில், கள்ளக்கிறிஸ்துக்களும் கள்ளத்தீர்க்கதரிசிகளும் எழும்பி, கூடுமானால் தெரிந்துகொள்ளப்பட்டவர்களையும் வஞ்சிக்கத்தக்கதாகப் பெரிய அடையாளங்களையும் அற்புதங்களையும் செய்வார்கள்.
	<br /><br />
	25. இதோ, முன்னதாக உங்களுக்கு அறிவித்திருக்கிறேன்.
	<br /><br />
	26. ஆகையால்: அதோ, வனாந்தரத்தில் இருக்கிறார் என்று சொல்வார்களானால், புறப்படாதிருங்கள்; இதோ, அறைவீட்டிற்குள் இருக்கிறார் என்று சொல்வார்களானால் நம்பாதிருங்கள்.
	<br /><br />
	27. மின்னல் கிழக்கிலிருந்து தோன்றி மேற்குவரைக்கும் பிரகாசிக்கிறதுபோல, மனுஷகுமாரனுடைய வருகையும் இருக்கும்.
	<br /><br />
	28. பிணம் எங்கேயோ அங்கே கழுகுகள் வந்து கூடும்.
	<br /><br />
	29. அந்நாட்களின் உபத்திரவம் முடிந்தவுடனே, சூரியன் அந்தகாரப்படும், சந்திரன் ஒளியைக் கொடாதிருக்கும், நட்சத்திரங்கள் வானத்திலிருந்து விழும், வானத்தின் சத்துவங்கள் அசைக்கப்படும்.
	<br /><br />
	30. அப்பொழுது, மனுஷகுமாரனுடைய அடையாளம் வானத்தில் காணப்படும். அப்பொழுது, மனுஷகுமாரன் வல்லமையோடும் மிகுந்த மகிமையோடும் வானத்தின் மேகங்கள்மேல் வருகிறதைப் பூமியிலுள்ள சகல கோத்திரத்தாரும் கண்டு புலம்புவார்கள்.
	<br /><br />
	31. வலுவாய்த் தொனிக்கும் எக்காள சத்தத்தோடே அவர் தமது தூதர்களை அனுப்புவார்; அவர்கள் அவரால் தெரிந்துகொள்ளப்பட்டவர்களை வானத்தின் ஒரு முனை முதற்கொண்டு மறுமுனைமட்டும் நாலு திசைகளிலுமிருந்து கூட்டிச் சேர்ப்பார்கள்.
	<br /><br />
	32. அத்திமரத்தினால் ஒரு உவமையைக் கற்றுக்கொள்ளுங்கள்; அதிலே இளங்கிளை தோன்றி, துளிர்விடும்போது, வசந்தகாலம் சமீபமாயிற்று என்று அறிவீர்கள்.
	<br /><br />
	33. அப்படியே இவைகளையெல்லாம் நீங்கள் காணும்போது, அவர் சமீபமாய் வாசலருகே வந்திருக்கிறார் என்று அறியுங்கள்.
	<br /><br />
	34. இவைகளெல்லாம் சம்பவிக்குமுன்னே இந்தச் சந்ததி ஒழிந்துபோகாதென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	35. வானமும் பூமியும் ஒழிந்துபோம், என் வார்த்தைகளோ ஒழிந்துபோவதில்லை.
	<br /><br />
	36. அந்த நாளையும் அந்த நாழிகையையும் என் பிதா ஒருவர் தவிர மற்றொருவனும் அறியான்; பரலோகத்திலுள்ள தூதர்களும் அறியார்கள்.
	<br /><br />
	37. நோவாவின் காலத்தில் எப்படி நடந்ததோ, அப்படியே மனுஷகுமாரன் வரும் காலத்திலும் நடக்கும்.
	<br /><br />
	38. எப்படியெனில், ஜலப்பிரளயத்துக்கு முன்னான காலத்திலே நோவா பேழைக்குள் பிரவேசிக்கும் நாள்வரைக்கும், ஜனங்கள் புசித்தும் குடித்தும், பெண்கொண்டும் பெண்கொடுத்தும்,
	<br /><br />
	39. ஜலப்பிரளயம் வந்து அனைவரையும் வாரிக்கொண்டுபோகுமட்டும் உணராதிருந்தார்கள்; அப்படியே மனுஷகுமாரன் வருங்காலத்திலும் நடக்கும்.
	<br /><br />
	40. அப்பொழுது, இரண்டுபேர் வயலில் இருப்பார்கள்; ஒருவன் ஏற்றுக்கொள்ளப்படுவான், ஒருவன் கைவிடப்படுவான்.
	<br /><br />
	41. இரண்டு ஸ்திரீகள் ஏந்திரம் அரைத்துக்கொண்டிருப்பார்கள்; ஒருத்தி ஏற்றுக்கொள்ளப்படுவாள், ஒருத்தி கைவிடப்படுவாள்.
	<br /><br />
	42. உங்கள் ஆண்டவர் இன்ன நாழிகையிலே வருவாரென்று நீங்கள் அறியாதிருக்கிறபடியினால் விழித்திருங்கள்.
	<br /><br />
	43. திருடன் இன்ன ஜாமத்தில் வருவானென்று வீட்டெஜமான் அறிந்திருந்தால், அவன் விழித்திருந்து, தன் வீட்டைக் கன்னமிடவொட்டானென்று அறிவீர்கள்.
	<br /><br />
	44. நீங்கள் நினையாத நாழிகையிலே மனுஷகுமாரன் வருவார்; ஆதலால், நீங்களும் ஆயத்தமாயிருங்கள்.
	<br /><br />
	45. ஏற்றவேளையிலே தன் வேலைக்காரருக்குப் போஜனங்கொடுத்து அவர்களை விசாரிக்கும்படி எஜமான் வைத்த உண்மையும் விவேகமுமுள்ள ஊழியக்காரன் யாவன்?
	<br /><br />
	46. எஜமான் வரும்போது அப்படிச் செய்கிறவனாகக் காணப்படுகிற ஊழியக்காரனே பாக்கியவான்.
	<br /><br />
	47. தன் ஆஸ்திகள் எல்லாவற்றின்மேலும் அவனை விசாரணைக்காரனாக வைப்பானென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	48. அந்த ஊழியக்காரனோ பொல்லாதவனாயிருந்து: என் ஆண்டவன் வர நாள் செல்லும் என்று தன் உள்ளத்திலே சொல்லிக்கொண்டு,
	<br /><br />
	49. தன் உடன்வேலைக்காரரை அடிக்கத் தொடங்கி, வெறியரோடே புசிக்கவும் குடிக்கவும் தலைப்பட்டால்,
	<br /><br />
	50. அந்த ஊழியக்காரன் நினையாத நாளிலும், அறியாத நாழிகையிலும், அவனுடைய எஜமான் வந்து,
	<br /><br />
	51. அவனைக் கடினமாய்த் தண்டித்து, மாயக்காரரோடே அவனுக்குப் பங்கை நியமிப்பான்; அங்கே அழுகையும் பற்கடிப்பும் உண்டாயிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew24