import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 8 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு, அவர் பட்டணங்கள்தோறும் கிராமங்கள்தோறும் பிரயாணம்பண்ணி, தேவனுடைய ராஜ்யத்திற்குரிய நற்செய்தியைக் கூறிப் பிரசங்கித்துவந்தார். பன்னிருவரும் அவருடனேகூட இருந்தார்கள்.
	<br /><br />
	2. அவர் பொல்லாத ஆவிகளையும் வியாதிகளையும் நீக்கிக் குணமாக்கின சில ஸ்திரீகளும், ஏழு பிசாசுகள் நீங்கின மகதலேனாள் என்னப்பட்ட மரியாளும்,
	<br /><br />
	3. ஏரோதின் காரியக்காரனான கூசாவின் மனைவியாகிய யோவன்னாளும், சூசன்னாளும், தங்கள் ஆஸ்திகளால் அவருக்கு ஊழியஞ்செய்துகொண்டுவந்த மற்ற அநேகம் ஸ்திரீகளும் அவருடனே இருந்தார்கள்.
	<br /><br />
	4. சகல பட்டணங்களிலுமிருந்து திரளான ஜனங்கள் அவரிடத்தில் வந்து கூடினபோது, அவர் உவமையாகச் சொன்னது:
	<br /><br />
	5. விதைக்கிறவன் ஒருவன் விதையை விதைக்கப் புறப்பட்டான்; அவன் விதைக்கையில் சில விதை வழியருகே விழுந்து மிதியுண்டது, ஆகாயத்துப் பறவைகள் வந்து அதைப் பட்சித்துப்போட்டது.
	<br /><br />
	6. சில விதை கற்பாறையின்மேல் விழுந்தது; அது முளைத்தபின் அதற்கு ஈரமில்லாததினால் உலர்ந்துபோயிற்று.
	<br /><br />
	7. சில விதை முள்ளுள்ள இடங்களில் விழுந்தது; முள் கூட வளர்ந்து, அதை நெருக்கிப்போட்டது.
	<br /><br />
	8. சில விதை நல்ல நிலத்தில் விழுந்தது, அது முளைத்து, ஒன்று நூறாகப் பலன் கொடுத்தது என்றார். இவைகளைச் சொல்லி, கேட்கிறதற்குக் காதுள்ளவன் கேட்கக்கடவன் என்று சத்தமிட்டுக் கூறினார்.
	<br /><br />
	9. அப்பொழுது அவருடைய சீஷர்கள், இந்த உவமையின் கருத்து என்னவென்று அவரிடத்தில் கேட்டார்கள்.
	<br /><br />
	10. அதற்கு அவர்: தேவனுடைய ராஜ்யத்தின் இரகசியங்களை அறியும்படி உங்களுக்கு அருளப்பட்டது; மற்றவர்களுக்கோ, அவர்கள் கண்டும் காணாதவர்களாகவும், கேட்டும் உணராதவர்களாகவும் இருக்கத்தக்கதாக, அவைகள் உவமைகளாகச் சொல்லப்படுகிறது.
	<br /><br />
	11. அந்த உவமையின் கருத்தாவது: விதை தேவனுடைய வசனம்.
	<br /><br />
	12. வழியருகே விதைக்கப்பட்டவர்கள் வசனத்தைக் கேட்கிறவர்களாயிருக்கிறார்கள்; அவர்கள் விசுவாசித்து இரட்சிக்கப்படாதபடிக்குப் பிசாசானவன் வந்து, அவ்வசனத்தை அவர்கள் இருதயத்திலிருந்து எடுத்துப்போடுகிறான்.
	<br /><br />
	13. கற்பாறையின்மேல் விதைக்கப்பட்டவர்கள் கேட்கும்போது சந்தோஷத்துடனே வசனத்தை ஏற்றுக்கொள்ளுகிறார்கள்; ஆயினும் தங்களுக்குள்ளே வேர்கொள்ளாதபடியினாலே, கொஞ்சக்காலமாத்திரம் விசுவாசித்து, சோதனை காலத்தில் பின்வாங்கிப்போகிறார்கள்.
	<br /><br />
	14. முள்ளுள்ள இடங்களில் விதைக்கப்பட்டவர்கள் வசனத்தைக் கேட்கிறவர்களாயிருக்கிறார்கள்; கேட்டவுடனே போய், பிரபஞ்சத்திற்குரிய கவலைகளினாலும் ஐசுவரியத்தினாலும் சிற்றின்பங்களினாலும் நெருக்கப்பட்டு, பலன்கொடாதிருக்கிறார்கள்.
	<br /><br />
	15. நல்ல நிலத்தில் விதைக்கப்பட்டவர்கள் வசனத்தைக் கேட்டு, அதை உண்மையும் நன்மையுமான இருதயத்திலே காத்துப் பொறுமையுடனே பலன்கொடுக்கிறவர்களாயிருக்கிறார்கள்.
	<br /><br />
	16. ஒருவனும் விளக்கைக் கொளுத்தி, அதை ஒரு பாத்திரத்தினாலே மூடவுமாட்டான், கட்டிலின்கீழே வைக்கவுமாட்டான்; உள்ளே பிரவேசிக்கிறவர்கள் அதின் வெளிச்சத்தைக் காணும்படிக்கு அதை விளக்குத்தண்டின்மேல் வைப்பான்.
	<br /><br />
	17. வெளியரங்கமாகாத இரகசியமுமில்லை, அறியப்பட்டு வெளிக்குவராத மறைபொருளுமில்லை.
	<br /><br />
	18. ஆதலால் நீங்கள் கேட்கிற விதத்தைக்குறித்துக் கவனியுங்கள்; உள்ளவனெவனோ அவனுக்குக் கொடுக்கப்படும்; இல்லாதவனெவனோ அவன் தனக்குண்டென்று நினைக்கிறதும் அவனிடத்திலிருந்து எடுத்துக்கொள்ளப்படும் என்றார்.
	<br /><br />
	19. அப்பொழுது அவருடைய தாயாரும் சகோதரரும் அவரிடத்தில் வந்தார்கள்; ஜனக்கூட்டத்தினாலே அவரண்டையில் அவர்கள் சேரக்கூடாதிருந்தது.
	<br /><br />
	20. அப்பொழுது: உம்முடைய தாயாரும் உம்முடைய சகோதரரும் உம்மைப் பார்க்கவேண்டுமென்று வெளியே நிற்கிறார்கள் என்று அவருக்கு அறிவித்தார்கள்.
	<br /><br />
	21. அதற்கு அவர்: தேவனுடைய வசனத்தைக் கேட்டு, அதின்படி செய்கிறவர்களே எனக்குத் தாயும் எனக்குச் சகோதரருமாயிருக்கிறார்கள் என்றார்.
	<br /><br />
	22. பின்பு ஒருநாள் அவர் தமது சீஷரோடுங்கூடப் படவில் ஏறி: கடலின் அக்கரைக்குப் போவோம் வாருங்கள் என்றார்; அப்படியே புறப்பட்டுப்போனார்கள்.
	<br /><br />
	23. படவு ஓடுகையில் அவர் நித்திரையாயிருந்தார். அப்பொழுது கடலிலே சுழல்காற்றுண்டானதால், அவர்கள் மோசமடையத்தக்கதாய்ப் படவு ஜலத்தினால் நிறைந்தது.
	<br /><br />
	24. அவர்கள் அவரிடத்தில் வந்து, ஐயரே, ஐயரே, மடிந்துபோகிறோம் என்று அவரை எழுப்பினார்கள்; அவர் எழுந்து, காற்றையும் ஜலத்தின் கொந்தளிப்பையும் அதட்டினார்; உடனே அவைகள் நின்றுபோய், அமைதலுண்டாயிற்று.
	<br /><br />
	25. அவர் அவர்களை நோக்கி: உங்கள் விசுவாசம் எங்கே என்றார். அவர்கள் பயந்து ஆச்சரியப்பட்டு: இவர் யாரோ, காற்றுக்கும் ஜலத்துக்கும் கட்டளையிடுகிறார், அவைகளும் இவருக்குக் கீழ்ப்படிகிறதே என்று ஒருவரோடொருவர் சொல்லிக்கொண்டார்கள்.
	<br /><br />
	26. பின்பு கலிலேயாவுக்கு எதிரான கதரேனருடைய நாட்டில் சேர்ந்தார்கள்.
	<br /><br />
	27. அவர் கரையிலிறங்கினபோது, நெடுநாளாய்ப் பிசாசுகள் பிடித்தவனும், வஸ்திரந்தரியாதவனும், வீட்டில் தங்காமல் பிரேதக் கல்லறைகளிலே தங்கினவனுமாயிருந்த அந்தப் பட்டணத்து மனுஷன் ஒருவன் அவருக்கு எதிராக வந்தான்.
	<br /><br />
	28. அவன் இயேசுவைக் கண்டபோது கூக்குரலிட்டு, அவருக்கு முன்பாக விழுந்து: இயேசுவே, உன்னதமான தேவனுடைய குமாரனே, எனக்கும் உமக்கும் என்ன? என்னை வேதனைப்படுத்தாதபடிக்கு உம்மை வேண்டிக்கொள்ளுகிறேன் என்று மகா சத்தத்தோடே சொன்னான்.
	<br /><br />
	29. அந்த அசுத்தஆவி அவனை விட்டுப் போகும்படி இயேசு கட்டளையிட்டபடியினாலே அப்படிச் சொன்னான். அந்த அசுத்தஆவி வெகுகாலமாய் அவனைப் பிடித்திருந்தது; அவன் சங்கிலிகளினாலும் விலங்குகளினாலும் கட்டுண்டு காவல்பண்ணப்பட்டிருந்தும் கட்டுகளை முறித்துப்போட்டுப் பிசாசினால் வனாந்தரங்களுக்குத் துரத்தப்பட்டிருந்தான்.
	<br /><br />
	30. இயேசு அவனை நோக்கி: உன் பேர் என்னவென்று கேட்டார்; அதற்கு அவன்: லேகியோன் என்றான்; அநேகம் பிசாசுகள் அவனுக்குள் புகுந்திருந்தபடியால் அந்தப் பேரைச் சொன்னான்.
	<br /><br />
	31. தங்களைப் பாதாளத்திலே போகக் கட்டளையிடாதபடிக்கு அவைகள் அவரை வேண்டிக்கொண்டன.
	<br /><br />
	32. அவ்விடத்தில் அநேகம் பன்றிகள் கூட்டமாய் மலையிலே மேய்ந்துகொண்டிருந்தது. அந்தப் பன்றிகளுக்குள் போகும்படி தங்களுக்கு உத்தரவு கொடுக்கவேண்டும் என்று அவரை வேண்டிக்கொண்டன; அவைகளுக்கு உத்தரவுகொடுத்தார்.
	<br /><br />
	33. அப்படியே பிசாசுகள் அந்த மனுஷனைவிட்டு நீங்கி, பன்றிகளுக்குள் புகுந்தன; அப்பொழுது அந்தப் பன்றிக்கூட்டம் உயர்ந்த மேட்டிலிருந்து கடலிலே பாய்ந்து, அமிழ்ந்து, மாண்டது.
	<br /><br />
	34. அவைகளை மேய்த்தவர்கள் சம்பவித்ததைக் கண்டு, ஓடிப்போய், பட்டணத்திலும் சுற்றுப்புறங்களிலும் அறிவித்தார்கள்.
	<br /><br />
	35. அப்பொழுது, சம்பவித்ததைப் பார்க்கும்படி ஜனங்கள் புறப்பட்டு, இயேசுவினிடத்தில் வந்து, பிசாசுகள் விட்டுப்போன மனுஷன் வஸ்திரந்தரித்து இயேசுவின் பாதத்தருகே உட்கார்ந்து புத்திதெளிந்திருக்கிறதைக் கண்டு, பயந்தார்கள்.
	<br /><br />
	36. பிசாசுகள் பிடித்திருந்தவன் சொஸ்தமாக்கப்பட்டதைக் கண்டவர்களும் அதை அவர்களுக்கு அறிவித்தார்கள்.
	<br /><br />
	37. அப்பொழுது கதரேனருடைய சுற்றுப்புறத்திலுள்ள திரளான ஜனங்கள் எல்லாரும் மிகவும் பயமடைந்தபடியினாலே, தங்களை விட்டுப் போகும்படி அவரை வேண்டிக்கொண்டார்கள். அந்தப்படி அவர் படவில் ஏறி, திரும்பிப்போனார்.
	<br /><br />
	38. பிசாசுகள் நீங்கின மனுஷன் அவரோடேகூட இருக்கும்படி உத்தரவு கேட்டான்.
	<br /><br />
	39. இயேசு அவனை நோக்கி: நீ உன் வீட்டுக்குத் திரும்பிப்போய், தேவன் உனக்குச் செய்தவைகளையெல்லாம் அறிவி என்று சொல்லி, அவனை அனுப்பிவிட்டார். அந்தப்படி அவன் போய், இயேசு தனக்குச் செய்தவைகளையெல்லாம் பட்டணத்தில் எங்கும் பிரசித்தப்படுத்தினான்.
	<br /><br />
	40. இயேசு திரும்பிவந்தபோது ஜனங்களெல்லாரும் அவருக்காகக் காத்திருந்தபடியால் அவரைச் சந்தோஷமாய் ஏற்றுக்கொண்டார்கள்.
	<br /><br />
	41. அப்பொழுது ஜெபஆலயத்தலைவனாகிய யவீரு என்னும் பேருள்ள ஒருவன் வந்து, இயேசுவின் பாதத்தில் விழுந்து, பன்னிரண்டு வயதுள்ள தன்னுடைய ஒரே குமாரத்தி மரண அவஸ்தையாயிருந்தபடியால்,
	<br /><br />
	42. தன் வீட்டிற்கு வரும்படி அவரை வேண்டிக்கொண்டான். அவர் போகையில் திரளான ஜனங்கள் அவரை நெருக்கினார்கள்.
	<br /><br />
	43. அப்பொழுது பன்னிரண்டு வருஷமாய்ப் பெரும்பாடுள்ளவளாயிருந்து, தன் ஆஸ்திகளையெல்லாம் வைத்தியர்களுக்குச் செலவழித்தும், ஒருவனாலும் சொஸ்தமாக்கப்படாதிருந்த ஒரு ஸ்திரீ,
	<br /><br />
	44. அவருக்குப் பின்னாக வந்து, அவருடைய வஸ்திரத்தின் ஓரத்தைத் தொட்டாள்; உடனே அவளுடைய பெரும்பாடு நின்றுபோயிற்று.
	<br /><br />
	45. அப்பொழுது இயேசு: என்னைத் தொட்டது யார் என்று கேட்டார். எங்களுக்குத் தெரியாதென்று எல்லாரும் சொன்னபோது, பேதுருவும் அவனுடனே கூட இருந்தவர்களும்: ஐயரே, திரளான ஜனங்கள் உம்மைச் சூழ்ந்து நெருக்கிக் கொண்டிருக்கிறார்களே, என்னைத் தொட்டது யார் என்று எப்படிக் கேட்கிறீர் என்றார்கள்.
	<br /><br />
	46. அதற்கு இயேசு: என்னிலிருந்து வல்லமை புறப்பட்டதை அறிந்திருக்கிறேன்; ஆதலால் ஒருவர் என்னைத் தொட்டதுண்டு என்றார்.
	<br /><br />
	47. அப்பொழுது அந்த ஸ்திரீ தான் மறைந்திருக்கவில்லையென்று கண்டு, நடுங்கிவந்து, அவர் முன்பாக விழுந்து, தான் அவரைத் தொட்ட காரணத்தையும் உடனே தான் சொஸ்தமானதையும் எல்லா ஜனங்களுக்கும் முன்பாக அவருக்கு அறிவித்தாள்.
	<br /><br />
	48. அவர் அவளைப் பார்த்து: மகளே, திடன்கொள், உன் விசுவாசம் உன்னை இரட்சித்தது, சமாதானத்தோடே போ என்றார்.
	<br /><br />
	49. அவர் இப்படிப் பேசிக்கொண்டிருக்கையில், ஜெபஆலயத்தலைவனுடைய வீட்டிலிருந்து ஒருவன் வந்து, அவனை நோக்கி: உம்முடைய குமாரத்தி மரித்துப்போனாள், போதகரை வருத்தப்படுத்த வேண்டாம் என்றான்.
	<br /><br />
	50. இயேசு அதைக் கேட்டு: பயப்படாதே; விசுவாசமுள்ளவனாயிரு, அப்பொழுது அவள் இரட்சிக்கப்படுவாள் என்றார்.
	<br /><br />
	51. அவர் வீட்டில் வந்தபோது, பேதுருவையும் யாக்கோபையும் யோவானையும் பெண்ணின் தகப்பனையும் தாயையும் தவிர வேறொருவரையும் உள்ளே வரவொட்டாமல்,
	<br /><br />
	52. எல்லாரும் அழுது அவளைக்குறித்துத் துக்கங்கொண்டாடுகிறதைக் கண்டு: அழாதேயுங்கள், அவள் மரித்துப்போகவில்லை, நித்திரையாயிருக்கிறாள் என்றார்.
	<br /><br />
	53. அவள் மரித்துப்போனாளென்று அவர்கள் அறிந்ததினால், அவரைப் பார்த்து நகைத்தார்கள்.
	<br /><br />
	54. எல்லாரையும் அவர் வெளியே போகப்பண்ணி, அவளுடைய கையைப் பிடித்து: பிள்ளையே எழுந்திரு என்றார்.
	<br /><br />
	55. அப்பொழுது அவள் உயிர் திரும்ப வந்தது; உடனே அவள் எழுந்திருந்தாள்; அவளுக்கு ஆகாரங்கொடுக்கக் கட்டளையிட்டார்.
	<br /><br />
	56. அவள் தாய்தகப்பன்மார் ஆச்சரியப்பட்டார்கள். அப்பொழுது நடந்ததை ஒருவருக்கும் சொல்லாமலிருக்கும்படி அவர்களுக்குக் கட்டளையிட்டார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	The Parable of the Sower
</h2>
<p>
	<br />
	1 After this, Jesus traveled about from one town and village to another, proclaiming the good news of the kingdom of God. The Twelve were with him,
	<br />
	2 and also some women who had been cured of evil spirits and diseases: Mary (called Magdalene) from whom seven demons had come out;
	<br />
	3 Joanna the wife of Chuza, the manager of Herod&rsquo;s household; Susanna; and many others. These women were helping to support them out of their own means.
	<br />
	4 While a large crowd was gathering and people were coming to Jesus from town after town, he told this parable:
	<br />
	5 &ldquo;A farmer went out to sow his seed. As he was scattering the seed, some fell along the path; it was trampled on, and the birds ate it up.
	<br />
	6 Some fell on rocky ground, and when it came up, the plants withered because they had no moisture.
	<br />
	7 Other seed fell among thorns, which grew up with it and choked the plants.
	<br />
	8 Still other seed fell on good soil. It came up and yielded a crop, a hundred times more than was sown.&rdquo;When he said this, he called out, &ldquo;Whoever has ears to hear, let them hear.&rdquo;
	<br />
	9 His disciples asked him what this parable meant.
	<br />
	10 He said, &ldquo;The knowledge of the secrets of the kingdom of God has been given to you, but to others I speak in parables, so that, &ldquo; &lsquo;though seeing, they may not see; though hearing, they may not understand.&rsquo;
	<br />
	11 &ldquo;This is the meaning of the parable: The seed is the word of God.
	<br />
	12 Those along the path are the ones who hear, and then the devil comes and takes away the word from their hearts, so that they may not believe and be saved.
	<br />
	13 Those on the rocky ground are the ones who receive the word with joy when they hear it, but they have no root. They believe for a while, but in the time of testing they fall away.
	<br />
	14 The seed that fell among thorns stands for those who hear, but as they go on their way they are choked by life&rsquo;s worries, riches and pleasures, and they do not mature.
	<br />
	15 But the seed on good soil stands for those with a noble and good heart, who hear the word, retain it, and by persevering produce a crop.
</p>
<h2>
	<br />
	A Lamp on a Stand
</h2>
<p>
	<br />
	16 &ldquo;No one lights a lamp and hides it in a clay jar or puts it under a bed. Instead, they put it on a stand, so that those who come in can see the light.
	<br />
	17 For there is nothing hidden that will not be disclosed, and nothing concealed that will not be known or brought out into the open.
	<br />
	18 Therefore consider carefully how you listen. Whoever has will be given more; whoever does not have, even what they think they have will be taken from them.&rdquo;
</p>
<h2>
	<br />
	Jesus&rsquo; Mother and Brothers
</h2>
<p>
	<br />
	19 Now Jesus&rsquo; mother and brothers came to see him, but they were not able to get near him because of the crowd.
	<br />
	20 Someone told him, &ldquo;Your mother and brothers are standing outside, wanting to see you.&rdquo;
	<br />
	21 He replied, &ldquo;My mother and brothers are those who hear God&rsquo;s word and put it into practice.&rdquo;
</p>
<h2>
	<br />
	Jesus Calms the Storm
</h2>
<p>
	<br />
	22 One day Jesus said to his disciples, &ldquo;Let us go over to the other side of the lake.&rdquo; So they got into a boat and set out.
	<br />
	23 As they sailed, he fell asleep. A squall came down on the lake, so that the boat was being swamped, and they were in great danger.
	<br />
	24 The disciples went and woke him, saying, &ldquo;Master, Master, we&rsquo;re going to drown!&rdquo; He got up and rebuked the wind and the raging waters; the storm subsided, and all was calm.
	<br />
	25 &ldquo;Where is your faith?&rdquo; he asked his disciples. In fear and amazement they asked one another, &ldquo;Who is this? He commands even the winds and the water, and they obey him.&rdquo;
</p>
<h2>
	<br />
	Jesus Restores a Demon-Possessed Man
</h2>
<p>
	<br />
	26 They sailed to the region of the Gerasenes, which is across the lake from Galilee.
	<br />
	27 When Jesus stepped ashore, he was met by a demon-possessed man from the town. For a long time this man had not worn clothes or lived in a house, but had lived in the tombs.
	<br />
	28 When he saw Jesus, he cried out and fell at his feet, shouting at the top of his voice, &ldquo;What do you want with me, Jesus, Son of the Most High God? I beg you, don&rsquo;t torture me!&rdquo;
	<br />
	29 For Jesus had commanded the impure spirit to come out of the man. Many times it had seized him, and though he was chained hand and foot and kept under guard, he had broken his chains and had been driven by the demon into solitary places.
	<br />
	30 Jesus asked him, &ldquo;What is your name?&rdquo;&ldquo;Legion,&rdquo; he replied, because many demons had gone into him.
	<br />
	31 And they begged Jesus repeatedly not to order them to go into the Abyss.
	<br />
	32 A large herd of pigs was feeding there on the hillside. The demons begged Jesus to let them go into the pigs, and he gave them permission.
	<br />
	33 When the demons came out of the man, they went into the pigs, and the herd rushed down the steep bank into the lake and was drowned.
	<br />
	34 When those tending the pigs saw what had happened, they ran off and reported this in the town and countryside,
	<br />
	35 and the people went out to see what had happened. When they came to Jesus, they found the man from whom the demons had gone out, sitting at Jesus&rsquo; feet, dressed and in his right mind; and they were afraid.
	<br />
	36 Those who had seen it told the people how the demon-possessed man had been cured.
	<br />
	37 Then all the people of the region of the Gerasenes asked Jesus to leave them, because they were overcome with fear. So he got into the boat and left.
	<br />
	38 The man from whom the demons had gone out begged to go with him, but Jesus sent him away, saying,
	<br />
	39 &ldquo;Return home and tell how much God has done for you.&rdquo; So the man went away and told all over town how much Jesus had done for him.
</p>
<h2>
	<br />
	Jesus Raises a Dead Girl and Heals a Sick Woman
</h2>
<p>
	<br />
	40 Now when Jesus returned, a crowd welcomed him, for they were all expecting him.
	<br />
	41 Then a man named Jairus, a synagogue leader, came and fell at Jesus&rsquo; feet, pleading with him to come to his house
	<br />
	42 because his only daughter, a girl of about twelve, was dying. As Jesus was on his way, the crowds almost crushed him.
	<br />
	43 And a woman was there who had been subject to bleeding for twelve years, but no one could heal her.
	<br />
	44 She came up behind him and touched the edge of his cloak, and immediately her bleeding stopped.
	<br />
	45 &ldquo;Who touched me?&rdquo; Jesus asked. When they all denied it, Peter said, &ldquo;Master, the people are crowding and pressing against you.&rdquo;
	<br />
	46 But Jesus said, &ldquo;Someone touched me; I know that power has gone out from me.&rdquo;
	<br />
	47 Then the woman, seeing that she could not go unnoticed, came trembling and fell at his feet. In the presence of all the people, she told why she had touched him and how she had been instantly healed.
	<br />
	48 Then he said to her, &ldquo;Daughter, your faith has healed you. Go in peace.&rdquo;
	<br />
	49 While Jesus was still speaking, someone came from the house of Jairus, the synagogue leader. &ldquo;Your daughter is dead,&rdquo; he said. &ldquo;Don&rsquo;t bother the teacher anymore.&rdquo;
	<br />
	50 Hearing this, Jesus said to Jairus, &ldquo;Don&rsquo;t be afraid; just believe, and she will be healed.&rdquo;
	<br />
	51 When he arrived at the house of Jairus, he did not let anyone go in with him except Peter, John and James, and the child&rsquo;s father and mother.
	<br />
	52 Meanwhile, all the people were wailing and mourning for her. &ldquo;Stop wailing,&rdquo; Jesus said. &ldquo;She is not dead but asleep.&rdquo;
	<br />
	53 They laughed at him, knowing that she was dead.
	<br />
	54 But he took her by the hand and said, &ldquo;My child, get up!&rdquo;
	<br />
	55 Her spirit returned, and at once she stood up. Then Jesus told them to give her something to eat.
	<br />
	56 Her parents were astonished, but he ordered them not to tell anyone what had happened.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke8