import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 19 </title>
	<meta
          name="description"
          content="Matthew 19 | மத்தேயு 19 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு இந்த வசனங்களைச் சொல்லி முடித்தபின்பு, அவர் கலிலேயாவை விட்டு யோர்தானுக்கு அக்கரையான யூதேயாவின் எல்லைகளில் வந்தார்.
	<br /><br />
	2. திரளான ஜனங்கள் அவருக்குப் பின்சென்றார்கள்; அவ்விடத்தில் அவர்களைச் சொஸ்தமாக்கினார்.
	<br /><br />
	3. அப்பொழுது, பரிசேயர் அவரைச் சோதிக்கவேண்டுமென்று அவரிடத்தில் வந்து: புருஷனானவன் தன் மனைவியை எந்த முகாந்தரத்தினாலாகிலும் தள்ளிவிடுவது நியாயமா என்று கேட்டார்கள்.
	<br /><br />
	4. அவர்களுக்கு அவர் பிரதியுத்தரமாக: ஆதியிலே மனுஷரை உண்டாக்கினவர் அவர்களை ஆணும் பெண்ணுமாக உண்டாக்கினார் என்பதையும்,
	<br /><br />
	5. இதினிமித்தம் புருஷனானவன் தன் தகப்பனையும் தாயையும் விட்டுத் தன் மனைவியோடே இசைந்திருப்பான்; அவர்கள் இருவரும் ஒரே மாம்சமாயிருப்பார்கள் என்று அவர் சொன்னதையும், நீங்கள் வாசிக்கவில்லையா?
	<br /><br />
	6. இப்படி இருக்கிறபடியினால், அவர்கள் இருவராயிராமல், ஒரே மாம்சமாயிருக்கிறார்கள்; ஆகையால், தேவன் இணைத்ததை மனுஷன் பிரிக்காதிருக்கக்கடவன் என்றார்.
	<br /><br />
	7. அதற்கு அவர்கள்: அப்படியானால், தள்ளுதற்சீட்டைக் கொடுத்து, அவளைத் தள்ளிவிடலாமென்று மோசே ஏன் கட்டளையிட்டார் என்றார்கள்.
	<br /><br />
	8. அதற்கு அவர்: உங்கள் மனைவிகளைத் தள்ளிவிடலாமென்று உங்கள் இருதயகடினத்தினிமித்தம் மோசே உங்களுக்கு இடங்கொடுத்தார்; ஆதிமுதலாய் அப்படியிருக்கவில்லை.
	<br /><br />
	9. ஆதலால், எவனாகிலும் தன் மனைவி வேசித்தனஞ்செய்ததினிமித்தமேயன்றி, அவளைத் தள்ளிவிட்டு வேறொருத்தியை விவாகம்பண்ணினால், அவன் விபசாரஞ்செய்கிறவனாயிருப்பான்; தள்ளிவிடப்பட்டவளை விவாகம்பண்ணுகிறவனும் விபசாரஞ்செய்கிறவனாயிருப்பான் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	10. அவருடைய சீஷர்கள் அவரை நோக்கி: மனைவியைப்பற்றிப் புருஷனுடைய காரியம் இப்படியிருந்தால், விவாகம்பண்ணுகிறது நல்லதல்ல என்றார்கள்.
	<br /><br />
	11. அதற்கு அவர்: வரம்பெற்றவர்களே தவிர மற்றவர்கள் இந்த வசனத்தை ஏற்றுக்கொள்ளமாட்டார்கள்.
	<br /><br />
	12. தாயின் வயிற்றிலிருந்து அண்ணகர்களாய்ப் பிறந்தவர்களும் உண்டு; மனுஷர்களால் அண்ணகர்களாக்கப்பட்டவர்களும் உண்டு; பரலோகராஜ்யத்தினிமித்தம் தங்களை அண்ணகர்களாக்கிக்கொண்டவர்களும் உண்டு; இதை ஏற்றுக்கொள்ள வல்லவன் ஏற்றுக்கொள்ளக்கடவன் என்றார்.
	<br /><br />
	13. அப்பொழுது, சிறு பிள்ளைகளின்மேல் அவர் கைகளை வைத்து ஜெபம்பண்ணும்படிக்கு அவர்களை அவரிடத்தில் கொண்டுவந்தார்கள்; கொண்டுவந்தவர்களைச் சீஷர்கள் அதட்டினார்கள்.
	<br /><br />
	14. இயேசுவோ: சிறு பிள்ளைகள் என்னிடத்தில் வருகிறதற்கு இடங்கொடுங்கள்; அவர்களைத் தடைபண்ணாதிருங்கள்; பரலோகராஜ்யம் அப்படிப்பட்டவர்களுடையது என்று சொல்லி,
	<br /><br />
	15. அவர்கள்மேல் கைகளை வைத்து, பின்பு அவ்விடம்விட்டுப் புறப்பட்டுப்போனார்.
	<br /><br />
	16. அப்பொழுது ஒருவன் வந்து, அவரை நோக்கி: நல்ல போதகரே, நித்தியஜீவனை அடைவதற்கு நான் எந்த நன்மையைச் செய்யவேண்டும் என்று கேட்டான்.
	<br /><br />
	17. அதற்கு அவர்: நீ என்னை நல்லவன் என்று சொல்வானேன்? தேவன் ஒருவர் தவிர நல்லவன் ஒருவனும் இல்லையே; நீ ஜீவனில் பிரவேசிக்க விரும்பினால் கற்பனைகளைக் கைக்கொள் என்றார்.
	<br /><br />
	18. அவன் அவரை நோக்கி: எவைகளை என்று கேட்டான். அதற்கு இயேசு: கொலை செய்யாதிருப்பாயாக, விபசாரஞ் செய்யாதிருப்பாயாக, களவு செய்யாதிருப்பாயாக, பொய்ச்சாட்சி சொல்லாதிருப்பாயாக;
	<br /><br />
	19. உன் தகப்பனையும் உன் தாயையும் கனம்பண்ணுவாயாக; உன்னிடத்தில் நீ அன்புகூருவதுபோலப் பிறனிடத்திலும் அன்புகூருவாயாக என்பவைகளையே என்றார்.
	<br /><br />
	20. அந்த வாலிபன் அவரை நோக்கி: இவைகளையெல்லாம் என் சிறுவயதுமுதல் கைக்கொண்டிருக்கிறேன்; இன்னும் என்னிடத்தில் குறைவு என்ன என்றான்.
	<br /><br />
	21. அதற்கு இயேசு: நீ பூரண சற்குணனாயிருக்க விரும்பினால், போய், உனக்கு உண்டானவைகளை விற்று, தரித்திரருக்குக் கொடு, அப்பொழுது, பரலோகத்தில் உனக்குப் பொக்கிஷம் உண்டாயிருக்கும்; பின்பு என்னைப் பின்பற்றிவா என்றார்.
	<br /><br />
	22. அந்த வாலிபன் மிகுந்த ஆஸ்தியுள்ளவனாயிருந்தபடியால், இந்த வார்த்தையைக் கேட்டபொழுது, துக்கமடைந்தவனாய்ப் போய்விட்டான்.
	<br /><br />
	23. அப்பொழுது, இயேசு தம்முடைய சீஷர்களை நோக்கி: ஐசுவரியவான் பரலோகராஜ்யத்தில் பிரவேசிப்பது அரிதென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	24. மேலும் ஐசுவரியவான் தேவனுடைய ராஜ்யத்தில் பிரவேசிப்பதைப்பார்க்கிலும், ஒட்டகமானது ஊசியின் காதிலே நுழைவது எளிதாயிருக்கும் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	25. அவருடைய சீஷர்கள் அதைக்கேட்டு மிகவும் ஆச்சரியப்பட்டு: அப்படியானால், யார் ரட்சிக்கப்படக்கூடும் என்றார்கள்.
	<br /><br />
	26. இயேசு, அவர்களைப் பார்த்து: மனுஷரால் இது கூடாததுதான்; தேவனாலே எல்லாம் கூடும் என்றார்.
	<br /><br />
	27. அப்பொழுது, பேதுரு அவரை நோக்கி: இதோ, நாங்கள் எல்லாவற்றையும் விட்டு, உம்மைப் பின்பற்றினோமே; எங்களுக்கு என்ன கிடைக்கும் என்றான்.
	<br /><br />
	28. அதற்கு இயேசு: மறுஜென்மகாலத்திலே மனுஷகுமாரன் தம்முடைய மகிமையுள்ள சிங்காசனத்தின்மேல் வீற்றிருக்கும்போது, என்னைப் பின்பற்றின நீங்களும், இஸ்ரவேலின் பன்னிரண்டு கோத்திரங்களையும் நியாயந்தீர்க்கிறவர்களாகப் பன்னிரண்டு சிங்காசனங்களின்மேல் வீற்றிருப்பீர்கள் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	29. என் நாமத்தினிமித்தம் வீட்டையாவது, சகோதரரையாவது, சகோதரிகளையாவது, தகப்பனையாவது, தாயையாவது, மனைவியையாவது, பிள்ளைகளையாவது, நிலங்களையாவது விட்டவன் எவனோ, அவன் நூறத்தனையாய் அடைந்து, நித்தியஜீவனையும் சுதந்தரித்துக்கொள்ளுவான்;
	<br /><br />
	30. ஆகிலும், முந்தினோர் அநேகர் பிந்தினோராயும், பிந்தினோர் அநேகர் முந்தினோராயும் இருப்பார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew19