import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew25 = () => {
  const verseNumber = 25;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 25 </title>
	<meta
          name="description"
          content="Matthew 25 | மத்தேயு 25 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது, பரலோகராஜ்யம் தங்கள் தீவட்டிகளைப் பிடித்துக்கொண்டு, மணவாளனுக்கு எதிர்கொண்டுபோகப் புறப்பட்ட பத்துக் கன்னிகைகளுக்கு ஒப்பாயிருக்கும்.
	<br /><br />
	2. அவர்களில் ஐந்துபேர் புத்தியுள்ளவர்களும், ஐந்துபேர் புத்தியில்லாதவர்களுமாயிருந்தார்கள்.
	<br /><br />
	3. புத்தியில்லாதவர்கள் தங்கள் தீவட்டிகளை எடுத்துக்கொண்டுபோனார்கள், எண்ணெயையோ கூடக் கொண்டுபோகவில்லை.
	<br /><br />
	4. புத்தியுள்ளவர்கள் தங்கள் தீவட்டிகளோடுங்கூடத் தங்கள் பாத்திரங்களில் எண்ணெயையும் கொண்டுபோனார்கள்.
	<br /><br />
	5. மணவாளன் வரத் தாமதித்தபோது, அவர்கள் எல்லாரும் நித்திரைமயக்கமடைந்து தூங்கிவிட்டார்கள்.
	<br /><br />
	6. நடுராத்திரியிலே: இதோ, மணவாளன் வருகிறார், அவருக்கு எதிர்கொண்டுபோகப் புறப்படுங்கள் என்கிற சத்தம் உண்டாயிற்று.
	<br /><br />
	7. அப்பொழுது, அந்தக் கன்னிகைகள் எல்லாரும் எழுந்திருந்து, தங்கள் தீவட்டிகளை ஆயத்தப்படுத்தினார்கள்.
	<br /><br />
	8. புத்தியில்லாதவர்கள் புத்தியுள்ளவர்களை நோக்கி: உங்கள் எண்ணெயில் எங்களுக்குக் கொஞ்சங்கொடுங்கள், எங்கள் தீவட்டிகள் அணைந்துபோகிறதே என்றார்கள்.
	<br /><br />
	9. புத்தியுள்ளவர்கள் பிரதியுத்தரமாக: அப்படியல்ல, எங்களுக்கும் உங்களுக்கும் போதாமலிராதபடி, நீங்கள் விற்கிறவர்களிடத்திற் போய், உங்களுக்காக வாங்கிக்கொள்ளுங்கள் என்றார்கள்.
	<br /><br />
	10. அப்படியே அவர்கள் வாங்கப்போனபோது மணவாளன் வந்துவிட்டார்; ஆயத்தமாயிருந்தவர்கள் அவரோடேகூடக் கலியாண வீட்டுக்குள் பிரவேசித்தார்கள்; கதவும் அடைக்கப்பட்டது.
	<br /><br />
	11. பின்பு, மற்றக் கன்னிகைகளும் வந்து: ஆண்டவரே, ஆண்டவரே, எங்களுக்குத் திறக்கவேண்டும் என்றார்கள்.
	<br /><br />
	12. அதற்கு அவர்: உங்களை அறியேன் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	13. மனுஷகுமாரன் வரும் நாளையாவது நாழிகையையாவது நீங்கள் அறியாதிருக்கிறபடியால் விழித்திருங்கள்.
	<br /><br />
	14. அன்றியும், பரலோகராஜ்யம் புறத்தேசத்துக்குப் பிரயாணமாய்ப் போகிற ஒரு மனுஷன், தன் ஊழியக்காரரை அழைத்து, தன் ஆஸ்திகளை அவர்கள் வசமாய் ஒப்புக்கொடுத்ததுபோல் இருக்கிறது.
	<br /><br />
	15. அவனவனுடைய திறமைக்குத்தக்கதாக, ஒருவனிடத்தில் ஐந்து தாலந்தும், ஒருவனிடத்தில் இரண்டு தாலந்தும், ஒருவனிடத்தில் ஒரு தாலந்துமாகக் கொடுத்து, உடனே பிரயாணப்பட்டுப் போனான்.
	<br /><br />
	16. ஐந்து தாலந்தை வாங்கினவன் போய், அவைகளைக்கொண்டு வியாபாரம் பண்ணி, வேறு ஐந்து தாலந்தைச் சம்பாதித்தான்.
	<br /><br />
	17. அப்படியே இரண்டு தாலந்தை வாங்கினவனும், வேறு இரண்டு தாலந்தைச் சம்பாதித்தான்.
	<br /><br />
	18. ஒரு தாலந்தை வாங்கினவனோ, போய், நிலத்தைத் தோண்டி, தன் எஜமானுடைய பணத்தைப் புதைத்துவைத்தான்.
	<br /><br />
	19. வெகுகாலமானபின்பு அந்த ஊழியக்காரருடைய எஜமான் திரும்பிவந்து, அவர்களிடத்தில் கணக்குக் கேட்டான்.
	<br /><br />
	20. அப்பொழுது, ஐந்து தாலந்தை வாங்கினவன், வேறு ஐந்து தாலந்தைக் கொண்டுவந்து: ஆண்டவனே, ஐந்து தாலந்தை என்னிடத்தில் ஒப்புவித்தீரே; அவைகளைக்கொண்டு, இதோ, வேறு ஐந்து தாலந்தைச் சம்பாதித்தேன் என்றான்.
	<br /><br />
	21. அவனுடைய எஜமான் அவனை நோக்கி: நல்லது, உத்தமமும் உண்மையுமுள்ள ஊழியக்காரனே, கொஞ்சத்திலே உண்மையாயிருந்தாய், அநேகத்தின்மேல் உன்னை அதிகாரியாக வைப்பேன், உன் எஜமானுடைய சந்தோஷத்திற்குள் பிரவேசி என்றான்.
	<br /><br />
	22. இரண்டு தாலந்தை வாங்கினவனும் வந்து: ஆண்டவனே, இரண்டு தாலந்தை என்னிடத்தில் ஒப்புவித்தீரே; அவைகளைக்கொண்டு, இதோ, வேறு இரண்டு தாலந்தைச் சம்பாதித்தேன் என்றான்.
	<br /><br />
	23. அவனுடைய எஜமான் அவனை நோக்கி: நல்லது, உத்தமமும் உண்மையுமுள்ள ஊழியக்காரனே, கொஞ்சத்திலே உண்மையாயிருந்தாய், அநேகத்தின்மேல் உன்னை அதிகாரியாக வைப்பேன்; உன் எஜமானுடைய சந்தோஷத்திற்குள் பிரவேசி என்றான்.
	<br /><br />
	24. ஒரு தாலந்தை வாங்கினவன் வந்து: ஆண்டவனே, நீர் விதைக்காத இடத்தில் அறுக்கிறவரும், தெளிக்காத இடத்தில் சேர்க்கிறவருமான கடினமுள்ள மனுஷன் என்று அறிவேன்.
	<br /><br />
	25. ஆகையால், நான் பயந்து, போய், உமது தாலந்தை நிலத்தில் புதைத்து வைத்தேன்; இதோ, உம்முடையதை வாங்கிக்கொள்ளும் என்றான்.
	<br /><br />
	26. அவனுடைய எஜமான் பிரதியுத்தரமாக: பொல்லாதவனும் சோம்பனுமான ஊழியக்காரனே, நான் விதைக்காத இடத்தில் அறுக்கிறவனென்றும், தெளிக்காத இடத்தில் சேர்க்கிறவனென்றும் அறிந்திருந்தாயே.
	<br /><br />
	27. அப்படியானால், நீ என் பணத்தைக் காசுக்காரர் வசத்தில் போட்டுவைக்க வேண்டியதாயிருந்தது; அப்பொழுது, நான் வந்து என்னுடையதை வட்டியோடே வாங்கிக்கொள்வேனே, என்று சொல்லி,
	<br /><br />
	28. அவனிடத்திலிருக்கிற தாலந்தை எடுத்து, பத்துத் தாலந்துள்ளவனுக்குக் கொடுங்கள்.
	<br /><br />
	29. உள்ளவனெவனோ அவனுக்குக் கொடுக்கப்படும், பரிபூரணமும் அடைவான்; இல்லாதவனிடத்திலிருந்து உள்ளதும் எடுத்துக்கொள்ளப்படும்.
	<br /><br />
	30. பிரயோஜனமற்ற ஊழியக்காரனாகிய இவனைப் புறம்பான இருளிலே தள்ளிப்போடுங்கள்; அங்கே அழுகையும் பற்கடிப்பும் உண்டாயிருக்கும் என்றான்.
	<br /><br />
	31. அன்றியும் மனுஷகுமாரன் தமது மகிமைபொருந்தினவராய்ச் சகல பரிசுத்த தூதரோடுங்கூட வரும்போது, தமது மகிமையுள்ள சிங்காசனத்தின்மேல் வீற்றிருப்பார்.
	<br /><br />
	32. அப்பொழுது, சகல ஜனங்களும் அவருக்கு முன்பாகச் சேர்க்கப்படுவார்கள். மேய்ப்பனானவன் செம்மறியாடுகளையும் வெள்ளாடுகளையும் வெவ்வேறாகப் பிரிக்கிறதுபோல அவர்களை அவர் பிரித்து,
	<br /><br />
	33. செம்மறியாடுகளைத் தமது வலதுபக்கத்திலும், வெள்ளாடுகளைத் தமது இடதுபக்கத்திலும் நிறுத்துவார்.
	<br /><br />
	34. அப்பொழுது, ராஜா தமது வலது பக்கத்தில் நிற்பவர்களைப் பார்த்து: வாருங்கள் என் பிதாவினால் ஆசீர்வதிக்கப்பட்டவர்களே, உலகம் உண்டானது முதல் உங்களுக்காக ஆயத்தம்பண்ணப்பட்டிருக்கிற ராஜ்யத்தைச் சுதந்தரித்துக்கொள்ளுங்கள்.
	<br /><br />
	35. பசியாயிருந்தேன், எனக்குப் போஜனங்கொடுத்தீர்கள்; தாகமாயிருந்தேன், என் தாகத்தைத் தீர்த்தீர்கள்; அந்நியனாயிருந்தேன், என்னைச் சேர்த்துக்கொண்டீர்கள்;
	<br /><br />
	36. வஸ்திரமில்லாதிருந்தேன், எனக்கு வஸ்திரங்கொடுத்தீர்கள்; வியாதியாயிருந்தேன், என்னை விசாரிக்க வந்தீர்கள்; காவலிலிருந்தேன், என்னைப் பார்க்கவந்தீர்கள் என்பார்.
	<br /><br />
	37. அப்பொழுது, நீதிமான்கள் அவருக்குப் பிரதியுத்தரமாக: ஆண்டவரே, நாங்கள் எப்பொழுது உம்மைப் பசியுள்ளவராகக் கண்டு உமக்குப் போஜனங்கொடுத்தோம்? எப்பொழுது உம்மைத் தாகமுள்ளவராகக்கண்டு உம்முடைய தாகத்தைத் தீர்த்தோம்?
	<br /><br />
	38. எப்பொழுது உம்மை அந்நியராகக்கண்டு உம்மைச் சேர்த்துக்கொண்டோம்? எப்பொழுது உம்மை வஸ்திரமில்லாதவராகக் கண்டு உமக்கு வஸ்திரங்கொடுத்தோம்?
	<br /><br />
	39. எப்பொழுது உம்மை வியாதியுள்ளவராகவும் காவலிலிருக்கிறவராகவும் கண்டு, உம்மிடத்தில் வந்தோம் என்பார்கள்.
	<br /><br />
	40. அதற்கு ராஜா பிரதியுத்தரமாக: மிகவும் சிறியவராகிய என் சகோதரரான இவர்களில் ஒருவனுக்கு நீங்கள் எதைச் செய்தீர்களோ, அதை எனக்கே செய்தீர்கள் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்பார்.
	<br /><br />
	41. அப்பொழுது, இடதுபக்கத்தில் நிற்பவர்களைப் பார்த்து அவர்: சபிக்கப்பட்டவர்களே, என்னைவிட்டு, பிசாசுக்காகவும் அவன் தூதர்களுக்காகவும் ஆயத்தம்பண்ணப்பட்டிருக்கிற நித்திய அக்கினியிலே போங்கள்.
	<br /><br />
	42. பசியாயிருந்தேன், நீங்கள் எனக்குப் போஜனங்கொடுக்கவில்லை; தாகமாயிருந்தேன், நீங்கள் என் தாகத்தைத் தீர்க்கவில்லை;
	<br /><br />
	43. அந்நியனாயிருந்தேன், நீங்கள் என்னைச் சேர்த்துக்கொள்ளவில்லை; வஸ்திரமில்லாதிருந்தேன், நீங்கள் எனக்கு வஸ்திரங்கொடுக்கவில்லை; வியாதியுள்ளவனாயும் காவலிலடைக்கப்பட்டவனாயும் இருந்தேன், நீங்கள் என்னை விசாரிக்க வரவில்லையென்பார்.
	<br /><br />
	44. அப்பொழுது, அவர்களும் அவருக்குப் பிரதியுத்தரமாக: ஆண்டவரே, உம்மைப் பசியுள்ளவராகவும், தாகமுள்ளவராகவும், அந்நியராகவும், வஸ்திரமில்லாதவராகவும், வியாதிப்பட்டவராகவும், காவலிலடைக்கப்பட்டவராகவும் நாங்கள் எப்பொழுது கண்டு, உமக்கு உதவி செய்யாதிருந்தோம் என்பார்கள்.
	<br /><br />
	45. அப்பொழுது அவர் அவர்களுக்குப் பிரதியுத்தரமாக: மிகவும் சிறியவர்களாகிய இவர்களில் ஒருவனுக்கு நீங்கள் எதைச் செய்யாதிருந்தீர்களோ, அதை எனக்கே செய்யாதிருந்தீர்கள் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்பார்.
	<br /><br />
	46. அந்தப்படி, இவர்கள் நித்திய ஆக்கினையை அடையவும், நீதிமான்களோ நித்திய ஜீவனை அடையவும் போவார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew25