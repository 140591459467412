import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 9 </title>
	<meta
          name="description"
          content="Amos 9 | ஆமோஸ் 9 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆண்டவரைப் பலிபீடத்தின்மேல் நிற்கக்கண்டேன்; அவர்: நீ வாசல் நிலைகள் அசையும்படி போதிகையை அடித்து, அவைகளை அவர்கள் எல்லாருடைய தலையின்மேலும் விழ உடைத்துப்போடு; அவர்களுக்குப் பின்னாகவரும் மீதியானவர்களை நான் பட்டயத்தினால் கொன்றுபோடுவேன்; அவர்களில் ஓடுகிறவன் ஒருவனும் தப்புவதுமில்லை, அவர்களில் தப்புகிறவன் ஒருவனும் இரட்சிக்கப்படுவதுமில்லை.
	<br /><br />
	2. அவர்கள் பாதாளபரியந்தம் தோண்டிப் பதுங்கிக்கொண்டாலும், என் கை அவ்விடத்திலிருந்து அவர்களைப் பிடித்துக்கொண்டுவரும்; அவர்கள் வானபரியந்தம் ஏறினாலும், அவ்விடத்திலுமிருந்து அவர்களை இறங்கப்பண்ணுவேன்;
	<br /><br />
	3. அவர்கள் கர்மேலின் கொடுமுடியிலே ஒளித்துக்கொண்டாலும், அங்கே அவர்களைத் தேடிப்பிடிப்பேன்; அவர்கள் சமுத்திரத்தின் ஆழத்திலே போய் என் கண்களுக்கு மறைந்துகொண்டாலும், அங்கே அவர்களைக் கடிக்கப் பாம்புகளுக்குக் கட்டளையிடுவேன்.
	<br /><br />
	4. அவர்கள் தங்கள் சத்துருவுக்கு முன்பாகச் சிறைப்பட்டுப்போனாலும், அங்கே அவர்களைக் கொன்றுபோடப் பட்டயத்துக்கு நான் கட்டளையிட்டு, என் கண்களை அவர்கள்மேல் நன்மைக்கல்ல, தீமைக்கென்றே வைப்பேன்.
	<br /><br />
	5. சேனைகளின் கர்த்தராகிய ஆண்டவர் தேசத்தைத் தொட, அது உருகிப்போம்; அப்பொழுது அதின் குடிகளெல்லாரும் புலம்புவார்கள்; எங்கும் நதியாய்ப் புரண்டோடி, எகிப்தினுடைய ஆற்று வெள்ளத்தைப்போல் வெள்ளமாகும்.
	<br /><br />
	6. அவர் வானத்தில் தமது மேலறைகளைக் கட்டி, பூமியில் தமது கீழறைகளை அஸ்திபாரப்படுத்தி, சமுத்திரத்தின் தண்ணீர்களை வரவழைத்து, அவைகளைப் பூமியினுடைய விசாலத்தின்மேல் ஊற்றுகிறவர்; கர்த்தர் என்பது அவருடைய நாமம்.
	<br /><br />
	7. இஸ்ரவேல் புத்திரரே, நீங்கள் எனக்கு எத்தியோப்பியரின் புத்திரரைப்போல் இருக்கிறீர்கள் அல்லவோ என்று கர்த்தர் சொல்லுகிறார்; நான் இஸ்ரவேலை எகிப்துதேசத்திலிருந்தும், பெலிஸ்தரைக் கப்தோரிலிருந்தும், சீரியரைக் கீரிலிருந்தும் கொண்டுவரவில்லையோ?
	<br /><br />
	8. இதோ, கர்த்தராகிய ஆண்டவரின் கண்கள் பாவமுள்ள ராஜ்யத்துக்கு விரோதமாக வைக்கப்பட்டிருக்கிறது; அதைப் பூமியின்மேல் இராதபடிக்கு அழித்துப்போடுவேன்; ஆகிலும் யாக்கோபின் வம்சத்தை முழுவதும் அழிக்கமாட்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. இதோ, ஜல்லடையினால் சலித்தரிக்கிறதுபோல் இஸ்ரவேல் வம்சத்தாரை எல்லா ஜாதிகளுக்குள்ளும் சலித்தரிக்கும்படிக்கு நான் கட்டளையிடுவேன்; ஆனாலும் ஒரு கோதுமைமணியும் தரையிலே விழுவதில்லை.
	<br /><br />
	10. தீங்கு எங்களை அணுகுவதுமில்லை, எங்களுக்கு நேரிடுவதுமில்லையென்று என் ஜனத்தில் சொல்லுகிற பாவிகளெல்லாரும் பட்டயத்தினால் சாவார்கள்.
	<br /><br />
	11. ஏதோமில் மீதியானவர்களையும், என் நாமம் விளங்கிய சகல ஜாதிகளையும் வசமாக்கிக்கொள்ளும்படிக்கு,
	<br /><br />
	12. அந்நாளிலே விழுந்துபோன தாவீதின் கூடாரத்தை நான் திரும்ப எடுப்பித்து, அதின் திறப்புகளை அடைத்து, அதில் பழுதாய்ப்போனதைச் சீர்ப்படுத்தி, பூர்வநாட்களில் இருந்ததுபோல அதை ஸ்தாபிப்பேன் என்று இதைச் செய்கிற கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. இதோ, உழுகிறவன் அறுக்கிறவனையும், திராட்சப்பழங்களை ஆலையாடுகிறவன் விதைக்கிறவனையும் தொடர்ந்துபிடித்து, பர்வதங்கள் திராட்சரசமாய் வடிகிறதும், மேடுகளெல்லாம் கரைகிறதுமான நாட்கள் வரும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. என் ஜனமாகிய இஸ்ரவேலின் சிறையிருப்பைத் திருப்புவேன்; அவர்கள் பாழான நகரங்களைக் கட்டி, அவைகளில் குடியிருந்து, திராட்சத்தோட்டங்களை நாட்டி, அவைகளுடைய பழரசத்தைக் குடித்து, தோட்டங்களை உண்டாக்கி, அவைகளின் கனிகளைப் புசிப்பார்கள்.
	<br /><br />
	15. அவர்களை அவர்கள் தேசத்திலே நாட்டுவேன்; நான் அவர்களுக்குக் கொடுத்த தேசத்திலிருந்து அவர்கள் இனிப் பிடுங்கப்படுவதில்லையென்று உன் தேவனாகிய கர்த்தர் சொல்லுகிறார் என்றார்.
</p>
<p>
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos9