import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 11 </title>
	<meta
          name="description"
          content="Daniel 11 | தானியேல் தீர்க்கதரிசி | தானியேல் 11 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மேதியனாகிய தரியு அரசாண்ட முதலாம் வருஷத்திலே நான் அவனைத் திடப்படுத்தவும் பலப்படுத்தவும் அவனுக்குத் துணை நின்றேன்.
	<br /><br />
	2. இப்போது நான் மெய்யான செய்தியை உனக்கு அறிவிப்பேன்; இதோ, இன்னும் மூன்று ராஜாக்கள் பெர்சியாவில் எழும்புவார்கள்; அதின்பின்பு நாலாம் ராஜாவாயிருப்பவன் எல்லாரிலும் மகா ஐசுவரிய சம்பன்னனாகி, தன் ஐசுவரியத்தினால் பலங்கொண்டு, கிரேக்கு ராஜ்யத்துக்கு விரோதமாகச் சகலரையும் எழுப்பிவிடுவான்.
	<br /><br />
	3. ஆனாலும் பராக்கிரமமுள்ள ஒரு ராஜா எழும்பி, பிரபலமாய் ஆண்டு, தனக்கு இஷ்டமானபடி செய்வான்.
	<br /><br />
	4. அவன் எழும்பினபின்பு, அவனுடைய ராஜ்யம் உடைந்துபோய், வானத்தின் நாலு திசைகளிலும் பகுக்கப்படும்; ஆனாலும் அது அவனுடைய சந்ததியாருக்கு அல்ல, அவன் செய்த ஆளுகையின்படியும் அல்ல; அவனுடைய ராஜ்யம் பிடுங்கப்பட்டு, அவனுடையவர்களல்லாத வேறேபேர்களிடமாய்த் தாண்டிப்போம்.
	<br /><br />
	5. தென்றிசை ராஜா பலவானாயிருப்பான்; ஆனாலும் அவனுடைய பிரபுக்களில் ஒருவன் அவனைப்பார்க்கிலும் பலவானாகி ஆளுவான்; இவனுடைய ஆளுகை பலத்த ஆளுகையாயிருக்கும்.
	<br /><br />
	6. அவர்கள் சில வருஷங்களுக்குப்பின்பு, ஒருவரோடொருவர் சம்பந்தம்பண்ணும்படிக்குத் தென்றிசை ராஜாவின் குமாரத்தி வடதிசை ராஜாவினிடத்தில் வருவாள்; ஆனாலும் அவளுக்குப் புயபலம் இராமற்போம்; அவனும் அவனுடைய புயமும் நிலைநிற்பதில்லை; அவளும் அவளை அழைத்துவந்தவர்களும், அவளைப் பெற்றவனும், அவளை அக்காலங்களில் பலப்படுத்தினவனும் ஒப்புக்கொடுக்கப்படுவார்கள்.
	<br /><br />
	7. ஆனாலும் அவளுடைய வேர்களின் கிளையாகிய ஒருவன் தன் ஸ்தானத்தில் எழும்பி, இராணுவத்தோடே வந்து, வடதிசை ராஜாவின் அரணிப்புக்குள் பிரவேசித்து, அவர்களை விரோதித்து,
	<br /><br />
	8. அவர்களுடைய அதிபதிகளையும், அவர்களுடைய விலையேறப்பெற்ற வெள்ளியும் பொன்னுமாகிய பாத்திரங்களையும், அவர்களுடைய தெய்வங்களையுங்கூட எகிப்துக்குக் கொண்டுபோய், சில வருஷங்கள்மட்டும் வடதிசை ராஜாவைப்பார்க்கிலும் நிலையாய் நிற்பான்.
	<br /><br />
	9. தென்றிசை ராஜா அவன் ராஜ்யத்துக்கு விரோதமாக வந்து, தன் தேசத்துக்குத் திரும்பிப்போவான்.
	<br /><br />
	10. ஆனாலும் அவனுடைய குமாரர் யுத்தஞ்செய்ய எத்தனித்து, திரளான சேனைகளைக் கூட்டுவார்கள்; இவர்களில் ஒருவன் நிச்சயமாய் வந்து, வெள்ளம்போலக் கடந்து, திரும்பவும் தன்னுடைய அரண்மட்டும் யுத்தங்கலந்து சேருவான்.
	<br /><br />
	11. அப்பொழுது தென்றிசை ராஜா கடுங்கோபங்கொண்டு புறப்பட்டுப்போய், வடதிசை ராஜாவோடே யுத்தம்பண்ணுவான்; இவன் பெரிய சேனையை ஏகமாய் நிறுத்துவான்; ஆனாலும் இந்தச் சேனை அவன் கையில் ஒப்புக்கொடுக்கப்படும்.
	<br /><br />
	12. அவன் இந்தச் சேனையை நீக்கினபின்பு, அவனுடைய இருதயம் கர்வங்கொள்ளும்; அவன் அநேகமாயிரம்பேரை மடிவிப்பான்; ஆனாலும் பலங்கொள்ளமாட்டான்.
	<br /><br />
	13. சில வருஷங்கள் சென்றபின்பு வடதிசை ராஜா திரும்ப முந்தின சேனையிலும் பெரிதான சேனையைச் சேர்த்து, மகா பெரிய சேனையோடும் வெகு சம்பத்தோடும் நிச்சயமாய் வருவான்.
	<br /><br />
	14. அக்காலங்களில் தென்றிசை ராஜாவுக்கு விரோதமாக அநேகர் எழும்புவார்கள்; அப்பொழுது உன் ஜனத்திலுள்ள துண்டரிக்கக்காரரின் புத்திரர் தரிசனத்தை நிறைவேற்றத் தங்களை உயர்த்துவார்கள்.
	<br /><br />
	15. வடதிசை ராஜா வந்து, கொத்தளம் போட்டு, அரணான நகரங்களைப் பிடிப்பான்; தென்றிசை ராஜாவின் புயபலங்களும் அவன் தெரிந்துகொண்ட ஜனமும் நில்லாமற்போம்; எதிர்க்கிறதற்குப் பெலன் இராது.
	<br /><br />
	16. ஆகையால் அவனுக்கு விரோதமாக வருகிறவன் தன் இஷ்டப்படிச் செய்வான்; அவனுக்கு முன்பாக நிலைநிற்பவன் ஒருவனும் இல்லை; அவன் சிங்காரமான தேசத்தில் தங்குவான்; எல்லாம் அவன் கைவசமாகும்.
	<br /><br />
	17. தன் ராஜ்யத்தின் வல்லமையோடெல்லாம் தானும் தன்னோடேகூடச் செம்மைமார்க்கத்தாரும் வர, இவன் தன் முகத்தைத் திருப்புவான்; இப்படிச் செய்து கெடுதியுண்டாகும்படி அவனுக்கு ஒரு கன்னிப்பெண்ணைக் கொடுப்பான், ஆனாலும் அவளாலே ஸ்திரம்பெறான்; அவள் அவன் பட்சத்தில் நில்லாள்.
	<br /><br />
	18. பின்பு இவன் தன் முகத்தைத் தீவுகளுக்கு நேராகத் திருப்பி, அநேகந் தீவுகளைப் பிடிப்பான்; ஆனாலும் ஒரு சேனாபதி இவன் செய்கிற நிந்தையை ஒழியப்பண்ணுவதுமல்லாமல், இவன் செய்த நிந்தையினிமித்தம் இவனுக்குச் சரிக்குச் சரிக்கட்டுவான்.
	<br /><br />
	19. ஆகையால் தன் முகத்தைத் தன் தேசத்தின் அரண்களுக்கு நேராகத் திருப்புவான்; அங்கே இடறிவிழுந்து காணப்படாமற்போவான்.
	<br /><br />
	20. செழிப்பான ராஜ்யத்தில் தண்டல்காரனைத் திரியப்பண்ணுகிற ஒருவன் தன் ஸ்தானத்தில் எழும்புவான்; ஆகிலும் சிலநாளைக்குள் கோபமில்லாமலும் யுத்தமில்லாமலும் நாசமடைவான்.
	<br /><br />
	21. அவன் ஸ்தானத்தில் அவமதிக்கப்பட்டவன் ஒருவன் எழும்புவான்; இவனுக்கு ராஜ்யபாரத்தின் மேன்மையைக் கொடாதிருப்பார்கள்; ஆனாலும் இவன் சமாதானமாய் நுழைந்து, இச்சகம்பேசி, ராஜ்யத்தைக் கட்டிக்கொள்ளுவான்.
	<br /><br />
	22. பிரவாகமாய் வருகிற சேனைகள் இவனாலே பிரவாகமாய் முறிக்கப்படும்; உடன்படிக்கையின் தலைவனும் முறிக்கப்படுவான்.
	<br /><br />
	23. ஏனென்றால் அவனோடே சம்பந்தம்பண்ணின நாட்கள்முதல் அவன் சூதாய் நடந்து, கொஞ்சம் ஜனங்களோடே புறப்பட்டுவந்து பெலங்கொள்ளுவான்.
	<br /><br />
	24. தேசம் சுகவாழ்வோடும் சம்பூரணத்தோடும் இருக்கையில், அவன் உட்பிரவேசித்து, தன் பிதாக்களும் தன் பிதாக்களின் பிதாக்களும் செய்யாததைச் செய்வான், கொள்ளையிட்டுச் சூறையாடி, பொருளை அவர்களுக்கு இறைத்துப் பங்கிட்டு, அரண்களுக்கு விரோதமாகத் தனக்குள் உபாயங்களை யோசிப்பான்; சிலகாலமட்டும் இப்படியிருக்கும்.
	<br /><br />
	25. பின்னும் தென்றிசை ராஜாவுக்கு விரோதமாகப் பெரிய சேனையோடே போர்செய்யத் தன் வல்லமையையும் தன் ஸ்திரத்தையும் எழுப்புவான்; அப்பொழுது தென்றிசை ராஜா மிகவும் பலத்த பெரிய இராணுவத்தோடே போய் யுத்தங்கலப்பான்; ஆனாலும் அவர்கள் அவனுக்கு விரோதமாகத் துராலோசனை பண்ணியிருந்தபடியால், அவன் நிற்கமாட்டான்.
	<br /><br />
	26. அவனுடைய போஜனங்களைச் சாப்பிடுகிறவர்கள் அவனை நாசப்படுத்துவார்கள்; ஆகையால் அவனுடைய இராணுவம் பிரவாகமாய் வரும்; அநேகர் கொலையுண்டு விழுவார்கள்.
	<br /><br />
	27. இந்த இரண்டு ராஜாக்களின் இருதயமும் தீமை செய்ய நினைக்கும்; ஒரே பந்தியிலிருந்து பொய்பேசுவார்கள்; ஆனாலும் அது வாய்ப்பதில்லை; குறித்தகாலத்துக்கு முடிவு இன்னும் நிறுத்திவைக்கப்பட்டிருக்கும்.
	<br /><br />
	28. அவன் மகா சம்பத்தோடே தன் தேசத்துக்குத் திரும்பி, தன் இருதயத்தைப் பரிசுத்த உடன்படிக்கைக்கு விரோதமாக வைத்து, அதற்கானதைச் செய்து, தன் தேசத்துக்குத் திரும்பிப் போவான்.
	<br /><br />
	29. குறித்தகாலத்திலே திரும்பவும் தென்தேசத்திற்கு வருவான்; ஆனாலும் அவனுடைய பின்நடபடி முன்நடபடியைப்போல் இராது.
	<br /><br />
	30. அவனுக்கு விரோதமாகக் கித்தீமின் கப்பல்கள் வரும்; அதினால் அவன் மனநோவடைந்து, திரும்பிப்போய், பரிசுத்த உடன்படிக்கைக்கு விரோதமாகக் குரோதங்கொண்டு, அதற்கானதைச் செய்து, பரிசுத்த உடன்படிக்கையைத் தள்ளினவர்களை அநுசரிப்பான்.
	<br /><br />
	31. ஆனாலும் அவனிடத்திலிருந்து புறப்பட்டசேனைகள் எழும்பி, அரணான பரிசுத்த ஸ்தலத்தைப் பரிசுத்தக்குலைச்சலாக்கி, அன்றாடபலியை நீக்கி, பாழாக்கும் அருவருப்பை அங்கே வைப்பார்கள்.
	<br /><br />
	32. உடன்படிக்கைக்குத் துரோகிகளாயிருக்கிறவர்களை இச்சகப்பேச்சுகளினால் கள்ளமார்க்கத்தாராக்குவான்; தங்கள் தேவனை அறிந்திருக்கிற ஜனங்கள் திடங்கொண்டு, அதற்கேற்றபடி செய்வார்கள்.
	<br /><br />
	33. ஜனங்களில் அறிவாளிகள் அநேகருக்கு அறிவை உணர்த்துவார்கள்; அநேகநாள்மட்டும் பட்டயத்தினாலும் அக்கினியினாலும் சிறையிருப்பினாலும் கொள்ளையினாலும் விழுவார்கள்.
	<br /><br />
	34. இப்படி அவர்கள் விழுகையில் கொஞ்சம் ஒத்தாசையால் சகாயமடைவார்கள்; அப்பொழுது அநேகர் இச்சகவார்த்தைகளோடே அவர்களை ஒட்டிக்கொள்வார்கள்.
	<br /><br />
	35. அறிவாளிகளைப் புடமிடுகிறதற்கும், சுத்திகரிக்கிறதற்கும், வெண்மையாக்குகிறதற்கும் அவர்களில் சிலர் விழுவார்கள்; முடிவுகாலபரியந்தம் இப்படியிருக்கும்; குறித்தகாலம் வர இன்னும் நாள் செல்லும்.
	<br /><br />
	36. ராஜா தனக்கு இஷ்டமானபடி செய்து, தன்னை உயர்த்தி, எந்தத் தேவனிலும் தன்னைப் பெரியவனாக்கி, தேவாதிதேவனுக்கு விரோதமாக ஆச்சரியமான காரியங்களைப் பேசுவான்; கோபம் தீருமட்டும் அவனுக்குக் கைகூடிவரும்; நிர்ணயிக்கப்பட்டது நடந்தேறும்.
	<br /><br />
	37. அவன் தன் பிதாக்களின் தேவர்களை மதியாமலும், ஸ்திரீகளின் சிநேகத்தையும், எந்தத் தேவனையும் மதியாமலும், எல்லாவற்றிற்கும் தன்னைப் பெரியவனாக்கி,
	<br /><br />
	38. அரண்களின் தேவனைத் தன் ஸ்தானத்திலே கனம்பண்ணி, தன் பிதாக்கள் அறியாத ஒரு தேவனைப் பொன்னினாலும், வெள்ளியினாலும், இரத்தினங்களினாலும், உச்சிதமான வஸ்துக்களினாலும் கனம்பண்ணுவான்.
	<br /><br />
	39. அவன் அரணிப்பான கோட்டைகளுக்காகவும், அந்நிய தேவனுக்காகவும் செய்வது என்னவென்றால், அவைகளை மதிக்கிறவர்களுக்கு மகா கனமுண்டாக்கி, அவர்கள் அநேகரை ஆளும்படிச் செய்து, அவர்களுக்குத் தேசத்தைக் கிரயத்துக்காகப் பங்கிடுவான்.
	<br /><br />
	40. முடிவுகாலத்திலோவென்றால், தென்றிசை ராஜா அவனோடே முட்டுக்கு நிற்பான்; வடதிசை ராஜாவும் இரதங்களோடும் குதிரைவீரர்களோடும் அநேகம் கப்பல்களோடும் சூறைக்காற்றுபோல் அவனுக்கு விரோதமாய் வருவான்; அவன் தேசங்களுக்குள் பிரவேசித்து, அவைகளைப் பிரவாகித்துக் கடந்துபோவான்.
	<br /><br />
	41. அவன் சிங்காரமான தேசத்திலும் வருவான்; அப்பொழுது அநேக தேசங்கள் கவிழ்க்கப்படும்; ஆனாலும் ஏதோமும், மோவாபும், அம்மோன் புத்திரரில் பிரதானமானவர்களும் அவன் கைக்குத் தப்பிப்போவார்கள்.
	<br /><br />
	42. அவன் தேசங்களின்மேல் தன் கையை நீட்டுவான்; எகிப்துதேசம் தப்புவதில்லை.
	<br /><br />
	43. எகிப்தினுடைய பொன்னும் வெள்ளியுமான ஐசுவரியங்களையும் உச்சிதமான எல்லா வஸ்துக்களையும் ஆண்டுகொள்ளுவான்; லீபியரும் எத்தியோப்பியரும் அவனுக்குப் பின்செல்லுவார்கள்.
	<br /><br />
	44. ஆனாலும் கிழக்கிலும் வடக்கிலும் இருந்துவரும் செய்திகள் அவனைக் கலங்கப்பண்ணும்; அப்பொழுது அவன் அநேகரை அழிக்கவும் சங்காரம்பண்ணவும் மகா உக்கிரத்தோடே புறப்பட்டுப்போய்,
	<br /><br />
	45. சமுத்திரங்களுக்கு இடையிலுள்ள சிங்காரமான பரிசுத்த பர்வதத்தண்டையிலே தன் அரமனையாகிய கூடாரங்களைப் போடுவான்; ஆனாலும் அவனுக்கு ஒத்தாசை பண்ணுவாரில்லாமல், அவன் முடிவடைவான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel11