import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OnePeter4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> Suffering as Christians Peter in Tamil Bible Verse | 1 பேதுரு - 4 </title>
	<meta
          name="description"
          content="Suffering as Christians | 1 Peter in Tamil Bible Verse | 1-பேதுரு அதிகாரம் - 4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கிறிஸ்தவர்களாக துன்பப்படுதல் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இப்படியிருக்க, கிறிஸ்து நமக்காக மாம்சத்திலே பாடுபட்டபடியால், நீங்களும் அப்படிப்பட்ட சிந்தையை ஆயுதமாகத் தரித்துக்கொள்ளுங்கள்.
	<br /><br />
	2. ஏனென்றால் மாம்சத்தில் பாடுபடுகிறவன் இனி மாம்சத்திலிருக்கும் காலம்வரைக்கும் மனுஷருடைய இச்சைகளின்படி பிழைக்காமல் தேவனுடைய சித்தத்தின்படியே பிழைக்கத்தக்கதாகப் பாவங்களை விட்டோய்ந்திருப்பான்.
	<br /><br />
	3. சென்ற வாழ்நாட் காலத்திலே நாம் புறஜாதிகளுடைய இஷ்டத்தின்படி நடந்துகொண்டது போதும்; அப்பொழுது நாம் காமவிகாரத்தையும் துர்இச்சைகளையும் நடப்பித்து, மதுபானம்பண்ணி, களியாட்டுச்செய்து, வெறிகொண்டு, அருவருப்பான விக்கிரகாராதனையைச் செய்துவந்தோம்.
	<br /><br />
	4. அந்தத் துன்மார்க்க உளையிலே அவர்களோடேகூட நீங்கள் விழாமலிருக்கிறதினாலே அவர்கள் ஆச்சரியப்பட்டு, உங்களைத் தூஷிக்கிறார்கள்.
	<br /><br />
	5. உயிரோடிருக்கிறவர்களுக்கும் மரித்தோர்களுக்கும் நியாயத்தீர்ப்புக்கொடுக்க ஆயத்தமாயிருக்கிறவருக்கு அவர்கள் கணக்கொப்புவிப்பார்கள்.
	<br /><br />
	6. இதற்காக மரித்தோரானவர்கள், மனுஷர்முன்பாக மாம்சத்திலே ஆக்கினைக்குள்ளாக்கப்பட்டிருந்தும், தேவன்முன்பாக ஆவியிலே பிழைக்கும்படியாக, அவர்களுக்கும் சுவிசேஷம் அறிவிக்கப்பட்டது.
	<br /><br />
	7. எல்லாவற்றிற்கும் முடிவு சமீபமாயிற்று; ஆகையால் தெளிந்த புத்தியுள்ளவர்களாயிருந்து, ஜெபம்பண்ணுவதற்கு ஜாக்கிரதையுள்ளவர்களாயிருங்கள்.
	<br /><br />
	8. எல்லாவற்றிற்கும் மேலாக ஒருவரிலொருவர் ஊக்கமான அன்புள்ளவர்களாயிருங்கள்; அன்பு திரளான பாவங்களை மூடும்.
	<br /><br />
	9. முறுமுறுப்பில்லாமல் ஒருவரையொருவர் உபசரியுங்கள்.
	<br /><br />
	10. அவனவன் பெற்ற வரத்தின்படியே நீங்கள் தேவனுடைய பற்பல கிருபையுள்ள ஈவுகளைப் பகிர்ந்துகொடுக்கும் நல்ல உக்கிராணக்காரர்போல, ஒருவருக்கொருவர் உதவிசெய்யுங்கள்.
	<br /><br />
	11. ஒருவன் போதித்தால் தேவனுடைய வாக்கியங்களின்படி போதிக்கக்கடவன்; ஒருவன் உதவிசெய்தால் தேவன் தந்தருளும் பெலத்தின்படி செய்யக்கடவன்; எல்லாவற்றிலேயும் இயேசுகிறிஸ்துமூலமாய்த் தேவன் மகிமைப்படும்படியே செய்வீர்களாக; அவருக்கே மகிமையும் வல்லமையும் சதாகாலங்களிலும் உண்டாயிருப்பதாக. ஆமென்.
	<br /><br />
	12. பிரியமானவர்களே, உங்களைச் சோதிக்கும்படி உங்கள் நடுவில் பற்றியெரிகிற அக்கினியைக்குறித்து ஏதோ புதுமையென்று திகையாமல்,
	<br /><br />
	13. கிறிஸ்துவின் மகிமை வெளிப்படும்போது நீங்கள் களிகூர்ந்து மகிழும்படியாக அவருடைய பாடுகளுக்கு நீங்கள் பங்காளிகளானதால் சந்தோஷப்படுங்கள்.
	<br /><br />
	14. நீங்கள் கிறிஸ்துவின் நாமத்தினிமித்தம் நிந்திக்கப்பட்டால் பாக்கியவான்கள்; ஏனென்றால் தேவனுடைய ஆவியாகிய மகிமையுள்ள ஆவியானவர் உங்கள்மேல் தங்கியிருக்கிறார்; அவர்களாலே தூஷிக்கப்படுகிறார்; உங்களாலே மகிமைப்படுகிறார்.
	<br /><br />
	15. ஆதலால் உங்களில் ஒருவனும் கொலைபாதகனாயாவது, திருடனாயாவது, பொல்லாங்கு செய்தவனாயாவது, அந்நிய காரியங்களில் தலையிட்டுக்கொண்டவனாயாவது பாடுபடுகிறவனாயிருக்கக்கூடாது.
	<br /><br />
	16. ஒருவன் கிறிஸ்தவனாயிருப்பதினால் பாடுபட்டால் வெட்கப்படாமலிருந்து, அதினிமித்தம் தேவனை மகிமைப்படுத்தக்கடவன்.
	<br /><br />
	17. நியாயத்தீர்ப்பு தேவனுடைய வீட்டிலே துவக்குங்காலமாயிருக்கிறது; முந்தி நம்மிடத்திலே அது துவக்கினால் தேவனுடைய சுவிசேஷத்திற்குக் கீழ்ப்படியாதவர்களின் முடிவு என்னமாயிருக்கும்?
	<br /><br />
	18. நீதிமானே இரட்சிக்கப்படுவது அரிதானால், பக்தியில்லாதவனும் பாவியும் எங்கே நிற்பான்?
	<br /><br />
	19. ஆகையால் தேவனுடைய சித்தத்தின்படி பாடநுபவிக்கிறவர்கள் நன்மைசெய்கிறவர்களாய்த் தங்கள் ஆத்துமாக்களை உண்மையுள்ள சிருஷ்டிகர்த்தாவாகிய அவருக்கு ஒப்புக்கொடுக்கக்கடவர்கள்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 1 பேதுரு 4 முடிவு நெருங்கிவிட்டது என்பதை நினைவூட்டுகிறது; நாம் தெளிவாக இருக்க வேண்டும், அதனால் நாம் பிரார்த்தனை செய்யலாம். ஒருவரையொருவர் ஆழமாக நேசிக்கவும், நமக்குக் கொடுக்கப்பட்ட பரிசுகளை ஒருவருக்கொருவர் சேவை செய்யவும் பயன்படுத்தவும் ஊக்குவிக்கப்படுகிறோம். நாம் கிறிஸ்தவர்களாக இருப்பதால் துன்பம் நம்மை ஆச்சரியப்படுத்தக்கூடாது, மாறாக நாம் கிறிஸ்துவின் பெயரைக் கொண்டிருக்கிறோம் என்பதற்கு சான்றாக இருப்பதால் நாம் மகிழ்ச்சியடையலாம் என்று பேதுரு சுட்டிக்காட்டுகிறார். கடவுளின் ஆவி நம்மீது தங்கியிருப்பதால் அவமதிக்கப்படும்போது நாம் ஆசீர்வதிக்கப்படுகிறோம் என்று அவர் ஊக்குவிக்கிறார். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OnePeter4