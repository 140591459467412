import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 15 </title>
	<meta
          name="description"
          content="Ezekiel 15 | எசேக்கியேல்  15 |
          Ezekiel 15 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, காட்டிலிருக்கிற செடிகளுக்குள் மற்ற எல்லாச் செடிகொடிகளைப்பார்க்கிலும் திராட்சச்செடிக்கு மேன்மை என்ன?
	<br /><br />
	3. யாதொரு வேலைசெய்ய அதிலே ஒரு கட்டை எடுக்கப்படுமோ? யாதொரு தட்டுமுட்டைத் தூக்கிவைக்கும்படி ஒரு முளையை அதினால் செய்வார்களோ?
	<br /><br />
	4. இதோ, அது அக்கினிக்கு இரையாக எறியப்படும்; அதின் இரண்டு முனைகளையும் அக்கினி எரித்துப்போடும்; அதின் நடுத்துண்டும் வெந்துபோம்; அது எந்த வேலைக்காவது உதவுமோ?
	<br /><br />
	5. இதோ, அது வேகாதிருக்கும்போதே ஒரு வேலைக்கும் உதவாதிருக்க, அக்கினி அதை எரித்து, அது வெந்துபோனபின்பு, அது இனி ஒரு வேலைக்கு உதவுவதெப்படி?
	<br /><br />
	6. ஆதலால், கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: காட்டுச் செடிகளுக்குள்ளிருக்கிற திராட்சச்செடியை நான் அக்கினிக்கு இரையாக ஒப்புக்கொடுத்ததுபோல, எருசலேமின் குடிகளையும் அப்படியே ஒப்புக்கொடுத்து,
	<br /><br />
	7. என் முகத்தை அவர்களுக்கு விரோதமாகத் திருப்புவேன்; அவர்கள் ஒரு அக்கினியிலிருந்து நீங்கித் தப்பினாலும், வேறே அக்கினி அவர்களைப் பட்சிக்கும்; அப்படியே நான் என் முகத்தை அவர்களுக்கு விரோதமாய்த் திருப்பும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	8. அவர்கள் துரோகம்பண்ணினபடியினால், நான் தேசத்தைப் பாழாய்ப் போகப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial15