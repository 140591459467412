import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 6 </title>
	<meta
          name="description"
          content="Hosea 6 | ஓசியா 6 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தரிடத்தில் திரும்புவோம் வாருங்கள்; நம்மைப் பீறினார், அவரே நம்மைக் குணமாக்குவார்; நம்மை அடித்தார், அவரே நம்முடைய காயங்களைக் கட்டுவார்.
	<br /><br />
	2. இரண்டு நாளுக்குப்பின்பு அவர் நம்மை உயிர்ப்பிப்பார்; மூன்றாம் நாளில் நம்மை எழுப்புவார்; அப்பொழுது நாம் அவருடைய சமுகத்தில் பிழைத்திருப்போம்.
	<br /><br />
	3. அப்பொழுது நாம் அறிவடைந்து, கர்த்தரை அறியும்படி தொடர்ந்து போவோம்; அவருடைய புறப்படுதல் அருணோதயம்போல ஆயத்தமாயிருக்கிறது; அவர் மழையைப்போலவும், பூமியின்மேல் பெய்யும் முன்மாரி பின்மாரியைப்போலவும் நம்மிடத்தில் வருவார்.
	<br /><br />
	4. எப்பிராயீமே, உனக்கு என்ன செய்வேன்? யூதாவே, உனக்கு என்ன செய்வேன்? உங்கள் பக்தி காலையில் காணும் மேகத்தைப்போலவும், விடியற்காலையில் தோன்றும் பனியைப்போலவும் ஒழிந்துபோகிறது.
	<br /><br />
	5. ஆகையால் தீர்க்கதரிசிகளைக்கொண்டு அவர்களை வெட்டினேன்; என் வாய்மொழிகளைக்கொண்டு அவர்களை அதம்பண்ணினேன்; உன்மேல் வரும் தண்டனைகள் வெளிச்சத்தைப்போல் வெளிப்படும்.
	<br /><br />
	6. பலியை அல்ல இரக்கத்தையும், தகனபலிகளைப்பார்க்கிலும் தேவனை அறிகிற அறிவையும், விரும்புகிறேன்.
	<br /><br />
	7. அவர்களோ ஆதாமைப்போல் உடன்படிக்கையை மீறி, அங்கே எனக்கு விரோதமாய்த் துரோகம்பண்ணினார்கள்.
	<br /><br />
	8. கீலேயாத் அக்கிரமம் செய்கிறவர்களின் பட்டணம்; அது இரத்தக்காலடிகளால் மிதிக்கப்பட்டிருக்கிறது.
	<br /><br />
	9. பறிகாரரின் கூட்டங்கள் ஒருவனுக்குக் காத்திருக்கிறதுபோல, சீகேமுக்குப் போகிற வழியிலே கொலைசெய்கிற ஆசாரியரின் கூட்டம் காத்திருக்கிறது; தோஷமான காரியங்களையே செய்கிறார்கள்.
	<br /><br />
	10. பயங்கரமான காரியத்தை இஸ்ரவேல் வம்சத்தாரில் கண்டேன்; அங்கே எப்பிராயீமின் வேசித்தனம் உண்டு; இஸ்ரவேல் தீட்டுப்பட்டுப்போயிற்று.
	<br /><br />
	11. யூதாவே, உனக்கு ஒரு அறுப்புக்காலம் நியமிக்கப்பட்டிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea6