import React from 'react';
import '../.././App.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

function TwoJohn (){
return (
    <>
    <Helmet>
        <title> The Second Book of John in Tamil Bible Verse | 2 யோவான்  </title>
        <meta
          name="description"
          content="The Second Book of John in Tamil Bible Verse | அப்போஸ்தலனாகிய யோவான் எழுதிய இரண்டாம்  நிருபம்   | புதிய ஏற்பாடு | New Testament"
        />
      </Helmet>
    <div className='body'>
        <h2 className='chapterh' align="center"> 2 யோவான் </h2>
        <div className='chapter'>
        <div className='introduction'>
        <p className='article content'>
            {/* Introductions of Tamilbible verses */}
            <h1>
			அப்போஸ்தலனாகிய யோவான் எழுதிய இரண்டாம்  நிருபம்
</h1>
<h4>
	ஆசிரியர்:
</h4>
<p>
	2 யோவான் புத்தகம் அதன் ஆசிரியரின் பெயரை நேரடியாக குறிப்பிடவில்லை. திருச்சபையின் ஆரம்ப நாட்களிலிருந்து வந்த பாரம்பரியம், ஆசிரியர் அப்போஸ்தலன் யோவான் என்று கூறுகிறது. இந்த கடிதத்திற்கு யோவான் என்ற கிறிஸ்துவின் மற்றொரு சீடர் காரணமாக இருக்கலாம் என்று பல ஆண்டுகளாக பல்வேறு யூகங்கள் உள்ளன. இருப்பினும், எல்லா ஆதாரங்களும் ஆசிரியரை யோவான் அன்பான சீடர் என்று சுட்டிக்காட்டுகின்றன, அவர் யோவான் நற்செய்தியையும் எழுதியுள்ளார்.
</p>
<h3>
	எழுதப்பட்ட தேதி
</h3>
<p>
	கிபி 85-95 க்கு இடையில் ஜானின் மற்ற கடிதங்களான 1 மற்றும் 3 யோவான் எழுதிய அதே நேரத்தில் 2 ஜானின் புத்தகம் எழுதப்பட்டிருக்கும்.
</p>
<h2>
	எழுதுவதன் நோக்கம்
</h2>
<p>
	2 யோவான் புத்தகம், யோவானின் கடிதத்தைப் படிப்பவர்கள் ஒருவரையொருவர் நேசிக்கவும், வேதத்திற்குக் கீழ்ப்படிந்து தங்கள் வாழ்க்கையை வாழவும் கட்டளைக்குக் கீழ்ப்படிவதன் மூலம் கடவுள் மீதும் அவருடைய மகன் இயேசு மீதும் தங்கள் அன்பைக் காட்ட வேண்டும் என்று ஒரு அவசர வேண்டுகோள். 2 யோவான் புத்தகம் , கிறிஸ்து உண்மையில் மாம்சத்தில் வரவில்லை என்று கூறி, அவதாரத்தை மறுத்துச் செல்லும் ஏமாற்றுக்காரர்களைத் தேடுவதற்கு ஒரு வலுவான எச்சரிக்கையாகும் .
</p>
<h2>
	முக்கிய வசனங்கள்
</h2>
<p>
	2 யோவான் 6 : "மேலும் இதுவே அன்பு: நாம் அவருடைய கட்டளைகளுக்குக் கீழ்ப்படிந்து நடப்பது. ஆரம்பத்திலிருந்தே நீங்கள் கேள்விப்பட்டபடி, அன்பில் நடப்பதே அவருடைய கட்டளை."
</p>
<p>
	2 யோவான் 8-9: "நீங்கள் உழைத்ததை இழக்காமல் பார்த்துக்கொள்ளுங்கள், ஆனால் நீங்கள் முழு பலனை அடைவீர்கள். கிறிஸ்துவின் போதனையில் தொடராமல் முன்னோக்கி ஓடும் எவருக்கும் கடவுள் இல்லை; போதனையில் தொடர்பவருக்கு தந்தை இருவரும் இருக்கிறார். மற்றும் மகன்."
</p>
<h2>
	சுருக்கமான சுருக்கம்
</h2>
<p>
	2 ஜானின் புத்தகம் "தேர்ந்தெடுக்கப்பட்ட பெண்மணி மற்றும் அவளுடைய குழந்தைகளை" குறிக்கும். இது தேவாலயத்தில் முக்கியமான ஒரு பெண்ணாக இருந்திருக்கலாம் அல்லது உள்ளூர் தேவாலயத்தையும் அதன் சபையையும் குறிக்கும் ஒரு குறியீடாக இருக்கலாம். அந்த நாட்களில், கிறிஸ்தவர்கள் துன்புறுத்தப்பட்டபோது இதுபோன்ற குறியீட்டு வணக்கங்கள் அடிக்கடி பயன்படுத்தப்பட்டன.
</p>
<p>
	2 யோவான் புத்தகம், கிறிஸ்துவின் சரியான கோட்பாட்டைக் கற்பிக்காத மற்றும் இயேசு உண்மையில் மாம்சத்தில் வரவில்லை என்று நம்பும் ஏமாற்றுக்காரர்களைப் பற்றிய அவசர எச்சரிக்கையுடன் பெரிதும் அக்கறை கொண்டுள்ளது. இத்தகைய போதனை இயேசுவின் மனிதத்தன்மையை மறுக்கிறது. உண்மையான விசுவாசிகள் இந்த தவறான போதகர்களைப் பற்றி அறிந்திருக்க வேண்டும் என்றும் அவர்களுடன் எந்த தொடர்பும் இல்லை என்றும் யோவான் மிகவும் ஆர்வமாக உள்ளார்.
</p>
<h3>
	இணைப்புகள்
</h3>
<p>
	யோவான் அன்பை ஒரு உணர்ச்சியாகவோ அல்லது உணர்வாகவோ விவரிக்கவில்லை, ஆனால் கடவுளின் கட்டளைகளுக்குக் கீழ்ப்படிவதாக விவரிக்கிறார். கட்டளைகளின் முக்கியத்துவத்தை இயேசு மீண்டும் வலியுறுத்தினார், குறிப்பாக "முதல் மற்றும் பெரிய கட்டளை", கடவுள் மீது அன்பு ( உபாகமம் 6:5 ), மற்றும் இரண்டாவது, ஒருவருக்கொருவர் அன்பு ( மத்தேயு 22:37-40 ; லேவியராகமம் 19:18) கடவுளின் பழைய ஏற்பாட்டு சட்டத்தை நீக்குவதற்குப் பதிலாக, இயேசு அதை நிறைவேற்றுவதற்கான வழிகளை தம்மில் வழங்குவதன் மூலம் அதை நிறைவேற்ற வந்தார்.
</p>
<h2>
	நடைமுறை பயன்பாடு
</h2>
<p>
	&ldquo;கிறிஸ்தவ&rdquo; என்று கூறுவதை நாம் பார்க்கும், கேட்பது மற்றும் படிக்கும் அனைத்தையும் வேதாகமத்துடன் சரிபார்ப்பது மிகவும் முக்கியம். சாத்தானின் மிகப்பெரிய ஆயுதங்களில் ஒன்று வஞ்சகம் என்பதால் இதை மிகவும் வலுவாக வலியுறுத்த முடியாது. வேதாகமத்தை அடிப்படையாகக் கொண்டதாகத் தோன்றும் ஒரு புதிய மற்றும் உற்சாகமான கோட்பாட்டின் மூலம் எடுத்துக் கொள்ளப்படுவது மிகவும் எளிதானது, ஆனால் இது நெருக்கமாக ஆராயப்பட்டால், உண்மையில் கடவுளுடைய வார்த்தையிலிருந்து விலகுவதாகும். நடப்பதாகத் தோன்றுவது வேதாகமத்துடன் வெளிப்படையாக வரிசையாக இல்லை என்றால், இது தவறானது மற்றும் ஆவிக்குரியது அல்ல, அதற்கும் நமக்கும் எந்த தொடர்பும் இருக்கக்கூடாது.
</p>


			<h2>
	THE Second LETTER TO John Tamil Bible
</h2>
<h3>
	Author
</h3>

<p>
	The Book of 2 John does not directly name its author. The tradition from the earliest days of the church states that the author was the apostle John. There have been various conjectures over the years that another disciple of Christ named John may have been responsible for this letter. However, all the evidence points to the author as John the beloved disciple who also wrote the Gospel of John.
</p>
<h2>
	Date of Writing
</h2>
<p>
	The Book of 2 John would most likely have been written at about the same time as John&rsquo;s other letters, 1 and 3 John, between A.D. 85-95.
</p>
<h2>
	Purpose of Writing
</h2>
<p>
	The Book of 2 John is an urgent plea that the readers of John&rsquo;s letter should show their love for God and His Son, Jesus, by obeying the commandment to love each other and live their lives in obedience to the Scriptures. The Book of 2 John is also a strong warning to be on the lookout for deceivers who were going about denying the Incarnation, saying that Christ had not actually come in the flesh.
</p>
<h3>
	Key Verses
</h3>
<p>
	2 John 6: "And this is love: that we walk in obedience to his commands. As you have heard from the beginning, his command is that you walk in love."
</p>
<p>
	2 John 8-9: "Watch out that you do not lose what you have worked for, but that you may be rewarded fully. Anyone who runs ahead and does not continue in the teaching of Christ does not have God; whoever continues in the teaching has both the Father and the Son."
</p>
<h2>
	Brief Summary
</h2>
<p>
	The Book of 2 John is addressed to "the chosen lady and her children." This could either have been a lady of important standing in the church or a code which refers to the local church and its congregation. In those days when Christians were being persecuted such coded salutations were often used.
</p>
<p>
	The Book of 2 John is largely concerned with an urgent warning concerning deceivers who were not teaching the exact doctrine of Christ and who maintained that Jesus did not actually come in the flesh. Such a teaching denies Jesus&rsquo; humanity. John is very anxious that true believers should be aware of these false teachers and have nothing to do with them.
</p>
<h2>
	Connections
</h2>
<p>
	John describes love not as an emotion or feeling, but as obedience to the commandments of God. Jesus reiterated the importance of the commandments, especially the &ldquo;first and greatest commandment,&rdquo; love for God (Deuteronomy 6:5), and the second, love for one another (Matthew 22:37-40; Leviticus 19:18). Far from abolishing the Old Testament law of God, Jesus came to fulfill it by providing the means of its fulfillment in Himself.
</p>
<h2>
	Practical Application
</h2>
<p>
	It is extremely important that we check everything we see, hear, and read that claims to be &ldquo;Christian&rdquo; with the Scriptures. This cannot be too strongly emphasized because one of Satan&rsquo;s greatest weapons is deceit. It is very easy to be taken in by a new and exciting doctrine that appears to be based on Scripture but which, if examined closely, is in fact a departure from the Word of God. If what appears to be happening does not line up explicitly with Scripture, then this is false and not of the Spirit, and we should have nothing to do with it.
</p>



            {/* End Introduction */}
        </p>

        </div>
        <div>
            <div className='chap-link'>
                <div className='btn'> <Link to="/2-John-1"> 01</Link> </div>

				            
                
            </div>
        </div>
    </div>
    </div>
    </>
);
}

export default TwoJohn;