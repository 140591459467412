import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah41 = () => {
  const verseNumber = 41;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 41 </title>
	<meta
          name="description"
          content="Jeremiah 41 | எரேமியா 41 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு ஏழாம் மாதத்திலே ராஜவம்சத்தில் பிறந்தவனும், எலிசாமாவின் குமாரனாகிய நெத்தானியாவின் மகனுமான இஸ்மவேலும், அவனுடனேகூட ராஜாவின் பிரபுக்களான பத்துப்பேரும் மிஸ்பாவுக்கு அகிக்காமின் குமாரனாகிய கெதலியாவினிடத்தில் வந்து, அங்கே ஏகமாய்ப் போஜனம்பண்ணினார்கள்.
	<br /><br />
	2. அப்பொழுது நெத்தானியாவின் குமாரனாகிய இஸ்மவேலும், அவனோடிருந்த பத்துப்பேரும் எழும்பி, பாபிலோன் ராஜா தேசத்தின்மேல் அதிகாரியாக வைத்த சாப்பானின் குமாரனாகிய அகிக்காமின் மகனான கெதலியாவைப் பட்டயத்தால் வெட்டினார்கள்.
	<br /><br />
	3. மிஸ்பாவிலே கெதலியாவினிடத்தில் இருந்த எல்லா யூதரையும், அங்கே காணப்பட்ட யுத்தமனுஷராகிய கல்தேயரையும் இஸ்மவேல் வெட்டிப்போட்டான்.
	<br /><br />
	4. அவன் கெதலியாவைக் கொன்ற பின்பு, மறுநாளிலே அதை ஒருவரும் இன்னும் அறியாதிருக்கையில்:
	<br /><br />
	5. தாடியைச் சிரைத்து, வஸ்திரங்களைக் கிழித்து, தங்களைக் கீறிக்கொண்டிருந்த எண்பதுபேர் சீகேமிலும் சீலோவிலும் சமாரியாவிலுமிருந்து, தங்கள் கைகளில் காணிக்கைகளையும் தூபவர்க்கங்களையும், கர்த்தருடைய ஆலயத்துக்குக் கொண்டுபோகும்படி வந்தார்கள்.
	<br /><br />
	6. அப்பொழுது நெத்தானியாவின் குமாரனாகிய இஸ்மவேல் மிஸ்பாவிலிருந்து புறப்பட்டு, அவர்களுக்கு எதிராக அழுதுகொண்டே நடந்துவந்து, அவர்களைச் சந்தித்தபோது, அவர்களை நோக்கி: அகிக்காமின் குமாரனாகிய கெதலியாவினிடத்தில் வாருங்கள் என்றான்.
	<br /><br />
	7. அவர்கள் நகரத்தின் மத்தியில் வந்தபோது, நெத்தானியாவின் குமாரனாகிய இஸ்மவேலும், அவனோடிருந்த மனுஷரும் அவர்களை வெட்டி ஒரு பள்ளத்திலே தள்ளிப்போட்டார்கள்.
	<br /><br />
	8. ஆனாலும் அவர்களில் பத்துப்பேர் மீந்திருந்தார்கள்; அவர்கள் இஸ்மவேலைப்பார்த்து: எங்களைக் கொலை செய்யவேண்டாம்; கோதுமையும் வாற்கோதுமையும் எண்ணெயும் தேனுமுள்ள புதையல்கள் எங்களுக்கு நிலத்தின்கீழ் இருக்கிறது என்றார்கள்; அப்பொழுது அவர்களை அவர்கள் சகோதரரோடுங்கூடக் கொலைசெய்யாமல் விட்டுவைத்தான்.
	<br /><br />
	9. இஸ்மவேல் கெதலியாவினிமித்தம் வெட்டின மனுஷருடைய பிரேதங்களையெல்லாம் எறிந்துபோட்ட பள்ளமோவெனில், ஆசா என்னும் ராஜா இஸ்ரவேலின் ராஜாவாகிய பாஷாவினிமித்தம் உண்டுபண்ணின பள்ளந்தானே; அதை நெத்தானியாவின் குமாரனாகிய இஸ்மவேல் வெட்டுண்ட பிரேதங்களால் நிரப்பினான்.
	<br /><br />
	10. பின்பு இஸ்மவேல் மிஸ்பாவில் இருக்கிற மீதியான ஜனத்தையெல்லாம் சிறைப்படுத்திக்கொண்டுபோனான்; ராஜாவின் குமாரத்திகளையும் காவற்சேனாதிபதியாகிய நேபுசராதான் அகிக்காமின் குமாரனாகிய கெதலியாவின் விசாரிப்புக்கு ஒப்புவித்துப்போன மிஸ்பாவிலுள்ள மீதியான சகல ஜனங்களையும் நெத்தானியாவின் குமாரனாகிய இஸ்மவேல் சிறைப்படுத்திக்கொண்டு அம்மோன் புத்திரர் பட்சத்தில் போகப் புறப்பட்டான்.
	<br /><br />
	11. நெத்தானியாவின் குமாரனாகிய இஸ்மவேல் செய்த பொல்லாப்பையெல்லாம் கரேயாவின் குமாரனாகிய யோகனானும், அவனோடிருந்த எல்லா இராணுவச் சேர்வைக்காரரும் கேட்டபோது,
	<br /><br />
	12. அவர்கள் புருஷரையெல்லாம் கூட்டிக்கொண்டு, நெத்தானியாவின் குமாரனாகிய இஸ்மவேலோடே யுத்தம்பண்ணப்போய், அவனைக் கிபியோனிலிருக்கும் பெருங்குளத்துத் தண்ணீரண்டையிலே கண்டார்கள்.
	<br /><br />
	13. அப்பொழுது இஸ்மவேலோடிருந்த சகல ஜனங்களும் கரேயாவின் குமாரனாகிய யோகனானையும், அவனோடிருந்த எல்லா இராணுவச் சேர்வைக்காரரையும் கண்டு சந்தோஷப்பட்டு,
	<br /><br />
	14. இஸ்மவேல் மிஸ்பாவிலிருந்து சிறைபிடித்துக்கொண்டுபோன ஜனங்களெல்லாம் பின்னிட்டுத் திரும்பி, கரேயாவின் குமாரனாகிய யோகனானிடத்தில் வந்துவிட்டார்கள்.
	<br /><br />
	15. நெத்தானியாவின் குமாரனாகிய இஸ்மவேலோ, எட்டுப்பேரோடுங்கூட யோகனானின் கைக்குத் தப்பி, அம்மோன் புத்திரரிடத்தில் போனான்.
	<br /><br />
	16. கரேயாவின் குமாரனாகிய யோகனானும், அவனோடிருந்த எல்லா இராணுவச் சேர்வைக்காரரும், அகிக்காமின் குமாரனாகிய கெதலியாவை வெட்டிப்போட்ட நெத்தானியாவின் குமாரனாகிய இஸ்மவேல் கொண்டுபோனதும், தாங்கள் கிபியோனிலே விடுதலையாக்கித் திரும்பப்பண்ணினதுமான மீதியான சகல ஜனமுமாகிய சேவகரான மனுஷரையும், ஸ்திரீகளையும், குழந்தைகளையும், அரமனைப் பிரதானிகளையும் சேர்த்துக்கொண்டு,
	<br /><br />
	17. பாபிலோன் ராஜா தேசத்தின்மேல் அதிகாரியாக்கின அகிக்காமின் குமாரனாகிய கெதலியாவை நெத்தானியாவின் குமாரனாகிய இஸ்மவேல் வெட்டிப்போட்டதினிமித்தம், கல்தேயருக்குப் பயந்தபடியினால்,
	<br /><br />
	18. தாங்கள் எகிப்துக்குப் போகப் புறப்பட்டு, பெத்லெகேமூருக்கு அருகான கிம்காமின் பேட்டையில் தங்கியிருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah41