import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 5 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் கெனேசரேத்துக் கடலருகே நின்றபோது, திரளான ஜனங்கள் தேவவசனத்தைக் கேட்கும்படி அவரிடத்தில் நெருங்கினார்கள்.
	<br /><br />
	2. அப்பொழுது கடற்கரையிலே நின்ற இரண்டு படவுகளைக் கண்டார். மீன்பிடிக்கிறவர்கள் அவைகளை விட்டிறங்கி, வலைகளை அலைசிக்கொண்டிருந்தார்கள்.
	<br /><br />
	3. அப்பொழுது அந்தப் படவுகளில் ஒன்றில் ஏறினார், அது சீமோனுடையதாயிருந்தது; அதைக் கரையிலிருந்து சற்றே தள்ளும்படி அவனைக் கேட்டுக்கொண்டு, அந்தப் படவில் உட்கார்ந்து, ஜனங்களுக்குப் போதகம்பண்ணினார்.
	<br /><br />
	4. அவர் போதகம்பண்ணி முடித்த பின்பு சீமோனை நோக்கி: ஆழத்திலே தள்ளிக்கொண்டுபோய், மீன்பிடிக்கும்படி உங்கள் வலைகளைப் போடுங்கள் என்றார்.
	<br /><br />
	5. அதற்குச் சீமோன்: ஐயரே, இராமுழுவதும் நாங்கள் பிரயாசப்பட்டும் ஒன்றும் அகப்படவில்லை; ஆகிலும் உம்முடைய வார்த்தையின்படியே வலையைப் போடுகிறேன் என்றான்.
	<br /><br />
	6. அந்தப்படியே அவர்கள் செய்து, தங்கள் வலை கிழிந்துபோகத்தக்கதாக மிகுதியான மீன்களைப் பிடித்தார்கள்.
	<br /><br />
	7. அப்பொழுது மற்றப் படவிலிருந்த கூட்டாளிகள் வந்து தங்களுக்கு உதவிசெய்யும்படிக்குச் சைகைகாட்டினார்கள்; அவர்கள் வந்து, இரண்டு படவுகளும் அமிழத்தக்கதாக நிரப்பினார்கள்.
	<br /><br />
	8. சீமோன் பேதுரு அதைக் கண்டு, இயேசுவின் பாதத்தில் விழுந்து: ஆண்டவரே, நான் பாவியான மனுஷன், நீர் என்னைவிட்டுப் போகவேண்டும் என்றான்.
	<br /><br />
	9. அவர்கள் திரளான மீன்களைப் பிடித்ததினிமித்தம், அவனுக்கும் அவனோடுகூட இருந்த யாவருக்கும் பிரமிப்புண்டானபடியினால் அப்படிச் சொன்னான்.
	<br /><br />
	10. சீமோனுக்குக் கூட்டாளிகளான செபெதேயுவின் குமாரராகிய யாக்கோபும் யோவானும் அந்தப்படியே பிரமித்தார்கள். அப்பொழுது இயேசு சீமோனை நோக்கி: பயப்படாதே, இதுமுதல் நீ மனுஷரைப் பிடிக்கிறவனாயிருப்பாய் என்றார்.
	<br /><br />
	11. அவர்கள் படவுகளைக் கரையிலே கொண்டுபோய் நிறுத்தி, எல்லாவற்றையும் விட்டு, அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	12. பின்பு அவர் ஒரு பட்டணத்தில் இருக்கையில், குஷ்டரோகம் நிறைந்த ஒரு மனுஷன் இயேசுவைக் கண்டு, முகங்குப்புற விழுந்து: ஆண்டவரே, உமக்குச் சித்தமானால், என்னைச் சுத்தமாக்க உம்மாலே ஆகும் என்று அவரை வேண்டிக்கொண்டான்.
	<br /><br />
	13. அவர் தமது கையை நீட்டி, அவனைத் தொட்டு: எனக்குச் சித்தமுண்டு, சுத்தமாகு என்றார்; உடனே குஷ்டரோகம் அவனை விட்டு நீங்கிற்று.
	<br /><br />
	14. அவர் அவனை நோக்கி: நீ இதை ஒருவருக்கும் சொல்லாமல், போய், உன்னை ஆசாரியனுக்குக் காண்பித்து, நீ சுத்தமானதினிமித்தம், மோசே கட்டளையிட்டபடியே, அவர்களுக்குச் சாட்சியாகப் பலி செலுத்து என்று கட்டளையிட்டார்.
	<br /><br />
	15. அப்படியிருந்தும் அவருடைய கீர்த்தி அதிகமாகப் பரம்பிற்று. திரளான ஜனங்கள் அவருடைய உபதேசத்தைக் கேட்பதற்கும் அவராலே தங்கள் பிணிகள் நீங்கிச் சவுக்கியமடைவதற்கும் கூடிவந்தார்கள்.
	<br /><br />
	16. அவரோ வனாந்தரத்தில் தனித்துப் போய், ஜெபம்பண்ணிக்கொண்டிருந்தார்.
	<br /><br />
	17. பின்பு ஒருநாள் அவர் உபதேசித்துக்கொண்டிருக்கிறபோது, கலிலேயா யூதேயா நாடுகளிலுள்ள சகல கிராமங்களிலும், எருசலேம் நகரத்திலுமிருந்து வந்த பரிசேயரும் நியாயசாஸ்திரிகளும் உட்கார்ந்திருந்தார்கள்; அப்பொழுது பிணியாளிகளைக் குணமாக்கத்தக்கதாகக் கர்த்தருடைய வல்லமை விளங்கிற்று.
	<br /><br />
	18. அப்பொழுது சில மனுஷர் திமிர்வாதக்காரன் ஒருவனைப் படுக்கையோடே எடுத்துக்கொண்டுவந்து, அவனை உள்ளே கொண்டுபோகவும் அவர் முன்பாக வைக்கவும் வகைதேடினார்கள்.
	<br /><br />
	19. ஜனக்கூட்டம் மிகுதியாயிருந்தபடியால் அவனை உள்ளே கொண்டுபோகிறதற்கு வகைகாணாமல், வீட்டின்மேல் ஏறி, தட்டோடுகள் வழியாய் ஜனங்களின் மத்தியில் இயேசுவுக்கு முன்பாக அவனைப் படுக்கையோடே இறக்கினார்கள்.
	<br /><br />
	20. அவர்களுடைய விசுவாசத்தை அவர் கண்டு, திமிர்வாதக்காரனை நோக்கி: மனுஷனே, உன் பாவங்கள் உனக்கு மன்னிக்கப்பட்டது என்றார்.
	<br /><br />
	21. அப்பொழுது வேதபாரகரும் பரிசேயரும் யோசனைபண்ணி, தேவதூஷணம் சொல்லுகிற இவன் யார்? தேவன் ஒருவரேயன்றிப் பாவங்களை மன்னிக்கத்தக்கவர் யார் என்றார்கள்.
	<br /><br />
	22. இயேசு அவர்கள் சிந்தனைகளை அறிந்து, அவர்களை நோக்கி: உங்கள் இருதயங்களில் நீங்கள் சிந்திக்கிறதென்ன?
	<br /><br />
	23. உன் பாவங்கள் உனக்கு மன்னிக்கப்பட்டது என்று சொல்வதோ, எழுந்து நடவென்று சொல்வதோ, எது எளிது?
	<br /><br />
	24. பூமியிலே பாவங்களை மன்னிக்க மனுஷகுமாரனுக்கு அதிகாரம் உண்டென்பதை நீங்கள் அறியவேண்டும் என்று சொல்லி, திமிர்வாதக்காரனை நோக்கி: நீ எழுந்து, உன் படுக்கையை எடுத்துக்கொண்டு, உன் வீட்டுக்குப் போ என்று உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	25. உடனே அவன் அவர்களுக்கு முன்பாக எழுந்து, தன் படுக்கையை எடுத்துக்கொண்டு, தேவனை மகிமைப்படுத்தி, தன் வீட்டுக்குப் போனான்.
	<br /><br />
	26. அதினாலே எல்லாரும் ஆச்சரியப்பட்டு, தேவனை மகிமைப்படுத்தினார்கள்; அல்லாமலும், அவர்கள் பயம் நிறைந்தவர்களாகி, அதிசயமான காரியங்களை இன்று கண்டோம் என்றார்கள்.
	<br /><br />
	27. இவைகளுக்குப் பின்பு, அவர் புறப்பட்டு, ஆயத்துறையில் உட்கார்ந்திருந்த லேவி என்னும் பேருடைய ஒரு ஆயக்காரனைக் கண்டு: எனக்குப் பின்சென்று வா என்றார்.
	<br /><br />
	28. அவன் எல்லாவற்றையும் விட்டு, எழுந்து, அவருக்குப் பின்சென்றான்.
	<br /><br />
	29. அந்த லேவி என்பவன் தன் வீட்டிலே அவருக்குப் பெரிய விருந்துபண்ணினான். அநேக ஆயக்காரரும் மற்றவர்களும் அவர்களோடேகூடப் பந்தியிருந்தார்கள்.
	<br /><br />
	30. வேதபாரகரும் பரிசேயரும் அவருடைய சீஷருக்கு விரோதமாக முறுமுறுத்து: நீங்கள் ஆயக்காரரோடும் பாவிகளோடும் போஜனபானம்பண்ணுகிறதென்னவென்று கேட்டார்கள்.
	<br /><br />
	31. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: பிணியாளிகளுக்கு வைத்தியன் வேண்டியதேயல்லாமல் சுகமுள்ளவர்களுக்கு வேண்டியதில்லை.
	<br /><br />
	32. நீதிமான்களையல்ல, பாவிகளையே மனந்திரும்புகிறதற்கு அழைக்கவந்தேன் என்றார்.
	<br /><br />
	33. பின்பு அவர்கள் அவரை நோக்கி: யோவானுடைய சீஷர் அநேகந்தரம் உபவாசித்து ஜெபம்பண்ணிக்கொண்டு வருகிறார்கள், பரிசேயருடைய சீஷரும் அப்படியே செய்கிறார்கள், உம்முடைய சீஷர் போஜனபானம்பண்ணுகிறார்களே, அதெப்படியென்று கேட்டார்கள்.
	<br /><br />
	34. அதற்கு அவர்: மணவாளன் தங்களோடிருக்கையில் மணவாளனுடைய தோழர்களை நீங்கள் உபவாசிக்கச் செய்யக்கூடுமா?
	<br /><br />
	35. மணவாளன் அவர்களை விட்டு எடுபடும் நாட்கள் வரும், அந்த நாட்களிலே உபவாசிப்பார்கள் என்றார்.
	<br /><br />
	36. அவர்களுக்கு ஒரு உவமையையும் சொன்னார்: ஒருவனும் புதிய வஸ்திரத்துண்டைப் பழைய வஸ்திரத்தின்மேல் போட்டு இணைக்கமாட்டான், இணைத்தால் புதியது பழையதைக் கிழிக்கும்; புதிய வஸ்திரத்துண்டு பழைய வஸ்திரத்துக்கு ஒவ்வாது.
	<br /><br />
	37. ஒருவனும் புது திராட்சரசத்தைப் பழந்துருத்திகளில் வார்த்துவைக்கமாட்டான்; வார்த்துவைத்தால் புதுரசம் துருத்திகளைக் கிழித்துப்போடும், இரசமும் சிந்திப்போம், துருத்திகளும் கெட்டுப்போம்.
	<br /><br />
	38. புது ரசத்தைப் புது துருத்திகளில் வார்த்துவைக்கவேண்டும், அப்பொழுது இரண்டும் பத்திரப்பட்டிருக்கும்.
	<br /><br />
	39. அன்றியும் ஒருவனும் பழைய ரசத்தைக் குடித்தவுடனே புது ரசத்தை விரும்பமாட்டான், பழைய ரசமே நல்லதென்று சொல்லுவான் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	Jesus Calls His First Disciples
</h2>
<p>
	<br />
	1 One day as Jesus was standing by the Lake of Gennesaret, the people were crowding around him and listening to the word of God.
	<br />
	2 He saw at the water&rsquo;s edge two boats, left there by the fishermen, who were washing their nets.
	<br />
	3 He got into one of the boats, the one belonging to Simon, and asked him to put out a little from shore. Then he sat down and taught the people from the boat.
	<br />
	4 When he had finished speaking, he said to Simon, &ldquo;Put out into deep water, and let down the nets for a catch.&rdquo;
	<br />
	5 Simon answered, &ldquo;Master, we&rsquo;ve worked hard all night and haven&rsquo;t caught anything. But because you say so, I will let down the nets.&rdquo;
	<br />
	6 When they had done so, they caught such a large number of fish that their nets began to break.
	<br />
	7 So they signaled their partners in the other boat to come and help them, and they came and filled both boats so full that they began to sink.
	<br />
	8 When Simon Peter saw this, he fell at Jesus&rsquo; knees and said, &ldquo;Go away from me, Lord; I am a sinful man!&rdquo;
	<br />
	9 For he and all his companions were astonished at the catch of fish they had taken,
	<br />
	10 and so were James and John, the sons of Zebedee, Simon&rsquo;s partners. Then Jesus said to Simon, &ldquo;Don&rsquo;t be afraid; from now on you will fish for people.&rdquo;
	<br />
	11 So they pulled their boats up on shore, left everything and followed him.
</p>
<h2>
	<br />
	Jesus Heals a Man With Leprosy
</h2>
<p>
	<br />
	12 While Jesus was in one of the towns, a man came along who was covered with leprosy. When he saw Jesus, he fell with his face to the ground and begged him, &ldquo;Lord, if you are willing, you can make me clean.&rdquo;
	<br />
	13 Jesus reached out his hand and touched the man. &ldquo;I am willing,&rdquo; he said. &ldquo;Be clean!&rdquo; And immediately the leprosy left him.
	<br />
	14 Then Jesus ordered him, &ldquo;Don&rsquo;t tell anyone, but go, show yourself to the priest and offer the sacrifices that Moses commanded for your cleansing, as a testimony to them.&rdquo;
	<br />
	15 Yet the news about him spread all the more, so that crowds of people came to hear him and to be healed of their sicknesses.
	<br />
	16 But Jesus often withdrew to lonely places and prayed.
</p>
<h2>
	<br />
	Jesus Forgives and Heals a Paralyzed Man
</h2>
<p>
	<br />
	17 One day Jesus was teaching, and Pharisees and teachers of the law were sitting there. They had come from every village of Galilee and from Judea and Jerusalem. And the power of the Lord was with Jesus to heal the sick.
	<br />
	18 Some men came carrying a paralyzed man on a mat and tried to take him into the house to lay him before Jesus.
	<br />
	19 When they could not find a way to do this because of the crowd, they went up on the roof and lowered him on his mat through the tiles into the middle of the crowd, right in front of Jesus.
	<br />
	20 When Jesus saw their faith, he said, &ldquo;Friend, your sins are forgiven.&rdquo;
	<br />
	21 The Pharisees and the teachers of the law began thinking to themselves, &ldquo;Who is this fellow who speaks blasphemy? Who can forgive sins but God alone?&rdquo;
	<br />
	22 Jesus knew what they were thinking and asked, &ldquo;Why are you thinking these things in your hearts?
	<br />
	23 Which is easier: to say, &lsquo;Your sins are forgiven,&rsquo; or to say, &lsquo;Get up and walk&rsquo;?
	<br />
	24 But I want you to know that the Son of Man has authority on earth to forgive sins.&rdquo; So he said to the paralyzed man, &ldquo;I tell you, get up, take your mat and go home.&rdquo;
	<br />
	25 Immediately he stood up in front of them, took what he had been lying on and went home praising God.
	<br />
	26 Everyone was amazed and gave praise to God. They were filled with awe and said, &ldquo;We have seen remarkable things today.&rdquo;
</p>
<h2>
	<br />
	Jesus Calls Levi and Eats With Sinners
</h2>
<p>
	<br />
	27 After this, Jesus went out and saw a tax collector by the name of Levi sitting at his tax booth. &ldquo;Follow me,&rdquo; Jesus said to him,
	<br />
	28 and Levi got up, left everything and followed him.
	<br />
	29 Then Levi held a great banquet for Jesus at his house, and a large crowd of tax collectors and others were eating with them.
	<br />
	30 But the Pharisees and the teachers of the law who belonged to their sect complained to his disciples, &ldquo;Why do you eat and drink with tax collectors and sinners?&rdquo;
	<br />
	31 Jesus answered them, &ldquo;It is not the healthy who need a doctor, but the sick.
	<br />
	32 I have not come to call the righteous, but sinners to repentance.&rdquo;
</p>
<h2>
	<br />
	Jesus Questioned About Fasting
</h2>
<p>
	<br />
	33 They said to him, &ldquo;John&rsquo;s disciples often fast and pray, and so do the disciples of the Pharisees, but yours go on eating and drinking.&rdquo;
	<br />
	34 Jesus answered, &ldquo;Can you make the friends of the bridegroom fast while he is with them?
	<br />
	35 But the time will come when the bridegroom will be taken from them; in those days they will fast.&rdquo;
	<br />
	36 He told them this parable: &ldquo;No one tears a piece out of a new garment to patch an old one. Otherwise, they will have torn the new garment, and the patch from the new will not match the old.
	<br />
	37 And no one pours new wine into old wineskins. Otherwise, the new wine will burst the skins; the wine will run out and the wineskins will be ruined.
	<br />
	38 No, new wine must be poured into new wineskins.
	<br />
	39 And no one after drinking old wine wants the new, for they say, &lsquo;The old is better.&rsquo; &rdquo;
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke5