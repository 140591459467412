import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah50 = () => {
  const verseNumber = 50;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 50 </title>
	<meta
          name="description"
          content="Jeremiah 50 | எரேமியா 50 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் தீர்க்கதரிசியாகிய எரேமியாவைக்கொண்டு பாபிலோனுக்கும் கல்தேயர் தேசத்துக்கும் விரோதமாக உரைத்த வசனம்:
	<br /><br />
	2. பாபிலோன் பிடிபட்டது; பேல் வெட்கப்பட்டது; மெரொதாக் நொறுங்குண்டது; அதினுடைய சிலைகள் இலச்சையடைந்தது; அதினுடைய விக்கிரகங்கள் நொறுங்கிப்போயின என்று ஜாதிகளுக்குள்ளே அறிவித்துப் பிரசித்தம்பண்ணுங்கள்; இதை மறைக்காமல் கொடியேற்றி விளம்பரம்பண்ணுங்கள்.
	<br /><br />
	3. அதற்கு விரோதமாய் வடக்கேயிருந்து ஒரு ஜாதி வந்து, அதின் தேசத்தைப் பாழாக்கிப்போடும்; அதிலே குடியிருப்பாரில்லை; மனுஷரோடே மிருகங்களும் ஓடிப்போய்விடும்.
	<br /><br />
	4. அந்நாட்களிலும் அக்காலத்திலும் இஸ்ரவேல் புத்திரர் வருவார்கள்; அவர்களும் யூதா புத்திரரும் ஏகமாய் அழுது, நடந்துவந்து, தங்கள் தேவனாகிய கர்த்தரைத் தேடுவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. மறக்கப்படாத நித்திய உடன்படிக்கையினால் நாம் கர்த்தரைச் சேர்ந்துகொள்வோம் வாருங்கள் என்று சீயோனுக்கு நேராய் முகங்களைத் திருப்பி, சீயோனுக்குப் போகிற வழி எதுவென்று கேட்டு விசாரிப்பார்கள்.
	<br /><br />
	6. என் ஜனங்கள் காணாமற்போன ஆடுகள், அவர்களுடைய மேய்ப்பர்கள் அவர்களைச் சிதறப்பண்ணி, பர்வதங்களில் அலையவிட்டார்கள்; ஒரு மலையிலிருந்து மறுமலைக்குப் போனார்கள்; தங்கள் தொழுவத்தை மறந்துவிட்டார்கள்.
	<br /><br />
	7. அவர்களைக் கண்டுபிடித்தவர்கள் எல்லாரும் அவர்களைப் பட்சித்தார்கள்; அவர்களுடைய சத்துருக்கள்: எங்கள்மேல் குற்றமில்லை; அவர்கள் நீதியின் வாசஸ்தலத்திலே கர்த்தருக்கு விரோதமாக, தங்கள் பிதாக்கள் நம்பின கர்த்தருக்கு விரோதமாகவே, பாவஞ்செய்தார்கள் என்றார்கள்.
	<br /><br />
	8. பாபிலோனின் நடுவிலிருந்தோடி, கல்தேயரின் தேசத்தைவிட்டுப் புறப்பட்டு, மந்தையின் முன்நடக்கும் கடாக்களைப்போல் இருங்கள்.
	<br /><br />
	9. இதோ, நான் பாபிலோனுக்கு விரோதமாக வடதேசத்தில் இருக்கும் பெரிய ஜாதிகளின் கூட்டத்தை எழுப்பி, அதை வரப்பண்ணுவேன்; அவர்கள் அதற்கு விரோதமாக ஆயத்தம்பண்ணுவார்கள்; அங்கேயிருந்து வருகிறவர்களால் அது பிடிக்கப்படும்; அவர்களுடைய அம்புகள் சாமர்த்தியமுள்ள பராக்கிரமசாலியின் அம்புகளைப்போல் இருக்கும்; அவைகள் விருதாவாய்த் திரும்புவதில்லை.
	<br /><br />
	10. கல்தேயா கொள்ளையாகும்: அதைக் கொள்ளையிடுகிறவர்கள் எல்லாரும் பரிபூரணமடைவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. என் சுதந்தரத்தைக் கொள்ளையிட்ட நீங்கள் சந்தோஷித்தீர்களே, களிகூர்ந்தீர்களே, புல்மேய்ந்து கொழுத்த கடாரியைப்போல் பூரித்து, வலியரிஷபம்போல் முக்காரம் போடுகிறீர்களே.
	<br /><br />
	12. உங்கள் தாய் மிகவும் வெட்கி, உங்களைப் பெற்றவள் நாணமடைவாள்; இதோ, அவள் ஜாதிகளுக்குள்ளே கடைசியாவதுமன்றி, வனாந்தரமும் வறட்சியும் அந்தரவெளியுமாவாள்.
	<br /><br />
	13. கர்த்தரின் கோபத்தினாலே அது குடியற்றதும் பெரும்பாழுமாயிருக்கும்; பாபிலோனைக் கடந்துபோகிற எவனும் அதின் எல்லா வாதைகளினிமித்தமும் பிரமித்து, ஈசல்போடுவான்.
	<br /><br />
	14. நீங்கள் எல்லாரும் பாபிலோனுக்கு விரோதமாய்ச் சுற்றிலும் அணிவகுத்து நின்று, வில்லை நாணேற்றி, அதின்மேல் அம்புகளை எய்யுங்கள்; அம்புச்செலவைப் பாராதேயுங்கள்; அது, கர்த்தருக்கு விரோதமாய்ப் பாவஞ்செய்தது.
	<br /><br />
	15. அதற்கு விரோதமாய்ச் சுற்றிலும் ஆர்ப்பரியுங்கள்; அது தன்னைக் கையளித்தது; அதின் அஸ்திபாரங்கள் விழுந்தது, அதின் மதில்கள் இடிக்கப்பட்டது; இது கர்த்தர் வாங்கும் பழி; அதினிடத்தில் பழிவாங்குங்கள்; அது செய்ததுபோலவே நீங்களும் அதற்குச் செய்யுங்கள்.
	<br /><br />
	16. விதைவிதைக்கிறவனையும் அறுப்புக்காலத்தில் அரிவாளைப் பிடிக்கிறவனையும் பாபிலோனில் இராதபடிச் சங்காரம்பண்ணுங்கள்; கொல்லுகிற பட்டயத்துக்குத் தப்ப அவரவர் தங்கள் ஜனத்தண்டைக்குத் திரும்பிக்கொண்டு, அவரவர் தங்கள் தேசத்துக்கு ஓடிப்போவார்கள்.
	<br /><br />
	17. இஸ்ரவேல் தெறிப்பட்டுப்போன ஆடு, சிங்கங்கள் அதைத் துரத்தின; முதலில் அசீரியா ராஜா அதைப் பட்சித்தான்; கடைசியில் பாபிலோன் ராஜாவாகிய இந்த நேபுகாத்நேச்சார் அதின் எலும்புகளை முறித்தான்.
	<br /><br />
	18. ஆகையால், இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் அசீரியா ராஜாவைத் தண்டித்ததுபோல் பாபிலோன் ராஜாவையும் அவன் தேசத்தையும் தண்டித்து,
	<br /><br />
	19. இஸ்ரவேலை அதின் வாசஸ்தலத்துக்குத் திரும்பிவரப்பண்ணுவேன்; அப்பொழுது அது கர்மேலிலும் பாசானிலும் மேயும்; எப்பிராயீமின் மலைகளிலும் கீலேயாத்திலும் அதின் ஆத்துமா திருப்தியாகும்.
	<br /><br />
	20. அந்நாட்களிலும் அக்காலத்திலும் இஸ்ரவேலின் அக்கிரமம் தேடப்பட்டாலும் அது காணாதிருக்கும்; யூதாவின் பாவங்கள் தேடப்பட்டாலும் அவைகள் கிடையாதிருக்கும்; நான் மீதியாக வைக்கிறவர்களுக்கு மன்னிப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	21. மெரதாயீம் தேசத்துக்கு விரோதமாகவும் பேகோடு குடிகளுக்கு விரோதமாகவும் நீ போய், அவர்களைத் துரத்தி, யாவையும் பாழாக்கிச் சங்காரம்பண்ணி, நான் உனக்குக் கட்டளையிட்டபடியெல்லாம் செய் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	22. தேசத்திலே யுத்தத்தின் சத்தமும் மகா சங்காரமும் உண்டு.
	<br /><br />
	23. சர்வ பூமியின் சம்மட்டி எப்படி முறித்து உடைக்கப்பட்டது! ஜாதிகளுக்குள்ளே பாபிலோன் எப்படிப் பாழாய்ப்போயிற்று!
	<br /><br />
	24. பாபிலோனே, உனக்குக் கண்ணியை வைத்தேன், நீ அதை அறியாமல் அதிலே சிக்குண்டுபோனாய்; நீ அகப்பட்டும் பிடிபட்டும் போனாய், நீ கர்த்தரோடே யுத்தங்கலந்தாயே.
	<br /><br />
	25. கர்த்தர் தம்முடைய ஆயுதசாலையைத் திறந்து, தம்முடைய சினத்தின் அஸ்திராயுதங்களை எடுத்துக்கொண்டு வந்தார்; இது கல்தேயர் தேசத்திலே சேனைகளின் கர்த்தராகிய ஆண்டவர் செய்கிற கிரியை.
	<br /><br />
	26. கடையாந்தரத்திலிருந்து அதற்கு விரோதமாக வந்து, அதின் களஞ்சியங்களைத் திறந்து, குவியல் குவியலாகக் குவித்து, அதில் ஒன்றும் மீதியாகாதபடிக்கு அதை முற்றிலும் அழித்துப்போடுங்கள்.
	<br /><br />
	27. அதின் காளைகளையெல்லாம் வெட்டுங்கள்; அவைகள் கொலைக்களம் சேரக்கடவது; ஐயோ! அவர்கள் விசாரிக்கப்படும் நாள் வந்ததே.
	<br /><br />
	28. நம்முடைய தேவன் பழிவாங்கினதை, அவர் தமது ஆலயத்துக்காகப் பழிவாங்கினதையே, சீயோனிலே அறிவிக்கும்படிக்கு, பாபிலோன் தேசத்திலிருந்து தப்பியோடிவந்தவர்களின் சத்தம் கேட்கப்படும்.
	<br /><br />
	29. பாபிலோனுக்கு விரோதமாய் வரும்படி வில்வீரரை அழையுங்கள்; வில் வளைக்கிறவர்களே, நீங்கள் எல்லாரும் அதற்கு விரோதமாய்ச் சுற்றிலும் பாளயமிறங்குங்கள், ஒருவரையும் தப்பவிடாதிருங்கள்; அதின் கிரியைக்குத்தக்க பலனை அதற்குச் சரிக்கட்டுங்கள்; அது செய்ததின்படியெல்லாம் அதற்குச் செய்யுங்கள்; அது இஸ்ரவேலின் பரிசுத்தராகிய கர்த்தருக்கு விரோதமாக இடும்புசெய்தது.
	<br /><br />
	30. ஆகையால் அதின் வாலிபர் அதின் வீதிகளில் விழுவார்கள்; அதின் யுத்தவீரர் எல்லாரும் அந்நாளிலே சங்காரமாவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	31. இதோ, இடும்புள்ளவனே, நான் உனக்கு விரோதமாக வருகிறேன் என்று சேனைகளின் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; நான் உன்னை விசாரிக்குங்காலமாகிய உன்னுடைய நாள் வந்தது.
	<br /><br />
	32. இடும்புள்ளவன் இடறிவிழுவான்; அவனை எடுத்து நிறுத்துவாரில்லை; நான் அவனுடைய பட்டணங்களில் அக்கினியைக் கொளுத்துவேன், அது அவன் சுற்றுப்புறத்தார் எல்லாரையும் பட்சிக்கும்.
	<br /><br />
	33. சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: இஸ்ரவேல் புத்திரரும் யூதா புத்திரரும் ஏகமாய் ஒடுங்குண்டார்கள்; அவர்களைச் சிறையாக்கின யாவரும் அவர்களை விடமாட்டோம் என்று கெட்டியாய்ப் பிடித்துக்கொண்டார்கள்.
	<br /><br />
	34. அவர்களுடைய மீட்பரோவெனில் வல்லமையுள்ளவர், சேனைகளின் கர்த்தர் என்பது அவருடைய நாமம்; தேசத்தை இளைப்பாறப்பண்ணுவதற்கும், பாபிலோன் குடிகளைத் தத்தளிக்கப்பண்ணுவதற்கும் அவர்களுடைய வழக்கை அவர் நடத்துவார்.
	<br /><br />
	35. பட்டயம் கல்தேயர்மேலும், பாபிலோன் குடிகள்மேலும், அதினுடைய பிரபுக்கள்மேலும், அதினுடைய ஞானிகள்மேலும் வருமென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	36. பட்டயம் பொய்களைப் பிணைக்கிறவர்கள்மேலும் வரும்; அவர்கள் பைத்தியக்காரராவார்கள்; பட்டயம் அதின் பராக்கிரமசாலிகள்மேலும் வரும், அவர்கள் கலங்குவார்கள்.
	<br /><br />
	37. பட்டயம் அதின் குதிரைகள்மேலும், அதின் இரதங்கள்மேலும், அதின் நடுவில் இருக்கிற பலஜாதியான ஜனங்கள் யாவர்மேலும் வரும், அவர்கள் பேடிகளாவார்கள்; பட்டயம் அதின் பொக்கிஷங்களின்மேல் வரும், அவைகள் கொள்ளையாகும்.
	<br /><br />
	38. வறட்சி அதின் தண்ணீர்கள்மேல் வரும், அவைகள் வறண்டுபோம்; அது விக்கிரக தேசம்; அருக்களிப்பான சிலைகளின்மேல் மனமயங்கியிருக்கிறார்கள்.
	<br /><br />
	39. ஆகையால் காட்டுமிருகங்களும் ஓரிகளும் அதிலே குடியிருக்கும்; கோட்டான்கள் அதிலே தங்கும்; இனி என்றென்றைக்கும் அது குடியேற்றப்படுவதில்லை; தலைமுறை தலைமுறையாக ஒருவரும் அதில் சஞ்சரிப்பதுமில்லை.
	<br /><br />
	40. தேவன் சோதோமையும் கொமோராவையும் அதின் சுற்றுப்புறங்களையும் கவிழ்த்துப்போட்டதுபோல இதையும் கவிழ்த்துப்போடுவேன் என்று கர்த்தர் சொல்லுகிறார்; ஒருவரும் அதில் குடியிருப்பதில்லை, ஒரு மனுபுத்திரனும் அதில் தங்குவதுமில்லை.
	<br /><br />
	41. இதோ, வடக்கேயிருந்து ஒரு ஜனமும் பெரிய ஜாதியும் வரும்; பூமியின் எல்லைகளிலிருந்து பலத்த ராஜாக்கள் எழும்புவார்கள்.
	<br /><br />
	42. அவர்கள் வில்லும் வேலும் பிடித்துவருவார்கள்; அவர்கள் இரக்கமில்லாத கொடியர்; அவர்கள் இரைச்சல் சமுத்திர இரைச்சல்போல் இருக்கும்; பாபிலோன் குமாரத்தியே, அவர்கள் உனக்கு விரோதமாக யுத்தத்துக்கு ஆயத்தப்பட்ட ஆட்களாய்க் குதிரைகளின்மேல் ஏறி வருவார்கள்.
	<br /><br />
	43. அவர்கள் வருகிற செய்தியை பாபிலோன் ராஜா கேட்கையில் அவன் கைகள் தளரும்; இடுக்கமும் பிரசவ வேதனைப்படுகிறவளுக்குண்டாகும் வேதனையைப்போன்ற வேதனையும் அவனைப் பிடிக்கும்.
	<br /><br />
	44. இதோ, புரண்டு ஓடுகிற யோர்தானிலிருந்து சிங்கத்தைப்போல் பலவானுடைய தாபரத்துக்கு விரோதமாக வருகிறான்; அவனை அங்கேயிருந்து சடிதியிலே ஓடிவரப்பண்ணுவேன்; நான் அதற்கு விரோதமாகக் கட்டளையிட்டு, அனுப்பத் தெரிந்துகொள்ளப்பட்டவன் யார்? எனக்குச் சமானமானவன் யார்? எனக்குத் திட்டஞ்சொல்பவன் யார்? எனக்கு முன்பாக நிற்கப்போகிற மேய்ப்பன் யார்?
	<br /><br />
	45. ஆகையால் கர்த்தர் பாபிலோனுக்கு விரோதமாக யோசித்த ஆலோசனையையும், அவர் கல்தேயர் தேசத்துக்கு விரோதமாக நினைத்திருக்கிற நினைவுகளையும் கேளுங்கள்; மெய்யாகவே மந்தையில் சிறியவர்கள் அவர்களைப் பிடித்திழுப்பார்கள்; மெய்யாகவே அவர்கள் இருக்கிற தாபரங்களை அவர் பாழாக்குவார்.
	<br /><br />
	46. பாபிலோன் பிடிபட்டதின் சத்தத்தினால் பூமி அதிரும், அதின் கூப்பிடுதல் ஜாதிகளுக்குள்ளே கேட்கப்படும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah50