import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 18 </title>
	<meta
          name="description"
          content="Ezekiel 18 | எசேக்கியேல்  18 |
          Ezekiel 18 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. பிதாக்கள் திராட்சக்காய்களைத் தின்றார்கள், பிள்ளைகளின் பற்கள் கூசிப்போயின என்னும் பழமொழியை நீங்கள் இஸ்ரவேல் தேசத்தைக்குறித்துச் சொல்லுகிறது என்ன?
	<br /><br />
	3. இனி இஸ்ரவேலில் இந்தப் பழமொழியைச் சொல்வது இல்லை என்பதை என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	4. இதோ, எல்லா ஆத்துமாக்களும் என்னுடையவைகள்; தகப்பனின் ஆத்துமா எப்படியோ, அப்படியே மகனின் ஆத்துமாவும் என்னுடையது; பாவஞ்செய்கிற ஆத்துமாவே சாகும்.
	<br /><br />
	5. ஒருவன் நீதிமானாயிருந்து, நியாயத்தையும் நீதியையும் செய்து,
	<br /><br />
	6. மலைகளின்மேல் சாப்பிடாமலும், இஸ்ரவேல் வம்சத்தாரின் நரகலான விக்கிரகங்களுக்கு நேராகத் தன் கண்களை ஏறெடுக்காமலும், தன் அயலானுடைய மனைவியைத் தீட்டுப்படுத்தாமலும் தூரஸ்திரீயோடே சேராமலும்,
	<br /><br />
	7. ஒருவனையும் ஒடுக்காமலும், கொள்ளையிடாமலுமிருந்து, கடன் வாங்கினவனுக்கு அடைமானத்தைத் திரும்பக்கொடுத்து, தன் அப்பத்தைப் பசித்தவனுக்குப் பங்கிட்டு, வஸ்திரமில்லாதவனுக்கு வஸ்திரம் தரிப்பித்து,
	<br /><br />
	8. வட்டிக்குக் கொடாமலும், பொலிசை வாங்காமலும், அநியாயத்துக்குத் தன் கையை விலக்கி, மனிதருக்குள்ள வழக்கை உண்மையாய்த் தீர்த்து,
	<br /><br />
	9. என் கட்டளைகளின்படி நடந்து, என் நியாயங்களைக் கைக்கொண்டு, உண்மையாயிருப்பானாகில் அவனே நீதிமான்; அவன் பிழைக்கவே பிழைப்பான் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	10. ஆனாலும் அவனுக்கு ஒரு குமாரன் பிறந்து, அவன் கள்ளனும் இரத்தஞ்சிந்துகிறவனும், மேற்சொல்லிய கடமைகளின்படி நடவாமல்,
	<br /><br />
	11. இவைகளில் ஒன்றுக்கொப்பானதைச் செய்கிறவனுமாயிருந்து, மலைகளின்மேல் சாப்பிட்டு, தன் அயலானுடைய மனைவியைத் தீட்டுப்படுத்தி,
	<br /><br />
	12. சிறுமையும் எளிமையுமானவனை ஒடுக்கி, கொள்ளைக்காரனாயிருந்து, அடைமானத்தைத் திரும்பக் கொடாமல், நரகலான விக்கிரகங்களுக்கு நேராகத் தன் கண்களை ஏறெடுத்து, அருவருப்பானதைச் செய்து,
	<br /><br />
	13. வட்டிக்குக் கொடுத்து, பொலிசை வாங்கினால், அவன் பிழைப்பானோ? அவன் பிழைப்பதில்லை; இந்த எல்லா அருவருப்புகளையும் செய்தானே; அவன் சாகவே சாவான்; அவன் இரத்தப்பழி அவன்மேல் இருக்கும்.
	<br /><br />
	14. பின்னும், இதோ, அவனுக்கு ஒரு குமாரன் பிறந்து, அவன் தன் தகப்பன் செய்த எல்லாப் பாவங்களையும் கண்டு, தான் அவைகளின்படி செய்யாதபடிக்கு எச்சரிக்கையாயிருந்து,
	<br /><br />
	15. மலைகளின்மேல் சாப்பிடாமலும், இஸ்ரவேல் வம்சத்தாரின் நரகலான விக்கிரகங்களுக்கு நேராகத் தன் கண்களை ஏறெடுக்காமலும், தன் அயலானுடைய மனைவியைத் தீட்டுப்படுத்தாமலும்,
	<br /><br />
	16. ஒருவனையும் ஒடுக்காமலும், அடைமானத்தை வைத்துக்கொண்டிராமலும், கொள்ளையிடாமலும், தன் அப்பத்தைப் பசித்தவனுக்குப் பங்கிட்டு, வஸ்திரமில்லாதவனுக்கு வஸ்திரந்தரிப்பித்து,
	<br /><br />
	17. சிறுமையானவனுக்கு நோவுண்டாக்காதபடித் தன் கையை விலக்கி, வட்டியும் பொலிசையும் வாங்காமலிருந்து என் நியாயங்களின்படி செய்து, என் கட்டளைகளில் நடந்தால், அவன் தன் தகப்பனுடைய அக்கிரமத்தினிமித்தம் சாகாமல் பிழைக்கவே பிழைப்பான்.
	<br /><br />
	18. அவன் தகப்பனோவென்றால் கொடுமைசெய்து, சகோதரனைக் கொள்ளையிட்டு, தகாததைத் தன் ஜனங்களின் நடுவிலே செய்தபடியினால், இதோ, அவன் தன் அக்கிரமத்திலே சாவான்.
	<br /><br />
	19. இதெப்படி, குமாரன் தகப்பனுடைய அக்கிரமத்தைச் சுமக்கிறதில்லையா என்று நீங்கள் கேட்டால், குமாரன் நியாயத்தையும் நீதியையும் செய்து, என் கட்டளைகளைக் கைக்கொண்டு, அவைகளின்படி செய்ததினால், அவன் பிழைக்கவே பிழைப்பான்.
	<br /><br />
	20. பாவஞ்செய்கிற ஆத்துமாவே சாகும்; குமாரன் தகப்பனுடைய அக்கிரமத்தைச் சுமப்பதுமில்லை, தகப்பன் குமாரனுடைய அக்கிரமத்தைச் சுமப்பதுமில்லை; நீதிமானுடைய நீதி அவன்மேல்தான் இருக்கும், துன்மார்க்கனுடைய துன்மார்க்கமும் அவன்மேல்தான் இருக்கும்.
	<br /><br />
	21. துன்மார்க்கன் தான் செய்த எல்லாப் பாவங்களையும் விட்டுத் திரும்பி, என் கட்டளைகளையெல்லாம் கைக்கொண்டு, நியாயத்தையும் நீதியையும் செய்வானேயாகில், அவன் பிழைக்கவே பிழைப்பான், அவன் சாவதில்லை.
	<br /><br />
	22. அவன் செய்த எல்லா மீறுதல்களும் நினைக்கப்படுவதில்லை; அவன் தான் செய்த நீதியிலே பிழைப்பான்.
	<br /><br />
	23. துன்மார்க்கன் சாகிறது எனக்கு எவ்வளவேனும் பிரியமோ? அவன் தன் வழிகளை விட்டுத் திரும்பிப் பிழைப்பது அல்லவோ எனக்குப் பிரியம் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	24. நீதிமான் தன் நீதியைவிட்டு விலகி, அநீதி செய்து, துன்மார்க்கன் செய்கிற சகல அருவருப்புகளின்படியும் செய்வானேயாகில், அவன் பிழைப்பானோ? அவன் செய்த அவனுடைய எல்லா நீதிகளும் நினைக்கப்படுவதில்லை; அவன் செய்த தன் துரோகத்திலேயும் அவன் செய்த தன் பாவத்திலேயும் சாவான்.
	<br /><br />
	25. நீங்களோ, ஆண்டவருடைய வழி செம்மையாய் இருக்கவில்லை என்கிறீர்கள்; இஸ்ரவேல் வம்சத்தாரே, கேளுங்கள்; என் வழி செம்மையாயிராதோ? உங்கள் வழிகள் அல்லவோ செம்மையல்லாததாயிருக்கிறது.
	<br /><br />
	26. நீதிமான் தன் நீதியைவிட்டு விலகி, அநீதிசெய்து அதிலே செத்தால், அவன் செய்த தன் அநீதியினிமித்தம் அவன் சாவான்.
	<br /><br />
	27. துன்மார்க்கன் தான் செய்த துன்மார்க்கத்தைவிட்டு விலகி, நியாயத்தையும் நீதியையும் செய்வானேயாகில், அவன் தன் ஆத்துமாவைப் பிழைக்கப்பண்ணுவான்.
	<br /><br />
	28. அவன் எச்சரிப்படைந்து, தான் செய்த எல்லா மீறுதல்களையும் விட்டுத் திரும்புகிறபடியினாலே அவன் பிழைக்கவே பிழைப்பான், அவன் சாவதில்லை.
	<br /><br />
	29. இஸ்ரவேல் வம்சத்தாரோ: ஆண்டவருடைய வழி செம்மையாய் இருக்கவில்லை என்கிறார்கள்; இஸ்ரவேல் வம்சத்தாரே, என் வழிகள் செம்மையாயிராதோ? உங்கள் வழிகள் அல்லவோ செம்மையல்லாததாயிருக்கிறது.
	<br /><br />
	30. ஆகையால் இஸ்ரவேல் வம்சத்தாரே, நான் உங்களில் அவனவனை அவனவன் வழிகளுக்குத் தக்கதாக நியாயந்தீர்ப்பேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; நீங்கள் மனந்திரும்புங்கள், உங்களுடைய எல்லா மீறுதல்களையும் விட்டுத் திரும்புங்கள்; அப்பொழுது அக்கிரமம் உங்கள் கேட்டுக்குக் காரணமாயிருப்பதில்லை.
	<br /><br />
	31. நீங்கள் துரோகம்பண்ணின உங்களுடைய எல்லாத் துரோகங்களையும் உங்கள்மேல் இராதபடிக்கு விலக்கி, உங்களுக்குப் புது இருதயத்தையும் புது ஆவியையும் உண்டுபண்ணிக்கொள்ளுங்கள்; இஸ்ரவேல் வம்சத்தாரே, நீங்கள் ஏன் சாகவேண்டும்?
	<br /><br />
	32. மனந்திரும்புங்கள், அப்பொழுது பிழைப்பீர்கள்; சாகிறவனுடைய சாவை நான் விரும்புகிறதில்லை என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial18