import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 13 </title>
	<meta
          name="description"
          content="Acts 13 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 13 |
          Acts of Apostles Chapter-13 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்தியோகியா பட்டணத்திலுள்ள சபையிலே பர்னபாவும், நீகர் என்னப்பட்ட சிமியோனும், சிரேனே ஊரானாகிய லூகியும், காற்பங்கு தேசாதிபதியாகிய ஏரோதுடனேகூட வளர்க்கப்பட்ட மனாயீனும், சவுலும், தீர்க்கதரிசிகளாயும் போதகர்களாயும் இருந்தார்கள்.
	<br /><br />
	2. அவர்கள் கர்த்தருக்கு ஆராதனைசெய்து, உபவாசித்துக்கொண்டிருக்கிறபோது: பர்னபாவையும் சவுலையும் நான் அழைத்த ஊழியத்துக்காக அவர்களைப் பிரித்துவிடுங்கள் என்று பரிசுத்தஆவியானவர் திருவுளம்பற்றினார்.
	<br /><br />
	3. அப்பொழுது உபவாசித்து ஜெபம்பண்ணி, அவர்கள்மேல் கைகளை வைத்து, அவர்களை அனுப்பினார்கள்.
	<br /><br />
	4. அப்படியே அவர்கள் பரிசுத்தஆவியினால் அனுப்பப்பட்டுச் செலூக்கியாபட்டணத்துக்கு வந்து, கப்பல் ஏறி, அங்கிருந்து சீப்புருதீவுக்குப் போனார்கள்.
	<br /><br />
	5. சாலமி பட்டணத்தில் வந்தபோது அவர்கள் யூதருடைய ஜெபஆலயங்களில் தேவவசனத்தைப் பிரசங்கித்தார்கள். யோவானும் அவர்களுக்கு உதவிக்காரனாயிருந்தான்.
	<br /><br />
	6. அவர்கள் பாப்போ பட்டணம்வரைக்கும் தீவைக் கடந்துவந்தபோது, பர்யேசு என்னும் பேர்கொண்ட மாயவித்தைக்காரனும் கள்ளத் தீர்க்கதரிசியுமான ஒரு யூதனைக் கண்டார்கள்.
	<br /><br />
	7. அவன் விவேகமுள்ள மனுஷனாகிய செர்கியுபவுல் என்னும் அதிபதியுடனேகூட இருந்தான். அந்த அதிபதி பர்னபாவையும் சவுலையும் அழைப்பித்து, அவர்களிடத்தில் தேவவசனத்தைக் கேட்க ஆசையாயிருந்தான்.
	<br /><br />
	8. மாயவித்தைக்காரன் என்று அர்த்தங்கொள்ளும் பேரையுடைய அந்த எலிமா என்பவன் அதிபதியை விசுவாசத்தினின்று திருப்பும்படி வகைதேடி, அவர்களோடு எதிர்த்துநின்றான்.
	<br /><br />
	9. அப்பொழுது பவுல் என்று சொல்லப்பட்ட சவுல் பரிசுத்தஆவியினால் நிறைந்தவனாய் அவனை உற்றுப்பார்த்து:
	<br /><br />
	10. எல்லாக் கபடமும் எல்லாப் பொல்லாங்கும் நிறைந்தவனே, பிசாசின் மகனே, நீதிக்கெல்லாம் பகைஞனே, கர்த்தருடைய செம்மையான வழிகளைப் புரட்டுவதில் ஓயமாட்டாயோ?
	<br /><br />
	11. இதோ, இப்பொழுதே, கர்த்தருடைய கை உன்மேல் வந்திருக்கிறது, சிலகாலம் சூரியனைக் காணாமல் நீ குருடனாயிருப்பாய் என்றான். உடனே மந்தாரமும் இருளும் அவன்மேல் விழுந்தது; அவன் தடுமாறி, கைலாகு கொடுக்கிறவர்களைத் தேடினான்.
	<br /><br />
	12. அப்பொழுது அதிபதி சம்பவித்ததைக் கண்டு, கர்த்தருடைய உபதேசத்தைக்குறித்து அதிசயப்பட்டு, விசுவாசித்தான்.
	<br /><br />
	13. பின்பு பவுலும் அவனைச் சேர்ந்தவர்களும் பாப்போ பட்டணத்தைவிட்டுக் கப்பல் ஏறிப் பம்பிலியாவிலிருக்கும் பெர்கே பட்டணத்துக்கு வந்தார்கள். யோவான் அவர்களை விட்டுப் பிரிந்து, எருசலேமுக்குத் திரும்பிப்போனான்.
	<br /><br />
	14. அவர்கள் பெர்கே பட்டணத்தை விட்டுப் புறப்பட்டு, பிசீதியா நாட்டிலுள்ள அந்தியோகியாவுக்கு வந்து, ஓய்வுநாளிலே ஜெபஆலயத்தில் பிரவேசித்து, உட்கார்ந்தார்கள்.
	<br /><br />
	15. நியாயப்பிரமாணமும் தீர்க்கதரிசன ஆகமமும் வாசித்துமுடிந்தபின்பு: சகோதரரே, நீங்கள் ஜனங்களுக்குப் புத்திசொல்ல விரும்பினால் சொல்லுங்கள் என்று சொல்லும்படி ஜெபஆலயத்தலைவர்கள் அவர்களிடத்தில் ஆள் அனுப்பினார்கள்.
	<br /><br />
	16. அப்பொழுது பவுல் எழுந்திருந்து, கையமர்த்தி: இஸ்ரவேலரே, தேவனுக்குப் பயந்து நடக்கிற சகல ஜனங்களே, கேளுங்கள்.
	<br /><br />
	17. இஸ்ரவேலராகிய இந்த ஜனத்தினுடைய தேவன் நம்முடைய பிதாக்களைத் தெரிந்துகொண்டு, எகிப்து தேசத்தில் அவர்கள் பரதேசிகளாய்ச் சஞ்சரித்தபோது ஜனங்களை உயர்த்தி, தமது புயபலத்தினாலே அதிலிருந்து அவர்களைப் புறப்படப்பண்ணி,
	<br /><br />
	18. நாற்பது வருஷகாலமாய் வனாந்தரத்தில் அவர்களை ஆதரித்து,
	<br /><br />
	19. கானான் தேசத்தில் ஏழு ஜாதிகளை அழித்து, அவர்களுடைய தேசத்தை இவர்களுக்குச் சுதந்தரமாகப் பங்கிட்டுக்கொடுத்து,
	<br /><br />
	20. பின்பு ஏறக்குறைய நானூற்றைம்பது வருஷகாலமாய் சாமுவேல் தீர்க்கதரிசிவரைக்கும் அவர்களுக்கு நியாயாதிபதிகளை ஏற்படுத்திவந்தார்.
	<br /><br />
	21. அதுமுதல் தங்களுக்கு ஒரு ராஜா வேண்டுமென்று கேட்டுக்கொண்டார்கள்; அப்படியே தேவன் பென்யமீன் கோத்திரத்தானாகிய கீசுடைய குமாரனான சவுலை நாற்பது வருஷகாலமாய் அவர்களுக்குக் கொடுத்தார்.
	<br /><br />
	22. பின்பு அவர் அவனைத் தள்ளி, தாவீதை அவர்களுக்கு ராஜாவாக ஏற்படுத்தி, ஈசாயின் குமாரனாகிய தாவீதை என் இருதயத்துக்கு ஏற்றவனாகக் கண்டேன்; எனக்குச் சித்தமானவைகளையெல்லாம் அவன் செய்வான் என்று அவனைக்குறித்துச் சாட்சியுங் கொடுத்தார்.
	<br /><br />
	23. அவனுடைய சந்ததியிலே தேவன் தமது வாக்குத்தத்தத்தின்படியே இஸ்ரவேலுக்கு இரட்சகராக இயேசுவை எழும்பப்பண்ணினார்.
	<br /><br />
	24. இவர் வெளிப்படுவதற்குமுன்னே மனந்திரும்புதலுக்கேற்ற ஞானஸ்நானத்தைக்குறித்து யோவான் இஸ்ரவேலர் யாவருக்கும் பிரசங்கித்தான்.
	<br /><br />
	25. யோவான் தன் பணிவிடை ஓட்டத்தை நிறைவேற்றுகிறபோது: நீங்கள் என்னை யார் என்று நினைக்கிறீர்கள், நான் அவர் அல்ல, இதோ, எனக்குப்பின் ஒருவர் வருகிறார், அவருடைய பாதரட்சையை அவிழ்க்கிறதற்கும் நான் பாத்திரன் அல்ல என்றான்.
	<br /><br />
	26. சகோதரரே, ஆபிரகாமின் சந்ததியில் பிறந்தவர்களே, தேவனுக்குப் பயந்து நடக்கிறவர்களே, இந்த இரட்சிப்பின் வசனம் உங்களுக்கு அனுப்பப்பட்டிருக்கிறது.
	<br /><br />
	27. எருசலேமில் குடியிருக்கிறவர்களும் அவர்கள் அதிகாரிகளும் அவரை அறியாமலும், ஓய்வுநாள்தோறும் வாசிக்கப்படுகிற தீர்க்கதரிசிகளின் வாக்கியங்களை அறியாமலும், அவரை ஆக்கினைக்குள்ளாகத் தீர்த்ததினால் அந்த வாக்கியங்களை நிறைவேற்றினார்கள்.
	<br /><br />
	28. மரணத்திற்கு ஏதுவானதொன்றும் அவரிடத்தில் காணாதிருந்தும், அவரைக் கொலைசெய்யும்படிக்குப் பிலாத்துவை வேண்டிக்கொண்டார்கள்.
	<br /><br />
	29. அவரைக்குறித்து எழுதியிருக்கிறவைகள் யாவையும் அவர்கள் நிறைவேற்றினபின்பு, அவரை மரத்திலிருந்து இறக்கி, கல்லறையிலே வைத்தார்கள்.
	<br /><br />
	30. தேவனோ அவரை மரித்தோரிலிருந்து எழுப்பினார்.
	<br /><br />
	31. தம்முடனேகூடக் கலிலேயாவிலிருந்து எருசலேமுக்குப் போனவர்களுக்கு அவர் அநேகநாள் தரிசனமானார்; அவர்களே ஜனங்களுக்கு முன்பாக அவருக்குச் சாட்சிகளாயிருக்கிறார்கள்.
	<br /><br />
	32. நீர் என்னுடைய குமாரன், இன்று நான் உம்மை ஜெநிப்பித்தேன் என்று இரண்டாம் சங்கீதத்தில் எழுதியிருக்கிறபடியே,
	<br /><br />
	33. இயேசுவை எழுப்பினதினாலே தேவன் நம்முடைய பிதாக்களுக்கு அருளிச்செய்த வாக்குத்தத்தத்தை அவர்களுடைய பிள்ளைகளாகிய நமக்கு நிறைவேற்றினார் என்று நாங்களும் உங்களுக்குச் சுவிசேஷமாய் அறிவிக்கிறோம்.
	<br /><br />
	34. இனி அவர் அழிவுக்குட்படாதபடிக்கு அவரை மரித்தோரிலிருந்து எழுப்பினார் என்பதைக்குறித்து: தாவீதுக்கு அருளின நிச்சயமான கிருபைகளை உங்களுக்குக் கட்டளையிடுவேன் என்று திருவுளம்பற்றினார்.
	<br /><br />
	35. அன்றியும், உம்முடைய பரிசுத்தர் அழிவைக் காணவொட்டீர் என்று வேறொரு சங்கீதத்தில் சொல்லியிருக்கிறது.
	<br /><br />
	36. தாவீது தன் காலத்திலே தேவனுடைய சித்தத்தின்படி அவருக்கு ஊழியஞ்செய்தபின்பு நித்திரையடைந்து, தன் பிதாக்களிடத்திலே சேர்க்கப்பட்டு, அழிவைக் கண்டான்.
	<br /><br />
	37. தேவனால் எழுப்பப்பட்டவரோ அழிவைக் காணவில்லை.
	<br /><br />
	38. ஆதலால் சகோதரரே, இவர் மூலமாய் உங்களுக்குப் பாவமன்னிப்பு உண்டாகும் என்று அறிவிக்கப்படுகிறதென்றும்,
	<br /><br />
	39. மோசேயின் நியாயப்பிரமாணத்தினாலே நீங்கள் எவைகளினின்று விடுதலையாகி நீதிமான்களாக்கப்படக்கூடாதிருந்ததோ, விசுவாசிக்கிறவன் எவனும் அவைகளினின்று இவராலே விடுதலையாகி நீதிமானாக்கப்படுகிறான் என்றும் உங்களுக்குத் தெரிந்திருக்கக்கடவது.
	<br /><br />
	40. அன்றியும், தீர்க்கதரிசிகளின் புஸ்தகத்திலே:
	<br /><br />
	41. அசட்டைக்காரரே, பாருங்கள், பிரமித்து அழிந்துபோங்கள்! உங்கள் நாட்களில் ஒரு கிரியையை நான் நடப்பிப்பேன், ஒருவன் அதை உங்களுக்கு விவரித்துச் சொன்னாலும் நீங்கள் விசுவாசிக்கமாட்டீர்கள் என்று சொல்லியிருக்கிறபடி, உங்களுக்கு நேரிடாதபடிக்கு எச்சரிக்கையாயிருங்கள் என்றான்.
	<br /><br />
	42. அவர்கள் யூதருடைய ஜெபஆலயத்திலிருந்து புறப்படுகையில், அடுத்த ஓய்வுநாளிலே இந்த வசனங்களைத் தங்களுக்குச் சொல்லவேண்டும் என்று புறஜாதியார் வேண்டிக்கொண்டார்கள்.
	<br /><br />
	43. ஜெபஆலயத்தில் கூடின சபை கலைந்துபோனபின்பு, யூதரிலும் யூதமார்க்கத்தமைந்த பக்தியுள்ளவர்களிலும் அநேகர் பவுலையும் பர்னபாவையும் பின்பற்றினார்கள். அவர்களுடனே இவர்கள் பேசி, தேவனுடைய கிருபையிலே நிலைகொண்டிருக்கும்படி அவர்களுக்குப் புத்திசொன்னார்கள்.
	<br /><br />
	44. அடுத்த ஓய்வுநாளிலே கொஞ்சங்குறையப் பட்டணத்தாரனைவரும் தேவவசனத்தைக் கேட்கும்படி கூடிவந்தார்கள்.
	<br /><br />
	45. யூதர்கள் ஜனக்கூட்டங்களைக் கண்டபோது பொறாமையினால் நிறைந்து, பவுலினால் சொல்லப்பட்டவைகளுக்கு எதிரிடையாய்ப் பேசி, விரோதித்துத் தூஷித்தார்கள்.
	<br /><br />
	46. அப்பொழுது பவுலும் பர்னபாவும் தைரியங்கொண்டு அவர்களை நோக்கி: முதலாவது உங்களுக்கே தேவவசனத்தைச் சொல்லவேண்டியதாயிருந்தது; நீங்களோ அதைத் தள்ளி, உங்களை நித்தியஜீவனுக்கு அபாத்திரராகத் தீர்த்துக் கொள்ளுகிறபடியினால், இதோ, நாங்கள் புறஜாதியாரிடத்தில் போகிறோம்.
	<br /><br />
	47. நீர் பூமியின் கடைசிபரியந்தமும் இரட்சிப்பாயிருக்கும்படி உம்மை ஜாதிகளுக்கு ஒளியாக வைத்தேன் என்கிற வேதவாக்கியத்தின்படி கர்த்தர் எங்களுக்குக் கட்டளையிட்டிருக்கிறபடியினால் இப்படிச் செய்கிறோம் என்றார்கள்.
	<br /><br />
	48. புறஜாதியார் அதைக் கேட்டுச் சந்தோஷப்பட்டு, கர்த்தருடைய வசனத்தை மகிமைப்படுத்தினார்கள். நித்திய ஜீவனுக்கு நியமிக்கப்பட்டவர்கள் எவர்களோ அவர்கள் விசுவாசித்தார்கள்.
	<br /><br />
	49. கர்த்தருடைய வசனம் அத்தேசமெங்கும் பிரசித்தமாயிற்று.
	<br /><br />
	50. யூதர்கள் பக்தியும் கனமுமுள்ள ஸ்திரீகளையும் பட்டணத்து முதலாளிகளையும் எடுத்துவிட்டு, பவுலையும் பர்னபாவையும் துன்பப்படுத்தும்படி செய்து, தங்கள் எல்லைகளுக்குப் புறம்பாக அவர்களைத் துரத்திவிட்டார்கள்.
	<br /><br />
	51. இவர்கள் தங்கள் கால்களில் படிந்த தூசியை அவர்களுக்கு எதிராக உதறிப்போட்டு, இக்கோனியா பட்டணத்துக்குப் போனார்கள்.
	<br /><br />
	52. சீஷர்கள் சந்தோஷத்தினாலும் பரிசுத்த ஆவியினாலும் நிரப்பப்பட்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts13