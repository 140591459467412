import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah43 = () => {
  const verseNumber = 43;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 43 </title>
	<meta
          name="description"
          content="Jeremiah 43 | எரேமியா 43 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எரேமியா சகல ஜனங்களுக்கும் அவர்களுடைய தேவனாகிய கர்த்தர் தன்னைக்கொண்டு அவர்களுக்குச் சொல்லியனுப்பின எல்லா வார்த்தைகளையும் சொன்னான்; அவர்களுடைய தேவனாகிய கர்த்தருடைய எல்லா வார்த்தைகளையும் அவன் அவர்களுக்குச் சொல்லிமுடித்தபின்பு,
	<br /><br />
	2. ஓசாயாவின் குமாரனாகிய அசரியாவும், கரேயாவின் குமாரனாகிய யோகனானும், அகங்காரிகளான எல்லா மனுஷரும் எரேமியாவை நோக்கி: நீ பொய் சொல்லுகிறாய்; எகிப்திலே தங்கும்படிக்கு அங்கே போகாதிருங்கள் என்று சொல்ல, எங்கள் தேவனாகிய கர்த்தர் உன்னை எங்களிடத்துக்கு அனுப்பவில்லை.
	<br /><br />
	3. கல்தேயர் எங்களைக் கொன்றுபோடவும், எங்களைப் பாபிலோனுக்குச் சிறைகளாகக் கொண்டுபோகவும், எங்களை அவர்கள் கையில் ஒப்புக்கொடுக்கும்படி, நேரியாவின் குமாரனாகிய பாருக்குத்தானே உன்னை எங்களுக்கு விரோதமாக ஏவினான் என்றார்கள்.
	<br /><br />
	4. அப்படியே யூதாவின் தேசத்திலே தரித்திருக்கவேண்டும் என்னும் கர்த்தருடைய சத்தத்துக்குக் கரேயாவின் குமாரனாகிய யோகனானும், சகல இராணுவச் சேர்வைக்காரரும், சகல ஜனங்களும் செவிகொடாமற்போனார்கள்.
	<br /><br />
	5. யூதா தேசத்தில் தங்கியிருப்பதற்கு, தாங்கள் துரத்துண்டிருந்த சகல ஜாதிகளிடத்திலுமிருந்து திரும்பிவந்த மீதியான யூதரெல்லாரையும், புருஷரையும், ஸ்திரீகளையும், குழந்தைகளையும், ராஜாவின் குமாரத்திகளையும், காவற்சேனாதிபதியாகிய நேபுசராதான், சாப்பானின் குமாரனாகிய அகிக்காமின் மகனான கெதலியாவினிடத்தில் விட்டுப்போன சகல ஆத்துமாக்களையும், தீர்க்கதரிசியாகிய எரேமியாவையும், நேரியாவின் குமாரனாகிய பாருக்கையும்,
	<br /><br />
	6. கரேயாவின் குமாரனாகிய யோகனானும் சகல இராணுவச் சேர்வைக்காரரும் கூட்டிக்கொண்டு,
	<br /><br />
	7. கர்த்தருடைய சத்தத்துக்குச் செவிகொடாதபடியினாலே, எகிப்துதேசத்துக்குப் போக எத்தனித்து, அதிலுள்ள தக்பானேஸ்மட்டும் போய்ச் சேர்ந்தார்கள்.
	<br /><br />
	8. தக்பானேசிலே கர்த்தருடைய வார்த்தை எரேமியாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	9. நீ உன் கையிலே பெரிய கற்களை எடுத்துக்கொண்டு, யூதா ஜனங்களுக்கு முன்பாக அவைகளைத் தக்பானேசில் இருக்கிற பார்வோனுடைய அரமனையின் ஒலிமுகவாசலில் இருக்கிற சூளையின் களிமண்ணிலே புதைத்து வைத்து,
	<br /><br />
	10. அவர்களை நோக்கி: இதோ, என் ஊழியக்காரனாகிய நேபுகாத்நேச்சார் என்கிற பாபிலோன் ராஜாவை நான் அழைத்தனுப்பி, நான் புதைப்பித்த இந்தக் கற்களின்மேல் அவனுடைய சிங்காசனத்தை வைப்பேன்; அவன் தன் ராஜகூடாரத்தை அவைகளின்மேல் விரிப்பான்.
	<br /><br />
	11. அவன் வந்து, எகிப்துதேசத்தை அழிப்பான்; சாவுக்கு ஏதுவானவன் சாவுக்கும், சிறையிருப்புக்கு ஏதுவானவன் சிறையிருப்புக்கும், பட்டயத்துக்கு ஏதுவானவன் பட்டயத்துக்கும் உள்ளாவான்.
	<br /><br />
	12. எகிப்தின் தேவர்களுடைய கோவில்களில் அக்கினியைக் கொளுத்துவேன்; அவன் அவைகளைச் சுட்டெரித்து, அவைகளைச் சிறைபிடித்துப்போய், ஒரு மேய்ப்பன் தன் கம்பளியைப் போர்த்துக்கொள்ளுமாப்போல் எகிப்துதேசத்தைப் போர்த்துக்கொண்டு, அவ்விடத்திலிருந்து சுகமாய்ப் புறப்பட்டுப்போவான்.
	<br /><br />
	13. அவன் எகிப்துதேசத்தில் இருக்கிற பெத்ஷிமேசின் சிலைகளை உடைத்து, எகிப்தின் தேவர்களுடைய கோவில்களை அக்கினியால் சுட்டுப்போடுவான் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah43