import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial43 = () => {
  const verseNumber = 43;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 43 </title>
	<meta
          name="description"
          content="Ezekiel 43 | எசேக்கியேல்  43 |
          Ezekiel 43 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் என்னைக் கீழ்த்திசைக்கு எதிர் வாசலாகிய வாசலுக்கு அழைத்துக்கொண்டுபோனார்.
	<br /><br />
	2. இதோ, இஸ்ரவேலின் தேவனுடைய மகிமை கீழ்த்திசையிலிருந்து வந்தது; அவருடைய சத்தம் பெருவெள்ளத்தின் இரைச்சலைப்போல இருந்தது; அவருடைய மகிமையினால் பூமி பிரகாசித்தது.
	<br /><br />
	3. நான் கண்ட இந்தத் தரிசனம் நகரத்தை அழிக்கவந்தபோது கண்ட தரிசனம்போல இருந்தது; இந்தத் தரிசனங்கள் கேபார் நதியண்டையிலே நான் கண்டிருந்த தரிசனத்தைப்போலும் இருந்தது; நான் முகங்குப்புற விழுந்தேன்.
	<br /><br />
	4. கர்த்தருடைய மகிமை கீழ்த்திசைக்கு எதிரான வாசல்வழியாய் ஆலயத்துக்குள் பிரவேசித்தது.
	<br /><br />
	5. அப்பொழுது ஆவி என்னை எடுத்து, உட்பிராகாரத்திலே கொண்டுபோய்விட்டது; இதோ, கர்த்தருடைய மகிமை ஆலயத்தை நிரப்பிற்று.
	<br /><br />
	6. அவர் ஆலயத்திலிருந்து என்னோடே பேசுகிறதைக் கேட்டேன்; அந்தப் புருஷன் என்னண்டையில் நின்றிருந்தார்.
	<br /><br />
	7. அவர் என்னை நோக்கி: மனுபுத்திரனே, இது நான் இஸ்ரவேல் புத்திரரின் நடுவே என்றென்றைக்கும் வாசம்பண்ணும் என் சிங்காசனமும் என் பாதபீடத்தின் ஸ்தானமுமாயிருக்கிறது; இனி இஸ்ரவேல் வம்சத்தாரும் அவர்களுடைய ராஜாக்களும் என் பரிசுத்த நாமத்தைத் தங்கள் மேடைகளில் தங்கள் வேசித்தனத்தினாலும் தங்கள் ராஜாக்களின் பிரேதங்களினாலும் தீட்டுப்படுத்துவதில்லை.
	<br /><br />
	8. அவர்கள் எனக்கும் தங்களுக்கும் நடுவே ஒரு சுவர் இருக்கும்படி, தங்கள் வாசற்படியை என் வாசற்படியண்டையிலும், தங்கள் வாசல்நிலைகளை என் வாசல் நிலைகளண்டையிலும் சேர்த்து, என் பரிசுத்த நாமத்தைத் தாங்கள் செய்த அருவருப்புகளினால் தீட்டுப்படுத்தினார்கள்; ஆகையால் என் கோபத்திலே அவர்களை நாசமாக்கினேன்.
	<br /><br />
	9. இப்பொழுதும் அவர்கள் தங்கள் வேசித்தனத்தையும் தங்கள் ராஜாக்களின் பிரேதங்களையும் என் சமுகத்தினின்று அகற்றினால் நான் என்றென்றைக்கும் அவர்கள் நடுவே வாசமாயிருப்பேன்.
	<br /><br />
	10. மனுபுத்திரனே, இஸ்ரவேல் வம்சத்தார் தங்கள் அக்கிரமங்களினிமித்தம் வெட்கப்படும்படிக்கு, நீ அவர்களுக்கு இந்த ஆலயத்தைக் காண்பி; அதின் அளவை அளக்கக்கடவர்கள்.
	<br /><br />
	11. அவர்கள் செய்த எல்லாவற்றினிமித்தமும் வெட்கப்பட்டால், அப்பொழுது இந்த ஆலயத்தின் ரூபத்தையும், அதின் அளவையும், அதின் முன் வாசல்களையும், அதின் பின் வாசல்களையும், அதின் எல்லா ஒழுங்குகளையும், அதின் எல்லாக் கட்டளைகளையும், அதின் எல்லா நியமங்களையும், அதின் எல்லாச் சட்டங்களையும் அவர்களுக்குத் தெரியப்படுத்தி, அவர்கள் அதினுடைய எல்லா ஒழுங்குகளையும், அதினுடைய எல்லா முறைமைகளையும் கைக்கொண்டு, அவைகளின்படி செய்யும்படிக்கு அதை அவர்கள் கண்களுக்கு முன்பாக எழுதிவை.
	<br /><br />
	12. ஆலயத்தினுடைய பிரமாணம் என்னவென்றால்: மலையுச்சியின்மேல் சுற்றிலும் அதின் எல்லையெங்கும் மிகவும் பரிசுத்தமாயிருக்கும்; இதுவே ஆலயத்தினுடைய பிரமாணம்.
	<br /><br />
	13. முழங்களின்படி அளக்கும் பலிபீடத்தின் அளவுகளாவன: ஒரு கை முழமும் நாலு விரற்கடையும் கொண்டது ஒரு முழமாகும்; அதின்படி சுற்றாதாரம், ஒரு முழ உயரமும், ஒரு முழ அகலமும், அதின் ஓரத்தைச் சுற்றிலுமுள்ள விளிம்பு ஒரு ஜாணுமாயிருக்கும்; இது பலிபீடத்தின் மேற்புறம்.
	<br /><br />
	14. தரையில் இருக்கிற ஆதாரந்துவக்கிக் கீழ்நிலைமட்டும் இரண்டுமுழமும், அகலம் ஒரு முழமும், சின்ன நிலைதுவக்கிப் பெரிய நிலைமட்டும் நாலு முழமும், அகலம் ஒரு முழமுமாயிருக்கும்.
	<br /><br />
	15. பலிபீடத்தின் சிகரம் நாலு முழ உயரமாயிருக்கும்; பலிபீடத்தின் சிகரத்துக்குமேலே நாலு கொம்புகள் இருக்கும்.
	<br /><br />
	16. பலிபீடத்தின் சிகரம் பன்னிரண்டு முழ நீளமும், பன்னிரண்டு முழ அகலமும் தன் நாலு பக்கங்களிலும் நாற்சதுரமுமாயிருக்கும்.
	<br /><br />
	17. அதின் நாலு பக்கங்களிலுள்ள சட்டத்தின் நீளம் பதிநாலு முழமும், அகலம் பதிநாலு முழமும், அதைச் சுற்றிலுமிருக்கிற விளிம்பு அரை முழமும், அதற்கு ஆதாரமானது சுற்றிலும் ஒரு முழமுமாயிருக்கும்; அதின் படிகள் கிழக்குக்கு எதிராயிருக்கும் என்றார்.
	<br /><br />
	18. பின்னும் அவர் என்னை நோக்கி: மனுபுத்திரனே, கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: பலிபீடத்தை உண்டுபண்ணும் நாளிலே அதின்மேல் தகனபலியிடுகிறதற்கும் அதின்மேல் இரத்தம் தெளிக்கிறதற்குமான கட்டளைகளாவன:
	<br /><br />
	19. எனக்கு ஆராதனை செய்கிறதற்கு என்னிடத்தில் சேருகிற சாதோக்கின் வம்சத்தாரான லேவி கோத்திரத்தாராகிய ஆசாரியர்களுக்கு நீ பாவநிவாரணபலியாக ஒரு இளங்காளையைக் கொடுப்பாயாக என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	20. அதின் இரத்தத்தில் கொஞ்சம் எடுத்து, அதின் நாலு கொம்புகளிலும், சட்டத்தின் நாலு கோடிகளிலும், சுற்றியிருக்கிற விளிம்பிலும் பூசி பாவநிவிர்த்திசெய்து, அதைச் சுத்திகரித்து,
	<br /><br />
	21. பின்பு பாவநிவாரணத்தின் காளையைக் கொண்டுபோய், அதை ஆலயத்திலே பரிசுத்த ஸ்தலத்துக்குப் புறம்பாகக் குறிக்கப்பட்ட இடத்திலே சுட்டெரிக்கவேண்டும்.
	<br /><br />
	22. இரண்டாம் நாளிலே பழுதற்ற ஒரு வெள்ளாட்டுக்கடாவைப் பாவநிவாரணத்துக்காகப் பலியிடுவாயாக; அவர்கள் இளங்காளையினாலே பலிபீடத்தைச் சுத்திசெய்ததுபோலப் பாவநிவாரணஞ் செய்யவேண்டும்.
	<br /><br />
	23. நீ பாவநிவாரணத்தை முடித்தபின்பு, பழுதற்ற ஒரு இளங்காளையையும், பழுதற்ற ஒரு ஆட்டுக்கடாவையும் மந்தையிலிருந்தெடுத்துப் பலியிடுவாயாக.
	<br /><br />
	24. அவைகளைக் கர்த்தருடைய சந்நிதியில் பலியிடுவாயாக; ஆசாரியர்கள் அவைகளின்மேல் உப்புதூவி, அவைகளைக் கர்த்தருக்குத் தகனபலியாக இடக்கடவர்கள்.
	<br /><br />
	25. ஏழுநாள்வரைக்கும் தினந்தினம் பாவநிவாரணத்துக்காக ஒரு வெள்ளாட்டுக்கடாவைப் படைப்பாயாக; பழுதற்றவைகளான இளங்காளையையும் மந்தையிலிருந்தெடுத்த ஆட்டுக்கடாவையும் படைப்பார்களாக.
	<br /><br />
	26. ஏழுநாள்வரைக்கும் பலிபீடத்தைப் பாவநிவிர்த்திசெய்து, அதைச் சுத்திகரித்து, பிரதிஷ்டைபண்ணக்கடவர்கள்.
	<br /><br />
	27. அந்நாட்கள் முடிந்தபின்பு, எட்டாம் நாள்முதல் ஆசாரியர்கள் பலிபீடத்தின்மேல் உங்கள் தகனபலிகளையும் உங்கள் ஸ்தோத்திரபலிகளையும் படைப்பார்களாக; அப்பொழுது உங்களை அங்கீகரிப்பேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial43