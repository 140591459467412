import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 14 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 14 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 14 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அன்பை நாடுங்கள்; ஞானவரங்களையும் விரும்புங்கள்; விசேஷமாய்த் தீர்க்கதரிசன வரத்தை விரும்புங்கள்.
	<br /><br />
	2. ஏனெனில், அந்நியபாஷையில் பேசுகிறவன், ஆவியினாலே இரகசியங்களைப் பேசினாலும், அவன் பேசுகிறதை ஒருவனும் அறியாதிருக்கிறபடியினாலே, அவன் மனுஷரிடத்தில் பேசாமல், தேவனிடத்தில் பேசுகிறான்.
	<br /><br />
	3. தீர்க்கதரிசனஞ் சொல்லுகிறவனோ மனுஷருக்குப் பக்திவிருத்தியும், புத்தியும், ஆறுதலும் உண்டாகத்தக்கதாகப் பேசுகிறான்.
	<br /><br />
	4. அந்நியபாஷையில் பேசுகிறவன் தனக்கே பக்திவிருத்தி உண்டாகப் பேசுகிறான்; தீர்க்கதரிசனஞ்சொல்லுகிறவனோ சபைக்குப் பக்திவிருத்தி உண்டாகப் பேசுகிறான்.
	<br /><br />
	5. நீங்களெல்லாரும் அந்நியபாஷைகளைப் பேசும்படி விரும்புகிறேன்; ஆகிலும், அந்நியபாஷைகளில் பேசுகிறவன் சபைக்குப் பக்திவிருத்தி உண்டாகும்படிக்கு அர்த்தத்தையும் சொல்லாவிட்டால், தீர்க்கதரிசனஞ் சொல்லுகிறவன் அவனிலும் மேன்மையுள்ளவன்; ஆதலால் நீங்கள் தீர்க்கதரிசனஞ் சொல்லுகிறவர்களாக வேண்டுமென்று அதிகமாய் விரும்புகிறேன்.
	<br /><br />
	6. மேலும், சகோதரரே, நான் உங்களிடத்தில் வந்து உங்களுக்கு இரகசியங்களை வெளிப்படுத்துவதற்காவது, அறிவுண்டாக்குவதற்காவது, தீர்க்கதரிசனத்தை அறிவிக்கிறதற்காவது, போதகத்தைப் போதிக்கிறதற்காவது ஏதுவானதைச் சொல்லாமல், அந்நியபாஷைகளில் பேசினால் என்னாலே உங்களுக்குப் பிரயோஜனம் என்ன?
	<br /><br />
	7. அப்படியே புல்லாங்குழல், சுரமண்டலம் முதலாகிய சத்தமிடுகிற உயிரில்லாத வாத்தியங்கள் தொனிகளில் வித்தியாசம் காட்டாவிட்டால், குழலாலே ஊதப்படுகிறதும், சுரமண்டலத்தாலே வாசிக்கப்படுகிறதும் இன்னதென்று எப்படித் தெரியும்?
	<br /><br />
	8. அந்தப்படி எக்காளமும் விளங்காத சத்தமிட்டால் எவன் யுத்தத்திற்கு ஆயத்தம்பண்ணுவான்?
	<br /><br />
	9. அதுபோல, நீங்களும் தெளிவான பேச்சை நாவினால் வசனியாவிட்டால் பேசப்பட்டது இன்னதென்று எப்படித்தெரியும்? ஆகாயத்தில் பேசுகிறவர்களாயிருப்பீர்களே.
	<br /><br />
	10. உலகத்திலே எத்தனையோவிதமான பாஷைகள் உண்டாயிருக்கிறது, அவைகளில் ஒன்றும் அர்த்தமில்லாததல்ல.
	<br /><br />
	11. ஆயினும், பாஷையின் கருத்தை நான் அறியாமலிருந்தால், பேசுகிறவனுக்கு அந்நியனாயிருப்பேன், பேசுகிறவனும் எனக்கு அந்நியனாயிருப்பான்.
	<br /><br />
	12. நீங்களும் ஆவிக்குரிய வரங்களை நாடுகிறவர்களானபடியால், சபைக்குப் பக்திவிருத்தி உண்டாகத்தக்கதாக அவைகளில் தேறும்படி நாடுங்கள்;
	<br /><br />
	13. அந்தப்படி அந்நியபாஷையில் பேசுகிறவன் அதின் அர்த்தத்தையும் சொல்லத்தக்கதாக விண்ணப்பம்பண்ணக்கடவன்.
	<br /><br />
	14. என்னத்தினாலெனில், நான் அந்நியபாஷையிலே விண்ணப்பம்பண்ணினால், என் ஆவி விண்ணப்பம்பண்ணுமேயன்றி, என் கருத்து பயனற்றதாயிருக்கும்.
	<br /><br />
	15. இப்படியிருக்க, செய்யவேண்டுவதென்ன? நான் ஆவியோடும் விண்ணப்பம்பண்ணுவேன், கருத்தோடும் விண்ணப்பம்பண்ணுவேன்; நான் ஆவியோடும் பாடுவேன், கருத்தோடும் பாடுவேன்.
	<br /><br />
	16. இல்லாவிட்டால், நீ ஆவியோடு ஸ்தோத்திரம்பண்ணும்போது, கல்லாதவன் உன் ஸ்தோத்திரத்திற்கு ஆமென் என்று எப்படிச் சொல்லுவான்? நீ பேசுகிறது இன்னதென்று அவன் அறியானே.
	<br /><br />
	17. நீ நன்றாய் ஸ்தோத்திரம்பண்ணுகிறாய், ஆகிலும் மற்றவன் பக்திவிருத்தியடையமாட்டானே.
	<br /><br />
	18. உங்களெல்லாரிலும் நான் அதிகமாய்ப் பாஷைகளைப் பேசுகிறேன், இதற்காக என் தேவனை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	19. அப்படியிருந்தும், நான் சபையிலே அந்நியபாஷையில் பதினாயிரம் வார்த்தைகளைப் பேசுகிறதிலும், மற்றவர்களை உணர்த்தும்படி என் கருத்தோடே ஐந்து வார்த்தைகளைப் பேசுகிறதே எனக்கு அதிக விருப்பமாயிருக்கும்.
	<br /><br />
	20. சகோதரரே, நீங்கள் புத்தியிலே குழந்தைகளாயிராதேயுங்கள்; துர்க்குணத்திலே குழந்தைகளாயும், புத்தியிலோ தேறினவர்களாயுமிருங்கள்.
	<br /><br />
	21. மறுபாஷைக்காரராலும், மறுஉதடுகளாலும் இந்த ஜனங்களிடத்தில் பேசுவேன்; ஆகிலும் அவர்கள் எனக்குச் செவிகொடுப்பதில்லையென்று கர்த்தர் சொல்லுகிறார் என்று வேதத்தில் எழுதியிருக்கிறதே.
	<br /><br />
	22. அப்படியிருக்க, அந்நியபாஷைகள் விசுவாசிகளுக்கு அடையாளமாயிராமல், அவிசுவாசிகளுக்கு அடையாளமாயிருக்கிறது; தீர்க்கதரிசனமோ அவிசுவாசிகளுக்கு அடையாளமாயிராமல், விசுவாசிகளுக்கு அடையாளமாயிருக்கிறது.
	<br /><br />
	23. ஆகையால், சபையாரெல்லாரும் ஏகமாய்க் கூடிவந்து, எல்லாரும் அந்நியபாஷைகளிலே பேசிக்கொள்ளும்போது, கல்லாதவர்களாவது, அவிசுவாசிகளாவது உள்ளே பிரவேசித்தால், அவர்கள் உங்களைப் பைத்தியம் பிடித்தவர்களென்பார்களல்லவா?
	<br /><br />
	24. எல்லாரும் தீர்க்கதரிசனஞ் சொல்லுகையில், அவிசுவாசியொருவன், அல்லது கல்லாதவனொருவன், உள்ளே பிரவேசித்தால், அவன் எல்லாராலும் உணர்த்துவிக்கப்பட்டும், எல்லாராலும் நிதானிக்கப்பட்டும் இருப்பான்.
	<br /><br />
	25. அவனுடைய இருதயத்தின் அந்தரங்கங்களும் வெளியரங்கமாகும்; அவன் முகங்குப்புற விழுந்து, தேவனைப்பணிந்துகொண்டு, தேவன் மெய்யாய் உங்களுக்குள்ளே இருக்கிறாரென்று அறிக்கையிடுவான்.
	<br /><br />
	26. நீங்கள் கூடிவந்திருக்கிறபோது, உங்களில் ஒருவன் சங்கீதம் பாடுகிறான், ஒருவன் போதகம்பண்ணுகிறான், ஒருவன் அந்நியபாஷையைப் பேசுகிறான், ஒருவன் இரகசியத்தை வெளிப்படுத்துகிறான், ஒருவன் வியாக்கியானம் பண்ணுகிறான். சகோதரரே, இது என்ன? சகலமும் பக்திவிருத்திக்கேதுவாகச் செய்யப்படக்கடவது.
	<br /><br />
	27. யாராவது அந்நியபாஷையிலே பேசுகிறதுண்டானால், அது இரண்டுபேர்மட்டில், அல்லது மிஞ்சினால் மூன்றுபேர்மட்டில் அடங்கவும், அவர்கள் ஒவ்வொருவராய்ப் பேசவும், ஒருவன் அர்த்தத்தைச் சொல்லவும் வேண்டும்.
	<br /><br />
	28. அர்த்தஞ் சொல்லுகிறவனில்லாவிட்டால், சபையிலே பேசாமல், தனக்கும் தேவனுக்கும் தெரியப் பேசக்கடவன்.
	<br /><br />
	29. தீர்க்கதரிசிகள் இரண்டுபேராவது மூன்றுபேராவது பேசலாம், மற்றவர்கள் நிதானிக்கக்கடவர்கள்.
	<br /><br />
	30. அங்கே உட்கார்ந்திருக்கிற மற்றொருவனுக்கு ஏதாகிலும் வெளிப்படுத்தப்பட்டால், முந்திப் பேசினவன் பேசாமலிருக்கக்கடவன்.
	<br /><br />
	31. எல்லாரும் கற்கிறதற்கும் எல்லாரும் தேறுகிறதற்கும், நீங்கள் அனைவரும் ஒவ்வொருவராகத் தீர்க்கதரிசனஞ்சொல்லலாம்.
	<br /><br />
	32. தீர்க்கதரிசிகளுடைய ஆவிகள் தீர்க்கதரிசிகளுக்கு அடங்கியிருக்கிறதே.
	<br /><br />
	33. தேவன் கலகத்திற்குத் தேவனாயிராமல், சமாதானத்திற்குத் தேவனாயிருக்கிறார்; பரிசுத்தவான்களுடைய சபைகளெல்லாவற்றிலேயும் அப்படியே இருக்கிறது.
	<br /><br />
	34. சபைகளில் உங்கள் ஸ்திரீகள் பேசாமலிருக்கக்கடவர்கள்; பேசும்படிக்கு அவர்களுக்கு உத்தரவில்லை; அவர்கள் அமர்ந்திருக்கவேண்டும்; வேதமும் அப்படியே சொல்லுகிறது.
	<br /><br />
	35. அவர்கள் ஒரு காரியத்தைக் கற்றுக்கொள்ள விரும்பினால், வீட்டிலே தங்கள் புருஷரிடத்தில் விசாரிக்கக்கடவர்கள்; ஸ்திரீகள் சபையிலே பேசுகிறது அயோக்கியமாயிருக்குமே.
	<br /><br />
	36. தேவவசனம் உங்களிடத்திலிருந்தா புறப்பட்டது? அது உங்களிடத்துக்கு மாத்திரமா வந்தது?
	<br /><br />
	37. ஒருவன் தன்னைத் தீர்க்கதரிசியென்றாவது, ஆவியைப் பெற்றவனென்றாவது எண்ணினால், நான் உங்களுக்கு எழுதுகிறவைகள் கர்த்தருடைய கற்பனைகளென்று அவன் ஒத்துக்கொள்ளக்கடவன்.
	<br /><br />
	38. ஒருவன் அறியாதவனாயிருந்தால், அவன் அறியாதவனாயிருக்கட்டும்.
	<br /><br />
	39. இப்படியிருக்க, சகோதரரே, தீர்க்கதரிசனஞ்சொல்ல நாடுங்கள், அந்நிய பாஷைகளைப் பேசுகிறதற்கும் தடைபண்ணாதிருங்கள்.
	<br /><br />
	40. சகலமும் நல்லொழுக்கமாயும் கிரமமாயும் செய்யப்படக்கடவது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians14