import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 3 </title>
	<meta
          name="description"
          content="Hosea 3 | ஓசியா 3 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு கர்த்தர் என்னை நோக்கி: அந்நிய தேவர்களை மதித்து, திராட்சரசமுள்ள பாத்திரங்களை விரும்புகிறவர்களான இஸ்ரவேல் புத்திரர்பேரில் கர்த்தர் வைத்திருக்கிற அன்புக்கு ஒப்பாக நீ இன்னும் போய், தன் நேசரால் நேசிக்கப்பட்டவளும், விபசாரியுமான ஒரு ஸ்திரீயை நேசித்துக்கொள் என்று சொன்னார்.
	<br /><br />
	2. அப்பொழுது நான் அவளை எனக்குப் பதினைந்து வெள்ளிக்காசுக்கும், ஒன்றரைக்கலம் வாற்கோதுமைக்கும் கொண்டு,
	<br /><br />
	3. அவளை நோக்கி: நீ வேசித்தனம்பண்ணாமலும், ஒருவனையும் சேராமலும் அநேகநாள் எனக்காகக் காத்திரு; உனக்காக நானும் காத்திருப்பேன் என்றேன்.
	<br /><br />
	4. இஸ்ரவேல் புத்திரர் அநேகநாள் ராஜா இல்லாமலும், அதிபதி இல்லாமலும், பலி இல்லாமலும், சிலை இல்லாமலும், ஏபோத் வஸ்திரம் இல்லாமலும், தேராபீம் இல்லாமலும் இருப்பார்கள்.
	<br /><br />
	5. பின்பு இஸ்ரவேல் புத்திரர் திரும்பி, தங்கள் தேவனாகிய கர்த்தரையும், தங்கள் ராஜாவாகிய தாவீதையும் தேடி, கடைசிநாட்களில் கர்த்தரையும், அவருடைய தயவையும் நாடி அஞ்சிக்கையாய் வருவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea3