import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Malachi2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மல்கியா 2 </title>
	<meta
          name="description"
          content="Malachi 2 | மல்கியா 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்போதும் ஆசாரியர்களே, இந்தக் கட்டளை உங்களுக்குரியது.
	<br /><br />
	2. நீங்கள் கேளாமலும் என் நாமத்துக்கு மகிமையைச் செலுத்தும்படி இதைச் சிந்தியாமலுமிருந்தால், நான் உங்களுக்குள்ளே சாபத்தை அனுப்பி, உங்கள் ஆசீர்வாதங்களையும் சாபமாக்குவேன்; ஆம், நீங்கள் அதைச் சிந்தியாமற்போனதினால் அவைகளைச் சபித்தேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. இதோ, நான் உங்களுடைய பயிரைக் கெடுத்து, உங்கள் பண்டிகைகளின் சாணியையே உங்கள் முகங்களில் இறைப்பேன்; அதனோடுகூட நீங்களும் தள்ளுபடியாவீர்கள்.
	<br /><br />
	4. லேவியோடேபண்ணின என் உடன்படிக்கை நிலைத்திருக்கும்படிக்கு இந்தக் கட்டளையை உங்களிடத்திற்கு அனுப்பினேன் என்கிறதை அப்பொழுது அறிந்துகொள்வீர்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. அவனோடே பண்ணின என் உடன்படிக்கை ஜீவனும் சமாதானமுமாக இருந்தது; அவன் எனக்குப் பயப்படும் பயத்தோடே இருக்கவேண்டுமென்று, இவைகளை அவனுக்குக் கட்டளையிட்டேன்; அப்படியே அவன் என் நாமத்துக்குப் பயந்தும் இருந்தான்.
	<br /><br />
	6. சத்தியவேதம் அவன் வாயில் இருந்தது; அவனுடைய உதடுகளில் அநியாயம் காணப்படவில்லை; அவன் என்னோடே சமாதானமும் யதார்த்தமுமாய்ச் சஞ்சரித்து, அநேகரை அக்கிரமத்தினின்று திருப்பினான்.
	<br /><br />
	7. ஆசாரியனுடைய உதடுகள் அறிவைக் காக்கவேண்டும்; வேதத்தை அவன் வாயிலே தேடுவார்களே; அவன் சேனைகளுடைய கர்த்தரின் தூதன்.
	<br /><br />
	8. நீங்களோ வழியைவிட்டு விலகி, அநேகரை வேதத்தைக்குறித்து இடறப்பண்ணினீர்கள்; லேவியின் உடன்படிக்கையைக் கெடுத்துப்போட்டீர்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. நீங்கள் என் வழிகளைக் கைக்கொள்ளாமல் வேதத்தைக்குறித்துப் பட்சபாதம் பண்ணினபடியினால் நானும் உங்களை எல்லா ஜனத்துக்கு முன்பாகவும் அற்பரும் நீசருமாக்கினேன்.
	<br /><br />
	10. நம்மெல்லாருக்கும் ஒரே பிதா இல்லையோ? ஒரே தேவன் நம்மைச் சிருஷ்டித்ததில்லையோ? நாம் நம்முடைய பிதாக்களின் உடன்படிக்கையைப் பரிசுத்தக்குலைச்சலாக்கி, அவனவன் தன்தன் சகோதரனுக்குத் துரோகம்பண்ணுவானேன்?
	<br /><br />
	11. யூதா ஜனங்கள் துரோகம்பண்ணினார்கள்; இஸ்ரவேலிலும் எருசலேமிலும் அருவருப்பான காரியம் செய்யப்பட்டது; கர்த்தர் சிநேகிக்கிற பரிசுத்தத்தை யூதா ஜனங்கள் பரிசுத்தக் குலைச்சலாக்கி அந்நிய தேவதையின் குமாரத்திகளை விவாகம்பண்ணினார்கள்.
	<br /><br />
	12. இப்படிச் செய்கிறவன் எவனோ, அவன் காவல்காக்கிறவனாயினும், உத்தரவுகொடுக்கிறவனாயினும், சேனைகளின் கர்த்தருக்குக் காணிக்கை செலுத்துகிறவனாயினும், அவனை யாக்கோபின் கூடாரங்களில் இராதபடிக்குக் கர்த்தர் சங்கரிப்பார்.
	<br /><br />
	13. நீங்கள் இரண்டாந்தரமும் இதைச் செய்து, கர்த்தருடைய பீடத்தைக் கண்ணீரினாலும் அழுகையினாலும் பெருமூச்சினாலும் நிரப்புகிறீர்கள்; ஆகையால், அவர் இனிக் காணிக்கையை மதியார், அதை உங்கள் கைகளில் பிரியமாய் ஏற்றுக்கொள்ளவுமாட்டார்.
	<br /><br />
	14. ஏன் என்று கேட்கிறீர்கள்; கர்த்தர் உனக்கும் உன் இளவயதின் மனைவிக்கும் சாட்சியாயிருக்கிறார்; உன் தோழியும் உன் உடன்படிக்கையின் மனைவியுமாகிய அவளுக்கு நீ துரோகம்பண்ணினாயே.
	<br /><br />
	15. அவர் ஒருவனையல்லவா படைத்தார்? ஆவி அவரிடத்தில் பரிபூரணமாயிருந்ததே, பின்னை ஏன் ஒருவனைப் படைத்தார்? தேவபக்தியுள்ள சந்ததியைப் பெறும்படிதானே. ஆகையால் ஒருவனும் தன் இளவயதின் மனைவிக்குத் துரோகம்பண்ணாதபடிக்கு, உங்கள் ஆவியைக்குறித்து எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	16. தள்ளிவிடுதலை நான் வெறுக்கிறேன் என்று இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறார்; அப்படிப்பட்டவன் கொடுமையினால் தன் வஸ்திரத்தை மூடுகிறான் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; ஆகையால் நீங்கள் துரோகம்பண்ணாமல் உங்கள் ஆவியைக்குறித்து எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	17. உங்கள் வார்த்தைகளினாலே கர்த்தரை வருத்தப்படுத்துகிறீர்கள்; ஆனாலும் எதினாலே அவரை வருத்தப்படுத்துகிறோம் என்கிறீர்கள்; பொல்லாப்பைச் செய்கிறவனெவனும் கர்த்தரின் பார்வைக்கு நல்லவன் என்றும், அப்படிப்பட்டவர்கள்பேரில் அவர் பிரியமாயிருக்கிறாரென்றும், நியாயந்தீர்க்கிற தேவன் எங்கேயென்றும், நீங்கள் சொல்லுகிறதினாலேயே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Malachi2