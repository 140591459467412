import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 4 </title>
	<meta
          name="description"
          content="Isaiah 4 | ஏசாயா 4 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாளில் ஏழு ஸ்திரீகள் ஒரே புருஷனைப் பிடித்து: நாங்கள் எங்கள் சொந்த ஆகாரத்தைப் புசித்து, எங்கள் சொந்த வஸ்திரத்தை உடுப்போம்; எங்கள் நிந்தை நீங்கும்படிக்கு உன்பேர்மாத்திரம் எங்கள்மேல் விளங்கட்டும் என்பார்கள்.
	<br /><br />
	2. இஸ்ரவேலில் தப்பினவர்களுக்கு அந்நாளிலே கர்த்தரின் கிளை அலங்காரமும் மகிமையுமாயிருக்கும்; பூமியின் கனி அவர்களுக்குச் சிறப்பும் அலங்காரமுமாயிருக்கும்.
	<br /><br />
	3. அப்பொழுது ஆண்டவர், சீயோன் குமாரத்திகளின் அழுக்கைக் கழுவி, நியாயத்தின் ஆவியினாலும், சுட்டெரிப்பின் ஆவியினாலும், எருசலேமின் இரத்தப்பழிகளை அதின் நடுவிலிருந்து நீக்கிவிடும்போது,
	<br /><br />
	4. சீயோனில் மீதியாயிருந்து, எருசலேமில் தரித்திருந்து ஜீவனுக்கென்று பேரெழுதப்பட்டவனெவனும் பரிசுத்தனென்று சொல்லப்படுவான்.
	<br /><br />
	5. அப்பொழுது கர்த்தர் சீயோன் மலையிலுள்ள எல்லா வாசஸ்தலங்களிலும், அதின் சபைகளின்மேலும், பகலில் மேகத்தையும் புகையையும், இரவில் கொழுந்துவிட்டு எரியும் அக்கினிப்பிரகாசத்தையும் உண்டாக்குவார்; மகிமையானவைகளின்மேலெல்லாம் காவல் உண்டாயிருக்கும்.
	<br /><br />
	6. பகலிலே வெயிலுக்கு நிழலாகவும், பெருங்காற்றுக்கும் மழைக்கும் அடைக்கலமாகவும், மறைவிடமாகவும், ஒரு கூடாரம் உண்டாயிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah4