import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah24 = () => {
  const verseNumber = 24;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 24 </title>
	<meta
          name="description"
          content="Isaiah 24 | ஏசாயா 24 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, கர்த்தர் தேசத்தை வெறுமையும் பாழுமாக்கி, அதைக் கவிழ்த்து, அதின் குடிகளைச் சிதறடிப்பார்.
	<br /><br />
	2. அப்பொழுது, ஜனத்துக்கு எப்படியோ அப்படியே ஆசாரியனுக்கும், வேலைக்காரனுக்கு எப்படியோ அப்படியே எஜமானுக்கும், வேலைக்காரிக்கு எப்படியோ அப்படியே எஜமானிக்கும், கொண்டவனுக்கு எப்படியோ அப்படியே விற்றவனுக்கும், கடன் கொடுத்தவனுக்கு எப்படியோ அப்படியே கடன்வாங்கினவனுக்கும், வட்டிவாங்கினவனுக்கு எப்படியோ அப்படியே வட்டிகொடுத்தவனுக்கும், எல்லாருக்கும் சரியாக நடக்கும்.
	<br /><br />
	3. தேசம் முழுதும் கொள்ளையாகி, முற்றிலும் வெறுமையாகும்; இது கர்த்தர் சொன்ன வார்த்தை.
	<br /><br />
	4. தேசம் புலம்பி வாடும்; பூமி சத்துவமற்று உலர்ந்துபோகும்; தேசத்து ஜனத்திலே உயர்ந்தவர்கள் தவிப்பார்கள்.
	<br /><br />
	5. தேசம் தன் குடிகளின் மூலமாய்த் தீட்டுப்பட்டது; அவர்கள் நியாயப்பிரமாணங்களை மீறி, கட்டளையை மாறுபாடாக்கி, நித்திய உடன்படிக்கையை முறித்தார்கள்.
	<br /><br />
	6. இதினிமித்தம் சாபம் தேசத்தைப் பட்சித்தது, அதின் குடிகள் தண்டிக்கப்பட்டார்கள்; தேசத்தார் தகிக்கப்பட்டார்கள், சிலர்மாத்திரம் மீந்திருக்கிறார்கள்.
	<br /><br />
	7. திராட்சரசம் துக்கங்கொண்டாடும், திராட்சச்செடி வதங்கும்; மனக்களிப்பாயிருந்தவர்கள் எல்லாரும் பெருமூச்சுவிடுவார்கள்.
	<br /><br />
	8. மேளங்களின் சந்தோஷம் ஓயும், களிகூருகிறவர்களின் சந்தடி ஒழியும், வீணையின் களிப்பு நின்றுபோம்.
	<br /><br />
	9. பாடலோடே திராட்சரசம் குடியார்கள்; மதுபானம் அதைக் குடிக்கிறவர்களுக்குக் கசக்கும்.
	<br /><br />
	10. வெறுமையாய்ப்போன நகரம் தகர்ந்து, ஒருவரும் உள்ளே பிரவேசிக்கக்கூடாதபடி, வீடுகளெல்லாம் அடைபட்டுக்கிடக்கும்.
	<br /><br />
	11. திராட்சரசத்துக்காக வீதிகளிலே கூக்குரல் உண்டு; எல்லாச் சந்தோஷமும் மங்கி, தேசத்தின் மகிழ்ச்சி அற்றுப்போம்.
	<br /><br />
	12. நகரத்தில் மீதியாயிருப்பது அழிவே; வாசல்கள் இடிக்கப்பட்டுப் பாழாய்க் கிடக்கும்.
	<br /><br />
	13. ஒலிவமரத்தை உலுக்கும்போதும், திராட்சப்பழங்களை அறுத்துத் தீரும்போதும், பின்பறிப்புக்குக் கொஞ்சம் மீந்திருப்பதுபோல, தேசத்துக்குள்ளும் இந்த ஜனங்களின் நடுவிலும் கொஞ்சம் மீந்திருக்கும்.
	<br /><br />
	14. அவர்கள் சத்தமிட்டுக் கெம்பீரிப்பார்கள்; கர்த்தருடைய மகத்துவத்தினிமித்தம் சமுத்திரத்தினின்று ஆர்ப்பரிப்பார்கள்.
	<br /><br />
	15. ஆகையால் கர்த்தரை, வெளுக்குந்திசையிலும், இஸ்ரவேலின் தேவனாகிய கர்த்தரின் நாமத்தைச் சமுத்திரத் தீவுகளிலும் மகிமைப்படுத்துங்கள்.
	<br /><br />
	16. நீதிபரனுக்கு மகிமை என்று பாடும் கீதங்களை பூமியின் கடையாந்தரத்திலிருந்து கேட்கிறோம்; நானோ, இளைத்துப்போனேன், இளைத்துப்போனேன்; எனக்கு ஐயோ! துரோகிகள் துரோகம்பண்ணுகிறார்கள்; துரோகிகள் மிகுதியாய்த் துரோகம்பண்ணுகிறார்கள் என்கிறேன்.
	<br /><br />
	17. தேசத்துக் குடிகளே, திகிலும், படுகுழியும், கண்ணியும் உங்களுக்கு நேரிடும்.
	<br /><br />
	18. அப்பொழுது, திகிலின் சத்தத்திற்கு விலகி ஓடுகிறவன் படுகுழியில் விழுவான்; படுகுழியிலிருந்து ஏறுகிறவன் கண்ணியில் அகப்படுவான்; உயர இருக்கும் மதகுகள் திறவுண்டு, பூமியின் அஸ்திபாரங்கள் குலுங்கும்.
	<br /><br />
	19. தேசம் நொறுங்கவே நொறுங்கும், தேசம் முறியவே முறியும், தேசம் அசையவே அசையும்.
	<br /><br />
	20. வெறித்தவனைப்போல தேசம் தள்ளாடி, ஒரு குடிலைப்போலப் பெயர்த்துப்போடப்படும்; அதின் பாதகம் அதின்மேல் பாரமாயிருக்கையால், அது விழுந்துபோம், இனி எழுந்திராது.
	<br /><br />
	21. அக்காலத்தில் கர்த்தர் உன்னதமான சேனையை உன்னதத்திலும், பூமியின் ராஜாக்களைப் பூமியிலும் விசாரிப்பார்.
	<br /><br />
	22. அவர்கள் கெபியில் ஏகமாய்க் கட்டுண்டவர்களாகச் சேர்ந்து, காவலில் அடைக்கப்பட்டு, அநேகநாள் சென்றபின்பு விசாரிக்கப்படுவார்கள்.
	<br /><br />
	23. அப்பொழுது சேனைகளின் கர்த்தர் சீயோன் மலையிலும் எருசலேமிலும் ஆளுகைசெய்வதால், சந்திரன் கலங்கும், சூரியன் நாணமடையும்; அவருடைய மூப்பர்களுக்கு முன்பாக மகிமை உண்டாயிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah24