import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Philippians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Book of Philippians Bible in Tamil | பிலிப்பியர் 1 </title>
	<meta
          name="description"
          content="Philippians 1 | பிலிப்பியர் 1 |
          Book of Philippians | Tamil Bible Verse | Tamil Bible | பிலிப்பியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசுகிறிஸ்துவின் ஊழியக்காரராகிய பவுலும் தீமோத்தேயும், பிலிப்பி பட்டணத்தில் கிறிஸ்து இயேசுவுக்குள்ளான பரிசுத்தவான்கள் அனைவருக்கும், கண்காணிகளுக்கும், உதவிக்காரருக்கும் எழுதுகிறதாவது:
	<br /><br />
	2. நம்முடைய பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	3. சுவிசேஷம் உங்களுக்கு அறிவிக்கப்பட்ட நாள்முதல் இதுவரைக்கும் நீங்கள் அதற்கு உடன்பட்டவர்களானபடியால்,
	<br /><br />
	4. நான் பண்ணுகிற ஒவ்வொரு விண்ணப்பத்திலும் உங்கள் அனைவருக்காகவும் எப்போதும் சந்தோஷத்தோடே விண்ணப்பம்பண்ணி,
	<br /><br />
	5. உங்களில் நற்கிரியையைத் தொடங்கினவர் அதை இயேசுகிறிஸ்துவின் நாள்பரியந்தம் முடிய நடத்திவருவாரென்று நம்பி,
	<br /><br />
	6. நான் உங்களை நினைக்கிறபொழுதெல்லாம் என் தேவனை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	7. என் கட்டுகளிலும், நான் சுவிசேஷத்திற்காக உத்தரவுசொல்லி அதைத் திடப்படுத்திவருகிறதிலும், நீங்கள் அனைவரும் எனக்கு அளிக்கப்பட்ட கிருபையில் பங்குள்ளவர்களானதால், உங்களை என் இருதயத்தில் தரித்துக்கொண்டிருக்கிறபடியினாலே, உங்களெல்லாரையுங் குறித்து நான் இப்படி நினைக்கிறது எனக்குத் தகுதியாயிருக்கிறது.
	<br /><br />
	8. இயேசுகிறிஸ்துவின் உருக்கமான அன்பிலே உங்களெல்லார்மேலும் எவ்வளவோ வாஞ்சையாயிருக்கிறேன் என்பதற்குத் தேவனே எனக்குச் சாட்சி.
	<br /><br />
	9. மேலும், உத்தமமானவைகளை நீங்கள் ஒப்புக்கொள்ளத்தக்கதாக உங்கள் அன்பானது அறிவிலும் எல்லா உணர்விலும் இன்னும் அதிகமதிகமாய்ப் பெருகவும்,
	<br /><br />
	10. தேவனுக்கு மகிமையும் துதியும் உண்டாகும்படி இயேசுகிறிஸ்துவினால் வருகிற நீதியின் கனிகளால் நிறைந்தவர்களாகி,
	<br /><br />
	11. நீங்கள் கிறிஸ்துவின் நாளுக்கென்று துப்புரவானவர்களும் இடறலற்றவர்களுமாயிருக்கவும் வேண்டுதல் செய்கிறேன்.
	<br /><br />
	12. சகோதரரே, எனக்குச் சம்பவித்தவைகள் சுவிசேஷம் பிரபலமாகும்படிக்கு ஏதுவாயிற்றென்று நீங்கள் அறிய மனதாயிருக்கிறேன்.
	<br /><br />
	13. அரமனை யெங்குமுள்ளவர்களுக்கும் மற்ற யாவருக்கும் என் கட்டுகள் கிறிஸ்துவுக்குள்ளான கட்டுகளென்று வெளியரங்கமாகி,
	<br /><br />
	14. சகோதரரில் அநேகர் என் கட்டுகளாலே கர்த்தருக்குள் திடன்கொண்டு பயமில்லாமல் திருவசனத்தைச் சொல்லும்படி அதிகமாய்த் துணிந்திருக்கிறார்கள்.
	<br /><br />
	15. சிலர் பொறாமையினாலும் விரோதத்தினாலும், சிலர் நல்மனதினாலும் கிறிஸ்துவைப் பிரசங்கிக்கிறார்கள்.
	<br /><br />
	16. சிலர் என் கட்டுகளோடே உபத்திரவத்தையுங்கூட்ட நினைத்து, சுத்தமனதோடே கிறிஸ்துவை அறிவியாமல், விரோதத்தினாலே அறிவிக்கிறார்கள்.
	<br /><br />
	17. சுவிசேஷத்திற்காக நான் உத்தரவு சொல்ல ஏற்படுத்தப்பட்டவனென்று அறிந்து, சிலர் அன்பினாலே அறிவிக்கிறார்கள்.
	<br /><br />
	18. இதனாலென்ன? வஞ்சகத்தினாலாவது, உண்மையினாலாவது, எப்படியாவது, கிறிஸ்து அறிவிக்கப்படுகிறார்; அதனால் சந்தோஷப்படுகிறேன், இன்னமும் சந்தோஷப்படுவேன்.
	<br /><br />
	19. அது உங்கள் வேண்டுதலினாலும் இயேசுகிறிஸ்துவினுடைய ஆவியின் உதவியினாலும் எனக்கு இரட்சிப்பாக முடியுமென்று அறிவேன்.
	<br /><br />
	20. நான் ஒன்றிலும் வெட்கப்பட்டுப்போகாமல், எப்பொழுதும்போல இப்பொழுதும், மிகுந்த தைரியத்தோடே ஜீவனாலாகிலும், சாவினாலாகிலும், கிறிஸ்து என் சரீரத்தினாலே மகிமைப்படுவாரென்று எனக்கு உண்டாயிருக்கிற வாஞ்சைக்கும் நம்பிக்கைக்கும் தக்கதாய், அப்படி முடியும்.
	<br /><br />
	21. கிறிஸ்து எனக்கு ஜீவன், சாவு எனக்கு ஆதாயம்.
	<br /><br />
	22. ஆகிலும் சரீரத்தில் பிழைத்திருக்கிறதினாலே என் கிரியைக்குப் பலனுண்டாயிருப்பதால், நான் தெரிந்துகொள்ளவேண்டியது இன்னதென்று அறியேன்.
	<br /><br />
	23. ஏனெனில் இவ்விரண்டினாலும் நான் நெருக்கப்படுகிறேன்; தேகத்தைவிட்டுப் பிரிந்து, கிறிஸ்துவுடனேகூட இருக்க எனக்கு ஆசையுண்டு, அது அதிக நன்மையாயிருக்கும்;
	<br /><br />
	24. அப்படியிருந்தும், நான் சரீரத்தில் தரித்திருப்பது உங்களுக்கு அதிக அவசியம்.
	<br /><br />
	25. இந்த நிச்சயத்தைக்கொண்டிருந்து, நான் மறுபடியும் உங்களிடத்தில் வருகிறதினால் என்னைக்குறித்து உங்களுடைய மகிழ்ச்சி கிறிஸ்து இயேசுவுக்குள் பெருகும்படிக்கு,
	<br /><br />
	26. உங்கள் விசுவாசத்தின் வர்த்தனைக்காகவும் சந்தோஷத்துக்காகவும் நான் பிழைத்து, உங்கள் அனைவரோடுங்கூட இருப்பேனென்று அறிந்திருக்கிறேன்.
	<br /><br />
	27. நான் வந்து உங்களைக் கண்டாலும், நான் வராமலிருந்தாலும், நீங்கள் ஒரே ஆவியிலே உறுதியாய் நின்று, ஒரே ஆத்துமாவினாலே சுவிசேஷத்தின் விசுவாசத்திற்காகக் கூடப்போராடி, எதிர்க்கிறவர்களால் ஒன்றிலும் மருளாதிருக்கிறீர்களென்று உங்களைக்குறித்து நான் கேள்விப்படும்படி, எவ்விதத்திலும் நீங்கள் கிறிஸ்துவின் சுவிசேஷத்திற்குப் பாத்திரராக மாத்திரம் நடந்துகொள்ளுங்கள்.
	<br /><br />
	28. நீங்கள் மருளாதிருக்கிறது அவர்கள் கெட்டுப்போகிறதற்கும், நீங்கள் இரட்சிக்கப்படுகிறதற்கும் அத்தாட்சியாயிருக்கிறது; இதுவும் தேவனுடைய செயலே.
	<br /><br />
	29. ஏனெனில் கிறிஸ்துவினிடத்தில் விசுவாசிக்கிறதற்குமாத்திரமல்ல, அவர் நிமித்தமாகப் பாடுபடுகிறதற்கும் உங்களுக்கு அருளப்பட்டிருக்கிறது.
	<br /><br />
	30. நீங்கள் என்னிடத்திலே கண்டதும் எனக்கு உண்டென்று இப்பொழுது கேள்விப்படுகிறதுமான போராட்டமே உங்களுக்கும் உண்டு.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Philippians1