import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 9 </title>
	<meta
          name="description"
          content="Matthew 9 | மத்தேயு 9 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது, அவர் படவில் ஏறி, இக்கரைப்பட்டுத் தம்முடைய பட்டணத்திற்கு வந்தார்.
	<br /><br />
	2. அங்கே படுக்கையிலே கிடந்த ஒரு திமிர்வாதக்காரனை அவரிடத்தில் கொண்டுவந்தார்கள். இயேசு அவர்களுடைய விசுவாசத்தைக் கண்டு, திமிர்வாதக்காரனை நோக்கி: மகனே, திடன்கொள், உன் பாவங்கள் உனக்கு மன்னிக்கப்பட்டது என்றார்.
	<br /><br />
	3. அப்பொழுது, வேதபாரகரில் சிலர்: இவன் தேவதூஷணம் சொல்லுகிறான் என்று தங்கள் உள்ளத்தில் சொல்லிக்கொண்டார்கள்.
	<br /><br />
	4. இயேசு அவர்கள் நினைவுகளை அறிந்து: நீங்கள் உங்கள் இருதயங்களில் பொல்லாதவைகளைச் சிந்திக்கிறதென்ன?
	<br /><br />
	5. உன் பாவங்கள் மன்னிக்கப்பட்டது என்று சொல்வதோ, எழுந்து நடவென்று சொல்வதோ, எது எளிது?
	<br /><br />
	6. பூமியிலே பாவங்களை மன்னிக்க மனுஷகுமாரனுக்கு அதிகாரம் உண்டென்பதை நீங்கள் அறியவேண்டும் என்று சொல்லி, திமிர்வாதக்காரனை நோக்கி: நீ எழுந்து, உன் படுக்கையை எடுத்துக்கொண்டு, உன் வீட்டுக்குப் போ என்றார்.
	<br /><br />
	7. உடனே அவன் எழுந்து, தன் வீட்டுக்குப் போனான்.
	<br /><br />
	8. ஜனங்கள் அதைக்கண்டு ஆச்சரியப்பட்டு, மனுஷருக்கு இப்படிப்பட்ட அதிகாரத்தைக் கொடுத்தவராகிய தேவனை மகிமைப்படுத்தினார்கள்.
	<br /><br />
	9. இயேசு அவ்விடம் விட்டுப் புறப்பட்டுப்போகையில், ஆயத்துறையில் உட்கார்ந்திருந்த மத்தேயு என்னும் ஒரு மனுஷனைக் கண்டு: எனக்குப் பின்சென்றுவா என்றார்; அவன் எழுந்து, அவருக்குப் பின்சென்றான்.
	<br /><br />
	10. பின்பு அவர் வீட்டிலே போஜனபந்தியிருக்கையில், அநேக ஆயக்காரரும் பாவிகளும் வந்து, இயேசுவோடும் அவர் சீஷரோடுங்கூடப் பந்தியிருந்தார்கள்.
	<br /><br />
	11. பரிசேயர் அதைக் கண்டு, அவருடைய சீஷர்களை நோக்கி: உங்கள் போதகர் ஆயக்காரரோடும் பாவிகளோடும் போஜனம்பண்ணுகிறதென்னவென்று கேட்டார்கள்.
	<br /><br />
	12. இயேசு அதைக் கேட்டு: பிணியாளிகளுக்கு வைத்தியன் வேண்டியதேயல்லாமல் சுகமுள்ளவர்களுக்கு வேண்டியதில்லை.
	<br /><br />
	13. பலியையல்ல, இரக்கத்தையே விரும்புகிறேன் என்பதின் கருத்து இன்னதென்று போய்க் கற்றுக்கொள்ளுங்கள்; நீதிமான்களையல்ல, பாவிகளையே மனந்திரும்புகிறதற்கு அழைக்க வந்தேன் என்றார்.
	<br /><br />
	14. அப்பொழுது, யோவானுடைய சீஷர் அவரிடத்தில் வந்து: நாங்களும் பரிசேயரும் அநேகந்தரம் உபவாசிக்கிறோமே; உம்முடைய சீஷர் உபவாசியாமலிருக்கிறதென்னவென்று கேட்டார்கள்.
	<br /><br />
	15. அதற்கு இயேசு: மணவாளன் தங்களோடிருக்கையில் மணவாளனுடைய தோழர் துயரப்படுவார்களா? மணவாளன் அவர்களை விட்டு எடுபடும் நாட்கள் வரும், அப்பொழுது உபவாசிப்பார்கள்.
	<br /><br />
	16. ஒருவனும் கோடித்துண்டைப் பழைய வஸ்திரத்தோடே இணைக்கமாட்டான்; இணைத்தால், அதினோடே இணைத்த துண்டு வஸ்திரத்தை அதிகமாய்க் கிழிக்கும், பீறலும் அதிகமாகும்.
	<br /><br />
	17. புது திராட்சரசத்தைப் பழந்துருத்திகளில் வார்த்துவைக்கிறதும் இல்லை; வார்த்துவைத்தால், துருத்திகள் கிழிந்துபோம், இரசமும் சிந்திப்போம், துருத்திகளும் கெட்டுப்போம்; புது ரசத்தைப் புது துருத்திகளில் வார்த்துவைப்பார்கள், அப்பொழுது இரண்டும் பத்திரப்பட்டிருக்கும் என்றார்.
	<br /><br />
	18. அவர் இவைகளை அவர்களுக்குச் சொல்லிக்கொண்டிருக்கையில், தலைவன் ஒருவன் வந்து அவரைப் பணிந்து: என் மகள் இப்பொழுதுதான் மரித்துப்போனாள்; ஆகிலும், நீர் வந்து அவள்மேல் உமது கையை வையும், அப்பொழுது பிழைப்பாள் என்றான்.
	<br /><br />
	19. இயேசு எழுந்து, தம்முடைய சீஷரோடுகூட அவன் பின்னே போனார்.
	<br /><br />
	20. அப்பொழுது, பன்னிரண்டு வருஷமாய்ப் பெரும்பாடுள்ள ஒரு ஸ்திரீ:
	<br /><br />
	21. நான் அவருடைய வஸ்திரத்தையாகிலும் தொட்டால் சொஸ்தமாவேன் என்று தன் உள்ளத்தில் எண்ணிக்கொண்டு, அவர் பின்னாலே வந்து, அவருடைய வஸ்திரத்தின் ஓரத்தைத் தொட்டாள்.
	<br /><br />
	22. இயேசு திரும்பி, அவளைப் பார்த்து: மகளே, திடன்கொள், உன் விசுவாசம் உன்னை இரட்சித்தது என்றார். அந்நேரம்முதல் அந்த ஸ்திரீ சொஸ்தமானாள்.
	<br /><br />
	23. இயேசுவானவர் தலைவனுடைய வீட்டிலே வந்து, தாரை ஊதுகிறவர்களையும், இரைகிற ஜனங்களையும் கண்டு:
	<br /><br />
	24. விலகுங்கள், இந்தச் சிறு பெண் மரிக்கவில்லை, நித்திரையாயிருக்கிறாள் என்றார். அதற்காக அவரைப்பார்த்து நகைத்தார்கள்.
	<br /><br />
	25. ஜனங்கள் வெளியே துரத்தப்பட்ட பின்பு, அவர் உள்ளே பிரவேசித்து, அந்தச் சிறுபெண்ணின் கையைப் பிடித்தார்; உடனே அவள் எழுந்திருந்தாள்.
	<br /><br />
	26. இந்தச் சங்கதி அத்தேசமெங்கும் பிரசித்தமாயிற்று.
	<br /><br />
	27. இயேசு அவ்விடம் விட்டுப் போகையில், இரண்டு குருடர் அவர் பின்னே சென்று: தாவீதின் குமாரனே, எங்களுக்கு இரங்கும் என்று கூப்பிட்டார்கள்.
	<br /><br />
	28. அவர் வீட்டிற்கு வந்தபின்பு, அந்தக் குருடர் அவரிடத்தில் வந்தார்கள். இயேசு அவர்களை நோக்கி: இதைச் செய்ய எனக்கு வல்லமை உண்டென்று விசுவாசிக்கிறீர்களா என்று கேட்டார்; அதற்கு அவர்கள்: ஆம் விசுவாசிக்கிறோம், ஆண்டவரே! என்றார்கள்.
	<br /><br />
	29. அப்பொழுது, அவர்களுடைய கண்களை அவர் தொட்டு: உங்கள் விசுவாசத்தின்படி உங்களுக்கு ஆகக்கடவது என்றார்.
	<br /><br />
	30. உடனே அவர்களுடைய கண்கள் திறக்கப்பட்டது. இதை ஒருவரும் அறியாதபடிக்கு எச்சரிக்கையாயிருங்கள் என்று இயேசு அவர்களுக்குக் கண்டிப்பாய்க் கட்டளையிட்டார்.
	<br /><br />
	31. அவர்களோ புறப்பட்டு, அத்தேசமெங்கும் அவருடைய கீர்த்தியைப் பிரசித்தம்பண்ணினார்கள்.
	<br /><br />
	32. அவர்கள் புறப்பட்டுப் போகையில், பிசாசுபிடித்த ஊமையான ஒரு மனுஷனை அவரிடத்தில் கொண்டுவந்தார்கள்.
	<br /><br />
	33. பிசாசு துரத்தப்பட்ட பின்பு ஊமையன் பேசினான். ஜனங்கள் ஆச்சரியப்பட்டு: இஸ்ரவேலில் இப்படி ஒருக்காலும் காணப்படவில்லை என்றார்கள்.
	<br /><br />
	34. பரிசேயரோ: இவன் பிசாசுகளின் தலைவனாலே பிசாசுகளைத் துரத்துகிறான் என்றார்கள்.
	<br /><br />
	35. பின்பு, இயேசு சகல பட்டணங்களையும் கிராமங்களையும் சுற்றி நடந்து, ஜெப ஆலயங்களில் உபதேசித்து, ராஜ்யத்தின் சுவிசேஷத்தைப் பிரசங்கித்து, ஜனங்களுக்கு உண்டாயிருந்த சகல வியாதிகளையும் சகல நோய்களையும் நீக்கி, அவர்களைச் சொஸ்தமாக்கினார்.
	<br /><br />
	36. அவர் திரளான ஜனங்களைக் கண்டபொழுது, அவர்கள் மேய்ப்பனில்லாத ஆடுகளைப்போலத் தொய்ந்துபோனவர்களும் சிதறப்பட்டவர்களுமாய் இருந்தபடியால், அவர்கள்மேல் மனதுருகி,
	<br /><br />
	37. தம்முடைய சீஷர்களை நோக்கி: அறுப்பு மிகுதி, வேலையாட்களோ கொஞ்சம்;
	<br /><br />
	38. ஆதலால், அறுப்புக்கு எஜமான் தமது அறுப்புக்கு வேலையாட்களை அனுப்பும்படி அவரை வேண்டிக்கொள்ளுங்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew9