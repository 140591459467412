import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneThessalonians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO THESSALONIANS 2 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE THESSALONIANS Chapter 2 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 2 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. சகோதரரே, நாங்கள் உங்களிடத்தில் பிரவேசித்தது வீணாயிருக்கவில்லையென்று நீங்களே அறிந்திருக்கிறீர்கள்.
	<br /><br />
	2. உங்களுக்குத் தெரிந்திருக்கிறபடி, முன்னே பிலிப்பிபட்டணத்திலே நாங்கள் பாடுபட்டு நிந்தையடைந்திருந்தும், வெகு போராட்டத்தோடே தேவனுடைய சுவிசேஷத்தை உங்களுக்குச் சொல்லும்படி, நம்முடைய தேவனுக்குள் தைரியங்கொண்டிருந்தோம்.
	<br /><br />
	3. எங்கள் போதகம் வஞ்சகத்தினாலும் துராசையினாலும் உண்டாகவில்லை, அது கபடமுள்ளதாயுமிருக்கவில்லை.
	<br /><br />
	4. சுவிசேஷத்தை எங்களிடத்தில் ஒப்புவிக்கத்தக்கதாய், தேவன் எங்களை உத்தமரென்றெண்ணினபடியே, நாங்கள் மனுஷருக்கு அல்ல, எங்கள் இருதயங்களைச் சோதித்தறிகிற தேவனுக்கே பிரியமுண்டாகப் பேசுகிறோம்.
	<br /><br />
	5. உங்களுக்குத் தெரிந்திருக்கிறபடி, நாங்கள் ஒருக்காலும் இச்சகமான வசனங்களைச் சொல்லவுமில்லை, பொருளாசையுள்ளவர்களாய் மாயம்பண்ணவுமில்லை; தேவனே சாட்சி.
	<br /><br />
	6. நாங்கள் கிறிஸ்துவின் அப்போஸ்தலராக உங்களுக்குப் பாரமாயிருக்கக் கூடியவர்களானாலும், உங்களிடத்திலாவது, மற்றவர்களிடத்திலாவது, மனுஷரால் வரும் மகிமையை நாங்கள் தேடவில்லை.
	<br /><br />
	7. உங்களிடத்தில் பட்சமாய் நடந்துகொண்டோம்; பால் கொடுக்கிற தாயானவள் தன் பிள்ளைகளைக் காப்பாற்றுகிறதுபோல,
	<br /><br />
	8. நாங்கள் உங்கள்மேல் வாஞ்சையாயிருந்து, தேவனுடைய சுவிசேஷத்தை உங்களுக்குக் கொடுத்ததுமல்லாமல், நீங்கள் எங்களுக்குப் பிரியமானவர்களானபடியினாலே, எங்கள் ஜீவனையும் உங்களுக்குக் கொடுக்க மனதாயிருந்தோம்.
	<br /><br />
	9. சகோதரரே, நாங்கள் பட்ட பிரயாசமும் வருத்தமும் உங்களுக்கு ஞாபகமாயிருக்கும்; உங்களில் ஒருவனுக்கும் பாரமாயிராதபடிக்கு, இரவும் பகலும் நாங்கள் வேலைசெய்து, தேவனுடைய சுவிசேஷத்தை உங்களிடத்தில் பிரசங்கித்தோம்.
	<br /><br />
	10. விசுவாசிகளாகிய உங்களுக்குள்ளே நாங்கள் எவ்வளவு பரிசுத்தமும் நீதியும் பிழையின்மையுமாய் நடந்தோமென்பதற்கு நீங்களும் சாட்சி, தேவனும் சாட்சி.
	<br /><br />
	11. மேலும், தம்முடைய ராஜ்யத்திற்கும் மகிமைக்கும் உங்களை அழைத்த தேவனுக்கு நீங்கள் பாத்திரராய் நடக்கவேண்டுமென்று,
	<br /><br />
	12. தகப்பன் தன் பிள்ளைகளுக்குச் சொல்லுகிறதுபோல, நாங்கள் உங்களில் ஒவ்வொருவனுக்கும் புத்தியும் தேறுதலும் எச்சரிப்பும் சொன்னதை அறிந்திருக்கிறீர்கள்.
	<br /><br />
	13. ஆகையால், நீங்கள் தேவவசனத்தை எங்களாலே கேள்விப்பட்டு ஏற்றுக்கொண்டபோது, அதை மனுஷர் வசனமாக ஏற்றுக்கொள்ளாமல், தேவவசனமாகவே ஏற்றுக்கொண்டதினாலே நாங்கள் இடைவிடாமல் தேவனுக்கு ஸ்தோத்திரஞ் செலுத்துகிறோம்; அது மெய்யாகவே தேவவசனந்தான், விசுவாசிக்கிற உங்களுக்குள்ளே அது பெலனும் செய்கிறது.
	<br /><br />
	14. எப்படியெனில், சகோதரரே, யூதேயா தேசத்தில் கிறிஸ்து இயேசுவுக்குள்ளான தேவனுடைய சபைகளை நீங்கள் பின்பற்றினவர்களாகி, அவர்கள் யூதராலே எப்படிப் பாடுபட்டார்களோ, அப்படியே நீங்களும் உங்கள் சுய ஜனங்களாலே பாடுபட்டீர்கள்.
	<br /><br />
	15. அந்த யூதர்கள் கர்த்தராகிய இயேசுவையும், தங்கள் தீர்க்கதரிசிகளையும் கொலைசெய்தவர்களும், எங்களைத் துன்பப்படுத்தினவர்களும், தேவனுக்கேற்காதவர்களும், மனுஷர் யாவருக்கும் விரோதிகளுமாயிருந்து,
	<br /><br />
	16. புறஜாதியார் இரட்சிக்கப்படுவதற்கு நாங்கள் அவர்களோடே பேசாதபடிக்குத் தடைபண்ணுகிறார்கள்; இவ்விதமாய் எக்காலத்திலும் தங்கள் பாவங்களை நிறைவாக்குகிறார்கள்; அவர்கள்மேல் கோபாக்கினை பூரணமாய் வந்திருக்கிறது.
	<br /><br />
	17. சகோதரரே, நாங்கள் இருதயத்தின்படி உங்களோடிருந்து, சரீரத்தின்படி கொஞ்சக்காலம் உங்களைவிட்டுப் பிரிந்திருந்தபடியினாலே, உங்கள் முகத்தைப் பார்க்கவேண்டுமென்று மிகுந்த ஆசையோடே அதிகமாய்ப் பிரயத்தனம்பண்ணினோம்.
	<br /><br />
	18. ஆகையால், நாங்கள் உங்களிடத்தில் வர இரண்டொருதரம் மனதாயிருந்தோம், பவுலாகிய நானே வர மனதாயிருந்தேன்; சாத்தானோ எங்களைத் தடைபண்ணினான்.
	<br /><br />
	19. எங்களுக்கு நம்பிக்கையும் சந்தோஷமும் மகிழ்ச்சியின் கிரீடமுமாயிருப்பவர்கள் யார்? நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து வரும்போது அவருடைய சந்நிதானத்திலே நீங்களல்லவா அப்படியிருப்பீர்கள்;
	<br /><br />
	20. நீங்களே எங்களுக்கு மகிமையும் சந்தோஷமுமாயிருக்கிறீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneThessalonians2