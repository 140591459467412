import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 17 </title>
	<meta
          name="description"
          content="John Chapter 17 | யோவான் அதிகாரம் 17 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு இவைகளைச் சொன்னபின்பு தம்முடைய கண்களை வானத்துக்கு ஏறெடுத்து:
	<br /><br />
	2. பிதாவே, வேளை வந்தது, நீர் உம்முடைய குமாரனுக்குத் தந்தருளின யாவருக்கும் அவர் நித்தியஜீவனைக் கொடுக்கும்பொருட்டு மாம்சமான யாவர்மேலும் நீர் அவருக்கு அதிகாரங்கொடுத்தபடியே, உம்முடைய குமாரன் உம்மை மகிமைப்படுத்தும்படிக்கு நீர் உம்முடைய குமாரனை மகிமைப்படுத்தும்.
	<br /><br />
	3. ஒன்றான மெய்த்தேவனாகிய உம்மையும் நீர் அனுப்பினவராகிய இயேசு கிறிஸ்துவையும் அறிவதே நித்தியஜீவன்.
	<br /><br />
	4. பூமியிலே நான் உம்மை மகிமைப்படுத்தினேன்; நான் செய்யும்படி நீர் எனக்கு நியமித்த கிரியையைச் செய்து முடித்தேன்.
	<br /><br />
	5. பிதாவே, உலகம் உண்டாகிறதற்கு முன்னே உம்மிடத்தில் எனக்கு உண்டாயிருந்த மகிமையினாலே இப்பொழுது நீர் என்னை உம்மிடத்திலே மகிமைப்படுத்தும்.
	<br /><br />
	6. நீர் உலகத்தில் தெரிந்தெடுத்து எனக்குத் தந்த மனுஷருக்கு உம்முடைய நாமத்தை வெளிப்படுத்தினேன். அவர்கள் உம்முடையவர்களாயிருந்தார்கள், அவர்களை எனக்குத் தந்தீர், அவர்கள் உம்முடைய வசனத்தைக் கைக்கொண்டிருக்கிறார்கள்.
	<br /><br />
	7. நீர் எனக்குத் தந்தவைகளெல்லாம் உம்மாலே உண்டாயினவென்று இப்பொழுது அறிந்திருக்கிறார்கள்.
	<br /><br />
	8. நீர் எனக்குக் கொடுத்த வார்த்தைகளை நான் அவர்களுக்குக் கொடுத்தேன்; அவர்கள் அவைகளை ஏற்றுக்கொண்டு, நான் உம்மிடத்திலிருந்து புறப்பட்டுவந்தேன் என்று நிச்சயமாய் அறிந்து, நீர் என்னை அனுப்பினீர் என்று விசுவாசித்திருக்கிறார்கள்.
	<br /><br />
	9. நான் அவர்களுக்காக வேண்டிக்கொள்ளுகிறேன்; உலகத்துக்காக வேண்டிக்கொள்ளாமல், நீர் எனக்குத் தந்தவர்களுக்காக வேண்டிக்கொள்ளுகிறேன்; அவர்கள் உம்முடையவர்களாயிருக்கிறார்களே.
	<br /><br />
	10. என்னுடையவைகள் யாவும் உம்முடையவைகள், உம்முடையவைகள் என்னுடையவைகள்; அவர்களில் நான் மகிமைப்பட்டிருக்கிறேன்.
	<br /><br />
	11. நான் இனி உலகத்திலிரேன், இவர்கள் உலகத்திலிருக்கிறார்கள்; நான் உம்மிடத்திற்கு வருகிறேன். பரிசுத்த பிதாவே, நீர் எனக்குத் தந்தவர்கள் நம்மைப்போல ஒன்றாயிருக்கும்படிக்கு, நீர் அவர்களை உம்முடைய நாமத்தினாலே காத்துக்கொள்ளும்.
	<br /><br />
	12. நான் அவர்களுடனேகூட உலகத்திலிருக்கையில் அவர்களை உம்முடைய நாமத்தினாலே காத்துக்கொண்டேன்; நீர் எனக்குத் தந்தவர்களைக் காத்துக் கொண்டுவந்தேன்; வேதவாக்கியம் நிறைவேறத்தக்கதாக, கேட்டின் மகன் கெட்டுப்போனானேயல்லாமல், அவர்களில் ஒருவனும் கெட்டுப்போகவில்லை.
	<br /><br />
	13. இப்பொழுது நான் உம்மிடத்திற்கு வருகிறேன்; அவர்கள் என் சந்தோஷத்தை நிறைவாய் அடையும்படி உலகத்தில் இருக்கையில் இவைகளைச் சொல்லுகிறேன்.
	<br /><br />
	14. நான் உம்முடைய வார்த்தையை அவர்களுக்குக் கொடுத்தேன்; நான் உலகத்தானல்லாததுபோல அவர்களும் உலகத்தாரல்ல; ஆதலால் உலகம் அவர்களைப் பகைத்தது.
	<br /><br />
	15. நீர் அவர்களை உலகத்திலிருந்து எடுத்துக்கொள்ளும்படி நான் வேண்டிக்கொள்ளாமல், நீர் அவர்களைத் தீமையினின்று காக்கும்படி வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	16. நான் உலகத்தானல்லாததுபோல, அவர்களும் உலகத்தாரல்ல.
	<br /><br />
	17. உம்முடைய சத்தியத்தினாலே அவர்களைப் பரிசுத்தமாக்கும்; உம்முடைய வசனமே சத்தியம்.
	<br /><br />
	18. நீர் என்னை உலகத்தில் அனுப்பினதுபோல, நானும் அவர்களை உலகத்தில் அனுப்புகிறேன்.
	<br /><br />
	19. அவர்களும் சத்தியத்தினாலே பரிசுத்தமாக்கப்பட்டவர்களாகும்படி, அவர்களுக்காக நான் என்னைத்தானே பரிசுத்தமாக்குகிறேன்.
	<br /><br />
	20. நான் இவர்களுக்காக வேண்டிக்கொள்ளுகிறதுமல்லாமல், இவர்களுடைய வார்த்தையினால் என்னை விசுவாசிக்கிறவர்களுக்காகவும் வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	21. அவர்களெல்லாரும் ஒன்றாயிருக்கவும், பிதாவே, நீர் என்னை அனுப்பினதை உலகம் விசுவாசிக்கிறதற்காக, நீர் என்னிலேயும் நான் உம்மிலேயும் இருக்கிறதுபோல அவர்களெல்லாரும் நம்மில் ஒன்றாயிருக்கவும் வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	22. நாம் ஒன்றாயிருக்கிறதுபோல அவர்களும் ஒன்றாயிருக்கும்படி, நீர் எனக்குத் தந்த மகிமையை நான் அவர்களுக்குக் கொடுத்தேன்.
	<br /><br />
	23. ஒருமைப்பாட்டில் அவர்கள் தேறினவர்களாயிருக்கும்படிக்கும், என்னை நீர் அனுப்பினதையும், நீர் என்னில் அன்பாயிருக்கிறதுபோல அவர்களிலும் அன்பாயிருக்கிறதையும் உலகம் அறியும்படிக்கும், நான் அவர்களிலும் நீர் என்னிலும் இருக்கும்படி வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	24. பிதாவே, உலகத்தோற்றத்துக்கு முன் நீர் என்னில் அன்பாயிருந்தபடியினால், நீர் எனக்குத் தந்த என்னுடைய மகிமையை நீர் எனக்குத் தந்தவர்கள் காணும்படியாக, நான் எங்கே இருக்கிறேனோ அங்கே அவர்களும் என்னுடனேகூட இருக்க விரும்புகிறேன்.
	<br /><br />
	25. நீதியுள்ள பிதாவே, உலகம் உம்மை அறியவில்லை, நான் உம்மை அறிந்திருக்கிறேன்; நீர் என்னை அனுப்பினதை இவர்களும் அறிந்திருக்கிறார்கள்.
	<br /><br />
	26. நீர் என்னிடத்தில் வைத்த அன்பு அவர்களிடத்திலிருக்கும்படிக்கும், நானும் அவர்களிலிருக்கும்படிக்கும், உம்முடைய நாமத்தை அவர்களுக்குத் தெரியப்படுத்தினேன்; இன்னமும் தெரியப்படுத்துவேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John17