import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const James4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> Submitting To God | James in Tamil Bible Verse | யாக்கோபு - 4 </title>
	<meta
          name="description"
          content="Submitting To God | James Chapter - 4 in Tamil Bible Verse | யாக்கோபு அதிகாரம்-4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil Verse"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கடவுளுக்கு அடிபணிதல் *
			</h2>
<br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. உங்களுக்குள்ளே யுத்தங்களும் சண்டைகளும் எதினாலே வருகிறது; உங்கள் அவயவங்களில் போர்செய்கிற இச்சைகளினாலல்லவா?
	<br /><br />
	2. நீங்கள் இச்சித்தும் உங்களுக்குக் கிடைக்கவில்லை; நீங்கள் கொலைசெய்தும், பொறாமையுள்ளவர்களாயிருந்தும், அடையக்கூடாமற்போகிறீர்கள்; நீங்கள் சண்டையும் யுத்தமும்பண்ணியும், நீங்கள் விண்ணப்பம்பண்ணாமலிருக்கிறதினாலே, உங்களுக்குச் சித்திக்கிறதில்லை.
	<br /><br />
	3. நீங்கள் விண்ணப்பம்பண்ணியும், உங்கள் இச்சைகளை நிறைவேற்றும்படி செலவழிக்கவேண்டுமென்று தகாதவிதமாய் விண்ணப்பம்பண்ணுகிறபடியினால், பெற்றுக்கொள்ளாமலிருக்கிறீர்கள்.
	<br /><br />
	4. விபசாரரே, விபசாரிகளே, உலக சிநேகம் தேவனுக்கு விரோதமான பகையென்று அறியீர்களா? ஆகையால் உலகத்துக்குச் சிநேகிதனாயிருக்க விரும்புகிறவன் தேவனுக்குப் பகைஞனாகிறான்.
	<br /><br />
	5. நம்மில் வாசமாயிருக்கிற ஆவியானவர் நம்மிடத்தில் வைராக்கிய வாஞ்சையாயிருக்கிறாரென்று வேதவாக்கியம் வீணாய்ச் சொல்லுகிறதென்று நினைக்கிறீர்களா?
	<br /><br />
	6. அவர் அதிகமான கிருபையை அளிக்கிறாரே. ஆதலால் தேவன் பெருமையுள்ளவர்களுக்கு எதிர்த்து நிற்கிறார், தாழ்மையுள்ளவர்களுக்கோ கிருபை அளிக்கிறாரென்று சொல்லியிருக்கிறது.
	<br /><br />
	7. ஆகையால், தேவனுக்குக் கீழ்ப்படிந்திருங்கள்; பிசாசுக்கு எதிர்த்து நில்லுங்கள், அப்பொழுது அவன் உங்களைவிட்டு ஓடிப்போவான்.
	<br /><br />
	8. தேவனிடத்தில் சேருங்கள், அப்பொழுது அவர் உங்களிடத்தில் சேருவார். பாவிகளே, உங்கள் கைகளைச் சுத்திகரியுங்கள்; இருமனமுள்ளவர்களே, உங்கள் இருதயங்களைப் பரிசுத்தமாக்குங்கள்.
	<br /><br />
	9. நீங்கள் துயரப்பட்டுத் துக்கித்து அழுங்கள்; உங்கள் நகைப்பு துக்கிப்பாகவும், உங்கள் சந்தோஷம் சஞ்சலமாகவும் மாறக்கடவது.
	<br /><br />
	10. கர்த்தருக்கு முன்பாகத் தாழ்மைப்படுங்கள், அப்பொழுது அவர் உங்களை உயர்த்துவார்.
	<br /><br />
	11. சகோதரரே, ஒருவருக்கொருவர் விரோதமாய்ப் பேசாதிருங்கள்; சகோதரனுக்கு விரோதமாய்ப் பேசி, தன் சகோதரனைக் குற்றப்படுத்துகிறவன் நியாயப்பிரமாணத்திற்கு விரோதமாய்ப்பேசி நியாயப்பிரமாணத்தைக் குற்றப்படுத்துகிறான்; நியாயப்பிரமாணத்தைக் குற்றப்படுத்துவாயானால், நீ நியாயப்பிரமாணத்தின்படி செய்கிறவனாயிராமல், அதற்கு நியாயாதிபதியாயிருப்பாய்.
	<br /><br />
	12. நியாயப்பிரமாணத்தைக் கட்டளையிடுகிறவர் ஒருவரே, அவரே இரட்சிக்கவும் அழிக்கவும் வல்லவர்; மற்றவனைக் குற்றப்படுத்துகிறதற்கு நீ யார்?
	<br /><br />
	13. மேலும், நாங்கள் இன்றைக்கு அல்லது நாளைக்கு இன்ன பட்டணத்திற்குப் போய், அங்கே ஒருவருஷம் தங்கி, வியாபாரஞ்செய்து, சம்பாத்தியம் பண்ணுவோமென்கிறவர்களே, கேளுங்கள்.
	<br /><br />
	14. நாளைக்கு நடப்பது உங்களுக்குத் தெரியாதே. உங்கள் ஜீவன் எப்படிப்பட்டது? கொஞ்சக்காலந்தோன்றிப் பின்பு தோன்றாமற்போகிற புகையைப்போலிருக்கிறதே.
	<br /><br />
	15. ஆதலால்: ஆண்டவருக்குச் சித்தமானால், நாங்களும் உயிரோடிருந்தால், இன்னின்னதைச் செய்வோம் என்று சொல்லவேண்டும்.
	<br /><br />
	16. இப்பொழுது உங்கள் வீம்புகளில் மேன்மைபாராட்டுகிறீர்கள்; இப்படிப்பட்ட மேன்மைபாராட்டல் யாவும் பொல்லாங்காயிருக்கிறது.
	<br /><br />
	17. ஆதலால், ஒருவன் நன்மைசெய்ய அறிந்தவனாயிருந்தும், அதைச் செய்யாமற்போனால், அது அவனுக்குப் பாவமாயிருக்கும்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* யாக்கோபு 4 உலகத்துடனான நட்புக்கும் கடவுளில் உள்ள வாழ்க்கைக்கும் இடையிலான போரை விவரிக்கிறது. யாக்கோபு கடவுளுக்கு அடிபணியவும், பிசாசு மற்றும் அவனது திட்டங்களை எதிர்க்கவும் அறிவுறுத்துகிறார். அவர் நம்மை உயர்த்துவதற்கு, கடவுளுக்கு முன்பாக நம்மைத் தாழ்த்த வேண்டும் என்று நாம் நினைவுபடுத்துகிறோம். பூமியில் நமது நேரம் குறைவாக இருப்பதாலும், கடவுளுடைய சித்தமே மேலோங்கி இருப்பதாலும், திட்டங்களைச் செய்யும்போது கர்த்தருடைய சித்தத்தை கணக்கில் எடுத்துக்கொள்ளும்படி எச்சரிக்கப்படுகிறோம். *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default James4