import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 14 </title>
	<meta
          name="description"
          content="Isaiah 14 | ஏசாயா 14 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் யாக்கோபுக்கு இரங்கி, பின்னும் இஸ்ரவேலரைத் தெரிந்துகொண்டு, அவர்களை அவர்கள் தேசத்திலே தாபரிக்கப்பண்ணுவார்; அந்நியரும் அவர்களோடு சேர்க்கையாகி, யாக்கோபின் வம்சத்தோடே கூடிக்கொள்வார்கள்.
	<br /><br />
	2. ஜனங்கள் அவர்களை அழைத்துக்கொண்டுபோய் அவர்கள் ஸ்தானத்தில் விடுவார்கள்; இஸ்ரவேல் வம்சத்தார் கர்த்தருடைய தேசத்திலே அவர்களை வேலைக்காரராகவும் வேலைக்காரிகளாகவும் கையாண்டு, தங்களைச் சிறையாக்கினவர்களைச் சிறையாக்கி, தங்களை ஒடுக்கினவர்களை ஆளுவார்கள்.
	<br /><br />
	3. கர்த்தர் உன் துக்கத்தையும், உன் தத்தளிப்பையும், நீ அடிமையாக்கப்பட்டிருந்த கடினமான அடிமைத்தனத்தையும் நீக்கி, உன்னை இளைப்பாறப்பண்ணும் அக்காலத்திலே,
	<br /><br />
	4. நீ பாபிலோன் ராஜாவின்மேல் சொல்லும் வாக்கியமாவது: ஒடுக்கினவன் ஒழிந்துபோனானே! பொன்னகரி ஒழிந்துபோயிற்றே!
	<br /><br />
	5. கர்த்தர் துஷ்டரின் தண்டாயுதத்தையும், அரசாண்டவர்களின் செங்கோலையும் முறித்துப்போட்டார்.
	<br /><br />
	6. உக்கிரங்கொண்டு ஓயாத அடியாய் ஜனங்களை அடித்து, கோபமாய் ஜாதிகளை அரசாண்டவன், தடுப்பாரில்லாமல் துன்பப்படுத்தப்படுகிறான்.
	<br /><br />
	7. பூமிமுழுதும் இளைப்பாறி அமைந்திருக்கிறது; கெம்பீரமாய் முழங்குகிறார்கள்.
	<br /><br />
	8. தேவதாரு விருட்சங்களும், லீபனோனின் கேதுருக்களும், உன்னிமித்தம் சந்தோஷப்பட்டு, நீ விழுந்துகிடந்தது முதற்கொண்டு எங்களை வெட்டவருவார் ஒருவரும் இல்லை என்று சொல்லுகிறது.
	<br /><br />
	9. கீழே இருக்கிற பாதாளம் உன்னிமித்தம் அதிர்ந்து, உன் வருகைக்கு எதிர்கொண்டு, பூமியில் அதிபதிகளாயிருந்து செத்த இராட்சதர் யாவரையும் உன்னிமித்தம் எழுப்பி, ஜாதிகளுடைய எல்லா ராஜாக்களையும் அவர்களுடைய சிங்காசனங்களிலிருந்து எழுந்திருக்கப்பண்ணுகிறது.
	<br /><br />
	10. அவர்களெல்லாரும் உன்னை நோக்கி: நீயும் எங்களைப்போலப் பலட்சயமானாயோ? எங்களுக்குச் சமானமானாயோ? என்று சொல்லுவார்கள்.
	<br /><br />
	11. உன் ஆடம்பரமும், உன் வாத்தியங்களின் முழக்கமும் பாதாளத்தில் தள்ளுண்டுபோயிற்று; புழுக்களே உன் படுக்கை, பூச்சிகளே உன் போர்வை.
	<br /><br />
	12. அதிகாலையின் மகனாகிய விடிவெள்ளியே, நீ வானத்திலிருந்து விழுந்தாயே! ஜாதிகளை ஈனப்படுத்தினவனே, நீ தரையிலே விழ வெட்டப்பட்டாயே!
	<br /><br />
	13. நான் வானத்துக்கு ஏறுவேன், தேவனுடைய நட்சத்திரங்களுக்குமேலாக என் சிங்காசனத்தை உயர்த்துவேன்; வடபுறங்களிலுள்ள ஆராதனைக் கூட்டத்தின் பர்வதத்திலே வீற்றிருப்பேன் என்றும்,
	<br /><br />
	14. நான் மேகங்களுக்கு மேலாக உன்னதங்களில் ஏறுவேன்; உன்னதமானவருக்கு ஒப்பாவேன் என்றும் நீ உன் இருதயத்தில் சொன்னாயே.
	<br /><br />
	15. ஆனாலும் நீ அகாதமான பாதாளத்திலே தள்ளுண்டுபோனாய்.
	<br /><br />
	16. உன்னைக் காண்கிறவர்கள் உன்னை உற்றுப்பார்த்து, உன்னைக்குறித்துச் சிந்தித்து: இவன்தானா பூமியைத் தத்தளிக்கவும், ராஜ்யங்களை அதிரவும் செய்து,
	<br /><br />
	17. உலகத்தை வனாந்தரமாக்கி, அதின் நகரங்களை அழித்து, சிறைப்பட்டவர்களைத் தங்கள் வீடுகளுக்குப் போகவிடாமலிருந்தவன் என்பார்கள்.
	<br /><br />
	18. ஜாதிகளுடைய சகல ராஜாக்களும், அவரவர் தங்கள் அறையிலே மகிமையோடே கிடத்தப்பட்டிருக்கிறார்கள்.
	<br /><br />
	19. நீயோ அழுகிப்போன கிளையைப்போலவும், பட்டயக்குத்தால் கொலையுண்டவர்களின் உடுப்பைப்போலவும், ஒரு குழியின் கற்களுக்குள்ளே கிடக்கிறவர்களைப்போலவும், காலால் மிதிக்கப்பட்ட பிணத்தைப்போலவும், உன் கல்லறைக்குப் புறம்பாய் எறிந்துவிடப்பட்டாய்.
	<br /><br />
	20. நீ அவர்களோடே அடக்கம் பண்ணப்படுவதில்லை; நீ உன் தேசத்தைக் கெடுத்து உன் ஜனத்தைக் கொன்றுபோட்டாய்; தீமைசெய்கிறவர்களுடைய சந்ததி ஒருபோதும் பேர்பெறுவதில்லை.
	<br /><br />
	21. அவன் புத்திரர் எழும்பித் தேசத்தைச் சுதந்தரித்துக்கொண்டு, உலகத்தைப் பட்டணங்களால் நிரப்பாதபடிக்கு, அவர்கள் பிதாக்களுடைய அக்கிரமத்தினிமித்தம் அவர்களைக் கொலைசெய்ய ஆயத்தம்பண்ணுங்கள்.
	<br /><br />
	22. நான் அவர்களுக்கு விரோதமாய் எழும்புவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; பாபிலோனுடைய பேரையும், அதில் மீந்திருக்கிறதையும், புத்திரனையும் பௌத்திரனையும் சங்கரிப்பேனென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	23. அதை முள்ளம்பன்றிகளுக்குச் சுதந்தரமும், தண்ணீர் நிற்கும் பள்ளங்களுமாக்கி, அதைச் சங்காரம் என்னும் துடைப்பத்தினால் பெருக்கிவிடுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	24. நான் நினைத்திருக்கிறபடியே நடக்கும்; நான் நிர்ணயித்தபடியே நிலைநிற்கும் என்று சேனைகளின் கர்த்தர் ஆணையிட்டுச் சொன்னார்.
	<br /><br />
	25. அசீரியனை என் தேசத்திலே முறித்து, என் மலைகளின்மேல் அவனை மிதித்துப்போடுவேன்; அப்பொழுது அவனுடைய நுகம் அவர்கள்மேலிருந்து விலகி, அவனுடைய சுமை அவர்கள் தோளிலிருந்து நீங்கும்.
	<br /><br />
	26. தேசமனைத்தின்மேலும் நிர்ணயிக்கப்பட்ட யோசனை இதுவே; சகல ஜாதிகள்மேலும் நீட்டப்பட்டிருக்கிற கையும் இதுவே என்றார்.
	<br /><br />
	27. சேனைகளின் கர்த்தர் இப்படி நிர்ணயித்திருக்கிறார், யார் அதை வியர்த்தமாக்குவான்? அவருடைய கை நீட்டப்பட்டிருக்கிறது, யார் அதைத் திருப்புவான்?
	<br /><br />
	28. ஆகாஸ் ராஜா மரணமடைந்த வருஷத்திலே உண்டான பாரம் என்னவென்றால்:
	<br /><br />
	29. முழு பெலிஸ்தியாவே, உன்னை அடித்த கோல் முறிந்ததென்று அக்களிப்பாயிராதே; பாம்பின் வேரிலிருந்து கட்டுவிரியன் தோன்றும்; அதின் கனி பறக்கிற அக்கினி சர்ப்பமாயிருக்கும்.
	<br /><br />
	30. தரித்திரரின் தலைப்பிள்ளைகள் திருப்தியாய்ப் புசித்து, எளியவர்கள் சுகமாய்ப் படுத்திருப்பார்கள்; உன்வேரைப் பஞ்சத்தினாலே சாகப்பண்ணுவேன், உன்னில் மீதியானவர்களை அவன் கொன்றுபோடுவான்.
	<br /><br />
	31. வாசலே அலறு; நகரமே கதறு; பெலிஸ்தியாவே, நீ முழுதும் கரைந்துபோகிறாய்; ஏனென்றால், வடக்கே இருந்து புகைக்காடாய் வருகிறான்; அவன் கூட்டங்களில் தனித்தவனில்லை.
	<br /><br />
	32. இப்போதும் இந்த ஜாதியின் ஸ்தானாபதிகளுக்கு என்ன மாறுத்தரவு சொல்லப்படும்? கர்த்தர் சீயோனை அஸ்திபாரப்படுத்தினார்; அவருடைய ஜனத்தில் சிறுமையானவர்கள் அதிலே திடன்கொண்டு தங்குவார்கள் என்பதே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah14