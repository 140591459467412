import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 6 </title>
	<meta
          name="description"
          content="Ezekiel 6 | எசேக்கியேல்  6 |
          Ezekiel 6 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ இஸ்ரவேலின் பர்வதங்களுக்கு நேராக உன் முகத்தைத் திருப்பி, அவைகளுக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்துச் சொல்லவேண்டியது என்னவென்றால்,
	<br /><br />
	3. இஸ்ரவேலின் பர்வதங்களே, கர்த்தராகிய ஆண்டவரின் வார்த்தையைக் கேளுங்கள்; கர்த்தராகிய ஆண்டவர் பர்வதங்களையும் குன்றுகளையும் ஓடைகளையும் பள்ளத்தாக்குகளையும் நோக்கி: இதோ, உங்கள்மேல் நான், நானே பட்டயத்தை வரப்பண்ணி, உங்கள் மேடைகளை அழித்துப்போடுவேன்.
	<br /><br />
	4. உங்கள் பலிபீடங்கள் பாழாக்கப்பட்டு, உங்கள் சிலைகள் தகர்க்கப்படும்; உங்களில் கொலையுண்கிறவர்களை உங்கள் நரகலான விக்கிரகங்களுக்கு முன்பாக விழப்பண்ணுவேன்.
	<br /><br />
	5. நான் இஸ்ரவேல் புத்திரருடைய பிரேதங்களை அவர்களுடைய நரகலான விக்கிரகங்களின் முன்னே கிடக்கப்பண்ணி, உங்கள் பலிபீடங்களைச் சுற்றிலும் உங்கள் எலும்புகளைச் சிதறப்பண்ணுவேன்.
	<br /><br />
	6. உங்கள் பலிபீடங்கள் நிர்மூலமும் பாழுமாகும்படிக்கும், உங்கள் நரகலான விக்கிரகங்கள் தகர்க்கப்பட்டு, ஓய்ந்து, உங்கள் சிலைகள் வெட்டுண்டு, உங்கள் கிரியைகள் குலைந்துபோகும்படிக்கும், உங்களுடைய சகல வாசஸ்தலங்களிலுமுள்ள பட்டணங்கள் நிர்மூலமும் உங்கள் மேடைகள் பாழுமாகும்.
	<br /><br />
	7. கொலைசெய்யப்பட்டவர்கள் உங்கள் நடுவில் விழுவார்கள்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	8. நீங்கள் தேசங்களில் சிதறடிக்கப்படும்போது, புறஜாதிகளுக்குள்ளே பட்டயத்துக்குத் தப்புவாரை உங்களுக்கு மீதியாக வைப்பேன்.
	<br /><br />
	9. என்னை விட்டுச் சோரம்போகிற இருதயத்தைக்குறித்தும், தங்கள் நரகலான விக்கிரகங்களின் பின்னே சோரம்போகிற தங்கள் கண்களைக்குறித்தும் மனமடிவானேன் என்று உங்களில் தப்பிப்போன அவர்கள் தாங்கள் சிறைப்பட்டிருக்கும் புறஜாதிகளுக்குள்ளே என்னை நினைத்து, தங்களுடைய சகல அருவருப்புகளினாலும் தாங்கள் செய்த பொல்லாப்புகளினிமித்தம் தங்களையே வெறுத்து,
	<br /><br />
	10. இந்தத் தீங்குகளையெல்லாம் தங்களுக்கு நேரிடப்பண்ணுவேன் என்று கர்த்தராகிய நான் விருதாவாய்ச் சொன்னதில்லையென்று அறிந்துகொள்வார்கள் என்றார்.
	<br /><br />
	11. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நீ உன் கையில் அடித்து, உன் காலால் தட்டி, இஸ்ரவேல் வம்சத்தாருடைய சகல பொல்லாத அருவருப்புகளினிமித்தமும் ஐயோ! என்று சொல்; அவர்கள் பட்டயத்தாலும் பஞ்சத்தாலும் கொள்ளைநோயாலும் விழுவார்கள்.
	<br /><br />
	12. தூரத்திலிருக்கிறவன் கொள்ளைநோயால் சாவான்; சமீபத்திலிருக்கிறவன் பட்டயத்தால் விழுவான்; மீதியாயிருந்து, முற்றிக்கைபோடப்பட்டவன் பஞ்சத்தால் சாவான்; இப்படி அவர்களில் என் உக்கிரத்தைத் தீர்த்துக்கொள்ளுவேன்.
	<br /><br />
	13. அவர்கள் தங்கள் நரகலான சகல விக்கிரகங்களுக்கும் மதுரவாசனையான தூபத்தைக் காட்டின ஸ்தலங்களாகிய உயர்ந்த சகல மேடுகளிலும், பர்வதங்களுடைய சகல சிகரங்களிலும், பச்சையான சகல விருட்சங்களின்கீழும், தழைத்திருக்கிற சகல கர்வாலி மரங்களின்கீழும், அவர்களுடைய நரகலான விக்கிரகங்களின் நடுவிலும் அவர்களுடைய பலிபீடங்களைச் சுற்றிலும், அவர்களில் கொலையுண்டவர்கள் கிடக்கும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	14. நான் என் கையை அவர்களுக்கு விரோதமாக நீட்டி, அவர்களுடைய எல்லா வாசஸ்தலங்களுமுள்ள தேசத்தை அழித்து, அதைத் திப்லாத்தின் வனாந்தரத்தைப்பார்க்கிலும் அதிகமாய்ப் பாழாக்குவேன், அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial6