import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah25 = () => {
  const verseNumber = 25;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 25 </title>
	<meta
          name="description"
          content="Jeremiah 25 | எரேமியா 25 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோசியாவின் குமாரனாகிய யோயாக்கீம் என்கிற யூதாவுடைய ராஜாவின் நாலாம் வருஷத்துக்குச் சரியான, பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் அரசாண்ட முதலாம் வருஷத்திலே யூதாவின் ஜனம் அனைத்தையும் குறித்து எரேமியாவுக்கு உண்டான வார்த்தை;
	<br /><br />
	2. அதைத் தீர்க்கதரிசியாகிய எரேமியா யூதாவின் ஜனம் அனைத்துக்கும், எருசலேமின் குடிகள் எல்லாருக்கும் அறிவிக்கிறதற்காக அவர்களை நோக்கி:
	<br /><br />
	3. ஆமோனின் குமாரனாகிய யோசியாவின் பதின்மூன்றாம் வருஷம் துவக்கி இந்நாள்மட்டும் சென்ற இந்த இருபத்துமூன்று வருஷமாகக் கர்த்தருடைய வார்த்தை எனக்கு உண்டாயிற்று; அதை நான் உங்களுக்கு ஏற்கனவே சொல்லிக்கொண்டுவந்தும் நீங்கள் கேளாமற்போனீர்கள்.
	<br /><br />
	4. கர்த்தர் உங்களிடத்திற்குத் தீர்க்கதரிசிகளாகிய தம்முடைய சகல ஊழியக்காரரையும் ஏற்கனவே அனுப்பிக்கொண்டேயிருந்தும், நீங்கள் கேளாமலும், கேட்கும்படிக்கு உங்கள் செவிகளைச் சாயாமலும்போனீர்கள்.
	<br /><br />
	5. அவர்களைக்கொண்டு அவர்: உங்களில் அவனவன் தன்தன் பொல்லாத வழியையும், உங்கள் கிரியைகளின் பொல்லாப்பையும் விட்டுத் திரும்பி, கர்த்தர் உங்களுக்கும் உங்கள் பிதாக்களுக்கும் கொடுத்த தேசத்தில் சதாகாலமும் குடியிருந்து,
	<br /><br />
	6. அந்நிய தேவர்களைப் பின்பற்றாமலும், அவைகளுக்கு ஆராதனைசெய்யாமலும், அவைகளைப் பணியாமலுமிருந்து, நான் உங்களுக்குத் தீமை செய்யாதபடிக்கு உங்கள் கைகளின் செய்கைகளால் எனக்குக் கோபமுண்டாக்காமலும் இருங்கள் என்று சொல்லியனுப்பினேன்.
	<br /><br />
	7. நீங்களோ, உங்களுக்குத் தீமையாக உங்கள் கைகளின் செய்கைகளாலே எனக்குக் கோபமூட்டும்படிக்கு, என் சொல்லைக் கேளாமற்போனீர்கள் என்று கர்த்தர் சொல்லுகிறார் என்றான்.
	<br /><br />
	8. நீங்கள் என் வார்த்தைகளைக் கேளாமற்போனபடியினால்,
	<br /><br />
	9. இதோ, நான் வடக்கேயிருக்கிற சகல வம்சங்களையும், என் ஊழியக்காரனாகிய நேபுகாத்நேச்சார் என்கிற பாபிலோன் ராஜாவையும் அழைத்தனுப்பி, அவர்களை இந்தத் தேசத்துக்கு விரோதமாகவும், இதின் குடிகளுக்கு விரோதமாகவும், சுற்றிலுமிருக்கிற இந்த எல்லா ஜாதிகளுக்கும் விரோதமாகவும் வரப்பண்ணி, அவைகளைச் சங்காரத்துக்கு ஒப்புக்கொடுத்து, அவைகளைப் பாழாகவும் இகழ்ச்சிக்குறியாகிய ஈசல்போடுதலாகவும், நித்திய வனாந்தரங்களாகவும் செய்வேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. மகிழ்ச்சியின் சத்தத்தையும், சந்தோஷத்தின் சத்தத்தையும், மணவாளனின் சத்தத்தையும், மணவாட்டியின் சத்தத்தையும், ஏந்திரத்தின் சத்தத்தையும் விளக்கின் வெளிச்சத்தையும் அவர்களிலிருந்து நீங்கப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. இந்தத் தேசமெல்லாம் வனாந்தரமும் பாழுமாகும்; இந்த ஜாதிகளோ, எழுபது வருஷமாகப் பாபிலோன் ராஜாவைச் சேவிப்பார்கள்.
	<br /><br />
	12. எழுபது வருஷம் நிறைவேறினபின்பு, நான் பாபிலோன் ராஜாவினிடத்திலும், அந்த ஜாதியினிடத்திலும், கல்தேயருடைய தேசத்தினிடத்திலும், அவர்களுடைய அக்கிரமத்தை விசாரித்து, அதை நித்திய பாழிடமாக்கி,
	<br /><br />
	13. நான் அந்தத் தேசத்துக்கு விரோதமாய் உரைத்த என் வார்த்தைகளையெல்லாம், எரேமியா சகல ஜாதிகளுக்கும் விரோதமாகத் தீர்க்கதரிசனமாய்ச் சொன்னதும், இந்தப் புஸ்தகத்தில் எழுதியிருக்கிறதுமான யாவையும் அதின்மேல் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. அநேகம் ஜாதிகளும் பெரிய ராஜாக்களும் அவர்களை அடிமைகொள்வார்கள்; நான் அவர்களுக்கு அவர்கள் கிரியைகளுக்குத்தக்கதாகவும், அவர்கள் கைகளின் செய்கைகளுக்குத்தக்கதாகவும் பதில் அளிப்பேன் என்கிறார்.
	<br /><br />
	15. இஸ்ரவேலின் தேவனாகிய கர்த்தர் என்னை நோக்கி: நான் உன்னை அனுப்புகிற ஜாதிகள் குடித்து, நான் தங்களுக்குள் அனுப்பும் பட்டயத்தால் அவர்கள் தள்ளாடி, புத்திகெட்டுப்போகும்படிக்கு,
	<br /><br />
	16. இந்த உக்கிரமாகிய மதுபானத்தின் பாத்திரத்தை நீ என் கையிலிருந்து வாங்கி, அவர்கள் எல்லாருக்கும் அதிலே குடிக்கக்கொடு என்றார்.
	<br /><br />
	17. அப்பொழுது நான் அந்தப் பாத்திரத்தைக் கர்த்தருடைய கையிலிருந்து வாங்கி, கர்த்தர் என்னை அனுப்பின எல்லா ஜாதிகளுக்கும் குடிக்கக் கொடுத்தேன்.
	<br /><br />
	18. எருசலேமுக்கும் யூதாவின் பட்டணங்களுக்கும், அதின் ராஜாக்களுக்கும், அதின் பிரபுக்களுக்கும், அவர்களை இந்நாளிலிருக்கிறபடி வனாந்தரமும் பாழும் இகழ்ச்சிக்குறியாகிய ஈசல்போடுதலும் சாபமுமாக்கிப்போடும்படி குடிக்கக்கொடுத்தேன்.
	<br /><br />
	19. எகிப்தின் ராஜாவாகிய பார்வோனுக்கும், அவன் ஊழியக்காரருக்கும், அவன் பிரபுக்களுக்கும், அவனுடைய எல்லா ஜனத்துக்கும்,
	<br /><br />
	20. கலந்து குடியிருக்கிற அனைவருக்கும், ஊத்ஸ் தேசத்தினுடைய எல்லா ராஜாக்களுக்கும், பெலிஸ்தருடைய தேசத்தில் இருக்கிற எல்லா ராஜாக்களுக்கும், அஸ்கலோனுக்கும், காசாவுக்கும், எக்ரோனுக்கும், அஸ்தோத்தில் மீதியானவர்களுக்கும்,
	<br /><br />
	21. ஏதோமுக்கும், மோவாபுக்கும், அம்மோன் புத்திரருக்கும்,
	<br /><br />
	22. தீருவினுடைய எல்லா ராஜாக்களுக்கும், சீதோனுடைய எல்லா ராஜாக்களுக்கும், சமுத்திரத்துக்கு அக்கரையான தீவுகளின் ராஜாக்களுக்கும்,
	<br /><br />
	23. தேதானுக்கும், தேமாவுக்கும், பூசுக்கும், கடையாந்தரங்களிலுள்ள அனைவருக்கும்,
	<br /><br />
	24. அரபிதேசத்து எல்லா ராஜாக்களுக்கும், வனாந்தரத்தில் கலந்து குடியிருக்கிறவர்களுடைய எல்லா ராஜாக்களுக்கும்,
	<br /><br />
	25. சிம்ரியினுடைய எல்லா ராஜாக்களுக்கும், ஏலாமினுடைய எல்லா ராஜாக்களுக்கும், மேதியாவினுடைய எல்லா ராஜாக்களுக்கும்,
	<br /><br />
	26. வடக்கேயிருக்கிற எல்லா ராஜாக்களுக்கும், சமீபமானவர்களும் தூரமானவர்களுமாகிய அவரவர்களுக்கும், பூமியின்மீதிலுள்ள சகல தேசத்து ராஜ்யங்களுக்கும் குடிக்கக்கொடுத்தேன்; சேசாக்கு என்கிற ராஜாவும் அவர்களுக்குப் பிற்பாடு குடிப்பான் என்றார்.
	<br /><br />
	27. நீங்கள் குடித்து, வெறித்து, வாந்திபண்ணி, நான் உங்களுக்குள் அனுப்பும் பட்டயத்தாலே எழுந்திராதபடிக்கு விழுங்கள் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் உரைக்கிறார் என்று நீ அவர்களுக்குச் சொல்லு.
	<br /><br />
	28. அவர்கள் குடிக்கிறதற்கு அந்தப் பாத்திரத்தை உன் கையில் வாங்கமாட்டோம் என்று சொல்வார்களானால், நீ அவர்களை நோக்கி: நீங்கள் குடித்துத் தீரவேண்டும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்று சொல்லு.
	<br /><br />
	29. இதோ, தீங்கைக் கட்டளையிட நான் என் நாமம் தரிக்கப்பட்ட நகரத்திலே துவக்கும்போது, நீங்கள் தண்டனைக்குத் தப்புவீர்களோ? நீங்கள் தப்புவதில்லை; நான் பூமியின் எல்லாக் குடிகளின்மேலும் பட்டயத்தை வரவழைக்கிறேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	30. ஆதலால் நீ அவர்களுக்கு விரோதமாக இந்த வார்த்தைகளையெல்லாம் தீர்க்கதரிசனமாக உரைத்து, அவர்களை நோக்கி: கர்த்தர் உயரத்திலிருந்து கெர்ச்சித்து, தமது பரிசுத்த வாசஸ்தலத்திலிருந்து தம்முடைய சத்தத்தைக் காட்டி, தம்முடைய தாபரத்துக்கு விரோதமாய்க் கெர்ச்சிக்கவே கெர்ச்சித்து, ஆலையை மிதிக்கிறவர்கள் ஆர்ப்பரிப்பதுபோல் பூமியினுடைய எல்லாக் குடிகளுக்கும் விரோதமாக ஆர்ப்பரிப்பார் என்று சொல் என்றார்.
	<br /><br />
	31. ஆரவாரம் பூமியின் கடையாந்தரமட்டும் போய் எட்டும்; ஜாதிகளோடே கர்த்தருக்கு வழக்கு இருக்கிறது; மாம்சமான யாவரோடும் அவர் நியாயத்துக்குள் பிரவேசிப்பார்; துன்மார்க்கரைப் பட்டயத்துக்கு ஒப்புக்கொடுப்பார் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	32. இதோ, ஜாதிஜாதிக்குத் தீமை பரம்பும், பூமியின் எல்லைகளிலிருந்து மகா புசல் எழும்பும்.
	<br /><br />
	33. அக்காலத்திலே பூமியின் ஒருமுனை துவக்கிப் பூமியின் மறுமுனைமட்டும் கர்த்தரால் கொலையுண்டவர்கள் கிடப்பார்கள்; அவர்கள் புலம்பப்படாமலும் சேர்க்கப்படாமலும் அடக்கம்பண்ணப்படாமலும் பூமியின்மேல் எருவாவார்கள்.
	<br /><br />
	34. மேய்ப்பர்களே, அலறுங்கள்; மந்தையில் பிரஸ்தாபமானவர்களே, சாம்பலில் புரண்டு கதறுங்கள்; நீங்கள் வெட்டப்படவும் சிதறடிக்கப்படவும் உங்கள் நாட்கள் நிறைவேறின; உச்சிதமான பாத்திரம்போல் விழுந்து நொறுங்குவீர்கள்.
	<br /><br />
	35. மேய்ப்பர்கள் ஓடிப்போகிறதற்கும், மந்தையில் பிரஸ்தாபமானவர்கள் தப்பித்துக்கொள்ளுகிறதற்கும் இடமிராது.
	<br /><br />
	36. தங்கள் மேய்ச்சலைக் கர்த்தர் பாழாக்கினதினிமித்தம் மேய்ப்பர்கள் கூப்பிடுகிறதும், மந்தையில் பிரஸ்தாபமானவர்கள் அலறுகிறதுமான சத்தமுண்டாகும்.
	<br /><br />
	37. அவர்கள் சுகித்திருந்த தாபரங்கள் கர்த்தருடைய கோபத்தின் எரிச்சலாலே சங்காரமாயின.
	<br /><br />
	38. அவர் பதிவிருந்து புறப்படும் சிங்கத்தைப்போலிருப்பார்; ஒடுக்குகிறவனுடைய உக்கிரத்தினாலும், அவனுடைய உக்கிரகோபத்தினாலும் அவர்கள் தேசம் பாழாயிற்றென்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah25