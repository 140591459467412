import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial23 = () => {
  const verseNumber = 23;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 23 </title>
	<meta
          name="description"
          content="Ezekiel 23 | எசேக்கியேல்  23 |
          Ezekiel 23 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, ஒரே தாயின் குமாரத்திகளாகிய இரண்டு ஸ்திரீகள் இருந்தார்கள்.
	<br /><br />
	3. அவர்கள் எகிப்திலே வேசித்தனம்பண்ணினார்கள்; தங்கள் இளம்பிராயத்திலே வேசித்தனம்பண்ணினார்கள்; அங்கே அவர்களுடைய ஸ்தனங்கள் அமுக்கப்பட்டு, அவர்களுடைய கன்னிமையின் கொங்கைகள் தொடப்பட்டது.
	<br /><br />
	4. அவர்களில் மூத்தவளின் பெயர் அகோலாள், அவளுடைய தங்கையின் பெயர் அகோலிபாள்; அவர்கள் என்னுடையவர்களாகி, குமாரரையும் குமாரத்திகளையும் பெற்றார்கள்; இவைகளே அவர்களுடைய பெயர்கள்; அகோலாள் என்பதற்குச் சமாரியா என்றும், அகோலிபாள் என்பதற்கு எருசலேம் என்றும் பொருளாம்.
	<br /><br />
	5. அகோலாள் என்னுடையவளாயிருக்கும்போது சோரம்போனாள்.
	<br /><br />
	6. நீலாம்பரந்தரித்த தலைவரும், அதிபதிகளும், சௌந்தரிய வாலிபரும், குதிரைகளின்மேல் ஏறுகிற வீரருமாயிருந்த சமீபதேசத்தாராகிய அசீரியரென்கிற தன் சிநேகிதர்மேல் அவள் மோகித்து,
	<br /><br />
	7. அசீரியரின் புத்திரரில் சிரேஷ்டமான அனைவரோடும், தான் மோகித்த அனைவரோடும் தன் வேசித்தனங்களை நடப்பித்து, அவர்களுடைய நரகலான சகல விக்கிரகங்களாலும் தீட்டுப்பட்டுப்போனாள்.
	<br /><br />
	8. தான் எகிப்திலே பண்ணின வேசித்தனங்களை அவள் விடவில்லை; அவர்கள் அவளுடைய வாலிபத்திலே அவளோடே சயனித்து, அவளுடைய கன்னிமையின் கொங்கைகளைத் தொட்டு, அவளிடத்தில் தங்கள் வேசித்தனத்தை நடப்பித்தார்கள்.
	<br /><br />
	9. ஆகையால் அவளுடைய சிநேகிதரின் கையிலே, அவள் மோகித்திருந்த அசீரியபுத்திரரின் கையிலே, நான் அவளை ஒப்புக்கொடுத்தேன்.
	<br /><br />
	10. அவர்கள் அவளை நிர்வாணமாக்கினார்கள்; அவளுடைய குமாரரையும் அவளுடைய குமாரத்திகளையும் சிறைபிடித்து, அவளையோ பட்டயத்தினால் கொன்றுபோட்டார்கள்; அப்படியே அவளிடத்தில் ஆக்கினைகள் செய்யப்பட்டபடியினால் ஸ்திரீகளுக்குள் அவகீர்த்தியுள்ளவளானாள்.
	<br /><br />
	11. அவளுடைய தங்கையாகிய அகோலிபாள் இதைக் கண்டும், தன் மோகவிகாரத்தில் அவளைப்பார்க்கிலும் கெட்டவளானாள்; தன் சகோதரியின் வேசித்தனங்களிலும் தன் வேசித்தனங்கள் அதிகமாயிற்று.
	<br /><br />
	12. மகா அலங்கார உடுப்புள்ள தலைவரும், அதிபதிகளும், குதிரைகள்மேல் ஏறுகிற வீரரும், சௌந்தரியமுள்ள வாலிபருமான சமீபதேசத்தாராகிய அசீரிய புத்திரர்மேல் மோகங்கொண்டாள்.
	<br /><br />
	13. அவளும் அசுத்தமானாளென்றும், அவர்கள் இருவரும் ஒரே வழியில் போனார்களென்றும் கண்டேன்.
	<br /><br />
	14. அவள் தன் வேசித்தனங்களில் அதிகரித்தாள்; சுவரில் ஜாதிலிங்கத்தால் சித்திரந்தீர்ந்த கல்தேயரின் புருஷ சுரூபங்களைக் கண்டாள்.
	<br /><br />
	15. அவர்கள் எல்லாரும் தங்கள் ஜெந்மதேசமாகிய கல்தேயாவில் உள்ள பாபிலோன் புத்திரரின் சாயலாகத் தங்கள் அரைகளில் கச்சை கட்டினவர்களும், தங்கள் தலைகளில் சாயந்தீர்ந்த பெரிய பாகைகளைத் தரித்தவர்களும், பார்வைக்கு ராஜகுமாரர்களுமாக இருந்தார்கள்.
	<br /><br />
	16. அவளுடைய கண்கள் அவர்களைப் பார்த்தவுடனே, அவள் அவர்கள்மேல் மோகித்து, கல்தேயாவுக்கு அவர்களண்டையிலே ஸ்தானாதிபதிகளை அனுப்பினாள்.
	<br /><br />
	17. அப்பொழுது பாபிலோன் புத்திரர் அவளண்டையிலே சிநேக சம்போகத்துக்கு வந்து, தங்கள் வேசித்தனங்களால் அவளைத் தீட்டுப்படுத்தினார்கள்; அவள் இவர்களால் தீட்டுப்பட்டுப் போனபின்பு, அவள் மனது அவர்களை விட்டுப் பிரிந்தது.
	<br /><br />
	18. இவ்விதமாய் அவள் தன் வேசித்தனங்களை வெளிப்படுத்தி, தன்னை நிர்வாணமாக்கினபோது, என் மனம் அவளுடைய சகோதரியை விட்டுப் பிரிந்ததுபோல அவளையும் விட்டுப் பிரிந்தது.
	<br /><br />
	19. அவள் எகிப்துதேசத்திலே வேசித்தனம் பண்ணின தன் வாலிபத்தின் நாட்களை நினைத்து, தன் வேசித்தனங்களில் அதிகரித்துப்போனாள்.
	<br /><br />
	20. கழுதைமாம்சமான மாம்சமும், குதிரை இந்திரியமான இந்திரியமுமுள்ள அவர்களுக்கு அவள் வைப்பாட்டியாயிருக்கும்படி அவர்கள்மேல் மோகித்தாள்.
	<br /><br />
	21. எகிப்தியரால் உன் கன்னிமையின் ஸ்தனங்களாகிய கொங்கைகள் தொடப்பட்ட காலத்தில், நீ உன் இளம்பிராயத்தில் செய்த முறைகேடுகளை நினைத்துவருகிறாய்.
	<br /><br />
	22. ஆகையால், அகோலிபாளே, கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, உன் மனது விட்டுப் பிரிந்த உன் சிநேகிதரை நான் உனக்கு விரோதமாக எழுப்பி, உனக்கு விரோதமாக அவர்களைச் சுற்றிலும் வரப்பண்ணுவேன்.
	<br /><br />
	23. சௌந்தரியமுள்ள வாலிபரும், தலைவரும், அதிபதிகளும், குதிரைகள்மேல் ஏறுகிற வீரருமாகிய பேர்பெற்ற பிரபுக்களான பாபிலோன் புத்திரரையும், கல்தேயர் எல்லாரையும், பேகோடு, சோவா, கோவா என்கிற தேசங்களின் மனுஷரையும் அவர்களோடேகூட அசீரிய புத்திரர் எல்லாரையும் வரப்பண்ணுவேன்.
	<br /><br />
	24. அவர்கள் வண்டில்களோடும், இரதங்களோடும், யந்திரங்களோடும், ஜனக்கூட்டத்தோடும், கேடகங்களும் பரிசைகளும் தலைச்சீராக்களும் தரித்தவர்களாய், உனக்கு விரோதமாக வந்து, உன்னைச் சுற்றிலும் பாளயமிறங்குவார்கள்; அவர்களுக்கு முன்னே நான் நியாயத்தை விளங்கப்பண்ணுவேன்; அவர்கள் தங்கள் நியாயங்களின்படி உன்னை நியாயந்தீர்ப்பார்கள்.
	<br /><br />
	25. உனக்கு விரோதமாக என் எரிச்சலை விளங்கப்பண்ணுவேன்; அவர்கள் உன்னை உக்கிரமாய் நடப்பித்து, உன் மூக்கையும் உன் காதுகளையும் அறுத்துப்போடுவார்கள்; உன்னில் மீதியாயிருப்பவர்கள் பட்டயத்தால் வெட்டுண்டுபோவார்கள்; அவர்கள் உன் குமாரரையும் உன் குமாரத்திகளையும் பிடித்துக்கொள்ளுவார்கள்; உன்னில் மீதியாயிருப்பவர்கள் அக்கினிக்கு இரையாவார்கள்.
	<br /><br />
	26. அவர்கள் உன் வஸ்திரங்களை உரிந்து, உன் சிங்காரமான ஆபரணங்களைப் பறித்துக்கொள்ளுவார்கள்.
	<br /><br />
	27. இவ்விதமாய் உன் முறைகேட்டையும், நீ எகிப்துதேசத்தில் துவக்கின உன் வேசித்தனத்தையும் ஒழியப்பண்ணுவேன்; நீ இனி அவர்களை நோக்க உன் கண்களை ஏறெடுக்காமலும், எகிப்துவை நினையாமலும் இருப்பாய்.
	<br /><br />
	28. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நீ பகைக்கிறவர்களின் கையிலும், உன் மனம் விட்டுப் பிரிந்தவர்களின் கையிலும் நான் உன்னை ஒப்புக்கொடுப்பேன்.
	<br /><br />
	29. அவர்கள் உன்னை வெறுப்பாய் நடத்தி, உன் பிரயாசத்தின் பலனையெல்லாம் எடுத்துக்கொண்டு, உன்னை அம்மணமும் நிர்வாணமுமாக்கிவிடுவார்கள்; அப்படியே உன் வெட்கக்கேடும் உன் முறைகேடுமான உன் வேசித்தனத்தின் நிர்வாணம் வெளிப்படுத்தப்படும்.
	<br /><br />
	30. நீ புறஜாதிகளைப் பின்தொடர்ந்து, அவர்களுடைய நரகலான விக்கிரகங்களால் உன்னைத் தீட்டுப்படுத்திக்கொண்ட உன் வேசித்தனத்தினிமித்தம் இவைகள் உனக்குச் செய்யப்படும்.
	<br /><br />
	31. உன் சகோதரியின் வழியிலே நீ நடந்தாய்; ஆகையால் அவளுடைய பாத்திரத்தை உன் கையிலே கொடுப்பேன்.
	<br /><br />
	32. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நீ உன் சகோதரியினுடைய ஆழமும் அகலமுமானதும் நிறைய வார்க்கப்பட்டதுமான பாத்திரத்தைக் குடித்து, நகைப்பும் பரியாசமுமாவாய்.
	<br /><br />
	33. சமாரியா என்னும் உன் சகோதரியினுடைய பாத்திரமாயிருக்கிற பிரமிப்பும் பாழ்க்கடிப்பும் என்கிற பாத்திரத்தால் வெறியினாலும் சஞ்சலத்தினாலும் நிறையப்படுவாய்.
	<br /><br />
	34. நீ அதில் இருக்கிறதைக் குடித்து, உறிஞ்சி, அதின் ஓடுகளை உடைத்துப்போட்டு, உன் ஸ்தனங்களைக் கீறிக்கொள்வாய்; நான் இதைச் சொன்னேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	35. ஆகையால், நீ என்னை மறந்து, என்னை உனக்குப் புறம்பே தள்ளிவிட்டதினிமித்தம், நீ உன் முறைகேட்டையும் உன் வேசித்தனங்களையும் சுமப்பாய் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	36. பின்னும் கர்த்தர் என்னை நோக்கி: மனுபுத்திரனே, நீ அகோலாளையும் அகோலிபாளையும் குறித்து வழக்காட மனதானால், அவர்களுடைய அருவருப்புகளை அவர்களுக்குத் தெரியக்காட்டு.
	<br /><br />
	37. அவர்கள் விபசாரம்பண்ணினார்கள்; அவர்கள் கைகளில் இரத்தமும் இருக்கிறது; அவர்கள் தங்கள் நரகலான விக்கிரகங்களோடே விபசாரம்பண்ணி, தாங்கள் எனக்குப் பெற்ற தங்கள் பிள்ளைகளையும் அவைகளுக்கு இரையாகத் தீக்கடக்கப்பண்ணினார்கள்.
	<br /><br />
	38. அன்றியும் அவர்கள் என் பரிசுத்த ஸ்தலத்தை அந்நாளிலேதானே தீட்டுப்படுத்தி, என் ஓய்வுநாட்களைப் பரிசுத்தக்குலைச்சலாக்கினார்கள்.
	<br /><br />
	39. அவர்கள் தங்கள் பிள்ளைகளைத் தங்கள் நரகலான விக்கிரகங்களுக்கென்று பலியிட்டபின்பு, அவர்கள் என் பரிசுத்த ஸ்தலத்தைப் பரிசுத்தக்குலைச்சலாக்க அந்நாளில்தானே அதற்குள் பிரவேசித்தார்கள்; இதோ, என் ஆலயத்தின் நடுவிலே இப்படிச் செய்தார்கள்.
	<br /><br />
	40. இதுவுமல்லாமல், தூரத்திலுள்ள புருஷரிடத்திற்குத் தூது அனுப்பி, அவர்களை வரவழைத்தார்கள், அவர்கள் வந்தார்கள்; அவர்களுக்கென்று நீ குளித்து, உன் கண்களில் மையிட்டுக்கொண்டு, ஆபரணங்களால் உன்னை அலங்கரித்து,
	<br /><br />
	41. சிறந்த மஞ்சத்தின்மேல் உட்கார்ந்துகொண்டாய்; அதின் முன்னே ஒரு பீடம் ஆயத்தம்பண்ணப்பட்டிருந்தது; என் தூபவர்க்கத்தையும் என் எண்ணெயையும் அதின்மேல் வைத்தாய்.
	<br /><br />
	42. அவளிடத்திலே அந்தச் சந்தடியின் இரைச்சல் அடங்கின பின்பு, ஜனத்திரளான புருஷரையும் அழைத்தனுப்பினார்கள்; சபேயர் வனாந்தரத்திலிருந்து கொண்டுவரப்பட்டார்கள்; இவர்கள் அவர்களுடைய கைகளில் கடகங்களையும் அவர்களுடைய தலைகளில் அலங்காரமான கிரீடங்களையும் போட்டார்கள்.
	<br /><br />
	43. விபசாரங்களில் கிழவியானவளைக்குறித்து அவள் இன்னும் தன் வேசித்தனங்களைச் செய்வாளோ என்றேன்.
	<br /><br />
	44. பரஸ்திரீயினிடத்திலே பிரவேசிக்குமாப்போல அவளிடத்தில் பிரவேசித்தார்கள்; இந்தப்பிரகாரமாக முறைகேடானவர்களாகிய அகோலாளிடத்திலும் அகோலிபாளிடத்திலும் பிரவேசித்தார்கள்.
	<br /><br />
	45. ஆகையால், விபசாரிகளை நியாயந்தீர்க்கிற பிரகாரமாகவும், இரத்தஞ்சிந்தும் ஸ்திரீகளை நியாயந்தீர்க்கிற பிரகாரமாகவும், நீதிமான்கள் அவர்களை நியாயந்தீர்ப்பார்கள்; அவர்கள் விபசாரிகள், அவர்களுடைய கைகளில் இரத்தம் இருக்கிறது.
	<br /><br />
	46. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நான் அவர்களுக்கு விரோதமாய் ஒரு கூட்டத்தைவரப்பண்ணி, அவர்களை அலைச்சலுக்கும் கொள்ளைக்கும் ஒப்புக்கொடுப்பேன்.
	<br /><br />
	47. அந்தக் கூட்டத்தார் அவர்களைக் கல்லெறிந்து, தங்கள் பட்டயங்களால் வெட்டிப்போடுவார்கள்; அவர்களுடைய குமாரரையும் அவர்களுடைய குமாரத்திகளையும் கொன்று, அவர்களுடைய வீடுகளை அக்கினியால் சுட்டெரிப்பார்கள்.
	<br /><br />
	48. இவ்விதமாய் எல்லா ஸ்திரீகளும் புத்தியடைந்து, உங்கள் முறைகேடுகளைச் செய்யாதிருக்கும்படி, முறைகேட்டைத் தேசத்தைவிட்டு ஒழியப்பண்ணுவேன்.
	<br /><br />
	49. உங்கள் முறைகேட்டை உங்கள்மேல் சுமத்துவார்கள்; அப்பொழுது நீங்கள் உங்கள் நரகலான விக்கிரகங்களைச் சேவித்த பாவங்களைச் சுமந்து, நான் கர்த்தராகிய ஆண்டவர் என்று அறிந்துகொள்வீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial23