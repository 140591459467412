import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoThessalonians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO THESSALONIANS 2 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE THESSALONIANS Chapter 2 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 2 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. அன்றியும், சகோதரரே, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் வருகையையும், நாம் அவரிடத்திலே சேர்க்கப்படுவதையுங்குறித்து, நாங்கள் உங்களை வேண்டிக்கொள்ளுகிறது என்னவென்றால்,
	<br /><br />
	2. ஒரு ஆவியினாலாவது, வார்த்தையினாலாவது, எங்களிடத்திலிருந்து வந்ததாய்த் தோன்றுகிற ஒரு நிருபத்தினாலாவது, கிறிஸ்துவினுடைய நாள் சமீபமாயிருக்கிறதாகச் சொல்லப்பட்டால், உடனே சஞ்சலப்படாமலும் கலங்காமலும் இருங்கள்.
	<br /><br />
	3. எவ்விதத்தினாலும் ஒருவனும் உங்களை மோசம்போக்காதபடிக்கு எச்சரிக்கையாயிருங்கள்; ஏனெனில் விசுவாச துரோகம் முந்தி நேரிட்டு, கேட்டின் மகனாகிய பாவமனுஷன் வெளிப்பட்டாலொழிய, அந்த நாள் வராது.
	<br /><br />
	4. அவன் எதிர்த்துநிற்கிறவனாயும், தேவனென்னப்படுவதெதுவோ, ஆராதிக்கப்படுவதெதுவோ, அவையெல்லாவற்றிற்கும் மேலாகத் தன்னை உயர்த்துகிறவனாயும், தேவனுடைய ஆலயத்தில் தேவன்போல உட்கார்ந்து, தன்னைத்தான் தேவனென்று காண்பிக்கிறவனாயும் இருப்பான்.
	<br /><br />
	5. நான் உங்களிடத்திலிருந்தபோது இவைகளைச் சொன்னது உங்களுக்கு ஞாபகமில்லையா?
	<br /><br />
	6. அவன் தன் காலத்திலே வெளிப்படும்படிக்கு, இப்பொழுது அவனைத் தடைசெய்கிறது இன்னதென்றும் அறிந்திருக்கிறீர்களே.
	<br /><br />
	7. அக்கிரமத்தின் இரகசியம் இப்பொழுதே கிரியைசெய்கிறது; ஆனாலும் தடைசெய்கிறவன் நடுவிலிருந்து நீக்கப்படுமுன்னே அது வெளிப்படாது.
	<br /><br />
	8. நீக்கப்படும்போது, அந்த அக்கிரமக்காரன் வெளிப்படுவான்; அவனைக் கர்த்தர் தம்முடைய வாயின் சுவாசத்தினாலே அழித்து, தம்முடைய வருகையின் பிரசன்னத்தினாலே நாசம்பண்ணுவார்.
	<br /><br />
	9. அந்த அக்கிரமக்காரனுடைய வருகை சாத்தானுடைய செயலின்படி சகல வல்லமையோடும் அடையாளங்களோடும் பொய்யான அற்புதங்களோடும்,
	<br /><br />
	10. கெட்டுப்போகிறவர்களுக்குள்ளே அநீதியினால் உண்டாகும் சகலவித வஞ்சகத்தோடும் இருக்கும். இரட்சிக்கப்படத்தக்கதாய்ச் சத்தியத்தின்மேலுள்ள அன்பை அவர்கள் அங்கிகரியாமற்போனபடியால் அப்படி நடக்கும்.
	<br /><br />
	11. ஆகையால் சத்தியத்தை விசுவாசியாமல் அநீதியில் பிரியப்படுகிற யாவரும் ஆக்கினைக்குள்ளாக்கப்படும்படிக்கு,
	<br /><br />
	12. அவர்கள் பொய்யை விசுவாசிக்கத்தக்கதாகக் கொடிய வஞ்சகத்தைத் தேவன் அவர்களுக்கு அனுப்புவார்.
	<br /><br />
	13. கர்த்தருக்குப் பிரியமான சகோதரரே, நீங்கள் ஆவியினாலே பரிசுத்தமாக்கப்படுகிறதினாலும், சத்தியத்தை விசுவாசிக்கிறதினாலும் இரட்சிப்படையும்படிக்கு, ஆதிமுதல் தேவன் உங்களைத் தெரிந்துகொண்டபடியினாலே, நாங்கள் உங்களைக்குறித்து எப்பொழுதும் தேவனை ஸ்தோத்திரிக்கக் கடனாளிகளாயிருக்கிறோம்.
	<br /><br />
	14. நீங்கள் நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் மகிமையை அடையும்பொருட்டாக எங்கள் சுவிசேஷத்தினாலே அந்த இரட்சிப்புக்கு அவர் உங்களை அழைத்தார்.
	<br /><br />
	15. ஆகையால், சகோதரரே, நீங்கள் நிலைகொண்டு, வார்த்தையினாலாவது நிருபத்தினாலாவது நாங்கள் உங்களுக்கு உபதேசித்த முறைமைகளைக் கைக்கொள்ளுங்கள்.
	<br /><br />
	16. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவும், நம்மிடத்தில் அன்புகூர்ந்து நித்திய ஆறுதலையும் நல்நம்பிக்கையையும் கிருபையாய் நமக்குக் கொடுத்திருக்கிற நம்முடைய பிதாவாகிய தேவனும்,
	<br /><br />
	17. உங்கள் இருதயங்களைத் தேற்றி, எல்லா நல்வசனத்திலும் நற்கிரியையிலும் உங்களை ஸ்திரப்படுத்துவாராக.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoThessalonians2