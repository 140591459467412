import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 10 </title>
	<meta
          name="description"
          content="Hosea 10 | ஓசியா 10 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேல் பலனற்ற திராட்சச்செடி, அது தனக்குத்தானே கனிகொடுக்கிறது; அவன் தன் கனியின் திரளுக்குச் சரியாய்ப் பலிபீடங்களைத் திரளாக்குகிறான்; தங்கள் தேசத்தின் செழிப்புக்குச் சரியாய்ச் சிறப்பான சிலைகளைச் செய்கிறார்கள்.
	<br /><br />
	2. அவர்கள் இருதயம் பிரிக்கப்பட்டிருக்கிறது; இப்போதும் குற்றஞ்சுமத்தப்படுவார்கள்; அவர்கள் பலிபீடங்களை இடித்து, அவர்கள் சிலைகளை நாசமாக்குவார்.
	<br /><br />
	3. நாம் கர்த்தருக்குப் பயப்படாமற்போனபடியினால் நமக்கு ராஜா இல்லை; ராஜா இருந்தாலும் நமக்காக என்ன செய்வான் என்று இனிச் சொல்லுவார்கள்.
	<br /><br />
	4. பொய்யாணையிடுகிற வார்த்தைகளைச் சொல்லி, உடன்படிக்கை பண்ணிக்கொண்டார்கள்; ஆகையால் வயல்வெளியின் படைச்சால்களில் விஷப்பூண்டுகளைப்போல நியாயத்தீர்ப்பு முளைக்கும்.
	<br /><br />
	5. சமாரியாவின் குடிகள் பெத்தாவேனிலுள்ள கன்றுக்குட்டியினிமித்தம் பயம் அடைவார்கள்; அதற்காகக் களிகூர்ந்த அதின் ஜனமும், அதின் பூசாசாரிகளும் அதின் மகிமை அதைவிட்டு நீங்கிப்போயிற்றென்று அதற்காகத் துக்கங்கொண்டாடுவார்கள்.
	<br /><br />
	6. அதுவும் அசீரியாவிலே யாரேப் ராஜாவுக்குக் காணிக்கையாகக் கொண்டுபோகப்படும்; எப்பிராயீம் இலச்சையடைவான்; இஸ்ரவேல் தன் ஆலோசனையினாலே வெட்கப்படுவான்.
	<br /><br />
	7. சமாரியாவின் ராஜா தண்ணீரின்மேல் இருக்கிற நுரையைப்போல் அழிந்துபோவான்.
	<br /><br />
	8. இஸ்ரவேலுடைய பாவமாகிய ஆபேனின் மேடைகள் அழிக்கப்படும்; முட்செடிகளும் முட்பூண்டுகளும் அவர்கள் பலிபீடங்களின்மேல் முளைக்கும்; பர்வதங்களைப்பார்த்து எங்களை மூடுங்கள் என்றும், குன்றுகளைப்பார்த்து எங்கள்மேல் விழுங்கள் என்றும் சொல்லுவார்கள்.
	<br /><br />
	9. இஸ்ரவேலே, நீ கிபியாவின் நாட்கள்முதல் பாவஞ்செய்துவந்தாய்; கிபியாவிலே அக்கிரமக்காரர்மேல் வந்த யுத்தம் தங்களைக் கிட்டுவதில்லையென்று அந்த நிலையிலே நிற்கிறார்கள்.
	<br /><br />
	10. நான் அவர்களைத் தண்டிக்க விரும்புகிறேன்; அவர்கள் செய்த இரண்டுவித பாவங்களினிமித்தம் கட்டப்படும்போது, ஜனங்கள் அவர்களுக்கு விரோதமாய்க் கூடுவார்கள்.
	<br /><br />
	11. எப்பிராயீம் நன்றாய்ப் பழக்கப்பட்டுப் போரடிக்க விரும்புகிற கடாரியாயிருக்கிறான்; ஆனாலும் நான் அவன் அழகான கழுத்தின்மேல் நுகத்தடியை வைப்பேன்; எப்பிராயீமை ஏர் பூட்டி ஓட்டுவேன்; யூதா உழுவான், யாக்கோபு அவனுக்குப் பரம்படிப்பான்.
	<br /><br />
	12. நீங்கள் நீதிக்கென்று விதைவிதையுங்கள்; தயவுக்கொத்ததாய் அறுப்பு அறுங்கள்; உங்கள் தரிசுநிலத்தைப் பண்படுத்துங்கள்; கர்த்தர் வந்து உங்கள்மேல் நீதியை வருஷிக்கப்பண்ணுமட்டும், அவரைத் தேடக் காலமாயிருக்கிறது.
	<br /><br />
	13. அநியாயத்தை உழுதீர்கள், தீவினையை அறுத்தீர்கள்; பொய்யின் கனிகளைப் புசித்தீர்கள்; உங்கள் வழியையும் உங்கள் பராக்கிரமசாலிகளின் திரளையும் நம்பினீர்கள்.
	<br /><br />
	14. ஆகையால் உங்கள் ஜனங்களுக்குள் அமளி எழும்பும்; பிள்ளைகளின்மேல் தாய் மோதியடிக்கப்பட்ட யுத்தநாளிலே பெத்தார்பேலைச் சல்மான் அழித்ததுபோல, உங்கள் எல்லா அரண்களும் அழிக்கப்படும்.
	<br /><br />
	15. உங்கள் கொடிய பொல்லாப்பினால் பெத்தேல் இப்படிப்பட்டதை உங்களுக்குச் செய்யும்; அதிகாலமே இஸ்ரவேலின் ராஜா முற்றிலும் சங்கரிக்கப்படுவான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea10