import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const James1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> Perseverance Through Trials James Tamil Bible | யாக்கோபு -1 </title>
	<meta
          name="description"
          content="Perseverance Through Trials James Chapter - 1 in Tamil Bible Verse | யாக்கோபு அதிகாரம்-1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* சோதனைகள் மூலம் விடாமுயற்சி *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. தேவனுக்கும் கர்த்தராகிய இயேசுகிறிஸ்துவுக்கும் ஊழியக்காரனாகிய யாக்கோபு, சிதறியிருக்கிற பன்னிரண்டு கோத்திரங்களுக்கும் வாழ்த்துதல் சொல்லி எழுதுகிறதாவது:
	<br /><br />
	2. என் சகோதரரே, நீங்கள் பலவிதமான சோதனைகளில் அகப்படும்போது,
	<br /><br />
	3. உங்கள் விசுவாசத்தின் பரீட்சையானது பொறுமையை உண்டாக்குமென்று அறிந்து, அதை மிகுந்த சந்தோஷமாக எண்ணுங்கள்.
	<br /><br />
	4. நீங்கள் ஒன்றிலும் குறைவுள்ளவர்களாயிராமல், பூரணராயும் நிறைவுள்ளவர்களாயும் இருக்கும்படி, பொறுமையானது பூரண கிரியை செய்யக்கடவது.
	<br /><br />
	5. உங்களில் ஒருவன் ஞானத்தில் குறைவுள்ளவனாயிருந்தால், யாவருக்கும் சம்பூரணமாய்க் கொடுக்கிறவரும் ஒருவரையும் கடிந்துகொள்ளாதவருமாகிய தேவனிடத்தில் கேட்கக்கடவன், அப்பொழுது அவனுக்குக் கொடுக்கப்படும்.
	<br /><br />
	6. ஆனாலும் அவன் எவ்வளவாகிலும் சந்தேகப்படாமல் விசுவாசத்தோடே கேட்கக்கடவன்; சந்தேகப்படுகிறவன் காற்றினால் அடிபட்டு அலைகிற கடலின் அலைக்கு ஒப்பாயிருக்கிறான்.
	<br /><br />
	7. அப்படிப்பட்ட மனுஷன் தான் கர்த்தரிடத்தில் எதையாகிலும் பெறலாமென்று நினையாதிருப்பானாக.
	<br /><br />
	8. இருமனமுள்ளவன் தன் வழிகளிலெல்லாம் நிலையற்றவனாயிருக்கிறான்.
	<br /><br />
	9. தாழ்ந்த சகோதரன் தான் உயர்த்தப்பட்டதைக்குறித்து மேன்மைபாராட்டக்கடவன்.
	<br /><br />
	10. ஐசுவரியவான் தான் தாழ்த்தப்பட்டதைக்குறித்து மேன்மைபாராட்டக்கடவன்; ஏனெனில் அவன் புல்லின் பூவைப்போல் ஒழிந்துபோவான்.
	<br /><br />
	11. சூரியன் கடும் வெய்யிலுடன் உதித்து, புல்லை உலர்த்தும்போது, அதின் பூ உதிர்ந்து, அதின் அழகான வடிவு அழிந்துபோம்; ஐசுவரியவானும் அப்படியே தன் வழிகளில் வாடிப்போவான்.
	<br /><br />
	12. சோதனையைச் சகிக்கிற மனுஷன் பாக்கியவான்; அவன் உத்தமனென்று விளங்கினபின்பு கர்த்தர் தம்மிடத்தில் அன்புகூருகிறவர்களுக்கு வாக்குத்தத்தம் பண்ணின ஜீவகிரீடத்தைப் பெறுவான்.
	<br /><br />
	13. சோதிக்கப்படுகிற எவனும், நான் தேவனால் சோதிக்கப்படுகிறேன் என்று சொல்லாதிருப்பானாக; தேவன் பொல்லாங்கினால் சோதிக்கப்படுகிறவரல்ல, ஒருவனையும் அவர் சோதிக்கிறவருமல்ல.
	<br /><br />
	14. அவனவன் தன்தன் சுய இச்சையினாலே இழுக்கப்பட்டு, சிக்குண்டு, சோதிக்கப்படுகிறான்.
	<br /><br />
	15. பின்பு இச்சையானது கர்ப்பந்தரித்து, பாவத்தைப் பிறப்பிக்கும், பாவம் பூரணமாகும்போது, மரணத்தைப் பிறப்பிக்கும்.
	<br /><br />
	16. என் பிரியமான சகோதரரே, மோசம்போகாதிருங்கள்.
	<br /><br />
	17. நன்மையான எந்த ஈவும் பூரணமான எந்த வரமும் பரத்திலிருந்துண்டாகி, சோதிகளின் பிதாவினிடத்திலிருந்து இறங்கிவருகிறது; அவரிடத்தில் யாதொரு மாறுதலும் யாதொரு வேற்றுமையின் நிழலுமில்லை.
	<br /><br />
	18. அவர் சித்தங்கொண்டு தம்முடைய சிருஷ்டிகளில் நாம் முதற்பலன்களாவதற்கு நம்மைச் சத்திய வசனத்தினாலே ஜெநிப்பித்தார்.
	<br /><br />
	19. ஆகையால், என் பிரியமான சகோதரரே, யாவரும் கேட்கிறதற்குத் தீவிரமாயும், பேசுகிறதற்குப் பொறுமையாயும், கோபிக்கிறதற்குத் தாமதமாயும் இருக்கக்கடவர்கள்;
	<br /><br />
	20. மனுஷருடைய கோபம் தேவனுடைய நீதியை நடப்பிக்கமாட்டாதே.
	<br /><br />
	21. ஆகையால், நீங்கள் எல்லாவித அழுக்கையும் கொடிய துர்க்குணத்தையும் ஒழித்துவிட்டு, உங்கள் உள்ளத்தில் நாட்டப்பட்டதாயும் உங்கள் ஆத்துமாக்களை இரட்சிக்க வல்லமையுள்ளதாயுமிருக்கிற வசனத்தைச் சாந்தமாய் ஏற்றுக்கொள்ளுங்கள்.
	<br /><br />
	22. அல்லாமலும், நீங்கள் உங்களை வஞ்சியாதபடிக்குத் திருவசனத்தைக் கேட்கிறவர்களாய் மாத்திரமல்ல, அதின்படி செய்கிறவர்களாயும் இருங்கள்.
	<br /><br />
	23. என்னத்தினாலெனில், ஒருவன் திருவசனத்தைக் கேட்டும் அதின்படி செய்யாதவனானால், கண்ணாடியிலே தன் சுபாவ முகத்தைப் பார்க்கிற மனுஷனுக்கு ஒப்பாயிருப்பான்;
	<br /><br />
	24. அவன் தன்னைத்தானே பார்த்து, அவ்விடம்விட்டுப் போனவுடனே, தன் சாயல் இன்னதென்பதை மறந்துவிடுவான்.
	<br /><br />
	25. சுயாதீனப்பிரமாணமாகிய பூரணப்பிரமாணத்தை உற்றுப்பார்த்து, அதிலே நிலைத்திருக்கிறவனே கேட்கிறதை மறக்கிறவனாயிராமல், அதற்கேற்ற கிரியை செய்கிறவனாயிருந்து, தன் செய்கையில் பாக்கியவானாயிருப்பான்.
	<br /><br />
	26. உங்களில் ஒருவன் தன் நாவை அடக்காமல், தன் இருதயத்தை வஞ்சித்து, தன்னைத் தேவபக்தியுள்ளவனென்று எண்ணினால் அவனுடைய தேவபக்தி வீணாயிருக்கும்.
	<br /><br />
	27. திக்கற்ற பிள்ளைகளும் விதவைகளும் படுகிற உபத்திரவத்திலே அவர்களை விசாரிக்கிறதும், உலகத்தால் கறைபடாதபடிக்குத் தன்னைக் காத்துக்கொள்ளுகிறதுமே பிதாவாகிய தேவனுக்குமுன்பாக மாசில்லாத சுத்தமான பக்தியாயிருக்கிறது.
</p>



      {/* End of verse */} 

	  <br /><br />
<h3>
* யாக்கோபு 1, சோதனைகளுக்கு மத்தியில் மகிழ்ச்சியாக இருக்க வாழ்த்து மற்றும் ஊக்கத்துடன் துவங்குகிறது, ஏனெனில் இந்த வகையான சோதனையின் விளைவு விடாமுயற்சி மற்றும் முதிர்ந்த மற்றும் முழுமையான நம்பிக்கை. இந்த செயல்முறைக்கு கடவுள் உண்மையுள்ளவர் என்றும், நாம் அதை சகித்துக்கொள்ள ஞானத்தை வழங்குவார் என்றும் யாக்கோபு ஊக்குவிக்கிறார். கடவுள் நம்மைச் சோதிப்பதில்லை என்று எச்சரிக்கிறார், மாறாக சோதனையானது நம்முடைய சொந்த தீய ஆசைகளிலிருந்து வருகிறது. பின்னர் யாக்கோபு கடவுளுடைய வார்த்தையைக் கேட்பது மற்றும் அதன்படி செயல்படுவது பற்றி அறிவுறுத்துகிறார். அது என்ன சொல்கிறது என்பதை அறிந்துகொள்வது நாம் உண்மையில் யார் என்பதை நமக்குக் காட்டுகிறது மற்றும் அதை வாழ்வது சுதந்திரத்தை அளித்து நம்மை ஆசீர்வதிக்கும் என்பதை அவர் காட்டுகிறார். *
			</h3>
<br /><br />
      </div>
    </div>
    </>
  );
}

export default James1