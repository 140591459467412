import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 9 </title>
	<meta
          name="description"
          content="Isaiah 9 | ஏசாயா 9 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆகிலும் அவர் செபுலோன் நாட்டையும், நப்தலி நாட்டையும் இடுக்கமாய் ஈனப்படுத்தின முந்தின காலத்திலிருந்ததுபோல அது இருண்டிருப்பதில்லை; ஏனென்றால் அவர் கடற்கரையருகிலும், யோர்தான் நதியோரத்திலுமுள்ள புறஜாதியாருடைய கலிலேயாவாகிய அத்தேசத்தைப் பிற்காலத்திலே மகிமைப்படுத்துவார்.
	<br /><br />
	2. இருளில் நடக்கிற ஜனங்கள் பெரிய வெளிச்சத்தைக் கண்டார்கள்; மரண இருளின் தேசத்தில் குடியிருக்கிறவர்களின்மேல் வெளிச்சம் பிரகாசித்தது.
	<br /><br />
	3. அந்த ஜாதியைத் திரளாக்கி, அதற்கு மகிழ்ச்சியைப் பெருகப்பண்ணினீர்; அறுப்பில் மகிழ்கிறதுபோலவும், கொள்ளையைப் பங்கிட்டுக்கொள்ளுகையில் களிகூருகிறதுபோலவும், உமக்கு முன்பாக மகிழுகிறார்கள்.
	<br /><br />
	4. மீதியானியரின் நாளில் நடந்ததுபோல, அவர்கள் சுமந்த நுகத்தடியையும், அவர்கள் தோளின்மேலிருந்த மிலாற்றையும், அவர்கள் ஆளோட்டியின் கோலையும் முறித்துப்போட்டீர்.
	<br /><br />
	5. அமளியாய் யுத்தம்பண்ணுகிற வீரருடைய ஆயுதவர்க்கங்களும், இரத்தத்தில் புரண்ட உடுப்பும் அக்கினிக்கு இரையாகச் சுட்டெரிக்கப்படும்.
	<br /><br />
	6. நமக்கு ஒரு பாலகன் பிறந்தார்; நமக்கு ஒரு குமாரன் கொடுக்கப்பட்டார்; கர்த்தத்துவம் அவர் தோளின்மேலிருக்கும்; அவர் நாமம் அதிசயமானவர், ஆலோசனைக்கர்த்தா, வல்லமையுள்ள தேவன், நித்திய பிதா, சமாதானப்பிரபு என்னப்படும்.
	<br /><br />
	7. தாவீதின் சிங்காசனத்தையும் அவனுடைய ராஜ்யத்தையும் அவர் திடப்படுத்தி, அதை இதுமுதற்கொண்டு என்றென்றைக்கும் நியாயத்தினாலும் நீதியினாலும் நிலைப்படுத்தும்படிக்கு, அவருடைய கர்த்தத்துவத்தின் பெருக்கத்துக்கும், அதின் சமாதானத்துக்கும் முடிவில்லை; சேனைகளின் கர்த்தருடைய வைராக்கியம் இதைச் செய்யும்.
	<br /><br />
	8. ஆண்டவர் யாக்கோபுக்கு ஒரு வார்த்தையை அனுப்பினார்; அது இஸ்ரவேலின்மேல் இறங்கிற்று.
	<br /><br />
	9. செங்கல்கட்டு இடிந்துபோயிற்று, பொளிந்த கல்லாலே திரும்பக் கட்டுவோம்; காட்டத்திமரங்கள் வெட்டிப்போடப்பட்டது, அவைகளுக்குப் பதிலாகக் கேதுருமரங்களை வைப்போம் என்று,
	<br /><br />
	10. அகந்தையும், மனப்பெருமையுமாய்ச் சொல்லுகிற எப்பிராயீமரும், சமாரியாவின் குடிகளுமாகிய எல்லா ஜனத்துக்கும் அது தெரியவரும்.
	<br /><br />
	11. ஆதலால் கர்த்தர் ரேத்சீனுடைய சத்துருக்களை அவர்கள்மேல் உயர்த்தி, அவர்களுடைய மற்றச் சத்துருக்களை அவர்களோடே கூட்டிக் கலப்பார்.
	<br /><br />
	12. முற்புறத்தில் சீரியரும், பிற்புறத்தில் பெலிஸ்தரும் வந்து, இஸ்ரவேலைத் திறந்தவாயால் பட்சிப்பார்கள்; இவையெல்லாவற்றிலும் அவருடைய கோபம் ஆறாமல், இன்னும் அவருடைய கை நீட்டினபடியே இருக்கிறது.
	<br /><br />
	13. ஜனங்கள் தங்களை அடிக்கிறவரிடத்தில் திரும்பாமலும், சேனைகளின் கர்த்தரைத் தேடாமலும் இருக்கிறார்கள்.
	<br /><br />
	14. ஆகையால் கர்த்தர் இஸ்ரவேலிலே தலையையும், வாலையும், கிளையையும், நாணலையும், ஒரேநாளிலே வெட்டிப்போடுவார்.
	<br /><br />
	15. மூப்பனும் கனம்பொருந்தினவனுமே தலை, பொய்ப்போதகம்பண்ணுகிற தீர்க்கதரிசியே வால்.
	<br /><br />
	16. இந்த ஜனத்தை நடத்துகிறவர்கள் எத்தருமாய், அவர்களால் நடத்தப்படுகிறவர்கள் நாசமடைகிறவர்களுமாய் இருக்கிறார்கள்.
	<br /><br />
	17. ஆதலால், ஆண்டவர் அவர்கள் வாலிபர்மேல் பிரியமாயிருப்பதில்லை; அவர்களிலிருக்கிற திக்கற்ற பிள்ளைகள்மேலும் விதவைகள்மேலும் இரங்குவதுமில்லை; அவர்கள் அனைவரும் மாயக்காரரும் பொல்லாதவர்களுமாயிருக்கிறார்கள்; எல்லா வாயும் ஆகாமியம் பேசும்; இவையெல்லாவற்றிலும் அவருடைய கோபம் ஆறாமல், இன்னும் அவருடைய கை நீட்டினபடியே இருக்கிறது.
	<br /><br />
	18. ஆகாமியமானது அக்கினியைப்போல எரிகிறது; அது முட்செடியையும் நெரிஞ்சிலையும் பட்சிக்கும், அது நெருங்கிய காட்டைக் கொளுத்தும், புகை திரண்டு எழும்பும்.
	<br /><br />
	19. சேனைகளின் கர்த்தருடைய சினத்தால் தேசம் அந்தகாரப்பட்டு, ஜனங்கள் அக்கினிக்கு இரையாவார்கள்; ஒருவனும் தன் சகோதரனைத் தப்பவிடான்.
	<br /><br />
	20. வலதுபுறத்தில் பட்சித்தாலும் பசித்திருப்பார்கள்; இடதுபுறத்தில் தின்றாலும் திருப்தியடையார்கள்; அவனவன் தன்தன் புயத்தின் மாம்சத்தைத் தின்பான்.
	<br /><br />
	21. மனாசே எப்பிராயீமையும், எப்பிராயீம் மனாசேயையும் பட்சிப்பார்கள்; இவர்கள் ஏகமாய் யூதாவுக்கு விரோதமாயிருப்பார்கள்; இவையெல்லாவற்றிலும் அவருடைய கோபம் ஆறாமல், இன்னும் அவருடைய கை நீட்டினபடியே இருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah9