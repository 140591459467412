import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 11 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 11 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 11 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. என் புத்தியீனத்தை நீங்கள் சற்றே சகித்தால் நலமாயிருக்கும்; என்னைச் சகித்துமிருக்கிறீர்களே.
	<br /><br />
	2. நான் உங்களைக் கற்புள்ள கன்னிகையாகக் கிறிஸ்து என்னும் ஒரே புருஷனுக்கு ஒப்புக்கொடுக்க நியமித்தபடியால், உங்களுக்காகத் தேவவைராக்கியமான வைராக்கியங்கொண்டிருக்கிறேன்.
	<br /><br />
	3. ஆகிலும், சர்ப்பமானது தன்னுடைய தந்திரத்தினாலே ஏவாளை வஞ்சித்ததுபோல, உங்கள் மனதும் கிறிஸ்துவைப்பற்றிய உண்மையினின்று விலகும்படி கெடுக்கப்படுமோவென்று பயந்திருக்கிறேன்.
	<br /><br />
	4. எப்படியெனில், உங்களிடத்தில் வருகிறவன் நாங்கள் பிரசங்கியாத வேறொரு இயேசுவைப் பிரசங்கித்தானானால், அல்லது நீங்கள் பெற்றிராத வேறொரு ஆவியையும், நீங்கள் ஏற்றுக்கொள்ளாத வேறொரு சுவிசேஷத்தையும் பெற்றீர்களானால், நன்றாய்ச் சகித்திருப்பீர்களே.
	<br /><br />
	5. மகா பிரதான அப்போஸ்தலரிலும், நான் ஒன்றிலும் குறைவுள்ளவனல்லவென்று எண்ணுகிறேன்.
	<br /><br />
	6. நான் பேச்சிலே கல்லாதவனாயிருந்தாலும், அறிவிலே கல்லாதவனல்ல; எந்த விஷயத்திலும் எல்லாருக்குமுன்பாகவும் உங்களுக்குள்ளே நாங்கள் வெளிப்பட்டிருக்கிறோமே.
	<br /><br />
	7. நீங்கள் உயர்த்தப்படும்படி நான் என்னைத்தானே தாழ்த்தி, தேவனுடைய சுவிசேஷத்தை இலவசமாய் உங்களுக்குப் பிரசங்கித்ததினாலே குற்றஞ்செய்தேனோ?
	<br /><br />
	8. உங்களுக்கு ஊழியஞ்செய்யும்படிக்கு, மற்றச் சபைகளிடத்தில் சம்பளத்தைப் பெற்று, அவர்களைக் கொள்ளையிட்டேன்.
	<br /><br />
	9. நான் உங்களோடிருந்து குறைவுபட்டபோதும், ஒருவரையும் நான் வருத்தப்படுத்தவில்லை; மக்கெதோனியாவிலிருந்து வந்த சகோதரர் என் குறைவை நிறைவாக்கினார்கள்; எவ்விதத்திலேயும் உங்களுக்குப் பாரமாயிராதபடிக்கு ஜாக்கிரதையாயிருந்தேன், இனிமேலும் ஜாக்கிரதையாயிருப்பேன்.
	<br /><br />
	10. அகாயாநாட்டின் திசைகளிலே இந்தப் புகழ்ச்சி என்னைவிட்டு நீங்குவதில்லையென்று என்னிலுள்ள கிறிஸ்துவினுடைய சத்தியத்தைக்கொண்டு சொல்லுகிறேன்.
	<br /><br />
	11. இப்படிச் சொல்லவேண்டியதென்ன? நான் உங்களைச் சிநேகியாதபடியினாலேயோ? தேவன் அறிவார்.
	<br /><br />
	12. மேலும், எங்களை விரோதிக்கச் சமயந்தேடுகிறவர்களுக்குச் சமயம் கிடையாதபடிக்கு, தங்களைக்குறித்து மேன்மைபாராட்டுகிற காரியத்தில் அவர்கள் எங்களைப்போலக் காணப்படும்படி, நான் செய்வதையே இன்னும் செய்வேன்.
	<br /><br />
	13. அப்படிப்பட்டவர்கள் கள்ள அப்போஸ்தலர்கள், கபடமுள்ள வேலையாட்கள், கிறிஸ்துவினுடைய அப்போஸ்தலரின் வேஷத்தைத் தரித்துக்கொண்டவர்களாயிருக்கிறார்கள்.
	<br /><br />
	14. அது ஆச்சரியமல்ல, சாத்தானும் ஒளியின் தூதனுடைய வேஷத்தைத் தரித்துக்கொள்வானே.
	<br /><br />
	15. ஆகையால் அவனுடைய ஊழியக்காரரும் நீதியின் ஊழியக்காரருடைய வேஷத்தைத் தரித்துக்கொண்டால் அது ஆச்சரியமல்லவே; அவர்கள் முடிவு அவர்கள் கிரியைகளுக்குத்தக்கதாயிருக்கும்.
	<br /><br />
	16. பின்னும் நான் சொல்லுகிறேன்; ஒருவனும் என்னைப் புத்தியீனனென்று எண்ணவேண்டாம்; அப்படி எண்ணினால், நானும் சற்றே மேன்மைபாராட்டும்படி, என்னைப் புத்தியீனனைப்போலாகிலும் ஏற்றுக்கொள்ளுங்கள்.
	<br /><br />
	17. இப்படி நான் சொல்லுகிறது கர்த்தருக்கேற்றபடி சொல்லாமல், மேன்மைபாராட்டும் தைரியத்தினாலே புத்தியீனனைப்போலச் சொல்லுகிறேன்.
	<br /><br />
	18. அநேகர் மாம்சத்திற்கேற்றபடி மேன்மைபாராட்டிக்கொள்ளுகையில், நானும் மேன்மைபாராட்டுவேன்.
	<br /><br />
	19. நீங்கள் புத்தியுள்ளவர்களாயிருந்து புத்தியில்லாதவர்களைச் சந்தோஷமாய்ச் சகித்திருக்கிறீர்களே.
	<br /><br />
	20. ஒருவன் உங்களைச் சிறையாக்கினாலும், ஒருவன் உங்களைப் பட்சித்தாலும், ஒருவன் உங்களைக் கைவசப்படுத்தினாலும், ஒருவன் தன்னை உயர்த்தினாலும், ஒருவன் உங்களை முகத்தில் அறைந்தாலும் சகித்திருக்கிறீர்களே.
	<br /><br />
	21. நாங்கள் பலவீனரானதுபோல, எங்களுக்கு வந்த கனவீனத்தைக்குறித்துப் பேசுகிறேன்; ஒருவன் எதிலே துணிவுள்ளவனாயிருக்கிறானோ அதிலே நானும் துணிவுள்ளவனாயிருக்கிறேன்; இப்படிப் புத்தியீனமாய்ப் பேசுகிறேன்.
	<br /><br />
	22. அவர்கள் எபிரெயரா? நானும் எபிரெயன்; அவர்கள் இஸ்ரவேலரா? நானும் இஸ்ரவேலன்; அவர்கள் ஆபிரகாமின் சந்ததியாரா? நானும் ஆபிரகாமின் சந்ததியான்.
	<br /><br />
	23. அவர்கள் கிறிஸ்துவின் ஊழியக்காரரா? நான் அதிகம்; புத்தியீனமாய்ப் பேசுகிறேன்; நான் அதிகமாய்ப் பிரயாசப்பட்டவன், அதிகமாய் அடிபட்டவன், அதிகமாய்க் காவல்களில் வைக்கப்பட்டவன், அநேகந்தரம் மரண அவதியில் அகப்பட்டவன்.
	<br /><br />
	24. யூதர்களால் ஒன்றுகுறைய நாற்பதடியாக ஐந்துதரம் அடிபட்டேன்;
	<br /><br />
	25. மூன்றுதரம் மிலாறுகளால் அடிபட்டேன், ஒருதரம் கல்லெறியுண்டேன், மூன்றுதரம் கப்பற்சேதத்தில் இருந்தேன், கடலிலே ஒரு இராப்பகல் முழுவதும் போக்கினேன்.
	<br /><br />
	26. அநேகந்தரம் பிரயாணம்பண்ணினேன்; ஆறுகளால் வந்த மோசங்களிலும், கள்ளரால் வந்த மோசங்களிலும், என் சுயஜனங்களால் வந்த மோசங்களிலும், அந்நிய ஜனங்களால் வந்த மோசங்களிலும், பட்டணங்களில் உண்டான மோசங்களிலும், வனாந்தரத்தில் உண்டான மோசங்களிலும், சமுத்திரத்தில் உண்டான மோசங்களிலும், கள்ளச்சகோதரரிடத்தில் உண்டான மோசங்களிலும்;
	<br /><br />
	27. பிரயாசத்திலும், வருத்தத்திலும், அநேகமுறை கண்விழிப்புகளிலும், பசியிலும் தாகத்திலும், அநேகமுறை உபவாசங்களிலும், குளிரிலும், நிர்வாணத்திலும் இருந்தேன்.
	<br /><br />
	28. இவை முதலானவைகளையல்லாமல், எல்லாச் சபைகளைக்குறித்தும் உண்டாயிருக்கிற கவலை என்னை நாள்தோறும் நெருக்குகிறது.
	<br /><br />
	29. ஒருவன் பலவீனனானால் நானும் பலவீனனாகிறதில்லையோ? ஒருவன் இடறினால் என் மனம் எரியாதிருக்குமோ?
	<br /><br />
	30. நான் மேன்மைபாராட்டவேண்டுமானால், என் பலவீனத்திற்கடுத்தவைகளைக் குறித்து மேன்மைபாராட்டுவேன்.
	<br /><br />
	31. என்றென்றைக்கும் ஸ்தோத்திரிக்கப்பட்ட தேவனும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் பிதாவுமானவர் நான் பொய் சொல்லுகிறதில்லையென்று அறிவார்.
	<br /><br />
	32. தமஸ்குபட்டணத்து அரேத்தா ராஜாவினுடைய சேனைத்தலைவன் என்னைப் பிடிக்கவேண்டுமென்று தமஸ்கருடைய பட்டணத்தைக் காவல்வைத்துக் காத்தான்;
	<br /><br />
	33. அப்பொழுது நான் கூடையிலே வைக்கப்பட்டு, ஜன்னலிலிருந்து மதில் வழியாய் இறக்கிவிடப்பட்டு, அவனுடைய கைக்குத் தப்பினேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians11