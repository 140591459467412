import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 7 </title>
	<meta
          name="description"
          content="Hosea 7 | ஓசியா 7 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் என் ஜனத்தின் சிறையிருப்பைத் திருப்பும்போதும், நான் இஸ்ரவேலை குணமாக்க விரும்பும்போதும், எப்பிராயீமின் அக்கிரமமும் சமாரியாவின் பொல்லாப்புகளும் வெளிப்படுத்தப்படும்; அவர்கள் வஞ்சனை செய்கிறார்கள்; திருடன் உள்ளே வருகிறான்; வெளியே பறிகாரரின் கூட்டத்தார் கொள்ளையிடுகிறார்கள்.
	<br /><br />
	2. அவர்கள் பொல்லாப்பையெல்லாம் நான் நினைவில் வைத்திருக்கிறேன் என்று அவர்கள் தங்கள் இருதயத்தில் சிந்திக்கிறதில்லை; இப்போதும் அவர்களுடைய கிரியைகள் அவர்களைச் சூழ்ந்துகொண்டது; அவைகள் என் முகத்துக்கு முன் இருக்கிறது.
	<br /><br />
	3. ராஜாவைத் தங்கள் பொல்லாப்பினாலும், அதிபதிகளைத் தங்கள் பொய்களினாலும் சந்தோஷப்படுத்துகிறார்கள்.
	<br /><br />
	4. அவர்கள் எல்லாரும் விபசாரக்கள்ளர்; அப்பஞ்சுடுகிறவன் எரிக்கும் அடுப்பைப்போல் இருக்கிறார்கள்; அவன் மாவைப் பிசைந்ததுமுதல் அது உப்பிப்போகுமட்டும், அனலை மூட்டாமல் ஓய்ந்திருக்கிறான்.
	<br /><br />
	5. நம்முடைய ராஜாவின் நாளென்று சொல்லி, அதிபதிகள் திராட்சரசத் துருத்திகளால் அவனுக்கு வியாதியுண்டாக்குகிறார்கள்; சரசக்காரரோடேகூட அவன் தன் கையை நீட்டுகிறான்.
	<br /><br />
	6. அவர்கள் பதிவிருக்கும்போது, தங்கள் இருதயத்தை அடுப்பைப்போல் ஆயத்தப்படுத்துகிறார்கள்; அவர்களில் அடுப்புமூட்டுகிறவன் இராமுழுதும் தூங்கினாலும், காலமேயோவென்றால் அது ஜுவாலிக்கிற அக்கினியாய் எரியும்.
	<br /><br />
	7. அவர்கள் எல்லாரும் அடுப்பைப்போல அனலாகி, தங்கள் நியாயாதிபதிகளைப் பட்சித்தார்கள்; அவர்களுடைய ராஜாக்கள் எல்லாரும் விழுந்தார்கள்; அவர்களில் என்னை நோக்கிக் கூப்பிடுகிறவன் ஒருவனும் இல்லை.
	<br /><br />
	8. எப்பிராயீம் அந்நியஜனங்களோடே கலந்திருக்கிறான்; எப்பிராயீம் திருப்பிப்போடாத அப்பம்.
	<br /><br />
	9. அந்நியர் அவனுடைய பலத்தைத் தின்கிறார்கள்; அவனோ அதை அறியான்; நரைமயிரும் அவனில் தெளித்திருக்கிறது, அவனோ அதை அறியாதிருக்கிறான்.
	<br /><br />
	10. இஸ்ரவேலின் அகந்தை அவர்கள் முகத்துக்கு முன்பாகச் சாட்சியிட்டாலும், அவர்கள் தங்கள் தேவனாகிய கர்த்தரிடத்தில் திரும்பாமலும், இவை எல்லாவற்றிலும் அவரைத் தேடாமலும் இருக்கிறார்கள்.
	<br /><br />
	11. எப்பிராயீம் பேதையான புறாவைப்போல் இருக்கிறான், அவனுக்குப் புத்தியில்லை; எகிப்தியனைக் கூப்பிடுகிறார்கள்; அசீரியனிடத்துக்கும் போகிறார்கள்.
	<br /><br />
	12. அவர்கள் போகும்போது, என் வலையை அவர்கள்மேல் வீசுவேன்; அவர்களை ஆகாயத்துப் பறவைகளைப்போலக் கீழே விழப்பண்ணுவேன்; அவர்களுடைய சபையில் கேள்வியானதின்படியே அவர்களைத் தண்டிப்பேன்.
	<br /><br />
	13. அவர்கள் என்னைவிட்டு அலைந்து திரிகிறபடியினால் அவர்களுக்கு ஐயோ! அவர்களுக்குக் கேடுவரும்; எனக்கு விரோதமாக இரண்டகம்பண்ணினார்கள்; நான் அவர்களை மீட்டிருந்தும், அவர்கள் எனக்கு விரோதமாகப் பொய்பேசுகிறார்கள்.
	<br /><br />
	14. அவர்கள் தங்கள் படுக்கைகளில் அலறுகிறபோது, தங்கள் இருதயத்தில் என்னை நோக்கிக் கூப்பிடுகிறதில்லை; அவர்கள் தானியத்துக்காகவும் திராட்சரசத்துக்காகவும் கூடுகிறார்கள்; என்னை வெறுத்து விலகிப்போகிறார்கள்.
	<br /><br />
	15. நான் அவர்களைத் தண்டித்தேன்; அவர்களுடைய புயங்கள் திரும்பப் பலப்படவும்பண்ணினேன்; ஆகிலும் எனக்கு விரோதமாகப் பொல்லாப்பு நினைக்கிறார்கள்.
	<br /><br />
	16. திரும்புகிறார்கள், ஆனாலும் உன்னதமானவரிடத்திற்கு அல்ல; மோசம்போக்குகிற வில்லைப்போலிருக்கிறார்கள்; அவர்களுடைய அதிபதிகள் தங்கள் நாவினுடைய உக்கிரத்தினிமித்தம் பட்டயத்தால் விழுவார்கள்; இதுவே எகிப்துதேசத்தினிமித்தம் அவர்களுக்கு வரும் நிந்தை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea7