import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 16 </title>
	<meta
          name="description"
          content="Jeremiah 16 | எரேமியா 16 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. நீ பெண்ணை விவாகம்பண்ணவேண்டாம்; இவ்விடத்தில் உனக்குக் குமாரரும் குமாரத்திகளும் இருக்கவேண்டாம் என்றார்.
	<br /><br />
	3. இவ்விடத்திலே பிறக்கிற குமாரரையும் குமாரத்திகளையும், இந்தத் தேசத்தில் அவர்களைப் பெற்ற தாய்களையும் அவர்களைப் பெற்ற பிதாக்களையுங்குறித்துக் கர்த்தர் சொல்லுகிறது என்னவென்றால்,
	<br /><br />
	4. மகா கொடிய வியாதிகளால் சாவார்கள், அவர்களுக்காகப் புலம்புவாரும், அவர்களை அடக்கம்பண்ணுவாருமில்லை, நிலத்தின்மேல் எருவாவார்கள்; பட்டயத்தாலும் பஞ்சத்தாலும் மடிந்துபோவார்கள்; அவர்களுடைய பிரேதம் ஆகாசத்துப் பறவைகளுக்கும் பூமியின் மிருகங்களுக்கும் இரையாகும்.
	<br /><br />
	5. ஆகையால், நீ துக்கவீட்டில் பிரவேசியாமலும், புலம்பப்போகாமலும், அவர்களுக்குப் பரிதபிக்காமலும் இருப்பாயாக என்று கர்த்தர் சொல்லுகிறார்; என் சமாதானத்தையும், கிருபையையும், இரக்கத்தையும், இந்த ஜனத்தைவிட்டு எடுத்துப்போட்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. இந்தத் தேசத்திலே பெரியோரும் சிறியோரும் சாவார்கள்; அவர்களை அடக்கம்பண்ணுவாரில்லை; அவர்களுக்காகப் புலம்புவாருமில்லை; அவர்கள் நிமித்தம் கீறிக்கொண்டு, மொட்டையடித்துக்கொள்வாருமில்லை.
	<br /><br />
	7. செத்தவர்கள் நிமித்தம் உண்டான துக்கத்தை ஆற்ற அவர்களுக்கு அப்பம் பங்கிடப்படுவதுமில்லை; ஒருவனுடைய தகப்பனுக்காவது, ஒருவனுடைய தாய்க்காவது துக்கப்படுகிறவர்களுக்குத் தேற்றரவின் பாத்திரத்தைக் குடிக்கக்கொடுப்பாருமில்லை.
	<br /><br />
	8. நீ அவர்களோடே புசித்துக் குடிக்க உட்காரும்படி விருந்துவீட்டிலும் பிரவேசியாயாக.
	<br /><br />
	9. ஏனெனில், இதோ, இவ்விடத்திலே நான் உங்கள் கண்களுக்கு முன்பாகவும், உங்கள் நாட்களிலுமே, சந்தோஷத்தின் சத்தத்தையும், மகிழ்ச்சியின் சத்தத்தையும், மணவாளனின் சத்தத்தையும், மணவாட்டியின் சத்தத்தையும் ஓயப்பண்ணுவேன் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. நீ இந்த வார்த்தைகளையெல்லாம் இந்த ஜனத்துக்கு அறிவிக்கும்போது, அவர்கள் உன்னை நோக்கி: கர்த்தர் எங்கள்மேல் இத்தனை பெரிய தீங்கைக் கூறுவானேன் என்றும், நாங்கள் செய்த அக்கிரமம் என்ன? நாங்கள் எங்கள் தேவனாகிய கர்த்தருக்கு விரோதமாகச் செய்த எங்கள் பாவம் என்ன? என்றும் கேட்பார்களானால்,
	<br /><br />
	11. நீ அவர்களை நோக்கி: உங்கள் பிதாக்கள் என்னைவிட்டு அந்நிய தேவர்களைப் பின்பற்றி, அவர்களைச் சேவித்து, அவர்களைப் பணிந்துகொண்டு, என் நியாயப்பிரமாணத்தைக் கைக்கொள்ளாமல் என்னை விட்டுவிட்டார்களே.
	<br /><br />
	12. நீங்கள் உங்கள் பிதாக்களைப்பார்க்கிலும் அதிக கேடாக நடந்தீர்களே; இதோ, உங்களில் ஒவ்வொருவரும் என் சொல்லைக் கேளாதபடிக்கு, உங்கள் பொல்லாத இருதயகடினத்தின்படி நடக்கிறீர்கள்.
	<br /><br />
	13. ஆதலால், உங்களை இந்தத் தேசத்திலிருந்து நீங்களும் உங்கள் பிதாக்களும் அறியாத தேசத்திற்குத் துரத்திவிடுவேன்; அங்கே இரவும் பகலும் அந்நிய தேவர்களைச் சேவிப்பீர்கள்; அங்கே நான் உங்களுக்குத் தயை செய்வதில்லை.
	<br /><br />
	14. ஆதலால், இதோ, நாட்கள் வரும், அப்பொழுது இஸ்ரவேல் புத்திரரை எகிப்துதேசத்திலிருந்து வரப்பண்ணின கர்த்தருடைய ஜீவனைக்கொண்டு இனிமேல் சத்தியம்பண்ணாமல்,
	<br /><br />
	15. இஸ்ரவேல் புத்திரரை வடதேசத்திலும், தாம் அவர்களைத் துரத்திவிட்ட எல்லா தேசங்களிலுமிருந்து வரப்பண்ணின கர்த்தருடைய ஜீவனைக்கொண்டு சத்தியம்பண்ணுவார்கள்; நான் அவர்கள் பிதாக்களுக்குக் கொடுத்த அவர்களுடைய தேசத்துக்கு அவர்களைத் திரும்பிவரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	16. இதோ, நான் மீன்பிடிக்கிற அநேகரை அழைத்தனுப்புவேன், இவர்கள் அவர்களைப் பிடிப்பார்கள்; அதற்குப் பின்பு வேட்டைக்காரராகிய அநேகரை அழைத்தனுப்புவேன், இவர்கள் அவர்களை எல்லா மலைகளிலும், எல்லாக் குன்றுகளிலும், கன்மலைகளின் வெடிப்புகளிலும் வேட்டையாடுவார்கள்.
	<br /><br />
	17. என் கண்கள் அவர்களுடைய எல்லா வழிகளின்மேலும் நோக்கமாயிருக்கிறது; அவைகள் என் முகத்துக்கு முன்பாக மறைந்திருக்கிறதில்லை; அவர்களுடைய அக்கிரமம் என் கண்களுக்கு முன்பாக மறைவாயிருக்கிறதுமில்லை.
	<br /><br />
	18. முதலாவது நான் அவர்களுடைய அக்கிரமத்துக்கும், அவர்களுடைய பாவத்துக்கும் இரட்டிப்பாய் நீதியைச் சரிக்கட்டுவேன்; அவர்கள் என் தேசத்தைத் தீட்டுப்படுத்தி, என் சுதந்தரத்தைச் சீயென்று அருவருக்கப்படத்தக்க தங்கள் காரியங்களின் நாற்றமான விக்கிரகங்களினாலே நிரப்பினார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	19. என் பெலனும், என் கோட்டையும், நெருக்கப்படுகிற நாளில் என் அடைக்கலமுமாகிய கர்த்தாவே, புறஜாதிகள் பூமியின் கடையாந்தரங்களிலிருந்து உம்மிடத்தில் வந்து: மெய்யாகவே, எங்கள் பிதாக்கள் பிரயோஜனமில்லாத பொய்யையும் மாயையையும் கைப்பற்றினார்கள் என்பார்கள்.
	<br /><br />
	20. மனுஷன் தனக்குத் தேவர்களை உண்டுபண்ணலாமோ? அவைகள் தேவர்கள் அல்லவே.
	<br /><br />
	21. ஆதலால், இதோ, இப்பொழுது நான் அவர்களுக்குத் தெரியப்பண்ணுவேன்; என் கரத்தையும் என் பெலத்தையுமே அவர்களுக்குத் தெரியப்பண்ணுவேன்; என் நாமம் யேகோவா என்று அறிந்துகொள்வார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah16