import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 8 </title>
	<meta
          name="description"
          content="Jeremiah 8 | எரேமியா 8 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே யூதாவினுடைய ராஜாக்களின் எலும்புகளையும், அவர்களுடைய பிரபுக்களின் எலும்புகளையும், ஆசாரியர்களின் எலும்புகளையும், தீர்க்கதரிசிகளின் எலும்புகளையும், எருசலேமுடைய குடிகளின் எலும்புகளையும், அவர்களுடைய பிரேதக்குழிகளிலிருந்து எடுத்து,
	<br /><br />
	2. அவர்கள் நேசித்ததும், சேவித்ததும், பின்பற்றினதும், நாடினதும், பணிந்துகொண்டதுமாயிருந்த சூரியனுக்கும், சந்திரனுக்கும், வானத்தின் சர்வசேனைக்கும் முன்பாக அவைகளைப் பரப்பிவைப்பார்கள் என்று கர்த்தர் சொல்லுகிறார்; அவைகள் வாரி அடக்கம்பண்ணப்படாமல் பூமியின்மேல் எருவாகும்.
	<br /><br />
	3. இந்தத் துஷ்ட வம்சத்தில் மீதியாயிருந்து, என்னால் எல்லா இடங்களிலும் துரத்திவிடப்பட்டு மீந்திருக்கிற யாவருக்கும், ஜீவனைப்பார்க்கிலும் மரணமே விருப்பமாயிருக்குமென்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. நீ அவர்களை நோக்கி: விழுந்தவர்கள் எழுந்திருக்கிறதில்லையோ? வழிதப்பிப்போனவர்கள் திரும்புகிறதில்லையோ?
	<br /><br />
	5. ஆனாலும் எருசலேமியராகிய இந்த ஜனம் என்றைக்கும் வழிதப்பிப்போகிறதென்ன? கபடத்தை உறுதியாய்ப் பிடித்திருக்கிறார்கள்; திரும்பமாட்டோம் என்கிறார்கள்.
	<br /><br />
	6. நான் கவனித்துக் கேட்டேன், அவர்கள் யதார்த்தம் பேசவில்லை; என்ன செய்தேனென்று சொல்லி, தன் பொல்லாப்பினிமித்தம் மனஸ்தாபப்படுகிறவன் ஒருவனுமில்லை; யுத்தத்துக்குள் பாய்கிற குதிரையைப்போல அவரவர் வேகமாய் ஓடிப்போனார்கள்.
	<br /><br />
	7. ஆகாயத்திலுள்ள நாரை முதலாய்த் தன் வேளையை அறியும்; காட்டுப்புறாவும், கொக்கும், தகைவிலான் குருவியும் தாங்கள் வரத்தக்க காலத்தை அறியும்; என் ஜனங்களோ கர்த்தரின் நியாயத்தை அறியார்கள் என்று கர்த்தர் உரைக்கிறார் என்று சொல்.
	<br /><br />
	8. நாங்கள் ஞானிகளென்றும், கர்த்தருடைய வேதம் எங்களிடத்திலிருக்கிறதென்றும் நீங்கள் சொல்லுகிறதெப்படி? மெய்யாகவே, இதோ, வேதபாரகரின் கள்ள எழுத்தாணி அதை அபத்தமாக்குகிறது.
	<br /><br />
	9. ஞானிகள் வெட்கி, கலங்கிப் பிடிபடுவார்கள்; இதோ, கர்த்தருடைய சொல்லை வெறுத்துப்போட்டார்கள்; அவர்களுக்கு ஞானமேது?
	<br /><br />
	10. ஆகையால் அவர்களுடைய ஸ்திரீகளை அந்நியருக்கும், அவர்களுடைய வயல்களை அவைகளைக் கட்டிக்கொள்பவர்களுக்கும் கொடுப்பேன்; அவர்களிலே சிறியோர்தொடங்கிப் பெரியோர்மட்டும் ஒவ்வொருவரும் பொருளாசைக்காரராயிருக்கிறார்கள்; தீர்க்கதரிசிகள்தொடங்கி ஆசாரியர்கள்மட்டும் ஒவ்வொருவரும் பொய்யராயிருந்து,
	<br /><br />
	11. சமாதானமில்லாதிருந்தும், சமாதானம் சமாதானம் என்று சொல்லி, என் ஜனமாகிய குமாரத்தியின் காயங்களை மேற்பூச்சாய்க் குணமாக்குகிறார்கள்.
	<br /><br />
	12. தாங்கள் அருவருப்பானதைச் செய்ததினிமித்தம் வெட்கப்படுகிறார்களா? பரிச்சேதம் வெட்கப்படார்கள், நாணவும் அறியார்கள்; ஆகையால் விழுகிறவர்களுக்குள்ளே விழுவார்கள்; நான் அவர்களை விசாரிக்குங்காலத்திலே இடறுண்டுபோவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. அவர்களை முற்றிலும் அழித்துப்போடுவேன் என்று கர்த்தர் சொல்லுகிறார்; திராட்சச்செடியிலே குலைகள் இராது, அத்திமரத்திலே பழங்கள் இராது, இலையும் உதிரும், நான் அவர்களுக்குக் கொடுத்தது அவர்களைவிட்டுத் தாண்டிப்போகும் என்று சொல்.
	<br /><br />
	14. நாம் சும்மாயிருப்பானேன்? கூடிவாருங்கள்; நாம் அரணான பட்டணங்களுக்குள் பிரவேசித்து, அங்கே சங்காரமாவோம்; நாம் கர்த்தருக்கு விரோதமாய்ப் பாவஞ்செய்தபடியால், நம்முடைய தேவனாகிய கர்த்தர் நம்மைச் சங்காரம்பண்ணி, நமக்குப் பிச்சுக்கலந்த தண்ணீரைக் குடிக்கக்கொடுக்கிறார்.
	<br /><br />
	15. சமாதானத்துக்குக் காத்திருந்தோம், பிரயோஜனமில்லை; ஆரோக்கிய காலத்துக்குக் காத்திருந்தோம், இதோ, ஆபத்து.
	<br /><br />
	16. தாணிலிருந்து அவர்களுடைய குதிரைகளின் மூச்செறிதல் கேட்கப்படுகிறது; அவர்களுடைய பலத்த அஸ்வங்கள் கனைக்கிற சத்தத்தினால் தேசமெல்லாம் அதிருகிறது; அவர்கள் வந்து தேசத்தையும் அதில் உள்ளவைகளையும், பட்டணத்தையும் அதின் குடிகளையும் பட்சிப்பார்கள்.
	<br /><br />
	17. மெய்யாய், இதோ, தடைகட்டப்படாத சர்ப்பங்களையும், கட்டுவிரியன்களையும் உங்களுக்குள் அனுப்புகிறேன், அவைகள் உங்களைக் கடிக்கும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. நான் சஞ்சலத்தில் ஆறுதலடையப் பார்த்தும், என் இருதயம் பலட்சயமாயிருக்கிறது.
	<br /><br />
	19. இதோ, சீயோனில் கர்த்தர் இல்லையோ? அதில் ராஜா இல்லையோ? என்று, என் ஜனமாகிய குமாரத்தி தூரதேசத்திலிருந்து கூப்பிடும் சத்தம் கேட்கப்படுகிறது; ஆனால், அவர்கள் தங்கள் சுரூபங்களினாலும் அந்நியரின் மாயைகளினாலும் எனக்குக் கோபமுண்டாக்கினது என்ன என்கிறார்.
	<br /><br />
	20. அறுப்புக்காலம் சென்றது, கோடைக்காலமும் முடிந்தது, நாமோ இரட்சிக்கப்படவில்லை.
	<br /><br />
	21. என் ஜனமாகிய குமாரத்தியின் காயங்களினால் நானும் காயப்பட்டேன்; கரிகறுத்திருக்கிறேன்; திகைப்பு என்னைப் பிடித்தது.
	<br /><br />
	22. கீலேயாத்திலே பிசின் தைலம் இல்லையோ? இரணவைத்தியனும் அங்கே இல்லையோ? பின்னை ஏன் என் ஜனமாகிய குமாரத்தி சொஸ்தமடையாமற்போனாள்?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah8