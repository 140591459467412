import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 21 </title>
	<meta
          name="description"
          content="Jeremiah 21 | எரேமியா 21 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சிதேக்கியா ராஜா மல்கியாவின் குமாரனாகிய பஸ்கூரையும், ஆசாரியனான மாசெயாவின் குமாரனாகிய செப்பனியாவையும் எரேமியாவினிடத்தில் அனுப்பி:
	<br /><br />
	2. நீர் எங்களுக்காகக் கர்த்தரிடத்தில் விசாரியும்; பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் எங்களுக்கு விரோதமாய் யுத்தஞ்செய்கிறான்; ஒருவேளை கர்த்தர் தம்முடைய எல்லா அற்புதச் செயலின்படியேயும் எங்களுக்கு அநுக்கிரகஞ்செய்து, அவனை எங்களைவிட்டுப் போகப்பண்ணுவார் என்று சொல்லியனுப்பினபோது,
	<br /><br />
	3. எரேமியா அவர்களைப்பார்த்து, நீங்கள் சிதேக்கியாவுக்குச் சொல்லவேண்டியது:
	<br /><br />
	4. இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறது என்னவென்றால், இதோ, உங்களை அலங்கத்துக்கு வெளியே முற்றிக்கைபோட்ட பாபிலோன் ராஜாவோடும் கல்தேயரோடும், நீங்கள் யுத்தம்பண்ணும்படி உங்கள் கைகளில் பிடித்திருக்கிற யுத்த ஆயுதங்களை நான் திருப்பிவிட்டு, அவர்களை இந்த நகரத்தின் நடுவிலே சேர்த்து,
	<br /><br />
	5. நான் நீட்டின கையினாலும் பலத்த புயத்தினாலும் கோபமும் உக்கிரமும் மகா கடுமையுமாக உங்களோடே யுத்தம்பண்ணி,
	<br /><br />
	6. இந்த நகரத்தின் குடிகளையும், மனுஷரையும், மிருகங்களையும் சங்கரிப்பேன்; மகா கொள்ளைநோயால் சாவார்கள்.
	<br /><br />
	7. அதற்குப்பின்பு நான் யூதாவின் ராஜாவாகிய சிதேக்கியாவையும், அவன் ஊழியக்காரரையும், ஜனத்தையும், இந்த நகரத்திலே கொள்ளைநோய்க்கும் பட்டயத்துக்கும் பஞ்சத்துக்கும் தப்பி மீதியானவர்களையும் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாருடைய கையிலும், அவர்கள் சத்துருக்களின் கையிலும், அவர்கள் பிராணனை வாங்கத்தேடுகிறவர்களின் கையிலும் ஒப்புக்கொடுப்பேன்; அவன் அவர்களைப் பட்டயக்கருக்கினால் வெட்டுவான்; அவன் அவர்களைத் தப்பவிடுவதுமில்லை, அவன் மன்னிப்பதுமில்லை, இரங்குவதுமில்லையென்று கர்த்தர் உரைக்கிறார் என்றான்.
	<br /><br />
	8. பின்னையும் அவர், இந்த ஜனங்களை நோக்கி: இதோ, நான் உங்கள் முன்னே ஜீவவழியையும் மரணவழியையும் வைக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. இந்த நகரத்திலே தரிக்கிறவன் பட்டயத்தாலும், பஞ்சத்தாலும், கொள்ளைநோயாலும் சாவான்; உங்களை முற்றிக்கைபோடும் கல்தேயர் வசமாய்ப் புறப்பட்டுப் போய்விடுகிறவனோ பிழைப்பான்; அவன் பிராணன் அவனுக்குக் கிடைத்த கொள்ளைப்பொருளைப்போல் இருக்கும்.
	<br /><br />
	10. என் முகத்தை இந்த நகரத்துக்கு விரோதமாய் நன்மைக்கு அல்ல, தீமைக்கே வைத்தேன் என்று கர்த்தர் சொல்லுகிறார்; அது பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுக்கப்படும்; அவன் அக்கினியால் அதைச் சுட்டெரிப்பானென்று சொல் என்றார்.
	<br /><br />
	11. யூதாராஜாவின் குடும்பத்தாரையும் நோக்கி: கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	12. தாவீதின் குடும்பத்தாரே, உங்கள் செய்கைகளுடைய பொல்லாப்பினிமித்தம் என் உக்கிரம் அக்கினியைப்போல் புறப்பட்டு, அவிக்கிறவன் இல்லாமல் எரியாதபடிக்கு, நீங்கள் ஏற்கனவே நியாயங்கேட்டு, பறிகொடுத்தவனை ஒடுக்குகிறவனுடைய கைக்குத் தப்புவியுங்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. இதோ, பள்ளத்தாக்கில் வாசம்பண்ணுகிறவளே, சமனான இடத்தில் கன்மலையாய் இருக்கிறவளே, எங்களுக்கு விரோதமாய் வருகிறவன் யார் என்றும், எங்கள் வாசஸ்தலங்களுக்குள் வருகிறவன் யார் என்றும் சொல்லுகிற உனக்கு நான் எதிராளியாயிருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. நான் உங்கள் கிரியைகளின் பலனுக்குத்தக்கதாய் உங்களை விசாரிப்பேன்; நான் அதின் காட்டிலே தீக்கொளுத்துவேன்; அது அதைச் சுற்றிலுமுள்ள யாவையும் பட்சிக்கும் என்று கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah21