import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title> The Changeless Christ Hebrews in Tamil Bible | எபிரெயர் - 13 </title>
	<meta
          name="description"
          content="The Changeless Christ Hebrews Chapter-13 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-13 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil."
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* மாறாத கிறிஸ்து *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. சகோதர சிநேகம் நிலைத்திருக்கக்கடவது.
	<br /><br />
	2. அந்நியரை உபசரிக்க மறவாதிருங்கள்; அதினாலே சிலர் அறியாமல் தேவதூதரையும் உபசரித்ததுண்டு.
	<br /><br />
	3. கட்டப்பட்டிருக்கிறவர்களோடே நீங்களும் கட்டப்பட்டவர்கள்போல அவர்களை நினைத்துக்கொள்ளுங்கள்; நீங்களும் சரீரத்தோடிருக்கிறவர்களென்று அறிந்து, தீங்கநுபவிக்கிறவர்களை நினைத்துக்கொள்ளுங்கள்.
	<br /><br />
	4. விவாகம் யாவருக்குள்ளும் கனமுள்ளதாயும், விவாகமஞ்சம் அசுசிப்படாததாயுமிருப்பதாக; வேசிக்கள்ளரையும் விபசாரக்காரரையும் தேவன் நியாயந்தீர்ப்பார்.
	<br /><br />
	5. நீங்கள் பண ஆசையில்லாதவர்களாய் நடந்து, உங்களுக்கு இருக்கிறவைகள் போதுமென்று எண்ணுங்கள்; நான் உன்னைவிட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை என்று அவர் சொல்லியிருக்கிறாரே.
	<br /><br />
	6. அதினாலே நாம் தைரியங்கொண்டு: கர்த்தர் எனக்குச் சகாயர், நான் பயப்படேன், மனுஷன் எனக்கு என்னசெய்வான் என்று சொல்லலாமே.
	<br /><br />
	7. தேவவசனத்தை உங்களுக்குப் போதித்து உங்களை நடத்தினவர்களை நீங்கள் நினைத்து, அவர்களுடைய நடக்கையின் முடிவை நன்றாய்ச் சிந்தித்து, அவர்களுடைய விசுவாசத்தைப் பின்பற்றுங்கள்.
	<br /><br />
	8. இயேசுகிறிஸ்து நேற்றும் இன்றும் என்றும் மாறாதவராயிருக்கிறார்.
	<br /><br />
	9. பலவிதமான அந்நிய போதனைகளால் அலைப்புண்டு திரியாதிருங்கள். போஜனபதார்த்தங்களினாலல்ல, கிருபையினாலே இருதயம் ஸ்திரப்படுகிறது நல்லது; போஜனபதார்த்தங்களில் முயற்சிசெய்கிறவர்கள் பலனடையவில்லையே.
	<br /><br />
	10. நமக்கு ஒரு பலிபீடமுண்டு, அதற்குரியவைகளைப் புசிக்கிறதற்குக் கூடாரத்தில் ஆராதனை செய்கிறவர்களுக்கு அதிகாரமில்லை.
	<br /><br />
	11. ஏனென்றால், எந்த மிருகங்களுடைய இரத்தம் பாவங்களினிமித்தமாகப் பரிசுத்த ஸ்தலத்துக்குள் பிரதான ஆசாரியனாலே கொண்டுவரப்படுகிறதோ, அந்த மிருகங்களின் உடல்கள் பாளயத்துக்குப் புறம்பே சுட்டெரிக்கப்படும்.
	<br /><br />
	12. அந்தப்படியே, இயேசுவும் தம்முடைய சொந்த இரத்தத்தினாலே ஜனத்தைப் பரிசுத்தஞ்செய்யும்படியாக நகரவாசலுக்குப் புறம்பே பாடுபட்டார்.
	<br /><br />
	13. ஆகையால், நாம் அவருடைய நிந்தையைச் சுமந்து, பாளயத்துக்குப் புறம்பே அவரிடத்திற்குப் புறப்பட்டுப்போகக்கடவோம்.
	<br /><br />
	14. நிலையான நகரம் நமக்கு இங்கே இல்லை; வரப்போகிறதையே நாடித் தேடுகிறோம்.
	<br /><br />
	15. ஆகையால், அவருடைய நாமத்தைத் துதிக்கும் உதடுகளின் கனியாகிய ஸ்தோத்திரபலியை அவர்மூலமாய் எப்போதும் தேவனுக்குச் செலுத்தக்கடவோம்.
	<br /><br />
	16. அன்றியும் நன்மைசெய்யவும், தானதர்மம்பண்ணவும் மறவாதிருங்கள்; இப்படிப்பட்ட பலிகளின்மேல் தேவன் பிரியமாயிருக்கிறார்.
	<br /><br />
	17. உங்களை நடத்துகிறவர்கள், உங்கள் ஆத்துமாக்களுக்காக உத்தரவாதம்பண்ணுகிறவர்களாய் விழித்திருக்கிறவர்களானபடியால், அவர்கள் துக்கத்தோடே அல்ல, சந்தோஷத்தோடே அதைச் செய்யும்படி, அவர்களுக்குக் கீழ்ப்படிந்து அடங்குங்கள்; அவர்கள் துக்கத்தோடே அப்படிச் செய்தால் அது உங்களுக்குப் பிரயோஜனமாயிருக்கமாட்டாதே.
	<br /><br />
	18. எங்களுக்காக வேண்டிக்கொள்ளுங்கள்; நாங்கள் நல்மனச்சாட்சியுள்ளவர்களாய் எல்லாவற்றிலும் யோக்கியமாய் நடக்க விரும்புகிறோமென்று நிச்சயித்திருக்கிறோம்.
	<br /><br />
	19. நான் அதிசீக்கிரமாய் உங்களிடத்தில் வரும்படிக்கு நீங்கள் இப்படி வேண்டிக்கொள்ளும்படி அதிகமாய்க் கேட்டுக்கொள்ளுகிறேன்.
	<br /><br />
	20. நித்திய உடன்படிக்கையின் இரத்தத்தினாலே ஆடுகளுடைய பெரிய மேய்ப்பரான நம்முடைய கர்த்தராகிய இயேசுவை மரித்தோரிலிருந்து ஏறிவரப்பண்ணின சமாதானத்தின் தேவன்,
	<br /><br />
	21. இயேசுகிறிஸ்துவைக்கொண்டு தமக்குமுன்பாகப் பிரியமானதை உங்களில் நடப்பித்து, நீங்கள் தம்முடைய சித்தத்தின்படிசெய்ய உங்களைச் சகலவித நற்கிரியையிலும் சீர்பொருந்தினவர்களாக்குவாராக; அவருக்கு என்றென்றைக்கும் மகிமை உண்டாவதாக. ஆமென்.
	<br /><br />
	22. சகோதரரே, நான் சுருக்கமாய் உங்களுக்கு எழுதின இந்தப் புத்திமதியான வார்த்தைகளை நீங்கள் பொறுமையாய் ஏற்றுக்கொள்ளும்படி வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	23. சகோதரனாகிய தீமோத்தேயு விடுதலையாக்கப்பட்டானென்று அறிவீர்களாக; அவன் சீக்கிரமாய் வந்தால், அவனோடேகூட நான் வந்து, உங்களைக் காண்பேன்.
	<br /><br />
	24. உங்களை நடத்துகிறவர்களையும், பரிசுத்தவான்கள் யாவரையும் வாழ்த்துங்கள். இத்தாலியா தேசத்தார் யாவரும் உங்களுக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	25. கிருபையானது உங்களனைவரோடுங்கூட இருப்பதாக. ஆமென்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* எபிரேயர் 13 எச்சரிக்கைகள், கோரிக்கைகள் மற்றும் இறுதி அறிவுரைகளின் கலவையாகும். இது கிறிஸ்துவின் கூட்டுறவை நினைவூட்டுகிறது, அன்பை நிலைநிறுத்தவும், விருந்தோம்பலைக் காட்டவும், ஒருவருக்கொருவர் மரியாதை செய்யவும். அதே போல் தேவாலயத்தில் தலைவர்களுக்காக ஜெபிப்பதை நினைவில் கொள்க. இயேசு நேற்றும் இன்றும் என்றும் மாறாதவர் என்பதை நினைவூட்டி அற்புதமான குறிப்புடன் முடிகிறது இந்தப் புத்தகம். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews13