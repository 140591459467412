import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial44 = () => {
  const verseNumber = 44;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 44 </title>
	<meta
          name="description"
          content="Ezekiel 44 | எசேக்கியேல்  44 |
          Ezekiel 44 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு, அவர் என்னைக் கிழக்குக்கு எதிரே பரிசுத்த ஸ்தலத்துக்குப் புறவாசல் வழியே திரும்பப்பண்ணினார்; அது பூட்டப்பட்டிருந்தது.
	<br /><br />
	2. அப்பொழுது கர்த்தர் என்னை நோக்கி: இந்த வாசல் திறக்கப்படாமல் பூட்டப்பட்டிருக்கும்; ஒருவரும் இதற்குள் பிரவேசிப்பதில்லை; இஸ்ரவேலின் தேவனாகிய கர்த்தர் இதற்குள் பிரவேசித்தார், ஆகையால் இது பூட்டப்பட்டிருக்கவேண்டும்.
	<br /><br />
	3. இது அதிபதிக்கே உரியது, அதிபதி கர்த்தருடைய சந்நிதியில் போஜனம்பண்ணும்படி இதில் உட்காருவான்; அவன் வாசல் மண்டபத்தின் வழியாய்ப் பிரவேசித்து, மறுபடியும் அதின் வழியாய்ப் புறப்படுவான் என்றார்.
	<br /><br />
	4. பின்பு அவர் என்னை வடக்கு வாசல்வழியாய் ஆலயத்தின் முகப்பிலே அழைத்துக்கொண்டுபோனார்; இதோ, கர்த்தருடைய ஆலயம் கர்த்தருடைய மகிமையால் நிறைந்ததை நான் கண்டு, முகங்குப்புற விழுந்தேன்.
	<br /><br />
	5. அப்பொழுது கர்த்தர் என்னை நோக்கி: மனுபுத்திரனே, கர்த்தருடைய ஆலயத்தின் சகல நியமங்களையும் அதின் சகல சட்டங்களையுங்குறித்து நான் உன்னோடே சொல்வதையெல்லாம் நீ உன் மனதிலே கவனித்து, உன் கண்களினாலே பார்த்து, உன் காதுகளினாலே கேட்டு, பரிசுத்த ஸ்தலத்தினுடைய எல்லா வாசற்படிகளின் வழியாய் ஆலயத்துக்குள் பிரவேசிப்பதும் அதிலிருந்து புறப்படுவதும் இன்னவிதமென்று நீ ஆலோசித்துப் பார்த்து,
	<br /><br />
	6. இஸ்ரவேல் வம்சத்தாராகிய கலகக்காரரோடே சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், இஸ்ரவேல் வம்சத்தாரே, நீங்கள் செய்த சகல அருவருப்புகளும் போதும்.
	<br /><br />
	7. நீங்கள் எனக்குச் செலுத்தவேண்டிய ஆகாரமாகிய நிணத்தையும் இரத்தத்தையும் செலுத்துகையில், என் ஆலயத்தைப் பரிசுத்தக்குலைச்சலாக்கும்படி விருத்தசேதனமில்லாத இருதயமும் விருத்தசேதனமில்லாத மாம்சமுமுள்ள அந்நிய புத்திரரை என் பரிசுத்த ஸ்தலத்துக்குள் இருக்கிறதற்காக அழைத்துக்கொண்டுவந்தீர்கள்; நீங்கள் செய்த எல்லா அருவருப்புகளினாலும் அவர்கள் என் உடன்படிக்கையை மீறினார்கள்.
	<br /><br />
	8. நீங்கள் என் பரிசுத்த வஸ்துக்களின் காவலைக் காவாமல், உங்களுக்கு இஷ்டமானவர்களை என் பரிசுத்த ஸ்தலத்திலே என் காவலைக் காக்கிறதற்காக வைத்தீர்கள்.
	<br /><br />
	9. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இஸ்ரவேல் புத்திரரின் நடுவில் இருக்கிற சகல அந்நிய புத்திரரிலும், விருத்தசேதனமில்லாத இருதயமும் விருத்தசேதனமில்லாத மாம்சமுமுள்ள அந்நிய புத்திரன் ஒருவனும் என் பரிசுத்த ஸ்தலத்துக்குள் பிரவேசிப்பதில்லை.
	<br /><br />
	10. இஸ்ரவேல் வழிதப்பிப்போகையில், என்னைவிட்டுத் தூரமானவர்களும், என்னைவிட்டு வழிதப்பித் தங்கள் நரகலான விக்கிரகங்களைப் பின்பற்றினவர்களுமாகிய லேவியரும் தங்கள் அக்கிரமத்தைச் சுமப்பார்கள்.
	<br /><br />
	11. ஆகிலும் அவர்கள் என் ஆலயத்தின் வாசல்களைக் காத்து, என் ஆலயத்தில் ஊழியஞ்செய்து, என் பரிசுத்த ஸ்தலத்திலே பணிவிடைக்காரராயிருப்பார்கள்; அவர்கள் ஜனங்களுக்காகத் தகனபலிகளையும் மற்றப் பலிகளையும் செலுத்தி, இவர்களுக்கு ஊழியஞ்செய்கிறதற்கு இவர்கள் முன்பாக என் பரிசுத்த ஸ்தலத்திலே பணிவிடைக்காரராயிருப்பார்கள்.
	<br /><br />
	12. அவர்கள் இவர்களுடைய நரகலான விக்கிரகங்களுக்கு முன்பாக நின்று இவர்களுக்கு ஊழியஞ்செய்து, இஸ்ரவேல் வம்சத்தாரை அக்கிரமத்தில் விழப்பண்ணினபடியினால், நான் என் கையை அவர்களுக்கு விரோதமாய் உயர்த்தினேன், அவர்கள் தங்கள் அக்கிரமத்தைச் சுமப்பார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	13. அவர்கள் எனக்கு ஆசாரியராய் ஆராதனை செய்யும்படி என் சமீபத்தில் வராமலும், மகா பரிசுத்தமான ஸ்தலத்தில் என் பரிசுத்த வஸ்துக்களில் யாதொன்றையும் கிட்டாமலும் இருக்கவேண்டும், அவர்கள் தங்கள் இலச்சையையும் தாங்கள் செய்த அருவருப்புகளையும் சுமக்கக்கடவர்கள்.
	<br /><br />
	14. ஆலயத்தின் சகல வேலைகளுக்கும் அதில் செய்யப்படவேண்டிய எல்லாவற்றிற்கும் நான் அவர்களை அதில் காவல்காக்கிறவர்களாயிருக்கக் கட்டளையிடுவேன்.
	<br /><br />
	15. இஸ்ரவேல் புத்திரரே, என்னைவிட்டு வழிதப்பிப்போகையில், என் பரிசுத்த ஸ்தலத்தின் காவலைக் காக்கிற சாதோக்கின் புத்திரராகிய லேவியரென்னும் ஆசாரியர்களே எனக்கு ஆராதனைசெய்ய என் சமீபத்தில் சேர்ந்து, நிணத்தையும் இரத்தத்தையும் எனக்குச் செலுத்த என் சந்நிதியில் நிற்பார்களென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	16. இவர்கள் என் பரிசுத்த ஸ்தலத்துக்குள் பிரவேசிப்பார்கள்; இவர்களே எனக்கு ஆராதனை செய்ய என் பீடத்தைக் கிட்டிவந்து, என் காவலைக் காப்பார்கள்.
	<br /><br />
	17. உட்பிராகாரத்தின் வாசல்களுக்குள் பிரவேசிக்கிறபோது, சணல்நூல் வஸ்திரங்களை உடுத்திக்கொள்வார்களாக; அவர்கள் உட்பிராகாரத்தின் வாசல்களிலும், உள்ளேயும் ஆராதனை செய்கையில், ஆட்டுமயிர் உடுப்பைத் தரிக்கலாகாது.
	<br /><br />
	18. அவர்களுடைய தலைகளில் சணல்நூல் குல்லாக்களையும், அவர்களுடைய இடைகளில் சணல்நூல் சல்லடங்களையும் தரிக்கவேண்டும்; வேர்வையுண்டாக்கத்தக்கதொன்றையும் அரையில் கட்டலாகாது.
	<br /><br />
	19. அவர்கள் வெளிப்பிராகாரமாகிய புறமுற்றத்திலே ஜனங்களிடத்தில் போகும்போது, அவர்கள் தாங்கள் ஆராதனைசெய்யும் சமயத்தில் உடுத்தியிருந்த தங்கள் வஸ்திரங்களைக் கழற்றி, அவைகளைப் பரிசுத்த அறைவீடுகளில் வைத்து, வேறே வஸ்திரங்களை உடுத்திக்கொள்ளக்கடவர்கள்; தங்கள் வஸ்திரங்களாலே ஜனங்களைப் பரிசுத்தப்படுத்தலாகாது.
	<br /><br />
	20. அவர்கள் தங்கள் தலைகளைச் சிரையாமலும், தங்கள் மயிரை நீளமாய் வளர்க்காமலும், தங்கள் தலைமயிரைக் கத்தரிக்கக்கடவர்கள்.
	<br /><br />
	21. ஆசாரியர்களில் ஒருவனும், உட்பிராகாரத்துக்குள் பிரவேசிக்கும்போது, திராட்சரசம் குடிக்கலாகாது.
	<br /><br />
	22. விதவையையும் தள்ளிவிடப்பட்டவளையும் அவர்கள் விவாகம்பண்ணாமல், இஸ்ரவேல் வம்சத்தாளாகிய கன்னிகையையாகிலும் ஒரு ஆசாரியனின் மனைவியாயிருந்த விதவையையாகிலும் விவாகம்பண்ணலாம்.
	<br /><br />
	23. அவர்கள் பரிசுத்தமானதற்கும் பரிசுத்தமல்லாததற்கும், தீட்டானதற்கும் தீட்டல்லாததற்கும் இருக்கும் வித்தியாசத்தை என் ஜனத்துக்குப் போதித்து, அவர்களுக்குத் தெரியப்பண்ணக்கடவர்கள்.
	<br /><br />
	24. வழக்கிருந்தால் அவர்கள் நியாயந்தீர்க்க ஆயத்தமாயிருந்து, என் நியாயங்களின்படி அதைத் தீர்த்து, என்னுடைய பண்டிகைகளில் எல்லாம் என் நியாயப்பிரமாணத்தையும் என் கட்டளைகளையும் கைக்கொண்டு, என் ஓய்வுநாட்களைப் பரிசுத்தமாக்கக்கடவர்கள்.
	<br /><br />
	25. தகப்பன், தாய், குமாரன், குமாரத்தி, சகோதரன், புருஷனுக்கு வாழ்க்கைப்படாத சகோதரி என்னும் இவர்களுடைய சவத்தினால் அவர்கள் தீட்டுப்படலாமேயல்லாமல், அவர்களில் ஒருவனும் செத்த ஒருவனிடத்தில் போய்த் தீட்டுப்படலாகாது.
	<br /><br />
	26. அவன் சுத்திகரிக்கப்பட்டபின்பு, அவனுக்கு ஏழுநாள் எண்ணப்படவேண்டும்.
	<br /><br />
	27. அவன் பரிசுத்த ஸ்தலத்தில் ஆராதனை செய்யும்படி பரிசுத்த ஸ்தலம் இருக்கிற உட்பிராகாரத்துக்குள் பிரவேசிக்கிறநாளிலே, அவன் தனக்காகப் பாவநிவாரண பலியைச் செலுத்தக்கடவன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	28. அவர்களுக்குரிய சுதந்தரம் என்னவென்றால்: நானே அவர்கள் சுதந்தரம்; ஆகையால் இஸ்ரவேலில் அவர்களுக்குக் காணியாட்சியைக் கொடாதிருப்பீர்களாக; நானே அவர்கள் காணியாட்சி.
	<br /><br />
	29. போஜனபலியையும் பாவநிவாரணபலியையும் குற்றநிவாரணபலியையும் அவர்கள் புசிப்பார்கள்; இஸ்ரவேலிலே பொருத்தனை பண்ணப்பட்டதெல்லாம் அவர்களுடையதாயிருப்பதாக.
	<br /><br />
	30. சகலவித முதற்கனிகளில் எல்லாம் முந்தின பலனும், நீங்கள் காணிக்கையாய்ச் செலுத்தும் எவ்விதமான பொருள்களும் ஆசாரியர்களுடையதாயிருப்பதாக; உங்கள் வீட்டில் ஆசீர்வாதம் தங்கும்படிக்கு நீங்கள் பிசைந்தமாவில் முதற்பாகத்தையும் ஆசாரியனுக்குக் கொடுக்கக்கடவீர்கள்.
	<br /><br />
	31. பறவைகளிலும் மிருகங்களிலும் தானாய்ச் செத்ததும் பீறுண்டதுமான ஒன்றையும் ஆசாரியர்கள் புசிக்கலாகாது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial44