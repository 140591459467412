import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 7 </title>
	<meta
          name="description"
          content="Isaiah 7 | ஏசாயா 7 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உசியாவினுடைய குமாரனாகிய யோதாமின் குமாரன் ஆகாஸ் என்னும் யூதாதேசத்து ராஜாவின் நாட்களிலே, ரேத்சீன் என்னும் சீரியாவின் ராஜாவும், ரெமலியாவின் குமாரனாகிய பெக்கா என்னும் இஸ்ரவேலின் ராஜாவும் எருசலேமின்மேல் யுத்தம்பண்ண வந்தார்கள், அவர்களால் அதைப் பிடிக்கக்கூடாமற்போயிற்று.
	<br /><br />
	2. சீரியர் எப்பிராயீமைச் சார்ந்திருக்கிறார்களென்று தாவீதின் குடும்பத்துக்கு அறிவிக்கப்பட்டபோது, ராஜாவின் இருதயமும் அவன் ஜனத்தின் இருதயமும் காட்டிலுள்ள மரங்கள் காற்றினால் அசைகிறதுபோல் அசைந்தது.
	<br /><br />
	3. அப்பொழுது கர்த்தர் ஏசாயாவை நோக்கி: நீயும் உன் மகன் சேயார்யாசூபுமாக வண்ணார் துறைவழியிலுள்ள மேற்குளத்து மதகின் கடைசிமட்டும் ஆகாசுக்கு எதிர்கொண்டுபோய்,
	<br /><br />
	4. நீ அவனை நோக்கி: சீரியர் எப்பிராயீமோடும், ரெமலியாவின் மகனோடும் உனக்கு விரோதமாகத் துராலோசனை பண்ணி,
	<br /><br />
	5. நாம் யூதாவுக்கு விரோதமாய்ப்போய், அதை நெருக்கி, அதை நமக்குள்ளே பங்கிட்டுக்கொண்டு, அதற்குத் தபேயாலின் குமாரனை ராஜாவாக ஏற்படுத்துவோம் என்று சொன்னதினிமித்தம்;
	<br /><br />
	6. நீ பயப்படாமல் அமர்ந்திருக்கப் பார்; இந்த இரண்டு புகைகிற கொள்ளிக்கட்டைகளாகிய சீரியரோடே வந்த ரேத்சீனும், ரெமலியாவின் மகனும் கொண்ட உக்கிரக்கோபத்தினிமித்தம் உன் இருதயம் துவளவேண்டாம்.
	<br /><br />
	7. கர்த்தராகிய ஆண்டவர்: அந்த ஆலோசனை நிலைநிற்பதில்லை, அதின்படி சம்பவிப்பதுமில்லை;
	<br /><br />
	8. சீரியாவின் தலை தமஸ்கு, தமஸ்குவின் தலை ரேத்சீன்; இன்னும் அறுபத்தைந்து வருஷங்களிலே எப்பிராயீம் ஒரு ஜனமாயிராதபடிக்கு நொறுங்குண்டுபோம்.
	<br /><br />
	9. எப்பிராயீமின் தலை சமாரியா, சமாரியாவின் தலை ரெமலியாவின் மகன்; நீங்கள் விசுவாசியாவிட்டால் நிலைபெறமாட்டீர்கள் என்று சொல் என்றார்.
	<br /><br />
	10. பின்னும் கர்த்தர் ஆகாசை நோக்கி:
	<br /><br />
	11. நீ உன் தேவனாகிய கர்த்தரிடத்தில் ஒரு அடையாளத்தை வேண்டிக்கொள்; அதை ஆழத்திலிருந்தாகிலும், உன்னதத்திலிருந்தாகிலும் உண்டாகக் கேட்டுக்கொள் என்று சொன்னார்.
	<br /><br />
	12. ஆகாசோ: நான் கேட்கமாட்டேன், நான் கர்த்தரைப் பரீட்சை செய்யமாட்டேன் என்றான்.
	<br /><br />
	13. அப்பொழுது ஏசாயா: தாவீதின் வம்சத்தாரே, கேளுங்கள்; நீங்கள் மனுஷரை விசனப்படுத்துகிறது போதாதென்று என் தேவனையும் விசனப்படுத்தப் பார்க்கிறீர்களோ?
	<br /><br />
	14. ஆதலால் ஆண்டவர் தாமே உங்களுக்கு ஒரு அடையாளத்தைக் கொடுப்பார்; இதோ, ஒரு கன்னிகை கர்ப்பவதியாகி ஒரு குமாரனைப் பெறுவாள், அவருக்கு இம்மானுவேல் என்று பேரிடுவாள்.
	<br /><br />
	15. தீமையை வெறுத்து நன்மையைத் தெரிந்துகொள்ள அறியும் வயதுமட்டும் அவர் வெண்ணெயையும் தேனையும் சாப்பிடுவார்.
	<br /><br />
	16. அந்தப் பிள்ளை தீமையை வெறுக்கவும், நன்மையைத் தெரிந்துகொள்ளவும் அறிகிறதற்குமுன்னே, நீ அருவருக்கிற தேசம் அதின் இரண்டு ராஜாக்களால் விட்டுவிடப்படும்.
	<br /><br />
	17. எப்பிராயீம் யூதாவைவிட்டுப் பிரிந்த நாள்முதல் வராத நாட்களைக் கர்த்தர் உன்மேலும், உன் ஜனத்தின்மேலும், உன் பிதாவுடைய வம்சத்தின்மேலும், அசீரியாவின் ராஜாவினாலே வரப்பண்ணுவார்.
	<br /><br />
	18. அந்நாட்களிலே, கர்த்தர் எகிப்து நதிகளின் கடையாந்தரத்திலுள்ள ஈயையும், அசீரியா தேசத்திலிருக்கும் தேனீயையும் பயில்காட்டி அழைப்பார்.
	<br /><br />
	19. அவைகள் வந்து ஏகமாய் வனாந்தரங்களின் பள்ளத்தாக்குகளிலும், கன்மலைகளின் வெடிப்புகளிலும், எல்லா முட்காடுகளிலும், மேய்ச்சலுள்ள எல்லா இடங்களிலும் தங்கும்.
	<br /><br />
	20. அக்காலத்திலே ஆண்டவர் கூலிக்குவாங்கின சவரகன் கத்தியினால், அதாவது, நதியின் அக்கரையிலுள்ள அசீரியா ராஜாவினால், தலைமயிரையும் கால்மயிரையும் சிரைப்பித்து, தாடியையும் வாங்கிப்போடுவிப்பார்.
	<br /><br />
	21. அக்காலத்தில் ஒருவன் ஒரு இளம்பசுவையும், இரண்டு ஆடுகளையும் வளர்த்தால்,
	<br /><br />
	22. அவைகள் பூரணமாய்ப் பால் கறக்கிறபடியினால் வெண்ணெயைச் சாப்பிடுவான்; தேசத்தின் நடுவில் மீதியாயிருப்பவனெவனும் வெண்ணெயையும் தேனையுமே சாப்பிடுவான்.
	<br /><br />
	23. அந்நாளிலே, ஆயிரம் வெள்ளிக்காசு பெறும் ஆயிரம் திராட்சச்செடியிருந்த நிலமெல்லாம் முட்செடியும் நெரிஞ்சிலுமாகும்.
	<br /><br />
	24. தேசமெங்கும் முட்செடியும் நெரிஞ்சிலும் உண்டாயிருப்பதினால், அம்புகளையும் வில்லையும் பிடித்து அங்கே போகவேண்டியதாயிருக்கும்.
	<br /><br />
	25. மண்வெட்டியால் கொத்தப்படுகிற மலைகள் உண்டே; முட்செடிகளுக்கும் நெரிஞ்சில்களுக்கும் பயப்படுவதினால் அவைகளில் ஒன்றிற்கும் போகக் கூடாமையினால், அவைகள் மாடுகளை ஓட்டிவிடுவதற்கும், ஆடுகள் மிதிப்பதற்குமான இடமாயிருக்கும் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah7