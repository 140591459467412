import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 15 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 15 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 15 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அன்றியும், சகோதரரே, நான் உங்களுக்குப் பிரசங்கித்த சுவிசேஷத்தை மறுபடியும் உங்களுக்குத் தெரியப்படுத்துகிறேன்; நீங்களும் அதை ஏற்றுக்கொண்டு, அதிலே நிலைத்திருக்கிறீர்கள்.
	<br /><br />
	2. நான் உங்களுக்குப் பிரசங்கித்தபிரகாரமாய், நீங்கள் அதைக் கைக்கொண்டிருந்தால், அதினாலே நீங்கள் இரட்சிக்கப்படுவீர்கள்; மற்றப்படி உங்கள் விசுவாசம் விருதாவாயிருக்குமே.
	<br /><br />
	3. நான் அடைந்ததும் உங்களுக்குப் பிரதானமாக ஒப்புவித்ததும் என்னவென்றால், கிறிஸ்துவானவர் வேதவாக்கியங்களின்படி நமது பாவங்களுக்காக மரித்து,
	<br /><br />
	4. அடக்கம்பண்ணப்பட்டு, வேதவாக்கியங்களின்படி மூன்றாம் நாளில் உயிர்த்தெழுந்து,
	<br /><br />
	5. கேபாவுக்கும், பின்பு பன்னிருவருக்கும் தரிசனமானார்.
	<br /><br />
	6. அதன்பின்பு அவர் ஐந்நூறுபேருக்கு அதிகமான சகோதரருக்கும் ஒரேவேளையில் தரிசனமானார்; அவர்களில் அநேகர் இந்நாள்வரைக்கும் இருக்கிறார்கள், சிலர் மாத்திரம் நித்திரையடைந்தார்கள்.
	<br /><br />
	7. பின்பு யாக்கோபுக்கும், அதன்பின்பு அப்போஸ்தலரெல்லாருக்கும் தரிசனமானார்.
	<br /><br />
	8. எல்லாருக்கும் பின்பு, அகாலப்பிறவிபோன்ற எனக்கும் தரிசனமானார்.
	<br /><br />
	9. நான் அப்போஸ்தலரெல்லாரிலும் சிறியவனாயிருக்கிறேன்; தேவனுடைய சபையைத் துன்பப்படுத்தினதினாலே, நான் அப்போஸ்தலனென்று பேர்பெறுவதற்கும் பாத்திரன் அல்ல.
	<br /><br />
	10. ஆகிலும் நான் இருக்கிறது தேவகிருபையினாலே இருக்கிறேன்; அவர் எனக்கு அருளிய கிருபை விருதாவாயிருக்கவில்லை; அவர்களெல்லாரிலும் நான் அதிகமாய்ப் பிரயாசப்பட்டேன்; ஆகிலும் நான் அல்ல, என்னுடனே இருக்கிற தேவகிருபையே அப்படிச் செய்தது.
	<br /><br />
	11. ஆகையால் நானாகிலும் அவர்களாகிலும் இப்படியே பிரசங்கித்துவருகிறோம், நீங்களும் இப்படியே விசுவாசித்திருக்கிறீர்கள்.
	<br /><br />
	12. கிறிஸ்து மரித்தோரிலிருந்து எழுந்தாரென்று பிரசங்கிக்கப்பட்டிருக்க, மரித்தோரின் உயிர்த்தெழுதலில்லையென்று உங்களில் சிலர் எப்படிச் சொல்லலாம்?
	<br /><br />
	13. மரித்தோரின் உயிர்த்தெழுதல் இல்லாவிட்டால், கிறிஸ்துவும் எழுந்திருக்கவில்லையே.
	<br /><br />
	14. கிறிஸ்து எழுந்திருக்கவில்லையென்றால், எங்கள் பிரசங்கமும் விருதா, உங்கள் விசுவாசமும் விருதா.
	<br /><br />
	15. மரித்தோர் உயிர்த்தெழாவிட்டால், தேவன் எழுப்பாத கிறிஸ்துவை அவர் எழுப்பினாரென்று நாங்கள் தேவனைக்குறித்துச் சாட்சிசொன்னதினாலே, தேவனுக்காகப் பொய்ச்சாட்சி சொல்லுகிறவர்களாகவும் காணப்படுவோமே.
	<br /><br />
	16. மரித்தோர் உயிர்த்தெழாவிட்டால், கிறிஸ்துவும் எழுந்திருக்கவில்லை.
	<br /><br />
	17. கிறிஸ்து எழுந்திராவிட்டால், உங்கள் விசுவாசம் வீணாயிருக்கும்; நீங்கள் இன்னும் உங்கள் பாவங்களில் இருப்பீர்கள்.
	<br /><br />
	18. கிறிஸ்துவுக்குள் நித்திரையடைந்தவர்களும் கெட்டிருப்பார்களே.
	<br /><br />
	19. இம்மைக்காகமாத்திரம் நாம் கிறிஸ்துவின்மேல் நம்பிக்கையுள்ளவர்களாயிருந்தால், எல்லா மனுஷரைப்பார்க்கிலும் பரிதபிக்கப்படத்தக்கவர்களாயிருப்போம்.
	<br /><br />
	20. கிறிஸ்துவோ மரித்தோரிலிருந்தெழுந்து, நித்திரையடைந்தவர்களில் முதற்பலனானார்.
	<br /><br />
	21. மனுஷனால் மரணம் உண்டானபடியால், மனுஷனால் மரித்தோரின் உயிர்த்தெழுதலும் உண்டாயிற்று.
	<br /><br />
	22. ஆதாமுக்குள் எல்லாரும் மரிக்கிறதுபோல, கிறிஸ்துவுக்குள் எல்லாரும் உயிர்ப்பிக்கப்படுவார்கள்.
	<br /><br />
	23. அவனவன் தன்தன் வரிசையிலே உயிர்ப்பிக்கப்படுவான், முதற்பலனானவர் கிறிஸ்து; பின்பு அவர் வருகையில் அவருடையவர்கள் உயிர்ப்பிக்கப்படுவார்கள்.
	<br /><br />
	24. அதன்பின்பு முடிவு உண்டாகும்; அப்பொழுது அவர் சகல துரைத்தனத்தையும் சகல அதிகாரத்தையும் வல்லமையையும் பரிகரித்து, தேவனும் பிதாவுமாயிருக்கிறவருக்கு ராஜ்யத்தை ஒப்புக்கொடுப்பார்.
	<br /><br />
	25. எல்லாச் சத்துருக்களையும் தமது பாதத்திற்குக் கீழாக்கிப்போடும்வரைக்கும், அவர் ஆளுகைசெய்யவேண்டியது.
	<br /><br />
	26. பரிகரிக்கப்படுங் கடைசிச் சத்துரு மரணம்.
	<br /><br />
	27. சகலத்தையும் அவருடைய பாதத்திற்குக் கீழ்ப்படுத்தினாரே; ஆகிலும் சகலமும் அவருக்குக் கீழ்ப்படுத்தப்பட்டதென்று சொல்லியிருக்கும்போது, சகலத்தையும் அவருக்குக் கீழ்ப்படுத்தினவர் கீழ்ப்படுத்தப்படவில்லையென்பது வெளியரங்கமாயிருக்கிறது.
	<br /><br />
	28. சகலமும் அவருக்குக் கீழ்ப்பட்டிருக்கும்போது, தேவனே சகலத்திலும் சகலமுமாயிருப்பதற்கு, குமாரன் தாமும் தமக்குச் சகலத்தையும் கீழ்ப்படுத்தினவருக்குக் கீழ்ப்பட்டிருப்பார்.
	<br /><br />
	29. மேலும் மரித்தோர் உயிர்த்தெழாவிட்டால், மரித்தவர்களுக்காக ஞானஸ்நானம் பெறுகிறவர்கள் என்ன செய்வார்கள்? மரித்தவர்களுக்காக ஏன் ஞானஸ்நானம் பெறுகிறார்கள்?
	<br /><br />
	30. நாங்களும் ஏன் எந்நேரமும் நாசமோசத்திற்கு ஏதுவாயிருக்கிறோம்?
	<br /><br />
	31. நான் அநுதினமும் சாகிறேன்; அதை நம்முடைய கர்த்தராகிய கிறிஸ்து இயேசுவினால் உங்களைக்குறித்து, நான் பாராட்டுகிற மேன்மையைக்கொண்டு சத்தியமாய்ச் சொல்லுகிறேன்.
	<br /><br />
	32. நான் எபேசுவிலே துஷ்டமிருகங்களுடனே போராடினேனென்று மனுஷர் வழக்கமாய்ச் சொல்லுகிறேன்; அப்படிப் போராடினதினாலே எனக்குப் பிரயோஜனமென்ன? மரித்தோர் உயிர்த்தெழாவிட்டால், புசிப்போம் குடிப்போம், நாளைக்குச் சாவோம் என்று சொல்லலாமே?
	<br /><br />
	33. மோசம்போகாதிருங்கள்; ஆகாத சம்பாஷணைகள் நல்லொழுக்கங்களைக் கெடுக்கும்.
	<br /><br />
	34. நீங்கள் பாவஞ்செய்யாமல் நீதிக்கேற்க விழித்துக்கொண்டு, தெளிந்தவர்களாயிருங்கள்; சிலர் தேவனைப்பற்றி அறிவில்லாதிருக்கிறார்களே; உங்களுக்கு வெட்கமுண்டாக இதைச் சொல்லுகிறேன்.
	<br /><br />
	35. ஆகிலும், மரித்தோர் எப்படி எழுந்திருப்பார்கள், எப்படிப்பட்ட சரீரத்தோடே வருவார்களென்று ஒருவன் சொல்வானாகில்,
	<br /><br />
	36. புத்தியீனனே, நீ விதைக்கிற விதை செத்தாலொழிய உயிர்க்கமாட்டாதே.
	<br /><br />
	37. நீ விதைக்கிறபோது, இனி உண்டாகும் மேனியை விதையாமல், கோதுமை, அல்லது மற்றொரு தானியத்தினுடைய வெறும் விதையையே விதைக்கிறாய்.
	<br /><br />
	38. அதற்குத் தேவன் தமது சித்தத்தின்படியே மேனியைக் கொடுக்கிறார்; விதை வகைகள் ஒவ்வொன்றிற்கும் அததற்கேற்ற மேனியையே கொடுக்கிறார்.
	<br /><br />
	39. எல்லா மாம்சமும் ஒரேவிதமான மாம்சமல்ல; மனுஷருடைய மாம்சம் வேறே, மிருகங்களுடைய மாம்சம் வேறே, மச்சங்களுடைய மாம்சம் வேறே, பறவைகளுடைய மாம்சம் வேறே.
	<br /><br />
	40. வானத்துக்குரிய மேனிகளுமுண்டு, பூமிக்குரிய மேனிகளுமுண்டு; வானத்துக்குரிய மேனிகளுடைய மகிமையும் வேறே, பூமிக்குரிய மேனிகளுடைய மகிமையும் வேறே;
	<br /><br />
	41. சூரியனுடைய மகிமையும் வேறே, சந்திரனுடைய மகிமையும் வேறே, நட்சத்திரங்களுடைய மகிமையும் வேறே; மகிமையிலே நட்சத்திரத்துக்கு நட்சத்திரம் விசேஷித்திருக்கிறது.
	<br /><br />
	42. மரித்தோரின் உயிர்த்தெழுதலும் அப்படியே இருக்கும். அழிவுள்ளதாய் விதைக்கப்படும், அழிவில்லாததாய் எழுந்திருக்கும்;
	<br /><br />
	43. கனவீனமுள்ளதாய் விதைக்கப்படும், மகிமையுள்ளதாய் எழுந்திருக்கும்; பலவீனமுள்ளதாய் விதைக்கப்படும், பலமுள்ளதாய் எழுந்திருக்கும்.
	<br /><br />
	44. ஜென்மசரீரம் விதைக்கப்படும், ஆவிக்குரிய சரீரம் எழுந்திருக்கும்; ஜென்ம சரீரமுமுண்டு, ஆவிக்குரிய சரீரமுமுண்டு.
	<br /><br />
	45. அந்தப்படியே முந்தின மனுஷனாகிய ஆதாம் ஜீவாத்துமாவானான் என்றெழுதியிருக்கிறது; பிந்தின ஆதாம் உயிர்ப்பிக்கிற ஆவியானார்.
	<br /><br />
	46. ஆகிலும் ஆவிக்குரிய சரீரம் முந்தினதல்ல, ஜென்மசரீரமே முந்தினது; ஆவிக்குரிய சரீரம் பிந்தினது.
	<br /><br />
	47. முந்தின மனுஷன் பூமியிலிருந்துண்டான மண்ணானவன்; இரண்டாம் மனுஷன் வானத்திலிருந்து வந்த கர்த்தர்.
	<br /><br />
	48. மண்ணானவன் எப்படிப்பட்டவனோ மண்ணானவர்களும் அப்படிப்பட்டவர்களே; வானத்துக்குரியவர் எப்படிப்பட்டவரோ, வானத்துக்குரியவர்களும் அப்படிப்பட்டவர்களே.
	<br /><br />
	49. மேலும் மண்ணானவனுடைய சாயலை நாம் அணிந்திருக்கிறதுபோல, வானவருடைய சாயலையும் அணிந்துகொள்வோம்.
	<br /><br />
	50. சகோதரரே, நான் சொல்லுகிறதென்னவெனில், மாம்சமும் இரத்தமும் தேவனுடைய ராஜ்யத்தைச் சுதந்தரிக்கமாட்டாது; அழிவுள்ளது அழியாமையைச் சுதந்தரிப்பதுமில்லை.
	<br /><br />
	51. இதோ, ஒரு இரகசியத்தை உங்களுக்கு அறிவிக்கிறேன்; நாமெல்லாரும் நித்திரையடைவதில்லை; ஆகிலும் கடைசி எக்காளம் தொனிக்கும்போது, ஒரு நிமிஷத்திலே, ஒரு இமைப்பொழுதிலே, நாமெல்லாரும் மறுரூபமாக்கப்படுவோம்.
	<br /><br />
	52. எக்காளம் தொனிக்கும், அப்பொழுது மரித்தோர் அழிவில்லாதவர்களாய் எழுந்திருப்பார்கள்; நாமும் மறுரூபமாக்கப்படுவோம்.
	<br /><br />
	53. அழிவுள்ளதாகிய இது அழியாமையையும், சாவுக்கேதுவாகிய இது சாவாமையையும் தரித்துக்கொள்ளவேண்டும்.
	<br /><br />
	54. அழிவுள்ளதாகிய இது அழியாமையையும், சாவுக்கேதுவாகிய இது சாவாமையையும் தரித்துக்கொள்ளும்போது, மரணம் ஜெயமாக விழுங்கப்பட்டது என்று எழுதியிருக்கிற வார்த்தை நிறைவேறும்.
	<br /><br />
	55. மரணமே! உன் கூர் எங்கே? பாதாளமே! உன் ஜெயம் எங்கே?
	<br /><br />
	56. மரணத்தின் கூர் பாவம், பாவத்தின் பெலன் நியாயப்பிரமாணம்.
	<br /><br />
	57. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினாலே நமக்கு ஜெயங்கொடுக்கிற தேவனுக்கு ஸ்தோத்திரம்.
	<br /><br />
	58. ஆகையால், எனக்குப் பிரியமான சகோதரரே, கர்த்தருக்குள் நீங்கள் படுகிற பிரயாசம் விருதாவாயிராதென்று அறிந்து, நீங்கள் உறுதிப்பட்டவர்களாயும், அசையாதவர்களாயும், கர்த்தருடைய கிரியையிலே எப்பொழுதும் பெருகுகிறவர்களாயும் இருப்பீர்களாக.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians15