import React from 'react';
import '../.././App.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Zechariah (){
return (
    <>
    <Helmet>
        <title> Tamil Bible | சகரியா  </title>
        <meta
          name="description"
          content="Zechariah | சகரியா   |
          Sagariya Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
      </Helmet>
    <div className='body'>
        <h2 className='chapterh' align="center"> சகரியா </h2>
        <div className='chapter'>
        <div className='introduction'>
        <p style={{"text-align": "justify"}} className='article content'>
            {/* Introductions of Tamilbible verses */}
			<h1>சகரியா</h1>
<h2>1. அமைப்பு:</h2>
<p>சகரியாவின் புத்தகத்தைப் புரிந்துகொள்வதற்கு, அதன் அமைப்பைக்குறித்த அறிதல் மிகவும் அவசியமானதாக இருக்கிறது. இதுவும் எருசலேமில் நடைபெற்ற சம்பவம்தான். சிறையிருப்பிலிருந்து திரும்பிவந்த யூதர்கள் தேவாலயத்தைக் கட்டும் பணிக்காக வந்திருந்தார்கள். அந்தநேரத்தில் எருசலேம் ஒரு அழகான நகரமாக அல்ல, அக்கினியால் சுட்டெரிக்கப்பட்டு, அலங்கோலமாக இருந்தது. தேவாலயத்தைக் கட்ட ஆரம்பித்து, வேலை நடைபெறாமல் கைவிடப்பட்டிருந்தது. 14 ஆண்டுகள் தேவாலயத்தைக் கட்டும்பணி எதுவுமே நடைபெறவில்லை. ஆனாலும் தேவன் அவர்களை மறந்துவிடவில்லை. கி.மு.520ல் தம்முடைய மக்களை ஊக்கப்படுத்துவதற்காக ஆகாய், சகரியா என்ற இரண்டு தீர்க்கதரிசிகளைத் தேவன் எழுப்பினார்.</p>
<p>எஸ்றா-5: 1 அப்பொழுது ஆகாய் தீர்க்கதரிசியும், இத்தோவின் குமாரனாகிய சகரியா என்னும் தீர்க்கதரிசியும், யூதாவிலும் எருசலேமிலுமுள்ள யூதருக்கு இஸ்ரவேல் தேவனின் நாமத்திலே தீர்க்கதரிசனம் சொன்னார்கள்.</p>
<p>எஸ்றா-6: 14 அப்படியே யூதரின் மூப்பர் கட்டினார்கள், தீர்க்கதரிசியாகிய ஆகாயும் இத்தோவின் குமாரனாகிய சகரியாவும் தீர்க்கதரிசனம் சொல்லிவந்தபடியினால் அவர்களுடைய காரியம் கைகூடி வந்தது.</p>
<p>ஆகாய் தீர்க்கதரிசியைப்போல, சகாரியாவும் தேவாலயத்தைக் கட்டுவதில் மக்களை ஊக்கப்படுத்துகிற ஊழியத்தைச் செய்தபோதிலும், சகரியாவின் புத்தகம் தேவாலயத்திற்கும் மேலாக, கடைசிக்காலத்தோடு சம்பந்தப்பட்ட பல சத்தியங்களை உள்ளடக்கிய ஒரு விசேஷித்த புத்தகமாக இருக்கிறது.</p>
<p>&dagger;சகரியா புத்தகத்தின் 1 முதல் 8 அதிகாரங்கள் ஆகாய் தீர்க்கதரிசியின் நாட்களிலே, அதாவது கி.மு.520 முதல் 518ல் நடந்தவைகளாகும்,</p>
<p>&dagger;ஆனால் அதிகாரங்கள் 9 முதல் 14, தேவாலயம் கட்டி முடிக்கப்பட்டபிறகு கி.மு.480 முதல் 470ல் நடை பெற்றவைகளாகும்.</p>
<h2>2. சகரியா:</h2>
<p>சகரியா என்ற பெயருக்கு யெகோவா நினைவுகூருகிறார் என்று அர்த்தமாகும். அதாவது தேவன் மறந்துவிடவில்லை. சகரியா என்ற பெயர் வேதாகமத்திலே மிகவும் பிரபல்யமானதாக இருக்கிறது. இந்த பெயரோடே 29 நபர்கள் வேதாகமத்தில் குறிப்பிடப் பட்டிருக்கிறார்கள்.</p>
<h5>உதாரணத்திற்கு</h5>
<p>யோவான் ஸ்நானகனுடைய தகப்பன் பெயர் சகரியா ஆகும். நம்மை நினைவுகூருகிற தேவனையே நாம் ஆராதிக்கிறோம். அவர் நம்மை மறப்பதில்லை. சகரியா ஆகாயின் நாட்களில் வாழ்ந்த ஒரு இளம் தீர்க்கதரிசியாவார். இவர் பாபிலோனிலே பிறந்து வளர்ந்தவராக இருந்ததால், எருசலேமை அவர் முன்பு பார்த்திருந்தது கிடையாது. தரியு அரசாண்ட இரண்டாம் வருஷம் எட்டாம் மாதத்திலே இத்தோவின் மகனான பெரகியாவின் குமாரனாகிய சகரியாவுக்கு உண்டான கர்த்தருடைய வார்த்தை என்று சகரி-1: 1 சொல்கிறது. எனவே சகரியாவின் தகப்பன் பெரகியா, சகரியாவின் தாத்தா இத்தோ ஆவார்கள். அவர்கள் ஆசாரியர்காளாவார்கள். ஆசாரியக் குடும்பத்தில் வளர்ந்த சகரியா ஆவிக்குரிய தன்மையுடையவராக இருந்தார்.</p>
<h2>3. செய்தி:</h2>
<p>3 வேறுபட்ட தேதிகளில் கொடுக்கப்பட்ட, 3 தனிப்பட்ட தீர்க்கதரிசனங்களோடு சகரியாவின் புத்தகம் ஆரம்பிக்கிறது.</p>
<p>↻ சகரி-1: 1 யூதர்கள் மறுபடியும் வேலையை ஆரம்பித்த 2 மாதங்களில் (ஆகா-1: 15)</p>
<p>↻ சகரி-1: 7 மூன்று மாதங்களுக்குப் பிறகு, யூதர்கள் தேவாலயத்திற்கு மீண்டும் அஸ்திபாரமிட்ட 2ஆவது மாதத்தில் (ஆகாய்-2: 18)</p>
<p>↻ சகரியா-7, 8 அதிகாரங்கள் (இரண்டு ஆண்டுகளுக்குப் பிறகு கொடுக்கப்பட்டவைகள்)</p>
<p>தேவாலயத்தைக் கட்டி முடிக்கவேண்டும், தேவாலயம் வருங்காலத்தில் முக்கியமான ஒன்றாக இருக்கிறது என்பதை இந்தப் புத்தகத்தின் செய்திகள் வலியுறுத்துகின்றன.</p>
<h2>சகாரியா புத்தகத்தின் தொகுப்பு:</h2>
<p>(மொத்தம் 14 அதிகாரங்கள், 5 பகுதிகளாகப் பிரிக்கலாம்)</p>
<ol>
<li>அதிகாரம்-1: 1-6 முதலாவது தீர்க்கதரிசனம்: மனந்திரும்புவதற்கான அழைப்பு</li>
</ol>
<p>சகரி-1: 3 ஆகையால் நீ அவர்களை நோக்கி: சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால்: என்னிடத்தில் திரும்புங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார், அப்பொழுது நான் உங்களிடத்துக்குத் திரும்புவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.</p>
<ol>
<li>அதிகாரம்-1: 7 முதல் 6: இரண்டாவது தீர்க்கதரிசனம்: 8 தரிசனங்களும், ஆசாரியனின் ஆளுகையும்</li>
<li>மிருதுச் செடிகளுக்குள்ளே நின்ற குதிரைகள் (1: 7-17), அதின்மேல் ஒரு புருஷன்:</li>
</ol>
<p>இந்த நபர் இயேசுவைப் பிரதபலிக்கிறது.</p>
<ol start="2">
<li>நான்கு கொம்புகளும், நான்கு தொழிலாளிகளும் (1: 18-21)</li>
</ol>
<p>4 கொம்புகள் 4 புறஜாதி ராஜ்யங்களையும், அவைகள் 4ம் முறியடிக்கப் படுவதையும் அது விவரிக்கிறது. தானியேலின் புத்தகத்தில் 4 சாம்ராஜ்யங் களைக்குறித்த விபரங்களை நாம் பார்க்கிறோம். பாபிலோன், மேதியா-பெர்சிய, கிரேக்கு, ரோம். முதல் 3 சாம்ராஜ்யங்கள், அடுத்தடுத்து வந்த சாம்ராஜ்யஙகளால் முறியடிக்கப் பட்டுவிட்டன. ஆனால் 4ஆவதாக வந்த ரோமசாம்ராஜ்த்தின் தாக்கம் இன்னும் முற்றிலுமாக முறியடிக்கப் படவில்லை. இயேசு கிறிஸ்துவின் 2ஆம் வருகையின்போது அது முற்றிலும் அழிக்கப்படும்.</p>
<ol start="3">
<li>கையிலே அளவுநூல் பிடித்திருந்த ஒரு மனிதன் (2: 1-13)</li>
</ol>
<p>இந்தப் பகுதி, எருசலேமின்மீது ரஷ்யப் படையெடுப்பைக் குறித்துச் சொல்கிறது. அதைக்குறித்து வெளிப்படுத்தின விசேஷத்தில் நாம் பார்க்கிறோம்.</p>
<ol start="4">
<li>யோசுவாவின் சுத்திகரிப்பு (3: 1-10)</li>
</ol>
<p>சகரி-3: 1-2 அவர் பிரதான ஆசாரியனாகிய யோசுவாவை எனக்குக் காண்பித்தார், அவன் கர்த்தருடைய தூதனுக்கு முன்பாக நின்றான், சாத்தான் அவனுக்கு விரோதஞ்செய்ய அவன் வலது பக்கத்திலே நின்றான். 2. அப்பொழுது கர்த்தர் சாத்தானை நோக்கி: கர்த்தர் உன்னைக் கடிந்து கொள்வாராக, சாத்தானே, எருசலேமைத் தெரிந்துகொண்ட கர்த்தர் உன்னைக் கடிந்துகொள்வாராக, இவன் அக்கினியினின்று தப்புவிக்கப்பட்ட கொள்ளி அல்லவா என்றார்.</p>
<ul>
<li>தேவதூதன்கூட சாத்தானைக் கடிந்து கொள்ளவில்லை. தேவன் உன்னைக் கடிந்து கொள்வாராக என்றே சொல்கிறான். நாம் யாரையும் கடிந்து கொள்ளக்கூடாது. யோசுவாவுக்குச் செய்யப்பட்ட காரியம் நம்முடைய இரட்சிப்பையும், சுத்திகரிப்பையும் விவரிக்கிற தீர்க்கதரிசனம் படமாகும்.</li>
</ul>
<ol start="5">
<li>பொன் குத்துவிளக்கும், 2 ஒலிவ மரங்களும் (4: 1-14)</li>
</ol>
<p>தேவனுடைய வேலையைச் செய்வதற்கு, ஆவியானவரின் பெலப்படுத்துதல் நமக்கு போதுமானதாகவும், எப்போதும் இருப்பதாகவும் வாக்களிக்கப் படுகிறது.</p>
<p>சகரி-4: 6 அப்பொழுது அவர்: செருபாபேலுக்குச் சொல்லப்படுகிற கர்த்தருடைய வார்த்தை என்னவென்றால், பலத்தினாலும் அல்ல, பராக்கிரமத்தினாலும் அல்ல, என்னுடைய ஆவியினாலேயே ஆகும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.</p>
<ol start="6">
<li>பறக்கிற ஒரு புஸ்தகச்சுருள் (5: 1-4)</li>
</ol>
<p>சகரி-5: 2 தூதன்: நீ காண்கிறது என்னவென்று கேட்டார், பறக்கிற ஒரு புஸ்தகச்சுருளைக் காண்கிறேன், அதின் நீளம் இருபது முழமும் அதின் அகலம் பத்து முழமுமாயிருக்கிறது என்றேன்.</p>
<p>புஸ்தகச் சுருளின் அளவு: 20 x 10, அதாவது 30 x 15 இஞ்சி. இது ஆசாரிப்புக் கூடாரத்தின் பரிசுத்த ஸ்தலத்தின் அதே அளவாகும்.</p>
<ol start="7">
<li>மரக்காலின் நடுவிலிருந்த ஸ்திரீ (5: 5-11)</li>
</ol>
<p>இஸ்ரவேலர்கள் பாபிலோனுக்குச் செல்வதற்குமுன் வேளாண்மை-விவசாயம் செய்தார்கள் (மரக்கால்). ஆனால் பாபிலோனுக்குச் சென்றபிறகோ, அவர்கள் பொருளாதார வியாபாரத்தில் மூழ்கிவிட்டார்கள். பணஆசை என்பது பாபிலோனின் தன்மையாகும். எனவே, பாபிலோனின் பொருளாதார வீழ்ச்சியை இது விவரிக்கிறது (வெளி-17,18)</p>
<ol start="8">
<li>நான்கு இரதங்கள் (6: 1-8)</li>
</ol>
<p>இது வெளிப்படுத்தின விசேஷம் 7ஆவது அதிகாரத்தில் வருகிற 4 குதிரை வீரர்களோடு தொடர்புடையதாக இருக்கிறது.</p>
<p>யோசுவா முடிசூட்டப்படுதல் (6: 9-15)</p>
<p>சகரி-6: 11-12 அங்கே அவர்கள் கையிலே வெள்ளியையும் பொன்னையும் வாங்கி, கிரிடங்களைச் செய்வித்து, யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனுடைய சிரசிலே வைத்து, 12. அவனோடே சொல்லவேண்டியது: சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால், இதோ, ஒரு புருஷன், அவருடைய நாமம் கிளை என்னப்படும், அவர் தம்முடைய ஸ்தானத்திலிருந்து முளைத்தெழும்பிக் கர்த்தருடைய ஆலயத்தைக் கட்டுவார்.</p>
<p>இது ஒரு அழகான ஆவிக்குரிய தீர்க்கதரிசனப் படமாகும். கிளை என்பது இயேசுவைக் குறிக்கிறது. இயேசு ஆசாரியராகவும், ராஜாவாகவும் இருப்பார். இப்படிப்பட்ட காரியம் பழைய ஏற்பாட்டுப் பிரமாணத்தில் தடைசெய்யப் பட்டிருந்தது. ஒரு நபர் ஆசாரியராக இருந்தால் அவர் ராஜாவாக முடியாது, ஒரு நபர் ராஜாவாக இருந்தால் அவர் ஆசாரியனாக முடியாது. ஆனால் பிரதான ஆசாரியராகிய இயேசுகிறிஸ்து வந்து, ராஜாவாக முடிசூட்டப் படுதலையும், தம்முடைய ஆயிர வருட அரசாட்சியில் கர்த்தருடைய ஆலயத்தை அவர் கட்டுவதையும் இது முன்னறிவிக்கிறது.</p>
<p>III. அதிகாரங்கள் 7-8 மூன்றாவது தீர்க்கதரிசனம்: 4 செய்திகள்:</p>
<p>இது தரியு ராஜாவின் 4ஆவது வருடம், 9ஆவது மாதத்தின் 4ஆம் தேதியன்று நடைபெற்றது என்பதைக் கவனியுங்கள்</p>
<h4>
	சகரி-7: 1 தரியு ராஜா அரசாண்ட நாலாம் வருஷம், கிஸ்லே என்னும் ஒன்பதாம் மாதம், நாலாந்தேதியிலே, சகரியாவுக்குக் கர்த்தருடைய வார்த்தை உண்டாயிற்று.நாம் எதற்காக உபவாசிக்கிறோம்? (7: 1-7)நமக்காகவா, வாடிக்கைக்காகவா, தேவனுக்காகவா?
</h4>
<p>
	சடங்கு முறைகளைவிட தேவன் உறவையே விரும்புகிறார் (7: 8-14)
</p>
<p>
	நாம் என்ன செய்கிறோம், எப்படிச் செய்கிறோம், எதற்காகச் செய்கிறோம், யாருக்காகச் செய்கிறோம்? (தேவன் மீதும், பிறர்மீதும் அன்பு முக்கியம்).
</p>
<p>
	எருசலேம் மீண்டும் புதுப்பிக்கப்படும் (8: 15-17)
</p>
<p>
	சகரி-8: 7-8 இதோ, கிழக்குதேசத்திலும் மேற்கு தேசத்திலுமிருந்து என் ஜனங்களை நான் இரட்சித்து, 8. அவர்களை அழைத்துக்கொண்டு வருவேன், அவர்கள் எருசலேமின் நடுவிலே குடியிருப்பார்கள், அவர்கள் எனக்கு உண்மையும் நீதியுமான ஜனமாயிருப்பவார்கள், நான் அவர்களுக்குத் தேவனாயிருப்பேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
</p>
<p>
	இயேசுவின் நாட்களில் எருசலேமிற்கு நடக்கவிருக்கும் இரட்சிப்பை இது அறிவிக்கிறது. எருசலேமில் இருப்பவர்கள செய்யவேண்டிய 4 காரியங்களை 8: 16-17 வசனங்களில் பார்க்கிறோம்.
</p>
<ol start="3">
</ol>
<p>
	சகரி-8: 16-17 நீங்கள் செய்யவேண்டிய காரியங்கள் என்னவென்றால்: அவனவன் பிறனோடே உண்மையைப் பேசுங்கள், உங்கள் வாசல்களில் சத்தியத்துக்கும் சமாதானத்துக்கும் ஏற்க நியாயந்தீருங்கள். 17. ஒருவனும் பிறனுக்கு விரோதமாய்த் தன் இருதயத்தில் தீங்கு நினையாமலும், பொய் ஆணையின்மேல் பிரியப்படாமலும் இருங்கள், இவைகளெல்லாம் நான் வெறுக்கிற காரியங்கள் என்று கர்த்தர் சொல்லுகிறார்.
</p>
<ol start="4">
	<li>
		வருங்காலத்தை நோக்கிப்பாருங்கள் (8: 18-19)
	</li>
</ol>
<p>
	சகரி-8: 19 நாலாம் மாதத்தின் உபவாசமும், ஐந்தாம் மாதத்தின் உபவாசமும், ஏழாம் மாதத்தின் உபவாசமும், பத்தாம் மாதத்தின் உபவாசமும் யூதா வம்சத்தாருக்கு மகிழ்ச்சியாகவும் சந்தோஷமாகவும் நல்ல பண்டிகைகளாகவும் மாறிப்போகும், ஆகையால் சத்தியத்தையும் சமாதானத்தையும் சிநேகியுங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
</p>
<ul>
	<li>
		அந்த நாட்களில், உங்களுடைய உபவாசங்கள் விருந்துகளாக மாற்றப்படும்.
	</li>
</ul>
<p>
	சகரி-8: 23 அநேக ஜனங்களும் பலத்த ஜாதிகளும் எருசலேமிலே சேனைகளின் கர்த்தரைத் தேடவும், கர்த்தருடைய சமுகத்தில் விண்ணப்பம் பண்ணவும் வருவார்கள்.
</p>
<ul>
	<li>
		பலதேசத்து மக்கள் கர்த்தரைத் தேடுவதற்காக எருசலேமிற்கு வருவார்கள். மக்கள் யூதர்களை நேசிப்பார்கள். அவர்கள் கர்த்தரை அறியவேண்டும் என்று ஆசைப்படுவார்கள்.
	</li>
</ul>
<ol>
	<li>
		அதிகாரங்கள் 9-11: முதலாவது பாரம்: இயேசுகிறிஸ்துவின் முதலாம் வருகை, மேசியா புறக்கணிக்கப்படுதல்: (இப்பொழுது தேவாலயம் கட்டி முடிக்கப்பட்டிருந்த நேரமாகும்).
	</li>
</ol>
<p>
	இயேசு கிறிஸ்துவின் முதலாம் வருகையையும், அவருடைய மரணத்தையும் இது வெளிப்படுத்துகிறது. மிகவும் துல்லியமான காரியங்கள் இதிலே கொடுக்கப் பட்டுள்ளன.
</p>
<p>
	சகரி-9: 1 ஆதிராக் தேசத்துக்கு விரோதமானதும், தமஸ்குவின்மேல் வந்து தங்குவதுமான கர்த்தருடைய வார்த்தையாகிய பாரம், மனுஷரின் கண்களும் இஸ்ரவேலுடைய சகல கோத்திரங்களின் கண்களும் கர்த்தரை நோக்கிக் கொண்டிருக்கும்.
</p>
<ul>
	<li>
		இஸ்ரவேல் மாத்திரமல்ல, அதைச் சுற்றியிருக்கும் மற்றதேசங்களும் நியாயந் தீர்க்கப்படும் என்பதைத் தேவன் அறிவிக்கிறார். ஆதிராக் என்பது சீரியா தேசத்தைக் குறிக்கிறது. தமஸ்கு சீரியாவின் தலைநகராகும்.
	</li>
</ul>
<p>
	சகரி-10: 1, 4 பின்மாரிகாலத்து மழையைக் கர்த்தரிடத்தில் வேண்டிக் கொள்ளுங்கள், அப்பொழுது கர்த்தர் மின்னல்களை உண்டாக்கி, வயல்வெளியில் அவரவருக்குப்ப யிருண்டாக அவர்களுக்கு மழையைக் கட்டளையிடுவார். 4. அவர்களிலிருந்து கோடிக்கல்லும், அவர்களிலிருந்து கூடாரமுளையும், அவர்களிலிருந்து யுத்தவில்லும் வரும், அவர்களிலிருந்து ஆளுகிற யாவரும் ஏகமாய்ப் புறப்படுவார்கள்.
</p>
<ul>
	<li>
		கோடிக்கல், கூடாரமுனை, யுத்தவில், யாவரையும் ஆள்பவர் என்பற வார்த்தைகள், இஸ்ர வேலரிலிருந்து ராஜாதி ராஜாவாக இயேசுகிறிஸ்து வருவதைக் குறிக்கிறது.
	</li>
</ul>
<ol>
	<li>
		அதிகாரங்கள் 12 முதல் 14: இரண்டாவது பாரம்: இயேசுகிறிஸ்துவின் 2ஆம் வருகை.
	</li>
</ol>
<p>
	சகரி-14: 1,4 இதோ, கர்த்தருடைய நாள் வருகிறது, உன்னில் கொள்ளையானது உன் நடுவிலே பங்கிடப்படும். 4. அந்நாளிலே அவருடைய பாதங்கள் கிழக்கே எருசலேமுக்கு எதிரே இருக்கிற ஒலிவமலையின்மேல் நிற்கும், அப்பொழுது மகாபெரிய பள்ளத்தாக்கு உண்டாகும்படி ஒலிவமலை தன் நடுமையத்திலே கிழக்கு மேற்காய் எதிராகப் பிளந்துபோம், அதினாலே, ஒருபாதி வடபக்கத்திலும் ஒருபாதி தென்பக்கத்திலும் சாயும்.
</p>
<p>
	இஸ்ரவேலருக்கும், தேனுடைய பிள்ளைகளாக இருக்கும் நமக்கும் மகிமையான நம்பிக்கையாக இருப்பது இதுதான். அந்திக்கிறிஸ்துவின் ஆட்சியின்போது, வடதேசத்து ராஜாக்கள் அவனுக்கு எதிராக யுத்தத்திற்கு வருதல், இறுதியில் இயேசுகிறிஸ்து வந்து அவனை அழித்தல் நடைபெறும். தம்மால் தெரிந்து கொள்ளப் பட்டவர்களை மகாஉபத்திரவத்தின் நாட்களில் தேவன் எவ்வாறு பாதுகாப்பார் என்பதை இந்தப் பகுதியிலிருந்து நாம் அறியமுடிகிறது. அந்நாளிலே என்ற வார்த்தை 22 முறை வருகிறது. புதியஏற்பாட்டில் சுமார் 721 இடங்களில் சகரியாவின் புத்தகத்திலிருந்து சுட்டிக் காட்டப்பட்டுள்ளது.
</p>
<p>
	சகரி-14: 16 பின்பு, எருசலேமுக்கு விரோதமாக வந்திருந்த எல்லா ஜாதிகளிலும் மீதியான யாவரும் சேனைகளின் கர்த்தராகிய ராஜாவைத் தொழுது கொள்ளும்படிக்கும், கூடாரப்பண்டிகையை ஆசரிக்கும்படிக்கும், வருஷாவருஷம் வருவார்கள்.
</p>
<p>
	ஆயிரவருட அரசாட்சியில் நாம் அனைவரும் இயேசுவோடிருந்து, கூடாரப் பண்டிகையைக் கொண்டாடுவோம். சகரி-14: 20 எங்கும் கர்த்தருக்குப் பரிசுத்தம் என்று எழுதியிருக்கும்.
</p>

            {/* End Introduction */}
        </p>

        </div>
        <div>
            <div className='chap-link'>
                <div className='btn'> <Link to="/Sagariya-1"> 01</Link> </div>
                <div className='btn'> <Link to="/Sagariya-2"> 02</Link> </div>
                <div className='btn'> <Link to="/Sagariya-3"> 03</Link> </div>
                <div className='btn'> <Link to="/Sagariya-4"> 04</Link> </div>
                <div className='btn'> <Link to="/Sagariya-5"> 05</Link> </div>
                <div className='btn'> <Link to="/Sagariya-6"> 06</Link> </div>
                <div className='btn'> <Link to="/Sagariya-7"> 07</Link> </div>
                <div className='btn'> <Link to="/Sagariya-8"> 08</Link> </div>
                <div className='btn'> <Link to="/Sagariya-9"> 09</Link> </div>
                <div className='btn'> <Link to="/Sagariya-10">10</Link> </div>
                <div className='btn'> <Link to="/Sagariya-11">11</Link> </div>
                <div className='btn'> <Link to="/Sagariya-12">12</Link> </div>
                <div className='btn'> <Link to="/Sagariya-13">13</Link> </div>
                <div className='btn'> <Link to="/Sagariya-14">14</Link> </div>
            </div>
        </div>
    </div>
    </div>
    </>
);
}

export default Zechariah;