import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 14 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 14
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. விசுவாசத்தில் பலவீனமுள்ளவனைச் சேர்த்துக்கொள்ளுங்கள்; ஆனாலும் அவனுடைய மன ஐயங்களைக் குற்றமாய் நிர்ணயிக்காமலிருங்கள்.
	<br /><br />
	2. ஒருவன் எந்தப் பதார்த்தத்தையும் புசிக்கலாமென்று நம்புகிறான்; பலவீனனோ மரக்கறிகளைமாத்திரம் புசிக்கிறான்.
	<br /><br />
	3. புசிக்கிறவன் புசியாதிருக்கிறவனை அற்பமாயெண்ணாதிருப்பானாக; புசியாதிருக்கிறவனும் புசிக்கிறவனைக் குற்றவாளியாகத் தீர்க்காதிருப்பானாக; தேவன் அவனை ஏற்றுக்கொண்டாரே.
	<br /><br />
	4. மற்றொருவனுடைய வேலைக்காரனைக் குற்றவாளியாகத் தீர்க்கிறதற்கு நீ யார்? அவன் நின்றாலும் விழுந்தாலும் அவனுடைய எஜமானுக்கே அவன் உத்தரவாதி; அவன் நிலைநிறுத்தப்படுவான்; தேவன் அவனை நிலைநிறுத்த வல்லவராயிருக்கிறாரே.
	<br /><br />
	5. அன்றியும், ஒருவன் ஒருநாளை மற்றொரு நாளிலும் விசேஷமாக எண்ணுகிறான்; வேறொருவன் எல்லா நாட்களையும் சரியாக எண்ணுகிறான்; அவனவன் தன்தன் மனதிலே முழு நிச்சயத்தை உடையவனாயிருக்கக்கடவன்.
	<br /><br />
	6. நாட்களை விசேஷித்துக்கொள்ளுகிறவன் கர்த்தருக்கென்று விசேஷித்துக் கொள்ளுகிறான்; நாட்களை விசேஷித்துக் கொள்ளாதவனும் கர்த்தருக்கென்று விசேஷித்துக்கொள்ளாதிருக்கிறான். புசிக்கிறவன் தேவனுக்கு ஸ்தோத்திரஞ்செலுத்துகிறபடியால், கர்த்தருக்கென்று புசிக்கிறான்; புசியாதிருக்கிறவனும் கர்த்தருக்கென்று புசியாதிருந்து, தேவனுக்கு ஸ்தோத்திரஞ் செலுத்துகிறான்.
	<br /><br />
	7. நம்மில் ஒருவனும் தனக்கென்று பிழைக்கிறதுமில்லை, ஒருவனும் தனக்கென்று மரிக்கிறதுமில்லை.
	<br /><br />
	8. நாம் பிழைத்தாலும் கர்த்தருக்கென்று பிழைக்கிறோம், நாம் மரித்தாலும் கர்த்தருக்கென்று மரிக்கிறோம்; ஆகையால் பிழைத்தாலும் மரித்தாலும் நாம் கர்த்தருடையவர்களாயிருக்கிறோம்.
	<br /><br />
	9. கிறிஸ்துவும் மரித்தோர்மேலும் ஜீவனுள்ளோர்மேலும் ஆண்டவராயிருக்கும்பொருட்டு, மரித்தும் எழுந்தும் பிழைத்துமிருக்கிறார்.
	<br /><br />
	10. இப்படியிருக்க, நீ உன் சகோதரனைக் குற்றவாளியென்று தீர்க்கிறதென்ன? நீ உன் சகோதரனை அற்பமாய் எண்ணுகிறதென்ன? நாமெல்லாரும் கிறிஸ்துவினுடைய நியாயாசனத்திற்கு முன்பாக நிற்போமே.
	<br /><br />
	11. அந்தப்படி: முழங்கால் யாவும் எனக்கு முன்பாக முடங்கும், நாவு யாவும் தேவனை அறிக்கைபண்ணும் என்று என் ஜீவனைக்கொண்டு உரைக்கிறேன் என்பதாய்க் கர்த்தர் சொல்லுகிறார் என்று எழுதியிருக்கிறது.
	<br /><br />
	12. ஆதலால் நம்மில் ஒவ்வொருவனும் தன்னைக்குறித்துத் தேவனுக்குக் கணக்கொப்புவிப்பான்.
	<br /><br />
	13. இப்படியிருக்க, நாம் இனிமேல் ஒருவரையொருவர் குற்றவாளிகளென்று தீர்க்காதிருப்போமாக. ஒருவனும் தன் சகோதரனுக்கு முன்பாகத் தடுக்கலையும் இடறலையும் போடலாகாதென்றே தீர்மானித்துக்கொள்ளுங்கள்.
	<br /><br />
	14. ஒரு பொருளும் தன்னிலே தீட்டுள்ளதல்லவென்று கர்த்தராகிய இயேசுவுக்குள் அறிந்து நிச்சயித்திருக்கிறேன்; ஒருபொருளைத் தீட்டுள்ளதென்று எண்ணிக்கொள்ளுகிறவனெவனோ அவனுக்கு அது தீட்டுள்ளதாயிருக்கும்.
	<br /><br />
	15. போஜனத்தினாலே உன் சகோதரனுக்கு விசனமுண்டாக்கினால், நீ அன்பாய் நடக்கிறவனல்ல; அவனை உன் போஜனத்தினாலே கெடுக்காதே, கிறிஸ்து அவனுக்காக மரித்தாரே.
	<br /><br />
	16. உங்கள் நன்மை தூஷிக்கப்பட இடங்கொடாதிருங்கள்.
	<br /><br />
	17. தேவனுடைய ராஜ்யம் புசிப்பும் குடிப்புமல்ல, அது நீதியும் சமாதானமும் பரிசுத்த ஆவியினாலுண்டாகும் சந்தோஷமுமாயிருக்கிறது.
	<br /><br />
	18. இவைகளிலே கிறிஸ்துவுக்கு ஊழியஞ்செய்கிறவன் தேவனுக்குப் பிரியனும் மனுஷரால் அங்கிகரிக்கப்பட்டவனுமாயிருக்கிறான்.
	<br /><br />
	19. ஆனபடியால் சமாதானத்துக்கடுத்தவைகளையும், அந்நியோந்நிய பக்திவிருத்தி உண்டாக்கத்தக்கவைகளையும் நாடக்கடவோம்.
	<br /><br />
	20. போஜனத்தினிமித்தம் தேவனுடைய கிரியையை அழித்துப்போடாதே. எந்தப் பதார்த்தமும் சுத்தமுள்ளதுதான்; ஆனாலும் இடறலுண்டாகப் புசிக்கிறவனுக்கு அது தீமையாயிருக்கும்.
	<br /><br />
	21. மாம்சம் புசிக்கிறதும், மதுபானம் பண்ணுகிறதும், மற்றெதையாகிலும் செய்கிறதும், உன் சகோதரன் இடறுகிறதற்காவது, தவறுகிறதற்காவது பலவீனப்படுகிறதற்காவது ஏதுவாயிருந்தால், அவைகளில் ஒன்றையும் செய்யாமலிருப்பதே நன்மையாயிருக்கும்.
	<br /><br />
	22. உனக்கு விசுவாசமிருந்தால் அது தேவனுக்குமுன்பாக உன்மட்டும் இருக்கட்டும். நல்லதென்று நிச்சயித்த காரியத்தில் தன்னைக் குற்றவாளியாக்காதவன் பாக்கியவான்.
	<br /><br />
	23. ஒருவன் சமுசயப்படுகிறவனாய்ப் புசித்தால், அவன் விசுவாசமில்லாமல் புசிக்கிறபடியினால், ஆக்கினைக்குள்ளாகத் தீர்க்கப்படுகிறான். விசுவாசத்தினாலே வராத யாவும் பாவமே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans14