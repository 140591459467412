import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 4 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 4 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 4 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்படியாக, எந்த மனுஷனும் எங்களைக் கிறிஸ்துவின் ஊழியக்காரரென்றும், தேவனுடைய இரகசியங்களின் உக்கிராணக்காரரென்றும் எண்ணிக்கொள்ளக்கடவன்.
	<br /><br />
	2. மேலும், உக்கிராணக்காரன் உண்மையுள்ளவனென்று காணப்படுவது அவனுக்கு அவசியமாம்.
	<br /><br />
	3. ஆயினும் நான் உங்களாலேயாவது மனுஷருடைய நியாயநாளின் விசாரணையினாலேயாவது தீர்ப்பைப் பெறுவது எனக்கு மிகவும் அற்ப காரியமாயிருக்கிறது; நானும் என்னைக்குறித்துத் தீர்ப்புச்சொல்லுகிறதில்லை.
	<br /><br />
	4. என்னிடத்தில் நான் யாதொரு குற்றத்தையும் அறியேன்; ஆகிலும் அதினாலே நான் நீதிமானாகிறதில்லை; என்னை நியாயம் விசாரிக்கிறவர் கர்த்தரே.
	<br /><br />
	5. ஆனதால், கர்த்தர் வருமளவும் நீங்கள் காலத்துக்குமுன்னே யாதொன்றைக்குறித்தும் தீர்ப்புச்சொல்லாதிருங்கள்; இருளில் மறைந்திருக்கிறவைகளை அவர் வெளியரங்கமாக்கி, இருதயங்களின் யோசனைகளையும் வெளிப்படுத்துவார்; அப்பொழுது அவனவனுக்குரிய புகழ்ச்சி தேவனால் உண்டாகும்.
	<br /><br />
	6. சகோதரரே, எழுதப்பட்டதற்கு மிஞ்சி எண்ணவேண்டாமென்று நீங்கள் எங்களாலே கற்றுக்கொள்ளவும், ஒருவனும் ஒருவனிமித்தம் மற்றொருவனுக்கு விரோதமாய் இறுமாப்படையாதிருக்கவும், நான் உங்கள்நிமித்தம் என்னையும் அப்பொல்லோவையும் திருஷ்டாந்தமாக வைத்து, இவைகளை எழுதினேன்.
	<br /><br />
	7. அன்றியும் உன்னை விசேஷித்தவனாகும்படி செய்கிறவர் யார்? உனக்கு உண்டாயிருக்கிறவைகளில் நீ பெற்றுக்கொள்ளாதது யாது? நீ பெற்றுக்கொண்டவனானால் பெற்றுக்கொள்ளாதவன்போல் ஏன் மேன்மைபாராட்டுகிறாய்?
	<br /><br />
	8. இப்பொழுது திருப்தியடைந்திருக்கிறீர்களே, இப்பொழுது ஐசுவரியவான்களாயிருக்கிறீர்களே, எங்களையல்லாமல் ஆளுகிறீர்களே; நீங்கள் ஆளுகிறவர்களானால் நலமாயிருக்கும்; அப்பொழுது உங்களுடனேகூட நாங்களும் ஆளுவோமே.
	<br /><br />
	9. எங்களுக்குத் தோன்றுகிறபடி தேவன் அப்போஸ்தலர்களாகிய எங்களை மரணத்துக்குக் குறிக்கப்பட்டவர்கள்போலக் கடைசியானவர்களாய்க் காணப்படப்பண்ணினார்; நாங்கள் உலகத்துக்கும் தூதருக்கும் மனுஷருக்கும் வேடிக்கையானோம்.
	<br /><br />
	10. நாங்கள் கிறிஸ்துவினிமித்தம் பைத்தியக்காரர், நீங்கள் கிறிஸ்துவில் புத்திசாலிகள்; நாங்கள் பலவீனர், நீங்கள் பலவான்கள்; நீங்கள் கனவான்கள், நாங்கள் கனவீனர்.
	<br /><br />
	11. இந்நேரம்வரைக்கும் பசியுள்ளவர்களும், தாகமுள்ளவர்களும், நிர்வாணிகளும், குட்டுண்டவர்களும், தங்க இடமில்லாதவர்களுமாயிருக்கிறோம்.
	<br /><br />
	12. எங்கள் கைகளினாலே வேலைசெய்து, பாடுபடுகிறோம்; வையப்பட்டு, ஆசீர்வதிக்கிறோம்; துன்பப்பட்டு, சகிக்கிறோம்.
	<br /><br />
	13. தூஷிக்கப்பட்டு, வேண்டிக்கொள்ளுகிறோம்; இந்நாள்வரைக்கும் உலகத்தின் குப்பையைப்போலவும், எல்லாரும் துடைத்துப்போடுகிற அழுக்கைப்போலவுமானோம்.
	<br /><br />
	14. உங்களை வெட்கப்படுத்தும்படிக்கு நான் இவைகளை எழுதவில்லை, நீங்கள் எனக்குப் பிரியமான பிள்ளைகளென்று உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	15. கிறிஸ்துவுக்குள் பதினாயிரம் உபாத்தியாயர்கள் உங்களுக்கு இருந்தாலும், தகப்பன்மார் அநேகர் உங்களுக்கு இல்லையே; கிறிஸ்து இயேசுவுக்குள் சுவிசேஷத்தினால் நான் உங்களைப் பெற்றேன்.
	<br /><br />
	16. ஆகையால், என்னைப் பின்பற்றுகிறவர்களாகுங்களென்று உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	17. இதினிமித்தமாக, எனக்குப் பிரியமும், கர்த்தருக்குள் உண்மையுமுள்ள என் குமாரனாகிய தீமோத்தேயுவை உங்களிடத்தில் அனுப்பினேன்; நான் எங்கும் எந்தச் சபையிலும் போதித்துவருகிறபிரகாரம் கிறிஸ்துவுக்குள்ளான என் நடக்கைகளை அவன் உங்களுக்கு ஞாபகப்படுத்துவான்.
	<br /><br />
	18. நான் உங்களிடத்திற்கு வருகிறதில்லை என்கிறதாகச் சிலர் இறுமாப்படைந்திருக்கிறார்கள்.
	<br /><br />
	19. ஆகிலும் கர்த்தருக்குச் சித்தமானால் நான் சீக்கிரமாய் உங்களிடத்திற்கு வந்து, இறுமாப்படைந்திருக்கிறவர்களுடைய பேச்சையல்ல, அவர்களுடைய பெலத்தையே அறிந்துகொள்வேன்.
	<br /><br />
	20. தேவனுடைய ராஜ்யம் பேச்சிலே அல்ல, பெலத்திலே உண்டாயிருக்கிறது.
	<br /><br />
	21. உங்களுக்கு என்னவேண்டும்? நான் பிரம்போடு உங்களிடத்தில் வரவேண்டுமோ? அல்லது அன்போடும் சாந்தமுள்ள ஆவியோடும் வரவேண்டுமோ?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians4