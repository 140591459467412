import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 14 </title>
	<meta
          name="description"
          content="The Book of Revelation - 14 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 14 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* சீயோனில் 1,44,000 பேருடன் ஆட்டுக்குட்டியைக் கண்டேன். தேவதூதர்கள், "தீர்ப்பு நேரம் வந்துவிட்டது" என்றார்கள். பூமி ஒரு கூர்மையான அரிவாளால் அறுவடை செய்யப்பட்டது. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பின்பு நான் பார்த்தபோது, இதோ, சீயோன் மலையின்மேல் ஆட்டுக்குட்டியானவரையும், அவரோடேகூட அவருடைய பிதாவின் நாமம் தங்கள் நெற்றிகளில் எழுதப்பட்டிருந்த இலட்சத்து நாற்பத்து நாலாயிரம்பேரையும் நிற்கக்கண்டேன்.
	<br /><br />
	2. அல்லாமலும், பெருவெள்ள இரைச்சல்போலவும், பலத்த இடிமுழக்கம்போலவும், ஒரு சத்தம் வானத்திலிருந்து உண்டாகக்கேட்டேன்; நான் கேட்ட சத்தம் சுரமண்டலக்காரர் தங்கள் சுரமண்டலங்களை வாசிக்கிற ஓசையைப்போலிருந்தது.
	<br /><br />
	3. அவர்கள் சிங்காசனத்திற்கு முன்பாகவும், நான்கு ஜீவன்களுக்கு முன்பாகவும், மூப்பர்களுக்கு முன்பாகவும் புதுப்பாட்டைப் பாடினார்கள்; அந்தப் பாட்டு பூமியிலிருந்து மீட்டுக்கொள்ளப்பட்ட இலட்சத்து நாற்பத்துநாலாயிரம் பேரேயல்லாமல் வேறொருவரும் கற்றுக்கொள்ளக் கூடாதிருந்தது.
	<br /><br />
	4. ஸ்திரீகளால் தங்களைக் கறைப்படுத்தாதவர்கள் இவர்களே; கற்புள்ளவர்கள் இவர்களே; ஆட்டுக்குட்டியானவர் எங்கே போனாலும் அவரைப் பின்பற்றுகிறவர்கள் இவர்களே; இவர்கள் மனுஷரிலிருந்து தேவனுக்கும் ஆட்டுக்குட்டியானவருக்கும் முதற்பலனாக மீட்டுக்கொள்ளப்பட்டவர்கள்.
	<br /><br />
	5. இவர்களுடைய வாயிலே கபடம் காணப்படவில்லை; இவர்கள் தேவனுடைய சிங்காசனத்திற்குமுன்பாக மாசில்லாதவர்களாயிருக்கிறார்கள்.
	<br /><br />
	6. பின்பு, வேறொரு தூதன் வானத்தின் மத்தியிலே பறக்கக்கண்டேன்; அவன் பூமியில் வாசம்பண்ணுகிற சகல ஜாதிகளுக்கும், கோத்திரத்தாருக்கும், பாஷைக்காரருக்கும், ஜனக்கூட்டத்தாருக்கும் அறிவிக்கத்தக்கதாக நித்திய சுவிசேஷத்தை உடையவனாயிருந்து,
	<br /><br />
	7. மிகுந்த சத்தமிட்டு: தேவனுக்குப் பயந்து, அவரை மகிமைப்படுத்துங்கள்; அவர் நியாயத்தீர்ப்புக்கொடுக்கும் வேளை வந்தது; வானத்தையும் பூமியையும் சமுத்திரத்தையும் நீரூற்றுகளையும் உண்டாக்கினவரையே தொழுதுகொள்ளுங்கள் என்று கூறினான்.
	<br /><br />
	8. வேறொரு தூதன் பின்சென்று: பாபிலோன் மகா நகரம் விழுந்தது! விழுந்தது! தன் வேசித்தனமாகிய உக்கிரமான மதுவைச் சகல ஜாதிகளுக்கும் குடிக்கக் கொடுத்தாளே! என்றான்.
	<br /><br />
	9. அவர்களுக்குப் பின்னே மூன்றாம் தூதன் வந்து, மிகுந்த சத்தமிட்டு: மிருகத்தையும் அதின் சொரூபத்தையும் வணங்கித் தன் நெற்றியிலாவது தன் கையிலாவது அதின் முத்திரையைத் தரித்துக்கொள்ளுகிறவனெவனோ,
	<br /><br />
	10. அவன் தேவனுடைய கோபாக்கினையாகிய பாத்திரத்திலே கலப்பில்லாமல் வார்க்கப்பட்ட அவருடைய உக்கிரமாகிய மதுவைக் குடித்து, பரிசுத்த தூதர்களுக்குமுன்பாகவும், ஆட்டுக்குட்டியானவருக்குமுன்பாகவும் அக்கினியினாலும் கந்தகத்தினாலும் வாதிக்கப்படுவான்.
	<br /><br />
	11. அவர்களுடைய வாதையின் புகை சதாகாலங்களிலும் எழும்பும்; மிருகத்தையும் அதின் சொரூபத்தையும் வணங்குகிறவர்களுக்கும், அதினுடைய நாமத்தின் முத்திரையைத் தரித்துக்கொள்ளுகிற எவனுக்கும் இரவும் பகலும் இளைப்பாறுதலிராது.
	<br /><br />
	12. தேவனுடைய கற்பனைகளையும் இயேசுவின் மேலுள்ள விசுவாசத்தையும் காத்துக்கொள்ளுகிறவர்களாகிய பரிசுத்தவான்களுடைய பொறுமை இதிலே விளங்கும் என்று கூறினான்.
	<br /><br />
	13. பின்பு, பரலோகத்திலிருந்து ஒரு சத்தம் உண்டாகக் கேட்டேன்; அது: கர்த்தருக்குள் மரிக்கிறவர்கள் இதுமுதல் பாக்கியவான்கள் என்றெழுது; அவர்கள் தங்கள் பிரயாசங்களை விட்டொழிந்து இளைப்பாறுவார்கள்; அவர்களுடைய கிரியைகள் அவர்களோடே கூடப்போம்; ஆவியானவரும் ஆம் என்று திருவுளம்பற்றுகிறார் என்று சொல்லிற்று.
	<br /><br />
	14. பின்பு நான் பார்த்தபோது, இதோ, வெண்மையான மேகத்தையும், அந்த மேகத்தின்மேல் மனுஷகுமாரனுக்கொப்பானவராய்த் தமது சிரசின்மேல் பொற்கிரீடத்தையும் தமது கையிலே கருக்குள்ள அரிவாளையுமுடைய ஒருவர் உட்கார்ந்திருக்கிறதையும் கண்டேன்.
	<br /><br />
	15. அப்பொழுது வேறொரு தூதன் தேவாலயத்திலிருந்து புறப்பட்டு, மேகத்தின்மேல் உட்கார்ந்திருக்கிறவரை நோக்கி: பூமியின் பயிர் முதிர்ந்தது, அறுக்கிறதற்குக் காலம் வந்தது, ஆகையால் உம்முடைய அரிவாளை நீட்டி அறுத்துவிடும் என்று மிகுந்த சத்தமிட்டுச் சொன்னான்.
	<br /><br />
	16. அப்பொழுது மேகத்தின்மேல் உட்கார்ந்தவர் தமது அரிவாளைப் பூமியின்மேல் நீட்டினார், பூமியின் விளைவு அறுப்புண்டது.
	<br /><br />
	17. பின்பு வேறொரு தூதனும் கருக்குள்ள அரிவாளைப் பிடித்துக்கொண்டு பரலோகத்திலுள்ள தேவாலயத்திலிருந்து புறப்பட்டுவந்தான்.
	<br /><br />
	18. அக்கினியின்மேல் அதிகாரமுள்ள வேறொரு தூதனும் பலிபீடத்திலிருந்து புறப்பட்டுவந்து, கருக்குள்ள அரிவாளைப் பிடித்திருக்கிறவனை நோக்கி: பூமியின் திராட்சப்பழங்கள் பழுத்திருக்கிறது, கருக்குள்ள உமது அரிவாளை நீட்டி, அதின் குலைகளை அறுத்துவிடும் என்று மிகுந்த சத்தத்தோடே சொன்னான்.
	<br /><br />
	19. அப்பொழுது அந்தத் தூதன் தன் அரிவாளைப் பூமியின்மேலே நீட்டி, பூமியின் திராட்சப்பழங்களை அறுத்து, தேவனுடைய கோபாக்கினையென்னும் பெரிய ஆலையிலே போட்டான்.
	<br /><br />
	20. நகரத்திற்குப் புறம்பேயுள்ள அந்த ஆலையிலே அது மிதிக்கப்பட்டது; அந்த ஆலையிலிருந்து ஆயிரத்தறுநூறு ஸ்தாதி தூரத்திற்கு இரத்தம் புறப்பட்டுக் குதிரைகளின் கடிவாளங்கள்மட்டும் பெருகிவந்தது.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h14>
*  *
			</h14>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation14