import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke23 = () => {
  const verseNumber = 23;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 23 </title>
	<meta
          name="description"
          content="Luke 23 | லூக்கா 23 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர்களுடைய கூட்டத்தாரெல்லாரும் எழுந்திருந்து, அவரைப் பிலாத்துவினிடத்திற்குக் கொண்டுபோய்:
	<br /><br />
	2. இவன் தன்னைக் கிறிஸ்து என்னப்பட்ட ராஜாவென்றும், ராயருக்கு வரிகொடுக்க வேண்டுவதில்லையென்றும் சொல்லி, ஜனங்களைக் கலகப்படுத்தக் கண்டோம் என்று அவர்மேல் குற்றஞ்சாட்டத் தொடங்கினார்கள்.
	<br /><br />
	3. பிலாத்து அவரை நோக்கி: நீ யூதருடைய ராஜாவா என்று கேட்டான். அவர் அவனுக்குப் பிரதியுத்தரமாக: நீர் சொல்லுகிறபடிதான் என்றார்.
	<br /><br />
	4. அப்பொழுது பிலாத்து பிரதான ஆசாரியர்களையும் ஜனங்களையும் நோக்கி: இந்த மனுஷனிடத்தில் நான் ஒரு குற்றத்தையும் காணவில்லை என்றான்.
	<br /><br />
	5. அதற்கு அவர்கள்: இவன் கலிலேயா நாடுதொடங்கி இவ்விடம்வரைக்கும் யூதேயாதேசமெங்கும் உபதேசம்பண்ணி, ஜனங்களைக் கலகப்படுத்துகிறான் என்று வைராக்கியத்தோடே சொன்னார்கள்.
	<br /><br />
	6. கலிலேயா என்பதைப் பிலாத்து கேட்டபொழுது, இந்த மனுஷன் கலிலேயனா என்று விசாரித்து,
	<br /><br />
	7. அவர் ஏரோதின் அதிகாரத்துக்குள்ளானவர் என்றறிந்து, அந்நாட்களில் எருசலேமிலே வந்திருந்த ஏரோதுவினிடத்திற்கு அவரை அனுப்பினான்.
	<br /><br />
	8. ஏரோது இயேசுவைக்குறித்து அநேக காரியங்களைக் கேள்விப்பட்டிருந்ததினாலும், அவரால் செய்யப்படும் அடையாளத்தைப் பார்க்கவேண்டுமென்று விரும்பியிருந்ததினாலும், அவரைக் காணும்படி வெகுநாளாய் ஆசைகொண்டிருந்தான். அந்தப்படி அவரைக் கண்டபோது, மிகவும் சந்தோஷப்பட்டு,
	<br /><br />
	9. அநேக காரியங்களைக்குறித்து அவரிடத்தில் வினாவினான். அவர் மறுமொழியாக அவனுக்கு ஒன்றும் சொல்லவில்லை.
	<br /><br />
	10. பிரதான ஆசாரியரும் வேதபாரகரும் அவர்மேல் பிடிவாதமாய்க் குற்றஞ்சாட்டிக்கொண்டே நின்றார்கள்.
	<br /><br />
	11. அப்பொழுது ஏரோது தன் போர்ச்சேவகரோடுகூட அவரை நிந்தித்துப் பரியாசம்பண்ணி, மினுக்கான வஸ்திரத்தை அவருக்கு உடுத்தி, அவரைத் திரும்பப் பிலாத்துவினிடத்திற்கு அனுப்பினான்.
	<br /><br />
	12. முன்னே ஒருவருக்கொருவர் பகைவராயிருந்த பிலாத்துவும் ஏரோதும் அன்றையத்தினம் சிநேகிதரானார்கள்.
	<br /><br />
	13. பிலாத்து பிரதான ஆசாரியர்களையும் அதிகாரிகளையும் ஜனங்களையும் கூடிவரச்செய்து,
	<br /><br />
	14. அவர்களை நோக்கி: ஜனங்களைக் கலகத்துக்குத் தூண்டிவிடுகிறவனாக இந்த மனுஷனை என்னிடத்தில் கொண்டுவந்தீர்கள்; நான் உங்களுக்கு முன்பாக விசாரித்தபோது, இவன்மேல் நீங்கள் சாட்டுகிற குற்றங்களில் ஒன்றையும் நான் இவனிடத்தில் காணவில்லை.
	<br /><br />
	15. உங்களை ஏரோதினிடத்திற்கும் அனுப்பினேன்; அவரும் இவனிடத்தில் குற்றம் காணவில்லை; மரணத்துக்கேதுவாக இவன் ஒன்றும் செய்யவில்லையே.
	<br /><br />
	16. ஆனபடியால் இவனைத் தண்டித்து, விடுதலையாக்குவேன் என்றான்.
	<br /><br />
	17. பண்டிகைதோறும் அவர்களுக்கு ஒருவனை அவன் விடுதலையாக்குவது அவசியமாயிருந்தபடியால் அப்படிச் சொன்னான்.
	<br /><br />
	18. ஜனங்களெல்லாரும் அதைக் கேட்டு: இவனை அகற்றும், பரபாசை எங்களுக்கு விடுதலையாக்கும் என்று சத்தமிட்டுக் கேட்டார்கள்.
	<br /><br />
	19. அந்தப் பரபாசென்பவன் நகரத்தில் நடந்த ஒரு கலகத்தினிமித்தமும் கொலைபாதகத்தினிமித்தமும் காவலிலே வைக்கப்பட்டிருந்தான்.
	<br /><br />
	20. பிலாத்து இயேசுவை விடுதலையாக்க மனதாய், மறுபடியும் அவர்களிடத்தில் பேசினான்.
	<br /><br />
	21. அவர்களோ: அவனைச் சிலுவையில் அறையும், சிலுவையில் அறையும் என்று கூக்குரலிட்டார்கள்.
	<br /><br />
	22. அவன் மூன்றாந்தரம் அவர்களை நோக்கி: ஏன், இவன் என்ன பொல்லாப்புச் செய்தான்? மரணத்துக்கு ஏதுவான குற்றம் ஒன்றும் இவனிடத்தில் நான் காணவில்லையே; ஆகையால் நான் இவனைத் தண்டித்து, விடுதலையாக்குவேன் என்றான்.
	<br /><br />
	23. அப்படியிருந்தும் அவரைச் சிலுவையில் அறையவேண்டுமென்று அவர்கள் உரத்த சத்தத்தோடு கேட்டுக்கொண்டேயிருந்தார்கள். அவர்களும் பிரதான ஆசாரியரும் இட்ட சத்தம் மேற்கொண்டது.
	<br /><br />
	24. அப்பொழுது அவர்கள் கேட்டுக்கொண்டபடியே ஆகட்டும் என்று பிலாத்து தீர்ப்புசெய்து,
	<br /><br />
	25. கலகத்தினிமித்தமும் கொலைபாதகத்தினிமித்தமும் காவலில் போடப்பட்டிருந்தவனை அவர்கள் கேட்டுக்கொண்டபடியே விடுதலையாக்கி, இயேசுவையோ அவர்கள் இஷ்டத்துக்கு ஒப்புக்கொடுத்தான்.
	<br /><br />
	26. அவர்கள் இயேசுவைக் கொண்டுபோகிறபோது, நாட்டிலிருந்து வருகிற சிரேனே ஊரானாகிய சீமோன் என்கிற ஒருவனைப் பிடித்து, சிலுவையை அவர்பின்னே சுமந்துகொண்டுவரும்படி அதை அவன்மேல் வைத்தார்கள்.
	<br /><br />
	27. திரள்கூட்டமான ஜனங்களும் அவருக்காகப் புலம்பி அழுகிற ஸ்திரீகளும் அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	28. இயேசு அவர்கள் முகமாய்த் திரும்பி: எருசலேமின் குமாரத்திகளே, நீங்கள் எனக்காக அழாமல், உங்களுக்காகவும் உங்கள் பிள்ளைகளுக்காகவும் அழுங்கள்.
	<br /><br />
	29. இதோ, மலடிகள் பாக்கியவதிகளென்றும், பிள்ளைபெறாத கர்ப்பங்களும் பால்கொடாத முலைகளும் பாக்கியமுள்ளவைகளென்றும் சொல்லப்படும் நாட்கள் வரும்.
	<br /><br />
	30. அப்பொழுது மலைகளை நோக்கி: எங்கள்மேல் விழுங்களென்றும், குன்றுகளை நோக்கி: எங்களை மறைத்துக்கொள்ளுங்களென்றும் சொல்லத்தொடங்குவார்கள்.
	<br /><br />
	31. பச்சைமரத்துக்கு இவைகளைச் செய்தால், பட்டமரத்துக்கு என்ன செய்யமாட்டார்கள் என்றார்.
	<br /><br />
	32. குற்றவாளிகளாகிய வேறே இரண்டுபேரும் அவரோடேகூடக் கொலைசெய்யப்படுவதற்குக் கொண்டுபோகப்பட்டார்கள்.
	<br /><br />
	33. கபாலஸ்தலம் என்று சொல்லப்பட்ட இடத்தில் அவர்கள் சேர்ந்தபொழுது, அங்கே அவரையும், அவருடைய வலதுபக்கத்தில் ஒரு குற்றவாளியையும், அவருடைய இடதுபக்கத்தில் ஒரு குற்றவாளியையும் சிலுவைகளில் அறைந்தார்கள்.
	<br /><br />
	34. அப்பொழுது இயேசு: பிதாவே, இவர்களுக்கு மன்னியும், தாங்கள் செய்கிறது இன்னதென்று அறியாதிருக்கிறார்களே என்றார். அவருடைய வஸ்திரங்களை அவர்கள் பங்கிட்டுச் சீட்டுப்போட்டார்கள்.
	<br /><br />
	35. ஜனங்கள் நின்று பார்த்துக்கொண்டிருந்தார்கள். அவர்களுடனேகூட அதிகாரிகளும் அவரை இகழ்ந்து: இவன் மற்றவர்களை இரட்சித்தான், இவன் தேவனால் தெரிந்துகொள்ளப்பட்ட கிறிஸ்துவானால், தன்னைத்தானே இரட்சித்துக்கொள்ளட்டும் என்றார்கள்.
	<br /><br />
	36. போர்ச்சேவகரும் அவரிடத்தில் சேர்ந்து, அவருக்குக் காடியைக் கொடுத்து:
	<br /><br />
	37. நீ யூதரின் ராஜாவானால், உன்னை இரட்சித்துக்கொள் என்று அவரைப் பரியாசம்பண்ணினார்கள்.
	<br /><br />
	38. இவன் யூதருடைய ராஜா என்று, கிரேக்கு லத்தீன் எபிரெயு எழுத்துக்களில் எழுதி, அவருக்கு மேலாக வைக்கப்பட்டது.
	<br /><br />
	39. அன்றியும் சிலுவையில் அறையப்பட்டிருந்த குற்றவாளிகளில் ஒருவன்: நீ கிறிஸ்துவானால் உன்னையும் எங்களையும் இரட்சித்துக்கொள் என்று அவரை இகழ்ந்தான்.
	<br /><br />
	40. மற்றவன் அவனை நோக்கி: நீ இந்த ஆக்கினைக்குட்பட்டவனாயிருந்தும் தேவனுக்குப் பயப்படுகிறதில்லையா?
	<br /><br />
	41. நாமோ நியாயப்படி தண்டிக்கப்படுகிறோம்; நாம் நடப்பித்தவைகளுக்குத்தக்க பலனை அடைகிறோம்; இவரோ தகாததொன்றையும் நடப்பிக்கவில்லையே என்று அவனைக் கடிந்துகொண்டு,
	<br /><br />
	42. இயேசுவை நோக்கி: ஆண்டவரே, நீர் உம்முடைய ராஜ்யத்தில் வரும்போது அடியேனை நினைத்தருளும் என்றான்.
	<br /><br />
	43. இயேசு அவனை நோக்கி: இன்றைக்கு நீ என்னுடனேகூடப் பரதீசிலிருப்பாய் என்று மெய்யாகவே உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	44. அப்பொழுது ஏறக்குறைய ஆறாம்மணி நேரமாயிருந்தது; ஒன்பதாம்மணி நேரம்வரைக்கும் பூமியெங்கும் அந்தகாரமுண்டாயிற்று.
	<br /><br />
	45. சூரியன் இருளடைந்தது, தேவாலயத்தின் திரைச்சீலை நடுவில் இரண்டாகக் கிழிந்தது.
	<br /><br />
	46. இயேசு: பிதாவே, உம்முடைய கைகளில் என் ஆவியை ஒப்புவிக்கிறேன் என்று மகா சத்தமாய்க் கூப்பிட்டுச் சொன்னார்; இப்படிச் சொல்லி, ஜீவனை விட்டார்.
	<br /><br />
	47. நூற்றுக்கு அதிபதி சம்பவித்ததைக்கண்டு: மெய்யாகவே இந்த மனுஷன் நீதிபரனாயிருந்தான் என்று சொல்லி, தேவனை மகிமைப்படுத்தினான்.
	<br /><br />
	48. இந்தக் காட்சியைப் பார்க்கும்படி கூடிவந்திருந்த ஜனங்களெல்லாரும் சம்பவித்தவைகளைப் பார்த்தபொழுது, தங்கள் மார்பில் அடித்துக்கொண்டு திரும்பிப்போனார்கள்.
	<br /><br />
	49. அவருக்கு அறிமுகமானவர்களெல்லாரும், கலிலேயாவிலிருந்து அவருக்குப் பின்சென்று வந்த ஸ்திரீகளும் தூரத்திலே நின்று இவைகளைப் பார்த்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	50. யோசேப்பு என்னும் பேர்கொண்ட ஒரு ஆலோசனைக்காரன் இருந்தான்; அவன் உத்தமனும் நீதிமானுமாயிருந்தான்.
	<br /><br />
	51. அவன் யூதருடைய பட்டணங்களிலொன்றாகிய அரிமத்தியாவிலிருந்து வந்தவனும், தேவனுடைய ராஜ்யத்துக்குக் காத்திருந்தவனும், யூதர்களுடைய ஆலோசனைக்கும் செய்கைக்கும் சம்மதியாதவனுமாயிருந்தான்.
	<br /><br />
	52. அவன் பிலாத்துவினிடத்தில் போய், இயேசுவின் சரீரத்தைக் கேட்டு,
	<br /><br />
	53. அதை இறக்கி, மெல்லிய துப்பட்டியிலே சுற்றி, கன்மலையில் வெட்டப்பட்டதுமாய் ஒருக்காலும் ஒருவனும் வைக்கப்படாததுமாயிருந்த ஒரு கல்லறையிலே வைத்தான்.
	<br /><br />
	54. அந்த நாள் ஆயத்தநாளாயிருந்தது; ஓய்வுநாளும் ஆரம்பமாயிற்று.
	<br /><br />
	55. கலிலேயாவிலிருந்து அவருடனேகூட வந்திருந்த ஸ்திரீகளும் பின்சென்று, கல்லறையையும் அவருடைய சரீரம் வைக்கப்பட்ட விதத்தையும் பார்த்து,
	<br /><br />
	56. திரும்பிப்போய், கந்தவர்க்கங்களையும் பரிமளதைலங்களையும் ஆயத்தம்பண்ணி, கற்பனையின்படியே ஓய்வுநாளில் ஓய்ந்திருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke23