import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Joel1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோவேல் 1 </title>
	<meta
          name="description"
          content="Joel 1 | யோவேல் 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பெத்துவேலின் குமாரனாகிய யோவேலுக்கு உண்டான கர்த்தருடைய வசனம்.
	<br /><br />
	2. முதியோரே, இதைக் கேளுங்கள்; தேசத்தின் சகல குடிகளே, செவிகொடுங்கள்; உங்கள் நாட்களிலாவது உங்கள் பிதாக்களின் நாட்களிலாவது இப்படிப்பட்டது சம்பவித்ததுண்டா?
	<br /><br />
	3. இதின் செய்தியை உங்கள் பிள்ளைகளுக்குத் தெரிவியுங்கள்; இதை உங்கள் பிள்ளைகள் தங்கள் பிள்ளைகளுக்கும், அவர்கள் தங்கள் பிள்ளைகளுக்கும் சந்ததியாருக்கும் தெரிவிக்கக்கடவர்கள்.
	<br /><br />
	4. பச்சைப்புழு விட்டதை வெட்டுக்கிளி தின்றது; வெட்டுக்கிளி விட்டதைப் பச்சைக்கிளி தின்றது; பச்சைக்கிளி விட்டதை முசுக்கட்டைப்பூச்சி தின்றது.
	<br /><br />
	5. வெறியரே, விழித்து அழுங்கள்; திராட்சரசம் குடிக்கிற சகல ஜனங்களே, புது திராட்சரசத்தினிமித்தம் அலறுங்கள்; அது உங்கள் வாயினின்று விலக்கப்பட்டது.
	<br /><br />
	6. எண்ணிமுடியாத ஒரு பலத்த ஜாதி என் தேசத்தின்மேல் வருகிறது; அதின் பற்கள் சிங்கத்தின் பற்கள்; துஷ்ட சிங்கத்தின் கடைவாய்ப்பற்கள் அதற்கு உண்டு.
	<br /><br />
	7. அது என் திராட்சச்செடியை அழித்து, என் அத்திமரத்தை உரித்து, அதின் பட்டையை முற்றிலும் தின்றுபோட்டது; அதின் கிளைகள் வெண்மையாயிற்று.
	<br /><br />
	8. தன் பாலியவயதின் புருஷனுக்காக இரட்டுடுத்தியிருக்கிற பெண்ணைப்போலப் புலம்பு.
	<br /><br />
	9. போஜனபலியும் பானபலியும் கர்த்தருடைய ஆலயத்தை விட்டு அற்றுப்போயின; கர்த்தரின் ஊழியக்காரராகிய ஆசாரியர்கள் துக்கிக்கிறார்கள்.
	<br /><br />
	10. வயல்வெளி பாழாயிற்று, பூமி துக்கங்கொண்டாடுகிறது; விளைச்சல் அழிக்கப்பட்டது; புது திராட்சரசம் வற்றிப்போயிற்று; எண்ணெய் மாண்டுபோயிற்று.
	<br /><br />
	11. பயிரிடுங் குடிகளே, வெட்கப்படுங்கள்; கோதுமையும் வாற்கோதுமையும் இல்லாமற்போயிற்று; திராட்சத்தோட்டக்காரரே, அலறுங்கள்; வயல்வெளியின் அறுப்பு அழிந்துபோயிற்று.
	<br /><br />
	12. திராட்சச்செடி வதங்கி, அத்திமரம் சாரமற்றுப்போகிறது; மாதளை, பேரீச்சம், கிச்சிலி முதலிய வெளியின் செடிகள் எல்லாம் வாடிப்போயின; சந்தோஷம் மனுபுத்திரரைவிட்டு ஒழிந்துபோயிற்று.
	<br /><br />
	13. ஆசாரியர்களே, இரட்டுடுத்திப் புலம்புங்கள்; பலிபீடத்தின் பணிவிடைக்காரரே, அலறுங்கள்; என் தேவனுடைய தொண்டரே, நீங்கள் உள்ளே பிரவேசித்து இரட்டுடுத்தவர்களாய் இராத்தங்குங்கள். உங்கள் தேவனுடைய ஆலயத்தில் போஜனபலியும் பானபலியும் செலுத்தப்படாமல் நிறுத்தப்பட்டது.
	<br /><br />
	14. பரிசுத்த உபவாசநாளை நியமியுங்கள்; விசேஷித்த ஆசரிப்பைக் கூறுங்கள்; மூப்பரையும் தேசத்தின் எல்லாக் குடிகளையும், உங்கள் தேவனாகிய கர்த்தரின் ஆலயத்திலே கூடிவரச்செய்து கர்த்தரை நோக்கிக் கூப்பிடுங்கள்.
	<br /><br />
	15. அந்த நாளினிமித்தம் ஐயோ! கர்த்தருடைய நாள் சமீபமாயிருக்கிறது; அது சங்காரம்போலச் சர்வவல்லவரிடத்திலிருந்து வருகிறது.
	<br /><br />
	16. நம்முடைய கண்களைவிட்டு ஆகாரமும், நம்முடைய தேவனின் ஆலயத்தைவிட்டுச் சந்தோஷமும் மகிழ்ச்சியும் நீக்கப்படவில்லையோ?
	<br /><br />
	17. விதையானது மண்கட்டிகளின்கீழ் மக்கிப்போயிற்று; பயிர் தீய்ந்துபோகிறதினால் பண்டசாலைகள் பாழாகிக் களஞ்சியங்கள் இடிந்துபோயின.
	<br /><br />
	18. மிருகங்கள் எவ்வளவாய்த் தவிக்கிறது; மாட்டுமந்தைகள் தங்களுக்கு மேய்ச்சல் இல்லாததினால் கலங்குகிறது; ஆட்டுமந்தைகளும் சேதமாய்ப்போயிற்று.
	<br /><br />
	19. கர்த்தாவே, உம்மை நோக்கிக் கூப்பிடுகிறேன்; அக்கினி வனாந்தரத்தின் மேய்ச்சல்களைப் பட்சித்து, ஜுவாலை வெளியின் விருட்சங்களையெல்லாம் எரித்துப்போடுகிறது.
	<br /><br />
	20. வெளியின் மிருகங்களும் உம்மை நோக்கிக் கதறுகிறது; நதிகளில் தண்ணீரெல்லாம் வற்றிப்போயிற்று; அக்கினி வனாந்தரத்தின் மேய்ச்சல்களைப் பட்சித்துப்போட்டது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Joel1