import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah37 = () => {
  const verseNumber = 37;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 37 </title>
	<meta
          name="description"
          content="Jeremiah 37 | எரேமியா 37 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் யூதாதேசத்தில் ராஜாவாக நியமித்த யோயாக்கீமுடைய குமாரனாகிய கோனியாவின் பட்டத்துக்கு யோசியாவின் குமாரனாகிய சிதேக்கியா வந்து அரசாண்டான்.
	<br /><br />
	2. கர்த்தர் எரேமியா தீர்க்கதரிசியைக்கொண்டு சொன்ன வார்த்தைகளுக்கு அவனாகிலும், அவனுடைய ஊழியக்காரராகிலும், தேசத்தின் ஜனங்களாகிலும் செவிகொடுக்கவில்லை.
	<br /><br />
	3. சிதேக்கியா ராஜாவோவெனில், செலேமியாவின் குமாரனாகிய யூகாலையும், மாசெயாவின் குமாரனாகிய செப்பனியா என்னும் ஆசாரியனையும் எரேமியா தீர்க்கதரிசியினிடத்தில் அனுப்பி: நீ நம்முடைய தேவனாகிய கர்த்தரை நோக்கி எங்களுக்காக விண்ணப்பம் பண்ணவேண்டும் என்று சொல்லச்சொன்னான்.
	<br /><br />
	4. அப்பொழுது எரேமியா ஜனத்தின் நடுவே வரத்தும்போக்குமாயிருந்தான்; அவனை அவர்கள் காவல் வீட்டில் இன்னும் போடவில்லை.
	<br /><br />
	5. பார்வோனின் சேனையோவென்றால், எகிப்திலிருந்து புறப்பட்டது; எருசலேமை முற்றிக்கைபோட்ட கல்தேயர் அவர்களுடைய செய்தியைக்கேட்டு, எருசலேமைவிட்டு நீங்கிப்போனார்கள்.
	<br /><br />
	6. அப்பொழுது எரேமியா தீர்க்கதரிசிக்குக் கர்த்தருடைய வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	7. இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறது என்னவென்றால், என்னிடத்தில் விசாரிக்கும்படி உங்களை என்னிடத்திற்கு அனுப்பின யூதாவின் ராஜாவை நீங்கள் நோக்கி: இதோ, உங்களுக்கு ஒத்தாசையாகப் புறப்பட்ட பார்வோனின் சேனை தன் தேசமாகிய எகிப்துக்குத் திரும்பிப்போகும்.
	<br /><br />
	8. கல்தேயரோவென்றால், திரும்பிவந்து இந்த நகரத்துக்கு விரோதமாக யுத்தம்பண்ணி, அதைப் பிடித்து, அக்கினியால் சுட்டெரிப்பார்கள்.
	<br /><br />
	9. கல்தேயர் நம்மைவிட்டு நிச்சயமாய்ப் போய்விடுவார்களென்று சொல்லி, நீங்கள் மோசம்போகாதிருங்கள், அவர்கள் போவதில்லை.
	<br /><br />
	10. உங்களோடே யுத்தம்பண்ணுகிற கல்தேயருடைய சேனையையெல்லாம் நீங்கள் முறிய அடித்தாலும், மீந்தவர்கள் எல்லாரும் காயம்பட்டவர்களாயிருந்தாலும், அவர்கள் தங்கள் கூடாரங்களிலிருந்து எழும்பி, இந்த நகரத்தை அக்கினியால் சுட்டெரிப்பார்கள் என்று கர்த்தர் உரைக்கிறார் என்று சொல்லுங்கள் என்றார்.
	<br /><br />
	11. பார்வோனின் சேனை வருகிறதென்று, கல்தேயருடைய சேனை எருசலேமைவிட்டுப் போனபோது,
	<br /><br />
	12. எரேமியா அவ்விடத்தைவிட்டு, ஜனத்தின் நடுவே ஜாடையாய் விலகி, பென்யமீன் தேசத்துக்குப் போக மனதாய் எருசலேமிலிருந்து புறப்பட்டுப்போனான்.
	<br /><br />
	13. அவன் பென்யமீன் வாசலில் வந்தபோது, காவற்சேர்வையின் அதிபதியாகிய யெரியா என்னும் நாமமுள்ள ஒருவன் அங்கே இருந்தான்; அவன் அனனியாவின் குமாரனாகிய செலேமியாவின் மகன்; அவன்: நீ கல்தேயரைச் சேரப்போகிறவன் என்று சொல்லி, எரேமியா தீர்க்கதரிசியைப் பிடித்தான்.
	<br /><br />
	14. அப்பொழுது எரேமியா: அது பொய், நான் கல்தேயரைச் சேரப்போகிறவனல்ல என்றான்; ஆனாலும் யெரியா எரேமியாவின் சொல்லைக் கேளாமல், அவனைப் பிடித்து, பிரபுக்களிடத்தில் கொண்டுபோனான்.
	<br /><br />
	15. அப்பொழுது பிரபுக்கள்: எரேமியாவின்பேரில் கடுங்கோபங்கொண்டு, அவனை அடித்து, அவனைச் சம்பிரதியாகிய யோனத்தானுடைய வீட்டில் காவற்படுத்தினார்கள்; அவர்கள் அதைக் காவற்கூடமாக்கியிருந்தார்கள்.
	<br /><br />
	16. அப்படியே எரேமியா காவற்கிடங்கின் நிலவறைகளில் பிரவேசித்து, அங்கே அநேகநாள் இருந்தான்.
	<br /><br />
	17. பின்பு சிதேக்கியா ராஜா அவனை அழைத்தனுப்பி: கர்த்தரால் ஒரு வார்த்தை உண்டோ என்று ராஜா அவனைத் தன் வீட்டிலே இரகசியமாய்க் கேட்டான். அதற்கு எரேமியா: உண்டு, பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுக்கப்படுவீர் என்று சொன்னான்.
	<br /><br />
	18. பின்னும் எரேமியா, சிதேக்கியா ராஜாவை நோக்கி: நீங்கள் என்னைக் காவல் வீட்டிலே அடைப்பதற்கு, நான் உமக்கும் உம்முடைய ஊழியக்காரருக்கும் இந்த ஜனத்துக்கும் விரோதமாக என்ன குற்றஞ்செய்தேன்?
	<br /><br />
	19. பாபிலோன் ராஜா உங்களுக்கும் இந்தத் தேசத்துக்கும் விரோதமாக வருவதில்லையென்று உங்களுக்குத் தீர்க்கதரிசனம் சொன்ன உங்களுடைய தீர்க்கதரிசிகள் எங்கே?
	<br /><br />
	20. இப்போதும் ராஜாவாகிய என் ஆண்டவனே, எனக்குச் செவிகொடுத்து, என் விண்ணப்பத்துக்குத் தயைசெய்து, என்னைச் சம்பிரதியாகிய யோனத்தானுடைய வீட்டிற்குத் திரும்ப அனுப்பவேண்டாம்; அனுப்பினால் நான் அங்கே செத்துப்போவேன் என்றான்.
	<br /><br />
	21. அப்பொழுது எரேமியாவைக் காவற்சாலையின் முற்றத்திலே காக்கவும், நகரத்திலே அப்பமிருக்குமட்டும் அப்பஞ்சுடுகிறவர்களின் வீதியிலே தினம் ஒரு அப்பத்தை அவனுக்கு வாங்கிக்கொடுக்கவும் கட்டளையிட்டான்; அப்படியே எரேமியா காவற்சாலையின் முற்றத்திலே இருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah37