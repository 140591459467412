import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zephaniah3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | செப்பனியா 3 </title>
	<meta
          name="description"
          content="Zephaniah 3 | செப்பனியா 3 | Seppania 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இடுக்கண் செய்து, ஊத்தையும் அழுக்குமாயிருக்கிற நகரத்துக்கு ஐயோ!
	<br /><br />
	2. அது சத்தத்துக்குச் செவிகொடுக்கவில்லை; அது கடிந்துகொள்ளுதலை ஏற்றுக்கொள்ளவில்லை; அது கர்த்தரை நம்பவில்லை; அது தன் தேவனிடத்தில் சேரவில்லை.
	<br /><br />
	3. அதற்குள்ளே இருக்கிற அதின் அதிபதிகள் கெர்ச்சிக்கிற சிங்கங்கள்; அதின் நியாயாதிபதிகள் சாயங்காலத்தில் புறப்படுகிறதும் விடியற்காலமட்டும் ஒரு எலும்பையும் மீதியாக வைக்காததுமான ஓநாய்கள்.
	<br /><br />
	4. அதின் தீர்க்கதரிசிகள் வீண்பெருமையும் வஞ்சகமுமுள்ளவர்கள்; அதின் ஆசாரியர்கள் பரிசுத்த ஸ்தலத்தைப் பரிசுத்தக்குலைச்சலாக்கி, வேதத்துக்கு அநியாயஞ்செய்தார்கள்.
	<br /><br />
	5. அதற்குள் இருக்கிற கர்த்தர் நீதியுள்ளவர்; அவர் அநியாயஞ்செய்வதில்லை; அவர் குறைவில்லாமல் காலைதோறும் தம்முடைய நியாயத்தை விளங்கப்பண்ணுகிறார்; அநியாயக்காரனோ வெட்கம் அறியான்.
	<br /><br />
	6. ஜாதிகளைச் சங்கரித்தேன்; அவர்கள் துருகங்கள் பாழாயின; அவர்களுடைய வீதிகளை ஒருவரும் கடந்துபோகாதபடிக்குப் பாழாக்கினேன்; அவர்களுடைய பட்டணங்கள் மனுஷர் இல்லாதபடிக்கும் குடியில்லாதபடிக்கும் அவாந்தரையாயின.
	<br /><br />
	7. உன் வாசஸ்தலம் நிர்மூலமாகாதபடிக்கு நீ எனக்குப் பயந்து, கடிந்துகொள்ளுதலை ஏற்றுக்கொள் என்றேன்; நான் அவர்களை எப்படித் தண்டித்தாலும், அவர்கள் அதிகாலையில் எழுந்து தங்கள் கிரியைகளையெல்லாம் கேடாக்கினார்கள்.
	<br /><br />
	8. ஆகையால் நான் கொள்ளையாட எழும்பும் நாள்மட்டும் எனக்குக் காத்திருங்கள் என்று கர்த்தர் சொல்லுகிறார்; என் சினமாகிய உக்கிர கோபத்தையெல்லாம் அவர்கள்மேல் சொரியும்படி ஜாதிகளைச் சேர்க்கவும், ராஜ்யங்களைக் கூட்டவும் நான் தீர்மானம்பண்ணினேன்; பூமியெல்லாம் என் எரிச்சலின் அக்கினியினால் அழியும்.
	<br /><br />
	9. அப்பொழுது ஜனங்களெல்லாரும் கர்த்தருடைய நாமத்தைத் தொழுதுகொண்டு, ஒருமனப்பட்டு அவருக்கு ஆராதனை செய்யும்படிக்கு, நான் அவர்கள் பாஷையைச் சுத்தமான பாஷையாக மாறப்பண்ணுவேன்.
	<br /><br />
	10. எத்தியோப்பியாவின் நதிகளுக்கு அக்கரையிலிருந்து என்னிடத்தில் விண்ணப்பம்பண்ணுகிறவர்களாகிய சிதறடிக்கப்பட்டவர்களின் குமாரத்தியானவள் எனக்குக் காணிக்கை கொண்டுவருவாள்.
	<br /><br />
	11. எனக்கு விரோதமாய்த் துரோகம்பண்ணி, நீ செய்த உன் எல்லாக் கிரியைகளினிமித்தமும் அந்நாளிலே வெட்கப்படாதிருப்பாய்; அப்பொழுது நான் உன் பெருமையைக்குறித்துக் களிகூர்ந்தவர்களை உன் நடுவிலிருந்து விலக்கிவிடுவேன்; நீ இனி என் பரிசுத்த பர்வதத்தில் அகங்காரங்கொள்ளமாட்டாய்.
	<br /><br />
	12. உன் நடுவில் சிறுமையும் எளிமையுமான ஜனத்தை மீதியாக வைப்பேன்; அவர்கள் கர்த்தருடைய நாமத்தின்மேல் நம்பிக்கையாயிருப்பார்கள்.
	<br /><br />
	13. இஸ்ரவேலில் மீதியானவர்கள் அநியாயஞ்செய்வதில்லை; அவர்கள் பொய் பேசுவதுமில்லை; வஞ்சகநாவு அவர்கள் வாயில் கண்டுபிடிக்கப்படுவதுமில்லை; அவர்கள் தங்களைப் பயப்படுத்துவாரில்லாமல் புசித்துப் படுத்துக்கொள்வார்கள்.
	<br /><br />
	14. சீயோன் குமாரத்தியே, கெம்பீரித்துப்பாடு; இஸ்ரவேலரே, ஆர்ப்பரியுங்கள்; எருசலேம் குமாரத்தியே, நீ முழு இருதயத்தோடும் மகிழ்ந்து களிகூரு.
	<br /><br />
	15. கர்த்தர் உன் ஆக்கினைகளை அகற்றி, உன் சத்துருக்களை விலக்கினார்; இஸ்ரவேலின் ராஜாவாகிய கர்த்தர் உன் நடுவிலே இருக்கிறார்; இனித் தீங்கைக் காணாதிருப்பாய்.
	<br /><br />
	16. அந்நாளிலே எருசலேமைப் பார்த்து, பயப்படாதே என்றும், சீயோனைப் பார்த்து, உன் கைகளைத் தளரவிடாதே என்றும் சொல்லப்படும்.
	<br /><br />
	17. உன் தேவனாகிய கர்த்தர் உன் நடுவில் இருக்கிறார்; அவர் வல்லமையுள்ளவர், அவர் இரட்சிப்பார்; அவர் உன்பேரில் சந்தோஷமாய் மகிழ்ந்து, தம்முடைய அன்பினிமித்தம் அமர்ந்திருப்பார்; அவர் உன்பேரில் கெம்பீரமாய்க் களிகூருவார்.
	<br /><br />
	18. உன் சபையின் மனுஷராயிருந்து, பண்டிகை ஆசரிப்பில்லாமையால் உண்டான நிந்தையினிமித்தம் சஞ்சலப்பட்டவர்களை நான் ஏகமாய்க் கூட்டிக்கொள்ளுவேன்.
	<br /><br />
	19. இதோ, அக்காலத்திலே உன்னைச் சிறுமைப்படுத்தின யாவரையும் தண்டிப்பேன்; நொண்டியானவனை இரட்சித்து, தள்ளுண்டவனைச் சேர்த்துக்கொள்ளுவேன்; அவர்கள் வெட்கம் அநுபவித்த சகல தேசங்களிலும் அவர்களுக்குப் புகழ்ச்சியும் கீர்த்தியும் உண்டாகச் செய்வேன்.
	<br /><br />
	20. அக்காலத்திலே உங்களைக் கூட்டிக்கொண்டுவருவேன்; அக்காலத்திலே உங்களைச் சேர்த்துக்கொள்ளுவேன்; உங்கள் கண்காண நான் உங்கள் சிறையிருப்பைத் திருப்பும்போது, பூமியிலுள்ள சகல ஜனங்களுக்குள்ளும் நான் உங்களைக் கீர்த்தியும் புகழ்ச்சியுமாக வைப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zephaniah3