import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 13 </title>
	<meta
          name="description"
          content="Zechariah 13 | சகரியா 13 | Sageria 13 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாளிலே பாவத்தையும் அழுக்கையும் நீக்க, தாவீதின் குடும்பத்தாருக்கும் எருசலேமின் குடிகளுக்கும் திறக்கப்பட்ட ஒரு ஊற்று உண்டாயிருக்கும்.
	<br /><br />
	2. அந்நாளிலே நான் விக்கிரகங்களின்பேரும் தேசத்தில் இராதபடிக்கு அழிப்பேன்; அவைகள் இனி நினைக்கப்படுவதில்லை; தரிசனம் சொல்லுகிறவர்களையும், அசுத்த ஆவியையும் தேசத்திலிருந்து போய்விடவும் பண்ணுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. இனி ஒருவன் தரிசனம் சொன்னால், அவனைப்பெற்ற அவன் தகப்பனும் அவன் தாயும் அவனை நோக்கி: நீ கர்த்தருடைய நாமத்தைக்கொண்டு பொய்பேசுகிறபடியால் நீ உயிரோடிருக்கப்படாது என்று சொல்லி, அவனைப் பெற்ற அவன் தகப்பனும் அவன் தாயும் அவன் தரிசனம் சொல்லும்போது அவனைக் குத்திப்போடுவார்கள்.
	<br /><br />
	4. அந்நாளிலே தரிசனம் சொல்லுகிற அவனவன் தான் சொன்ன தரிசனத்தினால் வெட்கப்பட்டு, பொய்சொல்லும்படிக்கு இனி மயிர்ப்போர்வையைப் போர்த்துக்கொள்ளாமல்,
	<br /><br />
	5. நான் தரிசனம் சொல்லுகிறவன் அல்ல, நான் நிலத்தைப் பயிரிடுகிறவன்; என் சிறுவயதுமுதல் ஒருவன் என்னை வேலைகொண்டான் என்பான்.
	<br /><br />
	6. அப்பொழுது ஒருவன் அவனை நோக்கி: உன் கைகளில் இருக்கிற இந்த வடுக்கள் ஏதென்று கேட்டால், என் சிநேகிதரின் வீட்டிலே காயப்பட்டதினால் உண்டானவைகள் என்பான்.
	<br /><br />
	7. பட்டயமே, என் மேய்ப்பன்மேலும் என் தோழனாகிய புருஷன்மேலும் எழும்பு என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; மேய்ப்பனை வெட்டு, அப்பொழுது ஆடுகள் சிதறிப்போகும்; ஆனாலும் என் கரத்தைச் சிறுவர்மேல் திரும்ப வைப்பேன்.
	<br /><br />
	8. தேசமெங்கும் சம்பவிக்கும் காரியம் என்னவென்றால், அதில் இருக்கிற இரண்டு பங்கு மனுஷர் சங்கரிக்கப்பட்டு மாண்டுபோவார்கள்; மூன்றாம் பங்கோ அதில் மீதியாயிருக்கும்.
	<br /><br />
	9. அந்த மூன்றாம் பங்கை நான் அக்கினிக்குட்படப்பண்ணி, வெள்ளியை உருக்குகிறதுபோல அவர்களை உருக்கி, பொன்னைப் புடமிடுகிறதுபோல அவர்களைப் புடமிடுவேன்; அவர்கள் என் நாமத்தைத் தொழுதுகொள்வார்கள்; நான் அவர்கள் விண்ணப்பத்தைக் கேட்பேன்; இது என் ஜனமென்று நான் சொல்லுவேன், கர்த்தர் என் தேவன் என்று அவர்கள் சொல்லுவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah13