import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 10 </title>
	<meta
          name="description"
          content="Isaiah 10 | ஏசாயா 10 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஏழைகளை வழக்கிலே தோற்கப்பண்ணவும், என் ஜனத்தில் சிறுமையானவர்களின் நியாயத்தைப் புரட்டவும், விதவைகளைச் சூறையாடவும், திக்கற்ற பிள்ளைகளைக் கொள்ளையிடவும்,
	<br /><br />
	2. அநியாயமான தீர்ப்புகளைச் செய்கிறவர்களுக்கும், கொடுமையான கட்டளைகளை எழுதுகிறவர்களுக்கும் ஐயோ!
	<br /><br />
	3. விசாரிப்பின் நாளிலும், தூரத்திலிருந்து வரும் பாழ்க்கடிப்பின் நாளிலும் நீங்கள் என்னசெய்வீர்கள்? உதவிபெறும்படி யாரிடத்தில் ஓடுவீர்கள்? உங்கள் மகிமையை எங்கே வைத்துவிடுவீர்கள்?
	<br /><br />
	4. கட்டுண்டவர்களின்கீழ் முடங்கினாலொழிய கொலைசெய்யப்பட்டவர்களுக்குள் விழுவார்கள்; இவையெல்லாவற்றிலும் அவருடைய கோபம் ஆறாமல், இன்னும் அவருடைய கை நீட்டினபடியே இருக்கிறது.
	<br /><br />
	5. என் கோபத்தின் கோலாகிய அசீரியனுக்கு ஐயோ! அவன் கையிலிருக்கிறது என் சினத்தின் தண்டாயுதம்.
	<br /><br />
	6. அவபக்தியான ஜனங்களுக்கு விரோதமாய் நான் அவனை அனுப்பி, எனக்குக் கோபமூட்டின ஜனத்தைக் கொள்ளையிடவும், சூறையாடவும், அதை வீதிகளின் சேற்றைப்போல் மிதித்துப்போடவும் அவனுக்குக் கட்டளைகொடுப்பேன்.
	<br /><br />
	7. அவனோ அப்படி எண்ணுகிறதுமில்லை, அவன் இருதயம் அப்படிப்பட்டதை நினைக்கிறதுமில்லை; அநேகம் ஜாதிகளை அழிக்கவும், சங்கரிக்கவுமே தன் மனதிலே நினைவுகொள்ளுகிறான்.
	<br /><br />
	8. அவன்: என் பிரபுக்கள் அனைவரும் ராஜாக்களல்லவோ?
	<br /><br />
	9. கல்னோபட்டணம் கர்கேமிசைப்போலானதில்லையோ? ஆமாத் அர்பாத்தைப்போலானதில்லையோ? சமாரியா தமஸ்குவைப்போலானதில்லையோ?
	<br /><br />
	10. எருசலேமையும் சமாரியாவையும்பார்க்கிலும் விசேஷித்த சிலைகளுள்ள விக்கிரக ராஜ்யங்களை என் கை கண்டுபிடித்திருக்க,
	<br /><br />
	11. நான் சமாரியாவுக்கும், அதின் விக்கிரகங்களுக்கும் செய்ததுபோல், எருசலேமுக்கும் அதின் விக்கிரகங்களுக்கும் செய்யாமலிருப்பேனோ என்று சொல்லுகிறான்.
	<br /><br />
	12. ஆதலால்: ஆண்டவர் சீயோன் மலையிலும் எருசலேமிலும் தமது செயலையெல்லாம் முடித்திருக்கும்போது, அசீரிய ராஜாவினுடைய பெருமையான நெஞ்சின் வினையையும், அவன் கண்களின் மேட்டிமையான பார்வையையும் நான் விசாரிப்பேன் என்கிறார்.
	<br /><br />
	13. அவன்: என் கையின் பெலத்தினாலும், என் ஞானத்தினாலும் இதைச் செய்தேன்; நான் புத்திமான், நான் ஜனங்களின் எல்லைகளை மாற்றி, அவர்கள் பண்டகசாலைகளைக் கொள்ளையிட்டு வல்லவனைப்போல் குடிகளைத் தாழ்த்தினேன்.
	<br /><br />
	14. ஒரு குருவிக்கூட்டைக் கண்டுபிடிக்கிறதுபோல் என் கை ஜனங்களின் ஆஸ்தியைக் கண்டுபிடித்தது; விட்டுவிடப்பட்ட முட்டைகளை வாரிக்கொள்வதுபோல் பூமியையெல்லாம் நான் வாரிக்கொண்டேன்; ஒருவரும் செட்டையை அசைத்ததுமில்லை, வாயைத் திறந்ததுமில்லை, கீச்சென்று சத்தமிட்டதுமில்லை என்று சொல்லுகிறான்.
	<br /><br />
	15. கோடரியானது தன்னால் வெட்டுகிறவனுக்கு விரோதமாய் மேன்மைபாராட்டலாமோ? வாளானது தன்னைக் கையாடுகிறவனுக்கு விரோதமாய்ப் பெருமைபாராட்டலாமோ? பாராட்டினால், தடியானது தன்னைப் பிடித்தவனை மிரட்டினாற்போலவும், கோலானது நான் மரக்கட்டையல்லவென்று எழும்பினாற்போலவும் இருக்குமே.
	<br /><br />
	16. ஆகையால் சேனைகளின் ஆண்டவராகிய கர்த்தர், அவனைச்சேர்ந்த கொழுத்தவர்களுக்குள்ளே இளைப்பை அனுப்புவார்; பட்சிக்கும் அக்கினியைப்போலும் ஒரு அக்கினியை அவன் மகிமையின்கீழ் கொளுத்துவார்.
	<br /><br />
	17. இஸ்ரவேலின் ஒளியானவர் அக்கினியும், அதின் பரிசுத்தர் அக்கினிஜுவாலையுமாகி, ஒரேநாளிலே அவனுடைய முட்செடிகளையும், நெரிஞ்சில்களையும் தகித்துப் பட்சித்து,
	<br /><br />
	18. அவனுடைய வனத்தின் மகிமையையும், அவனுடைய பயிர்நிலத்தின் மகிமையையும், உள்ளும் புறம்புமாய் அழியப்பண்ணுவார்; கொடிபிடிக்கிறவன் களைத்து விழுவதுபோலாகும்.
	<br /><br />
	19. காட்டில் அவனுக்கு மீதியான மரங்கள் கொஞ்சமாயிருக்கும், ஒரு சிறுபிள்ளை அவைகளை எண்ணி எழுதலாம்.
	<br /><br />
	20. அக்காலத்திலே இஸ்ரவேலில் மீதியானவர்களும், யாக்கோபின் வம்சத்தில் தப்பினவர்களும், பின்னொருபோதும் தங்களை அடித்தவனைச் சார்ந்துகொள்ளாமல், இஸ்ரவேலின் பரிசுத்தராகிய கர்த்தரையே உண்மையாய்ச் சார்ந்துகொள்வார்கள்.
	<br /><br />
	21. மீதியாயிருப்பவர்கள், யாக்கோபில் மீதியாயிருப்பவர்களே, வல்லமையுள்ள தேவனிடத்தில் திரும்புவார்கள்.
	<br /><br />
	22. இஸ்ரவேலே, உன் ஜனங்கள் சமுத்திரத்தின் மணலத்தனையாயிருந்தாலும், அவர்களில் மீதியாயிருப்பவர்கள்மாத்திரம் திரும்புவார்கள்; தீர்மானிக்கப்பட்ட அழிவு நிறைந்த நீதியோடே புரண்டுவரும்.
	<br /><br />
	23. சேனைகளின் கர்த்தராகிய ஆண்டவர் தேசத்துக்குள்ளெங்கும் தீர்மானிக்கப்பட்ட அழிவை வரச்செய்வார்.
	<br /><br />
	24. ஆகையால் சீயோனில் வாசமாயிருக்கிற என் ஜனமே, அசீரியனுக்குப் பயப்படாதே; அவன் உன்னைக் கோலால் அடித்து, எகிப்தியரைப்போல் தன் தண்டாயுதத்தை உன்மேல் ஓங்குவான்.
	<br /><br />
	25. ஆனாலும் இன்னும் கொஞ்சக்காலத்துக்குள்ளே என் உக்கிரமும், அவர்களைச் சங்கரிக்கப்போகிறதினால் என் கோபமும் தீர்ந்துபோம் என்று சேனைகளின் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	26. ஓரேப் கன்மலையண்டையிலே மீதியானியர் வெட்டுண்டதுபோல் சேனைகளின் கர்த்தர் அவன்மேல் ஒரு சவுக்கை எழும்பிவரப்பண்ணி, எகிப்திலே தமது கோலைக் கடலின்மேல் ஓங்கினதுபோல் அதை அவன்மேல் ஓங்குவார்.
	<br /><br />
	27. அந்நாளில் உன் தோளினின்று அவன் சுமையும், உன் கழுத்தினின்று அவன் நுகமும் நீக்கப்படும்; அபிஷேகத்தின் நுகம் முறிந்துபோம்.
	<br /><br />
	28. அவன் ஆயாத்துக்கு வந்து, மிக்ரோனைக் கடந்து, மிக்மாசிலே தன் ரஸ்துக்களை வைத்திருக்கிறான்.
	<br /><br />
	29. கணவாயைத் தாண்டி, கேபாவிலே பாளயமிறங்குகிறார்கள்; ராமா அதிர்கிறது; சவுலின் ஊராகிய கிபியா ஓடிப்போகிறது.
	<br /><br />
	30. காலீம் குமாரத்தியே, உரத்த சத்தமாய்க் கூப்பிடு; ஏழை ஆனதோத்தே, லாயீஷ் ஊர்மட்டும் எட்ட சத்தமிட்டுக் கூப்பிடு.
	<br /><br />
	31. மத்மேனா வலசைவாங்கிப்போம், கேபிமின் குடிகள் தப்பிஓடக் கூட்டங்கூடுகிறார்கள்.
	<br /><br />
	32. இனி ஒருநாள் நோபிலே தங்கி, சீயோன் குமாரத்தியின் பர்வதத்துக்கும், எருசலேமின் மேட்டுக்கும் விரோதமாய்க் கை நீட்டி மிரட்டுவான்.
	<br /><br />
	33. இதோ, சேனைகளின் கர்த்தராகிய ஆண்டவர் தோப்புகளைப் பயங்கரமாய் வெட்டுவார்; ஓங்கி வளர்ந்தவைகள் வெட்டுண்டு மேட்டிமையானவைகள் தாழ்த்தப்படும்.
	<br /><br />
	34. அவர் காட்டின் அடர்த்தியைக் கோடரியினாலே வெட்டிப்போடுவார்; மகத்துவமானவராலே லீபனோன் விழும்
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah10