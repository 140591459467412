import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 17 </title>
	<meta
          name="description"
          content="Ezekiel 17 | எசேக்கியேல்  17 |
          Ezekiel 17 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ இஸ்ரவேல் வம்சத்தாருக்கு ஒரு விடுகதையையும் உவமையையும் கூறி, சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	3. கர்த்தராகிய ஆண்டவர் சொல்லுகிறார், பெரிய செட்டைகளையும் நீளமான இறகுகளையும் உடையதும், பலவருணமான இறகுகளால் நிறைந்ததுமாகிய ஒரு பெரிய கழுகு லீபனோனில் வந்து, ஒரு கேதுருவின் நுனிக்கிளையைப் பிடித்து,
	<br /><br />
	4. அதின் இளங்கிளையிலுள்ள கொழுந்துகளைக்கொய்து, அதை வர்த்தக தேசத்துக்குக் கொண்டுபோய், அதை வர்த்தகருடைய நகரத்திலே வைத்தது;
	<br /><br />
	5. தேசத்தின் விதையில் ஒன்றை எடுத்து, அதைப் பயிர்நிலத்திலே போட்டு, அதை எடுத்து, மிகுந்த தண்ணீர் ஓரத்திலே பத்திரமாய் நட்டது.
	<br /><br />
	6. அது துளிர்த்து, படர்ந்து, தாழ்ந்த அடிமரமுள்ள திராட்சச்செடியாயிற்று; அதின்கொடிகள் அந்தக் கழுகுக்கு நேராகவும், அதின் வேர்கள் அதின் கீழாகவும் இருந்தன; இவ்விதமாய் அது திராட்சச்செடியாகி, கிளைகளை வீசி, கொப்புகளை விட்டது.
	<br /><br />
	7. அன்றியும் பெரிய செட்டைகளையும் திரளான இறகுகளையும் உடைய வேறொரு பெரிய கழுகு இருந்தது; இதோ, அது தன் நடவாகிய பாத்திகளிலிருந்து அதற்குத் தண்ணீர் பாய்ச்சும்படி இந்த திராட்சச்செடி அதற்கு நேராகத் தன் வேர்களை விட்டு, அதற்கு நேராகத் தன் கொடிகளை வீசினது.
	<br /><br />
	8. கொப்புகளை விடுகிறதற்கும், கனியைத் தருகிறதற்கும், மகிமையான திராட்சச்செடியாகிறதற்கும், இது மிகுந்த தண்ணீர்களின் ஓரமாகிய நல்ல நிலத்தில் நடப்பட்டிருந்தது.
	<br /><br />
	9. இது செழிக்குமா? இது பட்டுப்போகத்தக்கதாய் ஒருவன் இதின் வேர்களைப் பிடுங்காமலும், இதின் கனியை வெட்டாமலும் இருப்பானோ? துளிர்த்த எல்லா இலைகளோடும் இது பட்டுப்போகும்; இதை வேரோடே பிடுங்கும்படி ஒருவன் பலத்த புயத்தோடும் திரண்ட ஜனத்தோடும் வரத்தேவையில்லை.
	<br /><br />
	10. இதோ, நடப்பட்ட இது செழிப்பாயிருக்குமோ? கொண்டல்காற்று இதின்பேரில் படும்போது இது வாடி உலர்ந்து போகாதோ? இது நடப்பட்ட பாத்திகளிலே வாடிப்போமென்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	11. பின்பு கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	12. இப்போதும் இவைகளின் தாற்பரியம் தெரியுமா என்று நீ கலகவீட்டாரைக்கேட்டுச் சொல்லவேண்டியது என்னவென்றால், இதோ, பாபிலோன் ராஜா எருசலேமுக்கு வந்து, அதின் ராஜாவையும் அதின் பிரபுக்களையும் பிடித்து, அவர்களைத் தன்னிடமாகப் பாபிலோனுக்குக் கொண்டுபோகும்போது,
	<br /><br />
	13. அவன் ராஜவம்சத்திலே ஒருவனைத் தெரிந்தெடுத்து, அவனோடே உடன்படிக்கைபண்ணி,
	<br /><br />
	14. ராஜ்யம் தன்னை உயர்த்தாமல் தாழ்ந்திருக்கும்படிக்கும், தன் உடன்படிக்கையை அவன் கைக்கொள்ளுகிறதினால் அது நிலைநிற்கும்படிக்கும், அவனை ஆணைப்பிரமாணத்துக்கு உட்படுத்தி, தேசத்தில் பலசாலிகளைப் பிடித்துக் கொண்டுபோனானே.
	<br /><br />
	15. இவன் அவனுக்கு விரோதமாய்க் கலகஞ்செய்து, தனக்குக் குதிரைகளையும் அநேகம் ஆட்களையும் அனுப்பவேண்டுமென்று தன் ஸ்தானாபதிகளை எகிப்துக்கு அனுப்பினான்; இப்படிப்பட்டவனுக்கு வாய்க்குமோ? இப்படிச் செய்கிறவன் தப்பித்துக்கொள்வானோ? உடன்படிக்கையை முறித்தவன் தப்பித்துக்கொள்வானோ?
	<br /><br />
	16. தன்னை ராஜாவாக ஏற்படுத்திய ராஜாவினுடைய ஆணையை அசட்டைபண்ணி, அவனுடைய உடன்படிக்கையை முறித்துப்போட்டவன், அந்த ராஜாவினுடைய ஸ்தானமாகிய பாபிலோன் நடுவிலே அவனண்டையில் இருந்து மரணமடைவானென்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	17. அவன் அநேகம் ஜனங்களை நாசம்பண்ணும்படி அணைபோட்டு, கொத்தளங்களைக் கட்டும்போது, பார்வோன் பெரிய சேனையோடும், திரளான கூட்டத்தோடும் வந்து இவனுக்காக யுத்தத்தில் உதவமாட்டான்.
	<br /><br />
	18. இதோ, இவன் கையடித்துக் கொடுத்திருந்தும் உடன்படிக்கையை முறித்துப்போட்டு, ஆணையை அசட்டைபண்ணினான்; இப்படியெல்லாம் செய்தவன் தப்புவதில்லை.
	<br /><br />
	19. அதினிமித்தம் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: அவன் என் ஆணையை அசட்டைபண்ணினதையும், என் உடன்படிக்கையை முறித்துப்போட்டதையும், நான் அவனுடைய தலையின்மேல் வரப்பண்ணுவேன் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன்.
	<br /><br />
	20. அவன் என்னுடைய கண்ணியில் அகப்படும்படிக்கு நான் என் வலையை அவன்மேல் வீசி, அவனைப் பாபிலோனுக்குக் கொண்டுபோய், அவன் எனக்கு விரோதமாய்ப் பண்ணின துரோகத்தினிமித்தம் அங்கே அவனை நியாயம் விசாரிப்பேன்.
	<br /><br />
	21. அவனோடேகூட ஓடிப்போகிற யாவரும் அவனுடைய எல்லா இராணுவங்களும் பட்டயத்தால் விழுவார்கள்; மீதியானவர்களோ சகல திசைகளிலும் சிதறடிக்கப்படுவார்கள்; அப்பொழுது கர்த்தராகிய நான் இதைச் சொன்னேன் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	22. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நான் உயர்ந்த கேதுருவின் நுனிக்கிளைகளில் ஒன்றை எடுத்து, அதை நடுவேன்; அதின் இளங்கிளையிலுள்ள கொழுந்துகளில் இளசாயிருக்கிற ஒன்றைக் கொய்து, அதை உயரமும் உன்னதமுமான ஒரு பர்வதத்தின்மேல் நாட்டுவேன்.
	<br /><br />
	23. இஸ்ரவேலின் உயரமான பர்வதத்திலே அதை நாட்டுவேன்; அது கொப்புகளை விட்டு, கனிதந்து, மகிமையான கேதுருவாகும்; அதின் கீழே சகலவித பட்சிஜாதிகளும் தங்கி, அதின் கிளைகளின் நிழலிலே தாபரிக்கும்.
	<br /><br />
	24. அப்படியே கர்த்தராகிய நான் உயர்ந்த விருட்சத்தைத் தாழ்த்தி, தாழ்ந்த விருட்சத்தை உயர்த்தினேன் என்றும், நான் பச்சையான விருட்சத்தைப் பட்டுப்போகப்பண்ணி, பட்டுப்போன விருட்சத்தைத் தழைக்கப்பண்ணினேன் என்றும் வெளியின் விருட்சங்களுக்கு எல்லாம் தெரியவரும்; கர்த்தராகிய நான் இதைச் சொன்னேன், இதை நிறைவேற்றினேன் என்று உரைத்தார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial17