import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 17 </title>
	<meta
          name="description"
          content="Matthew 17 | மத்தேயு 17 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆறுநாளைக்குப் பின்பு, இயேசு பேதுருவையும் யாக்கோபையும் அவனுடைய சகோதரனாகிய யோவானையும் கூட்டிக்கொண்டு தனித்திருக்கும்படி உயர்ந்த மலையின்மேல் போய்,
	<br /><br />
	2. அவர்களுக்கு முன்பாக மறுரூபமானார்; அவர் முகம் சூரியனைப்போலப் பிரகாசித்தது, அவர் வஸ்திரம் வெளிச்சத்தைப்போல வெண்மையாயிற்று.
	<br /><br />
	3. அப்பொழுது மோசேயும் எலியாவும் அவரோடே பேசுகிறவர்களாக அவர்களுக்குக் காணப்பட்டார்கள்.
	<br /><br />
	4. அப்பொழுது பேதுரு இயேசுவை நோக்கி: ஆண்டவரே, நாம் இங்கே இருக்கிறது நல்லது; உமக்குச் சித்தமானால், இங்கே உமக்கு ஒரு கூடாரமும், மோசேக்கு ஒரு கூடாரமும், எலியாவுக்கு ஒரு கூடாரமுமாக, மூன்று கூடாரங்களைப் போடுவோம் என்றான்.
	<br /><br />
	5. அவன் பேசுகையில், இதோ, ஒளியுள்ள ஒரு மேகம் அவர்கள்மேல் நிழலிட்டது. இவர் என்னுடைய நேசகுமாரன், இவரில் பிரியமாயிருக்கிறேன், இவருக்குச் செவிகொடுங்கள் என்று அந்த மேகத்திலிருந்து ஒரு சத்தம் உண்டாயிற்று.
	<br /><br />
	6. சீஷர்கள் அதைக் கேட்டு, முகங்குப்புற விழுந்து, மிகவும் பயந்தார்கள்.
	<br /><br />
	7. அப்பொழுது, இயேசு வந்து, அவர்களைத் தொட்டு: எழுந்திருங்கள், பயப்படாதேயுங்கள் என்றார்.
	<br /><br />
	8. அவர்கள் தங்கள் கண்களை ஏறெடுத்துப் பார்க்கையில் இயேசுவைத்தவிர வேறொருவரையும் காணவில்லை.
	<br /><br />
	9. அவர்கள் மலையிலிருந்து இறங்குகிறபோது, இயேசு அவர்களை நோக்கி: மனுஷகுமாரன் மரித்தோரிலிருந்து எழுந்திருக்கும்வரைக்கும் இந்தத் தரிசனத்தை ஒருவருக்கும் சொல்லவேண்டாம் என்று கட்டளையிட்டார்.
	<br /><br />
	10. அப்பொழுது, அவருடைய சீஷர்கள் அவரை நோக்கி: அப்படியானால் எலியா முந்தி வரவேண்டும் என்று வேதபாரகர் சொல்லுகிறார்களே, அதெப்படி யென்று கேட்டார்கள்.
	<br /><br />
	11. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: எலியா முந்திவந்து எல்லாவற்றையும் சீர்ப்படுத்துவது மெய்தான்.
	<br /><br />
	12. ஆனாலும், எலியா வந்தாயிற்று என்று உங்களுக்குச் சொல்லுகிறேன்; அவனை அறியாமல் தங்கள் இஷ்டப்படி அவனுக்குச் செய்தார்கள்; இவ்விதமாய் மனுஷகுமாரனும் அவர்களால் பாடுபடுவார் என்றார்.
	<br /><br />
	13. அவர் யோவான்ஸ்நானனைக்குறித்துத் தங்களுக்குச் சொன்னார் என்று சீஷர்கள் அப்பொழுது அறிந்துகொண்டார்கள்.
	<br /><br />
	14. அவர்கள் ஜனங்களிடத்தில் வந்தபோது, ஒரு மனுஷன் அவரிடத்தில் வந்து, அவர் முன்பாக முழங்கால்படியிட்டு:
	<br /><br />
	15. ஆண்டவரே, என் மகனுக்கு இரங்கும், அவன் சந்திரரோகியாய்க் கொடிய வேதனைப்படுகிறான்; அடிக்கடி தீயிலும், அடிக்கடி ஜலத்திலும் விழுகிறான்.
	<br /><br />
	16. அவனை உம்முடைய சீஷர்களிடத்தில் கொண்டுவந்தேன்; அவனைச் சொஸ்தமாக்க அவர்களால் கூடாமற்போயிற்று என்றான்.
	<br /><br />
	17. இயேசு பிரதியுத்தரமாக: விசுவாசமில்லாத மாறுபாடுள்ள சந்ததியே, எதுவரைக்கும் நான் உங்களோடு இருப்பேன்? எதுவரைக்கும் உங்களிடத்தில் பொறுமையாய் இருப்பேன்? அவனை என்னிடத்தில் கொண்டுவாருங்கள் என்றார்.
	<br /><br />
	18. இயேசு பிசாசை அதட்டினார்; உடனே அது அவனை விட்டுப் புறப்பட்டது; அந்நேரமே அந்த இளைஞன் சொஸ்தமானான்.
	<br /><br />
	19. அப்பொழுது, சீஷர்கள் இயேசுவினிடத்தில் தனித்துவந்து: அதைத் துரத்திவிட எங்களால் ஏன் கூடாமற்போயிற்று என்று கேட்டார்கள்.
	<br /><br />
	20. அதற்கு இயேசு: உங்கள் அவிசுவாசத்தினாலேதான்; கடுகுவிதையளவு விசுவாசம் உங்களுக்கு இருந்தால் நீங்கள் இந்த மலையைப் பார்த்து, இவ்விடம் விட்டு அப்புறம்போ என்று சொல்ல அது அப்புறம் போம்; உங்களால் கூடாதகாரியம் ஒன்றுமிராது என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	21. இந்த ஜாதிப் பிசாசு ஜெபத்தினாலும் உபவாசத்தினாலுமேயன்றி மற்றெவ்விதத்தினாலும் புறப்பட்டுப்போகாது என்றார்.
	<br /><br />
	22. அவர்கள் கலிலேயாவிலே சஞ்சரிக்கும்போது, இயேசு அவர்களை நோக்கி: மனுஷகுமாரன் மனுஷர் கைகளில் ஒப்புக்கொடுக்கப்படுவார்.
	<br /><br />
	23. அவர்கள் அவரைக் கொலைசெய்வார்கள்; ஆகிலும் மூன்றாம் நாளிலே உயிர்த்தெழுந்திருப்பார் என்றார். அவர்கள் மிகுந்த துக்கமடைந்தார்கள்.
	<br /><br />
	24. அவர்கள் கப்பர்நகூமில் வந்தபோது, வரிப்பணம் வாங்குகிறவர்கள் பேதுருவினிடத்தில் வந்து: உங்கள் போதகர் வரிப்பணம் செலுத்துகிறதில்லையா என்று கேட்டார்கள்; செலுத்துகிறார் என்றான்.
	<br /><br />
	25. அவன் வீட்டிற்குள் வந்தபோது, அவன் பேசுகிறதற்கு முன்னமே இயேசு அவனை நோக்கி: சீமோனே, உனக்கு எப்படித் தோன்றுகிறது? பூமியின் ராஜாக்கள் தீர்வையையும் வரியையும் தங்கள் பிள்ளைகளிடத்திலோ, அந்நியரிடத்திலோ, யாரிடத்தில் வாங்குகிறார்கள் என்று கேட்டார்.
	<br /><br />
	26. அதற்குப் பேதுரு: அந்நியரிடத்தில் வாங்குகிறார்கள் என்றான். இயேசு அவனை நோக்கி: அப்படியானால் பிள்ளைகள் அதைச் செலுத்தவேண்டுவதில்லையே.
	<br /><br />
	27. ஆகிலும், நாம் அவர்களுக்கு இடறலாயிராதபடிக்கு, நீ கடலுக்குப் போய், தூண்டில்போட்டு, முதலாவது அகப்படுகிற மீனைப் பிடித்து, அதின் வாயைத் திறந்துபார்; ஒரு வெள்ளிப்பணத்தைக் காண்பாய்; அதை எடுத்து எனக்காகவும் உனக்காகவும் அவர்களிடத்தில் கொடு என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew17