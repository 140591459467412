import React from 'react';
import { Link } from 'react-router-dom';
import home from '../home.png';

const Header = ({ verseNumber }) => {

    const nextVerseNumber = verseNumber + 1;
  const backVerseNumber = verseNumber - 1;
  return (
    <header className='vh'>
      <div>
            <h1 className="verseh">
            1 கொரிந்தியர்  
            </h1>
            <span className='verseno'>
                {verseNumber}
            </span>
            
        </div>
      <div className="navigation">
        <div className='vbn'>
            {backVerseNumber > 0 && (
          <Link to={`/1-Corinthians-${backVerseNumber}`}>
            <span className='verseb'></span>
          </Link>
        )}
      <span className='versehome'>
        <Link to="/1-Corinthians">
          <img src={home} alt='home' />
        </Link>
      </span>{nextVerseNumber <= 16 && (
          <Link to={`/1-Corinthians-${nextVerseNumber}`}>
            <span className='versen'></span>
          </Link>
        )}
        </div>
        </div>
    </header>
  );
};

export default Header;
