import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 13 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 13 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 13 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் மனுஷர் பாஷைகளையும் தூதர் பாஷைகளையும் பேசினாலும், அன்பு எனக்கிராவிட்டால், சத்தமிடுகிற வெண்கலம்போலவும், ஓசையிடுகிற கைத்தாளம்போலவும் இருப்பேன்.
	<br /><br />
	2. நான் தீர்க்கதரிசன வரத்தை உடையவனாயிருந்து, சகல இரகசியங்களையும், சகல அறிவையும் அறிந்தாலும், மலைகளைப் பேர்க்கத்தக்கதாகச் சகல விசுவாசமுள்ளவனாயிருந்தாலும், அன்பு எனக்கிராவிட்டால் நான் ஒன்றுமில்லை.
	<br /><br />
	3. எனக்கு உண்டான யாவற்றையும் நான் அன்னதானம்பண்ணினாலும், என் சரீரத்தைச் சுட்டெரிக்கப்படுவதற்குக் கொடுத்தாலும், அன்பு எனக்கிராவிட்டால் எனக்குப் பிரயோஜனம் ஒன்றுமில்லை.
	<br /><br />
	4. அன்பு நீடிய சாந்தமும் தயவுமுள்ளது; அன்புக்குப் பொறாமையில்லை; அன்பு தன்னைப் புகழாது, இறுமாப்பாயிராது,
	<br /><br />
	5. அயோக்கியமானதைச் செய்யாது, தற்பொழிவை நாடாது, சினமடையாது, தீங்கு நினையாது,
	<br /><br />
	6. அநியாயத்தில் சந்தோஷப்படாமல், சத்தியத்தில் சந்தோஷப்படும்.
	<br /><br />
	7. சகலத்தையும் தாங்கும், சகலத்தையும் விசுவாசிக்கும், சகலத்தையும் நம்பும், சகலத்தையும் சகிக்கும்.
	<br /><br />
	8. அன்பு ஒருக்காலும் ஒழியாது. தீர்க்கதரிசனங்களானாலும் ஒழிந்துபோம், அந்நிய பாஷைகளானாலும் ஓய்ந்துபோம், அறிவானாலும் ஒழிந்துபோம்.
	<br /><br />
	9. நம்முடைய அறிவு குறைவுள்ளது, நாம் தீர்க்கதரிசனஞ் சொல்லுதலும் குறைவுள்ளது.
	<br /><br />
	10. நிறைவானது வரும்போது குறைவானது ஒழிந்துபோம்.
	<br /><br />
	11. நான் குழந்தையாயிருந்தபோது குழந்தையைப்போலப் பேசினேன், குழந்தையைப்போலச் சிந்தித்தேன், குழந்தையைப்போல யோசித்தேன்; நான் புருஷனானபோதோ குழந்தைக்கேற்றவைகளை ஒழித்துவிட்டேன்.
	<br /><br />
	12. இப்பொழுது கண்ணாடியிலே நிழலாட்டமாய்ப் பார்க்கிறோம், அப்பொழுது முகமுகமாய்ப் பார்ப்போம்; இப்பொழுது நான் குறைந்த அறிவுள்ளவன், அப்பொழுது நான் அறியப்பட்டிருக்கிறபடியே அறிந்துகொள்ளுவேன்.
	<br /><br />
	13. இப்பொழுது விசுவாசம், நம்பிக்கை, அன்பு இம்மூன்றும் நிலைத்திருக்கிறது; இவைகளில் அன்பே பெரியது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians13