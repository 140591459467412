import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 7 </title>
	<meta
          name="description"
          content="Song of Solomon 7 | உன்னதப்பாட்டு 7 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ராஜகுமாரத்தியே! பாதரட்சைகளிட்ட உன் பாதங்கள் மிகவும் அழகாயிருக்கிறது; உன் இடுப்பின் வடிவு விசித்திரத் தொழிற்காரரின் வேலையாகிய பூஷணம்போலிருக்கிறது.
	<br /><br />
	2. உன் நாபி திராட்சரசம் நிறைந்த வட்டக்கலசம்போலிருக்கிறது; உன் வயிறு லீலிபுஷ்பங்கள் சூழ்ந்த கோதுமை அம்பாரம்போலிருக்கிறது.
	<br /><br />
	3. உன் இரண்டு ஸ்தனங்களும் வெளிமானின் இரட்டைக்குட்டிகளுக்குச் சமானமாயிருக்கிறது.
	<br /><br />
	4. உன் கழுத்து யானைத்தந்தத்தினால் செய்த கோபுரத்தைப்போலவும், உன் கண்கள் எஸ்போனிலே பத்ரபீம் வாசலண்டையிலிருக்கும் குளங்களைப்போலவும், உன் மூக்கு தமஸ்குவின் திசையை நோக்கும் லீபனோனின் கோபுரத்தைப்போலவும் இருக்கிறது.
	<br /><br />
	5. உன் சிரசு கர்மேல் மலையைப்போலிருக்கிறது; உன் தலைமயிர் இரத்தாம்பரமயமாயிருக்கிறது; ராஜா நடைக்காவணங்களில் மயங்கிநிற்கிறார்.
	<br /><br />
	6. மனமகிழ்ச்சியை உண்டாக்கும் என் பிரியமே! நீ எவ்வளவு ரூபவதி, நீ எவ்வளவு இன்பமுள்ளவள்.
	<br /><br />
	7. உன் உயரம் பனைமரத்தொழுங்குபோலவும், உன் ஸ்தனங்கள் திராட்சக்குலைகள்போலவும் இருக்கிறது.
	<br /><br />
	8. நான் பனைமரத்திலேறி, அதின் மடல்களைப் பிடிப்பேன் என்றேன்; இப்பொழுதும் உன் ஸ்தனங்கள் திராட்சக்குலைகள்போலவும், உன் மூக்கின் வாசனை கிச்சிலிப்பழங்கள்போலவும் இருக்கிறது.
	<br /><br />
	9. உன் தொண்டை, என் நேசர் குடிக்கையில் மெதுவாயிறங்குகிறதும், உறங்குகிறவர்களின் உதடுகளைப் பேசப்பண்ணுகிறதுமான, நல்ல திராட்சரசத்தைப்போலிருக்கிறது.
	<br /><br />
	10. நான் என் நேசருடையவள், அவர் பிரியம் என்மேலிருக்கிறது.
	<br /><br />
	11. வாரும் என் நேசரே! வயல்வெளியில் போய், கிராமங்களில் தங்குவோம்.
	<br /><br />
	12. அதிகாலையிலே திராட்சத்தோட்டங்களுக்குப் போவோம்; திராட்சக்கொடி துளிர்த்து அதின் பூ மலர்ந்ததோ என்றும், மாதளஞ்செடிகள் பூப்பூத்ததோ என்றும் பார்ப்போம்; அங்கே என் நேசத்தின் உச்சிதங்களை உமக்குத் தருவேன்.
	<br /><br />
	13. தூதாயீம் பழம் வாசனை வீசும்; நமது வாசல்களண்டையிலே புதியவைகளும் பழையவைகளுமான சகலவித அருமையான கனிகளுமுண்டு; என் நேசரே! அவைகளை உமக்கு வைத்திருக்கிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs7