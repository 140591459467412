import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Malachi1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மல்கியா 1 </title>
	<meta
          name="description"
          content="Malachi 1 | மல்கியா 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மல்கியாவைக்கொண்டு கர்த்தர் இஸ்ரவேலுக்குச் சொன்ன வார்த்தையின் பாரம்.
	<br /><br />
	2. நான் உங்களைச் சிநேகித்தேன் என்று கர்த்தர் சொல்லுகிறார்; அதற்கு நீங்கள்: எங்களை எப்படிச் சிநேகித்தீர் என்கிறீர்கள்; கர்த்தர் சொல்லுகிறார்: ஏசா யாக்கோபுக்குச் சகோதரன் அல்லவோ? ஆகிலும் யாக்கோபை நான் சிநேகித்தேன்.
	<br /><br />
	3. ஏசாவையோ நான் வெறுத்தேன்; அவனுடைய மலைகளைப் பாழும், அவனுடைய சுதந்தரத்தை வனாந்தரத்திலுள்ள வலுசர்ப்பங்களின் தாவும் ஆக்கினேன்.
	<br /><br />
	4. ஏதோமியர்: நாம் எளிமைப்பட்டோம்; ஆனாலும் பாழானவைகளைத் திரும்பக் கட்டுவோம் என்று சொல்லுகிறார்கள்; அதற்குக் கர்த்தர்: அவர்கள் கட்டுவார்கள், நான் இடிப்பேன். அவர்கள் துன்மார்க்கத்தின் எல்லையென்றும், கர்த்தர் என்றைக்கும் சினம் வைக்கிற ஜனமென்றும் சொல்லப்படுவார்கள் என்கிறார்.
	<br /><br />
	5. இதை உங்கள் கண்கள் காணும். அப்பொழுது நீங்கள்: கர்த்தர் இஸ்ரவேலுடைய எல்லை துவக்கி மகிமைப்படுத்தப்படுவார் என்பீர்கள்.
	<br /><br />
	6. குமாரன் தன் பிதாவையும், ஊழியக்காரன் தன் எஜமானையும் கனம்பண்ணுகிறார்களே; நான் பிதாவானால் என் கனம் எங்கே? நான் எஜமானானால் எனக்குப் பயப்படும் பயம் எங்கே என்று சேனைகளின் கர்த்தர் தமது நாமத்தை அசட்டைபண்ணுகிற ஆசாரியர்களாகிய உங்களைக் கேட்கிறார்; அதற்கு நீங்கள்: உமது நாமத்தை எதினாலே அசட்டைபண்ணினோம் என்கிறீர்கள்.
	<br /><br />
	7. என் பீடத்தின்மேல் அசுத்தமான அப்பத்தைப் படைக்கிறதினாலேயே; ஆனாலும் உம்மை எதினாலே அசுத்தப்படுத்தினோம் என்கிறீர்கள்; கர்த்தருடைய பந்தி எண்ணமற்றுப்போயிற்றென்று நீங்கள் சொல்லுகிறதினாலேயே.
	<br /><br />
	8. நீங்கள் கண் ஊனமானதைப் பலியிடக்கொண்டுவந்தாலும் அது பொல்லாப்பல்ல, நீங்கள் காலூனமானதையும் நசல் பிடித்ததையும் கொண்டுவந்தாலும் அது பொல்லாப்பல்ல என்கிறீர்களே; அதை நீ உன் அதிபதிக்குச் செலுத்து, அவன் உன்மேல் பிரியமாயிருப்பானோ? உன் முகத்தைப் பார்ப்பானோ என்று சேனைகளின் கர்த்தர் கேட்கிறார்.
	<br /><br />
	9. இப்போதும் தேவனுடைய சமுகத்தை நோக்கிக் கெஞ்சுங்கள்; அப்பொழுது நம்மேல் இரங்குவார்; இது உங்களாலே வந்த காரியம், அவர் உங்களை அங்கீகரிப்பாரோ என்று சேனைகளின் கர்த்தர் கேட்கிறார்.
	<br /><br />
	10. உங்களில் எவன் கூலியில்லாமல் கதவுகளைப் பூட்டுவான்; என் பலிபீடத்தின்மேல் அக்கினியைக் கூலியில்லாமல் கொளுத்தவுமாட்டீர்கள்; உங்கள்பேரில் எனக்குப் பிரியமில்லையென்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; உங்கள் கைகளிலுள்ள காணிக்கை எனக்கு உகந்ததல்ல.
	<br /><br />
	11. சூரியன் உதிக்கிற திசைதொடங்கி, அது அஸ்தமிக்கிற திசைவரைக்கும், என் நாமம் ஜாதிகளுக்குள்ளே மகத்துவமாயிருக்கும்; எல்லா இடங்களிலும் என் நாமத்துக்குத் தூபமும் சுத்தமான காணிக்கையும் செலுத்தப்படும்; என் நாமம் ஜாதிகளுக்குள்ளே மகத்துவமாயிருக்கும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. நீங்களோ கர்த்தருடைய பந்தி அசுத்தமானது என்றும், அதின் ஆகாரமாகிய அதின் பலன் அற்பமானது என்றும் சொல்லுகிறதினாலே, என் நாமத்தைப் பரிசுத்தக்குலைச்சலாக்குகிறீர்கள்.
	<br /><br />
	13. இதோ, இது எவ்வளவு வருத்தமென்று சொல்லி, அதை ஒரு திரணமாய்ப் பேசி, பீறுண்டதையும் கால் ஊனமானதையும் நசல்கொண்டதையும் கொண்டுவந்து காணிக்கையாகச் செலுத்துகிறீர்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; அதை உங்கள் கைகளில் அங்கீகரித்துக்கொள்வேனோ என்று கர்த்தர் கேட்கிறார்.
	<br /><br />
	14. தன் மந்தையில் கடா இருக்கையில் கெட்டுப்போனதை ஆண்டவருக்கு நேர்ந்துகொண்டு பலியிடுகிற கபடஸ்தன் சபிக்கப்பட்டவன்; என் நாமம் ஜாதிகளுக்குள்ளே பயங்கரமாயிருக்கும்; நான் மகத்துவமான ராஜா என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Malachi1