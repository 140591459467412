import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Colossians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Book of Colossians Bible in Tamil | கொலோசெயர் 3 </title>
	<meta
          name="description"
          content="Colossians 3 | கொலோசெயர் 3 |
          Book of Colossians | Tamil Bible Verse | Tamil Bible | கொலோசெயருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. நீங்கள் கிறிஸ்துவுடன்கூட எழுந்ததுண்டானால், கிறிஸ்து தேவனுடைய வலதுபாரிசத்தில் வீற்றிருக்கும் இடத்திலுள்ள மேலானவைகளைத் தேடுங்கள்.
	<br /><br />
	2. பூமியிலுள்ளவைகளையல்ல, மேலானவைகளையே நாடுங்கள்.
	<br /><br />
	3. ஏனென்றால், நீங்கள் மரித்தீர்கள், உங்கள் ஜீவன் கிறிஸ்துவுடனே தேவனுக்குள் மறைந்திருக்கிறது.
	<br /><br />
	4. நம்முடைய ஜீவனாகிய கிறிஸ்து வெளிப்படும்போது, நீங்களும் அவரோடேகூட மகிமையிலே வெளிப்படுவீர்கள்.
	<br /><br />
	5. ஆகையால், விபசாரம், அசுத்தம், மோகம், துர்இச்சை, விக்கிரகாராதனையான பொருளாசை ஆகிய இவைகளைப் பூமியில் உண்டுபண்ணுகிற உங்கள் அவயவங்களை அழித்துப்போடுங்கள்.
	<br /><br />
	6. இவைகளின்பொருட்டே கீழ்ப்படியாமையின் பிள்ளைகள்மேல் தேவகோபாக்கினை வரும்.
	<br /><br />
	7. நீங்களும் முற்காலத்தில் அவர்களுக்குள்ளே சஞ்சரித்தபோது, அவைகளைச் செய்துகொண்டுவந்தீர்கள்.
	<br /><br />
	8. இப்பொழுதோ கோபமும் மூர்க்கமும் பொறாமையும், உங்கள் வாயில் பிறக்கலாகாத தூஷணமும் வம்பு வார்த்தைகளுமாகிய இவைகளையெல்லாம் விட்டுவிடுங்கள்.
	<br /><br />
	9. ஒருவருக்கொருவர் பொய் சொல்லாதிருங்கள்; பழைய மனுஷனையும் அவன் செய்கைகளையும் களைந்துபோட்டு,
	<br /><br />
	10. தன்னைச் சிருஷ்டித்தவருடைய சாயலுக்கொப்பாய்ப் பூரண அறிவடையும்படி புதிதாக்கப்பட்ட புதிய மனுஷனைத் தரித்துக்கொண்டிருக்கிறீர்களே.
	<br /><br />
	11. அதிலே கிரேக்கனென்றும் யூதனென்றுமில்லை, விருத்தசேதனமுள்ளவனென்றும் விருத்தசேதனமில்லாதவனென்றுமில்லை, புறஜாதியானென்றும் புறதேசத்தானென்றுமில்லை, அடிமையென்றும் சுயாதீனனென்றுமில்லை; கிறிஸ்துவே எல்லாரிலும் எல்லாமுமாயிருக்கிறார்.
	<br /><br />
	12. ஆகையால், நீங்கள் தேவனால் தெரிந்துகொள்ளப்பட்ட பரிசுத்தரும் பிரியருமாய், உருக்கமான இரக்கத்தையும், தயவையும், மனத்தாழ்மையையும், சாந்தத்தையும், நீடிய பொறுமையையும் தரித்துக்கொண்டு;
	<br /><br />
	13. ஒருவரையொருவர் தாங்கி, ஒருவர்பேரில் ஒருவருக்குக் குறைபாடு உண்டானால், கிறிஸ்து உங்களுக்கு மன்னித்ததுபோல, ஒருவருக்கொருவர் மன்னியுங்கள்.
	<br /><br />
	14. இவை எல்லாவற்றின்மேலும், பூரண சற்குணத்தின் கட்டாகிய அன்பைத் தரித்துக்கொள்ளுங்கள்.
	<br /><br />
	15. தேவசமாதானம் உங்கள் இருதயங்களில் ஆளக்கடவது, இதற்கென்றே நீங்கள் ஒரே சரீரமாக அழைக்கப்பட்டீர்கள்; நன்றியறிதலுள்ளவர்களாயுமிருங்கள்.
	<br /><br />
	16. கிறிஸ்துவின் வசனம் உங்களுக்குள்ளே சகல ஞானத்தோடும் பரிபூரணமாக வாசமாயிருப்பதாக; சங்கீதங்களினாலும் கீர்த்தனைகளினாலும் ஞானப்பாட்டுகளினாலும் ஒருவருக்கொருவர் போதித்துப் புத்திசொல்லிக்கொண்டு, உங்கள் இருதயத்திலே கர்த்தரைப் பக்தியுடன் பாடி;
	<br /><br />
	17. வார்த்தையினாலாவது கிரியையினாலாவது, நீங்கள் எதைச் செய்தாலும், அதையெல்லாம் கர்த்தராகிய இயேசுவின் நாமத்தினாலே செய்து, அவர் முன்னிலையாகப் பிதாவாகிய தேவனை ஸ்தோத்திரியுங்கள்.
	<br /><br />
	18. மனைவிகளே, கர்த்தருக்கேற்கும்படி, உங்கள் புருஷருக்குக் கீழ்ப்படியுங்கள்.
	<br /><br />
	19. புருஷர்களே, உங்கள் மனைவிகளில் அன்புகூருங்கள், அவர்கள்மேல் கசந்துகொள்ளாதிருங்கள்.
	<br /><br />
	20. பிள்ளைகளே, உங்களைப் பெற்றாருக்கு எல்லாக் காரியத்திலேயும் கீழ்ப்படியுங்கள்; இது கர்த்தருக்குப் பிரியமானது.
	<br /><br />
	21. பிதாக்களே, உங்கள் பிள்ளைகள் திடனற்றுப்போகாதபடி, அவர்களுக்குக் கோபமூட்டாதிருங்கள்.
	<br /><br />
	22. வேலைக்காரரே, சரீரத்தின்படி உங்கள் எஜமான்களாயிருக்கிறவர்களுக்கு எல்லாக் காரியத்திலேயும் கீழ்ப்படிந்து, நீங்கள் மனுஷருக்குப் பிரியமாயிருக்க விரும்புகிறவர்களாகப் பார்வைக்கு ஊழியஞ்செய்யாமல், தேவனுக்குப் பயப்படுகிறவர்களாகக் கபடமில்லாத இருதயத்தோடே ஊழியஞ்செய்யுங்கள்.
	<br /><br />
	23. நீங்கள் கர்த்தராகிய கிறிஸ்துவைச் சேவிக்கிறதினாலே, சுதந்தரமாகிய பலனைக் கர்த்தராலே பெறுவீர்களென்று அறிந்து,
	<br /><br />
	24. எதைச் செய்தாலும், அதை மனுஷர்களுக்கென்று செய்யாமல், கர்த்தருக்கென்றே மனப்பூர்வமாய்ச் செய்யுங்கள்.
	<br /><br />
	25. அநியாயஞ்செய்கிறவன் தான் செய்த அநியாயத்துக்கேற்ற பலனை அடைவான்; பட்சபாதமே இல்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Colossians3