import React from 'react';
import './contact.css';

import DonateButton from './DonateButton';
import qr from './assets/upi-qr.jpg'
import VerseOfTheDay from './VerseOfTheDay';

import { Helmet } from 'react-helmet';
import { IonIcon } from '@ionic/react';
import { logoFacebook } from 'ionicons/icons';
import { logoTwitter } from 'ionicons/icons';
import { logoInstagram } from 'ionicons/icons';
import { logoYoutube } from 'ionicons/icons';
import { logoPinterest } from 'ionicons/icons';
import { logoWhatsapp } from 'ionicons/icons';
import { send } from 'ionicons/icons';

function Contact (){
return (
	<>
    <Helmet>
        <title>Contact | Unveiling the Beauty of Tamil Bible Verse</title>
        <meta
          name="description"
          content="Contact Us. Select Page. About Us; Our Work; Our Projects; Get Involved; Home · Support Us · Online Store · தமிழ். Tamil.Bible. Explore the Tamil Bible"
        />
      </Helmet>
	  <VerseOfTheDay />
	  <br /><br/><br/>
	  <div className='contact_container'>
	  <div className='contact'>
			<h1>Contact</h1>
			<div className='social'>
				<a href="https://www.fb.com/tamilbible">			<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoFacebook} />		</a>
				<a href="https://www.instagram.com/tamilbible">		<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoInstagram} />		</a>
				<a href="https://www.twitter.com/TamilBibleWords">	<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoTwitter} />			</a>
				<a href="https://www.youtube.com/thamilbible">		<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoYoutube} />			</a>
				<a href="https://www.pinterest.com/tamil_bible">	<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoPinterest} />		</a>
				<a href="https://wa.me/+919444888727">				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoWhatsapp} />		</a>
				<a href="https://www.t.me/tamilverse">				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={send} />			</a>
			</div>
			<DonateButton />
			<p>
				<span  className='greenish_blue_color' ><a className='greenish_blue_color' href='https://linkfly.to/tamilbible'> TAMIL BIBLE </a></span> <br/><br />
				Evg: Jegan.M<br/>
				No 1, Charlu Street, <br />
				Vandalur, Chennai -48, <br />
				Tamil Nadu , India.<br />
				☎ +91 9444888727 <br />
				<br />
			</p>
		</div>
		<img src={qr} className='qr' alt='upi qr code evg Jegan'/>
    <form name='contact' method='post'>
        <h3>Feed Here</h3>

		<input type='hidden' name='form-name' value='contact'/>

        <label htmlfor="name">Name</label>
        <input name='name' type="text" placeholder="Enter" id="Name"/>

        <label htmlfor="email">Email & Phone</label>
        <input name='email' type="text" placeholder="Email or Phone" id="EmailorPhone"/>

		<label htmlfor="feedback">Feed Back</label>
        <input name='feedback' type="text" placeholder="Type Here...." id="Feedback"/>
        <button className='contact_form_send'>Send</button>
    </form>
	  </div>
	</>
);
	
}

export default Contact;
