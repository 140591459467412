import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 10 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 10 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 10 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்படியிருக்க, சகோதரரே, நீங்கள் எவைகளை அறியவேண்டுமென்றிருக்கிறேனென்றால்; நம்முடைய பிதாக்களெல்லாரும் மேகத்துக்குக் கீழாயிருந்தார்கள், எல்லாரும் சமுத்திரத்தின் வழியாய் நடந்துவந்தார்கள்.
	<br /><br />
	2. எல்லாரும் மோசேக்குள்ளாக மேகத்தினாலும் சமுத்திரத்தினாலும் ஞானஸ்நானம்பண்ணப்பட்டார்கள்.
	<br /><br />
	3. எல்லாரும் ஒரே ஞானபோஜனத்தைப் புசித்தார்கள்.
	<br /><br />
	4. எல்லாரும் ஒரே ஞானபானத்தைக் குடித்தார்கள். எப்படியெனில், அவர்களோடேகூடச் சென்ற ஞானக்கன்மலையின் தண்ணீரைக் குடித்தார்கள்; அந்தக் கன்மலை கிறிஸ்துவே.
	<br /><br />
	5. அப்படியிருந்தும், அவர்களில் அதிகமானபேர்களிடத்தில் தேவன் பிரியமாயிருந்ததில்லை; ஆதலால் வனாந்தரத்திலே அவர்கள் அழிக்கப்பட்டார்கள்.
	<br /><br />
	6. அவர்கள் இச்சித்ததுபோல நாமும் பொல்லாங்கானவைகளை இச்சியாதபடிக்கு, இவைகள் நமக்குத் திருஷ்டாந்தங்களாயிருக்கிறது.
	<br /><br />
	7. ஜனங்கள் புசிக்கவும் குடிக்கவும் உட்கார்ந்து, விளையாட எழுந்தார்கள் என்று எழுதியிருக்கிறபடி, அவர்களில் சிலர் விக்கிரகாராதனைக்காரர் ஆனதுபோல நீங்களும் ஆகாதிருங்கள்.
	<br /><br />
	8. அவர்களில் சிலர் வேசித்தனம்பண்ணி, ஒரேநாளில் இருபத்துமூவாயிரம்பேர் விழுந்துபோனார்கள்; அதுபோல நாமும் வேசித்தனம்பண்ணாதிருப்போமாக.
	<br /><br />
	9. அவர்களில் சிலர் கிறிஸ்துவைப் பரீட்சைபார்த்து, பாம்புகளால் அழிக்கப்பட்டார்கள்; அதுபோல நாமும் கிறிஸ்துவைப் பரீட்சைபாராதிருப்போமாக.
	<br /><br />
	10. அவர்களில் சிலர் முறுமுறுத்து, சங்காரக்காரனாலே அழிக்கப்பட்டார்கள்; அதுபோல நீங்களும் முறுமுறுக்காதிருங்கள்.
	<br /><br />
	11. இவைகளெல்லாம் திருஷ்டாந்தங்களாக அவர்களுக்குச் சம்பவித்தது; உலகத்தின் முடிவுகாலத்திலுள்ள நமக்கு எச்சரிப்புண்டாக்கும்படி எழுதப்பட்டும் இருக்கிறது.
	<br /><br />
	12. இப்படியிருக்க, தன்னை நிற்கிறவனென்று எண்ணுகிறவன் விழாதபடிக்கு எச்சரிக்கையாயிருக்கக்கடவன்.
	<br /><br />
	13. மனுஷருக்கு நேரிடுகிற சோதனையேயல்லாமல் வேறே சோதனை உங்களுக்கு நேரிடவில்லை. தேவன் உண்மையுள்ளவராயிருக்கிறார்; உங்கள் திராணிக்கு மேலாக நீங்கள் சோதிக்கப்படுகிறதற்கு அவர் இடங்கொடாமல், சோதனையைத் தாங்கத்தக்கதாக, சோதனையோடுகூட அதற்குத் தப்பிக்கொள்ளும்படியான போக்கையும் உண்டாக்குவார்.
	<br /><br />
	14. ஆகையால் எனக்குப் பிரியமானவர்களே, விக்கிரகாராதனைக்கு விலகி ஓடுங்கள்.
	<br /><br />
	15. உங்களைப் புத்திமான்களென்று எண்ணிப் பேசுகிறேன்; நான் சொல்லுகிறதை நீங்களே நிதானித்துப்பாருங்கள்.
	<br /><br />
	16. நாம் ஆசீர்வதிக்கிற ஆசீர்வாதத்தின் பாத்திரம் கிறிஸ்துவினுடைய இரத்தத்தின் ஐக்கியமாயிருக்கிறதல்லவா? நாம் பிட்கிற அப்பம் கிறிஸ்துவினுடைய சரீரத்தின் ஐக்கியமாயிருக்கிறதல்லவா?
	<br /><br />
	17. அந்த ஒரே அப்பத்தில் நாமெல்லாரும் பங்குபெறுகிறபடியால், அநேகரான நாம் ஒரே அப்பமும் ஒரே சரீரமுமாயிருக்கிறோம்.
	<br /><br />
	18. மாம்சத்தின்படியான இஸ்ரவேலரைப் பாருங்கள்; பலிகளைப் புசிக்கிறவர்கள் பலிபீடத்தோடே ஐக்கியமாயிருக்கிறார்களல்லவா?
	<br /><br />
	19. இப்படியிருக்க, விக்கிரகம் ஒரு பொருளென்றும், விக்கிரகத்துக்குப் படைக்கப்பட்டது ஒரு பொருளென்றும் நான் சொல்லுகிறேனோ?
	<br /><br />
	20. அஞ்ஞானிகள் பலியிடுகிறவைகளை தேவனுக்கு அல்ல, பேய்களுக்கே பலியிடுகிறார்களென்று சொல்லுகிறேன்; நீங்கள் பேய்களோடே ஐக்கியமாயிருக்க எனக்கு மனதில்லை.
	<br /><br />
	21. நீங்கள் கர்த்தருடைய பாத்திரத்திலும் பேய்களுடைய பாத்திரத்திலும் பானம்பண்ணக்கூடாதே; நீங்கள் கர்த்தருடைய போஜனபந்திக்கும் பேய்களுடைய போஜனபந்திக்கும் பங்குள்ளவர்களாயிருக்கக்கூடாதே.
	<br /><br />
	22. நாம் கர்த்தருக்கு எரிச்சலை மூட்டலாமா? அவரிலும் நாம் பலவான்களா?
	<br /><br />
	23. எல்லாவற்றையும் அநுபவிக்க எனக்கு அதிகாரமுண்டு, ஆகிலும் எல்லாம் தகுதியாயிராது; எல்லாவற்றையும் அநுபவிக்க எனக்கு அதிகாரமுண்டு, ஆகிலும் எல்லாம் பக்திவிருத்தியை உண்டாக்காது.
	<br /><br />
	24. ஒவ்வொருவனும் தன் சுயபிரயோஜனத்தைத் தேடாமல், பிறனுடைய பிரயோஜனத்தைத் தேடக்கடவன்.
	<br /><br />
	25. கடையிலே விற்கப்படுகிற எதையும் வாங்கிப் புசியுங்கள்; மனச்சாட்சியினிமித்தம் நீங்கள் ஒன்றையும் விசாரிக்கவேண்டியதில்லை.
	<br /><br />
	26. பூமியும் அதின் நிறைவும் கர்த்தருடையது.
	<br /><br />
	27. அன்றியும் அவிசுவாசிகளில் ஒருவன் உங்களை விருந்துக்கு அழைக்கும்போது, போக உங்களுக்கு மனதிருந்தால், மனச்சாட்சியினிமித்தம் ஒன்றையும் விசாரியாமல், உங்கள் முன் வைக்கப்படுகிற எதையும் புசியுங்கள்.
	<br /><br />
	28. ஆயினும் இது விக்கிரகங்களுக்குப் படைக்கப்பட்டதென்று ஒருவன் உங்களுக்குச் சொன்னால், அப்படி அறிவித்தவனிமித்தமும் மனச்சாட்சியினிமித்தமும் புசியாதிருங்கள்; பூமியும் அதின் நிறைவும் கர்த்தருடையது.
	<br /><br />
	29. உன்னுடைய மனச்சாட்சியைக் குறித்து நான் இப்படிச் சொல்லாமல், மற்றொருவனுடைய மனச்சாட்சியைக் குறித்தே சொல்லுகிறேன். என் சுயாதீனம் மற்றொருவனுடைய மனச்சாட்சியினாலே குற்றமாய் எண்ணப்படவேண்டுவதென்ன?
	<br /><br />
	30. மேலும் நான் அதை ஸ்தோத்திரிப்புடனே அநுபவித்தால், ஸ்தோத்திரித்து அநுபவிக்கிற பொருளைக்குறித்து நான் தூஷிக்கப்படுவானேன்?
	<br /><br />
	31. ஆகையால் நீங்கள் புசித்தாலும், குடித்தாலும், எதைச் செய்தாலும், எல்லாவற்றையும் தேவனுடைய மகிமைக்கென்று செய்யுங்கள்.
	<br /><br />
	32. நான் என் சுயபிரயோஜனத்தைத் தேடாமல், அநேகருடைய பிரயோஜனத்தைத் தேடி, அவர்கள் இரட்சிக்கப்படும்படிக்கு, எவ்விதத்திலும் எல்லாருக்கும் பிரியமாய் நடக்கிறதுபோல;
	<br /><br />
	33. நீங்களும் யூதருக்கும், கிரேக்கருக்கும், தேவனுடைய சபைக்கும் இடறலற்றவர்களாயிருங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians10