import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 21 </title>
	<meta
          name="description"
          content="John Chapter 21 | யோவான் அதிகாரம் 21 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவைகளுக்குப்பின்பு இயேசு திபேரியா கடற்கரையிலே மறுபடியும் சீஷருக்குத் தம்மை வெளிப்படுத்தினார்; வெளிப்படுத்தின விவரமாவது:
	<br /><br />
	2. சீமோன்பேதுருவும், திதிமு என்னப்பட்ட தோமாவும், கலிலேயா நாட்டிலுள்ள கானா ஊரானாகிய நாத்தான்வேலும், செபெதேயுவின் குமாரரும், அவருடைய சீஷரில் வேறு இரண்டுபேரும் கூடியிருக்கும்போது,
	<br /><br />
	3. சீமோன்பேதுரு மற்றவர்களை நோக்கி: மீன்பிடிக்கப்போகிறேன் என்றான். அதற்கு அவர்கள்: நாங்களும் உம்முடனேகூட வருகிறோம் என்றார்கள். அவர்கள் புறப்பட்டுப்போய், உடனே படவேறினார்கள். அந்த இராத்திரியிலே அவர்கள் ஒன்றும் பிடிக்கவில்லை.
	<br /><br />
	4. விடியற்காலமானபோது, இயேசு கரையிலே நின்றார்; அவரை இயேசு என்று சீஷர்கள் அறியாதிருந்தார்கள்.
	<br /><br />
	5. இயேசு அவர்களை நோக்கி: பிள்ளைகளே, புசிக்கிறதற்கு ஏதாகிலும் உங்களிடத்தில் உண்டா என்றார். அதற்கு அவர்கள்: ஒன்றுமில்லை என்றார்கள்.
	<br /><br />
	6. அப்பொழுது அவர்: நீங்கள் படவுக்கு வலதுபுறமாக வலையைப் போடுங்கள், அப்பொழுது உங்களுக்கு அகப்படும் என்றார். அப்படியே அவர்கள் போட்டு, திரளான மீன்கள் அகப்பட்டதினால், அதை இழுக்கமாட்டாதிருந்தார்கள்.
	<br /><br />
	7. ஆதலால் இயேசுவுக்கு அன்பாயிருந்த சீஷன் பேதுருவைப் பார்த்து: அவர் கர்த்தர் என்றான். அவர் கர்த்தர் என்று சீமோன்பேதுரு கேட்டவுடனே, தான் வஸ்திரமில்லாதவனாயிருந்தபடியினால், தன் மேற்சட்டையைக் கட்டிக்கொண்டு கடலிலே குதித்தான்.
	<br /><br />
	8. மற்றச் சீஷர்கள் கரைக்கு ஏறக்குறைய இருநூறுமுழத் தூரத்தில் இருந்தபடியினால் படவிலிருந்து கொண்டே மீன்களுள்ள வலையை இழுத்துக்கொண்டு வந்தார்கள்.
	<br /><br />
	9. அவர்கள் கரையிலே வந்திறங்கினபோது, கரிநெருப்புப் போட்டிருக்கிறதையும், அதின்மேல் மீன் வைத்திருக்கிறதையும், அப்பத்தையும் கண்டார்கள்.
	<br /><br />
	10. இயேசு அவர்களை நோக்கி: நீங்கள் இப்பொழுது பிடித்த மீன்களில் சிலவற்றைக் கொண்டுவாருங்கள் என்றார்.
	<br /><br />
	11. சீமோன்பேதுரு படவில் ஏறி, நூற்றைம்பத்துமூன்று பெரிய மீன்களால் நிறைந்த வலையைக் கரையில் இழுத்தான்; இத்தனை மீன்கள் இருந்தும் வலை கிழியவில்லை.
	<br /><br />
	12. இயேசு அவர்களை நோக்கி: வாருங்கள், போஜனம்பண்ணுங்கள் என்றார். அவரைக் கர்த்தரென்று சீஷர்கள் அறிந்தபடியினால் அவர்களில் ஒருவனும்: நீர் யார் என்று கேட்கத் துணியவில்லை.
	<br /><br />
	13. அப்பொழுது இயேசு வந்து, அப்பத்தையும் மீனையும் எடுத்து, அவர்களுக்குக் கொடுத்தார்.
	<br /><br />
	14. இயேசு மரித்தோரிலிருந்தெழுந்த பின்பு தம்முடைய சீஷருக்கு அருளின தரிசனங்களில் இது மூன்றாவது தரிசனம்.
	<br /><br />
	15. அவர்கள் போஜனம்பண்ணினபின்பு, இயேசு சீமோன்பேதுருவை நோக்கி: யோனாவின் குமாரனாகிய சீமோனே, இவர்களிலும் அதிகமாய் நீ என்னிடத்தில் அன்பாயிருக்கிறாயா என்றார். அதற்கு அவன்: ஆம் ஆண்டவரே, உம்மை நேசிக்கிறேன் என்பதை நீர் அறிவீர் என்றான். அவர்: என் ஆட்டுக்குட்டிகளை மேய்ப்பாயாக என்றார்.
	<br /><br />
	16. இரண்டாந்தரம் அவர் அவனை நோக்கி: யோனாவின் குமாரனாகிய சீமோனே, நீ என்னிடத்தில் அன்பாயிருக்கிறாயா என்றார். அதற்கு அவன்: ஆம் ஆண்டவரே, உம்மை நேசிக்கிறேன் என்பதை நீர் அறிவீர் என்றான். அவர்: என் ஆடுகளை மேய்ப்பாயாக என்றார்.
	<br /><br />
	17. மூன்றாந்தரம் அவர் அவனை நோக்கி: யோனாவின் குமாரனாகிய சீமோனே, நீ என்னை நேசிக்கிறாயா என்றார். என்னை நேசிக்கிறாயா என்று அவர் மூன்றாந்தரம் தன்னைக் கேட்டபடியினாலே, பேதுரு துக்கப்பட்டு: ஆண்டவரே, நீர் எல்லாவற்றையும் அறிந்திருக்கிறீர், நான் உம்மை நேசிக்கிறேன் என்பதையும் நீர் அறிவீர் என்றான். இயேசு: என் ஆடுகளை மேய்ப்பாயாக என்றார்.
	<br /><br />
	18. நீ இளவயதுள்ளவனாயிருந்தபோது உன்னை நீயே அரைகட்டிக்கொண்டு, உனக்கு இஷ்டமான இடங்களிலே நடந்து திரிந்தாய்; நீ முதிர்வயதுள்ளவனாகும்போது உன் கைகளை நீட்டுவாய்; வேறொருவன் உன் அரையைக் கட்டி, உனக்கு இஷ்டமில்லாத இடத்துக்கு உன்னைக் கொண்டுபோவான் என்று மெய்யாகவே மெய்யாகவே உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	19. இன்னவிதமான மரணத்தினாலே அவன் தேவனை மகிமைப்படுத்தப்போகிறானென்பதைக் குறிக்கும்படியாக இப்படிச் சொன்னார். அவர் இதைச் சொல்லியபின்பு, அவனை நோக்கி: என்னைப் பின்பற்றிவா என்றார்.
	<br /><br />
	20. பேதுரு திரும்பிப்பார்த்து, இயேசுவுக்கு அன்பாயிருந்தவனும், இராப்போஜனம்பண்ணுகையில் அவர் மார்பிலே சாய்ந்து: ஆண்டவரே, உம்மைக் காட்டிக்கொடுக்கிறவன் யார் என்று கேட்டவனுமாகிய சீஷன் பின்னே வருகிறதைக் கண்டான்.
	<br /><br />
	21. அவனைக் கண்டு, பேதுரு இயேசுவை நோக்கி: ஆண்டவரே, இவன் காரியம் என்ன என்றான்.
	<br /><br />
	22. அதற்கு இயேசு: நான் வருமளவும் இவனிருக்க எனக்குச் சித்தமானால், உனக்கென்ன, நீ என்னைப் பின்பற்றிவா என்றார்.
	<br /><br />
	23. ஆகையால் அந்தச் சீஷன் மரிப்பதில்லையென்கிற பேச்சு சகோதரருக்குள்ளே பரம்பிற்று. ஆனாலும், அவன் மரிப்பதில்லையென்று இயேசு சொல்லாமல், நான் வருமளவும் இவனிருக்க எனக்குச் சித்தமானால் உனக்கென்னவென்று சொன்னார்.
	<br /><br />
	24. அந்தச் சீஷனே இவைகளைக்குறித்துச் சாட்சிகொடுத்து இவைகளை எழுதினவன்; அவனுடைய சாட்சி மெய்யென்று அறிந்திருக்கிறோம்.
	<br /><br />
	25. இயேசு செய்த வேறு அநேக காரியங்களுமுண்டு; அவைகளை ஒவ்வொன்றாக எழுதினால் எழுதப்படும் புஸ்தகங்கள் உலகம் கொள்ளாதென்று எண்ணுகிறேன். ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John21