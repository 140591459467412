import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const SecondTimothy4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> 2 Timothy in Tamil Bible Verse | 2 தீமோத்தேயு அதிகாரம்-4 </title>
	<meta
          name="description"
          content="Second LETTER TO THE Timothy Chapter 4 | தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
		<br />
<h2>
* பருவத்திலும் வெளியிலும் வார்த்தையைப் பிரசங்கியுங்கள் *
			</h2>
<br />
			<br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. நான் தேவனுக்கு முன்பாகவும், உயிரோடிருக்கிறவர்களையும் மரித்தவர்களையும் நியாயந்தீர்க்கப்போகிற கர்த்தராகிய இயேசுகிறிஸ்துவுக்கு முன்பாகவும், அவருடைய பிரசன்னமாகுதலையும் அவருடைய ராஜ்யத்தையும் சாட்சியாக வைத்துக் கட்டளையிடுகிறதாவது:
	<br /><br />
	2. சமயம் வாய்த்தாலும் வாய்க்காவிட்டாலும் ஜாக்கிரதையாய்த் திருவசனத்தைப் பிரசங்கம்பண்ணு; எல்லா நீடிய சாந்தத்தோடும் உபதேசத்தோடும் கண்டனம்பண்ணி, கடிந்துகொண்டு, புத்திசொல்லு.
	<br /><br />
	3. ஏனென்றால், அவர்கள் ஆரோக்கியமான உபதேசத்தைப் பொறுக்க மனதில்லாமல், செவித்தினவுள்ளவர்களாகி, தங்கள் சுய இச்சைகளுக்கேற்ற போதகர்களைத் தங்களுக்குத் திரளாகச் சேர்த்துக்கொண்டு,
	<br /><br />
	4. சத்தியத்துக்குச் செவியை விலக்கி, கட்டுக்கதைகளுக்குச் சாய்ந்துபோகுங்காலம் வரும்.
	<br /><br />
	5. நீயோ எல்லாவற்றிலும் மனத்தெளிவுள்ளவனாயிரு, தீங்கநுபவி, சுவிசேஷகனுடைய வேலையைச் செய், உன் ஊழியத்தை நிறைவேற்று.
	<br /><br />
	6. ஏனென்றால், நான் இப்பொழுதே பானபலியாக வார்க்கப்பட்டுப்போகிறேன்; நான் தேகத்தை விட்டுப் பிரியும் காலம் வந்தது.
	<br /><br />
	7. நல்ல போராட்டத்தைப் போராடினேன், ஓட்டத்தை முடித்தேன், விசுவாசத்தைக் காத்துக்கொண்டேன்.
	<br /><br />
	8. இதுமுதல் நீதியின் கிரீடம் எனக்காக வைக்கப்பட்டிருக்கிறது, நீதியுள்ள நியாயாதிபதியாகிய கர்த்தர் அந்நாளிலே அதை எனக்குத் தந்தருளுவார்; எனக்கு மாத்திரமல்ல, அவர் பிரசன்னமாகுதலை விரும்பும் யாவருக்கும் அதைத் தந்தருளுவார்.
	<br /><br />
	9. நீ சீக்கிரமாய் என்னிடத்தில் வரும்படி ஜாக்கிரதைப்படு.
	<br /><br />
	10. ஏனென்றால், தேமா இப்பிரபஞ்சத்தின்மேல் ஆசைவைத்து, என்னைவிட்டுப் பிரிந்து, தெசலோனிக்கே பட்டணத்துக்குப் போய்விட்டான்; கிரெஸ்கே கலாத்தியா நாட்டிற்கும், தீத்து தல்மாத்தியா நாட்டிற்கும் போய்விட்டார்கள்.
	<br /><br />
	11. லூக்காமாத்திரம் என்னோடே இருக்கிறான். மாற்குவை உன்னோடே கூட்டிக்கொண்டுவா; ஊழியத்தில் அவன் எனக்குப் பிரயோஜனமுள்ளவன்.
	<br /><br />
	12. தீகிக்குவை நான் எபேசுவுக்கு அனுப்பினேன்.
	<br /><br />
	13. துரோவா பட்டணத்திலிருக்கிற கார்ப்பு என்பவன் வசத்தில் நான் வைத்துவந்த மேலங்கியையும், புஸ்தகங்களையும், விசேஷமாய்த் தோற்சுருள்களையும், நீ வருகிறபோது எடுத்துக்கொண்டுவா.
	<br /><br />
	14. கன்னானாகிய அலெக்சந்தர் எனக்கு வெகு தீமைசெய்தான்; அவனுடைய செய்கைக்குத்தக்கதாகக் கர்த்தர் அவனுக்குப் பதிலளிப்பாராக.
	<br /><br />
	15. நீயும் அவனைக்குறித்து எச்சரிக்கையாயிரு; அவன் நம்முடைய வார்த்தைகளுக்கு மிகவும் எதிர்த்துநின்றவன்.
	<br /><br />
	16. நான் முதல்விசை உத்தரவுசொல்ல நிற்கையில் ஒருவனும் என்னோடேகூட இருக்கவில்லை, எல்லாரும் என்னைக் கைவிட்டார்கள்; அந்தக் குற்றம் அவர்கள்மேல் சுமராதிருப்பதாக.
	<br /><br />
	17. கர்த்தரோ எனக்குத் துணையாக நின்று, என்னாலே பிரசங்கம் நிறைவேறுகிறதற்காகவும், புறஜாதியாரெல்லாரும் கேட்கிறதற்காகவும், என்னைப் பலப்படுத்தினார்; சிங்கத்தின் வாயிலிருந்தும் நான் இரட்சிக்கப்பட்டேன்.
	<br /><br />
	18. கர்த்தர் எல்லாத் தீமையினின்றும் என்னை இரட்சித்து, தம்முடைய பரம ராஜ்யத்தை அடையும்படி காப்பாற்றுவார்; அவருக்குச் சதாகாலங்களிலும் மகிமை உண்டாவதாக. ஆமென்.
	<br /><br />
	19. பிரிஸ்காளுக்கும் ஆக்கில்லாவுக்கும், ஒநேசிப்போருவின் வீட்டாருக்கும் என் வாழ்த்துதலைச் சொல்லு.
	<br /><br />
	20. எரஸ்து கொரிந்துபட்டணத்தில் இருந்துவிட்டான்; துரோப்பீமுவை மிலேத்துவில் வியாதிப்பட்டவனாக விட்டுவந்தேன்.
	<br /><br />
	21. மாரிகாலத்துக்குமுன் நீ வந்து சேரும்படி ஜாக்கிரதைப்படு. ஐபூலுவும், புதேஞ்சும், லீனுவும், கலவுதியாளும், மற்றெல்லாச் சகோதரரும் உனக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	22. கர்த்தராகிய இயேசுகிறிஸ்து உன் ஆவியுடனேகூட இருப்பாராக. கிருபை உங்களோடிருப்பதாக. ஆமென்.
</p>
<p>
</p>


      {/* End of verse */} 

	  <br />
		<br />
<h4>
* 2 தீமோத்தேயு 2 என்பது, சீசனிலும் வெளியிலும் வார்த்தையைப் பிரசங்கிக்கத் தயாராக இருக்க வேண்டும், மற்றவர்களைத் திருத்தவும், பயிற்றுவிக்கவும், ஊக்குவிக்கவும் எப்போதும் தயாராக இருக்க வேண்டும். திமோத்தேயுவை அமைதியாகவும் நிலையானதாகவும் இருக்கவும், கஷ்டங்களைச் சகித்துக்கொள்ளவும், அவருடைய ஊழியத்தின் எல்லாக் கடமைகளையும் முழுமையாகச் செய்யவும் பவுல் ஊக்குவிக்கிறார். பவுல் தீமோத்தேயு தனது சில பொருட்களையும் சிலரையும் கூட்டி விரைவாக வருமாறு இறுதி அறிவுறுத்தலுடன் முடிக்கிறார்.*
			</h4>
<br />
			<br />
      </div>
    </div>
    </>
  );
}

export default SecondTimothy4