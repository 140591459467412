import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial40 = () => {
  const verseNumber = 40;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 40 </title>
	<meta
          name="description"
          content="Ezekiel 40 | எசேக்கியேல்  40 |
          Ezekiel 40 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நாங்கள் சிறைப்பட்டுப்போன இருபத்தைந்தாம் வருஷத்தின் ஆரம்பத்தில் முதலாம் மாதம் பத்தாந்தேதியாகிய அன்றே கர்த்தருடைய கை என்மேல் அமர்ந்தது, அவர் என்னை அவ்விடத்துக்குக் கொண்டுபோனார்; அப்பொழுது நகரம் அழிக்கப்பட்டுப் பதினாலு வருஷமாயிற்று.
	<br /><br />
	2. தேவதரிசனங்களில் அவர் என்னை இஸ்ரவேல் தேசத்துக்குக் கொண்டுபோய், என்னை மகா உயரமான ஒரு மலையின்மேல் நிறுத்தினார்; அதின்மேல் தெற்காக ஒரு நகரம் கட்டியிருக்கிறதுபோல் காணப்பட்டது.
	<br /><br />
	3. அவர் என்னை அங்கே கொண்டுபோனார்; இதோ, அங்கே ஒரு புருஷன் இருந்தார்; அவருடைய தோற்றம் வெண்கலமாயிருந்தது; அவர் கையில் சணற்கயிறும் ஒரு அளவுகோலும் இருந்தது; அவர் வாசலிலே நின்றார்.
	<br /><br />
	4. அந்தப் புருஷன் என்னை நோக்கி: மனுபுத்திரனே, நீ கண்ணாரப்பார்த்து, காதாரக் கேட்டு, நான் உனக்குக் காண்பிப்பதெல்லாவற்றின் மேலும் உன் மனதை வை; நான் உனக்கு அவைகளைக் காண்பிக்கும்படி நீ இங்கே கொண்டுவரப்பட்டாய்; நீ காண்பதையெல்லாம் இஸ்ரவேல் வம்சத்தாருக்குத் தெரிவி என்றார்.
	<br /><br />
	5. இதோ, ஆலயத்துக்குப் புறம்பே சுற்றிலும் ஒரு மதில் இருந்தது; அந்தப் புருஷன் கையிலே ஆறுமுழ நீளமான ஒரு அளவுகோல் இருந்தது; ஒவ்வொரு முழமும் நமது கைமுழத்திலும் நாலு விரற்கடை அதிகமானது; அவர் அந்த மதிலை அளந்தார்; அகலம் ஒரு கோலாகவும் உயரம் ஒரு கோலாகவும் இருந்தது.
	<br /><br />
	6. பின்பு அவர் கிழக்குமுக வாசலுக்கு வந்து, அதின் படிகளின்மேல் ஏறி, வாசற்படியை ஒரு கோல் அகலமாகவும், மறுவாசற்படியை ஒரு கோல் அகலமாகவும் அளந்தார்.
	<br /><br />
	7. ஒவ்வொரு அறையும் ஒரு கோல் நீளமும் ஒரு கோல் அகலமுமாயிருந்தது; அறைவீடுகளுக்கு நடுவே ஐந்துமுழ இடம் விட்டிருந்தது; வாசலின் மண்டபத்தருகே உள்வாசற்படி ஒரு கோலளவாயிருந்தது.
	<br /><br />
	8. வாசலின் மண்டபத்தையும் உள்ளே ஒரு கோலளவாக அளந்தார்.
	<br /><br />
	9. பின்பு வாசலின் மண்டபத்தை எட்டுமுழமாகவும், அதின் தூணாதாரங்களை இரண்டு முழமாகவும் அளந்தார்; வாசலின் மண்டபம் உட்புறத்திலிருந்தது.
	<br /><br />
	10. கீழ்த்திசைக்கெதிரான வாசலின் அறைகள் இந்தப்புறத்தில் மூன்றும் அந்தப்புறத்தில் மூன்றுமாயிருந்தது; அவைகள் மூன்றுக்கும் ஒரே அளவும், இந்தப்புறத்திலும் அந்தப்புறத்திலும் இருந்த தூணாதாரங்களுக்கு ஒரே அளவும் இருந்தது.
	<br /><br />
	11. பின்பு வாசல் நடையின் அகலத்தைப் பத்துமுழமாகவும், வாசலின் நீளத்தைப் பதின்மூன்று முழமாகவும் அளந்தார்.
	<br /><br />
	12. அறைகளுக்குமுன்னே இந்தப்புறத்தில் ஒரு முழ இடமும் அந்தப்புறத்தில் ஒரு முழ இடமும் இருந்தது; ஒவ்வொரு அறையும் இந்தப்புறத்தில் ஆறு முழமும் அந்தப்புறத்தில் ஆறு முழமுமாயிருந்தது.
	<br /><br />
	13. பின்பு வாசலில் இருந்த அறையின் மெத்தையினின்று மற்ற அறையின் மெத்தைமட்டும் இருபத்தைந்து முழமாக அளந்தார்; கதவுக்குக் கதவு நேராயிருந்தது.
	<br /><br />
	14. தூணாதாரங்களை அறுபது முழமாக அளந்தார்; இந்தத் தூணாதாரங்களின் அருகே சுற்றிலும் முன்வாசலின் முற்றம் இருந்தது.
	<br /><br />
	15. பிரவேச வாசலின் முகப்புத்துவக்கி, உட்புறவாசல் மண்டபமுகப்புமட்டும் ஐம்பது முழமாயிருந்தது.
	<br /><br />
	16. வாசலுக்கு உட்புறமாகச் சுற்றிலுமுள்ள அறைகளுக்கும் அவைகளின் தூணாதாரங்களுக்கும் ஒடுக்கமான ஜன்னல்கள் இருந்தது; மண்டபங்களிலும் அப்படியே இருந்தது; உட்புறமாய்ச் சுற்றிலும் அந்த ஜன்னல்களும் தூணாதாரங்களில் சித்திரிக்கப்பட்ட பேரீச்சமரங்களும் இருந்தது.
	<br /><br />
	17. பின்பு என்னை வெளிப்பிராகாரத்துக்கு அழைத்துக்கொண்டுபோனார்; அங்கே அறைவீடுகளும், பிராகாரத்தின் சுற்றிலும் பதித்த தளவரிசையும் இருந்தது; அந்தத் தளவரிசையின்மேல் முப்பது அறைவீடுகள் இருந்தது.
	<br /><br />
	18. வாசலுக்குப் பக்கத்திலும் வாசல்களின் நீளத்துக்கு எதிரிலுமுள்ள அந்தத் தளவரிசை தாழ்வான தளவரிசையாயிருந்தது.
	<br /><br />
	19. பின்பு அவர் கீழ்வாசலின் முகப்புத்துவக்கி, உட்பிராகாரத்துப் புறமுகப்புமட்டுமுள்ள விசாலத்தை அளந்தார்; அது கிழக்கும் வடக்கும் நூறு முழமாயிருந்தது.
	<br /><br />
	20. வெளிப்பிராகாரத்துக்கு அடுத்த வடதிசைக்கு எதிரான வாசலின் நீளத்தையும் அகலத்தையும் அளந்தார்.
	<br /><br />
	21. அதற்கு இப்புறத்தில் மூன்று அறைகளும் அப்புறத்தில் மூன்று அறைகளும் இருந்தது; அதின் தூணாதாரங்களும் அதின் மண்டபங்களும் முதல் வாசலின் அளவுக்குச் சரியாயிருந்தது; அதின் நீளம் ஐம்பது முழமும், அகலம் இருபத்தைந்து முழமுமாயிருந்தது.
	<br /><br />
	22. அதின் ஜன்னல்களும், அதின் மண்டபங்களும், அதின்மேல் சித்திரிக்கப்பட்ட பேரீச்சமரங்களும், கீழ்த்திசைக்கு எதிரான வாசலின் அளவுக்குச் சரியாயிருந்தது; அதில் ஏறுகிறதற்கு ஏழு படிகளிருந்தது; அதின் மண்டபங்கள் அவைகளுக்கு முன்னாக இருந்தது.
	<br /><br />
	23. வடதிசையிலும் கீழ்த்திசையிலுமுள்ள ஒவ்வொரு வாசலுக்கு எதிராக உட்பிராகாரத்துக்கும் வாசல்கள் இருந்தது; ஒரு வாசல்துவக்கி மற்ற வாசல்மட்டும் நூறு முழமாக அளந்தார்.
	<br /><br />
	24. பின்பு என்னைத் தென்திசைக்கு அழைத்துக்கொண்டுபோனார்; அங்கே தென்திசைக்கு எதிரான வாசல் இருந்தது; அதின் தூணாதாரங்களையும் அதின் மண்டபங்களையும் அதற்குரிய அளவின்படி அளந்தார்.
	<br /><br />
	25. அந்த ஜன்னல்களுக்குச் சரியாக அதற்கும் அதின் மண்டபங்களுக்கும் ஜன்னல்கள் சுற்றிலும் இருந்தது; நீளம் ஐம்பதுமுழமும் அகலம் இருபத்தைந்து முழமுமாயிருந்தது.
	<br /><br />
	26. அதில் ஏறுகிறதற்கு ஏழு படிகளிருந்தது; அதற்கு முன்பாக அதின் மண்டபங்களும் இருந்தது; அதின் தூணாதாரங்களில் சித்திரிக்கப்பட்ட பேரீச்சமரங்களும் இந்தப்புறத்தில் ஒன்றும் அந்தப்புறத்தில் ஒன்றுமாக இருந்தது.
	<br /><br />
	27. உட்பிராகாரத்துக்கும் ஒரு வாசல் தென்திசைக்கு எதிராக இருந்தது; தென்திசையிலுள்ள ஒரு வாசல்துவக்கி மற்ற வாசல்மட்டும் நூறுமுழமாக அளந்தார்.
	<br /><br />
	28. பின்பு அவர் தெற்கு வாசலால் என்னை உட்பிராகாரத்துக்கு அழைத்துக்கொண்டுபோய், அந்த அளவுக்குச் சரியாகத் தெற்கு வாசலையும் அளந்தார்.
	<br /><br />
	29. அதின் அறைகளும், அதின் தூணாதாரங்களும், அதின் மண்டபங்களும், அந்த அளவுக்குச் சரியாக இருந்தது; அதற்கும் அதின் மண்டபங்களுக்கும் ஜன்னல்கள் சுற்றிலும் இருந்தது; நீளம் ஐம்பது முழமும் அகலம் இருபத்தைந்து முழமுமாயிருந்தது.
	<br /><br />
	30. இருபத்தைந்து முழ நீளமும் ஐந்துமுழ அகலமுமான மண்டபங்கள் சுற்றிலும் இருந்தது.
	<br /><br />
	31. அதின் மண்டபங்கள் வெளிப்பிராகாரத்தில் இருந்தது; அதின் தூணாதாரங்களில் சித்திரிக்கப்பட்ட பேரீச்சமரங்களும் இருந்தது; அதில் ஏறுகிறதற்கு எட்டுப்படிகள் இருந்தது.
	<br /><br />
	32. பின்பு அவர் கீழ்த்திசை வழியாய் என்னை உட்பிராகாரத்துக்கு அழைத்துக்கொண்டுபோய், அந்த அளவுக்குச் சரியாக அந்த வாசலையும் அளந்தார்.
	<br /><br />
	33. அதின் அறைகளும் அதின் தூணாதாரங்களும் அதின் மண்டபங்களும் அந்த அளவுகளுக்குச் சரியாக இருந்தது; அதற்கும் அதின் மண்டபங்களுக்கும் ஜன்னல்கள் சுற்றிலும் இருந்தது; நீளம் ஐம்பது முழமும் அகலம் இருபத்தைந்து முழமுமாயிருந்தது.
	<br /><br />
	34. அதின் மண்டபங்கள் வெளிப்பிராகாரத்தில் இருந்தது; அதின் தூணாதாரங்களில் இந்தப்புறத்திலும் அந்தப்புறத்திலும் சித்திரிக்கப்பட்ட பேரீச்சமரங்களும் இருந்தது; அதில் ஏறுகிறதற்கு எட்டுப் படிகள் இருந்தது.
	<br /><br />
	35. பின்பு அவர் என்னை வடக்குவாசலுக்கு அழைத்துக்கொண்டுபோய், அந்த அளவுக்குச் சரியாய் அதின் வாசலை அளந்தார்.
	<br /><br />
	36. அதின் அறைகளும் அதின் தூணாதாரங்களும் அதின் மண்டபங்களும் அளக்கப்பட்டது; அதைச் சுற்றி ஜன்னல்களும் இருந்தது; நீளம் ஐம்பது முழமும் அகலம் இருபத்தைந்து முழமுமாயிருந்தது.
	<br /><br />
	37. அதின் தூணாதாரங்கள் வெளிப்பிராகாரத்தில் இருந்தது; இந்தப்புறத்திலும் அந்தப்புறத்திலும் அதின் தூணாதாரங்களில் பேரீச்சமரங்களும் சித்திரிக்கப்பட்டிருந்தது; அதில் ஏறுவதற்கு எட்டுப் படிகள் இருந்தது.
	<br /><br />
	38. அதின் அறைகளும் அதின் கதவுகளும் வாசல்களின் தூணாதாரங்களுக்கு அருகில் இருந்தது; அங்கே தகனபலிகளைக் கழுவுவார்கள்.
	<br /><br />
	39. வாசலின் மண்டபத்திலே இந்தப்புறத்தில் இரண்டு பீடங்களும் அந்தப்புறத்தில் இரண்டு பீடங்களும் இருந்தது; அவைகளின்மேல் தகனபலியையும் பாவநிவாரண பலியையும் குற்றநிவாரணபலியையும் செலுத்துவார்கள்.
	<br /><br />
	40. வடக்குவாசலுக்குள் பிரவேசிக்கிறதற்கு ஏறிப்போகிற வெளிப்புறத்திலே இரண்டு பீடங்களும், வாசலின் மண்டபத்திலுள்ள மறுபுறத்திலே இரண்டு பீடங்களும் இருந்தது.
</p>
<p>
	41. வாசலின் அருகே இந்தப்புறத்தில் நாலு பீடங்களும், அந்தப்புறத்தில் நாலு பீடங்களும், ஆக எட்டுப் பீடங்கள் இருந்தது; அவைகளின்மேல் பலிகளைச் செலுத்துவார்கள்.
	<br /><br />
	42. தகனபலிக்குரிய நாலு பீடங்கள் வெட்டின கல்லாயிருந்தது; அவைகள் ஒன்றரை முழ நீளமும், ஒன்றரை முழ அகலமும், ஒரு முழ உயரமுமாயிருந்தது; அவைகளின்மேல் தகனபலிகளையும் மற்றப் பலிகளையும் செலுத்துகிற ஆயுதங்களை வைப்பார்கள்.
	<br /><br />
	43. நாலு விரற்கடையான முளைகள் உள்ளே சுற்றிலும் வரிசையாய் அடிக்கப்பட்டிருந்தது; செலுத்தும் பலிகளின் மாம்சம் பீடங்களின்மேல் வைக்கப்படும்.
	<br /><br />
	44. உட்பிராகாரத்திலே உள்வாசலுக்குப் புறம்பாகச் சங்கீதக்காரரின் அறைவீடுகள் இருந்தது; அவைகளில் வடக்கு வாசலின் பக்கமாக இருந்தவைகள் தென்திசைக்கு எதிராகவும், கிழக்கு வாசலின் பக்கமாக இருந்த வேறொரு வரிசை வடதிசைக்கு எதிராகவும் இருந்தது.
	<br /><br />
	45. பின்பு அவர் என்னை நோக்கி: தென்திசைக்கு எதிராயிருக்கிற இந்த அறை ஆலயக்காவலைக் காக்கிற ஆசாரியர்களுடையது.
	<br /><br />
	46. வடதிசைக்கு எதிராயிருக்கிற அறையோ, பலிபீடத்தின் காவலைக் காக்கிற ஆசாரியர்களுடையது; இவர்கள் லேவியின் புத்திரரில் கர்த்தருக்கு ஆராதனை செய்கிறதற்காக அவரிடத்தில் சேருகிற சாதோக்கின் புத்திரர் என்றார்.
	<br /><br />
	47. அவர் பிராகாரத்தை நூறுமுழ நீளமாகவும் நூறுமுழ அகலமாகவும் அளந்தார்; அது சதுரமாயிருந்தது; பலிபீடமோ ஆலயத்துக்கு முன்பாக இருந்தது.
	<br /><br />
	48. பின்பு அவர் என்னை ஆலய மண்டபத்துக்கு அழைத்துக்கொண்டுபோய், மண்டபத்தின் தூணாதாரத்தை இந்தப்புறத்தில் ஐந்து முழமும் அந்தப்புறத்தில் ஐந்து முழமுமாக அளந்தார்; வாசலின் அகலம் இந்தப்புறம் மூன்று முழமும் அந்தப்புறம் மூன்று முழமுமாயிருந்தது.
	<br /><br />
	49. மண்டபத்தின் நீளம் இருபது முழமும், அகலம் பதினொரு முழமுமாயிருந்தது; அதற்கு ஏறிப்போகிற படிகளும் இருந்தது; தூணாதாரங்களிலே இந்தப்புறத்தில் ஒரு தூணும் அந்தப்புறத்தில் ஒரு தூணும் இருந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial40