import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 10 </title>
	<meta
          name="description"
          content="Matthew 10 | மத்தேயு 10 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது, அவர் தம்முடைய பன்னிரண்டு சீஷர்களையும் தம்மிடத்தில் வரவழைத்து, அசுத்த ஆவிகளைத் துரத்தவும், சகல வியாதிகளையும் சகல நோய்களையும் நீக்கவும் அவர்களுக்கு அதிகாரங்கொடுத்தார்.
	<br /><br />
	2. அந்தப் பன்னிரண்டு அப்போஸ்தலருடைய நாமங்களாவன: முந்தினவன் பேதுரு என்னப்பட்ட சீமோன், அவன் சகோதரன் அந்திரேயா, செபெதேயுவின் குமாரன் யாக்கோபு, அவன் சகோதரன் யோவான்,
	<br /><br />
	3. பிலிப்பு, பற்தொலொமேயு, தோமா, ஆயக்காரனாகிய மத்தேயு, அல்பேயுவின் குமாரன் யாக்கோபு, ததேயு என்னும் மறுநாமமுள்ள லெபேயு,
	<br /><br />
	4. கானானியனாகிய சீமோன், அவரைக் காட்டிக்கொடுத்த யூதாஸ்காரியோத்து என்பவைகளே.
	<br /><br />
	5. இந்தப் பன்னிருவரையும் இயேசு அனுப்புகையில், அவர்களுக்குக் கட்டளையிட்டுச் சொன்னது என்னவென்றால்: நீங்கள் புறஜாதியார் நாடுகளுக்குப் போகாமலும், சமாரியர் பட்டணங்களில் பிரவேசியாமலும்,
	<br /><br />
	6. காணாமற்போன ஆடுகளாகிய இஸ்ரவேல் வீட்டாரிடத்திற்குப் போங்கள்.
	<br /><br />
	7. போகையில், பரலோகராஜ்யம் சமீபித்திருக்கிறது என்று பிரசங்கியுங்கள்.
	<br /><br />
	8. வியாதியுள்ளவர்களைச் சொஸ்தமாக்குங்கள், குஷ்டரோகிகளைச் சுத்தம்பண்ணுங்கள், மரித்தோரை எழுப்புங்கள், பிசாசுகளைத் துரத்துங்கள்; இலவசமாய்ப் பெற்றீர்கள், இலவசமாய்க் கொடுங்கள்.
	<br /><br />
	9. உங்கள் கச்சைகளில் பொன்னையாவது வெள்ளியையாவது செம்பையாவது,
	<br /><br />
	10. வழிக்காகப் பையையாவது, இரண்டு அங்கிகளையாவது, பாதரட்சைகளையாவது, தடியையாவது தேடி வைக்கவேண்டாம்; வேலையாள் தன் ஆகாரத்துக்குப் பாத்திரனாயிருக்கிறான்.
	<br /><br />
	11. எந்தப் பட்டணத்திலாவது கிராமத்திலாவது நீங்கள் பிரவேசிக்கும்போது, அதிலே பாத்திரமானவன் யாரென்று விசாரித்து, நீங்கள் புறப்படுமளவும் அவனிடத்தில் தங்கியிருங்கள்.
	<br /><br />
	12. ஒரு வீட்டுக்குள் பிரவேசிக்கும்போது அதை வாழ்த்துங்கள்.
	<br /><br />
	13. அந்த வீடு பாத்திரமாயிருந்தால், நீங்கள் கூறின சமாதானம் அவர்கள்மேல் வரக்கடவது; அபாத்திரமாயிருந்தால், நீங்கள் கூறின சமாதானம் உங்களிடத்திற்குத் திரும்பக்கடவது.
	<br /><br />
	14. எவனாகிலும் உங்களை ஏற்றுக்கொள்ளாமலும், உங்கள் வார்த்தைகளைக் கேளாமலும்போனால், அந்த வீட்டையாவது பட்டணத்தையாவது விட்டுப் புறப்படும்போது, உங்கள் கால்களில் படிந்த தூசியை உதறிப்போடுங்கள்.
	<br /><br />
	15. நியாயத்தீர்ப்பு நாளிலே அந்தப் பட்டணத்திற்கு நேரிடுவதைப்பார்க்கிலும் சோதோம் கொமோரா நாட்டிற்கு நேரிடுவது இலகுவாயிருக்கும் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	16. ஆடுகளை ஓநாய்களுக்குள்ளே அனுப்புகிறதுபோல, இதோ, நான் உங்களை அனுப்புகிறேன்; ஆகையால், சர்ப்பங்களைப்போல வினாவுள்ளவர்களும் புறாக்களைப்போலக் கபடற்றவர்களுமாய் இருங்கள்.
	<br /><br />
	17. மனுஷரைக்குறித்து எச்சரிக்கையாயிருங்கள்; அவர்கள் உங்களை ஆலோசனைச் சங்கங்களுக்கு ஒப்புக்கொடுத்து, தங்கள் ஜெப ஆலயங்களில் உங்களை வாரினால் அடிப்பார்கள்.
	<br /><br />
	18. அவர்களுக்கும் புறஜாதியாருக்கும் சாட்சியாக என்னிமித்தம் அதிபதிகளுக்கு முன்பாகவும், ராஜாக்களுக்கு முன்பாகவும் கொண்டுபோகப்படுவீர்கள்.
	<br /><br />
	19. அவர்கள் உங்களை ஒப்புக்கொடுக்கும்போது: எப்படிப் பேசுவோம் என்றும், என்னத்தைப் பேசுவோம் என்றும் கவலைப்படாதிருங்கள்; நீங்கள் பேசவேண்டுவது அந்நேரத்தில் உங்களுக்கு அருளப்படும்.
	<br /><br />
	20. பேசுகிறவர்கள் நீங்கள் அல்ல, உங்கள் பிதாவின் ஆவியானவரே உங்களிலிருந்து பேசுகிறவர்.
	<br /><br />
	21. சகோதரன் தன் சகோதரனையும், தகப்பன் தன் பிள்ளையையும், மரணத்துக்கு ஒப்புக்கொடுப்பார்கள்; பெற்றாருக்கு விரோதமாகப் பிள்ளைகள் எழும்பி அவர்களைக் கொலைசெய்வார்கள்.
	<br /><br />
	22. என் நாமத்தினிமித்தம் நீங்கள் எல்லாராலும் பகைக்கப்படுவீர்கள்; முடிவுபரியந்தம் நிலைத்திருப்பவனே இரட்சிக்கப்படுவான்.
	<br /><br />
	23. ஒரு பட்டணத்தில் உங்களைத் துன்பப்படுத்தினால் மறு பட்டணத்திற்கு ஓடிப்போங்கள்; மனுஷகுமாரன் வருவதற்குள்ளாக நீங்கள் இஸ்ரவேலருடைய பட்டணங்களையெல்லாம் சுற்றிமுடியாதென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	24. சீஷன் தன் போதகனிலும், வேலைக்காரன் தன் எஜமானிலும் மேற்பட்டவனல்ல.
	<br /><br />
	25. சீஷன் தன் போதகனைப்போலவும், வேலைக்காரன் தன் எஜமானைப்போலவும் இருப்பது போதும். வீட்டெஜமானையே பெயல்செபூல் என்று சொன்னார்களானால், அவன் வீட்டாரை அப்படிச் சொல்வது அதிக நிச்சயமல்லவா?
	<br /><br />
	26. அவர்களுக்குப் பயப்படாதிருங்கள்; வெளியாக்கப்படாத மறைபொருளும் இல்லை; அறியப்படாத இரகசியமும் இல்லை.
	<br /><br />
	27. நான் உங்களுக்கு இருளிலே சொல்லுகிறதை நீங்கள் வெளிச்சத்திலே சொல்லுங்கள்; காதிலே கேட்கிறதை நீங்கள் வீடுகளின்மேல் பிரசித்தம்பண்ணுங்கள்.
	<br /><br />
	28. ஆத்துமாவைக் கொல்ல வல்லவர்களாயிராமல், சரீரத்தை மாத்திரம் கொல்லுகிறவர்களுக்கு நீங்கள் பயப்படவேண்டாம்; ஆத்துமாவையும் சரீரத்தையும் நரகத்திலே அழிக்க வல்லவருக்கே பயப்படுங்கள்.
	<br /><br />
	29. ஒரு காசுக்கு இரண்டு அடைக்கலான் குருவிகளை விற்கிறார்கள் அல்லவா? ஆயினும் உங்கள் பிதாவின் சித்தமில்லாமல், அவைகளில் ஒன்றாகிலும் தரையிலே விழாது.
	<br /><br />
	30. உங்கள் தலையிலுள்ள மயிரெல்லாம் எண்ணப்பட்டிருக்கிறது.
	<br /><br />
	31. ஆதலால், பயப்படாதிருங்கள்; அநேகம் அடைக்கலான் குருவிகளைப்பார்க்கிலும் நீங்கள் விசேஷித்தவர்களாயிருக்கிறீர்கள்.
	<br /><br />
	32. மனுஷர் முன்பாக என்னை அறிக்கை பண்ணுகிறவன் எவனோ, அவனை நானும் பரலோகத்திலிருக்கிற என் பிதாவின் முன்பாக அறிக்கைபண்ணுவேன்.
	<br /><br />
	33. மனுஷர் முன்பாக என்னை மறுதலிக்கிறவன் எவனோ, அவனை நானும் பரலோகத்திலிருக்கிற என் பிதாவின் முன்பாக மறுதலிப்பேன்.
	<br /><br />
	34. பூமியின்மேல் சமாதானத்தை அனுப்பவந்தேன் என்று எண்ணாதிருங்கள்; சமாதானத்தையல்ல, பட்டயத்தையே அனுப்பவந்தேன்.
	<br /><br />
	35. எப்படியெனில், மகனுக்கும் தகப்பனுக்கும், மகளுக்கும் தாய்க்கும், மருமகளுக்கும் மாமிக்கும் பிரிவினையுண்டாக்க வந்தேன்.
	<br /><br />
	36. ஒரு மனுஷனுக்குச் சத்துருக்கள் அவன் வீட்டாரே.
	<br /><br />
	37. தகப்பனையாவது தாயையாவது என்னிலும் அதிகமாய் நேசிக்கிறவன் எனக்குப் பாத்திரன் அல்ல; மகனையாவது மகளையாவது என்னிலும் அதிகமாய் நேசிக்கிறவன் எனக்குப் பாத்திரன் அல்ல.
	<br /><br />
	38. தன் சிலுவையை எடுத்துக்கொண்டு என்னைப் பின்பற்றாதவன் எனக்குப் பாத்திரன் அல்ல.
	<br /><br />
	39. தன் ஜீவனைக் காக்கிறவன் அதை இழந்துபோவான்; என்னிமித்தம் தன் ஜீவனை இழந்துபோகிறவன் அதைக் காப்பான்.
	<br /><br />
	40. உங்களை ஏற்றுக்கொள்ளுகிறவன் என்னை ஏற்றுக்கொள்ளுகிறான்; என்னை ஏற்றுக்கொள்ளுகிறவன் என்னை அனுப்பினவரை ஏற்றுக்கொள்ளுகிறான்.
	<br /><br />
	41. தீர்க்கதரிசி என்னும் நாமத்தினிமித்தம் தீர்க்கதரிசியை ஏற்றுக்கொள்ளுகிறவன் தீர்க்கதரிசிக்கேற்ற பலனை அடைவான்; நீதிமான் என்னும் நாமத்தினிமித்தம் நீதிமானை ஏற்றுக்கொள்ளுகிறவன் நீதிமானுக்கேற்ற பலனை அடைவான்.
	<br /><br />
	42. சீஷன் என்னும் நாமத்தினிமித்தம் இந்தச் சிறியரில் ஒருவனுக்கு ஒரு கலசம் தண்ணீர் மாத்திரம் குடிக்கக் கொடுக்கிறவனும் தன் பலனை அடையாமற்போகான் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew10