import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 19 </title>
	<meta
          name="description"
          content="Jeremiah 19 | எரேமியா 19 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொன்னது: நீ போய்க் குயவன் வேலையான ஒரு கலசத்தைக்கொண்டு, ஜனத்தின் மூப்பரிலும் ஆசாரியர்களின் மூப்பரிலும் சிலரைக் கூட்டிக்கொண்டு,
	<br /><br />
	2. கிழக்கு வாசலுக்கு எதிரான இன்னோமுடைய குமாரனின் பள்ளத்தாக்கிலே புறப்பட்டுப்போய், நான் உன்னோடே சொல்லும் வார்த்தைகளை அங்கே பிரசித்தப்படுத்து.
	<br /><br />
	3. நீ அவர்களை நோக்கி: யூதாவின் ராஜாக்களே, எருசலேமின் குடிகளே, கர்த்தருடைய வார்த்தைகளைக் கேளுங்கள்; இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்: இதோ, நான் இந்த ஸ்தலத்தின்மேல் ஒரு பொல்லாப்பை வரப்பண்ணுவேன்; அதைக் கேட்கிற யாவருடைய காதுகளிலும் அது தொனித்துக்கொண்டிருக்கும்.
	<br /><br />
	4. அவர்கள் என்னை விட்டுவிட்டு, இந்த ஸ்தலத்தை அந்நிய ஸ்தலமாக்கி, தாங்களும், தங்கள் பிதாக்களும், யூதாவின் ராஜாக்களும், அறியாதிருந்த அந்நிய தேவர்களுக்கு அதிலே தூபங்காட்டி, இந்த ஸ்தலத்தைக் குற்றமில்லாதவர்களின் இரத்தத்தினாலே நிரப்பினபடியினாலும்,
	<br /><br />
	5. தங்கள் பிள்ளைகளைப் பாகாலுக்குத் தகனபலிகளாகத் தகனிக்கும்படி பாகாலின் மேடைகளைக் கட்டினபடியினாலும் இப்படி வரப்பண்ணுவேன்; இவைகளை நான் கற்பித்ததுமில்லை, சொன்னதுமில்லை, இவைகள் என் இருதயத்தில் தோன்றினதுமில்லை.
	<br /><br />
	6. ஆகையால், இதோ, நாட்கள் வரும், அப்பொழுது இந்த ஸ்தலம் தோப்பேத்தென்றும், இன்னோமுடைய குமாரனின் பள்ளத்தாக்கென்றும் இனிச் சொல்லப்படாமல், சங்காரப் பள்ளத்தாக்கென்று சொல்லப்படும்.
	<br /><br />
	7. அப்பொழுது நான் யூதாவுக்கும் எருசலேமுக்கும் கொண்டிருந்த ஆலோசனையை இந்த ஸ்தலத்திலே வெறுமையாக்கி, அவர்கள் சத்துருக்களுக்கு முன்பாக அவர்களைப் பட்டயத்தினாலும், அவர்கள் பிராணனை வாங்கத்தேடுகிறவர்களின் கையினாலும் விழப்பண்ணி, அவர்கள் பிரேதங்களை ஆகாயத்துப் பறவைகளுக்கும், பூமியின் மிருகங்களுக்கும் இரையாகக் கொடுத்து,
	<br /><br />
	8. இந்த நகரத்தைப் பாழாகவும், ஈசலிட்டு நிந்திக்கிற நிந்தையாகவும் வைப்பேன்; அதைக் கடந்துபோகிறவன் எவனும் பிரமித்து, அதின் எல்லா வாதைகளினிமித்தமும் ஈசலிடுவான்.
	<br /><br />
	9. அவர்களுடைய சத்துருக்களும் அவர்கள் பிராணனை வாங்கத் தேடுகிறவர்களும், அவர்களை இறுகப்பிடிக்கப்போகிற முற்றிக்கையிலும் இடுக்கத்திலும், நான் அவர்களைத் தங்கள் குமாரரின் மாம்சத்தையும் தங்கள் குமாரத்திகளின் மாம்சத்தையும் தின்னப்பண்ணுவேன்; அவனவன் தனக்கு அடுத்தவனுடைய மாம்சத்தைத் தின்பான் என்று கர்த்தர் சொல்லுகிறார் என்று நீ சொல்லி,
	<br /><br />
	10. உன்னோடே கூடவந்த மனுஷருடைய கண்களுக்கு முன்பாக அந்தக் கலசத்தை உடைத்துப்போட்டு,
	<br /><br />
	11. அவர்களை நோக்கி: திரும்பச் செப்பனிடப்படக்கூடாத குயவனுடைய மண்பாண்டத்தை உடைத்துப்போட்டவண்ணமாக நான் இந்த ஜனத்தையும் இந்த நகரத்தையும் உடைத்துப்போடுவேன்; புதைக்கிறதற்கு இடமில்லாததினால் தோப்பேத்திலே சவங்களைப் புதைப்பார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. இவ்விதமாக நான் இந்த ஸ்தலத்துக்கும் இதின் குடிகளுக்கும் செய்து, இந்த நகரத்தைத் தோப்பேத்துக்குச் சரியாக்குவேன்.
	<br /><br />
	13. எந்த வீடுகளின்மேல் வானத்தின் சகல சேனைக்கும் தூபங்காட்டி, அந்நிய தேவர்களுக்குப் பானபலிகளை வார்த்தார்களோ, அந்த வீடுகளாகிய எருசலேமின் வீடுகளும் யூதாவுடைய ராஜாவின் வீடுகளும் தோப்பேத் என்கிற ஸ்தலத்தைப்போல் தீட்டுப்பட்டவைகளாய் இருக்குமென்று கர்த்தர் சொல்லுகிறார் என்று சொல் என்றார்.
	<br /><br />
	14. பின்பு எரேமியா, கர்த்தர் தன்னைத் தீர்க்கதரிசனஞ்சொல்ல அனுப்பின தோப்பேத்திலிருந்து வந்து, கர்த்தருடைய ஆலயத்தின் பிராகாரத்திலே நின்றுகொண்டு, எல்லா ஜனங்களையும் பார்த்து:
	<br /><br />
	15. இதோ, நீங்கள் என் வார்த்தைகளைக் கேளாதபடிக்கு உங்கள் கழுத்தைக் கடினப்படுத்தினபடியினால் நான் இந்த நகரத்துக்கு விரோதமாய்ச் சொன்ன எல்லாத் தீங்கையும் இதின் மேலும் இதற்கடுத்த பட்டணங்களின் மேலும் வரப்பண்ணுவேன் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah19