import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 22 </title>
	<meta
          name="description"
          content="Ezekiel 22 | எசேக்கியேல்  22 |
          Ezekiel 22 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. இப்போதும் மனுபுத்திரனே, இரத்தஞ்சிந்தின நகரத்துக்காக நீ வழக்காடுவாயோ? வழக்காட மனதானால், நீ அதின் அருவருப்புகளையெல்லாம் அதற்குத் தெரியக்காட்டி,
	<br /><br />
	3. அதை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், உன் காலம் வரத்தக்கதாக உன் நடுவிலே இரத்தஞ்சிந்துகிறதும், உன்னைத் தீட்டுப்படுத்தத்தக்கதாக உனக்கே விரோதமாய் நரகலான விக்கிரகங்களை உண்டுபண்ணுகிறதுமான நகரமே,
	<br /><br />
	4. நீ சிந்தின உன் இரத்தத்தினால் நீ குற்றஞ்சுமந்ததாகி, நீ உண்டுபண்ணின உன் நரகலான விக்கிரகங்களால் நீ தீட்டுப்பட்டு, உன் நாட்களைச் சமீபிக்கப்பண்ணி, உன் வருஷங்களை நிறைவேற்றினாய்; ஆகையால் நான் உன்னைப் புறஜாதிகளுக்கு நிந்தையாகவும், தேசங்களுக்கெல்லாம் பரியாசமாகவும் வைப்பேன்.
	<br /><br />
	5. உனக்குச் சமீபமும் உனக்குத் தூரமுமான தேசங்களின் மனுஷர் நீ அவகீர்த்தியுள்ளதென்றும், அமளி பெருத்ததென்றும் உன்னைப் பரியாசம்பண்ணுவார்கள்.
	<br /><br />
	6. இதோ, இஸ்ரவேலின் அதிபதிகளில் அவரவர் தங்கள் புயபலத்துக்குத் தக்கதாக, உன்னில் இரத்தஞ்சிந்தினார்கள்.
	<br /><br />
	7. உன்னிலுள்ள தாய் தகப்பனை அற்பமாய் எண்ணினார்கள்; உன் நடுவில் பரதேசிக்கு இடுக்கண் செய்தார்கள்; உனக்குள் திக்கற்றவனையும் விதவையையும் ஒடுக்கினார்கள்.
	<br /><br />
	8. நீ என் பரிசுத்த வஸ்துக்களை அசட்டைபண்ணி, என் ஓய்வுநாட்களைப் பரிசுத்தக்குலைச்சலாக்கினாய்.
	<br /><br />
	9. இரத்தஞ்சிந்தும்படிக்கு அபாண்டம் பேசுகிறவர்கள் உன்னிடத்தில் இருக்கிறார்கள்; மலைகளின்மேல் சாப்பிடுகிறவர்களும் உன்னிடத்தில் இருக்கிறார்கள்; முறைகேடு செய்கிறவர்கள் உன் நடுவில் இருக்கிறார்கள்.
	<br /><br />
	10. தகப்பனை நிர்வாணமாக்கினவர்கள் உன்னில் இருக்கிறார்கள்; தூரஸ்திரீயைப் பலவந்தப்படுத்தினவர்கள் உன்னில் இருக்கிறார்கள்.
	<br /><br />
	11. உன்னில் ஒருவன் தன் அயலானுடைய மனைவியோடே அருவருப்பானதைச் செய்கிறான்; வேறொருவன் முறைகேடாய்த் தன் மருமகளைத் தீட்டுப்படுத்துகிறான்; வேறொருவன் தன் தகப்பனுக்குப் பிறந்த தன் சகோதரியைப் பலவந்தம் பண்ணுகிறான்.
	<br /><br />
	12. இரத்தஞ்சிந்தும்படிக்குப் பரிதானம் வாங்கினவர்கள் உன்னில் இருக்கிறார்கள்; நீ வட்டியையும் பொலிசையையும் வாங்கி, பொருளாசையினால் உன் அயலானுக்கு இடுக்கண் செய்து, என்னை மறந்து போனாய் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	13. இதோ, நீ அநியாயமாய்ச் சம்பாதித்த பொருளினிமித்தமும், உன் நடுவில் நீ சிந்தின இரத்தத்தினிமித்தமும் நான் கைகொட்டுகிறேன்.
	<br /><br />
	14. நான் உன்னில் நியாயஞ்செய்யும் நாட்களில் உன் இருதயம் தாங்குமோ? அப்பொழுது உன் கைகள் திடமாயிருக்குமோ? கர்த்தராகிய நான் இதைச் சொன்னேன், இதை நிறைவேற்றுவேன்.
	<br /><br />
	15. நான் உன்னைப் புறஜாதிகளுக்குள்ளே சிதறடித்து, உன்னைத் தேசங்களிலே தூற்றி, உன் அசுத்தத்தை உன்னில் ஒழியப்பண்ணுவேன்.
	<br /><br />
	16. நீ புறஜாதிகளின் கண்களுக்கு முன்பாகப் பரிசுத்தக் குலைச்சலாயிருந்து, நான் கர்த்தர் என்று அறிந்துகொள்வாய் என்று சொல் என்றார்.
	<br /><br />
	17. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	18. மனுபுத்திரனே, இஸ்ரவேல் வம்சத்தார் எனக்குக் களிம்பாய்ப் போனார்கள்; அவர்களெல்லாரும் குகையிலுள்ள பித்தளையும் தகரமும் இரும்பும் ஈயமுமாயிருக்கிறார்கள்; அவர்கள் வெள்ளியின் களிம்பாய்ப் போனார்கள்.
	<br /><br />
	19. ஆதலால், கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நீங்களெல்லாரும் களிம்பாய்ப் போனபடியினால், இதோ, நான் உங்களை எருசலேமுக்குள் சேர்ப்பேன்.
	<br /><br />
	20. வெள்ளியையும் பித்தளையையும் இரும்பையும் ஈயத்தையும் தகரத்தையும் அக்கினியில் ஊதி உருக்குவதற்காகக் குகைக்குள் சேர்க்கிறதுபோல, நான் என் கோபத்தினாலும் என் உக்கிரத்தினாலும் உங்களைச் சேர்த்துவைத்து உருக்குவேன்.
	<br /><br />
	21. நான் உங்களைக் கூட்டி, என் கோபமாகிய அக்கினியை உங்கள்மேல் ஊதுவேன்; அதற்குள்ளே நீங்கள் உருகுவீர்கள்.
	<br /><br />
	22. குகைக்குள் வெள்ளி உருகுமாப்போல, நீங்கள் அதற்குள் உருகுவீர்கள்; அப்பொழுது கர்த்தராகிய நான் என் உக்கிரத்தை உங்கள்மேல் ஊற்றிவிட்டேன் என்று அறிந்துகொள்வீர்கள் என்றார்.
	<br /><br />
	23. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	24. மனுபுத்திரனே, நீ தேசத்தைப் பார்த்து: நீ சுத்தம்பண்ணப்படாததேசம், கோபத்தின் காலத்தில் மழை பெய்யாத தேசம் என்று அதற்குச் சொல்லு.
	<br /><br />
	25. அதிலுள்ள தீர்க்கதரிசிகள் அதின் நடுவில் கட்டுப்பாடு பண்ணுகிறார்கள்; கெர்ச்சிக்கிற சிங்கம் இரை கவ்வுகிறதுபோல, ஆத்துமாக்களை அவர்கள் பட்சிக்கிறார்கள்; திரவியத்தையும் விலையுயர்ந்த பொருள்களையும் வாங்கிக்கொள்ளுகிறார்கள்; அதின் நடுவில் அநேகரை விதவைகளாக்குகிறார்கள்.
	<br /><br />
	26. அதின் ஆசாரியர்கள் என் வேதத்துக்கு அநியாயஞ்செய்து, என் பரிசுத்த வஸ்துக்களைப் பரிசுத்தக் குலைச்சலாக்குகிறார்கள்; பரிசுத்தமுள்ளதற்கும் பரிசுத்தமில்லாததற்கும் வித்தியாசம்பண்ணாமலும், அசுத்தமுள்ளதற்கும் அசுத்தமில்லாததற்கும் உண்டான வேற்றுமையைக் காண்பியாமலும் இருந்து, என் ஓய்வுநாட்களுக்குத் தங்கள் கண்களை மூடிக்கொள்ளுகிறார்கள்; அவர்கள் நடுவிலே நான் கனஈனம்பண்ணப்படுகிறேன்.
	<br /><br />
	27. அதின் நடுவில் இருக்கிற அதின் பிரபுக்கள் இரை கவ்வுகிற ஓநாய்களைப்போல் இருக்கிறார்கள்; அநியாயமாய்ப் பொருள் சம்பாதிக்கிறதற்கு இரத்தஞ்சிந்துகிறார்கள், ஆத்துமாக்களைக் கொள்ளையிடுகிறார்கள்.
	<br /><br />
	28. அதின் தீர்க்கதரிசிகள் அபத்தமானதைத் தரிசித்து, பொய்ச்சாஸ்திரத்தை அவர்களுக்குச் சொல்லி, கர்த்தர் உரைக்காதிருந்தும், கர்த்தராகிய ஆண்டவர் உரைத்தார் என்று சொல்லி, அவர்களுக்குச் சாரமற்ற சாந்தைப் பூசுகிறார்கள்.
	<br /><br />
	29. தேசத்தின் ஜனங்கள் இடுக்கண்செய்து, கொள்ளையடித்து, சிறுமையும் எளிமையுமானவனை ஒடுக்கி, அந்நியனை அநியாயமாய்த் துன்பப்படுத்துகிறார்கள்.
	<br /><br />
	30. நான் தேசத்தை அழிக்காதபடிக்குத் திறப்பிலே நிற்கவும் சுவரை அடைக்கவுந்தக்கதாக ஒரு மனுஷனைத் தேடினேன், ஒருவனையும் காணேன்.
	<br /><br />
	31. ஆகையால், நான் அவர்கள்மேல் என் கோபத்தை ஊற்றி, என் மூர்க்கத்தின் அக்கினியால் அவர்களை நிர்மூலமாக்கி, அவர்களுடைய வழியின் பலனை அவர்கள் தலையின்மேல் சுமரப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial22