import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew27 = () => {
  const verseNumber = 27;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 27 </title>
	<meta
          name="description"
          content="Matthew 27 | மத்தேயு 27 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. விடியற்காலமானபோது, சகல பிரதான ஆசாரியரும் ஜனத்தின் மூப்பரும், இயேசுவைக் கொலைசெய்யும்படி, அவருக்கு விரோதமாக ஆலோசனைபண்ணி,
	<br /><br />
	2. அவரைக் கட்டி, கொண்டுபோய், தேசாதிபதியாகிய பொந்தியுபிலாத்துவினிடத்தில் ஒப்புக்கொடுத்தார்கள்.
	<br /><br />
	3. அப்பொழுது, அவரைக் காட்டிக்கொடுத்த யூதாஸ், அவர் மரணாக்கினைக்குள்ளாகத் தீர்க்கப்பட்டதைக் கண்டு, மனஸ்தாபப்பட்டு, அந்த முப்பது வெள்ளிக்காசைப் பிரதான ஆசாரியரிடத்திற்கும் மூப்பரிடத்திற்கும் திரும்பக் கொண்டுவந்து:
	<br /><br />
	4. குற்றமில்லாத இரத்தத்தை நான் காட்டிக்கொடுத்ததினால் பாவஞ்செய்தேன் என்றான். அதற்கு அவர்கள்: எங்களுக்கென்ன, அது உன்பாடு என்றார்கள்.
	<br /><br />
	5. அப்பொழுது, அவன் அந்த வெள்ளிக்காசைத் தேவாலயத்திலே எறிந்துவிட்டு, புறப்பட்டுப்போய், நான்றுகொண்டு செத்தான்.
	<br /><br />
	6. பிரதான ஆசாரியர் அந்த வெள்ளிக்காசை எடுத்து: இது இரத்தக்கிரயமானதால், காணிக்கைப் பெட்டியிலே இதைப் போடலாகாதென்று சொல்லி,
	<br /><br />
	7. ஆலோசனைபண்ணினபின்பு, அந்நியரை அடக்கம்பண்ணுவதற்குக் குயவனுடைய நிலத்தை அதினாலே கொண்டார்கள்.
	<br /><br />
	8. இதினிமித்தம் அந்த நிலம் இந்நாள்வரைக்கும் இரத்தநிலம் என்னப்படுகிறது.
	<br /><br />
	9. இஸ்ரவேல் புத்திரரால் மதிக்கப்பட்டவருக்குக் கிரயமாகிய முப்பது வெள்ளிக்காசை அவர்கள் எடுத்து,
	<br /><br />
	10. கர்த்தர் எனக்குக் கற்பித்தபடி குயவனுடைய நிலத்திற்காக அதைக் கொடுத்தார்கள் என்று எரேமியா தீர்க்கதரிசியால் உரைக்கப்பட்டது அப்பொழுது நிறைவேறிற்று.
	<br /><br />
	11. இயேசு தேசாதிபதிக்கு முன்பாக நின்றார்; தேசாதிபதி அவரை நோக்கி: நீ யூதருடைய ராஜாவா என்று கேட்டான். அதற்கு இயேசு: நீர் சொல்லுகிறபடிதான் என்றார்.
	<br /><br />
	12. பிரதான ஆசாரியரும் மூப்பரும் அவர்மேல் குற்றஞ்சாட்டுகையில், அவர் மாறுத்தரம் ஒன்றும் சொல்லவில்லை.
	<br /><br />
	13. அப்பொழுது, பிலாத்து அவரை நோக்கி: இவர்கள் உன்மேல் எத்தனையோ குற்றங்களைச் சாட்டுகிறார்களே, நீ அவைகளைக் கேட்கவில்லையா என்றான்.
	<br /><br />
	14. அவரோ ஒரு வார்த்தையும் மாறுத்தரமாகச் சொல்லவில்லை; அதனால் தேசாதிபதி மிகவும் ஆச்சரியப்பட்டான்.
	<br /><br />
	15. காவல்பண்ணப்பட்டவர்களில் எவனை விடுதலையாக்கவேண்டுமென்று ஜனங்கள் கேட்டுக்கொள்வார்களோ, அவனை அவர்களுக்காக விடுதலையாக்குவது பண்டிகைதோறும் தேசாதிபதிக்கு வழக்கமாயிருந்தது.
	<br /><br />
	16. அப்பொழுது காவல்பண்ணப்பட்டவர்களில் பரபாஸ் என்னப்பட்ட பேர்போன ஒருவன் இருந்தான்.
	<br /><br />
	17. பொறாமையினாலே அவரை ஒப்புக்கொடுத்தார்கள் என்று பிலாத்து அறிந்து,
	<br /><br />
	18. அவர்கள் கூடியிருக்கையில், அவர்களை நோக்கி: எவனை நான் உங்களுக்கு விடுதலையாக்கவேண்டுமென்றிருக்கிறீர்கள்? பரபாசையோ? கிறிஸ்து என்னப்படுகிற இயேசுவையோ? என்று கேட்டான்.
	<br /><br />
	19. அவன் நியாயாசனத்தில் உட்கார்ந்திருக்கையில், அவனுடைய மனைவி அவனிடத்தில் ஆளனுப்பி: நீர் அந்த நீதிமானை ஒன்றும் செய்யவேண்டாம்; அவர் நிமித்தம் இன்றைக்குச் சொப்பனத்தில் வெகு பாடுபட்டேன் என்று சொல்லச்சொன்னாள்.
	<br /><br />
	20. பரபாசை விட்டுவிடக் கேட்டுக்கொள்ளவும், இயேசுவைக் கொலைசெய்விக்கவும் பிரதான ஆசாரியரும் மூப்பரும் ஜனங்களை ஏவிவிட்டார்கள்.
	<br /><br />
	21. தேசாதிபதி ஜனங்களை நோக்கி: இவ்விருவரில் எவனை நான் உங்களுக்கு விடுதலையாக்கவேண்டும் என்று கேட்டான். அதற்கு அவர்கள்: பரபாசை என்றார்கள்.
	<br /><br />
	22. பிலாத்து அவர்களை நோக்கி: அப்படியானால், கிறிஸ்து என்னப்பட்ட இயேசுவை நான் என்னசெய்யவேண்டும் என்று கேட்டான். அவனைச் சிலுவையில் அறையவேண்டும் என்று எல்லாரும் சொன்னார்கள்.
	<br /><br />
	23. தேசாதிபதியோ: ஏன், என்ன பொல்லாப்புச் செய்தான் என்றான். அதற்கு அவர்கள்: அவனைச் சிலுவையில் அறையவேண்டும் என்று அதிகமதிகமாய்க் கூக்குரலிட்டுச் சொன்னார்கள்.
	<br /><br />
	24. கலகம் அதிகமாகிறதேயல்லாமல் தன் பிரயத்தனத்தினாலே பிரயோஜனமில்லையென்று பிலாத்து கண்டு, தண்ணீரை அள்ளி, ஜனங்களுக்கு முன்பாகக் கைகளைக் கழுவி: இந்த நீதிமானுடைய இரத்தப்பழிக்கு நான் குற்றமற்றவன், நீங்களே பார்த்துக்கொள்ளுங்கள் என்றான்.
	<br /><br />
	25. அதற்கு ஜனங்களெல்லாரும்: இவனுடைய இரத்தப்பழி எங்கள்மேலும் எங்கள் பிள்ளைகள்மேலும் இருப்பதாக என்று சொன்னார்கள்.
	<br /><br />
	26. அப்பொழுது, அவன் பரபாசை அவர்களுக்கு விடுதலையாக்கி, இயேசுவையோ வாரினால் அடிப்பித்து, சிலுவையில் அறையும்படிக்கு ஒப்புக்கொடுத்தான்.
	<br /><br />
	27. அப்பொழுது, தேசாதிபதியின் போர்ச்சேவகர் இயேசுவைத் தேசாதிபதியின் அரமனையிலே கொண்டுபோய், போர்ச்சேவகரின் கூட்டம் முழுவதையும் அவரிடத்தில் கூடிவரச்செய்து,
	<br /><br />
	28. அவர் வஸ்திரங்களைக் கழற்றி, சிவப்பான மேலங்கியை அவருக்கு உடுத்தி,
	<br /><br />
	29. முள்ளுகளால் ஒரு முடியைப்பின்னி, அவர் சிரசின்மேல் வைத்து, அவர் வலதுகையில் ஒரு கோலைக் கொடுத்து, அவர் முன்பாக முழங்காற்படியிட்டு: யூதருடைய ராஜாவே, வாழ்க என்று அவரைப் பரியாசம்பண்ணி,
	<br /><br />
	30. அவர்மேல் துப்பி, அந்தக் கோலை எடுத்து, அவரைச் சிரசில் அடித்தார்கள்.
	<br /><br />
	31. அவரைப் பரியாசம்பண்ணினபின்பு, அவருக்கு உடுத்தின மேலங்கியைக் கழற்றி, அவருடைய வஸ்திரங்களை அவருக்கு உடுத்தி, அவரைச் சிலுவையில் அறையும்படி கொண்டுபோனார்கள்.
	<br /><br />
	32. போகையில், சிரேனே ஊரானாகிய சீமோன் என்னப்பட்ட ஒரு மனுஷனை அவர்கள் கண்டு, அவருடைய சிலுவையைச் சுமக்கும்படி அவனைப் பலவந்தம் பண்ணினார்கள்.
	<br /><br />
	33. கபாலஸ்தலம் என்று அர்த்தங்கொள்ளும் கொல்கொதா என்னும் இடத்துக்கு அவர்கள் வந்தபோது,
	<br /><br />
	34. கசப்புக்கலந்த காடியை அவருக்குக் குடிக்கக் கொடுத்தார்கள்; அவர் அதை ருசிபார்த்து, குடிக்க மனதில்லாதிருந்தார்.
	<br /><br />
	35. அவரைச் சிலுவையில் அறைந்தபின்பு, அவர்கள் சீட்டுப்போட்டு அவருடைய வஸ்திரங்களைப் பங்கிட்டுக் கொண்டார்கள். என் வஸ்திரங்களைத் தங்களுக்குள்ளே பங்கிட்டு, என் உடையின்பேரில் சீட்டுப்போட்டார்கள் என்று தீர்க்கதரிசியால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது.
	<br /><br />
	36. அவர்கள் அங்கே உட்கார்ந்து, அவரைக் காவல்காத்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	37. அன்றியும் அவர் அடைந்த ஆக்கினையின் முகாந்தரத்தைக் காண்பிக்கும் பொருட்டு, இவன் யூதருடைய ராஜாவாகிய இயேசு என்று எழுதி, அவர் சிரசுக்கு மேலாக வைத்தார்கள்.
	<br /><br />
	38. அப்பொழுது, அவருடைய வலதுபக்கத்தில் ஒருவனும் அவருடைய இடதுபக்கத்தில் ஒருவனுமாக இரண்டு கள்ளர் அவரோடேகூடச் சிலுவைகளில் அறையப்பட்டார்கள்.
	<br /><br />
	39. அந்த வழியாய் நடந்துபோகிறவர்கள் தங்கள் தலைகளைத் துலுக்கி:
	<br /><br />
	40. தேவாலயத்தை இடித்து, மூன்று நாளைக்குள்ளே கட்டுகிறவனே, உன்னை நீயே ரட்சித்துக்கொள்; நீ தேவனுடைய குமாரனானால் சிலுவையிலிருந்து இறங்கி வா என்று அவரைத் தூஷித்தார்கள்.
	<br /><br />
	41. அப்படியே பிரதான ஆசாரியரும் வேதபாரகரும் மூப்பரும் பரியாசம்பண்ணி:
	<br /><br />
	42. மற்றவர்களை ரட்சித்தான்; தன்னைத்தான் ரட்சித்துக்கொள்ளத் திராணியில்லை; இவன் இஸ்ரவேலின் ராஜாவானால் இப்பொழுது சிலுவையிலிருந்து இறங்கிவரட்டும், அப்பொழுது இவனை விசுவாசிப்போம்.
	<br /><br />
	43. தன்னைத் தேவனுடைய குமாரனென்று சொல்லி, தேவன்மேல் நம்பிக்கையாயிருந்தானே; அவர் இவன்மேல் பிரியமாயிருந்தால் இப்பொழுது இவனை இரட்சிக்கட்டும் என்றார்கள்.
	<br /><br />
	44. அவரோடேகூடச் சிலுவைகளில் அறையப்பட்ட கள்ளரும் அந்தப்படியே அவரை நிந்தித்தார்கள்.
	<br /><br />
	45. ஆறாம்மணி நேரமுதல் ஒன்பதாம்மணி நேரம்வரைக்கும் பூமியெங்கும் அந்தகாரம் உண்டாயிற்று.
	<br /><br />
	46. ஒன்பதாம்மணி நேரத்தில் இயேசு: ஏலீ! ஏலீ! லாமா சபக்தானி, என்று மிகுந்த சத்தமிட்டுக் கூப்பிட்டார்; அதற்கு என் தேவனே! என் தேவனே! ஏன் என்னைக் கைவிட்டீர் என்று அர்த்தமாம்.
	<br /><br />
	47. அங்கே நின்றவர்களில் சிலர் அதைக் கேட்டபொழுது: இவன் எலியாவைக் கூப்பிடுகிறான் என்றார்கள்.
	<br /><br />
	48. உடனே அவர்களில் ஒருவன் ஓடி, கடற்காளானை எடுத்து, காடியில் தோய்த்து, அதை ஒரு கோலில் மாட்டி, அவருக்குக் குடிக்கக் கொடுத்தான்.
	<br /><br />
	49. மற்றவர்களோ: பொறு, எலியா இவனை இரட்சிக்க வருவானோ, பார்ப்போம் என்றார்கள்.
	<br /><br />
	50. இயேசு, மறுபடியும் மகா சத்தமாய்க் கூப்பிட்டு, ஆவியை விட்டார்.
	<br /><br />
	51. அப்பொழுது, தேவாலயத்தின் திரைச்சீலை மேல்தொடங்கிக் கீழ்வரைக்கும் இரண்டாகக் கிழிந்தது, பூமியும் அதிர்ந்தது, கன்மலைகளும் பிளந்தது.
	<br /><br />
	52. கல்லறைகளும் திறந்தது, நித்திரையடைந்திருந்த அநேக பரிசுத்தவான்களுடைய சரீரங்களும் எழுந்திருந்தது.
	<br /><br />
	53. அவர் உயிர்த்தெழுந்தபின்பு, இவர்கள் கல்லறைகளைவிட்டுப் புறப்பட்டு, பரிசுத்த நகரத்தில் பிரவேசித்து, அநேகருக்குக் காணப்பட்டார்கள்.
	<br /><br />
	54. நூற்றுக்கு அதிபதியும், அவனோடேகூட இயேசுவைக் காவல்காத்திருந்தவர்களும், பூமியதிர்ச்சியையும் சம்பவித்த காரியங்களையும் கண்டு, மிகவும் பயந்து: மெய்யாகவே இவர் தேவனுடைய குமாரன் என்றார்கள்.
	<br /><br />
	55. மேலும், இயேசுவுக்கு ஊழியஞ்செய்யும்படி கலிலேயாவிலிருந்து அவரோடே வந்திருந்த அநேக ஸ்திரீகள் அங்கே தூரத்திலே நின்று பார்த்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	56. அவர்களுக்குள்ளே மகதலேனா மரியாளும், யாக்கோபுக்கும் யோசேக்கும் தாயாகிய மரியாளும், செபெதேயுவின் குமாரருடைய தாயும் இருந்தார்கள்.
	<br /><br />
	57. சாயங்காலமானபோது, இயேசுவுக்குச் சீஷனும் ஐசுவரியவானுமாயிருந்த யோசேப்பு என்னும் பேர்கொண்ட அரிமத்தியா ஊரானாகிய ஒரு மனுஷன் வந்து,
	<br /><br />
	58. பிலாத்துவினிடத்தில் போய், இயேசுவின் சரீரத்தைக் கேட்டான். அப்பொழுது, சரீரத்தைக் கொடுக்கும்படி பிலாத்து கட்டளையிட்டான்.
	<br /><br />
	59. யோசேப்பு அந்தச் சரீரத்தை எடுத்து, துய்யதான மெல்லிய துப்பட்டியிலே சுற்றி,
	<br /><br />
	60. தான் கன்மலையில் வெட்டியிருந்த தன்னுடைய புதிய கல்லறையிலே அதை வைத்து, கல்லறையின் வாசலில் ஒரு பெரிய கல்லைப் புரட்டிவைத்துப் போனான்.
	<br /><br />
	61. அங்கே மகதலேனா மரியாளும் மற்ற மரியாளும் கல்லறைக்கு எதிராக உட்கார்ந்திருந்தார்கள்.
	<br /><br />
	62. ஆயத்தநாளுக்குப் பின்னான மறுநாளிலே பிரதான ஆசாரியரும் பரிசேயரும் பிலாத்துவினிடத்தில் கூடிவந்து:
	<br /><br />
	63. ஆண்டவனே, அந்த எத்தன் உயிரோடிருக்கும்போது, மூன்றுநாளைக்குப்பின் எழுந்திருப்பேன் என்று சொன்னது எங்களுக்கு ஞாபகமிருக்கிறது.
	<br /><br />
	64. ஆகையால், அவனுடைய சீஷர்கள் இராத்திரியிலே வந்து, அவனைக் களவாய்க் கொண்டுபோய், மரித்தோரிலிருந்து எழுந்தானென்று ஜனங்களுக்குச் சொல்லாதபடிக்கும், முந்தின எத்தைப்பார்க்கிலும் பிந்தின எத்து கொடிதாகாதபடிக்கும், நீர் மூன்றுநாள்வரைக்கும் கல்லறையைப் பத்திரப்படுத்தும்படி கட்டளையிடவேண்டும் என்றார்கள்.
	<br /><br />
	65. அதற்குப் பிலாத்து: உங்களுக்குக் காவல்சேவகர் உண்டே; போய், உங்களால் கூடியமட்டும் பத்திரப்படுத்திக்கொள்ளுங்கள் என்றான்.
	<br /><br />
	66. அவர்கள் போய்க் கல்லுக்கு முத்திரைபோட்டு, காவல்வைத்து, கல்லறையைப் பத்திரப்படுத்தினார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew27