import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts24 = () => {
  const verseNumber = 24;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 24 </title>
	<meta
          name="description"
          content="Acts 24 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 24 |
          Acts of Apostles Chapter-24 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஐந்துநாளைக்குப்பின்பு பிரதான ஆசாரியனாகிய அனனியா மூப்பர்களோடும் தெர்த்துல்லு என்னும் ஒரு நியாயசாதுரியனோடும்கூடப் போனான், அவர்கள் பவுலுக்கு விரோதமாய்த் தேசாதிபதியினிடத்தில் பிராதுபண்ணினார்கள்.
	<br /><br />
	2. அவன் அழைக்கப்பட்டபோது, தெர்த்துல்லு குற்றஞ்சாட்டத்தொடங்கி:
	<br /><br />
	3. கனம்பொருந்திய பேலிக்ஸே, உம்மாலே நாங்கள் மிகுந்த சமாதான சவுக்கியத்தை அநுபவிக்கிறதையும், உம்முடைய பராமரிப்பினாலே இந்தத் தேசத்தாருக்குச் சிறந்த நன்மைகள் நடக்கிறதையும் நாங்கள் எப்பொழுதும் எங்கும் மிகுந்த நன்றியறிதலுடனே அங்கிகாரம்பண்ணுகிறோம்.
	<br /><br />
	4. உம்மை நான் அநேக வார்த்தைகளினாலே அலட்டாதபடிக்கு, நாங்கள் சுருக்கமாய்ச் சொல்வதை நீர் பொறுமையாய்க் கேட்கவேண்டுமென்று பிரார்த்திக்கிறேன்.
	<br /><br />
	5. என்னவென்றால், இந்த மனுஷன் கொள்ளைநோயாகவும், பூச்சக்கரத்திலுள்ள சகல யூதர்களுக்குள்ளும் கலகம் எழுப்புகிறவனாகவும், நசரேயருடைய மதபேதத்துக்கு முதலாளியாகவும் இருக்கிறானென்று கண்டறிந்தோம்.
	<br /><br />
	6. இவன் தேவாலயத்தையும் தீட்டுப்படுத்தப் பார்த்தான். நாங்கள் இவனைப்பிடித்து எங்கள் வேதப்பிரமாணத்தின்படியே நியாயந்தீர்க்க மனதாயிருந்தோம்.
	<br /><br />
	7. அப்பொழுது சேனாபதியாகிய லீசியா வந்து, மிகுந்த பலாத்காரமாய் இவனை எங்கள் கைகளிலிருந்து பறித்துக்கொண்டுபோய்,
	<br /><br />
	8. இவன்மேல் குற்றஞ்சாட்டுகிறவர்கள் உம்மிடத்தில் வரும்படி கட்டளையிட்டார். இவனிடத்தில் நீர் விசாரித்தால் நாங்கள் இவன்மேல் சாட்டுகிற குற்றங்கள் யாவையும் அறிந்துகொள்ளலாம் என்றான்.
	<br /><br />
	9. யூதர்களும் அதற்கு இசைந்து, இவைகள் யதார்த்தந்தான் என்றார்கள்.
	<br /><br />
	10. பவுல் பேசும்படி தேசாதிபதி சைகைகாட்டினபோது, அவன் உத்தரவாக: நீர் அநேக வருஷகாலமாய் இந்தத் தேசத்தாருக்கு நியாயாதிபதியாயிருக்கிறீரென்றறிந்து, நான் என் காரியங்களைக்குறித்து அதிக தைரியத்துடன் உத்தரவு சொல்லுகிறேன்.
	<br /><br />
	11. நான் தொழுதுகொள்ளும்படியாக எருசலேமுக்குப் போனதுமுதல் இதுவரைக்கும் பன்னிரண்டு நாள்மாத்திரம் ஆயிற்றென்று நீர் அறிந்துகொள்ளலாம்.
	<br /><br />
	12. தேவாலயத்திலே நான் ஒருவரிடத்திலாவது தர்க்கம்பண்ணினதையும், நான் ஜெபஆலயங்களிலாகிலும் நகரத்திலாகிலும் ஜனங்களுக்குள்ளே கலகமெழுப்பினதையும், இவர்கள் கண்டதில்லை.
	<br /><br />
	13. இப்பொழுது என்மேல் சாட்டுகிற குற்றங்களை இவர்கள் ரூபிக்கவுமாட்டார்கள்.
	<br /><br />
	14. உம்மிடத்தில் ஒன்றை ஒத்துக்கொள்ளுகிறேன்; அதென்னவென்றால், இவர்கள் மதபேதம் என்று சொல்லுகிற மார்க்கத்தின்படியே எங்கள் முன்னோர்களின் தேவனுக்கு ஆராதனைசெய்து நியாயப்பிரமாணத்திலேயும் தீர்க்கதரிசிகள் புஸ்தகங்களிலேயும் எழுதியிருக்கிற எல்லாவற்றையும் நான் விசுவாசித்து,
	<br /><br />
	15. நீதிமான்களும் அநீதிமான்களுமாகிய மரித்தோர் உயிர்த்தெழுந்திருப்பது உண்டென்று இவர்கள் தேவனிடத்தில் நம்பிக்கைகொண்டிருக்கிறது போல, நானும் நம்பிக்கைகொண்டிருக்கிறேன்.
	<br /><br />
	16. இதனால் நான் தேவனுக்கும் மனுஷருக்கும் முன்பாக எப்பொழுதும் குற்றமற்ற மனச்சாட்சியை உடையவனாயிருக்கப் பிரயாசப்படுகிறேன்.
	<br /><br />
	17. அநேக வருஷங்களுக்குப் பின்பு நான் என் ஜனத்தாருக்குத் தர்மப்பணத்தை ஒப்புவிக்கவும், காணிக்கைகளைச் செலுத்தவும் வந்தேன்.
	<br /><br />
	18. அப்பொழுது கூட்டமில்லாமலும் அமளியில்லாமலும் தேவாலயத்திலே சுத்திகரித்துக்கொண்டவனாயிருக்கையில், ஆசியா நாட்டாரான சில யூதர்கள் என்னைக் கண்டார்கள்.
	<br /><br />
	19. அவர்களுக்கு என்பேரில் விரோதமான காரியம் ஏதாகிலும் உண்டாயிருந்தால், அவர்களே இங்கே வந்து, உமக்கு முன்பாகக் குற்றஞ்சாட்டவேண்டும்.
	<br /><br />
	20. நான் ஆலோசனைச் சங்கத்தாருக்கு முன்பாக நின்றபோது அவர்கள் யாதொரு அநியாயத்தை என்னிடத்தில் கண்டதுண்டானால் இவர்களே அதைச்சொல்லட்டும்.
	<br /><br />
	21. நான் அவர்களுக்குள்ளே நின்றபோது மரித்தோர் உயிர்த்தெழுந்திருப்பதைக்குறித்து, இன்று உங்களாலே நியாயந்தீர்க்கப்படுகிறேனென்று நான் சொன்ன ஒரு சொல்லினிமித்தமேயன்றி வேறொன்றினிமித்தமும் குற்றங்காணப்படவில்லை என்றான்.
	<br /><br />
	22. இந்த மார்க்கத்தின் விஷயங்களை விவரமாய் அறிந்திருந்த பேலிக்ஸ் இவைகளைக் கேட்டபொழுது: சேனாபதியாகிய லீசியா வரும்போது உங்கள் காரியங்களைத் திட்டமாய் விசாரிப்பேன் என்று சொல்லி;
	<br /><br />
	23. பவுலைக் காவல்பண்ணவும், கண்டிப்பில்லாமல் நடத்தவும், அவனுக்கு ஊழியஞ்செய்கிறதற்கும் அவனைக் கண்டுகொள்ளுகிறதற்கும் வருகிற அவனுடைய மனுஷர்களில் ஒருவரையும் தடைசெய்யாதிருக்கவும் நூற்றுக்கு அதிபதியானவனுக்குக் கட்டளையிட்டு, அவர்களைக் காத்திருக்கும்படிசெய்தான்.
	<br /><br />
	24. சில நாளைக்குப்பின்பு பேலிக்ஸ் யூதஸ்திரீயாகிய தன் மனைவி துருசில்லாளுடனேகூட வந்து, பவுலை அழைப்பித்து, கிறிஸ்துவைப்பற்றும் விசுவாசத்தைக் குறித்து அவன் சொல்லக்கேட்டான்.
	<br /><br />
	25. அவன், நீதியையும், இச்சையடக்கத்தையும், இனிவரும் நியாயத்தீர்ப்பையும்குறித்துப் பேசுகையில், பேலிக்ஸ் பயமடைந்து: இப்பொழுது நீ போகலாம், எனக்குச் சமயமானபோது உன்னை அழைப்பிப்பேன் என்றான்.
	<br /><br />
	26. மேலும், அவன் பவுலை விடுதலைபண்ணும்படி தனக்கு அவன் பணங்கொடுப்பானென்று நம்பிக்கையுள்ளவனாயிருந்தான்; அதினிமித்தம் அவன் அநேகந்தரம் அவனை அழைத்து, அவனுடனே பேசினான்.
	<br /><br />
	27. இரண்டு வருஷம் சென்றபின்பு பேலிக்ஸ் என்பவனுக்குப் பதிலாய்ப் பொர்க்கியுபெஸ்து தேசாதிபதியாக வந்தான்; அப்பொழுது பேலிக்ஸ் யூதருக்குத் தயவுசெய்ய மனதாய்ப் பவுலைக் காவலில் வைத்துவிட்டுப்போனான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts24