import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 8 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 8
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆனபடியால், கிறிஸ்து இயேசுவுக்குட்பட்டவர்களாயிருந்து, மாம்சத்தின்படி நடவாமல் ஆவியின்படியே நடக்கிறவர்களுக்கு ஆக்கினைத்தீர்ப்பில்லை.
	<br /><br />
	2. கிறிஸ்து இயேசுவினாலே ஜீவனுடைய ஆவியின் பிரமாணம் என்னைப் பாவம் மரணம் என்பவைகளின் பிரமாணத்தினின்று விடுதலையாக்கிற்றே.
	<br /><br />
	3. அதெப்படியெனில், மாம்சத்தினாலே பலவீனமாயிருந்த நியாயப்பிரமாணம் செய்யக்கூடாததைத் தேவனே செய்யும்படிக்கு, தம்முடைய குமாரனைப் பாவமாம்சத்தின் சாயலாகவும், பாவத்தைப் போக்கும் பலியாகவும் அனுப்பி, மாம்சத்திலே பாவத்தை ஆக்கினைக்குள்ளாகத் தீர்த்தார்.
	<br /><br />
	4. மாம்சத்தின்படி நடவாமல் ஆவியின்படி நடக்கிற நம்மிடத்தில் நியாயப்பிரமாணத்தின் நீதி நிறைவேறும்படிக்கே அப்படிச் செய்தார்.
	<br /><br />
	5. அன்றியும் மாம்சத்தின்படி நடக்கிறவர்கள் மாம்சத்துக்குரியவைகளைச் சிந்திக்கிறார்கள்; ஆவியின்படி நடக்கிறவர்கள் ஆவிக்குரியவைகளைச் சிந்திக்கிறார்கள்.
	<br /><br />
	6. மாம்சசிந்தை மரணம்; ஆவியின் சிந்தையோ ஜீவனும் சமாதானமுமாம்.
	<br /><br />
	7. எப்படியென்றால், மாம்சசிந்தை தேவனுக்கு விரோதமான பகை; அது தேவனுடைய நியாயப்பிரமாணத்துக்குக் கீழ்ப்படியாமலும், கீழ்ப்படியக்கூடாமலும் இருக்கிறது.
	<br /><br />
	8. மாம்சத்துக்குட்பட்டவர்கள் தேவனுக்குப் பிரியமாயிருக்கமாட்டார்கள்.
	<br /><br />
	9. தேவனுடைய ஆவி உங்களில் வாசமாயிருந்தால், நீங்கள் மாம்சத்துக்குட்பட்டவர்களாயிராமல் ஆவிக்குட்பட்டவர்களாயிருப்பீர்கள். கிறிஸ்துவின் ஆவியில்லாதவன் அவருடையவனல்ல.
	<br /><br />
	10. மேலும் கிறிஸ்து உங்களிலிருந்தால் சரீரமானது பாவத்தினிமித்தம் மரித்ததாயும், ஆவியானது நீதியினிமித்தம் ஜீவனுள்ளதாயும் இருக்கும்.
	<br /><br />
	11. அன்றியும் இயேசுவை மரித்தோரிலிருந்து எழுப்பினவருடைய ஆவி உங்களில் வாசமாயிருந்தால், கிறிஸ்துவை மரித்தோரிலிருந்து எழுப்பினவர் உங்களில் வாசமாயிருக்கிற தம்முடைய ஆவியினாலே சாவுக்கேதுவான உங்கள் சரீரங்களையும் உயிர்ப்பிப்பார்.
	<br /><br />
	12. ஆகையால் சகோதரரே, மாம்சத்தின்படி பிழைப்பதற்கு நாம் மாம்சத்துக்குக் கடனாளிகளல்ல.
	<br /><br />
	13. மாம்சத்தின்படி பிழைத்தால் சாவீர்கள்; ஆவியினாலே சரீரத்தின் செய்கைகளை அழித்தால் பிழைப்பீர்கள்.
	<br /><br />
	14. மேலும் எவர்கள் தேவனுடைய ஆவியினாலே நடத்தப்படுகிறார்களோ, அவர்கள் தேவனுடைய புத்திரராயிருக்கிறார்கள்.
	<br /><br />
	15. அந்தப்படி, திரும்பவும் பயப்படுகிறதற்கு நீங்கள் அடிமைத்தனத்தின் ஆவியைப் பெறாமல், அப்பா பிதாவே, என்று கூப்பிடப்பண்ணுகிற புத்திரசுவிகாரத்தின் ஆவியைப் பெற்றீர்கள்.
	<br /><br />
	16. நாம் தேவனுடைய பிள்ளைகளாயிருக்கிறோமென்று ஆவியானவர்தாமே நம்முடைய ஆவியுடனேகூடச் சாட்சிகொடுக்கிறார்.
	<br /><br />
	17. நாம் பிள்ளைகளானால் சுதந்தரருமாமே; தேவனுடைய சுதந்தரரும், கிறிஸ்துவுக்கு உடன் சுதந்தரருமாமே; கிறிஸ்துவுடனேகூட நாம் மகிமைப்படும்படிக்கு அவருடனேகூடப் பாடுபட்டால் அப்படியாகும்.
	<br /><br />
	18. ஆதலால் இக்காலத்துப் பாடுகள் இனி நம்மிடத்தில் வெளிப்படும் மகிமைக்கு ஒப்பிடத்தக்கவைகள் அல்லவென்று எண்ணுகிறேன்.
	<br /><br />
	19. மேலும் தேவனுடைய புத்திரர் வெளிப்படுவதற்குச் சிருஷ்டியானது மிகுந்த ஆவலோடே காத்துக்கொண்டிருக்கிறது.
	<br /><br />
	20. அதேனென்றால் சிருஷ்டியானது அழிவுக்குரிய அடிமைத்தனத்தினின்று விடுதலையாக்கப்பட்டு, தேவனுடைய பிள்ளைகளுக்குரிய மகிமையான சுயாதீனத்தைப் பெற்றுக்கொள்ளும் என்கிற நம்பிக்கையோடே,
	<br /><br />
	21. அந்தச் சிருஷ்டியானது சுய இஷ்டத்தினாலே அல்ல, கீழ்ப்படுத்தினவராலேயே மாயைக்குக் கீழ்ப்பட்டிருக்கிறது.
	<br /><br />
	22. ஆகையால் நமக்குத் தெரிந்திருக்கிறபடி, இதுவரைக்கும் சர்வ சிருஷ்டியும் ஏகமாய்த் தவித்துப் பிரசவவேதனைப்படுகிறது.
	<br /><br />
	23. அதுவுமல்லாமல், ஆவியின் முதற்பலன்களைப் பெற்ற நாமுங்கூட நம்முடைய சரீர மீட்பாகிய புத்திரசுவிகாரம் வருகிறதற்குக் காத்திருந்து, நமக்குள்ளே தவிக்கிறோம்.
	<br /><br />
	24. அந்த நம்பிக்கையினாலே நாம் இரட்சிக்கப்பட்டிருக்கிறோம். காணப்படுகிறதை நம்புகிறது நம்பிக்கையல்ல; ஒருவன் தான் காண்கிறதை நம்பவேண்டுவதென்ன?
	<br /><br />
	25. நாம் காணாததை நம்பினோமாகில், அது வருகிறதற்குப் பொறுமையோடே காத்திருப்போம்.
	<br /><br />
	26. அந்தப்படியே ஆவியானவரும் நமது பலவீனங்களில் நமக்கு உதவிசெய்கிறார். நாம் ஏற்றபடி வேண்டிக்கொள்ள வேண்டியதின்னதென்று அறியாமலிருக்கிறபடியால், ஆவியானவர்தாமே வாக்குக்கடங்காத பெருமூச்சுகளோடு நமக்காக வேண்டுதல்செய்கிறார்.
	<br /><br />
	27. ஆவியானவர் தேவனுடைய சித்தத்தின்படியே பரிசுத்தவான்களுக்காக வேண்டுதல் செய்கிறபடியால், இருதயங்களை ஆராய்ந்துபார்க்கிறவர் ஆவியின் சிந்தை இன்னதென்று அறிவார்.
	<br /><br />
	28. அன்றியும், அவருடைய தீர்மானத்தின்படி அழைக்கப்பட்டவர்களாய் தேவனிடத்தில் அன்புகூருகிறவர்களுக்குச் சகலமும் நன்மைக்கு ஏதுவாக நடக்கிறதென்று அறிந்திருக்கிறோம்.
	<br /><br />
	29. தம்முடைய குமாரன் அநேக சகோதரருக்குள்ளே முதற்பேறானவராயிருக்கும்பொருட்டு, தேவன் எவர்களை முன்னறிந்தாரோ அவர்களைத் தமது குமாரனுடைய சாயலுக்கு ஒப்பாயிருப்பதற்கு முன்குறித்திருக்கிறார்;
	<br /><br />
	30. எவர்களை முன்குறித்தாரோ அவர்களை அழைத்துமிருக்கிறார்; எவர்களை அழைத்தாரோ அவர்களை நீதிமான்களாக்கியுமிருக்கிறார்; எவர்களை நீதிமான்களாக்கினாரோ அவர்களை மகிமைப்படுத்தியுமிருக்கிறார்.
	<br /><br />
	31. இவைகளைக்குறித்து நாம் என்ன சொல்லுவோம்? தேவன் நம்முடைய பட்சத்திலிருந்தால் நமக்கு விரோதமாயிருப்பவன் யார்?
	<br /><br />
	32. தம்முடைய சொந்தக்குமாரனென்றும் பாராமல் நம்மெல்லாருக்காகவும் அவரை ஒப்புக்கொடுத்தவர், அவரோடேகூட மற்ற எல்லாவற்றையும் நமக்கு அருளாதிருப்பதெப்படி?
	<br /><br />
	33. தேவன் தெரிந்துகொண்டவர்கள்மேல் குற்றஞ்சாட்டுகிறவன் யார்? தேவனே அவர்களை நீதிமான்களாக்குகிறவர்.
	<br /><br />
	34. ஆக்கினைக்குள்ளாகத் தீர்க்கிறவன் யார்? கிறிஸ்துவே மரித்தவர்; அவரே எழுந்துமிருக்கிறவர்; அவரே தேவனுடைய வலதுபாரிசத்திலும் இருக்கிறவர்; நமக்காக வேண்டுதல்செய்கிறவரும் அவரே.
	<br /><br />
	35. உமதுநிமித்தம் எந்நேரமும் கொல்லப்படுகிறோம், அடிக்கப்படும் ஆடுகளைப்போல எண்ணப்படுகிறோம் என்று எழுதியிருக்கிறபடி நேரிட்டாலும்,
	<br /><br />
	36. கிறிஸ்துவின் அன்பைவிட்டு நம்மைப் பிரிப்பவன் யார்? உபத்திரவமோ, வியாகுலமோ, துன்பமோ, பசியோ, நிர்வாணமோ, நாசமோசமோ, பட்டயமோ?
	<br /><br />
	37. இவையெல்லாவற்றிலேயும் நாம் நம்மில் அன்புகூருகிறவராலே முற்றும் ஜெயங்கொள்ளுகிறவர்களாயிருக்கிறோமே.
	<br /><br />
	38. மரணமானாலும், ஜீவனானாலும், தேவதூதர்களானாலும், அதிகாரங்களானாலும், வல்லமைகளானாலும், நிகழ்காரியங்களானாலும், வருங்காரியங்களானாலும்,
	<br /><br />
	39. உயர்வானாலும், தாழ்வானாலும், வேறெந்தச் சிருஷ்டியானாலும் நம்முடைய கர்த்தராகிய கிறிஸ்து இயேசுவிலுள்ள தேவனுடைய அன்பைவிட்டு நம்மைப் பிரிக்கமாட்டாதென்று நிச்சயித்திருக்கிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans8