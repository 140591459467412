import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 18 </title>
	<meta
          name="description"
          content="Isaiah 18 | ஏசாயா 18 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எத்தியோப்பியாவின் நதிகளுக்கு அக்கரையிலே நிழலிடும் செட்டைகளுடையதும்,
	<br /><br />
	2. கடல்வழியாய்த் தண்ணீர்களின்மேல் நாணல் படவுகளிலே ஸ்தானாபதிகளை அனுப்புகிறதுமான தேசத்துக்கு ஐயோ! வேகமான தூதர்களே, நெடுந்தூரமாய்ப் பரவியிருக்கிறதும், சிரைக்கப்பட்டதும், துவக்கமுதல் இதுவரைக்கும் கெடியாயிருந்ததும், அளவிடப்பட்டதும், மிதிக்கப்பட்டதும், நதிகள் பாழாக்குகிறதுமான ஜாதியண்டைக்குப் போங்கள்.
	<br /><br />
	3. பூச்சக்கரத்தில் வாசமாயிருக்கிறவர்களும், தேசத்துக் குடிகளுமாகிய நீங்களெல்லாரும், மலைகளின்மேல் கொடியேற்றப்படும்போது பாருங்கள், எக்காளம் ஊதப்படும்போது கேளுங்கள்.
	<br /><br />
	4. நான் அமர்ந்திருந்து பயிரின்மேல் காயும் காந்தியுள்ள வெயிலைப்போலவும், அறுப்புக்காலத்து உஷ்ணத்தில் உண்டாகும் பனிமேகத்தைப்போலவும், என் வாசஸ்தலத்திலிருந்து கண்ணோக்குவேன் என்று கர்த்தர் என்னுடனே சொன்னார்.
	<br /><br />
	5. திராட்சச்செடிகள் அறுப்புக்கு முன்னே பூப்பூத்து முற்றிக் காய்க்கிற காய்கள் பிஞ்சாயிருக்கும்போதே, அவர் அரிவாள்களினாலே கப்புக்கவர்களை அறுத்துக் கொடிகளையரிந்து அகற்றிப்போடுவார்.
	<br /><br />
	6. அவைகள் ஏகமாய் மலைகளின் பட்சிகளுக்கும், பூமியின் மிருகங்களுக்கும் விடப்படும்; பட்சிகள் அதின்மேல் கோடைகாலத்திலும், காட்டுமிருகங்களெல்லாம் அதின்மேல் மாரிகாலத்திலும் தங்கும்.
	<br /><br />
	7. அக்காலத்திலே நெடுந்தூரமாய்ப் பரவியிருக்கிறதும், சிரைக்கப்பட்டதும், துவக்கமுதல் இதுவரைக்கும் கெடியாயிருந்ததும், அளவிடப்பட்டதும், மிதிக்கப்பட்டதும், நதிகள் பாழாக்குகிறதுமான ஜாதியானது, சேனைகளின் கர்த்தரின் நாமம் தங்கும் ஸ்தலமாகிய சீயோன் மலையில், சேனைகளின் கர்த்தருக்குக் காணிக்கையாகக் கொண்டுவரப்படும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah18