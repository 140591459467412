import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 2 </title>
	<meta
          name="description"
          content="John Chapter 2 | யோவான் அதிகாரம் 2 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மூன்றாம் நாளிலே கலிலேயாவிலுள்ள கானா ஊரிலே ஒரு கலியாணம் நடந்தது; இயேசுவின் தாயும் அங்கேயிருந்தாள்.
	<br /><br />
	2. இயேசுவும் அவருடைய சீஷரும் அந்தக் கலியாணத்துக்கு அழைக்கப்பட்டிருந்தார்கள்.
	<br /><br />
	3. திராட்சரசம் குறைவுபட்டபோது, இயேசுவின் தாய் அவரை நோக்கி: அவர்களுக்குத் திராட்சரசம் இல்லை என்றாள்.
	<br /><br />
	4. அதற்கு இயேசு: ஸ்திரீயே, எனக்கும் உனக்கும் என்ன, என் வேளை இன்னும் வரவில்லை என்றார்.
	<br /><br />
	5. அவருடைய தாய் வேலைக்காரரை நோக்கி: அவர் உங்களுக்கு என்ன சொல்லுகிறாரோ, அதின்படி செய்யுங்கள் என்றாள்.
	<br /><br />
	6. யூதர்கள் தங்களைச் சுத்திகரிக்கும் முறைமையின்படியே, ஒவ்வொன்று இரண்டு மூன்று குடம் தண்ணீர் கொள்ளத்தக்க ஆறு கற்சாடிகள் அங்கே வைத்திருந்தது.
	<br /><br />
	7. இயேசு வேலைக்காரரை நோக்கி: ஜாடிகளிலே தண்ணீர் நிரப்புங்கள் என்றார்; அவர்கள் அவைகளை நிறைய நிரப்பினார்கள்.
	<br /><br />
	8. அவர் அவர்களை நோக்கி: நீங்கள் இப்பொழுது மொண்டு, பந்திவிசாரிப்புக்காரனிடத்தில் கொண்டுபோங்கள் என்றார்; அவர்கள் கொண்டுபோனார்கள்.
	<br /><br />
	9. அந்தத் திராட்சரசம் எங்கேயிருந்து வந்ததென்று தண்ணீரை மொண்ட வேலைக்காரருக்குத் தெரிந்ததேயன்றி பந்திவிசாரிப்புக்காரனுக்குத் தெரியாததினால், அவன் திராட்சரசமாய் மாறின தண்ணீரை ருசிபார்த்தபோது, மணவாளனை அழைத்து:
	<br /><br />
	10. எந்த மனுஷனும் முன்பு நல்ல திராட்சரசத்தைக் கொடுத்து, ஜனங்கள் திருப்தியடைந்தபின்பு, ருசி குறைந்ததைக் கொடுப்பான், நீரோ நல்ல ரசத்தை இதுவரைக்கும் வைத்திருந்தீரே என்றான்.
	<br /><br />
	11. இவ்விதமாக இயேசு இந்த முதலாம் அற்புதத்தைக் கலிலேயாவிலுள்ள கானா ஊரிலே செய்து, தம்முடைய மகிமையை வெளிப்படுத்தினார்; அவருடைய சீஷர்கள் அவரிடத்தில் விசுவாசம் வைத்தார்கள்.
	<br /><br />
	12. அதன்பின்பு அவரும் அவருடைய தாயாரும் அவருடைய சகோதரரும் அவருடைய சீஷரும் கப்பர்நகூமுக்குப்போய், அங்கே சிலநாள் தங்கினார்கள்.
	<br /><br />
	13. பின்பு யூதருடைய பஸ்காபண்டிகை சமீபமாயிருந்தது; அப்பொழுது இயேசு எருசலேமுக்குப் போய்,
	<br /><br />
	14. தேவாலயத்திலே ஆடுகள் மாடுகள் புறாக்களாகிய இவைகளை விற்கிறவர்களையும், காசுக்காரர் உட்கார்ந்திருக்கிறதையும் கண்டு,
	<br /><br />
	15. கயிற்றினால் ஒரு சவுக்கையுண்டுபண்ணி, அவர்கள் யாவரையும் ஆடுமாடுகளையும் தேவாலயத்துக்குப் புறம்பே துரத்திவிட்டு, காசுக்காரருடைய காசுகளைக் கொட்டி, பலகைகளைக் கவிழ்த்துப்போட்டு,
	<br /><br />
	16. புறா விற்கிறவர்களை நோக்கி: இவைகளை இவ்விடத்திலிருந்து எடுத்துக்கொண்டுபோங்கள்; என் பிதாவின் வீட்டை வியாபார வீடாக்காதிருங்கள் என்றார்.
	<br /><br />
	17. அப்பொழுது: உம்முடைய வீட்டைக்குறித்து உண்டான பக்திவைராக்கியம் என்னைப் பட்சித்தது என்று எழுதியிருக்கிறதை அவருடைய சீஷர்கள் நினைவுகூர்ந்தார்கள்.
	<br /><br />
	18. அப்பொழுது யூதர்கள் அவரை நோக்கி: நீர் இவைகளைச் செய்கிறீரே, இதற்கு என்ன அடையாளத்தை எங்களுக்குக் காண்பிக்கிறீர் என்று கேட்டார்கள்.
	<br /><br />
	19. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: இந்த ஆலயத்தை இடித்துப்போடுங்கள், மூன்று நாளைக்குள்ளே இதை எழுப்புவேன் என்றார்.
	<br /><br />
	20. அப்பொழுது யூதர்கள்: இந்த ஆலயத்தைக் கட்ட நாற்பத்தாறு வருஷம் சென்றதே, நீர் இதை மூன்று நாளைக்குள்ளே எழுப்புவீரோ என்றார்கள்.
	<br /><br />
	21. அவரோ தம்முடைய சரீரமாகிய ஆலயத்தைக்குறித்துப் பேசினார்.
	<br /><br />
	22. அவர் இப்படிச் சொன்னதை அவர் மரித்தோரிலிருந்தெழுந்தபின்பு அவருடைய சீஷர்கள் நினைவுகூர்ந்து, வேதவாக்கியத்தையும் இயேசு சொன்ன வசனத்தையும் விசுவாசித்தார்கள்.
	<br /><br />
	23. பஸ்காபண்டிகையிலே அவர் எருசலேமிலிருக்கையில், அவர் செய்த அற்புதங்களை அநேகர் கண்டு, அவருடைய நாமத்தில் விசுவாசம் வைத்தார்கள்.
	<br /><br />
	24. அப்படியிருந்தும், இயேசு எல்லாரையும் அறிந்திருந்தபடியால், அவர்களை நம்பி இணங்கவில்லை.
	<br /><br />
	25. மனுஷருள்ளத்திலிருப்பதை அவர் அறிந்திருந்தபடியால், மனுஷரைக் குறித்து ஒருவரும் அவருக்குச் சாட்சி கொடுக்கவேண்டியதாயிருக்கவில்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John2