import React from 'react';
import { Link } from 'react-router-dom';
import home from '../home.png';

const Header = ({ verseNumber }) => {

    const nextVerseNumber = verseNumber + 1;
  const backVerseNumber = verseNumber - 1;
  return (
    <header className='vh'>
      <div>
            <h1 className="verseh">
            யாக்கோபு  
            </h1>
            <span className='verseno'>
                {verseNumber}
            </span>
            
        </div>
      <div className="navigation">
        <div className='vbn'>
            {backVerseNumber > 0 && (
          <Link to={`/James-${backVerseNumber}`}>
            <span className='verseb'></span>
          </Link>
        )}
      <span className='versehome'>
        <Link to="/James">
          <img src={home} alt='home' />
        </Link>
      </span>{nextVerseNumber <= 5 && (
          <Link to={`/James-${nextVerseNumber}`}>
          <span className='versen'></span>
        </Link>
        )}
        </div>
        </div>
    </header>
  );
};

export default Header;
