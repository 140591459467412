import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial38 = () => {
  const verseNumber = 38;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 38 </title>
	<meta
          name="description"
          content="Ezekiel 38 | எசேக்கியேல்  38 |
          Ezekiel 38 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, மேசேக் தூபால் ஜாதிகளின் தலைமையான அதிபதியாகிய மாகோகு தேசத்தானான கோகுக்கு எதிராக நீ உன் முகத்தைத் திருப்பி, அவனுக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து,
	<br /><br />
	3. சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், மேசேக் தூபால் ஜாதிகளின் அதிபதியாகிய கோகே, இதோ, நான் உனக்கு விரோதமாக வருவேன்.
	<br /><br />
	4. நான் உன்னைத் திருப்பி, உன் வாயில் துறடுகளைப் போட்டு, உன்னையும் உன்னுடைய எல்லாச் சேனையையும், குதிரைகளையும், சர்வாயுதந்தரித்த குதிரைவீரர்களையும், பரிசையும் கேடகமுமுடைய திரளான கூட்டத்தையும் புறப்படப்பண்ணுவேன்; அவர்கள் எல்லாரும் பட்டயங்களைப் பிடித்திருப்பார்கள்.
	<br /><br />
	5. அவர்களோடேகூடப் பெர்சியரும், எத்தியோப்பியரும், லீபியரும் இருப்பார்கள்; அவர்களெல்லாரும் கேடகம் பிடித்து, தலைச்சீராவுந் தரித்திருப்பவர்கள்.
	<br /><br />
	6. கோமேரும் அவனுடைய எல்லா இராணுவங்களும், வடதிசையிலுள்ள தோகர்மா வம்சத்தாரும் அவர்களுடைய எல்லா இராணுவங்களுமாகிய திரளான ஜனங்கள் உன்னுடனேகூட இருப்பார்கள்.
	<br /><br />
	7. நீ ஆயத்தப்படு, உன்னுடனே கூடின உன் எல்லாக் கூட்டத்தையும் ஆயத்தப்படுத்து; நீ அவர்களுக்குக் காவலாளனாயிரு.
	<br /><br />
	8. அநேக நாட்களுக்குப் பிற்பாடு நீ விசாரிக்கப்படுவாய்; பட்டயத்துக்கு நீங்கலாகி, பற்பல ஜனங்களிலிருந்து சேர்த்துக்கொள்ளப்பட்டு வந்தவர்களின் தேசத்தில் கடைசி வருஷங்களிலே வருவாய்; நெடுநாள் பாழாய்க்கிடந்து, பிற்பாடு ஜாதிகளிலிருந்து கொண்டுவரப்பட்டவர்கள் எல்லாரும் சுகத்தோடே குடியிருக்கும் இஸ்ரவேலின் மலைகளுக்கு விரோதமாய் வருவாய்; அவர்கள் எல்லாரும் அஞ்சாமல் குடியிருக்கும்போது,
	<br /><br />
	9. பெருங்காற்றைப்போல் எழும்பிவருவாய்; நீயும் உன்னுடைய எல்லா இராணுவங்களும் உன்னோடேகூட இருக்கும் திரளான ஜனங்களும் கார்மேகம்போல் தேசத்தை மூடுவீர்கள்.
	<br /><br />
	10. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: அந்நாளிலே பாழாய்க்கிடந்து திரும்பக் குடியேற்றப்பட்ட ஸ்தலங்களுக்கு விரோதமாகவும், ஜாதிகளிடத்திலிருந்து சேர்க்கப்பட்டதும், ஆடுகளையும் மாடுகளையும் ஆஸ்திகளையும் சம்பாதித்து, தேசத்தின் நடுவில் குடியிருக்கிறதுமான ஜனத்துக்கு விரோதமாகவும், நீ உன் கையைத் திருப்பும்படிக்கு,
	<br /><br />
	11. உன் இருதயத்தில் யோசனைகள் எழும்ப, நீ பொல்லாத நினைவை நினைத்து,
	<br /><br />
	12. நான் கொள்ளையிடவும் சூறையாடவும், மதில்களில்லாமல் கிடக்கிற கிராமங்களுள்ள தேசத்துக்கு விரோதமாய்ப்போவேன்; நிர்விசாரமாய்ச் சுகத்தோடே குடியிருக்கிறவர்களின்மேல் வருவேன்; அவர்கள் எல்லாரும் மதில்களில்லாமல் குடியிருக்கிறார்கள்; அவர்களுக்குத் தாழ்ப்பாளும் இல்லை, கதவுகளும் இல்லை என்பாய்.
	<br /><br />
	13. சேபா தேசத்தாரும், தேதான் தேசத்தாரும், தர்ஷீசின் வர்த்தகரும் அதினுடைய பாலசிங்கங்களான அனைவரும் உன்னை நோக்கி: நீ கொள்ளையிட அல்லவோ வருகிறாயென்றும், நீ சூறையாடி, வெள்ளியையும் பொன்னையும் ஆஸ்தியையும் எடுத்துக்கொள்ளுகிறதற்கும், ஆடுகளையும் மாடுகளையும் பிடிக்கிறதற்கும், மிகவும் கொள்ளையிடுகிறதற்கும் அல்லவோ உன்னுடைய கூட்டத்தைக் கூட்டினாயென்றும் சொல்லுவார்கள்.
	<br /><br />
	14. ஆகையால் மனுபுத்திரனே, நீ தீர்க்கதரிசனம் உரைத்து, கோகை நோக்கிச் சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார்; என் ஜனமாகிய இஸ்ரவேல் சுகமாய்க் குடியிருக்கிற அக்காலத்திலே நீ அதை அறிவாய் அல்லவோ?
	<br /><br />
	15. அப்பொழுது நீயும் உன்னுடனேகூடத் திரளான ஜனங்களும் வடதிசையிலுள்ள உன் ஸ்தானத்திலிருந்து வருவீர்கள்; அவர்கள் பெரிய கூட்டமும் திரளான சேனையுமாயிருந்து, எல்லாரும் குதிரைகளின்மேல் ஏறுகிறவர்களாயிருப்பார்கள்.
	<br /><br />
	16. நீ தேசத்தைக் கார்மேகம்போல் மூட, என் ஜனமாகிய இஸ்ரவேலுக்கு விரோதமாக எழும்பிவருவாய்; கடைசி நாட்களிலே இது சம்பவிக்கும்; கோகே, ஜாதிகளின் கண்களுக்கு முன்பாக உன்மூலமாய் நான் பரிசுத்தர் என்று விளங்கப்படுகிறதினால் அவர்கள் என்னை அறியும்படிக்கு உன்னை என் தேசத்துக்கு விரோதமாக வரப்பண்ணுவேன்.
	<br /><br />
	17. உன்னை அவர்களுக்கு விரோதமாக வரப்பண்ணுவேன் என்று பூர்வநாட்களிலே அநேக வருஷகாலமாய்த் தீர்க்கதரிசனம் உரைத்து, இஸ்ரவேலின் தீர்க்கதரிசிகளாகிய என் ஊழியக்காரரைக்கொண்டு, அந்நாட்களிலே நான் குறித்துச்சொன்னவன் நீ அல்லவோ என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	18. இஸ்ரவேல் தேசத்துக்கு விரோதமாகக் கோகு வரும்காலத்தில் என் உக்கிரம் என் நாசியில் ஏறுமென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	19. அந்நாளிலே இஸ்ரவேல் தேசத்திலே பெரிய அதிர்ச்சி உண்டாகி,
	<br /><br />
	20. என் பிரசன்னத்தினால் சமுத்திரத்து மச்சங்களும், ஆகாயத்துப் பறவைகளும், வெளியின் மிருகங்களும், தரையில் ஊருகிற சகல பிராணிகளும், தேசமெங்குமுள்ள சகல நரஜீவன்களும் அதிரும்; பர்வதங்கள் இடியும்; செங்குத்தானவைகள் விழும்; எல்லா மதில்களும் தரையிலே விழுந்துபோம் என்று என் எரிச்சலினாலும் என் சினத்தின் அக்கினியினாலும் நிச்சயமாய்ச் சொல்லுகிறேன்.
	<br /><br />
	21. என் எல்லா மலைகளிலும் பட்டயத்தை அவனுக்கு விரோதமாக வரவழைப்பேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; அவனவன் பட்டயம் அவனவன் சகோதரனுக்கு விரோதமாயிருக்கும்.
	<br /><br />
	22. கொள்ளைநோயினாலும் இரத்தம் சிந்துதலினாலும் நான் அவனோடே வழக்காடி, அவன்மேலும் அவன் இராணுவங்களின்மேலும் அவனோடிருக்கும் திரளான ஜனங்களின்மேலும் வெள்ளமாய் அடிக்கும் மழையையும், பெருங்கல்மழையையும், அக்கினியையும், கந்தகத்தையும் வருஷிக்கப்பண்ணுவேன்.
	<br /><br />
	23. இவ்விதமாய் நான் அநேக ஜாதிகளின் கண்களுக்கு முன்பாக என் மகத்துவத்தையும் என் பரிசுத்தத்தையும் விளங்கப்பண்ணி, அறியப்படுவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial38