import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts28 = () => {
  const verseNumber = 28;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 28 </title>
	<meta
          name="description"
          content="Acts 28 | அப்போஸ்தலர் அதிகாரம் 28 |
          Acts of Apostles Chapter-28 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நாங்கள் தப்பிக் கரைசேர்ந்தபின்பு, அந்தத் தீவின்பேர் மெலித்தா என்று அறிந்தோம்.
	<br /><br />
	2. அந்நியராகிய அந்தத் தீவார் எங்களுக்குப் பாராட்டின அன்பு கொஞ்சமல்ல. அந்த வேளையிலே பிடித்திருந்த மழைக்காகவும் குளிருக்காகவும் அவர்கள் நெருப்பை மூட்டி, எங்கள் அனைவரையும் சேர்த்துக்கொண்டார்கள்.
	<br /><br />
	3. பவுல் சில விறகுகளை வாரி அந்த நெருப்பின்மேல் போடுகையில், ஒரு விரியன்பாம்பு அனலுறைத்துப் புறப்பட்டு அவனுடைய கையைக் கவ்விக்கொண்டது.
	<br /><br />
	4. விஷப்பூச்சி அவன் கையிலே தொங்குகிறதை அந்நியராகிய அந்தத் தீவார் கண்டபோது, இந்த மனுஷன் கொலைபாதகன், இதற்குச் சந்தேகமில்லை; இவன் சமுத்திரத்துக்குத் தப்பிவந்தும், பழியானது இவனைப் பிழைக்கவொட்டவில்லையென்று தங்களுக்குள்ளே சொல்லிக்கொண்டார்கள்.
	<br /><br />
	5. அவன் அந்தப் பூச்சியைத் தீயிலே உதறிப்போட்டு, ஒரு தீங்கும் அடையாதிருந்தான்.
	<br /><br />
	6. அவனுக்கு வீக்கங்கண்டு, அல்லது அவன் சடிதியாய் விழுந்து சாவானென்று அவர்கள் பார்த்துக்கொண்டிருந்தார்கள்; நெடுநேரமாய்ப் பார்த்துக்கொண்டிருந்தும், ஒரு சேதமும் அவனுக்கு வராததைக் கண்டபோது, வேறு சிந்தையாகி, இவன் தேவனென்று சொல்லிக்கொண்டார்கள்.
	<br /><br />
	7. தீவுக்கு முதலாளியாகிய புபிலியு என்னும் பேர்கொண்டவனுடைய நிலங்கள் அந்த இடத்திற்குச் சமீபமாயிருந்தது; அவன் எங்களை ஏற்றுக்கொண்டு, மூன்றுநாள் பட்சமாய் விசாரித்தான்.
	<br /><br />
	8. புபிலியுவினுடைய தகப்பன் ஜுரத்தினாலும் இரத்தபேதியினாலும் வருத்தப்பட்டுக் கிடந்தான்; பவுல் அவனிடத்திற்குப் போய் ஜெபம்பண்ணி, அவன்மேல் கைகளை வைத்து, அவனைக் குணமாக்கினான்.
	<br /><br />
	9. இது நடந்தபின்பு, தீவிலே இருந்த மற்ற வியாதிக்காரரும் வந்து, குணமாக்கப்பட்டார்கள்.
	<br /><br />
	10. அவர்கள் எங்களுக்கு அநேக மரியாதை செய்து, நாங்கள் கப்பல் ஏறிப்போகிறபோது எங்களுக்குத் தேவையானவைகளை ஏற்றினார்கள்.
	<br /><br />
	11. மூன்றுமாதம் சென்றபின்பு, அந்தத் தீவிலே மழைகாலத்திற்குத் தங்கியிருந்த மிதுனம் என்னும் அடையாளமுடைய அலெக்சந்திரியாபட்டணத்துக் கப்பலிலே நாங்கள் ஏறிப் புறப்பட்டு,
	<br /><br />
	12. சீரகூசா பட்டணத்தைச் சேர்ந்து, அங்கே மூன்றுநாள் தங்கினோம்.
	<br /><br />
	13. அவ்விடம்விட்டுச் சுற்றியோடி, ரேகியுதுறைமுகத்துக்கு வந்து சேர்ந்தோம். மறுநாளில் தென்றற் காற்றெடுக்கையில் புறப்பட்டு, இரண்டாம் நாள் புத்தேயோலிபட்டணத்திற்கு வந்து,
	<br /><br />
	14. அங்கே சகோதரரைக் கண்டோம்; அவர்கள் எங்களை ஏழுநாள் தங்களிடத்தில் இருக்கும்படி வேண்டிக்கொண்டார்கள்; அந்தப்படி நாங்கள் இருந்து, பின்பு ரோமாபுரிக்குப் போனோம்.
	<br /><br />
	15. அவ்விடத்திலுள்ள சகோதரர்கள் நாங்கள் வருகிற செய்தியைக் கேள்விப்பட்டு, சிலர் அப்பியுபுரம்வரைக்கும், சிலர் மூன்று சத்திரம்வரைக்கும், எங்களுக்கு எதிர்கொண்டுவந்தார்கள்; அவர்களைப் பவுல் கண்டு, தேவனை ஸ்தோத்திரித்துத் தைரியமடைந்தான்.
	<br /><br />
	16. நாங்கள் ரோமாபுரியில் சேர்ந்தபோது, நூற்றுக்கு அதிபதி தன் காவலிலிருந்தவர்களைச் சேனாபதியினிடத்தில் ஒப்புக்கொடுத்தான்; அப்பொழுது பவுல் தன்னைக் காத்திருக்கிற சேவகனுடனே தனித்துக் குடியிருக்கும்படி உத்தரவு பெற்றுக்கொண்டான்.
	<br /><br />
	17. மூன்றுநாளைக்குப்பின்பு, பவுல் யூதரில் பிரதானமானவர்களை வரவழைத்தான்; அவர்கள் கூடிவந்திருந்தபோது, அவன் அவர்களை நோக்கி: சகோதரரே, நம்முடைய ஜனங்களுக்கும் நம்முடைய முன்னோர்களின் முறைமைகளுக்கும் விரோதமானதொன்றையும் நான் செய்யாமலிருந்தும், கட்டப்பட்டவனாக எருசலேமிலிருந்து ரோமர் கைகளில் ஒப்புக்கொடுக்கப்பட்டேன்.
	<br /><br />
	18. அவர்கள் என்னை நியாயம் விசாரித்தபோது மரணத்துக்கேதுவான குற்றம் ஒன்றும் என்னிடத்தில் காணாதபடியினால், என்னை விடுதலையாக்க மனதாயிருந்தார்கள்.
	<br /><br />
	19. யூதர்கள் அதற்கு எதிர்பேசினபோது, நான் இராயனுக்கு அபயமிடவேண்டியதாயிருந்தது; ஆயினும் என் ஜனத்தார்மேல் யாதொரு குற்றஞ் சாட்டவேண்டுமென்று நான் அப்படிச் செய்யவில்லை.
	<br /><br />
	20. இந்தக் காரியத்தினிமித்தமே உங்களைக் காணவும் உங்களுடனே பேசவும் உங்களை அழைப்பித்தேன். இஸ்ரவேலுடைய நம்பிக்கைக்காகவே இந்தச் சங்கிலியால் கட்டப்பட்டிருக்கிறேன் என்றான்.
	<br /><br />
	21. அதற்கு அவர்கள்: உன்னைக்குறித்து யூதேயாவிலிருந்து எங்களுக்குக் காகிதம் வரவுமில்லை, வந்த சகோதரரில் ஒருவனும் உன்பேரில் ஒரு பொல்லாங்கை அறிவித்ததுமில்லை, அதைப்பற்றிப் பேசினதுமில்லை.
	<br /><br />
	22. எங்கும் இந்த மதபேதத்துக்கு விரோதமாய்ப் பேசுகிறதாக நாங்கள் அறிந்திருக்கிறபடியால், இதைக்குறித்து உன்னுடைய அபிப்பிராயம் என்னவென்று கேட்டறிய விரும்புகிறோம் என்றார்கள்.
	<br /><br />
	23. அதற்காக அவர்கள் ஒரு நாளைக்குறித்து, அநேகம்பேர் அவன் தங்கியிருந்த வீட்டிற்கு அவனிடத்தில் வந்தார்கள். அவன் காலமே தொடங்கிச் சாயங்காலமட்டும் மோசேயின் நியாயப்பிரமாணத்திலும் தீர்க்கதரிசிகளின் ஆகமங்களிலும் இருந்து இயேசுவுக்கடுத்த விசேஷங்களை அவர்களுக்குப் போதித்து, தேவனுடைய ராஜ்யத்தைக் குறித்துச் சாட்சிகொடுத்து விஸ்தரித்துப் பேசினான்.
	<br /><br />
	24. அவன் சொன்னவைகளைச் சிலர் விசுவாசித்தார்கள், சிலர் விசுவாசியாதிருந்தார்கள்.
	<br /><br />
	25. இப்படி அவர்கள் ஒருவரோடொருவர் ஒவ்வாமலிருந்து, புறப்பட்டுப்போகையில், பவுல் அவர்களுக்குச் சொன்ன வாக்கியமாவது:
	<br /><br />
	26. நீங்கள் காதாரக்கேட்டும் உணராதிருப்பீர்கள், கண்ணாரக்கண்டும் பாராதிருப்பீர்கள்.
	<br /><br />
	27. இவர்கள் கண்களினால் காணாமலும், காதுகளினால் கேளாமலும், இருதயத்தினால் உணர்ந்து குணப்படாமலும், நான் இவர்களை ஆரோக்கியமாக்காமலும் இருக்கும்படிக்கு, இந்த ஜனத்தின் இருதயம் கொழுத்திருக்கிறது; காதுகளினால் மந்தமாய்க் கேட்டுத் தங்கள் கண்களை மூடிக்கொண்டார்கள் என்று இந்த ஜனத்தினிடத்தில் போய்ச் சொல்லு என்பதைப் பரிசுத்தஆவி ஏசாயா தீர்க்கதரிசியைக்கொண்டு நம்முடைய பிதாக்களுடனே நன்றாய்ச் சொல்லியிருக்கிறார்.
	<br /><br />
	28. ஆதலால் தேவனுடைய இரட்சிப்பு புறஜாதியாருக்கு அனுப்பப்பட்டிருக்கிறதென்றும், அவர்கள் அதற்குச் செவிகொடுப்பார்களென்றும் உங்களுக்குத் தெரிந்திருக்கக்கடவது என்றான்.
	<br /><br />
	29. இப்படி அவன் சொன்னபின்பு, யூதர்கள் தங்களுக்குள்ளே மிகவும் தர்க்கம் பண்ணிக்கொண்டு, போய்விட்டார்கள்.
	<br /><br />
	30. பின்பு பவுல் தனக்காக வாடகைக்கு வாங்கியிருந்த வீட்டிலே இரண்டு வருஷமுழுதும் தங்கி, தன்னிடத்தில் வந்த யாவரையும் ஏற்றுக்கொண்டு,
	<br /><br />
	31. மிகுந்த தைரியத்துடனே தடையில்லாமல், தேவனுடைய ராஜ்யத்தைக் குறித்துப் பிரசங்கித்து, கர்த்தராகிய இயேசுகிறிஸ்துவைப்பற்றிய விசேஷங்களை உபதேசித்துக்கொண்டிருந்தான்.
</p>
<p>
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts28