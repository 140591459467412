import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 7 </title>
	<meta
          name="description"
          content="Mark 7 | மாற்கு 7 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எருசலேமிலிருந்து வந்த பரிசேயரும், வேதபாரகரில் சிலரும் அவரிடத்தில் கூடிவந்தார்கள்.
	<br /><br />
	2. அப்பொழுது அவருடைய சீஷரில் சிலர் கழுவாத அசுத்த கைகளாலே போஜனம்பண்ணுகிறதை அவர்கள் கண்டு குற்றம்பிடித்தார்கள்.
	<br /><br />
	3. ஏனெனில் பரிசேயர் முதலிய யூதர் அனைவரும் முன்னோர்களின் பாரம்பரியத்தைக் கைக்கொண்டு, அடிக்கடி கைகழுவினாலொழியச் சாப்பிடமாட்டார்கள்.
	<br /><br />
	4. கடையிலிருந்து வரும்போதும் ஸ்நானம்பண்ணாமல் சாப்பிடமாட்டார்கள். அப்படியே செம்புகளையும் கிண்ணங்களையும் செப்புக்குடங்களையும் மணைகளையும் கழுவுகிறதுமல்லாமல், வேறு அநேக ஆசாரங்களையும் கைக்கொண்டுவருவார்கள்.
	<br /><br />
	5. அப்பொழுது, அந்தப் பரிசேயரும் வேதபாரகரும் அவரை நோக்கி: உம்முடைய சீஷர்கள் முன்னோர்களின் பாரம்பரியத்தை மீறி, ஏன் கை கழுவாமல் சாப்பிடுகிறார்கள் என்று கேட்டார்கள்.
	<br /><br />
	6. அவர்களுக்கு அவர் பிரதியுத்தரமாக: இந்த ஜனங்கள் தங்கள் உதடுகளினால் என்னைக் கனம்பண்ணுகிறார்கள்; அவர்கள் இருதயமோ எனக்குத் தூரமாய் விலகியிருக்கிறது என்றும்,
	<br /><br />
	7. மனுஷருடைய கற்பனைகளை உபதேசங்களாகப் போதித்து, வீணாய் எனக்கு ஆராதனை செய்கிறார்கள் என்றும், எழுதியிருக்கிறபிரகாரம், மாயக்காரராகிய உங்களைக் குறித்து, ஏசாயா நன்றாய்த் தீர்க்கதரிசனம் சொல்லியிருக்கிறான்.
	<br /><br />
	8. நீங்கள் தேவனுடைய கட்டளையைத் தள்ளிவிட்டு, மனுஷருடைய பாரம்பரியத்தைக் கைக்கொண்டுவருகிறவர்களாய், கிண்ணங்களையும் செம்புகளையும் கழுவுகிறீர்கள்; மற்றும் இப்படிப்பட்ட அநேக சடங்குகளையும் அனுசரித்துவருகிறீர்கள் என்றார்.
	<br /><br />
	9. பின்னும் அவர் அவர்களை நோக்கி: நீங்கள் உங்கள் பாரம்பரியத்தைக் கைக்கொள்ளும்படிக்குத் தேவனுடைய கட்டளைகளை வியர்த்தமாக்கினது நன்றாயிருக்கிறது.
	<br /><br />
	10. எப்படியெனில், உன் தகப்பனையும் உன் தாயையும் கனம்பண்ணுவாயாக என்றும், தகப்பனையாவது தாயையாவது நிந்திக்கிறவன் கொல்லப்படவேண்டும் என்றும், மோசே சொல்லியிருக்கிறாரே.
	<br /><br />
	11. நீங்களோ, ஒருவன் தன் தகப்பனையாவது தாயையாவது நோக்கி: உனக்கு நான் செய்யத்தக்க உதவி எது உண்டோ, அதைக் கொர்பான் என்னும் காணிக்கையாகக் கொடுக்கிறேன் என்று சொல்லிவிட்டால் அவனுடைய கடமை தீர்ந்தது என்று சொல்லி,
	<br /><br />
	12. அவனை இனி தன் தகப்பனுக்காவது தன் தாய்க்காவது யாதொரு உதவியும் செய்ய ஒட்டாமல்;
	<br /><br />
	13. நீங்கள் போதித்த உங்கள் பாரம்பரியத்தினால் தேவவசனத்தை அவமாக்குகிறீர்கள். இதுபோலவே நீங்கள் மற்றும் அநேக காரியங்களையும் செய்கிறீர்கள் என்று சொன்னார்.
	<br /><br />
	14. பின்பு அவர் ஜனங்களெல்லாரையும் வரவழைத்து அவர்களை நோக்கி: நீங்கள் எல்லாரும் எனக்குச் செவிகொடுத்து உணருங்கள்.
	<br /><br />
	15. மனுஷனுக்குப் புறம்பே இருந்து அவனுக்குள்ளே போகிறதொன்றும் அவனைத் தீட்டுப்படுத்தமாட்டாது; அவன் உள்ளத்திலிருந்து புறப்படுகிறவைகளே அவனைத் தீட்டுப்படுத்தும்.
	<br /><br />
	16. கேட்கிறதற்கு ஒருவன் காதுள்ளவனாயிருந்தால் கேட்கக்கடவன் என்றார்.
	<br /><br />
	17. அவர் ஜனங்களைவிட்டு வீட்டுக்குள் பிரவேசித்தபோது, அவருடைய சீஷர்கள் அவர் சொன்ன உவமையைக்குறித்து அவரிடத்தில் விசாரித்தார்கள்.
	<br /><br />
	18. அதற்கு அவர்: நீங்களும் இவ்வளவு உணர்வில்லாதவர்களா? புறம்பேயிருந்து மனுஷனுக்குள்ளே போகிறதொன்றும் அவனைத் தீட்டுப்படுத்தமாட்டாதென்று நீங்கள் அறிந்துகொள்ளவில்லையா?
	<br /><br />
	19. அது அவன் இருதயத்தில் போகாமல் வயிற்றிலே போகிறது; அதிலிருந்து எல்லாப் போஜனங்களின் அசுத்தங்களையும் கழிக்கிற ஆசனவழியாய் நீங்கிப்போகும்.
	<br /><br />
	20. மனுஷனுக்குள்ளே இருந்து புறப்படுகிறதே மனுஷனைத் தீட்டுப்படுத்தும்.
	<br /><br />
	21. எப்படியெனில், மனுஷருடைய இருதயத்திற்குள்ளிருந்து பொல்லாத சிந்தனைகளும், விபசாரங்களும், வேசித்தனங்களும், கொலைபாதகங்களும்,
	<br /><br />
	22. களவுகளும், பொருளாசைகளும், துஷ்டத்தனங்களும், கபடும், காமவிகாரமும், வன்கண்ணும், தூஷணமும், பெருமையும், மதிகேடும் புறப்பட்டுவரும்.
	<br /><br />
	23. பொல்லாங்கானவைகளாகிய இவைகளெல்லாம் உள்ளத்திலிருந்து புறப்பட்டு மனுஷனைத் தீட்டுப்படுத்தும் என்றார்.
	<br /><br />
	24. பின்பு, அவர் எழுந்து அவ்விடம் விட்டுப் புறப்பட்டு, தீரு சீதோன் பட்டணங்களின் எல்லைகளில் போய், ஒரு வீட்டுக்குள் பிரவேசித்து, ஒருவரும் அதை அறியாதிருக்க விரும்பியும், அவர் மறைவாயிருக்கக் கூடாமற்போயிற்று.
	<br /><br />
	25. அசுத்த ஆவி பிடித்திருந்த ஒரு சிறு பெண்ணின் தாயாகிய ஒரு ஸ்திரீ அவரைக்குறித்துக் கேள்விப்பட்டு, வந்து அவர் பாதத்தில் விழுந்தாள்.
	<br /><br />
	26. அந்த ஸ்திரீ சீரோபேனிக்கியா தேசத்தாளாகிய கிரேக்க ஸ்திரீயாயிருந்தாள்; அவள் தன் மகளைப் பிடித்திருந்த பிசாசைத் துரத்திவிடவேண்டுமென்று அவரை வேண்டிக்கொண்டாள்.
	<br /><br />
	27. இயேசு அவளை நோக்கி: முந்திப் பிள்ளைகள் திருப்தியடையட்டும்; பிள்ளைகளின் அப்பத்தை எடுத்து நாய்க்குட்டிகளுக்குப் போடுகிறது நல்லதல்ல என்றார்.
	<br /><br />
	28. அதற்கு அவள்: மெய்தான், ஆண்டவரே, ஆகிலும், மேஜையின் கீழிருக்கும் நாய்க்குட்டிகள் பிள்ளைகள் சிந்துகிற துணிக்கைகளைத் தின்னுமே என்றாள்.
	<br /><br />
	29. அப்பொழுது, அவர்: நீ சொன்ன அந்த வார்த்தையினிமித்தம் போகலாம். பிசாசு உன் மகளை விட்டு நீங்கிப்போயிற்று என்றார்.
	<br /><br />
	30. அவள் தன் வீட்டுக்கு வந்தபொழுது, பிசாசு போய்விட்டதையும், தன் மகள் கட்டிலில் படுத்திருக்கிறதையும் கண்டாள்.
	<br /><br />
	31. மறுபடியும், அவர் தீரு சீதோன் பட்டணங்களின் எல்லைகளை விட்டுப் புறப்பட்டு, தெக்கப்போலியின் எல்லைகளின் வழியாய்க் கலிலேயாக் கடலருகே வந்தார்.
	<br /><br />
	32. அங்கே கொன்னைவாயுடைய ஒரு செவிடனை அவரிடத்தில் கொண்டுவந்து, அவர் தமது கையை அவன்மேல் வைக்கும்படி வேண்டிக்கொண்டார்கள்.
	<br /><br />
	33. அப்பொழுது, அவர் அவனை ஜனக்கூட்டத்தை விட்டுத் தனியே அழைத்துக்கொண்டுபோய், தம்முடைய விரல்களை அவன் காதுகளில் வைத்து, உமிழ்ந்து, அவனுடைய நாவைத் தொட்டு;
	<br /><br />
	34. வானத்தை அண்ணாந்துபார்த்து, பெருமூச்சுவிட்டு: எப்பத்தா என்றார்; அதற்குத் திறக்கப்படுவாயாக என்று அர்த்தமாம்.
	<br /><br />
	35. உடனே அவனுடைய செவிகள் திறக்கப்பட்டு, அவனுடைய நாவின் கட்டும் அவிழ்ந்து, அவன் செவ்வையாய்ப் பேசினான்.
	<br /><br />
	36. அதை ஒருவருக்கும் சொல்லவேண்டாமென்று அவர்களுக்குக் கட்டளையிட்டார்; ஆகிலும் எவ்வளவு அதிகமாய் அவர்களுக்குக் கட்டளையிட்டாரோ, அவ்வளவு அதிகமாய் அவர்கள் அதைப் பிரசித்தம்பண்ணி,
	<br /><br />
	37. எல்லாவற்றையும் நன்றாய்ச் செய்தார்; செவிடர் கேட்கவும், ஊமையர் பேசவும்பண்ணுகிறார் என்று சொல்லி, மேன்மேலும் ஆச்சரியப்பட்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark7