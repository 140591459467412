import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 13 </title>
	<meta
          name="description"
          content="Matthew 13 | மத்தேயு 13 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு அன்றையத்தினமே வீட்டிலிருந்து புறப்பட்டுப்போய், கடலோரத்திலே உட்கார்ந்தார்.
	<br /><br />
	2. திரளான ஜனங்கள் அவரிடத்தில் கூடிவந்தபடியால், அவர் படவில் ஏறி உட்கார்ந்தார்; ஜனங்களெல்லாரும் கரையிலே நின்றார்கள்.
	<br /><br />
	3. அவர் அநேக விசேஷங்களை உவமைகளாக அவர்களுக்குச் சொன்னார்: கேளுங்கள், விதைக்கிறவன் ஒருவன் விதைக்கப் புறப்பட்டான்.
	<br /><br />
	4. அவன் விதைக்கையில், சில விதை வழியருகே விழுந்தது; பறவைகள் வந்து அதைப் பட்சித்துப்போட்டது.
	<br /><br />
	5. சில விதை அதிக மண்ணில்லாத கற்பாறை இடங்களில் விழுந்தது; மண் ஆழமாயிராததினாலே அது சீக்கிரமாய் முளைத்தது.
	<br /><br />
	6. வெயில் ஏறினபோதோ, தீய்ந்து போய், வேரில்லாமையால் உலர்ந்து போயிற்று.
	<br /><br />
	7. சில விதை முள்ளுள்ள இடங்களில் விழுந்தது; முள் வளர்ந்து அதை நெருக்கிப்போட்டது.
	<br /><br />
	8. சில விதையோ நல்ல நிலத்தில் விழுந்து, சிலது நூறாகவும், சிலது அறுபதாகவும், சிலது முப்பதாகவும் பலன் தந்தது.
	<br /><br />
	9. கேட்கிறதற்குக் காதுள்ளவன் கேட்கக்கடவன் என்றார்.
	<br /><br />
	10. அப்பொழுது, சீஷர்கள் அவரிடத்தில் வந்து: ஏன் அவர்களோடே உவமைகளாகப் பேசுகிறீர் என்று கேட்டார்கள்.
	<br /><br />
	11. அவர்களுக்கு அவர் பிரதியுத்தரமாக: பரலோகராஜ்யத்தின் ரகசியங்களை அறியும்படி உங்களுக்கு அருளப்பட்டது, அவர்களுக்கோ அருளப்படவில்லை.
	<br /><br />
	12. உள்ளவன் எவனோ அவனுக்குக் கொடுக்கப்படும், பரிபூரணமும் அடைவான்; இல்லாதவன் எவனோ அவனிடத்தில் உள்ளதும் எடுத்துக்கொள்ளப்படும்.
	<br /><br />
	13. அவர்கள் கண்டும் காணாதவர்களாயும், கேட்டும் கேளாதவர்களாயும், உணர்ந்துகொள்ளாதவர்களாயும் இருக்கிறபடியினால், நான் உவமைகளாக அவர்களோடே பேசுகிறேன்.
	<br /><br />
	14. ஏசாயாவின் தீர்க்கதரிசனம் அவர்களிடத்தில் நிறைவேறுகிறது; அதாவது: காதாரக்கேட்டும் உணராதிருப்பீர்கள்; கண்ணாரக்கண்டும் அறியாதிருப்பீர்கள்.
	<br /><br />
	15. இந்த ஜனங்கள் கண்களினால் காணாமலும், காதுகளினால் கேளாமலும், இருதயத்தினால் உணர்ந்து மனந்திரும்பாமலும், நான் அவர்களை ஆரோக்கியமாக்காமலும் இருக்கும்படியாக, அவர்கள் இருதயம் கொழுத்திருக்கிறது; காதால் மந்தமாய்க் கேட்டு, தங்கள் கண்களை மூடிக்கொண்டார்கள் என்பதே.
	<br /><br />
	16. உங்கள் கண்கள் காண்கிறதினாலும், உங்கள் காதுகள் கேட்கிறதினாலும், அவைகள் பாக்கியமுள்ளவைகள்.
	<br /><br />
	17. அநேக தீர்க்கதரிசிகளும் நீதிமான்களும் நீங்கள் காண்கிறவைகளைக் காணவும், நீங்கள் கேட்கிறவைகளைக் கேட்கவும் விரும்பியும், காணாமலும் கேளாமலும் போனார்களென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	18. ஆகையால் விதைக்கிறவனைப்பற்றிய உவமையைக் கேளுங்கள்.
	<br /><br />
	19. ஒருவன், ராஜ்யத்தின் வசனத்தைக் கேட்டும் உணராதிருக்கும்போது, பொல்லாங்கன் வந்து, அவன் இருதயத்தில் விதைக்கப்பட்டதைப் பறித்துக்கொள்ளுகிறான்; அவனே வழியருகே விதைக்கப்பட்டவன்.
	<br /><br />
	20. கற்பாறை இடங்களில் விதைக்கப்பட்டவன், வசனத்தைக் கேட்டு, உடனே அதைச் சந்தோஷத்தோடே ஏற்றுக்கொள்ளுகிறவன்;
	<br /><br />
	21. ஆகிலும் தனக்குள்ளே வேரில்லாதவனாய், கொஞ்சக்காலமாத்திரம் நிலைத்திருப்பான்; வசனத்தினிமித்தம் உபத்திரவமும் துன்பமும் உண்டானவுடனே இடறலடைவான்.
	<br /><br />
	22. முள்ளுள்ள இடங்களில் விதைக்கப்பட்டவன், வசனத்தைக் கேட்கிறவனாயிருந்தும், உலகக்கவலையும் ஐசுவரியத்தின் மயக்கமும் வசனத்தை நெருக்கிப் போடுகிறதினால், அவனும் பலனற்றுப் போவான்.
	<br /><br />
	23. நல்ல நிலத்தில் விதைக்கப்பட்டவனோ, வசனத்தைக் கேட்கிறவனும் உணருகிறவனுமாயிருந்து, நூறாகவும் அறுபதாகவும் முப்பதாகவும் பலன் தருவான் என்றார்.
	<br /><br />
	24. வேறொரு உவமையை அவர்களுக்குச் சொன்னார்: பரலோகராஜ்யம் தன் நிலத்தில் நல்ல விதையை விதைத்த மனுஷனுக்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	25. மனுஷர் நித்திரைபண்ணுகையில் அவனுடைய சத்துரு வந்து, கோதுமைக்குள் களைகளை விதைத்துவிட்டுப் போனான்.
	<br /><br />
	26. பயிரானது வளர்ந்து கதிர்விட்ட போது, களைகளும் காணப்பட்டது.
	<br /><br />
	27. வீட்டெஜமானுடைய வேலைக்காரர் அவனிடத்தில் வந்து: ஆண்டவனே, நீர் உமது நிலத்தில் நல்ல விதையை விதைத்தீர் அல்லவா? பின்னை அதில் களைகள் எப்படி உண்டானது என்றார்கள்.
	<br /><br />
	28. அதற்கு அவன்: சத்துரு அதைச் செய்தான் என்றான். அப்பொழுது வேலைக்காரர்: நாங்கள் போய் அவைகளைப் பிடுங்கிப்போட உமக்குச் சித்தமா? என்று கேட்டார்கள்.
	<br /><br />
	29. அதற்கு அவன்: வேண்டாம், களைகளைப் பிடுங்கும்போது நீங்கள் கோதுமையையுங்கூட வேரோடே பிடுங்காதபடிக்கு, இரண்டையும் அறுப்புமட்டும் வளரவிடுங்கள்.
	<br /><br />
	30. அறுப்புக்காலத்தில் நான் அறுக்கிறவர்களை நோக்கி: முதலாவது, களைகளைப்பிடுங்கி, அவைகளைச் சுட்டெரிக்கிறதற்குக் கட்டுகளாகக் கட்டுங்கள்; கோதுமையையோ என் களஞ்சியத்தில் சேர்த்துவையுங்கள் என்பேன் என்று சொன்னான் என்றார்.
	<br /><br />
	31. வேறொரு உவமையை அவர்களுக்குச் சொன்னார்: பரலோகராஜ்யம் கடுகு விதைக்கு ஒப்பாயிருக்கிறது; அதை ஒரு மனுஷன் எடுத்துத் தன் நிலத்தில் விதைத்தான்.
	<br /><br />
	32. அது சகல விதைகளிலும் சிறிதாயிருந்தும், வளரும்போது, சகல பூண்டுகளிலும் பெரிதாகி, ஆகாயத்துப் பறவைகள் அதின் கிளைகளில் வந்து அடையத்தக்க மரமாகும் என்றார்.
	<br /><br />
	33. வேறொரு உவமையை அவர்களுக்குச் சொன்னார்: பரலோகராஜ்யம் புளித்தமாவுக்கு ஒப்பாயிருக்கிறது; அதை ஒரு ஸ்திரீ எடுத்து, முழுவதும் புளிக்கும்வரைக்கும், மூன்றுபடி மாவிலே அடக்கிவைத்தாள் என்றார்.
	<br /><br />
	34. இவைகளையெல்லாம் இயேசு ஜனங்களோடே உவமைகளாகப் பேசினார்; உவமைகளினாலேயன்றி, அவர்களோடே பேசவில்லை.
	<br /><br />
	35. என் வாயை உவமைகளினால் திறப்பேன்; உலகத்தோற்றமுதல் மறைபொருளானவைகளை வெளிப்படுத்துவேன் என்று தீர்க்கதரிசியால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது.
	<br /><br />
	36. அப்பொழுது இயேசு ஜனங்களை அனுப்பிவிட்டு வீட்டுக்குப்போனார். அவருடைய சீஷர்கள் அவரிடத்தில் வந்து: நிலத்தின் களைகளைப்பற்றிய உவமையை எங்களுக்கு வெளிப்படுத்தவேண்டுமென்று கேட்டார்கள்.
	<br /><br />
	37. அவர் பிரதியுத்தரமாக: நல்ல விதையை விதைக்கிறவன் மனுஷகுமாரன்;
	<br /><br />
	38. நிலம் உலகம்; நல்ல விதை ராஜ்யத்தின் புத்திரர்; களைகள் பொல்லாங்கனுடைய புத்திரர்;
	<br /><br />
	39. அவைகளை விதைக்கிற சத்துரு பிசாசு; அறுப்பு உலகத்தின் முடிவு; அறுக்கிறவர்கள் தேவதூதர்கள்.
	<br /><br />
	40. ஆதலால், களைகளைச் சேர்த்து அக்கினியால் சுட்டெரிக்கிறதுபோல, இவ்வுலகத்தின் முடிவிலே நடக்கும்.
	<br /><br />
	41. மனுஷகுமாரன் தம்முடைய தூதர்களை அனுப்புவார்; அவர்கள் அவருடைய ராஜ்யத்தில் இருக்கிற சகல இடறல்களையும் அக்கிரமஞ் செய்கிறவர்களையும் சேர்த்து,
	<br /><br />
	42. அவர்களை அக்கினிச் சூளையிலே போடுவார்கள்; அங்கே அழுகையும் பற்கடிப்பும் உண்டாயிருக்கும்.
	<br /><br />
	43. அப்பொழுது, நீதிமான்கள் தங்கள் பிதாவின் ராஜ்யத்திலே சூரியனைப்போலப் பிரகாசிப்பார்கள். கேட்கிறதற்குக் காதுள்ளவன் கேட்கக்கடவன்.
	<br /><br />
	44. அன்றியும், பரலோகராஜ்யம் நிலத்தில் புதைத்திருக்கிற பொக்கிஷத்துக்கு ஒப்பாயிருக்கிறது; அதை ஒரு மனுஷன் கண்டு, மறைத்து, அதைப்பற்றிய சந்தோஷத்தினாலே போய், தனக்கு உண்டான எல்லாவற்றையும் விற்று, அந்த நிலத்தைக் கொள்ளுகிறான்.
	<br /><br />
	45. மேலும், பரலோகராஜ்யம் நல்ல முத்துக்களைத் தேடுகிற வியாபாரிக்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	46. அவன் விலையுயர்ந்த ஒரு முத்தைக் கண்டு, போய், தனக்குண்டான எல்லாவற்றையும் விற்று, அதைக் கொள்ளுகிறான்.
	<br /><br />
	47. அன்றியும், பரலோகராஜ்யம் கடலிலே போடப்பட்டு, சகலவிதமான மீன்களையும் சேர்த்து வாரிக்கொள்ளும் வலைக்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	48. அது நிறைந்தபோது, அதைக் கரையில் இழுத்து, உட்கார்ந்து, நல்லவைகளைக் கூடைகளில் சேர்த்து, ஆகாதவைகளை எறிந்துபோடுவார்கள்.
	<br /><br />
	49. இப்படியே உலகத்தின் முடிவிலே நடக்கும். தேவதூதர்கள் புறப்பட்டு, நீதிமான்களின் நடுவிலிருந்து பொல்லாதவர்களைப் பிரித்து,
	<br /><br />
	50. அவர்களை அக்கினிச்சூளையிலே போடுவார்கள்; அங்கே அழுகையும் பற்கடிப்பும் உண்டாயிருக்கும் என்றார்.
	<br /><br />
	51. பின்பு, இயேசு அவர்களை நோக்கி: இவைகளையெல்லாம் அறிந்துகொண்டீர்களா என்று கேட்டார். அதற்கு அவர்கள்: ஆம், அறிந்துகொண்டோம், ஆண்டவரே, என்றார்கள்.
	<br /><br />
	52. அப்பொழுது அவர் அவர்களை நோக்கி: இப்படியிருக்கிறபடியால், பரலோகராஜ்யத்துக்கடுத்தவைகளில் உபதேசிக்கப்பட்டுத் தேறின வேதபாரகன் எவனும் தன் பொக்கிஷத்திலிருந்து புதியவைகளையும் பழையவைகளையும் எடுத்துக்கொடுக்கிற வீட்டெஜமானாகிய மனுஷனுக்கு ஒப்பாயிருக்கிறான் என்றார்.
	<br /><br />
	53. இயேசு இந்த உவமைகளைச் சொல்லி முடித்தபின்பு, அவ்விடம் விட்டு,
	<br /><br />
	54. தாம் வளர்ந்த ஊரிலே வந்து, அவர்களுடைய ஜெபஆலயத்திலே அவர்களுக்கு உபதேசம்பண்ணினார். அவர்கள் ஆச்சரியப்பட்டு: இவனுக்கு இந்த ஞானமும் பலத்த செய்கைகளும் எப்படி வந்தது?
	<br /><br />
	55. இவன் தச்சனுடைய குமாரன் அல்லவா? இவன் தாய் மரியாள் என்பவள் அல்லவா? யாக்கோபு யோசே சீமோன் யூதா என்பவர்கள் இவனுக்குச் சகோதரர் அல்லவா?
	<br /><br />
	56. இவன் சகோதரிகளெல்லாரும் நம்மிடத்தில் இருக்கிறார்கள் அல்லவா? இப்படியிருக்க, இதெல்லாம் இவனுக்கு எப்படி வந்தது? என்று சொல்லி,
	<br /><br />
	57. அவரைக்குறித்து இடறலடைந்தார்கள். இயேசு அவர்களை நோக்கி: தீர்க்கதரிசி ஒருவன் தன் ஊரிலும் தன் வீட்டிலுமேயன்றி வேறெங்கும் கனவீனமடையான் என்றார்.
	<br /><br />
	58. அவர்களுடைய அவிசுவாசத்தினிமித்தம் அவர் அங்கே அநேக அற்புதங்களைச் செய்யவில்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew13