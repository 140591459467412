import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah25 = () => {
  const verseNumber = 25;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 25 </title>
	<meta
          name="description"
          content="Isaiah 25 | ஏசாயா 25 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தாவே, நீரே என் தேவன்; உம்மை உயர்த்தி, உமது நாமத்தைத் துதிப்பேன்; நீர் அதிசயமானவைகளைச் செய்தீர்; உமது பூர்வ ஆலோசனைகள் சத்தியமும் உறுதியுமானவைகள்.
	<br /><br />
	2. நீர் நகரத்தை மண்மேடும், அரணான பட்டணத்தைப் பாழுமாக்கினீர்; அந்நியரின் ராஜதானியை ஊராயிராதபடிக்கும், என்றைக்கும் கட்டப்படாதபடிக்கும் செய்தீர்.
	<br /><br />
	3. ஆகையால் பலத்த ஜனங்கள் உம்மை மகிமைப்படுத்துவார்கள்; கொடூரமான ஜாதிகளின் நகரம் உமக்குப் பயப்படும்.
	<br /><br />
	4. கொடூரமானவர்களின் சீறல் மதிலை மோதியடிக்கிற பெரு வெள்ளத்தைப்போல் இருக்கையில், நீர் ஏழைக்குப் பெலனும், நெருக்கப்படுகிற எளியவனுக்குத் திடனும், பெருவெள்ளத்துக்குத் தப்பும் அடைக்கலமும், வெயிலுக்கு ஒதுங்கும் நிழலுமானீர்.
	<br /><br />
	5. வறட்சியான இடத்தின் காங்கை மேகத்தினால் தணிவதுபோல், அந்நியரின் மும்முரத்தைத் தணியப்பண்ணுவீர்; மேகத்தின் நிழலினால் வெயில் தணிகிறதுபோல் பெலவந்தரின் ஆரவாரம் தணியும்.
	<br /><br />
	6. சேனைகளின் கர்த்தர் இந்த மலையிலே சகல ஜனங்களுக்கும் ஒரு விருந்தை ஆயத்தப்படுத்துவார்; அது கொழுமையான பதார்த்தங்களும், பழமையான திராட்சரசமும், ஊனும் நிணமுமுள்ள பதார்த்தங்களும், தெளிந்த பழமையான திராட்சரசமும் நிறைந்த விருந்தாயிருக்கும்.
	<br /><br />
	7. சகல ஜனங்கள்மேலுமுள்ள முக்காட்டையும், சகல ஜாதிகளையும் மூடியிருக்கிற மூடலையும், இந்த மலையிலே அகற்றிப்போடுவார்.
	<br /><br />
	8. அவர் மரணத்தை ஜெயமாக விழுங்குவார்; கர்த்தராகிய தேவன் எல்லா முகங்களிலுமிருந்து கண்ணீரைத் துடைத்து, தமது ஜனத்தின் நிந்தையைப் பூமியிலிராதபடிக்கு முற்றிலும் நீக்கிவிடுவார்; கர்த்தரே இதைச் சொன்னார்.
	<br /><br />
	9. அக்காலத்திலே: இதோ, இவரே நம்முடைய தேவன்; இவருக்காகக் காத்திருந்தோம், இவர் நம்மை இரட்சிப்பார்; இவரே கர்த்தர், இவருக்காகக் காத்திருந்தோம்; இவருடைய இரட்சிப்பினால் களிகூர்ந்து மகிழுவோம் என்று சொல்லப்படும்.
	<br /><br />
	10. கர்த்தருடைய கரம் இந்த மலையிலே தங்கும்; கூளம் எருக்களத்தில் மிதிக்கப்படுவதுபோல, மோவாப் அவர்கீழ் மிதிக்கப்பட்டுப்போம்.
	<br /><br />
	11. நீந்துகிறவன் நீந்துவதற்காகத் தன் கைகளை விரிப்பதுபோல் அவர் தமது கைகளை அவர்கள் நடுவிலே விரித்து, அவர்கள் பெருமையையும், அவர்கள் கைகளின் சதிசர்ப்பனைகளையும் தாழ்த்திவிடுவார்.
	<br /><br />
	12. அவர் உன் மதில்களுடைய உயர்ந்த அரணைக் கீழே தள்ளித் தாழ்த்தித் தரையிலே தூளாக அழிப்பார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah25