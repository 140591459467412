import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 13 </title>
	<meta
          name="description"
          content="Hosea 13 | ஓசியா 13 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எப்பிராயீம் பேசினபோது நடுக்கமுண்டாயிற்று; அவன் இஸ்ரவேலிலே மேன்மைபெற்றான்; அவன் பாகால் விஷயத்தில் குற்றஞ்செய்து மடிந்துபோனான்.
	<br /><br />
	2. இப்போதும் அவர்கள் அதிகமதிகமாய்ப் பாவஞ்செய்து, தங்கள் வெள்ளியினால் வார்ப்பித்த சுரூபங்களையும், தங்கள் புத்திக்கேற்க விக்கிரகங்களையும் தங்களுக்கு உண்டுபண்ணுகிறார்கள்; இவைகளெல்லாம் தட்டாருடைய வேலை; மனுஷரில் பலியிடுகிறவர்கள் கன்றுக்குட்டிகளை முத்தமிடலாமென்று இவைகளைக்குறித்துச் சொல்லுகிறார்கள்.
	<br /><br />
	3. ஆகையால் அவர்கள் காலையில் காணும் மேகத்தைப்போலவும், விடியற்காலையில் ஒழிந்துபோகிற பனியைப்போலவும், பெருங்காற்று களத்திலிருந்து பறக்கடிக்கிற பதரைப்போலவும், புகைக்கூண்டில் ஏறிப்போகிற புகையைப்போலவும் இருப்பார்கள்.
	<br /><br />
	4. நான் உன்னை எகிப்து தேசத்திலிருந்து அழைத்துவந்ததுமுதல் உன் தேவனாகிய கர்த்தராயிருக்கிறேன்; ஆகையால் நீ என்னையன்றி வேறே தேவனை அறியவேண்டாம்; என்னையன்றி இரட்சகர் ஒருவரும் இல்லை.
	<br /><br />
	5. நான் உன்னை மகா வறட்சியான தேசமாகிய வனாந்தரத்திலே அறிந்துகொண்டேன்.
	<br /><br />
	6. தங்களுக்கு இருந்த மேய்ச்சலினால் திருப்தியானார்கள்; திருப்தியானபின்பு அவர்கள் இருதயம் மேட்டிமையாயிற்று; அதினால் என்னை மறந்தார்கள்.
	<br /><br />
	7. ஆகையால் நான் அவர்களுக்குச் சிங்கத்தைப்போல் இருப்பேன்; சிவிங்கியைப்போல் வழியருகே பதிவிருப்பேன்.
	<br /><br />
	8. குட்டிகளைப் பறிகொடுத்த கரடியைப்போல நான் அவர்களை எதிர்த்து, அவர்கள் ஈரற்குலையைக் கிழித்து, அவர்களை அங்கே சிங்கம் பட்சிக்கிறதுபோல் பட்சித்துப்போடுவேன்; காட்டுமிருகங்கள் அவர்களைப் பீறிப்போடும்.
	<br /><br />
	9. இஸ்ரவேலே, நீ உனக்குக் கேடுண்டாக்கிக்கொண்டாய்; ஆனாலும் என்னிடத்தில் உனக்குச் சகாயம் உண்டு.
	<br /><br />
	10. எனக்கு ராஜாவையும் அதிபதிகளையும் ஏற்படுத்தவேண்டும் என்றாயே; இப்போதும் உன்னுடைய எல்லாப் பட்டணங்களிலும் உன்னை இரட்சிக்கும் ராஜா எங்கே? உன் நியாயாதிபதிகள் எங்கே?
	<br /><br />
	11. நான் கோபத்திலே உனக்கு ராஜாவைக் கொடுத்தேன்; என் உக்கிரத்திலே அவனை எடுத்துக்கொண்டேன்.
	<br /><br />
	12. எப்பிராயீமின் அக்கிரமம் கட்டிவைத்திருக்கிறது; அவன் பாவம் பத்திரப்படுத்தப்பட்டிருக்கிறது.
	<br /><br />
	13. பிரசவஸ்திரீயின் வேதனை அவனுக்கு வரும்; அவன் விவேகமில்லாத பிள்ளை; பேறுகாலமட்டும் அவன் நிற்கவில்லை.
	<br /><br />
	14. அவர்களை நான் பாதாளத்தின் வல்லமைக்கு நீங்கலாக்கி மீட்பேன்; அவர்களை மரணத்துக்கு நீங்கலாக்கி விடுவிப்பேன்; மரணமே, உன் வாதைகள் எங்கே? பாதாளமே, உன் சங்காரம் எங்கே? மனமாறுதல் என் கண்களுக்கு மறைவானதாயிருக்கும்.
	<br /><br />
	15. இவன் சகோதரருக்குள்ளே ஜனம்பெருத்தவனாயிருந்தாலும், கர்த்தருடைய காற்றாகிய கீழ்க்காற்று வனாந்தரத்திலிருந்து எழும்பிவரும்; அதனால் அவனுடைய ஊற்றுகள் வற்றிப்போகும்; அவனுடைய துரவு சுவறிப்போகும்; அது இச்சிக்கப்படத்தக்க சகல பதார்த்தங்களுள்ள உத்தம பதார்த்தங்களையும் வாரிக்கொண்டுபோகும்.
	<br /><br />
	16. சமாரியா தன் தேவனுக்கு விரோதமாகக் கலகம்பண்ணினபடியால், குற்றஞ் சுமத்தப்பட்டதாயிருக்கும்; அவர்கள் பட்டயத்தால் விழுவார்கள்; அவர்களுடைய குழந்தைகள் மோதியடிக்கப்படும்; அவர்களுடைய கர்ப்பவதிகள் கீறப்பட்டுப்போவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea13