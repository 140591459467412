import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 19 </title>
	<meta
          name="description"
          content="John Chapter 19 | யோவான் அதிகாரம் 19 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது பிலாத்து இயேசுவைப் பிடித்து வாரினால் அடிப்பித்தான்.
	<br /><br />
	2. போர்ச்சேவகர் முள்ளுகளினால் ஒரு முடியைப் பின்னி அவர் சிரசின்மேல் வைத்து, சிவப்பான ஒரு அங்கியை அவருக்கு உடுத்தி:
	<br /><br />
	3. யூதருடைய ராஜாவே, வாழ்க என்று சொல்லி, அவரைக் கையினால் அடித்தார்கள்.
	<br /><br />
	4. பிலாத்து மறுபடியும் வெளியே வந்து: நான் இவனிடத்தில் ஒரு குற்றமும் காணேன் என்று நீங்கள் அறியும்படிக்கு, இதோ, உங்களிடத்தில் இவனை வெளியே கொண்டுவருகிறேன் என்றான்.
	<br /><br />
	5. இயேசு, முள்முடியும் சிவப்பங்கியும் தரித்தவராய், வெளியே வந்தார். அப்பொழுது பிலாத்து அவர்களை நோக்கி: இதோ, இந்த மனுஷன் என்றான்.
	<br /><br />
	6. பிரதான ஆசாரியரும் சேவகரும் அவரைக் கண்டபோது: சிலுவையில் அறையும் சிலுவையில் அறையும் என்று சத்தமிட்டார்கள். அதற்குப் பிலாத்து: நீங்களே இவனைக் கொண்டுபோய்ச் சிலுவையில் அறையுங்கள், நான் இவனிடத்தில் ஒரு குற்றமும் காணேன் என்றான்.
	<br /><br />
	7. யூதர்கள் அவனுக்குப் பிரதியுத்தரமாக: எங்களுக்கு ஒரு நியாயப்பிரமாணமுண்டு, இவன் தன்னைத் தேவனுடைய குமாரனென்று சொன்னபடியினால், அந்த நியாயப்பிரமாணத்தின்படியே, இவன் சாகவேண்டும் என்றார்கள்.
	<br /><br />
	8. பிலாத்து இந்த வார்த்தையைக் கேட்டபொழுது அதிகமாய்ப் பயந்து,
	<br /><br />
	9. மறுபடியும் அரமனைக்குள்ளே போய், இயேசுவை நோக்கி: நீ எங்கேயிருந்து வந்தவன் என்றான். அதற்கு இயேசு மாறுத்தரம் ஒன்றும் சொல்லவில்லை.
	<br /><br />
	10. அப்பொழுது பிலாத்து: நீ என்னோடே பேசுகிறதில்லையா? உன்னைச் சிலுவையில் அறைய எனக்கு அதிகாரமுண்டென்றும், உன்னை விடுதலைபண்ண எனக்கு அதிகாரமுண்டென்றும் உனக்குத் தெரியாதா என்றான்.
	<br /><br />
	11. இயேசு பிரதியுத்தரமாக: பரத்திலிருந்து உமக்குக் கொடுக்கப்படாதிருந்தால், என்மேல் உமக்கு ஒரு அதிகாரமுமிராது; ஆனபடியினாலே என்னை உம்மிடத்தில் ஒப்புக்கொடுத்தவனுக்கு அதிக பாவமுண்டு என்றார்.
	<br /><br />
	12. அதுமுதல் பிலாத்து அவரை விடுதலைபண்ண வகைதேடினான். யூதர்கள் அவனை நோக்கி: இவனை விடுதலைபண்ணினால் நீர் இராயனுக்குச் சிநேகிதனல்ல; தன்னை ராஜாவென்கிறவனெவனோ அவன் இராயனுக்கு விரோதி என்று சத்தமிட்டார்கள்.
	<br /><br />
	13. பிலாத்து இந்த வார்த்தையைக் கேட்டபொழுது, இயேசுவை வெளியே அழைத்துவந்து, தளவரிசைப்படுத்தின மேடையென்றும், எபிரெயு பாஷையிலே கபத்தா என்றும் சொல்லப்பட்ட இடத்திலே, நியாயாசனத்தின்மேல் உட்கார்ந்தான்.
	<br /><br />
	14. அந்த நாள் பஸ்காவுக்கு ஆயத்தநாளும் ஏறக்குறைய ஆறுமணி நேரமுமாயிருந்தது; அப்பொழுது அவன் யூதர்களை நோக்கி: இதோ, உங்கள் ராஜா என்றான்.
	<br /><br />
	15. அவர்கள்: இவனை அகற்றும் அகற்றும், சிலுவையில் அறையும் என்று சத்தமிட்டார்கள். அதற்குப் பிலாத்து: உங்கள் ராஜாவை நான் சிலுவையில் அறையலாமா என்றான். பிரதான ஆசாரியர் பிரதியுத்தரமாக: இராயனேயல்லாமல் எங்களுக்கு வேறே ராஜா இல்லை என்றார்கள்.
	<br /><br />
	16. அப்பொழுது அவரைச் சிலுவையில் அறையும்படிக்கு அவர்களிடத்தில் ஒப்புக்கொடுத்தான். அவர்கள் இயேசுவைப் பிடித்துக்கொண்டுபோனார்கள்.
	<br /><br />
	17. அவர் தம்முடைய சிலுவையைச் சுமந்துகொண்டு, எபிரெயு பாஷையிலே கொல்கொதா என்று சொல்லப்படும் கபாலஸ்தலம் என்கிற இடத்திற்குப் புறப்பட்டுப்போனார்.
	<br /><br />
	18. அங்கே அவரைச் சிலுவையில் அறைந்தார்கள்; அவரோடேகூட வேறிரண்டுபேரை இரண்டு பக்கங்களிலும் இயேசுவை நடுவிலுமாகச் சிலுவைகளில் அறைந்தார்கள்.
	<br /><br />
	19. பிலாத்து ஒரு மேல்விலாசத்தை எழுதி, சிலுவையின்மேல் போடுவித்தான். அதில் நசரேயனாகிய இயேசு யூதருடைய ராஜா என்று எழுதியிருந்தது.
	<br /><br />
	20. இயேசு சிலுவையில் அறையப்பட்ட இடம் நகரத்திற்குச் சமீபமாயிருந்தபடியினால், யூதரில் அநேகர் அந்த மேல்விலாசத்தை வாசித்தார்கள்; அது எபிரெயு கிரேக்கு லத்தீன் பாஷைகளில் எழுதியிருந்தது.
	<br /><br />
	21. அப்பொழுது யூதருடைய பிரதான ஆசாரியர் பிலாத்துவை நோக்கி: யூதருடைய ராஜா என்று நீர் எழுதாமல், தான் யூதருடைய ராஜா என்று அவன் சொன்னதாக எழுதும் என்றார்கள்.
	<br /><br />
	22. பிலாத்து பிரதியுத்தரமாக: நான் எழுதினது எழுதினதே என்றான்.
	<br /><br />
	23. போர்ச்சேவகர் இயேசுவைச் சிலுவையில் அறைந்தபின்பு, அவருடைய வஸ்திரங்களை எடுத்து, ஒவ்வொரு சேவகனுக்கு ஒவ்வொரு பங்காக நாலு பங்காக்கினார்கள்; அங்கியையும் எடுத்தார்கள், அந்த அங்கி, தையலில்லாமல் மேலே தொடங்கி முழுவதும் நெய்யப்பட்டதாயிருந்தது.
	<br /><br />
	24. அவர்கள்: இதை நாம் கிழியாமல், யாருக்கு வருமோ என்று இதைக்குறித்துச் சீட்டுப்போடுவோம் என்று ஒருவரோடொருவர் பேசிக்கொண்டார்கள். என் வஸ்திரங்களைத் தங்களுக்குள்ளே பங்கிட்டு, என் உடையின்மேல் சீட்டுப்போட்டார்கள் என்கிற வேதவாக்கியம் நிறைவேறத்தக்கதாகப் போர்ச்சேவகர் இப்படிச் செய்தார்கள்.
	<br /><br />
	25. இயேசுவின் சிலுவையினருகே அவருடைய தாயும், அவருடைய தாயின் சகோதரி கிலெயோப்பா மரியாளும், மகதலேனா மரியாளும் நின்றுகொண்டிருந்தார்கள்.
	<br /><br />
	26. அப்பொழுது இயேசு தம்முடைய தாயையும் அருகே நின்ற தமக்கு அன்பாயிருந்த சீஷனையும் கண்டு, தம்முடைய தாயை நோக்கி: ஸ்திரீயே, அதோ, உன் மகன் என்றார்.
	<br /><br />
	27. பின்பு அந்தச் சீஷனை நோக்கி: அதோ, உன் தாய் என்றார். அந்நேரமுதல் அந்தச் சீஷன் அவளைத் தன்னிடமாய் ஏற்றுக்கொண்டான்.
	<br /><br />
	28. அதன்பின்பு, எல்லாம் முடிந்தது என்று இயேசு அறிந்து, வேதவாக்கியம் நிறைவேறத்தக்கதாக: தாகமாயிருக்கிறேன் என்றார்.
	<br /><br />
	29. காடி நிறைந்த பாத்திரம் அங்கே வைக்கப்பட்டிருந்தது; அவர்கள் கடற்காளானைக் காடியிலே தோய்த்து, ஈசோப்புத்தண்டில் மாட்டி, அவர் வாயினிடத்தில் நீட்டிக்கொடுத்தார்கள்.
	<br /><br />
	30. இயேசு காடியை வாங்கினபின்பு, முடிந்தது என்று சொல்லி, தலையைச் சாய்த்து, ஆவியை ஒப்புக்கொடுத்தார்.
	<br /><br />
	31. அந்த நாள் பெரிய ஓய்வுநாளுக்கு ஆயத்தநாளாயிருந்தபடியினால், உடல்கள் அந்த ஓய்வுநாளிலே சிலுவைகளில் இராதபடிக்கு, யூதர்கள் பிலாத்துவினிடத்தில் போய், அவர்களுடைய காலெலும்புகளை முறிக்கும்படிக்கும், உடல்களை எடுத்துப்போடும்படிக்கும் உத்தரவு கேட்டுக்கொண்டார்கள்.
	<br /><br />
	32. அந்தப்படி போர்ச்சேவகர் வந்து, அவருடனேகூடச் சிலுவையில் அறையப்பட்ட முந்தினவனுடைய காலெலும்புகளையும் மற்றவனுடைய காலெலும்புகளையும் முறித்தார்கள்.
	<br /><br />
	33. அவர்கள் இயேசுவினிடத்தில் வந்து, அவர் மரித்திருக்கிறதைக் கண்டு, அவருடைய காலெலும்புகளை முறிக்கவில்லை.
	<br /><br />
	34. ஆகிலும் போர்ச்சேவகரில் ஒருவன் ஈட்டியினாலே அவருடைய விலாவில் குத்தினான்; உடனே இரத்தமும் தண்ணீரும் புறப்பட்டது.
	<br /><br />
	35. அதைக் கண்டவன் சாட்சிகொடுக்கிறான், அவனுடைய சாட்சி மெய்யாயிருக்கிறது; நீங்கள் விசுவாசிக்கும்படி, தான் சொல்லுகிறது மெய்யென்று அவன் அறிந்திருக்கிறான்.
	<br /><br />
	36. அவருடைய எலும்புகளில் ஒன்றும் முறிக்கப்படுவதில்லை என்கிற வேதவாக்கியம் நிறைவேறும்படி இவைகள் நடந்தது.
	<br /><br />
	37. அல்லாமலும் தாங்கள் குத்தினவரை நோக்கிப்பார்ப்பார்கள் என்று வேறொரு வேதவாக்கியம் சொல்லுகிறது.
	<br /><br />
	38. இவைகளுக்குப்பின்பு அரிமத்தியா ஊரானும், யூதருக்குப் பயந்ததினால் இயேசுவுக்கு அந்தரங்க சீஷனுமாகிய யோசேப்பு இயேசுவின் சரீரத்தை எடுத்துக்கொண்டுபோகும்படி பிலாத்துவினிடத்தில் உத்தரவு கேட்டான்; பிலாத்து உத்தரவு கொடுத்தான். ஆகையால் அவன் வந்து, இயேசுவின் சரீரத்தை எடுத்துக்கொண்டுபோனான்.
	<br /><br />
	39. ஆரம்பத்திலே ஒரு இராத்திரியில் இயேசுவினிடத்தில் வந்திருந்த நிக்கொதேமு என்பவன் வெள்ளைப்போளமும் கரியபோளமும் கலந்து ஏறக்குறைய நூறு இராத்தல் கொண்டுவந்தான்.
	<br /><br />
	40. அவர்கள் இயேசுவின் சரீரத்தை எடுத்து, யூதர்கள் அடக்கம்பண்ணும் முறைமையின்படியே அதைச் சுகந்தவர்க்கங்களுடனே சீலைகளில் சுற்றிக் கட்டினார்கள்.
	<br /><br />
	41. அவர் சிலுவையில் அறையப்பட்ட இடத்தில் ஒரு தோட்டமும், அந்தத் தோட்டத்தில் ஒருக்காலும் ஒருவனும் வைக்கப்பட்டிராத ஒரு புதிய கல்லறையும் இருந்தது.
	<br /><br />
	42. யூதருடைய ஆயத்தநாளானபடியினாலும், அந்தக் கல்லறை சமீபமாயிருந்தபடியினாலும், அவ்விடத்திலே இயேசுவை வைத்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John19