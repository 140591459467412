import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoThessalonians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO THESSALONIANS 3 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE THESSALONIANS Chapter 3 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 3 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. கடைசியாக, சகோதரரே, உங்களிடத்தில் கர்த்தருடைய வசனம் பரம்பி மகிமைப்படுகிறதுபோல, எவ்விடத்திலும் பரம்பி மகிமைப்படும்படிக்கும்,
	<br /><br />
	2. துர்க்குணராகிய பொல்லாத மனுஷர் கையினின்று நாங்கள் விடுவிக்கப்படும்படிக்கும், எங்களுக்காக வேண்டிக்கொள்ளுங்கள்; விசுவாசம் எல்லாரிடத்திலுமில்லையே.
	<br /><br />
	3. கர்த்தரோ உண்மையுள்ளவர், அவர் உங்களை ஸ்திரப்படுத்தி, தீமையினின்று விலக்கிக் காத்துக்கொள்ளுவார்.
	<br /><br />
	4. மேலும், நாங்கள் கட்டளையிடுகிறவைகளை நீங்கள் செய்துவருகிறீர்களென்றும், இனிமேலும் செய்வீர்களென்றும், உங்களைக்குறித்துக் கர்த்தருக்குள் நம்பிக்கையாயிருக்கிறோம்.
	<br /><br />
	5. கர்த்தர் உங்கள் இருதயங்களைத் தேவனைப்பற்றும் அன்புக்கும் கிறிஸ்துவின் பொறுமைக்கும் நேராய் நடத்துவாராக.
	<br /><br />
	6. மேலும், சகோதரரே, எங்களிடத்தில் ஏற்றுக்கொண்ட முறைமையின்படி நடவாமல், ஒழுங்கற்று நடக்கிற எந்தச் சகோதரனையும் நீங்கள் விட்டு விலக வேண்டுமென்று, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் நாமத்தினாலே, உங்களுக்குக் கட்டளையிடுகிறோம்.
	<br /><br />
	7. இன்னவிதமாய் எங்களைப் பின்பற்ற வேண்டுமென்று நீங்கள் அறிந்திருக்கிறீர்களே; நாங்கள் உங்களுக்குள்ளே ஒழுங்கற்று நடவாமலும்,
	<br /><br />
	8. ஒருவனிடத்திலும் இலவசமாய்ச் சாப்பிடாமலும், உங்களில் ஒருவனுக்கும் பாரமாயிராதபடிக்கு இரவும் பகலும் பிரயாசத்தோடும் வருத்தத்தோடும் வேலைசெய்து சாப்பிட்டோம்.
	<br /><br />
	9. உங்கள்மேல் பாரத்தை வைப்பதற்கான அதிகாரம் எங்களுக்கு இல்லையென்பதினாலே அப்படிச் செய்யாமல், நீங்கள் எங்களைப் பின்பற்றும்படிக்கு நாங்கள் உங்களுக்கு மாதிரியாயிருக்கவேண்டுமென்றே அப்படிச் செய்தோம்.
	<br /><br />
	10. ஒருவன் வேலைசெய்ய மனதில்லாதிருந்தால் அவன் சாப்பிடவும் கூடாதென்று நாங்கள் உங்களிடத்தில் இருந்தபோது உங்களுக்குக் கட்டளையிட்டோமே.
	<br /><br />
	11. உங்களில் சிலர் யாதொரு வேலையும் செய்யாமல், வீண் அலுவற்காரராய், ஒழுங்கற்றுத் திரிகிறார்களென்று கேள்விப்படுகிறோம்.
	<br /><br />
	12. இப்படிப்பட்டவர்கள் அமைதலோடே வேலைசெய்து, தங்கள் சொந்தச் சாப்பாட்டைச் சாப்பிடவேண்டுமென்று, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினாலே அவர்களுக்குக் கட்டளையிட்டுப் புத்திசொல்லுகிறோம்.
	<br /><br />
	13. சகோதரரே, நீங்கள் நன்மை செய்வதிலே சோர்ந்துபோகாமலிருங்கள்.
	<br /><br />
	14. மேலும், இந்த நிருபத்தில் சொல்லிய எங்கள் வசனத்துக்கு ஒருவன் கீழ்ப்படியாமற்போனால், அவனைக் குறித்துக்கொண்டு, அவன் வெட்கப்படும்படிக்கு அவனுடனே கலவாதிருங்கள்.
	<br /><br />
	15. ஆனாலும் அவனைச் சத்துருவாக எண்ணாமல், சகோதரனாக எண்ணி, அவனுக்குப் புத்திசொல்லுங்கள்.
	<br /><br />
	16. சமாதானத்தின் கர்த்தர்தாமே எப்பொழுதும் சகலவிதத்திலும் உங்களுக்குச் சமாதானத்தைத் தந்தருளுவாராக. கர்த்தர் உங்களனைவரோடுங்கூட இருப்பாராக.
	<br /><br />
	17. பவுலாகிய நான் என் கையெழுத்தாலே உங்களை வாழ்த்துகிறேன், நிருபங்கள்தோறும் இதுவே அடையாளம்; இப்படியே எழுதுகிறேன்.
	<br /><br />
	18. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் கிருபை உங்களனைவரோடுங்கூட இருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoThessalonians3