import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah50 = () => {
  const verseNumber = 50;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 50 </title>
	<meta
          name="description"
          content="Isaiah 50 | ஏசாயா 50 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொல்லுகிறார்: நான் உங்கள் தாயை அனுப்பிவிட்டபோது, அவளுக்குக் கொடுத்த தள்ளுதற்சீட்டு எங்கே? அல்லது எனக்குக் கடன்கொடுத்த எவனுக்கு உங்களை நான் விற்றுப்போட்டேன்? இதோ, உங்கள் அக்கிரமங்களினிமித்தம் நீங்கள் விற்கப்பட்டீர்கள்; உங்கள் பாதகங்களினிமித்தம் உங்கள் தாய் அனுப்பிவிடப்பட்டாள்.
	<br /><br />
	2. நான் வந்தபோது ஒருவனும் இல்லாமற்போனதென்ன? நான் கூப்பிட்டபோது மறுஉத்தரவு கொடுக்க ஒருவனும் இல்லாமற்போனதென்ன? மீட்கக்கூடாதபடிக்கு என் கரம் குறுகிற்றோ? விடுவிக்கிறதற்கு என்னிடத்தில் பெலனில்லாமற்போயிற்றோ? இதோ, என் கண்டிதத்தினாலே கடலை வற்றப்பண்ணி, நதிகளை வெட்டாந்தரையாக்கிப்போடுகிறேன்; அவைகளிலுள்ள மீன் தண்ணீரில்லாமல் தாகத்தால் செத்து நாறுகின்றது.
	<br /><br />
	3. நான் வானங்களுக்குக் காரிருளை உடுத்தி, இரட்டை அவைகளின் மூடுசீலையாக்குகிறேன்.
	<br /><br />
	4. இளைப்படைந்தவனுக்கு சமயத்திற்கேற்ற வார்த்தை சொல்ல நான் அறியும்படிக்கு, கர்த்தராகிய ஆண்டவர் எனக்குக் கல்விமானின் நாவைத் தந்தருளினார்; காலைதோறும் என்னை எழுப்புகிறார்; கற்றுக்கொள்ளுகிறவர்களைப்போல, நான் கேட்கும்படி என் செவியைக் கவனிக்கச்செய்கிறார்.
	<br /><br />
	5. கர்த்தராகிய ஆண்டவர் என்செவியைத் திறந்தார்; நான் எதிர்க்கவுமில்லை, நான் பின்வாங்கவுமில்லை.
	<br /><br />
	6. அடிக்கிறவர்களுக்கு என் முதுகையும், தாடைமயிரைப் பிடுங்குகிறவர்களுக்கு என் தாடைகளையும் ஒப்புக்கொடுத்தேன்; அவமானத்துக்கும் உமிழ்நீருக்கும் என் முகத்தை மறைக்கவில்லை.
	<br /><br />
	7. கர்த்தராகிய ஆண்டவர் எனக்குத் துணைசெய்கிறார்; ஆகையால் நான் வெட்கப்படேன்; நான் வெட்கப்பட்டுப்போவதில்லையென்று அறிந்திருக்கிறேன்; ஆதலால் என் முகத்தைக் கற்பாறையைப்போலாக்கினேன்.
	<br /><br />
	8. என்னை நீதிமானாக்குகிறவர் சமீபமாயிருக்கிறார்; என்னோடே வழக்காடுகிறவன் யார்? ஏகமாய் நிற்போமாக, யார் எனக்கு எதிராளி? அவன் என்னிடத்தில் வரட்டும்.
	<br /><br />
	9. இதோ, கர்த்தராகிய ஆண்டவர் எனக்குத் துணைசெய்கிறார்; என்னை ஆக்கினைக்குள்ளாகத் தீர்க்கிறவன் யார்? இதோ, அவர்கள் எல்லாரும் ஒரு வஸ்திரத்தைப்போலப் பழசாவார்கள்; பொட்டுப்பூச்சி அவர்களை அரிக்கும்.
	<br /><br />
	10. உங்களில் எவன் கர்த்தருக்குப் பயந்து, அவருடைய தாசனின் சொல்லைக் கேட்டு, தனக்கு வெளிச்சமில்லாததினால் இருட்டிலே நடக்கிறானோ, அவன் கர்த்தருடைய நாமத்தை நம்பி, தன் தேவனைச் சார்ந்துகொள்ளக்கடவன்.
	<br /><br />
	11. இதோ, நெருப்பைக் கொளுத்தி, அக்கினிப்பொறிகளால் சூழப்பட்டிருக்கிற நீங்கள் அனைவரும், உங்கள் அக்கினி தீபத்திலும், நீங்கள் மூட்டின அக்கினிஜுவாலையிலும் நடவுங்கள்; வேதனையில் கிடப்பீர்கள்; என் கரத்தினால் இது உங்களுக்கு உண்டாகும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah50