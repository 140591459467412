import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah33 = () => {
  const verseNumber = 33;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 33 </title>
	<meta
          name="description"
          content="Isaiah 33 | ஏசாயா 33 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கொள்ளையிடப்படாதிருந்தும், கொள்ளையிடுகிறவனும், துரோகம்பண்ணாதிருக்கிறவர்களுக்குத் துரோகம்பண்ணுகிறவனுமாகிய உனக்கு ஐயோ! நீ கொள்ளையிட்டு முடிந்தபின்பு கொள்ளையிடப்படுவாய்; நீ துரோகம்பண்ணித் தீர்ந்தபின்பு உனக்குத் துரோகம்பண்ணுவார்கள்.
	<br /><br />
	2. கர்த்தாவே, எங்களுக்கு இரங்கும், உமக்குக் காத்திருக்கிறோம்; தேவரீர் காலையில் அவர்கள் புயமும், இக்கட்டுக்காலத்தில் எங்கள் இரட்சிப்புமாயிரும்.
	<br /><br />
	3. அமளியின் சத்தத்தினாலே ஜனங்கள் அலைந்தோடி, நீர் எழுந்திருக்கும்போது ஜாதிகள் சிதறடிக்கப்படுவார்கள்.
	<br /><br />
	4. வெட்டுக்கிளிகள் சேர்க்கிறதுபோல உங்கள் கொள்ளை சேர்க்கப்படும்; வெட்டுக்கிளிகள் குதித்துத் திரிகிறதுபோல மனுஷர் அதின்மேல் குதித்துத் திரிவார்கள்.
	<br /><br />
	5. கர்த்தர் உயர்ந்தவர், அவர் உன்னதத்தில் வாசமாயிருக்கிறார்; அவர் சீயோனை நியாயத்தினாலும் நீதியினாலும் நிரப்புகிறார்.
	<br /><br />
	6. பூரணரட்சிப்பும் ஞானமும் அறிவும் உன் காலங்களுடைய உறுதியாயிருக்கும்; கர்த்தருக்குப் பயப்படுதலே அதின் பொக்கிஷம்.
	<br /><br />
	7. இதோ, அவர்களுடைய பராக்கிரமசாலிகள் வெளியிலே அலறுகிறார்கள்; சமாதானத்து ஸ்தானாபதிகள் மனங்கசந்து அழுகிறார்கள்.
	<br /><br />
	8. பாதைகள் பாழாயின; வழிப்போக்கர் இல்லை; உடன்படிக்கையை மீறுகிறான்; நகரங்களை இகழ்ச்சிபண்ணுகிறான்; மனுஷனை எண்ணாதேபோகிறான்.
	<br /><br />
	9. தேசம் துக்கித்து விடாய்த்திருக்கிறது; லீபனோன் வெட்கி வாடுகிறது; சாரோன் வனாந்தரத்துக்கு ஒப்பாகிறது; பாசானும் கர்மேலும் பாழாக்கப்படுகிறது.
	<br /><br />
	10. இப்பொழுது எழுந்தருளுவேன், இப்பொழுது உயருவேன், இப்பொழுது மேன்மைப்படுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. பதரைக் கர்ப்பந்தரித்துத் தாளடியைப் பெறுவீர்கள்; அக்கினியைப்போல் உங்கள் சுவாசமே உங்களைப் பட்சிக்கும்.
	<br /><br />
	12. ஜனங்கள் சுண்ணாம்பைப்போல நீற்றப்படுவார்கள்; வெட்டப்பட்ட முட்செடிகளைப்போலத் தீயில் எரிக்கப்படுவார்கள்.
	<br /><br />
	13. தூரத்திலுள்ளவர்களே, நான் செய்கிறதைக் கேளுங்கள்; சமீபத்திலிருக்கிறவர்களே, என் பராக்கிரமத்தை அறிந்துகொள்ளுங்கள் என்கிறார்.
	<br /><br />
	14. சீயோனிலுள்ள பாவிகள் திகைக்கிறார்கள்; மாயக்காரரை நடுக்கம் பிடிக்கிறது; பட்சிக்கும் அக்கினிக்கு முன்பாக நம்மில் தரித்திருப்பவன் யார்? நித்தியஜுவாலைக்கு முன்பாக நம்மில் தாபரிப்பவன் யார் என்கிறார்கள்.
	<br /><br />
	15. நீதியாய் நடந்து, செம்மையானவைகளைப் பேசி, இடுக்கண் செய்வதால் வரும் ஆதாயத்தை வெறுத்து, பரிதானங்களை வாங்காதபடிக்குத் தன் கைகளை உதறி, இரத்தஞ்சிந்துவதற்கான யோசனைகளைக் கேளாதபடிக்குத் தன் செவியை அடைத்து, பொல்லாப்பைக் காணாதபடிக்குத் தன் கண்களை மூடுகிறவனெவனோ,
	<br /><br />
	16. அவன் உயர்ந்த இடங்களில் வாசம்பண்ணுவான்; கன்மலைகளின் அரண்கள் அவனுடைய உயர்ந்த அடைக்கலமாகும்; அவன் அப்பம் அவனுக்குக் கொடுக்கப்படும்; அவன் தண்ணீர் அவனுக்கு நிச்சயமாய்க் கிடைக்கும்.
	<br /><br />
	17. உன் கண்கள் ராஜாவை மகிமை பொருந்தினவராகக் காணும், தூரத்திலுள்ள தேசத்தையும் பார்க்கும்.
	<br /><br />
	18. உன் மனம் பயங்கரத்தை நினைவுகூரும்; கணக்கன் எங்கே? தண்டல்காரன் எங்கே? கோபுரங்களை எண்ணினவன் எங்கே?
	<br /><br />
	19. உனக்கு விளங்காத பாஷையையும், அறிதற்கரிய ஒருவிதமான பேச்சையுமுடைய அந்தக் குரூர ஜனங்களை இனி நீ காணாய்.
	<br /><br />
	20. நம்முடைய பண்டிகைகள் ஆசரிக்கப்படும் நகரமாகிய சீயோனை நோக்கிப்பார்; உன் கண்கள் எருசலேமை அமரிக்கையான தாபரமாகவும், பெயர்க்கப்படாத கூடாரமாகவும் காணும்; இனி அதின் முளைகள் என்றைக்கும் பிடுங்கப்படுவதுமில்லை, அதின் கயிறுகளில் ஒன்றும் அறுந்து போவதுமில்லை.
	<br /><br />
	21. மகிமையுள்ள கர்த்தர் அங்கே நமக்கு மகா விசாலமான நதிகளும் ஆறுகளுமுள்ள ஸ்தலம்போலிருப்பார்; வலிக்கிற படவு அங்கே ஓடுவதும் இல்லை; பெரிய கப்பல் அங்கே கடந்துவருவதும் இல்லை.
	<br /><br />
	22. கர்த்தர் நம்முடைய நியாயாதிபதி, கர்த்தர் நம்முடைய நியாயப்பிரமாணிகர், கர்த்தர் நம்முடைய ராஜா, அவர் நம்மை இரட்சிப்பார்.
	<br /><br />
	23. உன் கயிறுகள் தளர்ந்துபோம்; பாய்மரத்தைக் கெட்டிப்படுத்தவும், பாயை விரிக்கவுங் கூடாமற்போம்; அப்பொழுது திரளான கொள்ளைப்பொருள் பங்கிடப்படும்; சப்பாணிகளும் கொள்ளையாடுவார்கள்.
	<br /><br />
	24. வியாதிப்பட்டிருக்கிறேன் என்று நகரவாசிகள் சொல்வதில்லை; அதில் வாசமாயிருக்கிற ஜனத்தின் அக்கிரமம் மன்னிக்கப்பட்டிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah33