import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 15 </title>
	<meta
          name="description"
          content="John Chapter 15 | யோவான் அதிகாரம் 15 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் மெய்யான திராட்சச்செடி, என் பிதா திராட்சத்தோட்டக்காரர்.
	<br /><br />
	2. என்னில் கனிகொடாதிருக்கிற கொடி எதுவோ அதை அவர் அறுத்துப்போடுகிறார்; கனிகொடுக்கிற கொடி எதுவோ, அது அதிக கனிகளைக் கொடுக்கும்படி, அதைச் சுத்தம்பண்ணுகிறார்.
	<br /><br />
	3. நான் உங்களுக்குச் சொன்ன உபதேசத்தினாலே நீங்கள் இப்பொழுதே சுத்தமாயிருக்கிறீர்கள்.
	<br /><br />
	4. என்னில் நிலைத்திருங்கள், நானும் உங்களில் நிலைத்திருப்பேன்; கொடியானது திராட்சச்செடியில் நிலைத்திராவிட்டால் அது தானாய்க் கனிகொடுக்க மாட்டாதது போல, நீங்களும் என்னில் நிலைத்திராவிட்டால், கனிகொடுக்கமாட்டீர்கள்.
	<br /><br />
	5. நானே திராட்சச்செடி, நீங்கள் கொடிகள். ஒருவன் என்னிலும் நான் அவனிலும் நிலைத்திருந்தால், அவன் மிகுந்த கனிகளைக் கொடுப்பான்; என்னையல்லாமல் உங்களால் ஒன்றும் செய்யக்கூடாது.
	<br /><br />
	6. ஒருவன் என்னில் நிலைத்திராவிட்டால், வெளியே எறியுண்ட கொடியைப்போல அவன் எறியுண்டு உலர்ந்து போவான்; அப்படிப்பட்டவைகளைச் சேர்த்து, அக்கினியிலே போடுகிறார்கள்; அவைகள் எரிந்துபோம்.
	<br /><br />
	7. நீங்கள் என்னிலும், என் வார்த்தைகள் உங்களிலும் நிலைத்திருந்தால், நீங்கள் கேட்டுக்கொள்வதெதுவோ அது உங்களுக்குச் செய்யப்படும்.
	<br /><br />
	8. நீங்கள் மிகுந்த கனிகளைக் கொடுப்பதினால் என் பிதா மகிமைப்படுவார், எனக்கும் சீஷராயிருப்பீர்கள்.
	<br /><br />
	9. பிதா என்னில் அன்பாயிருக்கிறதுபோல நானும் உங்களில் அன்பாயிருக்கிறேன்; என்னுடைய அன்பிலே நிலைத்திருங்கள்.
	<br /><br />
	10. நான் என் பிதாவின் கற்பனைகளைக் கைக்கொண்டு அவருடைய அன்பிலே நிலைத்திருக்கிறதுபோல, நீங்களும் என் கற்பனைகளைக் கைக்கொண்டிருந்தால், என்னுடைய அன்பிலே நிலைத்திருப்பீர்கள்.
	<br /><br />
	11. என்னுடைய சந்தோஷம் உங்களில் நிலைத்திருக்கும்படிக்கும், உங்கள் சந்தோஷம் நிறைவாயிருக்கும்படிக்கும், இவைகளை உங்களுக்குச் சொன்னேன்.
	<br /><br />
	12. நான் உங்களில் அன்பாயிருக்கிறதுபோல நீங்களும் ஒருவரிலொருவர் அன்பாயிருக்க வேண்டுமென்பதே என்னுடைய கற்பனையாயிருக்கிறது.
	<br /><br />
	13. ஒருவன் தன் சிநேகிதருக்காகத் தன் ஜீவனைக் கொடுக்கிற அன்பிலும் அதிகமான அன்பு ஒருவரிடத்திலுமில்லை.
	<br /><br />
	14. நான் உங்களுக்குக் கற்பிக்கிற யாவையும் நீங்கள் செய்வீர்களானால், என் சிநேகிதராயிருப்பீர்கள்.
	<br /><br />
	15. இனி நான் உங்களை ஊழியக்காரரென்று சொல்லுகிறதில்லை, ஊழியக்காரன் தன் எஜமான் செய்கிறதை அறியமாட்டான். நான் உங்களைச் சிநேகிதர் என்றேன், ஏனெனில் என் பிதாவினிடத்தில் நான் கேள்விப்பட்ட எல்லாவற்றையும் உங்களுக்கு அறிவித்தேன்.
	<br /><br />
	16. நீங்கள் என்னைத் தெரிந்துகொள்ளவில்லை, நான் உங்களைத் தெரிந்துகொண்டேன்; நீங்கள் என் நாமத்தினாலே பிதாவைக் கேட்டுக்கொள்வது எதுவோ, அதை அவர் உங்களுக்குக் கொடுக்கத்தக்கதாக நீங்கள் போய்க் கனிகொடுக்கும்படிக்கும், உங்கள் கனி நிலைத்திருக்கும்படிக்கும், நான் உங்களை ஏற்படுத்தினேன்.
	<br /><br />
	17. நீங்கள் ஒருவரிலொருவர் அன்பாயிருக்கவேண்டுமென்றே இவைகளை உங்களுக்குக் கற்பிக்கிறேன்.
	<br /><br />
	18. உலகம் உங்களைப் பகைத்தால், அது உங்களைப் பகைக்கிறதற்குமுன்னே என்னைப் பகைத்ததென்று அறியுங்கள்.
	<br /><br />
	19. நீங்கள் உலகத்தாராயிருந்தால், உலகம் தன்னுடையதைச் சிநேகித்திருக்கும்; நீங்கள் உலகத்தாராயிராதபடியினாலும், நான் உங்களை உலகத்திலிருந்து தெரிந்துகொண்டபடியினாலும், உலகம் உங்களைப் பகைக்கிறது.
	<br /><br />
	20. ஊழியக்காரன் தன் எஜமானிலும் பெரியவனல்லவென்று நான் உங்களுக்குச் சொன்ன வார்த்தையை நினைத்துக்கொள்ளுங்கள். அவர்கள் என்னைத் துன்பப்படுத்தினதுண்டானால், உங்களையும் துன்பப்படுத்துவார்கள்; அவர்கள் என் வசனத்தைக் கைக்கொண்டதுண்டானால், உங்கள் வசனத்தையும் கைக்கொள்ளுவார்கள்.
	<br /><br />
	21. அவர்கள் என்னை அனுப்பினவரை அறியாதபடியினால் என் நாமத்தினிமித்தமே இவைகளையெல்லாம் உங்களுக்குச் செய்வார்கள்.
	<br /><br />
	22. நான் வந்து அவர்களிடத்தில் பேசாதிருந்தேனானால் அவர்களுக்குப் பாவமிராது; இப்பொழுதோ தங்கள் பாவத்தைக்குறித்துப் போக்குச்சொல்ல அவர்களுக்கு இடமில்லை.
	<br /><br />
	23. என்னைப் பகைக்கிறவன் என் பிதாவையும் பகைக்கிறான்.
	<br /><br />
	24. வேறொருவரும் செய்யாத கிரியைகளை நான் அவர்களுக்குள்ளே செய்யாதிருந்தேனானால், அவர்களுக்குப் பாவமிராது; இப்பொழுது அவர்கள் என்னையும் என் பிதாவையும் கண்டும் பகைத்துமிருக்கிறார்கள்.
	<br /><br />
	25. முகாந்தரமில்லாமல் என்னைப் பகைத்தார்கள் என்று அவர்களுடைய வேதத்தில் எழுதியிருக்கிற வாக்கியம் நிறைவேறும்படிக்கு இப்படியாயிற்று.
	<br /><br />
	26. பிதாவினிடத்திலிருந்து நான் உங்களுக்கு அனுப்பப்போகிறவரும், பிதாவினிடத்திலிருந்து புறப்படுகிறவருமாகிய சத்திய ஆவியான தேற்றரவாளன் வரும்போது, அவர் என்னைக்குறித்துச் சாட்சி கொடுப்பார்.
	<br /><br />
	27. நீங்களும் ஆதிமுதல் என்னுடனேகூட இருந்தபடியால் எனக்குச் சாட்சிகளாயிருப்பீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John15