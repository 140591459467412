import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 10 </title>
	<meta
          name="description"
          content="Zechariah 10 | சகரியா 10 | Sageria 10 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்மாரிகாலத்து மழையைக் கர்த்தரிடத்தில் வேண்டிக்கொள்ளுங்கள்; அப்பொழுது கர்த்தர் மின்னல்களை உண்டாக்கி, வயல்வெளியில் அவரவருக்குப் பயிருண்டாக அவர்களுக்கு மழையைக் கட்டளையிடுவார்.
	<br /><br />
	2. சுரூபங்கள் அபத்தமானதைச் சொல்லிற்று; குறிசொல்லுகிறவர்கள் பொய்யைத் தரித்தார்கள்; சொப்பனக்காரர் வீணானதைச் சொல்லி, வியர்த்தமாகத் தேற்றரவுபண்ணினார்கள்; ஆகையால் ஜனங்கள் ஆடுகளைப்போலச் சிதறி, மேய்ப்பனில்லாததினால் சிறுமைப்பட்டார்கள்.
	<br /><br />
	3. மேய்ப்பருக்கு விரோதமாக என் கோபம் மூண்டது, கடாக்களைத் தண்டித்தேன்; சேனைகளின் கர்த்தர் யூதா வம்சத்தாராகிய தமது மந்தையை விசாரித்து, அவர்களை யுத்தத்திலே தமது சிறந்த குதிரையாக நிறுத்துவார்.
	<br /><br />
	4. அவர்களிலிருந்து கோடிக்கல்லும், அவர்களிலிருந்து கூடாரமுளையும், அவர்களிலிருந்து யுத்தவில்லும் வரும்; அவர்களிலிருந்து ஆளுகிற யாவரும் ஏகமாய்ப் புறப்படுவார்கள்.
	<br /><br />
	5. அவர்கள் யுத்தத்திலே தங்கள் சத்துருக்களை வீதிகளின் சேற்றில் மிதிக்கிற பராக்கிரமசாலிகளைப்போல இருந்து யுத்தம்பண்ணுவார்கள்; கர்த்தர் அவர்களோடேகூட இருப்பார்; குதிரைகளின்மேல் ஏறிவருகிறவர்கள் வெட்கப்படுவார்கள்.
	<br /><br />
	6. நான் யூதா வம்சத்தாரைப் பலப்படுத்தி, யோசேப்பு வம்சத்தாரை இரட்சித்து, அவர்களைத் திரும்ப நிலைக்கப்பண்ணுவேன்; நான் அவர்களுக்கு இரங்கினேன்; அவர்கள் என்னால் ஒருக்காலும் தள்ளிவிடப்படாதவர்களைப்போல் இருப்பார்கள்; நான் அவர்களுடைய தேவனாகிய கர்த்தர், நான் அவர்களுக்குச் செவிகொடுப்பேன்.
	<br /><br />
	7. எப்பிராயீமர் பராக்கிரமரைப்போல இருப்பார்கள்; மதுபானத்தால் களிப்பதுபோல, அவர்களுடைய இருதயம் களிக்கும்; அவர்களுடைய பிள்ளைகளும் அதைக் கண்டு மகிழுவார்கள்; அவர்கள் இருதயம் கர்த்தருக்குள் களிகூரும்.
	<br /><br />
	8. நான் அவர்களைப் பார்த்துப் பயில்போட்டு அவர்களைக் கூட்டிக்கொள்ளுவேன்; அவர்களை மீட்டுக்கொண்டேன்; அவர்கள் பெருகியிருந்ததுபோலவே பெருகிப்போவார்கள்.
	<br /><br />
	9. நான் அவர்களை ஜனங்களுக்குள்ளே இறைத்தபின்பு, அவர்கள் தூரதேசங்களிலே என்னை நினைத்து தங்கள் பிள்ளைகளோடுங்கூடப் பிழைத்துத் திரும்புவார்கள்.
	<br /><br />
	10. நான் அவர்களை எகிப்துதேசத்திலிருந்து திரும்பிவரப்பண்ணி, அவர்களை அசீரியாவிலிருந்து கூட்டிக்கொண்டு, அவர்களைக் கீலேயாத் தேசத்துக்கும் லீபனோனுக்கும் வரப்பண்ணுவேன்; அவர்களுக்கு இடம் போதாமற்போகும்.
	<br /><br />
	11. இடுக்கமென்கிற சமுத்திரத்தைக் கடக்கையில் அவர் சமுத்திரத்தின் அலைகளை அடிப்பார்; அப்பொழுது நதியின் ஆழங்கள் எல்லாம் வறண்டுபோகும்; அசீரியாவின் கர்வம் தாழ்த்தப்படும், எகிப்தின் கொடுங்கோல் விலகிப்போகும்.
	<br /><br />
	12. நான் அவர்களைக் கர்த்தருக்குள் பலப்படுத்துவேன்; அவர்கள் அவருடைய நாமத்திலே நடந்துகொள்ளுவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah10