import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 8 </title>
	<meta
          name="description"
          content="John Chapter 8 | யோவான் அதிகாரம் 8 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு ஒலிவமலைக்குப் போனார்.
	<br /><br />
	2. மறுநாள் காலையிலே அவர் திரும்பித் தேவாலயத்திற்கு வந்தபோது, ஜனங்களெல்லாரும் அவரிடத்தில் வந்தார்கள். அவர் உட்கார்ந்து அவர்களுக்கு உபதேசம்பண்ணினார்.
	<br /><br />
	3. அப்பொழுது விபசாரத்திலே கண்டுபிடிக்கப்பட்ட ஒரு ஸ்திரீயை வேதபாரகரும் பரிசேயரும் அவரிடத்தில் கொண்டுவந்து, அவளை நடுவே நிறுத்தி:
	<br /><br />
	4. போதகரே, இந்த ஸ்திரீ விபசாரத்தில் கையும் மெய்யுமாய்ப் பிடிக்கப்பட்டாள்.
	<br /><br />
	5. இப்படிப்பட்டவர்களைக் கல்லெறிந்து கொல்லவேண்டுமென்று மோசே நியாயப்பிரமாணத்தில் நமக்குக் கட்டளையிட்டிருக்கிறாரே, நீர் என்ன சொல்லுகிறீர் என்றார்கள்.
	<br /><br />
	6. அவர்மேல் குற்றஞ்சுமத்துவதற்கான காரணம் உண்டாகும்பொருட்டு அவரைச் சோதிக்கும்படி இப்படிச் சொன்னார்கள். இயேசுவோ குனிந்து, விரலினால் தரையிலே எழுதினார்.
	<br /><br />
	7. அவர்கள் ஓயாமல் அவரைக் கேட்டுக்கொண்டிருக்கையில், அவர் நிமிர்ந்து பார்த்து: உங்களில் பாவமில்லாதவன் இவள்மேல் முதலாவது கல்லெறியக்கடவன் என்று சொல்லி,
	<br /><br />
	8. அவர் மறுபடியும் குனிந்து, தரையிலே எழுதினார்.
	<br /><br />
	9. அவர்கள் அதைக் கேட்டு, தங்கள் மனச்சாட்சியினால் கடிந்துகொள்ளப்பட்டு, பெரியோர்முதல் சிறியோர்வரைக்கும் ஒவ்வொருவராய்ப் போய்விட்டார்கள். இயேசு தனித்திருந்தார், அந்த ஸ்திரீ நடுவே நின்றாள்.
	<br /><br />
	10. இயேசு நிமிர்ந்து அந்த ஸ்திரீயைத் தவிர வேறொருவரையுங் காணாமல்: ஸ்திரீயே, உன்மேல் குற்றஞ்சாட்டினவர்கள் எங்கே? ஒருவனாகிலும் உன்னை ஆக்கினைக்குள்ளாகத் தீர்க்கவில்லையா என்றார்.
	<br /><br />
	11. அதற்கு அவள்: இல்லை, ஆண்டவரே, என்றாள். இயேசு அவளை நோக்கி: நானும் உன்னை ஆக்கினைக்குள்ளாகத் தீர்க்கிறதில்லை; நீ போ, இனிப் பாவஞ்செய்யாதே என்றார்.
	<br /><br />
	12. மறுபடியும் இயேசு ஜனங்களை நோக்கி: நான் உலகத்திற்கு ஒளியாயிருக்கிறேன், என்னைப் பின்பற்றுகிறவன் இருளிலே நடவாமல் ஜீவஒளியை அடைந்திருப்பான் என்றார்.
	<br /><br />
	13. அப்பொழுது பரிசேயர் அவரை நோக்கி: உன்னைக்குறித்து நீயே சாட்சி கொடுக்கிறாய்; உன்னுடைய சாட்சி உண்மையானதல்ல என்றார்கள்.
	<br /><br />
	14. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: என்னைக்குறித்து நானே சாட்சி கொடுத்தாலும், என் சாட்சி உண்மையாயிருக்கிறது; ஏனெனில் நான் எங்கேயிருந்து வந்தேனென்றும், எங்கே போகிறேனென்றும் அறிந்திருக்கிறேன்; நீங்களோ நான் எங்கேயிருந்து வருகிறேனென்றும், எங்கே போகிறேனென்றும் அறியீர்கள்.
	<br /><br />
	15. நீங்கள் மாம்சத்துக்கேற்றபடி நியாயந்தீர்க்கிறீர்கள், நான் ஒருவனையும் நியாயந்தீர்க்கிறதில்லை;
	<br /><br />
	16. நான் நியாயந்தீர்த்தால், என் தீர்ப்பு சத்தியத்தின்படியிருக்கும்; ஏனெனில் நான் தனித்திருக்கவில்லை, நானும் என்னை அனுப்பின பிதாவுமாக இருக்கிறோம்.
	<br /><br />
	17. இரண்டுபேருடைய சாட்சி உண்மையென்று உங்கள் நியாயப்பிரமாணத்திலும் எழுதியிருக்கிறதே.
	<br /><br />
	18. நான் என்னைக்குறித்துச் சாட்சி கொடுக்கிறவனாயிருக்கிறேன், என்னை அனுப்பின பிதாவும் என்னைக்குறித்துச் சாட்சி கொடுக்கிறார் என்றார்.
	<br /><br />
	19. அப்பொழுது அவர்கள்: உம்முடைய பிதா எங்கே என்றார்கள். இயேசு பிரதியுத்தரமாக: என்னையும் அறியீர்கள், என் பிதாவையும் அறியீர்கள்; நீங்கள் என்னை அறிந்தீர்களானால் என் பிதாவையும் அறிவீர்கள் என்றார்.
	<br /><br />
	20. தேவாலயத்திலே இயேசு உபதேசம்பண்ணுகிறபோது, தருமப்பெட்டியிருக்கும் இடத்தில் இந்த வசனங்களைச் சொன்னார். அவருடைய வேளை இன்னும் வராதபடியினால் ஒருவனும் அவரைப் பிடிக்கவில்லை.
	<br /><br />
	21. இயேசு மறுபடியும் அவர்களை நோக்கி: நான் போகிறேன், நீங்கள் என்னைத் தேடி உங்கள் பாவங்களிலே சாவீர்கள்; நான் போகிற இடத்துக்கு வர உங்களால் கூடாது என்றார்.
	<br /><br />
	22. அப்பொழுது யூதர்கள்: நான் போகிற இடத்துக்கு வர உங்களால் கூடாது என்கிறானே, தன்னைத்தான் கொலைசெய்துகொள்வானோ என்று பேசிக்கொண்டார்கள்.
	<br /><br />
	23. அவர் அவர்களை நோக்கி: நீங்கள் தாழ்விலிருந்துண்டானவர்கள், நான் உயர்விலிருந்துண்டானவன்; நீங்கள் இந்த உலகத்திலிருந்துண்டானவர்கள், நான் இந்த உலகத்திலிருந்துண்டானவனல்ல.
	<br /><br />
	24. ஆகையால் நீங்கள் உங்கள் பாவங்களில் சாவீர்கள் என்று உங்களுக்குச் சொன்னேன்; நானே அவர் என்று நீங்கள் விசுவாசியாவிட்டால் உங்கள் பாவங்களிலே சாவீர்கள் என்றார்.
	<br /><br />
	25. அதற்கு அவர்கள்: நீர் யார் என்றார்கள். இயேசு அவர்களை நோக்கி: நான் ஆதிமுதலாய் உங்களுக்குச் சொல்லியிருக்கிறவர்தான்.
	<br /><br />
	26. உங்களைக்குறித்துப் பேசவும் நியாயந்தீர்க்கவும் எனக்கு அநேக காரியங்களுண்டு. என்னை அனுப்பினவர் சத்தியமுள்ளவர்; நான் அவரிடத்தில் கேட்டவைகளையே உலகத்துக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	27. பிதாவைக்குறித்துப் பேசினாரென்று அவர்கள் அறியாதிருந்தார்கள்.
	<br /><br />
	28. ஆதலால் இயேசு அவர்களை நோக்கி: நீங்கள் மனுஷகுமாரனை உயர்த்தின பின்பு, நானே அவரென்றும், நான் என் சுயமாய் ஒன்றும் செய்யாமல், என் பிதா எனக்குப் போதித்தபடியே இவைகளைச் சொன்னேன் என்றும் அறிவீர்கள்.
	<br /><br />
	29. என்னை அனுப்பினவர் என்னுடனேகூட இருக்கிறார், பிதாவுக்குப் பிரியமானவைகளை நான் எப்பொழுதும் செய்கிறபடியால் அவர் என்னைத் தனியே இருக்கவிடவில்லை என்றார்.
	<br /><br />
	30. இவைகளை அவர் சொல்லுகையில், அநேகர் அவரிடத்தில் விசுவாசம் வைத்தார்கள்.
	<br /><br />
	31. இயேசு தம்மை விசுவாசித்த யூதர்களை நோக்கி: நீங்கள் என் உபதேசத்தில் நிலைத்திருந்தால் மெய்யாகவே என் சீஷராயிருப்பீர்கள்;
	<br /><br />
	32. சத்தியத்தையும் அறிவீர்கள், சத்தியம் உங்களை விடுதலையாக்கும் என்றார்.
	<br /><br />
	33. அவர்கள் அவருக்குப் பிரதியுத்தரமாக: நாங்கள் ஆபிரகாமின் சந்ததியாயிருக்கிறோம், நாங்கள் ஒருக்காலும் ஒருவனுக்கும் அடிமைகளாயிருக்கவில்லை; விடுதலையாவீர்களென்று நீர் எப்படிச் சொல்லுகிறீர் என்றார்கள்.
	<br /><br />
	34. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: பாவஞ்செய்கிறவன் எவனும் பாவத்துக்கு அடிமையாயிருக்கிறான் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	35. அடிமையானவன் என்றைக்கும் வீட்டிலே நிலைத்திரான்; குமாரன் என்றைக்கும் நிலைத்திருக்கிறார்.
	<br /><br />
	36. ஆகையால் குமாரன் உங்களை விடுதலையாக்கினால் மெய்யாகவே விடுதலையாவீர்கள்.
	<br /><br />
	37. நீங்கள் ஆபிரகாமின் சந்ததியாரென்று அறிவேன்; ஆனாலும் உங்களுக்குள்ளே என் உபதேசம் இடம்பெறாதபடியால், என்னைக் கொலைசெய்யத் தேடுகிறீர்கள்.
	<br /><br />
	38. நான் என் பிதாவினிடத்தில் கண்டதைச் சொல்லுகிறேன், நீங்களும் உங்கள் பிதாவினிடத்தில் கண்டதைச் செய்கிறீர்கள் என்றார்.
	<br /><br />
	39. அதற்கு அவர்கள்: ஆபிரகாமே எங்கள் பிதா என்றார்கள். இயேசு அவர்களை நோக்கி: நீங்கள் ஆபிரகாமின் பிள்ளைகளாயிருந்தால் ஆபிரகாமின் கிரியைகளைச் செய்வீர்களே.
	<br /><br />
	40. தேவனிடத்தில் கேட்டிருக்கிற சத்தியத்தை உங்களுக்குச் சொன்ன மனுஷனாகிய என்னைக் கொல்லத் தேடுகிறீர்கள், ஆபிரகாம் இப்படிச் செய்யவில்லையே.
	<br /><br />
	41. நீங்கள் உங்கள் பிதாவின் கிரியைகளைச் செய்கிறீர்கள் என்றார். அதற்கு அவர்கள்: நாங்கள் வேசித்தனத்தினால் பிறந்தவர்களல்ல; ஒரே பிதா எங்களுக்குண்டு, அவர் தேவன் என்றார்கள்.
	<br /><br />
	42. இயேசு அவர்களை நோக்கி: தேவன் உங்கள் பிதாவாயிருந்தால் என்னிடத்தில் அன்பாயிருப்பீர்கள். ஏனெனில் நான் தேவனிடத்திலிருந்து வந்திருக்கிறேன்; நான் சுயமாய் வரவில்லை, அவரே என்னை அனுப்பினார்.
	<br /><br />
	43. என் வசனத்தை நீங்கள் ஏன் அறியாமலிருக்கிறீர்கள்? என் உபதேசத்தைக் கேட்க மனதில்லாதிருக்கிறதினால் அல்லவா?
	<br /><br />
	44. நீங்கள் உங்கள் பிதாவாகிய பிசாசானவனால் உண்டானவர்கள்; உங்கள் பிதாவினுடைய இச்சைகளின்படி செய்ய மனதாயிருக்கிறீர்கள்; அவன் ஆதிமுதற்கொண்டு மனுஷகொலைபாதகனாயிருக்கிறான்; சத்தியம் அவனிடத்திலில்லாதபடியால் அவன் சத்தியத்திலே நிலைநிற்கவில்லை; அவன் பொய்யனும் பொய்க்குப் பிதாவுமாயிருக்கிறபடியால் அவன் பொய்பேசும்போது தன் சொந்தத்தில் எடுத்துப் பேசுகிறான்.
	<br /><br />
	45. நான் உங்களுக்குச் சத்தியத்தைச் சொல்லுகிறபடியினாலே நீங்கள் என்னை விசுவாசிக்கிறதில்லை.
	<br /><br />
	46. என்னிடத்தில் பாவம் உண்டென்று உங்களில் யார் என்னைக் குற்றப்படுத்தக்கூடும்? நான் சத்தியத்தைச் சொல்லியிருக்க, நீங்கள் ஏன் என்னை விசுவாசிக்கிறதில்லை.
	<br /><br />
	47. தேவனால் உண்டானவன் தேவனுடைய வசனங்களுக்குச் செவிகொடுக்கிறான்; நீங்கள் தேவனால் உண்டாயிராதபடியினால் செவிகொடாமலிருக்கிறீர்கள் என்றார்.
	<br /><br />
	48. அப்பொழுது யூதர்கள் அவருக்குப் பிரதியுத்தரமாக: உன்னைச் சமாரியனென்றும், பிசாசுபிடித்தவனென்றும் நாங்கள் சொல்லுகிறது சரிதானே என்றார்கள்.
	<br /><br />
	49. அதற்கு இயேசு: நான் பிசாசு பிடித்தவனல்ல, நான் என் பிதாவைக் கனம்பண்ணுகிறேன், நீங்கள் என்னைக் கனவீனம்பண்ணுகிறீர்கள்.
	<br /><br />
	50. நான் எனக்கு மகிமையைத் தேடுகிறதில்லை; அதைத் தேடி நியாயந்தீர்க்கிறவர் ஒருவர் இருக்கிறார்.
	<br /><br />
	51. ஒருவன் என் வார்த்தையைக் கைக்கொண்டால், அவன் என்றென்றைக்கும் மரணத்தைக் காண்பதில்லை என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	52. அப்பொழுது யூதர்கள் அவரை நோக்கி: நீ பிசாசுபிடித்தவனென்று இப்பொழுது அறிந்திருக்கிறோம்; ஆபிரகாமும் தீர்க்கதரிசிகளும் மரித்தார்கள். நீயோ: ஒருவன் என் வார்த்தையைக் கைக்கொண்டால் என்றென்றைக்கும் மரணத்தை ருசிபார்ப்பதில்லை என்கிறாய்.
	<br /><br />
	53. எங்கள் பிதாவாகிய ஆபிரகாமிலும் நீ பெரியவனோ? அவர் மரித்தார், தீர்க்கதரிசிகளும் மரித்தார்கள்; உன்னை நீ எப்படிப்பட்டவனாக்குகிறாய் என்றார்கள்.
	<br /><br />
	54. இயேசு பிரதியுத்தரமாக: என்னை நானே மகிமைப்படுத்தினால் அந்த மகிமை வீணாயிருக்கும், என் பிதா என்னை மகிமைப்படுத்துகிறவர், அவரை உங்கள் தேவனென்று நீங்கள் சொல்லுகிறீர்கள்.
	<br /><br />
	55. ஆயினும் நீங்கள் அவரை அறியவில்லை, நான் அவரை அறிந்திருக்கிறேன்; அவரை அறியேன் என்று சொல்வேனாகில் உங்களைப்போல நானும் பொய்யனாயிருப்பேன்; அவரை நான் அறிந்து, அவருடைய வார்த்தையைக் கைக்கொண்டிருக்கிறேன்.
	<br /><br />
	56. உங்கள் பிதாவாகிய ஆபிரகாம் என்னுடைய நாளைக் காண ஆசையாயிருந்தான்; கண்டு களிகூர்ந்தான் என்றார்.
	<br /><br />
	57. அப்பொழுது யூதர்கள் அவரை நோக்கி: உனக்கு இன்னும் ஐம்பது வயதாகவில்லையே, நீ ஆபிரகாமைக் கண்டாயோ என்றார்கள்.
	<br /><br />
	58. அதற்கு இயேசு: ஆபிரகாம் உண்டாகிறதற்கு முன்னமே நான் இருக்கிறேன் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	59. அப்பொழுது அவர்மேல் எறியும்படி கல்லுகளை எடுத்துக்கொண்டார்கள். இயேசு மறைந்து, அவர்கள் நடுவே கடந்து, தேவாலயத்தை விட்டுப்போனார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John8