import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 14 </title>
	<meta
          name="description"
          content="Hosea 14 | ஓசியா 14 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேலே, உன் தேவனாகிய கர்த்தரிடத்தில் திரும்பு; நீ உன் அக்கிரமத்தினால் விழுந்தாய்.
	<br /><br />
	2. வார்த்தைகளைக்கொண்டு கர்த்தரிடத்தில் திரும்புங்கள்; அவரை நோக்கி: தேவரீர் எல்லா அக்கிரமத்தையும் நீக்கி, எங்களைத் தயவாய் அங்கீகரித்தருளும்; அப்பொழுது நாங்கள் எங்கள் உதடுகளின் காளைகளைச் செலுத்துவோம்.
	<br /><br />
	3. அசீரியா எங்களை இரட்சிப்பதில்லை; நாங்கள் குதிரைகளின்மேல் ஏறமாட்டோம்; எங்கள் கைகளின் கிரியையைப்பார்த்து: நீங்கள் எங்கள் தேவர்கள் என்று இனிச் சொல்லமாட்டோம்; திக்கற்றவன் உம்மிடத்தில் இரக்கம்பெறுகிறான் என்று சொல்லுங்கள்.
	<br /><br />
	4. நான் அவர்கள் சீர்கேட்டைக் குணமாக்குவேன்; அவர்களை மனப்பூர்வமாய்ச் சிநேகிப்பேன்; என் கோபம் அவர்களைவிட்டு நீங்கிற்று.
	<br /><br />
	5. நான் இஸ்ரவேலுக்குப் பனியைப்போலிருப்பேன்; அவன் லீலிப் புஷ்பத்தைப்போல் மலருவான்; லீபனோனைப்போல் வேரூன்றி நிற்பான்.
	<br /><br />
	6. அவன் கிளைகள் ஓங்கிப் படரும், அவன் அலங்காரம் ஒலிவமரத்தினுடைய அலங்காரத்தைப்போலவும், அவனுடைய வாசனை லீபனோனுடைய வாசனையைப்போலவும் இருக்கும்.
	<br /><br />
	7. அவன் நிழலில் குடியிருக்கிறவர்கள் திரும்புவார்கள்; தானிய விளைச்சலைப்போலச் செழித்து, திராட்சச்செடிகளைப்போலப் படருவார்கள்; அவன் வாசனை லீபனோனுடைய திராட்சரசத்தின் வாசனையைப்போல இருக்கும்.
	<br /><br />
	8. இனி எனக்கும் விக்கிரகங்களுக்கும் என்ன என்று எப்பிராயீம் சொல்லுவான்; நான் அவனுக்குச் செவிகொடுத்து, அவன்மேல் நோக்கமாயிருந்தேன்; நான் பச்சையான தேவதாரு விருட்சம்போலிருக்கிறேன்; என்னாலே உன் கனியுண்டாயிற்று என்று எப்பிராயீம் சொல்லுவான்.
	<br /><br />
	9. இவைகளை உணரத்தக்க ஞானமுள்ளவன் யார்? இவைகளைக் கிரகிக்கத்தக்க புத்தியுள்ளவன் யார்? கர்த்தருடைய வழிகள் செம்மையானவைகள், நீதிமான்கள் அவைகளில் நடப்பார்கள்; பாதகரோவென்றால் அவைகளில் இடறிவிழுவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea14