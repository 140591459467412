import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 16 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்னும் அவர் தம்முடைய சீஷர்களை நோக்கி: ஐசுவரியவானாகிய ஒரு மனுஷனுக்கு ஒரு உக்கிராணக்காரன் இருந்தான்; அவன் தன் எஜமானுடைய ஆஸ்திகளை அழித்துப்போடுகிறதாக எஜமானுக்கு அறிவிக்கப்பட்டது.
	<br /><br />
	2. அப்பொழுது எஜமான் அவனை வரவழைத்து: உன்னைக்குறித்து நான் இப்படிக் கேள்விப்படுகிறதென்ன? உன் உக்கிராணக் கணக்கை யொப்புவி, இனி நீ உக்கிராணக்காரனாயிருக்கக்கூடாது என்றான்.
	<br /><br />
	3. அப்பொழுது உக்கிராணக்காரன்: நான் என்ன செய்வேன், என் எஜமான் உக்கிராண விசாரிப்பிலிருந்து என்னைத் தள்ளிப்போடுகிறானே; கொத்துகிறதற்கு எனக்குப் பெலனில்லை, இரக்கவும் வெட்கப்படுகிறேன்.
	<br /><br />
	4. உக்கிராண விசாரிப்பைவிட்டு நான் தள்ளப்படும்போது, என்னைத் தங்கள் வீடுகளில் ஏற்றுக்கொள்வார் உண்டாகும்படி செய்யவேண்டியது இன்னதென்று எனக்குத் தெரியவந்தது, என்று தனக்குள்ளே சொல்லிக்கொண்டு;
	<br /><br />
	5. தன் எஜமானிடத்தில் கடன்பட்டவர்களை ஒவ்வொருவனாக வரவழைத்து: முதலாவது வந்தவனை நோக்கி: நீ என் எஜமானிடத்தில் பட்ட கடன் எவ்வளவு என்றான்.
	<br /><br />
	6. அவன்: நூறுகுடம் எண்ணெய் என்றான். அப்பொழுது உக்கிராணக்காரன் அவனை நோக்கி: நீ உன் சீட்டை வாங்கி, உட்கார்ந்து, ஐம்பது என்று சீக்கிரமாய் எழுது என்றான்.
	<br /><br />
	7. பின்பு அவன் வேறொருவனை நோக்கி: நீ பட்ட கடன் எவ்வளவு என்றான். அவன்: நூறு கலம் கோதுமை என்றான். அப்பொழுது அவன்: நீ உன் சீட்டை வாங்கி, எண்பது என்று எழுது என்றான்.
	<br /><br />
	8. அநீதியுள்ள உக்கிராணக்காரன் புத்தியாய்ச் செய்தான் என்று எஜமான் கண்டு, அவனை மெச்சிக்கொண்டான். இவ்விதமாய் ஒளியின் பிள்ளைகளைப்பார்க்கிலும் இந்தப் பிரபஞ்சத்தின் பிள்ளைகள் தங்கள் சந்ததியில் அதிக புத்திமான்களாயிருக்கிறார்கள்.
	<br /><br />
	9. நான் உங்களுக்குச் சொல்லுகிறேன், நீங்கள் மாளும்போது உங்களை நித்தியமான வீடுகளிலே ஏற்றுக் கொள்வாருண்டாகும்படி, அநீதியான உலகப்பொருளால் உங்களுக்குச் சிநேகிதரைச் சம்பாதியுங்கள்.
	<br /><br />
	10. கொஞ்சத்திலே உண்மையுள்ளவன் அநேகத்திலும் உண்மையுள்ளவனாயிருக்கிறான், கொஞ்சத்திலே அநீதியுள்ளவன் அநேகத்திலும் அநீதியுள்ளவனாயிருக்கிறான்.
	<br /><br />
	11. அநீதியான உலகப்பொருளைப்பற்றி நீங்கள் உண்மையாயிராவிட்டால், யார் உங்களை நம்பி உங்களிடத்தில் மெய்யான பொருளை ஒப்புவிப்பார்கள்?
	<br /><br />
	12. வேறொருவனுடைய காரியத்தில் நீங்கள் உண்மையுள்ளவர்களாயிராவிட்டால், உங்களுக்கு யாதொன்றைச் சொந்தமாகக் கொடுப்பவர் யார்?
	<br /><br />
	13. எந்த ஊழியக்காரனும் இரண்டு எஜமான்களுக்கு ஊழியஞ்செய்யக்கூடாது; ஒருவனைப் பகைத்து மற்றவனைச் சிநேகிப்பான், அல்லது ஒருவனைப் பற்றிக்கொண்டு மற்றவனை அசட்டைபண்ணுவான். தேவனுக்கும் உலகப்பொருளுக்கும் ஊழியஞ்செய்ய உங்களாலே கூடாது என்றார்.
	<br /><br />
	14. இவைகளையெல்லாம் பொருளாசைக்காரராகிய பரிசேயரும் கேட்டு, அவரைப் பரியாசம்பண்ணினார்கள்.
	<br /><br />
	15. அவர் அவர்களை நோக்கி: நீங்கள் மனுஷர்முன்பாக உங்களை நீதிமான்களாகக் காட்டுகிறீர்கள், தேவனோ உங்கள் இருதயங்களை அறிந்திருக்கிறார்; மனுஷருக்குள்ளே மேன்மையாக எண்ணப்படுகிறது தேவனுக்கு முன்பாக அருவருப்பாயிருக்கிறது.
	<br /><br />
	16. நியாயப்பிரமாணமும் தீர்க்கதரிசன வாக்கியங்களும் யோவான்வரைக்கும் வழங்கிவந்தது; அதுமுதல் தேவனுடைய ராஜ்யம் சுவிசேஷமாய் அறிவிக்கப்பட்டுவருகிறது, யாவரும் பலவந்தமாய் அதில் பிரவேசிக்கிறார்கள்.
	<br /><br />
	17. வேதத்தில் ஒரு எழுத்தின் உறுப்பு அவமாய்ப் போவதைப்பார்க்கிலும், வானமும் பூமியும் ஒழிந்துபோவது எளிதாயிருக்கும்.
	<br /><br />
	18. தன் மனைவியைத் தள்ளிவிட்டு, வேறொருத்தியை விவாகம்பண்ணுகிறவன் விபசாரஞ்செய்கிறான், புருஷனாலே தள்ளப்பட்டவளை விவாகம்பண்ணுகிறவனும் விபசாரஞ்செய்கிறான்.
	<br /><br />
	19. ஐசுவரியமுள்ள ஒரு மனுஷன் இருந்தான்; அவன் இரத்தாம்பரமும் விலையேறப்பெற்ற வஸ்திரமும் தரித்து, அநுதினமும் சம்பிரமமாய் வாழ்ந்துகொண்டிருந்தான்.
	<br /><br />
	20. லாசரு என்னும் பேர்கொண்ட ஒரு தரித்திரனும் இருந்தான்; அவன் பருக்கள் நிறைந்தவனாய், அந்த ஐசுவரியவானுடைய வாசலருகே கிடந்து,
	<br /><br />
	21. அவனுடைய மேஜையிலிருந்து விழுந் துணிக்கைகளாலே தன் பசியை ஆற்ற ஆசையாயிருந்தான்; நாய்கள் வந்து அவன் பருக்களை நக்கிற்று.
	<br /><br />
	22. பின்பு அந்தத் தரித்திரன் மரித்து, தேவதூதரால் ஆபிரகாமுடைய மடியிலே கொண்டுபோய் விடப்பட்டான்; ஐசுவரியவானும் மரித்து அடக்கம்பண்ணப்பட்டான்.
	<br /><br />
	23. பாதாளத்திலே அவன் வேதனைப்படுகிறபோது, தன் கண்களை ஏறெடுத்து, தூரத்திலே ஆபிரகாமையும் அவன் மடியிலே லாசருவையும் கண்டான்.
	<br /><br />
	24. அப்பொழுது அவன்: தகப்பனாகிய ஆபிரகாமே, நீர் எனக்கு இரங்கி, லாசரு தன் விரலின் நுனியைத் தண்ணீரில் தோய்த்து, என் நாவைக் குளிரப்பண்ணும்படி அவனை அனுப்பவேண்டும்; இந்த அக்கினிஜுவாலையில் வேதனைப்படுகிறேனே என்று கூப்பிட்டான்.
	<br /><br />
	25. அதற்கு ஆபிரகாம்: மகனே, நீ பூமியிலே உயிரோடிருக்குங் காலத்தில் உன் நன்மைகளை அனுபவித்தாய், லாசருவும் அப்படியே தீமைகளை அநுபவித்தான், அதை நினைத்துக்கொள்; இப்பொழுது அவன் தேற்றப்படுகிறான், நீயோ வேதனைப்படுகிறாய்.
	<br /><br />
	26. அதுவுமல்லாமல், இவ்விடத்திலிருந்து உங்களிடத்திற்குக் கடந்துபோகவும், அவ்விடத்திலிருந்து எங்களிடத்திற்குக் கடந்துவரவும் மனதுள்ளவர்களுக்குக் கூடாதபடிக்கு, எங்களுக்கும் உங்களுக்கும் நடுவே பெரும்பிளப்பு உண்டாக்கப்பட்டிருக்கிறது என்றான்.
	<br /><br />
	27. அப்பொழுது அவன்: அப்படியானால், தகப்பனே, எனக்கு ஐந்துபேர் சகோதரருண்டு, அவர்களும் வேதனையுள்ள இந்த இடத்துக்கு வராதபடி, அவன் போய் அவர்களுக்குச் சாட்சியாக அறிவிக்கும் பொருட்டு,
	<br /><br />
	28. நீர் அவனை என் தகப்பன் வீட்டுக்கு அனுப்பும்படி உம்மை வேண்டிக்கொள்ளுகிறேன் என்றான்.
	<br /><br />
	29. ஆபிரகாம் அவனை நோக்கி: அவர்களுக்கு மோசேயும் தீர்க்கதரிசிகளும் உண்டு, அவர்களுக்கு அவர்கள் செவிகொடுக்கட்டும் என்றான்.
	<br /><br />
	30. அதற்கு அவன்: அப்படியல்ல, தகப்பனாகிய ஆபிரகாமே, மரித்தோரிலிருந்து ஒருவன் அவர்களிடத்திற்குப் போனால் மனந்திரும்புவார்கள் என்றான்.
	<br /><br />
	31. அதற்கு அவன்: அவர்கள் மோசேக்கும் தீர்க்கதரிசிகளுக்கும் செவிகொடாவிட்டால், மரித்தோரிலிருந்து ஒருவன் எழுந்துபோனாலும், நம்பமாட்டார்கள் என்று சொன்னான் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke16