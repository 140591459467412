import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 1 | Tamil Bible Verse | வெளிப்படுத்தல் -1 </title>
	<meta
          name="description"
          content="The Book of Revelation in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* யோவானுக்கு இயேசு கிறிஸ்துவின் வெளிப்பாடு. தேவாலயங்களுக்கு எழுதுங்கள் என்று ஒரு குரல் கேட்டது. நான் மனுஷகுமாரனைப் போன்ற ஒருவரைக் கண்டேன், அவருடைய முகம் சூரியனைப் போன்றது.*
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. சீக்கிரத்தில் சம்பவிக்கவேண்டியவைகளைத் தம்முடைய ஊழியக்காரருக்குக் காண்பிக்கும்பொருட்டு, தேவன் இயேசுகிறிஸ்துவுக்கு ஒப்புவித்ததும், இவர் தம்முடைய தூதனை அனுப்பி, தம்முடைய ஊழியக்காரனாகிய யோவானுக்கு வெளிப்படுத்தினதுமான விசேஷம்.
	<br /><br />
	2. இவன் தேவனுடைய வசனத்தைக்குறித்தும், இயேசுகிறிஸ்துவைப்பற்றிய சாட்சியைக்குறித்தும், தான் கண்ட யாவற்றையும் சாட்சியாக அறிவித்திருக்கிறான்.
	<br /><br />
	3. இந்தத் தீர்க்கதரிசன வசனங்களை வாசிக்கிறவனும், கேட்கிறவர்களும், இதில் எழுதியிருக்கிறவைகளைக் கைக்கொள்ளுகிறவர்களும் பாக்கியவான்கள், காலம் சமீபமாயிருக்கிறது.
	<br /><br />
	4. யோவான் ஆசியாவிலுள்ள ஏழுசபைகளுக்கும் எழுதுகிறதாவது: இருக்கிறவரும் இருந்தவரும் வருகிறவருமானவராலும், அவருடைய சிங்காசனத்திற்கு முன்பாக இருக்கிற ஏழு ஆவிகளாலும்,
	<br /><br />
	5. உண்மையுள்ள சாட்சியும், மரித்தோரிலிருந்து முதற்பிறந்தவரும், பூமியின் ராஜாக்களுக்கு அதிபதியுமாகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	6. நம்மிடத்தில் அன்புகூர்ந்து, தமது இரத்தத்தினாலே நம்முடைய பாவங்களற நம்மைக் கழுவி, தம்முடைய பிதாவாகிய தேவனுக்கு முன்பாக நம்மை ராஜாக்களும் ஆசாரியர்களுமாக்கின அவருக்கு மகிமையும் வல்லமையும் என்றென்றைக்கும் உண்டாயிருப்பதாக. ஆமென்.
	<br /><br />
	7. இதோ, மேகங்களுடனே வருகிறார்; கண்கள் யாவும் அவரைக் காணும், அவரைக் குத்தினவர்களும் அவரைக் காண்பார்கள்; பூமியின் கோத்திரத்தாரெல்லாரும் அவரைப் பார்த்துப் புலம்புவார்கள். அப்படியே ஆகும், ஆமென்.
	<br /><br />
	8. இருக்கிறவரும் இருந்தவரும் வருகிறவருமாகிய சர்வவல்லமையுள்ள கர்த்தர்: நான் அல்பாவும், ஓமெகாவும், ஆதியும் அந்தமுமாயிருக்கிறேன் என்று திருவுளம்பற்றுகிறார்.
	<br /><br />
	9. உங்கள் சகோதரனும், இயேசுகிறிஸ்துவினிமித்தம் வருகிற உபத்திரவத்திற்கும் அவருடைய ராஜ்யத்திற்கும் அவருடைய பொறுமைக்கும் உங்கள் உடன்பங்காளனுமாயிருக்கிற யோவானாகிய நான் தேவவசனத்தினிமித்தமும், இயேசுகிறிஸ்துவைப்பற்றிய சாட்சியினிமித்தமும், பத்மு என்னும் தீவிலே இருந்தேன்.
	<br /><br />
	10. கர்த்தருடைய நாளில் ஆவிக்குள்ளானேன்; அப்பொழுது எனக்குப் பின்னாலே எக்காளசத்தம்போன்ற பெரிதான ஒரு சத்தத்தைக் கேட்டேன்.
	<br /><br />
	11. அது: நான் அல்பாவும் ஓமெகாவும், முந்தினவரும் பிந்தினவருமாயிருக்கிறேன். நீ காண்கிறதை ஒரு புஸ்தகத்தில் எழுதி, ஆசியாவிலிருக்கிற எபேசு, சிமிர்னா, பெர்கமு, தியத்தீரா, சர்தை, பிலதெல்பியா, லவோதிக்கேயா என்னும் பட்டணங்களிலுள்ள ஏழு சபைகளுக்கும் அனுப்பு என்று விளம்பினது.
	<br /><br />
	12. அப்பொழுது என்னுடனே பேசின சத்தத்தைப் பார்க்கத் திரும்பினேன்; திரும்பினபோது, ஏழு பொன் குத்துவிளக்குகளையும்,
	<br /><br />
	13. அந்த ஏழு குத்துவிளக்குகளின் மத்தியிலே, நிலையங்கி தரித்து, மார்பருகே பொற்கச்சை கட்டியிருந்த மனுஷகுமாரனுக்கொப்பானவரையும் கண்டேன்.
	<br /><br />
	14. அவருடைய சிரசும் மயிரும் வெண்பஞ்சைப்போலவும் உறைந்த மழையைப்போலவும் வெண்மையாயிருந்தது; அவருடைய கண்கள் அக்கினிஜுவாலையைப் போலிருந்தது;
	<br /><br />
	15. அவருடைய பாதங்கள் உலைக்களத்தில் காய்ந்த பிரகாசமான வெண்கலம்போலிருந்தது; அவருடைய சத்தம் பெருவெள்ளத்து இரைச்சலைப்போலிருந்தது.
	<br /><br />
	16. தமது வலதுகரத்திலே ஏழு நட்சத்திரங்களை ஏந்திக்கொண்டிருந்தார்; அவர் வாயிலிருந்து இருபுறமும் கருக்குள்ள பட்டயம் புறப்பட்டது; அவருடைய முகம் வல்லமையாய்ப் பிரகாசிக்கிற சூரியனைப்போலிருந்தது.
	<br /><br />
	17. நான் அவரைக் கண்டபோது செத்தவனைப்போல அவருடைய பாதத்தில் விழுந்தேன்; அப்பொழுது அவர் தம்முடைய வலதுகரத்தை என்மேல் வைத்து, என்னை நோக்கி: பயப்படாதே, நான் முந்தினவரும் பிந்தினவரும், உயிருள்ளவருமாயிருக்கிறேன்;
	<br /><br />
	18. மரித்தேன், ஆனாலும், இதோ, சதாகாலங்களிலும் உயிரோடிருக்கிறேன், ஆமென்; நான் மரணத்திற்கும் பாதாளத்திற்குமுரிய திறவுகோல்களை உடையவராயிருக்கிறேன்.
	<br /><br />
	19. நீ கண்டவைகளையும், இருக்கிறவைகளையும், இவைகளுக்குப்பின்பு சம்பவிப்பவைகளையும் எழுது;
	<br /><br />
	20. என் வலதுகரத்தில் நீ கண்ட ஏழு நட்சத்திரங்களின் இரகசியத்தையும், ஏழு பொன் குத்துவிளக்குகளின் இரகசியத்தையும் எழுது; அந்த ஏழு நட்சத்திரங்களும் ஏழு சபைகளின் தூதர்களாம்; நீ கண்ட ஏழு குத்துவிளக்குகளும் ஏழு சபைகளாம்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
*  *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation1