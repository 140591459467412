import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 3 </title>
	<meta
          name="description"
          content="Jeremiah 3 | எரேமியா 3 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஒரு புருஷன் தன் மனைவியைத் தள்ளிவிட, அவள் அவனிடத்திலிருந்து புறப்பட்டுப்போய், அந்நியபுருஷனுக்கு மனைவியானால், அவன் அவளிடத்தில் இனித் திரும்பப்போவானோ? அந்த தேசம் மிகவும் தீட்டுப்படுமல்லவோ? என்று மனுஷர் சொல்லுவார்கள்; நீயோவென்றால் அநேக நேசரோடே வேசித்தனம்பண்ணினாய்; ஆகிலும் என்னிடத்திற்குத் திரும்பிவா என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	2. நீ மேடுகளின்மேல் உன் கண்களை ஏறெடுத்து, நீ வேசித்தனம்பண்ணாத இடம் ஒன்று உண்டோ என்று பார்; வனாந்தரத்திலே அரபியன் காத்துக்கொண்டிருக்கிறதுபோல, நீ வழி ஓரங்களில் உன் நேசருக்குக் காத்துக்கொண்டிருந்து, உன் வேசித்தனங்களாலும், உன் அக்கிரமங்களாலும் தேசத்தைத் தீட்டுப்படுத்தினாய்.
	<br /><br />
	3. அதினிமித்தம் மழை வருஷியாமலும், பின்மாரியில்லாமலும் போயிற்று; உனக்கோ, சோரஸ்திரீயின் நெற்றியிருக்கிறது; நீயோ: நாணமாட்டேன் என்கிறாய்.
	<br /><br />
	4. நீ இதுமுதல் என்னை நோக்கி: என் பிதாவே, தேவரீர் என் இளவயதின் அதிபதியென்று சொல்லி,
	<br /><br />
	5. சதாகாலமும் கோபத்தை வைப்பாரோ? அதை என்றென்றைக்கும் காப்பாரோ என்கிறாய் அல்லவோ? இதோ, இப்படி நீ சொல்லியும் பொல்லாப்புகளைச் செய்து, மிஞ்சிப்போகிறாய் என்கிறார்.
	<br /><br />
	6. யோசியா ராஜாவின் நாட்களிலே கர்த்தர் என்னை நோக்கி: சீர்கெட்ட இஸ்ரவேல் என்பவள் செய்ததைக் கண்டாயா? அவள் உயரமான சகல மலையின்மேலும், பச்சையான சகல மரத்தின்கீழும் போய், அங்கே வேசித்தனம்பண்ணினாள்.
	<br /><br />
	7. அவள் இப்படியெல்லாம் செய்தபின்பு: நீ என்னிடத்தில் திரும்பிவா என்று நான் சொன்னேன்; அவளோ திரும்பவில்லை; இதை அவளுடைய சகோதரியாகிய யூதா என்கிற துரோகி கண்டாள்.
	<br /><br />
	8. சீர்கெட்ட இஸ்ரவேல் என்பவள் விபசாரம்பண்ணின முகாந்தரங்கள் எல்லாவற்றினிமித்தமும் நான் அவளை அனுப்பிவிட்டு, அவளுடைய தள்ளுதற்சீட்டை அவளுக்குக் கொடுத்தபோதும், அவளுடைய சகோதரியாகிய யூதா என்கிற துரோகி பயப்படாமல்; இவளும் போய் வேசித்தனம்பண்ணினாள், இதை நான் கண்டேன்.
	<br /><br />
	9. பிரசித்தமான அவளுடைய வேசித்தனத்தினாலே தேசம் தீட்டுப்பட்டுப்போயிற்று; கல்லோடும் மரத்தோடும் விபசாரம் பண்ணிக்கொண்டிருந்தாள் என்றார்.
	<br /><br />
	10. இவைகளையெல்லாம் கண்டும், யூதா என்கிற அவளுடைய சகோதரியாகிய துரோகி, கள்ளத்தனமாய்த் திரும்பினாளேயன்றி, முழு இருதயத்தோடும் என்னிடத்தில் திரும்பவில்லை என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. பின்னும் கர்த்தர் என்னை நோக்கி: யூதா என்கிற துரோகியைப்பார்க்கிலும் சீர்கெட்ட இஸ்ரவேல் என்பவள் தன்னை நீதியுள்ளவளாக்கினாள்.
	<br /><br />
	12. நீ போய் வடதிசையை நோக்கிக் கூறவேண்டிய வார்த்தைகள் என்னவென்றால்: சீர்கெட்ட இஸ்ரவேலே, திரும்பு என்று கர்த்தர் சொல்லுகிறார்; நான் உங்கள்மேல் என் கோபத்தை இறங்கப்பண்ணுவதில்லை; நான் கிருபையுள்ளவரென்று கர்த்தர் சொல்லுகிறார்; நான் என்றைக்கும் கோபம் வைக்கமாட்டேன்.
	<br /><br />
	13. நீயோ, உன் தேவனாகிய கர்த்தருக்கு விரோதமாய்த் துரோகம்பண்ணி, பச்சையான சகல மரத்தின்கீழும் அந்நியரோடே சோரமார்க்கமாய் நடந்து, உன் அக்கிரமத்தையும், என் சத்தத்துக்குச் செவிகொடாமற்போனதையும் ஒத்துக்கொள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. சீர்கெட்ட பிள்ளைகளே, திரும்புங்கள் என்று கர்த்தர் சொல்லுகிறார்; நான் உங்கள் நாயகர்; நான் உங்களை ஊரில் ஒருவனும், வம்சத்தில் இரண்டுபேருமாகத் தெரிந்து, உங்களைச் சீயோனுக்கு அழைத்துக்கொண்டுவந்து,
	<br /><br />
	15. உங்களுக்கு என் இருதயத்துக்கு ஏற்ற மேய்ப்பர்களைக் கொடுப்பேன், அவர்கள் உங்களை அறிவோடும் புத்தியோடும் மேய்ப்பார்கள்.
	<br /><br />
	16. நீங்கள் தேசத்திலே பெருகிப் பலுகுகிற அந்நாட்களிலே, அவர்கள் கர்த்தருடைய உடன்படிக்கைப் பெட்டியென்று இனிச் சொல்வதில்லை; அது அவர்கள் மனதில் எழும்புவதும் இல்லை; அது அவர்கள் நினைவில் வருவதும் இல்லை; அதைக்குறித்து விசாரிப்பதும் இல்லை; அது இனிச் செப்பனிடப்படுவதும் இல்லை என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. அக்காலத்திலே எருசலேமைக் கர்த்தருடைய சிங்காசனம் என்பார்கள்; சகல ஜாதியாரும் எருசலேமில் விளங்கிய கர்த்தருடைய நாமத்தினிமித்தம் அதினிடமாகச் சேர்வார்கள்; அவர்கள் இனித் தங்கள் பொல்லாத இருதயத்தின் இச்சையின்படி நடவார்கள்.
	<br /><br />
	18. அந்நாட்களிலே யூதா வம்சத்தார் இஸ்ரவேல் வம்சத்தாரோடே சேர்ந்து, அவர்கள் ஏகமாய் வடதேசத்திலிருந்து புறப்பட்டு, நான் தங்கள் பிதாக்களுக்குச் சுதந்தரமாகக் கொடுத்த தேசத்திற்கு வருவார்கள்.
	<br /><br />
	19. நான் உன்னைப் பிள்ளைகளின் வரிசையிலே வைத்து, ஜனக்கூட்டங்களுக்குள்ளே நல்ல சுதந்தரமாகிய தேசத்தை உனக்குக் கொடுப்பது எப்படியென்று சொன்னேன்; ஆனாலும் நீ என்னை நோக்கி, என் பிதாவே என்று அழைப்பாய்; நீ என்னைவிட்டு விலகுவதில்லை என்று திரும்பவும் சொன்னேன்.
	<br /><br />
	20. ஒரு மனைவி தன் புருஷனுக்குத் துரோகம் செய்வதுபோல, இஸ்ரவேல் வம்சத்தாராகிய நீங்கள் எனக்குத் துரோகம்செய்தது மெய் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	21. இஸ்ரவேல் புத்திரர் தங்கள் வழியை மாறுபாடாக்கி, தங்கள் தேவனாகிய கர்த்தரை மறந்ததினிமித்தம் அழுதுகொண்டு விண்ணப்பஞ்செய்யும் சத்தம் உயர்ந்த ஸ்தானங்களிலே கேட்கப்படும்.
	<br /><br />
	22. சீர்கெட்ட பிள்ளைகளே, திரும்புங்கள்; உங்கள் சீர்கேடுகளைக் குணமாக்குவேன் என்றார். இதோ, உம்மிடத்தில் வருகிறோம்; நீரே எங்கள் தேவனாகிய கர்த்தர்.
	<br /><br />
	23. குன்றுகளையும், திரளான மலைகளையும் நம்புகிறது விருதா என்பது மெய்; இஸ்ரவேலின் இரட்சிப்பு எங்கள் தேவனாகிய கர்த்தருக்குள் இருப்பது என்பது மெய்யே.
	<br /><br />
	24. இந்த இலச்சையானது எங்கள் சிறுவயதுமுதல் எங்கள் பிதாக்களுடைய பிரயாசத்தையும், அவர்கள் ஆடுகளையும் மாடுகளையும், அவர்கள் குமாரரையும் குமாரத்திகளையும் பட்சித்துப்போட்டது.
	<br /><br />
	25. எங்கள் இலச்சையிலே கிடக்கிறோம்; எங்கள் அவமானம் எங்களை மூடியிருக்கிறது; நாங்களும், எங்கள் பிதாக்களும் எங்கள் சிறுவயதுமுதல் இந்நாள்வரைக்கும் எங்கள் தேவனாகிய கர்த்தருக்கு விரோதமாகப் பாவஞ்செய்தோம்; எங்கள் தேவனாகிய கர்த்தருடைய சொல்லைக் கேளாமலும்போனோம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah3