import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 7 </title>
	<meta
          name="description"
          content="John Chapter 7 | யோவான் அதிகாரம் 7 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவைகளுக்குப்பின்பு, யூதர்கள் இயேசுவைக் கொலைசெய்ய வகைதேடினபடியால், அவர் யூதேயாவிலே சஞ்சரிக்க மனதில்லாமல் கலிலேயாவிலே சஞ்சரித்துவந்தார்.
	<br /><br />
	2. யூதருடைய கூடாரப்பண்டிகை சமீபமாயிருந்தது.
	<br /><br />
	3. அப்பொழுது அவருடைய சகோதரர் அவரை நோக்கி: நீர் செய்கிற கிரியைகளை உம்முடைய சீஷர்களும் பார்க்கும்படி, இவ்விடம்விட்டு யூதேயாவுக்குப் போம்.
	<br /><br />
	4. பிரபலமாயிருக்க விரும்புகிற எவனும் அந்தரங்கத்திலே ஒன்றையும் செய்யமாட்டான்; நீர் இப்படிப்பட்டவைகளைச் செய்தால் உலகத்துக்கு உம்மை வெளிப்படுத்தும் என்றார்கள்.
	<br /><br />
	5. அவருடைய சகோதரரும் அவரை விசுவாசியாதபடியால் இப்படிச் சொன்னார்கள்.
	<br /><br />
	6. இயேசு அவர்களை நோக்கி: என் வேளை இன்னும் வரவில்லை, உங்கள் வேளையோ எப்பொழுதும் ஆயத்தமாயிருக்கிறது.
	<br /><br />
	7. உலகம் உங்களைப் பகைக்கமாட்டாது; அதின் கிரியைகள் பொல்லாதவைகளாயிருக்கிறதென்று நான் சாட்சிகொடுக்கிறபடியினாலே அது என்னைப் பகைக்கிறது.
	<br /><br />
	8. நீங்கள் இந்தப் பண்டிகைக்குப் போங்கள்; என் வேளை இன்னும் வராதபடியால் நான் இந்தப் பண்டிகைக்கு இப்பொழுது போகிறதில்லை என்றார்.
	<br /><br />
	9. இவைகளை அவர்களுடனே சொல்லி, பின்னுங் கலிலேயாவிலே தங்கினார்.
	<br /><br />
	10. அவருடைய சகோதரர் போனபின்பு, அவர் வெளியரங்கமாய்ப் போகாமல் அந்தரங்கமாய்ப் பண்டிகைக்குப் போனார்.
	<br /><br />
	11. பண்டிகையிலே யூதர்கள் அவரைத் தேடி: அவர் எங்கேயிருக்கிறார் என்றார்கள்.
	<br /><br />
	12. ஜனங்களுக்குள்ளே அவரைக்குறித்து முறுமுறுப்புண்டாயிற்று. சிலர்: அவர் நல்லவர் என்றார்கள். வேறுசிலர்: அப்படியல்ல, அவன் ஜனங்களை வஞ்சிக்கிறவன் என்று சொல்லிக்கொண்டார்கள்.
	<br /><br />
	13. ஆனாலும் யூதருக்குப் பயந்திருந்ததினாலே, ஒருவனும் அவரைக்குறித்துத் தாராளமாய்ப் பேசவில்லை.
	<br /><br />
	14. பாதிப்பண்டிகையானபோது, இயேசு தேவாலயத்துக்குப்போய், உபதேசம்பண்ணினார்.
	<br /><br />
	15. அப்பொழுது யூதர்கள்: இவர் கல்லாதவராயிருந்தும் வேத எழுத்துக்களை எப்படி அறிந்திருக்கிறார் என்று ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	16. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: என் உபதேசம் என்னுடையதாயிராமல், என்னை அனுப்பினவருடையதாயிருக்கிறது.
	<br /><br />
	17. அவருடைய சித்தத்தின்படி செய்ய மனதுள்ளவனெவனோ அவன் இந்த உபதேசம் தேவனால் உண்டாயிருக்கிறதோ, நான் சுயமாய்ப் பேசுகிறேனோ என்று அறிந்துகொள்ளுவான்.
	<br /><br />
	18. சுயமாய்ப் பேசுகிறவன் தன் சுயமகிமையைத் தேடுகிறான், தன்னை அனுப்பினவரின் மகிமையைத் தேடுகிறவனோ உண்மையுள்ளவனாயிருக்கிறான், அவனிடத்தில் அநீதியில்லை.
	<br /><br />
	19. மோசே நியாயப்பிரமாணத்தை உங்களுக்குக் கொடுக்கவில்லையா? அப்படியிருந்தும் உங்களில் ஒருவனும் அந்த நியாயப்பிரமாணத்தின்படி நடக்கிறதில்லை; நீங்கள் ஏன் என்னைக் கொலைசெய்யத் தேடுகிறீர்கள் என்றார்.
	<br /><br />
	20. ஜனங்கள் அவருக்குப் பிரதியுத்தரமாக: நீ பிசாசுபிடித்தவன்; உன்னைக் கொலைசெய்யத் தேடுகிறவன் யார் என்றார்கள்.
	<br /><br />
	21. இயேசு அவர்களை நோக்கி: ஒரே கிரியையைச் செய்தேன், அதைக்குறித்து எல்லாரும் ஆச்சரியப்படுகிறீர்கள்.
	<br /><br />
	22. விருத்தசேதனம் மோசேயினால் உண்டாகாமல், பிதாக்களால் உண்டாயிற்று; பின்பு மோசே அதை உங்களுக்கு நியமித்தான்; நீங்கள் ஓய்வுநாளிலும் மனுஷனை விருத்தசேதனம் பண்ணுகிறீர்கள்.
	<br /><br />
	23. மோசேயின் நியாயப்பிரமாணம் மீறப்படாதபடிக்கு ஓய்வுநாளில் மனுஷன் விருத்தசேதனம் பெறலாமென்றால், நான் ஓய்வுநாளில் ஒரு மனுஷனை முழுவதும் சுகமாக்கினதினாலே என்மேல் எரிச்சலாயிருக்கலாமா?
	<br /><br />
	24. தோற்றத்தின்படி தீர்ப்புசெய்யாமல், நீதியின்படி தீர்ப்புசெய்யுங்கள் என்றார்.
	<br /><br />
	25. அப்பொழுது எருசலேம் நகரத்தாரில் சிலர்: இவனையல்லவா கொலைசெய்யத்தேடுகிறார்கள்?
	<br /><br />
	26. இதோ, இவன் தாராளமாய்ப் பேசுகிறானே, ஒருவரும் இவனுக்கு ஒன்றும் சொல்லுகிறதில்லையே, மெய்யாய் இவன் கிறிஸ்துதான் என்று அதிகாரிகள் நிச்சயமாக அறிந்திருக்கிறார்களோ?
	<br /><br />
	27. இவன் இன்ன இடத்திலிருந்து வந்தவனென்று நாம் அறிந்திருக்கிறோம், கிறிஸ்து வரும்போதோ, அவர் இன்ன இடத்திலிருந்து வருகிறவரென்று ஒருவனும் அறியமாட்டானே என்றார்கள்.
	<br /><br />
	28. அப்பொழுது இயேசு தேவாலயத்தில் உபதேசிக்கையில் சத்தமிட்டு: நீங்கள் என்னை அறிவீர்கள், நான் எங்கேயிருந்து வந்தேனென்றும் அறிவீர்கள்; நான் என்சுயமாய் வரவில்லை, என்னை அனுப்பினவர் சத்தியமுள்ளவர், அவரை நீங்கள் அறியாதிருக்கிறீர்கள்.
	<br /><br />
	29. நான் அவரால் வந்திருக்கிறபடியினாலும், அவர் என்னை அனுப்பியிருக்கிறபடியினாலும், நானே அவரை அறிந்திருக்கிறேன் என்றார்.
	<br /><br />
	30. அப்பொழுது அவரைப் பிடிக்க வகைதேடினார்கள்; ஆனாலும் அவருடைய வேளை இன்னும் வராதபடியினால் ஒருவனும் அவர்மேல் கைபோடவில்லை.
	<br /><br />
	31. ஜனங்களில் அநேகர் அவரை விசுவாசித்து: கிறிஸ்து வரும்போது, இவர் செய்கிற அற்புதங்களைப்பார்க்கிலும் அதிகம் செய்வாரோ என்றார்கள்.
	<br /><br />
	32. ஜனங்கள் அவரைக்குறித்து இப்படி முறுமுறுக்கிறதைப் பரிசேயர் கேட்டபொழுது, அவரைப் பிடித்துக்கொண்டு வரும்படிக்குப் பரிசேயரும் பிரதான ஆசாரியரும் சேவகரை அனுப்பினார்கள்.
	<br /><br />
	33. அப்பொழுது இயேசு அவர்களை நோக்கி: இன்னுங் கொஞ்சக்காலம் நான் உங்களுடனேகூட இருந்து, பின்பு என்னை அனுப்பினவரிடத்திற்குப் போகிறேன்.
	<br /><br />
	34. நீங்கள் என்னைத் தேடுவீர்கள், ஆனாலும் என்னைக் காணமாட்டீர்கள்; நான் இருக்கும் இடத்திற்கு நீங்கள் வரவுங்கூடாது என்றார்.
	<br /><br />
	35. அப்பொழுது யூதர்கள்: இவரை நாம் காணாதபடிக்கு எங்கே போவார், கிரேக்கருக்குள்ளே சிதறியிருக்கிறவர்களிடத்திற்குப் போய், கிரேக்கருக்கு உபதேசம்பண்ணுவாரோ?
	<br /><br />
	36. நீங்கள் என்னைத் தேடுவீர்கள், ஆனாலும் என்னைக் காணமாட்டீர்கள் என்றும், நான் இருக்கும் இடத்துக்கு நீங்கள் வரக்கூடாது என்றும், இவர் சொன்ன வார்த்தையின் கருத்து என்னவென்று தங்களுக்குள்ளே சொல்லிக்கொண்டார்கள்.
	<br /><br />
	37. பண்டிகையின் கடைசிநாளாகிய பிரதான நாளிலே இயேசு நின்று, சத்தமிட்டு: ஒருவன் தாகமாயிருந்தால் என்னிடத்தில் வந்து, பானம்பண்ணக்கடவன்.
	<br /><br />
	38. வேதவாக்கியம் சொல்லுகிறபடி என்னிடத்தில் விசுவாசமாயிருக்கிறவன் எவனோ, அவன் உள்ளத்திலிருந்து ஜீவத்தண்ணீருள்ள நதிகள் ஓடும் என்றார்.
	<br /><br />
	39. தம்மை விசுவாசிக்கிறவர்கள் அடையப்போகிற ஆவியைக்குறித்து இப்படிச் சொன்னார். இயேசு இன்னும் மகிமைப்படாதிருந்தபடியினால் பரிசுத்தஆவி இன்னும் அருளப்படவில்லை.
	<br /><br />
	40. ஜனங்களில் அநேகர் இந்த வசனத்தைக் கேட்டபொழுது: மெய்யாகவே இவர் தீர்க்கதரிசியானவர் என்றார்கள்.
	<br /><br />
	41. வேறுசிலர்: இவர் கிறிஸ்து என்றார்கள். வேறுசிலர்: கிறிஸ்து கலிலேயாவிலிருந்தா வருவார்?
	<br /><br />
	42. தாவீதின் சந்ததியிலும், தாவீது இருந்த பெத்லெகேம் ஊரிலுமிருந்து கிறிஸ்து வருவார் என்று வேதவாக்கியம் சொல்லவில்லையா என்றார்கள்.
	<br /><br />
	43. இவ்விதமாக அவரைக்குறித்து ஜனங்களுக்குள்ளே பிரிவினையுண்டாயிற்று.
	<br /><br />
	44. அவர்களில் சிலர் அவரைப் பிடிக்க மனதாயிருந்தார்கள்; ஆகிலும் ஒருவனும் அவர்மேல் கைபோடவில்லை.
	<br /><br />
	45. பின்பு அந்தச் சேவகர் பிரதான ஆசாரியரிடத்திற்கும் பரிசேயரிடத்திற்கும் திரும்பிவந்தார்கள்; இவர்கள் அவர்களை நோக்கி: நீங்கள் அவனை ஏன் கொண்டுவரவில்லை என்று கேட்டார்கள்.
	<br /><br />
	46. சேவகர் பிரதியுத்தரமாக: அந்த மனுஷன் பேசுகிறதுபோல ஒருவனும் ஒருக்காலும் பேசினதில்லை என்றார்கள்.
	<br /><br />
	47. அப்பொழுது பரிசேயர்: நீங்களும் வஞ்சிக்கப்பட்டீர்களா?
	<br /><br />
	48. அதிகாரிகளிலாவது பரிசேயரிலாவது யாதாமொருவர் அவனை விசுவாசித்ததுண்டா?
	<br /><br />
	49. வேதத்தை அறியாதவர்களாகிய இந்த ஜனங்கள் சபிக்கப்பட்டவர்கள் என்றார்கள்.
	<br /><br />
	50. இராத்திரியிலே அவரிடத்திற்கு வந்தவனும் அவர்களிலொருவனுமாகிய நிக்கொதேமு என்பவன் அவர்களை நோக்கி:
	<br /><br />
	51. ஒரு மனுஷன் சொல்வதைக் கேட்டு, அவன் செய்கைகளை அறிகிறதற்கு முன்னே, அவனை ஆக்கினைக்குட்படுத்தலாமென்று நம்முடைய நியாயப்பிரமாணம் சொல்லுகிறதா என்றான்.
	<br /><br />
	52. அதற்கு அவர்கள்: நீரும் கலிலேயனோ? கலிலேயாவிலிருந்து ஒரு தீர்க்கதரிசியும் எழும்புகிறதில்லை என்பதை ஆராய்ந்துபாரும் என்றார்கள்.
	<br /><br />
	53. பின்பு அவரவர் தங்கள் தங்கள் வீட்டுக்குப் போனார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John7