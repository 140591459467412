import React from 'react';
import { Link } from 'react-router-dom';
import home from '../home.png';

const Header = ({ verseNumber }) => {

    const nextVerseNumber = verseNumber + 1;
  const backVerseNumber = verseNumber - 1;
  return (
    <header className='vh'>
      <div>
            <h3 className="verseh">
            3 யோவான்  
            </h3>
            <span className='verseno'>
                {verseNumber}
            </span>
            
        </div>
      <div className="navigation">
        <div className='vbn'>
            {backVerseNumber > 0 && (
          <Link to={`/3-John-${backVerseNumber}`}>
            <span className='verseb'></span>
          </Link>
        )}
      <span className='versehome'>
        <Link to="/3-John">
          <img src={home} alt='home' />
        </Link>
      </span>{nextVerseNumber <= 1 && (
          <Link to={`/3-John-${nextVerseNumber}`}>
            <span className='versen'></span>
          </Link>
        )}
        </div>
        </div>
    </header>
  );
};

export default Header;
