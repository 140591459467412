import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial28 = () => {
  const verseNumber = 28;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 28 </title>
	<meta
          name="description"
          content="Ezekiel 28 | எசேக்கியேல்  28 |
          Ezekiel 28 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ தீருவின் அதிபதியை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், உன் இருதயம் மேட்டிமைகொண்டு: நான் தேவன், நான் சமுத்திரத்தின் நடுவே தேவாசனத்தில் வீற்றிருக்கிறேன் என்று நீ சொல்லி, உன் இருதயத்தைத் தேவனின் இருதயத்தைப்போல் ஆக்கினாலும், நீ மனுஷனேயல்லாமல் தேவனல்ல.
	<br /><br />
	3. இதோ, தானியேலைப்பார்க்கிலும் நீ ஞானவான்; இரகசியமானதொன்றும் உனக்கு மறைபொருள் அல்ல.
	<br /><br />
	4. நீ உன் ஞானத்தினாலும் உன் புத்தியினாலும் பொருள் சம்பாதித்து, பொன்னையும் வெள்ளியையும் உன் பொக்கிஷசாலைகளில் சேர்த்துக்கொண்டாய்.
	<br /><br />
	5. உன் வியாபாரத்தினாலும் உன் மகா ஞானத்தினாலும் உன் பொருளைப் பெருகப்பண்ணினாய்; உன் இருதயம் உன் செல்வத்தினால் மேட்டிமையாயிற்று.
	<br /><br />
	6. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நீ உன் இருதயத்தைத் தேவனின் இருதயத்தைப்போல ஆக்குகிறபடியினால்,
	<br /><br />
	7. இதோ, ஜாதிகளில் மகா பலவான்களாகிய மறுதேசத்தாரை உனக்கு விரோதமாய் வரப்பண்ணுவேன்; அவர்கள் உன் ஞானத்தின் அழகுக்கு விரோதமாய்த் தங்கள் பட்டயங்களை உருவி, உன் மினுக்கைக் குலைத்துப்போடுவார்கள்.
	<br /><br />
	8. உன்னைக் குழியிலே விழத்தள்ளுவார்கள்; நீ சமுத்திரங்களின் நடுவே கொலையுண்டு சாகிறவர்களைப்போல் சாவாய்.
	<br /><br />
	9. உன்னைக் கொல்லுகிறவனுக்கு முன்பாக: நான் தேவனென்று நீ சொல்வாயோ? உன்னைக் குத்திப்போடுகிறவன் கைக்கு நீ மனுஷனேயல்லாமல் தேவனல்லவே.
	<br /><br />
	10. மறுதேசத்தாரின் கையினால் நீ விருத்தசேதனமில்லாதவர்கள் சாவதுபோல் சாவாய்; நான் இதைச் சொன்னேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	11. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	12. மனுபுத்திரனே, நீ தீரு ராஜாவைக்குறித்துப் புலம்பி, அவனை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், நீ விசித்திரமாய்ச் செய்யப்பட்ட முத்திரைமோதிரம்; நீ ஞானத்தால் நிறைந்தவன்; பூரண அழகுள்ளவன்.
	<br /><br />
	13. நீ தேவனுடைய தோட்டமாகிய ஏதேனில் இருந்தவன்; பத்மராகம், புஷ்பராகம், வைரம், படிகப்பச்சை, கோமேதகம், யஸ்பி, இந்திரநீலம், மரகதம், மாணிக்கம் முதலான சகலவித இரத்தினங்களும் பொன்னும் உன்னை மூடிக்கொண்டிருக்கிறது; நீ சிருஷ்டிக்கப்பட்ட நாளில் உன் மேளவாத்தியங்களும் உன் நாகசுரங்களும் உன்னிடத்தில் ஆயத்தப்பட்டிருந்தது.
	<br /><br />
	14. நீ காப்பாற்றுகிறதற்காக அபிஷேகம்பண்ணப்பட்ட கேருப்; தேவனுடைய பரிசுத்த பர்வதத்தில் உன்னை வைத்தேன்; அக்கினிமயமான கற்களின் நடுவே உலாவினாய்.
	<br /><br />
	15. நீ சிருஷ்டிக்கப்பட்ட நாள்துவக்கி உன்னில் அநியாயம் கண்டுபிடிக்கப்பட்டதுமட்டும், உன் வழிகளில் குறையற்றிருந்தாய்.
	<br /><br />
	16. உன் வியாபாரத்தின் மிகுதியினால், உன் கொடுமை அதிகரித்து நீ பாவஞ்செய்தாய்; ஆகையால் நான் உன்னைத் தேவனுடைய பர்வதத்திலிருந்து ஆகாதவனென்று தள்ளி, காப்பாற்றுகிற கேருபாய் இருந்த உன்னை அக்கினிமயமான கற்களின் நடுவே இராதபடிக்கு அழித்துப்போடுவேன்.
	<br /><br />
	17. உன் அழகினால் உன் இருதயம் மேட்டிமையாயிற்று; உன் மினுக்கினால் உன் ஞானத்தைக் கெடுத்தாய்; உன்னைத் தரையிலே தள்ளிப்போடுவேன்; ராஜாக்கள் உன்னைப் பார்க்கும்படி உன்னை அவர்களுக்கு முன்பாக வேடிக்கையாக்குவேன்.
	<br /><br />
	18. உன் அக்கிரமங்களின் ஏராளத்தினாலும், உன் வியாபாரத்தின் அநீதத்தினாலும் உன் பரிசுத்த ஸ்தலங்களைப் பரிசுத்தக்குலைச்சலாக்கினாய்; ஆகையால் உன்னைப் பட்சிப்பதாகிய ஒரு அக்கினியை நான் உன் நடுவிலிருந்து புறப்படப்பண்ணி, உன்னைப்பார்க்கிற எல்லாருடைய கண்களுக்கு முன்பாகவும் உன்னைப் பூமியின்மேல் சாம்பலாக்குவேன்.
	<br /><br />
	19. ஜனங்களில் உன்னை அறிந்த அனைவரும் உன்னிமித்தம் திகைப்பார்கள்; மகா பயங்கரமாவாய்; இனி ஒருபோதும் இருக்கமாட்டாய் என்று உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	20. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	21. மனுபுத்திரனே, நீ உன் முகத்தைச் சீதோனுக்கு எதிராகத் திருப்பி, அதற்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து, சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	22. கர்த்தராகிய தேவன் சொல்லுகிறார்: சீதோனே, இதோ, நான் உனக்கு விரோதமாக வந்து, உன் நடுவிலே மகிமைப்படுவேன்; நான் அதிலே நியாயத்தீர்ப்புகளைச் செய்து, அதிலே பரிசுத்தரென்று விளங்கும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	23. நான் அதிலே கொள்ளைநோயையும், அதின் வீதிகளில் இரத்தத்தையும் வரப்பண்ணுவேன்; அதற்கு விரோதமாய்ச் சுற்றிலும் வந்த பட்டயத்தினால் காயம்பட்டவர்கள் அதின் நடுவிலே வெட்டுண்டு விழுவார்கள்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	24. இஸ்ரவேல் வம்சத்தாரை இகழ்ந்த அவர்களுடைய சுற்றுப்புறத்தாராகிய அனைவரிலும், இனித் தைக்கிற முள்ளும் நோவுண்டாக்குகிற நெரிஞ்சிலும் அவர்களுக்கு இராது; அப்பொழுது நான் கர்த்தராகிய ஆண்டவரென்று அறிந்துகொள்வார்கள்.
	<br /><br />
	25. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், நான் இஸ்ரவேல் வம்சத்தாரை, அவர்கள் சிதறடிக்கப்பட்டிருக்கிற ஜனங்களிடத்திலிருந்து சேர்த்துக்கொண்டுவந்து, அவர்களால் ஜாதிகளின் கண்களுக்கு முன்பாகப் பரிசுத்தரென்று விளங்கும்போது, அவர்கள் என் தாசனாகிய யாக்கோபுக்கு நான் கொடுத்த தங்களுடைய தேசத்திலே குடியிருப்பார்கள்.
	<br /><br />
	26. அவர்களுடைய சுற்றுப்புறத்தாரில் அவர்களை இகழ்ந்த அனைவரிலும் நான் நியாயத்தீர்ப்புகளைச் செய்யும்போது, அவர்கள் அதிலே சுகமாய்க் குடியிருந்து, வீடுகளைக் கட்டி, திராட்சத்தோட்டங்களை நாட்டி, சுகமாய் வாழ்ந்து, நான் தங்கள் தேவனாகிய கர்த்தர் என்று அறிந்துகொள்வார்கள் என்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial28