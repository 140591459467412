import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah64 = () => {
  const verseNumber = 64;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 64 </title>
	<meta
          name="description"
          content="Isaiah 64 | ஏசாயா 64 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆ, உமது நாமத்தைச் சத்துருக்களுக்குத் தெரியப்பண்ணுவதற்கும், ஜாதிகள் உம்முடைய சந்நிதிக்கு முன் தத்தளிப்பதற்கும்,
	<br /><br />
	2. தேவரீர் வானங்களைக் கிழித்திறங்கி, உருக்கும் அக்கினி எரியுமாப்போலவும், அக்கினி தண்ணீரைப் பொங்கப்பண்ணுமாப்போலவும், பர்வதங்கள் உமக்கு முன்பாக உருகும்படிசெய்யும்.
	<br /><br />
	3. நாங்கள் எதிர்பார்த்திராத பயங்கரமான காரியங்களை நீர் செய்தபோது, நீர் இறங்கினீர், உமது சந்நிதியில் பர்வதங்கள் உருகிப்போயின.
	<br /><br />
	4. தேவனே, உமக்குக் காத்திருக்கிறவர்களுக்கு நீர் செய்பவைகளை, நீரேயல்லாமல் உலகத்தோற்றம்முதற்கொண்டு ஒருவரும் கேட்டதுமில்லை, செவியால் உணர்ந்ததுமில்லை, அவைகளைக் கண்டதுமில்லை.
	<br /><br />
	5. மகிழ்ச்சியாய் நீதியைச் செய்கிறவர்களையும், உம்முடைய வழிகளில் உம்மை நினைக்கிறவர்களையும் சந்திக்கிறீர்; நாங்களோ, அவைகளுக்கு விரோதமாக எப்பொழுதும் பாவஞ்செய்தபடியினாலே, தேவரீர் கடுங்கோபங்கொண்டீர்; இன்னமும் தப்பியிருக்கிறோம்.
	<br /><br />
	6. நாங்கள் அனைவரும் தீட்டானவர்கள்போல இருக்கிறோம்; எங்களுடைய நீதிகளெல்லாம் அழுக்கான கந்தைபோல இருக்கிறது, நாங்கள் அனைவரும் இலைகளைப்போல் உதிருகிறோம்; எங்கள் அக்கிரமங்கள் காற்றைப்போல் எங்களை அடித்துக்கொண்டுபோகிறது.
	<br /><br />
	7. உமது நாமத்தை நோக்கிக் கூப்பிடுகிறவனும், உம்மைப் பற்றிக்கொள்ளும்படிக்கு விழித்துக்கொள்ளுகிறவனும் இல்லை; தேவரீர் உம்முடைய முகத்தை எங்களை விட்டு மறைத்து, எங்கள் அக்கிரமங்களினிமித்தம் எங்களைக் கரையப்பண்ணுகிறீர்.
	<br /><br />
	8. இப்பொழுதும் கர்த்தாவே, நீர் எங்களுடைய பிதா, நாங்கள் களிமண்; நீர் எங்களை உருவாக்குகிறவர், நாங்கள் அனைவரும் உமது கரத்தின் கிரியை.
	<br /><br />
	9. கர்த்தாவே, அதிகமாய்க் கடுங்கோபங்கொள்ளாமலும், என்றைக்கும் அக்கிரமத்தை நினைத்துக்கொள்ளாமலும் இருப்பீராக; இதோ, பாரும், நாங்கள் அனைவரும் உம்முடைய ஜனங்களே.
	<br /><br />
	10. உமது பரிசுத்த பட்டணங்கள் வனாந்தரமாயின; சீயோன் வனாந்தரமாயிற்று; எருசலேம் பாழாய்க்கிடக்கிறது.
	<br /><br />
	11. எங்கள் பிதாக்கள் உம்மைத் துதித்த பரிசுத்தமும் மகிமையுமான எங்களுடைய ஆலயம் அக்கினிக்கு இரையாகி, இன்பமான எங்களுடைய ஸ்தானங்களெல்லாம் பாழாயின.
	<br /><br />
	12. கர்த்தாவே, இவைகள் இப்படியிருந்தும் அடக்கிக்கொண்டிருப்பீரோ? மவுனமாயிருந்து, அதிகமாய் எங்களைச் சிறுமைப்படுத்துவீரோ?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah64