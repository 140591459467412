import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah48 = () => {
  const verseNumber = 48;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 48 </title>
	<meta
          name="description"
          content="Isaiah 48 | ஏசாயா 48 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேலென்னும் பெயர்பெற்று, யூதாவின் நீரூற்றிலிருந்து சுரந்தவர்களும், கர்த்தருடைய நாமத்தின்மேல் ஆணையிட்டு, உண்மையும் நீதியும் இல்லாமல் இஸ்ரவேலின் தேவனை அறிக்கையிடுகிறவர்களுமான யாக்கோபின் வம்சத்தாரே, கேளுங்கள்.
	<br /><br />
	2. அவர்கள் தங்களைப் பரிசுத்த நகரத்தார் என்று சொல்லி, சேனைகளின் கர்த்தர் என்னும் நாமமுள்ள இஸ்ரவேலின் தேவன்மேல் பற்றுதலாயிருக்கிறார்கள்.
	<br /><br />
	3. பூர்வகாலத்தில் நடந்தவைகளை ஆதிமுதல் அறிவித்தேன், அவைகள் என் வாயிலிருந்து பிறந்தன, அவைகளை வெளிப்படுத்தினேன்; அவைகளைச் சடிதியாய்ச் செய்தேன், அவைகள் நடந்தன.
	<br /><br />
	4. நீ கடினமுள்ளவனென்றும், உன் பிடரி நரம்பு இரும்பென்றும், உன் நெற்றி வெண்கலமென்றும் அறிந்திருக்கிறேன்.
	<br /><br />
	5. ஆகையால்: என் விக்கிரகம் அவைகளைச் செய்ததென்றும், நான் செய்த சுரூபமும், நான் வார்ப்பித்த விக்கிரகமும் அவைகளைக் கட்டளையிட்டதென்றும் நீ சொல்லாதபடிக்கு, நான் அவைகளை முன்னமே உனக்கு அறிவித்து, அவைகள் வராததற்குமுன்னே உனக்கு வெளிப்படுத்தினேன்.
	<br /><br />
	6. அவைகளைக் கேள்விப்பட்டாயே, அவைகளையெல்லாம் பார், இப்பொழுது நீங்களும் அவைகளை அறிவிக்கலாமல்லவோ? இதுமுதல் புதியவைகளானவைகளையும், நீ அறியாத மறைபொருளானவைகளையும் உனக்குத் தெரிவிக்கிறேன்.
	<br /><br />
	7. அவைகள் ஆதிமுதற்கொண்டு அல்ல, இப்பொழுதே உண்டாக்கப்பட்டன; இதோ, அவைகளை அறிவேன் என்று நீ சொல்லாதபடிக்கு, இந்நாட்களுக்கு முன்னே நீ அவைகளைக் கேள்விப்படவில்லை.
	<br /><br />
	8. நீ கேள்விப்படவுமில்லை, அறியவுமில்லை; ஆதிமுதல் உன் செவி திறந்திருக்கவுமில்லை; நீ துரோகம்பண்ணுவாயென்பதையும், தாயின் கர்ப்பந்தொடங்கி நீ மீறுகிறவனென்று பெயர்பெற்றதையும் அறிந்திருக்கிறேன்.
	<br /><br />
	9. என் நாமத்தினிமித்தம் என் கோபத்தை நிறுத்திவைத்தேன்; உன்னைச் சங்கரிக்காதபடிக்கு நான் என் புகழ்ச்சியினிமித்தம் உன்மேல் பொறுமையாயிருப்பேன்.
	<br /><br />
	10. இதோ, உன்னைப் புடமிட்டேன்; ஆனாலும் வெள்ளியைப்போலல்ல, உபத்திரவத்தின் குகையிலே உன்னைத் தெரிந்துகொண்டேன்.
	<br /><br />
	11. என்னிமித்தம், என்னிமித்தமே, அப்படிச் செய்வேன்; என் நாமத்தின் பரிசுத்தம் எப்படிக் குலைக்கப்படலாம்? என் மகிமையை நான் வேறொருவருக்குங்கொடேன்.
	<br /><br />
	12. யாக்கோபே, நான் அழைத்திருக்கிற இஸ்ரவேலே, எனக்குச் செவிகொடு; நான் அவரே, நான் முந்தினவரும், நான் பிந்தினவருமாமே.
	<br /><br />
	13. என் கரமே பூமியை அஸ்திபாரப்படுத்தி, என் வலதுகை வானங்களை அளவிட்டது; நான் அவைகளுக்குக் கட்டளையிட, அவைகள் அனைத்தும் நிற்கும்.
	<br /><br />
	14. நீங்களெல்லாரும் கூடிவந்து கேளுங்கள்; கர்த்தருக்குப் பிரியமானவன் அவருக்குச் சித்தமானதைப் பாபிலோனில் செய்வான்; அவன் புயம் கல்தேயரின்மேல் இருக்கும் என்பதை இவர்களில் அறிவித்தவன் யார்?
	<br /><br />
	15. நான், நானே அதைச் சொன்னேன்; நான் அவனை அழைத்தேன்; நான் அவனை வரப்பண்ணினேன்; அவன் வழி வாய்க்கும்.
	<br /><br />
	16. நீங்கள் என் சமீபத்தில் வந்து, நான் சொல்வதைக் கேளுங்கள்; நான் ஆதிமுதற்கொண்டு அந்தரங்கத்தில் பேசவில்லை; அது உண்டான காலந்துவக்கி அங்கே நான் இருந்தேன்; இப்பொழுதோ கர்த்தராகிய ஆண்டவரும், அவருடைய ஆவியும் என்னை அனுப்புகிறார்.
	<br /><br />
	17. இஸ்ரவேலின் பரிசுத்தராயிருக்கிற உன் மீட்பரான கர்த்தர் சொல்லுகிறதாவது: பிரயோஜனமாயிருக்கிறதை உனக்குப் போதித்து, நீ நடக்கவேண்டிய வழியிலே உன்னை நடத்துகிற உன் தேவனாகிய கர்த்தர் நானே.
	<br /><br />
	18. ஆ, என் கற்பனைகளைக் கவனித்தாயானால் நலமாயிருக்கும்; அப்பொழுது உன் சமாதானம் நதியைப்போலும், உன் நீதி சமுத்திரத்தின் அலைகளைப்போலும் இருக்கும்.
	<br /><br />
	19. அப்பொழுது உன் சந்ததி மணலத்தனையாகவும், உன் கர்ப்பப்பிறப்பு அதின் அணுக்களத்தனையாகவும் இருக்கும்; அப்பொழுது அதின் பெயர் நம்மை விட்டு அற்றுப்போகாமலும் அழிக்கப்படாமலும் இருக்கும்.
	<br /><br />
	20. பாபிலோனிலிருந்து புறப்படுங்கள்; கல்தேயரைவிட்டு ஓடிவாருங்கள்; கர்த்தர் தம்முடைய தாசனாகிய யாக்கோபை மீட்டுக்கொண்டாரென்று சொல்லுங்கள்; இதைக் கெம்பீரசத்தமாய்க் கூறிப் பிரசித்தப்படுத்துங்கள், பூமியின் கடையாந்தரமட்டும் வெளிப்படுத்துங்கள் என்கிறார்.
	<br /><br />
	21. அவர் அவர்களை வனாந்தரங்களில் நடத்தும்போது, அவர்களுக்குத் தாகவிடாயிருந்ததில்லை; கன்மலையிலிருந்து தண்ணீரை அவர்களுக்குச் சுரக்கப்பண்ணினார், கன்மலையைப் பிளந்தார், தண்ணீர் ஓடிவந்தது.
	<br /><br />
	22. துன்மார்க்கருக்குச் சமாதானம் இல்லையென்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah48