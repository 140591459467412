import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoThessalonians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO THESSALONIANS 1 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE THESSALONIANS Chapter 1 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 1 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. பவுலும், சில்வானும், தீமோத்தேயும், நம்முடைய பிதாவாகிய தேவனுக்குள்ளும், கர்த்தராகிய இயேசுகிறிஸ்துவுக்குள்ளும் இருக்கிற தெசலோனிக்கேயர் சபைக்கு எழுதுகிறதாவது:
	<br /><br />
	2. நம்முடைய பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	3. சகோதரரே, நாங்கள் எப்பொழுதும் உங்களுக்காகத் தேவனை ஸ்தோத்திரிக்கக் கடனாளிகளாயிருக்கிறோம்; உங்கள் விசுவாசம் மிகவும் பெருகுகிறபடியினாலும், நீங்களெல்லாரும் ஒருவரிலொருவர் வைத்திருக்கிற அன்பு அதிகரிக்கிறபடியினாலும், அப்படிச் செய்கிறது தகுதியாயிருக்கிறது.
	<br /><br />
	4. நீங்கள் சகிக்கிற சகல துன்பங்களிலும் உபத்திரவங்களிலும் பொறுமையையும் விசுவாசத்தையும் காண்பிக்கிறதினிமித்தம் உங்களைக்குறித்து நாங்கள் தேவனுடைய சபைகளில் மேன்மைபாராட்டுகிறோம்.
	<br /><br />
	5. நீங்கள் தேவனுடைய ராஜ்யத்தினிமித்தம் பாடு அநுபவிக்கிறவர்களாயிருக்க, அந்த ராஜ்யத்திற்கு நீங்கள் பாத்திரரென்றெண்ணப்படும்படிக்கு, தேவன் நியாயமான தீர்ப்புச்செய்கிறவரென்பதற்கு, அதுவே அத்தாட்சியாயிருக்கிறது.
	<br /><br />
	6. உங்களை உபத்திரவப்படுத்துகிறவர்களுக்கு உபத்திரவத்தையும், உபத்திரவப்படுகிற உங்களுக்கு எங்களோடேகூட இளைப்பாறுதலையும் பிரதிபலனாகக் கொடுப்பது தேவனுக்கு நீதியாயிருக்கிறதே.
	<br /><br />
	7. தேவனை அறியாதவர்களுக்கும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் சுவிசேஷத்திற்குக் கீழ்ப்படியாதவர்களுக்கும் நீதியுள்ள ஆக்கினையைச் செலுத்தும்படிக்கு,
	<br /><br />
	8. கர்த்தராகிய இயேசு தமது வல்லமையின் தூதரோடும், ஜுவாலித்து எரிகிற அக்கினியோடும், வானத்திலிருந்து வெளிப்படும்போது அப்படியாகும்.
	<br /><br />
	9. அந்நாளிலே தம்முடைய பரிசுத்தவான்களில் மகிமைப்படத்தக்கவராயும், நீங்கள் எங்களுடைய சாட்சியை விசுவாசித்தபடியினாலே உங்களிடத்திலும், விசுவாசிக்கிறவர்களெல்லாரிடத்திலும் ஆச்சரியப்படத்தக்கவராயும், அவர் வரும்போது,
	<br /><br />
	10. அவர்கள் கர்த்தருடைய சந்நிதானத்திலிருந்தும், அவருடைய வல்லமை பொருந்திய மகிமையிலிருந்தும் நீங்கலாகி, நித்திய அழிவாகிய தண்டனையை அடைவார்கள்.
	<br /><br />
	11. ஆகையால், நம்முடைய தேவனும் கர்த்தராகிய இயேசுகிறிஸ்துவும் அளிக்கும் கிருபையின்படியே, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் நாமம் உங்களிடத்திலும், நீங்கள் அவரிடத்திலும் மகிமைப்படும்பொருட்டாக;
	<br /><br />
	12. நம்முடைய தேவன் உங்களைத் தமது அழைப்புக்குப் பாத்திரராக்கவும், தமது தயையுள்ள சித்தம் முழுவதையும் விசுவாசத்தின் கிரியையையும் பலமாய் உங்களிடத்தில் நிறைவேற்றவும் வேண்டுமென்று, எப்பொழுதும் உங்களுக்காக வேண்டிக்கொள்ளுகிறோம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoThessalonians1