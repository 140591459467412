import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 21 </title>
	<meta
          name="description"
          content="Acts 21 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 21 |
          Acts of Apostles Chapter-21 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நாங்கள் அவர்களை விட்டுப் பிரிந்து, துறைபெயர்ந்தபின்பு, நேராயோடி, கோஸ்தீவையும், மறுநாளில் ரோதுதீவையும் சேர்ந்து, அவ்விடம் விட்டுப் பத்தாரா பட்டணத்துக்கு வந்து,
	<br /><br />
	2. அங்கே பெனிக்கே தேசத்திற்குப்போகிற ஒரு கப்பலைக் கண்டு, அதிலே ஏறிப்போனோம்.
	<br /><br />
	3. சீப்புருதீவைக் கண்டு, அதை இடதுபுறமாக விட்டு, சீரியாநாட்டிற்கு ஓடி, தீருபட்டணத்துறையில் இறங்கினோம்; அங்கே கப்பலின் சரக்குகளை இறக்கவேண்டியதாயிருந்தது.
	<br /><br />
	4. அவ்விடத்திலுள்ள சீஷரைக் கண்டுபிடித்து, அங்கே ஏழுநாள் தங்கினோம். அவர்கள் பவுலை நோக்கி: நீர் எருசலேமுக்குப் போகவேண்டாம் என்று ஆவியின் ஏவுதலினாலே சொன்னார்கள்.
	<br /><br />
	5. அந்த நாட்கள் நிறைவேறினபின்பு, நாங்கள் புறப்பட்டுப்போகையில், அவர்களெல்லாரும் மனைவிகளோடும் பிள்ளைகளோடுங்கூடப் பட்டணத்துக்குப் புறம்பே எங்களை வழிவிட்டனுப்பும்படி வந்தார்கள். அப்பொழுது கடற்கரையிலே நாங்கள் முழங்காற்படியிட்டு ஜெபம்பண்ணினோம்.
	<br /><br />
	6. ஒருவரிடத்திலொருவர் உத்தரவு பெற்றுக்கொண்டபின்பு, நாங்கள் கப்பல் ஏறினோம்; அவர்கள் தங்கள் வீடுகளுக்குத் திரும்பிப்போனார்கள்.
	<br /><br />
	7. நாங்கள் கப்பல்யாத்திரையை முடித்து, தீருபட்டணத்தை விட்டுப் பித்தொலோமாய் பட்டணத்துக்கு வந்து, சகோதரரை வினவி, அவர்களிடத்தில் ஒருநாள் தங்கினோம்.
	<br /><br />
	8. மறுநாளிலே பவுலைச் சேர்ந்தவர்களாகிய நாங்கள் புறப்பட்டுச் செசரியாபட்டணத்துக்கு வந்து, ஏழுபேரில் ஒருவனாகிய பிலிப்பென்னும் சுவிசேஷகனுடைய வீட்டிலே பிரவேசித்து, அவனிடத்தில் தங்கினோம்.
	<br /><br />
	9. தீர்க்கதரிசனஞ்சொல்லுகிற கன்னியாஸ்திரீகளாகிய நாலு குமாரத்திகள் அவனுக்கு இருந்தார்கள்.
	<br /><br />
	10. நாங்கள் அநேகநாள் அங்கே தங்கியிருக்கையில், அகபு என்னும் பேர்கொண்ட ஒரு தீர்க்கதரிசி யூதேயாவிலிருந்து வந்தான்.
	<br /><br />
	11. அவன் எங்களிடத்தில் வந்து, பவுலினுடைய கச்சையை எடுத்துத் தன் கைகளையும் கால்களையும் கட்டிக்கொண்டு: இந்தக் கச்சையையுடையவனை எருசலேமிலுள்ள யூதர் இவ்விதமாய்க் கட்டிப் புறஜாதியார் கைகளில் ஒப்புக்கொடுப்பார்கள் என்று பரிசுத்த ஆவியானவர் சொல்லுகிறார் என்றான்.
	<br /><br />
	12. இவைகளை நாங்கள் கேட்டபொழுது, எருசலேமுக்குப் போகவேண்டாமென்று, நாங்களும் அவ்விடத்தாரும் அவனை வேண்டிக்கொண்டோம்.
	<br /><br />
	13. அதற்குப் பவுல்: நீங்கள் அழுது என் இருதயத்தை ஏன் உடைந்துபோகப்பண்ணுகிறீர்கள்? எருசலேமில் நான் கர்த்தராகிய இயேசுவின் நாமத்திற்காகக் கட்டப்படுவதற்குமாத்திரமல்ல, மரிப்பதற்கும் ஆயத்தமாயிருக்கிறேன் என்றான்.
	<br /><br />
	14. அவன் சம்மதியாதபடியினாலே, கர்த்தருடைய சித்தம் ஆகக்கடவதென்று அமர்ந்திருந்தோம்.
	<br /><br />
	15. அந்த நாட்களுக்குப்பின்பு நாங்கள் பிரயாண சாமான்களை ஆயத்தம்பண்ணிக்கொண்டு எருசலேமுக்குப் போனோம்.
	<br /><br />
	16. செசரியாபட்டணத்திலுள்ள சீஷரில் சிலர் எங்களுடனேகூட வந்ததுமன்றி, சீப்புருதீவானாகிய மினாசோன் என்னும் ஒரு பழைய சீஷனிடத்திலே நாங்கள் தங்கும்படியாக அவனையும் தங்களோடே கூட்டிக்கொண்டு வந்தார்கள்.
	<br /><br />
	17. நாங்கள் எருசலேமுக்கு வந்தபோது, சகோதரர் எங்களைச் சந்தோஷமாய் ஏற்றுக்கொண்டார்கள்.
	<br /><br />
	18. மறுநாளிலே பவுல் எங்களைக் கூட்டிக்கொண்டு, யாக்கோபினிடத்திற்குப் போனான்; மூப்பரெல்லாரும் அங்கே கூடிவந்தார்கள்.
	<br /><br />
	19. அவர்களை அவன் வினவி, தன் ஊழியத்தினாலே தேவன் புறஜாதிகளிடத்தில் செய்தவைகளை ஒவ்வொன்றாய் விவரித்துச்சொன்னான்.
	<br /><br />
	20. அதை அவர்கள் கேட்டுக் கர்த்தரை மகிமைப்படுத்தினார்கள். பின்பு அவர்கள் அவனை நோக்கி: சகோதரனே, யூதர்களுக்குள் அநேகமாயிரம்பேர் விசுவாசிகளாயிருக்கிறதைப் பார்க்கிறீரே, அவர்களெல்லாரும் நியாயப்பிரமாணத்துக்காக வைராக்கியமுள்ளவர்களாயிருக்கிறார்கள்.
	<br /><br />
	21. புறஜாதிகளிடத்திலிருக்கிற யூதரெல்லாரும் தங்கள் பிள்ளைகளுக்கு விருத்தசேதனம் பண்ணவும், முறைமைகளின்படி நடக்கவும் வேண்டுவதில்லையென்று நீர் சொல்லி, இவ்விதமாய் அவர்கள் மோசேயை விட்டுப் பிரிந்துபோகும்படி போதிக்கிறீரென்று இவர்கள் உம்மைக்குறித்துக் கேள்விப்பட்டிருக்கிறார்கள்.
	<br /><br />
	22. இப்பொழுது செய்யவேண்டியது என்ன? நீர் வந்திருக்கிறீரென்று இவர்கள் கேள்விப்பட்டு, நிச்சயமாகக் கூட்டங்கூடுவார்கள்.
	<br /><br />
	23. ஆகையால் நாங்கள் உமக்குச் சொல்லுகிறபடி நீர் செய்யவேண்டும்; அதென்னவென்றால், பிரார்த்தனை பண்ணிக்கொண்டவர்களாகிய நாலுபேர் எங்களிடத்தில் இருக்கிறார்கள்.
	<br /><br />
	24. அவர்களை நீர் சேர்த்துக்கொண்டு, அவர்களுடனேகூடச் சுத்திகரிப்பு செய்துகொண்டு, அவர்கள் தலைச்சவரம்பண்ணிக் கொள்வதற்குச் செல்லுமானதைச் செலவுசெய்யும்; அப்படிச் செய்தால் உம்மைக்குறித்துக் கேள்விப்பட்ட காரியங்கள் அபத்தமென்றும், நீரும் நியாயப்பிரமாணத்தைக் கைக்கொண்டு நடக்கிறவரென்றும் எல்லாரும் அறிந்துகொள்வார்கள்.
	<br /><br />
	25. விசுவாசிகளான புறஜாதியார் இப்படிப்பட்டவைகளைக் கைக்கொள்ளாமல், விக்கிரகங்களுக்குப் படைத்ததற்கும், இரத்தத்திற்கும், நெருக்குண்டு செத்ததிற்கும், வேசித்தனத்திற்கும், விலகியிருக்கவேண்டுமென்று நாங்கள் தீர்மானம்பண்ணி, அவர்களுக்கு எழுதியனுப்பினோமே என்றார்கள்.
	<br /><br />
	26. அப்பொழுது பவுல் அந்த மனுஷரைச் சேர்த்துக்கொண்டு, மறுநாளிலே அவர்களுடனேகூடத் தானும் சுத்திகரிப்பு செய்துகொண்டு, தேவாலயத்தில் பிரவேசித்து, அவர்களில் ஒவ்வொருவனுக்காகவும் வேண்டிய பலிசெலுத்தித் தீருமளவும் சுத்திகரிப்பு நாட்களை நிறைவேற்றுவேனென்று அறிவித்தான்.
	<br /><br />
	27. அந்த ஏழுநாட்களும் நிறைவேறி வருகையில், ஆசியாநாட்டிலிருந்து வந்த யூதர்கள் அவனைத் தேவாலயத்திலே கண்டு, ஜனங்களெல்லாரையும் எடுத்துவிட்டு, அவன்மேல் கைபோட்டு:
	<br /><br />
	28. இஸ்ரவேலரே, உதவிசெய்யுங்கள். நம்முடைய ஜனத்திற்கும் வேதப்பிரமாணத்திற்கும் இந்த ஸ்தலத்திற்கும் விரோதமாக எங்கும் எல்லாருக்கும் போதித்துவருகிறவன் இவன்தான்; இந்தத் தேவாலயத்திற்குள்ளே கிரேக்கரையும் கூட்டிக்கொண்டுவந்து, இந்தப் பரிசுத்த ஸ்தலத்தைத் தீட்டுப்படுத்தினான் என்று சத்தமிட்டார்கள்.
	<br /><br />
	29. எபேசியனாகிய துரோப்பீமு என்பவன் முன்னே நகரத்தில் பவுலுடனேகூட இருக்கிறதைக் கண்டிருந்தபடியால், பவுல் அவனைத் தேவாலயத்தில் கூட்டிக்கொண்டு வந்திருப்பானென்று நினைத்தார்கள்.
	<br /><br />
	30. அப்பொழுது நகரமுழுவதும் கலக்கமுற்றது; ஜனங்கள் கூட்டமாய் ஓடிவந்து, பவுலைப்பிடித்து, அவனைத் தேவாலயத்திற்குப் புறம்பே இழுத்துக்கொண்டுபோனார்கள்; உடனே கதவுகள் பூட்டப்பட்டது.
	<br /><br />
	31. அவர்கள் அவனைக் கொலைசெய்ய எத்தனித்திருக்கையில், எருசலேம் முழுவதும் கலக்கமாயிருக்கிறதென்று போர்ச்சேவகரின் சேனாபதிக்குச் செய்திவந்தது.
	<br /><br />
	32. உடனே அவன் போர்ச்சேவகரையும் அவர்களுடைய அதிபதிகளையும் கூட்டிக்கொண்டு, அவர்களிடத்திற்கு ஓடினான்; சேனாபதியையும் போர்ச்சேவகரையும் அவர்கள் கண்டபோது பவுலை அடிக்கிறதை விட்டு நிறுத்தினார்கள்.
	<br /><br />
	33. அப்பொழுது சேனாபதி கிட்டவந்து அவனைப் பிடித்து, இரண்டு சங்கிலிகளினாலே கட்டும்படி சொல்லி: இவன் யார் என்றும் என்ன செய்தான் என்றும் விசாரித்தான்.
	<br /><br />
	34. அதற்கு ஜனங்கள் பலவிதமாய்ச் சத்தமிட்டார்கள்; சந்தடியினாலே நிச்சயத்தை அவன் அறியக்கூடாமல், அவனைக் கோட்டைக்குள்ளே கொண்டுபோகும்படி கட்டளையிட்டான்.
	<br /><br />
	35. அவன் படிகள்மேல் ஏறினபோது ஜனக்கூட்டம் திரண்டு பின்சென்று,
	<br /><br />
	36. இவனை அகற்றும் என்று உக்கிரமாய்க் கூப்பிட்டபடியினாலே, போர்ச்சேவகர் அவனைத் தூக்கிக்கொண்டு போகவேண்டியதாயிருந்தது.
	<br /><br />
	37. அவர்கள் பவுலைக் கோட்டைக்குள்ளே கொண்டுபோகிற சமயத்தில், அவன் சேனாபதியை நோக்கி: நான் உம்முடனே ஒரு வார்த்தை பேசலாமா என்றான். அதற்கு அவன்: உனக்குக் கிரேக்குபாஷை தெரியுமா?
	<br /><br />
	38. நீ இந்த நாட்களுக்குமுன்னே கலகமுண்டாக்கி, நாலாயிரங் கொலைபாதகரை வனாந்தரத்திற்குக் கொண்டுபோன எகிப்தியன் அல்லவா என்றான்.
	<br /><br />
	39. அதற்குப் பவுல்: நான் சிலிசியா நாட்டிலுள்ள கீர்த்திபெற்ற தர்சுபட்டணத்து யூதன்; ஜனங்களுடனே பேசும்படி எனக்கு உத்தரவாகவேண்டுமென்று உம்மை வேண்டிக்கொள்ளுகிறேன் என்றான்.
	<br /><br />
	40. உத்தரவானபோது, பவுல் படிகளின்மேல் நின்று ஜனங்களைப் பார்த்துக் கையமர்த்தினான்; மிகுந்த அமைதலுண்டாயிற்று; அப்பொழுது அவன் எபிரெயு பாஷையிலே பேசத்தொடங்கினான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts21