import React from 'react';
import '../Bible.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

const OLDTESTAMENT = () => {
  return (
    <div>
    <h1 align="center">Summery of Old Testament</h1>
    <div class="tes-glass-container">
        <div className='summery'>
            <h2>ஆதியாகமம்</h2>
            <p>
            ஆதியாகமம் தொடக்கங்களைப் பற்றி பேசுகிறது மற்றும் பைபிளின் மற்ற பகுதிகளைப் புரிந்துகொள்ள அடித்தளமாக உள்ளது. கடவுளுக்கும் அவனுடைய படைப்புக்கும், கடவுளுக்கும் மனித இனத்துக்கும், மனிதர்களுக்கும் இடையே உள்ள உறவுகளைப் பற்றிப் பேசும் மிக உயர்ந்த புத்தகம்.
 </p>
            <Link to="/Aathiyagamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யாத்திராகமம்</h2>
            <p>
            அடிமைத்தனத்திற்குப் பிறகு இஸ்ரவேலர்கள் எகிப்தை விட்டு வெளியேறிய வரலாற்றை யாத்திராகமம் விவரிக்கிறது. கடவுள் தனது பெயர், அவரது பண்புகள், அவரது மீட்பு, அவரது சட்டம் மற்றும் அவர் எவ்வாறு வணங்கப்பட வேண்டும் என்பதை வெளிப்படுத்தும் ஒரு அடிப்படை இறையியலை புத்தகம் இடுகிறது.
            </p>
            <Link to="/Yathiragamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>லேவியராகமம்</h2>
            <p>
            லேவியராகமம் அதன் பெயரை செப்டுவஜின்ட் (பழைய ஏற்பாட்டின் கிறிஸ்தவத்திற்கு முந்தைய கிரேக்க மொழிபெயர்ப்பு) இலிருந்து பெறுகிறது மற்றும் "லேவியர்களைப் பற்றியது" (இஸ்ரவேலின் பாதிரியார்கள்) என்று பொருள். புனித ராஜா தனது ராஜ்யத்தின் மக்களிடையே தனது பூமிக்குரிய சிம்மாசனத்தை அமைக்க உதவும் விதிமுறைகளின் கையேடாக இது செயல்படுகிறது. அவர்கள் எப்படி அவருடைய பரிசுத்த மக்களாக இருக்க வேண்டும் மற்றும் அவரைப் பரிசுத்தமான முறையில் வணங்க வேண்டும் என்பதை அது விளக்குகிறது.
            </p>
            <Link to="/Leviaragamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எண்ணாகமம்</h2>
            <p>
            சினாய் மலையிலிருந்து கானானின் எல்லையில் உள்ள மோவாபின் சமவெளிக்கு இஸ்ரேலின் பயணத்தின் கதையை எண்கள் விவரிக்கிறது. கடவுளுடைய ஜனங்களின் முணுமுணுப்பு மற்றும் கலகத்தைப் பற்றியும், அதைத் தொடர்ந்து அவர்களுடைய நியாயத்தீர்ப்பைப் பற்றியும் புத்தகம் சொல்கிறது
            </p>
            <Link to="/Ennagamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>உபாகமம்</h2>
            <p>
            உபாகமம் ("சட்டத்தின் மறுமுறை") கடவுளுடைய மக்களுக்கு அவருடைய உடன்படிக்கையைப் பற்றி நினைவூட்டுகிறது. யோசுவாவின் வெற்றி தொடங்கும் முன் புத்தகம் ஒரு "இடைநிறுத்தம்" மற்றும் கடவுள் என்ன தேவை என்பதை நினைவூட்டுகிறது.
            </p>
            <Link to="/Ubagamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யோசுவா</h2>
            <p>
            யோசுவா என்பது கடவுளின் மக்களுக்கு வெற்றி மற்றும் நிறைவு பற்றிய கதை. எகிப்தில் பல வருடங்கள் அடிமையாக இருந்து 40 வருடங்கள் பாலைவனத்தில் வாழ்ந்த பிறகு, இஸ்ரவேலர்கள் இறுதியாக தங்கள் பிதாக்களுக்கு வாக்களிக்கப்பட்ட தேசத்திற்குள் நுழைய அனுமதிக்கப்பட்டனர்.
            </p>
            <Link to="/Joshua">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>நியாயாதிபதிகள்</h2>
            <p>
            நியாயாதிபதிகள் புத்தகம், வாக்குப்பண்ணப்பட்ட தேசத்தில் இஸ்ரவேலின் வாழ்க்கையைச் சித்தரிக்கிறது—யோசுவாவின் மரணத்திலிருந்து முடியாட்சியின் எழுச்சி வரை. இது நெருக்கடி மற்றும் விசுவாச துரோகத்தின் காலங்களில் கடவுளிடம் அவசர முறையீடுகளைப் பற்றி கூறுகிறது, தலைவர்களை (நீதிபதிகளை) உயர்த்தும்படி கர்த்தரை நகர்த்துகிறது, அவர்கள் மூலம் அவர் வெளிநாட்டு அடக்குமுறையாளர்களை தூக்கி எறிந்து நிலத்தை அமைதிக்கு மீட்டெடுக்கிறார்.
            </p>
            <Link to="/Niyayathibathigal">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ரூத்</h2>
            <p>
            ரூத்தின் புத்தகம் இதுவரை எழுதப்பட்ட குறுகிய கதைகளின் சிறந்த எடுத்துக்காட்டுகளில் ஒன்றாக அழைக்கப்படுகிறது. நவோமி மற்றும் அவரது மருமகள் ரூத் (கிங் டேவிட் மற்றும் இயேசுவின் மூதாதையர்) வீழ்ச்சி மற்றும் மறுசீரமைப்பு மூலம் நீதிபதிகளின் காலத்தில் உண்மையான நம்பிக்கை மற்றும் பக்தியின் எஞ்சியிருக்கும் கணக்கை இது முன்வைக்கிறது.
            </p>
            <Link to="/Ruth">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 சாமுவேல்</h2>
            <p>
            இஸ்ரவேலில் ஒரு மனித ராஜா தலைமையில் ஒரு அரசியல் அமைப்பை கடவுள் நிறுவியதை சாமுவேல் விவரிக்கிறார். சாமுவேலின் வாழ்க்கையின் மூலம், முடியாட்சியின் எழுச்சியையும் அதன் முதல் ராஜா சவுலின் சோகத்தையும் நாம் காண்கிறோம்.
            </p>
            <Link to="/1Samuel">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 சாமுவேல்</h2>
            <p>
            ராஜா சவுலின் தோல்விக்குப் பிறகு, 2 சாமுவேல் தாவீதை சிறந்த தேவராஜ்ய ராஜாவின் உண்மையான (அபூரணமான) பிரதிநிதியாக சித்தரிக்கிறார். தாவீதின் ஆட்சியில் கர்த்தர் தேசத்தை செழிக்கச் செய்தார், அதன் எதிரிகளை தோற்கடித்தார், அவருடைய வாக்குறுதிகளை நிறைவேற்றினார்.
            </p>
            <Link to="/2Samuel">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 இராஜாக்கள்</h2>
            <p>
            1 இராஜாக்கள் இஸ்ரேலில் முடியாட்சி மற்றும் தீர்க்கதரிசிகள் மூலம் கடவுள் ஈடுபாடு கணக்கு தொடர்கிறது. டேவிட்டிற்குப் பிறகு, அவரது மகன் சாலமன் ஐக்கிய இராச்சியத்தின் சிம்மாசனத்தில் ஏறுகிறார், ஆனால் இந்த ஒற்றுமை அவரது ஆட்சியின் போது மட்டுமே நீடிக்கும். இஸ்ரவேலிலும் யூதாவிலும் அடுத்தடுத்து வந்த ஒவ்வொரு ராஜாவும் கடவுளின் அழைப்புக்கு எவ்வாறு பதிலளிக்கிறார்கள் - அல்லது அடிக்கடி நடப்பது போல், கேட்கத் தவறுகிறார்கள் என்பதை புத்தகம் ஆராய்கிறது.
            </p>
            <Link to="/1Rajakkal">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 இராஜாக்கள்</h2>
            <p>
            2 ராஜாக்கள் யூதா மற்றும் இஸ்ரவேலின் வரலாற்றுக் கணக்கை முன்னோக்கி கொண்டு செல்கிறார்கள். ஒவ்வொரு தேசத்தின் ராஜாக்களும் கடவுளுடனான உடன்படிக்கைக்கு அவர்கள் கீழ்ப்படிந்ததன் வெளிச்சத்தில் நியாயந்தீர்க்கப்படுகிறார்கள். இறுதியில், இரு நாட்டு மக்களும் கீழ்ப்படியாமைக்காக நாடு கடத்தப்படுகிறார்கள்.
            </p>
            <Link to="/2Rajakkal">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 நாளாகமம்</h2>
            <p>
            நாடுகடத்தப்பட்ட சமூகத்தின் தேவைகளை நிவர்த்தி செய்ய கிங்ஸின் ஆசிரியர் இஸ்ரேலின் வரலாற்றை ஒழுங்கமைத்து விளக்கியது போலவே, 1 நாளாகமத்தின் எழுத்தாளர் மீட்டெடுக்கப்பட்ட சமூகத்திற்காக மற்றொரு வரலாற்றை எழுதினார்.
            </p>
            <Link to="/1Nalagamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 நாளாகமம்</h2>
            <p>
            2 நாளாகமம் நாடுகடத்தப்பட்டு திரும்பியவர்களின் மறுசீரமைப்புக்கான கண்ணோட்டத்துடன் இஸ்ரவேலின் வரலாற்றின் கணக்கை நாளாகமம் தொடர்கிறது.
            </p>
            <Link to="/2Nalagamam">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எஸ்றா</h2>
            <p>
            கடவுளின் உடன்படிக்கை மக்கள் பாபிலோனிய நாடுகடத்தலில் இருந்து உடன்படிக்கை நிலத்திற்கு எவ்வாறு தேவராஜ்ய (கடவுளின் இராஜ்ஜியம்) சமூகமாக அந்நிய ஆட்சியின் கீழ் தொடர்ந்தாலும் எப்படி மீட்டெடுக்கப்பட்டனர் என்பதை எஸ்ரா புத்தகம் விவரிக்கிறது
            </p>
            <Link to="/Ezra">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>நெகேமியா</h2>
            <p>
            எஸ்றா புத்தகத்துடன் நெருங்கிய தொடர்புடைய, நெகேமியா இந்த "மன்னன் ராஜாவிடம்" திரும்புவதையும், அவரும் மற்ற இஸ்ரவேலர்களும் தங்கள் தாயகத்தில் எதிர்கொள்ளும் சவால்களை விவரிக்கிறார்
            </p>
            <Link to="/Nehemiah">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எஸ்தர்</h2>
            <p>
            பாரசீக ராணியாகி தன் மக்களை அழிவிலிருந்து காப்பாற்றும் யூதப் பெண்ணான எஸ்தரின் வரலாற்றுக் கணக்கு மூலம் ஆண்டுதோறும் பூரிம் பண்டிகையை நடத்துவதை எஸ்தர் பதிவு செய்கிறார்.
            </p>
            <Link to="/Esther">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யோபு</h2>
            <p>
            தொடர்ச்சியான ஏகபோகங்கள் மூலம், யோபு புத்தகம் பயங்கரமான சூழ்நிலையில் துன்பப்படும் ஒரு நீதிமான் பற்றிய விவரத்தை விவரிக்கிறது. புத்தகத்தின் ஆழமான நுண்ணறிவுகள், அதன் இலக்கிய கட்டமைப்புகள் மற்றும் அதன் சொல்லாட்சியின் தரம் ஆகியவை ஆசிரியரின் மேதைமையைக் காட்டுகின்றன.
            </p>
            <Link to="/Job">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>சங்கீதம்</h2>
            <p>
            சங்கீதங்கள் பல கருப்பொருள்கள் மற்றும் சூழ்நிலைகளில் கடவுளுக்கு பல நூற்றாண்டுகள் மதிப்புள்ள பாராட்டுக்கள் மற்றும் பிரார்த்தனைகளை பிரதிநிதித்துவப்படுத்தும் பாடல்கள் மற்றும் கவிதைகள் சேகரிக்கப்பட்டுள்ளன. சங்கீதங்கள் உணர்ச்சிவசப்பட்டவை, தெளிவானவை மற்றும் உறுதியானவை; அவை உருவங்கள், உருவகம் மற்றும் உருவகம் ஆகியவற்றில் நிறைந்துள்ளன.
            </p>
            <Link to="/Sangeetham">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>நீதிமொழிகள்</h2>
            <p>
            நீதிமொழிகள் "எளிமையானவர்களுக்கு விவேகத்தையும், இளைஞர்களுக்கு அறிவையும் விவேகத்தையும்" வழங்கவும், ஞானிகளை இன்னும் ஞானமுள்ளவர்களாக மாற்றவும் எழுதப்பட்டது. "என் மகன்(கள்)" என்ற அடிக்கடி குறிப்பிடப்படும் குறிப்புகள், இளைஞர்களுக்கு அறிவுரை வழங்குவதையும், பலனளிக்கும் பலன்களை அளிக்கும் வாழ்க்கை முறையில் அவர்களை வழிநடத்துவதையும் வலியுறுத்துகின்றன.
            </p>
            <Link to="/Neethimozhigal">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>பிரசங்கி</h2>
            <p>
            பிரசங்கியின் ஆசிரியர் மனித அனுபவத்தை ஆய்வு செய்வதற்கும் மனித சூழ்நிலையை மதிப்பிடுவதற்கும் தனது ஞான சக்திகளை பயன்படுத்துகிறார். அவரது முன்னோக்கு "சூரியனுக்குக் கீழே" என்ன நடக்கிறது (அனைத்து மனித ஆசிரியர்களுடையது போல) மட்டுமே
            </p>
            <Link to="/Prasangi">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>உன்னதப்பாட்டு</h2>
            <p>
            பண்டைய இஸ்ரவேலில் மனிதர்கள் அனைத்தையும் வார்த்தைகளில் வெளிப்படுத்தினர்: மரியாதை, நன்றியுணர்வு, கோபம், துக்கம், துன்பம், நம்பிக்கை, நட்பு, அர்ப்பணிப்பு. சாலமன் பாடலில், அன்பே வார்த்தைகளைக் கண்டறிகிறது - ஈர்க்கப்பட்ட வார்த்தைகள் அதன் நேர்த்தியான வசீகரத்தையும் அழகையும் கடவுளின் சிறந்த பரிசுகளில் ஒன்றாக வெளிப்படுத்துகின்றன.
            </p>
            <Link to="/Unnathapattu">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ஏசாயா</h2>
            <p>
            ஆமோஸின் மகன் ஏசாயா பெரும்பாலும் எழுதும் தீர்க்கதரிசிகளில் மிகப் பெரியவராகக் கருதப்படுகிறார். அவருடைய பெயருக்கு "ஆண்டவர் இரட்சிக்கிறார்" என்று பொருள். ஏசாயா கடவுளின் தீர்ப்பு மற்றும் இரட்சிப்பின் முழு பரிமாணங்களையும் வெளிப்படுத்தும் ஒரு புத்தகம்
            </p>
            <Link to="/Yesaya">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எரேமியா</h2>
            <p>
            இந்த புத்தகம் எரேமியாவின் தீர்க்கதரிசன ஊழியத்தின் கணக்கைப் பாதுகாக்கிறது, அவருடைய தனிப்பட்ட வாழ்க்கை மற்றும் போராட்டங்கள் மற்ற பழைய ஏற்பாட்டு தீர்க்கதரிசிகளை விட அதிக ஆழமாகவும் விரிவாகவும் நமக்குக் காட்டப்பட்டுள்ளன.
            </p>
            <Link to="/Eramia">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>புலம்பல்</h2>
            <p>
            புலம்பல்கள் கிமு 586 இல் ஜெருசலேம் (கர்த்தருடைய ராஜ்யத்தின் அரச நகரம்) அழிக்கப்பட்டதைக் குறித்த கவிதை மற்றும் சக்திவாய்ந்த புலம்பல்களைக் கொண்டுள்ளது.
            </p>
            <Link to="/Pulambal">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எசேக்கியேல்</h2>
            <p>
            பொதுவாக பழைய ஏற்பாடு மற்றும் குறிப்பாக தீர்க்கதரிசிகள் அனைத்து படைப்புகள் மற்றும் வரலாற்றின் போக்கின் மீது கடவுளின் இறையாண்மையை முன்வைத்து கற்பிக்கின்றனர். எசேக்கியேல் தீர்க்கதரிசியின் புத்தகத்தை விட, பைபிளில் எங்கும் கடவுளின் முன்முயற்சியும் கட்டுப்பாடும் தெளிவாகவும் பரவலாகவும் வெளிப்படுத்தப்படவில்லை.
            </p>
            <Link to="/Ezekiel">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>தானியேல் </h2>
            <p>
            இஸ்ரேலின் நாடுகடத்தலின் போது தீர்க்கதரிசி தானியேல் வாழ்க்கையில் நடந்த முக்கிய நிகழ்வுகளை தானியேல் படம்பிடித்தார். அவருடைய வாழ்க்கையும் தரிசனங்களும் கடவுளின் மீட்புத் திட்டங்களையும் வரலாற்றின் இறையாண்மைக் கட்டுப்பாட்டையும் சுட்டிக்காட்டுகின்றன.
            </p>
            <Link to="/Daniel">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ஓசியா</h2>
            <p>
            பீரியின் மகன் ஓசியா தீர்க்கதரிசி வடக்கு இராச்சியத்தின் துயரமான இறுதி நாட்களில் வாழ்ந்தார். துரோக இஸ்ரவேலருக்கு கடவுள் உண்மையுள்ளவராக இருப்பதற்கான உவமையாக அவருடைய வாழ்க்கை அமைந்தது
            </p>
            <Link to="/Osiya">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யோவேல்</h2>
            <p>
            யோவேல் தீர்க்கதரிசி யூதா மக்களுக்கு கடவுளின் வரவிருக்கும் நியாயத்தீர்ப்பு மற்றும் மனந்திரும்புதலின் மூலம் வரவிருக்கும் மறுசீரமைப்பு மற்றும் ஆசீர்வாதத்தைப் பற்றி எச்சரித்தார்
            </p>
            <Link to="/Yovel">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ஆமோஸ்</h2>
            <p>
            யூதாவின் மீது உசியா (கிமு 792-740) மற்றும் இஸ்ரேல் மீது ஜெரோபெயாம் II (793-753) ஆட்சியின் போது ஆமோஸ் தீர்க்கதரிசனம் கூறினார்.
            </p>
            <Link to="/Aamos">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ஒபதியா</h2>
            <p>
            தீர்க்கதரிசி ஒபதியா ஏதோமின் பெருமைமிக்க மக்களுக்கு வரவிருக்கும் நியாயத்தீர்ப்பைப் பற்றி எச்சரித்தார்.
            </p>
            <Link to="/Obadiah">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யோனா</h2>
            <p>
            யோனா ஒரு தீர்க்கதரிசன புத்தகமாக அசாதாரணமானது, இது நினிவே நகரத்திற்கான யோனாவின் பணி, அவரது எதிர்ப்பு, ஒரு பெரிய மீனில் அவர் சிறைபிடிப்பு, நகரத்திற்கு அவரது வருகை மற்றும் அடுத்தடுத்த விளைவுகளின் விவரிப்புக் கணக்கு.
            </p>
            <Link to="/Yona">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>மீகா</h2>
            <p>
            கிமு 750 மற்றும் 686 க்கு இடையில் யூதாவின் ராஜாக்களான ஜோதாம், ஆகாஸ் மற்றும் எசேக்கியா ஆகியோரின் ஆட்சியின் போது மீகா தீர்க்கதரிசனம் உரைத்தார். இஸ்ரவேல் விசுவாச துரோக நிலையில் இருந்தது. மைக்கா தனது தலைநகரான சமாரியாவின் வீழ்ச்சியை முன்னறிவித்தார், மேலும் யூதாவின் தவிர்க்க முடியாத பாழையும் முன்னறிவித்தார்.
            </p>
            <Link to="/Meega">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>நாகூம் </h2>
            <p>
            புத்தகத்தில் "நாஹூமின் தரிசனம்" உள்ளது, அதன் பெயர் "ஆறுதல்" என்று பொருள்படும். நினிவேயின் ஒடுக்குமுறை, கொடூரம், உருவ வழிபாடு மற்றும் துன்மார்க்கத்தின் மீது ஆண்டவரின் தீர்ப்புதான் முழு புத்தகத்தின் மையப்புள்ளி.
            </p>
            <Link to="/Nahum">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ஆபகூக்</h2>
            <p>
            ஆபகூக் எரேமியாவின் சமகாலத்தவர் மற்றும் தீவிர விசுவாசம் கொண்டவர் என்பதைத் தவிர, அவரைப் பற்றி அதிகம் அறியப்படவில்லை. அவருடைய பெயரைக் கொண்ட புத்தகத்தில் தீர்க்கதரிசிக்கும் கடவுளுக்கும் இடையே அநீதி மற்றும் துன்பம் பற்றிய உரையாடல் உள்ளது
            </p>
            <Link to="/Habakkuk">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>செப்பனியா</h2>
            <p>
            செப்பனியா தீர்க்கதரிசி யூதாவில் கணிசமான சமூக நிலைப்பாட்டைக் கொண்ட ஒரு நபராக இருந்தார், மேலும் அவர் அரச பரம்பரையுடன் தொடர்புடையவராக இருக்கலாம். கடவுளின் நெருங்கி வரும் தீர்ப்பை யூதாவுக்கு அறிவிப்பதே ஆசிரியரின் நோக்கம்.
            </p>
            <Link to="/Seppaniya">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ஆகாய்</h2>
            <p>
            ஆகாய் ஒரு தீர்க்கதரிசி, அவர் சகரியாவுடன் சேர்ந்து, நாடுகடத்தப்பட்டவர்களை ஆலயத்தை மீண்டும் கட்ட ஊக்குவித்தார். அவருடைய தீர்க்கதரிசனங்கள் கீழ்ப்படியாமையின் விளைவுகளைத் தெளிவாகக் காட்டுகின்றன. மக்கள் கடவுளுக்கும் அவருடைய வீட்டிற்கும் முன்னுரிமை கொடுக்கும்போது, ​​அவர்கள் ஆசீர்வதிக்கப்படுகிறார்கள்.
            </p>
            <Link to="/Haggai">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>சகரியா </h2>
            <p>
            எரேமியா மற்றும் எசேக்கியேலைப் போலவே, சகரியா ஒரு தீர்க்கதரிசி மட்டுமல்ல, ஒரு ஆசாரிய குடும்ப உறுப்பினரும் கூட. சகரியாவின் (மற்றும் ஹாகாய்) முக்கிய நோக்கம் யூதாவின் மக்களைக் கடிந்துகொள்வதும், கோயிலின் மறுகட்டமைப்பை முடிக்க அவர்களை ஊக்குவிப்பதும் ஊக்குவிப்பதும் ஆகும்.
            </p>
            <Link to="/Sagaria">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>மல்கியா </h2>
            <p>
            "என் தூதர்" என்று பொருள்படும் மல்கியா, இஸ்ரவேலர்கள் நாடுகடத்தப்பட்டு திரும்பிய பிறகு அவர்களிடம் பேசினார். புத்தகத்தின் இறையியல் செய்தியை ஒரு வாக்கியத்தில் சுருக்கமாகக் கூறலாம்: பெரிய ராஜா தனது மக்களை நியாயந்தீர்க்க மட்டுமல்ல, அவர்களை ஆசீர்வதிக்கவும் மீட்டெடுக்கவும் வருவார்.
            </p>
            <Link to="/Malgiya">மேலும் </Link>
        </div>
    </div>
    </div>
  );
}

export default OLDTESTAMENT