import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Lamentations4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible News | புலம்பல் 4 </title>
    <meta
          name="description"
          content="Lamentations 4 | புலம்பல் 4 |
          Pulambal | Tamil Bible News | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஐயோ! பொன் மங்கி, பசும்பொன்மாறி, பரிசுத்த ஸ்தலத்தின் கற்கள் சகல வீதிகளின் முனையிலும் கொட்டுண்டுபோயிற்றே.
	<br /><br />
	2. ஐயோ! தங்கத்துக்கொப்பான விலையேறப்பெற்ற சீயோன் குமாரர் குயவனுடைய கைவேலையான மண்பாண்டங்களாய் எண்ணப்படுகிறார்களே.
	<br /><br />
	3. திமிங்கிலங்கள் முதலாய்க் கொங்கைகளை நீட்டி, தங்கள் குட்டிகளுக்குப் பால் கொடுக்கும்; என் ஜனமாகிய குமாரத்தியோ வனாந்தரத்திலுள்ள தீக்குருவியைப்போல் குரூரமாயிருக்கிறாளே.
	<br /><br />
	4. குழந்தைகளின் நாவு தாகத்தால் மேல்வாயோடே ஒட்டிக்கொண்டிருக்கிறது; பிள்ளைகள் அப்பங்கேட்கிறார்கள், அவர்களுக்குக் கொடுப்பாரில்லை.
	<br /><br />
	5. ருசியான பதார்த்தங்களைச் சாப்பிட்டவர்கள் வீதிகளில் பாழாய்க்கிடக்கிறார்கள்; இரத்தாம்பரம் உடுத்தி வளர்ந்தவர்கள் குப்பைமேடுகளை அணைத்துக் கொள்ளுகிறார்கள்.
	<br /><br />
	6. கைச்செய்கை இல்லாமல் ஒரு நிமிஷத்திலே கவிழ்க்கப்பட்ட சோதோமின் பாவத்துக்கு வந்த தண்டனையைப்பார்க்கிலும் என் ஜனமாகிய குமாரத்தியின் அக்கிரமத்துக்கு வந்த தண்டனை பெரிதாயிருக்கிறது.
	<br /><br />
	7. அவளுடைய நசரேயர் உறைந்த மழையைப்பார்க்கிலும் சுத்தமும், பாலைப்பார்க்கிலும் வெண்மையும், பவளத்தைப்பார்க்கிலும் சிவப்பும், இந்திரநீலத்தைப்பார்க்கிலும் மேனியுமாயிருந்தார்கள்.
	<br /><br />
	8. இப்பொழுதோ அவர்களுடைய முகம் கரியிலும் கறுத்துப்போயிற்று; வீதிகளில் அறியப்படார்கள்; அவர்கள் தோல் அவர்கள் எலும்புகளோடு ஒட்டிக்கொண்டு, காய்ந்த மரத்துக்கு ஒப்பாயிற்று.
	<br /><br />
	9. பசியினால் கொலையுண்டவர்களைப்பார்க்கிலும் பட்டயத்தால் கொலையுண்டவர்கள் பாக்கியவான்களாயிருக்கிறார்கள்; அவர்கள் வயலின் வரத்தில்லாமையால் குத்துண்டு, கரைந்துபோகிறார்கள்.
	<br /><br />
	10. இரக்கமுள்ள ஸ்திரீகளின் கைகள் தங்கள் பிள்ளைகளைச் சமைத்தன, என் ஜனமாகிய குமாரத்தியின் அழிவில் அவைகள் அவர்களுக்கு ஆகாரமாயின.
	<br /><br />
	11. கர்த்தர் தமது கோபத்தை நிறைவேற்றி, தமது உக்கிரகோபத்தை ஊற்றி, சீயோனில் அக்கினியைக் கொளுத்தினார்; அது அதின் அஸ்திபாரங்களைப் பட்சித்துப்போட்டது.
	<br /><br />
	12. சத்துருவும் பகைஞனும் எருசலேமின் வாசல்களுக்குள் பிரவேசிப்பான் என்கிறதைப் பூமியின் ராஜாக்களும் பூச்சக்கரத்தின் சகல குடிகளும் நம்பமாட்டாதிருந்தார்கள்.
	<br /><br />
	13. அதின் நடுவில் நீதிமான்களின் இரத்தத்தைச் சிந்தின அதின் தீர்க்கதரிசிகளின் பாவங்களினாலும், அதின் ஆசாரியர்களின் அக்கிரமங்களினாலும் இப்படி வந்தது.
	<br /><br />
	14. குருடர்போல வீதிகளில் அலைந்து, ஒருவரும் அவர்கள் வஸ்திரங்களைத் தொடக்கூடாதபடி இரத்தத்தால் கறைப்பட்டிருந்தார்கள்.
	<br /><br />
	15. விலகுங்கள், தீட்டுப்பட்டவர்களே, தொடாமல் விலகுங்கள், விலகுங்கள், என்று அவர்களை நோக்கிக் கூப்பிட்டார்கள்; மெய்யாய்ப் பறந்தோடி அலைந்து போனார்கள்; இனித் தங்கித் தரிக்கமாட்டார்கள் என்று புறஜாதிகளுக்குள்ளே சொல்லப்பட்டது.
	<br /><br />
	16. கர்த்தருடைய கோபம் அவர்களைச் சிதறடித்தது, அவர்களை இனி அவர் நோக்கார்; ஆசாரியருடைய முகத்தைப் பாராமலும் முதியோரை மதியாமலும்போனார்கள்.
	<br /><br />
	17. இன்னும் எங்களுக்குச் சகாயம் வருமென்று நாங்கள் வீணாய் எதிர்பார்த்திருந்ததினாலே எங்கள் கண்கள் பூத்துப்போயின; இரட்சிக்கமாட்டாத ஜாதிக்கு எதிர்பார்த்துக்கொண்டிருந்தோம்.
	<br /><br />
	18. நாங்கள் எங்கள் வீதிகளில் நடவாதபடிக்கு எங்கள் அடிச்சுவடுகளை வேட்டையாடினார்கள்; எங்கள் முடிவு சமீபித்தது; எங்கள் நாட்கள் நிறைவேறிப்போயின; எங்கள் முடிவு வந்துவிட்டது.
	<br /><br />
	19. எங்களைப் பின்தொடர்ந்தவர்கள் ஆகாயத்துக் கழுகுகளைப்பார்க்கிலும் வேகமாயிருந்தார்கள்; பர்வதங்கள்மேல் எங்களைப் பின்தொடர்ந்தார்கள்; வனாந்தரத்தில் எங்களுக்குப் பதிவிருந்தார்கள்.
	<br /><br />
	20. கர்த்தரால் அபிஷேகம்பண்ணப்பட்டவனும், எங்கள் நாசியின் சுவாசமாயிருந்தவனும் அவர்களுடைய படுகுழியில் அகப்பட்டான்; அவனுடைய நிழலிலே ஜாதிகளுக்குள்ளே பிழைத்திருப்போம் என்று அவனைக்குறித்துச் சொல்லியிருந்தோமே.
	<br /><br />
	21. ஊத்ஸ்தேசவாசியாகிய ஏதோம் குமாரத்தியே, சந்தோஷித்துக் களிகூரு; பாத்திரம் உன்னிடத்திற்கும் தாண்டிவரும், அப்பொழுது நீ வெறித்து, மானபங்கமாய்க் கிடப்பாய்.
	<br /><br />
	22. சீயோன் குமாரத்தியே, உன் அக்கிரமத்துக்கு வரும் தண்டனை தீர்ந்தது; அவர் இனி உன்னை அப்புறம் சிறைப்பட்டுப்போகவிடார்; ஏதோம் குமாரத்தியே, உன் அக்கிரமத்தை அவர் விசாரிப்பார்; உன் பாவங்களை வெளிப்படுத்துவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Lamentations4