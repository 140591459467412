import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah28 = () => {
  const verseNumber = 28;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 28 </title>
	<meta
          name="description"
          content="Isaiah 28 | ஏசாயா 28 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எப்பிராயீமுடைய வெறியரின் பெருமையான கிரீடத்துக்கு ஐயோ, மதுபானத்தால் மயக்கமடைந்தவர்களின் செழிப்பான பள்ளத்தாக்குடைய கொடுமுடியின்மேலுள்ள அலங்கார ஜோடிப்பு வாடிப்போகும் புஷ்பமே!
	<br /><br />
	2. இதோ, திராணியும் வல்லமையுமுடைய ஒருவன் ஆண்டவரிடத்தில் இருக்கிறான்; அவன் கல்மழைப்போலவும், சங்காரப் புசல்போலவும், புரண்டுவருகிற பெருவெள்ளம்போலவும் வந்து, கையினாலே அதைத் தரையில் தள்ளிவிடுவான்.
	<br /><br />
	3. எப்பிராயீமுடைய வெறியரின் பெருமையான கிரீடம் காலால் மிதித்துப்போடப்படும்.
	<br /><br />
	4. செழிப்பான பள்ளத்தாக்குடைய கொடுமுடியின்மேலுள்ள அலங்கார ஜோடிப்பாகிய வாடிய புஷ்பம், பருவகாலத்துக்குமுன் பழுத்ததும், காண்கிறவன் பார்த்து, அது தன் கையில் இருக்கும்போதே விழுங்குகிறதுமான முதல் கனியைப்போல இருக்கும்.
	<br /><br />
	5. அக்காலத்திலே சேனைகளின் கர்த்தர் தமது ஜனத்தில் மீதியானவர்களுக்கு மகிமையான கிரீடமாகவும், அலங்காரமான முடியாகவும்,
	<br /><br />
	6. நியாயம் விசாரிக்க உட்காருகிறவனுக்கு நியாயத்தின் ஆவியாகவும், யுத்தத்தை அதின் வாசல்மட்டும் திருப்புகிறவர்களின் பராக்கிரமமாகவும் இருப்பார்.
	<br /><br />
	7. ஆனாலும் இவர்களும் திராட்சரசத்தால் மயங்கி, மதுபானத்தால் வழி தப்பிப்போகிறார்கள்; ஆசாரியனும் தீர்க்கதரிசியும் மதுபானத்தால் மதிமயங்கி, திராட்சரசத்தால் விழுங்கப்பட்டு, சாராயத்தினால் வழிதப்பி, தீர்க்கதரிசனத்தில் மோசம்போய், நியாயந்தீர்க்கிறதில் இடறுகிறார்கள்.
	<br /><br />
	8. போஜனபீடங்களெல்லாம் வாந்தியினாலும் அசுத்தத்தினாலும் நிறைந்திருக்கிறது; சுத்தமான இடமில்லை.
	<br /><br />
	9. அவர் யாருக்கு அறிவைப் போதிப்பார்? யாருக்கு உபதேசத்தை உணர்த்துவார்? பால்மறந்தவர்களுக்கும், முலை மறக்கப்பண்ணப்பட்டவர்களுக்குமே.
	<br /><br />
	10. கற்பனையின்மேல் கற்பனையும், கற்பனையின்மேல் கற்பனையும், பிரமாணத்தின்மேல் பிரமாணமும், பிரமாணத்தின்மேல் பிரமாணமும், இங்கே கொஞ்சமும் அங்கே கொஞ்சமுமாம் என்கிறார்கள்.
	<br /><br />
	11. பரியாச உதடுகளினாலும் அந்நியபாஷையினாலும் இந்த ஜனத்தோடே பேசுவார்.
	<br /><br />
	12. இதுவே நீங்கள் இளைத்தவனை இளைப்பாறப்பண்ணத்தக்க இளைப்பாறுதல்; இதுவே ஆறுதல் என்று அவர்களோடே அவர் சொன்னாலும் கேட்கமாட்டோம் என்கிறார்கள்.
	<br /><br />
	13. ஆதலால் அவர்கள் போய், பின்னிட்டு விழுந்து, நொறுங்கும்படிக்கும், சிக்குண்டு பிடிபடும்படிக்கும், கர்த்தருடைய வார்த்தை அவர்களுக்குக் கற்பனையின்மேல் கற்பனையும், கற்பனையின்மேல் கற்பனையும், பிரமாணத்தின்மேல் பிரமாணமும், பிரமாணத்தின்மேல் பிரமாணமும், இங்கே கொஞ்சமும் அங்கே கொஞ்சமுமாக இருக்கும்.
	<br /><br />
	14. ஆகையால் எருசலேமிலுள்ள இந்த ஜனத்தை ஆளுகிற நிந்தனைக்காரரே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	15. நீங்கள்: மரணத்தோடே உடன்படிக்கையும், பாதாளத்தோடே ஒப்பந்தமும் பண்ணினோம்; வாதை பெருவெள்ளமாய்ப் புரண்டுவந்தாலும் எங்களை அணுகாது; நாங்கள் பொய்யை எங்களுக்கு அடைக்கலமாக்கி, மாயையின் மறைவிலே வந்து அடைந்தோம் என்கிறீர்களே.
	<br /><br />
	16. ஆதலால் கர்த்தராகிய ஆண்டவர் உரைக்கிறதாவது: இதோ, அஸ்திபாரமாக ஒரு கல்லை நான் சீயோனிலே வைக்கிறேன்; அது பரீட்சிக்கப்பட்டதும், விலையேறப்பெற்றதும், திட அஸ்திபாரமுள்ளதுமான மூலைக்கல்லாயிருக்கும், விசுவாசிக்கிறவன் பதறான்.
	<br /><br />
	17. நான் நியாயத்தை நூலும், நீதியைத் தூக்குநூலுமாக வைப்பேன்; பொய் என்னும் அடைக்கலத்தைக் கல்மழை அழித்துவிடும்; மறைவிடத்தை ஜலப்பிரவாகம் அடித்துக்கொண்டுபோகும்.
	<br /><br />
	18. நீங்கள் மரணத்தோடு செய்த உடன்படிக்கை விருதாவாகி, நீங்கள் பாதாளத்தோடு செய்த ஒப்பந்தம் நிற்காதேபோம்; வாதை புரண்டுவரும்போது அதின்கீழ் மிதிக்கப்படுவீர்கள்.
	<br /><br />
	19. அது புரண்டுவந்தமாத்திரத்தில் உங்களை அடித்துக்கொண்டுபோம்; அது நாள்தோறும் இரவும்பகலும் புரண்டுவரும்; அதைப்பற்றிப் பிறக்குஞ்செய்தியைக் கேட்பதும் சஞ்சலத்தை உண்டாக்கும்.
	<br /><br />
	20. கால் நீட்டப் படுக்கையின் நீளம் போதாது; மூடிக்கொள்ளப் போர்வையின் அகலமும் போதாது.
	<br /><br />
	21. கர்த்தர் தமது கிரியையாகிய அபூர்வமான கிரியையைச் செய்யவும், தமது வேலையாகிய அபூர்வமான வேலையை நிறைவேற்றவும், அவர் பெராத்சீம் மலையிலே எழும்பினதுபோல எழும்பி, கிபியோனின் பள்ளத்தாக்கில் கோபங்கொண்டதுபோல கோபங்கொள்வார்.
	<br /><br />
	22. இப்பொழுதும் உங்கள் கட்டுகள் பலத்துப்போகாதபடிக்குப் பரியாசம்பண்ணாதிருங்கள்; தேசம் அனைத்தின்மேலும் நிர்ணயிக்கப்பட்ட சங்காரத்தின் செய்தியைச் சேனைகளின் கர்த்தராகிய ஆண்டவராலே கேள்விப்பட்டிருக்கிறேன்.
	<br /><br />
	23. செவிகொடுத்து என் சத்தத்தைக் கேளுங்கள்; நான் சொல்வதைக் கவனித்துக் கேளுங்கள்.
	<br /><br />
	24. உழுகிறவன் விதைக்கிறதற்காக நாள்தோறும் உழுகிறதுண்டோ? தன் நிலத்தைக் கொத்தி நாள்தோறும் பரம்படிக்கிறது உண்டோ?
	<br /><br />
	25. அவன் அதை மேலாக நிரவினபின்பு, அததற்கேற்ற இடத்தில் உளுந்தைத் தெளித்து, சீரகத்தைத் தூவி, முதல்தரமான கோதுமையையும் தெரிந்துகொண்ட வாற்கோதுமையையும் கம்பையும் விதைக்கிறான் அல்லவோ?
	<br /><br />
	26. அவனுடைய தேவன் அவனை நன்றாய்ப் போதித்து, அவனை உணர்த்துகிறார்.
	<br /><br />
	27. உளுந்து தூலத்தாலே போரடிக்கப்படுகிறதில்லை; சீரகத்தின்மேல் வண்டிலின் உருளைச் சுற்ற விடப்படுகிறதுமில்லை; உளுந்து கோலினாலும் சீரகம் மிலாற்றினாலும் அடிக்கப்படும்.
	<br /><br />
	28. அப்பத்துக்குத் தானியம் இடிக்கப்படும்; இடைவிடாமல் அவன் அதைப் போரடிக்கிறதில்லை; அவன் தன் வண்டிலின் உருளையால் அதை நசுக்குகிறதுமில்லை, தன் குதிரைகளால் அதை நொறுக்குகிறதுமில்லை.
	<br /><br />
	29. இதுவும் சேனைகளின் கர்த்தராலே உண்டாகிறது; அவர் ஆலோசனையில் ஆச்சரியமானவர், செயலில் மகத்துவமானவர்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah28