import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah26 = () => {
  const verseNumber = 26;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 26 </title>
	<meta
          name="description"
          content="Isaiah 26 | ஏசாயா 26 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே யூதாதேசத்தில் பாடப்படும் பாட்டாவது: பெலனான நகரம் நமக்கு உண்டு; இரட்சிப்பையே அதற்கு மதிலும் அரணுமாக ஏற்படுத்துவார்.
	<br /><br />
	2. சத்தியத்தைக் கைக்கொண்டுவருகிற நீதியுள்ள ஜாதி உள்ளே பிரவேசிப்பதற்காக வாசல்களைத் திறவுங்கள்.
	<br /><br />
	3. உம்மை உறுதியாய்ப் பற்றிக்கொண்ட மனதையுடையவன் உம்மையே நம்பியிருக்கிறபடியால், நீர் அவனைப் பூரண சமாதானத்துடன் காத்துக்கொள்வீர்.
	<br /><br />
	4. கர்த்தரை என்றென்றைக்கும் நம்புங்கள்; கர்த்தராகிய யேகோவா நித்திய கன்மலையாயிருக்கிறார்.
	<br /><br />
	5. அவர் உயரத்திலே வாசமாயிருக்கிறவர்களையும் கீழே தள்ளுகிறார், உயர்ந்த நகரத்தையும் தாழ்த்துகிறார்; அவர் தரைமட்டும் தாழ்த்தி அது மண்ணாகுமட்டும் இடியப்பண்ணுவார்.
	<br /><br />
	6. கால் அதை மிதிக்கும், சிறுமையானவர்களின் காலும் எளிமையானவர்களின் அடிகளுமே அதை மிதிக்கும்.
	<br /><br />
	7. நீதிமானுடைய பாதை செம்மையாயிருக்கிறது; மகா நீதிபரராகிய நீர் நீதிமானுடைய பாதையைச் செம்மைப்படுத்துகிறீர்.
	<br /><br />
	8. கர்த்தாவே, உம்முடைய நியாயத்தீர்ப்புகளின் வழியிலே உமக்குக் காத்திருக்கிறோம்; உமது நாமமும், உம்மை நினைக்கும் நினைவும் எங்கள் ஆத்தும வாஞ்சையாயிருக்கிறது.
	<br /><br />
	9. என் ஆத்துமா இரவிலே உம்மை வாஞ்சிக்கிறது; எனக்குள் இருக்கிற என் ஆவியால் அதிகாலையிலும் உம்மைத் தேடுகிறேன்; உம்முடைய நியாயத்தீர்ப்புகள் பூமியிலே நடக்கும்போது பூச்சக்கரத்துக்குடிகள் நீதியைக் கற்றுக்கொள்வார்கள்.
	<br /><br />
	10. துன்மார்க்கனுக்குத் தயைசெய்தாலும் நீதியைக் கற்றுக்கொள்ளான்; நீதியுள்ள தேசத்திலும் அவன் அநியாயஞ்செய்து கர்த்தருடைய மகத்துவத்தைக் கவனியாதேபோகிறான்.
	<br /><br />
	11. கர்த்தாவே, உமது கை ஓங்கியிருக்கிறது, அவர்கள் அதைக் காணாதிருக்கிறார்கள்; ஆனாலும் உமது ஜனத்துக்காக நீர் கொண்ட வைராக்கியத்தைக்கண்டு வெட்கப்படுவார்கள்; அக்கினி உம்முடைய சத்துருக்களைப் பட்சிக்கும்.
	<br /><br />
	12. கர்த்தாவே, எங்களுக்குச் சமாதானத்தைக் கட்டளையிடுவீர்; எங்கள் கிரியைகளையெல்லாம் எங்களுக்காக நடத்திவருகிறவர் நீரே.
	<br /><br />
	13. எங்கள் தேவனாகிய கர்த்தாவே, உம்மையல்லாமல் வேறே ஆண்டவன்மார் எங்களை ஆண்டார்கள்; இனி உம்மைமாத்திரம் சார்ந்து உம்முடைய நாமத்தைப் பிரஸ்தாபப்படுத்துவோம்.
	<br /><br />
	14. அவர்கள் செத்தவர்கள், ஜீவிக்கமாட்டார்கள்; மாண்ட ராட்சதர் திரும்ப எழுந்திரார்கள்; நீர் அவர்களை விசாரித்துச் சங்கரித்து, அவர்கள் பேரையும் அழியப்பண்ணினீர்.
	<br /><br />
	15. இந்த ஜாதியைப் பெருகப்பண்ணினீர்; கர்த்தாவே, இந்த ஜாதியைப் பெருகப்பண்ணினீர்; நீர் மகிமைப்பட்டீர், தேசத்தின் எல்லை எல்லாவற்றையும் நெடுந்தூரத்தில் தள்ளிவைத்தீர்.
	<br /><br />
	16. கர்த்தாவே, நெருக்கத்தில் உம்மைத் தேடினார்கள்; உம்முடைய தண்டனை அவர்கள்மேலிருக்கையில் அந்தரங்க வேண்டுதல் செய்தார்கள்.
	<br /><br />
	17. கர்த்தாவே, பேறுகாலம் சமீபித்திருக்கையில் வேதனைப்பட்டு, தன் அம்பாயத்தில் கூப்பிடுகிற கர்ப்பவதியைப்போல, உமக்கு முன்பாக இருக்கிறோம்.
	<br /><br />
	18. நாங்கள் கர்ப்பமாயிருந்து வேதனைப்பட்டு, காற்றைப் பெற்றவர்களைப்போல் இருக்கிறோம்; தேசத்தில் ஒரு இரட்சிப்பையும் செய்யமாட்டாதிருக்கிறோம்; பூச்சக்கரத்துக்குடிகள் விழுகிறதுமில்லை.
	<br /><br />
	19. மரித்த உம்முடையவர்கள் பிரேதமான என்னுடையவர்களோடே கூட எழுந்திருப்பார்கள்; மண்ணிலே தங்கியிருக்கிறவர்களே, விழித்துக் கெம்பீரியுங்கள்; உம்முடைய பனி பூண்டுகளின்மேல் பெய்யும் பனிபோல் இருக்கும்; மரித்தோரைப் பூமி புறப்படப்பண்ணும்.
	<br /><br />
	20. என் ஜனமே, நீ போய் உன் அறைகளுக்குள்ளே பிரவேசித்து, உன் கதவுகளைப் பூட்டிக்கொண்டு, சினம் கடந்துபோகுமட்டும் கொஞ்சநேரம் ஒளித்துக்கொள்.
	<br /><br />
	21. இதோ, பூமியினுடைய குடிகளின் அக்கிரமத்தினிமித்தம் அவர்களை விசாரிக்கும்படி கர்த்தர் தம்முடைய ஸ்தானத்திலிருந்து புறப்பட்டுவருவார்; பூமி தன் இரத்தப்பழிகளை வெளிப்படுத்தி, தன்னிடத்தில் கொலைசெய்யப்பட்டவர்களை இனி மூடாதிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah26