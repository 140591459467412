import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 5 </title>
	<meta
          name="description"
          content="Isaiah 5 | ஏசாயா 5 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்பொழுது நான் என் நேசரிடத்தில் அவருடைய திராட்சத்தோட்டத்தைக் குறித்து என் நேசருக்கேற்ற ஒரு பாட்டைப் பாடுவேன்; என் நேசருக்கு மகா செழிப்பான மேட்டிலே ஒரு திராட்சத்தோட்டம் உண்டு.
	<br /><br />
	2. அவர் அதை வேலியடைத்து, அதிலுள்ள கற்களைப் பொறுக்கி, அதிலே நற்குல திராட்சச்செடிகளை நட்டு, அதின் நடுவில் ஒரு கோபுரத்தைக்கட்டி, அதில் ஆலையையும் உண்டுபண்ணி, அது நல்ல திராட்சப்பழங்களைத் தருமென்று காத்திருந்தார்; அதுவோ கசப்பான பழங்களைத் தந்தது.
	<br /><br />
	3. எருசலேமின் குடிகளே, யூதாவின் மனுஷரே, எனக்கும் என் திராட்சத்தோட்டத்துக்கும் நியாயந்தீருங்கள்.
	<br /><br />
	4. நான் என் திராட்சத்தோட்டத்திற்காகச் செய்யாத எந்த வேலையை அதற்கு இனிச் செய்யலாம்? அது நல்ல திராட்சப்பழங்களைத் தருமென்று நான் காத்திருக்க, அது கசப்பான பழங்களைத் தந்ததென்ன?
	<br /><br />
	5. இப்போதும் நான் என் திராட்சத்தோட்டத்துக்குச் செய்வதை உங்களுக்கு அறிவிப்பேன்; அதின் வேலியை எடுத்துப்போடுவேன், அது மேய்ந்துபோடப்படும்; அதின் அடைப்பைத் தகர்ப்பேன், அது மிதியுண்டுபோம்.
	<br /><br />
	6. அதைப் பாழாக்கிவிடுவேன்; அதின் கிளை நறுக்கப்படாமலும், களைகொத்தி எடுக்கப்படாமலும் போவதினால், முட்செடியும் நெரிஞ்சிலும் முளைக்கும்; அதின்மேல் மழை பெய்யாதபடிக்கு மேகங்களுக்கும் கட்டளையிடுவேன் என்கிறார்.
	<br /><br />
	7. சேனைகளின் கர்த்தருடைய திராட்சத்தோட்டம் இஸ்ரவேலின் வம்சமே; அவருடைய மனமகிழ்ச்சியின் நாற்று யூதாவின் மனுஷரே; அவர் நியாயத்துக்குக் காத்திருந்தார், இதோ, கொடுமை; நீதிக்குக் காத்திருந்தார், இதோ, முறைப்பாடு.
	<br /><br />
	8. தாங்கள்மாத்திரம் தேசத்தின் நடுவில் வாசமாயிருக்கும்படி மற்றவர்களுக்கு இடமில்லாமற்போகுமட்டும், வீட்டோடே வீட்டைச் சேர்த்து, வயலோடே வயலைக் கூட்டுகிறவர்களுக்கு ஐயோ!
	<br /><br />
	9. சேனைகளின் கர்த்தர் என் செவிகேட்கச் சொன்னது: மெய்யாகவே அந்தத் திரளான வீடுகள் பாழாகும்; பெரியவைகளும் நேர்த்தியானவைகளுமாகிய வீடுகள் குடியில்லாதிருக்கும்.
	<br /><br />
	10. பத்தேர் நிலமாகிய திராட்சத்தோட்டம் ஒரேபடி ரசம் தரும்; ஒரு கல விதை ஒரு குறுணி விளையும்.
	<br /><br />
	11. சாராயத்தை நாடி அதிகாலமே எழுந்து, மதுபானம் தங்களைச் சூடாக்கும்படி தரித்திருந்து, இருட்டிப்போகுமளவும் குடித்துக்கொண்டேயிருக்கிறவர்களுக்கு ஐயோ!
	<br /><br />
	12. அவர்கள் சுரமண்டலத்தையும், தம்புருவையும், மேளத்தையும், நாகசுரத்தையும், மதுபானத்தையும் வைத்து விருந்துகொண்டாடுகிறார்கள்; ஆனாலும் கர்த்தரின் கிரியையை நோக்குகிறதுமில்லை; அவர் கரத்தின் செய்கையைச் சிந்திக்கிறதுமில்லை.
	<br /><br />
	13. என் ஜனங்கள் அறிவில்லாமையினால் சிறைப்பட்டுப்போகிறார்கள்; அவர்களில் கனமுள்ளவர்கள் பட்டினியினால் தொய்ந்துபோகிறார்கள்; அவர்களுடைய திரளான கூட்டத்தார் தாகத்தால் நாவறண்டுபோகிறார்கள்.
	<br /><br />
	14. அதினிமித்தம் பாதாளம் தன்னை விரிவாக்கி, தன் வாயை ஆவென்று மட்டில்லாமல் திறந்தது; அவர்கள் மகிமையும், அவர்கள் திரள்கூட்டமும், அவர்கள் ஆடம்பரமும், அவர்களில் களிகூருகிறவர்களும் அதற்குள் இறங்கிப்போவார்கள்.
	<br /><br />
	15. சிறியவன் தாழ்த்தப்படுவான், பெரியவனும் தாழ்ச்சியடைவான்; மேட்டிமையானவர்களின் கண்கள் தாழ்ந்துபோம்.
	<br /><br />
	16. சேனைகளின் கர்த்தர் நியாயத்தீர்ப்பினால் உயர்ந்து, பரிசுத்தமுள்ள தேவன் நீதியினால் பரிசுத்தராய் விளங்குவார்.
	<br /><br />
	17. அப்பொழுது ஆட்டுக்குட்டிகள் கண்டவிடமெல்லாம் மேயும்; கொழுத்தவர்களுடையதாயிருந்து பாழாய்ப்போன நிலங்களைப் பரதேசிகள் அநுபவிப்பார்கள்.
	<br /><br />
	18. மாயையின் கயிறுகளால் அக்கிரமத்தையும், வண்டியின் வடங்களால் பாவத்தையும் இழுத்துக்கொண்டு வந்து,
	<br /><br />
	19. நாம் பார்க்கும்படி அவர் தீவிரித்துத் தமது கிரியையைச் சீக்கிரமாய் நடப்பிக்கட்டுமென்றும், இஸ்ரவேலின் பரிசுத்தருடைய ஆலோசனையை நாம் அறியும்படி அது சமீபித்து வரட்டுமென்றும் சொல்லுகிறவர்களுக்கு ஐயோ!
	<br /><br />
	20. தீமையை நன்மையென்றும், நன்மையைத் தீமையென்றும் சொல்லி, இருளை வெளிச்சமும், வெளிச்சத்தை இருளுமாகப் பாவித்து, கசப்பைத் தித்திப்பும், தித்திப்பைக் கசப்புமென்று சாதிக்கிறவர்களுக்கு ஐயோ!
	<br /><br />
	21. தங்கள் பார்வைக்கு ஞானிகளும், தங்கள் எண்ணத்துக்குப் புத்திமான்களுமாய் இருக்கிறவர்களுக்கு ஐயோ!
	<br /><br />
	22. சாராயத்தைக் குடிக்க வீரரும், மதுவைக் கலந்துவைக்கப் பராக்கிரமசாலிகளுமாயிருந்து,
	<br /><br />
	23. பரிதானத்திற்காகக் குற்றவாளியை நீதிமானாகத் தீர்த்து, நீதிமானின் நியாயத்தை அவனுக்கு விரோதமாய்ப் புரட்டுகிறவர்களுக்கு ஐயோ!
	<br /><br />
	24. இதினிமித்தம் அக்கினிஜுவாலை வைக்கோலைப் பட்சிப்பதுபோலவும், செத்தையானது நெருப்புக்கு இரையாகி எரிந்துபோவதுபோலவும், அவர்கள் வேர் வாடி, அவர்கள் துளிர் தூசியைப்போல் பறந்துபோகும்; அவர்கள் சேனைகளின் கர்த்தருடைய வேதத்தை வெறுத்து, இஸ்ரவேலிலுள்ள பரிசுத்தருடைய வசனத்தை அசட்டைபண்ணினார்களே.
	<br /><br />
	25. ஆகையால் கர்த்தருடைய கோபம் தமது ஜனங்களுக்கு விரோதமாய் மூண்டது; அவர் தமது கையை அவர்களுக்கு விரோதமாய் நீட்டி, பர்வதங்கள் அதிரத்தக்கதாயும், அவர்கள் பிணங்கள் நடுவீதிகளில் குப்பைபோலாகத்தக்கதாயும், அவர்களை அடித்தார்; இவை எல்லாவற்றிலும் அவருடைய கோபம் ஆறாமல், இன்னும் அவருடைய கை நீட்டினபடியே இருக்கிறது.
	<br /><br />
	26. அவர் தூரத்திலுள்ள ஜாதியாருக்கு ஒரு கொடியை ஏற்றி, அவர்களைப் பூமியின் கடையாந்தரங்களிலிருந்து பயில்காட்டி அழைப்பார்; அப்பொழுது அவர்கள் தீவிரமும் வேகமுமாய் வருவார்கள்.
	<br /><br />
	27. அவர்களில் விடாய்த்தவனும் இடறுகிறவனும் இல்லை; தூங்குகிறவனும் உறங்குகிறவனும் இல்லை; அவர்களில் ஒருவனுடைய இடுப்பின் கச்சை அவிழ்வதும், பாதரட்சைகளின் வார் அறுந்துபோவதும் இல்லை.
	<br /><br />
	28. அவர்கள் அம்புகள் கூர்மையும், அவர்கள் வில்லுகளெல்லாம் நாணேற்றினவைகளும், அவர்கள் குதிரைகளின் குளம்புகள் கற்பாறையாக எண்ணப்பட்டவைகளும், அவர்கள் உருளைகள் சுழல்காற்றுக்கு ஒத்தவைகளுமாயிருக்கும்.
	<br /><br />
	29. அவர்கள் கெர்ச்சிப்பு சிங்கத்தின் கெர்ச்சிப்புபோலிருக்கிறது; பாலசிங்கங்களைப்போலக் கெர்ச்சித்து, உறுமி, இரையைப் பிடித்து, தப்புவிக்கிறவன் இல்லாமல், அதை எடுத்துக்கொண்டு போய்விடுவார்கள்.
	<br /><br />
	30. அந்நாளில், சமுத்திரம் இரைவதுபோல் அவர்களுக்கு விரோதமாய் இரைவார்கள்; அப்பொழுது தேசத்தைப்பார்த்தால், இதோ, அந்தகாரமும் வியாகுலமும் உண்டு; அதின் மேகங்களினால் வெளிச்சம் இருண்டுபோம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah5