import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 7 </title>
	<meta
          name="description"
          content="Zechariah 7 | சகரியா 7 | Sageria 7 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தரியு ராஜா அரசாண்ட நாலாம் வருஷம், கிஸ்லே என்னும் ஒன்பதாம் மாதம், நாலாந்தேதியிலே, சகரியாவுக்குக் கர்த்தருடைய வார்த்தை உண்டாயிற்று.
	<br /><br />
	2. கர்த்தருடைய சமுகத்தில் விண்ணப்பம்பண்ணவும்,
	<br /><br />
	3. நாங்கள் இத்தனை வருஷம்வரையிலே செய்ததுபோல ஐந்தாம் மாதத்திலே அழுது ஒடுக்கத்திலிருக்கவேண்டுமோ என்று சேனைகளுடைய கர்த்தரின் ஆலயத்திலிருக்கும் ஆசாரியரிடத்திலும் தீர்க்கதரிசிகளிடத்திலும் கேட்கவும், சரேத்சேரும் ரெகெம்மெலேகும் அவனுடைய மனுஷரும் தேவனுடைய ஆலயத்துக்கு அனுப்பப்பட்டார்கள்.
	<br /><br />
	4. அப்பொழுது சேனைகளுடைய கர்த்தரின் வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	5. நீ தேசத்தின் எல்லா ஜனத்தோடும் ஆசாரியர்களோடும் சொல்லவேண்டியது என்னவென்றால், நீங்கள் இந்த எழுபது வருஷமாக ஐந்தாம் மாதத்திலும் ஏழாம் மாதத்திலும் உபவாசம்பண்ணி துக்கங்கொண்டாடினபோது நீங்கள் எனக்கென்றுதானா உபவாசம்பண்ணினீர்கள்?
	<br /><br />
	6. நீங்கள் புசிக்கிறபோதும் குடிக்கிறபோதும் உங்களுக்கென்றல்லவா புசிக்கிறீர்கள்? உங்களுக்கென்றல்லவா குடிக்கிறீர்கள்?
	<br /><br />
	7. எருசலேமும் அதைச் சுற்றிலும் இருந்த பட்டணங்களும் குடிநிறைந்து சுகமாயிருந்த காலத்திலும் தெற்கு நாடும் சமபூமியும் குடியேறியிருந்த காலத்திலும் முன்னிருந்த தீர்க்கதரிசிகளைக்கொண்டு கர்த்தர் கூறின வார்த்தைகள் இவைகள் அல்லவோ என்று சொல் என்றார்.
	<br /><br />
	8. பின்பு கர்த்தருடைய வார்த்தை சகரியாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	9. சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால், நீங்கள் உண்மையாய் நியாயந்தீர்த்து, அவனவன் தன்தன் சகோதரனுக்குத் தயவும் இரக்கமும் செய்து,
	<br /><br />
	10. விதவையையும் திக்கற்ற பிள்ளையையும் பரதேசியையும் சிறுமையானவனையும் ஒடுக்காமலும், உங்களில் ஒருவனும் தன் சகோதரனுக்கு விரோதமாய்த் தன் இருதயத்தில் தீங்கு நினையாமலும் இருங்கள் என்றார்.
	<br /><br />
	11. அவர்களோ கவனிக்க மனதில்லாமல், தங்கள் தோளை முரட்டுத்தனமாய் விலக்கி, கேளாதபடிக்குத் தங்கள் செவிகளை அடைத்துக்கொண்டார்கள்.
	<br /><br />
	12. வேதத்தையும் சேனைகளின் கர்த்தர் தம்முடைய ஆவியின் மூலமாய் முந்தின தீர்க்கதரிசிகளைக்கொண்டு சொல்லியனுப்பின வார்த்தைகளையும் கேளாதபடிக்குத் தங்கள் இருதயத்தை வைரமாக்கினார்கள்; ஆகையால் மகா கடுங்கோபம் சேனைகளின் கர்த்தரிடத்திலிருந்து உண்டாயிற்று.
	<br /><br />
	13. ஆதலால் நான் கூப்பிட்டபோது, அவர்கள் எப்படிக் கேளாமற்போனார்களோ, அப்படியே அவர்கள் கூப்பிட்டபோது நானும் கேளாமலிருந்தேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. அவர்கள் அறியாத புறஜாதிகளுக்குள்ளே அவர்களைப் பறக்கடித்தேன்; அதினால் அவர்கள் பின்வைத்துப்போன தேசம் போக்குவரத்தில்லாமல் பாழாய்ப்போயிற்று; அவர்கள் இன்பமான தேசத்தைப் பாழாய்ப்போகப் பண்ணினார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah7