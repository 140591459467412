import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial32 = () => {
  const verseNumber = 32;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 32 </title>
	<meta
          name="description"
          content="Ezekiel 32 | எசேக்கியேல்  32 |
          Ezekiel 32 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பன்னிரண்டாம் வருஷம் பன்னிரண்டாம் மாதம் முதலாந்தேதியிலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ எகிப்தின் ராஜாவாகிய பார்வோனைக்குறித்துப் புலம்பி, அவனோடே சொல்லவேண்டியது என்னவென்றால்: ஜாதிகளுக்குள்ளே நீ பாலசிங்கத்துக்கு ஒப்பானவன்; நீ பெருந்தண்ணீர்களில் முதலையைப்போல் இருந்து, உன் நதிகளில் எழும்பி, உன் கால்களால் தண்ணீர்களைக் கலக்கி, அவைகளின் ஆறுகளைக் குழப்பிவிட்டாய்.
	<br /><br />
	3. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்: நான் வெகு ஜனக்கூட்டத்தைக்கொண்டு உன்மேல் என் வலையை வீசுவேன்; அவர்கள் என் வலையில் உன்னை இழுத்துக்கொள்வார்கள்.
	<br /><br />
	4. உன்னைத் தரையிலே போட்டுவிடுவேன்; நான் உன்னை வெட்டவெளியில் எறிந்துவிட்டு, ஆகாயத்துப் பறவைகளையெல்லாம் உன்மேல் இறங்கப்பண்ணி, பூமியனைத்தின் மிருகங்களையும் உன்னால் திருப்தியாக்கி,
	<br /><br />
	5. உன் சதையைப் பர்வதங்களின்மேல் போட்டு, உன் உடலினாலே பள்ளத்தாக்குகளை நிரப்பி,
	<br /><br />
	6. நீ நீந்தின தேசத்தின்மேல் உன் இரத்தத்தைப் பர்வதங்கள்மட்டும் பாயச்செய்வேன்; ஆறுகள் உன்னாலே நிரம்பும்.
	<br /><br />
	7. உன்னை நான் அணைத்துப்போடுகையில், வானத்தை மூடி, அதின் நட்சத்திரங்களை இருண்டுபோகப்பண்ணுவேன்; சூரியனை மேகத்தினால் மூடுவேன், சந்திரனும் தன் ஒளியைக்கொடாதிருக்கும்.
	<br /><br />
	8. நான் வானஜோதியான விளக்குகளையெல்லாம் உன்மேல் இருண்டுபோகப்பண்ணி, உன் தேசத்தின்மேல் அந்தகாரத்தை வரப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	9. உன் சங்காரத்தை ஜாதிகள்மட்டும், நீ அறியாத தேசங்கள்மட்டும் நான் எட்டப்பண்ணுகையில், அநேகம் ஜனங்களின் இருதயத்தை விசனமடையப்பண்ணுவேன்.
	<br /><br />
	10. அநேகம் ஜனங்களை உன்னிமித்தம் திகைக்கப்பண்ணுவேன்; அவர்களின் ராஜாக்கள், தங்கள் முகங்களுக்கு முன்பாக என் பட்டயத்தை நான் வீசுகையில் மிகவும் திடுக்கிடுவார்கள்; நீ விழும் நாளில் அவரவர் தம்தம் பிராணனுக்காக நிமிஷந்தோறும் தத்தளிப்பார்கள்.
	<br /><br />
	11. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: பாபிலோன் ராஜாவின் பட்டயம் உன்மேல் வரும்.
	<br /><br />
	12. பராக்கிரமசாலிகளின் பட்டயங்களால் உன் ஜனத்திரளை விழப்பண்ணுவேன்; அவர்களெல்லாரும் ஜாதிகளில் வல்லமையானவர்கள்; அவர்கள் எகிப்தின் ஆடம்பரத்தைக் கெடுப்பார்கள்; அதின் ஏராளமான கூட்டம் அழிக்கப்படும்.
	<br /><br />
	13. திரளான தண்ணீர்களின் கரைகளில் நடமாடுகிற அதின் மிருகஜீவன்களையெல்லாம் அழிப்பேன்; இனி மனுஷனுடைய கால் அவைகளைக் கலக்குவதுமில்லை, மிருகங்களுடைய குளம்புகள் அவைகளைக் குழப்புவதுமில்லை.
	<br /><br />
	14. அப்பொழுது அவர்களுடைய தண்ணீர்களைத் தெளியப்பண்ணி, அவர்கள் ஆறுகளை எண்ணெயைப்போல் ஓடப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. நான் எகிப்துதேசத்தைப் பாழாக்கும்போதும், தேசம் தன் நிறைவை இழந்து வெறுமையாய்க் கிடக்கும்போதும், நான் அதில் குடியிருக்கிற யாவரையும் சங்கரிக்கும்போதும், நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	16. இது புலம்பல்; இப்படிப் புலம்புவார்கள்; இப்படி ஜாதிகளின் குமாரத்திகள் புலம்புவார்கள்; இப்படி எகிப்துக்காகவும், அதினுடைய எல்லாத் திரளான ஜனங்களுக்காகவும் புலம்புவார்கள் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	17. பின்னும் பன்னிரண்டாம் வருஷம் அந்த மாதத்தின் பதினைந்தாந்தேதியிலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	18. மனுபுத்திரனே, நீ எகிப்தினுடைய ஏராளமான ஜனத்தினிமித்தம் புலம்பி, அவர்களையும் பிரபலமான ஜாதிகளின் குமாரத்திகளையும் குழியில் இறங்கினவர்கள் அண்டையிலே பூமியின் தாழ்விடங்களில் தள்ளிவிடு.
	<br /><br />
	19. மற்றவர்களைப்பார்க்கிலும் நீ அழகில் சிறந்தவளோ? நீ இறங்கி, விருத்தசேதனமில்லாதவர்களிடத்தில் கிட.
	<br /><br />
	20. அவர்கள் பட்டயத்தால் வெட்டுண்டவர்களின் நடுவிலே விழுவார்கள், பட்டயத்துக்கு ஒப்புக்கொடுக்கப்பட்டிருக்கிறார்கள்; அவளையும் அவளுடைய ஜனத்திரள் யாவையும் பிடித்திழுங்கள்.
	<br /><br />
	21. பராக்கிரமசாலிகளில் வல்லவர்களும், அவனுக்குத் துணைநின்றவர்களும், பாதாளத்தின் நடுவிலிருந்து அவனோடே பேசுவார்கள்; அவர்கள் விருத்தசேதனமில்லாதவர்களாய்ப் பட்டயத்தால் வெட்டுண்டு, இறங்கி, அங்கே கிடக்கிறார்கள்.
	<br /><br />
	22. அங்கே அசூரும் அவனுடைய எல்லாக் கூட்டத்தாரும் கிடக்கிறார்கள்; அவனைச் சுற்றிலும் அவர்களுடைய பிரேதக்குழிகள் இருக்கிறது; அவர்கள் எல்லாரும் பட்டயத்தால் வெட்டுண்டு விழுந்தவர்கள்தானே.
	<br /><br />
	23. பாதாளத்தின் பக்கங்களில் அவர்களுடைய பிரேதக்குழிகள் இருக்கிறது; அவனுடைய பிரேதக்குழியைச் சுற்றிலும் அவனுடைய கூட்டம் கிடக்கிறது, ஜீவனுள்ளோருடைய தேசத்திலே கெடியுண்டாக்கின அவர்களெல்லாரும் பட்டயத்தால் வெட்டுண்டு விழுந்தவர்கள்தானே.
	<br /><br />
	24. அங்கே ஏலாமும் அவனுடைய பிரேதக்குழியைச் சுற்றிலும் அவனுடைய எல்லா ஏராளமான ஜனமும் கிடக்கிறார்கள்; அவர்களெல்லாரும் பட்டயத்தால் வெட்டுண்டு விழுந்து, விருத்தசேதனமில்லாதவர்களாய்ப் பூமியின் தாழ்விடங்களில் இறங்கினார்கள்; ஜீவனுள்ளோருடைய தேசத்திலே கெடியுண்டாக்கின அவர்கள், குழியில் இறங்கினவர்களோடேகூடத் தங்கள் அவமானத்தைச் சுமக்கிறார்கள்.
	<br /><br />
	25. வெட்டுண்டவர்களின் நடுவே அவனை அவனுடைய எல்லா ஏராளமான ஜனத்துக்குள்ளும் கிடத்தினார்கள்; அவனைச் சுற்றிலும் அவர்களுடைய பிரேதக்குழிகள் இருக்கிறது; அவர்களெல்லாரும் பட்டயத்தால் வெட்டுண்ட விருத்தசேதனமில்லாதவர்கள்; ஜீவனுள்ளோருடைய தேசத்திலே அவர்கள் கெடியுண்டாக்கினவர்களாயிருந்தும், அவர்கள் குழியில் இறங்கினவர்களோடேகூடத் தங்கள் அவமானத்தைச் சுமக்கிறார்கள்; அவன் வெட்டுண்டவர்களின் நடுவே வைக்கப்பட்டிருக்கிறான்.
	<br /><br />
	26. அங்கே மேசேக்கும் தூபாலும் அவர்களுடைய ஏராளமான ஜனங்களும் கிடக்கிறார்கள்; அவர்களைச்சுற்றிலும் அவர்களுடைய பிரேதக்குழிகள் இருக்கிறது; அவனுடைய ஜீவனுள்ளோருடைய தேசத்திலே கெடியுண்டாக்கினவர்களாயிருந்தும், அவர்களெல்லாரும் விருத்தசேதனமில்லாதவர்கள்; பட்டயத்தால் வெட்டுண்டு விழுவார்கள்.
	<br /><br />
	27. ஜீவனுள்ளோருடைய தேசத்திலே பராக்கிரமசாலிகளுக்குக் கெடியுண்டாக்குகிறவர்களாயிருந்தும், அவர்கள் விருத்தசேதனமில்லாதவர்களாய் விழுந்து, தங்கள் யுத்த ஆயுதங்களோடு பாதாளத்திலிறங்கின பராக்கிரமசாலிகளோடே இவர்கள் கிடப்பதில்லை; அவர்கள் தங்கள் பட்டயங்களைத் தங்கள் தலைகளின்கீழ் வைத்தார்கள்; ஆனாலும் அவர்களுடைய அக்கிரமம் தங்கள் எலும்புகளின்மேல் இருக்கும்.
	<br /><br />
	28. நீயும் விருத்தசேதனமில்லாதவர்களின் நடுவே நொறுங்குண்டு, பட்டயத்தால் வெட்டுண்டவர்களோடே கிடப்பாய்.
	<br /><br />
	29. அங்கே ஏதோமும் அதின் ராஜாக்களும் அதின் எல்லாப் பிரபுக்களும் கிடக்கிறார்கள்; பட்டயத்தால் வெட்டுண்டவர்களிடத்தில் இவர்கள் தங்கள் வல்லமையோடுங்கூடக் கிடத்தப்பட்டார்கள்; இவர்கள் விருத்தசேதனமில்லாதவர்களிடத்திலும், குழியில் இறங்குகிறவர்களிடத்திலும் கிடக்கிறார்கள்.
	<br /><br />
	30. அங்கே வடதிசை அதிபதிகள் அனைவரும் எல்லாச் சீதோனியரும் கிடக்கிறார்கள்; இவர்கள் கெடியுண்டாக்குகிறவர்களாயிருந்தாலும் தங்கள் பராக்கிரமத்தைக் குறித்து வெட்கப்பட்டு, வெட்டுண்டவர்களிடத்தில் இறங்கி, பட்டயத்தால் வெட்டுண்டவர்களோடே விருத்தசேதனமில்லாதவர்களாய்க் கிடந்து, குழியில் இறங்கினவர்களிடத்தில் தங்கள் அவமானத்தைச் சுமந்துகொண்டிருக்கிறார்கள்.
	<br /><br />
	31. பார்வோன் அவர்களைப் பார்த்து தன் ஏராளமான ஜனத்தின்பேரிலும் ஆறுதலடைவான்; பட்டயத்தால் வெட்டுண்டார்களென்று, பார்வோனும் அவனுடைய சர்வ சேனையும் ஆறுதலடைவார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	32. என்னைப்பற்றிய கெடியை ஜீவனுள்ளோர் தேசத்தில் உண்டுபண்ணுகிறேன், பார்வோனும் அவனுடைய ஏராளமான ஜனமும் பட்டயத்தால் வெட்டுண்டவர்களிடத்தில் விருத்தசேதனமில்லாதவர்களின் நடுவே கிடத்தப்படுவார்கள் என்கிறதைக் கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial32