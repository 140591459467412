import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah37 = () => {
  const verseNumber = 37;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 37 </title>
	<meta
          name="description"
          content="Isaiah 37 | ஏசாயா 37 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ராஜாவாகிய எசேக்கியா அதைக் கேட்டபோது, தன் வஸ்திரங்களைக் கிழித்து, இரட்டு உடுத்துக்கொண்டு, கர்த்தருடைய ஆலயத்தில் பிரவேசித்து,
	<br /><br />
	2. அரமனை விசாரிப்புக்காரனாகிய எலியாக்கீமையும், சம்பிரதியாகிய செப்னாவையும், ஆசாரியர்களின் மூப்பரையும், ஆமோத்சின் குமாரனாகிய ஏசாயா என்னும் தீர்க்கதரிசியினிடத்திற்கு இரட்டு உடுத்தவர்களாக அனுப்பினான்.
	<br /><br />
	3. இவர்கள் அவனை நோக்கி: இந்த நாள் நெருக்கமும், கண்டிதமும், தூஷணமும் அநுபவிக்கிற நாள்; பிள்ளைப்பேறு நோக்கியிருக்கிறது; பெறவோ பெலன் இல்லை.
	<br /><br />
	4. ஜீவனுள்ள தேவனை நிந்திக்கும்படி, அசீரியா ராஜாவாகிய தன் ஆண்டவனால் அனுப்பப்பட்ட ரப்சாக்கே சொன்ன வார்த்தைகளை உமது தேவனாகிய கர்த்தர் கேட்டிருக்கிறார்; உமது தேவனாகிய கர்த்தர் கேட்டிருக்கிற வார்த்தைகளினிமித்தம் தண்டனைசெய்வார்; ஆகையால், இன்னும் மீதியாயிருக்கிறவர்களுக்காக விண்ணப்பஞ்செய்வீராக என்று எசேக்கியா சொல்லச்சொன்னார் என்றார்கள்.
	<br /><br />
	5. இவ்விதமாய் எசேக்கியா ராஜாவின் ஊழியக்காரர் ஏசாயாவினிடத்தில் வந்து சொன்னார்கள்.
	<br /><br />
	6. அப்பொழுது ஏசாயா அவர்களை நோக்கி: அசீரியா ராஜாவின் ஊழியக்காரர் என்னைத் தூஷித்ததும் நீர் கேட்டதுமான வார்த்தைகளினாலே பயப்படாதேயும்.
	<br /><br />
	7. இதோ, அவன் ஒரு செய்தியைக்கேட்டு, தன் தேசத்துக்குத் திரும்புவதற்கான ஆவியை நான் அவனுக்குள் அனுப்பி, அவனை அவன் தேசத்திலே பட்டயத்தால் விழப்பண்ணுவேன் என்று கர்த்தர் உரைக்கிறார் என்பதை உங்கள் ஆண்டவனிடத்தில் சொல்லுங்கள் என்றான்.
	<br /><br />
	8. அசீரியா ராஜா லாகீசைவிட்டுப் புறப்பட்டான் என்று கேள்விப்பட்டு, ரப்சாக்கே திரும்பிப்போய், அவன் லிப்னாவின்மேல் யுத்தம்பண்ணுகிறதைக் கண்டான்.
	<br /><br />
	9. அப்பொழுது, எத்தியோப்பியாவின் ராஜாவாகிய திராக்கா உம்மோடு யுத்தம்பண்ணப் புறப்பட்டான் என்று சொல்லக் கேள்விப்பட்டான்; அதைக் கேட்டபோது அவன் எசேக்கியாவினிடத்துக்கு ஸ்தானாபதிகளை அனுப்பி:
	<br /><br />
	10. நீங்கள் யூதாவின் ராஜாவாகிய எசேக்கியாவுக்குச் சொல்லவேண்டியது என்னவென்றால், எருசலேம் அசீரியா ராஜாவின் கையில் ஒப்புக்கொடுக்கப்படுவதில்லை என்று நீ நம்பியிருக்கிற உன் தேவன் உன்னை எத்தவொட்டாதே.
	<br /><br />
	11. இதோ, அசீரியா ராஜாக்கள் சகல தேசங்களையும் சங்கரித்த செய்தியை நீ கேள்விப்பட்டிருக்கிறாய்; நீ தப்புவாயோ?
	<br /><br />
	12. என் பிதாக்கள் அழித்துவிட்ட கோசானையும், ஆரானையும், ரேத்சேப்பையும், தெலாசாரிலிருந்த ஏதேனின் புத்திரரையும் அவர்களுடைய தேவர்கள் தப்புவித்ததுண்டோ?
	<br /><br />
	13. ஆமாத்தின் ராஜாவும், அர்பாத்தின் ராஜாவும், செப்பர்வாயீம் ஏனா ஈவா பட்டணங்களின் ராஜாவும் எங்கே என்று சொல்லுங்கள் என்றார்.
	<br /><br />
	14. எசேக்கியா ஸ்தானாபதிகளின் கையிலிருந்த நிருபத்தை வாங்கி வாசித்தான்; பின்பு எசேக்கியா கர்த்தருடைய ஆலயத்திற்குப் போய் அதைக் கர்த்தருக்கு முன்பாக விரித்து,
	<br /><br />
	15. கர்த்தரை நோக்கி:
	<br /><br />
	16. சேனைகளின் கர்த்தாவே, கேருபீன்களின் மத்தியில் வாசம்பண்ணுகிற இஸ்ரவேலின் தேவனே, நீர் ஒருவரே பூமியின் ராஜ்யங்களுக்கெல்லாம் தேவனானவர்; நீர் வானத்தையும் பூமியையும் உண்டாக்கினீர்.
	<br /><br />
	17. கர்த்தாவே, உமது செவியைச் சாய்த்துக்கேளும்; கர்த்தாவே, நீர் உமது கண்களைத் திறந்துபாரும், சனகெரிப் ஜீவனுள்ள தேவனை நிந்திக்கும்படிக்குச் சொல்லியனுப்பின வார்த்தைகளையெல்லாம் கேளும்.
	<br /><br />
	18. கர்த்தாவே, அசீரியா ராஜாக்கள் அந்த ஜாதிகளையும், அவர்கள் தேசத்தையும் நாசமாக்கி,
	<br /><br />
	19. அவர்களுடைய தேவர்களை நெருப்பிலே போட்டுவிட்டது மெய்தான்; அவைகள் தேவர்கள் அல்லவே, மனுஷர் கைவேலையான மரமும் கல்லுந்தானே; ஆகையால் அவைகளை நிர்த்தூளியாக்கினார்கள்.
	<br /><br />
	20. இப்போதும் எங்கள் தேவனாகிய கர்த்தாவே, நீர் ஒருவரே கர்த்தர் என்று பூமியின் ராஜ்யங்களெல்லாம் அறியும்படிக்கு, எங்களை அவன் கைக்கு நீங்கலாக்கி இரட்சியும் என்று விண்ணப்பம்பண்ணினான்.
	<br /><br />
	21. அப்பொழுது ஆமோத்சின் குமாரனாகிய ஏசாயா, எசேக்கியாவுக்குச் சொல்லியனுப்பினது: இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறது என்னவென்றால், அசீரியா ராஜாவாகிய சனகெரிபினிமித்தம் நீ என்னை நோக்கி விண்ணப்பம்பண்ணினாயே.
	<br /><br />
	22. அவனைக்குறித்துக் கர்த்தர் சொல்லுகிற வசனமாவது: சீயோன் குமாரத்தியாகிய கன்னியாஸ்திரீ உன்னை இகழ்ந்து, உன்னைப் பரிகாசம்பண்ணுகிறாள்; எருசலேம் குமாரத்தி உன் பின்னாலே தலையைத் துலுக்குகிறாள்.
	<br /><br />
	23. யாரை நிந்தித்துத் தூஷித்தாய்? யாருக்கு விரோதமாய் உன் சத்தத்தை உயர்த்தினாய்? நீ இஸ்ரவேலின் பரிசுத்தருக்கு விரோதமாய் அல்லவோ உன் கண்களை மேட்டிமையாய் ஏறெடுத்தாய்.
	<br /><br />
	24. உன் ஊழியக்காரரைக்கொண்டு நீ ஆண்டவரை நிந்தித்து: என் இரதங்களின் திரளினாலே நான் மலைகளின் கொடுமுடிகளுக்கும் லீபனோனின் சிகரங்களுக்கும் வந்து ஏறினேன்; அதின் உயரமான கேதுருமரங்களையும், உச்சிதமான தேவதாரு விருட்சங்களையும் நான் வெட்டி, உயர்ந்த அதின் கடைசி எல்லைமட்டும், அதின் செழுமையான வனமட்டும் வருவேன் என்றும்,
	<br /><br />
	25. நான் கிணறு வெட்டித் தண்ணீர் குடித்தேன்; என் உள்ளங்காலினால் அரணிப்பான இடங்களின் அகழிகளையெல்லாம் வறளவும் பண்ணினேன் என்றும் சொன்னாய்.
	<br /><br />
	26. நான் வெகுகாலத்துக்குமுன் அதை நியமித்து, பூர்வநாட்கள்முதல் அதைத் திட்டம்பண்ணினேன் என்பதை நீ கேட்டதில்லையோ? இப்பொழுது நீ அரணான பட்டணங்களைப் பாழான மண்மேடுகளாக்கும்படி நானே அதைச் சம்பவிக்கப்பண்ணினேன்.
	<br /><br />
	27. அதினாலே அவைகளின் குடிகள் கையிளைத்தவர்களாகி, கலங்கி வெட்கப்பட்டு, வெளியின் பூண்டுக்கும், பச்சிலைக்கும், வீடுகளின்மேல் முளைக்கும் புல்லுக்கும், ஓங்கி வளருமுன் தீய்ந்துபோம் பயிருக்கும் சமானமானார்கள்.
	<br /><br />
	28. உன் இருப்பையும், உன் போக்கையும், உன் வரவையும், நீ எனக்கு விரோதமாய்க் கொந்தளிக்கிறதையும் அறிவேன்.
	<br /><br />
	29. நீ எனக்கு விரோதமாய்க் கொந்தளித்து, வீரியம்பேசினது என் செவிகளில் ஏறினபடியினாலே, நான் என் துறட்டை உன் மூக்கிலும், என் கடிவாளத்தை உன் வாயிலும் போட்டு, நீ வந்த வழியே உன்னைத் திரும்பப்பண்ணுவேன்.
	<br /><br />
	30. உனக்கு அடையாளமாயிருப்பது என்னவென்றால்: இந்த வருஷத்திலே தப்பிப் பயிராகிறதையும், இரண்டாம் வருஷத்திலே தானாய் விளைகிறதையும் சாப்பிடுவீர்கள்; மூன்றாம் வருஷத்திலோ விதைத்து அறுத்து, திராட்சத்தோட்டங்களை நாட்டி, அவைகளின் கனிகளைப் புசிப்பீர்கள்.
	<br /><br />
	31. யூதா வம்சத்தாரில் தப்பி மீந்திருக்கிறவர்கள் மறுபடியும் கீழே வேர்பற்றி மேலே கனிகொடுப்பார்கள்.
	<br /><br />
	32. மீதியாயிருக்கிறவர்கள் எருசலேமிலும், தப்பினவர்கள் சீயோன் மலையிலுமிருந்து புறப்படுவார்கள்; சேனைகளுடைய கர்த்தரின் வைராக்கியம் இதைச் செய்யும்.
	<br /><br />
	33. ஆகையால் கர்த்தர் அசீரியா ராஜாவைக்குறித்து: அவன் இந்த நகரத்துக்குள் பிரவேசிப்பதில்லை; இதின்மேல் அம்பு எய்வதுமில்லை; இதற்கு முன்பாகக் கேடகத்தோடே வருவதுமில்லை; இதற்கு எதிராகக் கொத்தளம் போடுவதுமில்லை.
	<br /><br />
	34. அவன் இந்த நகரத்துக்குள் பிரவேசியாமல், தான் வந்தவழியே திரும்பிப்போவான்.
	<br /><br />
	35. என்னிமித்தமும் என் தாசனாகிய தாவீதினிமித்தமும், நான் இந்த நகரத்தை இரட்சிக்கும்படிக்கு இதற்கு ஆதரவாயிருப்பேன் என்பதைக் கர்த்தர் உரைக்கிறார் என்று சொல்லியனுப்பினான்.
	<br /><br />
	36. அப்பொழுது கர்த்தருடைய தூதன் புறப்பட்டு, அசீரியரின் பாளயத்தில் லட்சத்தெண்பத்தையாயிரம் பேரைச் சங்கரித்தான்; அதிகாலமே எழுந்திருக்கும்போது, இதோ, அவர்களெல்லாரும் செத்த பிரேதங்களாய்க் கிடந்தார்கள்.
	<br /><br />
	37. அப்பொழுது அசீரியா ராஜாவாகிய சனகெரிப் பிரயாணப்பட்டுத் திரும்பிப்போய், நினிவேயில் இருந்துவிட்டான்.
	<br /><br />
	38. அவன் தன் தேவனாகிய நிஸ்ரோகின் கோவிலிலே பணிந்துகொள்ளுகிறபோது, அவன் குமாரராகிய அத்ரமலேக்கும் சரேத்சேரும் அவனைப் பட்டயத்தினால் வெட்டிப்போட்டு, அரராத் தேசத்துக்குத் தப்பி ஓடிப்போனார்கள்; அவன் குமாரனாகிய எசரத்தோன் அவன் பட்டத்திற்கு வந்து அரசாண்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah37