import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 5 </title>
	<meta
          name="description"
          content="Acts 5 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 5 |
          Acts of Apostles Chapter-5 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அனனியா என்னும் பேருள்ள ஒருவனும், அவனுடைய மனைவியாகிய சப்பீராளும் தங்கள் காணியாட்சியை விற்றார்கள்.
	<br /><br />
	2. தன் மனைவி அறிய அவன் கிரயத்திலே ஒரு பங்கை வஞ்சித்துவைத்து, ஒரு பங்கைக் கொண்டுவந்து, அப்போஸ்தலருடைய பாதத்திலே வைத்தான்.
	<br /><br />
	3. பேதுரு அவனை நோக்கி: அனனியாவே, நிலத்தின் கிரயத்தில் ஒரு பங்கை வஞ்சித்துவைத்து, பரிசுத்தஆவியினிடத்தில் பொய்சொல்லும்படி, சாத்தான் உன் இருதயத்தை நிரப்பினதென்ன?
	<br /><br />
	4. அதை விற்கும் முன்னே அது உன்னுடையதாயிருக்கவில்லையோ? அதை விற்றபின்பும் அதின் கிரயம் உன் வசத்திலிருக்கவில்லையோ? நீ உன் இருதயத்திலே இப்படிப்பட்ட எண்ணங்கொண்டதென்ன? நீ மனுஷரிடத்தில் அல்ல, தேவனிடத்தில் பொய்சொன்னாய் என்றான்.
	<br /><br />
	5. அனனியா இந்த வார்த்தைகளைக் கேட்கவே, விழுந்து ஜீவனை விட்டான். இவைகளைக் கேள்விப்பட்ட யாவருக்கும் மிகுந்த பயமுண்டாயிற்று.
	<br /><br />
	6. வாலிபர் எழுந்து, அவனைச் சீலையில் சுற்றி, வெளியே எடுத்துக்கொண்டுபோய், அடக்கம்பண்ணினார்கள்.
	<br /><br />
	7. ஏறக்குறைய மூன்று மணி நேரத்துக்குப்பின்பு, அவனுடைய மனைவி நடந்ததை அறியாமல், உள்ளே வந்தாள்.
	<br /><br />
	8. பேதுரு அவளை நோக்கி: நிலத்தை இவ்வளவுக்குத்தானா விற்றீர்கள், எனக்குச் சொல் என்றான். அவள்: ஆம், இவ்வளவுக்குத்தான் என்றாள்.
	<br /><br />
	9. பேதுரு அவளை நோக்கி: கர்த்தருடைய ஆவியைச் சோதிக்கிறதற்கு நீங்கள் ஒருமனப்பட்டதென்ன? இதோ, உன் புருஷனை அடக்கம்பண்ணினவர்களுடைய கால்கள் வாசற்படியிலே வந்திருக்கிறது, உன்னையும் வெளியே கொண்டுபோவார்கள் என்றான்.
	<br /><br />
	10. உடனே அவள் அவனுடைய பாதத்தில் விழுந்து ஜீவனை விட்டாள். வாலிபர் உள்ளே வந்து, அவள் மரித்துப்போனதைக் கண்டு, அவளை வெளியே எடுத்துக்கொண்டுபோய், அவளுடைய புருஷனண்டையிலே அடக்கம்பண்ணினார்கள்.
	<br /><br />
	11. சபையாரெல்லாருக்கும், இவைகளைக் கேள்விப்பட்ட மற்ற யாவருக்கும், மிகுந்த பயமுண்டாயிற்று.
	<br /><br />
	12. அப்போஸ்தலருடைய கைகளினாலே அநேக அடையாளங்களும் அற்புதங்களும் ஜனங்களுக்குள்ளே செய்யப்பட்டது. எல்லாரும் ஒருமனப்பட்டுச் சாலொமோனுடைய மண்டபத்தில் இருந்தார்கள்.
	<br /><br />
	13. மற்றவர்களில் ஒருவரும் அவர்களுடனே சேரத் துணியவில்லை. ஆகிலும் ஜனங்கள் அவர்களை மேன்மைப்படுத்தினார்கள்.
	<br /><br />
	14. திரளான புருஷர்களும் ஸ்திரீகளும் விசுவாசமுள்ளவர்களாகிக் கர்த்தரிடமாக அதிகமதிகமாய்ச் சேர்க்கப்பட்டார்கள்.
	<br /><br />
	15. பிணியாளிகளைப் படுக்கைகளின் மேலும் கட்டில்களின்மேலும் கிடத்தி, பேதுரு நடந்துபோகையில் அவனுடைய நிழலாகிலும் அவர்களில் சிலர்மேல் படும்படிக்கு, அவர்களை வெளியே வீதிகளில் கொண்டுவந்து வைத்தார்கள்.
	<br /><br />
	16. சுற்றுப்பட்டணங்களிலுமிருந்து திரளான ஜனங்கள் பிணியாளிகளையும் அசுத்த ஆவிகளால் வாதிக்கப்பட்டவர்களையும் எருசலேமுக்குக் கொண்டுவந்தார்கள்; அவர்களெல்லாரும் குணமாக்கப்பட்டார்கள்.
	<br /><br />
	17. அப்பொழுது பிரதான ஆசாரியனும் அவனுடனேகூட இருந்த சதுசேய சமயத்தாரனைவரும் எழும்பி, பொறாமையினால் நிறைந்து,
	<br /><br />
	18. அப்போஸ்தலர்களைப் பிடித்து, பொதுவான சிறைச்சாலையிலே வைத்தார்கள்.
	<br /><br />
	19. கர்த்தருடைய தூதன் இராத்திரியிலே சிறைச்சாலையின் கதவுகளைத் திறந்து, அவர்களை வெளியே கொண்டுவந்து:
	<br /><br />
	20. நீங்கள் போய், தேவாலயத்திலே நின்று, இந்த ஜீவவார்த்தைகள் எல்லாவற்றையும் ஜனங்களுக்குச் சொல்லுங்கள் என்றான்.
	<br /><br />
	21. அவர்கள் அதைக்கேட்டு, அதிகாலமே தேவாலயத்தில் பிரவேசித்துப் போதகம்பண்ணினார்கள். பிரதான ஆசாரியனும் அவனுடனேகூட இருந்தவர்களும் வந்து, ஆலோசனைச் சங்கத்தாரையும் இஸ்ரவேல் புத்திரரின் மூப்பரெல்லாரையும் வரவழைத்து, அப்போஸ்தலர்களைக் கொண்டுவரும்படி சிறைச்சாலைக்குச் சேவகரை அனுப்பினார்கள்.
	<br /><br />
	22. சேவகர் போய், சிறைச்சாலையிலே அவர்களைக் காணாமல், திரும்பிவந்து:
	<br /><br />
	23. சிறைச்சாலை மிகுந்த பத்திரமாய்ப் பூட்டப்பட்டிருக்கவும், காவற்காரர் வெளியே கதவுகளுக்குமுன் நிற்கவும் கண்டோம்; திறந்தபொழுதோ உள்ளே ஒருவரையும் காணோம் என்று அறிவித்தார்கள்.
	<br /><br />
	24. இந்தச் செய்தியை ஆசாரியனும் தேவாலயத்தைக் காக்கிற சேனைத்தலைவனும் பிரதான ஆசாரியர்களும் கேட்டபொழுது, இதென்னமாய் முடியுமோ என்று, அவர்களைக்குறித்துக் கலக்கமடைந்தார்கள்.
	<br /><br />
	25. அப்பொழுது ஒருவன் வந்து: இதோ, நீங்கள் காவலில் வைத்த மனுஷர் தேவாலயத்திலே நின்று ஜனங்களுக்குப் போதகம்பண்ணுகிறார்கள் என்று அவர்களுக்கு அறிவித்தான்.
	<br /><br />
	26. உடனே சேனைத்தலைவன் சேவகரோடேகூடப் போய், ஜனங்கள் கல்லெறிவார்களென்று பயந்ததினால், பலவந்தம்பண்ணாமல் அவர்களை அழைத்துக்கொண்டுவந்தான்.
	<br /><br />
	27. அப்படி அவர்களை அழைத்துக்கொண்டுவந்து, ஆலோசனைச் சங்கத்துக்கு முன்பாக நிறுத்தினார்கள். அப்பொழுது பிரதான ஆசாரியன் அவர்களை நோக்கி:
	<br /><br />
	28. நீங்கள் அந்த நாமத்தைக்குறித்துப் போதகம்பண்ணக்கூடாதென்று நாங்கள் உங்களுக்கு உறுதியாய்க் கட்டளையிடவில்லையா? அப்படியிருந்தும், இதோ, எருசலேமை உங்கள் போதகத்தினாலே நிரப்பி, அந்த மனுஷனுடைய இரத்தப்பழியை எங்கள்மேல் சுமத்தவேண்டுமென்றிருக்கிறீர்கள் என்று சொன்னான்.
	<br /><br />
	29. அதற்குப் பேதுருவும் மற்ற அப்போஸ்தலரும்: மனுஷருக்குக் கீழ்ப்படிகிறதைப்பார்க்கிலும் தேவனுக்குக் கீழ்ப்படிகிறதே அவசியமாயிருக்கிறது.
	<br /><br />
	30. நீங்கள் மரத்திலே தூக்கிக் கொலைசெய்த இயேசுவை நம்முடைய பிதாக்களின் தேவன் எழுப்பி,
	<br /><br />
	31. இஸ்ரவேலுக்கு மனந்திரும்புதலையும் பாவமன்னிப்பையும் அருளுகிறதற்காக, அவரை அதிபதியாகவும் இரட்சகராகவும் தமது வலதுகரத்தினாலே உயர்த்தினார்.
	<br /><br />
	32. இந்தச் சங்கதிகளைக்குறித்து நாங்கள் அவருக்குச் சாட்சிகளாயிருக்கிறோம்; தேவன் தமக்குக் கீழ்ப்படிகிறவர்களுக்குத் தந்தருளின பரிசுத்தஆவியும் சாட்சி என்றார்கள்.
	<br /><br />
	33. அதை அவர்கள் கேட்டபொழுது, மூர்க்கமடைந்து, அவர்களைக் கொலைசெய்யும்படிக்கு யோசனைபண்ணினார்கள்.
	<br /><br />
	34. அப்பொழுது சகல ஜனங்களாலும் கனம்பெற்ற நியாயசாஸ்திரியாகிய கமாலியேல் என்னும் பேர்கொண்ட ஒரு பரிசேயன் ஆலோசனைச் சங்கத்தில் எழுந்திருந்து, அப்போஸ்தலரைச் சற்றுநேரம் வெளியே கொண்டுபோகச்சொல்லி,
	<br /><br />
	35. சங்கத்தாரை நோக்கி: இஸ்ரவேலரே, இந்த மனுஷருக்கு நீங்கள் செய்யப்போகிறதைக்குறித்து எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	36. ஏனென்றால் இந்நாட்களுக்கு முன்னே தெயுதாஸ் என்பவன் எழும்பி, தன்னை ஒரு பெரியவனாகப் பாராட்டினான்; ஏறக்குறைய நானூறுபேர் அவனைச் சேர்ந்தார்கள்; அவன் மடிந்துபோனான்; அவனை நம்பின அனைவரும் சிதறி, அவமாய்ப்போனார்கள்.
	<br /><br />
	37. அவனுக்குப்பின்பு, குடிமதிப்பின் நாட்களிலே, கலிலேயனாகிய யூதாஸ் என்பவன் எழும்பி, தன்னைப் பின்பற்றும்படி அநேக ஜனங்களை இழுத்தான்; அவனும் அழிந்துபோனான்; அவனை நம்பியிருந்த அனைவரும் சிதறடிக்கப்பட்டார்கள்.
	<br /><br />
	38. இப்பொழுது நான் உங்களுக்குச் சொல்லுகிறதென்னவென்றால், இந்த மனுஷருக்கு ஒன்றுஞ்செய்யாமல் இவர்களை விட்டுவிடுங்கள். இந்த யோசனையும் இந்தக் கிரியையும் மனுஷரால் உண்டாயிருந்ததானால் ஒழிந்துபோம்;
	<br /><br />
	39. தேவனால் உண்டாயிருந்ததேயானால், அதை ஒழித்துவிட உங்களால் கூடாது; தேவனோடே போர்செய்கிறவர்களாய்க் காணப்படாதபடிக்குப் பாருங்கள் என்றான்.
	<br /><br />
	40. அப்பொழுது அவர்கள் அவனுடைய யோசனைக்கு உடன்பட்டு, அப்போஸ்தலரை வரவழைத்து, அடித்து, இயேசுவின் நாமத்தைக்குறித்துப் பேசக்கூடாதென்று கட்டளையிட்டு, அவர்களை விடுதலையாக்கினார்கள்.
	<br /><br />
	41. அவருடைய நாமத்துக்காகத் தாங்கள் அவமானமடைவதற்குப் பாத்திரராக எண்ணப்பட்டபடியினால், சந்தோஷமாய் ஆலோசனைச் சங்கத்தைவிட்டுப் புறப்பட்டுப்போய்,
	<br /><br />
	42. தினந்தோறும் தேவாலயத்திலேயும் வீடுகளிலேயும் இடைவிடாமல் உபதேசம்பண்ணி, இயேசுவே கிறிஸ்துவென்று பிரசங்கித்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts5