import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah41 = () => {
  const verseNumber = 41;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 41 </title>
	<meta
          name="description"
          content="Isaiah 41 | ஏசாயா 41 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தீவுகளே, எனக்கு முன்பாக மவுனமாயிருங்கள்; ஜனங்கள் தங்கள் பெலனைப் புதிதாக்கிக்கொண்டு, சமீபித்து வந்து, பின்பு பேசக்கடவர்கள்; நாம் ஒருமிக்க நியாயாசனத்துக்கு முன்பாகச் சேருவோம்.
	<br /><br />
	2. கிழக்கிலிருந்து நீதிமானை எழுப்பி, தமது பாதபடியிலே வரவழைத்தவர் யார்? ஜாதிகளை அவனுக்கு ஒப்புக்கொடுத்து, அவனை ராஜாக்களுக்கு ஆண்டவனாக்கி, அவர்களை அவன் பட்டயத்துக்குத் தூசியும், அவன் வில்லுக்குச் சிதறடிக்கப்பட்ட தாளடியுமாக்கி,
	<br /><br />
	3. அவன் அவர்களைத் துரத்தவும், தன் கால்கள் நடவாதிருந்த பாதையிலே சமாதானத்தோடே நடக்கவும் பண்ணினவர் யார்?
	<br /><br />
	4. அதைச் செய்து நிறைவேற்றி, ஆதிமுதற்கொண்டு தலைமுறைகளை வரவழைக்கிறவர் யார்? முந்தினவராயிருக்கிற கர்த்தராகிய நான்தானே; பிந்தினவர்களோடும் இருப்பவராகிய நான்தானே.
	<br /><br />
	5. தீவுகள் அதைக் கண்டு பயப்படும், பூமியின் கடையாந்தரங்கள் நடுங்கும்; அவர்கள் சேர்ந்துவந்து,
	<br /><br />
	6. ஒருவருக்கு ஒருவர் ஒத்தாசை செய்து திடன்கொள் என்று சகோதரனுக்குச் சகோதரன் சொல்லுகிறான்.
	<br /><br />
	7. சித்திரவேலைக்காரன் தட்டானையும், சுத்தியாலே மெல்லிய தகடு தட்டுகிறவன் அடைகல்லின்மேல் அடிக்கிறவனையும் உற்சாகப்படுத்தி, இசைக்கிறதற்கான பக்குவமென்று சொல்லி, அது அசையாதபடிக்கு அவன் ஆணிகளால் அதை இறுக்குகிறான்.
	<br /><br />
	8. என் தாசனாகிய இஸ்ரவேலே, நான் தெரிந்துகொண்ட யாக்கோபே, என் சிநேகிதனான ஆபிரகாமின் சந்ததியே,
	<br /><br />
	9. நான் பூமியின் கடையாந்தரங்களிலிருந்து, உன்னை எடுத்து, அதின் எல்லைகளிலிருந்து அழைத்துவந்து: நீ என் தாசன், நான் உன்னைத் தெரிந்துகொண்டேன், நான் உன்னை வெறுத்துவிடவில்லை என்று சொன்னேன்.
	<br /><br />
	10. நீ பயப்படாதே, நான் உன்னுடனே இருக்கிறேன்; திகையாதே, நான் உன் தேவன்; நான் உன்னைப் பலப்படுத்தி உனக்குச் சகாயம்பண்ணுவேன்; என் நீதியின் வலதுகரத்தினால் உன்னைத் தாங்குவேன்.
	<br /><br />
	11. இதோ, உன்மேல் எரிச்சலாயிருக்கிற யாவரும் வெட்கி இலச்சையடைவார்கள்; உன்னோடே வழக்காடுகிறவர்கள் நாசமாகி ஒன்றுமில்லாமற்போவார்கள்.
	<br /><br />
	12. உன்னோடே போராடினவர்களைத் தேடியும் காணாதிருப்பாய்; உன்னோடே யுத்தம்பண்ணின மனுஷர் ஒன்றுமில்லாமல் இல்பொருளாவார்கள்.
	<br /><br />
	13. உன் தேவனாயிருக்கிற கர்த்தராகிய நான் உன் வலதுகையைப் பிடித்து: பயப்படாதே, நான் உனக்குத் துணைநிற்கிறேன் என்று சொல்லுகிறேன்.
	<br /><br />
	14. யாக்கோபு என்னும் பூச்சியே, இஸ்ரவேலின் சிறுகூட்டமே, பயப்படாதே; நான் உனக்குத் துணைநிற்கிறேன் என்று கர்த்தரும் இஸ்ரவேலின் பரிசுத்தருமாகிய உன் மீட்பர் உரைக்கிறார்.
	<br /><br />
	15. இதோ, போரடிக்கிறதற்கு நான் உன்னைப் புதிதும் கூர்மையுமான பற்களுள்ள யந்தரமாக்குகிறேன்; நீ மலைகளை மிதித்து நொறுக்கி, குன்றுகளைப் பதருக்கு ஒப்பாக்கிவிடுவாய்.
	<br /><br />
	16. அவைகளைத் தூற்றுவாய், அப்பொழுது காற்று அவைகளைக் கொண்டுபோய், சுழல்காற்று அவைகளைப் பறக்கடிக்கும்; நீயோ கர்த்தருக்குள்ளே களிகூர்ந்து, இஸ்ரவேலின் பரிசுத்தருக்குள்ளே மேன்மைபாராட்டிக் கொண்டிருப்பாய்.
	<br /><br />
	17. சிறுமையும் எளிமையுமானவர்கள் தண்ணீரைத் தேடி, அது கிடையாமல், அவர்கள் நாவு தாகத்தால் வறளும்போது, கர்த்தராகிய நான் அவர்களுக்குச் செவிகொடுத்து, இஸ்ரவேலின் தேவனாகிய நான் அவர்களைக் கைவிடாதிருப்பேன்.
	<br /><br />
	18. உயர்ந்த மேடுகளில் ஆறுகளையும், பள்ளத்தாக்குகளின் நடுவே ஊற்றுகளையும் திறந்து, வனாந்தரத்தைத் தண்ணீர்த் தடாகமும், வறண்ட பூமியை நீர்க்கேணிகளுமாக்கி,
	<br /><br />
	19. வனாந்தரத்திலே கேதுருமரங்களையும், சீத்தீம்மரங்களையும், மிருதுச்செடிகளையும், ஒலிவமரங்களையும் நட்டு, அவாந்தரவெளியிலே தேவதாரு விருட்சங்களையும், பாய்மரவிருட்சங்களையும், புன்னைமரங்களையும் உண்டுபண்ணுவேன்.
	<br /><br />
	20. கர்த்தருடைய கரம் அதைச் செய்தது என்றும், இஸ்ரவேலின் பரிசுத்தர் அதைப் படைத்தார் என்றும், யாவரும் கண்டு உணர்ந்து சிந்தித்து அறிவார்கள்.
	<br /><br />
	21. உங்கள் வழக்கைக் கொண்டுவாருங்கள் என்று கர்த்தர் சொல்லுகிறார்; உங்கள் பலமான நியாயங்களை வெளிப்படுத்துங்கள் என்று யாக்கோபின் ராஜா உரைக்கிறார்.
	<br /><br />
	22. அவர்கள் அவைகளைக் கொண்டுவந்து, சம்பவிக்கப்போகிறவைகளை நமக்குத் தெரிவிக்கட்டும்; அவைகளில் முந்தி சம்பவிப்பவைகள் இன்னவைகளென்று சொல்லி, நாம் நம்முடைய மனதை அவைகளின்மேல் வைக்கும்படிக்கும், பிந்தி சம்பவிப்பவைகளையும் நாம் அறியும்படிக்கும் நமக்குத் தெரிவிக்கட்டும்; வருங்காரியங்களை நமக்கு அறிவிக்கட்டும்.
	<br /><br />
	23. பின்வரும் காரியங்களை எங்களுக்குத் தெரிவியுங்கள்; அப்பொழுது நீங்கள் தேவர்கள் என்று அறிவோம்; அல்லது நன்மையாவது தீமையாவது செய்யுங்கள்; அப்பொழுது நாங்கள் திகைத்து ஏகமாய்க் கூடி அதைப் பார்ப்போம்.
	<br /><br />
	24. இதோ, நீங்கள் சூனியத்திலும் சூனியமாயிருக்கிறீர்கள்; உங்கள் செயல் வெறுமையிலும் வெறுமையானது; உங்களைத் தெரிந்துகொள்ளுகிறவன் அருவருப்பானவன்.
	<br /><br />
	25. நான் வடக்கேயிருந்து ஒருவனை எழும்பப்பண்ணுவேன், அவன் வருவான்; சூரியோதய திசையிலிருந்து என் நாமத்தைத் தொழுதுகொள்ளுவான்; அவன் வந்து அதிபதிகளைச் சேற்றைப்போலவும், குயவன் களிமண்ணை மிதிப்பதுபோலவும் மிதிப்பான்.
	<br /><br />
	26. நாம் அதை அறியும்படியாக ஆதியில் சொன்னவன் யார்? நாம் அவனை யதார்த்தவான் என்று சொல்லும்படி பூர்வகாலத்தில் அறிவித்தவன் யார்? அறிவிக்கிறவன் ஒருவனும் இல்லையே; உரைக்கிறவனும் இல்லையே; உங்கள் வார்த்தைகளைக் கேட்டிருக்கிறவனும் இல்லையே.
	<br /><br />
	27. முதல் முதல், நானே, சீயோனை நோக்கி: இதோ, அவைகளைப் பார் என்று சொல்லி, எருசலேமுக்குச் சுவிசேஷகரைக் கொடுக்கிறேன்.
	<br /><br />
	28. நான் பார்த்தேன், அவர்களில் அறிவிக்கிறவன் ஒருவனுமில்லை; நான் கேட்குங் காரியத்துக்குப் பிரதியுத்தரம் கொடுக்கத்தக்க ஒரு ஆலோசனைக்காரனும் அவர்களில் இல்லை.
	<br /><br />
	29. இதோ, அவர்கள் எல்லாரும் மாயை, அவர்கள் கிரியைகள் விருதா; அவர்களுடைய விக்கிரகங்கள் காற்றும் வெறுமையுந்தானே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah41