import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 16 </title>
	<meta
          name="description"
          content="Mark 16 | மாற்கு 16 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஓய்வுநாளானபின்பு மகதலேனா மரியாளும், யாக்கோபின் தாயாகிய மரியாளும், சலோமே என்பவளும், அவருக்குச் சுகந்தவர்க்கமிடும்படி அவைகளை வாங்கிக்கொண்டு,
	<br /><br />
	2. வாரத்தின் முதலாம்நாள் அதிகாலையிலே சூரியன் உதயமாகிறபோது கல்லறையினிடத்தில் வந்து,
	<br /><br />
	3. கல்லறையின் வாசலிலிருக்கிற கல்லை நமக்காக எவன் புரட்டித்தள்ளுவான் என்று ஒருவரோடொருவர் சொல்லிக்கொண்டார்கள்.
	<br /><br />
	4. அந்தக் கல் மிகவும் பெரிதாயிருந்தது; அவர்கள் ஏறிட்டுப்பார்க்கிறபோது, அது தள்ளப்பட்டிருக்கக் கண்டார்கள்.
	<br /><br />
	5. அவர்கள் கல்லறைக்குள் பிரவேசித்து, வெள்ளையங்கி தரித்தவனாய் வலதுபக்கத்தில் உட்கார்ந்திருந்த ஒரு வாலிபனைக் கண்டு பயந்தார்கள்.
	<br /><br />
	6. அவன் அவர்களை நோக்கி: பயப்படாதிருங்கள், சிலுவையில் அறையப்பட்ட நசரேயனாகிய இயேசுவைத் தேடுகிறீர்கள்; அவர் உயிர்த்தெழுந்தார்; அவர் இங்கேயில்லை; இதோ, அவரை வைத்த இடம்.
	<br /><br />
	7. நீங்கள் அவருடைய சீஷரிடத்திற்கும் பேதுருவினிடத்திற்கும் போய்: உங்களுக்கு முன்னே கலிலேயாவுக்குப் போகிறார், அவர் உங்களுக்குச் சொன்னபடியே அங்கே அவரைக் காண்பீர்கள் என்று, அவர்களுக்குச் சொல்லுங்கள் என்றான்.
	<br /><br />
	8. நடுக்கமும் திகிலும் அவர்களைப் பிடித்தபடியால், அவர்கள் சீக்கிரமாய் வெளியே வந்து, கல்லறையை விட்டு ஓடினார்கள்; அவர்கள் பயந்திருந்தபடியினால் ஒருவருக்கும் ஒன்றும் சொல்லாமற் போனார்கள்.
	<br /><br />
	9. வாரத்தின் முதலாம்நாள் அதிகாலையிலே இயேசு எழுந்திருந்தபின்பு, மகதலேனா மரியாளுக்கு முதல்முதல் தரிசனமானார்.
	<br /><br />
	10. அவளிடத்திலிருந்து அவர் ஏழு பிசாசுகளைத் துரத்தியிருந்தார். அவள் புறப்பட்டு, அவரோடேகூட இருந்தவர்கள் துக்கப்பட்டு அழுதுகொண்டிருக்கையில், அவர்களிடத்தில் போய், அந்தச் செய்தியை அறிவித்தாள்.
	<br /><br />
	11. அவர் உயிரோடிருக்கிறாரென்றும் அவளுக்குக் காணப்பட்டார் என்றும் அவர்கள் கேட்டபொழுது நம்பவில்லை.
	<br /><br />
	12. அதன்பின்பு அவர்களில் இரண்டுபேர் ஒரு கிராமத்துக்கு நடந்துபோகிறபொழுது அவர்களுக்கு மறுரூபமாய்த் தரிசனமானார்.
	<br /><br />
	13. அவர்களும் போய், அதை மற்றவர்களுக்கு அறிவித்தார்கள்; அவர்களையும் அவர்கள் நம்பவில்லை.
	<br /><br />
	14. அதன்பின்பு பதினொருவரும் போஜனபந்தியிலிருக்கையில் அவர்களுக்கு அவர் தரிசனமாகி, உயிர்த்தெழுந்திருந்த தம்மைக் கண்டவர்களை அவர்கள் நம்பாமற்போனதினிமித்தம் அவர்களுடைய அவிசுவாசத்தைக்குறித்தும் இருதயகடினத்தைக்குறித்தும் அவர்களைக் கடிந்துகொண்டார்.
	<br /><br />
	15. பின்பு, அவர் அவர்களை நோக்கி: நீங்கள் உலகமெங்கும் போய், சர்வ சிருஷ்டிக்கும் சுவிசேஷத்தைப் பிரசங்கியுங்கள்.
	<br /><br />
	16. விசுவாசமுள்ளவனாகி ஞானஸ்நானம் பெற்றவன் இரட்சிக்கப்படுவான்; விசுவாசியாதவனோ ஆக்கினைக்குள்ளாகத் தீர்க்கப்படுவான்.
	<br /><br />
	17. விசுவாசிக்கிறவர்களால் நடக்கும் அடையாளங்களாவன: என் நாமத்தினாலே பிசாசுகளைத் துரத்துவார்கள்; நவமான பாஷைகளைப் பேசுவார்கள்;
	<br /><br />
	18. சர்ப்பங்களை எடுப்பார்கள்; சாவுக்கேதுவான யாதொன்றைக் குடித்தாலும் அது அவர்களைச் சேதப்படுத்தாது; வியாதியஸ்தர்மேல் கைகளை வைப்பார்கள், அப்பொழுது அவர்கள் சொஸ்தமாவார்கள் என்றார்.
	<br /><br />
	19. இவ்விதமாய்க் கர்த்தர் அவர்களுடனே பேசினபின்பு, பரலோகத்துக்கு எடுத்துக்கொள்ளப்பட்டு, தேவனுடைய வலதுபாரிசத்தில் உட்கார்ந்தார்.
	<br /><br />
	20. அவர்கள் புறப்பட்டுப்போய், எங்கும் பிரசங்கம்பண்ணினார்கள். கர்த்தர் அவர்களுடனேகூடக் கிரியையை நடப்பித்து, அவர்களால் நடந்த அடையாளங்களினாலே வசனத்தை உறுதிப்படுத்தினார். ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark16