import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial33 = () => {
  const verseNumber = 33;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 33 </title>
	<meta
          name="description"
          content="Ezekiel 33 | எசேக்கியேல்  33 |
          Ezekiel 33 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ உன் ஜனத்தின் புத்திரரோடே பேசி, அவர்களோடே சொல்லவேண்டியதாவது: நான் தேசத்தின்மேல் பட்டயத்தை வரப்பண்ணுகையில் தேசத்தின் ஜனம் தங்கள் எல்லைகளிலுள்ள ஒருவனை அழைத்து, அவனைத் தங்களுக்குக் காவற்காரனாக வைத்தபின்பு,
	<br /><br />
	3. இவன் தேசத்தின்மேல் பட்டயம் வருவதைக்கண்டு, எக்காளம் ஊதி, ஜனத்தை எச்சரிக்கும்போது,
	<br /><br />
	4. எக்காளத்தின் சத்தத்தைக் கேட்கிறவன் அதைக் கேட்டும், எச்சரிக்கையாயிராமல், பட்டயம் வந்து அவனை வாரிக்கொள்ளுகிறது உண்டானால், அவனுடைய இரத்தப்பழி அவன் தலையின்மேல் சுமரும்.
	<br /><br />
	5. அவன் எக்காளத்தின் சத்தத்தைக் கேட்டும், எச்சரிக்கையாயிருக்கவில்லை; அவனுடைய இரத்தப்பழி அவன் பேரிலே சுமரும்; எச்சரிக்கையாயிருக்கிறவனோ தன் ஜீவனைத் தப்புவித்துக்கொள்ளுவான்.
	<br /><br />
	6. காவற்காரன் பட்டயம் வருவதைக் கண்டும், அவன் எக்காளம் ஊதாமலும் ஜனங்கள் எச்சரிக்கப்படாமலும், பட்டயம் வந்து அவர்களில் யாதொருவனை வாரிக்கொள்ளுகிறது உண்டானால், அவன் தன் அக்கிரமத்திலே வாரிக்கொள்ளப்பட்டான்; ஆனாலும் அவன் இரத்தப்பழியைக் காவற்காரன் கையிலே கேட்பேன்.
	<br /><br />
	7. மனுபுத்திரனே, நான் உன்னை இஸ்ரவேல் வம்சத்தாருக்குக் காவற்காரனாக வைத்தேன்; ஆகையால் நீ என் வாயினாலே வார்த்தையைக்கேட்டு, என் நாமத்தினாலே அவர்களை எச்சரிப்பாயாக.
	<br /><br />
	8. நான் துன்மார்க்கனை நோக்கி: துன்மார்க்கனே, நீ சாகவே சாவாய் என்று சொல்லுகையில், நீ துன்மார்க்கனைத் தன் துன்மார்க்கத்தில் இராதபடி எச்சரிக்கத்தக்கதாக அதை அவனுக்குச் சொல்லாமற்போனால், அந்தத் துன்மார்க்கன் தன் அக்கிரமத்திலே சாவான்; ஆனாலும் அவன் இரத்தப்பழியை உன் கையிலே கேட்பேன்.
	<br /><br />
	9. துன்மார்க்கன் தன் வழியைவிட்டுத் திரும்பும்படி நீ அவனை எச்சரித்தும், அவன் தன் வழியைவிட்டுத் திரும்பாமற்போனால், அவன் தன் அக்கிரமத்திலே சாவான்; நீயோ உன் ஆத்துமாவைத் தப்புவிப்பாய்.
	<br /><br />
	10. மனுபுத்திரனே, நீ இஸ்ரவேல் வம்சத்தாரை நோக்கி: எங்கள் துரோகங்களும் எங்கள் பாவங்களும் எங்கள்மேல் இருக்கிறது, நாங்கள் சோர்ந்துபோகிறோம், நாங்கள் பிழைப்பது எப்படியென்று நீங்கள் சொல்லுகிறீர்கள்.
	<br /><br />
	11. கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: நான் துன்மார்க்கனுடைய மரணத்தை விரும்பாமல், துன்மார்க்கன் தன் வழியைவிட்டுத் திரும்பிப் பிழைப்பதையே விரும்புகிறேன் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன்; இஸ்ரவேல் வம்சத்தாரே, உங்கள் பொல்லாத வழிகளைவிட்டுத் திரும்புங்கள், திரும்புங்கள்; நீங்கள் ஏன் சாகவேண்டும் என்கிறார் என்று அவர்களோடே சொல்லு.
	<br /><br />
	12. மனுபுத்திரனே, நீ உன் ஜனத்தின் புத்திரரை நோக்கி: நீதிமான் துரோகம்பண்ணுகிற நாளிலே அவனுடைய நீதி அவனைத் தப்புவிப்பதில்லை; துன்மார்க்கன் தன் துன்மார்க்கத்தைவிட்டுத் திரும்புகிற நாளிலே அவன் தன் அக்கிரமத்தினால் விழுந்துபோவதுமில்லை; நீதிமான் பாவஞ்செய்கிற நாளிலே தன் நீதியினால் பிழைப்பதுமில்லை.
	<br /><br />
	13. பிழைக்கவே பிழைப்பாய் என்று நான் நீதிமானுக்குச் சொல்லும்போது, அவன் தன் நீதியை நம்பி, அநியாயஞ்செய்தால், அவனுடைய நீதியில் ஒன்றும் நினைக்கப்படுவதில்லை, அவன் செய்த தன் அநியாயத்திலே சாவான்.
	<br /><br />
	14. பின்னும் சாகவே சாவாய் என்று நான் துன்மார்க்கனுக்குச் சொல்லும்போது, அவன் தன் பாவத்தைவிட்டுத் திரும்பி, நியாயமும் நீதியுஞ்செய்து,
	<br /><br />
	15. துன்மார்க்கன் தான் வாங்கின அடைமானத்தையும் தான் கொள்ளையிட்ட பொருளையும் திரும்பக் கொடுத்துவிட்டு, அநியாயம் செய்யாதபடி ஜீவப்பிரமாணங்களில் நடந்தால், அவன் சாகாமல் பிழைக்கவே பிழைப்பான்.
	<br /><br />
	16. அவன் செய்த அவனுடைய எல்லாப் பாவங்களும் அவனுக்கு விரோதமாக நினைக்கப்படுவதில்லை; அவன் நியாயமும் நீதியும் செய்தான், பிழைக்கவே பிழைப்பான் என்று சொல்லு.
	<br /><br />
	17. உன் ஜனத்தின் புத்திரரோ, ஆண்டவருடைய வழி செம்மையானதல்ல என்கிறார்கள்; அவர்களுடைய வழியே செம்மையானதல்ல.
	<br /><br />
	18. நீதிமான் தன் நீதியைவிட்டுத் திரும்பி, அநியாயஞ்செய்தால், அவன் அதினால் சாவான்.
	<br /><br />
	19. துன்மார்க்கன் தன் அக்கிரமத்தைவிட்டுத் திரும்பி, நியாயமும் நீதியும் செய்தால், அவன் அவைகளினால் பிழைப்பான்.
	<br /><br />
	20. நீங்களோ, ஆண்டவருடைய வழி செம்மையானதல்ல என்கிறீர்கள், இஸ்ரவேல் வீட்டாரே, நான் உங்களில் ஒவ்வொருவனையும் அவனவன் வழிகளின்படியே நியாயந்தீர்ப்பேன் என்று சொல் என்றார்.
	<br /><br />
	21. எங்கள் சிறையிருப்பின் பன்னிரண்டாம் வருஷம் பத்தாம் மாதம் ஐந்தாந்தேதியிலே எருசலேமிலிருந்து தப்பின ஒருவன் என்னிடத்தில் வந்து: நகரம் அழிக்கப்பட்டது என்றான்.
	<br /><br />
	22. தப்பினவன் வருகிறதற்கு முந்தின சாயங்காலத்திலே கர்த்தருடைய கை என்மேல் அமர்ந்து, அவன் காலையில் என்னிடத்தில் வருமட்டும் என் வாயைத் திறந்திருக்கப்பண்ணிற்று; என் வாய் திறக்கப்பட்டது, பின்பு நான் மௌனமாயிருக்கவில்லை.
	<br /><br />
	23. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	24. மனுபுத்திரனே, இஸ்ரவேல் தேசத்தின் பாழான இடங்களிலுள்ள குடிகள்: ஆபிரகாம் ஒருவனாயிருந்து, தேசத்தைச் சுதந்தரித்துக்கொண்டான்; நாங்கள் அநேகராயிருக்கிறோம், எங்களுக்கு இந்தத் தேசம் சுதந்தரமாகக் கொடுக்கப்பட்டது என்று சொல்லுகிறார்கள்.
	<br /><br />
	25. ஆகையால், நீ அவர்களை நோக்கி: நீங்கள் இரத்தத்தோடே கூடியதைத் தின்று, உங்கள் நரகலான விக்கிரகங்களுக்கு நேராக உங்கள் கண்களை ஏறெடுத்து, இரத்தத்தைச் சிந்தியிருக்கிறீர்கள், நீங்கள் தேசத்தைச் சுதந்தரித்துக்கொள்வீர்களோ?
	<br /><br />
	26. நீங்கள் உங்கள் பட்டயத்தை நம்பிக்கொண்டு, அருவருப்பானதைச் செய்து, உங்களில் அவனவன் தன்தன் அயலான் மனைவியைத் தீட்டுப்படுத்துகிறீர்கள்; நீங்கள் தேசத்தைச் சுதந்தரித்துக்கொள்வீர்களோ என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல்லு.
	<br /><br />
	27. நீ அவர்களை நோக்கி: பாழான இடங்களில் இருக்கிறவர்கள் பட்டயத்தால் விழுவார்கள்; வெளிகளில் இருக்கிறவனை மிருகங்களுக்கு இரையாக ஒப்புக்கொடுப்பேன்; கோட்டைகளிலும் கெபிகளிலும் இருக்கிறவர்கள் கொள்ளைநோயால் சாவார்கள்.
	<br /><br />
	28. நான் தேசத்தைப் பாழும் அவாந்தரமுமாக்குவேன்; அப்பொழுது அதினுடைய பெலத்தின் பெருமை ஒழிந்துபோகும்; அப்பொழுது இஸ்ரவேலின் மலைகள் கடந்துபோவாரில்லாமல் அவாந்தரமாய்க் கிடக்கும்.
	<br /><br />
	29. அவர்கள் செய்த அவர்களுடைய எல்லா அருவருப்புகளினிமித்தமும் நான் தேசத்தைப் பாழும் அவாந்தரமுமாக்கும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வார்கள், இதை என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல்லு.
	<br /><br />
	30. மேலும் மனுபுத்திரனே, உன் ஜனத்தின் புத்திரர் சுவர் ஓரங்களிலும் வீட்டுவாசல்களிலும் உன்னைக்குறித்துப் பேசி, கர்த்தரிடத்திலிருந்து புறப்பட்ட வார்த்தை என்னவென்று கேட்போம் வாருங்கள் என்று ஒருவரோடொருவரும் சகோதரனோடே சகோதரனும் சொல்லி,
	<br /><br />
	31. ஜனங்கள் கூடிவருகிற வழக்கத்தின்படி உன்னிடத்தில் வந்து, உனக்கு முன்பாக என் ஜனங்கள்போல் உட்கார்ந்து, உன் வார்த்தைகளைக் கேட்கிறார்கள்; ஆனாலும் அவர்கள் அவைகளின்படி செய்கிறதில்லை; அவர்கள் தங்கள் வாயினாலே இன்பமாய்ப் பேசுகிறார்கள், அவர்கள் இருதயமோ பொருளாசையைப் பின்பற்றிப் போகிறது.
	<br /><br />
	32. இதோ, நீ இனிய குரலும் கீதவாத்தியம் வாசிப்பதில் சாமர்த்தியமுமுடையவன் பாடும் இன்பமான பாட்டுக்குச் சமானமாயிருக்கிறாய்; அவர்கள் உன் வார்த்தைகளைக் கேட்கிறார்கள்; ஆனாலும் அவைகளின்படி செய்யாமற்போகிறார்கள்.
	<br /><br />
	33. இதோ, அது வருகிறது, அது வருகையில் தங்கள் நடுவிலே ஒரு தீர்க்கதரிசி இருந்தான் என்று அறிந்துகொள்வார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial33