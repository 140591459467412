import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 3 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 3
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்படியானால், யூதனுடைய மேன்மை என்ன? விருத்தசேதனத்தினாலே பிரயோஜனம் என்ன?
	<br /><br />
	2. அது எவ்விதத்திலும் மிகுதியாயிருக்கிறது; தேவனுடைய வாக்கியங்கள் அவர்களிடத்தில் ஒப்புவிக்கப்பட்டது விசேஷித்த மேன்மையாமே.
	<br /><br />
	3. சிலர் விசுவாசியாமற்போனாலுமென்ன? அவர்களுடைய அவிசுவாசம் தேவனுடைய உண்மையை அவமாக்குமோ?
	<br /><br />
	4. அப்படியாக்கமாட்டாது: நீர் உம்முடைய வசனங்களில் நீதிபரராய் விளங்கவும், உம்முடைய நியாயம் விசாரிக்கப்படும்போது வெற்றியடையவும் இப்படியாயிற்று என்று எழுதியிருக்கிறபடி, தேவனே சத்தியபரர் என்றும், எந்த மனுஷனும் பொய்யன் என்றும் சொல்வோமாக.
	<br /><br />
	5. நான் மனுஷர் பேசுகிற பிரகாரமாய்ப் பேசுகிறேன்; நம்முடைய அநீதி தேவனுடைய நீதியை விளங்கப்பண்ணினால் என்ன சொல்லுவோம்? கோபாக்கினையைச் செலுத்துகிற தேவன் அநீதராயிருக்கிறார் என்று சொல்லலாமா?
	<br /><br />
	6. அப்படிச் சொல்லக்கூடாது; சொல்லக்கூடுமானால், தேவன் உலகத்தை நியாயந்தீர்ப்பதெப்படி?
	<br /><br />
	7. அன்றியும், என் பொய்யினாலே தேவனுடைய சத்தியம் அவருக்கு மகிமையுண்டாக விளங்கினதுண்டானால், இனி நான் பாவியென்று தீர்க்கப்படுவானேன்?
	<br /><br />
	8. நன்மை வரும்படிக்குத் தீமைசெய்வோமாக என்றும் சொல்லலாமல்லவா? நாங்கள் அப்படிப் போதிக்கிறவர்களென்றும் சிலர் எங்களைத் தூஷித்துச் சொல்லுகிறார்களே; அப்படிப் போதிக்கிறவர்கள்மேல் வரும் ஆக்கினை நீதியாயிருக்கும்.
	<br /><br />
	9. ஆனாலும் என்ன? அவர்களைப்பார்க்கிலும் நாங்கள் விசேஷித்தவர்களா? எவ்வளவேனும் விசேஷித்தவர்களல்ல. யூதர் கிரேக்கர் யாவரும் பாவத்திற்குட்பட்டவர்களென்பதை முன்பு திருஷ்டாந்தப்படுத்தினோமே.
	<br /><br />
	10. அந்தப்படியே: நீதிமான் ஒருவனாகிலும் இல்லை;
	<br /><br />
	11. உணர்வுள்ளவன் இல்லை; தேவனைத் தேடுகிறவன் இல்லை;
	<br /><br />
	12. எல்லாரும் வழிதப்பி, ஏகமாய்க் கெட்டுப்போனார்கள்; நன்மைசெய்கிறவன் இல்லை, ஒருவனாகிலும் இல்லை.
	<br /><br />
	13. அவர்கள் தொண்டை திறக்கப்பட்ட பிரேதக்குழி, தங்கள் நாவுகளால் வஞ்சனைசெய்கிறார்கள்; அவர்களுடைய உதடுகளின் கீழே பாம்பின் விஷம் இருக்கிறது;
	<br /><br />
	14. அவர்கள் வாய் சபிப்பினாலும் கசப்பினாலும் நிறைந்திருக்கிறது;
	<br /><br />
	15. அவர்கள் கால்கள் இரத்தஞ்சிந்துகிறதற்குத் தீவிரிக்கிறது;
	<br /><br />
	16. நாசமும் நிர்ப்பந்தமும் அவர்கள் வழிகளிலிருக்கிறது;
	<br /><br />
	17. சமாதான வழியை அவர்கள் அறியாதிருக்கிறார்கள்;
	<br /><br />
	18. அவர்கள் கண்களுக்கு முன்பாகத் தெய்வபயமில்லை, என்று எழுதியிருக்கிறதே.
	<br /><br />
	19. மேலும், வாய்கள் யாவும் அடைக்கப்படும்படிக்கும், உலகத்தார் யாவரும் தேவனுடைய ஆக்கினைத்தீர்ப்புக்கு ஏதுவானவர்களாகும்படிக்கும், நியாயப்பிரமாணம் சொல்லுகிறதெல்லாம் நியாயப்பிரமாணத்துக்கு உட்பட்டிருக்கிறவர்களுக்கே சொல்லுகிறதென்று அறிந்திருக்கிறோம்.
	<br /><br />
	20. இப்படியிருக்க, பாவத்தை அறிகிற அறிவு நியாயப்பிரமாணத்தினால் வருகிறபடியால், எந்த மனுஷனும் நியாயப்பிரமாணத்தின் கிரியைகளினாலே தேவனுக்கு முன்பாக நீதிமானாக்கப்படுவதில்லை.
	<br /><br />
	21. இப்படியிருக்க, நியாயப்பிரமாணமில்லாமல் தேவநீதி வெளியாக்கப்பட்டிருக்கிறது; அதைக்குறித்து நியாயப்பிரமாணமும் தீர்க்கதரிசனங்களும் சாட்சியிடுகிறது.
	<br /><br />
	22. அது இயேசுகிறிஸ்துவைப்பற்றும் விசுவாசத்தினாலே பலிக்கும் தேவநீதியே; விசுவாசிக்கிற எவர்களுக்குள்ளும் எவர்கள்மேலும் அது பலிக்கும், வித்தியாசமே இல்லை.
	<br /><br />
	23. எல்லாரும் பாவஞ்செய்து, தேவமகிமையற்றவர்களாகி,
	<br /><br />
	24. இலவசமாய் அவருடைய கிருபையினாலே கிறிஸ்து இயேசுவிலுள்ள மீட்பைக்கொண்டு நீதிமான்களாக்கப்படுகிறார்கள்;
	<br /><br />
	25. தேவன் பொறுமையாயிருந்த முற்காலத்தில் நடந்த பாவங்களைத் தாம் பொறுத்துக்கொண்டதைக்குறித்துத் தம்முடைய நீதியைக் காண்பிக்கும்பொருட்டாகவும், தாம் நீதியுள்ளவரும், இயேசுவினிடத்தில் விசுவாசமாயிருக்கிறவனை நீதிமானாக்குகிறவருமாய் விளங்கும்படி, இக்காலத்திலே தமது நீதியைக் காண்பிக்கும் பொருட்டாகவும்,
	<br /><br />
	26. கிறிஸ்து இயேசுவினுடைய இரத்தத்தைப்பற்றும் விசுவாசத்தினாலே பலிக்கும் கிருபாதார பலியாக அவரையே ஏற்படுத்தினார்.
	<br /><br />
	27. இப்படியிருக்க, மேன்மைபாராட்டல் எங்கே? அது நீக்கப்பட்டதே. எந்தப் பிரமாணத்தினாலே? கிரியாப்பிரமாணத்தினாலேயா? அல்ல; விசுவாசப்பிரமாணத்தினாலேயே.
	<br /><br />
	28. ஆதலால், மனுஷன் நியாயப்பிரமாணத்தின் கிரியைகளில்லாமல் விசுவாசத்தினாலேயே நீதிமானாக்கப்படுகிறான் என்று தீர்க்கிறோம்.
	<br /><br />
	29. தேவன் யூதருக்குமாத்திரமா தேவன்? புறஜாதிகளுக்கும் தேவனல்லவா? ஆம் புறஜாதிகளுக்கும் அவர் தேவன்தான்.
	<br /><br />
	30. விருத்தசேதனமுள்ளவர்களை விசுவாசத்தினாலும், விருத்தசேதனமில்லாதவர்களை விசுவாசத்தின் மூலமாயும் நீதிமான்களாக்குகிற தேவன் ஒருவரே.
	<br /><br />
	31. அப்படியானால், விசுவாசத்தினாலே நியாயப்பிரமாணத்தை அவமாக்குகிறோமா? அப்படியல்ல; நியாயப்பிரமாணத்தை நிலைநிறுத்துகிறோமே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans3