import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah53 = () => {
  const verseNumber = 53;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 53 </title>
	<meta
          name="description"
          content="Isaiah 53 | ஏசாயா 53 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எங்கள் மூலமாய்க் கேள்விப்பட்டதை விசுவாசித்தவன் யார்? கர்த்தருடைய புயம் யாருக்கு வெளிப்பட்டது?
	<br /><br />
	2. இளங்கிளையைப்போலவும், வறண்ட நிலத்திலிருந்து துளிர்க்கிற வேரைப்போலவும் அவனுக்கு முன்பாக எழும்புகிறார்; அவருக்கு அழகுமில்லை, சௌந்தரியமுமில்லை; அவரைப் பார்க்கும்போது, நாம் அவரை விரும்பத்தக்க ரூபம் அவருக்கு இல்லாதிருந்தது.
	<br /><br />
	3. அவர் அசட்டைபண்ணப்பட்டவரும், மனுஷரால் புறக்கணிக்கப்பட்டவரும், துக்கம் நிறைந்தவரும், பாடு அநுபவித்தவருமாயிருந்தார்; அவரைவிட்டு, நம்முடைய முகங்களை மறைத்துக்கொண்டோம்; அவர் அசட்டைபண்ணப்பட்டிருந்தார்; அவரை எண்ணாமற்போனோம்.
	<br /><br />
	4. மெய்யாகவே அவர் நம்முடைய பாடுகளை ஏற்றுக்கொண்டு, நம்முடைய துக்கங்களைச் சுமந்தார்; நாமோ, அவர் தேவனால் அடிபட்டு வாதிக்கப்பட்டு, சிறுமைப்பட்டவரென்று எண்ணினோம்.
	<br /><br />
	5. நம்முடைய மீறுதல்களினிமித்தம் அவர் காயப்பட்டு, நம்முடைய அக்கிரமங்களினிமித்தம் அவர் நொறுக்கப்பட்டார்; நமக்குச் சமாதானத்தை உண்டுபண்ணும் ஆக்கினை அவர்மேல் வந்தது; அவருடைய தழும்புகளால் குணமாகிறோம்.
	<br /><br />
	6. நாமெல்லாரும் ஆடுகளைப்போல வழிதப்பித் திரிந்து, அவனவன் தன் தன் வழியிலே போனோம்; கர்த்தரோ நம்மெல்லாருடைய அக்கிரமத்தையும் அவர்மேல் விழப்பண்ணினார்.
	<br /><br />
	7. அவர் நெருக்கப்பட்டும் ஒடுக்கப்பட்டும் இருந்தார், ஆனாலும் தம்முடைய வாயை அவர் திறக்கவில்லை; அடிக்கப்படும்படி கொண்டுபோகப்படுகிற ஒரு ஆட்டுக்குட்டியைப்போலவும், தன்னை மயிர்கத்தரிக்கிறவனுக்கு முன்பாகச் சத்தமிடாதிருக்கிற ஆட்டைப்போலவும், அவர் தம்முடைய வாயைத் திறவாதிருந்தார்.
	<br /><br />
	8. இடுக்கணிலும் நியாயத்தீர்ப்பிலுமிருந்து அவர் எடுக்கப்பட்டார்; அவருடைய வம்சத்தை யாரால் சொல்லி முடியும்; ஜீவனுள்ளோருடைய தேசத்திலிருந்து அறுப்புண்டுபோனார்; என் ஜனத்தின் மீறுதலினிமித்தம் அவர் வாதிக்கப்பட்டார்.
	<br /><br />
	9. துன்மார்க்கரோடே அவருடைய பிரேதக்குழியை நியமித்தார்கள்; ஆனாலும் அவர் மரித்தபோது ஐசுவரியவானோடே இருந்தார்; அவர் கொடுமை செய்யவில்லை; அவர் வாயில் வஞ்சனை இருந்ததுமில்லை.
	<br /><br />
	10. கர்த்தரோ அவரை நொறுக்கச் சித்தமாகி, அவரைப் பாடுகளுக்குட்படுத்தினார்; அவருடைய ஆத்துமா தன்னைக் குற்றநிவாரணபலியாக ஒப்புக்கொடுக்கும்போது, அவர் தமது சந்ததியைக் கண்டு, நீடித்த நாளாயிருப்பார், கர்த்தருக்குச் சித்தமானது அவர் கையினால் வாய்க்கும்.
	<br /><br />
	11. அவர் தமது ஆத்தும வருத்தத்தின் பலனைக் கண்டு திருப்தியாவார்; என் தாசனாகிய நீதிபரர் தம்மைப் பற்றும் அறிவினால் அநேகரை நீதிமான்களாக்குவார்; அவர்களுடைய அக்கிரமங்களைத் தாமே சுமந்துகொள்வார்.
	<br /><br />
	12. அவர் தம்முடைய ஆத்துமாவை மரணத்திலூற்றி, அக்கிரமக்காரரில் ஒருவராக எண்ணப்பட்டு, அநேகருடைய பாவத்தைத் தாமே சுமந்து, அக்கிரமக்காரருக்காக வேண்டிக்கொண்டதினிமித்தம் அநேகரை அவருக்குப் பங்காகக் கொடுப்பேன்; பலவான்களை அவர் தமக்குக் கொள்ளையாகப் பங்கிட்டுக்கொள்வார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah53