import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneThessalonians5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO THESSALONIANS 5 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE THESSALONIANS Chapter 5 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 5 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. சகோதரரே, இவைகள் நடக்குங்காலங்களையும் சமயங்களையுங்குறித்து உங்களுக்கு எழுதவேண்டுவதில்லை.
	<br /><br />
	2. இரவிலே திருடன் வருகிறவிதமாய்க் கர்த்தருடைய நாள் வருமென்று நீங்களே நன்றாய் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	3. சமாதானமும் சவுக்கியமும் உண்டென்று அவர்கள் சொல்லும்போது, கர்ப்பவதியானவளுக்கு வேதனை வருகிறதுபோல, அழிவு சடிதியாய் அவர்கள்மேல் வரும்; அவர்கள் தப்பிப்போவதில்லை.
	<br /><br />
	4. சகோதரரே, அந்த நாள் திருடனைப்போல உங்களைப் பிடித்துக்கொள்ளத்தக்கதாக, நீங்கள் அந்தகாரத்திலிருக்கிறவர்களல்லவே.
	<br /><br />
	5. நீங்களெல்லாரும் வெளிச்சத்தின் பிள்ளைகளும், பகலின் பிள்ளைகளுமாயிருக்கிறீர்கள்; நாம் இரவுக்கும் இருளுக்கும் உள்ளானவர்களல்லவே.
	<br /><br />
	6. ஆகையால், மற்றவர்கள் தூங்குகிறதுபோல நாம் தூங்காமல், விழித்துக்கொண்டு தெளிந்தவர்களாயிருக்கக்கடவோம்.
	<br /><br />
	7. தூங்குகிறவர்கள் இராத்திரியிலே தூங்குவார்கள்; வெறிகொள்ளுகிறவர்கள் இராத்திரியிலே வெறிகொள்ளுவார்கள்.
	<br /><br />
	8. பகலுக்குரியவர்களாகிய நாமோ தெளிந்தவர்களாயிருந்து, விசுவாசம் அன்பு என்னும் மார்க்கவசத்தையும், இரட்சிப்பின் நம்பிக்கையென்னும் தலைச்சீராவையும் தரித்துக்கொண்டிருக்கக்கடவோம்.
	<br /><br />
	9. தேவன் நம்மைக் கோபாக்கினைக்கென்று நியமிக்காமல், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துமூலமாய் இரட்சிப்படைவதற்கென்று நியமித்தார்.
	<br /><br />
	10. நாம் விழித்திருப்பவர்களானாலும், நித்திரையடைந்தவர்களானாலும், தம்முடனேகூட நாம் ஏகமாய்ப் பிழைத்திருக்கும்படி அவர் நமக்காக மரித்தாரே.
	<br /><br />
	11. ஆகையால் நீங்கள் செய்துவருகிறபடியே, ஒருவரையொருவர் தேற்றி, ஒருவருக்கொருவர் பக்திவிருத்தி உண்டாகும்படி செய்யுங்கள்.
	<br /><br />
	12. அன்றியும், சகோதரரே, உங்களுக்குள்ளே பிரயாசப்பட்டு, கர்த்தருக்குள் உங்களை விசாரணைசெய்கிறவர்களாயிருந்து, உங்களுக்குப் புத்திசொல்லுகிறவர்களை நீங்கள் மதித்து,
	<br /><br />
	13. அவர்களுடைய கிரியையினிமித்தம் அவர்களை மிகவும் அன்பாய் எண்ணிக்கொள்ளும்படி உங்களை வேண்டிக்கொள்ளுகிறோம். உங்களுக்குள்ளே சமாதானமாயிருங்கள்.
	<br /><br />
	14. மேலும், சகோதரரே, நாங்கள் உங்களுக்குப் போதிக்கிறதென்னவென்றால், ஒழுங்கில்லாதவர்களுக்குப் புத்திசொல்லுங்கள், திடனற்றவர்களைத் தேற்றுங்கள், பலவீனரைத் தாங்குங்கள், எல்லாரிடத்திலும் நீடிய சாந்தமாயிருங்கள்.
	<br /><br />
	15. ஒருவனும் மற்றொருவன் செய்யும் தீமைக்குத் தீமைசெய்யாதபடி பாருங்கள்; உங்களுக்குள்ளும் மற்ற யாவருக்குள்ளும் எப்பொழுதும் நன்மைசெய்ய நாடுங்கள்.
	<br /><br />
	16. எப்பொழுதும் சந்தோஷமாயிருங்கள்.
	<br /><br />
	17. இடைவிடாமல் ஜெபம்பண்ணுங்கள்.
	<br /><br />
	18. எல்லாவற்றிலேயும் ஸ்தோத்திரஞ் செய்யுங்கள்; அப்படிச் செய்வதே கிறிஸ்து இயேசுவுக்குள் உங்களைக்குறித்துத் தேவனுடைய சித்தமாயிருக்கிறது.
	<br /><br />
	19. ஆவியை அவித்துப்போடாதிருங்கள்.
	<br /><br />
	20. தீர்க்கதரிசனங்களை அற்பமாயெண்ணாதிருங்கள்.
	<br /><br />
	21. எல்லாவற்றையும் சோதித்துப் பார்த்து, நலமானதைப் பிடித்துக்கொள்ளுங்கள்.
	<br /><br />
	22. பொல்லாங்காய்த் தோன்றுகிற எல்லாவற்றையும் விட்டு விலகுங்கள்.
	<br /><br />
	23. சமாதானத்தின் தேவன் தாமே உங்களை முற்றிலும் பரிசுத்தமாக்குவாராக. உங்கள் ஆவி ஆத்துமா சரீரம் முழுவதும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து வரும்போது குற்றமற்றதாயிருக்கும்படி காக்கப்படுவதாக.
	<br /><br />
	24. உங்களை அழைக்கிறவர் உண்மையுள்ளவர், அவர் அப்படியே செய்வார்.
	<br /><br />
	25. சகோதரரே, எங்களுக்காக வேண்டிக்கொள்ளுங்கள்.
	<br /><br />
	26. சகோதரரெல்லாரையும் பரிசுத்த முத்தத்தோடே வாழ்த்துங்கள்.
	<br /><br />
	27. இந்த நிருபம் பரிசுத்தமான சகோதரர் யாவருக்கும் வாசிக்கப்படும்படி செய்யவேண்டுமென்று கர்த்தர்பேரில் ஆணையிட்டு உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	28. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய கிருபை உங்களுடனேகூட இருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneThessalonians5