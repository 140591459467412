import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 11 </title>
	<meta
          name="description"
          content="Hosea 11 | ஓசியா 11 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேல் இளைஞனாயிருந்தபோது நான் அவனை நேசித்தேன்; எகிப்திலிருந்து என்னுடைய குமாரனை வரவழைத்தேன்.
	<br /><br />
	2. அவர்கள் தங்களை அழைக்கிறவர்களின் முகத்துக்கு விலகிப்போய்விட்டார்கள்; பாகால்களுக்குப் பலியிட்டு, விக்கிரகங்களுக்குத் தூபங்காட்டினார்கள்.
	<br /><br />
	3. நான் எப்பிராயீமைக் கைபிடித்து நடக்கப் பழக்கினேன்; ஆனாலும் நான் தங்களைக் குணமாக்குகிறவரென்று அறியாமற்போனார்கள்.
	<br /><br />
	4. மனுஷரைக் கட்டி இழுக்கிற அன்பின் கயிறுகளால் நான் அவர்களை இழுத்தேன், அவர்கள் கழுத்துகளின்மேல் இருந்த நுகத்தடியை எடுத்துப் போடுகிறவரைப்போல் இருந்து, அவர்கள் பட்சம் சாய்ந்து, அவர்களுக்கு ஆகாரங்கொடுத்தேன்.
	<br /><br />
	5. மனந்திரும்பமாட்டோமென்றதினால் அவர்கள் எகிப்துதேசத்துக்குத் திரும்பிப்போவதில்லை; அசீரியன் அவர்களுக்கு ராஜா.
	<br /><br />
	6. ஆகையால் அவர்களுடைய ஆலோசனைகளினிமித்தம் பட்டயம் அவர்கள் பட்டணங்களுக்குள் பாய்ந்து, அவர்கள் தாழ்ப்பாள்களை நிர்மூலம்பண்ணி, அவர்களைப் பட்சிக்கும்.
	<br /><br />
	7. என் ஜனங்கள் என்னைவிட்டு விலகுகிற மாறுபாட்டைப் பற்றிக்கொண்டிருக்கிறார்கள்; அவர்களை உன்னதமானவரிடத்தில் வரவழைத்தாலும் ஒருவனும் எழும்புகிறதில்லை.
	<br /><br />
	8. எப்பிராயீமே, நான் உன்னை எப்படிக் கைவிடுவேன்? இஸ்ரவேலே, நான் உன்னை எப்படி ஒப்புக்கொடுப்பேன்? நான் உன்னை எப்படி அத்மாவைப்போலாக்குவேன்? உன்னை எப்படிச் செபோயீமைப்போல வைப்பேன்? என் இருதயம் எனக்குள் குழம்புகிறது; என் பரிதாபங்கள் ஏகமாய்ப் பொங்குகிறது.
	<br /><br />
	9. என் உக்கிர கோபத்தின்படியே செய்யமாட்டேன்; எப்பிராயீமை அழிக்கும்படித் திரும்பமாட்டேன்; ஏனென்றால் நான் மனுஷனல்ல, தேவனாயிருக்கிறேன்; நான் உன் நடுவிலுள்ள பரிசுத்தர்; ஆகையால் பட்டணத்துக்கு விரோதமாக வரேன்.
	<br /><br />
	10. அவர்கள் கர்த்தரைப் பின்பற்றுவார்கள்; அவர் சிங்கத்தைப்போல் கெர்ச்சிப்பார்; அவர் கெர்ச்சிக்கும்போது அவர்கள் சந்ததியார் மேற்குத்திசையிலிருந்து நடுங்கி வருவார்கள்.
	<br /><br />
	11. எகிப்திலிருந்து குருவிகளைப்போலவும், அசீரியா தேசத்திலிருந்து புறாக்களைப்போலவும் பயந்து வருவார்கள்; அப்பொழுது அவர்களை அவர்கள் வீடுகளில் குடியிருக்கப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. எப்பிராயீமர் பொய்களினாலும், இஸ்ரவேல் வம்சத்தார் வஞ்சகத்தினாலும் என்னைச் சூழ்ந்துகொண்டிருக்கிறார்கள்; யூதாவோவெனில் இன்னும் தேவனோடே அரசாண்டு, பரிசுத்தவான்களோடே உண்மையாயிருக்கிறான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea11