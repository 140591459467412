import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts23 = () => {
  const verseNumber = 23;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 23 </title>
	<meta
          name="description"
          content="Acts 23 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 23 |
          Acts of Apostles Chapter-23 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பவுல் ஆலோசனைச் சங்கத்தாரை உற்றுப்பார்த்து: சகோதரரே, இந்நாள்வரைக்கும் எல்லா விஷயங்களிலும் நான் நல்மனச்சாட்சியோடே தேவனுக்குமுன்பாக நடந்துவந்தேன் என்று சொன்னான்.
	<br /><br />
	2. அப்பொழுது பிரதான ஆசாரியனாகிய அனனியா அவனுக்குச் சமீபமாய் நின்றவர்களை நோக்கி: இவன் வாயில் அடியுங்கள் என்று கட்டளையிட்டான்.
	<br /><br />
	3. அப்பொழுது பவுல் அவனைப்பார்த்து: வெள்ளையடிக்கப்பட்ட சுவரே, தேவன் உம்மை அடிப்பார்; நியாயப்பிரமாணத்தின்படி என்னை நியாயம் விசாரிக்கிறவராய் உட்கார்ந்திருக்கிற நீர் நியாயப்பிரமாணத்திற்கு விரோதமாய் என்னை அடிக்கச் சொல்லலாமா என்றான்.
	<br /><br />
	4. சமீபத்திலே நின்றவர்கள்: தேவனுடைய பிரதானஆசாரியரை வைகிறாயா என்றார்கள்.
	<br /><br />
	5. அதற்குப் பவுல்: சகோதரரே, இவர் பிரதான ஆசாரியரென்று எனக்குத் தெரியாது; உன் ஜனத்தின் அதிபதியைத் தீது சொல்லாயாக என்று எழுதியிருக்கிறதே என்றான்.
	<br /><br />
	6. பின்பு அவர்களில், சதுசேயர் ஒரு பங்கும் பரிசேயர் ஒரு பங்குமாயிருக்கிறார்களென்று பவுல் அறிந்து: சகோதரரே, நான் பரிசேயனும் பரிசேயனுடைய மகனுமாயிருக்கிறேன். மரித்தோருடைய உயிர்த்தெழுதலைப்பற்றிய நம்பிக்கையைக்குறித்து நான் நியாயம் விசாரிக்கப்படுகிறேன் என்று ஆலோசனைச் சங்கத்திலே சத்தமிட்டுச் சொன்னான்.
	<br /><br />
	7. அவன் இப்படிச் சொன்னபோது, பரிசேயருக்கும் சதுசேயருக்கும் வாக்குவாதமுண்டாயிற்று; கூட்டம் இரண்டாகப் பிரிந்தது.
	<br /><br />
	8. என்னத்தினாலென்றால், சதுசேயர் உயிர்த்தெழுதல் இல்லையென்றும், தேவதூதனும் ஆவியும் இல்லையென்றும் சொல்லுகிறார்கள். பரிசேயரோ அவ்விரண்டும் உண்டென்று ஒப்புக்கொள்ளுகிறார்கள்.
	<br /><br />
	9. ஆகையால் மிகுந்த கூக்குரல் உண்டாயிற்று. பரிசேய சமயத்தாரான வேதபாரகரில் சிலர் எழுந்து: இந்த மனுஷனிடத்தில் ஒரு பொல்லாங்கையும் காணோம்; ஒரு ஆவி அல்லது ஒரு தேவதூதன் இவனுடனே பேசினதுண்டானால், நாம் தேவனுடனே போர்செய்வது தகாது என்று வாதாடினார்கள்.
	<br /><br />
	10. மிகுந்த கலகம் உண்டானபோது, பவுல் அவர்களால் பீறுண்டுபோவானென்று சேனாபதி பயந்து, போர்ச்சேவகர் போய், அவனை அவர்கள் நடுவிலிருந்து இழுத்துக் கோட்டைக்குக் கொண்டுபோகும்படி கட்டளையிட்டான்.
	<br /><br />
	11. அன்று இராத்திரியிலே கர்த்தர் பவுலின் அருகே நின்று: பவுலே, திடன்கொள்; நீ என்னைக்குறித்து எருசலேமிலே சாட்சிகொடுத்ததுபோல ரோமாவிலும் சாட்சிகொடுக்கவேண்டும் என்றார்.
	<br /><br />
	12. விடியற்காலமானபோது, யூதரில் சிலர் ஒருமித்து, தாங்கள் பவுலைக் கொலைசெய்யுமளவும் புசிப்பதுமில்லை குடிப்பதுமில்லையென்று சபதம்பண்ணிக்கொண்டார்கள்.
	<br /><br />
	13. இப்படிக் கட்டுப்பாடு பண்ணிக்கொண்டவர்கள் நாற்பதுபேருக்கு அதிகமாயிருந்தார்கள்.
	<br /><br />
	14. அவர்கள் பிரதானஆசாரியர்களிடத்திலும் மூப்பர்களிடத்திலும் போய்: நாங்கள் பவுலைக் கொலைசெய்யுமளவும் ஒன்றும் புசிப்பதில்லையென்று உறுதியான சபதம் பண்ணிக்கொண்டோம்.
	<br /><br />
	15. ஆனபடியினால் நீங்கள் ஆலோசனைச் சங்கத்தாரோடே கூடப்போய், அவனுடைய காரியத்தை அதிக திட்டமாய் விசாரிக்க மனதுள்ளவர்கள்போலச் சேனாபதிக்குக் காண்பித்து, அவர் நாளைக்கு அவனை உங்களிடத்தில் கூட்டிக்கொண்டு வரும்படி அவரிடத்தில் கேட்பீர்களாக. அவன் கிட்டவருகிறதற்குள்ளே நாங்கள் அவனைக் கொலைசெய்ய ஆயத்தமாயிருப்போம் என்றார்கள்.
	<br /><br />
	16. இந்தச் சர்ப்பனையைப் பவுலினுடைய சகோதரியின் குமாரன் கேள்விப்பட்டு, கோட்டைக்குள்ளே போய், பவுலுக்கு அறிவித்தான்.
	<br /><br />
	17. அப்பொழுது பவுல் நூற்றுக்கு அதிபதிகளில் ஒருவனை அழைத்து, இந்த வாலிபனைச் சேனாபதியினிடத்திற்குக் கூட்டிக்கொண்டுபோம்; அவரிடத்தில் இவன் அறிவிக்கவேண்டிய ஒரு காரியம் உண்டு என்றான்.
	<br /><br />
	18. அந்தப்படியே அவன் இவனைச் சேனாபதியினிடத்திற்குக் கூட்டிக்கொண்டுபோய்: காவலில் வைக்கப்பட்டிருக்கிற பவுல் என்னை அழைத்து, உமக்கொரு காரியத்தைச் சொல்லவேண்டுமென்றிருக்கிற இந்த வாலிபனை உம்மிடத்திற்குக் கொண்டுபோகும்படி என்னைக் கேட்டுக்கொண்டான் என்றான்.
	<br /><br />
	19. அப்பொழுது சேனாபதி அவனுடைய கையைப் பிடித்துத் தனியே அழைத்துக்கொண்டுபோய்: நீ என்னிடத்தில் அறிவிக்கவேண்டிய காரியம் என்னவென்று கேட்டான்.
	<br /><br />
	20. அதற்கு அவன்: யூதர்கள் பவுலின் காரியத்தை அதிக திட்டமாய் விசாரிக்க மனதுள்ளவர்கள்போல, நீர் நாளைக்கு அவனை ஆலோசனைச் சங்கத்தாரிடத்தில் கொண்டுவரும்படி உம்மை வேண்டிக்கொள்ள உடன்பட்டிருக்கிறார்கள்.
	<br /><br />
	21. நீர் அவர்களுக்குச் சம்மதிக்கவேண்டாம்; அவர்களில் நாற்பதுபேருக்கு அதிகமானவர்கள் அவனைக் கொலைசெய்யுமளவும் தாங்கள் புசிப்பதுமில்லை குடிப்பதுமில்லையென்று சபதம்பண்ணிக்கொண்டு, அவனுக்குப் பதிவிருந்து, உம்முடைய உத்தரவுக்காக இப்பொழுது காத்துக்கொண்டு ஆயத்தமாயிருக்கிறார்கள் என்றான்.
	<br /><br />
	22. அப்பொழுது சேனாபதி: நீ இவைகளை எனக்கு அறிவித்ததாக ஒருவருக்குஞ் சொல்லாதே என்று கட்டளையிட்டு, வாலிபனை அனுப்பிவிட்டான்.
	<br /><br />
	23. பின்பு அவன் நூற்றுக்கு அதிபதிகளில் இரண்டுபேரை அழைத்து, செசரியாபட்டணத்திற்குப் போகும்படி இருநூறு காலாட்களையும், எழுபது குதிரைவீரரையும், இருநூறு ஈட்டிக்காரரையும், இராத்திரியில் மூன்றாம்மணி வேளையிலே, ஆயத்தம்பண்ணுங்களென்றும்;
	<br /><br />
	24. பவுலை ஏற்றி, தேசாதிபதியாகிய பேலிக்ஸினிடத்திற்குப் பத்திரமாய்க் கொண்டுபோகும்படிக்குக் குதிரைகளை ஆயத்தப்படுத்துங்களென்றும் சொன்னதுமன்றி,
	<br /><br />
	25. ஒரு நிருபத்தையும் எழுதினான்; அதின் விவரமாவது:
	<br /><br />
	26. கனம்பொருந்திய தேசாதிபதியாகிய பேலிக்ஸ் என்பவருக்குக் கிலவுதியு லீசியா வாழ்த்துதல் சொல்லி அறிவிக்கிறது என்னவென்றால்:
	<br /><br />
	27. இந்த மனுஷனை யூதர் பிடித்துக் கொலைசெய்யப்போகிற சமயத்தில், நான் போர்ச்சேவகரோடே கூடப்போய், இவன் ரோமனென்று அறிந்து, இவனை விடுவித்தேன்.
	<br /><br />
	28. அவர்கள் இவன்மேல் சாட்டின குற்றத்தை நான் அறியவேண்டுமென்று இவனை அவர்கள் ஆலோசனைச் சங்கத்துக்குமுன் கொண்டுபோனேன்.
	<br /><br />
	29. இவன் அவர்களுடைய வேதத்திற்கடுத்த விஷயங்களைக்குறித்துக் குற்றஞ்சாட்டப்பட்டவனென்று விளங்கினதேயல்லாமல், மரணத்துக்காவது விலங்குக்காவது ஏற்ற குற்றம் யாதொன்றும் இவனிடத்தில் இல்லையென்று கண்டறிந்தேன்.
	<br /><br />
	30. யூதர்கள் இவனுக்கு விரோதமாய்ச் சர்ப்பனையான யோசனை செய்கிறார்களென்று எனக்குத் தெரியவந்தபோது, உடனே இவனை உம்மிடத்திற்கு அனுப்பினேன்; குற்றஞ்சாட்டுகிறவர்களும் இவனுக்கு விரோதமாய்ச் சொல்லுகிற காரியங்களை உமக்கு முன்பாக வந்து சொல்லும்படி அவர்களுக்குக் கட்டளையிட்டேன். சுகமாயிருப்பீராக, என்றெழுதினான்.
	<br /><br />
	31. போர்ச்சேவகர் தங்களுக்குக் கட்டளையிட்டபடியே, பவுலைக் கூட்டிக்கொண்டு, இராத்திரியிலே அந்திப்பத்திரி ஊருக்குப் போய்,
	<br /><br />
	32. மறுநாளில் குதிரைவீரரை அவனுடனேகூடப் போகும்படி அனுப்பிவிட்டு, தாங்கள் கோட்டைக்குத் திரும்பினார்கள்.
	<br /><br />
	33. அவர்கள் செசரியாபட்டணத்தில் சேர்ந்து, நிருபத்தைத் தேசாதிபதியினிடத்தில் கொடுத்து, பவுலையும் அவன் முன்பாக நிறுத்தினார்கள்.
	<br /><br />
	34. தேசாதிபதி அதை வாசித்து: எந்த நாட்டானென்று கேட்டு, சிலிசியா நாட்டானென்று அறிந்தபோது:
	<br /><br />
	35. உன்மேல் குற்றஞ்சாட்டுகிறவர்களும் வந்திருக்கும்போது உன் காரியத்தைத் திட்டமாய்க் கேட்பேனென்று சொல்லி, ஏரோதின் அரமனையிலே அவனைக் காவல்பண்ணும்படி கட்டளையிட்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts23