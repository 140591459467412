import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 12 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 12
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்படியிருக்க, சகோதரரே, நீங்கள் உங்கள் சரீரங்களைப் பரிசுத்தமும் தேவனுக்குப் பிரியமுமான ஜீவபலியாக ஒப்புக்கொடுக்கவேண்டுமென்று, தேவனுடைய இரக்கங்களை முன்னிட்டு உங்களை வேண்டிக்கொள்ளுகிறேன்; இதுவே நீங்கள் செய்யத்தக்க புத்தியுள்ள ஆராதனை.
	<br /><br />
	2. நீங்கள் இந்தப் பிரபஞ்சத்திற்கு ஒத்த வேஷந்தரியாமல், தேவனுடைய நன்மையும் பிரியமும் பரிபூரணமுமான சித்தம் இன்னதென்று பகுத்தறியத்தக்கதாக, உங்கள் மனம் புதிதாகிறதினாலே மறுரூபமாகுங்கள்.
	<br /><br />
	3. அல்லாமலும், எனக்கு அருளப்பட்ட கிருபையினாலே நான் சொல்லுகிறதாவது; உங்களில் எவனானாலும் தன்னைக்குறித்து எண்ணவேண்டியதற்கு மிஞ்சி எண்ணாமல், அவனவனுக்குத் தேவன் பகிர்ந்த விசுவாச அளவின்படியே, தெளிந்த எண்ணமுள்ளவனாய் எண்ணவேண்டும்.
	<br /><br />
	4. ஏனெனில், நமக்கு ஒரே சரீரத்திலே அநேக அவயவங்களிருந்தும், எல்லா அவயவங்களுக்கும் ஒரே தொழில் இராததுபோல,
	<br /><br />
	5. அநேகராகிய நாமும் கிறிஸ்துவுக்குள் ஒரே சரீரமாயிருக்க, ஒருவருக்கொருவர் அவயவங்களாயிருக்கிறோம்.
	<br /><br />
	6. நமக்கு அருளப்பட்ட கிருபையின்படியே நாம் வெவ்வேறான வரங்களுள்ளவர்களானபடியினாலே, நம்மில் தீர்க்கதரிசனஞ்சொல்லுகிற வரத்தையுடையவன் விசுவாசப்பிரமாணத்துக்கேற்றதாகச் சொல்லக்கடவன்.
	<br /><br />
	7. ஊழியஞ்செய்கிறவன் ஊழியத்திலும், போதிக்கிறவன் போதிக்கிறதிலும்,
	<br /><br />
	8. புத்திசொல்லுகிறவன் புத்திசொல்லுகிறதிலும் தரித்திருக்கக்கடவன்; பகிர்ந்துகொடுக்கிறவன் வஞ்சனையில்லாமல் கொடுக்கக்கடவன்; முதலாளியானவன் ஜாக்கிரதையாயிருக்கக்கடவன்; இரக்கஞ்செய்கிறவன் உற்சாகத்துடனே செய்யக்கடவன்.
	<br /><br />
	9. உங்கள் அன்பு மாயமற்றதாயிருப்பதாக; தீமையை வெறுத்து, நன்மையைப்பற்றிக்கொண்டிருங்கள்.
	<br /><br />
	10. சகோதர சிநேகத்திலே ஒருவர்மேலொருவர் பட்சமாயிருங்கள்; கனம்பண்ணுகிறதிலே ஒருவருக்கொருவர் முந்திக்கொள்ளுங்கள்.
	<br /><br />
	11. அசதியாயிராமல் ஜாக்கிரதையாயிருங்கள்; ஆவியிலே அனலாயிருங்கள்; கர்த்தருக்கு ஊழியஞ்செய்யுங்கள்.
	<br /><br />
	12. நம்பிக்கையிலே சந்தோஷமாயிருங்கள்; உபத்திரவத்திலே பொறுமையாயிருங்கள்; ஜெபத்திலே உறுதியாய்த் தரித்திருங்கள்.
	<br /><br />
	13. பரிசுத்தவான்களுடைய குறைவில் அவர்களுக்கு உதவிசெய்யுங்கள்; அந்நியரை உபசரிக்க நாடுங்கள்.
	<br /><br />
	14. உங்களைத் துன்பப்படுத்துகிறவர்களை ஆசீர்வதியுங்கள்; ஆசீர்வதிக்கவேண்டியதேயன்றிச் சபியாதிருங்கள்.
	<br /><br />
	15. சந்தோஷப்படுகிறவர்களுடனே சந்தோஷப்படுங்கள்; அழுகிறவர்களுடனே அழுங்கள்.
	<br /><br />
	16. ஒருவரோடொருவர் ஏகசிந்தையுள்ளவர்களாயிருங்கள்; மேட்டிமையானவைகளைச் சிந்தியாமல், தாழ்மையானவர்களுக்கு இணங்குங்கள்; உங்களையே புத்திமான்களென்று எண்ணாதிருங்கள்.
	<br /><br />
	17. ஒருவனுக்கும் தீமைக்குத் தீமைசெய்யாதிருங்கள்; எல்லா மனுஷருக்கு முன்பாகவும் யோக்கியமானவைகளைச் செய்ய நாடுங்கள்.
	<br /><br />
	18. கூடுமானால் உங்களாலானமட்டும் எல்லா மனுஷரோடும் சமாதானமாயிருங்கள்.
	<br /><br />
	19. பிரியமானவர்களே, பழிவாங்குதல் எனக்குரியது, நானே பதிற்செய்வேன், என்று கர்த்தர் சொல்லுகிறார் என்று எழுதியிருக்கிறபடியால், நீங்கள் பழிவாங்காமல், கோபாக்கினைக்கு இடங்கொடுங்கள்.
	<br /><br />
	20. அன்றியும், உன் சத்துரு பசியாயிருந்தால், அவனுக்குப் போஜனங்கொடு; அவன் தாகமாயிருந்தால், அவனுக்குப் பானங்கொடு; நீ இப்படிச் செய்வதினால் அக்கினித்தழலை அவன் தலையின்மேல் குவிப்பாய்.
	<br /><br />
	21. நீ தீமையினாலே வெல்லப்படாமல், தீமையை நன்மையினாலே வெல்லு.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans12