import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial26 = () => {
  const verseNumber = 26;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 26 </title>
	<meta
          name="description"
          content="Ezekiel 26 | எசேக்கியேல்  26 |
          Ezekiel 26 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பதினோராம் வருஷம் முதலாந்தேதியிலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, தீருவானது எருசலேமுக்கு விரோதமாக, ஆ ஆ, ஜனசதளங்களின் ஒலிமுகவாசலாயிருந்த நகரி இடிக்கப்பட்டதென்றும் என்னிடமாக எல்லாம் புரண்டுவரும், அது பாழாக்கப்பட்டிருக்க நான் நிரப்பப்படுவேன் என்றும் சொல்லுகிறபடியினால்,
	<br /><br />
	3. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: தீருவே, இதோ, நான் உனக்கு விரோதமாக வருகிறேன்; சமுத்திரம் தன் அலைகளை எழும்பிவரப்பண்ணுகிற வண்ணமாய் நான் அநேகம் ஜாதிகளை உனக்கு விரோதமாக எழும்பி வரப்பண்ணுவேன்.
	<br /><br />
	4. அவர்கள் தீருவின் மதில்களை அழித்து, அதின் கொத்தளங்களை இடித்துப்போடுவார்கள்; நான் அதின் மண்ணும் அதில் இராதபடிக்கு விளக்கிப்போட்டு, அதை வெறும் பாறையாக்கிவிடுவேன்.
	<br /><br />
	5. அது வலைகளை விரிக்கிற இடமாகச் சமுத்திரத்தின் நடுவிலே இருக்கும்; நான் இதைச் சொன்னேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்; அது ஜாதிகளுக்குக் கொள்ளையாகும்.
	<br /><br />
	6. வெளியில் இருக்கிற அதின் குமாரத்திகளோ பட்டயத்தால் கொன்றுபோடப்படுவார்கள்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	7. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் ராஜாதிராஜாவாகிய நேபுகாத்நேச்சார் என்னும் பாபிலோன் ராஜாவை வடக்கேயிருந்து குதிரைகளோடும் இரதங்களோடும் குதிரைவீரரோடும் கூட்டத்தோடும் திரளான ஜனத்தோடும் தீருவுக்கு விரோதமாக வரப்பண்ணுவேன்.
	<br /><br />
	8. அவன் வெளியில் இருக்கிற உன் குமாரத்திகளைப் பட்டயத்தினால் கொன்று, உனக்கு விரோதமாகக் கொத்தளங்களைக் கட்டி, உனக்கு விரோதமாக அணைபோட்டு, உனக்கு விரோதமாகக் கேடயங்களை எடுத்து,
	<br /><br />
	9. உன் மதில்களை இடிக்கிற யந்திரங்களை எதிரே வைத்து, தன் கட்டைப்பாரைகளால் உன் கொத்தளங்களை இடித்துப்போடுவான்.
	<br /><br />
	10. அவன் குதிரைகளின் ஏராளத்தினால் தூள் எழும்பி உன்னை மூடும்; இடித்துத் திறப்பாக்கப்பட்ட பட்டணத்தில் பிரவேசிக்கும் வண்ணமாக, அவன் உன் வாசல்களுக்குள் பிரவேசிக்கையில், குதிரைவீரரும் வண்டில்களும் இரதங்களும் இரைகிற சத்தத்தினாலே உன் மதில்கள் அதிரும்.
	<br /><br />
	11. தன் குதிரைகளின் குளம்புகளினால் உன் வீதிகளையெல்லாம் மிதிப்பான்; உன் ஜனத்தைப் பட்டயத்தினால் கொன்றுபோடுவான்; உன் பலமான தூண்கள் தரையில் விழுந்துபோகும்.
	<br /><br />
	12. அவர்கள் உன் ஆஸ்தியைக் கொள்ளையிட்டு, உன் சரக்குகளைச் சூறையாடி, உன் மதில்களை இடித்து, உனக்கு விருப்பமான வீடுகளை அழித்து, உன் கல்லுகளையும் உன் மரங்களையும் உன் மண்ணையும் கடலின் நடுவிலே போட்டுவிடுவார்கள்.
	<br /><br />
	13. உன் பாட்டுகளின் சத்தத்தை ஓயப்பண்ணுவேன்; உன் சுரமண்டலங்களின் சத்தம் இனிக் கேட்கப்படுவதில்லை.
	<br /><br />
	14. உன்னை வெறும் பாறையாக்கிவிடுவேன்; நீ வலைகளை விரிக்கிற ஸ்தலமாயிருப்பாய்; இனிக் கட்டப்படாய்; கர்த்தராகிய நான் இதைச் சொன்னேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	15. தீருவுக்குக் கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: காயம்பட்டவர்கள் அலறும்போதும், உன் நடுவில் சங்காரம் நடக்கும்போதும், நீ விழுகிற சத்தத்தினால் தீவுகள் அதிராதோ?
	<br /><br />
	16. கடலரசர் எல்லாரும் தங்கள் சிங்காசனங்களைவிட்டு இறங்கி; தங்கள் சால்வைகளைக் கழற்றி, தங்கள் சித்திரத்தையலாடைகளை உரிந்துபோடுவார்கள்; நடுக்கமே அவர்கள் உடையாகும்; தரையிலே உட்கார்ந்து, நிமிஷந்தோறும் தத்தளித்து, உன்னிமித்தம் பிரமிப்பார்கள்.
	<br /><br />
	17. அவர்கள் உன்னிமித்தம் புலம்பி, உன்னைக் குறித்து: கடல் சஞ்சாரிகள் குடியிருந்த புகழ்பெற்ற நகரியே, ஐயோ! உன்னில் தங்கினவர்களுக்கெல்லாம் பயமுண்டாக்கின உன் குடிகளோடுங்கூடச் சமுத்திரத்திலே பலத்திருந்த நீ அழிந்துபோனாயோ!
	<br /><br />
	18. நீ விழும் நாளில் தீவுகள் தத்தளிக்கும்; நீ அகன்றுபோகும்போது சமுத்திரத்திலுள்ள தீவுகள் கலங்கும் என்பார்கள்.
	<br /><br />
	19. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நான் உன்னைக் குடியில்லாத நகரங்களைப்போலப் பாழான நகரமாக்கும்போதும், மிகுந்த தண்ணீர்கள் உன்னை மூடத்தக்கதாய் நான் உன்மேல் சமுத்திரத்தை வரப்பண்ணும்போதும்,
	<br /><br />
	20. பூர்வகாலத்து ஜனத்தண்டைக்குக் குழியில் இறங்குகிறவர்களோடே நான் உன்னை இறங்கப்பண்ணுவேன்; நீ குடியேறாதிருக்கும்படி பூர்வகாலமுதற்கொண்டு பாழாயிருக்கிற பூமியின் தாழ்விடங்களிலே குழியில் இறங்குகிறவர்களோடேகூட நான் உன்னைத் தங்கியிருக்கப்பண்ணுவேன்; ஜீவனுள்ளோருடைய தேசத்திலோ மகிமை விளங்கச் செய்வேன்.
	<br /><br />
	21. உன்னை மகா பயங்கரமாக வைப்பேன்; இனி நீ இருக்கமாட்டாய்; நீ தேடப்பட்டாலும் இனி என்றைக்கும் காணப்படமாட்டாய் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial26