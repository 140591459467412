import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts27 = () => {
  const verseNumber = 27;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 27 </title>
	<meta
          name="description"
          content="Acts 27 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 27 |
          Acts of Apostles Chapter-27 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நாங்கள் இத்தாலியா தேசத்துக்குக் கப்பல் ஏறிப் போகும்படி தீர்மானிக்கப்பட்டபோது, பவுலையும் காவலில் வைக்கப்பட்டிருந்த வேறுசிலரையும் அகுஸ்து பட்டாளத்தைச்சேர்ந்த யூலியு என்னும் பேர்கொண்ட நூற்றுக்கு அதிபதியினிடத்தில் ஒப்புவித்தார்கள்.
	<br /><br />
	2. அதிரமித்தியம் ஊர்க்கப்பலில் நாங்கள் ஏறி, ஆசியா நாட்டுக் கரைபிடித்தோடவேண்டுமென்று நினைத்துப் புறப்பட்டோம். மக்கெதோனியா தேசத்துத் தெசலோனிக்கே பட்டணத்தானாகிய அரிஸ்தர்க்கு எங்களுடனேகூட இருந்தான்.
	<br /><br />
	3. மறுநாள் சீதோன் துறைபிடித்தோம். யூலியு பவுலைப் பட்சமாய் நடப்பித்து, அவன் தன் சிநேகிதரிடத்திலே போய்ப் பராமரிப்படையும்படிக்கு உத்தரவு கொடுத்தான்.
	<br /><br />
	4. அவ்விடம்விட்டு நாங்கள் புறப்பட்டு, எதிர்காற்றாயிருந்தபடியினால், சீப்புருதீவின் ஒதுக்கிலே ஓடினோம்.
	<br /><br />
	5. பின்பு சிலிசியா பம்பிலியா நாடுகளின் கடல்வழியாய் ஓடி, லீசியா நாட்டு மீறாப்பட்டணத்தில் சேர்ந்தோம்.
	<br /><br />
	6. இத்தாலியாவுக்குப் போகிற அலெக்சந்திரியாபட்டணத்துக் கப்பலை நூற்றுக்கு அதிபதி அங்கே கண்டு, எங்களை அதில் ஏற்றினான்.
	<br /><br />
	7. காற்று எங்களைத் தடுத்தபடியினாலே, நாங்கள் அநேகநாள் மெதுவாய்ச் சென்று, வருத்தத்தோடே கினீதுபட்டணத்திற்கு எதிரே வந்து, சல்மோனே ஊருக்கு எதிராய்க் கிரேத்தாதீவின் ஒதுக்கில் ஓடினோம்.
	<br /><br />
	8. அதை வருத்தத்தோடே கடந்து, நல்ல துறைமுகம் என்னப்பட்ட ஒரு இடத்திற்கு வந்தோம்; லசேயபட்டணம் அதற்குச் சமீபமாயிருந்தது.
	<br /><br />
	9. வெகுகாலம் சென்று, உபவாசநாளும் கழிந்துபோனபடியினாலே, இனிக் கப்பல்யாத்திரை செய்கிறது மோசத்திற்கு ஏதுவாயிருக்குமென்று, பவுல் அவர்களை நோக்கி:
	<br /><br />
	10. மனுஷரே, இந்த யாத்திரையினாலே சரக்குக்கும் கப்பலுக்கும் மாத்திரமல்ல, நம்முடைய ஜீவனுக்கும் வருத்தமும் மிகுந்த சேதமும் உண்டாயிருக்குமென்று காண்கிறேன் என்று சொல்லி, அவர்களை எச்சரித்தான்.
	<br /><br />
	11. நூற்றுக்கு அதிபதி பவுலினால் சொல்லப்பட்டவைகளைப் பார்க்கிலும் மாலுமியையும் கப்பல் எஜமானையும் அதிகமாய் நம்பினான்.
	<br /><br />
	12. அந்தத் துறைமுகம் மழைகாலத்திலே தங்குவதற்கு வசதியாயிராதபடியினால், அவ்விடத்தை விட்டுத் தென்மேற்கையும் வடமேற்கையும் நோக்கியிருக்கும் கிரேத்தாதீவிலுள்ள துறைமுகமாகிய பேனிக்ஸ் என்னும் இடத்தில் சேரக்கூடுமானால் சேர்ந்து, மழைகாலத்தில் தங்கும்படி அநேகம்பேர் ஆலோசனை சொன்னார்கள்.
	<br /><br />
	13. தென்றல் மெதுவாயடித்தபடியால், தாங்கள் கோரினது கைகூடிவந்ததென்று எண்ணி, அவ்விடம்விட்டுப் பெயர்ந்து கிரேத்தாதீவுக்கு அருகாக ஓடினார்கள்.
	<br /><br />
	14. கொஞ்சநேரத்துக்குள்ளே யூரோக்கிலிதோன் என்னுங் கடுங்காற்று அதில் மோதிற்று.
	<br /><br />
	15. கப்பல் அதில் அகப்பட்டுக்கொண்டு, காற்றுக்கு எதிர்த்துப்போகக்கூடாதபடியினால் காற்றின் போக்கிலே கொண்டுபோகப்பட்டோம்.
	<br /><br />
	16. அப்படிக் கிலவுதா என்னப்பட்ட ஒரு சின்ன தீவின் ஒதுக்கிலே ஓடுகையில் வெகு வருத்தத்தோடே படவை வசப்படுத்தினோம்.
	<br /><br />
	17. அதை அவர்கள் தூக்கியெடுத்தபின்பு, பல உபாயங்கள் செய்து, கப்பலைச்சுற்றிக் கட்டி, சொரிமணலிலே விழுவோமென்று பயந்து, பாய்களை இறக்கி, இவ்விதமாய்க் கொண்டுபோகப்பட்டார்கள்.
	<br /><br />
	18. மேலும் பெருங்காற்று மழையில் நாங்கள் மிகவும் அடிபட்டபடியினால், மறுநாளில் சில சரக்குகளைக் கடலில் எறிந்தார்கள்.
	<br /><br />
	19. மூன்றாம் நாளிலே கப்பலின் தளவாடங்களை எங்கள் கைகளினாலே எடுத்து எறிந்தோம்.
	<br /><br />
	20. அநேகநாளாய்ச் சூரியனாவது நட்சத்திரங்களாவது காணப்படாமல், மிகுந்த பெருங்காற்றுமழையும் அடித்துக் கொண்டிருந்தபடியினால், இனி தப்பிப் பிழைப்போமென்னும் நம்பிக்கை முழுமையும் அற்றுப்போயிற்று.
	<br /><br />
	21. அநேகநாள் அவர்கள் போஜனம்பண்ணாமல் இருந்தபோது, பவுல் அவர்கள் நடுவிலே நின்று: மனுஷரே, இந்த வருத்தமும் சேதமும் வராதபடிக்கு என் சொல்லைக்கேட்டு, கிரேத்தாதீவை விட்டுப்புறப்படாமல் இருக்கவேண்டியதாயிருந்தது.
	<br /><br />
	22. ஆகிலும், திடமனதாயிருங்களென்று இப்பொழுது உங்களுக்குத் தைரியஞ்சொல்லுகிறேன். கப்பற்சேதமேயல்லாமல் உங்களில் ஒருவனுக்கும் பிராணச்சேதம் வராது.
	<br /><br />
	23. ஏனென்றால், என்னை ஆட்கொண்டவரும் நான் சேவிக்கிறவருமான தேவனுடைய தூதனானவன் இந்த இராத்திரியிலே என்னிடத்தில் வந்துநின்று:
	<br /><br />
	24. பவுலே, பயப்படாதே, நீ இராயனுக்கு முன்பாக நிற்கவேண்டும். இதோ, உன்னுடனேகூட யாத்திரைபண்ணுகிற யாவரையும் தேவன் உனக்குத் தயவுபண்ணினார் என்றான்.
	<br /><br />
	25. ஆனபடியினால் மனுஷரே, திடமனதாயிருங்கள். எனக்குச் சொல்லப்பட்ட பிரகாரமாகவே நடக்கும் என்று தேவனிடத்தில் நம்பிக்கையாயிருக்கிறேன்.
	<br /><br />
	26. ஆயினும் நாம் ஒரு தீவிலே விழவேண்டியதாயிருக்கும் என்றான்.
	<br /><br />
	27. பதினாலாம் இராத்திரியானபோது, நாங்கள் ஆதிரியாக் கடலிலே அலைவுபட்டு ஓடுகையில், நடுஜாமத்திலே கப்பலாட்களுக்கு ஒரு கரை கிட்டிவருகிறதாகத் தோன்றிற்று.
	<br /><br />
	28. உடனே அவர்கள் விழுதுவிட்டு இருபது பாகமென்று கண்டார்கள்; சற்றப்புறம் போனபொழுது, மறுபடியும் விழுது விட்டுப் பதினைந்து பாகமென்று கண்டார்கள்.
	<br /><br />
	29. பாறையிடங்களில் விழுவோமென்று பயந்து, பின்னணியத்திலிருந்து நாலு நங்கூரங்களைப்போட்டு, பொழுது எப்போது விடியுமோ என்றிருந்தார்கள்.
	<br /><br />
	30. அப்பொழுது கப்பலாட்கள் கப்பலை விட்டோடிப்போக வகைதேடி, முன்னணியத்திலிருந்து நங்கூரங்களைப் போடப்போகிற பாவனையாய்ப் படவைக் கடலில் இறக்குகையில்,
	<br /><br />
	31. பவுல் நூற்றுக்கு அதிபதியையும் சேவகரையும் நோக்கி: இவர்கள் கப்பலில் இராவிட்டால் நீங்கள் தப்பிப் பிழைக்கமாட்டீர்கள் என்றான்.
	<br /><br />
	32. அப்பொழுது, போர்ச்சேவகர் படவின் கயிறுகளை அறுத்து, அதைத் தாழவிழவிட்டார்கள்.
	<br /><br />
	33. பொழுது விடிகையில் எல்லாரும் போஜனம்பண்ணும்படி பவுல் அவர்களுக்குத் தைரியஞ்சொல்லி: நீங்கள் இன்று பதினாலுநாளாய் ஒன்றும் சாப்பிடாமல் பட்டினியாயிருக்கிறீர்கள்.
	<br /><br />
	34. ஆகையால் போஜனம்பண்ணும்படி உங்களை வேண்டிக்கொள்ளுகிறேன், நீங்கள் தப்பிப் பிழைப்பதற்கு அது உதவியாயிருக்கும்; உங்கள் தலையிலிருந்து ஒரு மயிரும் விழாது என்றான்.
	<br /><br />
	35. இப்படிச் சொல்லி, அப்பத்தை எடுத்து, எல்லாருக்குமுன்பாகவும் தேவனை ஸ்தோத்திரித்து, அதைப் பிட்டுப் புசிக்கத்தொடங்கினான்.
	<br /><br />
	36. அப்பொழுது எல்லாரும் திடமனப்பட்டுப் புசித்தார்கள்.
	<br /><br />
	37. கப்பலில் இருநூற்றெழுபத்தாறு பேர் இருந்தோம்.
	<br /><br />
	38. திருப்தியாகப் புசித்தபின்பு அவர்கள் கோதுமையைக் கடலிலே எறிந்து, கப்பலை இலகுவாக்கினார்கள்.
	<br /><br />
	39. பொழுது விடிந்தபின்பு, இன்னபூமியென்று அறியாதிருந்தார்கள். அப்பொழுது சமமான கரையுள்ள ஒரு துறைமுகம் அவர்களுக்குத் தென்பட்டது; கூடுமானால் அதற்குள் கப்பலையோட்ட யோசனையாயிருந்து,
	<br /><br />
	40. நங்கூரங்களை அறுத்துக் கடலிலே விட்டுவிட்டு, சுக்கான்களுடைய கட்டுகளைத் தளரவிட்டு, பெரும்பாயைக் காற்றுமுகமாய் விரித்து, கரைக்கு நேராய் ஓடி,
	<br /><br />
	41. இருபுறமும் கடல் மோதிய ஒரு இடத்திலே கப்பலைத் தட்டவைத்தார்கள்; முன்னணியம் ஊன்றி அசையாமலிருந்தது, பின்னணியம் அலைகளுடைய பலத்தினால் உடைந்துபோயிற்று.
	<br /><br />
	42. அப்பொழுது காவல்பண்ணப்பட்டவர்களில் ஒருவனும் நீந்தி ஓடிப்போகாதபடிக்கு அவர்களைக் கொன்றுபோடவேண்டுமென்று போர்ச்சேவகர் யோசனையாயிருந்தார்கள்.
	<br /><br />
	43. நூற்றுக்கு அதிபதி பவுலைக் காப்பாற்ற மனதாயிருந்து, அவர்களுடைய யோசனையைத் தடுத்து, நீந்தத்தக்கவர்கள் முந்திக் கடலில் விழுந்து கரையேறவும்,
	<br /><br />
	44. மற்றவர்களில் சிலர் பலகைகள்மேலும், சிலர் கப்பல் துண்டுகள்மேலும் போய்க் கரையேறவும் கட்டளையிட்டான்; இவ்விதமாய் எல்லாரும் தப்பிக் கரைசேர்ந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts27