import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 6 </title>
	<meta
          name="description"
          content="John Chapter 6 | யோவான் அதிகாரம் 6 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவைகளுக்குப்பின்பு இயேசு திபேரியாக்கடல் என்னப்பட்ட கலிலேயாக் கடலின் அக்கரைக்குப் போனார்.
	<br />
	2. அவர் வியாதிக்காரரிடத்தில் செய்த அற்புதங்களைத் திரளான ஜனங்கள் கண்டபடியால் அவருக்குப் பின்சென்றார்கள்.
	<br />
	3. இயேசு மலையின்மேல் ஏறி, அங்கே தம்முடைய சீஷருடனேகூட உட்கார்ந்தார்.
	<br />
	4. அப்பொழுது யூதருடைய பண்டிகையாகிய பஸ்கா சமீபமாயிருந்தது.
	<br />
	5. இயேசு தம்முடைய கண்களை ஏறெடுத்து, திரளான ஜனங்கள் தம்மிடத்தில் வருகிறதைக் கண்டு, பிலிப்புவை நோக்கி: இவர்கள் சாப்பிடத்தக்கதாக அப்பங்களை எங்கே கொள்ளலாம் என்று கேட்டார்.
	<br />
	6. தாம் செய்யப்போகிறதை அறிந்திருந்தும், அவனைச் சோதிக்கும்படி இப்படிக் கேட்டார்.
	<br />
	7. பிலிப்பு அவருக்குப் பிரதியுத்தரமாக: இவர்களில் ஒவ்வொருவனும் கொஞ்சங் கொஞ்சம் எடுத்துக்கொண்டாலும், இருநூறு பணத்து அப்பங்களும் இவர்களுக்குப் போதாதே என்றான்.
	<br />
	8. அப்பொழுது அவருடைய சீஷரிலொருவனும், சீமோன் பேதுருவின் சகோதரனுமாகிய அந்திரேயா அவரை நோக்கி:
	<br />
	9. இங்கே ஒரு பையன் இருக்கிறான், அவன் கையில் ஐந்து வாற்கோதுமை அப்பங்களும் இரண்டு மீன்களும் உண்டு, ஆனாலும் அவைகள் இத்தனை ஜனங்களுக்கு எம்மாத்திரம் என்றான்.
	<br />
	10. இயேசு: ஜனங்களை உட்காரவையுங்கள் என்றார். அந்த இடம் மிகுந்த புல்லுள்ளதாயிருந்தது. பந்தியிருந்த புருஷர்கள் ஏறக்குறைய ஐயாயிரம்பேராயிருந்தார்கள்.
	<br />
	11. இயேசு அந்த அப்பங்களை எடுத்து, ஸ்தோத்திரம்பண்ணி, சீஷர்களிடத்தில் கொடுத்தார்; சீஷர்கள் பந்தியிருந்தவர்களுக்குக் கொடுத்தார்கள்; அப்படியே மீன்களையும் அவர் எடுத்து அவர்களுக்கு வேண்டியமட்டும் கொடுத்தார்.
	<br />
	12. அவர்கள் திருப்தியடைந்தபின்பு, அவர் தம்முடைய சீஷர்களை நோக்கி: ஒன்றும் சேதமாய்ப் போகாதபடிக்கு மீதியான துணிக்கைகளைச் சேர்த்துவையுங்கள் என்றார்.
	<br />
	13. அந்தப்படியே அவர்கள் சேர்த்து, வாற்கோதுமை அப்பங்கள் ஐந்தில் அவர்கள் சாப்பிட்டு மீதியான துணிக்கைகளினாலே பன்னிரண்டு கூடைகளை நிரப்பினார்கள்.
	<br />
	14. இயேசு செய்த அற்புதத்தை அந்த மனுஷர் கண்டு: மெய்யாகவே இவர் உலகத்தில் வருகிறவரான தீர்க்கதரிசி என்றார்கள்.
	<br />
	15. ஆதலால் அவர்கள் வந்து, தம்மை ராஜாவாக்கும்படிப் பிடித்துக்கொண்டுபோக மனதாயிருக்கிறார்களென்று இயேசு அறிந்து, மறுபடியும் விலகி, தனியே மலையின்மேல் ஏறினார்.
	<br />
	16. சாயங்காலமானபோது அவருடைய சீஷர்கள் கடற்கரைக்குப் போய்,
	<br />
	17. படவில் ஏறி, கடலின் அக்கரையிலுள்ள கப்பர்நகூமுக்கு நேராய்ப்போனார்கள்; அப்பொழுது இருட்டாயிருந்தது, இயேசுவும் அவர்களிடத்தில் வராதிருந்தார்.
	<br />
	18. பெருங்காற்று அடித்தபடியினாலே கடல் கொந்தளித்தது.
	<br />
	19. அவர்கள் ஏறக்குறைய மூன்று நாலு மைல்தூரம் தண்டுவலித்துப் போனபொழுது, இயேசு கடலின்மேல் நடந்து, படவுக்குச் சமீபமாய் வருகிறதைக் கண்டு பயந்தார்கள்.
	<br />
	20. அவர்களை அவர் நோக்கி: நான்தான், பயப்படாதிருங்கள் என்றார்.
	<br />
	21. அப்பொழுது அவரைப் படவில் ஏற்றிக்கொள்ள மனதாயிருந்தார்கள்; உடனே படவு அவர்கள் போகிற கரையைப் பிடித்தது.
	<br />
	22. மறுநாளில் கடலின் அக்கரையிலே நின்ற ஜனங்கள் அவருடைய சீஷர் ஏறின அந்த ஒரே படவுதவிர அங்கே வேறொரு படவும் இருந்ததில்லையென்றும், இயேசு தம்முடைய சீஷருடனேகூடப் படவில் ஏறாமல் அவருடைய சீஷர்மாத்திரம் போனார்களென்றும் அறிந்தார்கள்.
	<br />
	23. கர்த்தர் ஸ்தோத்திரஞ்செய்தபின்பு அவர்கள் அப்பம் சாப்பிட்ட இடத்துக்குச் சமீபமாய்த் திபேரியாவிலிருந்து வேறே படவுகள் வந்தது.
	<br />
	24. அப்பொழுது இயேசுவும் அவருடைய சீஷரும் அங்கே இல்லாததை ஜனங்கள் கண்டு, உடனே அந்தப் படவுகளில் ஏறி, இயேசுவைத் தேடிக்கொண்டு, கப்பர்நகூமுக்கு வந்தார்கள்.
	<br />
	25. கடலின் அக்கரையிலே அவர்கள் அவரைக் கண்டபோது: ரபீ, நீர் எப்பொழுது இவ்விடம் வந்தீர் என்று கேட்டார்கள்.
	<br />
	26. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: நீங்கள் அற்புதங்களைக் கண்டதினால் அல்ல, நீங்கள் அப்பம் புசித்துத் திருப்தியானதினாலேயே என்னைத் தேடுகிறீர்கள் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br />
	27. அழிந்துபோகிற போஜனத்திற்காக அல்ல, நித்தியஜீவன்வரைக்கும் நிலைநிற்கிற போஜனத்திற்காகவே கிரியை நடப்பியுங்கள்; அதை மனுஷகுமாரன் உங்களுக்குக் கொடுப்பார்; அவரைப் பிதாவாகிய தேவன் முத்திரித்திருக்கிறார் என்றார்.
	<br />
	28. அப்பொழுது அவர்கள் அவரை நோக்கி: தேவனுக்கேற்ற கிரியைகளை நடப்பிக்கும்படி நாங்கள் என்ன செய்யவேண்டும் என்றார்கள்.
	<br />
	29. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: அவர் அனுப்பினவரை நீங்கள் விசுவாசிப்பதே தேவனுக்கேற்ற கிரியையாயிருக்கிறது என்றார்.
	<br />
	30. அதற்கு அவர்கள்: அப்படியானால் உம்மை விசுவாசிக்கும்படிக்கு நாங்கள் காணத்தக்கதாக நீர் என்ன அடையாளத்தைக் காண்பிக்கிறீர்? என்னத்தை நடப்பிக்கிறீர்?
	<br />
	31. வானத்திலிருந்து அவர்களுக்கு அப்பத்தைப் புசிக்கக்கொடுத்தார் என்று எழுதியிருக்கிறபடி, நம்முடைய பிதாக்கள் வனாந்தரத்தில் மன்னாவைப் புசித்தார்களே என்றார்கள்.
	<br />
	32. இயேசு அவர்களை நோக்கி: வானத்திலிருந்து வந்த அப்பத்தை மோசே உங்களுக்குக் கொடுக்கவில்லை; என் பிதாவோ வானத்திலிருந்து வந்த மெய்யான அப்பத்தை உங்களுக்குக் கொடுக்கிறார் என்று, மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br />
	33. வானத்திலிருந்திறங்கி, உலகத்துக்கு ஜீவனைக் கொடுக்கிற அப்பமே தேவன் அருளிய அப்பம் என்றார்.
	<br />
	34. அப்பொழுது அவர்கள் அவரை நோக்கி: ஆண்டவரே, இந்த அப்பத்தை எப்பொழுதும் எங்களுக்குத் தரவேண்டும் என்றார்கள்.
	<br />
	35. இயேசு அவர்களை நோக்கி: ஜீவ அப்பம் நானே, என்னிடத்தில் வருகிறவன் ஒருக்காலும் பசியடையான், என்னிடத்தில் விசுவாசமாயிருக்கிறவன் ஒருக்காலும் தாகமடையான்.
	<br />
	36. நீங்கள் என்னைக் கண்டிருந்தும் விசுவாசியாமலிருக்கிறீர்கள் என்று உங்களுக்குச் சொன்னேன்.
	<br />
	37. பிதாவானவர் எனக்குக் கொடுக்கிற யாவும் என்னிடத்தில் வரும்; என்னிடத்தில் வருகிறவனை நான் புறம்பே தள்ளுவதில்லை.
	<br />
	38. என் சித்தத்தின்படியல்ல, என்னை அனுப்பினவருடைய சித்தத்தின்படி செய்யவே நான் வானத்திலிருந்திறங்கி வந்தேன்.
	<br />
	39. அவர் எனக்குத் தந்தவைகளில் ஒன்றையும் நான் இழந்துபோகாமல், கடைசி நாளில் அவைகளை எழுப்புவதே என்னை அனுப்பின பிதாவின் சித்தமாயிருக்கிறது.
	<br />
	40. குமாரனைக் கண்டு, அவரிடத்தில் விசுவாசமாயிருக்கிறவன் எவனோ, அவன் நித்தியஜீவனை அடைவதும், நான் அவனைக் கடைசிநாளில் எழுப்புவதும், என்னை அனுப்பினவருடைய சித்தமாயிருக்கிறது என்றார்.
	<br />
	41. நான் வானத்திலிருந்து வந்த அப்பம் என்று அவர் சொன்னதினிமித்தம் யூதர்கள் அவரைக்குறித்து முறுமுறுத்து:
	<br />
	42. இவன் யோசேப்பின் குமாரனாகிய இயேசு அல்லவா, இவனுடைய தகப்பனையும் தாயையும் அறிந்திருக்கிறோமே; அப்படியிருக்க, நான் வானத்திலிருந்திறங்கி வந்தேன் என்று இவன் எப்படிச் சொல்லுகிறான் என்றார்கள்.
	<br />
	43. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: உங்களுக்குள்ளே முறுமுறுக்கவேண்டாம்.
	<br />
	44. என்னை அனுப்பின பிதா ஒருவனை இழுத்துக்கொள்ளாவிட்டால் அவன் என்னிடத்தில் வரமாட்டான்; கடைசிநாளில் நான் அவனை எழுப்புவேன்.
	<br />
	45. எல்லாரும் தேவனாலே போதிக்கப்பட்டிருப்பார்கள் என்று தீர்க்கதரிசிகளின் ஆகமத்தில் எழுதியிருக்கிறதே; ஆகையால் பிதாவினிடத்தில் கேட்டுக் கற்றுக்கொள்ளுகிறவன் எவனும் என்னிடத்தில் வருகிறான்.
	<br />
	46. தேவனிடத்தினின்று வந்தவரே தவிர வேறொருவரும் பிதாவைக் கண்டதில்லை, இவரே பிதாவைக் கண்டவர்.
	<br />
	47. என்னிடத்தில் விசுவாசமாயிருக்கிறவனுக்கு நித்தியஜீவன் உண்டென்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br />
	48. ஜீவ அப்பம் நானே.
	<br />
	49. உங்கள் பிதாக்கள் வனாந்தரத்திலே மன்னாவைப் புசித்திருந்தும் மரித்தார்கள்.
	<br />
	50. இதிலே புசிக்கிறவன் மரியாமலிருக்கும்படி வானத்திலிருந்திறங்கின அப்பம் இதுவே.
</p>
<p>
	51. நானே வானத்திலிருந்திறங்கின ஜீவ அப்பம்; இந்த அப்பத்தைப் புசிக்கிறவன் என்றென்றைக்கும் பிழைப்பான்; நான் கொடுக்கும் அப்பம் உலகத்தின் ஜீவனுக்காக நான் கொடுக்கும் என் மாம்சமே என்றார்.
	<br />
	52. அப்பொழுது யூதர்கள்: இவன் தன்னுடைய மாம்சத்தை எப்படி நமக்குப் புசிக்கக் கொடுப்பான் என்று தங்களுக்குள்ளே வாக்குவாதம்பண்ணினார்கள்.
	<br />
	53. அதற்கு இயேசு அவர்களை நோக்கி: நீங்கள் மனுஷகுமாரனுடைய மாம்சத்தைப் புசியாமலும், அவருடைய இரத்தத்தைப் பானம்பண்ணாமலும் இருந்தால் உங்களுக்குள்ளே ஜீவனில்லை என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br />
	54. என் மாம்சத்தைப் புசித்து, என் இரத்தத்தைப் பானம்பண்ணுகிறவனுக்கு நித்தியஜீவன் உண்டு; நான் அவனைக் கடைசிநாளில் எழுப்புவேன்.
	<br />
	55. என் மாம்சம் மெய்யான போஜனமாயிருக்கிறது, என் இரத்தம் மெய்யான பானமாயிருக்கிறது.
	<br />
	56. என் மாம்சத்தைப் புசித்து, என் இரத்தத்தைப் பானம்பண்ணுகிறவன் என்னிலே நிலைத்திருக்கிறான், நானும் அவனிலே நிலைத்திருக்கிறேன்.
	<br />
	57. ஜீவனுள்ள பிதா என்னை அனுப்பினதுபோலவும், நான் பிதாவினால் பிழைத்திருக்கிறதுபோலவும், என்னைப் புசிக்கிறவனும் என்னாலே பிழைப்பான்.
	<br />
	58. வானத்திலிருந்திறங்கின அப்பம் இதுவே; இது உங்கள் பிதாக்கள் புசித்த மன்னாவைப்போலல்ல, அவர்கள் மரித்தார்களே; இந்த அப்பத்தைப் புசிக்கிறவனோ என்றென்றைக்கும் பிழைப்பான் என்றார்.
	<br />
	59. கப்பர்நகூமிலுள்ள ஜெபஆலயத்திலே அவர் உபதேசிக்கையில் இவைகளைச் சொன்னார்.
	<br />
	60. அவருடைய சீஷரில் அநேகர் இவைகளைக் கேட்டபொழுது, இது கடினமான உபதேசம், யார் இதைக் கேட்பார்கள் என்றார்கள்.
	<br />
	61. சீஷர்கள் அதைக்குறித்து முறுமுறுக்கிறார்களென்று இயேசு தமக்குள்ளே அறிந்து, அவர்களை நோக்கி: இது உங்களுக்கு இடறலாயிருக்கிறதோ?
	<br />
	62. மனுஷகுமாரன் தாம் முன்னிருந்த இடத்திற்கு ஏறிப்போகிறதை நீங்கள் காண்பீர்களானால் எப்படியிருக்கும்?
	<br />
	63. ஆவியே உயிர்ப்பிக்கிறது, மாம்சமானது ஒன்றுக்கும் உதவாது; நான் உங்களுக்குச் சொல்லுகிற வசனங்கள் ஆவியாயும் ஜீவனாயும் இருக்கிறது.
	<br />
	64. ஆகிலும் உங்களில் விசுவாசியாதவர்கள் சிலர் உண்டு என்றார்; விசுவாசியாதவர்கள் இன்னாரென்றும், தம்மைக் காட்டிக்கொடுப்பவன் இன்னானென்றும் ஆதிமுதலாக இயேசு அறிந்திருந்தபடியால், அவர் பின்னும்:
	<br />
	65. ஒருவன் என் பிதாவின் அருளைப் பெறாவிட்டால் என்னிடத்திற்கு வரமாட்டான் என்று இதினிமித்தமே உங்களுக்குச் சொன்னேன் என்றார்.
	<br />
	66. அதுமுதல் அவருடைய சீஷரில் அநேகர் அவருடனேகூட நடவாமல் பின்வாங்கிப்போனார்கள்.
	<br />
	67. அப்பொழுது இயேசு பன்னிருவரையும் நோக்கி: நீங்களும் போய்விட மனதாயிருக்கிறீர்களோ என்றார்.
	<br />
	68. சீமோன் பேதுரு அவருக்குப் பிரதியுத்தரமாக: ஆண்டவரே, யாரிடத்தில் போவோம், நித்தியஜீவ வசனங்கள் உம்மிடத்தில் உண்டே.
	<br />
	69. நீர் ஜீவனுள்ள தேவனுடைய குமாரனாகிய கிறிஸ்து என்று நாங்கள் விசுவாசித்தும் அறிந்தும் இருக்கிறோம் என்றான்.
	<br />
	70. இயேசு அவர்களை நோக்கி: பன்னிருவராகிய உங்களை நான் தெரிந்துகொள்ளவில்லையா? உங்களுக்குள்ளும் ஒருவன் பிசாசாயிருக்கிறான் என்றார்.
	<br />
	71. சீமோனின் குமாரனாகிய யூதாஸ்காரியோத்து பன்னிருவரிலொருவனாயிருந்தும், தம்மைக் காட்டிக்கொடுக்கப் போகிறவனாயிருந்தபடியினால் அவனைக்குறித்து இப்படிச் சொன்னார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John6