import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah39 = () => {
  const verseNumber = 39;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 39 </title>
	<meta
          name="description"
          content="Isaiah 39 | ஏசாயா 39 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே பலாதானின் குமாரனாகிய மெரோதாக்பலாதான் என்னும் பாபிலோனின் ராஜா, எசேக்கியா வியாதிப்பட்டிருந்து ஆரோக்கியமானதைக் கேள்விப்பட்டு, அவனிடத்திற்கு நிருபங்களையும் வெகுமானத்தையும் அனுப்பினான்.
	<br /><br />
	2. எசேக்கியா அவர்களைக் கண்டு சந்தோஷப்பட்டு, தன் பொக்கிஷசாலையையும், வெள்ளியையும், பொன்னையும், கந்தவர்க்கங்களையும், நல்ல பரிமளதைலத்தையும், தன் ஆயுதசாலை அனைத்தையும், தன் பொக்கிஷசாலைகளில் உள்ளதெல்லாவற்றையும் அவர்களுக்குக் காண்பித்தான்; எசேக்கியா தன் அரமனையிலும், தன் ராஜ்யத்தில் எங்கும் அவர்களுக்குக் காண்பியாத பொருள் ஒன்றும் இல்லை.
	<br /><br />
	3. அப்பொழுது ஏசாயா தீர்க்கதரிசி எசேக்கியா ராஜாவினிடத்தில் வந்து: அந்த மனுஷர் என்ன சொன்னார்கள், எங்கேயிருந்து உம்மிடத்தில் வந்தார்கள் என்று கேட்டான். அதற்கு எசேக்கியா: பாபிலோன் என்னும் தூரதேசத்திலிருந்து என்னிடத்திற்கு வந்தார்கள் என்றான்.
	<br /><br />
	4. அப்பொழுது அவன்: உம்முடைய வீட்டில் என்னத்தைப் பார்த்தார்கள் என்று கேட்டான். அதற்கு எசேக்கியா: என் வீட்டிலுள்ளதெல்லாவற்றையும் பார்த்தார்கள்; என் பொக்கிஷங்களில் நான் அவர்களுக்குக் காண்பியாத பொருள் ஒன்றும் இல்லை என்றான்.
	<br /><br />
	5. அப்பொழுது ஏசாயா எசேக்கியாவை நோக்கி: சேனைகளுடைய கர்த்தரின் வார்த்தையைக் கேளும்.
	<br /><br />
	6. இதோ, நாட்கள் வரும்; அப்பொழுது உன் வீட்டில் உள்ளதிலும், உன் பிதாக்கள் இந்நாள்வரைக்கும் சேர்த்ததிலும் ஒன்றும் மீதியாய் வைக்கப்படாமல் எல்லாம் பாபிலோனுக்குக் கொண்டுபோகப்படும்.
	<br /><br />
	7. நீ பெறப்போகிற உன் சந்ததியாகிய உன் குமாரரிலும் சிலர் பாபிலோன் ராஜாவின் அரமனையிலே அரமனை வேலைக்காரராயிருப்பார்கள் என்று கர்த்தர் சொல்லுகிறார் என்றான்.
	<br /><br />
	8. அப்பொழுது எசேக்கியா ஏசாயாவை நோக்கி: நீர் சொன்ன கர்த்தருடைய வார்த்தை நல்லதுதான் என்று சொல்லி, என் நாட்களிலாவது சமாதானமும் உண்மையும் இருக்குமே என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah39