import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneTimothy5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title> 1 Timothy in Tamil Bible Verse | 1 தீமோத்தேயு அதிகாரம்-5 </title>
	<meta
          name="description"
          content="FIRST LETTER TO THE Timothy Chapter 5 | தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-5 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* விதவைகளுக்கு மரியாதை மற்றும் கவனிப்பு *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

	  <p>
	1. முதிர்வயதுள்ளவனைக் கடிந்துகொள்ளாமல், அவனைத் தகப்பனைப்போலவும், பாலிய புருஷரைச் சகோதரரைப்போலவும்,
	<br /><br />
	2. முதிர்வயதுள்ள ஸ்திரீகளைத் தாய்களைப்போலவும், பாலிய ஸ்திரீகளை எல்லாக் கற்புடன் சகோதரிகளைப்போலவும் பாவித்து, புத்திசொல்லு.
	<br /><br />
	3. உத்தம விதவைகளாகிய விதவைகளைக் கனம்பண்ணு.
	<br /><br />
	4. விதவையானவளுக்குப் பிள்ளைகளாவது, பேரன் பேர்த்திகளாவது இருந்தால், இவர்கள் முதலாவது தங்கள் சொந்தக் குடும்பத்தைத் தேவபக்தியாய் விசாரித்து, பெற்றார் செய்த நன்மைகளுக்குப் பதில் நன்மைகளைச் செய்யக் கற்றுக்கொள்ளக்கடவர்கள்; அது நன்மையும் தேவனுக்கு முன்பாகப் பிரியமுமாயிருக்கிறது.
	<br /><br />
	5. உத்தம விதவையாயிருந்து தனிமையாயிருக்கிறவள் தேவனிடத்தில் நம்பிக்கையுள்ளவளாய், இரவும் பகலும் வேண்டுதல்களிலும் ஜெபங்களிலும் நிலைத்திருப்பாள்.
	<br /><br />
	6. சுகபோகமாய் வாழ்கிறவள் உயிரோடே செத்தவள்.
	<br /><br />
	7. அவர்கள் குற்றஞ்சாட்டப்படாதவர்களாயிருக்கும்படி இவைகளைக் கட்டளையிடு.
	<br /><br />
	8. ஒருவன் தன் சொந்த ஜனங்களையும், விசேஷமாகத் தன் வீட்டாரையும் விசாரியாமற்போனால், அவன் விசுவாசத்தை மறுதலித்தவனும், அவிசுவாசியிலும் கெட்டவனுமாயிருப்பான்.
	<br /><br />
	9. அறுபது வயதுக்குக் குறையாதவளும், ஒரே புருஷனுக்கு மனைவியாயிருந்தவளுமாகி,
	<br /><br />
	10. பிள்ளைகளை வளர்த்து, அந்நியரை உபசரித்து, பரிசுத்தவான்களுடைய கால்களைக்கழுவி, உபத்திரவப்படுகிறவர்களுக்கு உதவிசெய்து, சகலவித நற்கிரியைகளையும் ஜாக்கிரதையாய் நடப்பித்து, இவ்விதமாய் நற்கிரியைகளைக்குறித்து நற்சாட்சி பெற்றவளுமாயிருந்தால், அப்படிப்பட்ட விதவையையே விதவைகள் கூட்டத்தில் சேர்த்துக்கொள்ளவேண்டும்.
	<br /><br />
	11. இளவயதுள்ள விதவைகளை அதிலே சேர்த்துக்கொள்ளாதே; ஏனெனில், அவர்கள் கிறிஸ்துவுக்கு விரோதமாய்க் காமவிகாரங்கொள்ளும்போது விவாகம்பண்ண மனதாகி,
	<br /><br />
	12. முதலில் கொண்டிருந்த விசுவாசத்தை விடுவதினாலே, ஆக்கினைக்குட்படுவார்கள்.
	<br /><br />
	13. அதுவுமல்லாமல், அவர்கள் சோம்பலுள்ளவர்களாய், வீடுவீடாய்த் திரியப்பழகுவார்கள்; சோம்பலுள்ளவர்களாய் மாத்திரமல்ல, அலப்புகிறவர்களாயும் வீணலுவற்காரிகளாயும் தகாத காரியங்களைப் பேசுகிறவர்களாயுமிருப்பார்கள்.
	<br /><br />
	14. ஆகையால் இளவயதுள்ள விதவைகள் விவாகம்பண்ணவும், பிள்ளைகளைப் பெறவும், வீட்டை நடத்தவும், விரோதியானவன் நிந்திக்கிறதற்கு இடமுண்டாக்காமலிருக்கவும் வேண்டுமென்றிருக்கிறேன்.
	<br /><br />
	15. ஏனெனில் இதற்குமுன்னே சிலர் சாத்தானைப் பின்பற்றி விலகிப்போனார்கள்.
	<br /><br />
	16. விசுவாசியாகிய ஒருவனிடத்திலாவது ஒருத்தியிடத்திலாவது விதவைகளிருந்தால், அவர்கள் இவர்களுக்கு உதவி செய்யக்கடவர்கள்; சபையானது உத்தம விதவைகளானவர்களுக்கு உதவிசெய்யவேண்டியதாகையால் அந்தப் பாரத்தை அதின்மேல் வைக்கக்கூடாது.
	<br /><br />
	17. நன்றாய் விசாரணைசெய்கிற மூப்பர்களை, விசேஷமாகத் திருவசனத்திலும் உபதேசத்திலும் பிரயாசப்படுகிறவர்களை, இரட்டிப்பான கனத்திற்குப் பாத்திரராக எண்ணவேண்டும்.
	<br /><br />
	18. போரடிக்கிற மாட்டை வாய்கட்டாயாக என்றும், வேலையாள் தன் கூலிக்குப் பாத்திரனாயிருக்கிறான் என்றும், வேதவாக்கியம் சொல்லுகிறதே.
	<br /><br />
	19. மூப்பனானவனுக்கு விரோதமாக ஒருவன் செய்யும் பிராதை இரண்டு மூன்று சாட்சிகள் இல்லாமல் நீ ஏற்றுக்கொள்ளக்கூடாது.
	<br /><br />
	20. மற்றவர்களுக்குப் பயமுண்டாகும்படி, பாவஞ்செய்தவர்களை எல்லாருக்கும் முன்பாகக் கடிந்துகொள்.
	<br /><br />
	21. நீ பட்சபாதத்தோடே ஒன்றும் செய்யாமலும், விசாரிக்குமுன் நிருணயம்பண்ணாமலும், இவைகளைக் காத்து நடக்கும்படி, தேவனுக்கும், கர்த்தராகிய இயேசுகிறிஸ்துவுக்கும், தெரிந்துகொள்ளப்பட்ட தூதருக்கும் முன்பாக, உறுதியாய்க் கட்டளையிடுகிறேன்.
	<br /><br />
	22. ஒருவன்மேலும் சீக்கிரமாய்க் கைகளை வையாதே; மற்றவர்கள் செய்யும் பாவங்களுக்கும் உடன்படாதே; உன்னைச் சுத்தவானாகக் காத்துக்கொள்.
	<br /><br />
	23. நீ இனிமேல் தண்ணீர் மாத்திரம் குடியாமல், உன் வயிற்றிற்காகவும், உனக்கு அடிக்கடி நேரிடுகிற பலவீனங்களுக்காகவும், கொஞ்சம் திராட்சரசமும் கூட்டிக்கொள்.
	<br /><br />
	24. சிலருடைய பாவங்கள் வெளியரங்கமாயிருந்து, நியாயத்தீர்ப்புக்கு முந்திக்கொள்ளும்; சிலருடைய பாவங்கள் அவர்களைப் பின்தொடரும்.
	<br /><br />
	25. அப்படியே சிலருடைய நற்கிரியைகளும் வெளியரங்கமாயிருக்கும்; அப்படி இராதவைகளும் மறைந்திருக்கமாட்டாது.
</p>


      {/* End of verse */}  

	  <br /><br />
		<br /><br />
<h2>
* I தீமோத்தேயு 5 விதவைகளை அவர்களின் தேவைகளில் கவனித்துக்கொள்வது பற்றிய வழிமுறைகளை வழங்குகிறது. அவர்களின் குடும்பங்கள் முதலில் அவர்களைக் கவனித்துக் கொள்ள வேண்டும் என்று பவுல் அறிவுறுத்துகிறார். இருப்பினும், ஒரு விதவை குடும்பம் இல்லாமல் இருந்தால், அவள் உணவுக்காக கடவுளை நம்புகிறாள், தேவாலயம் அவளை கவனித்துக் கொள்ள வேண்டும். அவர் மூப்பர்களைப் பற்றிய அறிவுரைகளையும் கொடுக்கிறார், பின்னர் தீமோத்தேயுவுக்கு நடைமுறை ஆலோசனையுடன் முடிக்கிறார்.*
			</h2>
<br /><br />
			<br /><br />
      </div>
    </div>
    </>
  );
}

export default OneTimothy5