import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 15 </title>
	<meta
          name="description"
          content="Isaiah 15 | ஏசாயா 15 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மோவாபின் பாரம். இரவிலே மோவாபிலுள்ள ஆர் என்னும் பட்டணம் பாழாக்கப்பட்டது, அது சங்காரமாயிற்று; இரவிலே மோவாபிலுள்ள கீர் என்னும் பட்டணம் பாழாக்கப்பட்டது, அது சங்காரமாயிற்று.
	<br /><br />
	2. அழும்படி மேடைகளாகிய பாயித்துக்கும் தீபோனுக்கும் போகிறார்கள்; நேபோவினிமித்தமும் மேதெபாவினிமித்தமும் மோவாப் அலறுகிறது; அவர்களுடைய தலைகளெல்லாம் மொட்டையடித்திருக்கும்; தாடிகளெல்லாம் கத்தரித்திருக்கும்.
	<br /><br />
	3. அதின் வீதிகளில் இரட்டைக்கட்டிக்கொண்டு, எல்லாரும் அதின் வீடுகள்மேலும், அதின் தெருக்களிலும் அலறி, அழுதுகொண்டிருக்கிறார்கள்.
	<br /><br />
	4. எஸ்போன் ஊராரும் எலெயாலே ஊராரும் கூக்குரலிடுகிறார்கள்; அவர்கள் சத்தம் யாகாஸ்மட்டும் கேட்கப்படுகிறது; ஆகையால் மோவாபின் ஆயுதபாணிகள் கதறுகிறார்கள்; அவனவனுடைய ஆத்துமா அவனவனில் கிலேசப்படுகிறது.
	<br /><br />
	5. என் இருதயம் மோவாபினிமித்தம் ஓலமிடுகிறது; அதிலிருந்து ஓடிவருகிறவர்கள் மூன்று வயது கிடாரியைப்போல அலைகிறார்கள்; லூகித்துக்கு ஏறிப்போகிற வழியிலே அழுகையோடே ஏறுகிறார்கள்; ஒரோனாயீமின் வழியிலே நொறுங்குதலின் கூக்குரல் இடுகிறார்கள்.
	<br /><br />
	6. நிம்ரீமின் நீர்ப்பாய்ச்சலான இடங்கள் பாழாய்ப்போம்; புல் உலர்ந்து, முளை அழிந்து, பச்சையில்லாமற்போகிறது.
	<br /><br />
	7. ஆதலால் மிகுதியாகச் சேர்த்ததையும், அவர்கள் சம்பாதித்து வைத்ததையும், அலரிகளின் ஆற்றுக்கப்பாலே எடுத்துக்கொண்டுபோவார்கள்.
	<br /><br />
	8. கூக்குரல் மோவாபின் எல்லையெங்கும் சுற்றும்; எக்லாயிம்மட்டும் அதின் அலறுதலும், பெரேலீம்மட்டும் அதின் புலம்புதலும் எட்டும்.
	<br /><br />
	9. தீமோனின் தண்ணீர்கள் இரத்தத்தால் நிறைந்திருக்கும்; தீமோனின்மேல் அதிக கேடுகளைக் கட்டளையிடுவேன்; மோவாபிலே தப்பினவர்கள்மேலும், தேசத்தில் மீதியானவர்கள்மேலும் சிங்கத்தை வரப்பண்ணுவேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah15