import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 7 </title>
	<meta
          name="description"
          content="Jeremiah 7 | எரேமியா 7 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தரால் எரேமியாவுக்கு உண்டான வசனம்:
	<br /><br />
	2. நீ கர்த்தருடைய ஆலயத்தின் வாசலிலே நின்று, அங்கே கூறிச் சொல்லவேண்டிய வசனம் என்னவென்றால், கர்த்தரைப் பணிந்துகொள்ள இந்த வாசல்களுக்குள்ளே பிரவேசிக்கிற யூத ஜனங்களாகிய நீங்களெல்லாரும் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	3. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்: உங்கள் வழிகளையும் உங்கள் கிரியைகளையும் சீர்ப்படுத்துங்கள், அப்பொழுது உங்களை இந்த ஸ்தலத்திலே குடியிருக்கப்பண்ணுவேன்.
	<br /><br />
	4. கர்த்தரின் ஆலயம், கர்த்தரின் ஆலயம், கர்த்தரின் ஆலயம் இதுவே என்று சொல்லி, பொய்வார்த்தைகளை நம்பிக்கொள்ளாதிருங்கள்.
	<br /><br />
	5. நீங்கள் உங்கள் வழிகளையும் உங்கள் கிரியைகளையும் நன்றாய்ச் சீர்ப்படுத்தி, நீங்கள் மனுஷனுக்கும் மனுஷனுக்குமுள்ள வழக்கை நியாயமாய்த் தீர்த்து,
	<br /><br />
	6. பரதேசியையும் திக்கற்றவனையும் விதவையையும் ஒடுக்காமலும், குற்றமில்லாத இரத்தத்தை இந்த ஸ்தலத்திலே சிந்தாமலும்; உங்களுக்குக் கேடுண்டாக அந்நிய தேவர்களைப் பின்பற்றாமலும் இருப்பீர்களேயாகில்,
	<br /><br />
	7. அப்பொழுது நான் உங்கள் பிதாக்களுக்குக் கொடுத்த தேசமாகிய இந்த ஸ்தலத்திலே உங்களைச் சதாகாலமும் குடியிருக்கப்பண்ணுவேன்.
	<br /><br />
	8. இதோ, ஒன்றுக்கும் உதவாத பொய்வார்த்தைகளை நீங்கள் நம்புகிறீர்கள்.
	<br /><br />
	9. நீங்கள் திருடி, கொலைசெய்து, விபசாரம்பண்ணி, பொய்யாணையிட்டு, பாகாலுக்குத் தூபங்காட்டி, நீங்கள் அறியாத அந்நிய தேவர்களைப் பின்பற்றி,
	<br /><br />
	10. பிற்பாடு வந்து, என் நாமம் தரிக்கப்பட்ட இந்த ஆலயத்திலே எனக்கு முன்பாக நின்று: இந்த அருவருப்புகளையெல்லாம் செய்வதற்காக விடுதலை பெற்றிருக்கிறோமென்று சொல்வீர்களோ?
	<br /><br />
	11. என் நாமம் தரிக்கப்பட்ட இந்த ஆலயம் உங்கள் பார்வைக்குக் கள்ளர்குகையாயிற்றோ? இதோ, நானும் இதைக் கண்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. நான் முந்தி என் நாமம் விளங்கப்பண்ணின சீலோவிலுள்ள என் ஸ்தலத்துக்கு நீங்கள் போய், இஸ்ரவேல் ஜனத்தினுடைய பொல்லாப்பினிமித்தம் நான் அதற்குச் செய்ததைப் பாருங்கள்.
	<br /><br />
	13. நீங்கள் இந்தக் கிரியைகளையெல்லாம் செய்தீர்கள், நான் உங்களுக்கு ஏற்கனவே சொல்லிவந்திருந்தும், நீங்கள் கேளாமலும், நான் உங்களைக் கூப்பிட்டும், நீங்கள் உத்தரவுகொடாமலும் போனபடியினால்,
	<br /><br />
	14. என் நாமம் தரிக்கப்பட்டதும், நீங்கள் நம்பிக்கை கொண்டிருக்கிறதுமான இந்த ஆலயத்துக்கும், உங்களுக்கும் உங்கள் பிதாக்களுக்கும் நான் கொடுத்த ஸ்தலத்துக்கும், நான் சீலோவுக்குச் செய்ததுபோலச் செய்வேன்.
	<br /><br />
	15. நான் உங்களுடைய எல்லாச் சகோதரருமாகிய எப்பிராயீம் சந்ததி அனைத்தையும் தள்ளிப்போட்டதுபோல, உங்களையும் என் முகத்தைவிட்டுத் தள்ளிப்போடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	16. நீ இந்த ஜனத்துக்காக விண்ணப்பம் செய்யவேண்டாம்; அவர்களுக்காக மன்றாடவும் கெஞ்சவும் வேண்டாம், என்னிடத்தில் அவர்களுக்காகப் பரிந்துபேசவும் வேண்டாம், நான் உனக்குச் செவிகொடுப்பதில்லை.
	<br /><br />
	17. யூதாவின் பட்டணங்களிலும் எருசலேமின் வீதிகளிலும் அவர்கள் செய்கிறதை நீ காணவில்லையா?
	<br /><br />
	18. எனக்கு மனமடிவுண்டாக அந்நிய தேவர்களுக்குப் பானபலிகளை வார்க்கிறார்கள்; அவர்கள் வானராக்கினிக்குப் பணியாரங்களைச் சுடும்படி பிள்ளைகள் விறகு பொறுக்குகிறார்கள், பிதாக்கள் நெருப்பு மூட்டுகிறார்கள், ஸ்திரீகள் மாப்பிசைகிறார்கள்.
	<br /><br />
	19. அவர்கள் எனக்கா மனமடிவுண்டாக்குகிறார்கள்? தங்கள் முகங்கள் வெட்கத்துக்குட்படும்படி அவர்கள் தங்களுக்கே அல்லவோ மனமடிவுண்டாக்குகிறார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	20. ஆதலால் இதோ, என் கோபமும் என் உக்கிரமும் இந்த ஸ்தலத்தின்மேலும், மனுஷர்மேலும், மிருகங்கள்மேலும், வெளியின் மரங்கள்மேலும், பூமியின் கனிகள்மேலும் ஊற்றப்படும்; அது அவியாமல் எரியும் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	21. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறதென்னவென்றால்: உங்கள் தகனபலிகளை மற்றப் பலிகளோடுங்கூட்டி, இறைச்சியைச் சாப்பிடுங்கள்.
	<br /><br />
	22. நான் உங்கள் பிதாக்களை எகிப்துதேசத்திலிருந்து அழைத்துவந்த நாளிலே, தகனபலியைக்குறித்தும், மற்றப் பலிகளைக்குறித்தும் நான் அவர்களோடே பேசினதையும் கட்டளையிட்டதையும்பார்க்கிலும்,
	<br /><br />
	23. என் வாக்குக்குச் செவிகொடுங்கள், அப்பொழுது நான் உங்கள் தேவனாயிருப்பேன், நீங்கள் என் ஜனமாயிருப்பீர்கள்; நான் உங்களுக்குக் கற்பிக்கும் எல்லா வழியிலும், நீங்கள் உங்களுக்கு நன்மையுண்டாகும்படிக்கு நடவுங்கள் என்கிற விசேஷத்தையே அவர்களுக்குச் சொல்லிக் கட்டளையிட்டேன்.
	<br /><br />
	24. அவர்களோ அதைக் கேளாமலும், தங்கள் செவியைச் சாயாமலும்போய், தங்கள் பொல்லாத இருதயத்தின் யோசனைகளின்படியும் கடினத்தின்படியும் நடந்து, முன்னிட்டல்ல பின்னிட்டே போனார்கள்.
	<br /><br />
	25. உங்கள் பிதாக்கள் எகிப்துதேசத்திலிருந்து புறப்பட்ட நாள்முதல் இந்நாள்மட்டும் நான் தீர்க்கதரிசிகளாகிய என் ஊழியக்காரரையெல்லாம் தினந்தினம் உங்களண்டைக்கு ஏற்கனவே அனுப்பிக்கொண்டிருந்தேன்.
	<br /><br />
	26. ஆனாலும் அவர்கள் என் சொல்லைக் கேளாமலும், தங்கள் செவியைச் சாயாமலும் போய், தங்கள் கழுத்தைக் கடினப்படுத்தி, தங்கள் பிதாக்களைப்பார்க்கிலும் அதிக பொல்லாப்புச் செய்தார்கள்.
	<br /><br />
	27. நீ இந்த வார்த்தைகளையெல்லாம் அவர்களுக்குச் சொன்னாலும், அவர்கள் உனக்குச் செவிகொடுக்கமாட்டார்கள்; நீ அவர்களை நோக்கிக் கூப்பிட்டாலும், அவர்கள் உனக்கு மறுஉத்தரவு கொடுக்கமாட்டார்கள்.
	<br /><br />
	28. ஆகையால் தங்கள் தேவனாகிய கர்த்தருடைய சத்தத்தைக் கேளாமலும், புத்தியை ஏற்றுக்கொள்ளாமலும் இருக்கிற ஜாதி இதுதான் என்றும், சத்தியம் அழிந்து, அது அவர்கள் வாயிலிருந்து அற்றுப்போனதென்றும் அவர்களுக்குச் சொல்.
	<br /><br />
	29. நீ உன் தலைமயிரைச் சிரைத்து, எறிந்துவிட்டு, உயர்தலங்களிலே புலம்பிக்கொண்டிரு; கர்த்தர் தமது சினத்துக்கு ஏதுவான சந்ததியை வெறுத்து நெகிழவிட்டார்.
	<br /><br />
	30. யூதா புத்திரர் என் பார்வைக்குப் பொல்லாப்பானதைச் செய்தார்கள் என்று கர்த்தர் சொல்லுகிறார்; என் நாமம் தரித்திருக்கிற ஆலயத்தைத் தீட்டுப்படுத்தத் தங்கள் அருவருப்புகளை அதிலே வைத்தார்கள்.
	<br /><br />
	31. தங்கள் குமாரரையும் தங்கள் குமாரத்திகளையும் அக்கினியிலே தகனிக்கிறதற்காக, அவர்கள் இன்னோம் குமாரனின் பள்ளத்தாக்கிலுள்ள தோப்பேத்தின் மேடைகளைக் கட்டினார்கள்; அதை நான் கட்டளையிடவுமில்லை, அது என் மனதில் தோன்றவுமில்லை.
	<br /><br />
	32. ஆதலால், இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார்; அப்பொழுது அது அப்புறம் தோப்பேத் என்றும், இன்னோம் குமாரனின் பள்ளத்தாக்கென்றும் சொல்லப்படாமல், சங்காரப் பள்ளத்தாக்கென்று சொல்லப்படும்; தோப்பேத்திலே இடங்கிடையாமற்போகுமட்டும் சவங்களை அடக்கம்பண்ணுவார்கள்.
	<br /><br />
	33. இந்த ஜனத்தின் பிணங்கள் ஆகாயத்தின் பறவைகளுக்கும் பூமியின் மிருகங்களுக்கும் இரையாகும்; அவைகளை வெருட்டுவாரும் இல்லாதிருப்பார்கள்.
	<br /><br />
	34. நான் யூதாவின் பட்டணங்களிலும் எருசலேமின் வீதிகளிலும் களிப்பின் சத்தத்தையும், மகிழ்ச்சியின் சத்தத்தையும், மணவாளனின் சத்தத்தையும், மணவாட்டியின் சத்தத்தையும் ஓயப்பண்ணுவேன்; தேசம் பாழாகும்.
</p>
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah7