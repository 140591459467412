import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 4 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 4 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 4 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்படிப்பட்ட ஊழியத்தை உடையவர்களாகிய நாங்கள் இரக்கம் பெற்றிருப்பதால் சோர்ந்துபோகிறதில்லை.
	<br /><br />
	2. வெட்கமான அந்தரங்க காரியங்களை நாங்கள் வெறுத்து, தந்திரமாய் நடவாமலும், தேவ வசனத்தைப் புரட்டாமலும், சத்தியத்தை வெளிப்படுத்துகிறதினாலே தேவனுக்கு முன்பாக எல்லா மனுஷருடைய மனச்சாட்சிக்கும் எங்களை உத்தமரென்று விளங்கப்பண்ணுகிறோம்.
	<br /><br />
	3. எங்கள் சுவிசேஷம் மறைபொருளாயிருந்தால், கெட்டுப்போகிறவர்களுக்கே அது மறைபொருளாயிருக்கும்.
	<br /><br />
	4. தேவனுடைய சாயலாயிருக்கிற கிறிஸ்துவின் மகிமையான சுவிசேஷத்தின் ஒளி, அவிசுவாசிகளாகிய அவர்களுக்குப் பிரகாசமாயிராதபடிக்கு, இப்பிரபஞ்சத்தின் தேவனானவன் அவர்களுடைய மனதைக் குருடாக்கினான்.
	<br /><br />
	5. நாங்கள் எங்களையே பிரசங்கியாமல், கிறிஸ்து இயேசுவைக் கர்த்தரென்றும், எங்களையோ இயேசுவினிமித்தம் உங்கள் ஊழியக்காரரென்றும் பிரசங்கிக்கிறோம்.
	<br /><br />
	6. இருளிலிருந்து வெளிச்சத்தைப் பிரகாசிக்கச்சொன்ன தேவன் இயேசுகிறிஸ்துவின் முகத்திலுள்ள தமது மகிமையின் அறிவாகிய ஒளியைத் தோன்றப்பண்ணும்பொருட்டாக, எங்கள் இருதயங்களிலே பிரகாசித்தார்.
	<br /><br />
	7. இந்த மகத்துவமுள்ள வல்லமை எங்களால் உண்டாயிராமல், தேவனால் உண்டாயிருக்கிறதென்று விளங்கும்படி, இந்தப் பொக்கிஷத்தை மண்பாண்டங்களில் பெற்றிருக்கிறோம்.
	<br /><br />
	8. நாங்கள் எப்பக்கத்திலும் நெருக்கப்பட்டும் ஒடுங்கிப்போகிறதில்லை; கலக்கமடைந்தும் மனமுறிவடைகிறதில்லை;
	<br /><br />
	9. துன்பப்படுத்தப்பட்டும் கைவிடப்படுகிறதில்லை; கீழே தள்ளப்பட்டும் மடிந்து போகிறதில்லை.
	<br /><br />
	10. கர்த்தராகிய இயேசுவினுடைய ஜீவனும் எங்கள் சரீரத்திலே விளங்கும்படிக்கு, இயேசுவின் மரணத்தை எப்பொழுதும் எங்கள் சரீரத்தில் சுமந்து திரிகிறோம்.
	<br /><br />
	11. எப்படியெனில், சாவுக்கினமான எங்கள் மாம்சத்திலே இயேசுவினுடைய ஜீவனும் விளங்கும்படிக்கு உயிரோடிருக்கிற நாங்கள் எப்பொழுதும் இயேசுவினிமித்தம் மரணத்திற்கு ஒப்புக்கொடுக்கப்படுகிறோம்.
	<br /><br />
	12. இப்படி மரணமானது எங்களிடத்திலும், ஜீவனானது உங்களிடத்திலும் பெலன்செய்கிறது.
	<br /><br />
	13. விசுவாசித்தேன், ஆகையால் பேசினேன் என்று எழுதியிருக்கிறபடி, நாங்களும் அந்த விசுவாசத்தின் ஆவியை உடையவர்களாயிருந்து, விசுவாசிக்கிறபடியால் பேசுகிறோம்.
	<br /><br />
	14. கர்த்தராகிய இயேசுவை எழுப்பினவர் எங்களையும் இயேசுவைக்கொண்டு எழுப்பி, உங்களுடனேகூடத் தமக்குமுன்பாக நிறுத்துவாரென்று அறிந்திருக்கிறோம்.
	<br /><br />
	15. தேவனுடைய மகிமை விளங்குவதற்கேதுவாகக் கிருபையானது அநேகருடைய ஸ்தோத்திரத்தினாலே பெருகும்படிக்கு, இவையெல்லாம் உங்கள்நிமித்தம் உண்டாயிருக்கிறது.
	<br /><br />
	16. ஆனபடியினாலே நாங்கள் சோர்ந்துபோகிறதில்லை; எங்கள் புறம்பான மனுஷனானது அழிந்தும், உள்ளான மனுஷனானது நாளுக்குநாள் புதிதாக்கப்படுகிறது.
	<br /><br />
	17. மேலும் காணப்படுகிறவைகளையல்ல, காணப்படாதவைகளை நோக்கியிருக்கிற நமக்கு, அதிசீக்கிரத்தில் நீங்கும் இலேசான நம்முடைய உபத்திரவம் மிகவும் அதிகமான நித்திய கனமகிமையை உண்டாக்குகிறது.
	<br /><br />
	18. ஏனெனில், காணப்படுகிறவைகள் அநித்தியமானவைகள், காணப்படாதவைகளோ நித்தியமானவைகள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians4