import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 12 </title>
	<meta
          name="description"
          content="Acts 12 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 12 |
          Acts of Apostles Chapter-12 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே ஏரோதுராஜா சபையிலே சிலரைத் துன்பப்படுத்தத்தொடங்கி;
	<br /><br />
	2. யோவானுடைய சகோதரனாகிய யாக்கோபைப் பட்டயத்தினாலே கொலைசெய்தான்.
	<br /><br />
	3. அது யூதருக்குப் பிரியமாயிருக்கிறதென்று அவன் கண்டு, பேதுருவையும் பிடிக்கத்தொடர்ந்தான். அப்பொழுது புளிப்பில்லாத அப்பப்பண்டிகை நாட்களாயிருந்தது.
	<br /><br />
	4. அவனைப் பிடித்துச் சிறைச்சாலையிலே வைத்து, பஸ்காபண்டிகைக்குப் பின்பு ஜனங்களுக்கு முன்பாக அவனை வெளியே கொண்டுவரலாமென்று எண்ணி, அவனைக் காக்கும்படி வகுப்புக்கு நான்கு போர்ச்சேவகராக ஏற்படுத்திய நான்கு வகுப்புகளின் வசமாக ஒப்புவித்தான்.
	<br /><br />
	5. அப்படியே பேதுரு சிறைச்சாலையிலே காக்கப்பட்டிருக்கையில் சபையார் அவனுக்காகத் தேவனை நோக்கி ஊக்கத்தோடே ஜெபம்பண்ணினார்கள்.
	<br /><br />
	6. ஏரோது அவனை வெளியே கொண்டுவரும்படி குறித்திருந்த நாளுக்கு முந்தின நாள் இராத்திரியிலே, பேதுரு இரண்டு சங்கிலிகளினாலே கட்டப்பட்டு, இரண்டு சேவகர் நடுவே நித்திரைபண்ணிக்கொண்டிருந்தான்; காவற்காரரும் கதவுக்கு முன்னிருந்து சிறைச்சாலையைக் காத்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	7. அப்பொழுது கர்த்தருடைய தூதன் அங்கே வந்து நின்றான்; அறையிலே வெளிச்சம் பிரகாசித்தது. அவன் பேதுருவை விலாவிலே தட்டி, சீக்கிரமாய் எழுந்திரு என்று அவனை எழுப்பினான். உடனே சங்கிலிகள் அவன் கைகளிலிருந்து விழுந்தது.
	<br /><br />
	8. தூதன் அவனை நோக்கி: உன் அரையைக் கட்டி, உன் பாதரட்சைகளைத் தொடுத்துக்கொள் என்றான். அவன் அந்தப்படியே செய்தான். தூதன் பின்னும் அவனை நோக்கி: உன் வஸ்திரத்தைப் போர்த்துக்கொண்டு என் பின்னே வா என்றான்.
	<br /><br />
	9. அந்தப்படியே அவன் புறப்பட்டு அவனுக்குப் பின்சென்று, தூதனால் செய்யப்பட்டது மெய்யென்று அறியாமல், தான் ஒரு தரிசனங்காண்கிறதாக நினைத்தான்.
	<br /><br />
	10. அவர்கள் முதலாங்காவலையும் இரண்டாங்காவலையும் கடந்து, நகரத்திற்குப்போகிற இருப்புக்கதவண்டையிலே வந்தபோது அது தானாய் அவர்களுக்குத் திறவுண்டது; அதின் வழியாய் அவர்கள் புறப்பட்டு ஒரு வீதி நெடுக நடந்துபோனார்கள்; உடனே தூதன் அவனை விட்டுப்போய்விட்டான்.
	<br /><br />
	11. பேதுருவுக்குத் தெளிவு வந்தபோது: ஏரோதின் கைக்கும் யூதஜனங்களின் எண்ணங்களுக்கும் என்னை விடுதலையாக்கும்படிக்குக் கர்த்தர் தம்முடைய தூதனை அனுப்பினாரென்று நான் இப்பொழுது மெய்யாய் அறிந்திருக்கிறேன் என்றான்.
	<br /><br />
	12. அவன் இப்படி நிச்சயித்துக்கொண்டு, மாற்கு என்னும் பேர்கொண்ட யோவானுடைய தாயாகிய மரியாள் வீட்டுக்கு வந்தான்; அங்கே அநேகர் கூடி ஜெபம்பண்ணிக்கொண்டிருந்தார்கள்.
	<br /><br />
	13. பேதுரு வாசற்கதவைத் தட்டினபோது ரோதை என்னும் பேர்கொண்ட ஒரு பெண் ஒற்றுக்கேட்க வந்தாள்.
	<br /><br />
	14. அவள் பேதுருவின் சத்தத்தை அறிந்து சந்தோஷத்தினால் கதவைத் திறவாமல், உள்ளேயோடி, பேதுரு வாசலுக்குமுன்னே நிற்கிறார் என்று அறிவித்தாள்.
	<br /><br />
	15. அவர்கள்: நீ பிதற்றுகிறாய் என்றார்கள். அவளோ அவர்தானென்று உறுதியாய்ச் சாதித்தாள். அப்பொழுது அவர்கள்: அவருடைய தூதனாயிருக்கலாம் என்றார்கள்.
	<br /><br />
	16. பேதுரு பின்னும் தட்டிக்கொண்டிருந்தான். அவர்கள் திறந்தபோது அவனைக் கண்டு பிரமித்தார்கள்.
	<br /><br />
	17. அவர்கள் பேசாமலிருக்கும்படி அவன் கையமர்த்தி, கர்த்தர் தன்னைக் காவலிலிருந்து விடுதலையாக்கின விதத்தை அவர்களுக்கு விவரித்து, இந்தச் செய்தியை யாக்கோபுக்கும் சகோதரருக்கும் அறிவியுங்கள் என்று சொல்லி; புறப்பட்டு, வேறொரு இடத்திற்குப் போனான்.
	<br /><br />
	18. பொழுது விடிந்தபின்பு பேதுருவைக்குறித்துச் சேவகருக்குள்ளே உண்டான கலக்கம் கொஞ்சமல்ல.
	<br /><br />
	19. ஏரோது அவனைத் தேடிக் காணாமற்போனபோது, காவற்காரரை விசாரணைசெய்து, அவர்களைக் கொலைசெய்யும்படி கட்டளையிட்டு, பின்பு யூதேயாதேசத்தை விட்டுச் செசரியா பட்டணத்துக்குப்போய், அங்கே வாசம்பண்ணினான்.
	<br /><br />
	20. அக்காலத்திலே ஏரோது தீரியர்பேரிலும் சீதோனியர்பேரிலும் மிகவுங் கோபமாயிருந்தான். தங்கள் தேசம் ராஜாவின் தேசத்தினால் போஷிக்கப்பட்டபடியினால், அவர்கள் ஒருமனப்பட்டு, அவனிடத்தில் வந்து, ராஜாவின் வீட்டு விசாரணைக்காரனாகிய பிலாஸ்துவைத் தங்கள் வசமாக்கிச் சமாதானம் கேட்டுக்கொண்டார்கள்.
	<br /><br />
	21. குறித்தநாளிலே, ஏரோது ராஜவஸ்திரம் தரித்துக்கொண்டு, சிங்காசனத்தின்மேல் உட்கார்ந்து, அவர்களுக்குப் பிரசங்கம்பண்ணினான்.
	<br /><br />
	22. அப்பொழுது ஜனங்கள் இது மனுஷசத்தமல்ல, இது தேவசத்தம் என்று ஆர்ப்பரித்தார்கள்.
	<br /><br />
	23. அவன் தேவனுக்கு மகிமையைச் செலுத்தாதபடியினால் உடனே கர்த்தருடைய தூதன் அவனை அடித்தான்; அவன் புழுப்புழுத்து இறந்தான்.
	<br /><br />
	24. தேவவசனம் வளர்ந்து பெருகிற்று.
	<br /><br />
	25. பர்னபாவும் சவுலும் தர்ம ஊழியத்தை நிறைவேற்றினபின்பு மாற்கு என்னும் மறுபேர்கொண்ட யோவானைக் கூட்டிக்கொண்டு எருசலேமைவிட்டுத் திரும்பிவந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts12