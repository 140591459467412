import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ephesians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Book of Ephesians Bible Tamil | எபேசியர் 3 </title>
	<meta
          name="description"
          content="Ephesians 3 | எபேசியர் 3 |
          Book of Ephesians | Tamil Bible Verse | Tamil Bible | எபேசியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. இதினிமித்தம், பவுலாகிய நான் புறஜாதியாராயிருக்கிற உங்கள்பொருட்டுக் கிறிஸ்து இயேசுவினிமித்தம் கட்டுண்டவனாயிருக்கிறேன்.
	<br /><br />
	2. உங்களுக்காக எனக்கு அளிக்கப்பட்டிருக்கிற தெய்வகிருபைக்குரிய நியமமும் இன்னதென்று கேட்டிருப்பீர்களே;
	<br /><br />
	3. அதென்னவெனில் புறஜாதிகள் சுவிசேஷத்தினாலே உடன் சுதந்தரருமாய், ஒரே சரீரத்திற்குள்ளானவர்களுமாய், கிறிஸ்துவுக்குள் அவர் பண்ணின வாக்குத்தத்தத்துக்கு உடன்பங்காளிகளுமாயிருக்கிறார்களென்கிற இந்த இரகசியத்தை அவர் எனக்கு வெளிப்படுத்தி அறிவித்தார்.
	<br /><br />
	4. இதைக்குறித்து நான் முன்னமே சுருக்கமாய் எழுதியிருக்கிறேன்.
	<br /><br />
	5. அதை நீங்கள் வாசிக்கையில் கிறிஸ்துவின் இரகசியத்தைக்குறித்து எனக்கு உண்டாயிருக்கிற அறிவை அறிந்துகொள்ளலாம்;
	<br /><br />
	6. இந்த இரகசியம் இப்பொழுது அவருடைய பரிசுத்த அப்போஸ்தலருக்கும் தீர்க்கதரிசிகளுக்கும் ஆவியானவராலே வெளிப்படுத்தப்பட்டிருக்கிறதுபோல, முற்காலங்களில் மனுபுத்திரருக்கு அறிவிக்கப்படவில்லை.
	<br /><br />
	7. தேவனுடைய பலத்த சத்துவத்தால் எனக்கு அளிக்கப்பட்ட வரமாகிய அவருடைய கிருபையினாலே இந்தச் சுவிசேஷத்துக்கு ஊழியக்காரனானேன்.
	<br /><br />
	8. பரிசுத்தவான்களெல்லாரிலும் சிறியவனாகிய நான் கிறிஸ்துவினுடைய அளவற்ற ஐசுவரியத்தைப் புறஜாதிகளிடத்தில் சுவிசேஷமாய் அறிவிக்கிறதற்காக இந்தக் கிருபை எனக்கு அளிக்கப்பட்டிருக்கிறது.
	<br /><br />
	9. தேவன் நம்முடைய கர்த்தராகிய கிறிஸ்து இயேசுவுக்குள் கொண்டிருந்த அநாதி தீர்மானத்தின்படியே,
	<br /><br />
	10. உன்னதங்களிலுள்ள துரைத்தனங்களுக்கும் அதிகாரங்களுக்கும் அவருடைய அநந்த ஞானமானது சபையின்மூலமாய் இப்பொழுது தெரியவரும்பொருட்டாக,
	<br /><br />
	11. இயேசுகிறிஸ்துவைக்கொண்டு எல்லாவற்றையும் சிருஷ்டித்த தேவனுக்குள்ளே ஆதிகாலங்கள் முதல் மறைந்திருந்த இரகசியத்தினுடைய ஐக்கியம் இன்னதென்று, எல்லாருக்கும் வெளிப்படையாகக் காண்பிக்கிறதற்கு, இந்தக் கிருபை எனக்கு அளிக்கப்பட்டிருக்கிறது.
	<br /><br />
	12. அவரைப் பற்றும் விசுவாசத்தால் அவருக்குள் நமக்குத் தைரியமும் திடநம்பிக்கையோடே தேவனிடத்தில் சேரும் சிலாக்கியமும் உண்டாயிருக்கிறது.
	<br /><br />
	13. ஆகையால் உங்கள்நிமித்தம் நான் அநுபவிக்கிற உபத்திரவங்களினால் நீங்கள் சோர்ந்துபோகாதிருக்க வேண்டிக்கொள்ளுகிறேன்; அவைகள் உங்களுக்கு மகிமையாயிருக்கிறதே.
	<br /><br />
	14. இதினிமித்தம் நான் பரலோகத்திலும் பூலோகத்திலுமுள்ள முழுக்குடும்பத்துக்கும் நாமகாரணராகிய,
	<br /><br />
	15. நம்முடைய கர்த்தராயிருக்கிற இயேசுகிறிஸ்துவினுடைய பிதாவை நோக்கி முழங்கால்படியிட்டு,
	<br /><br />
	16. நீங்கள் அவருடைய ஆவியினாலே உள்ளான மனுஷனில் வல்லமையாய்ப் பலப்படவும்,
	<br /><br />
	17. விசுவாசத்தினாலே கிறிஸ்து உங்கள் இருதயங்களில் வாசமாயிருக்கவும், நீங்கள் அன்பிலே வேரூன்றி, நிலைபெற்றவர்களாகி,
	<br /><br />
	18. சகல பரிசுத்தவான்களோடுங்கூடக் கிறிஸ்துவினுடைய அன்பின் அகலமும், நீளமும், ஆழமும், உயரமும் இன்னதென்று உணர்ந்து;
	<br /><br />
	19. அறிவுக்கெட்டாத அந்த அன்பை அறிந்துகொள்ள வல்லவர்களாகவும், தேவனுடைய சகல பரிபூரணத்தாலும் நிறையப்படவும், அவர் தமது மகிமையினுடைய ஐசுவரியத்தின்படியே, உங்களுக்கு அநுக்கிரகம் பண்ணவேண்டுமென்று வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	20. நாம் வேண்டிக்கொள்ளுகிறதற்கும் நினைக்கிறதற்கும் மிகவும் அதிகமாய் நமக்குள்ளே கிரியைசெய்கிற வல்லமையின்படியே, நமக்குச் செய்ய வல்லவராகிய அவருக்கு,
	<br /><br />
	21. சபையிலே கிறிஸ்து இயேசுவின் மூலமாய்த் தலைமுறை தலைமுறைக்கும் சதாகாலங்களிலும் மகிமை உண்டாவதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ephesians3