import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 14 </title>
	<meta
          name="description"
          content="Mark 14 | மாற்கு 14 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இரண்டு நாளைக்குப்பின்பு புளிப்பில்லாத அப்பஞ்சாப்பிடுகிற பஸ்காபண்டிகை வந்தது. அப்பொழுது பிரதான ஆசாரியரும் வேதபாரகரும், அவரைத் தந்திரமாய்ப் பிடித்துக் கொலைசெய்யும்படி வகைதேடினார்கள்.
	<br /><br />
	2. ஆகிலும் ஜனங்களுக்குள்ளே கலகம் உண்டாகாதபடிக்கு, பண்டிகையிலே அப்படிச் செய்யலாகாது என்றார்கள்.
	<br /><br />
	3. அவர் பெத்தானியாவில் குஷ்டரோகியாயிருந்த சீமோன் வீட்டிலே போஜனபந்தியிருக்கையில், ஒரு ஸ்திரீ விலையேறப்பெற்ற நளதம் என்னும் உத்தம தைலமுள்ள வெள்ளைக்கல் பரணியைக் கொண்டுவந்து, அதை உடைத்து, அந்தத் தைலத்தை அவர் சிரசின்மேல் ஊற்றினாள்.
	<br /><br />
	4. அப்பொழுது சிலர் தங்களுக்குள்ளே விசனமடைந்து: இந்தத் தைலத்தை இப்படி வீணாய்ச் செலவழிப்பானேன்?
	<br /><br />
	5. இதை முந்நூறு பணத்துக்கு அதிகமான கிரயத்துக்கு விற்று, தரித்திரருக்குக் கொடுக்கலாமே என்று சொல்லி, அவளைக்குறித்து முறுமுறுத்தார்கள்.
	<br /><br />
	6. இயேசு அவர்களை நோக்கி: அவளை விட்டுவிடுங்கள்; ஏன் அவளைத் தொந்தரவு படுத்துகிறீர்கள்? என்னிடத்தில் நற்கிரியையைச் செய்திருக்கிறாள்.
	<br /><br />
	7. தரித்திரர் எப்போதும் உங்களிடத்தில் இருக்கிறார்கள், உங்களுக்கு மனதுண்டாகும்போதெல்லாம் நீங்கள் அவர்களுக்கு நன்மை செய்யலாம், நானோ எப்போதும் உங்களிடத்தில் இரேன்.
	<br /><br />
	8. இவள் தன்னால் இயன்றதைச் செய்தாள்; நான் அடக்கம்பண்ணப்படுவதற்கு எத்தனமாக, என் சரீரத்தில் தைலம்பூச முந்திக்கொண்டாள்.
	<br /><br />
	9. இந்தச் சுவிசேஷம் உலகத்தில் எங்கெங்கே பிரசங்கிக்கப்படுமோ அங்கங்கே இவளை நினைப்பதற்காக இவள் செய்ததும் சொல்லப்படும் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	10. அப்பொழுது, பன்னிருவரில் ஒருவனாகிய யூதாஸ்காரியோத்து என்பவன் அவரைப் பிரதான ஆசாரியருக்குக் காட்டிக்கொடுக்கும்படி அவர்களிடத்திற்குப் போனான்.
	<br /><br />
	11. அவர்கள் அதைக் கேட்டு, சந்தோஷப்பட்டு, அவனுக்குப் பணங்கொடுப்போம் என்று வாக்குத்தத்தம் பண்ணினார்கள்; அவன் அவரைக் காட்டிக்கொடுப்பதற்குத் தகுந்த சமயம் பார்த்துக்கொண்டிருந்தான்.
	<br /><br />
	12. பஸ்காவைப் பலியிடும் நாளாகிய புளிப்பில்லாத அப்பஞ்சாப்பிடுகிற முதலாம் நாளிலே, அவருடைய சீஷர்கள் அவரிடத்தில் வந்து: நீர் பஸ்காவைப் புசிப்பதற்கு நாங்கள் எங்கே போய் ஆயத்தம்பண்ணச் சித்தமாயிருக்கிறீர் என்று கேட்டார்கள்.
	<br /><br />
	13. அவர் தம்முடைய சீஷரில் இரண்டுபேரை நோக்கி: நீங்கள் நகரத்திற்குள்ளே போங்கள், அங்கே தண்ணீர்க்குடம் சுமந்துவருகிற ஒரு மனுஷன் உங்களுக்கு எதிர்ப்படுவான், அவன் பின்னே போங்கள்;
	<br /><br />
	14. அவன் எந்த வீட்டிற்குள் பிரவேசிக்கிறானோ அந்த வீட்டு எஜமானை நீங்கள் நோக்கி: நான் என் சீஷரோடுகூடப் பஸ்காவைப் புசிக்கிறதற்குத் தகுதியான இடம் எங்கேயென்று போதகர் கேட்கிறார் என்று சொல்லுங்கள்.
	<br /><br />
	15. அவன் கம்பளம் முதலானவைகள் விரித்து ஆயத்தம்பண்ணப்பட்டிருக்கிற விஸ்தாரமான மேல்வீட்டறையை உங்களுக்குக் காண்பிப்பான்; அங்கே நமக்காக ஆயத்தம்பண்ணுங்கள் என்று சொல்லி அனுப்பினார்.
	<br /><br />
	16. அப்படியே, அவருடைய சீஷர் புறப்பட்டு நகரத்தில் போய், தங்களுக்கு அவர் சொன்னபடியே கண்டு, பஸ்காவை ஆயத்தம்பண்ணினார்கள்.
	<br /><br />
	17. சாயங்காலமானபோது, அவர் பன்னிருவரோடுங்கூட அவ்விடத்திற்கு வந்தார்.
	<br /><br />
	18. அவர்கள் பந்தியமர்ந்து போஜனம்பண்ணுகையில், இயேசு அவர்களை நோக்கி: என்னுடனே புசிக்கிற உங்களில் ஒருவன் என்னைக் காட்டிக்கொடுப்பான் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	19. அப்பொழுது அவர்கள் துக்கமடைந்து: நானோ? நானோ? என்று ஒவ்வொருவரும், அவரிடத்தில் கேட்கத் தொடங்கினார்கள்.
	<br /><br />
	20. அவர் பிரதியுத்தரமாக: என்னுடனேகூடத் தாலத்தில் கையிடுகிறவனாகிய பன்னிருவரிலொருவனே அவன் என்று சொல்லி;
	<br /><br />
	21. மனுஷகுமாரன் தம்மைக்குறித்து எழுதியிருக்கிறபடியே போகிறார்; ஆகிலும், எந்த மனுஷனால் மனுஷகுமாரன் காட்டிக்கொடுக்கப்படுகிறாரோ, அந்த மனுஷனுக்கு ஐயோ! அந்த மனுஷன் பிறவாதிருந்தானானால் அவனுக்கு நலமாயிருக்கும் என்றார்.
	<br /><br />
	22. அவர்கள் போஜனம்பண்ணுகையில், இயேசு அப்பத்தை எடுத்து ஆசீர்வதித்து, அதைப் பிட்டு, அவர்களுக்குக் கொடுத்து: நீங்கள் வாங்கிப் புசியுங்கள், இது என்னுடைய சரீரமாயிருக்கிறது என்றார்.
	<br /><br />
	23. பின்பு, பாத்திரத்தையும் எடுத்து, ஸ்தோத்திரம்பண்ணி, அதை அவர்களுக்குக் கொடுத்தார். அவர்களெல்லாரும் அதிலே பானம்பண்ணினார்கள்.
	<br /><br />
	24. அப்பொழுது அவர் அவர்களை நோக்கி: இது அநேகருக்காகச் சிந்தப்படுகிற புது உடன்படிக்கைக்குரிய என்னுடைய இரத்தமாயிருக்கிறது.
	<br /><br />
	25. நான் தேவனுடைய ராஜ்யத்தில் நவமான ரசத்தைப் பானம்பண்ணும் நாள்வரைக்கும் திராட்சப்பழரசத்தை இனி நான் பானம்பண்ணுவதில்லையென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	26. அவர்கள் ஸ்தோத்திரப்பாட்டைப் பாடினபின்பு, ஒலிவமலைக்குப் புறப்பட்டுப்போனார்கள்.
	<br /><br />
	27. அப்பொழுது, இயேசு அவர்களை நோக்கி: மேய்ப்பனை வெட்டுவேன், ஆடுகள் சிதறடிக்கப்படும், என்று எழுதியிருக்கிறபடி, இந்த இராத்திரியிலே நீங்களெல்லாரும் என்னிமித்தம் இடறலடைவீர்கள்.
	<br /><br />
	28. ஆகிலும் நான் உயிர்த்தெழுந்தபின்பு, உங்களுக்கு முன்னே கலிலேயாவுக்குப் போவேன் என்றார்.
	<br /><br />
	29. அதற்குப் பேதுரு: உமதுநிமித்தம் எல்லாரும் இடறலடைந்தாலும், நான் இடறலடையேன் என்றான்.
	<br /><br />
	30. இயேசு அவனை நோக்கி: இன்றைக்கு, இந்த இராத்திரியிலே, சேவல் இரண்டுதரம் கூவுகிறதற்குமுன்னே, நீ மூன்றுதரம் என்னை மறுதலிப்பாய் என்று மெய்யாகவே உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	31. அதற்கு அவன்: நான் உம்மோடே மரிக்கவேண்டியதாயிருந்தாலும் உம்மை மறுதலிக்கமாட்டேன் என்று உறுதியாய்ச் சொன்னான்; எல்லாரும் அப்படியே சொன்னார்கள்.
	<br /><br />
	32. பின்பு கெத்செமனே என்னப்பட்ட இடத்திற்கு வந்தார்கள். அப்பொழுது அவர் தம்முடைய சீஷர்களை நோக்கி: நான் ஜெபம்பண்ணுமளவும் இங்கே உட்கார்ந்திருங்கள் என்று சொல்லி;
	<br /><br />
	33. பேதுருவையும் யாக்கோபையும் யோவானையும் தம்மோடே கூட்டிக்கொண்டுபோய், திகிலடையவும், மிகவும் வியாகுலப்படவும் தொடங்கினார்.
	<br /><br />
	34. அப்பொழுது அவர்: என் ஆத்துமா மரணத்துக்கேதுவான துக்கங்கொண்டிருக்கிறது. நீங்கள் இங்கே தங்கி, விழித்திருங்கள் என்று சொல்லி,
	<br /><br />
	35. சற்று அப்புறம்போய், தரையிலே விழுந்து, அந்த வேளை தம்மைவிட்டு நீங்கிப்போகக்கூடுமானால் அது நீங்கவேண்டுமென்று வேண்டிக்கொண்டு:
	<br /><br />
	36. அப்பா பிதாவே, எல்லாம் உம்மாலே கூடும்; இந்தப் பாத்திரத்தை என்னிடத்திலிருந்து எடுத்துப்போடும், ஆகிலும் என் சித்தத்தின்படியல்ல, உம்முடைய சித்தத்தின்படியே ஆகக்கடவது என்றார்.
	<br /><br />
	37. பின்பு அவர் வந்து, அவர்கள் நித்திரைபண்ணுகிறதைக்கண்டு, பேதுருவை நோக்கி: சீமோனே, நித்திரைபண்ணுகிறாயா? ஒரு மணிநேரம் நீ விழித்திருக்கக்கூடாதா?
	<br /><br />
	38. நீங்கள் சோதனைக்குட்படாதபடிக்கு விழித்திருந்து ஜெபம்பண்ணுங்கள். ஆவி உற்சாகமுள்ளதுதான், மாம்சமோ பலவீனமுள்ளது என்றார்.
	<br /><br />
	39. அவர் மறுபடியும் போய் அந்த வார்த்தைகளையே சொல்லி ஜெபம்பண்ணினார்.
	<br /><br />
	40. அவர் திரும்ப வந்தபோது, அவர்கள் மறுபடியும் நித்திரைபண்ணுகிறதைக் கண்டார்; அவர்களுடைய கண்கள் மிகுந்த நித்திரைமயக்கம் அடைந்திருந்தபடியால், தாங்கள் மறுமொழியாக அவருக்குச் சொல்வது இன்னதென்று அறியாதிருந்தார்கள்.
	<br /><br />
	41. அவர் மூன்றாந்தரம் வந்து: இனி நித்திரைபண்ணி இளைப்பாறுங்கள்; போதும், வேளை வந்தது, இதோ, மனுஷகுமாரன் பாவிகளுடைய கைகளில் ஒப்புக்கொடுக்கப்படுகிறார்.
	<br /><br />
	42. என்னைக் காட்டிக்கொடுக்கிறவன், இதோ, வந்துவிட்டான், எழுந்திருங்கள், போவோம் என்றார்.
	<br /><br />
	43. உடனே, அவர் இப்படிப் பேசுகையில், பன்னிருவரில் ஒருவனாகிய யூதாஸ் வந்தான்; அவனோடேகூடப் பிரதான ஆசாரியரும் வேதபாரகரும் மூப்பரும் அனுப்பின திரளான ஜனங்கள், பட்டயங்களையும் தடிகளையும் பிடித்துக்கொண்டு வந்தார்கள்.
	<br /><br />
	44. அவரைக் காட்டிக்கொடுக்கிறவன்: நான் எவனை முத்தஞ்செய்வேனோ அவன்தான், அவனைப் பிடித்துப் பத்திரமாய்க் கொண்டுபோங்கள் என்று அவர்களுக்குக் குறிப்புச் சொல்லியிருந்தான்.
	<br /><br />
	45. அவன் வந்தவுடனே, அவரண்டையில் சேர்ந்து: ரபீ, ரபீ, என்று சொல்லி, அவரை முத்தஞ்செய்தான்.
	<br /><br />
	46. அப்பொழுது அவர்கள் அவர்மேல் கைபோட்டு, அவரைப் பிடித்தார்கள்.
	<br /><br />
	47. அப்பொழுது கூடநின்றவர்களில் ஒருவன் கத்தியை உருவி, பிரதான ஆசாரியனுடைய வேலைக்காரனைக் காதற வெட்டினான்.
	<br /><br />
	48. இயேசு அவர்களை நோக்கி: கள்ளனைப் பிடிக்கப் புறப்படுகிறதுபோல, நீங்கள் பட்டயங்களையும் தடிகளையும் எடுத்துக்கொண்டு என்னைப் பிடிக்கவந்தீர்கள்;
	<br /><br />
	49. நான் தினந்தோறும் உங்கள் நடுவிலே தேவாலயத்தில் உபதேசம்பண்ணிக் கொண்டிருந்தேன்; அப்பொழுது நீங்கள் என்னைப் பிடிக்கவில்லையே; ஆனாலும் வேதவாக்கியங்கள் நிறைவேற வேண்டியதாயிருக்கிறது என்றார்.
	<br /><br />
	50. அப்பொழுது எல்லாரும் அவரைவிட்டு ஓடிப்போனார்கள்.
</p>
<p>
	51. ஒரு வாலிபன் ஒரு துப்பட்டியை மாத்திரம் தன்மேல் போர்த்துக்கொண்டு அவர் பின்னே போனான்; அவனைப் பிடித்தார்கள்.
	<br /><br />
	52. அவன் தன் துப்பட்டியைப் போட்டு விட்டு, நிர்வாணமாய் அவர்களை விட்டு ஓடிப்போனான்.
	<br /><br />
	53. இயேசுவை அவர்கள் பிரதான ஆசாரியனிடத்தில் கொண்டுபோனார்கள்; அங்கே ஆசாரியர் மூப்பர் வேதபாரகர் எல்லாரும் கூடிவந்திருந்தார்கள்.
	<br /><br />
	54. பேதுரு தூரத்திலே அவருக்குப் பின்சென்று, பிரதான ஆசாரியனுடைய அரமனைக்குள் வந்து, சேவகரோடேகூட உட்கார்ந்து, நெருப்பண்டையிலே குளிர்காய்ந்துகொண்டிருந்தான்.
	<br /><br />
	55. அப்பொழுது பிரதான ஆசாரியரும் ஆலோசனைச் சங்கத்தாரனைவரும் இயேசுவைக் கொலைசெய்யும்படி அவருக்கு விரோதமாகச் சாட்சி தேடினார்கள்; அகப்படவில்லை.
	<br /><br />
	56. அநேகர் அவருக்கு விரோதமாகப் பொய்ச்சாட்சி சொல்லியும், அந்தச் சாட்சிகள் ஒவ்வவில்லை.
	<br /><br />
	57. அப்பொழுது சிலர் எழுந்து, கைவேலையாகிய இந்தத் தேவாலயத்தை நான் இடித்துப்போட்டு, கைவேலையல்லாத வேறொன்றை மூன்று நாளைக்குள்ளே கட்டுவேன் என்று இவன் சொன்னதை நாங்கள் கேட்டோம் என்று,
	<br /><br />
	58. அவருக்கு விரோதமாய்ப் பொய்ச்சாட்சி சொன்னார்கள்.
	<br /><br />
	59. அப்படிச் சொல்லியும் அவர்கள் சாட்சி ஒவ்வாமற்போயிற்று.
	<br /><br />
	60. அப்பொழுது பிரதான ஆசாரியன் எழுந்து நடுவே நின்று, இயேசுவை நோக்கி: இவர்கள் உனக்கு விரோதமாய்ச் சொல்லுகிறதைக் குறித்து நீ ஒன்றும் சொல்லுகிறதில்லையா என்று கேட்டான்.
	<br /><br />
	61. அவரோ ஒரு உத்தரவும் சொல்லாமல் பேசாதிருந்தார். மறுபடியும் பிரதான ஆசாரியன் அவரை நோக்கி: நீ ஸ்தோத்திரிக்கப்பட்ட தேவனுடைய குமாரனாகிய கிறிஸ்துதானா? என்று கேட்டான்.
	<br /><br />
	62. அதற்கு இயேசு: நான் அவர்தான்; மனுஷகுமாரன் சர்வவல்லவரின் வலதுபாரிசத்தில் வீற்றிருப்பதையும், வானத்தின் மேகங்கள்மேல் வருவதையும் நீங்கள் காண்பீர்கள் என்றார்.
	<br /><br />
	63. பிரதான ஆசாரியன் இதைக் கேட்டவுடனே, தன் வஸ்திரங்களைக் கிழித்துக்கொண்டு: இனிச் சாட்சிகள் நமக்கு வேண்டியதென்ன?
	<br /><br />
	64. தேவதூஷணத்தைக் கேட்டீர்களே, உங்களுக்கு என்னமாய்த் தோன்றுகிறது என்றான். அதற்கு அவர்களெல்லாரும்: இவன் மரணத்துக்குப் பாத்திரனாயிருக்கிறான் என்று தீர்மானம்பண்ணினார்கள்.
	<br /><br />
	65. அப்பொழுது சிலர் அவர்மேல் துப்பவும், அவருடைய முகத்தை மூடவும், அவரைக் குட்டவும், ஞானதிருஷ்டியினாலே பார்த்துச் சொல் என்று சொல்லவும் தொடங்கினார்கள்; வேலைக்காரரும் அவரைக் கன்னத்தில் அறைந்தார்கள்.
	<br /><br />
	66. அத்தருணத்திலே பேதுரு கீழே அரமனை முற்றத்திலிருக்கையில், பிரதான ஆசாரியனுடைய வேலைக்காரிகளில் ஒருத்தி வந்து,
	<br /><br />
	67. குளிர்காய்ந்துகொண்டிருக்கிற பேதுருவைக் கண்டு, அவனை உற்றுப்பார்த்து: நீயும் நசரேயனாகிய இயேசுவோடே இருந்தாய் என்றாள்.
	<br /><br />
	68. அதற்கு அவன்: நான் அறியேன்; நீ சொல்லுகிறது எனக்குத் தெரியாது என்று மறுதலித்து, வெளியே வாசல் மண்டபத்துக்குப் போனான்; அப்பொழுது சேவல் கூவிற்று.
	<br /><br />
	69. வேலைக்காரி அவனை மறுபடியும் கண்டு: இவன் அவர்களில் ஒருவன் என்று அருகே நின்றவர்களுக்குச் சொன்னாள்.
	<br /><br />
	70. அவன் மறுபடியும் மறுதலித்தான். சற்றுநேரத்துக்குப்பின்பு மறுபடியும் அருகே நிற்கிறவர்கள் பேதுருவைப் பார்த்து: மெய்யாகவே நீ அவர்களில் ஒருவன், நீ கலிலேயன், உன் பேச்சு அதற்கு ஒத்திருக்கிறது என்றார்கள்.
	<br /><br />
	71. அதற்கு அவன்: நீங்கள் சொல்லுகிற மனுஷனை அறியேன் என்று சொல்லி, சபிக்கவும் சத்தியம்பண்ணவும் தொடங்கினான்.
	<br /><br />
	72. உடனே சேவல் இரண்டாந்தரம் கூவிற்று. சேவல் இரண்டுதரங் கூவுகிறதற்குமுன்னே நீ என்னை மூன்றுதரம் மறுதலிப்பாய் என்று இயேசு தனக்குச் சொன்ன வார்த்தையைப் பேதுரு நினைவுகூர்ந்து, மிகவும் அழுதான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark14