import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 7 </title>
	<meta
          name="description"
          content="Matthew 7 | மத்தேயு 7 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீங்கள் குற்றவாளிகளென்று தீர்க்கப்படாதபடிக்கு மற்றவர்களைக் குற்றவாளிகளென்று தீர்க்காதிருங்கள்.
	<br /><br />
	2. ஏனெனில், நீங்கள் மற்றவர்களைத் தீர்க்கிற தீர்ப்பின்படியே நீங்களும் தீர்க்கப்படுவீர்கள்; நீங்கள் மற்றவர்களுக்கு அளக்கிற அளவின்படியே உங்களுக்கும் அளக்கப்படும்.
	<br /><br />
	3. நீ உன் கண்ணிலிருக்கிற உத்திரத்தை உணராமல், உன் சகோதரன் கண்ணிலிருக்கிற துரும்பைப் பார்க்கிறதென்ன?
	<br /><br />
	4. இதோ, உன் கண்ணில் உத்திரம் இருக்கையில் உன் சகோதரனை நோக்கி: நான் உன் கண்ணிலிருக்கும் துரும்பை எடுத்துப்போடட்டும் என்று நீ சொல்வதெப்படி?
	<br /><br />
	5. மாயக்காரனே! முன்பு உன் கண்ணிலிருக்கிற உத்திரத்தை எடுத்துப்போடு; பின்பு உன் சகோதரன் கண்ணிலிருக்கிற துரும்பை எடுத்துப்போட வகைபார்ப்பாய்.
	<br /><br />
	6. பரிசுத்தமானதை நாய்களுக்குக் கொடாதேயுங்கள்; உங்கள் முத்துக்களைப் பன்றிகள்முன் போடாதேயுங்கள்; போட்டால் தங்கள் கால்களால் அவைகளை மிதித்து, திரும்பிக்கொண்டு உங்களைப் பீறிப்போடும்.
	<br /><br />
	7. கேளுங்கள், அப்பொழுது உங்களுக்குக் கொடுக்கப்படும்; தேடுங்கள், அப்பொழுது கண்டடைவீர்கள்; தட்டுங்கள், அப்பொழுது உங்களுக்குத் திறக்கப்படும்;
	<br /><br />
	8. ஏனென்றால், கேட்கிறவன் எவனும் பெற்றுக்கொள்ளுகிறான்; தேடுகிறவன் கண்டடைகிறான்; தட்டுகிறவனுக்குத் திறக்கப்படும்.
	<br /><br />
	9. உங்களில் எந்த மனுஷனானாலும் தன்னிடத்தில் அப்பத்தைக் கேட்கிற தன் மகனுக்குக் கல்லைக் கொடுப்பானா?
	<br /><br />
	10. மீனைக் கேட்டால் அவனுக்குப் பாம்பைக் கொடுப்பானா?
	<br /><br />
	11. ஆகையால், பொல்லாதவர்களாகிய நீங்கள் உங்கள் பிள்ளைகளுக்கு நல்ல ஈவுகளைக் கொடுக்க அறிந்திருக்கும்போது, பரலோகத்திலிருக்கிற உங்கள் பிதா தம்மிடத்தில் வேண்டிக்கொள்ளுகிறவர்களுக்கு நன்மையானவைகளைக் கொடுப்பது அதிக நிச்சயம் அல்லவா?
	<br /><br />
	12. ஆதலால், மனுஷர் உங்களுக்கு எவைகளைச்செய்ய விரும்புகிறீர்களோ, அவைகளை நீங்களும் அவர்களுக்குச் செய்யுங்கள்; இதுவே நியாயப்பிரமாணமும் தீர்க்கதரிசனங்களுமாம்.
	<br /><br />
	13. இடுக்கமான வாசல்வழியாய் உட்பிரவேசியுங்கள்; கேட்டுக்குப் போகிற வாசல் விரிவும், வழி விசாலமுமாயிருக்கிறது; அதின் வழியாய்ப் பிரவேசிக்கிறவர்கள் அநேகர்.
	<br /><br />
	14. ஜீவனுக்குப் போகிற வாசல் இடுக்கமும், வழி நெருக்கமுமாயிருக்கிறது; அதைக் கண்டுபிடிக்கிறவர்கள் சிலர்.
	<br /><br />
	15. கள்ளத் தீர்க்கதரிசிகளுக்கு எச்சரிக்கையாயிருங்கள்; அவர்கள் ஆட்டுத்தோலைப் போர்த்துக்கொண்டு உங்களிடத்தில் வருவார்கள்; உள்ளத்திலோ அவர்கள் பட்சிக்கிற ஓநாய்கள்.
	<br /><br />
	16. அவர்களுடைய கனிகளினாலே அவர்களை அறிவீர்கள்; முட்செடிகளில் திராட்சப்பழங்களையும், முட்பூண்டுகளில் அத்திப்பழங்களையும் பறிக்கிறார்களா?
	<br /><br />
	17. அப்படியே நல்ல மரமெல்லாம் நல்ல கனிகளைக் கொடுக்கும்; கெட்ட மரமோ கெட்ட கனிகளைக் கொடுக்கும்.
	<br /><br />
	18. நல்ல மரம் கெட்ட கனிகளைக் கொடுக்கமாட்டாது; கெட்ட மரம் நல்ல கனிகளைக் கொடுக்கமாட்டாது.
	<br /><br />
	19. நல்ல கனிகொடாத மரமெல்லாம் வெட்டுண்டு, அக்கினியிலே போடப்படும்.
	<br /><br />
	20. ஆதலால், அவர்களுடைய கனிகளினாலே அவர்களை அறிவீர்கள்.
	<br /><br />
	21. பரலோகத்திலிருக்கிற என் பிதாவின் சித்தத்தின்படி செய்கிறவனே பரலோகராஜ்யத்தில் பிரவேசிப்பானேயல்லாமல், என்னை நோக்கி: கர்த்தாவே! கர்த்தாவே! என்று சொல்லுகிறவன் அதில் பிரவேசிப்பதில்லை.
	<br /><br />
	22. அந்நாளில் அநேகர் என்னை நோக்கி: கர்த்தாவே! கர்த்தாவே! உமது நாமத்தினாலே தீர்க்கதரிசனம் உரைத்தோம் அல்லவா? உமது நாமத்தினாலே பிசாசுகளைத் துரத்தினோம் அல்லவா? உமது நாமத்தினாலே அநேக அற்புதங்களைச் செய்தோம் அல்லவா? என்பார்கள்.
	<br /><br />
	23. அப்பொழுது, நான் ஒருக்காலும் உங்களை அறியவில்லை; அக்கிரமச் செய்கைக்காரரே, என்னைவிட்டு அகன்றுபோங்கள் என்று அவர்களுக்குச் சொல்லுவேன்.
	<br /><br />
	24. ஆகையால், நான் சொல்லிய இந்த வார்த்தைகளைக் கேட்டு, இவைகளின்படி செய்கிறவன் எவனோ, அவனைக் கன்மலையின்மேல் தன் வீட்டைக் கட்டின புத்தியுள்ள மனுஷனுக்கு ஒப்பிடுவேன்.
	<br /><br />
	25. பெருமழை சொரிந்து, பெருவெள்ளம் வந்து, காற்று அடித்து, அந்த வீட்டின்மேல் மோதியும், அது விழவில்லை; ஏனென்றால், அது கன்மலையின்மேல் அஸ்திபாரம் போடப்பட்டிருந்தது.
	<br /><br />
	26. நான் சொல்லிய இந்த வார்த்தைகளைக்கேட்டு, இவைகளின்படி செய்யாதிருக்கிறவன் எவனோ, அவன் தன் வீட்டை மணலின்மேல் கட்டின புத்தியில்லாத மனுஷனுக்கு ஒப்பிடப்படுவான்.
	<br /><br />
	27. பெருமழை சொரிந்து, பெருவெள்ளம் வந்து, காற்று அடித்து, அந்த வீட்டின்மேல் மோதினபோது அது விழுந்தது; விழுந்து முழுவதும் அழிந்தது என்றார்.
	<br /><br />
	28. இயேசு இந்த வார்த்தைகளைச் சொல்லி முடித்தபோது, அவர் வேதபாரகரைப்போல் போதியாமல், அதிகாரமுடையவராய் அவர்களுக்குப் போதித்தபடியால்,
	<br /><br />
	29. ஜனங்கள் அவருடைய போதகத்தைக்குறித்து ஆச்சரியப்பட்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew7