import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah63 = () => {
  const verseNumber = 63;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 63 </title>
	<meta
          name="description"
          content="Isaiah 63 | ஏசாயா 63 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஏதோமிலும் அதிலுள்ள போஸ்றா பட்டணத்திலுமிருந்து, சாயந்தீர்ந்த வஸ்திரங்களுடையவராகவும், மகத்துவமாய் உடுத்திருக்கிறவராகவும், தமது மகத்தான வல்லமையிலே எழுந்தருளினவராகவும் வருகிற இவர் யார்? நீதியாய்ப் பேசி இரட்சிக்க வல்லவராகிய நான்தானே.
	<br /><br />
	2. உம்முடைய உடுப்புச் சிவப்பாகவும், உம்முடைய வஸ்திரங்கள் ஆலையை மிதிக்கிறவன் வஸ்திரங்கள்போலவும் இருக்கிறதென்ன?
	<br /><br />
	3. நான் ஒருவனாய் ஆலையை மிதித்தேன்; ஜனங்களில் ஒருவனும் என்னோடிருந்ததில்லை; நான் என் கோபத்திலே அவர்களை மிதித்து, என் உக்கிரத்திலே அவர்களை நசுக்கிப்போட்டேன்; அதினால் அவர்கள் இரத்தம் என் வஸ்திரங்களின்மேல் தெறித்தது, என் உடுப்பையெல்லாம் கறைப்படுத்திக்கொண்டேன்.
	<br /><br />
	4. நீதியைச் சரிக்கட்டும் நாள் என் மனதிலிருந்தது; என்னுடையவர்களை மீட்கும் வருஷம் வந்தது.
	<br /><br />
	5. நான் பார்த்தேன், துணைசெய்வார் ஒருவருமில்லை; தாங்குவார் ஒருவருமில்லை என்று ஆச்சரியப்பட்டேன்; அப்பொழுது என் புயமே எனக்கு இரட்சிப்பாகி, என் உக்கிரமே என்னைத் தாங்கிற்று.
	<br /><br />
	6. நான் என் கோபத்திலே ஜனங்களை மிதித்து, என் உக்கிரத்திலே அவர்களை வெறியாக்கி, அவர்கள் சாரத்தைத் தரையிலே இறங்கப்பண்ணினேன்.
	<br /><br />
	7. கர்த்தர் எங்களுக்குச் செய்தருளின எல்லாவற்றிற்கும் தக்கதாகவும், அவர் தம்முடைய இரக்கங்களின்படியும் தம்முடைய திரளான தயவுகளின்படியும், இஸ்ரவேல் வம்சத்துக்குச் செய்த மகா நன்மைக்குத்தக்கதாகவும், கர்த்தருடைய கிரியைகளையும், கர்த்தருடைய துதிகளையும் பிரஸ்தாபம்பண்ணுவேன்.
	<br /><br />
	8. அவர்கள் என் ஜனந்தானென்றும், அவர்கள் வஞ்சனைசெய்யாதிருக்கும் பிள்ளைகளென்றும் சொல்லி, அவர்களுக்கு இரட்சகரானார்.
	<br /><br />
	9. அவர்களுடைய எல்லா நெருக்கத்திலும் அவர் நெருக்கப்பட்டார்; அவருடைய சமுகத்தின் தூதனானவர் அவர்களை இரட்சித்தார்; அவர் தமது அன்பினிமித்தமும், தமது பரிதாபத்தினிமித்தமும் அவர்களை மீட்டு, பூர்வநாட்களிலெல்லாம் அவர்களைத் தூக்கிச் சுமந்துவந்தார்.
	<br /><br />
	10. அவர்களோ கலகம்பண்ணி, அவருடைய பரிசுத்த ஆவியை விசனப்படுத்தினார்கள்; அதினால் அவர் அவர்களுக்குச் சத்துருவாய் மாறி, அவரே அவர்களுக்கு விரோதமாய் யுத்தம்பண்ணினார்.
	<br /><br />
	11. ஆகிலும் அவர் பூர்வநாட்களையும், மோசேயையும், தம்முடைய ஜனத்தையும் நினைவுகூர்ந்தார்; ஆனாலும் அவர்களையும், தமது மந்தையின் மேய்ப்பனையும் சமுத்திரத்திலிருந்து ஏறப்பண்ணினவர் இப்பொழுது எங்கே?
	<br /><br />
	12. அவர்கள் நடுவிலே தம்முடைய பரிசுத்த ஆவியை இருக்கக் கட்டளையிட்டு, மோசேயின் வலதுகையைக்கொண்டு அவர்களைத் தமது மகிமையின் புயத்தினாலே நடத்தி, தமக்கு நித்தியகீர்த்தியை உண்டாக்க அவர்களுக்கு முன்பாகத் தண்ணீரைப்பிளந்து,
	<br /><br />
	13. ஒரு குதிரை வனாந்தரவெளியிலே நடக்கிறதுபோல, அவர்கள் இடறாதபடிக்கு அவர்களை ஆழங்களில் நடக்கப்பண்ணினவர் எங்கே?
	<br /><br />
	14. கர்த்தருடைய ஆவியானவர் அவர்களைப் பள்ளத்தாக்கிலே போய் இறங்குகிற மிருகஜீவன்களைப்போல இளைப்பாறப்பண்ணினார்; இப்படியே தேவரீர், உமக்கு மகிமையுள்ள கீர்த்தியை உண்டாக்கும்படி உம்முடைய ஜனத்தை நடத்தினீர்.
	<br /><br />
	15. தேவரீர் பரலோகத்திலிருந்து கண்ணோக்கி, பரிசுத்தமும் மகிமையுமுள்ள உம்முடைய வாசஸ்தலத்திலிருந்து பாரும், உம்முடைய வைராக்கியமும், உம்முடைய வல்லமையும் எங்கே? உம்முடைய உள்ளத்தின் கொதிப்பையும், உம்முடைய மன உருக்கத்தையும் எனக்கு முன்பாக அடக்கிக்கொள்ளுகிறீரோ?
	<br /><br />
	16. தேவரீர் எங்கள் பிதாவாயிருக்கிறீர்; ஆபிரகாம் எங்களை அறியான், இஸ்ரவேலுக்கு நாங்கள் அறியப்பட்டவர்களுமல்ல; கர்த்தாவே, நீர் எங்கள் பிதாவும், எங்கள் மீட்பருமாயிருக்கிறீர்; இது பூர்வகாலமுதல் உம்முடைய நாமம்.
	<br /><br />
	17. கர்த்தாவே, நீர் எங்களை உம்முடைய வழிகளைவிட்டுத் தப்பிப்போகப்பண்ணி, எங்கள் இருதயத்தை உமக்குப் பயப்படாதபடிக்குக் கடினப்படுத்துவானேன்? உம்முடைய ஊழியக்காரரினிமித்தமும், உம்முடைய சுதந்தரமான கோத்திரங்களினிமித்தமும் திரும்பியருளும்.
	<br /><br />
	18. பரிசுத்தமுள்ள உமது ஜனங்கள் கொஞ்சக்காலமாத்திரம் அதைச் சுதந்தரித்தார்கள்; எங்கள் சத்துருக்கள் உம்முடைய பரிசுத்த ஸ்தலத்தை மிதித்துப்போட்டார்கள்.
	<br /><br />
	19. நாங்களே உம்முடையவர்கள், அவர்களை ஒருபொழுதும் நீர் ஆண்டதில்லை; அவர்களுக்கு உமது நாமம் தரிக்கப்பட்டதுமில்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah63