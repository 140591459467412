import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 3 </title>
	<meta
          name="description"
          content="Matthew 3 | மத்தேயு 3 | Mathew Gospel | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாட்களில் யோவான்ஸ்நானன் யூதேயாவின் வனாந்தரத்தில் வந்து:
	<br /><br />
	2. மனந்திரும்புங்கள், பரலோகராஜ்யம் சமீபித்திருக்கிறது என்று பிரசங்கம் பண்ணினான்.
	<br /><br />
	3. கர்த்தருக்கு வழியை ஆயத்தப்படுத்துங்கள்; அவருக்குப் பாதைகளைச் செவ்வைபண்ணுங்கள் என்று வனாந்தரத்திலே கூப்பிடுகிறவனுடைய சத்தம் உண்டென்று, ஏசாயா தீர்க்கதரிசியினால் சொல்லப்பட்டவன் இவனே.
	<br /><br />
	4. இந்த யோவான் ஒட்டகமயிர் உடையைத் தரித்து, தன் அரையில் வார்க்கச்சையைக் கட்டிக்கொண்டிருந்தான்; வெட்டுக்கிளியும் காட்டுத் தேனும் அவனுக்கு ஆகாரமாயிருந்தது.
	<br /><br />
	5. அப்பொழுது, எருசலேம் நகரத்தாரும், யூதேயா தேசத்தார் அனைவரும், யோர்தானுக்கு அடுத்த சுற்றுப்புறத்தார் யாவரும் அவனிடத்திற்குப் போய்,
	<br /><br />
	6. தங்கள் பாவங்களை அறிக்கையிட்டு, யோர்தான் நதியில் அவனால் ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	7. பரிசேயரிலும் சதுசேயரிலும் அநேகர் தன்னிடத்தில் ஞானஸ்நானம் பெறும்படி வருகிறதை அவன் கண்டு: விரியன் பாம்புக் குட்டிகளே! வருங்கோபத்துக்குத் தப்பித்துக்கொள்ள உங்களுக்கு வகைகாட்டினவன் யார்?
	<br /><br />
	8. மனந்திரும்புதலுக்கு ஏற்ற கனிகளைக் கொடுங்கள்.
	<br /><br />
	9. ஆபிரகாம் எங்களுக்குத் தகப்பன் என்று உங்களுக்குள்ளே சொல்லிக்கொள்ள நினையாதிருங்கள்; தேவன் இந்தக் கல்லுகளினாலே ஆபிரகாமுக்குப் பிள்ளைகளை உண்டுபண்ண வல்லவராயிருக்கிறார் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	10. இப்பொழுதே கோடரியானது மரங்களின் வேர் அருகே வைத்திருக்கிறது; ஆகையால், நல்ல கனிகொடாத மரமெல்லாம் வெட்டுண்டு அக்கினியிலே போடப்படும்.
	<br /><br />
	11. மனந்திரும்புதலுக்கென்று நான் ஜலத்தினால் உங்களுக்கு ஞானஸ்நானங் கொடுக்கிறேன்; எனக்குப்பின் வருகிறவரோ என்னிலும் வல்லவராயிருக்கிறார், அவருடைய பாதரட்சைகளைச் சுமக்கிறதற்கு நான் பாத்திரன் அல்ல; அவர் பரிசுத்த ஆவியினாலும் அக்கினியினாலும் உங்களுக்கு ஞானஸ்நானம் கொடுப்பார்.
	<br /><br />
	12. தூற்றுக்கூடை அவர் கையில் இருக்கிறது; அவர் தமது களத்தை நன்றாய் விளக்கி, தமது கோதுமையைக் களஞ்சியத்தில் சேர்ப்பார்; பதரையோ அவியாத அக்கினியினால் சுட்டெரிப்பார் என்றான்.
	<br /><br />
	13. அப்பொழுது யோவானால் ஞானஸ்நானம் பெறுவதற்கு இயேசு கலிலேயாவைவிட்டு யோர்தானுக்கு அருகே அவனிடத்தில் வந்தார்.
	<br /><br />
	14. யோவான் அவருக்குத் தடை செய்து: நான் உம்மாலே ஞானஸ்நானம் பெறவேண்டியதாயிருக்க, நீர் என்னிடத்தில் வரலாமா என்றான்.
	<br /><br />
	15. இயேசு அவனுக்குப் பிரதியுத்தரமாக: இப்பொழுது இடங்கொடு, இப்படி எல்லா நீதியையும் நிறைவேற்றுவது நமக்கு ஏற்றதாயிருக்கிறது என்றார். அப்பொழுது அவருக்கு இடங்கொடுத்தான்.
	<br /><br />
	16. இயேசு ஞானஸ்நானம் பெற்று, ஜலத்திலிருந்து கரையேறினவுடனே, இதோ, வானம் அவருக்குத் திறக்கப்பட்டது; தேவ ஆவி புறாவைப்போல இறங்கி, தம்மேல் வருகிறதைக் கண்டார்.
	<br /><br />
	17. அன்றியும், வானத்திலிருந்து ஒரு சத்தம் உண்டாகி: இவர் என்னுடைய நேசகுமாரன், இவரில் பிரியமாயிருக்கிறேன் என்று உரைத்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew3