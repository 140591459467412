import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 5 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 5 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 5 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உங்களுக்குள்ளே விபசாரம் உண்டென்று பிரசித்தமாய்ச் சொல்லப்படுகிறதே; ஒருவன் தன் தகப்பனுடைய மனைவியை வைத்துக்கொண்டிருக்கிறானே; அது அஞ்ஞானிகளுக்குள்ளும் சொல்லப்படாத விபசாரமாயிருக்கிறதே.
	<br /><br />
	2. இப்படிப்பட்ட காரியஞ்செய்தவனை நீங்கள் உங்களைவிட்டு நீக்காமலும் துக்கப்படாமலும், இறுமாப்படைந்திருக்கிறீர்கள்.
	<br /><br />
	3. நான் சரீரத்தினாலே உங்களுக்குத் தூரமாயிருந்தும், ஆவியினாலே உங்களோடேகூட இருக்கிறவனாய், இப்படிச் செய்தவனைக்குறித்து நான் கூட இருக்கிறதுபோல,
	<br /><br />
	4. நீங்களும், என்னுடைய ஆவியும், நமது கர்த்தராகிய இயேசுகிறிஸ்துவின் அதிகாரத்தோடே கூடிவந்திருக்கையில்,
	<br /><br />
	5. அப்படிப்பட்டவனுடைய ஆவி கர்த்தராகிய இயேசுகிறிஸ்துவின் நாளிலே இரட்சிக்கப்படும்படி, மாம்சத்தின் அழிவுக்காக, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் நாமத்தினாலே அவனைச் சாத்தானுக்கு ஒப்புக்கொடுக்கவேண்டுமென்று தீர்ப்புச்செய்கிறேன்.
	<br /><br />
	6. நீங்கள் மேன்மைபாராட்டுகிறது நல்லதல்ல; கொஞ்சம் புளித்தமா பிசைந்தமா முழுவதையும் புளிப்பாக்குமென்று அறியீர்களா?
	<br /><br />
	7. ஆகையால், நீங்கள் புளிப்பில்லாதவர்களாயிருக்கிறபடியே, புதிதாய்ப் பிசைந்த மாவாயிருக்கும்படிக்கு, பழைய புளித்தமாவைப் புறம்பே கழித்துப்போடுங்கள். ஏனெனில் நம்முடைய பஸ்காவாகிய கிறிஸ்து நமக்காகப் பலியிடப்பட்டிருக்கிறாரே.
	<br /><br />
	8. ஆதலால் பழைய புளித்தமாவோடே அல்ல, துர்க்குணம் பொல்லாப்பு என்னும் புளித்தமாவோடும் அல்ல, துப்புரவு உண்மை என்னும் புளிப்பில்லாத அப்பத்தோடே பண்டிகையை ஆசரிக்கக்கடவோம்.
	<br /><br />
	9. விபசாரக்காரரோடே கலந்திருக்கக்கூடாதென்று நிருபத்தில் உங்களுக்கு எழுதினேன்.
	<br /><br />
	10. ஆனாலும், இவ்வுலகத்திலுள்ள விபசாரக்காரர், பொருளாசைக்காரர், கொள்ளைக்காரர், விக்கிரகாராதனைக்காரர் இவர்களோடு எவ்வளவும் கலந்திருக்கக்கூடாதென்று நான் எழுதவில்லை; அப்படியானால் நீங்கள் உலகத்தைவிட்டு நீங்கிப்போகவேண்டியதாயிருக்குமே.
	<br /><br />
	11. நான் உங்களுக்கு எழுதினதென்னவென்றால், சகோதரனென்னப்பட்ட ஒருவன் விபசாரக்காரனாயாவது, பொருளாசைக்காரனாயாவது, விக்கிரகாராதனைக்காரனாயாவது, உதாசினனாயாவது, வெறியனாயாவது, கொள்ளைக்காரனாயாவது இருந்தால், அவனோடே கலந்திருக்கக்கூடாது; அப்படிப்பட்டவனுடனேகூடப் புசிக்கவுங்கூடாது.
	<br /><br />
	12. புறம்பே இருக்கிறவர்களைக் குறித்துத் தீர்ப்புச்செய்கிறது என் காரியமா? உள்ளே இருக்கிறவர்களைக் குறித்தல்லவோ நீங்கள் தீர்ப்புச்செய்கிறீர்கள்?
	<br /><br />
	13. புறம்பே இருக்கிறவர்களைக் குறித்துத் தேவனே தீர்ப்புச்செய்வார். ஆகையால் அந்தப் பொல்லாதவனை உங்களைவிட்டுத் தள்ளிப்போடுங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians5