import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 18 </title>
	<meta
          name="description"
          content="Matthew 18 | மத்தேயு 18 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவ்வேளையிலே சீஷர்கள் இயேசுவினிடத்தில் வந்து: பரலோகராஜ்யத்தில் எவன் பெரியவனாயிருப்பான் என்று கேட்டார்கள்.
	<br /><br />
	2. இயேசு ஒரு பிள்ளையைத் தம்மிடத்தில் அழைத்து, அதை அவர்கள் நடுவே நிறுத்தி:
	<br /><br />
	3. நீங்கள் மனந்திரும்பிப் பிள்ளைகளைப்போல் ஆகாவிட்டால், பரலோகராஜ்யத்தில் பிரவேசிக்கமாட்டீர்கள் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	4. ஆகையால் இந்தப் பிள்ளையைப்போலத் தன்னைத் தாழ்த்துகிறவன் எவனோ, அவனே பரலோகராஜ்யத்தில் பெரியவனாயிருப்பான்.
	<br /><br />
	5. இப்படிப்பட்ட ஒரு பிள்ளையை என் நாமத்தினிமித்தம் ஏற்றுக்கொள்ளுகிறவன் என்னை ஏற்றுக்கொள்ளுகிறான்.
	<br /><br />
	6. என்னிடத்தில் விசுவாசமாயிருக்கிற இந்தச் சிறியரில் ஒருவனுக்கு இடறல் உண்டாக்குகிறவன் எவனோ, அவனுடைய கழுத்தில் ஏந்திரக்கல்லைக் கட்டி, சமுத்திரத்தின் ஆழத்திலே அவனை அமிழ்த்துகிறது அவனுக்கு நலமாயிருக்கும்.
	<br /><br />
	7. இடறல்களினிமித்தம் உலகத்துக்கு ஐயோ, இடறல்கள் வருவது அவசியம், ஆனாலும் எந்த மனுஷனால் இடறல் வருகிறதோ, அவனுக்கு ஐயோ!
	<br /><br />
	8. உன் கையாவது உன் காலாவது உனக்கு இடறல் உண்டாக்கினால், அதைத் தறித்து எறிந்துபோடு; நீ இரண்டு கையுடையவனாய், அல்லது இரண்டு காலுடையவனாய் நித்திய அக்கினியிலே தள்ளப்படுவதைப்பார்க்கிலும், சப்பாணியாய், அல்லது ஊனனாய், நித்திய ஜீவனுக்குள் பிரவேசிப்பது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	9. உன் கண் உனக்கு இடறல் உண்டாக்கினால், அதைப் பிடுங்கி எறிந்துபோடு; இரண்டு கண்ணுடையவனாய் எரிநரகத்தில் தள்ளப்படுவதைப்பார்க்கிலும், ஒற்றைக்கண்ணனாய் ஜீவனுக்குள் பிரவேசிப்பது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	10. இந்தச் சிறியரில் ஒருவனையும் அற்பமாய் எண்ணாதபடிக்கு எச்சரிக்கையாயிருங்கள்; அவர்களுக்குரிய தேவதூதர்கள் பரலோகத்திலே என் பரமபிதாவின் சமுகத்தை எப்போதும் தரிசிக்கிறார்கள் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	11. மனுஷகுமாரன் கெட்டுப்போனதை ரட்சிக்க வந்தார்.
	<br /><br />
	12. உங்களுக்கு எப்படித் தோன்றுகிறது? ஒரு மனுஷனுக்கு நூறு ஆடுகளிருக்க, அவைகளில் ஒன்று சிதறிப்போனால், அவன் மற்றத் தொண்ணூற்றொன்பது ஆடுகளையும் மலைகளில் விட்டுப் போய்ச் சிதறிப்போனதைத் தேடாமலிருப்பானோ?
	<br /><br />
	13. அவன் அதைக் கண்டுபிடித்தால், சிதறிப்போகாத தொண்ணூற்றொன்பது ஆடுகளைக்குறித்துச் சந்தோஷப்படுகிறதைப்பார்க்கிலும், அதைக்குறித்து அதிகமாய்ச் சந்தோஷப்படுவான் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	14. இவ்விதமாக, இந்தச் சிறியரில் ஒருவனாகிலும் கெட்டுப்போவது பரலோகத்திலிருக்கிற உங்கள் பிதாவின் சித்தமல்ல.
	<br /><br />
	15. உன் சகோதரன் உனக்கு விரோதமாகக் குற்றஞ்செய்தால், அவனிடத்தில் போய், நீயும் அவனும் தனித்திருக்கையில், அவன் குற்றத்தை அவனுக்கு உணர்த்து; அவன் உனக்குச் செவிகொடுத்தால், உன் சகோதரனை ஆதாயப்படுத்திக்கொண்டாய்.
	<br /><br />
	16. அவன் செவிகொடாமற்போனால், இரண்டு மூன்று சாட்சிகளுடைய வாக்கினாலே சங்கதிகளெல்லாம் நிலைவரப்படும்படி, இரண்டொருவரை உன்னுடனே கூட்டிக்கொண்டு போ.
	<br /><br />
	17. அவர்களுக்கும் அவன் செவிகொடாமற்போனால், அதைச் சபைக்குத் தெரியப்படுத்து; சபைக்கும் செவிகொடாதிருப்பானானால், அவன் உனக்கு அஞ்ஞானியைப்போலவும் ஆயக்காரனைப்போலவும் இருப்பானாக.
	<br /><br />
	18. பூலோகத்திலே நீங்கள் எவைகளைக் கட்டுவீர்களோ அவைகள் பரலோகத்திலும் கட்டப்பட்டிருக்கும்; பூலோகத்திலே நீங்கள் எவைகளைக் கட்டவிழ்ப்பீர்களோ அவைகள் பரலோகத்திலும் கட்டவிழ்க்கப்பட்டிருக்கும் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	19. அல்லாமலும், உங்களில் இரண்டு பேர் தாங்கள் வேண்டிக்கொள்ளப்போகிற எந்தக் காரியத்தைக் குறித்தாகிலும் பூமியிலே ஒருமனப்பட்டிருந்தால், பரலோகத்தில் இருக்கிற என் பிதாவினால் அது அவர்களுக்கு உண்டாகும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	20. ஏனெனில், இரண்டுபேராவது மூன்றுபேராவது என் நாமத்தினாலே எங்கே கூடியிருக்கிறார்களோ, அங்கே அவர்கள் நடுவிலே இருக்கிறேன் என்றார்.
	<br /><br />
	21. அப்பொழுது, பேதுரு அவரிடத்தில் வந்து: ஆண்டவரே, என் சகோதரன் எனக்கு விரோதமாய்க் குற்றஞ்செய்து வந்தால், நான் எத்தனைதரம் மன்னிக்கவேண்டும்? ஏழுதரமட்டுமோ என்று கேட்டான்.
	<br /><br />
	22. அதற்கு இயேசு: ஏழுதரமாத்திரம் அல்ல, ஏழெழுபதுதரமட்டும் என்று உனக்குச் சொல்லுகிறேன்.
	<br /><br />
	23. எப்படியெனில், பரலோகராஜ்யம் தன் ஊழியக்காரரிடத்தில் கணக்குப் பார்க்கவேண்டுமென்றிருந்த ஒரு ராஜாவுக்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	24. அவன் கணக்குப்பார்க்கத் தொடங்கினபோது, பதினாயிரம் தாலந்து கடன் பட்டவன் ஒருவனை அவனுக்கு முன்பாகக் கொண்டுவந்தார்கள்.
	<br /><br />
	25. கடனைத்தீர்க்க அவனுக்கு நிர்வாகம் இல்லாதபடியால், அவனுடைய ஆண்டவன் அவனையும் அவன் பெண்ஜாதி பிள்ளைகளையும், அவனுக்கு உண்டான எல்லாவற்றையும் விற்று, கடனைத் தீர்க்கும்படிக் கட்டளையிட்டான்.
	<br /><br />
	26. அப்பொழுது, அந்த ஊழியக்காரன் தாழ விழுந்து, வணங்கி: ஆண்டவனே! என்னிடத்தில் பொறுமையாயிரும், எல்லாவற்றையும் உமக்குக் கொடுத்துத் தீர்க்கிறேன் என்றான்.
	<br /><br />
	27. அந்த ஊழியக்காரனுடைய ஆண்டவன் மனதிரங்கி, அவனை விடுதலைபண்ணி, கடனையும் அவனுக்கு மன்னித்துவிட்டான்.
	<br /><br />
	28. அப்படியிருக்க, அந்த ஊழியக்காரன் புறப்பட்டுப்போகையில், தன்னிடத்தில் நூறு வெள்ளிப்பணம் கடன்பட்டிருந்தவனாகிய தன் உடன்வேலைக்காரரில் ஒருவனைக் கண்டு, அவனைப் பிடித்து, தொண்டையை நெரித்து: நீ பட்ட கடனை எனக்குக் கொடுத்துத் தீர்க்கவேண்டும் என்றான்.
	<br /><br />
	29. அப்பொழுது அவனுடைய உடன்வேலைக்காரன் அவன் காலிலே விழுந்து: என்னிடத்தில் பொறுமையாயிரும், எல்லாவற்றையும் உமக்குக் கொடுத்துத் தீர்க்கிறேன் என்று, அவனை வேண்டிக்கொண்டான்.
	<br /><br />
	30. அவனோ சம்மதியாமல், போய், அவன் பட்ட கடனைக் கொடுத்துத் தீர்க்குமளவும் அவனைக் காவலில் போடுவித்தான்.
	<br /><br />
	31. நடந்ததை அவனுடைய உடன்வேலைக்காரர் கண்டு, மிகவும் துக்கப்பட்டு, ஆண்டவனிடத்தில் வந்து, நடந்ததையெல்லாம் அறிவித்தார்கள்.
	<br /><br />
	32. அப்பொழுது, அவனுடைய ஆண்டவன் அவனை அழைப்பித்து: பொல்லாத ஊழியக்காரனே, நீ என்னை வேண்டிக்கொண்டபடியினால் அந்தக் கடன் முழுவதையும் உனக்கு மன்னித்துவிட்டேன்.
	<br /><br />
	33. நான் உனக்கு இரங்கினதுபோல, நீயும் உன் உடன்வேலைக்காரனுக்கு இரங்கவேண்டாமோ என்று சொல்லி,
	<br /><br />
	34. அவனுடைய ஆண்டவன் கோபமடைந்து, அவன் பட்ட கடனையெல்லாம் தனக்குக் கொடுத்துத் தீர்க்குமளவும் உபாதிக்கிறவர்களிடத்தில் அவனை ஒப்புக்கொடுத்தான்.
	<br /><br />
	35. நீங்களும் அவனவன் தன் தன் சகோதரன் செய்த தப்பிதங்களை மனப்பூர்வமாய் மன்னியாமற்போனால், என் பரமபிதாவும் உங்களுக்கு இப்படியே செய்வார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew18