import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 22 </title>
	<meta
          name="description"
          content="Isaiah 22 | ஏசாயா 22 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தரிசனப் பள்ளத்தாக்கின் பாரம். உன்னில் உள்ளவர்கள் எல்லாரும் வீடுகளின்மேல் ஏறுவதற்கு உனக்கு இப்பொழுது வந்தது என்ன?
	<br /><br />
	2. சந்தடிகளால் நிறைந்து ஆரவாரம்பண்ணி, களிகூர்ந்திருந்த நகரமே, உன்னிடத்தில் கொலையுண்டவர்கள் பட்டயத்தால் கொலையுண்டதில்லை, யுத்தத்தில் செத்ததும் இல்லை.
	<br /><br />
	3. உன் அதிபதிகள் எல்லாரும் ஏகமாய் ஓடி அலைந்தும், வில்வீரரால் கட்டப்படுகிறார்கள்; உன்னில் அகப்பட்ட யாவரும் தூரத்துக்கு ஓடியும் ஏகமாய்க் கட்டப்படுகிறார்கள்.
	<br /><br />
	4. ஆகையால், என்னை நோக்கிப் பாராதேயுங்கள்; என் ஜனமாகிய குமாரத்தி பாழாய்ப்போனதினிமித்தம் மனங்கசந்து அழுவேன்; எனக்கு ஆறுதல் சொல்ல வராதேயுங்கள் என்கிறேன்.
	<br /><br />
	5. சேனைகளின் கர்த்தராகிய ஆண்டவராலே இது தரிசனப் பள்ளத்தாக்கிலே அமளியும், மிதியுண்குதலும், கலக்கமுமுள்ள நாளாயிருக்கிறது; இது அலங்கத்தைத் தகர்த்து, பர்வதத்துக்கு நேரே ஆர்ப்பரிக்கும் நாளாயிருக்கிறது.
	<br /><br />
	6. ஏலாமியன் அம்பறாத்தூணியை எடுத்து, இரதங்களோடும் காலாட்களோடும் குதிரைவீரரோடும் வருகிறான்; கீர் கேடகத்தை வெளிப்படுத்தும்.
	<br /><br />
	7. மகா வடிவான உன் பள்ளத்தாக்குகள் இரதங்களால் நிரப்பப்படும்; குதிரைவீரர் வாசல்கள்மட்டும் வந்து அணிவகுத்து நிற்பார்கள்.
	<br /><br />
	8. அவன் யூதாவின் மறைவை நீக்கிப்போடுவான்; அந்நாளிலே வனமாளிகையாகிய ஆயுதசாலையை நோக்குவாய்.
	<br /><br />
	9. நீங்கள் தாவீது நகரத்தின் இடிதல்கள் அநேகமாயிருப்பதைக் கண்டு, கீழ்க்குளத்துத் தண்ணீர்களைக் கட்டிவைத்து,
	<br /><br />
	10. எருசலேமின் வீடுகளை எண்ணி, அலங்கத்தை அரணிப்பாக்கும்படி வீடுகளை இடித்து,
	<br /><br />
	11. இரண்டு மதில்களுக்கு நடுவே பழைய குளத்துத் தண்ணீர்களுக்கு ஒரு அகழை உண்டுபண்ணுவீர்கள்; ஆனாலும் அதைச் செய்தவரை நீங்கள் நோக்காமலும், அதை ஏற்படுத்தித் தூரத்திலிருந்து வரப்பண்ணினவரைக் கவனியாமலும் போகிறீர்கள்.
	<br /><br />
	12. சேனைகளின் கர்த்தராகிய ஆண்டவர் அக்காலத்திலே அழவும், புலம்பவும், மொட்டையிடவும், இரட்டுடுத்தவும் கட்டளையிட்டார்.
	<br /><br />
	13. நீங்களோ, சந்தோஷித்துக் களித்து, ஆடுமாடுகளை அடித்து, இறைச்சியைச் சாப்பிட்டு, திராட்சரசத்தைக் குடித்து: புசிப்போம் குடிப்போம், நாளைக்குச் சாவோம் என்று சொல்வீர்கள்.
	<br /><br />
	14. மெய்யாகவே நீங்கள் சாகுமட்டும் இந்த அக்கிரமம் உங்களுக்கு நிவிர்த்தியாவதில்லை என்று சேனைகளின் கர்த்தராகிய ஆண்டவர் சொல்லுகிறாரென்பது என் செவி கேட்கும்படி சேனைகளின் கர்த்தரால் தெரிவிக்கப்பட்டது.
	<br /><br />
	15. சேனைகளின் கர்த்தராகிய ஆண்டவர் உரைத்ததாவது: நீ அரமனை விசாரிப்புக்காரனும் பொக்கிஷக்காரனுமாகிய செப்னா என்பவனிடத்தில் போய்ச் சொல்லவேண்டியது என்னவென்றால்,
	<br /><br />
	16. உயர்ந்த ஸ்தலத்திலே தன் கல்லறையை வெட்டி, கன்மலையிலே தனக்கு வாசஸ்தலத்தைத் தோண்டுகிறவனைப்போல, நீ உனக்கு இங்கே கல்லறையை வெட்டும்படிக்கு உனக்கு இங்கே என்ன இருக்கிறது? உனக்கு இங்கே யார் இருக்கிறார்கள்?
	<br /><br />
	17. இதோ, பெலவான் ஒருவனைத் துரத்துகிறவண்ணமாகக் கர்த்தர் உன்னைத் துரத்திவிட்டு, நிச்சயமாய் உன்னை மூடிப்போடுவார்.
	<br /><br />
	18. அவர் உன்னை உருண்டையைப்போல அகலமும் விசாலமுமான தேசத்திலே சுழற்றி எறிந்துவிடுவார்; அங்கே நீ சாவாய்; அங்கே உன் மகிமையின் இரதங்கள் உன் ஆண்டவனுடைய வீட்டுக்கு இகழ்ச்சியாக இருக்கும்.
	<br /><br />
	19. உன்னை உன் நிலையைவிட்டுத் துரத்திவிடுவேன்; உன் ஸ்தானத்திலிருந்து நீ பிடுங்கிப்போடப்படுவாய்.
	<br /><br />
	20. அந்நாளிலே இல்க்கியாவின் குமாரனாகிய எலியாக்கீம் என்னும் என் ஊழியக்காரனை நான் அழைத்து:
	<br /><br />
	21. உன் வஸ்திரத்தை அவனுக்குத் தரித்து, உன் கச்சையால் அவனை இடைக்கட்டி, உன் அதிகாரத்தை அவன் கையிலே கொடுப்பேன்; அவன் எருசலேமின் குடிகளுக்கும், யூதாவின் வம்சத்துக்கும் தகப்பனாயிருப்பான்.
	<br /><br />
	22. தாவீதுடைய வீட்டின் திறவுகோலை அவன் தோளின்மேல் வைப்பேன்; ஒருவரும் பூட்டக்கூடாதபடிக்கு அவன் திறப்பான், ஒருவரும் திறக்கக்கூடாதபடிக்கு அவன் பூட்டுவான்.
	<br /><br />
	23. அவனை உறுதியான இடத்திலே ஆணியாகக் கடாவுவேன்; அவன் தன் தகப்பன் வீட்டுக்கு மகிமையான சிங்காசனமாக இருப்பான்.
	<br /><br />
	24. அதின்மேல் அவன் தகப்பன் வம்சத்தாராகிய புத்திரர் பௌத்திரருடைய மகிமை அனைத்தையும், சிறிதும் பெரிதுமான சகல பானபாத்திரங்களையும் தூக்கிவைப்பார்கள்.
	<br /><br />
	25. உறுதியான இடத்தில் கடாவப்பட்டிருந்த ஆணி அந்நாளிலே பிடுங்கப்பட்டு, முறிந்துவிழும்; அப்பொழுது அதின்மேல் தொங்கின பாரம் அறுந்துவிழும் என்று சேனைகளின் கர்த்தர் உரைக்கிறார்; கர்த்தரே இதை உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah22