const bibleVerses = [
    {
      reference: "ஏசாயா 54:12",
      text: "உன் பலகணிகளைப் பளிங்கும், உன் வாசல்களை மாணிக்கக் கற்களும், உன் மதில்களையெல்லாம் உச்சிதமான கற்களுமாக்குவேன்."
    },
    {
      reference: "ஏசாயா 61:7",
      text: "உங்கள் வெட்கத்துக்குப் பதிலாக இரண்டத்தனையாய்ப் பலன் வரும்."
    },
    {
      reference: "ஏசாயா 60:20",
      text: "உன் சூரியன் இனி அஸ்தமிப்பதுமில்லை, உன் சந்திரன் மறைவதுமில்லை; கர்த்தரே உனக்கு நித்திய வெளிச்சமாயிருப்பார்; உன் துக்கநாட்கள் முடிந்துபோம்."
    },
    {
      reference: "எரேமியா 1:19",
      text: "உன்னை இரட்சிக்கும்படிக்கு நான் உன்னுடனே இருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்."
    },
    {
      reference: "செப்பனியா 3:15",
      text: "கர்த்தர் உன் ஆக்கினைகளை அகற்றி, உன் சத்துருக்களை விலக்கினார்; இஸ்ரவேலின் ராஜாவாகிய கர்த்தர் உன் நடுவிலே இருக்கிறார்; இனித் தீங்கைக் காணாதிருப்பாய்."
    },
    {
      reference: "மீகா 7:15",
      text: "எகிப்து தேசத்திலிருந்து புறப்பட்டநாளில் நடந்ததுபோலவே உன்னை அதிசயங்களைக் காணப்பண்ணுவேன்."
    },
    {
      reference: "நாகூம் 1:13",
      text: "உன்மேல் இருக்கிற நுகத்தை முறித்து உன் கட்டுகளை அறுப்பேன்."
    },
    {
      reference: "சகரியா 2:10",
      text: "கெம்பீரித்துப்பாடு; இதோ, நான் வந்து உன் நடுவில் வாசம்பண்ணுவேன்."
    },
    {
        reference: "ஆதியாகமம் 35:11",
        text: "நான் உன்னை சுற்றிலும் அக்கினி மதிலாயிருந்து, உன் நடுவில் மகிமையாக இருப்பேன்."
      },
      {
        reference: "சகரியா 9:12",
        text: "இரட்டிப்பான நன்மையைத் தருவேன், இன்றைக்கே தருவேன்."
      },
      {
        reference: "ஆதியாகமம் 9:7",
        text: "நீங்கள் பலுகிப் பெருகி, பூமியிலே திரளாய் வர்த்தித்து விருத்தியாகுங்கள்."
      },
      {
        reference: "ஆதியாகமம் 13:16",
        text: "உன் சந்ததியைப் பூமியின் தூளைப்போலப் பெருகப்பண்ணுவேன்."
      },
      {
        reference: "ஆதியாகமம் 15:1",
        text: "நீ பயப்படாதே; நான் உனக்குக் கேடகமும், உனக்கு மகா பெரிய பலனுமாயிருக்கிறேன்."
      },
      {
        reference: "ஆதியாகமம் 17:2",
        text: "நான் உன்னை மிகவும் அதிகமாய்ப் பலுகப்பண்ணி, உன்னிலே ஜாதிகளை உண்டாக்குவேன்; உன்னிடத்திலிருந்து ராஜாக்கள் தோன்றுவார்கள்."
      },
      {
        reference: "ஆதியாகமம் 17:6",
        text: "நான் உன்னோடேகூட இருந்து, உன்னை ஆசீர்வதிப்பேன்; நான் உனக்குக் உன் சந்ததிக்கும் இந்தத் தேசங்கள் யாவையும் தந்து, உன் தகப்பனாகிய ஆபிரகாமுக்கு நான் இட்ட ஆணையை நிறைவேற்றுவேன்."
      },
      {
        reference: "ஆதியாகமம் 26:3",
        text: "நான் உன்னை சுற்றிலும் அக்கினி மதிலாயிருந்து, உன் நடுவில் மகிமையாக இருப்பேன்."
      },
      {
        reference: "யாத்திராகமம் 14:14",
        text: "கர்த்தர் உங்களுக்காக யுத்தம் பண்ணுவார்; நீங்கள் சும்மாயிருப்பீர்கள்."
      },
      {
        reference: "யோசுவா 3:5",
        text: "கர்த்தர் உங்கள் நடுவிலே அற்புதங்களைச் செய்வார்."
      },
      {
        reference: "யோசுவா 3:7",
        text: "நான் மோசேயோடே இருந்ததுபோல, உன்னோடும் இருக்கிறேன்."
      },
      {
        reference: "2 நாளாகமம் 1:12",
        text: "உனக்குமுன் இருந்த ராஜாக்களுக்காகிலும் உனக்குப்பின் இருக்கும் ராஜாக்களுக்காகிலும் இல்லாத ஐசுவரியத்தையும் சம்பத்தையும் கனத்தையும் உனக்குத் தருவேன்."
      },
      {
        reference: "உபாகமம் 7:14",
        text: "சகல ஜனங்களைப் பார்க்கிலும் நீ ஆசீர்வதிக்கப்பட்டிருப்பாய்; உங்களுக்குள்ளும் உங்கள் மிருகஜீவன்களுக்குள்ளும் ஆணிலாகிலும் பெண்ணிலாகிலும் மலடிருப்பதில்லை."
      },
      {
        reference: "உபாகமம் 28:3",
        text: "நீ பட்டணத்திலும் ஆசீர்வதிக்கப்பட்டிருப்பாய்; வெளியிலும் ஆசீர்வதிக்கப்பட்டிருப்பாய்."
      },
      {
        reference: "உபாகமம் 28:8",
        text: "கர்த்தர் உன் களஞ்சியங்களிலும், நீ கையிடும் எல்லா வேலையிலும் உனக்கு ஆசீர்வாதம் கட்டளையிடுவார்; உன் தேவனாகிய கர்த்தர் உனக்குக் கொடுக்கும் தேசத்திலே உன்னை ஆசீர்வதிப்பார்."
      },
      {
        reference: "ஏசாயா 43:19",
        text: "இதோ, நான் புதிய காரியத்தைச் செய்கிறேன்; இப்பொழுதே அது தோன்றும்; நீங்கள் அதை அறியீர்களா? நான் வனாந்தரத்திலே வழியையும் அவாந்தரவெளியிலே ஆறுகளையும் உண்டாக்குவேன்."
      },
      {
        reference: "ஏசாயா 45:2",
        text: "நான் எகிப்தியருக்கு வரப்பண்ணின வியாதிகளில் ஒன்றையும் உனக்கு வரப்பண்ணேன்; நானே உன் பரிகாரியாகிய கர்த்தர்."
      },
      {
        reference: "யாத்திராகமம் 15:26",
        text: "உன்னோடேகூட இருந்து, நான் செய்யும் காரியம் பயங்கரமாயிருக்கும்."
      },
      {
        reference: "யாத்திராகமம் 34:10",
        text: "நீ செல்லும் இடமெங்குமுள்ள ஜனங்கள் எல்லாரையம் கலங்கடித்து, உன் சத்துருக்கள் எல்லாரையும் முதுகு காட்டப்பண்ணுவேன்."
      },
      {
        reference: "யாத்திராகமம் 33:12",
        text: "உன்னைப் பேர்சொல்லி அழைத்து அறிந்திருக்கிறேன் என்றும், என் கண்களில் உனக்குக் கிருபை கிடைத்தது."
      },
      {
        reference: "ஆதியாகமம் 28:15",
        text: "நான் உனக்கு முன்னே போய், கோணலானவைகளைச் செவ்வையாக்குவேன்."
      },
      {
        reference: "ஏசாயா 30:17",
        text: "நான் உனக்கு ஆரோக்கியம் வரப்பண்ணி, உன் காயங்களை ஆற்றுவேன்."
      },
      {
        reference: "2 Corinthians 12:9",
        text: "என் கிருபை உனக்குப்போதும்; பலவீனத்திலே என் பலம் பூரணமாய் விளங்கும்."
      },
      {
        reference: "Matthew 4:12",
        text: "நான் உன் வாயோடே இருந்து, நீ பேசவேண்டியதை உனக்குப் போதிப்பேன்."
      },
      {
        reference: "Joshua 1:5",
        text: "நீ உயிரோடிருக்கும் நாளெல்லாம் ஒருவனும் உனக்கு முன்பாக எதிர்த்து நிற்பதில்லை; நான் மோசேயோடே இருந்ததுபோல, உன்னோடும் இருப்பேன்; நான் உன்னைவிட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை."
      },
      {
        reference: "1 Chronicles 17:8",
        text: "நீ போன இடமெல்லாம் உன்னோடே இருந்து, உன் சத்துருக்களையெல்லாம் உனக்கு முன்பாக நிர்மூலமாக்கி, பூமியிலிருக்கிற பெரியோர்களின் நாமத்திற்கு ஒத்த நாமத்தை உனக்கு உண்டாக்கினேன்."
      },
      {
        reference: "Nehemiah 17:8",
        text: "உச்சிதமான கோதுமையினால் அவர்களைப் போஷிப்பேன்; கன்மலையின் தேனினால் உன்னைத் திருப்தியாக்குவேன்."
      },
      {
        reference: "Psalm 81:16",
        text: "நான் உன்க்குப் போதித்து, நீ நடக்கவேண்டிய வழியை உனக்குக் காட்டுவேன்; உன்மேல் என் கண்ணை வைத்து, உனக்கு ஆலோசனை சொல்லுவேன்."
      },
      {
        reference: "Psalm 32:8",
        text: "நான் உன் வலதுகையைப் பிடித்து: பயப்படாதே, நான் உனக்குத் துணைநிற்கிறேன் என்று சொல்லுகிறேன்."
      },
      {
        reference: "Isaiah 41:13",
        text: "எவர்கள் உனக்கு விரோதமாய்க் கூடுகிறார்களோ, அவர்கள் உன் பட்சத்தில் வருவார்கள்."
      },
      {
        reference: "Isaiah 54:15",
        text: "பயப்படாதே, நான் உன்னோடே இருக்கிறேன்; நான் உன் சந்ததியைக் கிழக்கிலிருந்து வரப்பண்ணி, உன்னை மேற்கிலும் இருந்து கூட்டிச்சேர்ப்பேன்."
      },
      {
        reference: "Isaiah 43:5",
        text: "நான் உன் வலதுகையைப் பிடித்து: பயப்படாதே, நான் உனக்குத் துணைநிற்கிறேன் என்று சொல்லுகிறேன்."
      },
      {
        reference: "Jeremiah 1:18",
        text: "நான் உன் வலதுகையைப் பிடித்து: பயப்படாதே, நான் உன்னைவிட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை."
      },
      {
        reference: "Jeremiah 33:3",
        text: "நீ அறியாததும் உனக்கு எட்டாததுமான பெரிய காரியங்களை உனக்கு அறிவிப்பேன்."
      },
      {
        reference: "Nahum 1:15",
        text: "துஷ்டன் இனி உன் வழியாய்க் கடந்துவருவதில்லை, அவன் முழுதும் சங்கரிக்கப்பட்டான்."
      },
      {
        reference: "Revelation 3:8",
        text: "இதோ, திறந்தவாசலை உனக்கு முன்பாக வைத்திருக்கிறேன், அதை ஒருவனும் பூட்டமாட்டான்."
      },
      {
        reference: "Hebrews 13:5",
        text: "நான் உன்னைவிட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை."
      },
      {
        reference: "Hebrews 6:14",
        text: "நிச்சயமாக நான் உன்னை ஆசீர்வதிக்கவே ஆசீர்வதித்து, உன்னைப்பெருகவே பெருகப்பண்ணுவேன்."
      },
      {
        reference: "Proverbs 3:10",
        text: "உன் களஞ்சியங்கள் பூரணமாய் நிரம்பும்; உன் ஆலைகளில் திராட்சரசம் புரண்டோடும்."
      },
      {
        reference: "Proverbs 23:18",
        text: "நிச்சயமாகவே முடிவு உண்டு; உன் நம்பிக்கை வீண்போகாது."
      },
      {
        reference: "Acts 18:10",
        text: "நான் உன்னைவிட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை."
      },
      {
        reference: "Romans 8:31",
        text: "நிச்சயமாகவே நாம் தோன்றுவோம், அது உனக்குத் எங்கள் வாயாக்கின்றது."
      },
      {
        reference: "Isaiah 44:3",
        text: "உன் சந்ததியின்மேல் என் ஆவியையும், உன் சந்தானத்தின்மேல் என் ஆசீர்வாதத்தையும் ஊற்றுவேன்."
      },
      {
        reference: "Isaiah 45:4",
        text: "வெண்கலக் கதவுகளை உடைத்து, இருப்புத் தாழ்ப்பாள்களை முறித்து, அந்தகாரத்தில் இருக்கிற பொக்கிஷங்களையும், ஒளிப்பிடத்தில் இருக்கிற புதையல்களையும் உனக்குக் கொடுப்பேன்."
      },
      {
        reference: "Jeremiah 15:21",
        text: "நான் உன்னை நித்திய மாட்சிமையாகவும், தலைமுறை தலைமுறையாயிருக்கும் மகிழ்ச்சியாகவும் வைப்பேன்."
      },
      {
        reference: "Isaiah 60:15",
        text: "நான் உன்னைப் பொல்லாதவர்களின் கைக்குத் தப்புவித்து, உன்னைப் பலவந்தரின் கைக்கு நீங்கலாக்கி விடுவிப்பேன்."
      },
      {
        reference: "Jeremiah 1:18",
        text: "நான் உன் வலதுகையைப் பிடித்து: பயப்படாதே, நான் உன்னைவிட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை."
      },
      {
        reference: "Jeremiah 33:3",
        text: "நீ அறியாததும் உனக்கு எட்டாததுமான பெரிய காரியங்களை உனக்கு அறிவிப்பேன்."
      },
      {
        reference: "Nahum 1:15",
        text: "துஷ்டன் இனி உன் வழியாய்க் கடந்துவருவதில்லை, அவன் முழுதும் சங்கரிக்கப்பட்டான்."
      },
      {
        reference: "Revelation 3:8",
        text: "இதோ, திறந்தவாசலை உனக்கு முன்பாக வைத்திருக்கிறேன், அதை ஒருவனும் பூட்டமாட்டான்."
      },
      {
        reference: "Romans 8:31",
        text: "நிச்சயமாக நாம் தோன்றுவோம், அது உனக்குத் எங்கள் வாயாக்கின்றது."
      },
      {
        reference: "Isaiah 44:3",
        text: "உன் சந்ததியின்மேல் என் ஆவியையும், உன் சந்தானத்தின்மேல் என் ஆசீர்வாதத்தையும் ஊற்றுவேன்."
      },
      {
        reference: "Isaiah 45:4",
        text: "வெண்கலக் கதவுகளை உடைத்து, இருப்புத் தாழ்ப்பாள்களை முறித்து, அந்தகாரத்தில் இருக்கிற பொக்கிஷங்களையும், ஒளிப்பிடத்தில் இருக்கிற புதையல்களையும் உனக்குக் கொடுப்பேன்."
      },
      {
        reference: "Jeremiah 15:21",
        text: "நான் உன்னை நித்திய மாட்சிமையாகவும், தலைமுறை தலைமுறையாயிருக்கும் மகிழ்ச்சியாகவும் வைப்பேன்."
      },
      {
        reference: "Isaiah 60:15",
        text: "நான் உன்னைப் பொல்லாதவர்களின் கைக்குத் தப்புவித்து, உன்னைப் பலவந்தரின் கைக்கு நீங்கலாக்கி விடுவிப்பேன்."
      },{
        reference: "நீதிமொழிகள் 23:18",
        text: "நிச்சயமாகவே முடிவு உண்டு; உன் நம்பிக்கை வீண்போகாது."
      },
      {
        reference: "எரேமியா 30:17",
        text: "நான் உனக்கு ஆரோக்கியம் வரப்பண்ணி, உன் காயங்களை ஆற்றுவேன்."
      },
      {
        reference: "ஆதியாகமம் 15:1",
        text: "நீ பயப்படாதே; நான் உனக்குக் கேடகமும், உனக்கு மகா பெரிய பலனுமாயிருக்கிறேன்."
      },
      {
        reference: "ஏசாயா 60:20",
        text: "கர்த்தரே உனக்கு நித்திய வெளிச்சமாயிருப்பார். உன் துக்க நாட்கள் முடிந்துபோம்."
      },
      {
        reference: "யாத்திராகமம் 14:14",
        text: "கர்த்தர் உங்களுக்காக யுத்தம் பண்ணுவார்; நீங்கள் சும்மாயிருப்பீர்கள்."
      },
      {
        reference: "யோசுவா 1:5",
        text: "நீ உயிரோடிருக்கும் நாளெல்லாம் ஒருவனும் உனக்கு முன்பாக எதிர்த்து நிற்பதில்லை. நான் மோசேயோடே இருந்ததுபோல, உன்னோடும் இருப்பேன். உன்னைவிட்டு விலகுவதுமில்லை, உன்னை கைவிடுவதுமில்லை."
      },
      {
        reference: "சங்கீதம் 81:16",
        text: "உச்சிதமான கோதுமையினால் அவர்களைப் போஷிப்பேன்; கன்மலையின் தேனினால் உன்னைத் திருப்தியாக்குவேன்."
      },
      {
        reference: "சங்கீதம் 128:5",
        text: "கர்த்தர் சீயோனிலிருந்து உன்னை ஆசிர்வதிப்பார். நீ ஜீவனுள்ள நாளெல்லாம் எருசலேமின் வாழ்வை காண்பாய்."
      },
      {
        reference: "சங்கீதம் 121:7",
        text: "கர்த்தர் உன் சூரியன் இனி அஸ்தமிப்பதுமில்லை, உன் சந்திரன் மறைவதுமில்லை; கர்த்தரே உனக்கு நித்திய வெளிச்சமாயிருப்பார்; உன் துக்கநாட்கள் முடிந்துபோம்."
      },
      {
        reference: "எபிரெயர் 6:14",
        text: "நிச்சயமாக நான் உன்னை ஆசீர்வதிக்கவே ஆசீர்வதித்து, உன்னைப்பெருகவே பெருகப்பண்ணுவேன்."
      },
      {
        reference: "ஏசாயா 60:1",
        text: "எழும்பி பிரகாசி உன் ஒளி வந்தது, கர்த்தருடைய மகிமை உன்மேல் உதித்தது."
      },
      {
        reference: "ஆதியாகமம் 12:2",
        text: "நான் உன்னை பெரிய ஜாதியாக்கி, உன்னை ஆசிர்வதித்து, உன் பேரைப் பெருமைபடுத்துவேன். நீ ஆசிவாதமாய் இருப்பாய்."
      },
      {
        reference: "உபாகமம் 28:14",
        text: "கர்த்தர் உன்னை வாலாக்காமல் தலையாக்குவார், நீ கீழாகாமல் மேலாவாய்."
      },{
        reference: "ஏசாயா 41:10",
        text: "நீ பயப்படாதே, நான் உன்னுடனே இருக்கிறேன்; திகையாதே, நான் உன் தேவன்; நான் உன்னைப் பலப்படுத்தி உனக்குச் சகாயம்செய்வேன்; என் நீதியின் வலதுகரத்தினால் உன்னைத் தாங்குவேன்."
      },
      {
        reference: "ஏசாயா 26:3",
        text: "உம்மை உறுதியாகப் பற்றிக்கொண்ட மனதையுடையவன் உம்மையே நம்பியிருக்கிறதினால், நீர் அவனைப் பூரண சமாதானத்துடன் காத்துக்கொள்வீர்."
      },
      {
        reference: "உபாகமம் 31:8",
        text: "கர்த்தர்தாமே உனக்கு முன்பாகப் போகிறவர், அவர் உன்னோடே இருப்பார்; அவர் உன்னை விட்டு விலகுவதுமில்லை, உன்னைக் கைவிடுவதுமில்லை; நீ பயப்படவும் கலங்கவும் வேண்டாம் என்றான்."
      },
      {
        reference: "யோவான் 16:33",
        text: "என்னிடத்தில் உங்களுக்குச் சமாதானம் உண்டாயிருக்கும் பொருட்டு இவைகளை உங்களுக்குச் சொன்னேன். உலகத்தில் உங்களுக்கு உபத்திரவம் உண்டு, ஆனாலும் திடன்கொள்ளுங்கள்; நான் உலகத்தை ஜெயித்தேன் என்றார்."
      },
      {
        reference: "சங்கீதம் 32:8",
        text: "நான் உனக்குப் போதித்து, நீ நடக்கவேண்டிய வழியை உனக்குக் காட்டுவேன்; உன்மேல் என் கண்ணை வைத்து, உனக்கு ஆலோசனை சொல்லுவேன்."
      },
      {
        reference: "2 கொரிந்தியர் 12:9",
        text: "அதற்கு அவர்: என் கிருபை உனக்குப்போதும்; பலவீனத்திலே என் பலம் பூரணமாய் விளங்கும் என்றார். ஆகையால், கிறிஸ்துவின் வல்லமை என்மேல் தங்கும்படி, என் பலவீனங்களைக்குறித்து நான் மிகவும் சந்தோஷமாய் மேன்மைபாராட்டுவேன். 10 அந்தப்படி நான் பலவீனனாயிருக்கும்போதே பலமுள்ளவனாயிருக்கிறேன்; ஆகையால் கிறிஸ்துவினிமித்தம் எனக்குவரும் பலவீனங்களிலும், நிந்தைகளிலும், நெருக்கங்களிலும், துன்பங்களிலும், இடுக்கண்களிலும் நான் பிரியப்படுகிறேன்."
      },
      {
        reference: "ஏசாயா 40:31",
        text: "கர்த்தருக்குக் காத்திருக்கிறவர்களோ புதுப்பெலனடைத்து, கழுகுகளைப்போலச் செட்டைகளை அடித்து எழும்புவார்கள்; அவர்கள் ஓடினாலும் இளைப்படையார்கள், நடந்தாலும் சோர்ந்துபோகார்கள்."
      },
      {
        reference: "எரேமியா 29:11",
        text: "நீங்கள் எதிர்பார்த்திருக்கும் முடிவை உங்களுக்கு கொடுக்கும்படிக்கு நான் உங்கள்பேரில் நினைத்திருக்கிற நினைவுகளை அறிவேன் என்று கர்த்தர் சொல்லுகிறார்; அவைகள் தீமைக்கல்ல, சமாதானத்துக்கேதுவான நினைவுகளே."
      },
      {
        reference: "1 பேதுரு 2:24",
        text: "நாம் பாவங்களுக்குச் செத்து, நீதிக்குப் பிழைத்திருக்கும்படிக்கு, அவர்தாமே தமது சரீரத்திலே நம்முடைய பாவங்களைச் சிலுவையின்மேல் சுமந்தார்; அவருடைய தழும்புகளால் குணமானீர்கள்."
      },
      {
        reference: "ஏசாயா 41:10",
        text: "அவர் சகலத்தையும் அதினதின் காலத்திலே நேர்த்தியாகச் செய்திருக்கிறார்: பிரசங்கி 3:11"
      },
      {
        reference: "யோவான் 14:15",
        text: "நீங்கள் என்னிடத்தில் அன்பாயிருந்தால் என் கற்பனைகளைக் கைக்கொள்ளுங்கள்."
      },
      {
        reference: "மத்தேயு 7:12",
        text: "ஆதலால், மனுஷர் உங்களுக்கு எவைகளைச்செய்ய விரும்புகிறீர்களோ, அவைகளை நீங்களும் அவர்களுக்குச் செய்யுங்கள்; இதுவே நியாயப்பிரமாணமும் தீர்க்கதரிசனங்களுமாம்."
      },
      {
        reference: "சங்கீதம் 30:5",
        text: "அவருடைய கோபம் ஒரு நிமிஷம், அவருடைய தயவோ நீடியவாழ்வு; சாயங்காலத்தில் அழுகை தங்கும், விடியற்காலத்திலே களிப்புண்டாகும்."
      },
      {
        reference: "எபிரெயர் 12:2",
        text: "அவர் தமக்குமுன் வைத்திருந்த சந்தோஷத்தின்பொருட்டு, அவமானத்தை எண்ணாமல், சிலுவையைச் சகித்து, தேவனுடைய சிங்காசனத்தின் வலதுபாரிசத்தில் வீற்றிருக்கிறார்."
      },
      {
        reference: "சங்கீதம் 32:8",
        text: "நான் உனக்குப் போதித்து, நீ நடக்கவேண்டிய வழியை உனக்குக் காட்டுவேன்."
      },
      {
        reference: "சங்கீதம் 91:5-6",
        text: "இரவில் உண்டாகும் பயங்கரத்துக்கும், பகலில் பறக்கும் அம்புக்கும், இருளில் நடமாடும் கொள்ளைநோய்க்கும், மத்தியானத்தில் பாழாக்கும் சங்காரத்துக்கும் பயப்படாதிருப்பாய்."
      },
      {
        reference: "சங்கீதம் 116:1,2",
        text: "கர்த்தர் என் சத்தத்தையும் என் விண்ணப்பத்தையும் கேட்டதினால், அவரில் அன்புகூருகிறேன். அவர் தமது செவியை எனக்குச் சாய்த்தபடியால், நான் உயிரோடிருக்குமளவும் அவரைத் தொழுதுகொள்ளுவேன்."
      },
      {
        reference: "சங்கீதம் 37:11",
        text: "சாந்தகுணமுள்ளவர்கள் பூமியைச் சுதந்தரித்து மிகுந்த சமாதானத்தினால் மனமகிழ்ச்சியாயிருப்பார்கள்."
      },
      {
        reference: "நீதிமொழிகள் 6:22",
        text: "நீ நடக்கும்போது அது உனக்கு வழிகாட்டும்; நீ படுக்கும்போது அது உன்னைக் காப்பாற்றும்; நீ விழிக்கும்போது அது உன்னோடே சம்பாஷிக்கும்."
      },
  ];
  
export default bibleVerses;