import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah36 = () => {
  const verseNumber = 36;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 36 </title>
	<meta
          name="description"
          content="Isaiah 36 | ஏசாயா 36 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எசேக்கியா ராஜா அரசாண்ட பதினாலாம் வருஷத்திலே அசீரியா ராஜாவாகிய சனகெரிப் யூதாவிலிருக்கிற அரணான சகல பட்டணங்களுக்கும் விரோதமாய் வந்து, அவைகளைப் பிடித்துக்கொண்டான்.
	<br /><br />
	2. அப்பொழுது அசீரியா ராஜா லாகீசிலிருந்து ரப்சாக்கேயைப் பெரிய சேனையோடே எருசலேமுக்கு எசேக்கியா ராஜாவினிடத்தில் அனுப்பினான்; அவன் வந்து வண்ணார் துறையின் வழியிலுள்ள மேல்குளத்துச் சாலகத்தண்டையிலே நின்றான்.
	<br /><br />
	3. அப்பொழுது இல்க்கியாவின் குமாரனாகிய எலியாக்கீம் என்னும் அரமனை விசாரிப்புக்காரனும், செப்னா என்னும் சம்பிரதியும், ஆசாப்பின் குமாரனாகிய யோவாக் என்னும் கணக்கனும் அவனிடத்திற்குப் புறப்பட்டுப்போனார்கள்.
	<br /><br />
	4. ரப்சாக்கே அவர்களை நோக்கி: அசீரியா ராஜாவாகிய மகாராஜாவானவர் உரைக்கிறதும், நீங்கள் எசேக்கியாவுக்குச் சொல்லவேண்டியதும் என்னவென்றால்: நீ நம்பியிருக்கிற இந்த நம்பிக்கை என்ன?
	<br /><br />
	5. யுத்தத்துக்கு மந்திராலோசனையும் வல்லமையும் உண்டென்று சொல்லுகிறாயே, அது வாய்ப்பேச்சேயன்றி வேறல்ல; நீ என்னை விரோதிக்கும்படி யார்மேல் நம்பிக்கை வைத்திருக்கிறாய்?
	<br /><br />
	6. இதோ, நெரிந்த நாணல்கோலாகிய அந்த எகிப்தை நம்புகிறாய்; அதின்மேல் ஒருவன் சாய்ந்தால், அது அவன் உள்ளங்கையில் பட்டுருவிப்போம்; எகிப்தின் ராஜாவாகிய பார்வோன் தன்னை நம்புகிற யாவருக்கும் அப்படியே இருப்பான்.
	<br /><br />
	7. நீ என்னிடத்தில்: நாங்கள் எங்கள் தேவனாகிய கர்த்தரை நம்புகிறோம் என்று சொல்வாயாகில், அவருடைய மேடைகளையும் அவருடைய பலிபீடங்களையும் அல்லவோ எசேக்கியா அகற்றி, யூதாவையும், எருசலேமையும் நோக்கி: இந்தப் பலிபீடத்தின்முன் பணியுங்கள் என்றானே.
	<br /><br />
	8. நான் உனக்கு இரண்டாயிரம் குதிரைகளைக் கொடுப்பேன்; நீ அவைகள்மேல் ஏறத்தக்கவர்களைச் சம்பாதிக்கக்கூடுமானால் அசீரியா ராஜாவாகிய என் ஆண்டவனோடே சபதங்கூறு.
	<br /><br />
	9. கூறாதேபோனால், நீ என் ஆண்டவனுடைய ஊழியக்காரரில் ஒரே ஒரு சிறிய தலைவனுடைய முகத்தை எப்படித் திருப்புவாய்? இரதங்களோடு குதிரைவீரரும் வருவார்கள் என்று எகிப்தையா நம்புகிறாய்?
	<br /><br />
	10. இப்பொழுதும் கர்த்தருடைய கட்டளையில்லாமல் இந்தத் தேசத்தை அழிக்க வந்தேனோ? இந்தத் தேசத்துக்கு விரோதமாய்ப் போய் அதை அழித்துப்போடு என்று கர்த்தர் என்னோடே சொன்னாரே என்று சொன்னான்.
	<br /><br />
	11. அப்பொழுது எலியாக்கீமும் செப்னாவும் யோவாகும், ரப்சாக்கேயைப்பார்த்து: உம்முடைய அடியாரோடே சீரியபாஷையிலே பேசும், அது எங்களுக்குத் தெரியும்; அலங்கத்திலிருக்கிற ஜனத்தின் செவிகள் கேட்க எங்களோடே யூதபாஷையிலே பேசவேண்டாம் என்றார்கள்.
	<br /><br />
	12. அதற்கு ரப்சாக்கே: உங்களோடுங்கூடத் தங்கள் மலத்தைத் தின்னவும், தங்கள் நீரைக் குடிக்கவும், அலங்கத்திலே தங்கியிருக்கிற புருஷரண்டைக்கே அல்லாமல், உன் ஆண்டவனண்டைக்கும், உன்னண்டைக்குமா, என் ஆண்டவன் இந்த வார்த்தைகளைப் பேச என்னை அனுப்பினார் என்று சொல்லி;
	<br /><br />
	13. ரப்சாக்கே நின்றுகொண்டு, யூதபாஷையிலே உரத்தசத்தமாய்: அசீரியா ராஜாவாகிய மகாராஜாவுடைய வார்த்தைகளைக் கேளுங்கள்.
	<br /><br />
	14. எசேக்கியா உங்களை வஞ்சியாதபடி பாருங்கள்; அவன் உங்களைத் தப்புவிக்கமாட்டான்.
	<br /><br />
	15. கர்த்தர் நம்மை நிச்சயமாய்த் தப்புவிப்பார், இந்த நகரம் அசீரியா ராஜாவின் கையில் ஒப்புக்கொடுக்கப்படுவதில்லை என்று சொல்லி, எசேக்கியா உங்களைக் கர்த்தரை நம்பப்பண்ணுவான்; அதற்கு இடங்கொடாதிருங்கள் என்று ராஜா சொல்லுகிறார்.
	<br /><br />
	16. எசேக்கியாவின் சொல்லைக் கேளாதிருங்கள். அசீரியா ராஜா சொல்லுகிறதாவது: நீங்கள் என்னோடே ராஜியாகி, காணிக்கையோடே என்னிடத்தில் வாருங்கள்.
	<br /><br />
	17. நான் வந்து, உங்களைத் தானியமும் திராட்சத்தோட்டமுமுள்ள தேசமும், அப்பமும் திராட்சரசமுமுள்ள தேசமுமாகிய உங்கள் தேசத்துக்கு ஒப்பான தேசத்துக்கு அழைத்துக்கொண்டுபோகுமளவும், அவனவன் தன் தன் திராட்சச்செடியின் கனியையும், தன் தன் அத்திமரத்தின் கனியையும் புசித்து, அவனவன் தன் தன் கிணற்றின் தண்ணீரைக் குடியுங்கள்.
	<br /><br />
	18. கர்த்தர் நம்மைத் தப்புவிப்பார் என்று உங்களைப் போதனைசெய்ய எசேக்கியாவுக்குச் செவிகொடாதிருங்கள்; ஜாதிகளுடைய தேவர்களில் யாராவது தங்கள் தேசத்தை அசீரியா ராஜாவின் கைக்குத் தப்புவித்ததுண்டோ?
	<br /><br />
	19. ஆமாத் அர்பாத் பட்டணங்களின் தேவர்கள் எங்கே? செப்பர்வாயீமின் தேவர்கள் எங்கே? அவர்கள் சமாரியாவை என் கைக்குத் தப்புவித்ததுண்டோ?
	<br /><br />
	20. கர்த்தர் எருசலேமை என் கைக்குத் தப்புவிப்பார் என்பதற்கு, அந்தத்தேசங்களுடைய எல்லாத் தேவர்களுக்குள்ளும் தங்கள் தேசத்தை என் கைக்குத் தப்புவித்தவர் யார் என்று ராஜா சொல்லுகிறார் என்றான்.
	<br /><br />
	21. அவர்களோ அவனுக்கு ஒரு வார்த்தையையும் பிரதியுத்தரமாகச் சொல்லாமல் மவுனமாயிருந்தார்கள்; அவனுக்கு மறுஉத்தரவு சொல்லவேண்டாம் என்று ராஜா கட்டளையிட்டிருந்தான்.
	<br /><br />
	22. அப்பொழுது இல்க்கியாவின் குமாரனாகிய எலியாக்கீம் என்னும் அரமனை விசாரிப்புக்காரனும், செப்னா என்னும் சம்பிரதியும், ஆசாப்பின் குமாரனாகிய யோவாக் என்னும் கணக்கனும், வஸ்திரங்களைக் கிழித்துக்கொண்டு, எசேக்கியாவினிடத்தில் வந்து, ரப்சாக்கேயின் வார்த்தைகளை அவனுக்கு அறிவித்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah36