import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 17 </title>
	<meta
          name="description"
          content="Jeremiah 17 | எரேமியா 17 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதாவின் பாவம் இரும்பெழுத்தாணியினாலும், வைரத்தின் நுனியினாலும் எழுதப்பட்டு, அவர்களுடைய இருதயத்தின் பலகையிலும் உங்கள் பலிபீடங்களுடைய கொம்புகளிலும் பதிந்திருக்கிறது.
	<br /><br />
	2. உயர்ந்த மேடுகளின்மேல் பச்சையான மரங்களண்டையில் இருந்த அவர்களுடைய பலிபீடங்களையும் அவர்களுடைய தோப்புகளையும் அவர்கள் பிள்ளைகள் நினைக்கும்படி இப்படிச் செய்திருக்கிறது.
	<br /><br />
	3. வயல்நிலத்திலுள்ள என் மலையே, நீ உன் எல்லைகளிலெல்லாம் செய்த பாவத்தினிமித்தம் நான் உன் ஆஸ்தியையும், உன் எல்லாப் பொக்கிஷங்களையும், உன் மேடைகளையுங்கூடச் சூறையிடுவிப்பேன்.
	<br /><br />
	4. அப்படியே நான் உனக்குக் கொடுத்த சுதந்தரத்தை நீதானே விட்டுவிடுவாய்; நீ அறியாத தேசத்தில் உன்னை உன் சத்துருக்களுக்கு அடிமையுமாக்குவேன்; என்றென்றைக்கும் எரியத்தக்க என் கோபத்தின் அக்கினியை மூட்டிவிட்டீர்களே.
	<br /><br />
	5. மனுஷன்மேல் நம்பிக்கைவைத்து, மாம்சமானதைத் தன் புயபலமாக்கிக்கொண்டு, கர்த்தரை விட்டு விலகுகிற இருதயமுள்ள மனுஷன் சபிக்கப்பட்டவன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. அவன் அந்தரவெளியில் கறளையாய்ப்போன செடியைப்போலிருந்து, நன்மைவருகிறதைக் காணாமல், வனாந்தரத்தின் வறட்சியான இடங்களிலும், குடியில்லாத உவர்நிலத்திலும் தங்குவான்.
	<br /><br />
	7. கர்த்தர்மேல் நம்பிக்கைவைத்து, கர்த்தரைத் தன் நம்பிக்கையாகக் கொண்டிருக்கிற மனுஷன் பாக்கியவான்.
	<br /><br />
	8. அவன் தண்ணீரண்டையிலே நாட்டப்பட்டதும், கால்வாய் ஓரமாகத் தன் வேர்களை விடுகிறதும், உஷ்ணம் வருகிறதைக் காணாமல் இலை பச்சையாயிருக்கிறதும், மழைத்தாழ்ச்சியான வருஷத்திலும் வருத்தமின்றித் தப்பாமல் கனி கொடுக்கிறதுமான மரத்தைப்போலிருப்பான்.
	<br /><br />
	9. எல்லாவற்றைப்பார்க்கிலும் இருதயமே திருக்குள்ளதும் மகா கேடுள்ளதுமாயிருக்கிறது, அதை அறியத்தக்கவன் யார்?
	<br /><br />
	10. கர்த்தராகிய நானே ஒவ்வொருவனுக்கும், அவனவன் வழிகளுக்கும் செய்கைகளின் பலன்களுக்கும் தக்கதைக் கொடுக்கும்படிக்கு, இருதயத்தை ஆராய்கிறவரும் உள்ளிந்திரியங்களைச் சோதித்தறிகிறவருமாயிருக்கிறேன்.
	<br /><br />
	11. அநியாயமாய் ஐசுவரியத்தைச் சம்பாதிக்கிறவன் முட்டையிட்டு அவயங்காத்தும், குஞ்சுபொரிக்காமற் போகிற கவுதாரிக்குச் சமானமாயிருக்கிறான்; அவன் தன் பாதி வயதிலே அதைவிட்டு, தன் முடிவிலே மூடனாயிருப்பான்.
	<br /><br />
	12. எங்கள் பரிசுத்த ஸ்தானம் ஆதிமுதற்கொண்டு உயர்ந்த மகிமையுள்ள சிங்காசனமாயிருக்கிறது.
	<br /><br />
	13. இஸ்ரவேலின் நம்பிக்கையாகிய கர்த்தாவே, உம்மைவிட்டு விலகுகிற யாவரும் வெட்கப்படுவார்கள்; அவர்கள் ஜீவனுள்ள தண்ணீரின் ஊற்றாகிய கர்த்தரை விட்டு விலகிப்போனபடியால், உம்மைவிட்டு அகன்றுபோகிறவர்களின் பெயர் புழுதியில் எழுதப்படும்.
	<br /><br />
	14. கர்த்தாவே, என்னைக் குணமாக்கும், அப்பொழுது குணமாவேன்; என்னை இரட்சியும், அப்பொழுது இரட்சிக்கப்படுவேன்; தேவரீரே என் துதி.
	<br /><br />
	15. இதோ, இவர்கள் என்னைப் பார்த்து: கர்த்தருடைய வார்த்தை எங்கே? அது இப்பொழுது வரட்டும் என்கிறார்கள்.
	<br /><br />
	16. நானோ உம்மைப் பின்பற்றுகிற மேய்ப்பன், இதற்கு நான் மிஞ்சி நடக்கவில்லை; ஆபத்துநாளை விரும்புகிறதுமில்லையென்று நீர் அறிவீர்; என் உதடுகளிலிருந்து புறப்பட்டது உமக்கு முன்பாகச் செவ்வையாயிருந்தது.
	<br /><br />
	17. நீர் எனக்குப் பயங்கரமாயிராதேயும்; தீங்குநாளில் நீரே என் அடைக்கலம்.
	<br /><br />
	18. நான் வெட்கப்படாமல், என்னைத் துன்பப்படுத்துகிறவர்கள் வெட்கப்படுவார்களாக; நான் கலங்காமல், அவர்கள் கலங்குவார்களாக; தேவரீர் தீங்குநாளை அவர்கள்மேல் வரப்பண்ணி, இரட்டிப்பான நொறுக்குதலால் அவர்களை நொறுக்கும்.
	<br /><br />
	19. கர்த்தர் என்னை நோக்கி: நீ போய் யூதாவின் ராஜாக்கள் வரத்தும்போக்குமாயிருக்கிற இந்த ஜனங்களின் புத்திரருடைய வாசலிலும் எருசலேமின் எல்லா வாசல்களிலும் நின்றுகொண்டு,
	<br /><br />
	20. அவர்களுடனே சொல்லவேண்டியது என்னவென்றால்: இந்த வாசல்களில் பிரவேசிக்கிற யூதாவின் ராஜாக்களும், எல்லா யூதரும், எருசலேமின் எல்லாக் குடிகளுமாகிய நீங்கள் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	21. நீங்கள் ஓய்வுநாளில் சுமைகளை எடுத்து, அவைகளை எருசலேமின் வாசல்களுக்குள் கொண்டுவராதபடிக்கும்,
	<br /><br />
	22. ஓய்வுநாளில் உங்கள் வீடுகளிலிருந்து சுமையை வெளியே கொண்டு போகாதபடிக்கும், ஒரு வேலையையும் செய்யாதபடிக்கும், உங்கள் ஆத்துமாக்களுக்காக எச்சரிக்கையாயிருந்து, நான் உங்கள் பிதாக்களுக்குக் கட்டளையிட்டபடி ஓய்வுநாளைப் பரிசுத்தமாக்குங்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	23. அவர்களோ கேளாமலும் தங்கள் செவிகளைச் சாயாமலும்போய்க் கேளாதபடிக்கும் புத்தியை ஏற்றுக்கொள்ளாதபடிக்கும், தங்கள் கழுத்தைக் கடினப்படுத்தினார்கள்.
	<br /><br />
	24. நீங்களோவெனில், ஓய்வுநாளில் இந்த நகரத்தின் வாசல்களுக்குள்ளே சுமையைக் கொண்டுவராதபடிக்கும், ஓய்வுநாளில் ஒரு வேலையையும் செய்யாமல் அதைப் பரிசுத்தமாக்கும்படிக்கும் என் சொல்லைக் கேட்பீர்களானால்,
	<br /><br />
	25. அப்பொழுது தாவீதின் சிங்காசனத்தில் உட்கார்ந்திருக்கிறவர்களும், இரதங்களின்மேலும் குதிரைகளின்மேலும் ஏறுகிறவர்களுமாகிய ராஜாக்களும் ராஜகுமாரர்களும், அவர்கள் பிரபுக்களும், யூதாவின் மனுஷரும், எருசலேமின் குடிகளும் இந்த நகரத்தின் வாசல்களுக்குள் பிரவேசிப்பார்கள்; இந்த நகரமும் என்றைக்கும் குடியுள்ளதாயிருக்கும்.
	<br /><br />
	26. யூதாவின் பட்டணங்களிலும், எருசலேமின் சுற்றுப்புறமான ஊர்களிலும், பென்யமீன் தேசத்திலும், பள்ளத்தாக்கான சீமையிலும், மலைநாட்டிலும், தெற்கிலுமிருந்து ஜனங்கள் சர்வாங்க தகனங்களையும், பலிகளையும், போஜனபலிகளையும், தூபவர்க்கங்களையும், ஸ்தோத்திரபலிகளையும் கர்த்தருடைய ஆலயத்துக்குக் கொண்டுவருவார்கள்.
	<br /><br />
	27. நீங்கள் ஓய்வுநாளைப் பரிசுத்தமாக்கும்படிக்கும் ஓய்வுநாளிலே சுமையை எருசலேமின் வாசல்களுக்குள் எடுத்துவராதிருக்கும்படிக்கும், என் சொல்லைக் கேளாமற்போனீர்களாகில், நான் அதின் வாசல்களில் தீக்கொளுத்துவேன்; அது எருசலேமின் அரமனைகளைப் பட்சித்தும், அவிந்துபோகாதிருக்கும் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah17