import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 12 </title>
	<meta
          name="description"
          content="Jeremiah 12 | எரேமியா 12 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தாவே, உம்மோடே நான் வழக்காடப்போனால், தேவரீர் நீதியுள்ளவராமே; ஆகிலும் உம்முடைய நியாயங்களைக் குறித்து உம்மோடே நான் பேசும்படி வேண்டுகிறேன்; ஆகாதவர்களின் வழி வாய்க்கிறதென்ன? துரோகஞ்செய்துவருகிற அனைவரும் சுகித்திருக்கிறதென்ன?
	<br /><br />
	2. நீர் அவர்களை நாட்டினீர், வேர்பற்றித் தேறிப்போனார்கள், கனியும் கொடுக்கிறார்கள்; நீர் அவர்கள் வாய்க்குச் சமீபமும், அவர்கள் உள்ளிந்திரியங்களுக்கோ தூரமுமாயிருக்கிறீர்.
	<br /><br />
	3. கர்த்தாவே, நீர் என்னை அறிந்திருக்கிறீர், என்னைக் காண்கிறீர்; என் இருதயம் உமக்கு முன்பாக எப்படிப்பட்டதென்று சோதித்து அறிகிறீர்; அடிக்கப்படும் ஆடுகளைப்போல அவர்களைப் பிடுங்கிப்போட்டு, கொலைநாளுக்கு அவர்களை நியமியும்.
	<br /><br />
	4. எந்தமட்டும் தேசம் புலம்பி, எல்லா வெளியின் புல்லும் வாடி, அதின் குடிகளுடைய பொல்லாப்பினிமித்தம் மிருகங்களும் பறவைகளும் அழியவேண்டும்! எங்கள் முடிவை அவன் காண்பதில்லையென்கிறார்கள்.
	<br /><br />
	5. நீ காலாட்களோடே ஓடும்போதே உன்னை இளைக்கப்பண்ணினார்களானால், குதிரைகளோடே எப்படிச் சேர்ந்து ஓடுவாய்? சமாதானமுள்ள தேசத்திலேயே நீ அடைக்கலம் தேடினால், யோர்தான் பிரவாகித்து வரும்போது நீ என்னசெய்வாய்?
	<br /><br />
	6. உன் சகோதரரும், உன் தகப்பன் வம்சத்தாரும் உனக்கு துரோகம்பண்ணி, அவர்களும் உன்னைப் பின்தொடர்ந்து மிகவும் ஆரவாரம்பண்ணினார்கள்; அவர்கள் உன்னோடே இனிய வார்த்தைகளைப் பேசினாலும் அவர்களை நம்பவேண்டாம்.
	<br /><br />
	7. நான் என் வீட்டை விட்டுவிட்டேன், என் சுதந்தரத்தை நெகிழவிட்டேன்; என் ஆத்துமா நேசித்தவனை அவனுடைய சத்துருவின் கையிலே ஒப்புக்கொடுத்தேன்.
	<br /><br />
	8. என் சுதந்தரம் காட்டிலுள்ள சிங்கத்தைப்போல் எனக்காயிற்று; அது எனக்கு விரோதமாய் கெர்ச்சிக்கிறது; ஆதலால் அதை வெறுக்கிறேன்.
	<br /><br />
	9. என் சுதந்தரம் பலவருணமான பட்சியைப்போல எனக்காயிற்று; ஆகையால், பட்சிகள் அதைச் சுற்றிலும் வருவதாக; வெளியில் சகல ஜீவன்களே அதைப் பட்சிக்கும்படி கூடிவாருங்கள்.
	<br /><br />
	10. அநேக மேய்ப்பர்கள் என் திராட்சத்தோட்டத்தை அழித்து, என் பங்கைக் காலால் மிதித்து, என் பிரியமான பங்கைப் பாழான வனாந்தரமாக்கினார்கள்.
	<br /><br />
	11. அதைப் பாழாக்கிவிட்டார்கள்; பாழாய்க் கிடக்கிற அது என்னை நோக்கிப் புலம்புகிறது; தேசமெல்லாம் பாழாயிற்று; ஒருவனும் அதை மனதிலே வைக்கிறதில்லை.
	<br /><br />
	12. கொள்ளைக்காரர் வனாந்தரத்திலுள்ள எல்லா உயர்நிலங்களின்மேலும் வருகிறார்கள்; கர்த்தருடைய பட்டயம் தேசத்தின் ஒருமுனைதொடங்கித் தேசத்தின் மறுமுனைமட்டும் பட்சித்துக்கொண்டிருக்கும்; மாம்சமாகிய ஒன்றுக்கும் சமாதானமில்லை.
	<br /><br />
	13. கோதுமையை விதைத்தார்கள், முள்ளுகளை அறுப்பார்கள்; பிரயாசப்படுவார்கள், பிரயோஜனமடையார்கள்; கர்த்தருடைய உக்கிரகோபத்தினால் உங்களுக்கு வரும் பலனைக்குறித்து வெட்கப்படுங்கள்.
	<br /><br />
	14. இதோ, நான் என் ஜனமாகிய இஸ்ரவேலுக்குக் காணியாட்சியாகக் கொடுத்த என் சுதந்தரத்தைத் தொடுகிற துஷ்டரான அயலார் அனைவரையும் தங்கள் தேசத்தில் இராதபடிக்குப் பிடுங்கிப்போடுவேன் என்று, கர்த்தர் அவர்களைக்குறித்துச் சொல்லுகிறார்; யூதா வம்சத்தாரையும் அவர்கள் நடுவில் இராதபடிக்குப் பிடுங்கிப்போடுவேன்.
	<br /><br />
	15. அவர்களை நான் பிடுங்கிப்போட்ட பிற்பாடு, நான் திரும்பவும் அவர்கள்மேல் இரங்கி, அவர்களைத் தங்கள் தங்கள் சுதந்தரத்துக்கும் தங்கள் தங்கள் பூமிக்கும் திரும்பப்பண்ணுவேன்.
	<br /><br />
	16. அப்புறம் அவர்கள் என் ஜனத்துக்குப் பாகாலின்மேல் ஆணையிடக் கற்றுக்கொடுத்ததுபோல, கர்த்தருடைய ஜீவனைக்கொண்டு என்று சொல்லி, என் நாமத்தின்மேல் ஆணையிடும்படி என் ஜனத்தின் வழிகளை நன்றாய்க் கற்றுக்கொண்டால், அவர்கள் என் ஜனத்தின் நடுவிலே ஊன்றக்கட்டப்படுவார்கள்.
	<br /><br />
	17. கேளார்களேயாகில், நான் அப்படிப்பட்ட ஜாதியை வேரோடே பிடுங்கிப்போட்டு அழித்துவிடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah12