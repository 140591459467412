import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jonah3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோனா 3 </title>
	<meta
          name="description"
          content="Jonah 3 | யோனா 3 | Yona 3 | 
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இரண்டாந்தரம் கர்த்தருடைய வார்த்தை யோனாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	2. நீ எழுந்து மகா நகரமாகிய நினிவேக்குப் போய், நான் உனக்குக் கற்பிக்கும் வார்த்தையை அதற்கு விரோதமாய்ப் பிரசங்கி என்றார்.
	<br /><br />
	3. யோனா எழுந்து, கர்த்தருடைய வார்த்தையின்படியே நினிவேக்குப் போனான்; நினிவே மூன்றுநாள் பிரயாண விஸ்தாரமான மகா பெரிய நகரமாயிருந்தது.
	<br /><br />
	4. யோனா நகரத்தில் பிரவேசித்து, ஒருநாள் பிரயாணம்பண்ணி: இன்னும் நாற்பதுநாள் உண்டு, அப்பொழுது நினிவே கவிழ்க்கப்பட்டுப்போம் என்று கூறினான்.
	<br /><br />
	5. அப்பொழுது நினிவேயிலுள்ள ஜனங்கள் தேவனை விசுவாசித்து, உபவாசஞ்செய்யும்படிக் கூறினார்கள்; பெரியோர்முதல் சிறியோர்மட்டும் இரட்டுடுத்திக்கொண்டார்கள்.
	<br /><br />
	6. இந்தச் செய்தி நினிவேயின் ராஜாவுக்கு எட்டினபோது, அவன் தன் சிங்காசனத்தைவிட்டு எழுந்து, தான் உடுத்தியிருந்த உடுப்பைக் கழற்றிப்போட்டு, இரட்டை உடுத்திக்கொண்டு, சாம்பலிலே உட்கார்ந்தான்.
	<br /><br />
	7. மேலும் ராஜா, தானும் தன் பிரதானிகளும் நிர்ணயம்பண்ணின கட்டளையாக, நினிவேயிலெங்கும் மனுஷரும் மிருகங்களும், மாடுகளும் ஆடுகளும் ஒன்றும் ருசிபாராதிருக்கவும், மேயாமலும் தண்ணீர் குடியாமலும் இருக்கவும்,
	<br /><br />
	8. மனுஷரும் மிருகங்களும் இரட்டினால் மூடிக்கொண்டு, தேவனை நோக்கி உரத்த சத்தமாய்க் கூப்பிடவும், அவரவர் தம்தம் பொல்லாத வழியையும் தம்தம் கைகளிலுள்ள கொடுமையையும் விட்டுத் திரும்பவுங்கடவர்கள்.
	<br /><br />
	9. யாருக்குத் தெரியும்; நாம் அழிந்து போகாதபடிக்கு ஒருவேளை தேவன் மனஸ்தாபப்பட்டு, தம்முடைய உக்கிர கோபத்தைவிட்டுத் திரும்பினாலும் திரும்புவார் என்று கூறச்சொன்னான்.
	<br /><br />
	10. அவர்கள் தங்கள் பொல்லாத வழியைவிட்டுத் திரும்பினார்களென்று தேவன் அவர்களுடைய கிரியைகளைப் பார்த்து, தாம் அவர்களுக்குச் செய்வேன் என்று சொல்லியிருந்த தீங்கைக் குறித்து மனஸ்தாபப்பட்டு, அதைச் செய்யாதிருந்தார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jonah3