import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoPeter3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> The Lords Return 2nd Peter3 in Tamil Bible Verse | 2 பேதுரு - 3 </title>
	<meta
          name="description"
          content="The Lords Return | 2nd Peter in Tamil Bible Verse | 2-பேதுரு அதிகாரம் - 3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* இறைவனின் இரண்டாம் வருகை  *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. பிரியமானவர்களே, இந்த இரண்டாம் நிருபத்தை இப்பொழுது உங்களுக்கு எழுதுகிறேன்.
	<br /><br />
	2. பரிசுத்த தீர்க்கதரிசிகளால் முன்சொல்லப்பட்ட வார்த்தைகளையும், இரட்சகராயிருக்கிற கர்த்தருடைய அப்போஸ்தலராகிய எங்களுடைய கட்டளைகளையும் நீங்கள் நினைவுகூரும்படி இந்த நிருபங்களினால் உங்கள் உண்மையான மனதை நினைப்பூட்டி எழுப்புகிறேன்.
	<br /><br />
	3. முதலாவது நீங்கள் அறியவேண்டியது என்னவெனில்: கடைசிநாட்களில் பரியாசக்காரர் வந்து, தங்கள் சுய இச்சைகளின்படியே நடந்து,
	<br /><br />
	4. அவர் வருவார் என்று சொல்லுகிற வாக்குத்தத்தம் எங்கே? பிதாக்கள் நித்திரையடைந்தபின்பு சகலமும் சிருஷ்டிப்பின்தோற்றமுதல் இருந்தவிதமாயிருக்கிறதே என்று சொல்லுவார்கள்.
	<br /><br />
	5. பூர்வகாலத்தில் தேவனுடைய வார்த்தையினாலே வானங்களும், ஜலத்தினின்று தோன்றி ஜலத்தினாலே நிலைகொண்டிருக்கிற பூமியும் உண்டாயினவென்பதையும்,
	<br /><br />
	6. அப்பொழுது இருந்த உலகம் ஜலப்பிரளயத்தினாலே அழிந்ததென்பதையும் மனதார அறியாமலிருக்கிறார்கள்.
	<br /><br />
	7. இப்பொழுது இருக்கிற வானங்களும் பூமியும் அந்த வார்த்தையினாலேயே அக்கினிக்கு இரையாக வைக்கப்பட்டு, தேவபக்தியில்லாதவர்கள் நியாயந்தீர்க்கப்பட்டு அழிந்துபோகும் நாள்வரைக்கும் காக்கப்பட்டிருக்கிறது.
	<br /><br />
	8. பிரியமானவர்களே, கர்த்தருக்கு ஒருநாள் ஆயிரம் வருஷம்போலவும், ஆயிரம் வருஷம் ஒருநாள்போலவும் இருக்கிறதென்கிற இந்த ஒரு காரியத்தை நீங்கள் அறியாதிருக்கவேண்டாம்.
	<br /><br />
	9. தாமதிக்கிறார் என்று சிலர் எண்ணுகிறபடி, கர்த்தர் தமது வாக்குத்தத்தத்தைக்குறித்துத் தாமதமாயிராமல்; ஒருவரும் கெட்டுப்போகாமல் எல்லாரும் மனந்திரும்பவேண்டுமென்று விரும்பி, நம்மேல் நீடிய பொறுமையுள்ளவராயிருக்கிறார்.
	<br /><br />
	10. கர்த்தருடைய நாள் இரவிலே திருடன் வருகிறவிதமாய் வரும்; அப்பொழுது வானங்கள் மடமட என்று அகன்றுபோம், பூதங்கள் வெந்து உருகிப்போம், பூமியும் அதிலுள்ள கிரியைகளும் எரிந்து அழிந்துபோம்.
	<br /><br />
	11. இப்படி இவைகளெல்லாம் அழிந்து போகிறதாயிருக்கிறபடியால் நீங்கள் எப்படிப்பட்ட பரிசுத்த நடக்கையும் தேவபக்தியும் உள்ளவர்களாயிருக்க வேண்டும்!
	<br /><br />
	12. தேவனுடைய நாள் சீக்கிரமாய் வரும்படிக்கு மிகுந்த ஆவலோடே காத்திருங்கள்; அந்த நாளில் வானங்கள் வெந்து அழிந்து, பூதங்கள் எரிந்து உருகிப்போம்.
	<br /><br />
	13. அவருடைய வாக்குத்தத்தத்தின்படியே நீதி வாசமாயிருக்கும் புதிய வானங்களும் புதிய பூமியும் உண்டாகுமென்று காத்திருக்கிறோம்.
	<br /><br />
	14. ஆகையால், பிரியமானவர்களே, இவைகள் வரக் காத்திருக்கிற நீங்கள் கறையற்றவர்களும் பிழையில்லாதவர்களுமாய்ச் சமாதானத்தோடே அவர் சந்நிதியில் காணப்படும்படி ஜாக்கிரதையாயிருங்கள்.
	<br /><br />
	15. மேலும் நம்முடைய கர்த்தரின் நீடிய பொறுமையை இரட்சிப்பென்று எண்ணுங்கள்; நமக்குப் பிரியமான சகோதரனாகிய பவுலும் தனக்கு அருளப்பட்ட ஞானத்தினாலே இப்படியே உங்களுக்கு எழுதியிருக்கிறான்;
	<br /><br />
	16. எல்லா நிருபங்களிலும் இவைகளைக்குறித்துப் பேசியிருக்கிறான்; அவன் சொன்னவைகளில் சில காரியங்கள் அறிகிறதற்கு அரிதாயிருக்கிறது; கல்லாதவர்களும் உறுதியில்லாதவர்களும் மற்ற வேதவாக்கியங்களைப் புரட்டுகிறதுபோலத் தங்களுக்குக் கேடுவரத்தக்கதாக இவைகளையும் புரட்டுகிறார்கள்.
	<br /><br />
	17. ஆதலால் பிரியமானவர்களே, இவைகளை முன்னமே நீங்கள் அறிந்திருக்கிறபடியால், அக்கிரமக்காரருடைய வஞ்சகத்திலே நீங்கள் இழுப்புண்டு உங்கள் உறுதியிலிருந்து விலகி விழுந்துபோகாதபடிக்கு எச்சரிக்கையாயிருந்து,
	<br /><br />
	18. நம்முடைய கர்த்தரும் இரட்சகருமாகிய இயேசுகிறிஸ்துவின் கிருபையிலும் அவரை அறிகிற அறிவிலும் வளருங்கள். அவருக்கு இப்பொழுதும் என்றென்றைக்கும் மகிமையுண்டாவதாக. ஆமென்.
</p>
<p>
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 2 பேதுரு 3 இறைவனின் இரண்டாம் வருகை பற்றிய கருத்துக்களை தெளிவுபடுத்துவதன் மூலம் தொடங்குகிறது. கடுமையான துன்புறுத்தலின் காரணமாக இயேசுவின் உடனடி வருகையை பெரும்பாலானோர் எதிர்பார்த்தனர். அது நடக்காததால் கேலிக்காரர்கள் சர்ச்சையை உருவாக்கினர். பேதுரு காரணங்களை எடுத்துரைத்து, இறுதி நாட்களில் பூமிக்கு என்ன நடக்கும் என்பதைப் பற்றி பேசுகிறார். அந்த நாளை எதிர்பார்த்து புனிதமான மற்றும் தெய்வீக வாழ்க்கையை வாழ நினைவூட்டும் கடிதத்தை அவர் முடிக்கிறார்.
<br /><br />
			<br /><br /> 
இந்த புத்தகம் ஆரம்பகால திருச்சபையின் நாட்களில் இருந்ததைப் போலவே இன்றும் பொருத்தமானது என்பதை நீங்கள் பார்க்க முடியும். இது நடைமுறை ஆலோசனை மற்றும் ஊக்கம் நிறைந்தது. நீங்கள் 2 பேதுருவின் புத்தகத்தை வாசிக்கும்போதும் படிக்கும்போதும் நீங்கள் ஆசீர்வதிக்கப்பட்டவர்களாகவும் ஊக்கமளிப்பவர்களாகவும் இருப்பீர்களாக. *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default TwoPeter3