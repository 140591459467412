import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 17 </title>
	<meta
          name="description"
          content="Isaiah 17 | ஏசாயா 17 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தமஸ்குவின் பாரம். இதோ, தமஸ்குவானது நகரமாயிராமல் தள்ளப்பட்டு, பாழான மண்மேடாகும்.
	<br /><br />
	2. ஆரோவேரின் பட்டணங்கள் பாழாய் விடப்பட்டு, மந்தைகளின் வெளியாயிருக்கும்; மிரட்டுவாரில்லாமல் அவைகள் அங்கே படுத்துக்கொள்ளும்.
	<br /><br />
	3. அரண் எப்பிராயீமையும், ராஜாங்கம் தமஸ்குவையும் விட்டொழியும்; இஸ்ரவேல் புத்திரருடைய மகிமைக்கு நேரிட்டதுபோல சீரியாவில் மீதியாயிருப்பவர்களுக்கும் நேரிடும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. அக்காலத்திலே யாக்கோபின் மகிமை குறைந்துபோம், அவனுடைய கொழுத்த தேகம் மெலிந்துபோம்.
	<br /><br />
	5. ஒருவன் ஓங்கின பயிரை அரிந்து, தன் கையினால் கதிர்களை அறுத்து, ரெப்பாயீம் பள்ளத்தாக்கிலே கதிர்களைச் சேர்க்கிறதுபோலிருக்கும்.
	<br /><br />
	6. ஆனாலும் ஒலிவ மரத்தை உலுக்கும்போது நுனிக்கொம்பிலே இரண்டு மூன்று காய்களும், காய்க்கிற அதின் கிளைகளிலே நாலைந்து காய்களும் மீதியாயிருப்பதுபோல, அதிலே பின்பறிப்புக்குக் கொஞ்சம் மீதியாயிருக்குமென்று இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. அக்காலத்திலே மனுஷன் தன் கைகளின் கிரியையாகிய பீடங்களை நோக்காமலும், தன் விரல்கள் உண்டுபண்ணின தோப்புவிக்கிரகங்களையும், சிலைகளையும் நோக்காமலும்,
	<br /><br />
	8. தன்னை உண்டாக்கினவரையே நோக்குவான், அவன் கண்கள் இஸ்ரவேலின் பரிசுத்தரையே நோக்கிக்கொண்டிருக்கும்.
	<br /><br />
	9. அக்காலத்திலே அவர்களுடைய அரணான பட்டணங்கள் இஸ்ரவேல் புத்திரருக்கு மீதியாய் வைக்கப்பட்ட தழையைப்போலவும், நுனிக்கொம்பைப்போலவுமாகி, பாழாய்க் கிடக்கும்.
	<br /><br />
	10. உன் பெலமாகிய கன்மலையை நீ நினையாமல், உன் இரட்சிப்பாகிய தேவனை மறந்தாய்; ஆதலால் நீ நேர்த்தியான நாற்றுகளை நட்டாலும், அந்நிய தேசத்துக் கன்றுகளை வைத்தாலும்,
	<br /><br />
	11. பகற்காலத்திலே உன் நாற்றை வளரவும், விடியற்காலத்திலே உன் விதையை முளைக்கவும் பண்ணினாலும், பலனைச் சேர்க்கும் நாளிலே துக்கமும் கடும்வேதனையுமே உங்கள் அறுப்பாயிருக்கும்.
	<br /><br />
	12. ஐயோ! கடல்கள் கொந்தளிக்கிறதுபோல கொந்தளிக்கிற அநேக ஜனங்களின் திரளும், பலத்த தண்ணீர்கள் இரைகிறதுபோல இரைகிற ஜனக்கூட்டங்களின் அமளியும் உண்டாயிருக்கிறது.
	<br /><br />
	13. ஜனக்கூட்டங்கள் திரளான தண்ணீர்கள் இரைகிறதுபோல இரைந்தாலும், அவர்களை அவர் அதட்டுவார்; அவர்கள் தூரமாய் ஓடிப்போவார்கள்; மலைகளிலே காற்றினால் பறக்கடிக்கிற பதரைப்போலவும், சுழல்காற்றிலே அகப்பட்ட துரும்பைப்போலவும் துரத்தப்படுவார்கள்.
	<br /><br />
	14. இதோ, சாயங்காலத்திலே கலக்கமுண்டாகும், விடியற்காலத்துக்குமுன் அவர்கள் ஒழிந்துபோவார்கள்; இதுவே நம்மைக் கொள்ளையாடுகிறவர்களின் பங்கும், நம்மைச் சூறையாடுகிறவர்களின் வீதமுமாயிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah17