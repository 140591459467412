import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Haggai2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆகாய் 2 </title>
	<meta
          name="description"
          content="Haggai 2 | ஆகாய் 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஏழாம் மாதம் இருபத்தோராந்தேதியிலே ஆகாய் என்னும் தீர்க்கதரிசியின் மூலமாய், கர்த்தருடைய வார்த்தை உண்டாயிற்று; அவர்:
	<br /><br />
	2. நீ செயல்த்தியேலின் குமாரனாகிய செருபாபேல் என்னும் யூதாவின் தலைவனோடும், யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனோடும், ஜனத்தில் மீதியானவர்களோடும் சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	3. இந்த ஆலயத்தின் முந்தின மகிமையைக் கண்டவர்களில் உங்களுக்குள்ளே மீந்திருக்கிறவர்கள் யார்? இப்பொழுது இது உங்களுக்கு எப்படிக் காண்கிறது? அதற்கு இது உங்கள் பார்வையில் ஒன்றுமில்லாததுபோல் காண்கிறதல்லவா?
	<br /><br />
	4. ஆனாலும் செருபாபேலே, நீ திடன்கொள் என்று கர்த்தர் சொல்லுகிறார்; யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனே, நீ திடன்கொள்; தேசத்தின் எல்லா ஜனங்களே, நீங்கள் திடன்கொள்ளுங்கள், வேலையை நடத்துங்கள் என்று கர்த்தர் சொல்லுகிறார்; நான் உங்களுடனே இருக்கிறேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. நீங்கள் எகிப்திலிருந்து புறப்படுகிறபோது நான் உங்களோடே உடன்படிக்கைபண்ணின வார்த்தையின்படியே, என் ஆவியானவரும் உங்கள் நடுவில் நிலைகொண்டிருப்பார்; பயப்படாதேயுங்கள்.
	<br /><br />
	6. சேனைகளின் கர்த்தர் சொல்லுகிறார்: கொஞ்சக்காலத்துக்குள்ளே இன்னும் ஒருதரம் நான் வானத்தையும் பூமியையும் சமுத்திரத்தையும் வெட்டாந்தரையையும் அசையப்பண்ணுவேன்.
	<br /><br />
	7. சகல ஜாதிகளையும் அசையப்பண்ணுவேன், சகல ஜாதிகளாலும் விரும்பப்பட்டவர் வருவார்; இந்த ஆலயத்தை மகிமையினால் நிறையப்பண்ணுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	8. வெள்ளியும் என்னுடையது, பொன்னும் என்னுடையது என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. முந்தின ஆலயத்தின் மகிமையைப்பார்க்கிலும், இந்தப் பிந்தின ஆலயத்தின் மகிமை பெரிதாயிருக்கும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; இவ்விடத்திலே சமாதானத்தைக் கட்டளையிடுவேன் என்று சேனைகளின் கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	10. தரியுவின் இரண்டாம் வருஷம் ஒன்பதாம் மாதம் இருபத்துநாலாந்தேதியிலே, ஆகாய் என்னும் தீர்க்கதரிசியின் மூலமாய்க் கர்த்தருடைய வார்த்தை உண்டாயிற்று; அவர்:
	<br /><br />
	11. ஒருவன் தன் வஸ்திரத்தின் தொங்கலிலே பரிசுத்த மாம்சத்தைக் கொண்டுபோகையில் தன் வஸ்திரத்தின் தொங்கல் அப்பத்தையாகிலும், சாதத்தையாகிலும், திராட்சரசத்தையாகிலும், எண்ணெயையாகிலும், மற்றெந்த போஜனபதார்த்தத்தையாகிலும் தொட்டால் அது பரிசுத்தமாகுமோ என்று நீ ஆசாரியரிடத்தில் வேத நியாயத்தைப்பற்றிக் கேள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றார்.
	<br /><br />
	12. அதற்கு ஆசாரியர்கள் பிரதியுத்தரமாக: பரிசுத்தமாகாது என்றார்கள்.
	<br /><br />
	13. பிணத்தால் தீட்டுப்பட்டவன் அவைகளில் எதையாகிலும் தொட்டால், அது தீட்டுப்படுமோ என்று ஆகாய் பின்னும் கேட்டான்; அதற்கு ஆசாரியர்கள் பிரதியுத்தரமாக: தீட்டுப்படும் என்றார்கள்.
	<br /><br />
	14. அப்பொழுது ஆகாய்: அப்படியே இந்த ஜனங்களும் இந்த ஜாதியாரும் என் சமுகத்தில் இருக்கிறார்கள் என்று கர்த்தர் சொல்லுகிறார்; அவர்களுடைய கைகளின் எல்லாக் கிரியைகளும் அப்படியே இருக்கிறது; அவர்கள் அங்கே கொண்டுவந்து படைக்கிறதும் தீட்டுப்பட்டிருக்கிறது.
	<br /><br />
	15. இப்போதும் கர்த்தருடைய ஆலயத்தைக் கட்டும்படி ஒரு கல்லின்மேல் ஒரு கல் வைக்கப்பட்டது முதல் நடந்ததை உங்கள் மனதிலே சிந்தித்துப்பாருங்கள்.
	<br /><br />
	16. அந்த நாட்கள் முதல் ஒருவன் இருபது மரக்காலாகக் கண்ட அம்பாரத்தினிடத்தில் வந்தபோது, பத்து மரக்கால்மாத்திரம் இருந்தது; ஒருவன் ஆலையின் தொட்டியில் ஐம்பது குடம் மொள்ள ஆலையினிடத்திலே வந்தபோது இருபது குடம்மாத்திரம் இருந்தது.
	<br /><br />
	17. கருக்காயினாலும் விஷப்பனியினாலும் கல்மழையினாலும் உங்களை உங்கள் கைகளின் வேலையிலெல்லாம் அடித்தேன்; ஆனாலும் நீங்கள் என்னிடத்தில் மனதைத் திருப்பாமல் போனீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. இப்போதும் இதற்கு முந்தினகாலத்தில் நடந்ததை உங்கள் மனதிலே சிந்தித்துப்பாருங்கள்; ஒன்பதாம் மாதம் இருபத்துநாலாந்தேதியாகிய இந்நாள்முதல் கர்த்தருடைய ஆலயத்தின் அஸ்திபாரம் போடப்பட்ட அந்நாள்வரைக்கும் சென்றகாலத்தில் நடந்ததை உங்கள் மனதிலே சிந்தித்துப்பாருங்கள்.
	<br /><br />
	19. களஞ்சியத்தில் இன்னும் விதைத்தானியம் உண்டோ? திராட்சச்செடியும் அத்திமரமும் மாதளஞ்செடியும் ஒலிவமரமும் கனிகொடுக்கவில்லையே; நான் இன்றுமுதல் உங்களை ஆசீர்வதிப்பேன் என்று சொல்லுகிறார் என்றான்.
	<br /><br />
	20. இருபத்துநாலாந்தேதியாகிய அந்நாளிலே கர்த்தருடைய வார்த்தை இரண்டாம் விசை ஆகாய் என்பவனுக்கு உண்டாகி, அவர்:
	<br /><br />
	21. நீ யூதாவின் தலைவனாகிய செருபாபேலோடே சொல்லவேண்டியது என்னவென்றால், நான் வானத்தையும் பூமியையும் அசையப்பண்ணி,
	<br /><br />
	22. ராஜ்யங்களின் சிங்காசனத்தைக் கவிழ்த்து, ஜாதிகளுடைய ராஜ்யங்களின் பெலத்தை அழித்து, இரதத்தையும் அதில் ஏறியிருக்கிறவர்களையும் கவிழ்த்துப்போடுவேன்; குதிரைகளோடே அவைகளின்மேல் ஏறியிருப்பவர்களும் அவரவர் தங்கள் தங்கள் சகோதரனின் பட்டயத்தினாலே விழுவார்கள்.
	<br /><br />
	23. சேனைகளின் கர்த்தர் சொல்லுகிறார்: செயல்த்தியேலின் குமாரனாகிய செருபாபேல் என்னும் என் ஊழியக்காரனே, உன்னை நான் அந்நாளிலே சேர்த்துக்கொண்டு, உன்னை முத்திரை மோதிரமாக வைப்பேன் என்று கர்த்தர் சொல்லுகிறார்; நான் உன்னைத் தெரிந்துகொண்டேன் என்று சேனைகளின் கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Haggai2