import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 16 </title>
	<meta
          name="description"
          content="Isaiah 16 | ஏசாயா 16 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தேசாதிபதிக்குச் செலுத்தும் ஆட்டுக்குட்டிகளை நீங்கள் சேலாபட்டணந்துவக்கி வனாந்தரமட்டும் சேர்த்துச் சீயோன் குமாரத்தியின் மலைக்கு அனுப்புங்கள்.
	<br /><br />
	2. இல்லாவிட்டால் கூட்டைவிட்டுத் துரத்தப்பட்டு அலைகிற குருவியைப்போல மோவாபின் குமாரத்திகள் அர்னோன் நதியின் துறைகளிடத்திலிருப்பார்கள்.
	<br /><br />
	3. நீ ஆலோசனைபண்ணி, நியாயஞ்செய்து, மத்தியானத்திலே உன் நிழலை இரவைப்போலாக்கி, துரத்தப்பட்டவர்களை மறைத்துக்கொள், ஓடிவருகிறவர்களைக் காட்டிக்கொடாதிரு.
	<br /><br />
	4. மோவாபே, துரத்திவிடப்பட்ட என் ஜனங்கள் உன்னிடத்தில் தங்கட்டும்; சங்கரிக்கிறவனுக்குத் தப்ப அவர்களுக்கு அடைக்கலமாயிரு; ஒடுக்குகிறவன் இல்லாதேபோவான்; சங்கரிப்பு ஒழிந்துபோம்; மிதிக்கிறவர்கள் தேசத்தில் இராதபடிக்கு அழிந்துபோவார்கள்.
	<br /><br />
	5. கிருபையினாலே சிங்காசனம் ஸ்தாபிக்கப்படும்; நியாயம் விசாரித்துத் துரிதமாய் நீதிசெய்கிற ஒருவர் அதின்மேல் தாவீதின் கூடாரத்திலே நியாயாதிபதியாய் உண்மையோடே வீற்றிருப்பார்.
	<br /><br />
	6. மோவாபின் பெருமையையும், அவன் மேட்டிமையையும், அவன் அகங்காரத்தையும், அவன் உக்கிரத்தையும் குறித்துக் கேட்டோம்; அவன் மெத்தப் பெருமைக்காரன்; ஆனாலும் அவன் வீம்பு செல்லாது.
	<br /><br />
	7. ஆகையால், மோவாபியர் ஒருவருக்காக ஒருவர் அலறுவார்கள், எல்லாரும் ஏகமாய் அலறுவார்கள்; கிராரேசேத் ஊரின் அஸ்திபாரங்கள் தகர்க்கப்பட்டதே என்று அவைகளுக்காகப் பெருமூச்சு விடுவார்கள்.
	<br /><br />
	8. எஸ்போன் ஊர் வயல்கள் வாடிப்போயின; சிப்மாஊர்த் திராட்சச்செடியின் நல்ல கொடிகளை ஜாதிகளின் அதிபதிகள் நறுக்கிப்போட்டார்கள்; அவைகள் யாசேர்மட்டும் சென்று வனாந்தரத்தில் படர்ந்திருந்தது; அவைகளின் கொடிகள் நீண்டு கடலுக்கப்பாலே எட்டியிருந்தது.
	<br /><br />
	9. ஆகையால் யாசேருக்காக அழுததுபோல, சிப்மாஊர்த் திராட்சச்செடிக்காகவும் மிகவும் அழுவேன்; எஸ்போனே, எலெயாலேயே, உனக்கு என் கண்ணீரைப் பாய்ச்சுவேன்; உன் வசந்தகாலத்துப் பழங்களுக்காகவும், உன் திராட்சப்பழ அறுப்புக்காகவும் ஆரவாரிக்கிற சந்தோஷ சத்தம் விழுந்துபோயிற்று.
	<br /><br />
	10. பயிர்வெளியிலிருந்து சந்தோஷமும் களிப்பும் அற்றுப்போயின; திராட்சத்தோட்டங்களில் பாடலுமில்லை ஆர்ப்பரிப்புமில்லை; ஆலையில் இரசத்தை மிதிக்கிறவனுமில்லை; சந்தோஷ ஆரவாரத்தை ஓயப்பண்ணினேன்.
	<br /><br />
	11. ஆகையால் மோவாபினிமித்தம் என் குடல்களும், கிராரேசினிமித்தம் என் உள்ளமும் சுரமண்டலத்தைப் போலத் தொனிக்கிறது.
	<br /><br />
	12. மோவாப் மேடைகளின்மேல் சலித்துப்போனான் என்று காணப்படும்போது, பிரார்த்தனைசெய்யத் தன் பரிசுத்த ஸ்தானத்திலே பிரவேசிப்பான்; ஆனாலும் அநுகூலப்படமாட்டான்.
	<br /><br />
	13. மோவாபைக்குறித்து அக்காலத்திலே கர்த்தர் சொன்ன வார்த்தை இதுவே.
	<br /><br />
	14. ஒரு கூலிக்காரனுடைய வருஷங்களுக்கொத்த மூன்று வருஷங்களுக்குள்ளே மோவாபின் மகிமையும் அதின் மகா ஜனக்கூட்டமும் சீரழிந்துபோம்; அதில் மீதியாயிருப்பது மிகவும் சிறிதும் அற்பமுமாயிருக்கும் என்று கர்த்தர் இப்பொழுது சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah16