import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 4 </title>
	<meta
          name="description"
          content="Amos 4 | ஆமோஸ் 4 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சமாரியாவின் மலைகளிலுள்ள பாசானின் மாடுகளே, நீங்கள் இந்த வார்த்தைகளைக் கேளுங்கள்; தரித்திரரை ஒடுக்கி, எளியவர்களை நொறுக்கி, அவர்களுடைய எஜமான்களை நோக்கி: நாங்கள் குடிக்கும்படிக் கொண்டுவாருங்கள் என்று சொல்லுகிறீர்கள்.
	<br /><br />
	2. இதோ, கர்த்தராகிய ஆண்டவர் உங்களைத் துறடுகளாலும், உங்கள் பின்சந்ததியை மீன்பிடிக்கிற தூண்டில்களாலும் இழுத்துக்கொண்டுபோகும் நாட்கள் வருமென்று அவர் தம்முடைய பரிசுத்தத்தைக்கொண்டு ஆணையிட்டார்.
	<br /><br />
	3. அப்பொழுது நீங்கள் ஒவ்வொருவனும் அரமனைக்குச் சுமந்துகொண்டுபோவதை எறிந்துவிட்டு, தனக்கு எதிரான திறப்புகளின் வழியாய்ப் புறப்பட்டுப்போவீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. பெத்தேலுக்குப் போய்த் துரோகம் பண்ணுங்கள், கில்காலுக்கும் போய்த் துரோகத்தைப் பெருகப்பண்ணி, காலைதோறும் உங்கள் பலிகளையும், மூன்றாம் வருஷத்திலே உங்கள் தசமபாகங்களையும் செலுத்தி,
	<br /><br />
	5. புளித்தமாவுள்ள ஸ்தோத்திரபலியோடே தூபங்காட்டி, உற்சாக பலிகளைக் கூறித் தெரியப்படுத்துங்கள்; இஸ்ரவேல் புத்திரரே, இப்படிச் செய்வதே உங்களுக்குப் பிரியம் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	6. ஆகையால் நான் உங்கள் பட்டணங்களில் எல்லாம் உங்கள் பல்லுகளுக்கு ஓய்வையும், உங்கள் ஸ்தலங்களில் எல்லாம் அப்பக்குறைவையும் கட்டளையிட்டேன்; ஆகிலும் நீங்கள் என்னிடத்தில் திரும்பாமற்போனீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. இதுவுமல்லாமல், அறுப்புக்காலம் வர இன்னும் மூன்றுமாதம் இருக்கும்போதே மழையை நான் தடுத்தேன், ஒரு பட்டணத்தின்மேல் மழைபெய்யவும் ஒரு பட்டணத்தின்மேல் மழை பெய்யாமலிருக்கவும் பண்ணினேன்; ஒரு வயலின்மேல் மழைபெய்தது, மழைபெய்யாத மற்ற வயல் காய்ந்து போயிற்று.
	<br /><br />
	8. இரண்டு மூன்று பட்டணங்களின் மனுஷர் தண்ணீர் குடிக்க ஒரே பட்டணத்துக்குப் போய் அலைந்தும் தாகந்தீர்த்துக்கொள்ளவில்லை; ஆகிலும் நீங்கள் என்னிடத்தில் திரும்பாமற்போனீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. கருக்காயினாலும் விஷப்பனியினாலும் உங்களை தண்டித்தேன்; உங்கள் சோலைகளிலும் திராட்சத்தோட்டங்களிலும் அத்திமரங்களிலும் ஒலிவமரங்களிலும் மிகுதியானதைப் பச்சைப்புழு அரித்துப்போட்டது; ஆகிலும் என்னிடத்தில் திரும்பாமற்போனீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. எகிப்திலே உண்டானதற்கு ஒத்த கொள்ளைநோயை உங்களுக்குள் அனுப்பினேன்; உங்கள் வாலிபரைப் பட்டயத்தாலே கொன்றேன்; உங்கள் குதிரைகளை அழித்துப்போட்டேன்; உங்கள் பாளயங்களின் நாற்றத்தை உங்கள் நாசிகளிலும் ஏறப்பண்ணினேன்; ஆகிலும் நீங்கள் என்னிடத்தில் திரும்பாமற்போனீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. சோதோமையும் கொமோராவையும் தேவன் கவிழ்த்துப்போட்டது போல, உங்களைக் கவிழ்த்துப்போட்டேன்; நீங்கள் அக்கினியினின்று பறிக்கப்பட்ட கொள்ளியைப்போல இருந்தீர்கள்; ஆகிலும் நீங்கள் என்னிடத்தில் திரும்பாமற்போனீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. ஆகையால் இஸ்ரவேலே, இந்தப்பிரகாரமாக உனக்குச் செய்வேன்; இஸ்ரவேலே, நான் இப்படி உனக்குச் செய்யப்போகிறபடியினால் உன் தேவனைச் சந்திக்கும்படி ஆயத்தப்படு.
	<br /><br />
	13. அவர் பர்வதங்களை உருவாக்கினவரும், காற்றைச் சிருஷ்டித்தவரும், மனுஷனுடைய நினைவுகள் இன்னதென்று அவனுக்கு வெளிப்படுத்துகிறவரும், விடியற்காலத்தை அந்தகாரமாக்குகிறவரும், பூமியினுடைய உயர்ந்த ஸ்தானங்களின்மேல் உலாவுகிறவருமாயிருக்கிறார்; சேனைகளின் தேவனாகிய கர்த்தர் என்பது அவருடைய நாமம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos4