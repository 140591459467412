import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 8 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 8 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 8 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அன்றியும் சகோதரரே, மக்கெதோனியா நாட்டுச் சபைகளுக்குத் தேவன் அளித்த கிருபையை உங்களுக்கு அறிவிக்கிறோம்.
	<br /><br />
	2. அவர்கள் மிகுந்த உபத்திரவத்தினாலே சோதிக்கப்படுகையில், கொடிய தரித்திரமுடையவர்களாயிருந்தும், தங்கள் பரிபூரண சந்தோஷத்தினாலே மிகுந்த உதாரத்துவமாய்க் கொடுத்தார்கள்.
	<br /><br />
	3. மேலும் அவர்கள் தங்கள் திராணிக்குத் தக்கதாகவும், தங்கள் திராணிக்கு மிஞ்சியும் கொடுக்க, தாங்களே மனதுள்ளவர்களாயிருந்தார்களென்பதற்கு, நான் சாட்சியாயிருக்கிறேன்.
	<br /><br />
	4. தங்கள் உபகாரத்தையும், பரிசுத்தவான்களுக்குச் செய்யப்படும் தர்ம ஊழியத்தின் பங்கையும் நாங்கள் ஏற்றுக்கொள்ளும்படி அவர்கள் எங்களை மிகவும் வேண்டிக்கொண்டார்கள்.
	<br /><br />
	5. மேலும் நாங்கள் நினைத்தபடிமாத்திரம் கொடாமல், தேவனுடைய சித்தத்தினாலே முன்பு தங்களைத்தாமே கர்த்தருக்கும், பின்பு எங்களுக்கும் ஒப்புக்கொடுத்தார்கள்.
	<br /><br />
	6. ஆதலால் தீத்து இந்தத் தர்மகாரியத்தை உங்களிடத்தில் தொடங்கினபடியே, அதை முடிக்கவும் வேண்டுமென்று அவனைக் கேட்டுக்கொண்டோம்.
	<br /><br />
	7. அல்லாமலும், விசுவாசத்திலும், போதிப்பிலும், அறிவிலும், எல்லாவித ஜாக்கிரதையிலும், எங்கள்மேலுள்ள உங்கள் அன்பிலும், மற்றெல்லாக் காரியங்களிலும், நீங்கள் பெருகியிருக்கிறதுபோல, இந்தத் தர்மகாரியத்திலும் பெருகவேண்டும்.
	<br /><br />
	8. இதை நான் கட்டளையாகச் சொல்லாமல், மற்றவர்களுடைய ஜாக்கிரதையைக்கொண்டு, உங்கள் அன்பின் உண்மையைச் சோதிக்கும்பொருட்டே சொல்லுகிறேன்.
	<br /><br />
	9. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் கிருபையை அறிந்திருக்கிறீர்களே; அவர் ஐசுவரியமுள்ளவராயிருந்தும், நீங்கள் அவருடைய தரித்திரத்தினாலே ஐசுவரியவான்களாகும்படிக்கு, உங்கள் நிமித்தம் தரித்திரரானாரே.
	<br /><br />
	10. இதைக்குறித்து என் யோசனையை உங்களுக்கு அறிவிக்கிறேன்; இதைச் செய்கிறதற்கு மாத்திரமல்ல, செய்யவேண்டுமென்று உற்சாகப்படுகிறதற்கும் ஒரு வருஷமாய் ஆரம்பம்பண்ணின உங்களுக்கு இது தகுதியாயிருக்கும்.
	<br /><br />
	11. ஆகையால் அதை இப்பொழுது செய்து நிறைவேற்றுங்கள்; கொடுக்கவேண்டும் என்கிற விருப்பமுண்டாயிருந்ததுபோல, உங்களுக்கு உள்ளவைகளில் எடுத்து அதை நிறைவேற்றுதலும் உண்டாவதாக.
	<br /><br />
	12. ஒருவனுக்கு மனவிருப்பமிருந்தால், அவனுக்கு இல்லாததின்படியல்ல, அவனுக்கு உள்ளதின்படியே அங்கிகரிக்கப்படும்.
	<br /><br />
	13. மற்றவர்களுக்குச் சகாயமும் உங்களுக்கு வருத்தமும் உண்டாகும்படியல்ல, சமநிலையிருக்கும்படியாகவே சொல்லுகிறேன்.
	<br /><br />
	14. எப்படியெனில், மிகுதியாய்ச் சேர்த்தவனுக்கு அதிகமானதுமில்லை, கொஞ்சமாய்ச் சேர்த்தவனுக்குக் குறைவானதுமில்லை என்று எழுதியிருக்கிறபிரகாரம்,
	<br /><br />
	15. சமநிலைப் பிரமாணத்தின்படியே, அவர்களுடைய செல்வம் உங்கள் வறுமைக்கு உதவும்படிக்கு இக்காலத்திலே உங்களுடைய செல்வம் அவர்களுடைய வறுமைக்கு உதவுவதாக.
	<br /><br />
	16. அன்றியும் உங்களுக்காக இப்படிப்பட்ட ஜாக்கிரதை உண்டாயிருக்கும்படி தீத்துவின் இருதயத்தில் அருளின தேவனுக்கு ஸ்தோத்திரம்.
	<br /><br />
	17. நாங்கள் கேட்டுக்கொண்டதை அவன் அங்கிகரித்ததுமல்லாமல், அவன் அதிக ஜாக்கிரதையாயிருந்து, தன் மனவிருப்பத்தின்படியே உங்களிடத்திற்கு வரப் புறப்பட்டான்.
	<br /><br />
	18. சுவிசேஷ ஊழியத்தில் எல்லாச் சபைகளிலும் புகழ்ச்சிபெற்ற ஒரு சகோதரனை அவனோடேகூட அனுப்பியிருக்கிறோம்.
	<br /><br />
	19. அதுமாத்திரமல்ல, கர்த்தருக்கு மகிமையுண்டாகவும், உங்கள் மனவிருப்பம் விளங்கவும், எங்கள் ஊழியத்தினாலே சேர்க்கப்படும் தர்மப்பணத்தைக் கொண்டுபோகையில், எங்களுக்கு வழித்துணையாயிருக்கும்படி, அவன் சபைகளால் தெரிந்து ஏற்படுத்தப்பட்டவனாயும் இருக்கிறான்.
	<br /><br />
	20. எங்கள் ஊழியத்தினாலே சேர்க்கப்படும் இந்த மிகுதியான தர்மப்பணத்தைக்குறித்து ஒருவனும் எங்களைக் குற்றப்படுத்தாதபடிக்கு நாங்கள் எச்சரிக்கையாயிருந்து,
	<br /><br />
	21. கர்த்தருக்கு முன்பாகமாத்திரமல்ல, மனுஷருக்கு முன்பாகவும் யோக்கியமானவைகளைச் செய்ய நாடுகிறோம்.
	<br /><br />
	22. மேலும், அநேக காரியங்களில் ஜாக்கிரதையுள்ளவனென்று நாங்கள் பலமுறை கண்டறிந்தவனும், இப்பொழுது உங்கள்மேலுள்ள மிகுந்த நம்பிக்கையினாலே அதிக ஜாக்கிரதையுள்ளவனுமாகிய நம்முடைய சகோதரனையும் இவர்களோடே கூட அனுப்பியிருக்கிறோம்.
	<br /><br />
	23. தீத்துவைக்குறித்து ஒருவன் விசாரித்தால், அவன் எனக்குக் கூட்டாளியும், உங்களுக்காக என் உடன்வேலையாளுமாயிருக்கிறானென்றும்; எங்கள் சகோதரரைக்குறித்து ஒருவன் விசாரித்தால், அவர்கள் சபைகளுடைய ஸ்தானாபதிகளும், கிறிஸ்துவுக்கு மகிமையுமாயிருக்கிறார்களென்றும் அறியக்கடவன்.
	<br /><br />
	24. ஆதலால் உங்கள் அன்பையும், நாங்கள் உங்களைக்குறித்துச் சொன்ன புகழ்ச்சியையும், சபைகளுக்கு முன்பாக அவர்களுக்குத் திருஷ்டாந்தப்படுத்துங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians8