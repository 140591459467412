import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 11 </title>
	<meta
          name="description"
          content="Ezekiel 11 | எசேக்கியேல்  11 |
          Ezekiel 11 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு ஆவியானவர் என்னை எடுத்து, என்னைக் கர்த்தருடைய ஆலயத்தின் கிழக்கு முகமாயிருக்கிற வாசலுக்குக் கொண்டுபோனார்; இதோ, அந்த வாசலின் நடையில் இருபத்தைந்து புருஷர் இருந்தார்கள்; அவர்களின் நடுவே ஜனத்தின் பிரபுக்களாகிய ஆசூரின் குமாரனாகிய யசனியாவையும், பெனாயாவின் குமாரனாகிய பெலத்தியாவையும் கண்டேன்.
	<br /><br />
	2. அப்பொழுது அவர் என்னை நோக்கி: மனுபுத்திரனே, இவர்கள் இந்த நகரத்திலே அக்கிரமமான நினைவுகளை நினைத்து, துராலோசனை சொல்லுகிற மனுஷர்.
	<br /><br />
	3. இது வீடுகளைக் கட்டுவதற்குக் காலமல்ல என்றும், இந்த நகரம் பானை, நாங்கள் அதிலுள்ள இறைச்சியென்றும் சொல்லுகிறார்கள்.
	<br /><br />
	4. ஆகையால் அவர்களுக்கு விரோதமாய்த் தீர்க்கதரிசனஞ்சொல்லு, மனுபுத்திரனே, தீர்க்கதரிசனஞ்சொல்லு என்றார்.
	<br /><br />
	5. அப்பொழுது கர்த்தருடைய ஆவி என்மேல் இறங்கினார்; அவர் என்னை நோக்கி: நீ சொல்லவேண்டியது என்னவென்றால், இஸ்ரவேல் வம்சத்தாரே, நீங்கள் இப்படிப் பேசுகிறது உண்டு; உங்கள் மனதில் எழும்புகிறதை நான் அறிவேன்.
	<br /><br />
	6. இந்த நகரத்தில் நீங்கள் அநேகரைக் கொலைசெய்தீர்கள்; அதின் வீதிகளைக் கொலையுண்டவர்களால் நிரப்பினீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. ஆகையால் நீங்கள் கொலைசெய்து, அதின் நடுவிலே போட்டுவிட்டவர்களே இறைச்சியும், இந்த நகரம் பானையுமாமே; உங்களையோ அதற்குள் இராதபடிக்குப் புறம்பாக்குவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	8. பட்டயத்துக்குப் பயப்பட்டீர்கள், பட்டயத்தையே உங்கள்மேல் வரப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	9. நான் உங்களை அதற்குள் இராதபடிக்குப் புறம்பாக்கி, உங்களை அந்நியரின் கையில் ஒப்புக்கொடுத்து, உங்களில் நியாயத்தீர்ப்புகளை நிறைவேற்றுவேன்.
	<br /><br />
	10. பட்டயத்தால் விழுவீர்கள்; இஸ்ரவேல் தேசத்தின் எல்லையிலே உங்களை நியாயந்தீர்ப்பேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	11. இந்த நகரம் உங்களுக்குப் பானையாயிருப்பதுமில்லை, நீங்கள் அதிலுள்ள இறைச்சியாயிருப்பதுமில்லை; இஸ்ரவேல் தேசத்தின் எல்லையிலே உங்களை நியாயந்தீர்ப்பேன்.
	<br /><br />
	12. என் கட்டளைகளின்படி நடவாமலும், என் நியாயங்களின்படி செய்யாமலும், உங்களைச் சுற்றிலுமிருக்கிற புறஜாதிகளுடைய முறைமைகளின்படி செய்த நீங்கள் அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள் என்று சொல் என்றார்.
	<br /><br />
	13. நான் இப்படித் தீர்க்கதரிசனஞ்சொல்லுகையில், பெனாயாவின் குமாரனாகிய பெலத்தியா செத்தான்; அப்பொழுது நான் முகங்குப்புற விழுந்து, மகா சத்தமாய்: ஆ, கர்த்தராகிய ஆண்டவரே, தேவரீர் இஸ்ரவேலில் மீதியானவர்களைச் சர்வசங்காரஞ்செய்வீரோ என்று முறையிட்டேன்.
	<br /><br />
	14. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	15. மனுபுத்திரனே, நீங்கள் கர்த்தரை விட்டுத் தூரமாய்ப் போங்கள், எங்களுக்கு இந்தத் தேசம் சுதந்தரமாகக் கொடுக்கப்பட்டதென்று, உன் சகோதரருக்கும், உன் குடும்பத்தாருக்கும், உன் பந்து ஜனங்களுக்கும், இஸ்ரவேல் வம்சத்தார் அனைவருக்கும், எருசலேமின் குடிகள் சொல்லுகிறார்கள்.
	<br /><br />
	16. ஆகையால் நான் அவர்களைத் தூரமாகப் புறஜாதிகளுக்குள்ளே துரத்தியிருந்தாலும், நான் அவர்களைத் தேசங்களிலே சிதறடித்திருந்தாலும், நான் அவர்கள் போன தேசங்களில் அவர்களுக்குக் கொஞ்சக்காலத்துக்குப் பரிசுத்த ஸ்தலமாக இருப்பேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல்.
	<br /><br />
	17. ஆதலால் நான் உங்களை ஜனங்களிடத்திலிருந்து சேர்த்து, நீங்கள் சிதறடிக்கப்பட்ட தேசங்களிலிருந்து உங்களைக் கூட்டிக்கொண்டு, இஸ்ரவேல் தேசத்தை உங்களுக்குக் கொடுப்பேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல்லு.
	<br /><br />
	18. அவர்கள் அங்கே வந்து, அதில் சீயென்றிகழப்படத்தக்கதும் அருவருக்கப்படத்தக்கதுமாயிருக்கிறதையெல்லாம் அதிலிருந்து அகற்றுவார்கள்.
	<br /><br />
	19. அவர்கள் என் கட்டளைகளின்படி நடந்து, என் நியாயங்களைக் கைக்கொண்டு, அவைகளின்படி செய்ய நான் அவர்களுக்கு ஏக இருதயத்தைத் தந்து, அவர்கள் உள்ளத்தில் புதிய ஆவியைக் கொடுத்து, கல்லான இருதயத்தை அவர்கள் மாம்சத்திலிருந்து எடுத்துப்போட்டு, சதையான இருதயத்தை அவர்களுக்கு அருளுவேன்.
	<br /><br />
	20. அவர்கள் என் ஜனமாயிருப்பார்கள், நான் அவர்கள் தேவனாயிருப்பேன்.
	<br /><br />
	21. ஆனாலும் சீயென்றிகழப்படத்தக்கதும் அருவருக்கப்படத்தக்கதுமான தங்கள் இருதயத்தின் இச்சையிலே எவர்கள் நடக்கிறார்களோ அவர்களுடைய வழியின் பலனை அவர்கள் தலைகளின்மேல் சுமரப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	22. அப்பொழுது கேருபீன்கள் தங்கள் செட்டைகளை விரித்து எழும்பின; சக்கரங்களும் அவைகளுக்கு அருகே சென்றன; இஸ்ரவேலின் தேவனுடைய மகிமை அவைகளின்மேல் உயர இருந்தது.
	<br /><br />
	23. கர்த்தருடைய மகிமை நகரத்தின் நடுவிலிருந்தெழும்பி, நகரத்துக்குக் கிழக்கே இருக்கிற மலையின்மேல் போய் நின்றது.
	<br /><br />
	24. பின்பு ஆவியானவர் என்னை எடுத்து, என்னை தேவனுடைய ஆவிக்குள்ளான தரிசனத்திலே கல்தேயாவுக்குச் சிறைப்பட்டுப்போனவர்கள் இடத்திலே கொண்டுபோய் விட்டார்; அப்பொழுது நான் கண்ட தரிசனம் என்னிலிருந்து எடுபட்டுப்போயிற்று.
	<br /><br />
	25. கர்த்தர் எனக்குக் காண்பித்த யாவையும் சிறையிருப்பிலிருந்தவர்களுக்குச் சொன்னேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial11