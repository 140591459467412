import React from 'react';
import '../.././App.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Jonah (){
return (
    <>
    <Helmet>
        <title> Tamil Bible | யோனா  </title>
        <meta
          name="description"
          content="Jonah | யோனா   |
          Yona Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
      </Helmet>
    <div className='body'>
        <h2 className='chapterh' align="center"> யோனா </h2>
        <div className='chapter'>
        <div className='introduction'>
        <p className='article content'>
            {/* Introductions of Tamilbible verses */}
            <ol>
	<li>
		<h1>
			யோனா
		</h1>
		<p>
			&nbsp;
		</p>
		<h2>
			1. அமைப்பு:
		</h2>
		<p>
			யோனா என்ற பெயருக்கு புறா என்று அர்த்தமாகும். பழையஏற்பாட்டில், சரித்திரப்புத்தகங்களில் குறிப்பிடப்பட்டுள்ள தீர்க்கதரிகளின் சிறிய புத்தகங்களில் இதுவும் ஒன்றாகும்.
		</p>
		<p>
			2ராஜா-14: 25 காதேப்பேர் ஊரானாகிய அமித்தாய் என்னும் தீர்க்கதரிசியின் குமாரன் யோனா என்னும் தம்முடைய ஊழியக் காரனைக்கொண்டு இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லியிருந்த வார்த்தையின்படியே, அவன் ஆமாத்தின் எல்லை முதற்கொண்டு சமபூமியின் கடல் மட்டுமுள்ள இஸ்ரவேலின் எல்லைகளைத் திரும்பச் சேர்த்துக்கொண்டான்.
		</p>
		<p>
			இந்தப் புத்தகம் இஸ்ரவேலில் ஆரம்பித்து, கடலுக்குள் சென்று (மத்திய தரைக்கடல்), நினைவேயில் முடிகிறது. இந்தச் சம்பவம் எந்த ஆண்டில் நடைபெற்றது என்பதற்கு எந்தவித வேதாகம ஆதாரமும் நமக்கு இல்லை. ஆனாலும் கி.மு.773 முதல் 755ல் ஆட்சிசெய்த 3ஆம் அஷூர்தனின் நாட்களில் நினிவேயில் ஒரு எழுப்புதல் நடைபெற்றது என்று நம்பப்படுகிறது. இது அநேகரால் நேசிக்கப்படுகிற, நன்கு அறியப்பட்ட புத்தகமாக இருக்கிறது. இந்தப் புத்தகத்திலிருந்து அநேக பாடங்கள் கற்றுக்கொள்ளப்பட்டு, பிறருக்கும் கற்றுக் கொடுக்கப்படமுடியும்.
		</p>
		<h3>
			உதாரணத்திற்கு:
		</h3>
		<ol>
			<li>
				யோனாவும் கப்பலும்
			</li>
			<li>
				யோனாவும் புயல்காற்றும்
			</li>
			<li>
				யோனாவும் மீனும்
			</li>
			<li>
				யோனாவும் நினிவேயும்
			</li>
			<li>
				யோனாவும் செடியும்.
			</li>
			<li>
				யோனா:
			</li>
		</ol>
		<p>
			யோனா அம்மித்தாயின் குமாரனாவர். இவர் காதேப்பேர் ஊரைச் சேர்ந்தவர் என்று 2ராஜாக்கள்-14: 25ல் பார்த்தோம். காதேப்பேர் என்பது செபுலோனின் தேசத்தில் இருந்த ஊராகும். எனவே யோனா செபுலோன் கோத்திரத்தைச் சேர்ந்தவராக இருந்திருப்பார். அப்படியென்றால், யோனா ஒரு ஆசாரியனாக இருந்திருக்கவும் வாய்ப்பிருக்கிறது. காதேப்பேர் நாசரேத்திலிருந்து வடக்கே 2 மைல் தொலைவில் உள்ள ஊராகும். ஆமோஸ், ஓசியா மற்றும் 2ஆம் யெரொபெயாமின் காலத்தில் யோனாவும் வாழ்ந்தவார். தேவன் யோனாவை அழைத்தபோது அவர் இஸ்ரவேலில் இருந்தார். ஆனாலும் தேவனைவிட்டு அவர் மிகவும் தூரமாக விலகி ஓடினார் என்பது நமக்குத் தெரியும். யோனா-1: 3-4ல் அவர் கடலில் இருப்பதை நாம் பார்க்கிறோம்.
		</p>
		<p>
			யோனா-1: 3-4 அப்பொழுது யோனா கர்த்தருடைய சமுகத்தினின்று விலகி, தர்ஷீசுக்கு ஓடிப்போகும்படி எழுந்து, யோப்பாவுக்குப் போய், தர்ஷீசுக்குப் போகிற ஒரு கப்பலைக்கண்டு, கூலி கொடுத்து, தான் கர்த்தருடைய சமுகத்தினின்று விலகும்படி, அவர்களோடே தர்ஷீசுக்குப் போகக் கப்பல் ஏறினான். 4. கர்த்தர் சமுத்திரத்தின்மேல் பெருங்காற்றை வரவிட்டார்: அதினால் கடலிலே கப்பல் உடையுமென்று நினைக்கத்தக்க பெரிய கொந்தளிப்பு உண்டாயிற்று. புராணங்களின்படி, யோனா சாரிபாத் ஊர் விதவையின் மகன் என்று சிலரால் சொல்லப்படுகிறது. ஆனால் அது உண்மையாக இருக்கவாய்ப்பில்லை. காரணம் இது உண்மையாக இருக்குமெனில், அவருக்கு 100 வயது கணக்கு வருகிறது. எனவே இதற்கு வாய்ப்பில்லை.
		</p>
		<h2>
			3. செய்தி:
		</h2>
		<p>
			மற்ற தீர்க்கதரிசிகளின் புத்தகங்களிலிருந்து இது முற்றிலும் மாறுபட்ட புத்தகமாக இருக்கிறது. காரணம் இது ஒரு குறிப்பட்ட தீர்க்கதரிசனத்தை முக்கியப்படுத்தாமல், யோனா தீர்க்கதரிசியின் வாழ்வில் நடந்த சம்பவத்தைக் குறித்ததாக இருக்கிறது. எனவே இது ஒரு செய்தி அல்ல, மாறாக ஒரு சரித்திர சம்பவமாகும். இந்தப் புத்தகம் குறிப்பாக தேவன் புறஜாதியாரை சந்திக்கும் சத்தியத்தை, அவருடைய இரக்கத்தை, மன்னிக்கும் தன்மையை, அவருடைய பரிதபிக்கும் குணாதிசயத்தை மிகஅழகாகப் படம்பிடித்துக் காட்டுகிறது. தேவனுடைய சித்தமும் மனிதனுடைய சித்தமும் எவ்வாறு இடைபடுகின்றன என்பதைக்குறித்த சிறந்த படைப்பாக இந்தப் புத்தகம் இருக்கிறது. முடிவில் விருப்பமற்ற ஒரு தீர்க்கதரிசியால் தேவனுடைய விருப்பம் (சித்தம்) நிறைவேற்றப்பட்டது.
		</p>
		<p>
			இந்தப் புத்தகத்தின் செய்தி நமக்கு மிகவும் முக்கியமானதாக இருக்கிறது. இரட்சிக்கப்படாத புறஜாதி மக்களைக்குறித்த பாரம் நமக்குள்ளே உண்டாகவேண்டும். தேவன் நம்மை நேசிப்பதைப்போலவே, இரட்சிக்கப்படாத புறஜாதியாரையும் நேசிக்கிறார். அவர்களும் இரட்சிக்கப்பட வேண்டும் என்று அவர் ஏங்குகிறார். புறஜாதியாரையும் தேவன் நேசிக்கிறார் என்பது யூதர்களால் ஏற்றுக்கொள்ளப்பட முடியாத ஒன்றாக இருந்தது.
		</p>
		<h2>
			யோனாவின் செய்தி 2 பகுதியாகப் பிரிக்கப்படலாம்:
		</h2>
		<ol>
			<li>
				அதிகாரம் 1 முதல் 2: யோனாவுக்குரிய முதல் அழைப்பு (கீழ்ப்படியவில்லை)
			</li>
		</ol>
		<p>
			யோனா-1: 2 நீ எழுந்து மகாநகரமாகிய நினிவேக்குப் போய், அதற்கு விரோதமாகப் பிரசங்கி, அவர்களுடைய அக்கிரமம் என் சமுகத்தில் வந்து எட்டினது என்றார்.
		</p>
		<p>
			நினிவே மிகப்பெரிய நகரமாக, அதிகமான பாவங்களும், அக்கிரமங்களும் காணப்பட்ட இடமாக இருந்தது. இது அசீரியாவின் தலைநகரமாகமாகும். அசீரியா என்றாலே முழு உலகமும் நடுங்குமளவுக்கு அது பயங்கரமானதாக இருந்து கொண்டிருந்தது. பகைவர்களை கொடூரமாக சித்திரவதைசெய்து, கொல்வதில் இவர்கள் பெயர்போனவர்களாக இருந்தார்கள். குற்றவாளிகளைத் தோலைஉறித்தல், மனிததோலை தங்கள் இருக்கையின் விரிப்புக்குப் பயன்படுத்துதல், சிறைபிடிக்கப்பட்ட பெண்களை கொடுமையாக அவமானப்படுத்திக் கெடுத்தல், தாங்கள் தோற்கடித்த பகுதியின் மக்களுடைய தலைகளை ஊரின் நுழைவாயிலில் குவித்துவைத்தல் போன்றவற்றைச் செய்தார்கள். 20 அடி உயரம். 40 அடி அகலத்திற்கு மண்டைஓடுகளைக் குவித்து வைத்திருந்தார்கள் என்றால், எத்தனை ஆயிரக்கணக்கான நபர்களின் தலைகள் என்று பாருங்கள்!
		</p>
		<p>
			யோனா-1: 3 அப்பொழுது யோனா கர்த்தருடைய சமுகத்தினின்று விலகி, தர்ஷீசுக்கு ஓடிப்போகும்படி எழுந்து, யோப்பாவுக்குப் போய், தர்ஷீசுக்குப் போகிற ஒரு கப்பலைக்கண்டு, கூலி கொடுத்து, தான் கர்த்தருடைய சமுகத்தினின்று விலகும்படி, அவர்களோடே தர்ஷீசுக்குப் போகக் கப்பல் ஏறினான்.
		</p>
		<p>
			தர்ஷீசு என்பது ஸ்பெயின் அல்லது பிரட்டீஷ் தீவுகளைக் குறிக்கிறது. அதாவது நினிவேக்கு நேர்எதிராக இருக்கும் ஊராகும். தேவனுக்கு எதிராகச்செல்லுதல், கர்த்தருடைய சமூகத்தினின்று விலகிச்செல்லுதல், நம்முடைய பிரச்சினைக்குத் தீர்வை அல்ல, மாறாக பிரச்சினையை இன்னும் அதிகமாக்கத்தான் செய்யும். அது நம்மை இன்னும் கீழாகத்தான் கொண்டுசெல்லும் என்பதை மறக்கவேண்டாம். யோனா-1: 5 யோனாவோவென்றால் கப்பலின் கீழ்த்தட்டில் இறங்கிபோய்ப் படுத்துக்கொண்டு, அயர்ந்த நித்திரை பண்ணினான்.
		</p>
		<p>
			சங்-139: 7-8 உம்முடைய ஆவிக்கு மறைவாக எங்கே போவேன்? உம்முடைய சமுகத்தைவிட்டு எங்கே ஓடுவேன்? 8. நான் வானத்திற்கு ஏறினாலும், நீர் அங்கே இருக்கிறீர், நான் பாதாளத்தில் படுக்கை போட்டாலும், நீர் அங்கேயும் இருக்கிறீர்.
		</p>
		<p>
			தேவனுடைய சமூகத்திற்கு விலகி நம்மால் எங்குமே செல்லமுடியாது எனபதுதான் உண்மை.
		</p>
		<p>
			யோனா நினிவேக்குப் போகாமல் விலகிச்சென்றதற்கு 2 காரணங்கள் கருதப்படுகின்றன:
		</p>
		<ol>
			<li>
				நினிவே பட்டணத்தாரின் கொடூரமான சுபாவம், தேவனால் அழிவுவருகிறது என்று பிரசங்கித்தால், தனக்கு ஆபத்தைப் கொண்டுவரும் என்று பயந்து அங்கே செல்வதைத் தவிர்த்திருக்கலாம்.
			</li>
			<li>
				யூதர்கள் அனைவரும் மற்ற உலகத்தாரை அதிகமாக வெறுத்தார்கள், நினிவே பட்டணத்தார் மனந்திரும்பி அழிவின் தண்டனைக்குத் தப்புவதை யோனாவும் விரும்பாததால், அவர் அங்கே செல்வதைத் தவிர்த்திருக்கலாம்.
			</li>
		</ol>
		<h5>
			உண்மைக் காரணத்தை யோனாவின் புத்தகமே கொடுக்கிறது:
		</h5>
		<p>
			யோனா-4: 2 கர்த்தரை நோக்கி விண்ணப்பம்பண்ணி: ஆ கர்த்தாவே, நான் என் தேசத்தில் இருக்கும்போதே நான் இதைச் சொல்லவில்லையா? இதினிமித்தமே நான் முன்னமே தர்ஷீசுக்கு ஓடிப்போனேன், நீர் இரக்கமும், மனஉருக்கமும், நீடியசாந்தமும், மிகுந்த கிருபையுமுள்ளவரும், தீங்குக்கு மனஸ்தாபப் படுகிறவருமான தேவனென்று அறிவேன்.
		</p>
		<p>
			மக்கள் மனந்திரும்பும்போது, தேவன் தாம் செய்யநினைத்திருந்த தீங்கைச் செய்யாதபடி, தன் முடிவை மாற்றுவதை யோனாவால் ஏற்றுக்கொள்ள முடியவில்லை. சத்தியம் தவறுக்காக தண்டிப்பேன் என்று சொல்கிறது. கிருபை தவறுக்காக மன்னிப்பேன் என்று சொல்கிறது. சத்தியமும் கிருபையும், தவறுக்காக மனந்திரும்பிடு, மன்னிக்கப்படுவாய் என்று சொல்கிறது. மதங்கள் சத்தியத்தின் தன்மையோடும், மனுஷீகக் கொள்கைகளும் (புதிய யுகம், விடுதலை இயக்கங்கள், சர்வசமய சங்கம்) மற்றும் கிருபையின் உபதேசங்களும் கிருபையின் தன்மையோடும் செயல்படுகின்றன. கிறிஸ்தவமோ சத்தியமும் கிருபையும் என்ற தன்மையில் செயல்படுகிறது.
		</p>
		<p>
			↻ யோவா-1: 14 அந்த வார்த்தை மாம்சமாகி, கிருபையினாலும் சத்தியத்தினாலும் நிறைந்தவராய், நமக்குள்ளே வாசம் பண்ணினார்.
		</p>
		<p>
			↻ எபே-4: 15 அன்புடன் (கிருபை) சத்தியத்தைக் கைக்கொண்டு, தலையாகிய கிறிஸ்துவுக்குள் எல்லாவற்றிலேயும், நாம் வளருகிறவர்களாய் இருக்கும்படியாக அப்படிச் செய்தார்.
		</p>
	</li>
</ol>
<ol>
	<li>
		<ol>
			<li>
				அதிகாரம் 3 முதல் 4 யோனாவுக்குரிய இரண்டாவது அழைப்பு: (கீழ்ப்படிந்தான். மீனின் வயிற்றுக்குள் யோனா பட்டபாடு, இந்தமுறை அவனை உடனே செல்லவைத்தது).
			</li>
		</ol>
		<p>
			யோனா-3: 2 நீ எழுந்து மகாநகரமாகிய நினிவேக்குப் போய், நான் உனக்குக் கற்பிக்கும் வார்த்தையை அதற்கு விரோதமாய்ப் பிரசங்கி என்றார்.
		</p>
		<h5>
			இயேசுவுக்கு அடையாளமாக யோனாவின் 2 சம்பவங்கள்:
		</h5>
		<ol>
			<li>
				பிரசங்கம் (மனந்திரும்புதலைக் குறித்து)
			</li>
			<li>
				மீனின் வயிற்றிலே 3 பகல் 3 இரவுகள் (72 மணிநேரம்- கல்லறையில் இயேசுவின் சரீரம்)
			</li>
		</ol>
		<p>
			லூக்-11: 30,32 யோனா நினிவே பட்டணத்தாருக்கு அடையாளமாய் இருந்ததுபோல, மனுஷகுமாரனும் இந்தச் சந்ததிக்கு அடையாளமாய் இருப்பார். 32. யோனாவின் பிரசங்கத்தைக் கேட்டு நினிவே பட்டணத்தார் மனந் திரும்பினார்கள், இதோ, யோனாவிலும் பெரியவர் இங்கே இருக்கிறார், ஆதலால் நியாயத் தீர்ப்புநாளிலே நினிவே பட்டணத்தார் இந்தச் சந்ததியாரோடு எழுந்து நின்று, இவார்கள்மேல் குற்றம் சுமத்துவார்கள்.
		</p>
		<p>
			மத்-16: 4 இந்தப் பொல்லாத விபச்சார சந்ததியார் அடையாளம் தேடுகிறார்கள், யோனா தீர்க்கதரிசியின் அடையாளமேயன்றி வேறேஅடையாளம் இவர்களுக்குக் கொடுக்கப் படுவதில்லை என்று சொல்லி, அவர்களை விட்டுப் புறப்பட்டுப்போனார்.
		</p>
		<p>
			மத்-12: 38-41 அப்பொழுது, வேதபாரகரிலும் பரிசேயரிலும் சிலர் அவரை நோக்கி: போதகரே, உம்மால் ஒரு அடையாளத்தைக் காணவிரும்புகிறோம் என்றார்கள். 39. அவர்களுக்கு அவர் பிரதியுத்தரமாக: இந்தப் பொல்லாத விபசாரச் சந்ததியார் அடையாளத்தைத் தேடுகிறார்கள், ஆனாலும் யோனா தீர்க்கதரிசியின் அடையளமேயன்றி வேறே அடையாளம் இவர்களுக்குக் கொடுக்கப் படுவதில்லை. 40. யோனா இரவும் பகலும் மூன்றுநாள் ஒரு பெரிய மீனின் வயிற்றில் இருந்ததுபோல, மனுஷகுமாரனும் இரவும் பகலும் மூன்றுநாள் பூமியின் இருதயத்தில் இருப்பார். 41. யோனாவின் பிரசங்கத்தைக் கேட்டு நினிவே பட்டணத்தார் மனந்திரும்பினார்கள். இதோ, யோனாவிலும் பெரியவர் இங்கே இருக்கிறார். ஆதலால் நியாயத் தீர்ப்புநாளிலே நினிவே பட்டணத்தார் இந்தச் சந்ததியாரோடு எழுந்து நின்று இவார்கள்மேல் குற்றம் சுமத்துவார்கள்.
		</p>
		<h2>
			யோனாவின் தொகுப்பு:
		</h2>
		<p>
			(4 அதிகாரங்கள் உள்ளன. ஒவ்வொரு அதிகாரத்திலும், யோனாவின் சுபாவத்தையும், தேவனுடைய இரக்கத்தின் குணாதிசயத்தையும் நாம் பார்க்கலாம்)
		</p>
		<ol>
			<li>
				அதிகாரம்-1
			</li>
			<li>
				யோனாவின் கீழ்படியாமை
			</li>
			<li>
				தேவனுடைய இரக்கம் பின்தொடருதல்
			</li>
		</ol>
		<p>
			யோனா-1: 17 யோனாவை விழுங்கும்படி ஒரு பெரியமீனைக் கர்த்தர் ஆயத்தப் படுத்தியிருந்தார், அந்த மீன் வயிற்றிலே யோனா இராப்பகல் மூன்று நாள் இருந்தான்.
		</p>
		<ol>
			<li>
				அதிகாரம்-2
			</li>
			<li>
				யோனாவின் சுயநல ஜெபம்:
			</li>
		</ol>
		<p>
			யோனா-2: 2,7 என் நெருக்கத்தில் நான் கர்த்தரை நோக்கிக் கூப்பிட்டேன், அவர் எனக்கு உத்தரவு அருளினார், நான் பாதாளத்தின் வயிற்றிலிருந்து கூக்குரலிட்டேன், நீர் என் சத்தத்தைக் கேட்டீர். 7. என் ஆத்துமா என்னில் தொய்ந்து போகையில் கர்த்தரை நினைத்தேன், அப்பொழுது என் விண்ணப்பம் உமது பரிசுத்த ஆலயத்திலே உம்மிடத்தில் வந்து சேர்ந்தது.
		</p>
		<ol start="2">
			<li>
				தேவனுடைய இரக்கம் பிடிவாதமாகத் தொடருதல்
			</li>
		</ol>
		<p>
			யோனா-2: 10 கர்த்தர் மீனுக்குக் கட்டளையிட்டார், அது யோனாவைக் கரையிலே கக்கிவிட்டது.
		</p>
		<p>
			III. அதிகாரம்-3
		</p>
		<ol>
			<li>
				யோனாவின் தயக்கமான கீழ்படிதல்
			</li>
		</ol>
		<p>
			யோனா-3: 3 யோனா எழுந்து, கர்த்தருடைய வார்த்தையின்படியே நினிவேக்குப் போனான், நினிவே மூன்றுநாள் பிரயாண விஸ்தாரமான மகாபெரிய நகரமாயிருந்தது.
		</p>
		<ol start="2">
			<li>
				நினிவேயின் மனந்திரும்புதல் (ராஜா முதல் சகல மக்களும்)
			</li>
		</ol>
		<p>
			யோனா-3: 8 மனுஷரும் மிருகங்களும் இரட்டினால் மூடிக்கொண்டு, தேவனை நோக்கி உரத்தசத்தமாய்க் கூப்பிடவும், அவரவர் தம்தம் பொல்லாத வழியையும் தம்தம் கைகளிலுள்ள கொடுமையையும் விட்டுத் திரும்பவுங் கடவார்கள்.
		</p>
		<ol start="3">
			<li>
				தேவனுடைய இரக்கம் சாந்தப்படுத்துதல்:
			</li>
		</ol>
		<p>
			யோனா-3: 10 அவர்கள் தங்கள் பொல்லாத வழியைவிட்டுத் திரும்பினார்களென்று தேவன் அவர்களுடைய கிரியைகளைப் பார்த்து, தாம் அவர்களுக்குச் செய்வேன் என்று சொல்லியிருந்த தீங்கைக் குறித்து மனஸ்தாபப்பட்டு, அதைச் செய்யாதிருந்தார்.
		</p>
		<ol>
			<li>
				அதிகாரம்-4
			</li>
			<li>
				யோனாவின் கோபம் (4: 1-விசனம், 4: 4 எரிச்சல், 4: 8 சோர்வு)
			</li>
			<li>
				யோனாவின் எதிர்மறை ஜெபம்:
			</li>
		</ol>
		<p>
			யோனா-4: 3, 8 இப்போதும் கர்த்தாவே, என் பிராணனை என்னைவிட்டு எடுத்துக்கொள்ளும், நான் உயிரோடு இருக்கிறதைப் பார்க்கிலும் சாகிறது நலமாயிருக்கும் என்றான். 8. சூரியன் உதித்தபோது தேவன் உஷ்ணமான கீழ்க்காற்றைக் கட்டளையிட்டார், அப்பொழுது வெயில் யோனாவுடைய தலையில் படுகிறதினால் அவன் சோர்ந்துபோய், தனக்குள்ளே சாவை விரும்பி: நான் உயிரோடு இருக்கிறதைப் பார்க்கிலும் சாகிறது நலமாயிருக்கும் என்றான்.
		</p>
		<ol start="3">
			<li>
				தேவனுடைய இரக்கம் மேலோங்குதல்:
			</li>
		</ol>
		<p>
			யோனா-4: 11 வலதுகைக்கும் இடதுகைக்கும் வித்தியாசம் அறியாத இலட்சத்து இருபதினாயிரம் பேருக்கு அதிகமான மனுஷரும் அநேக மிருகஜீவன்களும் இருக்கிற மகா நகரமாகிய நினிவேக்காக நான் பரிதபியாமல் இருப்பேனோ என்றார்.
		</p>
	</li>
</ol>

            {/* End Introduction */}
        </p>

        </div>
        <div>
            <div className='chap-link'>
                <div className='btn'> <Link to="/Yona-1"> 01</Link> </div>
                <div className='btn'> <Link to="/Yona-2"> 02</Link> </div>
                <div className='btn'> <Link to="/Yona-3"> 03</Link> </div>
                <div className='btn'> <Link to="/Yona-4"> 04</Link> </div>
            </div>
        </div>
    </div>
    </div>
    </>
);
}

export default Jonah;