import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah44 = () => {
  const verseNumber = 44;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 44 </title>
	<meta
          name="description"
          content="Jeremiah 44 | எரேமியா 44 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எகிப்துதேசத்தில் குடியேறி, மிக்தோலிலும், தக்பானேசிலும், நோப்பிலும், பத்ரோஸ் சீமையிலும் வாசமான எல்லா யூதரையுங்குறித்து, எரேமியாவுக்கு உண்டான வசனம்:
	<br /><br />
	2. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: நான் எருசலேமின்மேலும், யூதாவின் சகல பட்டணங்களின்மேலும், வரப்பண்ணின தீங்கையெல்லாம் நீங்கள் கண்டீர்கள்.
	<br /><br />
	3. இதோ, அவர்களும் நீங்களும் உங்கள் பிதாக்களும் அறியாத தேவர்களுக்குத் தூபங்காட்டவும், ஆராதனை செய்யவும் போய், எனக்குக் கோபமூட்டும்படிக்குச் செய்த அவர்களுடைய பொல்லாப்பினிமித்தம், அவைகள் இந்நாளில் பாழாய்க்கிடக்கிறது, அவைகளில் குடியில்லை.
	<br /><br />
	4. நான் வெறுக்கிற இந்த அருவருப்பான காரியத்தைச் செய்யாதிருங்களென்று, தீர்க்கதரிசிகளாகிய என் ஊழியக்காரரைக்கொண்டு உங்களுக்கு ஏற்கனவே சொல்லியனுப்பிக்கொண்டிருந்தேன்.
	<br /><br />
	5. ஆனாலும் அவர்கள் அந்நிய தேவர்களுக்குத் தூபங்காட்டாதபடிக்கு, என் சொல்லைக் கேளாமலும், பொல்லாப்பை விட்டுத் திரும்புவதற்குச் செவியைச் சாய்க்காமலும் போனார்கள்.
	<br /><br />
	6. ஆகையால், என் உக்கிரமும் என் கோபமும் மூண்டு, யூதாவின் பட்டணங்களிலும் எருசலேமின் வீதிகளிலும் பற்றியெரிந்தது; அவைகள் இந்நாளில் இருக்கிறபடி வனாந்தரமும் பாழுமாய்ப் போயிற்று.
	<br /><br />
	7. இப்போதும் இஸ்ரவேலின் தேவனும் சேனைகளின் தேவனுமாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால், நீங்கள் யூதாவில் ஒருவரையும் உங்களுக்கு மீதியாக வைக்காமல், உங்களில் புருஷனையும் ஸ்திரீயையும் பிள்ளையையும் பால்குடிக்கிற குழந்தையையும் வேரற்றுப்போகப்பண்ணும்படிக்கு, உங்கள் கைகளின் கிரியைகளாலே எனக்குக் கோபமூட்டுகிற பெரிய பொல்லாப்பை உங்கள் ஆத்துமாக்களுக்கு விரோதமாகச் செய்து,
	<br /><br />
	8. உங்களை வேரற்றுப்போகப்பண்ணுவதற்காகவும், நீங்கள் பூமியின் சகல ஜாதிகளுக்குள்ளும் சாபமும் நிந்தையுமாயிருப்பதற்காகவும், நீங்கள் தங்கியிருக்கவந்த எகிப்துதேசத்திலே அந்நிய தேவர்களுக்குத் தூபங்காட்டுவானேன்?
	<br /><br />
	9. யூதாதேசத்திலும் எருசலேமின் வீதிகளிலும் உங்கள் பிதாக்கள் செய்த பொல்லாப்புகளையும், யூதாவின் ராஜாக்கள் செய்த பொல்லாப்புகளையும், அவர்கள் ஸ்திரீகள் செய்த பொல்லாப்புகளையும், நீங்கள் செய்த பொல்லாப்புகளையும், உங்கள் ஸ்திரீகள் செய்த பொல்லாப்புகளையும் மறந்துபோனீர்களோ?
	<br /><br />
	10. அவர்கள் இந்நாள்மட்டும் மனம் நொறுங்குண்டதுமில்லை, அவர்கள் பயப்படுகிறதுமில்லை; நான் உங்கள் முன்பாகவும் உங்கள் பிதாக்கள் முன்பாகவும் வைத்த என் வேதத்தின்படியும் என் கட்டளைகளின்படியும் நடக்கிறதுமில்லை.
	<br /><br />
	11. ஆகையால், இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால், இதோ, நான் உங்களுக்குத் தீங்குண்டாகவும், யூதாவனைத்தையும் சங்கரிக்கத்தக்கதாகவும், என் முகத்தை உங்களுக்கு விரோதமாகத் திருப்பி,
	<br /><br />
	12. எகிப்துதேசத்திலே தங்கும்படிக்கு வரத் தங்கள் முகங்களைத் திருப்பின மீதியான யூதரை வாரிக்கொள்ளுவேன்; அவர்கள் அனைவரும் எகிப்துதேசத்திலே நிர்மூலமாவார்கள்; அவர்கள் சிறியவன்முதல் பெரியவன்வரைக்கும், பட்டயத்துக்கு இரையாகி, பஞ்சத்தாலும் நிர்மூலமாவார்கள்; அவர்கள் பட்டயத்தாலும் பஞ்சத்தாலும் செத்து, சாபமும் பாழும் பழிப்பும் நிந்தையுமாவார்கள்.
	<br /><br />
	13. நான் எருசலேமைத் தண்டித்தபடி எகிப்துதேசத்தில் குடியிருக்கிறவர்களையும் பட்டயத்தாலும், பஞ்சத்தாலும், கொள்ளைநோயாலும் தண்டிப்பேன்.
	<br /><br />
	14. எகிப்துதேசத்திலே தங்கவும், மறுபடியும் தங்கள் ஆத்துமா வாஞ்சித்திருக்கிற யூதா தேசத்திலே குடியேறுவதற்கு அங்கே திரும்பிப்போகவும் வேண்டுமென்று இங்கே வந்த மீதியான யூதரிலே மீதியாயிருக்கிறவர்களும் தப்புகிறவர்களுமில்லை; தப்பிப்போகிறவர்களாகிய மற்றவர்களேயொழிய அவர்களில் ஒருவரும் அங்கே திரும்புவதில்லையென்றார் என்று சொன்னான்.
	<br /><br />
	15. அப்பொழுது தங்கள் ஸ்திரீகள் அந்நிய தேவர்களுக்குத் தூபங்காட்டினதாக அறிந்திருந்த எல்லாப் புருஷரும், பெரிய கூட்டமாய் நின்றிருந்த எல்லா ஸ்திரீகளும், எகிப்துதேசத்தில் பத்ரோசிலே குடியிருந்த சகல ஜனங்களும் எரேமியாவுக்குப் பிரதியுத்தரமாக:
	<br /><br />
	16. நீ கர்த்தருடைய நாமத்தில் எங்களுக்குச் சொன்ன வார்த்தையின்படியே நாங்கள் உனக்குச் செவிகொடாமல்,
	<br /><br />
	17. எங்கள் வாயிலிருந்து புறப்பட்ட எல்லா வார்த்தையின்படியேயும் நாங்கள் செய்து, வானராக்கினிக்குத் தூபங்காட்டி, அவளுக்குப் பானபலிகளை வார்ப்போம்; நாங்களும், எங்கள் பிதாக்களும், எங்கள் ராஜாக்களும், எங்கள் பிரபுக்களும், யூதா பட்டணங்களிலும் எருசலேமின் வீதிகளிலும் செய்ததுபோலவே செய்வோம்; அப்பொழுது நாங்கள் அப்பத்தினால் திருப்தியாகி, ஒரு பொல்லாப்பையும் காணாமல் வாழ்ந்திருந்தோம்.
	<br /><br />
	18. நாங்கள் வானராக்கினிக்குத் தூபங்காட்டாமலும், அவளுக்குப் பானபலிகளை வார்க்காமலும் போனதுமுதற்கொண்டு, எல்லாம் எங்களுக்குக் குறைவுபட்டது; பட்டயத்தாலும் பஞ்சத்தாலும் அழிந்துபோனோம்.
	<br /><br />
	19. மேலும் நாங்கள் வானராக்கினிக்குத் தூபங்காட்டி, அவளுக்குப் பானபலிகளை வார்த்தபோது, நாங்கள் எங்கள் புருஷரின் அனுமதியில்லாமல் அவளுக்குப் பணியாரங்களைச் சுட்டு, பானபலிகளை வார்த்து, அவளை நமஸ்கரித்தோமோ? என்றார்கள்.
	<br /><br />
	20. அப்பொழுது எரேமியா, தனக்கு இப்படிப்பட்ட மறுமொழி கொடுத்த சகல ஜனங்களாகிய ஸ்திரீ புருஷர்களையும் மற்ற யாவரையும் நோக்கி:
	<br /><br />
	21. யூதாவின் பட்டணங்களிலும், எருசலேமின் வீதிகளிலும், நீங்களும் உங்கள் பிதாக்களும் உங்கள் ராஜாக்களும், உங்கள் பிரபுக்களும், தேசத்தின் ஜனங்களும் காட்டின தூபங்களை அல்லவோ கர்த்தர் நினைத்துத் தம்முடைய மனதிலே வைத்துக்கொண்டார்.
	<br /><br />
	22. உங்கள் கிரியைகளின் பொல்லாப்பையும், நீங்கள் செய்த அருவருப்புகளையும், கர்த்தர் அப்புறம் பொறுத்திருக்கக்கூடாதபடியினால் அல்லவோ, உங்கள் தேசம் இந்நாளில் இருக்கிறபடி குடியற்ற அந்தரவெளியும் பாழும் சாபமுமாயிற்று.
	<br /><br />
	23. நீங்கள் தூபங்காட்டி, கர்த்தருக்கு விரோதமாகப் பாவஞ்செய்து, கர்த்தருடைய சத்தத்துக்குச் செவிகொடாமலும், அவருடைய வேதத்துக்கும், அவருடைய கட்டளைகளுக்கும், அவருடைய சாட்சிகளுக்கும் இணங்கி நடவாமலும் போனபடியினாலே இந்நாளில் இருக்கிறபடி இந்தத் தீங்கு உங்களுக்கு நேரிட்டது என்றான்.
	<br /><br />
	24. பின்னும் எரேமியா சகல ஜனங்களையும், சகல ஸ்திரீகளையும் நோக்கி: எகிப்துதேசத்தில் இருக்கிற யூதராகிய நீங்கள் எல்லாரும் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	25. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால், வானராக்கினிக்குத் தூபங்காட்டவும், அவளுக்குப் பானபலிகளை வார்க்கவும், நாங்கள் நேர்ந்துகொண்ட பொருத்தனைகளை எவ்விதத்திலும் செலுத்துவோமென்று, நீங்களும் உங்கள் ஸ்திரீகளும், உங்கள் வாயினாலே சொல்லி, உங்கள் கைகளினாலே நிறைவேற்றினீர்கள்; நீங்கள் உங்கள் பொருத்தனைகளை ஸ்திரப்படுத்தினது மெய்யே, அவைகளைச் செலுத்தினதும் மெய்யே.
	<br /><br />
	26. ஆகையால், எகிப்துதேசத்தில் குடியிருக்கிற யூதா ஜனங்களாகிய நீங்கள் எல்லாரும் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்; இதோ, கர்த்தராகிய ஆண்டவருடைய ஜீவனாணை என்று, எகிப்து தேசமெங்கும் ஒரு யூதா மனுஷன் வாயினாலும் இனி என் நாமம் வழங்கப்படுவதில்லையென்று நான் என் மகத்தான நாமத்தைக்கொண்டு ஆணையிடுகிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	27. இதோ, நான் அவர்கள்மேல் நன்மைக்கல்ல தீமைக்கே ஜாக்கிரதையாயிருப்பேன்; எகிப்து தேசத்திலிருக்கிற யூதா மனுஷர் எல்லாரும் ஒழிந்து தீருமளவும் பட்டயத்தாலும் பஞ்சத்தாலும் சங்காரமாவார்கள்.
	<br /><br />
	28. ஆனாலும் பட்டயத்துக்குத் தப்புகிறவர்கள் எகிப்துதேசத்திலிருந்து யூதாதேசத்துக்குக் கொஞ்சம் பேராய்த் திரும்புவார்கள்; அப்படியே எகிப்துதேசத்திலே தங்கியிருக்க வந்த யூதாவில் மீதியான அனைவரும் அக்காலத்திலே தங்களுடைய வார்த்தையோ, என் வார்த்தையோ, யாருடைய வார்த்தை மெய்ப்படும் என்று அறிவார்கள்.
	<br /><br />
	29. நான் இவ்விடத்தில் உங்களைத் தண்டிப்பேன் என்று உங்களுக்கு விரோதமாய்ச் சொன்ன என் வார்த்தைகள் மெய்ப்படுமென்று நீங்கள் அறிவதற்கு உங்களுக்கு இதுவே அடையாளம் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	30. இதோ, நான் யூதாவின் ராஜாவாகிய சிதேக்கியாவை, அவனுடைய சத்துருவும் அவன் பிராணனை வாங்கத் தேடினவனுமாகிய நேபுகாத்நேச்சார் என்னும் பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுத்ததுபோல, நான் பார்வோன் ஒப்பிரா என்னும் எகிப்தின் ராஜாவையும், அவனுடைய சத்துருக்களின் கையிலும், அவன் பிராணனை வாங்கத்தேடுகிறவர்களின் கையிலும் ஒப்புக்கொடுப்பேன் என்று கர்த்தர் சொல்லுகிறார் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah44