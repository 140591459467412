import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OnePeter2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Living as God’s Chosen People Peter in Tamil Bible | 1 பேதுரு  2 </title>
	<meta
          name="description"
          content="Living as God’s Chosen People in Tamil Bible Verse | பேதுரு அதிகாரம் -2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கடவுளால் தேர்ந்தெடுக்கப்பட்ட மக்களாக வாழ்வது *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இப்படியிருக்க, கர்த்தர் தயையுள்ளவரென்பதை நீங்கள் ருசிபார்த்ததுண்டானால்,
	<br /><br />
	2. சகல துர்க்குணத்தையும், சகலவித கபடத்தையும், வஞ்சகங்களையும், பொறாமைகளையும், சகலவித புறங்கூறுதலையும் ஒழித்துவிட்டு,
	<br /><br />
	3. நீங்கள் வளரும்படி, புதிதாய்ப் பிறந்த குழந்தைகளைப்போல, திருவசனமாகிய களங்கமில்லாத ஞானப்பாலின்மேல் வாஞ்சையாயிருங்கள்.
	<br /><br />
	4. மனுஷரால் தள்ளப்பட்டதாயினும், தேவனால் தெரிந்துகொள்ளப்பட்டதும் விலையேறப்பெற்றதுமாயிருக்கிற ஜீவனுள்ள கல்லாகிய அவரிடத்தில் சேர்ந்தவர்களாகிய நீங்களும்,
	<br /><br />
	5. ஜீவனுள்ள கற்களைப்போல ஆவிக்கேற்ற மாளிகையாகவும், இயேசுகிறிஸ்து மூலமாய்த் தேவனுக்குப் பிரியமான ஆவிக்கேற்ற பலிகளைச் செலுத்தும்படிக்குப் பரிசுத்த ஆசாரியக்கூட்டமாகவும் கட்டப்பட்டுவருகிறீர்கள்.
	<br /><br />
	6. அந்தப்படியே: இதோ, தெரிந்துகொள்ளப்பட்டதும் விலையேறப்பெற்றதுமாயிருக்கிற மூலைக்கல்லை சீயோனில் வைக்கிறேன்; அதின்மேல் விசுவாசமாயிருக்கிறவன் வெட்கப்படுவதில்லை என்று வேதத்திலே சொல்லியிருக்கிறது.
	<br /><br />
	7. ஆகையால் விசுவாசிக்கிற உங்களுக்கு அது விலையேறப்பெற்றது; கீழ்ப்படியாமலிருக்கிறவர்களுக்கோ வீட்டைக் கட்டுகிறவர்களால் தள்ளப்பட்ட பிரதான மூலைக்கல்லாகிய அந்தக் கல் இடறுதற்கேதுவான கல்லும் விழுதற்கேதுவான கன்மலையுமாயிற்று;
	<br /><br />
	8. அவர்கள் திருவசனத்திற்குக் கீழ்ப்படியாதவர்களாயிருந்து இடறுகிறார்கள்; அதற்கென்றே நியமிக்கப்பட்டவர்களாயும் இருக்கிறார்கள்.
	<br /><br />
	9. நீங்களோ, உங்களை அந்தகாரத்தினின்று தம்முடைய ஆச்சரியமான ஒளியினிடத்திற்கு வரவழைத்தவருடைய புண்ணியங்களை அறிவிக்கும்படிக்குத் தெரிந்துகொள்ளப்பட்ட சந்ததியாயும், ராஜரீகமான ஆசாரியக்கூட்டமாயும், பரிசுத்த ஜாதியாயும், அவருக்குச் சொந்தமான ஜனமாயும் இருக்கிறீர்கள்.
	<br /><br />
	10. முன்னே நீங்கள் தேவனுடைய ஜனங்களாயிருக்கவில்லை, இப்பொழுதோ அவருடைய ஜனங்களாயிருக்கிறீர்கள்; முன்னே நீங்கள் இரக்கம் பெறாதவர்களாயிருந்தீர்கள், இப்பொழுதோ இரக்கம் பெற்றவர்களாயிருக்கிறீர்கள்.
	<br /><br />
	11. பிரியமானவர்களே, அந்நியர்களும் பரதேசிகளுமாயிருக்கிற நீங்கள் ஆத்துமாவுக்கு விரோதமாய்ப் போர்செய்கிற மாம்ச இச்சைகளை விட்டு விலகி,
	<br /><br />
	12. புறஜாதிகள் உங்களை அக்கிரமக்காரரென்று விரோதமாய்ப் பேசும் விஷயத்தில், அவர்கள் உங்கள் நற்கிரியைகளைக் கண்டு, அவற்றினிமித்தம் சந்திப்பின் நாளிலே தேவனை மகிமைப்படுத்தும்படி நீங்கள் அவர்களுக்குள்ளே நல்நடக்கையுள்ளவர்களாய் நடந்துகொள்ளுங்கள் என்று உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	13. நீங்கள் மனுஷருடைய கட்டளைகள் யாவற்றிற்கும் கர்த்தர்நிமித்தம் கீழ்ப்படியுங்கள்.
	<br /><br />
	14. மேலான அதிகாரமுள்ள ராஜாவுக்கானாலுஞ்சரி, தீமைசெய்கிறவர்களுக்கு ஆக்கினையும் நன்மைசெய்கிறவர்களுக்குப் புகழ்ச்சியும் உண்டாகும்படி அவனால் அனுப்பப்பட்ட அதிகாரிகளுக்கானாலுஞ்சரி, கீழ்ப்படியுங்கள்.
	<br /><br />
	15. நீங்கள் நன்மைசெய்கிறதினாலே புத்தியீன மனுஷருடைய அறியாமையை அடக்குவது தேவனுடைய சித்தமாயிருக்கிறது.
	<br /><br />
	16. சுயாதீனமுள்ளவர்களாயிருந்தும் உங்கள் சுயாதீனத்தைத் துர்க்குணத்திற்கு மூடலாகக் கொண்டிராமல், தேவனுக்கு அடிமைகளாயிருங்கள்.
	<br /><br />
	17. எல்லாரையும் கனம்பண்ணுங்கள்; சகோதரரிடத்தில் அன்புகூருங்கள்; தேவனுக்குப் பயந்திருங்கள்; ராஜாவைக் கனம்பண்ணுங்கள்.
	<br /><br />
	18. வேலைக்காரரே, அதிக பயத்துடனே உங்கள் எஜமான்களுக்குக் கீழ்ப்படிந்திருங்கள்; நல்லவர்களுக்கும் சாந்தகுணமுள்ளவர்களுக்கும் மாத்திரம் அல்ல, முரட்டுக்குணமுள்ளவர்களுக்கும் கீழ்ப்படிந்திருங்கள்.
	<br /><br />
	19. ஏனெனில், தேவன்மேல் பற்றுதலாயிருக்கிற மனச்சாட்சியினிமித்தம் ஒருவன் அநியாயமாய்ப் பாடுபட்டு உபத்திரவங்களைப் பொறுமையாய்ச் சகித்தால் அதுவே பிரீதியாயிருக்கும்.
	<br /><br />
	20. நீங்கள் குற்றஞ்செய்து அடிக்கப்படும்போது பொறுமையோடே சகித்தால், அதினால் என்ன கீர்த்தியுண்டு? நீங்கள் நன்மைசெய்து பாடுபடும்போது பொறுமையோடே சகித்தால் அதுவே தேவனுக்குமுன்பாகப் பிரீதியாயிருக்கும்.
	<br /><br />
	21. இதற்காக நீங்கள் அழைக்கப்பட்டுமிருக்கிறீர்கள்; ஏனெனில், கிறிஸ்துவும் உங்களுக்காகப் பாடுபட்டு, நீங்கள் தம்முடைய அடிச்சுவடுகளைத் தொடர்ந்துவரும்படி உங்களுக்கு மாதிரியைப் பின்வைத்துப்போனார்.
	<br /><br />
	22. அவர் பாவஞ்செய்யவில்லை, அவருடைய வாயிலே வஞ்சனை காணப்படவுமில்லை;
	<br /><br />
	23. அவர் வையப்படும்போது பதில் வையாமலும், பாடுபடும்போது பயமுறுத்தாமலும், நியாயமாய்த் தீர்ப்புச்செய்கிறவருக்குத் தம்மை ஒப்புவித்தார்.
	<br /><br />
	24. நாம் பாவங்களுக்குச் செத்து, நீதிக்குப் பிழைத்திருக்கும்படிக்கு, அவர்தாமே தமது சரீரத்திலே நம்முடைய பாவங்களைச் சிலுவையின்மேல் சுமந்தார்; அவருடைய தழும்புகளால் குணமானீர்கள்.
	<br /><br />
	25. சிதறுண்ட ஆடுகளைப்போலிருந்தீர்கள்; இப்பொழுதோ உங்கள் ஆத்துமாக்களுக்கு மேய்ப்பரும் கண்காணியுமானவரிடத்தில் திருப்பப்பட்டிருக்கிறீர்கள்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* 1 பேதுரு 2, கர்த்தராகிய இயேசுவை மூலக்கல்லாகக் கொண்ட ஒரு ஆவிக்குரிய வீட்டிற்குள் நாம் எவ்வாறு கட்டப்படுகிறோம் என்பதைக் காட்டுவதன் மூலம் தொடங்குகிறது. நாம் ஒரு "தேர்ந்தெடுக்கப்பட்ட மக்கள், ஒரு அரச ஆசாரியத்துவம், ஒரு பரிசுத்த தேசம், கடவுளுக்கு சொந்தமான மக்கள்" என்று பேதுரு கூறுகிறார் (vs. 9). இதன் காரணமாக நாம் இவ்வுலகில் அந்நியர்களாக வாழ வேண்டும், மற்றவர்கள் கடவுளைக் கண்டு மகிமைப்படுத்துவதற்காக நல்ல வாழ்க்கை வாழ வேண்டும். எங்கள் மீது அதிகாரம் உள்ளவர்களுக்கு அடிபணியவும், சரியான மரியாதை காட்டவும் நாங்கள் அறிவுறுத்தப்படுகிறோம்; நாம் கிறிஸ்துவின் முன்மாதிரியைப் பின்பற்ற வேண்டும். *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OnePeter2