import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 3 </title>
	<meta
          name="description"
          content="Luke 3 | லூக்கா 3 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. திபேரியுராயன் ராஜ்யபாரம் பண்ணின பதினைந்தாம் வருஷத்திலே, பொந்தியுபிலாத்து யூதேயாவுக்குத் தேசாதிபதியாயும், ஏரோது காற்பங்கு தேசமாகிய கலிலேயாவுக்கு அதிபதியாயும், அவன் சகோதரனாகிய பிலிப்பு காற்பங்கு தேசமாகிய இத்துரேயாவுக்கும் திராகொனித்தி நாட்டிற்கும் அதிபதியாயும், லிசானியா காற்பங்கு தேசமாகிய அபிலேனேக்கு அதிபதியாயும்,
	<br /><br />
	2. அன்னாவும் காய்பாவும் பிரதான ஆசாரியராயும் இருந்தகாலத்தில் வனாந்தரத்திலே சகரியாவின் குமாரனாகிய யோவானுக்குத் தேவனுடைய வார்த்தை உண்டாயிற்று.
	<br /><br />
	3. அப்பொழுது: கர்த்தருக்கு வழியை ஆயத்தப்படுத்துங்கள், அவருக்குப் பாதைகளைச் செவ்வைபண்ணுங்கள் என்றும்,
	<br /><br />
	4. பள்ளங்களெல்லாம் நிரப்பப்படும், சகல மலைகளும் குன்றுகளும் தாழ்த்தப்படும், கோணலானவைகள் செவ்வையாகும், கரடானவைகள் சமமாகும் என்றும்,
	<br /><br />
	5. மாம்சமான யாவரும் தேவனுடைய இரட்சிப்பைக் காண்பார்கள் என்றும், வனாந்தரத்திலே கூப்பிடுகிறவனுடைய சத்தம் உண்டாகும் என்று ஏசாயா தீர்க்கதரிசியின் ஆகமத்தில் எழுதியிருக்கிறபிரகாரம்,
	<br /><br />
	6. அவன் யோர்தான் நதிக்கு அருகான தேசமெங்கும் போய், பாவமன்னிப்புக்கென்று மனந்திரும்புதலுக்கேற்ற ஞானஸ்நானத்தைக்குறித்துப் பிரசங்கித்தான்.
	<br /><br />
	7. அவன், தன்னிடத்தில் ஞானஸ்நானம் பெறும்படிக்குப் புறப்பட்டுவந்த திரளான ஜனங்களை நோக்கி: விரியன்பாம்புக் குட்டிகளே! வருங்கோபத்துக்குத் தப்பித்துக்கொள்ள உங்களுக்கு வகைகாட்டினவன் யார்?
	<br /><br />
	8. மனந்திரும்புதலுக்கு ஏற்ற கனிகளைக் கொடுங்கள்; ஆபிரகாம் எங்களுக்குத் தகப்பன் என்று உங்களுக்குள்ளே சொல்லத்தொடங்காதிருங்கள்; தேவன் இந்தக் கல்லுகளினாலே ஆபிரகாமுக்குப் பிள்ளைகளை உண்டுபண்ண வல்லவராயிருக்கிறார் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	9. இப்பொழுதே கோடரியானது மரங்களின் வேர் அருகே வைத்திருக்கிறது; ஆகையால் நல்ல கனிகொடாத மரமெல்லாம் வெட்டுண்டு அக்கினியிலே போடப்படும் என்றான்.
	<br /><br />
	10. அப்பொழுது ஜனங்கள் அவனை நோக்கி: அப்படியானால் நாங்கள் என்ன செய்யவேண்டும் என்று கேட்டார்கள்.
	<br /><br />
	11. அவர்களுக்கு அவன் பிரதியுத்தரமாக: இரண்டு அங்கிகளையுடையவன் இல்லாதவனுக்குக் கொடுக்கக்கடவன்; ஆகாரத்தை உடையவனும் அப்படியே செய்யக்கடவன் என்றான்.
	<br /><br />
	12. ஆயக்காரரும் ஞானஸ்நானம் பெறவந்து, அவனை நோக்கி: போதகரே, நாங்கள் என்னசெய்யவேண்டும் என்று கேட்டார்கள்.
	<br /><br />
	13. அதற்கு அவன்: உங்களுக்குக் கட்டளையிட்டிருக்கிறதற்கு அதிகமாய் ஒன்றும் வாங்காதிருங்கள் என்றான்.
	<br /><br />
	14. போர்ச்சேவகரும் அவனை நோக்கி: நாங்கள் என்னசெய்யவேண்டும் என்று கேட்டார்கள். அதற்கு அவன்: நீங்கள் ஒருவருக்கும் இடுக்கண்செய்யாமலும் பொய்யாய்க் குற்றஞ்சாட்டாமலும், உங்கள் சம்பளமே போதுமென்றும் இருங்கள் என்றான்.
	<br /><br />
	15. யோவானைக்குறித்து: இவன்தான் கிறிஸ்துவோ என்று ஜனங்களெல்லாரும் எண்ணங்கொண்டு, தங்கள் இருதயங்களில் யோசனையாயிருக்கையில்,
	<br /><br />
	16. யோவான் எல்லாருக்கும் பிரதியுத்தரமாக: நான் ஜலத்தினால் உங்களுக்கு ஞானஸ்நானங் கொடுக்கிறேன், என்னிலும் வல்லவர் ஒருவர் வருகிறார், அவருடைய பாதரட்சைகளின் வாரை அவிழ்க்கிறதற்கும் நான் பாத்திரன் அல்ல, அவர் பரிசுத்தஆவியினாலும் அக்கினியினாலும் உங்களுக்கு ஞானஸ்நானங் கொடுப்பார்.
	<br /><br />
	17. தூற்றுக்கூடை அவர் கையில் இருக்கிறது, அவர் தமது களத்தை நன்றாய் விளக்கி, கோதுமையைத் தமது களஞ்சியத்தில் சேர்ப்பார்; பதரையோ அவியாத அக்கினியினால் சுட்டெரிப்பார் என்றான்.
	<br /><br />
	18. வேறு அநேக புத்திமதிகளையும் அவன் ஜனங்களுக்குச் சொல்லிப் பிரசங்கித்தான்.
	<br /><br />
	19. காற்பங்கு தேசாதிபதியாகிய ஏரோது தன் சகோதரனான பிலிப்புவின் மனைவி ஏரோதியாளினிமித்தமாகவும், தான் செய்த மற்றப் பொல்லாங்குகளினிமித்தமாகவும், யோவானாலே கடிந்துகொள்ளப்பட்டபோது,
	<br /><br />
	20. தான் செய்த மற்றெல்லாப் பொல்லாங்குகளும் தவிர, யோவானையும் காவலில் அடைத்துவைத்தான்.
	<br /><br />
	21. ஜனங்களெல்லாரும் ஞானஸ்நானம் பெற்றபோது, இயேசுவும் ஞானஸ்நானம் பெற்று, ஜெபம்பண்ணுகையில், வானம் திறக்கப்பட்டது;
	<br /><br />
	22. பரிசுத்த ஆவியானவர் ரூபங்கொண்டு புறாவைப்போல அவர்மேல் இறங்கினார். வானத்திலிருந்து ஒரு சத்தமும் உண்டாகி: நீர் என்னுடைய நேசகுமாரன், உம்மில் பிரியமாயிருக்கிறேன் என்று உரைத்தது.
	<br /><br />
	23. அப்பொழுது இயேசு ஏறக்குறைய முப்பது வயதுள்ளவரானார். அவர் யோசேப்பின் குமாரனென்று எண்ணப்பட்டார். அந்த யோசேப்பு ஏலியின் குமாரன்;
	<br /><br />
	24. ஏலி மாத்தாத்தின் குமாரன்; மாத்தாத் லேவியின் குமாரன்; லேவி மெல்கியின் குமாரன்; மெல்கி யன்னாவின் குமாரன்; யன்னா யோசேப்பின் குமாரன்;
	<br /><br />
	25. யோசேப்பு மத்தத்தியாவின் குமாரன்; மத்தத்தியா ஆமோசின் குமாரன்; ஆமோஸ் நாகூமின் குமாரன்; நாகூம் எஸ்லியின் குமாரன்; எஸ்லி நங்காயின் குமாரன்.
	<br /><br />
	26. நங்காய் மாகாத்தின் குமாரன்; மாகாத் மத்தத்தியாவின் குமாரன்; மத்தத்தியா சேமேயின் குமாரன்; சேமேய் யோசேப்பின் குமாரன்; யோசேப்பு யூதாவின் குமாரன்; யூதா யோவன்னாவின் குமாரன்.
	<br /><br />
	27. யோவன்னா ரேசாவின் குமாரன்; ரேசா சொரொபாபேலின் குமாரன்; சொரொபாபேல் சலாத்தியேலின் குமாரன்; சலாத்தியேல் நேரியின் குமாரன்.
	<br /><br />
	28. நேரி மெல்கியின் குமாரன்; மெல்கி அத்தியின் குமாரன்; அத்தி கோசாமின் குமாரன்; கோசாம் எல்மோதாமின் குமாரன்; எல்மோதாம் ஏரின் குமாரன்; ஏர் யோசேயின் குமாரன்.
	<br /><br />
	29. யோசே எலியேசரின் குமாரன்; எலியேசர் யோரீமின் குமாரன்; யோரீம் மாத்தாத்தின் குமாரன்; மாத்தாத் லேவியின் குமாரன்.
	<br /><br />
	30. லேவி சிமியோனின் குமாரன்; சிமியோன் யூதாவின் குமாரன்; யூதா யோசேப்பின் குமாரன்; யோசேப்பு யோனானின் குமாரன்; யோனான் எலியாக்கீமின் குமாரன்.
	<br /><br />
	31. எலியாக்கீம் மெலெயாவின் குமாரன்; மெலெயா மயினானின் குமாரன்; மயினான் மாத்தாத்தாவின் குமாரன்; மாத்தாத்தா நாத்தானின் குமாரன்; நாத்தான் தாவீதின் குமாரன்.
	<br /><br />
	32. தாவீது ஈசாயின் குமாரன்; ஈசாய் ஓபேதின் குமாரன்; ஓபேத் போவாசின் குமாரன்; போவாஸ் சல்மோனின் குமாரன்; சல்மோன் நகசோனின் குமாரன்.
	<br /><br />
	33. நகசோன் அம்மினதாபின் குமாரன்; அம்மினதாப் ஆராமின் குமாரன்; ஆராம் எஸ்ரோமின் குமாரன்; எஸ்ரோம் பாரேசின் குமாரன்; பாரேஸ் யூதாவின் குமாரன்; யூதா யாக்கோபின் குமாரன்.
	<br /><br />
	34. யாக்கோபு ஈசாக்கின் குமாரன்; ஈசாக்கு ஆபிரகாமின் குமாரன்; ஆபிரகாம் தேராவின் குமாரன்; தேரா நாகோரின் குமாரன்.
	<br /><br />
	35. நாகோர் சேரூக்கின் குமாரன்; சேரூக் ரெகூவின் குமாரன்; ரெகூ பேலேக்கின் குமாரன்; பேலேக் ஏபேரின் குமாரன்; ஏபேர் சாலாவின் குமாரன்.
	<br /><br />
	36. சாலா காயினானின் குமாரன்; காயினான் அர்ப்பகசாத்தின் குமாரன்; அர்ப்பகசாத் சேமின் குமாரன்; சேம் நோவாவின் குமாரன்; நோவா லாமேக்கின் குமாரன்.
	<br /><br />
	37. லாமேக்கு மெத்தூசலாவின் குமாரன்; மெத்தூசலா ஏனோக்கின் குமாரன்; ஏனோக்கு யாரேதின் குமாரன்; யாரேத் மகலாலெயேலின் குமாரன்; மகலாலெயேல் கேனானின் குமாரன்; கேனான் ஏனோசின் குமாரன்.
	<br /><br />
	38. ஏனோஸ் சேத்தின் குமாரன்; சேத் ஆதாமின் குமாரன்; ஆதாம் தேவனால் உண்டானவன்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	John the Baptist Prepares the Way
</h2>
<p>
	<br />
	1 In the fifteenth year of the reign of Tiberius Caesar&mdash;when Pontius Pilate was governor of Judea, Herod tetrarch of Galilee, his brother Philip tetrarch of Iturea and Traconitis, and Lysanias tetrarch of Abilene&mdash;
	<br />
	2 during the high-priesthood of Annas and Caiaphas, the word of God came to John son of Zechariah in the wilderness.
	<br />
	3 He went into all the country around the Jordan, preaching a baptism of repentance for the forgiveness of sins.
	<br />
	4 As it is written in the book of the words of Isaiah the prophet: &ldquo;A voice of one calling in the wilderness, &lsquo;Prepare the way for the Lord, make straight paths for him.
	<br />
	5 Every valley shall be filled in, every mountain and hill made low. The crooked roads shall become straight, the rough ways smooth.
	<br />
	6 And all people will see God&rsquo;s salvation.&rsquo; &rdquo;
	<br />
	7 John said to the crowds coming out to be baptized by him, &ldquo;You brood of vipers! Who warned you to flee from the coming wrath?
	<br />
	8 Produce fruit in keeping with repentance. And do not begin to say to yourselves, &lsquo;We have Abraham as our father.&rsquo; For I tell you that out of these stones God can raise up children for Abraham.
	<br />
	9 The ax is already at the root of the trees, and every tree that does not produce good fruit will be cut down and thrown into the fire.&rdquo;
	<br />
	10 &ldquo;What should we do then?&rdquo; the crowd asked.
	<br />
	11 John answered, &ldquo;Anyone who has two shirts should share with the one who has none, and anyone who has food should do the same.&rdquo;
	<br />
	12 Even tax collectors came to be baptized. &ldquo;Teacher,&rdquo; they asked, &ldquo;what should we do?&rdquo;
	<br />
	13 &ldquo;Don&rsquo;t collect any more than you are required to,&rdquo; he told them.
	<br />
	14 Then some soldiers asked him, &ldquo;And what should we do?&rdquo; He replied, &ldquo;Don&rsquo;t extort money and don&rsquo;t accuse people falsely&mdash;be content with your pay.&rdquo;
	<br />
	15 The people were waiting expectantly and were all wondering in their hearts if John might possibly be the Messiah.
	<br />
	16 John answered them all, &ldquo;I baptize you with water. But one who is more powerful than I will come, the straps of whose sandals I am not worthy to untie. He will baptize you with the Holy Spirit and fire.
	<br />
	17 His winnowing fork is in his hand to clear his threshing floor and to gather the wheat into his barn, but he will burn up the chaff with unquenchable fire.&rdquo;
	<br />
	18 And with many other words John exhorted the people and proclaimed the good news to them.
	<br />
	19 But when John rebuked Herod the tetrarch because of his marriage to Herodias, his brother&rsquo;s wife, and all the other evil things he had done,
	<br />
	20 Herod added this to them all: He locked John up in prison.
</p>
<h2>
	<br />
	The Baptism and Genealogy of Jesus
</h2>
<p>
	<br />
	21 When all the people were being baptized, Jesus was baptized too. And as he was praying, heaven was opened
	<br />
	22 and the Holy Spirit descended on him in bodily form like a dove. And a voice came from heaven: &ldquo;You are my Son, whom I love; with you I am well pleased.&rdquo;
	<br />
	23 Now Jesus himself was about thirty years old when he began his ministry. He was the son, so it was thought, of Joseph, the son of Heli,
	<br />
	24 the son of Matthat, the son of Levi, the son of Melki, the son of Jannai, the son of Joseph,
	<br />
	25 the son of Mattathias, the son of Amos, the son of Nahum, the son of Esli, the son of Naggai,
	<br />
	26 the son of Maath, the son of Mattathias, the son of Semein, the son of Josek, the son of Joda,
	<br />
	27 the son of Joanan, the son of Rhesa, the son of Zerubbabel, the son of Shealtiel, the son of Neri,
	<br />
	28 the son of Melki, the son of Addi, the son of Cosam, the son of Elmadam, the son of Er,
	<br />
	29 the son of Joshua, the son of Eliezer, the son of Jorim, the son of Matthat, the son of Levi,
	<br />
	30 the son of Simeon, the son of Judah, the son of Joseph, the son of Jonam, the son of Eliakim,
	<br />
	31 the son of Melea, the son of Menna, the son of Mattatha, the son of Nathan, the son of David,
	<br />
	32 the son of Jesse, the son of Obed, the son of Boaz, the son of Salmon, the son of Nahshon,
	<br />
	33 the son of Amminadab, the son of Ram,the son of Hezron, the son of Perez, the son of Judah,
	<br />
	34 the son of Jacob, the son of Isaac, the son of Abraham, the son of Terah, the son of Nahor,
	<br />
	35 the son of Serug, the son of Reu, the son of Peleg, the son of Eber, the son of Shelah,
	<br />
	36 the son of Cainan, the son of Arphaxad, the son of Shem, the son of Noah, the son of Lamech,
	<br />
	37 the son of Methuselah, the son of Enoch, the son of Jared, the son of Mahalalel, the son of Kenan,
	<br />
	38 the son of Enosh, the son of Seth, the son of Adam, the son of God.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke3