import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 16 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 16
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கெங்கிரேயா ஊர் சபைக்கு ஊழியக்காரியாகிய நம்முடைய சகோதரி பெபேயாளை நீங்கள் கர்த்தருக்குள் பரிசுத்தவான்களுக்கேற்றபடி ஏற்றுக்கொண்டு,
	<br /><br />
	2. எந்தக் காரியத்தில் உங்கள் உதவி அவளுக்குத் தேவையாயிருக்கிறதோ அதிலே நீங்கள் அவளுக்கு உதவிசெய்யவேண்டுமென்று அவளை உங்களிடத்தில் ஒப்புவிக்கிறேன்; அவள் அநேகருக்கும் எனக்குங்கூட ஆதரவாயிருந்தவள்.
	<br /><br />
	3. கிறிஸ்து இயேசுவுக்குள் என் உடன்வேலையாட்களாகிய பிரிஸ்கில்லாளையும் ஆக்கில்லாவையும் வாழ்த்துங்கள்.
	<br /><br />
	4. அவர்கள் என் பிராணனுக்காகத் தங்கள் கழுத்தைக் கொடுத்தவர்கள்; அவர்களைப்பற்றி நான்மாத்திரமல்ல, புறஜாதியாரில் உண்டான சபையாரெல்லாரும் நன்றியறிதலுள்ளவர்களாயிருக்கிறார்கள்.
	<br /><br />
	5. அவர்களுடைய வீட்டிலே கூடிவருகிற சபையையும் வாழ்த்துங்கள். அகாயாவிலே கிறிஸ்துவுக்கு முதற்பலனாகிய என் பிரியமான எப்பனெத்தை வாழ்த்துங்கள்.
	<br /><br />
	6. எங்களுக்காக மிகவும் பிரயாசப்பட்ட மரியாளை வாழ்த்துங்கள்.
	<br /><br />
	7. அப்போஸ்தலருக்குள் பெயர்பெற்றவர்களும் எனக்கு முந்திக் கிறிஸ்துவுக்குள்ளானவர்களும் என் இனத்தாரும் என்னுடனேகூடக் காவலில் கட்டுண்டவர்களுமாயிருக்கிற அன்றோனீக்கையும் யூனியாவையும் வாழ்த்துங்கள்.
	<br /><br />
	8. கர்த்தருக்குள் எனக்குப் பிரியமான அம்பிலியாவை வாழ்த்துங்கள்.
	<br /><br />
	9. கிறிஸ்துவுக்குள் நம்மோடே உடன்வேலையாளாகிய உர்பானையும், என் பிரியமான ஸ்தாக்கியையும் வாழ்த்துங்கள்.
	<br /><br />
	10. கிறிஸ்துவுக்குள் உத்தமனாகிய அப்பெல்லேயை வாழ்த்துங்கள். அரிஸ்தொபூலுவின் வீட்டாரை வாழ்த்துங்கள்.
	<br /><br />
	11. என் இனத்தானாகிய ஏரோதியோனை வாழ்த்துங்கள். நர்கீசுவின் வீட்டாரில் கர்த்தருக்குட்பட்டவர்களை வாழ்த்துங்கள்.
	<br /><br />
	12. கர்த்தருக்குள் பிரயாசப்படுகிற திரிபேனாளையும் திரிபோசாளையும் வாழ்த்துங்கள். கர்த்தருக்குள் மிகவும் பிரயாசப்பட்ட பிரியமான பெர்சியாளை வாழ்த்துங்கள்.
	<br /><br />
	13. கர்த்தருக்குள் தெரிந்துகொள்ளப்பட்ட ரூபையும், எனக்கும் தாயாகிய அவனுடைய தாயையும் வாழ்த்துங்கள்.
	<br /><br />
	14. அசிங்கிரீத்துவையும், பிலெகோனையும், எர்மாவையும், பத்திரொபாவையும், எர்மேயையும், அவர்களோடிருக்கிற சகோதரரையும் வாழ்த்துங்கள்.
	<br /><br />
	15. பிலொலோகையும், யூலியாளையும், நேரேயையும், அவனுடைய சகோதரியையும், ஒலிம்பாவையும், அவர்களோடிருக்கிற பரிசுத்தவான்களெல்லாரையும் வாழ்த்துங்கள்.
	<br /><br />
	16. ஒருவரையொருவர் பரிசுத்த முத்தத்தோடு வாழ்த்துங்கள். கிறிஸ்துவின் சபையார் உங்களை வாழ்த்துகிறார்கள்.
	<br /><br />
	17. அன்றியும் சகோதரரே, நீங்கள் கற்றுக்கொண்ட உபதேசத்திற்கு விரோதமாய்ப் பிரிவினைகளையும் இடறல்களையும் உண்டாக்குகிறவர்களைக்குறித்து எச்சரிக்கையாயிருந்து, அவர்களை விட்டு விலகவேண்டுமென்று உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	18. அப்படிப்பட்டவர்கள் நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவுக்கு ஊழியஞ்செய்யாமல் தங்கள் வயிற்றுக்கே ஊழியஞ்செய்து, நயவசனிப்பினாலும் இச்சகப்பேச்சினாலும், கபடில்லாதவர்களுடைய இருதயங்களை வஞ்சிக்கிறவர்களாயிருக்கிறார்கள்.
	<br /><br />
	19. உங்கள் கீழ்ப்படிதல் யாவருக்கும் தெரியவந்திருக்கிறது. ஆகையால் உங்களைக்குறித்துச் சந்தோஷப்படுகிறேன்; ஆனாலும் நீங்கள் நன்மைக்கு ஞானிகளும் தீமைக்குப் பேதைகளுமாயிருக்கவேண்டுமென்று விரும்புகிறேன்.
	<br /><br />
	20. சமாதானத்தின் தேவன் சீக்கிரமாய்ச் சாத்தானை உங்கள் கால்களின்கீழே நசுக்கிப்போடுவார். நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய கிருபை உங்களுடனேகூட இருப்பதாக. ஆமென்.
	<br /><br />
	21. என் உடன்வேலையாளாகிய தீமோத்தேயும், என் இனத்தாராகிய லூகியும், யாசோனும், சொசிபத்தரும் உங்களை வாழ்த்துகிறார்கள்.
	<br /><br />
	22. இந்த நிருபத்தை எழுதின தெர்தியுவாகிய நான் கர்த்தருக்குள் உங்களை வாழ்த்துகிறேன்.
	<br /><br />
	23. என்னையும் சபையனைத்தையும் உபசரித்துவருகிற காயு உங்களை வாழ்த்துகிறான். பட்டணத்து உக்கிராணக்காரனாகிய ஏரஸ்தும், சகோதரனாகிய குவர்த்தும் உங்களை வாழ்த்துகிறார்கள்.
	<br /><br />
	24. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய கிருபை உங்கள் அனைவரோடுங்கூட இருப்பதாக. ஆமென்.
	<br /><br />
	25. ஆதிகாலமுதல் அடக்கமாயிருந்து, இப்பொழுது தீர்க்கதரிசன ஆகமங்களினாலே அநாதி தேவனுடைய கட்டளையின்படி வெளியரங்கமாக்கப்பட்டதும், சகல ஜாதிகளும் விசுவாசத்திற்குக் கீழ்ப்படியும்படிக்கு அவர்களுக்கு அறிவிக்கப்பட்டதுமாயிருக்கிற இரகசியத்தை வெளிப்படுத்துகிறதான,
	<br /><br />
	26. இயேசுகிறிஸ்துவைப்பற்றிய பிரசங்கமாகிய என் சுவிசேஷத்தின்படியே உங்களை ஸ்திரப்படுத்த வல்லவரும்,
	<br /><br />
	27. தாம் ஒருவரே ஞானமுள்ளவருமாயிருக்கிற தேவனுக்கு இயேசுகிறிஸ்துவின் மூலமாய் என்றென்றைக்கும் மகிமை உண்டாவதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans16