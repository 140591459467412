import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 20 </title>
	<meta
          name="description"
          content="John Chapter 20 | யோவான் அதிகாரம் 20 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. வாரத்தின் முதல்நாள் காலையில், அதிக இருட்டோடே, மகதலேனா மரியாள் கல்லறையினிடத்திற்கு வந்து கல்லறையை அடைத்திருந்த கல் எடுத்துப் போட்டிருக்கக்கண்டாள்.
	<br /><br />
	2. உடனே அவள் ஓடி, சீமோன்பேதுருவினிடத்திலும் இயேசுவுக்கு அன்பாயிருந்த மற்றச் சீஷனிடத்திலும் போய்: கர்த்தரைக் கல்லறையிலிருந்து எடுத்துக்கொண்டு போய்விட்டார்கள், அவரை வைத்த இடம் எங்களுக்குத் தெரியவில்லை என்றாள்.
	<br /><br />
	3. அப்பொழுது பேதுருவும் மற்றச் சீஷனும் கல்லறையினிடத்திற்குப் போகும்படி புறப்பட்டு, இருவரும் ஒருமித்து ஓடினார்கள்.
	<br /><br />
	4. பேதுருவைப்பார்க்கிலும் மற்றச் சீஷன் துரிதமாய் ஓடி, முந்திக் கல்லறையினிடத்தில் வந்து,
	<br /><br />
	5. அதற்குள்ளே குனிந்துபார்த்து, சீலைகள் கிடக்கிறதைக் கண்டான்; ஆனாலும் அவன் உள்ளே போகவில்லை.
	<br /><br />
	6. சீமோன்பேதுரு அவனுக்குப் பின்னே வந்து, கல்லறைக்குள்ளே பிரவேசித்து,
	<br /><br />
	7. சீலைகள் கிடக்கிறதையும், அவருடைய தலையில் சுற்றியிருந்த சீலை மற்றச் சீலைகளுடனே வைத்திராமல் தனியே ஒரு இடத்திலே சுருட்டி வைத்திருக்கிறதையும் கண்டான்.
	<br /><br />
	8. முந்திக் கல்லறையினிடத்திற்கு வந்த மற்றச் சீஷனும் அப்பொழுது உள்ளே பிரவேசித்து, கண்டு விசுவாசித்தான்.
	<br /><br />
	9. அவர் மரித்தோரிலிருந்து எழுந்திருக்கவேண்டும் என்கிற வேதவாக்கியத்தை அவர்கள் இன்னும் அறியாதிருந்தார்கள்.
	<br /><br />
	10. பின்பு அந்தச் சீஷர்கள் தங்களுடைய இடத்திற்குத் திரும்பிப்போனார்கள்.
	<br /><br />
	11. மரியாள் கல்லறையினருகே வெளியே நின்று அழுதுகொண்டிருந்தாள்; அப்படி அழுதுகொண்டிருக்கையில் அவள் குனிந்து கல்லறைக்குள்ளே பார்த்து,
	<br /><br />
	12. இயேசுவின் சரீரம் வைக்கப்பட்டிருந்த இடத்திலே வெள்ளுடை தரித்தவர்களாய் இரண்டு தூதர்கள், தலைமாட்டில் ஒருவனும் கால்மாட்டில் ஒருவனுமாக, உட்கார்ந்திருக்கிறதைக் கண்டாள்.
	<br /><br />
	13. அவர்கள் அவளை நோக்கி: ஸ்திரீயே, ஏன் அழுகிறாய் என்றார்கள். அதற்கு அவள்: என் ஆண்டவரை எடுத்துக்கொண்டு போய்விட்டார்கள், அவரை வைத்த இடம் எனக்குத் தெரியவில்லை என்றாள்.
	<br /><br />
	14. இவைகளைச் சொல்லிப் பின்னாகத் திரும்பி, இயேசு நிற்கிறதைக் கண்டாள்; ஆனாலும் அவரை இயேசு என்று அறியாதிருந்தாள்.
	<br /><br />
	15. இயேசு அவளைப் பார்த்து: ஸ்திரீயே, ஏன் அழுகிறாய், யாரைத் தேடுகிறாய் என்றார். அவள், அவரைத் தோட்டக்காரனென்று எண்ணி: ஐயா, நீர் அவரை எடுத்துக்கொண்டு போனதுண்டானால், அவரை வைத்த இடத்தை எனக்குச் சொல்லும், நான் போய் அவரை எடுத்துக்கொள்ளுவேன் என்றாள்.
	<br /><br />
	16. இயேசு அவளை நோக்கி: மரியாளே என்றார். அவள் திரும்பிப் பார்த்து: ரபூனி என்றாள்; அதற்குப் போதகரே என்று அர்த்தமாம்.
	<br /><br />
	17. இயேசு அவளை நோக்கி: என்னைத் தொடாதே, நான் இன்னும் என் பிதாவினிடத்திற்கு ஏறிப்போகவில்லை; நீ என் சகோதரரிடத்திற்குப் போய், நான் என் பிதாவினிடத்திற்கும் உங்கள் பிதாவினிடத்திற்கும், என் தேவனிடத்திற்கும் உங்கள் தேவனிடத்திற்கும் ஏறிப்போகிறேன் என்று அவர்களுக்குச் சொல்லு என்றார்.
	<br /><br />
	18. மகதலேனா மரியாள் போய், தான் கர்த்தரைக் கண்டதையும், அவர் தன்னுடனே சொன்னவைகளையும் சீஷருக்கு அறிவித்தாள்.
	<br /><br />
	19. வாரத்தின் முதல்நாளாகிய அன்றையத்தினம் சாயங்காலவேளையிலே, சீஷர்கள் கூடியிருந்த இடத்தில், யூதர்களுக்குப் பயந்ததினால் கதவுகள் பூட்டியிருக்கையில், இயேசு வந்து நடுவே நின்று: உங்களுக்குச் சமாதானம் என்றார்.
	<br /><br />
	20. அவர் இப்படிச் சொல்லித் தம்முடைய கைகளையும் விலாவையும் அவர்களுக்குக் காண்பித்தார். சீஷர்கள் கர்த்தரைக்கண்டு சந்தோஷப்பட்டார்கள்.
	<br /><br />
	21. இயேசு மறுபடியும் அவர்களை நோக்கி: உங்களுக்குச் சமாதானமுண்டாவதாக; பிதா என்னை அனுப்பினதுபோல நானும் உங்களை அனுப்புகிறேன் என்று சொல்லி,
	<br /><br />
	22. அவர்கள்மேல் ஊதி: பரிசுத்த ஆவியைப் பெற்றுக்கொள்ளுங்கள்;
	<br /><br />
	23. எவர்களுடைய பாவங்களை மன்னிக்கிறீர்களோ அவைகள் அவர்களுக்கு மன்னிக்கப்படும், எவர்களுடைய பாவங்களை மன்னியாதிருக்கிறீர்களோ அவைகள் அவர்களுக்கு மன்னிக்கப்படாதிருக்கும் என்றார்.
	<br /><br />
	24. இயேசு வந்திருந்தபோது பன்னிருவரில் ஒருவனாகிய திதிமு என்னப்பட்ட தோமா என்பவன் அவர்களுடனேகூட இருக்கவில்லை.
	<br /><br />
	25. மற்றச் சீஷர்கள்: கர்த்தரைக் கண்டோம் என்று அவனுடனே சொன்னார்கள். அதற்கு அவன்: அவருடைய கைகளில் ஆணிகளினாலுண்டான காயத்தை நான் கண்டு, அந்தக் காயத்திலே என் விரலையிட்டு, என் கையை அவருடைய விலாவிலே போட்டாலொழிய விசுவாசிக்கமாட்டேன் என்றான்.
	<br /><br />
	26. மறுபடியும் எட்டுநாளைக்குப்பின்பு அவருடைய சீஷர்கள் வீட்டுக்குள்ளே இருந்தார்கள்; தோமாவும் அவர்களுடனேகூட இருந்தான்; கதவுகள் பூட்டப்பட்டிருந்தது. அப்பொழுது இயேசு வந்து நடுவே நின்று: உங்களுக்குச் சமாதானம் என்றார்.
	<br /><br />
	27. பின்பு அவர் தோமாவை நோக்கி: நீ உன் விரலை இங்கே நீட்டி, என் கைகளைப் பார், உன் கையை நீட்டி, என் விலாவிலே போடு, அவிசுவாசியாயிராமல் விசுவாசியாயிரு என்றார்.
	<br /><br />
	28. தோமா அவருக்குப் பிரதியுத்தரமாக: என் ஆண்டவரே! என் தேவனே! என்றான்.
	<br /><br />
	29. அதற்கு இயேசு: தோமாவே, நீ என்னைக் கண்டதினாலே விசுவாசித்தாய், காணாதிருந்தும் விசுவாசிக்கிறவர்கள் பாக்கியவான்கள் என்றார்.
	<br /><br />
	30. இந்தப் புஸ்தகத்தில் எழுதியிராத வேறு அநேக அற்புதங்களையும் இயேசு தமது சீஷருக்கு முன்பாகச் செய்தார்.
	<br /><br />
	31. இயேசு தேவனுடைய குமாரனாகிய கிறிஸ்து என்று நீங்கள் விசுவாசிக்கும்படியாகவும், விசுவாசித்து அவருடைய நாமத்தினாலே நித்தியஜீவனை அடையும்படியாகவும், இவைகள் எழுதப்பட்டிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John20