import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 20 </title>
	<meta
          name="description"
          content="The Book of Revelation - 20 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 20 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* சாத்தான் கட்டப்பட்டான், தியாகிகள் கிறிஸ்துவுடன் 1,000 ஆண்டுகள் ஆட்சி செய்தனர். பிறகு சாத்தான் நெருப்புக் கடலில் தள்ளப்பட்டான். இறந்தவர்கள் தீர்ப்பளிக்கப்பட்டனர் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. ஒரு தூதன் பாதாளத்தின் திறவுகோலையும் பெரிய சங்கிலியையும் தன் கையிலே பிடித்துக்கொண்டு வானத்திலிருந்திறங்கி வரக்கண்டேன்.
	<br /><br />
	2. பிசாசென்றும் சாத்தானென்றும் சொல்லப்பட்ட பழைய பாம்பாகிய வலுசர்ப்பத்தை அவன் பிடித்து, அதை ஆயிரம் வருஷமளவுங் கட்டிவைத்து, அந்த ஆயிரம் வருஷம் நிறைவேறும்வரைக்கும் அது ஜனங்களை மோசம்போக்காதபடிக்கு அதைப் பாதாளத்திலே தள்ளியடைத்து, அதின்மேல் முத்திரைபோட்டான்.
	<br /><br />
	3. அதற்குப் பின்பு அது கொஞ்சக்காலம் விடுதலையாகவேண்டும்.
	<br /><br />
	4. அன்றியும், நான் சிங்காசனங்களைக் கண்டேன்; அவைகளின்மேல் உட்கார்ந்தார்கள்; நியாயத்தீர்ப்புக் கொடுக்கும்படி அவர்களுக்கு அதிகாரம் அளிக்கப்பட்டது. இயேசுவைப்பற்றிய சாட்சியினிமித்தமும் தேவனுடைய வசனத்தினிமித்தமும் சிரச்சேதம்பண்ணப்பட்டவர்களுடைய ஆத்துமாக்களையும், மிருகத்தையாவது அதின் சொரூபத்தையாவது வணங்காமலும் தங்கள் நெற்றியிலும் தங்கள் கையிலும் அதின் முத்திரையைத் தரித்துக்கொள்ளாமலும் இருந்தவர்களையும் கண்டேன். அவர்கள் உயிர்த்துக் கிறிஸ்துவுடனேகூட ஆயிரம் வருஷம் அரசாண்டார்கள்.
	<br /><br />
	5. மரணமடைந்த மற்றவர்கள் அந்த ஆயிரம் வருஷம் முடியுமளவும் உயிரடையவில்லை. இதுவே முதலாம் உயிர்த்தெழுதல்.
	<br /><br />
	6. முதலாம் உயிர்த்தெழுதலுக்குப் பங்குள்ளவன் பாக்கியவானும் பரிசுத்தவானுமாயிருக்கிறான்; இவர்கள்மேல் இரண்டாம் மரணத்திற்கு அதிகாரமில்லை; இவர்கள் தேவனுக்கும் கிறிஸ்துவுக்கும் முன்பாக ஆசாரியராயிருந்து, அவரோடேகூட ஆயிரம் வருஷம் அரசாளுவார்கள்.
	<br /><br />
	7. அந்த ஆயிரம் வருஷம் முடியும்போது சாத்தான் தன் காவலிலிருந்து விடுதலையாகி,
	<br /><br />
	8. பூமியின் நான்கு திசைகளிலுமுள்ள ஜாதிகளாகிய கோகையும் மாகோகையும் மோசம்போக்கும்படிக்கும், அவர்களை யுத்தத்திற்குக் கூட்டிக்கொள்ளும்படிக்கும் புறப்படுவான்; அவர்களுடைய தொகை கடற்கரை மணலத்தனையாயிருக்கும்.
	<br /><br />
	9. அவர்கள் பூமியெங்கும் பரம்பி, பரிசுத்தவான்களுடைய பாளையத்தையும், பிரியமான நகரத்தையும் வளைந்துகொண்டார்கள்; அப்பொழுது தேவனால் வானத்திலிருந்து அக்கினி இறங்கி அவர்களைப் பட்சித்துப்போட்டது.
	<br /><br />
	10. மேலும் அவர்களை மோசம்போக்கின பிசாசானவன், மிருகமும் கள்ளத்தீர்க்கதரிசியுமிருக்கிற இடமாகிய அக்கினியும் கந்தகமுமான கடலிலே தள்ளப்பட்டான். அவர்கள் இரவும் பகலும் சதாகாலங்களிலும் வாதிக்கப்படுவார்கள்.
	<br /><br />
	11. பின்பு, நான் பெரிய வெள்ளைச் சிங்காசனத்தையும் அதின்மேல் வீற்றிருக்கிறவரையும் கண்டேன்; அவருடைய சமுகத்திலிருந்து பூமியும் வானமும் அகன்றுபோயின; அவைகளுக்கு இடங்காணப்படவில்லை.
	<br /><br />
	12. மரித்தோராகிய சிறியோரையும் பெரியோரையும் தேவனுக்குமுன்பாக நிற்கக்கண்டேன்; அப்பொழுது புஸ்தகங்கள் திறக்கப்பட்டன; ஜீவபுஸ்தகம் என்னும் வேறொரு புஸ்தகமும் திறக்கப்பட்டது; அப்பொழுது அந்தப் புஸ்தகங்களில் எழுதப்பட்டவைகளின்படியே மரித்தோர் தங்கள் தங்கள் கிரியைகளுக்குத்தக்கதாக நியாயத்தீர்ப்படைந்தார்கள்.
	<br /><br />
	13. சமுத்திரம் தன்னிலுள்ள மரித்தோரை ஒப்புவித்தது; மரணமும் பாதாளமும் தங்களிலுள்ள மரித்தோரை ஒப்புவித்தன. யாவரும் தங்கள் தங்கள் கிரியைகளின்படியே நியாயத்தீர்ப்படைந்தார்கள்.
	<br /><br />
	14. அப்பொழுது மரணமும் பாதாளமும் அக்கினிக்கடலிலே தள்ளப்பட்டன. இது இரண்டாம் மரணம்.
	<br /><br />
	15. ஜீவபுஸ்தகத்திலே எழுதப்பட்டவனாகக் காணப்படாதவனெவனோ அவன் அக்கினிக்கடலிலே தள்ளப்பட்டான்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h20>
*  *
			</h20>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation20