import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 5 </title>
	<meta
          name="description"
          content="Mark 5 | மாற்கு 5 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர்கள் கடலுக்கு அக்கரையிலுள்ள கதரேனருடைய நாட்டில் வந்தார்கள்.
	<br /><br />
	2. அவர் படவிலிருந்து இறங்கினவுடனே, அசுத்த ஆவியுள்ள ஒரு மனுஷன் பிரேதக்கல்லறைகளிலிருந்து அவருக்கு எதிராக வந்தான்.
	<br /><br />
	3. அவனுடைய குடியிருப்பு கல்லறைகளிலே இருந்தது; அவனைச் சங்கிலிகளினாலும் கட்ட ஒருவனாலும் கூடாதிருந்தது.
	<br /><br />
	4. அவன் அநேகந்தரம் விலங்குகளினாலும் சங்கிலிகளினாலும் கட்டப்பட்டிருந்தும், சங்கிலிகளை முறித்து, விலங்குகளைத் தகர்த்துப்போடுவான்; அவனையடக்க ஒருவனாலும் கூடாதிருந்தது.
	<br /><br />
	5. அவன் எப்பொழுதும் இரவும் பகலும், மலைகளிலும் கல்லறைகளிலும் இருந்து, கூக்குரலிட்டு, கல்லுகளினாலே தன்னைக் காயப்படுத்திக்கொண்டிருந்தான்.
	<br /><br />
	6. அவன் இயேசுவைத் தூரத்திலே கண்டபோது, ஓடிவந்து, அவரைப் பணிந்துகொண்டு:
	<br /><br />
	7. இயேசுவே, உன்னதமான தேவனுடைய குமாரனே, எனக்கும் உமக்கும் என்ன? என்னை வேதனைப்படுத்தாதபடிக்குத் தேவன்பேரில் உமக்கு ஆணையென்று மிகுந்த சத்தமிட்டுச் சொன்னான்.
	<br /><br />
	8. ஏனெனில் அவர் அவனை நோக்கி: அசுத்த ஆவியே, இந்த மனுஷனை விட்டுப் புறப்பட்டுப் போ என்று சொல்லியிருந்தார்.
	<br /><br />
	9. அப்பொழுது அவர் அவனை நோக்கி: உன் பேர் என்னவென்று கேட்டார். அதற்கு அவன்: நாங்கள் அநேகராயிருக்கிறபடியால் என் பேர் லேகியோன் என்று சொல்லி,
	<br /><br />
	10. தங்களை அந்தத் திசையிலிருந்து துரத்திவிடாதபடிக்கு அவரை மிகவும் வேண்டிக்கொண்டான்.
	<br /><br />
	11. அப்பொழுது, அவ்விடத்தில் மலையருகே அநேகம் பன்றிகள் கூட்டமாக மேய்ந்துகொண்டிருந்தது.
	<br /><br />
	12. அந்தப் பிசாசுகளெல்லாம் அவரை நோக்கி: பன்றிகளுக்குள்ளே போகும்படி, அவைகளுக்குள்ளே எங்களை அனுப்பும் என்று அவரை வேண்டிக்கொண்டன.
	<br /><br />
	13. இயேசு அவைகளுக்கு உத்தரவு கொடுத்தவுடனே, அசுத்த ஆவிகள் புறப்பட்டுப் பன்றிகளுக்குள் போயின; உடனே ஏறக்குறைய இரண்டாயிரம் பன்றிகளுள்ள அந்தக் கூட்டம் உயர்ந்த மேட்டிலிருந்து ஓடி, கடலிலே பாய்ந்து, கடலில் அமிழ்ந்து மாண்டது.
	<br /><br />
	14. பன்றிகளை மேய்த்தவர்கள் ஓடி, இதைப் பட்டணத்திலும் சுற்றுப்புறங்களிலும் அறிவித்தார்கள். அப்பொழுது சம்பவித்ததைப் பார்க்கும்படி ஜனங்கள் புறப்பட்டு;
	<br /><br />
	15. இயேசுவினிடத்தில் வந்து, லேகியோனாகிய பிசாசுகள் பிடித்திருந்தவன் வஸ்திரந்தரித்து, உட்கார்ந்து, புத்தி தெளிந்திருக்கிறதைக் கண்டு, பயந்தார்கள்.
	<br /><br />
	16. பிசாசுகள் பிடித்திருந்தவனுக்கும் பன்றிகளுக்கும் சம்பவித்ததைக் கண்டவர்களும் அவர்களுக்கு விவரமாய்ச் சொன்னார்கள்.
	<br /><br />
	17. அப்பொழுது தங்கள் எல்லைகளை விட்டுப் போகும்படி அவரை வேண்டிக்கொள்ளத் தொடங்கினார்கள்.
	<br /><br />
	18. அப்படியே அவர் படவில் ஏறுகிறபொழுது, பிசாசு பிடித்திருந்தவன், அவரோடேகூட இருக்கும்படி தனக்கு உத்தரவுகொடுக்க அவரை வேண்டிக்கொண்டான்.
	<br /><br />
	19. இயேசு அவனுக்கு உத்தரவுகொடாமல்: நீ உன் இனத்தாரிடத்தில் உன் வீட்டிற்குப்போய், கர்த்தர் உனக்கு இரங்கி, உனக்குச் செய்தவைகளையெல்லாம் அவர்களுக்கு அறிவியென்று சொன்னார்.
	<br /><br />
	20. அந்தப்படி அவன் போய், இயேசு தனக்குச் செய்தவைகளையெல்லாம் தெக்கப்போலி என்னும் நாட்டில் பிரசித்தம்பண்ணத்தொடங்கினான்; எல்லாரும் ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	21. இயேசு படவில் ஏறி மறுபடியும் இக்கரைக்கு வந்து, கடலோரத்திலிருந்தபோது, திரளான ஜனங்கள் அவரிடத்தில் கூடிவந்தார்கள்.
	<br /><br />
	22. அப்பொழுது, ஜெபஆலயத்தலைவரில் ஒருவனாகிய யவீரு என்பவன் வந்து, அவரைக் கண்டவுடனே, அவர் பாதத்திலே விழுந்து:
	<br /><br />
	23. என் குமாரத்தி மரண அவஸ்தைப்படுகிறாள், அவள் ஆரோக்கியம் அடையும்படிக்கு நீர் வந்து, அவள்மேல் உமது கைகளை வையும், அப்பொழுது பிழைப்பாள் என்று அவரை மிகவும் வேண்டிக்கொண்டான்.
	<br /><br />
	24. அவர் அவனோடேகூடப் போனார். திரளான ஜனங்கள் அவருக்குப் பின்சென்று, அவரை நெருக்கினார்கள்.
	<br /><br />
	25. அப்பொழுது பன்னிரண்டு வருஷமாய்ப் பெரும்பாடுள்ள ஒரு ஸ்திரீ,
	<br /><br />
	26. அநேக வைத்தியர்களால் மிகவும் வருத்தப்பட்டு, தனக்கு உண்டானவைகளையெல்லாம் செலவழித்தும், சற்றாகிலும் குணமடையாமல் அதிக வருத்தப்படுகிறபொழுது,
	<br /><br />
	27. இயேசுவைக்குறித்துக் கேள்விப்பட்டு: நான் அவருடைய வஸ்திரங்களையாகிலும் தொட்டால் சொஸ்தமாவேன் என்று சொல்லி;
	<br /><br />
	28. ஜனக்கூட்டத்துக்குள்ளே அவருக்குப் பின்னாக வந்து, அவருடைய வஸ்திரத்தைத் தொட்டாள்.
	<br /><br />
	29. உடனே அவளுடைய உதிரத்தின் ஊறல் நின்றுபோயிற்று; அந்த வேதனை நீங்கி ஆரோக்கியமடைந்ததை அவள் தன் சரீரத்தில் உணர்ந்தாள்.
	<br /><br />
	30. உடனே இயேசு தம்மிலிருந்து வல்லமை புறப்பட்டதைத் தமக்குள் அறிந்து, ஜனக்கூட்டத்துக்குள்ளே திரும்பி: என் வஸ்திரங்களைத் தொட்டது யார் என்று கேட்டார்.
	<br /><br />
	31. அவருடைய சீஷர்கள் அவரை நோக்கி: திரளான ஜனங்கள் உம்மை நெருக்கிக்கொண்டிருக்கிறதை நீர் கண்டும், என்னைத் தொட்டது யார் என்று கேட்கிறீரே என்றார்கள்.
	<br /><br />
	32. இதைச் செய்தவளைக் காணும்படிக்கு அவர் சுற்றிலும் பார்த்தார்.
	<br /><br />
	33. தன்னிடத்திலே சம்பவித்ததை அறிந்த அந்த ஸ்திரீயானவள் பயந்து, நடுங்கி, அவர் முன்பாக வந்து விழுந்து, உண்மையையெல்லாம் அவருக்குச் சொன்னாள்.
	<br /><br />
	34. அவர் அவளைப் பார்த்து: மகளே, உன் விசுவாசம் உன்னை இரட்சித்தது, நீ சமாதானத்தோடேபோய், உன் வேதனை நீங்கி, சுகமாயிரு என்றார்.
	<br /><br />
	35. அவர் இப்படிப் பேசிக்கொண்டிருக்கையில், ஜெபஆலயத்தலைவனுடைய வீட்டிலிருந்து சிலர் வந்து: உம்முடைய குமாரத்தி மரித்துப்போனாள், இனி ஏன் போதகரை வருத்தப்படுத்துகிறீர் என்றார்கள்.
	<br /><br />
	36. அவர்கள் சொன்ன வார்த்தையை இயேசு கேட்டவுடனே, ஜெபஆலயத்தலைவனை நோக்கி: பயப்படாதே, விசுவாசமுள்ளவனாயிரு என்று சொல்லி;
	<br /><br />
	37. பேதுருவையும், யாக்கோபையும், யாக்கோபின் சகோதரன் யோவானையும் தவிர, வேறொருவரும் தம்மோடே வருகிறதற்கு இடங்கொடாமல்;
	<br /><br />
	38. ஜெபஆலயத்தலைவனுடைய வீட்டிலே வந்து, சந்தடியையும் மிகவும் அழுது புலம்புகிறவர்களையும் கண்டு,
	<br /><br />
	39. உள்ளே பிரவேசித்து: நீங்கள் சந்தடிபண்ணி அழுகிறதென்ன? பிள்ளை மரிக்கவில்லை, நித்திரையாயிருக்கிறாள் என்றார்.
	<br /><br />
	40. அதற்காக அவரைப் பார்த்து நகைத்தார்கள். எல்லாரையும் அவர் வெளியே போகப்பண்ணி, பிள்ளையின் தகப்பனையும் தாயையும் தம்மோடே வந்தவர்களையும் அழைத்துக்கொண்டு, பிள்ளையிருந்த இடத்தில் பிரவேசித்து,
	<br /><br />
	41. பிள்ளையின் கையைப் பிடித்து: தலீத்தாகூமி என்றார்; அதற்கு, சிறுபெண்ணே எழுந்திரு என்று உனக்குச் சொல்லுகிறேன் என்று அர்த்தமாம்.
	<br /><br />
	42. உடனே சிறுபெண் எழுந்து நடந்தாள்; அவள் பன்னிரண்டு வயதுள்ளவளாயிருந்தாள். அவர்கள் மிகுந்த ஆச்சரியப்பட்டுப் பிரமித்தார்கள்.
	<br /><br />
	43. அதை ஒருவருக்கும் அறிவியாதபடி அவர்களுக்கு உறுதியாகக் கட்டளையிட்டு, அவளுக்கு ஆகாரம் கொடுக்கும்படி சொன்னார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark5