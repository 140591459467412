import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 21 </title>
	<meta
          name="description"
          content="Ezekiel 21 | எசேக்கியேல்  21 |
          Ezekiel 21 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ உன் முகத்தை எருசலேமுக்கு நேராகத் திருப்பி, பரிசுத்த ஸ்தலங்களுக்கு விரோதமாக உன் வசனத்தைப் பொழிந்து, இஸ்ரவேல் தேசத்துக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து,
	<br /><br />
	3. இஸ்ரவேல் தேசத்தை நோக்கி, கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், இதோ, நான் உனக்கு விரோதமாக வந்து, என் பட்டயத்தை அதின் உறையிலிருந்து உருவி, உன்னில் சன்மார்க்கனையும் துன்மார்க்கனையும் சங்காரம்பண்ணுவேன்.
	<br /><br />
	4. நான் உன்னில் சன்மார்க்கனையும் துன்மார்க்கனையும் சங்காரம்பண்ணப் போகிறபடியினால் தெற்கு துவக்கி வடக்குமட்டுமுள்ள எல்லா மாம்சத்துக்கும் விரோதமாய் என் பட்டயம் அதின் உறையிலிருந்து புறப்படும்.
	<br /><br />
	5. அப்பொழுது கர்த்தராகிய நான் என் பட்டயத்தை அதின் உறையிலிருந்து உருவினேன் என்பதை எல்லா மாம்சமும் அறியும்; அது இனி உறைக்குள் திரும்புவதில்லை.
	<br /><br />
	6. ஆதலால் மனுபுத்திரனே, உன் இடுப்பு நொறுங்கும்படி பெருமூச்சுவிடு; அவர்கள் கண்களுக்கு முன்பாக மனங்கசந்து பெருமூச்சுவிடு.
	<br /><br />
	7. நீ எதினிமித்தம் பெருமூச்சுவிடுகிறாய் என்று அவர்கள் உன்னிடத்தில் கேட்டால், நீ அவர்களை நோக்கி: துர்ச்செய்தி வருகிறதினிமித்தமே; அதினால், இருதயங்களெல்லாம் உருகி, கைகளெல்லாம் தளர்ந்து, மனமெல்லாம் தியங்கி, முழங்கால்களெல்லாம் தண்ணீரைப்போல அலைவுண்ணும்; இதோ, அது வந்து சம்பவிக்கும் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	8. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	9. மனுபுத்திரனே, நீ தீர்க்கதரிசனம் உரைத்து, சொல்லவேண்டியது என்னவென்றால்: பட்டயம் கூர்மையாக்கப்பட்டது, பட்டயம் கூர்மையாக்கப்பட்டது; அது துலக்கப்பட்டுமிருக்கிறது.
	<br /><br />
	10. மகா சங்காரஞ்செய்வதற்கு அது கூர்மையாக்கப்பட்டிருக்கிறது; மின்னத்தக்கதாய் அது துலக்கப்பட்டிருக்கிறது; சந்தோஷப்படுவோமோ? அது என் குமாரனுடைய கோல், அது சகல விருட்சங்களையும் அலட்சியம்பண்ணும்.
	<br /><br />
	11. அதைக் கையாடும்படி அதைத்துலக்கக் கொடுத்தார்; கொல்லுகிறவன் கையிலே கொடுக்கும்படி அந்தப் பட்டயம் கூர்மையாக்கப்பட்டிருக்கிறது; அது துலக்கப்பட்டதுமாயிருக்கிறது என்று கர்த்தர் உரைக்கிறார் என்று சொல்.
	<br /><br />
	12. மனுபுத்திரனே, நீ ஓலமிட்டு அலறு; பட்டயம் என் ஜனத்தின்மேல் வரும்; அது இஸ்ரவேல் பிரபுக்கள் எல்லார்மேலும் வரும்; அதினிமித்தம் என் ஜனத்துக்குள்ளே திகில் உண்டாயிருக்கும்; ஆகையால் உன் விலாவிலே அடித்துக்கொள்.
	<br /><br />
	13. யாவையும் கண்டிக்கிற கோல் வந்தாலொழிய இனிச் சோதனையினால் தீருகிறதென்னவென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	14. ஆகையால் மனுபுத்திரனே, நீ தீர்க்கதரிசனம் சொல்லி, கையோடே கைகொட்டு; பட்டயம் மூன்றுதரம் இரட்டித்துவரும்; அது கொலையுண்டவர்களின் பட்டயம்; அது கொலையுண்ணப்போகிற பெரியவர்களின் உள்ளறைகளில் பிரவேசிக்கிற பட்டயம்.
	<br /><br />
	15. அவர்களுடைய இருதயம் கரைந்து, அவர்களுடைய இடையூறுகள் திரளாகும்படி, பட்டயத்தின் கூர்மையை அவர்களுடைய எல்லா வாசல்களுக்கும் நேரிடக் கட்டளையிடுவேன்; ஆ, அது மின்னும்படியாகப் பதமிடப்பட்டது, வெட்டும்படியாகத் தீட்டிவைக்கப்பட்டது.
	<br /><br />
	16. ஏகபலமாய் வலதுபுறமாக வெட்டு, திரும்பி இடதுபுறமாகவும் வெட்டு; உன் முகம் திரும்புகிற திக்கெல்லாம் வெட்டு.
	<br /><br />
	17. நானும் கையோடே கைகொட்டி, என் உக்கிரத்தை ஆறப்பண்ணுவேன் என்று கர்த்தராகிய நான் சொன்னேன் என்றார்.
	<br /><br />
	18. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	19. மனுபுத்திரனே, பாபிலோன் ராஜாவின் பட்டயம் வரத்தக்கதாக இரண்டுவழிகளைக் குறித்துக்கொள்; இரண்டும் ஒரே தேசத்திலிருந்து வரவேண்டும்; நீ ஒரு இடத்தைத் தெரிந்துகொள், நகரத்துக்குப் போகிற வழியின் முனையில் அந்த இடத்தைத் தெரிந்துகொள்.
	<br /><br />
	20. பட்டயம் அம்மோன் புத்திரரின் பட்டணமாகிய ரப்பாவுக்கு விரோதமாக வரத்தக்க ஒரு வழியையும், யூதாவில் இருக்கிற அரணான எருசலேமுக்கு விரோதமாக வரத்தக்க ஒரு வழியையும் குறித்துக்கொள்.
	<br /><br />
	21. பாபிலோன் ராஜா இரண்டு வழிகளின் முனையாகிய வழிப்பிரிவிலே நிமித்தம் பார்க்கிறதற்காக நிற்பான்; அம்புகளைத் தீட்டி, விக்கிரகங்களை உசாவி, ஈரலால் குறிபார்ப்பான்.
	<br /><br />
	22. தலைவரை நியமிக்கிறதற்கும், சங்காரஞ்செய்யும்படி ஆர்ப்பரிக்கிறதற்கும், கெம்பீரமாய்ச் சத்தமிடுகிறதற்கும், வாசல்களை முட்டும் யந்திரங்களை வைக்கிறதற்கும், மண்மேடு போடுகிறதற்கும், கொத்தளங்களைக் கட்டுகிறதற்கும், எருசலேமைக்குறித்து நிமித்தம் பார்க்குதல் அவனுடைய வலதுபுறத்திலே உண்டாயிருக்கும்.
	<br /><br />
	23. இந்த நிமித்தமானது ஆணையிட்டவர்களுக்கு முன்பாகப் பொய்யாகத் தோன்றும்; ஆயினும் அவர்கள் பிடிக்கப்படும்படி அவன் அவர்கள் துரோகத்தை நினைப்பான்.
	<br /><br />
	24. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: உங்கள் துரோகங்கள் வெளியரங்கமாகிறதினாலும், உங்கள் செய்கைகளிலெல்லாம் உங்கள் பாவங்கள் தெரியவருகிறதினாலும், நீங்கள் உங்கள் அக்கிரமத்தை நினைக்கப்பண்ணுகிறீர்களே; நீங்கள் இப்படிப்பட்டவர்களென்று நினைக்கப்படுகிறீர்களே; ஆதலால் கைப்பிடியாய்ப் பிடிக்கப்படுவீர்கள்.
	<br /><br />
	25. இஸ்ரவேலை ஆளுகிற அவபக்தியுள்ள துன்மார்க்க அதிபதியே, அக்கிரமத்துக்கு முடிவுவருங்காலத்தில் உன் நாள் வந்தது.
	<br /><br />
	26. பாகையைக் கழற்று, கிரீடத்தை எடுத்துப்போடு; அது இனி முன்போலிராது; தாழ்ந்தவனை உயர்த்தி, உயர்ந்தவனைத் தாழ்த்துவேன்.
	<br /><br />
	27. அதைக் கவிழ்ப்பேன், கவிழ்ப்பேன், கவிழ்ப்பேன்; உரிமைக்காரனானவர் வருமட்டும் அது இல்லாதிருக்கும்; அவருக்கே அதைக் கொடுப்பேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	28. பின்னும் மனுபுத்திரனே, நீ தீர்க்கதரிசனம் சொல்லு: அம்மோன் புத்திரரையும் அவர்கள் சொல்லும் நிந்தனைகளையும் குறித்துக் கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்,
	<br /><br />
	29. அக்கிரமத்துக்கு முடிவுவருங்காலத்தில் வந்த தங்களுடைய நாளுக்கு ஏதுவாகி, கொலையுண்டுபோனவர்களுடைய பிடரிகளோடேகூட உன்னைத் துன்மார்க்கரின் கையினால் விழப்பண்ணும்படிக்கு, உனக்கு அபத்தமானது தரிசிக்கப்படுகிறபோதும், உனக்குப் பொய்நிமித்தம் பார்க்கப்படுகிறபோதும் பட்டயம் உருவப்பட்டது, பட்டயமே உருவப்பட்டது; வெட்டவும் சங்கரிக்கவும் அது மின்னத்தக்கதாய்த் துலக்கப்பட்டிருக்கிறது.
	<br /><br />
	30. உன் பட்டயத்தை நீ திரும்ப அதின் உறையிலே போடு; நீ சிருஷ்டிக்கப்பட்ட ஸ்தானமாகிய உன் ஜெந்மதேசத்திலே நான் உன்னை நியாயந்தீர்த்து,
	<br /><br />
	31. என் சினத்தை உன்மேல் ஊற்றுவேன்; நான் என் மூர்க்கத்தின் அக்கினியை உன்மேல் ஊதி, மிருககுணமுள்ளவர்களும், அழிக்கிறதற்குத் திறமையுள்ளவர்களுமாகிய மனுஷரின் கையில் உன்னை ஒப்புக்கொடுப்பேன்.
	<br /><br />
	32. நீ அக்கினிக்கு இரையாவாய்; உன் இரத்தம் உன் தேசத்தின் நடுவில் சிந்திக்கிடக்கும்; நீ இனி நினைக்கப்படுவதில்லை; கர்த்தராகிய நான் இதைச் சொன்னேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial21