import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 6 </title>
	<meta
          name="description"
          content="Isaiah 6 | ஏசாயா 6 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உசியா ராஜா மரணமடைந்த வருஷத்தில், ஆண்டவர் உயரமும் உன்னதமுமான சிங்காசனத்தின்மேல் வீற்றிருக்கக்கண்டேன்; அவருடைய வஸ்திரத்தொங்கலால் தேவாலயம் நிறைந்திருந்தது.
	<br /><br />
	2. சேராபீன்கள் அவருக்கு மேலாக நின்றார்கள்; அவர்களில் ஒவ்வொருவனுக்கும் அவ்வாறு செட்டைகளிருந்தன; அவனவன் இரண்டு செட்டைகளால் தன் தன் முகத்தை மூடி, இரண்டு செட்டைகளால் தன் தன் கால்களை மூடி, இரண்டு செட்டைகளால் பறந்து;
	<br /><br />
	3. ஒருவரையொருவர் நோக்கி: சேனைகளின் கர்த்தர் பரிசுத்தர், பரிசுத்தர், பரிசுத்தர், பூமியனைத்தும் அவருடைய மகிமையால் நிறைந்திருக்கிறது என்று கூப்பிட்டுச் சொன்னார்கள்.
	<br /><br />
	4. கூப்பிடுகிறவர்களின் சத்தத்தால் வாசல்களின் நிலைகள் அசைந்து, ஆலயம் புகையினால் நிறைந்தது.
	<br /><br />
	5. அப்பொழுது நான்: ஐயோ! அதமானேன், நான் அசுத்த உதடுகளுள்ள மனுஷன், அசுத்த உதடுகளுள்ள ஜனங்களின் நடுவில் வாசமாயிருக்கிறவன்; சேனைகளின் கர்த்தராகிய ராஜாவை என் கண்கள் கண்டதே என்றேன்.
	<br /><br />
	6. அப்பொழுது சேராபீன்களில் ஒருவன் பலிபீடத்திலிருந்து, தன் கையிலே பிடித்த குறட்டால் ஒரு நெருப்புத்தழலை எடுத்து, என்னிடத்தில் பறந்து வந்து,
	<br /><br />
	7. அதினால் என் வாயைத் தொட்டு: இதோ, இது உன் உதடுகளைத் தொட்டதினால் உன் அக்கிரமம் நீங்கி, உன் பாவம் நிவிர்த்தியானது என்றான்.
	<br /><br />
	8. பின்பு: யாரை நான் அனுப்புவேன், யார் நமது காரியமாய்ப் போவான் என்று உரைக்கிற ஆண்டவருடைய சத்தத்தைக் கேட்டேன். அதற்கு நான்: இதோ, அடியேன் இருக்கிறேன்; என்னை அனுப்பும் என்றேன்.
	<br /><br />
	9. அப்பொழுது அவர்: நீ போய், இந்த ஜனங்களை நோக்கி, நீங்கள் காதாரக் கேட்டும் உணராமலும், கண்ணாரக்கண்டும் அறியாமலும் இருங்கள் என்று சொல்.
	<br /><br />
	10. இந்த ஜனங்கள் தங்கள் கண்களினால் காணாமலும், தங்கள் காதுகளினால் கேளாமலும், தங்கள் இருதயத்தினால் உணர்ந்து குணப்படாமலும், நான் அவர்களை ஆரோக்கியமாக்காமலுமிருக்க, நீ அவர்கள் இருதயத்தைக் கொழுத்ததாக்கி, அவர்கள் காதுகளை மந்தப்படுத்தி, அவர்கள் கண்களை மூடிப்போடு என்றார்.
	<br /><br />
	11. அப்பொழுது நான்: ஆண்டவரே, எதுவரைக்கும் என்று கேட்டேன். அதற்கு அவர்: பட்டணங்கள் குடியில்லாமலும், வீடுகள் மனுஷசஞ்சாரமில்லாமலும் பாழாகி, பூமி அவாந்தரவெளியாகி,
	<br /><br />
	12. கர்த்தர் மனுஷரைத் தூரமாக விலக்குவதினால், தேசத்தின் நடுமையம் முற்றிலும் வெறுமையாக்கப்படும் வரைக்குமே.
	<br /><br />
	13. ஆகிலும் அதில் இன்னும் பத்தில் ஒரு பங்கிருக்கும், அதுவும் திரும்ப நிர்மூலமாக்கப்படும்; கர்வாலிமரமும் அரசமரமும் இலையற்றுப்போனபின்பு, அவைகளின் அடிமரம் இருப்பதுபோல, அதின் அடிமரமும் பரிசுத்த வித்தாயிருக்கும் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah6