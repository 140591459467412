import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah42 = () => {
  const verseNumber = 42;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 42 </title>
	<meta
          name="description"
          content="Jeremiah 42 | எரேமியா 42 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது எல்லா இராணுவச் சேர்வைக்காரரும், கரேயாவின் குமாரனாகிய யோகனானும், ஓசாயாவின் குமாரனாகிய யெசனியாவும், சிறியோர் முதல் பெரியோர்மட்டுமான சகல ஜனங்களும் சேர்ந்துவந்து,
	<br /><br />
	2. தீர்க்கதரிசியாகிய எரேமியாவை நோக்கி: உம்முடைய தேவனாகிய கர்த்தர் நாங்கள் நடக்கவேண்டிய வழியையும், செய்யவேண்டிய காரியத்தையும் எங்களுக்குத் தெரியப்பண்ணும்படிக்கு, நீர் எங்கள் விண்ணப்பத்துக்கு இடங்கொடுத்து, மீதியாயிருக்கிற இந்தச் சகல ஜனங்களாகிய எங்களுக்காக உம்முடைய தேவனாகிய கர்த்தரை நோக்கி ஜெபம்பண்ணும்.
	<br /><br />
	3. உம்முடைய கண்கள் எங்களைக் காண்கிறபடியே திரளான ஜனங்களில் கொஞ்சப்பேரே மீந்திருக்கிறோம் என்றார்கள்.
	<br /><br />
	4. அப்பொழுது எரேமியா தீர்க்கதரிசி அவர்களை நோக்கி: நீங்கள் சொன்னதைக் கேட்டேன்; இதோ, உங்கள் வார்த்தையின்படியே உங்கள் தேவனாகிய கர்த்தரை நோக்கி ஜெபம்பண்ணுவேன்; கர்த்தர் உங்களுக்கு மறுஉத்தரவாகச் சொல்லும் எல்லா வார்த்தைகளையும் நான் உங்களுக்கு ஒன்றையும் மறைக்காமல் அறிவிப்பேன் என்றான்.
	<br /><br />
	5. அப்பொழுது அவர்கள் எரேமியாவை நோக்கி: உம்முடைய தேவனாகிய கர்த்தர் உம்மைக்கொண்டு எங்களுக்குச் சொல்லியனுப்பும் எல்லா வார்த்தைகளின்படியும் நாங்கள் செய்யாவிட்டால், கர்த்தர் நமக்கு நடுவே சத்தியமும் உண்மையுமான சாட்சியாயிருக்கக்கடவர்.
	<br /><br />
	6. அது நன்மையானாலும் தீமையானாலும் சரி, எங்கள் தேவனாகிய கர்த்தரின் சத்தத்துக்கு நாங்கள் கீழ்ப்படிவதினால் எங்களுக்கு நன்மையுண்டாகும்படி நாங்கள் உம்மை அனுப்புகிற எங்கள் தேவனாகிய கர்த்தருடைய சத்தத்துக்குக் கீழ்ப்படிந்து நடப்போம் என்றார்கள்.
	<br /><br />
	7. பத்துநாள் சென்றபின்பு, கர்த்தருடைய வார்த்தை எரேமியாவுக்கு உண்டாயிற்று.
	<br /><br />
	8. அப்பொழுது அவன், கரேயாவின் குமாரனாகிய யோகனானையும், அவனோடிருந்த எல்லா இராணுவச் சேர்வைக்காரரையும், சிறியோர்முதல் பெரியோர்மட்டும் உண்டான சகல ஜனங்களையும் அழைத்து,
	<br /><br />
	9. அவர்களை நோக்கி: உங்களுக்காக விண்ணப்பஞ்செய்யும்படிக்கு நீங்கள் என்னை அனுப்பின இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறது என்னவென்றால்,
	<br /><br />
	10. நீங்கள் இந்தத் தேசத்திலே தரித்திருந்தால், நான் உங்களைக் கட்டுவேன், உங்களை இடிக்கமாட்டேன்; உங்களை நாட்டுவேன், உங்களைப் பிடுங்கமாட்டேன்; நான் உங்களுக்குச் செய்திருக்கிற தீங்குக்கு மனஸ்தாபப்பட்டேன்.
	<br /><br />
	11. நீங்கள் பயப்படுகிற பாபிலோன் ராஜாவுக்குப் பயப்படவேண்டாம், அவனுக்குப் பயப்படாதிருப்பீர்களாக என்று கர்த்தர் சொல்லுகிறார், உங்களை இரட்சிக்கும்படிக்கும், உங்களை அவன் கைக்குத் தப்புவிக்கும்படிக்கும் நான் உங்களுடனே இருந்து,
	<br /><br />
	12. அவன் உங்களுக்கு இரங்குகிறதற்கும், உங்கள் சுயதேசத்துக்கு உங்களைத் திரும்பிவரப்பண்ணுகிறதற்கும் உங்களுக்கு இரக்கஞ்செய்வேன்.
	<br /><br />
	13. நீங்கள் உங்கள் தேவனாகிய கர்த்தருடைய சத்தத்துக்குச் செவிகொடாமல், நாங்கள் இந்தத் தேசத்திலே இருக்கிறதில்லையென்றும்,
	<br /><br />
	14. நாங்கள் யுத்தத்தைக் காணாததும், எக்காள சத்தத்தைக் கேளாததும், அப்பத்தாழ்ச்சியினால் பட்டினியாய் இராததுமான எகிப்து தேசத்துக்கே போய், அங்கே தரித்திருப்போம் என்றும் சொல்வீர்களேயாகில்,
	<br /><br />
	15. யூதாவில் மீந்திருக்கிறவர்களே, அதைக்குறித்து உண்டான கர்த்தருடைய வார்த்தையைக் கேளுங்கள்: நீங்கள் எகிப்துக்குப் போக உங்கள் முகங்களைத் திருப்பி, அங்கே தங்கப்போவீர்களானால்,
	<br /><br />
	16. நீங்கள் பயப்படுகிற பட்டயம் எகிப்து தேசத்திலே உங்களைப் பிடிக்கும்; நீங்கள் ஐயப்படுகிற பஞ்சம் எகிப்திலே உங்களைத் தொடர்ந்து வரும், அங்கே சாவீர்கள்.
	<br /><br />
	17. எகிப்திலே தங்கவேண்டுமென்று அவ்விடத்துக்குத் தங்கள் முகங்களைத் திருப்பின எல்லா மனுஷருக்கும் என்ன சம்பவிக்குமென்றால், பட்டயத்தாலும், பஞ்சத்தாலும், கொள்ளைநோயாலும் சாவார்கள்; நான் அவர்கள்மேல் வரப்பண்ணும் தீங்கினாலே அவர்களில் மீதியாகிறவர்களும் தப்புகிறவர்களுமில்லை என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. என் கோபமும் என் உக்கிரமும் எருசலேமின் குடிகள்மேல் எப்படி மூண்டதோ, அப்படியே என் உக்கிரம் நீங்கள் எகிப்துக்குப் போகும்போது, உங்கள்மேல் மூளும்; நீங்கள் சாபமாகவும் பாழாகவும் பழிப்பாகவும் நிந்தையாகவும் இருந்து, இவ்விடத்தை இனிக்காணாதிருப்பீர்கள் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	19. யூதாவில் மீதியானவர்களே, எகிப்துக்குப் போகாதிருங்கள் என்று கர்த்தர் உங்களைக்குறித்துச் சொன்னாரென்பதை இந்நாளில் உங்களுக்குச் சாட்சியாக அறிவித்தேன் என்று அறியுங்கள்.
	<br /><br />
	20. உங்கள் ஆத்துமாக்களுக்கு விரோதமாய் உங்களை மோசம்போக்கினீர்கள்; நீ எங்கள் தேவனாகிய கர்த்தரை நோக்கி: எங்களுக்காக விண்ணப்பம்பண்ணி, எங்கள் தேவனாகிய கர்த்தர் சொல்வதையெல்லாம் எங்களுக்கு அறிவிக்கவேண்டும்; அதின்படியே செய்வோம் என்று நீங்கள் சொல்லி, என்னை உங்கள் தேவனாகிய கர்த்தரிடத்துக்கு அனுப்பினீர்கள்.
	<br /><br />
	21. நான் இந்நாளில் அதை உங்களுக்கு அறிவித்தேன்; ஆனாலும், உங்கள் தேவனாகிய கர்த்தரின் சத்தத்துக்கும், அவர் என்னைக்கொண்டு உங்களுக்குச் சொல்லியனுப்பின எந்தக்காரியத்துக்கும் செவிகொடாமற்போனீர்கள்.
	<br /><br />
	22. இப்போதும் தங்கியிருப்பதற்கு நீங்கள் போக விரும்புகிற ஸ்தலத்தில்தானே பட்டயத்தாலும், பஞ்சத்தாலும், கொள்ளைநோயாலும் சாவீர்களென்று நிச்சயமாய் அறியுங்கள் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah42