import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoJohn2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Use Discernment in Hospitality 2 John TamilBible 2  யோவான் 1 </title>
	<meta
          name="description"
          content="Use Discernment in Hospitality| 2 -John in Tamil Bible Verse | 2-யோவான்அதிகாரம் - 1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible "
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* விருந்தோம்பலில் விவேகத்தைப் பயன்படுத்துங்கள் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. நமக்குள் நிலைநிற்கிறதும் என்றென்றைக்கும் நம்மோடிருப்பதுமாகிய சத்தியத்தினிமித்தம், நான் மாத்திரமல்ல, சத்தியத்தை அறிந்திருக்கிற யாவரும் சத்தியத்தின்படி நேசித்திருக்கிறவளும்,
	<br /><br />
	2. தெரிந்துகொள்ளப்பட்டவளுமாகிய அம்மாளுக்கும் அவளுடைய பிள்ளைகளுக்கும், மூப்பனாகிய நான் எழுதுகிறதாவது:
	<br /><br />
	3. பிதாவாகிய தேவனாலும் பிதாவின் குமாரனாயிருக்கிற கர்த்தராகிய இயேசுகிறிஸ்துவினாலும், கிருபையும் இரக்கமும் சமாதானமும், சத்தியத்தோடும் அன்போடுங்கூட, உங்களோடிருப்பதாக.
	<br /><br />
	4. பிதாவினால் நாம் பெற்ற கற்பனையின்படியே உம்முடைய பிள்ளைகளில் சிலர் சத்தியத்திலே நடக்கிறதை நான் கண்டு மிகவும் சந்தோஷப்பட்டேன்.
	<br /><br />
	5. இப்பொழுதும் அம்மாளே, நாம் ஒருவரிலொருவர் அன்புகூரவேண்டுமென்று, உமக்குப் புதிய கற்பனையாக எழுதாமல், ஆதிமுதல் நமக்கு உண்டாயிருக்கிற கற்பனையாக எழுதி, உம்மை வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	6. நாம் அவருடைய கற்பனைகளின்படி நடப்பதே அன்பு; நீங்கள் ஆதிமுதல் கேட்டிருக்கிறபடி நடந்துகொள்ளவேண்டிய கற்பனை இதுவே.
	<br /><br />
	7. மாம்சத்தில் வந்த இயேசுகிறிஸ்துவை அறிக்கைபண்ணாத அநேக வஞ்சகர் உலகத்திலே தோன்றியிருக்கிறார்கள்; இப்படிப்பட்டவனே வஞ்சகனும் அந்திக்கிறிஸ்துவுமாயிருக்கிறான்.
	<br /><br />
	8. உங்கள் செய்கைகளின் பலனை இழந்துபோகாமல், பூரண பலனைப் பெறும்படிக்கு எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	9. கிறிஸ்துவின் உபதேசத்திலே நிலைத்திராமல் மீறி நடக்கிற எவனும் தேவனை உடையவனல்ல; கிறிஸ்துவின் உபதேசத்தில் நிலைத்திருக்கிறவனோ பிதாவையும் குமாரனையும் உடையவன்.
	<br /><br />
	10. ஒருவன் உங்களிடத்தில் வந்து இந்த உபதேசத்தைக் கொண்டுவராமலிருந்தால், அவனை உங்கள் வீட்டிலே ஏற்றுக்கொள்ளாமலும், அவனுக்கு வாழ்த்துதல் சொல்லாமலும் இருங்கள்.
	<br /><br />
	11. அவனுக்கு வாழ்த்துதல் சொல்லுகிறவன் அவனுடைய துர்க்கிரியைகளுக்கும் பங்குள்ளவனாகிறான்.
	<br /><br />
	12. உங்களுக்கு எழுதவேண்டிய காரியங்கள் அநேகமுண்டு; காகிதத்தினாலும் மையினாலும் அவைகளை எழுத எனக்கு மனதில்லை. உங்களுடைய சந்தோஷம் நிறைவாயிருக்கும்படிக்கு உங்களிடத்தில் வந்து, முகமுகமாய்ப் பேசலாமென்று நம்பியிருக்கிறேன்.
	<br /><br />
	13. தெரிந்துகொள்ளப்பட்ட உம்முடைய சகோதரியின் பிள்ளைகள் உமக்கு வாழ்த்துதல் சொல்லுகிறார்கள். ஆமென்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 2 வயது முதிர்ந்த ஒரு மூப்பரான அப்போஸ்தலன் யோவானின் வாழ்த்துடன் யோவான் ஆரம்பிக்கிறார். அன்பில் நடக்க வேண்டும் என்ற கடவுளின் கட்டளைக்குக் கீழ்ப்படிவதற்கான வழிமுறைகளுடன் அவர் திறக்கிறார். பின்னர் அவர் கியர்களை மாற்றி, தவறான போதகர்களை அவளது வீட்டிற்குள் வரவேற்பதற்கு எதிராக எச்சரித்தார். இயேசு கிறிஸ்து உடல் வடிவில் வருவதை மறுக்கும் போதகர்கள். விருந்தோம்பல் அவளிடம் எதிர்பார்க்கப்படும் அதே வேளையில், நம்பிக்கையை அழிக்க முற்படுபவர்களுக்கு எதிராக பாகுபாடு காட்டுவது பரவாயில்லை என்பதை அவர் அவளுக்குத் தெரியப்படுத்துகிறார். மேலும் இந்த விஷயங்களைப் பற்றி விவாதித்துக்கொண்டு நேரில் வந்து அவளைப் பார்க்க வேண்டும் என்று தனது விருப்பத்தைத் தெரிவித்து கடிதத்தை முடிக்கிறார் *
<br /><br /> 
<br /><br /> 
* நீங்கள் II யோவான் புத்தகத்தைப் படித்து முடிக்கும்போது நீங்கள் ஆசீர்வதிக்கப்படுவீர்கள். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default TwoJohn2