import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial46 = () => {
  const verseNumber = 46;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 46 </title>
	<meta
          name="description"
          content="Ezekiel 46 | எசேக்கியேல்  46 |
          Ezekiel 46 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: வேலைசெய்கிற ஆறுநாளிலும் கிழக்குக்கு எதிரான உட்பிராகாரத்தினுடைய வாசல் பூட்டப்பட்டிருந்து, ஓய்வுநாளிலும் மாதப்பிறப்பிலும் திறக்கப்படக்கடவது.
	<br /><br />
	2. அப்பொழுது அதிபதி வெளிவாசல் மண்டபத்தின் வழியாய்ப் பிரவேசித்து, வாசல் நிலையண்டையிலே நிற்கக்கடவன்; ஆசாரியர்களோ அவனுடைய தகனபலியையும், அவனுடைய சமாதான பலிகளையும் படைக்கக்கடவர்கள்; அவன் வாசற்படியிலே ஆராதனைசெய்து, பின்பு புறப்படுவானாக; அந்த வாசல் சாயங்காலமட்டும் பூட்டப்படாதிருப்பதாக.
	<br /><br />
	3. தேசத்து ஜனங்களும் ஓய்வுநாட்களிலும் மாதப்பிறப்புகளிலும் அந்த வாசலின் நடையிலே கர்த்தருடைய சந்நிதியில் ஆராதனை செய்யக்கடவர்கள்.
	<br /><br />
	4. அதிபதி ஓய்வுநாளிலே கர்த்தருக்குப் பலியிடும் தகனபலி, பழுதற்ற ஆறு ஆட்டுக்குட்டிகளும் பழுதற்ற ஒரு ஆட்டுக்கடாவுமே.
	<br /><br />
	5. ஆட்டுக்கடாவோடே போஜனபலியாக ஒரு மரக்கால் மாவையும், ஆட்டுக்குட்டிகளோடே போஜனபலியாகத் தன் திராணிக்குத்தக்கதாய்த் தருகிற ஈவையும், ஒவ்வொரு மரக்கால் மாவோடே ஒருபடி எண்ணெயையும் படைக்கக்கடவன்.
	<br /><br />
	6. மாதப்பிறப்பான நாளிலோ, அவன் பழுதற்ற ஒரு இளங்காளையையும், பழுதற்ற ஆறு ஆட்டுக்குட்டிகளையும், ஒரு ஆட்டுக்கடாவையும் பலியிட்டு,
	<br /><br />
	7. போஜனபலியாக இளங்காளையோடே ஒரு மரக்கால் மாவையும், ஆட்டுக்கடாவோடே ஒரு மரக்கால் மாவையும், ஆட்டுக்குட்டிகளோடே தன் திராணிக்குத்தக்கதாய், ஒவ்வொரு மரக்கால் மாவோடே ஒருபடி எண்ணெயையும் படைக்கக்கடவன்.
	<br /><br />
	8. அதிபதி வருகிறபோது வாசல் மண்டபத்தின் வழியாய்ப் பிரவேசித்து, அது வழியாய்த் திரும்பப் புறப்படக்கடவன்.
	<br /><br />
	9. தேசத்தின் ஜனங்கள் குறிக்கப்பட்ட நாட்களில் கர்த்தருடைய சந்நிதியில் வரும்போது, ஆராதனை செய்கிறதற்காக வடக்கு வாசல்வழியாய் உட்பிரவேசித்தவன் தெற்கு வாசல் வழியாய்ப் புறப்படவும், தெற்கு வாசல்வழியாய் உட்பிரவேசித்தவன் வடக்கு வாசல்வழியாய்ப் புறப்படவும்கடவன்; தான் பிரவேசித்த வாசல்வழியாய்த் திரும்பிப்போகாமல், தனக்கு எதிரான வழியாய்ப் புறப்பட்டுப்போவானாக.
	<br /><br />
	10. அவர்கள் உட்பிரவேசிக்கும்போது, அதிபதி அவர்கள் நடுவிலே அவர்களோடேகூட உட்பிரவேசித்து, அவர்கள் புறப்படும்போது அவனும் கூடப் புறப்படுவானாக.
	<br /><br />
	11. பண்டிகைகளிலும் குறிக்கப்பட்ட காலங்களிலும் அவன் படைக்கும் போஜனபலியாவது: காளையோடே ஒரு மரக்கால் மாவும், ஆட்டுக்கடாவோடே ஒரு மரக்கால் மாவும், ஆட்டுக்குட்டிகளோடே அவன் திராணிக்குத்தக்கதாய்த் தருகிற ஒரு ஈவும், ஒவ்வொரு மரக்கால் மாவோடே ஒருபடி எண்ணெயும் கொடுக்கவேண்டும்.
	<br /><br />
	12. அதிபதி உற்சாகமான தகனபலியாகிலும், சமாதான பலிகளையாகிலும் கர்த்தருக்கு உற்சாகமாய்ச் செலுத்தவேண்டுமென்றால், அவனுக்குக் கிழக்கு நோக்கிய எதிரான வாசல் திறக்கப்படுவதாக; அப்பொழுது அவன் ஓய்வுநாளில் செய்கிறதுபோல, தன் தகனபலியையும் தன் சமாதான பலியையும் செலுத்தி, பின்பு புறப்படக்கடவன்; அவன் புறப்பட்டபின்பு வாசல் பூட்டப்படவேண்டும்.
	<br /><br />
	13. தினந்தோறும் ஒரு வயதான பழுதற்ற ஒரு ஆட்டுக்குட்டியைக் கர்த்தருக்குத் தகனபலியாகப் படைக்கக்கடவாய்; காலைதோறும் அதைப்படைக்கவேண்டும்.
	<br /><br />
	14. அதினோடே காலைதோறும் போஜனபலியாக ஒரு மரக்கால் மாவிலே ஆறத்தொரு பங்கையும், மெல்லிய மாவைப் பிசையும்படிக்கு ஒருபடி எண்ணெயிலே மூன்றத்தொரு பங்கையும் படைக்கக்கடவாய்; இது அன்றாடம் கர்த்தருக்குப் படைக்கவேண்டிய நித்திய கட்டளையான போஜனபலி.
	<br /><br />
	15. இப்படிக் காலைதோறும் அன்றாட தகனபலியாக ஆட்டுக்குட்டியையும் போஜனபலியையும் எண்ணெயையும் செலுத்துவார்களாக.
	<br /><br />
	16. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: அதிபதி தன் குமாரரில் ஒருவனுக்குத் தன் சுதந்தரத்தில் ஒரு பங்கைக் கொடுத்தால், அது அவன் குமாரருடையதாயிருக்கும்; அது சுதந்தரவீதமாய் அவர்களுக்குச் சொந்தமாகும்.
	<br /><br />
	17. அவன் தன் ஊழியக்காரரில் ஒருவனுக்குத் தன் சுதந்தரத்தில் ஒரு பங்கைக் கொடுத்தானேயாகில், அது விடுதலையின் வருஷமட்டும் அவனுடையதாயிருந்து, பின்பு திரும்ப அதிபதியின் வசமாய்ச் சேரும்; அதின் சுதந்தரம் அவன் குமாரருக்கே உரியது, அது அவர்களுடையதாயிருக்கும்.
	<br /><br />
	18. அதிபதியானவன் ஜனத்தை இடுக்கண் செய்து, அவர்களின் சொந்தமானதற்கு அவர்களைப் புறம்பாக்கி, அவர்களுடைய சுதந்தரத்திலிருந்து ஒன்றும் எடுக்கலாகாது; என் ஜனத்தில் ஒருவரும் தங்கள் சொந்தமானதற்குப் புறம்பாக்கப்பட்டுச் சிதறடிக்கப்படாதபடிக்கு அவன் தன் சொந்தத்திலே தன் குமாரருக்குச் சுதந்தரம் கொடுக்கக்கடவன்.
	<br /><br />
	19. பின்பு அவர் வாசலின் பக்கத்தில் இருந்த நடைவழியாய் என்னை வடக்குக்கு எதிரான ஆசாரியர்களுடைய பரிசுத்த அறைவீடுகளுக்கு அழைத்துக்கொண்டுபோனார்; அவ்விடத்தில் மேற்கே இருபுறத்திலும் ஒரு இடம் இருந்தது.
	<br /><br />
	20. அவர் என்னை நோக்கி: குற்றநிவாரணபலியையும் பாவநிவாரணபலியையும் போஜனபலியையும் ஆசாரியர்கள் வெளிப்பிராகாரத்திலே கொண்டுபோய் ஜனங்களைப் பரிசுத்தம்பண்ணாதபடிக்கு, அவர்கள் அவைகளைச் சமைக்கிறதற்கும் சுடுகிறதற்குமான ஸ்தலம் இதுவே என்றார்.
	<br /><br />
	21. பின்பு அவர் என்னை வெளிப்பிராகாரத்தில் அழைத்துக்கொண்டுபோய், என்னைப் பிராகாரத்தின் நாலு மூலைகளையும் கடந்துபோகப்பண்ணினார்; பிராகாரத்து ஒவ்வொரு மூலையிலும் ஒவ்வொரு முற்றம் இருந்தது.
	<br /><br />
	22. பிராகாரத்தின் நாலு மூலைகளிலும் புகைத்துவாரங்களுள்ள இந்த முற்றங்கள் நாற்பது முழ நீளமும், முப்பது முழ அகலமுமானவைகள்; இந்த நாலு மூலை முற்றங்களுக்கும் ஒரே அளவு இருந்தது.
	<br /><br />
	23. இந்த நாலுக்கும் சுற்றிலும் உள்ளே ஒரு சுற்றுக்கட்டு உண்டாயிருந்தது; இந்தச் சுற்றுக்கட்டுகளின் சுற்றிலும் அடுப்புகள் போடப்பட்டிருந்தது.
	<br /><br />
	24. அவர் என்னை நோக்கி: இவைகள் ஜனங்கள் இடும் பலிகளை ஆலயத்தின் பணிவிடைக்காரர் சமைக்கிற வீடுகள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial46