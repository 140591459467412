import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 21 </title>
	<meta
          name="description"
          content="Isaiah 21 | ஏசாயா 21 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கடல் வனாந்தரத்தின் பாரம். சுழல்காற்று தென்திசையிலிருந்து எழும்பிக் கடந்துவருகிறதுபோல, பயங்கரமான தேசமாகிய வனாந்தரத்திலிருந்து அது வருகிறது.
	<br /><br />
	2. கொடிய தரிசனம் எனக்குத் தெரிவிக்கப்பட்டது; துரோகி துரோகம்பண்ணி, பாழாக்கிறவன் பாழாக்கிக்கொண்டே இருக்கிறான்; ஏலாமே எழும்பு; மேதியாவே முற்றிக்கைபோடு; அதினாலே உண்டான தவிப்பையெல்லாம் ஒழியப்பண்ணினேன்.
	<br /><br />
	3. ஆகையால், என் இடுப்பு மகா வேதனையால் நிறைந்திருக்கிறது; பிள்ளைபெறுகிறவளின் வேதனைகளுக்கு ஒத்த வேதனைகள் என்னைப் பிடித்தது; கேட்டதினால் உளைவுகொண்டு, கண்டதினால் கலங்கினேன்.
	<br /><br />
	4. என் இருதயம் திகைத்தது; திகில் என்னைத் திடுக்கிடப்பண்ணிற்று; எனக்கு இன்பந்தந்த இரவு பயங்கரமாயிற்று.
	<br /><br />
	5. பந்தியை ஆயத்தப்படுத்துங்கள், ஜாமக்காரரை வையுங்கள், புசியுங்கள், குடியுங்கள்; பிரபுக்களே, எழுந்து பரிசைகளுக்கு எண்ணெய் பூசுங்கள்.
	<br /><br />
	6. ஆண்டவர் என்னை நோக்கி: நீ போய், காண்பதைத் தெரிவிக்கும்படி ஜாமக்காரனை வை என்றார்.
	<br /><br />
	7. அவன் ஒரு இரதத்தையும், ஜோடு ஜோடான குதிரைவீரரையும், ஜோடு ஜோடாகக் கழுதைகளின்மேலும் ஒட்டகங்களின்மேலும் ஏறிவருகிறவர்களையும் கண்டு, மிகுந்த கவனமாய்க் கவனித்துக்கொண்டே இருந்து:
	<br /><br />
	8. ஆண்டவரே, நான் பகல்முழுதும் என் காவலிலே நின்று, இராமுழுதும் நான் என் காவலிடத்திலே தரித்திருக்கிறேன் என்று சிங்கத்தைப்போல் சத்தமிட்டுக் கூப்பிடுகிறான்.
	<br /><br />
	9. இதோ, ஒரு ஜோடு குதிரை பூண்ட இரதத்தின்மேல் ஏறியிருக்கிற ஒரு மனுஷன் வருகிறான்; பாபிலோன் விழுந்தது, விழுந்தது; அதின் விக்கிரக தேவர்களையெல்லாம் தரையோடே மோதி உடைத்தார் என்று பிரதியுத்தரம் சொல்லுகிறான்.
	<br /><br />
	10. என் போரடிப்பின் தானியமே, என் களத்தின் கோதுமையே, இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தரால் நான் கேள்விப்பட்டதை உங்களுக்கு அறிவித்தேன்.
	<br /><br />
	11. தூமாவின் பாரம். சேயீரிலிருந்து என்னை நோக்கி: ஜாமக்காரனே, இரவு எவ்வளவு சென்றது? என்று கூப்பிட்டுக் கேட்க;
	<br /><br />
	12. அதற்கு ஜாமக்காரன்: விடியற்காலம் வருகிறது, இராக்காலமும் வருகிறது; நீங்கள் கேட்க மனதானால் திரும்பிவந்து கேளுங்கள் என்று சொல்லுகிறான்.
	<br /><br />
	13. அரபியாவின் பாரம். திதானியராகிய பயணக்கூட்டங்களே, நீங்கள் அரபியாவின் காடுகளில் இராத்தங்குவீர்கள்.
	<br /><br />
	14. தேமாதேசத்தின் குடிகளே, நீங்கள் தாகமாயிருக்கிறவர்களுக்குத் தண்ணீர் கொண்டுபோய், தப்பி ஓடுகிறவர்களுக்கு அப்பங்கொடுக்க எதிர்கொண்டுபோங்கள்.
	<br /><br />
	15. அவர்கள், பட்டயங்களுக்கும், உருவின கட்கத்துக்கும், நாணேற்றின வில்லுக்கும், யுத்தத்தின் கொடுமைக்கும் தப்பி ஓடுகிறார்கள்.
	<br /><br />
	16. ஆண்டவர் என்னை நோக்கி: ஒரு கூலிக்காரனுடைய வருஷங்களுக்கொத்த ஒரே வருஷத்திலே கேதாருடைய மகிமையெல்லாம் அற்றுப்போம்.
	<br /><br />
	17. கேதார் புத்திரராகிய பராக்கிரம வில்வீரரின் தொகையில் மீதியானவர்கள் கொஞ்சப்பேராயிருப்பார்கள் என்றார்; இஸ்ரவேலின் தேவனாகிய கர்த்தர் இதை உரைத்தார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah21