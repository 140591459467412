import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 20 </title>
	<meta
          name="description"
          content="Jeremiah 20 | எரேமியா 20 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எரேமியா இந்த வார்த்தைகளைத் தீர்க்கதரிசனமாகச் சொல்லுகிறதை ஆசாரியனான இம்மேருடைய குமாரனும், கர்த்தருடைய ஆலயத்துப் பிரதான விசாரணைக் கர்த்தனுமாகிய பஸ்கூர் கேட்டபோது,
	<br /><br />
	2. எரேமியா தீர்க்கதரிசியைப் பஸ்கூர் அடித்து, அவனைக் கர்த்தருடைய ஆலயத்திலே பென்யமீன் கோத்திரத்தாரைச் சேர்ந்த மேல்வாசலில் இருக்கும் காவலறையிலே போட்டான்.
	<br /><br />
	3. மறுநாளிலே பஸ்கூர் எரேமியாவைக் காவலறையிலிருந்து வெளியே போகவிட்டான்; அப்பொழுது எரேமியா அவனை நோக்கி: கர்த்தர் உன்னைப் பஸ்கூர் என்று அழைக்காமல், மாகோர்மீசாபீப் என்று அழைக்கிறார்.
	<br /><br />
	4. மேலும் கர்த்தர்: இதோ, நான் உன்னையும், உன் எல்லாச் சிநேகிதரையும் பயத்துக்கு ஒப்புக்கொடுக்கிறேன்; உன் கண்கள் காண இவர்கள் சத்துருக்களின் பட்டயத்தால் விழுவார்கள்; யூதா அனைத்தையும் நான் பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுப்பேன்; அவன் அவர்களைச் சிறைபிடித்துச் சிலரைப் பாபிலோனுக்குக் கொண்டுபோய்ச் சிலரைப் பட்டயத்தால் வெட்டிப்போடுவான்.
	<br /><br />
	5. இந்த நகரத்தின் எல்லாப் பலத்தையும், அதின் எல்லாச் சம்பத்தையும், அதின் அருமையான எல்லாப் பொருள்களையும், யூதா ராஜாக்களின் எல்லாப் பொக்கிஷங்களையும், நான் அவர்கள் சத்துருக்கள் கையில் ஒப்புக்கொடுப்பேன்; அவர்கள் அவைகளைக் கொள்ளையிட்டு, பாபிலோனுக்குக் கொண்டுபோவார்கள்.
	<br /><br />
	6. பஸ்கூரே, நீயும் உன் வீட்டில் வாசமாயிருக்கிற யாவரும் சிறைப்பட்டுப்போவீர்கள்; நீயும் உன் கள்ளத்தீர்க்கதரிசனத்துக்குச் செவிகொடுத்த உன் சிநேகிதர் யாவரும் பாபிலோனுக்குப் போய், அங்கே மரித்து, அங்கே அடக்கம்பண்ணப்படுவீர்களென்று சொல்லுகிறார் என்றான்.
	<br /><br />
	7. கர்த்தாவே, என்னை இணங்கப்பண்ணினீர், நான் இணங்கினேன்; நீர் என்னிலும் பலத்தவராயிருந்து, என்னை மேற்கொண்டீர்; நாள்தோறும் நகைப்புக்கு இடமானேன்; எல்லாரும் என்னைப் பரிகாசம்பண்ணுகிறார்கள்.
	<br /><br />
	8. நான் பேசினது முதற்கொண்டு கதறுகிறேன்; கொடுமையென்றும் பாழ்க்கடிப்பென்றும் சத்தமிட்டுக் கூறுகிறேன்; நான் கூறின கர்த்தருடைய வார்த்தை நாள்தோறும் எனக்கு நிந்தையும், பரிகாசமுமாயிற்று.
	<br /><br />
	9. ஆதலால் நான் அவரைப் பிரஸ்தாபம்பண்ணாமலும் இனிக் கர்த்தருடைய நாமத்திலே பேசாமலும் இருப்பேன் என்றேன்; ஆனாலும் அவருடைய வார்த்தை என் எலும்புகளில் அடைபட்டு எரிகிற அக்கினியைப்போல் என் இருதயத்தில் இருந்தது; அதைச் சகித்து இளைத்துப்போனேன்; எனக்குப் பொறுக்கக்கூடாமற்போயிற்று.
	<br /><br />
	10. அநேகர் சொல்லும் அவதூறைக் கேட்டேன், பயஞ்சூழ்ந்திருந்தது; அறிவியுங்கள், அப்பொழுது நாங்கள் அதை அறிவிப்போம் என்கிறார்கள்; என்னோடே சமாதானமாயிருந்த அனைவரும் நான் தவறிவிழும்படிக் காத்திருந்து: ஒருவேளை இணங்குவான், அப்பொழுது அவனை மேற்கொண்டு, அவனில் குரோதந்தீர்த்துக்கொள்வோம் என்கிறார்கள்.
	<br /><br />
	11. கர்த்தரோ பயங்கரமான பராக்கிரமசாலியாய் என்னோடு இருக்கிறார், ஆகையால் என்னைத் துன்பப்படுத்துகிறவர்கள் மேற்கொள்ளாமல் இடறுவார்கள்; தங்கள் காரியம் வாய்க்காதபடியால் மிகவும் வெட்கப்படுவார்கள்; மறக்கப்படாத நித்திய இலச்சை அவர்களுக்கு உண்டாகும்.
	<br /><br />
	12. ஆனாலும் நீதிமானைச் சோதித்தறிந்து, உள்ளிந்திரியங்களையும் இருதயத்தையும் பார்க்கிற சேனைகளின் கர்த்தாவே, நீர் அவர்களுக்கு நீதியைச் சரிக்கட்டுகிறதைக் காண்பேனாக; என் காரியத்தை உம்மிடத்தில் சாட்டிவிட்டேன்.
	<br /><br />
	13. கர்த்தரைப் பாடுங்கள், கர்த்தரைத் துதியுங்கள்; அவர் எளியவனுடைய ஆத்துமாவைப் பொல்லாதவர்களின் கைக்குத் தப்புவிக்கிறார்.
	<br /><br />
	14. நான் பிறந்தநாள் சபிக்கப்படுவதாக; என் தாயார் என்னைப் பெற்ற நாள் ஆசீர்வதிக்கப்படாதிருப்பதாக.
	<br /><br />
	15. உமக்கு ஒரு ஆண்பிள்ளை பிறந்ததென்று என் தகப்பனுக்கு நற்செய்தியாக அறிவித்து, அவனை மிகவும் சந்தோஷப்படுத்தின மனுஷன் சபிக்கப்படக்கடவன்.
	<br /><br />
	16. அந்த மனுஷன், கர்த்தர் மனம் மாறாமல் கவிழ்த்துப்போட்ட பட்டணங்களைப்போலிருந்து, காலமே அலறுதலையும் மத்தியான வேளையிலே கூக்குரலையும் கேட்கக்கடவன்.
	<br /><br />
	17. என் தாயார் எனக்குப் பிரேதக்குழியும், நான் என்றைக்கும் பிரசவியாத சூலுமாய் இருக்கத்தக்கதாகக் கர்ப்பத்திலே நான் கொலைசெய்யப்படாமற்போனதென்ன?
	<br /><br />
	18. நான் வருத்தத்தையும் சஞ்சலத்தையும் கண்டு, என் நாட்கள் வெட்கமாய்க் கழியும்படிக்கு நான் கர்ப்பத்திலிருந்து வெளிப்பட்டதென்ன?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah20