import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-3 </title>
	<meta
          name="description"
          content="Ecclesiastes 3 | பிரசங்கி 3 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஒவ்வொன்றிற்கும் ஒவ்வொரு காலமுண்டு; வானத்தின் கீழிருக்கிற ஒவ்வொரு காரியத்துக்கும் ஒவ்வொரு சமயமுண்டு.
	<br /><br />
	2. பிறக்க ஒரு காலமுண்டு, இறக்க ஒரு காலமுண்டு; நட ஒரு காலமுண்டு, நட்டதைப் பிடுங்க ஒரு காலமுண்டு;
	<br /><br />
	3. கொல்ல ஒரு காலமுண்டு, குணமாக்க ஒரு காலமுண்டு; இடிக்க ஒரு காலமுண்டு, கட்ட ஒரு காலமுண்டு;
	<br /><br />
	4. அழ ஒரு காலமுண்டு, நகைக்க ஒரு காலமுண்டு; புலம்ப ஒரு காலமுண்டு, நடனம்பண்ண ஒரு காலமுண்டு;
	<br /><br />
	5. கற்களை எறிந்துவிட ஒரு காலமுண்டு, கற்களைச் சேர்க்க ஒரு காலமுண்டு; தழுவ ஒரு காலமுண்டு, தழுவாமலிருக்க ஒரு காலமுண்டு;
	<br /><br />
	6. தேட ஒரு காலமுண்டு, இழக்க ஒரு காலமுண்டு; காப்பாற்ற ஒரு காலமுண்டு, எறிந்துவிட ஒரு காலமுண்டு;
	<br /><br />
	7. கிழிக்க ஒரு காலமுண்டு, தைக்க ஒரு காலமுண்டு; மவுனமாயிருக்க ஒரு காலமுண்டு, பேச ஒரு காலமுண்டு;
	<br /><br />
	8. சிநேகிக்க ஒரு காலமுண்டு, பகைக்க ஒரு காலமுண்டு; யுத்தம்பண்ண ஒரு காலமுண்டு, சமாதானம்பண்ண ஒரு காலமுண்டு.
	<br /><br />
	9. வருத்தப்பட்டுப் பிரயாசப்படுகிறவனுக்கு அதினால் பலன் என்ன?
	<br /><br />
	10. மனுபுத்திரர் பாடுபடும்படி தேவன் அவர்களுக்கு நியமித்த தொல்லையைக் கண்டேன்.
	<br /><br />
	11. அவர் சகலத்தையும் அதினதின் காலத்திலே நேர்த்தியாகச் செய்திருக்கிறார்; உலகத்தையும் அவர்கள் உள்ளத்திலே வைத்திருக்கிறார்; ஆதலால் தேவன் ஆதிமுதல் அந்தம்மட்டும் செய்துவரும் கிரியையை மனுஷன் கண்டுபிடியான்.
	<br /><br />
	12. மகிழ்ச்சியாயிருப்பதும், உயிரோடிருக்கையில் நன்மைசெய்வதுமேயல்லாமல், வேறொரு நன்மையும் மனுஷனுக்கு இல்லையென்று அறிந்தேன்.
	<br /><br />
	13. அன்றியும் மனுஷர் யாவரும் புசித்துக் குடித்துத் தங்கள் சகலப் பிரயாசத்தின் பலனையும் அநுபவிப்பது தேவனுடைய அநுக்கிரகம்.
	<br /><br />
	14. தேவன் செய்வது எதுவோ அது என்றைக்கும் நிலைக்கும் என்று அறிவேன்; அதினோடே ஒன்றும் கூட்டவுங்கூடாது, அதிலிருந்து ஒன்றும் குறைக்கவுங்கூடாது; மனுஷர் தமது சமுகத்தில் பயந்திருக்கும்படி தேவன் இப்படிச் செய்துவருகிறார்.
	<br /><br />
	15. முன் நடந்ததே இப்பொழுதும் நடக்கிறது; இனி நடக்கப்போகிறதும் முன்னமே நடந்தது; நடந்ததையோ தேவன் விசாரிப்பார்.
	<br /><br />
	16. பின்னும் சூரியனுக்குக் கீழே நான் நியாயஸ்தலத்தைக் கண்டேன், அங்கே அநியாயம் இருந்தது; நீதிஸ்தலத்தையும் கண்டேன், அங்கே அநீதி இருந்தது.
	<br /><br />
	17. சகல எண்ணங்களையும் சகல செய்கைகளையும் நியாயந்தீர்க்குங்காலம் இனி இருக்கிறபடியால் சன்மார்க்கனையும் துன்மார்க்கனையும் தேவன் நியாயந்தீர்ப்பார் என்று என் உள்ளத்தில் எண்ணினேன்.
	<br /><br />
	18. மனுபுத்திரர் தாங்கள் மிருகங்களைப்போல் இருக்கிறார்களென்பதை அவர்கள் காணும்படிக்கு தேவன் அவர்களைச் சோதிக்கிறாரென்று நான் மனுஷருடைய நிலைமையைக்குறித்து என் உள்ளத்தில் எண்ணினேன்.
	<br /><br />
	19. மனுபுத்திரருக்குச் சம்பவிக்கிறது மிருகங்களுக்கும் சம்பவிக்கும்; அவர்களுக்கும் இவைகளுக்கும் ஏக சம்பவமுண்டு; இவைகள் சாகிறதுபோலவே அவர்களும் சாகிறார்கள்; ஜீவன்களுக்கெல்லாம் சுவாசம் ஒன்றே; மிருகத்தைப்பார்க்கிலும் மனுஷன் மேன்மையுள்ளவன் அல்ல; எல்லாம் மாயையே.
	<br /><br />
	20. எல்லாம் ஒரே இடத்துக்குப் போகிறது; எல்லாம் மண்ணிலே உண்டாகிறது, எல்லாம் மண்ணுக்குத் திரும்புகிறது.
	<br /><br />
	21. உயர ஏறும் மனுஷனுடைய ஆவியையும், தாழப் பூமியிலிறங்கும் மிருகங்களுடைய ஆவியையும் அறிகிறவன் யார்?
	<br /><br />
	22. இப்படியிருக்கிறபடியால், மனுஷன் தன் செய்கைகளில் மகிழ்ச்சியாயிருக்கும் நன்மையையேயல்லாமல், வேறே நன்மை இல்லையென்று கண்டேன்; இதுவே அவன் பங்கு; தனக்குப் பின்வரும் காரியங்களைக் காணும்படிக்கு அவனைத் திரும்பிவரப்பண்ணுகிறவன் யார்?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes3