import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah32 = () => {
  const verseNumber = 32;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 32 </title>
	<meta
          name="description"
          content="Jeremiah 32 | எரேமியா 32 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நேபுகாத்நேச்சாரின் பதினெட்டாம் வருஷத்துக்குச் சரியான யூதாவின் ராஜாவாகிய சிதேக்கியா அரசாண்ட பத்தாம் வருஷத்தில், கர்த்தரால் எரேமியாவுக்கு உண்டான வார்த்தை:
	<br /><br />
	2. அப்பொழுது பாபிலோன் ராஜாவின் சேனை எருசலேமை முற்றிக்கை போட்டிருந்தது; எரேமியா தீர்க்கதரிசியோ, யூதா ராஜாவின் அரமனையிலுள்ள காவற்சாலையின் முற்றத்திலே அடைக்கப்பட்டிருந்தான்.
	<br /><br />
	3. ஏனென்றால், இதோ, இந்த நகரத்தைப் பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுக்கிறேன்; அவன் இதைப்பிடிப்பான் என்று கர்த்தர் சொல்லுகிறாரென்றும்,
	<br /><br />
	4. யூதாவின் ராஜாவாகிய சிதேக்கியா கல்தேயருடைய கைக்குத் தப்பிப்போகாமல், பாபிலோன் ராஜாவின் கையில் நிச்சயமாக ஒப்புக்கொடுக்கப்படுவான்; அவன் வாய் இவன் வாயோடே பேசும், அவன் கண்கள் இவன் கண்களைக் காணும்.
	<br /><br />
	5. அவன் சிதேக்கியாவைப் பாபிலோனுக்குக் கொண்டுபோவான்; நான் அவனைச் சந்திக்குமட்டும் அங்கே அவன் இருப்பான்; நீங்கள் கல்தேயரோடே யுத்தம்பண்ணினாலும் உங்களுக்கு வாய்ப்பதில்லை என்று கர்த்தர் சொல்லுகிறாரென்றும், நீ தீர்க்கதரிசனஞ்சொல்லவேண்டியது என்ன என்று சொல்லி, யூதாவின் ராஜாவாகிய சிதேக்கியா அங்கே அவனை அடைத்துவைத்தான்.
	<br /><br />
	6. அதற்கு எரேமியா சொன்னது: கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	7. இதோ, உன் பெரிய தகப்பனாகிய சல்லூமின் குமாரன் அனாமெயேல் உன்னிடத்தில் வந்து: ஆனதோத்திலிருக்கிற என் நிலத்தை நீ வாங்கிக்கொள்; அதைக் கொள்ளுகிறதற்கு உனக்கே மீட்கும் அதிகாரம் அடுத்ததென்று சொல்வான் என்று உரைத்தார்.
	<br /><br />
	8. அப்படியே என் பெரியதகப்பன் மகனாகிய அனாமெயேல், கர்த்தருடைய வார்த்தையின்படி காவற்சாலையின் முற்றத்தில் என்னிடத்துக்கு வந்து: பென்யமீன் நாட்டு ஆனதோத்தூரிலுள்ள என் நிலத்தை நீர் வாங்கிக்கொள்ளும்; சுதந்தரபாத்தியம் உமக்குண்டு, அதை மீட்கும் அதிகாரம் உமக்கு அடுத்தது; அதை வாங்கிக்கொள்ளும் என்றான்; அப்பொழுது அது கர்த்தருடைய வார்த்தை என்று அறிந்துகொண்டேன்.
	<br /><br />
	9. ஆகையால் என் பெரியதகப்பன் மகனாகிய அனாமெயேலின் கையில், நான் ஆனதோத்திலிருக்கிற அவனுடைய நிலத்தைக்கொண்டு, அதின் விலைக்கிரயமாகிய பதினேழு சேக்கலிடை வெள்ளியை அவனுக்கு நிறுத்துக்கொடுத்தேன்.
	<br /><br />
	10. நான் பத்திரத்தில் கையெழுத்தையும், முத்திரையையும் போட்டு, சாட்சிகளை வைத்து, வெள்ளியைத் தராசிலே நிறுத்துக்கொடுத்தபின்பு,
	<br /><br />
	11. நான் சட்டத்துக்கும் வழக்கத்துக்கும் ஏற்றபடி முத்திரைபோடப்பட்ட கிரயப்பத்திரத்தையும் திறந்திருக்கிற பிரதியையும் எடுத்து,
	<br /><br />
	12. என் பெரியதகப்பன் மகனாகிய அனாமெயேலுடைய கண்களுக்கு முன்பாகவும், கிரயப்பத்திரத்தில் கையெழுத்துப்போட்ட சாட்சிகளுடைய கண்களுக்கு முன்பாகவும், காவற்சாலையின் முற்றத்தில் உட்கார்ந்திருந்த எல்லா யூதருடைய கண்களுக்கு முன்பாகவும், அதை மாசெயாவின் குமாரனாகிய நேரியாவின் மகனான பாருக்கினிடத்தில் கொடுத்து,
	<br /><br />
	13. அவர்களுடைய கண்களுக்கு முன்பாகப் பாருக்கை நோக்கி:
	<br /><br />
	14. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால், நீ முத்திரைபோடப்பட்ட கிரயப்பத்திரமும், திறந்திருக்கிற பிரதிபத்திரமுமாகிய இந்தச் சாசனங்களை வாங்கி, அவைகள் அநேகநாளிருக்கும்படிக்கு அவைகளை ஒரு மண்பாண்டத்திலே வை.
	<br /><br />
	15. ஏனெனில் இனி இந்தத் தேசத்தில் வீடுகளும் நிலங்களும் திராட்சத்தோட்டங்களும் கொள்ளப்படுமென்று, இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றேன்.
	<br /><br />
	16. நான் கிரயப்பத்திரத்தை நேரியாவின் குமாரனாகிய பாருக்கினிடத்தில் கொடுத்தபின்பு, நான் கர்த்தரை நோக்கிப் பண்ணின விண்ணப்பமாவது:
	<br /><br />
	17. ஆ, கர்த்தராகிய ஆண்டவரே, இதோ, தேவரீர் உம்முடைய மகா பலத்தினாலும், நீட்டப்பட்ட உம்முடைய புயத்தினாலும், வானத்தையும் பூமியையும் உண்டாக்கினீர்; உம்மாலே செய்யக்கூடாத அதிசயமான காரியம் ஒன்றுமில்லை.
	<br /><br />
	18. ஆயிரம் தலைமுறைகளுக்கும் கிருபை செய்கிறவரும், பிதாக்களுடைய அக்கிரமத்தை அவர்களுடைய பின்னடியார் பிள்ளைகளின் மடியிலே சரிக்கட்டுகிறவருமாகிய சேனைகளின் கர்த்தர் என்னும் நாமமுள்ள மகத்துவமும் வல்லமையுமுள்ள தேவனே,
	<br /><br />
	19. யோசனையிலே பெரியவரும், செயலிலே வல்லவருமாயிருக்கிறீர்; அவனவனுக்கு அவனவனுடைய வழிக்குத்தக்கதாகவும், அவனவனுடைய கிரியைகளின் பலனுக்குத்தக்கதாகவும் அளிக்கும்படி, உம்முடைய கண்கள் மனுபுத்திரருடைய எல்லா வழிகளின்மேலும் நோக்கமாயிருக்கின்றன.
	<br /><br />
	20. இஸ்ரவேலிலும் மற்ற மனுஷருக்குள்ளும் இந்நாள்வரைக்கும் விளங்குகிற அடையாளங்களையும் அற்புதங்களையும் தேவரீர் எகிப்துதேசத்திலே செய்து, இந்நாளில் நிற்கும் கீர்த்தியை உமக்கு உண்டாக்கி,
	<br /><br />
	21. இஸ்ரவேலாகிய உமது ஜனத்தை அடையாளங்களினாலும், அற்புதங்களினாலும், பலத்த கையினாலும், ஓங்கிய புயத்தினாலும், மகா பயங்கரத்தினாலும் எகிப்துதேசத்திலிருந்து புறப்படப்பண்ணி,
	<br /><br />
	22. அவர்களுடைய பிதாக்களுக்கு நீர் கொடுப்பேன் என்று ஆணையிட்ட பாலும் தேனும் ஓடிய தேசமாயிருக்கிற இந்தத் தேசத்தை அவர்களுக்குக் கொடுத்தீர்.
	<br /><br />
	23. அவர்கள் அதற்குள் பிரவேசித்து, அதைச் சுதந்தரித்துக்கொண்டார்கள்; ஆனாலும் அவர்கள் உமது சத்தத்துக்குச் செவிகொடாமலும், உமது நியாயப்பிரமாணத்தில் நடவாமலும், செய்யும்படி நீர் அவர்களுக்குக் கற்பித்ததொன்றையும் செய்யாமலும் போனார்கள்; ஆதலால் இந்தத் தீங்கையெல்லாம் அவர்களுக்கு நேரிடப்பண்ணினீர்.
	<br /><br />
	24. இதோ, கொத்தளங்கள் போடப்பட்டிருக்கிறது; நகரத்தைப் பிடிக்கவருகிறார்கள்; பட்டயத்தினிமித்தமும், பஞ்சத்தினிமித்தமும், கொள்ளைநோயினிமித்தமும் இந்த நகரம் அதற்கு விரோதமாய் யுத்தம்பண்ணுகிற கல்தேயரின் கையிலே கொடுக்கப்படுகிறது; நீர் சொன்னபடி சம்பவிக்கிறது; இதோ, நீர் அதைப் பார்க்கிறீர்.
	<br /><br />
	25. கர்த்தராகிய ஆண்டவரே, நகரம் கல்தேயரின் கையிலே கொடுக்கப்படுகிறதாயிருந்தும், தேவரீர் என்னை நோக்கி: நீ உனக்கு ஒரு நிலத்தை விலைக்கிரயமாகக்கொண்டு, அதற்குச் சாட்சிகளை வையென்று சொன்னீரே என்றேன்.
	<br /><br />
	26. அப்பொழுது கர்த்தருடைய வார்த்தை எரேமியாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	27. இதோ, நான் மாம்சமான யாவருக்கும் தேவனாகிய கர்த்தர்; என்னாலே செய்யக்கூடாத அதிசயமான காரியம் ஒன்றுண்டோ?
	<br /><br />
	28. ஆதலால், இதோ, நான் இந்த நகரத்தைக் கல்தேயரின் கையிலும், பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரின் கையிலும் ஒப்புக்கொடுக்கிறேன், அவன் இதைப் பிடிப்பான் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	29. இந்த நகரத்துக்கு விரோதமாய் யுத்தம்பண்ணுகிற கல்தேயர் உட்பிரவேசித்து, இந்த நகரத்தைத் தீக்கொளுத்தி, இதைச் சுட்டெரிப்பார்கள்; எனக்குக் கோபமுண்டாக்கும்படி எந்த வீடுகளின்மேல் பாகாலுக்குத் தூபங்காட்டி, அந்நிய தேவர்களுக்குப் பானபலிகளை வார்த்தார்களோ, அந்த வீடுகளையும் சுட்டெரிப்பார்கள்.
	<br /><br />
	30. இஸ்ரவேல் புத்திரரும் யூதா புத்திரரும் தங்கள் சிறுவயதுமுதல் என் பார்வைக்குப் பொல்லாப்பானதையே செய்துவந்தார்கள்; இஸ்ரவேல் புத்திரர் தங்கள் கைகளின் செய்கையினாலே எனக்குக் கோபத்தையே உண்டாக்கி வந்தார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	31. அவர்கள் இந்த நகரத்தைக் கட்டின நாள் முதற்கொண்டு, இந்நாள்வரைக்கும் அது எனக்குக் கோபமுண்டாகவும், எனக்கு உக்கிரமுண்டாகவும், நான் அதை என் முகத்தை விட்டு அகற்றுகிறதற்கு ஏதுவாகவும் இருந்தது.
	<br /><br />
	32. எனக்குக் கோபமுண்டாகும்படிக்கு இஸ்ரவேல் புத்திரரும், யூதா புத்திரரும், அவர்கள் ராஜாக்களும், அவர்கள் பிரபுக்களும், அவர்கள் ஆசாரியர்களும், அவர்கள் தீர்க்கதரிசிகளும், யூதாவின் மனுஷரும், எருசலேமின் குடிகளும் செய்த எல்லாப் பொல்லாப்பினிமித்தமும் இப்படி நடக்கும்.
	<br /><br />
	33. முகத்தையல்ல, முதுகை எனக்குக் காட்டினார்கள்; நான் ஏற்கனவே அவர்களுக்கு உபதேசித்தும் அவர்கள் புத்தியை ஏற்றுக்கொள்ளச் செவிகொடாமற்போனார்கள்.
	<br /><br />
	34. அவர்கள் என் நாமம் தரிக்கப்பட்ட ஆலயத்தைத் தீட்டுப்படுத்தும்படிக்கு, தங்கள் அருவருப்புகளை அதிலே வைத்தார்கள்.
	<br /><br />
	35. அவர்கள் மோளேகுக்கென்று தங்கள் குமாரரையும் தங்கள் குமாரத்திகளையும் தீக்கடக்கப்பண்ணும்படி இன்னோமுடைய குமாரனின் பள்ளத்தாக்கிலிருக்கிற பாகாலின் மேடைகளைக் கட்டினார்கள்; யூதாவைப் பாவஞ்செய்யப்பண்ணுவதற்கு அவர்கள் இந்த அருவருப்பான காரியத்தைச் செய்யவேண்டுமென்று நான் அவர்களுக்குக் கற்பித்ததுமில்லை, அது என் மனதிலே தோன்றினதுமில்லை.
	<br /><br />
	36. இப்படியிருக்கையில் பட்டயத்தாலும், பஞ்சத்தாலும், கொள்ளைநோயாலும், பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுக்கப்பட்டுப்போம் என்று நீங்கள் சொல்லுகிற இந்த நகரத்தைக்குறித்து இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	37. இதோ, என் சினத்திலும், என் கோபத்திலும், என் மகா உக்கிரத்திலும், நான் அவர்களைத் துரத்தின எல்லாத் தேசங்களிலுமிருந்து அவர்களைச் சேர்த்துக்கொண்டு, அவர்களை இந்த ஸ்தலத்துக்குத் திரும்பிவரவும் இதிலே சுகமாய்த் தங்கியிருக்கவும் பண்ணுவேன்.
	<br /><br />
	38. அவர்கள் என் ஜனமாயிருப்பார்கள், நான் அவர்கள் தேவனாயிருப்பேன்.
	<br /><br />
	39. அவர்கள் தங்களுக்கும், தங்கள் பின்னடியாருக்கும், தங்கள் பிள்ளைகளுக்கும் நன்மையுண்டாகும்படி சகல நாட்களிலும் எனக்குப் பயப்படும்படிக்கு, நான் அவர்களுக்கு ஒரே இருதயத்தையும் ஒரே வழியையும் கட்டளையிட்டு,
	<br /><br />
	40. அவர்களுக்கு நன்மைசெய்யும்படி, நான் அவர்களை விட்டுப் பின்வாங்குவதில்லையென்கிற நித்திய உடன்படிக்கையை அவர்களோடே பண்ணி, அவர்கள் என்னைவிட்டு அகன்று போகாதபடிக்கு, எனக்குப் பயப்படும் பயத்தை அவர்கள் இருதயத்திலே வைத்து,
	<br /><br />
	41. அவர்களுக்கு நன்மைசெய்யும்படி அவர்கள்மேல் சந்தோஷமாயிருந்து, என் முழு இருதயத்தோடும் என் முழு ஆத்துமாவோடும் அவர்களை இந்தத் தேசத்திலே நிச்சயமாய் நாட்டுவேன்.
	<br /><br />
	42. நான் இந்த ஜனத்தின்மேல் இந்தப் பெரிய தீங்கையெல்லாம் வரப்பண்ணினதுபோல, அவர்களைக்குறித்துச் சொன்ன எல்லா நன்மையையும் அவர்கள்மேல் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	43. மனுஷனும் மிருகமும் இல்லாதபடிக்குப் பாழாய்ப்போயிற்று என்றும், கல்தேயரின் கையிலே ஒப்புக்கொடுக்கப்பட்டுப்போயிற்று என்றும், நீங்கள் சொல்லுகிற இந்தத் தேசத்திலே நிலங்கள் கொள்ளப்படும்.
	<br /><br />
	44. பென்யமீன் தேசத்திலும், எருசலேமின் சுற்றுப்புறங்களிலும், யூதாவின் பட்டணங்களிலும், மலைக்காடான பட்டணங்களிலும், பள்ளத்தாக்கான பட்டணங்களிலும், தென்திசைப் பட்டணங்களிலும், நிலங்கள் விலைக்கிரயமாகக் கொள்ளப்படுகிற பத்திரங்களில் கையெழுத்துப் போடுகிறதும் முத்திரையிடுகிறதும் அதற்குச் சாட்சி வைக்கிறதும் உண்டாயிருக்கும்; அவர்கள் சிறையிருப்பைத் திருப்புவேன் என்று கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah32