import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const SecondTimothy2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> 2 Timothy in Tamil Bible Verse | 2 தீமோத்தேயு அதிகாரம்-2 </title>
	<meta
          name="description"
          content="Second LETTER TO THE Timothy Chapter 2 | தீமோத்தேயுக்கு எழுதின 2 நிருபம்  | அதிகாரம்-2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
<h2>
* கிருபையில் பலமாக இருங்கள் *
			</h2>
<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. ஆதலால், என் குமாரனே, நீ கிறிஸ்து இயேசுவிலுள்ள கிருபையில் பலப்படு.
	<br /><br />
	2. அநேக சாட்சிகளுக்கு முன்பாக நீ என்னிடத்தில் கேட்டவைகளை மற்றவர்களுக்குப் போதிக்கத்தக்க உண்மையுள்ள மனுஷர்களிடத்தில் ஒப்புவி.
	<br /><br />
	3. நீயும் இயேசுகிறிஸ்துவுக்கு நல்ல போர்ச்சேவகனாய்த் தீங்கநுபவி.
	<br /><br />
	4. தண்டில் சேவகம்பண்ணுகிற எவனும், தன்னைச் சேவகமெழுதிக்கொண்டவனுக்கு ஏற்றவனாயிருக்கும்படி, பிழைப்புக்கடுத்த அலுவல்களில் சிக்கிக்கொள்ளமாட்டான்.
	<br /><br />
	5. மேலும் ஒருவன் மல்யுத்தம்பண்ணினாலும், சட்டத்தின்படி பண்ணாவிட்டால் முடிசூட்டப்படான்.
	<br /><br />
	6. பிரயாசப்பட்டுப் பயிரிடுகிறவன் பலனில் முந்திப் பங்கடையவேண்டும்.
	<br /><br />
	7. நான் சொல்லுகிறவைகளைச் சிந்தித்துக்கொள்; கர்த்தர் எல்லாக் காரியங்களிலும் உனக்குப் புத்தியைத் தந்தருளுவார்.
	<br /><br />
	8. தாவீதின் சந்ததியில் பிறந்த இயேசுகிறிஸ்து, என் சுவிசேஷத்தின்படியே, மரித்தோரிலிருந்தெழுப்பப்பட்டவரென்று நினைத்துக்கொள்.
	<br /><br />
	9. இந்தச் சுவிசேஷத்தினிமித்தம் நான் பாதகன்போலக் கட்டப்பட்டு, துன்பத்தை அநுபவிக்கிறேன்; தேவவசனமோ கட்டப்பட்டிருக்கவில்லை.
	<br /><br />
	10. ஆகையால், தெரிந்துகொள்ளப்பட்டவர்கள் கிறிஸ்து இயேசுவினால் உண்டான இரட்சிப்பை நித்திய மகிமையோடே பெற்றுக்கொள்ளும்படி, சகலத்தையும் அவர்கள் நிமித்தமாகச் சகிக்கிறேன்.
	<br /><br />
	11. இந்த வார்த்தை உண்மையுள்ளது; என்னவெனில், நாம் அவரோடேகூட மரித்தோமானால், அவரோடேகூடப் பிழைத்துமிருப்போம்;
	<br /><br />
	12. அவரோடேகூடப் பாடுகளைச் சகித்தோமானால் அவரோடேகூட ஆளுகையும் செய்வோம்; நாம் அவரை மறுதலித்தால், அவரும் நம்மை மறுதலிப்பார்;
	<br /><br />
	13. நாம் உண்மையில்லாதவர்களாயிருந்தாலும், அவர் உண்மையுள்ளவராயிருக்கிறார்; அவர் தம்மைத்தாம் மறுதலிக்கமாட்டார்.
	<br /><br />
	14. இவைகளை அவர்களுக்கு நினைப்பூட்டி, ஒன்றுக்கும் பிரயோஜனமில்லாமல், கேட்கிறவர்களைக் கவிழ்த்துப்போடுகிறதற்கேதுவான வாக்குவாதம் செய்யாதபடிக்கு, கர்த்தருக்கு முன்பாக அவர்களுக்கு எச்சரித்துப் புத்திசொல்லு.
	<br /><br />
	15. நீ வெட்கப்படாத ஊழியக்காரனாயும் சத்திய வசனத்தை நிதானமாய்ப் பகுத்துப் போதிக்கிறவனாயும் உன்னைத் தேவனுக்கு முன்பாக உத்தமனாக நிறுத்தும்படி ஜாக்கிரதையாயிரு.
	<br /><br />
	16. சீர்கேடான வீண்பேச்சுகளுக்கு விலகியிரு; அவைகளால் (கள்ளப்போதகர்களான) அவர்கள் அதிக அவபக்தியுள்ளவர்களாவார்கள்;
	<br /><br />
	17. அவர்களுடைய வார்த்தை அரிபிளவையைப்போலப் படரும்; இமெநேயும் பிலேத்தும் அப்படிப்பட்டவர்கள்;
	<br /><br />
	18. அவர்கள் சத்தியத்தை விட்டு விலகி, உயிர்த்தெழுதல் நடந்தாயிற்றென்று சொல்லி, சிலருடைய விசுவாசத்தைக் கவிழ்த்துப்போடுகிறார்கள்.
	<br /><br />
	19. ஆகிலும் தேவனுடைய உறுதியான அஸ்திபாரம் நிலைத்திருக்கிறது; கர்த்தர் தம்முடையவர்களை அறிவாரென்பதும், கிறிஸ்துவின் நாமத்தைச் சொல்லுகிற எவனும் அநியாயத்தைவிட்டு விலகக்கடவனென்பதும், அதற்கு முத்திரையாயிருக்கிறது.
	<br /><br />
	20. ஒரு பெரிய வீட்டிலே பொன்னும் வெள்ளியுமான பாத்திரங்களுமல்லாமல், மரமும் மண்ணுமான பாத்திரங்களுமுண்டு; அவைகளில் சில கனத்திற்கும் சில கனவீனத்திற்குமானவைகள்.
	<br /><br />
	21. ஆகையால் ஒருவன் இவைகளை விட்டு, தன்னைச் சுத்திகரித்துக்கொண்டால், அவன் பரிசுத்தமாக்கப்பட்டதும், எஜமானுக்கு உபயோகமானதும், எந்த நற்கிரியைக்கும் ஆயத்தமாக்கப்பட்டதுமான கனத்துக்குரிய பாத்திரமாயிருப்பான்.
	<br /><br />
	22. அன்றியும், பாலியத்துக்குரிய இச்சைகளுக்கு நீ விலகியோடி, சுத்த இருதயத்தோடே கர்த்தரைத் தொழுதுகொள்ளுகிறவர்களுடனே, நீதியையும் விசுவாசத்தையும் அன்பையும் சமாதானத்தையும் அடையும்படி நாடு.
	<br /><br />
	23. புத்தியீனமும் அயுக்தமுமான தர்க்கங்கள் சண்டைகளைப் பிறப்பிக்குமென்று அறிந்து, அவைகளுக்கு விலகியிரு.
	<br /><br />
	24. கர்த்தருடைய ஊழியக்காரன் சண்டைபண்ணுகிறவனாயிராமல், எல்லாரிடத்திலும் சாந்தமுள்ளவனும், போதகசமர்த்தனும், தீமையைச் சகிக்கிறவனுமாயிருக்கவேண்டும்.
	<br /><br />
	25. எதிர்பேசுகிறவர்கள் சத்தியத்தை அறியும்படி தேவன் அவர்களுக்கு மனந்திரும்புதலை அருளத்தக்கதாகவும்,
	<br /><br />
	26. பிசாசானவனுடைய இச்சையின்படி செய்ய அவனால் பிடிபட்டிருக்கிற அவர்கள் மறுபடியும் மயக்கந்தெளிந்து அவன் கண்ணிக்கு நீங்கத்தக்கதாகவும், சாந்தமாய் அவர்களுக்கு உபதேசிக்கவேண்டும்.
</p>


      {/* End of verse */} 

	  <br />
		<br />
<h4>
* 2 தீமோத்தேயு 2 என்பது பணியில் ஒருமையாக இருக்க ஒரு நினைவூட்டலாகும். கிறிஸ்து இயேசுவின் கிருபையில் பலமாக இருக்கவும், கஷ்டங்களைச் சகித்துக்கொள்ளவும் பவுல் தீமோத்தேயுவிடம் கூறுகிறார். அவர் இயேசுவின் மீது கவனம் செலுத்த வேண்டும், அவருடைய உயிர்த்தெழுதல் மற்றும் தேர்ந்தெடுக்கப்பட்டவர்களுக்காக விடாமுயற்சியுடன் இருக்க வேண்டும். கடவுளுடைய வார்த்தையை திறமையுடனும் துல்லியத்துடனும் கையாளுவதற்கு பதிலாக வார்த்தைகளால் சண்டையிட வேண்டாம் என்று தீமோத்தேயுவுக்கு பவுல் அறிவுறுத்துகிறார். வார்த்தையின் மூலம் மெதுவாகப் போதிக்கவும் விளக்கவும் முடிந்தால், கடவுள் மக்களை சத்தியத்தின் அறிவிற்கு அழைத்துச் செல்ல முடியும். *
			</h4>
<br />
			<br />
      </div>
    </div>
    </>
  );
}

export default SecondTimothy2