import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 10 </title>
	<meta
          name="description"
          content="Mark 10 | மாற்கு 10 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | TamilBibleVerse | TamilBible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் அவ்விடம் விட்டெழுந்து, யோர்தானுக்கு அக்கரையிலுள்ள தேசத்தின் வழியாய் யூதேயாவின் எல்லைகளில் வந்தார். ஜனங்கள் மறுபடியும் அவரிடத்தில் கூடிவந்தார்கள். அவர் தம்முடைய வழக்கத்தின்படியே மறுபடியும் அவர்களுக்குப் போதகம்பண்ணினார்.
	<br /><br />
	2. அப்பொழுது பரிசேயர், அவரைச் சோதிக்கவேண்டுமென்று, அவரிடத்தில் வந்து: புருஷனானவன் தன் மனைவியைத் தள்ளிவிடுவது நியாயமா என்று கேட்டார்கள்.
	<br /><br />
	3. அவர் பிரதியுத்தரமாக: மோசே உங்களுக்குக் கட்டளையிட்டிருக்கிறது என்ன என்று கேட்டார்.
	<br /><br />
	4. அதற்கு அவர்கள்: தள்ளுதற்சீட்டை எழுதிக்கொடுத்து, அவளைத் தள்ளிவிடலாமென்று மோசே உத்தரவுகொடுத்திருக்கிறார் என்றார்கள்.
	<br /><br />
	5. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: உங்கள் இருதயகடினத்தினிமித்தம் இந்தக் கட்டளையை உங்களுக்கு எழுதிக்கொடுத்தான்.
	<br /><br />
	6. ஆகிலும், ஆதியிலே மனுஷரைச் சிருஷ்டித்த தேவன் அவர்களை ஆணும் பெண்ணுமாக உண்டாக்கினார்.
	<br /><br />
	7. இதினிமித்தம் புருஷனானவன் தன் தகப்பனையும் தாயையும் விட்டுத் தன் மனைவியோடே இசைந்திருப்பான்;
	<br /><br />
	8. அவர்கள் இருவரும் ஒரே மாம்சமாயிருப்பார்கள்; இவ்விதமாய் அவர்கள் இருவராயிராமல் ஒரே மாம்சமாயிருக்கிறார்கள்.
	<br /><br />
	9. ஆகையால், தேவன் இணைத்ததை மனுஷன் பிரிக்காதிருக்கக்கடவன் என்றார்.
	<br /><br />
	10. பின்பு வீட்டிலே அவருடைய சீஷர்கள் அந்தக் காரியத்தைக்குறித்து மறுபடியும் அவரிடத்தில் விசாரித்தார்கள்.
	<br /><br />
	11. அப்பொழுது அவர்: எவனாகிலும் தன் மனைவியைத் தள்ளிவிட்டு, வேறொருத்தியை விவாகம்பண்ணினால், அவன் அவளுக்கு விரோதமாய் விபசாரஞ்செய்கிறவனாயிருப்பான்.
	<br /><br />
	12. மனைவியும் தன் புருஷனைத் தள்ளிவிட்டு, வேறொருவனை விவாகம்பண்ணினால், விபசாரஞ்செய்கிறவளாயிருப்பாள் என்றார்.
	<br /><br />
	13. அப்பொழுது, சிறு பிள்ளைகளை அவர் தொடும்படிக்கு அவர்களை அவரிடத்தில் கொண்டுவந்தார்கள்; கொண்டுவந்தவர்களைச் சீஷர்கள் அதட்டினார்கள்.
	<br /><br />
	14. இயேசு அதைக் கண்டு, விசனமடைந்து: சிறு பிள்ளைகள் என்னிடத்தில் வருகிறதற்கு இடங்கொடுங்கள்; அவர்களைத் தடைபண்ணாதிருங்கள்; தேவனுடைய ராஜ்யம் அப்படிப்பட்டவர்களுடையது.
	<br /><br />
	15. எவனாகிலும் சிறு பிள்ளையைப்போல் தேவனுடைய ராஜ்யத்தை ஏற்றுக்கொள்ளாவிட்டால், அவன் அதில் பிரவேசிப்பதில்லையென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்று சொல்லி,
	<br /><br />
	16. அவர்களை அணைத்துக்கொண்டு, அவர்கள்மேல் கைகளை வைத்து, அவர்களை ஆசீர்வதித்தார்.
	<br /><br />
	17. பின்பு அவர் புறப்பட்டு வழியிலே போகையில், ஒருவன் ஓடிவந்து, அவருக்கு முன்பாக முழங்கால்படியிட்டு: நல்ல போதகரே, நித்தியஜீவனைச் சுதந்தரித்துக்கொள்ளும்படி நான் என்னசெய்யவேண்டும் என்று கேட்டான்.
	<br /><br />
	18. அதற்கு இயேசு: நீ என்னை நல்லவன் என்று சொல்வானேன்? தேவன் ஒருவர் தவிர நல்லவன் ஒருவனுமில்லையே.
	<br /><br />
	19. விபசாரஞ் செய்யாதிருப்பாயாக, கொலை செய்யாதிருப்பாயாக, களவு செய்யாதிருப்பாயாக, பொய்ச்சாட்சி சொல்லாதிருப்பாயாக, வஞ்சனை செய்யாதிருப்பாயாக, உன் தகப்பனையும் உன் தாயையும் கனம்பண்ணுவாயாக என்கிற கற்பனைகளை அறிந்திருக்கிறாயே என்றார்.
	<br /><br />
	20. அதற்கு அவன்: போதகரே, இவைகளையெல்லாம் என் சிறு வயதுமுதல் கைக்கொண்டிருக்கிறேன் என்றான்.
	<br /><br />
	21. இயேசு அவனைப் பார்த்து, அவனிடத்தில் அன்புகூர்ந்து: உன்னிடத்தில் ஒரு குறைவு உண்டு; நீ போய், உனக்கு உண்டானவைகளையெல்லாம் விற்று, தரித்திரருக்குக் கொடு; அப்பொழுது பரலோகத்திலே உனக்குப் பொக்கிஷம் உண்டாயிருக்கும்; பின்பு சிலுவையை எடுத்துக்கொண்டு, என்னைப் பின்பற்றிவா என்றார்.
	<br /><br />
	22. அவன் மிகுந்த ஆஸ்தியுள்ளவனாயிருந்தபடியால், இந்த வார்த்தையைக் கேட்டு, மனமடிந்து, துக்கத்தோடே போய்விட்டான்.
	<br /><br />
	23. அப்பொழுது இயேசு சுற்றிப்பார்த்து, தம்முடைய சீஷரை நோக்கி: ஐசுவரியமுள்ளவர்கள் தேவனுடைய ராஜ்யத்தில் பிரவேசிப்பது எவ்வளவு அரிதாயிருக்கிறது என்றார்.
	<br /><br />
	24. சீஷர்கள் அவருடைய வார்த்தைகளைக்குறித்து ஆச்சரியப்பட்டார்கள். இயேசு பின்னும் அவர்களை நோக்கி: பிள்ளைகளே, ஐசுவரியத்தின்மேல் நம்பிக்கையாயிருக்கிறவர்கள் தேவனுடைய ராஜ்யத்தில் பிரவேசிக்கிறது எவ்வளவு அரிதாயிருக்கிறது!
	<br /><br />
	25. ஐசுவரியவான் தேவனுடைய ராஜ்யத்தில் பிரவேசிப்பதைப்பார்க்கிலும், ஒட்டகமானது ஊசியின் காதிலே நுழைவது எளிதாயிருக்கும் என்றார்.
	<br /><br />
	26. அவர்கள் பின்னும் அதிகமாய் ஆச்சரியப்பட்டு: அப்படியானால் யார் இரட்சிக்கப்படக்கூடும் என்று தங்களுக்குள்ளே சொல்லிக்கொண்டார்கள்.
	<br /><br />
	27. இயேசு அவர்களைப் பார்த்து: மனுஷரால் இது கூடாததுதான், தேவனால் இது கூடாததல்ல; தேவனாலே எல்லாம் கூடும் என்றார்.
	<br /><br />
	28. அப்பொழுது பேதுரு அவரை நோக்கி: இதோ, நாங்கள் எல்லாவற்றையும்விட்டு, உம்மைப் பின்பற்றினோமே, என்று சொல்லத்தொடங்கினான்.
	<br /><br />
	29. அதற்கு இயேசு பிரதியுத்தரமாக: என்னிமித்தமாகவும், சுவிசேஷத்தினிமித்தமாகவும், வீட்டையாவது, சகோதரரையாவது, சகோதரிகளையாவது, தகப்பனையாவது, தாயையாவது, மனைவியையாவது, பிள்ளைகளையாவது, நிலங்களையாவது விட்டவன் எவனும்,
	<br /><br />
	30. இப்பொழுது இம்மையிலே, துன்பங்களோடேகூட நூறத்தனையாக, வீடுகளையும், சகோதரரையும், சகோதரிகளையும், தாய்களையும், பிள்ளைகளையும், நிலங்களையும், மறுமையிலே நித்தியஜீவனையும் அடைவான் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	31. ஆகிலும் முந்தினோர் அநேகர் பிந்தினோராயும், பிந்தினோர் அநேகர் முந்தினோராயும் இருப்பார்கள் என்றார்.
	<br /><br />
	32. பின்பு அவர்கள் எருசலேமுக்குப் பிரயாணமாய்ப் போகையில், இயேசு அவர்களுக்கு முன்னே நடந்துபோனார்; அவர்கள் திகைத்து, அவருக்குப் பின்னே, பயத்தோடே போனார்கள். அப்பொழுது அவர் பன்னிருவரையும் அழைத்து, தமக்குச் சம்பவிக்கப்போகிறவைகளை அவர்களுக்கு மறுபடியும் சொல்லத்தொடங்கினார்:
	<br /><br />
	33. இதோ, எருசலேமுக்குப் போகிறோம்; அங்கே மனுஷகுமாரன் பிரதான ஆசாரியரிடத்திலும் வேதபாரகரிடத்திலும் ஒப்புக்கொடுக்கப்படுவார்; அவர்கள் அவரை மரண ஆக்கினைக்குள்ளாகத் தீர்த்து, புறத்தேசத்தாரிடத்தில் ஒப்புக்கொடுப்பார்கள்.
	<br /><br />
	34. அவர்கள் அவரைப் பரியாசம்பண்ணி, அவரை வாரினால் அடித்து, அவர்மேல் துப்பி, அவரைக் கொலைசெய்வார்கள்; ஆகிலும் மூன்றாம் நாளிலே அவர் உயிரோடே எழுந்திருப்பார் என்றார்.
	<br /><br />
	35. அப்பொழுது செபெதேயுவின் குமாரராகிய யாக்கோபும் யோவானும் அவரிடத்தில் வந்து: போதகரே, நாங்கள் கேட்டுக்கொள்ளப்போகிறதை நீர் எங்களுக்குச் செய்யவேண்டுமென்று விரும்புகிறோம் என்றார்கள்.
	<br /><br />
	36. அவர் அவர்களை நோக்கி: நான் உங்களுக்கு என்னசெய்யவேண்டுமென்று விரும்புகிறீர்கள் என்று கேட்டார்.
	<br /><br />
	37. அதற்கு அவர்கள்: உமது மகிமையிலே, எங்களில் ஒருவன் உமது வலதுபாரிசத்திலும், ஒருவன் உமது இடதுபாரிசத்திலும் உட்கார்ந்திருக்கும்படி எங்களுக்கு அருள்செய்யவேண்டும் என்றார்கள்.
	<br /><br />
	38. இயேசு அவர்களை நோக்கி: நீங்கள் கேட்டுக்கொள்ளுகிறது இன்னது என்று உங்களுக்கே தெரியவில்லை. நான் குடிக்கும் பாத்திரத்தில் நீங்கள் குடிக்கவும், நான் பெறும் ஸ்நானத்தை நீங்கள் பெறவும், உங்களால் கூடுமா என்றார்.
	<br /><br />
	39. அதற்கு அவர்கள்: கூடும் என்றார்கள். இயேசு அவர்களை நோக்கி: நான் குடிக்கும் பாத்திரத்தில் நீங்கள் குடிப்பீர்கள், நான் பெறும் ஸ்நானத்தையும் நீங்கள் பெறுவீர்கள்.
	<br /><br />
	40. ஆனாலும் என் வலதுபாரிசத்திலும் என் இடதுபாரிசத்திலும் உட்கார்ந்திருக்கும்படி எவர்களுக்கு ஆயத்தம்பண்ணப்பட்டிருக்கிறதோ அவர்களுக்கேயல்லாமல், மற்றொருவருக்கும் அதை அருளுவது என் காரியமல்ல என்றார்.
	<br /><br />
	41. மற்றப் பத்துப்பேரும் அதைக்கேட்டு, யாக்கோபின் மேலும் யோவானின் மேலும் எரிச்சலானார்கள்.
	<br /><br />
	42. அப்பொழுது, இயேசு அவர்களைக் கிட்டவரச்செய்து: புறஜாதியாருக்கு அதிகாரிகளாக எண்ணப்பட்டவர்கள் அவர்களை இறுமாப்பாய் ஆளுகிறார்கள் என்றும், அவர்களில் பெரியவர்கள் அவர்கள்மேல் கடினமாய் அதிகாரம் செலுத்துகிறார்கள் என்றும் நீங்கள் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	43. உங்களுக்குள்ளே அப்படி இருக்கலாகாது; உங்களில் எவனாகிலும் பெரியவனாயிருக்க விரும்பினால், அவன் உங்களுக்குப் பணிவிடைக்காரனாயிருக்கக்கடவன்.
	<br /><br />
	44. உங்களில் எவனாகிலும் முதன்மையானவனாயிருக்க விரும்பினால், அவன் எல்லாருக்கும் ஊழியக்காரனாயிருக்கக்கடவன்.
	<br /><br />
	45. அப்படியே, மனுஷகுமாரனும் ஊழியங்கொள்ளும்படி வராமல், ஊழியஞ்செய்யவும், அநேகரை மீட்கும்பொருளாகத் தம்முடைய ஜீவனைக் கொடுக்கவும் வந்தார் என்றார்.
	<br /><br />
	46. பின்பு அவர்கள் எரிகோவுக்கு வந்தார்கள். அவரும் அவருடைய சீஷர்களும் திரளான ஜனங்களும் எரிகோவைவிட்டுப் புறப்படுகிறபோது, திமேயுவின் மகனாகிய பர்திமேயு என்கிற ஒரு குருடன், வழியருகே உட்கார்ந்து, பிச்சை கேட்டுக்கொண்டிருந்தான்.
	<br /><br />
	47. அவன் நசரேயனாகிய இயேசு வருகிறாரென்று கேள்விப்பட்டு: இயேசுவே, தாவீதின் குமாரனே, எனக்கு இரங்கும் என்று கூப்பிடத் தொடங்கினான்.
	<br /><br />
	48. அவன் பேசாதிருக்கும்படி அநேகர் அவனை அதட்டினார்கள். அவனோ: தாவீதின் குமாரனே, எனக்கு இரங்கும் என்று, முன்னிலும் அதிகமாய்க் கூப்பிட்டான்.
	<br /><br />
	49. இயேசு நின்று, அவனை அழைத்துவரச் சொன்னார். அவர்கள் அந்தக் குருடனை அழைத்து: திடன்கொள், எழுந்திரு, உன்னை அழைக்கிறார் என்றார்கள்.
	<br /><br />
	50. உடனே அவன் தன் மேல்வஸ்திரத்தை எறிந்துவிட்டு, எழுந்து, இயேசுவினிடத்தில் வந்தான்.
	<br /><br />
	51. இயேசு அவனை நோக்கி: நான் உனக்கு என்ன செய்யவேண்டும் என்றிருக்கிறாய் என்றார். அதற்கு அந்தக் குருடன்: ஆண்டவரே, நான் பார்வையடையவேண்டும் என்றான்.
	<br /><br />
	52. இயேசு அவனை நோக்கி: நீ போகலாம், உன் விசுவாசம் உன்னை இரட்சித்தது என்றார். உடனே அவன் பார்வையடைந்து, வழியிலே இயேசுவுக்குப் பின்சென்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark10