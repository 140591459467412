import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke24 = () => {
  const verseNumber = 24;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 24 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. வாரத்தின் முதலாம்நாள் அதிகாலையிலே தாங்கள் ஆயத்தம்பண்ணின கந்தவர்க்கங்களை அவர்கள் எடுத்துக்கொண்டு வேறு சில ஸ்திரீகளோடுங்கூடக் கல்லறையினிடத்தில் வந்தார்கள்.
	<br /><br />
	2. கல்லறையை அடைத்திருந்த கல் புரட்டித் தள்ளப்பட்டிருக்கிறதைக்கண்டு,
	<br /><br />
	3. உள்ளே பிரவேசித்து, கர்த்தராகிய இயேசுவின் சரீரத்தைக் காணாமல்,
	<br /><br />
	4. அதைக்குறித்து மிகுந்த கலக்கமடைந்திருக்கையில், பிரகாசமுள்ள வஸ்திரந்தரித்த இரண்டுபேர் அவர்கள் அருகே நின்றார்கள்.
	<br /><br />
	5. அந்த ஸ்திரீகள் பயப்பட்டுத் தலைகவிழ்ந்து தரையை நோக்கி நிற்கையில், அந்த இரண்டுபேரும் அவர்களை நோக்கி: உயிரோடிருக்கிறவரை நீங்கள் மரித்தோரிடத்தில் தேடுகிறதென்ன?
	<br /><br />
	6. அவர் இங்கே இல்லை, அவர் உயிர்த்தெழுந்தார்.
	<br /><br />
	7. மனுஷகுமாரன் பாவிகளான மனுஷர் கைகளில் ஒப்புக்கொடுக்கப்படவும், சிலுவையில் அறையப்படவும், மூன்றாம் நாளில் எழுந்திருக்கவும் வேண்டுமென்பதாக அவர் கலிலேயாவிலிருந்த காலத்தில் உங்களுக்குச் சொன்னதை நினைவுகூருங்கள் என்றார்கள்.
	<br /><br />
	8. அப்பொழுது அவர்கள் அவருடைய வார்த்தைகளை நினைவுகூர்ந்து,
	<br /><br />
	9. கல்லறையை விட்டுத் திரும்பிப்போய், இந்தச் சங்கதிகளெல்லாவற்றையும் பதினொருவருக்கும் மற்றெல்லாருக்கும் அறிவித்தார்கள்.
	<br /><br />
	10. இவைகளை அப்போஸ்தலருக்குச் சொன்னவர்கள் மகதலேனா மரியாளும், யோவன்னாளும், யாக்கோபின் தாயாகிய மரியாளும், இவர்களுடனேகூட இருந்த மற்ற ஸ்திரீகளுமே.
	<br /><br />
	11. இவர்களுடைய வார்த்தைகள் அவர்களுக்கு வீண்பேச்சாகத் தோன்றினதினால், அவர்கள் இவர்களை நம்பவில்லை.
	<br /><br />
	12. பேதுருவோ எழுந்திருந்து, கல்லறையினிடத்திற்கு ஓடி, அதற்குள்ளே குனிந்துபார்க்கையில், சீலைகளைத் தனிப்பட வைத்திருக்கக்கண்டு, சம்பவித்ததைக் குறித்துத் தன்னில் ஆச்சரியப்பட்டுக்கொண்டுபோனான்.
	<br /><br />
	13. அன்றையத்தினமே அவர்களில் இரண்டுபேர் எருசலேமுக்கு ஏழு அல்லது எட்டுமைல் தூரமான எம்மாவு என்னும் கிராமத்துக்குப் போனார்கள்.
	<br /><br />
	14. போகையில் இந்த வர்த்தமானங்கள் யாவையுங்குறித்து அவர்கள் ஒருவரோடொருவர் பேசிக்கொண்டார்கள்.
	<br /><br />
	15. இப்படி அவர்கள் பேசி, சம்பாஷித்துக்கொண்டிருக்கையில், இயேசு தாமே சேர்ந்து அவர்களுடனேகூட நடந்துபோனார்.
	<br /><br />
	16. ஆனாலும் அவரை அறியாதபடிக்கு அவர்களுடைய கண்கள் மறைக்கப்பட்டிருந்தது.
	<br /><br />
	17. அப்பொழுது அவர் அவர்களை நோக்கி: நீங்கள் துக்கமுகமுள்ளவர்களாய் வழிநடந்து, ஒருவருக்கொருவர் சொல்லிக்கொள்ளுகிற காரியங்கள் என்னவென்று கேட்டார்.
	<br /><br />
	18. அவர்களில் ஒருவனாகிய கிலெயோப்பா என்பவன் பிரதியுத்தரமாக: இந்நாட்களில் எருசலேமிலே நடந்தவைகளை அறியாதபடிக்கு நீர் அந்நியராயிருக்கிறீரோ என்றான்.
	<br /><br />
	19. அவர்: எவைகள் என்றார். அதற்கு அவர்கள்: நசரேயனாகிய இயேசுவைக் குறித்தவைகளே; அவர் தேவனுக்கு முன்பாகவும் ஜனங்களெல்லாருக்கு முன்பாகவும் செய்கையிலும் வாக்கிலும் வல்லமையுள்ள தீர்க்கதரிசியாயிருந்தார்.
	<br /><br />
	20. நம்முடைய பிரதான ஆசாரியரும் அதிகாரிகளும் அவரை மரண ஆக்கினைக்குட்படுத்தி, சிலுவையில் அறைந்தார்கள்.
	<br /><br />
	21. அவரே இஸ்ரவேலை மீட்டிரட்சிப்பவர் என்று நாங்கள் நம்பியிருந்தோம். இவைகள் சம்பவித்து இன்று மூன்று நாளாகிறது.
	<br /><br />
	22. ஆனாலும் எங்கள் கூட்டத்தைச் சேர்ந்த சில ஸ்திரீகள் அதிகாலமே கல்லறையினிடத்திற்குப்போய்,
	<br /><br />
	23. அவருடைய சரீரத்தைக் காணாமல், திரும்பிவந்து, அவர் உயிரோடிருக்கிறார் என்று சொன்ன தேவதூதரைத் தரிசித்தோம் என்று சொல்லி, எங்களைப் பிரமிக்கப்பண்ணினார்கள்.
	<br /><br />
	24. அப்பொழுது எங்களிலே சிலர் கல்லறையினிடத்திற்குப் போய், ஸ்திரீகள் சொன்னபடியே கண்டார்கள்; அவரையோ காணவில்லை என்றார்கள்.
	<br /><br />
	25. அப்பொழுது அவர் அவர்களை நோக்கி: தீர்க்கதரிசிகள் சொன்ன யாவையும் விசுவாசிக்கிறதற்குப் புத்தியில்லாத மந்த இருதயமுள்ளவர்களே,
	<br /><br />
	26. கிறிஸ்து இவ்விதமாகப் பாடுபடவும், தமது மகிமையில் பிரவேசிக்கவும் வேண்டியதில்லையா என்று சொல்லி,
	<br /><br />
	27. மோசே முதலிய சகல தீர்க்கதரிசிகளும் எழுதின வேதவாக்கியங்களெல்லாவற்றிலும் தம்மைக்குறித்துச் சொல்லியவைகளை அவர்களுக்கு விவரித்துக் காண்பித்தார்.
	<br /><br />
	28. அத்தருணத்தில் தாங்கள் போகிற கிராமத்துக்குச் சமீபமானார்கள். அப்பொழுது அவர் அப்புறம் போகிறவர் போலக் காண்பித்தார்.
	<br /><br />
	29. அவர்கள் அவரை நோக்கி: நீர் எங்களுடனே தங்கியிரும், சாயங்காலமாயிற்று, பொழுதும் போயிற்று, என்று அவரை வருந்திக் கேட்டுக்கொண்டார்கள். அப்பொழுது அவர் அவர்களுடனே தங்கும்படி உள்ளே போனார்.
	<br /><br />
	30. அவர்களோடே அவர் பந்தியிருக்கையில், அவர் அப்பத்தை எடுத்து, ஆசீர்வதித்து, அதைப்பிட்டு, அவர்களுக்குக் கொடுத்தார்.
	<br /><br />
	31. அப்பொழுது அவர்களுடைய கண்கள் திறக்கப்பட்டு, அவரை அறிந்தார்கள். உடனே அவர் அவர்களுக்கு மறைந்துபோனார்.
	<br /><br />
	32. அப்பொழுது அவர்கள் ஒருவரையொருவர் நோக்கி: வழியிலே அவர் நம்முடனே பேசி, வேதவாக்கியங்களை நமக்கு விளங்கக்காட்டினபொழுது, நம்முடைய இருதயம் நமக்குள்ளே கொழுந்துவிட்டு எரியவில்லையா என்று சொல்லிக்கொண்டு,
	<br /><br />
	33. அந்நேரமே எழுந்திருந்து, எருசலேமுக்குத் திரும்பிப்போய், பதினொருவரும் அவர்களோடிருந்தவர்களும் கூடியிருக்கக்கண்டு:
	<br /><br />
	34. கர்த்தர் மெய்யாகவே உயிர்த்தெழுந்து சீமோனுக்குத் தரிசனமானார் என்று அவர்கள் சொல்லக் கேட்டு,
	<br /><br />
	35. வழியில் நடந்தவைகளையும், அவர் அப்பத்தைப் பிட்கையில் தாங்கள் அவரை அறிந்துகொண்டதையும் விவரித்துச் சொன்னார்கள்.
	<br /><br />
	36. இவைகளைக்குறித்து அவர்கள் பேசிக்கொண்டிருக்கையில், இயேசு தாமே அவர்கள் நடுவிலே நின்று: உங்களுக்குச் சமாதானம் என்றார்.
	<br /><br />
	37. அவர்கள் கலங்கி, பயந்து, ஒரு ஆவியைக் காண்கிறதாக நினைத்தார்கள்.
	<br /><br />
	38. அவர் அவர்களை நோக்கி: நீங்கள் ஏன் கலங்குகிறீர்கள்; உங்கள் இருதயங்களில் சந்தேகங்கள் எழும்புகிறதென்ன?
	<br /><br />
	39. நான்தான் என்று அறியும்படி, என் கைகளையும் என் கால்களையும் பாருங்கள், என்னைத் தொட்டுப்பாருங்கள்; நீங்கள் காண்கிறபடி, எனக்கு மாம்சமும் எலும்புகளும் உண்டாயிருக்கிறதுபோல ஒரு ஆவிக்கு இராதே என்று சொல்லி,
	<br /><br />
	40. தம்முடைய கைகளையும் கால்களையும் அவர்களுக்குக் காண்பித்தார்.
	<br /><br />
	41. ஆனாலும் சந்தோஷத்தினால் அவர்கள் இன்னும் விசுவாசியாமல் ஆச்சரியப்படுகையில்: புசிக்கிறதற்கு ஏதாகிலும் இங்கே உண்டா என்று அவர்களிடத்தில் கேட்டார்.
	<br /><br />
	42. அப்பொழுது பொரித்த மீன்கண்டத்தையும் தேன்கூட்டுத் துணிக்கையையும் அவருக்குக் கொடுத்தார்கள்.
	<br /><br />
	43. அவைகளை அவர் வாங்கி அவர்களுக்கு முன்பாகப் புசித்து,
	<br /><br />
	44. அவர்களை நோக்கி: மோசேயின் நியாயப்பிரமாணத்திலும் தீர்க்கதரிசிகளின் ஆகமங்களிலும் சங்கீதங்களிலும் என்னைக்குறித்து எழுதியிருக்கிறவைகளெல்லாம் நிறைவேறவேண்டியதென்று, நான் உங்களோடிருந்தபோது உங்களுக்குச் சொல்லிக்கொண்டுவந்த விசேஷங்கள் இவைகளே என்றார்.
	<br /><br />
	45. அப்பொழுது வேதவாக்கியங்களை அறிந்துகொள்ளும்படி அவர்களுடைய மனதை அவர் திறந்து அவர்களை நோக்கி:
	<br /><br />
	46. எழுதியிருக்கிறபடி, கிறிஸ்து பாடுபடவும், மூன்றாம் நாளில் மரித்தோரிலிருந்தெழுந்திருக்கவும் வேண்டியதாயிருந்தது;
	<br /><br />
	47. அன்றியும் மனந்திரும்புதலும் பாவமன்னிப்பும் எருசலேம் தொடங்கிச் சகல தேசத்தாருக்கும் அவருடைய நாமத்தினாலே பிரசங்கிக்கப்படவும் வேண்டியது.
	<br /><br />
	48. நீங்கள் இவைகளுக்குச் சாட்சிகளாயிருக்கிறீர்கள்.
	<br /><br />
	49. என் பிதா வாக்குத்தத்தம்பண்ணினதை, இதோ, நான் உங்களுக்கு அனுப்புகிறேன். நீங்களோ உன்னதத்திலிருந்து வரும் பெலனால் தரிப்பிக்கப்படும்வரைக்கும் எருசலேம் நகரத்தில் இருங்கள் என்றார்.
	<br /><br />
	50. பின்பு அவர் பெத்தானியாவரைக்கும் அவர்களை அழைத்துக்கொண்டுபோய், தம்முடைய கைகளை உயர்த்தி, அவர்களை ஆசீர்வதித்தார்.
	<br /><br />
	51. அவர்களை ஆசீர்வதிக்கையில், அவர்களை விட்டுப் பிரிந்து, பரலோகத்துக்கு எடுத்துக்கொள்ளப்பட்டார்.
	<br /><br />
	52. அவர்கள் அவரைப் பணிந்துகொண்டு, மிகுந்த சந்தோஷத்தோடே எருசலேமுக்குத் திரும்பிவந்து,
	<br /><br />
	53. நாடோறும் தேவாலயத்திலே தேவனைப் புகழ்ந்து துதித்துக்கொண்டிருந்தார்கள். ஆமென்.
</p>
<p>
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke24