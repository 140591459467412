import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 14 </title>
	<meta
          name="description"
          content="Acts 14 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 14 |
          Acts of Apostles Chapter-14 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இக்கோனியா பட்டணத்திலே அவர்கள் இருவரும் யூதருடைய ஜெபஆலயத்தில் பிரவேசித்து, யூதரிலும் கிரேக்கரிலும் திரளான ஜனங்கள் விசுவாசிக்கத்தக்கதாகப் பிரசங்கித்தார்கள்.
	<br /><br />
	2. விசுவாசியாத யூதர்கள் சகோதரருக்கு விரோதமாகப் புறஜாதியாருடைய மனதை எழுப்பிவிட்டு, பகையுண்டாக்கினார்கள்.
	<br /><br />
	3. அவர்கள் அங்கே அநேகநாள் சஞ்சரித்துக் கர்த்தரை முன்னிட்டுத் தைரியமுள்ளவர்களாய்ப் போதகம்பண்ணினார்கள்; அவர் தமது கிருபையுள்ள வசனத்திற்குச் சாட்சியாக அடையாளங்களும் அற்புதங்களும் அவர்கள் கைகளால் செய்யப்படும்படி அநுக்கிரகம்பண்ணினார்.
	<br /><br />
	4. பட்டணத்து ஜனங்கள் பிரிந்து, சிலர் யூதரையும் சிலர் அப்போஸ்தலரையும் சேர்ந்துகொண்டார்கள்.
	<br /><br />
	5. இவர்களை அவமானப்படுத்தவும் கல்லெறியவும் வேண்டுமென்று, புறஜாதியாரும் யூதரும் அவர்கள் அதிகாரிகளும் அமளிபண்ணுகையில்,
	<br /><br />
	6. இவர்கள் அதை அறிந்து, லிக்கவோனியா நாட்டிலுள்ள பட்டணங்களாகிய லீஸ்திராவுக்கும் தெர்பைக்கும் அவைகளின் சுற்றுப்புறங்களுக்கும் ஓடிப்போய்;
	<br /><br />
	7. அங்கே சுவிசேஷத்தைப் பிரசங்கம் பண்ணினார்கள்.
	<br /><br />
	8. லீஸ்திராவிலே ஒருவன் தன் தாயின் வயிற்றிலிருந்து பிறந்ததுமுதல் சப்பாணியாயிருந்து, ஒருபோதும் நடவாமல், கால்கள் வழங்காதவனாய் உட்கார்ந்து,
	<br /><br />
	9. பவுல் பேசுகிறதைக் கேட்டுக்கொண்டிருந்தான். அவனைப் பவுல் உற்றுப்பார்த்து, இரட்சிப்புக்கேற்ற விசுவாசம் அவனுக்கு உண்டென்று கண்டு:
	<br /><br />
	10. நீ எழுந்து காலூன்றி நிமிர்ந்து நில் என்று உரத்த சத்தத்தோடே சொன்னான். உடனே அவன் குதித்தெழுந்து நடந்தான்.
	<br /><br />
	11. பவுல் செய்ததை ஜனங்கள் கண்டு, தேவர்கள் மனுஷரூபமெடுத்து நம்மிடத்தில் இறங்கிவந்திருக்கிறார்கள் என்று லிக்கவோனியா பாஷையிலே சத்தமிட்டுச் சொல்லி,
	<br /><br />
	12. பர்னபாவை யூப்பித்தர் என்றும், பவுல் பிரசங்கத்தை நடத்தினவனானபடியினால் அவனை மெர்க்கூரி என்றும் சொன்னார்கள்.
	<br /><br />
	13. அல்லாமலும் பட்டணத்துக்கு முன்னே இருந்த யூப்பித்தருடைய கோவில் பூஜாசாரி எருதுகளையும் பூமாலைகளையும் வாசலண்டையிலே கொண்டுவந்து, ஜனங்களோடேகூட அவர்களுக்குப் பலியிட மனதாயிருந்தான்.
	<br /><br />
	14. அப்போஸ்தலராகிய பர்னபாவும் பவுலும் அதைக் கேட்டபொழுது, தங்கள் வஸ்திரங்களைக் கிழித்துக்கொண்டு, கூட்டத்துக்குள்ளே ஓடி, உரத்த சத்தமாய்:
	<br /><br />
	15. மனுஷரே, ஏன் இப்படிச் செய்கிறீர்கள்? நாங்களும் உங்களைப்போலப் பாடுள்ள மனுஷர்தானே; நீங்கள் இந்த வீணான தேவர்களைவிட்டு, வானத்தையும் பூமியையும் சமுத்திரத்தையும் அவைகளிலுள்ள யாவற்றையும் உண்டாக்கின ஜீவனுள்ள தேவனிடத்திற்குத் திரும்பவேண்டுமென்று உங்களுக்குப் பிரசங்கிக்கிறோம்.
	<br /><br />
	16. சென்ற காலங்களில் அவர் சகல ஜனங்களையும் தங்கள் தங்கள் வழிகளிலே நடக்கவிட்டிருந்தும்,
	<br /><br />
	17. அவர் நன்மை செய்துவந்து, வானத்திலிருந்து மழைகளையும் செழிப்புள்ள காலங்களையும் நமக்குத் தந்து, ஆகாரத்தினாலும் சந்தோஷத்தினாலும் நம்முடைய இருதயங்களை நிரப்பி, இவ்விதமாய் அவர் தம்மைக்குறித்துச் சாட்சி விளங்கப்பண்ணாதிருந்ததில்லை என்றார்கள்.
	<br /><br />
	18. இப்படி அவர்கள் சொல்லியும் தங்களுக்கு ஜனங்கள் பலியிடாதபடிக்கு அவர்களை அமர்த்துகிறது அரிதாயிருந்தது.
	<br /><br />
	19. பின்பு அந்தியோகியாவிலும் இக்கோனியாவிலுமிருந்து சில யூதர்கள் வந்து, ஜனங்களுக்குப் போதனைசெய்து, பவுலைக் கல்லெறிந்து, அவன் மரித்துப்போனானென்று எண்ணி, அவனைப் பட்டணத்துக்கு வெளியிலே இழுத்துக்கொண்டு போனார்கள்.
	<br /><br />
	20. சீஷர்கள் அவனைச் சூழ்ந்துநிற்கையில், அவன் எழுந்து, பட்டணத்திற்குள் பிரவேசித்தான். மறுநாளில் பர்னபாவுடனேகூடத் தெர்பைக்குப் புறப்பட்டுப்போனான்.
	<br /><br />
	21. அந்தப் பட்டணத்தில் அவர்கள் சுவிசேஷத்தைப் பிரசங்கித்து, அநேகரைச் சீஷராக்கினபின்பு, லீஸ்திராவுக்கும் இக்கோனியாவுக்கும் அந்தியோகியாவுக்கும் திரும்பிவந்து,
	<br /><br />
	22. சீஷருடைய மனதைத் திடப்படுத்தி, விசுவாசத்திலே நிலைத்திருக்கும்படி அவர்களுக்குப் புத்திசொல்லி, நாம் அநேக உபத்திரவங்களின் வழியாய்த் தேவனுடைய ராஜ்யத்தில் பிரவேசிக்கவேண்டுமென்று சொன்னார்கள்.
	<br /><br />
	23. அல்லாமலும் அந்தந்தச் சபைகளில் அவர்களுக்கு மூப்பர்களை ஏற்படுத்தி வைத்து, உபவாசித்து ஜெபம்பண்ணி, அவர்கள் விசுவாசித்துப் பற்றிக்கொண்ட கர்த்தருக்கு அவர்களை ஒப்புவித்தார்கள்.
	<br /><br />
	24. பின்பு பிசீதியாநாட்டைக் கடந்து, பம்பிலியா நாட்டிற்கு வந்து,
	<br /><br />
	25. பெர்கே ஊரில் வசனத்தைப் பிரசங்கித்து, அத்தலியா பட்டணத்திற்குப் போனார்கள்.
	<br /><br />
	26. அங்கே கப்பல் ஏறி, தாங்கள் நிறைவேற்றின கிரியைக்காகத் தேவனுடைய கிருபைக்கு ஒப்புவிக்கப்பட்டுப் புறப்பட்டு அந்தியோகியாவுக்கு வந்தார்கள்.
	<br /><br />
	27. அவர்கள் அங்கே சேர்ந்தபொழுது சபையைக் கூடிவரச்செய்து, தேவன் தங்களைக்கொண்டு செய்தவைகளையும், அவர் புறஜாதிகளுக்கு விசுவாசத்தின் கதவைத்திறந்ததையும் அறிவித்து,
	<br /><br />
	28. அங்கே சீஷருடனேகூட அநேகநாள் சஞ்சரித்திருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts14