import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 14 </title>
	<meta
          name="description"
          content="Zechariah 14 | சகரியா 14 | Sageria 14 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, கர்த்தருடைய நாள் வருகிறது, உன்னில் கொள்ளையானது உன் நடுவிலே பங்கிடப்படும்.
	<br /><br />
	2. எருசலேமுக்கு விரோதமாக யுத்தம்பண்ணச் சகல ஜாதிகளையும் கூட்டுவேன்; நகரம் பிடிக்கப்படும்; வீடுகள் கொள்ளையாகும்; ஸ்திரீகள் அவமானப்படுவார்கள்; நகரத்தாரில் பாதி மனுஷர் சிறைப்பட்டுப்போவார்கள்; மீதியான ஜனமோ நகரத்தை விட்டு அறுப்புண்டுபோவதில்லை.
	<br /><br />
	3. கர்த்தர் புறப்பட்டு, யுத்தநாளிலே போராடுவதுபோல் அந்த ஜாதிகளோடே போராடுவார்.
	<br /><br />
	4. அந்நாளிலே அவருடைய பாதங்கள் கிழக்கே எருசலேமுக்கு எதிரே இருக்கிற ஒலிவமலையின்மேல் நிற்கும்; அப்பொழுது மகா பெரிய பள்ளத்தாக்கு உண்டாகும்படி ஒலிவமலை தன் நடுமையத்திலே கிழக்கு மேற்காய் எதிராகப் பிளந்துபோம்; அதினாலே, ஒரு பாதி வடபக்கத்திலும் ஒரு பாதி தென்பக்கத்திலும் சாயும்.
	<br /><br />
	5. அப்பொழுது என் மலைகளின் பள்ளத்தாக்கு வழியாய் ஓடிப்போவீர்கள்; மலைகளின் பள்ளத்தாக்கு ஆத்சால்மட்டும் போகும்; நீங்கள் யூதாவின் ராஜாவாகிய உசியாவின் நாட்களில் பூமியதிர்ச்சிக்குத் தப்பி ஓடிப்போனதுபோல் ஓடிப்போவீர்கள்; என் தேவனாகிய கர்த்தர் வருவார்; தேவரீரோடே எல்லாப் பரிசுத்தவான்களும் வருவார்கள்.
	<br /><br />
	6. அந்நாளில் வெளிச்சம் இல்லாமல், ஒருவேளை பிரகாசமும் ஒருவேளை மப்புமாயிருக்கும்.
	<br /><br />
	7. ஒருநாள் உண்டு, அது கர்த்தருக்குத் தெரிந்தது; அது பகலுமல்ல இரவுமல்ல; ஆனாலும் சாயங்காலத்திலே வெளிச்சமுண்டாகும்.
	<br /><br />
	8. அந்நாளிலே ஜீவத்தண்ணீர்கள் எருசலேமிலிருந்து புறப்பட்டு, பாதி கிழக்குச் சமுத்திரத்துக்கும், பாதி மேற்குச் சமுத்திரத்துக்கும் போய், மாரிகாலத்துக்கும் கோடைகாலத்துக்கும் இருக்கும்.
	<br /><br />
	9. அப்பொழுது கர்த்தர் பூமியின்மீதெங்கும் ராஜாவாயிருப்பார்; அந்நாளில் ஒரே கர்த்தர் இருப்பார், அவருடைய நாமமும் ஒன்றாயிருக்கும்.
	<br /><br />
	10. தேசமெல்லாம் கேபாதொடங்கி எருசலேமுக்குத் தெற்கே இருக்கிற ரிம்மோன்வரைக்கும் சமபூமியாகத் திருத்தப்படும்; எருசலேமோ உயர்ந்ததாகி, தன் ஸ்தானத்திலே பென்யமீன் வாசல்தொடங்கி முதல்வாசலென்கிற ஸ்தலமட்டும் கோடிவாசல்வரைக்கும், அனானெயேல் கோபுரம் துவக்கி ராஜாவின் திராட்ச ஆலைகள்மட்டும் குடியேற்றப்பட்டிருக்கும்.
	<br /><br />
	11. அதிலே ஜனங்கள் வாசம்பண்ணுவார்கள்; இனிச் சங்கரிப்பில்லாமல் எருசலேம் சுகமாய்த் தங்கியிருக்கும்.
	<br /><br />
	12. எருசலேமுக்கு விரோதமாக யுத்தம்பண்ணின எல்லா ஜனங்களையும் கர்த்தர் வாதிக்கும் வாதையாவது: அவர்கள் காலூன்றி நிற்கையிலும் அவர்களுடைய சதை அழிந்துபோகும்; அவர்கள் கண்கள் தங்கள் குழிகளிலே கெட்டுப்போகும்; அவர்கள் நாவு அவர்கள் வாயிலே அழுகிப்போகும்.
	<br /><br />
	13. அந்நாளிலே கர்த்தரால் பெரிய கலக்கம் அவர்களுக்குள் உண்டாகும்; அவனவன் தன் தன் அயலானின் கையைப் பிடிப்பான்; அவனவனுடைய கை அவனவன் அயலானுடைய கைக்கு விரோதமாக எழும்பும்.
	<br /><br />
	14. யூதாவும் எருசலேமிலே யுத்தம்பண்ணும்; அப்பொழுது சுற்றிலும் இருக்கிற சகல ஜாதிகளுடைய ஆஸ்தியாகிய பொன்னும் வெள்ளியும் வஸ்திரங்களும் மகா திரளாகக் கூட்டப்படும்.
	<br /><br />
	15. அந்தப் பாளையங்களில் இருக்கும் குதிரைகள் கோவேறு கழுதைகள் ஒட்டகங்கள் கழுதைகள் முதலான எல்லா மிருகஜீவன்களுக்கும் வரும் வாதையும், அந்த வாதையைப் போலவே இருக்கும்.
	<br /><br />
	16. பின்பு, எருசலேமுக்கு விரோதமாக வந்திருந்த எல்லா ஜாதிகளிலும் மீதியான யாவரும் சேனைகளின் கர்த்தராகிய ராஜாவைத் தொழுதுகொள்ளும்படிக்கும், கூடாரப்பண்டிகையை ஆசரிக்கும்படிக்கும், வருஷாவருஷம் வருவார்கள்.
	<br /><br />
	17. அப்பொழுது பூமியின் வம்சங்களில் சேனைகளின் கர்த்தராகிய ராஜாவைத் தொழுதுகொள்ள எருசலேமுக்கு வராதவர்கள் எவர்களோ அவர்கள்மேல் மழை வருஷிப்பதில்லை.
	<br /><br />
	18. மழை வருஷிக்காத எகிப்தின் வம்சம் வராமலும் சேராமலும் போனால், கூடாரப்பண்டிகையை ஆசரிக்கவராத ஜாதிகளைக் கர்த்தர் வாதிக்கும் வாதையே அவர்கள்மேலும் வரும்.
	<br /><br />
	19. இது எகிப்தியருடைய பாவத்துக்கும், கூடாரப்பண்டிகையை ஆசரிக்க வராத சகல ஜாதிகளுடைய பாவத்துக்கும் வரும் ஆக்கினை.
	<br /><br />
	20. அந்நாளிலே குதிரைகளின் மணிகளிலே கர்த்தருக்குப் பரிசுத்தம் என்னும் விலாசம் எழுதியிருக்கும்; கர்த்தருடைய ஆலயத்திலுள்ள பானைகள் பலிபீடத்துக்கு முன்பாக இருக்கிற பாத்திரங்களைப் போலிருக்கும்.
	<br /><br />
	21. அப்பொழுது எருசலேமிலும் யூதாவிலுமுள்ள எல்லாப் பானைகளும் சேனைகளின் கர்த்தருக்குப் பரிசுத்தமாயிருக்கும்; பலியிடுகிற யாவரும் வந்து அவைகளில் எடுத்து, அவைகளில் சமைப்பார்கள்; அந்நாள்முதல் சேனைகளுடைய கர்த்தரின் ஆலயத்திலே யாதொரு கானானியனும் இருப்பதில்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah14