import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OnePeter1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> Living Hope & Sure Salvation Peter in Tamil Bible | 1 பேதுரு - 1 </title>
	<meta
          name="description"
          content="Living Hope & Sure Salvation in Tamil Bible Verse | பேதுரு அதிகாரம் -1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
		<br />
<h2>
* வாழும் நம்பிக்கை & நிச்சயமான இரட்சிப்பு *
			</h2>
<br />
<br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. இயேசுகிறிஸ்துவின் அப்போஸ்தலனாகிய பேதுரு, பொந்து, கலாத்தியா, கப்பத்தோக்கியா, ஆசியா, பித்தினியா தேசங்களிலே சிதறியிருக்கிறவர்களில்,
	<br /><br />
	2. பிதாவாகிய தேவனுடைய முன்னறிவின்படியே, ஆவியானவரின் பரிசுத்தமாக்குதலினாலே, கீழ்ப்படிதலுக்கும் இயேசுகிறிஸ்துவினுடைய இரத்தந்தெளிக்கப்படுதலுக்கும் தெரிந்துகொள்ளப்பட்ட பரதேசிகளுக்கு எழுதுகிறதாவது: கிருபையும் சமாதானமும் உங்களுக்குப் பெருகக்கடவது.
	<br /><br />
	3. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் பிதாவாகிய தேவனுக்கு ஸ்தோத்திரம் உண்டாவதாக;
	<br /><br />
	4. அவர், இயேசுகிறிஸ்து மரித்தோரிலிருந்து எழுந்ததினாலே, அழியாததும் மாசற்றதும் வாடாததுமாகிய சுதந்தரத்திற்கேதுவாக, ஜீவனுள்ள நம்பிக்கை உண்டாகும்படி, தமது மிகுந்த இரக்கத்தின்படியே நம்மை மறுபடியும் ஜெநிப்பித்தார்.
	<br /><br />
	5. கடைசிக்காலத்திலே வெளிப்பட ஆயத்தமாக்கப்பட்டிருக்கிற இரட்சிப்புக்கு ஏதுவாக விசுவாசத்தைக்கொண்டு தேவனுடைய பலத்தினாலே காக்கப்பட்டிருக்கிற உங்களுக்கு அந்தச் சுதந்தரம் பரலோகத்தில் வைக்கப்பட்டிருக்கிறது.
	<br /><br />
	6. இதிலே நீங்கள் மிகவும் சந்தோஷப்படுகிறீர்கள்; என்றாலும், துன்பப்படவேண்டியது அவசியமானதால், இப்பொழுது கொஞ்சக்காலம் பலவிதமான சோதனைகளினாலே துக்கப்படுகிறீர்கள்.
	<br /><br />
	7. அழிந்துபோகிற பொன் அக்கினியினாலே சோதிக்கப்படும்; அதைப்பார்க்கிலும் அதிக விலையேறப்பெற்றதாயிருக்கிற உங்கள் விசுவாசம் சோதிக்கப்பட்டு, இயேசுகிறிஸ்து வெளிப்படும்போது உங்களுக்குப் புகழ்ச்சியும் கனமும் மகிமையுமுண்டாகக் காணப்படும்.
	<br /><br />
	8. அவரை நீங்கள் காணாமலிருந்தும் அவரிடத்தில் அன்புகூருகிறீர்கள்; இப்பொழுது அவரைத் தரிசியாமலிருந்தும் அவரிடத்தில் விசுவாசம் வைத்து, சொல்லிமுடியாததும் மகிமையால் நிறைந்ததுமாயிருக்கிற சந்தோஷமுள்ளவர்களாய்க் களிகூர்ந்து,
	<br /><br />
	9. உங்கள் விசுவாசத்தின் பலனாகிய ஆத்துமரட்சிப்பை அடைகிறீர்கள்.
	<br /><br />
	10. உங்களுக்கு உண்டான கிருபையைக் குறித்துத் தீர்க்கதரிசனஞ்சொன்ன தீர்க்கதரிசிகள் இந்த இரட்சிப்பைக்குறித்துக் கருத்தாய் ஆராய்ந்து பரிசோதனைபண்ணினார்கள்;
	<br /><br />
	11. தங்களிலுள்ள கிறிஸ்துவின் ஆவியானவர் கிறிஸ்துவுக்கு உண்டாகும் பாடுகளையும், அவைகளுக்குப் பின்வரும் மகிமைகளையும் முன்னறிவித்தபோது, இன்னகாலத்தைக் குறித்தாரென்பதையும், அந்தக் காலத்தின் விசேஷம் இன்னதென்பதையும் ஆராய்ந்தார்கள்.
	<br /><br />
	12. தங்கள்நிமித்தமல்ல, நமதுநிமித்தமே இவைகளைத் தெரிவித்தார்களென்று அவர்களுக்கு வெளியாக்கப்பட்டது; பரலோகத்திலிருந்து அனுப்பப்பட்ட பரிசுத்த ஆவியினாலே உங்களுக்குச் சுவிசேஷத்தைப் பிரசங்கித்தவர்களைக்கொண்டு இவைகள் இப்பொழுது உங்களுக்கு அறிவிக்கப்பட்டு வருகிறது; இவைகளை உற்றுப்பார்க்கத் தேவதூதரும் ஆசையாயிருக்கிறார்கள்.
	<br /><br />
	13. ஆகையால், நீங்கள் உங்கள் மனதின் அரையைக் கட்டிக்கொண்டு, தெளிந்த புத்தியுள்ளவர்களாயிருந்து; இயேசுகிறிஸ்து வெளிப்படும்போது உங்களுக்கு அளிக்கப்படுங் கிருபையின்மேல் பூரண நம்பிக்கையுள்ளவர்களாயிருங்கள்.
	<br /><br />
	14. நீங்கள் முன்னே உங்கள் அறியாமையினாலே கொண்டிருந்த இச்சைகளின்படி இனி நடவாமல், கீழ்ப்படிகிற பிள்ளைகளாயிருந்து,
	<br /><br />
	15. உங்களை அழைத்தவர் பரிசுத்தராயிருக்கிறதுபோல, நீங்களும் உங்கள் நடக்கைகளெல்லாவற்றிலேயும் பரிசுத்தராயிருங்கள்.
	<br /><br />
	16. நான் பரிசுத்தர், ஆகையால் நீங்களும் பரிசுத்தராயிருங்கள் என்று எழுதியிருக்கிறதே.
	<br /><br />
	17. அன்றியும், பட்சபாதமில்லாமல் அவனவனுடைய கிரியைகளின்படி நியாயந்தீர்க்கிறவரை நீங்கள் பிதாவாகத் தொழுதுகொண்டுவருகிறபடியால், இங்கே பரதேசிகளாய்ச் சஞ்சரிக்குமளவும் பயத்துடனே நடந்துகொள்ளுங்கள்.
	<br /><br />
	18. உங்கள் முன்னோர்களால் பாரம்பரியமாய் நீங்கள் அநுசரித்துவந்த வீணான நடத்தையினின்று அழிவுள்ள வஸ்துக்களாகிய வெள்ளியினாலும் பொன்னினாலும் மீட்கப்படாமல்,
	<br /><br />
	19. குற்றமில்லாத மாசற்ற ஆட்டுக்குட்டியாகிய கிறிஸ்துவின் விலையேறப்பெற்ற இரத்தத்தினாலே மீட்கப்பட்டீர்களென்று அறிந்திருக்கிறீர்களே.
	<br /><br />
	20. அவர் உலகத்தோற்றத்திற்கு முன்னே குறிக்கப்பட்டவராயிருந்து, தமது மூலமாய்த் தேவன்மேல் விசுவாசமாயிருக்கிற உங்களுக்காக இந்தக் கடைசிக் காலங்களில் வெளிப்பட்டார்.
	<br /><br />
	21. உங்கள் விசுவாசமும் நம்பிக்கையும் தேவன்மேலிருக்கும்படி, அவரை மரித்தோரிலிருந்து எழுப்பி, அவருக்கு மகிமையைக் கொடுத்தார்.
	<br /><br />
	22. ஆகையால் நீங்கள் மாயமற்ற சகோதர சிநேகமுள்ளவர்களாகும்படி, ஆவியினாலே சத்தியத்திற்குக் கீழ்ப்படிந்து, உங்கள் ஆத்துமாக்களைச் சுத்தமாக்கிக்கொண்டவர்களாயிருக்கிறபடியால், சுத்த இருதயத்தோடே ஒருவரிலொருவர் ஊக்கமாய் அன்புகூருங்கள்;
	<br /><br />
	23. அழிவுள்ள வித்தினாலே அல்ல, என்றென்றைக்கும் நிற்கிறதும் ஜீவனுள்ளதுமான தேவவசனமாகிய அழிவில்லாத வித்தினாலே மறுபடியும் ஜெநிப்பிக்கப்பட்டிருக்கிறீர்களே.
	<br /><br />
	24. மாம்சமெல்லாம் புல்லைப்போலவும், மனுஷருடைய மகிமையெல்லாம் புல்லின் பூவைப்போலவுமிருக்கிறது; புல் உலர்ந்தது, அதின் பூவும் உதிர்ந்தது.
	<br /><br />
	25. கர்த்தருடைய வசனமோ என்றென்றைக்கும் நிலைத்திருக்கும்; உங்களுக்குச் சுவிசேஷமாய் அறிவிக்கப்பட்டுவருகிற வசனம் இதுவே.
</p>


      {/* End of verse */} 

	  <br /><br />

<h3>
* 1 பேதுரு 1 கடவுளால் தேர்ந்தெடுக்கப்பட்டவர்களுக்கு ஒரு அழகான வாழ்த்துடன் தொடங்குகிறது; ஆவியானவரால் பரிசுத்தப்படுத்தப்பட்டு, உயிருள்ள நம்பிக்கையில் மீண்டும் பிறந்தார். துன்பம் கிறிஸ்துவுடன் உண்மையான விசுவாசத்தை வெளிப்படுத்தும் என்று பேதுரு ஊக்குவிக்கிறார். இந்த மாபெரும் இரட்சிப்பைப் பற்றி பேசும் போது, ​​தீர்க்கதரிசிகள் கிறிஸ்துவின் நேரத்தையும் சூழ்நிலையையும் தீவிரமாகத் தேடினர் என்பதையும் அவர் நினைவுபடுத்துகிறார். ஆகையால், நாங்கள் பரிசுத்தமாக இருக்கவும், ஒருவரையொருவர் ஆழமாக நேசிக்கவும் நினைவூட்டப்படுகிறோம், ஏனென்றால் நாங்கள் அதிக விலைக்கு வாங்கப்பட்டோம்; ஆட்டுக்குட்டியின் விலைமதிப்பற்ற இரத்தம். *
			</h3>
<br />
      </div>
    </div>
    </>
  );
}

export default OnePeter1