import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 2 </title>
	<meta
          name="description"
          content="Song of Solomon 2 | உன்னதப்பாட்டு 2 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் சாரோனின் ரோஜாவும், பள்ளத்தாக்குகளின் லீலிபுஷ்பமுமாயிருக்கிறேன்.
	<br /><br />
	2. முள்ளுகளுக்குள்ளே லீலிபுஷ்பம் எப்படியிருக்கிறதோ, அப்படியே குமாரத்திகளுக்குள்ளே எனக்குப் பிரியமானவளும் இருக்கிறாள்.
	<br /><br />
	3. காட்டுமரங்களுக்குள்ளே கிச்சிலிமரம் எப்படியிருக்கிறதோ, அப்படியே குமாரருக்குள்ளே என் நேசர் இருக்கிறார்; அதின் நிழலிலே வாஞ்சையாய் உட்காருகிறேன், அதின் கனி என் வாய்க்கு மதுரமாயிருக்கிறது.
	<br /><br />
	4. என்னை விருந்துசாலைக்கு அழைத்துக்கொண்டுபோனார்; என்மேல் பறந்த அவருடைய கொடி நேசமே.
	<br /><br />
	5. திராட்சரசத்தால் என்னைத் தேற்றுங்கள், கிச்சிலிப்பழங்களால் என்னை ஆற்றுங்கள்; நேசத்தால் சோகமடைந்திருக்கிறேன்.
	<br /><br />
	6. அவர் இடதுகை என் தலையின்கீழ் இருக்கிறது; அவர் வலதுகை என்னை அணைத்துக்கொள்ளுகிறது.
	<br /><br />
	7. எருசலேமின் குமாரத்திகளே! எனக்குப் பிரியமானவளுக்கு மனதாகுமட்டும், நீங்கள் அவளை விழிக்கப்பண்ணாமலும் எழுப்பாமலுமிருக்கும்படி வெளிமான்கள்மேலும் வெளியின் மரைகள்மேலும் உங்களை ஆணையிடுகிறேன்.
	<br /><br />
	8. இது என் நேசருடைய சத்தம்! இதோ, அவர் மலைகள்மேல் குதித்தும் மேடுகள்மேல் துள்ளியும் வருகிறார்.
	<br /><br />
	9. என் நேசர் வெளிமானுக்கும் மரைக்குட்டிக்கும் ஒப்பாயிருக்கிறார்; இதோ, அவர் எங்கள் மதிலுக்குப் புறம்பே நின்று, பலகணி வழியாய்ப் பார்த்து, கிராதியின் வழியாய்த் தமது மலர்ந்த முகத்தைக் காண்பிக்கிறார்.
	<br /><br />
	10. என் நேசர் என்னோடே பேசி: என் பிரியமே! என் ரூபவதியே! எழுந்துவா.
	<br /><br />
	11. இதோ, மாரிகாலம் சென்றது, மழைபெய்து ஒழிந்தது.
	<br /><br />
	12. பூமியிலே புஷ்பங்கள் காணப்படுகிறது; குருவிகள் பாடுங்காலம் வந்தது, காட்டுப்புறாவின் சத்தம் நமது தேசத்தில் கேட்கப்படுகிறது.
	<br /><br />
	13. அத்திமரம் காய்காய்த்தது; திராட்சக்கொடிகள் பூப்பூத்து வாசனையும் பரிமளிக்கிறது; என் பிரியமே! என் ரூபவதியே! நீ எழுந்துவா.
	<br /><br />
	14. கன்மலையின் வெடிப்புகளிலும் சிகரங்களின் மறைவிடங்களிலும் தங்குகிற என் புறாவே! உன் முகரூபத்தை எனக்குக் காண்பி, உன் சத்தத்தை நான் கேட்கட்டும்; உன் சத்தம் இன்பமும், உன் முகரூபம் அழகுமாயிருக்கிறது என்றார்.
	<br /><br />
	15. திராட்சத்தோட்டங்களைக் கெடுக்கிற குழிநரிகளையும் சிறுநரிகளையும் நமக்குப் பிடியுங்கள்; நம்முடைய திராட்சத்தோட்டங்கள் பூவும் பிஞ்சுமாயிருக்கிறதே.
	<br /><br />
	16. என் நேசர் என்னுடையவர், நான் அவருடையவள். அவர் லீலிபுஷ்பங்களுக்குள்ளே மேய்கிறார்.
	<br /><br />
	17. என் நேசரே! பகல் குளிர்ச்சியாகி, நிழல் சாய்ந்துபோகும்வரைக்கும், நீர் திரும்பி, குன்றும் பிளப்புமான கன்மலைகளில் குதித்துவரும் கலைமானுக்கும் மரைகளின் குட்டிக்கும் சமானமாயிரும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs2