import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 19 </title>
	<meta
          name="description"
          content="Acts 19 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 19 |
          Acts of Apostles Chapter-19 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொல்லோ என்பவன் கொரிந்து பட்டணத்திலே இருக்கையில், பவுல் மேடான தேசங்கள் வழியாய்ப் போய், எபேசுவுக்கு வந்தான்; அங்கே சில சீஷரைக் கண்டு:
	<br /><br />
	2. நீங்கள் விசுவாசிகளானபோது, பரிசுத்தஆவியைப் பெற்றீர்களா என்று கேட்டான். அதற்கு அவர்கள்: பரிசுத்தஆவி உண்டென்பதை நாங்கள் கேள்விப்படவே இல்லை என்றார்கள்.
	<br /><br />
	3. அப்பொழுது அவன்: அப்படியானால் நீங்கள் எந்த ஞானஸ்நானம் பெற்றீர்கள் என்றான். அதற்கு அவர்கள்: யோவான் கொடுத்த ஞானஸ்நானம் பெற்றோம் என்றார்கள்.
	<br /><br />
	4. அப்பொழுது பவுல்: யோவான் தனக்குப்பின் வருகிறவராகிய கிறிஸ்து இயேசுவில் விசுவாசிகளாயிருக்கவேண்டும் என்று ஜனங்களுக்குச் சொல்லி, மனந்திரும்புதலுக்கு ஏற்ற ஞானஸ்நானத்தைக் கொடுத்தானே என்றான்.
	<br /><br />
	5. அதைக் கேட்டபோது அவர்கள் கர்த்தராகிய இயேசுவின் நாமத்தினாலே ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	6. அல்லாமலும் பவுல் அவர்கள்மேல் கைகளை வைத்தபோது, பரிசுத்தஆவி அவர்கள்மேல் வந்தார்; அப்பொழுது அவர்கள் அந்நியபாஷைகளைப் பேசித் தீர்க்கதரிசனஞ் சொன்னார்கள்.
	<br /><br />
	7. அந்த மனுஷரெல்லாரும் ஏறக்குறையப் பன்னிரண்டுபேராயிருந்தார்கள்.
	<br /><br />
	8. பின்பு பவுல் ஜெபஆலயத்தில் பிரவேசித்து, தைரியமாய்ப் பிரசங்கித்து, மூன்று மாதமளவும் தேவனுடைய ராஜ்யத்துக்கடுத்தவைகளைக்குறித்துச் சம்பாஷணைபண்ணி, புத்திசொல்லிக்கொண்டு வந்தான்.
	<br /><br />
	9. சிலர் கடினப்பட்டு அவிசுவாசிகளாகிக் கூட்டத்திற்கு முன்பாக இந்த மார்க்கத்தை நிந்தித்தபோது, அவன் அவர்களை விட்டு விலகி, சீஷரை அவர்களிலிருந்து பிரித்துக்கொண்டு, திறன்னு என்னும் ஒருவனுடைய வித்தியாசாலையிலே அநுதினமும் சம்பாஷித்துக்கொண்டுவந்தான்.
	<br /><br />
	10. இரண்டு வருஷகாலம் இப்படி நடந்ததினாலே ஆசியாவில் குடியிருந்த யூதரும் கிரேக்கருமாகிய எல்லாரும் கர்த்தராகிய இயேசுவின் வசனத்தைக் கேட்டார்கள்.
	<br /><br />
	11. பவுலின் கைகளினாலே தேவன் விசேஷித்த அற்புதங்களைச் செய்தருளினார்.
	<br /><br />
	12. அவனுடைய சரீரத்திலிருந்து உறுமால்களையும் கச்சைகளையும் கொண்டுவந்து, வியாதிக்காரர்மேல் போட வியாதிகள் அவர்களைவிட்டு நீங்கிப்போயின; பொல்லாத ஆவிகளும் அவர்களைவிட்டுப் புறப்பட்டன.
	<br /><br />
	13. அப்பொழுது தேசாந்தரிகளாய்த் திரிகிற மந்திரவாதிகளாகிய யூதரில் சிலர் பொல்லாத ஆவிகள் பிடித்திருந்தவர்கள்மேல் கர்த்தராகிய இயேசுவின் நாமத்தைச் சொல்லத் துணிந்து: பவுல் பிரசங்கிக்கிற இயேசுவின்பேரில் ஆணையிட்டு உங்களுக்குக் கட்டளையிடுகிறோம் என்றார்கள்.
	<br /><br />
	14. பிரதான ஆசாரியனாகிய ஸ்கேவா என்னும் ஓர் யூதனுடைய குமாரர் ஏழுபேர் இப்படிச் செய்தார்கள்.
	<br /><br />
	15. பொல்லாத ஆவி அவர்களை நோக்கி: இயேசுவை அறிவேன், பவுலையும் அறிவேன், நீங்கள் யார் என்று சொல்லி,
	<br /><br />
	16. பொல்லாத ஆவியையுடைய மனுஷன் அவர்கள்மேல் பாய்ந்து, பலாத்காரம்பண்ணி, அவர்களை மேற்கொள்ள, அவர்கள் நிருவாணிகளும் காயப்பட்டவர்களுமாகி அந்த வீட்டை விட்டு ஓடிப்போனார்கள்.
	<br /><br />
	17. இது எபேசுவிலே குடியிருந்த யூதர் கிரேக்கர் அனைவருக்கும் தெரியவந்தபோது, அவர்களெல்லாரும் பயமடைந்தார்கள்; கர்த்தராகிய இயேசுவின் நாமம் மகிமைப்பட்டது.
	<br /><br />
	18. விசுவாசித்தவர்களில் அநேகர் வந்து, தங்கள் செய்கைகளை வெளிப்படுத்தி அறிக்கையிட்டார்கள்.
	<br /><br />
	19. மாயவித்தைக்காரராயிருந்தவர்களில் அநேகர் தங்கள் புஸ்தகங்களைக் கொண்டுவந்து, எல்லாருக்கு முன்பாகச் சுட்டெரித்தார்கள்; அவைகளின் கிரயத்தைத் தொகைபார்த்து, ஐம்பதினாயிரம் வெள்ளிக்காசாகக் கண்டார்கள்.
	<br /><br />
	20. இவ்வளவு பலமாய்க் கர்த்தருடைய வசனம் விருத்தியடைந்து மேற்கொண்டது.
	<br /><br />
	21. இவைகள் முடிந்தபின்பு, பவுல் மக்கெதோனியா அகாயா என்னும் நாடுகளில் சுற்றிநடந்து, எருசலேமுக்குப்போகும்படி ஆவியில் நிருணயம்பண்ணிக்கொண்டு: நான் அங்கே போனபின்பு ரோமாபுரியையும் பார்க்கவேண்டியதென்று சொல்லி,
	<br /><br />
	22. தனக்கு உதவிசெய்தவர்களில் இரண்டுபேராகிய தீமோத்தேயுவையும் எரஸ்துவையும் மக்கெதோனியாவுக்கு அனுப்பிவிட்டு; தான் பின்னுஞ் சிலகாலம் ஆசியாவிலே தங்கினான்.
	<br /><br />
	23. அக்காலத்திலே இந்த மார்க்கத்தைக்குறித்துப் பெரிய கலகம் உண்டாயிற்று.
	<br /><br />
	24. எப்படியென்றால், தெமேத்திரியு என்னும் பேர்கொண்ட ஒரு தட்டான் தியானாளின் கோவிலைப்போல வெள்ளியினால் சிறிய கோவில்களைச் செய்து, தொழிலாளிகளுக்கு மிகுந்த ஆதாயம் வருவித்துக்கொண்டிருந்தான்.
	<br /><br />
	25. இவர்களையும் இப்படிப்பட்ட தொழில்செய்கிற மற்ற வேலையாட்களையும் அவன் கூடிவரச்செய்து: மனுஷர்களே, இந்தத் தொழிலினால் நமக்கு நல்ல பிழைப்பு உண்டாயிருக்கிறதென்று அறிவீர்கள்.
	<br /><br />
	26. இப்படியிருக்க, கைகளினால் செய்யப்பட்ட தேவர்கள் தேவர்களல்லவென்று இந்தப் பவுல் என்பவன் சொல்லி, எபேசுவிலேமாத்திரமல்ல, கொஞ்சங்குறைய ஆசியா எங்கும் அநேக ஜனங்களுக்குப் போதித்து, அவர்களை வசப்படுத்திக்கொண்டான் என்று நீங்கள் கண்டும் கேட்டும் இருக்கிறீர்கள்.
	<br /><br />
	27. இதனால் நம்முடைய தொழில் அற்றுப்போகும்படியான அபாயம் நேரிட்டிருக்கிறதுமல்லாமல், மகா தேவியாகிய தியானாளுடைய கோவில் எண்ணமற்றுப் போகிறதற்கும், ஆசியா முழுமையும் பூச்சக்கரமும் சேவிக்கிற அவளுடைய மகத்துவம் அழிந்துபோகிறதற்கும் ஏதுவாயிருக்கிறது என்றான்.
	<br /><br />
	28. அவர்கள் இதைக் கேட்டு, கோபத்தால் நிறைந்து: எபேசியருடைய தியானாளே பெரியவள் என்று சத்தமிட்டார்கள்.
	<br /><br />
	29. பட்டணம் முழுவதும் கலகத்தினால் நிறைந்தது. பவுலுக்கு வழித்துணையாய் வந்த மக்கெதோனியராகிய காயுவையும் அரிஸ்தர்க்குவையும் அவர்கள் இழுத்துக்கொண்டு, ஒருமனப்பட்டு அரங்கசாலைக்குப் பாய்ந்தோடினார்கள்.
	<br /><br />
	30. பவுல் கூட்டத்துக்குள்ளே போக மனதாயிருந்தபோது, சீஷர்கள் அவனைப் போகவிடவில்லை.
	<br /><br />
	31. ஆசியாநாட்டுத் தலைவரில் அவனுக்குச் சிநேகிதராயிருந்த சிலரும் அவனிடத்திற்கு ஆள் அனுப்பி, அரங்கசாலைக்குள் போகவேண்டாம் என்று எச்சரித்தார்கள்.
	<br /><br />
	32. கூட்டத்தில் அமளியுண்டாகி, சிலர் இப்படியும் சிலர் அப்படியுமாகப் பேசினார்கள்; தாங்கள் கூடிவந்த காரணம் இன்னதென்று அநேகருக்குத் தெரியாதிருந்தது.
	<br /><br />
	33. அப்பொழுது யூதர்கள் அலெக்சந்தர் என்பவனை முன்னிற்கத் தள்ளுகையில், கூட்டத்திலே சிலர் அவனை முன்னே இழுத்துவிட்டார்கள். அலெக்சந்தர் கையமர்த்தி, ஜனங்களுக்கு உத்தரவுசொல்ல மனதாயிருந்தான்.
	<br /><br />
	34. அவன் யூதனென்று அவர்கள் அறிந்தபோது, எபேசியருடைய தியானாளே பெரியவள் என்று இரண்டுமணி நேரமளவும் எல்லாரும் ஏகமாய்ச் சத்தமிட்டுக்கொண்டிருந்தார்கள்.
	<br /><br />
	35. பட்டணத்துச் சம்பிரதியானவன் ஜனங்களை அமர்த்தி: எபேசியரே, எபேசியருடைய பட்டணம் மகா தேவியாகிய தியானாளுக்கும் வானத்திலிருந்து விழுந்த சிலைக்கும் கோவிற்பரிசாரகியாயிருக்கிறதை அறியாதவன் உண்டோ?
	<br /><br />
	36. இது எதிர்பேசப்படாத காரியமாகையால், நீங்கள் ஒன்றும் பதறிச்செய்யாமல் அமர்ந்திருக்கவேண்டும்.
	<br /><br />
	37. இந்த மனுஷரை இங்கே கொண்டுவந்தீர்கள்; இவர்கள் கோவிற்கொள்ளைக்காரருமல்ல, உங்கள் தேவியைத் தூஷிக்கிறவர்களுமல்ல.
	<br /><br />
	38. தெமேத்திரியுக்கும் அவனைச் சேர்ந்த தொழிலாளிகளுக்கும் ஒருவன்மேல் ஒருகாரியம் உண்டாயிருந்தால், நியாயம் விசாரிக்கிற நாட்களுண்டு, தேசாதிபதிகளும் இருக்கிறார்கள்; ஒருவர்பேரிலொருவர் வழக்காடிக்கொள்ளட்டும்.
	<br /><br />
	39. நீங்கள் வேறே யாதொரு காரியத்தைக்குறித்து விசாரிக்கவேண்டியதானால், அது நியாயசங்கத்திலே தீர்க்கப்படும்.
	<br /><br />
	40. இன்றைக்கு உண்டான கலகத்தைக்குறித்து நாம் உத்தரவுசொல்லுகிறதற்கு ஏதுவில்லாதபடியால், இந்தக் கலகத்தைக்குறித்து நாங்கள் விசாரிக்கப்படும்போது, குற்றவாளிகளாகிறதற்கு ஏதுவாயிருப்போமே என்று சொல்லி,
	<br /><br />
	41. பின்பு கூட்டத்தை அனுப்பிவிட்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts19