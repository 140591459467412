import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ephesians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Book of Ephesians Bible Tamil | எபேசியர் 4 </title>
	<meta
          name="description"
          content="Ephesians 4 | எபேசியர் 4 |
          Book of Ephesians | Tamil Bible Verse | Tamil Bible | எபேசியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. ஆதலால், கர்த்தர்நிமித்தம் கட்டுண்டவனாகிய நான் உங்களுக்குச் சொல்லுகிற புத்தியென்னவெனில், நீங்கள் அழைக்கப்பட்ட அழைப்புக்குப் பாத்திரவான்களாய் நடந்து,
	<br /><br />
	2. மிகுந்த மனத்தாழ்மையும் சாந்தமும் நீடிய பொறுமையும் உடையவர்களாய், அன்பினால் ஒருவரையொருவர் தாங்கி,
	<br /><br />
	3. சமாதானக்கட்டினால் ஆவியின் ஒருமையைக் காத்துக்கொள்வதற்கு ஜாக்கிரதையாயிருங்கள்.
	<br /><br />
	4. உங்களுக்கு உண்டான அழைப்பினாலே நீங்கள் ஒரே நம்பிக்கைக்கு அழைக்கப்பட்டதுபோல, ஒரே சரீரமும், ஒரே ஆவியும் உண்டு;
	<br /><br />
	5. ஒரே கர்த்தரும், ஒரே விசுவாசமும், ஒரே ஞானஸ்நானமும்,
	<br /><br />
	6. எல்லாருக்கும் ஒரே தேவனும் பிதாவும் உண்டு; அவர் எல்லார்மேலும், எல்லாரோடும், உங்கள் எல்லாருக்குள்ளும் இருக்கிறவர்.
	<br /><br />
	7. கிறிஸ்துவினுடைய ஈவின் அளவுக்குத்தக்கதாக நம்மில் அவனவனுக்குக் கிருபை அளிக்கப்பட்டிருக்கிறது.
	<br /><br />
	8. ஆதலால், அவர் உன்னதத்திற்கு ஏறி, சிறைப்பட்டவர்களைச் சிறையாக்கி, மனுஷர்களுக்கு வரங்களை அளித்தார் என்று சொல்லியிருக்கிறார்.
	<br /><br />
	9. ஏறினார் என்பதினாலே அவர் அதற்குமுன்னே பூமியின் தாழ்விடங்களில் இறங்கினார் என்று விளங்குகிறதல்லவா?
	<br /><br />
	10. இறங்கினவரே எல்லாவற்றையும் நிரப்பத்தக்கதாக, எல்லா வானங்களுக்கும் மேலாக உன்னதத்திற்கு ஏறினவருமாயிருக்கிறார்.
	<br /><br />
	11. மேலும் நாம் அனைவரும் தேவனுடைய குமாரனைப் பற்றும் விசுவாசத்திலும் அறிவிலும் ஒருமைப்பட்டவர்களாகி, கிறிஸ்துவினுடைய நிறைவான வளர்ச்சியின் அளவுக்குத்தக்க பூரணபுருஷராகும்வரைக்கும்,
	<br /><br />
	12. பரிசுத்தவான்கள் சீர்பொருந்தும்பொருட்டு, சுவிசேஷ ஊழியத்தின் வேலைக்காகவும், கிறிஸ்துவின் சரீரமாகிய சபையானது பக்திவிருத்தி அடைவதற்காகவும்,
	<br /><br />
	13. அவர், சிலரை அப்போஸ்தலராகவும், சிலரைத் தீர்க்கதரிசிகளாகவும், சிலரைச் சுவிசேஷகராகவும், சிலரை மேய்ப்பராகவும் போதகராகவும் ஏற்படுத்தினார்.
	<br /><br />
	14. நாம் இனிக் குழந்தைகளாயிராமல், மனுஷருடைய சூதும் வஞ்சிக்கிறதற்கேதுவான தந்திரமுமுள்ள போதகமாகிய பலவித காற்றினாலே அலைகளைப்போல அடிபட்டு அலைகிறவர்களாயிராமல்,
	<br /><br />
	15. அன்புடன் சத்தியத்தைக் கைக்கொண்டு, தலையாகிய கிறிஸ்துவுக்குள் எல்லாவற்றிலேயும், நாம் வளருகிறவர்களாயிருக்கும்படியாக அப்படிச் செய்தார்.
	<br /><br />
	16. அவராலே சரீரம் முழுதும், அதற்கு உதவியாயிருக்கிற சகல கணுக்களினாலும் இசைவாய்க் கூட்டி இணைக்கப்பட்டு, ஒவ்வொரு அவயவமும் தன்தன் அளவுக்குத்தக்கதாய்க் கிரியைசெய்கிறபடியே, அது அன்பினாலே தனக்குப் பக்திவிருத்தி உண்டாக்குகிறதற்கேதுவாகச் சரீரவளர்ச்சியை உண்டாக்குகிறது.
	<br /><br />
	17. ஆதலால், கர்த்தருக்குள் நான் உங்களுக்குச் சாட்சியாகச் சொல்லி எச்சரிக்கிறது என்னவெனில், மற்றப் புறஜாதிகள் தங்கள் வீணான சிந்தையிலே நடக்கிறதுபோல நீங்கள் இனி நடவாமலிருங்கள்.
	<br /><br />
	18. அவர்கள் புத்தியில் அந்தகாரப்பட்டு, தங்கள் இருதயகடினத்தினால் தங்களில் இருக்கும் அறியாமையினாலே தேவனுடைய ஜீவனுக்கு அந்நியராயிருந்து;
	<br /><br />
	19. உணர்வில்லாதவர்களாய், சகலவித அசுத்தங்களையும் ஆவலோடே நடப்பிக்கும்படி, தங்களைக் காமவிகாரத்திற்கு ஒப்புக்கொடுத்திருக்கிறார்கள்.
	<br /><br />
	20. நீங்களோ இவ்விதமாய்க் கிறிஸ்துவைக் கற்றுக்கொள்ளவில்லை.
	<br /><br />
	21. இயேசுவினிடத்திலுள்ள சத்தியத்தின்படியே, நீங்கள் அவரிடத்தில் கேட்டறிந்து, அவரால் போதிக்கப்பட்டீர்களே.
	<br /><br />
	22. அந்தப்படி, முந்தின நடக்கைக்குரிய மோசம்போக்கும் இச்சைகளாலே கெட்டுப்போகிற பழைய மனுஷனை நீங்கள் களைந்துபோட்டு,
	<br /><br />
	23. உங்கள் உள்ளத்திலே புதிதான ஆவியுள்ளவர்களாகி,
	<br /><br />
	24. மெய்யான நீதியிலும் பரிசுத்தத்திலும் தேவனுடைய சாயலாகச் சிருஷ்டிக்கப்பட்ட புதிய மனுஷனைத் தரித்துக்கொள்ளுங்கள்.
	<br /><br />
	25. அன்றியும், நாம் ஒருவருக்கொருவர் அவயவங்களாயிருக்கிறபடியால், பொய்யைக் களைந்து, அவனவன் பிறனுடனே மெய்யைப் பேசக்கடவன்.
	<br /><br />
	26. நீங்கள் கோபங்கொண்டாலும் பாவஞ்செய்யாதிருங்கள்; சூரியன் அஸ்தமிக்கிறதற்கு முன்னாக உங்கள் எரிச்சல் தணியக்கடவது;
	<br /><br />
	27. பிசாசுக்கு இடங்கொடாமலும் இருங்கள்.
	<br /><br />
	28. திருடுகிறவன் இனித் திருடாமல், குறைச்சலுள்ளவனுக்குக் கொடுக்கத்தக்கதாகத் தனக்கு உண்டாயிருக்கும்படி, தன் கைகளினால் நலமான வேலைசெய்து, பிரயாசப்படக்கடவன்.
	<br /><br />
	29. கெட்ட வார்த்தை ஒன்றும் உங்கள் வாயிலிருந்து புறப்படவேண்டாம்; பக்திவிருத்திக்கு ஏதுவான நல்ல வார்த்தை உண்டானால் அதையே கேட்கிறவர்களுக்குப் பிரயோஜனமுண்டாகும்படி பேசுங்கள்.
	<br /><br />
	30. அன்றியும், நீங்கள் மீட்கப்படும் நாளுக்கென்று முத்திரையாகப் பெற்ற தேவனுடைய பரிசுத்தஆவியைத் துக்கப்படுத்தாதிருங்கள்.
	<br /><br />
	31. சகலவிதமான கசப்பும், கோபமும், மூர்க்கமும், கூக்குரலும், தூஷணமும், மற்ற எந்தத் துர்க்குணமும் உங்களைவிட்டு நீங்கக்கடவது.
	<br /><br />
	32. ஒருவருக்கொருவர் தயவாயும் மனஉருக்கமாயும் இருந்து, கிறிஸ்துவுக்குள் தேவன் உங்களுக்கு மன்னித்ததுபோல, நீங்களும் ஒருவருக்கொருவர் மன்னியுங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ephesians4