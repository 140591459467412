import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 3 </title>
	<meta
          name="description"
          content="Ezekiel 3 | எசேக்கியேல்  3 |
          Ezekiel 3 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் என்னை நோக்கி: மனுபுத்திரனே, நீ காண்கிறதைப் புசி; இந்தச் சுருளை நீ புசித்து, இஸ்ரவேல் சந்ததியாரிடத்தில் போய் அவர்களோடே பேசு என்றார்.
	<br /><br />
	2. அப்படியே என் வாயைத் திறந்தேன்; அப்பொழுது அவர் அந்தச் சுருளை எனக்குப் புசிக்கக்கொடுத்து:
	<br /><br />
	3. மனுபுத்திரனே, நான் உனக்குக் கொடுக்கிற இந்தச் சுருளை நீ உன் வயிற்றிலே உட்கொண்டு, அதினால் உன் குடல்களை நிரப்புவாயாக என்றார்; அப்பொழுது நான் அதைப் புசித்தேன்; அது என் வாய்க்குத் தேனைப்போல் தித்திப்பாயிருந்தது.
	<br /><br />
	4. பின்பு அவர் என்னை நோக்கி: மனுபுத்திரனே, நீ போய், இஸ்ரவேல் சந்ததியாரிடத்தில் சேர்ந்து, என் வார்த்தைகளைக்கொண்டு அவர்களோடே பேசு.
	<br /><br />
	5. விளங்காத பேச்சும், கடினமான பாஷையுமுள்ள ஜனத்தண்டைக்கல்ல, இஸ்ரவேல் சந்ததியாரிடத்திற்கே நீ அனுப்பப்படுகிறாய்.
	<br /><br />
	6. விளங்காத பேச்சும், தாங்கள் சொல்லும் வார்த்தைகளை நீ அறியாத கடினமான பாஷையுமுள்ள அநேகமான ஜனங்களிடத்திற்கு நீ அனுப்பப்படவில்லை; நான் அவர்களிடத்திற்கு உன்னை அனுப்பினாலும், அவர்கள் உனக்குச் செவிகொடுப்பார்களோ?
	<br /><br />
	7. இஸ்ரவேல் வீட்டாரோவெனில், உனக்குச் செவிகொடுக்கமாட்டார்கள்; எனக்கே செவிகொடுக்கமாட்டோம் என்கிறார்களே; இஸ்ரவேல் வம்சத்தார் அனைவரும் கடினமான நெற்றியும் முரட்டாட்டமுள்ள இருதயமும் உள்ளவர்கள்.
	<br /><br />
	8. இதோ, உன் முகத்தை அவர்கள் முகத்துக்கு எதிராகவும், உன் நெற்றியை அவர்கள் நெற்றிக்கு எதிராகவும் கெட்டியாக்கினேன்.
	<br /><br />
	9. உன் நெற்றியை வச்சிரக்கல்லைப்போலாக்கினேன், கன்மலையைப்பார்க்கிலும் கெட்டியாக்கினேன்; அவர்கள் கலகமுள்ள வீட்டாரென்று நீ அவர்களுக்குப் பயப்படாமலும் அவர்கள் முகங்களுக்குக் கலங்காமலும் இரு என்றார்.
	<br /><br />
	10. பின்னும் அவர் என்னை நோக்கி: மனுபுத்திரனே, நான் உன்னுடனே சொல்லும் என் வார்த்தைகளையெல்லாம் நீ உன் செவிகளாலே கேட்டு, உன் இருதயத்தில் ஏற்றுக்கொண்டு,
	<br /><br />
	11. நீ போய், சிறைப்பட்ட உன் ஜனத்தின் புத்திரரிடத்திலே சேர்ந்து, அவர்கள் கேட்டாலும் கேளாவிட்டாலும் அவர்களோடே பேசி, கர்த்தராகிய ஆண்டவர் இன்னின்னதை உரைக்கிறார் என்று அவர்களோடே சொல் என்றார்.
	<br /><br />
	12. அப்பொழுது ஆவி, என்னை உயர எடுத்துக்கொண்டது; கர்த்தருடைய ஸ்தானத்திலிருந்து விளங்கிய அவருடைய மகிமைக்கு ஸ்தோத்திரம் உண்டாவதாக என்று எனக்குப் பின்னாகக் கூப்பிட்ட மகா சத்தத்தின் இரைச்சலைக் கேட்டேன்.
	<br /><br />
	13. ஒன்றோடொன்று இணைந்திருக்கிற ஜீவன்களுடைய செட்டைகளின் இரைச்சலையும், அதற்கெதிரே ஓடிய சக்கரங்களின் இரைச்சலையும், மகா சத்தத்தின் இரைச்சலையும் கேட்டேன்.
	<br /><br />
	14. ஆவி என்னை உயர எடுத்துக்கொண்டது; நான் என் ஆவியின் உக்கிரத்தினாலே மனங்கசந்துபோனேன்; ஆனாலும் கர்த்தருடைய கரம் என்மேல் பலமாக இருந்தது.
	<br /><br />
	15. கேபார் நதியண்டையிலே தெலாபீபிலே தாபரிக்கிற சிறைப்பட்டவர்களிடத்துக்கு நான் வந்து, அவர்கள் தாபரிக்கிற ஸ்தலத்திலே தாபரித்து, ஏழுநாள் அவர்கள் நடுவிலே பிரமித்தவனாய்த் தங்கினேன்.
	<br /><br />
	16. ஏழுநாள் முடிந்தபின்பு கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	17. மனுபுத்திரனே, உன்னை இஸ்ரவேல் வம்சத்தாருக்குக் காவலாளனாக வைத்தேன்; நீ என் வாயினாலே வார்த்தையைக்கேட்டு, என் நாமத்தினாலே அவர்களை எச்சரிப்பாயாக.
	<br /><br />
	18. சாகவே சாவாய் என்று நான் துன்மார்க்கனுக்குச் சொல்லுகையில், நீ துன்மார்க்கனைத் தன் துன்மார்க்கமான வழியில் இராதபடிக்கு எச்சரிக்கும்படியாகவும், அவனை உயிரோடே காக்கும்படியாகவும், அதை அவனுக்குச் சொல்லாமலும், நீ அவனை எச்சரிக்காமலும் இருந்தால், அந்தத் துன்மார்க்கன் தன் துன்மார்க்கத்திலே சாவான்; அவன் இரத்தப்பழியையோ உன் கையிலே கேட்பேன்.
	<br /><br />
	19. நீ துன்மார்க்கனை எச்சரித்தும், அவன் தன் துன்மார்க்கத்தையும் தன் ஆகாத வழியையும் விட்டுத் திரும்பாமற் போவானாகில், அவன் தன் துன்மார்க்கத்திலே சாவான்; நீயோவென்றால் உன் ஆத்துமாவைத் தப்புவிப்பாய்.
	<br /><br />
	20. அப்படியே, நீதிமான் தன் நீதியை விட்டுத் திரும்பி, நீதிகேடு செய்யும்போதும், நான் அவன்முன் இடறலை வைக்கும்போதும், அவன் சாவான்; நீ அவனை எச்சரிக்காதபடியினாலே அவன் தன் பாவத்திலே சாவான்; அவன் செய்த நீதிகள் நினைக்கப்படுவதில்லை; அவனுடைய இரத்தப்பழியையோ உன் கையிலே கேட்பேன்.
	<br /><br />
	21. நீதிமான் பாவஞ் செய்யாதபடிக்கு நீ நீதிமானை எச்சரித்தபின்பு அவன் பாவஞ்செய்யாவிட்டால், அவன் பிழைக்கவே பிழைப்பான்; அவன் எச்சரிக்கப்பட்டான்; நீயும் உன் ஆத்துமாவைத் தப்புவித்தாய் என்றார்.
	<br /><br />
	22. அவ்விடத்திலே கர்த்தருடைய கரம் என்மேல் அமர்ந்தது; அவர்: நீ எழுந்திருந்து பள்ளத்தாக்குக்குப் புறப்பட்டுப்போ, அங்கே உன்னுடனே பேசுவேன் என்றார்.
	<br /><br />
	23. அப்படியே நான் எழுந்திருந்து, பள்ளத்தாக்குக்குப் புறப்பட்டுப் போனேன்; இதோ, கேபார் நதியண்டையிலே நான் கண்ட மகிமைக்குச் சரியாக அங்கே கர்த்தருடைய மகிமை விளங்கினது; அப்பொழுது நான் முகங்குப்புற விழுந்தேன்.
	<br /><br />
	24. உடனே ஆவி எனக்குள்ளே புகுந்து, என்னைக் காலூன்றி நிற்கும்படி செய்தது, அப்பொழுது அவர் என்னுடனே பேசி: நீ போய், உன் வீட்டுக்குள்ளே உன்னை அடைத்துக்கொண்டிரு.
	<br /><br />
	25. இதோ, மனுபுத்திரனே, உன்மேல் கயிறுகளைப்போட்டு, அவைகளால் உன்னைக் கட்டப்போகிறார்கள்; ஆகையால் நீ அவர்களுக்குள்ளே போகவேண்டாம்.
	<br /><br />
	26. நான் உன் நாவை உன் மேல்வாயோடே ஒட்டிக்கொள்ளப்பண்ணுவேன்; நீ அவர்களைக் கடிந்துகொள்ளுகிற மனுஷனாயிராமல், ஊமையனாயிருப்பாய்; அவர்கள் கலகவீட்டார்.
	<br /><br />
	27. நான் உன்னோடே பேசும்போது, உன் வாயைத் திறப்பேன்; அப்பொழுது கர்த்தராகிய ஆண்டவர் இன்னின்னதை உரைத்தார் என்று அவர்களோடே சொல்வாய்; கேட்கிறவன் கேட்கட்டும், கேளாதவன் கேளாதிருக்கட்டும்; அவர்கள் கலகவீட்டார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial3