import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Galatians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | கலாத்தியருக்கு எழுதின நிருபம் 3 </title>
	<meta
          name="description"
          content="Galatians | கலாத்தியர் அதிகாரம் 3 | கலாத்தியருக்கு எழுதின நிருபம் |
          Book of Galatians | Chapter 3 | Tamil Bible Verse | Tamil Bible | புதிய ஏற்பாடு | TamilBible | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. புத்தியில்லாத கலாத்தியரே, நீங்கள் சத்தியத்திற்குக் கீழ்ப்படியாமற்போகத்தக்கதாக உங்களை மயக்கினவன் யார்? இயேசுகிறிஸ்து சிலுவையிலறையப்பட்டவராக உங்கள் கண்களுக்குமுன் பிரத்தியட்சமாய் உங்களுக்குள்ளே வெளிப்படுத்தப்பட்டிருந்தாரே.
	<br /><br />
	2. ஒன்றைமாத்திரம் உங்களிடத்தில் அறிய விரும்புகிறேன்; நியாயப்பிரமாணத்தின் கிரியைகளினாலேயோ, விசுவாசக் கேள்வியினாலேயோ, எதினாலே ஆவியைப் பெற்றீர்கள்?
	<br /><br />
	3. ஆவியினாலே ஆரம்பம்பண்ணின நீங்கள் இப்பொழுது மாம்சத்தினாலே முடிவுபெறப்போகிறீர்களோ? நீங்கள் இத்தனை புத்தியீனரா?
	<br /><br />
	4. இத்தனை பாடுகளையும் வீணாய்ப் பட்டீர்களோ? அவைகள் வீணாய்ப்போயிற்றே.
	<br /><br />
	5. அன்றியும் உங்களுக்கு ஆவியை அளித்து, உங்களுக்குள்ளே அற்புதங்களை நடப்பிக்கிறவர் அதை நியாயப்பிரமாணத்தின் கிரியைகளினாலேயோ, விசுவாசக் கேள்வியினாலேயோ, எதினாலே செய்கிறார்?
	<br /><br />
	6. அப்படியே ஆபிரகாம் தேவனை விசுவாசித்தான், அது அவனுக்கு நீதியாக எண்ணப்பட்டது.
	<br /><br />
	7. ஆகையால் விசுவாசமார்க்கத்தார்கள் எவர்களோ அவர்களே ஆபிரகாமின் பிள்ளைகளென்று அறிவீர்களாக.
	<br /><br />
	8. மேலும் தேவன் விசுவாசத்தினாலே புறஜாதிகளை நீதிமான்களாக்குகிறாரென்று வேதம் முன்னாகக் கண்டு: உனக்குள் சகல ஜாதிகளும் ஆசீர்வதிக்கப்படும் என்று ஆபிரகாமுக்குச் சுவிசேஷமாய் முன்னறிவித்தது.
	<br /><br />
	9. அந்தப்படி விசுவாசமார்க்கத்தார் விசுவாசமுள்ள ஆபிரகாமுடனே ஆசீர்வதிக்கப்படுகிறார்கள்.
	<br /><br />
	10. நியாயப்பிரமாணத்தின் கிரியைக்காரராகிய யாவரும் சாபத்திற்குட்பட்டிருக்கிறார்கள்; நியாயப்பிரமாண புஸ்தகத்தில் எழுதப்பட்டவைகளையெல்லாம் செய்யத்தக்கதாக அவைகளில் நிலைத்திராதவன் எவனோ அவன் சபிக்கப்பட்டவன் என்று எழுதியிருக்கிறதே.
	<br /><br />
	11. நியாயப்பிரமாணத்தினாலே ஒருவனும் தேவனிடத்தில் நீதிமானாகிறதில்லையென்பது வெளியரங்கமாயிருக்கிறது. ஏனெனில், விசுவாசத்தினாலே நீதிமான் பிழைப்பான் என்று எழுதியிருக்கிறதே.
	<br /><br />
	12. நியாயப்பிரமாணமோ விசுவாசத்திற்குரியதல்ல; அவைகளைச் செய்கிற மனுஷனே அவைகளால் பிழைப்பான்.
	<br /><br />
	13. மரத்திலே தூக்கப்பட்ட எவனும் சபிக்கப்பட்டவன் என்று எழுதியிருக்கிறபடி, கிறிஸ்து நமக்காகச் சாபமாகி, நியாயப்பிரமாணத்தின் சாபத்திற்கு நம்மை நீங்கலாக்கி மீட்டுக்கொண்டார்.
	<br /><br />
	14. ஆபிரகாமுக்கு உண்டான ஆசீர்வாதம் கிறிஸ்து இயேசுவினால் புறஜாதிகளுக்கு வரும்படியாகவும், ஆவியைக்குறித்துச் சொல்லப்பட்ட வாக்குத்தத்தத்தை நாம் விசுவாசத்தினாலே பெறும்படியாகவும் இப்படியாயிற்று.
	<br /><br />
	15. சகோதரரே, மனுஷர் முறைமையின்படி சொல்லுகிறேன்; மனுஷர்களுக்குள்ளே உறுதிபண்ணப்பட்ட உடன்படிக்கையை ஒருவனும் தள்ளுகிறதுமில்லை, அதினோடே ஒன்றையும் கூட்டுகிறதுமில்லை.
	<br /><br />
	16. ஆபிரகாமுக்கும் அவனுடைய சந்ததிக்கும் வாக்குத்தத்தங்கள் பண்ணப்பட்டன; சந்ததிகளுக்கு என்று அநேகரைக்குறித்துச் சொல்லாமல், உன் சந்ததிக்கு என்று ஒருவனைக்குறித்துச் சொல்லியிருக்கிறார், அந்தச் சந்ததி கிறிஸ்துவே.
	<br /><br />
	17. ஆதலால் நான் சொல்லுகிறதென்னவெனில், கிறிஸ்துவை முன்னிட்டுத் தேவனால் முன் உறுதிபண்ணப்பட்ட உடன்படிக்கையை நானூற்றுமுப்பது வருஷத்திற்குப்பின்பு உண்டான நியாயப்பிரமாணமானது தள்ளி, வாக்குத்தத்தத்தை வியர்த்தமாக்கமாட்டாது.
	<br /><br />
	18. அன்றியும், சுதந்தரமானது நியாயப்பிரமாணத்தினாலே உண்டானால் அது வாக்குத்தத்தத்தினாலே உண்டாயிராது; தேவன் அதை ஆபிரகாமுக்கு வாக்குத்தத்தத்தினாலே அருளிச்செய்தாரே.
	<br /><br />
	19. அப்படியானால், நியாயப்பிரமாணத்தின் நோக்கமென்ன? வாக்குத்தத்தத்தைப்பெற்ற சந்ததி வருமளவும் அது அக்கிரமங்களினிமித்தமாகக் கூட்டப்பட்டு, தேவதூதரைக்கொண்டு மத்தியஸ்தன் கையிலே கட்டளையிடப்பட்டது.
	<br /><br />
	20. மத்தியஸ்தன் ஒருவனுக்குரியவனல்ல, தேவனோ ஒருவர்.
	<br /><br />
	21. அப்படியானால், நியாயப்பிரமாணம் தேவனுடைய வாக்குத்தத்தங்களுக்கு விரோதமா? அல்லவே; உயிரைக் கொடுக்கத்தக்க நியாயப்பிரமாணம் அருளப்பட்டிருந்ததானால், நீதியானது நியாயப்பிரமாணத்தினால் உண்டாயிருக்குமே.
	<br /><br />
	22. அப்படியிராதபடியால், இயேசுகிறிஸ்துவைப் பற்றும் விசுவாசத்தினாலே பலிக்கிற வாக்குத்தத்தம் விசுவாசமுள்ளவர்களுக்கு அளிக்கப்படும்படி வேதம் எல்லாரையும் ஏகமாய்ப் பாவத்தின்கீழ் அடைத்துப்போட்டது.
	<br /><br />
	23. ஆதலால் விசுவாசம் வருகிறதற்குமுன்னே, வெளிப்படப்போகிற விசுவாசத்திற்கு ஏதுவாக நாம் அடைக்கப்பட்டவர்களாய் நியாயப்பிரமாணத்தின்கீழ் காவல்பண்ணப்பட்டிருந்தோம்.
	<br /><br />
	24. இவ்விதமாக, நாம் விசுவாசத்தினாலே நீதிமான்களாக்கப்படுவதற்கு நியாயப்பிரமாணம் நம்மைக் கிறிஸ்துவினிடத்தில் வழிநடத்துகிற உபாத்தியாய் இருந்தது.
	<br /><br />
	25. விசுவாசம் வந்தபின்பு நாம் உபாத்திக்குக் கீழானவர்களல்லவே.
	<br /><br />
	26. நீங்களெல்லாரும் கிறிஸ்து இயேசுவைப்பற்றும் விசுவாசத்தினால் தேவனுடைய புத்திரராயிருக்கிறீர்களே.
	<br /><br />
	27. ஏனெனில், உங்களில் கிறிஸ்துவுக்குள்ளாக ஞானஸ்நானம் பெற்றவர்கள் எத்தனைபேரோ, அத்தனைபேரும் கிறிஸ்துவைத் தரித்துக்கொண்டீர்களே.
	<br /><br />
	28. யூதனென்றும் கிரேக்கனென்றுமில்லை, அடிமையென்றும் சுயாதீனனென்றுமில்லை, ஆணென்றும் பெண்ணென்றுமில்லை; நீங்களெல்லாரும் கிறிஸ்து இயேசுவுக்குள் ஒன்றாயிருக்கிறீர்கள்.
	<br /><br />
	29. நீங்கள் கிறிஸ்துவினுடையவர்களானால், ஆபிரகாமின் சந்ததியாராயும், வாக்குத்தத்தத்தின்படியே சுதந்தரராயும் இருக்கிறீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Galatians3