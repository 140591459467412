import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 4 | Tamil Bible Verse | வெளிப்படுத்தல் -4  </title>
	<meta
          name="description"
          content="The Book of Revelation - 4 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* பரலோகத்தில் சிங்காசனத்தில் ஒருவர் அமர்ந்திருப்பதைக் கண்டேன். நான்கு ஜீவராசிகளும், "பரிசுத்தம், பரிசுத்தம், பரிசுத்தம்" என்று கூறுகின்றன. "எல்லாவற்றையும் நீயே படைத்தாய்" என்று பெரியோர்கள் கூறுகிறார்கள். *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இவைகளுக்குப்பின்பு, இதோ, பரலோகத்தில் திறக்கப்பட்டிருந்த ஒரு வாசலைக் கண்டேன். முன்னே எக்காளசத்தம்போல என்னுடனே பேச நான் கேட்டிருந்த சத்தமானது: இங்கே ஏறிவா, இவைகளுக்குப்பின்பு சம்பவிக்கவேண்டியவைகளை உனக்குக் காண்பிப்பேன் என்று விளம்பினது.
	<br /><br />
	2. உடனே ஆவிக்குள்ளானேன்; அப்பொழுது, இதோ, வானத்தில் ஒரு சிங்காசனம் வைக்கப்பட்டிருந்தது, அந்தச் சிங்காசனத்தின்மேல் ஒருவர் வீற்றிருந்தார்.
	<br /><br />
	3. வீற்றிருந்தவர், பார்வைக்கு வச்சிரக்கல்லுக்கும் பதுமராகத்துக்கும் ஒப்பாயிருந்தார்; அந்தச் சிங்காசனத்தைச்சுற்றி ஒரு வானவில்லிருந்தது; அது பார்வைக்கு மரகதம்போல் தோன்றிற்று.
	<br /><br />
	4. அந்தச் சிங்காசனத்தைச் சூழ இருபத்துநான்கு சிங்காசனங்களிருந்தன; இருபத்துநான்கு மூப்பர்கள் வெண்வஸ்திரந்தரித்து, தங்கள் சிரசுகளில் பொன்முடி சூடி, அந்தச் சிங்காசனங்களின்மேல் உட்கார்ந்திருக்கக்கண்டேன்.
	<br /><br />
	5. அந்தச் சிங்காசனத்திலிருந்து மின்னல்களும் இடிமுழக்கங்களும் சத்தங்களும் புறப்பட்டன; தேவனுடைய ஏழு ஆவிகளாகிய ஏழு அக்கினி தீபங்கள் சிங்காசனத்திற்கு முன்பாக எரிந்துகொண்டிருந்தன.
	<br /><br />
	6. அந்தச் சிங்காசனத்திற்கு முன்பாகப் பளிங்குக்கொப்பான கண்ணாடிக்கடலிருந்தது; அந்தச் சிங்காசனத்தின் மத்தியிலும் அந்தச் சிங்காசனத்தைச் சுற்றிலும் நான்கு ஜீவன்களிருந்தன, அவைகள் முன்புறத்திலும் பின்புறத்திலும் கண்களால் நிறைந்திருந்தன.
	<br /><br />
	7. முதலாம் ஜீவன் சிங்கத்திற்கொப்பாகவும், இரண்டாம் ஜீவன் காளைக்கொப்பாகவும், மூன்றாம் ஜீவன் மனுஷமுகம் போன்ற முகமுள்ளதாகவும், நான்காம் ஜீவன் பறக்கிற கழுகுக்கு ஒப்பாகவுமிருந்தன.
	<br /><br />
	8. அந்த நான்கு ஜீவன்களிலும் ஒவ்வொன்று அவ்வாறு சிறகுகளுள்ளவைகளும், சுற்றிலும் உள்ளேயும் கண்களால் நிறைந்தவைகளுமாயிருந்தன. அவைகள்: இருந்தவரும் இருக்கிறவரும் வருகிறவருமாகிய சர்வவல்லமையுள்ள தேவனாகிய கர்த்தர் பரிசுத்தர் பரிசுத்தர் பரிசுத்தர் என்று இரவும் பகலும் ஓய்வில்லாமல் சொல்லிக்கொண்டிருந்தன.
	<br /><br />
	9. மேலும், சிங்காசனத்தின்மேல் வீற்றிருந்து, சதாகாலங்களிலும் உயிரோடிருக்கிறவருக்கு அந்த ஜீவன்கள், மகிமையையும் கனத்தையும் ஸ்தோத்திரத்தையும் செலுத்தும்போது,
	<br /><br />
	10. இருபத்துநான்கு மூப்பர்களும் சிங்காசனத்தின்மேல் வீற்றிருக்கிறவருக்கு முன்பாக வணக்கமாய் விழுந்து, சதாகாலங்களிலும் உயிரோடிருக்கிறவரைத் தொழுதுகொண்டு, தங்கள் கிரீடங்களைச் சிங்காசனத்திற்குமுன்பாக வைத்து:
	<br /><br />
	11. கர்த்தாவே, தேவரீர், மகிமையையும் கனத்தையும் வல்லமையையும் பெற்றுக்கொள்ளுகிறதற்குப் பாத்திரராயிருக்கிறீர்; நீரே சகலத்தையும் சிருஷ்டித்தீர், உம்முடைய சித்தத்தினாலே அவைகள் உண்டாயிருக்கிறவைகளும் சிருஷ்டிக்கப்பட்டவைகளுமாயிருக்கிறது என்றார்கள்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
*  *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation4