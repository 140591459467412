import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 12 </title>
	<meta
          name="description"
          content="Ezekiel 12 | எசேக்கியேல்  12 |
          Ezekiel 12 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ கலகவீட்டாரின் நடுவிலே தங்கியிருக்கிறாய்; காணும்படிக்கு அவர்களுக்குக் கண்கள் இருந்தாலும் காணாமற்போகிறார்கள்; கேட்கும்படிக்கு அவர்களுக்குக் காதுகள் இருந்தாலும் கேளாமற்போகிறார்கள்; அவர்கள் கலகவீட்டார்.
	<br /><br />
	3. இப்போதும் மனுபுத்திரனே, நீ பரதேசம் போகும்படி பிரயாண சாமான்களை ஆயத்தப்படுத்தி, பகற்காலத்திலே அவர்கள் கண்களுக்கு முன்பாகப் பிரயாணப்படு; உன் ஸ்தலத்தைவிட்டு வேறே ஸ்தலத்துக்கு அவர்களுடைய கண்களுக்கு முன்பாகப் புறப்பட்டுப்போ; அவர்கள் கலகவீட்டாரானபோதிலும் ஒருவேளை சிந்தித்து உணருவார்கள்.
	<br /><br />
	4. சிறையிருப்புக்குப் போகிறவனைப்போல் உன் சாமான்களை நீ பகற்காலத்திலே அவர்கள் கண்களுக்கு முன்பாக வெளியே வைத்து, நீ சாயங்காலத்திலே அவர்கள் கண்களுக்கு முன்பாகச் சிறையிருப்புக்குப் போகிறவனைப்போல் புறப்படுவாயாக.
	<br /><br />
	5. அவர்களுடைய கண்களுக்கு முன்பாக நீ சுவரிலே துவாரமிட்டு, அதின் வழியாய் அவைகளை வெளியே கொண்டுபோவாயாக.
	<br /><br />
	6. அவர்களுடைய கண்களுக்கு முன்பாக அவைகளை உன் தோளின்மேல் எடுத்து, மாலைமயங்கும் வேளையிலே வெளியே கொண்டுபோவாயாக; நீ தேசத்தைப் பாராதபடி உன் முகத்தை மூடிக்கொள்; இஸ்ரவேல் வம்சத்தாருக்கு உன்னை அடையாளமாக்கினேன் என்றார்.
	<br /><br />
	7. எனக்குக் கட்டளையிட்டபடியே நான் செய்தேன்; சிறைப்பட்டுப்போகும்போது சாமான்களைக் கொண்டுபோவதுபோல என் சாமான்களைப் பகற்காலத்தில் வெளியே வைத்தேன்; சாயங்காலத்திலோ கையினால் சுவரிலே துவாரமிட்டு, மாலை மயங்கும் வேளையிலே அவைகளை வெளியே கொண்டுபோய், அவர்கள் கண்களுக்கு முன்பாக அவைகளைத் தோளின்மேல் எடுத்துக்கொண்டுபோனேன்.
	<br /><br />
	8. விடியற்காலத்திலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	9. மனுபுத்திரனே, கலகவீட்டாராகிய இஸ்ரவேல் வம்சத்தார் உன்னைப் பார்த்து: நீ செய்கிறது என்னவென்று உன்னைக் கேட்டார்கள் அல்லவா?
	<br /><br />
	10. இது எருசலேமில் இருக்கிற அதிபதியின்மேலும் அதின் நடுவில் இருக்கிற இஸ்ரவேல் வம்சத்தார் அனைவரின்மேலும் சுமரும் பாரம் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று அவர்களிடத்தில் சொல்லு.
	<br /><br />
	11. நீ அவர்களை நோக்கி: நான் உங்களுக்கு அடையாளமாயிருக்கிறேன்; நான் செய்வது எப்படியோ, அப்படியே அவர்களுக்கும் செய்யப்படும்; சிறைப்பட்டுப் பரதேசம் போவார்கள்.
	<br /><br />
	12. அவர்கள் நடுவில் இருக்கிற அதிபதி மாலைமயங்கும்போது தோளின்மேல் சுமைசுமந்து புறப்படுவான்; வெளியே சுமைகொண்டுபோகச் சுவரிலே துவாரமிடுவார்கள்; கண்ணினாலே அவன் தன் தேசத்தைக் காணாதபடி தன் முகத்தை மூடிக்கொள்வான்.
	<br /><br />
	13. நான் என் வலையை அவன்மேல் வீசுவேன், அவன் என் கண்ணியிலே பிடிபடுவான்; அவனைக் கல்தேயர் தேசமாகிய பாபிலோனுக்குக் கொண்டுபோவேன்; அங்கே அவன் சாவான்; ஆகிலும் அதைக் காணமாட்டான்.
	<br /><br />
	14. அவனுக்கு உதவியாக அவனைச் சுற்றிலும் இருக்கிற யாவரையும் அவனுடைய எல்லா இராணுவங்களையும் நான் சகல திசைகளிலும் தூற்றி, அவர்கள் பின்னே பட்டயத்தை உருவுவேன்.
	<br /><br />
	15. அப்படி நான் அவர்களை ஜாதிகளுக்குள்ளே தூற்றி, அவர்களை தேசங்களிலே சிதறடிக்கும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	16. ஆனாலும் தாங்கள் போய்ச்சேரும் ஜாதிகளுக்குள்ளே தங்கள் அருவருப்புகளையெல்லாம் விவரிக்கும்படி, நான் அவர்களில் கொஞ்சம்பேரைப் பஞ்சத்துக்கும் பட்டயத்துக்கும் கொள்ளைநோய்க்கும் தப்பி மீந்திருக்கப்பண்ணுவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள் என்று சொல் என்றார்.
	<br /><br />
	17. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	18. மனுபுத்திரனே, நீ உன் அப்பத்தை நடுக்கத்தோடே புசித்து, உன் தண்ணீரைத் தத்தளிப்போடும் விசாரத்தோடும் குடித்து,
	<br /><br />
	19. தேசத்திலுள்ள ஜனங்களை நோக்கி: இஸ்ரவேல் தேசத்திலுள்ள எருசலேமின் குடிகளைக்குறித்துக் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், தங்கள் அப்பத்தை விசாரத்தோடே புசித்து, தங்கள் தண்ணீரைத் திகிலோடே குடிப்பார்கள்; அவர்களுடைய தேசத்துக் குடிகளுடைய கொடுமைகளினிமித்தம் அதிலுள்ளதெல்லாம் அழிய, அது பாழாகும்.
	<br /><br />
	20. குடியேறியிருக்கிற பட்டணங்கள் வனாந்தரங்களாகி, தேசம் பாழாய்ப்போகும்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள் என்று சொல் என்றார்.
	<br /><br />
	21. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	22. மனுபுத்திரனே, நாட்கள் நீடிக்கும், தரிசனம் எல்லாம் அவமாகும் என்று இஸ்ரவேல் தேசத்திலே வழங்கும் பழமொழி என்ன?
	<br /><br />
	23. ஆகையால் நீ அவர்களை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், அவர்கள் இனி இஸ்ரவேலிலே இந்தப் பழமொழியைச் சொல்லிவராதபடிக்கு நான் அதை ஒழியப்பண்ணுவேன்; நாட்களும் எல்லாத் தரிசனத்தின் பொருளும் சமீபித்து வந்தன என்று அவர்களோடே சொல்லு.
	<br /><br />
	24. இஸ்ரவேல் வம்சத்தாரின் நடுவில் இனிச் சகல கள்ளத்தரிசனமும் முகஸ்துதியான குறிசொல்லுதலும் இராமற்போகும்.
	<br /><br />
	25. நான் கர்த்தர், நான் சொல்லுவேன், நான் சொல்லும் வார்த்தை நிறைவேறும்; இனித் தாமதியாது; கலகவீட்டாரே, உங்கள் நாட்களிலே நான் வார்த்தையைச் சொல்லுவேன், அதை நிறைவேறவும் பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	26. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	27. மனுபுத்திரனே, இதோ, இஸ்ரவேல் வம்சத்தார்: இவன் காண்கிற தரிசனம் நிறைவேற அநேகநாள் செல்லும்; தூரமாயிருக்கிற காலங்களைக்குறித்து இவன் தீர்க்கதரிசனம் சொல்லுகிறான் என்கிறார்கள்.
	<br /><br />
	28. ஆகையால் என் வார்த்தைகளில் ஒன்றாகிலும் இனித் தாமதிப்பதில்லையென்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்; நான் சொன்ன வார்த்தை நிறைவேறும் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று அவர்களோடே சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial12