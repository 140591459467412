import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial29 = () => {
  const verseNumber = 29;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 29 </title>
	<meta
          name="description"
          content="Ezekiel 29 | எசேக்கியேல்  29 |
          Ezekiel 29 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பத்தாம் வருஷம் பத்தாம் மாதம் பன்னிரண்டாந்தேதியிலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ எகிப்தின் ராஜாவாகிய பார்வோனுக்கு எதிராக உன் முகத்தைத் திருப்பி, அவனுக்கும் எகிப்து முழுதுக்கும் விரோதமாய்த் தீர்க்கதரிசனம் உரைத்து, சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	3. கர்த்தராகிய ஆண்டவர் சொல்லுகிறார், எகிப்தின் ராஜாவாகிய பார்வோனே, நீ உன் நதிகளின் நடுவிலே படுத்துக்கொண்டு: என் நதி என்னுடையது, நான் அதை எனக்காக உண்டுபண்ணினேன் என்று சொல்லுகிற பெரிய முதலையே, இதோ, நான் உனக்கு விரோதமாய் வந்து,
	<br /><br />
	4. உன் வாயிலே துறடுகளை மாட்டி, உன் நதிகளின் மச்சங்களை உன் செதிள்களில் ஒட்டிக்கொள்ளும்படி செய்து, உன்னை உன் நதிகளின் நடுவிலிருந்து தூக்கிவிடுவேன்; உன் நதிகளின் மச்சங்களெல்லாம் உன் செதில்களில் ஒட்டிக்கொண்டிருக்கும்.
	<br /><br />
	5. உன்னையும் உன் நதிகளின் எல்லா மச்சங்களையும் வனாந்தரத்திலே போட்டுவிடுவேன்; வெட்டவெளியிலே விழுவாய்; நீ சேர்த்துக்கொள்ளப்படுவதில்லை; உன்னைப் பூமியின் மிருகங்களுக்கும் ஆகாயத்தின் பறவைகளுக்கும் இரையாகக் கொடுப்பேன்.
	<br /><br />
	6. அப்பொழுது எகிப்துதேசத்தின் குடிகளெல்லாரும் நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்; அவர்கள் இஸ்ரவேல் வம்சத்தாருக்கு நாணற்கோலாயிருந்தார்களே.
	<br /><br />
	7. அவர்கள் உன்னைக் கையிலே பிடிக்கும்போது, நீ ஒடிந்துபோய், அவர்கள் விலாவையெல்லாம் பிளப்பாய்; அவர்கள் உன்மேல் சாயும்போது, நீ முறிந்து, அவர்கள் இடுப்பு முழுவதையும் மரத்துப்போகப்பண்ணுவாய்.
	<br /><br />
	8. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் உன்மேல் பட்டயத்தை வரப்பண்ணி, உன்னில் மனுஷரையும் மிருகங்களையும் சங்காரம்பண்ணுவேன்.
	<br /><br />
	9. எகிப்து தேசம் பாழும் வனாந்தரமுமாகும்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்: நதி என்னுடையது, நான் அதை உண்டாக்கினேன் என்று சொன்னானே.
	<br /><br />
	10. ஆகையால், இதோ, நான் உனக்கும் உன் நதிகளுக்கும் விரோதமாக வந்து, மிக்தோல்முதல் எத்தியோப்பியாவின் எல்லையிலுள்ள செவெனேவரைக்கும் எகிப்துதேசத்தை அவாந்தரமும் பாழுமான வனாந்தரங்களாக்குவேன்.
	<br /><br />
	11. மனுஷனுடைய கால் அதைக் கடப்பதுமில்லை, மிருகஜீவனுடைய கால் அதை மிதிப்பதுமில்லை; அது நாற்பது வருஷம் குடியற்றிருக்கும்.
	<br /><br />
	12. எகிப்துதேசத்தைப் பாழாய்ப்போன தேசங்களின் நடுவிலே பாழாய்ப்போகப்பண்ணுவேன்; அதின் பட்டணங்கள் அவாந்தரமாக்கப்பட்ட பட்டணங்களின் நடுவிலே நாற்பது வருஷம் பாழாய்க் கிடக்கும்; நான் எகிப்தியரை ஜாதிகளுக்குள்ளே சிதறடித்து, அவர்களைத் தேசங்களுக்குள்ளே தூற்றிவிடுவேன்.
	<br /><br />
	13. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நாற்பதுவருஷம் முடியும்போது, நான் எகிப்தியரை அவர்கள் சிதறுண்டிருக்கிற ஜனங்களிடத்திலிருந்து சேர்த்துக்கொண்டு,
	<br /><br />
	14. எகிப்தியரின் சிறையிருப்பைத் திருப்பி, அவர்களை அவர்களுடைய ஜநநதேசமாகிய பத்ரோஸ் தேசத்திலே திரும்பிவரப்பண்ணுவேன்; அங்கே அவர்கள் அற்ப ராஜ்யமாயிருப்பார்கள்.
	<br /><br />
	15. அது இனி ஜாதிகளின்மேல் தன்னை உயர்த்தாமல், மற்ற ராஜ்யங்களிலும் அற்பமானதாயிருக்கும்; அவர்கள் இனி ஜாதிகளை ஆளாதபடிக்கு அவர்களைக் குறுகிப்போகப்பண்ணுவேன்.
	<br /><br />
	16. அவர்களின் பிறகே போய், அவர்களை நோக்கிக்கொண்டிருக்கிறதினால் இஸ்ரவேல் வம்சத்தார் எனக்குத் தங்கள் அக்கிரமத்தை நினைப்பூட்டாதபடிக்கு, இனி அவர்கள் இவர்களுடைய நம்பிக்கையாயிராமற்போவார்கள்; அப்பொழுது நான் கர்த்தராகிய ஆண்டவர் என்று அறிந்துகொள்வார்கள் என்கிறார் என்று சொல் என்றார்.
	<br /><br />
	17. இருபத்தேழாம் வருஷம் முதலாம் மாதம் முதலாந்தேதியிலே, கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	18. மனுபுத்திரனே, பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் தீருவின் முன்னே தன் சேனையினிடத்தில் கடும் ஊழியம் வாங்கினான்; ஒவ்வொரு தலையும் மொட்டையாயிற்று; ஒவ்வொரு தோள்பட்டையின் தோலும் உரிந்துபோயிற்று; ஆனாலும் அவன் தீருவுக்கு விரோதமாகச் செய்த ஊழியத்தினாலே அவனுக்காவது அவன் சேனைக்காவது கூலி கிடைக்கவில்லை.
	<br /><br />
	19. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாருக்கு எகிப்துதேசத்தைக் கொடுக்கிறேன்; அவன் அதின் ஏராளமான ஜனத்தைச் சிறைபிடித்து அதின் ஆஸ்தியைச் சூறையாடி, அதின் கொள்ளைப்பொருளை எடுத்துக்கொள்வான்; இது அவனுடைய சேனைக்குக் கூலியாயிருக்கும்.
	<br /><br />
	20. அவன் அதிலே செய்த வேலைக்கு எகிப்துதேசத்தை நான் அவனுக்குக் கூலியாகக் கொடுத்தேன்; எனக்காக அதைச் செய்தார்களென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	21. அந்நாளிலே நான் இஸ்ரவேல் வம்சத்தாரின் கொம்பை முளைக்கப்பண்ணி, அவர்கள் நடுவிலே தாராளமாய்ப் பேசும் வாயை உனக்குக் கட்டளையிடுவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial29