import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 19 </title>
	<meta
          name="description"
          content="Isaiah 19 | ஏசாயா 19 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எகிப்தின் பாரம். இதோ, கர்த்தர் வேகமான மேகத்தின்மேல் ஏறி எகிப்துக்கு வருவார்; அப்பொழுது எகிப்தின் விக்கிரகங்கள் அவருக்கு முன்பாகக் குலுங்கும், எகிப்தின் இருதயம் தனக்குள்ளே கரைந்துபோகும்.
	<br /><br />
	2. சகோதரனோடே சகோதரனும், சிநேகிதனோடே சிநேகிதனும், பட்டணத்தோடே பட்டணமும், ராஜ்யத்தோடே ராஜ்யமும் யுத்தம்பண்ணும்படியாய், எகிப்தியரை எகிப்தியரோடே போர் கலக்கப்பண்ணுவேன்.
	<br /><br />
	3. அதினால் எகிப்தியருடைய ஆவி அவர்களுக்குள் சோர்ந்துபோகும்; அவர்கள் ஆலோசனையை முழுகிப்போகப் பண்ணுவேன்; அப்பொழுது விக்கிரகங்களையும், மந்திரவாதிகளையும், சன்னதக்காரர்களையும், குறிசொல்லுகிறவர்களையும் தேடுவார்கள்.
	<br /><br />
	4. நான் எகிப்தியரைக் கடினமான அதிபதியின் கையில் ஒப்பிப்பேன்; கடூரமான ராஜா அவர்களை ஆளுவான் என்று சேனைகளின் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	5. அப்பொழுது கடலின் தண்ணீர்கள் குறைந்து, நதியும் வற்றி வறண்டுபோம்.
	<br /><br />
	6. ஆறுகளைத் திருப்பிவிடுவார்கள்; அரணிப்பான அகழிகள் வெறுமையாகி வறண்டுபோம்; கொறுக்கையும் நாணலும் வாடும்.
	<br /><br />
	7. நதியோரத்திலும் நதிமுகத்திலும் இருக்கிற மடலுள்ள செடிகளும், நதியருகே விதைக்கப்பட்ட யாவும் உலர்ந்துபோம்; அது பறக்கடிக்கப்பட்டு இல்லாதேபோம்.
	<br /><br />
	8. மீன்பிடிக்கிறவர்கள் பெருமூச்சுவிடுவார்கள், நதியிலே தூண்டில்போடுகிற யாவரும் துக்கிப்பார்கள்; தண்ணீர்களின்மேல் வலைகளை வீசுகிறவர்கள் சலித்துப்போவார்கள்.
	<br /><br />
	9. மெல்லிய சணலைப் பக்குவப்படுத்துகிறவர்களும், சல்லாக்களை நெய்கிறவர்களும் நாணுவார்கள்.
	<br /><br />
	10. மீன் வளர்க்கிற குளங்களைக் கூலிக்கு அணைக்கட்டுகிற அனைவருடைய அணைக்கட்டுகளும் உடைந்துபோம்.
	<br /><br />
	11. சோவான்பட்டணத்தின் பிரபுக்களானவர்கள் மூடர்கள்; பார்வோனுடைய ஞானமுள்ள ஆலோசனைக்காரரின் ஆலோசனை மதியீனமாயிற்று: நான் ஞானிகளின் புத்திரன், நான் பூர்வ ராஜாக்களின் குமாரன் என்று பார்வோனுடனே எப்படிச் சொல்லுகிறீர்கள்?
	<br /><br />
	12. அவர்கள் எங்கே? உன் ஞானிகள் எங்கே? சேனைகளின் கர்த்தர் எகிப்தைக்குறித்துப் பண்ணின யோசனையை அவர்கள் உனக்குத் தெரிவிக்கட்டும்; அல்லது தாங்களே அறிந்துகொள்ளட்டும்.
	<br /><br />
	13. சோவான் பிரபுக்கள் மூடரானார்கள்; நோப்பின் பிரபுக்கள் மோசம்போனார்கள்; எகிப்தையும் அதின் கோத்திரத்தலைவரையும் வழிதப்பப்பண்ணுகிறார்கள்.
	<br /><br />
	14. கர்த்தர் அதின் நடுவில் தாறுமாறுகளின் ஆவியை வரப்பண்ணினார்; ஆனதுகொண்டு வெறியன் வாந்திபண்ணி, தள்ளாடித் திரிகிறதுபோல, அவர்கள் எகிப்தை அதின் எல்லாச் செய்கையிலும் தள்ளாடித் திரியப்பண்ணுகிறார்கள்.
	<br /><br />
	15. எகிப்தில் தலையாகிலும் வாலாகிலும் கிளையாகிலும் நாணலாகிலும் செய்யும் வேலை ஒன்றுமிராது.
	<br /><br />
	16. அக்காலத்திலே எகிப்தியர் பெண்டுகளைப்போலிருந்து, சேனைகளின் கர்த்தர் தங்கள்மேல் அசைக்கும் கையசைவினாலே அஞ்சி நடுங்குவார்கள்.
	<br /><br />
	17. சேனைகளின் கர்த்தர் அவர்களுக்கு விரோதமாக நிர்ணயித்துக்கொண்ட ஆலோசனையினிமித்தம் யூதாவின் தேசம் எகிப்தியருக்குப் பயங்கரமாயிருக்கும்; தனக்குள் அதை நினைக்கிறவனெவனும் திடுக்கிடுவான்.
	<br /><br />
	18. அக்காலத்திலே எகிப்துதேசத்திலிருக்கும் ஐந்து பட்டணங்கள் கானான் பாஷையைப் பேசி, சேனைகளின் கர்த்தரை முன்னிட்டு ஆணையிடும்; அவைகளில் ஒன்று நிர்மூலமான பட்டணம் என்னப்படும்.
	<br /><br />
	19. அக்காலத்திலே எகிப்துதேசத்தின் நடுவிலே கர்த்தருக்கு ஒரு பலிபீடமும், அதின் எல்லையருகே கர்த்தருக்கு ஒரு ஸ்தம்பமும் உண்டாயிருக்கும்.
	<br /><br />
	20. அது எகிப்து தேசத்திலே சேனைகளின் கர்த்தருக்கு அடையாளமும் சாட்சியுமாயிருக்கும்; ஒடுக்குகிறவர்களினிமித்தம் அவர்கள் கர்த்தரை நோக்கிக் கூப்பிடுவார்கள்; அப்பொழுது அவர்களுக்கு ஒரு இரட்சகனையும், ஒரு பெலவானையும் அனுப்பி அவர்களை விடுவிப்பார்.
	<br /><br />
	21. அப்பொழுது கர்த்தர் எகிப்தியருக்கு அறியப்படுவார்; எகிப்தியர் கர்த்தரை அக்காலத்திலே அறிந்து, அவருக்குப் பலிகளோடும் காணிக்கைகளோடும் ஆராதனைசெய்து, கர்த்தருக்குப் பொருத்தனைகளைப்பண்ணி அவைகளைச் செலுத்துவார்கள்.
	<br /><br />
	22. கர்த்தர் எகிப்தியரை வாதையினால் அடிப்பார்; அடித்துக் குணமாக்குவார்; அவர்கள் கர்த்தரிடத்தில் மனந்திரும்புவார்கள்; அப்பொழுது அவர்கள் விண்ணப்பத்தைக் கேட்டு, அவர்களைக் குணமாக்குவார்.
	<br /><br />
	23. அக்காலத்திலே எகிப்திலிருந்து அசீரியாவுக்குப் போகிற பெரும்பாதை உண்டாயிருக்கும்; அசீரியர் எகிப்துக்கும், எகிப்தியர் அசீரியாவுக்கும் வந்து, எகிப்தியர் அசீரியரோடுங்கூட ஆராதனை செய்வார்கள்.
	<br /><br />
	24. அக்காலத்திலே இஸ்ரவேல் எகிப்தோடும் அசீரியாவோடும் மூன்றாவதாகப் பூமியின் நடுவில் ஆசீர்வாதமாயிருக்கும்.
	<br /><br />
	25. அவர்களைக்குறித்துச் சேனைகளின் கர்த்தர்: எகிப்தியராகிய என் ஜனமும், அசீரியராகிய என் கரத்தின் கிரியையும், இஸ்ரவேலராகிய என் சுதந்தரமும் ஆசீர்வதிக்கப்பட்டது என்று சொல்லி, அவர்களை ஆசீர்வதிப்பார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah19