import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title> Faith Hall of Fame Hebrews | Tamil Bible Verse  | எபிரெயர் - 11 </title>
	<meta
          name="description"
          content="The Faith Hall of Fame Hebrews Chapter-11 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-11 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* நம்பிக்கையால் வெற்றி *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. விசுவாசமானது நம்பப்படுகிறவைகளின் உறுதியும், காணப்படாதவைகளின் நிச்சயமுமாயிருக்கிறது.
	<br /><br />
	2. அதினாலே முன்னோர்கள் நற்சாட்சிபெற்றார்கள்.
	<br /><br />
	3. விசுவாசத்தினாலே நாம் உலகங்கள் தேவனுடைய வார்த்தையினால் உண்டாக்கப்பட்டதென்றும், இவ்விதமாய், காணப்படுகிறவைகள் தோன்றப்படுகிறவைகளால் உண்டாகவில்லையென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	4. விசுவாசத்தினாலே ஆபேல் காயீனுடைய பலியிலும் மேன்மையான பலியை தேவனுக்குச் செலுத்தினான்; அதினாலே அவன் நீதிமானென்று சாட்சி பெற்றான்; அவனுடைய காணிக்கைகளைக் குறித்துத் தேவனே சாட்சி கொடுத்தார்; அவன் மரித்தும் இன்னும் பேசுகிறான்.
	<br /><br />
	5. விசுவாசத்தினாலே ஏனோக்கு மரணத்தைக் காணாதபடிக்கு எடுத்துக்கொள்ளப்பட்டான்; தேவன் அவனை எடுத்துக்கொண்டபடியினாலே, அவன் காணப்படாமற்போனான்; அவன் தேவனுக்குப் பிரியமானவனென்று அவன் எடுத்துக்கொள்ளப்படுவதற்கு முன்னமே சாட்சிபெற்றான்.
	<br /><br />
	6. விசுவாசமில்லாமல் தேவனுக்குப் பிரியமாயிருப்பது கூடாதகாரியம்; ஏனென்றால், தேவனிடத்தில் சேருகிறவன் அவர் உண்டென்றும், அவர் தம்மைத் தேடுகிறவர்களுக்குப் பலன் அளிக்கிறவரென்றும் விசுவாசிக்கவேண்டும்.
	<br /><br />
	7. விசுவாசத்தினாலே நோவா தற்காலத்திலே காணாதவைகளைக்குறித்துத் தேவஎச்சரிப்புப் பெற்று, பயபக்தியுள்ளவனாகி, தன் குடும்பத்தை இரட்சிப்பதற்குப் பேழையை உண்டுபண்ணினான்; அதினாலே அவன் உலகம் ஆக்கினைக்குள்ளானதென்று தீர்த்து, விசுவாசத்தினாலுண்டாகும் நீதிக்குச் சுதந்தரவாளியானான்.
	<br /><br />
	8. விசுவாசத்தினாலே ஆபிரகாம் தான் சுதந்தரமாகப் பெறப்போகிற இடத்திற்குப் போகும்படி அழைக்கப்பட்டபோது, கீழ்ப்படிந்து, தான் போகும் இடம் இன்னதென்று அறியாமல் புறப்பட்டுப்போனான்.
	<br /><br />
	9. விசுவாசத்தினாலே அவன் வாக்குத்தத்தம்பண்ணப்பட்ட தேசத்திலே பரதேசியைப்போலச் சஞ்சரித்து, அந்த வாக்குத்தத்தத்திற்கு உடன் சுதந்தரராகிய ஈசாக்கோடும் யாக்கோபோடும் கூடாரங்களிலே குடியிருந்தான்;
	<br /><br />
	10. ஏனெனில், தேவன் தாமே கட்டி உண்டாக்கின அஸ்திபாரங்களுள்ள நகரத்துக்கு அவன் காத்திருந்தான்.
	<br /><br />
	11. விசுவாசத்தினாலே சாராளும் வாக்குத்தத்தம்பண்ணினவர் உண்மையுள்ளவரென்றெண்ணி, கர்ப்பந்தரிக்கப் பெலனடைந்து, வயதுசென்றவளாயிருந்தும் பிள்ளைபெற்றாள்.
	<br /><br />
	12. ஆனபடியால், சரீரஞ்செத்தவனென்று எண்ணத்தகும் ஒருவனாலே, வானத்திலுள்ள பெருக்கமான நட்சத்திரங்களைப்போலவும் கடற்கரையிலுள்ள எண்ணிறந்த மணலைப்போலவும், மிகுந்த ஜனங்கள் பிறந்தார்கள்.
	<br /><br />
	13. இவர்களெல்லாரும், வாக்குத்தத்தம்பண்ணப்பட்டவைகளை அடையாமல், தூரத்திலே அவைகளைக் கண்டு, நம்பி அணைத்துக்கொண்டு, பூமியின்மேல் தங்களை அந்நியரும் பரதேசிகளும் என்று அறிக்கையிட்டு, விசுவாசத்தோடே மரித்தார்கள்.
	<br /><br />
	14. இப்படி அறிக்கையிடுகிறவர்கள் சுயதேசத்தை நாடிப்போகிறோம் என்று தெரியப்படுத்துகிறார்கள்.
	<br /><br />
	15. தாங்கள் விட்டுவந்த தேசத்தை நினைத்தார்களானால், அதற்குத் திரும்பிப்போவதற்கு அவர்களுக்குச் சமயங்கிடைத்திருக்குமே.
	<br /><br />
	16. அதையல்ல, அதிலும் மேன்மையான பரமதேசத்தையே விரும்பினார்கள்; ஆகையால் தேவன் அவர்களுடைய தேவனென்னப்பட வெட்கப்படுகிறதில்லை; அவர்களுக்கு ஒரு நகரத்தை ஆயத்தம்பண்ணினாரே.
	<br /><br />
	17. மேலும் விசுவாசத்தினாலே ஆபிரகாம் தான் சோதிக்கப்பட்டபோது ஈசாக்கைப் பலியாக ஒப்புக்கொடுத்தான்.
	<br /><br />
	18. ஈசாக்கினிடத்தில் உன் சந்ததி விளங்கும் என்று அவனோடே சொல்லப்பட்டிருந்ததே; இப்படிப்பட்ட வாக்குத்தத்தங்களைப் பெற்றவன், மரித்தோரிலிருந்தும் எழுப்பத் தேவன் வல்லவராயிருக்கிறாரென்றெண்ணி,
	<br /><br />
	19. தனக்கு ஒரேபேறானவனையே பலியாக ஒப்புக்கொடுத்தான்; மரித்தோரிலிருந்து அவனை பாவனையாகத் திரும்பவும் பெற்றுக்கொண்டான்.
	<br /><br />
	20. விசுவாசத்தினாலே ஈசாக்கு வருங்காரியங்களைக்குறித்து யாக்கோபையும் ஏசாவையும் ஆசீர்வதித்தான்.
	<br /><br />
	21. விசுவாசத்தினாலே யாக்கோபு தன் மரணகாலத்தில் யோசேப்பினுடைய குமாரர் இருவரையும் ஆசீர்வதித்து, தன் கோலின் முனையிலே சாய்ந்து தொழுதுகொண்டான்.
	<br /><br />
	22. விசுவாசத்தினாலே யோசேப்பு இஸ்ரவேல் புத்திரர் எகிப்து தேசத்தைவிட்டுப் புறப்படுவார்களென்பதைப்பற்றித் தன் அந்தியகாலத்தில் பேசி, தன் எலும்புகளைக்குறித்துக் கட்டளைகொடுத்தான்.
	<br /><br />
	23. மோசே பிறந்தபோது அவனுடைய தாய்தகப்பன்மார் அவனை அழகுள்ள பிள்ளையென்று கண்டு, விசுவாசத்தினாலே, ராஜாவினுடைய கட்டளைக்குப் பயப்படாமல் அவனை மூன்றுமாதம் ஒளித்துவைத்தார்கள்.
	<br /><br />
	24. விசுவாசத்தினாலே மோசே தான் பெரியவனானபோது பார்வோனுடைய குமாரத்தியின் மகன் என்னப்படுவதை வெறுத்து,
	<br /><br />
	25. அநித்தியமான பாவசந்தோஷங்களை அநுபவிப்பதைப்பார்க்கிலும் தேவனுடைய ஜனங்களோடே துன்பத்தை அநுபவிப்பதையே தெரிந்துகொண்டு,
	<br /><br />
	26. இனிவரும் பலன்மேல் நோக்கமாயிருந்து, எகிப்திலுள்ள பொக்கிஷங்களிலும் கிறிஸ்துவினிமித்தம் வரும் நிந்தையை அதிக பாக்கியமென்று எண்ணினான்.
	<br /><br />
	27. விசுவாசத்தினாலே அவன் அதரிசனமானவரைத் தரிசிக்கிறதுபோல உறுதியாயிருந்து, ராஜாவின் கோபத்துக்குப் பயப்படாமல் எகிப்தைவிட்டுப் போனான்.
	<br /><br />
	28. விசுவாசத்தினாலே, முதற்பேறானவைகளைச் சங்கரிக்கிறவன் இஸ்ரவேலரைத் தொடாதபடிக்கு, அவன் பஸ்காவையும் இரத்தம் பூசுதலாகிய நியமத்தையும் ஆசரித்தான்.
	<br /><br />
	29. விசுவாசத்தினாலே அவர்கள் சிவந்த சமுத்திரத்தை உலர்ந்த தரையைக் கடந்துபோவதுபோலக் கடந்துபோனார்கள்; எகிப்தியர் அப்படிச் செய்யத்துணிந்து அமிழ்ந்துபோனார்கள்.
	<br /><br />
	30. விசுவாசத்தினாலே எரிகோ பட்டணத்தின் மதில்கள் ஏழுநாள் சுற்றிவரப்பட்டு விழுந்தது.
	<br /><br />
	31. விசுவாசத்தினாலே ராகாப் என்னும் வேசி வேவுகாரரைச் சமாதானத்தோடே ஏற்றுக்கொண்டு, கீழ்ப்படியாதவர்களோடேகூடச் சேதமாகாதிருந்தாள்.
	<br /><br />
	32. பின்னும் நான் என்ன சொல்லுவேன்? கிதியோன், பாராக், சிம்சோன், யெப்தா, தாவீது, சாமுவேல் என்பவர்களையும், தீர்க்கதரிசிகளையுங்குறித்து நான் விவரஞ்சொல்லவேண்டுமானால் காலம் போதாது.
	<br /><br />
	33. விசுவாசத்தினாலே அவர்கள் ராஜ்யங்களை ஜெயித்தார்கள், நீதியை நடப்பித்தார்கள், வாக்குத்தத்தங்களைப் பெற்றார்கள், சிங்கங்களின் வாய்களை அடைத்தார்கள்,
	<br /><br />
	34. அக்கினியின் உக்கிரத்தை அவித்தார்கள், பட்டயக்கருக்குக்குத் தப்பினார்கள், பலவீனத்தில் பலன்கொண்டார்கள்; யுத்தத்தில் வல்லவர்களானார்கள், அந்நியருடைய சேனைகளை முறியடித்தார்கள்.
	<br /><br />
	35. ஸ்திரீகள் சாகக்கொடுத்த தங்களுடையவர்களை உயிரோடெழுந்திருக்கப் பெற்றார்கள்; வேறுசிலர் மேன்மையான உயிர்த்தெழுதலை அடையும்படிக்கு, விடுதலைபெறச் சம்மதியாமல், வாதிக்கப்பட்டார்கள்;
	<br /><br />
	36. வேறு சிலர் நிந்தைகளையும் அடிகளையும் கட்டுகளையும் காவலையும் அநுபவித்தார்கள்;
	<br /><br />
	37. கல்லெறியுண்டார்கள், வாளால் அறுப்புண்டார்கள், பரீட்சைபார்க்கப்பட்டார்கள், பட்டயத்தினாலே வெட்டப்பட்டு மரித்தார்கள், செம்மறியாட்டுத் தோல்களையும் வெள்ளாட்டுத் தோல்களையும் போர்த்துக்கொண்டு திரிந்து, குறைவையும் உபத்திரவத்தையும் துன்பத்தையும் அநுபவித்தார்கள்;
	<br /><br />
	38. உலகம் அவர்களுக்குப் பாத்திரமாயிருக்கவில்லை; அவர்கள் வனாந்தரங்களிலேயும் மலைகளிலேயும் குகைகளிலேயும் பூமியின் வெடிப்புகளிலேயும் சிதறுண்டு அலைந்தார்கள்.
	<br /><br />
	39. இவர்களெல்லாரும் விசுவாசத்தினாலே நற்சாட்சிபெற்றும், வாக்குத்தத்தம்பண்ணப்பட்டதை அடையாமற்போனார்கள்.
	<br /><br />
	40. அவர்கள் நம்மையல்லாமல் பூரணராகாதபடிக்கு விசேஷித்த நன்மையானதொன்றைத் தேவன் நமக்கென்று முன்னதாக நியமித்திருந்தார்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* எபிரேயர் 11 நம்பிக்கையால் வெற்றி பெற்ற ஆண்களையும் பெண்களையும் பற்றிய அற்புதமான படத்தை வரைகிறது; கடவுளை நம்பி, அவருடைய வாக்குறுதிகளைப் பின்பற்றிய மக்கள், தங்கள் வாழ்நாளில் அவை நிறைவேறுவதைப் பலர் பார்க்கவில்லை. இந்த அத்தியாயம் விசுவாசத்தை வரையறுக்கிறது மற்றும் அது கடவுளைப் பிரியப்படுத்துகிறது என்பதைக் காட்டுகிறது. கடவுளின் வாக்குறுதிகளைப் பற்றிக்கொண்டு, இயேசுவின் வருகையை எதிர்நோக்கி நாம் இந்த வாழ்க்கையை வழிநடத்தும்போது இது நமக்கு ஒரு சிறந்த நினைவூட்டலாகும். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews11