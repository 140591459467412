import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah45 = () => {
  const verseNumber = 45;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 45 </title>
	<meta
          name="description"
          content="Jeremiah 45 | எரேமியா 45 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோசியாவின் குமாரனாகிய யோயாக்கீம் என்னும் யூதா ராஜாவின் நாலாம் வருஷத்திலே நேரியாவின் குமாரனாகிய பாருக் இந்த வசனங்களை எரேமியாவின் வாய் சொல்ல ஒரு புஸ்தகத்தில் எழுதுகையில், எரேமியா தீர்க்கதரிசி அவனிடத்தில் பேசி,
	<br /><br />
	2. பாருக்கே, உன்னைக்குறித்து இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால்,
	<br /><br />
	3. நீ: இப்பொழுது எனக்கு ஐயோ! கர்த்தர் என் நோவைச் சஞ்சலத்தால் வர்த்திக்கப்பண்ணினார், என் தவிப்பினால் இளைத்தேன், இளைப்பாறுதலைக் காணாதேபோனேன் என்று சொன்னாய் என்கிறார்.
	<br /><br />
	4. இதோ, நான் கட்டினதையே நான் இடிக்கிறேன்; நான் நாட்டினதையே நான் பிடுங்குகிறேன்; இந்த முழுத்தேசத்துக்கும் இப்படியே நடக்கும்.
	<br /><br />
	5. நீ உனக்குப் பெரிய காரியங்களைத் தேடுகிறாயோ? தேடாதே; இதோ, மாம்சமான யாவர்மேலும் தீங்கை வரப்பண்ணுகிறேன் என்று கர்த்தர் சொல்லுகிறார்; ஆனாலும், நீ போகும் சகல ஸ்தலங்களிலும் உன் பிராணனை உனக்குக் கிடைக்கும் கொள்ளைப்பொருளாகத் தருகிறேன் என்று கர்த்தர் சொல்லுகிறார் என்று அவனுடனே சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah45