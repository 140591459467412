import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Philemon1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> Philemon in Tamil Bible Verse | பிலேமோன் அதிகாரம்-1 </title>
	<meta
          name="description"
          content="The Book of Philemon Chapter 1 | பிலேமோன் | அதிகாரம்-1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
		<br />
<h2>
* ஒனேசிமஸுக்காக பவுலின் வேண்டுகோள் *
			</h2>
<br />
			<br />

      {/* Vesrse of Tamilbible */}

	  <p>
	1. கிறிஸ்து இயேசுவினிமித்தம் கட்டப்பட்டவனாயிருக்கிற பவுலும், சகோதரனாகிய தீமோத்தேயும், எங்களுக்குப் பிரியமுள்ளவனும் உடன்வேலையாளுமாயிருக்கிற பிலேமோனுக்கும்,
	<br /><br />
	2. பிரியமுள்ள அப்பியாளுக்கும், எங்கள் உடன் போர்ச்சேவகனாகிய அர்க்கிப்புவுக்கும், உம்முடைய வீட்டிலே கூடிவருகிற சபைக்கும் எழுதுகிறதாவது:
	<br /><br />
	3. நம்முடைய பிதாவாகிய தேவனாலும், கர்த்தராகிய இயேசுகிறிஸ்துவினாலும், உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	4. கர்த்தராகிய இயேசுவினிடத்திலும், எல்லாப் பரிசுத்தவான்களிடத்திலுமுள்ள உம்முடைய அன்பையும் உம்முடைய விசுவாசத்தையும் நான் கேள்விப்பட்டு,
	<br /><br />
	5. என் ஜெபங்களில் உம்மை நினைத்து, எப்பொழுதும் என் தேவனுக்கு ஸ்தோத்திரஞ்செய்து,
	<br /><br />
	6. உங்களிலுள்ள சகல நன்மைகளும் தெரியப்படுகிறதினாலே உம்முடைய விசுவாசத்தின் அந்நியோந்நியம் கிறிஸ்துஇயேசுவுக்காகப் பயன்படவேண்டுமென்று வேண்டுதல்செய்கிறேன்.
	<br /><br />
	7. சகோதரனே, பரிசுத்தவான்களுடைய உள்ளங்கள் உம்மாலே இளைப்பாறினபடியால், உம்முடைய அன்பினாலே மிகுந்த சந்தோஷமும் ஆறுதலும் அடைந்திருக்கிறோம்.
	<br /><br />
	8. ஆகையால், பவுலாகிய நான் முதிர்வயதுள்ளவனாகவும், இயேசுகிறிஸ்துவினிமித்தம் இப்பொழுது கட்டப்பட்டவனாகவும் இருக்கிறபடியால்,
	<br /><br />
	9. நீர் செய்யத்தக்கதை உமக்குக் கட்டளையிடும்படிக்குக் கிறிஸ்துவுக்குள் நான் துணியத்தக்கவனாயிருந்தாலும், அப்படிச் செய்யாமல், அன்பினிமித்தம் மன்றாடுகிறேன்.
	<br /><br />
	10. என்னவென்றால், கட்டப்பட்டிருக்கையில் நான் பெற்ற என் மகனாகிய ஒநேசிமுக்காக உம்மை மன்றாடுகிறேன்.
	<br /><br />
	11. முன்னே அவன் உமக்குப் பிரயோஜனமில்லாதவன், இப்பொழுதோ உமக்கும் எனக்கும் பிரயோஜனமுள்ளவன்.
	<br /><br />
	12. அவனை நான் உம்மிடத்திற்கு அனுப்புகிறேன்; என் உள்ளம்போலிருக்கிற அவனை நீர் ஏற்றுக்கொள்ளும்.
	<br /><br />
	13. சுவிசேஷத்தினிமித்தம் கட்டப்பட்டிருக்கிற எனக்கு ஊழியஞ்செய்யும்படி உமக்குப் பதிலாக அவனை என்னிடத்தில் வைத்துக்கொள்ளவேண்டுமென்றிருந்தேன்.
	<br /><br />
	14. ஆனாலும் நீர் செய்யும் நன்மையைக் கட்டாயத்தினாலல்ல, மனப்பூர்வமாய்ச் செய்யத்தக்கதாக, நான் உம்முடைய சம்மதியில்லாமல் ஒன்றும் செய்ய எனக்கு மனதில்லை.
	<br /><br />
	15. அவன் என்றென்றைக்கும் உம்முடையவனாயிருக்கும்படிக்கும், இனிமேல் அவன் அடிமையானவனாகவல்ல, அடிமையானவனுக்கு மேலானவனாகவும் பிரியமுள்ள சகோதரனாகவுமிருக்கும்படிக்கும் கொஞ்சக்காலம் உம்மைவிட்டுப் பிரிந்துபோனானாக்கும்.
	<br /><br />
	16. எனக்கு அவன் பிரியமான சகோதரனானால், உமக்குச் சரீரத்தின்படியேயும் கர்த்தருக்குள்ளும் எவ்வளவு பிரியமுள்ளவனாயிருக்கவேண்டும்!
	<br /><br />
	17. ஆதலால், நீர் என்னை உம்மோடே ஐக்கியமானவனென்று எண்ணினால், என்னை ஏற்றுக்கொள்வதுபோல அவனையும் ஏற்றுக்கொள்ளும்.
	<br /><br />
	18. அவன் உமக்கு யாதொரு அநியாயஞ்செய்ததும், உம்மிடத்தில் கடன்பட்டதும் உண்டானால், அதை என் கணக்கிலே வைத்துக்கொள்ளும்.
	<br /><br />
	19. பவுலாகிய நான் இதை என் சொந்தக்கையாலே எழுதினேன், நான் அதைச் செலுத்தித் தீர்ப்பேன். நீர் உம்மைத்தாமே எனக்குக் கடனாகச் செலுத்தவேண்டுமென்று நான் உமக்குச் சொல்லவேண்டியதில்லையே.
	<br /><br />
	20. ஆம், சகோதரனே, கர்த்தருக்குள் உம்மாலே எனக்குப் பிரயோஜனமுண்டாகட்டும்; கர்த்தருக்குள் என் உள்ளத்தை இளைப்பாறப்பண்ணும்.
	<br /><br />
	21. நான் சொல்லுகிறதிலும் அதிகமாய்ச் செய்வீரென்று அறிந்து, இதற்கு நீர் இணங்குவீரென்று நிச்சயித்து, உமக்கு எழுதியிருக்கிறேன்.
	<br /><br />
	22. மேலும், உங்கள் விண்ணப்பங்களினாலே நான் உங்களுக்கு அநுக்கிரகிக்கப்படுவேனென்று நம்பியிருக்கிறபடியால், நான் இருக்கும்படிக்கு ஓரிடத்தை எனக்காக ஆயத்தம்பண்ணும்.
	<br /><br />
	23. கிறிஸ்து இயேசுவினிமித்தம் என்னோடேகூடக் காவலில் வைக்கப்பட்டிருக்கிற எப்பாப்பிராவும்,
	<br /><br />
	24. என் உடன்வேலையாட்களாகிய மாற்குவும், அரிஸ்தர்க்கும், தேமாவும், லூக்காவும் உமக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	25. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் கிருபை உங்கள் ஆவியுடனேகூட இருப்பதாக. ஆமென்.
</p>
<p>
</p>



      {/* End of verse */} 

	  <br />
		<br />
<h4>
<p>
* பிலேமோன் 1, அப்போஸ்தலனாகிய பவுல், பிலேமோனுக்கும், அவனது வீட்டாருக்கும், அவனது வீட்டில் சந்தித்த தேவாலயத்திற்கும் நன்றி செலுத்தும் ஜெபத்துடன் துவங்குகிறது. பின்னர் அவர் ஏன் ஒனேசிமஸை தன்னிடம் திருப்பி அனுப்புகிறார் என்பதை விளக்கி பிலேமோனுக்கு கடிதம் எழுதுகிறார், மேலும் அவரை மன்னிக்கும்படி கேட்கிறார். அடிமையை கிறிஸ்துவில் அன்பான சகோதரனாக ஏற்றுக்கொள்ளும்படி அவர் அழைக்கிறார், அவர் சங்கிலியில் இருந்தபோது இந்த மனிதன் எவ்வாறு அப்போஸ்தலனுக்கு புத்துணர்ச்சி அளித்து சேவை செய்தார் என்பதை விளக்குகிறார். பிலேமோனுக்கு எடுத்துச் செல்லப்பட்ட எதற்கும் திருப்பித் தருவதாகக் கூறி, அவர் வருகை தருவதாக நம்பியதால், அவருக்கு விருந்தினர் அறையைத் தயார் செய்யும்படி பால் கேட்கிறார்.
</p>
<p>
	இந்த புத்தகம் மன்னிப்பு பற்றிய வலுவான செய்தியை அளிக்கிறது, குறிப்பாக விசுவாசிகளுக்கு இடையே. அன்றைய கலாச்சார நடைமுறைகளைப் பொருட்படுத்தாமல் அனைத்து மக்களையும் சமமாகப் பார்ப்பதற்கான நல்ல நுண்ணறிவை இது வழங்குகிறது. *
</p>

			</h4>
<br />
      </div>
    </div>
    </>
  );
}

export default Philemon1