import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> Jesus is Greater than Moses Hebrews Tamil Bible | எபிரெயர் 3 </title>
	<meta
          name="description"
          content="Jesus is Greater than Moses Hebrews Chapter-3 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h3>
* இயேசு மோசேயை விட பெரியவர் *
			</h3>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. இப்படியிருக்க, பரம அழைப்புக்குப் பங்குள்ளவர்களாகிய பரிசுத்த சகோதரரே, நாம் அறிக்கைபண்ணுகிற அப்போஸ்தலரும் பிரதான ஆசாரியருமாயிருக்கிற கிறிஸ்து இயேசுவைக் கவனித்துப்பாருங்கள்;
	<br /><br />
	2. மோசே தேவனுடைய வீட்டில் எங்கும் உண்மையுள்ளவனாயிருந்ததுபோல, இவரும் தம்மை ஏற்படுத்தினவருக்கு உண்மையுள்ளவராயிருக்கிறார்.
	<br /><br />
	3. வீட்டை உண்டுபண்ணினவன் வீட்டைப்பார்க்கிலும் அதிக கனத்திற்குரியவனாயிருக்கிறான்; அதுபோல மோசேயைப்பார்க்கிலும் இவர் அதிக மகிமைக்குப் பாத்திரராயிருக்கிறார்.
	<br /><br />
	4. ஏனெனில், எந்த வீடும் ஒருவனால் உண்டாக்கப்படும்; எல்லாவற்றையும் உண்டுபண்ணினவர் தேவன்.
	<br /><br />
	5. சொல்லப்படப்போகிற காரியங்களுக்குச் சாட்சியாக, மோசே பணிவிடைக்காரனாய், அவருடைய வீட்டில் எங்கும் உண்மையுள்ளவனாயிருந்தான்.
	<br /><br />
	6. கிறிஸ்துவோ அவருடைய வீட்டிற்கு மேற்பட்டவரான குமாரனாக உண்மையுள்ளவராயிருக்கிறார்; நம்பிக்கையினாலே உண்டாகும் தைரியத்தையும் மேன்மைபாராட்டலையும் முடிவுபரியந்தம் உறுதியாய்ப் பற்றிக்கொண்டிருப்போமாகில், நாமே அவருடைய வீடாயிருப்போம்.
	<br /><br />
	7. ஆகையால், பரிசுத்தஆவியானவர் சொல்லுகிறபடியே: இன்று அவருடைய சத்தத்தைக் கேட்பீர்களாகில்,
	<br /><br />
	8. வனாந்தரத்திலே கோபமூட்டினபோதும், சோதனைநாளிலும் நடந்ததுபோல, உங்கள் இருதயங்களைக் கடினப்படுத்தாதிருங்கள்.
	<br /><br />
	9. அங்கே உங்கள் பிதாக்கள் என்னைச் சோதித்து, என்னைப் பரீட்சைபார்த்து, நாற்பது வருஷகாலம் என் கிரியைகளைக் கண்டார்கள்.
	<br /><br />
	10. ஆதலால், நான் அந்தச் சந்ததியை அரோசித்து, அவர்கள் எப்பொழுதும் வழுவிப்போகிற இருதயமுள்ள ஜனமென்றும், என்னுடைய வழிகளை அறியாதவர்களென்றும் சொல்லி;
	<br /><br />
	11. என்னுடைய இளைப்பாறுதலில் அவர்கள் பிரவேசிப்பதில்லையென்று என்னுடைய கோபத்திலே ஆணையிட்டேன் என்றார்.
	<br /><br />
	12. சகோதரரே, ஜீவனுள்ள தேவனைவிட்டு விலகுவதற்கேதுவான அவிசுவாசமுள்ள பொல்லாத இருதயம் உங்களில் ஒருவனுக்குள்ளும் இராதபடிக்கு நீங்கள் எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	13. உங்களில் ஒருவனாகிலும் பாவத்தின் வஞ்சனையினாலே கடினப்பட்டுப் போகாதபடிக்கு, இன்று என்னப்படுமளவும் நாடோறும் ஒருவருக்கொருவர் புத்திசொல்லுங்கள்.
	<br /><br />
	14. நாம் ஆரம்பத்திலே கொண்ட நம்பிக்கையை முடிவுபரியந்தம் உறுதியாய்ப் பற்றிக்கொண்டிருப்போமாகில், கிறிஸ்துவினிடத்தில் பங்குள்ளவர்களாயிருப்போம்.
	<br /><br />
	15. இன்று அவருடைய சத்தத்தைக் கேட்பீர்களாகில், கோபமூட்டுதலில் நடந்ததுபோல உங்கள் இருதயங்களைக் கடினப்படுத்தாதிருங்கள் என்று சொல்லியிருக்கிறதே.
	<br /><br />
	16. கேட்டவர்களில் கோபமூட்டினவர்கள் யார்? மோசேயினால் எகிப்திலிருந்து புறப்பட்ட யாவரும் அப்படிச் செய்தார்களல்லவா?
	<br /><br />
	17. மேலும், அவர் நாற்பது வருஷமாய் யாரை அரோசித்தார்? பாவஞ்செய்தவர்களையல்லவா? அவர்களுடைய சவங்கள் வனாந்தரத்தில் விழுந்துபோயிற்றே.
	<br /><br />
	18. பின்னும், என்னுடைய இளைப்பாறுதலில் பிரவேசிப்பதில்லையென்று அவர் யாரைக்குறித்து ஆணையிட்டார்? கீழ்ப்படியாதவர்களைக்குறித்தல்லவா?
	<br /><br />
	19. ஆதலால், அவிசுவாசத்தினாலே அவர்கள் அதில் பிரவேசிக்கக்கூடாமற்போனார்களென்று பார்க்கிறோம்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* இயேசு நம்முடைய உண்மையுள்ள பிரதான ஆசாரியராக இருக்கிறார், அவர் ஒரு மகனாக தேவனுடைய வீட்டில் நித்தியமாக வாசம் செய்கிறார் என்ற உண்மையை வெளிப்படுத்துகிறது. இது மோசேக்கும் நியாயப்பிரமாணத்தின் உடன்படிக்கைக்கும் அவருடைய மேன்மையைக் காட்டுகிறது. இந்த அத்தியாயம் இதயத்தின் கடினத்தன்மை மற்றும் பாவம் மற்றும் நம்பிக்கையின்மையால் ஈர்க்கப்படுவதற்கு எதிரான எச்சரிக்கையாகவும் செயல்படுகிறது. நாம் அனுதினமும் ஒருவரையொருவர் ஊக்கப்படுத்தி, கிறிஸ்துவில் நம்பிக்கையுடன் இறுதிவரை பலமாக நிற்க வேண்டும். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews3