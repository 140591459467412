import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Titus3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Instructions for Godly Living | Titus in Tamil Bible Verse | தீத்து 3</title>
	<meta
          name="description"
          content="Instructions for Godly Living | Titus Chapter-3 | தீத்துக்கு எழுதின நிருபம்  | அதிகாரம்-3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
<h3>
* தெய்வீக வாழ்க்கைக்கான வழிமுறைகள் *
			</h3>
<br />

      {/* Vesrse of Tamilbible */}

	  <p>
	1. துரைத்தனங்களுக்கும் அதிகாரங்களுக்கும் கீழ்ப்படிந்து அடங்கியிருக்கவும், சகலவிதமான நற்கிரியைகளையும் செய்ய ஆயத்தமாயிருக்கவும்,
	<br /><br />
	2. ஒருவனையும் தூஷியாமலும், சண்டை பண்ணாமலும், பொறுமையுள்ளவர்களாய் எல்லா மனுஷருக்கும் சாந்தகுணத்தைக் காண்பிக்கவும் அவர்களுக்கு நினைப்பூட்டு.
	<br /><br />
	3. ஏனெனில், முற்காலத்திலே நாமும் புத்தியீனரும், கீழ்ப்படியாதவர்களும், வழிதப்பி நடக்கிறவர்களும், பலவித இச்சைகளுக்கும் இன்பங்களுக்கும் அடிமைப்பட்டவர்களும், துர்க்குணத்தோடும் பொறாமையோடும் ஜீவனம்பண்ணுகிறவர்களும், பகைக்கப்படத்தக்கவர்களும், ஒருவரையொருவர் பகைக்கிறவர்களுமாயிருந்தோம்.
	<br /><br />
	4. நம்முடைய இரட்சகராகிய தேவனுடைய தயையும் மனுஷர்மேலுள்ள அன்பும் பிரசன்னமானபோது,
	<br /><br />
	5. நாம் செய்த நீதியின் கிரியைகளினிமித்தம் அவர் நம்மை இரட்சியாமல், தமது இரக்கத்தின்படியே, மறுஜென்ம முழுக்கினாலும், பரிசுத்த ஆவியினுடைய புதிதாக்குதலினாலும் நம்மை இரட்சித்தார்.
	<br /><br />
	6. தமது கிருபையினாலே நாம் நீதிமான்களாக்கப்பட்டு, நித்திய ஜீவனுண்டாகும் என்கிற நம்பிக்கையின்படி சுதந்தரராகத்தக்கதாக,
	<br /><br />
	7. அவர் நமது இரட்சகராகிய இயேசுகிறிஸ்து மூலமாய், அந்தப் பரிசுத்தஆவியை நம்மேல் சம்பூரணமாய்ப் பொழிந்தருளினார்.
	<br /><br />
	8. இந்த வார்த்தை உண்மையுள்ளது; தேவனிடத்தில் விசுவாசமானவர்கள் நற்கிரியைகளைச் செய்ய ஜாக்கிரதையாயிருக்கும்படி நீ இவைகளைக்குறித்துத் திட்டமாய்ப் போதிக்கவேண்டுமென்று விரும்புகிறேன்; இவைகளே நன்மையும் மனுஷருக்குப் பிரயோஜனமுமானவைகள்.
	<br /><br />
	9. புத்தியீனமான தர்க்கங்களையும், வம்சவரலாறுகளையும், சண்டைகளையும், நியாயப்பிரமாணத்தைக்குறித்து உண்டாகிற வாக்குவாதங்களையும் விட்டு விலகு; அவைகள் அப்பிரயோஜனமும் வீணுமாயிருக்கும்.
	<br /><br />
	10. வேதப்புரட்டனாயிருக்கிற ஒருவனுக்கு நீ இரண்டொருதரம் புத்தி சொன்னபின்பு அவனைவிட்டு விலகு.
	<br /><br />
	11. அப்படிப்பட்டவன் நிலைதவறி, தன்னிலேதானே ஆக்கினைத்தீர்ப்புடையவனாய்ப் பாவஞ்செய்கிறவனென்று அறிந்திருக்கிறாயே.
	<br /><br />
	12. நான் அர்த்தெமாவையாவது தீகிக்குவையாவது உன்னிடத்தில் அனுப்பும்போது நீ நிக்கொப்போலிக்கு என்னிடத்தில் வர ஜாக்கிரதைப்படு; மாரிகாலத்திலே அங்கே தங்கும்படி தீர்மானித்திருக்கிறேன்.
	<br /><br />
	13. நியாயசாஸ்திரியாகிய சேனாவுக்கும் அப்பொல்லோவுக்கும் ஒரு குறைவுமில்லாதபடிக்கு அவர்களை ஜாக்கிரதையாய் விசாரித்து வழிவிட்டனுப்பு.
	<br /><br />
	14. நம்முடையவர்களும் கனியற்றவர்களாயிராதபடி குறைவுகளை நீக்குகிறதற்கேதுவாக நற்கிரியைகளைச் செய்யப்பழகட்டும்.
	<br /><br />
	15. என்னோடிருக்கிற யாவரும் உனக்கு வாழ்த்துதல் சொல்லுகிறார்கள். விசுவாசத்திலே நம்மைச் சிநேகிக்கிறவர்களுக்கு வாழ்த்துதல் சொல்லு. கிருபையானது உங்களனைவரோடுங்கூட இருப்பதாக. ஆமென்.
</p>





      {/* End of verse */} 

	  <br /><br />
<h4>
 <p>
* கிருபையின் மூலம் நாம் நீதிமான்களாக்கப்படுவது கடவுளின் கருணையினால்தான் என்பதை நினைவூட்டுதலுடன் தீத்து 3 முடிவடைகிறது. நாம் இப்போது நித்திய வாழ்வின் நம்பிக்கையுடன் வாரிசுகளாக இருக்கிறோம். இதைத்தான் பவுல் தீத்துக்கு கற்பிக்க நினைவூட்டுகிறார், முட்டாள்தனமான சர்ச்சைகளையும் வாதங்களையும் தவிர்க்கும்படி எச்சரித்தார், ஏனெனில் அவை லாபகரமானவை அல்ல. மாறாக, கடவுளின் கருணையின் உண்மை அனைவருக்கும் பயனுள்ளதாக இருக்கும், மேலும் நல்லதைச் செய்ய நம்மைத் தூண்டும். பவுல் தன்னிடம் வருபவர்களுக்கு உதவவும், பயனுள்ள வாழ்க்கையை வாழவும் தீத்துக்கு அறிவுறுத்தல்களுடன் முடிக்கிறார்.
</p>
<p>
நீங்கள் பார்க்க முடியும் என, இது தேவாலயம் புரிந்து நடைமுறைப்படுத்த ஒரு முக்கியமான புத்தகம். நாம் கற்பிப்பதில் நாம் வலுவாக இருக்க வேண்டும், அதனால் நம் வாழ்க்கையும் கற்பித்தலும் மற்றவர்களுக்கு கவர்ச்சிகரமானதாக இருக்கும் *
</p>
			</h4>
<br />
      </div>
    </div>
    </>
  );
}

export default Titus3