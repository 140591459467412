import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 8 </title>
	<meta
          name="description"
          content="Song of Solomon 8 | உன்னதப்பாட்டு 8 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆ, நீர் என் தாயின் பால்குடித்த என் சகோதரனைப்போலிருந்தீரானால், நான் உம்மை வெளியிலே சந்தித்து முத்தமிடுவேன்; என்னை நிந்திக்கவுமாட்டார்கள்.
	<br /><br />
	2. நான் உம்மைக் கூட்டிக்கொண்டு, என் தாயின் வீட்டுக்கு அழைத்துக்கொண்டுபோவேன்; நீர் என்னைப் போதிப்பீர், கந்தவர்க்கமிட்ட திராட்சரசத்தையும், என் மாதளம்பழரசத்தையும் உமக்குக் குடிக்கக்கொடுப்பேன்.
	<br /><br />
	3. அவர் இடதுகை என் தலையின் கீழிருக்கும், அவர் வலதுகை என்னை அணைக்கும்.
	<br /><br />
	4. எருசலேமின் குமாரத்திகளே! எனக்குப் பிரியமானவளுக்கு மனதாகுமட்டும் நீங்கள் அவளை விழிக்கப்பண்ணாமலும் எழுப்பாமலும் இருக்க உங்களை ஆணையிடுகிறேன்.
	<br /><br />
	5. தன் நேசர்மேல் சார்ந்துகொண்டு வனாந்தரத்திலிருந்து வருகிற இவள் யார்? கிச்சிலிமரத்தின்கீழ் உம்மை எழுப்பினேன்; அங்கே உமது தாய் உம்மைப் பெற்றாள்; அங்கே உம்மைப் பெற்றவள் வேதனைப்பட்டு உம்மைப் பெற்றாள்.
	<br /><br />
	6. நீர் என்னை உமது இருதயத்தின்மேல் முத்திரையைப்போலவும், உமது புயத்தின்மேல் முத்திரையைப்போலவும் வைத்துக்கொள்ளும்; நேசம் மரணத்தைப்போல் வலிது; நேசவைராக்கியம் பாதாளத்தைப்போல் கொடிதாயிருக்கிறது; அதின் தழல் அக்கினித்தழலும் அதின் ஜுவாலை கடும் ஜுவாலையுமாயிருக்கிறது.
	<br /><br />
	7. திரளான தண்ணீர்கள் நேசத்தை அவிக்கமாட்டாது, வெள்ளங்களும் அதைத் தணிக்கமாட்டாது; ஒருவன் தன் வீட்டிலுள்ள ஆஸ்திகளையெல்லாம் நேசத்துக்காகக் கொடுத்தாலும், அது முற்றிலும் அசட்டைபண்ணப்படும்.
	<br /><br />
	8. நமக்கு ஒரு சிறிய சகோதரியுண்டு, அவளுக்கு ஸ்தனங்களில்லை; நம்முடைய சகோதரியைக் கேட்கும் நாளில் அவளுக்காக நாம் என்ன செய்வோம்?
	<br /><br />
	9. அவள் ஒரு மதிலானால், அதின்மேல் வெள்ளிக்கோட்டையைக் கட்டுவோம்; அவள் கதவானால், கேதுருப்பலகைகளை அதற்கு இணைப்போம்.
	<br /><br />
	10. நான் மதில்தான், என் ஸ்தனங்கள் கோபுரங்கள்; அவருடைய கண்களில் கடாட்சம்பெறலானேன்.
	<br /><br />
	11. பாகால் ஆமோனிலே சாலொமோனுக்கு ஒரு திராட்சத்தோட்டம் உண்டாயிருந்தது, அந்தத் தோட்டத்தைக் காவலாளிகள் வசத்திலே அதின் பலனுக்காக, ஒவ்வொருவன் ஆயிரம் வெள்ளிக்காசு கொண்டுவரும்படி விட்டார்.
	<br /><br />
	12. என் திராட்சத்தோட்டம் எனக்கு முன்பாக இருக்கிறது; சாலொமோனே! உமக்கு அந்த ஆயிரமும், அதின் கனியைக் காக்கிறவர்களுக்கு இருநூறும் சேரும்.
	<br /><br />
	13. தோட்டங்களில் வாசம்பண்ணுகிறவளே! தோழர் உன் சத்தத்தைக் கேட்கிறார்கள்; நானும் அதைக் கேட்கட்டும்.
	<br /><br />
	14. என் நேசரே! தீவிரியும், கந்தவர்க்கங்களின் மலைகள்மேலுள்ள வெளிமானுக்கும் மரைகளின் குட்டிக்கும் சமானமாயிரும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs8