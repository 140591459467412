import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 20 </title>
	<meta
          name="description"
          content="Acts 20 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 20 |
          Acts of Apostles Chapter-20 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கலகம் அமர்ந்தபின்பு, பவுல் சீஷரைத் தன்னிடத்திற்கு வரவழைத்து, வினவிக்கொண்டு, மக்கெதோனியாவுக்குப் போகப் புறப்பட்டான்.
	<br /><br />
	2. அவன் அந்தத் திசைகளிலே சுற்றி நடந்து, சீஷர்களுக்கு வெகுவாய்ப் புத்திசொல்லி, கிரேக்கு தேசத்திலே சேர்ந்தான்.
	<br /><br />
	3. அங்கே மூன்றுமாதம் சஞ்சரித்தபின்பு, அவன் கப்பல் ஏறி, சீரியாதேசத்துக்குப்போக மனதாயிருந்தபோது, யூதர்கள் அவனுக்குத் தீமைசெய்யும்படி இரகசியமான யோசனைகொண்டிருந்தபடியால், மக்கெதோனியா தேசத்தின் வழியாய்த் திரும்பிப்போகத் தீர்மானம் பண்ணினான்.
	<br /><br />
	4. பெரோயா ஊரானாகிய சோபத்தரும், தெசலோனிக்கேயரில் அரிஸ்தர்க்கும், செக்குந்தும், தெர்பையானாகிய காயுவும், தீமோத்தேயும், ஆசியா நாட்டாராகிய தீகிக்கும் துரோப்பீமும், ஆசியாநாடுவரைக்கும் அவனுக்கு வழித்துணையாய் வந்தார்கள்.
	<br /><br />
	5. இவர்கள் முன்னாகப் போய், துரோவாபட்டணத்திலே எங்களுக்காகக் காத்திருந்தார்கள்.
	<br /><br />
	6. புளிப்பில்லாத அப்பப்பண்டிகை நாட்களுக்குப்பின்பு நாங்கள் கப்பல் ஏறிப் பிலிப்பிபட்டணத்தை விட்டு ஐந்து நாளைக்குள்ளே துரோவாபட்டணத்துக்கு அவர்களிடத்தில் வந்து, அங்கே ஏழுநாள் தங்கியிருந்தோம்.
	<br /><br />
	7. வாரத்தின் முதல்நாளிலே, அப்பம் பிட்கும்படி சீஷர்கள் கூடிவந்திருக்கையில், பவுல் மறுநாளிலே புறப்படவேண்டுமென்றிருந்து, அவர்களுடனே சம்பாஷித்து, நடுராத்திரிமட்டும் பிரசங்கித்தான்.
	<br /><br />
	8. அவர்கள் கூடியிருந்த மேல்வீட்டிலே அநேக விளக்குகள் வைத்திருந்தது.
	<br /><br />
	9. அப்பொழுது ஐத்திகு என்னும் பேர்கொண்ட ஒரு வாலிபன் ஜன்னலில் உட்கார்ந்திருந்து, பவுல் நெடுநேரம் பிரசங்கம் பண்ணிக்கொண்டிருக்கையில், மிகுந்த தூக்கமடைந்து, நித்திரைமயக்கத்தினால் சாய்ந்து, மூன்றாம் மெத்தையிலிருந்து கீழே விழுந்து, மரித்தவனாய் எடுக்கப்பட்டான்.
	<br /><br />
	10. உடனே பவுல் இறங்கிப்போய், அவன்மேல் விழுந்து, அவனை அணைத்துக்கொண்டு: கலங்காதிருங்கள், இவன் உயிர் இவனுக்குள் இருக்கிறது என்றான்.
	<br /><br />
	11. பின்பு ஏறிப்போய், அப்பம் பிட்டுப் புசித்து, விடியற்காலமளவும் வெகுநேரம் பேசிக்கொண்டிருந்து, பின்பு புறப்பட்டான்.
	<br /><br />
	12. அந்த வாலிபனை அவர்கள் உயிருள்ளவனாகக் கூட்டிக்கொண்டுவந்து, மிகுந்த ஆறுதலடைந்தார்கள்.
	<br /><br />
	13. பவுல் ஆசோபட்டணம்வரைக்கும் கரைவழியாய்ப் போக மனதாயிருந்தபடியால், அவன் திட்டம்பண்ணியிருந்தபடியே, நாங்கள் கப்பல் ஏறி, அந்தப் பட்டணத்தில் அவனை ஏற்றிக்கொள்ளும்படி முன்னாக அங்கே போயிருந்தோம்.
	<br /><br />
	14. அவன் ஆசோபட்டணத்திலே எங்களைக் கண்டபோது, நாங்கள் அவனை ஏற்றிக்கொண்டு, மித்திலேனே பட்டணத்துக்கு வந்தோம்.
	<br /><br />
	15. அவ்விடம்விட்டு, மறுநாளிலே கீயுதீவுக்கு எதிராக வந்து,
	<br /><br />
	16. பவுல் கூடுமானால் பெந்தெகொஸ்தே பண்டிகைநாளிலே எருசலேமிலிருக்கவேண்டுமென்று தீவிரப்பட்டதினிமித்தம், தான் ஆசியாவிலே காலம்போக்காதபடிக்கு, எபேசு பட்டணத்தைக் கடந்து போகவேண்டுமென்று தீர்மானித்ததினால், மறுநாளிலே சாமுதீவு பிடித்து, துரோகில்லியோன் ஊர்த்துறையிலே தங்கி, மறுநாள் மிலேத்துபட்டணத்துக்கு வந்தோம்.
	<br /><br />
	17. மிலேத்துவிலிருந்து அவன் எபேசுவுக்கு ஆள் அனுப்பி, சபையின் மூப்பரை வரவழைத்தான்.
	<br /><br />
	18. அவர்கள் தன்னிடத்தில் வந்து சேர்ந்தபொழுது, அவன் அவர்களை நோக்கி: நான் ஆசியாநாட்டில் வந்த முதல்நாள் தொடங்கி எல்லாக் காலங்களிலும் உங்களுடனே இன்னவிதமாய் இருந்தேன் என்பதை நீங்கள் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	19. வெகு மனத்தாழ்மையோடும், மிகுந்த கண்ணீரோடும், யூதருடைய தீமையான யோசனைகளால் எனக்கு நேரிட்ட சோதனைகளோடும், நான் கர்த்தரைச் சேவித்தேன்.
	<br /><br />
	20. பிரயோஜனமானவைகளில் ஒன்றையும் நான் உங்களுக்கு மறைத்துவைக்காமல், வெளியரங்கமாக வீடுகள்தோறும் உங்களுக்குப் பிரசங்கித்து, உபதேசம்பண்ணி,
	<br /><br />
	21. தேவனிடத்திற்கு மனந்திரும்புவதைக்குறித்தும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவை விசுவாசிப்பதைக்குறித்தும், நான் யூதருக்கும் கிரேக்கருக்கும் சாட்சியாக அறிவித்தேன்.
	<br /><br />
	22. இப்பொழுதும் நான் ஆவியிலே கட்டுண்டவனாய் எருசலேமுக்குப் போகிறேன்; அங்கே எனக்கு நேரிடுங்காரியங்களை நான் அறியேன்.
	<br /><br />
	23. கட்டுகளும் உபத்திரவங்களும் எனக்கு வைத்திருக்கிறதென்று பரிசுத்தஆவியானவர் பட்டணந்தோறும் தெரிவிக்கிறதைமாத்திரம் அறிந்திருக்கிறேன்.
	<br /><br />
	24. ஆகிலும் அவைகளில் ஒன்றையுங்குறித்துக் கவலைப்படேன்; என் பிராணனையும் நான் அருமையாக எண்ணேன்; என் ஓட்டத்தைச் சந்தோஷத்தோடே முடிக்கவும், தேவனுடைய கிருபையின் சுவிசேஷத்தைப் பிரசங்கம்பண்ணும்படிக்கு நான் கர்த்தராகிய இயேசுவினிடத்தில் பெற்ற ஊழியத்தை நிறைவேற்றவுமே விரும்புகிறேன்.
	<br /><br />
	25. இதோ, நான் உங்களுக்குள்ளே சஞ்சரித்து, தேவனுடைய ராஜ்யத்தைக் குறித்துப் பிரசங்கம்பண்ணினதைக் கேட்டவர்களாகிய நீங்களெல்லாரும் இனி என் முகத்தைப் பார்க்கமாட்டீர்களென்று அறிந்திருக்கிறேன்.
	<br /><br />
	26. தேவனுடைய ஆலோசனையில் ஒன்றையும் நான் மறைத்துவைக்காமல், எல்லாவற்றையும் உங்களுக்கு அறிவித்தபடியினாலே,
	<br /><br />
	27. எல்லாருடைய இரத்தப்பழிக்கும் நீங்கி நான் சுத்தமாயிருக்கிறேனென்பதற்கு உங்களை இன்றையத்தினம் சாட்சிகளாக வைக்கிறேன்.
	<br /><br />
	28. ஆகையால், உங்களைக்குறித்தும், தேவன் தம்முடைய சுயரத்தத்தினாலே சம்பாதித்துக்கொண்ட தமது சபையை மேய்ப்பதற்குப் பரிசுத்தஆவி உங்களைக் கண்காணிகளாக வைத்த மந்தை முழுவதையுங்குறித்தும், எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	29. நான் போனபின்பு மந்தையைத் தப்பவிடாத கொடிதான ஓநாய்கள் உங்களுக்குள்ளே வரும்.
	<br /><br />
	30. உங்களிலும் சிலர் எழும்பி, சீஷர்களைத் தங்களிடத்தில் இழுத்துக்கொள்ளும்படி மாறுபாடானவைகளைப் போதிப்பார்களென்று அறிந்திருக்கிறேன்.
	<br /><br />
	31. ஆனபடியால், நான் மூன்றுவருஷ காலமாய் இரவும் பகலும் கண்ணீரோடே இடைவிடாமல் அவனவனுக்குப் புத்திசொல்லிக்கொண்டுவந்ததை நினைத்து விழித்திருங்கள்.
	<br /><br />
	32. இப்பொழுதும் சகோதரரே, நீங்கள் பக்திவிருத்தியடையவும், பரிசுத்தமாக்கப்பட்ட அனைவருக்குள்ளும் உங்களுக்குச் சுதந்தரத்தைக் கொடுக்கவும் வல்லவராயிருக்கிற தேவனுக்கும் அவருடைய கிருபையுள்ள வசனத்துக்கும் உங்களை ஒப்புக்கொடுக்கிறேன்.
	<br /><br />
	33. ஒருவனுடைய வெள்ளியையாகிலும் பொன்னையாகிலும் வஸ்திரத்தையாகிலும் நான் இச்சிக்கவில்லை.
	<br /><br />
	34. நீங்கள் அறிந்திருக்கிறபடி, எனக்கும் என்னுடனேகூட இருந்தவர்களுக்கும் வேண்டியவைகளுக்காக இந்தக் கைகளே வேலைசெய்தது.
	<br /><br />
	35. இப்படிப் பிரயாசப்பட்டு, பலவீனரைத் தாங்கவும், வாங்குகிறதைப்பார்க்கிலும் கொடுக்கிறதே பாக்கியம் என்று கர்த்தராகிய இயேசு சொன்ன வார்த்தைகளை நினைக்கவும் வேண்டுமென்று எல்லா விதத்திலேயும் உங்களுக்குக் காண்பித்தேன் என்றான்.
	<br /><br />
	36. இவைகளைச் சொன்னபின்பு, அவன் முழங்காற்படியிட்டு, அவர்களெல்லாரோடுங்கூட ஜெபம்பண்ணினான்.
	<br /><br />
	37. அவர்களெல்லாரும் மிகவும் அழுது, என் முகத்தை நீங்கள் இனிப் பார்க்கமாட்டீர்களென்று அவன் சொன்ன வார்த்தையைக்குறித்து அதிகமாய்த் துக்கப்பட்டு,
	<br /><br />
	38. பவுலின் கழுத்தைக் கட்டிக்கொண்டு, அவனை முத்தஞ்செய்து, கப்பல்வரைக்கும் அவனுடனே கூடப்போனார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts20