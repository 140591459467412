import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah54 = () => {
  const verseNumber = 54;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 54 </title>
	<meta
          name="description"
          content="Isaiah 54 | ஏசாயா 54 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பிள்ளைபெறாத மலடியே, மகிழ்ந்துபாடு; கர்ப்பவேதனைப்படாதவளே, கெம்பீரமாய்ப் பாடி ஆனந்தசத்தமிடு; வாழ்க்கைப்பட்டவளுடைய பிள்ளைகளைப்பார்க்கிலும், அநாத ஸ்திரீயினுடைய பிள்ளைகள் அதிகம் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	2. உன் கூடாரத்தின் இடத்தை விசாலமாக்கு; உன் வாசஸ்தலங்களின் திரைகள் விரிவாகட்டும்; தடைசெய்யாதே; உன் கயிறுகளை நீளமாக்கி, உன் முளைகளை உறுதிப்படுத்து.
	<br /><br />
	3. நீ வலதுபுறத்திலும் இடதுபுறத்திலும் இடங்கொண்டு பெருகுவாய்; உன் சந்ததியார் ஜாதிகளைச் சுதந்தரித்துக்கொண்டு, பாழாய்க்கிடந்த பட்டணங்களைக் குடியேற்றுவிப்பார்கள்.
	<br /><br />
	4. பயப்படாதே, நீ வெட்கப்படுவதில்லை; நாணாதே, நீ இலச்சையடைவதில்லை; உன் வாலிபத்தின் வெட்கத்தை நீ மறந்து, உன் விதவையிருப்பின் நிந்தையை இனி நினையாதிருப்பாய்.
	<br /><br />
	5. உன் சிருஷ்டிகரே உன் நாயகர்; சேனைகளின் கர்த்தர் என்பது அவருடைய நாமம், இஸ்ரவேலின் பரிசுத்தர் உன் மீட்பர், அவர் சர்வபூமியின் தேவன் என்னப்படுவார்.
	<br /><br />
	6. கைவிடப்பட்டு மனம்நொந்தவளான ஸ்திரீயைப்போலவும், இளம்பிராயத்தில் விவாகஞ்செய்து தள்ளப்பட்ட மனைவியைப்போலவும் இருக்கிற உன்னைக் கர்த்தர் அழைத்தார் என்று உன் தேவன் சொல்லுகிறார்.
	<br /><br />
	7. இமைப்பொழுது உன்னைக் கைவிட்டேன்; ஆனாலும் உருக்கமான இரக்கங்களால் உன்னைச் சேர்த்துக்கொள்வேன்.
	<br /><br />
	8. அற்பகாலம் மூண்ட கோபத்தினால் என் முகத்தை இமைப்பொழுது உனக்கு மறைத்தேன்; ஆனாலும் நித்திய கிருபையுடன் உனக்கு இரங்குவேன் என்று கர்த்தராகிய உன் மீட்பர் சொல்லுகிறார்.
	<br /><br />
	9. இது எனக்கு நோவாவின் காலத்திலுண்டான வெள்ளம்போலிருக்கும்; நோவாவின் காலத்திலுண்டான வெள்ளம் இனி பூமியின்மேல் புரண்டு வருவதில்லை என்று நான் ஆணையிட்டதுபோல, உன்மேல் நான் கோபங்கொள்வதில்லையென்றும், உன்னை நான் கடிந்துகொள்வதில்லையென்றும் ஆணையிட்டேன்.
	<br /><br />
	10. மலைகள் விலகினாலும், பர்வதங்கள் நிலைபெயர்ந்தாலும், என் கிருபை உன்னைவிட்டு விலகாமலும், என் சமாதானத்தின் உடன்படிக்கை நிலைபெயராமலும் இருக்கும் என்று, உன்மேல் மனதுருகுகிற கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. சிறுமைப்பட்டவளே, பெருங்காற்றில் அடிபட்டவளே, தேற்றரவற்றவளே, இதோ, நான் உன் கல்லுகளைப் பிரகாசிக்கும்படி வைத்து, நீலரத்தினங்களை உன் அஸ்திபாரமாக்கி,
	<br /><br />
	12. உன் பலகணிகளைப் பளிங்கும், உன் வாசல்களை மாணிக்கக் கற்களும், உன் மதில்களையெல்லாம் உச்சிதமான கற்களுமாக்குவேன்.
	<br /><br />
	13. உன் பிள்ளைகளெல்லாரும் கர்த்தரால் போதிக்கப்பட்டிருப்பார்கள்; உன் பிள்ளைகளுடைய சமாதானம் பெரிதாயிருக்கும்.
	<br /><br />
	14. நீதியினால் ஸ்திரப்பட்டிருப்பாய்; கொடுமைக்குத் தூரமாவாய்; பயமில்லாதிருப்பாய்; திகிலுக்குத் தூரமாவாய், அது உன்னை அணுகுவதில்லை.
	<br /><br />
	15. இதோ, உனக்கு விரோதமாய்க் கூட்டங்கூடினால், அது என்னாலே கூடுகிற கூட்டமல்ல; எவர்கள் உனக்கு விரோதமாய்க் கூடுகிறார்களோ, அவர்கள் உன் பட்சத்தில் வருவார்கள்.
	<br /><br />
	16. இதோ, கரிநெருப்பை ஊதி, தன் கிரியைக்கான ஆயுதத்தை உண்டுபண்ணுகிற கொல்லனையும் நான் சிருஷ்டித்தேன்; கெடுத்து நிக்கிரகமாக்குகிறவனையும் நான் சிருஷ்டித்தேன்.
	<br /><br />
	17. உனக்கு விரோதமாய் உருவாக்கப்படும் எந்த ஆயுதமும் வாய்க்காதேபோம்; உனக்கு விரோதமாய் நியாயத்தில் எழும்பும் எந்த நாவையும் நீ குற்றப்படுத்துவாய்; இது கர்த்தருடைய ஊழியக்காரரின் சுதந்தரமும், என்னாலுண்டான அவர்களுடைய நீதியுமாயிருக்கிறதென்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah54