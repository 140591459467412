import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jude1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Book of Jude Chapter - 1 in Tamil Bible Verse | யூதா - 1</title>
	<meta
          name="description"
          content="The Book of Jude Chapter - 1 in Tamil Bible Verse | யூதா எழுதிய நிருபம் அதிகாரம் 1  | Tamil Bible Words | புதிய ஏற்பாடு | New Testament | Bible"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* யூதா, தேர்ந்தெடுக்கப்பட்டவர்களுக்கு. தெய்வபக்தியற்ற மக்கள் கருணையை சிற்றின்பமாக மாற்றுகிறார்கள். அவை இரண்டு முறை இறந்த மரங்களாக மதிப்பிடப்படுகின்றன. விசுவாசத்தில் உங்களைக் கட்டியெழுப்புங்கள் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. இயேசுகிறிஸ்துவினுடைய ஊழியக்காரனும், யாக்கோபினுடைய சகோதரனுமாயிருக்கிற யூதா, பிதாவாகிய தேவனாலே பரிசுத்தமாக்கப்பட்டவர்களும், இயேசுகிறிஸ்துவினாலே காக்கப்பட்டவர்களுமாகிய அழைக்கப்பட்டவர்களுக்கு எழுதுகிறதாவது:
	<br /><br />
	2. உங்களுக்கு இரக்கமும் சமாதானமும் அன்பும் பெருகக்கடவது.
	<br /><br />
	3. பிரியமானவர்களே, பொதுவான இரட்சிப்பைக்குறித்து உங்களுக்கு எழுதும்படி நான் மிகவும் கருத்துள்ளவனாயிருக்கையில், பரிசுத்தவான்களுக்கு ஒருவிசை ஒப்புக்கொடுக்கப்பட்ட விசுவாசத்திற்காக நீங்கள் தைரியமாய்ப் போராடவேண்டுமென்று உங்களுக்கு எழுதி உணர்த்துவது எனக்கு அவசியமாய்க் கண்டது.
	<br /><br />
	4. ஏனெனில் நமது தேவனுடைய கிருபையைக் காமவிகாரத்துக்கேதுவாகப் புரட்டி, ஒன்றான ஆண்டவராகிய தேவனையும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவையும் மறுதலிக்கிற பக்தியற்றவர்களாகிய சிலர் பக்கவழியாய் நுழைந்திருக்கிறார்கள்; அவர்கள் இந்த ஆக்கினைக்குள்ளாவார்களென்று பூர்வத்திலே எழுதியிருக்கிறது.
	<br /><br />
	5. நீங்கள் முன்னமே அறிந்திருந்தாலும், நான் உங்களுக்கு நினைப்பூட்ட விரும்புகிறதென்னவெனில், கர்த்தர் தமது ஜனத்தை எகிப்துதேசத்திலிருந்து வரப்பண்ணி இரட்சித்து, பின்பு விசுவாசியாதவர்களை அழித்தார்.
	<br /><br />
	6. தங்களுடைய ஆதிமேன்மையைக் காத்துக்கொள்ளாமல், தங்களுக்குரிய வாசஸ்தலத்தை விட்டுவிட்ட தூதர்களையும், மகாநாளின் நியாயத்தீர்ப்புக்கென்று நித்திய சங்கிலிகளினாலே கட்டி, அந்தகாரத்தில் அடைத்துவைத்திருக்கிறார்.
	<br /><br />
	7. அப்படியே சோதோம் கொமோரா பட்டணத்தார்களும், அவைகளைச் சூழ்ந்த பட்டணத்தார்களும், அவர்களைப்போல் விபசாரம்பண்ணி, அந்நிய மாம்சத்தைத் தொடர்ந்து, நித்திய அக்கினியின் ஆக்கினையை அடைந்து, திருஷ்டாந்தமாக வைக்கப்பட்டிருக்கிறார்கள்.
	<br /><br />
	8. அப்படிப்போலவே, சொப்பனக்காரராகிய இவர்களும் மாம்சத்தை அசுசிப்படுத்திக்கொண்டு, கர்த்தத்துவத்தை அசட்டைபண்ணி, மகத்துவங்களைத் தூஷிக்கிறார்கள்.
	<br /><br />
	9. பிரதான தூதனாகிய மிகாவேல் மோசேயினுடைய சரீரத்தைக்குறித்துப் பிசாசுடனே தர்க்கித்துப் பேசினபோது, அவனைத் தூஷணமாய்க் குற்றப்படுத்தத் துணியாமல்: கர்த்தர் உன்னைக் கடிந்துகொள்வாராக என்று சொன்னான்.
	<br /><br />
	10. இவர்கள் தங்களுக்குத் தெரியாதவைகளைத் தூஷிக்கிறார்கள்; புத்தியில்லாத மிருகங்களைப்போலச் சுபாவப்படி தங்களுக்குத் தெரிந்திருக்கிறவைகளாலே தங்களைக் கெடுத்துக்கொள்ளுகிறார்கள்.
	<br /><br />
	11. இவர்களுக்கு ஐயோ! இவர்கள் காயீனுடைய வழியில் நடந்து, பிலேயாம் கூலிக்காகச்செய்த வஞ்சகத்திலே விரைந்தோடி, கோரா எதிர்த்துப்பேசின பாவத்திற்குள்ளாகி, கெட்டுப்போனார்கள்.
	<br /><br />
	12. இவர்கள் உங்கள் அன்பின் விருந்துகளில் கறைகளாயிருந்து, பயமின்றிக் கூட விருந்துண்டு, தங்களைத் தாங்களே மேய்த்துக்கொள்ளுகிறார்கள்; இவர்கள் காற்றுகளால் அடியுண்டோடுகிற தண்ணீரற்ற மேகங்களும், இலையுதிர்ந்து கனியற்று இரண்டுதரஞ் செத்து வேரற்றுப்போன மரங்களும்,
	<br /><br />
	13. தங்கள் அவமானங்களை நுரைதள்ளுகிற அமளியான கடலலைகளும், மார்க்கந்தப்பி அலைகிற நட்சத்திரங்களுமாயிருக்கிறார்கள்; இவர்களுக்காக என்றென்றைக்கும் காரிருளே வைக்கப்பட்டிருக்கிறது.
	<br /><br />
	14. ஆதாமுக்கு ஏழாந்தலைமுறையான ஏனோக்கும் இவர்களைக்குறித்து: இதோ, எல்லாருக்கும் நியாயத்தீர்ப்புக் கொடுக்கிறதற்கும், அவர்களில் அவபக்தியுள்ளவர்கள் யாவரும் அவபக்தியாய்ச் செய்துவந்த சகல அவபக்தியான கிரியைகளினிமித்தமும்,
	<br /><br />
	15. தமக்கு விரோதமாய் அவபக்தியுள்ள பாவிகள் பேசின கடின வார்த்தைகளெல்லாவற்றினிமித்தமும், அவர்களைக் கண்டிக்கிறதற்கும், ஆயிரமாயிரமான தமது பரிசுத்தவான்களோடுங்கூடக் கர்த்தர் வருகிறார் என்று முன்னறிவித்தான்.
	<br /><br />
	16. இவர்கள் முறுமுறுக்கிறவர்களும், முறையிடுகிறவர்களும், தங்கள் இச்சைகளின்படி நடக்கிறவர்களுமாயிருக்கிறார்கள்; இவர்களுடைய வாய் இறுமாப்பானவைகளைப் பேசும்; தற்பொழிவுக்காக முகஸ்துதி செய்வார்கள்.
	<br /><br />
	17. நீங்களோ பிரியமானவர்களே, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் அப்போஸ்தலரால் முன் சொல்லப்பட்ட வார்த்தைகளை நினைவுகூருங்கள்.
	<br /><br />
	18. கடைசிக்காலத்திலே தங்கள் துன்மார்க்கமான இச்சைகளின்படி நடக்கிற பரியாசக்காரர் தோன்றுவார்கள் என்று உங்களுக்குச் சொன்னார்களே.
	<br /><br />
	19. இவர்கள் பிரிந்துபோகிறவர்களும், ஜென்மசுபாவத்தாரும், ஆவியில்லாதவர்களுமாமே.
	<br /><br />
	20. நீங்களோ பிரியமானவர்களே, உங்கள் மகா பரிசுத்தமான விசுவாசத்தின்மேல் உங்களை உறுதிப்படுத்திக்கொண்டு, பரிசுத்தஆவிக்குள் ஜெபம்பண்ணி,
	<br /><br />
	21. தேவனுடைய அன்பிலே உங்களைக் காத்துக்கொண்டு, நித்தியஜீவனுக்கேதுவாக நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய இரக்கத்தைப்பெறக் காத்திருங்கள்.
	<br /><br />
	22. அல்லாமலும், நீங்கள் பகுத்தறிவுள்ளவர்களாயிருந்து, சிலருக்கு இரக்கம் பாராட்டி, சிலரை அக்கினியிலிருந்து இழுத்துவிட்டு, பயத்தோடே இரட்சித்து,
	<br /><br />
	23. மாம்சத்தால் கறைப்பட்டிருக்கிற வஸ்திரத்தையும் வெறுத்துத் தள்ளுங்கள்.
	<br /><br />
	24. வழுவாதபடி உங்களைக் காக்கவும், தமது மகிமையுள்ள சந்நிதானத்திலே மிகுந்த மகிழ்ச்சியோடே உங்களை மாசற்றவர்களாய் நிறுத்தவும் வல்லமையுள்ளவரும்,
	<br /><br />
	25. தாம் ஒருவரே ஞானமுள்ளவருமாகிய நம்முடைய இரட்சகரான தேவனுக்குக் கனமும் மகத்துவமும் வல்லமையும் அதிகாரமும் இப்பொழுதும் எப்பொழுதும் உண்டாவதாக. ஆமென்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
*  *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Jude1