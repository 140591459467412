import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 1 </title>
	<meta
          name="description"
          content="Acts 1 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 1 |
          Acts of Apostles Chapter-1 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தெயோப்பிலுவே, இயேசுவானவர் தாம் தெரிந்துகொண்ட அப்போஸ்தலருக்குப் பரிசுத்தஆவியினாலே கட்டளையிட்ட பின்பு,
	<br /><br />
	2. அவர் எடுத்துக்கொள்ளப்பட்ட நாள்வரைக்கும் செய்யவும் உபதேசிக்கவும் தொடங்கின எல்லாவற்றையுங்குறித்து, முதலாம் பிரபந்தத்தை உண்டுபண்ணினேன்.
	<br /><br />
	3. அவர் பாடுபட்டபின்பு, நாற்பது நாளளவும் அப்போஸ்தலருக்குத் தரிசனமாகி, தேவனுடைய ராஜ்யத்துக்குரியவைகளை அவர்களுடனே பேசி, அநேகம் தெளிவான திருஷ்டாந்தங்களினாலே அவர்களுக்குத் தம்மை உயிரோடிருக்கிறவராகக் காண்பித்தார்.
	<br /><br />
	4. அன்றியும், அவர் அவர்களுடனே கூடிவந்திருக்கும்போது, அவர்களை நோக்கி: யோவான் ஜலத்தினாலே ஞானஸ்நானங் கொடுத்தான்; நீங்கள் சில நாளுக்குள்ளே பரிசுத்தஆவியினாலே ஞானஸ்நானம் பெறுவீர்கள்.
	<br /><br />
	5. ஆகையால் நீங்கள் எருசலேமை விட்டுப் போகாமல் என்னிடத்தில் கேள்விப்பட்ட பிதாவின் வாக்குத்தத்தம் நிறைவேறக் காத்திருங்கள் என்று கட்டளையிட்டார்.
	<br /><br />
	6. அப்பொழுது கூடிவந்திருந்தவர்கள் அவரை நோக்கி: ஆண்டவரே, இக்காலத்திலா ராஜ்யத்தை இஸ்ரவேலுக்குத் திரும்பக் கொடுப்பீர் என்று கேட்டார்கள்.
	<br /><br />
	7. அதற்கு அவர்: பிதாவானவர் தம்முடைய ஆதீனத்திலே வைத்திருக்கிற காலங்களையும் வேளைகளையும் அறிகிறது உங்களுக்கு அடுத்ததல்ல.
	<br /><br />
	8. பரிசுத்தஆவி உங்களிடத்தில் வரும்போது நீங்கள் பெலனடைந்து, எருசலேமிலும், யூதேயா முழுவதிலும், சமாரியாவிலும், பூமியின் கடைசிபரியந்தமும், எனக்குச் சாட்சிகளாயிருப்பீர்கள் என்றார்.
	<br /><br />
	9. இவைகளை அவர் சொன்னபின்பு, அவர்கள் பார்த்துக்கொண்டிருக்கையில், உயர எடுத்துக்கொள்ளப்பட்டார்; அவர்கள் கண்களுக்கு மறைவாக ஒரு மேகம் அவரை எடுத்துக்கொண்டது.
	<br /><br />
	10. அவர் போகிறபோது அவர்கள் வானத்தை அண்ணாந்து பார்த்துக்கொண்டிருக்கையில், இதோ, வெண்மையான வஸ்திரந்தரித்தவர்கள் இரண்டுபேர் அவர்களருகே நின்று:
	<br /><br />
	11. கலிலேயராகிய மனுஷரே, நீங்கள் ஏன் வானத்தை அண்ணாந்துபார்த்து நிற்கிறீர்கள்? உங்களிடத்தினின்று வானத்துக்கு எடுத்துக்கொள்ளப்பட்ட இந்த இயேசுவானவர் எப்படி உங்கள் கண்களுக்கு முன்பாக வானத்துக்கு எழுந்தருளிப்போனாரோ, அப்படியே மறுபடியும் வருவார் என்றார்கள்.
	<br /><br />
	12. அப்பொழுது அவர்கள் எருசலேமுக்குச் சமீபமாய் ஒரு ஓய்வுநாள் பிரயாண தூரத்திலிருக்கிற ஒலிவமலை என்னப்பட்ட மலையிலிருந்து எருசலேமுக்குத் திரும்பிப்போனார்கள்.
	<br /><br />
	13. அவர்கள் அங்கே வந்தபோது மேல்வீட்டில் ஏறினார்கள்; அதில் பேதுருவும், யாக்கோபும், யோவானும், அந்திரேயாவும், பிலிப்பும், தோமாவும், பற்தொலொமேயும், மத்தேயும், அல்பேயுவின் குமாரனாகிய யாக்கோபும், செலோத்தே என்னப்பட்ட சீமோனும், யாக்கோபின் சகோதரனாகிய யூதாவும் தங்கியிருந்தார்கள்.
	<br /><br />
	14. அங்கே இவர்களெல்லாரும், ஸ்திரீகளோடும் இயேசுவின் தாயாகிய மரியாளோடும், அவருடைய சகோதரரோடுங்கூட ஒருமனப்பட்டு, ஜெபத்திலும் வேண்டுதலிலும் தரித்திருந்தார்கள்.
	<br /><br />
	15. அந்நாட்களிலே, சீஷர்களில் ஏறக்குறைய நூற்றிருபதுபேர் கூடியிருந்தபோது, அவர்கள் நடுவிலே பேதுரு எழுந்து நின்று:
	<br /><br />
	16. சகோதரரே, இயேசுவைப் பிடித்தவர்களுக்கு வழிகாட்டின யூதாசைக்குறித்துப் பரிசுத்தஆவி தாவீதின் வாக்கினால் முன் சொன்ன வேதவாக்கியம் நிறைவேற வேண்டியதாயிருந்தது.
	<br /><br />
	17. அவன் எங்களில் ஒருவனாக எண்ணப்பட்டு, இந்த ஊழியத்திலே பங்கு பெற்றவனாயிருந்தான்.
	<br /><br />
	18. அநீதத்தின் கூலியினால் அவன் ஒரு நிலத்தைச் சம்பாதித்து, தலைகீழாக விழுந்தான்; அவன் வயிறுவெடித்து, குடல்களெல்லாம் சரிந்துபோயிற்று.
	<br /><br />
	19. இது எருசலேமிலுள்ள குடிகள் யாவருக்கும் தெரிந்திருக்கிறது; அதினாலே அந்த நிலம் அவர்களுடைய பாஷையிலே இரத்தநிலம் என்று அர்த்தங்கொள்ளும் அக்கெல்தமா என்னப்பட்டிருக்கிறது.
	<br /><br />
	20. சங்கீத புஸ்தகத்திலே: அவனுடைய வாசஸ்தலம் பாழாகக்கடவது, ஒருவனும் அதில் வாசம்பண்ணாதிருப்பானாக என்றும்; அவனுடைய கண்காணிப்பை வேறொருவன் பெறக்கடவன் என்றும் எழுதியிருக்கிறது.
	<br /><br />
	21. ஆதலால், யோவான் ஞானஸ்நானங்கொடுத்த நாள்முதற்கொண்டு, கர்த்தராகிய இயேசுவானவர் நம்மிடத்திலிருந்து உயர எடுத்துக்கொள்ளப்பட்ட நாள்வரைக்கும்,
	<br /><br />
	22. அவர் நம்மிடத்தில் சஞ்சரித்திருந்த காலங்களிலெல்லாம் எங்களுடனேகூட இருந்த மனுஷர்களில் ஒருவன் அவர் உயிரோடெழுந்ததைக்குறித்து, எங்களுடனேகூடச் சாட்சியாக ஏற்படுத்தப்படவேண்டும் என்றான்.
	<br /><br />
	23. அப்பொழுது அவர்கள் யுஸ்து என்னும் மறுநாமமுள்ள பர்சபா என்னப்பட்ட யோசேப்பும் மத்தியாவும் ஆகிய இவ்விரண்டுபேரையும் நிறுத்தி:
	<br /><br />
	24. எல்லாருடைய இருதயங்களையும் அறிந்திருக்கிற கர்த்தாவே, யூதாஸ் என்பவன் தனக்குரிய இடத்துக்குப் போகும்படி இழந்துபோன இந்த ஊழியத்திலும் இந்த அப்போஸ்தலப்பட்டத்திலும் பங்கு பெறுவதற்காக,
	<br /><br />
	25. இவ்விரண்டு பேரில் தேவரீர் தெரிந்துகொண்டவனை எங்களுக்குக் காண்பித்தருளும் என்று ஜெபம்பண்ணி;
	<br /><br />
	26. பின்பு, அவர்களைக்குறித்துச் சீட்டுப்போட்டார்கள்; சீட்டு மத்தியாவின்பேருக்கு விழுந்தது; அப்பொழுது அவன் பதினொரு அப்போஸ்தலருடனே சேர்த்துக்கொள்ளப்பட்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts1