import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneTimothy4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> 1 Timothy in Tamil Bible Verse | 1 தீமோத்தேயு அதிகாரம்-4 </title>
	<meta
          name="description"
          content="FIRST LETTER TO THE Timothy Chapter 4 | 1 தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* தீமோத்தேயுக்கான ஆலோசனை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

	  <p>
	1. ஆகிலும், ஆவியானவர் வெளிப்படையாய்ச் சொல்லுகிறபடி, பிற்காலங்களிலே மனச்சாட்சியில் சூடுண்ட பொய்யருடைய மாயத்தினாலே சிலர் வஞ்சிக்கிற ஆவிகளுக்கும் பிசாசுகளின் உபதேசங்களுக்கும் செவிகொடுத்து, விசுவாசத்தை விட்டு விலகிப்போவார்கள்.
	<br /><br />
	2. விவாகம்பண்ணாதிருக்கவும்,
	<br /><br />
	3. விசுவாசிகளும் சத்தியத்தை அறிந்தவர்களும் ஸ்தோத்திரத்தோடே அநுபவிக்கும்படி தேவன் படைத்த போஜனபதார்த்தங்களை விலக்கவும் வேண்டுமென்று அந்தப் பொய்யர் கட்டளையிடுவார்கள்.
	<br /><br />
	4. தேவன் படைத்ததெல்லாம் நல்லதாயிருக்கிறது; ஸ்தோத்திரத்தோடே ஏற்றுக்கொள்ளப்படுமாகில் ஒன்றும் தள்ளப்படத்தக்கதல்ல.
	<br /><br />
	5. அது தேவவசனத்தினாலும் ஜெபத்தினாலும் பரிசுத்தமாக்கப்படும்.
	<br /><br />
	6. இவைகளை நீ சகோதரருக்குப் போதித்துவந்தால், விசுவாசத்திற்குரிய வார்த்தைகளிலும் நீ அநுசரித்த நற்போதகத்திலும் தேறினவனாகி, இயேசுகிறிஸ்துவுக்கு நல்ல ஊழியக்காரனாயிருப்பாய்.
	<br /><br />
	7. சீர்கேடும் கிழவிகள் பேச்சுமாயிருக்கிற கட்டுக்கதைகளுக்கு விலகி, தேவ பக்திக்கேதுவாக முயற்சிபண்ணு.
	<br /><br />
	8. சரீரமுயற்சி அற்ப பிரயோஜனமுள்ளது; தேவபக்தியானது இந்த ஜீவனுக்கும் இதற்குப் பின்வரும் ஜீவனுக்கும் வாக்குத்தத்தமுள்ளதாகையால் எல்லாவற்றிலும் பிரயோஜனமுள்ளது.
	<br /><br />
	9. இந்த வார்த்தை உண்மையும் எல்லா அங்கிகரிப்புக்கும் பாத்திரமுமாயிருக்கிறது.
	<br /><br />
	10. இதினிமித்தம் பிரயாசப்படுகிறோம், நிந்தையும் அடைகிறோம்; ஏனெனில் எல்லா மனுஷருக்கும், விசேஷமாக விசுவாசிகளுக்கும் இரட்சகராகிய ஜீவனுள்ள தேவன்மேல் நம்பிக்கை வைத்திருக்கிறோம்.
	<br /><br />
	11. இவைகளை நீ கட்டளையிட்டுப் போதித்துக்கொண்டிரு.
	<br /><br />
	12. உன் இளமையைக்குறித்து ஒருவனும் உன்னை அசட்டைபண்ணாதபடிக்கு, நீ வார்த்தையிலும், நடக்கையிலும், அன்பிலும், ஆவியிலும், விசுவாசத்திலும், கற்பிலும், விசுவாசிகளுக்கு மாதிரியாயிரு.
	<br /><br />
	13. நான் வருமளவும் வாசிக்கிறதிலும் புத்திசொல்லுகிறதிலும் உபதேசிக்கிறதிலும் ஜாக்கிரதையாயிரு.
	<br /><br />
	14. மூப்பராகிய சங்கத்தார் உன்மேல் கைகளை வைத்தபோது தீர்க்கதரிசனத்தினால் உனக்கு அளிக்கப்பட்ட வரத்தைப்பற்றி அசதியாயிராதே.
	<br /><br />
	15. நீ தேறுகிறது யாவருக்கும் விளங்கும்படி இவைகளையே சிந்தித்துக்கொண்டு, இவைகளிலே நிலைத்திரு.
	<br /><br />
	16. உன்னைக்குறித்தும் உபதேசத்தைக்குறித்தும் எச்சரிக்கையாயிரு, இவைகளில் நிலைகொண்டிரு, இப்படிச் செய்வாயானால், உன்னையும் உன் உபதேசத்தைக் கேட்பவர்களையும் இரட்சித்துக்கொள்ளுவாய்.
</p>


      {/* End of verse */}  

	  <br /><br />
		<br /><br />
<h2>
*I தீமோத்தேயு 4 என்பது நேரங்களைக் கவனிக்க ஒரு எச்சரிக்கை. சிலர் நம்பிக்கையை கைவிட்டு, திருமணத்தை தடை செய்தல், சில உணவுகளை தவிர்ப்பது போன்ற பேய்கள் போதிக்கும் விஷயங்களை பின்பற்றுவார்கள் என்று குறிப்பிட்டுள்ளார். எவ்வாறாயினும், அனைத்தும் கடவுளால் படைக்கப்பட்டவை, நன்றியுடன் ஏற்றுக்கொள்ளப்பட வேண்டும் என்பதை பவுல் நினைவுபடுத்துகிறார். பின்னர் அவர் தீமோத்தேயுவை தேவபக்தியுள்ளவராக இருப்பதற்கும், வேதத்தை வாசிப்பதற்கும், பிரசங்கிப்பதற்கும், போதிப்பதற்கும் தன்னை அர்ப்பணித்துக்கொள்ளும்படியும் ஊக்குவிக்கிறார். அவனுடைய வாழ்க்கையையும் அவனுடைய கோட்பாட்டையும் உன்னிப்பாகக் கவனிக்கும்படி அவன் அவனை ஊக்குவிக்கிறான்.*
			</h2>
<br /><br />
			<br /><br />

      </div>
    </div>
    </>
  );
}

export default OneTimothy4