import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 4 </title>
	<meta
          name="description"
          content="Jeremiah 4 | எரேமியா 4 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேலே, நீ திரும்புகிறதற்கு மனதாயிருந்தால் என்னிடத்தில் திரும்பு என்று கர்த்தர் சொல்லுகிறார்; நீ உன் அருவருப்புகளை என் பார்வையினின்று அகற்றிவிட்டால், நீ இனி அலைந்து திரிவதில்லை.
	<br /><br />
	2. நீ உண்மையோடும், நியாயத்தோடும், நீதியோடும், கர்த்தருடைய ஜீவனைக்கொண்டு ஆணையிடுவாய்; புறஜாதிகளும் அவருக்குள் ஆசீர்வதிக்கப்பட்டு, அவருக்குள் மேன்மைபாராட்டுவார்கள்.
	<br /><br />
	3. யூதா மனுஷரோடும், எருசலேமியரோடும் கர்த்தர் சொல்லுகிறது என்னவென்றால்: நீங்கள் முள்ளுகளுக்குள்ளே விதையாதிருங்கள், உங்கள் தரிசுநிலத்தைப் பண்படுத்துங்கள்.
	<br /><br />
	4. யூதா மனுஷரே, எருசலேமின் குடிகளே, உங்கள் கிரியைகளுடைய பொல்லாப்பினிமித்தம் என் உக்கிரம் அக்கினியைப்போல எழும்பி, அவிப்பார் இல்லாமல் எரியாதபடிக்கு நீங்கள் கர்த்தருக்கென்று உங்களை விருத்தசேதனம்பண்ணி, உங்கள் இருதயத்தின் நுனித்தோலை நீக்கிப்போடுங்கள்.
	<br /><br />
	5. தேசத்தில் எக்காளம் ஊதுங்கள் என்று சொல்லி, யூதாவில் அறிவித்து, எருசலேமில் கேட்கப்பண்ணுங்கள்; நாம் அரணான பட்டணங்களுக்கு உட்படும்படிக்குச் சேருங்கள் என்று சொல்லி, உரத்த சத்தமாய்க் கூப்பிடுங்கள்.
	<br /><br />
	6. சீயோனுக்கு நேரே கொடியேற்றுங்கள்; கூடுங்கள், நிற்காதிருங்கள்; நான் வடக்கேயிருந்து பொல்லாப்பையும், மகா சங்காரத்தையும் வரப்பண்ணுகிறேன்.
	<br /><br />
	7. உன் தேசத்தைப் பாழாக்கி விடும்படிக்குச் சிங்கம் தன் புதரிலிருந்து எழும்பி, ஜாதிகளைச் சங்கரிக்கிறவன் தன் ஸ்தானத்திலிருந்து புறப்பட்டு வருகிறான்; உன் பட்டணங்கள் குடியிராதபடி அழிக்கப்படும் என்கிறார்.
	<br /><br />
	8. இதினிமித்தம் இரட்டைக் கட்டிக்கொள்ளுங்கள்; புலம்பி அலறுங்கள்; கர்த்தருடைய உக்கிரகோபம் நம்மைவிட்டுத் திரும்பவில்லையே.
	<br /><br />
	9. அந்நாளிலே ராஜாவின் இருதயமும், பிரபுக்களின் இருதயமும் மடிந்துபோம்; ஆசாரியர்கள் திடுக்கிட்டு, தீர்க்கதரிசிகள் திகைப்பார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. அப்பொழுது நான்: ஆ! கர்த்தராகிய ஆண்டவரே, உங்களுக்குச் சமாதானமிருக்கும் என்று சொன்னதினால், மெய்யாகவே இந்த ஜனத்துக்கும் எருசலேமுக்கும் மிகுதியான மோசத்தை வரப்பண்ணினீர்; பட்டயம் பிராணன்மட்டும் எட்டுகிறதே என்றேன்.
	<br /><br />
	11. வனாந்தரத்திலுள்ள உயர்நிலங்களிலிருந்து, ஒரு தீக்காற்று என் ஜனமாகிய குமாரத்திக்கு நேராக அடிக்கும் என்று அக்காலத்திலே இந்த ஜனத்தோடும் எருசலேமோடும் சொல்லப்படும்; அது தூற்றவுமாட்டாது சுத்திகரிக்கவுமாட்டாது.
	<br /><br />
	12. இதைப்பார்க்கிலும் பலமான காற்று என் காரியமாய் வரும்; இப்பொழுது நானும் அவர்களோடே நியாயம் பேசுவேன்.
	<br /><br />
	13. இதோ, மேகங்களைப்போல எழும்பிவருகிறான்; அவனுடைய இரதங்கள் பெருங்காற்றைப்போலிருக்கிறது; அவன் குதிரைகள் கழுகுகளிலும் வேகமானவைகள்; நமக்கு ஐயோ! நாம் பாழாக்கப்படுகிறோமே.
	<br /><br />
	14. எருசலேமே, நீ இரட்சிக்கப்படும்படிக்கு உன் இருதயத்தைப் பொல்லாப்பறக் கழுவு; எந்தமட்டும் அக்கிரம நினைவுகள் உன் உள்ளத்திலே தங்கும்.
	<br /><br />
	15. தாணிலிருந்து ஒரு சத்தம் வந்து, செய்தியை அறிவிக்கிறது; எப்பிராயீமின் மலையிலிருந்து வந்து, தீங்கைப் பிரசித்தம்பண்ணுகிறது.
	<br /><br />
	16. ஜாதிகளுக்கு அதை நீங்கள் பிரஸ்தாபம்பண்ணுங்கள்; இதோ, காவற்சேவகர் தூரதேசத்திலிருந்து வந்து, யூதாவுடைய பட்டணங்களுக்கு விரோதமாய் உரத்தசத்தமிடுவார்கள் என்று எருசலேமுக்குக் கூறுங்கள்.
	<br /><br />
	17. அதற்கு விரோதமாய் அவர்கள் வயல்வெளிகளின் காவற்காரரைப்போலச் சுற்றிலுமிருப்பார்கள்; அது எனக்கு விரோதமாய்க் கலகஞ்செய்தது என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. உன் நடக்கையும் உன் கிரியைகளுமே இவைகளை உனக்கு நேரிடப்பண்ணின; இது இத்தனை கசப்பாயிருந்து, உன் இருதயமட்டும் எட்டுகிறதற்குக் காரணம் உன் பொல்லாப்புத்தானே.
	<br /><br />
	19. என் குடல்கள், என் குடல்களே நோகிறது; என் உள்ளம் வேதனைப்படுகிறது, என் இருதயம் என்னில் கதறுகிறது; நான் பேசாமல் அமர்ந்திருக்கக்கூடாது; என் ஆத்துமாவே, எக்காளத்தின் சத்தத்தையும், யுத்தத்தின் ஆர்ப்பரிப்பையும் கேட்டாயே.
	<br /><br />
	20. நாசத்துக்குமேல் நாசம் வருகிறதாகக் கூறப்படுகிறது; தேசமெல்லாம் பாழாகிறது; அசுப்பிலே என் கூடாரங்களும், ஒரு நிமிஷத்திலே என் திரைகளும் பாழாக்கப்படுகிறது.
	<br /><br />
	21. நான் எதுவரைக்கும் கொடியைக்கண்டு, எக்காளத்தின் சத்தத்தைக் கேட்பேன்.
	<br /><br />
	22. என் ஜனங்களோ மதியற்றவர்கள், என்னை அவர்கள் அறியாதிருக்கிறார்கள்; அவர்கள் பைத்தியமுள்ள பிள்ளைகள், அவர்களுக்கு உணர்வே இல்லை; பொல்லாப்புச்செய்ய அவர்கள் அறிவாளிகள், நன்மைசெய்யவோ அவர்கள் அறிவில்லாதவர்கள்.
	<br /><br />
	23. பூமியைப் பார்த்தேன், அது ஒழுங்கின்மையும் வெறுமையுமாயிருந்தது; வானங்களைப் பார்த்தேன், அவைகளுக்கு ஒளியில்லாதிருந்தது.
	<br /><br />
	24. பர்வதங்களைப் பார்த்தேன், அவைகள் அதிர்ந்தன; எல்லாக் குன்றுகளும் அசைந்தன.
	<br /><br />
	25. பின்னும் நான் பார்க்கும்போது, மனுஷனில்லை; ஆகாசத்துப் பறவைகளெல்லாம் பறந்துபோயின.
	<br /><br />
	26. பின்னும் நான் பார்க்கும்போது, கர்த்தராலும், அவருடைய உக்கிரகோபத்தாலும் பயிர்நிலம் வனாந்தரமாயிற்று; அதின் பட்டணங்களெல்லாம் இடிந்துபோயின.
	<br /><br />
	27. தேசமெல்லாம் பாழாய்ப்போம்; ஆகிலும் சர்வசங்காரம் செய்யேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	28. இதினிமித்தம் பூமி புலம்பும், உயர இருக்கிற வானங்கள் கறுத்துப்போம்; நான் அதைச் சொன்னேன், அதை நிர்ணயம் பண்ணினேன்; நான் மனஸ்தாபப்படுவதும் இல்லை; நான் அதைவிட்டுத் திரும்புவதும் இல்லை.
	<br /><br />
	29. குதிரைவீரரும் வில்வீரரும் இடும் சத்தத்தினாலே சகல ஊராரும் ஓடி, அடர்த்தியான காடுகளில் புகுந்து, கன்மலைகளிலும் ஏறுவார்கள்; ஒரு மனுஷனும் அவைகளிலே குடியிராதபடி எல்லா ஊர்களும் விடப்பட்டிருக்கும்.
	<br /><br />
	30. பாழாய்ப்போன நீ இப்பொழுது என்ன செய்வாய்? நீ இரத்தாம்பரம் உடுத்தாலும், பொன்னாபரணங்களால் உன்னைச் சிங்காரித்தாலும், உன் கண்களில் மையிட்டுக்கொண்டாலும், வீணாய் உன்னை அழகுபடுத்துவாய்; சோரநாயகர் உன்னை அசட்டைபண்ணி, உன் பிராணனை வாங்கத் தேடுவார்கள்.
	<br /><br />
	31. கர்ப்பவேதனைப்படுகிறவளின் சத்தமாகவும், முதல்விசை பிள்ளை பெறுகிறவளின் வியாகுலமாகவும், சீயோன் குமாரத்தியின் சத்தத்தைக் கேட்கிறேன்; அவள் பெருமூச்சுவிட்டு, தன் கைகளை விரித்து: ஐயோ! கொலைபாதகர்களாலே என் ஆத்துமா சோர்ந்துபோகிறதே என்கிறாள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah4