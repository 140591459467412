import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 17 </title>
	<meta
          name="description"
          content="Acts 17 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 17 |
          Acts of Apostles Chapter-17 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர்கள் அம்பிபோலி பட்டணத்தையும் அப்பொலோனியா பட்டணத்தையும் கடந்து, தெசலோனிக்கே பட்டணத்துக்கு வந்தார்கள்; அங்கே யூதருக்கு ஒரு ஜெபஆலயம் இருந்தது.
	<br /><br />
	2. பவுல் தன் வழக்கத்தின்படியே அவர்களிடத்தில் போய், மூன்று ஓய்வுநாட்களில் வேதவாக்கியங்களின் நியாயங்களை எடுத்து அவர்களுடனே சம்பாஷித்து,
	<br /><br />
	3. கிறிஸ்து பாடுபடவும் மரித்தோரிலிருந்து எழுந்திருக்கவும் வேண்டியதென்றும், நான் உங்களுக்கு அறிவிக்கிற இந்த இயேசுவே கிறிஸ்து என்றும் காண்பித்து, திருஷ்டாந்தப்படுத்தினான்.
	<br /><br />
	4. அவர்களில் சிலரும், பக்தியுள்ள கிரேக்கரில் திரளான ஜனங்களும், கனம்பொருந்திய ஸ்திரீகளில் அநேகரும் விசுவாசித்து, பவுலையும் சீலாவையும் சேர்ந்து கொண்டார்கள்.
	<br /><br />
	5. விசுவாசியாத யூதர்கள் வைராக்கியங்கொண்டு வீணராகிய சில பொல்லாதவர்களைச் சேர்த்துக்கொண்டு, கூட்டங்கூடி, பட்டணத்தில் அமளியுண்டாக்கி, யாசோனுடைய வீட்டை வளைந்துகொண்டு, அவர்களைப் பட்டணத்தாருக்கு முன்பாக இழுத்துக்கொண்டுவர வகைதேடினார்கள்.
	<br /><br />
	6. அவர்களைக் காணாமல், யாசோனையும் சில சகோதரரையும் பட்டணத்து அதிகாரிகளிடத்தில் இழுத்துக்கொண்டுவந்து: உலகத்தைக் கலக்குகிறவர்கள் இங்கேயும் வந்திருக்கிறார்கள்.
	<br /><br />
	7. இவர்களை யாசோன் ஏற்றுக்கொண்டான். இவர்களெல்லாரும் இயேசு என்னும் வேறொருவனை ராஜா என்று சொல்லி, இராயனுடைய கட்டளைகளுக்கு விரோதமாகச் செய்கிறார்களென்று கூக்குரலிட்டு,
	<br /><br />
	8. இவைகளைக் கேட்டுக்கொண்டிருந்த ஜனங்களையும் பட்டணத்து அதிகாரிகளையும் கலங்கப்பண்ணினார்கள்.
	<br /><br />
	9. பின்பு அவர்கள் யாசோனிடத்திலும் மற்றவர்களிடத்திலும் ஜாமீன் வாங்கிக்கொண்டு, அவர்களை விட்டுவிட்டார்கள்.
	<br /><br />
	10. உடனே சகோதரர் இராத்திரிகாலத்திலே பவுலையும் சீலாவையும் பெரோயா பட்டணத்துக்கு அனுப்பிவிட்டார்கள்; அவர்கள் அங்கே சேர்ந்து, யூதருடைய ஜெபஆலயத்திற்குப் போனார்கள்.
	<br /><br />
	11. அந்தப் பட்டணத்தார் மனோவாஞ்சையாய் வசனத்தை ஏற்றுக்கொண்டு, காரியங்கள் இப்படியிருக்கிறதா என்று தினந்தோறும் வேதவாக்கியங்களை ஆராய்ந்துபார்த்ததினால், தெசலோனிக்கேயில் உள்ளவர்களைப்பார்க்கிலும் நற்குணசாலிகளாயிருந்தார்கள்.
	<br /><br />
	12. அதனால் அவர்களில் அநேகம்பேரும் கனம்பொருந்திய கிரேக்கரில் அநேக ஸ்திரீகளும் புருஷர்களும் விசுவாசித்தார்கள்.
	<br /><br />
	13. பெரோயாவிலும் தேவவசனம் பவுலினால் அறிவிக்கப்படுகிறதென்று தெசலோனிக்கேயரான யூதர்கள் அறிந்தபோது, அங்கேயும் வந்து, ஜனங்களைக் கிளப்பிவிட்டார்கள்.
	<br /><br />
	14. உடனே சகோதரர் பவுலைச் சமுத்திரவழியாய்ப் போக அனுப்பிவிட்டார்கள். சீலாவும் தீமோத்தேயும் அங்கே தங்கியிருந்தார்கள்.
	<br /><br />
	15. பவுலை வழிநடத்தினவர்கள் அவனை அத்தேனே பட்டணம்வரைக்கும் அழைத்துக்கொண்டுபோய்; அங்கே சீலாவும் தீமோத்தேயும் அதிசீக்கிரமாகத் தன்னிடத்திற்கு வரும்படி அவர்களுக்குச் சொல்லக் கட்டளை பெற்றுக்கொண்டு புறப்பட்டுப்போனார்கள்.
	<br /><br />
	16. அத்தேனே பட்டணத்தில் பவுல் அவர்களுக்காகக் காத்துக்கொண்டிருக்கையில், அந்தப் பட்டணம் விக்கிரகங்களால் நிறைந்திருக்கிறதைக் கண்டு, தன் ஆவியில் மிகுந்த வைராக்கியமடைந்து,
	<br /><br />
	17. ஜெபஆலயத்தில் யூதரோடும், பக்தியுள்ளவர்களோடும், சந்தைவெளியில் எதிர்ப்பட்டவர்களோடும் தினந்தோறும் சம்பாஷணைபண்ணினான்.
	<br /><br />
	18. அப்பொழுது எப்பிக்கூரரும் ஸ்தோயிக்கருமான ஞானிகளில் சிலர் அவனுடனே வாக்குவாதம்பண்ணினார்கள். சிலர்: இந்த வாயாடி என்ன பேசப்போகிறான் என்றார்கள். சிலர்: இவன் அந்நிய தேவதைகளை அறிவிக்கிறவனாகக் காண்கிறது என்றார்கள். அவன் இயேசுவையும் உயிர்த்தெழுதலையும் அவர்களுக்குப் பிரசங்கித்தபடியினாலே அப்படிச் சொன்னார்கள்.
	<br /><br />
	19. அவர்கள் அவனை மார்ஸ் மேடைக்கு அழைத்துக்கொண்டுபோய்: நீ சொல்லுகிற புதிதான உபதேசம் இன்னதென்று நாங்கள் அறியலாமா?
	<br /><br />
	20. நூதனமான காரியங்களை எங்கள் காதுகள் கேட்கப்பண்ணுகிறாய்; அவைகளின் கருத்து இன்னதென்று அறிய மனதாயிருக்கிறோம் என்றார்கள்.
	<br /><br />
	21. அந்த அத்தேனே பட்டணத்தாரெல்லாரும், அங்கே தங்குகிற அந்நியரும், நவமான காரியங்களைச் சொல்லுகிறதிலும் கேட்கிறதிலுமேயொழிய வேறொன்றிலும் பொழுதுபோக்குகிறதில்லை.
	<br /><br />
	22. அப்பொழுது பவுல் மார்ஸ் மேடையின் நடுவிலே நின்று: அத்தேனரே, எந்த விஷயத்திலும் நீங்கள் மிகுந்த தேவதாபக்தியுள்ளவர்களென்று காண்கிறேன்.
	<br /><br />
	23. எப்படியென்றால், நான் சுற்றித்திரிந்து, உங்கள் ஆராதனைக்குரியவைகளைக் கவனித்துப் பார்த்தபொழுது, அறியப்படாத தேவனுக்கு என்று எழுதியிருக்கிற ஒரு பலிபீடத்தைக் கண்டேன்; நீங்கள் அறியாமல் ஆராதிக்கிற அவரையே நான் உங்களுக்கு அறிவிக்கிறேன்.
	<br /><br />
	24. உலகத்தையும் அதிலுள்ள யாவற்றையும் உண்டாக்கின தேவனானவர் வானத்திற்கும் பூமிக்கும் ஆண்டவராயிருக்கிறபடியால் கைகளினால் கட்டப்பட்ட கோவில்களில் அவர் வாசம்பண்ணுகிறதில்லை.
	<br /><br />
	25. எல்லாருக்கும் ஜீவனையும் சுவாசத்தையும் சகலத்தையும் கொடுக்கிற அவர், தமக்கு யாதொன்று தேவையானதுபோல, மனுஷர் கைகளால் பணிவிடைகொள்ளுகிறதுமில்லை.
	<br /><br />
	26. மனுஷஜாதியான சகல ஜனங்களையும் அவர் ஒரே இரத்தத்தினாலே தோன்றப்பண்ணி, பூமியின்மீதெங்கும் குடியிருக்கச்செய்து, முன் தீர்மானிக்கப்பட்ட காலங்களையும் அவர்கள் குடியிருப்பின் எல்லைகளையும் குறித்திருக்கிறார்;
	<br /><br />
	27. கர்த்தராகிய தம்மை அவர்கள் தடவியாகிலும் கண்டுபிடிக்கத்தக்கதாகத் தம்மைத் தேடும்படிக்கு அப்படிச் செய்தார்; அவர் நம்மில் ஒருவருக்கும் தூரமானவரல்லவே.
	<br /><br />
	28. ஏனெனில் அவருக்குள் நாம் பிழைக்கிறோம், அசைகிறோம், இருக்கிறோம்; அப்படியே உங்கள் புலவர்களிலும் சிலர், நாம் அவருடைய சந்ததியார் என்று சொல்லியிருக்கிறார்கள்.
	<br /><br />
	29. நாம் தேவனுடைய சந்ததியாராயிருக்க, மனுஷருடைய சித்திரவேலையினாலும் யுக்தியினாலும் உருவாக்கின பொன், வெள்ளி, கல் இவைகளுக்குத் தெய்வம் ஒப்பாயிருக்குமென்று நாம் நினைக்கலாகாது.
	<br /><br />
	30. அறியாமையுள்ள காலங்களைத் தேவன் காணாதவர்போலிருந்தார்; இப்பொழுதோ மனந்திரும்பவேண்டுமென்று எங்குமுள்ள மனுஷரெல்லாருக்கும் கட்டளையிடுகிறார்.
	<br /><br />
	31. மேலும் ஒரு நாளைக் குறித்திருக்கிறார்; அதிலே அவர் தாம் நியமித்த மனுஷனைக்கொண்டு, பூலோகத்தை நீதியாய் நியாயந்தீர்ப்பார்; அந்த மனுஷனை மரித்தோரிலிருந்து எழுப்பினதினாலே அதின் நிச்சயத்தை எல்லாருக்கும் விளங்கப்பண்ணினார் என்றான்.
	<br /><br />
	32. மரித்தோரின் உயிர்த்தெழுதலைக்குறித்து அவர்கள் கேட்டபொழுது, சிலர் இகழ்ந்தார்கள். சிலர்: நீ சொல்லுகிறதை இன்னொருவேளை கேட்போம் என்றார்கள்.
	<br /><br />
	33. இப்படியிருக்க, பவுல் அவர்களை விட்டுப் போய்விட்டான்.
	<br /><br />
	34. சிலர் அவனைப் பற்றிக்கொண்டு, விசுவாசிகளானார்கள். அவர்களில் மார்ஸ்மேடையின் நியாயாதிபதிகளில் ஒருவனாகிய தியொனீசியு என்பவனும், தாமரி என்னும் பேருள்ள ஒரு ஸ்திரீயும், இவர்களுடனே வேறு சிலரும் இருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts17