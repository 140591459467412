import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OnePeter5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title> Final Greetings | 1st Peter - 5 in Tamil Bible Verse | 1 பேதுரு - 5 </title>
	<meta
          name="description"
          content="Final Greetings | 1 Peter in Tamil Bible Verse | 1-பேதுரு அதிகாரம் - 5 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h3>
* இறுதி வாழ்த்துக்கள் *
			</h3>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. உங்களிலுள்ள மூப்பருக்கு உடன்மூப்பனும், கிறிஸ்துவின் பாடுகளுக்குச் சாட்சியும், இனி வெளிப்படும் மகிமைக்குப் பங்காளியுமாயிருக்கிற நான் புத்திசொல்லுகிறதென்னவென்றால்:
	<br /><br />
	2. உங்களிடத்திலுள்ள தேவனுடைய மந்தையை நீங்கள் மேய்த்து, கட்டாயமாய் அல்ல, மனப்பூர்வமாயும், அவலட்சணமான ஆதாயத்திற்காக அல்ல, உற்சாக மனதோடும்,
	<br /><br />
	3. சுதந்தரத்தை இறுமாப்பாய் ஆளுகிறவர்களாக அல்ல, மந்தைக்கு மாதிரிகளாகவும், கண்காணிப்புச் செய்யுங்கள்.
	<br /><br />
	4. அப்படிச் செய்தால் பிரதான மேய்ப்பர் வெளிப்படும்போது மகிமையுள்ள வாடாத கிரீடத்தைப் பெறுவீர்கள்.
	<br /><br />
	5. அந்தப்படி, இளைஞரே, மூப்பருக்குக் கீழ்ப்படியுங்கள். நீங்களெல்லாரும் ஒருவருக்கொருவர் கீழ்ப்படிந்து, மனத்தாழ்மையை அணிந்துகொள்ளுங்கள்; பெருமையுள்ளவர்களுக்குத் தேவன் எதிர்த்து நிற்கிறார், தாழ்மையுள்ளவர்களுக்கோ கிருபை அளிக்கிறார்.
	<br /><br />
	6. ஆகையால், ஏற்றகாலத்திலே தேவன் உங்களை உயர்த்தும்படிக்கு, அவருடைய பலத்த கைக்குள் அடங்கியிருங்கள்.
	<br /><br />
	7. அவர் உங்களை விசாரிக்கிறவரானபடியால், உங்கள் கவலைகளையெல்லாம் அவர்மேல் வைத்துவிடுங்கள்.
	<br /><br />
	8. தெளிந்த புத்தியுள்ளவர்களாயிருங்கள், விழித்திருங்கள்; ஏனெனில், உங்கள் எதிராளியாகிய பிசாசானவன் கெர்ச்சிக்கிற சிங்கம்போல் எவனை விழுங்கலாமோ என்று வகைதேடிச் சுற்றித்திரிகிறான்.
	<br /><br />
	9. விசுவாசத்தில் உறுதியாயிருந்து, அவனுக்கு எதிர்த்து நில்லுங்கள்; உலகத்திலுள்ள உங்கள் சகோதரரிடத்திலே அப்படிப்பட்ட பாடுகள் நிறைவேறிவருகிறதென்று அறிந்திருக்கிறீர்களே.
	<br /><br />
	10. கிறிஸ்து இயேசுவுக்குள் நம்மைத் தமது நித்திய மகிமைக்கு அழைத்தவராயிருக்கிற சகல கிருபையும் பொருந்திய தேவன்தாமே கொஞ்சக்காலம் பாடநுபவிக்கிற உங்களைச் சீர்ப்படுத்தி, ஸ்திரப்படுத்தி, பலப்படுத்தி, நிலைநிறுத்துவாராக;
	<br /><br />
	11. அவருக்கு மகிமையும் வல்லமையும் சதாகாலங்களிலும் உண்டாயிருப்பதாக. ஆமென்.
	<br /><br />
	12. உங்களுக்குப் புத்திசொல்லும்படிக்கும், நீங்கள் நிலைகொண்டு நிற்கிற கிருபை தேவனுடைய மெய்யான கிருபைதானென்று சாட்சியிடும்படிக்கும், நான் சுருக்கமாய் உங்களுக்கு எழுதி, எனக்குத் தோன்றுகிறபடி உண்மையுள்ள சகோதரனாகிய சில்வானுவின் கையிலே கொடுத்து அனுப்பியிருக்கிறேன்.
	<br /><br />
	13. உங்களுடனேகூடத் தெரிந்துகொள்ளப்பட்டிருக்கிற பாபிலோனிலுள்ள சபையும், என் குமாரனாகிய மாற்கும் உங்களுக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	14. ஒருவரையொருவர் அன்பின் முத்தத்தோடே வாழ்த்துதல் செய்யுங்கள். கிறிஸ்து இயேசுவுக்குள்ளான உங்கள் அனைவருக்கும் சமாதானமுண்டாவதாக. ஆமென்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 1 பேதுரு 5 தலைவர்களுக்கு ஒரு சில அறிவுரைகளுடன் முடிவடைகிறது, கடவுள் ஒப்படைத்ததை நல்ல மேய்ப்பர்களாக இருக்க நினைவூட்டுகிறது. மனத்தாழ்மையுடன் இருப்பதற்கும், கடவுள் நம்மீது வைத்திருக்கும் மிகுந்த அன்பு மற்றும் அக்கறையின் காரணமாக எல்லா கவலைகளையும் அவர் மீது போடுவது. நாம் சுயக் கட்டுப்பாட்டுடன் இருக்கவும், எதிரி விழுங்கப் பார்க்கிறார் என்பதை அறிந்து கொள்ளவும் ஊக்குவிக்கப்படுகிறோம்; எனினும் இதற்கு பரிகாரம் எதிர்ப்பதும் நம்பிக்கையில் உறுதியாக நிற்பதும் ஆகும். எல்லாக் கிருபையின் தேவன் எல்லாவற்றையும் மீட்டெடுத்து, நம்மை பலமாகவும், உறுதியாகவும், உறுதியுடனும் ஆக்குவார் என்பதை நினைவூட்டி இந்த கடிதத்தை பேதுரு முடிக்கிறார்.
<br /><br />
		<br /><br />
நீங்கள் பார்ப்பது போல், இந்த புத்தகம் விசுவாசிகளுக்கு குறிப்பாக துன்பங்களுக்கு மத்தியில் நடைமுறை ஆலோசனைகள் மற்றும் நுண்ணறிவுகள் நிறைந்துள்ளது. நீங்கள் 1 பேதுருவின் புத்தகத்தை வாசிக்கும்போதும் படிக்கும்போதும் நீங்கள் ஆசீர்வதிக்கப்பட்டவர்களாகவும் ஊக்கமளிப்பவர்களாகவும் இருப்பீர்களாக. *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OnePeter5