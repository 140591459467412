import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneJohn5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title> Overcoming the World | 1 John -5 | Tamil Bible | 1 யோவான் - 5 </title>
	<meta
          name="description"
          content="Overcoming the World | 1 -John in Tamil Bible Verse | 1-யோவான்அதிகாரம் - 5 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible "
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* உலகத்தை வெல்வது *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இயேசுவானவரே கிறிஸ்து என்று விசுவாசிக்கிற எவனும் தேவனால் பிறந்திருக்கிறான்; பிறப்பித்தவரிடத்தில் அன்புகூருகிற எவனும் அவரால் பிறப்பிக்கப்பட்டவனிடத்திலும் அன்புகூருகிறான்.
	<br /><br />
	2. நாம் தேவனிடத்தில் அன்புகூர்ந்து அவருடைய கற்பனைகளைக் கைக்கொள்ளும்போது, தேவனுடைய பிள்ளைகளிடத்தில் அன்புகூருகிறோமென்று அறிந்துகொள்ளுகிறோம்.
	<br /><br />
	3. நாம் தேவனுடைய கற்பனைகளைக் கைக்கொள்வதே அவரிடத்தில் அன்புகூருவதாம்; அவருடைய கற்பனைகள் பாரமானவைகளுமல்ல.
	<br /><br />
	4. தேவனால் பிறப்பதெல்லாம் உலகத்தை ஜெயிக்கும்; நம்முடைய விசுவாசமே உலகத்தை ஜெயிக்கிற ஜெயம்.
	<br /><br />
	5. இயேசுவானவர் தேவனுடைய குமாரனென்று விசுவாசிக்கிறவனேயன்றி உலகத்தை ஜெயிக்கிறவன் யார்?
	<br /><br />
	6. இயேசுகிறிஸ்துவாகிய இவரே ஜலத்தினாலும் இரத்தத்தினாலும் வந்தவர்; ஜலத்தினாலே மாத்திரமல்ல, ஜலத்தினாலும் இரத்தத்தினாலும் வந்தவர். ஆவியானவர் சத்தியமாகையால், ஆவியானவரே சாட்சிகொடுக்கிறவர்.
	<br /><br />
	7. [பரலோகத்திலே சாட்சியிடுகிறவர்கள் மூவர், பிதா, வார்த்தை, பரிசுத்தஆவி என்பவர்களே, இம்மூவரும் ஒன்றாயிருக்கிறார்கள்;
	<br /><br />
	8. பூலோகத்திலே] சாட்சியிடுகிறவைகள் மூன்று, ஆவி, ஜலம், இரத்தம் என்பவைகளே, இம்மூன்றும் ஒருமைப்பட்டிருக்கிறது.
	<br /><br />
	9. நாம் மனுஷருடைய சாட்சியை ஏற்றுக்கொண்டால், அதைப்பார்க்கிலும் தேவனுடைய சாட்சி அதிகமாயிருக்கிறது; தேவன் தமது குமாரனைக்குறித்துக் கொடுத்த சாட்சி இதுவே.
	<br /><br />
	10. தேவனுடைய குமாரனிடத்தில் விசுவாசமாயிருக்கிறவன் அந்தச் சாட்சியைத் தனக்குள்ளே கொண்டிருக்கிறான்; தேவனை விசுவாசியாதவனோ, தேவன் தம்முடைய குமாரனைக்குறித்துக் கொடுத்த சாட்சியை விசுவாசியாததினால், அவரைப் பொய்யராக்குகிறான்.
	<br /><br />
	11. தேவன் நமக்கு நித்தியஜீவனைத் தந்திருக்கிறார், அந்த ஜீவன் அவருடைய குமாரனில் இருக்கிறதென்பதே அந்தச் சாட்சியாம்.
	<br /><br />
	12. குமாரனை உடையவன் ஜீவனை உடையவன், தேவனுடைய குமாரன் இல்லாதவன் ஜீவன் இல்லாதவன்.
	<br /><br />
	13. உங்களுக்கு நித்தியஜீவன் உண்டென்று நீங்கள் அறியவும், தேவகுமாரனுடைய நாமத்தின்மேல் நீங்கள் விசுவாசமாயிருக்கவும், தேவகுமாரனுடைய நாமத்தின்மேல் விசுவாசமாயிருக்கிற உங்களுக்கு இவைகளை எழுதியிருக்கிறேன்.
	<br /><br />
	14. நாம் எதையாகிலும் அவருடைய சித்தத்தின்படி கேட்டால், அவர் நமக்குச் செவிகொடுக்கிறாரென்பதே அவரைப் பற்றி நாம் கொண்டிருக்கிற தைரியம்.
	<br /><br />
	15. நாம் எதைக் கேட்டாலும், அவர் நமக்குச் செவிகொடுக்கிறாரென்று நாம் அறிந்திருந்தோமானால், அவரிடத்தில் நாம் கேட்டவைகளைப் பெற்றுக்கொண்டோமென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	16. மரணத்துக்கு ஏதுவல்லாத பாவத்தைத் தன் சகோதரன் செய்ய ஒருவன் கண்டால், அவன் வேண்டுதல்செய்யக்கடவன், அப்பொழுது அவனுக்கு ஜீவனைக் கொடுப்பார்; யாருக்கென்றால், மரணத்துக்கு ஏதுவல்லாத பாவத்தைச் செய்தவர்களுக்கே; மரணத்துக்கு ஏதுவான பாவமுண்டு, அதைக்குறித்து வேண்டுதல்செய்ய நான் சொல்லேன்.
	<br /><br />
	17. அநீதியெல்லாம் பாவந்தான்; என்றாலும் மரணத்துக்கு ஏதுவல்லாத பாவமுமுண்டு.
	<br /><br />
	18. தேவனால் பிறந்த எவனும் பாவஞ்செய்யானென்று அறிந்திருக்கிறோம்; தேவனால் பிறந்தவன் தன்னைக் காக்கிறான், பொல்லாங்கன் அவனைத் தொடான்.
	<br /><br />
	19. நாம் தேவனால் உண்டாயிருக்கிறோமென்றும், உலகமுழுவதும் பொல்லாங்கனுக்குள் கிடக்கிறதென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	20. அன்றியும், நாம் சத்தியமுள்ளவரை அறிந்துகொள்வதற்குத் தேவனுடைய குமாரன் வந்து நமக்குப் புத்தியைத் தந்திருக்கிறாரென்றும் அறிவோம்; அவருடைய குமாரனாகிய இயேசுகிறிஸ்து என்னப்பட்ட சத்தியமுள்ளவருக்குள்ளும் இருக்கிறோம்; இவரே மெய்யான தேவனும் நித்தியஜீவனுமாயிருக்கிறார்.
	<br /><br />
	21. பிள்ளைகளே, நீங்கள் விக்கிரகங்களுக்கு விலகி, உங்களைக் காத்துக்கொள்வீர்களாக. ஆமென்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 1 யோவான் 5, கடவுளால் பிறந்த ஒவ்வொருவரும் உலகத்தை ஜெயிக்கிறார்கள் என்பதை விளக்குவதன் மூலம் தொடங்குகிறது. இந்த வெற்றி விசுவாசத்தினால் வருகிறது. இந்த அத்தியாயம் கிறிஸ்துவில் நித்திய ஜீவனைப் பற்றிய வாக்குறுதியை நமக்கு உறுதிப்படுத்துகிறது. கடவுளுக்கு முன்பாக நமக்கு நம்பிக்கை இருக்கிறது என்பதையும், அவருடைய சித்தத்தின்படி நாம் அவரிடம் எதையும் கேட்க முடியும் என்பதையும், அவர் நமக்குக் கேட்டு பதிலளிப்பார் என்பதையும் நினைவூட்டுகிறது. நம்மில் வாழும் குமாரன் நமக்குப் புரியவைக்கிறார், அதனால் உண்மை என்ன என்பதை நாம் அறியலாம் என்பதை நினைவுபடுத்துவதன் மூலம் யோவான் முடிக்கிறார்.
<br /><br />
நீங்கள் பார்க்க முடியும் என, இது ஒரு அற்புதமான புத்தகம் மற்றும் இயேசுவின் அன்பு, கீழ்ப்படிதல் மற்றும் வெற்றியின் இந்த அற்புதமான செய்தியைப் படிக்கும், புரிந்துகொண்டு அனுபவிக்கும் அனைவரையும் ஆசீர்வதிக்கும். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OneJohn5