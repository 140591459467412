import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 5 </title>
	<meta
          name="description"
          content="John Chapter 5 | யோவான் அதிகாரம் 5 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவைகளுக்குப்பின்பு யூதருடைய பண்டிகை ஒன்று வந்தது; அப்பொழுது இயேசு எருசலேமுக்குப் போனார்.
	<br /><br />
	2. எபிரெய பாஷையிலே பெதஸ்தா என்னப்பட்ட ஒரு குளம் எருசலேமில் ஆட்டுவாசலினருகே இருக்கிறது, அதற்கு ஐந்து மண்டபங்களுண்டு.
	<br /><br />
	3. அவைகளிலே குருடர், சப்பாணிகள், சூம்பின உறுப்புடையவர்கள் முதலான வியாதிக்காரர் அநேகர் படுத்திருந்து, தண்ணீர் எப்பொழுது கலங்கும் என்று காத்துக்கொண்டிருப்பார்கள்.
	<br /><br />
	4. ஏனெனில் சில சமயங்களிலே தேவதூதன் ஒருவன் அந்தக் குளத்தில் இறங்கி, தண்ணீரைக் கலக்குவான்; தண்ணீர் கலங்கினபின்பு யார் முந்தி அதில் இறங்குவானோ அவன் எப்பேர்ப்பட்ட வியாதிஸ்தனாயிருந்தாலும் சொஸ்தமாவான்.
	<br /><br />
	5. முப்பத்தெட்டு வருஷம் வியாதிகொண்டிருந்த ஒரு மனுஷன் அங்கே இருந்தான்.
	<br /><br />
	6. படுத்திருந்த அவனை இயேசு கண்டு, அவன் வெகுகாலமாய் வியாதிஸ்தனென்று அறிந்து, அவனை நோக்கி: சொஸ்தமாகவேண்டுமென்று விரும்புகிறாயா என்று கேட்டார்.
	<br /><br />
	7. அதற்கு வியாதிஸ்தன்: ஆண்டவரே, தண்ணீர் கலக்கப்படும்போது என்னைக் குளத்தில் கொண்டுபோய் விடுகிறதற்கு ஒருவருமில்லை, நான் போகிறதற்குள்ளே வேறொருவன் எனக்கு முந்தி இறங்கிவிடுகிறான் என்றான்.
	<br /><br />
	8. இயேசு அவனை நோக்கி: எழுந்திரு, உன் படுக்கையை எடுத்துக்கொண்டு நட என்றார்.
	<br /><br />
	9. உடனே அந்த மனுஷன் சொஸ்தமாகி, தன் படுக்கையை எடுத்துக்கொண்டு, நடந்துபோனான். அந்த நாள் ஓய்வுநாளாயிருந்தது.
	<br /><br />
	10. ஆதலால் யூதர்கள் குணமாக்கப்பட்டவனை நோக்கி: இது ஓய்வுநாளாயிருக்கிறதே, படுக்கையை எடுத்துக்கொண்டு போகிறது உனக்கு நியாயமல்ல என்றார்கள்.
	<br /><br />
	11. அவன் அவர்களுக்குப் பிரதியுத்தரமாக: என்னைச் சொஸ்தமாக்கினவர், உன் படுக்கையை எடுத்துக்கொண்டு நடவென்று எனக்குச் சொன்னார் என்றான்.
	<br /><br />
	12. அதற்கு அவர்கள்: உன் படுக்கையை எடுத்துக்கொண்டு நடவென்று உன்னுடனே சொன்ன மனுஷன் யார் என்று அவனிடத்தில் கேட்டார்கள்.
	<br /><br />
	13. சொஸ்தமாக்கப்பட்டவன் அவர் இன்னாரென்று அறியவில்லை; அவ்விடத்தில் ஜனங்கள் கூட்டமாயிருந்தபடியினால் இயேசு விலகியிருந்தார்.
	<br /><br />
	14. அதற்குப்பின்பு இயேசு அவனைத் தேவாலயத்திலே கண்டு: இதோ, நீ சொஸ்தமானாய், அதிக கேடானதொன்றும் உனக்கு வராதபடி இனிப் பாவஞ்செய்யாதே என்றார்.
	<br /><br />
	15. அந்த மனுஷன் போய், தன்னைச் சொஸ்தமாக்கினவர் இயேசு என்று யூதர்களுக்கு அறிவித்தான்.
	<br /><br />
	16. இயேசு இவைகளை ஓய்வுநாளில் செய்தபடியால், யூதர்கள் அவரைத் துன்பப்படுத்தி, அவரைக் கொலைசெய்ய வகைதேடினார்கள்.
	<br /><br />
	17. இயேசு அவர்களை நோக்கி: என் பிதா இதுவரைக்கும் கிரியை செய்து வருகிறார், நானும் கிரியை செய்து வருகிறேன் என்றார்.
	<br /><br />
	18. அவர் ஓய்வுநாள் கட்டளையை மீறினதுமல்லாமல், தேவனைத் தம்முடைய சொந்தப் பிதா என்றுஞ்சொல்லித் தம்மைத் தேவனுக்குச் சமமாக்கினபடியினாலே, யூதர்கள் அவரைக் கொலைசெய்யும்படி அதிகமாய் வகைதேடினார்கள்.
	<br /><br />
	19. அப்பொழுது இயேசு அவர்களை நோக்கி: மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன்: பிதாவானவர் செய்யக் குமாரன் காண்கிறதெதுவோ, அதையேயன்றி, வேறொன்றையும் தாமாய்ச் செய்யமாட்டார்; அவர் எவைகளைச் செய்கிறாரோ, அவைகளைக் குமாரனும் அந்தப்படியே செய்கிறார்.
	<br /><br />
	20. பிதாவானவர் குமாரனிடத்தில் அன்பாயிருந்து, தாம் செய்கிறவைகளையெல்லாம் அவருக்குக் காண்பிக்கிறார்; நீங்கள் ஆச்சரியப்படத்தக்கதாக இவைகளைப் பார்க்கிலும் பெரிதான கிரியைகளையும் அவருக்குக் காண்பிப்பார்.
	<br /><br />
	21. பிதாவானவர் மரித்தோரை எழுப்பி உயிர்ப்பிக்கிறதுபோல, குமாரனும் தமக்குச் சித்தமானவர்களை உயிர்ப்பிக்கிறார்.
	<br /><br />
	22. அன்றியும் பிதாவைக் கனம்பண்ணுகிறதுபோல எல்லாரும் குமாரனையும் கனம்பண்ணும்படிக்கு, பிதாவானவர் தாமே ஒருவருக்கும் நியாயத்தீர்ப்புச் செய்யாமல், நியாயத்தீர்ப்புச் செய்யும் அதிகாரம் முழுவதையும் குமாரனுக்கு ஒப்புக்கொடுத்திருக்கிறார்.
	<br /><br />
	23. குமாரனைக் கனம்பண்ணாதவன் அவரை அனுப்பின பிதாவையும் கனம் பண்ணாதவனாயிருக்கிறான்.
	<br /><br />
	24. என் வசனத்தைக் கேட்டு, என்னை அனுப்பினவரை விசுவாசிக்கிறவனுக்கு நித்தியஜீவன் உண்டு; அவன் ஆக்கினைத் தீர்ப்புக்குட்படாமல், மரணத்தைவிட்டு நீங்கி, ஜீவனுக்குட்பட்டிருக்கிறான் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	25. மரித்தோர் தேவகுமாரனுடைய சத்தத்தைக் கேட்குங் காலம் வரும், அது இப்பொழுதே வந்திருக்கிறது; அதைக் கேட்கிறவர்கள் பிழைப்பார்கள் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	26. ஏனெனில், பிதாவானவர் தம்மில் தாமே ஜீவனுடையவராயிருக்கிறது போல, குமாரனும் தம்மில்தாமே ஜீவனுடையவராயிருக்கும்படி அருள்செய்திருக்கிறார்.
	<br /><br />
	27. அவர் மனுஷகுமாரனாயிருக்கிறபடியால், நியாயத்தீர்ப்புச் செய்யும்படிக்கு அதிகாரத்தையும் அவருக்குக் கொடுத்திருக்கிறார்.
	<br /><br />
	28. இதைக்குறித்து நீங்கள் ஆச்சரியப்படவேண்டாம்; ஏனென்றால் பிரேதக்குழிகளிலுள்ள அனைவரும் அவருடைய சத்தத்தைக் கேட்குங் காலம் வரும்;
	<br /><br />
	29. அப்பொழுது, நன்மைசெய்தவர்கள் ஜீவனை அடையும்படி எழுந்திருக்கிறவர்களாகவும், தீமைசெய்தவர்கள் ஆக்கினையை அடையும்படி எழுந்திருக்கிறவர்களாகவும் புறப்படுவார்கள்.
	<br /><br />
	30. நான் என் சுயமாய் ஒன்றுஞ் செய்கிறதில்லை; நான் கேட்கிறபடியே நியாயந்தீர்க்கிறேன்; எனக்குச் சித்தமானதை நான் தேடாமல், என்னை அனுப்பின பிதாவுக்குச் சித்தமானதையே நான் தேடுகிறபடியால் என் தீர்ப்பு நீதியாயிருக்கிறது.
	<br /><br />
	31. என்னைக்குறித்து நானே சாட்சிகொடுத்தால் என் சாட்சி மெய்யாயிராது.
	<br /><br />
	32. என்னைக்குறித்துச் சாட்சிகொடுக்கிறவர் வேறொருவர் இருக்கிறார், அவர் என்னைக்குறித்துக் கொடுக்கிற சாட்சி மெய்யான சாட்சியென்று அறிந்திருக்கிறேன்.
	<br /><br />
	33. நீங்கள் யோவானிடத்தில் ஆளனுப்பி விசாரித்தீர்கள், அவன் சத்தியத்திற்குச் சாட்சிகொடுத்தான்.
	<br /><br />
	34. நான் ஏற்றுக்கொள்ளுகிற சாட்சி மனுஷருடைய சாட்சியல்ல, நீங்கள் இரட்சிக்கப்படுவதற்காகவே இவைகளைச் சொல்லுகிறேன்.
	<br /><br />
	35. அவன் எரிந்து பிரகாசிக்கிற விளக்காயிருந்தான்; நீங்களும் சிலகாலம் அவன் வெளிச்சத்திலே களிகூர மனதாயிருந்தீர்கள்.
	<br /><br />
	36. யோவானுடைய சாட்சியைப்பார்க்கிலும் மேன்மையான சாட்சி எனக்கு உண்டு; அதென்னவெனில், நான் நிறைவேற்றும்படிக்குப் பிதாவானவர் எனக்குக் கற்பித்ததும் நான் செய்துவருகிறதுமான கிரியைகளே பிதா என்னை அனுப்பினார் என்று என்னைக்குறித்துச் சாட்சி கொடுக்கிறது.
	<br /><br />
	37. என்னை அனுப்பின பிதா தாமே என்னைக்குறித்துச் சாட்சி கொடுத்திருக்கிறார்; நீங்கள் ஒருக்காலும் அவர் சத்தத்தைக் கேட்டதுமில்லை, அவர் ரூபத்தைக் கண்டதுமில்லை.
	<br /><br />
	38. அவர் அனுப்பினவரை நீங்கள் விசுவாசியாதபடியால் அவருடைய வசனம் உங்களில் தரித்திருக்கிறதுமில்லை.
	<br /><br />
	39. வேதவாக்கியங்களை ஆராய்ந்து பாருங்கள்; அவைகளால் உங்களுக்கு நித்தியஜீவன் உண்டென்று எண்ணுகிறீர்களே, என்னைக்குறித்துச் சாட்சிகொடுக்கிறவைகளும் அவைகளே.
	<br /><br />
	40. அப்படியிருந்தும் உங்களுக்கு ஜீவன் உண்டாகும்படி என்னிடத்தில் வர உங்களுக்கு மனதில்லை.
	<br /><br />
	41. நான் மனுஷரால் மகிமையை ஏற்றுக்கொள்ளுகிறதில்லை.
	<br /><br />
	42. உங்களில் தேவ அன்பு இல்லையென்று உங்களை அறிந்திருக்கிறேன்.
	<br /><br />
	43. நான் என் பிதாவின் நாமத்தினாலே வந்திருந்தும் நீங்கள் என்னை ஏற்றுக்கொள்ளவில்லை, வேறொருவன் தன் சுயநாமத்தினாலே வந்தால் அவனை ஏற்றுக்கொள்வீர்கள்.
	<br /><br />
	44. தேவனாலேமாத்திரம் வருகிற மகிமையைத் தேடாமல், ஒருவராலொருவர் மகிமையை ஏற்றுக்கொள்ளுகிற நீங்கள் எப்படி விசுவாசிப்பீர்கள்?
	<br /><br />
	45. பிதாவினிடத்தில் நான் உங்கள்மேல் குற்றஞ்சாட்டுவேன் என்று நினையாதிருங்கள்; நீங்கள் நம்புகிற மோசேயே உங்கள்மேல் குற்றஞ்சாட்டுவான்.
	<br /><br />
	46. நீங்கள் மோசேயை விசுவாசித்தீர்களானால், என்னையும் விசுவாசிப்பீர்கள்; அவன் என்னைக்குறித்து எழுதியிருக்கிறானே.
	<br /><br />
	47. அவன் எழுதின வாக்கியங்களை நீங்கள் விசுவாசியாமலிருந்தால் நான் சொல்லுகிற வசனங்களை எப்படி விசுவாசிப்பீர்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John5