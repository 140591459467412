import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-12 </title>
	<meta
          name="description"
          content="Ecclesiastes 122 | பிரசங்கி 12 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீ உன் வாலிபப்பிராயத்திலே உன் சிருஷ்டிகரை நினை; தீங்குநாட்கள் வராததற்குமுன்னும், எனக்குப் பிரியமானவைகளல்ல என்று நீ சொல்லும் வருஷங்கள் சேராததற்குமுன்னும்,
	<br /><br />
	2. சூரியனும், வெளிச்சமும், சந்திரனும், நட்சத்திரங்களும், அந்தகாரப்படாததற்குமுன்னும்,
	<br /><br />
	3. மழைக்குப்பின் மேகங்கள் திரும்பத் திரும்ப வராததற்குமுன்னும், வீட்டுக்காவலாளிகள் தள்ளாடி, பெலசாலிகள் கூனிப்போய், ஏந்திரம் அரைக்கிறவர்கள் கொஞ்சமானதினால் ஓய்ந்து, பலகணிவழியாய்ப் பார்க்கிறவர்கள் இருண்டுபோகிறதற்குமுன்னும்,
	<br /><br />
	4. ஏந்திர சத்தம் தாழ்ந்ததினால் தெருவாசலின் கதவுகள் அடைபட்டு, குருவியின் சத்தத்துக்கும் எழுந்திருக்கவேண்டியதாகி, கீதவாத்தியக் கன்னிகைகளெல்லாம் அடங்கிப்போகாததற்குமுன்னும்,
	<br /><br />
	5. மேட்டுக்காக அச்சமுண்டாகி, வழியிலே பயங்கள் தோன்றி, வாதுமைமரம் பூப்பூத்து, வெட்டுக்கிளியும் பாரமாகி, பசித்தீபனமும் அற்றுப்போகாததற்கு முன்னும், மனுஷன் தன் நித்திய வீட்டுக்குப் போகிறதினாலே, துக்கங்கொண்டாடுகிறவர்கள் வீதியிலே திரியாததற்குமுன்னும்,
	<br /><br />
	6. வெள்ளிக்கயிறு கட்டுவிட்டு, பொற்கிண்ணி நசுங்கி, ஊற்றின் அருகே சால் உடைந்து, துரவண்டையில் உருளை நொறுங்கி,
	<br /><br />
	7. இவ்விதமாய் மண்ணானது தான் முன்னிருந்த பூமிக்குத் திரும்பி, ஆவி தன்னைத் தந்த தேவனிடத்திற்கு மறுபடியும் போகாததற்குமுன்னும், அவரை உன் வாலிபப்பிராயத்திலே நினை.
	<br /><br />
	8. மாயை மாயை, எல்லாம் மாயை என்று பிரசங்கி சொல்லுகிறான்.
	<br /><br />
	9. மேலும், பிரசங்கி ஞானவானாயிருந்தபடியால், அவன் ஜனத்துக்கு அறிவைப்போதித்து, கவனமாய்க் கேட்டாராய்ந்து, அநேகம் நீதிமொழிகளைச் சேர்த்து எழுதினான்.
	<br /><br />
	10. இதமான வார்த்தைகளைக் கண்டுபிடிக்கப் பிரசங்கி வகைதேடினான்; எழுதின வாக்கியங்கள் செவ்வையும் சத்தியமுமானவைகள்.
	<br /><br />
	11. ஞானிகளின் வாக்கியங்கள் தாற்றுக்கோல்கள்போலவும் சங்கத்தலைவர்களால் அறையப்பட்ட ஆணிகள்போலவும் இருக்கிறது; அவைகள் ஒரே மேய்ப்பனால் அளிக்கப்பட்டது.
	<br /><br />
	12. என் மகனே! இவைகளினாலே புத்தியடைவாயாக; அநேகம் புஸ்தகங்களை உண்டுபண்ணுகிறதற்கு முடிவில்லை; அதிக படிப்பு உடலுக்கு இளைப்பு.
	<br /><br />
	13. காரியத்தின் கடைத்தொகையைக் கேட்போமாக, தேவனுக்குப் பயந்து, அவர் கற்பனைகளைக் கைக்கொள்; எல்லா மனுஷர்மேலும் விழுந்த கடமை இதுவே.
	<br /><br />
	14. ஒவ்வொரு கிரியையையும், அந்தரங்கமான ஒவ்வொரு காரியத்தையும், நன்மையானாலும் தீமையானாலும், தேவன் நியாயத்திலே கொண்டுவருவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes12