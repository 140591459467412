import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 16 </title>
	<meta
          name="description"
          content="Ezekiel 16 | எசேக்கியேல்  16 |
          Ezekiel 16 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ எருசலேமின் அருவருப்புகளை அதற்கு அறிவித்துச் சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	3. கர்த்தராகிய ஆண்டவர் எருசலேமுக்குச் சொல்லுகிறார், கானான் தேசமே உன் உற்பத்திக்கும் உன் பிறப்புக்கும் இடம், உன் தகப்பன் எமோரியன், உன் தாய் ஏத்தித்தி.
	<br /><br />
	4. உன் பிறப்பின் வர்த்தமானம் என்னவென்றால், நீ பிறந்த நாளிலே உன் தொப்புள் அறுக்கப்படவுமில்லை; நீ சுத்தமாவதற்குத் தண்ணீரினால் குளிப்பாட்டப்படவுமில்லை; உப்பால் சுத்திகரிக்கப்படவுமில்லை; துணிகளில் சுற்றப்படவுமில்லை.
	<br /><br />
	5. உனக்காகப் பரிதபித்து, இவைகளில் ஒன்றையாகிலும் உனக்குச் செய்ய ஒரு கண்ணும் உன்பேரில் இரக்கமாயிருந்ததுமில்லை; நீ பிறந்தநாளில் நீ அருவருக்கப்பட்டதினால் வெளியில் எறிந்துவிடப்பட்டாய்.
	<br /><br />
	6. நான் உன் அருகே கடந்துபோகும்போது, மிதிக்கப்படுவதற்கு ஏதுவாய் நீ உன் இரத்தத்தில் கிடக்கிறதைக் கண்டு, உன் இரத்தத்தில் கிடக்கிற உன்னைப்பார்த்து: பிழைத்திரு என்றேன்; ஆம், உன் இரத்தத்தில் கிடக்கிற உன்னைப்பார்த்து: பிழைத்திரு என்று சொன்னேன்.
	<br /><br />
	7. உன்னை வயலின் பயிரைப்போல அநேகமாயிரமாய்ப் பெருகும்படி வைத்தேன்; நீ வளர்ந்து பெரியவளாகி, மகா சௌந்தரியவதியானாய்; உன் ஸ்தனங்கள் எழும்பின, உன் மயிர் வளர்ந்தது; ஆனாலும், நீ நிர்வாணமும் அம்மணமுமாயிருந்தாய்.
	<br /><br />
	8. நான் உன் அருகே கடந்துபோனபோது, உன்னைப் பார்த்தேன்; இதோ, உன் காலம் பருவகாலமாயிருந்தது; அப்பொழுது என் வஸ்திரத்தை உன்மேல் விரித்து, உன் நிர்வாணத்தை மூடி, உனக்கு ஆணையிட்டுக்கொடுத்து, உன்னோடு உடன்படிக்கை பண்ணினேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; இவ்விதமாய் நீ என்னுடையவளானாய்.
	<br /><br />
	9. நான் உன்னை ஜலத்தினால் முழுக்காட்டி, உன்னை இரத்தமற ஸ்நானம் பண்ணுவித்து, உனக்கு எண்ணெய் பூசி,
	<br /><br />
	10. சித்திரத்தையலாடையை உனக்கு உடுத்தி, சாயந்தீர்ந்த பாதரட்சைகளை உனக்குத் தரித்து, கட்ட மெல்லிய புடவையையும், மூடிக்கொள்ளப் பட்டுச் சால்வையையும் உனக்குக் கொடுத்து,
	<br /><br />
	11. உன்னை ஆபரணங்களால் அலங்கரித்து, உன் கைகளிலே கடகங்களையும், உன் கழுத்திலே சரப்பணியையும் போட்டு,
	<br /><br />
	12. உன் நெற்றியில் நெற்றிப்பட்டத்தையும், உன் காதுகளில் காதணியையும், உன் தலையின்மேல் சிங்காரமான கிரீடத்தையும் தரித்தேன்.
	<br /><br />
	13. இவ்விதமாய்ப் பொன்னினாலும் வெள்ளியினாலும் நீ அலங்கரிக்கப்பட்டாய்; உன் உடுப்பு மெல்லிய புடவையும் பட்டும் சித்திரத்தையலாடையுமாயிருந்தது; மெல்லிய மாவையும் தேனையும் நெய்யையும் சாப்பிட்டாய்; நீ மிகவும் அழகுள்ளவளாகி, ராஜ்யத்தைச் சுதந்தரிக்கும் சிலாக்கியத்தையும் பெற்றாய்.
	<br /><br />
	14. உன் அழகினாலே உன் கீர்த்தி புறஜாதிகளுக்குள்ளே பிரசித்தமாயிற்று; நான் உன்மேல் வைத்த என் மகிமையினாலே அது குறைவற்றதாய் இருந்ததென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. நீயோவென்றால் உன் அழகை நம்பி, உன் கீர்த்தியினால் சோரமார்க்கமாய் நடந்து, வழிப்போக்கரில் உனக்கு நேர்பட்ட யாவரோடும் வேசித்தனம்பண்ணி,
	<br /><br />
	16. உன் வஸ்திரங்களில் சிலவற்றை எடுத்து, பலவருணச் சோடிப்பான மேடைகளை உனக்கு உண்டாக்கி, அவைகளின்மேல் வேசித்தனம்பண்ணினாய்; அப்படிக்கொத்த காரியங்கள் ஒருக்காலும் சம்பவித்ததுமில்லை, சம்பவிப்பதுமில்லை.
	<br /><br />
	17. நான் உனக்குக் கொடுத்த என் பொன்னும் என் வெள்ளியுமான உன் சிங்கார ஆபரணங்களை நீ எடுத்து, உனக்கு ஆண்சுரூபங்களை உண்டாக்கி, அவைகளோடே வேசித்தனம்பண்ணி,
	<br /><br />
	18. உன் சித்திரத்தையலாடைகளை எடுத்து, அவைகளை மூடி, என் எண்ணெயையும் என் தூபவர்க்கத்தையும் அவைகளின்முன் படைத்து,
	<br /><br />
	19. நான் உனக்குக் கொடுத்த என் அப்பத்தையும், நீ சாப்பிடும்படி உனக்குக் கொடுத்த மெல்லிய மாவையும் நெய்யையும் தேனையும் நீ அவைகளின்முன் சுகந்தவாசனையாகப் படைத்தாய்; காரியம் இப்படி ஆயிற்றென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	20. நீ எனக்குப் பெற்ற உன் குமாரரையும் உன் குமாரத்திகளையும் எடுத்து, அவர்களை அவைகளுக்கு இரையாகப் பலியிட்டாய்.
	<br /><br />
	21. நீ செய்த வேசித்தனங்கள் போதாதென்று, நீ என் பிள்ளைகளை அவைகளுக்குத் தீக்கடக்கப்பண்ண ஒப்புக்கொடுத்து, அவர்களைக் கொலைசெய்தாய்.
	<br /><br />
	22. நீ உன் எல்லா அருவருப்புகளிலும் வேசித்தனங்களிலும் நடக்கும்போது, நிர்வாணமும் அம்மணமுமாயிருந்ததும், உன் இரத்தத்திலே மிதிக்கப்பட ஏதுவாய்க் கிடந்ததுமான உன் சிறுவயதின் நாட்களை நினையாமற்போனாய்.
	<br /><br />
	23. ஐயோ! உனக்கு ஐயோ! என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; நீ செய்த பொல்லாப்புகளெல்லாம் தவிர,
	<br /><br />
	24. நீ உனக்கு மண்டபங்களைக் கட்டி, உனக்குச் சகல வீதிகளிலும் உயர்ந்த மேடைகளை உண்டுபண்ணினாய்.
	<br /><br />
	25. நீ சகல வழிமுகனையிலும் உன் உயர்ந்த மேடைகளைக் கட்டி, உன் அழகை அருவருப்பாக்கி, வழிப்போக்கர் யாவருக்கும் உன் கால்களை விரித்து, உன் வேசித்தனங்களைத் திரளாய்ப் பெருகப்பண்ணி,
	<br /><br />
	26. சதை பெருத்த உன் அயல் தேசத்தாராகிய எகிப்திய புத்திரரோடே வேசித்தனம்பண்ணி, எனக்குக் கோபம் உண்டாக்கும்படி உன் வேசித்தனங்களைப் பெருகப்பண்ணினாய்.
	<br /><br />
	27. ஆதலால், இதோ, நான் என் கையை உனக்கு விரோதமாக நீட்டி, உனக்கு நியமித்த போஜனத்தைக் குறுக்கி, உன் முறைகேடான மார்க்கத்தைக் குறித்து வெட்கப்பட்ட உன் பகையாளிகளாகிய பெலிஸ்தருடைய குமாரத்திகளின் இச்சைக்கு உன்னை ஒப்புக்கொடுத்தேன்.
	<br /><br />
	28. நீ திருப்தியடையாததினால் அசீரிய புத்திரரோடும் வேசித்தனம்பண்ணினாய்; அவர்களோடே வேசித்தனம்பண்ணியும் நீ திருப்தியடையவில்லை.
	<br /><br />
	29. நீ கானான் தேசத்திலே செய்த வேசித்தனத்தைக் கல்தேயர்மட்டும் எட்டச் செய்தாய்; அதினாலும் நீ திருப்தியடையாமற்போனாய்.
	<br /><br />
	30. வெட்கங்கெட்ட வேசியின் கிரியைகளாகிய இவைகளையெல்லாம் நீ செய்து,
	<br /><br />
	31. சகல வழிமுகனையிலும் உன் மண்டபங்களைக் கட்டி, சகல வீதிகளிலும் உன் மேடைகளை உண்டாக்கினபடியால், உன் இருதயம் எவ்வளவாய்க் களைத்துப்போயிருக்கிறது என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; நீ பணையத்தை அலட்சியம்பண்ணுகிறதினால், நீ வேசியைப்போல இராமல்,
	<br /><br />
	32. தன் புருஷனுக்குப் பதிலாக அந்நியரைச் சேர்த்துக்கொள்ளுகிற விபசார ஸ்திரீயைப்போல இருக்கிறாய்.
	<br /><br />
	33. எல்லா வேசிகளுக்கும் பணையங் கொடுக்கிறார்கள்; நீயோ உன் நேசர்கள் சுற்றுப்புறங்களிலிருந்து உன்னிடத்தில் வேசித்தனஞ்செய்ய வரும்படி அவர்களுக்கெல்லாம் நீயே பணையங்கொடுத்து, அவர்களுக்கு வெகுமதிகளைத் தருகிறாய்.
	<br /><br />
	34. இவ்விதமாய் உன் வேசித்தனங்களுக்கும் வேறே ஸ்திரீகளின் வேசித்தனங்களுக்கும் வித்தியாசமுண்டு; வேசித்தனம்பண்ண அவர்கள் உன்னைப் பின் செல்லமாட்டார்கள்; பணையம் உனக்குக் கொடுக்கப்படாமல் நீயே பணையம் கொடுக்கிறபடியால் நீ செய்வது விபரீதம்.
	<br /><br />
	35. ஆகையால், வேசியே, கர்த்தருடைய வார்த்தையைக் கேள்.
	<br /><br />
	36. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: உன் வேசித்தனத்தின் அசுத்தம் பாய்ந்தபடியினாலும், நீ உன் காமவிகாரிகளோடும் அருவருப்பாகிய உன் நரகலான விக்கிரகங்களோடும் வேசித்தனம்பண்ணி, இவைகளுக்கு உன் பிள்ளைகளின் இரத்தத்தைப் படைத்ததினால் உன் நிர்வாணம் திறக்கப்பட்டபடியினாலும்,
	<br /><br />
	37. இதோ, நீ சம்போகம்பண்ணின உன் எல்லாக் காமவிகாரிகளையும், நீ நேசித்த யாவரையும், நீ பகைத்திருக்கிற அனைவரோடும் நான் கூடிவரச்செய்து, சுற்றிலுமிருந்து அவர்களை உனக்கு விரோதமாகச் சேர்த்து, அவர்கள் உன் நிர்வாணத்தையெல்லாம் காணும்படி உன் நிர்வாணத்தை அவர்களுக்கு முன்பாகத் திறந்துவைத்து,
	<br /><br />
	38. விபசாரிகளையும் இரத்தஞ்சிந்தினவர்களையும் நியாயந்தீர்க்கிறபடியே உன்னை நியாயந்தீர்த்து, உக்கிரத்தோடும் எரிச்சலோடும் இரத்தப்பழியை உன்பேரில் சுமத்தி,
	<br /><br />
	39. உன்னை அவர்கள் கையில் ஒப்புக்கொடுப்பேன்; அவர்கள் உன் மண்டபங்களை இடித்து, உன் மேடைகளைத் தரையாக்கிப்போட்டு, உன் வஸ்திரங்களை உரிந்து, உன் சிங்கார ஆபரணங்களை எடுத்துக்கொண்டு, உன்னை அம்மணமும் நிர்வாணமுமாக விட்டுப்போய்,
	<br /><br />
	40. உனக்கு விரோதமாக ஒரு கூட்டத்தைக் கொண்டுவந்து, உன்னைக் கல்லெறிந்து, உன்னைத் தங்கள் பட்டயங்களால் குத்திபோட்டு,
	<br /><br />
	41. உன் வீடுகளை அக்கினியால் சுட்டெரித்து, அநேக ஸ்திரீகளின் கண்களுக்கு முன்பாக உன்னில் நியாயத்தீர்ப்புகளைச் செய்வார்கள்; உன் வேசித்தனத்தை ஒழியப்பண்ணுவேன்; நீ இனிப் பணையங்கொடுப்பதில்லை.
	<br /><br />
	42. இவ்விதமாய் என் எரிச்சல் உன்னை விட்டு நீங்கும்படி, நான் என் உக்கிரத்தை உன்னில் ஆறப்பண்ணி, இனி கோபமாயிராமல் அமருவேன்.
	<br /><br />
	43. நீ உன் இளவயதின் நாட்களை நினையாமல், இவைகளெல்லாவற்றினாலும் எனக்குக் கோபம் உண்டாக்கினபடியினால், இதோ, நான் உன் வழியின் பலனை உன் தலையின்மேல் சுமரப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; அதினாலே இனி உன் எல்லா அருவருப்புகளினாலும் இப்படிப்பட்ட முறைகேடான காரியத்தைச் செய்யமாட்டாய்.
	<br /><br />
	44. இதோ, பழமொழி சொல்லுகிறவர்கள் எல்லாரும்: தாயைப்போல மகள் என்று உன்னைக்குறித்துப் பழமொழி சொல்லுவார்கள்.
	<br /><br />
	45. நீ, தன் புருஷனையும் தன் பிள்ளைகளையும் அருவருத்த உன் தாயின் மகள்; நீ, தங்கள் புருஷரையும் தங்கள் பிள்ளைகளையும் அருவருத்த உன் சகோதரிகளின் சகோதரி; உங்கள் தாய் ஏத்தித்தி; உங்கள் தகப்பன் எமோரியன்.
	<br /><br />
	46. உன் இடதுபுறத்திலே, தானும் தன் குமாரத்திகளுமாய்க் குடியிருந்த சமாரியா உன் தமக்கை; உன் வலதுபுறத்திலே, தானும் தன் குமாரத்திகளுமாய்க் குடியிருந்த சோதோம் உன் தங்கை.
	<br /><br />
	47. ஆகிலும் நீ அவர்களுடைய மார்க்கங்களிலே நடவாமலும், அவர்களுடைய அருவருப்புகளின்படி செய்யாமலும், அது மகா அற்பகாரியம் என்கிறதுபோல நீ உன் எல்லா வழிகளிலேயும் அவர்களைப்பார்க்கிலும் கேடாய் நடந்தாய்.
	<br /><br />
	48. நீயும் உன் குமாரத்திகளும் செய்ததுபோல, உன் சகோதரியாகிய சோதோமும் அவளுடைய குமாரத்திகளும் செய்யவில்லை என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	49. இதோ, கெர்வமும், ஆகாரத் திரட்சியும், நிர்விசாரமான சாங்கோபாங்கமுமாகிய இவைகளே உன் சகோதரியான சோதோமின் அக்கிரமம்; இவைகளே அவளிடத்திலும் அவள் குமாரத்திகளிடத்திலும் இருந்தன; சிறுமையும் எளிமையுமானவனுடைய கையை அவள் பலப்படுத்தவில்லை.
	<br /><br />
	50. அவர்கள் தங்களை உயர்த்தி, என் முகத்துக்கு முன்பாக அருவருப்பானதைச் செய்தார்கள்; அதை நான் கண்டபோது, அவர்களை ஒழித்துவிட்டேன்.
  <br /><br />
	51. நீ செய்த பாவங்களில் அரைவாசியும் சமாரியா செய்யவில்லை; நீ உன் சகோதரிகளைப்பார்க்கிலும் உன் பாவங்களைப் பெருகப்பண்ணி, நீ செய்த உன் எல்லா அருவருப்புகளினாலும் அவர்களை நீதியுள்ளவர்களென்று விளங்கப்பண்ணினாய்.
	<br /><br />
	52. இப்போதும் உன் சகோதரிகளைக் குற்றவாளிகள் என்று தீர்த்த நீ அவர்களைப்பார்க்கிலும் அருவருப்பாகச் செய்த உன் பாவங்களினிமித்தம் உன் இலச்சையைச் சுமந்துகொள்; உன்னைப்பார்க்கிலும் அவர்கள் நீதியுள்ளவர்கள்; உன் சகோதரிகளை நீதியுள்ளவர்களென்று விளங்கப்பண்ணின நீ வெட்கமடைந்து, உன் இலச்சையைச் சுமந்துகொள்.
	<br /><br />
	53. நான் சோதோமும் அவள் குமாரத்திகளும் சிறையிருக்கிற அவர்களுடைய சிறையிருப்பையும், சமாரியாவும் அவள் குமாரத்திகளும் சிறையிருக்கிற சிறையிருப்பையும் திருப்பும்போது, அவர்கள் நடுவிலே நீ சிறையிருக்கிற உன்னுடைய சிறையிருப்பையும் திருப்புவேன்.
	<br /><br />
	54. அதினால் நீ அவர்களுக்கு ஆறுதலாக இருந்து, உன் இலச்சையைச் சுமந்து, நீ செய்த எல்லாவற்றினாலும் வெட்கமடைவாய்.
	<br /><br />
	55. உன் சகோதரிகளாகிய சோதோமும் அவள் குமாரத்திகளும் தங்கள் முந்தின சீருக்குத் திரும்புவார்கள்; சமாரியாவும் அவள் குமாரத்திகளும் தங்கள் முந்தின சீருக்குத் திரும்புவார்கள்; நீயும் உன் குமாரத்திகளும் உங்கள் முந்தின சீருக்குத் திரும்புவீர்கள்.
	<br /><br />
	56. உன்னை வெறுக்கும் சீரியாவின் குமாரத்திகளும், அவளைச் சுற்றிலும் இருக்கிற பெலிஸ்தரின் குமாரத்திகளும் அவமானம்பண்ணினபோது உன் பொல்லாப்பு வெளியாயிற்றே.
	<br /><br />
	57. அதற்குமுன் உன் கெர்வத்தின் நாளிலே உன் சகோதரியாகிய சோதோமின் பெயரை உன் வாயினாலே உச்சரிக்கவுமாட்டாய்.
	<br /><br />
	58. உன் முறைகேட்டையும் உன் அருவருப்புகளையும் நீ சுமப்பாய் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	59. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: உடன்படிக்கையை முறித்துப்போடுகிறதினால் ஆணையை அசட்டைபண்ணின நீ செய்ததுபோல நான் உனக்கும் செய்வேன்.
	<br /><br />
	60. ஆகிலும் உன் இளவயதில் உன்னோடே பண்ணின என் உடன்படிக்கையை நான் நினைத்து, நித்திய உடன்படிக்கையை உனக்கு ஏற்படுத்துவேன்.
	<br /><br />
	61. அப்பொழுது உன் தமக்கைகளையும் உன் தங்கைகளையும் நீ சேர்த்துக்கொள்ளுகையில், உன் வழிகளை நினைத்து நாணுவாய்; அவர்களை நான் உனக்குக் குமாரத்திகளாகக் கொடுப்பேன்; உன்னுடைய உடன்படிக்கையைப் பார்த்துக் கொடுப்பதில்லை.
	<br /><br />
	62. உன்னோடே என் உடன்படிக்கையைப்பண்ணி ஏற்படுத்துவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிவாய்.
	<br /><br />
	63. நீ செய்த எல்லாவற்றையும் நான் மன்னித்தருளும்போது, நீ நினைத்து வெட்கி, உன் நாணத்தினால் உன் வாயை இனித் திறக்கமாட்டாதிருப்பாய் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial16