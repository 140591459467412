import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 5 </title>
	<meta
          name="description"
          content="Jeremiah 5 | எரேமியா 5 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நியாயஞ்செய்கிற மனுஷனைக் கண்டுபிடிப்பீர்களோ என்றும், சத்தியத்தைத் தேடுகிறவன் உண்டோ என்றும், எருசலேமின் தெருக்களிலே திரிந்துபார்த்து, விசாரித்து, அதின் வீதிகளிலே தேடுங்கள்; காண்பீர்களானால் அதற்கு மன்னிப்புத் தருவேன்.
	<br /><br />
	2. அவர்கள்: கர்த்தருடைய ஜீவனைக்கொண்டு சொல்லுகிறோம் என்றாலும், பொய்யாணையிடுகிறார்களே.
	<br /><br />
	3. கர்த்தாவே, உம்முடைய கண்கள் சத்தியத்தை அல்லவோ நோக்குகின்றது; அவர்களை அடிக்கிறீர், ஆனாலும் அவர்களுக்கு நோகாது; அவர்களை நிர்மூலமாக்குகிறீர், ஆனாலும் புத்தியை ஏற்றுக்கொள்ளமாட்டோம் என்கிறார்கள்; தங்கள் முகங்களைக் கன்மலையைப்பார்க்கிலும் கெட்டியாக்கி, திரும்பமாட்டோம் என்கிறார்கள்.
	<br /><br />
	4. அப்பொழுது நான்: இவர்கள் நீசராமே, இவர்கள் மதியற்றவர்கள்; கர்த்தருடைய வழியையும், தங்கள் தேவனுடைய நியாயத்தையும் அறியாதிருக்கிறார்கள் என்றும்;
	<br /><br />
	5. நான் பெரியோர்களிடத்திலே போய், அவர்களோடே பேசுவேன்; அவர்கள் கர்த்தருடைய வழியையும், தங்கள் தேவனுடைய நியாயத்தையும் அறிவார்கள் என்றும் சொன்னேன்; அவர்களோ ஏகமாய் நுகத்தடியை முறித்து, கட்டுகளை அறுத்துப்போட்டார்கள்.
	<br /><br />
	6. ஆகையால் காட்டிலிருந்து வரும் சிங்கம் அவர்களைக் கொல்லும், வனாந்தரத்திலுள்ள ஓநாய்கள் அவர்களைப் பீறும், சிவிங்கி அவர்கள் பட்டணங்களின்மேல் நோக்கமாயிருக்கும்; அவைகளிலிருந்து புறப்படுகிறவன் எவனும் பீறப்படுவான்; அவர்கள் மீறுதல்கள் பெருகி, அவர்கள் சீர்கேடுகள் அதிகரித்தது.
	<br /><br />
	7. இவைகளை நான் உனக்கு மன்னிப்பது எப்படி? உன் பிள்ளைகள் என்னை விட்டுவிட்டு, தெய்வம் அல்லாதவைகள் பேரில் ஆணையிடுகிறார்கள்; நான் திருப்தியாக்கின அவர்கள் விபசாரம்பண்ணி, வேசிவீட்டிலே கூட்டங்கூடுகிறார்கள்.
	<br /><br />
	8. அவர்கள் கொழுத்த குதிரைகளைப்போல் காலமே எழும்பி, அவனவன் தன்தன் அயலானுடைய பெண்ஜாதியின் பின்னாலே கனைக்கிறான்.
	<br /><br />
	9. இவைகளை விசாரியாதிருப்பேனோ? இப்படிப்பட்ட ஜாதிக்கு என் ஆத்துமா நீதியைச் சரிக்கட்டாதிருக்குமோ என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. அதின் மதில்கள்மேலேறி அழித்துப்போடுங்கள்; ஆனாலும் சர்வசங்காரம் செய்யாதிருங்கள்; அதின் கொத்தளங்களை இடித்துப்போடுங்கள்; அவைகள் கர்த்தருடையவைகள் அல்ல.
	<br /><br />
	11. இஸ்ரவேல் வம்சத்தாரும், யூதா வம்சத்தாரும் எனக்கு விரோதமாய் மிகுதியும் துரோகம்பண்ணினார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. அவர் அப்படிப்பட்டவர் அல்லவென்றும், பொல்லாப்பு நம்மேல் வராது, நாம் பட்டயத்தையாகிலும், பஞ்சத்தையாகிலும் காண்பதில்லையென்றும்,
	<br /><br />
	13. தீர்க்கதரிசிகள் காற்றாய்ப்போவார்கள்; திருவாக்கு அவர்களில் இல்லை; அவர்களுக்கே அப்படி ஆகக்கடவதென்றும், அவர்கள் சொல்லிக் கர்த்தரை மறுதலித்தார்கள்.
	<br /><br />
	14. ஆகையால் சேனைகளின் தேவனாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால்: நீங்கள் இந்த வார்த்தையைச் சொன்னபடியினால், இதோ, நான் உன் வாயிலிட்ட என் வார்த்தைகளை அக்கினியும், இந்த ஜனத்தை விறகும் ஆக்குவேன், அது இவர்களைப் பட்சிக்கும்.
	<br /><br />
	15. இஸ்ரவேல் வம்சத்தாரே, இதோ, தூரத்திலிருந்து நான் உங்கள்மேல் ஒரு ஜாதியைக் கொண்டுவருவேன் என்று கர்த்தர் சொல்லுகிறார்; அது பலத்த ஜாதி, அது பூர்வகாலத்து ஜாதி, அவர்கள் நீ அறியாத பாஷையைப் பேசும் ஜாதி, அவர்கள் பேசுகிறது இன்னதென்று உனக்கு விளங்காது.
	<br /><br />
	16. திறந்த பிரேதக்குழிகளைப்போல் அவர்கள் அம்பறாத்தூணிகள் இருக்கும்; அவர்கள் அனைவரும் பராக்கிரமசாலிகள்.
	<br /><br />
	17. அவர்கள் உன் குமாரரும் உன் குமாரத்திகளும் சாப்பிடவேண்டிய உன் விளைச்சலையும், உன் அப்பத்தையும் சாப்பிட்டு, உன் ஆடுகளையும் உன் மாடுகளையும் பட்சித்து, உன் திராட்சப்பழங்களையும் உன் அத்திப்பழங்களையும் சாப்பிட்டு, நீ நம்பின உன்னுடைய அரணான பட்டணங்களைப் பட்டயத்தாலே வெறுமையாக்குவார்கள்.
	<br /><br />
	18. ஆகிலும் நான் அந்நாட்களிலும் உங்களைச் சர்வசங்காரம் செய்யாதிருப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	19. எங்கள் தேவனாகிய கர்த்தர் எங்களுக்கு இவைகளையெல்லாம் எதினிமித்தம் செய்தார் என்று நீங்கள் கேட்டால், அப்பொழுது நீ அவர்களைப் பார்த்து: நீங்கள் என்னைவிட்டு, உங்களுடைய தேசத்திலே அந்நிய தேவர்களைச் சேவித்ததுபோல, உங்களுடையதல்லாத தேசத்திலே அந்நியர்களைச் சேவிப்பீர்களென்று சொல்வாயாக.
	<br /><br />
	20. நீங்கள் யாக்கோபின் வீட்டிலே அறிவித்து, யூதாவிலே சொல்லிக் கூறவேண்டியது என்னவென்றால்,
	<br /><br />
	21. கண்கள் இருந்தும் காணாமலும், காதுகள் இருந்தும் கேளாமலுமிருக்கிற அறிவில்லாத ஜனங்களே, கேளுங்கள்.
	<br /><br />
	22. எனக்குப் பயப்படாதிருப்பீர்களோ என்று கர்த்தர் சொல்லுகிறார்; அலைகள் மோதியடித்தாலும் மேற்கொள்ளாதபடிக்கும், அவைகள் இரைந்தாலும் கடவாதபடிக்கும், கடக்கக்கூடாத நித்திய பிரமாணமாகச் சமுத்திரத்தின் மணலை எல்லையாய் வைத்திருக்கிறவராகிய எனக்கு முன்பாக அதிராதிருப்பீர்களோ?
	<br /><br />
	23. இந்த ஜனங்களோ முரட்டாட்டமும் கலகமுமான இருதயமுள்ளவர்கள்; முரட்டாட்டம்பண்ணிப் போய்விடுகிறார்கள்.
	<br /><br />
	24. அந்தந்தப் பருவத்திலே எங்களுக்கு மழையையும், முன்மாரியையும் பின்மாரியையும் கொடுத்து, அறுப்புக்கு நியமித்த வாரங்களை எங்களுக்குத் தற்காக்கிற எங்கள் தேவனாகிய கர்த்தருக்குப் பயந்திருப்போம் என்று அவர்கள் தங்கள் இருதயத்திலே சொல்லுகிறதில்லை.
	<br /><br />
	25. உங்கள் அக்கிரமங்கள் இவைகளை விலக்கி, உங்கள் பாவங்கள் உங்களுக்கு நன்மையை வரவொட்டாதிருக்கிறது.
	<br /><br />
	26. குருவிபிடிக்கிறவர்கள் பதுங்குகிறதுபோல் பதுங்கி, மனுஷரைப் பிடிக்கக் கண்ணிகளை வைக்கிற துன்மார்க்கர் என் ஜனங்களில் காணப்படுகிறார்கள்.
	<br /><br />
	27. குருவிகளால் கூண்டு நிறைந்திருக்கிறதுபோல், அவர்கள் வீடுகள் கபடங்களால் நிறைந்திருக்கிறது; ஆதலால் அவர்கள் பெருகி ஐசுவரியவான்களாகிறார்கள்.
	<br /><br />
	28. கொழுத்து, சளுக்குப்பண்ணுகிறார்கள்; துன்மார்க்கனுடைய செயல்களைக் கண்டிக்காமல் விடுகிறார்கள்; திக்கற்றவனுடைய வழக்கை விசாரியாமல், தாங்கள்மாத்திரம் வாழுகிறார்கள்; எளியவர்களின் நியாயத்தைத் தீரார்கள்.
	<br /><br />
	29. இவைகளை விசாரியாதிருப்பேனோ? இப்படிப்பட்ட ஜாதிக்கு என் ஆத்துமா நீதியைச் சரிக்கட்டாதிருக்குமோ என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	30. திகைத்துத் திடுக்கிடத்தக்க காரியம் தேசத்திலே நடந்துவருகிறது.
	<br /><br />
	31. தீர்க்கதரிசிகள் கள்ளத்தீர்க்கதரிசனம் சொல்லுகிறார்கள்; ஆசாரியர்கள் அவர்கள் மூலமாய் ஆளுகிறார்கள்; இப்படியிருப்பது என் ஜனத்துக்குப் பிரியமாயிருக்கிறது; ஆனாலும் முடிவிலே என்னசெய்வீர்கள்?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah5