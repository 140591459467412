import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zephaniah2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | செப்பனியா 2 </title>
	<meta
          name="description"
          content="Zephaniah 2 | செப்பனியா 2 | Seppania 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. விரும்பப்படாத ஜாதியே, கட்டளை பிறக்குமுன்னும், பதரைப்போல நாள் பறந்துபோகுமுன்னும் கர்த்தருடைய உக்கிரகோபம் உங்கள்மேல் இறங்குமுன்னும், கர்த்தருடைய கோபத்தின் நாள் உங்கள்மேல் வருமுன்னும்,
	<br /><br />
	2. நீங்கள் உங்களை உய்த்து ஆராய்ந்து சோதியுங்கள்.
	<br /><br />
	3. தேசத்திலுள்ள எல்லாச் சிறுமையானவர்களே, கர்த்தருடைய நியாயத்தை நடப்பிக்கிறவர்களே, அவரைத் தேடுங்கள்; நீதியைத் தேடுங்கள், மனத்தாழ்மையைத் தேடுங்கள்; அப்பொழுது ஒருவேளை கர்த்தருடைய கோபத்தின்நாளிலே மறைக்கப்படுவீர்கள்.
	<br /><br />
	4. காத்சா குடியற்று, அஸ்கலோன் பாழாகும்; அஸ்தோத்தைப் பட்டப்பகலிலே பறக்கடிப்பார்கள்; எக்ரோன் வேரோடே பிடுங்கப்படும்.
	<br /><br />
	5. சமுத்திரக்கரை குடிகளாகிய கிரேத்தியருக்கு ஐயோ! பெலிஸ்தரின் தேசமாகிய கானானே, கர்த்தருடைய வார்த்தை உனக்கு விரோதமாயிருக்கிறது; இனி உன்னில் குடியில்லாதபடிக்கு உன்னை அழிப்பேன்.
	<br /><br />
	6. சமுத்திரக்கரை தேசம் மேய்ப்பர் தங்கும் குடில்களும் ஆட்டுத்தோழங்களுமாகும்.
	<br /><br />
	7. அந்தத் தேசம் யூதா வம்சத்தாரில் மீதியானவர்களின் வசமாகும்; அவர்கள் அவ்விடங்களில் மந்தை மேய்ப்பார்கள்; அஸ்கலோனின் வீடுகளிலே சாயங்காலத்திலே படுத்துக்கொள்வார்கள்; அவர்களுடைய தேவனாகிய கர்த்தர் அவர்களை விசாரித்து, அவர்கள் சிறையிருப்பைத் திருப்புவார்.
	<br /><br />
	8. மோவாப் செய்த நிந்தனையையும், அம்மோன் புத்திரர் என் ஜனத்தை நிந்தித்து, அவர்கள் எல்லையைக் கடந்து பெருமைபாராட்டிச் சொன்ன தூஷணங்களையும் கேட்டேன்.
	<br /><br />
	9. ஆகையால் மோவாப் சோதோமைப்போலும், அம்மோன் புத்திரரின் தேசம் கொமோராவைப்போலுமாகி, காஞ்சொறி படரும் இடமும், உப்புப்பள்ளமும், நித்திய பாழுமாயிருக்கும்; என் ஜனத்தில் மீந்தவர்கள் அவர்களைக் கொள்ளையிட்டு, என் ஜாதியில் மீதியானவர்கள் அவர்களைச் சுதந்தரித்துக்கொள்வார்கள் என்பதை என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் உரைக்கிறார்.
	<br /><br />
	10. அவர்கள் சேனைகளுடைய கர்த்தரின் ஜனத்துக்கு விரோதமாய்ப் பெருமைபாராட்டி அவர்களை நிந்தித்தபடியினால், இது அவர்கள் அகங்காரத்துக்குப் பதிலாக அவர்களுக்குக் கிடைக்கும்.
	<br /><br />
	11. கர்த்தர் அவர்கள்மேல் கெடியாயிருப்பார்; பூமியிலுள்ள தேவர்களையெல்லாம் மெலிந்துபோகப்பண்ணுவார்; அப்பொழுது தீவுகளிலுள்ள சகல புறஜாதிகளும் அவரவர் தங்கள் தங்கள் ஸ்தானத்திலிருந்து அவரைப் பணிந்துகொள்வார்கள்.
	<br /><br />
	12. எத்தியோப்பியராகிய நீங்களும் என் பட்டயத்தினால் கொலைசெய்யப்படுவீர்கள்.
	<br /><br />
	13. அவர் தமது கையை வடதேசத்துக்கு விரோதமாய் நீட்டி, அசீரியாவை அழித்து, நினிவேயைப் பாழும் வனாந்தரத்துக்கொத்த வறட்சியுமான ஸ்தலமாக்குவார்.
	<br /><br />
	14. அதின் நடுவில் மந்தைகளும் ஜாதிஜாதியான சகல மிருகங்களும் படுத்துக்கொள்ளும்; அதினுடைய சிகரங்களின்மேல் நாரையும் கோட்டானும் இராத்தங்கும்; பலகணிகளில் கூவுகிற சத்தம் பிறக்கும்; வாசற்படிகளில் பாழ்க்கடிப்பு இருக்கும்; கேதுருமரங்களின் மச்சைத் திறப்பாக்கிப்போடுவார்.
	<br /><br />
	15. நான்தான், என்னைத்தவிர வேறொருவரும் இல்லை என்று தன் இருதயத்தில் சொல்லி, நிர்விசாரமாய் வாழ்ந்து களிகூர்ந்திருந்த நகரம் இதுவே; இது பாழும் மிருகஜீவன்களின் தாபரமுமாய்ப்போய்விட்டதே! அதின் வழியாய்ப்போகிறவன் எவனும் ஈசல் போட்டுத் தன் கையைக் கொட்டுவான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zephaniah2