import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial39 = () => {
  const verseNumber = 39;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 39 </title>
	<meta
          name="description"
          content="Ezekiel 39 | எசேக்கியேல்  39 |
          Ezekiel 39 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்போதும் மனுபுத்திரனே, நீ கோகுக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்துச் சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், மேசேக் தூபால் ஜாதிகளின் அதிபதியாகிய கோகே, இதோ, நான் உனக்கு விரோதமாக வருகிறேன்.
	<br /><br />
	2. நான் உன்னைத் திருப்பி உன்னை ஆறு துறடுகளால் இழுத்து, உன்னை வடபுறங்களிலிருந்து எழும்பவும் இஸ்ரவேல் மலைகளில் வரவும்பண்ணி,
	<br /><br />
	3. உன் வில்லை உன் இடதுகையிலிருந்து தட்டிவிட்டு, உன் அம்புகளை உன் வலதுகையிலிருந்து விழப்பண்ணுவேன்.
	<br /><br />
	4. நீயும் உன் எல்லா இராணுவங்களும் உன்னோடிருக்கிற ஜனங்களும் இஸ்ரவேல் மலைகளில் விழுவீர்கள்; உராய்ஞ்சுகிற சகலவித பட்சிகளுக்கும் வெளியின் மிருகங்களுக்கும் உன்னை இரையாகக் கொடுப்பேன்.
	<br /><br />
	5. விசாலமான வெளியில் விழுவாய்; நான் இதைச் சொன்னேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	6. நான் மாகோகிடத்திலும் தீவுகளில் நிர்விசாரமாய்க் குடியிருக்கிறவர்களிடத்திலும் அக்கினியை அனுப்புவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	7. இவ்விதமாய் நான் என் ஜனமாகிய இஸ்ரவேலின் நடுவிலே என் பரிசுத்தநாமத்தைத் தெரிவிப்பேன்; என் பரிசுத்த நாமத்தை இனிப் பரிசுத்தக்குலைச்சலாக்கவொட்டேன்; அதினால் நான் இஸ்ரவேலில் பரிசுத்தராகிய கர்த்தர் என்று புறஜாதிகள் அறிந்துகொள்வார்கள்.
	<br /><br />
	8. இதோ, அது வந்தது, அது சம்பவித்தது என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; நான் சொன்ன நாள் இதுவே.
	<br /><br />
	9. இஸ்ரவேல் பட்டணங்களின் குடிகள் வெளியே போய், கேடகங்களும், பரிசைகளும், வில்லுகளும், அம்புகளும், வளைதடிகளும், ஈட்டிகளுமாகிய ஆயுதங்களை எடுத்து எரிப்பார்கள்; ஏழுவருஷம் அவைகளை எடுத்து எரிப்பார்கள்.
	<br /><br />
	10. அவர்கள் வெளியிலிருந்து விறகு கொண்டுவராமலும் காடுகளில் வெட்டாமலும், ஆயுதங்களை எடுத்து எரிப்பார்கள்; அவர்கள் தங்களைக் கொள்ளையிட்டவர்களைக் கொள்ளையிட்டு, தங்களைச் சூறையாடினவர்களைச் சூறையாடுவார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	11. அந்நாளில் இஸ்ரவேல் தேசத்திலே சமுத்திரத்துக்குக் கிழக்கே பிரயாணக்காரரின் பள்ளத்தாக்கைப் புதைக்கிற ஸ்தானமாகக் கோகுக்குக் கொடுப்பேன்; அது வழிப்போக்கர் மூக்கைப் பொத்திக்கொண்டுபோகப்பண்ணும்; அங்கே கோகையும் அவனுடைய எல்லாச் சேனையையும் புதைத்து, அதை ஆமோன்கோகின் பள்ளத்தாக்கு என்பார்கள்.
	<br /><br />
	12. இஸ்ரவேல் வம்சத்தார், தேசத்தைச் சுத்தம்பண்ணும்படிக்கு அவர்களைப் புதைத்துத்தீர ஏழுமாதம் செல்லும்.
	<br /><br />
	13. தேசத்தின் ஜனங்களெல்லாரும் புதைத்துக்கொண்டிருப்பார்கள்; நான் மகிமைப்படும் அந்நாளிலே அது அவர்களுக்குக் கீர்த்தியாக இருக்கும் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	14. தேசத்தைச் சுத்தம்பண்ணுவதற்காக அதில் கிடக்கும் மற்றப் பிரேதங்களைப் புதைக்கும்படிக்கு நித்தமும் தேசத்தில் சுற்றித்திரியும் மனுஷரையும், சுற்றித்திரிகிறவர்களோடேகூடப் புதைக்கிறவர்களையும் தெரிந்து நியமிப்பார்கள்; ஏழுமாதங்கள் முடிந்தபின்பும் இவர்கள் தேடிக்கொண்டிருப்பார்கள்.
	<br /><br />
	15. தேசத்தில் சுற்றித்திரிகிறவர்கள் திரிந்துகொண்டிருப்பார்கள்; யாராவது ஒருவன் மனுஷனின் எலும்பைக் காணும்போது புதைக்கிறவர்கள் அதை ஆமோன்கோகுடைய பள்ளத்தாக்கிலே புதைக்குமட்டும் அதினண்டையிலே ஒரு அடையாளத்தை நாட்டுவான்.
	<br /><br />
	16. அந்த நகரத்துக்கு ஆமோனா என்று பெயரிடப்படும்; இவ்விதமாய்த் தேசத்தைச் சுத்தம்பண்ணுவார்கள்.
	<br /><br />
	17. மனுபுத்திரனே, கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நீ சகலவித பட்சிகளையும் வெளியில் இருக்கிற சகல மிருகங்களையும் நோக்கி: நீங்கள் ஏகமாய்க் கூடிக்கொண்டு, இஸ்ரவேலின் மலைகளில் நான் உங்களுக்காகச் செய்யும் யாகமாகிய மகா யாகத்துக்குச் சுற்றிலுமிருந்து வந்துசேர்ந்து, மாம்சம் தின்று இரத்தங்குடியுங்கள்.
	<br /><br />
	18. நீங்கள் பராக்கிரமசாலிகளின் மாம்சத்தைத் தின்று, பூமியினுடைய பிரபுக்களின் இரத்தத்தைக் குடிப்பீர்கள்; அவர்கள் எல்லாரும் பாசானிலே கொழுத்துப்போன ஆட்டுக்கடாக்களுக்கும், ஆட்டுக்குட்டிகளுக்கும் வெள்ளாட்டுக் கடாக்களுக்கும் காளைகளுக்கும் சமானமானவர்கள்.
	<br /><br />
	19. நான் உங்களுக்காகச் செய்யும் யாகத்திலே நீங்கள் திருப்தியாகுமளவும் கொழுப்பைத் தின்று, வெறியாகுமளவும் இரத்தத்தைக் குடிப்பீர்கள்.
	<br /><br />
	20. இவ்விதமாய் என் பந்தியிலே குதிரைகளையும், இரதவீரர்களையும், பராக்கிரமசாலிகளையும், சகல யுத்தவீரர்களையும் தின்று, திருப்தியாவீர்களென்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல்லு.
	<br /><br />
	21. இவ்விதமாய் என் மகிமையை நான் புறஜாதிகளுக்குள்ளே விளங்கப்பண்ணுவேன்; நான் செய்த என் நியாயத்தையும் அவர்கள்மேல் நான் வைத்த என் கையையும் எல்லா ஜாதிகளும் காண்பார்கள்.
	<br /><br />
	22. அன்றுமுதல் என்றும் நான் தங்கள் தேவனாகிய கர்த்தர் என்று இஸ்ரவேல் வம்சத்தார் அறிந்துகொள்வார்கள்.
	<br /><br />
	23. இஸ்ரவேல் வம்சத்தார் தங்கள் அக்கிரமத்தினிமித்தமே சிறைப்பட்டுப்போனார்கள் என்று அப்பொழுது புறஜாதிகள் அறிந்துகொள்வார்கள்; அவர்கள் எனக்கு விரோதமாய்த் துரோகம்பண்ணினபடியால், என் முகத்தை நான் அவர்களுக்கு மறைத்து, அவர்கள் சத்துருக்களின் கையில் அவர்களை ஒப்புக்கொடுத்தேன்; அவர்கள் அனைவரும் பட்டயத்தால் விழுந்தார்கள்.
	<br /><br />
	24. அவர்களுடைய அசுத்தத்துக்குத் தக்கதாகவும், அவர்களுடைய மீறுதல்களுக்குத்தக்கதாகவும், நான் அவர்களுக்குச் செய்து, என் முகத்தை அவர்களுக்கு மறைத்தேன்.
	<br /><br />
	25. ஆதலால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	26. அவர்கள் தங்கள் அவமானத்தையும், பயப்படுத்துவார் இல்லாமல், தாங்கள் சுகமாய்த் தங்கள் தேசத்தில் குடியிருக்கும்போது எனக்கு விரோதமாய்த் தாங்கள் செய்த எல்லாத் துரோகத்தையும் சுமந்து தீர்த்தபின்பு, நான் யாக்கோபின் சிறையிருப்பைத் திருப்பி, இஸ்ரவேல் வம்சமனைத்துக்கும் இரங்கி, என் பரிசுத்த நாமத்துக்காக வைராக்கியமாயிருப்பேன்.
	<br /><br />
	27. நான் அவர்களை ஜனசதளங்களிலிருந்து திரும்பிவரப்பண்ணி, அவர்களுடைய பகைஞரின் தேசங்களிலிருந்து அவர்களைக் கூட்டிக்கொண்டுவந்து, திரளான ஜாதிகளுடைய கண்களுக்கு முன்பாக அவர்களுக்குள் நான் பரிசுத்தர் என்று விளங்கும்போது,
	<br /><br />
	28. தங்களைப் புறஜாதிகளிடத்தில் சிறைப்பட்டுப்போகப்பண்ணின நான் தங்களில் ஒருவரையும் அங்கே அப்புறம் வைக்காமல், தங்களைத் தங்கள் சுயதேசத்திலே திரும்பக்கூட்டிக்கொண்டுவந்தேன் என்பதினால், நான் தங்கள் தேவனாகிய கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	29. நான் இஸ்ரவேல் வம்சத்தார்மேல் என் ஆவியை ஊற்றினபடியினால் என் முகத்தை இனி அவர்களுக்கு மறைக்கமாட்டேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial39