import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah23 = () => {
  const verseNumber = 23;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 23 </title>
	<meta
          name="description"
          content="Isaiah 23 | ஏசாயா 23 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தீருவின் பாரம். தர்ஷீஸ் கப்பல்களே, அலறுங்கள்; அது வீடு இல்லாதபடிக்கும், அதில் வருவார் இல்லாதபடிக்கும் பாழாக்கப்பட்டது; இந்தச் செய்தி கித்தீம் தேசத்திலிருந்து அவர்களுக்குத் தெரிவிக்கப்படுகிறது.
	<br /><br />
	2. தீவுக்குடிகளே, மவுனமாயிருங்கள்; சீதோனின் வர்த்தகர் சமுத்திரத்திலே யாத்திரைபண்ணி உன்னை நிரப்பினார்கள்.
	<br /><br />
	3. சீகோர் நதியின் மிகுந்த நீர்ப்பாய்ச்சல்களால் விளையும் பயிர்வகைகளும், ஆற்றங்கரையின் அறுப்பும் அதின் வருமானமாயிருந்தது; அது ஜாதிகளின் சந்தையாயிருந்தது.
	<br /><br />
	4. சீதோனே, வெட்கப்படு; நான் இனிக் கர்ப்பவேதனைப்படுகிறதும் இல்லை; பெறுகிறதும் இல்லை; இளைஞரை வளர்க்கிறதும் இல்லை; கன்னிகைகளை ஆதரிக்கிறதும் இல்லை என்று சமுத்திரக் கோட்டையான கடல்துறை சொல்லுகிறது.
	<br /><br />
	5. எகிப்தின் செய்தி கேட்கப்பட்டதினால் நோய் உண்டானதுபோல, தீருவின் செய்தி கேட்கப்படுவதினாலும் நோய் உண்டாகும்.
	<br /><br />
	6. கரைதுறைக் குடிகளே, நீங்கள் தர்ஷீஸ்மட்டும் புறப்பட்டுப்போய் அலறுங்கள்.
	<br /><br />
	7. பூர்வநாட்கள்முதல் நிலைபெற்றுக் களிகூர்ந்திருந்த உங்கள் பட்டணம் இதுதானா? பரதேசம்போய்ச் சஞ்சரிக்கிறதற்கு அவள் கால்களே அவளைத் தூரமாய்க் கொண்டுபோம்.
	<br /><br />
	8. கிரீடம் தரிப்பிக்கும் தீருவுக்கு விரோதமாக இதை யோசித்துத் தீர்மானித்தவர் யார்? அதின் வர்த்தகர் பிரபுக்களும், அதின் வியாபாரிகள் பூமியின் கனவான்களுமாமே.
	<br /><br />
	9. சர்வ சிங்காரத்தின் மேன்மையைக் குலைக்கவும், பூமியின் கனவான்கள் யாவரையும் கனஈனப்படுத்தவும், சேனைகளின் கர்த்தரே இதை யோசித்துத் தீர்மானித்தார்.
	<br /><br />
	10. தர்ஷீஸின் குமாரத்தியே, நதியைப்போல நீ உன் தேசத்தில் பாய்ந்துபோ, உனக்கு அணையில்லை.
	<br /><br />
	11. கர்த்தர் தமது கையைச் சமுத்திரத்தின்மேல் நீட்டி, ராஜ்யங்களைக் குலுங்கப்பண்ணினார்; கானானின் அரண்களை அழிக்க அவர் அதற்கு விரோதமாய்க் கட்டளைகொடுத்து:
	<br /><br />
	12. ஒடுங்குண்ட கன்னியாகிய சீதோன் குமாரத்தியே, இனிக் களிகூர்ந்துகொண்டிராய்; எழுந்து கித்தீமுக்குப் புறப்பட்டுப்போ, அங்கும் உனக்கு இளைப்பாறுதல் இல்லையென்றார்.
	<br /><br />
	13. கல்தேயருடைய தேசத்தைப் பார்; அந்த ஜனம் முன்னிருந்ததில்லை; அசீரியன் வனாந்தரத்தாருக்காக அதை அஸ்திபாரப்படுத்தினான்; அவர்கள் அதின் கோபுரங்களை உண்டாக்கி, அதின் அரமனைகளைக் கட்டினார்கள்; அவர் அதை அழிவுக்கென்று நியமித்தார்.
	<br /><br />
	14. தர்ஷீஸ் கப்பல்களே, அலறுங்கள்; உங்கள் அரண் பாழாக்கப்பட்டது.
	<br /><br />
	15. அக்காலத்திலே தீரு, ஒரு ராஜாவுடைய நாட்களின்படி, எழுபது வருஷம் மறக்கப்பட்டிருக்கும்; எழுபது வருஷங்களின் முடிவிலே தீருவுக்குச் சம்பவிப்பது வேசியின் பாடலுக்குச் சமானமாயிருக்கும்.
	<br /><br />
	16. மறக்கப்பட்ட வேசியே, நீ வீணையை எடுத்து நகரத்தைச் சுற்றித்திரி; நீ நினைக்கப்படும்படி அதை இனிதாக வாசித்துப் பல பாட்டுகளைப் பாடு.
	<br /><br />
	17. எழுபது வருஷங்களின் முடிவிலே கர்த்தர் வந்து தீருவைச் சந்திப்பார்; அப்பொழுது அது தன் பணையத்துக்கு திரும்பிவந்து, பூமியிலுள்ள சகல ராஜ்யங்களோடும் வேசித்தனம் பண்ணும்.
	<br /><br />
	18. அதின் வியாபாரமும், அதின் பணையமும் கர்த்தருக்குப் பரிசுத்தமாக்கப்படும்; அது பொக்கிஷமாய்ச் சேர்க்கப்படுவதும் இல்லை; பூட்டி வைக்கப்படுவதும் இல்லை; கர்த்தருடைய சமுகத்தில் வாசமாயிருக்கிறவர்கள் திருப்தியாகச் சாப்பிடவும் நல்ல வஸ்திரங்களைத் தரிக்கவும் அதின் வியாபாரம் அவர்களைச் சேரும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah23