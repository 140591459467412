import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah23 = () => {
  const verseNumber = 23;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 23 </title>
	<meta
          name="description"
          content="Jeremiah 23 | எரேமியா 23 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. என் மேய்ச்சலின் ஆடுகளைக் கெடுத்துச் சிதறடிக்கிற மேய்ப்பர்களுக்கு ஐயோ! என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	2. இஸ்ரவேலின் தேவனாகிய கர்த்தர் தமது ஜனத்தை மேய்க்கிற மேய்ப்பர்களுக்கு விரோதமாகச் சொல்லுகிறது என்னவென்றால், நீங்கள் என் ஆடுகளைப் பராமரியாமல், அவைகளைச் சிதறடித்து அவைகளைத் துரத்திவிட்டீர்கள்; இதோ, நான் உங்கள்பேரில் உங்கள் செய்கைகளின் பொல்லாப்புக்கேற்ற தண்டனையை உங்கள்மேல் வருவிப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. நான் என் ஆடுகளில் மீதியாயிருப்பவைகளைத் துரத்தியிருந்த எல்லாத் தேசங்களிலுமிருந்து சேர்த்து, அவைகளைத் திரும்ப அவைகளின் தொழுவங்களுக்குக் கொண்டுவருவேன்; அப்பொழுது அவைகள் பலுகிப் பெருகும்.
	<br /><br />
	4. அவைகளை மேய்க்கத்தக்கவர்களையும் அவைகள்மேல் ஏற்படுத்துவேன்; இனி அவைகள் பயப்படுவதுமில்லை, கலங்குவதுமில்லை, காணாமற்போவதுமில்லையென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது தாவீதுக்கு ஒரு நீதியுள்ள கிளையை எழும்பப்பண்ணுவேன்; அவர் ராஜாவாயிருந்து, ஞானமாய் ராஜரிகம்பண்ணி, பூமியிலே நியாயத்தையும் நீதியையும் நடப்பிப்பார்.
	<br /><br />
	6. அவர் நாட்களில் யூதா இரட்சிக்கப்படும், இஸ்ரவேல் சுகமாய் வாசம்பண்ணும்; அவருக்கு இடும் நாமம் நமது நீதியாயிருக்கிற கர்த்தர் என்பதே.
	<br /><br />
	7. ஆதலால், இதோ, நாட்கள் வரும், அப்பொழுது இஸ்ரவேல் புத்திரரை எகிப்துதேசத்திலிருந்து அழைத்துக்கொண்டுவந்த கர்த்தருடைய ஜீவனைக்கொண்டு சத்தியம்பண்ணாமல்,
	<br /><br />
	8. இஸ்ரவேல் வீட்டின் சந்ததியாரைத் தங்கள் சுயதேசத்தில் குடியிருக்கும்படிக்கு வடதேசத்திலும், நான் அவர்களைத் துரத்தியிருந்த எல்லா தேசங்களிலுமிருந்து அழைத்து வழி நடத்திக்கொண்டுவந்த கர்த்தருடைய ஜீவனைக்கொண்டு சத்தியம்பண்ணுவார்களென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. தீர்க்கதரிசிகளினிமித்தம் என் இருதயம் என் உள்ளத்திலே நொறுங்கியிருக்கிறது; என் எலும்புகளெல்லாம் அதிருகிறது; கர்த்தர் நிமித்தமும், அவருடைய பரிசுத்த வார்த்தைகளினிமித்தமும் நான் வெறித்திருக்கிற மனுஷனைப்போலவும் மதுபானம் மேற்கொண்டவனைப்போலவும் இருக்கிறேன்.
	<br /><br />
	10. தேசம் விபசாரக்காரரால் நிறைந்திருக்கிறது, தேசம் சாபத்தினால் துக்கிக்கிறது, வனாந்தரத்தின் மேய்ச்சல்கள் வாடிப்போகிறது; அவர்கள் ஓட்டம் பொல்லாதது; அவர்கள் பெலன் அநியாயமாயிருக்கிறது.
	<br /><br />
	11. தீர்க்கதரிசியும் ஆசாரியனும் மாயக்காரராயிருக்கிறார்கள்; என் ஆலயத்திலும் அவர்களுடைய பொல்லாப்பைக் கண்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. ஆதலால், அவர்கள் வழி அவர்களுக்கு இருட்டிலே சறுக்கலான வழியாயிருக்கும், துரத்துண்டு அதிலே விழுவார்கள்; அவர்கள் விசாரிக்கப்படும் வருஷத்திலே அவர்கள்மேல் பொல்லாப்பை வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. சமாரியாவின் தீர்க்கதரிசிகளிலோ மதிகேட்டைக் கண்டேன்; பாகாலைக்கொண்டு தீர்க்கதரிசனஞ்சொல்லி, இஸ்ரவேல் என்னும் என் ஜனத்தை மோசம்போக்கினார்கள்.
	<br /><br />
	14. எருசலேமின் தீர்க்கதரிசிகளிலும் திடுக்கிடத்தக்க காரியத்தைக் காண்கிறேன்; விபசாரம்பண்ணி, வஞ்சகமாய் நடந்து, ஒருவனும் தன் பொல்லாப்பை விட்டுத் திரும்பாதபடிக்குப் பொல்லாதவர்களின் கைகளைத் திடப்படுத்துகிறார்கள்; அவர்கள் எல்லாரும் எனக்குச் சோதோமைப்போலும், அதின் குடிகள் கொமோராவைப்போலும் இருக்கிறார்கள்.
	<br /><br />
	15. ஆதலால் சேனைகளின் கர்த்தர் தீர்க்கதரிசிகளைக்குறித்து: இதோ, நான் அவர்களுக்குப் புசிக்க எட்டியையும், குடிக்கப் பிச்சுக்கலந்த தண்ணீரையும் கொடுப்பேன்; எருசலேமின் தீர்க்கதரிசிகளிலிருந்து மாயமானது தேசமெங்கும் பரம்பிற்றே என்று சொல்லுகிறார்.
	<br /><br />
	16. உங்களுக்குத் தீர்க்கதரிசனம் சொல்லுகிற தீர்க்கதரிசிகளின் வார்த்தைகளைக் கேளாதிருங்கள்; அவர்கள் உங்களை வீண்பெருமை கொள்ளும்படி செய்கிறார்கள்; கர்த்தருடைய வாக்கை அல்ல, தாங்கள் யூகித்த தரிசனத்தைச் சொல்லுகிறார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. அவர்கள் என்னை அசட்டைபண்ணுகிறவர்களை நோக்கி: உங்களுக்குச் சமாதானம் இருக்குமென்று கர்த்தர் சொன்னாரென்று சொல்லுகிறதுமல்லாமல்; தங்கள் இருதயத்தின் கடினத்திலே நடக்கிற யாவரையும் நோக்கி: உங்கள்மேல் பொல்லாப்பு வராதென்றும் சொல்லுகிறார்கள்.
	<br /><br />
	18. கர்த்தருடைய ஆலோசனையில் கூடநின்று, அவருடைய வார்த்தையைக் கேட்டறிந்தவன் யார்? அவருடைய வார்த்தையைக் கவனித்துக் கேட்டவன் யார்?
	<br /><br />
	19. இதோ, கர்த்தருடைய பெருங்காற்றாகிய கொடிய புசல் புறப்பட்டது; அது துன்மார்க்கருடைய தலையின்மேல் உக்கிரமாய் மோதும்.
	<br /><br />
	20. கர்த்தர் தம்முடைய இருதயத்தின் நினைவுகளை நடப்பித்து நிறைவேற்றுமளவும், அவருடைய கோபம் தணியாது; கடைசிநாட்களில் அதை நன்றாய் உணருவீர்கள்.
	<br /><br />
	21. அந்தத் தீர்க்கதரிசிகளை நான் அனுப்பாதிருந்தும் அவர்கள் ஓடினார்கள்; அவர்களோடே நான் பேசாதிருந்தும் அவர்கள் தீர்க்கதரிசனஞ் சொன்னார்கள்.
	<br /><br />
	22. அவர்கள் என் ஆலோசனையிலே நிலைத்திருந்தார்களேயாகில், அப்பொழுது அவர்கள் என் வார்த்தைகளை ஜனங்களுக்குத் தெரிவித்து, அவர்களைத் தங்கள் பொல்லாத வழிகளையும் தங்கள் செய்கைகளின் பொல்லாப்பையும் விட்டுத் திருப்புவார்கள்.
	<br /><br />
	23. நான் சமீபத்திற்கு மாத்திரமா தேவன், தூரத்திற்கும் தேவன் அல்லவோ என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	24. யாவனாகிலும் தன்னை நான் காணாதபடிக்கு மறைவிடங்களில் ஒளித்துக்கொள்ளக்கூடுமோ என்று கர்த்தர் சொல்லுகிறார்; நான் வானத்தையும் பூமியையும் நிரப்புகிறவர் அல்லவோ என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	25. சொப்பனங்கண்டேன், சொப்பனங்கண்டேன் என்று, என் நாமத்தைச் சொல்லிப் பொய்த்தீர்க்கதரிசனம் உரைக்கிற தீர்க்கதரிசிகள் சொல்லுகிறதைக் கேட்டேன்.
	<br /><br />
	26. எதுவரைக்கும் இப்படியிருக்கும்? பொய்த்தீர்க்கதரிசனம் சொல்லுகிற தீர்க்கதரிசிகளின் இருதயத்தில் ஏதாகிலுமுண்டோ? இவர்கள் தங்கள் இருதயத்தின் வஞ்சகத்தையே தீர்க்கதரிசனமாகச் சொல்லுகிறவர்கள்.
	<br /><br />
	27. என் ஜனத்தின் பிதாக்கள் பாகாலினிமித்தம் என் நாமத்தை மறந்ததுபோல, இவர்கள் தங்கள் அயலாருக்கு விவரிக்கிற தங்கள் சொப்பனங்களினாலே என் நாமத்தை அவர்கள் மறக்கும்படி செய்யப்பார்க்கிறார்கள்.
	<br /><br />
	28. சொப்பனங்கண்ட தீர்க்கதரிசி சொப்பனத்தை விவரிப்பானாக; என் வார்த்தையுள்ளவனோ, என் வார்த்தையை உண்மையாய்ச் சொல்வானாக; கோதுமைக்குமுன் பதர் எம்மாத்திரம்? என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	29. என் வார்த்தை அக்கினியைப்போலும், கன்மலையை நொறுக்கும் சம்மட்டியைப்போலும் இருக்கிறதல்லவோ? என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	30. ஆகையால், இதோ, ஒவ்வொருவராய்த் தன்தன் அயலாரிடத்தில் என் வார்த்தையைத் திருட்டுத்தனமாய் எடுக்கிற தீர்க்கதரிசிகளுக்கு நான் விரோதி என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	31. இதோ, தங்கள் நாவின் சொல்லையே வழங்கி: அவர் அதை உரைத்தார் என்று சொல்லுகிற தீர்க்கதரிசிகளுக்கு நான் விரோதி என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	32. இதோ, பொய்ச்சொப்பனங்களைத் தீர்க்கதரிசனமாகச் சொல்லி, அவைகளை விவரித்து, என் ஜனத்தைத் தங்கள் பொய்களினாலும், தங்கள் வீம்புகளினாலும், மோசம்போக்குகிறவர்களுக்கு நான் விரோதி என்று கர்த்தர் சொல்லுகிறார்; நான் அவர்களை அனுப்பினதுமில்லை, அவர்களுக்குக் கற்பித்ததுமில்லை; அவர்கள் இந்த ஜனத்துக்கு ஒரு பிரயோஜனமாய் இருப்பதுமில்லை என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	33. கர்த்தராலே சுமரும் பாரம் என்னவென்று இந்த ஜனமாகிலும் ஒரு தீர்க்கதரிசியாகிலும் ஒரு ஆசாரியனாகிலும் உன்னைக் கேட்டால், உங்களைத் தள்ளிவிடுவேன் என்பதே பாரம் என்று நீ அவர்களுடனே சொல்லவேண்டும்.
	<br /><br />
	34. கர்த்தரால் சுமரும் பாரம் என்று சொல்லுகிற தீர்க்கதரிசியாகிலும் ஆசாரியனாகிலும் ஜனமாகிலும் சரி, அப்படிச் சொல்லுகிற மனுஷனையும் அவன் வீட்டாரையும் தண்டிப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	35. கர்த்தர் என்ன மறுஉத்தரவு கொடுத்தார்? கர்த்தர் என்ன சொன்னார்? என்று நீங்கள் அவரவர் தங்கள் அயலானையும் அவரவர் தங்கள் சகோதரனையும் கேட்பீர்களாக.
	<br /><br />
	36. ஆனால் கர்த்தரால் சுமரும் பாரம் என்கிற சொல்லை இனி வழங்காதிருப்பீர்களாக, அவனவன் வார்த்தையே அவனவனுக்குப் பாரமாயிருக்கும்; அதேனென்றால், நமது தேவனாகிய சேனைகளின் கர்த்தர் என்கிற ஜீவனுள்ள தேவனுடைய வார்த்தைகளைப் புரட்டுகிறீர்கள்.
	<br /><br />
	37. கர்த்தர் உனக்கு என்ன மறுஉத்தரவு கொடுத்தார்? கர்த்தர் என்ன சொன்னார்? என்று நீ தீர்க்கதரிசியைக் கேட்பாயாக.
	<br /><br />
	38. நீங்களோவெனில், கர்த்தரால் சுமரும் பாரம் என்று சொல்லுகிறபடியினாலே: கர்த்தரின் பாரம் என்று சொல்லாதிருங்களென்று நான் உங்களுக்குச் சொல்லி அனுப்பியும், நீங்கள் இந்த வார்த்தையைக் கர்த்தரின் பாரம் என்று சொல்லுகிறீர்களே.
	<br /><br />
	39. ஆதலால், இதோ, நான் உங்களை மறக்கவே மறந்து, உங்களையும், நான் உங்களுக்கும் உங்கள் பிதாக்களுக்கும் கொடுத்த நகரத்தையும், எனக்கு முன்பாக இராதபடிக்குக் கைவிட்டு,
	<br /><br />
	40. மறக்கப்படாத நித்திய நிந்தையையும், நித்திய இலச்சையையும் உங்கள்மேல் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah23