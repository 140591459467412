import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah27 = () => {
  const verseNumber = 27;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 27 </title>
	<meta
          name="description"
          content="Jeremiah 27 | எரேமியா 27 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோசியாவின் குமாரனும் யூதாவின் ராஜாவுமாகிய யோயாக்கீமுடைய ராஜ்யபாரத்தின் துவக்கத்திலே கர்த்தரால் எரேமியாவுக்கு உண்டான வார்த்தை:
	<br /><br />
	2. கர்த்தர் என்னை நோக்கி: நீ உனக்குக் கயிறுகளையும் நுகங்களையும் உண்டுபண்ணி, அவைகளை உன் கழுத்திலே பூட்டிக்கொண்டு,
	<br /><br />
	3. அவைகளை எருசலேமுக்குச் சிதேக்கியா ராஜாவினிடத்தில் வரும் ஸ்தானாபதிகள் கையிலே ஏதோமின் ராஜாவுக்கும், மோவாபின் ராஜாவுக்கும், அம்மோன் புத்திரரின் ராஜாவுக்கும், தீருவின் ராஜாவுக்கும், சீதோனின் ராஜாவுக்கும் அனுப்பி,
	<br /><br />
	4. அவர்கள் தங்கள் எஜமான்களுக்குச் சொல்லும்படிக் கற்பிக்கவேண்டுமென்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால்,
	<br /><br />
	5. நான் பூமியையும் மனுஷனையும் பூமியின்மேலுள்ள மிருகஜீவன்களையும் என் மகா பலத்தினாலும் ஓங்கிய என் புயத்தினாலும் உண்டாக்கினேன்; எனக்கு இஷ்டமானவனுக்கு அதைக் கொடுக்கிறேன்.
	<br /><br />
	6. இப்பொழுதும் நான் இந்தத் தேசங்களையெல்லாம் என் ஊழியக்காரனாகிய நேபுகாத்நேச்சார் என்கிற பாபிலோன் ராஜாவின் கையிலே கொடுத்தேன்; அவனுக்கு ஊழியஞ்செய்யும்படி வெளியின் மிருகஜீவன்களையும் கொடுத்தேன்.
	<br /><br />
	7. அவனுடைய தேசத்துக்குக் காலம் வருகிறவரையில் சகல ஜாதிகளும் அவனையும் அவனுடைய புத்திரபௌத்திரரையும் சேவிப்பார்கள்; அதின்பின்பு அநேகம் ஜாதிகளும் பெரிய ராஜாக்களும் அவனை அடிமைகொள்வார்கள்.
	<br /><br />
	8. எந்த ஜாதியாவது, எந்த ராஜ்யமாவது பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் என்பவனைச் சேவியாமலும், தன் கழுத்தைப் பாபிலோன் ராஜாவின் நுகத்துக்குக் கீழ்ப்படுத்தாமலும்போனால், அந்த ஜாதியை நான் அவன் கையாலே நிர்மூலமாக்குமளவும், பட்டயத்தாலும் பஞ்சத்தாலும் கொள்ளைநோயாலும் தண்டிப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. பாபிலோன் ராஜாவை நீங்கள் சேவிப்பதில்லையென்று உங்களுக்குச் சொல்லுகிற உங்கள் தீர்க்கதரிசிகளுக்கும், உங்கள் குறிகாரருக்கும், உங்கள் சொப்பனக்காரருக்கும், உங்கள் நாட்பார்க்கிறவர்களுக்கும், உங்கள் சூனியக்காரருக்கும் நீங்கள் செவிகொடாதிருங்கள்.
	<br /><br />
	10. நான் உங்களை உங்கள் தேசத்திலிருந்து தூரப்படுத்துகிறதற்கும், உங்களைத் துரத்திவிடுகிறதற்கும், நீங்கள் அழிகிறதற்குமாக அவர்கள் உங்களுக்குப் பொய்யான தீர்க்கதரிசனம் சொல்லுகிறார்கள்.
	<br /><br />
	11. ஆனாலும் எந்த ஜாதி தன் கழுத்தைப் பாபிலோன் ராஜாவின் நுகத்துக்குக் கீழ்ப்படுத்தி, அவனைச் சேவிக்குமோ, அந்த ஜாதியைத் தன் தேசத்தைப் பயிரிட்டு, அதிலே குடியிருந்து தாபரிக்கப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. இந்த எல்லா வார்த்தைகளின்படியே நான் யூதாவின் ராஜாவாகிய சிதேக்கியாவோடு பேசி: உங்கள் கழுத்தைப் பாபிலோன் ராஜாவின் நுகத்துக்குக் கீழ்ப்படுத்தி, அவனையும் அவன் ஜனத்தையும் சேவியுங்கள்; அப்பொழுது பிழைப்பீர்கள்.
	<br /><br />
	13. பாபிலோன் ராஜாவைச் சேவியாமற்போகிற ஜாதிக்கு விரோதமாகக் கர்த்தர் சொன்னதின்படியே, நீயும் உன் ஜனமும் பட்டயத்தாலும் பஞ்சத்தாலும் கொள்ளைநோயாலும் சாவானேன்?
	<br /><br />
	14. நீங்கள் பாபிலோன் ராஜாவைச் சேவிப்பதில்லையென்று உங்களுடனே சொல்லுகிற தீர்க்கதரிசிகளின் வார்த்தைகளைக் கேளாதிருங்கள்; அவர்கள் உங்களுக்குப் பொய்யான தீர்க்கதரிசனம் சொல்லுகிறார்கள்.
	<br /><br />
	15. நான் அவர்களை அனுப்பினதில்லை; நான் உங்களைத் துரத்திவிடுகிறதற்கும், நீங்களும் உங்களுக்குத் தீர்க்கதரிசனம் சொல்லுகிற தீர்க்கதரிசிகளும் அழிந்துபோகிறதற்கும் அல்லவோ இவர்கள் என் நாமத்தைச் சொல்லி, பொய்யான தீர்க்கதரிசனம் உரைக்கிறார்கள் என்று கர்த்தர் சொல்லுகிறார் என்றேன்.
	<br /><br />
	16. மேலும் நான் ஆசாரியரையும் இந்த எல்லா ஜனங்களையும் நோக்கி: இதோ, கர்த்தருடைய ஆலயத்தின் பணிமுட்டுகள் இப்பொழுது சீக்கிரத்திலே பாபிலோனிலிருந்து திரும்பிக் கொண்டுவரப்படுமென்று, உங்களுக்குத் தீர்க்கதரிசனம் சொல்லுகிற உங்களுடைய தீர்க்கதரிசிகளின் வார்த்தைகளைக் கேளாதிருங்கள் என்று கர்த்தர் சொல்லுகிறார்; அவர்கள் உங்களுக்குப் பொய்யான தீர்க்கதரிசனம் சொல்லுகிறார்கள்.
	<br /><br />
	17. அவர்களுக்குச் செவிகொடாதிருங்கள்; பாபிலோன் ராஜாவைச் சேவியுங்கள், அப்பொழுது பிழைப்பீர்கள்; இந்த நகரம் பாழாய்ப்போகவேண்டியதென்ன?
	<br /><br />
	18. அல்லது அவர்கள் தீர்க்கதரிசிகளாயிருந்து, அவர்களிடத்திலே கர்த்தருடைய வார்த்தை இருந்தால், கர்த்தருடைய ஆலயத்திலும், யூதாராஜாவின் அரமனையிலும், எருசலேமிலும் மீதியான பணிமுட்டுகள் பாபிலோனுக்குப் போகாதபடிக்கு அவர்கள் சேனைகளின் கர்த்தரை நோக்கி மன்றாடட்டுமே.
	<br /><br />
	19. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் யோயாக்கீமின் குமாரனாகிய எகொனியா என்னும் யூதாவின் ராஜாவையும், யூதாவிலும் எருசலேமிலிருந்த பெரியோர் அனைவரையும் எருசலேமிலிருந்து பாபிலோனுக்குச் சிறைபிடித்துக்கொண்டுபோகையில்,
	<br /><br />
	20. எடுக்காமல் விட்ட சகல தூண்களையும், கடல்தொட்டியையும், ஆதாரங்களையும், இந்த நகரத்தில் மீதியான மற்றப் பணிமுட்டுகளையுங்குறித்துச் சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்,
	<br /><br />
	21. கர்த்தருடைய ஆலயத்திலும், யூதாராஜாவின் அரமனையிலும், எருசலேமிலும் மீதியான அந்தப் பணிமுட்டுகள் பாபிலோனுக்குக் கொண்டுபோகப்படுமென்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	22. நான் அவைகளை விசாரிக்கும் நாள்வரைக்கும் அவைகள் அங்கே இருக்கும்; பின்பு அவைகளைத் திரும்ப இந்த ஸ்தலத்திற்குக் கொண்டுவரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார் என்றேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah27