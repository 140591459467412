import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 17 </title>
	<meta
          name="description"
          content="Book of Revelation Chapter 17 | வெளிப்படுத்தின விசேஷம்  | அதிகாரம்-17 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஏழு தலைகளும் பத்துக் கொம்புகளும் கொண்ட ஒரு மிருகத்தின் மீது ஒரு வேசியைக் கண்டேன். "தலைகளும் கொம்புகளும் ராஜாக்கள், ஆட்டுக்குட்டி வெல்வார்" என்றார். *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. ஏழு கலசங்களையுடைய அந்த ஏழு தூதரில் ஒருவன் வந்து என்னோடே பேசி: நீ வா, திரளான தண்ணீர்கள்மேல் உட்கார்ந்திருக்கிற மகா வேசியோடே பூமியின் ராஜாக்கள் வேசித்தனம்பண்ணினார்களே, அவளுடைய வேசித்தனமாகிய மதுவால் பூமியின் குடிகளும் வெறிகொண்டிருந்தார்களே;
	<br /><br />
	2. அவளுக்கு வருகிற ஆக்கினையை உனக்குக் காண்பிப்பேன் என்று சொல்லி;
	<br /><br />
	3. ஆவிக்குள் என்னை வனாந்தரத்திற்குக் கொண்டுபோனான். அப்பொழுது ஏழு தலைகளையும் பத்துக் கொம்புகளையும் உடையதும் தூஷணமான நாமங்களால் நிறைந்ததுமான சிவப்புநிறமுள்ள மிருகத்தின்மேல் ஒரு ஸ்திரீ ஏறியிருக்கக்கண்டேன்.
	<br /><br />
	4. அந்த ஸ்திரீ இரத்தாம்பரமும் சிவப்பான ஆடையுந்தரித்து, பொன்னினாலும் இரத்தினங்களினாலும் முத்துக்களினாலும் சிங்காரிக்கப்பட்டு, தன் வேசித்தனமாகிய அருவருப்புகளாலும் அசுத்தத்தாலும் நிறைந்த பொற்பாத்திரத்தைத் தன் கையிலே பிடித்திருந்தாள்.
	<br /><br />
	5. மேலும், இரகசியம், மகா பாபிலோன், வேசிகளுக்கும் பூமியிலுள்ள அருவருப்புகளுக்கும் தாய் என்னும் நாமம் அவள் நெற்றியில் எழுதியிருந்தது.
	<br /><br />
	6. அந்த ஸ்திரீ பரிசுத்தவான்களின் இரத்தத்தினாலும், இயேசுவினுடைய சாட்சிகளின் இரத்தத்தினாலும் வெறிகொண்டிருக்கிறதைக் கண்டேன்; அவளைக் கண்டு நான் மிகவும் ஆச்சரியப்பட்டேன்.
	<br /><br />
	7. அப்பொழுது, தூதனானவன் என்னை நோக்கி: ஏன் ஆச்சரியப்படுகிறாய்? இந்த ஸ்திரீயினுடைய இரகசியத்தையும், ஏழு தலைகளையும் பத்துக் கொம்புகளையுமுடையதாய் இவளைச் சுமக்கிற மிருகத்தினுடைய இரகசியத்தையும் உனக்குச் சொல்லுகிறேன்.
	<br /><br />
	8. நீ கண்ட மிருகம் முன்னே இருந்தது, இப்பொழுது இல்லை; அது பாதாளத்திலிருந்து ஏறிவந்து, நாசமடையப்போகிறது. உலகத்தோற்றமுதல் ஜீவபுஸ்தகத்தில் பேரெழுதப்பட்டிராத பூமியின் குடிகள், இருந்ததும், இராமற்போனதும், இனி இருப்பதுமாயிருக்கிற மிருகத்தைப்பார்த்து ஆச்சரியப்படுவார்கள்.
	<br /><br />
	9. ஞானமுள்ள மனம் இதிலே விளங்கும். அந்த ஏழு தலைகளும் அந்த ஸ்திரீ உட்கார்ந்திருக்கிற ஏழு மலைகளாம்.
	<br /><br />
	10. அவைகள் ஏழு ராஜாக்களாம்; இவர்களில் ஐந்துபேர் விழுந்தார்கள், ஒருவன் இருக்கிறான், மற்றவன் இன்னும் வரவில்லை; வரும்போது அவன் கொஞ்சக்காலம் தரித்திருக்கவேண்டும்.
	<br /><br />
	11. இருந்ததும் இராததுமாகிய மிருகமே எட்டாவதானவனும், அவ்வேழிலிருந்து தோன்றுகிறவனும், நாசமடையப்போகிறவனுமாயிருக்கிறான்.
	<br /><br />
	12. நீ கண்ட பத்துக் கொம்புகளும் பத்து ராஜாக்களாம்; இவர்கள் இன்னும் ராஜ்யம் பெறவில்லை; இவர்கள் மிருகத்துடனேகூட ஒருமணி நேரமளவும் ராஜாக்கள்போல அதிகாரம் பெற்றுக்கொள்ளுகிறார்கள்.
	<br /><br />
	13. இவர்கள் ஒரே யோசனையுள்ளவர்கள்; இவர்கள் தங்கள் வல்லமையையும் அதிகாரத்தையும் மிருகத்திற்குக் கொடுப்பார்கள்.
	<br /><br />
	14. இவர்கள் ஆட்டுக்குட்டியானவருடனே யுத்தம்பண்ணுவார்கள்; ஆட்டுக்குட்டியானவர் கர்த்தாதி கர்த்தரும் ராஜாதி ராஜாவுமாயிருக்கிறபடியால் அவர்களை ஜெயிப்பார்; அவரோடுகூட இருக்கிறவர்கள் அழைக்கப்பட்டவர்களும் தெரிந்துகொள்ளப்பட்டவர்களும் உண்மையுள்ளவர்களுமாயிருக்கிறார்கள் என்றான்.
	<br /><br />
	15. பின்னும் அவன் என்னை நோக்கி: அந்த வேசி உட்கார்ந்திருக்கிற தண்ணீர்களைக் கண்டாயே; அவைகள் ஜனங்களும் கூட்டங்களும் ஜாதிகளும் பாஷைக்காரருமாம்.
	<br /><br />
	16. நீ மிருகத்தின்மேல் கண்ட பத்துக் கொம்புகளானவர்கள் அந்த வேசியைப் பகைத்து, அவளைப் பாழும் நிர்வாணமுமாக்கி, அவளுடைய மாம்சத்தைப் பட்சித்து, அவளை நெருப்பினால் சுட்டெரித்துப்போடுவார்கள்.
	<br /><br />
	17. தேவன் தம்முடைய வார்த்தைகள் நிறைவேறுமளவும், அவர்கள் தமது யோசனையை நிறைவேற்றுகிறதற்கும், ஒரே யோசனையுள்ளவர்களாயிருந்து, தங்கள் ராஜ்யத்தை மிருகத்திற்குக் கொடுக்கிறதற்கும் அவர்களுடைய இருதயங்களை ஏவினார்.
	<br /><br />
	18. நீ கண்ட ஸ்திரீயானவள் பூமியின் ராஜாக்கள்மேல் ராஜ்யபாரம்பண்ணுகிற மகா நகரமேயாம் என்றான்
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h17>
*  *
			</h17>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation17