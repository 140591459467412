import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Philippians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Book of Philippians Bible in Tamil | பிலிப்பியர் 2 </title>
	<meta
          name="description"
          content="Philippians 2 | பிலிப்பியர் 2 |
          Book of Philippians | Tamil Bible Verse | Tamil Bible | பிலிப்பியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆதலால் கிறிஸ்துவுக்குள் யாதொரு ஆறுதலும், அன்பினாலே யாதொரு தேறுதலும், ஆவியின் யாதொரு ஐக்கியமும், யாதொரு உருக்கமான பட்சமும் இரக்கங்களும் உண்டானால்,
	<br /><br />
	2. நீங்கள் ஏக சிந்தையும் ஏக அன்புமுள்ளவர்களாயிருந்து, இசைந்த ஆத்துமாக்களாய் ஒன்றையே சிந்தித்து, என் சந்தோஷத்தை நிறைவாக்குங்கள்.
	<br /><br />
	3. ஒன்றையும் வாதினாலாவது வீண் பெருமையினாலாவது செய்யாமல், மனத்தாழ்மையினாலே ஒருவரையொருவர் தங்களிலும் மேன்மையானவர்களாக எண்ணக்கடவீர்கள்.
	<br /><br />
	4. அவனவன் தனக்கானவைகளையல்ல, பிறருக்கானவைகளையும் நோக்குவானாக.
	<br /><br />
	5. கிறிஸ்து இயேசுவிலிருந்த சிந்தையே உங்களிலும் இருக்கக்கடவது;
	<br /><br />
	6. அவர் தேவனுடைய ரூபமாயிருந்தும், தேவனுக்குச் சமமாயிருப்பதைக் கொள்ளையாடின பொருளாக எண்ணாமல்,
	<br /><br />
	7. தம்மைத்தாமே வெறுமையாக்கி, அடிமையின் ரூபமெடுத்து, மனுஷர் சாயலானார்.
	<br /><br />
	8. அவர் மனுஷரூபமாய்க் காணப்பட்டு, மரணபரியந்தம், அதாவது சிலுவையின் மரணபரியந்தமும் கீழ்ப்படிந்தவராகி, தம்மைத்தாமே தாழ்த்தினார்.
	<br /><br />
	9. ஆதலால் தேவன் எல்லாவற்றிற்கும் மேலாக அவரை உயர்த்தி,
	<br /><br />
	10. இயேசுவின் நாமத்தில் வானோர் பூதலத்தோர் பூமியின் கீழானோருடைய முழங்கால் யாவும் முடங்கும்படிக்கும்,
	<br /><br />
	11. பிதாவாகிய தேவனுக்கு மகிமையாக இயேசுகிறிஸ்து கர்த்தரென்று நாவுகள் யாவும் அறிக்கைபண்ணும்படிக்கும், எல்லா நாமத்திற்கும் மேலான நாமத்தை அவருக்குத் தந்தருளினார்.
	<br /><br />
	12. ஆதலால், எனக்குப் பிரியமானவர்களே, நீங்கள் எப்பொழுதும் கீழ்ப்படிகிறபடியே, நான் உங்களுக்குச் சமீபமாயிருக்கும்பொழுது மாத்திரமல்ல, நான் தூரமாயிருக்கிற இப்பொழுதும், அதிக பயத்தோடும் நடுக்கத்தோடும் உங்கள் இரட்சிப்பு நிறைவேறப் பிரயாசப்படுங்கள்.
	<br /><br />
	13. ஏனெனில் தேவனே தம்முடைய தயவுள்ள சித்தத்தின்படி விருப்பத்தையும் செய்கையையும் உங்களில் உண்டாக்குகிறவராயிருக்கிறார்.
	<br /><br />
	14. நான் வீணாக ஓடினதும் வீணாகப் பிரயாசப்பட்டதுமில்லையென்கிற மகிழ்ச்சி கிறிஸ்துவின் நாளில் எனக்கு உண்டாயிருப்பதற்கு, ஜீவவசனத்தைப் பிடித்துக்கொண்டு, உலகத்திலே சுடர்களைப்போலப் பிரகாசிக்கிற நீங்கள்,
	<br /><br />
	15. கோணலும் மாறுபாடுமான சந்ததியின் நடுவிலே குற்றமற்றவர்களும் கபடற்றவர்களும், தேவனுடைய மாசற்ற பிள்ளைகளுமாயிருக்கும்படிக்கு,
	<br /><br />
	16. எல்லாவற்றையும் முறுமுறுப்பில்லாமலும் தர்க்கிப்பில்லாமலும் செய்யுங்கள்.
	<br /><br />
	17. மேலும், உங்கள் விசுவாசமாகிய பலியின்மேலும் ஊழியத்தின்மேலும் நான் வார்க்கப்பட்டுப்போனாலும், நான் மகிழ்ந்து, உங்களனைவரோடுங்கூடச் சந்தோஷப்படுவேன்.
	<br /><br />
	18. இதினிமித்தம் நீங்களும் மகிழ்ந்து, என்னோடேகூடச் சந்தோஷப்படுங்கள்.
	<br /><br />
	19. அன்றியும், நானும் உங்கள் செய்திகளை அறிந்து மனத்தேறுதல் அடையும்படிக்குச் சீக்கிரமாய்த் தீமோத்தேயுவை உங்களிடத்தில் அனுப்பலாமென்று கர்த்தராகிய இயேசுவுக்குள் நம்பியிருக்கிறேன்.
	<br /><br />
	20. அதேனென்றால், உங்கள் காரியங்களை உண்மையாய் விசாரிக்கிறதற்கு என்னைப்போல மனதுள்ளவன் அவனையன்றி வேறொருவனும் என்னிடத்திலில்லை.
	<br /><br />
	21. மற்றவர்களெல்லாரும் கிறிஸ்து இயேசுவுக்குரியவைகளைத் தேடாமல், தங்களுக்குரியவைகளையே தேடுகிறார்கள்.
	<br /><br />
	22. தகப்பனுக்குப் பிள்ளை ஊழியஞ்செய்வதுபோல, அவன் என்னுடனேகூட சுவிசேஷத்தினிமித்தம் ஊழியஞ்செய்தானென்று அவனுடைய உத்தமகுணத்தை அறிந்திருக்கிறீர்கள்.
	<br /><br />
	23. ஆகையால் என் காரியங்கள் இன்னபடி நடக்கும் என்று நான் அறிந்தவுடனே அவனை அனுப்பலாமென்று நினைத்திருக்கிறேன்.
	<br /><br />
	24. அன்றியும் நானே சீக்கிரத்தில் வருவேனென்று கர்த்தருக்குள் நம்பிக்கையாயிருக்கிறேன்.
	<br /><br />
	25. மேலும், என் சகோதரனும், உடன்வேலையாளும், உடன்சேவகனும், உங்கள் ஸ்தானாபதியும், என் குறைச்சலுக்கு உதவிசெய்தவனுமான எப்பாப்பிரோதீத்துவை உங்களிடத்தில் அனுப்பவேண்டுமென்று எண்ணினேன்.
	<br /><br />
	26. அவன் உங்கள் எல்லார்மேலும் வாஞ்சையுள்ளவனும், தான் வியாதிப்பட்டதை நீங்கள் கேள்விப்பட்டதினாலே மிகவும் வியாகுலப்படுகிறவனுமாயிருந்தான்.
	<br /><br />
	27. அவன் வியாதிப்பட்டு மரணத்திற்குச் சமீபமாயிருந்தது மெய்தான். ஆகிலும், தேவன் அவனுக்கு இரங்கினார்; அவனுக்கு இரங்கினதுமல்லாமல், துக்கத்தின்மேல் துக்கம் எனக்கு உண்டாகாதபடிக்கு, எனக்கும் இரங்கினார்.
	<br /><br />
	28. ஆகையால் நீங்கள் அவனை மறுபடியும் கண்டு சந்தோஷப்படவும், என் துக்கங் குறையவும், அவனை அதிசீக்கிரமாய் அனுப்பினேன்.
	<br /><br />
	29. ஆனபடியினாலே நீங்கள் கர்த்தருக்குள் மிகுந்த சந்தோஷத்தோடே அவனை ஏற்றுக்கொண்டு, இப்படிப்பட்டவர்களைக் கனமாய் எண்ணுங்கள்.
	<br /><br />
	30. ஏனெனில் நீங்கள் எனக்குச் செய்யவேண்டிய ஊழியத்திலே உங்கள் குறைவை நிறைவாக்கும்படிக்கு, அவன் தன் பிராணனையும் எண்ணாமல், கிறிஸ்துவின் ஊழியத்தினிமித்தம் மரணத்திற்குச் சமீபமாயிருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Philippians2