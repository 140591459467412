import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Obadiah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஒபதியா 1 </title>
	<meta
          name="description"
          content="Obadiah 1 | ஒபதியா 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஒபதியாவின் தரிசனம்; கர்த்தராகிய ஆண்டவர் ஏதோமைக் குறித்துச் சொல்லுகிறது என்னவென்றால்: எழும்புங்கள், அதற்கு விரோதமாக யுத்தம்பண்ண எழும்புவோம் வாருங்கள் என்று அறிவிக்க ஸ்தானாபதி ஜாதிகளிடத்தில் அனுப்பப்படும் செய்தியைக் கர்த்தர் சொல்லக்கேட்டோம்.
	<br /><br />
	2. இதோ, நான் உன்னை ஜாதிகளில் சிறுகப்பண்ணினேன்; நீ மெத்தவும் அசட்டைபண்ணப்பட்டிருக்கிறாய்.
	<br /><br />
	3. கன்மலை வெடிப்புகளாகிய உன் உயர்ந்த ஸ்தானத்திலே குடியிருந்து, என்னைத் தரையிலே விழத்தள்ளுகிறவன் யார் என்று உன் இருதயத்தில் சொல்லுகிறவனே, உன் இருதயத்தின் அகந்தை உன்னை மோசம்போக்குகிறது.
	<br /><br />
	4. நீ கழுகைப்போல உயரப்போனாலும், நீ நட்சத்திரங்களுக்குள்ளே உன் கூட்டைக் கட்டினாலும், அவ்விடத்திலிருந்தும் உன்னை விழத்தள்ளுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. நீ எவ்வளவாய்ச் சங்கரிப்பட்டுப்போனாய்! திருடராகிலும் இராத்திரியில் கொள்ளையடிக்கிறவர்களாகிலும் உன்னிடத்தில் வந்தால், தங்களுக்குப் போதுமானமட்டும் திருடுவார்கள் அல்லவோ? திராட்சப்பழங்களை அறுக்கிறவர்கள் உன்னிடத்தில் வந்தால், சில பழங்களை விட்டுவிடுவார்கள் அல்லவோ?
	<br /><br />
	6. ஏசாவினுடையவைகள் எவ்வளவாய்த் தேடிப்பார்க்கப்பட்டது; அவனுடைய அந்தரங்கப் பொக்கிஷங்கள் எவ்வளவாய் ஆராய்ந்தெடுத்துக் கொள்ளப்பட்டது.
	<br /><br />
	7. உன்னோடு உடன்படிக்கை செய்த எல்லா மனுஷரும் உன்னை எல்லைமட்டும் துரத்திவிட்டார்கள்; உன்னோடு சமாதானமாயிருந்த மனுஷர் உன்னை மோசம்போக்கி, உன்னை மேற்கொண்டார்கள்; உன் அப்பத்தைச் சாப்பிட்டவர்கள் உனக்குக் கீழே கண்ணிவைத்தார்கள். அவனுக்கு உணர்வில்லை.
	<br /><br />
	8. அந்நாளில் அல்லவோ நான் ஏதோமிலுள்ள ஞானிகளையும், ஏசாவின் பர்வதத்திலுள்ள புத்திமான்களையும் அழிப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. தேமானே, ஏசாவின் பர்வதத்திலுள்ள மனுஷர் யாவரும் கொலையினால் சங்கரிக்கப்படும்படி உன் பராக்கிரமசாலிகள் கலங்குவார்கள்.
	<br /><br />
	10. நீ உன் சகோதரனாகிய யாக்கோபுக்குச் செய்த கொடுமையினிமித்தம் வெட்கம் உன்னை மூடும்; நீ முற்றிலும் சங்கரிக்கப்பட்டுப்போவாய்.
	<br /><br />
	11. நீ எதிர்த்துநின்ற நாளிலும், அந்நியர் அவன் சேனையைச் சிறைபிடித்துப்போன நாளிலும், மறுதேசத்தார் அவன் வாசல்களுக்குள் பிரவேசித்து எருசலேமின்பேரில் சீட்டுப்போட்ட காலத்தில், நீயும் அவர்களில் ஒருவனைப்போல் இருந்தாய்.
	<br /><br />
	12. உன் சகோதரன் அந்நியர்வசமான நாளாகிய அவனுடைய நாளை நீ பிரியத்தோடே பாராமலும், யூதா புத்திரருடைய அழிவின் நாளிலே அவர்கள் நிமித்தம் சந்தோஷப்படாமலும், அவர்கள் நெருக்கப்படுகிற நாளிலே நீ பெருமையாய்ப் பேசாமலும் இருக்க வேண்டியதாயிருந்தது.
	<br /><br />
	13. என் ஜனத்தின் ஆபத்துநாளிலே நீ அவர்கள் வாசல்களுக்குள் பிரவேசியாமலும், அவர்கள் ஆபத்துநாளிலே அவர்கள் அநுபவிக்கிற தீங்கை நீ பிரியத்தோடே பாராமலும், அவர்கள் ஆபத்துநாளிலே அவர்கள் ஆஸ்தியில் கைபோடாமலும்,
	<br /><br />
	14. அவர்களில் தப்பினவர்களைச் சங்கரிக்கும்படி வழிச்சந்திகளிலே நிற்காமலும், இக்கட்டு நாளில் அவர்களில் மீதியானவர்களைக் காட்டிக்கொடாமலும் இருக்கவேண்டியதாயிருந்தது.
	<br /><br />
	15. எல்லா ஜாதிகளுக்கும் விரோதமான நாளாகிய கர்த்தருடைய நாள் சமீபமாய் வந்திருக்கிறது; நீ செய்தபடியே உனக்கும் செய்யப்படும்; உன் செய்கையின் பலன் உன் தலையின்மேல் திரும்பும்.
	<br /><br />
	16. நீங்கள் என் பரிசுத்த பர்வதத்தின்மேல் மதுபானம்பண்ணினபடியே எல்லா ஜாதிகளும் எப்பொழுதும் மதுபானம்பண்ணுவார்கள்; அவர்கள் குடித்து விழுங்குவார்கள், இராதவர்களைப்போல் இருப்பார்கள்.
	<br /><br />
	17. ஆனாலும் சீயோன் பர்வதத்திலே தப்பியிருப்பார் உண்டு, அவர்கள் பரிசுத்தமாயிருப்பார்கள்; யாக்கோபின் வம்சத்தார் தங்களுடைய சுதந்தரங்களைச் சுதந்தரித்துக்கொள்வார்கள்.
	<br /><br />
	18. யாக்கோபு வம்சத்தார் அக்கினியும், யோசேப்பு வம்சத்தார் அக்கினிஜுவாலையுமாயிருப்பார்கள்; ஏசா வம்சத்தாரோ வைக்கோல் துரும்பாயிருப்பார்கள்; அவர்கள் இவர்களைக் கொளுத்தி, ஏசாவின் வம்சத்தில் மீதியிராதபடி இவர்களைப் பட்சிப்பார்கள்; கர்த்தர் இதைச் சொன்னார்.
	<br /><br />
	19. தென்தேசத்தார் ஏசாவின் மலையையும், சமனான தேசத்தார் பெலிஸ்தரின் தேசத்தையும் சுதந்தரித்துக்கொள்வார்கள்; அவர்கள் எப்பிராயீமின் நாட்டையும், சமாரியாவின் நாட்டையும் சுதந்தரித்துக்கொள்வார்கள்; பென்யமீன் மனுஷர் கீலேயாத்தையும் சுதந்தரித்துக்கொள்வார்கள்.
	<br /><br />
	20. சர்பாத்மட்டும் கானானியருக்குள்ளே சிறைப்பட்டுப்போன இஸ்ரவேல் புத்திரராகிய இந்தச் சேனையும், சேப்பாராத்தில் சிறைப்பட்டுப்போன எருசலேம் நகரத்தாரும் தென்திசைப் பட்டணங்களைச் சுதந்தரித்துக்கொள்வார்கள்.
	<br /><br />
	21. ஏசாவின் பர்வதத்தை நியாயந்தீர்ப்பதற்காக இரட்சகர்கள் சீயோன் பர்வதத்தில் வந்தேறுவார்கள்; அப்பொழுது ராஜ்யம் கர்த்தருடையதாய் இருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Obadiah1