import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 2 </title>
	<meta
          name="description"
          content="Matthew 2 | மத்தேயு 2 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஏரோதுராஜாவின் நாட்களில் யூதேயாவிலுள்ள பெத்லெகேமிலே இயேசு பிறந்தபொழுது, கிழக்கிலிருந்து சாஸ்திரிகள் எருசலேமுக்கு வந்து,
	<br /><br />
	2. யூதருக்கு ராஜாவாகப் பிறந்திருக்கிறவர் எங்கே? கிழக்கிலே அவருடைய நட்சத்திரத்தைக் கண்டு, அவரைப் பணிந்துகொள்ள வந்தோம் என்றார்கள்.
	<br /><br />
	3. ஏரோதுராஜா அதைக் கேட்டபொழுது, அவனும் அவனோடுகூட எருசலேம் நகரத்தார் அனைவரும் கலங்கினார்கள்.
	<br /><br />
	4. அவன் பிரதான ஆசாரியர் ஜனத்தின் வேதபாரகர் எல்லாரையும் கூடிவரச்செய்து: கிறிஸ்துவானவர் எங்கே பிறப்பாரென்று அவர்களிடத்தில் விசாரித்தான்.
	<br /><br />
	5. அதற்கு அவர்கள்: யூதேயாவிலுள்ள பெத்லெகேமிலே பிறப்பார்; அதேனென்றால்:
	<br /><br />
	6. யூதேயா தேசத்திலுள்ள பெத்லெகேமே, யூதாவின் பிரபுக்களில் நீ சிறியதல்ல; என் ஜனமாகிய இஸ்ரவேலை ஆளும் பிரபு உன்னிடத்திலிருந்து புறப்படுவார் என்று, தீர்க்கதரிசியினால் எழுதப்பட்டிருக்கிறது என்றார்கள்.
	<br /><br />
	7. அப்பொழுது ஏரோது, சாஸ்திரிகளை இரகசியமாய் அழைத்து, நட்சத்திரம் காணப்பட்ட காலத்தைக்குறித்து அவர்களிடத்தில் திட்டமாய் விசாரித்து:
	<br /><br />
	8. நீங்கள் போய், பிள்ளையைக்குறித்துத் திட்டமாய் விசாரியுங்கள்; நீங்கள் அதைக் கண்டபின்பு, நானும் வந்து அதைப் பணிந்துகொள்ளும்படி எனக்கு அறிவியுங்கள் என்று சொல்லி, அவர்களைப் பெத்லெகேமுக்கு அனுப்பினான்.
	<br /><br />
	9. ராஜா சொன்னதை அவர்கள் கேட்டுப் போகையில், இதோ, அவர்கள் கிழக்கிலே கண்ட நட்சத்திரம் பிள்ளை இருந்த ஸ்தலத்திற்குமேல் வந்து நிற்கும்வரைக்கும் அவர்களுக்குமுன் சென்றது.
	<br /><br />
	10. அவர்கள் அந்த நட்சத்திரத்தைக் கண்டபோது, மிகுந்த ஆனந்த சந்தோஷமடைந்தார்கள்.
	<br /><br />
	11. அவர்கள் அந்த வீட்டுக்குள் பிரவேசித்து, பிள்ளையையும் அதின் தாயாகிய மரியாளையும் கண்டு, சாஷ்டாங்கமாய் விழுந்து அதைப் பணிந்துகொண்டு, தங்கள் பொக்கிஷங்களைத் திறந்து, பொன்னையும் தூபவர்க்கத்தையும் வெள்ளைப்போளத்தையும் அதற்குக் காணிக்கையாக வைத்தார்கள்.
	<br /><br />
	12. பின்பு, அவர்கள் ஏரோதினிடத்திற்குத் திரும்பிப் போகவேண்டாமென்று சொப்பனத்தில் தேவனால் எச்சரிக்கப்பட்டு, வேறு வழியாய்த் தங்கள் தேசத்திற்குத் திரும்பிப்போனார்கள்.
	<br /><br />
	13. அவர்கள் போனபின்பு, கர்த்தருடைய தூதன் சொப்பனத்தில் யோசேப்புக்குக் காணப்பட்டு: ஏரோது பிள்ளையைக் கொலைசெய்யத் தேடுவான்; ஆதலால் நீ எழுந்து, பிள்ளையையும் அதின் தாயையும் கூட்டிக்கொண்டு எகிப்துக்கு ஓடிப்போய், நான் உனக்குச் சொல்லும்வரைக்கும் அங்கே இரு என்றான்.
	<br /><br />
	14. அவன் எழுந்து, இரவிலே பிள்ளையையும் அதின் தாயையும் கூட்டிக்கொண்டு, எகிப்துக்குப் புறப்பட்டுப்போய்,
	<br /><br />
	15. ஏரோதின் மரணபரியந்தம் அங்கே இருந்தான். எகிப்திலிருந்து என்னுடைய குமாரனை வரவழைத்தேன் என்று, தீர்க்கதரிசியின் மூலமாய்க் கர்த்தரால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது.
	<br /><br />
	16. அப்பொழுது ஏரோது தான் சாஸ்திரிகளால் வஞ்சிக்கப்பட்டதைக் கண்டு, மிகுந்த கோபமடைந்து, ஆட்களை அனுப்பி, தான் சாஸ்திரிகளிடத்தில் திட்டமாய் விசாரித்த காலத்தின்படியே, பெத்லெகேமிலும் அதின் சகல எல்லைகளிலுமிருந்த இரண்டு வயதுக்குட்பட்ட எல்லா ஆண்பிள்ளைகளையும் கொலைசெய்தான்.
	<br /><br />
	17. புலம்பலும் அழுகையும் மிகுந்த துக்கங்கொண்டாடலுமாகிய கூக்குரல் ராமாவிலே கேட்கப்பட்டது; ராகேல் தன் பிள்ளைகளுக்காக அழுது, அவைகள் இல்லாதபடியால் ஆறுதலடையாதிருக்கிறாள் என்று,
	<br /><br />
	18. எரேமியா தீர்க்கதரிசியினால் உரைக்கப்பட்டது அப்பொழுது நிறைவேறிற்று.
	<br /><br />
	19. ஏரோது இறந்தபின்பு, கர்த்தருடைய தூதன் எகிப்திலே யோசேப்புக்குச் சொப்பனத்தில் காணப்பட்டு:
	<br /><br />
	20. நீ எழுந்து, பிள்ளையையும் அதின் தாயையும் கூட்டிக்கொண்டு, இஸ்ரவேல் தேசத்துக்குப் போ; பிள்ளையின் பிராணனை வாங்கத்தேடினவர்கள் இறந்துபோனார்கள் என்றான்.
	<br /><br />
	21. அவன் எழுந்து பிள்ளையையும் அதின் தாயையும் கூட்டிக்கொண்டு இஸ்ரவேல் தேசத்துக்கு வந்தான்.
	<br /><br />
	22. ஆகிலும், அர்கெலாயு தன் தகப்பனாகிய ஏரோதின் பட்டத்துக்கு வந்து, யூதேயாவில் அரசாளுகிறான் என்று கேள்விப்பட்டு, அங்கே போகப் பயந்தான். அப்பொழுது, அவன் சொப்பனத்தில் தேவனால் எச்சரிக்கப்பட்டு, கலிலேயா நாட்டின் புறங்களிலே விலகிப்போய்,
	<br /><br />
	23. நாசரேத்து என்னும் ஊரிலே வந்து வாசம்பண்ணினான். நசரேயன் என்னப்படுவார் என்று, தீர்க்கதரிசிகளால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew2