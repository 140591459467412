import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah65 = () => {
  const verseNumber = 65;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 65 </title>
	<meta
          name="description"
          content="Isaiah 65 | ஏசாயா 65 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. என்னைக்குறித்து விசாரித்துக் கேளாதிருந்தவர்களாலே தேடப்பட்டேன்; என்னைத் தேடாதிருந்தவர்களாலே கண்டறியப்பட்டேன்; என்னுடைய நாமம் விளங்காதிருந்த ஜாதியை நோக்கி: இதோ, இங்கே இருக்கிறேன் என்றேன்.
	<br /><br />
	2. நலமல்லாத வழியிலே தங்கள் நினைவுகளின்படி நடக்கிற முரட்டாட்டமான ஜனத்தண்டைக்கு நாள் முழுதும் என் கைகளை நீட்டினேன்.
	<br /><br />
	3. அந்த ஜனங்கள் என் சந்நிதியிலே நித்தம் எனக்குக் கோபமுண்டாக்கி, தோட்டங்களிலே பலியிட்டு, செங்கற்களின்மேல் தூபங்காட்டி,
	<br /><br />
	4. பிரேதக்குழிகளண்டையில் உட்கார்ந்து, பாழான ஸ்தலங்களில் இராத்தங்கி, பன்றியிறைச்சியைத் தின்று, தங்கள் பாத்திரங்களில் அருவருப்பானவைகளின் ஆணத்தை வைத்திருந்து:
	<br /><br />
	5. நீ உன்மட்டிலிரு, என் சமீபத்தில் வராதே, உன்னைப்பார்க்கிலும் நான் பரிசுத்தன் என்று சொல்லுகிறார்கள்; இவர்கள் என் கோபத்தாலாகிய புகையும், நாள்முழுதும் எரிகிற அக்கினியுமாயிருப்பார்கள்.
	<br /><br />
	6. இதோ, அது எனக்கு முன்பாக எழுதியிருக்கிறது; நான் மவுனமாயிராமல் சரிக்குச் சரிக்கட்டுவேன்.
	<br /><br />
	7. உங்கள் அக்கிரமங்களுக்கும் மலைகளில் தூபங்காட்டி, மேடைகளின்மேல் என்னை நிந்தித்த உங்கள் பிதாக்களுடைய அக்கிரமங்களுக்கும் தக்கதாக அவர்கள் மடியிலே சரிக்கட்டுவேன்; நான் அவர்கள் முந்தின செய்கையின் பலனை அவர்கள் மடியிலே அளப்பேனென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	8. கர்த்தர் சொல்லுகிறது என்னவென்றால்: ஒரு திராட்சக்குலையில் இரசம் காணப்படும்போது அதை அழிக்காதே, அதிலே ஆசீர்வாதம் உண்டென்று சொல்லுகிறபடி, நான் என் ஊழியக்காரரினிமித்தம் அனைத்தையும் அழிக்காதபடி செய்வேன்.
	<br /><br />
	9. யாக்கோபிலிருந்து ஒரு வித்தையும், யூதாவிலிருந்து என் மலைகளைச் சுதந்தரிப்பவரையும் எழும்பப்பண்ணுவேன்; நான் தெரிந்துகொண்டவர்கள் அதைச் சுதந்தரித்துக்கொண்டு, என் ஊழியக்காரர் அங்கே வாசம்பண்ணுவார்கள்.
	<br /><br />
	10. என்னைத் தேடுகிற என் ஜனத்துக்குச் சாரோன் ஆட்டுத்தொழுவமாகவும், ஆகோரின் பள்ளத்தாக்கு மாட்டுக்கிடையாகவும் இருக்கும்.
	<br /><br />
	11. ஆனாலும் கர்த்தரை விட்டு, என் பரிசுத்த பர்வதத்தை மறந்து, காத் என்னும் தெய்வத்துக்குப் பந்தியை ஆயத்தம்பண்ணி, மேனி என்னும் தெய்வத்துக்குப் பானபலியை நிறைய வார்க்கிறவர்களே,
	<br /><br />
	12. உங்களை நான் பட்டயத்துக்கு எண்ணிக்கொடுப்பேன்; நீங்கள் அனைவரும் கொலைசெய்யப்படக் குனிவீர்கள்; நான் கூப்பிட்டும் நீங்கள் மறுஉத்தரவு கொடுக்கவில்லை; நான் பேசியும் நீங்கள் கேட்கவில்லை; என் பார்வைக்குப் பொல்லாப்பானதைச் செய்து, எனக்குப் பிரியமல்லாததைத் தெரிந்துகொண்டீர்கள்.
	<br /><br />
	13. ஆதலால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்: இதோ, என் ஊழியக்காரர் புசிப்பார்கள், நீங்களோ பசியாயிருப்பீர்கள்; இதோ, என் ஊழியக்காரர் குடிப்பார்கள், நீங்களோ தாகமாயிருப்பீர்கள்; இதோ, என் ஊழியக்காரர் சந்தோஷப்படுவார்கள், நீங்களோ வெட்கப்படுவீர்கள்.
	<br /><br />
	14. இதோ, என் ஊழியக்காரர் மனமகிழ்ச்சியினாலே கெம்பீரிப்பார்கள், நீங்களோ மனநோவினாலே அலறி, ஆவியின் முறிவினாலே புலம்புவீர்கள்.
	<br /><br />
	15. நான் தெரிந்துகொண்டவர்களுக்கு நீங்கள் உங்கள் நாமத்தைச் சாபவார்த்தையாகப் பின்வைத்துப்போவீர்கள்; கர்த்தராகிய ஆண்டவர் உன்னைக் கொன்றுபோட்டு, தம்முடைய ஊழியக்காரருக்கு வேறே நாமத்தைத் தரிப்பார்.
	<br /><br />
	16. அதினாலே பூமியிலே தன்னை ஆசீர்வதிக்கிறவன் சத்திய தேவனுக்குள் தன்னை ஆசீர்வதிப்பான்; பூமியிலே ஆணையிடுகிறவன் சத்திய தேவன்பேரில் ஆணையிடுவான்; முந்தின இடுக்கண்கள் மறக்கப்பட்டு, அவைகள் என் கண்களுக்கு மறைந்துபோயின.
	<br /><br />
	17. இதோ, நான் புதிய வானத்தையும் புதிய பூமியையும் சிருஷ்டிக்கிறேன்; முந்தினவைகள் இனி நினைக்கப்படுவதுமில்லை, மனதிலே தோன்றுவதுமில்லை.
	<br /><br />
	18. நான் சிருஷ்டிக்கிறதினாலே நீங்கள் என்றென்றைக்கும் மகிழ்ந்து களிகூர்ந்திருங்கள்; இதோ, எருசலேமைக் களிகூருதலாகவும், அதின் ஜனத்தை மகிழ்ச்சியாகவும் சிருஷ்டிக்கிறேன்.
	<br /><br />
	19. நான் எருசலேமின்மேல் களிகூர்ந்து, என் ஜனத்தின்மேல் மகிழ்ச்சியாயிருப்பேன்; அழுகையின் சத்தமும், கூக்குரலின் சத்தமும் அதில் இனிக் கேட்கப்படுவதில்லை.
	<br /><br />
	20. அங்கே இனி அற்ப ஆயுசுள்ள பாலகனும், தன் நாட்கள் பூரணமாகாத கிழவனும் உண்டாயிரார்கள்; நூறு வயதுசென்று மரிக்கிறவனும் வாலிபனென்று எண்ணப்படுவான், நூறு வயதுள்ளவனாகிய பாவியோ சபிக்கப்படுவான்.
	<br /><br />
	21. வீடுகளைக் கட்டி, அவைகளில் குடியிருப்பார்கள், திராட்சத்தோட்டங்களை நாட்டி, அவைகளின் கனியைப் புசிப்பார்கள்.
	<br /><br />
	22. அவர்கள் கட்டுகிறதும், வேறொருவர் குடியிருக்கிறதும், அவர்கள் நாட்டுகிறதும், வேறொருவர் கனிபுசிக்கிறதுமாயிருப்பதில்லை; ஏனெனில் விருட்சத்தின் நாட்களைப்போல என் ஜனத்தின் நாட்களிருக்கும்; நான் தெரிந்துகொண்டவர்கள் தங்கள் கைகளின் கிரியைகளை நெடுநாளாய் அநுபவிப்பார்கள்.
	<br /><br />
	23. அவர்கள் விருதாவாக உழைப்பதில்லை; அவர்கள் துன்பமுண்டாகப் பிள்ளைகளைப் பெறுவதுமில்லை; அவர்களும், அவர்களோடேகூட அவர்கள் சந்தானமும் கர்த்தராலே ஆசீர்வதிக்கப்பட்ட சந்ததியாயிருப்பார்கள்.
	<br /><br />
	24. அப்பொழுது அவர்கள் கூப்பிடுகிறதற்குமுன்னே நான் மறுஉத்தரவு கொடுப்பேன்; அவர்கள் பேசும்போதே நான் கேட்பேன்.
	<br /><br />
	25. ஓனாயும் ஆட்டுக்குட்டியும் ஒருமித்து மேயும்; சிங்கம் மாட்டைப்போல வைக்கோலைத் தின்னும்; புழுதி சர்ப்பத்துக்கு இரையாகும்; என் பரிசுத்த பர்வதமெங்கும் அவைகள் தீங்குசெய்வதுமில்லை, கேடுண்டாக்குவதுமில்லையென்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah65