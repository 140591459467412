import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 8 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 8 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 8 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. விக்கிரகங்களுக்குப் படைக்கப்பட்டவைகளைக்குறித்த விஷயத்தில், நம்மெல்லாருக்கும் அறிவு உண்டென்று நமக்குத் தெரியுமே. அறிவு இறுமாப்பை உண்டாக்கும், அன்போ பக்திவிருத்தியை உண்டாக்கும்.
	<br /><br />
	2. ஒருவன் தான் ஏதேனும் ஒன்றை அறிந்தவனென்று எண்ணிக்கொள்வானானால், ஒன்றையும் அறியவேண்டியபிரகாரமாக அவன் இன்னும் அறியவில்லை.
	<br /><br />
	3. தேவனில் அன்புகூருகிறவனெவனோ, அவன் தேவனால் அறியப்பட்டிருக்கிறான்.
	<br /><br />
	4. விக்கிரகங்களுக்குப் படைக்கப்பட்டவைகளைப் புசிக்கிற விஷயத்தைப்பற்றி, உலகத்திலே விக்கிரகமானது ஒன்றுமில்லையென்றும் ஒருவரேயன்றி வேறொரு தேவன் இல்லையென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	5. வானத்திலேயும் பூமியிலேயும் தேவர்கள் என்னப்படுகிறவர்கள் உண்டு; இப்படி அநேக தேவர்களும் அநேக கர்த்தாக்களும் உண்டாயிருந்தாலும்,
	<br /><br />
	6. பிதாவாகிய ஒரே தேவன் நமக்குண்டு, அவராலே சகலமும் உண்டாயிருக்கிறது; அவருக்கென்று நாமும் உண்டாயிருக்கிறோம். இயேசுகிறிஸ்து என்னும் ஒரே கர்த்தரும் நமக்குண்டு; அவர்மூலமாய்ச் சகலமும் உண்டாயிருக்கிறது, அவர் மூலமாய் நாமும் உண்டாயிருக்கிறோம்.
	<br /><br />
	7. ஆகிலும், இந்த அறிவு எல்லாரிடத்திலும் இல்லை. சிலர் இன்றையவரைக்கும் விக்கிரகத்தை ஒரு பொருளென்று எண்ணி, விக்கிரகத்துக்குப் படைக்கப்பட்டதைப் புசிக்கிறார்கள்; அவர்களுடைய மனச்சாட்சி பலவீனமாயிருப்பதால் அசுசிப்படுகிறது.
	<br /><br />
	8. போஜனமானது நம்மைத் தேவனுக்கு உகந்தவர்களாக்கமாட்டாது; என்னத்தினாலெனில், புசிப்பதினால் நமக்கு ஒரு மேன்மையுமில்லை, புசியாதிருப்பதினால் நமக்கு ஒரு குறைவுமில்லை.
	<br /><br />
	9. ஆகிலும் இதைக்குறித்து உங்களுக்கு உண்டாயிருக்கிற அதிகாரம் எவ்விதத்திலும் பலவீனருக்குத் தடுக்கலாகாதபடிக்குப் பாருங்கள்.
	<br /><br />
	10. எப்படியெனில், அறிவுள்ளவனாகிய உன்னை விக்கிரகக்கோவிலிலே பந்தியிருக்க ஒருவன் கண்டால், பலவீனனாயிருக்கிற அவனுடைய மனச்சாட்சி விக்கிரகங்களுக்குப் படைக்கப்பட்டவைகளைப் புசிப்பதற்குத் துணிவுகொள்ளுமல்லவா?
	<br /><br />
	11. பலவீனமுள்ள சகோதரன் உன் அறிவினிமித்தம் கெட்டுப்போகலாமா? அவனுக்காகக் கிறிஸ்து மரித்தாரே.
	<br /><br />
	12. இப்படிச் சகோதரருக்கு விரோதமாய்ப் பாவஞ்செய்து, பலவீனமுள்ள அவர்களுடைய மனச்சாட்சியைப் புண்படுத்துகிறதினாலே, நீங்கள் கிறிஸ்துவுக்கு விரோதமாய்ப் பாவஞ்செய்கிறீர்கள்.
	<br /><br />
	13. ஆதலால் போஜனம் என் சகோதரனுக்கு இடறலுண்டாக்கினால், நான் என் சகோதரனுக்கு இடறலுண்டாக்காதபடிக்கு, என்றைக்கும் மாம்சம் புசியாதிருப்பேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians8