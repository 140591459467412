import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 6 </title>
	<meta
          name="description"
          content="Matthew 6 | மத்தேயு 6 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மனுஷர் காணவேண்டுமென்று அவர்களுக்கு முன்பாக உங்கள் தர்மத்தைச் செய்யாதபடிக்கு எச்சரிக்கையாயிருங்கள்; செய்தால், பரலோகத்திலிருக்கிற உங்கள் பிதாவினிடத்தில் உங்களுக்குப் பலனில்லை.
	<br /><br />
	2. ஆகையால் நீ தர்மஞ்செய்யும்போது, மனுஷரால் புகழப்படுவதற்கு, மாயக்காரர் ஆலயங்களிலும் வீதிகளிலும் செய்வதுபோல, உனக்கு முன்பாகத் தாரை ஊதுவியாதே; அவர்கள் தங்கள் பலனை அடைந்து தீர்ந்ததென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	3. நீயோ தர்மஞ்செய்யும்போது, உன் தர்மம் அந்தரங்கமாயிருப்பதற்கு, உன் வலதுகை செய்கிறதை உன் இடதுகை அறியாதிருக்கக்கடவது.
	<br /><br />
	4. அப்பொழுது அந்தரங்கத்தில் பார்க்கிற உன் பிதா தாமே உனக்கு வெளியரங்கமாய்ப் பலனளிப்பார்.
	<br /><br />
	5. அன்றியும் நீ ஜெபம்பண்ணும்போது மாயக்காரரைப் போலிருக்கவேண்டாம்; மனுஷர் காணும்படியாக அவர்கள் ஜெப ஆலயங்களிலும் வீதிகளின் சந்திகளிலும் நின்று ஜெபம்பண்ண விரும்புகிறார்கள்; அவர்கள் தங்கள் பலனை அடைந்து தீர்ந்ததென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	6. நீயோ ஜெபம்பண்ணும்போது, உன் அறைவீட்டுக்குள் பிரவேசித்து, உன் கதவைப் பூட்டி, அந்தரங்கத்திலிருக்கிற உன் பிதாவை நோக்கி ஜெபம்பண்ணு; அப்பொழுது, அந்தரங்கத்தில் பார்க்கிற உன் பிதா வெளியரங்கமாய் உனக்குப் பலனளிப்பார்.
	<br /><br />
	7. அன்றியும் நீங்கள் ஜெபம்பண்ணும்போது, அஞ்ஞானிகளைப்போல வீண்வார்த்தைகளை அலப்பாதேயுங்கள்; அவர்கள், அதிக வசனிப்பினால் தங்கள் ஜெபம் கேட்கப்படுமென்று நினைக்கிறார்கள்.
	<br /><br />
	8. அவர்களைப்போல நீங்கள் செய்யாதிருங்கள்; உங்கள் பிதாவை நோக்கி நீங்கள் வேண்டிக்கொள்ளுகிறதற்கு முன்னமே உங்களுக்கு இன்னது தேவை என்று அவர் அறிந்திருக்கிறார்.
	<br /><br />
	9. நீங்கள் ஜெபம்பண்ணவேண்டிய விதமாவது: பரமண்டலங்களிலிருக்கிற எங்கள் பிதாவே, உம்முடைய நாமம் பரிசுத்தப்படுவதாக.
	<br /><br />
	10. உம்முடைய ராஜ்யம் வருவதாக; உம்முடைய சித்தம் பரமண்டலத்திலே செய்யப்படுகிறதுபோலப் பூமியிலேயும் செய்யப்படுவதாக.
	<br /><br />
	11. எங்களுக்கு வேண்டிய ஆகாரத்தை இன்று எங்களுக்குத் தாரும்.
	<br /><br />
	12. எங்கள் கடனாளிகளுக்கு நாங்கள் மன்னிக்கிறதுபோல எங்கள் கடன்களை எங்களுக்கு மன்னியும்.
	<br /><br />
	13. எங்களைச் சோதனைக்குட்படப்பண்ணாமல், தீமையினின்று எங்களை இரட்சித்துக்கொள்ளும், ராஜ்யமும், வல்லமையும், மகிமையும் என்றென்றைக்கும் உம்முடையவைகளே, ஆமென், என்பதே.
	<br /><br />
	14. மனுஷருடைய தப்பிதங்களை நீங்கள் அவர்களுக்கு மன்னித்தால், உங்கள் பரமபிதா உங்களுக்கும் மன்னிப்பார்.
	<br /><br />
	15. மனுஷருடைய தப்பிதங்களை நீங்கள் அவர்களுக்கு மன்னியாதிருந்தால், உங்கள் பிதா உங்கள் தப்பிதங்களையும் மன்னியாதிருப்பார்.
	<br /><br />
	16. நீங்கள் உபவாசிக்கும்போது, மாயக்காரரைப்போல முகவாடலாய் இராதேயுங்கள்; அவர்கள் உபவாசிக்கிறதை மனுஷர் காணும்பொருட்டாக, தங்கள் முகங்களை வாடப்பண்ணுகிறார்கள்; அவர்கள் தங்கள் பலனை அடைந்து தீர்ந்ததென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	17. நீயோ உபவாசிக்கும்போது, அந்த உபவாசம் மனுஷர்களுக்குக் காணப்படாமல், அந்தரங்கத்தில் இருக்கிற உன் பிதாவுக்கே காணப்படும்படியாக, உன் தலைக்கு எண்ணெய் பூசி, உன் முகத்தைக் கழுவு.
	<br /><br />
	18. அப்பொழுது, அந்தரங்கத்தில் பார்க்கிற உன் பிதா உனக்கு வெளியரங்கமாய்ப் பலனளிப்பார்.
	<br /><br />
	19. பூமியிலே உங்களுக்குப் பொக்கிஷங்களைச் சேர்த்துவைக்கவேண்டாம்; இங்கே பூச்சியும் துருவும் அவைகளைக் கெடுக்கும்; இங்கே திருடரும் கன்னமிட்டுத் திருடுவார்கள்.
	<br /><br />
	20. பரலோகத்திலே உங்களுக்குப் பொக்கிஷங்களைச் சேர்த்துவையுங்கள்; அங்கே பூச்சியாவது துருவாவது கெடுக்கிறதும் இல்லை; அங்கே திருடர் கன்னமிட்டுத் திருடுகிறதும் இல்லை.
	<br /><br />
	21. உங்கள் பொக்கிஷம் எங்கேயிருக்கிறதோ அங்கே உங்கள் இருதயமும் இருக்கும்.
	<br /><br />
	22. கண்ணானது சரீரத்தின் விளக்காயிருக்கிறது; உன் கண் தெளிவாயிருந்தால், உன் சரீரம் முழுவதும் வெளிச்சமாயிருக்கும்.
	<br /><br />
	23. உன் கண் கெட்டதாயிருந்தால், உன் சரீரம் முழுவதும் இருளாயிருக்கும்; இப்படி உன்னிலுள்ள வெளிச்சம் இருளாயிருந்தால், அவ்விருள் எவ்வளவு அதிகமாயிருக்கும்!
	<br /><br />
	24. இரண்டு எஜமான்களுக்கு ஊழியஞ் செய்ய ஒருவனாலும் கூடாது; ஒருவனைப் பகைத்து, மற்றவனைச் சிநேகிப்பான்; அல்லது ஒருவனைப் பற்றிக்கொண்டு, மற்றவனை அசட்டைபண்ணுவான்; தேவனுக்கும் உலகப்பொருளுக்கும் ஊழியஞ்செய்ய உங்களால் கூடாது.
	<br /><br />
	25. ஆகையால், என்னத்தை உண்போம், என்னத்தைக் குடிப்போம் என்று உங்கள் ஜீவனுக்காகவும்; என்னத்தை உடுப்போம் என்று உங்கள் சரீரத்துக்காகவும் கவலைப்படாதிருங்கள் என்று, உங்களுக்குச் சொல்லுகிறேன்; ஆகாரத்தைப்பார்க்கிலும் ஜீவனும், உடையைப்பார்க்கிலும் சரீரமும் விசேஷித்தவைகள் அல்லவா?
	<br /><br />
	26. ஆகாயத்துப் பட்சிகளைக் கவனித்துப்பாருங்கள்; அவைகள் விதைக்கிறதுமில்லை, அறுக்கிறதுமில்லை, களஞ்சியங்களில் சேர்த்துவைக்கிறதுமில்லை; அவைகளையும் உங்கள் பரமபிதா பிழைப்பூட்டுகிறார்; அவைகளைப்பார்க்கிலும் நீங்கள் விசேஷித்தவர்கள் அல்லவா?
	<br /><br />
	27. கவலைப்படுகிறதினாலே உங்களில் எவன் தன் சரீர அளவோடு ஒரு முழத்தைக் கூட்டுவான்?
	<br /><br />
	28. உடைக்காகவும் நீங்கள் கவலைப்படுகிறதென்ன? காட்டுப் புஷ்பங்கள் எப்படி வளருகிறதென்று கவனித்துப்பாருங்கள்; அவைகள் உழைக்கிறதுமில்லை, நூற்கிறதுமில்லை;
	<br /><br />
	29. என்றாலும், சாலொமோன் முதலாய்த் தன் சர்வ மகிமையிலும் அவைகளில் ஒன்றைப்போலாகிலும் உடுத்தியிருந்ததில்லை என்று, உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	30. அற்ப விசுவாசிகளே! இன்றைக்கு இருந்து நாளைக்கு அடுப்பிலே போடப்படும் காட்டுப் புல்லுக்குத் தேவன் இவ்விதமாக உடுத்துவித்தால், உங்களுக்கு உடுத்துவிப்பது அதிக நிச்சயமல்லவா?
	<br /><br />
	31. ஆகையால், என்னத்தை உண்போம், என்னத்தைக் குடிப்போம், என்னத்தை உடுப்போம் என்று, கவலைப்படாதிருங்கள்.
	<br /><br />
	32. இவைகளையெல்லாம் அஞ்ஞானிகள் நாடித்தேடுகிறார்கள்; இவைகளெல்லாம் உங்களுக்கு வேண்டியவைகள் என்று உங்கள் பரமபிதா அறிந்திருக்கிறார்.
	<br /><br />
	33. முதலாவது தேவனுடைய ராஜ்யத்தையும் அவருடைய நீதியையும் தேடுங்கள், அப்பொழுது இவைகளெல்லாம் உங்களுக்குக்கூடக் கொடுக்கப்படும்.
	<br /><br />
	34. ஆகையால், நாளைக்காகக் கவலைப்படாதிருங்கள்; நாளையத்தினம் தன்னுடையவைகளுக்காகக் கவலைப்படும். அந்தந்த நாளுக்கு அதினதின் பாடு போதும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew6