import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 8 </title>
	<meta
          name="description"
          content="Ezekiel 8 | எசேக்கியேல்  8 |
          Ezekiel 8 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆறாம் வருஷத்து ஆறாம் மாதம் ஐந்தாந்தேதியிலே, நான் என் வீட்டில் உட்கார்ந்திருக்கிறபோதும், யூதாவின் மூப்பர்கள் எனக்கு முன்பாக உட்கார்ந்திருக்கிறபோதும், கர்த்தராகிய ஆண்டவருடைய கரம் அங்கே என்மேல் அமர்ந்தது.
	<br /><br />
	2. அப்பொழுது இதோ, அக்கினிச்சாயலாய்த் தோன்றுகிற ஒருவரைக் கண்டேன்; அவருடைய இடுப்புக்குக் கீழெல்லாம் அக்கினியும் அவருடைய இடுப்புக்கு மேலெல்லாம் சொகுசாவைப்போல் பிரகாசிக்கிற சாயலுமாயிருந்தது.
	<br /><br />
	3. கைபோல் தோன்றினதை அவர் நீட்டி, என் தலைமயிரைப் பிடித்து என்னைத் தூக்கினார்; ஆவியானவர் என்னைப் பூமிக்கும் வானத்துக்கும் நடுவே கொண்டுபோய், தேவதரிசனத்திலே என்னை எருசலேமில் வடதிசைக்கு எதிரான உள்வாசலின் நடையிலே விட்டார்; அங்கே எரிச்சலுண்டாக்குகிற விக்கிரகத்தின் ஸ்தானம் இருந்தது.
	<br /><br />
	4. இதோ, நான் பள்ளத்தாக்கிலே கண்டிருந்த தரிசனத்துக்குச் சரியாக இஸ்ரவேலின் தேவனுடைய மகிமை அங்கே விளங்கினது.
	<br /><br />
	5. அவர் என்னைப்பார்த்து: மனுபுத்திரனே, உன் கண்களை ஏறெடுத்து, வடக்கே பார் என்றார்; அப்பொழுது நான் என் கண்களை ஏறெடுத்து, வடக்கே பார்த்தேன்; இதோ, பலிபீடத்தின் வாசலுக்கு வடக்கே பார்த்தேன்; நடையிலே எரிச்சலுண்டாக்குகிற அந்த விக்கிரகம் இருந்தது.
	<br /><br />
	6. அவர் என்னை நோக்கி: மனுபுத்திரனே, அவர்கள் செய்கிறதைக் காண்கிறாயா? என் பரிசுத்த ஸ்தலத்தைவிட்டு என்னைத் தூரமாய்ப் போகப்பண்ணும்படியான இஸ்ரவேல் வம்சத்தார் இங்கே செய்கிற மிகுந்த அருவருப்புகளைக் காண்கிறாய் அல்லவா? இதிலும் அதிக அருவருப்புகளை இன்னமும் காண்பாய் என்று சொல்லி,
	<br /><br />
	7. என்னைப் பிராகாரத்தின் வாசலுக்குக் கொண்டுபோனார்; அப்பொழுது இதோ, சுவரில் ஒரு துவாரத்தைக் கண்டேன்.
	<br /><br />
	8. அவர் என்னை நோக்கி: மனுபுத்திரனே, நீ சுவரிலே துவாரமிடு என்றார்; நான் சுவரிலே துவாரமிட்டபோது, இதோ, ஒரு வாசல் இருந்தது.
	<br /><br />
	9. அவர் என்னைப்பார்த்து: நீ உள்ளே போய், அவர்கள் இங்கே செய்கிற கொடிய அருவருப்புகளைப் பார் என்றார்.
	<br /><br />
	10. நான் உள்ளே போய்ப் பார்த்தபோது, இதோ, சகலவித ஊரும்பிராணிகளும் அருவருப்பான மிருகங்களுமாகிய இவைகளின் சுரூபங்களும், இஸ்ரவேல் வம்சத்தாருடைய நரகலான சகல விக்கிரகங்களும் சுவரில் சுற்றிலும் சித்திரந்தீரப்பட்டிருந்தன.
	<br /><br />
	11. இஸ்ரவேல் வம்சத்தாரின் மூப்பரில் எழுபதுபேரும், அவர்களின் நடுவிலே சாப்பானுடைய குமாரனாகிய யசனியாவும், அவனவன் தன்தன் கையிலே தன்தன் தூபகலசத்தைப் பிடித்துக்கொண்டு, அவைகளுக்கு முன்பாக நின்றார்கள்; தூபவர்க்கத்தினால் மிகுந்த புகை எழும்பிற்று.
	<br /><br />
	12. அப்பொழுது அவர் என்னை நோக்கி: மனுபுத்திரனே, இஸ்ரவேல் வம்சத்தாரின் மூப்பர்கள் அந்தகாரத்திலே அவரவர் தங்கள் விக்கிரகங்களின் சித்திர விநோத அறைகளில் செய்கிறதை நீ கண்டாயா? கர்த்தர் எங்களைப் பார்க்கிறதில்லை; கர்த்தர் தேசத்தைக் கைவிட்டார் என்று சொல்லுகிறார்களே என்றார்.
	<br /><br />
	13. பின்னும் அவர்கள் செய்கிற அதிக அருவருப்புகளை இன்னமும் காண்பாய் என்று அவர் என்னுடனே சொல்லி,
	<br /><br />
	14. என்னைக் கர்த்தருடைய ஆலயத்து வடக்கு வாசலின் நடையிலே கொண்டு போனார்; இதோ, அங்கே தம்மூசுக்காக அழுதுகொண்டிருக்கிற ஸ்திரீகள் உட்கார்ந்திருந்தார்கள்.
	<br /><br />
	15. அப்பொழுது அவர்: மனுபுத்திரனே, இதைக் கண்டாயா? இதிலும் அதிக அருவருப்புகளை இன்னமும் காண்பாய் என்று என்னுடனே சொல்லி,
	<br /><br />
	16. என்னைக் கர்த்தருடைய ஆலயத்தின் உட்பிராகாரத்திலே கொண்டுபோனார்; இதோ, கர்த்தருடைய ஆலயத்தின் வாசல் நடையிலே மண்டபத்துக்கும் பலிபீடத்துக்கும் நடுவே, ஏறக்குறைய இருபத்தைந்து புருஷர், தங்கள் முதுகைக் கர்த்தருடைய ஆலயத்துக்கும் தங்கள் முகத்தைக் கீழ்த்திசைக்கும் நேராகத் திருப்பினவர்களாய்க் கிழக்கே இருக்கும் சூரியனை நமஸ்கரித்தார்கள்.
	<br /><br />
	17. அப்பொழுது அவர் என்னை நோக்கி: மனுபுத்திரனே, இதைக் கண்டாயா? இங்கே யூதா வம்சத்தார் செய்கிற அருவருப்புகள் அற்பமான காரியமா? அவர்கள் தேசத்தைக் கொடுமையினால் நிரப்பி என்னை அடிக்கடி கோபமூட்டுகிறார்கள்; இதோ, அவர்கள் திராட்சக்கிளையைத் தங்கள் நாசிக்கு நேராகப் பிடிக்கிறார்கள்.
	<br /><br />
	18. ஆகையால் நானும் உக்கிரத்தோடே காரியத்தை நடத்துவேன்; என் கண் தப்பவிடுவதில்லை, நான் இரங்குவதில்லை; அவர்கள் மகா சத்தமாய் என் செவிகள் கேட்கக் கூப்பிட்டாலும் அவர்களுக்கு நான் செவிகொடுப்பதில்லை என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial8