import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah38 = () => {
  const verseNumber = 38;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 38 </title>
	<meta
          name="description"
          content="Isaiah 38 | ஏசாயா 38 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாட்களில் எசேக்கியா வியாதிப்பட்டு மரணத்துக்கு ஏதுவாயிருந்தான்; அப்பொழுது ஆமோத்சின் குமாரனாகிய ஏசாயா என்னும் தீர்க்கதரிசி அவனிடத்தில் வந்து, அவனை நோக்கி: நீர் உமது வீட்டுக்காரியத்தை ஒழுங்குப்படுத்தும், நீர் பிழைக்கமாட்டீர், மரித்துப்போவீர் என்று கர்த்தர் சொல்லுகிறார் என்றான்.
	<br /><br />
	2. அப்பொழுது எசேக்கியா தன் முகத்தைச் சுவர்ப்புறமாகத் திருப்பிக்கொண்டு, கர்த்தரை நோக்கி:
	<br /><br />
	3. ஆ கர்த்தாவே, நான் உமக்கு முன்பாக உண்மையும் மன உத்தமமுமாய் நடந்து, உமது பார்வைக்கு நலமானதைச் செய்தேன் என்பதை நினைத்தருளும் என்று விண்ணப்பம்பண்ணி, எசேக்கியா மிகவும் அழுதான்.
	<br /><br />
	4. அப்பொழுது ஏசாயாவுக்கு உண்டான கர்த்தருடைய வார்த்தையாவது:
	<br /><br />
	5. நீ போய் எசேக்கியாவை நோக்கி: உன் தகப்பனாகிய தாவீதின் தேவனாயிருக்கிற கர்த்தர் சொல்லுகிறது என்னவென்றால், உன் விண்ணப்பத்தைக் கேட்டேன்; உன் கண்ணீரைக் கண்டேன்; இதோ, உன் நாட்களோடே பதினைந்து வருஷம் கூட்டுவேன்.
	<br /><br />
	6. நான் உன்னையும் இந்த நகரத்தையும் அசீரியா ராஜாவின் கைக்குத் தப்புவித்து, இந்த நகரத்துக்கு ஆதரவாயிருப்பேன்.
	<br /><br />
	7. இதோ, ஆகாசுடைய சூரிய கடியாரத்தில் பாகைக்குப் பாகை இறங்கின சாயையைப் பத்துப்பாகை பின்னிட்டுத் திருப்புவேன் என்றார்.
	<br /><br />
	8. தாம் சொன்ன இந்த வார்த்தையின்படி கர்த்தர் செய்வார் என்பதற்கு இது கர்த்தரால் உனக்கு அடையாளமாயிருக்கும் என்று சொல் என்றார்; அப்படியே கடியாரத்தில் இறங்கியிருந்த சூரியசாயை பத்துப்பாகை திரும்பிற்று.
	<br /><br />
	9. யூதாவின் ராஜாவாகிய எசேக்கியா வியாதிப்பட்டு, தன் வியாதி நீங்கிச் சொஸ்தமானபோது எழுதிவைத்ததாவது:
	<br /><br />
	10. நான் என் பூரண ஆயுசின் வருஷங்களுக்குச் சேராமல் பாதாளத்தின் வாசல்களுக்குட்படுவேன் என்று என் நாட்கள் அறுப்புண்கிறபோது சொன்னேன்.
	<br /><br />
	11. கர்த்தரை, கர்த்தரை, நான் இனி உயிரோடிருக்கிறவர்களின் தேசத்திலே தரிசிப்பதில்லை; இனி பூலோகக் குடிகளோடே இருந்து மனுஷரை நான் காண்பதில்லை.
	<br /><br />
	12. என் ஆயுசு மேய்ப்பனுடைய கூடாரத்தைப்போல என்னைவிட்டுப் பெயர்ந்துபோகிறது; நெய்கிறவன் பாவை அறுக்கிறதுபோல என் ஜீவனை அறுக்கக் கொடுக்கிறேன்; என்னைப் பாவிலிருந்து அறுத்துவிடுகிறார்; இன்று இரவுக்குள்ளே என்னை முடிவடையப்பண்ணுவீர்.
	<br /><br />
	13. விடியற்காலமட்டும் நான் எண்ணமிட்டுக்கொண்டிருந்தேன்; அவர் சிங்கம்போல என் எலும்புகளையெல்லாம் நொறுக்குவார்; இன்று இரவுக்குள்ளே என்னை முடிவடையப்பண்ணுவீர் என்று சொல்லி,
	<br /><br />
	14. நமுட்டைப்போலும், தகைவிலான் குருவியைப்போலும் கூவினேன், புறாவைப்போல் புலம்பினேன்; என் கண்கள் உயரப் பார்க்கிறதினால் பூத்துப்போயின; கர்த்தாவே, ஒடுங்கிப்போகிறேன்; என் காரியத்தை மேற்போட்டுக் கொள்ளும் என்றேன்.
	<br /><br />
	15. நான் என்னசொல்லுவேன்? அவர் எனக்கு வாக்கு அருளினார்; அந்தப்பிரகாரமே செய்தார்; என் ஆயுசின் வருஷங்களிலெல்லாம் என் ஆத்துமாவின் கசப்பை நினைத்து நடந்துகொள்வேன்.
	<br /><br />
	16. ஆண்டவரே, இவைகளினால் மனுஷர் பிழைக்கிறார்கள்; இவைகளெல்லாம் என் உயிர்க்கு உயிராயிருக்கிறது; என்னைச் சொஸ்தப்படவும் பிழைக்கவும்பண்ணினீர்.
	<br /><br />
	17. இதோ, சமாதானத்துக்குப் பதிலாக மகா கசப்பு வந்திருந்தது, தேவரீரோ என் ஆத்துமாவை நேசித்து அழிவின் குழிக்கு விலக்கினீர்; என் பாவங்களையெல்லாம் உமது முதுகுக்குப் பின்னாக எறிந்துவிட்டீர்.
	<br /><br />
	18. பாதாளம் உம்மைத் துதியாது, மரணம் உம்மைப் போற்றாது; குழியில் இறங்குகிறவர்கள் உம்முடைய சத்தியத்தைத் தியானிப்பதில்லை.
	<br /><br />
	19. நான் இன்று செய்கிறதுபோல, உயிரோடிருக்கிறவன், உயிரோடிருக்கிறவனே, உம்மைத் துதிப்பான், தகப்பன் பிள்ளைகளுக்கு உமது சத்தியத்தைத் தெரிவிப்பான்.
	<br /><br />
	20. கர்த்தர் என்னை இரட்சிக்கவந்தார்; ஆகையால் எங்கள் ஜீவநாளெல்லாம் கர்த்தருடைய ஆலயத்திலே என் கீதவாத்தியங்களை வாசித்துப் பாடுவோம் என்று எழுதிவைத்தான்.
	<br /><br />
	21. அத்திப்பழத்து அடையைக் கொண்டுவந்து, பிளவையின்மேல் பற்றுப்போடுங்கள்; அப்பொழுது பிழைப்பார் என்று ஏசாயா சொல்லியிருந்தான்.
	<br /><br />
	22. அப்பொழுது எசேக்கியா: நான் கர்த்தருடைய ஆலயத்துக்குப் போவதற்கு அடையாளம் என்னவென்று கேட்டிருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah38