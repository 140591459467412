import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 10 </title>
	<meta
          name="description"
          content="John Chapter 10 | யோவான் அதிகாரம் 10 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன்; ஆட்டுத்தொழுவத்துக்குள் வாசல்வழியாய்ப் பிரவேசியாமல், வேறுவழியாய் ஏறுகிறவன் கள்ளனும் கொள்ளைக்காரனுமாயிருக்கிறான்.
	<br /><br />
	2. வாசல்வழியாய்ப் பிரவேசிக்கிறவனோ ஆடுகளின் மேய்ப்பனாயிருக்கிறான்.
	<br /><br />
	3. வாசலைக் காக்கிறவன் அவனுக்குத் திறக்கிறான்; ஆடுகளும் அவன் சத்தத்துக்குச் செவிகொடுக்கிறது. அவன் தன்னுடைய ஆடுகளைப் பேர்சொல்லிக் கூப்பிட்டு, அவைகளை வெளியே நடத்திக் கொண்டு போகிறான்.
	<br /><br />
	4. அவன் தன்னுடைய ஆடுகளை வெளியே விட்டபின்பு, அவைகளுக்கு முன்பாக நடந்துபோகிறான், ஆடுகள் அவன் சத்தத்தை அறிந்திருக்கிறபடியினால் அவனுக்குப் பின்செல்லுகிறது.
	<br /><br />
	5. அந்நியருடைய சத்தத்தை அறியாதபடியினால் அவைகள் அந்நியனுக்குப் பின்செல்லாமல், அவனை விட்டோடிப்போம் என்றார்.
	<br /><br />
	6. இந்த உவமையை இயேசு அவர்களுடனே சொன்னார்; அவர்களோ அவர் சொன்னவைகளின் கருத்தை அறியவில்லை.
	<br /><br />
	7. ஆதலால் இயேசு மறுபடியும் அவர்களை நோக்கி: நானே ஆடுகளுக்கு வாசல் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	8. எனக்கு முன்னே வந்தவர்களெல்லாரும் கள்ளரும் கொள்ளைக்காரருமாயிருக்கிறார்கள்; ஆடுகள் அவர்களுக்குச் செவிகொடுக்கவில்லை.
	<br /><br />
	9. நானே வாசல், என் வழியாய் ஒருவன் உட்பிரவேசித்தால், அவன் இரட்சிக்கப்படுவான், அவன் உள்ளும் புறம்பும் சென்று, மேய்ச்சலைக் கண்டடைவான்.
	<br /><br />
	10. திருடன் திருடவும் கொல்லவும் அழிக்கவும் வருகிறானேயன்றி வேறொன்றுக்கும் வரான். நானோ அவைகளுக்கு ஜீவன் உண்டாயிருக்கவும், அது பரிபூரணப்படவும் வந்தேன்.
	<br /><br />
	11. நானே நல்ல மேய்ப்பன்: நல்ல மேய்ப்பன் ஆடுகளுக்காகத் தன் ஜீவனைக் கொடுக்கிறான்.
	<br /><br />
	12. மேய்ப்பனாயிராதவனும், ஆடுகள் தனக்குச் சொந்தமல்லாதவனுமான கூலியாள் ஓநாய் வருகிறதைக் கண்டு ஆடுகளை விட்டு ஓடிப்போகிறான்; அப்பொழுது ஓநாய் ஆடுகளைப்பீறி, அவைகளைச் சிதறடிக்கும்.
	<br /><br />
	13. கூலியாள் கூலிக்காக வேலைசெய்கிறவனாகையால் ஓடிப்போகிறான், ஆடுகளுக்காக அவன் கவலைப்படான்.
	<br /><br />
	14. நானே நல்ல மேய்ப்பன்; பிதா என்னை அறிந்திருக்கிறதுபோலவும், நான் பிதாவை அறிந்திருக்கிறதுபோலவும்,
	<br /><br />
	15. நான் என்னுடையவைகளை அறிந்தும் என்னுடையவைகளால் அறியப்பட்டுமிருக்கிறேன்; ஆடுகளுக்காக என் ஜீவனையும் கொடுக்கிறேன்.
	<br /><br />
	16. இந்தத் தொழுவத்திலுள்ளவைகளல்லாமல் வேறே ஆடுகளும் எனக்கு உண்டு; அவைகளையும் நான் கொண்டுவரவேண்டும், அவைகள் என் சத்தத்துக்குச் செவிகொடுக்கும், அப்பொழுது ஒரே மந்தையும் ஒரே மேய்ப்பனுமாகும்.
	<br /><br />
	17. நான் என் ஜீவனை மறுபடியும் அடைந்துகொள்ளும்படிக்கு அதைக் கொடுக்கிறபடியினால் பிதா என்னில் அன்பாயிருக்கிறார்.
	<br /><br />
	18. ஒருவனும் அதை என்னிடத்திலிருந்து எடுத்துக்கொள்ளமாட்டான்; நானே அதைக் கொடுக்கிறேன், அதைக் கொடுக்கவும் எனக்கு அதிகாரம் உண்டு, அதை மறுபடியும் எடுத்துக்கொள்ளவும் எனக்கு அதிகாரம் உண்டு. இந்தக் கட்டளையை என் பிதாவினிடத்தில் பெற்றுக்கொண்டேன் என்றார்.
	<br /><br />
	19. இந்த வசனங்களினிமித்தம் யூதருக்குள்ளே மறுபடியும் பிரிவினையுண்டாயிற்று.
	<br /><br />
	20. அவர்களில் அநேகர்: இவன் பிசாசுபிடித்தவன், பயித்தியக்காரன்; ஏன் இவனுக்குச் செவிகொடுக்கிறீர்கள் என்றார்கள்.
	<br /><br />
	21. வேறே சிலர்: இவைகள் பிசாசுபிடித்தவனுடைய வசனங்களல்லவே. குருடருடைய கண்களைப் பிசாசு திறக்கக்கூடுமா என்றார்கள்.
	<br /><br />
	22. பின்பு எருசலேமிலே தேவாலயப் பிரதிஷ்டைபண்டிகை வந்தது; மாரிகாலமுமாயிருந்தது.
	<br /><br />
	23. இயேசு தேவாலயத்தில் சாலொமோனுடைய மண்டபத்திலே உலாவிக்கொண்டிருந்தார்.
	<br /><br />
	24. அப்பொழுது யூதர்கள் அவரைச் சூழ்ந்துகொண்டு: எதுவரைக்கும் எங்கள் ஆத்துமாவுக்குச் சந்தேகம் உண்டாக்குகிறீர், நீர் கிறிஸ்துவானால் எங்களுக்குத் தெளிவாய்ச் சொல்லும் என்றார்கள்.
	<br /><br />
	25. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: அதை உங்களுக்குச் சொன்னேன், நீங்கள் விசுவாசிக்கவில்லை; என் பிதாவின் நாமத்தினாலே நான் செய்கிற கிரியைகளே என்னைக்குறித்துச் சாட்சிகொடுக்கிறது.
	<br /><br />
	26. ஆனாலும், நான் உங்களுக்குச் சொன்னபடியே, நீங்கள் என் மந்தையின் ஆடுகளாயிராதபடியினால் விசுவாசியாமலிருக்கிறீர்கள்.
	<br /><br />
	27. என் ஆடுகள் என் சத்தத்திற்குச் செவிகொடுக்கிறது; நான் அவைகளை அறிந்திருக்கிறேன், அவைகள் எனக்குப் பின்செல்லுகிறது.
	<br /><br />
	28. நான் அவைகளுக்கு நித்தியஜீவனைக் கொடுக்கிறேன்; அவைகள் ஒருக்காலும் கெட்டுப்போவதில்லை, ஒருவனும் அவைகளை என் கையிலிருந்து பறித்துக் கொள்வதுமில்லை.
	<br /><br />
	29. அவைகளை எனக்குத் தந்த என் பிதா எல்லாரிலும் பெரியவராயிருக்கிறார்; அவைகளை என் பிதாவின் கையிலிருந்து பறித்துக்கொள்ள ஒருவனாலும் கூடாது.
	<br /><br />
	30. நானும் பிதாவும் ஒன்றாயிருக்கிறோம் என்றார்.
	<br /><br />
	31. அப்பொழுது யூதர்கள் மறுபடியும் அவர்மேல் கல்லெறியும்படி, கல்லுகளை எடுத்துக்கொண்டார்கள்.
	<br /><br />
	32. இயேசு அவர்களை நோக்கி: நான் என் பிதாவினாலே அநேக நற்கிரியைகளை உங்களுக்குக் காண்பித்தேன், அவைகளில் எந்தக் கிரியையினிமித்தம் என்மேல் கல்லெறிகிறீர்கள் என்றார்.
	<br /><br />
	33. யூதர்கள் அவருக்குப் பிரதியுத்தரமாக: நற்கிரியையினிமித்தம் நாங்கள் உன்மேல் கல்லெறிகிறதில்லை; நீ மனுஷனாயிருக்க, உன்னைத் தேவன் என்று சொல்லி, இவ்விதமாகத் தேவதூஷணஞ் சொல்லுகிறபடியினால் உன்மேல் கல்லெறிகிறோம் என்றார்கள்.
	<br /><br />
	34. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: தேவர்களாயிருக்கிறீர்கள் என்று நான் சொன்னேன் என்பதாய் உங்கள் வேதத்தில் எழுதியிருக்கவில்லையா?
	<br /><br />
	35. தேவவசனத்தைப் பெற்றுக்கொண்டவர்களைத் தேவர்கள் என்று அவர் சொல்லியிருக்க, வேதவாக்கியமும் தவறாததாயிருக்க,
	<br /><br />
	36. பிதாவினால் பரிசுத்தமாக்கப்பட்டும், உலகத்தில் அனுப்பப்பட்டும் இருக்கிற நான் என்னைத் தேவனுடைய குமாரன் என்று சொன்னதினாலே தேவதூஷணஞ் சொன்னாய் என்று நீங்கள் சொல்லலாமா?
	<br /><br />
	37. என் பிதாவின் கிரியைகளை நான் செய்யாதிருந்தால், நீங்கள் என்னை விசுவாசிக்கவேண்டியதில்லை.
	<br /><br />
	38. செய்தேனேயானால், நீங்கள் என்னை விசுவாசியாதிருந்தாலும், பிதா என்னிலும் நான் அவரிலும் இருக்கிறதை நீங்கள் அறிந்து விசுவாசிக்கும்படி அந்தக் கிரியைகளை விசுவாசியுங்கள் என்றார்.
	<br /><br />
	39. இதினிமித்தம் அவர்கள் மறுபடியும் அவரைப் பிடிக்கத் தேடினார்கள், அவரோ அவர்கள் கைக்குத் தப்பி,
	<br /><br />
	40. யோர்தானுக்கு அக்கரையிலே முன்னே யோவான் ஞானஸ்நானங் கொடுத்துக்கொண்டிருந்த இடத்துக்குத் திரும்பிப்போய், அங்கே தங்கினார்.
	<br /><br />
	41. அநேகர் அவரிடத்தில் வந்து: யோவான் ஒரு அற்புதத்தையும் செய்யவில்லை; ஆகிலும் இவரைக்குறித்து யோவான் சொன்னதெல்லாம் மெய்யாயிருக்கிறது என்றார்கள்.
	<br /><br />
	42. அவ்விடத்திலே அநேகர் அவரிடத்தில் விசுவாசமுள்ளவர்களானார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John10