import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneTimothy2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> 1 Timothy in Tamil Bible Verse | 1 தீமோத்தேயு அதிகாரம்-2 </title>
	<meta
          name="description"
          content="FIRST LETTER TO THE Timothy Chapter 2 | 1 தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* வழிபாடு பற்றிய வழிமுறைகள் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

	  <p>
	1. நான் பிரதானமாய்ச் சொல்லுகிற புத்தியென்னவெனில், எல்லா மனுஷருக்காகவும் விண்ணப்பங்களையும் ஜெபங்களையும் வேண்டுதல்களையும் ஸ்தோத்திரங்களையும் பண்ணவேண்டும்;
	<br /><br />
	2. நாம் எல்லாப் பக்தியோடும் நல்லொழுக்கத்தோடும் கலகமில்லாமல் அமைதலுள்ள ஜீவனம்பண்ணும்படிக்கு, ராஜாக்களுக்காகவும், அதிகாரமுள்ள யாவருக்காகவும் அப்படியே செய்யவேண்டும்.
	<br /><br />
	3. நம்முடைய இரட்சகராகிய தேவனுக்குமுன்பாக அது நன்மையும் பிரியமுமாயிருக்கிறது.
	<br /><br />
	4. எல்லா மனுஷரும் இரட்சிக்கப்படவும், சத்தியத்தை அறிகிற அறிவை அடையவும், அவர் சித்தமுள்ளவராயிருக்கிறார்.
	<br /><br />
	5. தேவன் ஒருவரே, தேவனுக்கும் மனுஷருக்கும் மத்தியஸ்தரும் ஒருவரே.
	<br /><br />
	6. எல்லாரையும் மீட்கும்பொருளாகத் தம்மை ஒப்புக்கொடுத்த மனுஷனாகிய கிறிஸ்து இயேசு அவரே; இதற்குரிய சாட்சி ஏற்ற காலங்களில் விளங்கிவருகிறது.
	<br /><br />
	7. இதற்காகவே நான் பிரசங்கியாகவும், அப்போஸ்தலனாகவும், புறஜாதிகளுக்கு விசுவாசத்தையும் சத்தியத்தையும் விளங்கப்பண்ணுகிற போதகனாகவும் நியமிக்கப்பட்டிருக்கிறேன்; நான் பொய் சொல்லாமல் கிறிஸ்துவுக்குள் உண்மையைச் சொல்லுகிறேன்.
	<br /><br />
	8. அன்றியும், புருஷர்கள் கோபமும் தர்க்கமுமில்லாமல் பரிசுத்தமான கைகளை உயர்த்தி, எல்லா இடங்களிலேயும் ஜெபம்பண்ணவேண்டுமென்று விரும்புகிறேன்.
	<br /><br />
	9. ஸ்திரீகளும் மயிரைப் பின்னுதலினாலாவது, பொன்னினாலாவது, முத்துக்களினாலாவது, விலையேறப்பெற்ற வஸ்திரத்தினாலாவது தங்களை அலங்கரியாமல்,
	<br /><br />
	10. தகுதியான வஸ்திரத்தினாலும், நாணத்தினாலும், தெளிந்த புத்தியினாலும், தேவபக்தியுள்ளவர்களென்று சொல்லிக்கொள்ளுகிற ஸ்திரீகளுக்கு ஏற்றபடியே நற்கிரியைகளினாலும், தங்களை அலங்கரிக்கவேண்டும்.
	<br /><br />
	11. ஸ்திரீயானவள் எல்லாவற்றிலும் அடக்கமுடையவளாயிருந்து, அமைதலோடு கற்றுக்கொள்ளக்கடவள்.
	<br /><br />
	12. உபதேசம்பண்ணவும், புருஷன்மேல் அதிகாரஞ் செலுத்தவும், ஸ்திரீயானவளுக்கு நான் உத்தரவு கொடுக்கிறதில்லை; அவள் அமைதலாயிருக்கவேண்டும்.
	<br /><br />
	13. என்னத்தினாலெனில், முதலாவது ஆதாம் உருவாக்கப்பட்டான், பின்பு ஏவாள் உருவாக்கப்பட்டாள்.
	<br /><br />
	14. மேலும், ஆதாம் வஞ்சிக்கப்படவில்லை, ஸ்திரீயானவளே வஞ்சிக்கப்பட்டு மீறுதலுக்கு உட்பட்டாள்.
	<br /><br />
	15. அப்படியிருந்தும், தெளிந்த புத்தியோடு விசுவாசத்திலும் அன்பிலும் பரிசுத்தத்திலும் நிலைகொண்டிருந்தால், பிள்ளைப்பேற்றினாலே இரட்சிக்கப்படுவாள்.
</p>



      {/* End of verse */}  

	  <br /><br />
		<br /><br />
<h2>
* I தீமோத்தேயு 2, அதிகாரத்தில் இருப்பவர்களுக்காக நாம் ஜெபிக்க வேண்டும், அதனால் நாம் அமைதியான மற்றும் அமைதியான வாழ்க்கையை வாழ முடியும் என்பதை நினைவூட்டுகிறது. ஒவ்வொருவரும் இரட்சிக்கப்படவும், அவரைப் பற்றிய அறிவை அடையவும் கடவுள் விரும்புகிறார் என்ற உண்மையை நாம் கற்றுக்கொள்கிறோம். இந்த தேவாலயத்தின் பெண்களுக்கு அடக்கமாக உடுத்திக்கொள்ளவும், விசுவாசத்தைப் பற்றி அறியவும் குறிப்பிட்ட அறிவுறுத்தல்களுடன் பவுல் முடிக்கிறார்.*
			</h2>
<br /><br />
			<br /><br />
      </div>
    </div>
    </>
  );
}

export default OneTimothy2