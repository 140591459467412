import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 15 </title>
	<meta
          name="description"
          content="The Book of Revelation - 15 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 15 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஏழு வாதைகளுடன் தேவதூதர்களைக் கண்டேன். வெற்றி பெற்றவர்கள், பாடல்கள் பாடினர். தேவதூதர்களுக்கு கோபத்தின் ஏழு கிண்ணங்கள் கொடுக்கப்பட்டன. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பின்பு, வானத்திலே பெரிதும் ஆச்சரியமுமான வேறொரு அடையாளமாகிய கடைசியான ஏழு வாதைகளையுடைய ஏழு தூதரைக் கண்டேன், அவைகளால் தேவனுடைய கோபம் முடிகிறது.
	<br /><br />
	2. அன்றியும், அக்கினிகலந்த கண்ணாடிக் கடல்போன்ற ஒரு கடலையும், மிருகத்திற்கும் அதின் சொரூபத்திற்கும் அதின் முத்திரைக்கும் அதின் நாமத்தின் இலக்கத்திற்கும் உள்ளாகாமல் ஜெயங்கொண்டவர்கள் தேவ சுரமண்டலங்களைப் பிடித்துக்கொண்டு அந்தக் கண்ணாடிக் கடலருகே நிற்கிறதையும் கண்டேன்.
	<br /><br />
	3. அவர்கள் தேவனுடைய ஊழியக்காரனாகிய மோசேயின் பாட்டையும் ஆட்டுக்குட்டியானவருடைய பாட்டையும் பாடி: சர்வவல்லமையுள்ள தேவனாகிய கர்த்தாவே, தேவரீருடைய கிரியைகள் மகத்துவமும் ஆச்சரியமுமானவைகள்; பரிசுத்தவான்களின் ராஜாவே, தேவரீருடைய வழிகள் நீதியும் சத்தியமுமானவைகள்.
	<br /><br />
	4. கர்த்தாவே, யார் உமக்குப் பயப்படாமலும், உமது நாமத்தை மகிமைப்படுத்தாமலும் இருக்கலாம்? தேவரீர் ஒருவரே பரிசுத்தர், எல்லா ஜாதிகளும் வந்து உமக்கு முன்பாகத் தொழுதுகொள்வார்கள்; உம்முடைய நீதியான செயல்கள் வெளியரங்கமாயின என்றார்கள்.
	<br /><br />
	5. இவைகளுக்குப்பின்பு, நான் பார்த்தபோது, இதோ, பரலோகத்திலே சாட்சியின் கூடாரமாகிய ஆலயம் திறக்கப்பட்டது;
	<br /><br />
	6. அந்த ஆலயத்திலிருந்து ஏழு வாதைகளையுடைய அவ்வேழு தூதர்களும் சுத்தமும் பிரகாசமுமான மெல்லிய வஸ்திரந்தரித்து, மார்பருகே பொற்கச்சைகளைக் கட்டிக்கொண்டு புறப்பட்டார்கள்.
	<br /><br />
	7. அப்பொழுது அந்த நான்கு ஜீவன்களில் ஒன்று, சதாகாலங்களிலும் உயிரோடிருக்கிற தேவனுடைய கோபாக்கினையால் நிறைந்த பொற்கலசங்கள் ஏழையும் அந்த ஏழு தூதர்களுக்குங் கொடுத்தது.
	<br /><br />
	8. அப்பொழுது, தேவனுடைய மகிமையினாலும் அவருடைய வல்லமையினாலும் உண்டான புகையினாலே தேவாலயம் நிறைந்தது; ஏழு தூதர்களுடைய ஏழு வாதைகளும் முடியும்வரைக்கும் ஒருவரும் தேவாலயத்திற்குள் பிரவேசிக்கக் கூடாதிருந்தது.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h15>
*  *
			</h15>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation15