import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title> Melchizedek's Priesthood Hebrews - 7 Tamil Bible | எபிரெயர் 7  </title>
	<meta
          name="description"
          content="Melchizedek's Priesthood like Christ's Chapter-7 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-7 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கிறிஸ்துவைப் போலவே மெல்கிசேதேக்கின் ஆசாரியத்துவம் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இந்த மெல்கிசேதேக்கு சாலேமின் ராஜாவும், உன்னதமான தேவனுடைய ஆசாரியனுமாயிருந்தான்; ராஜாக்களை முறியடித்துவந்த ஆபிரகாமுக்கு இவன் எதிர்கொண்டுபோய், அவனை ஆசீர்வதித்தான்.
	<br /><br />
	2. இவனுக்கு ஆபிரகாம் எல்லாவற்றிலும் தசமபாகம் கொடுத்தான்; இவனுடைய முதற்பேராகிய மெல்கிசேதேக்கு என்பதற்கு நீதியின் ராஜா என்றும், பின்பு சாலேமின் ராஜா என்பதற்குச் சமாதானத்தின் ராஜா என்றும் அருத்தமாம்.
	<br /><br />
	3. இவன் தகப்பனும் தாயும் வம்சவரலாறும் இல்லாதவன்; இவன் நாட்களின் துவக்கமும் ஜீவனின் முடிவுமுடையவனாயிராமல், தேவனுடைய குமாரனுக்கு ஒப்பானவனாய் என்றென்றைக்கும் ஆசாரியனாக நிலைத்திருக்கிறான்.
	<br /><br />
	4. இவன் எவ்வளவு பெரியவனாயிருக்கிறான் பாருங்கள்; கோத்திரத்தலைவனாகிய ஆபிரகாம் முதலாய் கொள்ளையிடப்பட்ட பொருள்களில் இவனுக்குத் தசமபாகம் கொடுத்தான்.
	<br /><br />
	5. லேவியின் புத்திரரில் ஆசாரியத்துவத்தை அடைகிறவர்களும், ஆபிரகாமின் அரையிலிருந்துவந்த தங்கள் சகோதரரான ஜனங்களின் கையிலே நியாயப்பிரமாணத்தின்படி தசமபாகம் வாங்குகிறதற்குக் கட்டளைபெற்றிருக்கிறார்கள்.
	<br /><br />
	6. ஆகிலும், அவர்களுடைய வம்சவரிசையில் வராதவனாகிய இவன் ஆபிரகாமின் கையில் தசமபாகம் வாங்கி, வாக்குத்தத்தங்களைப் பெற்றவனை ஆசீர்வதித்தான்.
	<br /><br />
	7. சிறியவன் பெரியவனாலே ஆசீர்வதிக்கப்படுவான், அதற்குச் சந்தேகமில்லை.
	<br /><br />
	8. அன்றியும், இங்கே, மரிக்கிற மனுஷர்கள் தசமபாகம் வாங்குகிறார்கள்; அங்கேயோ, பிழைத்திருக்கிறான் என்று சாட்சிபெற்றவன் வாங்கினான்.
	<br /><br />
	9. அன்றியும், மெல்கிசேதேக்கு ஆபிரகாமுக்கு எதிர்கொண்டுபோனபோது, லேவியானவன் தன் தகப்பனுடைய அரையிலிருந்தபடியால்,
	<br /><br />
	10. தசமபாகம் வாங்குகிற அவனும் ஆபிரகாமின் மூலமாய்த் தசமபாகம் கொடுத்தான் என்று சொல்லலாம்.
	<br /><br />
	11. அல்லாமலும், இஸ்ரவேல் ஜனங்கள் லேவிகோத்திர ஆசாரிய முறைமைக்குட்பட்டிருந்தல்லவோ நியாயப்பிரமாணத்தைப் பெற்றார்கள்; அந்த ஆசாரிய முறைமையினாலே பூரணப்படுதல் உண்டாயிருக்குமானால், ஆரோனுடைய முறைமையின்படி அழைக்கப்படாமல், மெல்கிசேதேக்கினுடைய முறைமையின்படி அழைக்கப்பட்ட வேறொரு ஆசாரியர் எழும்பவேண்டுவதென்ன?
	<br /><br />
	12. ஆசாரியத்துவம் மாற்றப்பட்டிருக்குமேயானால், நியாயப்பிரமாணமும் மாற்றப்படவேண்டியதாகும்.
	<br /><br />
	13. இவைகள் எவரைக்குறித்துச் சொல்லப்பட்டிருக்கிறதோ, அவர் வேறொரு கோத்திரத்துக்குள்ளானவராயிருக்கிறாரே; அந்தக் கோத்திரத்தில் ஒருவனாகிலும் பலிபீடத்து ஊழியம் செய்ததில்லையே.
	<br /><br />
	14. நம்முடைய கர்த்தர் யூதாகோத்திரத்தில் தோன்றினாரென்பது பிரசித்தமாயிருக்கிறது; அந்தக் கோத்திரத்தாரைக்குறித்து மோசே ஆசாரியத்துவத்தைப்பற்றி ஒன்றும் சொல்லவில்லையே.
	<br /><br />
	15. அல்லாமலும், மெல்கிசேதேக்குக்கு ஒப்பாய் வேறொரு ஆசாரியர் எழும்புகிறார் என்று சொல்லியிருப்பதினால், மேற்சொல்லியது மிகவும் பிரசித்தமாய் விளங்குகிறது.
	<br /><br />
	16. அவர் மாம்ச சம்பந்தமான கட்டளையாகிய நியாயப்பிரமாணத்தின்படி ஆசாரியராகாமல்,
	<br /><br />
	17. நீர் மெல்கிசேதேக்கின் முறைமையின்படி என்றென்றைக்கும் ஆசாரியராயிருக்கிறீர் என்று சொல்லிய சாட்சிக்குத்தக்கதாக அழியாத ஜீவனுக்குரிய வல்லமையின்படியே ஆசாரியரானார்.
	<br /><br />
	18. முந்தின கட்டளை பெலவீனமுள்ளதும் பயனற்றதுமாயிருந்ததினிமித்தம் மாற்றப்பட்டது.
	<br /><br />
	19. நியாயப்பிரமாணமானது ஒன்றையும் பூரணப்படுத்தவில்லை, அதிக நன்மையான நம்பிக்கையை வருவிப்பதோ பூரணப்படுத்துகிறது; அந்த நம்பிக்கையினாலே தேவனிடத்தில் சேருகிறோம்.
	<br /><br />
	20. அன்றியும், அவர்கள் ஆணையில்லாமல் ஆசாரியராக்கப்படுகிறார்கள்; இவரோ: நீர் மெல்கிசேதேக்கின் முறைமையின்படி என்றென்றைக்கும் ஆசாரியராயிருக்கிறீர் என்று கர்த்தர் ஆணையிட்டார், மனம் மாறாமலுமிருப்பார் என்று தம்முடனே சொன்னவராலே ஆணையோடே ஆசாரியரானார்.
	<br /><br />
	21. ஆனதால், இயேசுவானவர் ஆணையின்படியே ஆசாரியராக்கப்பட்டது எவ்வளவு விசேஷித்த காரியமோ,
	<br /><br />
	22. அவ்வளவு விசேஷித்த உடன்படிக்கைக்குப் பிணையாளியானார்.
	<br /><br />
	23. அன்றியும், அவர்கள் மரணத்தினிமித்தம் நிலைத்திருக்கக்கூடாதவர்களானபடியால், ஆசாரியராக்கப்படுகிறவர்கள் அநேகராயிருக்கிறார்கள்.
	<br /><br />
	24. இவரோ என்றென்றைக்கும் நிலைத்திருக்கிறபடியினாலே, மாறிப்போகாத ஆசாரியத்துவமுள்ளவராயிருக்கிறார்.
	<br /><br />
	25. மேலும், தமது மூலமாய்த் தேவனிடத்தில் சேருகிறவர்களுக்காக வேண்டுதல்செய்யும்படிக்கு அவர் எப்பொழுதும் உயிரோடிருக்கிறவராகையால் அவர்களை முற்றுமுடிய இரட்சிக்க வல்லவராயுமிருக்கிறார்.
	<br /><br />
	26. பரிசுத்தரும், குற்றமற்றவரும், மாசில்லாதவரும், பாவிகளுக்கு விலகினவரும், வானங்களிலும் உயர்ந்தவருமாயிருக்கிற இவ்விதமான பிரதான ஆசாரியரே நமக்கு ஏற்றவராயிருக்கிறார்.
	<br /><br />
	27. அவர் பிரதான ஆசாரியர்களைப்போல முன்பு சொந்தப் பாவங்களுக்காகவும், பின்பு ஜனங்களுடைய பாவங்களுக்காகவும் நாடோறும் பலியிடவேண்டுவதில்லை; ஏனெனில் தம்மைத்தாமே பலியிட்டதினாலே இதை ஒரேதரம் செய்துமுடித்தார்.
	<br /><br />
	28. நியாயப்பிரமாணமானது பெலவீனமுள்ள மனுஷர்களைப் பிரதான ஆசாரியராக ஏற்படுத்துகிறது; நியாயப்பிரமாணத்திற்குப்பின்பு உண்டான ஆணையோடே விளங்கிய வசனமோ என்றென்றைக்கும் பூரணரான குமாரனை ஏற்படுத்துகிறது.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
*  எபிரேயர் 7 இயேசுவையும், பரம்பரை பரம்பரை இல்லாமல் என்றென்றும் பாதிரியாரான மெல்கிசேதேக்கையும் ஒப்பிடுகிறது. ஆபிரகாம் மெல்கிசேதேக்கிற்கு மரியாதை மற்றும் தசமபாகம் செலுத்தினார், மேலும் லேவி இன்னும் ஆபிரகாமுக்குள் இருந்ததால் லேவியரின் ஆசாரியத்துவம் அவரால் ஆசீர்வதிக்கப்பட்டது. இந்த பாணியில், இயேசுவும் மெல்கிசேதேக்கைப் போல என்றென்றும் ஒரு பாதிரியார். எனினும் அவர் லேவி கோத்திரத்தைச் சேர்ந்தவர் அல்ல. ஆசாரியத்துவத்தில் ஏற்படும் மாற்றத்துடன் சட்டத்திலும் மாற்றம் ஏற்பட வேண்டும். இயேசு தம்முடைய ஆசாரியத்துவத்தை தனது அழியாத வாழ்வின் மூலம் நிரூபித்துள்ளார். இதன் காரணமாக, அவர் மூலம் கடவுளிடம் நெருங்கி வருபவர்களை அவர் முழுமையாகக் காப்பாற்ற முடியும், ஏனெனில் அவர் எப்போதும் அவர்களுக்காகப் பரிந்து பேசுவதற்காக வாழ்கிறார். *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews7