import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-10 </title>
	<meta
          name="description"
          content="Ecclesiastes 100 | பிரசங்கி 10 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. செத்த ஈக்கள் தைலக்காரனுடைய பரிமளதைலத்தை நாறிக் கெட்டுப்போகப்பண்ணும்; ஞானத்திலும் கனத்திலும் பேர்பெற்றவனைச் சொற்ப மதியீனமும் அப்படியே செய்யும்.
	<br /><br />
	2. ஞானியின் இருதயம் வலதுகையிலும், மூடனின் இருதயமோ இடதுகையிலும் இருக்கும்.
	<br /><br />
	3. மூடன் வழியிலே நடக்கிறபோதும் மதிகெட்டவனாயிருக்கிறான்; தான் மூடனென்று அவன் எல்லாருக்கும் சொல்லுகிறான்.
	<br /><br />
	4. அதிபதியின் கோபம் உன்மேல் எழும்பினால் உன் ஸ்தானத்தை விட்டு விலகாதே; இணங்குதல் பெரிய குற்றங்களையும் அமர்த்திப்போடும்.
	<br /><br />
	5. நான் சூரியனுக்குக்கீழே கண்ட ஒரு தீங்குண்டு, அது அதிபதியினிடத்தில் தோன்றும் தப்பிதமே.
	<br /><br />
	6. மூடர் மகா உயர்ந்த நிலையில் வைக்கப்படுகிறார்கள்; சீமான்களோ தாழ்ந்த நிலையில் உட்கார்ந்திருக்கிறார்கள்.
	<br /><br />
	7. வேலைக்காரர் குதிரைகள்மேல் ஏறிப்போகிறதையும், பிரபுக்கள் வேலைக்காரர்போல் தரையிலே நடக்கிறதையும் கண்டேன்.
	<br /><br />
	8. படுகுழியை வெட்டுகிறவன் அதிலே விழுவான்; அடைப்பைப் பிடுங்குகிறவனைப் பாம்பு கடிக்கும்.
	<br /><br />
	9. கல்லுகளைப் பேர்க்கிறவன் அவைகளால் காயப்படுவான்; மரத்தைப் பிளக்கிறவன் அதினால் மோசப்படுவான்.
	<br /><br />
	10. இருப்பு ஆயுதம் மழுங்கலாயிருக்க, அதை ஒருவன் தீட்டாமற்போனால், அதிகபலத்தைப் பிரயோகம்பண்ணவேண்டியதாகும்; ஆகையால் ஒரு காரியத்தைச் செவ்வையாய்ச் செய்வதற்கு ஞானமே பிரதானம்.
	<br /><br />
	11. தடைகட்டப்படாத பாம்பு கடிக்குமே, அலப்புவாயனும் அதற்கு ஒப்பானவன்.
	<br /><br />
	12. ஞானியினுடைய வாய்மொழிகள் தயையுள்ளவைகள்; மூடனுடைய உதடுகளோ அவனையே விழுங்கும்.
	<br /><br />
	13. அவன் வாய்மொழிகளின் துவக்கம் மதியீனமும், அவன் வாக்கின் முடிவு கொடிய பைத்தியமுமாம்.
	<br /><br />
	14. மூடன் மிகுதியாய்ப் பேசுகிறான், நடக்கப்போகிறது இன்னதென்று மனுஷன் அறியான்; தனக்குப்பிற்பாடு சம்பவிக்கப்போகிறதை அவனுக்கு அறிவிப்பவன் யார்?
	<br /><br />
	15. ஊருக்குப் போகும் வழியை மூடன் அறியாததினால், அவன் தொல்லை ஒவ்வொருவரையும் இளைக்கப்பண்ணும்.
	<br /><br />
	16. ராஜா சிறுபிள்ளையுமாய், பிரபுக்கள் அதிகாலமே உண்கிறவர்களுமாயிருக்கப்பட்ட தேசமே, உனக்கு ஐயோ!
	<br /><br />
	17. ராஜா குலமகனுமாய், பிரபுக்கள் வெறிக்க உண்ணாமல் பெலன்கொள்ள ஏற்றவேளையில் உண்கிறவர்களுமாயிருக்கப்பட்ட தேசமே, நீ பாக்கியமுள்ளது.
	<br /><br />
	18. மிகுந்த சோம்பலினால் மேல்மச்சுப் பழுதாகும்; கைகளின் நெகிழ்வினாலே வீடு ஒழுக்காகும்.
	<br /><br />
	19. விருந்து சந்தோஷத்துக்கென்று செய்யப்படும்; திராட்சரசம் ஜீவனுள்ளோரைக் களிப்பாக்கும்; பணமோ எல்லாவற்றிற்கும் உதவும்.
	<br /><br />
	20. ராஜாவை உன் மனதிலும் நிந்தியாதே, ஐசுவரியவானை உன் படுக்கையிலும் நிந்தியாதே; ஆகாயத்துப் பறவை அந்தச் சத்தத்தைக் கொண்டுபோகும், செட்டைகளுள்ளது அந்தச் செய்தியை அறிவிக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes10