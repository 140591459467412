import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-8 </title>
	<meta
          name="description"
          content="Ecclesiastes 8 | பிரசங்கி 8 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஞானமுள்ளவனுக்கு ஒப்பானவன் யார்? காரியத்தின் தாற்பரியத்தை அறிந்தவன் யார்? மனுஷனுடைய ஞானம் அவன் முகத்தைப் பிரகாசிக்கப்பண்ணும், அவன் முகத்தின் மூர்க்கம் மாறும்.
	<br /><br />
	2. ராஜாவின் கட்டளையைக் கைக்கொண்டு நட என்று நான் உனக்கு எச்சரிக்கிறேன்; நீ தேவனுக்கு இட்ட ஆணையின்படியே இதைச் செய்.
	<br /><br />
	3. நீ அவன் சமுகத்தை விட்டுவிலகத் துரிதப்படாதே; பொல்லாத காரியத்திலே பிடிவாதமாய் நில்லாதே; அவன் தனக்கு இஷ்டமானதெல்லாம் செய்வான்.
	<br /><br />
	4. ராஜாவின் வார்த்தை எங்கேயோ அங்கே அதிகாரம் உண்டு; நீர் என்ன செய்கிறீர் என்று அவனுக்குச் சொல்லத்தக்கவன் யார்?
	<br /><br />
	5. கற்பனையைக் கைக்கொள்ளுகிறவன் ஒரு தீங்கையும் அறியான்; ஞானியின் இருதயம் காலத்தையும் நியாயத்தையும் அறியும்.
	<br /><br />
	6. எல்லாக் காரியத்துக்கும் காலமும் நியாயமுமுண்டு; ஆதலால் மனுஷனுக்கு நேரிடும் சஞ்சலம் மிகுதி.
	<br /><br />
	7. இன்னது சம்பவிக்கும் என்று அவன் அறியானே; அது இன்னவிதமாய்ச் சம்பவிக்கும் என்று அவனுக்குச் சொல்லத்தக்கவன் யார்?
	<br /><br />
	8. ஆவியை விடாதிருக்கிறதற்கு ஆவியின்மேல் ஒரு மனுஷனுக்கும் அதிகாரமில்லை; மரணநாளின்மேலும் அவனுக்கு அதிகாரமில்லை; அந்தப் போருக்கு நீங்கிப்போவதுமில்லை; துன்மார்க்கரைத் துன்மார்க்கம் விடுவிக்கவுமாட்டாது.
	<br /><br />
	9. இவையெல்லாவற்றையும் நான் பார்த்து, சூரியனுக்குக் கீழே செய்யப்படும் எல்லாக் கிரியைகளையும் சிந்தித்தேன்; ஒரு மனுஷன் தனக்கே கேடுண்டாக வேறொரு மனுஷனை ஆளுகிற காலமுமுண்டு.
	<br /><br />
	10. பரிசுத்த ஸ்தலத்துக்குப் போக்குவரவு செய்த துன்மார்க்கர் அடக்கம்பண்ணப்பட்டதைக் கண்டேன்; அவர்கள் அப்படி செய்துவந்த பட்டணத்திலேயே மறக்கப்பட்டுப்போனார்கள்; இதுவும் மாயையே.
	<br /><br />
	11. துர்க்கிரியைக்குத்தக்க தண்டனை சீக்கிரமாய் நடவாதபடியால், மனுபுத்திரரின் இருதயம் பொல்லாப்பைச் செய்ய அவர்களுக்குள்ளே துணிகரங்கொண்டிருக்கிறது.
	<br /><br />
	12. பாவி நூறுதரம் பொல்லாப்பைச் செய்து நீடித்து வாழ்ந்தாலும் என்ன? தேவனுக்கு அஞ்சி, அவருக்கு முன்பாகப் பயந்திருப்பவர்களே நன்றாயிருப்பார்கள் என்று அறிந்திருக்கிறேன்.
	<br /><br />
	13. துன்மார்க்கனோ நன்றாயிருப்பதில்லை; அவன் தேவனுக்கு முன்பாகப் பயப்படாதிருக்கிறபடியால், நிழலைப்போலிருக்கிற அவனுடைய வாழ்நாள் நீடித்திருப்பதுமில்லை.
	<br /><br />
	14. பூமியின்மேல் நடக்கிற வேறொரு மாயையான காரியமுமுண்டு; அதாவது, துன்மார்க்கரின் கிரியைக்கு வருவதுபோல, நீதிமான்களுக்கும் வரும்; நீதிமான்களின் கிரியைக்கு வருவதுபோல, துன்மார்க்கருக்கும் வரும்; இதுவும் மாயை என்றேன்.
	<br /><br />
	15. ஆகையால் நான் களிப்பைப் புகழ்ந்தேன்; புசிப்பதும் குடிப்பதும் மகிழ்வதுமேயல்லாமல் சூரியனுக்குக்கீழே மனுஷனுக்கு வேறொரு நன்மையும் இல்லை; சூரியனுக்குக்கீழே தேவன் அவனுக்குத் தந்த ஜீவகாலத்தில் அவன் பிரயாசத்தினால் அவனுக்கு நிலைக்கும் பலன் இதுவே.
	<br /><br />
	16. நான் ஞானத்தை அறியவும், மனுஷன் இரவும் பகலும் கண்ணுறக்கமில்லாமல் பூமியிலே செய்யும் வேலைகளைப் பார்க்கவும் என் மனதைச் செலுத்தினபோது,
	<br /><br />
	17. தேவன் செய்யும் சகல கிரியைகளையும் நான் கவனித்துப்பார்த்து, சூரியனுக்குக்கீழே செய்யப்படும் கிரியையை மனுஷன் கண்டுபிடிக்கக்கூடாதென்று கண்டேன். அதை அறியும்படி மனுஷன் பிரயாசப்பட்டாலும் அறியமாட்டான்; அதை அறியலாம் என்று ஞானி எண்ணினாலும் அவனும் அதை அறிந்துகொள்ளமாட்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes8