import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 4 </title>
	<meta
          name="description"
          content="Matthew 4 | மத்தேயு 4 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது இயேசு பிசாசினால் சோதிக்கப்படுவதற்கு ஆவியானவராலே வனாந்தரத்திற்குக் கொண்டுபோகப்பட்டார்.
	<br /><br />
	2. அவர் இரவும் பகலும் நாற்பதுநாள் உபவாசமாயிருந்தபின்பு, அவருக்குப் பசியுண்டாயிற்று.
	<br /><br />
	3. அப்பொழுது சோதனைக்காரன் அவரிடத்தில் வந்து: நீர் தேவனுடைய குமாரனேயானால், இந்தக் கல்லுகள் அப்பங்களாகும்படி சொல்லும் என்றான்.
	<br /><br />
	4. அவர் பிரதியுத்தரமாக: மனுஷன் அப்பத்தினாலேமாத்திரமல்ல, தேவனுடைய வாயிலிருந்து புறப்படுகிற ஒவ்வொரு வார்த்தையினாலும் பிழைப்பான் என்று எழுதியிருக்கிறதே என்றார்.
	<br /><br />
	5. அப்பொழுது பிசாசு அவரைப் பரிசுத்த நகரத்திற்குக் கொண்டுபோய், தேவாலயத்து உப்பரிகையின்மேல் அவரை நிறுத்தி:
	<br /><br />
	6. நீர் தேவனுடைய குமாரனேயானால் தாழக்குதியும்; ஏனெனில், தம்முடைய தூதர்களுக்கு உம்மைக்குறித்துக் கட்டளையிடுவார்; உமது பாதம் கல்லில் இடறாதபடிக்கு, அவர்கள் உம்மைக் கைகளில் ஏந்திக்கொண்டு போவார்கள் என்பதாய் எழுதியிருக்கிறது என்று சொன்னான்.
	<br /><br />
	7. அதற்கு இயேசு: உன் தேவனாகிய கர்த்தரைப் பரீட்சை பாராதிருப்பாயாக என்றும் எழுதியிருக்கிறதே என்றார்.
	<br /><br />
	8. மறுபடியும், பிசாசு அவரை மிகவும் உயர்ந்த மலையின்மேல் கொண்டுபோய், உலகத்தின் சகல ராஜ்யங்களையும் அவைகளின் மகிமையையும் அவருக்குக் காண்பித்து:
	<br /><br />
	9. நீர் சாஷ்டாங்கமாய் விழுந்து, என்னைப் பணிந்துகொண்டால், இவைகளையெல்லாம் உமக்குத் தருவேன் என்று சொன்னான்.
	<br /><br />
	10. அப்பொழுது இயேசு: அப்பாலே போ சாத்தானே; உன் தேவனாகிய கர்த்தரைப் பணிந்துகொண்டு, அவர் ஒருவருக்கே ஆராதனை செய்வாயாக என்று எழுதியிருக்கிறதே என்றார்.
	<br /><br />
	11. அப்பொழுது பிசாசானவன் அவரை விட்டு விலகிப்போனான். உடனே தேவதூதர்கள் வந்து, அவருக்குப் பணிவிடை செய்தார்கள்.
	<br /><br />
	12. யோவான் காவலில் வைக்கப்பட்டான் என்று இயேசு கேள்விப்பட்டு, கலிலேயாவுக்குப் போய்,
	<br /><br />
	13. நாசரேத்தை விட்டு, செபுலோன் நப்தலி என்னும் நாடுகளின் எல்லைகளிலிருக்கும் கடற்கரைக்கு அருகான கப்பர்நகூமிலே வந்து வாசம்பண்ணினார்.
	<br /><br />
	14. கடற்கரையருகிலும் யோர்தானுக்கு அப்புறத்திலுமுள்ள செபுலோன் நாடும் நப்தலி நாடும் ஆகிய புறஜாதியாருடைய கலிலேயாவிலே,
	<br /><br />
	15. இருளில் இருக்கும் ஜனங்கள் பெரிய வெளிச்சத்தைக் கண்டார்கள்; மரண இருளின் திசையிலிருக்கிறவர்களுக்கு வெளிச்சம் உதித்தது என்று,
	<br /><br />
	16. ஏசாயா தீர்க்கதரிசியினால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது.
	<br /><br />
	17. அதுமுதல் இயேசு: மனந்திரும்புங்கள், பரலோகராஜ்யம் சமீபித்திருக்கிறது என்று பிரசங்கிக்கத் தொடங்கினார்.
	<br /><br />
	18. இயேசு கலிலேயாக் கடலோரமாய் நடந்துபோகையில், மீன்பிடிக்கிறவர்களாயிருந்த இரண்டு சகோதரராகிய பேதுரு என்னப்பட்ட சீமோனும், அவன் சகோதரன் அந்திரேயாவும், கடலில் வலைபோட்டுக்கொண்டிருக்கிறபோது, அவர்களைக் கண்டு:
	<br /><br />
	19. என் பின்னே வாருங்கள், உங்களை மனுஷரைப் பிடிக்கிறவர்களாக்குவேன் என்றார்.
	<br /><br />
	20. உடனே அவர்கள் வலைகளை விட்டு, அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	21. அவர் அவ்விடம் விட்டுப் போகையில், வேறே இரண்டு சகோதரராகிய செபெதேயுவின் மகன் யாக்கோபும், அவன் சகோதரன் யோவானும் தங்கள் தகப்பன் செபெதேயுவுடனே படவிலிருந்து, தங்கள் வலைகளைப் பழுதுபார்த்துக்கொண்டிருக்கிறபோது, அவர்களைக் கண்டு, அவர்களையும் அழைத்தார்.
	<br /><br />
	22. உடனே அவர்கள் படவையும் தங்கள் தகப்பனையும் விட்டு, அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	23. பின்பு, இயேசு கலிலேயா எங்கும் சுற்றி நடந்து, அவர்களுடைய ஜெப ஆலயங்களில் உபதேசித்து, ராஜ்யத்தின் சுவிசேஷத்தைப் பிரசங்கித்து, ஜனங்களுக்கு உண்டாயிருந்த சகல வியாதிகளையும் சகல நோய்களையும் நீக்கிச் சொஸ்தமாக்கினார்.
	<br /><br />
	24. அவருடைய கீர்த்தி சீரியா எங்கும் பிரசித்தமாயிற்று. அப்பொழுது பலவித வியாதிகளையும் வேதனைகளையும் அடைந்திருந்த சகல பிணியாளிகளையும், பிசாசு பிடித்தவர்களையும் சந்திரரோகிகளையும், திமிர்வாதக்காரரையும் அவரிடத்தில் கொண்டுவந்தார்கள்; அவர்களைச் சொஸ்தமாக்கினார்.
	<br /><br />
	25. கலிலேயாவிலும், தெக்கப்போலியிலும், எருசலேமிலும், யூதேயாவிலும், யோர்தானுக்கு அப்புறத்திலும் இருந்து திரளான ஜனங்கள் வந்து, அவருக்குப் பின்சென்றார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew4