import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const SecondTimothy3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> 2 Timothy in Tamil Bible Verse | 2 தீமோத்தேயு அதிகாரம்-3 </title>
	<meta
          name="description"
          content="Second LETTER TO THE Timothy Chapter 3 | தீமோத்தேயுக்கு எழுதின 2 நிருபம்  | அதிகாரம்-3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
		<br />
<h2>
* கடைசி நாட்களில் கடவுளின்மை *
			</h2>
<br />
			<br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. மேலும், கடைசிநாட்களில் கொடிய காலங்கள் வருமென்று அறிவாயாக.
	<br /><br />
	2. எப்படியெனில், மனுஷர்கள் தற்பிரியராயும், பணப்பிரியராயும், வீம்புக்காரராயும், அகந்தையுள்ளவர்களாயும், தூஷிக்கிறவர்களாயும், தாய்தகப்பன்மாருக்குக் கீழ்ப்படியாதவர்களாயும், நன்றியறியாதவர்களாயும், பரிசுத்தமில்லாதவர்களாயும்,
	<br /><br />
	3. சுபாவ அன்பில்லாதவர்களாயும், இணங்காதவர்களாயும், அவதூறு செய்கிறவர்களாயும், இச்சையடக்கமில்லாதவர்களாயும், கொடுமையுள்ளவர்களாயும், நல்லோரைப் பகைக்கிறவர்களாயும்,
	<br /><br />
	4. துரோகிகளாயும், துணிகரமுள்ளவர்களாயும், இறுமாப்புள்ளவர்களாயும், தேவப்பிரியராயிராமல் சுகபோகப்பிரியராயும்,
	<br /><br />
	5. தேவபக்தியின் வேஷத்தைத் தரித்து அதின் பெலனை மறுதலிக்கிறவர்களாயும் இருப்பார்கள்; இப்படிப்பட்டவர்களை நீ விட்டு விலகு.
	<br /><br />
	6. பாவங்களால் நிறைந்து, பற்பல இச்சைகளால் இழுப்புண்டு,
	<br /><br />
	7. எப்போதும் கற்றாலும் ஒருபோதும் சத்தியத்தை அறிந்து உணராதவர்களாயிருக்கிற பெண்பிள்ளைகளுடைய வீடுகளில் இப்படிப்பட்டவர்கள் நுழைந்து, அவர்களை வசப்படுத்திக்கொள்ளுகிறார்கள்.
	<br /><br />
	8. யந்நேயும் யம்பிரேயும் மோசேக்கு எதிர்த்து நின்றதுபோல இவர்களும் சத்தியத்துக்கு எதிர்த்து நிற்கிறார்கள்; இவர்கள் துர்ப்புத்தியுள்ள மனுஷர்கள், விசுவாசவிஷயத்தில் பரீட்சைக்கு நில்லாதவர்கள்.
	<br /><br />
	9. ஆனாலும், இவர்கள் அதிகமாய்ப் பலப்படுவதில்லை; அவ்விருவருடைய மதிகேடு எல்லாருக்கும் வெளிப்பட்டதுபோல, இவர்களுடைய மதிகேடும் வெளிப்படும்.
	<br /><br />
	10. நீயோ என் போதகத்தையும் நடக்கையையும் நோக்கத்தையும் விசுவாசத்தையும் நீடிய சாந்தத்தையும் அன்பையும் பொறுமையையும்,
	<br /><br />
	11. அந்தியோகியா, இக்கோனியா, லீஸ்திரா என்னும் பட்டணங்களில் எனக்கு உண்டான துன்பங்களையும் பாடுகளையும் நன்றாய் அறிந்திருக்கிறாய்; எவ்வளவோ துன்பங்களைச் சகித்தேன்; இவையெல்லாவற்றினின்றும் கர்த்தர் என்னை நீங்கலாக்கிவிட்டார்.
	<br /><br />
	12. அன்றியும் கிறிஸ்து இயேசுவுக்குள் தேவபக்தியாய் நடக்க மனதாயிருக்கிற யாவரும் துன்பப்படுவார்கள்.
	<br /><br />
	13. பொல்லாதவர்களும் எத்தர்களுமானவர்கள் மோசம்போக்குகிறவர்களாகவும், மோசம்போகிறவர்களாகவுமிருந்து மேன்மேலும் கேடுள்ளவர்களாவார்கள்.
	<br /><br />
	14. நீ கற்று நிச்சயித்துக்கொண்டவைகளில் நிலைத்திரு; அவைகளை இன்னாரிடத்தில் கற்றாய் என்று நீ அறிந்திருக்கிறதுமல்லாமல்,
	<br /><br />
	15. கிறிஸ்து இயேசுவைப்பற்றும் விசுவாசத்தினாலே உன்னை இரட்சிப்புக்கேற்ற ஞானமுள்ளவனாக்கத்தக்க பரிசுத்த வேத எழுத்துக்களை, நீ சிறுவயதுமுதல் அறிந்தவனென்றும் உனக்குத் தெரியும்.
	<br /><br />
	16. வேதவாக்கியங்களெல்லாம் தேவஆவியினால் அருளப்பட்டிருக்கிறது; தேவனுடைய மனுஷன் தேறினவனாகவும், எந்த நற்கிரியையுஞ் செய்யத் தகுதியுள்ளவனாகவும் இருக்கும்படியாக,
	<br /><br />
	17. அவைகள் உபதேசத்துக்கும், கடிந்துகொள்ளுதலுக்கும், சீர்திருத்தலுக்கும், நீதியைப் படிப்பிக்குதலுக்கும் பிரயோஜனமுள்ளவைகளாயிருக்கிறது.
</p>


      {/* End of verse */} 

	  <br />
		<br />
<h4>
* 2 தீமோத்தேயு 3, விசுவாச துரோக காலம் வரப்போகிறது என்பதற்கான எச்சரிக்கையாக இருக்கிறது. கடைசி நாட்களில் மக்கள் தங்களை நேசிப்பவர்களாக இருப்பார்கள், தெய்வீகத்தின் ஒரு வடிவத்தைக் கொண்டிருப்பார்கள், ஆனால் அதன் சக்தியை மறுப்பார்கள். அவர்கள் தொடர்ந்து அறிவைத் தேடிக்கொண்டிருப்பார்கள் ஆனால் உண்மையை ஏற்றுக்கொள்ள முடியாது. இந்த மக்களுடன் நாம் ஒன்றும் செய்யக்கூடாது என்று பவுல் உறுதியாக அறிவுறுத்துகிறார். மாறாக, நம்மை ஞானமுள்ளவர்களாகவும், ஒவ்வொரு நற்செயல்களுக்கும் நம்மை ஆயத்தப்படுத்தக்கூடிய புனித நூல்களை அறிந்து கொள்வதன் மூலம் இதை எதிர்த்துப் போராட வேண்டும்*
			</h4>
<br />
			<br /> 
      </div>
    </div>
    </>
  );
}

export default SecondTimothy3