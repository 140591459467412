import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 12 </title>
	<meta
          name="description"
          content="John Chapter 12 | யோவான் அதிகாரம் 12 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பஸ்காபண்டிகை வர ஆறுநாளைக்கு முன்னே இயேசு தாம் மரணத்திலிருந்து எழுப்பின லாசரு இருந்த பெத்தானியாவுக்கு வந்தார்.
	<br />
	2. அங்கே அவருக்கு இராவிருந்து பண்ணினார்கள்; மார்த்தாள் பணிவிடை செய்தாள்; லாசருவும் அவருடனேகூடப் பந்தியிருந்தவர்களில் ஒருவனாயிருந்தான்.
	<br />
	3. அப்பொழுது மரியாள் விலையேறப்பெற்ற களங்கமில்லாத நளதம் என்னும் தைலத்தில் ஒரு இராத்தல் கொண்டுவந்து, அதை இயேசுவின் பாதங்களில் பூசி, தன் தலைமயிரால் அவருடைய பாதங்களைத் துடைத்தாள்; அந்த வீடு முழுவதும் தைலத்தின் பரிமளத்தினால் நிறைந்தது.
	<br />
	4. அப்பொழுது அவருடைய சீஷரில் ஒருவனும் அவரைக் காட்டிக்கொடுக்கப் போகிறவனுமாகிய சீமோனுடைய குமாரனான யூதாஸ்காரியோத்து:
	<br />
	5. இந்தத் தைலத்தை முந்நூறு பணத்துக்கு விற்று, தரித்திரருக்குக் கொடாமல் போனதென்ன என்றான்.
	<br />
	6. அவன் தரித்திரரைக்குறித்துக் கவலைப்பட்டு இப்படிச் சொல்லாமல், அவன் திருடனானபடியினாலும், பணப்பையை வைத்துக்கொண்டு அதிலே போடப்பட்டதைச் சுமக்கிறவனானபடியினாலும் இப்படிச் சொன்னான்.
	<br />
	7. அப்பொழுது இயேசு: இவளை விட்டுவிடு, என்னை அடக்கம்பண்ணும் நாளுக்காக இதை வைத்திருந்தாள்.
	<br />
	8. தரித்திரர் எப்பொழுதும் உங்களிடத்தில் இருக்கிறார்கள்; நான் எப்பொழுதும் உங்களிடத்தில் இரேன் என்றார்.
	<br />
	9. அப்பொழுது யூதரில் திரளான ஜனங்கள் அவர் அங்கேயிருக்கிறதை அறிந்து, இயேசுவினிமித்தமாகமாத்திரம் அல்ல, அவர் மரித்தோரிலிருந்தெழுப்பின லாசருவைக் காணும்படியாகவும் வந்தார்கள்.
	<br />
	10. லாசருவினிமித்தமாக யூதர்களில் அநேகர் போய், இயேசுவினிடத்தில் விசுவாசம் வைத்தபடியால்,
	<br />
	11. பிரதான ஆசாரியர்கள் லாசருவையும் கொலைசெய்ய ஆலோசனைபண்ணினார்கள்.
	<br />
	12. மறுநாளிலே இயேசு எருசலேமுக்கு வருகிறாரென்று பண்டிகைக்கு வந்த திரளான ஜனங்கள் கேள்விப்பட்டு,
	<br />
	13. குருத்தோலைகளைப் பிடித்துக்கொண்டு, அவருக்கு எதிர்கொண்டுபோகும்படி புறப்பட்டு: ஓசன்னா, கர்த்தருடைய நாமத்தினாலே வருகிற இஸ்ரவேலின் ராஜா ஸ்தோத்திரிக்கப்பட்டவர் என்று ஆர்ப்பரித்தார்கள்.
	<br />
	14. அல்லாமலும்: சீயோன் குமாரத்தியே, பயப்படாதே, உன் ராஜா கழுதைக்குட்டியின்மேல் ஏறிவருகிறார் என்று எழுதியிருக்கிறபிரகாரமாக,
	<br />
	15. இயேசு ஒரு கழுதைக்குட்டியைக் கண்டு அதின்மேல் ஏறிப்போனார்.
	<br />
	16. இவைகளை அவருடைய சீஷர்கள் துவக்கத்திலே அறியவில்லை. இயேசு மகிமையடைந்த பின்பு, இப்படி அவரைக் குறித்து எழுதியிருக்கிறதையும், தாங்கள் இப்படி அவருக்குச் செய்ததையும் நினைவுகூர்ந்தார்கள்.
	<br />
	17. அன்றியும் அவருடனேகூட இருந்த ஜனங்கள் அவர் லாசருவைக் கல்லறையிலிருந்து வெளியே வரவழைத்து, அவனை உயிரோடே எழுப்பினாரென்று சாட்சிகொடுத்தார்கள்.
	<br />
	18. அப்படிப்பட்ட அற்புதத்தை அவர் செய்தார் என்று ஜனங்கள் கேள்விப்பட்டதினால் அவர்கள் அவருக்கு எதிர்கொண்டு போனார்கள்.
	<br />
	19. அப்பொழுது பரிசேயர் ஒருவரையொருவர் நோக்கி: நீங்கள் செய்கிறதெல்லாம் வீணென்று அறிகிறதில்லையா? இதோ, உலகமே அவனுக்குப் பின்சென்று போயிற்றே என்றார்கள்.
	<br />
	20. பண்டிகையில் ஆராதனை செய்யவந்தவர்களில் சில கிரேக்கர் இருந்தார்கள்.
	<br />
	21. அவர்கள் கலிலேயாநாட்டுப் பெத்சாயிதா ஊரானாகிய பிலிப்புவினிடத்தில் வந்து: ஐயா, இயேசுவைக் காண விரும்புகிறோம் என்று அவனைக் கேட்டுக்கொண்டார்கள்.
	<br />
	22. பிலிப்பு வந்து, அதை அந்திரேயாவுக்கு அறிவித்தான்; பின்பு அந்திரேயாவும் பிலிப்புவும் அதை இயேசுவுக்கு அறிவித்தார்கள்.
	<br />
	23. அப்பொழுது இயேசு அவர்களை நோக்கி: மனுஷகுமாரன் மகிமைப்படும்படியான வேளை வந்தது.
	<br />
	24. மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன், கோதுமை மணியானது நிலத்தில் விழுந்து சாகாவிட்டால் தனித்திருக்கும், செத்ததேயாகில் மிகுந்த பலனைக்கொடுக்கும்.
	<br />
	25. தன் ஜீவனைச் சிநேகிக்கிறவன் அதை இழந்துபோவான்; இந்த உலகத்தில் தன் ஜீவனை வெறுக்கிறவனோ அதை நித்திய ஜீவகாலமாய்க் காத்துக்கொள்ளுவான்.
	<br />
	26. ஒருவன் எனக்கு ஊழியஞ்செய்கிறவனானால் என்னைப் பின்பற்றக்கடவன், நான் எங்கே இருக்கிறேனோ அங்கே என் ஊழியக்காரனும் இருப்பான்; ஒருவன் எனக்கு ஊழியஞ்செய்தால் அவனைப் பிதாவானவர் கனம்பண்ணுவார்.
	<br />
	27. இப்பொழுது என் ஆத்துமா கலங்குகிறது, நான் என்ன சொல்லுவேன். பிதாவே, இந்த வேளையினின்று என்னை இரட்சியும் என்று சொல்வேனோ; ஆகிலும், இதற்காகவே இந்த வேளைக்குள் வந்தேன்.
	<br />
	28. பிதாவே, உமது நாமத்தை மகிமைப்படுத்தும் என்றார். அப்பொழுது: மகிமைப்படுத்தினேன், இன்னமும் மகிமைப்படுத்துவேன் என்கிற சத்தம் வானத்திலிருந்து உண்டாயிற்று.
	<br />
	29. அங்கே நின்றுகொண்டிருந்து, அதைக் கேட்ட ஜனங்கள்: இடிமுழக்கமுண்டாயிற்று என்றார்கள். வேறுசிலர்: தேவதூதன் அவருடனே பேசினான் என்றார்கள்.
	<br />
	30. இயேசு அவர்களை நோக்கி: இந்தச் சத்தம் என்னிமித்தம் உண்டாகாமல் உங்கள் நிமித்தமே உண்டாயிற்று.
	<br />
	31. இப்பொழுதே இந்த உலகத்துக்கு நியாயத்தீர்ப்பு உண்டாகிறது; இப்பொழுதே இந்த உலகத்தின் அதிபதி புறம்பாகத் தள்ளப்படுவான்.
	<br />
	32. நான் பூமியிலிருந்து உயர்த்தப்பட்டிருக்கும்போது, எல்லாரையும் என்னிடத்தில் இழுத்துக்கொள்ளுவேன் என்றார்.
	<br />
	33. தாம் இன்னவிதமான மரணமாய் மரிக்கப்போகிறாரென்பதைக் குறிக்கும்படி இப்படிச் சொன்னார்.
	<br />
	34. ஜனங்கள் அவரை நோக்கி: கிறிஸ்து என்றென்றைக்கும் இருக்கிறார் என்று வேதத்தில் சொல்லியதை நாங்கள் கேட்டிருக்கிறோம், அப்படியிருக்க மனுஷகுமாரன் உயர்த்தப்படவேண்டியதென்று எப்படிச் சொல்லுகிறீர்; இந்த மனுஷகுமாரன் யார் என்றார்கள்.
	<br />
	35. அதற்கு இயேசு: இன்னும் கொஞ்சக்காலம் ஒளி உங்களிடத்தில் இருக்கும்; இருளில் நீங்கள் அகப்படாதபடிக்கு ஒளி உங்களோடிருக்கையில் நடவுங்கள்; இருளில் நடக்கிறவன் தான் போகிற இடம் இன்னதென்று அறியான்.
	<br />
	36. ஒளி உங்களோடிருக்கையில் நீங்கள் ஒளியின் பிள்ளைகளாகும்படிக்கு, ஒளியினிடத்தில் விசுவாசமாயிருங்கள் என்றார். இவைகளை இயேசு சொல்லி, அவர்களை விட்டு மறைந்தார்.
	<br />
	37. அவர் இத்தனை அற்புதங்களை அவர்களுக்கு முன்பாகச் செய்திருந்தும், அவர்கள் அவரை விசுவாசிக்கவில்லை.
	<br />
	38. கர்த்தாவே, எங்கள் மூலமாய்க் கேள்விப்பட்டதை விசுவாசித்தவன் யார்? கர்த்தருடைய புயம் யாருக்கு வெளிப்பட்டது என்று ஏசாயா தீர்க்கதரிசி சொன்ன வசனம் நிறைவேறும்படி இப்படி நடந்தது.
	<br />
	39. ஆகையால் அவர்கள் விசுவாசிக்கமாட்டாமல்போனார்கள். ஏனெனில் ஏசாயா பின்னும்:
	<br />
	40. அவர்கள் கண்களினால் காணாமலும், இருதயத்தினால் உணராமலும், குணப்படாமலும் இருக்கும்படிக்கும், நான் அவர்களை ஆரோக்கியமாக்காமல் இருக்கும்படிக்கும், அவர்களுடைய கண்களை அவர் குருடாக்கி, அவர்கள் இருதயத்தைக் கடினமாக்கினார் என்றான்.
	<br />
	41. ஏசாயா அவருடைய மகிமையைக்கண்டு, அவரைக்குறித்துப் பேசுகிறபோது இவைகளைச் சொன்னான்.
	<br />
	42. ஆகிலும் அதிகாரிகளிலும் அநேகர் அவரிடத்தில் விசுவாசம் வைத்தார்கள். அப்படியிருந்தும் ஜெபஆலயத்துக்குப் புறம்பாக்கப்படாதபடி, பரிசேயர்நிமித்தம் அதை அறிக்கைபண்ணாதிருந்தார்கள்.
	<br />
	43. அவர்கள் தேவனால் வருகிற மகிமையிலும் மனுஷரால் வருகிற மகிமையை அதிகமாய் விரும்பினார்கள்.
	<br />
	44. அப்பொழுது இயேசு சத்தமிட்டு: என்னிடத்தில் விசுவாசமாயிருக்கிறவன் என்னிடத்தில் அல்ல, என்னை அனுப்பினவரிடத்தில் விசுவாசமாயிருக்கிறான்.
	<br />
	45. என்னைக் காண்கிறவன் என்னை அனுப்பினவரைக் காண்கிறான்.
	<br />
	46. என்னிடத்தில் விசுவாசமாயிருக்கிறவனெவனும் இருளில் இராதபடிக்கு, நான் உலகத்தில் ஒளியாக வந்தேன்.
	<br />
	47. ஒருவன் என் வார்த்தைகளைக் கேட்டும் விசுவாசியாமற்போனால், அவனை நான் நியாயந்தீர்ப்பதில்லை; நான் உலகத்தை நியாயந்தீர்க்கவராமல், உலகத்தை இரட்சிக்கவந்தேன்.
	<br />
	48. என்னைத் தள்ளி என் வார்த்தைகளை ஏற்றுக்கொள்ளாதவனை நியாயந்தீர்க்கிறதொன்றிருக்கிறது; நான் சொன்ன வசனமே அவனைக் கடைசிநாளில் நியாயந்தீர்க்கும்.
	<br />
	49. நான் சுயமாய்ப் பேசவில்லை, நான் பேசவேண்டியது இன்னதென்றும் உபதேசிக்கவேண்டியது இன்னதென்றும் என்னை அனுப்பின பிதாவே எனக்குக் கட்டளையிட்டார்.
	<br />
	50. அவருடைய கட்டளை நித்திய ஜீவனாயிருக்கிறதென்று அறிவேன்; ஆகையால் நான் பேசுகிறவைகளைப் பிதா எனக்குச் சொன்னபடியே பேசுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John12