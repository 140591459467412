import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 1 </title>
	<meta
          name="description"
          content="Jeremiah 1 | எரேமியா 1 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பென்யமீன் தேசத்திலுள்ள ஆனதோத் ஊரிலிருந்த ஆசாரியர்களில் ஒருவனாகிய இல்க்கியாவின் குமாரன் எரேமியாவினுடைய வசனங்கள்:
	<br /><br />
	2. ஆமோனுடைய குமாரனாகிய யோசியா என்கிற யூதாவுடைய ராஜாவின் நாட்களில், அவன் அரசாண்ட பதின்மூன்றாம் வருஷத்தில் இவனுக்குக் கர்த்தருடைய வார்த்தை உண்டாயிற்று.
	<br /><br />
	3. அப்புறம் யோசியாவின் குமாரனாகிய யோயாக்கீம் என்கிற யூதாவுடைய ராஜாவின் நாட்களிலும், யோசியாவின் குமாரனாகிய சிதேக்கியா என்கிற யூதாவுடைய ராஜாவின் பதினோராம் வருஷத்து முடிவுமட்டாகவும், எருசலேம் ஊரார் ஐந்தாம் மாதத்தில் சிறைப்பட்டுப்போகும்வரைக்கும் கர்த்தருடைய வார்த்தை அவனுக்கு உண்டாயிற்று.
	<br /><br />
	4. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	5. நான் உன்னைத் தாயின் வயிற்றில் உருவாக்கு முன்னே உன்னை அறிந்தேன்; நீ கர்ப்பத்திலிருந்து வெளிப்படுமுன்னே நான் உன்னைப் பரிசுத்தம்பண்ணி, உன்னை ஜாதிகளுக்குத் தீர்க்கதரிசியாகக் கட்டளையிட்டேன் என்று சொன்னார்.
	<br /><br />
	6. அப்பொழுது நான்: ஆ கர்த்தராகிய ஆண்டவரே, இதோ, நான் பேச அறியேன்; சிறுபிள்ளையாயிருக்கிறேன் என்றேன்.
	<br /><br />
	7. ஆனாலும் கர்த்தர்: நான் சிறுபிள்ளையென்று நீ சொல்லாதே, நான் உன்னை அனுப்புகிற எல்லாரிடத்திலும் நீ போய், நான் உனக்குக் கட்டளையிடுகிறவைகளையெல்லாம் நீ பேசுவாயாக.
	<br /><br />
	8. நீ அவர்களுக்குப் பயப்படவேண்டாம்; உன்னைக் காக்கும்படிக்கு நான் உன்னுடனே இருக்கிறேன் என்று கர்த்தர் சொல்லி,
	<br /><br />
	9. கர்த்தர் தமது கரத்தை நீட்டி, என் வாயைத் தொட்டு: இதோ, என் வார்த்தைகளை உன் வாயிலே வைக்கிறேன்.
	<br /><br />
	10. பார், பிடுங்கவும், இடிக்கவும், அழிக்கவும், கவிழ்க்கவும், கட்டவும், நாட்டவும் உன்னை நான் இன்றையதினம் ஜாதிகளின்மேலும் ராஜ்யங்களின்மேலும் ஏற்படுத்தினேன் என்று கர்த்தர் என்னுடனே சொன்னார்.
	<br /><br />
	11. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்: எரேமியாவே, நீ என்னத்தைக் காண்கிறாய் என்று கேட்டார்; வாதுமைமரத்தின் கிளையைக் காண்கிறேன் என்றேன்.
	<br /><br />
	12. அப்பொழுது கர்த்தர்: நீ கண்டது சரியே; என் வார்த்தையைத் தீவிரமாய் நிறைவேற்றுவேன் என்றார்.
	<br /><br />
	13. கர்த்தருடைய வார்த்தை இரண்டாந்தரம் எனக்கு உண்டாகி, அவர்: நீ காண்கிறது என்ன என்று கேட்டார்; பொங்குகிற பானையைக் காண்கிறேன், அதின் வாய் வடக்கேயிருந்து நோக்குகிறது என்றேன்.
	<br /><br />
	14. அப்பொழுது கர்த்தர் என்னை நோக்கி: வடக்கேயிருந்து தீங்கு தேசத்தினுடைய குடிகள் எல்லார்மேலும் வரும்.
	<br /><br />
	15. இதோ, நான் வடதிசை ராஜ்யங்களின் வம்சங்களையெல்லாம் கூப்பிடுவேன் என்று கர்த்தர் சொல்லுகிறார்; அவர்கள் வந்து அவனவன் தன் தன் சிங்காசனத்தை எருசலேமின் ஒலிமுகவாசல்களுக்கும், அதின் சுற்றுமதில்கள் எல்லாவற்றிற்கும் விரோதமாகவும், யூதா தேசத்து எல்லாப் பட்டணங்களுக்கும் விரோதமாகவும் வைப்பார்கள்.
	<br /><br />
	16. அவர்கள் என்னைவிட்டு அந்நிய தேவர்களுக்குத் தூபங்காட்டி, தங்கள் கைகளின் கிரியையைப் பணிந்துகொண்ட அவர்களுடைய சகல தீமைகளினிமித்தமும் நான் என் நியாயத்தீர்ப்புகளை அவர்களுக்கு விரோதமாகக் கூறுவேன்.
	<br /><br />
	17. ஆகையால் நீ உன் அரையைக் கட்டிக்கொண்டு நின்று, நான் உனக்குக் கட்டளையிடுகிறவைகளையெல்லாம் அவர்களுக்குச் சொல்; நான் உன்னை அவர்களுக்கு முன்பாகக் கலங்கப்பண்ணாதபடிக்கு, நீ அவர்கள் முகத்துக்கு அஞ்சாதிரு.
	<br /><br />
	18. இதோ, தேசமனைத்துக்கும், யூதாவின் ராஜாக்களுக்கும், அதின் பிரபுக்களுக்கும், அதின் ஆசாரியர்களுக்கும், தேசத்தின் ஜனங்களுக்கும் எதிராக நான் உன்னை இன்றையதினம் அரணிப்பான பட்டணமும், இருப்புத்தூணும், வெண்கல அலங்கமும் ஆக்கினேன்.
	<br /><br />
	19. அவர்கள் உனக்கு விரோதமாக யுத்தம்பண்ணுவார்கள்; ஆனாலும் உன்னை மேற்கொள்ளமாட்டார்கள்; உன்னை இரட்சிக்கும்படிக்கு நான் உன்னுடனே இருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
</p>
<p>
	Chapter 2
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah1