import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 15 </title>
	<meta
          name="description"
          content="Matthew 15 | மத்தேயு 15 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்பொழுது, எருசலேமிலிருந்து வந்த வேதபாரகரும் பரிசேயரும் இயேசுவினிடத்தில் வந்து:
	<br /><br /><br /><br />
	2. உம்முடைய சீஷர்கள் முன்னோர்களின் பாரம்பரியத்தை ஏன் மீறி நடக்கிறார்கள்? கைகழுவாமல் போஜனம் பண்ணுகிறார்களே! என்றார்கள்.
	<br /><br /><br /><br />
	3. அவர்களுக்கு அவர் பிரதியுத்தரமாக: நீங்கள் உங்கள் பாரம்பரியத்தினாலே தேவனுடைய கற்பனையை ஏன் மீறி நடக்கிறீர்கள்?
	<br /><br /><br /><br />
	4. உன் தகப்பனையும் உன் தாயையும் கனம்பண்ணுவாயாக என்றும்; தகப்பனையாவது தாயையாவது நிந்திக்கிறவன் கொல்லப்படவேண்டும் என்றும், தேவன் கற்பித்திருக்கிறாரே.
	<br /><br /><br /><br />
	5. நீங்களோ, எவனாகிலும் தகப்பனையாவது தாயையாவது நோக்கி: உனக்கு நான் செய்யத்தக்க உதவி எது உண்டோ, அதைக் காணிக்கையாகக் கொடுக்கிறேன் என்று சொல்லி, தன் தகப்பனையாவது தன் தாயையாவது கனம்பண்ணாமற்போனாலும், அவனுடைய கடமை தீர்ந்ததென்று போதித்து,
	<br /><br /><br /><br />
	6. உங்கள் பாரம்பரியத்தினாலே தேவனுடைய கற்பனையை அவமாக்கிவருகிறீர்கள்.
	<br /><br /><br /><br />
	7. மாயக்காரரே, உங்களைக்குறித்து:
	<br /><br /><br /><br />
	8. இந்த ஜனங்கள் தங்கள் வாயினால் என்னிடத்தில் சேர்ந்து, தங்கள் உதடுகளினால் என்னைக் கனம்பண்ணுகிறார்கள்; அவர்கள் இருதயமோ எனக்குத் தூரமாய் விலகியிருக்கிறது;
	<br /><br /><br /><br />
	9. மனுஷருடைய கற்பனைகளை உபதேசங்களாகப் போதித்து, வீணாய் எனக்கு ஆராதனை செய்கிறார்கள் என்று, ஏசாயா தீர்க்கதரிசி நன்றாய்ச் சொல்லியிருக்கிறான் என்றார்.
	<br /><br /><br /><br />
	10. பின்பு அவர் ஜனங்களை வரவழைத்து, அவர்களை நோக்கி: நீங்கள் கேட்டு உணருங்கள்.
	<br /><br /><br /><br />
	11. வாய்க்குள்ளே போகிறது மனுஷனைத் தீட்டுப்படுத்தாது, வாயிலிருந்து புறப்படுகிறதே மனுஷனைத் தீட்டுப்படுத்தும் என்றார்.
	<br /><br /><br /><br />
	12. அப்பொழுது, அவருடைய சீஷர்கள் அவரிடத்தில் வந்து: பரிசேயர் இந்த வசனத்தைக் கேட்டு இடறலடைந்தார்கள் என்று அறிவீரா என்றார்கள்.
	<br /><br /><br /><br />
	13. அவர் பிரதியுத்தரமாக: என் பரமபிதா நடாத நாற்றெல்லாம் வேரோடே பிடுங்கப்படும்.
	<br /><br /><br /><br />
	14. அவர்களை விட்டுவிடுங்கள், அவர்கள் குருடருக்கு வழிகாட்டுகிற குருடராயிருக்கிறார்கள்; குருடனுக்குக் குருடன் வழிகாட்டினால் இருவரும் குழியிலே விழுவார்களே என்றார்.
	<br /><br /><br /><br />
	15. அப்பொழுது, பேதுரு அவரை நோக்கி: இந்த உவமையை எங்களுக்கு வெளிப்படுத்தவேண்டும் என்றான்.
	<br /><br /><br /><br />
	16. அதற்கு இயேசு: நீங்களும் இன்னும் உணர்வில்லாதவர்களாயிருக்கிறீர்களா?
	<br /><br /><br /><br />
	17. வாய்க்குள்ளே போகிறதெல்லாம் வயிற்றில் சென்று ஆசனவழியாய்க் கழிந்துபோம் என்பதை நீங்கள் இன்னும் அறியவில்லையா?
	<br /><br /><br /><br />
	18. வாயிலிருந்து புறப்படுகிறவைகள் இருதயத்திலிருந்து புறப்பட்டுவரும்; அவைகளே மனுஷனைத் தீட்டுப்படுத்தும்.
	<br /><br /><br /><br />
	19. எப்படியெனில், இருதயத்திலிருந்து பொல்லாத சிந்தனைகளும், கொலைபாதகங்களும், விபசாரங்களும், வேசித்தனங்களும், களவுகளும், பொய்ச்சாட்சிகளும், தூஷணங்களும் புறப்பட்டுவரும்.
	<br /><br /><br /><br />
	20. இவைகளே மனுஷனைத் தீட்டுப்படுத்தும்; கைகழுவாமல் சாப்பிடுகிறது மனுஷனைத் தீட்டுப்படுத்தாது என்றார்.
	<br /><br /><br /><br />
	21. பின்பு, இயேசு அவ்விடம் விட்டுப் புறப்பட்டு, தீரு சீதோன் பட்டணங்களின் திசைகளுக்குப் போனார்.
	<br /><br /><br /><br />
	22. அப்பொழுது, அந்தத் திசைகளில் குடியிருக்கிற கானானிய ஸ்திரீ ஒருத்தி அவரிடத்தில் வந்து: ஆண்டவரே, தாவீதின் குமாரனே, எனக்கு இரங்கும், என் மகள் பிசாசினால் கொடிய வேதனைப்படுகிறாள் என்று சொல்லிக் கூப்பிட்டாள்.
	<br /><br /><br /><br />
	23. அவளுக்குப் பிரதியுத்தரமாக அவர் ஒரு வார்த்தையும் சொல்லவில்லை. அப்பொழுது அவருடைய சீஷர்கள் வந்து: இவள் நம்மைப் பின்தொடர்ந்து கூப்பிடுகிறாளே, இவளை அனுப்பிவிடும் என்று அவரை வேண்டிக்கொண்டார்கள்.
	<br /><br /><br /><br />
	24. அதற்கு அவர்: காணாமற்போன ஆடுகளாகிய இஸ்ரவேல் வீட்டாரிடத்திற்கு அனுப்பப்பட்டேனேயன்றி, மற்றப்படியல்ல என்றார்.
	<br /><br /><br /><br />
	25. அவள் வந்து: ஆண்டவரே, எனக்கு உதவிசெய்யும் என்று அவரைப் பணிந்துகொண்டாள்.
	<br /><br /><br /><br />
	26. அவர் அவளை நோக்கி: பிள்ளைகளின் அப்பத்தை எடுத்து, நாய்க்குட்டிகளுக்குப் போடுகிறது நல்லதல்ல என்றார்.
	<br /><br /><br /><br />
	27. அதற்கு அவள்: மெய்தான் ஆண்டவரே, ஆகிலும் நாய்க்குட்டிகள் தங்கள் எஜமான்களின் மேஜையிலிருந்து விழும் துணிக்கைகளைத் தின்னுமே என்றாள்.
	<br /><br /><br /><br />
	28. இயேசு அவளுக்குப் பிரதியுத்தரமாக: ஸ்திரீயே, உன் விசுவாசம் பெரிது; நீ விரும்புகிறபடி உனக்கு ஆகக்கடவது என்றார். அந்நேரமே அவள் மகள் ஆரோக்கியமானாள்.
	<br /><br /><br /><br />
	29. இயேசு அவ்விடம் விட்டுப் புறப்பட்டு, கலிலேயாக் கடலருகே வந்து, ஒரு மலையின்மேல் ஏறி, அங்கே உட்கார்ந்தார்.
	<br /><br /><br /><br />
	30. அப்பொழுது, சப்பாணிகள், குருடர், ஊமையர், ஊனர் முதலிய அநேகரை, திரளான ஜனங்கள் கூட்டிக்கொண்டு இயேசுவினிடத்தில் வந்து, அவர்களை அவர் பாதத்திலே வைத்தார்கள்; அவர்களை அவர் சொஸ்தப்படுத்தினார்.
	<br /><br /><br /><br />
	31. ஊமையர் பேசுகிறதையும், ஊனர் சொஸ்தமடைகிறதையும், சப்பாணிகள் நடக்கிறதையும், குருடர் பார்க்கிறதையும் ஜனங்கள் கண்டு, ஆச்சரியப்பட்டு, இஸ்ரவேலின் தேவனை மகிமைப்படுத்தினார்கள்.
	<br /><br /><br /><br />
	32. பின்பு, இயேசு தம்முடைய சீஷர்களை அழைத்து: ஜனங்களுக்காகப் பரிதபிக்கிறேன், இவர்கள் என்னிடத்தில் மூன்றுநாள் தங்கியிருந்து சாப்பிட ஒன்றுமில்லாதிருக்கிறார்கள்; இவர்களைப் பட்டினியாய் அனுப்பிவிட எனக்கு மனதில்லை, வழியில் சோர்ந்துபோவார்களே என்றார்.
	<br /><br /><br /><br />
	33. அதற்கு அவருடைய சீஷர்கள்: இவ்வளவு திரளான ஜனங்களுக்குத் திருப்தியுண்டாகும்படி வேண்டிய அப்பங்கள் இந்த வனாந்தரத்திலே நமக்கு எப்படி அகப்படும் என்றார்கள்.
	<br /><br /><br /><br />
	34. அதற்கு இயேசு: உங்களிடத்தில் எத்தனை அப்பங்கள் உண்டு என்று கேட்டார். அவர்கள்: ஏழு அப்பங்களும் சில சிறு மீன்களும் உண்டு என்றார்கள்.
	<br /><br /><br /><br />
	35. அப்பொழுது அவர் ஜனங்களைத் தரையில் பந்தியிருக்கக் கட்டளையிட்டு,
	<br /><br /><br /><br />
	36. அந்த ஏழு அப்பங்களையும் அந்த மீன்களையும் எடுத்து, ஸ்தோத்திரம் பண்ணி, பிட்டுத் தம்முடைய சீஷர்களிடத்தில் கொடுத்தார்; சீஷர்கள் ஜனங்களுக்குப் பரிமாறினார்கள்.
	<br /><br /><br /><br />
	37. எல்லாரும் சாப்பிட்டுத் திருப்தியடைந்தார்கள்; மீதியான துணிக்கைகளை ஏழு கூடைநிறைய எடுத்தார்கள்.
	<br /><br /><br /><br />
	38. ஸ்திரீகளும் பிள்ளைகளும் தவிர, சாப்பிட்ட புருஷர் நாலாயிரம்பேராயிருந்தார்கள்.
	<br /><br /><br /><br />
	39. அவர் ஜனங்களை அனுப்பிவிட்டு, படவில் ஏறி, மக்தலாவின் எல்லைகளில் வந்தார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew15