import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew26 = () => {
  const verseNumber = 26;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 26 </title>
	<meta
          name="description"
          content="Matthew 26 | மத்தேயு 26 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு இந்த வசனங்களையெல்லாம் சொல்லி முடித்தபின்பு, அவர் தம்முடைய சீஷரை நோக்கி:
	<br /><br />
	2. இரண்டுநாளைக்குப்பின்பு பஸ்காபண்டிகை வருமென்று அறிவீர்கள்; அப்பொழுது, மனுஷகுமாரன் சிலுவையில் அறையப்படுவதற்கு ஒப்புக்கொடுக்கப்படுவார் என்றார்.
	<br /><br />
	3. அப்பொழுது, பிரதான ஆசாரியரும் வேதபாரகரும் ஜனத்தின் மூப்பரும், காய்பா என்னப்பட்ட பிரதான ஆசாரியனுடைய அரமனையிலே கூடிவந்து,
	<br /><br />
	4. இயேசுவைத் தந்திரமாய்ப் பிடித்துக் கொலைசெய்யும்படி ஆலோசனைபண்ணினார்கள்.
	<br /><br />
	5. ஆகிலும் ஜனங்களுக்குள்ளே கலகமுண்டாகாதபடிக்குப் பண்டிகையிலே அப்படிச் செய்யலாகாது என்றார்கள்.
	<br /><br />
	6. இயேசு பெத்தானியாவில் குஷ்டரோகியாயிருந்த சீமோன் வீட்டில் இருக்கையில்,
	<br /><br />
	7. ஒரு ஸ்திரீ விலையேறப்பெற்ற பரிமளதைலமுள்ள வெள்ளைக்கல்பரணியைக் கொண்டுவந்து, அவர் போஜனபந்தியிலிருக்கும்போது, அந்தத் தைலத்தை அவர் சிரசின்மேல் ஊற்றினாள்.
	<br /><br />
	8. அவருடைய சீஷர்கள் அதைக் கண்டு விசனமடைந்து: இந்த வீண்செலவு என்னத்திற்கு?
	<br /><br />
	9. இந்தத் தைலத்தை உயர்ந்த விலைக்கு விற்று, தரித்திரருக்குக் கொடுக்கலாமே என்றார்கள்.
	<br /><br />
	10. இயேசு அதை அறிந்து, அவர்களை நோக்கி: நீங்கள் இந்த ஸ்திரீயை ஏன் தொந்தரவுபடுத்துகிறீர்கள்? என்னிடத்தில் நற்கிரியையைச் செய்திருக்கிறாள்.
	<br /><br />
	11. தரித்திரர் எப்போதும் உங்களிடத்தில் இருக்கிறார்கள்; நானோ எப்போதும் உங்களிடத்தில் இரேன்.
	<br /><br />
	12. இவள் இந்தத் தைலத்தை என் சரீரத்தின்மேல் ஊற்றினது என்னை அடக்கம் பண்ணுவதற்கு எத்தனமான செய்கையாயிருக்கிறது.
	<br /><br />
	13. இந்தச் சுவிசேஷம் உலகத்தில் எங்கெங்கே பிரசங்கிக்கப்படுமோ அங்கங்கே இவளை நினைப்பதற்காக இவள் செய்ததும் சொல்லப்படும் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	14. அப்பொழுது, பன்னிருவரில் ஒருவனாகிய யூதாஸ்காரியோத்து என்பவன் பிரதான ஆசாரியரிடத்திற்குப் போய்:
	<br /><br />
	15. நான் அவரை உங்களுக்குக் காட்டிக்கொடுக்கிறேன், நீங்கள் எனக்கு என்ன கொடுக்கிறீர்கள் என்றான். அவர்கள் அவனுக்கு முப்பது வெள்ளிக்காசைக் கொடுக்க உடன்பட்டார்கள்.
	<br /><br />
	16. அதுமுதல் அவன் அவரைக் காட்டிக்கொடுப்பதற்குச் சமயம் பார்த்துக்கொண்டிருந்தான்.
	<br /><br />
	17. புளிப்பில்லாத அப்பப்பண்டிகையின் முதல்நாளிலே, சீஷர்கள் இயேசுவினிடத்தில் வந்து: பஸ்காவைப் புசிப்பதற்கு நாங்கள் எங்கே உமக்கு ஆயத்தம்பண்ணச் சித்தமாயிருக்கிறீர் என்று கேட்டார்கள்.
	<br /><br />
	18. அதற்கு அவர்: நீங்கள் நகரத்திலே இன்னானிடத்திற்குப் போய்: என் வேளை சமீபமாயிருக்கிறது, உன் வீட்டிலே என் சீஷரோடேகூடப் பஸ்காவை ஆசரிப்பேன் என்று போதகர் சொல்லுகிறார் என்று அவனுக்குச் சொல்லுங்கள் என்றார்.
	<br /><br />
	19. இயேசு கற்பித்தபடி சீஷர்கள் போய், பஸ்காவை ஆயத்தம்பண்ணினார்கள்.
	<br /><br />
	20. சாயங்காலமானபோது, பன்னிருவரோடுங்கூட அவர் பந்தியிருந்தார்.
	<br /><br />
	21. அவர்கள் போஜனம்பண்ணுகையில், அவர்: உங்களிலொருவன் என்னைக் காட்டிக்கொடுப்பான் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	22. அப்பொழுது, அவர்கள் மிகவும் துக்கமடைந்து, அவரை நோக்கி: ஆண்டவரே, நானோ, நானோ? என்று ஒவ்வொருவராய்க் கேட்கத்தொடங்கினார்கள்.
	<br /><br />
	23. அவர் பிரதியுத்தரமாக: என்னோடேகூடத் தாலத்தில் கையிடுகிறவனே என்னைக் காட்டிக்கொடுப்பான்.
	<br /><br />
	24. மனுஷகுமாரன் தம்மைக்குறித்து எழுதியிருக்கிறபடியே போகிறார்; ஆகிலும், எந்த மனுஷனால் மனுஷகுமாரன் காட்டிக்கொடுக்கப்படுகிறாரோ, அந்த மனுஷனுக்கு ஐயோ; அந்த மனுஷன் பிறவாதிருந்தானானால் அவனுக்கு நலமாயிருக்கும் என்றார்.
	<br /><br />
	25. அவரைக் காட்டிக்கொடுத்த யூதாசும் அவரை நோக்கி: ரபீ, நானோ? என்றான்; அதற்கு அவர்: நீ சொன்னபடிதான் என்றார்.
	<br /><br />
	26. அவர்கள் போஜனம்பண்ணுகையில், இயேசு அப்பத்தை எடுத்து, ஆசீர்வதித்து, அதைப் பிட்டு, சீஷர்களுக்குக் கொடுத்து: நீங்கள் வாங்கிப் புசியுங்கள், இது என்னுடைய சரீரமாயிருக்கிறது என்றார்.
	<br /><br />
	27. பின்பு, பாத்திரத்தையும் எடுத்து, ஸ்தோத்திரம்பண்ணி, அவர்களுக்குக் கொடுத்து: நீங்கள் எல்லாரும் இதிலே பானம்பண்ணுங்கள்;
	<br /><br />
	28. இது பாவமன்னிப்புண்டாகும்படி அநேகருக்காகச் சிந்தப்படுகிற புது உடன்படிக்கைக்குரிய என்னுடைய இரத்தமாயிருக்கிறது.
	<br /><br />
	29. இதுமுதல் இந்தத் திராட்சப்பழரசத்தை நவமானதாய் உங்களோடேகூட என் பிதாவின் ராஜ்யத்திலே நான் பானம்பண்ணும் நாள்வரைக்கும் இதைப் பானம் பண்ணுவதில்லையென்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	30. அவர்கள் ஸ்தோத்திரப்பாட்டைப் பாடினபின்பு, ஒலிவமலைக்குப் புறப்பட்டுப்போனார்கள்.
	<br /><br />
	31. அப்பொழுது, இயேசு அவர்களை நோக்கி: மேய்ப்பனை வெட்டுவேன், மந்தையின் ஆடுகள் சிதறடிக்கப்படும் என்று எழுதியிருக்கிறபடி, இந்த இராத்திரியிலே நீங்கள் எல்லாரும் என்னிமித்தம் இடறலடைவீர்கள்.
	<br /><br />
	32. ஆகிலும் நான் உயிர்த்தெழுந்த பின்பு, உங்களுக்கு முன்னே கலிலேயாவுக்குப் போவேன் என்றார்.
	<br /><br />
	33. பேதுரு அவருக்குப் பிரதியுத்தரமாக: உமதுநிமித்தம் எல்லாரும் இடறலடைந்தாலும், நான் ஒருக்காலும் இடறலடையேன் என்றான்.
	<br /><br />
	34. இயேசு அவனை நோக்கி: இந்த இராத்திரியிலே சேவல் கூவுகிறதற்கு முன்னே, நீ என்னை மூன்றுதரம் மறுதலிப்பாய் என்று, மெய்யாகவே உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	35. அதற்குப் பேதுரு: நான் உம்மோடே மரிக்கவேண்டியதாயிருந்தாலும் உம்மை மறுதலிக்கமாட்டேன் என்றான்; சீஷர்கள் எல்லாரும் அப்படியே சொன்னார்கள்.
	<br /><br />
	36. அப்பொழுது, இயேசு அவர்களோடே கெத்செமனே என்னப்பட்ட இடத்திற்கு வந்து, சீஷர்களை நோக்கி: நான் அங்கே போய் ஜெபம்பண்ணுமளவும் நீங்கள் இங்கே உட்கார்ந்திருங்கள் என்று சொல்லி;
	<br /><br />
	37. பேதுருவையும் செபெதேயுவின் குமாரர் இருவரையும் கூட்டிக்கொண்டுபோய், துக்கமடையவும் வியாகுலப்படவும் தொடங்கினார்.
	<br /><br />
	38. அப்பொழுது, அவர்: என் ஆத்துமா மரணத்துக்கேதுவான துக்கங்கொண்டிருக்கிறது; நீங்கள் இங்கே தங்கி, என்னோடேகூட விழித்திருங்கள் என்று சொல்லி,
	<br /><br />
	39. சற்று அப்புறம்போய், முகங்குப்புற விழுந்து: என் பிதாவே, இந்தப் பாத்திரம் என்னைவிட்டு நீங்கக்கூடுமானால் நீங்கும்படி செய்யும்; ஆகிலும் என் சித்தத்தின்படியல்ல, உம்முடைய சித்தத்தின்படியே ஆகக்கடவது என்று ஜெபம்பண்ணினார்.
	<br /><br />
	40. பின்பு, அவர் சீஷர்களிடத்தில் வந்து, அவர்கள் நித்திரைபண்ணுகிறதைக் கண்டு, பேதுருவை நோக்கி: நீங்கள் ஒரு மணி நேரமாவது என்னோடேகூட விழித்திருக்கக்கூடாதா?
	<br /><br />
	41. நீங்கள் சோதனைக்குட்படாதபடிக்கு விழித்திருந்து ஜெபம்பண்ணுங்கள்; ஆவி உற்சாகமுள்ளதுதான், மாம்சமோ பலவீனமுள்ளது என்றார்.
	<br /><br />
	42. அவர் மறுபடியும் இரண்டாந்தரம் போய்: என் பிதாவே, இந்தப் பாத்திரத்தில் நான் பானம்பண்ணினாலொழிய இது என்னைவிட்டு நீங்கக்கூடாதாகில், உம்முடைய சித்தத்தின்படி ஆகக்கடவது என்று ஜெபம்பண்ணினார்.
	<br /><br />
	43. அவர் திரும்ப வந்தபோது, அவர்கள் மறுபடியும் நித்திரைபண்ணுகிறதைக் கண்டார்; அவர்களுடைய கண்கள் மிகுந்த நித்திரைமயக்கம் அடைந்திருந்தது.
	<br /><br />
	44. அவர் மறுபடியும் அவர்களை விட்டுப்போய், மூன்றாந்தரமும் அந்த வார்த்தைகளையே சொல்லி ஜெபம்பண்ணினார்.
	<br /><br />
	45. பின்பு அவர் தம்முடைய சீஷர்களிடத்தில் வந்து: இனி நித்திரைபண்ணி இளைப்பாறுங்கள்; இதோ, மனுஷகுமாரன் பாவிகளுடைய கைகளில் ஒப்புக்கொடுக்கப்படுகிற வேளை வந்தது.
	<br /><br />
	46. என்னைக் காட்டிக்கொடுக்கிறவன், இதோ, வந்துவிட்டான், எழுந்திருங்கள், போவோம் என்றார்.
	<br /><br />
	47. அவர் இப்படிப் பேசுகையில், பன்னிருவரில் ஒருவனாகிய யூதாஸ் வந்தான்; அவனோடேகூடப் பிரதான ஆசாரியரும் ஜனத்தின் மூப்பரும் அனுப்பின திரளான ஜனங்கள் பட்டயங்களையும் தடிகளையும் பிடித்துக்கொண்டு வந்தார்கள்.
	<br /><br />
	48. அவரைக் காட்டிக்கொடுக்கிறவன்: நான் எவனை முத்தஞ்செய்வேனோ அவன்தான், அவனைப் பிடித்துக்கொள்ளுங்கள் என்று அவர்களுக்கு அடையாளம் சொல்லியிருந்தான்.
	<br /><br />
	49. உடனே, அவன் இயேசுவினிடத்தில் வந்து: ரபீ, வாழ்க என்று சொல்லி, அவரை முத்தஞ்செய்தான்.
	<br /><br />
	50. இயேசு அவனை நோக்கி: சிநேகிதனே, என்னத்திற்காக வந்திருக்கிறாய் என்றார். அப்பொழுது, அவர்கள் கிட்டவந்து, இயேசுவின்மேல் கைபோட்டு, அவரைப் பிடித்தார்கள்.
</p>
<p>
	51. அப்பொழுது இயேசுவோடிருந்தவர்களில் ஒருவன் கை நீட்டித் தன் பட்டயத்தை உருவி, பிரதான ஆசாரியனுடைய வேலைக்காரனைக் காதற வெட்டினான்.
	<br /><br />
	52. அப்பொழுது, இயேசு அவனை நோக்கி: உன் பட்டயத்தைத் திரும்ப அதின் உறையிலே போடு; பட்டயத்தை எடுக்கிற யாவரும் பட்டயத்தால் மடிந்து போவார்கள்.
	<br /><br />
	53. நான் இப்பொழுது என் பிதாவை வேண்டிக்கொண்டால், அவர் பன்னிரண்டு லேகியோனுக்கு அதிகமான தூதரை என்னிடத்தில் அனுப்பமாட்டாரென்று நினைக்கிறாயா?
	<br /><br />
	54. அப்படிச் செய்வேனானால், இவ்விதமாய்ச் சம்பவிக்கவேண்டும் என்கிற வேதவாக்கியங்கள் எப்படி நிறைவேறும் என்றார்.
	<br /><br />
	55. அந்த வேளையிலே இயேசு ஜனங்களை நோக்கி: கள்ளனைப் பிடிக்கப் புறப்படுகிறதுபோல, நீங்கள் பட்டயங்களையும் தடிகளையும் எடுத்துக்கொண்டு என்னைப் பிடிக்கவந்தீர்கள்; நான் தினந்தோறும் உங்கள் நடுவிலே உட்கார்ந்து தேவாலயத்தில் உபதேசம்பண்ணிக்கொண்டிருந்தேன்; அப்பொழுது, நீங்கள் என்னைப் பிடிக்கவில்லையே.
	<br /><br />
	56. ஆகிலும் தீர்க்கதரிசிகள் எழுதியிருக்கிற வசனங்கள் நிறைவேறும்படி இவைகளெல்லாம் சம்பவிக்கிறது என்றார். அப்பொழுது, சீஷர்களெல்லாரும் அவரைவிட்டு ஓடிப்போனார்கள்.
	<br /><br />
	57. இயேசுவைப் பிடித்தவர்கள் அவரைப் பிரதான ஆசாரியனாகிய காய்பாவினிடத்திற்குக் கொண்டுபோனார்கள்; அங்கே வேதபாரகரும் மூப்பரும் கூடிவந்திருந்தார்கள்.
	<br /><br />
	58. பேதுரு, தூரத்திலே அவருக்குப் பின்சென்று, பிரதான ஆசாரியனுடைய அரமனைவரைக்கும் வந்து, உள்ளே பிரவேசித்து, முடிவைப் பார்க்கும்படி சேவகரோடே உட்கார்ந்தான்.
	<br /><br />
	59. பிரதான ஆசாரியரும் மூப்பரும் சங்கத்தார் யாவரும் இயேசுவைக் கொலைசெய்யும்படி அவருக்கு விரோதமாய்ப் பொய்ச்சாட்சி தேடினார்கள்;
	<br /><br />
	60. ஒருவரும் அகப்படவில்லை; அநேகர் வந்து பொய்ச்சாட்சி சொல்லியும் அவர்கள் சாட்சி ஒவ்வவில்லை; கடைசியிலே இரண்டு பொய்ச்சாட்சிகள் வந்து:
	<br /><br />
	61. தேவனுடைய ஆலயத்தை இடித்துப்போடவும், மூன்றுநாளைக்குள்ளே அதைக் கட்டவும் என்னாலே ஆகும் என்று இவன் சொன்னான் என்றார்கள்.
	<br /><br />
	62. அப்பொழுது, பிரதான ஆசாரியன் எழுந்திருந்து, அவரை நோக்கி: இவர்கள் உனக்கு விரோதமாய்ச் சாட்சிசொல்லுகிறதைக்குறித்து நீ ஒன்றும் சொல்லுகிறதில்லையா என்றான்.
	<br /><br />
	63. இயேசுவோ பேசாமலிருந்தார். அப்பொழுது, பிரதான ஆசாரியன் அவரை நோக்கி: நீ தேவனுடைய குமாரனாகிய கிறிஸ்துதானா? அதை எங்களுக்குச் சொல்லும்படி ஜீவனுள்ள தேவன்பேரில் உன்னை ஆணையிட்டுக் கேட்கிறேன் என்றான்.
	<br /><br />
	64. அதற்கு இயேசு: நீர் சொன்னபடிதான்; அன்றியும், மனுஷகுமாரன் சர்வவல்லவருடைய வலதுபாரிசத்தில் வீற்றிருப்பதையும் வானத்தின் மேகங்கள்மேல் வருவதையும் இதுமுதல் காண்பீர்களென்று, உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	65. அப்பொழுது பிரதான ஆசாரியன் தன் வஸ்திரங்களைக் கிழித்துக்கொண்டு: இவன் தேவதூஷணம் சொன்னான்; இனிச் சாட்சிகள் நமக்கு வேண்டியதென்ன? இதோ, இவன் தூஷணத்தை இப்பொழுது கேட்டீர்களே.
	<br /><br />
	66. உங்களுக்கு என்னமாய்த் தோன்றுகிறது என்று கேட்டான். அதற்கு அவர்கள்: மரணத்துக்குப் பாத்திரனாயிருக்கிறான் என்றார்கள்.
	<br /><br />
	67. அப்பொழுது, அவருடைய முகத்தில் துப்பி, அவரைக் குட்டினார்கள்; சிலர் அவரைக் கன்னத்தில் அறைந்து:
	<br /><br />
	68. கிறிஸ்துவே, உம்மை அடித்தவன் யார்? அதை ஞானதிருஷ்டியினால் எங்களுக்குச் சொல்லும் என்றார்கள்.
	<br /><br />
	69. அத்தருணத்தில் பேதுரு வெளியே வந்து அரமனை முற்றத்தில் உட்கார்ந்திருந்தான். அப்பொழுது, வேலைக்காரி ஒருத்தி அவனிடத்தில் வந்து: நீயும் கலிலேயனாகிய இயேசுவோடேகூட இருந்தாய் என்றாள்.
	<br /><br />
	70. அதற்கு அவன்: நீ சொல்லுகிறது எனக்குத் தெரியாது என்று, எல்லாருக்கும் முன்பாக மறுதலித்தான்.
	<br /><br />
	71. அவன், வாசல் மண்டபத்திற்குப் போனபொழுது வேறொருத்தி அவனைக் கண்டு: இவனும் நசரேயனாகிய இயேசுவோடேகூட இருந்தான் என்று அங்கே இருந்தவர்களுக்குச் சொன்னாள்.
	<br /><br />
	72. அவனோ: அந்த மனுஷனை நான் அறியேன் என்று ஆணையிட்டு, மறுபடியும் மறுதலித்தான்.
	<br /><br />
	73. சற்றுநேரத்துக்குப்பின்பு அங்கே நின்றவர்கள் பேதுருவினிடத்தில் வந்து: மெய்யாகவே நீயும் அவர்களில் ஒருவன்; உன் பேச்சு உன்னை வெளிப்படுத்துகிறது என்றார்கள்.
	<br /><br />
	74. அப்பொழுது அவன்: அந்த மனுஷனை அறியேன் என்று சொல்லி, சபிக்கவும் சத்தியம்பண்ணவும் தொடங்கினான். உடனே சேவல் கூவிற்று.
	<br /><br />
	75. அப்பொழுது பேதுரு: சேவல் கூவுகிறதற்குமுன்னே நீ என்னை மூன்றுதரம் மறுதலிப்பாய் என்று இயேசு தன்னிடத்தில் சொன்ன வசனத்தை நினைத்துக்கொண்டு, வெளியே போய், மனங்கசந்து அழுதான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew26