import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 9 </title>
	<meta
          name="description"
          content="Luke 9 | லூக்கா 9 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் தம்முடைய பன்னிரண்டு சீஷரையும் வரவழைத்து, சகல பிசாசுகளையும் துரத்தவும், வியாதியுள்ளவர்களைக் குணமாக்கவும் அவர்களுக்கு வல்லமையும் அதிகாரமும் கொடுத்து,
	<br /><br />
	2. தேவனுடைய ராஜ்யத்தைக்குறித்துப் பிரசங்கிக்கவும், பிணியாளிகளைச் சொஸ்தமாக்கவும் அவர்களை அனுப்பினார்.
	<br /><br />
	3. அப்பொழுது அவர் அவர்களை நோக்கி: வழிக்குத் தடியையாவது பையையாவது அப்பத்தையாவது காசையாவது எடுத்துக்கொண்டு போகவேண்டாம்; இரண்டு அங்கிகளைக் கொண்டுபோகவும் வேண்டாம்.
	<br /><br />
	4. எந்த வீட்டிலே பிரவேசிக்கிறீர்களோ, அங்கே தங்கி, அங்கிருந்து புறப்படுங்கள்.
	<br /><br />
	5. உங்களை ஏற்றுக்கொள்ளாதவர்கள் எவர்களோ அவர்களுடைய ஊரைவிட்டு நீங்கள் புறப்படும்போது, அவர்களுக்குச் சாட்சியாக உங்கள் கால்களில் படிந்த தூசியை உதறிப்போடுங்கள் என்றார்.
	<br /><br />
	6. அவர்கள் புறப்பட்டுப்போய், கிராமங்கள்தோறும் திரிந்து, எங்கும் சுவிசேஷத்தைப் பிரசங்கித்து, பிணியாளிகளைக் குணமாக்கினார்கள்.
	<br /><br />
	7. அப்பொழுது காற்பங்கு தேசாதிபதியாகிய ஏரோது அவரால் செய்யப்பட்ட யாவையும் கேள்விப்பட்டதுமன்றி; சிலர் யோவான் மரித்தோரிலிருந்து எழுந்தான் என்றும்,
	<br /><br />
	8. சிலர் எலியா தோன்றினான் என்றும், வேறு சிலர் பூர்வகாலத்துத் தீர்க்கதரிசிகளில் ஒருவன் உயிர்த்தெழுந்தான் என்றும் சொல்லிக்கொண்டபடியால், கலக்கமடைந்து:
	<br /><br />
	9. யோவானை நான் சிரச்சேதம்பண்ணினேன், இவன் இப்படிப்பட்டவைகளைச் செய்கிறான் என்று கேள்விப்படுகிறேனே! இவன் யார்? என்று ஏரோது சொல்லி, அவரைப் பார்க்க விரும்பினான்.
	<br /><br />
	10. அப்போஸ்தலர் திரும்பிவந்து, தாங்கள் செய்த யாவையும் அவருக்கு விவரித்துச் சொன்னார்கள். அப்பொழுது அவர் அவர்களைக் கூட்டிக்கொண்டு, தனித்திருக்கும்படி பெத்சாயிதா என்னும் பட்டணத்தைச் சேர்ந்த வனாந்தரமான ஒரு இடத்துக்குப் போனார்.
	<br /><br />
	11. ஜனங்கள் அதை அறிந்து, அவர் பின்னே போனார்கள்; அவர்களை அவர் ஏற்றுக்கொண்டு, தேவனுடைய ராஜ்யத்தைக்குறித்து அவர்களுடனே பேசி, சொஸ்தமடையவேண்டுமென்றிருந்தவர்களைச் சொஸ்தப்படுத்தினார்.
	<br /><br />
	12. சாயங்காலமாகிறபோது, பன்னிருவரும் சேர்ந்துவந்து, அவரை நோக்கி: நாம் இருக்கிற இடம் வனாந்தரமாயிருக்கிறது, சுற்றியிருக்கிற ஊர்களிலும் கிராமங்களிலும் ஜனங்கள் போய்த் தங்கி, போஜனபதார்த்தங்களைச் சம்பாதித்துக்கொள்ளும்படி அவர்களை அனுப்பிவிடவேண்டும் என்றார்கள்.
	<br /><br />
	13. அவர் அவர்களை நோக்கி: நீங்களே அவர்களுக்குப் போஜனங்கொடுங்கள் என்றார். அதற்கு அவர்கள்: எங்களிடத்தில் ஐந்து அப்பங்களும் இரண்டு மீன்களுமாத்திரமுண்டு, இந்த ஜனங்களெல்லாருக்கும் போஜனங் கொடுக்கவேண்டியதானால், நாங்கள் போய் வாங்கிக்கொண்டு வரவேண்டுமே என்றார்கள்.
	<br /><br />
	14. ஏறக்குறைய ஐயாயிரம் புருஷர் இருந்தார்கள். அவர்களைப் பந்திக்கு ஐம்பது ஐம்பதுபேராக உட்காரும்படி சொல்லுங்கள் என்று தம்முடைய சீஷர்களுக்குச் சொன்னார்.
	<br /><br />
	15. அவர்கள் அந்தப்படியே எல்லாரையும் உட்காரும்படி செய்தார்கள்.
	<br /><br />
	16. அப்பொழுது அவர் அந்த ஐந்து அப்பங்களையும் அந்த இரண்டு மீன்களையும் எடுத்து, வானத்தை அண்ணாந்துபார்த்து, அவைகளை ஆசீர்வதித்து, பிட்டு, ஜனங்கள்முன் வைக்கும்படி சீஷர்களிடத்தில் கொடுத்தார்.
	<br /><br />
	17. எல்லாரும் சாப்பிட்டுத் திருப்தியடைந்தார்கள். மீதியான துணிக்கைகள் பன்னிரண்டு கூடைநிறைய எடுக்கப்பட்டது.
	<br /><br />
	18. பின்பு அவர் தமது சீஷரோடேகூடத் தனித்து ஜெபம்பண்ணிக்கொண்டிருக்கையில், அவர்களை நோக்கி: ஜனங்கள் என்னை யார் என்று சொல்லுகிறார்கள் என்று கேட்டார்.
	<br /><br />
	19. அதற்கு அவர்கள்: சிலர் உம்மை யோவான்ஸ்நானன் என்றும், சிலர் எலியா என்றும், வேறு சிலர் பூர்வகாலத்துத் தீர்க்கதரிசிகளில் ஒருவர் உயிர்த்தெழுந்தார் என்றும் சொல்லுகிறார்கள் என்றார்கள்.
	<br /><br />
	20. அப்பொழுது அவர்: நீங்கள் என்னை யார் என்று சொல்லுகிறீர்கள் என்று கேட்டார்; பேதுரு பிரதியுத்தரமாக: நீர் தேவனுடைய கிறிஸ்து என்றான்.
	<br /><br />
	21. அப்பொழுது அவர்கள் அதை ஒருவருக்கும் சொல்லாதபடிக்கு உறுதியாய்க் கட்டளையிட்டார்.
	<br /><br />
	22. மேலும் மனுஷகுமாரன் பல பாடுகள்படவும், மூப்பராலும் பிரதான ஆசாரியராலும் வேதபாரகராலும் ஆகாதவனென்று தள்ளப்படவும், கொல்லப்படவும், மூன்றாம் நாளில் உயிர்த்தெழுந்திருக்கவும் வேண்டும் என்று சொன்னார்.
	<br /><br />
	23. பின்பு அவர் எல்லாரையும் நோக்கி: ஒருவன் என் பின்னே வர விரும்பினால் அவன் தன்னைத்தான் வெறுத்து, தன் சிலுவையை அநுதினமும் எடுத்துக்கொண்டு, என்னைப் பின்பற்றக்கடவன்.
	<br /><br />
	24. தன் ஜீவனை இரட்சிக்க விரும்புகிறவன் அதை இழந்துபோவான்; என்னிமித்தமாகத் தன் ஜீவனை இழந்துபோகிறவன் அதை இரட்சித்துக்கொள்ளுவான்.
	<br /><br />
	25. மனுஷன் உலகமுழுவதையும் ஆதாயப்படுத்திக்கொண்டாலும், தன்னைத் தான் கெடுத்து நஷ்டப்படுத்தினால் அவனுக்கு லாபம் என்ன?
	<br /><br />
	26. என்னைக்குறித்தும் என் வார்த்தைகளைக்குறித்தும் எவன் வெட்கப்படுகிறானோ, அவனைக்குறித்து மனுஷகுமாரனும் தம்முடைய மகிமையோடும் பிதாவின் மகிமையோடும் பரிசுத்த தூதர்களின் மகிமையோடும் வரும்போது வெட்கப்படுவார்.
	<br /><br />
	27. இங்கே நிற்கிறவர்களில் சிலர் தேவனுடைய ராஜ்யத்தைக் காணுமுன், மரணத்தை ருசிபார்ப்பதில்லையென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	28. இந்த வார்த்தைகளை அவர் சொல்லி ஏறக்குறைய எட்டுநாளானபின்பு, அவர் பேதுருவையும் யோவானையும் யாக்கோபையும் கூட்டிக்கொண்டு, ஜெபம்பண்ணுகிறதற்கு ஒரு மலையின்மேல் ஏறினார்.
	<br /><br />
	29. அவர் ஜெபம்பண்ணுகையில், அவருடைய முகரூபம் மாறிற்று, அவருடைய வஸ்திரம் வெண்மையாகிப் பிரகாசித்தது.
	<br /><br />
	30. அன்றியும் மோசே எலியா என்னும் இரண்டுபேரும் மகிமையோடே காணப்பட்டு, அவருடனே சம்பாஷணைபண்ணி,
	<br /><br />
	31. அவர் எருசலேமிலே நிறைவேற்றப்போகிற அவருடைய மரணத்தைக்குறித்துப் பேசிக்கொண்டிருந்தார்கள்.
	<br /><br />
	32. பேதுருவும் அவனோடிருந்தவர்களும் நித்திரைமயக்கமாயிருந்தார்கள். ஆகிலும் அவர்கள் விழித்து அவருடைய மகிமையையும் அவரோடே நின்ற அவ்விரண்டுபேரையும் கண்டார்கள்.
	<br /><br />
	33. அவ்விருவரும் அவரைவிட்டுப் பிரிந்துபோகையில், பேதுரு இயேசுவை நோக்கி: ஐயரே, நாம் இங்கே இருக்கிறது நல்லது, உமக்கு ஒரு கூடாரமும், மோசேக்கு ஒரு கூடாரமும், எலியாவுக்கு ஒரு கூடாரமுமாக, மூன்று கூடாரங்களைப் போடுவோம் என்று, தான் சொல்லுகிறது இன்னதென்று அறியாமல் சொன்னான்.
	<br /><br />
	34. இப்படி அவன் பேசுகையில், ஒரு மேகம் வந்து அவர்கள்மேல் நிழலிட்டது; அவர்கள் அந்த மேகத்துக்குள் பிரவேசிக்கையில் சீஷர்கள் பயந்தார்கள்.
	<br /><br />
	35. அப்பொழுது: இவர் என்னுடைய நேசகுமாரன், இவருக்குச் செவிகொடுங்கள் என்று மேகத்திலிருந்து ஒரு சத்தமுண்டாயிற்று.
	<br /><br />
	36. அந்தச் சத்தம் உண்டாகையில் இயேசு ஒருவரே காணப்பட்டார். தாங்கள் கண்டவைகளில் ஒன்றையும் அவர்கள் அந்நாட்களில் ஒருவருக்கும் சொல்லாமல் அடக்கிவைத்திருந்தார்கள்.
	<br /><br />
	37. மறுநாளில் அவர்கள் மலையிலிருந்திறங்கினபோது, திரளான ஜனங்கள் அவருக்கு எதிர்கொண்டுவந்தார்கள்.
	<br /><br />
	38. அவர்களில் ஒருவன் சத்தமிட்டு: போதகரே, என் மகனைக் கடாட்சித்தருள வேண்டுமென்று உம்மை வேண்டிக்கொள்ளுகிறேன், அவன் எனக்கு ஒரே பிள்ளையாயிருக்கிறான்.
	<br /><br />
	39. ஒரு ஆவி அவனைப் பிடிக்கிறது, அப்பொழுது அலறுகிறான், அது அவனை நுரைதள்ள அலைக்கழித்து, அவனைக் கசக்கினபின்பும், அவனை விட்டு நீங்குவது அரிதாயிருக்கிறது.
	<br /><br />
	40. அதைத் துரத்திவிடும்படி உம்முடைய சீஷரை வேண்டிக்கொண்டேன், அவர்களால் கூடாமற்போயிற்று என்றான்.
	<br /><br />
	41. இயேசு பிரதியுத்தரமாக: விசுவாசமில்லாத மாறுபாடான சந்ததியே, எதுவரைக்கும் நான் உங்களோடிருந்து, உங்களிடத்தில் பொறுமையாயிருப்பேன்? உன் மகனை இங்கே கொண்டுவா என்றார்.
	<br /><br />
	42. அவன் சமீபித்துவருகையில், பிசாசு அவனைக் கீழே தள்ளி, அலைக்கழித்தது. இயேசு அந்த அசுத்தஆவியை அதட்டி, இளைஞனைக் குணமாக்கி, அவன் தகப்பனிடத்தில் அவனை ஒப்புக்கொடுத்தார்.
	<br /><br />
	43. அப்பொழுது எல்லாரும் தேவனுடைய மகத்துவத்தைக் குறித்துப் பிரமித்தார்கள். இயேசு செய்த யாவையுங்குறித்து அனைவரும் ஆச்சரியப்படுகையில் அவர் தம்முடைய சீஷர்களை நோக்கி:
	<br /><br />
	44. நீங்கள் இந்த வார்த்தைகளைக் கவனமாய்க் கேளுங்கள்; மனுஷகுமாரன் மனுஷர் கைகளில் ஒப்புக்கொடுக்கப்படப்போகிறார் என்றார்.
	<br /><br />
	45. அவர்கள் அந்த வார்த்தையின் கருத்தை அறிந்துகொள்ளவில்லை; அது அவர்களுக்குத் தோன்றாமல் மறைபொருளாயிருந்தது; அந்த வார்த்தையைக்குறித்து அவரிடத்தில் விசாரிக்கவும் பயந்தார்கள்.
	<br /><br />
	46. பின்பு தங்களில் எவன் பெரியவனாயிருப்பானென்கிற வாக்குவாதம் அவர்களுக்குள் உண்டாயிற்று.
	<br /><br />
	47. இயேசு அவர்கள் இருதயத்தின் யோசனையை அறிந்து, ஒரு சிறுபிள்ளையை எடுத்து, அதைத் தமதருகே நிறுத்தி,
	<br /><br />
	48. அவர்களை நோக்கி: இந்தச் சிறுபிள்ளையை என் நாமத்தினிமித்தம் ஏற்றுக்கொள்ளுகிறவன் என்னை ஏற்றுக்கொள்ளுகிறான்; என்னை ஏற்றுக்கொள்ளுகிறவன் என்னை அனுப்பினவரை ஏற்றுக்கொள்ளுகிறான்; உங்களெல்லாருக்குள்ளும் எவன் சிறியவனாயிருக்கிறானோ அவனே பெரியவனாயிருப்பான் என்றார்.
	<br /><br />
	49. அப்பொழுது யோவான் அவரை நோக்கி: ஐயரே, ஒருவன் உம்முடைய நாமத்தினாலே பிசாசுகளைத் துரத்துகிறதை நாங்கள் கண்டு, அவன் எங்களுடனேகூட உம்மைப் பின்பற்றாதவனானபடியால், அவனைத் தடுத்தோம் என்றான்.
	<br /><br />
	50. அதற்கு இயேசு: தடுக்கவேண்டாம்; நமக்கு விரோதியாயிராதவன் நமது பட்சத்திலிருக்கிறான் என்றார்.
	<br /><br />
	51. பின்பு, அவர் எடுத்துக்கொள்ளப்படும் நாட்கள் சமீபித்தபோது, அவர் எருசலேமுக்குப் போகத் தமது முகத்தைத்திருப்பி,
	<br /><br />
	52. தமக்கு முன்னாகத் தூதர்களை அனுப்பினார். அவர்கள் போய், அவருக்கு இடத்தை ஆயத்தம்பண்ணும்படி சமாரியருடைய ஒரு கிராமத்திலே பிரவேசித்தார்கள்.
	<br /><br />
	53. அவர் எருசலேமுக்குப் போக நோக்கமாயிருந்தபடியினால் அவ்வூரார் அவரை ஏற்றுக்கொள்ளவில்லை.
	<br /><br />
	54. அவருடைய சீஷராகிய யாக்கோபும் யோவானும் அதைக் கண்டபோது: ஆண்டவரே, எலியா செய்ததுபோல, வானத்திலிருந்து அக்கினி இறங்கி இவர்களை அழிக்கும்படி நாங்கள் கட்டளையிட உமக்குச் சித்தமா என்று கேட்டார்கள்.
	<br /><br />
	55. அவர் திரும்பிப்பார்த்து: நீங்கள் இன்ன ஆவியுள்ளவர்களென்பதை அறியீர்கள் என்று அதட்டி,
	<br /><br />
	56. மனுஷகுமாரன் மனுஷருடைய ஜீவனை அழிக்கிறதற்கு அல்ல, இரட்சிக்கிறதற்கே வந்தார் என்றார். அதன்பின்பு அவர்கள் வேறொரு கிராமத்துக்குப் போனார்கள்.
	<br /><br />
	57. அவர்கள் வழியிலே போகையில் ஒருவன் அவரை நோக்கி: ஆண்டவரே, நீர் எங்கே போனாலும் உம்மைப் பின்பற்றி வருவேன் என்றான்.
	<br /><br />
	58. அதற்கு இயேசு: நரிகளுக்குக் குழிகளும் ஆகாயத்துப் பறவைகளுக்குக் கூடுகளும் உண்டு, மனுஷகுமாரனுக்கோ தலைசாய்க்க இடமில்லை என்றார்.
	<br /><br />
	59. வேறொருவனை அவர் நோக்கி: என்னைப் பின்பற்றிவா என்றார். அதற்கு அவன்: ஆண்டவரே, முன்பு நான் போய் என் தகப்பனை அடக்கம்பண்ண எனக்கு உத்தரவு கொடுக்கவேண்டும் என்றான்.
	<br /><br />
	60. அதற்கு இயேசு: மரித்தோர் தங்கள் மரித்தோரை அடக்கம்பண்ணட்டும்; நீ போய், தேவனுடைய ராஜ்யத்தைக்குறித்துப் பிரசங்கி என்றார்.
	<br /><br />
	61. பின்பு வேறொருவன் அவரை நோக்கி: ஆண்டவரே, உம்மைப் பின்பற்றுவேன், ஆனாலும் முன்பு நான் போய் வீட்டிலிருக்கிறவர்களிடத்தில் அனுப்புவித்துக்கொண்டுவரும்படி எனக்கு உத்தரவு கொடுக்கவேண்டும் என்றான்.
	<br /><br />
	62. அதற்கு இயேசு: கலப்பையின்மேல் தன் கையை வைத்துப் பின்னிட்டுப்பார்க்கிற எவனும் தேவனுடைய ராஜ்யத்துக்குத் தகுதியுள்ளவன் அல்ல என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	Jesus Sends Out the Twelve
</h2>
<p>
	<br />
	1 When Jesus had called the Twelve together, he gave them power and authority to drive out all demons and to cure diseases,
	<br />
	2 and he sent them out to proclaim the kingdom of God and to heal the sick.
	<br />
	3 He told them: &ldquo;Take nothing for the journey&mdash;no staff, no bag, no bread, no money, no extra shirt.
	<br />
	4 Whatever house you enter, stay there until you leave that town.
	<br />
	5 If people do not welcome you, leave their town and shake the dust off your feet as a testimony against them.&rdquo;
	<br />
	6 So they set out and went from village to village, proclaiming the good news and healing people everywhere.
	<br />
	7 Now Herod the tetrarch heard about all that was going on. And he was perplexed because some were saying that John had been raised from the dead,
	<br />
	8 others that Elijah had appeared, and still others that one of the prophets of long ago had come back to life.
	<br />
	9 But Herod said, &ldquo;I beheaded John. Who, then, is this I hear such things about?&rdquo; And he tried to see him.
</p>
<h2>
	<br />
	Jesus Feeds the Five Thousand
</h2>
<p>
	<br />
	10 When the apostles returned, they reported to Jesus what they had done. Then he took them with him and they withdrew by themselves to a town called Bethsaida,
	<br />
	11 but the crowds learned about it and followed him. He welcomed them and spoke to them about the kingdom of God, and healed those who needed healing.
	<br />
	12 Late in the afternoon the Twelve came to him and said, &ldquo;Send the crowd away so they can go to the surrounding villages and countryside and find food and lodging, because we are in a remote place here.&rdquo;
	<br />
	13 He replied, &ldquo;You give them something to eat.&rdquo;They answered, &ldquo;We have only five loaves of bread and two fish&mdash;unless we go and buy food for all this crowd.&rdquo;
	<br />
	14 (About five thousand men were there.) But he said to his disciples, &ldquo;Have them sit down in groups of about fifty each.&rdquo;
	<br />
	15 The disciples did so, and everyone sat down.
	<br />
	16 Taking the five loaves and the two fish and looking up to heaven, he gave thanks and broke them. Then he gave them to the disciples to distribute to the people.
	<br />
	17 They all ate and were satisfied, and the disciples picked up twelve basketfuls of broken pieces that were left over.
</p>
<h2>
	<br />
	Peter Declares That Jesus Is the Messiah
</h2>
<p>
	<br />
	18 Once when Jesus was praying in private and his disciples were with him, he asked them, &ldquo;Who do the crowds say I am?&rdquo;
	<br />
	19 They replied, &ldquo;Some say John the Baptist; others say Elijah; and still others, that one of the prophets of long ago has come back to life.&rdquo;
	<br />
	20 &ldquo;But what about you?&rdquo; he asked. &ldquo;Who do you say I am?&rdquo;Peter answered, &ldquo;God&rsquo;s Messiah.&rdquo;
</p>
<h2>
	<br />
	Jesus Predicts His Death
</h2>
<p>
	<br />
	21 Jesus strictly warned them not to tell this to anyone.
	<br />
	22 And he said, &ldquo;The Son of Man must suffer many things and be rejected by the elders, the chief priests and the teachers of the law, and he must be killed and on the third day be raised to life.&rdquo;
	<br />
	23 Then he said to them all: &ldquo;Whoever wants to be my disciple must deny themselves and take up their cross daily and follow me.
	<br />
	24 For whoever wants to save their life will lose it, but whoever loses their life for me will save it.
	<br />
	25 What good is it for someone to gain the whole world, and yet lose or forfeit their very self?
	<br />
	26 Whoever is ashamed of me and my words, the Son of Man will be ashamed of them when he comes in his glory and in the glory of the Father and of the holy angels.
	<br />
	27 &ldquo;Truly I tell you, some who are standing here will not taste death before they see the kingdom of God.&rdquo;
</p>
<h2>
	<br />
	The Transfiguration
</h2>
<p>
	<br />
	28 About eight days after Jesus said this, he took Peter, John and James with him and went up onto a mountain to pray.
	<br />
	29 As he was praying, the appearance of his face changed, and his clothes became as bright as a flash of lightning.
	<br />
	30 Two men, Moses and Elijah, appeared in glorious splendor, talking with Jesus.
	<br />
	31 They spoke about his departure, which he was about to bring to fulfillment at Jerusalem.
	<br />
	32 Peter and his companions were very sleepy, but when they became fully awake, they saw his glory and the two men standing with him.
	<br />
	33 As the men were leaving Jesus, Peter said to him, &ldquo;Master, it is good for us to be here. Let us put up three shelters&mdash;one for you, one for Moses and one for Elijah.&rdquo; (He did not know what he was saying.)
	<br />
	34 While he was speaking, a cloud appeared and covered them, and they were afraid as they entered the cloud.
	<br />
	35 A voice came from the cloud, saying, &ldquo;This is my Son, whom I have chosen; listen to him.&rdquo;
	<br />
	36 When the voice had spoken, they found that Jesus was alone. The disciples kept this to themselves and did not tell anyone at that time what they had seen.
</p>
<h2>
	<br />
	Jesus Heals a Demon-Possessed Boy
</h2>
<p>
	<br />
	37 The next day, when they came down from the mountain, a large crowd met him.
	<br />
	38 A man in the crowd called out, &ldquo;Teacher, I beg you to look at my son, for he is my only child.
	<br />
	39 A spirit seizes him and he suddenly screams; it throws him into convulsions so that he foams at the mouth. It scarcely ever leaves him and is destroying him.
	<br />
	40 I begged your disciples to drive it out, but they could not.&rdquo;
	<br />
	41 &ldquo;You unbelieving and perverse generation,&rdquo; Jesus replied, &ldquo;how long shall I stay with you and put up with you? Bring your son here.&rdquo;
	<br />
	42 Even while the boy was coming, the demon threw him to the ground in a convulsion. But Jesus rebuked the impure spirit, healed the boy and gave him back to his father.
	<br />
	And they were all amazed at the greatness of God.
</p>
<h2>
	<br />
	Jesus Predicts His Death a Second Time
</h2>
<p>
	<br />
	43 While everyone was marveling at all that Jesus did, he said to his disciples,
	<br />
	44 &ldquo;Listen carefully to what I am about to tell you: The Son of Man is going to be delivered into the hands of men.&rdquo;
	<br />
	45 But they did not understand what this meant. It was hidden from them, so that they did not grasp it, and they were afraid to ask him about it.
	<br />
	46 An argument started among the disciples as to which of them would be the greatest.
	<br />
	47 Jesus, knowing their thoughts, took a little child and had him stand beside him.
	<br />
	48 Then he said to them, &ldquo;Whoever welcomes this little child in my name welcomes me; and whoever welcomes me welcomes the one who sent me. For it is the one who is least among you all who is the greatest.&rdquo;
	<br />
	49 &ldquo;Master,&rdquo; said John, &ldquo;we saw someone driving out demons in your name and we tried to stop him, because he is not one of us.&rdquo;
	<br />
	50 &ldquo;Do not stop him,&rdquo; Jesus said, &ldquo;for whoever is not against you is for you.&rdquo;
</p>
<h2>
	<br />
	Samaritan Opposition
</h2>
<p>
	<br />
	51 As the time approached for him to be taken up to heaven, Jesus resolutely set out for Jerusalem.
	<br />
	52 And he sent messengers on ahead, who went into a Samaritan village to get things ready for him;
	<br />
	53 but the people there did not welcome him, because he was heading for Jerusalem.
	<br />
	54 When the disciples James and John saw this, they asked, &ldquo;Lord, do you want us to call fire down from heaven to destroy them ?&rdquo;
	<br />
	55 But Jesus turned and rebuked them.
	<br />
	56 Then he and his disciples went to another village.
</p>
<h2>
	<br />
	The Cost of Following Jesus
</h2>
<p>
	<br />
	57 As they were walking along the road, a man said to him, &ldquo;I will follow you wherever you go.&rdquo;
	<br />
	58 Jesus replied, &ldquo;Foxes have dens and birds have nests, but the Son of Man has no place to lay his head.&rdquo;
	<br />
	59 He said to another man, &ldquo;Follow me.&rdquo;But he replied, &ldquo;Lord, first let me go and bury my father.&rdquo;
	<br />
	60 Jesus said to him, &ldquo;Let the dead bury their own dead, but you go and proclaim the kingdom of God.&rdquo;
	<br />
	61 Still another said, &ldquo;I will follow you, Lord; but first let me go back and say goodbye to my family.&rdquo;
	<br />
	62 Jesus replied, &ldquo;No one who puts a hand to the plow and looks back is fit for service in the kingdom of God.&rdquo;
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke9