import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ephesians6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Book of Ephesians Bible Tamil | எபேசியர் 6 </title>
	<meta
          name="description"
          content="Ephesians 6 | எபேசியர் 6 |
          Book of Ephesians | Tamil Bible Verse | Tamil Bible | எபேசியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. பிள்ளைகளே, உங்கள் பெற்றாருக்குக் கர்த்தருக்குள் கீழ்ப்படியுங்கள், இது நியாயம்.
	<br /><br />
	2. உனக்கு நன்மை உண்டாயிருப்பதற்கும், பூமியிலே உன் வாழ்நாள் நீடித்திருப்பதற்கும்,
	<br /><br />
	3. உன் தகப்பனையும் உன் தாயையும் கனம்பண்ணுவாயாக என்பதே வாக்குத்தத்தமுள்ள முதலாங் கற்பனையாயிருக்கிறது.
	<br /><br />
	4. பிதாக்களே, நீங்களும் உங்கள் பிள்ளைகளைக் கோபப்படுத்தாமல், கர்த்தருக்கேற்ற சிட்சையிலும் போதனையிலும் அவர்களை வளர்ப்பீர்களாக.
	<br /><br />
	5. வேலைக்காரரே, நீங்கள் கிறிஸ்துவுக்குக் கீழ்ப்படிகிறதுபோல, சரீரத்தின்படி உங்கள் எஜமான்களாயிருக்கிறவர்களுக்கும் பயத்தோடும் நடுக்கத்தோடும் கபடற்ற மனதோடும் கீழ்ப்படிந்து;
	<br /><br />
	6. மனுஷருக்குப் பிரியமாயிருக்க விரும்புகிறவர்களாகப் பார்வைக்கு ஊழியஞ்செய்யாமல், கிறிஸ்துவின் ஊழியக்காரராக, மனப்பூர்வமாய்த் தேவனுடைய சித்தத்தின்படி செய்யுங்கள்.
	<br /><br />
	7. அடிமையானவனானாலும், சுயாதீனமுள்ளவனானாலும், அவனவன் செய்கிற நன்மையின்படியே கர்த்தரிடத்தில் பலனை அடைவானென்று அறிந்து,
	<br /><br />
	8. மனுஷருக்கென்று ஊழியஞ்செய்யாமல், கர்த்தருக்கென்றே நல்மனதோடே ஊழியஞ்செய்யுங்கள்.
	<br /><br />
	9. எஜமான்களே, அப்படியே நீங்களும், வேலைக்காரருக்குச் செய்யவேண்டியவைகளைச் செய்து, அவர்களுக்கும் உங்களுக்கும் எஜமானானவர் பரலோகத்தில் இருக்கிறாரென்றும், அவரிடத்தில் பட்சபாதம் இல்லையென்றும் அறிந்து, கடுஞ்சொல்லை விட்டுவிடுங்கள்.
	<br /><br />
	10. கடைசியாக, என் சகோதரரே, கர்த்தரிலும் அவருடைய சத்துவத்தின் வல்லமையிலும் பலப்படுங்கள்.
	<br /><br />
	11. நீங்கள் பிசாசின் தந்திரங்களோடு எதிர்த்துநிற்கத் திராணியுள்ளவர்களாகும்படி, தேவனுடைய சர்வாயுதவர்க்கத்தையும் தரித்துக்கொள்ளுங்கள்.
	<br /><br />
	12. ஏனெனில், மாம்சத்தோடும் இரத்தத்தோடுமல்ல, துரைத்தனங்களோடும், அதிகாரங்களோடும், இப்பிரபஞ்சத்தின் அந்தகார லோகாதிபதிகளோடும், வானமண்டலங்களிலுள்ள பொல்லாத ஆவிகளின் சேனைகளோடும் நமக்குப் போராட்டம் உண்டு.
	<br /><br />
	13. ஆகையால், தீங்குநாளிலே அவைகளை நீங்கள் எதிர்க்கவும், சகலத்தையும் செய்துமுடித்தவர்களாய் நிற்கவும் திராணியுள்ளவர்களாகும்படிக்கு, தேவனுடைய சர்வாயுதவர்க்கத்தையும் எடுத்துக்கொள்ளுங்கள்.
	<br /><br />
	14. சத்தியம் என்னும் கச்சையை உங்கள் அரையில் கட்டினவர்களாயும், நீதியென்னும் மார்க்கவசத்தைத் தரித்தவர்களாயும்;
	<br /><br />
	15. சமாதானத்தின் சுவிசேஷத்திற்குரிய ஆயத்தம் என்னும் பாதரட்சையைக் கால்களிலே தொடுத்தவர்களாயும்;
	<br /><br />
	16. பொல்லாங்கன் எய்யும் அக்கினியாஸ்திரங்களையெல்லாம் அவித்துப்போடத்தக்கதாய், எல்லாவற்றிற்கும் மேலாக விசுவாசமென்னும் கேடகத்தைப் பிடித்துக்கொண்டவர்களாயும் நில்லுங்கள்.
	<br /><br />
	17. இரட்சணியமென்னும் தலைச்சீராவையும், தேவவசனமாகிய ஆவியின் பட்டயத்தையும் எடுத்துக்கொள்ளுங்கள்.
	<br /><br />
	18. எந்தச் சமயத்திலும் சகலவிதமான வேண்டுதலோடும் விண்ணப்பத்தோடும் ஆவியினாலே ஜெபம்பண்ணி, அதன்பொருட்டு மிகுந்த மனஉறுதியோடும் சகல பரிசுத்தவான்களுக்காகவும் பண்ணும் வேண்டுதலோடும் விழித்துக்கொண்டிருங்கள்.
	<br /><br />
	19. சுவிசேஷத்திற்காகச் சங்கிலியால் கட்டப்பட்டிருக்கிற ஸ்தானாபதியாகிய நான் அதைப்பற்றிப் பேசவேண்டியபடி தைரியமாய்ப் பேசத்தக்கதாக,
	<br /><br />
	20. நான் தைரியமாய் என் வாயைத் திறந்து சுவிசேஷத்தின் இரகசியத்தை அறிவிக்கிறதற்கு வாக்கு எனக்குக் கொடுக்கப்படும்படி எனக்காகவும் விண்ணப்பம்பண்ணுங்கள்.
	<br /><br />
	21. அன்றியும், எனக்கடுத்த காரியங்களும், என் சுகசெய்திகளும் உங்களுக்குத் தெரியவரும்படிக்கு, அவைகளையெல்லாம் நமக்குப் பிரியமான சகோதரனும் கர்த்தருக்குள் உண்மையுள்ள ஊழியக்காரனுமாயிருக்கிற தீகிக்கு உங்களுக்கு அறிவிப்பான்.
	<br /><br />
	22. நீங்கள் எங்கள் செய்திகளை அறியவும், அவன் உங்கள் இருதயங்களுக்கு ஆறுதல் செய்யவும், அவனை உங்களிடத்தில் அனுப்பினேன்.
	<br /><br />
	23. பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும், சகோதரருக்குச் சமாதானமும் விசுவாசத்தோடு கூடிய அன்பும் உண்டாவதாக.
	<br /><br />
	24. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினிடத்தில் அழியாத அன்புடனே அன்புகூருகிற யாவரோடும் கிருபை உண்டாயிருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ephesians6