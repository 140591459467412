import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> The Superiority of Christ Hebrews-1 in Tamil Bible | எபிரெயர் 1 </title>
	<meta
          name="description"
          content="The Superiority of Christ Hebrews Chapter-1 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கிறிஸ்துவின் மேன்மை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பூர்வகாலங்களில் பங்குபங்காகவும் வகைவகையாகவும், தீர்க்கதரிசிகள் மூலமாய்ப் பிதாக்களுக்குத் திருவுளம் பற்றின தேவன்,
	<br /><br />
	2. இந்தக் கடைசி நாட்களில் குமாரன் மூலமாய் நமக்குத் திருவுளம்பற்றினார்; இவரைச் சர்வத்துக்கும் சுதந்தரவாளியாக நியமித்தார், இவரைக்கொண்டு உலகங்களையும் உண்டாக்கினார்.
	<br /><br />
	3. இவர் அவருடைய மகிமையின் பிரகாசமும், அவருடைய தன்மையின் சொரூபமுமாயிருந்து, சர்வத்தையும் தம்முடைய வல்லமையுள்ள வசனத்தினாலே தாங்குகிறவராய், தம்மாலேதாமே நம்முடைய பாவங்களை நீக்கும் சுத்திகரிப்பை உண்டுபண்ணி, உன்னதத்திலுள்ள மகத்துவமானவருடைய வலதுபாரிசத்திலே உட்கார்ந்தார்.
	<br /><br />
	4. இவர் தேவதூதரைப்பார்க்கிலும் எவ்வளவு விசேஷித்த நாமத்தைச் சுதந்தரித்துக்கொண்டாரோ, அவ்வளவு அதிகமாய் அவர்களிலும் மேன்மையுள்ளவரானார்.
	<br /><br />
	5. எப்படியெனில், நீர் என்னுடைய குமாரன், இன்று நான் உம்மை ஜெநிப்பித்தேன் என்றும்; நான் அவருக்குப் பிதாவாயிருப்பேன், அவர் எனக்குக் குமாரனாயிருப்பார் என்றும், அவர் தூதர்களில் யாருக்காவது எப்போதாகிலும் சொன்னதுண்டா?
	<br /><br />
	6. மேலும், தமது முதற்பேறானவரை உலகத்தில் பிரவேசிக்கச்செய்தபோது: தேவதூதர் யாவரும் அவரைத் தொழுதுகொள்ளக்கடவர்கள் என்றார்.
	<br /><br />
	7. தேவதூதரைக்குறித்தோ: தம்முடைய தூதர்களைக் காற்றுகளாகவும், தம்முடைய ஊழியக்காரரை அக்கினிஜுவாலைகளாகவும் செய்கிறார் என்று சொல்லியிருக்கிறது.
	<br /><br />
	8. குமாரனை நோக்கி: தேவனே, உம்முடைய சிங்காசனம் என்றென்றைக்குமுள்ளது, உம்முடைய ராஜ்யத்தின் செங்கோல் நீதியுள்ள செங்கோலாயிருக்கிறது.
	<br /><br />
	9. நீர் நீதியை விரும்பி, அக்கிரமத்தை வெறுத்திருக்கிறீர்; ஆதலால், தேவனே, உம்முடைய தேவன் உமது தோழரைப்பார்க்கிலும் உம்மை ஆனந்த தைலத்தினால் அபிஷேகம்பண்ணினார் என்றும்;
	<br /><br />
	10. கர்த்தாவே, நீர் ஆதியிலே பூமியை அஸ்திபாரப்படுத்தினீர்; வானங்கள் உம்முடைய கரத்தின் கிரியைகளாயிருக்கிறது;
	<br /><br />
	11. அவைகள் அழிந்துபோம்; நீரோ நிலைத்திருப்பீர்; அவைகளெல்லாம் வஸ்திரம்போலப் பழைமையாய்ப்போம்;
	<br /><br />
	12. ஒரு சால்வையைப்போல அவைகளைச் சுருட்டுவீர், அப்பொழுது மாறிப்போம்; நீரோ மாறாதவராயிருக்கிறீர், உம்முடைய ஆண்டுகள் முடிந்துபோவதில்லை என்றும் சொல்லியிருக்கிறது.
	<br /><br />
	13. மேலும், நான் உம்முடைய சத்துருக்களை உமக்குப் பாதபடியாக்கிப் போடும்வரைக்கும் நீர் என்னுடைய வலதுபாரிசத்தில் உட்காரும் என்று தூதர்களில் யாருக்காவது எப்போதாகிலும் அவர் சொன்னதுண்டா?
	<br /><br />
	14. இரட்சிப்பைச் சுதந்தரிக்கப்போகிறவர்களினிமித்தமாக ஊழியஞ்செய்யும்படிக்கு அவர்களெல்லாரும் அனுப்பப்படும் பணிவிடை ஆவிகளாயிருக்கிறார்களல்லவா?
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* கிறிஸ்து இயேசுவின் மேலாதிக்கத்தைப் பற்றியது. சிலுவையில் அவர் செய்த மீட்புப் பணியின் காரணமாக அவர் தேவதூதர்களை விட எப்படி உயர்ந்தவர் என்பதை இது ஒரு அற்புதமான சித்திரத்தை வரைகிறது. இயேசு தந்தையின் சரியான பிரதிநிதி மற்றும் கடவுளின் உண்மையான இயல்பை நமக்குத் தெரிவிக்க அனுப்பப்பட்டார். இயேசு யார், அவர் என்ன சொன்னார் என்பதைப் புரிந்துகொள்வதன் மூலம், கடவுளின் இயல்பு மற்றும் குணத்தின் முழுமையை அறிந்து கொள்ளலாம். *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews1