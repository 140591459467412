import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial41 = () => {
  const verseNumber = 41;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 41 </title>
	<meta
          name="description"
          content="Ezekiel 41 | எசேக்கியேல்  41 |
          Ezekiel 41 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் என்னைத் தேவாலயத்திற்கு அழைத்துக்கொண்டுபோய், தூணாதாரங்களை இந்தப்புறத்தில் ஆறுமுழ அகலமும் அந்தப்புறத்தில் ஆறுமுழ அகலமுமாக அளந்தார்; அது வாசஸ்தலத்தின் அகல அளவாம்.
	<br /><br />
	2. வாசல் நடையின் அகலம் பத்து முழமும் வாசல் நடையின் பக்கங்கள் இந்தப்புறத்தில் ஐந்து முழமும் அந்தப்புறத்தில் ஐந்து முழமுமாயிருந்தது; அதின் நீளத்தை நாற்பது முழமும் அகலத்தை இருபது முழமுமாக அளந்தார்.
	<br /><br />
	3. பின்பு அவர் உள்ளே போய், வாசல் நடையின் தூணாதாரங்களை இரண்டு முழமாகவும், வாசல் நடையை ஆறுமுழமாகவும், வாசல் நடையின் அகலத்தை ஏழுமுழமாகவும் அளந்தார்.
	<br /><br />
	4. பின்பு அவர் தேவாலயத்தின் முன்புறத்திலே அதின் நீளத்தை இருபதுமுழமாகவும், அதின் அகலத்தை இருபது முழமாகவும் அளந்து, என்னை நோக்கி: இது மகா பரிசுத்த ஸ்தலம் என்றார்.
	<br /><br />
	5. பின்பு அவர் ஆலயத்தின் சுவரை ஆறு முழமாகவும், ஆலயத்தைச் சுற்றிலும் இருந்த சுற்றுக்கட்டினுடைய அகலத்தை நாலுமுழமாகவும் அளந்தார்.
	<br /><br />
	6. இந்தச் சுற்றுக்கட்டுகள் பக்கக்கட்டின்மேல் பக்கக்கட்டான வரிசைகளாய் முப்பத்துமூன்று இருந்தது; அவைகள் ஆலயத்தின் சுவருக்குள் ஊன்றியிராமல், சுற்றுக்கட்டுகளுக்காகச் சுற்றிலும் அவைகள் ஊன்றும்படிக்கு ஆலயத்துக்கு அடுத்திருந்த ஒட்டுச்சுவரிலே பாய்ந்திருந்தது.
	<br /><br />
	7. உயர உயரச் சுற்றிலும் சுற்றுக்கட்டுகளுக்கு அகலம் அதிகமாயிருந்தது; ஆலயத்தைச் சுற்றிலும் உயர உயர ஆலயத்தைச் சுற்றிச் சுற்றி அகலம் வரவர அதிகமாயிருந்தது; ஆதலால் இவ்விதமாய்க் கீழ்நிலையிலிருந்து நடுநிலைவழியாய் மேல்நிலைக்கு ஏறும் வழியிருந்தது.
	<br /><br />
	8. மாளிகைக்குச் சுற்றிலும் இருந்த உயரத்தையும் பார்த்தேன், சுற்றுக்கட்டுகளின் அஸ்திபாரங்கள் ஆறு பெரிய முழங்கொண்ட ஒரு முழக்கோலின் உயரமாயிருந்தது.
	<br /><br />
	9. புறம்பே சுற்றுக்கட்டுக்கு இருந்த சுவரின் அகலம் ஐந்துமுழமாயிருந்தது; ஆலயத்துக்கு இருக்கும் சுற்றுக்கட்டுகளின் மாளிகையிலே வெறுமையாய் விட்டிருந்த இடங்களும் அப்படியே இருந்தது.
	<br /><br />
	10. ஆலயத்தைச் சுற்றிலும் அறைவீடுகளுக்கு நடுவாக இருந்த விசாலம் இருபது முழமாயிருந்தது.
	<br /><br />
	11. சுற்றுக்கட்டினுடைய வாசல் நடைகள், வெறுமையாய் விட்டிருந்த இடங்களிலிருந்து, ஒரு வாசல் நடை வடக்கேயும், ஒரு வாசல் நடை தெற்கேயும் இருந்தது; வெறுமையாய் விட்டிருந்த இடங்களின் விசாலம் சுற்றிலும் ஐந்து முழமாயிருந்தது.
	<br /><br />
	12. மேற்றிசையிலே பிரத்தியேகமான இடத்துக்கு முன்னிருந்த மாளிகைமட்டும் அகலம் எழுபது முழமும், மாளிகையினுடைய சுவரின் அகலம் சுற்றிலும் ஐந்து முழமும், அதினுடைய நீளம் தொண்ணூறு முழமுமாயிருந்தது.
	<br /><br />
	13. அவர் ஆலயத்தை நூறு முழ நீளமாகவும், பிரத்தியேகமான இடத்தையும் மாளிகையையும் அதின் சுவர்களையும் நூறு முழ நீளமாகவும் அளந்தார்.
	<br /><br />
	14. ஆலயத்தின் முற்புறமும் கிழக்குக்கு எதிரான பிரத்தியேகமான இடமும் இருந்த அகலம் நூறு முழமாயிருந்தது.
	<br /><br />
	15. பிரத்தியேகமான இடத்தின் பின்புறமாக அதற்கு எதிரே இருந்த மாளிகையின் நீளத்தையும், அதற்கு இந்தப்புறத்திலும் அந்தப்புறத்திலும் இருந்த நடைகாவணங்களையும் அளந்தார்; உள்ளான தேவாலயமும் முற்றத்தின் மண்டபங்களும் உட்பட நூறு முழமாயிருந்தது.
	<br /><br />
	16. வாசற்படிகளும், ஒடுக்கமான ஜன்னல்களும், மூன்று பக்கங்களில் சுற்றிலும் வாசல்களுக்கு எதிரான நடைகாவணங்களும் சுற்றிலும் தரைதுவக்கி ஜன்னல்கள் மட்டாகப் பலகை அடித்திருந்தது; ஜன்னல்கள் மூடப்பட்டிருந்தது.
	<br /><br />
	17. வாசலின் மேலேதுவக்கி ஆலயத்தின் உட்புறமும் வெளிப்புறமும் சுற்றிலும் சுவரின் உட்புறமும் வெளிப்புறமும் எல்லாம் அளவிட்டிருந்தது.
	<br /><br />
	18. கேருபீன்களும் பேரீச்சமரங்களும் சித்திரிக்கப்பட்டிருந்தது; கேருபீனுக்கும் கேருபீனுக்கும் நடுவாக ஒவ்வொரு பேரீச்சமரம் இருந்தது; ஒவ்வொரு கேருபீனுக்கும் இரண்டிரண்டு முகங்கள் இருந்தது.
	<br /><br />
	19. பேரீச்சமரத்துக்கு இந்தண்டையில் மனுஷமுகமும், பேரீச்சமரத்துக்கு அந்தண்டையில் சிங்கமுகமும் இருந்தது; இப்படியே ஆலயத்தைச் சுற்றிலும் செய்திருந்தது.
	<br /><br />
	20. தரைதுவக்கி வாசலின் மேற்புறமட்டும், தேவாலயத்தின் சுவரிலும், கேருபீன்களும் பேரீச்சமரங்களும் சித்திரிக்கப்பட்டிருந்தது.
	<br /><br />
	21. தேவாலயத்தின் கதவு நிலைகள் சதுரமும், பரிசுத்த ஸ்தலத்தினுடைய முகப்பின் உருவம் அந்த உருவத்துக்குச் சரியுமாயிருந்தது.
	<br /><br />
	22. மரத்தினால் செய்யப்பட்ட பலிபீடத்தின் உயரம் மூன்று முழமும், அதின் நீளம் இரண்டு முழமுமாயிருந்தது; அதின் கோடிகளும் அதின் விளிம்புகளும் அதின் பக்கங்களும் மரத்தினால் செய்யப்பட்டிருந்தது. அவர் என்னை நோக்கி: இது கர்த்தருடைய சந்நிதியிலிருக்கிற பீடம் என்றார்.
	<br /><br />
	23. தேவாலயத்துக்கும் பரிசுத்த ஸ்தலத்துக்கும் இரண்டு வாசல்களும்,
	<br /><br />
	24. வாசல்களுக்கு மடக்குக் கதவுகளாகிய இரட்டைக் கதவுகளும் இருந்தது; ஒரு வாசலுக்கு இரண்டு கதவுகளும் மற்ற வாசலுக்கு இரண்டு கதவுகளும் இருந்தது.
	<br /><br />
	25. சுவர்களில் சித்திரிக்கப்பட்டிருந்ததுபோல் ஆலயத்தினுடைய கதவுகளிலும் கேருபீன்களும் பேரீச்சமரங்களும் சித்திரிக்கப்பட்டிருந்தது; புறம்பே மண்டபத்தின் முன்பாக உத்திரங்கள் வைத்திருந்தது.
	<br /><br />
	26. மண்டபத்தின் பக்கங்களில் இந்தப்புறத்திலும் அந்தப்புறத்திலும், ஆலயத்தின் சுற்றுக்கட்டுகளிலும் ஒடுக்கமான ஜன்னல்களும் சித்திரிக்கப்பட்ட பேரீச்சமரங்களும் உத்திரங்களும் இருந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial41