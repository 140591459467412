import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 9 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 9 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 9 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் அப்போஸ்தலனல்லவா? நான் சுயாதீனனல்லவா? நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவை நான் தரிசிக்கவில்லையா? கர்த்தருக்குள் நீங்கள் என் கிரியையாயிருக்கிறீர்களல்லவா?
	<br /><br />
	2. நான் மற்றவர்களுக்கு அப்போஸ்தலனாயிராவிட்டாலும், உங்களுக்கல்லவோ அப்போஸ்தலனாயிருக்கிறேன்; கர்த்தருக்குள் நீங்கள் என் அப்போஸ்தல ஊழியத்திற்கு முத்திரையாயிருக்கிறீர்களே.
	<br /><br />
	3. என்னை நியாயம் விசாரிக்கிறவர்களுக்கு நான் சொல்லுகிற மாறுத்தரமாவது:
	<br /><br />
	4. புசிக்கவும் குடிக்கவும் எங்களுக்கு அதிகாரமில்லையா?
	<br /><br />
	5. மற்ற அப்போஸ்தலரும், கர்த்தருடைய சகோதரரும், கேபாவும் செய்கிறதுபோல, மனைவியாகிய ஒரு சகோதரியைக் கூட்டிக்கொண்டு திரிய எங்களுக்கும் அதிகாரமில்லையா?
	<br /><br />
	6. அல்லது, கைத்தொழில் செய்யாதிருக்கிறதற்கு எனக்கும் பர்னபாவுக்கும்மாத்திரந்தானா அதிகாரமில்லை?
	<br /><br />
	7. எவன் தன் சொந்தப்பணத்தைச் செலவழித்து, தண்டிலே சேவகம்பண்ணுவான்? எவன் திராட்சத்தோட்டத்தை உண்டாக்கி, அதின் கனியில் புசியாதிருப்பான்? எவன் மந்தையை மேய்த்து, அதின் பாலைச் சாப்பிடாதிருப்பான்?
	<br /><br />
	8. இவைகளை மனுஷர் வழக்கத்தின்படி சொல்லுகிறேனோ? நியாயப்பிரமாணமும் இவைகளைச் சொல்லுகிறதில்லையா?
	<br /><br />
	9. போரடிக்கிற மாட்டை வாய்கட்டாயாக என்று மோசேயின் பிரமாணத்திலே எழுதியிருக்கிறதே. தேவன் மாடுகளுக்காகவே கவலையாயிருக்கிறாரோ?
	<br /><br />
	10. நமக்காகத்தான் இதைச் சொல்லுகிறாரோ? உழுகிறவன் நம்பிக்கையோடே உழவும், போரடிக்கிறவன் தான் நம்புகிறதில் பங்கடைவேன் என்கிற நம்பிக்கையோடே போரடிக்கவும் வேண்டியதே, ஆகையால், அது நமக்காகவே எழுதியிருக்கிறது.
	<br /><br />
	11. நாங்கள் உங்களுக்கு ஞானநன்மைகளை விதைத்திருக்க, உங்கள் சரீரநன்மைகளை அறுத்தால் அது பெரிய காரியமா?
	<br /><br />
	12. மற்றவர்கள் உங்களிடத்திலே இந்த அதிகாரத்தைச் செலுத்தினால், அவர்களிலும் நாங்கள் அதிகமாய்ச் செலுத்தலாமல்லவா? அப்படியிருந்தும், கிறிஸ்துவின் சுவிசேஷத்திற்கு யாதொரு தடையும் உண்டாகாதபடிக்கு, நாங்கள் இந்த அதிகாரத்தைச் செலுத்தாமல் எல்லாப் பாடும் படுகிறோம்.
	<br /><br />
	13. ஆசாரிய ஊழியஞ்செய்கிறவர்கள் தேவாலயத்திற்குரியவைகளில் புசிக்கிறார்களென்றும், பலிபீடத்தை அடுத்துப் பணிவிடை செய்கிறவர்களுக்குப் பலிபீடத்திலுள்ளவைகளில் பங்கு உண்டென்றும் அறியீர்களா?
	<br /><br />
	14. அந்தப்படியே சுவிசேஷத்தை அறிவிக்கிறவர்களுக்குச் சுவிசேஷத்தினாலே பிழைப்பு உண்டாகவேண்டுமென்று கர்த்தரும் கட்டளையிட்டிருக்கிறார்.
	<br /><br />
	15. அப்படியிருந்தும், நான் இவைகளில் ஒன்றையும் அநுபவிக்கவில்லை; இப்படி எனக்கு நடக்கவேண்டுமென்று இவைகளை நான் எழுதுகிறதுமில்லை. என் மேன்மைபாராட்டலை ஒருவன் அவத்தமாக்குகிறதைப்பார்க்கிலும் சாகிறது எனக்கு நலமாயிருக்கும்.
	<br /><br />
	16. சுவிசேஷத்தை நான் பிரசங்கித்துவந்தும், மேன்மைபாராட்ட எனக்கு இடமில்லை; அது என்மேல் விழுந்த கடமையாயிருக்கிறது; சுவிசேஷத்தை நான் பிரசங்கியாதிருந்தால், எனக்கு ஐயோ.
	<br /><br />
	17. நான் உற்சாகமாய் அப்படிச் செய்தால் எனக்குப் பலன் உண்டு; உற்சாகமில்லாதவனாய்ச் செய்தாலும், உக்கிராண உத்தியோகம் எனக்கு ஒப்புவிக்கப்பட்டிருக்கிறதே.
	<br /><br />
	18. ஆதலால் எனக்குப் பலன் என்ன? நான் சுவிசேஷத்தைப் பிரசங்கிக்கையில் அதைப்பற்றி எனக்கு உண்டாயிருக்கிற அதிகாரத்தை முற்றிலும் செலுத்தாமல், கிறிஸ்துவின் சுவிசேஷத்தைச் செலவில்லாமல் ஸ்தாபிப்பதே எனக்குப் பலன்.
	<br /><br />
	19. நான் ஒருவருக்கும் அடிமைப்படாதவனாயிருந்தும், நான் அதிக ஜனங்களை ஆதாயப்படுத்திக்கொள்ளும்படிக்கு, என்னைத்தானே எல்லாருக்கும் அடிமையாக்கினேன்.
	<br /><br />
	20. யூதரை ஆதாயப்படுத்திக்கொள்ளும்படிக்கு யூதருக்கு யூதனைப்போலவும், நியாயப்பிரமாணத்துக்குக் கீழ்ப்பட்டவர்களை ஆதாயப்படுத்திக்கொள்ளும்படிக்கு நியாயப்பிரமாணத்துக்குக் கீழ்ப்பட்டவனைப் போலவுமானேன்.
	<br /><br />
	21. நியாயப்பிரமாணமில்லாதவர்களை ஆதாயப்படுத்திக்கொள்ளும்படிக்கு அவர்களுக்கு நியாயப்பிரமாணம் இல்லாதவனைப் போலவுமானேன். அப்படியிருந்தும், நான் தேவனுக்குமுன்பாக நியாயப்பிரமாணமில்லாதவனாயிராமல், கிறிஸ்துவின் பிரமாணத்துக்குள்ளானவனாயிருக்கிறேன்.
	<br /><br />
	22. பலவீனரை ஆதாயப்படுத்திக்கொள்ளும்படிக்குப் பலவீனருக்குப் பலவீனனைப்போலானேன்; எப்படியாகிலும் சிலரை இரட்சிக்கும்படிக்கு நான் எல்லாருக்கும் எல்லாமானேன்.
	<br /><br />
	23. சுவிசேஷத்தில் நான் உடன்பங்காளியாகும்படிக்கு, அதினிமித்தமே இப்படிச் செய்கிறேன்.
	<br /><br />
	24. பந்தயச் சாலையில் ஓடுகிறவர்களெல்லாரும் ஓடுவார்கள்; ஆகிலும், ஒருவனே பந்தயத்தைப் பெறுவானென்று அறியீர்களா? நீங்கள் பெற்றுக்கொள்ளத்தக்கதாக ஓடுங்கள்.
	<br /><br />
	25. பந்தயத்திற்குப் போராடுகிற யாவரும் எல்லாவற்றிலேயும் இச்சையடக்கமாயிருப்பார்கள். அவர்கள் அழிவுள்ள கிரீடத்தைப் பெறும்படிக்கு அப்படிச் செய்கிறார்கள், நாமோ அழிவில்லாத கிரீடத்தைப் பெறும்படிக்கு அப்படிச் செய்கிறோம்.
	<br /><br />
	26. ஆதலால் நான் நிச்சயமில்லாதவனாக ஓடேன்; ஆகாயத்தை அடிக்கிறவனாகச் சிலம்பம்பண்ணேன்.
	<br /><br />
	27. மற்றவர்களுக்குப் பிரசங்கம்பண்ணுகிற நான்தானே ஆகாதவனாய்ப் போகாதபடிக்கு, என் சரீரத்தை ஒடுக்கிக் கீழ்ப்படுத்துகிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians9