import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Habakkuk3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆபகூக் 3 </title>
	<meta
          name="description"
          content="Habakkuk 3 | ஆபகூக் 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆபகூக் தீர்க்கதரிசி சிகாயோனில் பாடின விண்ணப்பம்.
	<br /><br />
	2. கர்த்தாவே, நீர் வெளிப்படுத்தினதை நான் கேட்டேன், எனக்குப் பயமுண்டாயிற்று; கர்த்தாவே, வருஷங்களின் நடுவிலே உம்முடைய கிரியையை உயிர்ப்பியும், வருஷங்களின் நடுவிலே அதை விளங்கப்பண்ணும்; கோபித்தாலும் இரக்கத்தை நினைத்தருளும்.
	<br /><br />
	3. தேவன் தேமானிலிருந்தும், பரிசுத்தர் பாரான் பர்வதத்திலிருந்தும் வந்தார்; (சேலா.) அவருடைய மகிமை வானங்களை மூடிக்கொண்டது; அவர் துதியினால் பூமி நிறைந்தது.
	<br /><br />
	4. அவருடைய பிரகாசம் சூரியனைப்போலிருந்தது; அவருடைய கரத்திலிருந்து கிரணங்கள் வீசின; அங்கே அவருடைய பராக்கிரமம் மறைந்திருந்தது.
	<br /><br />
	5. அவருக்கு முன்பாகக் கொள்ளைநோய் சென்றது; அவர் அடிகளிலிருந்து எரிபந்தமான காய்ச்சல் புறப்பட்டது.
	<br /><br />
	6. அவர் நின்று பூமியை அளந்தார்; அவர் பார்த்துப் புறஜாதிகளைக் கரையப்பண்ணினார்; பூர்வ பர்வதங்கள் சிதறடிக்கப்பட்டது, என்றுமுள்ள மலைகள் தாழ்ந்தது; அவருடைய நடைகள் நித்திய நடைகளாயிருந்தது.
	<br /><br />
	7. கூஷானின் கூடாரங்கள் வருத்தத்தில் அகப்பட்டிருக்கக்கண்டேன்; மீதியான் தேசத்தின் திரைகள் நடுங்கின.
	<br /><br />
	8. கர்த்தர் நதிகளின்மேல் கோபமாயிருந்தாரோ? தேவரீர் உம்முடைய குதிரைகளின்மேலும் இரட்சிப்புண்டாக்குகிற உம்முடைய இரதங்களின்மேலும் ஏறிவருகிறபோது, உமது கோபம் நதிகளுக்கும் உமது சினம் சமுத்திரத்திற்கும் விரோதமாயிருந்ததோ?
	<br /><br />
	9. கோத்திரங்களுக்கு ஆணையிட்டுக் கொடுத்த வாக்கின்படியே உம்முடைய வில் நாணேற்றப்பட்டதாக விளங்கினது; (சேலா.) நீர் பூமியைப் பிளந்து ஆறுகளை உண்டாக்கினீர்.
	<br /><br />
	10. பர்வதங்கள் உம்மைக் கண்டு நடுங்கின; ஜலம் பிரவாகித்துக் கடந்துபோயிற்று; ஆழி இரைந்தது, அதின் கைகளை உயர எடுத்தது.
	<br /><br />
	11. சந்திரனும் சூரியனும் தன்தன் மண்டலத்தில் நின்றன; உமது அம்புகளின் ஜோதியிலும், உமது ஈட்டியினுடைய மின்னல் பிரகாசத்திலும் நடந்தன.
	<br /><br />
	12. நீர் கோபத்தோடே பூமியில் நடந்தீர், உக்கிரத்தோடே ஜாதிகளைப் போரடித்தீர்.
	<br /><br />
	13. உமது ஜனத்தின் இரட்சிப்புக்காகவும் நீர் அபிஷேகம் பண்ணுவித்தவனின் இரட்சிப்புக்காகவுமே நீர் புறப்பட்டீர்; கழுத்தளவாக அஸ்திபாரத்தைத் திறப்பாக்கி, துஷ்டனுடைய வீட்டிலிருந்த தலைவனை வெட்டினீர்; சேலா.
	<br /><br />
	14. என்னைச் சிதறடிப்பதற்குப் பெருங்காற்றைப்போல் வந்தார்கள்; சிறுமையானவனை மறைவிடத்திலே பட்சிப்பது அவர்களுக்குச் சந்தோஷமாயிருந்தது; நீர் அவனுடைய ஈட்டிகளினாலேயே அவனுடைய கிராமத்து அதிபதிகளை உருவக் குத்தினீர்.
	<br /><br />
	15. திரளான தண்ணீர் குவியலாகிய சமுத்திரத்துக்குள் உமது குதிரைகளோடே நடந்துபோனீர்.
	<br /><br />
	16. நான் கேட்டபொழுது என் குடல் குழம்பிற்று; அந்தச் சத்தத்துக்கு என் உதடுகள் துடித்தது; என் எலும்புகளில் உக்கல் உண்டாயிற்று; என் நிலையிலே நடுங்கினேன்; ஆனாலும் எங்களோடே எதிர்க்கும் ஜனங்கள் வரும்போது, இக்கட்டுநாளிலே நான் இளைப்பாறுதல் அடைவேன்.
	<br /><br />
	17. அத்திமரம் துளிர்விடாமற்போனாலும், திராட்சச்செடிகளில் பழம் உண்டாகாமற்போனாலும், ஒலிவமரத்தின் பலன் அற்றுப்போனாலும், வயல்கள் தானியத்தை விளைவியாமற்போனாலும், கிடையில் ஆட்டுமந்தைகள் முதலற்றுப்போனாலும், தொழுவத்திலே மாடு இல்லாமற்போனாலும்,
	<br /><br />
	18. நான் கர்த்தருக்குள் மகிழ்ச்சியாயிருப்பேன், என் இரட்சிப்பின் தேவனுக்குள் களிகூருவேன்.
	<br /><br />
	19. ஆண்டவராகிய கர்த்தர் என் பெலன்; அவர் என் கால்களை மான்கால்களைப்போலாக்கி, உயரமான ஸ்தலங்களில் என்னை நடக்கப்பண்ணுவார். இது நெகிநோத் என்னும் வாத்தியத்தில் வாசிக்க இராகத்தலைவனுக்கு ஒப்புவிக்கப்பட்ட சங்கீதம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Habakkuk3