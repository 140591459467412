import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 15 </title>
	<meta
          name="description"
          content="Jeremiah 15 | எரேமியா 15 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் என்னை நோக்கி: மோசேயும் சாமுவேலும் என் முகத்துக்கு முன்பாக நின்றாலும், என் மனம் இந்த ஜனங்கள் பட்சமாய்ச் சாராது; இவர்கள் என் முகத்தைவிட்டுப் புறப்பட்டுப்போகும்படி இவர்களைத் துரத்திவிடு.
	<br /><br />
	2. எங்கே புறப்பட்டுப்போவோம் என்று இவர்கள் உன்னைக் கேட்டால், நீ அவர்களை நோக்கி: சாவுக்கு ஏதுவானவர்கள் சாவுக்கும், பட்டயத்துக்கு ஏதுவானவர்கள் பட்டயத்துக்கும், பஞ்சத்துக்கு ஏதுவானவர்கள் பஞ்சத்துக்கும், சிறையிருப்புக்கு ஏதுவானவர்கள் சிறையிருப்புக்கும் நேராய்ப் போகவேண்டும் என்று கர்த்தர் உரைக்கிறார் என்று சொல்லு.
	<br /><br />
	3. கொன்றுபோடப் பட்டயமும், பிடித்து இழுக்க நாய்களும், பட்சித்து அழிக்க ஆகாயத்துப் பறவைகளும், பூமியின் மிருகங்களும் ஆகிய நான்குவிதமான வாதைகளை நான் அவர்கள்மேல் வரக் கட்டளையிடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. எசேக்கியாவின் குமாரனும், யூதாவின் ராஜாவுமாகிய மனாசே எருசலேமில் செய்தவைகளினிமித்தம் அவர்களைப் பூமியிலுள்ள எல்லா ராஜ்யங்களிலும் அலையப்பண்ணுவேன்.
	<br /><br />
	5. எருசலேமே, யார் உன்மேல் இரங்குவார்கள்? யார் உன்மேல் பரிதபிப்பார்கள்? யார் உன்னிடத்திற்குத் திரும்பி, உன் சுகசெய்தியை விசாரிப்பார்கள்?
	<br /><br />
	6. நீ என்னைவிட்டுப் பின்வாங்கிப்போனாய்; ஆகையால், என் கையை உனக்கு விரோதமாய் நீட்டி, உன்னை அழிப்பேன்; நான் பொறுத்துப் பொறுத்து இளைத்துப்போனேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. தேசத்தின் வாசல்களில் அவர்களைத் தூற்றுக்கூடையால் தூற்றிப்போடுவேன்; என் ஜனங்கள் தங்கள் வழிகளைவிட்டுத் திரும்பாதபடியினால் நான் அவர்களைப் பிள்ளைகள் அற்றவர்களாக்கி அழிப்பேன்.
	<br /><br />
	8. கடற்கரை மணலைப்பார்க்கிலும் அதிக விதவைகள் அவர்களில் உண்டாயிருப்பார்கள்; பட்டப்பகலிலே பாழாக்குகிறவனைத் தாயின்மேலும் பிள்ளைகளின்மேலும் வரப்பண்ணுவேன்; அவர்கள்மேல் பட்டணத்தின் கலகத்தையும், பயங்கரங்களையும் விழப்பண்ணுவேன்.
	<br /><br />
	9. ஏழு பிள்ளைகளைப் பெற்றவள் களைத்துப்போகிறாள்; அவள் தன் பிராணனை விட்டுவிட்டாள்; இன்னும் பகலாயிருக்கையில் அவளுடைய சூரியன் அஸ்தமித்தது; வெட்கமும் இலச்சையும் அடைந்தாள்; அவர்களில் மீதியாகிறவர்களையோ அவர்களுடைய சத்துருக்களுக்கு முன்பாகப் பட்டயத்துக்கு ஒப்புக்கொடுப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. என் தாயே, தேசத்துக்கெல்லாம் வழக்குக்கும் வாதுக்கும் உள்ளானவனாயிருக்கும்படி என்னை நீ பெற்றாயே; ஐயோ! நான் அவர்களுக்கு வட்டிக்குக் கொடுத்ததுமில்லை, அவர்கள் எனக்கு வட்டிக்குக் கொடுத்ததுமில்லை; ஆனாலும், எல்லாரும் என்னைச் சபிக்கிறார்கள்.
	<br /><br />
	11. உன்னில் மீதியாயிருப்பவர்கள் நன்மையடைவார்கள்; தீங்கின் காலத்திலும் நெருக்கத்தின் காலத்திலும் உனக்காக நான் சத்துருவுக்கு எதிர்ப்பட்டு, உனக்குச் சகாயஞ்செய்வேன் என்று மெய்யாகவே சொல்லுகிறேன்.
	<br /><br />
	12. வடக்கேயிருந்து வரும் இரும்பையும் வெண்கலத்தையும் இரும்பு நொறுக்குமோ?
	<br /><br />
	13. உன்னுடைய எல்லாப் பாவங்களினிமித்தமும், உன்னுடைய எல்லா எல்லைகளிலும், நான் உன் ஆஸ்தியையும், உன் பொக்கிஷங்களையும் கிரயமில்லாமல் சூறையிடுவிப்பேன்.
	<br /><br />
	14. நீ அறியாத தேசத்தில் உன் சத்துருக்கள் வசமாக நான் உன்னைத் தாண்டிப்போகப் பண்ணுவேன்; உங்கள்மேல் எரியப்போகிற அக்கினி என் கோபத்தினாலே மூண்டது என்று கர்த்தர் சொன்னார்.
	<br /><br />
	15. கர்த்தாவே, நீர் அதை அறிவீர்; தேவரீர் என்னை நினைத்து, என்னை விசாரித்து, என்னைத் துன்பப்படுத்துகிறவர்களுக்கு என்னிமித்தம் நீதியைச் சரிக்கட்டும்; உம்முடைய நீடிய பொறுமையினிமித்தம் என்னை வாரிக்கொள்ளாதிரும்; நான் உம்முடைய நிமித்தம் நிந்தையைச் சகிக்கிறேன் என்று அறியும்.
	<br /><br />
	16. உம்முடைய வார்த்தைகள் கிடைத்தவுடனே அவைகளை உட்கொண்டேன்; உம்முடைய வார்த்தைகள் எனக்குச் சந்தோஷமும், என் இருதயத்துக்கு மகிழ்ச்சியுமாயிருந்தது; சேனைகளின் தேவனாகிய கர்த்தாவே, உம்முடைய நாமம் எனக்குத் தரிக்கப்பட்டிருக்கிறது.
	<br /><br />
	17. நான் பரியாசக்காரருடைய கூட்டத்தில் உட்கார்ந்து களிகூர்ந்ததில்லை; உமது கரத்தினிமித்தம் தனித்து உட்கார்ந்தேன்; சலிப்பினால் என்னை நிரப்பினீர்.
	<br /><br />
	18. என் நோவு நித்தியகாலமாகவும், என் காயம் ஆறாத கொடிய புண்ணாகவும் இருப்பானேன்? நீர் எனக்கு நம்பப்படாத ஊற்றைப்போலவும், வற்றிப்போகிற ஜலத்தைப்போலவும் இருப்பீரோ?
	<br /><br />
	19. இதினிமித்தம்: நீ திரும்பினால் நான் உன்னைத் திரும்பச் சீர்ப்படுத்துவேன்; என் முகத்துக்குமுன்பாக நிலைத்துமிருப்பாய்; நீ தீழ்ப்பானதினின்று விலையேறப்பெற்றதைப் பிரித்தெடுத்தால், என் வாய்போலிருப்பாய்; நீ அவர்களிடத்தில் திரும்பாமல், அவர்கள் உன்னிடத்தில் திரும்புவார்களாக என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	20. உன்னை இந்த ஜனத்துக்கு எதிரே அரணான வெண்கல அலங்கமாக்குவேன்; அவர்கள் உனக்கு விரோதமாக யுத்தம்பண்ணுவார்கள், ஆனாலும் உன்னை மேற்கொள்ளமாட்டார்கள்; உன்னை இரட்சிப்பதற்காகவும், உன்னைத் தப்புவிப்பதற்காகவும், நான் உன்னுடனே இருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	21. நான் உன்னைப் பொல்லாதவர்களின் கைக்குத் தப்புவித்து, உன்னைப் பலவந்தரின் கைக்கு நீங்கலாக்கி விடுவிப்பேன் என்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah15