import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ephesians5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Book of Ephesians Bible Tamil | எபேசியர் 5 </title>
	<meta
          name="description"
          content="Ephesians 5 | எபேசியர் 5 |
          Book of Ephesians | Tamil Bible Verse | Tamil Bible | எபேசியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. ஆதலால், நீங்கள் பிரியமான பிள்ளைகளைப்போலத் தேவனைப் பின்பற்றுகிறவர்களாகி,
	<br /><br />
	2. கிறிஸ்து நமக்காகத் தம்மைத் தேவனுக்குச் சுகந்த வாசனையான காணிக்கையாகவும் பலியாகவும் ஒப்புக்கொடுத்து நம்மில் அன்புகூர்ந்ததுபோல, நீங்களும் அன்பிலே நடந்துகொள்ளுங்கள்.
	<br /><br />
	3. மேலும், பரிசுத்தவான்களுக்கு ஏற்றபடி, வேசித்தனமும், மற்றெந்த அசுத்தமும், பொருளாசையும் ஆகிய இவைகளின் பேர்முதலாய் உங்களுக்குள்ளே சொல்லப்படவுங்கூடாது.
	<br /><br />
	4. அப்படியே வம்பும், புத்தியீனமான பேச்சும், பரியாசமும் தகாதவைகள்; ஸ்தோத்திரஞ்செய்தலே தகும்.
	<br /><br />
	5. விபசாரக்காரனாவது, அசுத்தனாவது, விக்கிரகாராதனைக்காரனாகிய பொருளாசைக்காரனாவது தேவனுடைய ராஜ்யமாகிய கிறிஸ்துவின் ராஜ்யத்திலே சுதந்தரமடைவதில்லையென்று அறிந்திருக்கிறீர்களே.
	<br /><br />
	6. இப்படிப்பட்டவைகளினிமித்தமாகக் கீழ்ப்படியாமையின் பிள்ளைகள்மேல் தேவகோபாக்கினை வருவதால், ஒருவனும் உங்களை வீண்வார்த்தைகளினாலே மோசம்போக்காதபடிக்கு எச்சரிக்கையாயிருங்கள்;
	<br /><br />
	7. அவர்களுக்குப் பங்காளிகளாகாதிருங்கள்.
	<br /><br />
	8. முற்காலத்தில் நீங்கள் அந்தகாரமாயிருந்தீர்கள், இப்பொழுதோ கர்த்தருக்குள் வெளிச்சமாயிருக்கிறீர்கள்; வெளிச்சத்தின் பிள்ளைகளாய் நடந்துகொள்ளுங்கள்.
	<br /><br />
	9. ஆவியின் கனி, சகல நற்குணத்திலும் நீதியிலும் உண்மையிலும் விளங்கும்.
	<br /><br />
	10. கர்த்தருக்குப் பிரியமானது இன்னதென்று நீங்கள் சோதித்துப்பாருங்கள்.
	<br /><br />
	11. கனியற்ற அந்தகாரக் கிரியைகளுக்கு உடன்படாமல், அவைகளைக் கடிந்துகொள்ளுங்கள்.
	<br /><br />
	12. அவர்களால் ஒளிப்பிடத்தில் செய்யப்படும் கிரியைகளைச் சொல்லுகிறதும் அவலட்சணமாயிருக்கிறதே.
	<br /><br />
	13. அவைகளெல்லாம் கடிந்துகொள்ளப்பட்டு வெளிச்சத்தினால் வெளியரங்கமாகும்; வெளியரங்கமாக்குகிறதெல்லாம் வெளிச்சமாயிருக்கிறது.
	<br /><br />
	14. ஆதலால், தூங்குகிற நீ விழித்து, மரித்தோரை விட்டு எழுந்திரு, அப்பொழுது கிறிஸ்து உன்னைப் பிரகாசிப்பிப்பாரென்று சொல்லியிருக்கிறார்.
	<br /><br />
	15. ஆனபடியினாலே, நீங்கள் ஞானமற்றவர்களைப்போல நடவாமல், ஞானமுள்ளவர்களைப்போலக் கவனமாய் நடந்துகொள்ளப்பார்த்து,
	<br /><br />
	16. நாட்கள் பொல்லாதவைகளானதால் காலத்தைப் பிரயோஜனப்படுத்திக்கொள்ளுங்கள்.
	<br /><br />
	17. ஆகையால், நீங்கள் மதியற்றவர்களாயிராமல், கர்த்தருடைய சித்தம் இன்னதென்று உணர்ந்துகொள்ளுங்கள்.
	<br /><br />
	18. துன்மார்க்கத்திற்கு ஏதுவான மதுபான வெறிகொள்ளாமல், ஆவியினால் நிறைந்து;
	<br /><br />
	19. சங்கீதங்களினாலும் கீர்த்தனைகளினாலும் ஞானப்பாட்டுகளினாலும் ஒருவருக்கொருவர் புத்திசொல்லிக்கொண்டு, உங்கள் இருதயத்தில் கர்த்தரைப் பாடிக் கீர்த்தனம்பண்ணி,
	<br /><br />
	20. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் நாமத்தினாலே எப்பொழுதும் எல்லாவற்றிற்காகவும் பிதாவாகிய தேவனை ஸ்தோத்திரித்து,
	<br /><br />
	21. தெய்வ பயத்தோடே ஒருவருக்கொருவர் கீழ்ப்படிந்திருங்கள்.
	<br /><br />
	22. மனைவிகளே, கர்த்தருக்குக் கீழ்ப்படிகிறதுபோல, உங்கள் சொந்தப் புருஷருக்குங் கீழ்ப்படியுங்கள்.
	<br /><br />
	23. கிறிஸ்து சபைக்குத் தலையாயிருக்கிறதுபோல, புருஷனும் மனைவிக்குத் தலையாயிருக்கிறான்; அவரே சரீரத்திற்கும் இரட்சகராயிருக்கிறார்.
	<br /><br />
	24. ஆகையால், சபையானது கிறிஸ்துவுக்குக் கீழ்ப்படிகிறதுபோல மனைவிகளும் தங்கள் சொந்தப் புருஷர்களுக்கு எந்தக் காரியத்திலேயும் கீழ்ப்படிந்திருக்கவேண்டும்.
	<br /><br />
	25. புருஷர்களே, உங்கள் மனைவிகளில் அன்புகூருங்கள்; அப்படியே கிறிஸ்துவும் சபையில் அன்புகூர்ந்து,
	<br /><br />
	26. தாம் அதைத் திருவசனத்தைக்கொண்டு தண்ணீர் முழுக்கினால் சுத்திகரித்து, பரிசுத்தமாக்குகிறதற்கும்,
	<br /><br />
	27. கறைதிரை முதலானவைகள் ஒன்றும் இல்லாமல் பரிசுத்தமும் பிழையற்றதுமான மகிமையுள்ள சபையாக அதைத் தமக்குமுன் நிறுத்திக்கொள்வதற்கும் தம்மைத்தாமே அதற்காக ஒப்புக்கொடுத்தார்.
	<br /><br />
	28. அப்படியே, புருஷர்களும் தங்கள் மனைவிகளைத் தங்கள் சொந்தச் சரீரங்களாகப் பாவித்து, அவர்களில் அன்புகூரவேண்டும்; தன் மனைவியில் அன்புகூருகிறவன் தன்னில்தான் அன்புகூருகிறான்.
	<br /><br />
	29. தன் சொந்த மாம்சத்தைப் பகைத்தவன் ஒருவனுமில்லையே; கர்த்தர் சபையைப் போஷித்துக் காப்பாற்றுகிறதுபோல ஒவ்வொருவனும் தன் மாம்சத்தைப் போஷித்துக் காப்பாற்றுகிறான்.
	<br /><br />
	30. நாம் அவருடைய சரீரத்தின் அவயவங்களாயும், அவருடைய மாம்சத்திற்கும் அவருடைய எலும்புகளுக்கும் உரியவர்களாயும் இருக்கிறோம்.
	<br /><br />
	31. இதினிமித்தம் மனுஷன் தன் தகப்பனையும் தன் தாயையும் விட்டு, தன் மனைவியுடன் இசைந்து, இருவரும் ஒரே மாம்சமாயிருப்பார்கள்.
	<br /><br />
	32. இந்த இரகசியம் பெரியது; நான் கிறிஸ்துவைப்பற்றியும் சபையைப்பற்றியும் சொல்லுகிறேன்.
	<br /><br />
	33. எப்படியும், உங்களிலும் அவனவன் தன்னிடத்தில் அன்புகூருவதுபோல, தன் மனைவியினிடத்திலும் அன்புகூரக்கடவன்; மனைவியும் புருஷனிடத்தில் பயபக்தியாயிருக்கக்கடவள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ephesians5