import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah24 = () => {
  const verseNumber = 24;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 24 </title>
	<meta
          name="description"
          content="Jeremiah 24 | எரேமியா 24 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார், யோயாக்கீமின் குமாரனாகிய எகொனியா என்கிற யூதாவின் ராஜாவையும், யூதாவின் பிரபுக்களையும், எருசலேமிலுள்ள தச்சரையும் கொல்லரையும் சிறைபிடித்து, பாபிலோனுக்குக் கொண்டுபோனபின்பு, இதோ, கர்த்தருடைய ஆலயத்தின்முன் வைக்கப்பட்டிருந்த அத்திப்பழங்களுள்ள இரண்டு கூடைகளைக் கர்த்தர் எனக்குக் காண்பித்தார்.
	<br /><br />
	2. ஒரு கூடையிலே முதல் கந்தாயத்து அத்திப்பழங்களுக்குச் சமானமான மிகவும் நல்ல அத்திப்பழங்களும், மற்றக் கூடையிலே புசிக்கத்தகாத மிகவும் கெட்ட அத்திப்பழங்களும் இருந்தது.
	<br /><br />
	3. கர்த்தர் என்னை நோக்கி: எரேமியாவே, நீ என்னத்தைக் காண்கிறாய் என்றார்; அதற்கு நான்: அத்திப்பழங்களைக் காண்கிறேன்; நல்லவைகளான அத்திப்பழங்கள் மிகவும் நல்லவைகளும், கெட்டவைகளோ புசிக்கத்தகாத மிகவும் கெட்டவைகளுமாயிருக்கிறது என்றேன்.
	<br /><br />
	4. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	5. நான் இவ்விடத்திலிருந்து, கல்தேயர் தேசத்துக்குச் சிறைப்பட்டுப் போகவிட்ட யூதரை நான் இந்த நல்ல அத்திப்பழங்களுக்கு ஒப்பிட்டு, அவர்களுக்கு நன்மையுண்டாக அவர்களை அங்கிகரிப்பேன்.
	<br /><br />
	6. அவர்களுக்கு நன்மையுண்டாக நான் என் கண்களை அவர்கள்மேல் வைத்து, அவர்களை இந்த தேசத்துக்குத் திரும்பிவரப்பண்ணி, அவர்களைக் கட்டுவேன், அவர்களை இடிக்கமாட்டேன், அவர்களை நாட்டுவேன், அவர்களைப் பிடுங்கமாட்டேன்.
	<br /><br />
	7. நான் கர்த்தர் என்று அறியும் இருதயத்தை அவர்களுக்குக் கொடுப்பேன்; அவர்கள் என் ஜனமாயிருப்பார்கள், நான் அவர்கள் தேவனாயிருப்பேன்; அவர்கள் தங்கள் முழு இருதயத்தோடும் என்னிடத்திற்குத் திரும்புவார்கள் என்று இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	8. புசிக்கத்தகாத கெட்ட அத்திப்பழங்களைத் தள்ளிவிடுவதுபோல, நான் சிதேக்கியா என்கிற யூதாவின் ராஜாவையும் அவனுடைய பிரபுக்களையும், இந்தத் தேசத்திலே மீதியான எருசலேமின் குடிகளையும், எகிப்து தேசத்தில் குடியிருக்கிறவர்களையும் தள்ளிவிட்டு,
	<br /><br />
	9. அவர்களுக்குத் தீமையுண்டாக அவர்களை பூமியிலுள்ள எல்லா ராஜ்யங்களிலும் அலைந்து திரிகிறவர்களாகவும், நான் அவர்களைத் துரத்திவிட்ட எல்லா இடங்களிலும் நிந்தையாகவும், பழமொழியாகவும், வசைச்சொல்லாகவும், சாபமாகவும் வைத்து,
	<br /><br />
	10. அவர்களுக்கும் அவர்கள் பிதாக்களுக்கும் நான் கொடுத்த தேசத்தில் அவர்கள் இராதபடிக்கு நிர்மூலமாகுமட்டும், அவர்களுக்குள்ளே பட்டயத்தையும், பஞ்சத்தையும், கொள்ளைநோயையும் அனுப்புவேன் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah24