import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 6 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 6
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆகையால் என்னசொல்லுவோம்? கிருபை பெருகும்படிக்குப் பாவத்திலே நிலைநிற்கலாம் என்று சொல்லுவோமா? கூடாதே.
	<br /><br />
	2. பாவத்துக்கு மரித்த நாம் இனி அதிலே எப்படிப் பிழைப்போம்?
	<br /><br />
	3. கிறிஸ்து இயேசுவுக்குள்ளாக ஞானஸ்நானம் பெற்ற நாமனைவரும் அவருடைய மரணத்துக்குள்ளாக ஞானஸ்நானம் பெற்றதை அறியாமலிருக்கிறீர்களா?
	<br /><br />
	4. மேலும் பிதாவின் மகிமையினாலே கிறிஸ்து மரித்தோரிலிருந்து எழுப்பப்பட்டதுபோல, நாமும் புதிதான ஜீவனுள்ளவர்களாய் நடந்துகொள்ளும்படிக்கு, அவருடைய மரணத்திற்குள்ளாக்கும் ஞானஸ்நானத்தினாலே கிறிஸ்துவுடனேகூட அடக்கம்பண்ணப்பட்டோம்.
	<br /><br />
	5. ஆதலால் அவருடைய மரணத்தின் சாயலில் நாம் இணைக்கப்பட்டவர்களானால், அவர் உயிர்த்தெழுதலின் சாயலிலும் இணைக்கப்பட்டிருப்போம்.
	<br /><br />
	6. நாம் இனிப் பாவத்துக்கு ஊழியஞ் செய்யாதபடிக்கு, பாவசரீரம் ஒழிந்துபோகும்பொருட்டாக, நம்முடைய பழைய மனுஷன் அவரோடேகூடச் சிலுவையில் அறையப்பட்டதென்று அறிந்திருக்கிறோம்.
	<br /><br />
	7. மரித்தவன் பாவத்துக்கு நீங்கி விடுதலையாக்கப்பட்டிருக்கிறானே.
	<br /><br />
	8. ஆகையால் கிறிஸ்துவுடனேகூட நாம் மரித்தோமானால், அவருடனேகூடப் பிழைத்தும் இருப்போம் என்று நம்புகிறோம்.
	<br /><br />
	9. மரித்தோரிலிருந்து எழுந்த கிறிஸ்து இனி மரிப்பதில்லையென்று அறிந்திருக்கிறோம்; மரணம் இனி அவரை ஆண்டுகொள்வதில்லை.
	<br /><br />
	10. அவர் மரித்தது, பாவத்திற்கென்று ஒரேதரம் மரித்தார்; அவர் பிழைத்திருக்கிறது, தேவனுக்கென்று பிழைத்திருக்கிறார்.
	<br /><br />
	11. அப்படியே நீங்களும், உங்களைப் பாவத்திற்கு மரித்தவர்களாகவும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவுக்குள் தேவனுக்கென்று பிழைத்திருக்கிறவர்களாகவும் எண்ணிக்கொள்ளுங்கள்.
	<br /><br />
	12. ஆகையால், நீங்கள் சரீர இச்சைகளின்படி பாவத்திற்குக் கீழ்ப்படியத்தக்கதாக, சாவுக்கேதுவான உங்கள் சரீரத்தில் பாவம் ஆளாதிருப்பதாக.
	<br /><br />
	13. நீங்கள் உங்கள் அவயவங்களை அநீதியின் ஆயுதங்களாகப் பாவத்திற்கு ஒப்புக்கொடாமல், உங்களை மரித்தோரிலிருந்து பிழைத்திருக்கிறவர்களாகத் தேவனுக்கு ஒப்புக்கொடுத்து, உங்கள் அவயவங்களை நீதிக்குரிய ஆயுதங்களாகத் தேவனுக்கு ஒப்புக்கொடுங்கள்.
	<br /><br />
	14. நீங்கள் நியாயப்பிரமாணத்திற்குக் கீழ்ப்பட்டிராமல் கிருபைக்குக் கீழ்ப்பட்டிருக்கிறபடியால், பாவம் உங்களை மேற்கொள்ளமாட்டாது.
	<br /><br />
	15. இதினால் என்ன? நாம் நியாயப்பிரமாணத்திற்குக் கீழ்ப்பட்டிராமல் கிருபைக்குக் கீழ்ப்பட்டிருக்கிறபடியால், பாவஞ்செய்யலாமா? கூடாதே.
	<br /><br />
	16. மரணத்துக்கேதுவான பாவத்துக்கானாலும், நீதிக்கேதுவான கீழ்ப்படிதலுக்கானாலும், எதற்குக் கீழ்ப்படியும்படி உங்களை அடிமைகளாக ஒப்புக்கொடுக்கிறீர்களோ, அதற்கே கீழ்ப்படிகிற அடிமைகளாயிருக்கிறீர்களென்று அறியீர்களா?
	<br /><br />
	17. முன்னே நீங்கள் பாவத்திற்கு அடிமைகளாயிருந்தும், இப்பொழுது உங்களுக்கு ஒப்புவிக்கப்பட்ட உபதேச சட்டத்திற்கு நீங்கள் மனப்பூர்வமாய்க் கீழ்ப்படிந்ததினாலே தேவனுக்கு ஸ்தோத்திரம்.
	<br /><br />
	18. பாவத்தினின்று நீங்கள் விடுதலையாக்கப்பட்டு, நீதிக்கு அடிமைகளானீர்கள்.
	<br /><br />
	19. உங்கள் மாம்ச பலவீனத்தினிமித்தம் மனுஷர் பேசுகிறபிரகாரமாய்ப் பேசுகிறேன். அக்கிரமத்தை நடப்பிக்கும்படி முன்னே நீங்கள் உங்கள் அவயவங்களை அசுத்தத்திற்கும் அக்கிரமத்திற்கும் அடிமைகளாக ஒப்புக்கொடுத்ததுபோல, இப்பொழுது பரிசுத்தமானதை நடப்பிக்கும்படி உங்கள் அவயவங்களை நீதிக்கு அடிமைகளாக ஒப்புக்கொடுங்கள்.
	<br /><br />
	20. பாவத்திற்கு நீங்கள் அடிமைகளாயிருந்த காலத்தில் நீதிக்கு நீங்கினவர்களாயிருந்தீர்கள்.
	<br /><br />
	21. இப்பொழுது உங்களுக்கு வெட்கமாகத் தோன்றுகிற காரியங்களினாலே அக்காலத்தில் உங்களுக்கு என்ன பலன் கிடைத்தது? அவைகளின் முடிவு மரணமே.
	<br /><br />
	22. இப்பொழுது நீங்கள் பாவத்தினின்று விடுதலையாக்கப்பட்டு, தேவனுக்கு அடிமைகளானதினால், பரிசுத்தமாகுதல் உங்களுக்குக் கிடைக்கும் பலன், முடிவோ நித்தியஜீவன்.
	<br /><br />
	23. பாவத்தின் சம்பளம் மரணம்; தேவனுடைய கிருபைவரமோ நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினால் உண்டான நித்தியஜீவன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans6