import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 9 </title>
	<meta
          name="description"
          content="Ezekiel 9 | எசேக்கியேல்  9 |
          Ezekiel 9 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் என் காதுகள் கேட்க மகா சத்தமாய்: நகரத்தின் விசாரிப்புக்காரர் சங்கரிக்கும் ஆயுதங்களைத் தங்கள் கைகளில் பிடித்துக்கொண்டு வரக்கடவர்கள் என்று சொன்னார்.
	<br /><br />
	2. அப்பொழுது இதோ, ஆறு புருஷர், வெட்டுகிற ஆயுதங்களைத் தங்கள் கைகளில் பிடித்துக்கொண்டு, வடக்கே பார்த்த உயர்ந்த வாசலின் வழியிலிருந்து வந்தார்கள்; அவர்களில் சணல்நூல் அங்கிதரித்து, தன் அரையில் கணக்கனுடைய மைக்கூட்டை வைத்திருக்கிற ஒருவன் இருந்தான்; அவர்கள் உள்ளே பிரவேசித்து, வெண்கல பலிபீடத்தண்டையிலே நின்றார்கள்.
	<br /><br />
	3. அப்பொழுது இஸ்ரவேலின் தேவனுடைய மகிமை கேருபீன்மேலிருந்தெழும்பி, ஆலயத்தின் வாசற்படியிலே வந்து, சணல்நூல் அங்கிதரித்து, தன் அரையிலே கணக்கனுடைய மைக்கூட்டை வைத்திருக்கிற புருஷனைக் கூப்பிட்டு,
	<br /><br />
	4. கர்த்தர் அவனை நோக்கி: நீ எருசலேம் நகரம் எங்கும் உருவப்போய், அதற்குள்ளே செய்யப்படுகிற சகல அருவருப்புகளினிமித்தமும் பெருமூச்சுவிட்டழுகிற மனுஷரின் நெற்றிகளில் அடையாளம் போடு என்றார்.
	<br /><br />
	5. பின்பு அவர் என் காதுகள் கேட்க மற்றவர்களை நோக்கி: நீங்கள் இவன் பின்னாலே நகரமெங்கும் உருவப்போய் வெட்டுங்கள்; உங்கள் கண் தப்பவிடாமலும், நீங்கள் இரங்காமலும்,
	<br /><br />
	6. முதியோரையும், வாலிபரையும், கன்னிகைகளையும், குழந்தைகளையும், ஸ்திரீகளையும் சங்கரித்துக் கொன்றுபோடுங்கள்; அடையாளம் போடப்பட்டிருக்கிற ஒருவனையும் கிட்டாதிருங்கள், என் பரிசுத்த ஸ்தலத்திலே துவக்குங்கள் என்று என் காதுகள் கேட்கச் சொன்னார்; அப்பொழுது அவர்கள் ஆலயத்துக்குமுன்னே இருந்த மூப்பரிடத்தில் துவக்கம் பண்ணினார்கள்.
	<br /><br />
	7. அவர் அவர்களை நோக்கி: நீங்கள் ஆலயத்தைத் தீட்டுப்படுத்தி, பிராகாரங்களைக் கொலையுண்டவர்களாலே நிரப்பி, புறப்பட்டுப்போங்கள் என்றார்; அவர்கள் நகரத்தில் போய் வெட்டினார்கள்.
	<br /><br />
	8. அவர்கள் வெட்டிக்கொண்டுபோகையில் நான்மாத்திரம் தனித்து, முகங்குப்புற விழுந்து: ஆ, கர்த்தராகிய ஆண்டவரே, தேவரீர் எருசலேமின்மேல் உமது உக்கிரத்தை ஊற்றுகையில் இஸ்ரவேலின் மீதியானவர்களையெல்லாம் அழிப்பீரோ என்று முறையிட்டேன்.
	<br /><br />
	9. அதற்கு அவர்: இஸ்ரவேலும் யூதாவுமாகிய வம்சத்தாரின் அக்கிரமம் மிகவும் பெரிது; தேசம் இரத்தப்பழிகளால் நிறைந்திருக்கிறது; நகரமும் மாறுபாட்டினால் நிரப்பப்பட்டிருக்கிறது; கர்த்தர் தேசத்தைக் கைவிட்டார்; கர்த்தர் பார்க்கமாட்டார் என்று சொல்லுகிறார்கள்.
	<br /><br />
	10. ஆகையால் என் கண் தப்பவிடுவதுமில்லை, நான் இரக்கஞ்செய்வதுமில்லை; அவர்களுடைய வழியின் பலனை அவர்கள் சிரசின்மேல் இறங்கப்பண்ணுவேன் என்றார்.
	<br /><br />
	11. இதோ, சணல்நூல் அங்கி தரித்து, தன் அரையில் மைக்கூட்டை வைத்திருக்கிற புருஷன் வந்து: நீர் எனக்குக் கட்டளையிட்டபடியே செய்தேன் என்று காரியத்தைத் தெரிவித்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial9