import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 13 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 13
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எந்த மனுஷனும் மேலான அதிகாரமுள்ளவர்களுக்குக் கீழ்ப்படியக்கடவன்; ஏனென்றால், தேவனாலேயன்றி ஒரு அதிகாரமுமில்லை; உண்டாயிருக்கிற அதிகாரங்கள் தேவனாலே நியமிக்கப்பட்டிருக்கிறது.
	<br /><br />
	2. ஆதலால் அதிகாரத்திற்கு எதிர்த்து நிற்கிறவன் தேவனுடைய நியமத்திற்கு எதிர்த்து நிற்கிறான்; அப்படி எதிர்த்து நிற்கிறவர்கள் தங்களுக்குத் தாங்களே ஆக்கினையை வருவித்துக்கொள்ளுகிறார்கள்.
	<br /><br />
	3. மேலும் அதிகாரிகள் நற்கிரியைகளுக்கல்ல, துர்க்கிரியைகளுக்கே பயங்கரமாயிருக்கிறார்கள்; ஆகையால் நீ அதிகாரத்திற்குப் பயப்படாதிருக்கவேண்டுமானால், நன்மைசெய், அதினால் உனக்குப் புகழ்ச்சி உண்டாகும்.
	<br /><br />
	4. உனக்கு நன்மை உண்டாகும் பொருட்டு, அவன் தேவஊழியக்காரனாயிருக்கிறான். நீ தீமைசெய்தால் பயந்திரு; பட்டயத்தை அவன் விருதாவாய்ப் பிடித்திருக்கவில்லை; தீமைசெய்கிறவன்மேல் கோபாக்கினை வரப்பண்ணும்படி, அவன் நீதியைச் செலுத்துகிற தேவஊழியக்காரனாயிருக்கிறானே.
	<br /><br />
	5. ஆகையால், நீங்கள் கோபாக்கினையினிமித்தம் மாத்திரமல்ல, மனச்சாட்சியினிமித்தமும் கீழ்ப்படியவேண்டும்.
	<br /><br />
	6. இதற்காகவே நீங்கள் வரியையும் கொடுக்கிறீர்கள். அவர்கள் இந்த வேலையைப் பார்த்துவருகிற தேவஊழியக்காரராயிருக்கிறார்களே.
	<br /><br />
	7. ஆகையால் யாவருக்கும் செலுத்தவேண்டிய கடமைகளைச் செலுத்துங்கள்; எவனுக்கு வரியைச் செலுத்தவேண்டியதோ அவனுக்கு வரியையும், எவனுக்குத் தீர்வையைச் செலுத்தவேண்டியதோ அவனுக்குத் தீர்வையையும் செலுத்துங்கள்; எவனுக்குப் பயப்படவேண்டியதோ அவனுக்குப் பயப்படுங்கள்; எவனைக் கனம்பண்ணவேண்டியதோ அவனைக் கனம்பண்ணுங்கள்.
	<br /><br />
	8. ஒருவரிடத்திலொருவர் அன்புகூருகிற கடனேயல்லாமல், மற்றொன்றிலும் ஒருவனுக்கும் கடன்படாதிருங்கள்; பிறனிடத்தில் அன்புகூருகிறவன் நியாயப்பிரமாணத்தை நிறைவேற்றுகிறான்.
	<br /><br />
	9. எப்படியென்றால், விபசாரம் செய்யாதிருப்பாயாக, கொலை செய்யாதிருப்பாயாக, களவு செய்யாதிருப்பாயாக, பொய்ச்சாட்சி சொல்லாதிருப்பாயாக, இச்சியாதிருப்பாயாக என்கிற இந்தக் கற்பனைகளும், வேறே எந்தக் கற்பனையும், உன்னிடத்தில் நீ அன்புகூருகிறதுபோலப் பிறனிடத்திலும் அன்புகூருவாயாக என்கிற ஒரே வார்த்தையிலே தொகையாய் அடங்கியிருக்கிறது.
	<br /><br />
	10. அன்பானது பிறனுக்குப் பொல்லாங்குசெய்யாது; ஆதலால் அன்பு நியாயப்பிரமாணத்தின் நிறைவேறுதலாயிருக்கிறது.
	<br /><br />
	11. நித்திரையைவிட்டு எழுந்திருக்கத்தக்க வேளையாயிற்றென்று, நாம் காலத்தை அறிந்தவர்களாய், இப்படி நடக்கவேண்டும்; நாம் விசுவாசிகளானபோது இரட்சிப்பு சமீபமாயிருந்ததைப்பார்க்கிலும் இப்பொழுது அது நமக்கு அதிக சமீபமாயிருக்கிறது.
	<br /><br />
	12. இரவு சென்றுபோயிற்று, பகல் சமீபமாயிற்று; ஆகையால் அந்தகாரத்தின் கிரியைகளை நாம் தள்ளிவிட்டு, ஒளியின் ஆயுதங்களைத் தரித்துக்கொள்ளக்கடவோம்.
	<br /><br />
	13. களியாட்டும் வெறியும், வேசித்தனமும் காமவிகாரமும், வாக்குவாதமும் பொறாமையும் உள்ளவர்களாய் நடவாமல், பகலிலே நடக்கிறவர்கள்போலச் சீராய் நடக்கக்கடவோம்.
	<br /><br />
	14. துர்இச்சைகளுக்கு இடமாக உடலைப் பேணாமலிருந்து, கர்த்தராகிய இயேசுகிறிஸ்துவைத் தரித்துக்கொள்ளுங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans13