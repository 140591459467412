import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 7 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 7 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 7 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்படிப்பட்ட வாக்குத்தத்தங்கள் நமக்கு உண்டாயிருக்கிறபடியினால், பிரியமானவர்களே, மாம்சத்திலும் ஆவியிலும் உண்டான எல்லா அசுசியும் நீங்க, நம்மைச் சுத்திகரித்துக்கொண்டு, பரிசுத்தமாகுதலைத் தேவபயத்தோடே பூரணப்படுத்தக்கடவோம்.
	<br /><br />
	2. எங்களுக்கு இடங்கொடுங்கள்; நாங்கள் ஒருவனுக்கும் அநியாயஞ்செய்யவில்லை, ஒருவனையும் கெடுக்கவில்லை, ஒருவனையும் வஞ்சிக்கவில்லை.
	<br /><br />
	3. உங்களைக் குற்றவாளிகளாக்கும் பொருட்டாக இப்படி நான் சொல்லுகிறதில்லை; முன்னே நான் சொல்லியபடி, உங்களுடனேகூடச் சாகவும் கூடப் பிழைக்கவுந்தக்கதாக எங்களிருதயங்களில் நீங்கள் இருக்கிறீர்களே.
	<br /><br />
	4. மிகுந்த தைரியத்தோடே உங்களுடன் பேசுகிறேன்; உங்களைக்குறித்து மிகவும் மேன்மைபாராட்டுகிறேன், ஆறுதலால் நிறைந்திருக்கிறேன், எங்களுக்கு உண்டான சகல உபத்திரவத்திலேயும் பரிபூரண சந்தோஷமாயிருக்கிறேன்.
	<br /><br />
	5. எப்படியென்றால், நாங்கள் மக்கெதோனியா நாட்டிலே வந்தபோது, எங்கள் சரீரத்திற்கு இளைப்பாறுதல் ஒன்றுமில்லாமல், எப்பக்கத்திலேயும் உபத்திரவப்பட்டோம்; புறம்பே போராட்டங்களும், உள்ளே பயங்களும் இருந்தன.
	<br /><br />
	6. ஆகிலும், சிறுமைப்பட்டவர்களுக்கு ஆறுதல் செய்கிற தேவன் தீத்து வந்ததினாலே எங்களுக்கு ஆறுதல்செய்தார்.
	<br /><br />
	7. அவன் வந்ததினாலே மாத்திரமல்ல, உங்கள் வாஞ்சையையும், உங்கள் துக்கிப்பையும், என்னைப்பற்றி உங்களுக்கு உண்டான பக்திவைராக்கியத்தையும் அவன் கண்டு, உங்களால் அடைந்த ஆறுதலைத் தெரியப்படுத்தினதினாலும், நானும் ஆறுதலடைந்து அதிகமாய்ச் சந்தோஷப்பட்டேன்.
	<br /><br />
	8. ஆதலால் நான் நிருபத்தினாலே உங்களைத் துக்கப்படுத்தியிருந்தும், அந்த நிருபம் கொஞ்சப்பொழுதாகிலும் உங்களைத் துக்கப்படுத்தினதென்று கண்டு நான் மனஸ்தாபப்பட்டிருந்தும், இப்பொழுது மனஸ்தாபப்படுகிறதில்லை.
	<br /><br />
	9. இப்பொழுது சந்தோஷப்படுகிறேன்; நீங்கள் துக்கப்பட்டதற்காக அல்ல, மனந்திரும்புகிறதற்கேதுவாகத் துக்கப்பட்டதற்காகவே சந்தோஷப்படுகிறேன்; நீங்கள் ஒன்றிலும் எங்களால் நஷ்டப்படாதபடிக்கு, தேவனுக்கேற்ற துக்கம் அடைந்தீர்களே.
	<br /><br />
	10. தேவனுக்கேற்ற துக்கம் பின்பு மனஸ்தாபப்படுகிறதற்கு இடமில்லாமல் இரட்சிப்புக்கேதுவான மனந்திரும்புதலை உண்டாக்குகிறது; லௌகிக துக்கமோ மரணத்தை உண்டாக்குகிறது.
	<br /><br />
	11. பாருங்கள், நீங்கள் தேவனுக்கேற்ற துக்கமடைந்ததுண்டே; அது உங்களிடத்தில் எவ்வளவு ஜாக்கிரதையையும், குற்றந்தீர எவ்வளவு நியாயஞ்சொல்லுதலையும், எவ்வளவு வெறுப்பையும், எவ்வளவு பயத்தையும், எவ்வளவு ஆவலையும், எவ்வளவு பக்திவைராக்கியத்தையும், எவ்வளவு கண்டிப்பையும் உண்டாக்கிற்று. இந்தக் காரியத்திலே நீங்கள் எல்லாவிதத்திலும் உங்களைச் சுத்தவான்களென்று விளங்கப்பண்ணினீர்கள்.
	<br /><br />
	12. ஆதலால் நான் உங்களுக்கு அப்படி எழுதியிருந்தும், அநியாயஞ்செய்தவனிமித்தமுமல்ல, அநியாயஞ்செய்யப்பட்டவனிமித்தமுமல்ல, தேவனுக்குமுன்பாக உங்களைக்குறித்து எங்களுக்கு உண்டாயிருக்கிற ஜாக்கிரதை உங்களுக்கு வெளிப்படும்பொருட்டே அப்படி எழுதினேன்.
	<br /><br />
	13. இதினிமித்தம் நீங்கள் ஆறுதலடைந்ததினாலே நாங்களும் ஆறுதலடைந்தோம்; விசேஷமாகத் தீத்துவினுடைய ஆவி உங்கள் அனைவராலும் ஆறுதலடைந்ததினாலே, அவனுக்கு உண்டான சந்தோஷத்தினால் அதிக சந்தோஷப்பட்டோம்.
	<br /><br />
	14. இப்படியிருக்க, உங்களுக்குப் புகழ்ச்சியாய் நான் அவனுடனே சொன்ன யாதொன்றைக்குறித்தும், வெட்கப்படமாட்டேன்; நாங்கள் சகலத்தையும் உங்களுக்குச் சத்தியமாய்ச் சொன்னதுபோல, தீத்துவுடனே நாங்கள் உங்களுக்குப் புகழ்ச்சியாய்ச் சொன்னதும் சத்தியமாக விளங்கிற்றே.
	<br /><br />
	15. மேலும் நீங்களெல்லாரும் கட்டளைக்கு அமைந்து, பயத்தோடும் நடுக்கத்தோடும் தன்னை ஏற்றுக்கொண்டதை அவன் நினைக்கையில், அவனுடைய உள்ளம் உங்களைப்பற்றி அதிக அன்பாயிருக்கிறது.
	<br /><br />
	16. ஆகையால் எல்லாவிதத்திலும் உங்களைக்குறித்து எனக்குத் திடநம்பிக்கை உண்டாயிருக்கிறதென்று சந்தோஷப்படுகிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians7