import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah32 = () => {
  const verseNumber = 32;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 32 </title>
	<meta
          name="description"
          content="Isaiah 32 | ஏசாயா 32 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, ஒரு ராஜா நீதியாக அரசாளுவார்; பிரபுக்களும் நியாயமாகத் துரைத்தனம்பண்ணுவார்கள்.
	<br /><br />
	2. அவர் காற்றுக்கு ஒதுக்காகவும், பெருவெள்ளத்துக்குப் புகலிடமாகவும், வறண்ட நிலத்துக்கு நீர்க்கால்களாகவும், விடாய்த்த பூமிக்குப் பெருங்கன்மலையின் நிழலாகவும் இருப்பார்.
	<br /><br />
	3. அப்பொழுது காண்கிறவர்களின் கண்கள் மங்கலாயிராது; கேட்கிறவர்களின் செவிகள் கவனித்தே இருக்கும்.
	<br /><br />
	4. பதற்றமுள்ளவர்களின் இருதயம் அறிவை உணர்ந்துகொள்ளும், தெற்றுவாயருடைய நாவு தடையின்றித் தெளிவாய்ப் பேசும்.
	<br /><br />
	5. மூடன் இனி தயாளன் என்று மதிக்கப்படான்; லோபி இனி உதாரன் என்று சொல்லப்படுவதுமில்லை.
	<br /><br />
	6. ஏனென்றால் மூடன், மூடத்தனத்தைப் பேசுகிறான்; அவன் இருதயம் அநியாயத்தை நடப்பிக்கும்; அவன் மாயம்பண்ணி, கர்த்தருக்கு விரோதமாய் விபரீதம் பேசி, பசியுள்ள ஆத்துமாவை வெறுமையாக வைத்து, தாகமுள்ளவனுக்குத் தாகந்தீர்க்காதிருக்கிறான்.
	<br /><br />
	7. லோபியின் எத்தனங்களும் பொல்லாதவைகள்; ஏழைகள் நியாயமாய்ப் பேசும்போது, அவன் கள்ளவார்த்தைகளாலே எளியவர்களைக் கெடுக்கும்படி தீவினைகளை யோசிக்கிறான்.
	<br /><br />
	8. தயாளகுணமுள்ளவன் தயாளமானவைகளை யோசிக்கிறான், தயாளமானவைகளிலே நிலைத்தும் இருக்கிறான்.
	<br /><br />
	9. சுகஜீவிகளாகிய ஸ்திரீகளே, எழுந்திருந்து என் வார்த்தைகளைக் கேளுங்கள்; நிர்விசாரமான குமாரத்திகளே, என் வசனத்துக்குச் செவிகொடுங்கள்.
	<br /><br />
	10. நிர்விசாரிகளே, ஒரு வருஷமும் சில நாட்களுமாய்த் தத்தளிப்பீர்கள்; திராட்சப்பலன் அற்றுப்போம்; அறுப்புக்காலம் வராது.
	<br /><br />
	11. சுகஜீவிகளே, நடுங்குங்கள்; நிர்விசாரிகளே, தத்தளியுங்கள்; உடையை உரிந்து களைந்துபோட்டு, அரையில் இரட்டைக் கட்டிக்கொள்ளுங்கள்.
	<br /><br />
	12. செழிப்பான வயல்களினிமித்தமும் கனிதரும் திராட்சச் செடிகளினிமித்தமும் மாரடித்துப் புலம்புவார்கள்.
	<br /><br />
	13. என் ஜனத்தினுடைய நிலத்திலும், களிகூர்ந்திருந்த நகரத்திலுள்ள சந்தோஷம் நிறைந்த எல்லா வீடுகளிலும், முட்செடியும் நெரிஞ்சிலும் முளைக்கும்.
	<br /><br />
	14. அரமனை பாழாக விடப்படும், ஜனம் நிறைந்த நகரம் வெறுமையாகும், மேடும் துருக்கமும் என்றைக்கும் கெபிகளாகும், அவைகள் காட்டுக்கழுதைகள் களிக்கும் இடமாயும் மந்தைகளுக்கு மேய்ச்சலிடமாயும் இருக்கும்.
	<br /><br />
	15. உன்னதத்திலிருந்து நம்மேல் ஆவி ஊற்றப்படுமட்டும் அப்படியே இருக்கும்; அப்பொழுது வனாந்தரம் செழிப்பான வயல்வெளியாகும்; செழிப்பான வயல்வெளி காடாக எண்ணப்படும்.
	<br /><br />
	16. வனாந்தரத்திலே நியாயம் வாசமாயிருக்கும், செழிப்பான வயல்வெளியிலே நீதி தங்கித்தரிக்கும்.
	<br /><br />
	17. நீதியின் கிரியை சமாதானமும், நீதியின் பலன் என்றுமுள்ள அமரிக்கையும் சுகமுமாம்.
	<br /><br />
	18. என் ஜனம் சமாதான தாபரங்களிலும், நிலையான வாசஸ்தலங்களிலும், அமைதியாய்த் தங்கும் இடங்களிலும் குடியிருக்கும்.
	<br /><br />
	19. ஆனாலும் காடு அழிய கல்மழை பெய்யும், அந்த நகரம் மகா தாழ்வாய்த் தாழ்ந்துபோம்.
	<br /><br />
	20. மாடுகளையும் கழுதைகளையும் நடத்திக்கொண்டுபோய், நீர்வளம் பொருந்திய இடங்களிலெல்லாம் விதை விதைக்கிற நீங்கள் பாக்கியவான்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah32