import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 1 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 1 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 1 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தேவனுடைய சித்தத்தினாலே இயேசுகிறிஸ்துவின் அப்போஸ்தலனாகும்படி அழைக்கப்பட்டவனாகிய பவுலும், சகோதரனாகிய சொஸ்தெனேயும்,
	<br /><br />
	2. கொரிந்துவிலே கிறிஸ்து இயேசுவுக்குள் பரிசுத்தமாக்கப்பட்டவர்களாயும், பரிசுத்தவான்களாகும்படி அழைக்கப்பட்டவர்களாயுமிருக்கிற தேவனுடைய சபைக்கும், எங்களுக்கும் தங்களுக்கும் ஆண்டவராயிருக்கிற நம்முடைய கர்த்தராகிய இயேசு கிறிஸ்துவின் நாமத்தை எங்கும் தொழுதுகொள்ளுகிற அனைவருக்கும் எழுதுகிறதாவது:
	<br /><br />
	3. நம்முடைய பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	4. கிறிஸ்துவைப்பற்றிய சாட்சி உங்களுக்குள்ளே ஸ்திரப்படுத்தப்பட்டபடியே,
	<br /><br />
	5. நீங்கள் இயேசுகிறிஸ்துவுக்குள்ளாய் எல்லா உபதேசத்திலும் எல்லா அறிவிலும், மற்றெல்லாவற்றிலும், சம்பூரணமுள்ளவர்களாக்கப்பட்டிருக்கிறபடியால்,
	<br /><br />
	6. அவர் மூலமாய் உங்களுக்கு அளிக்கப்பட்ட தேவகிருபைக்காக, நான் உங்களைக்குறித்து எப்பொழுதும் என் தேவனை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	7. அப்படியே நீங்கள் யாதொரு வரத்திலும் குறைவில்லாதவர்களாய், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து வெளிப்படுவதற்குக் காத்திருக்கிறீர்கள்.
	<br /><br />
	8. நம்முடைய கர்த்தராகிய இயேசு கிறிஸ்துவின் நாளிலே நீங்கள் குற்றஞ்சாட்டப்படாதவர்களாயிருக்கும்படி முடிவுபரியந்தம் அவர் உங்களை ஸ்திரப்படுத்துவார்.
	<br /><br />
	9. தம்முடைய குமாரனும் நம்முடைய கர்த்தருமாயிருக்கிற இயேசுகிறிஸ்துவுடனே ஐக்கியமாயிருப்பதற்கு உங்களை அழைத்த தேவன் உண்மையுள்ளவர்.
	<br /><br />
	10. சகோதரரே, நீங்களெல்லாரும் ஒரே காரியத்தைப் பேசவும், பிரிவினைகளில்லாமல் ஏகமனதும் ஏகயோசனையும் உள்ளவர்களாய்ச் சீர்பொருந்தியிருக்கவும் வேண்டுமென்று, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் நாமத்தினாலே உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	11. ஏனெனில், என் சகோதரரே, உங்களுக்குள்ளே வாக்குவாதங்கள் உண்டென்று குலோவேயாளின் வீட்டாரால் உங்களைக்குறித்து எனக்கு அறிவிக்கப்பட்டது.
	<br /><br />
	12. உங்களில் சிலர்: நான் பவுலைச் சேர்ந்தவனென்றும், நான் அப்பொல்லோவைச் சேர்ந்தவனென்றும், நான் கேபாவைச் சேர்ந்தவனென்றும், நான் கிறிஸ்துவைச் சேர்ந்தவனென்றும் சொல்லுகிறபடியால், நான் இப்படிச் சொல்லுகிறேன்.
	<br /><br />
	13. கிறிஸ்து பிரிந்திருக்கிறாரா? பவுலா உங்களுக்காகச் சிலுவையிலறையப்பட்டான்? பவுலின் நாமத்தினாலேயா ஞானஸ்நானம் பெற்றீர்கள்?
	<br /><br />
	14. என் நாமத்தினாலே ஞானஸ்நானங் கொடுத்தேனென்று ஒருவனும் சொல்லாதபடிக்கு,
	<br /><br />
	15. நான் கிறிஸ்புவுக்கும் காயுவுக்குமேயன்றி, உங்களில் வேறொருவனுக்கும் ஞானஸ்நானங் கொடுக்கவில்லை; இதற்காகத் தேவனை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	16. ஸ்தேவானுடைய வீட்டாருக்கும் நான் ஞானஸ்நானங் கொடுத்ததுண்டு. இதுவுமல்லாமல் இன்னும் யாருக்காவது நான் ஞானஸ்நானங் கொடுத்தேனோ இல்லையோ அறியேன்.
	<br /><br />
	17. ஞானஸ்நானத்தைக் கொடுக்கும்படி கிறிஸ்து என்னை அனுப்பவில்லை; சுவிசேஷத்தைப் பிரசங்கிக்கவே அனுப்பினார்; கிறிஸ்துவின் சிலுவை வீணாய்ப்போகாதபடிக்கு, சாதுரிய ஞானமில்லாமல் பிரசங்கிக்கவே அனுப்பினார்.
	<br /><br />
	18. சிலுவையைப்பற்றிய உபதேசம் கெட்டுப்போகிறவர்களுக்குப் பைத்தியமாயிருக்கிறது, இரட்சிக்கப்படுகிற நமக்கோ அது தேவபெலனாயிருக்கிறது.
	<br /><br />
	19. அந்தப்படி ஞானிகளுடைய ஞானத்தை நான் அழித்து, புத்திசாலிகளுடைய புத்தியை அவமாக்குவேன் என்று எழுதியிருக்கிறது.
	<br /><br />
	20. ஞானி எங்கே? வேதபாரகன் எங்கே? இப்பிரபஞ்சத் தர்க்கசாஸ்திரி எங்கே? இவ்வுலகத்தின் ஞானத்தைத் தேவன் பைத்தியமாக்கவில்லையா?
	<br /><br />
	21. எப்படியெனில், தேவஞானத்துக்கேற்றபடி உலகமானது சுயஞானத்தினாலே தேவனை அறியாதிருக்கையில், பைத்தியமாகத் தோன்றுகிற பிரசங்கத்தினாலே விசுவாசிகளை இரட்சிக்கத் தேவனுக்குப் பிரியமாயிற்று.
	<br /><br />
	22. யூதர்கள் அடையாளத்தைக் கேட்கிறார்கள், கிரேக்கர் ஞானத்தைத் தேடுகிறார்கள்;
	<br /><br />
	23. நாங்களோ சிலுவையில் அறையப்பட்ட கிறிஸ்துவைப் பிரசங்கிக்கிறோம்; அவர் யூதருக்கு இடறலாயும் கிரேக்கருக்குப் பைத்தியமாயும் இருக்கிறார்.
	<br /><br />
	24. ஆகிலும் யூதரானாலும் கிரேக்கரானாலும் எவர்கள் அழைக்கப்பட்டிருக்கிறார்களோ அவர்களுக்குக் கிறிஸ்து தேவபெலனும் தேவஞானமுமாயிருக்கிறார்.
	<br /><br />
	25. இந்தப்படி, தேவனுடைய பைத்தியம் என்னப்படுவது மனுஷருடைய ஞானத்திலும் அதிக ஞானமாயிருக்கிறது; தேவனுடைய பலவீனம் என்னப்படுவது மனுஷருடைய பலத்திலும் அதிக பலமாயிருக்கிறது.
	<br /><br />
	26. எப்படியெனில், சகோதரரே, நீங்கள் அழைக்கப்பட்ட அழைப்பைப் பாருங்கள்; மாம்சத்தின்படி ஞானிகள் அநேகரில்லை, வல்லவர்கள் அநேகரில்லை, பிரபுக்கள் அநேகரில்லை.
	<br /><br />
	27. ஞானிகளை வெட்கப்படுத்தும்படி தேவன் உலகத்தில் பைத்தியமானவைகளைத் தெரிந்துகொண்டார்; பலமுள்ளவைகளை வெட்கப்படுத்தும்படி தேவன் உலகத்தில் பலவீனமானவைகளைத் தெரிந்துகொண்டார்.
	<br /><br />
	28. உள்ளவைகளை அவமாக்கும்படி, உலகத்தின் இழிவானவைகளையும், அற்பமாய் எண்ணப்பட்டவைகளையும், இல்லாதவைகளையும், தேவன் தெரிந்துகொண்டார்.
	<br /><br />
	29. மாம்சமான எவனும் தேவனுக்கு முன்பாகப் பெருமைபாராட்டாதபடிக்கு அப்படிச் செய்தார்.
	<br /><br />
	30. அந்தப்படி, நீங்கள் அவராலே கிறிஸ்து இயேசுவுக்குட்பட்டிருக்கிறீர்கள். எழுதியிருக்கிறபடி மேன்மைபாராட்டுகிறவன் கர்த்தரைக்குறித்தே மேன்மைபாராட்டத்தக்கதாக,
	<br /><br />
	31. அவரே தேவனால் நமக்கு ஞானமும் நீதியும் பரிசுத்தமும் மீட்புமானார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians1