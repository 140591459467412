import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial48 = () => {
  const verseNumber = 48;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 48 </title>
	<meta
          name="description"
          content="Ezekiel 48 | எசேக்கியேல்  48 |
          Ezekiel 48 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கோத்திரங்களின் நாமங்களாவன: வடமுனைதுவக்கி ஆமாத்துக்குப்போகிற எத்லோன் வழியின் ஓரத்துக்கும், ஆத்சார்ஏனானுக்கும், ஆமாத்தருகே வடக்கேயிருக்கிற தமஸ்குவின் எல்லைக்கும் உள்ளாகக் கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் தாணுக்கு ஒரு பங்கும்,
	<br /><br />
	2. தாணின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் ஆசேருக்கு ஒரு பங்கும்,
	<br /><br />
	3. ஆசேரின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் நப்தலிக்கு ஒரு பங்கும்,
	<br /><br />
	4. நப்தலியின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் மனாசேக்கு ஒரு பங்கும்,
	<br /><br />
	5. மனாசேயின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் எப்பிராயீமுக்கு ஒரு பங்கும்,
	<br /><br />
	6. எப்பிராயீமின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் ரூபனுக்கு ஒரு பங்கும்,
	<br /><br />
	7. ரூபனின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் யூதாவுக்கு ஒரு பங்கும் உண்டாவதாக.
	<br /><br />
	8. யூதாவின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் நீங்கள் அர்ப்பிதமாக்கவேண்டிய பங்கு இருக்கும்; அது, இருபத்தையாயிரங்கோல் அகலமும், கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் இருக்கிற பங்குகளில் ஒவ்வொன்றுக்கும் சரியான நீளமுமாம்; பரிசுத்த ஸ்தலம் அதின் நடுவிலே இருப்பதாக.
	<br /><br />
	9. இதிலே கர்த்தருக்கு நீங்கள் அர்ப்பிதமாக்கவேண்டிய பங்கு இருபத்தையாயிரங்கோல் நீளமும், பதினாயிரங்கோல் அகலமுமாயிருப்பதாக.
	<br /><br />
	10. வடக்கே இருபத்தையாயிரங்கோல் நீளமும், மேற்கே பதினாயிரங்கோல் அகலமும், கிழக்கே பதினாயிரங்கோல் அகலமும், தெற்கே இருபத்தையாயிரங்கோல் நீளமுமாகிய இந்தப் பரிசுத்த அர்ப்பிதநிலமானது ஆசாரியருடையதாயிருக்கும்; கர்த்தருடைய பரிசுத்த ஸ்தலம் அதின் நடுவிலே இருப்பதாக.
	<br /><br />
	11. இஸ்ரவேல் புத்திரர் வழிதப்பிப்போகையில், லேவியர் வழிதப்பிப்போனதுபோல வழிதப்பிப்போகாமல், என் காவலைக் காத்துக்கொண்ட சாதோக்கின் புத்திரராகிய பரிசுத்தமாக்கப்பட்ட ஆசாரியர்களுக்கு அது உரியதாகும்.
	<br /><br />
	12. அப்படியே தேசத்தில் அர்ப்பிதமாக்கப்படுகிறதிலே மகா பரிசுத்தமான பங்கு அவர்களுக்கு லேவியருடைய எல்லையருகே இருப்பதாக.
	<br /><br />
	13. ஆசாரியரின் எல்லைக்கு எதிராக லேவியர் அடையும் பங்கு இருபத்தையாயிரங்கோல் நீளமும், பதினாயிரங்கோல் அகலமுமாயிருக்கவேண்டும்; நீளம் இருபத்தையாயிரங்கோலும், அகலம் பதினாயிரங்கோலுமாயிருப்பதாக.
	<br /><br />
	14. அவர்கள் அதில் ஒன்றையும் விற்கவும் தேசத்தின் முதல் விளைவை மாற்றவும் மற்றவர்களுக்குக் கொடுக்கவும் தகாது; அது கர்த்தருக்குப் பரிசுத்தமானது.
	<br /><br />
	15. இருபத்தையாயிரங்கோலுக்கு எதிராக அகலத்தில் மீதியாயிருக்கும் ஐயாயிரங்கோலோவென்றால், பரிசுத்தமாயிராமல், குடியேறும் நகரத்துக்கும் வெளிநிலங்களுக்கும் விடவேண்டும்; நகரம் அதின் நடுவில் இருப்பதாக.
	<br /><br />
	16. அதின் அளவுகளாவன: வடபுறம் நாலாயிரத்தைந்நூறு கோலும், தென்புறம் நாலாயிரத்தைந்நூறு கோலும், கீழ்ப்புறம் நாலாயிரத்தைந்நூறு கோலும், மேற்புறம் நாலாயிரத்தைந்நூறு கோலுமாம்.
	<br /><br />
	17. நகரத்தின் வெளிநிலங்கள் வடக்கே இருநூற்றைம்பது கோலும், தெற்கே இருநூற்றைம்பது கோலும், கிழக்கே இருநூற்றைம்பது கோலும், மேற்கே இருநூற்றைம்பது கோலுமாயிருப்பதாக.
	<br /><br />
	18. பரிசுத்த அர்ப்பிதநிலத்துக்கு எதிராக நீளத்தில் மீதியானது கிழக்கே பதினாயிரங்கோலும் மேற்கே பதினாயிரங்கோலுமாம்; அது பரிசுத்த அர்ப்பிதநிலத்துக்கு எதிராயிருக்கும்; அதின் வருமானம் நகரத்திற்காக ஊழியஞ்செய்கிறவர்களுக்கு ஆகாரமாயிருப்பதாக.
	<br /><br />
	19. இஸ்ரவேலின் சகல கோத்திரங்களிலுமிருந்து குறிக்கப்பட்ட சிலர் நகரத்திற்காகப் பணிவிடை செய்வார்கள்.
	<br /><br />
	20. அர்ப்பிதநிலமனைத்தும் இருபத்தையாயிரங்கோல் நீளமும், இருபத்தையாயிரங்கோல் அகலமுமாய் இருக்கக்கடவது; பட்டணத்தின் காணி உட்பட இந்தப் பரிசுத்த அர்ப்பிதநிலம் சதுரமாய் இருக்கவேண்டும்.
	<br /><br />
	21. பரிசுத்த அர்ப்பிதநிலத்துக்கும் நகரத்தின் காணிக்கும் இந்தப்புறத்திலும் அந்தப்புறத்திலும், அர்ப்பிதநிலத்தினுடைய இருபத்தையாயிரங்கோலின் முன்பாகக் கிழக்கு எல்லைமட்டுக்கும், மேற்கிலே இருபத்தையாயிரங்கோலின் முன்பாக மேற்கு எல்லைமட்டுக்கும் மீதியாயிருப்பது அதிபதியினுடையது; பங்குகளுக்கு எதிரான அது அதிபதியினுடையதாயிருப்பதாக; அதற்கு நடுவாகப் பரிசுத்த அர்ப்பிதநிலமும் ஆலயத்தின் பரிசுத்த ஸ்தலமும் இருக்கும்.
	<br /><br />
	22. அதிபதியினுடையதற்கு நடுவேயிருக்கும் லேவியரின் காணிதுவக்கியும் நகரத்தின் காணிதுவக்கியும், யூதாவின் எல்லைக்கும் பென்யமீனின் எல்லைக்கும் நடுவேயிருக்கிறது அதிபதியினுடையது.
	<br /><br />
	23. மற்றக் கோத்திரங்களுக்கு உண்டாகும் பங்குகளாவன: கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் பென்யமீனுக்கு ஒரு பங்கும்,
	<br /><br />
	24. பென்யமீன் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் சிமியோனுக்கு ஒரு பங்கும்,
	<br /><br />
	25. சிமியோனின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் இசக்காருக்கு ஒரு பங்கும்,
	<br /><br />
	26. இசக்காரின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் செபுலோனுக்கு ஒரு பங்கும்,
	<br /><br />
	27. செபுலோனின் எல்லையருகே கீழ்த்திசை துவக்கி மேற்றிசைமட்டும் காத்துக்கு ஒரு பங்கும் உண்டாயிருப்பதாக.
	<br /><br />
	28. காத்தின் எல்லையருகே தென்மூலையாகிய தெற்கு எல்லை, தாமார்துவக்கி காதேசிலுள்ள சண்டைமூட்டுதலின் தண்ணீர்கள் மட்டாகவும் பெரிய சமுத்திரமட்டாகவும் போகும்.
	<br /><br />
	29. சுதந்தரிக்கும்படி இதுவே நீங்கள் இஸ்ரவேல் கோத்திரங்களுக்குச் சீட்டுப்போட்டுப் பங்கிடும் தேசம், இவைகளே அவர்களின் பங்குகள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	30. நகரத்தினின்று புறப்படும் வழிகளாவன: வடபுறத்திலே நாலாயிரத்தைந்நூறு கோலாகிய அளவுண்டாயிருக்கும்.
	<br /><br />
	31. நகரத்தின் வாசல்கள், இஸ்ரவேல் கோத்திரங்களுடைய நாமங்களின்படியே பெயர் பெறக்கடவது; வடக்கே ரூபனுக்கு ஒரு வாசல், யூதாவுக்கு ஒரு வாசல், லேவிக்கு ஒரு வாசல், ஆக மூன்று வாசல்கள் இருப்பதாக.
	<br /><br />
	32. கீழ்ப்புறத்திலே நாலாயிரத்தைந்நூறு கோல், அதில் யோசேப்புக்கு ஒரு வாசல், பென்யமீனுக்கு ஒரு வாசல், தாணுக்கு ஒரு வாசல், ஆக மூன்று வாசல்கள் இருப்பதாக.
	<br /><br />
	33. தென்புறத்திலே நாலாயிரத்தைந்நூறு கோல், அதில் சிமியோனுக்கு ஒரு வாசல், இசக்காருக்கு ஒரு வாசல், செபுலோனுக்கு ஒரு வாசல், ஆக மூன்று வாசல்கள் இருப்பதாக.
	<br /><br />
	34. மேற்புறத்திலே நாலாயிரத்தைந்நூறு கோல், அதில் காத்துக்கு ஒரு வாசல், ஆசேருக்கு ஒரு வாசல், நப்தலிக்கு ஒரு வாசல், ஆக மூன்று வாசல்கள் இருப்பதாக.
	<br /><br />
	35. சுற்றிலும் அதின் அளவு பதினெண்ணாயிரங்கோலாகும்; அந்நாள் முதல் நகரம் யேகோவா ஷம்மா என்னும் பெயர்பெறும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial48