import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const James5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title> The Prayer of Faith James in Tamil Bible Verse | யாக்கோபு - 5 </title>
	<meta
          name="description"
          content="The Prayer of Faith | James Chapter - 5 in Tamil Bible Verse | யாக்கோபு அதிகாரம்-5 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil Verse"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
<h2>
* விசுவாசத்தின் பிரார்த்தனை *
			</h2>
<br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. ஐசுவரியவான்களே, கேளுங்கள், உங்கள்மேல் வரும் நிர்ப்பந்தங்களினிமித்தம் அலறி அழுங்கள்.
	<br /><br />
	2. உங்கள் ஐசுவரியம் அழிந்து, உங்கள் வஸ்திரங்கள் பொட்டரித்துப்போயின.
	<br /><br />
	3. உங்கள் பொன்னும் வெள்ளியும் துருப்பிடித்தது; அவைகளிலுள்ள துரு உங்களுக்கு விரோதமாகச் சாட்சியாயிருந்து, அக்கினியைப்போல உங்கள் மாம்சத்தைத் தின்னும். கடைசிநாட்களிலே பொக்கிஷத்தைச் சேர்த்தீர்கள்.
	<br /><br />
	4. இதோ, உங்கள் வயல்களை அறுத்த வேலைக்காரருடைய கூலி உங்களால் அநியாயமாய்ப் பிடிக்கப்பட்டுக் கூக்குரலிடுகிறது; அறுத்தவர்களுடைய கூக்குரல் சேனைகளுடைய கர்த்தரின் செவிகளில் பட்டது.
	<br /><br />
	5. பூமியிலே நீங்கள் சம்பிரமமாய் வாழ்ந்து, சுகபோகத்தில் உழன்றீர்கள்; கொழுத்தவைகளை அடிக்கும் நாளில் நடக்கிறதுபோல உங்கள் இருதயங்களைப் போஷித்தீர்கள்.
	<br /><br />
	6. நீதிமானை நீங்கள் ஆக்கினைக்குள்ளாகத் தீர்த்துக் கொலைசெய்தீர்கள்; அவன் உங்களோடே எதிர்த்துநிற்கவில்லை.
	<br /><br />
	7. இப்படியிருக்க, சகோதரரே, கர்த்தர் வருமளவும் நீடிய பொறுமையாயிருங்கள். இதோ, பயிரிடுகிறவன் பூமியின் நற்பலனை அடையவேண்டுமென்று, முன்மாரியும் பின்மாரியும் வருமளவும், நீடிய பொறுமையோடே காத்திருக்கிறான்.
	<br /><br />
	8. நீங்களும் நீடிய பொறுமையோடிருந்து, உங்கள் இருதயங்களை ஸ்திரப்படுத்துங்கள்; கர்த்தரின் வருகை சமீபமாயிருக்கிறதே.
	<br /><br />
	9. சகோதரரே, நீங்கள் நியாயந்தீர்க்கப்படாதபடிக்கு ஒருவருக்கொருவர் விரோதமாய் முறையிடாதிருங்கள்; இதோ, நியாயாதிபதி வாசற்படியில் நிற்கிறார்.
	<br /><br />
	10. என் சகோதரரே, கர்த்தருடைய நாமத்தினாலே பேசின தீர்க்கதரிசிகளைத் துன்பப்படுதலுக்கும் நீடிய பொறுமைக்கும் திருஷ்டாந்தமாக வைத்துக்கொள்ளுங்கள்.
	<br /><br />
	11. இதோ, பொறுமையாயிருக்கிறவர்களைப் பாக்கியவான்களென்கிறோமே! யோபின் பொறுமையைக்குறித்துக் கேள்விப்பட்டிருக்கிறீர்கள்; கர்த்தருடைய செயலின் முடிவையும் கண்டிருக்கிறீர்கள்; கர்த்தர் மிகுந்த உருக்கமும் இரக்கமுமுள்ளவராயிருக்கிறாரே.
	<br /><br />
	12. விசேஷமாய், என் சகோதரரே, வானத்தின்பேரிலாவது, பூமியின்பேரிலாவது, வேறெந்த ஆணையினாலாவது சத்தியம்பண்ணாதிருங்கள்; நீங்கள் ஆக்கினைக்குள்ளாகத் தீர்க்கப்படாதபடிக்கு உள்ளதை உள்ளதென்றும், இல்லதை இல்லதென்றும் சொல்லக்கடவீர்கள்.
	<br /><br />
	13. உங்களில் ஒருவன் துன்பப்பட்டால் ஜெபம்பண்ணக்கடவன்; ஒருவன் மகிழ்ச்சியாயிருந்தால் சங்கீதம் பாடக்கடவன்.
	<br /><br />
	14. உங்களில் ஒருவன் வியாதிப்பட்டால், அவன் சபையின் மூப்பர்களை வரவழைப்பானாக; அவர்கள் கர்த்தருடைய நாமத்தினாலே அவனுக்கு எண்ணெய்பூசி, அவனுக்காக ஜெபம்பண்ணக்கடவர்கள்.
	<br /><br />
	15. அப்பொழுது விசுவாசமுள்ள ஜெபம் பிணியாளியை இரட்சிக்கும்; கர்த்தர் அவனை எழுப்புவார்; அவன் பாவஞ்செய்தவனானால் அது அவனுக்கு மன்னிக்கப்படும்.
	<br /><br />
	16. நீங்கள் சொஸ்தமடையும்படிக்கு, உங்கள் குற்றங்களை ஒருவருக்கொருவர் அறிக்கையிட்டு, ஒருவருக்காக ஒருவர் ஜெபம்பண்ணுங்கள். நீதிமான் செய்யும் ஊக்கமான வேண்டுதல் மிகவும் பெலனுள்ளதாயிருக்கிறது.
	<br /><br />
	17. எலியா என்பவன் நம்மைப்போலப்பாடுள்ள மனுஷனாயிருந்தும், மழைபெய்யாதபடிக்குக் கருத்தாய் ஜெபம்பண்ணினான், அப்பொழுது மூன்றுவருஷமும் ஆறுமாதமும் பூமியின்மேல் மழை பெய்யவில்லை.
	<br /><br />
	18. மறுபடியும் ஜெபம்பண்ணினான், அப்பொழுது வானம் மழையைப் பொழிந்தது, பூமி தன் பலனைத் தந்தது.
	<br /><br />
	19. சகோதரரே, உங்களில் ஒருவன் சத்தியத்தைவிட்டு விலகி மோசம்போகும்போது, மற்றொருவன் அவனைத் திருப்பினால்,
	<br /><br />
	20. தப்பிப்போன மார்க்கத்தினின்று பாவியைத் திருப்புகிறவன் ஒரு ஆத்துமாவை மரணத்தினின்று இரட்சித்து, திரளான பாவங்களை மூடுவானென்று அறியக்கடவன்.
</p>


      {/* End of verse */} 

	  <br /><br />
<h3>
*  யாக்கோபு 5 தொழிலாளர்களின் செலவில் செல்வத்தை பதுக்கி வைப்பது பற்றிய எச்சரிக்கையுடன் தொடங்குகிறது. துன்பத்தில் பொறுமையாக இருக்கும்படி அவர் நம்மை ஊக்குவிக்கிறார், ஏனென்றால் கர்த்தர் நம்மை சோதனைகளின் மூலம் கொண்டு வந்து எல்லாவற்றையும் சரி செய்ய முடியும். யாக்கோபு ஜெபம் பற்றிய அறிவுறுத்தல்களுடன் கடிதத்தை மூடுகிறார். விசுவாசத்தோடு ஜெபிக்கும்படி நாம் அறிவுறுத்தப்படுகிறோம், ஏனென்றால் "நீதிமானுடைய ஜெபம் வல்லமையும் பயனுள்ளதும் ஆகும்" (வச. 5:16).
<br />
<br />
நீங்கள் பார்க்க முடியும் என யாக்கோபு புத்தகம் ஒரு விசுவாசி என்ன நம்பிக்கை வாழ நடைமுறை ஆலோசனை கொடுக்கிறது. நீங்கள் யாக்கோபு புத்தகத்தை வாசிக்கும்போதும் படிக்கும்போதும் ஆசீர்வதிக்கப்பட்டவர்களாகவும், ஊக்கமளிப்பவர்களாகவும் இருப்பீர்களாக. *
			</h3>
<br />
      </div>
    </div>
    </>
  );
}

export default James5