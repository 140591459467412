import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 7 | Tamil Bible Verse | வெளிப்படுத்தல் - 7 </title>
	<meta
          name="description"
          content="The Book of Revelation - 7 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 7 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* 1,44,000 பேர் சீல் வைக்கப்பட்டனர். திரளான மக்கள், "இரட்சிப்பு எங்கள் கடவுளுக்கு உரியது" என்று கூக்குரலிட்டனர். ஒரு மூப்பர் சொன்னார், "கடவுள் இவர்களின் கண்ணீரையும் துடைப்பார்". *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. இவைகளுக்குப்பின்பு, பூமியின் நான்கு திசைகளிலும் நான்கு தூதர்கள் நின்று, பூமியின்மேலாவது, சமுத்திரத்தின்மேலாவது, ஒரு மரத்தின்மேலாவது, காற்று அடியாதபடிக்கு, பூமியின் நான்கு காற்றுகளையும் பிடித்திருக்கக்கண்டேன்.
	<br /><br />
	2. ஜீவனுள்ள தேவனுடைய முத்திரைக் கோலையுடைய வேறொரு தூதன் சூரியன் உதிக்குந்திசையிலிருந்து ஏறிவரக்கண்டேன்; அவன், பூமியையும் சமுத்திரத்தையும் சேதப்படுத்துகிறதற்கு அதிகாரம் பெற்ற அந்த நான்கு தூதரையும் நோக்கி:
	<br /><br />
	3. நாம் நமது தேவனுடைய ஊழியக்காரரின் நெற்றிகளில் முத்திரைபோட்டுத் தீருமளவும் பூமியையும் சமுத்திரத்தையும் மரங்களையும் சேதப்படுத்தாதிருங்கள் என்று மகா சத்தமிட்டுக் கூப்பிட்டான்.
	<br /><br />
	4. முத்திரைபோடப்பட்டவர்களின் தொகையைச் சொல்லக்கேட்டேன்; இஸ்ரவேல் புத்திரருடைய சகல கோத்திரங்களிலும் முத்திரைபோடப்பட்டவர்கள் இலட்சத்து நாற்பத்து நாலாயிரம்பேர்.
	<br /><br />
	5. யூதா கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். ரூபன் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். காத் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம்.
	<br /><br />
	6. ஆசேர் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். நப்தலி கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். மனாசே கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம்.
	<br /><br />
	7. சிமியோன் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். லேவி கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். இசக்கார் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம்.
	<br /><br />
	8. செபுலோன் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். யோசேப்பு கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம். பென்யமீன் கோத்திரத்தில் முத்திரைபோடப்பட்டவர்கள் பன்னீராயிரம்.
	<br /><br />
	9. இவைகளுக்குப்பின்பு, நான் பார்த்தபோது, இதோ, சகல ஜாதிகளிலும் கோத்திரங்களிலும் ஜனங்களிலும் பாஷைக்காரரிலுமிருந்து வந்ததும், ஒருவனும் எண்ணக்கூடாததுமான திரளான கூட்டமாகிய ஜனங்கள், வெள்ளை அங்கிகளைத் தரித்து, தங்கள் கைகளில் குருத்தோலைகளைப் பிடித்து, சிங்காசனத்திற்கு முன்பாகவும் ஆட்டுக்குட்டியானவருக்கு முன்பாகவும் நிற்கக்கண்டேன்.
	<br /><br />
	10. அவர்கள் மகா சத்தமிட்டு: இரட்சிப்பின் மகிமை சிங்காசனத்தின்மேல் வீற்றிருக்கிற எங்கள் தேவனுக்கும் ஆட்டுக்குட்டியானவருக்கும் உண்டாவதாக என்று ஆர்ப்பரித்தார்கள்.
	<br /><br />
	11. தூதர்கள் யாவரும் சிங்காசனத்தையும் மூப்பர்களையும் நான்கு ஜீவன்களையும் சூழநின்று, சிங்காசனத்திற்குமுன்பாக முகங்குப்புற விழுந்து, தேவனைத் தொழுதுகொண்டு:
	<br /><br />
	12. ஆமென், எங்கள் தேவனுக்குத் துதியும் மகிமையும் ஞானமும் ஸ்தோத்திரமும் கனமும் வல்லமையும் பெலனும் சதாகாலங்களிலும் உண்டாவதாக; ஆமென், என்றார்கள்.
	<br /><br />
	13. அப்பொழுது, மூப்பர்களில் ஒருவன் என்னை நோக்கி: வெள்ளை அங்கிகளைத் தரித்திருக்கிற இவர்கள் யார்? எங்கேயிருந்து வந்தார்கள்? என்று கேட்டான்.
	<br /><br />
	14. அதற்கு நான்: ஆண்டவனே, அது உமக்கே தெரியும் என்றேன். அப்பொழுது அவன்: இவர்கள் மிகுந்த உபத்திரவத்திலிருந்து வந்தவர்கள்; இவர்கள் தங்கள் அங்கிகளை ஆட்டுக்குட்டியானவருடைய இரத்தத்திலே தோய்த்து வெளுத்தவர்கள்.
	<br /><br />
	15. ஆனபடியால், இவர்கள் தேவனுடைய சிங்காசனத்திற்கு முன்பாக இருந்து, இரவும் பகலும் அவருடைய ஆலயத்திலே அவரைச் சேவிக்கிறார்கள்; சிங்காசனத்தின்மேல் வீற்றிருக்கிறவர் இவர்களுக்குள்ளே வாசமாயிருப்பார்.
	<br /><br />
	16. இவர்கள் இனி பசியடைவதுமில்லை, இனி தாகமடைவதுமில்லை; வெயிலாவது உஷ்ணமாவது இவர்கள்மேல் படுவதுமில்லை.
	<br /><br />
	17. சிங்காசனத்தின் மத்தியிலிருக்கிற ஆட்டுக்குட்டியானவரே இவர்களை மேய்த்து, இவர்களை ஜீவத்தண்ணீருள்ள ஊற்றுகளண்டைக்கு நடத்துவார்; தேவன்தாமே இவர்களுடைய கண்ணீர் யாவையும் துடைப்பார் என்றான்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* 1,44,000 பேர் பழங்குடியினரிடமிருந்து சீல் வைக்கப்பட்டனர். திரளான மக்கள், "இரட்சிப்பு எங்கள் கடவுளுக்கு உரியது" என்று கூக்குரலிட்டனர். ஒரு பெரியவர் சொன்னார், "கடவுள் ஒவ்வொரு கண்ணீரையும் துடைப்பார்".  *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation7