import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 8 </title>
	<meta
          name="description"
          content="Amos 8 | ஆமோஸ் 8 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு கர்த்தராகிய ஆண்டவர் எனக்குக் காண்பித்ததாவது: இதோ, பழுத்த பழங்களுள்ள ஒரு கூடை இருந்தது.
	<br /><br />
	2. அவர்: ஆமோசே, நீ என்னத்தைக் காண்கிறாய் என்று கேட்டார்? பழுத்த பழங்களுள்ள ஒரு கூடையைக் காண்கிறேன் என்றேன். அப்பொழுது கர்த்தர் என்னை நோக்கி: என் ஜனமாகிய இஸ்ரவேலுக்கு முடிவுகாலம் வந்தது; இனி அவர்களை மன்னிக்கமாட்டேன்.
	<br /><br />
	3. அந்நாளிலே தேவாலயப்பாட்டுகள் அலறுதலாக மாறும்; எல்லா இடத்திலும் திரளான பிரேதங்கள் புலம்பலில்லாமல் எறிந்துவிடப்படும் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	4. தேசத்தில் சிறுமைப்பட்டவர்களை ஒழியப்பண்ண, எளியவர்களை விழுங்கி:
	<br /><br />
	5. நாங்கள் மரக்காலைக் குறைத்து, சேக்கல் நிறையை அதிகமாக்கி, கள்ளத்தராசினால் வஞ்சித்து, தரித்திரரைப் பணத்துக்கும், எளியவர்களை ஒரு ஜோடு பாதரட்சைக்கும் கொள்ளும்படிக்கும், தானியத்தின் பதரை விற்கும்படிக்கும்,
	<br /><br />
	6. நாங்கள் தவசம் விற்கத்தக்கதாக மாதப்பிறப்பும், நாங்கள் தானியத்தின் பண்டசாலைகளைத் திறக்கத்தக்கதாக ஓய்வுநாளும் எப்போது முடியும் என்று சொல்லுகிறவர்களே, இதைக் கேளுங்கள்.
	<br /><br />
	7. அவர்கள் செய்கைகளையெல்லாம் நான் ஒருபோதும் மறப்பதில்லையென்று கர்த்தர் யாக்கோபுடைய மகிமையின்பேரில் ஆணையிட்டார்.
	<br /><br />
	8. இதினிமித்தம் தேசம் அதிரவும், அதின் குடிகள் எல்லாம் துக்கிக்கவும், எங்கும் நதிகளாய்ப் புரண்டோடவும், எகிப்தின் ஆற்றுவெள்ளத்தைப்போல் அடித்து, பெருவெள்ளமாகவும் வேண்டாமோ?
	<br /><br />
	9. அந்நாளிலே நான் மத்தியானத்திலே சூரியனை அஸ்தமிக்கப்பண்ணி, பட்டப்பகலிலே தேசத்தை அந்தகாரப்படுத்தி,
	<br /><br />
	10. உங்கள் பண்டிகைகளைத் துக்கிப்பாகவும், உங்கள் பாட்டுகளையெல்லாம் புலம்பலாகவும் மாறப்பண்ணி, சகல அரைகளிலும் இரட்டையும், சகல தலைகளிலும் மொட்டையையும் வருவித்து, அவர்களுடைய துக்கிப்பை ஒரே பிள்ளைக்காகத் துக்கிக்கிற துக்கிப்புக்குச் சமானமாக்கி, அவர்களுடைய முடிவைக் கசப்பான நாளாக்குவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	11. இதோ, நான் தேசத்தின்மேல் பஞ்சத்தை அனுப்பும் நாட்கள் வரும்; ஆகாரக் குறைவினால் உண்டாகிய பஞ்சமல்ல, ஜலக்குறைவினால் உண்டாகிய தாகமுமல்ல, கர்த்தருடைய வசனம் கேட்கக் கிடையாத பஞ்சத்தை அனுப்புவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	12. அப்பொழுது அவர்கள் கர்த்தருடைய வசனத்தைத் தேட ஒரு சமுத்திரந்தொடங்கி மறு சமுத்திரமட்டும், வடதிசைதொடங்கிக் கீழ்த்திசைமட்டும் அலைந்து திரிந்தும் அதைக் கண்டடையாமற் போவார்கள்.
	<br /><br />
	13. அந்நாளிலே சௌந்தரியமுள்ள கன்னிகைகளும் வாலிபரும் தாகத்தினால் சோர்ந்துபோவார்கள்.
	<br /><br />
	14. தாணே! உன் தேவனுடைய ஜீவனைக்கொண்டும், பெயெர்செபா மார்க்கத்தின் தேவனுடைய ஜீவனைக்கொண்டும் என்று சொல்லி, சமாரியாவின் தோஷத்தின்மேல் ஆணையிடுகிறவர்கள் விழுவார்கள்; இனி ஒருபோதும் எழுந்திருக்கமாட்டார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos8