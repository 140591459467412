import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 20 </title>
	<meta
          name="description"
          content="Matthew 20 | மத்தேயு 20 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பரலோகராஜ்யம் வீட்டெஜமானாகிய ஒரு மனுஷனுக்கு ஒப்பாயிருக்கிறது; அவன் தன் திராட்சத்தோட்டத்துக்கு வேலையாட்களை அமர்த்த அதிகாலையிலே புறப்பட்டான்.
	<br /><br />
	2. வேலையாட்களுடன் நாளொன்றுக்கு ஒரு பணம் கூலிபேசி, அவர்களைத் தன் திராட்சத்தோட்டத்துக்கு அனுப்பினான்.
	<br /><br />
	3. மூன்றாம் மணிவேளையிலும் அவன் புறப்பட்டுப்போய், கடைத்தெருவிலே சும்மா நிற்கிற வேறு சிலரைக் கண்டு:
	<br /><br />
	4. நீங்களும் திராட்சத்தோட்டத்துக்குப் போங்கள், நியாயமானபடி உங்களுக்குக் கூலி கொடுப்பேன் என்றான்; அவர்களும் போனார்கள்.
	<br /><br />
	5. மறுபடியும், ஆறாம் ஒன்பதாம் மணிவேளையிலும் அவன் போய் அப்படியே செய்தான்.
	<br /><br />
	6. பதினோராம் மணிவேளையிலும் அவன் போய், சும்மா நிற்கிற வேறு சிலரைக்கண்டு: நீங்கள் பகல் முழுவதும் இங்கே சும்மா நிற்கிறதென்ன என்று கேட்டான்.
	<br /><br />
	7. அதற்கு அவர்கள்: ஒருவரும் எங்களுக்கு வேலையிடவில்லை என்றார்கள். அவன் அவர்களை நோக்கி: நீங்களும் திராட்சத்தோட்டத்துக்குப் போங்கள், நியாயமானபடி கூலி பெற்றுக்கொள்வீர்கள் என்றான்.
	<br /><br />
	8. சாயங்காலத்தில், திராட்சத்தோட்டத்துக்கு எஜமான் தன் காரியக்காரனை நோக்கி: நீ வேலையாட்களை அழைத்து, பிந்திவந்தவர்கள் தொடங்கி முந்திவந்தவர்கள்வரைக்கும் அவர்களுக்குக் கூலிகொடு என்றான்.
	<br /><br />
	9. அப்பொழுது பதினோராம் மணிவேளையில் வேலையமர்த்தப்பட்டவர்கள் வந்து ஆளுக்கு ஒவ்வொரு பணம் வாங்கினார்கள்.
	<br /><br />
	10. முந்தி அமர்த்தப்பட்டவர்கள் வந்து, தங்களுக்கு அதிக கூலி கிடைக்கும் என்று எண்ணினார்கள், அவர்களும் ஆளுக்கு ஒவ்வொரு பணம் வாங்கினார்கள்.
	<br /><br />
	11. வாங்கிக்கொண்டு, வீட்டெஜமானை நோக்கி:
	<br /><br />
	12. பிந்திவந்தவர்களாகிய இவர்கள் ஒரு மணி நேரமாத்திரம் வேலைசெய்தார்கள்; பகலின் கஷ்டத்தையும் வெயிலின் உஷ்ணத்தையும் சகித்த எங்களுக்கு இவர்களைச் சமமாக்கினீரே என்று முறுமுறுத்தார்கள்.
	<br /><br />
	13. அவர்களில் ஒருவனுக்கு அவன் பிரதியுத்தரமாக: சிநேகிதனே, நான் உனக்கு அநியாயஞ்செய்யவில்லை; நீ என்னிடத்தில் ஒரு பணத்துக்குச் சம்மதிக்கவில்லையா?
	<br /><br />
	14. உன்னுடையதை நீ வாங்கிக்கொண்டு போ, உனக்குக் கொடுத்ததுபோலப் பிந்தி வந்தவனாகிய இவனுக்கும் கொடுப்பது என்னுடைய இஷ்டம்.
	<br /><br />
	15. என்னுடையதை என் இஷ்டப்படி செய்ய எனக்கு அதிகாரமில்லையா? நான் தயாளனாயிருக்கிறபடியால், நீ வன்கண்ணனாயிருக்கலாமா என்றான்.
	<br /><br />
	16. இவ்விதமாக, பிந்தினோர் முந்தினோராயும், முந்தினோர் பிந்தினோராயும் இருப்பார்கள்; அழைக்கப்பட்டவர்கள் அநேகர், தெரிந்துகொள்ளப்பட்டவர்களோ சிலர் என்றார்.
	<br /><br />
	17. இயேசு எருசலேமுக்குப் போகும்போது, வழியிலே பன்னிரண்டு சீஷரையும் தனியே அழைத்து:
	<br /><br />
	18. இதோ, எருசலேமுக்குப் போகிறோம்; மனுஷகுமாரன் பிரதான ஆசாரியரிடத்திலும் வேதபாரகரிடத்திலும் ஒப்புக்கொடுக்கப்படுவார்; அவர்கள் அவரை மரண ஆக்கினைக்குள்ளாகத் தீர்த்து,
	<br /><br />
	19. அவரைப் பரியாசம்பண்ணவும், வாரினால் அடிக்கவும், சிலுவையில் அறையவும் புறஜாதியாரிடத்தில் ஒப்புக்கொடுப்பார்கள்; ஆகிலும் மூன்றாம் நாளிலே அவர் உயிரோடே எழுந்திருப்பார் என்றார்.
	<br /><br />
	20. அப்பொழுது, செபெதேயுவின் குமாரருடைய தாய் தன் குமாரரோடுகூட அவரிடத்தில் வந்து, அவரைப் பணிந்துகொண்டு: உம்மிடத்தில் ஒரு விண்ணப்பம் பண்ணவேண்டும் என்றாள்.
	<br /><br />
	21. அவர் அவளை நோக்கி: உனக்கு என்ன வேண்டும் என்று கேட்டார். அதற்கு அவள்: உம்முடைய ராஜ்யத்திலே என் குமாரராகிய இவ்விரண்டுபேரில் ஒருவன் உமது வலதுபாரிசத்திலும், ஒருவன் உமது இடதுபாரிசத்திலும் உட்கார்ந்திருக்கும்படி அருள்செய்யவேண்டும் என்றாள்.
	<br /><br />
	22. இயேசு பிரதியுத்தரமாக: நீங்கள் கேட்டுக்கொள்ளுகிறது இன்னது என்று உங்களுக்குத் தெரியவில்லை. நான் குடிக்கும் பாத்திரத்தில் நீங்கள் குடிக்கவும், நான் பெறும் ஸ்நானத்தை நீங்கள் பெறவும் உங்களால் கூடுமா என்றார். அதற்கு அவர்கள்: கூடும் என்றார்கள்.
	<br /><br />
	23. அவர் அவர்களை நோக்கி: என் பாத்திரத்தில் நீங்கள் குடிப்பீர்கள், நான் பெறும் ஸ்நானத்தையும் நீங்கள் பெறுவீர்கள்; ஆனாலும், என் வலதுபாரிசத்திலும் என் இடதுபாரிசத்திலும் உட்கார்ந்திருக்கும்படி என் பிதாவினால் எவர்களுக்கு ஆயத்தம்பண்ணப்பட்டிருக்கிறதோ அவர்களுக்கேயல்லாமல், மற்றொருவருக்கும் அதை அருளுவது என் காரியமல்ல என்றார்.
	<br /><br />
	24. மற்றப் பத்துப்பேரும் அதைக்கேட்டு, அந்த இரண்டு சகோதரர்பேரிலும் எரிச்சலானார்கள்.
	<br /><br />
	25. அப்பொழுது, இயேசு அவர்களைக் கிட்டவரச்செய்து: புறஜாதியாருடைய அதிகாரிகள் அவர்களை இறுமாப்பாய் ஆளுகிறார்கள் என்றும், பெரியவர்கள் அவர்கள்மேல் கடினமாய் அதிகாரஞ் செலுத்துகிறார்கள் என்றும், நீங்கள் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	26. உங்களுக்குள்ளே அப்படி இருக்கலாகாது; உங்களில் எவனாகிலும் பெரியவனாயிருக்க விரும்பினால், அவன் உங்களுக்குப் பணிவிடைக்காரனாயிருக்கக்கடவன்.
	<br /><br />
	27. உங்களில் எவனாகிலும் முதன்மையானவனாயிருக்க விரும்பினால், அவன் உங்களுக்கு ஊழியக்காரனாயிருக்கக்கடவன்.
	<br /><br />
	28. அப்படியே, மனுஷகுமாரனும் ஊழியங்கொள்ளும்படி வராமல், ஊழியஞ் செய்யவும், அநேகரை மீட்கும்பொருளாகத் தம்முடைய ஜீவனைக் கொடுக்கவும் வந்தார் என்றார்.
	<br /><br />
	29. அவர்கள் எரிகோவிலிருந்து புறப்பட்டுப்போகையில், திரளான ஜனங்கள் அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	30. அப்பொழுது வழியருகே உட்கார்ந்திருந்த இரண்டு குருடர், இயேசு அவ்வழியே வருகிறார் என்று கேள்விப்பட்டு: ஆண்டவரே, தாவீதின் குமாரனே, எங்களுக்கு இரங்கும் என்று கூப்பிட்டார்கள்.
	<br /><br />
	31. அவர்கள் பேசாதிருக்கும்படி ஜனங்கள் அவர்களை அதட்டினார்கள். அவர்களோ: ஆண்டவரே, தாவீதின் குமாரனே, எங்களுக்கு இரங்கும் என்று அதிகமாய்க் கூப்பிட்டார்கள்.
	<br /><br />
	32. இயேசு நின்று, அவர்களைத் தம்மிடத்தில் அழைத்து: நான் உங்களுக்கு என்ன செய்யவேண்டும் என்றிருக்கிறீர்கள் என்றார்.
	<br /><br />
	33. அதற்கு அவர்கள்: ஆண்டவரே, எங்கள் கண்களைத் திறக்கவேண்டும் என்றார்கள்.
	<br /><br />
	34. இயேசு மனதுருகி, அவர்கள் கண்களைத் தொட்டார்; உடனே அவர்கள் பார்வையடைந்து, அவருக்குப் பின்சென்றார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew20