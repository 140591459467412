import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah35 = () => {
  const verseNumber = 35;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 35 </title>
	<meta
          name="description"
          content="Isaiah 35 | ஏசாயா 35 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. வனாந்தரமும் வறண்ட நிலமும் மகிழ்ந்து, கடுவெளி களித்து, புஷ்பத்தைப்போலச் செழிக்கும்.
	<br /><br />
	2. அது மிகுதியாய்ச் செழித்துப் பூரித்து ஆனந்தக்களிப்புடன் பாடும்; லீபனோனின் மகிமையும், கர்மேல் சாரோன் என்பவைகளின் அலங்காரமும் அதற்கு அளிக்கப்படும்; அவர்கள் கர்த்தருடைய மகிமையையும், நமது தேவனுடைய மகத்துவத்தையும் காண்பார்கள்.
	<br /><br />
	3. தளர்ந்த கைகளைத் திடப்படுத்தி, தள்ளாடுகிற முழங்கால்களைப் பலப்படுத்துங்கள்.
	<br /><br />
	4. மனம் பதறுகிறவர்களைப் பார்த்து: நீங்கள் பயப்படாதிருங்கள், திடன்கொள்ளுங்கள்; இதோ, உங்கள் தேவன் நீதியைச் சரிக்கட்டவும், உங்கள் தேவன் பதிலளிக்கவும் வருவார்; அவர் வந்து உங்களை இரட்சிப்பார் என்று சொல்லுங்கள்.
	<br /><br />
	5. அப்பொழுது குருடரின் கண்கள் திறக்கப்பட்டு, செவிடரின் செவிகள் திறவுண்டுபோம்.
	<br /><br />
	6. அப்பொழுது முடவன் மானைப்போல் குதிப்பான்; ஊமையன் நாவும் கெம்பீரிக்கும்; வனாந்தரத்திலே தண்ணீர்களும், கடுவெளியிலே ஆறுகளும் பாய்ந்தோடும்.
	<br /><br />
	7. வெட்டாந்தரை தண்ணீர்த் தடாகமும், வறண்ட நிலம் நீரூற்றுகளுமாகும்; வலுசர்ப்பங்கள் தாபரித்துக் கிடந்த இடங்களிலே புல்லும் கொறுக்கையும் நாணலும் உண்டாகும்.
	<br /><br />
	8. அங்கே பெரும்பாதையான வழியும் இருக்கும்; அது பரிசுத்த வழி என்னப்படும்; தீட்டுள்ளவன் அதிலே நடந்துவருவதில்லை; அந்த வழியில் நடக்கிறவர்கள் பேதையராயிருந்தாலும் திசைகெட்டுப் போவதில்லை.
	<br /><br />
	9. அங்கே சிங்கம் இருப்பதில்லை; துஷ்டமிருகம் அங்கே போவதுமில்லை, அங்கே காணப்படவுமாட்டாது; மீட்கப்பட்டவர்களே அதில் நடப்பார்கள்.
	<br /><br />
	10. கர்த்தரால் மீட்கப்பட்டவர்கள் திரும்பி, ஆனந்தக்களிப்புடன் பாடி, சீயோனுக்கு வருவார்கள்; நித்திய மகிழ்ச்சி அவர்கள் தலையின்மேலிருக்கும்; சந்தோஷமும் மகிழ்ச்சியும் அடைவார்கள்; சஞ்சலமும் தவிப்பும் ஓடிப்போம்
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah35