import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 3 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 3 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 3 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மேலும், சகோதரரே, நான் உங்களை ஆவிக்குரியவர்களென்று எண்ணி உங்களுடனே பேசக்கூடாமல், மாம்சத்துக்குரியவர்களென்றும், கிறிஸ்துவுக்குள் குழந்தைகளென்றும் எண்ணிப் பேசவேண்டியதாயிற்று.
	<br /><br />
	2. நீங்கள் பெலனில்லாதவர்களானதால், உங்களுக்குப் போஜனங்கொடாமல், பாலைக் குடிக்கக்கொடுத்தேன்; இன்னமும் நீங்கள் மாம்சத்துக்குரியவர்களாயிருக்கிறபடியால், இப்பொழுதும் உங்களுக்குப் பெலனில்லை.
	<br /><br />
	3. பொறாமையும் வாக்குவாதமும் பேதகங்களும் உங்களுக்குள் இருக்கிறபடியால், நீங்கள் மாம்சத்திற்குரியவர்களாயிருந்து மனுஷமார்க்கமாய் நடக்கிறீர்களல்லவா?
	<br /><br />
	4. ஒருவன் நான் பவுலைச் சேர்ந்தவனென்றும், வேறொருவன் நான் அப்பொல்லோவைச் சேர்ந்தவனென்றும் சொல்லுகிறபடியால் நீங்கள் மாம்சத்திற்குரியவர்களல்லவா?
	<br /><br />
	5. பவுல் யார்? அப்பொல்லோ யார்? கர்த்தர் அவனவனுக்கு அருள் அளித்தபடியே நீங்கள் விசுவாசிக்கிறதற்கு ஏதுவாயிருந்த ஊழியக்காரர்தானே.
	<br /><br />
	6. நான் நட்டேன், அப்பொல்லோ நீர்ப்பாய்ச்சினான், தேவனே விளையச்செய்தார்.
	<br /><br />
	7. அப்படியிருக்க, நடுகிறவனாலும் ஒன்றுமில்லை, நீர்ப்பாய்ச்சுகிறவனாலும் ஒன்றுமில்லை, விளையச்செய்கிற தேவனாலே எல்லாமாகும்.
	<br /><br />
	8. மேலும் நடுகிறவனும் நீர்ப்பாய்ச்சுகிறவனும் ஒன்றாயிருக்கிறார்கள்; அவனவன் தன்தன் வேலைக்குத் தக்கதாய்க் கூலியைப் பெறுவான்.
	<br /><br />
	9. நாங்கள் தேவனுக்கு உடன்வேலையாட்களாயிருக்கிறோம்; நீங்கள் தேவனுடைய பண்ணையும், தேவனுடைய மாளிகையுமாயிருக்கிறீர்கள்.
	<br /><br />
	10. எனக்கு அளிக்கப்பட்ட தேவகிருபையின்படியே புத்தியுள்ள சிற்பாசாரியைப்போல அஸ்திபாரம்போட்டேன். வேறொருவன் அதின்மேல் கட்டுகிறான். அவனவன் தான் அதின்மேல் இன்னவிதமாய்க் கட்டுகிறானென்று பார்க்கக்கடவன்.
	<br /><br />
	11. போடப்பட்டிருக்கிற அஸ்திபாரமாகிய இயேசுகிறிஸ்துவை அல்லாமல் வேறே அஸ்திபாரத்தைப் போட ஒருவனாலும் கூடாது.
	<br /><br />
	12. ஒருவன் அந்த அஸ்திபாரத்தின்மேல் பொன், வெள்ளி, விலையேறப்பெற்ற கல், மரம், புல், வைக்கோல் ஆகிய இவைகளைக் கட்டினால்,
	<br /><br />
	13. அவனவனுடைய வேலைப்பாடு வெளியாகும்; நாளானது அதை விளங்கப்பண்ணும். ஏனெனில் அது அக்கினியினாலே வெளிப்படுத்தப்படும்; அவனவனுடைய வேலைப்பாடு எத்தன்மையுள்ளதென்று அக்கினியானது பரிசோதிக்கும்.
	<br /><br />
	14. அதின்மேல் ஒருவன் கட்டினது நிலைத்தால், அவன் கூலியைப் பெறுவான்.
	<br /><br />
	15. ஒருவன் கட்டினது வெந்துபோனால், அவன் நஷ்டமடைவான்; அவனோ இரட்சிக்கப்படுவான்; அதுவும் அக்கினியிலகப்பட்டுத் தப்பினதுபோலிருக்கும்.
	<br /><br />
	16. நீங்கள் தேவனுடைய ஆலயமாயிருக்கிறீர்களென்றும், தேவனுடைய ஆவி உங்களில் வாசமாயிருக்கிறாரென்றும் அறியாதிருக்கிறீர்களா?
	<br /><br />
	17. ஒருவன் தேவனுடைய ஆலயத்தைக்கெடுத்தால், அவனைத் தேவன் கெடுப்பார்; தேவனுடைய ஆலயம் பரிசுத்தமாயிருக்கிறது; நீங்களே அந்த ஆலயம்.
	<br /><br />
	18. ஒருவனும் தன்னைத்தானே வஞ்சியாதிருப்பானாக; இவ்வுலகத்திலே உங்களில் ஒருவன் தன்னை ஞானியென்று எண்ணினால் அவன் ஞானியாகும்படிக்குப் பைத்தியக்காரனாகக்கடவன்.
	<br /><br />
	19. இவ்வுலகத்தின் ஞானம் தேவனுக்கு முன்பாகப் பைத்தியமாயிருக்கிறது. அப்படியே, ஞானிகளை அவர்களுடைய தந்திரத்திலே பிடிக்கிறாரென்றும்,
	<br /><br />
	20. ஞானிகளுடைய சிந்தனைகள் வீணாயிருக்கிறதென்று கர்த்தர் அறிந்திருக்கிறாரென்றும் எழுதியிருக்கிறது.
	<br /><br />
	21. இப்படியிருக்க, ஒருவனும் மனுஷரைக்குறித்து மேன்மைபாராட்டாதிருப்பானாக; எல்லாம் உங்களுடையதே;
	<br /><br />
	22. பவுலாகிலும், அப்பொல்லோவாகிலும், கேபாவாகிலும், உலகமாகிலும், ஜீவனாகிலும் மரணமாகிலும், நிகழ்காரியங்களாகிலும், வருங்காரியங்களாகிலும், எல்லாம் உங்களுடையது;
	<br /><br />
	23. நீங்கள் கிறிஸ்துவினுடையவர்கள்; கிறிஸ்து தேவனுடையவர்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians3