import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 10 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 10
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சகோதரரே, இஸ்ரவேலர் இரட்சிக்கப்படவேண்டும் என்பதே என் இருதயத்தின் விருப்பமும் நான் தேவனை நோக்கிச் செய்யும் விண்ணப்பமுமாயிருக்கிறது.
	<br /><br />
	2. தேவனைப்பற்றி அவர்களுக்கு வைராக்கியமுண்டென்று அவர்களைக்குறித்துச் சாட்சிசொல்லுகிறேன்; ஆகிலும் அது அறிவுக்கேற்ற வைராக்கியமல்ல.
	<br /><br />
	3. எப்படியென்றால், அவர்கள் தேவநீதியை அறியாமல், தங்கள் சுயநீதியை நிலைநிறுத்தத் தேடுகிறபடியால் தேவநீதிக்குக் கீழ்ப்படியாதிருக்கிறார்கள்.
	<br /><br />
	4. விசுவாசிக்கிற எவனுக்கும் நீதி உண்டாகும்படியாகக் கிறிஸ்து நியாயப்பிரமாணத்தின் முடிவாயிருக்கிறார்.
	<br /><br />
	5. மோசே நியாயப்பிரமாணத்தினாலாகும் நீதியைக்குறித்து: இவைகளைச் செய்கிற மனுஷன் இவைகளால் பிழைப்பான் என்று எழுதியிருக்கிறான்.
	<br /><br />
	6. விசுவாசத்தினாலாகும் நீதியானது: கிறிஸ்துவை இறங்கிவரப்பண்ணும்படி பரலோகத்துக்கு ஏறுகிறவன் யார்?
	<br /><br />
	7. அல்லது கிறிஸ்துவை மரித்தோரிலிருந்து ஏறிவரப்பண்ணும்படி பாதாளத்துக்கு இறங்குகிறவன் யார்? என்று உன் உள்ளத்திலே சொல்லாதிருப்பாயாக என்று சொல்லுகிறதுமன்றி;
	<br /><br />
	8. இந்த வார்த்தை உனக்குச் சமீபமாய் உன் வாயிலும் உன் இருதயத்திலும் இருக்கிறது என்றும் சொல்லுகிறது; இந்த வார்த்தை நாங்கள் பிரசங்கிக்கிற விசுவாசத்தின் வார்த்தையே.
	<br /><br />
	9. என்னவென்றால், கர்த்தராகிய இயேசுவை நீ உன் வாயினாலே அறிக்கையிட்டு, தேவன் அவரை மரித்தோரிலிருந்து எழுப்பினாரென்று உன் இருதயத்திலே விசுவாசித்தால் இரட்சிக்கப்படுவாய்.
	<br /><br />
	10. நீதியுண்டாக இருதயத்திலே விசுவாசிக்கப்படும், இரட்சிப்புண்டாக வாயினாலே அறிக்கைபண்ணப்படும்.
	<br /><br />
	11. அவரை விசுவாசிக்கிறவன் எவனோ அவன் வெட்கப்படுவதில்லையென்று வேதம் சொல்லுகிறது.
	<br /><br />
	12. யூதனென்றும் கிரேக்கனென்றும் வித்தியாசமே இல்லை; எல்லாருக்குங் கர்த்தரானவர் தம்மைத் தொழுதுகொள்ளுகிற யாவருக்கும் ஐசுவரியசம்பன்னராயிருக்கிறார்.
	<br /><br />
	13. ஆதலால் கர்த்தருடைய நாமத்தைத் தொழுதுகொள்ளுகிற எவனும் இரட்சிக்கப்படுவான்.
	<br /><br />
	14. அவரை விசுவாசியாதவர்கள் எப்படி அவரைத் தொழுதுகொள்ளுவார்கள்? அவரைக்குறித்துக் கேள்விப்படாதவர்கள் எப்படி விசுவாசிப்பார்கள்? பிரசங்கிக்கிறவன் இல்லாவிட்டால் எப்படிக் கேள்விப்படுவார்கள்?
	<br /><br />
	15. அனுப்பப்படாவிட்டால் எப்படிப் பிரசங்கிப்பார்கள்? சமாதானத்தைக்கூறி, நற்காரியங்களைச் சுவிசேஷமாய் அறிவிக்கிறவர்களுடைய பாதங்கள் எவ்வளவு அழகானவைகள் என்று எழுதியிருக்கிறதே.
	<br /><br />
	16. ஆனாலும் சுவிசேஷத்துக்கு எல்லாரும் கீழ்ப்படியவில்லை. அதைக்குறித்து ஏசாயா: கர்த்தாவே, எங்கள் மூலமாய்க் கேள்விப்பட்டதை விசுவாசித்தவன் யார் என்று சொல்லுகிறான்.
	<br /><br />
	17. ஆதலால் விசுவாசம் கேள்வியினாலே வரும், கேள்வி தேவனுடைய வசனத்தினாலே வரும்.
	<br /><br />
	18. இப்படியிருக்க, அவர்கள் கேள்விப்படவில்லையா என்று கேட்கிறேன்; கேள்விப்பட்டார்கள்; அவைகளின் சத்தம் பூமியெங்கும் அவைகளின் வசனங்கள் பூச்சக்கரத்துக் கடைசிவரைக்கும் செல்லுகிறதே.
	<br /><br />
	19. இஸ்ரவேலர் அதை அறியவில்லையா என்று கேட்கிறேன், அறிந்தார்கள். முதலாவது, மோசே: எனக்கு ஜனங்களல்லாதவர்களைக்கொண்டு நான் உங்களுக்கு எரிச்சல் உண்டாக்குவேன்; புத்தியீனமுள்ள ஜனங்களாலே உங்களுக்குக் கோபமூட்டுவேன் என்றான்.
	<br /><br />
	20. அல்லாமலும் ஏசாயா: என்னைத் தேடாதவர்களாலே கண்டறியப்பட்டேன், என்னை விசாரித்துக் கேளாதவர்களுக்கு வெளியரங்கமானேன் என்று தைரியங்கொண்டு சொல்லுகிறான்.
	<br /><br />
	21. இஸ்ரவேலரைக்குறித்தோ: கீழ்ப்படியாதவர்களும் எதிர்த்துப் பேசுகிறவர்களுமாயிருக்கிற ஜனங்களிடத்திற்கு நாள்முழுதும் என் கைகளை நீட்டினேன் என்று அவன் சொல்லியிருக்கிறான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans10