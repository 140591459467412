import React, { useState, useEffect } from 'react';
import bibleVerses from './data';
import './VerseOfTheDay.css'
import { RWebShare } from 'react-web-share';

const VerseOfTheDay = () => {
  const [verse, setVerse] = useState(null);

  // Function to get the current date in the local timezone.
  const getCurrentLocalDate = () => {
    return new Date();
  };

  // Function to get the verse for the current day based on the date.
  const getVerseForCurrentDay = () => {
    const currentDate = getCurrentLocalDate();
    const dayOfYear = currentDate.getUTCDate();
    const selectedVerse = bibleVerses[dayOfYear % bibleVerses.length];
    setVerse(selectedVerse);
  };

  useEffect(() => {
    getVerseForCurrentDay();
  }, []);

  return (
    <div className='verseoftheday'>
    <br />
    <br />
    <h2>Verse of the Day ✞ Tamil Bible </h2>
<br />
<br />
{verse && (
  
    <div style={{ textAlign: "center" }}>
      <p>{verse.text}</p>
      <br />
      <p style={{ textAlign: "right" }}>
        <span className='yellow_color'>{verse.reference}</span>
        <RWebShare
  data={{
    text: `${verse.text} (${verse.reference})`, // The text you want to share
    url: "https://www.tamilbibleverse.com", // The URL you want to share
    title: "Today's Tamil Bible Verse", // The title you want to share
  }}
  onClick={() => console.log("shared successfully!")}
>
        
        <div className="sharevod"><button className="btn btn-warning">Share</button></div>
  </RWebShare>
      </p>
    </div>
)}
<br/><br/>
    </div>
  );
};

export default VerseOfTheDay;
