import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 7 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 7 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 7 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீங்கள் எனக்கு எழுதின காரியங்களைக்குறித்து நான் எழுதுகிறதென்னவென்றால், ஸ்திரீயைத் தொடாமலிருக்கிறது மனுஷனுக்கு நல்லது.
	<br /><br />
	2. ஆகிலும் வேசித்தனம் இராதபடிக்கு அவனவன் தன் சொந்த மனைவியையும், அவளவள் தன் சொந்தப் புருஷனையும் உடையவர்களாயிருக்கவேண்டும்.
	<br /><br />
	3. புருஷன் தன் மனைவிக்குச் செய்யவேண்டிய கடமையைச் செய்யக்கடவன்; அப்படியே மனைவியும் தன் புருஷனுக்குச் செய்யக்கடவள்.
	<br /><br />
	4. மனைவியானவள் தன் சுயசரீரத்திற்கு அதிகாரியல்ல, புருஷனே அதற்கு அதிகாரி; அப்படியே புருஷனும் தன் சுயசரீரத்திற்கு அதிகாரியல்ல, மனைவியே அதற்கு அதிகாரி.
	<br /><br />
	5. உபவாசத்திற்கும் ஜெபத்திற்கும் தடையிராதபடிக்கு இருவரும் சிலகாலம் பிரிந்திருக்கவேண்டுமென்று சம்மதித்தாலன்றி, ஒருவரைவிட்டு ஒருவர் பிரியாதிருங்கள்; உங்களுக்கு விரதத்துவம் இல்லாமையால் சாத்தான் உங்களைத் தூண்டிவிடாதபடிக்கு, மறுபடியும் கூடி வாழுங்கள்.
	<br /><br />
	6. இதை நான் கட்டளையாகச் சொல்லாமல், யோசனையாகச் சொல்லுகிறேன்.
	<br /><br />
	7. எல்லா மனுஷரும் என்னைப்போலவே இருக்க விரும்புகிறேன். ஆகிலும் அவனவனுக்குத் தேவனால் அருளப்பட்ட அவனவனுக்குரிய வரமுண்டு; அது ஒருவனுக்கு ஒருவிதமாயும், மற்றொருவனுக்கு வேறுவிதமாயும் இருக்கிறது.
	<br /><br />
	8. விவாகமில்லாதவர்களையும், கைம்பெண்களையும்குறித்து நான் சொல்லுகிறது என்னவென்றால், அவர்கள் என்னைப்போல இருந்துவிட்டால் அவர்களுக்கு நலமாயிருக்கும்.
	<br /><br />
	9. ஆகிலும் அவர்கள் விரத்தராயிருக்கக்கூடாதிருந்தால் விவாகம்பண்ணக்கடவர்கள்; வேகிறதைப்பார்க்கிலும் விவாகம்பண்ணுகிறது நலம்.
	<br /><br />
	10. விவாகம்பண்ணிக்கொண்டவர்களுக்கு நானல்ல, கர்த்தரே கட்டளையிடுகிறதாவது: மனைவியானவள் தன் புருஷனை விட்டுப் பிரிந்துபோகக்கூடாது.
	<br /><br />
	11. பிரிந்துபோனால் அவள் விவாகமில்லாதிருக்கக்கடவள், அல்லது புருஷனோடே ஒப்புரவாகக்கடவள்; புருஷனும் தன் மனைவியைத் தள்ளிவிடக்கூடாது.
	<br /><br />
	12. மற்றவர்களைக்குறித்துக் கர்த்தர் அல்ல, நானே சொல்லுகிறதாவது: சகோதரனொருவனுடைய மனைவி அவிசுவாசியாயிருந்தும், அவனுடனே வாசமாயிருக்க அவளுக்குச் சம்மதமிருந்தால், அவன் அவளைத் தள்ளிவிடாதிருக்கக்கடவன்.
	<br /><br />
	13. அப்படியே ஒரு ஸ்திரீயினுடைய புருஷன் அவிசுவாசியாயிருந்தும், அவளுடனே வாசமாயிருக்க அவனுக்குச் சம்மதமிருந்தால், அவள் அவனைத் தள்ளிவிடாதிருக்கக்கடவள்.
	<br /><br />
	14. என்னத்தினாலெனில், அவிசுவாசியான புருஷன் தன் மனைவியால் பரிசுத்தமாக்கப்படுகிறான்; அவிசுவாசியான மனைவியும் தன் புருஷனால் பரிசுத்தமாக்கப்படுகிறாள். இல்லாவிட்டால் உங்கள் பிள்ளைகள் அசுத்தமாயிருக்குமே; இப்பொழுதோ அவைகள் பரிசுத்தமாயிருக்கின்றன.
	<br /><br />
	15. ஆகிலும் அவிசுவாசி பிரிந்துபோனால் பிரிந்துபோகட்டும், இப்படிப்பட்ட விஷயத்தில், சகோதரனாவது சகோதரியாவது அடிமைப்பட்டவர்களல்ல. சமாதானமாயிருக்கும்படிக்கே தேவன் நம்மை அழைத்திருக்கிறார்.
	<br /><br />
	16. மனைவியானவளே, நீ உன் புருஷனை இரட்சிப்பாயோ அல்லவோ உனக்கு எப்படித் தெரியும்? புருஷனே, நீ உன் மனைவியை இரட்சிப்பாயோ அல்லவோ உனக்கு எப்படித் தெரியும்?
	<br /><br />
	17. தேவன் அவனவனுக்குப் பகிர்ந்ததெப்படியோ, கர்த்தர் அவனவனை அழைத்ததெப்படியோ, அப்படியே அவனவன் நடக்கக்கடவன். எல்லாச் சபைகளிலேயும் இப்படியே திட்டம்பண்ணுகிறேன்.
	<br /><br />
	18. ஒருவன் விருத்தசேதனம் பெற்றவனாய் அழைக்கப்பட்டிருந்தால், விருத்தசேதனமில்லாதவனாயிருக்க வகைதேடானாக; ஒருவன் விருத்தசேதனமில்லாதவனாய் அழைக்கப்பட்டிருந்தால், விருத்தசேதனம் பெறாதிருப்பானாக.
	<br /><br />
	19. விருத்தசேதனமும் ஒன்றுமில்லை, விருத்தசேதனமில்லாமையும் ஒன்றுமில்லை; தேவனுடைய கற்பனைகளைக் கைக்கொள்ளுகிறதே காரியம்.
	<br /><br />
	20. அவனவன் தான் அழைக்கப்பட்ட நிலைமையிலே நிலைத்திருக்கக்கடவன்.
	<br /><br />
	21. அடிமையாய் நீ அழைக்கப்பட்டிருந்தால், கவலைப்படாதே; நீ சுயாதீனனாகக்கூடுமானால் அதை நலமென்று அநுசரித்துக்கொள்.
	<br /><br />
	22. கர்த்தருக்குள் அழைக்கப்பட்ட அடிமையானவன் கர்த்தருடைய சுயாதீனனாயிருக்கிறான்; அப்படியே அழைக்கப்பட்ட சுயாதீனன் கிறிஸ்துவினுடைய அடிமையாயிருக்கிறான்.
	<br /><br />
	23. நீங்கள் கிரயத்துக்குக் கொள்ளப்பட்டீர்கள்; மனுஷருக்கு அடிமைகளாகாதிருங்கள்.
	<br /><br />
	24. சகோதரரே, அவனவன் தான் அழைக்கப்பட்ட நிலைமையிலே தேவனுக்கு முன்பாக நிலைத்திருக்கக்கடவன்.
	<br /><br />
	25. அன்றியும் கன்னிகைகளைக்குறித்து, கர்த்தரால் எனக்குக் கட்டளை இல்லை. ஆகிலும் நான் உண்மையுள்ளவனாயிருக்கிறதற்குக் கர்த்தரால் இரக்கம்பெற்று, என் அபிப்பிராயத்தைத் தெரியப்படுத்துகிறேன்.
	<br /><br />
	26. அதென்னவெனில், இப்பொழுது உண்டாயிருக்கிற துன்பத்தினிமித்தம் விவாகமில்லாமலிருக்கிறது மனுஷனுக்கு நலமாயிருக்குமென்று எண்ணுகிறேன்.
	<br /><br />
	27. நீ மனைவியோடே கட்டப்பட்டிருந்தால், அவிழ்க்கப்பட வகைதேடாதே; நீ மனைவி இல்லாதவனாயிருந்தால், மனைவியைத் தேடாதே.
	<br /><br />
	28. நீ விவாகம்பண்ணினாலும் பாவமல்ல; கன்னிகை விவாகம்பண்ணினாலும் பாவமல்ல. ஆகிலும் அப்படிப்பட்டவர்கள் சரீரத்திலே உபத்திரவப்படுவார்கள்; அதற்கு நீங்கள் தப்பவேண்டுமென்றிருக்கிறேன்.
	<br /><br />
	29. மேலும், சகோதரரே, நான் சொல்லுகிறதென்னவெனில், இனிவரும் காலம் குறுகினதானபடியால், மனைவிகளுள்ளவர்கள் மனைவிகளில்லாதவர்கள்போலவும்,
	<br /><br />
	30. அழுகிறவர்கள் அழாதவர்கள்போலவும், சந்தோஷப்படுகிறவர்கள் சந்தோஷப்படாதவர்கள்போலவும், கொள்ளுகிறவர்கள் கொள்ளாதவர்கள்போலவும்,
	<br /><br />
	31. இவ்வுலகத்தை அனுபவிக்கிறவர்கள் அதைத் தகாதவிதமாய் அனுபவியாதவர்கள்போலவும் இருக்கவேண்டும்; இவ்வுலகத்தின் வேஷம் கடந்துபோகிறதே.
	<br /><br />
	32. நீங்கள் கவலையற்றவர்களாயிருக்க விரும்புகிறேன். விவாகமில்லாதவன் கர்த்தருக்கு எப்படிப் பிரியமாயிருக்கலாமென்று, கர்த்தருக்குரியவைகளுக்காகக் கவலைப்படுகிறான்.
	<br /><br />
	33. விவாகம்பண்ணினவன் தன் மனைவிக்கு எப்படிப் பிரியமாயிருக்கலாமென்று, உலகத்திற்குரியவைகளுக்காகக் கவலைப்படுகிறான்.
	<br /><br />
	34. அதுபோல, மனைவியானவளுக்கும் கன்னிகைக்கும் வித்தியாசமுண்டு. விவாகமில்லாதவள் சரீரத்திலும் ஆத்துமாவிலும் பரிசுத்தமாயிருக்கும்படி, கர்த்தருக்குரியவைகளுக்காகக் கவலைப்படுகிறாள்; விவாகம்பண்ணினவள் தன் புருஷனுக்கு எப்படிப் பிரியமாயிருக்கலாமென்று, உலகத்திற்குரியவைகளுக்காகக் கவலைப்படுகிறாள்.
	<br /><br />
	35. இதை நான் உங்களைக் கண்ணியில் அகப்படுத்தவேண்டுமென்று சொல்லாமல், உங்களுக்குத் தகுதியாயிருக்குமென்றும், நீங்கள் கவலையில்லாமல் கர்த்தரைப் பற்றிக்கொண்டிருக்கவேண்டுமென்றும், உங்கள் சுயபிரயோஜனத்துக்காகவே சொல்லுகிறேன்.
	<br /><br />
	36. ஆகிலும் ஒருவன் தன் புத்திரியின் கன்னிகைப்பருவம் கடந்துபோனதினாலே, அவள் விவாகம்பண்ணாமலிருப்பது அவளுக்குத் தகுதியல்லவென்றும், அவள் விவாகம்பண்ணுவது அவசியமென்றும் நினைத்தால், அவன் தன் மனதின்படி செய்யக்கடவன்; அது பாவமல்ல, விவாகம்பண்ணட்டும்.
	<br /><br />
	37. ஆயினும் அதற்கு அவசியத்தைக் காணாமல், தன் இருதயத்திலே உறுதியுள்ளவனாயும், சுயசித்தத்தின்படி செய்ய அதிகாரமுள்ளவனாயுமிருந்து, தன் புத்திரியின் கன்னிகைப் பருவத்தைக் காக்கவேண்டுமென்று தன் இருதயத்தில் தீர்மானிக்கிறவன் நன்மைசெய்கிறான்.
	<br /><br />
	38. இப்படியிருக்க, அவளை விவாகம்பண்ணிக் கொடுக்கிறவனும் நன்மைசெய்கிறான்; கொடாமலிருக்கிறவனும் அதிக நன்மைசெய்கிறான்.
	<br /><br />
	39. மனைவியானவள் தன் புருஷன் உயிரோடிருக்குங் காலமளவும் பிரமாணத்தினால் கட்டப்பட்டிருக்கிறாள்; தன் புருஷன் மரித்தபின்பு தனக்கு இஷ்டமானவனாயும் கர்த்தருக்குட்பட்டவனாயுமிருக்கிற எவனையாகிலும் விவாகம்பண்ணிக்கொள்ள விடுதலையாயிருக்கிறாள்.
	<br /><br />
	40. ஆகிலும் என்னுடைய அபிப்பிராயத்தின்படி அவள் அப்படியே இருந்துவிட்டால் பாக்கியவதியாயிருப்பாள். என்னிடத்திலும் தேவனுடைய ஆவி உண்டென்று எண்ணுகிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians7