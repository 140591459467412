import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 11 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 11
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்படியிருக்க, தேவன் தம்முடைய ஜனங்களைத் தள்ளிவிட்டாரோ என்று கேட்கிறேன், தள்ளிவிடவில்லையே; நானும் ஆபிரகாமின் சந்ததியிலும் பென்யமீன் கோத்திரத்திலும் பிறந்த இஸ்ரவேலன்.
	<br /><br />
	2. தேவன் தாம் முன்னறிந்துகொண்ட தம்முடைய ஜனங்களைத் தள்ளிவிடவில்லை. எலியாவைக்குறித்துச் சொல்லிய இடத்தில், வேதம் சொல்லுகிறதை அறியீர்களா? அவன் தேவனை நோக்கி:
	<br /><br />
	3. கர்த்தாவே, உம்முடைய தீர்க்கதரிசிகளை அவர்கள் கொலைசெய்து, உம்முடைய பலிபீடங்களை இடித்துப்போட்டார்கள்; நான் ஒருவன்மாத்திரம் மீதியாயிருக்கிறேன், என் பிராணனையும் வாங்கத்தேடுகிறார்களே என்று இஸ்ரவேலருக்கு விரோதமாய் விண்ணப்பம்பண்ணினபோது,
	<br /><br />
	4. அவனுக்கு உண்டான தேவஉத்தரவு என்ன? பாகாலுக்குமுன்பாக முழங்காற்படியிடாத ஏழாயிரம்பேரை எனக்காக மீதியாக வைத்தேன் என்பதே.
	<br /><br />
	5. அப்படிப்போல இக்காலத்திலேயும் கிருபையினாலே உண்டாகும் தெரிந்துகொள்ளுதலின்படி ஒரு பங்கு மீதியாயிருக்கிறது.
	<br /><br />
	6. அது கிருபையினாலே உண்டாயிருந்தால் கிரியைகளினாலே உண்டாயிராது; அப்படியல்லவென்றால், கிருபையானது கிருபையல்லவே. அன்றியும் அது கிரியைகளினாலே உண்டாயிருந்தால் அது கிருபையாயிராது; அப்படியல்லவென்றால், கிரியையானது கிரியையல்லவே.
	<br /><br />
	7. அப்படியானால் என்ன? இஸ்ரவேலர் தேடுகிறதை அடையாமலிருக்கிறார்கள்; தெரிந்துகொள்ளப்பட்டவர்களோ அதை அடைந்திருக்கிறார்கள்; மற்றவர்கள் இன்றையத்தினம்வரைக்கும் கடினப்பட்டிருக்கிறார்கள்.
	<br /><br />
	8. கனநித்திரையின் ஆவியையும், காணாதிருக்கிற கண்களையும், கேளாதிருக்கிற காதுகளையும், தேவன் அவர்களுக்குக் கொடுத்தார் என்று எழுதியிருக்கிறபடியாயிற்று.
	<br /><br />
	9. அன்றியும், அவர்களுடைய பந்தி அவர்களுக்குச் சுருக்கும் கண்ணியும் இடறுதற்கான கல்லும் பதிலுக்குப் பதிலளித்தலுமாகக்கடவது;
	<br /><br />
	10. காணாதபடிக்கு அவர்களுடைய கண்கள் அந்தகாரப்படக்கடவது; அவர்களுடைய முதுகை எப்போதும் குனியப்பண்ணும் என்று தாவீதும் சொல்லியிருக்கிறான்.
	<br /><br />
	11. இப்படியிருக்க, விழுந்துபோகும்படிக்கா இடறினார்கள் என்று கேட்கிறேன், அப்படியல்லவே; அவர்களுக்குள்ளே வைராக்கியத்தை எழுப்பத்தக்கதாக அவர்களுடைய தவறுதலினாலே புறஜாதிகளுக்கு இரட்சிப்பு கிடைத்தது.
	<br /><br />
	12. அவர்களுடைய தவறு உலகத்திற்கு ஐசுவரியமும், அவர்களுடைய குறைவு புறஜாதிகளுக்கு ஐசுவரியமுமாயிருக்க, அவர்களுடைய நிறைவு எவ்வளவு அதிகமாய் அப்படியிருக்கும்.
	<br /><br />
	13. புறஜாதியாராகிய உங்களுடனே பேசுகிறேன்; புறஜாதிகளுக்கு நான் அப்போஸ்தலனாயிருக்கிறதினாலே என் இனத்தாருக்குள்ளே நான் வைராக்கியத்தை எழுப்பி, அவர்களில் சிலரை இரட்சிக்க வேண்டுமென்று,
	<br /><br />
	14. என் ஊழியத்தை மேன்மைப்படுத்துகிறேன்.
	<br /><br />
	15. அவர்களைத் தள்ளிவிடுதல் உலகத்தை ஒப்புரவாக்குதலாயிருக்க, அவர்களை அங்கிகரித்துக்கொள்ளுதல் என்னமாயிராது; மரித்தோரிலிருந்து ஜீவன் உண்டானதுபோலிருக்குமல்லவோ?
	<br /><br />
	16. மேலும் முதற்பலனாகிய மாவானது பரிசுத்தமாயிருந்தால், பிசைந்தமா முழுவதும் பரிசுத்தமாயிருக்கும்; வேரானது பரிசுத்தமாயிருந்தால், கிளைகளும் பரிசுத்தமாயிருக்கும்.
	<br /><br />
	17. சில கிளைகள் முறித்துப்போடப்பட்டிருக்க, காட்டொலிவமரமாகிய நீ அவைகள் இருந்த இடத்தில் ஒட்டவைக்கப்பட்டு, ஒலிவமரத்தின் வேருக்கும் சாரத்துக்கும் உடன்பங்காளியாயிருந்தாயானால்,
	<br /><br />
	18. நீ அந்தக் கிளைகளுக்கு விரோதமாய்ப் பெருமைபாராட்டாதே; பெருமைபாராட்டுவாயானால், நீ வேரைச் சுமக்காமல், வேர் உன்னைச் சுமக்கிறதென்று நினைத்துக்கொள்.
	<br /><br />
	19. நான் ஒட்டவைக்கப்படுவதற்கு அந்தக் கிளைகள் முறித்துப்போடப்பட்டதென்று சொல்லுகிறாயே.
	<br /><br />
	20. நல்லது, அவிசுவாசத்தினாலே அவைகள் முறித்துப்போடப்பட்டன, நீ விசுவாசத்தினாலே நிற்கிறாய்; மேட்டிமைச் சிந்தையாயிராமல் பயந்திரு.
	<br /><br />
	21. சுபாவக்கிளைகளைத் தேவன் தப்பவிடாதிருக்க, உன்னையும் தப்பவிடமாட்டார் என்று எச்சரிக்கையாயிரு.
	<br /><br />
	22. ஆகையால், தேவனுடைய தயவையும் கண்டிப்பையும் பார்; விழுந்தவர்களிடத்திலே கண்டிப்பையும், உன்னிடத்திலே தயவையும் காண்பித்தார்; அந்தத் தயவிலே நிலைத்திருப்பாயானால் உனக்குத் தயவுகிடைக்கும்; நிலைத்திராவிட்டால் நீயும் வெட்டுண்டுபோவாய்.
	<br /><br />
	23. அன்றியும், அவர்கள் அவிசுவாசத்திலே நிலைத்திராதிருந்தால் அவர்களும் ஒட்டவைக்கப்படுவார்கள்; அவர்களை மறுபடியும் ஒட்டவைக்கிறதற்குத் தேவன் வல்லவராயிருக்கிறாரே.
	<br /><br />
	24. சுபாவத்தின்படி காட்டொலிவமரத்திலிருந்து நீ வெட்டப்பட்டு, சுபாவத்திற்கு விரோதமாய் நல்ல ஒலிவமரத்திலே ஒட்டவைக்கப்பட்டிருந்தால், சுபாவக்கிளைகளாகிய அவர்கள் தங்கள் சுய ஒலிவமரத்திலே ஒட்டவைக்கப்படுவது அதிக நிச்சயமல்லவா?
	<br /><br />
	25. மேலும், சகோதரரே, நீங்கள் உங்களையே புத்திமான்களென்று எண்ணாதபடிக்கு ஒரு இரகசியத்தை நீங்கள் அறியவேண்டுமென்றிருக்கிறேன்; அதென்னவெனில், புறஜாதியாருடைய நிறைவு உண்டாகும்வரைக்கும் இஸ்ரவேலரில் ஒரு பங்குக்குக் கடினமான மனதுண்டாயிருக்கும்.
	<br /><br />
	26. இந்தப்பிரகாரம் இஸ்ரவேலரெல்லாரும் இரட்சிக்கப்படுவார்கள். மீட்கிறவர் சீயோனிலிருந்து வந்து, அவபக்தியை யாக்கோபை விட்டு விலக்குவார் என்றும்;
	<br /><br />
	27. நான் அவர்களுடைய பாவங்களை நீக்கும்போது, இதுவே நான் அவர்களுடனே செய்யும் உடன்படிக்கை என்றும் எழுதியிருக்கிறது.
	<br /><br />
	28. சுவிசேஷத்தைக்குறித்து அவர்கள் உங்கள்நிமித்தம் பகைஞராயிருக்கிறார்கள்; தெரிந்துகொள்ளுதலைக்குறித்து அவர்கள் பிதாக்களினிமித்தம் அன்புகூரப்பட்டவர்களாயிருக்கிறார்கள்.
	<br /><br />
	29. தேவனுடைய கிருபைவரங்களும், அவர்களை அழைத்த அழைப்பும் மாறாதவைகளே.
	<br /><br />
	30. ஆதலால், நீங்கள் முற்காலத்திலே தேவனுக்குக் கீழ்ப்படியாதிருந்து, இப்பொழுது அவர்களுடைய கீழ்ப்படியாமையினாலே இரக்கம்பெற்றிருக்கிறதுபோல,
	<br /><br />
	31. அவர்களும் இப்பொழுது கீழ்ப்படியாமலிருந்தும், பின்பு உங்களுக்குக் கிடைத்த இரக்கத்தினாலே இரக்கம் பெறுவார்கள்.
	<br /><br />
	32. எல்லார்மேலும் இரக்கமாயிருக்கத்தக்கதாக, தேவன் எல்லாரையும் கீழ்ப்படியாமைக்குள்ளே அடைத்துப்போட்டார்.
	<br /><br />
	33. ஆ! தேவனுடைய ஐசுவரியம், ஞானம், அறிவு என்பவைகளின் ஆழம் எவ்வளவாயிருக்கிறது! அவருடைய நியாயத்தீர்ப்புகள் அளவிடப்படாதவைகள், அவருடைய வழிகள் ஆராயப்படாதவைகள்!
	<br /><br />
	34. கர்த்தருடைய சிந்தையை அறிந்தவன் யார்? அவருக்கு ஆலோசனைக்காரனாயிருந்தவன் யார்?
	<br /><br />
	35. தனக்குப் பதில்கிடைக்கும்படிக்கு முந்தி அவருக்கு ஒன்றைக் கொடுத்தவன் யார்?
	<br /><br />
	36. சகலமும் அவராலும் அவர் மூலமாயும் அவருக்காகவும் இருக்கிறது; அவருக்கே என்றென்றைக்கும் மகிமையுண்டாவதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans11