import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 13 </title>
	<meta
          name="description"
          content="Ezekiel 13 | எசேக்கியேல்  13 |
          Ezekiel 13 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, தீர்க்கதரிசனம் சொல்லுகிற இஸ்ரவேலின் தீர்க்கதரிசிகளுக்கு விரோதமாக நீ தீர்க்கதரிசனம் உரைத்து, தங்கள் இருதயத்தில் இருக்கிறதையே எடுத்துத் தீர்க்கதரிசனஞ் சொல்லுகிறவர்களோடே நீ சொல்லவேண்டியது என்னவென்றால்: கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	3. கர்த்தராகிய ஆண்டவர் உரைக்கிறதாவது: தாங்கள் ஒன்றும் தரிசியாதிருந்தும், தங்களுடைய ஆவியின் ஏவுதலைப் பின்பற்றுகிற மதிகெட்ட தீர்க்கதரிசிகளுக்கு ஐயோ!
	<br /><br />
	4. இஸ்ரவேலே, உன் தீர்க்கதரிசிகள் வனாந்தரங்களிலுள்ள நரிகளுக்கு ஒப்பாயிருக்கிறார்கள்.
	<br /><br />
	5. நீங்கள் கர்த்தருடைய நாளிலே யுத்தத்தில் நிலைநிற்கும்படிக்கு, திறப்புகளில் ஏறினதுமில்லை; இஸ்ரவேல் வம்சத்தாருக்காகச் சுவரை அடைத்ததுமில்லை.
	<br /><br />
	6. கர்த்தர் தங்களை அனுப்பாதிருந்தும், கர்த்தர் உரைத்தார் என்று சொல்லி, அவர்கள் அபத்தத்தையும் பொய்க்குறியையும் தரிசித்து, காரியத்தை நிர்வாகம்பண்ணலாமென்று நம்பிக்கையாயிருக்கிறார்கள்.
	<br /><br />
	7. நான் உரைக்காதிருந்தும், நீங்கள் கர்த்தர் உரைத்தார் என்று சொல்லும்போது, அபத்தமான தரிசனையைத் தரிசித்து, பொய்க்குறியைச் சொல்லுகிறீர்கள் அல்லவா?
	<br /><br />
	8. ஆகையால் கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: நீங்கள் அபத்தமானதைச் சொல்லி, பொய்யானதைத் தரிசிக்கிறபடியினால், இதோ, நான் உங்களுக்கு விரோதமானவர் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	9. அபத்தமானதைத் தரிசித்து, பொய்க்குறியைச் சொல்லுகிற தீர்க்கதரிசிகளுக்கு என் கை விரோதமாயிருக்கும்; அவர்கள் என் ஜனத்தின் சங்கத்தில் இருப்பதுமில்லை; இஸ்ரவேல் வம்சத்தாரின் அட்டவணையில் எழுதப்படுவதுமில்லை; இஸ்ரவேல் தேசத்துக்குள் பிரவேசிப்பதுமில்லை; அப்பொழுது நான் கர்த்தராகிய ஆண்டவரென்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	10. சமாதானம் இல்லாதிருந்தும் சமாதானமென்று சொல்லி, அவர்கள் என் ஜனத்தை மோசம்போக்குகிறார்கள்; ஒருவன் மண்சுவரை வைக்கிறான்; இதோ, மற்றவர்கள் சாரமில்லாத சாந்தை அதற்குப் பூசுகிறார்கள்.
	<br /><br />
	11. சாரமில்லாத சாந்தைப் பூசுகிறவர்களை நோக்கி: அது இடிந்துவிழுமென்று சொல்; வெள்ளமாகப் பெருகுகிற மழை பெய்யும்; மகா கல்மழையே, நீ சொரிவாய்; கொடிய புசல்காற்றும் அதைப் பிளக்கும்.
	<br /><br />
	12. இதோ, சுவர் விழும்போது: நீங்கள் பூசின பூச்சு எங்கே என்று சொல்வார்கள் அல்லவா?
	<br /><br />
	13. ஆகையால் என் உக்கிரத்திலே கொடிய புசல்காற்றை எழும்பி அடிக்கப்பண்ணுவேன்; என் கோபத்திலே வெள்ளமாக அடிக்கிற மழையும், என் உக்கிரத்திலே நிர்மூலமாக்கத்தக்க பெருங்கல் மழையும் சொரியும் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	14. அப்பொழுது நீங்கள் சாரமில்லாத சாந்தைப் பூசின சுவரை நான் இடித்து, அதின் அஸ்திபாரம் திறந்துகிடக்கும்படி அதைத் தரையிலே விழப்பண்ணுவேன்; உள்ளே இருக்கிற நீங்கள் நிர்மூலமாகும்படி அது விழும்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	15. இப்படிச் சுவரிலும், அதற்குச் சாரமில்லாத சாந்தைப் பூசினவர்களிலும் நான் என் உக்கிரத்தைத் தீர்த்துக்கொண்டு: சுவருமில்லை, அதற்குச் சாந்து பூசினவர்களுமில்லை.
	<br /><br />
	16. எருசலேமைக்குறித்துத் தீர்க்கதரிசனஞ்சொல்லி, சமாதானம் இல்லாதிருந்தும் சமாதானம் உண்டென்று தரிசனங்காண்கிற இஸ்ரவேலின் தீர்க்கதரிசிகள் இல்லாமற்போவார்கள் என்று உங்களுக்குச் சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	17. மனுபுத்திரனே, தங்களுடைய இருதயத்தில் இருக்கிறதையே எடுத்து, தீர்க்கதரிசனஞ்சொல்லுகிற உன் ஜனத்தின் குமாரத்திகளுக்கு எதிராக உன் முகத்தைத் திருப்பி, அவர்களுக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து, சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	18. ஆத்துமாக்களை வேட்டையாடும்படிக்குச் சகல கைகளுக்கும் தழுவணைகளைத் தைத்து, அந்தந்த வயதுள்ளவர்களுடைய தலைக்கும் தலையணைகளை உண்டு பண்ணுகிறவர்களுக்கு ஐயோ! நீங்கள் என் ஜனத்தின் ஆத்துமாக்களை வேட்டையாடி, அவைகளை உங்களுக்கு உயிரோடே காப்பாற்றுவீர்களோ?
	<br /><br />
	19. சாகத்தகாத ஆத்துமாக்களைக் கொல்வதற்கும், உயிரோடே இருக்கத்தகாத ஆத்துமாக்களை உயிரோடே காப்பாற்றுவதற்குமாக நீங்கள் பொய்க்குச் செவிகொடுக்கிற என் ஜனத்துக்குப் பொய் சொல்லுகிறதினாலே சில சிரங்கை வாற்கோதுமைக்காகவும் அப்பத்துண்டுகளுக்காகவும் என்னை என் ஜனத்துக்குள்ளே பரிசுத்தக்குலைச்சலாக்குவீர்களோ என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	20. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நீங்கள் ஆத்துமாக்களைப் பறக்கடிக்கும்படி வேட்டையாடுகிற உங்கள் தழுவணைகளுக்கு விரோதமாக நான் வந்து, அவைகளை உங்கள் புயங்களிலிருந்து பிடுங்கிக் கிழித்து, நீங்கள் பறக்கடிக்க வேட்டையாடுகிற ஆத்துமாக்களை நான் விடுதலை பண்ணி,
	<br /><br />
	21. உங்கள் தலையணைகளைக் கிழித்து, என் ஜனத்தை உங்கள் கைகளுக்கு நீங்கலாக்கிவிடுவேன்; அவர்கள் இனி வேட்டையாடப்படும்படி உங்கள் கைகளில் இரார்கள்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	22. நான் சஞ்சலப்படுத்தாத நீதிமானின் இருதயத்தை நீங்கள் வீணாய் முறியப்பண்ணினபடியினாலும், துன்மார்க்கன் தன் பொல்லாத வழியைவிட்டுத் திரும்பவும் நான் அவனை உயிரோடே காக்கவுங் கூடாதபடிக்கு நீங்கள் அவனுடைய கைகளைத் திடப்படுத்தினபடியினாலும்,
	<br /><br />
	23. நீங்கள் இனி அபத்தமானதைத் தரிசிப்பதுமில்லை, சாஸ்திரம் பார்ப்பதுமில்லை; நான் என் ஜனத்தை உங்கள் கைகளுக்கு நீங்கலாக்கிவிடுவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial13