import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 11 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 11 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 11 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் கிறிஸ்துவைப் பின்பற்றுகிறதுபோல, நீங்கள் என்னைப் பின்பற்றுகிறவர்களாயிருங்கள்.
	<br /><br />
	2. சகோதரரே, நீங்கள் எல்லாவற்றிலும் என்னை நினைத்துக்கொண்டு, நான் உங்களுக்கு ஒப்புவித்தபடி நீங்கள் கட்டளைகளைக் கைக்கொண்டு வருகிறதினிமித்தம் உங்களைப் புகழுகிறேன்.
	<br /><br />
	3. ஒவ்வொரு புருஷனுக்கும் கிறிஸ்து தலையாயிருக்கிறாரென்றும், ஸ்திரீக்குப் புருஷன் தலையாயிருக்கிறானென்றும், கிறிஸ்துவுக்குத் தேவன் தலையாயிருக்கிறாரென்றும், நீங்கள் அறியவேண்டுமென்று விரும்புகிறேன்.
	<br /><br />
	4. ஜெபம்பண்ணுகிறபோதாவது, தீர்க்கதரிசனஞ் சொல்லுகிறபோதாவது, தன் தலையை மூடிக்கொண்டிருக்கிற எந்தப் புருஷனும் தன் தலையைக் கனவீனப்படுத்துகிறான்.
	<br /><br />
	5. ஜெபம்பண்ணுகிறபோதாவது, தீர்க்கதரிசனஞ் சொல்லுகிறபோதாவது, தன் தலையை மூடிக்கொள்ளாதிருக்கிற எந்த ஸ்திரீயும் தன் தலையைக் கனவீனப்படுத்துகிறாள்; அது அவளுக்குத் தலை சிரைக்கப்பட்டதுபோலிருக்குமே.
	<br /><br />
	6. ஸ்திரீயானவள் முக்காடிட்டுக்கொள்ளாவிட்டால் தலைமயிரையும் கத்தரித்துப்போடக்கடவள்; தலைமயிர் கத்தரிக்கப்படுகிறதும் சிரைக்கப்படுகிறதும் ஸ்திரீக்கு வெட்கமானால் முக்காடிட்டுக்கொண்டிருக்கக்கடவள்.
	<br /><br />
	7. புருஷனானவன் தேவனுடைய சாயலும் மகிமையுமாயிருக்கிறபடியால், தன் தலையை மூடிக்கொள்ளவேண்டுவதில்லை; ஸ்திரீயானவள் புருஷனுடைய மகிமையாயிருக்கிறாள்.
	<br /><br />
	8. புருஷன் ஸ்திரீயிலிருந்து தோன்றினவனல்ல, ஸ்திரீயே புருஷனிலிருந்து தோன்றினவள்.
	<br /><br />
	9. புருஷன் ஸ்திரீக்காகச் சிருஷ்டிக்கப்பட்டவனல்ல, ஸ்திரீயே புருஷனுக்காகச் சிருஷ்டிக்கப்பட்டவள்.
	<br /><br />
	10. ஆகையால் தூதர்களினிமித்தம் ஸ்திரீயானவள் தலையின்மேல் முக்காடிட்டுக்கொள்ளவேண்டும்.
	<br /><br />
	11. ஆகிலும் கர்த்தருக்குள் ஸ்திரீயில்லாமல் புருஷனுமில்லை, புருஷனில்லாமல் ஸ்திரீயுமில்லை.
	<br /><br />
	12. ஸ்திரீயானவள் புருஷனிலிருந்து தோன்றுகிறதுபோல, புருஷனும் ஸ்திரீயினால் தோன்றுகிறான்; சகலமும் தேவனால் உண்டாயிருக்கிறது.
	<br /><br />
	13. ஸ்திரீயானவள் தேவனை நோக்கி ஜெபம்பண்ணுகையில், தன் தலையை மூடிக்கொள்ளாமலிருக்கிறது இலட்சணமாயிருக்குமோ என்று உங்களுக்குள்ளே நிதானித்துக்கொள்ளுங்கள்.
	<br /><br />
	14. புருஷன் மயிரை நீளமாய் வளர்க்கிறது அவனுக்கு கனவீனமாயிருக்கிறதென்றும்,
	<br /><br />
	15. ஸ்திரீ தன் மயிரை நீளமாய் வளர்க்கிறது அவளுக்கு மகிமையாயிருக்கிறதென்றும் சுபாவமே உங்களுக்குப் போதிக்கிறதில்லையா? தலைமயிர் அவளுக்கு முக்காடாகக் கொடுக்கப்பட்டிருக்கிறதே.
	<br /><br />
	16. ஆகிலும் ஒருவன் வாக்குவாதஞ்செய்ய மனதாயிருந்தால், எங்களுக்கும், தேவனுடைய சபைகளுக்கும், அப்படிப்பட்ட வழக்கமில்லையென்று அறியக்கடவன்.
	<br /><br />
	17. உங்களைப் புகழாமல் இதைக்குறித்து உங்களுக்குக் கட்டளைகொடுக்கிறேன்; நீங்கள் கூடிவருதல் நன்மைக்கேதுவாயிராமல், தீமைக்கேதுவாயிருக்கிறதே.
	<br /><br />
	18. முதலாவது, நீங்கள் சபையிலே கூடிவந்திருக்கும்போது, உங்களில் பிரிவினைகள் உண்டென்று கேள்விப்படுகிறேன்; அதில் சிலவற்றை நம்புகிறேன்.
	<br /><br />
	19. உங்களில் உத்தமர்கள் இன்னாரென்று வெளியாகும்படிக்கு மார்க்கபேதங்களும் உங்களுக்குள்ளே உண்டாயிருக்கவேண்டியதே.
	<br /><br />
	20. நீங்கள் ஓரிடத்தில் கூடிவரும்போது, அவனவன் தன்தன் சொந்த போஜனத்தை முந்திச் சாப்பிடுகிறான்; ஒருவன் பசியாயிருக்கிறான், ஒருவன் வெறியாயிருக்கிறான்.
	<br /><br />
	21. இப்படிச் செய்கிறது கர்த்தருடைய இராப்போஜனம்பண்ணுதலல்லவே.
	<br /><br />
	22. புசிக்கிறதற்கும் குடிக்கிறதற்கும் உங்களுக்கு வீடுகள் இல்லையா? தேவனுடைய சபையை அசட்டைபண்ணி, இல்லாதவர்களை வெட்கப்படுத்துகிறீர்களா? உங்களுக்கு நான் என்னசொல்லுவேன்? இதைக்குறித்து உங்களைப் புகழ்வேனோ? புகழேன்.
	<br /><br />
	23. நான் உங்களுக்கு ஒப்புவித்ததைக் கர்த்தரிடத்தில் பெற்றுக்கொண்டேன்; என்னவெனில், கர்த்தராகிய இயேசு தாம் காட்டிக்கொடுக்கப்பட்ட அன்று இராத்திரியிலே அப்பத்தை எடுத்து,
	<br /><br />
	24. ஸ்தோத்திரம்பண்ணி, அதைப் பிட்டு: நீங்கள் வாங்கிப் புசியுங்கள், இது உங்களுக்காகப் பிட்கப்படுகிற என்னுடைய சரீரமாயிருக்கிறது; என்னை நினைவுகூரும்படி இதைச் செய்யுங்கள் என்றார்.
	<br /><br />
	25. போஜனம்பண்ணினபின்பு, அவர் அந்தப்படியே பாத்திரத்தையும் எடுத்து: இந்தப் பாத்திரம் என் இரத்தத்தினாலாகிய புதிய உடன்படிக்கையாயிருக்கிறது; நீங்கள் இதைப் பானம்பண்ணும்போதெல்லாம் என்னை நினைவுகூரும்படி இதைச் செய்யுங்கள் என்றார்.
	<br /><br />
	26. ஆகையால் நீங்கள் இந்த அப்பத்தைப் புசித்து, இந்தப் பாத்திரத்தில் பானம்பண்ணும்போதெல்லாம் கர்த்தர் வருமளவும் அவருடைய மரணத்தைத் தெரிவிக்கிறீர்கள்.
	<br /><br />
	27. இப்படியிருக்க, எவன் அபாத்திரமாய்க் கர்த்தருடைய அப்பத்தைப் புசித்து, அவருடைய பாத்திரத்தில் பானம்பண்ணுகிறானோ, அவன் கர்த்தருடைய சரீரத்தையும் இரத்தத்தையும் குறித்துக் குற்றமுள்ளவனாயிருப்பான்.
	<br /><br />
	28. எந்த மனுஷனும் தன்னைத்தானே சோதித்தறிந்து, இந்த அப்பத்தில் புசித்து, இந்தப் பாத்திரத்தில் பானம்பண்ணக்கடவன்.
	<br /><br />
	29. என்னத்தினாலெனில், அபாத்திரமாய்ப் போஜனபானம்பண்ணுகிறவன், கர்த்தருடைய சரீரம் இன்னதென்று நிதானித்து அறியாததினால், தனக்கு ஆக்கினைத்தீர்ப்பு வரும்படி போஜனபானம்பண்ணுகிறான்.
	<br /><br />
	30. இதினிமித்தம், உங்களில் அநேகர் பலவீனரும் வியாதியுள்ளவர்களுமாயிருக்கிறார்கள்; அநேகர் நித்திரையும் அடைந்திருக்கிறார்கள்.
	<br /><br />
	31. நம்மை நாமே நிதானித்து அறிந்தால் நாம் நியாயந்தீர்க்கப்படோம்.
	<br /><br />
	32. நாம் நியாயந்தீர்க்கப்படும்போது உலகத்தோடே ஆக்கினைக்குள்ளாகத் தீர்க்கப்படாதபடிக்கு, கர்த்தராலே சிட்சிக்கப்படுகிறோம்.
	<br /><br />
	33. ஆகையால், என் சகோதரரே, நீங்கள் போஜனம்பண்ணக் கூடிவரும்போது, ஒருவருக்காக ஒருவர் காத்திருங்கள்.
	<br /><br />
	34. நீங்கள் ஆக்கினைக்கேதுவாகக் கூடிவராதபடிக்கு, ஒருவனுக்குப் பசியிருந்தால் வீட்டிலே சாப்பிடக்கடவன். மற்றக் காரியங்களை நான் வரும்போது திட்டம்பண்ணுவேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians11