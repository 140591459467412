import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah28 = () => {
  const verseNumber = 28;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 28 </title>
	<meta
          name="description"
          content="Jeremiah 28 | எரேமியா 28 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதாவுடைய ராஜாவாகிய சிதேக்கியா அரசாளத் துவக்கின நாலாம் வருஷம் ஐந்தாம் மாதத்திலே, அசூரின் குமாரனாகிய அனனியா என்னப்பட்ட கிபியோன் ஊரானாகிய தீர்க்கதரிசி கர்த்தருடைய ஆலயத்திலே ஆசாரியர்களும் சகல ஜனங்களும் பார்த்திருக்க என்னை நோக்கி:
	<br /><br />
	2. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால், பாபிலோன் ராஜாவின் நுகத்தை முறித்தேன்.
	<br /><br />
	3. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் இவ்விடத்திலிருந்து எடுத்துப் பாபிலோனுக்குக் கொண்டுபோன கர்த்தருடைய ஆலயத்தின் பணிமுட்டுகளையெல்லாம் நான் இரண்டு வருஷகாலத்திலே இவ்விடத்துக்குத் திரும்பக் கொண்டுவரப்பண்ணுவேன்.
	<br /><br />
	4. யோயாக்கீமின் குமாரனாகிய எகொனியா என்கிற யூதாவுடைய ராஜாவையும் பாபிலோனுக்குச் சிறையாகக் கொண்டுபோகப்பட்ட யூதர் அனைவரையும் நான் இவ்விடத்துக்குத் திரும்பிவரப்பண்ணுவேன்; பாபிலோன் ராஜாவின் நுகத்தை உடைப்பேன் என்றார் என்று சொன்னான்.
	<br /><br />
	5. அப்பொழுது எரேமியா தீர்க்கதரிசி ஆசாரியர்கள் பார்த்திருக்கவும், கர்த்தருடைய ஆலயத்தில் நின்றிருந்த ஜனங்களெல்லாரும் பார்த்திருக்கவும் அனனியா தீர்க்கதரிசியை நோக்கி:
	<br /><br />
	6. ஆமென், கர்த்தர் அப்படியே செய்வாராக; கர்த்தருடைய ஆலயத்தின் பணிமுட்டுகளையும் சிறைப்பட்டுப்போன அனைவரையும் பாபிலோனிலிருந்து திரும்பிவரப்பண்ணுவாரென்று நீ தீர்க்கதரிசனமாகச் சொன்ன உன் வார்த்தைகளைக் கர்த்தர் நிறைவேற்றுவாராக.
	<br /><br />
	7. ஆகிலும், உன் செவிகளும் சகல ஜனத்தின் செவிகளும் கேட்க நான் சொல்லும் வார்த்தையைக் கேள்.
	<br /><br />
	8. பூர்வகாலமுதல் எனக்கு முன்னும் உனக்கு முன்னும் இருந்த தீர்க்கதரிசிகள் அநேகம் தேசங்களுக்கு விரோதமாகவும், பெரிய ராஜ்யங்களுக்கு விரோதமாகவும், யுத்தத்தையும் பஞ்சத்தையும் கொள்ளைநோயையும் குறித்துத் தீர்க்கதரிசனம் சொன்னார்கள்.
	<br /><br />
	9. சமாதானம் வரும் என்று தீர்க்கதரிசி தீர்க்கதரிசனம் சொல்லியிருக்க, அந்தத் தீர்க்கதரிசி சொன்ன வார்த்தையின்படியே வந்தால், அப்பொழுது அவன் கர்த்தர் மெய்யாய் அனுப்பின தீர்க்கதரிசியாக விளங்குவானென்று எரேமியா தீர்க்கதரிசி சொன்னான்.
	<br /><br />
	10. அப்பொழுது அனனியா என்கிற தீர்க்கதரிசி எரேமியா தீர்க்கதரிசியின் கழுத்திலிருந்த நுகத்தை எடுத்து அதை உடைத்துப்போட்டான்.
	<br /><br />
	11. பின்பு அனனியா சகல ஜனங்களுக்கு முன்பாகவும்: இந்தப்பிரகாரமாக இரண்டு வருஷகாலத்திலே பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாருடைய நுகத்தைச் சகல ஜாதிகளின் கழுத்திலுமிருந்து விலக உடைத்துப்போடுவேன் என்று கர்த்தர் சொல்லுகிறார் என்றான். அப்பொழுது எரேமியா தீர்க்கதரிசி தன் வழியே போனான்.
	<br /><br />
	12. அனனியா என்கிற தீர்க்கதரிசி எரேமியா தீர்க்கதரிசியின் கழுத்திலிருந்த நுகத்தை உடைத்துப்போட்டபிற்பாடு, கர்த்தருடைய வார்த்தை எரேமியாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	13. நீ போய், அனனியாவை நோக்கி: நீ மரநுகத்தை உடைத்தாய்; அதற்குப் பதிலாக இருப்பு நுகத்தை உண்டுபண்ணு என்று கர்த்தர் சொன்னார்.
	<br /><br />
	14. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரைச் சேவிக்கும்படிக்கு இருப்பு நுகத்தை இந்த எல்லா ஜாதிகளுடைய கழுத்தின்மேலும் போட்டேன்; அவர்கள் அவனைச் சேவிப்பார்கள். வெளியின் மிருகஜீவன்களையும் அவனுக்கு ஒப்புக்கொடுத்தேன் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	15. பின்பு எரேமியா தீர்க்கதரிசி அனனியா என்கிற தீர்க்கதரிசியை நோக்கி: இப்போதும் அனனியாவே, கேள்; கர்த்தர் உன்னை அனுப்பினதில்லை; நீயோ இந்த ஜனத்தைப் பொய்யை நம்பும்படிச் செய்தாய்.
	<br /><br />
	16. ஆகையால், இதோ, உன்னைப் பூமியின்மேல் இராதபடிக்கு அகற்றிவிடுவேன்; இந்த வருஷத்திலே நீ சாவாய் என்று கர்த்தர் சொல்லுகிறார்; கர்த்தருக்கு விரோதமாய்க் கலகம் உண்டாகப் பேசினாயே என்றான்.
	<br /><br />
	17. அப்படியே அனனியா என்கிற தீர்க்கதரிசி அவ்வருஷத்திலேதானே ஏழாம் மாதத்தில் செத்துப்போனான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah28