import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial24 = () => {
  const verseNumber = 24;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 24 </title>
	<meta
          name="description"
          content="Ezekiel 24 | எசேக்கியேல்  24 |
          Ezekiel 24 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஒன்பதாம் வருஷம் பத்தாம் மாதம் பத்தாந்தேதியிலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, இந்த நாளின் பேரையும் இந்தத் தேதியையும் நீ எழுதிவை, இந்தத் தேதியில்தானே பாபிலோன் ராஜா எருசலேமில் பாளயமிறங்கினான்.
	<br /><br />
	3. இப்போதும் நீ கலகவீட்டாருக்கு ஒரு உபமானத்தைக் காண்பித்து, அவர்களை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், ஒரு கொப்பரையை அடுப்பிலே வை; அதை அடுப்பிலே வைத்து, அதிலே தண்ணீரை விடு.
	<br /><br />
	4. சகல நல்ல கண்டங்களான பின்னந்தொடைகளும் முன்னந்தொடைகளுமாகிய கண்டங்களைச் சேர்த்து அதிலே போடு; நல்ல எலும்புகளால் அதை நிரப்பு.
	<br /><br />
	5. ஆட்டுமந்தையில் தெரிந்துகொள்ளப்பட்டதை அதற்காகக் கொண்டுவந்து, எலும்புகளை அதின் கீழே குவித்து எரிக்கவேண்டும்; அதிலுள்ள எலும்புகளும் வேகத்தக்கதாக அதைப் பொங்கப்பொங்கக் காய்ச்சவேண்டும்.
	<br /><br />
	6. இதற்காகக் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நுரை ஒட்டிக்கொண்டிருக்கிறதும் நுரை நீங்காததுமாகிய கொப்பரை என்னப்பட்ட இரத்தஞ்சிந்திய நகரத்துக்கு ஐயோ! அதில் இருக்கிறதைக் கண்டங்கண்டமாக எடுத்துக்கொண்டுபோ; அதின்பேரில் சீட்டுப்போடலாகாது.
	<br /><br />
	7. அவள் இரத்தம் அவள் நடுவில் இருக்கிறது; மண்ணிலே மறைந்து போகும்படி அதைத் தரையிலே ஊற்றாமல் கற்பாறையிலே ஊற்றிப்போட்டாள்.
	<br /><br />
	8. நீதியைச் சரிக்கட்டுவதற்காகக் கோபம் மூளும்படி நான் அவள் இரத்தத்தை மறைக்காமல் கன்மலையின்மேல் வைத்தேன்.
	<br /><br />
	9. ஆதலால், கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இரத்தஞ்சிந்திய நகரத்துக்கு ஐயோ! நான் பெரிதான கட்டைகளைக் குவித்து எரியப்பண்ணுவேன்.
	<br /><br />
	10. திரளான விறகுகளைக் கூட்டு, தீயை மூட்டு, இறைச்சியை முறுகவேவித்துச் சம்பாரங்களை இடு; எலும்புகளை எரித்துப்போடு.
	<br /><br />
	11. பின்பு கொப்பரை காய்ந்து, அதின் களிம்பு வெந்து, அதற்குள் இருக்கிற அதின் அழுக்கு உருகி, அதின் நுரை நீங்கும்படி அதை வெறுமையாகத் தழலின்மேல் வை.
	<br /><br />
	12. அது மகா வருத்தத்தை உண்டாக்கியும், அதின் திரளான நுரை அதை விட்டு நீங்கவில்லை; அதின் நுரை அக்கினிக்கு உள்ளாகவேண்டியது.
	<br /><br />
	13. உன் அசுத்தத்தோடே முறைகேடும் இருக்கிறது; நான் உன்னைச் சுத்திகரித்தும், நீ சுத்தமாகாதபடியினால், இனி என் உக்கிரம் உன்னில் ஆறித்தீருமட்டும் உன் அசுத்தம் நீங்கிச் சுத்திகரிக்கப்படமாட்டாய்.
	<br /><br />
	14. கர்த்தராகிய நான் இதைச் சொன்னேன், இது நிறைவேறும், நான் இதைச் செய்வேன்; நான் பின்வாங்குவதும் தப்பவிடுவதும் மனஸ்தாபப்படுவதும் இல்லை; உன் வழிகளுக்கும் உன் செய்கைகளுக்குந்தக்கதாக உன்னை நியாயந்தீர்ப்பார்களென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	16. மனுபுத்திரனே, இதோ, நான் உன் கண்களுக்கு விருப்பமானவளை ஒரே அடியினாலே உன்னைவிட்டு எடுத்துக்கொள்ளுவேன்; ஆனாலும் நீ புலம்பாமலும் அழாமலும் கண்ணீர்விடாமலும் இருப்பாயாக.
	<br /><br />
	17. அலறாமல் பெருமூச்சுவிடு, இழவு கொண்டாடவேண்டாம்; உன் பாகையை உன் தலையிலே கட்டி, உன் பாதரட்சைகளை உன் பாதங்களில் தொடுத்துக்கொள்; உன் தாடியை மூடாமலும் துக்கங்கொண்டாடுகிறவர்களின் அப்பத்தைப் புசியாமலும் இருக்கக்கடவாய் என்றார்.
	<br /><br />
	18. விடியற்காலத்தில் நான் ஜனங்களோடே பேசினேன்; அன்று சாயங்காலத்தில் என் மனைவி செத்துப்போனாள்; எனக்குக் கட்டளையிட்டபடியே விடியற்காலத்தில் செய்தேன்.
	<br /><br />
	19. அப்பொழுது ஜனங்கள் என்னை நோக்கி: நீர் செய்கிறவைகள் எங்களுக்கு என்னத்திற்கு அடையாளம் என்பதை எங்களுக்குத் தெரிவிக்கமாட்டீரா என்று கேட்டார்கள்.
	<br /><br />
	20. நான் அவர்களுக்குப் பிரதியுத்தரமாக: கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	21. நீ இஸ்ரவேல் வீட்டாரை நோக்கி, கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், இதோ, உங்கள் பலத்தின் முக்கியமும் உங்கள் கண்களின் விருப்பமும் உங்கள் ஆத்துமாவின் வாஞ்சையுமாகிய என் பரிசுத்த ஸ்தலத்தை நான் பரிசுத்தக்குலைச்சலாக்குகிறேன்; நீங்கள் விட்டுவந்த உங்கள் குமாரரும் உங்கள் குமாரத்திகளும் பட்டயத்தால் விழுவார்கள்.
	<br /><br />
	22. அப்பொழுது நான் செய்ததுபோல நீங்களும் செய்வீர்கள்; தாடியை மூடாமலும் துக்கங்கொண்டாடுகிறவர்களின் அப்பத்தைப் புசியாமலும் இருப்பீர்கள்.
	<br /><br />
	23. உங்கள் பாகைகள் உங்கள் தலைகளிலும், உங்கள் பாதரட்சைகள் உங்கள் கால்களிலும் இருக்கும்; நீங்கள் புலம்பாமலும் அழாமலும் இருந்து, உங்கள் அக்கிரமங்களில் வாடிப்போய், ஒருவரையொருவர் பார்த்துத் தவிப்பீர்கள்.
	<br /><br />
	24. அப்படியே எசேக்கியேல் உங்களுக்கு அடையாளமாக இருப்பான்; அவன் செய்தபடி எல்லாம் நீங்களும் செய்வீர்கள்; இப்படி வரும்போது நான் கர்த்தராகிய ஆண்டவர் என்று அறிந்துகொள்வீர்கள் என்று சொல் என்று உரைத்தார் என்றேன்.
	<br /><br />
	25. பின்னும் மனுபுத்திரனே, நான் எந்த நாளிலே அவர்களுடைய பலத்தையும், அவர்களுடைய அலங்காரத்தின் மகிழ்ச்சியையும், அவர்களுடைய கண்களின் விருப்பத்தையும், அவர்களுடைய ஆத்துமாவின் விசேஷித்த வாஞ்சையையும், அவர்களுடைய குமாரரையும், அவர்களுடைய குமாரத்திகளையும் அவர்களைவிட்டு எடுத்துக்கொள்ளுகிறேனோ,
	<br /><br />
	26. அந்த நாளிலேதானே தப்பிவந்த ஒருவன் உன்னிடத்தில் வந்து உன் காதுகள் கேட்கச் சொல்லுவான் அல்லவோ?
	<br /><br />
	27. அந்த நாளிலேதானே உன் வாய் திறக்கப்பட்டு, நீ தப்பிவந்தவனோடே பேசுவாய்; இனி மவுனமாயிருக்கமாட்டாய்; இப்படி நீ அவர்களுக்கு அடையாளமாக இருப்பாய்; நான் கர்த்தர் என்று அப்பொழுது அறிந்துகொள்வார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial24