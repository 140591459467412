import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah30 = () => {
  const verseNumber = 30;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 30 </title>
	<meta
          name="description"
          content="Isaiah 30 | ஏசாயா 30 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பாவத்தோடே பாவத்தைக் கூட்டும்படி, என்னை அல்லாமல் ஆலோசனைபண்ணி, என் ஆவியை அல்லாமல் தங்களை மூடிக்கொள்ளப் பார்க்கிறவர்களும்,
	<br /><br />
	2. என் வாக்கைக் கேளாமல் பார்வோனின் பெலத்தினாலே பெலக்கவும், எகிப்தின் நிழலிலே ஒதுங்கவும் வேண்டும் என்று எகிப்துக்குப் போகிறவர்களுமாகிய முரட்டாட்டமுள்ள புத்திரருக்கு ஐயோ! என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. பார்வோனுடைய பெலன் உங்களுக்கு வெட்கமாகவும் எகிப்தினுடைய நிழலொதுக்கு உங்களுக்கு இலச்சையாகவும் இருக்கும்.
	<br /><br />
	4. அவர்கள் பிரபுக்கள் சோவானிலே போய், அவர்கள் ஸ்தானாபதிகள் ஆனேஸ்மட்டும் சேருகிறார்கள்.
	<br /><br />
	5. ஆனாலும் தங்கள் சகாயத்துக்கும் பிரயோஜனத்துக்கும் உதவாமல், வெட்கத்துக்கும் நிந்தைக்குமே உதவும் ஜனத்தினாலே யாவரும் வெட்கப்படுவார்கள்.
	<br /><br />
	6. தெற்கேபோகிற மிருகஜீவன்களின் பாரம். துஷ்டசிங்கமும், கிழச்சிங்கமும், விரியனும், பறக்கிற கொள்ளிவாய்ச்சர்ப்பமும் வருகிறதும், நெருக்கமும் இடுக்கமும் அடைவிக்கிறதுமான தேசத்துக்கு, அவர்கள் கழுதை மறிகளுடைய முதுகின்மேல் தங்கள் ஆஸ்திகளையும், ஒட்டகங்களுடைய முதுகின்மேல் தங்கள் பொக்கிஷங்களையும், தங்களுக்கு உதவாத ஜனத்தண்டைக்கு ஏற்றிக்கொண்டுபோகிறார்கள்.
	<br /><br />
	7. எகிப்தியர் சகாயம்பண்ணுவது வியர்த்தமும் வீணுமாம்; ஆகையால், சும்மாயிருப்பதே அவர்களுக்குப் பெலன் என்று அதற்காகக் கூறுகிறேன்.
	<br /><br />
	8. இப்பொழுது நீ போய், இது பிற்காலத்துக்கு என்றென்றைக்கும் இருக்கும்படி, இதை அவர்களுக்கு முன்பாக ஒரு பலகையில் எழுதி, ஒரு புஸ்தகத்தில் வரை.
	<br /><br />
	9. இவர்கள் கலகமுள்ள ஜனங்களும், பொய்பேசுகிற பிள்ளைகளும், கர்த்தருடைய வேதத்தைக் கேட்க மனதில்லாத புத்திரருமாயிருக்கிறார்கள்.
	<br /><br />
	10. இவர்கள் தரிசனக்காரரை நோக்கி: தரிசனங் காணவேண்டாம் என்றும், ஞானதிருஷ்டிக்காரரை நோக்கி: யதார்த்தமாய் எங்களுக்குத் தரிசனஞ்சொல்லாமல், எங்களுக்கு இதமான சொற்களை உரைத்து, மாயமானவைகளைத் திருஷ்டியுங்கள் என்றும்,
	<br /><br />
	11. நீங்கள் வழியை விட்டு, பாதையினின்று விலகி, இஸ்ரவேலின் பரிசுத்தரை எங்கள் முன்பாக இராமல் ஓயப்பண்ணுங்கள் என்றும் சொல்லுகிறார்கள்.
	<br /><br />
	12. நீங்கள் இந்த வார்த்தையை வெறுத்து, இடுக்கமும் தாறுமாறும் செய்கிறதை நம்பி, அதைச் சார்ந்துகொள்ளுகிறபடியால்,
	<br /><br />
	13. இந்த அக்கிரமம் உங்களுக்கு உயர்ந்த சுவரில் விழப் பிதுங்கிநிற்கிறதும், திடீரென்று சடிதியாய் இடியப்போகிறதுமான வெடிப்பைப்போல இருக்கும் என்று இஸ்ரவேலின் பரிசுத்தர் சொல்லுகிறார்.
	<br /><br />
	14. அவர்களை அவர் தப்பவிடாமல் உடையும்படி குயக்கலத்தை நொறுக்குவதுபோல அவர்களை நொறுக்குவார்; அடுப்பிலே நெருப்பு எடுக்கிறதற்கும், குளத்திலே தண்ணீர் மொள்ளுகிறதற்கும் நொறுங்கின துண்டுகளில், ஒரு ஓடாகிலும் அகப்படாதேபோம்.
	<br /><br />
	15. நீங்கள் மனந்திரும்பி அமர்ந்திருந்தால் இரட்சிக்கப்படுவீர்கள்; அமரிக்கையும் நம்பிக்கையுமே உங்கள் பெலனாயிருக்கும் என்று இஸ்ரவேலின் பரிசுத்தராயிருக்கிற கர்த்தராகிய தேவன் சொல்லுகிறார்; நீங்களோ அப்படிச் செய்ய மனதாயிராமல்;
	<br /><br />
	16. அப்படியல்ல, குதிரைகளின்மேல் ஏறி ஓடிப்போவோம் என்கிறீர்கள்; அப்படியே ஓடிப்போவீர்கள், வேகமான வாகனங்களின்மேல் ஏறிப்போவோம் என்கிறீர்கள்; அப்படியே உங்களைத் துரத்துகிறவர்கள் வேகமாய்த் துரத்துவார்கள்.
	<br /><br />
	17. நீங்கள் மலையுச்சியின்மேல் ஒரு கம்பத்தைப்போலவும், மேட்டின்மேல் ஒரு கொடியைப்போலவும் மீந்திருக்குமட்டாக, ஒருவன் பயமுறுத்த ஆயிரம்பேரும், ஐந்துபேர் பயமுறுத்த நீங்கள் அனைவரும் ஓடிப்போவீர்கள்.
	<br /><br />
	18. ஆனாலும் உங்களுக்கு இரங்கும்படி கர்த்தர் காத்திருப்பார், உங்கள்மேல் மனதுருகும்படி எழுந்திருப்பார்; கர்த்தர் நீதிசெய்கிற தேவன்; அவருக்குக் காத்திருக்கிற அனைவரும் பாக்கியவான்கள்.
	<br /><br />
	19. சீயோனைச் சேர்ந்த ஜனங்கள் எருசலேமில் வாசமாயிருப்பார்கள்; இனி நீ அழுதுகொண்டிராய்; உன் கூப்பிடுதலின் சத்தத்துக்கு அவர் உருக்கமாய் இரங்கி, அதைக் கேட்டவுடனே உனக்கு மறுஉத்தரவு அருளுவார்.
	<br /><br />
	20. ஆண்டவர் உங்களுக்குத் துன்பத்தின் அப்பத்தையும், உபத்திரவத்தின் தண்ணீரையும் கொடுத்தாலும், உன் போதகர்கள் இனி ஒருபோதும் மறைந்திருக்கமாட்டார்கள்; உன் கண்கள் உன் போதகர்களைக் காணும்.
	<br /><br />
	21. நீங்கள் வலதுபுறமாய்ச் சாயும்போதும், இடதுபுறமாய்ச் சாயும்போதும்: வழி இதுவே, இதிலே நடவுங்கள் என்று உங்களுக்குப் பின்னாலே சொல்லும் வார்த்தையை உங்கள் காதுகள் கேட்கும்.
	<br /><br />
	22. உன் சுரூபங்களை மூடிய வெள்ளித்தகட்டையும், உன் விக்கிரகங்களின் பொன் ஆடையாபரணத்தையும் தீட்டாக எண்ணி, அவைகளைத் தீட்டுப்பட்ட வஸ்திரம்போல எறிந்துவிட்டு, அதைச் சீ! போ என்பாய்.
	<br /><br />
	23. அப்பொழுது நீ நிலத்தில் விதைக்கும் உன் விதைக்கு அவர் மழையையும், நிலத்தின் பலனாகிய ஆகாரத்தையும் தருவார்; அது கொழுமையும் புஷ்டியுமாய் இருக்கும்; அக்காலத்திலே உன் ஆடுமாடுகள் விஸ்தாரமான மேய்ச்சலுள்ள ஸ்தலத்திலே மேயும்;
	<br /><br />
	24. நிலத்தை உழுகிற எருதுகளும் கழுதைமறிகளும், முறத்தினாலும் தூற்றுக்கூடையினாலும் தூற்றப்பட்ட ருசியுள்ள கப்பிகளைத் தின்னும்.
	<br /><br />
	25. கோபுரங்கள் விழுகிற மகா சங்காரத்தின் நாளிலே, உயரமான சகல மலைகளின்மேலும், உயரமான சகல மேடுகளின்மேலும் ஆறுகளும் வாய்க்கால்களும் உண்டாகும்.
	<br /><br />
	26. கர்த்தர் தமது ஜனத்தின் முறிவைக் கட்டி, அதின் அடிக்காயத்தைக் குணமாக்கும் நாளிலே, சந்திரனுடைய வெளிச்சம் சூரியனுடைய வெளிச்சத்தைப்போலவும், சூரியனுடைய வெளிச்சம் ஏழத்தனையாய் ஏழு பகலின் வெளிச்சத்தைப்போலவும் இருக்கும்.
	<br /><br />
	27. இதோ, கர்த்தருடைய நாமம் தூரத்திலிருந்து வரும்; அவருடைய கோபம் எரிகிறதும் கனன்று புகைகிறதுமாயிருக்கும்; அவருடைய உதடுகள் சினத்தால் நிறைந்து, அவருடைய நாவு பட்சிக்கிற அக்கினிபோல இருக்கும்.
	<br /><br />
	28. நாசம் என்னும் சல்லடையிலே ஜாதிகளை அரிக்கும்படிக்கு அவர் ஊதும் சுவாசம் கழுத்துமட்டும் எட்டுகிற ஆற்றுவெள்ளத்தைப்போலவும், ஜனங்களுடைய வாயிலே போட்டு அலைக்கழிக்கிற கடிவாளத்தைப்போலவும் இருக்கும்.
	<br /><br />
	29. பண்டிகை ஆசரிக்கப்படும் இராத்திரியிலே பாடுகிறதுபோலப் பாடுவீர்கள்; கர்த்தருடைய பர்வதமாகிய இஸ்ரவேலின் கன்மலையண்டைக்குப்போக நாகசுரத்தோடே நடந்துவருகிறபோது மகிழுகிறதுபோல மகிழுவீர்கள்.
	<br /><br />
	30. கர்த்தர் மகத்துவமானவர்; தமது சத்தத்தைக் கேட்கப்பண்ணி, உக்கிர கோபத்தினாலும், பட்சிக்கிற அக்கினிஜுவாலையினாலும், இடி பெருவெள்ளம் கல்மழையினாலும், தமது புயத்தின் வல்லமையைக் காண்பிப்பார்.
	<br /><br />
	31. அப்பொழுது தண்டாயுதத்தினால் அடித்த அசீரியன் கர்த்தருடைய சத்தத்தினாலே நொறுங்குண்டுபோவான்.
	<br /><br />
	32. கர்த்தர் அவன்மேல் சுமத்தும் ஆக்கினைத்தண்டம் செல்லுமிடமெங்கும், மேளங்களும் வீணைகளும் அதினுடன் போகும்; கொடிய யுத்தங்களினால் அவனை எதிர்த்து யுத்தஞ்செய்வார்.
	<br /><br />
	33. தோப்பேத் ஏற்கனவே ஏற்படுத்தப்பட்டது; ராஜாவுக்கு அது ஆயத்தப்படுத்தப்பட்டது; அதை ஆழமும் விசாலமுமாக்கினார்; வேகும்படி அக்கினியும் மிகுந்த விறகுமுண்டு; கர்த்தரின் சுவாசம் கெந்தகத் தீயைப்போல அதைக் கொளுத்தும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah30