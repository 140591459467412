import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 4 </title>
	<meta
          name="description"
          content="John Chapter 4 | யோவான் அதிகாரம் 4 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோவானைப்பார்க்கிலும் இயேசு அநேகம் பேரைச் சீஷராக்கி ஞானஸ்நானங்கொடுக்கிறாரென்று பரிசேயர் கேள்விப்பட்டதாகக் கர்த்தர் அறிந்தபோது,
	<br /><br />
	2. யூதேயாவைவிட்டு மறுபடியுங் கலிலேயாவுக்குப் போனார்.
	<br /><br />
	3. இயேசு தாமே ஞானஸ்நானங்கொடுக்கவில்லை, அவருடைய சீஷர்கள் கொடுத்தார்கள்.
	<br /><br />
	4. அவர் சமாரியா நாட்டின் வழியாய்ப் போகவேண்டியதாயிருந்தபடியால்,
	<br /><br />
	5. யாக்கோபு தன் குமாரனாகிய யோசேப்புக்குக் கொடுத்த நிலத்துக்கு அருகே இருந்த சமாரியாவிலுள்ள சீகார் என்னப்பட்ட ஊருக்கு வந்தார்.
	<br /><br />
	6. அங்கே யாக்கோபுடைய கிணறு இருந்தது; இயேசு பிரயாணத்தினால் இளைப்படைந்தவராய் அந்தக் கிணற்றினருகே உட்கார்ந்தார்; அப்பொழுது ஏறக்குறைய ஆறாம்மணி வேளையாயிருந்தது.
	<br /><br />
	7. அவருடைய சீஷர்கள் போஜனபதார்த்தங்களைக் கொள்ளும்படி ஊருக்குள்ளே போயிருந்தார்கள்.
	<br /><br />
	8. அப்பொழுது சமாரியா நாட்டாளாகிய ஒரு ஸ்திரீ தண்ணீர் மொள்ள வந்தாள். இயேசு அவளை நோக்கி: தாகத்துக்குத் தா என்றார்.
	<br /><br />
	9. யூதர்கள் சமாரியருடனே சம்பந்தங்கலவாதவர்களானபடியால், சமாரியா ஸ்திரீ அவரை நோக்கி: நீர் யூதனாயிருக்க, சமாரியா ஸ்திரீயாகிய என்னிடத்தில், தாகத்துக்குத்தா என்று எப்படிக் கேட்கலாம் என்றாள்.
	<br /><br />
	10. இயேசு அவளுக்குப் பிரதியுத்தரமாக: நீ தேவனுடைய ஈவையும், தாகத்துக்குத்தா என்று உன்னிடத்தில் கேட்கிறவர் இன்னார் என்பதையும் அறிந்திருந்தாயானால், நீயே அவரிடத்தில் கேட்டிருப்பாய், அவர் உனக்கு ஜீவத்தண்ணீரைக் கொடுத்திருப்பார் என்றார்.
	<br /><br />
	11. அதற்கு அந்த ஸ்திரீ: ஆண்டவரே, மொண்டுகொள்ள உம்மிடத்தில் பாத்திரமில்லையே, கிணறும் ஆழமாயிருக்கிறதே, பின்னை எங்கேயிருந்து உமக்கு ஜீவத்தண்ணீர் உண்டாகும்.
	<br /><br />
	12. இந்தக் கிணற்றை எங்களுக்குத் தந்த நம்முடைய பிதாவாகிய யாக்கோபைப்பார்க்கிலும் நீர் பெரியவரோ? அவரும் அவர் பிள்ளைகளும் அவர் மிருகஜீவன்களும் இதிலே குடித்ததுண்டே என்றாள்.
	<br /><br />
	13. இயேசு அவளுக்குப் பிரதியுத்தரமாக: இந்தத் தண்ணீரைக் குடிக்கிறவனுக்கு மறுபடியும் தாகமுண்டாகும்.
	<br /><br />
	14. நான் கொடுக்கும் தண்ணீரைக் குடிக்கிறவனுக்கோ ஒருக்காலும் தாகமுண்டாகாது; நான் அவனுக்குக் கொடுக்கும் தண்ணீர் அவனுக்குள்ளே நித்திய ஜீவகாலமாய் ஊறுகிற நீரூற்றாயிருக்கும் என்றார்.
	<br /><br />
	15. அந்த ஸ்திரீ அவரை நோக்கி: ஆண்டவரே, எனக்குத் தாகமுண்டாகாமலும், நான் இங்கே மொண்டுகொள்ள வராமலுமிருக்கும்படி அந்தத் தண்ணீரை எனக்குத் தரவேண்டும் என்றாள்.
	<br /><br />
	16. இயேசு அவளை நோக்கி: நீ போய், உன் புருஷனை இங்கே அழைத்துக் கொண்டுவா என்றார்.
	<br /><br />
	17. அதற்கு அந்த ஸ்திரீ: எனக்குப் புருஷன் இல்லை என்றாள். இயேசு அவளை நோக்கி: எனக்குப் புருஷன் இல்லையென்று நீ சொன்னது சரிதான்.
	<br /><br />
	18. எப்படியெனில், ஐந்து புருஷர் உனக்கிருந்தார்கள், இப்பொழுது உனக்கிருக்கிறவன் உனக்குப் புருஷனல்ல, இதை உள்ளபடி சொன்னாய் என்றார்.
	<br /><br />
	19. அப்பொழுது அந்த ஸ்திரீ அவரை நோக்கி: ஆண்டவரே, நீர் தீர்க்கதரிசி என்று காண்கிறேன்.
	<br /><br />
	20. எங்கள் பிதாக்கள் இந்த மலையிலே தொழுதுகொண்டுவந்தார்கள்; நீங்கள் எருசலேமிலிருக்கிற ஸ்தலத்திலே தொழுதுகொள்ளவேண்டும் என்கிறீர்களே என்றாள்.
	<br /><br />
	21. அதற்கு இயேசு: ஸ்திரீயே, நான் சொல்லுகிறதை நம்பு, நீங்கள் இந்த மலையிலும் எருசலேமிலும் மாத்திரமல்ல, எங்கும் பிதாவைத் தொழுதுகொள்ளுங்காலம் வருகிறது.
	<br /><br />
	22. நீங்கள் அறியாததைத் தொழுதுகொள்ளுகிறீர்கள்; நாங்கள் அறிந்திருக்கிறதைத் தொழுதுகொள்ளுகிறோம்; ஏனென்றால் இரட்சிப்பு யூதர்கள் வழியாய் வருகிறது.
	<br /><br />
	23. உண்மையாய்த் தொழுதுகொள்ளுகிறவர்கள் பிதாவை ஆவியோடும் உண்மையோடும் தொழுதுகொள்ளுங்காலம் வரும், அது இப்பொழுதே வந்திருக்கிறது; தம்மைத் தொழுதுகொள்ளுகிறவர்கள் இப்படிப்பட்டவர்களாயிருக்கும்படி பிதாவானவர் விரும்புகிறார்.
	<br /><br />
	24. தேவன் ஆவியாயிருக்கிறார், அவரைத் தொழுதுகொள்ளுகிறவர்கள் ஆவியோடும் உண்மையோடும் அவரைத் தொழுதுகொள்ளவேண்டும் என்றார்.
	<br /><br />
	25. அந்த ஸ்திரீ அவரை நோக்கி: கிறிஸ்து என்னப்படுகிற மேசியா வருகிறார் என்று அறிவேன், அவர் வரும்போது எல்லாவற்றையும் நமக்கு அறிவிப்பார் என்றாள்.
	<br /><br />
	26. அதற்கு இயேசு: உன்னுடனே பேசுகிற நானே அவர் என்றார்.
	<br /><br />
	27. அத்தருணத்தில் அவருடைய சீஷர்கள் வந்து, அவர் ஸ்திரீயுடனே பேசுகிறதைப்பற்றி ஆச்சரியப்பட்டார்கள். ஆகிலும் என்ன தேடுகிறீரென்றாவது, ஏன் அவளுடனே பேசுகிறீரென்றாவது, ஒருவனும் கேட்கவில்லை.
	<br /><br />
	28. அப்பொழுது அந்த ஸ்திரீ, தன் குடத்தை வைத்துவிட்டு, ஊருக்குள்ளே போய், ஜனங்களை நோக்கி:
	<br /><br />
	29. நான் செய்த எல்லாவற்றையும் ஒரு மனுஷன் எனக்குச் சொன்னார்; அவரை வந்து பாருங்கள்; அவர் கிறிஸ்துதானோ என்றாள்.
	<br /><br />
	30. அப்பொழுது அவர்கள் ஊரிலிருந்து புறப்பட்டு அவரிடத்தில் வந்தார்கள்.
	<br /><br />
	31. இப்படி நடக்கையில் சீஷர்கள் அவரை நோக்கி: ரபீ, போஜனம்பண்ணும் என்று வேண்டிக்கொண்டார்கள்.
	<br /><br />
	32. அதற்கு அவர்: நான் புசிப்பதற்கு நீங்கள் அறியாத ஒரு போஜனம் எனக்கு உண்டு என்றார்.
	<br /><br />
	33. அப்பொழுது சீஷர்கள் ஒருவரையொருவர் பார்த்து: யாராவது அவருக்குப் போஜனம் கொண்டுவந்திருப்பானோ என்றார்கள்.
	<br /><br />
	34. இயேசு அவர்களை நோக்கி: நான் என்னை அனுப்பினவருடைய சித்தத்தின்படி செய்து அவருடைய கிரியையை முடிப்பதே என்னுடைய போஜனமாயிருக்கிறது.
	<br /><br />
	35. அறுப்புக்காலம் வருகிறதற்கு இன்னும் நாலுமாதம் செல்லும் என்று நீங்கள் சொல்லுகிறதில்லையா? இதோ, வயல் நிலங்கள் இப்பொழுதே அறுப்புக்கு விளைந்திருக்கிறதென்று உங்கள் கண்களை ஏறெடுத்துப்பாருங்கள் என்று நான் உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	36. விதைக்கிறவனும் அறுக்கிறவனுமாகிய இருவரும் ஒருமித்துச் சந்தோஷப்படத்தக்கதாக, அறுக்கிறவன் கூலியை வாங்கி, நித்திய ஜீவனுக்காகப் பலனைச் சேர்த்துக்கொள்ளுகிறான்.
	<br /><br />
	37. விதைக்கிறவன் ஒருவன் அறுக்கிறவன் ஒருவன் என்கிற மெய்யான வழக்கச்சொல் இதினாலே விளங்குகிறது.
	<br /><br />
	38. நீங்கள் பிரயாசப்பட்டுப் பயிரிடாததை அறுக்க நான் உங்களை அனுப்பினேன், மற்றவர்கள் பிரயாசப்பட்டார்கள், அவர்களுடைய பிரயாசத்தின் பலனை நீங்கள் பெற்றீர்கள் என்றார்.
	<br /><br />
	39. நான் செய்த எல்லாவற்றையும் எனக்குச் சொன்னார் என்று சாட்சி சொன்ன அந்த ஸ்திரீயினுடைய வார்த்தையினிமித்தம் அந்த ஊரிலுள்ள சமாரியரில் அநேகர் அவர்மேல் விசுவாசமுள்ளவர்களானார்கள்.
	<br /><br />
	40. சமாரியர் அவரிடத்தில் வந்து, தங்களிடத்தில் தங்கவேண்டுமென்று அவரை வேண்டிக்கொண்டார்கள்; அவர் இரண்டுநாள் அங்கே தங்கினார்.
	<br /><br />
	41. அப்பொழுது அவருடைய உபதேசத்தினிமித்தம் இன்னும் அநேகம்பேர் விசுவாசித்து,
	<br /><br />
	42. அந்த ஸ்திரீயை நோக்கி: உன் சொல்லினிமித்தம் அல்ல, அவருடைய உபதேசத்தை நாங்களே கேட்டு, அவர் மெய்யாய்க் கிறிஸ்துவாகிய உலகரட்சகர் என்று அறிந்து விசுவாசிக்கிறோம் என்றார்கள்.
	<br /><br />
	43. இரண்டுநாளைக்குப்பின்பு அவர் அவ்விடம்விட்டுப் புறப்பட்டு, கலிலேயாவுக்குப் போனார்.
	<br /><br />
	44. ஒரு தீர்க்கதரிசிக்குத் தன் சொந்த ஊரிலே கனமில்லையென்று இயேசு தாமே சொல்லியிருந்தார்.
	<br /><br />
	45. அவர் கலிலேயாவில் வந்தபோது, எருசலேமில் பண்டிகையிலே அவர் செய்த எல்லாவற்றையும் பார்த்திருந்த கலிலேயர் அவரை ஏற்றுக்கொண்டார்கள்; அவர்களும் பண்டிகைக்குப் போயிருந்தார்கள்.
	<br /><br />
	46. பின்பு, இயேசு தாம் தண்ணீரைத் திராட்சரசமாக்கின கலிலேயாவிலுள்ள கானா ஊருக்கு மறுபடியும் வந்தார்; அப்பொழுது கப்பர்நகூமிலே ராஜாவின் மனுஷரில் ஒருவனுடைய குமாரன் வியாதியாயிருந்தான்.
	<br /><br />
	47. இயேசு யூதேயாவிலிருந்து கலிலேயாவுக்கு வந்தாரென்று அந்த மனுஷன் கேள்விப்பட்டபோது, அவரிடத்திற்குப் போய், தன் மகன் மரண அவஸ்தையாயிருந்தபடியினாலே, அவனைக் குணமாக்கும்படிக்கு வரவேண்டுமென்று அவரை வேண்டிக்கொண்டான்.
	<br /><br />
	48. அப்பொழுது இயேசு அவனை நோக்கி: நீங்கள் அடையாளங்களையும் அற்புதங்களையும் காணாவிட்டால் விசுவாசிக்கமாட்டீர்கள் என்றார்.
	<br /><br />
	49. அதற்கு ராஜாவின் மனுஷன்: ஆண்டவரே, என் பிள்ளை சாகிறதற்குமுன்னே வரவேண்டும் என்றான்.
	<br /><br />
	50. இயேசு அவனை நோக்கி: நீ போகலாம், உன் குமாரன் பிழைத்திருக்கிறான் என்றார். அந்த மனுஷன், இயேசு சொன்ன வார்த்தையை நம்பிப் போனான்.
	<br /><br />
	51. அவன் போகையில், அவனுடைய ஊழியக்காரர் அவனுக்கு எதிர்கொண்டுவந்து, உம்முடைய குமாரன் பிழைத்திருக்கிறான் என்று அறிவித்தார்கள்.
	<br /><br />
	52. அப்பொழுது: எந்த மணிநேரத்தில் அவனுக்குக் குணமுண்டாயிற்று என்று அவர்களிடத்தில் விசாரித்தான். அவர்கள்: நேற்று ஏழாமணிநேரத்தில் ஜுரம் அவனை விட்டது என்றார்கள்.
	<br /><br />
	53. உன் குமாரன் பிழைத்திருக்கிறான் என்று இயேசு தன்னுடனே சொன்ன மணிநேரம் அதுவே என்று தகப்பன் அறிந்து, அவனும் அவன் வீட்டாரனைவரும் விசுவாசித்தார்கள்.
	<br /><br />
	54. இயேசு யூதேயாவிலிருந்து கலிலேயாவுக்குத் திரும்பிவந்தபின்பு, இது அவர் செய்த இரண்டாம் அற்புதம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John4