import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah34 = () => {
  const verseNumber = 34;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 34 </title>
	<meta
          name="description"
          content="Jeremiah 34 | எரேமியா 34 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரும் அவனுடைய சர்வசேனையும், அவன் ஆளுகைக்குட்பட்ட பூமியின் சகல ராஜ்யங்களும், சகல ஜனங்களும் எருசலேமுக்கும் அதைச் சேர்ந்த சகல பட்டணங்களுக்கும் விரோதமாக யுத்தம்பண்ணுகையில் எரேமியாவுக்குக் கர்த்தரால் உண்டான வார்த்தை:
	<br /><br />
	2. இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறது என்னவென்றால்: நீ போய், யூதாவின் ராஜாவாகிய சிதேக்கியாவினிடத்தில் பேசி, அவனுக்குச் சொல்லவேண்டியது: இதோ, நான் இந்த நகரத்தைப் பாபிலோன் ராஜாவின் கையில் ஒப்புக்கொடுக்கிறேன்; அவன் இதை அக்கினியால் சுட்டெரிப்பான்.
	<br /><br />
	3. நீ அவன் கைக்குத் தப்பிப்போகாமல், நிச்சயமாய்ப் பிடிபட்டு, அவன் கையில் ஒப்புக்கொடுக்கப்படுவாய்; உன் கண்கள் பாபிலோன் ராஜாவின் கண்களைக் காணும்; அவன் வாய் உன் வாயோடே பேசும்; நீ பாபிலோனுக்குப் போவாய் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. ஆகிலும் யூதாவின் ராஜாவாகிய சிதேக்கியாவே, கர்த்தருடைய வார்த்தையைக் கேள்; உன்னைக்குறித்துக் கர்த்தர் சொல்லுகிறது என்னவென்றால்: நீ பட்டயத்தாலே சாவதில்லை.
	<br /><br />
	5. சமாதானத்தோடே சாவாய்; உனக்கு முன்னிருந்த ராஜாக்களாகிய உன் பிதாக்களினிமித்தம் கந்தவர்க்கங்களைக் கொளுத்தினதுபோல உன்னிமித்தமும் கொளுத்தி, ஐயோ! ஆண்டவனே, என்று சொல்லி, உனக்காகப் புலம்புவார்கள்; இது நான் சொன்ன வார்த்தையென்று கர்த்தர் உரைத்தார் என்று சொல் என்றார்.
	<br /><br />
	6. இந்த வார்த்தைகளையெல்லாம் தீர்க்கதரிசியாகிய எரேமியா எருசலேமிலே யூதாவின் ராஜாவாகிய சிதேக்கியாவினிடத்தில் சொன்னான்.
	<br /><br />
	7. அப்பொழுது பாபிலோன் ராஜாவின் சேனைகள் எருசலேமுக்கு விரோதமாகவும், யூதாவின் எல்லாப் பட்டணங்களிலும் மீந்த பட்டணங்களாகிய லாகீசுக்கும், அசெக்காவுக்கும் விரோதமாகவும் யுத்தம்பண்ணிக்கொண்டிருந்தது; யூதாவின் அரணிப்பான பட்டணங்களில் இவைகளே மீந்திருந்தவைகள்.
	<br /><br />
	8. ஒருவனும் யூதஜாதியானாகிய தன் சகோதரனை அடிமைகொள்ளாதபடிக்கு, அவனவன் எபிரெயனாகிய தன் வேலைக்காரனையும், எபிரெய ஸ்திரீயாகிய தன் வேலைக்காரியையும் சுயாதீனராக அனுப்பிவிடவேண்டுமென்று அவர்களுக்கு விடுதலையைக் கூறும்படி,
	<br /><br />
	9. ராஜாவாகிய சிதேக்கியா எருசலேமில் இருக்கிற எல்லா ஜனத்தோடும் உடன்படிக்கை பண்ணினபின்பு, எரேமியாவுக்குக் கர்த்தரால் வார்த்தை உண்டாயிற்று.
	<br /><br />
	10. ஒவ்வொருவனும் தன் வேலைக்காரனையும் தன் வேலைக்காரியையும் இனி அடிமைகொள்ளாதபடிக்கு, சுயாதீனராக அனுப்பிவிடவேண்டுமென்று உடன்படிக்கைக்கு உட்பட்ட எல்லாப் பிரபுக்களும் எல்லா ஜனங்களும் கேட்டபோது, செவிகொடுத்து அவர்களை அனுப்பிவிட்டார்கள்.
	<br /><br />
	11. ஆனாலும் அதற்குப்பின்பு அவர்கள் மாறாட்டம்பண்ணி, தாங்கள் சுயாதீனராக அனுப்பிவிட்ட வேலைக்காரரையும் வேலைக்காரிகளையும் மறுபடியும் அழைப்பித்து, அவர்களை வேலைக்காரரும் வேலைக்காரிகளுமாயிருக்கும்படி அடிமைப்படுத்திக்கொண்டார்கள்.
	<br /><br />
	12. ஆதலால், கர்த்தராலே எரேமியாவுக்கு வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	13. இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறது என்னவென்றால், அவனவன் தனக்கு விற்கப்பட்ட எபிரெயனாகிய தன் சகோதரனை முடிவிலே நீங்கள் ஏழாம் வருஷத்திலே அனுப்பிவிடவேண்டும் என்றும், அவன் உனக்கு ஆறுவருஷம் அடிமையாயிருந்தபின்பு, அவனை உன்னிடத்தில் வைக்காமல் சுயாதீனனாக அனுப்பிவிடவேண்டும் என்றும்,
	<br /><br />
	14. நான் உங்கள் பிதாக்களை அடிமை வீடாகிய எகிப்துதேசத்திலிருந்து புறப்படப்பண்ணின நாளிலே அவர்களோடே உடன்படிக்கைபண்ணினேன்; ஆனாலும் உங்கள் பிதாக்கள் என் சொல்லைக் கேளாமலும், தங்கள் செவியைச் சாயாமலும் போனார்கள்.
	<br /><br />
	15. நீங்களோ, இந்நாளிலே மனந்திரும்பி, அவனவன் தன் அயலானுக்கு விடுதலையைக் கூறின விஷயத்திலே என் பார்வைக்குச் செம்மையானதைச் செய்து, என் நாமம் தரிக்கப்பட்ட ஆலயத்திலே இதற்காக என் முகத்துக்குமுன் உடன்படிக்கைபண்ணியிருந்தீர்கள்.
	<br /><br />
	16. ஆனாலும் நீங்கள் மாறாட்டம்பண்ணி, என் நாமத்தைப் பரிசுத்தக்குலைச்சலாக்கி, நீங்கள் அவனவன் விடுதலையாகவும் சுயாதீனராகவும் அனுப்பிவிட்ட தன் வேலைக்காரனையும் தன் வேலைக்காரியையும் திரும்ப அழைத்துவந்து, அவர்களை உங்களுக்கு வேலைக்காரரும் வேலைக்காரிகளுமாயிருக்கும்படி அடிமைப்படுத்தினீர்கள்.
	<br /><br />
	17. ஆகையால் கர்த்தர் சொல்லுகிறது என்னவென்றால், நீங்கள் அவனவன் தன் சகோதரனுக்கும் அவனவன் தன் அயலானுக்கும் விடுதலையைக் கூறினவிஷயத்தில் என் சொல்லைக் கேளாமற்போனீர்களே; இதோ, நான் உங்களைப் பட்டயத்துக்கும், கொள்ளைநோய்க்கும், பஞ்சத்துக்கும் ஒப்புக்கொடுக்கிற விடுதலையை உங்களுக்குக் கூறுகிறேன்; பூமியின் ராஜ்யங்களிலெல்லாம் அலைகிறதற்கும் உங்களை ஒப்புக்கொடுப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. என் முகத்துக்குமுன் பண்ணின உடன்படிக்கையின் வார்த்தைகளை நிறைவேற்றாமல், என் உடன்படிக்கையை மீறின மனுஷரை நான் துண்டங்களின் நடுவாகக் கடந்துபோகும்படி அவர்களை இரண்டாகத் துண்டித்த கன்றுக்குட்டியைப்போல் ஆக்குவேன்.
	<br /><br />
	19. கன்றுக்குட்டியின் துண்டுகளின் நடுவே கடந்துபோன யூதாவின் பிரபுக்களையும், எருசலேமின் பிரபுக்களையும், பிரதானிகளையும், ஆசாரியர்களையும், தேசத்தின் சகல ஜனங்களையும் அப்படிச் செய்து,
	<br /><br />
	20. நான் அவர்களை அவர்கள் சத்துருக்களின் கையிலும், அவர்கள் பிராணனை வாங்கத்தேடுகிறவர்களின் கையிலும் ஒப்புக்கொடுப்பேன்; அவர்களுடைய பிரேதம் ஆகாயத்தின் பறவைகளுக்கும் பூமியின் மிருகங்களுக்கும் இரையாகும்.
	<br /><br />
	21. யூதாவின் ராஜாவாகிய சிதேக்கியாவையும் அவனுடைய பிரபுக்களையும் அவர்கள் சத்துருக்களின் கையிலும்; அவர்கள் பிராணனை வாங்கத் தேடுகிறவர்களின் கையிலும், உங்களைவிட்டுப் பேர்ந்துபோன பாபிலோன் ராஜாவினுடைய சேனைகளின் கையிலும் ஒப்புக்கொடுப்பேன்.
	<br /><br />
	22. இதோ, நான் கட்டளைகொடுத்து, அவர்களை இந்த நகரத்துக்குத் திரும்பப்பண்ணுவேன்; அவர்கள் அதற்கு விரோதமாக யுத்தம்பண்ணி, அதைப் பிடித்து, அதை அக்கினியால் சுட்டெரிப்பார்கள்; யூதாவின் பட்டணங்களையும், ஒருவரும் அவைகளில் குடியிராதபடிப் பாழாய்ப்போகப்பண்ணுவேன் என்று கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah34