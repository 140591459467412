import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 6 </title>
	<meta
          name="description"
          content="Song of Solomon 6 | உன்னதப்பாட்டு 6 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உன் நேசர் எங்கே போனார்? ஸ்திரீகளில் ரூபவதியே! உன் நேசர் எவ்விடம் போய்விட்டார்? உன்னோடேகூட நாங்களும் அவரைத் தேடுவோம்.
	<br /><br />
	2. தோட்டங்களில் மேயவும், லீலிபுஷ்பங்களைக் கொய்யவும், என் நேசர் தமது தோட்டத்துக்கும் கந்தவர்க்கப் பாத்திகளுக்கும் போனார்.
	<br /><br />
	3. நான் என் நேசருடையவள், என் நேசர் என்னுடையவர்; அவர் லீலிபுஷ்பங்களுக்குள்ளே மேய்கிறார்.
	<br /><br />
	4. என் பிரியமே! நீ திர்சாவைப்போல் சௌந்தரியமும், எருசலேமைப்போல் வடிவமும், கொடிகள் பறக்கும் படையைப்போல் கெடியுமானவள்.
	<br /><br />
	5. உன் கண்களை என்னைவிட்டுத் திருப்பு, அவைகள் என்னை வென்றது; உன் அளகபாரம் கீலேயாத் மலையிலே தழைமேயும் வெள்ளாட்டு மந்தையைப்போலிருக்கிறது.
	<br /><br />
	6. உன் பற்கள் குளிப்பாட்டப்பட்டுக் கரையேறுகிறவைகளும், ஒன்றானாலும் மலடாயிராமல் இரட்டைக் குட்டியீன்றவைகளுமான ஆட்டுமந்தையைப்போலிருக்கிறது.
	<br /><br />
	7. உன் முக்காட்டின் நடுவே உன் கன்னங்கள் வெடித்த மாதளம்பழம்போலிருக்கிறது.
	<br /><br />
	8. ராஜஸ்திரீகள் அறுபதுபேரும், மறுமனையாட்டிகள் எண்பதுபேருமுண்டு; கன்னியருக்குத் தொகையில்லை.
	<br /><br />
	9. என் புறாவோ, என் உத்தமியோ ஒருத்தியே; அவள் தன் தாய்க்கு ஒரே பிள்ளை; அவள் தன்னைப் பெற்றவளுக்கு அருமையானவள்; குமாரத்திகள் அவளைக் கண்டு, அவளை வாழ்த்தினார்கள்; ராஜஸ்திரீகளும் மறுமனையாட்டிகளும் அவளைப் போற்றினார்கள்.
	<br /><br />
	10. சந்திரனைப்போல் அழகும், சூரியனைப்போல் பிரகாசமும், கொடிகள் பறக்கும் படையைப்போல் கெடியுமுள்ளவளாய், அருணோதயம்போல் உதிக்கிற இவள் யார்?
	<br /><br />
	11. பள்ளத்தாக்கிலே பழுத்த கனிகளைப் பார்க்கவும், திராட்சச்செடிகள் துளிர்விட்டு, மாதளஞ்செடிகள் பூத்ததா என்று அறியவும், வாதுமைத் தோட்டத்துக்குப் போனேன்.
	<br /><br />
	12. நினையாததுக்குமுன்னே என் ஆத்துமா என்னை அம்மினதாபின் இரதங்களுக்கு ஒப்பாக்கிற்று.
	<br /><br />
	13. திரும்பிவா, திரும்பிவா, சூலமித்தியே! நாங்கள் உன்னைப் பார்க்கும்படிக்கு, திரும்பிவா, திரும்பிவா. சூலமித்தியில் நீங்கள் என்னத்தைப் பார்க்கிறீர்கள்? அவள் இரண்டு சேனையின் கூட்டத்துக்குச் சமானமானவள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs6