import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 2 </title>
	<meta
          name="description"
          content="Daniel 2 | தானியேல் தீர்க்கதரிசி | தானியேல் 2 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நேபுகாத்நேச்சார் ராஜ்யபாரம்பண்ணும் இரண்டாம் வருஷத்திலே, நேபுகாத்நேச்சார் சொப்பனங்களைக் கண்டான்; அதினால், அவனுடைய ஆவி கலங்கி, அவனுடைய நித்திரை கலைந்தது.
	<br /><br />
	2. அப்பொழுது ராஜா தன் சொப்பனங்களைத் தனக்குத் தெரிவிக்கும்பொருட்டுச் சாஸ்திரிகளையும் ஜோசியரையும் சூனியக்காரரையும் கல்தேயரையும் அழைக்கச்சொன்னான்; அவர்கள் வந்து, ராஜசமுகத்தில் நின்றார்கள்.
	<br /><br />
	3. ராஜா அவர்களை நோக்கி: ஒரு சொப்பனம் கண்டேன்; அந்தச் சொப்பனத்தை அறியவேண்டுமென்று என் ஆவி கலங்கியிருக்கிறது என்றான்.
	<br /><br />
	4. அப்பொழுது கல்தேயர் ராஜாவை நோக்கி: ராஜாவே, நீர் என்றும் வாழ்க; சொப்பனத்தை உமது அடியாருக்குச் சொல்லும், அப்பொழுது அதின் அர்த்தத்தை விடுவிப்போம் என்று சீரியபாஷையிலே சொன்னார்கள்.
	<br /><br />
	5. ராஜா கல்தேயருக்குப் பிரதியுத்தரமாக: என்னிடத்திலிருந்து பிறக்கிற தீர்மானம் என்னவென்றால், நீங்கள் சொப்பனத்தையும் அதின் அர்த்தத்தையும் எனக்கு அறிவியாமற்போனால் துண்டித்துப்போடப்படுவீர்கள்; உங்கள் வீடுகள் எருக்களங்களாக்கப்படும்.
	<br /><br />
	6. சொப்பனத்தையும் அதின் அர்த்தத்தையும் தெரிவித்தீர்களேயாகில், என்னிடத்தில் வெகுமதிகளையும் பரிசுகளையும் மிகுந்த கனத்தையும் பெறுவீர்கள்; ஆகையால் சொப்பனத்தையும் அதின் அர்த்தத்தையும் எனக்குத் தெரிவியுங்கள் என்றான்.
	<br /><br />
	7. அவர்கள் மறுபடியும் பிரதியுத்தரமாக: ராஜா அடியாருக்குச் சொப்பனத்தைச் சொல்வாராக; அப்பொழுது அதின் அர்த்தத்தை விடுவிப்போம் என்றார்கள்.
	<br /><br />
	8. அதற்கு ராஜா பிரதியுத்தரமாக: என்னிடத்திலிருந்து தீர்மானம் பிறந்தபடியினாலே நீங்கள் காலதாமதம் பண்ணப்பார்க்கிறீர்களென்று நிச்சயமாக எனக்குத் தெரியவருகிறது.
	<br /><br />
	9. காலம் மாறுமென்று நீங்கள் எனக்கு முன்பாகப் பொய்யும் புரட்டுமான விசேஷத்தைச் சொல்லும்படி எத்தனம்பண்ணி இருக்கிறீர்கள்; நீங்கள் சொப்பனத்தை எனக்குத் தெரிவிக்காமற்போனால், உங்களெல்லாருக்கும் இந்த ஒரே தீர்ப்பு பிறந்திருக்கிறது; ஆகையால் சொப்பனத்தை எனக்குச் சொல்லுங்கள்; அப்பொழுது அதின் அர்த்தத்தையும் உங்களால் காண்பிக்கக்கூடுமென்று அறிந்துகொள்ளுவேன் என்றான்.
	<br /><br />
	10. கல்தேயர் ராஜசமுகத்தில் பிரதியுத்தரமாக: ராஜா கேட்கும் காரியத்தை அறிவிக்கத்தக்க மனுஷன் பூமியில் ஒருவனும் இல்லை; ஆகையால் மகத்துவமும் வல்லமையுமான எந்த ராஜாவும் இப்படிப்பட்ட காரியத்தை ஒரு சாஸ்திரியினிடத்திலாவது ஜோசியனிடத்திலாவது கல்தேயனிடத்திலாவது கேட்டதில்லை.
	<br /><br />
	11. ராஜா கேட்கிற காரியம் மிகவும் அருமையானது; மாம்சமாயிருக்கிறவர்களோடே வாசம்பண்ணாத தேவர்களேயொழிய ராஜசமுகத்தில் அதை அறிவிக்கத்தக்கவர் ஒருவரும் இல்லை என்றார்கள்.
	<br /><br />
	12. இதினிமித்தம் ராஜா மகா கோபமும் உக்கிரமுங்கொண்டு, பாபிலோனில் இருக்கிற எல்லா ஞானிகளையும் கொலைசெய்யும்படி கட்டளையிட்டான்.
	<br /><br />
	13. ஞானிகளைக் கொலைசெய்ய வேண்டுமென்கிற கட்டளை வெளிப்பட்டபோது, தானியேலையும் அவன் தோழரையும் கொலைசெய்யத் தேடினார்கள்.
	<br /><br />
	14. பாபிலோனின் ஞானிகளைக் கொலைசெய்யப் புறப்பட்ட ராஜாவினுடைய தலையாரிகளுக்கு அதிபதியாகிய ஆரியோகோடே தானியேல் யோசனையும் புத்தியுமாய்ப் பேசி:
	<br /><br />
	15. இந்தக் கட்டளை ராஜாவினால் இத்தனை அவசரமாய்ப் பிறந்ததற்குக் காரணம் என்ன என்று ராஜாவின் சேர்வைக்காரனாகிய ஆரியோகினிடத்தில் கேட்டான்; அப்பொழுது ஆரியோகு தானியேலுக்குக் காரியத்தை அறிவித்தான்.
	<br /><br />
	16. தானியேல் ராஜாவினிடத்தில் போய், சொப்பனத்தின் அர்த்தத்தை ராஜாவுக்குக் காண்பிக்கும்படித் தனக்குத் தவணைகொடுக்க விண்ணப்பம்பண்ணினான்.
	<br /><br />
	17. பின்பு தானியேல் தன் வீட்டுக்குப் போய், தானும் தன் தோழரும் பாபிலோனின் மற்ற ஞானிகளோடேகூட அழியாதபடிக்கு இந்த மறைபொருளைக்குறித்துப் பரலோகத்தின் தேவனை நோக்கி இரக்கம் கேட்கிறதற்காக,
	<br /><br />
	18. அனனியா, மீஷாவேல், அசரியா என்னும் தன்னுடைய தோழருக்கு இந்தக் காரியத்தை அறிவித்தான்.
	<br /><br />
	19. பின்பு இராக்காலத்தில் தரிசனத்திலே தானியேலுக்கு மறைபொருள் வெளிப்படுத்தப்பட்டது; அப்பொழுது தானியேல் பரலோகத்தின் தேவனை ஸ்தோத்திரித்தான்.
	<br /><br />
	20. பின்பு தானியேல் சொன்னது: தேவனுடைய நாமத்துக்கு என்றென்றைக்குமுள்ள சதாகாலங்களிலும் ஸ்தோத்திரமுண்டாவதாக; ஞானமும் வல்லமையும் அவருக்கே உரியது.
	<br /><br />
	21. அவர் காலங்களையும் சமயங்களையும் மாற்றுகிறவர்; ராஜாக்களைத் தள்ளி, ராஜாக்களை ஏற்படுத்துகிறவர்; ஞானிகளுக்கு ஞானத்தையும், அறிவாளிகளுக்கு அறிவையும் கொடுக்கிறவர்.
	<br /><br />
	22. அவரே ஆழமும் மறைபொருளுமானதை வெளிப்படுத்துகிறவர்; இருளில் இருக்கிறதை அவர் அறிவார்; வெளிச்சம் அவரிடத்தில் தங்கும்.
	<br /><br />
	23. என் பிதாக்களின் தேவனே, நீர் எனக்கு ஞானமும் வல்லமையும் கொடுத்து, நாங்கள் உம்மிடத்தில் வேண்டிக்கொண்டதை இப்பொழுது எனக்கு அறிவித்து, ராஜாவின் காரியத்தை எங்களுக்குத் தெரிவித்தபடியினால், உம்மைத் துதித்துப் புகழுகிறேன் என்றான்.
	<br /><br />
	24. பின்பு தானியேல் பாபிலோனின் ஞானிகளை அழிக்க ராஜா கட்டளையிட்ட ஆரியோகினிடத்தில் போய்: பாபிலோனின் ஞானிகளை அழிக்காதேயும், என்னை ராஜாவின் முன்பாக அழைத்துக்கொண்டுபோம்; ராஜாவுக்கு அர்த்தத்தைத் தெரிவிப்பேன் என்று சொன்னான்.
	<br /><br />
	25. அப்பொழுது ஆரியோகு தானியேலை ராஜாவின் முன்பாகத் தீவிரமாய் அழைத்துக்கொண்டுபோய்: சிறைப்பட்டுவந்த யூதேயா தேசத்தாரில் ஒரு புருஷனைக் கண்டுபிடித்தேன்; அவன் ராஜாவுக்கு அர்த்தத்தைத் தெரிவிப்பான் என்றான்.
	<br /><br />
	26. ராஜா பெல்தெஷாத்சாரென்னும் நாமமுள்ள தானியேலை நோக்கி: நான் கண்ட சொப்பனத்தையும் அதின் அர்த்தத்தையும் நீ எனக்கு அறிவிக்கக்கூடுமா என்று கேட்டான்.
	<br /><br />
	27. தானியேல் ராஜசமுகத்தில் பிரதியுத்தரமாக: ராஜா கேட்கிற மறைபொருளை ராஜாவுக்குத் தெரிவிக்க ஞானிகளாலும், ஜோசியராலும், சாஸ்திரிகளாலும், குறிசொல்லுகிறவர்களாலும் கூடாது.
	<br /><br />
	28. மறைபொருள்களை வெளிப்படுத்துகிற பரலோகத்திலிருக்கிற தேவன் கடைசிநாட்களில் சம்பவிப்பதை ராஜாவாகிய நேபுகாத்நேச்சாருக்குத் தெரிவித்திருக்கிறார்; உம்முடைய சொப்பனமும் உமது படுக்கையின்மேல் உம்முடைய தலையில் உண்டான தரிசனங்களும் என்னவென்றால்:
	<br /><br />
	29. ராஜாவே, உம்முடைய படுக்கையின்மேல் நீர் படுத்திருக்கையில், இனிமேல் சம்பவிக்கப்போகிறதென்ன என்கிற நினைவுகள் உமக்குள் எழும்பிற்று; அப்பொழுது மறைபொருள்களை வெளிப்படுத்துகிறவர் சம்பவிக்கப்போகிறதை உமக்குத் தெரிவித்தார்.
	<br /><br />
	30. உயிரோடிருக்கிற எல்லாரைப்பார்க்கிலும் எனக்கு அதிக ஞானம் உண்டென்பதினாலே அல்ல; அர்த்தம் ராஜாவுக்குத் தெரியவரவும், உம்முடைய இருதயத்தின் நினைவுகளை நீர் அறியவும், இந்த மறைபொருள் எனக்கு வெளியாக்கப்பட்டது.
	<br /><br />
	31. ராஜாவே, நீர் ஒரு பெரிய சிலையைக் கண்டீர்; அந்தப் பெரிய சிலை மிகுந்த பிரகாசமுள்ளதாயிருந்தது; அது உமக்கு எதிரே நின்றது; அதின் ரூபம் பயங்கரமாயிருந்தது.
	<br /><br />
	32. அந்தச் சிலையின் தலை பசும்பொன்னும், அதின் மார்பும் அதின் புயங்களும் வெள்ளியும், அதின் வயிறும் அதின் தொடையும் வெண்கலமும்,
	<br /><br />
	33. அதின் கால்கள் இரும்பும், அதின் பாதங்கள் பாதி இரும்பும் பாதி களிமண்ணுமாயிருந்தது.
	<br /><br />
	34. நீர் பார்த்துக்கொண்டிருக்கும்போதே, கைகளால் பெயர்க்கப்படாத ஒரு கல் பெயர்ந்து உருண்டுவந்தது; அது அந்தச் சிலையை இரும்பும் களிமண்ணுமாகிய அதின் பாதங்களில் மோதி, அவைகளை நொறுக்கிப்போட்டது.
	<br /><br />
	35. அப்பொழுது அந்த இரும்பும் களிமண்ணும் வெண்கலமும் வெள்ளியும் பொன்னும் ஏகமாய் நொறுங்குண்டு, கோடைகாலத்தில் போரடிக்கிற களத்திலிருந்து பறந்துபோகிற பதரைப்போலாயிற்று; அவைகளுக்கு ஒரு இடமும் கிடையாதபடி காற்று அவைகளை அடித்துக்கொண்டுபோயிற்று; சிலையை மோதின கல்லோவென்றால், ஒரு பெரிய பர்வதமாகி பூமியையெல்லாம் நிரப்பிற்று.
	<br /><br />
	36. சொப்பனம் இதுதான்; அதின் அர்த்தத்தையும் ராஜசமுகத்தில் தெரிவிப்போம்.
	<br /><br />
	37. ராஜாவே, நீர் ராஜாதி ராஜாவாயிருக்கிறீர்; பரலோகத்தின் தேவன் உமக்கு ராஜரீகத்தையும் பராக்கிரமத்தையும் வல்லமையையும் மகிமையையும் அருளினார்.
	<br /><br />
	38. சகல இடங்களிலுமுள்ள மனுபுத்திரரையும் வெளியின் மிருகங்களையும் ஆகாயத்துப் பறவைகளையும் அவர் உமது கையில் ஒப்புக்கொடுத்து, உம்மை அவைகளையெல்லாம் ஆளும்படி செய்தார். பொன்னான அந்தத் தலை நீரே.
	<br /><br />
	39. உமக்குப்பிறகு உமக்குக் கீழ்த்தரமான வேறொரு ராஜ்யம் தோன்றும்; பின்பு பூமியையெல்லாம் ஆண்டுகொள்ளும் வெண்கலமான மூன்றாம் ராஜ்யம் ஒன்று எழும்பும்.
	<br /><br />
	40. நாலாவது ராஜ்யம் இரும்பைப்போல உரமாயிருக்கும்; இரும்பு எல்லாவற்றையும் எப்படி நொறுக்கிச் சின்னபின்னமாக்குகிறதோ, அப்படியே இது நொறுக்கிப்போடுகிற இரும்பைபோல அவைகளையெல்லாம் நொறுக்கித் தகர்த்துப்போடும்.
	<br /><br />
	41. பாதங்களும் கால்விரல்களும் பாதி குயவனின் களிமண்ணும், பாதி இரும்புமாயிருக்க நீர் கண்டீரே, அந்த ராஜ்யம் பிரிக்கப்படும்; ஆகிலும் களிமண்ணோடே இரும்பு கலந்திருக்க நீர் கண்டபடியே இரும்பினுடைய உறுதியில் கொஞ்சம் அதிலே இருக்கும்.
	<br /><br />
	42. கால்விரல்கள் பாதி இரும்பும் பாதி களிமண்ணுமாயிருந்தது என்னவென்றால், அந்த ராஜ்யம் ஒருபங்கு உரமும் ஒருபங்கு நெரிசலுமாயிருக்கும்.
	<br /><br />
	43. நீர் இரும்பைக் களிமண்ணோடே கலந்ததாகக் கண்டீரே, அவர்கள் மற்ற மனுஷரோடே சம்பந்தங்கலப்பார்கள்; ஆகிலும் இதோ, களிமண்ணோடே இரும்பு கலவாததுபோல அவர்கள் ஒருவரோடொருவர் ஒட்டிக்கொள்ளாதிருப்பார்கள்.
	<br /><br />
	44. அந்த ராஜாக்களின் நாட்களிலே, பரலோகத்தின் தேவன் என்றென்றைக்கும் அழியாத ஒரு ராஜ்யத்தை எழும்பப்பண்ணுவார்; அந்த ராஜ்யம் வேறே ஜனத்துக்கு விடப்படுவதில்லை; ஒரு கல் கையால் பெயர்க்கப்படாமல் மலையிலிருந்து பெயர்ந்து, உருண்டுவந்து, இரும்பையும் வெண்கலத்தையும் களிமண்ணையும் வெள்ளியையும் பொன்னையும் நொறுக்கினதை நீர் கண்டீரே, அப்படியே அது அந்த ராஜ்யங்களையெல்லாம் நொறுக்கி, நிர்மூலமாக்கி, தானோ என்றென்றைக்கும் நிற்கும்.
	<br /><br />
	45. இனிமேல் சம்பவிக்கப்போகிறதை மகா தேவன் ராஜாவுக்குத் தெரிவித்திருக்கிறார்; சொப்பனமானது நிச்சயம், அதின் அர்த்தம் சத்தியம் என்றான்.
	<br /><br />
	46. அப்பொழுது ராஜாவாகிய நேபுகாத்நேச்சார் முகங்குப்புற விழுந்து, தானியேலை வணங்கி, அவனுக்குக் காணிக்கை செலுத்தவும் தூபங்காட்டவும் கட்டளையிட்டான்.
	<br /><br />
	47. ராஜா தானியேலை நோக்கி: நீ இந்த மறைபொருளை வெளிப்படுத்தினபடியினால், மெய்யாய் உங்கள் தேவனே தேவர்களுக்குத் தேவனும், ராஜாக்களுக்கு ஆண்டவரும், மறைபொருள்களை வெளிப்படுத்துகிறவருமாயிருக்கிறார் என்றான்.
	<br /><br />
	48. பின்பு ராஜா தானியேலைப் பெரியவனாக்கி, அவனுக்கு அநேகம் சிறந்த வெகுமதிகளைக் கொடுத்து, அவனைப் பாபிலோன் மாகாணம் முழுதுக்கும் அதிபதியாகவும், பாபிலோனிலுள்ள சகல ஞானிகளின் மேலும் பிரதான அதிகாரியாகவும் நியமித்தான்.
	<br /><br />
	49. தானியேல் ராஜாவை வேண்டிக்கொண்டதின்பேரில் அவன் சாத்ராக்கையும், மேஷாக்கையும், ஆபேத்நேகோவையும் பாபிலோன் மாகாணத்துக் காரியங்களை விசாரிக்கும்படி வைத்தான்; தானியேலோவென்றால் ராஜாவின் கொலுமண்டபத்தில் இருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel2