import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 2 </title>
	<meta
          name="description"
          content="Mark 2 | மாற்கு 2 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சிலநாட்களுக்குப்பின்பு அவர் மறுபடியும் கப்பர்நகூமுக்குப் போனார்; அவர் வீட்டிலிருக்கிறாரென்று ஜனங்கள் கேள்விப்பட்டு;
	<br /><br />
	2. உடனே வாசலுக்கு முன்னும் நிற்க இடம்போதாதபடிக்கு அநேகர் கூடிவந்தார்கள்; அவர்களுக்கு வசனத்தைப் போதித்தார்.
	<br /><br />
	3. அப்பொழுது நாலுபேர் ஒரு திமிர்வாதக்காரனைச் சுமந்துகொண்டு அவரிடத்தில் வந்தார்கள்;
	<br /><br />
	4. ஜனக்கூட்டத்தினிமித்தம் அவருக்குச் சமீபமாய்ச் சேரக்கூடாமல், அவர் இருந்த வீட்டின் மேற்கூரையைப் பிரித்துத் திறப்பாக்கி, திமிர்வாதக்காரன் கிடக்கிற படுக்கையை இறக்கினார்கள்.
	<br /><br />
	5. இயேசு அவர்கள் விசுவாசத்தைக் கண்டு, திமிர்வாதக்காரனை நோக்கி: மகனே, உன் பாவங்கள் உனக்கு மன்னிக்கப்பட்டது என்றார்.
	<br /><br />
	6. அங்கே உட்கார்ந்திருந்த வேதபாரகரில் சிலர்:
	<br /><br />
	7. இவன் இப்படித் தேவதூஷணம் சொல்லுகிறதென்ன? தேவன் ஒருவரேயன்றிப் பாவங்களை மன்னிக்கத்தக்கவர் யார் என்று தங்கள் இருதயங்களில் சிந்தித்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	8. அவர்கள் தங்களுக்குள்ளே இப்படிச் சிந்திக்கிறார்களென்று இயேசு உடனே தம்முடைய ஆவியில் அறிந்து, அவர்களை நோக்கி: நீங்கள் உங்கள் இருதயங்களில் இப்படிச் சிந்திக்கிறதென்ன?
	<br /><br />
	9. உன் பாவங்கள் உனக்கு மன்னிக்கப்பட்டதென்று சொல்வதோ, எழுந்து உன் படுக்கையை எடுத்துக்கொண்டு நடவென்று சொல்வதோ, எது எளிது?
	<br /><br />
	10. பூமியிலே பாவங்களை மன்னிக்க மனுஷகுமாரனுக்கு அதிகாரம் உண்டென்பதை நீங்கள் அறியவேண்டும் என்று சொல்லி, திமிர்வாதக்காரனை நோக்கி:
	<br /><br />
	11. நீ எழுந்து, உன் படுக்கையை எடுத்துக்கொண்டு, உன் வீட்டுக்குப் போ என்று உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	12. உடனே, அவன் எழுந்து, தன் படுக்கையை எடுத்துக்கொண்டு எல்லாருக்குமுன்பாகப் போனான். அப்பொழுது எல்லாரும் ஆச்சரியப்பட்டு: நாம் ஒருக்காலும் இப்படிக் கண்டதில்லையென்று சொல்லி, தேவனை மகிமைப்படுத்தினார்கள்.
	<br /><br />
	13. அவர் மறுபடியும் புறப்பட்டுக் கடலருகே போனார்; அப்பொழுது ஜனங்களெல்லாரும் அவரிடத்தில் வந்தார்கள்; அவர்களுக்குப் போதகம்பண்ணினார்.
	<br /><br />
	14. அவர் நடந்துபோகையில், அல்பேயுவின் குமாரனாகிய லேவி ஆயத்துறையில் உட்கார்ந்திருக்கிறதைக் கண்டு: எனக்குப் பின்சென்றுவா என்றார்; அவன் எழுந்து அவருக்குப் பின்சென்றான்.
	<br /><br />
	15. அப்பொழுது, அவனுடைய வீட்டிலே அவர் போஜனபந்தியிருக்கையில், அநேக ஆயக்காரரும் பாவிகளும் அவரோடுகூட வந்திருந்தபடியால், அவர்களும் இயேசுவோடும் அவர் சீஷரோடுங்கூடப் பந்தியிருந்தார்கள்.
	<br /><br />
	16. அவர் ஆயக்காரரோடும் பாவிகளோடும் போஜனம்பண்ணுகிறதை வேதபாரகரும் பரிசேயரும் கண்டு, அவருடைய சீஷரை நோக்கி: அவர் ஆயக்காரரோடும் பாவிகளோடும் போஜனபானம் பண்ணுகிறதென்னவென்று கேட்டார்கள்.
	<br /><br />
	17. இயேசு அதைக் கேட்டு: பிணியாளிகளுக்கு வைத்தியன் வேண்டியதேயல்லாமல் சுகமுள்ளவர்களுக்கு வேண்டியதில்லை; நீதிமான்களையல்ல, பாவிகளையே மனந்திரும்புகிறதற்கு அழைக்கவந்தேன் என்றார்.
	<br /><br />
	18. யோவானுடைய சீஷரும் பரிசேயருடைய சீஷரும் உபவாசம்பண்ணிவந்தார்கள். அவர்கள் அவரிடத்தில் வந்து: யோவானுடைய சீஷரும் பரிசேயருடைய சீஷரும் உபவாசிக்கிறார்களே, உம்முடைய சீஷர் உபவாசியாமலிருக்கிறதென்னவென்று கேட்டார்கள்.
	<br /><br />
	19. அதற்கு இயேசு: மணவாளன் தங்களோடிருக்கையில் மணவாளனுடைய தோழர் உபவாசிப்பார்களா? மணவாளன் தங்களுடனே இருக்கும்வரைக்கும் உபவாசிக்கமாட்டார்களே.
	<br /><br />
	20. மணவாளன் அவர்களை விட்டு எடுபடும் நாட்கள் வரும், அந்த நாட்களிலே உபவாசிப்பார்கள்.
	<br /><br />
	21. ஒருவனும் கோடித்துண்டைப் பழைய வஸ்திரத்தோடு இணைக்கமாட்டான், இணைத்தால், அதினோடே இணைத்த புதியதுண்டு பழையதை அதிகமாய்க் கிழிக்கும், பீறலும் அதிகமாகும்.
	<br /><br />
	22. ஒருவனும் புது திராட்சரசத்தைப் பழந்துருத்திகளில் வார்த்துவைக்கமாட்டான்; வார்த்துவைத்தால், புதுரசம் துருத்திகளைக் கிழித்துப்போடும், இரசமும் சிந்திப்போம், துருத்திகளும் கெட்டுப்போம்; புதுரசத்தைப் புது துருத்திகளில் வார்த்துவைக்கவேண்டும் என்றார்.
	<br /><br />
	23. பின்பு, அவர் ஓய்வுநாளில் பயிர் வழியே போனார்; அவருடைய சீஷர்கள் கூட நடந்துபோகையில், கதிர்களைக் கொய்யத் தொடங்கினார்கள்.
	<br /><br />
	24. பரிசேயர் அவரை நோக்கி: இதோ, ஓய்வுநாளில் செய்யத்தகாததை இவர்கள் ஏன் செய்கிறார்கள் என்றார்கள்.
	<br /><br />
	25. அதற்கு அவர்: தாவீதுக்கு உண்டான ஆபத்தில், தானும் தன்னோடிருந்தவர்களும் பசியாயிருந்தபோது,
	<br /><br />
	26. அவன் அபியத்தார் என்னும் பிரதான ஆசாரியன் காலத்தில் செய்ததை நீங்கள் ஒருக்காலும் வாசிக்கவில்லையா? அவன் தேவனுடைய வீட்டில் பிரவேசித்து, ஆசாரியர்தவிர வேறொருவரும் புசிக்கத்தகாத தெய்வசமுகத்து அப்பங்களைத் தானும் புசித்துத் தன்னோடிருந்தவர்களுக்கும் கொடுத்தானே என்றார்.
	<br /><br />
	27. பின்பு அவர்களை நோக்கி: மனுஷன் ஓய்வுநாளுக்காக உண்டாக்கப்படவில்லை, ஓய்வுநாள் மனுஷனுக்காக உண்டாக்கப்பட்டது;
	<br /><br />
	28. ஆகையால் மனுஷகுமாரன் ஓய்வு நாளுக்கும் ஆண்டவராய் இருக்கிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark2