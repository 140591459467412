import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 1 </title>
	<meta
          name="description"
          content="John Chapter 1 | யோவான் அதிகாரம் 1 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆதியிலே வார்த்தை இருந்தது, அந்த வார்த்தை தேவனிடத்திலிருந்தது, அந்த வார்த்தை தேவனாயிருந்தது.
	<br /><br />
	2. அவர் ஆதியிலே தேவனோடிருந்தார்.
	<br /><br />
	3. சகலமும் அவர் மூலமாய் உண்டாயிற்று; உண்டானதொன்றும் அவராலேயல்லாமல் உண்டாகவில்லை.
	<br /><br />
	4. அவருக்குள் ஜீவன் இருந்தது, அந்த ஜீவன் மனுஷருக்கு ஒளியாயிருந்தது.
	<br /><br />
	5. அந்த ஒளி இருளிலே பிரகாசிக்கிறது; இருளானது அதைப் பற்றிக்கொள்ளவில்லை.
	<br /><br />
	6. தேவனால் அனுப்பப்பட்ட ஒரு மனுஷன் இருந்தான், அவன் பேர் யோவான்.
	<br /><br />
	7. அவன் தன்னால் எல்லாரும் விசுவாசிக்கும்படி அந்த ஒளியைக்குறித்துச் சாட்சிகொடுக்க சாட்சியாக வந்தான்.
	<br /><br />
	8. அவன் அந்த ஒளியல்ல, அந்த ஒளியைக்குறித்துச் சாட்சிகொடுக்க வந்தவனாயிருந்தான்.
	<br /><br />
	9. உலகத்திலே வந்து எந்த மனுஷனையும் பிரகாசிப்பிக்கிற ஒளியே அந்த மெய்யான ஒளி.
	<br /><br />
	10. அவர் உலகத்தில் இருந்தார், உலகம் அவர் மூலமாய் உண்டாயிற்று, உலகமோ அவரை அறியவில்லை.
	<br /><br />
	11. அவர் தமக்குச் சொந்தமானதிலே வந்தார், அவருக்குச் சொந்தமானவர்களோ அவரை ஏற்றுக்கொள்ளவில்லை.
	<br /><br />
	12. அவருடைய நாமத்தின்மேல் விசுவாசமுள்ளவர்களாய் அவரை ஏற்றுக்கொண்டவர்கள் எத்தனைபேர்களோ, அத்தனைபேர்களும் தேவனுடைய பிள்ளைகளாகும்படி, அவர்களுக்கு அதிகாரங் கொடுத்தார்.
	<br /><br />
	13. அவர்கள், இரத்தத்தினாலாவது மாம்ச சித்தத்தினாலாவது புருஷனுடைய சித்தத்தினாலாவது பிறவாமல், தேவனாலே பிறந்தவர்கள்.
	<br /><br />
	14. அந்த வார்த்தை மாம்சமாகி, கிருபையினாலும் சத்தியத்தினாலும் நிறைந்தவராய், நமக்குள்ளே வாசம்பண்ணினார்; அவருடைய மகிமையைக் கண்டோம்; அது பிதாவுக்கு ஒரேபேறானவருடைய மகிமைக்கு ஏற்ற மகிமையாகவே இருந்தது.
	<br /><br />
	15. யோவான் அவரைக்குறித்துச் சாட்சிகொடுத்து: எனக்குப் பின்வருகிறவர் எனக்கு முன்னிருந்தவர், ஆகையால் அவர் என்னிலும் மேன்மையுள்ளவர் என்று நான் சொல்லியிருந்தேனே, அவர் இவர்தான் என்று சத்தமிட்டுக் கூறினான்.
	<br /><br />
	16. அவருடைய பரிபூரணத்தினால் நாம் எல்லாரும் கிருபையின்மேல் கிருபைபெற்றோம்.
	<br /><br />
	17. எப்படியெனில், நியாயப்பிரமாணம் மோசேயின் மூலமாய்க் கொடுக்கப்பட்டது, கிருபையும் சத்தியமும் இயேசு கிறிஸ்துவின் மூலமாய் உண்டாயின.
	<br /><br />
	18. தேவனை ஒருவனும் ஒருக்காலுங் கண்டதில்லை, பிதாவின் மடியிலிருக்கிற ஒரேபேறான குமாரனே அவரை வெளிப்படுத்தினார்.
	<br /><br />
	19. எருசலேமிலிருந்து யூதர்கள் ஆசாரியரையும் லேவியரையும் யோவானிடத்தில் அனுப்பி: நீர் யார் என்று கேட்டபொழுது,
	<br /><br />
	20. அவன் மறுதலியாமல் அறிக்கையிட்டதுமன்றி, நான் கிறிஸ்து அல்ல என்றும் அறிக்கையிட்டான்.
	<br /><br />
	21. அப்பொழுது அவர்கள்: பின்னை யார்? நீர் எலியாவா என்று கேட்டார்கள். அதற்கு: நான் அவன் அல்ல என்றான். நீர் தீர்க்கதரிசியானவரா என்று கேட்டார்கள். அதற்கும்: அல்ல என்றான்.
	<br /><br />
	22. அவர்கள் பின்னும் அவனை நோக்கி: நீர் யார்? எங்களை அனுப்பினவர்களுக்கு நாங்கள் உத்தரவு சொல்லும்படிக்கு, உம்மைக்குறித்து என்ன சொல்லுகிறீர் என்று கேட்டார்கள்.
	<br /><br />
	23. அதற்கு அவன்: கர்த்தருக்கு வழியைச் செவ்வைபண்ணுங்கள் என்று ஏசாயா தீர்க்கதரிசி சொன்னபடியே, நான் வனாந்தரத்திலே கூப்பிடுகிறவனுடைய சத்தமாயிருக்கிறேன் என்றான்.
	<br /><br />
	24. அனுப்பப்பட்டவர்கள் பரிசேயராயிருந்தார்கள்.
	<br /><br />
	25. அவர்கள் அவனை நோக்கி: நீர் கிறிஸ்துவுமல்ல, எலியாவுமல்ல, தீர்க்கதரிசியானவருமல்லவென்றால், ஏன் ஞானஸ்நானங் கொடுக்கிறீர் என்று கேட்டார்கள்.
	<br /><br />
	26. யோவான் அவர்களுக்குப் பிரதியுத்தரமாக: நான் ஜலத்தினாலே ஞானஸ்நானங்கொடுக்கிறேன்; நீங்கள் அறியாதிருக்கிற ஒருவர் உங்கள் நடுவிலே நிற்கிறார்.
	<br /><br />
	27. அவர் எனக்குப் பின்வந்தும் என்னிலும் மேன்மையுள்ளவர்; அவருடைய பாதரட்சையின் வாரை அவிழ்ப்பதற்கும் நான் பாத்திரனல்ல என்றான்.
	<br /><br />
	28. இவைகள் யோர்தானுக்கு அக்கரையில் யோவான் ஞானஸ்நானங்கொடுத்த பெத்தாபராவிலே நடந்தன.
	<br /><br />
	29. மறுநாளிலே யோவான் இயேசுவைத் தன்னிடத்தில் வரக்கண்டு: இதோ, உலகத்தின் பாவத்தைச் சுமந்துதீர்க்கிற தேவ ஆட்டுக்குட்டி.
	<br /><br />
	30. எனக்குப்பின் ஒருவர் வருகிறார், அவர் எனக்கு முன்னிருந்தபடியால் என்னிலும் மேன்மையுள்ளவரென்று நான் சொன்னேனே, அவர் இவர்தான்.
	<br /><br />
	31. நானும் இவரை அறியாதிருந்தேன்; இவர் இஸ்ரவேலுக்கு வெளிப்படும் பொருட்டாக, நான் ஜலத்தினாலே ஞானஸ்நானங் கொடுக்கவந்தேன் என்றான்.
	<br /><br />
	32. பின்னும் யோவான் சாட்சியாகச் சொன்னது: ஆவியானவர் புறாவைப்போல வானத்திலிருந்திறங்கி, இவர்மேல் தங்கினதைக் கண்டேன்.
	<br /><br />
	33. நானும் இவரை அறியாதிருந்தேன்; ஆனாலும் ஜலத்தினால் ஞானஸ்நானங்கொடுக்கும்படி என்னை அனுப்பினவர்: ஆவியானவர் இறங்கி யார்மேல் தங்குவதை நீ காண்பாயோ, அவரே பரிசுத்த ஆவியினால் ஞானஸ்நானங்கொடுக்கிறவர் என்று எனக்குச் சொல்லியிருந்தார்.
	<br /><br />
	34. அந்தப்படியே நான் கண்டு, இவரே தேவனுடைய குமாரன் என்று சாட்சி கொடுத்துவருகிறேன் என்றான்.
	<br /><br />
	35. மறுநாளிலே யோவானும் அவனுடைய சீஷரில் இரண்டுபேரும் நிற்கும்போது,
	<br /><br />
	36. இயேசு நடந்துபோகிறதை அவன் கண்டு: இதோ, தேவ ஆட்டுக்குட்டி என்றான்.
	<br /><br />
	37. அவன் அப்படிச் சொன்னதை அவ்விரண்டு சீஷருங்கேட்டு, இயேசுவுக்குப் பின்சென்றார்கள்.
	<br /><br />
	38. இயேசு திரும்பி, அவர்கள் பின் செல்லுகிறதைக் கண்டு: என்ன தேடுகிறீர்கள் என்றார். அதற்கு அவர்கள்: ரபீ, நீர் எங்கே தங்கியிருக்கிறீர் என்று கேட்டார்கள்; ரபீ என்பதற்குப் போதகரே என்று அர்த்தமாம்.
	<br /><br />
	39. அவர்: வந்து பாருங்கள் என்றார். அவர்கள் வந்து அவர் தங்கியிருந்த இடத்தைக் கண்டு, அன்றையத்தினம் அவரிடத்தில் தங்கினார்கள். அப்பொழுது ஏறக்குறையப் பத்துமணி வேளையாயிருந்தது.
	<br /><br />
	40. யோவான் சொன்னதைக் கேட்டு, அவருக்குப் பின்சென்ற இரண்டுபேரில் ஒருவன் சீமோன் பேதுருவின் சகோதரனாகிய அந்திரேயா என்பவன்.
	<br /><br />
	41. அவன் முதலாவது தன் சகோதரனாகிய சீமோனைக் கண்டு: மேசியாவைக் கண்டோம் என்று சொன்னான்; மேசியா என்பதற்குக் கிறிஸ்து என்று அர்த்தமாம்.
	<br /><br />
	42. பின்பு, அவனை இயேசுவினிடத்தில் கூட்டிக்கொண்டுவந்தான். இயேசு அவனைப் பார்த்து: நீ யோனாவின் மகனாகிய சீமோன், நீ கேபா என்னப்படுவாய் என்றார்; கேபா என்பதற்குப் பேதுரு என்று அர்த்தமாம்.
	<br /><br />
	43. மறுநாளிலே இயேசு கலிலேயாவுக்குப்போக மனதாயிருந்து, பிலிப்புவைக் கண்டு: நீ எனக்குப் பின்சென்று வா என்றார்.
	<br /><br />
	44. பிலிப்பென்பவன் அந்திரேயா பேதுரு என்பவர்களுடைய ஊராகிய பெத்சாயிதா பட்டணத்தான்.
	<br /><br />
	45. பிலிப்பு நாத்தான்வேலைக் கண்டு: நியாயப்பிரமாணத்திலே மோசேயும் தீர்க்கதரிசிகளும் எழுதியிருக்கிறவரைக் கண்டோம்; அவர் யோசேப்பின் குமாரனும் நாசரேத்தூரானுமாகிய இயேசுவே என்றான்.
	<br /><br />
	46. அதற்கு நாத்தான்வேல்: நாசரேத்திலிருந்து யாதொரு நன்மை உண்டாகக்கூடுமா என்றான். அதற்குப் பிலிப்பு: வந்து பார் என்றான்.
	<br /><br />
	47. இயேசு நாத்தான்வேலைத் தம்மிடத்தில் வரக்கண்டு அவனைக்குறித்து: இதோ, கபடற்ற உத்தம இஸ்ரவேலன் என்றார்.
	<br /><br />
	48. அதற்கு நாத்தான்வேல்: நீர் என்னை எப்படி அறிவீர் என்றான். இயேசு அவனை நோக்கி: பிலிப்பு உன்னை அழைக்கிறதற்குமுன்னே, நீ அத்திமரத்தின் கீழிருக்கும்போது உன்னைக் கண்டேன் என்றார்.
	<br /><br />
	49. அதற்கு நாத்தான்வேல்: ரபீ, நீர் தேவனுடைய குமாரன், நீர் இஸ்ரவேலின் ராஜா என்றான்.
	<br /><br />
	50. இயேசு அவனுக்குப் பிரதியுத்தரமாக: அத்திமரத்தின் கீழே உன்னைக் கண்டேன் என்று நான் உனக்குச் சொன்னதினாலேயா விசுவாசிக்கிறாய்; இதிலும் பெரிதானவைகளைக் காண்பாய் என்றார்.
	<br /><br />
	51. பின்னும், அவர் அவனை நோக்கி: வானம் திறந்திருக்கிறதையும், தேவதூதர்கள் மனுஷகுமாரனிடத்திலிருந்து ஏறுகிறதையும் இறங்குகிறதையும் நீங்கள் இதுமுதல் காண்பீர்கள் என்று மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John1