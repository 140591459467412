import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah52 = () => {
  const verseNumber = 52;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 52 </title>
	<meta
          name="description"
          content="Jeremiah 52 | எரேமியா 52 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சிதேக்கியா ராஜாவாகிறபோது இருபத்தொரு வயதாயிருந்தான்; அவன் பதினொரு வருஷம் எருசலேமில் ராஜ்யபாரம்பண்ணினான்; அவனுடைய தாயின்பேர் அமூத்தாள், அவள் லீப்னா ஊரானாகிய எரேமியாவின் குமாரத்தி.
	<br /><br />
	2. யோயாக்கீம் செய்தபடியெல்லாம் அவனும் கர்த்தருடைய பார்வைக்குப் பொல்லாப்பானதைச் செய்தான்.
	<br /><br />
	3. எருசலேமையும் யூதாவையும் கர்த்தர் தம்முடைய சமுகத்தைவிட்டு அகற்றித் தீருமளவும், அவைகளின்மேலுள்ள அவருடைய கோபத்தினால் இப்படி நடந்ததும் அல்லாமல், சிதேக்கியா பாபிலோனிலே ராஜாவுக்கு விரோதமாகக் கலகம்பண்ணினான்.
	<br /><br />
	4. அவன் ராஜ்யபாரம்பண்ணும் ஒன்பதாம் வருஷம் பத்தாம் மாதம் பத்தாந்தேதியிலே பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரும், அவனுடைய எல்லா இராணுவமும் எருசலேமுக்கு விரோதமாய் வந்து, அதற்கு எதிராகப் பாளயமிறங்கி, சுற்றிலும் அதற்கு எதிராகக் கொத்தளங்களைக் கட்டினார்கள்.
	<br /><br />
	5. அப்படியே சிதேக்கியா ராஜாவின் பதினோராம் வருஷமட்டும் நகரம் முற்றிக்கை போடப்பட்டிருந்தது.
	<br /><br />
	6. நாலாம் மாதம் ஒன்பதாம் தேதியிலே பஞ்சம் நகரத்திலே அதிகரித்து, தேசத்தின் ஜனத்துக்கு ஆகாரமில்லாமல்போயிற்று.
	<br /><br />
	7. நகரத்தின் மதில் இடிக்கப்பட்டது; அப்பொழுது கல்தேயர் நகரத்தைச் சூழ்ந்திருக்கையில், யுத்தமனுஷர் எல்லாரும் இராத்திரிகாலத்தில் ஓடி, ராஜாவுடைய தோட்டத்தின் வழியே இரண்டு மதில்களுக்கும் நடுவான வாசலால் நகரத்திலிருந்து புறப்பட்டு, வயல்வெளியின் வழியே போய்விட்டார்கள்.
	<br /><br />
	8. ஆனாலும் கல்தேயருடைய இராணுவத்தார் ராஜாவைப் பின்தொடர்ந்து, எரிகோவின் சமனான பூமியில் சிதேக்கியாவைக் கிட்டினார்கள்; அப்பொழுது அவனுடைய இராணுவத்தார் எல்லாரும் அவனைவிட்டுச் சிதறிப்போயிருந்தார்கள்.
	<br /><br />
	9. அவர்கள் ராஜாவைப் பிடித்து, அவனை ஆமாத்தேசத்தின் ஊராகிய ரிப்லாவுக்குப் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரிடத்துக்குக் கொண்டுபோனார்கள்; அங்கே இவனுக்கு நியாயத்தீர்ப்புக் கொடுத்தான்.
	<br /><br />
	10. பின்பு பாபிலோன் ராஜா சிதேக்கியாவின் குமாரரை அவன் கண்களுக்கு முன்பாக வெட்டினான்; யூதாவின் பிரபுக்களெல்லாரையும் ரிப்லாவிலே வெட்டினான்.
	<br /><br />
	11. சிதேக்கியாவின் கண்களைக் குருடாக்கி, அவனுக்கு இரண்டு விலங்குகளைப் போடுவித்தான்; பின்பு பாபிலோன் ராஜா அவனைப் பாபிலோனுக்குக் கொண்டுபோய், அவன் மரணமடையும் நாள்மட்டும் அவனைக் காவல் வீட்டில் அடைத்துவைத்தான்.
	<br /><br />
	12. ஐந்தாம் மாதம் பத்தாந்தேதியிலே, பாபிலோன் ராஜாவுக்கு முன்பாக நிற்கிறவனாகிய காவற்சேனாதிபதியான நேபுசராதான் எருசலேமுக்கு வந்தான்; அது நேபுகாத்நேச்சார் என்னும் ராஜா பாபிலோனை அரசாளுகிற பத்தொன்பதாம் வருஷமாயிருந்தது.
	<br /><br />
	13. அவன் கர்த்தருடைய ஆலயத்தையும், ராஜாவின் அரமனையையும், எருசலேமிலுள்ள எல்லா வீடுகளையும், ஒவ்வொரு பெரிய மனிதனுடைய வீட்டையும் அக்கினியினால் சுட்டெரித்துப்போட்டான்.
	<br /><br />
	14. காவற்சேனாதிபதியோடிருந்த கல்தேயரின் இராணுவத்தாரெல்லாரும் எருசலேமைச் சுற்றிலும் இருந்த அலங்கங்களை இடித்துப்போட்டார்கள்.
	<br /><br />
	15. ஜனத்தில் ஏழைகளான சிலரையும் நகரத்தில் மீதியான மற்ற ஜனத்தையும், பாபிலோன் ராஜாவின் வசமாக ஓடிவந்துவிட்டவர்களையும், மற்ற ஜனங்களையும் காவற்சேனாதிபதியாகிய நேபுசராதான் சிறைகளாகக் கொண்டுபோனான்.
	<br /><br />
	16. ஆனால் தேசத்தாரில் ஏழைகளான சிலரைக் காவற்சேனாதிபதியாகிய நேபுசராதான் திராட்சத்தோட்டக்காரராகவும் பயிரிடுங்குடிகளாகவும் விட்டுவைத்தான்.
	<br /><br />
	17. கர்த்தருடைய ஆலயத்திலிருந்த வெண்கலத் தூண்களையும், கர்த்தருடைய ஆலயத்திலிருந்த ஆதாரங்களையும், வெண்கலக் கடல்தொட்டியையும் கல்தேயர் உடைத்துப்போட்டு, அவைகளின் வெண்கலத்தையெல்லாம் பாபிலோனுக்கு எடுத்துக்கொண்டு போனார்கள்.
	<br /><br />
	18. செப்புச்சட்டிகளையும், சாம்பல் எடுக்கும் கரண்டிகளையும், வெட்டுக்கத்திகளையும், கலங்களையும், கலயங்களையும், ஆராதனைக்குரிய சகல வெண்கலப்பணிமுட்டுகளையும் எடுத்துக்கொண்டுபோனார்கள்.
	<br /><br />
	19. பசும்பொன்னும் சுத்தவெள்ளியுமான கிண்ணங்களையும், தூபகலசங்களையும், கலங்களையும், சட்டிகளையும், விளக்குத்தண்டுகளையும், கலயங்களையும், கரகங்களையும் காவற்சேனாதிபதி எடுத்துக்கொண்டான்.
	<br /><br />
	20. சாலொமோன் ராஜா கர்த்தருடைய ஆலயத்துக்காகச் செய்து வைத்த இரண்டு தூண்களும் ஒரு கடல்தொட்டியும் ஆதாரங்களின் கீழ்நின்ற பன்னிரண்டு வெண்கல ரிஷபங்களும் ஆகிய இவைகளுக்குரிய வெண்கலத்துக்கு நிறையில்லை.
	<br /><br />
	21. அந்தத் தூண்களோவெனில், ஒவ்வொரு தூணும் பதினெட்டுமுழ உயரமாயிருந்தது; பன்னிரண்டு முழநூல் அதைச் சுற்றும்; நாலு விரற்கடை அதின் கனம்; உள்ளே குழாயாயிருந்தது.
	<br /><br />
	22. அதின்மேல் வெண்கலக் குமிழ் இருந்தது; ஒரு குமிழின் உயரம் ஐந்து முழம், குமிழிலே சுற்றிலும் பின்னலும் மாதளம்பழங்களும் செய்திருந்தது; எல்லாம் வெண்கலமாயிருந்தது; அதற்குச் சரியாய் மற்றத் தூணுக்கும் மாதளம்பழங்களும் செய்திருந்தது.
	<br /><br />
	23. தொண்ணூற்றாறு மாதளம்பழங்கள் நான்கு திசைகளுக்கும் எதிராகச் செய்திருந்தது; குமிழைச் சுற்றிலும் செய்திருந்த மாதளம்பழங்கள் நூறு.
	<br /><br />
	24. காவற்சேனாதிபதி பிரதான ஆசாரியனாகிய செராயாவையும், இரண்டாம் ஆசாரியனாகிய செப்பனியாவையும், வாசற்படியின் மூன்று காவற்காரரையும் பிடித்துக்கொண்டுபோனான்.
	<br /><br />
	25. நகரத்திலோவென்றால் அவன் யுத்த மனுஷரின் விசாரிப்புக்காரனாகிய பிரதானி ஒருவனையும், ராஜாவின் மந்திரிகளில் நகரத்தில் அகப்பட்ட ஏழுபேரையும், தேசத்தின் ஜனத்தைச் சேவகம் எழுதுகிற தலைமையான சம்பிரதியையும், தேசத்து ஜனத்திலே பட்டணத்தின் நடுவில் அகப்பட்ட அறுபதுபேரையும் பிடித்துக்கொண்டுபோனான்.
	<br /><br />
	26. அவர்களைக் காவற்சேனாதிபதியாகிய நேபுசராதான் பிடித்து, அவர்களை ரிப்லாவுக்குப் பாபிலோன் ராஜாவினிடத்திற்குக் கொண்டுபோய்விட்டான்.
	<br /><br />
	27. அப்பொழுது பாபிலோன் ராஜா ஆமாத் என்னும் தேசத்தின் பட்டணமாகிய ரிப்லாவிலே அவர்களை வெட்டிக் கொன்றுபோட்டான்; இவ்விதமாக யூதர்கள் தங்கள் தேசத்திலிருந்து சிறைகளாய்க் கொண்டுபோகப்பட்டார்கள்.
	<br /><br />
	28. நேபுகாத்நேச்சார் சிறைபிடித்துப்போன ஜனங்களின் தொகை எவ்வளவென்றால், ஏழாம் வருஷத்தில் மூவாயிரத்து இருபத்துமூன்று யூதரும்,
	<br /><br />
	29. நேபுகாத்நேச்சாருடைய பதினெட்டாம் வருஷத்தில் எருசலேமிலிருந்து எண்ணூற்று முப்பத்திரண்டுபேர்களும் கொண்டுபோகப்பட்டார்கள்.
	<br /><br />
	30. நேபுகாத்நேச்சாருடைய இருபத்துமூன்றாம் வருஷத்தில் காவற்சேனாதிபதியாகிய நேபுசராதான் யூதரில் எழுநூற்று நாற்பத்தைந்துபேர்களைச் சிறைபிடித்துக்கொண்டுபோனான்; ஆக நாலாயிரத்து அறுநூறுபேர்களாம்.
	<br /><br />
	31. யூதாவின் ராஜாவாகிய யோயாக்கீனுடைய சிறையிருப்பின் முப்பத்தேழாம் வருஷம் பன்னிரண்டாம் மாதம் இருபத்தைந்தாம் தேதியிலே, ஏவில் மெரொதாக் என்னும் பாபிலோன் ராஜா, தான் ராஜாவான வருஷத்திலே, யூதாவின் ராஜாவாகிய யோயாக்கீனைச் சிறைச்சாலையிலிருந்து வெளிப்படப்பண்ணி, அவன் தலையை உயர்த்தி,
	<br /><br />
	32. அவனோடே அன்பாய்ப் பேசி, அவனுடைய ஆசனத்தைத் தன்னோடே பாபிலோனில் இருந்த ராஜாக்களுடைய ஆசனங்களுக்கு மேலாக வைத்து,
	<br /><br />
	33. அவனுடைய சிறையிருப்பு வஸ்திரங்களை மாற்றினான்; அவன் உயிரோடிருந்த சகல நாளும் தன் சமுகத்தில் நித்தம் போஜனம்பண்ணும்படி செய்தான்.
	<br /><br />
	34. அவன் உயிரோடிருந்த நாளெல்லாம் அவனுடைய மரணநாள் பரியந்தமும், அவனுடைய செலவுக்காகப் பாபிலோன் ராஜாவினால் கட்டளையான அநுதினத் திட்டத்தின்படி, அநுதினமும் அவனுக்குக் கொடுக்கப்பட்டுவந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah52