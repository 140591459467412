import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah46 = () => {
  const verseNumber = 46;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 46 </title>
	<meta
          name="description"
          content="Jeremiah 46 | எரேமியா 46 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. புறஜாதிகளுக்கு விரோதமாய் எரேமியா தீர்க்கதரிசிக்கு உண்டான கர்த்தருடைய வசனம்:
	<br /><br />
	2. எகிப்தைக்குறித்தும், ஐப்பிராத்து நதியண்டையில் கர்கேமிசிலே இருந்ததும் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார், யோசியாவின் குமாரனாகிய யோயாக்கீம் என்னும் யூதா ராஜாவின் நாலாம் வருஷத்திலே முறிய அடித்ததுமான பார்வோன்நேகோ என்னப்பட்ட எகிப்து ராஜாவின் ராணுவத்தைக்குறித்தும் அவர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	3. கேடகங்களையும் பரிசைகளையும் ஆயத்தம்பண்ணி, யுத்தத்துக்கு வாருங்கள்.
	<br /><br />
	4. குதிரைவீரரே, குதிரைகளின்மேல் சேணங்களை வைத்து ஏறி, தலைச்சீராயை அணிந்துகொண்டு நில்லுங்கள்; ஈட்டிகளைத் துலக்கி, கவசங்களைத் தரித்துக்கொள்ளுங்கள்.
	<br /><br />
	5. அவர்கள் கலங்கி, பின்வாங்குகிறதை நான் காண்கிறதென்ன? சுற்றிலுமுண்டான பயங்கரத்தினிமித்தம் அவர்களுடைய பராக்கிரமசாலிகள் முறியுண்டு, திரும்பிப்பாராமல் ஓட்டமாய் ஓடிப்போகிறார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. வேகமாய் ஓடுகிறவன் ஓடிப்போகவேண்டாம்; பராக்கிரமசாலி தப்பிப்போகவேண்டாம்; வடக்கே ஐப்பிராத்து நதியண்டையிலே அவர்கள் இடறிவிழுவார்கள்.
	<br /><br />
	7. பிரவாகம்போல் புரண்டுவருகிற இவன் யார்? அலைகள் மோதியடிக்கிற நதிகள்போல் எழும்பிவருகிற இவன் யார்?
	<br /><br />
	8. எகிப்தியனே பிரவாகத்தைப்போல் புரண்டுவருகிறான், அவனே அலைகள் மோதியடிக்கிற நதிகள்போல எழும்பிவருகிறான்; நான் போய், தேசத்தை மூடி, நகரத்தையும் அதில் குடியிருக்கிறவர்களையும் அழிப்பேன் என்றான்.
	<br /><br />
	9. குதிரைகளே, போய் ஏறுங்கள்; இரதங்களே, கடகட என்று ஓடுங்கள்; பராக்கிரமசாலிகளும், கேடகம் பிடிக்கிற எத்தியோப்பியரும், பூத்தியரும், வில்லைப்பிடித்து நாணேற்றுகிற லீதியரும் புறப்படக்கடவர்கள்.
	<br /><br />
	10. ஆனாலும், இது சேனைகளின் கர்த்தராகிய ஆண்டவரின் நாளும், அவர் தம்முடைய சத்துருக்களுக்கு நீதியைச் சரிக்கட்டுகிற நாளுமாயிருக்கிறது; ஆகையால், பட்டயம் பட்சித்து, அவர்களுடைய இரத்தத்தால் திருப்தியாகி வெறித்திருக்கும்; வடதேசத்தில் ஐப்பிராத்து நதியண்டையிலே சேனைகளின் கர்த்தராகிய ஆண்டவருக்கு ஒரு யாகமும் உண்டு.
	<br /><br />
	11. எகிப்தின் குமாரத்தியாகிய கன்னிகையே, நீ கீலேயாத்துக்குப் போய், பிசின் தைலம் வாங்கு; திரளான அவிழ்தங்களை நீ கூட்டுகிறது விருதா, உனக்கு ஆரோக்கியமுண்டாகாது.
	<br /><br />
	12. ஜாதிகள் உன் இலச்சையைக் கேள்விப்பட்டார்கள்; உன் கூக்குரலால் தேசம் நிறைந்தது; பராக்கிரமசாலியின்மேல் பராக்கிரமசாலி இடறி, இருவரும் ஏகமாய் விழுந்தார்கள் என்றார்.
	<br /><br />
	13. எகிப்துதேசத்தை அழிக்கப் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் வருவானென்பதைக்குறித்து, எரேமியா தீர்க்கதரிசியினிடத்தில் கர்த்தர் சொன்ன வசனம்:
	<br /><br />
	14. ஆயத்தப்பட்டு நில், பட்டயம் உன்னைச் சுற்றிலும் உண்டானதைப் பட்சித்துப்போடுகிறதென்று சொல்லி, எகிப்திலே அறிவித்து, மிக்தோலிலே கூறி, நோப்பிலும் தக்பானேசிலும் பிரசித்தம்பண்ணுங்கள்.
	<br /><br />
	15. உன் வீரர் வாரிக்கொள்ளப்படுகிறதென்ன? கர்த்தர் அவர்களைத் தள்ளினதால் அவர்கள் நிலைநிற்கவில்லை.
	<br /><br />
	16. அநேகரை இடறப்பண்ணுகிறார்; அவனவன் தனக்கடுத்தவன்மேல் விழுகிறான்; அவர்கள்: எழுந்திருங்கள், கொல்லுகிற பட்டயத்துக்குத் தப்ப நமது ஜனத்தண்டைக்கும், நாம் பிறந்த தேசத்துக்கும் திரும்பிப்போவோம் என்கிறார்கள்.
	<br /><br />
	17. எகிப்தின் ராஜாவாகிய பார்வோன் பாழாக்கப்பட்டான்; அவனுக்குக் குறித்த காலம் முடிந்ததென்று அங்கே சத்தமிட்டுச் சொல்லுகிறார்கள்.
	<br /><br />
	18. பர்வதங்களில் தாபோரும், சமுத்திரத்தின் அருகே கர்மேலும் இருக்கிற நிச்சயம்போல் அவன் வருவானென்று சேனைகளின் கர்த்தர் என்னும் நாமமுள்ள ராஜா தம்முடைய ஜீவனைக்கொண்டு சொல்லுகிறார்.
	<br /><br />
	19. எகிப்துதேசவாசியாகிய குமாரத்தியே, சிறையிருப்புக்குப் போகும் பிரயாண சாமான்களை ஆயத்தப்படுத்து, நோப் பாழாகும்; அது குடியில்லாமல் சுட்டெரிக்கப்பட்டுக் கிடக்கும்.
	<br /><br />
	20. எகிப்து மகா நேர்த்தியான கடாரி, அடிக்கிறவன் வடக்கேயிருந்து வருகிறான்.
	<br /><br />
	21. அதின் நடுவில் இருக்கிற அதின் கூலிப்படைகள் கொழுத்த காளைகள் போலிருக்கிறார்கள்; இவர்களும் நிற்காமல், திரும்பிக்கொண்டு ஏகமாய் ஓடிப்போவார்கள்; அவர்கள் விசாரிக்கப்படுகிற அவர்களுடைய ஆபத்துநாள் அவர்கள்மேல் வந்தது.
	<br /><br />
	22. அவன் பாம்பைப்போல் சீறிவருவான், இராணுவபலத்தோடே நடந்து, காடுவெட்டிகளைப்போல் கோடரிகளோடு அதின்மேல் வருவார்கள்.
	<br /><br />
	23. எண்ணப்படாத மரங்களாயிருந்தாலும் அந்தக் காட்டை வெட்டுவார்கள் என்று கர்த்தர் சொல்லுகிறார்; அவர்கள் வெட்டுக்கிளிகளைப்பார்க்கிலும் அதிகமானவர்கள், அவர்களுக்குத் தொகையில்லை.
	<br /><br />
	24. எகிப்தின் குமாரத்தி கலங்குவாள்; வடதிசை ஜனத்தின் கையில் ஒப்புக்கொடுக்கப்படுவாள்.
	<br /><br />
	25. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் நோ என்னும் பட்டணத்திலுள்ள திரளான ஜனங்களையும், பார்வோனையும், எகிப்தையும், அதின் தேவர்களையும், அதின் ராஜாக்களையும், பார்வோனையும், அவனை நம்பியிருக்கிறவர்களையும் விசாரித்து,
	<br /><br />
	26. அவர்கள் பிராணனை வாங்கத் தேடுகிறவர்களின் கையிலும், பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரின் கையிலும், அவனுடைய சேவகரின் கையிலும், அவர்களை ஒப்புக்கொடுப்பேன்; அதற்குப்பின்பு அது பூர்வகாலத்தில் இருந்ததுபோல் குடியேற்றப்படும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	27. என் தாசனாகிய யாக்கோபே, நீ பயப்படாதே; இஸ்ரவேலே, நீ கலங்காதே; இதோ, நான் உன்னைத் தூரத்திலும், உன் சந்ததியை அவர்கள் சிறையிருப்பின் தேசத்திலுமிருந்து விடுவித்து இரட்சிப்பேன்; அப்பொழுது யாக்கோபு திரும்பிவந்து, அமைதியோடும் சாங்கோபாங்கத்தோடும் இருப்பான்; அவனைத் தத்தளிக்கப்பண்ணுவார் இல்லை.
	<br /><br />
	28. என் தாசனாகிய யாக்கோபே, நீ பயப்படாதே என்று கர்த்தர் சொல்லுகிறார்; நான் உன்னுடனே இருக்கிறேன்; உன்னைத் துரத்திவிட்ட எல்லா ஜாதிகளையும் நான் நிர்மூலமாக்குவேன்; உன்னையோ நான் நிர்மூலமாக்காமல், உன்னை மட்டாய்த் தண்டிப்பேன்; ஆனாலும் உன்னை நான் குற்றமில்லாமல் நீங்கலாக விடுவதில்லையென்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah46