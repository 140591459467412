import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const ThreeJohn3 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> Faithful Ministers of the Truth 3 John Tamil Bible | 3  யோவான் </title>
	<meta
          name="description"
          content="Welcome Faithful Ministers of the Truth | 3 -John in Tamil Bible Verse | 3-யோவான்அதிகாரம் - 1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* சத்தியத்தின் உண்மையுள்ள அமைச்சர்களை வரவேற்கிறோம் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. மூப்பனாகிய நான் சத்தியத்தின்படி நேசிக்கிற பிரியமான காயுவுக்கு எழுதுகிறதாவது:
	<br /><br />
	2. பிரியமானவனே, உன் ஆத்துமா வாழ்கிறதுபோல நீ எல்லாவற்றிலும் வாழ்ந்து சுகமாயிருக்கும்படி வேண்டுகிறேன்.
	<br /><br />
	3. சகோதரர் வந்து நீ சத்தியத்தில் நடந்துகொள்ளுகிறாய் என்று உன்னுடைய உண்மையைக்குறித்துச் சாட்சி கொடுத்தபோது மிகவும் சந்தோஷப்பட்டேன்.
	<br /><br />
	4. என் பிள்ளைகள் சத்தியத்திலே நடக்கிறார்கள் என்று நான் கேள்விப்படுகிற சந்தோஷத்திலும் அதிகமான சந்தோஷம் எனக்கு இல்லை.
	<br /><br />
	5. பிரியமானவனே, நீ சகோதரருக்கும் அந்நியருக்கும் செய்கிற யாவற்றையும் உண்மையாய்ச் செய்கிறாய்.
	<br /><br />
	6. அவர்கள் உன்னுடைய அன்பைக் குறித்துச் சபைக்குமுன்பாகச் சாட்சி சொன்னார்கள்; தேவனுக்கு முன்பாகப் பாத்திரமானபடி அவர்களை நீ வழிவிட்டனுப்பினால் நலமாயிருக்கும்.
	<br /><br />
	7. ஏனெனில் அவர்கள் புறஜாதியாரிடத்தில் ஒன்றும் வாங்காமல் அவருடைய நாமத்தினிமித்தம் புறப்பட்டுப்போனார்கள்.
	<br /><br />
	8. ஆகையால் நாம் சத்தியத்திற்கு உடன்வேலையாட்களாயிருக்கும்படி அப்படிப்பட்டவர்களைச் சேர்த்துக்கொள்ளக் கடனாளிகளாயிருக்கிறோம்.
	<br /><br />
	9. நான் சபைக்கு எழுதினேன்; ஆனாலும் அவர்களில் முதன்மையாயிருக்க விரும்புகிற தியோத்திரேப்பு எங்களை ஏற்றுக்கொள்ளவில்லை.
	<br /><br />
	10. ஆனபடியால், நான் வந்தால், அவன் எங்களுக்கு விரோதமாய்ப் பொல்லாத வார்த்தைகளை அலப்பி, செய்துவருகிற கிரியைகளை நினைத்துக்கொள்வேன். அவன் இப்படிச் செய்துவருவதும் போதாமல், தான் சகோதரரை ஏற்றுக்கொள்ளாமலிருக்கிறதுமன்றி, ஏற்றுக்கொள்ள மனதாயிருக்கிறவர்களையும் தடைசெய்து, சபைக்குப் புறம்பே தள்ளுகிறான்.
	<br /><br />
	11. பிரியமானவனே, நீ தீமையானதைப் பின்பற்றாமல், நன்மையானதைப் பின்பற்று, நன்மைசெய்கிறவன் தேவனால் உண்டாயிருக்கிறான்; தீமைசெய்கிறவன் தேவனைக் காணவில்லை.
	<br /><br />
	12. தேமேத்திரியு எல்லாராலும் நற்சாட்சிபெற்றதுமல்லாமல், சத்தியத்தாலும் நற்சாட்சிபெற்றவன்; நாங்களும் சாட்சிகொடுக்கிறோம், எங்கள் சாட்சி மெய்யென்று அறிவீர்கள்.
	<br /><br />
	13. எழுதவேண்டிய காரியங்கள் அநேகமுண்டு; ஆனால் மையினாலும் இறகினாலும் எழுத எனக்கு மனதில்லை.
	<br /><br />
	14. சீக்கிரமாய் உன்னைக் காணலாமென்று நம்பியிருக்கிறேன், அப்பொழுது முகமுகமாய்ப் பேசிக்கொள்ளுவோம். உனக்குச் சமாதானம் உண்டாவதாக. சிநேகிதர் உனக்கு வாழ்த்துதல் சொல்லுகிறார்கள். சிநேகிதரைப் பேர்பேராக வாழ்த்துவாயாக.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 3 யோவான் 1, கயஸுக்கு அப்போஸ்தலன் யோவானின் தனிப்பட்ட வாழ்த்துடன் தொடங்குகிறது. கர்த்தருடைய பயண ஆசிரியர்களை வரவேற்க அவருடைய வீட்டைத் திறந்ததில் அவருடைய உண்மைத்தன்மைக்காக யோவான் அவரைப் பாராட்டினார். சத்தியத்தில் அவர்களுடன் பங்காளியாக இருக்க, அத்தகைய மனிதர்களை வரவேற்க அவர் நினைவூட்டுகிறார். அப்போஸ்தலரைப் பற்றி பொய்களைப் பரப்பி, இந்த மனிதர்களை வரவேற்க மறுத்து, செய்த எவரையும் பலப்படுத்திய தியோட்ரெப்ஸ் காட்டிய அழிவுகரமான நடத்தைக்கு எதிராக யோவான் பின்னர் எச்சரிக்கிறார். யோவான் அவர்களிடம் வந்து நேருக்கு நேர் பேச விருப்பம் தெரிவித்து கடிதத்தை மூடுகிறார். *
<br /><br />
		<br /><br />
* நீங்கள் 3 யோவான் புத்தகத்தை வாசிக்கும்போதும் படிக்கும்போதும் நீங்கள் ஆசீர்வதிக்கப்பட்டவர்களாகவும் ஊக்கமளிப்பவர்களாகவும் இருப்பீர்களாக. *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default ThreeJohn3