import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 3 | Tamil Bible Verse | வெளிப்படுத்தல் -3  </title>
	<meta
          name="description"
          content="The Book of Revelation - 3 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h3>
* "சர்தை, நீங்கள் உயிருடன் இல்லை, ஆனால் இறந்துவிட்டீர்கள்.  பிலதெல்பியா, நீங்கள் என் வார்த்தையில் நிலைத்திருக்கிறீர்கள். லவோதிக்கேயா, நீங்கள் வெதுவெதுப்பானவர்." *
			</h3>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. சர்தை சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: தேவனுடைய ஏழு ஆவிகளையும் ஏழு நட்சத்திரங்களையும் உடையவர் சொல்லுகிறதாவது; உன் கிரியைகளை அறிந்திருக்கிறேன், நீ உயிருள்ளவனென்று பெயர்கொண்டிருந்தும் செத்தவனாயிருக்கிறாய்.
	<br /><br />
	2. நீ விழித்துக்கொண்டு, சாகிறதற்கேதுவாயிருக்கிறவைகளை ஸ்திரப்படுத்து; உன் கிரியைகள் தேவனுக்குமுன் நிறைவுள்ளவைகளாக நான் காணவில்லை.
	<br /><br />
	3. ஆகையால் நீ கேட்டுப் பெற்றுக்கொண்ட வகையை நினைவுகூர்ந்து, அதைக் கைக்கொண்டு மனந்திரும்பு. நீ விழித்திராவிட்டால், திருடனைப்போல் உன்மேல் வருவேன்; நான் உன்மேல் வரும்வேளையை அறியாதிருப்பாய்.
	<br /><br />
	4. ஆனாலும் தங்கள் வஸ்திரங்களை அசுசிப்படுத்தாத சிலபேர் சர்தையிலும் உனக்குண்டு; அவர்கள் பாத்திரவான்களானபடியால் வெண்வஸ்திரந்தரித்து என்னோடேகூட நடப்பார்கள்.
	<br /><br />
	5. ஜெயங்கொள்ளுகிறவனெவனோ அவனுக்கு வெண்வஸ்திரம் தரிப்பிக்கப்படும்; ஜீவபுஸ்தகத்திலிருந்து அவனுடைய நாமத்தை நான் கிறுக்கிப்போடாமல், என் பிதா முன்பாகவும் அவருடைய தூதர் முன்பாகவும் அவன் நாமத்தை அறிக்கையிடுவேன்.
	<br /><br />
	6. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன் என்றெழுது.
	<br /><br />
	7. பிலதெல்பியா சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: பரிசுத்தமுள்ளவரும், சத்தியமுள்ளவரும், தாவீதின் திறவுகோலை உடையவரும், ஒருவரும் பூட்டக்கூடாதபடிக்குத் திறக்கிறவரும், ஒருவரும் திறக்கக்கூடாதபடிக்குப் பூட்டுகிறவருமாயிருக்கிறவர் சொல்லுகிறதாவது;
	<br /><br />
	8. உன் கிரியைகளை அறிந்திருக்கிறேன்; உனக்குக் கொஞ்சம் பெலன் இருந்தும், நீ என் நாமத்தை மறுதலியாமல், என் வசனத்தைக் கைக்கொண்டபடியினாலே, இதோ, திறந்தவாசலை உனக்கு முன்பாக வைத்திருக்கிறேன், அதை ஒருவனும் பூட்டமாட்டான்.
	<br /><br />
	9. இதோ, யூதரல்லாதிருந்தும் தங்களை யூதரென்று பொய் சொல்லுகிறவர்களாகிய சாத்தானுடைய கூட்டத்தாரில் சிலரை உனக்குக் கொடுப்பேன்; இதோ, அவர்கள் உன் பாதங்களுக்கு முன்பாக வந்து பணிந்து, நான் உன்மேல் அன்பாயிருக்கிறதை அறிந்துகொள்ளும்படி செய்வேன்.
	<br /><br />
	10. என் பொறுமையைக்குறித்துச் சொல்லிய வசனத்தை நீ காத்துக்கொண்டபடியினால், பூமியில் குடியிருக்கிறவர்களைச் சோதிக்கும்படியாகப் பூச்சக்கரத்தின்மேலெங்கும் வரப்போகிற சோதனைகாலத்திற்குத் தப்பும்படி நானும் உன்னைக் காப்பேன்.
	<br /><br />
	11. இதோ, சீக்கிரமாய் வருகிறேன்; ஒருவனும் உன் கிரீடத்தை எடுத்துக்கொள்ளாதபடிக்கு உனக்குள்ளதைப் பற்றிக்கொண்டிரு.
	<br /><br />
	12. ஜெயங்கொள்ளுகிறவனெவனோ அவனை என் தேவனுடைய ஆலயத்திலே தூணாக்குவேன், அதினின்று அவன் ஒருக்காலும் நீங்குவதில்லை; என் தேவனுடைய நாமத்தையும் என் தேவனால் பரலோகத்திலிருந்திறங்கிவருகிற புதிய எருசலேமாகிய என் தேவனுடைய நகரத்தின் நாமத்தையும், என் புதிய நாமத்தையும் அவன்மேல் எழுதுவேன்.
	<br /><br />
	13. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன் என்றெழுது.
	<br /><br />
	14. லவோதிக்கேயா சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: உண்மையும் சத்தியமுமுள்ள சாட்சியும், தேவனுடைய சிருஷ்டிக்கு ஆதியுமாயிருக்கிற ஆமென் என்பவர் சொல்லுகிறதாவது;
	<br /><br />
	15. உன் கிரியைகளை அறிந்திருக்கிறேன்; நீ குளிருமல்ல அனலுமல்ல; நீ குளிராயாவது அனலாயாவது இருந்தால் நலமாயிருக்கும்.
	<br /><br />
	16. இப்படி நீ குளிருமின்றி அனலுமின்றி வெதுவெதுப்பாயிருக்கிறபடியினால் உன்னை என் வாயினின்று வாந்திபண்ணிப்போடுவேன்.
	<br /><br />
	17. நீ நிர்ப்பாக்கியமுள்ளவனும், பரிதபிக்கப்படத்தக்கவனும், தரித்திரனும், குருடனும், நிர்வாணியுமாயிருக்கிறதை அறியாமல், நான் ஐசுவரியவானென்றும், திரவியசம்பன்னனென்றும், எனக்கு ஒரு குறைவுமில்லையென்றும் சொல்லுகிறபடியால்;
	<br /><br />
	18. நான்: நீ ஐசுவரியவானாகும்படிக்கு நெருப்பிலே புடமிடப்பட்ட பொன்னையும், உன் நிர்வாணமாகிய அவலட்சணம் தோன்றாதபடிக்கு நீ உடுத்திக்கொள்வதற்கு வெண்வஸ்திரங்களையும் என்னிடத்திலே வாங்கிக்கொள்ளவும், நீ பார்வையடையும்படிக்கு உன் கண்களுக்குக் கலிக்கம்போடவும் வேண்டுமென்று உனக்கு ஆலோசனை சொல்லுகிறேன்.
	<br /><br />
	19. நான் நேசிக்கிறவர்களெவர்களோ அவர்களைக் கடிந்துகொண்டு சிட்சிக்கிறேன்; ஆகையால் நீ ஜாக்கிரதையாயிருந்து, மனந்திரும்பு.
	<br /><br />
	20. இதோ, வாசற்படியிலே நின்று தட்டுகிறேன்; ஒருவன் என் சத்தத்தைக் கேட்டு, கதவைத் திறந்தால், அவனிடத்தில் நான் பிரவேசித்து, அவனோடே போஜனம்பண்ணுவேன், அவனும் என்னோடே போஜனம்பண்ணுவான்.
	<br /><br />
	21. நான் ஜெயங்கொண்டு என் பிதாவினுடைய சிங்காசனத்திலே அவரோடேகூட உட்கார்ந்ததுபோல, ஜெயங்கொள்ளுகிறவனெவனோ அவனும் என்னுடைய சிங்காசனத்தில் என்னோடேகூட உட்காரும்படிக்கு அருள்செய்வேன்.
	<br /><br />
	22. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன் என்றெழுது என்றார்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
*  *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation3