import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 9 </title>
	<meta
          name="description"
          content="Zechariah 9 | சகரியா 9 | Sageria 9 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆதிராக் தேசத்துக்கு விரோதமானதும், தமஸ்குவின்மேல் வந்து தங்குவதுமான கர்த்தருடைய வார்த்தையாகிய பாரம்; மனுஷரின் கண்களும் இஸ்ரவேலுடைய சகல கோத்திரங்களின் கண்களும் கர்த்தரை நோக்கிக்கொண்டிருக்கும்.
	<br /><br />
	2. ஆமாத்தும், மிகவும் ஞானமுள்ள தீருவும் சீதோனும் அதின் எல்லைக்குள்ளாயிருக்கும்.
	<br /><br />
	3. தீரு தனக்கு அரணைக்கட்டி, தூளைப்போல் வெள்ளியையும், வீதிகளின் சேற்றைப்போல் பசும்பொன்னையும் சேர்த்துவைத்தது.
	<br /><br />
	4. இதோ, ஆண்டவர் அதைத் தள்ளிவிட்டு, சமுத்திரத்தில் அதின் பலத்தை முறித்துப்போடுவார்; அது அக்கினிக்கு இரையாகும்.
	<br /><br />
	5. அஸ்கலோன் அதைக் கண்டு பயப்படும், காத்சாவும் அதைக்கண்டு மிகவும் துக்கிக்கும், எக்ரோனும் தன் நம்பிக்கை அற்றுப்போனபடியால் மிகவும் பிரலாபிக்கும்; காத்சாவில் ராஜா அழிந்துபோவான்; அஸ்கலோன் குடியற்றிருக்கும்.
	<br /><br />
	6. அஸ்தோத்தில் வேசிப்பிள்ளைகள் வாசம்பண்ணுவார்கள்; நான் பெலிஸ்தரின் கர்வத்தை அழிப்பேன்.
	<br /><br />
	7. அவனுடைய இரத்தத்தை அவன் வாயிலிருந்தும், அவனுடைய அருவருப்புகளை அவன் பல்லுகளின் நடுவிலிருந்தும் நீக்கிப்போடுவேன்; அவனோ நம்முடைய தேவனுக்கென்று மீதியாக வைக்கப்பட்டு, யூதாவிலே பிரபுவைப்போல இருப்பான்; எக்ரோன் எபூசியனைப்போல இருப்பான்.
	<br /><br />
	8. சேனையானது புறப்படும்போதும், திரும்பி வரும்போதும், என் ஆலயம் காக்கப்படும்படி அதைச் சுற்றிலும் பாளயம்போடுவேன்; இனி ஒடுக்குகிறவன் அவர்களிடத்தில் கடந்துவருவதில்லை; அதை என் கண்களினாலே பார்த்துக்கொண்டிருக்கிறேன்.
	<br /><br />
	9. சீயோன் குமாரத்தியே, மிகவும் களிகூரு; எருசலேம் குமாரத்தியே, கெம்பீரி; இதோ, உன் ராஜா உன்னிடத்தில் வருகிறார்; அவர் நீதியுள்ளவரும் இரட்சிக்கிறவரும் தாழ்மையுள்ளவரும், கழுதையின்மேலும் கழுதைக்குட்டியாகிய மறியின்மேலும் ஏறிவருகிறவருமாயிருக்கிறார்.
	<br /><br />
	10. எப்பிராயீமினின்று இரதங்களையும் எருசலேமினின்று குதிரைகளையும் அற்றுப்போகப்பண்ணுவேன், யுத்தவில்லும் இல்லாமற்போகும்; அவர் ஜாதிகளுக்குச் சமாதானம் கூறுவார்; அவருடைய ஆளுகை ஒரு சமுத்திரந்தொடங்கி மறுசமுத்திரம்வரைக்கும், நதிதொடங்கிப் பூமியின் எல்லைகள்பரியந்தமும் செல்லும்.
	<br /><br />
	11. உனக்கு நான் செய்வதென்னவென்றால், தண்ணீரில்லாத குழியிலே அடைபட்டிருக்கிற உன்னுடையவர்களை நான் உன் உடன்படிக்கையின் இரத்தத்தினாலே விடுதலைபண்ணுவேன்.
	<br /><br />
	12. நம்பிக்கையுடைய சிறைகளே, அரணுக்குத் திரும்புங்கள்; இரட்டிப்பான நன்மையைத் தருவேன், இன்றைக்கே தருவேன்.
	<br /><br />
	13. நான் எனக்கென்று யூதாவை நாணேற்றி, எப்பிராயீமிலே வில்லை நிரப்பி, சீயோனே, உன் புத்திரரைக் கிரேக்குதேசப் புத்திரருக்கு விரோதமாக எழுப்பி, உன்னைப் பராக்கிரமசாலியின் பட்டயத்துக்கு ஒப்பாக்குவேன்.
	<br /><br />
	14. அவர்கள் பட்சத்தில் கர்த்தர் காணப்படுவார்; அவருடைய அம்பு மின்னலைப்போலப் புறப்படும்; கர்த்தராகிய ஆண்டவர் எக்காளம் ஊதி, தென்திசைச் சுழல்காற்றுகளோடே நடந்துவருவார்.
	<br /><br />
	15. சேனைகளின் கர்த்தர் அவர்களைக் காப்பாற்றுவார்; அவர்கள் பட்சித்து, கவண்கற்களால் கீழ்ப்படுத்திக்கொள்வார்கள்; அவர்கள் குடித்துக் களிப்பினால் ஆரவாரிப்பார்கள்; பானபாத்திரங்கள்போலவும் பலிபீடத்தின் கோடிகளைப்போலவும் நிறைந்திருப்பார்கள்.
	<br /><br />
	16. அந்நாளில் அவர்களுடைய தேவனாகிய கர்த்தர் தம்முடைய ஜனமான மந்தையாகிய அவர்களை இரட்சிப்பார்; அவர்கள் அவருடைய தேசத்தில் ஏற்றப்பட்ட கொடிகளின் கிரீடத்தில் பதிந்திருப்பார்கள்.
	<br /><br />
	17. அவருடைய காருண்யம் எத்தனை பெரியது? அவருடைய சௌந்தரியம் எத்தனை பெரியது? தானியம் வாலிபரையும், புது திராட்சரசம் கன்னிகைகளையும் வளர்க்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah9