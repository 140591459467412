import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 6 </title>
	<meta
          name="description"
          content="Acts 6 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 6 |
          Acts of Apostles Chapter-6 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாட்களிலே, சீஷர்கள் பெருகினபோது, கிரேக்கரானவர்கள், தங்கள் விதவைகள் அன்றாடக விசாரணையில் திட்டமாய் விசாரிக்கப்படவில்லையென்று, எபிரெயருக்கு விரோதமாய் முறுமுறுத்தார்கள்.
	<br /><br />
	2. அப்பொழுது பன்னிருவரும் சீஷர் கூட்டத்தை வரவழைத்து: நாங்கள் தேவவசனத்தைப் போதியாமல், பந்திவிசாரணைசெய்வது தகுதியல்ல.
	<br /><br />
	3. ஆதலால் சகோதரரே, பரிசுத்தஆவியும் ஞானமும் நிறைந்து, நற்சாட்சி பெற்றிருக்கிற ஏழுபேரை உங்களில் தெரிந்துகொள்ளுங்கள்; அவர்களை இந்த வேலைக்காக ஏற்படுத்துவோம்.
	<br /><br />
	4. நாங்களோ ஜெபம்பண்ணுவதிலும் தேவவசனத்தைப் போதிக்கிற ஊழியத்திலும் இடைவிடாமல் தரித்திருப்போம் என்றார்கள்.
	<br /><br />
	5. இந்த யோசனை சபையாரெல்லாருக்கும் பிரியமாயிருந்தது. அப்பொழுது விசுவாசமும் பரிசுத்தஆவியும் நிறைந்தவனாகிய ஸ்தேவானையும், பிலிப்பையும், பிரொகோரையும், நிக்கானோரையும், தீமோனையும், பர்மெனாவையும், யூதமார்க்கத்தமைந்தவனான அந்தியோகியா பட்டணத்தானாகிய நிக்கொலாவையும் தெரிந்துகொண்டு,
	<br /><br />
	6. அவர்களை அப்போஸ்தலருக்குமுன்பாக நிறுத்தினார்கள். இவர்கள் ஜெபம்பண்ணி, அவர்கள்மேல் கைகளை வைத்தார்கள்.
	<br /><br />
	7. தேவவசனம் விருத்தியடைந்தது; சீஷருடைய தொகை எருசலேமில் மிகவும் பெருகிற்று; ஆசாரியர்களில் அநேகரும் விசுவாசத்துக்குக் கீழ்ப்படிந்தார்கள்.
	<br /><br />
	8. ஸ்தேவான் விசுவாசத்தினாலும் வல்லமையினாலும் நிறைந்தவனாய் ஜனங்களுக்குள்ளே பெரிய அற்புதங்களையும் அடையாளங்களையும் செய்தான்.
	<br /><br />
	9. அப்பொழுது லிபர்த்தீனர் என்னப்பட்டவர்களின் ஆலயத்தைச் சேர்ந்தவர்களிலும், சிரேனே பட்டணத்தாரிலும், அலெக்சந்திரியா பட்டணத்தாரிலும், சிலிசியா நாட்டாரிலும், ஆசியா தேசத்தாரிலும் சிலர் எழும்பி, ஸ்தேவானுடனே தர்க்கம்பண்ணினார்கள்.
	<br /><br />
	10. அவன் பேசின ஞானத்தையும் ஆவியையும் எதிர்த்துநிற்க அவர்களால் கூடாமற்போயிற்று.
	<br /><br />
	11. அப்பொழுது அவர்கள்: மோசேக்கும் தேவனுக்கும் விரோதமாக இவன் தூஷண வார்த்தைகளைப் பேசக்கேட்டோம் என்று சொல்லும்படியாக மனுஷரை ஏற்படுத்தி;
	<br /><br />
	12. ஜனங்களையும் மூப்பரையும் வேதபாரகரையும் எழுப்பிவிட்டு; அவன்மேல் பாய்ந்து, அவனைப் பிடித்து, ஆலோசனைச் சங்கத்தாருக்கு முன்பாக இழுத்துக்கொண்டுபோய்;
	<br /><br />
	13. பொய்ச்சாட்சிகளையும் நிறுத்தினார்கள். இவர்கள்: இந்த மனுஷன் இந்தப் பரிசுத்த ஸ்தலத்துக்கும் வேதப்பிரமாணத்துக்கும் விரோதமாகத் தூஷண வார்த்தைகளை ஓயாமற் பேசுகிறான்;
	<br /><br />
	14. எப்படியென்றால், நசரேயனாகிய அந்த இயேசு இந்த ஸ்தலத்தை அழித்துப்போட்டு, மோசே நமக்குக் கொடுத்த முறைமைகளை மாற்றுவானென்று இவன் சொல்லக்கேட்டோம் என்றார்கள்.
	<br /><br />
	15. ஆலோசனைச் சங்கத்தில் உட்கார்ந்திருந்த அனைவரும் அவன்மேல் கண்ணோக்கமாயிருந்து, அவன் முகம் தேவதூதன் முகம்போலிருக்கக் கண்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts6