import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 5 </title>
	<meta
          name="description"
          content="Micah 5 | மீகா 5 | Meega 5|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சேனைகளையுடைய நகரமே, இப்போது தண்டுதண்டாகக் கூடிக்கொள்; நமக்கு விரோதமாக முற்றிக்கை போடப்படும்; இஸ்ரவேலுடைய நியாயாதிபதியைக் கோலினால் கன்னத்திலே அடிப்பார்கள்.
	<br /><br />
	2. எப்பிராத்தா என்னப்பட்ட பெத்லெகேமே, நீ யூதேயாவிலுள்ள ஆயிரங்களுக்குள்ளே சிறியதாயிருந்தும், இஸ்ரவேலை ஆளப்போகிறவர் உன்னிடத்திலிருந்து புறப்பட்டு என்னிடத்தில் வருவார்; அவருடைய புறப்படுதல் அநாதிநாட்களாகிய பூர்வத்தினுடையது.
	<br /><br />
	3. ஆனாலும் பிரசவிக்கிறவள் பிரசவிக்கிறமட்டும் அவர்களை ஒப்புக்கொடுப்பார்; அப்பொழுது அவருடைய சகோதரரில் மீதியானவர்கள் இஸ்ரவேல் புத்திரரோடுங்கூடத் திரும்புவார்கள்.
	<br /><br />
	4. அவர் நின்றுகொண்டு, கர்த்தருடைய பலத்தோடும் தம்முடைய தேவனாகிய கர்த்தருடைய நாமத்தின் மகத்துவத்தோடும் மேய்ப்பார்; ஆகையால் அவர்கள் நிலைத்திருப்பார்கள்; அவர் இனிப் பூமியின் எல்லைகள்பரியந்தமும் மகிமைப்படுவார்.
	<br /><br />
	5. இவரே சமாதான காரணர்; அசீரியன் நம்முடைய தேசத்திலே வரும்போதும், நம்முடைய அரண்மனைகளை மிதிக்கும்போதும், ஏழு மேய்ப்பரையும் மனுஷரில் எட்டு அதிபதிகளையும் அவனுக்கு விரோதமாக நிறுத்துவேன்.
	<br /><br />
	6. இவர்கள் அசீரியா தேசத்தையும், நிம்ரோதின் தேசத்தையும், அதினுடைய வாசல்களுக்கு உட்புறமாகப் பட்டயத்திற்கு இரையாக்குவார்கள்; அசீரியன் நம்முடைய தேசத்தில் வரும்போதும், நம்முடைய எல்லைகளை மிதிக்கும்போதும் அவனுக்கு நம்மைத் தப்புவிப்பார்.
	<br /><br />
	7. யாக்கோபிலே மீதியானவர்கள் கர்த்தராலே வருகிற பனியைப்போலவும், மனுஷனுக்குக் காத்திராமலும், மனுபுத்திரருக்குத் தாமதியாமலும், பூண்டுகள்மேல் வருகிற மழைகளைப்போலவும், அநேக ஜனங்களின் நடுவிலே இருப்பார்கள்.
	<br /><br />
	8. யாக்கோபிலே மீதியானவர்கள், சிங்கம் காட்டுமிருகங்களுக்குள்ளே இருக்கிறதற்குச் சமானமாகவும், கடந்துபோய் மிதித்துத் தப்புவிப்பார் இல்லாமல் பீறிப்போடுகிற பாலசிங்கம் ஆட்டுமந்தைகளுக்குள்ளே இருக்கிறதற்குச் சமானமாகவும் ஜாதிகளுக்குள் அநேக ஜனங்களின் நடுவிலே இருப்பார்கள்.
	<br /><br />
	9. உன்னுடைய கை உன் விரோதிகளின்மேல் உயரும்; உன் சத்துருக்களெல்லாரும் சங்கரிக்கப்படுவார்கள்.
	<br /><br />
	10. அந்நாளிலே நான் உன் குதிரைகளை உன் நடுவில் இராதபடிக்குச் சங்கரித்து, உன் இரதங்களை அழித்து,
	<br /><br />
	11. உன் தேசத்துப் பட்டணங்களைச் சங்கரித்து, உன் அரண்களையெல்லாம் நிர்மூலமாக்கி,
	<br /><br />
	12. சூனிய வித்தைகள் உன் கையில் இராதபடிக்கு அகற்றுவேன்; நாள் பார்க்கிறவர்கள் உன்னிடத்தில் இல்லாமற்போவார்கள்.
	<br /><br />
	13. உன் சுரூபங்களையும் உன் சிலைகளையும் உன் நடுவில் இராதபடிக்கு நிர்மூலமாக்குவேன்; உன் கையின் கிரியையை நீ இனிப் பணிந்துகொள்ளாய்.
	<br /><br />
	14. நான் உன் விக்கிரகத்தோப்புகளை உன் நடுவில் இராதபடிக்குப் பிடுங்கி, உன் பட்டணங்களை அழித்து,
	<br /><br />
	15. செவிகொடாத புறஜாதிகளிடத்திலே கோபத்தோடும் உக்கிரத்தோடும் நீதியைச் சரிக்கட்டுவேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah5