import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah26 = () => {
  const verseNumber = 26;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 26 </title>
	<meta
          name="description"
          content="Jeremiah 26 | எரேமியா 26 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோசியாவின் குமாரனும் யூதாவின் ராஜாவுமாகிய யோயாக்கீமுடைய ராஜ்யபாரத்தின் துவக்கத்திலே கர்த்தரால் உண்டான வார்த்தை:
	<br /><br />
	2. நீ கர்த்தருடைய ஆலயத்தின் பிராகாரத்திலே நின்றுகொண்டு, கர்த்தருடைய ஆலயத்திலே பணியவருகிற யூதாவுடைய பட்டணங்களின் குடிகள் அனைவரோடும் சொல்லும்படி நான் உனக்குக் கற்பித்த எல்லா வார்த்தைகளையும் அவர்களுக்குச் சொல்லு; ஒரு வார்த்தையையும் குறைத்துப்போடாதே என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. அவர்கள் செய்கைகளுடைய பொல்லாப்பினிமித்தம் நான் அவர்களுக்குச் செய்ய நினைக்கிற தீங்குக்கு நான் மனஸ்தாபப்படத்தக்கதாக ஒருவேளை அவர்கள் கேட்டு, அவரவர் தம்தம் பொல்லாத வழியைவிட்டுத் திரும்புவார்கள்.
	<br /><br />
	4. நீ அவர்களை நோக்கி: நான் உங்களிடத்துக்கு ஏற்கனவே அனுப்பிக்கொண்டிருந்தும், நீங்கள் கேளாமற்போன என் ஊழியக்காரராகிய தீர்க்கதரிசிகளின் வார்த்தைகளை நீங்கள் கேட்கும்படிக்கும்,
	<br /><br />
	5. நான் உங்கள் முன்வைத்த என் நியாயப்பிரமாணத்தின்படி நீங்கள் நடக்கும்படிக்கும், நீங்கள் என் சொல்லைக் கேளாமற்போனால்,
	<br /><br />
	6. நான் இந்த ஆலயத்தைச் சீலோவாவைப்போலாக்கி, இந்த நகரத்தைப் பூமியிலுள்ள எல்லா ஜாதிகளுக்கு முன்பாகவும் சாபமாக்கிப்போடுவேன் என்று கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	7. எரேமியா இந்த வார்த்தைகளையெல்லாம் கர்த்தருடைய ஆலயத்திலே சொல்லும்போது, ஆசாரியர்களும், தீர்க்கதரிசிகளும், சகல ஜனங்களும் கேட்டார்கள்.
	<br /><br />
	8. சகல ஜனங்களுக்கும் சொல்லக் கர்த்தர் தனக்குக் கற்பித்தவைகளையெல்லாம் எரேமியா சொல்லி முடித்தபோது, ஆசாரியர்களும் தீர்க்கதரிசிகளும் சகல ஜனங்களும் அவனைப் பிடித்து: நீ சாகவே சாகவேண்டும்.
	<br /><br />
	9. இந்த ஆலயம் சீலோவைப்போலாகி, இந்த நகரம் குடியில்லாமல் பாழாய்ப்போம் என்று, நீ கர்த்தருடைய நாமத்திலே தீர்க்கதரிசனம் சொல்வானேன் என்று சொல்லி, ஜனங்கள் எல்லாரும் கர்த்தருடைய ஆலயத்திலே எரேமியாவுக்கு விரோதமாய்க் கூடினார்கள்.
	<br /><br />
	10. யூதாவின் பிரபுக்கள் இந்த வர்த்தமானங்களைக் கேட்டு, ராஜாவின் வீட்டிலிருந்து கர்த்தருடைய ஆலயத்துக்குப் போய், கர்த்தருடைய புதிய வாசலில் உட்கார்ந்தார்கள்.
	<br /><br />
	11. அப்பொழுது ஆசாரியர்களும் தீர்க்கதரிசிகளும், பிரபுக்களையும் சகல ஜனங்களையும் நோக்கி: இந்த மனுஷன் மரண ஆக்கினைக்குப் பாத்திரன்; உங்கள் செவிகளாலே நீங்கள் கேட்டபடி, இந்த நகரத்துக்கு விரோதமாகத் தீர்க்கதரிசனம் சொன்னானே என்றார்கள்.
	<br /><br />
	12. அப்பொழுது எரேமியா எல்லாப் பிரபுக்களையும், எல்லா ஜனங்களையும் நோக்கி: நீங்கள் கேட்ட எல்லா வார்த்தைகளையும் இந்த ஆலயத்துக்கும் இந்த நகரத்துக்கும் விரோதமாகத் தீர்க்கதரிசனமாய்ச் சொல்லக் கர்த்தர் என்னை அனுப்பினார்.
	<br /><br />
	13. இப்பொழுதும் நீங்கள் உங்கள் வழிகளையும், உங்கள் கிரியைகளையும் சீர்ப்படுத்தி, உங்கள் தேவனாகிய கர்த்தருடைய சத்தத்தைக் கேளுங்கள்; அப்பொழுது கர்த்தர் உங்களுக்கு விரோதமாய்ச் சொன்ன தீங்குக்கு மனஸ்தாபப்படுவார்.
	<br /><br />
	14. நானோவெனில், இதோ, உங்கள் கையில் இருக்கிறேன்; உங்கள் பார்வைக்கு நன்மையும் நியாயமுமாயிருக்கிறதை எனக்குச் செய்யுங்கள்.
	<br /><br />
	15. ஆகிலும் நீங்கள் என்னைக் கொன்றுபோட்டால், நீங்கள் குற்றமில்லாத இரத்தப்பழியை உங்கள்மேலும் இந்த நகரத்தின்மேலும் இதின் குடிகளின்மேலும் சுமத்திக்கொள்வீர்களென்று நிச்சயமாய் அறியுங்கள்; இந்த வார்த்தைகளையெல்லாம் உங்கள் செவிகளிலே சொல்லக் கர்த்தர் மெய்யாகவே என்னை உங்களிடத்திற்கு அனுப்பினார் என்று சொன்னான்.
	<br /><br />
	16. அப்பொழுது பிரபுக்களும் சகல ஜனங்களும், ஆசாரியர்களையும் தீர்க்கதரிசிகளையும் நோக்கி: இந்த மனுஷன் மரண ஆக்கினைக்குப் பாத்திரனல்ல; நம்முடைய தேவனாகிய கர்த்தரின் நாமத்திலே நம்முடனே பேசினான் என்றார்கள்.
	<br /><br />
	17. தேசத்திலே மூப்பரானவர்களில் சிலர் எழும்பி, சபையாகிய ஜனங்களை நோக்கி:
	<br /><br />
	18. யூதாவின் ராஜாவாகிய எசேக்கியாவின் நாட்களில் மொரேசா ஊரானாகிய மீகா தீர்க்கதரிசனஞ்சொல்லி, யூதாவின் சகல ஜனங்களையும் பார்த்து: சீயோன் வயல்வெளியாக உழப்பட்டு, எருசலேம் மண்மேடுகளாய்ப்போம்; இந்த ஆலயத்தின் பர்வதம் காட்டிலுள்ள மேடுகளாய்ப்போம் என்று சேனைகளின் கர்த்தர் உரைத்தார் என்று சொன்னான்.
	<br /><br />
	19. அவனை யூதாவின் ராஜாவாகிய எசேக்கியாவும் மற்ற யூதர்களும் சேர்ந்து கொன்றுபோட்டார்களா? அவன் கர்த்தருக்குப் பயந்து, கர்த்தரின் முகத்தை நோக்கிக் கெஞ்சினானல்லவா? அப்பொழுது கர்த்தர் அவர்களுக்கு விரோதமாகச் சொல்லியிருந்த தீங்குக்கு மனஸ்தாபப்பட்டார்; இப்போதும், நாம் நம்முடைய ஆத்துமாவுக்கு விரோதமாக மகா பொல்லாப்பை வரப்பண்ணுகிறவர்களாயிருக்கிறோமே.
	<br /><br />
	20. கீரியாத்யாரீம் ஊரானாகிய செமாயாவின் குமாரன் உரியா என்னும் ஒரு மனுஷனும் கர்த்தருடைய நாமத்திலே தீர்க்கதரிசனம் சொல்லுகிறவனாயிருந்தான்; அவன் எரேமியாவின் வார்த்தைகளுக்குச் சரியாக இந்த நகரத்துக்கும் இந்தத் தேசத்துக்கும் விரோதமாகத் தீர்க்கதரிசனம் சொன்னான்.
	<br /><br />
	21. யோயாக்கீம் ராஜாவும் அவனுடைய சகல பராக்கிரமசாலிகளும் பிரபுக்களும் அவன் வார்த்தைகளைக் கேட்டபோது, ராஜா அவனைக் கொன்றுபோடும்படி எத்தனித்தான்; அதை உரியா கேட்டு, பயந்து, ஓடிப்போய், எகிப்திலே சேர்ந்தான்.
	<br /><br />
	22. அப்பொழுது யோயாக்கீம்ராஜா அக்போரின் குமாரனாகிய எல்நாத்தானையும் அவனோடேகூட வேறே சிலரையும் எகிப்துவரைக்கும் அனுப்பினான்.
	<br /><br />
	23. இவர்கள் உரியாவை எகிப்திலிருந்து கொண்டுவந்து, அவனை யோயாக்கீம் ராஜாவினிடத்தில் விட்டார்கள்; அவன் பட்டயத்தாலே அவனை வெட்டி, அவன் உடலை நீச ஜனங்களின் பிரேதக்குழிகளிடத்திலே எறிந்துவிட்டான் என்றார்கள்.
	<br /><br />
	24. ஆகிலும் எரேமியாவைக் கொல்ல ஜனங்களின் கையில் ஒப்புக்கொடாதபடி, சாப்பானுடைய குமாரனாகிய அகீக்காம் அவனுக்குச் சகாயமாயிருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah26