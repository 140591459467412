import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 11 </title>
	<meta
          name="description"
          content="Matthew 11 | மத்தேயு 11 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு தம்முடைய பன்னிரண்டு சீஷர்களுக்கும் கட்டளைகொடுத்து முடித்தபின்பு, அவர்களுடைய பட்டணங்களில் உபதேசிக்கவும் பிரசங்கிக்கவும் அவ்விடம் விட்டுப் போனார்.
	<br /><br />
	2. அத்தருணத்தில் காவலிலிருந்த யோவான் கிறிஸ்துவின் கிரியைகளைக்குறித்துக் கேள்விப்பட்டு, தன் சீஷரில் இரண்டு பேரை அழைத்து:
	<br /><br />
	3. வருகிறவர் நீர்தானா, அல்லது வேறொருவர் வரக்காத்திருக்கவேண்டுமா? என்று அவரிடத்தில் கேட்கும்படி அனுப்பினான்.
	<br /><br />
	4. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: நீங்கள் கேட்கிறதையும் காண்கிறதையும் யோவானிடத்தில் போய் அறிவியுங்கள்;
	<br /><br />
	5. குருடர் பார்வையடைகிறார்கள், சப்பாணிகள் நடக்கிறார்கள், குஷ்டரோகிகள் சுத்தமாகிறார்கள், செவிடர் கேட்கிறார்கள், மரித்தோர் எழுந்திருக்கிறார்கள், தரித்திரருக்குச் சுவிசேஷம் பிரசங்கிக்கப்படுகிறது.
	<br /><br />
	6. என்னிடத்தில் இடறலடையாதிருக்கிறவன் எவனோ அவன் பாக்கியவான் என்றார்.
	<br /><br />
	7. அவர்கள் போனபின்பு, இயேசு யோவானைக்குறித்து ஜனங்களுக்குச் சொன்னது என்னவென்றால்: எதைப் பார்க்க வனாந்தரத்திற்குப் போனீர்கள்? காற்றினால் அசையும் நாணலையோ?
	<br /><br />
	8. அல்லவென்றால், எதைப் பார்க்கப்போனீர்கள்? மெல்லிய வஸ்திரந்தரித்த மனுஷனையோ? மெல்லிய வஸ்திரந்தரித்திருக்கிறவர்கள் அரசர் மாளிகைகளில் இருக்கிறார்கள்.
	<br /><br />
	9. அல்லவென்றால், எதைப் பார்க்கப்போனீர்கள்? தீர்க்கதரிசியையோ? ஆம், தீர்க்கதரிசியைப்பார்க்கிலும் மேன்மையுள்ளவனையே என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	10. அதெப்படியெனில்: இதோ, நான் என் தூதனை உமக்கு முன்பாக அனுப்புகிறேன்; அவன் உமக்கு முன்னே போய், உமது வழியை ஆயத்தம்பண்ணுவான் என்று எழுதிய வாக்கியத்தால் குறிக்கப்பட்டவன் இவன்தான்.
	<br /><br />
	11. ஸ்திரீகளிடத்திலே பிறந்தவர்களில் யோவான்ஸ்நானனைப்பார்க்கிலும் பெரியவன் ஒருவனும் எழும்பினதில்லை; ஆகிலும், பரலோகராஜ்யத்தில் சிறியவனாயிருக்கிறவன் அவனிலும் பெரியவனாயிருக்கிறானென்று உங்களுக்கு மெய்யாகவே சொல்லுகிறேன்.
	<br /><br />
	12. யோவான்ஸ்நானன் காலமுதல் இதுவரைக்கும் பரலோகராஜ்யம் பலவந்தம் பண்ணப்படுகிறது; பலவந்தம்பண்ணுகிறவர்கள் அதைப் பிடித்துக்கொள்ளுகிறார்கள்.
	<br /><br />
	13. நியாயப்பிரமாணமும் தீர்க்கதரிசிகள் யாவரும் யோவான்வரைக்கும் தீர்க்கதரிசனம் உரைத்ததுண்டு.
	<br /><br />
	14. நீங்கள் ஏற்றுக்கொள்ள மனதாயிருந்தால், வருகிறவனாகிய எலியா இவன்தான்.
	<br /><br />
	15. கேட்கிறதற்குக் காதுள்ளவன் கேட்கக்கடவன்.
	<br /><br />
	16. இந்தச் சந்ததியை யாருக்கு ஒப்பிடுவேன்? சந்தைவெளிகளில் உட்கார்ந்து, தங்கள் தோழரைப் பார்த்து:
	<br /><br />
	17. உங்களுக்காகக் குழல் ஊதினோம், நீங்கள் கூத்தாடவில்லை; உங்களுக்காகப் புலம்பினோம், நீங்கள் மாரடிக்கவில்லை என்று குறைசொல்லுகிற பிள்ளைகளுக்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	18. எப்படியெனில், யோவான் போஜனபானம்பண்ணாதவனாய் வந்தான்; அதற்கு அவர்கள்: அவன் பிசாசு பிடித்திருக்கிறவன் என்றார்கள்.
	<br /><br />
	19. மனுஷகுமாரன் போஜனபானம்பண்ணுகிறவராய் வந்தார்; அதற்கு அவர்கள்: இதோ, போஜனப்பிரியனும் மதுபானப்பிரியனுமான மனுஷன், ஆயக்காரருக்கும் பாவிகளுக்கும் சிநேகிதன் என்கிறார்கள். ஆனாலும், ஞானமானது அதன் பிள்ளைகளால் நீதியுள்ளதென்று ஒப்புக்கொள்ளப்படும் என்றார்.
	<br /><br />
	20. அப்பொழுது, தமது பலத்த செய்கைகளில், அதிகமானவைகளைச் செய்யக்கண்ட பட்டணங்கள் மனந்திரும்பாமற் போனபடியினால் அவைகளை அவர் கடிந்துகொள்ளத் தொடங்கினார்:
	<br /><br />
	21. கோராசினே! உனக்கு ஐயோ, பெத்சாயிதாவே! உனக்கு ஐயோ, உங்களில் செய்யப்பட்ட பலத்த செய்கைகள் தீருவிலும் சீதோனிலும் செய்யப்பட்டிருந்ததானால், அப்பொழுதே இரட்டுடுத்திச் சாம்பலில் உட்கார்ந்து மனந்திரும்பியிருப்பார்கள்.
	<br /><br />
	22. நியாயத்தீர்ப்புநாளிலே உங்களுக்கு நேரிடுவதைப்பார்க்கிலும், தீருவுக்கும் சீதோனுக்கும் நேரிடுவது இலகுவாயிருக்கும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	23. வானபரியந்தம் உயர்த்தப்பட்ட கப்பர்நகூமே, நீ பாதாளபரியந்தம் தாழ்த்தப்படுவாய்; உன்னில் செய்யப்பட்ட பலத்த செய்கைகள் சோதோமிலே செய்யப்பட்டிருந்ததானால், அது இந்நாள்வரைக்கும் நிலைத்திருக்கும்.
	<br /><br />
	24. நியாயத்தீர்ப்புநாளிலே உனக்கு நேரிடுவதைப்பார்க்கிலும், சோதோம் நாட்டிற்கு நேரிடுவது இலகுவாயிருக்கும் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	25. அந்தச் சமயத்திலே இயேசு சொன்னது: பிதாவே! வானத்துக்கும் பூமிக்கும் ஆண்டவரே! இவைகளை ஞானிகளுக்கும் கல்விமான்களுக்கும் மறைத்து, பாலகருக்கு வெளிப்படுத்தினபடியால் உம்மை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	26. ஆம், பிதாவே! இப்படிச் செய்வது உம்முடைய திருவுளத்துக்குப் பிரியமாயிருந்தது.
	<br /><br />
	27. சகலமும் என் பிதாவினால் எனக்கு ஒப்புக்கொடுக்கப்பட்டிருக்கிறது; பிதா தவிர வேறொருவனும் குமாரனை அறியான்; குமாரனும், குமாரன் எவனுக்கு அவரை வெளிப்படுத்தச் சித்தமாயிருக்கிறாரோ அவனும் தவிர, வேறொருவனும் பிதாவை அறியான்.
	<br /><br />
	28. வருத்தப்பட்டுப் பாரஞ்சுமக்கிறவர்களே! நீங்கள் எல்லாரும் என்னிடத்தில் வாருங்கள்; நான் உங்களுக்கு இளைப்பாறுதல் தருவேன்.
	<br /><br />
	29. நான் சாந்தமும் மனத்தாழ்மையுமாய் இருக்கிறேன்; என் நுகத்தை உங்கள்மேல் ஏற்றுக்கொண்டு, என்னிடத்தில் கற்றுக்கொள்ளுங்கள்; அப்பொழுது, உங்கள் ஆத்துமாக்களுக்கு இளைப்பாறுதல் கிடைக்கும்.
	<br /><br />
	30. என் நுகம் மெதுவாயும், என் சுமை இலகுவாயும் இருக்கிறது என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew11