import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 3 </title>
	<meta
          name="description"
          content="Zechariah 3 | சகரியா 3 | Sageria 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் பிரதான ஆசாரியனாகிய யோசுவாவை எனக்குக் காண்பித்தார்; அவன் கர்த்தருடைய தூதனுக்கு முன்பாக நின்றான்; சாத்தான் அவனுக்கு விரோதஞ்செய்ய அவன் வலதுபக்கத்திலே நின்றான்.
	<br /><br />
	2. அப்பொழுது கர்த்தர் சாத்தானை நோக்கி: கர்த்தர் உன்னைக் கடிந்துகொள்வாராக; சாத்தானே, எருசலேமைத் தெரிந்துகொண்ட கர்த்தர் உன்னைக் கடிந்துகொள்வாராக; இவன் அக்கினியினின்று தப்புவிக்கப்பட்ட கொள்ளி அல்லவா என்றார்.
	<br /><br />
	3. யோசுவாவோவெனில் அழுக்கு வஸ்திரம் தரித்தவனாய்த் தூதனுக்கு முன்பாக நின்றிருந்தான்.
	<br /><br />
	4. அவர் தமக்கு முன்பாக நிற்கிறவர்களை நோக்கி: இவன்மேல் இருக்கிற அழுக்கு வஸ்திரங்களைக் களைந்துபோடுங்கள் என்றார்; பின்பு அவனை நோக்கி: பார், நான் உன் அக்கிரமத்தை உன்னிலிருந்து நீங்கச்செய்து, உனக்குச் சிறந்த வஸ்திரங்களைத் தரிப்பித்தேன் என்றார்.
	<br /><br />
	5. அவன் சிரசின்மேல் சுத்தமான பாகையை வைப்பார்களாக என்றார்; அப்பொழுது சுத்தமான பாகையை அவன் சிரசின்மேல் வைத்து, அவனுக்கு வஸ்திரங்களைத் தரிப்பித்தார்கள்; கர்த்தருடைய தூதன் அங்கே நின்றார்.
	<br /><br />
	6. கர்த்தருடைய தூதன் யோசுவாவுக்குச் சாட்சியாக:
	<br /><br />
	7. சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால்: நீ என் வழிகளில் நடந்து என் காவலைக் காத்தால், நீ என் ஆலயத்தில் நியாயம் விசாரிப்பாய்; என் பிராகாரங்களையும் காவல்காப்பாய்; இங்கே நிற்கிறவர்களுக்குள்ளே உலாவுகிறதற்கு இடம் நான் உனக்குக் கட்டளையிடுவேன்.
	<br /><br />
	8. இப்போதும், பிரதான ஆசாரியனாகிய யோசுவாவே, நீ கேள்; உனக்கு முன்பாக உட்கார்ந்திருக்கிற உன் தோழரும் கேட்கக்கடவர்கள்; இவர்கள் அடையாளமாயிருக்கிற புருஷர்; இதோ, கிளை என்னப்பட்டவராகிய என் தாசனை நான் வரப்பண்ணுவேன்.
	<br /><br />
	9. இதோ, நான் யோசுவாவுக்கு முன்பாக வைத்த கல்; இந்த ஒரே கல்லின்மேல் ஏழு கண்களும் வைக்கப்பட்டிருக்கிறது; இதோ, நான் அதின் சித்திரவேலையை நிறைவேற்றி, இந்தத் தேசத்தில் அக்கிரமத்தை ஒரேநாளிலே நீக்கிப்போடுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. அந்நாளிலே நீங்கள் ஒருவரையொருவர் திராட்சச்செடியின்கீழும் அத்திமரத்தின்கீழும் வரவழைப்பீர்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah3