import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah47 = () => {
  const verseNumber = 47;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 47 </title>
	<meta
          name="description"
          content="Isaiah 47 | ஏசாயா 47 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பாபிலோன் குமாரத்தியாகிய கன்னிகையே, நீ இறங்கி மண்ணிலே உட்காரு; கல்தேயரின் குமாரத்தியே, தரையிலே உட்காரு; உனக்குச் சிங்காசனமில்லை; நீ செருக்குக்காரியும் சுகசெல்வியும் என்று இனி அழைக்கப்படுவதில்லை.
	<br /><br />
	2. ஏந்திரத்தை எடுத்து மாவரை; உன் முக்காட்டை நீக்கிவிடு; வெறுங்காலும் அம்மணத்தொடையுமாய் ஆறுகளைக் கடந்துபோ.
	<br /><br />
	3. உன் நிர்வாணம் வெளிப்படும்; உன் இலச்சை காணப்படும்; நான் ஒருவனையும் பாராமல் நீதியைச் சரிக்கட்டுவேன்.
	<br /><br />
	4. எங்கள் மீட்பருடைய நாமம் இஸ்ரவேலின் பரிசுத்தராகிய சேனைகளுடைய கர்த்தர் என்பது.
	<br /><br />
	5. கல்தேயரின் குமாரத்தியே, நீ அந்தகாரத்துக்குள் பிரவேசித்து மவுனமாய் உட்காரு; இனி நீ ராஜ்யங்களின் நாயகியென்று அழைக்கப்படுவதில்லை.
	<br /><br />
	6. நான் என் ஜனத்தின்மேல் கடுங்கோபமடைந்து, என் சுதந்தரத்தைப் பரிசுத்தக் குலைச்சலாக்கி, அவர்களை உன் கையில் ஒப்புக்கொடுத்தேன்; நீ அவர்கள்மேல் இரக்கம்வையாமல், முதிர்வயதுள்ளவர்களின்மேல் உன் நுகத்தை மகா பாரமாக்கி,
	<br /><br />
	7. என்றென்றைக்கும் நாயகியாயிருப்பேனென்று சொல்லி, இந்தக்காரியங்களை இதுவரைக்கும் உன் மனதிலே வையாமலும், அதின் முடிவை நினையாமலும் போனாய்.
	<br /><br />
	8. இப்பொழுதும் சுகசெல்வியே, விசாரமில்லாமல் வாழ்கிறவளே: நான்தான், என்னைத்தவிர ஒருவருமில்லை; நான் விதவையாவதில்லை, நான் சந்தான சேதத்தை அறிவதில்லையென்று உன் இருதயத்திலே சொல்லுகிறவளே, நான் சொல்லுகிறதைக் கேள்.
	<br /><br />
	9. சந்தான சேதமும் விதவையிருப்பும் ஆகிய இவ்விரண்டும் உனக்குச் சடிதியாக ஒரேநாளில் வரும்; உன் திரளான சூனியங்களினிமித்தமும், உன் வெகுவான ஸ்தம்பன வித்தைகளினிமித்தமும் அவைகள் பூரணமாய் உன்மேல் வரும்.
	<br /><br />
	10. உன் துன்மார்க்கத்திலே நீ திடநம்பிக்கையாயிருந்து: என்னைப் பார்க்கிறவர் ஒருவரும் இல்லையென்றாய். உன் ஞானமும் உன் அறிவுமே உன்னைக் கெடுத்தது; நான்தான், என்னைத்தவிர ஒருவருமில்லையென்று உன் இருதயத்தில் எண்ணினாய்.
	<br /><br />
	11. ஆகையால் தீங்கு உன்மேல் வரும், அது எங்கேயிருந்து உதித்ததென்று நீ அறியாய்; விக்கினம் உன்மேல் வரும், நீ அதை நிவிர்த்தியாக்கமாட்டாய்; நீ அறியாதபடிக்குச் சடிதியாய் உண்டாகும் பாழ்க்கடிப்பு உன்மேல் வரும்.
	<br /><br />
	12. நீ உன் சிறுவயதுமுதல் பிரயாசப்பட்டுப் பழகிவருகிற உன் ஸ்தம்பன வித்தைகளையும், உன் திரளான சூனியங்களையும் நீ அநுசரித்து நில்; அவைகளால் உனக்குப் பிரயோஜனம் உண்டோ, பலன் உண்டோ, பார்ப்போம்.
	<br /><br />
	13. உன் திரளான யோசனைகளினால் நீ இளைத்துப்போனாய்; இப்பொழுதும் ஜோசியரும், நட்சத்திரம் பார்க்கிறவர்களும், அமாவாசி கணிக்கிறவர்களும் எழும்பி, உனக்கு நேரிடுகிறவைகளுக்கு உன்னை விலக்கி இரட்சிக்கட்டும்.
	<br /><br />
	14. இதோ, அவர்கள் தாளடியைப்போல இருப்பார்கள், நெருப்பு அவர்களைச் சுட்டெரிக்கும்; அவர்கள் தங்கள் பிராணனை அக்கினிஜுவாலையினின்று விடுவிப்பதில்லை; அது குளிர்காயத்தக்க தழலுமல்ல; எதிரே உட்காரத்தக்க அடுப்புமல்ல.
	<br /><br />
	15. உன் சிறுவயதுமுதல் நீ பிரயாசப்பட்டு எவர்களுடன் வியாபாரம் பண்ணினாயோ, அவர்களும் அப்படியே இருப்பார்கள்; அவரவர் தங்கள் போக்கிலே போய் அலைவார்கள்; உன்னை இரட்சிப்பார் இல்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah47