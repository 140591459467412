import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 13 </title>
	<meta
          name="description"
          content="John Chapter 13 | யோவான் அதிகாரம் 13 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பஸ்கா பண்டிகைக்கு முன்னே, இயேசு இவ்வுலகத்தைவிட்டுப் பிதாவினிடத்திற்குப் போகும்படியான தம்முடைய வேளை வந்ததென்று அறிந்து, தாம் இவ்வுலகத்திலிருக்கிற தம்முடையவர்களிடத்தில் அன்புவைத்தபடியே, முடிவுபரியந்தமும் அவர்களிடத்தில் அன்புவைத்தார்.
	<br /><br />
	2. சீமோனின் குமாரனாகிய யூதாஸ்காரியோத்து அவரைக் காட்டிக்கொடுக்கும்படி பிசாசானவன் அவன் இருதயத்தைத் தூண்டினபின்பு, அவர்கள் போஜனம் பண்ணிக்கொண்டிருக்கையில்;
	<br /><br />
	3. தம்முடைய கையில் பிதா எல்லாவற்றையும் ஒப்புக்கொடுத்தாரென்பதையும், தாம் தேவனிடத்திலிருந்து வந்ததையும், தேவனிடத்திற்குப் போகிறதையும் இயேசு அறிந்து;
	<br /><br />
	4. போஜனத்தை விட்டெழுந்து, வஸ்திரங்களைக் கழற்றிவைத்து, ஒரு சீலையை எடுத்து, அரையிலே கட்டிக்கொண்டு,
	<br /><br />
	5. பின்பு பாத்திரத்தில் தண்ணீர் வார்த்து, சீஷருடைய கால்களைக் கழுவவும், தாம் கட்டிக்கொண்டிருந்த சீலையினால் துடைக்கவும் தொடங்கினார்.
	<br /><br />
	6. அவர் சீமோன் பேதுருவினிடத்தில் வந்தபோது, அவன் அவரை நோக்கி: ஆண்டவரே, நீர் என் கால்களைக் கழுவலாமா என்றான்.
	<br /><br />
	7. இயேசு அவனுக்குப் பிரதியுத்தரமாக: நான் செய்கிறது இன்னதென்று இப்பொழுது நீ அறியாய், இனிமேல் அறிவாய் என்றார்.
	<br /><br />
	8. பேதுரு அவரை நோக்கி: நீர் ஒருக்காலும் என் கால்களைக் கழுவப்படாது என்றான். இயேசு அவனுக்குப் பிரதியுத்தரமாக: நான் உன்னைக் கழுவாவிட்டால் என்னிடத்தில் உனக்குப் பங்கில்லை என்றார்.
	<br /><br />
	9. அதற்குச் சீமோன் பேதுரு: ஆண்டவரே, என் கால்களைமாத்திரமல்ல, என் கைகளையும் என் தலையையும்கூடக் கழுவவேண்டும் என்றான்.
	<br /><br />
	10. இயேசு அவனை நோக்கி: முழுகினவன் தன் கால்களைமாத்திரம் கழுவவேண்டியதாயிருக்கும், மற்றப்படி அவன் முழுவதும் சுத்தமாயிருக்கிறான்; நீங்களும் சுத்தமாயிருக்கிறீர்கள்; ஆகிலும் எல்லாரும் அல்ல என்றார்.
	<br /><br />
	11. தம்மைக் காட்டிக்கொடுக்கிறவனை அவர் அறிந்திருந்தபடியினால் நீங்களெல்லாரும் சுத்தமுள்ளவர்கள் அல்ல என்றார்.
	<br /><br />
	12. அவர்களுடைய கால்களை அவர் கழுவினபின்பு, தம்முடைய வஸ்திரங்களைத் தரித்துக்கொண்டு, திரும்ப உட்கார்ந்து, அவர்களை நோக்கி: நான் உங்களுக்குச் செய்ததை அறிந்திருக்கிறீர்களா?
	<br /><br />
	13. நீங்கள் என்னைப் போதகரென்றும், ஆண்டவரென்றும் சொல்லுகிறீர்கள், நீங்கள் சொல்லுகிறது சரியே, நான் அவர்தான்.
	<br /><br />
	14. ஆண்டவரும் போதகருமாகிய நானே உங்கள் கால்களைக் கழுவினதுண்டானால், நீங்களும் ஒருவருடைய கால்களை ஒருவர் கழுவக்கடவீர்கள்.
	<br /><br />
	15. நான் உங்களுக்குச் செய்ததுபோல நீங்களும் செய்யும்படி உங்களுக்கு மாதிரியைக் காண்பித்தேன்.
	<br /><br />
	16. மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன், ஊழியக்காரன் தன் எஜமானிலும் பெரியவனல்ல, அனுப்பப்பட்டவன் தன்னை அனுப்பினவரிலும் பெரியவனல்ல.
	<br /><br />
	17. நீங்கள் இவைகளை அறிந்திருக்கிறபடியினால், இவைகளைச் செய்வீர்களானால், பாக்கியவான்களாயிருப்பீர்கள்.
	<br /><br />
	18. உங்களெல்லாரையுங்குறித்து நான் பேசவில்லை, நான் தெரிந்துகொண்டவர்களை அறிவேன்; ஆகிலும் வேதவாக்கியம் நிறைவேறத்தக்கதாக, என்னுடனே அப்பம் புசிக்கிறவன் என்மேல் தன் குதிகாலைத் தூக்கினான்.
	<br /><br />
	19. அது நடக்கும்போது நானே அவரென்று நீங்கள் விசுவாசிக்கும்பொருட்டு, இப்பொழுது அது நடப்பதற்கு முன்னமே அதை உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	20. நான் அனுப்புகிறவனை ஏற்றுக்கொள்ளுகிறவன் என்னை ஏற்றுக்கொள்ளுகிறான், என்னை ஏற்றுக்கொள்ளுகிறவன் என்னை அனுப்பினவரை ஏற்றுக்கொள்ளுகிறான் என்று, மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	21. இயேசு இவைகளைச் சொன்னபின்பு, ஆவியிலே கலங்கி: உங்களில் ஒருவன் என்னைக் காட்டிக்கொடுப்பான் என்று, மெய்யாகவே மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்று சாட்சியாகச் சொன்னார்.
	<br /><br />
	22. அப்பொழுது யாரைக்குறித்துப் பேசுகிறாரோ என்று சீஷர்கள் ஐயப்பட்டு, ஒருவரையொருவர் நோக்கிப்பார்த்தார்கள்.
	<br /><br />
	23. அந்தச் சமயத்தில் அவருடைய சீஷரில் இயேசுவுக்கு அன்பானவனாயிருந்த ஒருவன் இயேசுவின் மார்பிலே சாய்ந்துகொண்டிருந்தான்.
	<br /><br />
	24. யாரைக்குறித்துச் சொல்லுகிறாரென்று விசாரிக்கும்படி சீமோன்பேதுரு அவனுக்குச் சைகைகாட்டினான்.
	<br /><br />
	25. அப்பொழுது அவன் இயேசுவின் மார்பிலே சாய்ந்துகொண்டு: ஆண்டவரே, அவன் யார் என்றான்.
	<br /><br />
	26. இயேசு பிரதியுத்தரமாக: நான் இந்தத் துணிக்கையைத் தோய்த்து எவனுக்குக் கொடுப்பேனோ, அவன்தான் என்று சொல்லி, துணிக்கையைத் தோய்த்து, சீமோன் குமாரனாகிய யூதாஸ்காரியோத்துக்குக் கொடுத்தார்.
	<br /><br />
	27. அந்தத் துணிக்கையை அவன் வாங்கினபின்பு, சாத்தான் அவனுக்குள் புகுந்தான். அப்பொழுது இயேசு அவனை நோக்கி: நீ செய்கிறதைச் சீக்கிரமாய்ச் செய் என்றார்.
	<br /><br />
	28. அவர் இப்படி அவனுடனே சொன்னதின் கருத்தைப் பந்தியிருந்தவர்களில் ஒருவனும் அறியவில்லை.
	<br /><br />
	29. யூதாஸ் பணப்பையை வைத்துக் கொண்டிருந்தபடியினால், அவன் போய், பண்டிகைக்குத் தேவையானவைகளைக் கொள்ளும்படிக்காவது, தரித்திரருக்கு ஏதாகிலும் கொடுக்கும்படிக்காவது, இயேசு அவனுடனே சொல்லியிருப்பார் என்று சிலர் நினைத்தார்கள்.
	<br /><br />
	30. அவன் அந்தத் துணிக்கையை வாங்கினவுடனே புறப்பட்டுப்போனான்; அப்பொழுது இராக்காலமாயிருந்தது.
	<br /><br />
	31. அவன் புறப்பட்டுப்போனபின்பு இயேசு: இப்பொழுது மனுஷகுமாரன் மகிமைப்படுகிறார், தேவனும் அவரில் மகிமைப்படுகிறார்.
	<br /><br />
	32. தேவன் அவரில் மகிமைப்பட்டிருந்தால், தேவன் அவரைத் தம்மில் மகிமைப்படுத்துவார், சீக்கிரமாய் அவரை மகிமைப்படுத்துவார்.
	<br /><br />
	33. பிள்ளைகளே, இன்னும் கொஞ்சக்காலம் நான் உங்களுடனேகூட இருப்பேன்; நீங்கள் என்னைத் தேடுவீர்கள்; ஆனாலும் நான் போகிற இடத்துக்கு நீங்கள் வரக்கூடாதென்று நான் யூதரோடே சொன்னதுபோல இப்பொழுது உங்களோடும் சொல்லுகிறேன்.
	<br /><br />
	34. நீங்கள் ஒருவரிலொருவர் அன்பாயிருங்கள்; நான் உங்களில் அன்பாயிருந்ததுபோல நீங்களும் ஒருவரிலொருவர் அன்பாயிருங்கள் என்கிற புதிதான கட்டளையை உங்களுக்குக் கொடுக்கிறேன்.
	<br /><br />
	35. நீங்கள் ஒருவரிலொருவர் அன்புள்ளவர்களாயிருந்தால், அதினால் நீங்கள் என்னுடைய சீஷர்களென்று எல்லாரும் அறிந்துகொள்வார்கள் என்றார்.
	<br /><br />
	36. சீமோன்பேதுரு அவரை நோக்கி: ஆண்டவரே, நீர் எங்கே போகிறீர் என்றான். இயேசு அவனுக்குப் பிரதியுத்தரமாக: நான் போகிற இடத்துக்கு இப்பொழுது நீ என் பின்னே வரக்கூடாது, பிற்பாடு என் பின்னே வருவாய் என்றார்.
	<br /><br />
	37. பேதுரு அவரை நோக்கி: ஆண்டவரே, நான் இப்பொழுது உமக்குப் பின்னே ஏன் வரக்கூடாது? உமக்காக என் ஜீவனையும் கொடுப்பேன் என்றான்.
	<br /><br />
	38. இயேசு அவனுக்குப் பிரதியுத்தரமாக: எனக்காக உன் ஜீவனைக் கொடுப்பாயோ? சேவல் கூவுகிறதற்கு முன்னே நீ என்னை மூன்றுதரம் மறுதலிப்பாயென்று, மெய்யாகவே மெய்யாகவே உனக்குச் சொல்லுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John13