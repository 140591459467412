import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah46 = () => {
  const verseNumber = 46;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 46 </title>
	<meta
          name="description"
          content="Isaiah 46 | ஏசாயா 46 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பேல் பணியும், நேபோ குனியும், அவைகளின் விக்கிரகங்கள் காட்டுமிருகங்களுக்கும் நாட்டுமிருகங்களுக்கும் சுமையாகும்; நீங்கள் சுமந்த சுமைகள் இளைத்துப்போன மிருகங்களுக்குப் பாரமாயிருக்கும்.
	<br /><br />
	2. அவைகள் ஏகமாய்க் குனிந்துபணியும்; சுமைகளை அவைகள் தப்புவிக்கமாட்டாது; அவைகள் தாமே சிறைப்பட்டுப்போகும்.
	<br /><br />
	3. யாக்கோபின் சந்ததியாரே இஸ்ரவேல் சந்ததியில் மீதியாகிய சகல ஜனங்களே, எனக்குச் செவிகொடுங்கள்; தாயின் வயிற்றில் தோன்றினதுமுதல் உங்களை ஏந்தி, தாயின் கர்ப்பத்தில் உற்பத்தியானதுமுதல் உங்களைத் தாங்கினேன்.
	<br /><br />
	4. உங்கள் முதிர்வயதுவரைக்கும் நான் அப்படிச் செய்வேன்; நரைவயதுமட்டும் நான் உங்களைத் தாங்குவேன்; நான் அப்படிச் செய்துவந்தேன்; இனிமேலும் நான் ஏந்துவேன், நான் சுமப்பேன், தப்புவிப்பேன்.
	<br /><br />
	5. யாருக்கு என்னைச் சாயலும் சமமுமாக்கி, யாருக்கு நான் ஒப்பாகும்படிக்கு என்னை ஒப்பிடுவீர்கள்?
	<br /><br />
	6. பையிலிருக்கிற பொன்னைக்கொட்டி, வெள்ளியைத் தராசில் நிறுத்து, தட்டானுடனே கூலி பொருத்திக்கொள்ளுகிறார்கள்; அவன் ஒரு தெய்வத்தை உண்டாக்குகிறான்; அதை வணங்கிப் பணிந்துகொள்ளுகிறார்கள்.
	<br /><br />
	7. அதைத் தோளின்மேல் எடுத்து, அதைச் சுமந்து, அதை அதின் ஸ்தானத்திலே வைக்கிறார்கள்; அங்கே அது நிற்கும்; தன் இடத்தைவிட்டு அசையாது; ஒருவன் அதை நோக்கிக் கூப்பிட்டால், அது மறுஉத்தரவு கொடுக்கிறதுமில்லை, அவன் இக்கட்டை நீக்கி அவனை இரட்சிக்கிறதுமில்லை.
	<br /><br />
	8. இதை நினைத்துப் புருஷராயிருங்கள்; பாதகரே, இதை மனதில் வையுங்கள்.
	<br /><br />
	9. முந்திப் பூர்வகாலத்தில் நடந்தவைகளை நினையுங்கள்; நானே தேவன், வேறொருவரும் இல்லை; நானே தேவன், எனக்குச் சமானமில்லை.
	<br /><br />
	10. அந்தத்திலுள்ளவைகளை ஆதிமுதற்கொண்டும், இன்னும் செய்யப்படாதவைகளைப் பூர்வகாலமுதற்கொண்டும் அறிவிக்கிறேன்; என் ஆலோசனை நிலைநிற்கும், எனக்குச் சித்தமானவைகளையெல்லாம் செய்வேன் என்று சொல்லி,
	<br /><br />
	11. உராய்ஞ்சுகிற ஒரு பட்சியைக் கிழக்கிலிருந்தும், என் ஆலோசனையை நிறைவேற்றும் மனுஷனைத் தூரதேசத்திலிருந்தும் வரவழைக்கிறவராயிருக்கிறேன்; அதைச் சொன்னேன், அதை நிறைவேற்றுவேன்; அதைத் திட்டம்பண்ணினேன், அதைச் செய்து முடிப்பேன்.
	<br /><br />
	12. முரட்டு இருதயமுள்ளவர்களே, நீதிக்குத் தூரமானவர்களே, எனக்குச் செவிகொடுங்கள்.
	<br /><br />
	13. என் நீதியைச் சமீபிக்கப்பண்ணுகிறேன், அது தூரமாயிருப்பதில்லை; என் இரட்சிப்புத் தாமதிப்பதுமில்லை; நான் சீயோனில் இரட்சிப்பையும், இஸ்ரவேலுக்கு என் மகிமையையும் கட்டளையிடுவேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah46