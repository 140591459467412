import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 1 </title>
	<meta
          name="description"
          content="Micah 1 | மீகா 1 | Meega 1|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோதாம், ஆகாஸ், எசேக்கியா என்னும் யூதா ராஜாக்களுடைய நாட்களில், மொரேசா ஊரானாகிய மீகாவுக்கு உண்டானதும், அவன் சமாரியாவுக்கும் எருசலேமுக்கும் விரோதமாய்த் தரிசித்ததுமான கர்த்தருடைய வார்த்தை.
	<br /><br />
	2. சகல ஜனங்களே, கேளுங்கள்; பூமியே, அதிலுள்ளவைகளே, செவிகொடுங்கள்; கர்த்தராகிய ஆண்டவர், தம்முடைய பரிசுத்த ஆலயத்திலிருக்கிற ஆண்டவரே, உங்களுக்கு விரோதமாகச் சாட்சியாயிருப்பார்.
	<br /><br />
	3. இதோ, கர்த்தர் தமது ஸ்தானத்திலிருந்து புறப்பட்டு வருகிறார்; அவர் இறங்கிப் பூமியின் உயர்ந்த இடங்களை மிதிப்பார்.
	<br /><br />
	4. மெழுகு அக்கினிக்கு முன்பாக உருகுகிறதுபோலவும், மலைகளிலிருந்து பாயுந் தண்ணீர் தரையைப் பிளக்கிறதுபோலவும், பர்வதங்கள் அவர் கீழே உருகி, பள்ளத்தாக்குகள் பிளந்துபோகும்.
	<br /><br />
	5. இது எல்லாம் யாக்கோபுடைய மீறுதலினிமித்தமும், இஸ்ரவேல் வம்சத்தாருடைய பாவங்களினிமித்தமும் சம்பவிக்கும்; யாக்கோபின் மீறுதலுக்குக் காரணமென்ன? சமாரியா அல்லவோ? யூதாவின் மேடைகளுக்குக் காரணமென்ன? எருசலேம் அல்லவோ?
	<br /><br />
	6. ஆகையால் நான் சமாரியாவை வெளியான மண்மேடும், திராட்சச்செடி நடுகிற நிலமுமாக்கி, அதின் கற்களைப் பள்ளத்தாக்கிலே புரண்டு விழப்பண்ணி, அதின் அஸ்திபாரங்களைத் திறந்துவைப்பேன்.
	<br /><br />
	7. அதின் சுரூபங்கள் எல்லாம் நொறுக்கப்படும்; அதின் பணையங்கள் எல்லாம் அக்கினியால் எரித்துப்போடப்படும்; அதின் விக்கிரகங்களை எல்லாம் பாழாக்குவேன்; வேசிப்பணையத்தினால் சேர்க்கப்பட்டது, திரும்ப வேசிப்பணையமாய்ப் போகும்.
	<br /><br />
	8. இதினிமித்தம் நான் புலம்பி அலறுவேன்; பறிகொடுத்தவனாகவும் அம்மணமாகவும் நடப்பேன்; நான் ஓரிகளைப்போல ஊளையிட்டு, ஆந்தைகளைப்போல அலறுவேன்.
	<br /><br />
	9. அதின் காயம் ஆறாதது; அது யூதாமட்டும் வந்தது; என் ஜனத்தின் வாசலாகிய எருசலேம்மட்டும் வந்தெட்டினது.
	<br /><br />
	10. அதைக் காத்பட்டணத்திலே அறிவியாதேயுங்கள், அழவே வேண்டாம்; பெத்அப்ராவிலே புழுதியில் புரளு.
	<br /><br />
	11. சாப்பீரில் குடியிருக்கிறவளே, வெட்கத்துடன் அம்மணமாய் அப்பாலே போ; சாயனானில் குடியிருக்கிறவள் வெளியே வருவதில்லை; பெத்ஏசேலின் புலம்பல் உங்களுக்கு அடைக்கலமாயிராது.
	<br /><br />
	12. மாரோத்தில் குடியிருக்கிறவள் நன்மை வருமென்று எதிர்பார்த்திருந்தாள்; ஆனாலும் தீமை கர்த்தரிடத்திலிருந்து எருசலேமின் வாசல்வரைக்கும் வந்தது.
	<br /><br />
	13. லாகீசில் குடியிருக்கிறவளே, வேகமான குதிரைகளை இரதத்திலே பூட்டு; நீயே சீயோன் குமாரத்தியின் பாவத்துக்குக் காரணி; உன்னிடத்தில் இஸ்ரவேலின் பாதகங்கள் காணப்பட்டது.
	<br /><br />
	14. ஆகையால் மோர்ஷேத்காத்தினிடத்தில் உனக்கு இருக்கிறதைக் கொடுத்துவிடுவாய்; அக்சீபின் வீடுகள் இஸ்ரவேலின் ராஜாக்களுக்கு அபத்தமாய்ப்போகும்.
	<br /><br />
	15. மரேஷாவில் குடியிருக்கிறவளே, உனக்கு இன்னும் ஒரு சுதந்தரவாளியை வரப்பண்ணுவேன்; அவன் இஸ்ரவேலின் மகிமையாகிய அதுல்லாம்மட்டும் வருவான்.
	<br /><br />
	16. உனக்கு அருமையான உன் பிள்ளைகளினிமித்தம் நீ உன் தலையைச் சிரைத்து மொட்டையிட்டுக்கொள்; கழுகைப்போல முழுமொட்டையாயிரு, அவர்கள் உன்னைவிட்டுச் சிறைப்பட்டுப்போகிறார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah1