import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 1 </title>
	<meta
          name="description"
          content="Daniel 1 | தானியேல் தீர்க்கதரிசி | தானியேல் 1 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதாவின் ராஜாவாகிய யோயாக்கீம் அரசாண்ட மூன்றாம் வருஷத்திலே பாபிலோனின் ராஜாவாகிய நேபுகாத்நேச்சார் எருசலேமுக்கு வந்து, அதை முற்றிக்கைபோட்டான்.
	<br /><br />
	2. அப்பொழுது ஆண்டவர் யூதாவின் ராஜாவாகிய யோயாக்கீமையும் தேவனுடைய ஆலயத்தின் பாத்திரங்களில் சிலவற்றையும் அவன் கையில் ஒப்புக்கொடுத்தார்; அவன் அந்தப் பாத்திரங்களைச் சினேயார் தேசத்திலுள்ள தன் தேவனுடைய கோவிலுக்குக் கொண்டுபோய், அவைகளைத் தன் தேவனுடைய பண்டசாலைக்குள் வைத்தான்.
	<br /><br />
	3. அப்பொழுது இஸ்ரவேல் புத்திரருக்குள்ளே ராஜகுலத்தார்களிலும் துரைமக்களிலும் யாதொரு மாசும் இல்லாதவர்களும், அழகானவர்களும், சகல ஞானத்திலும் தேறினவர்களும், அறிவில் சிறந்தவர்களும், கல்வியில் நிபுணரும், ராஜாவின் அரமனையிலே சேவிக்கத் திறமையுள்ளவர்களுமாகிய சில வாலிபரைக் கொண்டுவரவும்,
	<br /><br />
	4. அவர்களுக்குக் கல்தேயரின் எழுத்தையும் பாஷையையும் கற்றுக்கொடுக்கவும் ராஜா தன் பிரதானிகளின் தலைவனாகிய அஸ்பேனாசுக்குக் கற்பித்தான்.
	<br /><br />
	5. ராஜா, தான் உண்ணும் போஜனத்திலேயும் தான் குடிக்கும் திராட்சரசத்திலேயும் தினம் ஒரு பங்கை அவர்களுக்கு நியமித்து, அவர்களை மூன்றுவருஷம் வளர்க்கவும், அதின் முடிவிலே அவர்கள் ராஜாவுக்கு முன்பாக நிற்கும்படி செய்யவும் கட்டளையிட்டான்.
	<br /><br />
	6. அவர்களுக்குள் யூதா புத்திரராகிய தானியேல், அனனியா, மீஷாவேல், அசரியா என்பவர்கள் இருந்தார்கள்.
	<br /><br />
	7. பிரதானிகளின் தலைவன், தானியேலுக்கு பெல்தெஷாத்சார் என்றும், அனனியாவுக்கு சாத்ராக் என்றும், மீஷாவேலுக்கு மேஷாக் என்றும், அசரியாவுக்கு ஆபேத்நேகோ என்றும் மறுபெயரிட்டான்.
	<br /><br />
	8. தானியேல் ராஜாவின் போஜனத்தினாலும் அவர் பானம்பண்ணும் திராட்சரசத்தினாலும் தன்னைத் தீட்டுப்படுத்தலாகாதென்று, தன் இருதயத்தில் தீர்மானம்பண்ணிக்கொண்டு, தன்னைத் தீட்டுப்படுத்தாதபடி பிரதானிகளின் தலைவனிடத்தில் வேண்டிக்கொண்டான்.
	<br /><br />
	9. தேவன் தானியேலுக்குப் பிரதானிகளின் தலைவனிடத்தில் தயவும் இரக்கமும் கிடைக்கும்படி செய்தார்.
	<br /><br />
	10. பிரதானிகளின் தலைவன் தானியேலை நோக்கி: உங்களுக்குப் போஜனத்தையும் பானத்தையும் குறித்திருக்கிற ராஜாவாகிய என் ஆண்டவனுக்கு நான் பயப்படுகிறேன்; அவர் உங்களோடொத்த வாலிபரின் முகங்களைப்பார்க்கிலும் உங்கள் முகங்கள் வாடிப்போனவைகளாகக் காணவேண்டியதென்ன? அதினால் ராஜா என்னைச் சிரச்சேதம்பண்ணுவாரே என்றான்.
	<br /><br />
	11. அப்பொழுது பிரதானிகளின் தலைவனாலே, தானியேல், அனனியா, மீஷாவேல், அசரியா என்பவர்கள்மேல் விசாரிப்புக்காரனாக வைக்கப்பட்ட மேல்ஷார் என்பவனைத் தானியேல் நோக்கி:
	<br /><br />
	12. பத்துநாள்வரைக்கும் உமது அடியாரைச் சோதித்துப்பாரும்; எங்களுக்குப் புசிக்கப் பருப்பு முதலான மரக்கறிகளையும், குடிக்கத் தண்ணீரையும் கொடுத்து,
	<br /><br />
	13. எங்கள் முகங்களையும் ராஜபோஜனத்தில் புசிக்கிற வாலிபருடைய முகங்களையும் ஒத்துப்பாரும்; பின்பு நீர் காண்கிறபடி உமது அடியாருக்குச் செய்யும் என்றான்.
	<br /><br />
	14. அவன் இந்தக் காரியத்திலே அவர்களுக்குச் செவிகொடுத்து, பத்துநாளளவும் அவர்களைச் சோதித்துப்பார்த்தான்.
	<br /><br />
	15. பத்துநாள் சென்றபின்பு, ராஜபோஜனத்தைப் புசித்த எல்லா வாலிபரைப்பார்க்கிலும் அவர்கள் முகம் களையுள்ளதாயும், சரீரம் புஷ்டியுள்ளதாயும் காணப்பட்டது.
	<br /><br />
	16. ஆகையால் மேல்ஷார் அவர்கள் புசிக்கக் கட்டளையான போஜனத்தையும், அவர்கள் குடிக்கக் கட்டளையான திராட்சரசத்தையும் நீக்கிவைத்து, அவர்களுக்குப் பருப்பு முதலானவைகளைக் கொடுத்தான்.
	<br /><br />
	17. இந்த நாலு வாலிபருக்கும் தேவன் சகல எழுத்திலும் ஞானத்திலும் அறிவையும் சாமர்த்தியத்தையும் கொடுத்தார்; தானியேலைச் சகல தரிசனங்களையும் சொப்பனங்களையும் அறியத்தக்க அறிவுள்ளவனாக்கினார்.
	<br /><br />
	18. அவர்களை ராஜாவினிடத்தில் கொண்டுவருகிறதற்குக் குறித்த நாட்கள் நிறைவேறினபோது, பிரதானிகளின் தலைவன் அவர்களை நேபுகாத்நேச்சாருக்கு முன்பாகக் கொண்டுவந்து விட்டான்.
	<br /><br />
	19. ராஜா அவர்களோடே பேசினான்; அவர்கள் எல்லாருக்குள்ளும் தானியேல், அனனியா, மீஷாவேல், அசரியா என்பவர்களைப்போல வேறொருவரும் காணப்படவில்லை; ஆகையால் இவர்கள் ராஜசமுகத்தில் நின்றார்கள்.
	<br /><br />
	20. ஞானத்துக்கும் புத்திக்கும் அடுத்த எந்த விஷயத்தில் ராஜா அவர்களைக் கேட்டு விசாரித்தானோ, அதிலே தன் ராஜ்யம் எங்குமுள்ள சகல சாஸ்திரிகளிலும் ஜோசியரிலும் அவர்களைப் பத்துமடங்கு சமர்த்தராகக் கண்டான்.
	<br /><br />
	21. கோரேஸ் ராஜ்யபாரம்பண்ணும் முதலாம் வருஷமட்டும் தானியேல் அங்கே இருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel1