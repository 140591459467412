import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Malachi3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மல்கியா 3 </title>
	<meta
          name="description"
          content="Malachi 3 | மல்கியா 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, நான் என் தூதனை அனுப்புகிறேன், அவன் எனக்கு முன்பாகப் போய், வழியை ஆயத்தம்பண்ணுவான்; அப்பொழுது நீங்கள் தேடுகிற ஆண்டவரும் நீங்கள் விரும்புகிற உடன்படிக்கையின் தூதனுமானவர் தம்முடைய ஆலயத்துக்குத் தீவிரமாய் வருவார்; இதோ, வருகிறார் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	2. ஆனாலும் அவர் வரும் நாளைச் சகிப்பவன் யார்? அவர் வெளிப்படுகையில் நிலைநிற்பவன் யார்? அவர் புடமிடுகிறவனுடைய அக்கினியைப்போலவும், வண்ணாருடைய சவுக்காரத்தைப்போலவும் இருப்பார்.
	<br /><br />
	3. அவர் உட்கார்ந்து வெள்ளியைப் புடமிட்டுச் சுத்திகரித்துக்கொண்டிருப்பார்; அவர் லேவியின் புத்திரரைச் சுத்திகரித்து, அவர்கள் கர்த்தருடையவர்களாயிருக்கும்படிக்கும், நீதியாய் காணிக்கையைச் செலுத்தும்படிக்கும், அவர்களைப் பொன்னைப்போலவும் வெள்ளியைப்போலவும் புடமிடுவார்.
	<br /><br />
	4. அப்பொழுது பூர்வநாட்களிலும் முந்தின வருஷங்களிலும் இருந்ததுபோல, யூதாவின் காணிக்கையும், எருசலேமின் காணிக்கையும் கர்த்தருக்குப் பிரியமாயிருக்கும்.
	<br /><br />
	5. நான் நியாயத்தீர்ப்புச் செய்யும்படி உங்களிடத்தில் வந்து, சூனியக்காரருக்கும் விபசாரருக்கும் பொய்யாணை இடுகிறவர்களுக்கும், எனக்குப் பயப்படாமல் விதவைகளும் திக்கற்ற பிள்ளைகளுமாகிய கூலிக்காரரின் கூலியை அபகரித்துக்கொள்ளுகிறவர்களுக்கும், பரதேசிக்கு அநியாயஞ்செய்கிறவர்களுக்கும் விரோதமாய்த் தீவிரமான சாட்சியாயிருப்பேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. நான் கர்த்தர், நான் மாறாதவர்; ஆகையால் யாக்கோபின் புத்திரராகிய நீங்கள் நிர்மூலமாகவில்லை.
	<br /><br />
	7. நீங்கள் உங்கள் பிதாக்களின் நாட்கள் தொடங்கி என் கட்டளைகளைக் கைக்கொள்ளாமல், அவைகளைவிட்டு விலகிப்போனீர்கள்; என்னிடத்திற்குத் திரும்புங்கள், அப்பொழுது உங்களிடத்திற்குத் திரும்புவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; நாங்கள் எந்த விஷயத்தில் திரும்பவேண்டும் என்கிறீர்கள்.
	<br /><br />
	8. மனுஷன் தேவனை வஞ்சிக்கலாமா? நீங்களோ என்னை வஞ்சிக்கிறீர்கள். எதிலே உம்மை வஞ்சித்தோம் என்கிறீர்கள்? தசமபாகத்திலும் காணிக்கைகளிலுந்தானே.
	<br /><br />
	9. நீங்கள் சபிக்கப்பட்டவர்கள்; ஜனத்தாராகிய நீங்கள் எல்லாரும் என்னை வஞ்சித்தீர்கள்.
	<br /><br />
	10. என் ஆலயத்தில் ஆகாரம் உண்டாயிருக்கும்படித் தசமபாகங்களையெல்லாம் பண்டசாலையிலே கொண்டுவாருங்கள்; அப்பொழுது நான் வானத்தின் பலகணிகளைத் திறந்து, இடங்கொள்ளாமற்போகுமட்டும் உங்கள்மேல் ஆசீர்வாதத்தை வருஷிக்கமாட்டேனோவென்று அதினால் என்னைச் சோதித்துப் பாருங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. பூமியின் கனியைப் பட்சித்துப் போடுகிறவைகளை உங்கள் நிமித்தம் கண்டிப்பேன்; அவைகள் உங்கள் நிலத்தின் பலனை அழிப்பதில்லை, வெளியிலுள்ள திராட்சக்கொடி பழமில்லாமற்போவதுமில்லை என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. அப்பொழுது எல்லா ஜாதிகளும் உங்களைப் பாக்கியவான்கள் என்பார்கள்; தேசம் விரும்பப்படத்தக்கதாயிருக்கும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. நீங்கள் எனக்கு விரோதமாய்ப் பேசின பேச்சுகள் கடினமாயிருக்கிறது என்று கர்த்தர் சொல்லுகிறார்; ஆனாலும் உமக்கு விரோதமாக என்னத்தைப் பேசினோம் என்கிறீர்கள்.
	<br /><br />
	14. தேவனைச் சேவிப்பது விருதா, அவருடைய கட்டளைகளைக் கைக்கொள்ளுகிறதினாலும், சேனைகளின் கர்த்தருக்கு முன்பாகத் துக்கித்து நடக்கிறதினாலும் என்ன பிரயோஜனம்?
	<br /><br />
	15. இப்போதும் அகங்காரிகளைப் பாக்கியவான்கள் என்கிறோம்; தீமை செய்கிறவர்கள் திடப்படுகிறார்கள்; அவர்கள் தேவனைப் பரீட்சைபார்த்தாலும் விடுவிக்கப்படுகிறார்களே என்று சொல்லுகிறீர்கள்.
	<br /><br />
	16. அப்பொழுது கர்த்தருக்குப் பயந்தவர்கள் ஒருவரோடொருவர் பேசிக்கொள்வார்கள்; கர்த்தர் கவனித்துக்கேட்பார்; கர்த்தருக்குப் பயந்தவர்களுக்காகவும் அவருடைய நாமத்தைத் தியானிக்கிறவர்களுக்காகவும் ஞாபகப்புஸ்தகம் ஒன்று அவருக்கு முன்பாக எழுதப்பட்டிருக்கிறது.
	<br /><br />
	17. என் சம்பத்தை நான் சேர்க்கும் நாளிலே அவர்கள் என்னுடையவர்களாயிருப்பார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; ஒரு மனுஷன் தனக்கு ஊழியஞ்செய்கிற தன்னுடைய குமாரனைக் கடாட்சிக்கிறதுபோல நான் அவர்களைக் கடாட்சிப்பேன்.
	<br /><br />
	18. அப்பொழுது நீங்கள் நீதிமானுக்கும் துன்மார்க்கனுக்கும், தேவனுக்கு ஊழியஞ்செய்கிறவனுக்கும் அவருக்கு ஊழியஞ்செய்யாதவனுக்கும் இருக்கிற வித்தியாசத்தைத் திரும்பவும் காண்பீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Malachi3