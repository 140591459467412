import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Malachi4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மல்கியா 4 </title>
	<meta
          name="description"
          content="Malachi 4 | மல்கியா 4 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, சூளையைப்போல எரிகிற நாள் வரும்; அப்பொழுது அகங்காரிகள் யாவரும் அக்கிரமஞ்செய்கிற யாவரும் துரும்பாயிருப்பார்கள்; வரப்போகிற அந்த நாள் அவர்களைச் சுட்டெரிக்கும்; அது அவர்களுக்கு வேரையும் கொப்பையும் வைக்காமற்போகும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	2. ஆனாலும் என் நாமத்துக்குப் பயந்திருக்கிற உங்கள்மேல் நீதியின் சூரியன் உதிக்கும்; அதின் செட்டைகளின்கீழ் ஆரோக்கியம் இருக்கும்; நீங்கள் வெளியே புறப்பட்டுப்போய், கொழுத்த கன்றுகளைப்போல வளருவீர்கள்.
	<br /><br />
	3. துன்மார்க்கரை மிதிப்பீர்கள்; நான் இதைச் செய்யும்நாளிலே அவர்கள் உங்கள் உள்ளங்கால்களின்கீழ் சாம்பலாயிருப்பார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. ஓரேபிலே இஸ்ரவேலரெல்லாருக்காகவும் என் தாசனாகிய மோசேக்கு நான் கற்பித்த நியாயப்பிரமாணமாகிய கட்டளைகளையும் நியாயங்களையும் நினையுங்கள்.
	<br /><br />
	5. இதோ, கர்த்தருடைய பெரிதும் பயங்கரமுமான நாள் வருகிறதற்கு முன்னே நான் உங்களிடத்திற்கு எலியா தீர்க்கதரிசியை அனுப்புகிறேன்.
	<br /><br />
	6. நான் வந்து பூமியைச் சங்காரத்தால் அடிக்காதபடிக்கு, அவன் பிதாக்களுடைய இருதயத்தைப் பிள்ளைகளிடத்திற்கும், பிள்ளைகளுடைய இருதயத்தை அவர்கள் பிதாக்களிடத்திற்கும் திருப்புவான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Malachi4