import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 2 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 2 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 2 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சகோதரரே, நான் உங்களிடத்தில் வந்தபோது, தேவனைப்பற்றிய சாட்சியைச் சிறந்த வசனிப்போடாவது ஞானத்தோடாவது அறிவிக்கிறவனாக வரவில்லை.
	<br /><br />
	2. இயேசுகிறிஸ்துவை, சிலுவையில் அறையப்பட்ட அவரையேயன்றி, வேறொன்றையும் உங்களுக்குள்ளே அறியாதிருக்கத் தீர்மானித்திருந்தேன்.
	<br /><br />
	3. அல்லாமலும் நான் பலவீனத்தோடும் பயத்தோடும் மிகுந்த நடுக்கத்தோடும் உங்களிடத்தில் இருந்தேன்.
	<br /><br />
	4. உங்கள் விசுவாசம் மனுஷருடைய ஞானத்திலல்ல, தேவனுடைய பெலத்தில் நிற்கும்படிக்கு,
	<br /><br />
	5. என் பேச்சும் என் பிரசங்கமும் மனுஷ ஞானத்திற்குரிய நயவசனமுள்ளதாயிராமல், ஆவியினாலும் பெலத்தினாலும் உறுதிப்படுத்தப்பட்டதாயிருந்தது.
	<br /><br />
	6. அப்படியிருந்தும், தேறினவர்களுக்குள்ளே ஞானத்தைப் பேசுகிறோம்; இப்பிரபஞ்சத்தின் ஞானத்தையல்ல, அழிந்துபோகிறவர்களாகிய இப்பிரபஞ்சத்தின் பிரபுக்களுடைய ஞானத்தையுமல்ல,
	<br /><br />
	7. உலகத்தோற்றத்திற்குமுன்னே தேவன் நம்முடைய மகிமைக்காக ஏற்படுத்தினதும், மறைக்கப்பட்டதுமாயிருந்த இரகசியமான தேவஞானத்தையே பேசுகிறோம்.
	<br /><br />
	8. அதை இப்பிரபஞ்சத்துப் பிரபுக்களில் ஒருவனும் அறியவில்லை; அறிந்தார்களானால், மகிமையின் கர்த்தரை அவர்கள் சிலுவையில் அறையமாட்டார்களே.
	<br /><br />
	9. எழுதியிருக்கிறபடி: தேவன் தம்மில் அன்புகூருகிறவர்களுக்கு ஆயத்தம்பண்ணினவைகளைக் கண் காணவுமில்லை, காது கேட்கவுமில்லை, அவைகள் மனுஷனுடைய இருதயத்தில் தோன்றவுமில்லை;
	<br /><br />
	10. நமக்கோ தேவன் அவைகளைத் தமது ஆவியினாலே வெளிப்படுத்தினார்; அந்த ஆவியானவர் எல்லாவற்றையும், தேவனுடைய ஆழங்களையும், ஆராய்ந்திருக்கிறார்.
	<br /><br />
	11. மனுஷனிலுள்ள ஆவியேயன்றி மனுஷரில் எவன் மனுஷனுக்குரியவைகளை அறிவான்? அப்படிப்போல, தேவனுடைய ஆவியேயன்றி, ஒருவனும் தேவனுக்குரியவைகளை அறியமாட்டான்.
	<br /><br />
	12. நாங்களோ உலகத்தின் ஆவியைப்பெறாமல், தேவனால் எங்களுக்கு அருளப்பட்டவைகளை அறியும்படிக்குத் தேவனிலிருந்து புறப்படுகிற ஆவியையே பெற்றோம்.
	<br /><br />
	13. அவைகளை நாங்கள் மனுஷஞானம் போதிக்கிற வார்த்தைகளாலே பேசாமல், பரிசுத்தஆவி போதிக்கிற வார்த்தைகளாலே பேசி, ஆவிக்குரியவைகளை ஆவிக்குரியவைகளோடே சம்பந்தப்படுத்திக் காண்பிக்கிறோம்.
	<br /><br />
	14. ஜென்மசுபாவமான மனுஷனோ தேவனுடைய ஆவிக்குரியவைகளை ஏற்றுக்கொள்ளான்; அவைகள் அவனுக்குப் பைத்தியமாகத் தோன்றும்; அவைகள் ஆவிக்கேற்றபிரகாரமாய் ஆராய்ந்து நிதானிக்கப்படுகிறவைகளானதால், அவைகளை அறியவுமாட்டான்.
	<br /><br />
	15. ஆவிக்குரியவன் எல்லாவற்றையும் ஆராய்ந்து நிதானிக்கிறான்; ஆனாலும் அவன் மற்றொருவனாலும் ஆராய்ந்து நிதானிக்கப்படான்.
	<br /><br />
	16. கர்த்தருக்குப் போதிக்கத்தக்கதாக அவருடைய சிந்தையை அறிந்தவன் யார்? எங்களுக்கோ கிறிஸ்துவின் சிந்தை உண்டாயிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians2