import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoPeter2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> A Sure Salvation | 2 Peter - 1 in Tamil Bible Verse | 2 பேதுரு - 1 </title>
	<meta
          name="description"
          content=" நிச்சயமான இரட்சிப்பு | 2nd Peter in Tamil Bible Verse | 2-பேதுரு அதிகாரம் - 1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible "
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஒரு நிச்சயமான இரட்சிப்பு *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. நம்முடைய தேவனும் இரட்சகருமாயிருக்கிற இயேசுகிறிஸ்துவினுடைய நீதியால் எங்களைப்போல அருமையான விசுவாசத்தைப் பெற்றவர்களுக்கு, இயேசுகிறிஸ்துவின் ஊழியக்காரனும் அப்போஸ்தலனுமாகிய சீமோன் பேதுரு எழுதுகிறதாவது:
	<br /><br />
	2. தேவனையும் நம்முடைய கர்த்தராகிய இயேசுவையும் அறிகிற அறிவினால் உங்களுக்குக் கிருபையும் சமாதானமும் பெருகக்கடவது.
	<br /><br />
	3. தம்முடைய மகிமையினாலும் காருணியத்தினாலும் நம்மை அழைத்தவரை அறிகிற அறிவினாலே ஜீவனுக்கும் தேவபக்திக்கும் வேண்டிய யாவற்றையும், அவருடைய திவ்விய வல்லமையானது நமக்குத் தந்தருளினதுமன்றி,
	<br /><br />
	4. இச்சையினால் உலகத்திலுண்டான கேட்டுக்குத் தப்பி, திவ்விய சுபாவத்துக்குப் பங்குள்ளவர்களாகும்பொருட்டு, மகா மேன்மையும் அருமையுமான வாக்குத்தத்தங்களும் அவைகளினாலே நமக்கு அளிக்கப்பட்டிருக்கிறது.
	<br /><br />
	5. இப்படியிருக்க, நீங்கள் அதிக ஜாக்கிரதையுள்ளவர்களாய் உங்கள் விசுவாசத்தோடே தைரியத்தையும், தைரியத்தோடே ஞானத்தையும்,
	<br /><br />
	6. ஞானத்தோடே இச்சையடக்கத்தையும், இச்சையடக்கத்தோடே பொறுமையையும், பொறுமையோடே தேவபக்தியையும்,
	<br /><br />
	7. தேவபக்தியோடே சகோதர சிநேகத்தையும், சகோதர சிநேகத்தோடே அன்பையும் கூட்டி வழங்குங்கள்.
	<br /><br />
	8. இவைகள் உங்களுக்கு உண்டாயிருந்து பெருகினால், உங்களை நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவை அறிகிற அறிவிலே வீணரும் கனியற்றவர்களுமாயிருக்கவொட்டாது.
	<br /><br />
	9. இவைகள் இல்லாதவன் எவனோ, அவன் முன்செய்த பாவங்களறத் தான் சுத்திகரிக்கப்பட்டதை மறந்து கண்சொருகிப்போன குருடனாயிருக்கிறான்.
	<br /><br />
	10. ஆகையால், சகோதரரே, உங்கள் அழைப்பையும் தெரிந்துகொள்ளுதலையும் உறுதியாக்கும்படி ஜாக்கிரதையாயிருங்கள்; இவைகளைச் செய்தால் நீங்கள் ஒருக்காலும் இடறிவிழுவதில்லை.
	<br /><br />
	11. இவ்விதமாய், நம்முடைய கர்த்தரும் இரட்சகருமாகிய இயேசுகிறிஸ்துவினுடைய நித்திய ராஜ்யத்துக்குட்படும் பிரவேசம் உங்களுக்குப் பரிபூரணமாய் அளிக்கப்படும்.
	<br /><br />
	12. இதினிமித்தம், இவைகளை நீங்கள் அறிந்தும், நீங்கள் இப்பொழுது அறிந்திருக்கிற சத்தியத்தில் உறுதிப்பட்டிருந்தும், உங்களுக்கு இவைகளை எப்பொழுதும் நினைப்பூட்ட நான் அசதியாயிரேன்.
	<br /><br />
	13. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து எனக்கு அறிவித்தபடி நான் என் கூடாரத்தைவிட்டுப்போவது சீக்கிரத்தில் நேரிடுமென்று அறிந்து,
	<br /><br />
	14. இந்தக் கூடாரத்தில் நான் இருக்குமளவும் உங்களை நினைப்பூட்டி எழுப்பிவிடுவது நியாயமென்று எண்ணுகிறேன்.
	<br /><br />
	15. மேலும், நான் சென்றுபோனபின்பு இவைகளை நீங்கள் எப்பொழுதும் நினைத்துக்கொள்ள ஏதுவுண்டாயிருக்கும்படி பிரயத்தனம்பண்ணுவேன்.
	<br /><br />
	16. நாங்கள் தந்திரமான கட்டுக்கதைகளைப் பின்பற்றினவர்களாக அல்ல, அவருடைய மகத்துவத்தைக் கண்ணாரக் கண்டவர்களாகவே நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் வல்லமையையும் வருகையையும் உங்களுக்கு அறிவித்தோம்.
	<br /><br />
	17. இவர் என்னுடைய நேசகுமாரன், இவரிடத்தில் பிரியமாயிருக்கிறேன் என்று சொல்லுகிற சத்தம் உன்னதமான மகிமையிலிருந்து அவருக்கு உண்டாகி, பிதாவாகிய தேவனால் அவர் கனத்தையும் மகிமையையும் பெற்றபோது,
	<br /><br />
	18. அவரோடேகூட நாங்கள் பரிசுத்த பருவதத்திலிருக்கையில், வானத்திலிருந்து பிறந்த அந்தச் சத்தத்தைக்கேட்டோம்.
	<br /><br />
	19. அதிக உறுதியான தீர்க்கதரிசனவசனமும் நமக்கு உண்டு; பொழுது விடிந்து விடிவெள்ளி உங்கள் இருதயங்களில் உதிக்குமளவும் இருளுள்ள ஸ்தலத்தில் பிரகாசிக்கிற விளக்கைப்போன்ற அவ்வசனத்தைக் கவனித்திருப்பது நலமாயிருக்கும்.
	<br /><br />
	20. வேதத்திலுள்ள எந்தத் தீர்க்கதரிசனமும் சுயதோற்றமான பொருளையுடையதாயிராதென்று நீங்கள் முந்தி அறியவேண்டியது.
	<br /><br />
	21. தீர்க்கதரிசனமானது ஒருகாலத்திலும் மனுஷருடைய சித்தத்தினாலே உண்டாகவில்லை; தேவனுடைய பரிசுத்த மனுஷர்கள் பரிசுத்த ஆவியினாலே ஏவப்பட்டுப் பேசினார்கள்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* 2 பேதுரு 1 ஒரு அழகான வாழ்த்து மற்றும் நினைவூட்டலுடன் துவங்குகிறது, தெய்வீக வாழ்க்கையை வாழவும், நம் உலகின் ஊழலில் இருந்து தப்பிக்கவும் நமக்கு மிகவும் பெரிய வாக்குறுதிகள் வழங்கப்பட்டுள்ளன. நாம் திறம்பட்டவர்களாகவும், பலனளிக்கக்கூடியவர்களாகவும் இருக்க, கடவுளைப் பற்றிய நமது அறிவில் முன்னேறும்படி வலியுறுத்தப்படுகிறோம். அப்போஸ்தலனாகிய பேதுரு, தான் கிறிஸ்துவின் மகத்துவத்தை நேரில் கண்ட சாட்சியாகவும், கிறிஸ்துவின் செய்தியை உறுதிப்படுத்தும் புனித மலையில் அவருடன் இருந்ததாகவும் நினைவுபடுத்துகிறார், வேதத்தின் அனைத்து தீர்க்கதரிசனங்களும் பரிசுத்த ஆவியின் தூண்டுதலால் வந்தவை என்பதை நிரூபிக்கிறது. *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default TwoPeter2