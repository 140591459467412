import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial35 = () => {
  const verseNumber = 35;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 35 </title>
	<meta
          name="description"
          content="Ezekiel 35 | எசேக்கியேல்  35 |
          Ezekiel 35 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ உன் முகத்தைச் சேயீர்மலைக்கு நேராகத் திருப்பி அதற்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து,
	<br /><br />
	3. அதற்குச் சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், சேயீர்மலையே, இதோ, நான் உனக்கு விரோதமாக வந்து, என் கையை உனக்கு விரோதமாகநீட்டி, உன்னைப் பாழும் அவாந்தரவெளியுமாக்குவேன்.
	<br /><br />
	4. உன் பட்டணங்களை வனாந்தரமாக்கிப்போடுவேன்; நீ பாழாய்ப்போவாய்; நான் கர்த்தர் என்று அறிந்துகொள்வாய்.
	<br /><br />
	5. நீ பழைய பகையை வைத்து, இஸ்ரவேல் புத்திரருடைய அக்கிரமம் நிறைவேறுகையில் அவர்களுக்கு உண்டான ஆபத்தின் காலத்திலே பட்டயத்தின் கூர்மையினால் அவர்களுடைய இரத்தத்தைச் சிந்தினபடியால்,
	<br /><br />
	6. நான் இரத்தப் பழிக்கு உன்னை ஒப்புவிப்பேன்; இரத்தப்பழி உன்னைப் பின்தொடரும் என்று கர்த்தராகிய ஆண்டவராயிருக்கிற நான் என் ஜீவனைக்கொண்டு சொல்லுகிறேன்; நீ இரத்தத்தை வெறுக்காதபடியினால் இரத்தம் உன்னைப் பின்தொடரும்.
	<br /><br />
	7. நான் சேயீர்மலையைப் பாழும் அவாந்தர இடமுமாக்கி, அதிலே போக்குவரவு செய்வார் இல்லாதபடி சங்காரஞ்செய்து,
	<br /><br />
	8. அதின் மலைகளைக் கொலையுண்டவர்களாலே நிரப்புவேன்; உன் மேடுகளிலும் உன் பள்ளத்தாக்குகளிலும் உன் எல்லா ஆறுகளிலும் பட்டயத்தால் வெட்டுண்டவர்கள் விழுவார்கள்.
	<br /><br />
	9. நீ என்றைக்கும் அவாந்தரவெளியாயிருக்கும்படி செய்வேன்; உன் பட்டணங்கள் குடியேற்றப்படுவதில்லை; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	10. இரண்டு ஜாதிகளும் இரண்டு தேசங்களும் கர்த்தர் வசமாயிருந்தும், அவைகள் என்னுடையவைகளாகும், நான் அவைகளைச் சுதந்தரித்துக்கொள்ளுவேன் என்று நீ சொல்லுகிறபடியினால்,
	<br /><br />
	11. நீ அவர்கள்மேல் வைத்த வர்மத்தினால் செய்த உன் கோபத்துக்குத்தக்கதாகவும், உன் பொறாமைக்குத்தக்கதாகவும் நான் செய்து, கர்த்தராகிய ஆண்டவராயிருக்கிற நான் உன்னை நியாயந்தீர்க்கும்போது, என்னை அவர்களுக்குள் அதினால் அறியப்பண்ணுவேன் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன்.
	<br /><br />
	12. இஸ்ரவேலின் மலைகள் பாழாக்கப்பட்டு எங்களுக்கு இரையாகக் கொடுக்கப்பட்டது என்று, நீ அவைகளுக்கு விரோதமாய்ச் சொன்ன உன் நிந்தனைகளையெல்லாம் கர்த்தராகிய நான் கேட்டேன் என்று அப்பொழுது அறிந்துகொள்வாய்.
	<br /><br />
	13. நீங்கள் உங்கள் வாயினால் எனக்கு விரோதமாகப் பெருமைபாராட்டி, எனக்கு விரோதமாக உங்கள் வார்த்தைகளைப் பெருகப்பண்ணினீர்கள்; அதை நான் கேட்டேன்.
	<br /><br />
	14. பூமியெல்லாம் மகிழும்போது நான் உன்னைப் பாழாயிருக்கும்படி செய்வேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. இஸ்ரவேல் வம்சத்தாரின் சுதந்தரம் பாழாய்ப்போனதைக் கண்டு மகிழ்ந்தாயே, உனக்கும் அப்படியே சம்பவிக்கச்செய்வேன்; சேயீர்மலையே, ஏதோமே, நீ முழுதும் பாழாவாய்; அதினால் நான் கர்த்தர் என்று அறிந்துகொள்வார்களென்று உரைத்தார் என்று சொல்லு.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial35