import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 14 </title>
	<meta
          name="description"
          content="Ezekiel 14 | எசேக்கியேல்  14 |
          Ezekiel 14 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேலுடைய மூப்பரில் சிலர் என்னிடத்தில் வந்து, எனக்கு முன்பாக உட்கார்ந்தார்கள்.
	<br /><br />
	2. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	3. மனுபுத்திரனே, இந்த மனுஷர் தங்கள் நரகலான விக்கிரகங்களைத் தங்கள் இருதயத்தின்மேல் நாட்டி, தங்கள் அக்கிரமமாகிய இடறலைத் தங்கள் முகத்துக்கு எதிராக வைத்துக்கொண்டிருக்கிறார்களே; இவர்கள் என்னிடத்தில் விசாரிக்கத்தகுமா?
	<br /><br />
	4. ஆகையால், நீ அவர்களோடே பேசிச்சொல்லவேண்டியது என்னவென்றால்: இஸ்ரவேல் வம்சத்தாரில் தன்னுடைய நரகலான விக்கிரகங்களைத் தன் இருதயத்தின்மேல் நாட்டி, தன் அக்கிரமமாகிய இடறலைத் தன் முகத்துக்கு எதிராக வைத்துக்கொண்டிருக்கிற எவனாகிலும் தீர்க்கதரிசியினிடத்தில் வந்தால், கர்த்தராகிய நான் இஸ்ரவேல் வம்சத்தாருடைய இருதயத்தில் இருக்கிறதைப் பிடிக்கும்படியாக அப்படிப்பட்டவனுடைய நரகலான விக்கிரகங்களின் திரட்சிக்குத்தக்கதாக உத்தரவு கொடுப்பேன்.
	<br /><br />
	5. அவர்கள் எல்லாரும் தங்கள் நரகலான விக்கிரகங்களைப் பின்பற்றி, என்னைவிட்டுப் பேதலித்துப்போனார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	6. ஆகையால், நீ இஸ்ரவேல் வம்சத்தாரை நோக்கி: திரும்புங்கள், உங்கள் நரகலான விக்கிரகங்களை விட்டுத் திரும்புங்கள்; உங்கள் சகல அருவருப்புகளையும் விட்டு உங்கள் முகங்களைத் திருப்புங்கள் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	7. இஸ்ரவேல் வம்சத்தாரிலும் இஸ்ரவேலில் தங்குகிற அந்நியரிலும் என்னைப் பின்பற்றாமல் பேதலித்து, தன் நரகலான விக்கிரகங்களைத் தன் இருதயத்தின்மேல் நாட்டி, தன் அக்கிரமமாகிய இடறலைத் தன் முகத்துக்கெதிராக வைத்துக்கொண்டிருக்கிற எவனாகிலும் தீர்க்கதரிசியின் மூலமாய் என்னிடத்தில் விசாரிக்க வந்தால், அவனுக்குக் கர்த்தராகிய நானே உத்தரவுகொடுத்து,
	<br /><br />
	8. அந்த மனுஷனுக்கு விரோதமாக என் முகத்தைத் திருப்பி, அவனை அடையாளமாகவும் பழமொழியாகவும் வைத்து, அவனை என் ஜனத்தின் நடுவில் இராதபடிக்குச் சங்கரித்துப்போடுவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	9. ஒரு தீர்க்கதரிசி எத்தப்பட்டு ஒரு விசேஷத்தைச் சொன்னானாகில், அப்படிக்கொத்த தீர்க்கதரிசியைக் கர்த்தராகிய நானே எத்தப்படப்பண்ணினேன்; நான் அவனுக்கு விரோதமாக என் கையை நீட்டி, அவனை இஸ்ரவேல் ஜனத்தின் நடுவில் இராதபடிக்கு அழிப்பேன்.
	<br /><br />
	10. அப்படியே அவரவர் தங்கள் தங்கள் அக்கிரமத்தைச் சுமப்பார்கள்; தீர்க்கதரிசியினிடத்தில் விசாரிக்கிறவனுடைய தண்டனை எப்படியோ அப்படியே தீர்க்கதரிசியினுடைய தண்டனையும் இருக்கும்.
	<br /><br />
	11. இஸ்ரவேல் வம்சத்தார் இனி என்னைவிட்டு வழிதப்பிப்போகாமலும், தங்கள் எல்லா மீறுதல்களாலும் இனி அசுசிப்படாமலும் இருக்கும்பொருட்டாக இப்படிச் சம்பவிக்கும்; அப்பொழுது அவர்கள் என் ஜனமாயிருப்பார்கள், நான் அவர்கள் தேவனாயிருப்பேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	12. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	13. மனுபுத்திரனே, ஒரு தேசம் எனக்கு விரோதமாய்த் துரோகம்பண்ணிக்கொண்டேயிருந்து, பாவஞ்செய்தால், நான் அதற்கு விரோதமாக என் கையை நீட்டி, அதில் அப்பம் என்னும் ஆதரவுகோலை முறித்து, அதில் பஞ்சத்தை அனுப்பி, மனுஷரையும் மிருகங்களையும் அதில் இராதபடிக்கு நாசம்பண்ணுவேன்.
	<br /><br />
	14. அப்பொழுது நோவா தானியேல் யோபு ஆகிய இம்மூன்று புருஷரும் அதின் நடுவில் இருந்தாலும், அவர்கள் தங்கள் நீதியினால் தங்கள் ஆத்துமாக்களைமாத்திரம் தப்புவிப்பார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. நான் தேசத்தில் துஷ்டமிருகங்களை அனுப்ப, அம்மிருகங்களினிமித்தம் ஒருவரும் அதின் வழியாய் நடக்கக்கூடாதபடி வெறுமையும் பாழுமாகும்போது,
	<br /><br />
	16. அந்த மூன்று புருஷரும் அதின் நடுவில் இருந்தாலும், தாங்கள்மாத்திரம் தப்புவார்களேயல்லாமல், குமாரரையாகிலும் குமாரத்திகளையாகிலும் தப்புவிக்கமாட்டார்கள்; தேசமும் பாழாய்ப்போகும் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்பதைக் கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	17. அல்லது நான் அந்தத் தேசத்தின்மேல் பட்டயத்தை வரப்பண்ணி: பட்டயமே, தேசத்தை உருவப்போ என்று சொல்லி, அதிலுள்ள மனுஷரையும் மிருகங்களையும் நாசம்பண்ணும்போது,
	<br /><br />
	18. அந்த மூன்று புருஷரும் அதின் நடுவில் இருந்தாலும், தாங்கள்மாத்திரம் தப்புவார்களேயல்லாமல், குமாரரையாகிலும் குமாரத்திகளையாகிலும் தப்புவிக்கமாட்டார்கள் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்பதைக் கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	19. அல்லது நான் அந்தத் தேசத்தில் கொள்ளைநோயை அனுப்பி, அதிலுள்ள மனுஷரையும் மிருகங்களையும் நாசம்பண்ணும்படி அதின்மேல் இரத்தப்பழியாக என் உக்கிரத்தை ஊற்றும்போது,
	<br /><br />
	20. நோவாவும் தானியேலும் யோபும் அதின் நடுவில் இருந்தாலும், அவர்கள் தங்கள் நீதியினால் தங்கள் ஆத்துமாக்களை மாத்திரம் தப்புவிப்பார்களேயல்லாமல், குமாரனையாகிலும், குமாரத்தியையாகிலும் தப்புவிக்கமாட்டார்கள் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்பதைக் கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	21. ஆகையால், கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நான் மனுஷரையும் மிருகங்களையும் நாசம்பண்ணும்படி எருசலேமுக்கு விரோதமாகப் பட்டயம், பஞ்சம், துஷ்டமிருகங்கள், கொள்ளைநோய் என்னும் இந்நான்கு கொடிய தண்டனைகளையும் அனுப்பும்போது எவ்வளவு அதிக சங்காரமாகும்?
	<br /><br />
	22. ஆகிலும், இதோ, அதிலே தப்பி மீதியாகி வெளியே கொண்டுவரப்படுகிற குமாரரும் குமாரத்திகளும் சிலர் இருப்பார்கள்; இதோ, அவர்கள் உங்களண்டைக்குப் புறப்பட்டு வருவார்கள்; அப்பொழுது நீங்கள் அவர்கள் மார்க்கத்தையும் அவர்கள் கிரியைகளையும் கண்டு, நான் எருசலேமின்மேல் வரப்பண்ணின தீங்கையும் அதின்மேல் நான் வரப்பண்ணின எல்லாவற்றையுங்குறித்துத் தேற்றப்படுவீர்கள்.
	<br /><br />
	23. நீங்கள் அவர்கள் மார்க்கத்தையும் அவர்கள் கிரியையையும் காணும்போது, அவர்கள் உங்களுக்குத் தேற்றரவாயிருப்பார்கள்; நான் அதிலே செய்த எல்லாவற்றையும் முகாந்தரமில்லாமல் செய்யவில்லையென்று அப்பொழுது அறிந்துகொள்வீர்கள் என்பதைக் கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொன்னார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial14