import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Habakkuk1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆபகூக் 1 </title>
	<meta
          name="description"
          content="Habakkuk 1 | ஆபகூக் 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆபகூக் என்னும் தீர்க்கதரிசி தரிசனமாய்க் கண்ட பாரம்.
	<br /><br />
	2. கர்த்தாவே, நான் எதுவரைக்கும் உம்மை நோக்கிக் கூப்பிடுவேன், நீர் கேளாமலிருக்கிறீரே! கொடுமையினிமித்தம் நான் எதுவரைக்கும் உம்மை நோக்கிக் கூப்பிடுவேன், நீர் இரட்சியாமலிருக்கிறீரே!
	<br /><br />
	3. நீர் எனக்கு அக்கிரமத்தைக் காண்பித்து, என்னைத் தீவினையைப் பார்க்கப்பண்ணுகிறதென்ன? கொள்ளையும் கொடுமையும் எனக்கு எதிரே நிற்கிறது; வழக்கையும் வாதையும் எழுப்புகிறவர்கள் உண்டு.
	<br /><br />
	4. ஆகையால் நியாயப்பிரமாணம் பெலனற்றதாகி, நியாயம் ஒருபோதும் செல்லாமற்போகிறது; துன்மார்க்கன் நீதிமானை வளைந்துகொள்ளுகிறான்; ஆதலால் நியாயம் புரட்டப்படுகிறது.
	<br /><br />
	5. நீங்கள் புறஜாதிகளை நோக்கிப்பார்த்து, ஆச்சரியப்பட்டுப் பிரமியுங்கள்; விவரிக்கப்பட்டாலும் நீங்கள் விசுவாசியாத ஒரு கிரியையை உங்கள் நாட்களில் நடப்பிப்பேன்.
	<br /><br />
	6. இதோ, நான் கல்தேயரென்னும் கொடிதும் வேகமுமான ஜாதியாரை எழுப்புவேன்; அவர்கள் தங்களுடையதல்லாத வாசஸ்தலங்களைக் கட்டிக்கொள்ளத் தேசத்தின் விசாலங்களில் நடந்துவருவார்கள்.
	<br /><br />
	7. அவர்கள் கெடியும் பயங்கரமுமானவர்கள்; அவர்களுடைய நியாயமும் அவர்களுடைய மேன்மையும் அவர்களாலேயே உண்டாகும்.
	<br /><br />
	8. அவர்களுடைய குதிரைகள் சிவிங்கிகளிலும் வேகமும், சாயங்காலத்தில் திரிகிற ஓநாய்களிலும் தீவிரமுமாயிருக்கும்; அவர்களுடைய குதிரைவீரர் பரவுவார்கள்; அவர்களுடைய குதிரைவீரர் தூரத்திலிருந்து வருவார்கள்; இரைக்குத் தீவிரிக்கிற கழுகுகளைப்போல் பறந்துவருவார்கள்.
	<br /><br />
	9. அவர்களெல்லாரும் கொடுமை செய்யவருவார்கள்; அவர்களுடைய முகங்கள் சுவறச் செய்யும் கீழ்க்காற்றைப்போலிருக்கும்; அவர்கள் மணலத்தனை ஜனங்களைச் சிறைபிடித்துச் சேர்ப்பார்கள்.
	<br /><br />
	10. அவர்கள் ராஜாக்களை ஆகடியம்பண்ணுவார்கள்; அதிபதிகள் அவர்களுக்குப் பரியாசமாயிருப்பார்கள்; அவர்கள் அரண்களையெல்லாம் பார்த்து நகைத்து, மண்மேடுகளைக் குவித்து அவைகளைப் பிடிப்பார்கள்.
	<br /><br />
	11. அப்பொழுது அவனுடைய மனம்மாற, அவன் தன் பெலன் தன் தேவனாலே உண்டானதென்று சொல்லி மிஞ்சிப்போய்க் குற்றவாளியாவான்.
	<br /><br />
	12. கர்த்தாவே, நீர் பூர்வகாலமுதல் என் தேவனும், என் பரிசுத்தருமானவர் அல்லவா? நாங்கள் சாவதில்லை; கர்த்தாவே, நியாயத்தீர்ப்புச் செய்ய அவனை வைத்தீர்; கன்மலையே, தண்டனை செய்ய அவனை நியமித்தீர்.
	<br /><br />
	13. தீமையைப் பார்க்கமாட்டாத சுத்தக்கண்ணனே, அநியாயத்தை நோக்கிக்கொண்டிருக்கமாட்டீரே; பின்னை துரோகிகளை நீர் நோக்கிக்கொண்டிருக்கிறதென்ன? துன்மார்க்கன் தன்னைப் பார்க்கிலும் நீதிமானை விழுங்கும்போது நீர் மௌனமாயிருக்கிறதென்ன?
	<br /><br />
	14. மனுஷரைச் சமுத்திரத்து மச்சங்களுக்கும், அதிகாரியில்லாத ஊர்வனவற்றிற்கும் சமானமாக்குகிறதென்ன?
	<br /><br />
	15. அவர்களெல்லாரையும் தூண்டிலினால் இழுத்துக்கொள்ளுகிறான்; அவர்களைத் தன் வலையினால் பிடித்து, தன் பறியிலே சேர்த்துக்கொள்ளுகிறான்; அதினால் சந்தோஷப்பட்டுக் களிகூருகிறான்.
	<br /><br />
	16. ஆகையால் அவைகளினால் தன் பங்கு கொழுப்புள்ளதும், தன் போஜனம் ருசிகரமுள்ளதுமாயிற்று என்று சொல்லி அவன் தன் வலைக்குப் பலியிட்டுத் தன் பறிக்குத் தூபங்காட்டுகிறான்.
	<br /><br />
	17. இதற்காக அவன் தன் வலையை இழுத்து அதிலுள்ளவைகளைக் கொட்டிக்கொண்டிருந்து, இரக்கமில்லாமல் ஜாதிகளை எப்போதும் கொன்றுபோட வேண்டுமோ?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Habakkuk1