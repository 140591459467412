import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const SecondTimothy1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> 2 Timothy in Tamil Bible Verse | 2 தீமோத்தேயு அதிகாரம்-1 </title>
	<meta
          name="description"
          content="Second LETTER TO THE Timothy Chapter 1 | தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
		<br />
<h2>
* ஒப்படைக்கப்பட்டதைக் காத்தல் *
			</h2>
<br /><br />
			

      {/* Vesrse of Tamilbible */}

	  <p>
	1. கிறிஸ்து இயேசுவினால் உண்டாயிருக்கிற ஜீவனைப்பற்றிய வாக்குத்தத்தத்தின்படி, தேவனுடைய சித்தத்தினாலே, இயேசுகிறிஸ்துவின் அப்போஸ்தலனாயிருக்கிற பவுல்,
	<br /><br />
	2. பிரியமுள்ள குமாரனாகிய தீமோத்தேயுவுக்கு எழுதுகிறதாவது: பிதாவாகிய தேவனாலும் நம்முடைய கர்த்தராகிய கிறிஸ்து இயேசுவினாலும் கிருபையும் இரக்கமும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	3. நான் இரவும் பகலும் இடைவிடாமல் என் ஜெபங்களில் உன்னை நினைத்து, உன் கண்ணீரை ஞாபகம்பண்ணி, சந்தோஷத்தால் நிறையப்படும்படிக்கு உன்னைக் காண வாஞ்சையாயிருந்து,
	<br /><br />
	4. உன்னிலுள்ள மாயமற்ற விசுவாசத்தை நினைவுகூருகிறதினால், என் முன்னோர்கள் முதற்கொண்டு சுத்த மனச்சாட்சியோடே ஆராதித்துவரும் தேவனை நான் ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	5. அந்த விசுவாசம் முந்தி உன் பாட்டியாகிய லோவிசாளுக்குள்ளும் உன் தாயாகிய ஐனிக்கேயாளுக்குள்ளும் நிலைத்திருந்தது; அது உனக்குள்ளும் நிலைத்திருக்கிறதென்று நிச்சயித்திருக்கிறேன்.
	<br /><br />
	6. இதினிமித்தமாக, நான் உன்மேல் என் கைகளை வைத்ததினால் உனக்கு உண்டான தேவவரத்தை நீ அனல்மூட்டி எழுப்பிவிடும்படி உனக்கு நினைப்பூட்டுகிறேன்.
	<br /><br />
	7. தேவன் நமக்குப் பயமுள்ள ஆவியைக் கொடாமல், பலமும் அன்பும் தெளிந்த புத்தியுள்ள ஆவியையே கொடுத்திருக்கிறார்.
	<br /><br />
	8. ஆகையால் நம்முடைய கர்த்தரைப் பற்றிய சாட்சியைக்குறித்தாவது, அவர் நிமித்தம் கட்டப்பட்டிருக்கிற என்னைக் குறித்தாவது, நீ வெட்கப்படாமல், தேவவல்லமைக்கேற்றபடி சுவிசேஷத்திற்காக என்னோடேகூடத் தீங்கநுபவி.
	<br /><br />
	9. அவர் நம்முடைய கிரியைகளின்படி நம்மை இரட்சிக்காமல், தம்முடைய தீர்மானத்தின்படியும், ஆதிகாலமுதல் கிறிஸ்து இயேசுவுக்குள் நமக்கு அருளப்பட்ட கிருபையின்படியும், நம்மை இரட்சித்து, பரிசுத்த அழைப்பினாலே அழைத்தார்.
	<br /><br />
	10. நம்முடைய இரட்சகராகிய இயேசுகிறிஸ்து பிரசன்னமானதினாலே அந்தக் கிருபை இப்பொழுது வெளிப்படுத்தப்பட்டது; அவர் மரணத்தைப் பரிகரித்து, ஜீவனையும் அழியாமையையும் சுவிசேஷத்தினாலே வெளியரங்கமாக்கினார்.
	<br /><br />
	11. அதற்கு நான் பிரசங்கியாகவும், அப்போஸ்தலனாகவும், புறஜாதியாருக்குப் போதகனாகவும் நியமிக்கப்பட்டேன்.
	<br /><br />
	12. அதினிமித்தம் நான் இந்தப் பாடுகளையும் அனுபவிக்கிறேன்; ஆயினும், நான் வெட்கப்படுகிறதில்லை; ஏனென்றால், நான் விசுவாசித்திருக்கிறவர் இன்னார் என்று அறிவேன், நான் அவரிடத்தில் ஒப்புக்கொடுத்ததை அவர் அந்நாள்வரைக்கும் காத்துக்கொள்ள வல்லவராயிருக்கிறாரென்று நிச்சயித்துமிருக்கிறேன்.
	<br /><br />
	13. நீ கிறிஸ்து இயேசுவைப்பற்றும் விசுவாசத்தோடும் அன்போடும் என்னிடத்தில் கேட்டிருக்கிற ஆரோக்கியமான வசனங்களின் சட்டத்தைக் கைக்கொண்டிரு.
	<br /><br />
	14. உன்னிடத்தில் ஒப்புவிக்கப்பட்ட அந்த நற்பொருளை நமக்குள்ளே வாசம்பண்ணுகிற பரிசுத்த ஆவியினாலே காத்துக்கொள்.
	<br /><br />
	15. ஆசியாநாட்டிலிருக்கிற யாவரும், அவர்களில் பிகெல்லு எர்மொகெனே முதலாய் என்னைவிட்டு விலகினார்களென்று அறிந்திருக்கிறாய்.
	<br /><br />
	16. ஒநேசிப்போருவின் வீட்டாருக்குக் கர்த்தர் இரக்கங் கட்டளையிடுவாராக; அவன் அநேகந்தரம் என்னை இளைப்பாற்றினான்; என் விலங்கைக்குறித்து அவன் வெட்கப்படவுமில்லை;
	<br /><br />
	17. அவன் ரோமாவில் வந்திருந்தபோது அதிக ஜாக்கிரதையாய் என்னைத் தேடிக் கண்டுபிடித்தான்.
	<br /><br />
	18. அந்நாளிலே அவன் கர்த்தரிடத்தில் இரக்கத்தைக் கண்டடையும்படி, கர்த்தர் அவனுக்கு அனுக்கிரகஞ்செய்வாராக; அவன் எபேசுவிலே செய்த பற்பல உதவிகளையும் நீ நன்றாய் அறிந்திருக்கிறாயே.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* 2 தீமோத்தேயு 1, கைகளை வைப்பதன் மூலம் அவர் பெற்ற கடவுளின் பரிசை தீமிதிப்பதற்கு பவுல் ஊக்குவிப்பதோடு தொடங்குகிறது. கர்த்தராகிய இயேசு கிறிஸ்துவைப் பற்றி சாட்சியமளிப்பதில் வெட்கப்பட வேண்டாம் அல்லது பவுல் சங்கிலியில் இருந்தாலும் வெட்கப்பட வேண்டாம் என்று அவர் தீமோத்தேயுவுக்கு நினைவூட்டினார். பவுலிடம் இருந்து தான் கற்றுக்கொண்ட போதனையின் முறையையும் சரியான கோட்பாட்டையும் கடைப்பிடிக்குமாறு தீமோத்தேயுவிடம் அவர் கட்டளையிடுகிறார்.*
			</h4>
<br /><br />
      </div>
    </div>
    </>
  );
}

export default SecondTimothy1