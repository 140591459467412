import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 1 </title>
	<meta
          name="description"
          content="Isaiah 1 | ஏசாயா 1 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆமோத்சின் குமாரனாகிய ஏசாயா, யூதாவின் ராஜாக்களாகிய உசியா, யோதாம், ஆகாஸ், எசேக்கியா என்பவர்களின் நாட்களில், யூதாவையும் எருசலேமையும் குறித்துக் கண்ட தரிசனம்.
	<br /><br />
	2. வானங்களே, கேளுங்கள்; பூமியே, செவிகொடு; கர்த்தர் பேசுகிறார்; நான் பிள்ளைகளை வளர்த்து ஆதரித்தேன்; அவர்களோ எனக்கு விரோதமாய்க் கலகம்பண்ணினார்கள்.
	<br /><br />
	3. மாடு தன் எஜமானையும், கழுதை தன் ஆண்டவனின் முன்னணையையும் அறியும்; இஸ்ரவேலோ அறிவில்லாமலும், என் ஜனம் உணர்வில்லாமலும் இருக்கிறது என்கிறார்.
	<br /><br />
	4. ஐயோ, பாவமுள்ள ஜாதியும், அக்கிரமத்தால் பாரஞ்சுமந்த ஜனமும், பொல்லாதவர்களின் சந்ததியும், கேடு உண்டாக்குகிற புத்திரருமாயிருக்கிறார்கள்; கர்த்தரை விட்டு, இஸ்ரவேலின் பரிசுத்தருக்குக் கோபமுண்டாக்கி, பின்வாங்கிப்போனார்கள்.
	<br /><br />
	5. இன்னும் நீங்கள் ஏன் அடிக்கப்படவேண்டும்? அதிகம் அதிகமாய் விலகிப்போகிறீர்களே; தலையெல்லாம் வியாதியும், இருதயமெல்லாம் பலட்சயமுமாய் இருக்கிறது.
	<br /><br />
	6. உள்ளங்கால் தொடங்கி உச்சந்தலைமட்டும் அதிலே சுகமேயில்லை; அது காயமும், வீக்கமும், நொதிக்கிற இரணமுமுள்ளது; அது சீழ் பிதுக்கப்படாமலும், கட்டப்படாமலும், எண்ணெயினால் ஆற்றப்படாமலும் இருக்கிறது.
	<br /><br />
	7. உங்கள் தேசம் பாழாயிருக்கிறது; உங்கள் பட்டணங்கள் அக்கினியினால் சுட்டெரிக்கப்பட்டது; உங்கள் நாட்டை அந்நியர் உங்கள் கண்களுக்கு முன்பாகப் பட்சிக்கிறார்கள்; அது அந்நியரால் கவிழ்க்கப்பட்ட பாழ்ந்தேசம்போல் இருக்கிறது.
	<br /><br />
	8. சீயோன் குமாரத்தி திராட்சத்தோட்டத்திலுள்ள ஒரு குச்சுபோலவும், வெள்ளரித்தோட்டத்திலுள்ள ஒரு குடிசைபோலவும், முற்றிக்கைபோடப்பட்ட ஒரு பட்டணம்போலவும் மீந்திருக்கிறாள்.
	<br /><br />
	9. சேனைகளின் கர்த்தர் நமக்குக் கொஞ்சம் மீதியை வைக்காதிருந்தாரானால், நாம் சோதோமைப்போலாகி, கொமோராவுக்கு ஒத்திருப்போம்.
	<br /><br />
	10. சோதோமின் அதிபதிகளே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்; கொமோராவின் ஜனமே, நமது தேவனுடைய வேதத்துக்குச் செவிகொடுங்கள்.
	<br /><br />
	11. உங்கள் பலிகளின் திரள் எனக்கு என்னத்துக்கு என்று கர்த்தர் சொல்லுகிறார்; ஆட்டுக்கடாக்களின் தகனபலிகளும், கொழுத்த மிருகங்களின் நிணமும் எனக்கு அரோசிகமாயிருக்கிறது; காளைகள், ஆட்டுக்குட்டிகள், கடாக்களுடைய இரத்தத்தின்மேல் எனக்குப் பிரியமில்லை.
	<br /><br />
	12. நீங்கள் என் சந்நிதியில் வரும்போது, என் பிராகாரங்களை இப்படி மிதிக்கவேண்டுமென்று உங்களிடத்தில் கேட்டது யார்?
	<br /><br />
	13. இனி வீண் காணிக்கைகளைக் கொண்டுவரவேண்டாம்; தூபங்காட்டுதல் எனக்கு அருவருப்பாயிருக்கிறது; நீங்கள் அக்கிரமத்தோடே ஆசரிக்கிற மாதப்பிறப்பையும், ஓய்வுநாளையும், சபைக்கூட்டத்தையும் நான் இனிச் சகிக்கமாட்டேன்.
	<br /><br />
	14. உங்கள் மாதப்பிறப்புகளையும், உங்கள் பண்டிகைகளையும் என் ஆத்துமா வெறுக்கிறது; அவைகள் எனக்கு வருத்தமாயிருக்கிறது; அவைகளைச் சுமந்து இளைத்துப்போனேன்.
	<br /><br />
	15. நீங்கள் உங்கள் கைகளை விரித்தாலும், என் கண்களை உங்களைவிட்டு மறைக்கிறேன்; நீங்கள் மிகுதியாய் ஜெபம்பண்ணினாலும் கேளேன்; உங்கள் கைகள் இரத்தத்தினால் நிறைந்திருக்கிறது.
	<br /><br />
	16. உங்களைக் கழுவிச் சுத்திகரியுங்கள்; உங்கள் கிரியைகளின் பொல்லாப்பை என் கண்களுக்கு மறைவாக அகற்றிவிட்டு, தீமைசெய்தலை விட்டு ஓயுங்கள்;
	<br /><br />
	17. நன்மைசெய்யப் படியுங்கள்; நியாயத்தைத் தேடுங்கள்; ஒடுக்கப்பட்டவனை ஆதரித்து, திக்கற்ற பிள்ளையின் நியாயத்தையும், விதவையின் வழக்கையும் விசாரியுங்கள்.
	<br /><br />
	18. வழக்காடுவோம் வாருங்கள் என்று கர்த்தர் சொல்லுகிறார்; உங்கள் பாவங்கள் சிவேரென்றிருந்தாலும் உறைந்த மழையைப்போல் வெண்மையாகும்; அவைகள் இரத்தாம்பரச்சிவப்பாயிருந்தாலும் பஞ்சைப்போலாகும்.
	<br /><br />
	19. நீங்கள் மனம்பொருந்திச் செவிகொடுத்தால், தேசத்தின் நன்மையைப் புசிப்பீர்கள்.
	<br /><br />
	20. மாட்டோம் என்று எதிர்த்துநிற்பீர்களாகில் பட்டயத்துக்கு இரையாவீர்கள்; கர்த்தரின் வாய் இதைச் சொல்லிற்று.
	<br /><br />
	21. உண்மையுள்ள நகரம் எப்படி வேசியாய்ப்போயிற்று! அது நியாயத்தால் நிறைந்திருந்தது, நீதி அதில் குடிகொண்டிருந்தது; இப்பொழுதோ அதின் குடிகள் கொலைபாதகர்.
	<br /><br />
	22. உன் வெள்ளி களிம்பாயிற்று; உன் திராட்சரசம் தண்ணீர்க்கலப்பானது.
	<br /><br />
	23. உன் பிரபுக்கள் முரடரும் திருடரின் தோழருமாயிருக்கிறார்கள்; அவர்களில் ஒவ்வொருவனும் பரிதானத்தை விரும்பி, கைக்கூலியை நாடித்திரிகிறான்; திக்கற்ற பிள்ளையின் நியாயத்தை விசாரியார்கள்; விதவையின் வழக்கு அவர்களிடத்தில் ஏறுகிறதில்லை.
	<br /><br />
	24. ஆகையால் சேனைகளின் கர்த்தரும் இஸ்ரவேலின் வல்லவருமாகிய ஆண்டவர் சொல்லுகிறதாவது: ஓகோ, நான் என் சத்துருக்களில் கோபம் ஆறி, என் பகைஞருக்கு நீதியைச் சரிக்கட்டுவேன்.
	<br /><br />
	25. நான் என் கையை உன்னிடமாய்த் திருப்பி, உன் களிம்பு நீங்க உன்னைச் சுத்தமாய்ப் புடமிட்டு, உன் ஈயத்தையெல்லாம் நீக்குவேன்.
	<br /><br />
	26. உன் நியாயாதிபதிகளை முன்னிருந்ததுபோலவும், உன் ஆலோசனைக்காரரை ஆதியில் இருந்ததுபோலவும் திரும்பக் கட்டளையிடுவேன்; பின்பு நீ நீதிபுரம் என்றும், சத்தியநகரம் என்றும் பெயர்பெறுவாய்.
	<br /><br />
	27. சீயோன் நியாயத்தினாலும், அதிலே திரும்பிவருகிறவர்கள் நீதியினாலும் மீட்கப்படுவார்கள்.
	<br /><br />
	28. துரோகிகளும் பாவிகளுமோ ஏகமாய் நொறுங்குண்டுபோவார்கள்; கர்த்தரை விட்டு விலகுகிறவர்கள் நிர்மூலமாவார்கள்.
	<br /><br />
	29. நீங்கள் விரும்பின கர்வாலிமரங்களினிமித்தம் வெட்கப்படுவீர்கள்; நீங்கள் தெரிந்துகொண்ட தோப்புகளினிமித்தம் நாணமடைவீர்கள்.
	<br /><br />
	30. இலையுதிர்ந்த கர்வாலிமரத்தைப்போலவும், தண்ணீரில்லாத தோப்பைப்போலவும் இருப்பீர்கள்.
	<br /><br />
	31. பராக்கிரமசாலி சணற்கூளமும், அவன் கிரியை அக்கினிப்பொறியுமாகி, இரண்டும் அவிப்பாரில்லாமல் ஏகமாய் வெந்துபோம் என்று சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah1