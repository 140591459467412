import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jonah2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோனா 2 </title>
	<meta
          name="description"
          content="Jonah 2 | யோனா 2 | Yona 2 | 
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்த மீனின் வயிற்றிலிருந்து, யோனா தன் தேவனாகிய கர்த்தரை நோக்கி விண்ணப்பம்பண்ணி:
	<br /><br />
	2. என் நெருக்கத்தில் நான் கர்த்தரை நோக்கிக் கூப்பிட்டேன்; அவர் எனக்கு உத்தரவு அருளினார்; நான் பாதாளத்தின் வயிற்றிலிருந்து கூக்குரலிட்டேன், நீர் என் சத்தத்தைக் கேட்டீர்.
	<br /><br />
	3. சமுத்திரத்தின் நடுமையமாகிய ஆழத்திலே நீர் என்னைத் தள்ளிவிட்டீர்; நீரோட்டம் என்னைச் சூழ்ந்துகொண்டது; உம்முடைய வெள்ளங்களும் அலைகளும் எல்லாம் என்மேல் புரண்டது.
	<br /><br />
	4. நான் உமது கண்களுக்கு எதிரே இராதபடிக்குத் தள்ளப்பட்டேன்; ஆகிலும் இன்னமும் உம்முடைய பரிசுத்த ஆலயத்தை நோக்குவேன் என்றேன்.
	<br /><br />
	5. தண்ணீர்கள் பிராணபரியந்தம் என்னை நெருக்கினது; ஆழி என்னைச் சூழ்ந்தது; கடற்பாசி என் தலையைச் சுற்றிக்கொண்டது.
	<br /><br />
	6. பர்வதங்களின் அடிவாரங்கள் பரியந்தமும் இறங்கினேன்; பூமியின் தாழ்ப்பாள்கள் என்றென்றைக்கும் என்னை அடைக்கிறதாயிருந்தது; ஆனாலும் என் தேவனாகிய கர்த்தாவே, நீர் என் பிராணனை அழிவுக்குத் தப்புவித்தீர்.
	<br /><br />
	7. என் ஆத்துமா என்னில் தொய்ந்துபோகையில் கர்த்தரை நினைத்தேன்; அப்பொழுது என் விண்ணப்பம் உமது பரிசுத்த ஆலயத்திலே உம்மிடத்தில் வந்து சேர்ந்தது.
	<br /><br />
	8. பொய்யான மாயையைப் பற்றிக்கொள்ளுகிறவர்கள் தங்களுக்கு வரும் கிருபையைப் போக்கடிக்கிறார்கள்.
	<br /><br />
	9. நானோவெனில் துதியின் சத்தத்தோடே உமக்குப் பலியிடுவேன்; நான் பண்ணின பொருத்தனையைச் செலுத்துவேன்; இரட்சிப்பு கர்த்தருடையது என்றான்.
	<br /><br />
	10. கர்த்தர் மீனுக்குக் கட்டளையிட்டார், அது யோனாவைக் கரையிலே கக்கிவிட்டது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jonah2