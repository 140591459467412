import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial47 = () => {
  const verseNumber = 47;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 47 </title>
	<meta
          name="description"
          content="Ezekiel 47 | எசேக்கியேல்  47 |
          Ezekiel 47 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் என்னை ஆலயத்தின் வாசலுக்குத் திரும்பிவரப்பண்ணினார்; இதோ, வாசற்படியின் கீழிருந்து தண்ணீர் புறப்பட்டுக் கிழக்கே ஓடுகிறதாயிருந்தது; ஆலயத்தின் முகப்பு கிழக்கு நோக்கியிருந்தது; அந்தத் தண்ணீர் ஆலயத்தின் வலதுபுறமாய்ப் பலிபீடத்துக்குத் தெற்கே பாய்ந்தது.
	<br /><br />
	2. அவர் என்னை வடக்கு வாசல் வழியாய்ப் புறப்படப்பண்ணி, என்னை வெளியிலே கீழ்த்திசைக்கு எதிரான புறவாசல்மட்டும் சுற்றி நடத்திக்கொண்டுபோனார்; அங்கே தண்ணீர் வலதுபுறத்திலிருந்து பாய்கிறதாயிருந்தது.
	<br /><br />
	3. அந்தப் புருஷன் தமது கையில் நூலைப் பிடித்துக்கொண்டு, கிழக்கே புறப்படுகையில் ஆயிரமுழம் அளந்து, என்னைத் தண்ணீரைக் கடக்கப்பண்ணினார்; தண்ணீர் கணுக்கால் அளவாயிருந்தது.
	<br /><br />
	4. பின்னும் அவர் ஆயிரமுழம் அளந்து, என்னைத் தண்ணீரைக் கடக்கப்பண்ணினார்; அங்கே தண்ணீர் முழங்கால் அளவாயிருந்தது; பின்னும் அவர் ஆயிரமுழம் அளந்து என்னைக் கடக்கப்பண்ணினார்; அங்கே தண்ணீர் இடுப்பளவாயிருந்தது.
	<br /><br />
	5. பின்னும் அவர் ஆயிரமுழம் அளந்தார்; அங்கே அது நான் கடக்கக்கூடாத நதியாயிருந்தது; தண்ணீர் நீச்சாழமும் கடக்கமுடியாத நதியுமாயிருந்தது.
	<br /><br />
	6. அவர் என்னை நோக்கி: மனுபுத்திரனே, இதைக் கண்டாயா என்று சொல்லி, என்னை நதியோரமாய்த் திரும்ப நடத்திக்கொண்டுபோனார்.
	<br /><br />
	7. நான் நடந்துவருகையில், இதோ, நதியோரத்தில் இக்கரையிலும் அக்கரையிலும் வெகு திரளான விருட்சங்கள் இருந்தது.
	<br /><br />
	8. அவர் என்னை நோக்கி: இந்தத் தண்ணீர் கிழக்குதேசத்துக்குப் புறப்பட்டுப்போய், வனாந்தரவழியாய் ஓடி கடலில் விழும்; இது கடலில் பாய்ந்து, விழுந்தபின்பு, அதின் தண்ணீர் ஆரோக்கியமாகும்.
	<br /><br />
	9. சம்பவிப்பது என்னவென்றால், இந்த நதி போகுமிடமெங்கும் சஞ்சரிக்கும் ஜீவபிராணிகள் யாவும் பிழைக்கும்; இந்தத் தண்ணீர் அங்கே வந்தபடியினால் வெகு ஏராளமான மச்சங்களும் உண்டாயிருக்கும்; இந்த நதி போகுமிடமெங்குமுள்ள யாவும் ஆரோக்கியப்பட்டுப் பிழைக்கும்.
	<br /><br />
	10. அப்பொழுது என்கேதிதுவக்கி எனெக்லாயிம்மட்டும் மீன்பிடிக்கிறவர்கள் அதின் கரையிலே நிற்பார்கள்; அதெல்லாம் வலைகளை விரிக்கிற ஸ்தலமாயிருக்கும்; அதின் மச்சங்கள் பெரிய சமுத்திரத்தின் மச்சங்களைப்போலப் பல ஜாதியும் மகா ஏராளமுமாயிருக்கும்.
	<br /><br />
	11. ஆனாலும் அதினுடைய உளையான பள்ளங்களும் அதினுடைய மடுக்களும் ஆரோக்கியமாகாமல், உப்பாகவே விட்டுவிடப்படும்.
	<br /><br />
	12. நதியோரமாய் அதின் இக்கரையிலும் அக்கரையிலும் புசிப்புக்கான சகலவித விருட்சங்களும் வளரும்; அவைகளின் இலைகள் உதிர்வதுமில்லை, அவைகளின் கனிகள் கெடுவதுமில்லை; அவைகளுக்குப் பாயும் தண்ணீர் பரிசுத்த ஸ்தலத்திலிருந்து பாய்கிறபடியினால் மாதந்தோறும் புதுக்கனிகளைக் கொடுத்துக்கொண்டேயிருக்கும்; அவைகளின் கனிகள் புசிப்புக்கும், அவைகளின் இலைகள் அவிழ்தத்துக்குமானவைகள்.
	<br /><br />
	13. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நீங்கள் இஸ்ரவேலின் பன்னிரண்டு கோத்திரங்களுடைய இலக்கத்தின்படியே தேசத்தைச் சுதந்தரித்துக்கொண்டு குறிக்கவேண்டிய எல்லையாவது: யோசேப்புக்கு இரண்டு பங்கு உண்டு.
	<br /><br />
	14. சகோதரனோடே சகோதரனுக்குச் சரிபங்கு உண்டாக அதைச் சுதந்தரித்துக்கொள்ளக்கடவீர்கள்; அதை உங்கள் பிதாக்களுக்குக் கொடுப்பேன் என்று நான் ஆணையிட்டுக் கொடுத்தேன்; ஆகையால் உங்களுக்கு இந்தத் தேசம் சுதந்தரமாகக் கிடைக்கும்.
	<br /><br />
	15. தேசத்தின் எல்லையாவது: வடபுறம் பெரிய சமுத்திரந்துவக்கி, சேதாதுக்குப் போகிற எத்லோன் வழியாயிருக்கிற,
	<br /><br />
	16. ஆமாத்தும், பேரொத்தாவும், தமஸ்குவின் எல்லைக்கும் ஆமாத்தின் எல்லைக்கும் நடுவான சிப்ராயிமும், ஆப்ரானின் எல்லையோடே சேர்ந்த ஆத்சார் அத்தீகோனுமானது.
	<br /><br />
	17. அப்படியே சமுத்திரத்திலிருந்து போகிற எல்லை ஆத்சார்ஏனானும், தமஸ்குவின் எல்லையும், வடமூலையான வடக்கும் ஆமாத்தின் எல்லையுமானது; இது வடபுறம்.
	<br /><br />
	18. கீழ்ப்புறத்தை ஆப்ரானுக்கும் தமஸ்குவுக்கும் நடுவாகவும், கீலேயாத்துக்கும் யோர்தானின் ஓரமான இஸ்ரவேல் தேசத்துக்கும் நடுவாகவும் கீழ்க்கடல்மட்டும் இருக்கும் எல்லையாய் அளப்பீர்களாக; இது கீழ்ப்புறம்.
	<br /><br />
	19. தென்மூலையான தென்புறம் தாமார் துவக்கி, காதேசிலுள்ள சண்டைமூட்டுதலின் தண்ணீர்கள்மட்டாகவும், ஆறுமட்டாகவும், பெரிய சமுத்திரமட்டாகவும் இருப்பது; இது தென்மூலையான தென்புறம்.
	<br /><br />
	20. மேற்புறம் அந்த எல்லைதுவக்கி ஆமாத்துக்கு எதிராக வந்து சேருமட்டும் இருக்கிற பெரிய சமுத்திரமே; இது மேற்புறம்.
	<br /><br />
	21. இந்தத் தேசத்தை நீங்கள் இஸ்ரவேல் கோத்திரங்களின்படியே உங்களுக்குள்ளே பங்கிட்டுக்கொள்வீர்களாக.
	<br /><br />
	22. உங்களுக்கும், உங்களுக்குள்ளே தங்கி உங்களுக்குள்ளே பிள்ளைகளைப் பெறுகிற அந்நியர்களுக்கும், நீங்கள் அதைச் சீட்டுப்போட்டுச் சுதந்தரமாக்கிக்கொள்வீர்களாக; இவர்கள் உங்களுக்கு இஸ்ரவேல் புத்திரரில் பிறந்தவர்களைப்போல இருந்து, உங்களோடேகூட இஸ்ரவேல் கோத்திரங்களின் நடுவே சுதந்தரத்துக்கு உடன்படுவார்களாக.
	<br /><br />
	23. அந்நியன் எந்தக் கோத்திரத்தோடே தங்கியிருக்கிறானோ, அதிலே அவனுடைய சுதந்தரத்தை அவனுக்குக் கொடுக்கக்கடவீர்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial47