import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Philippians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Book of Philippians Bible in Tamil | பிலிப்பியர் 3 </title>
	<meta
          name="description"
          content="Philippians 3 | பிலிப்பியர் 3 |
          Book of Philippians | Tamil Bible Verse | Tamil Bible | பிலிப்பியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மேலும், என் சகோதரரே, கர்த்தருக்குள் சந்தோஷப்படுங்கள். எழுதினவைகளையே எழுதுவது எனக்கு வருத்தமல்ல, அது உங்களுக்கு நலமாயிருக்கும்.
	<br /><br />
	2. நாய்களுக்கு எச்சரிக்கையாயிருங்கள், பொல்லாத வேலையாட்களுக்கு எச்சரிக்கையாயிருங்கள், சுன்னத்துக்காரருக்கு எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	3. ஏனெனில், மாம்சத்தின்மேல் நம்பிக்கையாயிராமல், ஆவியினாலே தேவனுக்கு ஆராதனைசெய்து, கிறிஸ்து இயேசுவுக்குள் மேன்மைபாராட்டுகிற நாமே விருத்தசேதனமுள்ளவர்கள்.
	<br /><br />
	4. மாம்சத்தின்மேல் நம்பிக்கை வைக்கவேண்டுமானால் நானும் வைக்கலாம்; வேறொருவன் மாம்சத்தின்மேல் நம்பிக்கையாயிருக்க நினைத்தால் நான் அதிகமாய் அப்படிச் செய்யலாம்.
	<br /><br />
	5. நான் எட்டாம் நாளில் விருத்தசேதனமடைந்தவன், இஸ்ரவேல் வம்சத்தான், பென்யமீன் கோத்திரத்தான், எபிரெயரில் பிறந்த எபிரெயன், நியாயப்பிரமாணத்தின்படி பரிசேயன்;
	<br /><br />
	6. பக்திவைராக்கியத்தின்படி சபையைத் துன்பப்படுத்தினவன், நியாயப்பிரமாணத்திற்குரிய நீதியின்படி குற்றஞ்சாட்டப்படாதவன்.
	<br /><br />
	7. ஆகிலும், எனக்கு லாபமாயிருந்தவைகளெவைகளோ அவைகளைக் கிறிஸ்துவுக்காக நஷ்டமென்று எண்ணினேன்.
	<br /><br />
	8. அதுமாத்திரமல்ல, என் கர்த்தராகிய கிறிஸ்து இயேசுவை அறிகிற அறிவின் மேன்மைக்காக எல்லாவற்றையும் நஷ்டமென்று எண்ணிக்கொண்டிருக்கிறேன்.
	<br /><br />
	9. நான் கிறிஸ்துவை ஆதாயப்படுத்திக்கொள்ளும்படிக்கும், நியாயப்பிரமாணத்தினால் வருகிற சுயநீதியை உடையவனாயிராமல், கிறிஸ்துவைப் பற்றும் விசுவாசத்தினால் வருகிறதும் விசுவாசமூலமாய்த் தேவனால் உண்டாயிருக்கிறதுமான நீதியை உடையவனாயிருந்து, கிறிஸ்துவுக்குள் இருக்கிறவனென்று காணப்படும்படிக்கும்,
	<br /><br />
	10. இப்படி நான் அவரையும் அவருடைய உயிர்த்தெழுதலின் வல்லமையையும், அவருடைய பாடுகளின் ஐக்கியத்தையும் அறிகிறதற்கும், அவருடைய மரணத்திற்கொப்பான மரணத்திற்குள்ளாகி, எப்படியாயினும் நான் மரித்தோரிலிருந்து உயிரோடெழுந்திருப்பதற்குத் தகுதியாகும்படிக்கும்,
	<br /><br />
	11. அவருக்காக எல்லாவற்றையும் நஷ்டமென்று விட்டேன்; குப்பையுமாக எண்ணுகிறேன்.
	<br /><br />
	12. நான் அடைந்தாயிற்று, அல்லது முற்றும் தேறினவனானேன் என்று எண்ணாமல், கிறிஸ்து இயேசுவினால் நான் எதற்காகப் பிடிக்கப்பட்டேனோ அதை நான் பிடித்துக்கொள்ளும்படி ஆசையாய்த் தொடருகிறேன்.
	<br /><br />
	13. சகோதரரே, அதைப் பிடித்துக்கொண்டேனென்று நான் எண்ணுகிறதில்லை; ஒன்று செய்கிறேன், பின்னானவைகளை மறந்து, முன்னானவைகளை நாடி,
	<br /><br />
	14. கிறிஸ்து இயேசுவுக்குள் தேவன் அழைத்த பரம அழைப்பின் பந்தயப்பொருளுக்காக இலக்கை நோக்கித் தொடருகிறேன்.
	<br /><br />
	15. ஆகையால், நம்மில் தேறினவர்கள் யாவரும் இந்தச் சிந்தையாயிருக்கக்கடவோம்; எந்தக் காரியத்திலாவது நீங்கள் வேறே சிந்தையாயிருந்தால், அதையும் தேவன் உங்களுக்கு வெளிப்படுத்துவார்.
	<br /><br />
	16. ஆகிலும் நாம் எதுவரையில் தேறியிருக்கிறோமோ, அதுமுதல் ஒரே ஒழுங்காய் நடந்துகொண்டு, ஒரே சிந்தையாயிருப்போமாக.
	<br /><br />
	17. சகோதரரே, நீங்கள் என்னோடேகூடப் பின்பற்றுகிறவர்களாகி, நாங்கள் உங்களுக்கு வழிகாட்டுகிறபடி நடக்கிறவர்களை மாதிரியாக நோக்குங்கள்.
	<br /><br />
	18. ஏனெனில், அநேகர் வேறுவிதமாய் நடக்கிறார்கள்; அவர்கள் கிறிஸ்துவின் சிலுவைக்குப் பகைஞரென்று உங்களுக்கு அநேகந்தரம் சொன்னேன், இப்பொழுது கண்ணீரோடும் சொல்லுகிறேன்.
	<br /><br />
	19. அவர்களுடைய முடிவு அழிவு, அவர்களுடைய தேவன் வயிறு, அவர்களுடைய மகிமை அவர்களுடைய இலச்சையே, அவர்கள் பூமிக்கடுத்தவைகளைச் சிந்திக்கிறார்கள்.
	<br /><br />
	20. நம்முடைய குடியிருப்போ பரலோகத்திலிருக்கிறது, அங்கேயிருந்து கர்த்தராயிருக்கிற இயேசுகிறிஸ்து என்னும் இரட்சகர் வர எதிர்பார்த்துக்கொண்டிருக்கிறோம்.
	<br /><br />
	21. அவர் எல்லாவற்றையும் தமக்குக் கீழ்ப்படுத்திக்கொள்ளத்தக்க தம்முடைய வல்லமையான செயலின்படியே, நம்முடைய அற்பமான சரீரத்தைத் தம்முடைய மகிமையான சரீரத்திற்கு ஒப்பாக மறுரூபப்படுத்துவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Philippians3