import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Galatians5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | கலாத்தியருக்கு எழுதின நிருபம் 5 </title>
	<meta
          name="description"
          content="Galatians | கலாத்தியர் அதிகாரம் 5 | கலாத்தியருக்கு எழுதின நிருபம் |
          Book of Galatians | Chapter 5 | Tamil Bible Verse | Tamil Bible | புதிய ஏற்பாடு | TamilBible | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆனபடியினாலே, நீங்கள் மறுபடியும் அடிமைத்தனத்தின் நுகத்துக்குட்படாமல், கிறிஸ்து நமக்கு உண்டாக்கின சுயாதீன நிலைமையிலே நிலைகொண்டிருங்கள்.
	<br /><br />
	2. இதோ, நீங்கள் விருத்தசேதனம்பண்ணிக்கொண்டால் கிறிஸ்துவினால் உங்களுக்கு ஒரு பிரயோஜனமுமிராது என்று பவுலாகிய நான் உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	3. மேலும், விருத்தசேதனம்பண்ணிக்கொள்ளுகிற எந்த மனுஷனும் நியாயப்பிரமாணம் முழுவதையும் நிறைவேற்றக் கடனாளியாயிருக்கிறான் என்று மறுபடியும் அப்படிப்பட்டவனுக்குச் சாட்சியாகச் சொல்லுகிறேன்.
	<br /><br />
	4. நியாயப்பிரமாணத்தினால் நீதிமான்களாக விரும்புகிற நீங்கள் யாவரும் கிறிஸ்துவைவிட்டுப் பிரிந்து கிருபையினின்று விழுந்தீர்கள்.
	<br /><br />
	5. நாங்களோ நீதிகிடைக்குமென்று ஆவியைக்கொண்டு விசுவாசத்தினால் நம்பிக்கையோடே காத்திருக்கிறோம்.
	<br /><br />
	6. கிறிஸ்து இயேசுவினிடத்தில் விருத்தசேதனமும் விருத்தசேதனமில்லாமையும் ஒன்றுக்கும் உதவாது, அன்பினால் கிரியைசெய்கிற விசுவாசமே உதவும்.
	<br /><br />
	7. நீங்கள் நன்றாய் ஓடினீர்களே; சத்தியத்திற்குக் கீழ்ப்படியாமற்போக உங்களுக்குத் தடைசெய்தவன் யார்?
	<br /><br />
	8. இந்தப் போதனை உங்களை அழைத்தவரால் உண்டானதல்ல.
	<br /><br />
	9. புளிப்புள்ள கொஞ்சமாவானது பிசைந்த மாவனைத்தையும் உப்பப்பண்ணும்.
	<br /><br />
	10. நீங்கள் வேறுவிதமாய்ச் சிந்திக்கமாட்டீர்களென்று நான் கர்த்தருக்குள் உங்களைக்குறித்து நம்பிக்கையாயிருக்கிறேன்; உங்களைக் கலக்குகிறவன் எப்படிப்பட்டவனாயிருந்தாலும் தனக்கேற்ற ஆக்கினையை அடைவான்.
	<br /><br />
	11. சகோதரரே, இதுவரைக்கும் நான் விருத்தசேதனத்தைப் பிரசங்கிக்கிறவனாயிருந்தால், இதுவரைக்கும் என்னத்திற்குத் துன்பப்படுகிறேன்? அப்படியானால் சிலுவையைப்பற்றி வரும் இடறல் ஒழிந்திருக்குமே.
	<br /><br />
	12. உங்களைக் கலக்குகிறவர்கள் தறிப்புண்டுபோனால் நலமாயிருக்கும்.
	<br /><br />
	13. சகோதரரே, நீங்கள் சுயாதீனத்திற்கு அழைக்கப்பட்டீர்கள், இந்தச் சுயாதீனத்தை நீங்கள் மாம்சத்திற்கேதுவாக அநுசரியாமல், அன்பினாலே ஒருவருக்கொருவர் ஊழியஞ்செய்யுங்கள்.
	<br /><br />
	14. உன்னிடத்தில் நீ அன்புகூருவதுபோலப் பிறனிடத்திலும் அன்புகூருவாயாக, என்கிற இந்த ஒரே வார்த்தையிலே நியாயப்பிரமாணம் முழுவதும் நிறைவேறும்.
	<br /><br />
	15. நீங்கள் ஒருவரையொருவர் கடித்துப் பட்சித்தீர்களானால் அழிவீர்கள், அப்படி ஒருவராலொருவர் அழிக்கப்படாதபடிக்கு எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	16. பின்னும் நான் சொல்லுகிறதென்னவென்றால், ஆவிக்கேற்றபடி நடந்துகொள்ளுங்கள், அப்பொழுது மாம்ச இச்சையை நிறைவேற்றாதிருப்பீர்கள்.
	<br /><br />
	17. மாம்சம் ஆவிக்கு விரோதமாகவும், ஆவி மாம்சத்துக்கு விரோதமாகவும் இச்சிக்கிறது; நீங்கள் செய்யவேண்டுமென்றிருக்கிறவைகளைச் செய்யாதபடிக்கு, இவைகள் ஒன்றுக்கொன்று விரோதமாயிருக்கிறது.
	<br /><br />
	18. ஆவியினால் நடத்தப்படுவீர்களானால், நீங்கள் நியாயப்பிரமாணத்திற்குக் கீழ்ப்பட்டவர்களல்ல.
	<br /><br />
	19. மாம்சத்தின் கிரியைகள் வெளியரங்கமாயிருக்கின்றன; அவையாவன: விபசாரம், வேசித்தனம், அசுத்தம், காமவிகாரம்,
	<br /><br />
	20. விக்கிரகாராதனை, பில்லிசூனியம், பகைகள், விரோதங்கள், வைராக்கியங்கள், கோபங்கள், சண்டைகள், பிரிவினைகள், மார்க்கபேதங்கள்,
	<br /><br />
	21. பொறாமைகள், கொலைகள், வெறிகள், களியாட்டுகள் முதலானவைகளே; இப்படிப்பட்டவைகளைச் செய்கிறவர்கள் தேவனுடைய ராஜ்யத்தைச் சுதந்தரிப்பதில்லையென்று முன்னே நான் சொன்னதுபோல இப்பொழுதும் உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	22. ஆவியின் கனியோ, அன்பு, சந்தோஷம், சமாதானம், நீடியபொறுமை, தயவு, நற்குணம், விசுவாசம்,
	<br /><br />
	23. சாந்தம், இச்சையடக்கம்; இப்படிப்பட்டவைகளுக்கு விரோதமான பிரமாணம் ஒன்றுமில்லை.
	<br /><br />
	24. கிறிஸ்துவினுடையவர்கள் தங்கள் மாம்சத்தையும் அதின் ஆசை இச்சைகளையும் சிலுவையில் அறைந்திருக்கிறார்கள்.
	<br /><br />
	25. நாம் ஆவியினாலே பிழைத்திருந்தால், ஆவிக்கேற்றபடி நடக்கவும் கடவோம்.
	<br /><br />
	26. வீண் புகழ்ச்சியை விரும்பாமலும், ஒருவரையொருவர் கோபமூட்டாமலும், ஒருவர்மேல் ஒருவர் பொறாமைகொள்ளாமலும் இருக்கக்கடவோம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Galatians5