import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 10 </title>
	<meta
          name="description"
          content="Ezekiel 10 | எசேக்கியேல்  10 |
          Ezekiel 10 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, கேருபீன்களுடைய தலைக்குமேல் இருந்த மண்டலத்தில் இந்திரநீலரத்தினம்போன்ற சிங்காசனச் சாயலான ஒரு தோற்றத்தைக் கண்டேன்; அது அவைகளுக்குமேல் காணப்பட்டது.
	<br /><br />
	2. அவர் சணல்நூல் அங்கி தரித்திருந்த புருஷனை நோக்கி: நீ கேருபீனின்கீழ் இருக்கிற சக்கரங்களுக்கு நடுவிலே பிரவேசித்து, கேருபீன்களின் நடுவே இருக்கிற அக்கினித்தழலில் உன் கை நிறைய எடுத்து, அதை நகரத்தின்மேல் இறையென்றார்; அப்படியே அவன் என் கண் காண உள்ளே பிரவேசித்தான்.
	<br /><br />
	3. அந்தப் புருஷன் உள்ளே பிரவேசிக்கையில், கேருபீன்கள் ஆலயத்தின் வலதுபுறத்தில் நின்றன; ஒரு மேகம் உட்பிராகாரத்தை நிரப்பிற்று.
	<br /><br />
	4. கர்த்தருடைய மகிமை கேருபீனின் மேலிருந்து எழும்பி, ஆலயத்தின் வாசற்படியிலே வந்தது; ஆலயம் மேகத்தினாலே நிறைந்திருந்தது, பிராகாரமும் கர்த்தருடைய மகிமையின் பிரகாசத்தினால் நிரம்பிற்று.
	<br /><br />
	5. கேருபீன்களுடைய செட்டைகளின் இரைச்சல் சர்வத்துக்கும் வல்ல தேவன் பேசுகையில் உண்டாகும் சத்தம்போல வெளிப்பிராகாரமட்டும் கேட்கப்பட்டது.
	<br /><br />
	6. அவர் சணல்நூல் அங்கி தரித்திருந்த புருஷனை நோக்கி: நீ கேருபீன்களுக்குள் சக்கரங்களின் நடுவிலிருந்து அக்கினியை எடு என்று கட்டளையிட்டவுடனே, அவன் உள்ளே பிரவேசித்துச் சக்கரங்களண்டையிலே நின்றான்.
	<br /><br />
	7. அப்பொழுது கேருபீன்களுக்குள்ளே ஒரு கேருபீன் தன் கையைக் கேருபீன்களின் நடுவில் இருக்கிற அக்கினியில் நீட்டி, அதில் எடுத்து, சணல்நூல் அங்கி தரித்திருந்த புருஷனுடைய கையில் கொடுத்தான்; அவன் அதை வாங்கிக்கொண்டு வெளியே வந்தான்.
	<br /><br />
	8. கேருபீன்களுடைய செட்டைகளின் கீழ் மனுஷர் கையின் சாயலானது காணப்பட்டது.
	<br /><br />
	9. இதோ, கேருபீன்களண்டையில் நாலு சக்கரங்கள் இருக்கக் கண்டேன்; ஒவ்வொரு கேருபீன் அண்டையில் ஒவ்வொரு சக்கரம் இருந்தது; சக்கரங்களின் தோற்றம் படிகப்பச்சை வருணம்போலிருந்தது.
	<br /><br />
	10. அவைகள் நாலுக்கும் ஒரே சாயலான ரூபம் இருந்தது; சக்கரங்களின் நடுவிலே சக்கரம் இருக்குமாப்போல் காணப்பட்டது.
	<br /><br />
	11. அவைகள் ஓடுகையில் தங்கள் நாலு பக்கங்களிலும் ஓடும்; ஓடுகையில் அவைகள் திரும்பினதில்லை; தலைநோக்கும் இடத்துக்கே அவைகள் அதின் பின்னாலே ஓடின; ஓடுகையில் அவைகள் திரும்பினதில்லை.
	<br /><br />
	12. அவைகளின் உடல் அனைத்தும், அவைகளின் முதுகுகளும், அவைகளின் கைகளும், அவைகளின் செட்டைகளும், அந்தச் சக்கரங்களும், சுற்றிலும் கண்களினாலே நிறைந்திருந்தன; அவைகள் நாலுக்கும் இருந்த சக்கரங்களும் அப்படியே இருந்தன.
	<br /><br />
	13. அந்தச் சக்கரங்களைப் பார்த்து: சக்கரமே என்று ஒருவன் கூப்பிடுகிற சத்தத்தைக் கேட்டேன்.
	<br /><br />
	14. ஒவ்வொன்றுக்கும் நாலு முகங்கள் இருந்தன; முதலாம் முகம் கேருபீன் முகமும், இரண்டாம் முகம் மனுஷமுகமும், மூன்றாம் முகம் சிங்கமுகமும், நாலாம் முகம் கழுகுமுகமுமாயிருந்தது.
	<br /><br />
	15. கேருபீன்கள் மேலே எழும்பின; இதுதான், நான் கேபார் நதியண்டையிலே கண்டிருந்த ஜீவன்.
	<br /><br />
	16. கேருபீன்கள் செல்லுகையில் சக்கரங்கள் அவைகள் அருகே ஓடின; பூமியிலிருந்து எழும்பக் கேருபீன்கள் தங்கள் செட்டைகளை விரித்தபோது, சக்கரங்களும் அவைகளை விட்டு விலகிப்போகவில்லை.
	<br /><br />
	17. அவைகள் நிற்கையில் இவைகளும் நின்றன; அவைகள் எழும்புகையில் இவைகளும் எழும்பின; ஜீவனுடைய ஆவி இவைகளில் இருந்தது.
	<br /><br />
	18. கர்த்தருடைய மகிமை ஆலயத்தின் வாசற்படியைவிட்டுப் புறப்பட்டு, கேருபீன்களின்மேல் நின்றது.
	<br /><br />
	19. அப்பொழுது கேருபீன்கள் தங்கள் செட்டைகளை விரித்து, என் கண் காணப் பூமியைவிட்டு எழும்பின; அவைகள் புறப்படுகையில் சக்கரங்களும் அவைகளுக்குச் சரியாய்ச் சென்றன; கர்த்தருடைய ஆலயத்தின் கிழக்கு வாசலிலே போய் நிற்க, இஸ்ரவேலின் தேவனுடைய மகிமை அவைகளின்மேல் உயர இருந்தது.
	<br /><br />
	20. இது நான் கேபார் நதியண்டையிலே இஸ்ரவேலின் தேவனுக்குக் கீழே இருக்கக்கண்ட அந்த ஜீவன்தானே; அவைகள் கேருபீன்கள் என்று அறிந்துகொண்டேன்.
	<br /><br />
	21. அவைகளில் ஒவ்வொன்றுக்கும் நாலு முகமும், ஒவ்வொன்றுக்கும் நாலு செட்டைகளும் இருந்தன; அவைகளுடைய செட்டைகளின் கீழ் மனுஷகைகளின் சாயல் இருந்தது.
	<br /><br />
	22. அவைகளுடைய முகங்கள் நான் கேபார் நதியண்டையிலே கண்டிருந்த அந்த முகங்களின் சாயலாயிருந்தது; ஒவ்வொன்றும் தன்தன் முகத்துக்கு எதிரான திசையை நோக்கிச் சென்றது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial10