import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah38 = () => {
  const verseNumber = 38;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 38 </title>
	<meta
          name="description"
          content="Jeremiah 38 | எரேமியா 38 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இந்த நகரத்திலே தரித்திருக்கிறவன், பட்டயத்தாலும், பஞ்சத்தாலும், கொள்ளைநோயாலும் சாவான்; கல்தேயரிடத்துக்குப் புறப்பட்டுப்போகிறவனோ உயிரோடிருப்பான்; அவனுடைய பிராணன் அவனுக்குக் கிடைத்த கொள்ளையுடைமையைப்போலிருக்கும்; அவன் பிழைப்பானென்பதைக் கர்த்தர் உரைக்கிறார் என்றும்,
	<br /><br />
	2. இந்த நகரம் பாபிலோன் ராஜாவினுடைய இராணுவத்தின் கையில் நிச்சயமாக ஒப்புக்கொடுக்கப்படும்; அவன் அதைப் பிடிப்பானென்பதைக் கர்த்தர் உரைக்கிறார் என்றும்,
	<br /><br />
	3. எரேமியா எல்லா ஜனத்தோடும் சொல்லிக்கொண்டிருந்த வார்த்தைகளை மாத்தானின் குமாரனாகிய செப்பத்தியாவும், பஸ்கூரின் குமாரனாகிய கெதலியாவும், செலேமியாவின் குமாரனாகிய யூகாலும், மல்கியாவின் குமாரனாகிய பஸ்கூரும் கேட்டார்கள்.
	<br /><br />
	4. அப்பொழுது பிரபுக்கள் ராஜாவை நோக்கி: இந்த மனுஷன் கொல்லப்பட உத்தரவாகவேண்டும்; அதேனென்றால், இந்த நகரத்தில் மீதியாயிருக்கிற யுத்தமனுஷரிடத்திலும், மற்றுமுள்ள சகல ஜனங்களிடத்திலும், இவன் இப்படிப்பட்ட வார்த்தைகளைச் சொல்லுகிறதினாலே அவர்களுடைய கைகளைத் தளர்ந்துபோகப்பண்ணுகிறான்; இவன் இந்த ஜனத்தின் க்ஷேமத்தைத் தேடாமல், அவர்கள் கேட்டையே தேடுகிறான் என்றார்கள்.
	<br /><br />
	5. அப்பொழுது சிதேக்கியா ராஜா: இதோ, அவன் உங்கள் கைகளில் இருக்கிறான்; உங்களுக்கு விரோதமாய் ராஜா ஒன்றும் செய்யக்கூடாது என்றான்.
	<br /><br />
	6. அப்பொழுது அவர்கள் எரேமியாவைப் பிடித்து, அவனைக் காவற்சாலையின் முற்றத்திலிருந்த அம்மெலேகின் குமாரனாகிய மல்கியாவினுடைய துரவிலே போட்டார்கள்; எரேமியாவைக் கயிறுகளினால் அதிலே இறக்கிவிட்டார்கள்; அந்தத் துரவிலே தண்ணீர் இல்லாமல் உளையாயிருந்தது, அந்த உளையிலே எரேமியா அமிழ்ந்தினான்.
	<br /><br />
	7. அவர்கள் எரேமியாவைத் துரவிலே போட்டதை ராஜாவின் அரமனையில் இருந்த எத்தியோப்பியனாகிய எபெத்மெலேக் என்னும் ஒரு பிரதானி கேள்விப்பட்டான்; ராஜாவோ பென்யமீன் வாசலிலே உட்கார்ந்திருந்தான்.
	<br /><br />
	8. அப்பொழுது எபெத்மெலேக் ராஜாவின் அரமனையிலிருந்து புறப்பட்டுப்போய், ராஜாவை நோக்கி:
	<br /><br />
	9. ராஜாவாகிய என் ஆண்டவனே, இந்தப் புருஷர் எரேமியா தீர்க்கதரிசியைத் துரவிலே போட்டுச் செய்தது எல்லாம் தகாத செய்கையாயிருக்கிறது; அவன் இருக்கிற இடத்திலே பட்டினியினால் சாவானே, இனி நகரத்திலே அப்பமில்லை என்றான்.
	<br /><br />
	10. அப்பொழுது ராஜா எபெத்மெலேக் என்னும் எத்தியோப்பியனை நோக்கி: நீ இவ்விடத்திலிருந்து முப்பது மனுஷரை உன்னுடனே கூட்டிக்கொண்டுபோய், எரேமியா தீர்க்கதரிசிசாகாததற்குமுன்னே அவனைத் துரவிலிருந்து தூக்கிவிடு என்று கட்டளையிட்டான்.
	<br /><br />
	11. அப்பொழுது எபெத்மெலேக் அந்த மனுஷரைத் தன்னுடனே கூட்டிக்கொண்டு, ராஜாவின் அரமனைப் பொக்கிஷசாலையின் கீழிருந்த அறைக்குள் புகுந்து, கிழிந்துபோன பழம்புடவைகளையும் கந்தைத்துணிகளையும் எடுத்துக்கொண்டுபோய், அவைகளைக் கயிறுகளினால் எரேமியாவண்டைக்குத் துரவிலே இறக்கிவிட்டு,
	<br /><br />
	12. எபெத்மெலேக் என்னும் எத்தியோப்பியன் எரேமியாவுடனே: கிழிந்துபோன இந்தப் பழம்புடவைகளையும் கந்தைகளையும் உம்முடைய அக்குள்களில் கயிறுகளுக்குள் அடங்கவைத்துப் போட்டுக்கொள்ளும் என்றான்; எரேமியா அப்படியே செய்தான்.
	<br /><br />
	13. அப்பொழுது எரேமியாவைக் கயிறுகளால் தூக்கி, அவனைத் துரவிலிருந்து எடுத்துவிட்டார்கள்; எரேமியா காவற்சாலையின் முற்றத்தில் இருந்தான்.
	<br /><br />
	14. பின்பு சிதேக்கியா ராஜா, எரேமியா தீர்க்கதரிசியைக் கர்த்தருடைய ஆலயத்தில் இருக்கும் மூன்றாம் வாசலிலே தன்னிடத்திற்கு வரவழைத்தான்; அங்கே ராஜா, எரேமியாவைநோக்கி: நான் உன்னிடத்தில் ஒரு காரியம் கேட்கிறேன்; நீ எனக்கு ஒன்றும் மறைக்கவேண்டாம் என்றான்.
	<br /><br />
	15. அப்பொழுது எரேமியா சிதேக்கியாவை நோக்கி: நான் அதை உமக்கு அறிவித்தால் என்னை நிச்சயமாய்க் கொலைசெய்வீரல்லவா? நான் உமக்கு ஆலோசனை சொன்னாலும், என் சொல்லைக் கேட்கமாட்டீர் என்றான்.
	<br /><br />
	16. அப்பொழுது சிதேக்கியா ராஜா: நான் உன்னைக் கொல்லாமலும், உன் பிராணனை வாங்கத்தேடுகிற இந்த மனுஷர் கையில் உன்னை ஒப்புக்கொடாமலும் இருப்பேன் என்பதை, நமக்கு இந்த ஆத்துமாவை உண்டுபண்ணின கர்த்தருடைய ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று எரேமியாவுக்கு இரகசியமாய் ஆணையிட்டான்.
	<br /><br />
	17. அப்பொழுது எரேமியா சிதேக்கியாவை நோக்கி: நீர் பாபிலோன் ராஜாவின் பிரபுக்களண்டைக்குப் புறப்பட்டுப்போவீரானால், உம்முடைய ஆத்துமா உயிரோடிருக்கும்; இந்தப்பட்டணம் அக்கினியால் சுட்டெரிக்கப்படுவதில்லை; நீரும் உம்முடைய குடும்பமும் உயிரோடிருப்பீர்கள்.
	<br /><br />
	18. நீர் பாபிலோன் ராஜாவின் பிரபுக்களிடத்திற்குப் புறப்பட்டுப்போகாவிட்டால், அப்பொழுது இந்த நகரம் கல்தேயர் கையில் ஒப்புக்கொடுக்கப்படும், அவர்கள் இதை அக்கினியால் சுட்டெரிப்பார்கள்; நீர் அவர்களுக்குத் தப்பிப்போவதில்லை என்கிறதை இஸ்ரவேலின் தேவனும் சேனைகளின் தேவனுமாகிய கர்த்தர் சொல்லுகிறார் என்றான்.
	<br /><br />
	19. அப்பொழுது சிதேக்கியா ராஜா எரேமியாவை நோக்கி: கல்தேயர் தங்களைச் சேர்ந்துபோன யூதரின் கையிலே என்னைப் பரியாசம்பண்ண ஒப்புக்கொடுப்பார்களோ என்று நான் ஐயப்படுகிறேன் என்றான்.
	<br /><br />
	20. அதற்கு எரேமியா: உம்மை ஒப்புக்கொடார்கள்; நான் உம்மிடத்தில் சொல்லுகிற கர்த்தருடைய வார்த்தைக்குச் செவிகொடும், அப்பொழுது உமக்கு நன்மையாயிருக்கும், உம்முடைய ஆத்துமா பிழைக்கும்.
	<br /><br />
	21. நான் புறப்படுகிறதில்லை என்பீரேயாகில், கர்த்தர் எனக்குத் தெரியப்படுத்தின வார்த்தையாவது:
	<br /><br />
	22. இதோ, யூதா ராஜாவின் வீட்டிலே மீதியான எல்லா ஸ்திரீகளும் வெளியே பாபிலோன் ராஜாவின் பிரபுக்களண்டையில் கொண்டுபோகப்படுவார்கள்; அப்பொழுது, இதோ, அவர்கள்தானே உம்முடைய இஷ்டர்கள்; அவர்கள் உமக்குப் போதனைசெய்து, உம்மை மேற்கொண்டார்கள் என்றும், உம்முடைய கால்கள் உளையிலே அமிழ்ந்தினபின்பு அவர்கள் பின்வாங்கிப்போனார்கள் என்றும் அந்த ஸ்திரீகளே சொல்லுவார்கள்.
	<br /><br />
	23. உம்முடைய எல்லா ஸ்திரீகளையும், உம்முடைய பிள்ளைகளையும் வெளியே கல்தேயரிடத்தில் கொண்டுபோவார்கள்; நீரும் அவர்கள் கைக்குத் தப்பிப்போகாமல் பாபிலோன் ராஜாவின் கையினால் பிடிக்கப்பட்டு, இந்த நகரம் அக்கினியால் சுட்டெரிக்கப்படக் காரணமாயிருப்பீர் என்றான்.
	<br /><br />
	24. அப்பொழுது சிதேக்கியா எரேமியாவை நோக்கி: இந்த வார்த்தைகளை ஒருவருக்கும் அறிவிக்கவேண்டாம்; அப்பொழுது நீ சாவதில்லை.
	<br /><br />
	25. நான் உன்னோடே பேசினதைப் பிரபுக்கள் கேள்விப்பட்டு, உன்னிடத்தில் வந்து: நீ ராஜாவோடே பேசிக் கொண்டதை எங்களுக்குத் தெரிவி, எங்களுக்கு ஒன்றும் மறைக்காதே, அப்பொழுது உன்னைக் கொல்லாதிருப்போம்; ராஜா உன்னோடு என்ன பேசினார் என்று உன்னைக் கேட்பார்களேயாகில்,
	<br /><br />
	26. நான் யோனத்தானுடைய வீட்டிலே சாகாதபடிக்கு ராஜா என்னை அங்கே திரும்ப அனுப்பவேண்டாம் என்று, அவர் முகத்துக்கு முன்பாக விண்ணப்பம்பண்ணினேன் என்று சொல்வாயாக என்றான்.
	<br /><br />
	27. பின்பு எல்லாப் பிரபுக்களும், எரேமியாவினிடத்தில் வந்து, அவனைக் கேட்டார்கள்; அப்பொழுது அவன்: ராஜா கற்பித்த இந்த எல்லா வார்த்தைகளின்படியே அவர்களுக்கு அறிவித்தான்; காரியம் கேள்விப்படாமற்போனபடியினால், அவனோடே பேசாமலிருந்துவிட்டார்கள்.
	<br /><br />
	28. அப்படியே எரேமியா, எருசலேம் பிடிபடுகிற நாள்மட்டாகக் காவற்சாலையின் முற்றத்தில் இருந்தான்; எருசலேம் பிடிபட்டுப்போனபோதும் அங்கேயே இருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah38