import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> The Believer's Rest Hebrews- 4 Tamil Bible Verse | எபிரெயர் 4 </title>
	<meta
          name="description"
          content="The Believer's Rest Hebrews Chapter-4 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil Verse"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* விசுவாசிகளின் ஓய்வு *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. ஆனபடியினாலே, அவருடைய இளைப்பாறுதலில் பிரவேசிப்பதற்கேதுவான வாக்குத்தத்தம் நமக்குண்டாயிருக்க, உங்களில் ஒருவனும் அதை அடையாமல் பின்வாங்கிப்போனவனாகக் காணப்படாதபடிக்குப் பயந்திருக்கக்கடவோம்.
	<br /><br />
	2. ஏனெனில், சுவிசேஷம் அவர்களுக்கு அறிவிக்கப்பட்டதுபோல நமக்கும் அறிவிக்கப்பட்டது; கேட்டவர்கள் விசுவாசமில்லாமல் கேட்டபடியினால், அவர்கள் கேட்ட வசனம் அவர்களுக்குப் பிரயோஜனப்படவில்லை.
	<br /><br />
	3. விசுவாசித்தவர்களாகிய நாமோ அந்த இளைப்பாறுதலில் பிரவேசிக்கிறோம்; அவருடைய கிரியைகள் உலகத்தோற்றமுதல் முடிந்திருந்தும்: இவர்கள் என்னுடைய இளைப்பாறுதலில் பிரவேசிப்பதில்லையென்று என்னுடைய கோபத்திலே ஆணையிட்டேன் என்றார்.
	<br /><br />
	4. மேலும், தேவன் தம்முடைய கிரியைகளையெல்லாம் முடித்து ஏழாம் நாளிலே ஓய்ந்திருந்தார் என்று ஏழாம் நாளைக்குறித்து ஓரிடத்தில் சொல்லியிருக்கிறார்.
	<br /><br />
	5. அன்றியும், அவர்கள் என்னுடைய இளைப்பாறுதலில் பிரவேசிப்பதில்லை என்றும் அந்த இடத்திலேதானே சொல்லியிருக்கிறார்.
	<br /><br />
	6. ஆகையால், சிலர் அதில் பிரவேசிப்பது இன்னும் வரப்போகிற காரியமாயிருக்கிறபடியினாலும், சுவிசேஷத்தை முதலாவது கேட்டவர்கள் கீழ்ப்படியாமையினாலே அதில் பிரவேசியாமற்போனபடியினாலும்,
	<br /><br />
	7. இன்று அவருடைய சத்தத்தைக் கேட்பீர்களாகில் உங்கள் இருதயங்களைக் கடினப்படுத்தாதிருங்கள் என்று வெகுகாலத்திற்குப்பின்பு தாவீதின் சங்கீதத்திலே சொல்லியிருக்கிறபடி, இன்று என்று சொல்வதினாலே பின்னும் ஒருநாளைக் குறித்திருக்கிறார்.
	<br /><br />
	8. யோசுவா அவர்களை இளைப்பாறுதலுக்குட்படுத்தியிருந்தால், பின்பு அவர் வேறொரு நாளைக்குறித்துச் சொல்லியிருக்கமாட்டாரே.
	<br /><br />
	9. ஆகையால், தேவனுடைய ஜனங்களுக்கு இளைப்பாறுகிற காலம் இனி வருகிறதாயிருக்கிறது.
	<br /><br />
	10. ஏனெனில், அவருடைய இளைப்பாறுதலில் பிரவேசித்தவன், தேவன் தம்முடைய கிரியைகளை முடித்து ஓய்ந்ததுபோல, தானும் தன் கிரியைகளை முடித்து ஓய்ந்திருப்பான்.
	<br /><br />
	11. ஆகையால், அந்தத் திருஷ்டாந்தத்தின்படி, ஒருவனாகிலும் கீழ்ப்படியாமையினாலே விழுந்துபோகாதபடிக்கு, நாம் இந்த இளைப்பாறுதலில் பிரவேசிக்க ஜாக்கிரதையாயிருக்கக்கடவோம்.
	<br /><br />
	12. தேவனுடைய வார்த்தையானது ஜீவனும் வல்லமையும் உள்ளதாயும், இருபுறமும் கருக்குள்ள எந்தப் பட்டயத்திலும் கருக்கானதாயும், ஆத்துமாவையும் ஆவியையும், கணுக்களையும் ஊனையும் பிரிக்கத்தக்கதாக உருவக் குத்துகிறதாயும், இருதயத்தின் நினைவுகளையும் யோசனைகளையும் வகையறுக்கிறதாயும் இருக்கிறது.
	<br /><br />
	13. அவருடைய பார்வைக்கு மறைவான சிருஷ்டி ஒன்றுமில்லை; சகலமும் அவருடைய கண்களுக்குமுன்பாக நிர்வாணமாயும் வெளியரங்கமாயுமிருக்கிறது, அவருக்கே நாம் கணக்கு ஒப்புவிக்கவேண்டும்.
	<br /><br />
	14. வானங்களின் வழியாய்ப் பரலோகத்திற்குப்போன தேவகுமாரனாகிய இயேசு என்னும் மகா பிரதான ஆசாரியர் நமக்கு இருக்கிறபடியினால், நாம் பண்ணின அறிக்கையை உறுதியாய்ப் பற்றிக்கொண்டிருக்கக்கடவோம்.
	<br /><br />
	15. நம்முடைய பலவீனங்களைக்குறித்துப் பரிதபிக்கக்கூடாத பிரதான ஆசாரியர் நமக்கிராமல், எல்லாவிதத்திலும் நம்மைப்போல் சோதிக்கப்பட்டும், பாவமில்லாதவராயிருக்கிற பிரதான ஆசாரியரே நமக்கிருக்கிறார்.
	<br /><br />
	16. ஆதலால், நாம் இரக்கத்தைப் பெறவும், ஏற்ற சமயத்தில் சகாயஞ்செய்யுங் கிருபையை அடையவும், தைரியமாய்க் கிருபாசனத்தண்டையிலே சேரக்கடவோம்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* கிறிஸ்துவில் விசுவாசத்தின் மூலம் தேவனுடைய மக்களுக்கு உண்மையான ஓய்வுநாளை வழங்குகிறது. கடவுளுடைய மக்களுக்கு இந்த ஓய்வு பழைய ஏற்பாடு முழுவதும் வாக்குறுதியளிக்கப்பட்டது. உண்மையான சப்பாத் ஓய்வு என்பது சட்டத்தை கடைப்பிடிப்பதாலோ அல்லது வாரத்தில் ஒரு நாள் வேலையில் இருந்து ஓய்வு எடுப்பதாலோ வருவதில்லை. விசுவாசிகளின் ஓய்வு கிறிஸ்துவுடன் கூட்டுறவு கொள்வதன் மூலம் நம் வாழ்வில் நிறைவு பெறுகிறது. *
			</h4>
<br /><br />
      </div>
    </div>
    </>
  );
}

export default Hebrews4