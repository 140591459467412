import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah29 = () => {
  const verseNumber = 29;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 29 </title>
	<meta
          name="description"
          content="Jeremiah 29 | எரேமியா 29 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எகொனியா என்னும் ராஜாவும், ராஜஸ்திரீயும், பிரதானிகளும், யூதாவிலும் எருசலேமிலுமுள்ள பிரபுக்களும், தச்சரும், கொல்லரும் எருசலேமைவிட்டுப் புறப்பட்டுப்போன பிற்பாடு,
	<br /><br />
	2. எரேமியா தீர்க்கதரிசி சிறைப்பட்டுப்போன மூப்பர்களில் மீதியானவர்களுக்கும், ஆசாரியர்களுக்கும், தீர்க்கதரிசிகளுக்கும், நேபுகாத்நேச்சார் சிறைப்படுத்தி எருசலேமிலிருந்து பாபிலோனுக்குக் கொண்டுபோன சகல ஜனங்களுக்கும் எழுதி,
	<br /><br />
	3. யூதாவின் ராஜாவாகிய சிதேக்கியா பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரிடத்தில் கொடுக்கும்படி சாப்பானின் குமாரனாகிய எலெயாசாரின் கையிலும், இல்க்கியாவின் குமாரனாகிய கெமரியாவின் கையிலும் கொடுத்து, எருசலேமிலிருந்து பாபிலோனுக்கு அனுப்பின நிருபத்தின் விபரம்:
	<br /><br />
	4. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர், தாம் எருசலேமிலிருந்து பாபிலோனுக்குச் சிறைப்பட்டுப்போகப்பண்ணின அனைவருக்கும் அறிவிக்கிறது என்னவென்றால்,
	<br /><br />
	5. நீங்கள் வீடுகளைக் கட்டி, குடியிருந்து, தோட்டங்களை நாட்டி, அவைகளின் கனியைச் சாப்பிடுங்கள்.
	<br /><br />
	6. நீங்கள் பெண்களை விவாகம்பண்ணி, குமாரரையும் குமாரத்திகளையும் பெற்று, உங்கள் குமாரருக்குப் பெண்களைக்கொண்டு, உங்கள் குமாரத்திகளைப் புருஷருக்குக் கொடுங்கள்; இவர்களும் குமாரரையும் குமாரத்திகளையும் பெறட்டும்; நீங்கள் அங்கே குறுகாமல் பெருகி,
	<br /><br />
	7. நான் உங்களைச் சிறைப்பட்டுப்போகப்பண்ணின பட்டணத்தின் சமாதானத்தைத் தேடி, அதற்காகக் கர்த்தரை விண்ணப்பம்பண்ணுங்கள்; அதற்குச் சமாதானமிருக்கையில் உங்களுக்கும் சமாதானமிருக்கும்.
	<br /><br />
	8. மேலும், உங்கள் நடுவிலிருக்கிற உங்கள் தீர்க்கதரிசிகளும் உங்கள் குறிகாரரும் உங்களை மோசம்போக்கவொட்டாதிருங்கள்; சொப்பனம் காணப்பண்ணுகிற உங்கள் சொப்பனக்காரருக்குச் செவிகொடாமலும் இருங்கள் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. அவர்கள் என் நாமத்தைச் சொல்லி உங்களுக்குப் பொய்யான தீர்க்கதரிசனம் உரைக்கிறார்கள்; நான் அவர்களை அனுப்பினதில்லை என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. பாபிலோனிலே எழுபதுவருஷம் நிறைவேறினபின்பு நான் உங்களைச் சந்தித்து, உங்களை இவ்விடத்துக்குத் திரும்பிவரப்பண்ணும்படிக்கு உங்கள்மேல் என் நல்வார்த்தையை நிறைவேறப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. நீங்கள் எதிர்பார்த்திருக்கும் முடிவை உங்களுக்குக் கொடுக்கும்படிக்கு நான் உங்கள்பேரில் நினைத்திருக்கிற நினைவுகளை அறிவேன் என்று கர்த்தர் சொல்லுகிறார்; அவைகள் தீமைக்கல்ல, சமாதானத்துக்கேதுவான நினைவுகளே.
	<br /><br />
	12. அப்பொழுது நீங்கள் கூடிவந்து, என்னைத் தொழுதுகொண்டு, என்னை நோக்கி விண்ணப்பம்பண்ணுவீர்கள்; நான் உங்களுக்குச் செவிகொடுப்பேன்.
	<br /><br />
	13. உங்கள் முழு இருதயத்தோடும் என்னைத் தேடினீர்களானால், என்னைத் தேடுகையில் கண்டுபிடிப்பீர்கள்.
	<br /><br />
	14. நான் உங்களுக்குக் காணப்படுவேன் என்று கர்த்தர் சொல்லுகிறார்; நான் உங்கள் சிறையிருப்பைத் திருப்பி, நான் உங்களைத் துரத்திவிட்ட எல்லா ஜாதிகளிலும் எல்லா இடங்களிலுமிருந்து உங்களைச் சேர்த்து, நான் உங்களை விலக்கியிருந்த ஸ்தலத்துக்கே உங்களைத் திரும்பிவரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	15. கர்த்தர் எங்களுக்குப் பாபிலோனிலும் தீர்க்கதரிசிகளை எழுப்பினார் என்று சொல்லுகிறீர்கள்.
	<br /><br />
	16. ஆனால் தாவீதின் சிங்காசனத்தில் உட்கார்ந்திருக்கிற ராஜாவைக்குறித்தும், உங்களோடேகூடச் சிறையிருப்பில் புறப்பட்டுப்போகாமல் இந்த நகரத்தில் குடியிருக்கிற உங்கள் சகோதரராகிய எல்லா ஜனங்களைக்குறித்தும்,
	<br /><br />
	17. இதோ, நான் பட்டயத்தையும், பஞ்சத்தையும், கொள்ளைநோயையும் அவர்களுக்குள் அனுப்புவேன் என்று கர்த்தர் சொல்லுகிறார்; புசிக்கத்தகாத கெட்டுப்போன அத்திப்பழங்களுக்கு அவர்களை ஒப்பாக்குவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. அவர்கள் என் வார்த்தைகளைக் கேளாமற்போனபடியால், நான் அவர்களைப் பட்டயத்தாலும், பஞ்சத்தாலும் கொள்ளைநோயாலும் பின்தொடர்ந்து, அவர்களைப் பூமியிலுள்ள எல்லா ராஜ்யங்களிலும் அலைந்து திரிகிறவர்களாகவும், நான் அவர்களைத் துரத்துகிற எல்லா ஜாதிகளிடத்திலும் சாபமாகவும், பாழாகவும், ஈசலிடுதலுக்கிடமாகவும், நிந்தையாகவும் வைப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	19. நான் உங்களிடத்திற்குத் தீர்க்கதரிசிகளாகிய என் ஊழியக்காரரை ஏற்கனவே அனுப்பிக்கொண்டேயிருந்தும், நீங்கள் செவிகொடாமற்போனீர்களே என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	20. இப்போதும் சிறையிருக்கும்படி நான் எருசலேமிலிருந்து பாபிலோனுக்கு அனுப்பிவிட்ட நீங்களெல்லாரும் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	21. என் நாமத்தைச் சொல்லி, உங்களுக்குப் பொய்யான தீர்க்கதரிசனம் உரைக்கிற கொலாயாவின் குமாரனாகிய ஆகாபையும், மாசெயாவின் குமாரனாகிய சிதேக்கியாவையுங்குறித்து: இதோ, நான் அவர்களைப் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரின் கையிலே ஒப்புக்கொடுக்கிறேன்; அவன் அவர்களை உங்கள் கண்களுக்கு முன்பாகக் கொன்றுபோடுவான்.
	<br /><br />
	22. பாபிலோன் ராஜா அக்கினியினால் சுட்டுப்போட்ட சிதேக்கியாவுக்கும் ஆகாபுக்கும் கர்த்தர் உன்னைச் சமானமாக்கக்கடவரென்று, அவர்களைக் குறித்து ஒரு சாபவார்த்தை பாபிலோனிலே சிறையிருக்கிற யூதா அனைவருக்குள்ளும் வழங்கும் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	23. அவர்கள் இஸ்ரவேலிலே மதிகெட்ட காரியத்தைச் செய்து, தங்கள் அயலாருடைய பெண்ஜாதிகளோடே விபசாரம்பண்ணி, நான் அவர்களுக்குக் கற்பியாத பொய்யான வார்த்தையை என் நாமத்தைச் சொல்லி உரைத்தார்கள்; நான் அதை அறிவேன்; அதற்கு நானே சாட்சி என்று கர்த்தர் சொல்லுகிறார் என்று எழுதினான்.
	<br /><br />
	24. பின்னும் கர்த்தர் என்னை நோக்கி: நீ நெகெலாமியனாகிய செமாயாவுக்கும் சொல்லியனுப்பவேண்டியது என்னவென்றால்:
	<br /><br />
	25. நீ எருசலேமிலிருக்கிற எல்லா ஜனங்களுக்கும், மாசெயாவின் குமாரனாகிய செப்பனியா என்னும் ஆசாரியனுக்கும், மற்ற ஆசாரியர்களுக்கும் உன் நாமத்திலே நிருபத்தை எழுதியனுப்பினது என்னவென்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	26. இவனுக்கு அவன் எழுதியிருந்த நிருபமாவது: நீங்கள் கர்த்தருடைய ஆலயத்தின் விசாரிப்புக்காரராயிருக்கும்படிக்கும், பிரமைகொண்டு தன்னைத் தீர்க்கதரிசியாக்கிக்கொள்ளுகிறவனாகிய எந்த மனுஷனையும் நீர் காவல் அறையிலும் தொழுவிலும் போடும்படிக்கும், கர்த்தர் உம்மை ஆசாரியனாயிருந்த யோய்தாவின் ஸ்தானத்திலே ஆசாரியனாக வைத்தாரே.
	<br /><br />
	27. இப்போதும் உங்களுக்குத் தீர்க்கதரிசனம் கூறிவருகிற ஆனதோத் ஊரானாகிய எரேமியாவை நீர் கடிந்து கொள்ளாமற்போனதென்ன?
	<br /><br />
	28. இந்தச் சிறையிருப்பு நெடுங்காலமாக இருக்கும்; நீங்கள் வீடுகளைக்கட்டி, அவைகளில் குடியிருந்து, தோட்டங்களை நாட்டி, அவைகளின் கனிகளைச் சாப்பிடுங்களென்று பாபிலோனில் இருக்கிற எங்களுக்குச் சொல்லியனுப்பினானென்று எழுதியிருந்தான்.
	<br /><br />
	29. இந்த நிருபத்தைச் செப்பனியா என்கிற ஆசாரியன் எரேமியா தீர்க்கதரிசியின் காதுகள் கேட்க வாசித்தான்.
	<br /><br />
	30. ஆதலால், கர்த்தருடைய வார்த்தை எரேமியாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	31. சிறையிருக்கிற அனைவருக்கும் நீ சொல்லியனுப்பவேண்டியது என்னவென்றால்: நெகெலாமியனாகிய செமாயாவைக்குறித்து, கர்த்தர்: செமாயாவை நான் அனுப்பாதிருந்தும், அவன் உங்களுக்குத் தீர்க்கதரிசனஞ்சொல்லி, உங்களைப் பொய்யை நம்பப்பண்ணுகிறபடியினால்,
	<br /><br />
	32. இதோ, நான் நெகெலாமியனாகிய செமாயாவையும், அவன் சந்ததியையும் தண்டிப்பேன்; இந்த ஜனத்தின் நடுவிலே குடியிருப்பவன் ஒருவனும் அவனுக்கு இல்லாதிருப்பான்; நான் என் ஜனத்துக்குச் செய்யும் நன்மையை அவன் காண்பதில்லை என்று கர்த்தர் சொல்லுகிறார்; கர்த்தருக்கு விரோதமாய்க் கலகமுண்டாகப் பேசினான் என்று கர்த்தர் சொல்லுகிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah29