import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 6 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 6 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 6 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உங்களில் ஒருவனுக்கு வேறொருவனோடே வழக்குண்டானால், வழக்காடும்படி அவன் பரிசுத்தவான்களிடத்தில் போகாமல், அநீதக்காரரிடத்தில் போகத் துணிகிறதென்ன?
	<br /><br />
	2. பரிசுத்தவான்கள் உலகத்தை நியாயந்தீர்ப்பார்களென்று அறியீர்களா? உலகம் உங்களால் நியாயந்தீர்க்கப்படுவதாயிருக்க, அற்ப வழக்குகளைத் தீர்க்க நீங்கள் அபாத்திரரா?
	<br /><br />
	3. தேவ தூதர்களையும் நியாயந்தீர்ப்போமென்று அறியீர்களா? அப்படியிருக்க, இந்த ஜீவனுக்கேற்றவைகளை நீங்கள் தீர்த்துக்கொள்ளக்கூடாதிருக்கிறது எப்படி?
	<br /><br />
	4. இந்த ஜீவனுக்கேற்ற வழக்குகள் உங்களுக்கு இருந்தால், தீர்ப்புச்செய்கிறதற்கு, சபையில் அற்பமாய் எண்ணப்பட்டவர்களை நியமித்துக்கொள்ளுங்கள்.
	<br /><br />
	5. உங்களுக்கு வெட்கம் உண்டாகும்படி இதைச் சொல்லுகிறேன். சகோதரனுக்கும் சகோதரனுக்கும் உண்டான வழக்கைத் தீர்க்கத்தக்க விவேகி ஒருவனாகிலும் உங்களுக்குள் இல்லையா?
	<br /><br />
	6. சகோதரனோடே சகோதரன் வழக்காடுகிறான், அவிசுவாசிகளுக்கு முன்பாகவும் அப்படிச் செய்கிறான்.
	<br /><br />
	7. நீங்கள் ஒருவரோடொருவர் வழக்காடுகிறது எவ்விதத்திலும் குற்றமாயிருக்கிறது. அப்படிச் செய்கிறதைவிட நீங்கள் ஏன் அநியாயத்தைச் சகித்துக்கொள்ளுகிறதில்லை, ஏன் நஷ்டத்தைப் பொறுத்துக்கொள்ளுகிறதில்லை?
	<br /><br />
	8. நீங்களே அநியாயஞ்செய்கிறீர்கள், நஷ்டப்படுத்துகிறீர்கள்; சகோதரருக்கும் அப்படிச் செய்கிறீர்களே.
	<br /><br />
	9. அநியாயக்காரர் தேவனுடைய ராஜ்யத்தைச் சுதந்தரிப்பதில்லையென்று அறியீர்களா? வஞ்சிக்கப்படாதிருங்கள்; வேசிமார்க்கத்தாரும், விக்கிரகாராதனைக்காரரும், விபசாரக்காரரும், சுயபுணர்ச்சிக்காரரும், ஆண்புணர்ச்சிக்காரரும்,
	<br /><br />
	10. திருடரும், பொருளாசைக்காரரும், வெறியரும், உதாசினரும், கொள்ளைக்காரரும் தேவனுடைய ராஜ்யத்தைச் சுதந்தரிப்பதில்லை.
	<br /><br />
	11. உங்களில் சிலர் இப்படிப்பட்டவர்களாயிருந்தீர்கள்; ஆயினும் கர்த்தராகிய இயேசுவின் நாமத்தினாலும், நமது தேவனுடைய ஆவியினாலும் கழுவப்பட்டீர்கள், பரிசுத்தமாக்கப்பட்டீர்கள், நீதிமான்களாக்கப்பட்டீர்கள்.
	<br /><br />
	12. எல்லாவற்றையும் அநுபவிக்க எனக்கு அதிகாரமுண்டு, ஆகிலும் எல்லாம் தகுதியாயிராது; எல்லாவற்றையும் அநுபவிக்க எனக்கு அதிகாரமுண்டு, ஆகிலும் நான் ஒன்றிற்கும் அடிமைப்படமாட்டேன்.
	<br /><br />
	13. வயிற்றுக்குப் போஜனமும் போஜனத்துக்கு வயிறும் ஏற்கும்; ஆனாலும் தேவன் இதையும் அதையும் அழியப்பண்ணுவார். சரீரமோ வேசித்தனத்திற்கல்ல, கர்த்தருக்கே உரியது; கர்த்தரும் சரீரத்திற்கு உரியவர்.
	<br /><br />
	14. தேவன் கர்த்தரை எழுப்பினாரே, நம்மையும் தமது வல்லமையினாலே எழுப்புவார்.
	<br /><br />
	15. உங்கள் சரீரங்கள் கிறிஸ்துவின் அவயவங்களென்று அறியீர்களா? அப்படியிருக்க, நான் கிறிஸ்துவின் அவயவங்களை வேசியின் அவயவங்களாக்கலாமா? அப்படிச் செய்யலாகாதே.
	<br /><br />
	16. வேசியோடு இசைந்திருக்கிறவன் அவளுடனே ஒரே சரீரமாயிருக்கிறானென்று அறியீர்களா? இருவரும் ஒரே மாம்சமாயிருப்பார்கள் என்று சொல்லியிருக்கிறதே.
	<br /><br />
	17. அப்படியே கர்த்தரோடிசைந்திருக்கிறவனும், அவருடனே ஒரே ஆவியாயிருக்கிறான்.
	<br /><br />
	18. வேசித்தனத்திற்கு விலகியோடுங்கள். மனுஷன் செய்கிற எந்தப் பாவமும் சரீரத்திற்குப் புறம்பாயிருக்கும்; வேசித்தனஞ் செய்கிறவனோ தன் சுயசரீரத்திற்கு விரோதமாய்ப் பாவஞ்செய்கிறான்.
	<br /><br />
	19. உங்கள் சரீரமானது நீங்கள் தேவனாலே பெற்றும் உங்களில் தங்கியும் இருக்கிற பரிசுத்த ஆவியினுடைய ஆலயமாயிருக்கிறதென்றும், நீங்கள் உங்களுடையவர்களல்லவென்றும் அறியீர்களா?
	<br /><br />
	20. கிரயத்துக்குக் கொள்ளப்பட்டீர்களே; ஆகையால் தேவனுக்கு உடையவைகளாகிய உங்கள் சரீரத்தினாலும் உங்கள் ஆவியினாலும் தேவனை மகிமைப்படுத்துங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians6