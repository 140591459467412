import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 9 </title>
	<meta
          name="description"
          content="Daniel 9 | தானியேல் தீர்க்கதரிசி | தானியேல் 9 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கல்தேயருடைய ராஜ்யத்தின்மேல் ராஜாவாக்கப்பட்ட மேதிய குலத்தானாகிய அகாஸ்வேருவின் புத்திரனான தரியு ராஜ்யபாரம்பண்ணுகிற முதலாம் வருஷத்திலே,
	<br /><br />
	2. தானியேலாகிய நான் எருசலேமின் பாழ்க்கடிப்புகள் நிறைவேறித்தீர எழுபதுவருஷம் செல்லுமென்று கர்த்தர் எரேமியா தீர்க்கதரிசியோடே சொல்லிய வருஷங்களின் தொகையைப் புஸ்தகங்களால் அறிந்துகொண்டேன்.
	<br /><br />
	3. நான் உபவாசம்பண்ணி, இரட்டிலும் சாம்பலிலும் உட்கார்ந்து, தேவனாகிய ஆண்டவரை ஜெபத்தினாலும் விண்ணப்பங்களினாலும் தேட என்முகத்தை அவருக்கு நேராக்கி,
	<br /><br />
	4. என் தேவனாகிய கர்த்தரை நோக்கி ஜெபம்பண்ணி, பாவ அறிக்கைசெய்து: ஆ ஆண்டவரே, உம்மில் அன்புகூர்ந்து, உம்முடைய கற்பனைகளைக் கைக்கொள்ளுகிறவர்களுக்கு உடன்படிக்கையையும் கிருபையையும் காக்கிற மகத்துவமும் பயங்கரமுமான தேவனே,
	<br /><br />
	5. நாங்கள் பாவஞ்செய்து, அக்கிரமக்காரராயிருந்து, துன்மார்க்கமாய் நடந்து, கலகம்பண்ணி, உம்முடைய கற்பனைகளையும் உம்முடைய நியாயங்களையும் விட்டு அகன்றுபோனோம்.
	<br /><br />
	6. உமது நாமத்தினாலே எங்கள் ராஜாக்களோடும் எங்கள் பிரபுக்களோடும் எங்கள் பிதாக்களோடும் தேசத்தினுடைய சகல ஜனங்களோடும் பேசின தீர்க்கதரிசிகளாகிய உம்முடைய ஊழியக்காரருக்குச் செவிகொடாமற்போனோம்.
	<br /><br />
	7. ஆண்டவரே, நீதி உமக்கே உரியது; உமக்கு விரோதமாகச் செய்த துரோகத்தினிமித்தம் உம்மாலே சமீபமும் தூரமுமான எல்லா தேசங்களிலும் துரத்தப்பட்டிருக்கிற யூதமனுஷரும் எருசலேமின் குடிகளும் சகல இஸ்ரவேலருமாகிய நாங்கள் இந்நாளில் இருக்கிறபடியே, வெட்கம் எங்களுக்கே உரியது.
	<br /><br />
	8. ஆண்டவரே, உமக்கு விரோதமாகப் பாவஞ்செய்தபடியால், நாங்களும் எங்கள் ராஜாக்களும் எங்கள் பிரபுக்களும் எங்கள் பிதாக்களும் வெட்கத்துக்குரியவர்களானோம்.
	<br /><br />
	9. அவருக்கு விரோதமாக நாங்கள் கலகம்பண்ணி, அவர் தீர்க்கதரிசிகளாகிய தம்முடைய ஊழியக்காரரைக்கொண்டு எங்களுக்கு முன்பாகவைத்த அவருடைய நியாயப்பிரமாணங்களின்படி நடக்கத்தக்கதாக நாங்கள் அவருடைய சத்தத்துக்குச் செவிகொடாமற்போனோம்.
	<br /><br />
	10. ஆனாலும் எங்கள் தேவனாகிய ஆண்டவரிடத்தில் இரக்கங்களும் மன்னிப்புகளும் உண்டு.
	<br /><br />
	11. இஸ்ரவேலர் எல்லாரும் உமது நியாயப்பிரமாணத்தை மீறி, உமது சத்தத்துக்குச் செவிகொடாமல் விலகிப்போனார்கள். அவருக்கு விரோதமாகப் பாவஞ்செய்தோம்; ஆகையால் தேவனுடைய தாசனாகிய மோசேயின் நியாயப்பிரமாணப் புஸ்தகத்தில் எழுதியிருக்கிற சாபமும் ஆணையாக்கினையும் எங்கள்மேல் சொரியப்பட்டன.
	<br /><br />
	12. எருசலேமில் சம்பவித்ததுபோல வானத்தின்கீழ் எங்கும் சம்பவியாதிருக்கிற பெரிய தீங்கை எங்கள்மேல் வரப்பண்ணினதினால், அவர் எங்களுக்கும் எங்களை நியாயந்தீர்த்த நியாயாதிபதிகளுக்கும் விரோதமாகச் சொல்லியிருந்த தம்முடைய வார்த்தைகளை நிறைவேற்றினார்.
	<br /><br />
	13. மோசேயின் நியாயப்பிரமாணப் புஸ்தகத்தில் எழுதியிருக்கிறபடியே இந்தத் தீங்கெல்லாம் எங்கள்மேல் வந்தது; ஆனாலும் நாங்கள் எங்கள் அக்கிரமங்களை விட்டுத் திரும்புகிறதற்கும், உம்முடைய சத்தியத்தைக் கவனிக்கிறதற்கும், எங்கள் தேவனாகிய கர்த்தரின் முகத்தை நோக்கிக் கெஞ்சினதில்லை.
	<br /><br />
	14. ஆதலால் கர்த்தர் கவனமாயிருந்து, அந்தத் தீங்கை எங்கள்மேல் வரப்பண்ணினார்; எங்கள் தேவனாகிய கர்த்தர் தாம் செய்துவருகிற தம்முடைய கிரியைகளில் எல்லாம் நீதியுள்ளவர்; நாங்களோ அவருடைய சத்தத்துக்குச் செவிகொடாமற்போனோம்.
	<br /><br />
	15. இப்போதும் உமது ஜனத்தைப் பலத்த கையினால் எகிப்துதேசத்திலிருந்து புறப்படப்பண்ணி, இந்நாள்வரைக்கும் இருக்கிறபடி, உமக்குக் கீர்த்தியை உண்டாக்கின எங்கள் தேவனாகிய ஆண்டவரே, நாங்கள் பாவஞ்செய்து, துன்மார்க்கராய் நடந்தோம்.
	<br /><br />
	16. ஆண்டவரே, உம்முடைய சர்வநீதியின்படியே, உமது கோபமும் உமது உக்கிரமும் உம்முடைய பரிசுத்த பர்வதமாகிய எருசலேம் என்னும் உம்முடைய நகரத்தை விட்டுத் திரும்பும்படி செய்யும்; எங்கள் பாவங்களினாலும் எங்கள் பிதாக்களின் அக்கிரமங்களினாலும் எருசலேமும் உம்முடைய ஜனமாகிய நாங்களும் எங்கள் சுற்றுப்புறத்தார் யாவருக்கும் நிந்தையானோம்.
	<br /><br />
	17. இப்போதும் எங்கள் தேவனே, நீர் உமது அடியானுடைய விண்ணப்பத்தையும் அவனுடைய கெஞ்சுதலையும் கேட்டு, பாழாய்க் கிடக்கிற உம்முடைய பரிசுத்த ஸ்தலத்தின்மேல் ஆண்டவரினிமித்தம் உமது முகத்தைப் பிரகாசிக்கப்பண்ணும்.
	<br /><br />
	18. என் தேவனே, உம்முடைய செவியைச் சாய்த்துக் கேட்டருளும்; உம்முடைய கண்களைத் திறந்து, எங்கள் பாழிடங்களையும், உமது நாமம் தரிக்கப்பட்டிருக்கிற நகரத்தையும் பார்த்தருளும்; நாங்கள் எங்கள் நீதிகளை அல்ல, உம்முடைய மிகுந்த இரக்கங்களையே நம்பி, எங்கள் விண்ணப்பங்களை உமக்கு முன்பாகச் செலுத்துகிறோம்.
	<br /><br />
	19. ஆண்டவரே கேளும், ஆண்டவரே மன்னியும், ஆண்டவரே கவனியும்; என் தேவனே, உம்முடைய நிமித்தமாக அதைத் தாமதியாமல் செய்யும்; உம்முடைய நகரத்துக்கும் உம்முடைய ஜனத்துக்கும் உம்முடைய நாமம் தரிக்கப்பட்டிருக்கிறதே என்றேன்.
	<br /><br />
	20. இப்படி நான் சொல்லி, ஜெபம்பண்ணி, என் பாவத்தையும் என் ஜனமாகிய இஸ்ரவேலின் பாவத்தையும் அறிக்கையிட்டு, என் தேவனுடைய பரிசுத்த பர்வதத்துக்காக என் விண்ணப்பத்தை என் தேவனாகிய கர்த்தருக்கு முன்பாகச் செலுத்திக்கொண்டிருந்தேன்.
	<br /><br />
	21. அப்படி நான் ஜெபம்பண்ணிக்கொண்டிருக்கும்போதே, முதல் தரிசனத்திலே நான் கண்ட புருஷனாகிய காபிரியேல், வேகமாய்ப் பறந்துவந்து, அந்திப்பலியின் நேரமாகிய வேளையிலே என்னைத் தொட்டான்.
	<br /><br />
	22. அவன் எனக்குத் தெளிவுண்டாக்கி, என்னோடேபேசி: தானியேலே, உனக்கு அறிவை உணர்த்தும்படி இப்போது புறப்பட்டுவந்தேன்.
	<br /><br />
	23. நீ மிகவும் பிரியமானவன், ஆதலால், நீ வேண்டிக்கொள்ளத் தொடங்கினபோதே கட்டளை வெளிப்பட்டது, நான் அதை அறிவிக்கவந்தேன்; இப்போதும் சொல்லுகிற வார்த்தையை நீ கவனித்துக்கேட்டு, தரிசனத்தை அறிந்துகொள்.
	<br /><br />
	24. மீறுதலைத் தவிர்க்கிறதற்கும், பாவங்களைத் தொலைக்கிறதற்கும், அக்கிரமத்தை நிவிர்த்திபண்ணுகிறதற்கும், நித்திய நீதியை வருவிக்கிறதற்கும், தரிசனத்தையும் தீர்க்கதரிசனத்தையும் முத்திரிக்கிறதற்கும், மகா பரிசுத்தமுள்ளவரை அபிஷேகம்பண்ணுகிறதற்கும், உன் ஜனத்தின்மேலும் உன் பரிசுத்த நகரத்தின்மேலும் எழுபது வாரங்கள் செல்லும்படி குறிக்கப்பட்டிருக்கிறது.
	<br /><br />
	25. இப்போதும் நீ அறிந்து உணர்ந்துகொள்ளவேண்டியது என்னவென்றால்: எருசலேமைத் திரும்ப எடுப்பித்துக் கட்டுகிறதற்கான கட்டளை வெளிப்படுவதுமுதல், பிரபுவாகிய மேசியா வருமட்டும் ஏழு வாரமும், அறுபத்திரண்டு வாரமும் செல்லும்; அவைகளில் வீதிகளும் அலங்கங்களும் மறுபடியும் கட்டப்படும்; ஆனாலும் இடுக்கமான காலங்களில் இப்படியாகும்.
	<br /><br />
	26. அந்த அறுபத்திரண்டு வாரங்களுக்குப் பின்பு மேசியா சங்கரிக்கப்படுவார்; ஆனாலும் தமக்காக அல்ல; நகரத்தையும் பரிசுத்த ஸ்தலத்தையும் வரப்போகிற பிரபுவின் ஜனங்கள் அழித்துப்போடுவார்கள்; அதின் முடிவு ஜலப்பிரவாகம்போல இருக்கும்; முடிவுபரியந்தம் யுத்தமும் நாசமும் உண்டாக நியமிக்கப்பட்டது.
	<br /><br />
	27. அவர் ஒரு வாரமளவும் அநேகருக்கு உடன்படிக்கையை உறுதிப்படுத்தி, அந்த வாரம் பாதி சென்றபோது பலியையும் காணிக்கையையும் ஒழியப்பண்ணுவார்; அருவருப்பான செட்டைகளோடே பாழாக்குகிறவன் வந்து இறங்குவான், நிர்ணயிக்கப்பட்டிருக்கிற நிர்மூலம் பாழாக்குகிறவன்மேல் தீருமட்டும் சொரியும் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel9