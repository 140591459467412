import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneCorinthians16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO CORINTHIANS 16 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE CORINTHIANS Chapter 16 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 16 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பரிசுத்தவான்களுக்காகச் சேர்க்கப்படும் தர்மப்பணத்தைக்குறித்து நான் கலாத்தியா நாட்டுச் சபைகளுக்குப் பண்ணின திட்டத்தின்படியே நீங்களும் செய்யுங்கள்.
	<br /><br />
	2. நான் வந்திருக்கும்போது பணஞ் சேர்க்குதல் இராதபடிக்கு, உங்களில் அவனவன் வாரத்தின் முதல்நாள்தோறும், தன்தன் வரவுக்குத் தக்கதாக எதையாகிலும் தன்னிடத்திலே சேர்த்துவைக்கக்கடவன்.
	<br /><br />
	3. நான் வரும்போது உங்கள் உபகாரத்தை எருசலேமுக்குக் கொண்டுபோகும்படிக்கு, நீங்கள் தகுதியுள்ளவர்களாகக் குறிக்கிறவர்கள் எவர்களோ, அவர்களிடத்தில் நிருபங்களைக் கொடுத்து, அவர்களை அனுப்புவேன்.
	<br /><br />
	4. நானும் போகத்தக்கதானால், அவர்கள் என்னுடனேகூட வரலாம்.
	<br /><br />
	5. நான் மக்கெதோனியா நாட்டின் வழியாய்ப் போகிறபடியால், மக்கெதோனியா நாட்டைக் கடந்தபின்பு உங்களிடத்திற்கு வருவேன்.
	<br /><br />
	6. நான் எங்கே போனாலும் நீங்கள் என்னை வழிவிட்டனுப்பும்படிக்கு, நான் உங்களிடத்தில் சிலகாலம் தங்கவேண்டியதாயிருக்கும்; ஒருவேளை மழைகாலம் முடியும்வரையும் இருப்பேன்.
	<br /><br />
	7. இப்பொழுது வழிப்பிரயாணத்திலே உங்களைக் கண்டுகொள்ளமாட்டேன்; கர்த்தர் உத்தரவுகொடுத்தால் உங்களிடத்தில் வந்து சிலகாலம் தங்கியிருக்கலாமென்று நம்புகிறேன்.
	<br /><br />
	8. ஆகிலும் பெந்தெகொஸ்தே பண்டிகைவரைக்கும் எபேசு பட்டணத்தில் இருப்பேன்.
	<br /><br />
	9. ஏனெனில் இங்கே பெரிதும் அநுகூலமுமான கதவு எனக்குத் திறக்கப்பட்டிருக்கிறது; விரோதஞ்செய்கிறவர்களும் அநேகர் இருக்கிறார்கள்.
	<br /><br />
	10. தீமோத்தேயு உங்களிடத்திற்கு வந்தானேயாகில், அவன் உங்களிடத்தில் பயமில்லாமலிருக்கப் பாருங்கள்; என்னைப்போல அவனும் கர்த்தருடைய கிரியையை நடப்பிக்கிறானே.
	<br /><br />
	11. ஆனபடியினால் ஒருவனும் அவனை அற்பமாய் எண்ணாதிருப்பானாக; சகோதரரோடேகூட அவன் வருகிறதற்கு நான் காத்திருக்கிறபடியால், என்னிடத்தில் வரும்படிக்கு அவனைச் சமாதானத்தோடே வழிவிட்டனுப்புங்கள்.
	<br /><br />
	12. சகோதரனாகிய அப்பொல்லோவைக் குறித்தோவெனில், சகோதரரோடேகூட உங்களிடத்தில் வரும்படிக்கு அவனை மிகவும் வேண்டிக்கொண்டேன்; ஆகிலும் இப்பொழுது வர அவனுக்கு மனதில்லை; அவனுக்கு நற்சமயம் உண்டாயிருக்கும்போது வருவான்.
	<br /><br />
	13. விழித்திருங்கள், விசுவாசத்திலே நிலைத்திருங்கள், புருஷராயிருங்கள், திடன்கொள்ளுங்கள்.
	<br /><br />
	14. உங்கள் காரியங்களெல்லாம் அன்போடே செய்யப்படக்கடவது.
	<br /><br />
	15. சகோதரரே, ஸ்தேவானுடைய வீட்டார் அகாயாநாட்டிலே முதற்பலனானவர்களென்றும், பரிசுத்தவான்களுக்கு ஊழியஞ்செய்யும்படிக்குத் தங்களை ஒப்புவித்திருக்கிறார்களென்றும் அறிந்திருக்கிறீர்களே.
	<br /><br />
	16. இப்படிப்பட்டவர்களுக்கும், உடன்வேலையாட்களாய்ப் பிரயாசப்படுகிற மற்ற யாவருக்கும் நீங்கள் கீழ்ப்படிந்திருக்கவேண்டுமென்று உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	17. ஸ்தேவான், பொர்த்துனாத்து, அகாயுக்கு என்பவர்கள் வந்ததற்காகச் சந்தோஷமாயிருக்கிறேன், நீங்கள் எனக்குச் செய்யவேண்டியதாயிருந்ததை அவர்கள் செய்திருக்கிறார்கள்.
	<br /><br />
	18. அவர்கள் என் ஆவிக்கும் உங்கள் ஆவிக்கும் ஆறுதல் செய்தார்கள்; இப்படிப்பட்டவர்களை அங்கிகாரம்பண்ணுங்கள்.
	<br /><br />
	19. ஆசியா நாட்டிலுள்ள சபையார் உங்களை வாழ்த்துகிறார்கள். ஆக்கில்லாவும் பிரிஸ்கில்லாளும் தங்கள் வீட்டிலே கூடுகிற சபையோடுங்கூடக் கர்த்தருக்குள் உங்களை மிகவும் வாழ்த்துகிறார்கள்.
	<br /><br />
	20. சகோதரரெல்லாரும் உங்களை வாழ்த்துகிறார்கள். ஒருவரையொருவர் பரிசுத்த முத்தத்தோடே வாழ்த்துங்கள்.
	<br /><br />
	21. பவுலாகிய நான் என் கையெழுத்தாலே உங்களை வாழ்த்துகிறேன்.
	<br /><br />
	22. ஒருவன் கர்த்தராகிய இயேசுகிறிஸ்துவினிடத்தில் அன்புகூராமற்போனால், அவன் சபிக்கப்பட்டவனாயிருக்கக்கடவன், கர்த்தர் வருகிறார்.
	<br /><br />
	23. கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய கிருபை உங்களுடனேகூட இருப்பதாக.
	<br /><br />
	24. கிறிஸ்து இயேசுவுக்குள்ளான என்னுடைய அன்பு உங்களெல்லாரோடுங்கூட இருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneCorinthians16