import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 20 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாட்களில் ஒன்றில், அவர் தேவாலயத்திலே ஜனங்களுக்கு உபதேசித்து, சுவிசேஷத்தைப் பிரசங்கித்தபோது, பிரதான ஆசாரியரும் வேதபாரகரும் மூப்பரும் அவரிடத்தில் கூடிவந்து:
	<br /><br />
	2. நீர் எந்த அதிகாரத்தினால் இவைகளைச் செய்கிறீர்? இந்த அதிகாரத்தை உமக்குக் கொடுத்தவர் யார்? அதை எங்களுக்குச் சொல்லும் என்றார்கள்.
	<br /><br />
	3. அவர்களுக்கு அவர் பிரதியுத்தரமாக: நானும் உங்களிடத்தில் ஒரு காரியத்தைக் கேட்கிறேன், அதை எனக்குச் சொல்லுங்கள்.
	<br /><br />
	4. யோவான் கொடுத்த ஸ்நானம் தேவனால் உண்டாயிற்றோ? மனுஷரால் உண்டாயிற்றோ? என்று கேட்டார்.
	<br /><br />
	5. அவர்கள் தங்களுக்குள்ளே யோசனைபண்ணி: தேவனால் உண்டாயிற்று என்று சொல்வோமானால், பின்னை ஏன் அவனை விசுவாசிக்கவில்லை என்று கேட்பார்.
	<br /><br />
	6. மனுஷரால் உண்டாயிற்று என்று சொல்வோமானால், ஜனங்களெல்லாரும் யோவானைத் தீர்க்கதரிசியென்று எண்ணுகிறபடியினால் நம்மேல் கல்லெறிவார்கள் என்று சொல்லி:
	<br /><br />
	7. அது யாரால் உண்டாயிற்றோ, எங்களுக்குத் தெரியாது என்று பிரதியுத்தரம் சொன்னார்கள்.
	<br /><br />
	8. அப்பொழுது இயேசு: நானும் இன்ன அதிகாரத்தினால் இவைகளைச் செய்கிறேனென்று உங்களுக்குச் சொல்லேன் என்றார்.
	<br /><br />
	9. பின்பு அவர் ஜனங்களுக்குச் சொல்லத்தொடங்கின உவமையாவது: ஒரு மனுஷன் ஒரு திராட்சத்தோட்டத்தை உண்டாக்கி, அதைத் தோட்டக்காரருக்குக் குத்தகையாக விட்டு, நெடுநாளாகப் புறத்தேசத்துக்குப் போயிருந்தான்.
	<br /><br />
	10. அந்தத் தோட்டக்காரர் திராட்சத்தோட்டத்தின் கனிகளில் தன் பாகத்தைக் கொடுத்தனுப்பும்படி, பருவகாலத்திலே அவர்களிடத்தில் ஒரு ஊழியக்காரனை அனுப்பினான். அந்தத் தோட்டக்காரர் அவனை அடித்து, வெறுமையாக அனுப்பிவிட்டார்கள்.
	<br /><br />
	11. பின்பு அவன் வேறொரு ஊழியக்காரனை அனுப்பினான்; அவனையும் அவர்கள் அடித்து, அவமானப்படுத்தி, வெறுமையாக அனுப்பிவிட்டார்கள்.
	<br /><br />
	12. அவன் மூன்றாந்தரமும் ஒரு ஊழியக்காரனை அனுப்பினான்; அவனையும் அவர்கள் காயப்படுத்தி, துரத்திவிட்டார்கள்.
	<br /><br />
	13. அப்பொழுது திராட்சத்தோட்டத்தின் எஜமான்: நான் என்ன செய்யலாம், எனக்குப் பிரியமான குமாரனை அனுப்பினால், அவனையாகிலும் கண்டு அஞ்சுவார்கள் என்று எண்ணி, அவனை அனுப்பினான்.
	<br /><br />
	14. தோட்டக்காரர் அவனைக் கண்டபோது: இவன் சுதந்தரவாளி, சுதந்தரம் நம்முடையதாகும்படிக்கு இவனைக் கொல்லுவோம் வாருங்கள் என்று ஒருவரோடொருவர் சொல்லிக்கொண்டு,
	<br /><br />
	15. அவனைத் திராட்சத்தோட்டத்திற்குப் புறம்பே தள்ளி, கொன்றுபோட்டார்கள். இப்படியிருக்க, திராட்சத்தோட்டத்தின் எஜமான் அவர்களை என்னசெய்வான்?
	<br /><br />
	16. அவன் வந்து அந்தத் தோட்டக்காரரைச் சங்கரித்து, திராட்சத்தோட்டத்தை வேறு தோட்டக்காரரிடத்தில் கொடுப்பான் அல்லவா என்றார். அவர்கள் அதைக்கேட்டு, அப்படியாகாதிருப்பதாக என்றார்கள்.
	<br /><br />
	17. அப்பொழுது அவர் அவர்களைப் பார்த்து: வீடு கட்டுகிறவர்கள் ஆகாதென்று தள்ளின கல்லே, மூலைக்குத் தலைக்கல்லாயிற்று என்று எழுதியிருக்கிற வேதவாக்கியத்தின் கருத்தென்ன?
	<br /><br />
	18. அந்தக் கல்லின்மேல் விழுகிறவன் எவனோ அவன் நொறுங்கிப்போவான், அது எவன்மேல் விழுமோ அவனை நசுக்கிப்போடும் என்றார்.
	<br /><br />
	19. பிரதான ஆசாரியரும் வேதபாரகரும் தங்களைக்குறித்து இந்த உவமையைச் சொன்னாரென்று அறிந்து, அந்நேரத்திலே அவரைப் பிடிக்க வகைதேடியும் ஜனங்களுக்குப் பயந்திருந்தார்கள்.
	<br /><br />
	20. அவர்கள் சமயம்பார்த்து, தேசாதிபதியின் ஆளுகைக்கும் அதிகாரத்துக்கும் அவரை ஒப்புக்கொடுக்கும்படி அவருடைய பேச்சிலே குற்றங்கண்டுபிடிக்கலாமென்று, தங்களை உண்மையுள்ளவர்களாய்க் காண்பிக்கிற வேவுகாரரை அவரிடத்தில் அனுப்பினார்கள்.
	<br /><br />
	21. அவர்கள் வந்து: போதகரே, நீர் நிதானமாய்ப் பேசி உபதேசிக்கிறீரென்றும், முகதாட்சணியமில்லாமல் தேவனுடைய மார்க்கத்தைச் சத்தியமாய்ப் போதிக்கிறீரென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	22. இராயனுக்கு வரிகொடுக்கிறது நியாயமோ அல்லவோ, எங்களுக்குச் சொல்லும் என்று கேட்டார்கள்.
	<br /><br />
	23. அவர்களுடைய தந்திரத்தை அவர் அறிந்து, நீங்கள் என்னை ஏன் சோதிக்கிறீர்கள்?
	<br /><br />
	24. ஒரு பணத்தை எனக்குக் காண்பியுங்கள். இதிலிருக்கிற சொரூபமும் மேலெழுத்தும் யாருடையது என்று கேட்டார். அதற்கு அவர்கள்: இராயனுடையது என்றார்கள்.
	<br /><br />
	25. அதற்கு அவர்: அப்படியானால், இராயனுடையதை இராயனுக்கும், தேவனுடையதைத் தேவனுக்கும் செலுத்துங்கள் என்றார்.
	<br /><br />
	26. அவர்கள் அவரை ஜனங்களுக்கு முன்பாகப் பேச்சிலே குற்றம்பிடிக்கக்கூடாமல், அவர் சொன்ன உத்தரவைக்குறித்து ஆச்சரியப்பட்டு, மவுனமாயிருந்தார்கள்.
	<br /><br />
	27. உயிர்த்தெழுதல் இல்லையென்று சாதிக்கிற சதுசேயரில் சிலர் அவரிடத்தில் வந்து:
	<br /><br />
	28. போதகரே, ஒருவன் மனைவியையுடையவனாயிருந்து பிள்ளையில்லாமல் இறந்துபோனால், அவனுடைய சகோதரன் அவன் மனைவியை விவாகம்பண்ணி, தன் சகோதரனுக்குச் சந்தானமுண்டாக்கவேண்டும் என்று மோசே எங்களுக்கு எழுதிவைத்திருக்கிறாரே.
	<br /><br />
	29. சகோதரர் ஏழுபேரிருந்தார்கள், அவர்களில் மூத்தவன் ஒரு பெண்ணை விவாகம்பண்ணி, பிள்ளையில்லாமல் இறந்துபோனான்.
	<br /><br />
	30. பின்பு இரண்டாஞ்சகோதரன் அவளை விவாகம்பண்ணி, அவனும் பிள்ளையில்லாமல் இறந்துபோனான்.
	<br /><br />
	31. மூன்றாஞ்சகோதரனும் அவளை விவாகம்பண்ணினான். அப்படியே ஏழுபேரும் அவளை விவாகம்பண்ணி, பிள்ளையில்லாமல் இறந்துபோனார்கள்.
	<br /><br />
	32. எல்லாருக்கும் பின்பு அந்த ஸ்திரீயும் இறந்துபோனாள்.
	<br /><br />
	33. இவ்விதமாய் ஏழுபேரும் அவளை விவாகம்பண்ணியிருக்க, உயிர்த்தெழுதலில் அவர்களில் எவனுக்கு அவள் மனைவியாயிருப்பாள் என்று கேட்டார்கள்.
	<br /><br />
	34. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: இந்தப் பிரபஞ்சத்தின் பிள்ளைகள் பெண்கொண்டும் பெண்கொடுத்தும் வருகிறார்கள்.
	<br /><br />
	35. மறுமையையும் மரித்தோரிலிருந்தெழுந்திருக்குதலையும் அடையப் பாத்திரராக எண்ணப்படுகிறவர்களோ பெண்கொள்வதுமில்லை பெண் கொடுப்பதுமில்லை.
	<br /><br />
	36. அவர்கள் இனி மரிக்கவுமாட்டார்கள்; அவர்கள் உயிர்த்தெழுதலின் பிள்ளைகளானபடியால் தேவதூதருக்கு ஒப்பானவர்களுமாய், தேவனுக்குப் பிள்ளைகளுமாயிருப்பார்கள்.
	<br /><br />
	37. அன்றியும் மரித்தோர் எழுந்திருப்பார்களென்பதை மோசேயும் முட்செடியைப்பற்றிய வாசகத்தில் காண்பித்திருக்கிறார். எப்படியெனில், கர்த்தரை ஆபிரகாமின் தேவனென்றும் ஈசாக்கின் தேவனென்றும் யாக்கோபின் தேவனென்றும் சொல்லியிருக்கிறார்.
	<br /><br />
	38. அவர் மரித்தோரின் தேவனாயிராமல், ஜீவனுள்ளோரின் தேவனாயிருக்கிறார்; எல்லாரும் அவருக்குப் பிழைத்திருக்கிறார்களே என்றார்.
	<br /><br />
	39. அப்பொழுது வேதபாரகரில் சிலர் அதைக் கேட்டு: போதகரே, நன்றாய்ச் சொன்னீர் என்றார்கள்.
	<br /><br />
	40. அதன்பின்பு அவர்கள் அவரிடத்தில் வேறொன்றுங்கேட்கத் துணியவில்லை.
	<br /><br />
	41. அவர் அவர்களை நோக்கி: கிறிஸ்து தாவீதின் குமாரனென்று எப்படிச் சொல்லுகிறார்கள்?
	<br /><br />
	42. நான் உம்முடைய சத்துருக்களை உமக்குப் பாதபடியாக்கிப் போடும்வரைக்கும் நீர் என்னுடைய வலதுபாரிசத்தில் உட்காரும் என்று,
	<br /><br />
	43. கர்த்தர் என் ஆண்டவருடனே சொன்னார் என்று தாவீது தானே சங்கீத புஸ்தகத்தில் சொல்லுகிறானே.
	<br /><br />
	44. தாவீது அவரை ஆண்டவரென்று சொல்லியிருக்க, அவனுக்கு அவர் குமாரனாயிருப்பது எப்படி என்றார்.
	<br /><br />
	45. பின்பு ஜனங்களெல்லாரும் கேட்கையில் அவர் தம்முடைய சீஷரை நோக்கி:
	<br /><br />
	46. நீண்ட அங்கிகளைத் தரித்துக்கொண்டு திரியவும், சந்தை வெளிகளில் வந்தனங்களை அடையவும், ஜெபஆலயங்களில் முதன்மையான ஆசனங்களில் உட்காரவும், விருந்துகளில் முதன்மையான இடங்களில் இருக்கவும் விரும்பி,
	<br /><br />
	47. விதவைகளின் வீடுகளைப் பட்சித்து, பார்வைக்கு நீண்ட ஜெபம்பண்ணுகிற வேதபாரகரைக்குறித்து எச்சரிக்கையாயிருங்கள், அவர்கள் அதிக ஆக்கினையை அடைவார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke20