import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jonah4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோனா 4 </title>
	<meta
          name="description"
          content="Jonah 4 | யோனா 4 | Yona 4 | 
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோனாவுக்கு இது மிகவும் விசனமாயிருந்தது; அவன் கடுங்கோபங்கொண்டு,
	<br /><br />
	2. கர்த்தரை நோக்கி விண்ணப்பம்பண்ணி: ஆ கர்த்தாவே, நான் என் தேசத்தில் இருக்கும்போதே நான் இதைச் சொல்லவில்லையா? இதினிமித்தமே நான் முன்னமே தர்ஷீசுக்கு ஓடிப்போனேன்; நீர் இரக்கமும் மன உருக்கமும் நீடிய சாந்தமும் மிகுந்த கிருபையுமுள்ளவரும், தீங்குக்கு மனஸ்தாபப்படுகிறவருமான தேவனென்று அறிவேன்.
	<br /><br />
	3. இப்போதும் கர்த்தாவே, என் பிராணனை என்னைவிட்டு எடுத்துக்கொள்ளும்; நான் உயிரோடிருக்கிறதைப்பார்க்கிலும் சாகிறது நலமாயிருக்கும் என்றான்.
	<br /><br />
	4. அதற்குக் கர்த்தர்: நீ எரிச்சலாயிருக்கிறது நல்லதோ என்றார்.
	<br /><br />
	5. பின்பு யோனா நகரத்திலிருந்து புறப்பட்டு, நகரத்துக்குக் கிழக்கேபோய், அங்கே தனக்கு ஒரு குடிசையைப் போட்டு, நகரத்துக்குச் சம்பவிக்கப்போகிறதைத் தான் பார்க்குமட்டும் அதின் கீழ் நிழலில் உட்கார்ந்திருந்தான்.
	<br /><br />
	6. யோனாவுடைய தலையின்மேல் நிழலுண்டாயிருக்கவும், அவனை அவனுடைய மனமடிவுக்கு நீங்கலாக்கவும் தேவனாகிய கர்த்தர் ஒரு ஆமணக்குச் செடியை முளைக்கக் கட்டளையிட்டு, அதை அவன்மேல் ஓங்கி வளரப்பண்ணினார்; அந்த ஆமணக்கின்மேல் யோனா மிகவும் சந்தோஷப்பட்டான்.
	<br /><br />
	7. மறுநாளிலோ கிழக்கு வெளுக்கும் நேரத்தில் தேவன் ஒரு பூச்சியைக் கட்டளையிட்டார்; அது ஆமணக்குச் செடியை அரித்துப்போட்டது; அதினால் அது காய்ந்துபோயிற்று.
	<br /><br />
	8. சூரியன் உதித்தபோது தேவன் உஷ்ணமான கீழ்க்காற்றைக் கட்டளையிட்டார்; அப்பொழுது வெயில் யோனாவுடைய தலையில் படுகிறதினால் அவன் சோர்ந்துபோய், தனக்குள்ளே சாவை விரும்பி: நான் உயிரோடிருக்கிறதைப்பார்க்கிலும் சாகிறது நலமாயிருக்கும் என்றான்.
	<br /><br />
	9. அப்பொழுது தேவன் யோனாவை நோக்கி: நீ ஆமணக்கினிமித்தம் எரிச்சலாயிருக்கிறது நல்லதோ என்றார்; அதற்கு அவன்: நான் மரணபரியந்தமும் எரிச்சலாயிருக்கிறது நல்லதுதான் என்றான்.
	<br /><br />
	10. அதற்குக் கர்த்தர்: நீ பிரயாசப்படாததும், நீ வளர்க்காததும், ஒரு இராத்திரியிலே முளைத்ததும், ஒரு இராத்திரியிலே அழிந்துபோனதுமான ஆமணக்குக்காகப் பரிதபிக்கிறாயே.
	<br /><br />
	11. வலதுகைக்கும் இடதுகைக்கும் வித்தியாசம் அறியாத இலட்சத்து இருபதினாயிரம்பேருக்கு அதிகமான மனுஷரும் அநேக மிருகஜீவன்களும் இருக்கிற மகா நகரமாகிய நினிவேக்காக நான் பரிதபியாமலிருப்பேனோ என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jonah4