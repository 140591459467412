import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 12 </title>
	<meta
          name="description"
          content="Isaiah 12 | ஏசாயா 12 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே நீ சொல்வது: கர்த்தாவே, நான் உம்மைத் துதிப்பேன்; நீர் என்மேல் கோபமாயிருந்தீர்; ஆனாலும் உம்முடைய கோபம் நீங்கிற்று; நீர் என்னைத் தேற்றுகிறீர்.
	<br /><br />
	2. இதோ, தேவனே என் இரட்சிப்பு; நான் பயப்படாமல் நம்பிக்கையாயிருப்பேன்; கர்த்தராகிய யேகோவா என் பெலனும், என் கீதமுமானவர்; அவரே எனக்கு இரட்சிப்புமானவர்.
	<br /><br />
	3. நீங்கள் இரட்சிப்பின் ஊற்றுகளிலிருந்து மகிழ்ச்சியோடே தண்ணீர் மொண்டுகொள்வீர்கள்.
	<br /><br />
	4. அக்காலத்திலே நீங்கள் சொல்வது: கர்த்தரைத் துதியுங்கள்; அவர் நாமத்தைத் தொழுதுகொள்ளுங்கள்; அவருடைய செய்கைகளை ஜனங்களுக்குள்ளே அறிவியுங்கள்; அவருடைய நாமம் உயர்ந்ததென்று பிரஸ்தாபம்பண்ணுங்கள்.
	<br /><br />
	5. கர்த்தரைக் கீர்த்தனம்பண்ணுங்கள், அவர் மகத்துவமான கிரியைகளைச் செய்தார்; இது பூமியெங்கும் அறியப்படக்கடவது என்பீர்கள்.
	<br /><br />
	6. சீயோனில் வாசமாயிருக்கிறவளே, நீ சத்தமிட்டுக் கெம்பீரி; இஸ்ரவேலின் பரிசுத்தர் உன் நடுவில் பெரியவராயிருக்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah12