import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const James3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> Taming the Tongue James in Tamil Bible Verse | யாக்கோபு - 3 </title>
	<meta
          name="description"
          content="Taming the Tongue | James Chapter - 3 in Tamil Bible Verse | யாக்கோபு அதிகாரம்-3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil Verse"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
<h2>
* நாக்கை அடக்குதல் *
			</h2>
<br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. என் சகோதரரே, அதிக ஆக்கினையை அடைவோம் என்று அறிந்து, உங்களில் அநேகர் போதகராகாதிருப்பீர்களாக.
	<br /><br />
	2. நாம் எல்லாரும் அநேக விஷயங்களில் தவறுகிறோம்; ஒருவன் சொல்தவறாதவனானால் அவன் பூரணபுருஷனும், தன் சரீரமுழுவதையும் கடிவாளத்தினாலே அடக்கிக்கொள்ளக்கூடியவனுமாயிருக்கிறான்.
	<br /><br />
	3. பாருங்கள், குதிரைகள் நமக்குக் கீழ்ப்படியும்படிக்கு அவைகளின் வாய்களில் கடிவாளம்போட்டு, அவைகளுடைய முழுச்சரீரத்தையும் திருப்பி நடத்துகிறோம்.
	<br /><br />
	4. கப்பல்களையும் பாருங்கள், அவைகள் மகா பெரியவைகளாயிருந்தாலும், கடுங்காற்றுகளால் அடிபட்டாலும், அவைகளை நடத்துகிறவன் போகும்படி யோசிக்கும் இடம் எதுவோ அவ்விடத்திற்கு நேராக மிகவும் சிறிதான சுக்கானாலே திருப்பப்படும்.
	<br /><br />
	5. அப்படியே, நாவானதும் சிறிய அவயவமாயிருந்தும் பெருமையானவைகளைப் பேசும். பாருங்கள், சிறிய நெருப்பு எவ்வளவு பெரிய காட்டைக் கொளுத்திவிடுகிறது!
	<br /><br />
	6. நாவும் நெருப்புத்தான், அது அநீதி நிறைந்த உலகம்; நம்முடைய அவயவங்களில் நாவானது முழுச்சரீரத்தையும் கறைப்படுத்தி, ஆயுள் சக்கரத்தைக் கொளுத்திவிடுகிறதாயும், நரக அக்கினியினால் கொளுத்தப்படுகிறதாயும் இருக்கிறது!
	<br /><br />
	7. சகலவிதமான மிருகங்கள், பறவைகள், ஊரும்பிராணிகள், நீர்வாழும் ஜெந்துக்கள் ஆகிய இவைகளின் சுபாவம் மனுஷசுபாவத்தால் அடக்கப்படும், அடக்கப்பட்டதுமுண்டு.
	<br /><br />
	8. நாவை அடக்க ஒரு மனுஷனாலும் கூடாது; அது அடங்காத பொல்லாங்குள்ளதும் சாவுக்கேதுவான விஷம் நிறைந்ததுமாயிருக்கிறது.
	<br /><br />
	9. அதினாலே நாம் பிதாவாகிய தேவனைத் துதிக்கிறோம்; தேவனுடைய சாயலின்படி உண்டாக்கப்பட்ட மனுஷரை அதினாலேயே சபிக்கிறோம்.
	<br /><br />
	10. துதித்தலும் சபித்தலும் ஒரே வாயிலிருந்து புறப்படுகிறது. என் சகோதரரே, இப்படியிருக்கலாகாது.
	<br /><br />
	11. ஒரே ஊற்றுக்கண்ணிலிருந்து தித்திப்பும் கசப்புமான தண்ணீர் சுரக்குமா?
	<br /><br />
	12. என் சகோதரரே, அத்திமரம் ஒலிவப்பழங்களையும், திராட்சச்செடி அத்திப்பழங்களையும் கொடுக்குமா? அப்படியே உவர்ப்பான நீரூற்றுத் தித்திப்பான ஜலத்தைக் கொடுக்கமாட்டாது.
	<br /><br />
	13. உங்களில் ஞானியும் விவேகியுமாயிருக்கிறவன் எவனோ, அவன் ஞானத்திற்குரிய சாந்தத்தோடே தன் கிரியைகளை நல்ல நடக்கையினாலே காண்பிக்கக்கடவன்.
	<br /><br />
	14. உங்கள் இருதயத்திலே கசப்பான வைராக்கியத்தையும் விரோதத்தையும் வைத்தீர்களானால், நீங்கள் பெருமைபாராட்டாதிருங்கள்; சத்தியத்திற்கு விரோதமாய்ப் பொய்சொல்லாமலுமிருங்கள்.
	<br /><br />
	15. இப்படிப்பட்ட ஞானம் பரத்திலிருந்து இறங்கிவருகிற ஞானமாயிராமல், லௌகிக சம்பந்தமானதும், ஜென்மசுபாவத்துக்குரியதும், பேய்த்தனத்துக்கடுத்ததுமாயிருக்கிறது.
	<br /><br />
	16. வைராக்கியமும் விரோதமும் எங்கே உண்டோ, அங்கே கலகமும் சகல துர்ச்செய்கைகளுமுண்டு.
	<br /><br />
	17. பரத்திலிருந்து வருகிற ஞானமோ முதலாவது சுத்தமுள்ளதாயும், பின்பு சமாதானமும் சாந்தமும் இணக்கமுமுள்ளதாயும், இரக்கத்தாலும் நற்கனிகளாலும் நிறைந்ததாயும், பட்சபாதமில்லாததாயும், மாயமற்றதாயுமிருக்கிறது.
	<br /><br />
	18. நீதியாகிய கனியானது சமாதானத்தை நடப்பிக்கிறவர்களாலே சமாதானத்திலே விதைக்கப்படுகிறது.
</p>



      {/* End of verse */} 

	  <br />
<h3>
* யாக்கோபு 3 நாம் சொல்லும் வார்த்தைகளின் ஆபத்துகளைப் பற்றி அறிவுறுத்துகிறது. நாக்கு வாழ்க்கையின் போக்கை அமைக்கும் திறனைக் கொண்டுள்ளது, யாக்கோபு அதை ஒரு கப்பலைச் செலுத்தும் சுக்கான் மற்றும் அதன் பாதையில் உள்ள அனைத்தையும் அழிக்கக்கூடிய நெருப்புடன் ஒப்பிடுகிறார். ஒரே வாயிலிருந்து புகழ்ச்சியும் சாபமும் வரும் என்று அவர் வேறுபடுத்திக் காட்டுகிறார், இருப்பினும் உண்மையான நம்பிக்கை நம் வார்த்தைகளால் தெளிவாகத் தெரிகிறது. பரலோகத்திலிருந்து வரும் ஞானத்தின் வரையறையுடன் இந்த அத்தியாயம் முடிவடைகிறது. அது "சமாதானத்தை விரும்புகிற, அக்கறையுள்ள, கீழ்ப்படிதலுள்ள, இரக்கத்தினாலும் நல்ல கனிகளினாலும் நிறைந்தது, பாரபட்சமற்றது மற்றும் நேர்மையானது" (வச. 3:17). *
			</h3>
<br />
      </div>
    </div>
    </>
  );
}

export default James3