import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 13 </title>
	<meta
          name="description"
          content="Jeremiah 13 | எரேமியா 13 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் என்னை நோக்கி: நீ போய், உனக்கு ஒரு சணல் கச்சையை வாங்கி, அதை உன் அரையிலே கட்டிக்கொள்; அதைத் தண்ணீரிலே படவொட்டாதே என்றார்.
	<br /><br />
	2. நான் கர்த்தருடைய வார்த்தையின்படியே ஒரு கச்சையை வாங்கி, அதை என் அரையிலே கட்டிக்கொண்டேன்.
	<br /><br />
	3. இரண்டாம்விசை கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	4. நீ வாங்கினதும் உன் அரையிலிருக்கிறதுமான கச்சையை எடுத்துக்கொண்டு எழுந்து, ஐப்பிராத்து நதிமட்டும் போய், அதை அங்கே ஒரு கன்மலை வெடிப்பிலே ஒளித்துவை என்றார்.
	<br /><br />
	5. நான் போய், கர்த்தர் எனக்குக் கட்டளையிட்டபடியே அதை ஐப்பிராத்து நதியின் ஓரத்தில் ஒளித்துவைத்தேன்.
	<br /><br />
	6. அநேகநாள் சென்றபின்பு கர்த்தர் என்னை நோக்கி: நீ எழுந்து ஐப்பிராத்து நதிக்குப்போய் அங்கே ஒளித்துவைக்க நான் உனக்குக் கட்டளையிட்ட கச்சையை அவ்விடத்திலிருந்து எடுத்துக்கொண்டுவா என்றார்.
	<br /><br />
	7. அப்பொழுது நான் ஐப்பிராத்து நதிக்குப்போய், கச்சையை ஒளித்துவைத்த இடத்திலே தோண்டி அதை எடுத்தேன்; ஆனால், இதோ, அந்தக் கச்சை கெட்டு ஒன்றுக்கும் உதவாமற் போயிற்று.
	<br /><br />
	8. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	9. இப்படியே நான் யூதாவுடைய பெருமையையும், எருசலேமுடைய மிகுந்த பெருமையையும் கெட்டுப்போகப்பண்ணுவேன்.
	<br /><br />
	10. என் வார்த்தைகளைக் கேட்கமாட்டோம் என்று மறுத்து, தங்கள் இருதயத்தின் கடினத்தின்படி நடந்து, அந்நிய தேவர்களைச் சேவிக்கவும் அவர்களைப் பணிந்துகொள்ளவும் அவர்களைப் பின்பற்றுகிற இந்தப் பொல்லாத ஜனங்கள் ஒன்றுக்கும் உதவாமற்போன இந்தக் கச்சையைப்போலாவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. கச்சையானது மனுஷனுடைய அரைக்குச் சேர்க்கையாயிருக்கிறதுபோல, நான் இஸ்ரவேல் குடும்பத்தார் அனைவரையும் யூதாவின் குடும்பத்தார் அனைவரையும், எனக்கு ஜனங்களாகவும், கீர்த்தியாகவும், துதியாகவும், மகிமையாகவும் சேர்க்கையாக்கிக் கொண்டேன்; ஆனாலும் அவர்கள் செவிகொடாமற்போனார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. சகல ஜாடிகளும் திராட்சரசத்தினாலே நிரப்பப்படுமென்று, இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறார் என்கிற வார்த்தையை அவர்களுடனே சொல்; அதற்கு அவர்கள்: சகல ஜாடிகளும் திராட்சரசத்தினாலே நிரப்பப்படுவது எங்களுக்குத் தெரியாதா என்று உன்னுடனே சொல்லுவார்கள்.
	<br /><br />
	13. அப்பொழுது நீ அவர்களை நோக்கி: இதோ, இந்தத் தேசத்தின் குடிகளெல்லாரையும், தாவீதின் சிங்காசனத்தின்மேல் உட்கார்ந்திருக்கிற ராஜாக்களையும், ஆசாரியர்களையும், தீர்க்கதரிசிகளையும், எருசலேமின் குடிகள் எல்லாரையும் நான் வெறியினால் நிரப்பி,
	<br /><br />
	14. பிதாக்களும் பிள்ளைகளுமாகிய அவர்களை ஒருவர்மேல் ஒருவர் மோதி விழும்படிப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்; நான் அவர்களை அழிப்பதேயன்றி மன்னிப்பதுமில்லை, தப்பவிடுவதுமில்லை, இரங்குவதுமில்லையென்று கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	15. நீங்கள் செவிகொடுத்துக் கேளுங்கள்; மேட்டிமையாய் இராதேயுங்கள்; கர்த்தர் விளம்பினார்.
	<br /><br />
	16. அவர் அந்தகாரத்தை வரப்பண்ணுவதற்கு முன்னும், இருண்ட மலைகளில் உங்கள் கால்கள் இடறுவதற்கு முன்னும், நீங்கள் வெளிச்சத்துக்குக் காத்திருக்கும்போது, அவர் அதை அந்தகாரமும் காரிருளுமாக மாறப்பண்ணுவதற்கு முன்னும், உங்கள் தேவனாகிய கர்த்தருக்கு மகிமையைச் செலுத்துங்கள்.
	<br /><br />
	17. நீங்கள் இதைக் கேளாமற் போனீர்களானால், என் ஆத்துமா மறைவிடங்களில் உங்கள் பெருமையினிமித்தம் துக்கித்து, கர்த்தருடைய மந்தை சிறைப்பட்டுப்போனதென்று என் கண் மிகவும் அழுது கண்ணீர் சொரியும்.
	<br /><br />
	18. நீங்கள் ராஜாவையும் ராஜாத்தியையும் நோக்கி: தாழவந்து உட்காருங்கள்; உங்கள் சிரசின் சிங்காரமாகிய உங்கள் மகிமையின் கிரீடம் விழுந்ததென்று சொல்லுங்கள்.
	<br /><br />
	19. தெற்கிலுள்ள பட்டணங்கள் அடைக்கப்பட்டன; அவைகளைத் திறப்பார் இல்லை; யூதா அனைத்தும் குடிவிலக்கப்பட்டுப்போம்; அது சமூலமாய்ச் சிறைப்பட்டுப்போம்.
	<br /><br />
	20. உங்கள் கண்களை ஏறெடுத்து, வடக்கேயிருந்து வருகிறவர்களைப் பாருங்கள்; உனக்குக் கொடுக்கப்பட்டிருந்த மந்தையும், உன் மகிமையான ஆட்டுக்கிடையும் எங்கே?
	<br /><br />
	21. அவர் உன்னை விசாரிக்கும்போது என்ன சொல்லுவாய்? அவர்கள் உன்மேல் ஆதிக்கக்காரரும் தலைவருமாயிருக்க, நீ அவர்களைப் பழக்குவித்தாயே; கர்ப்பவதிக்குப் பிரசவவேதனை உண்டாகும்போது உண்டாகும் வேதனைகளைப்போல் வேதனைகள் உன்னைப் பிடிப்பதில்லையோ?
	<br /><br />
	22. இவைகள் எனக்கு நேரிட்டது ஏதென்று நீ உன் இருதயத்தில் சொன்னாயாகில், உன் திரளான அக்கிரமத்தினிமித்தமே உன் வஸ்திரத்து ஓரங்கள் விலக்கப்பட்டு, உன் பாதங்கள் பலவந்தஞ்செய்யப்படுகின்றன.
	<br /><br />
	23. எத்தியோப்பியன் தன் தோலையும், சிவிங்கி தன் புள்ளிகளையும் மாற்றக்கூடுமோ? கூடுமானால், தீமைசெய்யப் பழகின நீங்களும் நன்மைசெய்யக்கூடும்.
	<br /><br />
	24. ஆதலால் வனாந்தரக் காற்றால் பறக்கடிக்கப்படும் துரும்பைப்போல அவர்களைச் சிதறடிப்பேன்.
	<br /><br />
	25. நீ என்னை மறந்து, பொய்யை நம்பினபடியினாலே, இது உன்னுடைய வீதமும், என்னால் உனக்கு அளக்கப்படும் உன்னுடைய பங்குமாயிருக்கும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	26. உன் மானம் காணப்பட நான் உன் வஸ்திரத்து ஓரங்களை உன் முகமட்டாக எடுத்துப்போடுவேன்.
	<br /><br />
	27. உன் விபசாரங்களையும், உன் கனைக்குதல்களையும், வெளியிலே மேடுகளின்மேல் நீ பண்ணின வேசித்தனத்தின் முறைகேடுகளாகிய உன் அருவருப்புகளையும் நான் கண்டேன்; எருசலேமே, உனக்கு ஐயோ! நீ சுத்திகரிக்கப்படமாட்டாயா? இது இன்னும் எத்தனை காலத்துக்குப்பின் நடக்கும்? என்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah13