import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 2 </title>
	<meta
          name="description"
          content="Zechariah 2 | சகரியா 2 | Sageria 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் என் கண்களை ஏறெடுத்துப் பார்த்தபோது, இதோ, தன் கையிலே அளவுநூல் பிடித்திருந்த ஒரு புருஷனைக் கண்டேன்.
	<br /><br />
	2. நீர் எவ்விடத்துக்குப் போகிறீர் என்று கேட்டேன்; அதற்கு அவர்: எருசலேமின் அகலம் இவ்வளவு என்றும் அதின் நீளம் இவ்வளவு என்றும் அறியும்படி அதை அளக்கிறதற்குப் போகிறேன் என்றார்.
	<br /><br />
	3. இதோ, என்னோடே பேசின தூதன் புறப்பட்டபோது, வேறொரு தூதன் அவரைச் சந்திக்கும்படிப் புறப்பட்டுவந்தான்.
	<br /><br />
	4. இவனை அவர் நோக்கி: நீ ஓடி இந்த வாலிபனிடத்தில் சொல்லவேண்டியது என்னவென்றால், எருசலேம் தன் நடுவிலே கூடும் மனுஷரின் திரளினாலும் மிருகஜீவன்களின் திரளினாலும் மதிலில்லாத பட்டணங்கள்போல் வாசஸ்தலமாகும்.
	<br /><br />
	5. நான் அதற்குச் சுற்றிலும் அக்கினி மதிலாயிருந்து, அதின் நடுவில் மகிமையாக இருப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. ஓகோ, நீங்கள் எழும்பி வடதேசத்திலிருந்து ஓடிவாருங்கள் என்று கர்த்தர் சொல்லுகிறார்; ஆகாயத்து நான்கு திசைகளிலும் உங்களை நான் சிதறப்பண்ணினேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. பாபிலோன் குமாரத்தியினிடத்தில் குடியிருக்கிற சீயோனே, உன்னை விடுவித்துக்கொள்.
	<br /><br />
	8. பிற்பாடு மகிமையுண்டாகும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; உங்களைக் கொள்ளையிட்ட ஜாதிகளிடத்துக்கு என்னை அனுப்பினார்; உங்களைத் தொடுகிறவன் அவருடைய கண்மணியைத் தொடுகிறான்.
	<br /><br />
	9. இதோ, நான் என் கையை அவர்களுக்கு விரோதமாக அசைப்பேன்; அதினால் அவர்கள் தங்கள் அடிமைகளுக்குக் கொள்ளையாவார்கள்; அப்பொழுது சேனைகளின் கர்த்தர் என்னை அனுப்பினாரென்று அறிவீர்கள்.
	<br /><br />
	10. சீயோன் குமாரத்தியே, கெம்பீரித்துப்பாடு; இதோ, நான் வந்து உன் நடுவில் வாசம்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. அந்நாளிலே அநேகம் ஜாதிகள் கர்த்தரைச் சேர்ந்து என் ஜனமாவார்கள்; நான் உன் நடுவில் வாசமாயிருப்பேன்; அப்பொழுது சேனைகளின் கர்த்தர் என்னை உன்னிடத்தில் அனுப்பினாரென்று அறிவாய்.
	<br /><br />
	12. கர்த்தர் பரிசுத்த தேசத்திலே யூதாவாகிய தமது பங்கைச் சுதந்தரித்து, திரும்பவும் எருசலேமைத் தெரிந்துகொள்வார்.
	<br /><br />
	13. மாம்சமான சகலமான பேர்களே, கர்த்தருக்கு முன்பாக மௌனமாயிருங்கள்; அவர் தமது பரிசுத்த வாசஸ்தலத்திலிருந்து எழுந்தருளினார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah2