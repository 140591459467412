import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah57 = () => {
  const verseNumber = 57;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 57 </title>
	<meta
          name="description"
          content="Isaiah 57 | ஏசாயா 57 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீதிமான் மடிந்துபோகிறான், ஒருவரும் அதை மனதில் வைக்கிறதில்லை; புத்திமான்கள் எடுத்துக்கொள்ளப்படுகிறார்கள், ஆனாலும் தீங்குவராததற்குமுன்னே நீதிமான் எடுத்துக்கொள்ளப்படுகிறான் என்பதைச் சிந்திப்பார் இல்லை.
	<br /><br />
	2. நேர்மையாய் நடந்தவர்கள் சமாதானத்துக்குள் பிரவேசித்து, தங்கள் படுக்கைகளில் இளைப்பாறுகிறார்கள்.
	<br /><br />
	3. நாள்பார்க்கிறவளின் பிள்ளைகளே, விபசாரனுக்கும் வேசிக்கும் பிறந்த சந்ததியாரே, நீங்கள் இங்கே கிட்டிவாருங்கள்.
	<br /><br />
	4. நீங்கள் யாரைப் பரியாசம்பண்ணுகிறீர்கள்? யாருக்கு விரோதமாய் வாயைத் திறந்து, நாக்கை நீட்டுகிறீர்கள்? நீங்கள் துரோகம்பண்ணுகிற பிள்ளைகளும், கள்ளச் சந்ததியாருமல்லவோ?
	<br /><br />
	5. நீங்கள் பச்சையான சகல மரத்தின்கீழும், விக்கிரக தேவர்களோடே மோகாக்கினியில் வெந்து, பள்ளத்தாக்குகளிலே கன்மலை வெடிப்புகளின் கீழ்ப் பிள்ளைகளைக் கொன்றுபோடுகிறவர்கள்.
	<br /><br />
	6. பள்ளத்தாக்குகளிலுள்ள வழவழப்பான சிலைகளிடத்தில் உன் பங்கு இருக்கிறது; அவைகள், அவைகளே உன் வீதம்; அவைகளுக்கு நீ பானபலியை வார்த்து, போஜனபலியையும் செலுத்துகிறாய்; இவைகளின்மேல் பிரியப்படுவேனோ?
	<br /><br />
	7. நீ உயரமும் உன்னதமுமான மலைகளின்மேல் உன் மஞ்சத்தை வைக்கிறாய்; அங்கேயும் பலியிடும்படி ஏறுகிறாய்.
	<br /><br />
	8. கதவுகளுக்கும் நிலைகளுக்கும் பின்னாக உன் ஞாபகக்குறியை வைக்கிறாய்; நீ என்னைவிட்டுப்போய் மற்றவர்களுக்கு உன்னை வெளிப்படுத்தினாய்; ஏறிப்போய் உன் மஞ்சத்தை அகலமாக்கி, அவர்களோடே உடன்படிக்கை பண்ணினாய்; அவர்களுடைய மஞ்சத்தைக் காண்கிற எல்லா இடத்திலும் அதை நேசிக்கிறாய்.
	<br /><br />
	9. நீ தைலத்தைப் பூசிக்கொண்டு ராஜாவினிடத்தில் போகிறாய்; உன் பரிமளங்களை மிகுதியாக்கி, உன் ஸ்தானாபதிகளைத் தூரத்துக்கு அனுப்பி, உன்னைப் பாதாளமட்டும் தாழ்த்துகிறாய்.
	<br /><br />
	10. வழிதூரமானதால் உழன்றுபோகிறாய்; அது விருதாவென்று நீ சொல்லுகிறதில்லை; உன் கைபெலத்தைக் கண்டுபிடித்தாய்; ஆகையால் நீ ஆயாசப்படவில்லை.
	<br /><br />
	11. நீ யாருக்கு அஞ்சிப் பயப்படுகிறாய், நீ பொய்சொல்லுகிறாயே; நீ என்னை நினையாமலும், உன் மனதிலே வைக்காமலும் போகிறாய்; நான் வெகுகாலம் மவுனமாயிருந்தேன் அல்லவா? ஆகையால் எனக்குப் பயப்படாதிருக்கிறாய்.
	<br /><br />
	12. உன் நீதியையும் உன் கிரியைகளையும் நான் வெளிப்படுத்துவேன், அவைகள் உனக்கு உதவாது.
	<br /><br />
	13. நீ கூப்பிடும்போது, உன் கணங்கள் உன்னைத் தப்புவிக்கட்டும்; காற்று அவைகளையெல்லாம் பறக்கடித்து, மாயை அவைகளைக் கொண்டுபோம்; என்னை நம்பியிருக்கிறவனோ தேசத்தைச் சுதந்தரித்து, என் பரிசுத்த பர்வதத்திலே காணியாட்சிக்காரனாயிருப்பான்.
	<br /><br />
	14. வழியை உயர்த்தி உயர்த்தி, அதைச் செம்மைப்படுத்தி, இடறல்களை என் ஜனத்தின் வழியிலிருந்து எடுத்துப்போடுங்கள் என்னப்படும்.
	<br /><br />
	15. நித்தியவாசியும் பரிசுத்தர் என்கிற நாமமுள்ளவருமாகிய மகத்துவமும் உன்னதமுமானவர் சொல்லுகிறார்: உன்னதத்திலும் பரிசுத்த ஸ்தலத்திலும் வாசம்பண்ணுகிற நான், பணிந்தவர்களின் ஆவியை உயிர்ப்பிக்கிறதற்கும், நொறுங்கினவர்களின் இருதயத்தை உயிர்ப்பிக்கிறதற்கும், நொறுங்குண்டு பணிந்த ஆவியுள்ளவர்களிடத்திலும் வாசம்பண்ணுகிறேன்.
	<br /><br />
	16. நான் எப்போதும் வழக்காடமாட்டேன்; நான் என்றைக்கும் கோபமாயிருப்பதுமில்லை; ஏனென்றால், ஆவியும், நான் உண்டுபண்ணின ஆத்துமாக்களும், என் முகத்துக்கு முன்பாகச் சோர்ந்துபோகுமே.
	<br /><br />
	17. நான் அவர்கள் பொருளாசையென்னும் அக்கிரமத்தினிமித்தம் கடுங்கோபமாகி, அவர்களை அடித்தேன்; நான் மறைந்து, கடுங்கோபமாயிருந்தேன்; தங்கள் மனம்போனபோக்கிலே மாறுபாடாய் நடந்தார்களே.
	<br /><br />
	18. அவர்கள் வழிகளை நான் பார்த்து, அவர்களைக் குணமாக்குவேன்; அவர்களை நடத்தி, திரும்பவும் அவர்களுக்கும் அவர்களிலே துக்கப்படுகிறவர்களுக்கும் ஆறுதல் அளிப்பேன்.
	<br /><br />
	19. தூரமாயிருக்கிறவர்களுக்கும் சமீபமாயிருக்கிறவர்களுக்கும் சமாதானம் சமாதானம் என்று கூறும் உதடுகளின் பலனைச் சிருஷ்டிக்கிறேன்; அவர்களைக் குணமாக்குவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	20. துன்மார்க்கரோ கொந்தளிக்கும் கடலைப்போலிருக்கிறார்கள்; அது அமர்ந்திருக்கக் கூடாமல், அதின் ஜலங்கள் சேற்றையும் அழுக்கையும் கரையில் ஒதுக்குகிறது.
	<br /><br />
	21. துன்மார்க்கருக்குச் சமாதானம் இல்லையென்று என் தேவன் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah57