import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 9 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 9 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 9 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பரிசுத்தவான்களுக்குச் செய்யவேண்டிய தர்மசகாயத்தைக்குறித்து, நான் அதிகமாக உங்களுக்கு எழுதவேண்டுவதில்லை.
	<br /><br />
	2. உங்கள் மனவிருப்பத்தை அறிந்திருக்கிறேன்; அகாயாவிலுள்ளவர்கள் ஒருவருஷமாக ஆயத்தமாயிருக்கிறார்களென்று, நான் மக்கெதோனியருடனே சொல்லி, உங்களைப் புகழ்ந்தேனே; உங்கள் ஜாக்கிரதை அநேகரை எழுப்பிவிட்டதுமுண்டு.
	<br /><br />
	3. அப்படியிருந்தும், உங்களைக்குறித்து நாங்கள் சொன்ன புகழ்ச்சி இந்தக் காரியத்தில் வீணாய்ப்போகாமல், நான் சொன்னபடி நீங்கள் ஆயத்தப்பட்டவர்களாயிருப்பதற்கு, இந்தச் சகோதரரை அனுப்பினேன்.
	<br /><br />
	4. மக்கெதோனியர் என்னுடனேகூட வந்து, உங்களை ஆயத்தப்படாதவர்களாகக் கண்டால், இவ்வளவு நிச்சயமாய் உங்களைப் புகழ்ந்ததற்காக, நீங்கள் வெட்கப்படுவீர்களென்று நாங்கள் சொல்லாமல், நாங்களே வெட்கப்படவேண்டியதாயிருக்கும்.
	<br /><br />
	5. ஆகையால், வாக்குத்தத்தம் பண்ணப்பட்டிருக்கிற உங்கள் தானதர்மமானது லோபத்தனமாய்க் கொடுக்கப்பட்டதாயிராமல், உதாரத்துவமாய்க் கொடுக்கப்பட்டதாயிருக்கும்படியாக அதை ஆயத்தப்படுத்துகிறதற்குச் சகோதரரை ஏவி, உங்களிடத்தில் முன்னதாக அனுப்புவது எனக்கு அவசியம் என்று காணப்பட்டது.
	<br /><br />
	6. பின்னும் நான் சொல்லுகிறதென்னவெனில், சிறுக விதைக்கிறவன் சிறுக அறுப்பான், பெருக விதைக்கிறவன் பெருக அறுப்பான்.
	<br /><br />
	7. அவனவன் விசனமாயுமல்ல, கட்டாயமாயுமல்ல, தன் மனதில் நியமித்தபடியே கொடுக்கக்கடவன்; உற்சாகமாய்க் கொடுக்கிறவனிடத்தில் தேவன் பிரியமாயிருக்கிறார்.
	<br /><br />
	8. மேலும், நீங்கள் எல்லாவற்றிலும் எப்பொழுதும் சம்பூரணமுடையவர்களாயும், சகலவித நற்கிரியைகளிலும் பெருகுகிறவர்களாயுமிருக்கும்படியாக, தேவன் உங்களிடத்தில் சகலவித கிருபையையும் பெருகச்செய்ய வல்லவராயிருக்கிறார்.
	<br /><br />
	9. வாரியிறைத்தான், ஏழைகளுக்குக் கொடுத்தான், அவனுடைய நீதி என்றென்றைக்கும் நிற்கும் என்று எழுதியிருக்கிறபடியாகும்.
	<br /><br />
	10. விதைக்கிறவனுக்கு விதையையும், புசிக்கிறதற்கு ஆகாரத்தையும் அளிக்கிறவர் உங்களுக்கு விதையை அளித்து, அதைப் பெருகப்பண்ணி, உங்கள் நீதியின் விளைச்சலை வர்த்திக்கச்செய்வார்.
	<br /><br />
	11. தேவனுக்கு எங்களால் ஸ்தோத்திரமுண்டாவதற்கு ஏதுவாயிருக்கும் மிகுந்த உதாரகுணத்திலே நீங்கள் எவ்விதத்திலும் சம்பூரணமுள்ளவர்களாவீர்கள்.
	<br /><br />
	12. இந்தத் தர்மசகாயமாகிய பணிவிடை பரிசுத்தவான்களுடைய குறைவுகளை நீக்குகிறதுமல்லாமல், அநேகர் தேவனை ஸ்தோத்திரிப்பதினாலே சம்பூரண பலனுள்ளதாயும் இருக்கும்.
	<br /><br />
	13. அவர்கள் இந்தத் தர்மசகாயத்தினாலாகிய நன்மையை அனுபவித்து, நீங்கள் கிறிஸ்துவின் சுவிசேஷத்தைக் கீழ்ப்படிதலோடே அறிக்கையிட்டிருக்கிறதினிமித்தமும், தங்களுக்கும் மற்ற அனைவருக்கும் நீங்கள் உதாரத்துவமாய்த் தர்மஞ்செய்கிறதினிமித்தமும், அவர்கள் தேவனை மகிமைப்படுத்தி;
	<br /><br />
	14. உங்களுக்காக வேண்டுதல்செய்து, தேவன் உங்களுக்கு அளித்த மிகவும் விசேஷித்த கிருபையினிமித்தம் உங்கள்மேல் வாஞ்சையாயிருக்கிறார்கள்.
	<br /><br />
	15. தேவன் அருளிய சொல்லிமுடியாத ஈவுக்காக அவருக்கு ஸ்தோத்திரம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians9