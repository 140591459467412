import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 5 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 5
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவ்விதமாக, நாம் விசுவாசத்தினாலே நீதிமான்களாக்கப்பட்டிருக்கிறபடியால், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துமூலமாய் தேவனிடத்தில் சமாதானம் பெற்றிருக்கிறோம்.
	<br /><br />
	2. அவர்மூலமாய் நாம் இந்தக் கிருபையில் பிரவேசிக்கும் சிலாக்கியத்தை விசுவாசத்தினால் பெற்று நிலைகொண்டிருந்து, தேவமகிமையை அடைவோமென்கிற நம்பிக்கையினாலே மேன்மைபாராட்டுகிறோம்.
	<br /><br />
	3. அதுமாத்திரமல்ல, உபத்திரவம் பொறுமையையும், பொறுமை பரீட்சையையும், பரீட்சை நம்பிக்கையையும் உண்டாக்குகிறதென்று நாங்கள் அறிந்து,
	<br /><br />
	4. உபத்திரவங்களிலேயும் மேன்மைபாராட்டுகிறோம்.
	<br /><br />
	5. மேலும் நமக்கு அருளப்பட்ட பரிசுத்தஆவியினாலே தேவ அன்பு நம்முடைய இருதயங்களில் ஊற்றப்பட்டிருக்கிறபடியால், அந்த நம்பிக்கை நம்மை வெட்கப்படுத்தாது.
	<br /><br />
	6. அன்றியும் நாம் பெலனற்றவர்களாயிருக்கும்போதே, குறித்த காலத்தில் கிறிஸ்து அக்கிரமக்காரருக்காக மரித்தார்.
	<br /><br />
	7. நீதிமானுக்காக ஒருவன் மரிக்கிறது அரிது; நல்லவனுக்காக ஒருவேளை ஒருவன் மரிக்கத் துணிவான்.
	<br /><br />
	8. நாம் பாவிகளாயிருக்கையில் கிறிஸ்து நமக்காக மரித்ததினாலே, தேவன் நம்மேல் வைத்த தமது அன்பை விளங்கப்பண்ணுகிறார்.
	<br /><br />
	9. இப்படி நாம் அவருடைய இரத்தத்தினாலே நீதிமான்களாக்கப்பட்டிருக்க, கோபாக்கினைக்கு நீங்கலாக அவராலே நாம் இரட்சிக்கப்படுவது அதிக நிச்சயமாமே.
	<br /><br />
	10. நாம் தேவனுக்குச் சத்துருக்களாயிருக்கையில், அவருடைய குமாரனின் மரணத்தினாலே அவருடனே ஒப்புரவாக்கப்பட்டோமானால், ஒப்புரவாக்கப்பட்டபின் நாம் அவருடைய ஜீவனாலே இரட்சிக்கப்படுவது அதிக நிச்சயமாமே.
	<br /><br />
	11. அதுவுமல்லாமல், இப்பொழுது ஒப்புரவாகுதலை நமக்குக் கிடைக்கப்பண்ணின நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துமூலமாய் நாம் தேவனைப்பற்றியும் மேன்மைபாராட்டுகிறோம்.
	<br /><br />
	12. இப்படியாக, ஒரே மனுஷனாலே பாவமும் பாவத்தினாலே மரணமும் உலகத்திலே பிரவேசித்ததுபோலவும், எல்லா மனுஷரும் பாவஞ்செய்தபடியால், மரணம் எல்லாருக்கும் வந்ததுபோலவும் இதுவுமாயிற்று.
	<br /><br />
	13. நியாயப்பிரமாணம் கொடுக்கப்படுவதற்கு முன்னும் பாவம் உலகத்திலிருந்தது; நியாயப்பிரமாணம் இல்லாதிருந்தால் பாவம் எண்ணப்படமாட்டாது.
	<br /><br />
	14. அப்படியிருந்தும், மரணமானது ஆதாம்முதல் மோசேவரைக்கும், ஆதாமின் மீறுதலுக்கொப்பாய்ப் பாவஞ்செய்யாதவர்களையும் ஆண்டுகொண்டது; அந்த ஆதாம் பின்பு வந்தவருக்கு முன்னடையாளமானவன்.
	<br /><br />
	15. ஆனாலும் மீறுதலின் பலன் கிருபை வரத்தின் பலனுக்கு ஒப்பானதல்ல. எப்படியெனில், ஒருவனுடைய மீறுதலினாலே அநேகர் மரித்திருக்க, தேவனுடைய கிருபையும் இயேசுகிறிஸ்து என்னும் ஒரே மனுஷனுடைய கிருபையினாலே வரும் ஈவும் அநேகர்மேல் அதிகமாய்ப் பெருகியிருக்கிறது.
	<br /><br />
	16. மேலும் ஒருவன் பாவஞ்செய்ததினால் உண்டான தீர்ப்பு தேவன் அருளும் ஈவுக்கு ஒப்பானதல்ல; அந்தத் தீர்ப்பு ஒரே குற்றத்தினிமித்தம் ஆக்கினைக்கு ஏதுவாயிருந்தது; கிருபைவரமோ அநேக குற்றங்களை நீக்கி நீதிவிளங்கும் தீர்ப்புக்கு ஏதுவாயிருக்கிறது.
	<br /><br />
	17. அல்லாமலும், ஒருவனுடைய மீறுதலினாலே, அந்த ஒருவன்மூலமாய், மரணம் ஆண்டுகொண்டிருக்க, கிருபையின் பரிபூரணத்தையும் நீதியாகிய ஈவின் பரிபூரணத்தையும் பெறுகிறவர்கள் இயேசுகிறிஸ்து என்னும் ஒருவராலே ஜீவனை அடைந்து ஆளுவார்களென்பது அதிக நிச்சயமாமே.
	<br /><br />
	18. ஆகையால் ஒரே மீறுதலினாலே எல்லா மனுஷருக்கும் ஆக்கினைக்கு ஏதுவான தீர்ப்பு உண்டானதுபோல, ஒரே நீதியினாலே எல்லா மனுஷருக்கும் ஜீவனை அளிக்கும் நீதிக்கு ஏதுவான தீர்ப்பு உண்டாயிற்று.
	<br /><br />
	19. அன்றியும் ஒரே மனுஷனுடைய கீழ்ப்படியாமையினாலே அநேகர் பாவிகளாக்கப்பட்டதுபோல, ஒருவருடைய கீழ்ப்படிதலினாலே அநேகர் நீதிமான்களாக்கப்படுவார்கள்.
	<br /><br />
	20. மேலும், மீறுதல் பெருகும்படிக்கு நியாயப்பிரமாணம் வந்தது; அப்படியிருந்தும், பாவம் பெருகின இடத்தில் கிருபை அதிகமாய்ப் பெருகிற்று.
	<br /><br />
	21. ஆதலால் பாவம் மரணத்துக்கு ஏதுவாக ஆண்டுகொண்டதுபோல, கிருபையானது நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் மூலமாய் நீதியினாலே நித்தியஜீவனுக்கு ஏதுவாக ஆண்டுகொண்டது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans5