import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 5 </title>
	<meta
          name="description"
          content="Amos 5 | ஆமோஸ் 5 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேல் வம்சத்தாரே, உங்களைக்குறித்து நான் புலம்பிச் சொல்லும் இந்த வசனத்தைக் கேளுங்கள்.
	<br /><br />
	2. இஸ்ரவேல் என்னும் கன்னிகை விழுந்தாள், அவள் இனி ஒருபோதும் எழுந்திருக்கமாட்டாள்; தன் தேசத்தில் விழுந்துகிடக்கிறாள், அவளை எடுப்பாரில்லை.
	<br /><br />
	3. நகரத்திலிருந்து புறப்பட்ட ஆயிரம்பேரில் நூறுபேரும், நூறுபேரில் பத்துப்பேரும் இஸ்ரவேல் வம்சத்தாருக்கு மீந்திருப்பார்கள் என்று கர்த்தராகிய தேவன் சொல்லுகிறார்.
	<br /><br />
	4. கர்த்தர் இஸ்ரவேல் வம்சத்தாருக்குச் சொல்லுகிறது என்னவென்றால்: என்னைத் தேடுங்கள், அப்பொழுது பிழைப்பீர்கள்.
	<br /><br />
	5. பெத்தேலைத் தேடாதேயுங்கள், கில்காலிலும் சேராதேயுங்கள், பெயெர்செபாவுக்கும் போகாதேயுங்கள்; ஏனென்றால் கில்கால் சிறையிருப்பாகவும், பெத்தேல் பாழான ஸ்தலமாகவும் போகும்.
	<br /><br />
	6. கர்த்தரைத் தேடுங்கள், அப்பொழுது பிழைப்பீர்கள்; இல்லாவிட்டால் பெத்தேலில் இருக்கிற ஒருவராலும் அவிக்கப்படாத அக்கினி யோசேப்பின் வீட்டில் பற்றி, அதைப் பட்சிக்கும்.
	<br /><br />
	7. நியாயத்தை எட்டியாக மாற்றி, நீதியைத் தரையிலே விழப்பண்ணுகிறவர்களே, அவரைத் தேடுங்கள்.
	<br /><br />
	8. அவர் அறுமீனையும் மிருகசீரிஷத்தையும் உண்டாக்கினவர்; அவர் மரண இருளை விடியற்காலமாக மாற்றி, பகலை இராத்திரியாக அந்தகாரப்படுத்துகிறவர்; அவர் சமுத்திரத்தின் தண்ணீர்களை வரவழைத்து, அவைகளைப் பூமியின் விசாலத்தின்மேல் ஊற்றுகிறவர்; கர்த்தர் என்பது அவருடைய நாமம்.
	<br /><br />
	9. அரணான ஸ்தலத்தின்மேல் பாழ்க்கடிப்பு வரத்தக்கதாக, அவர் கொள்ளை கொடுத்தவனைப் பலத்தவனுக்கு விரோதமாய் லகுவடையப்பண்ணுகிறவர்.
	<br /><br />
	10. ஒலிமுகவாசலிலே கடிந்துகொள்ளுகிறவனை அவர்கள் பகைத்து, யதார்த்தமாய்ப் பேசுகிறவனை வெறுக்கிறார்கள்.
	<br /><br />
	11. நீங்கள் தரித்திரனை மிதித்து, அவன் கையிலே தானியத்தைச் சுமைசுமையாய் வாங்குகிறபடியினால், நீங்கள் பொளிந்த கற்களால் வீடுகளைக் கட்டினீர்கள், ஆனாலும் அவைகளில் நீங்கள் குடியிருப்பதில்லை; இன்பமான திராட்சத்தோட்டங்களை நாட்டினீர்கள், ஆனாலும் அவைகளின் இரசத்தை நீங்கள் குடிப்பதில்லை.
	<br /><br />
	12. உங்கள் மீறுதல்கள் மிகுதியென்றும், உங்கள் பாவங்கள் பலத்ததென்றும் அறிவேன்; நீதிமானை ஒடுக்கி, பரிதானம் வாங்கி, ஒலிமுகவாசலில் ஏழைகளின் நியாயத்தைப் புரட்டுகிறீர்கள்.
	<br /><br />
	13. ஆகையால் புத்திமான் அந்தக் காலத்திலே மௌனமாயிருக்கவேண்டும்; அந்தக் காலம் தீமையான காலம்.
	<br /><br />
	14. நீங்கள் பிழைக்கும்படிக்குத் தீமையை அல்ல, நன்மையைத் தேடுங்கள்; அப்பொழுது நீங்கள் சொல்லுகிறபடியே சேனைகளின் தேவனாகிய கர்த்தர் உங்களோடே இருப்பார்.
	<br /><br />
	15. நீங்கள் தீமையை வெறுத்து, நன்மையை விரும்பி, ஒலிமுகவாசலில் நியாயத்தை நிலைப்படுத்துங்கள்; ஒருவேளை சேனைகளின் தேவனாகிய கர்த்தர் யோசேப்பிலே மீதியானவர்களுக்கு இரங்குவார்.
	<br /><br />
	16. ஆதலால் ஆண்டவரும் சேனைகளின் தேவனுமாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால்: எல்லாத்தெருக்களிலும் புலம்பல் உண்டாகும்; எல்லா வீதிகளிலும் ஐயோ! ஐயோ! என்று ஓலமிடுவார்கள்; பயிரிடுகிறவர்களைத் துக்கங்கொண்டாடுகிறதற்கும், ஒப்பாரிபாட அறிந்தவர்களைப் புலம்புகிறதற்கும் வரவழைப்பார்கள்.
	<br /><br />
	17. எல்லாத் திராட்சத்தோட்டங்களிலும் புலம்பல் உண்டாயிருக்கும், நான் உன் நடுவே கடந்துபோவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. கர்த்தருடைய நாளை விரும்புகிறவர்களுக்கு ஐயோ! அதினால் உங்களுக்கு என்ன உண்டு? கர்த்தருடைய நாள் வெளிச்சமாயிராமல் அந்தகாரமாயிருக்கும்.
	<br /><br />
	19. சிங்கத்துக்குத் தப்பினவனுக்குக் கரடி எதிர்ப்பட்டதுபோலவும், அல்லது வீட்டுக்குள்ளே வந்து சுவரின்மேல் தன் கையை வைத்தபோது பாம்பு அவனைக் கடித்ததுபோலவும் இருக்கும்.
	<br /><br />
	20. கர்த்தருடைய நாள் வெளிச்சமாயிராமல், இருளும் பிரகாசமற்ற அந்தகாரமுமாயிருக்குமல்லவோ?
	<br /><br />
	21. உங்கள் பண்டிகைகளைப் பகைத்து வெறுக்கிறேன்; உங்கள் ஆசரிப்பு நாட்களில் எனக்குப் பிரியமில்லை.
	<br /><br />
	22. உங்கள் தகனபலிகளையும் போஜனபலிகளையும் எனக்குப் படைத்தாலும் நான் அங்கீகரிக்கமாட்டேன்; கொழுமையான உங்கள் மிருகங்களின் ஸ்தோத்திரபலிகளையும் நான் நோக்கிப் பார்க்கமாட்டேன்.
	<br /><br />
	23. உன் பாட்டுகளின் இரைச்சலை என்னைவிட்டு அகற்று; உன் வீணைகளின் ஓசையை நான் கேட்கமாட்டேன்.
	<br /><br />
	24. நியாயம் தண்ணீரைப்போலவும், நீதி வற்றாத நதியைப்போலவும் புரண்டு வரக்கடவது.
	<br /><br />
	25. இஸ்ரவேல் வம்சத்தாரே, நீங்கள் வனாந்தரத்திலே இருந்த நாற்பது வருஷம்வரையில் பலிகளையும் காணிக்கைகளையும் எனக்குச் செலுத்தினீர்களோ?
	<br /><br />
	26. நீங்கள் உங்களுக்கு உண்டாக்கின மோளோகுடைய கூடாரத்தையும், உங்கள் தேவர்களின் நட்சத்திரராசியாகிய உங்கள் சொரூபங்களின் சப்பரத்தையும் சுமந்துகொண்டுவந்தீர்களே.
	<br /><br />
	27. ஆகையால் உங்களைத் தமஸ்குவுக்கு அப்பாலே குடியோட்டுவேன் என்று சேனைகளுடைய தேவன் என்னும் நாமமுள்ள கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos5