import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title> The Old and New Hebrews-9 Tamil Bible Verse | எபிரெயர் 9</title>
	<meta
          name="description"
          content="The Old and the New Hebrews Chapter-9 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-9 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil Verse"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* பழைய மற்றும் புதிய *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. அன்றியும், முதலாம் உடன்படிக்கையானது ஆராதனைக்கேற்ற முறைமைகளும் பூமிக்குரிய பரிசுத்த ஸ்தலமும் உடையதாயிருந்தது.
	<br /><br />
	2. எப்படியெனில், ஒரு கூடாரம் உண்டாக்கப்பட்டிருந்தது; அதின் முந்தின பாகத்தில் குத்துவிளக்கும், மேஜையும், தேவசமுகத்தப்பங்களும் இருந்தன; அது பரிசுத்த ஸ்தலமென்னப்படும்.
	<br /><br />
	3. இரண்டாந் திரைக்குள்ளே மகா பரிசுத்த ஸ்தலமென்னப்பட்ட கூடாரம் இருந்தது.
	<br /><br />
	4. அதிலே பொன்னாற்செய்த தூபகலசமும், முழுவதும் பொற்றகடு பொதிந்திருக்கப்பட்ட உடன்படிக்கைப் பெட்டியும் இருந்தன; அந்தப் பெட்டியிலே மன்னா வைக்கப்பட்ட பொற்பாத்திரமும், ஆரோனுடைய தளிர்த்த கோலும், உடன்படிக்கையின் கற்பலகைகளும் இருந்தன.
	<br /><br />
	5. அதற்கு மேலே மகிமையுள்ள கேருபீன்கள் வைக்கப்பட்டுக் கிருபாசனத்தை நிழலிட்டிருந்தன; இவைகளைக்குறித்து விவரமாய்ப்பேச இப்பொழுது சமயமில்லை.
	<br /><br />
	6. இவைகள் இவ்விதமாய் ஆயத்தமாக்கப்பட்டிருக்க, ஆசாரியர்கள் ஆராதனை முறைமைகளை நிறைவேற்றும்படிக்கு முதலாங்கூடாரத்திலே நித்தமும் பிரவேசிப்பார்கள்.
	<br /><br />
	7. இரண்டாங்கூடாரத்திலே பிரதான ஆசாரியன்மாத்திரம் வருஷத்திற்கு ஒருதரம் இரத்தத்தோடே பிரவேசித்து, அந்த இரத்தத்தைத் தனக்காகவும் ஜனங்களுடைய தப்பிதங்களுக்காகவும் செலுத்துவான்.
	<br /><br />
	8. அதினாலே, முதலாங்கூடாரம் நிற்குமளவும் பரிசுத்த ஸ்தலத்திற்குப்போகிற மார்க்கம் இன்னும் வெளிப்படவில்லையென்று பரிசுத்த ஆவியானவர் தெரியப்படுத்தியிருக்கிறார்.
	<br /><br />
	9. அந்தக் கூடாரம் இக்காலத்திற்கு உதவுகிற ஒப்பனையாயிருக்கிறது; அதற்கேற்றபடியே செலுத்தப்பட்டுவருகிற காணிக்கைகளும் பலிகளும் ஆராதனை செய்கிறவனுடைய மனச்சாட்சியைப் பூரணப்படுத்தக்கூடாதவைகளாம்.
	<br /><br />
	10. இவைகள் சீர்திருத்தல் உண்டாகும் காலம்வரைக்கும் நடந்தேறும்படி கட்டளையிடப்பட்ட போஜனபானங்களும், பலவித ஸ்நானங்களும், சரீரத்திற்கேற்ற சடங்குகளுமேயல்லாமல் வேறல்ல.
	<br /><br />
	11. கிறிஸ்துவானவர் வரப்போகிற நன்மைகளுக்குரிய பிரதான ஆசாரியராய் வெளிப்பட்டு, கையினால் செய்யப்பட்டதாகிய இந்தச் சிருஷ்டிசம்பந்தமான கூடாரத்தின் வழியாக அல்ல, பெரிதும் உத்தமமுமான கூடாரத்தின் வழியாகவும்,
	<br /><br />
	12. வெள்ளாட்டுக்கடா, இளங்காளை இவைகளுடைய இரத்தத்தினாலே அல்ல, தம்முடைய சொந்த இரத்தத்தினாலும் ஒரேதரம் மகா பரிசுத்த ஸ்தலத்திலே பிரவேசித்து, நித்திய மீட்பை உண்டுபண்ணினார்.
	<br /><br />
	13. அதெப்படியெனில், காளை வெள்ளாட்டுக்கடா இவைகளின் இரத்தமும், தீட்டுப்பட்டவர்கள்மேல் தெளிக்கப்பட்ட கடாரியின் சாம்பலும், சரீரசுத்தியுண்டாகும்படி பரிசுத்தப்படுத்துமானால்,
	<br /><br />
	14. நித்திய ஆவியினாலே தம்மைத்தாமே பழுதற்ற பலியாகத் தேவனுக்கு ஒப்புக்கொடுத்த கிறிஸ்துவினுடைய இரத்தம் ஜீவனுள்ள தேவனுக்கு ஊழியஞ்செய்வதற்கு உங்கள் மனச்சாட்சியைச் செத்தக்கிரியைகளறச் சுத்திகரிப்பது எவ்வளவு நிச்சயம்!
	<br /><br />
	15. ஆகையால் முதலாம் உடன்படிக்கையின் காலத்திலே நடந்த அக்கிரமங்களை நிவிர்த்திசெய்யும்பொருட்டு அவர் மரணமடைந்து, அழைக்கப்பட்டவர்கள் வாக்குத்தத்தம்பண்ணப்பட்ட நித்திய சுதந்தரத்தை அடைந்துகொள்வதற்காக, புது உடன்படிக்கையின் மத்தியஸ்தராயிருக்கிறார்.
	<br /><br />
	16. ஏனென்றால், எங்கே மரணசாசனமுண்டோ, அங்கே அந்தச் சாசனத்தை எழுதினவனுடைய மரணமும் உண்டாகவேண்டும்.
	<br /><br />
	17. எப்படியெனில், மரணமுண்டான பின்பே மரணசாசனம் உறுதிப்படும்; அதை எழுதினவன் உயிரோடிருக்கையில் அதற்குப் பெலனில்லையே.
	<br /><br />
	18. அந்தப்படி, முதலாம் உடன்படிக்கையும் இரத்தமில்லாமல் பிரதிஷ்டை பண்ணப்படவில்லை.
	<br /><br />
	19. எப்படியெனில், மோசே, நியாயப்பிரமாணத்தின்படி, சகல ஜனங்களுக்கும் எல்லாக் கட்டளைகளையும் சொன்னபின்பு, இளங்காளை வெள்ளாட்டுக்கடா இவைகளின் இரத்தத்தைத் தண்ணீரோடும், சிவப்பான ஆட்டுமயிரோடும், ஈசோப்போடுங்கூட எடுத்து, புஸ்தகத்தின்மேலும் ஜனங்களெல்லார்மேலும் தெளித்து:
	<br /><br />
	20. தேவன் உங்களுக்குக் கட்டளையிட்ட உடன்படிக்கையின் இரத்தம் இதுவே என்று சொன்னான்.
	<br /><br />
	21. இவ்விதமாக, கூடாரத்தின்மேலும் ஆராதனைக்குரிய சகல பணிமுட்டுகளின்மேலும் இரத்தத்தைத் தெளித்தான்.
	<br /><br />
	22. நியாயப்பிரமாணத்தின்படி கொஞ்சங்குறைய எல்லாம் இரத்தத்தினாலே சுத்திகரிக்கப்படும்; இரத்தஞ்சிந்துதலில்லாமல் மன்னிப்பு உண்டாகாது.
	<br /><br />
	23. ஆதலால், பரலோகத்திலுள்ளவைகளுக்குச் சாயலானவைகள் இப்படிப்பட்ட பலிகளினாலே சுத்திகரிக்கப்பட வேண்டியதாயிருந்தது; பரலோகத்திலுள்ளவைகளோ இவைகளிலும் விசேஷித்த பலிகளாலே சுத்திகரிக்கப்படவேண்டியதாமே.
	<br /><br />
	24. அந்தப்படி, மெய்யான பரிசுத்த ஸ்தலத்துக்கு அடையாளமான கையினால் செய்யப்பட்டதாயிருக்கிற பரிசுத்த ஸ்தலத்திலே கிறிஸ்துவானவர் பிரவேசியாமல், பரலோகத்திலேதானே இப்பொழுது நமக்காகத் தேவனுடைய சமுகத்தில் பிரத்தியட்சமாகும்படி பிரவேசித்திருக்கிறார்.
	<br /><br />
	25. பிரதான ஆசாரியன் அந்நிய இரத்தத்தோடே வருஷந்தோறும் பரிசுத்த ஸ்தலத்துக்குள் பிரவேசிக்கிறதுபோல, அவர் அநேகந்தரம் தம்மைப் பலியிடும்படிக்குப் பிரவேசிக்கவில்லை.
	<br /><br />
	26. அப்படியிருந்ததானால், உலகமுண்டானது முதற்கொண்டு அவர் அநேகந்தரம் பாடுபடவேண்டியதாயிருக்குமே; அப்படியல்ல, அவர் தம்மைத்தாமே பலியிடுகிறதினாலே பாவங்களை நீக்கும்பொருட்டாக இந்தக் கடைசிக்காலத்தில் ஒரேதரம் வெளிப்பட்டார்.
	<br /><br />
	27. அன்றியும், ஒரேதரம் மரிப்பதும், பின்பு நியாயத்தீர்ப்படைவதும், மனுஷருக்கு நியமிக்கப்பட்டிருக்கிறபடியே,
	<br /><br />
	28. கிறிஸ்துவும் அநேகருடைய பாவங்களைச் சுமந்து தீர்க்கும்படிக்கு ஒரேதரம் பலியிடப்பட்டு, தமக்காகக் காத்துக்கொண்டிருக்கிறவர்களுக்கு இரட்சிப்பை அருளும்படி இரண்டாந்தரம் பாவமில்லாமல் தரிசனமாவார்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h9>
* எபிரேயர் 9, பழைய ஏற்பாட்டில் வழிபாடு எவ்வாறு வணக்கத்தை முழுமைப்படுத்த முடியாது என்பதைக் காட்டுகிறது, ஏனெனில் அது உணவு, பானம், பல்வேறு கழுவுதல் மற்றும் உடலுக்கான ஒழுங்குமுறைகளுடன் மட்டுமே தொடர்புடையது. இருப்பினும், கிறிஸ்து தோன்றியபோது அவர் பரலோக வாசஸ்தலத்திற்குள் நுழைந்து, கருணை இருக்கையில் தனது சொந்த இரத்தத்தை ஊற்றினார். காளைகள் மற்றும் ஆடுகளின் இரத்தம் வழிபடுபவர்களின் மனசாட்சியை ஒருபோதும் சுத்தப்படுத்த முடியாது, ஆனால் கிறிஸ்துவின் இரத்தம் நம்மை முழுமையாகச் சுத்தப்படுத்தியதால் முழுமையாக இரட்சிக்க முடிகிறது, இப்போது நாம் கடவுளுக்கு முன்பாக களங்கமோ குற்றமோ இல்லாமல் இருக்கிறோம். கிறிஸ்து தம் பலியின் மூலம் பாவத்தைப் போக்க யுகங்களின் நிறைவில் தோன்றினார். *
			</h9>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews9