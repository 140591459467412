import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 13 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பிலாத்து சில கலிலேயருடைய இரத்தத்தை அவர்களுடைய பலிகளோடே கலந்திருந்தான்; அந்த வேளையிலே அங்கே இருந்தவர்களில் சிலர் அந்தச் செய்தியை அவருக்கு அறிவித்தார்கள்.
	<br /><br />
	2. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: அந்தக் கலிலேயருக்கு அப்படிப்பட்டவைகள் சம்பவித்ததினாலே, மற்ற எல்லாக் கலிலேயரைப் பார்க்கிலும் அவர்கள் பாவிகளாயிருந்தார்களென்று நினைக்கிறீர்களோ?
	<br /><br />
	3. அப்படியல்லவென்று உங்களுக்குச் சொல்லுகிறேன்; நீங்கள் மனந்திரும்பாமற்போனால் எல்லாரும் அப்படியே கெட்டுப்போவீர்கள்.
	<br /><br />
	4. சீலோவாமிலே கோபுரம் விழுந்து பதினெட்டுப்பேரைக் கொன்றதே; எருசலேமில் குடியிருக்கிற மனுஷரெல்லாரிலும் அவர்கள் குற்றவாளிகளாயிருந்தார்களென்று நினைக்கிறீர்களோ?
	<br /><br />
	5. அப்படியல்லவென்று உங்களுக்குச் சொல்லுகிறேன்; நீங்கள் மனந்திரும்பாமற்போனால் எல்லாரும் அப்படியே கெட்டுப்போவீர்கள் என்றார்.
	<br /><br />
	6. அப்பொழுது அவர் ஒரு உவமையையும் சொன்னார்: ஒருவன் தன் திராட்சத்தோட்டத்தில் ஒரு அத்திமரத்தை நட்டிருந்தான்; அவன் வந்து அதிலே கனியைத் தேடினபோது ஒன்றுங் காணவில்லை.
	<br /><br />
	7. அப்பொழுது அவன் தோட்டக்காரனை நோக்கி: இதோ, மூன்று வருஷமாய் இந்த அத்திமரத்திலே கனியைத் தேடி வருகிறேன்; ஒன்றையுங் காணவில்லை, இதை வெட்டிப்போடு, இது நிலத்தையும் ஏன் கெடுக்கிறது என்றான்.
	<br /><br />
	8. அதற்கு அவன்: ஐயா, இது இந்த வருஷமும் இருக்கட்டும்; நான் இதைச் சுற்றிலுங் கொத்தி, எருப்போடுவேன்,
	<br /><br />
	9. கனிகொடுத்தால் சரி, கொடாவிட்டால், இனிமேல் இதை வெட்டிப்போடலாம் என்று சொன்னான் என்றார்.
	<br /><br />
	10. ஒரு ஓய்வுநாளில் அவர் ஜெபஆலயத்தில் போதகம்பண்ணிக்கொண்டிருந்தார்.
	<br /><br />
	11. அப்பொழுது பதினெட்டு வருஷமாய்ப் பலவீனப்படுத்தும் ஆவியைக் கொண்ட ஒரு ஸ்திரீ அங்கேயிருந்தாள். அவள் எவ்வளவும் நிமிரக்கூடாத கூனியாயிருந்தாள்.
	<br /><br />
	12. இயேசு அவளைக் கண்டு, தம்மிடத்தில் அழைத்து: ஸ்திரீயே, உன் பலவீனத்தினின்று விடுதலையாக்கப்பட்டாய் என்று சொல்லி,
	<br /><br />
	13. அவள்மேல் தமது கைகளை வைத்தார்; உடனே அவள் நிமிர்ந்து, தேவனை மகிமைப்படுத்தினாள்.
	<br /><br />
	14. இயேசு ஓய்வுநாளிலே சொஸ்தமாக்கினபடியால், ஜெப ஆலயத்தலைவன் கோபமடைந்து, ஜனங்களை நோக்கி: வேலைசெய்கிறதற்கு ஆறுநாள் உண்டே, அந்த நாட்களிலே நீங்கள் வந்து சொஸ்தமாக்கிக்கொள்ளுங்கள், ஓய்வுநாளிலே அப்படிச் செய்யலாகாது என்றான்.
	<br /><br />
	15. கர்த்தர் அவனுக்குப் பிரதியுத்தரமாக: மாயக்காரனே, உங்களில் எவனும் ஓய்வுநாளில் தன் எருதையாவது தன் கழுதையையாவது தொழுவத்திலிருந்து அவிழ்த்துக்கொண்டுபோய், அதற்குத் தண்ணீர் காட்டுகிறதில்லையா?
	<br /><br />
	16. இதோ, சாத்தான் பதினெட்டு வருஷமாய்க் கட்டியிருந்த ஆபிரகாமின் குமாரத்தியாகிய இவளை ஓய்வுநாளில் இந்தக் கட்டிலிருந்து அவிழ்த்துவிடவேண்டியதில்லையா என்றார்.
	<br /><br />
	17. அவர் அப்படிச் சொன்னபோது, அவரை விரோதித்திருந்த அனைவரும் வெட்கப்பட்டார்கள். ஜனங்களெல்லாரும் அவரால் செய்யப்பட்ட சகல மகிமையான செய்கைகளைக்குறித்தும் சந்தோஷப்பட்டார்கள்.
	<br /><br />
	18. அவர் அவர்களை நோக்கி: தேவனுடைய ராஜ்யம் எதற்கொப்பாயிருக்கிறது; அதை எதற்கு ஒப்பிடுவேன்?
	<br /><br />
	19. அது ஒரு கடுகுவிதைக்கு ஒப்பாயிருக்கிறது; அதை ஒரு மனுஷன் எடுத்துத் தன் தோட்டத்திலே போட்டான்; அது வளர்ந்து, பெரிய மரமாயிற்று; ஆகாயத்துப் பறவைகள் வந்து, அதின் கிளைகளில் அடைந்தது என்றார்.
	<br /><br />
	20. மேலும் அவர்: தேவனுடைய ராஜ்யத்தை நான் எதற்கு ஒப்பிடுவேன்?
	<br /><br />
	21. அது புளித்தமாவுக்கு ஒப்பாயிருக்கிறது; அதை ஒரு ஸ்திரீ எடுத்து முழுவதும் புளிக்கும்வரைக்கும் மூன்றுபடிமாவிலே அடக்கிவைத்தாள் என்றார்.
	<br /><br />
	22. அவர் எருசலேமுக்குப் பிரயாணமாய்ப் போகும்போது, பட்டணங்கள்தோறும் கிராமங்கள்தோறும் உபதேசம் பண்ணிக்கொண்டு போனார்.
	<br /><br />
	23. அப்பொழுது ஒருவன் அவரை நோக்கி: ஆண்டவரே, இரட்சிக்கப்படுகிறவர்கள் சிலபேர்தானோ என்று கேட்டான்; அதற்கு அவர்:
	<br /><br />
	24. இடுக்கமான வாசல்வழியாய் உட்பிரவேசிக்கப் பிரயாசப்படுங்கள், அநேகர் உட்பிரவேசிக்க வகைதேடினாலும் அவர்களாலே கூடாமற்போகும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	25. வீட்டெஜமான் எழுந்து, கதவைப் பூட்டினபின்பு, நீங்கள் வெளியே நின்று: ஆண்டவரே, ஆண்டவரே, எங்களுக்குத் திறக்கவேண்டுமென்று சொல்லிக் கதவைத் தட்டும்போது, அவர் பிரதியுத்தரமாக: நீங்கள் எவ்விடத்தாரோ, உங்களை அறியேன் என்று உங்களுக்குச் சொல்லுவார்.
	<br /><br />
	26. அப்பொழுது நீங்கள்: உம்முடைய சமுகத்தில் போஜனபானம்பண்ணினோமே, நீர் எங்கள் வீதிகளில் போதகம்பண்ணினீரே என்று சொல்லுவீர்கள்.
	<br /><br />
	27. ஆனாலும் அவர்: நீங்கள் எவ்விடத்தாரோ, உங்களை அறியேன், அக்கிரமக்காரராகிய நீங்களெல்லாரும் என்னைவிட்டு அகன்றுபோங்கள் என்று சொல்லுவார் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	28. நீங்கள் ஆபிரகாமையும் ஈசாக்கையும் யாக்கோபையும் சகல தீர்க்கதரிசிகளையும் தேவனுடைய ராஜ்யத்திலிருக்கிறவர்களாகவும், உங்களையோ புறம்பே தள்ளப்பட்டவர்களாகவும் காணும்போது உங்களுக்கு அழுகையும் பற்கடிப்பும் அங்கே உண்டாயிருக்கும்.
	<br /><br />
	29. கிழக்கிலும் மேற்கிலும் வடக்கிலும் தெற்கிலும் இருந்து ஜனங்கள் வந்து, தேவனுடைய ராஜ்யத்தில் பந்தியிருப்பார்கள்.
	<br /><br />
	30. அப்பொழுது முந்தினோர் பிந்தினோராவார்கள், பிந்தினோர் முந்தினோராவார்கள் என்றார்.
	<br /><br />
	31. அந்த நாளிலே சில பரிசேயர் அவரிடத்தில் வந்து: நீர் இவ்விடத்தை விட்டுப் போய்விடும்; ஏரோது உம்மைக் கொலைசெய்ய மனதாய் இருக்கிறான் என்றார்கள்.
	<br /><br />
	32. அதற்கு அவர்: நான் இன்றைக்கும் நாளைக்கும் பிசாசுகளைத் துரத்தி, வியாதியுள்ளவர்களைச் சொஸ்தமாக்கி, மூன்றாம் நாளில் நிறைவடைவேன்.
	<br /><br />
	33. இன்றைக்கும் நாளைக்கும் மறுநாளைக்கும் நான் நடமாடவேண்டும்; எருசலேமுக்குப் புறம்பே ஒரு தீர்க்கதரிசியும் மடிந்துபோகிறதில்லையென்று நான் சொன்னதாக நீங்கள் போய் அந்த நரிக்குச் சொல்லுங்கள்.
	<br /><br />
	34. எருசலேமே, எருசலேமே, தீர்க்கதரிசிகளைக் கொலைசெய்து, உன்னிடத்தில் அனுப்பப்பட்டவர்களைக் கல்லெறிகிறவளே! கோழி தன் குஞ்சுகளைத் தன் சிறகுகளின் கீழே கூட்டிச் சேர்த்துக் கொள்ளும் வண்ணமாக நான் எத்தனைதரமோ உன் பிள்ளைகளைக் கூட்டிச் சேர்த்துக்கொள்ள மனதாயிருந்தேன்; உங்களுக்கோ மனதில்லாமற்போயிற்று.
	<br /><br />
	35. இதோ, உங்கள் வீடு உங்களுக்குப் பாழாக்கிவிடப்படும்; கர்த்தருடைய நாமத்தினாலே வருகிறவர் ஸ்தோத்திரிக்கப்பட்டவர் என்று நீங்கள் சொல்லுங்காலம் வருமளவும் என்னைக் காணாதிருப்பீர்கள் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke13