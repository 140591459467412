import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew28 = () => {
  const verseNumber = 28;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 28 </title>
	<meta
          name="description"
          content="Matthew 28 | மத்தேயு 28 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஓய்வுநாள் முடிந்து, வாரத்தின் முதலாம் நாள் விடிந்துவருகையில், மகதலேனா மரியாளும் மற்ற மரியாளும் கல்லறையைப் பார்க்கவந்தார்கள்.
	<br /><br />
	2. அப்பொழுது, பூமி மிகவும் அதிரும்படி, கர்த்தருடைய தூதன் வானத்திலிருந்திறங்கி வந்து, வாசலிலிருந்த கல்லைப் புரட்டித் தள்ளி, அதின்மேல் உட்கார்ந்தான்.
	<br /><br />
	3. அவனுடைய ரூபம் மின்னல்போலவும், அவனுடைய வஸ்திரம் உறைந்த மழையைப்போல வெண்மையாகவும் இருந்தது.
	<br /><br />
	4. காவலாளர் அவனுக்குப் பயந்ததினால் திடுக்கிட்டுச் செத்தவர்கள் போலானார்கள்.
	<br /><br />
	5. தூதன் அந்த ஸ்திரீகளை நோக்கி: நீங்கள் பயப்படாதிருங்கள்; சிலுவையில் அறையப்பட்ட இயேசுவைத் தேடுகிறீர்கள் என்று அறிவேன்.
	<br /><br />
	6. அவர் இங்கே இல்லை; தாம் சொன்னபடியே உயிர்த்தெழுந்தார்; கர்த்தரை வைத்த இடத்தை வந்து பாருங்கள்;
	<br /><br />
	7. சீக்கிரமாய்ப் போய், அவர் மரித்தோரிலிருந்து எழுந்தார் என்று அவருடைய சீஷர்களுக்குச் சொல்லுங்கள். அவர் உங்களுக்குமுன்னே கலிலேயாவுக்குப் போகிறார்; அங்கே அவரைக் காண்பீர்கள்; இதோ, உங்களுக்குச் சொன்னேன் என்றான்.
	<br /><br />
	8. அவர்கள் பயத்தோடும் மகா சந்தோஷத்தோடும் கல்லறையை விட்டுச் சீக்கிரமாய்ப் புறப்பட்டு, அவருடைய சீஷர்களுக்கு அறிவிக்க ஓடினார்கள்.
	<br /><br />
	9. அவர்கள் அவருடைய சீஷர்களுக்கு அறிவிக்கப் போகிறபோது, இயேசு தாமே அவர்களுக்கு எதிர்ப்பட்டு: வாழ்க என்றார். அவர்கள் கிட்டவந்து, அவர் பாதங்களைத் தழுவி, அவரைப் பணிந்துகொண்டார்கள்.
	<br /><br />
	10. அப்பொழுது இயேசு அவர்களை நோக்கி: பயப்படாதிருங்கள்; நீங்கள் போய், என் சகோதரர் கலிலேயாவுக்குப் போகும்படி அவர்களுக்குச் சொல்லுங்கள்; அங்கே அவர்கள் என்னைக் காண்பார்கள் என்றார்.
	<br /><br />
	11. அவர்கள் போகையில், காவல்சேவகரில் சிலர் நகரத்துக்குள்ளே வந்து, நடந்த யாவற்றையும் பிரதான ஆசாரியருக்கு அறிவித்தார்கள்.
	<br /><br />
	12. இவர்கள் மூப்பரோடே கூடிவந்து, ஆலோசனைபண்ணி, சேவகருக்கு வேண்டிய பணத்தைக் கொடுத்து:
	<br /><br />
	13. நாங்கள் நித்திரைபண்ணுகையில், அவனுடைய சீஷர்கள் இராத்திரியிலே வந்து, அவனைக் களவாய்க் கொண்டு போய்விட்டார்கள் என்று சொல்லுங்கள்.
	<br /><br />
	14. இது தேசாதிபதிக்குக் கேள்வியானால், நாங்கள் அவரைச் சம்மதப்படுத்தி, உங்களைத் தப்புவிப்போம் என்றார்கள்.
	<br /><br />
	15. அவர்கள் பணத்தை வாங்கிக்கொண்டு, தங்களுக்குப் போதிக்கப்பட்டபடியே செய்தார்கள். இந்தப் பேச்சு யூதருக்குள்ளே இந்நாள்வரைக்கும் பிரசித்தமாயிருக்கிறது.
	<br /><br />
	16. பதினொரு சீஷர்களும், கலிலேயாவிலே இயேசு தங்களுக்குக் குறித்திருந்த மலைக்குப் போனார்கள்.
	<br /><br />
	17. அங்கே அவர்கள் அவரைக் கண்டு, பணிந்துகொண்டார்கள்; சிலரோ சந்தேகப்பட்டார்கள்.
	<br /><br />
	18. அப்பொழுது இயேசு சமீபத்தில் வந்து, அவர்களை நோக்கி: வானத்திலும் பூமியிலும் சகல அதிகாரமும் எனக்குக் கொடுக்கப்பட்டிருக்கிறது.
	<br /><br />
	19. ஆகையால், நீங்கள் புறப்பட்டுப்போய், சகல ஜாதிகளையும் சீஷராக்கி, பிதா குமாரன் பரிசுத்த ஆவியின் நாமத்திலே அவர்களுக்கு ஞானஸ்நானங்கொடுத்து,
	<br /><br />
	20. நான் உங்களுக்குக் கட்டளையிட்ட யாவையும் அவர்கள் கைக்கொள்ளும்படி அவர்களுக்கு உபதேசம்பண்ணுங்கள். இதோ, உலகத்தின் முடிவுபரியந்தம் சகல நாட்களிலும் நான் உங்களுடனேகூட இருக்கிறேன் என்றார். ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew28