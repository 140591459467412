import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 19 </title>
	<meta
          name="description"
          content="Ezekiel 19 | எசேக்கியேல்  19 |
          Ezekiel 19 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீ இஸ்ரவேல் பிரபுக்களின்பேரில் ஒரு புலம்பல் பாடி,
	<br /><br />
	2. சொல்லவேண்டியது என்னவென்றால்: உன் தாய் எப்படிப்பட்டவள்? அவள் ஒரு பெண்சிங்கம், அவள் சிங்கங்களுக்குள்ளே படுத்திருந்து, பாலசிங்கங்களின் நடுவிலே தன் குட்டிகளை வளர்த்தாள்.
	<br /><br />
	3. தன் குட்டிகளில் ஒன்று வளர்ந்து, பாலசிங்கமாகி, இரைதேடப் பழகி, மனுஷரைப் பட்சித்தது.
	<br /><br />
	4. புறஜாதிகளும் அதின் செய்தியைக் கேட்டார்கள், அது அவர்களுடைய படுகுழியில் அகப்பட்டது; அதைச் சங்கிலிகளினால் கட்டி, எகிப்துதேசத்திற்குக் கொண்டுபோனார்கள்.
	<br /><br />
	5. தாய்ச்சிங்கம் காத்திருந்து, தன் நம்பிக்கை அபத்தமாய்ப் போயிற்றென்று கண்டு, அது தன் குட்டிகளில் வேறொன்றை எடுத்து, அதைப் பாலசிங்கமாக வைத்தது.
	<br /><br />
	6. அது சிங்கங்களுக்குள்ளே சஞ்சரித்து, பாலசிங்கமாகி, இரைதேடப் பழகி, மனுஷரைப் பட்சித்தது.
	<br /><br />
	7. அவர்களுடைய பாழான அரமனைகளில் திரிந்து, அவர்களுடைய பட்டணங்களைப் பாழாக்கிற்று; அதினுடைய கெர்ச்சிப்பின் சத்தத்துக்குத் தேசமும் அதிலுள்ள யாவும் திகைத்தது.
	<br /><br />
	8. அப்பொழுது சுற்றுப்புறத்து ஜாதிகள் அதற்கு விரோதமாக எழும்பிவந்து, தங்கள் வலையை அதின்மேல் வீசினார்கள்; அது அவர்களுடைய படுகுழியிலே அகப்பட்டது.
	<br /><br />
	9. அவர்கள் அதைச் சங்கிலிகளினால் கட்டி, ஒரு கூட்டுக்குட்படுத்தி, அதைப் பாபிலோன் ராஜாவினிடத்துக்குக் கொண்டுபோனார்கள்; இனி அதின் சத்தம் இஸ்ரவேலின் பர்வதங்களின்மேல் கேட்கப்படாதபடிக்கு அதை அரணான இடங்களில் கொண்டுபோய் அடைத்தார்கள்.
	<br /><br />
	10. நீ அமரிக்கையோடு இருக்கையில் உன் தாய் தண்ணீர் ஓரமாய் நாட்டப்பட்டதும், மிகுதியான நீர்ப்பாய்ச்சலால் கனிதருகிறதும் தழைத்திருக்கிறதுமான திராட்சச்செடியாயிருந்தாள்.
	<br /><br />
	11. ஆளுகிறவர்களின் செங்கோலுக்கேற்ற பலத்த கொப்புகள் அதற்கு இருந்தது; அதின் வளர்த்தி அடர்த்தியான கிளைகளுக்குள்ளே உயர ஓங்கி, தன் உயர்த்தியினாலே தன் திரளான கொடிகளோடுங்கூடத் தோன்றிற்று.
	<br /><br />
	12. ஆனாலும் அது உக்கிரமாய்ப் பிடுங்கப்பட்டு, தரையிலே தள்ளுண்டது; கீழ்க்காற்றினால் அதின் கனி காய்ந்துபோயிற்று; அதின் பலத்த கொப்புகள் முறிந்து, பட்டுப்போயின; அக்கினி அவைகளைப் பட்சித்தது.
	<br /><br />
	13. இப்பொழுது அது வறண்டதும் தண்ணீர் இல்லாததுமான வனாந்தர பூமியிலே நடப்பட்டிருக்கிறது.
	<br /><br />
	14. அதின் கொடிகளிலுள்ள ஒரு கொப்பிலிருந்து அக்கினி புறப்பட்டு, அதின் கனியைப் பட்சித்தது; ஆளுகிற செங்கோலுக்கேற்ற பலத்த கொப்பு இனி அதில் இல்லையென்று சொல்; இதுவே புலம்பல், இதுவே புலம்பலாயிருக்கும் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial19