import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Matthew Tamil Bible | மத்தேயு 1 </title>
	<meta
          name="description"
          content="Matthew 1 | மத்தேயு 1 |
          Gospel of Matthew | Tamil Bible Verse | Tamil Bible | மத்தேயு எழுதின சுவிசேஷம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
	  <p>
	1. ஆபிரகாமின் குமாரனாகிய தாவீதின் குமாரனான இயேசுகிறிஸ்துவினுடைய வம்ச வரலாறு:
	<br /><br />
	2. ஆபிரகாம் ஈசாக்கைப் பெற்றான்; ஈசாக்கு யாக்கோபைப் பெற்றான்; யாக்கோபு யூதாவையும் அவன் சகோதரரையும் பெற்றான்;
	<br /><br />
	3. யூதா பாரேசையும் சாராவையும் தாமாரினிடத்தில் பெற்றான்; பாரேஸ் எஸ்ரோமைப் பெற்றான்; எஸ்ரோம் ஆராமைப் பெற்றான்;
	<br /><br />
	4. ஆராம் அம்மினதாபைப் பெற்றான்; அம்மினதாப் நகசோனைப் பெற்றான்; நகசோன் சல்மோனைப் பெற்றான்;
	<br /><br />
	5. சல்மோன் போவாசை ராகாபினிடத்தில் பெற்றான்; போவாஸ் ஓபேதை ரூத்தினிடத்தில் பெற்றான்; ஓபேத் ஈசாயைப் பெற்றான்;
	<br /><br />
	6. ஈசாய் தாவீது ராஜாவைப் பெற்றான்; தாவீது ராஜா உரியாவின் மனைவியாயிருந்தவளிடத்தில் சாலொமோனைப் பெற்றான்;
	<br /><br />
	7. சாலொமோன் ரெகொபெயாமைப் பெற்றான்; ரெகொபெயாம் அபியாவைப் பெற்றான்; அபியா ஆசாவைப் பெற்றான்;
	<br /><br />
	8. ஆசா யோசபாத்தைப் பெற்றான்; யோசபாத் யோராமைப் பெற்றான்; யோராம் உசியாவைப் பெற்றான்;
	<br /><br />
	9. உசியா யோதாமைப் பெற்றான்; யோதாம் ஆகாசைப் பெற்றான்; ஆகாஸ் எசேக்கியாவைப் பெற்றான்;
	<br /><br />
	10. எசேக்கியா மனாசேயைப் பெற்றான்; மனாசே ஆமோனைப் பெற்றான்; ஆமோன் யோசியாவைப் பெற்றான்;
	<br /><br />
	11. பாபிலோனுக்குச் சிறைப்பட்டுப் போகுங்காலத்தில், யோசியா எகொனியாவையும் அவனுடைய சகோதரரையும் பெற்றான்.
	<br /><br />
	12. பாபிலோனுக்குச் சிறைப்பட்டுப் போனபின்பு, எகொனியா சலாத்தியேலைப் பெற்றான்; சலாத்தியேல் சொரொபாபேலைப் பெற்றான்;
	<br /><br />
	13. சொரொபாபேல் அபியூதைப் பெற்றான்; அபியூத் எலியாக்கீமைப் பெற்றான்; எலியாக்கீம் ஆசோரைப் பெற்றான்;
	<br /><br />
	14. ஆசோர் சாதோக்கைப் பெற்றான்; சாதோக்கு ஆகீமைப் பெற்றான்; ஆகீம் எலியூதைப் பெற்றான்;
	<br /><br />
	15. எலியூத் எலெயாசாரைப் பெற்றான்; எலெயாசார் மாத்தானைப் பெற்றான்; மாத்தான் யாக்கோபைப் பெற்றான்;
	<br /><br />
	16. யாக்கோபு மரியாளுடைய புருஷனாகிய யோசேப்பைப் பெற்றான்; அவளிடத்தில் கிறிஸ்து என்னப்படுகிற இயேசு பிறந்தார்.
	<br /><br />
	17. இவ்விதமாய் உண்டான தலைமுறைகளெல்லாம் ஆபிரகாம்முதல் தாவீதுவரைக்கும் பதினாலு தலைமுறைகளும்; தாவீதுமுதல் பாபிலோனுக்குச் சிறைப்பட்டுப்போன காலம்வரைக்கும் பதினாலு தலைமுறைகளும்; பாபிலோனுக்குச் சிறைப்பட்டுப்போன காலமுதல் கிறிஸ்துவரைக்கும் பதினாலு தலைமுறைகளுமாம்.
	<br /><br />
	18. இயேசுகிறிஸ்துவினுடைய ஜெநநத்தின் விவரமாவது: அவருடைய தாயாகிய மரியாள் யோசேப்புக்கு நியமிக்கப்பட்டிருக்கையில், அவர்கள் கூடிவருமுன்னே, அவள் பரிசுத்த ஆவியினாலே கர்ப்பவதியானாள் என்று காணப்பட்டது.
	<br /><br />
	19. அவள் புருஷனாகிய யோசேப்பு நீதிமானாயிருந்து, அவளை அவமானப்படுத்த மனதில்லாமல், இரகசியமாய் அவளைத் தள்ளிவிட யோசனையாயிருந்தான்.
	<br /><br />
	20. அவன் இப்படிச் சிந்தித்துக்கொண்டிருக்கையில், கர்த்தருடைய தூதன் சொப்பனத்தில் அவனுக்குக் காணப்பட்டு: தாவீதின் குமாரனாகிய யோசேப்பே, உன் மனைவியாகிய மரியாளைச் சேர்த்துக்கொள்ள ஐயப்படாதே; அவளிடத்தில் உற்பத்தியாயிருக்கிறது பரிசுத்த ஆவியினால் உண்டானது.
	<br /><br />
	21. அவள் ஒரு குமாரனைப் பெறுவாள், அவருக்கு இயேசு என்று பேரிடுவாயாக; ஏனெனில் அவர் தமது ஜனங்களின் பாவங்களை நீக்கி அவர்களை இரட்சிப்பார் என்றான்.
	<br /><br />
	22. தீர்க்கதரிசியின் மூலமாய்க் கர்த்தராலே உரைக்கப்பட்டது நிறைவேறும்படி இதெல்லாம் நடந்தது.
	<br /><br />
	23. அவன்: இதோ, ஒரு கன்னிகை கர்ப்பவதியாகி ஒரு குமாரனைப் பெறுவாள்; அவருக்கு இம்மானுவேல் என்று பேரிடுவார்கள் என்று சொன்னான். இம்மானுவேல் என்பதற்குத் தேவன் நம்மோடிருக்கிறார் என்று அர்த்தமாம்.
	<br /><br />
	24. யோசேப்பு நித்திரை தெளிந்து எழுந்து, கர்த்தருடைய தூதன் தனக்குக் கட்டளையிட்டபடியே தன் மனைவியைச் சேர்த்துக்கொண்டு;
	<br /><br />
	25. அவள் தன் முதற்பேறான குமாரனைப் பெறுமளவும் அவளை அறியாதிருந்து, அவருக்கு இயேசு என்று பேரிட்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew1