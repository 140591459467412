import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial34 = () => {
  const verseNumber = 34;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 34 </title>
	<meta
          name="description"
          content="Ezekiel 34 | எசேக்கியேல்  34 |
          Ezekiel 34 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, இஸ்ரவேலின் மேய்ப்பருக்கு விரோதமாகத் தீர்க்கதரிசனம் உரை; நீ தீர்க்கதரிசனம் உரைத்து, அவர்களோடே சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் மேய்ப்பருக்குச் சொல்லுகிறார்; தங்களையே மேய்க்கிற இஸ்ரவேலின் மேய்ப்பருக்கு ஐயோ! மேய்ப்பர் அல்லவா மந்தையை மேய்க்கவேண்டும்.
	<br /><br />
	3. நீங்கள் நெய்யைச் சாப்பிட்டு, ஆட்டுமயிரை உடுப்பாக்கிக்கொள்ளுகிறீர்கள்; கொழுத்ததை அடிக்கிறீர்கள்; மந்தையையோ மேய்க்காமற்போகிறீர்கள்.
	<br /><br />
	4. நீங்கள் பலவீனமானவைகளைப் பலப்படுத்தாமலும், நசல்கொண்டவைகளைக் குணமாக்காமலும், எலும்பு முறிந்தவைகளைக் காயங்கட்டாமலும், துரத்துண்டவைகளைத் திருப்பிக்கொண்டு வராமலும், காணாமற்போனவைகளைத் தேடாமலும் போய், பலாத்காரமும் கடூரமுமாய் அவைகளை ஆண்டீர்கள்.
	<br /><br />
	5. மேய்ப்பன் இல்லாததினால் அவைகள் சிதறுண்டுபோயின; சிதறுண்டுபோனவைகள் காட்டு மிருகங்களுக்கெல்லாம் இரையாயின.
	<br /><br />
	6. என் ஆடுகள் சகல மலைகளிலும் உயரமான சகல மேடுகளிலும் அலைப்புண்டு, பூமியின்மீதெங்கும் என் ஆடுகள் சிதறித்திரிகிறது; விசாரிக்கிறவனுமில்லை, தேடுகிறவனுமில்லை.
	<br /><br />
	7. ஆகையால் மேய்ப்பரே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	8. கர்த்தராகிய ஆண்டவராயிருக்கிற நான் என் ஜீவனைக்கொண்டு சொல்லுகிறேன்; மேய்ப்பன் இல்லாததினால் என் ஆடுகள் சூறையாகி, என் ஆடுகள் காட்டுமிருகங்களுக்கெல்லாம் இரையாய்ப் போயின; என் மேய்ப்பர்கள் என் ஆடுகளை விசாரியாமற்போனார்கள், மேய்ப்பர்கள் மந்தையை மேய்க்காமல் தங்களையே மேய்த்தார்கள்.
	<br /><br />
	9. ஆகையால் மேய்ப்பரே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	10. கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்: இதோ, நான் மேய்ப்பருக்கு விரோதமாக வந்து, என் ஆடுகளை அவர்கள் கையிலே கேட்டு, மேய்ப்பர் இனித் தங்களையே மேய்க்காதபடி, மந்தையை மேய்க்கும் தொழிலைவிட்டு அவர்களை விலக்கி, என் ஆடுகள் அவர்களுக்கு ஆகாரமாயிராதபடி, அவைகளை அவர்கள் வாய்க்குத் தப்பப்பண்ணுவேன் என்று சொல்லு.
	<br /><br />
	11. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் நானே என் ஆடுகளை விசாரித்து, அவைகளைத் தேடிப்பார்ப்பேன்.
	<br /><br />
	12. ஒரு மேய்ப்பன் சிதறுண்ட தன் ஆடுகளின் நடுவே இருக்கிற நாளில் தன் மந்தையைத் தேடிக்கொண்டிருக்கிறதுபோல, நான் என் ஆடுகளைத் தேடி, மப்பும் மந்தாரமுமான நாளிலே அவைகள் சிதறுண்டுபோன எல்லா இடங்களிலுமிருந்து அவைகளைத் தப்பிவரப்பண்ணி,
	<br /><br />
	13. அவைகளை ஜனங்களிடத்திலிருந்து புறப்படவும் தேசங்களிலிருந்து சேரவும்பண்ணி, அவைகளுடைய சுயதேசத்திலே அவைகளைக் கொண்டுவந்து, இஸ்ரவேல் மலைகளின்மேலும் ஆறுகள் அண்டையிலும் தேசத்தின் சகல வாசஸ்தலங்களிலும் அவைகளை மேய்ப்பேன்.
	<br /><br />
	14. அவைகளை நல்ல மேய்ச்சலிலே மேய்ப்பேன்; இஸ்ரவேலுடைய உயர்ந்த மலைகளில் அவைகளுடைய தொழுவம் இருக்கும்; அங்கே அவைகள் நல்ல தொழுவத்தில் படுத்துக்கொள்ளும், இஸ்ரவேலின் மலைகளின்மேல் நல்ல மேய்ச்சலை மேயும்.
	<br /><br />
	15. என் ஆடுகளை நான் மேய்த்து, அவைகளை நான் மடக்குவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	16. நான் காணாமற்போனதைத் தேடி, துரத்துண்டதைத் திரும்பக்கொண்டுவந்து, எலும்பு முறிந்ததைக் காயங்கட்டி, நசல்கொண்டதைத் திடப்படுத்துவேன்; நியாயத்துக்குத்தக்கதாய் அவைகளை மேய்த்து, புஷ்டியும் பெலமுமுள்ளவைகளை அழிப்பேன்.
	<br /><br />
	17. என் மந்தையே, கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, ஆட்டுக்கும் ஆட்டுக்கும், ஆட்டுக்கடாக்களுக்கும், வெள்ளாட்டுக்கடாக்களுக்கும் நான் நியாயந்தீர்ப்பேன்.
	<br /><br />
	18. நீங்கள் நல்ல மேய்ச்சலை மேய்ந்து, உங்கள் மேய்ச்சல்களில் மீதியானதை உங்கள் கால்களால் மிதிக்கலாமா? தெளிந்த தண்ணீரைக் குடித்து மீதியாயிருக்கிறதை உங்கள் கால்களால் குழப்பிப்போடலாமா?
	<br /><br />
	19. என் ஆடுகள் உங்கள் கால்களால் மிதிக்கப்பட்டதை மேயவும், உங்கள் கால்களால் குழப்பப்பட்டதைக் குடிக்கவும் வேண்டுமோ?
	<br /><br />
	20. ஆகையால், கர்த்தராகிய ஆண்டவர் அவைகளை நோக்கி: இதோ, நான், நானே கொழுத்த ஆடுகளுக்கும் இளைத்த ஆடுகளுக்கும் நியாயந்தீர்ப்பேன்.
	<br /><br />
	21. நீங்கள் பலவீனமாயிருக்கிறவைகளையெல்லாம் புறம்பாக்கிச் சிதறப்பண்ணும்படி, அவைகளைப் பக்கத்தினாலும் முன்னந்தொடையினாலும் தள்ளி உங்கள் கொம்புகளைக்கொண்டு முட்டுகிறபடியினாலே,
	<br /><br />
	22. நான் என் ஆடுகளை இனிச் சூறையாகாதபடிக்கு இரட்சித்து, ஆடுகளுக்கும் ஆடுகளுக்கும் நியாயந்தீர்ப்பேன்.
	<br /><br />
	23. அவர்களை மேய்க்கும்படி என் தாசனாகிய தாவீது என்னும் ஒரே மேய்ப்பனை நான் அவர்கள்மேல் விசாரிப்பாயிருக்க ஏற்படுத்துவேன்; இவர் அவர்களை மேய்த்து, இவரே அவர்களுக்கு மேய்ப்பனாயிருப்பார்.
	<br /><br />
	24. கர்த்தராகிய நான் அவர்களுக்குத் தேவனாக இருப்பேன், என் தாசனாகிய தாவீது அவர்கள் நடுவில் அதிபதியாயிருப்பார்; கர்த்தராகிய நான் இதைச் சொன்னேன்.
	<br /><br />
	25. நான் அவர்களோடு சமாதான உடன்படிக்கைசெய்து, துஷ்ட மிருகங்களைத் தேசத்தில் இராதபடிக்கு ஒழியப்பண்ணுவேன்; அவர்கள் சுகமாய் வனாந்தரத்தில் தாபரித்து, காடுகளில் நித்திரைபண்ணுவார்கள்.
	<br /><br />
	26. நான் அவர்களையும் என் மேட்டின் சுற்றுப்புறங்களையும் ஆசீர்வாதமாக்கி, ஏற்றகாலத்திலே மழையைப் பெய்யப்பண்ணுவேன்; ஆசீர்வாதமான மழை பெய்யும்.
	<br /><br />
	27. வெளியின் விருட்சங்கள் தங்கள் கனியைத்தரும்; பூமி தன் பலனைக் கொடுக்கும்; அவர்கள் தங்கள் தேசத்தில் சுகமாயிருப்பார்கள்; நான் அவர்கள் நுகத்தின் கயிறுகளை அறுத்து, அவர்களை அடிமைகொண்டவர்களின் கைக்கு அவர்களை நீங்கலாக்கி விடுவிக்கும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	28. இனி அவர்கள் புறஜாதிகளுக்குக் கொள்ளையாவதில்லை, பூமியின் மிருகங்கள் அவர்களைப் பட்சிப்பதுமில்லை; தத்தளிக்கப்பண்ணுவார் இல்லாமல் சுகமாய்த் தங்குவார்கள்.
	<br /><br />
	29. நான் அவர்களுக்குக் கீர்த்திபொருந்திய ஒரு நாற்றை எழும்பப்பண்ணுவேன்; அவர்கள் இனி தேசத்திலே பஞ்சத்தால் வாரிக்கொள்ளப்படுவதுமில்லை, இனிப் புறஜாதிகள் செய்யும் அவமானத்தைச் சுமப்பதுமில்லை.
	<br /><br />
	30. தங்கள் தேவனாகிய கர்த்தராயிருக்கிற நான் தங்களோடே இருக்கிறதையும், இஸ்ரவேல் வம்சத்தாராகிய தாங்கள் என் ஜனமாயிருக்கிறதையும், அவர்கள் அறிந்துகொள்வார்களென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	31. என் மந்தையும் என் மேய்ச்சலின் ஆடுகளுமாகிய நீங்கள் மனுஷர்; நான் உங்கள் தேவன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial34