import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah33 = () => {
  const verseNumber = 33;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 33 </title>
	<meta
          name="description"
          content="Jeremiah 33 | எரேமியா 33 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எரேமியா இன்னும் காவற்சாலையின் முற்றத்திலே அடைக்கப்பட்டிருக்கையில், கர்த்தருடைய வார்த்தை இரண்டாந்தரம் அவனுக்கு உண்டாகி, அவர்:
	<br /><br />
	2. இதைச் செய்கிற கர்த்தருமாய், இதைத் திடப்படுத்தும்படிக்கு இதை உருவேற்படுத்துகிற கர்த்தருமாயிருக்கிற யேகோவா என்னும் நாமமுள்ளவர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	3. என்னை நோக்கிக் கூப்பிடு, அப்பொழுது நான் உனக்கு உத்தரவுகொடுத்து, நீ அறியாததும் உனக்கு எட்டாததுமான பெரிய காரியங்களை உனக்கு அறிவிப்பேன்.
	<br /><br />
	4. எதிர்க்கொத்தளங்களினாலும் பட்டயத்தாலும் இடிக்கப்பட்டவைகளாகிய இந்த நகரத்தின் வீடுகளையும், யூதா ராஜாக்களின் வீடுகளையுங்குறித்து:
	<br /><br />
	5. இந்த நகரத்தின் எல்லாப் பொல்லாப்பினிமித்தமும் நான் என் முகத்தை மறைத்தபடியினாலே என் கோபத்திலும் உக்கிரத்திலும் வெட்டுண்ட மனுஷப் பிரேதங்களினாலே அவைகளை நான் நிரப்பும்படியாகவே, அவர்கள் கல்தேயரோடே யுத்தம்பண்ணப்போகிறார்கள்.
	<br /><br />
	6. இதோ, நான் அவர்களுக்குச் சவுக்கியமும் ஆரோக்கியமும் வரப்பண்ணி, அவர்களைக் குணமாக்கி, அவர்களுக்குப் பரிபூரண சமாதானத்தையும் சத்தியத்தையும் வெளிப்படுத்துவேன்.
	<br /><br />
	7. நான் யூதாவின் சிறையிருப்பையும், இஸ்ரவேலின் சிறையிருப்பையும் திருப்பி, முன்னிருந்ததுபோல அவர்களைக் கட்டுவித்து,
	<br /><br />
	8. அவர்கள் எனக்கு விரோதமாய்க் குற்றஞ்செய்த அவர்களுடைய எல்லா அக்கிரமங்களுக்கும் அவர்களை நீங்கலாக்கிச் சுத்திகரித்து, அவர்கள் எனக்கு விரோதமாய்க் குற்றஞ்செய்து, எனக்கு விரோதமாய்த் துரோகம்பண்ணின அவர்களுடைய எல்லா அக்கிரமங்களையும் மன்னிப்பேன்.
	<br /><br />
	9. நான் அவர்களுக்குச் செய்யும் நன்மையையெல்லாம் கேட்கப்போகிற பூமியின் எல்லா ஜாதிகளுக்குமுன்பாக அது எனக்கு மகிழ்ச்சியுள்ள கீர்த்தியாயும் புகழ்ச்சியாயும் மகிமையாயும் இருக்கும்; நான் அவர்களுக்கு அருளிச்செய்யும் எல்லா நன்மையினிமித்தமும், எல்லாச் சமாதானத்தினிமித்தமும் இவர்கள் பயந்து நடுங்குவார்கள் என்று இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. மனுஷனில்லாமலும் மிருகமில்லாமலும் அவாந்தரவெளியாய்க் கிடக்கிறதென்று, நீங்கள் சொல்லுகிற இவ்விடத்திலும், யூதாவின் பட்டணங்களிலும் மனுஷனாவது மிருகமாவது இல்லாத பாழான எருசலேமின் வீதிகளிலும்,
	<br /><br />
	11. இன்னும் களிப்பின் சத்தமும், மகிழ்ச்சியின் சத்தமும், மணவாளனின் சத்தமும், மணவாட்டியின் சத்தமும்: சேனைகளின் கர்த்தரைத் துதியுங்கள், கர்த்தர் நல்லவர், அவர் கிருபை என்றுமுள்ளதென்று சொல்லுகிறவர்களின் சத்தமும், கர்த்தருடைய ஆலயத்துக்கு ஸ்தோத்திரபலிகளைக் கொண்டுவருகிறவர்களின் சத்தமும் கேட்கப்படும் என்று கர்த்தர் சொல்லுகிறார்; அவர்கள் முன்னிருந்ததுபோலிருக்கும்படி தேசத்தின் சிறையிருப்பைத் திருப்புவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. மனுஷனும் மிருகமும் இல்லாமல் அவாந்தரவெளியாய்க் கிடக்கிற இவ்விடத்திலும், இதற்கடுத்த பட்டணங்களிலும், ஆட்டுமந்தையை மேய்த்து மடக்குகிற மேய்ப்பர்களின் தாபரங்கள் உண்டாயிருக்கும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. மலைத்தேசமான பட்டணங்களிலும், பள்ளத்தாக்குகளான பட்டணங்களிலும், தென்திசைப் பட்டணங்களிலும் பென்யமீன் நாட்டிலும், எருசலேமின் சுற்றுப்புறங்களிலும், யூதாவின் பட்டணங்களிலும், ஆட்டுமந்தைகள் தங்களைத் தொகையிடுகிறவனுடைய கைக்குள்ளாகக் கடந்துவரும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது நான் இஸ்ரவேலின் குடும்பத்துக்கும், யூதாவின் குடும்பத்துக்கும் சொன்ன நல்வார்த்தையை நிறைவேற்றுவேன்.
	<br /><br />
	15. அந்நாட்களிலும், அக்காலத்திலும் தாவீதுக்கு நீதியின் கிளையை முளைக்கப்பண்ணுவேன்; அவர் பூமியிலே நியாயத்தையும் நீதியையும் நடப்பிப்பார்.
	<br /><br />
	16. அந்நாட்களில் யூதா இரட்சிக்கப்பட்டு, எருசலேம் சுகமாய்த் தங்கும்; அவர் எங்கள் நீதியாயிருக்கிற கர்த்தர் என்பது அவருடைய நாமம்.
	<br /><br />
	17. இஸ்ரவேல் வம்சத்தின் சிங்காசனத்தின்மேல் உட்காரத்தக்க புருஷன் தாவீதுக்கு இல்லாமற்போவதில்லை.
	<br /><br />
	18. தகனபலியிட்டு, போஜனபலிசெலுத்தி, நாள்தோறும் பலியிடும் புருஷன் எனக்கு முன்பாக ஆசாரியருக்கும் லேவியருக்கும் இல்லாமற்போவதுமில்லை என்று கர்த்தர் சொல்லுகிறார் என்றார்.
	<br /><br />
	19. பின்னும் எரேமியாவுக்குக் கர்த்தருடைய வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	20. குறித்த நேரங்களில் பகற்காலமும் இராக்காலமும் உண்டாகாதபடிக்கு, நீங்கள் பகற்காலத்தைக்குறித்து நான் பண்ணின உடன்படிக்கையையும், இராக்காலத்தைக்குறித்து நான் பண்ணின உடன்படிக்கையையும் அவமாக்கினால்,
	<br /><br />
	21. அப்பொழுது என் தாசனாகிய தாவீதோடே நான் பண்ணின உடன்படிக்கையும், அவன் சிங்காசனத்தில் அரசாளும் குமாரன் அவனுக்கு இல்லாமற்போகும்படியாக அவமாகும்; என் ஊழியக்காரராகிய லேவியரோடும் ஆசாரியரோடும் நான் பண்ணின உடன்படிக்கையும் அப்பொழுது அவமாகும்.
	<br /><br />
	22. வானத்து நட்சத்திரங்கள் எண்ணப்படாததும் கடற்கரை மணல் அளக்கப்படாததுமாயிருக்கிறதுபோல, நான் என் தாசனாகிய தாவீதின் சந்ததியையும் எனக்கு ஊழியஞ்செய்கிற லேவியரையும் வர்த்திக்கப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார் என்றார்.
	<br /><br />
	23. பின்னும் எரேமியாவுக்குக் கர்த்தருடைய வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	24. கர்த்தர் தெரிந்துகொண்ட இரண்டு வம்சங்களையும் வெறுத்துப்போட்டாரென்று இந்த ஜனம் சொல்லி, தங்களுக்கு முன்பாக என் ஜனம் இனி ஒரு ஜாதியல்லவென்று அதைத் தூஷிக்கிறார்களென்பதை நீ காண்கிறதில்லையோ?
	<br /><br />
	25. வானத்துக்கும் பூமிக்கும் குறித்திருக்கிற நியமங்களை நான் காவாமல், பகற்காலத்தையும் இராக்காலத்தையுங்குறித்து நான் பண்ணின உடன்படிக்கை அற்றுப்போகிறது உண்டானால்,
	<br /><br />
	26. அப்பொழுது நான் யாக்கோபின் சந்ததியையும், என் தாசனாகிய தாவீதின் சந்ததியையும் தள்ளி, நான் ஆபிரகாம் ஈசாக்கு யாக்கோபு என்பவர்களின் சந்ததியை ஆளத்தக்கவர்களை அதிலிருந்து எடுக்காதபடிக்கு வெறுத்துப்போடுவேன்; அவர்களுடைய சிறையிருப்பை நான் திருப்பி, அவர்களுக்கு இரங்குவேன் என்று கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah33