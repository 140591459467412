import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 18 </title>
	<meta
          name="description"
          content="The Book of Revelation - 18 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 18 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஒரு தேவதை, "விழுந்தது, விழுந்தது பாபிலோன்!" அரசர்களும் வணிகர்களும் அழுவார்கள். ஒரு தேவதை, "பெரிய நகரம் தூக்கி எறியப்படும்" என்றார். *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இவைகளுக்குப்பின்பு, வேறொரு தூதன் மிகுந்த அதிகாரமுடையவனாய், வானத்திலிருந்திறங்கி வரக்கண்டேன்; அவனுடைய மகிமையினால் பூமி பிரகாசமாயிற்று.
	<br /><br />
	2. அவன் பலத்த சத்தமிட்டு: மகா பாபிலோன் விழுந்தது! விழுந்தது! அது பேய்களுடைய குடியிருப்பும், சகலவித அசுத்தஆவிகளுடைய காவல்வீடும், அசுத்தமும் அருவருப்புமுள்ள சகலவித பறவைகளுடைய கூடுமாயிற்று.
	<br /><br />
	3. அவளுடைய வேசித்தனத்தின் உக்கிரமான மதுவை எல்லா ஜாதிகளும் குடித்தார்கள்; பூமியின் ராஜாக்கள் அவளோடே வேசித்தனம்பண்ணினார்கள்; பூமியின் வர்த்தகர் அவளுடைய செல்வச்செருக்கின் மிகுதியினால் ஐசுவரியவான்களானார்கள் என்று விளம்பினான்.
	<br /><br />
	4. பின்பு, வேறொரு சத்தம் வானத்திலிருந்து உண்டாகக் கேட்டேன். அது: என் ஜனங்களே, நீங்கள் அவளுடைய பாவங்களுக்கு உடன்படாமலும், அவளுக்கு நேரிடும் வாதைகளில் அகப்படாமலும் இருக்கும்படிக்கு அவளைவிட்டு வெளியே வாருங்கள்.
	<br /><br />
	5. அவளுடைய பாவம் வானபரியந்தம் எட்டினது, அவளுடைய அநியாயங்களைத் தேவன் நினைவுகூர்ந்தார்.
	<br /><br />
	6. அவள் உங்களுக்குப் பலனளித்ததுபோல நீங்களும் அவளுக்குப் பலனளியுங்கள்; அவளுடைய கிரியைகளுக்குத்தக்கதாக அவளுக்கு இரட்டிப்பாகக் கொடுத்துத் தீருங்கள்; அவள் உங்களுக்குக் கலந்துகொடுத்த பாத்திரத்திலே இரட்டிப்பாக அவளுக்குக் கலந்துகொடுங்கள்.
	<br /><br />
	7. அவள் தன்னை மகிமைப்படுத்தி, செல்வச்செருக்காய் வாழ்ந்ததெவ்வளவோ அவ்வளவாய் வாதையையும் துக்கத்தையும் அவளுக்குக் கொடுங்கள். நான் ராஜஸ்திரீயாய் வீற்றிருக்கிறேன்; நான் கைம்பெண்ணல்ல, நான் துக்கத்தைக் காண்பதில்லையென்று அவள் தன் இருதயத்திலே எண்ணினாள்.
	<br /><br />
	8. ஆகையால் அவளுக்கு வரும் வாதைகளாகிய சாவும் துக்கமும் பஞ்சமும் ஒரேநாளிலே வரும்; அவள் அக்கினியினாலே சுட்டெரிக்கப்படுவாள்; அவளுக்கு நியாயத்தீர்ப்புக் கொடுக்கும் தேவனாகிய கர்த்தர் வல்லமையுள்ளவர்.
	<br /><br />
	9. அவளுடனே வேசித்தனஞ்செய்து செல்வச்செருக்காய் வாழ்ந்த பூமியின் ராஜாக்களும் அவள் வேகிறதினால் உண்டான புகையைக் காணும்போது அவளுக்காக அழுது புலம்பி,
	<br /><br />
	10. அவளுக்கு உண்டான வாதையினால் பயந்து, தூரத்திலே நின்று: ஐயையோ! பாபிலோன், மகா நகரமே! பலமான பட்டணமே! ஒரே நாழிகையில் உனக்கு ஆக்கினை வந்ததே! என்பார்கள்.
	<br /><br />
	11. பூமியின் வர்த்தகர்களும் தங்கள் தங்கள் சரக்குகளாகிய பொன்னையும், வெள்ளியையும், இரத்தினங்களையும், முத்துக்களையும், சல்லாவையும், இரத்தாம்பரத்தையும், பட்டாடைகளையும், சிவப்பாடைகளையும்,
	<br /><br />
	12. சகலவித வாசனைக் கட்டைகளையும், தந்தத்தினால் செய்திருக்கிற சகலவித வஸ்துக்களையும், விலையுயர்ந்த மரத்தினாலும் வெண்கலத்தினாலும் இரும்பினாலும் வெள்ளைக் கல்லினாலும் செய்திருக்கிற சகலவித வஸ்துக்களையும்,
	<br /><br />
	13. இலவங்கப்பட்டையையும், தூபவர்க்கங்களையும், தைலங்களையும், சாம்பிராணியையும், திராட்சரசத்தையும், எண்ணெயையும், மெல்லிய மாவையும், கோதுமையையும், மாடுகளையும், ஆடுகளையும், குதிரைகளையும், இரதங்களையும், அடிமைகளையும், மனுஷருடைய ஆத்துமாக்களையும் இனிக் கொள்வாரில்லாதபடியால், அவளுக்காக அழுது புலம்புவார்கள்.
	<br /><br />
	14. உன் ஆத்துமா இச்சித்த பழவர்க்கங்கள் உன்னைவிட்டு நீங்கிப்போயின; கொழுமையானவைகளும் சம்பிரமமானவைகளும் உன்னைவிட்டு நீங்கிப்போயின; நீ அவைகளை இனிக் காண்பதில்லை.
	<br /><br />
	15. இப்படிப்பட்டவைகளைக் கொண்டு வர்த்தகம்பண்ணி, அவளால் ஐசுவரியவான்களானவர்கள் அவளுக்கு உண்டான வாதையினால் பயந்து, தூரத்திலே நின்று;
	<br /><br />
	16. ஐயையோ! சல்லாவும் இரத்தாம்பரமும் சிவப்பாடையும் தரித்து, பொன்னினாலும் இரத்தினங்களினாலும் முத்துக்களினாலும் சிங்காரிக்கப்பட்டிருந்த மகா நகரமே! ஒரு நாழிகையிலே இவ்வளவு ஐசுவரியமும் அழிந்துபோயிற்றே! என்று சொல்லி, அழுது துக்கிப்பார்கள்.
	<br /><br />
	17. மாலுமிகள் யாவரும், கப்பல்களில் யாத்திரை பண்ணுகிறவர்கள் யாவரும், கப்பலாட்களும், சமுத்திரத்திலே தொழில்செய்கிற யாவரும் தூரத்திலே நின்று,
	<br /><br />
	18. அவள் வேகிறதினால் உண்டான புகையைப் பார்த்து: இந்த மகா நகரத்திற்கொப்பான நகரம் உண்டோ என்று சத்தமிட்டு,
	<br /><br />
	19. தங்கள் தலைகள்மேல் புழுதியைப்போட்டுக்கொண்டு: ஐயையோ, மகா நகரமே! சமுத்திரத்திலே கப்பல்களையுடைய அனைவரும் இவளுடைய உச்சிதமான சம்பூரணத்தினால் ஐசுவரியவான்களானார்களே! ஒரு நாழிகையிலே இவள் பாழாய்ப்போனாளே! என்று அழுது துக்கித்து ஓலமிடுவார்கள்.
	<br /><br />
	20. பரலோகமே! பரிசுத்தவான்களாகிய அப்போஸ்தலர்களே! தீர்க்கதரிசிகளே! அவளைக்குறித்துக் களிகூருங்கள். உங்கள் நிமித்தம் தேவன் அவளை நியாயந்தீர்த்தாரே! என்று தூதன் சொன்னான்.
	<br /><br />
	21. அப்பொழுது, பலமுள்ள தூதனொருவன் பெரிய ஏந்திரக்கல்லையொத்த ஒரு கல்லை எடுத்துச் சமுத்திரத்திலே எறிந்து: இப்படியே பாபிலோன் மகாநகரம் வேகமாய்த் தள்ளுண்டு, இனி ஒருபோதும் காணப்படாமற்போகும்.
	<br /><br />
	22. சுரமண்டலக்காரரும், கீதவாத்தியக்காரரும், நாகசுரக்காரரும், எக்காளக்காரருமானவர்களுடைய சத்தம் இனி உன்னிடத்தில் கேட்கப்படுவதுமில்லை; எந்தத் தொழிலாளியும் இனி உன்னிடத்தில் காணப்படுவதுமில்லை; ஏந்திரசத்தம் இனி உன்னிடத்தில் கேட்கப்படுவதுமில்லை.
	<br /><br />
	23. விளக்குவெளிச்சம் இனி உன்னிடத்தில் பிரகாசிப்பதுமில்லை; மணவாளனும் மணவாட்டியுமானவர்களுடைய சத்தம் இனி உன்னிடத்தில் கேட்கப்படுவதுமில்லை. உன் வர்த்தகர் பூமியில் பெரியோர்களாயிருந்தார்களே; உன் சூனியத்தால் எல்லா ஜாதிகளும் மோசம்போனார்களே.
	<br /><br />
	24. தீர்க்கதரிசிகளுடைய இரத்தமும் பரிசுத்தவான்களுடைய இரத்தமும் பூமியில் கொல்லப்பட்ட அனைவருடைய இரத்தமும் அவளிடத்தில் காணப்பட்டது என்று விளம்பினான்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h18>
*  *
			</h18>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation18