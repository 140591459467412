import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah30 = () => {
  const verseNumber = 30;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 30 </title>
	<meta
          name="description"
          content="Jeremiah 30 | எரேமியா 30 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தராலே எரேமியாவுக்கு உண்டான வார்த்தை:
	<br /><br />
	2. இஸ்ரவேலின் தேவனாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால், நான் உன்னோடே சொன்ன எல்லா வார்த்தைகளையும் ஒரு புஸ்தகத்தில் எழுதிக்கொள்.
	<br /><br />
	3. இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது நான் இஸ்ரவேலும் யூதாவுமாகிய என் ஜனத்தினுடைய சிறையிருப்பைத் திருப்பி, நான் அவர்கள் பிதாக்களுக்குக் கொடுத்த தேசத்துக்கு அவர்களைத் திரும்பிவரப்பண்ணுவேன்; அதை அவர்கள் சுதந்தரித்துக்கொள்ளுவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. இவைகள் கர்த்தர் இஸ்ரவேலையும் யூதாவையுங்குறித்துச் சொன்ன வார்த்தைகளே.
	<br /><br />
	5. கர்த்தர் சொல்லுகிறது என்னவென்றால்: தத்தளிப்பின் சத்தத்தைக் கேட்கிறோம்; திகிலுண்டு, சமாதானமில்லை.
	<br /><br />
	6. ஆணாய்ப் பிறந்தவன் பிரசவிக்கிறதுண்டோ என்று கேட்டுப்பாருங்கள்; பிரசவிக்கிற ஸ்திரீயைப்போல் புருஷர் யாவரும் தங்கள் இடுப்புகளின்மேல் தங்கள் கைகளை வைத்திருக்கிறதையும், முகங்களெல்லாம் மாறி வெளுத்திருக்கிறதையும் நான் காண்கிறதென்ன?
	<br /><br />
	7. ஐயோ! அந்த நாள் பெரியது; அதைப்போலொத்த நாளில்லை; அது யாக்கோபுக்கு இக்கட்டுக்காலம்; ஆனாலும் அவன் அதற்கு நீங்கலாகி இரட்சிக்கப்படுவான்.
	<br /><br />
	8. அந்நாளில் நான் அவன் நுகத்தை உன் கழுத்தின்மேல் இராதபடிக்கு உடைத்து, உன் கட்டுகளை அறுப்பேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; அந்நியர் இனி அவனை அடிமைகொள்வதில்லை.
	<br /><br />
	9. தங்கள் தேவனாகிய கர்த்தரையும், நான் தங்களுக்கு எழுப்பப்போகிற தங்கள் ராஜாவாகிய தாவீதையுமே சேவிப்பார்கள்.
	<br /><br />
	10. ஆகையால் என் தாசனாகிய யாக்கோபே, நீ பயப்படாதே; இஸ்ரவேலே, கலங்காதே என்று கர்த்தர் சொல்லுகிறார்; இதோ, நான் உன்னைத் தூரத்திலும், உன் சந்ததியைத் தங்கள் சிறையிருப்பின் தேசத்திலும் இராதபடிக்கு இரட்சிப்பேன்; யாக்கோபு திரும்பிவந்து அமர்ந்து சுகித்திருப்பான்; அவனைத் தத்தளிக்கப்பண்ணுகிறவனில்லை.
	<br /><br />
	11. உன்னை இரட்சிப்பதற்காக நான் உன்னோடே இருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்; உன்னைச் சிதறடித்த எல்லா ஜாதிகளையும் நான் நிர்மூலமாக்குவேன்; உன்னையோ நான் நிர்மூலமாக்காமலும், முற்றிலும் தண்டியாமல் விடாமலும், மட்டாய்த் தண்டிப்பேன்.
	<br /><br />
	12. கர்த்தர் சொல்லுகிறது என்னவென்றால்: உன் புண் ஆறாததாயும் உன் காயம் கொடிதாயும் இருக்கிறது.
	<br /><br />
	13. உன் காயங்களைக் கட்டும்படி உனக்காக ஏற்படுவாரில்லை; உன்னைச் சொஸ்தப்படுத்தும் ஔஷதங்களுமில்லை.
	<br /><br />
	14. உன் நேசர் யாவரும் உன்னை மறந்தார்கள்; அவர்கள் உன்னைத் தேடார்கள்; திரளான உன் அக்கிரமத்தினிமித்தமும், உன் பாவங்கள் பலத்துப்போனதினிமித்தமும், சத்துரு வெட்டும் வண்ணமாகவும், கொடியவன் தண்டிக்கிற வண்ணமாகவும் நான் உன்னைத் தண்டித்தேன்.
	<br /><br />
	15. உன் நொறுங்குதலினாலும் உன் வேதனையின் மிகுதியினாலும் நீ கூக்குரலிடுவானேன்? திரளான உன் அக்கிரமத்தினிமித்தமும் பலத்துப்போன உன் பாவங்களினிமித்தமும் இப்படி உனக்குச் செய்தேன்.
	<br /><br />
	16. ஆதலால் உன்னைப் பட்சிக்கிறவர்கள் யாவரும் பட்சிக்கப்படுவார்கள்; உன் சத்துருக்களெல்லாரும் சிறைப்பட்டுப்போவார்கள்; உன்னைச் சூறையாடுகிறவர்கள் சூறையாடப்படுவார்கள்; உன்னைக் கொள்ளையிடுகிற அனைவரையும் கொள்ளைக்கு ஒப்புக்கொடுப்பேன்.
	<br /><br />
	17. அவர்கள்: உன்னை விசாரிப்பாரற்ற சீயோன் என்று சொல்லி, உனக்குத் தள்ளுண்டவள் என்று பேரிட்டபடியால், நான் உனக்கு ஆரோக்கியம் வரப்பண்ணி, உன் காயங்களை ஆற்றுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் யாக்கோபுடைய கூடாரங்களின் சிறையிருப்பைத் திருப்பி, அவன் வாசஸ்தலங்களுக்கு இரக்கஞ்செய்வேன்; நகரம் தன் மண்மேட்டின்மேல் கட்டப்பட்டு, அரமனை முன்போல நிலைப்படும்.
	<br /><br />
	19. அவைகளிலிருந்து ஸ்தோத்திரமும் ஆடல்பாடலின் சத்தமும் புறப்படும்; அவர்களை வர்த்திக்கப்பண்ணுவேன், அவர்கள் குறுகிப்போவதில்லை; அவர்களை மகிமைப்படுத்துவேன், அவர்கள் சிறுமைப்படுவதில்லை.
	<br /><br />
	20. அவர்கள் பிள்ளைகள் முன்போலிருப்பார்கள்; அவர்கள் சபை எனக்கு முன்பாகத் திடப்படும்; அவர்களை ஒடுக்கின யாவரையும் தண்டிப்பேன்.
	<br /><br />
	21. அவர்களுடைய பிரபு அவர்களில் ஒருவனாயிருக்க, அவர்களுடைய அதிபதி அவர்கள் நடுவிலிருந்து தோன்றுவார்; அவரைச் சமீபித்து வரப்பண்ணுவேன், அவர் சமீபித்து வருவார், என்னிடத்தில் சேரும்படி தன் இருதயத்தைப் பிணைப்படுத்துகிற இவர் யார்? என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	22. நீங்கள் என் ஜனமாயிருப்பீர்கள், நான் உங்கள் தேவனாயிருப்பேன்.
	<br /><br />
	23. இதோ, கோராவாரிக் காற்றாகிய கர்த்தருடைய பெருங்காற்று உக்கிரமாயெழும்பி, அடித்து, துன்மார்க்கருடைய தலையின்மேல் மோதும்.
	<br /><br />
	24. கர்த்தர் தம்முடைய இருதயத்தின் நினைவுகளை நடப்பித்து நிறைவேற்றுமளவும், அவருடைய உக்கிரகோபம் தணியாது; கடைசிநாட்களில் அதை உணர்ந்துகொள்வீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah30