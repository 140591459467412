import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah43 = () => {
  const verseNumber = 43;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 43 </title>
	<meta
          name="description"
          content="Isaiah 43 | ஏசாயா 43 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்போதும் யாக்கோபே, உன்னைச் சிருஷ்டித்தவரும், இஸ்ரவேலே, உன்னை உருவாக்கினவருமாகிய கர்த்தர் சொல்லுகிறதாவது: பயப்படாதே; உன்னை மீட்டுக்கொண்டேன்; உன்னைப் பேர்சொல்லி அழைத்தேன்; நீ என்னுடையவன்.
	<br /><br />
	2. நீ தண்ணீர்களைக் கடக்கும்போது நான் உன்னோடு இருப்பேன்; நீ ஆறுகளைக் கடக்கும்போது அவைகள் உன்மேல் புரளுவதில்லை; நீ அக்கினியில் நடக்கும்போது வேகாதிருப்பாய்; அக்கினிஜுவாலை உன்பேரில் பற்றாது.
	<br /><br />
	3. நான் இஸ்ரவேலின் பரிசுத்தரும், உன் இரட்சகருமாயிருக்கிற உன் தேவனாகிய கர்த்தர்; உன்னை மீட்கும்பொருளாக எகிப்தையும், உனக்கு ஈடாக எத்தியோப்பியாவையும் சேபாவையும் கொடுத்தேன்.
	<br /><br />
	4. நீ என் பார்வைக்கு அருமையானபடியினால் கனம்பெற்றாய்; நானும் உன்னைச் சிநேகித்தேன், ஆதலால் உனக்குப் பதிலாக மனுஷர்களையும், உன் ஜீவனுக்கு ஈடாக ஜனங்களையும் கொடுப்பேன்.
	<br /><br />
	5. பயப்படாதே, நான் உன்னோடே இருக்கிறேன்; நான் உன் சந்ததியைக் கிழக்கிலிருந்து வரப்பண்ணி, உன்னை மேற்கிலும் இருந்து கூட்டிச்சேர்ப்பேன்.
	<br /><br />
	6. நான் வடக்கை நோக்கி: கொடு என்றும், தெற்கை நோக்கி: வைத்திராதே என்றும் சொல்லி, தூரத்திலிருந்து என் குமாரரையும், பூமியின் கடையாந்தரத்திலிருந்து என் குமாரத்திகளையும்,
	<br /><br />
	7. நான் என் மகிமைக்கென்று சிருஷ்டித்து உருவாக்கிப் படைத்து, என் நாமந்தரிக்கப்பட்ட யாவரையும் கொண்டுவா என்பேன்.
	<br /><br />
	8. கண்களிருந்தும் குருடராயிருக்கிற ஜனத்தையும், காதுகளிருந்தும் செவிடராயிருக்கிறவர்களையும் புறப்பட்டு வரப்பண்ணுங்கள்.
	<br /><br />
	9. சகல ஜாதிகளும் ஏகமாய்ச் சேர்ந்துகொண்டு, சகல ஜனங்களும் கூடிவரட்டும்; இதை அறிவித்து, முந்தி சம்பவிப்பவைகளை நமக்குத் தெரிவிக்கிறவன் யார்? கேட்டு மெய்யென்று சொல்லக்கூடும்படிக்கு, அவர்கள் தங்கள் சாட்சிகளைக் கொண்டுவந்து யதார்த்தவான்களாய் விளங்கட்டும்.
	<br /><br />
	10. நானே அவரென்று நீங்கள் உணர்ந்து, என்னை அறிந்து விசுவாசிக்கும்படிக்கு, நீங்களும் நான் தெரிந்துகொண்ட என் தாசனும் எனக்குச் சாட்சிகளாயிருக்கிறீர்கள் என்று கர்த்தர் சொல்லுகிறார்; எனக்குமுன் ஏற்பட்ட தேவன் இல்லை; எனக்குப்பின் இருப்பதும் இல்லை.
	<br /><br />
	11. நான், நானே கர்த்தர்; என்னையல்லாமல் ரட்சகர் இல்லை.
	<br /><br />
	12. நானே அறிவித்து, இரட்சித்து, விளங்கப்பண்ணினேன்; உங்களில் இப்படிச் செய்யத்தக்க அந்நிய தேவன் இல்லை; நானே தேவன் என்பதற்கு நீங்கள் எனக்குச் சாட்சிகள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. நாள் உண்டாகாததற்கு முன்னும் நானே இருக்கிறேன்; என் கைக்குத் தப்புவிக்கத்தக்கவன் இல்லை; நான் செய்கிறதைத் தடுப்பவன் யார்?
	<br /><br />
	14. நான் உங்களுக்காக அரண்களெல்லாம் இடிந்துவிழவும், கல்தேயர் படவுகளிலிருந்து அலறவும் செய்யத்தக்கவர்களைப் பாபிலோனுக்கு அனுப்பினேனென்று, உங்கள் மீட்பரும் இஸ்ரவேலின் பரிசுத்தருமாகிய கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	15. நானே உங்கள் பரிசுத்தராகிய கர்த்தரும், இஸ்ரவேலின் சிருஷ்டிகரும், உங்கள் ராஜாவுமானவர்.
	<br /><br />
	16. சமுத்திரத்திலே வழியையும் வலிய தண்ணீர்களிலே பாதையையும் உண்டாக்கி,
	<br /><br />
	17. இரதங்களையும் குதிரைகளையும் இராணுவங்களையும் பராக்கிரமசாலிகளையும் புறப்படப்பண்ணி, அவைகள் எழுந்திராதபடிக்கு ஒருமித்து விழுந்து கிடக்கவும், ஒரு திரி அணைகிறதுபோல் அவைகள் அணைந்துபோகவும்பண்ணுகிற கர்த்தர் சொல்லுகிறதாவது:
	<br /><br />
	18. முந்தினவைகளை நினைக்கவேண்டாம்; பூர்வமானவைகளைச் சிந்திக்கவேண்டாம்.
	<br /><br />
	19. இதோ, நான் புதிய காரியத்தைச் செய்கிறேன்; இப்பொழுதே அது தோன்றும்; நீங்கள் அதை அறியீர்களா? நான் வனாந்தரத்திலே வழியையும், அவாந்தரவெளியிலே ஆறுகளையும் உண்டாக்குவேன்.
	<br /><br />
	20. நான் தெரிந்துகொண்ட என் ஜனத்தின் தாகத்துக்கு வனாந்தரத்திலே தண்ணீர்களையும் அவாந்தரவெளியிலே ஆறுகளையும் உண்டாக்குவதினால், காட்டுமிருகங்களும், வலுசர்ப்பங்களும், கோட்டான் குஞ்சுகளும் என்னைக் கனம்பண்ணும்.
	<br /><br />
	21. இந்த ஜனத்தை எனக்கென்று ஏற்படுத்தினேன்; இவர்கள் என் துதியைச் சொல்லிவருவார்கள்.
	<br /><br />
	22. ஆனாலும் யாக்கோபே, நீ என்னை நோக்கிக் கூப்பிடவில்லை; இஸ்ரவேலே, நீ என்னைக்குறித்து மனஞ்சலித்துப்போனாய்.
	<br /><br />
	23. உன் ஆடுகளைத் தகனபலிகளாக நீ எனக்குச் செலுத்தவில்லை; உன் பலிகளாலே நீ என்னைக் கனம்பண்ணவுமில்லை; காணிக்கைகளைச் செலுத்தும்படி நான் உன்னைச் சங்கடப்படுத்தாமலும், தூபங்காட்டும்படி உன்னை வருத்தப்படுத்தாமலும் இருந்தேன்.
	<br /><br />
	24. நீ எனக்குப் பணங்களால் சுகந்தபட்டையைக் கொள்ளாமலும், உன் பலிகளின் நிணத்தினால் என்னைத் திருப்தியாக்காமலும், உன் பாவங்களினால் என்னைச் சங்கடப்படுத்தி, உன் அக்கிரமங்களினால் என்னை வருத்தப்படுத்தினாய்.
	<br /><br />
	25. நான், நானே உன் மீறுதல்களை என் நிமித்தமாகவே குலைத்துப்போடுகிறேன்; உன் பாவங்களை நினையாமலும் இருப்பேன்.
	<br /><br />
	26. நாம் ஒருவரோடொருவர் வழக்காடும்படி எனக்கு நினைப்பூட்டு; நீ நீதிமானாக விளங்கும்படி உன் காரியத்தைச் சொல்.
	<br /><br />
	27. உன் ஆதிதகப்பன் பாவஞ்செய்தான்; உனக்கு முன்னின்று பேசுகிறவர்களும் எனக்கு விரோதமாய்த் துரோகம்பண்ணினார்கள்.
	<br /><br />
	28. ஆகையால், நான் பரிசுத்த ஸ்தலத்தின் தலைவர்களைப் பரிசுத்தக்குலைச்சலாக்கி, யாக்கோபைச் சாபத்துக்கும், இஸ்ரவேலை நிந்தனைக்கும் ஒப்புக்கொடுப்பேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah43