import React from 'react';
import '../.././App.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

function TwoThessalonians (){
return (
    <>
    <Helmet>
        <title> 2 Thessalonians in Tamil Bible Verse | 2 தெசலோனிக்கேயர் </title>
        <meta
          name="description"
          content="Book of Second Thessalonians in Tamil Bible Verse | 2 தெசலோனிக்கேயருக்கு எழுதின இரண்டாம் நிருபம் | புதிய ஏற்பாடு | New Testament"
        />
      </Helmet>
    <div className='body'>
        <h2 className='chapterh' align="center"> 2 தெசலோனிக்கேயர் </h2>
        <div className='chapter'>
        <div className='introduction'>
        <p className='article content'>
            {/* Introductions of Tamilbible verses */}
            <h2>
			தெசலோனிக்கேயருக்கு எழுதிய இரண்டாம் நிருபம்
</h2>
<h4>
	ஆசிரியர்:
</h4>
<p>
	2 தெசலோனிக்கேயர் 1:1, 2 தெசலோனிக்கேயர் புத்தகம் அப்போஸ்தலன் பவுலால் எழுதப்பட்டது, அநேகமாக சீலா மற்றும் தீமோத்தேயுவுடன் சேர்ந்து எழுதப்பட்டது.
</p>
<p>
	எழுதப்பட்ட தேதி: 2 தெசலோனிக்கேயர் புத்தகம் கிபி 51-52 இல் எழுதப்பட்டிருக்கலாம்.
</p>
<h2>
	எழுதப்பட்டதன் நோக்கம்
</h2>
<p>
	தெசலோனிக்காவில் உள்ள தேவாலயத்தில் கர்த்தருடைய நாளைப் பற்றி இன்னும் சில தவறான கருத்துகள் இருந்தன. அது ஏற்கனவே வந்துவிட்டதாக நினைத்த அவர்கள் தங்கள் வேலையை நிறுத்தினர். அவர்கள் மோசமாகத் துன்புறுத்தப்பட்டனர். தவறான எண்ணங்களைப் போக்கவும் அவர்களுக்கு ஆறுதல் அளிக்கவும் பவுல் எழுதினார்.
</p>
<h2>
	முக்கிய வசனங்கள்
</h2>
<p>
	2 தெசலோனிக்கேயர் 1:6-7, &ldquo;கடவுள் நீதியுள்ளவர்: உங்களைத் தொந்தரவு செய்பவர்களுக்கு அவர் துன்பத்தைத் திருப்பித் தருவார், கஷ்டப்படுகிற உங்களுக்கும் நமக்கும் நிவாரணம் அளிப்பார். கர்த்தராகிய இயேசு வானத்திலிருந்து எரிகிற அக்கினியில் வல்லமையுள்ள தேவதூதர்களுடன் வெளிப்படும்போது இது நடக்கும்."
</p>
<p>
	2 தெசலோனிக்கேயர் 2:13 , "ஆனால், கர்த்தரால் நேசிக்கப்படும் சகோதரர்களே, உங்களுக்காக நாங்கள் எப்போதும் கடவுளுக்கு நன்றி சொல்ல வேண்டும், ஏனென்றால் ஆவியின் பரிசுத்த கிரியையின் மூலமும் சத்தியத்தின் மீதுள்ள நம்பிக்கையின் மூலமும் இரட்சிக்கப்படுவதற்கு கடவுள் உங்களைத் தேர்ந்தெடுத்தார்."
</p>
<p>
	2 தெசலோனிக்கேயர் 3:3 , "ஆனால் கர்த்தர் உண்மையுள்ளவர், அவர் உங்களைப் பலப்படுத்தி, பொல்லாதவனிடமிருந்து பாதுகாப்பார்."
</p>
<p>
	2 தெசலோனிக்கேயர் 3:10 , &ldquo;நாங்கள் உங்களோடு இருந்தபோதும் உங்களுக்கு இந்த விதியைக் கொடுத்தோம்: ஒருவன் வேலை செய்யாவிட்டால், அவன் சாப்பிடமாட்டான்.&rdquo;
</p>
<h3>
	சுருக்கமான சுருக்கம்
</h3>
<p>
	பவுல் தெசலோனிக்காவில் உள்ள தேவாலயத்தை வாழ்த்துகிறார், மேலும் அவர்களை உற்சாகப்படுத்துகிறார். அவர்கள் கர்த்தருக்குள் என்ன செய்கிறார்கள் என்பதைக் கேள்விப்பட்டதற்காக அவர் அவர்களைப் பாராட்டுகிறார், மேலும் அவர் அவர்களுக்காக ஜெபிக்கிறார் ( 2 தெசலோனிக்கேயர் 1:11-12 ). அதிகாரம் 2ல், கர்த்தருடைய நாளில் என்ன நடக்கும் என்பதை பவுல் விளக்குகிறார் ( 2 தெசலோனிக்கேயர் 2:1-12 ). பின்னர் பவுல் அவர்களை உறுதியாக நிற்க ஊக்குவிக்கிறார் மற்றும் சுவிசேஷத்தின்படி வாழாத சும்மா இருக்கும் மனிதர்களிடமிருந்து விலகி இருக்குமாறு அறிவுறுத்துகிறார் ( 2 தெசலோனிக்கேயர் 3:6 ).
</p>
<h3>
	இணைப்புகள்
</h3>
<p>
	பால் தனது உரையில் பல பழைய ஏற்பாட்டு பத்திகளைக் குறிப்பிடுகிறார். இந்த கடிதத்தில் இறுதிக் காலம் பற்றிய அவரது போதனைகளில் பெரும்பாலானவை டேனியல் தீர்க்கதரிசி மற்றும் அவருடைய தரிசனங்களை அடிப்படையாகக் கொண்டவை. 2 தெசலோனிக்கேயர் 2:3-9 இல், "பாவத்தின் மனிதன்" ( டேனியல் 7-8 ) பற்றிய தானியேலின் தீர்க்கதரிசனத்தை அவர் குறிப்பிடுகிறார் .
</p>
<h2>
	நடைமுறை பயன்பாடு
</h2>
<p>
	2 தெசலோனிக்கேயர் புத்தகம் இறுதி நேரத்தை விளக்கும் தகவல்களால் நிரப்பப்பட்டுள்ளது. சும்மா இருக்காமல் இருப்பதற்காக உழைக்க வேண்டும் என்றும் அறிவுறுத்துகிறது. 2 தெசலோனிக்கேயர்களில் சில பெரிய ஜெபங்களும் உள்ளன, அவை இன்று மற்ற விசுவாசிகளுக்காக எவ்வாறு ஜெபிக்க வேண்டும் என்பதற்கு ஒரு எடுத்துக்காட்டு.
</p>


			<h1>
	THE SECOND LETTER TO Thessalonians Tamil Bible
</h1>
<h3>
	Author
</h3>
<p>
	2 Thessalonians 1:1 indicates that the Book of 2 Thessalonians was written by the apostle Paul, probably along with Silas and Timothy.
</p>
<h3>
	Date of Writing
</h3>
<p>
	The Book of 2 Thessalonians was likely written in AD 51-52.
</p>
<p>
	Purpose of Writing: The church in Thessalonica still had some misconceptions about the Day of the Lord. They thought it had come already so they stopped with their work. They were being persecuted badly. Paul wrote to clear up misconceptions and to comfort them.
</p>
<h2>
	Key Verses
</h2>
<p>
	2 Thessalonians 1:6-7, &ldquo;God is just: He will pay back trouble to those who trouble you and give relief to you who are troubled, and to us as well. This will happen when the Lord Jesus is revealed from heaven in blazing fire with powerful angels.&rdquo;
</p>
<p>
	2 Thessalonians 2:13, &ldquo;But we ought always thank God for you, brothers loved by the Lord, because from the beginning God chose you to be saved through the sanctifying work of the Spirit and through belief in the truth.&rdquo;
</p>
<p>
	2 Thessalonians 3:3, &ldquo;But the Lord is faithful, and he will strengthen and protect you from the evil one.&rdquo;
</p>
<p>
	2 Thessalonians 3:10, &ldquo;For even when we were with you we gave you this rule: If a man will not work, he shall not eat.&rdquo;
</p>
<h2>
	Brief Summary
</h2>
<p>
	Paul greets the church at Thessalonica and encourages and exhorts them. He commends them for what he hears they are doing in the Lord, and he prays for them (2 Thessalonians 1:11-12). In chapter 2, Paul explains what will happen in the Day of the Lord (2 Thessalonians 2:1-12). Paul then encourages them to stand firm and instructs them to keep away from idle men who don&rsquo;t live by the gospel (2 Thessalonians 3:6).
</p>
<blockquote>
	<p>
		Connections
	</p>
</blockquote>
<p>
	Paul refers to several Old Testament passages in his discourse on the end times, thereby confirming and reconciling the OT prophets. Much of his teaching on the end times in this letter is based on the prophet Daniel and his visions. In 2 Thessalonians 2:3-9, he refers to Daniel&rsquo;s prophecy regarding the &ldquo;man of sin&rdquo; (Daniel 7&ndash;8).
</p>
<h2>
	Practical Application
</h2>
<p>
	The Book of 2 Thessalonians is filled with information that explains the end times. It also exhorts us not to be idle and to work for what we have. There are also some great prayers in 2 Thessalonians that can be an example for us on how to pray for other believers today.
</p>

            {/* End Introduction */}
        </p>

        </div>
        <div>
            <div className='chap-link'>
                <div className='btn'> <Link to="/2-Thessalonians-1"> 01</Link> </div>
                <div className='btn'> <Link to="/2-Thessalonians-2"> 02</Link> </div>
                <div className='btn'> <Link to="/2-Thessalonians-3"> 03</Link> </div>
				            
                
            </div>
        </div>
    </div>
    </div>
    </>
);
}

export default TwoThessalonians;