import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 1 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மகா கனம்பொருந்திய தெயோப்பிலுவே, நாங்கள் முழுநிச்சயமாய் நம்புகிற சங்கதிகளை,
	<br /><br />
	2. ஆரம்பமுதல் கண்ணாரக்கண்டு வசனத்தைப் போதித்தவர்கள் எங்களுக்கு ஒப்புவித்தபடியே அவைகளைக்குறித்துச் சரித்திரம் எழுத அநேகம்பேர் ஏற்பட்டபடியினால்,
	<br /><br />
	3. ஆதிமுதல் எல்லாவற்றையும் திட்டமாய் விசாரித்தறிந்த நானும் உமக்கு உபதேசிக்கப்பட்ட விசேஷங்களின் நிச்சயத்தை நீர் அறியவேண்டுமென்று,
	<br /><br />
	4. அவைகளை ஒழுங்காய் உமக்கு எழுதுவது எனக்கு நலமாய்த் தோன்றிற்று.
	<br /><br />
	5. யூதேயாதேசத்தின் ராஜாவாகிய ஏரோதின் நாட்களில், அபியா என்னும் ஆசாரிய வகுப்பில் சகரியா என்னும் பேர்கொண்ட ஆசாரியன் ஒருவன் இருந்தான். அவன் மனைவி ஆரோனுடைய குமாரத்திகளில் ஒருத்தி, அவள் பேர் எலிசபெத்து.
	<br /><br />
	6. அவர்கள் இருவரும் கர்த்தரிட்ட சகல கற்பனைகளின்படியேயும் நியமங்களின்படியேயும் குற்றமற்றவர்களாய் நடந்து, தேவனுக்கு முன்பாக நீதியுள்ளவர்களாயிருந்தார்கள்.
	<br /><br />
	7. எலிசபெத்து மலடியாயிருந்தபடியினால், அவர்களுக்குப் பிள்ளையில்லாதிருந்தது; இருவரும் வயதுசென்றவர்களாயும் இருந்தார்கள்.
	<br /><br />
	8. அப்படியிருக்க, அவன் தன் ஆசாரிய வகுப்பின் முறைப்படி தேவசந்நிதியிலே ஆசாரிய ஊழியம் செய்துவருகிற காலத்தில்,
	<br /><br />
	9. ஆசாரிய ஊழிய முறைமையின்படி அவன் தேவாலயத்துக்குள் பிரவேசித்துத் தூபங்காட்டுகிறதற்குச் சீட்டைப் பெற்றான்.
	<br /><br />
	10. தூபங்காட்டுகிற வேளையிலே ஜனங்களெல்லாரும் கூட்டமாய் வெளியே ஜெபம்பண்ணிக்கொண்டிருந்தார்கள்.
	<br /><br />
	11. அப்பொழுது கர்த்தருடைய தூதன் ஒருவன் தூபபீடத்தின் வலதுபக்கத்திலே நின்று அவனுக்குத் தரிசனமானான்.
	<br /><br />
	12. சகரியா அவனைக்கண்டு கலங்கி, பயமடைந்தான்.
	<br /><br />
	13. தூதன் அவனை நோக்கி: சகரியாவே, பயப்படாதே, உன் வேண்டுதல் கேட்கப்பட்டது; உன் மனைவியாகிய எலிசபெத்து உனக்கு ஒரு குமாரனைப் பெறுவாள், அவனுக்கு யோவான் என்று பேரிடுவாயாக.
	<br /><br />
	14. உனக்குச் சந்தோஷமும் மகிழ்ச்சியும் உண்டாகும், அவன் பிறப்பினிமித்தம் அநேகர் சந்தோஷப்படுவார்கள்.
	<br /><br />
	15. அவன் கர்த்தருக்கு முன்பாகப் பெரியவனாயிருப்பான், திராட்சரசமும் மதுவும் குடியான், தன் தாயின் வயிற்றிலிருக்கும்போதே பரிசுத்த ஆவியினால் நிரப்பப்பட்டிருப்பான்.
	<br /><br />
	16. அவன் இஸ்ரவேல் சந்ததியாரில் அநேகரை அவர்கள் தேவனாகிய கர்த்தரிடத்திற்குத் திருப்புவான்.
	<br /><br />
	17. பிதாக்களுடைய இருதயங்களைப் பிள்ளைகளிடத்திற்கும், கீழ்ப்படியாதவர்களை நீதிமான்களுடைய ஞானத்திற்கும் திருப்பி, உத்தமமான ஜனத்தைக் கர்த்தருக்கு ஆயத்தப்படுத்தும்படியாக, அவன் எலியாவின் ஆவியும் பலமும் உடையவனாய் அவருக்கு முன்னே நடப்பான் என்றான்.
	<br /><br />
	18. அப்பொழுது சகரியா தேவதூதனை நோக்கி: இதை நான் எதினால் அறிவேன்; நான் கிழவனாயிருக்கிறேன், என் மனைவியும் வயதுசென்றவளாயிருக்கிறாளே என்றான்.
	<br /><br />
	19. தேவதூதன் அவனுக்குப் பிரதியுத்தரமாக: நான் தேவசந்நிதானத்தில் நிற்கிற காபிரியேல் என்பவன்; உன்னுடனே பேசவும், உனக்கு இந்த நற்செய்தியை அறிவிக்கவும் அனுப்பப்பட்டு வந்தேன்;
	<br /><br />
	20. இதோ, தகுந்தகாலத்திலே நிறைவேறப்போகிற என் வார்த்தைகளை நீ விசுவாசியாதபடியினால் இவைகள் சம்பவிக்கும் நாள்மட்டும் நீ பேசக்கூடாமல் ஊமையாயிருப்பாய் என்றான்.
	<br /><br />
	21. ஜனங்கள் சகரியாவுக்குக் காத்திருந்து, அவன் தேவாலயத்தில் தாமதித்ததினால் ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	22. அவன் வெளியே வந்தபோது அவர்களிடத்தில் பேசக்கூடாமலிருந்தான்; அதினாலே தேவாலயத்தில் ஒரு தரிசனத்தைக் கண்டானென்று அறிந்தார்கள். அவனும் அவர்களுக்குச் சைகை காட்டி ஊமையாயிருந்தான்.
	<br /><br />
	23. அவனுடைய ஊழியத்தின் நாட்கள் நிறைவேறினவுடனே தன் வீட்டுக்குப்போனான்.
	<br /><br />
	24. அந்நாட்களுக்குப்பின்பு, அவன் மனைவியாகிய எலிசபெத்து கர்ப்பவதியாகி: ஜனங்களுக்குள்ளே எனக்கு உண்டாயிருந்த நிந்தையை நீக்கும்படியாகக் கர்த்தர் இந்த நாட்களில் என்மேல் கடாட்சம் வைத்து,
	<br /><br />
	25. எனக்கு இப்படிச் செய்தருளினார் என்று சொல்லி, ஐந்து மாதம் வெளிப்படாதிருந்தாள்.
	<br /><br />
	26. ஆறாம் மாதத்திலே காபிரியேல் என்னும் தூதன், கலிலேயாவிலுள்ள நாசரேத்தென்னும் ஊரில்,
	<br /><br />
	27. தாவீதின் வம்சத்தானாகிய யோசேப்பு என்கிற நாமமுள்ள புருஷனுக்கு நியமிக்கப்பட்டிருந்த ஒரு கன்னிகையினிடத்திற்கு தேவனாலே அனுப்பப்பட்டான்; அந்தக் கன்னிகையின் பேர் மரியாள்.
	<br /><br />
	28. அவள் இருந்த வீட்டில் தேவதூதன் பிரவேசித்து: கிருபை பெற்றவளே, வாழ்க, கர்த்தர் உன்னுடனே இருக்கிறார், ஸ்திரீகளுக்குள்ளே நீ ஆசீர்வதிக்கப்பட்டவள் என்றான்.
	<br /><br />
	29. அவளோ அவனைக் கண்டு, அவன் வார்த்தையினால் கலங்கி, இந்த வாழ்த்துதல் எப்படிப்பட்டதோ என்று சிந்தித்துக்கொண்டிருந்தாள்.
	<br /><br />
	30. தேவதூதன் அவளை நோக்கி: மரியாளே, பயப்படாதே; நீ தேவனிடத்தில் கிருபைபெற்றாய்.
	<br /><br />
	31. இதோ, நீ கர்ப்பவதியாகி ஒரு குமாரனைப் பெறுவாய், அவருக்கு இயேசு என்று பேரிடுவாயாக.
	<br /><br />
	32. அவர் பெரியவராயிருப்பார், உன்னதமானவருடைய குமாரன் என்னப்படுவார்; கர்த்தராகிய தேவன் அவருடைய பிதாவாகிய தாவீதின் சிங்காசனத்தை அவருக்குக் கொடுப்பார்.
	<br /><br />
	33. அவர் யாக்கோபின் குடும்பத்தாரை என்றென்றைக்கும் அரசாளுவார்; அவருடைய ராஜ்யத்துக்கு முடிவிராது என்றான்.
	<br /><br />
	34. அதற்கு மரியாள் தேவதூதனை நோக்கி: இது எப்படியாகும்? புருஷனை அறியேனே என்றாள்.
	<br /><br />
	35. தேவதூதன் அவளுக்குப் பிரதியுத்தரமாக: பரிசுத்தஆவி உன்மேல் வரும்; உன்னதமானவருடைய பலம் உன்மேல் நிழலிடும்; ஆதலால் உன்னிடத்தில் பிறக்கும் பரிசுத்தமுள்ளது தேவனுடைய குமாரன் என்னப்படும்.
	<br /><br />
	36. இதோ, உனக்கு இனத்தாளாயிருக்கிற எலிசபெத்தும் தன் முதிர்வயதிலே ஒரு புத்திரனைக் கர்ப்பந்தரித்திருக்கிறாள்; மலடியென்னப்பட்ட அவளுக்கு இது ஆறாம் மாதம்.
	<br /><br />
	37. தேவனாலே கூடாதகாரியம் ஒன்றுமில்லை என்றான்.
	<br /><br />
	38. அதற்கு மரியாள்: இதோ, நான் ஆண்டவருக்கு அடிமை, உம்முடைய வார்த்தையின்படி எனக்கு ஆகக்கடவது என்றாள். அப்பொழுது தேவதூதன் அவளிடத்திலிருந்து போய்விட்டான்.
	<br /><br />
	39. அந்நாட்களில் மரியாள் எழுந்து, மலைநாட்டிலே யூதாவிலுள்ள ஒரு பட்டணத்திற்குத் தீவிரமாய்ப் போய்,
	<br /><br />
	40. சகரியாவின் வீட்டுக்குள் பிரவேசித்து, எலிசபெத்தை வாழ்த்தினாள்.
	<br /><br />
	41. எலிசபெத்து மரியாளுடைய வாழ்த்துதலைக் கேட்டபொழுது, அவளுடைய வயிற்றிலிருந்த பிள்ளை துள்ளிற்று; எலிசபெத்து பரிசுத்த ஆவியினால் நிரப்பப்பட்டு,
	<br /><br />
	42. உரத்த சத்தமாய்: ஸ்திரீகளுக்குள்ளே நீ ஆசீர்வதிக்கப்பட்டவள், உன் கர்ப்பத்தின் கனியும் ஆசீர்வதிக்கப்பட்டது.
	<br /><br />
	43. என் ஆண்டவருடைய தாயார் என்னிடத்தில் வந்தது எனக்கு எதினால் கிடைத்தது.
	<br /><br />
	44. இதோ, நீ வாழ்த்தின சத்தம் என் காதில் விழுந்தவுடனே, என் வயிற்றிலுள்ள பிள்ளை களிப்பாய்த் துள்ளிற்று.
	<br /><br />
	45. விசுவாசித்தவளே பாக்கியவதி, கர்த்தராலே அவளுக்குச் சொல்லப்பட்டவைகள் நிறைவேறும் என்றாள்.
	<br /><br />
	46. அப்பொழுது மரியாள்: என் ஆத்துமா கர்த்தரை மகிமைப்படுத்துகிறது.
	<br /><br />
	47. என் ஆவி என் இரட்சகராகிய தேவனில் களிகூருகிறது.
	<br /><br />
	48. அவர் தம்முடைய அடிமையின் தாழ்மையை நோக்கிப்பார்த்தார்; இதோ, இதுமுதல் எல்லாச் சந்ததிகளும் என்னைப் பாக்கியவதி என்பார்கள்.
	<br /><br />
	49. வல்லமையுடையவர் மகிமையானவைகளை எனக்குச் செய்தார்; அவருடைய நாமம் பரிசுத்தமுள்ளது.
	<br /><br />
	50. அவருடைய இரக்கம் அவருக்குப் பயந்திருக்கிறவர்களுக்குத் தலைமுறை தலைமுறைக்குமுள்ளது.
</p>
<p>
	51. தம்முடைய புயத்தினாலே பராக்கிரமஞ்செய்தார்; இருதயசிந்தையில் அகந்தையுள்ளவர்களைச் சிதறடித்தார்.
	<br /><br />
	52. பலவான்களை ஆசனங்களிலிருந்து தள்ளி, தாழ்மையானவர்களை உயர்த்தினார்.
	<br /><br />
	53. பசியுள்ளவர்களை நன்மைகளினால் நிரப்பி, ஐசுவரியமுள்ளவர்களை வெறுமையாய் அனுப்பிவிட்டார்.
	<br /><br />
	54. நம்முடைய பிதாக்களுக்கு அவர் சொன்னபடியே, ஆபிரகாமுக்கும் அவன் சந்ததிக்கும் என்றென்றைக்கும் இரக்கஞ்செய்ய நினைத்து,
	<br /><br />
	55. தம்முடைய தாசனாகிய இஸ்ரவேலை ஆதரித்தார் என்றாள்.
	<br /><br />
	56. மரியாள் ஏறக்குறைய மூன்றுமாதம் அவளுடனே இருந்து, தன் வீட்டுக்குத் திரும்பிப்போனாள்.
	<br /><br />
	57. எலிசபெத்துக்குப் பிரசவகாலம் நிறைவேறினபோது அவள் ஒரு புத்திரனைப் பெற்றாள்.
	<br /><br />
	58. கர்த்தர் அவளிடத்தில் தம்முடைய இரக்கத்தை விளங்கப்பண்ணினாரென்று அவளுடைய அயலகத்தாரும் பந்துஜனங்களும் கேள்விப்பட்டு, அவளுடனேகூடச் சந்தோஷப்பட்டார்கள்.
	<br /><br />
	59. எட்டாம்நாளிலே பிள்ளைக்கு விருத்தசேதனம் பண்ணும்படிக்கு அவர்கள் வந்து, அதின் தகப்பனுடைய நாமத்தின்படி அதற்குச் சகரியா என்று பேரிடப்போனார்கள்.
	<br /><br />
	60. அப்பொழுது அதின் தாய்: அப்படியல்ல, அதற்கு யோவான் என்று பேரிடவேண்டும் என்றாள்.
	<br /><br />
	61. அதற்கு அவர்கள்: உன் உறவின் முறையாரில் இந்தப் பேருள்ளவன் ஒருவனும் இல்லையே என்று சொல்லி,
	<br /><br />
	62. அதின் தகப்பனை நோக்கி: இதற்கு என்ன பேரிட மனதாயிருக்கிறீர் என்று சைகையினால் கேட்டார்கள்.
	<br /><br />
	63. அவன் எழுத்துப் பலகையைக் கேட்டு வாங்கி, இவன் பேர் யோவான் என்று எழுதினான்; எல்லாரும் ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	64. உடனே அவனுடைய வாய் திறக்கப்பட்டு, அவனுடைய நாவும் கட்டவிழ்க்கப்பட்டு, தேவனை ஸ்தோத்திரித்துப் பேசினான்.
	<br /><br />
	65. அதினால் அவர்களைச்சுற்றி வாசமாயிருந்த யாவருக்கும் பயமுண்டாயிற்று. மேலும் யூதேயாவின் மலைநாடெங்கும் இந்த வர்த்தமானங்களெல்லாம் சொல்லிக்கொள்ளப்பட்டது.
	<br /><br />
	66. அவைகளைக் கேள்விப்பட்டவர்களெல்லாரும் தங்கள் மனதிலே அவைகளை வைத்துக்கொண்டு, இந்தப் பிள்ளை எப்படிப்பட்டதாயிருக்குமோ என்றார்கள். கர்த்தருடைய கரம் அந்தப் பிள்ளையோடே இருந்தது.
	<br /><br />
	67. அவனுடைய தகப்பனாகிய சகரியா பரிசுத்த ஆவியினாலே நிரப்பப்பட்டு, தீர்க்கதரிசனமாக:
	<br /><br />
	68. இஸ்ரவேலின் தேவனாகிய கர்த்தருக்கு ஸ்தோத்திரம் உண்டாவதாக.
	<br /><br />
	69. அவர் நம்முடைய பிதாக்களுக்கு வாக்குத்தத்தம்பண்ணின இரக்கத்தைச் செய்வதற்கும்;
	<br /><br />
	70. தம்முடைய பரிசுத்த உடன்படிக்கையை நினைத்தருளி:
	<br /><br />
	71. உங்கள் சத்துருக்களின் கைகளினின்று நீங்கள் விடுதலையாக்கப்பட்டு, உயிரோடிருக்கும் நாளெல்லாம் பயமில்லாமல் எனக்கு முன்பாகப் பரிசுத்தத்தோடும் நீதியோடும் எனக்கு ஊழியஞ்செய்யக் கட்டளையிடுவேன் என்று,
	<br /><br />
	72. அவர் நம்முடைய பிதாவாகிய ஆபிரகாமுக்கு இட்ட ஆணையை நிறைவேற்றுவதற்கும்;
	<br /><br />
	73. ஆதிமுதற்கொண்டிருந்த தம்முடைய பரிசுத்த தீர்க்கதரிசிகளின் வாக்கினால் தாம் சொன்னபடியே,
	<br /><br />
	74. தமது ஜனத்தைச் சந்தித்து மீட்டுக்கொண்டு, நம்முடைய சத்துருக்களினின்றும், நம்மைப் பகைக்கிற யாவருடைய கைகளினின்றும், நம்மை இரட்சிக்கும்படிக்கு,
	<br /><br />
	75. தம்முடைய தாசனாகிய தாவீதின் வம்சத்திலே நமக்கு இரட்சணியக் கொம்பை ஏற்படுத்தினார்.
	<br /><br />
	76. நீயோ பாலகனே, உன்னதமானவருடைய தீர்க்கதரிசி என்னப்படுவாய்; நீ கர்த்தருக்கு வழிகளை ஆயத்தம்பண்ணவும்,
	<br /><br />
	77. நமது தேவனுடைய உருக்கமான இரக்கத்தினாலே அவருடைய ஜனத்துக்குப் பாவமன்னிப்பாகிய இரட்சிப்பைத் தெரியப்படுத்தவும், அவருக்கு முன்னாக நடந்துபோவாய்.
	<br /><br />
	78. அந்தகாரத்திலும் மரண இருளிலும் உட்கார்ந்திருக்கிறவர்களுக்கு வெளிச்சம் தரவும்,
	<br /><br />
	79. நம்முடைய கால்களைச் சமாதானத்தின் வழியிலே நடத்தவும், அவ்விரக்கத்தினாலே உன்னதத்திலிருந்து தோன்றிய அருணோதயம் நம்மைச் சந்தித்திருக்கிறது என்றான்.
	<br /><br />
	80. அந்தப் பிள்ளை வளர்ந்து, ஆவியிலே பலங்கொண்டு, இஸ்ரவேலுக்குத் தன்னைக் காண்பிக்கும் நாள்வரைக்கும் வனாந்தரங்களிலே இருந்தான்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	Luke 1
</h2>
<h3>
	Introduction
</h3>
<p>
	<br /><br />
	1 Many have undertaken to draw up an account of the things that have been fulfilled among us,
	<br /><br />
	2 just as they were handed down to us by those who from the first were eyewitnesses and servants of the word.
	<br /><br />
	3 With this in mind, since I myself have carefully investigated everything from the beginning, I too decided to write an orderly account for you, most excellent Theophilus,
	<br /><br />
	4 so that you may know the certainty of the things you have been taught.
	<br /><br />
	The Birth of John the Baptist Foretold
	<br /><br />
	5 In the time of Herod king of Judea there was a priest named Zechariah, who belonged to the priestly division of Abijah; his wife Elizabeth was also a descendant of Aaron.
	<br /><br />
	6 Both of them were righteous in the sight of God, observing all the Lord&rsquo;s commands and decrees blamelessly.
	<br /><br />
	7 But they were childless because Elizabeth was not able to conceive, and they were both very old.
	<br /><br />
	8 Once when Zechariah&rsquo;s division was on duty and he was serving as priest before God,
	<br /><br />
	9 he was chosen by lot, according to the custom of the priesthood, to go into the temple of the Lord and burn incense.
	<br /><br />
	10 And when the time for the burning of incense came, all the assembled worshipers were praying outside.
	<br /><br />
	11 Then an angel of the Lord appeared to him, standing at the right side of the altar of incense.
	<br /><br />
	12 When Zechariah saw him, he was startled and was gripped with fear.
	<br /><br />
	13 But the angel said to him: &ldquo;Do not be afraid, Zechariah; your prayer has been heard. Your wife Elizabeth will bear you a son, and you are to call him John.
	<br /><br />
	14 He will be a joy and delight to you, and many will rejoice because of his birth,
	<br /><br />
	15 for he will be great in the sight of the Lord. He is never to take wine or other fermented drink, and he will be filled with the Holy Spirit even before he is born.
	<br /><br />
	16 He will bring back many of the people of Israel to the Lord their God.
	<br /><br />
	17 And he will go on before the Lord, in the spirit and power of Elijah, to turn the hearts of the parents to their children and the disobedient to the wisdom of the righteous&mdash;to make ready a people prepared for the Lord.&rdquo;
	<br /><br />
	18 Zechariah asked the angel, &ldquo;How can I be sure of this? I am an old man and my wife is well along in years.&rdquo;
	<br /><br />
	19 The angel said to him, &ldquo;I am Gabriel. I stand in the presence of God, and I have been sent to speak to you and to tell you this good news.
	<br /><br />
	20 And now you will be silent and not able to speak until the day this happens, because you did not believe my words, which will come true at their appointed time.&rdquo;
	<br /><br />
	21 Meanwhile, the people were waiting for Zechariah and wondering why he stayed so long in the temple.
	<br /><br />
	22 When he came out, he could not speak to them. They realized he had seen a vision in the temple, for he kept making signs to them but remained unable to speak.
	<br /><br />
	23 When his time of service was completed, he returned home.
	<br /><br />
	24 After this his wife Elizabeth became pregnant and for five months remained in seclusion.
	<br /><br />
	25 &ldquo;The Lord has done this for me,&rdquo; she said. &ldquo;In these days he has shown his favor and taken away my disgrace among the people.&rdquo;
	<br /><br />
	The Birth of Jesus Foretold
	<br /><br />
	26 In the sixth month of Elizabeth&rsquo;s pregnancy, God sent the angel Gabriel to Nazareth, a town in Galilee,
	<br /><br />
	27 to a virgin pledged to be married to a man named Joseph, a descendant of David. The virgin&rsquo;s name was Mary.
	<br /><br />
	28 The angel went to her and said, &ldquo;Greetings, you who are highly favored! The Lord is with you.&rdquo;
	<br /><br />
	29 Mary was greatly troubled at his words and wondered what kind of greeting this might be.
	<br /><br />
	30 But the angel said to her, &ldquo;Do not be afraid, Mary; you have found favor with God.
	<br /><br />
	31 You will conceive and give birth to a son, and you are to call him Jesus.
	<br /><br />
	32 He will be great and will be called the Son of the Most High. The Lord God will give him the throne of his father David,
	<br /><br />
	33 and he will reign over Jacob&rsquo;s descendants forever; his kingdom will never end.&rdquo;
	<br /><br />
	34 &ldquo;How will this be,&rdquo; Mary asked the angel, &ldquo;since I am a virgin?&rdquo;
	<br /><br />
	35 The angel answered, &ldquo;The Holy Spirit will come on you, and the power of the Most High will overshadow you. So the holy one to be born will be called the Son of God.
	<br /><br />
	36 Even Elizabeth your relative is going to have a child in her old age, and she who was said to be unable to conceive is in her sixth month.
	<br /><br />
	37 For no word from God will ever fail.&rdquo;
	<br /><br />
	38 &ldquo;I am the Lord&rsquo;s servant,&rdquo; Mary answered. &ldquo;May your word to me be fulfilled.&rdquo; Then the angel left her.
</p>
  <br /><br />
	Mary Visits Elizabeth
	<br /><br />
	39 At that time Mary got ready and hurried to a town in the hill country of Judea,
	<br /><br />
	40 where she entered Zechariah&rsquo;s home and greeted Elizabeth.
	<br /><br />
	41 When Elizabeth heard Mary&rsquo;s greeting, the baby leaped in her womb, and Elizabeth was filled with the Holy Spirit.
	<br /><br />
	42 In a loud voice she exclaimed: &ldquo;Blessed are you among women, and blessed is the child you will bear!
	<br /><br />
	43 But why am I so favored, that the mother of my Lord should come to me?
	<br /><br />
	44 As soon as the sound of your greeting reached my ears, the baby in my womb leaped for joy.
	<br /><br />
	45 Blessed is she who has believed that the Lord would fulfill his promises to her!&rdquo;
	<br /><br />
	Mary&rsquo;s Song
	<br /><br />
	46 And Mary said: &ldquo;My soul glorifies the Lord
	<br /><br />
	47 and my spirit rejoices in God my Savior,
	<br /><br />
	48 for he has been mindful of the humble state of his servant. From now on all generations will call me blessed,
	<br /><br />
	49 for the Mighty One has done great things for me&mdash; holy is his name.
	<br /><br />
	50 His mercy extends to those who fear him, from generation to generation.
	<br /><br />
	51 He has performed mighty deeds with his arm; he has scattered those who are proud in their inmost thoughts.
	<br /><br />
	52 He has brought down rulers from their thrones but has lifted up the humble.
	<br /><br />
	53 He has filled the hungry with good things but has sent the rich away empty.
	<br /><br />
	54 He has helped his servant Israel, remembering to be merciful
	<br /><br />
	55 to Abraham and his descendants forever, just as he promised our ancestors.&rdquo;
	<br /><br />
	56 Mary stayed with Elizabeth for about three months and then returned home.
	<br /><br />
	The Birth of John the Baptist
	<br /><br />
	57 When it was time for Elizabeth to have her baby, she gave birth to a son.
	<br /><br />
	58 Her neighbors and relatives heard that the Lord had shown her great mercy, and they shared her joy.
	<br /><br />
	59 On the eighth day they came to circumcise the child, and they were going to name him after his father Zechariah,
	<br /><br />
	60 but his mother spoke up and said, &ldquo;No! He is to be called John.&rdquo;
	<br /><br />
	61 They said to her, &ldquo;There is no one among your relatives who has that name.&rdquo;
	<br /><br />
	62 Then they made signs to his father, to find out what he would like to name the child.
	<br /><br />
	63 He asked for a writing tablet, and to everyone&rsquo;s astonishment he wrote, &ldquo;His name is John.&rdquo;
	<br /><br />
	64 Immediately his mouth was opened and his tongue set free, and he began to speak, praising God.
	<br /><br />
	65 All the neighbors were filled with awe, and throughout the hill country of Judea people were talking about all these things.
	<br /><br />
	66 Everyone who heard this wondered about it, asking, &ldquo;What then is this child going to be?&rdquo; For the Lord&rsquo;s hand was with him.
  <br /><br />
  <br /><br />
	Zechariah&rsquo;s Song
	<br /><br />
	67 His father Zechariah was filled with the Holy Spirit and prophesied:
	<br /><br />
	68 &ldquo;Praise be to the Lord, the God of Israel, because he has come to his people and redeemed them.
	<br /><br />
	69 He has raised up a horn of salvation for us in the house of his servant David
	<br /><br />
	70 (as he said through his holy prophets of long ago),
	<br /><br />
	71 salvation from our enemies and from the hand of all who hate us&mdash;
	<br /><br />
	72 to show mercy to our ancestors and to remember his holy covenant,
	<br /><br />
	73 the oath he swore to our father Abraham:
	<br /><br />
	74 to rescue us from the hand of our enemies, and to enable us to serve him without fear
	<br /><br />
	75 in holiness and righteousness before him all our days.
	<br /><br />
	76 And you, my child, will be called a prophet of the Most High; for you will go on before the Lord to prepare the way for him,
	<br /><br />
	77 to give his people the knowledge of salvation through the forgiveness of their sins,
	<br /><br />
	78 because of the tender mercy of our God, by which the rising sun will come to us from heaven
	<br /><br />
	79 to shine on those living in darkness and in the shadow of death, to guide our feet into the path of peace.&rdquo;
	<br /><br />
	80 And the child grew and became strong in spirit ; and he lived in the wilderness until he appeared publicly to Israel.
  <br /><br />


      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke1