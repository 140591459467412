import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 6 </title>
	<meta
          name="description"
          content="Zechariah 6 | சகரியா 6 | Sageria 6 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் திரும்பவும் என் கண்களை ஏறெடுத்து, இதோ, இரண்டு பர்வதங்களின் நடுவாகப் புறப்பட்டுவருகிற நாலு இரதங்களைக் கண்டேன்; அந்தப் பர்வதங்கள் வெண்கலப் பர்வதங்களாயிருந்தன.
	<br /><br />
	2. முதலாம் இரதத்தில் சிவப்புக் குதிரைகளும், இரண்டாம் இரதத்தில் கறுப்புக் குதிரைகளும்,
	<br /><br />
	3. மூன்றாம் இரதத்தில் வெள்ளைக் குதிரைகளும், நான்காம் இரதத்தில் புள்ளிபுள்ளியான சிவப்புக் குதிரைகளும் பூட்டியிருந்தன.
	<br /><br />
	4. நான் என்னோடே பேசின தூதனை நோக்கி: ஆண்டவனே, இவைகள் என்னவென்று கேட்டேன்.
	<br /><br />
	5. அந்தத் தூதன் எனக்குப் பிரதியுத்தரமாக: இவைகள் சர்வலோகத்துக்கும் ஆண்டவராயிருக்கிறவருடைய சமுகத்தில் நின்று புறப்படுகிற வானத்தினுடைய நாலு ஆவிகள் என்றார்.
	<br /><br />
	6. ஒன்றில் பூட்டப்பட்டிருந்த கறுப்புக்குதிரைகள் வடதேசத்துக்குப் புறப்பட்டுப்போயின; வெண்மையான குதிரைகள் அவைகளின் பின்னாலே புறப்பட்டுப்போயின; புள்ளிபுள்ளியான குதிரைகள் தென்தேசத்துக்குப் புறப்பட்டுப்போயின.
	<br /><br />
	7. சிவப்புக் குதிரைகளோவென்றால் புறப்பட்டுப்போய், பூமியிலே சுற்றித்திரியும்படி கேட்டுக்கொண்டன; அதற்கு அவர்: போய்ப் பூமியில் சுற்றித்திரியுங்கள் என்றார்; அப்படியே பூமியிலே சுற்றித்திரிந்தன.
	<br /><br />
	8. பின்பு அவர் என்னைக் கூப்பிட்டு: பார், வடதேசத்துக்குப் புறப்பட்டுப்போனவைகள், வடதேசத்திலே என் கோபத்தைச் சாந்திபண்ணிற்று என்று என்னோடே சொன்னார்.
	<br /><br />
	9. பின்பு கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	10. சிறையிருப்பின் மனுஷராகிய எல்தாயும், தொபியாவும், யெதாயாவும் பாபிலோனிலிருந்து வந்திருக்கும் அந்நாளிலே நீ போய், செப்பனியாவின் குமாரனாகிய யோசியாவின் வீட்டுக்குள் பிரவேசித்து,
	<br /><br />
	11. அங்கே அவர்கள் கையிலே வெள்ளியையும் பொன்னையும் வாங்கி, கிரீடங்களைச் செய்வித்து, யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனுடைய சிரசிலே வைத்து,
	<br /><br />
	12. அவனோடே சொல்லவேண்டியது: சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால், இதோ, ஒரு புருஷன், அவருடைய நாமம் கிளை என்னப்படும்; அவர் தம்முடைய ஸ்தானத்திலிருந்து முளைத்தெழும்பிக் கர்த்தருடைய ஆலயத்தைக் கட்டுவார்.
	<br /><br />
	13. அவரே கர்த்தருடைய ஆலயத்தைக் கட்டுவார்; அவர் மகிமைபொருந்தினவராய், தம்முடைய சிங்காசனத்தின்மேல் வீற்றிருந்து ஆளுகை செய்வார்; தம்முடைய சிங்காசனத்தின்மேல் ஆசாரியராயும் இருப்பார்; இவ்விரண்டின் நடுவாகச் சமாதானத்தின் ஆலோசனை விளங்கும்.
	<br /><br />
	14. இந்தக் கிரீடங்களோவென்றால், கர்த்தருடைய ஆலயத்திலே, ஏலேமுக்கும், தொபியாவுக்கும், யெதாயாவுக்கும், செப்பனியாவின் குமாரனாகிய ஏனுக்கும் நினைப்பூட்டுதலுக்கென்று வைக்கப்படுவதாக.
	<br /><br />
	15. தூரத்திலுள்ளவர்கள் வந்து கர்த்தருடைய ஆலயத்தைக் கூட இருந்து கட்டுவார்கள்; அப்பொழுது சேனைகளின் கர்த்தர் என்னை உங்களிடத்திற்கு அனுப்பினாரென்று அறிந்துகொள்வீர்கள்; நீங்கள் உங்கள் தேவனாகிய கர்த்தரின் சத்தத்தைக் கேட்டு நடந்தீர்களானால் இது நிறைவேறும் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah6