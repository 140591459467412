import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 12 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 12 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 12 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மேன்மைபாராட்டுகிறது எனக்குத் தகுதியல்லவே; ஆகிலும், கர்த்தர் அருளிய தரிசனங்களையும் வெளிப்படுத்தல்களையும் சொல்லுகிறேன்.
	<br /><br />
	2. கிறிஸ்துவுக்குள்ளான ஒரு மனுஷனை அறிவேன்; அவன் பதினாலு வருஷத்திற்கு முன்னே மூன்றாம் வானம்வரைக்கும் எடுக்கப்பட்டான்; அவன் சரீரத்திலிருந்தானோ, சரீரத்திற்குப் புறம்பேயிருந்தானோ, அதை அறியேன்; தேவன் அறிவார்.
	<br /><br />
	3. அந்த மனுஷன் பரதீசுக்குள் எடுக்கப்பட்டு, மனுஷர் பேசப்படாததும் வாக்குக்கெட்டாததுமாகிய வார்த்தைகளைக் கேட்டானென்று அறிந்திருக்கிறேன்.
	<br /><br />
	4. அவன் சரீரத்திலிருந்தானோ, சரீரத்திற்குப் புறம்பேயிருந்தானோ, அதை அறியேன்; தேவன் அறிவார்.
	<br /><br />
	5. இப்படிப்பட்டவனைக்குறித்து மேன்மைபாராட்டுவேன்; ஆனாலும் என்னைக்குறித்து என் பலவீனங்களிலேயன்றி, வேறொன்றிலும் மேன்மைபாராட்டமாட்டேன்.
	<br /><br />
	6. சத்தியமானதை நான் பேசுகிறேன்; நான் மேன்மைபாராட்ட மனதாயிருந்தாலும், நான் புத்தியீனனல்ல, ஆனாலும் ஒருவனும் என்னிடத்தில் காண்கிறதற்கும், என்னாலே கேட்கிறதற்கும் மேலாக என்னை எண்ணாதபடிக்கு அப்படிச் செய்யாதிருப்பேன்.
	<br /><br />
	7. அன்றியும், எனக்கு வெளிப்படுத்தப்பட்டவைகளுக்குரிய மேன்மையினிமித்தம் நான் என்னை உயர்த்தாதபடிக்கு, என் மாம்சத்திலே ஒரு முள் கொடுக்கப்பட்டிருக்கிறது; என்னை நான் உயர்த்தாதபடிக்கு, அது என்னைக் குட்டும் சாத்தானுடைய தூதனாயிருக்கிறது.
	<br /><br />
	8. அது என்னைவிட்டு நீங்கும்படிக்கு, நான் மூன்றுதரம் கர்த்தரிடத்தில் வேண்டிக்கொண்டேன்.
	<br /><br />
	9. அதற்கு அவர்: என் கிருபை உனக்குப்போதும்; பலவீனத்திலே என் பலம் பூரணமாய் விளங்கும் என்றார். ஆகையால், கிறிஸ்துவின் வல்லமை என்மேல் தங்கும்படி, என் பலவீனங்களைக்குறித்து நான் மிகவும் சந்தோஷமாய் மேன்மைபாராட்டுவேன்.
	<br /><br />
	10. அந்தப்படி நான் பலவீனமாயிருக்கும்போதே பலமுள்ளவனாயிருக்கிறேன்; ஆகையால் கிறிஸ்துவினிமித்தம் எனக்கு வரும் பலவீனங்களிலும், நிந்தைகளிலும், நெருக்கங்களிலும், துன்பங்களிலும், இடுக்கண்களிலும் நான் பிரியப்படுகிறேன்.
	<br /><br />
	11. மேன்மைபாராட்டி, புத்தியீனனாயினேன்; நீங்களே இதற்கு என்னைப் பலவந்தப்படுத்தினீர்கள். நான் ஒன்றுமில்லையென்றாலும், மகா பிரதான அப்போஸ்தலருக்கும் நான் எவ்வளவும் குறைந்தவனாயிராதபடியால், உங்களாலே மெச்சிக்கொள்ளப்படவேண்டியதாயிருந்ததே.
	<br /><br />
	12. அப்போஸ்தலனுக்குரிய அடையாளங்கள் எல்லாவிதமான பொறுமையோடும், அதிசயங்களோடும், அற்புதங்களோடும், வல்லமைகளோடும், உங்களுக்குள்ளே நடப்பிக்கப்பட்டதே.
	<br /><br />
	13. எதிலே மற்றச் சபைகளுக்குக் குறைவாயிருந்தீர்கள்? நான் உங்களை வருத்தப்படுத்தாதிருந்ததே உங்களுக்குக் குறைவு; இந்த அநியாயத்தை எனக்கு மன்னியுங்கள்.
	<br /><br />
	14. இதோ, உங்களிடத்திற்கு மூன்றாந்தரம் வர ஆயத்தமாயிருக்கிறேன்; நான் உங்களை வருத்தப்படுத்துவதில்லை; நான் உங்களுடையதையல்ல, உங்களையே தேடுகிறேன்; பெற்றாருக்குப் பிள்ளைகளல்ல, பிள்ளைகளுக்குப் பெற்றார்களே பொக்கிஷங்களைச் சேர்த்துவைக்கவேண்டும்.
	<br /><br />
	15. ஆதலால், நான் உங்களில் எவ்வளவு அதிகமாய் அன்புகூருகிறேனோ அவ்வளவு குறைவாய் உங்களால் அன்புகூரப்பட்டிருந்தாலும், மிகவும் சந்தோஷமாய் நான் உங்கள் ஆத்துமாக்களுக்காகச் செலவுபண்ணவும் செலவுபண்ணப்படவும் விரும்புகிறேன்.
	<br /><br />
	16. அப்படியாகட்டும்; நான் உங்களுக்குப் பாரமாயிருக்கவில்லை; ஆனாலும், உபாயமுள்ளவனாயிருந்து, தந்திரத்தினாலே உங்களைப் பிடித்தேனாம்.
	<br /><br />
	17. நான் உங்களிடத்திற்கு அனுப்பினவர்களில் எவன்மூலமாயாவது உங்களிடத்தில் பொழிவைத் தேடினதுண்டா?
	<br /><br />
	18. தீத்து உங்களிடத்தில் வரும்படி நான் அவனைக் கேட்டுக்கொண்டு, அவனுடனேகூட ஒரு சகோதரனை அனுப்பினேன்; தீத்து உங்களிடத்தில் ஏதாவது பொழிவைத் தேடினானா? நாங்கள் ஒரே ஆவியையுடையவர்களாய், ஒரே அடிச்சுவடுகளில் நடந்தோமல்லவா?
	<br /><br />
	19. நாங்கள் யோக்கியர்களென்று விளங்கும்படி உங்களிடத்தில் பேசுகிறோமென்று எண்ணுகிறீர்களோ? தேவனுக்குமுன்பாகக் கிறிஸ்துவுக்குள் பேசுகிறோம். பிரியமானவர்களே, சகலத்தையும் உங்கள் பக்திவிருத்திக்காகச் செய்கிறோம்.
	<br /><br />
	20. ஆகிலும் நான் வந்து, உங்களை என் மனதின்படியிருக்கிறவர்களாகக் காணாமலும், நானும் உங்கள் மனதின்படியிருக்கிறவனாகக் காணப்படாமலுமிருப்பேனோவென்றும்; விரோதங்கள், வைராக்கியங்கள், கோபங்கள், வாக்குவாதங்கள், புறங்கூறுதல், கோட்சொல்லுதல், இறுமாப்பு, கலகங்கள் ஆகிய இவைகள் உங்களுக்குள்ளே உண்டாயிருக்குமோவென்றும்;
	<br /><br />
	21. மறுபடியும் நான் வருகிறபோது, என் தேவன் உங்களிடத்தில் என்னைத் தாழ்த்தும்படிக்கு முன் பாவஞ்செய்தவர்களாகிய அநேகர் தாங்கள் நடப்பித்த அசுத்தத்தையும் வேசித்தனத்தையும் காமவிகாரத்தையும் விட்டு மனந்திரும்பாமலிருக்கிறதைக்குறித்து, நான் துக்கப்படவேண்டியதாயிருக்குமோவென்றும் பயந்திருக்கிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians12