import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 4 </title>
	<meta
          name="description"
          content="Ezekiel 4 | எசேக்கியேல்  4 |
          Ezekiel 4 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மனுபுத்திரனே, நீ ஒரு செங்கலை எடுத்து, அதை உன்முன் வைத்து, அதின்மேல் எருசலேம் நகரத்தை வரைந்து,
	<br /><br />
	2. அதற்கு விரோதமாக முற்றிக்கைபோட்டு, அதற்கு விரோதமாகக் கொத்தளங்களைக் கட்டி, அதற்கு விரோதமாக மண்மேடுபோட்டு, அதற்கு விரோதமாக இராணுவங்களை நிறுத்தி, சுற்றிலும் அதற்கு விரோதமாக மதிலிடிக்கும் யந்திரங்களை வை.
	<br /><br />
	3. மேலும் நீ ஒரு இருப்புச்சட்டியை வாங்கி, அதை உனக்கும் நகரத்துக்கும் நடுவாக இருப்புச்சுவராக்கி, அது முற்றிக்கையாய்க் கிடக்கும்படிக்கு உன் முகத்தை அதற்கு நேராய்த் திருப்பி, அதை முற்றிக்கைபோட்டுக்கொண்டிரு; இது இஸ்ரவேல் வம்சத்துக்கு அடையாளம்.
	<br /><br />
	4. நீ உன் இடதுபக்கமாய் ஒருக்களித்துப் படுத்து, அதின்மேல் இஸ்ரவேல் வம்சத்தாரின் அக்கிரமத்தைச் சுமத்திக்கொள்; நீ அந்தப்பக்கமாய் ஒருக்களித்திருக்கும் நாட்களின் இலக்கத்தின்படியே அவர்களுடைய அக்கிரமத்தைச் சுமப்பாய்.
	<br /><br />
	5. அவர்களுடைய அக்கிரமத்தின் வருஷங்களை உனக்கு நாட்கணக்காய் எண்ணக் கட்டளையிட்டேன்; முந்நூற்றுத் தொண்ணூறு நாட்கள்வரைக்கும் நீ இஸ்ரவேல் வம்சத்தாரின் அக்கிரமத்தைச் சுமக்கவேண்டும்.
	<br /><br />
	6. நீ இவைகளை நிறைவேற்றினபின்பு, மறுபடியும் உன் வலதுபக்கமாய் ஒருக்களித்து, யூதா வம்சத்தாரின் அக்கிரமத்தை நாற்பதுநாள்வரைக்கும் சுமக்கவேண்டும்; ஒவ்வொரு வருஷத்துக்குப் பதிலாக ஒவ்வொரு நாளை உனக்குக் கட்டளையிட்டேன்.
	<br /><br />
	7. நீ எருசலேமின் முற்றிக்கைக்கு நேராகத் திருப்பிய முகமும், திறந்த புயமுமாக இருந்து, அதற்கு விரோதமாகத் தீர்க்கதரிசனம் சொல்லு.
	<br /><br />
	8. இதோ, நீ அதை முற்றிக்கைப்போடும் நாட்களை நிறைவேற்றுமட்டும் ஒரு பக்கத்திலிருந்து மறுபக்கத்தில் புரளக்கூடாதபடிக்கு உன்னைக் கயிறுகளால் கட்டுவேன்.
	<br /><br />
	9. நீ கோதுமையையும் வாற்கோதுமையையும் பெரும்பயற்றையும் சிறுபயற்றையும் தினையையும் கம்பையும் வாங்கி, அவைகளை ஒரு பாத்திரத்திலே போட்டு, அவைகளால் உனக்கு அப்பஞ்சுடுவாய்; நீ ஒருக்களித்துப் படுக்கும் நாட்களுடைய இலக்கத்தின்படியே முந்நூற்றுத் தொண்ணூறுநாள் அதில் எடுத்துச் சாப்பிடுவாயாக.
	<br /><br />
	10. நீ சாப்பிடும் போஜனம், நாள் ஒன்றுக்கு இருபது சேக்கல் நிறையாயிருக்கும்; அப்படி நாளுக்குநாள் சாப்பிடுவாயாக.
	<br /><br />
	11. தண்ணீரையும் அளவாய் ஹின் என்னும் படியில் ஆறிலொரு பங்கைக் குடிப்பாய்; அப்படி நாளுக்குநாள் குடிப்பாயாக.
	<br /><br />
	12. அதை வாற்கோதுமை அடையைப்போல் சாப்பிடுவாயாக; அது மனுஷனிலிருந்து கழிந்த கஷ்டத்தின் வறட்டிகளால் அவர்கள் கண்களுக்கு முன்பாகச் சுடப்படுவதாக.
	<br /><br />
	13. அதற்கு ஒத்தபடியே இஸ்ரவேல் புத்திரர், நான் அவர்களைத் துரத்துகிற புறஜாதிகளுக்குள்ளே தங்கள் அப்பத்தைத் தீட்டுள்ளதாகச் சாப்பிடுவார்கள் என்று கர்த்தர் சொன்னார்.
	<br /><br />
	14. அப்பொழுது நான்: ஆ, கர்த்தராகிய ஆண்டவரே, இதோ, என் ஆத்துமா தீட்டுப்படவில்லை; தானாய்ச் செத்ததையாவது, பீறுண்டதையாவது நான் என் சிறுவயதுமுதல் இதுவரைக்கும் சாப்பிட்டதில்லை; அருவருப்பான இறைச்சி என் வாய்க்குட்பட்டதுமில்லை என்றேன்.
	<br /><br />
	15. அப்பொழுது அவர் என்னை நோக்கி: பார், மனுஷ கஷ்டத்தின் வறட்டிக்குப் பதிலாக உனக்கு மாட்டுச்சாணி வறட்டியைக் கட்டளையிடுகிறேன்; அதினால் உன் அப்பத்தைச் சுடுவாயாக என்றார்.
	<br /><br />
	16. பின்னும் அவர்: மனுபுத்திரனே, இதோ, அப்பமும் தண்ணீரும் அவர்களுக்குக் குறையவும், அவனவன் திடுக்கிடவும், அவர்கள் தங்கள் அக்கிரமத்திலே வாடிப்போகவும்,
	<br /><br />
	17. நான் எருசலேமிலே அப்பம் என்னும் ஆதரவுகோலை முறிக்கிறேன்; அவர்கள் அப்பத்தை நிறையின்படியே விசாரத்தோடே சாப்பிட்டு, தண்ணீரை அளவின்படியே திடுக்கிடுதலோடே குடிப்பார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial4