import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 2 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 2
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆகையால், மற்றவர்களைக் குற்றவாளியாகத் தீர்க்கிறவனே, நீ யாரானாலும் சரி, போக்குச்சொல்ல உனக்கு இடமில்லை; நீ குற்றமாகத் தீர்க்கிறவைகள் எவைகளோ, அவைகளை நீயே செய்கிறபடியால், நீ மற்றவர்களைக்குறித்துச் சொல்லுகிற தீர்ப்பினாலே உன்னைத்தானே குற்றவாளியாகத் தீர்க்கிறாய்.
	<br /><br />
	2. இப்படிப்பட்டவைகளைச் செய்கிறவர்களுக்குத் தேவனுடைய நியாயத்தீர்ப்பு சத்தியத்தின்படியே இருக்கிறதென்று அறிந்திருக்கிறோம்.
	<br /><br />
	3. இப்படிப்பட்டவைகளைச் செய்கிறவர்களைக் குற்றவாளிகளென்று தீர்த்தும், அவைகளையே செய்கிறவனே, நீ தேவனுடைய நியாயத்தீர்ப்புக்குத் தப்பிக்கொள்ளலாமென்று நினைக்கிறாயோ?
	<br /><br />
	4. அல்லது தேவதயவு நீ குணப்படும்படி உன்னை ஏவுகிறதென்று அறியாமல், அவருடைய தயவு பொறுமை நீடியசாந்தம் இவைகளின் ஐசுவரியத்தை அசட்டைபண்ணுகிறாயோ?
	<br /><br />
	5. உன் மனக்கடினத்திற்கும் குணப்படாத இருதயத்திற்கும் ஏற்றபடி, தேவனுடைய நீதியுள்ள தீர்ப்பு வெளிப்படும் கோபாக்கினைநாளிலே உனக்காகக் கோபாக்கினையைக் குவித்துக்கொள்ளுகிறாயே.
	<br /><br />
	6. தேவன் அவனவனுடைய கிரியைகளுக்குத்தக்கதாய் அவனவனுக்குப் பலனளிப்பார்.
	<br /><br />
	7. சோர்ந்துபோகாமல் நற்கிரியைகளைச் செய்து, மகிமையையும் கனத்தையும் அழியாமையையும் தேடுகிறவர்களுக்கு நித்தியஜீவனை அளிப்பார்.
	<br /><br />
	8. சண்டைக்காரராயிருந்து, சத்தியத்திற்குக் கீழ்ப்படியாமல், அநியாயத்திற்குக் கீழ்ப்படிந்திருக்கிறவர்களுக்கோ உக்கிரகோபாக்கினை வரும்.
	<br /><br />
	9. முன்பு யூதரிலும் பின்பு கிரேக்கரிலும் பொல்லாங்குசெய்கிற எந்த மனுஷ ஆத்துமாவுக்கும் உபத்திரவமும் வியாகுலமும் உண்டாகும்.
	<br /><br />
	10. முன்பு யூதரிலும் பின்பு கிரேக்கரிலும் எவன் நன்மைசெய்கிறானோ அவனுக்கு மகிமையும் கனமும் சமாதானமும் உண்டாகும்.
	<br /><br />
	11. தேவனிடத்தில் பட்சபாதமில்லை.
	<br /><br />
	12. எவர்கள் நியாயப்பிரமாணமில்லாமல் பாவஞ்செய்கிறார்களோ, அவர்கள் நியாயப்பிரமாணமில்லாமல் கெட்டுப்போவார்கள்; எவர்கள் நியாயப்பிரமாணத்துக்குட்பட்டவர்களாய்ப் பாவஞ்செய்கிறார்களோ, அவர்கள் நியாயப்பிரமாணத்தினாலே ஆக்கினைத்தீர்ப்படைவார்கள்.
	<br /><br />
	13. நியாயப்பிரமாணத்தைக் கேட்கிறவர்கள் தேவனுக்கு முன்பாக நீதிமான்களல்ல, நியாயப்பிரமாணத்தின்படி செய்கிறவர்களே நீதிமான்களாக்கப்படுவார்கள்.
	<br /><br />
	14. அன்றியும் நியாயப்பிரமாணமில்லாத புறஜாதிகள் சுபாவமாய் நியாயப்பிரமாணத்தின்படி செய்கிறபோது, நியாயப்பிரமாணமில்லாத அவர்கள் தங்களுக்குத் தாங்களே நியாயப்பிரமாணமாயிருக்கிறார்கள்.
	<br /><br />
	15. அவர்களுடைய மனச்சாட்சியும் கூடச் சாட்சியிடுகிறதினாலும், குற்றமுண்டு குற்றமில்லையென்று அவர்களுடைய சிந்தனைகள் ஒன்றையொன்று தீர்க்கிறதினாலும், நியாயப்பிரமாணத்திற்கேற்ற கிரியை தங்கள் இருதயங்களில் எழுதியிருக்கிறதென்று காண்பிக்கிறார்கள்.
	<br /><br />
	16. என் சுவிசேஷத்தின்படியே, தேவன் இயேசுகிறிஸ்துவைக்கொண்டு மனுஷருடைய அந்தரங்கங்களைக்குறித்து நியாயத்தீர்ப்புக்கொடுக்கும் நாளிலே இது விளங்கும்.
	<br /><br />
	17. நீ யூதனென்று பெயர்பெற்று, நியாயப்பிரமாணத்தின்மேல் பற்றுதலாயிருந்து, தேவனைக்குறித்து மேன்மைபாராட்டி,
	<br /><br />
	18. நியாயப்பிரமாணத்தினால் உபதேசிக்கப்பட்டவனாய், அவருடைய சித்தத்தை அறிந்து, நன்மை தீமை இன்னதென்று வகையறுக்கிறாயே.
	<br /><br />
	19. நீ உன்னைக் குருடருக்கு வழிகாட்டியாகவும், அந்தகாரத்திலுள்ளவர்களுக்கு வெளிச்சமாகவும்,
	<br /><br />
	20. பேதைகளுக்குப் போதகனாகவும், குழந்தைகளுக்கு உபாத்தியாயனாகவும், நியாயப்பிரமாணத்தின் அறிவையும் சத்தியத்தையும் காட்டிய சட்டமுடையவனாகவும் எண்ணுகிறாயே.
	<br /><br />
	21. இப்படியிருக்க, மற்றவனுக்குப் போதிக்கிற நீ உனக்குத்தானே போதியாமலிருக்கலாமா? களவுசெய்யக்கூடாதென்று பிரசங்கிக்கிற நீ களவுசெய்யலாமா?
	<br /><br />
	22. விபசாரம் செய்யக்கூடாதென்று சொல்லுகிற நீ விபசாரம் செய்யலாமா? விக்கிரகங்களை அருவருக்கிற நீ கோவில்களைக் கொள்ளையிடலாமா?
	<br /><br />
	23. நியாயப்பிரமாணத்தைக்குறித்து மேன்மைபாராட்டுகிற நீ நியாயப்பிரமாணத்தை மீறிநடந்து, தேவனைக் கனவீனம்பண்ணலாமா?
	<br /><br />
	24. எழுதியிருக்கிறபடி, தேவனுடைய நாமம் புறஜாதிகளுக்குள்ளே உங்கள் மூலமாய்த் தூஷிக்கப்படுகிறதே.
	<br /><br />
	25. நீ நியாயப்பிரமாணத்தைக் கைக்கொண்டு நடந்தால் விருத்தசேதனம் பிரயோஜனமுள்ளதுதான்; நீ நியாயப்பிரமாணத்தை மீறி நடந்தால் உன் விருத்தசேதனம் விருத்தசேதனமில்லாமையாயிற்றே.
	<br /><br />
	26. மேலும் விருத்தசேதனமில்லாதவன் நியாயப்பிரமாணத்துக்கேற்ற நீதிகளைக் கைக்கொண்டால், அவனுடைய விருத்தசேதனமில்லாமை விருத்தசேதனம் என்றெண்ணப்படுமல்லவா?
	<br /><br />
	27. சுபாவத்தின்படி விருத்தசேதனமில்லாதவனாயிருந்தும் நியாயப்பிரமாணத்தை நிறைவேற்றுகிறவனாயிருந்தால், அவன் வேத எழுத்தும் விருத்தசேதனமும் உள்ளவனாயிருந்தும், நியாயப்பிரமாணத்தை மீறுகிற உன்னைக் குற்றப்படுத்துவானல்லவா?
	<br /><br />
	28. ஆதலால் புறம்பாக யூதனானவன் யூதனல்ல, புறம்பாக மாம்சத்தில் செய்யப்படும் விருத்தசேதனமும் விருத்தசேதனமல்ல.
	<br /><br />
	29. உள்ளத்திலே யூதனானவனே யூதன்; எழுத்தின்படி உண்டாகாமல், ஆவியின்படி இருதயத்தில் உண்டாகும் விருத்தசேதனமே விருத்தசேதனம்; இப்படிப்பட்டவனுக்குரிய புகழ்ச்சி மனுஷராலே அல்ல, தேவனாலே உண்டாயிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans2