import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 6 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பஸ்காபண்டிகையின் இரண்டாம் நாளைக்குப் பின்வந்த முதலாம் ஓய்வுநாளிலே, அவர் பயிர்வழியே நடந்துபோகையில், அவருடைய சீஷர்கள் கதிர்களைக் கொய்து, கைகளினால் நிமிட்டித் தின்றார்கள்.
	<br /><br />
	2. பரிசேயரில் சிலர் அவர்களை நோக்கி: ஓய்வுநாளில் செய்யத்தகாததை நீங்கள் ஏன் செய்கிறீர்கள் என்று கேட்டார்கள்.
	<br /><br />
	3. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: தாவீதும் அவனோடிருந்தவர்களும் பசியாயிருந்தபோது செய்ததை நீங்கள் வாசிக்கவில்லையா? அவன் தேவனுடைய வீட்டில் பிரவேசித்து, ஆசாரியர் மாத்திரமே தவிர வேறொருவரும் புசிக்கத்தகாத தேவசமுகத்து அப்பங்களைக் கேட்டு வாங்கி,
	<br /><br />
	4. தான் புசித்ததுமன்றி, தன்னுடனே கூட இருந்தவர்களுக்கும் கொடுத்தானே என்று சொன்னார்.
	<br /><br />
	5. மேலும் மனுஷகுமாரன் ஓய்வுநாளுக்கும் ஆண்டவராய் இருக்கிறார் என்றார்.
	<br /><br />
	6. வேறொரு ஓய்வுநாளிலே, அவர் ஜெபஆலயத்தில் பிரவேசித்து உபதேசித்தார். அங்கே சூம்பின வலதுகையையுடைய ஒரு மனுஷன் இருந்தான்.
	<br /><br />
	7. அப்பொழுது வேதபாரகரும் பரிசேயரும் அவரிடத்தில் குற்றம் பிடிக்கும்படி, ஓய்வுநாளில் சொஸ்தமாக்குவாரோ என்று அவர்மேல் நோக்கமாயிருந்தார்கள்.
	<br /><br />
	8. அவர்களுடைய சிந்தனைகளை அவர் அறிந்து, சூம்பின கையையுடைய மனுஷனை நோக்கி: நீ எழுந்து, நடுவே நில் என்றார். அவன் எழுந்து நின்றான்.
	<br /><br />
	9. அப்பொழுது இயேசு அவர்களை நோக்கி: நான் உங்களிடத்தில் ஒன்று கேட்கிறேன்; ஓய்வுநாட்களில் நன்மை செய்வதோ, தீமை செய்வதோ, ஜீவனைக் காப்பதோ, அழிப்பதோ, எது நியாயம் என்று கேட்டு,
	<br /><br />
	10. அவர்களெல்லாரையும் சுற்றிப் பார்த்து, அந்த மனுஷனை நோக்கி: உன் கையை நீட்டு என்றார். அப்படியே அவன் தன் கையை நீட்டினான், உடனே அவன் கை மறுகையைப்போலச் சொஸ்தமாயிற்று.
	<br /><br />
	11. அவர்களோ மூர்க்கவெறிகொண்டு, இயேசுவை என்ன செய்யலாமென்று ஒருவரோடொருவர் ஆலோசித்தார்கள்.
	<br /><br />
	12. அந்நாட்களிலே, அவர் ஜெபம்பண்ணும்படி ஒரு மலையின்மேல் ஏறி, இராமுழுவதும் தேவனை நோக்கி ஜெபம்பண்ணிக்கொண்டிருந்தார்.
	<br /><br />
	13. பொழுது விடிந்தபோது, அவர் தம்முடைய சீஷர்களை வரவழைத்து, அவர்களில் பன்னிரண்டுபேரைத் தெரிந்துகொண்டு, அவர்களுக்கு அப்போஸ்தலர் என்று பேரிட்டார்.
	<br /><br />
	14. அவர்கள் யாரெனில், பேதுரு என்று தாம் பேரிட்ட சீமோன், அவன் சகோதரனாகிய அந்திரேயா, யாக்கோபு, யோவான், பிலிப்பு, பற்தொலொமேயு,
	<br /><br />
	15. மத்தேயு, தோமா, அல்பேயுவின் குமாரனாகிய யாக்கோபு, செலோத்தே என்னப்பட்ட சீமோன்,
	<br /><br />
	16. யாக்கோபின் சகோதரனாகிய யூதா, துரோகியான யூதாஸ்காரியோத்து என்பவர்களே.
	<br /><br />
	17. பின்பு அவர் அவர்களுடனேகூட இறங்கி, சமனான ஒரு இடத்திலே நின்றார். அங்கே அவருடைய சீஷரில் அநேகம்பேரும் அவருடைய உபதேசத்தைக் கேட்கும்படிக்கும், தங்கள் வியாதிகளினின்று குணமாக்கப்படும்படிக்கும், யூதேயாதேசத்துத் திசைகள் யாவற்றிலிருந்தும், எருசலேம் நகரத்திலிருந்தும், தீரு சீதோன் பட்டணங்கள் இருக்கிற கடலோரத்திலிருந்தும் வந்தவர்களாகிய திரளான ஜனங்களும் இருந்தார்கள்.
	<br /><br />
	18. அசுத்த ஆவிகளால் வாதிக்கப்பட்டவர்களும் வந்து, ஆரோக்கியமடைந்தார்கள்.
	<br /><br />
	19. அவரிடத்திலிருந்து வல்லமை புறப்பட்டு எல்லாரையும் குணமாக்கினபடியினாலே, ஜனங்கள் யாவரும் அவரைத் தொடும்படிக்கு வகைதேடினார்கள்.
	<br /><br />
	20. அப்பொழுது அவர் தம்முடைய சீஷர்களை நோக்கிப்பார்த்து: தரித்திரராகிய நீங்கள் பாக்கியவான்கள்; தேவனுடைய ராஜ்யம் உங்களுடையது.
	<br /><br />
	21. இப்பொழுது பசியாயிருக்கிற நீங்கள் பாக்கியவான்கள்; திருப்தியடைவீர்கள். இப்பொழுது அழுகிற நீங்கள் பாக்கியவான்கள்; இனி நகைப்பீர்கள்.
	<br /><br />
	22. மனுஷகுமாரன் நிமித்தமாக ஜனங்கள் உங்களைப் பகைத்து, உங்களைப் புறம்பாக்கி, உங்களை நிந்தித்து, உங்கள் நாமத்தைப் பொல்லாததென்று தள்ளிவிடும்போது நீங்கள் பாக்கியவான்களாயிருப்பீர்கள்.
	<br /><br />
	23. அந்நாளிலே நீங்கள் சந்தோஷப்பட்டுக் களிகூருங்கள்; பரலோகத்தில் உங்கள் பலன் மிகுதியாயிருக்கும்; அவர்களுடைய பிதாக்கள் தீர்க்கதரிசிகளுக்கும் அப்படியே செய்தார்கள்.
	<br /><br />
	24. ஐசுவரியவான்களாகிய உங்களுக்கு ஐயோ; உங்கள் ஆறுதலை நீங்கள் அடைந்து தீர்ந்தது.
	<br /><br />
	25. திருப்தியுள்ளவர்களாயிருக்கிற உங்களுக்கு ஐயோ; பசியாயிருப்பீர்கள். இப்பொழுது நகைக்கிற உங்களுக்கு ஐயோ; இனி துக்கப்பட்டு அழுவீர்கள்.
	<br /><br />
	26. எல்லா மனுஷரும் உங்களைக் குறித்துப் புகழ்ச்சியாய்ப் பேசும்போது உங்களுக்கு ஐயோ; அவர்கள் பிதாக்கள் கள்ளத்தீர்க்கதரிசிகளுக்கும் அப்படியே செய்தார்கள்.
	<br /><br />
	27. எனக்குச் செவிகொடுக்கிற உங்களுக்கு நான் சொல்லுகிறேன்: உங்கள் சத்துருக்களைச் சிநேகியுங்கள்; உங்களைப் பகைக்கிறவர்களுக்கு நன்மைசெய்யுங்கள்.
	<br /><br />
	28. உங்களைச் சபிக்கிறவர்களை ஆசீர்வதியுங்கள்; உங்களை நிந்திக்கிறவர்களுக்காக ஜெபம் பண்ணுங்கள்.
	<br /><br />
	29. உன்னை ஒரு கன்னத்தில் அறைகிறவனுக்கு மறு கன்னத்தையும் கொடு; உன் அங்கியை எடுத்துக்கொள்ளுகிறவனுக்கு உன் வஸ்திரத்தையும் எடுத்துக்கொள்ளத் தடைபண்ணாதே.
	<br /><br />
	30. உன்னிடத்தில் கேட்கிற எவனுக்கும் கொடு; உன்னுடையதை எடுத்துக் கொள்ளுகிறவனிடத்தில் அதைத் திரும்பக் கேளாதே.
	<br /><br />
	31. மனுஷர் உங்களுக்கு எப்படிச் செய்ய வேண்டுமென்று விரும்புகிறீர்களோ, அப்படியே நீங்களும் அவர்களுக்குச் செய்யுங்கள்.
	<br /><br />
	32. உங்களைச் சிநேகிக்கிறவர்களையே நீங்கள் சிநேகித்தால், உங்களுக்குப் பலன் என்ன? பாவிகளும் தங்களைச் சிநேகிக்கிறவர்களைச் சிநேகிக்கிறார்களே.
	<br /><br />
	33. உங்களுக்கு நன்மைசெய்கிறவர்களுக்கே நீங்கள் நன்மைசெய்தால், உங்களுக்குப் பலன் என்ன? பாவிகளும் அப்படிச் செய்கிறார்களே.
	<br /><br />
	34. திரும்பக் கொடுப்பார்களென்று நம்பி நீங்கள் கடன்கொடுத்தால் உங்களுக்குப் பலன் என்ன? திரும்பத் தங்களுக்குக் கொடுக்கப்படும்படியாகப் பாவிகளும் பாவிகளுக்குக் கடன்கொடுக்கிறார்களே.
	<br /><br />
	35. உங்கள் சத்துருக்களைச் சிநேகியுங்கள், நன்மைசெய்யுங்கள், கைம்மாறு கருதாமல் கடன் கொடுங்கள்; அப்பொழுது உங்கள் பலன் மிகுதியாயிருக்கும், உன்னதமானவருக்கு நீங்கள் பிள்ளைகளாயிருப்பீர்கள்; அவர் நன்றியறியாதவர்களுக்கும் துரோகிகளுக்கும் நன்மை செய்கிறாரே.
	<br /><br />
	36. ஆகையால் உங்கள் பிதா இரக்கமுள்ளவராயிருக்கிறதுபோல, நீங்களும் இரக்கமுள்ளவர்களாயிருங்கள்.
	<br /><br />
	37. மற்றவர்களைக் குற்றவாளிகளென்று தீர்க்காதிருங்கள்; அப்பொழுது நீங்களும் குற்றவாளிகளென்று தீர்க்கப்படாதிருப்பீர்கள்; மற்றவர்களை ஆக்கினைக்குள்ளாகும்படி தீர்க்காதிருங்கள், அப்பொழுது நீங்களும் ஆக்கினைக்குள்ளாகத் தீர்க்கப்படாதிருப்பீர்கள்; விடுதலைபண்ணுங்கள், அப்பொழுது நீங்களும் விடுதலைபண்ணப்படுவீர்கள்.
	<br /><br />
	38. கொடுங்கள், அப்பொழுது உங்களுக்கும் கொடுக்கப்படும்; அமுக்கிக் குலுக்கிச் சரிந்து விழும்படி நன்றாய் அளந்து, உங்கள் மடியிலே போடுவார்கள்; நீங்கள் எந்த அளவினால் அளக்கிறீர்களோ அந்த அளவினால் உங்களுக்கும் அளக்கப்படும் என்றார்.
	<br /><br />
	39. பின்னும் அவர் ஒரு உவமையை அவர்களுக்குச் சொன்னார்: குருடனுக்குக் குருடன் வழிகாட்டக்கூடுமோ? இருவரும் பள்ளத்தில் விழுவார்கள் அல்லவா?
	<br /><br />
	40. சீஷன் தன் குருவுக்கு மேற்பட்டவனல்ல, தேறினவன் எவனும் தன் குருவைப்போலிருப்பான்.
	<br /><br />
	41. நீ உன் கண்ணிலிருக்கிற உத்திரத்தை உணராமல், உன் சகோதரன் கண்ணிலிருக்கிற துரும்பைப் பார்க்கிறதென்ன?
	<br /><br />
	42. அல்லது நீ உன் கண்ணிலிருக்கிற உத்திரத்தை உணராமல், உன் சகோதரனை நோக்கி: சகோதரனே, நான் உன் கண்ணிலிருக்கிற துரும்பை எடுத்துப்போடட்டும் என்று நீ சொல்வதெப்படி? மாயக்காரனே! முன்பு உன் கண்ணிலிருக்கிற உத்திரத்தை எடுத்துப்போடு, பின்பு உன் சகோதரன் கண்ணிலிருக்கிற துரும்பை எடுத்துப்போட வகைபார்ப்பாய்.
	<br /><br />
	43. நல்ல மரமானது கெட்ட கனி கொடாது, கெட்ட மரமானது நல்ல கனி கொடாது.
	<br /><br />
	44. அந்தந்த மரம் அதனதன் கனியினால் அறியப்படும்; முட்செடிகளில் அத்திப்பழங்களைப் பறிக்கிறதுமில்லை, நெருஞ்சிச்செடியில் திராட்சப்பழங்களைப் பறிக்கிறதுமில்லை.
	<br /><br />
	45. நல்ல மனுஷன் தன் இருதயமாகிய நல்ல பொக்கிஷத்திலிருந்து நல்லதை எடுத்துக் காட்டுகிறான்; பொல்லாத மனுஷன் தன் இருதயமாகிய பொல்லாத பொக்கிஷத்திலிருந்து பொல்லாததை எடுத்துக்காட்டுகிறான்; இருதயத்தின் நிறைவினால் அவனவன் வாய் பேசும்.
	<br /><br />
	46. என்னை ஆண்டவரே! ஆண்டவரே! என்று நீங்கள் சொல்லியும், நான் சொல்லுகிறபடி நீங்கள் செய்யாமற்போகிறதென்ன?
	<br /><br />
	47. என்னிடத்தில் வந்து, என் வார்த்தைகளைக் கேட்டு, அவைகளின்படி செய்கிறவன் யாருக்கு ஒப்பாயிருக்கிறானென்று உங்களுக்குக் காண்பிப்பேன்.
	<br /><br />
	48. ஆழமாய்த் தோண்டி, கற்பாறையின்மேல் அஸ்திபாரம் போட்டு, வீடுகட்டுகிற மனுஷனுக்கு ஒப்பாயிருக்கிறான்; பெருவெள்ளம் வந்து, நீரோட்டம் அந்த வீட்டின்மேல் மோதியும், அதை அசைக்கக் கூடாமற்போயிற்று; ஏனென்றால் அது கன்மலையின்மேல் அஸ்திபாரம் போடப்பட்டிருந்தது.
	<br /><br />
	49. என் வார்த்தைகளைக் கேட்டும் அவைகளின்படி செய்யாதவனோ அஸ்திபாரமில்லாமல் மண்ணின்மேல் வீடுகட்டினவனுக்கு ஒப்பாயிருக்கிறான்; நீரோட்டம் அதின்மேல் மோதினவுடனே அது விழுந்தது; விழுந்து முழுவதும் அழிந்தது என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	Jesus Is Lord of the Sabbath
</h2>
<p>
	<br />
	1 One Sabbath Jesus was going through the grainfields, and his disciples began to pick some heads of grain, rub them in their hands and eat the kernels.
	<br />
	2 Some of the Pharisees asked, &ldquo;Why are you doing what is unlawful on the Sabbath?&rdquo;
	<br />
	3 Jesus answered them, &ldquo;Have you never read what David did when he and his companions were hungry?
	<br />
	4 He entered the house of God, and taking the consecrated bread, he ate what is lawful only for priests to eat. And he also gave some to his companions.&rdquo;
	<br />
	5 Then Jesus said to them, &ldquo;The Son of Man is Lord of the Sabbath.&rdquo;
	<br />
	6 On another Sabbath he went into the synagogue and was teaching, and a man was there whose right hand was shriveled.
	<br />
	7 The Pharisees and the teachers of the law were looking for a reason to accuse Jesus, so they watched him closely to see if he would heal on the Sabbath.
	<br />
	8 But Jesus knew what they were thinking and said to the man with the shriveled hand, &ldquo;Get up and stand in front of everyone.&rdquo; So he got up and stood there.
	<br />
	9 Then Jesus said to them, &ldquo;I ask you, which is lawful on the Sabbath: to do good or to do evil, to save life or to destroy it?&rdquo;
	<br />
	10 He looked around at them all, and then said to the man, &ldquo;Stretch out your hand.&rdquo; He did so, and his hand was completely restored.
	<br />
	11 But the Pharisees and the teachers of the law were furious and began to discuss with one another what they might do to Jesus.
</p>
<h2>
	<br />
	The Twelve Apostles
</h2>
<p>
	<br />
	12 One of those days Jesus went out to a mountainside to pray, and spent the night praying to God.
	<br />
	13 When morning came, he called his disciples to him and chose twelve of them, whom he also designated apostles:
	<br />
	14 Simon (whom he named Peter), his brother Andrew, James, John, Philip, Bartholomew,
	<br />
	15 Matthew, Thomas, James son of Alphaeus, Simon who was called the Zealot,
	<br />
	16 Judas son of James, and Judas Iscariot, who became a traitor.
</p>
<h2>
	<br />
	Blessings and Woes
</h2>
<p>
	<br />
	17 He went down with them and stood on a level place. A large crowd of his disciples was there and a great number of people from all over Judea, from Jerusalem, and from the coastal region around Tyre and Sidon,
	<br />
	18 who had come to hear him and to be healed of their diseases. Those troubled by impure spirits were cured,
	<br />
	19 and the people all tried to touch him, because power was coming from him and healing them all.
	<br />
	20 Looking at his disciples, he said: &ldquo;Blessed are you who are poor, for yours is the kingdom of God.
	<br />
	21 Blessed are you who hunger now, for you will be satisfied. Blessed are you who weep now, for you will laugh.
	<br />
	22 Blessed are you when people hate you, when they exclude you and insult you and reject your name as evil, because of the Son of Man.
	<br />
	23 &ldquo;Rejoice in that day and leap for joy, because great is your reward in heaven. For that is how their ancestors treated the prophets.
	<br />
	24 &ldquo;But woe to you who are rich, for you have already received your comfort.
	<br />
	25 Woe to you who are well fed now, for you will go hungry. Woe to you who laugh now, for you will mourn and weep.
	<br />
	26 Woe to you when everyone speaks well of you, for that is how their ancestors treated the false prophets.
</p>
<h2>
	<br />
	Love for Enemies
</h2>
<p>
	<br />
	27 &ldquo;But to you who are listening I say: Love your enemies, do good to those who hate you,
	<br />
	28 bless those who curse you, pray for those who mistreat you.
	<br />
	29 If someone slaps you on one cheek, turn to them the other also. If someone takes your coat, do not withhold your shirt from them.
	<br />
	30 Give to everyone who asks you, and if anyone takes what belongs to you, do not demand it back.
	<br />
	31 Do to others as you would have them do to you.
	<br />
	32 &ldquo;If you love those who love you, what credit is that to you? Even sinners love those who love them.
	<br />
	33 And if you do good to those who are good to you, what credit is that to you? Even sinners do that.
	<br />
	34 And if you lend to those from whom you expect repayment, what credit is that to you? Even sinners lend to sinners, expecting to be repaid in full.
	<br />
	35 But love your enemies, do good to them, and lend to them without expecting to get anything back. Then your reward will be great, and you will be children of the Most High, because he is kind to the ungrateful and wicked.
	<br />
	36 Be merciful, just as your Father is merciful.
</p>
<h2>
	<br />
	Judging Others
</h2>
<p>
	<br />
	37 &ldquo;Do not judge, and you will not be judged. Do not condemn, and you will not be condemned. Forgive, and you will be forgiven.
	<br />
	38 Give, and it will be given to you. A good measure, pressed down, shaken together and running over, will be poured into your lap. For with the measure you use, it will be measured to you.&rdquo;
	<br />
	39 He also told them this parable: &ldquo;Can the blind lead the blind? Will they not both fall into a pit?
	<br />
	40 The student is not above the teacher, but everyone who is fully trained will be like their teacher.
	<br />
	41 &ldquo;Why do you look at the speck of sawdust in your brother&rsquo;s eye and pay no attention to the plank in your own eye?
	<br />
	42 How can you say to your brother, &lsquo;Brother, let me take the speck out of your eye,&rsquo; when you yourself fail to see the plank in your own eye? You hypocrite, first take the plank out of your eye, and then you will see clearly to remove the speck from your brother&rsquo;s eye.
</p>
<h2>
	<br />
	A Tree and Its Fruit
</h2>
<p>
	<br />
	43 &ldquo;No good tree bears bad fruit, nor does a bad tree bear good fruit.
	<br />
	44 Each tree is recognized by its own fruit. People do not pick figs from thornbushes, or grapes from briers.
	<br />
	45 A good man brings good things out of the good stored up in his heart, and an evil man brings evil things out of the evil stored up in his heart. For the mouth speaks what the heart is full of.
</p>
<h2>
	<br />
	The Wise and Foolish Builders
</h2>
<p>
	<br />
	46 &ldquo;Why do you call me, &lsquo;Lord, Lord,&rsquo; and do not do what I say?
	<br />
	47 As for everyone who comes to me and hears my words and puts them into practice, I will show you what they are like.
	<br />
	48 They are like a man building a house, who dug down deep and laid the foundation on rock. When a flood came, the torrent struck that house but could not shake it, because it was well built.
	<br />
	49 But the one who hears my words and does not put them into practice is like a man who built a house on the ground without a foundation. The moment the torrent struck that house, it collapsed and its destruction was complete.&rdquo;
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke6