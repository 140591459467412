import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-9 </title>
	<meta
          name="description"
          content="Ecclesiastes 9 | பிரசங்கி 9 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவை எல்லாவற்றையும் நான் என் மனதிலே வகையறுக்கும்படிக்குச் சிந்தித்தேன்; நீதிமான்களும் ஞானிகளும், தங்கள் கிரியைகளுடன், தேவனுடைய கைவசமாயிருக்கிறார்கள்; தனக்குமுன் இருக்கிறவர்களைக்கொண்டு ஒருவனும் விருப்பையாவது வெறுப்பையாவது அறியான்.
	<br /><br />
	2. எல்லாருக்கும் எல்லாம் ஒரேவிதமாய்ச் சம்பவிக்கும்; சன்மார்க்கனுக்கும் துன்மார்க்கனுக்கும், நற்குணமும் சுத்தமுமுள்ளவனுக்கும் சுத்தமில்லாதவனுக்கும், பலியிடுகிறவனுக்கும் பலியிடாதவனுக்கும், ஒரேவிதமாய்ச் சம்பவிக்கும்; நல்லவனுக்கு எப்படியோ பொல்லாதவனுக்கும் அப்படியே; ஆணையிடுகிறவனுக்கும் ஆணையிடப் பயப்படுகிறவனுக்கும் சமமாய்ச் சம்பவிக்கும்.
	<br /><br />
	3. எல்லாருக்கும் ஒரேவிதமாய்ச் சம்பவிக்கிறது சூரியனுக்குக் கீழே நடக்கிறதெல்லாவற்றிலும் விசேஷித்த தீங்காம்; ஆதலால் மனுபுத்திரரின் இருதயம் தீமையினால் நிறைந்திருக்கிறது; அவர்கள் உயிரோடிருக்கும் நாளளவும் அவர்கள் இருதயம் பைத்தியங்கொண்டிருந்து, பின்பு அவர்கள் செத்தவர்களிடத்திற்குப் போகிறார்கள்.
	<br /><br />
	4. இதற்கு நீங்கலாயிருக்கிறவன் யார்? உயிரோடிருக்கிற அனைவரிடத்திலும் நம்பிக்கையுண்டு; செத்த சிங்கத்தைப்பார்க்கிலும் உயிருள்ள நாய் வாசி.
	<br /><br />
	5. உயிரோடிருக்கிறவர்கள் தாங்கள் மரிப்பதை அறிவார்களே, மரித்தவர்கள் ஒன்றும் அறியார்கள்; இனி அவர்களுக்கு ஒரு பலனுமில்லை, அவர்கள் பேர்முதலாய் மறக்கப்பட்டிருக்கிறது.
	<br /><br />
	6. அவர்கள் சிநேகமும், அவர்கள் பகையும், அவர்கள் பொறாமையும் எல்லாம் ஒழிந்துபோயிற்று; சூரியனுக்குக் கீழே செய்யப்படுகிறதொன்றிலும் அவர்களுக்கு இனி என்றைக்கும் பங்கில்லை.
	<br /><br />
	7. நீ போய், உன் ஆகாரத்தைச் சந்தோஷத்துடன் புசித்து, உன் திராட்சரசத்தை மனமகிழ்ச்சியுடன் குடி; தேவன் உன் கிரியைகளை அங்கீகாரம்பண்ணியிருக்கிறார்.
	<br /><br />
	8. உன் வஸ்திரங்கள் எப்பொழுதும் வெள்ளையாயும், உன் தலைக்கு எண்ணெய் குறையாததாயும் இருப்பதாக.
	<br /><br />
	9. சூரியனுக்குக்கீழே தேவன் உனக்கு நியமித்திருக்கிற மாயையான நாட்களிலெல்லாம் நீ நேசிக்கிற மனைவியோடே நிலையில்லாத இந்த ஜீவவாழ்வை அநுபவி; இந்த ஜீவனுக்குரிய வாழ்விலும், நீ சூரியனுக்குக்கீழே படுகிற பிரயாசத்திலும் பங்கு இதுவே.
	<br /><br />
	10. செய்யும்படி உன் கைக்கு நேரிடுகிறது எதுவோ, அதை உன் பெலத்தோடே செய்; நீ போகிற பாதாளத்திலே செய்கையும் வித்தையும் அறிவும் ஞானமும் இல்லையே.
	<br /><br />
	11. நான் திரும்பிக்கொண்டு சூரியனுக்குக் கீழே கண்டதாவது: ஓடுகிறதற்கு வேகமுள்ளவர்களின் வேகமும், யுத்தத்துக்குச் சவுரியவான்களின் சவுரியமும் போதாது; பிழைப்புக்கு ஞானமுள்ளவர்களின் ஞானமும் போதாது; ஐசுவரியம் அடைகிறதற்குப் புத்திமான்களின் புத்தியும் போதாது; தயவு அடைகிறதற்கு வித்துவான்களின் அறிவும் போதாது; அவர்களெல்லாருக்கும் சமயமும் தேவச்செயலும் நேரிடவேண்டும்.
	<br /><br />
	12. தன் காலத்தை மனுஷன் அறியான்; மச்சங்கள் கொடிய வலையில் அகப்படுவதுபோலவும், குருவிகள் கண்ணியில் பிடிபடுவதுபோலவும், மனுபுத்திரர் பொல்லாத காலத்திலே சடிதியில் தங்களுக்கு நேரிடும் ஆபத்தில் அகப்படுவார்கள்.
	<br /><br />
	13. சூரியனுக்குக் கீழே ஞானமுள்ள காரியத்தையும் கண்டேன்; அது என் பார்வைக்குப் பெரிதாய்த் தோன்றிற்று.
	<br /><br />
	14. ஒரு சிறு பட்டணம் இருந்தது, அதிலே இருந்த குடிகள் கொஞ்ச மனிதர்; அதற்கு விரோதமாய் ஒரு பெரிய ராஜா வந்து, அதை வளைந்துகொண்டு, அதற்கு எதிராகப் பெரிய கொத்தளங்களைக் கட்டினான்.
	<br /><br />
	15. அதிலே ஞானமுள்ள ஒரு ஏழை மனிதன் இருந்தான்; அவன் தன் ஞானத்தினாலே அந்தப் பட்டணத்தை விடுவித்தான்; ஆனாலும் அந்த ஏழை மனிதனை ஒருவரும் நினைக்கவில்லை.
	<br /><br />
	16. ஆகையால் ஏழையின் ஞானம் அசட்டைபண்ணப்பட்டு, அவன் வார்த்தைகள் கேட்கப்படாமற்போனாலும், பெலத்தைப்பார்க்கிலும் ஞானமே உத்தமம் என்றேன்.
	<br /><br />
	17. மூடரை ஆளும் அதிபதியின் கூக்குரலைப்பார்க்கிலும் ஞானிகளுடைய அமரிக்கையான வார்த்தைகளே கேட்கப்படத்தக்கவைகள்.
	<br /><br />
	18. யுத்த ஆயுதங்களைப்பார்க்கிலும் ஞானமே நலம்; பாவியான ஒருவன் மிகுந்த நன்மையைக் கெடுப்பான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes9