import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah60 = () => {
  const verseNumber = 60;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 60 </title>
	<meta
          name="description"
          content="Isaiah 60 | ஏசாயா 60 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எழும்பிப் பிரகாசி; உன் ஒளி வந்தது, கர்த்தருடைய மகிமை உன்மேல் உதித்தது.
	<br /><br />
	2. இதோ, இருள் பூமியையும், காரிருள் ஜனங்களையும் மூடும்; ஆனாலும் உன்மேல் கர்த்தர் உதிப்பார்; அவருடைய மகிமை உன்மேல் காணப்படும்.
	<br /><br />
	3. உன் வெளிச்சத்தினிடத்துக்கு ஜாதிகளும், உதிக்கிற உன் ஒளியினிடத்துக்கு ராஜாக்களும் நடந்துவருவார்கள்.
	<br /><br />
	4. சுற்றிலும் உன் கண்களை ஏறெடுத்துப்பார்; அவர்கள் எல்லாரும் ஏகமாய்க் கூடி உன்னிடத்திற்கு வருகிறார்கள்; உன் குமாரர் தூரத்திலிருந்து வந்து, உன் குமாரத்திகள் உன் பக்கத்திலே வளர்க்கப்படுவார்கள்.
	<br /><br />
	5. அப்பொழுது நீ அதைக் கண்டு ஓடிவருவாய்; உன் இருதயம் அதிசயப்பட்டுப் பூரிக்கும்; கடற்கரையின் திரளான கூட்டம் உன் வசமாகத் திரும்பும், ஜாதிகளின் பலத்த சேனை உன்னிடத்துக்கு வரும்.
	<br /><br />
	6. ஒட்டகங்களின் ஏராளமும், மீதியான் ஏப்பாத் தேசங்களின் வேகமான ஒட்டகங்களும் உன்னை மூடும்; சேபாவிலுள்ளவர்கள் யாவரும் பொன்னையும் தூபவர்க்கத்தையும் கொண்டுவந்து, கர்த்தரின் துதிகளைப் பிரசித்தப்படுத்துவார்கள்.
	<br /><br />
	7. கேதாரின் ஆடுகளெல்லாம் உன்னிடத்தில் சேர்க்கப்படும்; நெபாயோத்தின் கடாக்கள் உன்னைச் சேவித்து, அங்கிகரிக்கப்பட்டதாய் என் பலிபீடத்தின்மேல் ஏறும்; என் மகிமையின் ஆலயத்தை மகிமைப்படுத்துவேன்.
	<br /><br />
	8. மேகத்தைப்போலவும், தங்கள் பலகணித்துவாரங்களுக்குத் தீவிரிக்கிற புறாக்களைப்போலவும் பறந்துவருகிற இவர்கள் யார்?
	<br /><br />
	9. தீவுகள் எனக்குக் காத்திருக்கும்; அவர் உன்னை மகிமைப்படுத்தினார் என்று உன் பிள்ளைகளையும், அவர்களோடேகூட அவர்கள் பொன்னையும், அவர்கள் வெள்ளியையும் உன் தேவனாகிய கர்த்தரின் நாமத்துக்கென்றும், இஸ்ரவேலின் பரிசுத்தருக்கென்றும், தூரத்திலிருந்து கொண்டுவர, தர்ஷீசின் கப்பல்களும் ஏற்கனவே எனக்குக் காத்திருக்கும்.
	<br /><br />
	10. அந்நியரின் புத்திரர் உன் மதில்களைக் கட்டி, அவர்களுடைய ராஜாக்கள் உன்னைச் சேவிப்பார்கள்; என் கடுங்கோபத்தினால் உன்னை அடித்தேன்; ஆனாலும் என் கிருபையினால் உனக்கு இரங்கினேன்.
	<br /><br />
	11. உன்னிடத்துக்கு ஜாதிகளின் பலத்த சேனையைக் கொண்டுவரும்படிக்கும், அவர்களுடைய ராஜாக்களை அழைத்துவரும்படிக்கும், உன் வாசல்கள் இரவும்பகலும் பூட்டப்படாமல் எப்பொழுதும் திறந்திருக்கும்.
	<br /><br />
	12. உன்னைச் சேவிக்காத ஜாதியும் ராஜ்யமும் அழியும்; அந்த ஜாதிகள் நிச்சயமாய்ப் பாழாகும்.
	<br /><br />
	13. என் பரிசுத்த ஸ்தானத்தைச் சிங்காரிக்கும்படிக்கு, லீபனோனின் மகிமையும், தேவதாருவிருட்சங்களும், பாய்மர விருட்சங்களும், புன்னைமரங்களுங்கூட உன்னிடத்திற்குக் கொண்டுவரப்படும்; என் பாதஸ்தானத்தை மகிமைப்படுத்துவேன்.
	<br /><br />
	14. உன்னை ஒடுக்கினவர்களின் பிள்ளைகளும் குனிந்து உன்னிடத்தில் வந்து, உன்னை அசட்டைபண்ணின யாவரும் உன் காலடியில் பணிந்து, உன்னைக் கர்த்தருடைய நகரம் என்றும், இஸ்ரவேலுடைய பரிசுத்தரின் சீயோன் என்றும் சொல்வார்கள்.
	<br /><br />
	15. நீ நெகிழப்பட்டதும், கைவிடப்பட்டதும், ஒருவரும் கடந்து நடவாததுமாயிருந்தாய்; ஆனாலும் உன்னை நித்திய மாட்சிமையாகவும், தலைமுறை தலைமுறையாயிருக்கும் மகிழ்ச்சியாகவும் வைப்பேன்.
	<br /><br />
	16. நீ ஜாதிகளின் பாலைக் குடித்து, ராஜாக்களின் முலைப்பாலையும் உண்டு, கர்த்தராகிய நான் இரட்சகரென்றும், யாக்கோபின் வல்லவர் உன் மீட்பரென்றும் அறிந்துகொள்வாய்.
	<br /><br />
	17. நான் வெண்கலத்துக்குப் பதிலாகப் பொன்னையும், இரும்புக்குப் பதிலாக வெள்ளியையும், மரங்களுக்குப் பதிலாக வெண்கலத்தையும், கற்களுக்குப் பதிலாக இரும்பையும் வரப்பண்ணி, உன் கண்காணிகளைச் சமாதானமுள்ளவர்களும், உன் தண்டற்காரரை நீதியுள்ளவர்களுமாக்குவேன்.
	<br /><br />
	18. இனிக் கொடுமை உன் தேசத்திலும், அழிவும் நாசமும் உன் எல்லைகளிலும் கேட்கப்படமாட்டாது; உன் மதில்களை இரட்சிப்பென்றும், உன் வாசல்களைத் துதியென்றும் சொல்லுவாய்.
	<br /><br />
	19. இனிச் சூரியன் உனக்குப் பகலிலே வெளிச்சமாயிராமலும், சந்திரன் தன் வெளிச்சத்தால் உனக்குப் பிரகாசியாமலும், கர்த்தரே உனக்கு நித்திய வெளிச்சமும், உன் தேவனே உனக்கு மகிமையுமாயிருப்பார்.
	<br /><br />
	20. உன் சூரியன் இனி அஸ்தமிப்பதுமில்லை; உன் சந்திரன் மறைவதுமில்லை; கர்த்தரே உனக்கு நித்திய வெளிச்சமாயிருப்பார்; உன் துக்கநாட்கள் முடிந்துபோம்.
	<br /><br />
	21. உன் ஜனங்கள் யாவரும் நீதிமான்களும், என்றைக்கும் பூமியைச் சுதந்தரிக்குங் குடிகளும், நான் நட்ட கிளைகளும், நான் மகிமைப்படும்படி என் கரங்களின் கிரியைகளுமாயிருப்பார்கள்.
	<br /><br />
	22. சின்னவன் ஆயிரமும், சிறியவன் பலத்த ஜாதியுமாவான்; கர்த்தராகிய நான் ஏற்றகாலத்தில் இதைத் தீவிரமாய் நடப்பிப்பேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah60