import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 6 </title>
	<meta
          name="description"
          content="Mark 6 | மாற்கு 6 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் அவ்விடம் விட்டுப் புறப்பட்டு, தாம் வளர்ந்த ஊருக்கு வந்தார்; அவருடைய சீஷரும் அவரோடேகூட வந்தார்கள்.
	<br /><br />
	2. ஓய்வுநாளானபோது, ஜெபஆலயத்தில் உபதேசம்பண்ணத் தொடங்கினார். அநேகர் கேட்டு, ஆச்சரியப்பட்டு, இவைகள் இவனுக்கு எங்கேயிருந்து வந்தது? இவன் கைகளினால் இப்படிப்பட்ட பலத்த செய்கைகள் நடக்கும்படி இவனுக்குக் கொடுக்கப்பட்ட ஞானம் எப்படிப்பட்டது?
	<br /><br />
	3. இவன் தச்சன் அல்லவா? மரியாளுடைய குமாரன் அல்லவா? யாக்கோபு யோசே யூதா சீமோன் என்பவர்களுக்குச் சகோதரன் அல்லவா? இவன் சகோதரிகளும் இங்கே நம்மிடத்தில் இருக்கிறார்கள் அல்லவா? என்று சொல்லி, அவரைக்குறித்து இடறலடைந்தார்கள்.
	<br /><br />
	4. இயேசு அவர்களை நோக்கி: தீர்க்கதரிசி ஒருவன் தன் ஊரிலும் தன் இனத்திலும் தன் வீட்டிலுமேயன்றி வேறெங்கும் கனவீனமடையான் என்றார்.
	<br /><br />
	5. அங்கே அவர் சில நோயாளிகள்மேல் கைகளை வைத்து, அவர்களைக் குணமாக்கினதேயன்றி, வேறொரு அற்புதமும் செய்யக்கூடாமல்,
	<br /><br />
	6. அவர்களுடைய அவிசுவாசத்தைக் குறித்து ஆச்சரியப்பட்டு; கிராமங்களிலே சுற்றித்திரிந்து, உபதேசம்பண்ணினார்.
	<br /><br />
	7. அவர் பன்னிருவரையும் அழைத்து, அசுத்த ஆவிகளைத் துரத்த அவர்களுக்கு அதிகாரங்கொடுத்து,
	<br /><br />
	8. வழிக்குப் பையையாகிலும், அப்பத்தையாகிலும், கச்சையில் காசையாகிலும், எடுத்துக்கொண்டுபோகாமல், ஒரு தடியை மாத்திரம் எடுத்துக்கொண்டுபோகவும்;
	<br /><br />
	9. பாதரட்சைகளைப் போட்டுக்கொண்டுபோகவும், இரண்டு அங்கிகளைத் தரியாதிருக்கவும் கட்டளையிட்டார்.
	<br /><br />
	10. பின்பு அவர்களை நோக்கி: நீங்கள் எங்கேயாகிலும் ஒரு வீட்டில் பிரவேசித்தால், அவ்விடத்தை விட்டுப் புறப்படுகிறவரைக்கும் அங்கேதானே தங்கியிருங்கள்.
	<br /><br />
	11. எவர்களாகிலும் உங்களை ஏற்றுக்கொள்ளாமலும், உங்கள் வசனங்களைக் கேளாமலும் இருந்தால், நீங்கள் அவ்விடம் விட்டுப் புறப்படும்போது, அவர்களுக்குச் சாட்சியாக உங்கள் கால்களின் கீழே படிந்த தூசியை உதறிப்போடுங்கள். நியாயத்தீர்ப்புநாளிலே அந்தப் பட்டணத்திற்கு நேரிடுவதைப்பார்க்கிலும் சோதோம் கொமோரா பட்டணத்திற்கு நேரிடுவது இலகுவாயிருக்கும் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்று சொல்லி, அவர்களை இரண்டு இரண்டுபேராக அனுப்பினார்.
	<br /><br />
	12. அவர்கள் புறப்பட்டுப்போய்: மனந்திரும்புங்கள் என்று பிரசங்கித்து;
	<br /><br />
	13. அநேகம் பிசாசுகளைத் துரத்தி, அநேகம் நோயாளிகளை எண்ணெய் பூசிச் சொஸ்தமாக்கினார்கள்.
	<br /><br />
	14. அவருடைய பேர் பிரசித்தமானபடியினால், ஏரோதுராஜா அவரைக்குறித்துக் கேள்விப்பட்டு: யோவான்ஸ்நானன் மரித்தோரிலிருந்து எழுந்தான், ஆகையால் அவனிடத்தில் இந்தப் பலத்த செய்கைகள் விளங்குகிறது என்றான்.
	<br /><br />
	15. சிலர்: அவர் எலியா என்றார்கள். வேறு சிலர்: அவர், ஒரு தீர்க்கதரிசி, அல்லது தீர்க்கதரிசிகளில் ஒருவனைப்போலிருக்கிறாரென்று சொன்னார்கள்.
	<br /><br />
	16. ஏரோது அதைக் கேட்டபொழுது: அவன் நான் சிரச்சேதம் பண்ணின யோவான்தான்; அவன் மரித்தோரிலிருந்து எழுந்தான் என்றான்.
	<br /><br />
	17. ஏரோது தன் சகோதரனாகிய பிலிப்புவின் மனைவி ஏரோதியாளைத் தனக்கு மனைவியாக்கிக்கொண்டபோது,
	<br /><br />
	18. யோவான் ஏரோதை நோக்கி: நீர் உம்முடைய சகோதரன் மனைவியை வைத்துக்கொள்வது நியாயமல்லவென்று சொன்னதினிமித்தம், ஏரோது சேவகரை அனுப்பி, யோவானைப் பிடித்துக் கட்டிக் காவலில் வைத்திருந்தான்.
	<br /><br />
	19. ஏரோதியாளும் அவனுக்குச் சதி நினைத்து, அவனைக் கொன்றுபோட மனதாயிருந்தாள்; ஆகிலும் அவளால் கூடாமற்போயிற்று.
	<br /><br />
	20. அதேனென்றால் யோவான் நீதியும் பரிசுத்தமுமுள்ளவனென்று ஏரோது அறிந்து, அவனுக்குப் பயந்து, அவனைப் பாதுகாத்து, அவன் யோசனையின்படி அநேக காரியங்களைச் செய்து, விருப்பத்தோடே அவன் சொல்லைக் கேட்டு வந்தான்.
	<br /><br />
	21. பின்பு சமயம் வாய்த்தது; எப்படியென்றால், ஏரோது தன் ஜென்மநாளிலே தன்னுடைய பிரபுக்களுக்கும், சேனாதிபதிகளுக்கும், கலிலேயா நாட்டின் பிரதான மனுஷருக்கும் ஒரு விருந்து பண்ணினபோது,
	<br /><br />
	22. ஏரோதியாளின் குமாரத்தி சபை நடுவே வந்து நடனம்பண்ணி, ஏரோதுவையும் அவனோடேகூடப் பந்தியிருந்தவர்களையும் சந்தோஷப்படுத்தினாள். அப்பொழுது, ராஜா சிறுபெண்ணை நோக்கி: உனக்கு வேண்டியதை என்னிடத்தில் கேள், அதை உனக்குத் தருவேன் என்று சொன்னதுமல்லாமல்;
	<br /><br />
	23. நீ என்னிடத்தில் எதைக் கேட்டாலும், அது என் ராஜ்யத்தில் பாதியானாலும், அதை உனக்குத் தருவேன் என்று அவளுக்கு ஆணையும் இட்டான்.
	<br /><br />
	24. அப்பொழுது, அவள் வெளியே போய், நான் என்ன கேட்கவேண்டும் என்று தன் தாயினிடத்தில் கேட்டாள். அதற்கு அவள்: யோவான்ஸ்நானனுடைய தலையைக் கேள் என்றாள்.
	<br /><br />
	25. உடனே அவள் ராஜாவினிடத்தில் சீக்கிரமாய் வந்து: நீர் இப்பொழுதே ஒரு தாலத்தில் யோவான்ஸ்நானனுடைய தலையை எனக்குத் தரவேண்டும் என்று கேட்டாள்.
	<br /><br />
	26. அப்பொழுது ராஜா மிகுந்த துக்கமடைந்தான்; ஆகிலும், ஆணையினிமித்தமும், கூடப்பந்தியிருந்தவர்களினிமித்தமும், அவளுக்கு அதை மறுக்க மனதில்லாமல்;
	<br /><br />
	27. உடனே அவனுடைய தலையைக் கொண்டுவரும்படி சேவகனுக்குக் கட்டளையிட்டு அனுப்பினான்.
	<br /><br />
	28. அந்தப்படி அவன் போய், காவற்கூடத்திலே அவனைச் சிரச்சேதம்பண்ணி, அவன் தலையை ஒரு தாலத்திலே கொண்டுவந்து, அதை அந்தச் சிறுபெண்ணுக்குக் கொடுத்தான்; அந்தச் சிறுபெண் அதைத் தன் தாயினிடத்தில் கொடுத்தாள்.
	<br /><br />
	29. அவனுடைய சீஷர்கள் அதைக் கேள்விப்பட்டு வந்து, அவன் உடலை எடுத்து, ஒரு கல்லறையில் வைத்தார்கள்.
	<br /><br />
	30. அப்பொழுது அப்போஸ்தலர் இயேசுவினிடத்தில் கூடிவந்து, தாங்கள் செய்தவைகள் உபதேசித்தவைகள் யாவையும் அவருக்கு அறிவித்தார்கள்.
	<br /><br />
	31. அவர் அவர்களை நோக்கி: வனாந்தரமான ஓரிடத்தில் தனித்துச் சற்றே இளைப்பாறும்படி போவோம் வாருங்கள் என்றார்; ஏனெனில், வருகிறவர்களும் போகிறவர்களும் அநேகராயிருந்தபடியினால் போஜனம் பண்ணுகிறதற்கும் அவர்களுக்குச் சமயமில்லாதிருந்தது.
	<br /><br />
	32. அப்படியே அவர்கள் தனிமையாய் ஒரு படவில் ஏறி வனாந்தரமான ஓர் இடத்திற்குப் போனார்கள்.
	<br /><br />
	33. அவர்கள் புறப்பட்டுப் போகிறதை ஜனங்கள் கண்டார்கள். அவரை அறிந்த அநேகர் சகல பட்டணங்களிலுமிருந்து கால்நடையாய் அவ்விடத்திற்கு ஓடி, அவர்களுக்கு முன்னே அங்கே சேர்ந்து, அவரிடத்தில் கூடிவந்தார்கள்.
	<br /><br />
	34. இயேசு கரையில் வந்து, அநேக ஜனங்களைக் கண்டு, அவர்கள் மேய்ப்பனில்லாத ஆடுகளைப்போலிருந்தபடியால், அவர்கள்மேல் மனதுருகி, அநேக காரியங்களை அவர்களுக்கு உபதேசிக்கத் தொடங்கினார்.
	<br /><br />
	35. வெகுநேரம் சென்றபின்பு, அவருடைய சீஷர்கள் அவரிடத்தில் வந்து: இது வனாந்தரமான இடம், வெகுநேரமுமாயிற்று;
	<br /><br />
	36. புசிக்கிறதற்கும் இவர்களிடத்தில் ஒன்றுமில்லை; ஆகையால் இவர்கள் சுற்றியிருக்கிற கிராமங்களுக்கும் ஊர்களுக்கும் போய், தங்களுக்காக அப்பங்களை வாங்கிக்கொள்ளும்படி இவர்களை அனுப்பிவிடவேண்டும் என்றார்கள்.
	<br /><br />
	37. அவர் அவர்களை நோக்கி: நீங்களே அவர்களுக்குப் போஜனங்கொடுங்கள் என்றார். அதற்கு அவர்கள்: நாங்கள் போய், இருநூறு பணத்துக்கு அப்பங்களை வாங்கி இவர்களுக்குப் புசிக்கும்படி கொடுக்கக்கூடுமோ என்றார்கள்.
	<br /><br />
	38. அதற்கு அவர்: உங்களிடத்தில் எத்தனை அப்பங்களுண்டு, போய்ப்பாருங்கள் என்றார். அவர்கள் பார்த்துவந்து: ஐந்து அப்பங்களும், இரண்டு மீன்களும் உண்டு என்றார்கள்.
	<br /><br />
	39. அப்பொழுது எல்லாரையும் பசும்புல்லின்மேல் பந்திபந்தியாக உட்காரவைக்கும்படி அவர்களுக்குக் கட்டளையிட்டார்.
	<br /><br />
	40. அப்படியே வரிசை வரிசையாய், நூறுநூறுபேராகவும் ஐம்பதைம்பதுபேராகவும், உட்கார்ந்தார்கள்.
	<br /><br />
	41. அவர் அந்த ஐந்து அப்பங்களையும், அந்த இரண்டு மீன்களையும் எடுத்து, வானத்தை அண்ணாந்துபார்த்து, ஆசீர்வதித்து, அப்பங்களைப்பிட்டு, அவர்களுக்குப் பரிமாறும்படி தம்முடைய சீஷர்களிடத்தில் கொடுத்தார். அப்படியே இரண்டு மீன்களையும் எல்லாருக்கும் பங்கிட்டார்.
	<br /><br />
	42. எல்லாரும் சாப்பிட்டுத் திருப்தியடைந்தார்கள்.
	<br /><br />
	43. மேலும் அப்பங்களிலும் மீன்களிலும் மீதியான துணிக்கைகளைப் பன்னிரண்டு கூடைநிறைய எடுத்தார்கள்.
	<br /><br />
	44. அப்பம் சாப்பிட்ட புருஷர் ஏறக்குறைய ஐயாயிரம்பேராயிருந்தார்கள்.
	<br /><br />
	45. அவர் ஜனங்களை அனுப்பிவிடுகையில், தம்முடைய சீஷர்கள் படவில் ஏறி அக்கரையில் பெத்சாயிதாவுக்கு எதிராக, தமக்கு முன்னே போகும்படி, அவர்களைத் துரிதப்படுத்தினார்.
	<br /><br />
	46. அவர் ஜனங்களை அனுப்பிவிட்டபின்பு, ஜெபம்பண்ணும்படி ஒரு மலையின்மேல் ஏறினார்.
	<br /><br />
	47. சாயங்காலமானபோது படவு நடுக்கடலிலிருந்தது; அவரோ கரையிலே தனிமையாயிருந்தார்.
	<br /><br />
	48. அப்பொழுது காற்று அவர்களுக்கு எதிராயிருந்தபடியினால், அவர்கள் தண்டு வலிக்கிறதில் வருத்தப்படுகிறதை அவர் கண்டு, இராத்திரியில் நாலாம் ஜாமத்தில் கடலின்மேல் நடந்து அவர்களிடத்தில் வந்து, அவர்களைக் கடந்துபோகிறவர்போல் காணப்பட்டார்.
	<br /><br />
	49. அவர் கடலின்மேல் நடக்கிறதை அவர்கள் கண்டு, ஆவேசம் என்று எண்ணி, சத்தமிட்டு அலறினார்கள்.
	<br /><br />
	50. அவர்களெல்லாரும் அவரைக் கண்டு கலக்கமடைந்தார்கள். உடனே அவர் அவர்களோடே பேசி: திடன்கொள்ளுங்கள், நான்தான், பயப்படாதிருங்கள் என்று சொல்லி,
	<br /><br />
	51. அவர்கள் இருந்த படவில் ஏறினார். அப்பொழுது காற்று அமர்ந்தது; அதினால் அவர்கள் தங்களுக்குள்ளே மிகவும் பிரமித்து ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	52. அவர்களுடைய இருதயம் கடினமுள்ளதாயிருந்தபடியினால் அப்பங்களைக் குறித்து அவர்கள் உணராமற்போனார்கள்.
	<br /><br />
	53. அவர்கள் கடலைக் கடந்து கெனேசரேத்தென்னும் நாட்டிற்கு வந்து, கரைபிடித்தார்கள்.
	<br /><br />
	54. அவர்கள் படவிலிருந்து இறங்கினவுடனே, ஜனங்கள் அவரை அறிந்து,
	<br /><br />
	55. அந்தச் சுற்றுப்புறமெங்கும் ஓடித்திரிந்து, பிணியாளிகளைப் படுக்கைகளில் கிடத்தி, அவர் வந்திருக்கிறாரென்று கேள்விப்பட்ட இடங்களிலெல்லாம் சுமந்து கொண்டுவந்தார்கள்.
	<br /><br />
	56. அல்லாமலும் அவர் பிரவேசித்த கிராமங்கள் பட்டணங்கள் நாடுகள் எவைகளோ, அவைகளின் சந்தைவெளிகளிலே வியாதிக்காரரை வைத்து, அவருடைய வஸ்திரத்தின் ஓரத்தையாகிலும் அவர்கள் தொடும்படி உத்தரவாகவேண்டும் என்று அவரை வேண்டிக்கொண்டார்கள்; அவரைத் தொட்ட யாவரும் சொஸ்தமானார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark6