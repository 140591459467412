import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneJohn3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> Obedience & Love | 1 John - 3 in Tamil Bible | 1 யோவான்- 3 </title>
	<meta
          name="description"
          content="Obedience & Love | 1st John in Tamil Bible Verse | 1-யோவான்அதிகாரம் - 3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கீழ்ப்படிதல் & அன்பு *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. நாம் தேவனுடைய பிள்ளைகளென்று அழைக்கப்படுவதினாலே பிதாவானவர் நமக்குப் பாராட்டின அன்பு எவ்வளவு பெரிதென்று பாருங்கள்; உலகம் அவரை அறியாதபடியினாலே நம்மையும் அறியவில்லை.
	<br /><br />
	2. பிரியமானவர்களே, இப்பொழுது தேவனுடைய பிள்ளைகளாயிருக்கிறோம், இனி எவ்விதமாயிருப்போமென்று இன்னும் வெளிப்படவில்லை; ஆகிலும் அவர் வெளிப்படும்போது அவர் இருக்கிறவண்ணமாகவே நாம் அவரைத் தரிசிப்பதினால், அவருக்கு ஒப்பாயிருப்போமென்று அறிந்திருக்கிறோம்.
	<br /><br />
	3. அவர்மேல் இப்படிப்பட்ட நம்பிக்கை வைத்திருக்கிறவனெவனும், அவர் சுத்தமுள்ளவராயிருக்கிறதுபோல, தன்னையும் சுத்திகரித்துக்கொள்ளுகிறான்.
	<br /><br />
	4. பாவஞ்செய்கிற எவனும் நியாயப்பிரமாணத்தை மீறுகிறான்; நியாயப்பிரமாணத்தை மீறுகிறதே பாவம்.
	<br /><br />
	5. அவர் நம்முடைய பாவங்களைச் சுமந்து தீர்க்க வெளிப்பட்டாரென்று அறிவீர்கள்; அவரிடத்தில் பாவமில்லை.
	<br /><br />
	6. அவரில் நிலைத்திருக்கிற எவனும் பாவஞ்செய்கிறதில்லை; பாவஞ்செய்கிற எவனும் அவரைக் காணவுமில்லை, அவரை அறியவுமில்லை.
	<br /><br />
	7. பிள்ளைகளே, நீங்கள் ஒருவராலும் வஞ்சிக்கப்படாதிருங்கள்; நீதியைச் செய்கிறவன் அவர் நீதியுள்ளவராயிருக்கிறதுபோலத் தானும் நீதியுள்ளவனாயிருக்கிறான்.
	<br /><br />
	8. பாவஞ்செய்கிறவன் பிசாசினாலுண்டாயிருக்கிறான்; ஏனெனில் பிசாசானவன் ஆதிமுதல் பாவஞ்செய்கிறான்; பிசாசினுடைய கிரியைகளை அழிக்கும்படிக்கே தேவனுடைய குமாரன் வெளிப்பட்டார்.
	<br /><br />
	9. தேவனால் பிறந்த எவனும் பாவஞ்செய்யான், ஏனெனில் அவருடைய வித்து அவனுக்குள் தரித்திருக்கிறது; அவன் தேவனால் பிறந்தபடியினால் பாவஞ்செய்யமாட்டான்.
	<br /><br />
	10. இதினாலே தேவனுடைய பிள்ளைகள் இன்னாரென்றும், பிசாசின் பிள்ளைகள் இன்னாரென்றும் வெளிப்படும்; நீதியைச் செய்யாமலும் தன் சகோதரனில் அன்புகூராமலும் இருக்கிற எவனும் தேவனால் உண்டானவனல்ல.
	<br /><br />
	11. நாம் ஒருவரிலொருவர் அன்புகூரவேண்டுமென்பதே நீங்கள் ஆதிமுதல் கேள்விப்பட்ட விசேஷமாயிருக்கிறது.
	<br /><br />
	12. பொல்லாங்கனால் உண்டாயிருந்து தன் சகோதரனைக் கொலைசெய்த காயீனைப்போலிருக்கவேண்டாம்; அவன் எதினிமித்தம் அவனைக் கொலைசெய்தான்? தன் கிரியைகள் பொல்லாதவைகளும், தன் சகோதரனுடைய கிரியைகள் நீதியுள்ளவைகளுமாயிருந்ததினிமித்தந்தானே.
	<br /><br />
	13. என் சகோதரரே, உலகம் உங்களைப் பகைத்தால் ஆச்சரியப்படாதிருங்கள்.
	<br /><br />
	14. நாம் சகோதரரிடத்தில் அன்புகூருகிறபடியால், மரணத்தைவிட்டு நீங்கி ஜீவனுக்குட்பட்டிருக்கிறோமென்று அறிந்திருக்கிறோம்; சகோதரனிடத்தில் அன்புகூராதவன் மரணத்திலே நிலைகொண்டிருக்கிறான்.
	<br /><br />
	15. தன் சகோதரனைப் பகைக்கிற எவனும் மனுஷ கொலைபாதகனாயிருக்கிறான்; மனுஷ கொலைபாதகனெவனோ அவனுக்குள் நித்தியஜீவன் நிலைத்திராது என்று அறிவீர்கள்.
	<br /><br />
	16. அவர் தம்முடைய ஜீவனை நமக்காகக் கொடுத்ததினாலே அன்பு இன்னதென்று அறிந்திருக்கிறோம்; நாமும் சகோதரருக்காக ஜீவனைக்கொடுக்கக் கடனாளிகளாயிருக்கிறோம்.
	<br /><br />
	17. ஒருவன் இவ்வுலக ஆஸ்தி உடையவனாயிருந்து, தன் சகோதரனுக்குக் குறைச்சலுண்டென்று கண்டு, தன் இருதயத்தை அவனுக்கு அடைத்துக்கொண்டால், அவனுக்குள் தேவ அன்பு நிலைகொள்ளுகிறதெப்படி?
	<br /><br />
	18. என் பிள்ளைகளே, வசனத்தினாலும் நாவினாலுமல்ல, கிரியையினாலும் உண்மையினாலும் அன்புகூரக்கடவோம்.
	<br /><br />
	19. இதினாலே நாம் நம்மைச் சத்தியத்திற்குரியவர்களென்று அறிந்து, நம்முடைய இருதயத்தை அவருக்கு முன்பாக நிச்சயப்படுத்திக்கொள்ளலாம்.
	<br /><br />
	20. நம்முடைய இருதயமே நம்மைக் குற்றவாளிகளாகத் தீர்க்குமானால், தேவன் நம்முடைய இருதயத்திலும் பெரியவராயிருந்து சகலத்தையும் அறிந்திருக்கிறார்.
	<br /><br />
	21. பிரியமானவர்களே, நம்முடைய இருதயம் நம்மைக் குற்றவாளிகளென்று தீர்க்காதிருந்தால், நாம் தேவனிடத்தில் தைரியங்கொண்டிருந்து,
	<br /><br />
	22. அவருடைய கற்பனைகளை நாம் கைக்கொண்டு அவருக்கு முன்பாகப் பிரியமானவைகளைச் செய்கிறபடியினால் நாம் வேண்டிக்கொள்ளுகிறதெதுவோ அதை அவராலே பெற்றுக்கொள்ளுகிறோம்.
	<br /><br />
	23. நாம் அவருடைய குமாரனாகிய இயேசுகிறிஸ்துவின் நாமத்தின்மேல் விசுவாசமாயிருந்து, அவர் நமக்குக் கட்டளையிட்டபடி ஒருவரிலொருவர் அன்பாயிருக்கவேண்டுமென்பதே அவருடைய கற்பனையாயிருக்கிறது.
	<br /><br />
	24. அவருடைய கட்டளைகளைக் கைக்கொள்ளுகிறவன் அவரில் நிலைத்திருக்கிறான், அவரும் அவனில் நிலைத்திருக்கிறார்; அவர் நம்மில் நிலைத்திருக்கிறதை அவர் நமக்குத் தந்தருளின ஆவியினாலே அறிந்திருக்கிறோம்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 1 யோவான் 3, நம்மை அவருடைய பிள்ளைகளாக ஆக்குவதில் கடவுள் நம்மீது வைத்திருக்கும் அன்பின் மகத்துவத்தை ஒரு அழகான நினைவூட்டலுடன் தொடங்குகிறது. பின்னர் இந்த அத்தியாயம் பாவம் செய்யாததற்கான காரணங்களைக் கூறுகிறது. நம்முடைய பாவங்களைப் போக்கவும், பிசாசின் வேலையை அழிக்கவும் இயேசு தோன்றினார். உண்மையான பிள்ளைகள் கடவுளிடமிருந்து பிறந்ததால் அவர்கள் தொடர்ந்து பாவம் செய்வதில்லை என்று யோவான் குறிப்பிடுகிறார். உண்மையிலும் செயல்களிலும் தன்னை வெளிப்படுத்தும் அன்பின் இயேசுவின் முன்மாதிரியைப் பின்பற்றுவதற்கான நினைவூட்டலுடன் இந்த அத்தியாயம் முடிவடைகிறது. நம் இதயங்கள் நம்மைக் கண்டிக்க வேண்டாம் என்று நாங்கள் ஊக்குவிக்கப்படுகிறோம், ஏனென்றால் கடவுள் நம் இதயங்களை விட பெரியவர், எல்லாவற்றையும் அறிந்தவர். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OneJohn3