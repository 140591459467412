import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 10 </title>
	<meta
          name="description"
          content="The Book of Revelation - 10 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 10 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* நான் ஒரு தேவ தூதனை பார்த்தேன். அவர் கூக்குரலிட்டார், ஏழு இடிமுழக்கங்கள் முழங்கின. "சுருளைச் சாப்பிடு" என்றது ஒரு குரல். அது இனிப்பாகவும் பின்னர் கசப்பாகவும் இருந்தது. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பின்பு, பலமுள்ள வேறொரு தூதன் வானத்திலிருந்திறங்கி வரக்கண்டேன்; மேகம் அவனைச் சூழ்ந்திருந்தது, அவனுடைய சிரசின்மேல் வானவில்லிருந்தது, அவனுடைய முகம் சூரியனைப்போலவும், அவனுடைய கால்கள் அக்கினி ஸ்தம்பங்களைப்போலவும் இருந்தது.
	<br /><br />
	2. திறக்கப்பட்ட ஒரு சிறு புஸ்தகம் அவன் கையில் இருந்தது; தன் வலதுபாதத்தைச் சமுத்திரத்தின்மேலும், தன் இடதுபாதத்தைப் பூமியின்மேலும் வைத்து,
	<br /><br />
	3. சிங்கம் கெர்ச்சிக்கிறதுபோல மகா சத்தமாய் ஆர்ப்பரித்தான்; அவன் ஆர்ப்பரித்தபோது ஏழு இடிகளும் சத்தமிட்டு முழங்கின.
	<br /><br />
	4. அவ்வேழு இடிகளும் தங்கள் சத்தங்களை முழங்கினபோது, நான் எழுதவேண்டுமென்றிருந்தேன். அப்பொழுது: ஏழு இடிமுழக்கங்கள் சொன்னவைகளை நீ எழுதாமல் முத்திரைபோடு என்று வானத்திலிருந்து ஒரு சத்தம் உண்டாகக் கேட்டேன்.
	<br /><br />
	5. சமுத்திரத்தின்மேலும் பூமியின்மேலும் நிற்கிறதாக நான் கண்ட தூதன், தன் கையை வானத்திற்கு நேராக உயர்த்தி:
	<br /><br />
	6. இனி காலம் செல்லாது; ஆனாலும் தேவன் தம்முடைய ஊழியக்காரராகிய தீர்க்கதரிசிகளுக்குச் சுவிசேஷமாய் அறிவித்தபடி, ஏழாம் தூதனுடைய சத்தத்தின் நாட்களிலே அவன் எக்காளம் ஊதப்போகிறபோது தேவரகசியம் நிறைவேறும் என்று,
	<br /><br />
	7. வானத்தையும் அதிலுள்ளவைகளையும், பூமியையும் அதிலுள்ளவைகளையும், சமுத்திரத்தையும் அதிலுள்ளவைகளையும் சிருஷ்டித்தவரும், சதாகாலங்களிலும் உயிரோடிருக்கிறவருமானவர்மேல் ஆணையிட்டுச் சொன்னான்.
	<br /><br />
	8. நான் வானத்திலிருந்து பிறக்கக்கேட்ட சத்தம் மறுபடியும் என்னுடனே பேசி: சமுத்திரத்தின்மேலும் பூமியின்மேலும் நிற்கிற தூதனுடைய கையிலிருக்கும் திறக்கப்பட்ட சிறு புஸ்தகத்தை நீ போய் வாங்கிக்கொள் என்று சொல்ல,
	<br /><br />
	9. நான் தூதனிடத்தில் போய்: அந்தச் சிறு புஸ்தகத்தை எனக்குத் தாரும் என்றேன். அதற்கு அவன்: நீ இதை வாங்கிப் புசி; இது உன் வயிற்றுக்குக் கசப்பாயிருக்கும், ஆகிலும் உன் வாய்க்கு இது தேனைப்போல மதுரமாயிருக்கும் என்றான்.
	<br /><br />
	10. நான் அந்தச் சிறு புஸ்தகத்தைத் தூதனுடைய கையிலிருந்து வாங்கி, அதைப் புசித்தேன்; என் வாய்க்கு அது தேனைப்போல மதுரமாயிருந்தது; நான் அதைப் புசித்தவுடனே என் வயிறு கசப்பாயிற்று.
	<br /><br />
	11. அப்பொழுது அவன் என்னை நோக்கி: நீ மறுபடியும் அநேக ஜனங்களையும், ஜாதிகளையும், பாஷைக்காரரையும், ராஜாக்களையுங்குறித்துத் தீர்க்கதரிசனஞ்சொல்லவேண்டும் என்றான்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h10>
*  *
			</h10>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation10