import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah40 = () => {
  const verseNumber = 40;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 40 </title>
	<meta
          name="description"
          content="Isaiah 40 | ஏசாயா 40 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. என் ஜனத்தை ஆற்றுங்கள், தேற்றுங்கள்;
	<br /><br />
	2. எருசலேமுடன் பட்சமாய்ப்பேசி, அதின் போர் முடிந்தது என்றும், அதின் அக்கிரமம் நிவிர்த்தியாயிற்று என்றும், அது தன் சகல பாவங்களினிமித்தமும் கர்த்தரின் கையில் இரட்டிப்பாய் அடைந்து தீர்ந்தது என்றும், அதற்குக் கூறுங்கள் என்று உங்கள் தேவன் சொல்லுகிறார்.
	<br /><br />
	3. கர்த்தருக்கு வழியை ஆயத்தப்படுத்துங்கள், அவாந்தரவெளியிலே நம்முடைய தேவனுக்குப் பாதையைச் செவ்வைபண்ணுங்கள் என்றும்,
	<br /><br />
	4. பள்ளமெல்லாம் உயர்த்தப்பட்டு, சகல மலையும் குன்றும் தாழ்த்தப்பட்டு, கோணலானது செவ்வையாகி, கரடுமுரடானவைச் சமமாக்கப்படும் என்றும்,
	<br /><br />
	5. கர்த்தரின் மகிமை வெளியரங்கமாகும், மாம்சமான யாவும் அதை ஏகமாய்க் காணும், கர்த்தரின் வாக்கு அதை உரைத்தது என்றும் வனாந்தரத்திலே கூப்பிடுகிற சத்தம் உண்டாயிற்று.
	<br /><br />
	6. பின்னும் கூப்பிட்டுச் சொல் என்று ஒரு சத்தம் உண்டாயிற்று; என்னத்தைக் கூப்பிட்டுச் சொல்வேன் என்றேன். அதற்கு: மாம்சமெல்லாம் புல்லைப்போலவும், அதின் மேன்மையெல்லாம் வெளியின் பூவைப்போலவும் இருக்கிறது.
	<br /><br />
	7. கர்த்தரின் ஆவி அதின்மேல் ஊதும்போது, புல் உலர்ந்து, பூ உதிரும்; ஜனமே புல்.
	<br /><br />
	8. புல் உலர்ந்து பூ உதிரும்; நமது தேவனுடைய வசனமோ என்றென்றைக்கும் நிற்கும் என்பதையே சொல் என்று உரைத்தது.
	<br /><br />
	9. சீயோன் என்னும் சுவிசேஷகியே, நீ உயர்ந்த பர்வதத்தில் ஏறு; எருசலேம் என்னும் சுவிசேஷகியே, நீ உரத்த சத்தமிட்டுக் கூப்பிடு, பயப்படாமல் சத்தமிட்டு, யூதா பட்டணங்களை நோக்கி: இதோ, உங்கள் தேவன் என்று கூறு.
	<br /><br />
	10. இதோ, கர்த்தராகிய ஆண்டவர் பராக்கிரமசாலியாக வருவார்; அவர் தமது புயத்தினால் அரசாளுவார்; இதோ, அவர் அளிக்கும் பலன் அவரோடேகூட வருகிறது; அவர் கொடுக்கும் பிரதிபலன் அவருடைய முகத்துக்கு முன்பாகச் செல்லுகிறது.
	<br /><br />
	11. மேய்ப்பனைப்போலத் தமது மந்தையை மேய்ப்பார்; ஆட்டுக்குட்டிகளைத் தமது புயத்தினால் சேர்த்து, தமது மடியிலே சுமந்து, கறவலாடுகளை மெதுவாய் நடத்துவார்.
	<br /><br />
	12. தண்ணீர்களைத் தமது கைப்பிடியால் அளந்து, வானங்களை ஜாணளவாய்ப் பிரமாணித்து, பூமியின் மண்ணை மரக்காலில் அடக்கி, பர்வதங்களைத் துலாக்கோலாலும், மலைகளைத் தராசாலும் நிறுத்தவர் யார்?
	<br /><br />
	13. கர்த்தருடைய ஆவியை அளவிட்டு, அவருக்கு ஆலோசனைக்காரனாயிருந்து, அவருக்குப் போதித்தவன் யார்?
	<br /><br />
	14. தமக்கு அறிவை உணர்த்தவும், தம்மை நியாயவழியிலே உபதேசிக்கவும், தமக்கு ஞானத்தைக் கற்றுக்கொடுக்கவும், தமக்கு விவேகத்தின் வழியை அறிவிக்கவும், அவர் யாரோடே ஆலோசனைபண்ணினார்?
	<br /><br />
	15. இதோ, ஜாதிகள் ஏற்றச்சாலில் தொங்கும் துளிபோலவும், தராசிலே படியும் தூசிபோலவும் எண்ணப்படுகிறார்கள்; இதோ, தீவுகளை ஒரு அணுவைப்போல் தூக்குகிறார்.
	<br /><br />
	16. லீபனோன் எரிக்கும் விறகுக்குப் போதாது; அதிலுள்ள மிருகஜீவன்கள் தகனபலிக்கும் போதாது.
	<br /><br />
	17. சகல ஜாதிகளும் அவருக்கு முன்பாக ஒன்றுமில்லை, அவர்கள் சூனியத்தில் சூனியமாகவும், மாயையாகவும் எண்ணப்படுகிறார்கள்.
	<br /><br />
	18. இப்படியிருக்க, தேவனை யாருக்கு ஒப்பிடுவீர்கள்? எந்தச் சாயலை அவருக்கு ஒப்பிடுவீர்கள்?
	<br /><br />
	19. கன்னான் ஒரு சுரூபத்தை வார்க்கிறான், தட்டான் பொன்தகட்டால் அதை மூடி, அதற்கு வெள்ளிச்சங்கிலிகளைப் பொருந்தவைக்கிறான்.
	<br /><br />
	20. அதற்குக் கொடுக்க வகையில்லாதவன் உளுத்துப்போகாத மரத்தைத் தெரிந்துகொண்டு, அசையாத ஒரு சுரூபத்தைச் செய்யும்படி நிபுணனான ஒரு தச்சனைத் தேடுகிறான்.
	<br /><br />
	21. நீங்கள் அறியீர்களா? நீங்கள் கேள்விப்படவில்லையா? ஆதிமுதல் உங்களுக்குத் தெரிவிக்கப்படவில்லையா? பூமி அஸ்திபாரப்பட்டதுமுதல் உணராதிருக்கிறீர்களா?
	<br /><br />
	22. அவர் பூமி உருண்டையின்மேல் வீற்றிருக்கிறவர்; அதின் குடிகள் வெட்டுக்கிளிகளைப்போல இருக்கிறார்கள்; அவர் வானங்களை மெல்லிய திரையாகப் பரப்பி, அவைகளைக் குடியிருக்கிறதற்கான கூடாரமாக விரிக்கிறார்.
	<br /><br />
	23. அவர் பிரபுக்களை மாயையாக்கி, பூமியின் நியாயாதிபதிகளை அவாந்தரமாக்குகிறார்.
	<br /><br />
	24. அவர்கள் திரும்ப நாட்டப்படுவதுமில்லை, விதைக்கப்படுவதுமில்லை; அவர்களுடைய அடிமரம் திரும்பப் பூமியிலே வேர்விடுவதுமில்லை; அவர்கள்மேல் அவர் ஊதவே பட்டுப்போவார்கள்; பெருங்காற்று அவர்களை ஒரு துரும்பைப்போல் அடித்துக்கொண்டுபோம்.
	<br /><br />
	25. இப்படியிருக்க, என்னை யாருக்கு ஒப்பிடுவீர்கள்? எனக்கு யாரை நிகராக்குவீர்கள்? என்று பரிசுத்தர் சொல்லுகிறார்.
	<br /><br />
	26. உங்கள் கண்களை ஏறெடுத்துப்பாருங்கள்; அவைகளைச் சிருஷ்டித்தவர் யார்? அவர் அவைகளின் சேனையை இலக்கத்திட்டமாகப் புறப்படப்பண்ணி, அவைகளையெல்லாம் பேர்பேராக அழைக்கிறவராமே; அவருடைய மகா பெலத்தினாலும், அவருடைய மகா வல்லமையினாலும், அவைகளில் ஒன்றும் குறையாமலிருக்கிறது.
	<br /><br />
	27. யாக்கோபே, இஸ்ரவேலே: என் வழி கர்த்தருக்கு மறைவாயிற்று என்றும், என் நியாயம் என் தேவனிடத்தில் எட்டாமல் போகிறது என்றும் நீ சொல்வானேன்?
	<br /><br />
	28. பூமியின் கடையாந்தரங்களைச் சிருஷ்டித்த கர்த்தராகிய அநாதிதேவன் சோர்ந்துபோவதுமில்லை, இளைப்படைவதுமில்லை; இதை நீ அறியாயோ? இதை நீ கேட்டதில்லையோ? அவருடைய புத்தி ஆராய்ந்து முடியாதது.
	<br /><br />
	29. சோர்ந்துபோகிறவனுக்கு அவர் பெலன் கொடுத்து, சத்துவமில்லாதவனுக்குச் சத்துவத்தைப் பெருகப்பண்ணுகிறார்.
	<br /><br />
	30. இளைஞர் இளைப்படைந்து சோர்ந்துபோவார்கள், வாலிபரும் இடறிவிழுவார்கள்.
	<br /><br />
	31. கர்த்தருக்குக் காத்திருக்கிறவர்களோ புதுப்பெலன் அடைந்து, கழுகுகளைப்போலச் செட்டைகளை அடித்து எழும்புவார்கள்; அவர்கள் ஓடினாலும் இளைப்படையார்கள், நடந்தாலும் சோர்ந்துபோகார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah40