import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 10 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இவைகளுக்குப்பின்பு கர்த்தர் வேறே எழுபதுபேரை நியமித்து, தாம் போகும் சகல பட்டணங்களுக்கும் இடங்களுக்கும் அவர்களைத் தமக்கு முன்னே இரண்டிரண்டு பேராக அனுப்பினார்.
	<br /><br />
	2. அப்பொழுது அவர் அவர்களை நோக்கி: அறுப்பு மிகுதி, வேலையாட்களோ கொஞ்சம்; ஆகையால் அறுப்புக்கு எஜமான் தமது அறுப்புக்கு வேலையாட்களை அனுப்பும்படி அவரை வேண்டிக்கொள்ளுங்கள்.
	<br /><br />
	3. புறப்பட்டுப்போங்கள்; ஆட்டுக்குட்டிகளை ஓநாய்களுக்குள்ளே அனுப்புகிறதுபோல, இதோ, நான் உங்களை அனுப்புகிறேன்.
	<br /><br />
	4. பணப்பையையும் சாமான் பையையும் பாதரட்சைகளையும் கொண்டுபோகவேண்டாம்; வழியிலே ஒருவரையும் வினவவும் வேண்டாம்.
	<br /><br />
	5. ஒரு வீட்டில் பிரவேசிக்கிறபோது: இந்த வீட்டுக்குச் சமாதானம் உண்டாவதாகவென்று முதலாவது சொல்லுங்கள்.
	<br /><br />
	6. சமாதான பாத்திரன் அங்கே இருந்தால், நீங்கள் கூறின சமாதானம் அவனிடத்தில் தங்கும், இல்லாதிருந்தால் அது உங்களிடத்திற்குத் திரும்பிவரும்.
	<br /><br />
	7. அந்த வீட்டிலேதானே நீங்கள் தங்கியிருந்து, அவர்கள் கொடுக்கிறவைகளைப் புசித்துக் குடியுங்கள்; வேலையாள் தன் கூலிக்குப் பாத்திரனாயிருக்கிறான். வீட்டுக்கு வீடு போகாதிருங்கள்.
	<br /><br />
	8. ஒரு பட்டணத்தில் நீங்கள் பிரவேசிக்கிறபொழுது, ஜனங்கள் உங்களை ஏற்றுக்கொண்டால் அவர்கள் உங்கள்முன் வைக்கிறவைகளை நீங்கள் புசித்து,
	<br /><br />
	9. அவ்விடத்திலுள்ள பிணியாளிகளைச் சொஸ்தமாக்கி: தேவனுடைய ராஜ்யம் உங்களுக்குச் சமீபமாய் வந்திருக்கிறது என்று அவர்களுக்குச் சொல்லுங்கள்.
	<br /><br />
	10. யாதொரு பட்டணத்தில் நீங்கள் பிரவேசிக்கிறபொழுது, ஜனங்கள் உங்களை ஏற்றுக்கொள்ளாவிட்டால் அதின் வீதிகளிலே நீங்கள் போய்:
	<br /><br />
	11. எங்களில் ஒட்டின உங்கள் பட்டணத்தின் தூசியையும் உங்களுக்கு விரோதமாய்த் துடைத்துப்போடுகிறோம்; ஆயினும் தேவனுடைய ராஜ்யம் உங்களுக்குச் சமீபமாய் வந்திருக்கிறதென்பதை அறிந்துகொள்வீர்களாக என்று சொல்லுங்கள்.
	<br /><br />
	12. அந்தப் பட்டணத்திற்கு நேரிடுவதைப்பார்க்கிலும் அந்த நாளிலே சோதோம் நாட்டிற்கு நேரிடுவது இலகுவாயிருக்கும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	13. கோராசீன் பட்டணமே, உனக்கு ஐயோ, பெத்சாயிதா பட்டணமே, உனக்கு ஐயோ, உங்களில் செய்யப்பட்ட பலத்த செய்கைகள் தீருவிலும் சீதோனிலும் செய்யப்பட்டிருந்ததானால், அப்பொழுதே இரட்டுடுத்தி, சாம்பலில் உட்கார்ந்து, மனந்திரும்பியிருப்பார்கள்.
	<br /><br />
	14. நியாயத்தீர்ப்புநாளில் உங்களுக்கு நேரிடுவதைப்பார்க்கிலும், தீருவுக்கும் சீதோனுக்கும் நேரிடுவது இலகுவாயிருக்கும்.
	<br /><br />
	15. வானபரியந்தம் உயர்த்தப்பட்ட கப்பர்நகூமே, நீ பாதாளபரியந்தம் தாழ்த்தப்படுவாய் என்று சொல்லி,
	<br /><br />
	16. சீஷரை நோக்கி: உங்களுக்குச் செவிகொடுக்கிறவன் எனக்குச் செவிகொடுக்கிறான், உங்களை அசட்டைபண்ணுகிறவன் என்னை அசட்டைபண்ணுகிறான், என்னை அசட்டைபண்ணுகிறவன் என்னை அனுப்பினவரை அசட்டைபண்ணுகிறான் என்றார்.
	<br /><br />
	17. பின்பு அந்த எழுபதுபேரும் சந்தோஷத்தோடே திரும்பிவந்து: ஆண்டவரே, உம்முடைய நாமத்தினாலே பிசாசுகளும் எங்களுக்குக் கீழ்ப்படிகிறது என்றார்கள்.
	<br /><br />
	18. அவர்களை அவர் நோக்கி: சாத்தான் மின்னலைப்போல வானத்திலிருந்து விழுகிறதைக் கண்டேன்.
	<br /><br />
	19. இதோ, சர்ப்பங்களையும் தேள்களையும் மிதிக்கவும், சத்துருவினுடைய சகல வல்லமையையும் மேற்கொள்ளவும் உங்களுக்கு அதிகாரங்கொடுக்கிறேன்; ஒன்றும் உங்களைச் சேதப்படுத்தமாட்டாது.
	<br /><br />
	20. ஆகிலும், ஆவிகள் உங்களுக்குக் கீழ்ப்படிகிறதற்காக நீங்கள் சந்தோஷப்படாமல், உங்கள் நாமங்கள் பரலோகத்தில் எழுதியிருக்கிறதற்காகச் சந்தோஷப்படுங்கள் என்றார்.
	<br /><br />
	21. அந்த வேளையில் இயேசு ஆவியிலே களிகூர்ந்து: பிதாவே! வானத்துக்கும் பூமிக்கும் ஆண்டவரே! இவைகளை நீர் ஞானிகளுக்கும் கல்விமான்களுக்கும் மறைத்து, பாலகருக்கு வெளிப்படுத்தினபடியால் உம்மை ஸ்தோத்திரிக்கிறேன்; ஆம், பிதாவே! இப்படிச் செய்வது உம்முடைய திருவுளத்துக்குப் பிரியமாயிருந்தது.
	<br /><br />
	22. சகலமும் என் பிதாவினால் எனக்கு ஒப்புக்கொடுக்கப்பட்டிருக்கிறது; பிதா தவிர வேறொருவனும் குமாரன் இன்னாரென்று அறியான், குமாரனும், குமாரன் அவரை எவனுக்கு வெளிப்படுத்தச் சித்தமாயிருக்கிறாரோ அவனுந்தவிர, வேறொருவனும் பிதா இன்னாரென்று அறியான் என்றார்.
	<br /><br />
	23. பின்பு தமது சீஷரிடத்தில் திரும்பி, தனித்து அவர்களை நோக்கி: நீங்கள் காண்கிறவைகளைக் காணுங் கண்கள் பாக்கியமுள்ளவைகள்.
	<br /><br />
	24. அநேக தீர்க்கதரிசிகளும் ராஜாக்களும் நீங்கள் காண்கிறவைகளைக் காணவும், நீங்கள் கேட்கிறவைகளைக் கேட்கவும் விரும்பியும், காணாமலும் கேளாமலும் போனார்கள் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	25. அப்பொழுது நியாயசாஸ்திரி ஒருவன் எழுந்திருந்து, அவரைச் சோதிக்கும்படி: போதகரே, நித்திய ஜீவனைச் சுதந்தரித்துக்கொள்ளும்படிக்கு நான் என்ன செய்யவேண்டும் என்று கேட்டான்.
	<br /><br />
	26. அதற்கு அவர்: நியாயப்பிரமாணத்தில் என்ன எழுதியிருக்கிறது? நீ வாசித்திருக்கிறது என்ன என்றார்.
	<br /><br />
	27. அவன் பிரதியுத்தரமாக: உன் தேவனாகிய கர்த்தரிடத்தில் உன் முழு இருதயத்தோடும் உன் முழு ஆத்துமாவோடும் உன் முழுப் பலத்தோடும் உன் முழுச்சிந்தையோடும் அன்புகூர்ந்து, உன்னிடத்தில் அன்புகூருவதுபோலப் பிறனிடத்திலும் அன்புகூருவாயாக என்று எழுதியிருக்கிறது என்றான்.
	<br /><br />
	28. அவர் அவனை நோக்கி: நிதானமாய் உத்தரவு சொன்னாய்; அப்படியே செய், அப்பொழுது பிழைப்பாய் என்றார்.
	<br /><br />
	29. அவன் தன்னை நீதிமான் என்று காண்பிக்க மனதாய் இயேசுவை நோக்கி: எனக்குப் பிறன் யார் என்று கேட்டான்.
	<br /><br />
	30. இயேசு பிரதியுத்தரமாக: ஒரு மனுஷன் எருசலேமிலிருந்து எரிகோவுக்குப் போகையில் கள்ளர் கையில் அகப்பட்டான்; அவர்கள் அவன் வஸ்திரங்களை உரிந்துகொண்டு, அவனைக் காயப்படுத்தி, குற்றுயிராக விட்டுப் போனார்கள்.
	<br /><br />
	31. அப்பொழுது தற்செயலாய் ஒரு ஆசாரியன் அந்த வழியே வந்து, அவனைக் கண்டு, பக்கமாய் விலகிப்போனான்.
	<br /><br />
	32. அந்தப்படியே ஒரு லேவியனும் அந்த இடத்துக்கு வந்து, அவனைக் கண்டு, பக்கமாய் விலகிப்போனான்.
	<br /><br />
	33. பின்பு சமாரியன் ஒருவன் பிரயாணமாய் வருகையில், அவனைக் கண்டு, மனதுருகி,
	<br /><br />
	34. கிட்ட வந்து, அவனுடைய காயங்களில் எண்ணெயும் திராட்சரசமும் வார்த்து, காயங்களைக் கட்டி, அவனைத் தன் சுயவாகனத்தின்மேல் ஏற்றி, சத்திரத்துக்குக் கொண்டுபோய், அவனைப் பராமரித்தான்.
	<br /><br />
	35. மறுநாளிலே தான் புறப்படும்போது இரண்டு பணத்தை எடுத்து, சத்திரத்தான் கையில் கொடுத்து: நீ இவனை விசாரித்துக்கொள், அதிகமாய் ஏதாகிலும் இவனுக்காகச் செலவழித்தால், நான் திரும்பிவரும்போது அதை உனக்குத் தருவேன் என்றான்.
	<br /><br />
	36. இப்படியிருக்க, கள்ளர்கையில் அகப்பட்டவனுக்கு இந்த மூன்றுபேரில் எவன் பிறனாயிருந்தான்? உனக்கு எப்படித் தோன்றுகிறது என்றார்.
	<br /><br />
	37. அதற்கு அவன்: அவனுக்கு இரக்கஞ்செய்தவனே என்றான். அப்பொழுது இயேசு அவனை நோக்கி: நீயும் போய் அந்தப்படியே செய் என்றார்.
	<br /><br />
	38. பின்பு, அவர்கள் பிரயாணமாய்ப் போகையில், அவர் ஒரு கிராமத்தில் பிரவேசித்தார். அங்கே மார்த்தாள் என்னும் பேர்கொண்ட ஒரு ஸ்திரீ அவரைத் தன் வீட்டிலே ஏற்றுக்கொண்டாள்.
	<br /><br />
	39. அவளுக்கு மரியாள் என்னப்பட்ட ஒரு சகோதரி இருந்தாள்; அவள் இயேசுவின் பாதத்தருகே உட்கார்ந்து, அவருடைய வசனத்தைக் கேட்டுக்கொண்டிருந்தாள்.
	<br /><br />
	40. மார்த்தாளோ பற்பல வேலைகளைச் செய்வதில் மிகவும் வருத்தமடைந்து, அவரிடத்தில் வந்து: ஆண்டவரே, நான் தனியே வேலைசெய்யும்படி என் சகோதரி என்னை விட்டுவந்திருக்கிறதைக் குறித்து உமக்குக் கவையில்லையா? எனக்கு உதவிசெய்யும்படி அவளுக்குச் சொல்லும் என்றாள்.
	<br /><br />
	41. இயேசு அவளுக்குப் பிரதியுத்தரமாக: மார்த்தாளே, மார்த்தாளே, நீ அநேக காரியங்களைக்குறித்துக் கவலைப்பட்டுக் கலங்குகிறாய்.
	<br /><br />
	42. தேவையானது ஒன்றே, மரியாள் தன்னை விட்டெடுபடாத நல்ல பங்கைத் தெரிந்துகொண்டாள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	Jesus Sends Out the Seventy-Two
</h2>
<p>
	<br />
	1 After this the Lord appointed seventy-two others and sent them two by two ahead of him to every town and place where he was about to go.
	<br />
	2 He told them, &ldquo;The harvest is plentiful, but the workers are few. Ask the Lord of the harvest, therefore, to send out workers into his harvest field.
	<br />
	3 Go! I am sending you out like lambs among wolves.
	<br />
	4 Do not take a purse or bag or sandals; and do not greet anyone on the road.
	<br />
	5 &ldquo;When you enter a house, first say, &lsquo;Peace to this house.&rsquo;
	<br />
	6 If someone who promotes peace is there, your peace will rest on them; if not, it will return to you.
	<br />
	7 Stay there, eating and drinking whatever they give you, for the worker deserves his wages. Do not move around from house to house.
	<br />
	8 &ldquo;When you enter a town and are welcomed, eat what is offered to you.
	<br />
	9 Heal the sick who are there and tell them, &lsquo;The kingdom of God has come near to you.&rsquo;
	<br />
	10 But when you enter a town and are not welcomed, go into its streets and say,
	<br />
	11 &lsquo;Even the dust of your town we wipe from our feet as a warning to you. Yet be sure of this: The kingdom of God has come near.&rsquo;
	<br />
	12 I tell you, it will be more bearable on that day for Sodom than for that town.
	<br />
	13 &ldquo;Woe to you, Chorazin! Woe to you, Bethsaida! For if the miracles that were performed in you had been performed in Tyre and Sidon, they would have repented long ago, sitting in sackcloth and ashes.
	<br />
	14 But it will be more bearable for Tyre and Sidon at the judgment than for you.
	<br />
	15 And you, Capernaum, will you be lifted to the heavens? No, you will go down to Hades.
	<br />
	16 &ldquo;Whoever listens to you listens to me; whoever rejects you rejects me; but whoever rejects me rejects him who sent me.&rdquo;
	<br />
	17 The seventy-two returned with joy and said, &ldquo;Lord, even the demons submit to us in your name.&rdquo;
	<br />
	18 He replied, &ldquo;I saw Satan fall like lightning from heaven.
	<br />
	19 I have given you authority to trample on snakes and scorpions and to overcome all the power of the enemy; nothing will harm you.
	<br />
	20 However, do not rejoice that the spirits submit to you, but rejoice that your names are written in heaven.&rdquo;
	<br />
	21 At that time Jesus, full of joy through the Holy Spirit, said, &ldquo;I praise you, Father, Lord of heaven and earth, because you have hidden these things from the wise and learned, and revealed them to little children. Yes, Father, for this is what you were pleased to do.
	<br />
	22 &ldquo;All things have been committed to me by my Father. No one knows who the Son is except the Father, and no one knows who the Father is except the Son and those to whom the Son chooses to reveal him.&rdquo;
	<br />
	23 Then he turned to his disciples and said privately, &ldquo;Blessed are the eyes that see what you see.
	<br />
	24 For I tell you that many prophets and kings wanted to see what you see but did not see it, and to hear what you hear but did not hear it.&rdquo;
</p>
<h2>
	<br />
	The Parable of the Good Samaritan
</h2>
<p>
	<br />
	25 On one occasion an expert in the law stood up to test Jesus. &ldquo;Teacher,&rdquo; he asked, &ldquo;what must I do to inherit eternal life?&rdquo;
	<br />
	26 &ldquo;What is written in the Law?&rdquo; he replied. &ldquo;How do you read it?&rdquo;
	<br />
	27 He answered, &ldquo; &lsquo;Love the Lord your God with all your heart and with all your soul and with all your strength and with all your mind&rsquo; ; and, &lsquo;Love your neighbor as yourself.&rsquo; &rdquo;
	<br />
	28 &ldquo;You have answered correctly,&rdquo; Jesus replied. &ldquo;Do this and you will live.&rdquo;
	<br />
	29 But he wanted to justify himself, so he asked Jesus, &ldquo;And who is my neighbor?&rdquo;
	<br />
	30 In reply Jesus said: &ldquo;A man was going down from Jerusalem to Jericho, when he was attacked by robbers. They stripped him of his clothes, beat him and went away, leaving him half dead.
	<br />
	31 A priest happened to be going down the same road, and when he saw the man, he passed by on the other side.
	<br />
	32 So too, a Levite, when he came to the place and saw him, passed by on the other side.
	<br />
	33 But a Samaritan, as he traveled, came where the man was; and when he saw him, he took pity on him.
	<br />
	34 He went to him and bandaged his wounds, pouring on oil and wine. Then he put the man on his own donkey, brought him to an inn and took care of him.
	<br />
	35 The next day he took out two denarii and gave them to the innkeeper. &lsquo;Look after him,&rsquo; he said, &lsquo;and when I return, I will reimburse you for any extra expense you may have.&rsquo;
	<br />
	36 &ldquo;Which of these three do you think was a neighbor to the man who fell into the hands of robbers?&rdquo;
	<br />
	37 The expert in the law replied, &ldquo;The one who had mercy on him.&rdquo; Jesus told him, &ldquo;Go and do likewise.&rdquo;
</p>
<h2>
	<br />
	At the Home of Martha and Mary
</h2>
<p>
	<br />
	38 As Jesus and his disciples were on their way, he came to a village where a woman named Martha opened her home to him.
	<br />
	39 She had a sister called Mary, who sat at the Lord&rsquo;s feet listening to what he said.
	<br />
	40 But Martha was distracted by all the preparations that had to be made. She came to him and asked, &ldquo;Lord, don&rsquo;t you care that my sister has left me to do the work by myself? Tell her to help me!&rdquo;
	<br />
	41 &ldquo;Martha, Martha,&rdquo; the Lord answered, &ldquo;you are worried and upset about many things,
	<br />
	42 but few things are needed&mdash;or indeed only one. Mary has chosen what is better, and it will not be taken away from her.&rdquo;
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke10