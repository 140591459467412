import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial37 = () => {
  const verseNumber = 37;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 37 </title>
	<meta
          name="description"
          content="Ezekiel 37 | எசேக்கியேல்  37 |
          Ezekiel 37 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய கை என்மேல் அமர்ந்து, கர்த்தர் என்னை ஆவிக்குள்ளாக்கி வெளியே கொண்டுபோய், எலும்புகள் நிறைந்த ஒரு பள்ளத்தாக்கின் நடுவில் நிறுத்தி,
	<br /><br />
	2. என்னை அவைகளின் அருகே சுற்றி நடக்கப்பண்ணினார்; இதோ, பள்ளத்தாக்கின் வெட்டவெளியிலே அந்த எலும்புகள் மகா திரளாய்க்கிடந்தது; அவைகள் மிகவும் உலர்ந்ததுமாயிருந்தது.
	<br /><br />
	3. அவர் என்னை நோக்கி: மனுபுத்திரனே, இந்த எலும்புகள் உயிரடையுமா என்று கேட்டார்; அதற்கு நான்: கர்த்தராகிய ஆண்டவரே, தேவரீர் அதை அறிவீர் என்றேன்.
	<br /><br />
	4. அப்பொழுது அவர்: நீ இந்த எலும்புகளைக்குறித்துத் தீர்க்கதரிசனம் உரைத்து, அவைகளைப்பார்த்துச் சொல்லவேண்டியது என்னவென்றால்: உலர்ந்த எலும்புகளே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	5. கர்த்தராகிய ஆண்டவர் இந்த எலும்புகளை நோக்கி: இதோ, நான் உங்களுக்குள் ஆவியைப் பிரவேசிக்கப்பண்ணுவேன், அப்பொழுது உயிரடைவீர்கள்.
	<br /><br />
	6. நான் உங்கள்மேல் நரம்புகளைச் சேர்த்து, உங்கள்மேல் மாம்சத்தை உண்டாக்கி, உங்களைத் தோலினால் மூடி, உங்களில் ஆவியைக் கட்டளையிடுவேன்; அப்பொழுது நீங்கள் உயிரடைந்து, நான் கர்த்தர் என்று அறிந்துகொள்வீர்களென்று உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	7. எனக்குக் கட்டளையிட்டபடியே நான் தீர்க்கதரிசனம் உரைத்தேன்; நான் தீர்க்கதரிசனம் உரைக்கையில் ஒரு இரைச்சல் உண்டாயிற்று; இதோ, அசைவுண்டாகி, ஒவ்வொரு எலும்பும் தன்தன் எலும்போடே சேர்ந்துகொண்டது.
	<br /><br />
	8. நான் பார்த்துக்கொண்டிருக்கையில், இதோ, அவைகள்மேல் நரம்புகளும் மாம்சமும் உண்டாயிற்று, மேற்புறமெங்கும் தோலினால் மூடப்பட்டது; ஆனாலும் அவைகளில் ஆவி இல்லாதிருந்தது.
	<br /><br />
	9. அப்பொழுது அவர் என்னைப்பார்த்து: நீ ஆவியை நோக்கித் தீர்க்கதரிசனம் உரை; மனுபுத்திரனே, நீ தீர்க்கதரிசனம் உரைத்து, ஆவியை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், ஆவியே, நீ காற்றுத்திசை நான்கிலுமிருந்து வந்து, கொலையுண்ட இவர்கள் உயிரடையும்படிக்கு இவர்கள்மேல் ஊது என்கிறார் என்று சொல் என்றார்.
	<br /><br />
	10. எனக்குக் கற்பிக்கப்பட்டபடி நான் தீர்க்கதரிசனம் உரைத்தேன்; அப்பொழுது ஆவி அவர்களுக்குள் பிரவேசிக்க, அவர்கள் உயிரடைந்து, காலூன்றி, மகா பெரிய சேனையாய் நின்றார்கள்.
	<br /><br />
	11. அப்பொழுது அவர் என்னை நோக்கி: மனுபுத்திரனே, இந்த எலும்புகள் இஸ்ரவேல் வம்சத்தார் அனைவருமே; இதோ, அவர்கள் எங்கள் எலும்புகள் உலர்ந்துபோயிற்று; எங்கள் நம்பிக்கை அற்றுப்போயிற்று; நாங்கள் அறுப்புண்டுபோகிறோம் என்கிறார்கள்.
	<br /><br />
	12. ஆகையால் நீ தீர்க்கதரிசனம் உரைத்து, அவர்களோடே சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், இதோ, என் ஜனங்களே, நான் உங்கள் பிரேதக்குழிகளைத் திறந்து, உங்களை உங்கள் பிரேதக்குழிகளிலிருந்து வெளிப்படவும், உங்களை இஸ்ரவேல் தேசத்துக்கு வரவும்பண்ணுவேன்.
	<br /><br />
	13. என் ஜனங்களே, நான் உங்கள் பிரேதக்குழிகளைத் திறந்து, உங்களை உங்கள் பிரேதக்குழிகளிலிருந்து வெளிப்படப்பண்ணும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	14. என் ஆவியை உங்களுக்குள் வைப்பேன்; நீங்கள் உயிரடைவீர்கள்; நான் உங்களை உங்கள் தேசத்தில் வைப்பேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்; இதைச் சொன்னேன், இதைச் செய்வேன் என்று கர்த்தர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	15. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	16. மனுபுத்திரனே, நீ ஒரு கோலை எடுத்து, அதிலே யூதாவுக்கும் அதைச் சேர்ந்த இஸ்ரவேல் புத்திரருக்கும் அடுத்தது என்று எழுதி; பின்பு வேறொரு கோலை எடுத்து, அதிலே எப்பிராயீமுக்கும் அதைச்சேர்ந்த இஸ்ரவேல் வம்சத்தார் அனைவருக்கும் அடுத்த யோசேப்பின் கோலென்று எழுதி,
	<br /><br />
	17. அவைகளை ஒரே கோலாகும்படி ஒன்றோடொன்று இசையச்செய், அவைகள் உன் கையில் ஒன்றாகும்.
	<br /><br />
	18. இவைகளின் பொருள் இன்னதென்று எங்களுக்கு அறிவிக்கமாட்டீரோ என்று உன் ஜனத்தின் புத்திரர் உன்னிடத்தில் கேட்டால்,
	<br /><br />
	19. நீ அவர்களை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், இதோ, எப்பிராயீமுக்கும் அதைச்சேர்ந்த இஸ்ரவேல் கோத்திரங்களுக்கும் அடுத்த யோசேப்பின் கோலை எடுத்து, அதை யூதாவின் கோலோடே சேர்த்து, அவைகளை ஒரே கோலாக்குவேன்; அவைகள் என் கையில் ஒன்றாகும் என்கிறார் என்று சொல்.
	<br /><br />
	20. சொல்லும்போது, நீ எழுதின கோல்கள் அவர்களுடைய கண்களுக்கு முன்பாக உன் கையில் இருக்கவேண்டும்.
	<br /><br />
	21. நீ அவர்களை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், இதோ, நான் இஸ்ரவேல் வம்சத்தாரை அவர்கள் போயிருக்கும் ஜாதிகளிடத்திலிருந்து அழைத்து, சுற்றிலுமிருந்து அவர்களைச் சேர்த்து, அவர்களை அவர்கள் சுயதேசத்திலே வரப்பண்ணி,
	<br /><br />
	22. அவர்களை இஸ்ரவேலின் மலைகளாகிய தேசத்திலே ஒரே ஜாதியாக்குவேன்; ஒரே ராஜா அவர்கள் எல்லாருக்கும் ராஜாவாக இருப்பார்; அவர்கள் இனி இரண்டு ஜாதிகளாக இருப்பதில்லை; அவர்கள் இனி இரண்டு ராஜ்யங்களாகப் பிரிவதுமில்லை.
	<br /><br />
	23. அவர்கள் இனித் தங்கள் நரகலான விக்கிரகங்களினாலும் தங்கள் அருவருப்புகளினாலும் தங்களுடைய சகல மீறுதல்களினாலும் தங்களைத் தீட்டுப்படுத்துவதுமில்லை; அவர்கள் குடியிருந்து பாவஞ்செய்த எல்லா இடங்களிலிருந்தும் நான் அவர்களை நீங்கலாக்கி இரட்சித்து, அவர்களைச் சுத்தம்பண்ணுவேன்; அப்பொழுது அவர்கள் என் ஜனமாயிருப்பார்கள், நான் அவர்கள் தேவனாயிருப்பேன்.
	<br /><br />
	24. என் தாசனாகிய தாவீது என்பவர் அவர்கள்மேல் ராஜாவாக இருப்பார்; அவர்கள் எல்லாருக்கும் ஒரே மேய்ப்பர் இருப்பார்; அப்பொழுது அவர்கள் என் நியாயங்களில் நடந்து, என் கட்டளைகளைக் கைக்கொண்டு, அவைகளின்படியே செய்து,
	<br /><br />
	25. நான் என் தாசனாகிய யாக்கோபுக்குக் கொடுத்ததும், உங்கள் பிதாக்கள் குடியிருந்ததுமான தேசத்திலே குடியிருப்பார்கள்; அவர்களும் அவர்கள் பிள்ளைகளும் அவர்களுடைய பிள்ளைகளின் பிள்ளைகளும் அதிலே என்றென்றைக்கும் குடியிருப்பார்கள்; என் தாசனாகிய தாவீது என்பவர் என்றென்றைக்கும் அவர்களுக்கு அதிபதியாயிருப்பார்.
	<br /><br />
	26. நான் அவர்களோடே சமாதான உடன்படிக்கை செய்வேன்; அது அவர்களுக்கு நித்திய உடன்படிக்கையாயிருக்கும்; நான் அவர்களை நிலைப்படுத்தி, அவர்களை வர்த்திக்கப்பண்ணி, அவர்கள் நடுவிலே என் பரிசுத்த ஸ்தலத்தை என்றென்றைக்கும் ஸ்தாபிப்பேன்.
	<br /><br />
	27. என் வாசஸ்தலம் அவர்களிடத்தில் இருக்கும், நான் அவர்கள் தேவனாயிருப்பேன், அவர்கள் என் ஜனமாயிருப்பார்கள்.
	<br /><br />
	28. அப்படியே என் பரிசுத்த ஸ்தலம் அவர்கள் நடுவிலே என்றென்றைக்கும் இருக்கும்போது, நான் இஸ்ரவேலைப் பரிசுத்தம்பண்ணுகிற கர்த்தர் என்று ஜாதிகள் அறிந்துகொள்வார்கள் என்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial37