import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 8 </title>
	<meta
          name="description"
          content="Mark 8 | மாற்கு 8 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்த நாட்களிலே திரளான ஜனங்கள் கூடிவந்திருக்கையில், அவர்கள் சாப்பிடுகிறதற்கு ஒன்றுமில்லாதபோது, இயேசு தம்முடைய சீஷரை அழைத்து:
	<br /><br />
	2. ஜனங்களுக்காகப் பரிதபிக்கிறேன், இவர்கள் இப்பொழுது என்னிடத்தில் தங்கியிருந்த மூன்றுநாளாய்ச் சாப்பிட ஒன்றுமில்லாதிருக்கிறார்கள்.
	<br /><br />
	3. இவர்களில் சிலர் தூரத்திலிருந்து வந்தவர்களாகையால், நான் இவர்களைப் பட்டினியாய் வீட்டிற்கு அனுப்பிவிட்டால் வழியில் சோர்ந்துபோவார்களே என்றார்.
	<br /><br />
	4. அதற்கு அவருடைய சீஷர்கள்: இந்த வனாந்தரத்திலே ஒருவன் எங்கேயிருந்து அப்பங்களைக் கொண்டுவந்து இத்தனை பேர்களைத் திருப்தியாக்கக்கூடும் என்றார்கள்.
	<br /><br />
	5. அதற்கு அவர்: உங்களிடத்தில் எத்தனை அப்பங்கள் உண்டு என்று கேட்டார். அவர்கள்: ஏழு அப்பங்கள் உண்டு என்றார்கள்.
	<br /><br />
	6. அப்பொழுது அவர் ஜனங்களைத் தரையிலே பந்தியிருக்கக் கட்டளையிட்டு, அந்த ஏழு அப்பங்களையும் எடுத்து, ஸ்தோத்திரம் பண்ணி, பிட்டு, அவர்களுக்குப் பரிமாறும்படி சீஷர்களிடத்தில் கொடுத்தார்; அவர்கள் ஜனங்களுக்குப் பரிமாறினார்கள்.
	<br /><br />
	7. சில சிறுமீன்களும் அவர்களிடத்தில் இருந்தது; அவர் அவைகளையும் ஆசீர்வதித்து அவர்களுக்குப் பரிமாறும்படி சொன்னார்.
	<br /><br />
	8. அவர்கள் சாப்பிட்டுத் திருப்தியடைந்தார்கள்; மீதியான துணிக்கைகளை ஏழு கூடைநிறைய எடுத்தார்கள்.
	<br /><br />
	9. சாப்பிட்டவர்கள் ஏறக்குறைய நாலாயிரம்பேராயிருந்தார்கள். பின்பு அவர் அவர்களை அனுப்பிவிட்டார்.
	<br /><br />
	10. உடனே அவர் தம்முடைய சீஷரோடேகூடப் படவில் ஏறி, தல்மனூத்தாவின் எல்லைகளில் வந்தார்.
	<br /><br />
	11. அப்பொழுது பரிசேயர் வந்து அவரோடே தர்க்கிக்கத்தொடங்கி, அவரைச் சோதிக்கும்படி, வானத்திலிருந்து ஒரு அடையாளத்தைக் காண்பிக்கவேண்டும் என்று கேட்டார்கள்.
	<br /><br />
	12. அவர் தம்முடைய ஆவியில் பெருமூச்சுவிட்டு: இந்தச் சந்ததியார் அடையாளம் தேடுகிறதென்ன? இந்தச் சந்ததியாருக்கு ஒரு அடையாளமும் கொடுக்கப்படுவதில்லையென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்று சொல்லி,
	<br /><br />
	13. அவர்களை விட்டு மறுபடியும் படவில் ஏறி, அக்கரைக்குப் போனார்.
	<br /><br />
	14. சீஷர்கள் அப்பங்களைக் கொண்டுவர மறந்துபோனார்கள்; படவிலே அவர்களிடத்தில் ஒரு அப்பம் மாத்திரம் இருந்தது.
	<br /><br />
	15. அவர் அவர்களை நோக்கி: நீங்கள் பரிசேயருடைய புளித்தமாவைக்குறித்தும் ஏரோதின் புளித்தமாவைக்குறித்தும் எச்சரிக்கையாயிருங்கள் என்று கற்பித்தார்.
	<br /><br />
	16. அதற்கு அவர்கள்: நம்மிடத்தில் அப்பங்கள் இல்லாதபடியால் இப்படிச் சொல்லுகிறார் என்று தங்களுக்குள்ளே யோசனைபண்ணிக்கொண்டார்கள்.
	<br /><br />
	17. இயேசு அதை அறிந்து, அவர்களை நோக்கி: உங்களிடத்தில் அப்பங்கள் இல்லாதபடியினால் நீங்கள் யோசனைபண்ணுகிறதென்ன? இன்னும் சிந்தியாமலும் உணராமலும் இருக்கிறீர்களா? இன்னும் உங்கள் இருதயம் கடினமாயிருக்கிறதா?
	<br /><br />
	18. உங்களுக்குக் கண்களிருந்தும் காணாதிருக்கிறீர்களா? காதுகளிருந்தும் கேளாதிருக்கிறீர்களா? நினைவுகூராமலுமிருக்கிறீர்களா?
	<br /><br />
	19. நான் ஐந்து அப்பங்களை ஐயாயிரம் பேருக்குப் பங்கிட்டபோது, மீதியான துணிக்கைகளை எத்தனை கூடைநிறைய எடுத்தீர்கள் என்று கேட்டார். பன்னிரண்டு என்றார்கள்.
	<br /><br />
	20. நான் ஏழு அப்பங்களை நாலாயிரம் பேருக்குப் பங்கிட்டபோது, மீதியான துணிக்கைகளை எத்தனை கூடைநிறைய எடுத்தீர்கள் என்று கேட்டார். ஏழு என்றார்கள்.
	<br /><br />
	21. அப்படியானால், நீங்கள் உணராதிருக்கிறது எப்படி என்றார்.
	<br /><br />
	22. பின்பு அவர் பெத்சாயிதா ஊருக்கு வந்தார்; அப்பொழுது ஒரு குருடனை அவரிடத்தில் கொண்டுவந்து, அவனைத் தொடும்படி அவரை வேண்டிக்கொண்டார்கள்.
	<br /><br />
	23. அவர் குருடனுடைய கையைப் பிடித்து, அவனைக் கிராமத்துக்கு வெளியே அழைத்துக்கொண்டுபோய், அவன் கண்களில் உமிழ்ந்து, அவன்மேல் கைகளை வைத்து: எதையாகிலும் காண்கிறாயா என்று கேட்டார்.
	<br /><br />
	24. அவன் ஏறிட்டுப்பார்த்து: நடக்கிற மனுஷரை மரங்களைப்போலக் காண்கிறேன் என்றான்.
	<br /><br />
	25. பின்பு அவர் மறுபடியும் அவன் கண்களின்மேல் கைகளை வைத்து, அவனை ஏறிட்டுப் பார்க்கும்படி செய்தார்; அப்பொழுது அவன் சொஸ்தமடைந்து, யாவரையும் தெளிவாய்க் கண்டான்.
	<br /><br />
	26. பின்பு அவர் அவனை நோக்கி: நீ கிராமத்தில் பிரவேசியாமலும், கிராமத்தில் இதை ஒருவருக்கும் சொல்லாமலும் இரு என்று சொல்லி, அவனை வீட்டிற்கு அனுப்பிவிட்டார்.
	<br /><br />
	27. பின்பு, இயேசுவும் அவருடைய சீஷர்களும் புறப்பட்டு, பிலிப்புச் செசரியா பட்டணத்தைச் சேர்ந்த கிராமங்களுக்குப் போனார்கள். வழியிலே அவர் தம்முடைய சீஷர்களை நோக்கி: ஜனங்கள் என்னை யார் என்று சொல்லுகிறார்கள் என்று கேட்டார்.
	<br /><br />
	28. அதற்கு அவர்கள்: சிலர் உம்மை யோவான்ஸ்நானன் என்றும், சிலர் எலியா என்றும், வேறு சிலர் தீர்க்கதரிசிகளில் ஒருவர் என்றும் சொல்லுகிறார்கள் என்றார்கள்.
	<br /><br />
	29. அப்பொழுது அவர்: நீங்கள் என்னை யார் என்று சொல்லுகிறீர்கள் என்று கேட்டார்; பேதுரு பிரதியுத்தரமாக: நீர் கிறிஸ்து என்றான்.
	<br /><br />
	30. அப்பொழுது தம்மைக்குறித்து ஒருவருக்கும் சொல்லாதபடிக்கு அவர்களுக்கு உறுதியாய்க் கட்டளையிட்டார்.
	<br /><br />
	31. அல்லாமலும், மனுஷகுமாரன் பல பாடுகள்பட்டு, மூப்பராலும் பிரதான ஆசாரியராலும் வேதபாரகராலும் ஆகாதவனென்று தள்ளப்பட்டு, கொல்லப்பட்டு, மூன்றுநாளைக்குப்பின்பு உயிர்த்தெழுந்திருக்கவேண்டியதென்று அவர்களுக்குப் போதிக்கத் தொடங்கினார்.
	<br /><br />
	32. இந்த வார்த்தையை அவர் தாராளமாகச் சொன்னார். அப்பொழுது, பேதுரு அவரைத் தனியே அழைத்துக்கொண்டுபோய், அவரைக் கடிந்துகொள்ளத் தொடங்கினான்.
	<br /><br />
	33. அவர் திரும்பித் தம்முடைய சீஷரைப் பார்த்து, பேதுருவை நோக்கி: எனக்குப் பின்னாகப்போ, சாத்தானே, நீ தேவனுக்கு ஏற்றவைகளைச் சிந்தியாமல் மனுஷருக்கு ஏற்றவைகளைச் சிந்திக்கிறாய் என்று சொல்லி, அவனைக் கடிந்து கொண்டார்.
	<br /><br />
	34. பின்பு அவர் ஜனங்களையும் தம்முடைய சீஷர்களையும் தம்மிடத்தில் அழைத்து: ஒருவன் என் பின்னே வர விரும்பினால், அவன் தன்னைத்தான் வெறுத்து, தன் சிலுவையை எடுத்துக்கொண்டு, என்னைப் பின்பற்றக்கடவன்.
	<br /><br />
	35. தன் ஜீவனை இரட்சிக்க விரும்புகிறவன் அதை இழந்துபோவான், என்னிமித்தமாகவும் சுவிசேஷத்தினிமித்தமாகவும் தன் ஜீவனை இழந்துபோகிறவன் அதை இரட்சித்துக்கொள்ளுவான்.
	<br /><br />
	36. மனுஷன் உலகம் முழுவதையும் ஆதாயப்படுத்திக்கொண்டாலும், தன் ஜீவனை நஷ்டப்படுத்தினால் அவனுக்கு லாபம் என்ன?
	<br /><br />
	37. மனுஷன் தன் ஜீவனுக்கு ஈடாக என்னத்தைக் கொடுப்பான்?
	<br /><br />
	38. ஆதலால் விபசாரமும் பாவமுமுள்ள இந்தச் சந்ததியில் என்னைக்குறித்தும் என் வார்த்தைகளைக்குறித்தும் எவன் வெட்கப்படுவானோ, அவனைக் குறித்து மனுஷகுமாரனும் தமது பிதாவின் மகிமைபொருந்தினவராய்ப் பரிசுத்த தூதர்களோடுங்கூட வரும்போது வெட்கப்படுவார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark8