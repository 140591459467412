import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 5 </title>
	<meta
          name="description"
          content="Hosea 5 | ஓசியா 5 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆசாரியர்களே, இதைக் கேளுங்கள்; இஸ்ரவேல் வம்சத்தாரே, கவனியுங்கள்; ராஜாவின் வீட்டாரே, செவிகொடுங்கள்; இந்த நியாய விசாரிப்பு உங்கள்மேல் செல்லும்; நீங்கள் மிஸ்பாவில் கண்ணியும் தாபோரின்மேல் விரிக்கப்பட்ட வலையுமானீர்கள்.
	<br /><br />
	2. நெறிதவறினவர்கள் மிகுதியும் வதை செய்கிறார்கள்; அவர்கள் எல்லாரையும் நான் தண்டிப்பேன்.
	<br /><br />
	3. எப்பிராயீமை நான் அறிவேன், இஸ்ரவேல் எனக்கு மறைவானதல்ல; எப்பிராயீமே, இப்போது நீ சோரம்போனாயே, இஸ்ரவேல் தீட்டுப்பட்டதே.
	<br /><br />
	4. அவர்கள் தங்கள் தேவனிடத்துக்குத் திரும்புவதற்குத் தங்கள் கிரியைகளைச் சீர்திருத்தமாட்டார்கள், வேசித்தன ஆவி அவர்கள் உள்ளத்தில் இருக்கிறது; கர்த்தரை அறியார்கள்.
	<br /><br />
	5. இஸ்ரவேலின் அகந்தை அவர்கள் முகத்துக்கு முன்பாகச் சாட்சியிடுகிறது; ஆகையால் இஸ்ரவேலும் எப்பிராயீமும் தங்கள் அக்கிரமத்தினால் இடறுண்டு விழுவார்கள்; அவர்களோடே யூதாவும் இடறுண்டு விழுவான்.
	<br /><br />
	6. அவர்கள் கர்த்தரைத் தேடும்படி தங்கள் ஆடுகளோடும் தங்கள் மாடுகளோடும் போவார்கள்; அவரைக் காணமாட்டார்கள்; அவர் அவர்களை விட்டு விலகினார்.
	<br /><br />
	7. கர்த்தருக்கு விரோதமாக துரோகம்பண்ணினார்கள்; அந்நியபிள்ளைகளைப் பெற்றார்கள்; இப்போதும் ஒரு மாதத்துக்குள்ளாக அவர்கள் தங்கள் பங்குகளோடே பட்சிக்கப்படுவார்கள்.
	<br /><br />
	8. கிபியாவிலே எக்காளத்தையும், ராமாவிலே பூரிகையையும் ஊதுங்கள்; பெத்தாவேனிலே கதறுங்கள்; பென்யமீனே உன்னைப் பின்தொடருகிறார்கள்.
	<br /><br />
	9. தண்டிப்பின் நாளிலே எப்பிராயீம் பாழாவான்; நிச்சயமாய் வரப்போகிறதை இஸ்ரவேலின் கோத்திரங்களுக்குள்ளே அறிவிக்கிறேன்.
	<br /><br />
	10. யூதாவின் பிரபுக்கள் எல்லைகளை ஒதுக்குகிறவர்களுக்கு ஒப்பானார்கள்; அவர்கள்மேல் என் உக்கிரகோபத்தைத் தண்ணீரைப்போல ஊற்றுவேன்.
	<br /><br />
	11. எப்பிராயீம் தகாத கற்பனையை மனதாரப் பின்பற்றிப்போனபடியால் அவன் ஒடுங்குண்டு, நியாயவிசாரணையில் நொறுக்கப்பட்டுப்போகிறான்.
	<br /><br />
	12. நான் எப்பிராயீமுக்குப் பொட்டரிப்பைப்போலவும், யூதாவின் வீட்டுக்கு உளுப்பைப்போலவும் இருப்பேன்.
	<br /><br />
	13. எப்பிராயீம் தன் வியாதியையும், யூதா தன் காயத்தையும் கண்டபோது, எப்பிராயீம் அசீரியனண்டைக்குப்போய் யாரேப் ராஜாவினிடத்தில் ஆள் அனுப்பினான்; ஆனாலும் உங்களைக் குணமாக்கவும் உங்களில் இருக்கிற காயத்தை ஆற்றவும் அவனால் கூடாமற்போயிற்று.
	<br /><br />
	14. நான் எப்பிராயீமுக்குச் சிங்கம்போலவும், யூதாவின் வம்சத்தாருக்குப் பாலசிங்கம்போலவும் இருப்பேன்; நான் நானே பீறிவிட்டுப் போய்விடுவேன்; தப்புவிப்பார் இல்லாமல் எடுத்துக்கொண்டுபோவேன்.
	<br /><br />
	15. அவர்கள் தங்கள் குற்றங்களை உணர்ந்து, என் முகத்தைத் தேடுமட்டும் நான் என் ஸ்தானத்துக்குத் திரும்பிப் போய்விடுவேன்; தங்கள் ஆபத்தில் என்னைக் கருத்தாய்த் தேடுவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea5