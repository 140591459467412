import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 4 </title>
	<meta
          name="description"
          content="Acts 4 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 4 |
          Acts of Apostles Chapter-4 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர்கள் ஜனங்களுடனே பேசிக்கொண்டிருக்கையில், ஆசாரியர்களும் தேவாலயத்துச் சேனைத்தலைவனும் சதுசேயரும் அவர்களிடத்தில் வந்து,
	<br /><br />
	2. அவர்கள் ஜனங்களுக்கு உபதேசிக்கிறதினாலும், இயேசுவை முன்னிட்டு, மரித்தோரிலிருந்து உயிர்த்தெழுதலைப் பிரசங்கிக்கிறதினாலும், சினங்கொண்டு,
	<br /><br />
	3. அவர்களைப் பிடித்து, சாயங்காலமாயிருந்தபடியினால், மறுநாள்வரைக்கும் காவலில் வைத்தார்கள்.
	<br /><br />
	4. வசனத்தைக் கேட்டவர்களில் அநேகர் விசுவாசித்தார்கள்; அவர்கள் தொகை ஏறக்குறைய ஐயாயிரமாயிருந்தது.
	<br /><br />
	5. மறுநாளிலே ஜனங்களுடைய அதிகாரிகளும் மூப்பரும் வேதபாரகரும்,
	<br /><br />
	6. பிரதான ஆசாரியனாகிய அன்னாவும், காய்பாவும், யோவானும், அலெக்சந்தரும், பிரதான ஆசாரியருடைய குடும்பத்தார் யாவரும் எருசலேமிலே கூட்டங்கூடி,
	<br /><br />
	7. அவர்களை நடுவே நிறுத்தி: நீங்கள் எந்த வல்லமையினாலே, எந்த நாமத்தினாலே, இதைச் செய்தீர்கள் என்று கேட்டார்கள்.
	<br /><br />
	8. அப்பொழுது பேதுரு பரிசுத்தஆவியினாலே நிறைந்து, அவர்களை நோக்கி: ஜனத்தின் அதிகாரிகளே, இஸ்ரவேலின் மூப்பர்களே,
	<br /><br />
	9. பிணியாளியாயிருந்த இந்த மனுஷனுக்குச் செய்யப்பட்ட உபகாரத்தைக்குறித்து எதினாலே இவன் ஆரோக்கியமானானென்று நீங்கள் இன்று எங்களிடத்தில் விசாரித்துக்கேட்டால்,
	<br /><br />
	10. உங்களால் சிலுவையில் அறையப்பட்டவரும், தேவனால் மரித்தோரிலிருந்து எழுப்பப்பட்டவருமாயிருக்கிற நசரேயனாகிய இயேசுகிறிஸ்துவின் நாமத்தினாலேயே இவன் உங்களுக்கு முன்பாகச் சொஸ்தமாய் நிற்கிறானென்று உங்களெல்லாருக்கும், இஸ்ரவேல் ஜனங்களெல்லாருக்கும் தெரிந்திருக்கக்கடவது.
	<br /><br />
	11. வீடுகட்டுகிறவர்களாகிய உங்களால் அற்பமாய் எண்ணப்பட்ட அவரே மூலைக்குத் தலைக்கல்லானவர்.
	<br /><br />
	12. அவராலேயன்றி வேறொருவராலும் இரட்சிப்பு இல்லை; நாம் இரட்சிக்கப்படும்படிக்கு வானத்தின் கீழெங்கும், மனுஷர்களுக்குள்ளே அவருடைய நாமமேயல்லாமல் வேறொரு நாமம் கட்டளையிடப்படவும் இல்லை என்றான்.
	<br /><br />
	13. பேதுருவும் யோவானும் பேசுகிற தைரியத்தை அவர்கள் கண்டு, அவர்கள் படிப்பறியாதவர்களென்றும் பேதைமையுள்ளவர்களென்றும் அறிந்தபடியினால் ஆச்சரியப்பட்டு, அவர்கள் இயேசுவுடனேகூட இருந்தவர்களென்றும் அறிந்துகொண்டார்கள்.
	<br /><br />
	14. சொஸ்தமாக்கப்பட்ட மனுஷன் அவர்கள் அருகே நிற்கிறதைக் கண்டபடியால், எதிர்பேச அவர்களுக்கு இடமில்லாதிருந்தது.
	<br /><br />
	15. அப்பொழுது அவர்களை ஆலோசனைச் சங்கத்தைவிட்டு வெளியே போகும்படி கட்டளையிட்டு, தங்களுக்குள்ளே யோசனைபண்ணிக்கொண்டு:
	<br /><br />
	16. இந்த மனுஷரை நாம் என்ன செய்யலாம்? எருசலேமில் வாசம்பண்ணுகிற எல்லாருக்கும் தெரிந்திருக்கிறபடி வெளியரங்கமான அற்புதம் இவர்களால் செய்யப்பட்டதே, அதை நாம் இல்லையென்று சொல்லக்கூடாது.
	<br /><br />
	17. ஆகிலும் இது அதிகமாய் ஜனத்துக்குள்ளே பரம்பாதபடிக்கு, இதுமுதல் ஒருவரோடும் இந்த நாமத்தைக்குறித்துப் பேசக்கூடாதென்று, அவர்களை உறுதியாய்ப் பயமுறுத்தவேண்டுமென்று சொல்லிக்கொண்டு,
	<br /><br />
	18. அவர்களை அழைத்து: இயேசுவின் நாமத்தைக்குறித்து எவ்வளவும் பேசவும் போதிக்கவும் கூடாதென்று அவர்களுக்குக் கட்டளையிட்டார்கள்.
	<br /><br />
	19. பேதுருவும் யோவானும் அவர்களுக்குப் பிரதியுத்தரமாக: தேவனுக்குச் செவிகொடுக்கிறதைப்பார்க்கிலும் உங்களுக்குச் செவிகொடுக்கிறது தேவனுக்கு முன்பாக நியாயமாயிருக்குமோ என்று நீங்களே நிதானித்துப்பாருங்கள்.
	<br /><br />
	20. நாங்கள் கண்டவைகளையும் கேட்டவைகளையும் பேசாமலிருக்கக்கூடாதே என்றார்கள்.
	<br /><br />
	21. நடந்த சங்கதிகளைக்குறித்து எல்லாரும் தேவனை மகிமைப்படுத்தினபடியால், ஜனங்களுக்குப் பயந்து அவர்களைத் தண்டிக்க வகையொன்றுங்காணாமல், அவர்களைப் பயமுறுத்தி விட்டுவிட்டார்கள்.
	<br /><br />
	22. அற்புதமாய்ச் சொஸ்தமாக்கப்பட்ட மனுஷன் நாற்பது வயதுக்கு மேற்பட்டவனாயிருந்தான்.
	<br /><br />
	23. அவர்கள் விடுதலையாக்கப்பட்டபின்பு, தங்களைச் சேர்ந்தவர்களிடத்தில் வந்து, பிரதான ஆசாரியர்களும் மூப்பர்களும் தங்களுக்குச் சொன்ன யாவையும் அறிவித்தார்கள்.
	<br /><br />
	24. அவர்கள் அதைக் கேட்டு, ஒருமனப்பட்டுத் தேவனை நோக்கிச் சத்தமிட்டு: கர்த்தாவே, நீர் வானத்தையும் பூமியையும் சமுத்திரத்தையும் அவைகளிலுள்ள யாவற்றையும் உண்டாக்கின தேவனாயிருக்கிறீர்.
	<br /><br />
	25. புறஜாதிகள் கொந்தளித்து, ஜனங்கள் விருதா காரியங்களைச் சிந்திப்பானேன் என்றும்,
	<br /><br />
	26. கர்த்தருக்கு விரோதமாகவும் அவருடைய கிறிஸ்துவுக்கு விரோதமாகவும் பூமியின் ராஜாக்கள் எழும்பி நின்று, அதிகாரிகள் ஏகமாய்க் கூட்டங்கூடினார்கள் என்றும் தேவரீர் உம்முடைய தாசனாகிய தாவீதின் வாக்கினால் உரைத்தீரே.
	<br /><br />
	27. அந்தப்படி உம்முடைய கரமும் உம்முடைய ஆலோசனையும் முன்குறித்தவைகள் யாவையும் செய்யும்படி,
	<br /><br />
	28. ஏரோதும் பொந்தியுபிலாத்தும், புறஜாதிகளோடும் இஸ்ரவேல் ஜனங்களோடுங்கூட, நீர் அபிஷேகம்பண்ணின உம்முடைய பரிசுத்த பிள்ளையாகிய இயேசுவுக்கு விரோதமாய், மெய்யாகவே கூட்டங்கூடினார்கள்.
	<br /><br />
	29. இப்பொழுதும், கர்த்தாவே, அவர்கள் பயமுறுத்தல்களைத் தேவரீர் கவனித்து,
	<br /><br />
	30. உம்முடைய பரிசுத்த பிள்ளையாகிய இயேசுவின் நாமத்தினாலே அடையாளங்களும் அற்புதங்களும் நடக்கும்படி செய்து, பிணியாளிகளைக் குணமாக்கும்படி உம்முடைய கரத்தை நீட்டி, உம்முடைய ஊழியக்காரர் உம்முடைய வசனத்தை முழு தைரியத்தோடும் சொல்லும்படி அவர்களுக்கு அநுக்கிரகஞ்செய்தருளும் என்றார்கள்.
	<br /><br />
	31. அவர்கள் ஜெபம்பண்ணினபோது, அவர்கள் கூடியிருந்த இடம் அசைந்தது. அவர்களெல்லாரும் பரிசுத்தஆவியினால் நிரப்பப்பட்டு, தேவவசனத்தைத் தைரியமாய்ச் சொன்னார்கள்.
	<br /><br />
	32. விசுவாசிகளாகிய திரளான கூட்டத்தார் ஒரே இருதயமும் ஒரே மனமுமுள்ளவர்களாயிருந்தார்கள். ஒருவனாகிலும் தனக்குள்ளவைகளில் ஒன்றையும் தன்னுடையதென்று சொல்லவில்லை; சகலமும் அவர்களுக்குப் பொதுவாயிருந்தது.
	<br /><br />
	33. கர்த்தராகிய இயேசுவின் உயிர்த்தெழுதலைக்குறித்து அப்போஸ்தலர்கள் மிகுந்த பலமாய்ச் சாட்சிகொடுத்தார்கள்; அவர்களெல்லார்மேலும் பூரண கிருபை உண்டாயிருந்தது.
	<br /><br />
	34. நிலங்களையும் வீடுகளையும் உடையவர்கள் அவைகளை விற்று, விற்கப்பட்டவைகளின் கிரயத்தைக் கொண்டுவந்து,
	<br /><br />
	35. அப்போஸ்தலருடைய பாதத்திலே வைத்தார்கள். அவனவனுக்குத் தேவையானதற்குத்தக்கதாய்ப் பகிர்ந்துகொடுக்கப்பட்டது; அவர்களில் ஒருவனுக்கும் ஒன்றும் குறைவாயிருந்ததில்லை.
	<br /><br />
	36. சீப்புருதீவானும் லேவியனும் அப்போஸ்தலராலே ஆறுதலின் மகன் என்று அர்த்தங்கொள்ளும் பர்னபா என்னும் மறுபேர்பெற்றவனுமாகிய யோசே என்பவன்,
	<br /><br />
	37. தனக்கு உண்டாயிருந்த நிலத்தைவிற்று, அதின் கிரயத்தைக் கொண்டுவந்து, அப்போஸ்தலருடைய பாதத்திலே வைத்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts4