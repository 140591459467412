import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 22 </title>
	<meta
          name="description"
          content="Matthew 22 | மத்தேயு 22 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு மறுபடியும் அவர்களோடே உவமைகளாய்ப் பேசிச் சொன்னது என்னவென்றால்:
	<br /><br />
	2. பரலோகராஜ்யம் தன் குமாரனுக்குக் கலியாணஞ்செய்த ஒரு ராஜாவுக்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	3. அழைக்கப்பட்டவர்களைக் கலியாணத்திற்கு வரச்சொல்லும்படி அவன் தன் ஊழியக்காரரை அனுப்பினான்; அவர்களோ வர மனதில்லாதிருந்தார்கள்.
	<br /><br />
	4. அப்பொழுது அவன் வேறு ஊழியக்காரரை அழைத்து: நீங்கள் போய், இதோ, என் விருந்தை ஆயத்தம்பண்ணினேன், என் எருதுகளும் கொழுத்த ஜெந்துக்களும் அடிக்கப்பட்டது, எல்லாம் ஆயத்தமாயிருக்கிறது; கலியாணத்திற்கு வாருங்கள் என்று அழைக்கப்பட்டவர்களுக்குச் சொல்லுங்களென்று அனுப்பினான்.
	<br /><br />
	5. அழைக்கப்பட்டவர்களோ அதை அசட்டைபண்ணி, ஒருவன் தன் வயலுக்கும், ஒருவன் தன் வியாபாரத்துக்கும் போய்விட்டார்கள்.
	<br /><br />
	6. மற்றவர்கள் அவன் ஊழியக்காரரைப் பிடித்து, அவமானப்படுத்தி, கொலைசெய்தார்கள்.
	<br /><br />
	7. ராஜா அதைக் கேள்விப்பட்டு, கோபமடைந்து, தன் சேனைகளை அனுப்பி, அந்தக் கொலைபாதகரை அழித்து, அவர்கள் பட்டணத்தையும் சுட்டெரித்தான்.
	<br /><br />
	8. அப்பொழுது, அவன் தன் ஊழியக்காரரை நோக்கி: கலியாண விருந்து ஆயத்தமாயிருக்கிறது, அழைக்கப்பட்டவர்களோ அதற்கு அபாத்திரராய்ப் போனார்கள்.
	<br /><br />
	9. ஆகையால், நீங்கள் வழிச்சந்திகளிலே போய், காணப்படுகிற யாவரையும் கலியாணத்திற்கு அழைத்துக்கொண்டுவாருங்கள் என்றான்.
	<br /><br />
	10. அந்த ஊழியக்காரர் புறப்பட்டு, வழிகளிலே போய், தாங்கள் கண்ட நல்லார் பொல்லார் யாவரையும் கூட்டிக்கொண்டு வந்தார்கள்; கலியாணசாலை விருந்தாளிகளால் நிறைந்தது.
	<br /><br />
	11. விருந்தாளிகளைப் பார்க்கும்படி ராஜா உள்ளே பிரவேசித்தபோது, கலியாண வஸ்திரம் தரித்திராத ஒரு மனுஷனை அங்கே கண்டு:
	<br /><br />
	12. சிநேகிதனே, நீ கலியாண வஸ்திரமில்லாதவனாய் இங்கே எப்படி வந்தாய் என்று கேட்டான்; அதற்கு அவன் பேசாமலிருந்தான்.
	<br /><br />
	13. அப்பொழுது, ராஜா பணிவிடைக்காரரை நோக்கி: இவனைக் கையுங்காலும் கட்டிக் கொண்டுபோய், அழுகையும் பற்கடிப்பும் உண்டாயிருக்கிற புறம்பான இருளிலே போடுங்கள் என்றான்.
	<br /><br />
	14. அந்தப்படியே, அழைக்கப்பட்டவர்கள் அநேகர், தெரிந்துகொள்ளப்பட்டவர்களோ சிலர் என்றார்.
	<br /><br />
	15. அப்பொழுது, பரிசேயர் போய், பேச்சிலே அவரை அகப்படுத்தும்படி யோசனைபண்ணி,
	<br /><br />
	16. தங்கள் சீஷரையும் ஏரோதியரையும் அவரிடத்தில் அனுப்பினார்கள். அவர்கள் வந்து: போதகரே, நீர் சத்தியமுள்ளவரென்றும், தேவனுடைய மார்க்கத்தைச் சத்தியமாய்ப் போதிக்கிறவரென்றும், நீர் முகதாட்சணியம் இல்லாதவராகையால் எவனைக்குறித்தும் உமக்குக் கவலையில்லையென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	17. ஆதலால், உமக்கு எப்படித் தோன்றுகிறது? இராயனுக்கு வரிகொடுக்கிறது நியாயமோ, அல்லவோ? அதை எங்களுக்குச் சொல்லும் என்று கேட்டார்கள்.
	<br /><br />
	18. இயேசு அவர்கள் துர்க்குணத்தை அறிந்து: மாயக்காரரே, நீங்கள் என்னை ஏன் சோதிக்கிறீர்கள்?
	<br /><br />
	19. வரிக்காசை எனக்குக் காண்பியுங்கள் என்றார்; அவர்கள் ஒரு பணத்தை அவரிடத்தில் கொண்டுவந்தார்கள்.
	<br /><br />
	20. அப்பொழுது அவர்: இந்தச் சுரூபமும் மேலெழுத்தும் யாருடையது என்று கேட்டார்.
	<br /><br />
	21. இராயனுடையது என்றார்கள். அதற்கு அவர்: அப்படியானால், இராயனுடையதை இராயனுக்கும், தேவனுடையதைத் தேவனுக்கும் செலுத்துங்கள் என்றார்.
	<br /><br />
	22. அவர்கள் அதைக்கேட்டு ஆச்சரியப்பட்டு அவரை விட்டுப் போய்விட்டார்கள்.
	<br /><br />
	23. உயிர்த்தெழுதல் இல்லை என்று சாதிக்கிற சதுசேயர் அன்றையத்தினம் அவரிடத்தில் வந்து:
	<br /><br />
	24. போதகரே, ஒருவன் சந்தானம் இல்லாமல் இறந்துபோனால், அவனுடைய சகோதரன் அவன் மனைவியை விவாகம்பண்ணி, தன் சகோதரனுக்குச் சந்தானம் உண்டாக்கவேண்டும் என்று மோசே சொன்னாரே.
	<br /><br />
	25. எங்களுக்குள்ளே சகோதரர் ஏழுபேர் இருந்தார்கள்; மூத்தவன் விவாகம்பண்ணி, மரித்து, சந்தானமில்லாததினால் தன் மனைவியைத் தன் சகோதரனுக்கு விட்டுவிட்டுப்போனான்.
	<br /><br />
	26. அப்படியே இரண்டாம் மூன்றாம் சகோதரன்முதல் ஏழாம் சகோதரன்வரைக்கும் செய்தார்கள்.
	<br /><br />
	27. எல்லாருக்கும் பின்பு அந்த ஸ்திரீயும் இறந்துபோனாள்.
	<br /><br />
	28. ஆகையால், உயிர்த்தெழுதலில் அவ்வேழுபேரில் எவனுக்கு அவள் மனைவியாயிருப்பாள்? அவர்கள் எல்லாரும் அவளை விவாகம்பண்ணியிருந்தார்களே என்று கேட்டார்கள்.
	<br /><br />
	29. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: நீங்கள் வேதவாக்கியங்களையும், தேவனுடைய வல்லமையையும் அறியாமல் தப்பான எண்ணங்கொள்ளுகிறீர்கள்.
	<br /><br />
	30. உயிர்த்தெழுதலில் கொள்வனையும் கொடுப்பனையும் இல்லை; அவர்கள் பரலோகத்திலே தேவதூதரைப்போல் இருப்பார்கள்;
	<br /><br />
	31. மேலும் மரித்தோர் உயிர்த்தெழுதலைப்பற்றி: நான் ஆபிரகாமின் தேவனும், ஈசாக்கின் தேவனும், யாக்கோபின் தேவனுமாயிருக்கிறேன் என்று தேவனால் உங்களுக்கு உரைக்கப்பட்டிருக்கிறதை நீங்கள் வாசிக்கவில்லையா?
	<br /><br />
	32. தேவன் மரித்தோருக்குத் தேவனாயிராமல், ஜீவனுள்ளோருக்குத் தேவனாயிருக்கிறார் என்றார்.
	<br /><br />
	33. ஜனங்கள் இதைக் கேட்டு, அவருடைய போதகத்தைக்குறித்து ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	34. அவர் சதுசேயரை வாயடைத்தார் என்று பரிசேயர் கேள்விப்பட்டு, அவரிடத்தில் கூடிவந்தார்கள்.
	<br /><br />
	35. அவர்களில் நியாயசாஸ்திரி ஒருவன் அவரைச் சோதிக்கும்படி:
	<br /><br />
	36. போதகரே, நியாயப்பிரமாணத்திலே எந்தக் கற்பனை பிரதானமானது என்று கேட்டான்.
	<br /><br />
	37. இயேசு அவனை நோக்கி: உன் தேவனாகிய கர்த்தரிடத்தில் உன் முழு இருதயத்தோடும் உன் முழு ஆத்துமாவோடும் உன் முழு மனதோடும் அன்புகூருவாயாக;
	<br /><br />
	38. இது முதலாம் பிரதான கற்பனை.
	<br /><br />
	39. இதற்கு ஒப்பாயிருக்கிற இரண்டாம் கற்பனை என்னவென்றால், உன்னிடத்தில் நீ அன்புகூருவதுபோலப் பிறனிடத்திலும் அன்புகூருவாயாக என்பதே.
	<br /><br />
	40. இவ்விரண்டு கற்பனைகளிலும் நியாயப்பிரமாணம் முழுமையும் தீர்க்கதரிசனங்களும் அடங்கியிருக்கிறது என்றார்.
	<br /><br />
	41. பரிசேயர் கூடியிருக்கையில், இயேசு அவர்களை நோக்கி:
	<br /><br />
	42. கிறிஸ்துவைக்குறித்து நீங்கள் என்ன நினைக்கிறீர்கள், அவர் யாருடைய குமாரன்? என்று கேட்டார். அவர் தாவீதின் குமாரன் என்றார்கள்.
	<br /><br />
	43. அதற்கு அவர்: அப்படியானால், தாவீது பரிசுத்த ஆவியினாலே அவரை ஆண்டவர் என்று சொல்லியிருக்கிறது எப்படி?
	<br /><br />
	44. நான் உம்முடைய சத்துருக்களை உமக்குப் பாதபடியாக்கிப் போடும்வரைக்கும் நீர் என்னுடைய வலதுபாரிசத்தில் உட்காரும் என்று கர்த்தர் என் ஆண்டவரோடே சொன்னார் என்று சொல்லியிருக்கிறானே.
	<br /><br />
	45. தாவீது அவரை ஆண்டவர் என்று சொல்லியிருக்க, அவனுக்கு அவர் குமாரனாயிருப்பது எப்படி என்றார்.
	<br /><br />
	46. அதற்கு மாறுத்தரமாக ஒருவனும் அவருக்கு ஒரு வார்த்தையும் சொல்லக்கூடாதிருந்தது. அன்றுமுதல் ஒருவனும் அவரிடத்தில் கேள்விகேட்கத் துணியவில்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew22