import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah31 = () => {
  const verseNumber = 31;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 31 </title>
	<meta
          name="description"
          content="Jeremiah 31 | எரேமியா 31 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே நான் இஸ்ரவேலின் வம்சங்களுக்கெல்லாம் தேவனாயிருப்பேன், அவர்கள் என் ஜனமாயிருப்பார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	2. பட்டயத்திற்குத் தப்பி, மீந்த ஜனம் வனாந்தரத்தில் இரக்கம் பெற்றது; இஸ்ரவேலுக்கு இளைப்பாறுதலைக் கட்டளையிடப்போகிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. பூர்வகாலமுதல் கர்த்தர் எனக்குத் தரிசனையானார் என்பாய்; ஆம் அநாதி சிநேகத்தால் உன்னைச் சிநேகித்தேன்; ஆதலால் காருணியத்தால் உன்னை இழுத்துக்கொள்ளுகிறேன்.
	<br /><br />
	4. இஸ்ரவேல் என்னும் கன்னிகையே, மறுபடியும் உன்னைக் கட்டுவிப்பேன், நீ கட்டப்படுவாய்; மறுபடியும் நீ மேளவாத்தியத்தோடும் ஆடல்பாடல் செய்கிறவர்களின் களிப்புள்ள கூட்டத்தோடும் புறப்படுவாய்.
	<br /><br />
	5. மறுபடியும் சமாரியாவின் மலைகளிலே திராட்சத்தோட்டங்களை நாட்டுவாய்; நாட்டுகிறவர்கள் அவைகளை நாட்டி, அதின் பலனை அநுபவிப்பார்கள்.
	<br /><br />
	6. எழுந்திருங்கள், சீயோனில் நம்முடைய தேவனாகிய கர்த்தரிடத்திற்குப் போவோம் வாருங்கள் என்று எப்பிராயீமின் மலைகளிலுள்ள ஜாமக்காரர் கூறுங்காலம் வரும்.
	<br /><br />
	7. கர்த்தர் சொல்லுகிறது என்னவென்றால்: யாக்கோபினிமித்தம் மகிழ்ச்சியாய்க் கெம்பீரித்து, ஜாதிகளுடைய தலைவரினிமித்தம் ஆர்ப்பரியுங்கள்; சத்தத்தைக் கேட்கப்பண்ணி, துதித்து: கர்த்தாவே, இஸ்ரவேலில் மீதியான உமது ஜனத்தை இரட்சியும் என்று சொல்லுங்கள்.
	<br /><br />
	8. இதோ, நான் அவர்களை வடதேசத்திலிருந்து வரப்பண்ணி, அவர்களைப் பூமியின் எல்லைகளிலிருந்து கூட்டிவருவேன்; குருடரும், சப்பாணிகளும், கர்ப்பவதிகளும், பிள்ளைத்தாய்ச்சிகளுங்கூட அவர்களில் இருப்பார்கள்; மகா கூட்டமாய் இவ்விடத்துக்குத் திரும்புவார்கள்.
	<br /><br />
	9. அழுகையோடும் விண்ணப்பங்களோடும் வருவார்கள்; அவர்களை வழிநடத்துவேன்; அவர்களைத் தண்ணீருள்ள நதிகளண்டைக்கு இடறாத செம்மையான வழியிலே நடக்கப்பண்ணுவேன்; இஸ்ரவேலுக்கு நான் பிதாவாயிருக்கிறேன், எப்பிராயீம் என் சேஷ்டபுத்திரனாயிருக்கிறான்.
	<br /><br />
	10. ஜாதிகளே, நீங்கள் கர்த்தருடைய வார்த்தையைக் கேட்டு, தூரத்திலுள்ள தீவுகளில் அறிவித்து, இஸ்ரவேலைச் சிதறடித்தவர் அதைச் சேர்த்துக்கொண்டு, ஒரு மேய்ப்பன் தன் மந்தையைக் காக்கும்வண்ணமாக அதைக் காப்பார் என்று சொல்லுங்கள்.
	<br /><br />
	11. கர்த்தர் யாக்கோபை மீட்டு, அவனிலும் பலத்தவனுடைய கைக்கு அவனை நீங்கலாக்கி விடுவிக்கிறார்.
	<br /><br />
	12. அவர்கள் வந்து, சீயோனின் உச்சியிலே கெம்பீரித்து, கர்த்தர் அருளும் கோதுமை, திராட்சரசம், எண்ணெய், ஆட்டுக்குட்டிகள், கன்றுக்குட்டிகள் என்பவைகளாகிய இந்த நன்மைகளுக்காக ஓடிவருவார்கள்; அவர்களுடைய ஆத்துமா நீர்ப்பாய்ச்சலான தோட்டம்போலிருக்கும்; அவர்கள் இனித் தொய்ந்துபோவதில்லை.
	<br /><br />
	13. அப்பொழுது கன்னிகைகளும், வாலிபரும், முதியோருங்கூட ஆனந்தக்களிப்பாய் மகிழுவார்கள்; நான் அவர்கள் துக்கத்தைச் சந்தோஷமாக மாற்றி, அவர்களைத் தேற்றி, அவர்கள் சஞ்சலம் நீங்க அவர்களைச் சந்தோஷப்படுத்துவேன்.
	<br /><br />
	14. ஆசாரியர்களின் ஆத்துமாவைக் கொழுமையானவைகளினால் பூரிப்பாக்குவேன்; என் ஜனங்கள் நான் அளிக்கும் நன்மையினால் திருப்தியாவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	15. ராமாவிலே புலம்பலும் கசப்பான அழுகையுமாகிய கூக்குரல் கேட்கப்பட்டது; ராகேல் தன் பிள்ளைகளுக்காக அழுது, தன் பிள்ளைகள் இல்லாதபடியால் அவைகளினிமித்தம் ஆறுதல் அடையாதிருக்கிறாள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	16. நீ அழாதபடிக்கு உன் சத்தத்தை அடக்கி, நீ கண்ணீர் விடாதபடிக்கு உன் கண்களைக் காத்துக்கொள் என்று கர்த்தர் சொல்லுகிறார்; உன் கிரியைக்குப் பலனுண்டென்று கர்த்தர் சொல்லுகிறார்; அவர்கள் சத்துருவின் தேசத்திலிருந்து திரும்பிவருவார்கள்.
	<br /><br />
	17. உன் முடிவைப்பற்றி உனக்கு நம்பிக்கையுண்டு; உன் பிள்ளைகள் தங்கள் தேசத்துக்குத் திரும்பிவருவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. நீர் என்னைத் தண்டித்தீர், நான் பணியாத மாடுபோல் அடிக்கப்பட்டேன்; என்னைத் திருப்பும், அப்பொழுது திருப்பப்படுவேன்; நீரே என் தேவனாகிய கர்த்தர்.
	<br /><br />
	19. நான் திரும்பினபின்பு மனஸ்தாபப்பட்டுக்கொண்டிருக்கிறேன், நான் என்னை அறிந்துகொண்டதற்குப் பின்பு விலாவில் அடித்துக்கொண்டிருக்கிறேன், வெட்கி நாணிக்கொண்டுமிருக்கிறேன், என் இளவயதின் நிந்தையைச் சுமந்துவருகிறேன் என்று எப்பிராயீம் துக்கித்துப் புலம்பிக்கொண்டிருக்கிறதை நிச்சயமாய்க் கேட்டேன்.
	<br /><br />
	20. எப்பிராயீம் எனக்கு அருமையான குமாரன் அல்லவோ? அவன் எனக்குப் பிரியமான பிள்ளையல்லவோ? அவனுக்கு விரோதமாய்ப் பேசினதுமுதல் அவனை நினைத்துக்கொண்டே இருக்கிறேன்; ஆகையால் என் உள்ளம் அவனுக்காகக் கொதிக்கிறது; அவனுக்கு உருக்கமாய் இரங்குவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	21. உனக்குக் குறிப்படையாளங்களை நிறுத்து; உனக்குத் தூண்களை நாட்டு; நீ நடந்த வழியாகிய பாதையின்மேல் உன் மனதை வை; இஸ்ரவேலாகிய குமாரத்தியே, திரும்பு; இந்த உன்னுடைய பட்டணங்களுக்கே திரும்பு.
	<br /><br />
	22. சீர்கெட்டுப்போன குமாரத்தியே, எந்தமட்டும் விலகித் திரிவாய்? கர்த்தர் பூமியிலே ஒரு புதுமையைச் சிருஷ்டிப்பார், ஸ்திரீயானவள் புருஷனைச் சூழ்ந்துகொள்ளுவாள்.
	<br /><br />
	23. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: நான் அவர்கள் சிறையிருப்பைத் திருப்பும்போது, அவர்கள்: நீதியின் வாசஸ்தலமே, பரிசுத்த பர்வதமே, கர்த்தர் உன்னை ஆசீர்வதிக்கக்கடவரென்கிற வார்த்தையை யூதாவின் தேசத்திலும் அதின் பட்டணங்களிலும் சொல்லுவார்கள்.
	<br /><br />
	24. அதிலே யூதாவும், அதனுடைய எல்லாப் பட்டணங்களின் மனுஷரும் பயிரிடுங் குடிகளும், மந்தைகளை மேய்த்துத் திரிகிறவர்களும் ஏகமாய்க் குடியிருப்பார்கள்.
	<br /><br />
	25. நான் விடாய்த்த ஆத்துமாவைச் சம்பூரணமடையப்பண்ணி, தொய்ந்த எல்லா ஆத்துமாவையும் நிரப்புவேன்.
	<br /><br />
	26. இதற்காக நான் விழித்துப்பார்த்துக்கொண்டிருக்கிறேன்; என் நித்திரை எனக்கு இன்பமாயிருந்தது.
	<br /><br />
	27. இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது இஸ்ரவேல் குடும்பத்தையும் யூதா குடும்பத்தையும் மனுஷவித்தினாலும், மிருகவித்தினாலும் விதைப்பேன்.
	<br /><br />
	28. அப்பொழுது நான் பிடுங்கவும் இடிக்கவும் நிர்மூலமாக்கவும் அழிக்கவும் தீங்குசெய்யவும் அவர்கள்பேரில் எப்படி ஜாக்கிரதையாயிருந்தேனோ, அப்படியே கட்டவும் நாட்டவும் அவர்கள்பேரில் ஜாக்கிரதையாயிருப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	29. பிதாக்கள் திராட்சக்காய்களைத் தின்றார்கள், பிள்ளைகளின் பற்கள் கூசிப்போயின என்று அந்நாட்களில் சொல்லமாட்டார்கள்.
	<br /><br />
	30. அவனவன் தன்தன் அக்கிரமத்தினிமித்தமே சாவான்; எந்த மனுஷன் திராட்சக்காய்களைத் தின்பானோ அவனுடைய பற்களே கூசிப்போகும்.
	<br /><br />
	31. இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது இஸ்ரவேல் குடும்பத்தோடும் யூதா குடும்பத்தோடும் புது உடன்படிக்கை பண்ணுவேன்.
	<br /><br />
	32. நான் அவர்கள் பிதாக்களை எகிப்துதேசத்திலிருந்து அழைத்துவரக் கைப்பிடித்த நாளிலே அவர்களோடே பண்ணின உடன்படிக்கையின்படி அல்ல; ஏனெனில் நான் அவர்களுக்கு நாயகராயிருந்தும், அந்த என் உடன்படிக்கையை அவர்கள் மீறி அவமாக்கிப்போட்டார்களே என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	33. அந்நாட்களுக்குப்பிற்பாடு, நான் இஸ்ரவேல் குடும்பத்தோடே பண்ணப்போகிற உடன்படிக்கையாவது; நான் என் நியாயப்பிரமாணத்தை அவர்கள் உள்ளத்திலே வைத்து, அதை அவர்கள் இருதயத்திலே எழுதி, நான் அவர்கள் தேவனாயிருப்பேன், அவர்கள் என் ஜனமாயிருப்பார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	34. இனி ஒருவன் தன் அயலானையும், ஒருவன் தன் சகோதரனையும் நோக்கி: கர்த்தரை அறிந்துகொள் என்று போதிப்பதில்லை; அவர்களில் சிறியவன்முதல் பெரியவன்மட்டும், எல்லாரும் என்னை அறிந்துகொள்வார்கள் என்று கர்த்தர் சொல்லுகிறார்; நான் அவர்கள் அக்கிரமத்தை மன்னித்து, அவர்கள் பாவங்களை இனி நினையாதிருப்பேன்.
	<br /><br />
	35. சூரியனைப் பகல் வெளிச்சத்துக்காகவும் சந்திரநட்சத்திர நியமங்களை இராவெளிச்சத்துக்காகவும் கட்டளையிட்டவரும், அலைகள் கொந்தளிக்கத்தக்கதாகச் சமுத்திரத்தைக் குலுக்குகிறவரும், சேனைகளின் கர்த்தர் என்னும் நாமமுள்ளவருமாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	36. இந்த நியமங்கள் எனக்கு முன்பாக இல்லாதபடிக்கு ஒழிந்துபோனால், அப்பொழுது இஸ்ரவேல் சந்ததியும் எனக்கு முன்பாக என்றைக்கும் ஒரு ஜாதியாயிராதபடிக்கு அற்றுப்போம் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	37. கர்த்தர் சொல்லுகிறது என்னவென்றால்: மேலே இருக்கிற வானங்கள் அளக்கப்படவும், கீழே இருக்கிற பூமியின் அஸ்திபாரங்கள் ஆராயப்படவும் கூடுமானால், நான் இஸ்ரவேல் வம்சத்தார் அனைவரையும் அவர்கள் செய்த எல்லாவற்றினிமித்தமும் வெறுத்துவிடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	38. இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார்; அப்பொழுது இந்த நகரம், அனானெயேலின் கோபுரமுதல் கோடிவாசல்மட்டும் கர்த்தருக்கென்று கட்டப்படும்.
	<br /><br />
	39. அப்புறமும் அளவுநூல் அதற்கு எதிராய்க் காரேப் என்னும் மேட்டின்மேல் சென்று கோவாத்புறமாக சுற்றிப்போம்.
	<br /><br />
	40. பிரேதங்களைப் புதைக்கிறதும், சாம்பலைக் கொட்டுகிறதுமான பள்ளத்தாக்கனைத்தும், கீதரோன் வாய்க்காலுக்கு இப்பாலே கிழக்கே இருக்கிற குதிரைவாசலின் கோடிமட்டும் உண்டான சகல நிலங்களும் கர்த்தருக்குப் பரிசுத்தமாயிருக்கும்; அப்புறம் அது என்றென்றைக்கும் பிடுங்கப்படுவதுமில்லை இடிக்கப்படுவதுமில்லை என்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah31