import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Joel3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோவேல் 3 </title>
	<meta
          name="description"
          content="Joel 3 | யோவேல் 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, யூதாவுக்கும் எருசலேமுக்கும் உண்டாயிருக்கிற சிறையிருப்பை நான் திருப்பும் அந்நாட்களிலும் அக்காலத்திலும்,
	<br /><br />
	2. நான் சகல ஜாதியாரையும் கூட்டி, யோசபாத்தின் பள்ளத்தாக்கிலே அவர்களை இறங்கிப்போகப்பண்ணி, அவர்கள் என் ஜனத்தையும் இஸ்ரவேலென்னும் என் சுதந்தரத்தையும் புறஜாதிகளுக்குள்ளே சிதறடித்து என் தேசத்தைப் பங்கிட்டுக்கொண்டதினிமித்தமும்,
	<br /><br />
	3. அவர்கள் என் ஜனத்தின்பேரில் சீட்டுப்போட்டு, ஆண்குழந்தைகளை வேசிப்பணையமாகக் கொடுத்து, மதுபானம் பண்ணும்படி, பெண் குழந்தைகளைத் திராட்சரசத்துக்குக் கிரயமாகக் கொடுத்ததினிமித்தமும், அங்கே அவர்களோடு வழக்காடுவேன்.
	<br /><br />
	4. தீருவே, சீதோனே, பெலிஸ்தியாவின் சகல எல்லைகளே, உங்களுக்கும் எனக்கும் என்ன? இப்படி எனக்குச் சரிக்கட்டுகிறீர்களோ? இப்படி எனக்குச் சரிக்கட்டுவீர்களாகில், நான் தாமதமின்றி அதிசீக்கிரமாய் நீங்கள் சரிக்கட்டுகிறதை உங்கள் தலையின்மேல் திரும்பும்படி செய்வேன்.
	<br /><br />
	5. நீங்கள் என் வெள்ளியையும் என் பொன்னையும் எடுத்து, இன்பமும் உச்சிதமுமான என் பொருள்களை உங்கள் கோவில்களிலே கொண்டுபோய்,
	<br /><br />
	6. யூதாவின் குமாரரையும் எருசலேமின் குமாரரையும் அவர்களுடைய எல்லைகளுக்குத் தூரமாக்கும்படிக்கு, கிரேக்கரிடத்தில் விற்றுப்போட்டீர்கள்.
	<br /><br />
	7. இதோ, நீங்கள் அவர்களை விற்றுப்போட்ட அவ்விடத்திலிருந்து நான் அவர்களை எழும்பிவரப்பண்ணி, நீங்கள் சரிக்கட்டினதை உங்கள் தலையின்மேல் திரும்பும்படி செய்து,
	<br /><br />
	8. உங்கள் குமாரரையும் உங்கள் குமாரத்திகளையும் யூதா புத்திரரின் கையிலே விற்பேன்; இவர்கள் அவர்களைத் தூரதேசத்தாராகிய சபேயரிடத்தில் விற்றுப்போடுவார்கள்; கர்த்தர் இதைச் சொன்னார்.
	<br /><br />
	9. இதைப் புறஜாதிகளுக்குள்ளே கூறுங்கள்; யுத்தத்துக்கு ஆயத்தம்பண்ணுங்கள், பராக்கிரமசாலிகளை எழுப்புங்கள்; யுத்தவீரர் எல்லாரும் சேர்ந்து ஏறிவரக்கடவர்கள்.
	<br /><br />
	10. உங்கள் மண்வெட்டிகளைப் பட்டயங்களாகவும், உங்கள் அரிவாள்களை ஈட்டிகளாகவும் அடியுங்கள்; பலவீனனும் தன்னைப் பலவான் என்று சொல்வானாக.
	<br /><br />
	11. சகல ஜாதிகளே, நீங்கள் சுற்றிலுமிருந்து ஏகமாய் வந்து கூடுங்கள்; கர்த்தாவே, நீரும் அங்கே உம்முடைய பராக்கிரமசாலிகளை இறங்கப்பண்ணுவீராக.
	<br /><br />
	12. ஜாதிகள் எழும்பி யோசபாத்தின் பள்ளத்தாக்குக்கு வருவார்களாக; சுற்றிலுமுள்ள ஜாதிகளை நியாயந்தீர்க்க அங்கே நான் வீற்றிருப்பேன்.
	<br /><br />
	13. பயிர் முதிர்ந்தது, அரிவாளை நீட்டி அறுங்கள், வந்து இறங்குங்கள்; ஆலை நிரம்பியிருக்கிறது, ஆலையின் தொட்டிகள் வழிந்தோடுகிறது; அவர்களுடைய பாதகம் பெரியது.
	<br /><br />
	14. நியாயத்தீர்ப்பின் பள்ளத்தாக்கிலே ஜனங்கள் திரள்திரளாய் இருக்கிறார்கள்; நியாயத்தீர்ப்பின் பள்ளத்தாக்கிலே கர்த்தரின் நாள் சமீபமாயிருக்கிறது.
	<br /><br />
	15. சூரியனும் சந்திரனும் இருண்டுபோகும், நட்சத்திரங்கள் ஒளிமழுங்கும்.
	<br /><br />
	16. கர்த்தர் சீயோனிலிருந்து கெர்ச்சித்து, எருசலேமிலிருந்து சத்தமிடுவார்; வானமும் பூமியும் அதிரும்; ஆனாலும் கர்த்தர் தமது ஜனத்துக்கு அடைக்கலமும் இஸ்ரவேல் புத்திரருக்கு அரணான கோட்டையுமாயிருப்பார்.
	<br /><br />
	17. என் பரிசுத்த பர்வதமாகிய சீயோனிலே வாசமாயிருக்கிற நான் உங்கள் தேவனாகிய கர்த்தர் என்று அப்பொழுது அறிந்துகொள்வீர்கள்; அப்பொழுது எருசலேம் பரிசுத்தமாயிருக்கும்; அந்நியர் இனி அதைக் கடந்துபோவதில்லை.
	<br /><br />
	18. அக்காலத்தில் பர்வதங்கள் திராட்சரசத்தைப் பொழியும், மலைகள் பாலாய் ஓடும், யூதாவின் ஆறுகள் எல்லாம் பிரவாகித்து ஓடும்; ஒரு ஊற்று கர்த்தருடைய ஆலயத்திலிருந்து புறப்பட்டுச் சித்தீம் என்னும் பள்ளத்தாக்கை நீர்ப்பாய்ச்சலாக்கும்.
	<br /><br />
	19. யூதா புத்திரரின் தேசத்திலே குற்றமில்லாத இரத்தத்தைச் சிந்தி, அவர்களுக்குச் செய்த கொடுமையினிமித்தம் எகிப்து பாழாய்ப்போகும், ஏதோம் பாழான வனாந்தரமாகும்.
	<br /><br />
	20. ஆனால் யூதாவோ சதாகாலமாகவும், எருசலேம் தலைமுறை தலைமுறையாகவும் குடியேற்றப்பட்டிருக்கும்.
	<br /><br />
	21. நான் தண்டியாமல் விட்டிருந்த அவர்களுடைய இரத்தப்பழியைத் தண்டியாமல் விடேன்; கர்த்தர் சீயோனிலே வாசமாயிருக்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Joel3