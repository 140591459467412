import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ephesians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Book of Ephesians Bible Tamil | எபேசியர் 2 </title>
	<meta
          name="description"
          content="Ephesians 2 | எபேசியர் 2 |
          Book of Ephesians | Tamil Bible Verse | Tamil Bible | எபேசியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்கிரமங்களினாலும் பாவங்களினாலும் மரித்தவர்களாயிருந்த உங்களை உயிர்ப்பித்தார்.
	<br /><br />
	2. அவைகளில் நீங்கள் முற்காலத்திலே இவ்வுலக வழக்கத்திற்கேற்றபடியாகவும், கீழ்ப்படியாமையின் பிள்ளைகளிடத்தில் இப்பொழுது கிரியைசெய்கிற ஆகாயத்து அதிகாரப் பிரபுவாகிய ஆவிக்கேற்றபடியாகவும் நடந்துகொண்டீர்கள்.
	<br /><br />
	3. அவர்களுக்குள்ளே நாமெல்லாரும் முற்காலத்திலே நமது மாம்ச இச்சையின்படியே நடந்து, நமது மாம்சமும் மனசும் விரும்பினவைகளைச் செய்து, சுபாவத்தினாலே மற்றவர்களைப்போலக் கோபாக்கினையின் பிள்ளைகளாயிருந்தோம்.
	<br /><br />
	4. தேவனோ இரக்கத்தில் ஐசுவரியமுள்ளவராய் நம்மில் அன்புகூர்ந்த தம்முடைய மிகுந்த அன்பினாலே,
	<br /><br />
	5. அக்கிரமங்களில் மரித்தவர்களாயிருந்த நம்மைக் கிறிஸ்துவுடனேகூட உயிர்ப்பித்தார்; கிருபையினாலே இரட்சிக்கப்பட்டீர்கள்.
	<br /><br />
	6. கிறிஸ்து இயேசுவுக்குள் அவர் நம்மிடத்தில் வைத்த தயவினாலே, தம்முடைய கிருபையின் மகா மேன்மையான ஐசுவரியத்தை வருங்காலங்களில் விளங்கச்செய்வதற்காக,
	<br /><br />
	7. கிறிஸ்து இயேசுவுக்குள் நம்மை அவரோடேகூட எழுப்பி, உன்னதங்களிலே அவரோடேகூட உட்காரவும் செய்தார்.
	<br /><br />
	8. கிருபையினாலே விசுவாசத்தைக்கொண்டு இரட்சிக்கப்பட்டீர்கள்; இது உங்களால் உண்டானதல்ல, இது தேவனுடைய ஈவு;
	<br /><br />
	9. ஒருவரும் பெருமைபாராட்டாதபடிக்கு இது கிரியைகளினால் உண்டானதல்ல;
	<br /><br />
	10. ஏனெனில், நற்கிரியைகளைச் செய்கிறதற்கு நாம் கிறிஸ்து இயேசுவுக்குள் சிருஷ்டிக்கப்பட்டு, தேவனுடைய செய்கையாயிருக்கிறோம்; அவைகளில் நாம் நடக்கும்படி அவர் முன்னதாக அவைகளை ஆயத்தம்பண்ணியிருக்கிறார்.
	<br /><br />
	11. ஆனபடியினால் முன்னே மாம்சத்தின்படி புறஜாதியாராயிருந்து, மாம்சத்தில் கையினாலே செய்யப்படுகிற விருத்தசேதனமுடையவர்களால் விருத்தசேதனமில்லாதவர்களென்னப்பட்ட நீங்கள்,
	<br /><br />
	12. அக்காலத்திலே கிறிஸ்துவைச் சேராதவர்களும், இஸ்ரவேலுடைய காணியாட்சிக்குப் புறம்பானவர்களும், வாக்குத்தத்தத்தின் உடன்படிக்கைகளுக்கு அந்நியரும், நம்பிக்கையில்லாதவர்களும், இவ்வுலகத்தில் தேவனற்றவர்களுமாயிருந்தீர்களென்று நினைத்துக்கொள்ளுங்கள்.
	<br /><br />
	13. முன்னே தூரமாயிருந்த நீங்கள் இப்பொழுது கிறிஸ்து இயேசுவுக்குள் கிறிஸ்துவின் இரத்தத்தினாலே சமீபமானீர்கள்.
	<br /><br />
	14. எப்படியெனில், அவரே நம்முடைய சமாதான காரணராகி, இருதிறத்தாரையும் ஒன்றாக்கி, பகையாக நின்ற பிரிவினையாகிய நடுச்சுவரைத் தகர்த்து,
	<br /><br />
	15. சட்டதிட்டங்களாகிய நியாயப்பிரமாணத்தைத் தம்முடைய மாம்சத்தினாலே ஒழித்து, இருதிறத்தாரையும் தமக்குள்ளாக ஒரே புதிய மனுஷனாகச் சிருஷ்டித்து, இப்படிச் சமாதானம்பண்ணி,
	<br /><br />
	16. பகையைச் சிலுவையினால் கொன்று, அதினாலே இருதிறத்தாரையும் ஒரே சரீரமாகத் தேவனுக்கு ஒப்புரவாக்கினார்.
	<br /><br />
	17. அல்லாமலும் அவர் வந்து, தூரமாயிருந்த உங்களுக்கும், சமீபமாயிருந்த அவர்களுக்கும், சமாதானத்தைச் சுவிசேஷமாக அறிவித்தார்.
	<br /><br />
	18. அந்தப்படியே நாம் இருதிறத்தாரும் ஒரே ஆவியினாலே பிதாவினிடத்தில் சேரும் சிலாக்கியத்தை அவர்மூலமாய்ப் பெற்றிருக்கிறோம்.
	<br /><br />
	19. ஆகையால், நீங்கள் இனி அந்நியரும் பரதேசிகளுமாயிராமல், பரிசுத்தவான்களோடே ஒரே நகரத்தாரும் தேவனுடைய வீட்டாருமாயிருந்து,
	<br /><br />
	20. அப்போஸ்தலர் தீர்க்கதரிசிகள் என்பவர்களுடைய அஸ்திபாரத்தின்மேல் கட்டப்பட்டவர்களுமாயிருக்கிறீர்கள்; அதற்கு இயேசுகிறிஸ்து தாமே மூலைக்கல்லாயிருக்கிறார்;
	<br /><br />
	21. அவர்மேல் மாளிகை முழுவதும் இசைவாய் இணைக்கப்பட்டு, கர்த்தருக்குள் பரிசுத்த ஆலயமாக எழும்புகிறது;
	<br /><br />
	22. அவர்மேல் நீங்களும் ஆவியினாலே தேவனுடைய வாசஸ்தலமாகக் கூட்டிக்கட்டப்பட்டுவருகிறீர்கள்.
</p>


      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ephesians2