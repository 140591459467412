import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 3 </title>
	<meta
          name="description"
          content="Daniel 3 | தானியேல் தீர்க்கதரிசி | தானியேல் 3 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ராஜாவாகிய நேபுகாத்நேச்சார் அறுபதுமுழ உயரமும் ஆறு முழ அகலமுமான ஒரு பொற்சிலையைப் பண்ணுவித்து, பாபிலோன் மாகாணத்திலிருக்கிற தூரா என்னும் சமபூமியிலே நிறுத்தினான்.
	<br /><br />
	2. பின்பு ராஜாவாகிய நேபுகாத்நேச்சார் தேசாதிபதிகளையும், அதிகாரிகளையும், தலைவரையும், நியாயாதிபதிகளையும், பொக்கிஷக்காரரையும், நீதிசாஸ்திரிகளையும், விசாரிப்புக்காரரையும், நாடுகளிலுள்ள உத்தியோகஸ்தர் யாவரையும் நேபுகாத்நேச்சார் ராஜா நிறுத்தின சிலையின் பிரதிஷ்டைக்கு வந்து சேரும்படி அழைத்தனுப்பினான்.
	<br /><br />
	3. அப்பொழுது தேசாதிபதிகளும், அதிகாரிகளும், தலைவரும், நியாயாதிபதிகளும், பொக்கிஷக்காரரும், நீதிசாஸ்திரிகளும், விசாரிப்புக்காரரும், நாடுகளிலுள்ள உத்தியோகஸ்தர் யாவரும், ராஜாவாகிய நேபுகாத்நேச்சார் நிறுத்தின சிலையின் பிரதிஷ்டைக்கு வந்துசேர்ந்து, நேபுகாத்நேச்சார் நிறுத்தின சிலைக்கு எதிராக நின்றார்கள்.
	<br /><br />
	4. கட்டியக்காரன் உரத்த சத்தமாய்: சகல ஜனங்களும், ஜாதிகளும், பாஷைக்காரருமானவர்களே, உங்களுக்கு அறிவிக்கப்படுகிறது என்னவென்றால்:
	<br /><br />
	5. எக்காளம், நாகசுரம், கின்னரம், வீணை, சுரமண்டலம், தம்புரு முதலான சகலவித கீதவாத்தியங்களின் சத்தத்தை நீங்கள் கேட்கும்போது, நீங்கள் தாழவிழுந்து, ராஜாவாகிய நேபுகாத்நேச்சார் நிறுத்தின பொற்சிலையைப் பணிந்துகொள்ளக்கடவீர்கள்.
	<br /><br />
	6. எவனாகிலும் தாழ விழுந்து, அதைப் பணிந்துகொள்ளாமற்போனால், அவன் அந்நேரமே எரிகிற அக்கினிச்சூளையின் நடுவிலே போடப்படுவான் என்றான்.
	<br /><br />
	7. ஆதலால் சகல ஜனங்களும், எக்காளம், நாகசுரம், கின்னரம், வீணை, சுரமண்டலம் முதலான சகலவித கீதவாத்தியங்களின் சத்தத்தைக் கேட்டவுடனே, சகல ஜனத்தாரும் ஜாதியாரும் பாஷைக்காரரும் தாழ விழுந்து, ராஜாவாகிய நேபுகாத்நேச்சார் நிறுத்தின பொற்சிலையைப் பணிந்துகொண்டார்கள்.
	<br /><br />
	8. அச்சமயத்தில் கல்தேயரில் சிலர் ராஜசமுகத்தில் வந்து, யூதர்பேரில் குற்றஞ்சாற்றி,
	<br /><br />
	9. ராஜாவாகிய நேபுகாத்நேச்சாரை நோக்கி: ராஜாவே, நீர் என்றும் வாழ்க.
	<br /><br />
	10. எக்காளம், நாகசுரம், கின்னரம், வீணை, சுரமண்டலம், தம்புரு முதலான சகலவித கீதவாத்தியங்களின் சத்தத்தையும் கேட்கிற எந்த மனுஷனும் தாழவிழுந்து, பொற்சிலையைப் பணிந்துகொள்ளவேண்டுமென்றும்,
	<br /><br />
	11. எவனாகிலும் தாழ விழுந்து பணிந்துகொள்ளாமற்போனால், அவன் எரிகிற அக்கினிச்சூளையின் நடுவிலே போடப்படவேண்டுமென்றும், ராஜாவாகிய நீர் கட்டளையிட்டீரே.
	<br /><br />
	12. பாபிலோன் மாகாணத்தின் காரியங்களை விசாரிக்கும்படி நீர் ஏற்படுத்தின சாத்ராக், மேஷாக், ஆபேத்நேகோ என்னும் யூதரான மனுஷர் இருக்கிறார்களே; அவர்கள் ராஜாவாகிய உம்மை மதிக்கவில்லை; அவர்கள் உம்முடைய தேவர்களுக்கு ஆராதனை செய்யாமலும், நீர் நிறுத்தின பொற்சிலையைப் பணிந்துகொள்ளாமலும் இருக்கிறார்கள் என்றார்கள்.
	<br /><br />
	13. அப்பொழுது நேபுகாத்நேச்சார் உக்கிரகோபங்கொண்டு சாத்ராக்கையும், மேஷாக்கையும், ஆபேத்நேகோவையும் அழைத்துக்கொண்டுவரும்படி கட்டளையிட்டான்; அவர்கள் அந்தப் புருஷரை ராஜாவின் சமுகத்தில் கொண்டுவந்து விட்டபோது,
	<br /><br />
	14. நேபுகாத்நேச்சார் அவர்களை நோக்கி: சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களே, நீங்கள் என் தேவர்களுக்கு ஆராதனைசெய்யாமலும் நான் நிறுத்தின பொற்சிலையைப் பணிந்துகொள்ளாமலும் இருந்தது மெய்தானா?
	<br /><br />
	15. இப்போதும் எக்காளம், நாகசுரம், கின்னரம், வீணை, சுரமண்டலம், தம்புரு முதலான சகலவித கீதவாத்தியங்களின் சத்தத்தையும் நீங்கள் கேட்கும்போது, தாழ விழுந்து, நான் பண்ணிவைத்த சிலையைப் பணிந்துகொள்ள ஆயத்தமாயிருந்தால் நல்லது; பணிந்துகொள்ளாதிருந்தீர்களேயாகில், அந்நேரமே எரிகிற அக்கினிச்சூளையின் நடுவிலே போடப்படுவீர்கள்; உங்களை என் கைக்குத் தப்புவிக்கப்போகிற தேவன் யார் என்றான்.
	<br /><br />
	16. சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்கள் ராஜாவை நோக்கி: நேபுகாத்நேச்சாரே, இந்தக் காரியத்தைக் குறித்து உமக்கு உத்தரவு சொல்ல எங்களுக்கு அவசியமில்லை.
	<br /><br />
	17. நாங்கள் ஆராதிக்கிற எங்கள் தேவன் எங்களைத் தப்புவிக்க வல்லவராயிருக்கிறார்; அவர் எரிகிற அக்கினிச்சூளைக்கும், ராஜாவாகிய உம்முடைய கைக்கும் நீங்கலாக்கி விடுவிப்பார்.
	<br /><br />
	18. விடுவிக்காமற்போனாலும், நாங்கள் உம்முடைய தேவர்களுக்கு ஆராதனை செய்வதுமில்லை, நீர் நிறுத்தின பொற்சிலையைப் பணிந்துகொள்வதுமில்லை என்கிறது ராஜாவாகிய உமக்குத் தெரிந்திருக்கக்கடவது என்றார்கள்.
	<br /><br />
	19. அப்பொழுது நேபுகாத்நேச்சாருக்குக் கடுங்கோபமூண்டு: சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களுக்கு விரோதமாய் அவனுடைய முகம் வேறுபட்டது; சூளையைச் சாதாரணமாய்ச் சூடாக்குவதைப்பார்க்கிலும் ஏழுமடங்கு அதிகமாய்ச் சூடாக்கும்படி உத்தரவுகொடுத்து,
	<br /><br />
	20. சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களை எரிகிற அக்கினிச்சூளையிலே போடுவதற்கு அவர்களைக் கட்டும்படி, தன் இராணுவத்தில் பலசாலிகளாகிய புருஷருக்குக் கட்டளையிட்டான்.
	<br /><br />
	21. அப்பொழுது அவர்கள் தங்கள் சால்வைகளோடும் நிசார்களோடும் பாகைகளோடும் மற்ற வஸ்திரங்களோடும் கட்டப்பட்டு, எரிகிற அக்கினிச்சூளையின் நடுவிலே போடப்பட்டார்கள்.
	<br /><br />
	22. ராஜாவின் கட்டளை கடுமையாயிருந்தபடியினாலும், சூளை மிகவும் சூடாக்கப்பட்டிருந்தபடியினாலும், அக்கினிஜுவாலையானது சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களைத் தூக்கிக்கொண்டுபோன புருஷரைக் கொன்றுபோட்டது.
	<br /><br />
	23. சாத்ராக், மேஷாக், ஆபேத்நேகோ என்னும் அந்த மூன்று புருஷரும் கட்டுண்டவர்களாய் எரிகிற அக்கினிச்சூளையின் நடுவிலே விழுந்தார்கள்.
	<br /><br />
	24. அப்பொழுது ராஜாவாகிய நேபுகாத்நேச்சார் பிரமித்து, தீவிரமாய் எழுந்திருந்து, தன் மந்திரிமார்களை நோக்கி: மூன்று புருஷரை அல்லவோ கட்டுண்டவர்களாக அக்கினியிலே போடுவித்தோம் என்றான்; அவர்கள் ராஜாவுக்குப் பிரதியுத்தரமாக: ஆம், ராஜாவே என்றார்கள்.
	<br /><br />
	25. அதற்கு அவன்: இதோ, நாலுபேர் விடுதலையாய் அக்கினியின் நடுவிலே உலாவுகிறதைக் காண்கிறேன்; அவர்களுக்கு ஒரு சேதமுமில்லை; நாலாம் ஆளின் சாயல் தேவபுத்திரனுக்கு ஒப்பாயிருக்கிறது என்றான்.
	<br /><br />
	26. அப்பொழுது நேபுகாத்நேச்சார் எரிகிற அக்கினிச்சூளையின் வாசலண்டைக்கு வந்து, உன்னதமான தேவனுடைய தாசராகிய சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களே, நீங்கள் வெளியே வாருங்கள் என்றான்; அப்பொழுது சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்கள் அக்கினியின் நடுவிலிருந்து வெளியே வந்தார்கள்.
	<br /><br />
	27. தேசாதிபதிகளும், அதிகாரிகளும், தலைவரும், ராஜாவின் மந்திரிகளும் கூடிவந்து, அந்தப் புருஷருடைய சரீரங்களின்மேல் அக்கினி பெலஞ்செய்யாமலும், அவர்களுடைய தலைமயிர் கருகாமலும், அவர்களுடைய சால்வைகள் சேதப்படாமலும், அக்கினியின் மணம் அவர்களிடத்தில் வீசாமலும் இருந்ததைக் கண்டார்கள்.
	<br /><br />
	28. அப்பொழுது நேபுகாத்நேச்சார் வசனித்து: சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களுடைய தேவனுக்கு ஸ்தோத்திரம்; அவர்கள் தங்களுடைய தேவனைத்தவிர வேறொரு தேவனையும் சேவித்துப் பணியாமல், அவரையே நம்பி, ராஜாவின் கட்டளையைத் தள்ளி, தங்கள் சரீரங்களை ஒப்புக்கொடுத்ததினால், அவர் தமது தூதனை அனுப்பி, தம்முடைய தாசரை விடுவித்தார்.
	<br /><br />
	29. ஆதலால் சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களுடைய தேவனுக்கு விரோதமாகத் தூஷண வார்த்தையைச் சொல்லுகிற எந்த ஜனத்தானும், எந்த ஜாதியானும், எந்தப் பாஷைக்காரனும் துண்டித்துப்போடப்படுவான்; அவன் வீடு எருக்களமாக்கப்படும் என்று என்னாலே தீர்மானிக்கப்படுகிறது; இவ்விதமாய் இரட்சிக்கத்தக்க தேவன் வேறொருவரும் இல்லையென்றான்.
	<br /><br />
	30. பின்பு ராஜா சாத்ராக், மேஷாக், ஆபேத்நேகோ என்பவர்களைப் பாபிலோன் தேசத்திலே உயர்த்தினான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel3