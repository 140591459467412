import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 9 </title>
	<meta
          name="description"
          content="Jeremiah 9 | எரேமியா 9 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆ, என் தலை தண்ணீரும், என் கண்கள் கண்ணீரூற்றுமானால் நலமாயிருக்கும்; அப்பொழுது என் ஜனமாகிய குமாரத்தி கொலையுண்ணக் கொடுத்தவர்கள் நிமித்தம் நான் இரவும்பகலும் அழுவேன்.
	<br /><br />
	2. ஆ, வனாந்தரத்தில் வழிப்போக்கரின் தாபரம் எனக்கு இருந்தால் நலமாயிருக்கும்; அப்பொழுது நான் என் ஜனத்தைவிட்டு, அவர்களிடத்தில் இராதபடிக்குப் போய்விடுவேன்; அவர்களெல்லாரும் விபசாரரும் துரோகிகளின் கூட்டமுமாயிருக்கிறார்கள்.
	<br /><br />
	3. அவர்கள் பொய்யைப் பிரயோகிக்கத் தங்கள் நாவாகிய வில்லை வளைக்கிறார்கள்; அவர்கள் இந்தத் தேசத்திலே பலத்துக்கொள்வது சத்தியத்துக்காக அல்ல; பொல்லாப்பிலிருந்து பொல்லாப்புக்கு நடந்தேறுகிறார்கள்; என்னையோ அறியாதிருக்கிறார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. நீங்கள் அவனவன் தன் தன் சிநேகிதனுக்கு எச்சரிக்கையாயிருங்கள், எந்தச் சகோதரனையும் நம்பாதிருங்கள்; எந்தச் சகோதரனும் மோசம்பண்ணுகிறான், எந்தச் சிநேகிதனும் தூற்றித்திரிகிறான்.
	<br /><br />
	5. அவர்கள் மெய்யைப் பேசாமல் ஒவ்வொருவரும் தமக்கடுத்தவனை ஏய்க்கிறார்கள்; பொய்யைப்பேசத் தங்கள் நாவைப் பழக்குகிறார்கள், அக்கிரமஞ்செய்ய உழைக்கிறார்கள்.
	<br /><br />
	6. கபடத்தின் நடுவிலே குடியிருக்கிறாய்; கபடத்தினிமித்தம் அவர்கள் என்னை அறியமாட்டோமென்கிறார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. ஆகையால், இதோ, நான் அவர்களை உருக்கி, அவர்களைப் புடமிடுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; என் ஜனமாகிய குமாரத்தியை வேறெந்தப்பிரகாரமாக நடத்துவேன்?
	<br /><br />
	8. அவர்கள் நாவு கூர்மையாக்கப்பட்ட அம்பு, அது கபடம் பேசுகிறது; அவனவன் தன் தன் அயலானோடே தன் தன் வாயினாலே சமாதானமாய்ப் பேசுகிறான், ஆனாலும் தன் உள்ளத்திலே அவனுக்குப் பதிவிடை வைக்கிறான்.
	<br /><br />
	9. இவைகளினிமித்தம் அவர்களை விசாரியாதிருப்பேனோ? இப்படிப்பட்ட ஜாதிக்கு என் ஆத்துமா நீதியைச் சரிக்கட்டாதிருக்குமோ என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. மலைகளுக்காக அழுது துக்கங்கொண்டாடுவேன்; வனாந்தரத் தாபரங்களுக்காகப் புலம்புவேன்; ஒருவனும் அவைகளைக் கடந்து போகாதவண்ணமாய் அவைகள் பாழாக்கப்பட்டுக் கிடக்கின்றன; ஆடுமாடுகளின் சத்தம் கேட்கப்படுகிறதுமில்லை; ஆகாசத்துப் பறவைகளும் மிருகஜீவன்களும் எல்லாம் ஓடிச் சிதறிப்போயின.
	<br /><br />
	11. நான் எருசலேமை மண்மேடுகளும் வலுசர்ப்பங்களின் தாபரமுமாக்குவேன்; யூதாவின் பட்டணங்களையும் குடியில்லாதபடி பாழாக்கிப்போடுவேன்.
	<br /><br />
	12. இதை உணரத்தக்க ஞானமுள்ளவன் யார்? தேசம் அழிந்து, ஒருவனும் கடந்து போகாதபடி அது பாழாக்கப்படுகிற முகாந்தரமென்னவென்று கர்த்தருடைய வாய் தன்னுடனே சொல்லுகிறதைக் கேட்டு அறிவிக்கத்தக்கவன் யார்?
	<br /><br />
	13. நான் அவரவருக்கு விதித்த என் நியாயப்பிரமாணத்தை அவர்கள் விட்டு, என் சொல்லைக் கேளாமலும், அதின்படி நடவாமலும்,
	<br /><br />
	14. தங்களுடைய இருதயத்தின் கடினத்தையும், தங்கள் பிதாக்கள் தங்களுக்குக் கற்றுக்கொடுத்தபடி பாகால்களையும் பின்தொடர்ந்தார்களே என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	15. ஆதலால், இதோ, நான் இந்த ஜனத்துக்குப் புசிக்க எட்டியையும், குடிக்கப் பிச்சுக்கலந்த தண்ணீரையும் கொடுத்து,
	<br /><br />
	16. அவர்களும், அவர்கள் பிதாக்களும் அறியாத புறஜாதிகளுக்குள்ளே அவர்களைச் சிதறடித்து, பட்டயம் அவர்களை நிர்மூலமாக்குமட்டும் அதை அவர்களுக்குப் பின்னாக அனுப்புவேன் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. நீங்கள் யோசனைபண்ணி, புலம்பற்காரிகளை வரவழைத்து, அதிலே பழகின ஸ்திரீகளைக் கூப்பிடுங்களென்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. அவர்கள் சீக்கிரமாய் வந்து, நம்முடைய கண்கள் கண்ணீராய்ச் சொரியத்தக்கதாகவும், நம்முடைய இமைகள் தண்ணீராய் ஓடத்தக்கதாகவும், ஒப்பாரி சொல்லக்கடவர்கள்.
	<br /><br />
	19. எத்தனையாய்ப் பாழாக்கப்பட்டோம்! மிகவும் கலங்கியிருக்கிறோம்; நாங்கள் தேசத்தை விட்டுப்போகிறோம், எங்கள் வாசஸ்தலங்களை அவர்கள் கவிழ்த்துப்போட்டார்கள் என்று சீயோனிலிருந்து உண்டாகிற புலம்பலின் சத்தம் கேட்கப்படும்.
	<br /><br />
	20. ஆதலால் ஸ்திரீகளே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்; உங்கள் செவி அவருடைய வாயின் வசனத்தை ஏற்றுக்கொள்ளட்டும்; நீங்கள் உங்கள் குமாரத்திகளுக்கு ஒப்பாரியையும், அவளவள் தன் தன் தோழிக்குப் புலம்பலையும் கற்றுக்கொடுங்கள்.
	<br /><br />
	21. வீதியிலிருக்கிற குழந்தைகளையும், தெருக்களிலிருக்கிற இளைஞரையும் சங்காரம்பண்ணச் சாவு நம்முடைய பலகணிகளிலேறி, நம்முடைய அரமனைகளில் பிரவேசித்தது.
	<br /><br />
	22. மனுஷரின் சவங்கள் வயல்வெளியின்மேல் எருவைப்போலவும், அறுக்கிறவனுக்குப் பின்னாலே ஒருவனும் வாரிக்கொள்ளாதிருக்கிற அரியைப்போலவும் கிடக்கும் என்று கர்த்தர் உரைத்தார் என்று சொல்.
	<br /><br />
	23. ஞானி தன் ஞானத்தைக்குறித்து மேன்மைபாராட்டவேண்டாம்; பராக்கிரமன் தன் பராக்கிரமத்தைக்குறித்து மேன்மைபாராட்டவேண்டாம்; ஐசுவரியவான் தன் ஐசுவரியத்தைக்குறித்து மேன்மைபாராட்டவேண்டாம்;
	<br /><br />
	24. மேன்மைபாராட்டுகிறவன் பூமியிலே கிருபையையும் நியாயத்தையும் நீதியையும் செய்கிற கர்த்தர் நான் என்று என்னை அறிந்து உணர்ந்திருக்கிறதைக்குறித்தே மேன்மைபாராட்டக்கடவன் என்று கர்த்தர் சொல்லுகிறார்; இவைகளின்மேல் பிரியமாயிருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	25. இதோ, நாட்கள் வரும்; அப்பொழுது விருத்தசேதனமில்லாதவர்களோடுங்கூட விருத்தசேதனமுள்ள யாவரையும்,
	<br /><br />
	26. எகிப்தையும், யூதாவையும், ஏதோமையும், அம்மோன் புத்திரரையும், மோவாபையும், கடைசி எல்லைகளிலுள்ள வனாந்தரக்குடிகளான யாவரையும் தண்டிப்பேன்; புறஜாதியார் அனைவரும் விருத்தசேதனமில்லாதவர்கள்; ஆனாலும், இஸ்ரவேல் வம்சத்தார் அனைவரும் இருதயத்திலே விருத்தசேதனமில்லாதவர்கள் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah9