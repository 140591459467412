import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneThessalonians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO THESSALONIANS 4 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE THESSALONIANS Chapter 4 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 4 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. அன்றியும், சகோதரரே, நீங்கள் இன்னின்ன பிரகாரமாய் நடக்கவும், தேவனுக்குப் பிரியமாயிருக்கவும் வேண்டுமென்று, நீங்கள் எங்களால் கேட்டு ஏற்றுக்கொண்டபடியே, அதிகமதிகமாய்த் தேறும்படிக்கு, கர்த்தராகிய இயேசுவுக்குள் உங்களை வேண்டிக்கொண்டு புத்திசொல்லுகிறோம்.
	<br /><br />
	2. கர்த்தராகிய இயேசுவினாலே நாங்கள் உங்களுக்குக் கொடுத்த கட்டளைகளை அறிந்திருக்கிறீர்களே.
	<br /><br />
	3. நீங்கள் பரிசுத்தமுள்ளவர்களாகவேண்டுமென்பதே தேவனுடைய சித்தமாயிருக்கிறது. அந்தப்படி, நீங்கள் வேசிமார்க்கத்துக்கு விலகியிருந்து,
	<br /><br />
	4. தேவனை அறியாத அஞ்ஞானிகளைப்போல மோக இச்சைக்குட்படாமல்,
	<br /><br />
	5. உங்களில் அவனவன் தன்தன் சரீர பாண்டத்தைப் பரிசுத்தமாயும் கனமாயும் ஆண்டுகொள்ளும்படி அறிந்து:
	<br /><br />
	6. இந்த விஷயத்தில் ஒருவனும் மீறாமலும் தன் சகோதரனை வஞ்சியாமலும் இருக்கவேண்டும்; முன்னமே நாங்கள் உங்களுக்குச் சொல்லி, சாட்சியாக எச்சரித்தபடியே இப்படிப்பட்ட விஷயங்களெல்லாவற்றையுங்குறித்துக் கர்த்தர் நீதியைச் சரிக்கட்டுகிறவராயிருக்கிறார்.
	<br /><br />
	7. தேவன் நம்மை அசுத்தத்திற்கல்ல பரிசுத்தத்திற்கே அழைத்திருக்கிறார்.
	<br /><br />
	8. ஆகையால் அசட்டைபண்ணுகிறவன் மனுஷரை அல்ல, தமது பரிசுத்தஆவியை நமக்குத் தந்தருளின தேவனையே அசட்டைபண்ணுகிறான்.
	<br /><br />
	9. சகோதர சிநேகத்தைக்குறித்து நான் உங்களுக்கு எழுதவேண்டுவதில்லை; நீங்கள் ஒருவரிலொருவர் அன்பாயிருக்கும்படிக்குத் தேவனால் போதிக்கப்பட்டவர்களாயிருக்கிறீர்களே.
	<br /><br />
	10. அந்தப்படி நீங்கள் மக்கெதோனியா நாடெங்குமுள்ள சகோதரரெல்லாருக்கும் செய்துவருகிறீர்கள். சகோதரரே, அன்பிலே நீங்கள் இன்னும் அதிகமாய்ப் பெருகவும்;
	<br /><br />
	11. புறம்பேயிருக்கிறவர்களைப் பற்றி யோக்கியமாய் நடந்து, ஒன்றிலும் உங்களுக்குக் குறைவில்லாதிருக்கும்படிக்கு,
	<br /><br />
	12. நாங்கள் உங்களுக்குக் கட்டளையிட்டபடியே, அமைதலுள்ளவர்களாயிருக்கும்படி நாடவும், உங்கள் சொந்த அலுவல்களைப் பார்க்கவும், உங்கள் சொந்தக் கைகளினாலே வேலைசெய்யவும் வேண்டுமென்று உங்களுக்குப் புத்திசொல்லுகிறோம்.
	<br /><br />
	13. அன்றியும், சகோதரரே, நித்திரையடைந்தவர்களினிமித்தம் நீங்கள் நம்பிக்கையற்றவர்களான மற்றவர்களைப்போலத் துக்கித்து, அறிவில்லாதிருக்க எனக்கு மனதில்லை.
	<br /><br />
	14. இயேசுவானவர் மரித்து பின்பு எழுந்திருந்தாரென்று விசுவாசிக்கிறோமே; அப்படியே இயேசுவுக்குள் நித்திரையடைந்தவர்களையும் தேவன் அவரோடேகூடக் கொண்டுவருவார்.
	<br /><br />
	15. கர்த்தருடைய வார்த்தையை முன்னிட்டு நாங்கள் உங்களுக்குச் சொல்லுகிறதாவது: கர்த்தருடைய வருகைமட்டும் உயிரோடிருக்கும் நாம் நித்திரையடைந்தவர்களுக்கு முந்திக்கொள்வதில்லை.
	<br /><br />
	16. ஏனெனில், கர்த்தர் தாமே ஆரவாரத்தோடும், பிரதான தூதனுடைய சத்தத்தோடும், தேவ எக்காளத்தோடும் வானத்திலிருந்து இறங்கிவருவார்; அப்பொழுது கிறிஸ்துவுக்குள் மரித்தவர்கள் முதலாவது எழுந்திருப்பார்கள்.
	<br /><br />
	17. பின்பு உயிரோடிருக்கும் நாமும் கர்த்தருக்கு எதிர்கொண்டுபோக, மேகங்கள்மேல் அவர்களோடேகூட ஆகாயத்தில் எடுத்துக்கொள்ளப்பட்டு, இவ்விதமாய் எப்பொழுதும் கர்த்தருடனேகூட இருப்போம்.
	<br /><br />
	18. ஆகையால், இந்த வார்த்தைகளினாலே நீங்கள் ஒருவரையொருவர் தேற்றுங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneThessalonians4