import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Lamentations2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible News | புலம்பல் 2 </title>
    <meta
          name="description"
          content="Lamentations 2 | புலம்பல் 2 |
          Pulambal | Tamil Bible News | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஐயோ! ஆண்டவர் தமது கோபத்தில் சீயோன் குமாரத்தியை மந்தாரத்தினால் மூடினார்; அவர் தமது கோபத்தின் நாளிலே தமது பாதபீடத்தை நினையாமல் இஸ்ரவேலின் மகிமையை வானத்திலிருந்து தரையிலே விழத்தள்ளினார்.
	<br /><br />
	2. ஆண்டவர் தப்பவிடாமல் யாக்கோபின் வாசஸ்தலங்களையெல்லாம் விழுங்கினார்; அவர் யூதா குமாரத்தியின் அரண்களையெல்லாம் தமது சினத்திலே இடித்து, தரையோடே தரையாக்கிப்போட்டார்; ராஜ்யத்தையும் அதின் பிரபுக்களையும் பரிசுத்தக்குலைச்சலாக்கினார்.
	<br /><br />
	3. அவர் தமது உக்கிரகோபத்திலே இஸ்ரவேலின் கொம்பு முழுவதையும் வெட்டிப்போட்டார்; சத்துருவுக்கு முன்பாக அவர் தமது வலதுகரத்தைப் பின்னாகத் திருப்பி, சுற்றிலும் இருப்பதைப் பட்சிக்கிற அக்கினிஜுவாலையைப்போல் யாக்கோபுக்கு விரோதமாக எரித்தார்.
	<br /><br />
	4. பகைஞனைப்போல் தம்முடைய வில்லை நாணேற்றினார்; சத்துருவைப்போல் தம்முடைய வலதுகரத்தை நீட்டிநின்று, கண்ணுக்கு இன்பமானதையெல்லாம் அழித்துப்போட்டார்; சீயோன் குமாரத்தியின் கூடாரத்திலே தம்முடைய உக்கிரத்தை அக்கினியைப்போல் சொரியப்பண்ணினார்.
	<br /><br />
	5. ஆண்டவர் பகைஞன்போலானார்; இஸ்ரவேலை விழுங்கினார்; அதின் அரமனைகளையெல்லாம் விழுங்கினார்; அதின் அரண்களை அழித்து, யூதா குமாரத்திக்கு மிகுந்த துக்கிப்பையும் சலிப்பையும் உண்டாக்கினார்.
	<br /><br />
	6. தோட்டத்தின் வேலியைப்போல இருந்த தம்முடைய வேலியைப் பலவந்தமாய்ப் பிடுங்கிப்போட்டார்; சபைகூடுகிற தம்முடைய ஸ்தலங்களை அழித்தார்; கர்த்தர் சீயோனிலே பண்டிகையையும் ஓய்வுநாளையும் மறக்கப்பண்ணி, தமது உக்கிரமான கோபத்தில் ராஜாவையும் ஆசாரியனையும் புறக்கணித்துவிட்டார்.
	<br /><br />
	7. ஆண்டவர் தமது பலிபீடத்தை ஒழித்துவிட்டார்; தமது பரிசுத்த ஸ்தலத்தை வெறுத்துவிட்டார்; அதினுடைய அரமனைகளின் மதில்களைச் சத்துருவின் கையில் ஒப்புக்கொடுத்தார்; பண்டிகைநாளில் ஆரவாரம்பண்ணுகிறதுபோல் கர்த்தரின் ஆலயத்தில் ஆரவாரம்பண்ணினார்கள்.
	<br /><br />
	8. கர்த்தர் சீயோன் குமாரத்தியின் அலங்கத்தை நிர்மூலமாக்க நினைத்தார்; நூலைப்போட்டார்; அழிக்காதபடித் தம்முடைய கையை அவர் முடக்கிக்கொண்டதில்லை; அரணிப்பையும் அலங்கத்தையும் புலம்பச்செய்தார்; அவைகள் முற்றிலும் பெலனற்றுக் கிடக்கிறது.
	<br /><br />
	9. அவள் வாசல்கள் தரையில் அமிழ்ந்திக்கிடக்கிறது; அவள் தாழ்ப்பாள்களை முறித்து உடைத்துப்போட்டார்; அவள் ராஜாவும் அவள் பிரபுக்களும் புறஜாதியாருக்குள் இருக்கிறார்கள்; வேதமுமில்லை; அவள் தீர்க்கதரிசிகளுக்குக் கர்த்தரால் தரிசனம் கிடைக்கிறதுமில்லை.
	<br /><br />
	10. சீயோன் குமாரத்தியின் மூப்பர்கள் தரையில் உட்கார்ந்து மௌனமாய் இருக்கிறார்கள்; தங்கள் தலைகளின்மேல் புழுதியைப் போட்டுக்கொள்ளுகிறார்கள்; இரட்டு உடுத்தியிருக்கிறார்கள்; எருசலேமின் கன்னியர்கள் தலைகவிழ்ந்து தரையை நோக்கிக்கொண்டிருக்கிறார்கள்.
	<br /><br />
	11. என் ஜனமாகிய குமாரத்தியின் நொறுங்குதலினிமித்தம் கண்ணீர் சொரிகிறதினால் என் கண்கள் பூத்துப்போகிறது; என் குடல்கள் கொதிக்கிறது; என் ஈரல் இளகித் தரையிலே வடிகிறது; குழந்தைகளும் பாலகரும் நகரத்தின் வீதிகளிலே மூர்ச்சித்துக்கிடக்கிறார்கள்.
	<br /><br />
	12. அவைகள் குத்துண்டவர்களைப்போல நகரத்தின் வீதிகளிலே மூர்ச்சித்துக்கிடக்கும்போதும், தங்கள் தாய்களின் மடியிலே தங்கள் பிராணனை விடும்போதும், தங்கள் தாய்களை நோக்கி: தானியமும் திராட்சரசமும் எங்கே என்கிறார்கள்.
	<br /><br />
	13. எருசலேம் குமாரத்தியே, நான் உனக்குச் சாட்சியாக என்னத்தைச் சொல்லுவேன்? உன்னை எதற்கு ஒப்பிடுவேன்? சீயோன் குமாரத்தியாகிய கன்னிகையே, நான் உன்னைத் தேற்றும்படிக்கு உன்னை எதற்கு நிகர்சொல்லுவேன்? உன் காயம் சமுத்திரத்தைப்போல் பெரிதாயிருக்கிறதே, உன்னைக் குணமாக்குகிறவன் யார்?
	<br /><br />
	14. உன் தீர்க்கதரிசிகள் அபத்தமும் வியர்த்தமுமான தரிசனங்களை உனக்காகத் தரிசித்தார்கள்; அவர்கள் உன் சிறையிருப்பை விலக்கும்படி உன் அக்கிரமத்தை எடுத்துக்காட்டாமல், அபத்தமானவைகளையும் கேடானவைகளையும் உனக்காகத் தரிசித்தார்கள்.
	<br /><br />
	15. வழிப்போக்கர் யாவரும் உன்பேரில் கை கொட்டுகிறார்கள்; எருசலேம் குமாரத்தியின்பேரில் ஈசற்போட்டு, தங்கள் தலைகளைத் துலுக்கி: பூரணவடிவும் சர்வபூமியின் மகிழ்ச்சியுமான நகரம் இதுதானா என்கிறார்கள்.
	<br /><br />
	16. உன் பகைஞர் எல்லாரும் உன்பேரில் தங்கள் வாயைத் திறக்கிறார்கள்; ஈசற்போட்டுப் பற்கடிக்கிறார்கள்; அதை விழுங்கினோம், நாம் காத்திருந்த நாள் இதுவே, இப்பொழுது நமக்குக் கிடைத்தது, அதைக் கண்டோம் என்கிறார்கள்.
	<br /><br />
	17. கர்த்தர் தாம் நினைத்ததைச் செய்தார்; பூர்வநாட்கள் முதற்கொண்டு தாம் கட்டளையிட்ட தமது வார்த்தையை நிறைவேற்றினார்; அவர் தப்பவிடாமல் நிர்மூலமாக்கி, உன்மேல் பகைஞன் சந்தோஷிக்கும்படி செய்தார்; உன் சத்துருக்களின் கொம்பை உயர்த்தினார்.
	<br /><br />
	18. அவர்கள் இருதயம் ஆண்டவரை நோக்கிக் கூப்பிடுகிறது; சீயோன் குமாரத்தியின் மதிலே, இரவும் பகலும் நதியவ்வளவு கண்ணீர் விடு, ஓய்ந்திராதே, உன் கண்ணின் கறுப்புவிழி சும்மாயிருக்கவொட்டாதே.
	<br /><br />
	19. எழுந்திரு, இராத்திரியிலே முதற்சாமத்தில் கூப்பிடு; ஆண்டவரின் சமுகத்தில் உன் இருதயத்தைத் தண்ணீரைப்போல ஊற்றிவிடு; எல்லாத் தெருக்களின் முனையிலும் பசியினால் மூர்ச்சித்துப்போகிற உன் குழந்தைகளின் பிராணனுக்காக உன் கைகளை அவரிடத்திற்கு ஏறெடு.
	<br /><br />
	20. கர்த்தாவே, யாருக்கு இந்தப்பிரகாரமாகச் செய்தீரென்று நோக்கிப்பாரும்; ஸ்திரீகள் கைக்குழந்தைகளாகிய தங்கள் கர்ப்பக்கனியைத் தின்னவேண்டுமோ? ஆண்டவருடைய பரிசுத்த ஸ்தலத்தில் ஆசாரியனும் தீர்க்கதரிசியும் கொலைசெய்யப்படவேண்டுமோ?
	<br /><br />
	21. இளைஞனும் முதிர்வயதுள்ளவனும் தெருக்களில் தரையிலே கிடக்கிறார்கள்; என் கன்னிகைகளும் என் வாலிபரும் பட்டயத்தால் விழுந்தார்கள்; உமது கோபத்தின் நாளிலே வெட்டி, அவர்களைத் தப்பவிடாமல் கொன்றுபோட்டீர்.
	<br /><br />
	22. பண்டிகைநாளில் கும்புகளை வரவழைப்பதுபோல் சுற்றிலுமிருந்து எனக்குத் திகில்களை வரவழைத்தீர்; கர்த்தருடைய கோபத்தின் நாளிலே தப்பினவனும் மீதியானவனுமில்லை; நான் கைகளில் ஏந்தி வளர்த்தவர்களை என் பகைஞன் நாசம்பண்ணினான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Lamentations2