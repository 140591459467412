import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah55 = () => {
  const verseNumber = 55;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 55 </title>
	<meta
          name="description"
          content="Isaiah 55 | ஏசாயா 55 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஓ, தாகமாயிருக்கிறவர்களே, நீங்கள் எல்லாரும் தண்ணீர்களண்டைக்கு வாருங்கள்; பணமில்லாதவர்களே, நீங்கள் வந்து, வாங்கிச் சாப்பிடுங்கள்; நீங்கள் வந்து, பணமுமின்றி விலையுமின்றித் திராட்சரசமும் பாலும் கொள்ளுங்கள்.
	<br /><br />
	2. நீங்கள் அப்பமல்லாததற்காகப் பணத்தையும், திருப்திசெய்யாத பொருளுக்காக உங்கள் பிரயாசத்தையும் செலவழிப்பானேன்? நீங்கள் எனக்குக் கவனமாய்ச் செவிகொடுத்து, நலமானதைச் சாப்பிடுங்கள்; அப்பொழுது உங்கள் ஆத்துமா கொழுப்பான பதார்த்தத்தினால் மகிழ்ச்சியாகும்.
	<br /><br />
	3. உங்கள் செவியைச் சாய்த்து, என்னிடத்தில் வாருங்கள்: கேளுங்கள், அப்பொழுது உங்கள் ஆத்துமா பிழைக்கும்; தாவீதுக்கு அருளின நிச்சயமான கிருபைகளை உங்களுக்கு நித்திய உடன்படிக்கையாக ஏற்படுத்துவேன்.
	<br /><br />
	4. இதோ, அவரை ஜனக்கூட்டங்களுக்குச் சாட்சியாகவும், ஜனங்களுக்குத் தலைவராகவும், அதிபதியாகவும் ஏற்படுத்தினேன்.
	<br /><br />
	5. இதோ, நீ அறியாதிருந்த ஜாதியை வரவழைப்பாய்; உன்னை அறியாதிருந்த ஜாதி உன் தேவனாகிய கர்த்தரின் நிமித்தமும், இஸ்ரவேலுடைய பரிசுத்தரின் நிமித்தமும் உன்னிடத்திற்கு ஓடிவரும்; அவர் உன்னை மேன்மைப்படுத்தியிருக்கிறார்.
	<br /><br />
	6. கர்த்தரைக் கண்டடையத்தக்க சமயத்தில் அவரைத் தேடுங்கள்; அவர் சமீபமாயிருக்கையில் அவரை நோக்கிக் கூப்பிடுங்கள்.
	<br /><br />
	7. துன்மார்க்கன் தன் வழியையும், அக்கிரமக்காரன் தன் நினைவுகளையும் விட்டு, கர்த்தரிடத்தில் திரும்பக்கடவன்; அவர் அவன்மேல் மனதுருகுவார்; நம்முடைய தேவனிடத்திற்கே திரும்பக்கடவன்; அவர் மன்னிக்கிறதற்குத் தயை பெருத்திருக்கிறார்.
	<br /><br />
	8. என் நினைவுகள் உங்கள் நினைவுகள் அல்ல; உங்கள் வழிகள் என் வழிகளும் அல்லவென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. பூமியைப்பார்க்கிலும் வானங்கள் எப்படி உயர்ந்திருக்கிறதோ, அப்படியே உங்கள் வழிகளைப்பார்க்கிலும் என் வழிகளும், உங்கள் நினைவுகளைப்பார்க்கிலும் என் நினைவுகளும் உயர்ந்திருக்கிறது.
	<br /><br />
	10. மாரியும் உறைந்த மழையும் வானத்திலிருந்து இறங்கி, அவ்விடத்துக்குத் திரும்பாமல் பூமியை நனைத்து, அதில் முளை கிளம்பி விளையும்படிச்செய்து, விதைக்கிறவனுக்கு விதையையும், புசிக்கிறவனுக்கு ஆகாரத்தையும் கொடுக்கிறது எப்படியோ,
	<br /><br />
	11. அப்படியே என் வாயிலிருந்து புறப்படும் வசனமும் இருக்கும்; அது வெறுமையாய் என்னிடத்திற்குத் திரும்பாமல், அது நான் விரும்புகிறதைச்செய்து, நான் அதை அனுப்பின காரியமாகும்படி வாய்க்கும்.
	<br /><br />
	12. நீங்கள் மகிழ்ச்சியாய்ப் புறப்பட்டு, சமாதானமாய்க் கொண்டுபோகப்படுவீர்கள்; பர்வதங்களும் மலைகளும் உங்களுக்கு முன்பாகக் கெம்பீரமாய் முழங்கி, வெளியின் மரங்களெல்லாம் கைகொட்டும்.
	<br /><br />
	13. முட்செடிக்குப் பதிலாகத் தேவதாரு விருட்சம் முளைக்கும்; காஞ்சொறிக்குப் பதிலாக மிருதுச்செடி எழும்பும்; அது கர்த்தருக்குக் கீர்த்தியாகவும், நிர்மூலமாகாத நித்திய அடையாளமாகவும் இருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah55