import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 2 </title>
	<meta
          name="description"
          content="Isaiah 2 | ஏசாயா 2 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆமோத்சின் குமாரனாகிய ஏசாயா யூதாவையும் எருசலேமையும் குறித்துக் கண்ட தரிசனம்.
	<br /><br />
	2. கடைசிநாட்களில் கர்த்தருடைய ஆலயமாகிய பர்வதம் பர்வதங்களின் கொடுமுடியில் ஸ்தாபிக்கப்பட்டு, மலைகளுக்கு மேலாய் உயர்த்தப்படும்; எல்லா ஜாதிகளும் அதற்கு ஓடிவருவார்கள்.
	<br /><br />
	3. திரளான ஜனங்கள் புறப்பட்டு வந்து: நாம் கர்த்தரின் பர்வதத்துக்கும், யாக்கோபின் தேவனுடைய ஆலயத்துக்கும் போவோம் வாருங்கள்; அவர் தமது வழிகளை நமக்குப் போதிப்பார், நாம் அவர் பாதைகளில் நடப்போம் என்பார்கள்; ஏனெனில் சீயோனிலிருந்து வேதமும், எருசலேமிலிருந்து கர்த்தரின் வசனமும் வெளிப்படும்.
	<br /><br />
	4. அவர் ஜாதிகளுக்குள் நியாயந்தீர்த்து, திரளான ஜனங்களைக் கடிந்துகொள்வார்; அப்பொழுது அவர்கள் தங்கள் பட்டயங்களை மண்வெட்டிகளாகவும், தங்கள் ஈட்டிகளை அரிவாள்களாகவும் அடிப்பார்கள்; ஜாதிக்கு விரோதமாய் ஜாதி பட்டயம் எடுப்பதில்லை, இனி அவர்கள் யுத்தத்தைக் கற்பதுமில்லை.
	<br /><br />
	5. யாக்கோபின் வம்சத்தாரே, கர்த்தரின் வெளிச்சத்திலே நடப்போம் வாருங்கள்.
	<br /><br />
	6. யாக்கோபின் வம்சத்தாராகிய உம்முடைய ஜனத்தைக் கைநெகிழ்ந்தீர்; அவர்கள் கீழ்த்திசையாரின் போதகத்தால் நிறைந்து, பெலிஸ்தரைப்போல் நாள்பார்க்கிறவர்களாயிருந்து, அந்நிய புத்திரர்மேல் பிரியப்படுகிறார்களே.
	<br /><br />
	7. அவர்கள் தேசம் வெள்ளியினாலும் பொன்னினாலும் நிறைந்திருக்கிறது; அவர்கள் பொக்கிஷங்களுக்கு முடிவில்லை; அவர்கள் தேசம் குதிரைகளாலும் நிறைந்திருக்கிறது; அவர்கள் இரதங்களுக்கும் முடிவில்லை.
	<br /><br />
	8. அவர்கள் தேசம் விக்கிரகங்களாலும் நிறைந்திருக்கிறது; தங்கள் கைகளின் கிரியையையும், தங்கள் விரல்கள் உண்டுபண்ணினதையும் பணிந்துகொள்ளுகிறார்கள்.
	<br /><br />
	9. சிறியவனும் குனிகிறான், பெரியவனும் பணிகிறான்; ஆகையால் அவர்களுக்கு மன்னியாதிருப்பீர்.
	<br /><br />
	10. கர்த்தரின் பயங்கரத்திற்கும், அவருடைய மகிமைப்பிரதாபத்திற்கும் விலகி, நீ கன்மலையில் ஒதுங்கி, மண்ணில் ஒளித்துக்கொள்.
	<br /><br />
	11. நரரின் மேட்டிமையான கண்கள் தாழ்த்தப்படும், மனுஷரின் வீறாப்பும் தணியும்; கர்த்தர் ஒருவரே அந்நாளில் உயர்ந்திருப்பார்.
	<br /><br />
	12. எல்லாம் தாழ்த்தப்படும்பொருட்டுச் சேனைகளுடைய கர்த்தரின் நாளானது பெருமையும் மேட்டிமையுமானவை எல்லாவற்றின்மேலும், உயர்ந்தவை எல்லாவற்றின்மேலும்,
	<br /><br />
	13. லீபனோனிலுள்ள உயரமும் ஓங்கலுமான எல்லாக் கேதுருக்களின்மேலும், பாசானிலுள்ள எல்லாக் கர்வாலி மரங்களின்மேலும்,
	<br /><br />
	14. உன்னதமான எல்லாப் பர்வதங்களின்மேலும், உயரமான எல்லா மலைகளின்மேலும்,
	<br /><br />
	15. உயர்ந்த எல்லாக் கோபுரத்தின்மேலும், அரணான எல்லா மதிலின்மேலும்,
	<br /><br />
	16. தர்ஷீசின் கப்பல்கள் எல்லாவற்றின்மேலும், எல்லாச் சித்திர விநோதங்களின்மேலும் வரும்.
	<br /><br />
	17. அப்பொழுது நரரின் மேட்டிமை தாழ்ந்து, மனுஷரின் வீறாப்புத் தணியும்; கர்த்தர் ஒருவரே அந்நாளில் உயர்ந்திருப்பார்.
	<br /><br />
	18. விக்கிரகங்கள் கட்டோடே ஒழிந்துபோம்.
	<br /><br />
	19. பூமியைத் தத்தளிக்கப்பண்ணக் கர்த்தர் எழும்பும்போது, அவருடைய பயங்கரத்திற்கும், அவருடைய மகிமைப்பிரதாபத்திற்கும் விலகி, கன்மலைகளின் கெபிகளிலும், பூமியின் குகைகளிலும் புகுந்துகொள்வார்கள்.
	<br /><br />
	20. பூமியைத் தத்தளிக்கப்பண்ணக் கர்த்தர் எழும்பும் அந்நாளிலே, அவருடைய பயங்கரத்திற்கும், அவருடைய மகிமைப்பிரதாபத்திற்கும் விலகி, கன்மலைகளின் வெடிப்புகளிலும் குன்றுகளின் சந்துகளிலும் புகுந்துகொள்ளும்படிக்கு,
	<br /><br />
	21. மனுஷன் பணிந்துகொள்ளத் தனக்கு உண்டாக்கியிருந்த தன் வெள்ளி விக்கிரகங்களையும், தன் பொன் விக்கிரகங்களையும், மூஞ்சூறுகளுக்கும் துரிஞ்சில்களுக்கும் எறிந்துவிடுவான்.
	<br /><br />
	22. நாசியிலே சுவாசமுள்ள மனுஷனை நம்புவதை விட்டுவிடுங்கள்; எண்ணப்படுவதற்கு அவன் எம்மாத்திரம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah2