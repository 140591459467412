import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 5 | Tamil Bible Verse | வெளிப்படுத்தல் - 5 </title>
	<meta
          name="description"
          content="The Book of Revelation - 5 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 5 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஒரு ஆட்டுக்குட்டி கொல்லப்பட்டது போல் நின்று ஏழு முத்திரைகள் கொண்ட சுருளை எடுத்தது. உயிரினங்கள், பெரியவர்கள் மற்றும் எண்ணற்ற தேவதூதர்கள், "ஆட்டுக்குட்டி தகுதியானவர்!" *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. அன்றியும், உள்ளும் புறம்பும் எழுதப்பட்டு, ஏழு முத்திரைகளால் முத்திரிக்கப்பட்டிருந்த ஒரு புஸ்தகத்தைச் சிங்காசனத்தின்மேல் வீற்றிருக்கிறவருடைய வலதுகரத்திலே கண்டேன்.
	<br /><br />
	2. புஸ்தகத்தைத் திறக்கவும் அதின் முத்திரைகளை உடைக்கவும் பாத்திரவான் யார் என்று மிகுந்த சத்தமிட்டுக் கூறுகிற பலமுள்ள ஒரு தூதனையுங் கண்டேன்.
	<br /><br />
	3. வானத்திலாவது, பூமியிலாவது, பூமியின்கீழாவது, ஒருவனும் அந்தப் புஸ்தகத்தைத் திறக்கவும், அதைப் பார்க்கவும் கூடாதிருந்தது.
	<br /><br />
	4. ஒருவனும் அந்தப் புஸ்தகத்தைத் திறந்து வாசிக்கவும் அதைப் பார்க்கவும் பாத்திரவானாகக் காணப்படாததினால் நான் மிகவும் அழுதேன்.
	<br /><br />
	5. அப்பொழுது மூப்பர்களில் ஒருவன் என்னை நோக்கி: நீ அழவேண்டாம்; இதோ, யூதா கோத்திரத்துச் சிங்கமும் தாவீதின் வேருமானவர் புஸ்தகத்தைத் திறக்கவும் அதின் ஏழு முத்திரைகளையும் உடைக்கவும் ஜெயங்கொண்டிருக்கிறார் என்றான்.
	<br /><br />
	6. அப்பொழுது, இதோ, அடிக்கப்பட்ட வண்ணமாயிருக்கிற ஒரு ஆட்டுக்குட்டி சிங்காசனத்திற்கும், நான்கு ஜீவன்களுக்கும், மூப்பர்களுக்கும் மத்தியிலே நிற்கக்கண்டேன்; அது ஏழு கொம்புகளையும் ஏழு கண்களையும் உடையதாயிருந்தது; அந்தக் கண்கள் பூமியெங்கும் அனுப்பப்படுகிற தேவனுடைய ஏழு ஆவிகளேயாம்.
	<br /><br />
	7. அந்த ஆட்டுக்குட்டியானவர் வந்து, சிங்காசனத்தின்மேல் உட்கார்ந்தவருடைய வலதுகரத்திலிருந்த புஸ்தகத்தை வாங்கினார்.
	<br /><br />
	8. அந்தப் புஸ்தகத்தை அவர் வாங்கினபோது, அந்த நான்கு ஜீவன்களும், இருபத்துநான்கு மூப்பர்களும் தங்கள் தங்கள் சுரமண்டலங்களையும், பரிசுத்தவான்களுடைய ஜெபங்களாகிய தூபவர்க்கத்தால் நிறைந்த பொற்கலசங்களையும் பிடித்துக்கொண்டு, ஆட்டுக்குட்டியானவருக்கு முன்பாக வணக்கமாய் விழுந்து:
	<br /><br />
	9. தேவரீர் புஸ்தகத்தை வாங்கவும் அதின் முத்திரைகளை உடைக்கவும் பாத்திரராயிருக்கிறீர்; ஏனெனில் நீர் அடிக்கப்பட்டு, சகல கோத்திரங்களிலும் பாஷைக்காரரிலும் ஜனங்களிலும் ஜாதிகளிலுமிருந்து எங்களைத் தேவனுக்கென்று உம்முடைய இரத்தத்தினாலே மீட்டுக்கொண்டு,
	<br /><br />
	10. எங்கள் தேவனுக்குமுன்பாக எங்களை ராஜாக்களும் ஆசாரியர்களுமாக்கினீர்; நாங்கள் பூமியிலே அரசாளுவோம் என்று புதிய பாட்டைப் பாடினார்கள்.
	<br /><br />
	11. பின்னும் நான் பார்த்தபோது, சிங்காசனத்தையும் ஜீவன்களையும் மூப்பர்களையும் சூழ்ந்திருந்த அநேக தூதர்களுடைய சத்தத்தைக் கேட்டேன்; அவர்களுடைய இலக்கம் பதினாயிரம் பதினாயிரமாகவும், ஆயிரமாயிரமாகவுமிருந்தது.
	<br /><br />
	12. அவர்களும் மகா சத்தமிட்டு: அடிக்கப்பட்ட ஆட்டுக்குட்டியானவர் வல்லமையையும் ஐசுவரியத்தையும் ஞானத்தையும் பெலத்தையும் கனத்தையும் மகிமையையும் ஸ்தோத்திரத்தையும் பெற்றுக்கொள்ளப் பாத்திரராயிருக்கிறார் என்று சொன்னார்கள்.
	<br /><br />
	13. அப்பொழுது, வானத்திலும் பூமியிலும் பூமியின்கீழுமிருக்கிற சிருஷ்டிகள் யாவும், சமுத்திரத்திலுள்ளவைகளும், அவற்றுளடங்கிய வஸ்துக்கள் யாவும்: சிங்காசனத்தின்மேல் வீற்றிருக்கிறவருக்கும் ஆட்டுக்குட்டியானவருக்கும் ஸ்தோத்திரமும் கனமும் மகிமையும் வல்லமையும் சதாகாலங்களிலும் உண்டாவதாக என்று சொல்லக்கேட்டேன்.
	<br /><br />
	14. அதற்கு நான்கு ஜீவன்களும்: ஆமென் என்று சொல்லின. இருபத்துநான்கு மூப்பர்களும் வணக்கமாய் விழுந்து சதாகாலங்களிலும் உயிரோடிருக்கிறவரைத் தொழுதுகொண்டார்கள்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h5>
*  *
			</h5>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation5