import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 8 </title>
	<meta
          name="description"
          content="Hosea 8 | ஓசியா 8 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உன் வாயிலே எக்காளத்தை வை; அவர்கள் என் உடன்படிக்கையை மீறி, என் நியாயப்பிரமாணத்துக்கு விரோதமாகத் துரோகம்பண்ணினபடியினால், கர்த்தருடைய வீட்டின்மேல் சத்துரு கழுகைப்போல் பறந்துவருகிறான்.
	<br /><br />
	2. எங்கள் தேவனே, உம்மை அறிந்திருக்கிறோம் என்று சொல்லி இஸ்ரவேலர் கூப்பிடுவார்கள்.
	<br /><br />
	3. ஆனாலும் இஸ்ரவேலர் நன்மையை வெறுத்தார்கள்; சத்துரு அவர்களைத் தொடருவான்.
	<br /><br />
	4. அவர்கள் ராஜாக்களை ஏற்படுத்திக்கொண்டார்கள், ஆனாலும் என்னாலே அல்ல; அதிபதிகளை வைத்துக்கொண்டார்கள், ஆனாலும் நான் அறியேன்; அவர்கள் வேரறுப்புண்டு போகும்படித் தங்கள் வெள்ளியினாலும் தங்கள் பொன்னினாலும் தங்களுக்கு விக்கிரகங்களைச் செய்வித்தார்கள்.
	<br /><br />
	5. சமாரியாவே, உன் கன்றுக்குட்டி உன்னை வெறுத்துவிடுகிறது; என் கோபம் அவர்கள்மேல் மூண்டது; எதுவரைக்கும் சுத்தாங்கம் அடையமாட்டாதிருப்பார்கள்?
	<br /><br />
	6. அதுவும் இஸ்ரவேலருடைய செய்கையே; தட்டான் அதைச் செய்தான், ஆதலால் அது தேவன் அல்லவே, சமாரியாவின் கன்றுக்குட்டி துண்டுதுண்டாய்ப் போகும்.
	<br /><br />
	7. அவர்கள் காற்றை விதைத்து, சூறைக்காற்றை அறுப்பார்கள்; விளைச்சல் அவர்களுக்கு இல்லை; கதிர் மாவைக் கொடுக்கமாட்டாது; கொடுத்தாலும் அந்நியர் அதை விழுங்குவார்கள்.
	<br /><br />
	8. இஸ்ரவேலர் விழுங்கப்படுகிறார்கள்; அவர்கள் இனிப் புறஜாதிகளுக்குள்ளே விரும்பப்படாத பாத்திரத்தைப்போல் இருப்பார்கள்.
	<br /><br />
	9. அவர்கள் தனித்துத் திரிகிற காட்டுக்கழுதையைப்போல் அசீரியரண்டைக்குப் போனார்கள்; எப்பிராயீமர் நேசரைக் கூலிக்குப் பொருத்திக்கொண்டார்கள்.
	<br /><br />
	10. அவர்கள் புறஜாதியாரைக் கூலிக்குப் பொருத்திக்கொண்டாலும், இப்பொழுது நான் அவர்களைக் கூட்டுவேன்; அதிபதிகளின் ராஜா சுமத்தும் சுமையினால் அவர்கள் கொஞ்சக்காலத்துக்குள்ளே அகப்படுவார்கள்.
	<br /><br />
	11. எப்பிராயீமர் பாவஞ்செய்வதற்கேதுவாய்ப் பலிபீடங்களைப் பெருகப்பண்ணினார்கள்; ஆதலால் பலிபீடங்களே அவர்கள் பாவஞ்செய்வதற்கு ஏதுவாகும்.
	<br /><br />
	12. என் வேதத்தின் மகத்துவங்களை அவர்களுக்கு எழுதிக்கொடுத்தேன்; அவைகளை அந்நியகாரியமாக எண்ணினார்கள்.
	<br /><br />
	13. எனக்குச் செலுத்தும் பலிகளின் மாம்சத்தை அவர்கள் பலியிட்டுப் புசிக்கிறார்கள்; கர்த்தர் அவர்கள்மேல் பிரியமாயிரார்; அவர்களுடைய அக்கிரமத்தை அவர் நினைத்து, அவர்கள் பாவத்தை விசாரிக்கும்போதோவெனில், அவர்கள் எகிப்துக்குத் திரும்பிப்போவார்கள்.
	<br /><br />
	14. இஸ்ரவேல் தன்னை உண்டாக்கினவரை மறந்து கோவில்களைக் கட்டுகிறான்; யூதா அரணான பட்டணங்களைப் பெருகப்பண்ணுகிறான்; ஆனாலும் நான் அதின் நகரங்களில் அக்கினியை வரப்பண்ணுவேன்; அது அவைகளின் கோவில்களைப் பட்சிக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea8