import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ephesians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Book of Ephesians Bible Tamil | எபேசியர் 1 </title>
	<meta
          name="description"
          content="Ephesians 1 | எபேசியர் 1 |
          Book of Ephesians | Tamil Bible Verse | Tamil Bible | எபேசியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
	  <p>
	1. தேவனுடைய சித்தத்தினாலே இயேசுகிறிஸ்துவினுடைய அப்போஸ்தலனாகிய பவுல், எபேசுவிலே கிறிஸ்து இயேசுவுக்குள் விசுவாசிகளாயிருக்கிற பரிசுத்தவான்களுக்கு எழுதுகிறதாவது:
	<br /><br />
	2. நம்முடைய பிதாவாகிய தேவனாலும், கர்த்தராகிய இயேசுகிறிஸ்துவினாலும், உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	3. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் பிதாவாகிய தேவனுக்கு ஸ்தோத்திரம்; அவர் கிறிஸ்துவுக்குள் உன்னதங்களிலே ஆவிக்குரிய சகல ஆசீர்வாதத்தினாலும் நம்மை ஆசீர்வதித்திருக்கிறார்.
	<br /><br />
	4. தமக்குமுன்பாக நாம் அன்பில் பரிசுத்தமுள்ளவர்களும் குற்றமில்லாதவர்களுமாயிருப்பதற்கு, அவர் உலகத்தோற்றத்துக்குமுன்னே கிறிஸ்துவுக்குள் நம்மைத் தெரிந்துகொண்டபடியே,
	<br /><br />
	5. பிரியமானவருக்குள் தாம் நமக்குத் தந்தருளின தம்முடைய கிருபையின் மகிமைக்குப் புகழ்ச்சியாக,
	<br /><br />
	6. தம்முடைய தயவுள்ள சித்தத்தின்படியே, நம்மை இயேசுகிறிஸ்துமூலமாய்த் தமக்குச் சுவிகாரபுத்திரராகும்படி முன்குறித்திருக்கிறார்.
	<br /><br />
	7. அவருடைய கிருபையின் ஐசுவரியத்தின்படியே இவருடைய இரத்தத்தினாலே பாவமன்னிப்பாகிய மீட்பு இவருக்குள் நமக்கு உண்டாயிருக்கிறது.
	<br /><br />
	8. அந்தக் கிருபையை அவர் சகல ஞானத்தோடும் புத்தியோடும் எங்களிடத்தில் பெருகப்பண்ணினார்.
	<br /><br />
	9. காலங்கள் நிறைவேறும்போது விளங்கும் நியமத்தின்படி பரலோகத்திலிருக்கிறவைகளும் பூலோகத்திலிருக்கிறவைகளுமாகிய சகலமும் கிறிஸ்துவுக்குள்ளே கூட்டப்படவேண்டுமென்று,
	<br /><br />
	10. தமக்குள்ளே தீர்மானித்திருந்த தம்முடைய தயவுள்ள சித்தத்தின் இரகசியத்தை எங்களுக்கு அறிவித்தார்.
	<br /><br />
	11. மேலும் கிறிஸ்துவின்மேல் முன்னே நம்பிக்கையாயிருந்த நாங்கள் அவருடைய மகிமைக்குப் புகழ்ச்சியாயிருக்கும்படிக்கு,
	<br /><br />
	12. தமது சித்தத்தின் ஆலோசனைக்குத்தக்கதாக எல்லாவற்றையும் நடப்பிக்கிற அவருடைய தீர்மானத்தின்படியே, நாங்கள் முன்குறிக்கப்பட்டு, கிறிஸ்துவுக்குள் அவருடைய சுதந்தரமாகும்படி தெரிந்துகொள்ளப்பட்டோம்.
	<br /><br />
	13. நீங்களும் உங்கள் இரட்சிப்பின் சுவிசேஷமாகிய சத்திய வசனத்தைக் கேட்டு, விசுவாசிகளானபோது, வாக்குத்தத்தம்பண்ணப்பட்ட பரிசுத்தஆவியால் அவருக்குள் முத்திரைபோடப்பட்டீர்கள்.
	<br /><br />
	14. அவருக்குச் சொந்தமானவர்கள் அவருடைய மகிமைக்குப் புகழ்ச்சியாக மீட்கப்படுவார்கள் என்பதற்கு ஆவியானவர் நம்முடைய சுதந்தரத்தின் அச்சாரமாயிருக்கிறார்.
	<br /><br />
	15. ஆனபடியினாலே, கர்த்தராகிய இயேசுவின்மேலுள்ள உங்கள் விசுவாசத்தையும், பரிசுத்தவான்களெல்லார்மேலுமுள்ள உங்கள் அன்பையுங்குறித்து நான் கேள்விப்பட்டு,
	<br /><br />
	16. இடைவிடாமல் உங்களுக்காக ஸ்தோத்திரம்பண்ணி, என் ஜெபங்களில் உங்களை நினைத்து,
	<br /><br />
	17. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் தேவனும் மகிமையின் பிதாவுமானவர் தம்மை நீங்கள் அறிந்துகொள்வதற்கான ஞானத்தையும் தெளிவையும் அளிக்கிற ஆவியை உங்களுக்குத் தந்தருளவேண்டுமென்றும்,
	<br /><br />
	18. தாம் நம்மை அழைத்ததினாலே நமக்கு உண்டாயிருக்கிற நம்பிக்கை இன்னதென்றும், பரிசுத்தவான்களிடத்தில் தமக்கு உண்டாயிருக்கிற சுதந்தரத்தினுடைய மகிமையின் ஐசுவரியம் இன்னதென்றும்;
	<br /><br />
	19. தாம் கிறிஸ்துவை மரித்தோரிலிருந்து எழுப்பி, அவரிடத்தில் நடப்பித்த தமது பலத்த சத்துவத்தின் வல்லமைப்படியே விசுவாசிக்கிறவர்களாகிய நம்மிடத்திலே காண்பிக்கும் தம்முடைய வல்லமையின் மகா மேன்மையான மகத்துவம் இன்னதென்றும், நீங்கள் அறியும்படிக்கு, அவர் உங்களுக்குப் பிரகாசமுள்ள மனக்கண்களைக் கொடுக்கவேண்டுமென்றும் வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	20. எல்லாத் துரைத்தனத்துக்கும், அதிகாரத்துக்கும், வல்லமைக்கும், கர்த்தத்துவத்துக்கும், இம்மையில்மாத்திரமல்ல மறுமையிலும் பேர்பெற்றிருக்கும் எல்லா நாமத்துக்கும் மேலாய் அவர் உயர்ந்திருக்கத்தக்கதாக,
	<br /><br />
	21. அவரை உன்னதங்களில் தம்முடைய வலதுபாரிசத்தில் உட்காரும்படி செய்து,
	<br /><br />
	22. எல்லாவற்றையும் அவருடைய பாதங்களுக்குக் கீழ்ப்படுத்தி,
	<br /><br />
	23. எல்லாவற்றையும் எல்லாவற்றாலும் நிரப்புகிறவருடைய நிறைவாகிய சரீரமான சபைக்கு அவரை எல்லாவற்றிற்கும் மேலான தலையாகத் தந்தருளினார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ephesians1