import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneTimothy6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title> 1 Timothy in Tamil Bible Verse | 1 தீமோத்தேயு அதிகாரம்-6 </title>
	<meta
          name="description"
          content="FIRST LETTER TO THE Timothy Chapter 6 | தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-6 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* தீமோத்தேயுவிடம் பவுலின் இறுதிக் குற்றச்சாட்டு *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


	  <p>
	1. தேவனுடைய நாமமும் உபதேசமும் தூஷிக்கப்படாதபடிக்கு, அடிமைத்தன நுகத்திற்குட்பட்டிருக்கிற வேலைக்காரர் யாவரும் தங்கள் எஜமான்களை எல்லாக் கனத்திற்கும் பாத்திரரென்று எண்ணிக்கொள்ளக்கடவர்கள்.
	<br /><br />
	2. விசுவாசிகளாகிய எஜமான்களை உடையவர்கள், தங்கள் எஜமான்கள் சகோதரராயிருக்கிறதினாலே அவர்களை அசட்டைபண்ணாமல், நல்வேலையின் பலனைப் பெற்றுக்கொள்ளுகிற அவர்கள் விசுவாசிகளும் பிரியருமாயிருக்கிறபடியால், அவர்களுக்கு அதிகமாய் ஊழியஞ்செய்யக்கடவர்கள்; இந்தப்படியே போதித்துப் புத்திசொல்லு.
	<br /><br />
	3. ஒருவன் நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் ஆரோக்கியமான வசனங்களையும், தேவபக்திக்கேற்ற உபதேசங்களையும் ஒப்புக்கொள்ளாமல், வேற்றுமையான உபதேசங்களைப் போதிக்கிறவனானால்,
	<br /><br />
	4. அவன் இறுமாப்புள்ளவனும், ஒன்றும் அறியாதவனும், தர்க்கங்களையும் வாக்குவாதங்களையும்பற்றி நோய்கொண்டவனுமாயிருக்கிறான்; அவைகளாலே பொறாமையும், சண்டையும், தூஷணங்களும், பொல்லாத சம்சயங்களுமுண்டாகி,
	<br /><br />
	5. கெட்ட சிந்தையுள்ளவர்களும் சத்தியமில்லாதவர்களும் தேவபக்தியை ஆதாயத்தொழிலென்று எண்ணுகிறவர்களுமாயிருக்கிற மனுஷர்களால் உண்டாகும் மாறுபாடான தர்க்கங்களும் பிறக்கும்; இப்படிப்பட்டவர்களை விட்டு விலகு.
	<br /><br />
	6. போதுமென்கிற மனதுடனே கூடிய தேவபக்தியே மிகுந்த ஆதாயம்.
	<br /><br />
	7. உலகத்திலே நாம் ஒன்றும் கொண்டுவந்ததுமில்லை, இதிலிருந்து நாம் ஒன்றும் கொண்டுபோவதுமில்லை என்பது நிச்சயம்.
	<br /><br />
	8. உண்ணவும் உடுக்கவும் நமக்கு உண்டாயிருந்தால் அது போதுமென்றிருக்கக் கடவோம்.
	<br /><br />
	9. ஐசுவரியவான்களாக விரும்புகிறவர்கள் சோதனையிலும் கண்ணியிலும், மனுஷரைக் கேட்டிலும் அழிவிலும் அமிழ்த்துகிற மதிகேடும் சேதமுமான பலவித இச்சைகளிலும் விழுகிறார்கள்.
	<br /><br />
	10. பண ஆசை எல்லாத் தீமைக்கும் வேராயிருக்கிறது; சிலர் அதை இச்சித்து, விசுவாசத்தைவிட்டு வழுவி, அநேக வேதனைகளாலே தங்களை உருவக் குத்திக்கொண்டிருக்கிறார்கள்.
	<br /><br />
	11. நீயோ, தேவனுடைய மனுஷனே, இவைகளை விட்டோடி, நீதியையும் தேவபக்தியையும் விசுவாசத்தையும் அன்பையும் பொறுமையையும் சாந்தகுணத்தையும் அடையும்படி நாடு.
	<br /><br />
	12. விசுவாசத்தின் நல்ல போராட்டத்தைப் போராடு, நித்தியஜீவனைப் பற்றிக்கொள்; அதற்காகவே நீ அழைக்கப்பட்டாய்; அநேக சாட்சிகளுக்கு முன்பாக நல்ல அறிக்கை பண்ணினவனுமாயிருக்கிறாய்.
	<br /><br />
	13. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து பிரசன்னமாகும்வரைக்கும், நீ இந்தக் கற்பனையை மாசில்லாமலும் குற்றமில்லாமலும் கைக்கொள்ளும்படிக்கு,
	<br /><br />
	14. எல்லாவற்றையும் உயிரோடிருக்கச் செய்கிற தேவனுடைய சந்நிதானத்திலேயும், பொந்தியுபிலாத்துவின் முன்னின்று நல்ல அறிக்கையைச் சாட்சியாக விளங்கப்பண்ணின கிறிஸ்து இயேசுவினுடைய சந்நிதானத்திலேயும் உனக்குக் கட்டளையிடுகிறேன்.
	<br /><br />
	15. அந்தப் பிரசன்னமாகுதலைத் தேவன் தம்முடைய காலங்களில் வெளிப்படுத்துவார். அவரே நித்தியானந்தமுள்ள ஏக சக்கராதிபதியும், ராஜாதி ராஜாவும், கர்த்தாதி கர்த்தாவும்,
	<br /><br />
	16. ஒருவராய், சாவாமையுள்ளவரும், சேரக்கூடாத ஒளியில் வாசம்பண்ணுகிறவரும், மனுஷரில் ஒருவரும் கண்டிராதவரும், காணக்கூடாதவருமாயிருக்கிறவர்; அவருக்கே கனமும் நித்திய வல்லமையும் உண்டாயிருப்பதாக. ஆமென்.
	<br /><br />
	17. இவ்வுலகத்திலே ஐசுவரியமுள்ளவர்கள் இறுமாப்பான சிந்தையுள்ளவர்களாயிராமலும், நிலையற்ற ஐசுவரியத்தின்மேல் நம்பிக்கை வையாமலும், நாம் அனுபவிக்கிறதற்குச் சகலவித நன்மைகளையும் நமக்குச் சம்பூரணமாய்க் கொடுக்கிற ஜீவனுள்ள தேவன்மேல் நம்பிக்கை வைக்கவும்,
	<br /><br />
	18. நன்மைசெய்யவும், நற்கிரியைகளில் ஐசுவரியவான்களாகவும், தாராளமாய்க் கொடுக்கிறவர்களும், உதாரகுணமுள்ளவர்களுமாயிருக்கவும்,
	<br /><br />
	19. நித்திய ஜீவனைப் பற்றிக்கொள்ளும்படி வருங்காலத்திற்காகத் தங்களுக்கு நல்ல ஆதாரத்தைப் பொக்கிஷமாக வைக்கவும் அவர்களுக்குக் கட்டளையிடு.
	<br /><br />
	20. ஓ தீமோத்தேயுவே, உன்னிடத்தில் ஒப்புவிக்கப்பட்டதை நீ காத்துக்கொண்டு, சீர்கேடான வீண்பேச்சுகளுக்கும், ஞானமென்று பொய்யாய்ப் பேர்பெற்றிருக்கிற கொள்கையின் விபரீதங்களுக்கும் விலகு.
	<br /><br />
	21. சிலர் அதைப் பாராட்டி, விசுவாசத்தைவிட்டு வழுவிப்போனார்கள். கிருபையானது உன்னோடேகூட இருப்பதாக. ஆமென்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h6>
* I தீமோத்தேயு 6, திருப்தியாக இருப்பதற்கும், தெய்வீகத்தைப் பின்பற்றுவதற்கும் ஒரு நினைவூட்டல். செல்வத்தைப் பெறுவதற்கான விருப்பத்திலிருந்து தப்பித்து, நீதி, தெய்வீகம், விசுவாசம், அன்பு, சகிப்புத்தன்மை மற்றும் மென்மை ஆகியவற்றைப் பின்தொடர அவர் தீமோத்தேயுவுக்கு அறிவுறுத்துகிறார். மீண்டும் ஒருமுறை அவர் தீமோத்தேயுவிடம் நல்ல சண்டையை எதிர்த்துப் போராடவும், அவருக்குக் கொடுக்கப்பட்ட நித்திய ஜீவனைப் பற்றிக்கொள்ளவும் கூறுகிறார். இந்தக் கடிதம் தீமோத்தேயுவிடம் ஒப்படைக்கப்பட்டதை கவனமாகக் காத்துக்கொள்ளும்படி பவுல் நினைப்பூட்டுகிறது.
</h6>
<br /><br />
<br /><br />
<h6>
இந்த புத்தகம் தேவாலயத்திற்கும் அதன் தலைவர்களுக்கும் நடைமுறை அறிவுறுத்தல்கள் நிறைந்தது. I தீமோத்தேயுவின் புத்தகத்தை நீங்கள் படிக்கும்போதும் படிக்கும்போதும் நீங்கள் ஆசீர்வதிக்கப்பட்டவர்களாகவும் ஊக்கமளிப்பவர்களாகவும் இருப்பீர்களாக.*
			</h6>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OneTimothy6