import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke17 = () => {
  const verseNumber = 17;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 17 </title>
	<meta
          name="description"
          content="Luke 17 | லூக்கா 17 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் தம்முடைய சீஷர்களை நோக்கி: இடறல்கள் வராமல்போவது கூடாதகாரியம், ஆகிலும் அவைகள் எவனால் வருகிறதோ, அவனுக்கு ஐயோ!
	<br /><br />
	2. அவன் இந்தச் சிறுவரில் ஒருவனுக்கு இடறலுண்டாக்குகிறதைப் பார்க்கிலும், அவனுடைய கழுத்தில் ஏந்திரக்கல் கட்டப்பட்டு, அவன் சமுத்திரத்தில் தள்ளுண்டுபோவது அவனுக்கு நலமாயிருக்கும்.
	<br /><br />
	3. உங்களைக்குறித்து எச்சரிக்கையாயிருங்கள். உன் சகோதரன் உனக்கு விரோதமாய்க் குற்றஞ்செய்தால், அவனைக் கடிந்துகொள்; அவன் மனஸ்தாபப்பட்டால், அவனுக்கு மன்னிப்பாயாக.
	<br /><br />
	4. அவன் ஒருநாளில் ஏழுதரம் உனக்கு விரோதமாய்க் குற்றஞ்செய்து, ஏழுதரமும் உன்னிடத்தில் வந்து: நான் மனஸ்தாபப்படுகிறேன் என்று சொன்னால், அவனுக்கு மன்னிப்பாயாக என்றார்.
	<br /><br />
	5. அப்பொழுது அப்போஸ்தலர் கர்த்தரை நோக்கி: எங்கள் விசுவாசத்தை வர்த்திக்கப்பண்ணவேண்டும் என்றார்கள்.
	<br /><br />
	6. அதற்குக் கர்த்தர்: கடுகுவிதையளவு விசுவாசம் உங்களுக்கு உண்டாயிருந்தால், நீங்கள் இந்தக் காட்டத்திமரத்தை நோக்கி: நீ வேரோடே பிடுங்குண்டு கடலிலே நடப்படுவாயாக என்று சொல்ல, அது உங்களுக்குக் கீழ்ப்படியும்.
	<br /><br />
	7. உங்களில் ஒருவனுடைய ஊழியக்காரன் உழுது அல்லது மந்தைமேய்த்து வயலிலிருந்து வரும்போது, எஜமான் அவனை நோக்கி: நீ முன்பு போய்ச் சாப்பிட்டுவா என்று அவனுக்குச் சொல்வானோ?
	<br /><br />
	8. நீ எனக்குச் சாப்பாடு ஆயத்தம்பண்ணி, அரைகட்டிக்கொண்டு, நான் போஜனபானம்பண்ணுமளவும் எனக்கு ஊழியஞ்செய், அதற்குப்பின் நீ புசித்துக்குடிக்கலாம் என்று அவனுக்குச் சொல்லுவானல்லவா?
	<br /><br />
	9. தான் கட்டளையிட்டவைகளை அந்த வேலைக்காரன் செய்ததற்காக அவனுக்கு உபசாரஞ்செய்வானோ? அப்படிச் செய்யமாட்டானே.
	<br /><br />
	10. அப்படியே நீங்களும் உங்களுக்குக் கட்டளையிடப்பட்ட யாவற்றையும் செய்த பின்பு: நாங்கள் அப்பிரயோஜனமான ஊழியக்காரர், செய்யவேண்டிய கடமையைமாத்திரம் செய்தோம் என்று சொல்லுங்கள் என்றார்.
	<br /><br />
	11. பின்பு அவர் எருசலேமுக்குப் பிரயாணம்பண்ணுகையில், அவர் சமாரியா கலிலேயா என்னும் நாடுகளின் வழியாக நடந்துபோனார்.
	<br /><br />
	12. அவர் ஒரு கிராமத்தில் பிரவேசித்தபோது, குஷ்டரோகமுள்ள மனுஷர் பத்துப்பேர் அவருக்கு எதிராக வந்து, தூரத்திலே நின்று:
	<br /><br />
	13. இயேசு ஐயரே, எங்களுக்கு இரங்கும் என்று சத்தமிட்டார்கள்.
	<br /><br />
	14. அவர்களை அவர் பார்த்து: நீங்கள் போய், ஆசாரியர்களுக்கு உங்களைக் காண்பியுங்கள் என்றார். அந்தப்படி அவர்கள் போகையில் சுத்தமானார்கள்.
	<br /><br />
	15. அவர்களில் ஒருவன் தான் ஆரோக்கியமானதைக் கண்டு, திரும்பிவந்து, உரத்த சத்தத்தோடே தேவனை மகிமைப்படுத்தி,
	<br /><br />
	16. அவருடைய பாதத்தருகே முகங்குப்புற விழுந்து, அவருக்கு ஸ்தோத்திரஞ்செலுத்தினான்; அவன் சமாரியனாயிருந்தான்.
	<br /><br />
	17. அப்பொழுது இயேசு: சுத்தமானவர்கள் பத்துப்பேர் அல்லவா, மற்ற ஒன்பதுபேர் எங்கே?
	<br /><br />
	18. தேவனை மகிமைப்படுத்துகிறதற்கு, இந்த அந்நியனே ஒழிய மற்றொருவனும் திரும்பிவரக்காணோமே என்று சொல்லி,
	<br /><br />
	19. அவனை நோக்கி: நீ எழுந்துபோ, உன் விசுவாசம் உன்னை இரட்சித்தது என்றார்.
	<br /><br />
	20. தேவனுடைய ராஜ்யம் எப்பொழுது வருமென்று, பரிசேயர் அவரிடத்தில் கேட்டபொழுது, அவர்களுக்கு அவர் பிரதியுத்தரமாக: தேவனுடைய ராஜ்யம் பிரத்தியட்சமாய் வராது.
	<br /><br />
	21. இதோ, இங்கே என்றும், அதோ, அங்கே என்றும் சொல்லப்படுகிறதற்கும் ஏதுவிராது; இதோ, தேவனுடைய ராஜ்யம் உங்களுக்குள் இருக்கிறதே என்றார்.
	<br /><br />
	22. பின்பு அவர் சீஷர்களை நோக்கி: மனுஷகுமாரனுடைய நாட்களிலொன்றைக் காணவேண்டுமென்று நீங்கள் ஆசைப்படுங்காலம் வரும்; ஆனாலும் அதைக் காணமாட்டீர்கள்.
	<br /><br />
	23. இதோ, இங்கே என்றும், அதோ, அங்கே என்றும், சிலர் உங்களிடத்தில் சொல்லுவார்கள்; நீங்களோ போகாமலும் பின்தொடராமலும் இருங்கள்.
	<br /><br />
	24. மின்னல் வானத்தின் ஒரு திசையில் தோன்றி மறுதிசைவரைக்கும் பிரகாசிக்கிறதுபோல மனுஷகுமாரனும் தம்முடைய நாளிலே தோன்றுவார்.
	<br /><br />
	25. அதற்கு முன்பு அவர் அநேகம் பாடுபட்டு, இந்தச் சந்ததியினால் ஆகாதவனென்று தள்ளப்படவேண்டியதாயிருக்கிறது.
	<br /><br />
	26. நோவாவின் நாட்களில் நடந்ததுபோல மனுஷகுமாரனுடைய நாட்களிலும் நடக்கும்.
	<br /><br />
	27. நோவா பேழைக்குள் பிரவேசித்த நாள்வரைக்கும் ஜனங்கள் புசித்துக் குடித்தார்கள், பெண்கொண்டு கொடுத்தார்கள்; ஜலப்பிரளயம் வந்து எல்லாரையும் அழித்துப்போட்டது.
	<br /><br />
	28. லோத்தினுடைய நாட்களில் நடந்ததுபோலவும் நடக்கும்; ஜனங்கள் புசித்தார்கள், குடித்தார்கள், கொண்டார்கள், விற்றார்கள், நட்டார்கள், கட்டினார்கள்.
	<br /><br />
	29. லோத்து சோதோமை விட்டுப் புறப்பட்ட நாளிலே வானத்திலிருந்து அக்கினியும் கந்தகமும் வருஷித்து, எல்லாரையும் அழித்துப்போட்டது.
	<br /><br />
	30. மனுஷகுமாரன் வெளிப்படும் நாளிலும் அப்படியே நடக்கும்.
	<br /><br />
	31. அந்த நாளிலே வீட்டின்மேலிருப்பவன் வீட்டிலுள்ள தன் பண்டங்களை எடுத்துக்கொண்டுபோக இறங்காமல் இருக்கக்கடவன்; அப்படியே வயலிலிருக்கிறவன் பின்னிட்டுத் திரும்பாமலும் இருக்கக்கடவன்.
	<br /><br />
	32. லோத்தின் மனைவியை நினைத்துக் கொள்ளுங்கள்.
	<br /><br />
	33. தன் ஜீவனை இரட்சிக்க வகைதேடுகிறவன் அதை இழந்து போவான்; இழந்துபோகிறவன் அதை உயிர்ப்பித்துக் கொள்ளுவான்.
	<br /><br />
	34. அந்த இராத்திரியில் ஒரே படுக்கையில் படுத்திருக்கிற இரண்டுபேரில் ஒருவன் ஏற்றுக்கொள்ளப்படுவான், மற்றவன் கைவிடப்படுவான்.
	<br /><br />
	35. திரிகை திரிக்கிற இரண்டு ஸ்திரீகளில் ஒருத்தி ஏற்றுக்கொள்ளப்படுவாள், மற்றவள் கைவிடப்படுவாள்.
	<br /><br />
	36. வயலிலிருக்கிற இரண்டுபேரில் ஒருவன் ஏற்றுக்கொள்ளப்படுவான், மற்றவன் கைவிடப்படுவான் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	37. அவர்கள் அவருக்குப் பிரதியுத்தரமாக: எங்கே, ஆண்டவரே, என்றார்கள். அதற்கு அவர்: பிணம் எங்கேயோ அங்கே கழுகுகள் வந்து கூடும் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke17