import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah52 = () => {
  const verseNumber = 52;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 52 </title>
	<meta
          name="description"
          content="Isaiah 52 | ஏசாயா 52 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எழும்பு, எழும்பு, சீயோனே, உன் வல்லமையைத் தரித்துக்கொள்; பரிசுத்த நகரமாகிய எருசலேமே, உன் அலங்கார வஸ்திரங்களை உடுத்திக்கொள்; விருத்தசேதனமில்லாதவனும் அசுத்தனும் இனி உன்னிடத்தில் வருவதில்லை.
	<br /><br />
	2. தூசியை உதறிவிட்டு எழுந்திரு; எருசலேமே, வீற்றிரு; சிறைப்பட்டுப்போன சீயோன் குமாரத்தியே, உன் கழுத்திலுள்ள கட்டுகளை அவிழ்த்து விடு.
	<br /><br />
	3. விலையின்றி விற்கப்பட்டீர்கள், பணமின்றி மீட்கப்படுவீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. பூர்வத்தில் என் ஜனங்கள் தங்கும்படி எகிப்துக்குப் போனார்கள்; அசீரியனும் முகாந்தரமில்லாமல் அவர்களை ஒடுக்கினான் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	5. இப்பொழுது எனக்கு இங்கே என்ன இருக்கிறது, என் ஜனங்கள் விருதாவாய்க் கொண்டுபோகப்பட்டார்கள்; அவர்களை ஆளுகிறவர்கள் அவர்களை அலறப்பண்ணுகிறார்கள்; நித்தமும் இடைவிடாமல் என் நாமம் தூஷிக்கப்படுகிறது என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. இதினிமித்தம், என் ஜனங்கள் என் நாமத்தை அறிவார்கள்; இதைச் சொல்லுகிறவர் நானே என்று அக்காலத்திலே அறிவார்கள்; இதோ, இங்கே இருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. சமாதானத்தைக் கூறி, நற்காரியங்களைச் சுவிசேஷமாய் அறிவித்து, இரட்சிப்பைப் பிரசித்தப்படுத்தி: உன் தேவன் ராஜரிகம் பண்ணுகிறாரென்று சீயோனுக்குச் சொல்லுகிற சுவிசேஷகனுடைய பாதங்கள் மலைகளின்மேல் எவ்வளவு அழகாயிருக்கின்றன.
	<br /><br />
	8. உன் ஜாமக்காரருடைய சத்தம் கேட்கப்படும்; அவர்கள் சத்தமிட்டு ஏகமாய்க் கெம்பீரிப்பார்கள்; ஏனென்றால், கர்த்தர் சீயோனைத் திரும்பிவரப்பண்ணும்போது, அதைக் கண்ணாரக் காண்பார்கள்.
	<br /><br />
	9. எருசலேமின் பாழான ஸ்தலங்களே, முழங்கி ஏகமாய்க் கெம்பீரித்துப் பாடுங்கள்; கர்த்தர் தம்முடைய ஜனங்களுக்கு ஆறுதல்செய்து எருசலேமை மீட்டுக்கொண்டார்.
	<br /><br />
	10. எல்லா ஜாதிகளின் கண்களுக்கு முன்பாகவும் கர்த்தர் தம்முடைய பரிசுத்த புயத்தை வெளிப்படுத்துவார்; பூமியின் எல்லைகளில் உள்ளவர்களெல்லாரும் நமது தேவனுடைய இரட்சிப்பைக் காண்பார்கள்.
	<br /><br />
	11. புறப்படுங்கள், புறப்படுங்கள், அவ்விடம்விட்டுப் போங்கள்; அசுத்தமானதைத் தொடாதிருங்கள்; கர்த்தருடைய பாத்திரங்களைச் சுமக்கிறவர்களே, அதின் நடுவிலிருந்து புறப்பட்டு உங்களைச் சுத்திகரியுங்கள்.
	<br /><br />
	12. நீங்கள் தீவிரித்துப் புறப்படுவதில்லை; நீங்கள் ஓடிப்போகிறவர்கள்போல ஓடிப்போவதுமில்லை; கர்த்தர் உங்கள் முன்னே போவார்; இஸ்ரவேலின் தேவன் உங்கள் பிறகே உங்களைக் காக்கிறவராயிருப்பார்.
	<br /><br />
	13. இதோ, என் தாசன் ஞானமாய் நடப்பார், அவர் உயர்த்தப்பட்டு, மேன்மையும் மகா உன்னதமுமாயிருப்பார்.
	<br /><br />
	14. மனுஷனைப்பார்க்கிலும் அவருடைய முகப்பார்வையும், மனுபுத்திரரைப்பார்க்கிலும் அவருடைய ரூபமும், இவ்வளவு அந்தக்கேடு அடைந்தபடியினாலே, அவரைக்கண்ட அநேகர் பிரமிப்படைந்தார்கள்.
	<br /><br />
	15. அப்படியே, அவர் அநேகம் ஜாதிகள்மேல் தெளிப்பார்; அவர் நிமித்தம் ராஜாக்கள் தங்கள் வாயை மூடுவார்கள்; ஏனெனில், தங்களுக்குத் தெரிவிக்கப்படாதிருந்ததை அவர்கள் காண்பார்கள்; கேள்விப்படாதிருந்ததை அவர்கள் அறிந்துகொள்வார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah52