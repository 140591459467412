import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-6 </title>
	<meta
          name="description"
          content="Ecclesiastes 6 | பிரசங்கி 6 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சூரியனுக்குக் கீழே நான் கண்ட வேறொரு தீங்குமுண்டு; அது மனுஷருக்குள்ளே பெரும்பாலும் நடந்துவருகிறது.
	<br /><br />
	2. அதாவது, ஒருவனுக்குத் தேவன் செல்வத்தையும் சம்பத்தையும் கனத்தையும் கொடுக்கிறார்; அவன் என்ன இச்சித்தாலும் அதெல்லாம் அவனுக்குக் குறைவில்லாமல் கிடைக்கும்; ஆனாலும் அவைகளை அநுபவிக்கும் சக்தியைத் தேவன் அவனுக்குக் கொடுக்கவில்லை; அந்நிய மனுஷன் அதை அநுபவிக்கிறான்; இதுவும் மாயையும், கொடிய நோயுமானது.
	<br /><br />
	3. ஒருவன் நூறு பிள்ளைகளைப் பெற்று, அநேகம் வருஷம் ஜீவித்து, தீர்க்காயுசை அடைந்திருந்தாலும், அவன் ஆத்துமா அந்தச் செல்வத்தால் திருப்தியடையாமலும், அவனுக்குப் பிரேதக்கல்லறை முதலாய் இல்லாமலும் போகுமானால், அவனைப்பார்க்கிலும் கருவழிந்த பிண்டம் வாசி என்கிறேன்.
	<br /><br />
	4. அது மாயையாய்த் தோன்றி இருளிலே மறைந்துபோய்விடுகிறது; அதின் பேர் அந்தகாரத்தால் மூடப்படும்.
	<br /><br />
	5. அது சூரியனைக் கண்டதுமில்லை, ஒன்றையும் அறிந்ததுமில்லை; அவனுக்கு இல்லாத அமைச்சல் அதற்கு உண்டு.
	<br /><br />
	6. அவன் இரண்டாயிரம் வருஷம் பிழைத்திருந்தாலும் ஒரு நன்மையையும் காண்பதில்லை; எல்லாரும் ஒரே இடத்துக்குப் போகிறார்கள் அல்லவா?
	<br /><br />
	7. மனுஷன் படும் பிரயாசமெல்லாம் அவன் வாய்க்காகத்தானே; அவன் மனதுக்கோ திருப்தியில்லை.
	<br /><br />
	8. இப்படியிருக்க, மூடனைப்பார்க்கிலும் ஞானிக்கு உண்டாகும் மேன்மை என்ன? ஜீவனுள்ளோருக்கு முன்பாக நடந்துகொள்ளும்படி அறிந்த ஏழைக்கும் உண்டாகும் மேன்மை என்ன?
	<br /><br />
	9. ஆசையானது அலைந்துதேடுகிறதைப் பார்க்கிலும் கண் கண்டதே நலம்; இதுவும் மாயையும், மனதைச் சஞ்சலப்படுத்துகிறதுமாயிருக்கிறது.
	<br /><br />
	10. இருக்கிறவன் எவனும் தோன்றுமுன்னமே பேரிடப்பட்டிருக்கிறான்; அவன் மனுஷனென்று தெரிந்திருக்கிறது; தன்னிலும் பலத்தவரோடே போராட அவனால் கூடாது.
	<br /><br />
	11. மாயையைப் பெருகப்பண்ணுகிற அநேக விசேஷங்கள் உண்டாயிருக்கிறபடியால் அதினாலே மனுஷருக்குப் பிரயோஜனமென்ன?
	<br /><br />
	12. நிழலைப்போன்ற மாயையான தன் ஜீவகாலத்தைப் போக்கும் மனுஷனுக்கு இந்த ஜீவனில் நன்மை இன்னதென்று அறிந்தவன் யார்? தனக்குப்பின்பு சூரியனுக்குக் கீழே சம்பவிக்குங்காரியம் இன்னதென்று மனுஷனுக்கு அறிவிப்பவன் யார்?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes6