import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 13 </title>
	<meta
          name="description"
          content="Isaiah 13 | ஏசாயா 13 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆமோத்சின் குமாரனாகிய ஏசாயா பாபிலோன்மேல் வரக்கண்ட பாரம்.
	<br /><br />
	2. உயர்ந்த பர்வதத்தின்மேல் கொடியேற்றுங்கள்; உரத்த சத்தமிட்டு ஜனங்களை வரவழையுங்கள்; அவர்கள் பிரபுக்களின் வாசல்களுக்குள் பிரவேசிப்பதற்குச் சைகை காட்டுங்கள்.
	<br /><br />
	3. நான் பரிசுத்தமாக்கினவர்களுக்குக் கட்டளை கொடுத்தேன்; என் கோபத்தை நிறைவேற்ற என் பராக்கிரமசாலிகளை அழைத்தும் இருக்கிறேன்; அவர்கள் என் மகத்துவத்தினாலே களிகூருகிறவர்கள் என்கிறார்.
	<br /><br />
	4. திரளான ஜனங்களின் சத்தத்துக்கொத்த வெகு கூட்டத்தின் இரைச்சலும், கூட்டப்பட்ட ஜாதிகளுடைய ராஜ்யங்களின் அமளியான இரைச்சலும் மலைகளில் கேட்கப்படுகிறது; சேனைகளின் கர்த்தர் யுத்தராணுவத்தை இலக்கம்பார்க்கிறார்.
	<br /><br />
	5. கர்த்தர் வருகிறார்; அவருடைய கோபத்தின் ஆயுதங்களும், தேசத்தையெல்லாம் அழிக்க, வானங்கவிழ்ந்த கடையாந்தர தேசத்திலிருந்து வருகிறது.
	<br /><br />
	6. அலறுங்கள், கர்த்தரின் நாள் சமீபமாயிருக்கிறது; அது சர்வவல்லவரிடத்திலிருந்து மகா சங்காரமாய் வரும்.
	<br /><br />
	7. ஆதலால் எல்லாக் கைகளும் நெகிழ்ந்து, எல்லா மனுஷரின் இருதயமும் கரைந்துபோம்.
	<br /><br />
	8. அவர்கள் திகிலடைவார்கள்; வேதனைகளும் வாதைகளும் அவர்களைப் பிடிக்கும்; பிள்ளைபெறுகிறவளைப்போல வேதனைப்படுவார்கள்; ஒருவரையொருவர் பிரமித்துப்பார்ப்பார்கள்; அவர்கள் முகங்கள் நெருப்பான முகங்களாயிருக்கும்.
	<br /><br />
	9. இதோ, தேசத்தைப் பாழாக்கி, அதின் பாவிகளை அதிலிருந்து அழிப்பதற்காகக் கர்த்தருடைய நாள் கடூரமும், மூர்க்கமும், உக்கிரகோபமுமாய் வருகிறது.
	<br /><br />
	10. வானத்தின் நட்சத்திரங்களும் ராசிகளும் ஒளி கொடாதிருக்கும்; சூரியன் உதிக்கையில் இருண்டுபோம்; சந்திரன் ஒளி கொடாதிருக்கும்.
	<br /><br />
	11. பாவத்தினிமித்தம் உலகத்தையும், அக்கிரமத்தினிமித்தம் துன்மார்க்கரையும் நான் தண்டித்து, அகங்காரரின் பெருமையை ஒழியப்பண்ணி, கொடியரின் இடும்பைத் தாழ்த்துவேன்.
	<br /><br />
	12. புருஷனைப் பசும்பொன்னிலும், மனுஷனை ஓப்பீரின் தங்கத்திலும் அபூருவமாக்குவேன்.
	<br /><br />
	13. இதினிமித்தம் சேனைகளின் கர்த்தருடைய உக்கிரத்தினால் அவருடைய கடுங்கோபத்தின் நாளிலே பூமி தன்னிடத்தைவிட்டு நீங்கும்படி வானத்தை அதிரப்பண்ணுவேன்.
	<br /><br />
	14. துரத்தப்பட்ட வெளிமானைப்போலும், யாரும் சேர்க்காத ஆட்டைப்போலும் இருப்பார்கள்; அவரவர் தங்கள் ஜனத்தண்டைக்குப் போக முகத்தைத் திருப்பி, அவரவர் தங்கள் தேசத்துக்கு ஓடிப்போவார்கள்.
	<br /><br />
	15. அகப்பட்டவனெவனும் குத்துண்டு, அவர்களைச் சேர்ந்திருந்தவனெவனும் பட்டயத்தால் விழுவான்.
	<br /><br />
	16. அவர்கள் குழந்தைகள் அவர்கள் கண்களுக்கு முன்பாக மோதியடிக்கப்படும்; அவர்கள் வீடுகள் கொள்ளையிடப்படும்; அவர்கள் மனைவிகள் அவமானப்படுவார்கள்.
	<br /><br />
	17. இதோ, நான் அவர்களுக்கு விரோதமாய் மேதியரை எழுப்புவேன்; அவர்கள் வெள்ளியை மதியாமலும், பொன்னின்மேல் பிரியப்படாமலும்,
	<br /><br />
	18. வில்லுகளால் இளைஞரைச் சிதைத்துவிடுவார்கள்; கர்ப்பக்கனியின்மேல் அவர்கள் இரங்குவதில்லை; அவர்கள் கண் பிள்ளைகளைத் தப்பவிடுவதுமில்லை.
	<br /><br />
	19. ராஜ்யங்களுக்குள் அலங்காரமும், கல்தேயருடைய பிரதான மகிமையுமாகிய பாபிலோனானது தேவனால் சோதோமும் கொமோராவும் கவிழ்க்கப்பட்டதுபோல கவிழ்க்கப்படும்.
	<br /><br />
	20. இனி ஒருபோதும் அதில் ஒருவரும் குடியேறுவதுமில்லை, தலைமுறைதோறும் அதில் ஒருவரும் தங்கித் தரிப்பதுமில்லை; அங்கே அரபியன் கூடாரம்போடுவதுமில்லை; அங்கே மேய்ப்பர் மந்தையை மறிப்பதுமில்லை.
	<br /><br />
	21. காட்டுமிருகங்கள் அங்கே படுத்துக்கொள்ளும்; ஊளையிடும் பிராணிகள் அவர்கள் வீடுகளை நிரப்பும்; கோட்டான்கள் அங்கே குடிகொள்ளும்; காட்டாடு அங்கே துள்ளும்.
	<br /><br />
	22. அவர்கள் பாழான மாளிகைகளில் ஓரிகள் ஊளையிடும்; வலுசர்ப்பங்கள் அவர்கள் செல்விக்கையான அரமனைகளில் ஏகமாய்க் கூடும்; அதின்காலம் சீக்கிரம் வரும், அதின் நாட்கள் நீடித்திராது என்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah13