import React from 'react';
import '../.././App.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

function SecondTimothy (){
return (
    <>
    <Helmet>
        <title> Second Letter to Timothy in Tamil Bible Verse | 2 தீமோத்தேயு </title>
        <meta
          name="description"
          content="Second Letter to the Timothy in Tamil Bible |  தீமோத்தேயுக்கு பவுல் எழுதின இரண்டாம் நிருபம் | Tamil Bible Verse | புதிய ஏற்பாடு New Testament"
        />
      </Helmet>
    <div className='body'>
        <h2 className='chapterh' align="center"> 1 தீமோத்தேயு </h2>
        <div className='chapter'>
        <div className='introduction'>
        <p className='article content'>
            {/* Introductions of Tamilbible verses */}
            <h2>
			தீமோத்தேயுக்கு எழுதிய இரண்டாம் நிருபம்
</h2>
<h4>
	ஆசிரியர்:
</h4>

<p>
	2 தீமோத்தேயு 1:1, 2 தீமோத்தேயு புத்தகத்தின் ஆசிரியரை அப்போஸ்தலன் பவுல் என்று அடையாளப்படுத்துகிறது.
</p>
<h2>
	எழுதப்பட்ட தேதி
</h2>
<p>
	2 தீமோத்தேயு புத்தகம் அப்போஸ்தலனாகிய பவுல் கொல்லப்படுவதற்கு சற்று முன்பு, தோராயமாக கிபி 67 இல் எழுதப்பட்டது.
</p>
<h2>
	எழுதப்பட்டதன் நோக்கம்
</h2>
<p>
	ரோமில் மீண்டும் சிறையில் அடைக்கப்பட்ட அப்போஸ்தலன் பவுல் தனிமையாகவும் கைவிடப்பட்டதாகவும் உணர்ந்தார். தன்னுடைய பூமிக்குரிய வாழ்க்கை விரைவில் முடிவுக்கு வரப்போகிறது என்பதை பவுல் உணர்ந்தார். 2 தீமோத்தேயு புத்தகம் அடிப்படையில் பவுலின் &ldquo;கடைசி வார்த்தைகள்&rdquo; ஆகும். தேவாலயங்கள் மற்றும் குறிப்பாக தீமோத்தேயு மீது அக்கறை காட்ட பவுல் தனது சொந்த சூழ்நிலைகளைக் கடந்தார். பவுல் தனது கடைசி வார்த்தைகளைப் பயன்படுத்தி தீமோத்தேயுவையும் மற்ற எல்லா விசுவாசிகளையும் விசுவாசத்தில் நிலைத்திருக்க ஊக்குவித்தார் ( 2 தீமோத்தேயு 3:14 ) மற்றும் இயேசு கிறிஸ்துவின் நற்செய்தியை அறிவிக்க விரும்பினார் ( 2 தீமோத்தேயு 4:2)
</p>
<h3>
	முக்கிய வசனங்கள்
</h3>
<p>
	2 தீமோத்தேயு 1:7 , "கடவுள் நமக்கு பயமுறுத்தும் ஆவியைக் கொடுக்கவில்லை, மாறாக வலிமை, அன்பு மற்றும் சுய ஒழுக்கத்தின் ஆவியைக் கொடுத்தார்."
</p>
<p>
	2 தீமோத்தேயு 3:16-17 , "எல்லா வேதவாக்கியங்களும் தேவனால் சுவாசிக்கப்பட்டது, மேலும் போதிக்கவும், கடிந்துகொள்ளவும், திருத்தவும், நீதியைப் பயிற்றுவிக்கவும் பயனுள்ளதாக இருக்கிறது, இதனால் கடவுளின் மனிதன் ஒவ்வொரு நற்கிரியைக்கும் முற்றிலும் ஆயத்தமாக இருக்க வேண்டும்."
</p>
<p>
	2 தீமோத்தேயு 4:2 , &ldquo;வார்த்தையைப் பிரசங்கியுங்கள்; சீசன் மற்றும் வெளியே தயார்; மிகவும் பொறுமை மற்றும் கவனமான அறிவுறுத்தலுடன் திருத்தவும், கண்டிக்கவும் மற்றும் ஊக்குவிக்கவும்.
</p>
<p>
	2 தீமோத்தேயு 4:7-8, &ldquo;நல்ல போராட்டத்தைப் போராடினேன், ஓட்டத்தை முடித்தேன், விசுவாசத்தைக் காத்துக்கொண்டேன். இப்போது நீதியின் கிரீடம் எனக்காகக் காத்திருக்கிறது, நீதியுள்ள நியாயாதிபதியாகிய கர்த்தர் அந்நாளில் அதை எனக்குக் கொடுப்பார் - எனக்கு மட்டுமல்ல, அவர் தோன்றுவதற்கு ஏங்கிய அனைவருக்கும்.
</p>
<h2>
	சுருக்கமான சுருக்கம்
</h2>
<p>
	பவுல் தீமோத்தேயுவை கிறிஸ்துவின் மீது பேரார்வத்துடன் இருக்கவும், உறுதியான கோட்பாட்டில் உறுதியாக இருக்கவும் ஊக்குவிக்கிறார் ( 2 தீமோத்தேயு 1:1-2 , 13-14 ). தேவபக்தியற்ற நம்பிக்கைகள் மற்றும் பழக்கவழக்கங்களைத் தவிர்க்கவும், ஒழுக்கக்கேடான எதிலிருந்தும் தப்பிக்கவும் தீமோத்தேயுவுக்கு பவுல் நினைவூட்டுகிறார் ( 2 தீமோத்தேயு 2:14-26 ). இறுதிக் காலத்தில் கிறிஸ்தவ நம்பிக்கையிலிருந்து கடுமையான துன்புறுத்தல் மற்றும் விசுவாச துரோகம் இரண்டும் இருக்கும் ( 2 தீமோத்தேயு 3:1-17) விசுவாசத்தில் உறுதியாக நிற்கவும், பந்தயத்தை வலுவாக முடிக்கவும் விசுவாசிகளுக்கு ஒரு தீவிர வேண்டுகோளுடன் பவுல் முடிக்கிறார் ( 2 தீமோத்தேயு 4:1-8 ).
</p>
<h2>
	இணைப்புகள்
</h2>
<p>
	தீமோத்தேயு மற்றும் அவர் தவறான போதகர்களின் ஆபத்துக்களைப் பற்றி எச்சரிப்பதில் பவுல் மிகவும் அக்கறை கொண்டிருந்தார், அவர் மோசேயை எதிர்த்த எகிப்திய மந்திரவாதிகளின் கதையைப் பயன்படுத்தினார் (யாத்திராகமம் 7:11 , 22 ; 8:7 , 18 , 19 ; 9:11 ; ) பழைய ஏற்பாட்டில் அவர்களின் பெயர்கள் குறிப்பிடப்படவில்லை என்றாலும், இந்த மனிதர்கள் தங்கக் கன்றுக்குட்டியைக் கட்டத் தூண்டியதாகவும், மற்ற விக்கிரகாராதகர்களுடன் கொல்லப்பட்டதாகவும் பாரம்பரியம் கூறுகிறது ( யாத்திராகமம் 32) கிறிஸ்துவின் சத்தியத்தை எதிர்ப்பவர்களுக்கும் அதே விதியை பவுல் கணிக்கிறார், அவர்களின் முட்டாள்தனம் இறுதியில் "அனைவருக்கும்" தெளிவாக்கப்படுகிறது ( 2 தீமோத்தேயு 3:9 ).
</p>
<h3>
	நடைமுறை பயன்பாடு
</h3>
<p>
	கிறிஸ்தவ வாழ்க்கையில் பக்கவாட்டாக இருப்பது எளிது. இயேசு கிறிஸ்துவால் பரலோகத்தில் வெகுமதி பெறப்படும் ( 2 தீமோத்தேயு 4:8 ) பரிசின் மீது நாம் நம் கண்களை வைத்திருக்க வேண்டும். தவறான கோட்பாடு மற்றும் தெய்வபக்தியற்ற நடைமுறைகள் இரண்டையும் தவிர்க்க நாம் முயற்சி செய்ய வேண்டும். கடவுளுடைய வார்த்தையைப் பற்றிய நமது அறிவில் அடித்தளமாக இருப்பதன் மூலமும், பைபிளுக்கு புறம்பான எதையும் ஏற்றுக்கொள்ள மறுப்பதில் உறுதியாக இருப்பதன் மூலமும் மட்டுமே இதை நிறைவேற்ற முடியும்.
</p>


			<h1>
	THE Second LETTER TO Timothy Tamil Bible
</h1>
<h3>
	Author
</h3>
<p>
	2 Timothy 1:1 identifies the author of the Book of 2 Timothy as the apostle Paul.
</p>
<h2>
	Date of Writing
</h2>
<p>
	The Book of 2 Timothy was written in approximately A.D. 67, shortly before the apostle Paul was put to death.
</p>
<h2>
	Purpose of Writing
</h2>
<p>
	Imprisoned in Rome yet again, the apostle Paul felt lonely and abandoned. Paul recognized that his earthly life was likely coming to an end soon. The Book of 2 Timothy is essentially Paul&rsquo;s &ldquo;last words.&rdquo; Paul looked past his own circumstances to express concern for the churches and specifically for Timothy. Paul wanted to use his last words to encourage Timothy, and all other believers, to persevere in faith (2 Timothy 3:14) and proclaim the gospel of Jesus Christ (2 Timothy 4:2).
</p>
<h3>
	Key Verses
</h3>
<p>
	2 Timothy 1:7, &ldquo;For God did not give us a spirit of timidity, but a spirit of power, of love and of self-discipline.&rdquo;
</p>
<p>
	2 Timothy 3:16-17, &ldquo;All Scripture is God-breathed and is useful for teaching, rebuking, correcting and training in righteousness, so that the man of God may be thoroughly equipped for every good work.&rdquo;
</p>
<p>
	2 Timothy 4:2, &ldquo;Preach the Word; be prepared in season and out of season; correct, rebuke and encourage&mdash;with great patience and careful instruction.&rdquo;
</p>
<p>
	2 Timothy 4:7-8, &ldquo;I have fought the good fight, I have finished the race, I have kept the faith. Now there is in store for me the crown of righteousness, which the Lord, the righteous Judge, will award to me on that day&mdash;and not only to me, but also to all who have longed for his appearing.&rdquo;
</p>
<h2>
	Brief Summary
</h2>
<p>
	Paul encourages Timothy to remain passionate for Christ and to remain firm in sound doctrine (2 Timothy 1:1-2, 13-14). Paul reminds Timothy to avoid ungodly beliefs and practices and to flee from anything immoral (2 Timothy 2:14-26). In the end times there will be both intense persecution and apostasy from the Christian faith (2 Timothy 3:1-17). Paul closes with an intense plea for believers to stand firm in the faith and to finish the race strong (2 Timothy 4:1-8).
</p>
<h2>
	Connections
</h2>
<p>
	So concerned was Paul to warn Timothy and those he pastored of the dangers of false teachers that he invoked the story of the Egyptian magicians who opposed Moses (Exodus 7:11, 22; 8:7, 18, 19; 9:11). Although their names are not mentioned in the Old Testament, tradition has it that these men instigated the building of the golden calf and were killed with the rest of the idolaters (Exodus 32). Paul predicts the same fate for those who resist the truth of Christ, their folly eventually being made &ldquo;clear to everyone&rdquo; (2 Timothy 3:9).
</p>
<h3>
	Practical Application
</h3>
<p>
	It is easy to get side-tracked in the Christian life. We have to keep our eyes on the prize&mdash;being rewarded in heaven by Jesus Christ (2 Timothy 4:8). We must strive to avoid both false doctrine and ungodly practices. This can only be accomplished by being grounded in our knowledge of God&rsquo;s Word and firm in our refusal to accept anything that is unbiblical.
</p>


            {/* End Introduction */}
        </p>

        </div>
        <div>
            <div className='chap-link'>
                <div className='btn'> <Link to="/2-Timothy-1"> 01</Link> </div>
                <div className='btn'> <Link to="/2-Timothy-2"> 02</Link> </div>
                <div className='btn'> <Link to="/2-Timothy-3"> 03</Link> </div>
                <div className='btn'> <Link to="/2-Timothy-4"> 04</Link> </div>

				            
                
            </div>
        </div>
    </div>
    </div>
    </>
);
}

export default SecondTimothy;