import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-1 </title>
	<meta
          name="description"
          content="Ecclesiastes | பிரசங்கி 1 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தாவீதின் குமாரனும் எருசலேமின் ராஜாவுமாகிய பிரசங்கியின் வாக்கியங்கள்.
	<br /><br />
	2. மாயை, மாயை, எல்லாம் மாயை என்று பிரசங்கி சொல்லுகிறான்.
	<br /><br />
	3. சூரியனுக்குக் கீழே மனுஷன் படுகிற எல்லாப் பிரயாசத்தினாலும் அவனுக்குப் பலன் என்ன?
	<br /><br />
	4. ஒரு சந்ததி போகிறது, மறு சந்ததி வருகிறது; பூமியோ என்றைக்கும் நிலைத்திருக்கிறது.
	<br /><br />
	5. சூரியன் உதிக்கிறது, சூரியன் அஸ்தமிக்கிறது; தான் உதித்த இடத்திற்கே அது திரும்பவும் தீவிரிக்கிறது.
	<br /><br />
	6. காற்று தெற்கே போய், வடக்கேயுஞ்சுற்றி, சுழன்று சுழன்று அடித்து, தான் சுற்றின இடத்துக்கே திரும்பவும் வரும்.
	<br /><br />
	7. எல்லா நதிகளும் சமுத்திரத்திலே ஓடி விழுந்தும் சமுத்திரம் நிரம்பாது; தாங்கள் உற்பத்தியான இடத்திற்கே நதிகள் மறுபடியும் திரும்பும்.
	<br /><br />
	8. எல்லாம் வருத்தத்தினால் நிறைந்திருக்கிறது; அது மனுஷரால் சொல்லிமுடியாது; காண்கிறதினால் கண் திருப்தியாகிறதில்லை, கேட்கிறதினால் செவி நிரப்பப்படுகிறதுமில்லை.
	<br /><br />
	9. முன் இருந்ததே இனிமேலும் இருக்கும்; முன் செய்யப்பட்டதே பின்னும் செய்யப்படும்; சூரியனுக்குக் கீழே நூதனமானது ஒன்றுமில்லை.
	<br /><br />
	10. இதைப் பார், இது நூதனம் என்று சொல்லப்படத்தக்க காரியம் ஒன்றுண்டோ? அது நமக்கு முன்னுள்ள பூர்வகாலங்களிலும் இருந்ததே.
	<br /><br />
	11. முன் இருந்தவைகளைப்பற்றி ஞாபகம் இல்லை; அப்படியே பின்வரும் காரியங்களைப்பற்றியும் இனிமேலிருப்பவர்களுக்கு ஞாபகம் இராது.
	<br /><br />
	12. பிரசங்கியாகிய நான் எருசலேமில் இஸ்ரவேலருக்கு ராஜாவாயிருந்தேன்.
	<br /><br />
	13. வானத்தின்கீழ் நடப்பதையெல்லாம் ஞானமாய் விசாரித்து ஆராய்ச்சி செய்கிறதற்கு என் மனதைப் பிரயோகம்பண்ணினேன்; மனுபுத்திரர் இந்தக் கடுந்தொல்லையில் அடிபடும்படிக்குத் தேவன் அதை அவர்களுக்கு நியமித்திருக்கிறார்.
	<br /><br />
	14. சூரியனுக்குக் கீழே செய்யப்படுகிற காரியங்களையெல்லாம் கவனித்துப் பார்த்தேன்; இதோ, எல்லாம் மாயையும், மனதுக்குச் சஞ்சலமுமாயிருக்கிறது.
	<br /><br />
	15. கோணலானதை நேராக்கக்கூடாது; குறைவானதை எண்ணிமுடியாது.
	<br /><br />
	16. இதோ, நான் பெரியவனாயிருந்து, எனக்குமுன் எருசலேமிலிருந்த எல்லாரைப்பார்க்கிலும் ஞானமடைந்து தேறினேன்; என் மனம் மிகுந்த ஞானத்தையும் அறிவையும் கண்டறிந்தது என்று நான் என் உள்ளத்திலே சொல்லிக்கொண்டேன்.
	<br /><br />
	17. ஞானத்தை அறிகிறதற்கும், பைத்தியத்தையும் மதியீனத்தையும் அறிகிறதற்கும், நான் என் மனதைப் பிரயோகம்பண்ணினேன்; இதுவும் மனதுக்குச் சஞ்சலமாயிருக்கிறதென்று கண்டேன்.
	<br /><br />
	18. அதிக ஞானத்திலே அதிக சலிப்புண்டு; அறிவுபெருத்தவன் நோவுபெருத்தவன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes1