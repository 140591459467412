import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 9 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 9
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எனக்கு மிகுந்த துக்கமும் இடைவிடாத மனவேதனையும் உண்டாயிருக்கிறது;
	<br /><br />
	2. நான் சொல்லுகிறது பொய்யல்ல, கிறிஸ்துவுக்குள் உண்மையைச் சொல்லுகிறேன் என்று பரிசுத்தஆவிக்குள் என் மனச்சாட்சியும் எனக்குச் சாட்சியாயிருக்கிறது.
	<br /><br />
	3. மாம்சத்தின்படி என் இனத்தாராகிய என் சகோதரருக்குப் பதிலாக நானே கிறிஸ்துவைவிட்டுச் சபிக்கப்பட்டவனாக வேண்டுமென்று விரும்புவேனே.
	<br /><br />
	4. அவர்கள் இஸ்ரவேலரே; புத்திரசுவிகாரமும், மகிமையும், உடன்படிக்கைகளும், நியாயப்பிரமாணமும், தேவாராதனையும், வாக்குத்தத்தங்களும் அவர்களுடையவைகளே;
	<br /><br />
	5. பிதாக்கள் அவர்களுடையவர்களே; மாம்சத்தின்படி கிறிஸ்துவும் அவர்களில் பிறந்தாரே, இவர் என்றென்றைக்கும் ஸ்தோத்திரிக்கப்பட்ட சர்வத்திற்கும் மேலான தேவன். ஆமென்.
	<br /><br />
	6. தேவவசனம் அவமாய்ப் போயிற்றென்று சொல்லக்கூடாது; ஏனென்றால், இஸ்ரவேல் வம்சத்தார் எல்லாரும் இஸ்ரவேலரல்லவே.
	<br /><br />
	7. அவர்கள் ஆபிரகாமின் சந்ததியாரானாலும் எல்லாரும் பிள்ளைகளல்லவே; ஈசாக்கினிடத்தில் உன் சந்ததி விளங்கும் என்று சொல்லியிருக்கிறதே.
	<br /><br />
	8. அதெப்படியென்றால், மாம்சத்தின்படி பிள்ளைகளானவர்கள் தேவனுடைய பிள்ளைகளல்ல, வாக்குத்தத்தத்தின்படி பிள்ளைகளானவர்களே அந்தச் சந்ததி என்றெண்ணப்படுகிறார்கள்.
	<br /><br />
	9. அந்த வாக்குத்தத்தமான வார்த்தையாவது: குறித்த காலத்திலே வருவேன், அப்பொழுது சாராள் ஒரு குமாரனைப் பெறுவாள் என்பதே.
	<br /><br />
	10. இதுவுமல்லாமல், நம்முடைய பிதாவாகிய ஈசாக்கு என்னும் ஒருவனாலே ரெபெக்காள் கர்ப்பவதியானபோது,
	<br /><br />
	11. பிள்ளைகள் இன்னும் பிறவாமலும், நல்வினை தீவினை ஒன்றும் செய்யாமலுமிருக்கையில், தேவனுடைய தெரிந்துகொள்ளுதலின்படியிருக்கிற அவருடைய தீர்மானம் கிரியைகளினாலே நிலைநிற்காமல் அழைக்கிறவராலே நிலைநிற்கும்படிக்கு,
	<br /><br />
	12. மூத்தவன் இளையவனுக்கு ஊழியஞ்செய்வான் என்று அவளுடனே சொல்லப்பட்டது.
	<br /><br />
	13. அப்படியே, யாக்கோபைச் சிநேகித்து, ஏசாவை வெறுத்தேன் என்றும் எழுதியிருக்கிறது.
	<br /><br />
	14. ஆகையால் நாம் என்ன சொல்லுவோம்? தேவனிடத்திலே அநீதி உண்டென்று சொல்லலாமா? சொல்லக்கூடாதே.
	<br /><br />
	15. அவர் மோசேயை நோக்கி: எவன்மேல் இரக்கமாயிருக்கச் சித்தமாயிருப்பேனோ அவன்மேல் இரக்கமாயிருப்பேன், எவன்மேல் உருக்கமாயிருக்கச் சித்தமாயிருப்பேனோ அவன்மேல் உருக்கமாயிருப்பேன் என்றார்.
	<br /><br />
	16. ஆகையால் விரும்புகிறவனாலும் அல்ல, ஓடுகிறவனாலும் அல்ல, இரங்குகிற தேவனாலேயாம்.
	<br /><br />
	17. மேலும் என்னுடைய வல்லமையை உன்னிடத்தில் காண்பிக்கும்படியாகவும், என்னுடைய நாமம் பூமியில் எங்கும் பிரஸ்தாபமாகும்படியாகவும், உன்னை நிலைநிறுத்தினேன் என்று பார்வோனுடனே சொன்னதாக வேதத்தில் சொல்லியிருக்கிறது.
	<br /><br />
	18. ஆதலால் எவன்மேல் இரக்கமாயிருக்கச் சித்தமாயிருக்கிறாரோ அவன்மேல் இரக்கமாயிருக்கிறார், எவனைக் கடினப்படுத்தச் சித்தமாயிருக்கிறாரோ அவனைக் கடினப்படுத்துகிறார்.
	<br /><br />
	19. இப்படியானால், அவர் இன்னும் ஏன் குற்றம்பிடிக்கிறார்? அவர் சித்தத்திற்கு எதிர்த்துநிற்பவன் யார்? என்று என்னுடனே சொல்லுவாய்.
	<br /><br />
	20. அப்படியானால், மனுஷனே, தேவனோடு எதிர்த்துத் தர்க்கிக்கிற நீ யார்? உருவாக்கப்பட்ட வஸ்து உருவாக்கினவனை நோக்கி: நீ என்னை ஏன் இப்படி உண்டாக்கினாயென்று சொல்லலாமா?
	<br /><br />
	21. மிதியிட்ட ஒரே களிமண்ணினாலே குயவன் ஒரு பாத்திரத்தைக் கனமான காரியத்துக்கும், ஒரு பாத்திரத்தைக் கனவீனமான காரியத்துக்கும் பண்ணுகிறதற்கு மண்ணின்மேல் அவனுக்கு அதிகாரம் இல்லையோ?
	<br /><br />
	22. தேவன் தமது கோபத்தைக் காண்பிக்கவும், தமது வல்லமையைத் தெரிவிக்கவும்,
	<br /><br />
	23. தாம் மகிமைக்காக எத்தனமாக்கின கிருபாபாத்திரங்கள்மேல் தம்முடைய மகிமையின் ஐசுவரியத்தைத் தெரியப்படுத்தவும் சித்தமாய், அழிவுக்கு எத்தனமாக்கப்பட்ட கோபாக்கினைப் பாத்திரங்கள்மேல் மிகவும் நீடிய சாந்தத்தோடே பொறுமையாயிருந்தாரானால் உனக்கென்ன?
	<br /><br />
	24. அவர் யூதரிலிருந்துமாத்திரமல்ல, புறஜாதிகளிலுமிருந்து நம்மை அழைத்திருக்கிறாரே.
	<br /><br />
	25. அந்தப்படி: எனக்கு ஜனங்களல்லாதவர்களை என்னுடைய ஜனங்கள் என்றும், சிநேகிக்கப்படாதிருந்தவளைச் சிநேகிக்கப்பட்டவள் என்றும் சொல்லி அழைப்பேன்.
	<br /><br />
	26. நீங்கள் என்னுடைய ஜனங்களல்லவென்று அவர்களுக்குச் சொல்லப்பட்ட இடத்திலே அவர்கள் ஜீவனுள்ள தேவனுடைய பிள்ளைகள் என்னப்படுவார்கள் என்று ஓசியாவின் தீர்க்கதரிசனத்தில் சொல்லியிருக்கிறது.
	<br /><br />
	27. அல்லாமலும் இஸ்ரவேல் புத்திரருடைய இலக்கம் சமுத்திரத்தின் மணலத்தனையாயிருந்தாலும், மீதியாயிருப்பவர்கள் மாத்திரம் இரட்சிக்கப்படுவார்கள் என்றும்;
	<br /><br />
	28. அவர் நீதியோடே சீக்கிரமாய்த் தம்முடைய காரியத்தை நிறைவேற்றுவார்; கர்த்தர் பூமியிலே சீக்கிரமாகவே காரியத்தை நிறைவேற்றி முடிப்பார் என்றும் ஏசாயா இஸ்ரவேலரைக்குறித்துச் சொல்லுகிறான்.
	<br /><br />
	29. அல்லாமலும் ஏசாயா முன்னே சொன்னபடி: சேனைகளின் கர்த்தர் நமக்குள்ளே ஒரு சந்ததியை மீதியாக வைக்காதிருந்தாரானால் நாம் சோதோமைப்போலாகி கொமோராவுக்கு ஒத்திருப்போம்.
	<br /><br />
	30. இப்படியிருக்க நாம் என்னசொல்லுவோம்? நீதியைத் தேடாத புறஜாதியார் நீதியை அடைந்தார்கள்; அது விசுவாசத்தினாலாகும் நீதியே.
	<br /><br />
	31. நீதிப்பிரமாணத்தைத் தேடின இஸ்ரவேலரோ நீதிப்பிரமாணத்தை அடையவில்லை.
	<br /><br />
	32. என்னத்தினாலென்றால், அவர்கள் விசுவாசத்தினாலே அதைத் தேடாமல், நியாயப்பிரமாணத்தின் கிரியைகளினாலே தேடினபடியால் அதை அடையவில்லை; இடறுதற்கான கல்லில் இடறினார்கள்.
	<br /><br />
	33. இதோ, இடறுதற்கான கல்லையும், தவறுதற்கான கன்மலையையும், சீயோனில் வைக்கிறேன்; அவரிடத்தில் விசுவாசமாயிருப்பவன் எவனோ அவன் வெட்கப்படுவதில்லை என்று எழுதியிருக்கிறபடியாயிற்று.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans9