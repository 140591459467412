import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 16 </title>
	<meta
          name="description"
          content="Matthew 16 | மத்தேயு 16 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பரிசேயரும் சதுசேயரும் அவரைச் சோதிக்கும்படி அவரிடத்தில் வந்து: வானத்திலிருந்து ஓர் அடையாளத்தைத் தங்களுக்குக் காண்பிக்கவேண்டும் என்று கேட்டார்கள்.
	<br /><br />
	2. அவர்களுக்கு அவர் பிரதியுத்தரமாக: அஸ்தமனமாகிறபோது, செவ்வானமிட்டிருக்கிறது, அதினால் வெளிவாங்கும் என்று சொல்லுகிறீர்கள்.
	<br /><br />
	3. உதயமாகிறபோது, செவ்வானமும் மந்தாரமுமாயிருக்கிறது, அதினால் இன்றைக்குக் காற்றும் மழையும் உண்டாகும் என்று சொல்லுகிறீர்கள். மாயக்காரரே, வானத்தின் தோற்றத்தை நிதானிக்க உங்களுக்குத் தெரியுமே, காலங்களின் அடையாளங்களை நிதானிக்க உங்களால் கூடாதா?
	<br /><br />
	4. இந்தப் பொல்லாத விபசார சந்ததியார் அடையாளம் தேடுகிறார்கள்; யோனா தீர்க்கதரிசியின் அடையாளமேயன்றி வேறே அடையாளம் இவர்களுக்குக் கொடுக்கப்படுவதில்லை என்று சொல்லி, அவர்களை விட்டுப் புறப்பட்டுப்போனார்.
	<br /><br />
	5. அவருடைய சீஷர்கள் அக்கரை சேர்ந்தபோது, அப்பங்களைக் கொண்டுவர மறந்துபோனார்கள்.
	<br /><br />
	6. இயேசு அவர்களை நோக்கி: பரிசேயர் சதுசேயர் என்பவர்களின் புளித்தமாவைக்குறித்து எச்சரிக்கையாயிருங்கள் என்றார்.
	<br /><br />
	7. நாம் அப்பங்களைக் கொண்டுவராதபடியால் இப்படிச் சொல்லுகிறார் என்று அவர்கள் தங்களுக்குள்ளே யோசனைபண்ணிக்கொண்டார்கள்.
	<br /><br />
	8. இயேசு அதை அறிந்து: அற்பவிசுவாசிகளே, அப்பங்களைக் கொண்டுவராததைக்குறித்து நீங்கள் உங்களுக்குள்ளே யோசனைபண்ணுகிறதென்ன?
	<br /><br />
	9. இன்னும் நீங்கள் உணரவில்லையா? ஐந்து அப்பங்களை ஐயாயிரம்பேருக்குப் பகிர்ந்ததையும், மீதியானதை எத்தனை கூடைநிறைய எடுத்தீர்கள் என்பதையும்;
	<br /><br />
	10. ஏழு அப்பங்களை நாலாயிரம்பேருக்குப் பகிர்ந்ததையும், மீதியானதை எத்தனை கூடைநிறைய எடுத்தீர்கள் என்பதையும் நீங்கள் நினைவுகூராமலிருக்கிறீர்களா?
	<br /><br />
	11. பரிசேயர் சதுசேயர் என்பவர்களின் புளித்தமாவுக்கு எச்சரிக்கையாயிருக்கவேண்டும் என்று நான் சொன்னது அப்பத்தைக்குறித்துச் சொல்லவில்லை என்று நீங்கள் உணராதிருக்கிறது எப்படி என்றார்.
	<br /><br />
	12. அப்பொழுது, அவர் அப்பத்தின் புளித்தமாவைக்குறித்து எச்சரிக்கையாயிருக்கவேண்டுமென்று சொல்லாமல், பரிசேயர் சதுசேயர் என்பவர்களின் உபதேசத்தைக்குறித்தே அப்படிச் சொன்னார் என்று அறிந்துகொண்டார்கள்.
	<br /><br />
	13. பின்பு, இயேசு பிலிப்புச் செசரியாவின் திசைகளில் வந்தபோது, தம்முடைய சீஷரை நோக்கி: மனுஷகுமாரனாகிய என்னை ஜனங்கள் யார் என்று சொல்லுகிறார்கள் என்று கேட்டார்.
	<br /><br />
	14. அதற்கு அவர்கள்: சிலர் உம்மை யோவான்ஸ்நானன் என்றும், சிலர் எலியா என்றும்; வேறு சிலர் எரேமியா, அல்லது தீர்க்கதரிசிகளில் ஒருவர் என்றும் சொல்லுகிறார்கள் என்றார்கள்.
	<br /><br />
	15. அப்பொழுது அவர்: நீங்கள் என்னை யார் என்று சொல்லுகிறீர்கள் என்று கேட்டார்.
	<br /><br />
	16. சீமோன் பேதுரு பிரதியுத்தரமாக: நீர் ஜீவனுள்ள தேவனுடைய குமாரனாகிய கிறிஸ்து என்றான்.
	<br /><br />
	17. இயேசு அவனை நோக்கி: யோனாவின் குமாரனாகிய சீமோனே, நீ பாக்கியவான்; மாம்சமும் இரத்தமும் இதை உனக்கு வெளிப்படுத்தவில்லை, பரலோகத்திலிருக்கிற என் பிதா இதை உனக்கு வெளிப்படுத்தினார்.
	<br /><br />
	18. மேலும், நான் உனக்குச் சொல்லுகிறேன், நீ பேதுருவாய் இருக்கிறாய், இந்தக் கல்லின்மேல் என் சபையைக் கட்டுவேன்; பாதாளத்தின் வாசல்கள் அதை மேற்கொள்வதில்லை.
	<br /><br />
	19. பரலோகராஜ்யத்தின் திறவுகோல்களை நான் உனக்குத் தருவேன்; பூலோகத்திலே நீ கட்டுகிறது எதுவோ அது பரலோகத்திலும் கட்டப்பட்டிருக்கும், பூலோகத்திலே நீ கட்டவிழ்ப்பது எதுவோ அது பரலோகத்திலும் கட்டவிழ்க்கப்பட்டிருக்கும் என்றார்.
	<br /><br />
	20. அப்பொழுது, தாம் கிறிஸ்துவாகிய இயேசு என்று ஒருவருக்கும் சொல்லாதபடிக்குத் தம்முடைய சீஷர்களுக்குக் கட்டளையிட்டார்.
	<br /><br />
	21. அதுமுதல் இயேசு, தாம் எருசலேமுக்குப்போய், மூப்பராலும் பிரதான ஆசாரியராலும் வேதபாரகராலும் பல பாடுகள்பட்டு, கொலையுண்டு, மூன்றாம் நாளில் எழுந்திருக்கவேண்டும் என்பதைத் தம்முடைய சீஷர்களுக்குச் சொல்லத்தொடங்கினார்.
	<br /><br />
	22. அப்பொழுது, பேதுரு அவரைத் தனியே அழைத்துக்கொண்டுபோய்: ஆண்டவரே, இது உமக்கு நேரிடக்கூடாதே, இது உமக்குச் சம்பவிப்பதில்லை என்று அவரைக் கடிந்துகொள்ளத் தொடங்கினான்.
	<br /><br />
	23. அவரோ திரும்பிப் பேதுருவைப் பார்த்து: எனக்குப் பின்னாகப்போ, சாத்தானே, நீ எனக்கு இடறலாயிருக்கிறாய்; தேவனுக்கு ஏற்றவைகளைச் சிந்தியாமல் மனுஷருக்கு ஏற்றவைகளைச் சிந்திக்கிறாய் என்றார்.
	<br /><br />
	24. அப்பொழுது, இயேசு தம்முடைய சீஷர்களை நோக்கி: ஒருவன் என்னைப் பின்பற்றி வர விரும்பினால், அவன் தன்னைத்தான் வெறுத்து, தன் சிலுவையை எடுத்துக்கொண்டு என்னைப் பின்பற்றக்கடவன்.
	<br /><br />
	25. தன் ஜீவனை ரட்சிக்க விரும்புகிறவன் அதை இழந்துபோவான்; என்னிமித்தமாகத் தன் ஜீவனை இழந்துபோகிறவன் அதைக் கண்டடைவான்.
	<br /><br />
	26. மனுஷன் உலகம் முழுவதையும் ஆதாயப்படுத்திக்கொண்டாலும், தன் ஜீவனை நஷ்டப்படுத்தினால் அவனுக்கு லாபம் என்ன? மனுஷன் தன் ஜீவனுக்கு ஈடாக என்னத்தைக் கொடுப்பான்?
	<br /><br />
	27. மனுஷகுமாரன் தம்முடைய பிதாவின் மகிமைபொருந்தினவராய்த் தம்முடைய தூதரோடுங்கூட வருவார்; அப்பொழுது, அவனவன் கிரியைக்குத்தக்கதாக அவனவனுக்குப் பலனளிப்பார்.
	<br /><br />
	28. இங்கே நிற்கிறவர்களில் சிலர் மனுஷகுமாரன் தம்முடைய ராஜ்யத்தில் வருவதைக் காணுமுன், மரணத்தை ருசிபார்ப்பதில்லை என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew16