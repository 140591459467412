import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 16 </title>
	<meta
          name="description"
          content="The Book of Revelation - 17 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 16 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கோபத்தின் கிண்ணங்கள் ஊற்றப்பட்டன. கடல் இரத்தமாக மாறியது, இருள் சூழ்ந்தது, ராஜாக்கள் அர்மகெதோனில் கூடினர், பூமி அதிர்ந்தது. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. அப்பொழுது தேவாலயத்திலிருந்து உண்டான ஒரு பெருஞ்சத்தம் அந்த ஏழு தூதருடனே: நீங்கள் போய்த் தேவனுடைய கோபகலசங்களைப் பூமியின்மேல் ஊற்றுங்கள் என்று சொல்லக்கேட்டேன்.
	<br /><br />
	2. முதலாம் தூதன் போய், தன் கலசத்திலுள்ளதைப் பூமியின்மேல் ஊற்றினான்; உடனே மிருகத்தின் முத்திரையைத் தரித்தவர்களும் அதின் சொரூபத்தை வணங்குகிறவர்களுமாகிய மனுஷர்களுக்குப் பொல்லாத கொடிய புண்ணுண்டாயிற்று.
	<br /><br />
	3. இரண்டாம் தூதன் தன் கலசத்திலுள்ளதைச் சமுத்திரத்திலே ஊற்றினான்; உடனே அது செத்தவனுடைய இரத்தம் போலாயிற்று; சமுத்திரத்திலுள்ள பிராணிகள் யாவும் மாண்டுபோயின.
	<br /><br />
	4. மூன்றாம் தூதன் தன் கலசத்திலுள்ளதை ஆறுகளிலும், நீரூற்றுகளிலும் ஊற்றினான்; உடனே அவைகள் இரத்தமாயின.
	<br /><br />
	5. அப்பொழுது தண்ணீர்களின் தூதன்: இருக்கிறவரும் இருந்தவரும் பரிசுத்தருமாகிய தேவரீர் இப்படி நியாயந்தீர்க்க நீதியுள்ளவராயிருக்கிறீர்.
	<br /><br />
	6. அவர்கள் பரிசுத்தவான்களுடைய இரத்தத்தையும் தீர்க்கதரிசிகளுடைய இரத்தத்தையும் சிந்தினபடியினால், இரத்தத்தையே அவர்களுக்குக் குடிக்கக்கொடுத்தீர்; அதற்குப் பாத்திரராயிருக்கிறார்கள் என்று சொல்லக்கேட்டேன்.
	<br /><br />
	7. பலிபீடத்திலிருந்து வேறொருவன்: ஆம், சர்வவல்லமையுள்ள தேவனாகிய கர்த்தாவே, உம்முடைய நியாயத்தீர்ப்புகள் சத்தியமும் நீதியுமானவைகள் என்று சொல்லக்கேட்டேன்.
	<br /><br />
	8. நான்காம் தூதன் தன் கலசத்திலுள்ளதைச் சூரியன்மேல் ஊற்றினான்; தீயினால் மனுஷரைத் தகிக்கும்படி அதற்கு அதிகாரங் கொடுக்கப்பட்டது.
	<br /><br />
	9. அப்பொழுது மனுஷர்கள் மிகுந்த உஷ்ணத்தினாலே தகிக்கப்பட்டு, இந்த வாதைகளைச் செய்ய அதிகாரமுள்ள தேவனுடைய நாமத்தைத் தூஷித்தார்களேயல்லாமல், அவரை மகிமைப்படுத்த மனந்திரும்பவில்லை.
	<br /><br />
	10. ஐந்தாம் தூதன் தன் கலசத்திலுள்ளதை மிருகத்தினுடைய சிங்காசனத்தின்மேல் ஊற்றினான்; அப்பொழுது அதின் ராஜ்யம் இருளடைந்தது; அவர்கள் வருத்தத்தினாலே தங்கள் நாவுகளைக் கடித்துக்கொண்டு,
	<br /><br />
	11. தங்கள் வருத்தங்களாலும், தங்கள் புண்களாலும், பரலோகத்தின் தேவனைத் தூஷித்தார்களேயல்லாமல், தங்கள் கிரியைகளை விட்டு மனந்திரும்பவில்லை.
	<br /><br />
	12. ஆறாம் தூதன் தன் கலசத்திலுள்ளதை ஐபிராத் என்னும் பெரிய நதியின்மேல் ஊற்றினான்; அப்பொழுது சூரியன் உதிக்குந் திசையிலிருந்துவரும் ராஜாக்களுக்கு வழி ஆயத்தமாகும்படி அந்த நதியின் தண்ணீர் வற்றிப்போயிற்று.
	<br /><br />
	13. அப்பொழுது, வலுசர்ப்பத்தின் வாயிலும் மிருகத்தின் வாயிலும் கள்ளத்தீர்க்கதரிசியின் வாயிலுமிருந்து தவளைகளுக்கு ஒப்பான மூன்று அசுத்த ஆவிகள் புறப்பட்டுவரக் கண்டேன்.
	<br /><br />
	14. அவைகள் அற்புதங்களைச் செய்கிற பிசாசுகளின் ஆவிகள்; அவைகள் பூலோகமெங்குமுள்ள ராஜாக்களைச் சர்வவல்லமையுள்ள தேவனுடைய மகாநாளில் நடக்கும் யுத்தத்திற்குக் கூட்டிச்சேர்க்கும்படிக்குப் புறப்பட்டுப்போகிறது.
	<br /><br />
	15. இதோ, திருடனைப்போல் வருகிறேன். தன் மானம் காணப்படத்தக்கதாக நிர்வாணமாய் நடவாதபடிக்கு விழித்துக்கொண்டு, தன் வஸ்திரங்களைக் காத்துக்கொள்ளுகிறவன் பாக்கியவான்.
	<br /><br />
	16. அப்பொழுது எபிரெயு பாஷையிலே அர்மகெதோன் என்னப்பட்ட இடத்திலே அவர்களைக் கூட்டிச் சேர்த்தான்.
	<br /><br />
	17. ஏழாம் தூதன் தன் கலசத்திலுள்ளதை ஆகாயத்தில் ஊற்றினான்; அப்பொழுது பரலோகத்தின் ஆலயத்திலுள்ள சிங்காசனத்திலிருந்து: ஆயிற்று என்று சொல்லிய பெருஞ்சத்தம் பிறந்தது.
	<br /><br />
	18. சத்தங்களும் இடிமுழக்கங்களும் மின்னல்களும் உண்டாயின; பூமி மிகவும் அதிர்ந்தது, பூமியின்மேல் மனுஷர்கள் உண்டான நாள்முதற்கொண்டு அப்படிப்பட்ட பெரிய அதிர்ச்சியுண்டானதில்லை.
	<br /><br />
	19. அப்பொழுது மகா நகரம் மூன்று பங்காகப் பிரிக்கப்பட்டது, புறஜாதிகளுடைய பட்டணங்கள் விழுந்தன. மகா பாபிலோனுக்குத் தேவனுடைய உக்கிரமான கோபாக்கினையாகிய மதுவுள்ள பாத்திரத்தைக் கொடுக்கும்படி அது அவருக்கு முன்பாக நினைப்பூட்டப்பட்டது.
	<br /><br />
	20. தீவுகள் யாவும் அகன்றுபோயின; பர்வதங்கள் காணப்படாமற்போயின.
	<br /><br />
	21. தாலந்து நிறையான பெரிய கல்மழையும் வானத்திலிருந்து மனுஷர்மேல் விழுந்தது; அந்தக் கல்மழையினால் உண்டான வாதையினிமித்தம் மனுஷர்கள் தேவனைத் தூஷித்தார்கள்; அந்த வாதை மகா கொடிதாயிருந்தது.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h16>
*  *
			</h16>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation16