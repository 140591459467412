import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 6 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 6 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 6 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தேவனுடைய கிருபையை நீங்கள் விருதாவாய்ப் பெறாதபடிக்கு, உடன்வேலையாட்களாகிய நாங்கள் உங்களுக்குப் புத்திசொல்லுகிறோம்.
	<br /><br />
	2. அநுக்கிரக காலத்திலே நான் உனக்குச் செவிகொடுத்து, இரட்சணியநாளிலே உனக்கு உதவிசெய்தேன் என்று சொல்லியிருக்கிறாரே; இதோ, இப்பொழுதே அநுக்கிரககாலம், இப்பொழுதே இரட்சணியநாள்.
	<br /><br />
	3. இந்த ஊழியம் குற்றப்படாதபடிக்கு, நாங்கள் யாதொன்றிலும் இடறல் உண்டாக்காமல், எவ்விதத்தினாலேயும், எங்களைத் தேவஊழியக்காரராக விளங்கப்பண்ணுகிறோம்.
	<br /><br />
	4. மிகுந்த பொறுமையிலும், உபத்திரவங்களிலும், நெருக்கங்களிலும், இடுக்கண்களிலும்,
	<br /><br />
	5. அடிகளிலும், காவல்களிலும், கலகங்களிலும், பிரயாசங்களிலும், கண்விழிப்புகளிலும், உபவாசங்களிலும்,
	<br /><br />
	6. கற்பிலும், அறிவிலும், நீடிய சாந்தத்திலும், தயவிலும், பரிசுத்தஆவியிலும், மாயமற்ற அன்பிலும்,
	<br /><br />
	7. சத்தியவசனத்திலும், திவ்விய பலத்திலும்; நீதியாகிய வலதிடதுபக்கத்து ஆயுதங்களைத் தரித்திருக்கிறதிலும்,
	<br /><br />
	8. கனத்திலும், கனவீனத்திலும், துர்க்கீர்த்தியிலும், நற்கீர்த்தியிலும்; எத்தரென்னப்பட்டாலும் நிஜஸ்தராகவும்,
	<br /><br />
	9. அறியப்படாதவர்களென்னப்பட்டாலும் நன்றாய் அறியப்பட்டவர்களாகவும், சாகிறவர்கள் என்னப்பட்டாலும் உயிரோடிருக்கிறவர்களாகவும், தண்டிக்கப்படுகிறவர்கள் என்னப்பட்டாலும் கொல்லப்படாதவர்களாகவும்,
	<br /><br />
	10. துக்கப்படுகிறவர்கள் என்னப்பட்டாலும் எப்பொழுதும் சந்தோஷப்படுகிறவர்களாகவும், தரித்திரர் என்னப்பட்டாலும் அநேகரை ஐசுவரியவான்களாக்குகிறவர்களாகவும், ஒன்றுமில்லாதவர்களென்னப்பட்டாலும் சகலத்தையுமுடையவர்களாகவும் எங்களை விளங்கப்பண்ணுகிறோம்.
	<br /><br />
	11. கொரிந்தியரே, எங்கள் வாய் உங்களோடே பேசத் திறந்திருக்கிறது, எங்கள் இருதயம் பூரித்திருக்கிறது.
	<br /><br />
	12. எங்கள் உள்ளம் உங்களைக்குறித்து நெருக்கமடையவில்லை, உங்கள் உள்ளமே எங்களைக்குறித்து நெருக்கமடைந்திருக்கிறது.
	<br /><br />
	13. ஆதலால் அதற்குப் பதிலீடாக நீங்களும் பூரிப்பாகுங்களென்று, பிள்ளைகளுக்குச் சொல்லுகிறதுபோல, உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	14. அந்நிய நுகத்திலே அவிசுவாசிகளுடன் பிணைக்கப்படாதிருப்பீர்களாக; நீதிக்கும் அநீதிக்கும் சம்பந்தமேது? ஒளிக்கும் இருளுக்கும் ஐக்கியமேது?
	<br /><br />
	15. கிறிஸ்துவுக்கும் பேலியாளுக்கும் இசைவேது? அவிசுவாசியுடனே விசுவாசிக்குப் பங்கேது?
	<br /><br />
	16. தேவனுடைய ஆலயத்துக்கும் விக்கிரகங்களுக்கும் சம்பந்தமேது? நான் அவர்களுக்குள்ளே வாசம்பண்ணி, அவர்களுக்குள்ளே உலாவி, அவர்கள் தேவனாயிருப்பேன், அவர்கள் என் ஜனங்களாயிருப்பார்கள் என்று, தேவன் சொன்னபடி, நீங்கள் ஜீவனுள்ள தேவனுடைய ஆலயமாயிருக்கிறீர்களே.
	<br /><br />
	17. ஆனபடியால், நீங்கள் அவர்கள் நடுவிலிருந்து புறப்பட்டுப் பிரிந்துபோய், அசுத்தமானதைத் தொடாதிருங்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. அப்பொழுது, நான் உங்களை ஏற்றுக்கொண்டு, உங்களுக்குப் பிதாவாயிருப்பேன், நீங்கள் எனக்குக் குமாரரும் குமாரத்திகளுமாயிருப்பீர்களென்று சர்வவல்லமையுள்ள கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians6