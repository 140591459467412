import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 19 </title>
	<meta
          name="description"
          content="The Book of Revelation - 19 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 19 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* அல்லேலூயா! கர்த்தர் ஆட்சி செய்கிறார்" என்று திரளான மக்கள் கூப்பிட்டனர். தேவனுடைய வார்த்தை பரலோகப் படைகளை வழிநடத்தியது. மிருகம் நெருப்பு ஏரியில் தள்ளப்பட்டது. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. இவைகளுக்குப்பின்பு, பரலோகத்தில் திரளான ஜனக்கூட்டம் இடுகிற ஆரவாரத்தைக் கேட்டேன். அவர்கள்: அல்லேலூயா, இரட்சணியமும் மகிமையும் கனமும் வல்லமையும் நம்முடைய தேவனாகிய கர்த்தருக்கே உரியது; அவருடைய நியாயத்தீர்ப்புகள் சத்தியமும் நீதியுமானவைகள்.
	<br /><br />
	2. தன் வேசித்தனத்தினால் பூமியைக் கெடுத்த மகா வேசிக்கு அவர் நியாயத்தீர்ப்புக்கொடுத்து, தம்முடைய ஊழியக்காரரின் இரத்தத்திற்காக அவளிடத்தில் பழிவாங்கினாரே என்றார்கள்.
	<br /><br />
	3. மறுபடியும் அவர்கள்: அல்லேலூயா என்று சொல்லி ஆர்ப்பரித்தார்கள். அவளுடைய புகை என்றென்றைக்கும் எழும்புகிறது என்றார்கள்.
	<br /><br />
	4. இருபத்துநான்கு மூப்பர்களும், நான்கு ஜீவன்களும் வணக்கமாய் விழுந்து: ஆமென், அல்லேலூயா, என்று சொல்லி, சிங்காசனத்தின்மேல் வீற்றிருக்கும் தேவனைத் தொழுதுகொண்டார்கள்.
	<br /><br />
	5. மேலும், நமது தேவனுடைய ஊழியக்காரரே, அவருக்குப் பயப்படுகிற சிறியோரும் பெரியோருமானவர்களே, நீங்கள் யாவரும் அவரைத் துதியுங்கள் என்று ஒரு சத்தம் சிங்காசனத்திலிருந்து பிறந்தது.
	<br /><br />
	6. அப்பொழுது திரளான ஜனங்கள் இடும் ஆரவாரம்போலவும், பெருவெள்ள இரைச்சல்போலவும், பலத்த இடிமுழக்கம்போலவும், ஒரு சத்தமுண்டாகி: அல்லேலூயா, சர்வவல்லமையுள்ள தேவனாகிய கர்த்தர் ராஜ்யபாரம்பண்ணுகிறார்.
	<br /><br />
	7. நாம் சந்தோஷப்பட்டுக் களிகூர்ந்து அவருக்குத் துதிசெலுத்தக்கடவோம். ஆட்டுக்குட்டியானவருடைய கலியாணம் வந்தது, அவருடைய மனைவி தன்னை ஆயத்தம்பண்ணினாள் என்று சொல்லக் கேட்டேன்.
	<br /><br />
	8. சுத்தமும் பிரகாசமுமான மெல்லிய வஸ்திரம் தரித்துக்கொள்ளும்படி அவளுக்கு அளிக்கப்பட்டது; அந்த மெல்லிய வஸ்திரம் பரிசுத்தவான்களுடைய நீதிகளே.
	<br /><br />
	9. பின்னும், அவன் என்னை நோக்கி: ஆட்டுக்குட்டியானவரின் கலியாண விருந்துக்கு அழைக்கப்பட்டவர்கள் பாக்கியவான்கள் என்றெழுது என்றான். மேலும், இவைகள் தேவனுடைய சத்தியமான வசனங்கள் என்று என்னுடனே சொன்னான்.
	<br /><br />
	10. அப்பொழுது அவனை வணங்கும்படி அவனுடைய பாதத்தில் விழுந்தேன். அவன் என்னை நோக்கி: இப்படிச் செய்யாதபடிக்குப் பார்; உன்னோடும் இயேசுவைக்குறித்துச் சாட்சியிட்ட உன் சகோதரரோடுங்கூட நானும் ஒரு ஊழியக்காரன்; தேவனைத் தொழுதுகொள். இயேசுவைப்பற்றின சாட்சி தீர்க்கதரிசனத்தின் ஆவியாயிருக்கிறது என்றான்.
	<br /><br />
	11. பின்பு, பரலோகம் திறந்திருக்கக் கண்டேன்; இதோ, ஒரு வெள்ளைக்குதிரை காணப்பட்டது, அதின்மேல் ஏறியிருந்தவர் உண்மையும் சத்தியமுமுள்ளவரென்னப்பட்டவர்; அவர் நீதியாய் நியாயந்தீர்த்து யுத்தம்பண்ணுகிறார்.
	<br /><br />
	12. அவருடைய கண்கள் அக்கினிஜுவாலையைப்போலிருந்தன, அவருடைய சிரசின்மேல் அநேக கிரீடங்கள் இருந்தன; அவருக்கேயன்றி வேறொருவருக்குந் தெரியாத ஒரு நாமமும் எழுதியிருந்தது.
	<br /><br />
	13. இரத்தத்தில் தோய்க்கப்பட்ட வஸ்திரத்தைத் தரித்திருந்தார்; அவருடைய நாமம் தேவனுடைய வார்த்தை என்பதே.
	<br /><br />
	14. பரலோகத்திலுள்ள சேனைகள் வெண்மையும் சுத்தமுமான மெல்லிய வஸ்திரந் தரித்தவர்களாய், வெள்ளைக்குதிரைகளின்மேல் ஏறி, அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	15. புறஜாதிகளை வெட்டும்படிக்கு அவருடைய வாயிலிருந்து கூர்மையான பட்டயம் புறப்படுகிறது; இருப்புக்கோலால் அவர்களை அரசாளுவார்; அவர் சர்வவல்லமையுள்ள தேவனுடைய உக்கிரகோபமாகிய மதுவுள்ள ஆலையை மிதிக்கிறார்.
	<br /><br />
	16. ராஜாதி ராஜா, கர்த்தாதி கர்த்தா என்னும் நாமம் அவருடைய வஸ்திரத்தின்மேலும் அவருடைய தொடையின்மேலும் எழுதப்பட்டிருந்தது.
	<br /><br />
	17. பின்பு ஒரு தூதன் சூரியனில் நிற்கக்கண்டேன்; அவன் வானத்தின் மத்தியில் பறக்கிற சகல பறவைகளையும் பார்த்து:
	<br /><br />
	18. நீங்கள் ராஜாக்களின் மாம்சத்தையும், சேனைத்தலைவர்களின் மாம்சத்தையும், பலவான்களின் மாம்சத்தையும், குதிரைகளின் மாம்சத்தையும், அவைகளின்மேல் ஏறியிருக்கிறவர்களின் மாம்சத்தையும், சுயாதீனர் அடிமைகள், சிறியோர் பெரியோர், இவர்களெல்லாருடைய மாம்சத்தையும் பட்சிக்கும்படிக்கு, மகா தேவன் கொடுக்கும் விருந்துக்குக் கூடிவாருங்கள் என்று மிகுந்த சத்தத்தோடே கூப்பிட்டான்.
	<br /><br />
	19. பின்பு, மிருகமும் பூமியின் ராஜாக்களும் அவர்களுடைய சேனைகளும், குதிரையின்மேல் ஏறியிருக்கிறவரோடும் அவருடைய சேனையோடும் யுத்தம்பண்ணும்படிக்குக் கூடிவரக்கண்டேன்.
	<br /><br />
	20. அப்பொழுது மிருகம் பிடிக்கப்பட்டது; மிருகத்தின்முன்பாகச் செய்த அற்புதங்களால் அதின் முத்திரையைத் தரித்தவர்களையும் அதின் சொரூபத்தை வணங்கினவர்களையும் மோசம்போக்கின கள்ளத்தீர்க்கதரிசியுங்கூடப் பிடிக்கப்பட்டான்; இருவரும் கந்தகம் எரிகிற அக்கினிக்கடலிலே உயிரோடே தள்ளப்பட்டார்கள்.
	<br /><br />
	21. மற்றவர்கள் குதிரையின்மேல் ஏறினவருடைய வாயிலிருந்து புறப்படுகிற பட்டயத்தால் கொல்லப்பட்டார்கள்; அவர்களுடைய மாம்சத்தினால் பறவைகள் யாவும் திருப்தியடைந்தன.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h19>
*  *
			</h19>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation19