import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial25 = () => {
  const verseNumber = 25;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 25 </title>
	<meta
          name="description"
          content="Ezekiel 25 | எசேக்கியேல்  25 |
          Ezekiel 25 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ அம்மோன் புத்திரருக்கு எதிராக உன் முகத்தைத்திருப்பி, அவர்களுக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து,
	<br /><br />
	3. அம்மோன் புத்திரருக்குச் சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவருடைய வார்த்தையைக் கேளுங்கள்; கர்த்தராகிய ஆண்டவர் உரைக்கிறார்: என் பரிசுத்த ஸ்தலம் பரிசுத்தக்குலைச்சலாக்கப்படுகிறபோதும், இஸ்ரவேல் தேசம் பாழாக்கப்படுகிறபோதும், யூதா வம்சத்தார் சிறையிருப்பிலே போகிறபோதும், நீ அவர்களுக்கு விரோதமாக ஆ ஆ, என்று நிந்தித்தபடியினால்,
	<br /><br />
	4. இதோ, நான் உன்னைக் கிழக்குத் தேசத்தாருக்குச் சுதந்தரமாக ஒப்புக்கொடுப்பேன், அவர்கள் உன்னில் தங்கள் அரண்களைக் கட்டி, உன்னில் தங்கள் வாசஸ்தலங்களை உண்டுபண்ணுவார்கள்; அவர்கள் உன் கனிகளைப் புசித்து, உன் பாலைக் குடிப்பார்கள்.
	<br /><br />
	5. நான் ரப்பாவை ஒட்டகங்களின் கொட்டகையும், அம்மோன் புத்திரரின் தேசத்தை ஆட்டுக்கிடையுமாக்குவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	6. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இஸ்ரவேல் தேசத்துக்கு விரோதமாக நீ கைகொட்டி, உன் காலால் தட்டி, வர்மம் வைத்து, ஆகடியம் பண்ணினபடியினால்,
	<br /><br />
	7. இதோ, உனக்கு விரோதமாக நான் என் கையை நீட்டி, உன்னை ஜாதிகளுக்குக் கொள்ளையாக ஒப்புக்கொடுத்து, உன்னை ஜனங்களுக்குள்ளே வேரற்றுப்போகப்பண்ணி, உன்னைத் தேசங்களுக்குள்ளே அழித்து, உன்னை நிர்மூலமாக்குவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வாய்.
	<br /><br />
	8. கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: இதோ, யூதா வம்சத்தார் எல்லா ஜாதிகளுக்கும் ஒத்தவர்களென்று மோவாபும் சேயீரும் சொல்லுகிறபடியினால்,
	<br /><br />
	9. இதோ, அம்மோன் புத்திரரின்பேர் ஜாதிகளுக்குள் இராதபடிக்கு நான் அம்மோன் புத்திரரின் தேசத்தைக் கிழக்குத் தேசத்தாருக்குத் திறந்துவைத்து, சுதந்தரமாய் ஒப்புக்கொடுக்கிறவண்ணமாக,
	<br /><br />
	10. நான் மோவாப் தேசத்தின் பக்கத்திலுள்ள அதின் கடையாந்தர ஊர்களாகிய பட்டணங்கள் முதற்கொண்டுள்ள தேசத்தின் அலங்காரமாகிய பெத்யெசிமோத்தையும், பாகால்மெயோனையும், கீரியாத்தாயீமையும் அவர்களுக்குத் திறந்துவைத்து,
	<br /><br />
	11. மோவாபிலே நியாயங்களைச் செய்வேன், அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	12. கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: ஏதோம் யூதா வம்சத்தாரிடத்தில் குரோதந்தீர்த்து, பழிவாங்கி, பெரிய குற்றஞ்செய்தபடியினால்,
	<br /><br />
	13. கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்: நான் ஏதோம் தேசத்துக்கு விரோதமாக என் கையை நீட்டி அதில் மனுஷரையும் மிருகங்களையும் இராதபடிக்குச் சங்காரம்பண்ணி, அதைத் தேமான் துவக்கித் தேதான்மட்டும் வனாந்தரமாக்குவேன்; பட்டயத்தால் விழுவார்கள்.
	<br /><br />
	14. நான் இஸ்ரவேலாகிய என் ஜனத்தின் கையினால் ஏதோமினிடத்தில் பழிவாங்குவேன்; அவர்கள் என் கோபத்தின்படியும் என் உக்கிரத்தின்படியும் ஏதோமுக்குச் செய்வார்கள்; அப்பொழுது நான் பழிவாங்குவது இன்னதென்று அறிந்துகொள்வார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால்: பெலிஸ்தியர் குரோதக்காரராயிருந்து, பழம்பகையால் கேடுசெய்யவேண்டுமென்று, வர்மம் வைத்துப் பழிவாங்கினபடியினால்,
	<br /><br />
	16. இதோ, நான் பெலிஸ்தியருக்கு விரோதமாக என் கையை நீட்டி, கிரேத்தியரைச் சங்கரித்து, சமுத்திரக்கரையில் மீதியானவர்களை அழித்து,
	<br /><br />
	17. உக்கிரமான தண்டனைகளினால் அவர்களில் கொடிதாய்ப் பழிவாங்குவேன்; நான் அவர்களில் பழிவாங்கும்போது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial25