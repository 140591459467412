import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 4 </title>
	<meta
          name="description"
          content="Zechariah 4 | சகரியா 4 | Sageria 4 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. என்னோடே பேசின தூதன் திரும்பிவந்து நித்திரைபண்ணுகிற ஒருவனை எழுப்புவதுபோல் என்னை எழுப்பி:
	<br /><br />
	2. நீ காண்கிறது என்னவென்று கேட்டார்; அதற்கு நான்: இதோ, முழுவதும் பொன்னினால் செய்யப்பட்ட குத்துவிளக்கைக் காண்கிறேன்; அதின் உச்சியில் அதின் கிண்ணமும், அதின்மேல் அதின் ஏழு அகல்களும், அதின் உச்சியில் இருக்கிற அகல்களுக்குப்போகிற ஏழு குழாய்களும் இருக்கிறது.
	<br /><br />
	3. அதின் அருகில் கிண்ணத்திற்கு வலதுபுறமாக ஒன்றும், அதற்கு இடதுபுறமாக ஒன்றும், ஆக இரண்டு ஒலிவமரங்கள் இருக்கிறது என்றேன்.
	<br /><br />
	4. நான் என்னோடே பேசின தூதனை நோக்கி: ஆண்டவனே, இவைகள் என்னவென்று கேட்டேன்.
	<br /><br />
	5. என்னோடே பேசின தூதன் மறுமொழியாக: இவைகள் இன்னதென்று உனக்குத் தெரியாதா என்றார்; ஆண்டவனே, எனக்குத் தெரியாது என்றேன்.
	<br /><br />
	6. அப்பொழுது அவர்: செருபாபேலுக்குச் சொல்லப்படுகிற கர்த்தருடைய வார்த்தை என்னவென்றால், பலத்தினாலும் அல்ல, பராக்கிரமத்தினாலும் அல்ல, என்னுடைய ஆவியினாலேயே ஆகும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. பெரிய பர்வதமே, நீ எம்மாத்திரம்? செருபாபேலுக்கு முன்பாக நீ சமபூமியாவாய்; தலைக்கல்லை அவன் கொண்டுவருவான்; அதற்குக் கிருபையுண்டாவதாக, கிருபையுண்டாவதாக என்று ஆர்ப்பரிப்பார்கள் என்றார்.
	<br /><br />
	8. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	9. செருபாபேலின் கைகள் இந்த ஆலயத்துக்கு அஸ்திபாரம் போட்டது; அவன் கைகளே இதை முடித்துத் தீர்க்கும்; அதினால் சேனைகளின் கர்த்தர் என்னை உங்களிடத்திற்கு அனுப்பினாரென்று அறிவாய்.
	<br /><br />
	10. அற்பமான ஆரம்பத்தின் நாளை யார் அசட்டைபண்ணலாம்? பூமியெங்கும் சுற்றிப்பார்க்கிறவைகளாகிய கர்த்தருடைய ஏழு கண்களும் செருபாபேலின் கையில் இருக்கிற தூக்குநூலைச் சந்தோஷமாய்ப் பார்க்கிறது என்றார்.
	<br /><br />
	11. பின்பு நான் அவரை நோக்கி: குத்துவிளக்குக்கு வலதுபுறமாகவும் அதற்கு இடதுபுறமாகவும் இருக்கிற இந்த இரண்டு ஒலிவமரங்கள் என்னவென்று கேட்டேன்.
	<br /><br />
	12. மறுபடியும் நான் அவரை நோக்கி: இரண்டு பொற்குழாய்களின் வழியாய்த் தொங்கி, பொன்னிறமான எண்ணெயைத் தங்களிலிருந்து இறங்கப்பண்ணுகிறவைகளாகிய ஒலிவமரங்களின் இரண்டு கிளைகள் என்னவென்று கேட்டேன்.
	<br /><br />
	13. அதற்கு அவர்: இவைகள் இன்னதென்று உனக்குத் தெரியாதா என்றார்; ஆண்டவனே, எனக்குத் தெரியாது என்றேன்.
	<br /><br />
	14. அப்பொழுது அவர்: இவைகள் இரண்டும் சர்வலோகத்துக்கும் ஆண்டவராயிருக்கிறவரின் சமுகத்தில் நிற்கிற அபிஷேகம் பெற்றவர்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah4