import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah51 = () => {
  const verseNumber = 51;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 51 </title>
	<meta
          name="description"
          content="Jeremiah 51 | எரேமியா 51 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் பாபிலோனுக்கு விரோதமாகவும், எனக்கு விரோதமாய் எழும்புகிறவர்களின் மத்தியில் குடியிருக்கிறவர்களுக்கு விரோதமாகவும் அழிக்கும் காற்றை எழும்பப்பண்ணி,
	<br /><br />
	2. தூற்றுவாரைப் பாபிலோனுக்கு அனுப்புவேன்; அவர்கள் அதைத்தூற்றி, வெறுமையாக்கிப்போடுவார்கள்; ஆபத்துநாளிலே அதற்கு விரோதமாய்ச் சூழ்ந்துகொண்டிருப்பார்கள்.
	<br /><br />
	3. வில்லை நாணேற்றுகிறவனுக்கு விரோதமாகவும், தன் கவசத்தில் பெருமைபாராட்டுகிறவனுக்கு விரோதமாகவும், வில்வீரன் தன் வில்லை நாணேற்றக்கடவன்; அதின் வாலிபரைத் தப்பவிடாமல் அதின் சேனையை எல்லாம் சங்காரம்பண்ணுங்கள்.
	<br /><br />
	4. குத்திப்போடப்பட்டவர்கள் கல்தேயரின் தேசத்திலும், கொலைசெய்யப்பட்டவர்கள் அதின் வீதிகளிலும் விழுவார்கள்.
	<br /><br />
	5. அவர்கள் தேசம் இஸ்ரவேலின் பரிசுத்தருக்கு விரோதமாகச் செய்த அக்கிரமத்தினால் நிறைந்திருந்தும், யூதா தன் தேவனாலும் இஸ்ரவேல் சேனைகளின் கர்த்தராலும் கைவிடப்படவில்லை.
	<br /><br />
	6. நீங்கள் பாபிலோனின் அக்கிரமத்தில் சங்காரமாகாதபடிக்கு அதின் நடுவிலிருந்து ஓடி, அவரவர் தங்கள் ஆத்துமாவைத் தப்புவியுங்கள்; இது கர்த்தர் அதினிடத்தில் பழிவாங்குகிற காலமாயிருக்கிறது; அவர் அதற்குப் பதில் செலுத்துவார்.
	<br /><br />
	7. பாபிலோன் கர்த்தருடைய கையிலுள்ள பொற்பாத்திரம்; அது பூமி அனைத்தையும் வெறிக்கப்பண்ணினது; அதின் மதுவை ஜாதிகள் குடித்தார்கள்; ஆகையால் ஜாதிகள் புத்திமயங்கிப்போனார்கள்.
	<br /><br />
	8. பாபிலோன் சடிதியில் விழுந்து தகர்ந்தது; அதற்காக அலறுங்கள்; அதின் நோவை ஆற்றப் பிசின் தைலம் போடுங்கள்; ஒருவேளை குணமாகும்.
	<br /><br />
	9. பாபிலோனைக் குணமாக்கும்படிப் பார்த்தோம், அது குணமாகவில்லை; அதை விட்டுவிடுங்கள்; நாம் அவரவர் நம்முடைய தேசங்களுக்குப் போகக்கடவோம்; அதின் ஆக்கினை வானமட்டும் ஏறி ஆகாயமண்டலங்கள் பரியந்தம் எட்டினது.
	<br /><br />
	10. கர்த்தர் நம்முடைய நீதியை வெளிப்படுத்தினார்; நம்முடைய தேவனாகிய கர்த்தரின் செயலைச் சீயோனில் விவரிப்போம் வாருங்கள்.
	<br /><br />
	11. அம்புகளைத் துலக்குங்கள்; கேடகங்களை நன்றாய்ச் செப்பனிடுங்கள்; கர்த்தர் மேதியருடைய ராஜாக்களின் ஆவியை எழுப்பினார்; பாபிலோனை அழிக்கவேண்டுமென்பதே அவருடைய நினைவு; இது கர்த்தர் வாங்கும் பழி, இது தமது ஆலயத்துக்காக அவர் வாங்கும் பழி.
	<br /><br />
	12. பாபிலோனின் மதில்கள்மேல் கொடியேற்றுங்கள், காவலைப் பலப்படுத்துங்கள், ஜாமங் காக்கிறவர்களை நிறுத்துங்கள், பதிவிருப்பாரை வையுங்கள்; ஆனாலும் கர்த்தர் எப்படி நினைத்தாரோ அப்படியே தாம் பாபிலோனின் குடிகளுக்கு விரோதமாகச் சொன்னதைச் செய்வார்.
	<br /><br />
	13. திரளான தண்ணீர்களின்மேல் வாசம்பண்ணுகிறவளே, திரண்ட சம்பத்துடையவளே, உனக்கு முடிவும் உன் பொருளாசைக்கு ஒழிவும் வந்தது.
	<br /><br />
	14. மெய்யாகவே, பச்சைக்கிளிகளைப்போல் திரளான மனுஷரால் உன்னை நிரம்பப்பண்ணுவேன்; அவர்கள் உன்மேல் ஆரவாரம்பண்ணுவார்கள் என்று சேனைகளின் கர்த்தர் தம்முடைய ஜீவனைக்கொண்டு ஆணையிட்டார்.
	<br /><br />
	15. அவர் பூமியைத் தமது வல்லமையினால் உண்டாக்கி, பூச்சக்கரத்தைத் தமது ஞானத்தினால் படைத்து, வானத்தைத் தமது பேரறிவினால் விரித்தார்.
	<br /><br />
	16. அவர் சத்தமிடுகையில் திரளான தண்ணீர் வானத்தில் உண்டாகிறது; அவர் பூமியின் எல்லைகளிலிருந்து மேகங்களை எழும்பப்பண்ணி, மழையுடனே மின்னல்களை உண்டாக்கி, காற்றைத் தமது பண்டசாலையிலிருந்து ஏவிவிடுகிறார்.
	<br /><br />
	17. மனுஷர் அனைவரும் அறிவில்லாமல் மிருக குணமானார்கள்; தட்டார் அனைவரும் சுரூபங்களாலே வெட்கிப்போகிறார்கள்; அவர்கள் வார்ப்பித்த விக்கிரகம் பொய்யே, அவைகளில் சுவாசம் இல்லை.
	<br /><br />
	18. அவைகள் மாயையும் மகா எத்துமான கிரியையாயிருக்கிறது; அவைகள் விசாரிக்கப்படும் நாளிலே அழியும்.
	<br /><br />
	19. யாக்கோபின் பங்காயிருக்கிறவர் அவைகளைப்போல அல்ல, அவர் சர்வத்தையும் உண்டாக்கினவர்; இஸ்ரவேல் அவருடைய சுதந்தரமான கோத்திரம்; சேனைகளின் கர்த்தர் என்பது அவருடைய நாமம்.
	<br /><br />
	20. நீ எனக்குத் தண்டாயுதமும் அஸ்திராயுதமுமானவன்; நான் உன்னைக்கொண்டு ஜாதிகளை நொறுக்குவேன்; உன்னைக்கொண்டு ராஜ்யங்களை அழிப்பேன்.
	<br /><br />
	21. உன்னைக்கொண்டு குதிரையையும், குதிரை வீரனையும் நொறுக்குவேன்; உன்னைக்கொண்டு இரதத்தையும் இரதவீரனையும் நொறுக்குவேன்.
	<br /><br />
	22. உன்னைக்கொண்டு புருஷனையும் ஸ்திரீயையும் நொறுக்குவேன்; உன்னைக்கொண்டு கிழவனையும் இளைஞனையும் நொறுக்குவேன்; உன்னைக்கொண்டு வாலிபனையும் கன்னிகையையும் நொறுக்குவேன்.
	<br /><br />
	23. உன்னைக்கொண்டு மேய்ப்பனையும் அவனுடைய மந்தையையும் நொறுக்குவேன்; உன்னைக்கொண்டு உழவனையும் அவனுடைய உழவுகாளைகளையும் நொறுக்குவேன்; உன்னைக்கொண்டு அதிபதிகளையும் அதிகாரிகளையும் நொறுக்குவேன்.
	<br /><br />
	24. பாபிலோனுக்கும் கல்தேயர் தேசத்தின் சகல குடிகளுக்கும், அவர்கள் உங்கள் கண்களுக்கு முன்பாகச் சீயோனில் செய்த அவர்களுடைய எல்லாப் பொல்லாப்புக்காகவும் பழிவாங்குவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	25. இதோ, பூமியை எல்லாம் கெடுக்கிற கேடான பர்வதமே, நான் உனக்கு விரோதமாக வந்து, என் கையை உனக்கு விரோதமாக நீட்டி, உன்னைக் கன்மலைகளிலிருந்து உருட்டி, உன்னை எரிந்துபோன பர்வதமாக்கிப்போடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	26. மூலைக்கல்லுக்காகிலும் அஸ்திபாரக்கல்லுக்காகிலும் ஒரு கல்லையும் உன்னிலிருந்து எடுக்கமாட்டார்கள்; நீ என்றென்றைக்கும் பாழாய்க்கிடக்கிற ஸ்தலமாவாய் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	27. தேசத்திலே கொடியேற்றுங்கள்; ஜாதிகளுக்குள் எக்காளம் ஊதுங்கள்; ஜாதிகளை அதற்கு விரோதமாக ஆயத்தப்படுத்துங்கள்; ஆரராத், மின்னி, அஸ்கெனாஸ் என்னும் ராஜ்யங்களை அதற்கு விரோதமாக வரக்கூப்பிடுங்கள்; அதற்கு விரோதமாகத் தளகர்த்தனுக்குப் பட்டங்கட்டுங்கள்; சுணையுள்ள வெட்டுக்கிளிகள்போன்ற குதிரைகளை வரப்பண்ணுங்கள்.
	<br /><br />
	28. மேதியாதேசத்தின் ராஜாக்களும் அதின் தலைவரும் அதின் சகல அதிகாரிகளும் அவரவருடைய ராஜ்யபாரத்துக்குக் கீழான சகல தேசத்தாருமாகிய ஜாதிகளை அதற்கு விரோதமாக ஆயத்தப்படுத்துங்கள்.
	<br /><br />
	29. அப்பொழுது தேசம் அதிர்ந்து வேதனைப்படும்; பாபிலோன் தேசத்தைக் குடியில்லாதபடிப் பாழாக்க, பாபிலோனுக்கு விரோதமாய்க் கர்த்தர் நினைத்தவைகள் நிலைக்கும்.
	<br /><br />
	30. பாபிலோனின் பராக்கிரமசாலிகள் யுத்தம்பண்ணாமல், கோட்டைகளில் இருந்துவிட்டார்கள்; அவர்கள் பராக்கிரமம் அழிந்து பேடிகளானார்கள்; அதின் வாசஸ்தலங்களைக் கொளுத்திப்போட்டார்கள்; அதின் தாழ்ப்பாள்கள் உடைக்கப்பட்டது.
	<br /><br />
	31. கடையாந்தர முனைதுவக்கி அவனுடைய பட்டணம் பிடிபட்டது என்றும், துறைவழிகள் அகப்பட்டுப்போய், நாணல்கள் அக்கினியால் சுட்டெரிக்கப்பட்டது என்றும், யுத்த மனுஷர் கலங்கியிருக்கிறார்கள் என்றும் பாபிலோன் ராஜாவுக்கு அறிவிக்க,
	<br /><br />
	32. அஞ்சற்காரன்மேல் அஞ்சற்காரனும் தூதன்மேல் தூதனும் ஓடுகிறான்.
	<br /><br />
	33. பாபிலோன் குமாரத்தி மிதிக்கப்படுங் களத்துக்குச் சமானம்; அதைப் போரடிக்குங் காலம் வந்தது; இன்னும் கொஞ்சக்காலத்திலே அறுப்புக்காலம் அதற்கு வரும் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	34. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் என்னைப் பட்சித்தான், என்னைக் கலங்கடித்தான், என்னை வெறும் பாத்திரமாக வைத்துப்போனான்; வலுசர்ப்பம்போல என்னை விழுங்கி, என் சுவையுள்ள பதார்த்தங்களால் தன் வயிற்றை நிரப்பினான், என்னைத் துரத்திவிட்டான்.
	<br /><br />
	35. எனக்கும் என் இனத்தாருக்கும் செய்த கொடுமையின் பழி பாபிலோன்மேல் வரக்கடவதென்று சீயோனில் வாசமானவள் சொல்லுகிறாள்; என் இரத்தப்பழி கல்தேயர் தேசத்துக் குடிகளின்மேல் வரக்கடவதென்று எருசலேம் என்பவளும் சொல்லுகிறாள்.
	<br /><br />
	36. ஆகையால் கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் உனக்காக வழக்காடி, உன் பழிக்குப் பழிவாங்கி, அதின் கடலை வறளவும் அதின் ஊற்றைச் சுவறவும்பண்ணுவேன்.
	<br /><br />
	37. அப்பொழுது பாபிலோன் குடியில்லாத மண்மேடுகளும், வலுசர்ப்பங்களின் தாபரமும், பாழும், ஈசல்போடப்படுதலுக்கு இடமுமாய்ப்போகும்.
	<br /><br />
	38. ஏகமாய் அவர்கள் சிங்கங்களைப்போலக் கெர்ச்சித்து, சிங்கக்குட்டிகளைப்போலச் சத்தமிடுவார்கள்.
	<br /><br />
	39. அவர்கள் களிக்கும் சமயத்திலே நான் அவர்கள் குடிக்கும் பானத்தை அவர்களுக்குக் குடிக்கக்கொடுத்து, அவர்கள் துள்ளத்தக்கதாக அவர்களை வெறியாக்குவேன்; அதினால் அவர்கள் என்றென்றைக்கும் விழிக்காத நித்திரை அடைவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	40. அவர்களை ஆட்டுக்குட்டிகளைப்போலவும், ஆட்டுக்கடாக்களைப்போலவும், வெள்ளாட்டுக்கடாக்களைப்போலவும் அடிக்கப்பட இறங்கிப்போகப் பண்ணுவேன்.
</p>
<p>
	41. சேசாக்கு பிடியுண்டு, பூமிமுழுதும் புகழும் புகழ்ச்சி அகப்பட்டது எப்படி? ஜாதிகளுக்குள்ளே பாபிலோன் பிரமிப்பானது எப்படி?
	<br /><br />
	42. சமுத்திரம் பாபிலோன்மேல் புரண்டுவந்தது; அதின் அலைகளின் திரட்சியினால் அது மூடப்பட்டது.
	<br /><br />
	43. அதின் பட்டணங்கள் பாழுமாய், வறட்சியும் வனாந்தரமுமான பூமியுமாய், ஒரு மனுஷனும் குடியிராததும் ஒரு மனுபுத்திரனும் கடவாததுமான நிலமுமாய்ப்போயிற்று.
	<br /><br />
	44. நான் பாபிலோனில் இருக்கிற பேலைத் தண்டிப்பேன்; அது விழுங்கினதை அதின் வாயிலிருந்து கக்கப்பண்ணுவேன்; ஜாதிகள் இனி அதினிடத்திற்கு ஓடிவரமாட்டார்கள், பாபிலோனின் மதிலும் விழும்.
	<br /><br />
	45. என் ஜனங்களே, நீங்கள் அதின் நடுவிலிருந்து புறப்படுங்கள்; கர்த்தருடைய கோபத்தின் உக்கிரத்துக்குத் தப்பும்படி அவனவன் தன்தன் ஆத்துமாவை இரட்சித்துக்கொள்ளக்கடவன்.
	<br /><br />
	46. உங்கள் இருதயம் துவளாமலும், தேசத்தில் கேட்கப்படும் செய்தியினால் நீங்கள் பயப்படாமலும் இருங்கள்; ஒரு வருஷத்திலே ஒரு செய்தி கேட்கப்பட்டு, பின்பு மறுவருஷத்திலே வேறு செய்தி கேட்கப்படும்; தேசத்திலே கொடுமை உண்டாகும்; ஆளுகிறவன்மேல் ஆளுகிறவன் வருவான்.
	<br /><br />
	47. ஆகையால், இதோ, நான் பாபிலோனின் விக்கிரகங்களைத் தண்டிக்கும் நாட்கள் வரும், அப்பொழுது அதின் தேசம் எல்லாம் கலங்கும்; அதில் கொலையுண்கிற யாவரும் அதின் நடுவில் விழுந்துகிடப்பார்கள்.
	<br /><br />
	48. வானமும் பூமியும் அவைகளிலுள்ள யாவும் பாபிலோன்மேல் கெம்பீரிக்கும்; பாழ்க்கடிக்கிறவர்கள் அதற்கு வடக்கேயிருந்து வருவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	49. பாபிலோன் இஸ்ரவேலில் கொலையுண்டவர்களை விழப்பண்ணினதுபோல, பாபிலோனிலும் சமஸ்த தேசங்களிலும் கொலையுண்கிறவர்கள் விழுவார்கள்.
	<br /><br />
	50. பட்டயத்துக்குத் தப்பினவர்களே, தங்கித்தரியாமல் நடந்துவாருங்கள்; தூரத்திலே கர்த்தரை நினையுங்கள்; எருசலேம் உங்கள் ஞாபகத்தில் வரக்கடவது.
	<br /><br />
	51. நிந்தையைக் கேட்டதினால் வெட்கப்பட்டோம்; கர்த்தருடைய ஆலயத்தின் பரிசுத்த ஸ்தலங்களின்மேல் அந்நியர் வந்ததினால் நாணம் நம்முடைய முகங்களை மூடிற்று.
	<br /><br />
	52. ஆகையால், கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் அதின் விக்கிரகங்களுக்கு விரோதமாய் விசாரிக்கும் நாட்கள் வரும்; அப்பொழுது அதின் தேசமெங்கும் கொலையுண்கிறவர்கள் கத்துவார்கள்.
	<br /><br />
	53. பாபிலோன் வானபரியந்தம் ஏறினாலும், அது தன் பலமான அரணை உயர்த்தினாலும், அதைப் பாழாக்குகிறவர்கள் என்னிடத்திலிருந்து வருவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	54. பாபிலோனிலிருந்து கூக்குரலின் சத்தமும், கல்தேயர் தேசத்திலிருந்து மகா சங்காரமும் கேட்கப்படும்.
	<br /><br />
	55. கர்த்தர் பாபிலோனைப் பாழாக்கி அதிலுள்ள பெரிய சத்தத்தை ஒழியப்பண்ணுவார்; அவர்களுடைய அலைகள் திரளான தண்ணீர்களைப்போல இரையும், அவர்களுடைய சத்தம் அமளியாயிருக்கும்.
	<br /><br />
	56. பாபிலோனைப் பாழாக்குகிறவன் அதின்மேல் வருகிறான்; அதின் பராக்கிரமசாலிகள் பிடிபடுவார்கள்; அவர்களுடைய வில்லுகள் முறிந்துபோகும்; சரிக்கட்டுகிற தேவனாகிய கர்த்தர் நிச்சயமாகப் பதில் அளிப்பார்.
	<br /><br />
	57. அதின் பிரபுக்களையும் அதின் ஞானிகளையும் அதின் தலைவரையும் அதின் அதிகாரிகளையும் அதின் பராக்கிரமசாலிகளையும் வெறிக்கப்பண்ணுவேன்; அப்பொழுது அவர்கள் என்றென்றைக்கும் விழிக்காத தூக்கமாய்த் தூங்கி விழுவார்கள் என்று சேனைகளின் கர்த்தர் என்னும் நாமமுள்ள ராஜா சொல்லுகிறார்.
	<br /><br />
	58. பாபிலோனின் விஸ்தீரணமான மதில்கள் முற்றிலும் தரையாக்கப்பட்டு, அதின் உயரமான வாசல்கள் அக்கினியால் சுட்டெரிக்கப்படும்; அப்படியே ஜனங்கள் பிரயாசப்பட்டது விருதாவும், ஜாதிகள் வருத்தப்பட்டுச் சம்பாதித்தது அக்கினிக்கு இரையுமாகுமென்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	59. பாபிலோன்மேல் வரும் எல்லாத் தீங்கையும், பாபிலோனுக்கு விரோதமாக எழுதப்பட்ட இந்த எல்லா வசனங்களையும் எரேமியா ஒரு புஸ்தகத்தில் எழுதினான்.
	<br /><br />
	60. யூதாவின் ராஜாவாகிய சிதேக்கியா ராஜ்யபாரம்பண்ணும் நாலாம் வருஷத்திலே பாபிலோனுக்குப் போன சமயத்தில் அவனோடே கூடப்போன மசெயாவின் மகனாகிய நேரியாவின் குமாரனும் சாந்தகுணமுள்ள பிரபுவுமாகிய செராயாவுக்கு எரேமியா தீர்க்கதரிசி கற்பித்த வார்த்தை.
	<br /><br />
	61. எரேமியா செராயாவை நோக்கி: நீ பாபிலோனுக்கு வந்தபின்பு நீ இதைப் பார்த்து, இந்த எல்லா வசனங்களையும் வாசித்துச் சொல்லவேண்டியது என்னவென்றால்:
	<br /><br />
	62. கர்த்தாவே, இந்த ஸ்தலத்திலே மனுஷனும் மிருகமுமுதலாய்த் தங்கித் தரிக்காதபடிக்கும், அது என்றென்றைக்கும் பாழாய்க் கிடக்கும்படிக்கும், அதை அழித்துப்போடுவேன் என்று தேவரீர் அதைக்குறித்து உரைத்தீர் என்பதை நீ சொல்லி,
	<br /><br />
	63. நீ இந்தப் புஸ்தகத்தை வாசித்துத் தீர்ந்தபோது, அதிலே ஒரு கல்லைக்கட்டி, அதை ஐப்பிராத்து நடுவில் எறிந்துவிட்டு,
	<br /><br />
	64. இப்படியே பாபிலோன் முழுகிப்போகும், நான் அதின்மேல் வரப்பண்ணும் தீங்கினால் எழுந்திருக்கமாட்டாமல் இளைத்து விழுவார்கள் என்றார் என்று சொல்லுவாயாக என்றான். எரேமியாவின் வசனங்கள் இவ்வளவோடே முடிந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah51