import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 7 </title>
	<meta
          name="description"
          content="Daniel 7 | தானியேல் தீர்க்கதரிசி | தானியேல் 7 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பாபிலோன் ராஜாவாகிய பெல்ஷாத்சாரின் முதலாம் வருஷத்திலே தானியேல் ஒரு சொப்பனத்தையும் தன் படுக்கையின்மேல் தன் தலையில் தோன்றின தரிசனங்களையும் கண்டான். பின்பு அவன் அந்தச் சொப்பனத்தை எழுதி, காரியங்களின் தொகையை விவரித்தான்.
	<br /><br />
	2. தானியேல் சொன்னது: இராத்திரிகாலத்தில் எனக்கு உண்டான தரிசனத்திலே நான் கண்டது என்னவென்றால்: இதோ, வானத்தின் நாலு காற்றுகளும் பெரிய சமுத்திரத்தின்மேல் அடித்தது.
	<br /><br />
	3. அப்பொழுது வெவ்வேறு ரூபமுள்ள நாலு பெரிய மிருகங்கள் சமுத்திரத்திலிருந்து எழும்பின.
	<br /><br />
	4. முந்தினது சிங்கத்தைப்போல இருந்தது; அதற்குக் கழுகின் செட்டைகள் உண்டாயிருந்தது; நான் பார்த்துக்கொண்டிருக்கையில், அதின் இறகுகள் பிடுங்கப்பட்டது; அது தரையிலிருந்து எடுக்கப்பட்டு, மனுஷனைப்போல இரண்டு காலின்மேல் நிமிர்ந்து நிற்கும்படி செய்யப்பட்டது; மனுஷ இருதயம் அதற்குக் கொடுக்கப்பட்டது.
	<br /><br />
	5. பின்பு, கரடிக்கு ஒப்பாகிய வேறே இரண்டாம் மிருகத்தைக் கண்டேன்; அது ஒரு பக்கமாய்ச் சாய்ந்துநின்று, தன் வாயின் பற்களுக்குள்ளே மூன்று விலாவெலும்புகளைக் கவ்விக்கொண்டிருந்தது; எழும்பி வெகு மாம்சம் தின்னென்று அதற்குச் சொல்லப்பட்டது.
	<br /><br />
	6. அதின்பின்பு, சிவிங்கியைப்போலிருக்கிற வேறொரு மிருகத்தைக் கண்டேன்; அதின் முதுகின்மேல் பட்சியின் செட்டைகள் நாலு இருந்தது; அந்த மிருகத்துக்கு நாலு தலைகளும் உண்டாயிருந்தது; அதற்கு ஆளுகை அளிக்கப்பட்டது.
	<br /><br />
	7. அதற்குப்பின்பு, இராத்தரிசனங்களில் நாலாம் மிருகத்தைக் கண்டேன்; அது கெடியும் பயங்கரமும் மகா பலத்ததுமாயிருந்தது; அதற்குப் பெரிய இருப்புப்பற்கள் இருந்தது; அது நொறுக்கிப் பட்சித்து, மீதியானதைத் தன் கால்களால் மிதித்துப்போட்டது; அது தனக்கு முன்னிருந்த எல்லா மிருகங்களைப்பார்க்கிலும் வேற்றுருவமாயிருந்தது, அதற்குப் பத்துக் கொம்புகள் இருந்தது.
	<br /><br />
	8. அந்தக் கொம்புகளை நான் கவனித்திருக்கையில், இதோ, அவைகளுக்கு இடையிலே வேறொரு சின்ன கொம்பு எழும்பிற்று; அதற்கு முன்பாக முந்தின கொம்புகளில் மூன்று பிடுங்கப்பட்டது; இதோ, அந்தக் கொம்பிலே மனுஷகண்களுக்கு ஒப்பான கண்களும் பெருமையானவைகளைப் பேசும் வாயும் இருந்தது.
	<br /><br />
	9. நான் பார்த்துக்கொண்டிருக்கையில், சிங்காசனங்கள் வைக்கப்பட்டது; நீண்ட ஆயுசுள்ளவர் வீற்றிருந்தார்; அவருடைய வஸ்திரம் உறைந்த மழையைப்போலவும், அவருடைய சிரசின் மயிர் வெண்மையாகவும் பஞ்சைப்போலத் துப்புரவாகவும் இருந்தது; அவருடைய சிங்காசனம் அக்கினிஜுவாலையும், அதின் சக்கரங்கள் எரிகிற நெருப்புமாயிருந்தது.
	<br /><br />
	10. அக்கினி நதி அவர் சந்நிதியிலிருந்து புறப்பட்டு ஓடினது; ஆயிரமாயிரம்பேர் அவரைச் சேவித்தார்கள்; கோடாகோடிபேர் அவருக்கு முன்பாக நின்றார்கள்; நியாயசங்கம் உட்கார்ந்தது; புஸ்தகங்கள் திறக்கப்பட்டது.
	<br /><br />
	11. அப்பொழுது நான் பார்த்தேன்; நான் பார்த்துக்கொண்டிருக்கையில் அந்தக் கொம்பு பெருமையான பேச்சுகளைப் பேசினதினிமித்தம் அந்த மிருகம் கொலைசெய்யப்பட்டது; அதின் உடல் அழிக்கப்பட்டு, எரிகிற அக்கினிக்கு ஒப்புக்கொடுக்கப்பட்டது.
	<br /><br />
	12. மற்ற மிருகங்களுடைய ஆளுகையோவென்றால், அவைகளை விட்டு நீக்கப்பட்டது; ஆனாலும், அவைகளுக்குக் காலமும் சமயமும் ஆகுமட்டும் அவைகள் உயிரோடே இருக்கும்படி கட்டளையிடப்பட்டது.
	<br /><br />
	13. இராத்தரிசனங்களிலே நான் பார்த்துக்கொண்டிருக்கையில், இதோ, மனுஷகுமாரனுடைய சாயலான ஒருவர் வானத்து மேகங்களுடனே வந்தார்; அவர் நீண்ட ஆயுசுள்ளவர் இடமட்டும் வந்து, அவர் சமீபத்தில் கொண்டுவரப்பட்டார்.
	<br /><br />
	14. சகல ஜனங்களும் ஜாதியாரும், பாஷைக்காரரும் அவரையே சேவிக்கும்படி, அவருக்குக் கர்த்தத்துவமும் மகிமையும் ராஜரிகமும் கொடுக்கப்பட்டது; அவருடைய கர்த்தத்துவம் நீங்காத நித்திய கர்த்தத்துவமும், அவருடைய ராஜ்யம் அழியாததுமாயிருக்கும்.
	<br /><br />
	15. தானியேலாகிய நான் என் தேகத்தினுள் என் ஆவியிலே சஞ்சலப்பட்டேன்; என் தலையில் தோன்றின தரிசனங்கள் என்னைக் கலங்கப்பண்ணினது.
	<br /><br />
	16. சமீபத்தில் நிற்கிறவர்களில் ஒருவனிடத்தில் நான் போய், இதன் பொருள் எல்லாவற்றையும் எனக்குச் சொல்லும்படி அவனை வேண்டிக்கொண்டேன்; அவன் அந்தக் காரியங்களின் அர்த்தத்தை எனக்கு அறிவித்துச் சொன்னது என்னவென்றால்:
	<br /><br />
	17. அந்த நாலு பெரிய மிருகங்களும் பூமியிலிருந்து எழும்புகிற நாலு ராஜாக்கள்.
	<br /><br />
	18. ஆனாலும் உன்னதமானவருடைய பரிசுத்தவான்கள் ராஜரிகத்தைப் பெற்று, என்றென்றைக்குமுள்ள சதாகாலங்களிலும் ராஜ்யத்தைச் சுதந்தரித்துக்கொள்வார்கள் என்றான்.
	<br /><br />
	19. அப்பொழுது மற்றவைகளையெல்லாம் பார்க்கிலும் வேற்றுருவும் கெடியுமுள்ளதுமாய், இருப்புப் பற்களும், வெண்கல நகங்களுமுடையதாய் நொறுக்கிப் பட்சித்து, மீதியானதைத் தன் கால்களால் மிதித்துப்போட்டதுமாயிருந்த நாலாம் மிருகத்தைக்குறித்தும்,
	<br /><br />
	20. அதின் தலைமேலுள்ள பத்துக் கொம்புகளைக்குறித்தும், தனக்கு முன்பாக மூன்று கொம்புகள் விழுந்துபோக எழும்பினதுமாய், கண்களையும் பெருமையானவைகளைப் பேசும் வாயையுமுடையதுமாய், மற்றவைகளைப்பார்க்கிலும் பருமனாகத் தோன்றினதுமாயிருந்த அந்த வேறே கொம்பைக்குறித்தும், அவற்றின் பொருளை அறிய மனதாயிருந்தேன்.
	<br /><br />
	21. நீண்ட ஆயுசுள்ளவர் வருமட்டாகவும், நியாயவிசாரிப்பு உன்னதமானவருடைய பரிசுத்தவான்களுக்குக் கொடுக்கப்பட்டு, பரிசுத்தவான்கள் ராஜ்யத்தைச் சுதந்தரித்துக்கொள்ளும் காலம் வருமட்டாகவும்,
	<br /><br />
	22. இந்தக் கொம்பு பரிசுத்தவான்களோடே யுத்தம்பண்ணி, அவர்களை மேற்கொண்டது என்று கண்டேன்.
	<br /><br />
	23. அவன் சொன்னது: நாலாம் மிருகம் பூமியிலே உண்டாகும் நாலாம் ராஜ்யமாம்; அது எல்லா ராஜ்யங்களைப்பார்க்கிலும் வேறாயிருந்து, பூமியை எல்லாம் பட்சித்து, அதை மிதித்து, அதை நொறுக்கிப்போடும்.
	<br /><br />
	24. அந்தப் பத்துக்கொம்புகள் என்னவென்றால், அந்த ராஜ்யத்திலே எழும்பும் பத்து ராஜாக்களாம்; அவர்களுக்குப்பின்பு வேறொருவன் எழும்புவான்; அவன் முந்தினவர்களைப்பார்க்கிலும் வேறாயிருந்து, மூன்று ராஜாக்களைத் தாழ்த்திப்போட்டு,
	<br /><br />
	25. உன்னதமானவருக்கு விரோதமாக வார்த்தைகளைப் பேசி, உன்னதமானவருடைய பரிசுத்தவான்களை ஒடுக்கி, காலங்களையும் பிரமாணங்களையும் மாற்ற நினைப்பான்; அவர்கள் ஒரு காலமும், காலங்களும், அரைக்காலமும் செல்லுமட்டும் அவன் கையில் ஒப்புக்கொடுக்கப்படுவார்கள்.
	<br /><br />
	26. ஆனாலும் நியாயசங்கம் உட்காரும்; அப்பொழுது முடிவுபரியந்தம் அவனைச் சங்கரிக்கும்படியாகவும் அழிக்கும்படியாகவும் அவனுடைய ஆளுகையை நீக்கிப்போடுவார்கள்.
	<br /><br />
	27. வானத்தின் கீழெங்குமுள்ள ராஜ்யங்களின் ராஜரிகமும் ஆளுகையும் மகத்துவமும் உன்னதமானவருடைய பரிசுத்தவான்களாகிய ஜனங்களுக்குக் கொடுக்கப்படும்; அவருடைய ராஜ்யம் நித்திய ராஜ்யம்; சகல கர்த்தத்துவங்களும் அவரைச் சேவித்து, அவருக்குக் கீழ்ப்பட்டிருக்கும் என்றான்.
	<br /><br />
	28. அவன் சொன்ன வார்த்தை இத்தோடே முடிந்தது. தானியேலாகிய நான் என் நினைவுகளால் மிகவும் கலங்கினேன்; என் முகம் வேறுபட்டது; இந்தக் காரியத்தை என் மனதிலே வைத்துக்கொண்டேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel7