import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 8 </title>
	<meta
          name="description"
          content="Isaiah 8 | ஏசாயா 8 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்னும் கர்த்தர் என்னை நோக்கி: நீ ஒரு பெரிய பத்திரத்தை எடுத்து மனுஷன் எழுதுகிற பிரகாரமாய் அதிலே மகேர்-சாலால்-அஷ்-பாஸ் என்று எழுது என்றார்.
	<br /><br />
	2. அப்பொழுது நான் உண்மையுள்ள சாட்சிக்காரராகிய ஆசாரியனான உரியாவையும், யெபெரெகியாவின் குமாரனான சகரியாவையும் அதற்குச் சாட்சிகளாக வைத்துக்கொண்டேன்.
	<br /><br />
	3. நான் தீர்க்கதரிசியானவளைச் சேர்ந்தபோது, அவள் கர்ப்பவதியாகி ஒரு குமாரனைப் பெற்றாள்; அப்பொழுது கர்த்தர் என்னை நோக்கி: மகேர்-சாலால்-அஷ்-பாஸ் என்னும் பேரை அவனுக்கு இடு.
	<br /><br />
	4. இந்தப் பாலகன், அப்பா, அம்மா என்று கூப்பிட அறியுமுன்னே, தமஸ்குவின் ஆஸ்தியும், சமாரியாவின் கொள்ளையும், அசீரியாவின் ராஜாவுக்கு முன்பாகக் கொண்டுபோகப்படும் என்றார்.
	<br /><br />
	5. பின்னும் கர்த்தர் என்னை நோக்கி:
	<br /><br />
	6. இந்த ஜனம் மெதுவாய் ஓடுகிற சீலோவாவின் தண்ணீர்களை அசட்டைபண்ணி, ரேத்சீனையும் ரெமலியாவின் குமாரனையும் சார்ந்து சந்தோஷிக்கிறபடியினால்,
	<br /><br />
	7. இதோ, ஆண்டவர் வல்லமையுள்ள திரளான ஆற்றுநீரைப்போன்ற அசீரியாவின் ராஜாவையும், அவனுடைய சகல ஆடம்பரத்தையும் அவர்கள்மேல் புரளப்பண்ணுவார்; அது அவர்களுடைய ஓடைகளெல்லாவற்றின்மேலும் போய், அவர்களுடைய எல்லாக் கரைகள்மேலும் புரண்டு,
	<br /><br />
	8. யூதாவுக்குள் புகுந்து பிரவாகித்துக் கடந்து, கழுத்துமட்டும் வரும் என்றார். இம்மானுவேலே, அவன் செட்டைகளின் விரிவு உமது தேசத்தின் விசாலத்தை மூடும்.
	<br /><br />
	9. ஜனங்களே, நீங்கள் கூட்டங்கூடுங்கள், முறியடிக்கப்படுவீர்கள்; தூரதேசத்தாராகிய நீங்கள் எல்லாரும் செவிகொடுங்கள்; இடைக்கட்டிக்கொள்ளுங்கள், முறிந்தோடுவீர்கள்,
	<br /><br />
	10. ஆலோசனை செய்யுங்கள், அது அபத்தமாகும்; வார்த்தையை வசனியுங்கள், அது நிற்காது; தேவன் எங்களோடே இருக்கிறார்.
	<br /><br />
	11. கர்த்தருடைய கரம் என்மேல் அமர்ந்து, அவர் என்னுடனே பேசி, நான் இந்த ஜனத்தின் வழியிலே நடவாதபடி எனக்குப் புத்திசொல்லி விளம்பினதாவது:
	<br /><br />
	12. இந்த ஜனங்கள் கட்டுப்பாடு என்று சொல்லுகிறதையெல்லாம் நீங்கள் கட்டுப்பாடு என்று சொல்லாமலும், அவர்கள் பயப்படுகிற பயத்தின்படி நீங்கள் பயப்படாமலும், கலங்காமலும்,
	<br /><br />
	13. சேனைகளின் கர்த்தரையே பரிசுத்தம்பண்ணுங்கள்; அவரே உங்கள் பயமும், அவரே உங்கள் அச்சமுமாயிருப்பாராக.
	<br /><br />
	14. அவர் உங்களுக்குப் பரிசுத்த ஸ்தலமாயிருப்பார்; ஆகிலும் இஸ்ரவேலின் இரண்டு கோத்திரத்துக்கும் தடுக்கலின் கல்லும், இடறுதலின் கன்மலையும், எருசலேமின் குடிகளுக்குச் சுருக்கும் கண்ணியுமாயிருப்பார்.
	<br /><br />
	15. அவர்களில் அநேகர் இடறிவிழுந்து நொறுங்கிச் சிக்குண்டு பிடிபடுவார்கள்.
	<br /><br />
	16. சாட்சி ஆகமத்தைக் கட்டி, என் சீஷருக்குள்ளே வேதத்தை முத்திரையிடு என்றார்.
	<br /><br />
	17. நானோ யாக்கோபின் குடும்பத்துக்குத் தமது முகத்தை மறைக்கிற கர்த்தருக்காகக் காத்திருந்து, அவருக்கு எதிர்பார்த்திருப்பேன்.
	<br /><br />
	18. இதோ, நானும், கர்த்தர் எனக்குக் கொடுத்த பிள்ளைகளும் சீயோன் பர்வதத்தில் வாசமாயிருக்கிற சேனைகளின் கர்த்தராலே இஸ்ரவேலில் அடையாளங்களாகவும் அற்புதங்களாகவும் இருக்கிறோம்.
	<br /><br />
	19. அவர்கள் உங்களை நோக்கி: அஞ்சனம் பார்க்கிறவர்களிடத்திலும், முணுமுணென்று ஓதுகிற குறிகாரரிடத்திலும் விசாரியுங்கள் என்று சொல்லும்போது, ஜனங்கள் தன் தேவனிடத்தில் விசாரிக்கவேண்டியதல்லவோ? உயிருள்ளவர்களுக்காகச் செத்தவர்களிடத்தில் விசாரிக்கலாமோ?
	<br /><br />
	20. வேதத்தையும் சாட்சி ஆகமத்தையும் கவனிக்கவேண்டும்; இந்த வார்த்தையின்படியே சொல்லாவிட்டால், அவர்களுக்கு விடியற்காலத்து வெளிச்சமில்லை.
	<br /><br />
	21. இடுக்கண் அடைந்தவர்களாயும் பட்டினியாயும் தேசத்தைக் கடந்துபோவார்கள்; அவர்கள் பட்டினியாயிருக்கும்போது, மூர்க்கவெறிகொண்டு, தங்கள் ராஜாவையும் தங்கள் தேவனையும் தூஷிப்பார்கள்.
	<br /><br />
	22. அவர்கள் அண்ணாந்துபார்ப்பார்கள், பூமியையும் நோக்கிப்பார்ப்பார்கள்; ஆனாலும் இதோ, இக்கட்டும் அந்தகாரமும் இருக்கும்; இடுக்கத்தால் இருளடைந்து, அந்தகாரத்திலே தள்ளுண்டு அலைவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah8