import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 13 | TAMIL BILBE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 13 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 13 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இந்த மூன்றாந்தரம் நான் உங்களிடத்திற்கு வருகிறேன்; சகல காரியங்களும் இரண்டு மூன்று சாட்சிகளுடைய வாக்கினால் நிலைவரப்படும்.
	<br /><br />
	2. நான் இரண்டாந்தரம் உங்களிடத்திலிருந்தபோது சொன்னதுபோல, இப்பொழுது தூரமாயிருந்தும் உங்களிடத்திலிருக்கிறவனாக, நான் மறுபடியும் வந்தால் தப்பவிடமாட்டேனென்று முன்பு பாவஞ்செய்தவர்களுக்கும் மற்ற எல்லாருக்கும் முன்னறிவித்து எழுதுகிறேன்.
	<br /><br />
	3. கிறிஸ்து எனக்குள்ளே பேசுகிறாரென்பதற்கு அத்தாட்சி தேடுகிறீர்களே; அவர் உங்களிடமாய்ப் பலவீனரல்ல, உங்களிடத்தில் வல்லவராயிருக்கிறார்.
	<br /><br />
	4. ஏனெனில் அவர் பலவீனத்தால் சிலுவையில் அறையப்பட்டிருந்தும், தேவனுடைய வல்லமையினால் பிழைத்திருக்கிறார்; அப்படி நாங்களும் அவருக்குள் பலவீனராயிருந்தும், உங்களிடமாய் விளங்கிய தேவனுடைய வல்லமையினால் அவருடனேகூடப் பிழைத்திருப்போம்.
	<br /><br />
	5. நீங்கள் விசுவாசமுள்ளவர்களோவென்று உங்களை நீங்களே சோதித்து அறியுங்கள்; உங்களை நீங்களே பரீட்சித்துப் பாருங்கள். இயேசுகிறிஸ்து உங்களுக்குள் இருக்கிறாரென்று உங்களை நீங்களே அறியீர்களா? நீங்கள் பரீட்சைக்கு நில்லாதவர்களாயிருந்தால் அறியீர்கள்.
	<br /><br />
	6. நாங்களோ பரீட்சைக்கு நில்லாதவர்களல்லவென்பதை அறிவீர்களென்று நம்புகிறேன்.
	<br /><br />
	7. மேலும் நீங்கள் ஒரு பொல்லாங்கும் செய்யாதிருக்கும்படியாக, தேவனை நோக்கி விண்ணப்பம்பண்ணுகிறேன். நாங்கள் பரீட்சைக்கு நின்றவர்களென்று காணப்படும்பொருட்டல்ல, நாங்கள் பரீட்சைக்கு நில்லாதவர்கள்போலிருந்தாலும், நீங்கள் நலமானதைச் செய்யும்பொருட்டே விண்ணப்பம்பண்ணுகிறேன்.
	<br /><br />
	8. சத்தியத்திற்கு விரோதமாக நாங்கள் ஒன்றுஞ்செய்யக்கூடாமல், சத்தியத்திற்கு அநுகூலமாகவே செய்யக்கூடும்.
	<br /><br />
	9. நாங்கள் பலவீனரும் நீங்கள் பலமுள்ளவர்களுமாயிருக்கையில், சந்தோஷப்படுகிறோம்; நீங்கள் நற்சீர்பொருந்தும்படிக்கும் விண்ணப்பம்பண்ணுகிறோம்.
	<br /><br />
	10. ஆனதால் இடித்துப்போடவல்ல, ஊன்றக் கட்டவே கர்த்தர் எனக்குக் கொடுத்த அதிகாரத்தின்படி, நான் உங்களிடத்தில் வந்திருக்கும்போது, கண்டிதம்பண்ணாதபடிக்கு, நான் தூரமாயிருந்து இவைகளை எழுதுகிறேன்.
	<br /><br />
	11. கடைசியாக, சகோதரரே, சந்தோஷமாயிருங்கள், நற்சீர் பொருந்துங்கள், ஆறுதலடையுங்கள்; ஏகசிந்தையாயிருங்கள், சமாதானமாயிருங்கள், அப்பொழுது அன்புக்கும் சமாதானத்துக்கும் காரணராகிய தேவன் உங்களோடேகூட இருப்பார்.
	<br /><br />
	12. ஒருவரையொருவர் பரிசுத்த முத்தத்தோடு வாழ்த்துங்கள்.
	<br /><br />
	13. பரிசுத்தவான்களெல்லாரும் உங்களுக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	14. கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய கிருபையும், தேவனுடைய அன்பும், பரிசுத்த ஆவியினுடைய ஐக்கியமும், உங்கள் அனைவரோடுங்கூட இருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians13