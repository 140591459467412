import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 4 </title>
	<meta
          name="description"
          content="Mark 4 | மாற்கு 4 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் மறுபடியும் கடலோரத்திலே போதகம்பண்ணத் தொடங்கினார். திரளான ஜனங்கள் அவரிடத்தில் கூடிவந்தபடியால், அவர் கடலிலே நின்ற ஒரு படவில் ஏறி உட்கார்ந்தார்; ஜனங்களெல்லாரும் கடற்கரையில் நின்றார்கள்.
	<br /><br />
	2. அவர் அநேக விசேஷங்களை உவமைகளாக அவர்களுக்குப் போதித்தார்; போதிக்கிறபொழுது அவர்களுக்குச் சொன்னது:
	<br /><br />
	3. கேளுங்கள், விதைக்கிறவன் ஒருவன் விதைக்கப் புறப்பட்டான்.
	<br /><br />
	4. அவன் விதைக்கையில், சில விதை வழியருகே விழுந்தது; ஆகாயத்துப் பறவைகள் வந்து அதைப் பட்சித்துப்போட்டது.
	<br /><br />
	5. சில விதை அதிக மண்ணில்லாத கற்பாறை நிலத்தில் விழுந்தது; அதற்கு ஆழமான மண்ணில்லாததினாலே சீக்கிரத்தில் முளைத்தது;
	<br /><br />
	6. வெயில் ஏறினபோதோ, தீய்ந்து போய், வேரில்லாமையால் உலர்ந்துபோயிற்று.
	<br /><br />
	7. சில விதை முள்ளுள்ள இடங்களில் விழுந்தது; முள் வளர்ந்து, அது பலன் கொடாதபடி, அதை நெருக்கிப்போட்டது.
	<br /><br />
	8. சில விதை நல்ல நிலத்தில் விழுந்து, ஓங்கிவளருகிற பயிராகி, ஒன்று முப்பதும், ஒன்று அறுபதும், ஒன்று நூறுமாகப் பலன் தந்தது.
	<br /><br />
	9. கேட்கிறதற்குக் காதுள்ளவன் கேட்கக்கடவன் என்று அவர்களுக்குச் சொன்னார்.
	<br /><br />
	10. அவர் தனித்திருக்கிறபோது, பன்னிருவரோடுங்கூட அவரைச் சூழ்ந்திருந்தவர்கள் இந்த உவமையைக் குறித்து அவரிடத்தில் கேட்டார்கள்.
	<br /><br />
	11. அதற்கு அவர்: தேவனுடைய ராஜ்யத்தின் இரகசியத்தை அறியும்படி உங்களுக்கு அருளப்பட்டது; புறம்பே இருக்கிறவர்களுக்கோ இவைகளெல்லாம் உவமைகளாகச் சொல்லப்படுகிறது.
	<br /><br />
	12. அவர்கள் குணப்படாதபடிக்கும், பாவங்கள் அவர்களுக்கு மன்னிக்கப்படாதபடிக்கும், அவர்கள் கண்டும் காணாதவர்களாகவும், கேட்டும் உணராதவர்களாகவும் இருக்கும்படி, இப்படிச் சொல்லப்படுகிறது என்றார்.
	<br /><br />
	13. பின்பு அவர் அவர்களை நோக்கி: இந்த உவமையை நீங்கள் அறியவில்லையா? அறியாவிட்டால் மற்ற உவமைகளையெல்லாம் எப்படி அறிவீர்கள்?
	<br /><br />
	14. விதைக்கிறவன் வசனத்தை விதைக்கிறான்.
	<br /><br />
	15. வசனத்தைக் கேட்டவுடனே சாத்தான் வந்து, அவர்கள் இருதயங்களில் விதைக்கப்பட்ட வசனத்தை எடுத்துப்போடுகிறான்; இவர்களே வசனம் விதைக்கப்படுகிற வழியருகானவர்கள்.
	<br /><br />
	16. அப்படியே, வசனத்தைக் கேட்டவுடனே அதைச் சந்தோஷத்தோடு ஏற்றுக்கொண்டும்,
	<br /><br />
	17. தங்களுக்குள்ளே வேர்கொள்ளாதபடியால், கொஞ்சக்காலமாத்திரம் நிலைத்திருக்கிறார்கள், வசனத்தினிமித்தம் உபத்திரவமும் துன்பமும் உண்டானவுடனே இடறலடைகிறார்கள்; இவர்களே கற்பாறை நிலத்தில் விதைக்கப்பட்டவர்கள்.
	<br /><br />
	18. வசனத்தைக் கேட்டும், உலகக்கவலைகளும், ஐசுவரியத்தின் மயக்கமும், மற்றவைகளைப்பற்றி உண்டாகிற இச்சைகளும் உட்பிரவேசித்து, வசனத்தை நெருக்கிப்போட, அதினால் பலனற்றுப்போகிறார்கள்.
	<br /><br />
	19. இவர்களே முள்ளுள்ள இடங்களில் விதைக்கப்பட்டவர்கள்.
	<br /><br />
	20. வசனத்தைக் கேட்டு, ஏற்றுக்கொண்டு, ஒன்று முப்பதும் ஒன்று அறுபதும் ஒன்று நூறுமாகப் பலன்கொடுக்கிறார்கள்; இவர்களே நல்ல நிலத்தில் விதைக்கப்பட்டவர்கள் என்றார்.
	<br /><br />
	21. பின்னும் அவர் அவர்களை நோக்கி: விளக்கைத் தண்டின்மேல் வைக்கிறதற்கேயன்றி, மரக்காலின் கீழாகிலும், கட்டிலின் கீழாகிலும், வைக்கிறதற்குக் கொண்டுவருவார்களா?
	<br /><br />
	22. வெளியரங்கமாகாத அந்தரங்கமுமில்லை, வெளிக்கு வராத மறைபொருளுமில்லை.
	<br /><br />
	23. கேட்கிறதற்கு ஒருவன் காதுள்ளவனாயிருந்தால் கேட்கக்கடவன் என்றார்.
	<br /><br />
	24. பின்னும் அவர் அவர்களை நோக்கி: நீங்கள் கேட்கிறதைக் கவனியுங்கள். எந்த அளவினால் அளக்கிறீர்களோ, அந்த அளவினால் உங்களுக்கும் அளக்கப்படும், கேட்கிற உங்களுக்கு அதிகம் கொடுக்கப்படும்.
	<br /><br />
	25. உள்ளவனெவனோ அவனுக்குக் கொடுக்கப்படும்; இல்லாதவனெவனோ அவனிடத்தில் உள்ளதும் எடுத்துக்கொள்ளப்படும் என்றார்.
	<br /><br />
	26. பின்னும் அவர் அவர்களை நோக்கி: தேவனுடைய ராஜ்யமானது, ஒரு மனுஷன் நிலத்தில் விதையை விதைத்து;
	<br /><br />
	27. இரவில் தூங்கி, பகலில் விழித்திருக்க, அவனுக்குத் தெரியாதவிதமாய், விதை முளைத்துப் பயிராகிறதற்கு ஒப்பாயிருக்கிறது.
	<br /><br />
	28. எப்படியென்றால், நிலமானது முன்பு முளையையும், பின்பு கதிரையும், கதிரிலே நிறைந்த தானியத்தையும் பலனாகத் தானாய்க் கொடுக்கும்.
	<br /><br />
	29. பயிர் விளைந்து அறுப்புக்காலம் வந்தவுடனே, அறுக்கிறதற்கு ஆட்களை அனுப்புகிறான் என்றார்.
	<br /><br />
	30. பின்னும் அவர் அவர்களை நோக்கி: தேவனுடைய ராஜ்யத்தை எதற்கு ஒப்பிடுவோம்? அல்லது எந்த உவமையினாலே அதைத் திருஷ்டாந்தப்படுத்துவோம்?
	<br /><br />
	31. அது ஒரு கடுகுவிதைக்கு ஒப்பாயிருக்கிறது; அது பூமியில் விதைக்கப்படும்போது பூமியிலுள்ள சகல விதைகளிலும் சிறிதாயிருக்கிறது;
	<br /><br />
	32. விதைக்கப்பட்ட பின்போ, அது வளர்ந்து, சகல பூண்டுகளிலும் பெரிதாகி, ஆகாயத்துப் பறவைகள் அதினுடைய நிழலின்கீழ் வந்தடையத்தக்க பெரிய கிளைகளை விடும் என்றார்.
	<br /><br />
	33. அவர்கள் கேட்டறியும் திராணிக்குத்தக்கதாக, அவர் இப்படிப்பட்ட அநேக உவமைகளினாலே அவர்களுக்கு வசனத்தைச் சொன்னார்.
	<br /><br />
	34. உவமைகளினாலேயன்றி அவர்களுக்கு ஒன்றும் சொல்லவில்லை; அவர் தம்முடைய சீஷரோடே தனித்திருக்கும்போது, அவர்களுக்கு எல்லாவற்றையும் விவரித்துச்சொன்னார்.
	<br /><br />
	35. அன்று சாயங்காலத்தில், அவர் அவர்களை நோக்கி: அக்கரைக்குப் போவோம் வாருங்கள் என்றார்.
	<br /><br />
	36. அவர்கள் ஜனங்களை அனுப்பிவிட்டு, அவர் படவிலிருந்தபடியே அவரைக் கொண்டுபோனார்கள். வேறே படவுகளும் அவரோடேகூட இருந்தது.
	<br /><br />
	37. அப்பொழுது பலத்த சுழல்காற்று உண்டாகி, படவு நிரம்பத்தக்கதாக, அலைகள் அதின்மேல் மோதிற்று.
	<br /><br />
	38. கப்பலின் பின்னணியத்தில் அவர் தலையணையை வைத்து நித்திரையாயிருந்தார். அவர்கள் அவரை எழுப்பி: போதகரே, நாங்கள் மடிந்துபோகிறது உமக்குக் கவலையில்லையா என்றார்கள்.
	<br /><br />
	39. அவர் எழுந்து, காற்றை அதட்டி, கடலைப்பார்த்து: இரையாதே, அமைதலாயிரு என்றார். அப்பொழுது காற்று நின்றுபோய், மிகுந்த அமைதல் உண்டாயிற்று.
	<br /><br />
	40. அவர் அவர்களை நோக்கி: ஏன் இப்படிப் பயப்பட்டீர்கள்? ஏன் உங்களுக்கு விசுவாசம் இல்லாமற்போயிற்று என்றார்.
	<br /><br />
	41. அவர்கள் மிகவும் பயந்து: இவர் யாரோ? காற்றும் கடலும் இவருக்குக் கீழ்ப்படிகிறதே என்று, ஒருவரோடொருவர் சொல்லிக்கொண்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark4