import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Joel2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோவேல் 2 </title>
	<meta
          name="description"
          content="Joel 2 | யோவேல் 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சீயோனிலே எக்காளம் ஊதுங்கள், என் பரிசுத்த பர்வதத்திலே எச்சரிப்பின் சத்தமிடுங்கள்; தேசத்தின் குடிகள் எல்லாம் தத்தளிக்கக்கடவர்கள்; ஏனெனில் கர்த்தருடைய நாள் வருகிறது, அது சமீபமாயிருக்கிறது.
	<br /><br />
	2. அது இருளும் அந்தகாரமுமான நாள்; அது மப்பும் மந்தாரமுமான நாள்; விடியற்கால வெளுப்பு பர்வதங்களின்மேல் பரவுகிறதுபோல ஏராளமான பலத்த ஒரு ஜாதி தீவிரமாக வந்து பரவும்; அப்படிப்பட்டது முன் ஒரு காலத்திலும் உண்டானதுமில்லை, இனித் தலைமுறை தலைமுறையாக வரும் வருஷங்களிலும் உண்டாவதுமில்லை.
	<br /><br />
	3. அவைகளுக்கு முன்னாக அக்கினி பட்சிக்கும், அவைகளுக்குப் பின்னாக ஜுவாலை எரிக்கும்; அவைகளுக்கு முன்னாகத் தேசம் ஏதேன் தோட்டத்தைப்போலவும், அவைகளுக்குப் பின்னாகப் பாழான வனாந்தரத்தைப்போலவும் இருக்கும்; அவைகளுக்கு ஒன்றும் தப்பிப்போவதில்லை.
	<br /><br />
	4. அவைகளின் சாயல் குதிரைகளின் சாயலை ஒத்தது; அவைகள் குதிரை வீரரைப்போல ஓடும்.
	<br /><br />
	5. அவைகள் ஓடுகிற இரதங்களின் இரைச்சல்போலவும், செத்தைகளை எரிக்கிற அக்கினிஜுவாலையின் இரைச்சல்போலவும், யுத்தத்துக்கு ஆயத்தப்பட்ட பலத்த ஜனத்தின் இரைச்சல்போலவும், பர்வதங்களுடைய சிகரங்களின்மேல் குதிக்கும்.
	<br /><br />
	6. அவைகளுக்கு முன்பாக ஜனங்கள் நடுங்குவார்கள்; எல்லா முகங்களும் கருகிப்போகும்.
	<br /><br />
	7. அவைகள் பராக்கிரமசாலிகளைப்போல ஓடும்; யுத்தவீரரைப்போல மதிலேறும்; வரிசைகள் பிசகாமல், ஒவ்வொன்றும் தன் தன் அணியிலே செல்லும்.
	<br /><br />
	8. ஒன்றை ஒன்று நெருக்காது; ஒவ்வொன்றும் தன் தன் பாதையிலே செல்லும்; அவைகள் ஆயுதங்களுக்குள் விழுந்தாலும் காயம்படாமற்போகும்.
	<br /><br />
	9. அவைகள் பட்டணம் எங்கும் செல்லும்; மதிலின்மேல் ஓடும், வீடுகளின்மேல் ஏறும்; பலகணிவழியாய்த் திருடனைப்போல உள்ளே நுழையும்.
	<br /><br />
	10. அவைகளுக்கு முன்பாகப் பூமி அதிரும்; வானங்கள் அசையும்; சூரியனும் சந்திரனும் இருண்டுபோகும்; நட்சத்திரங்கள் ஒளி மழுங்கும்.
	<br /><br />
	11. கர்த்தர் தமது சேனைக்குமுன் சத்தமிடுவார்; அவருடைய பாளயம் மகா பெரிது, அவருடைய வார்த்தையின்படி செய்கிறதற்கு வல்லமையுள்ளது; கர்த்தருடைய நாள் பெரிதும் மகா பயங்கரமுமாயிருக்கும்; அதைச் சகிக்கிறவன் யார்?
	<br /><br />
	12. ஆதலால் நீங்கள் இப்பொழுதே உபவாசத்தோடும் அழுகையோடும் புலம்பலோடும் உங்கள் முழு இருதயத்தோடும் என்னிடத்தில் திரும்புங்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. நீங்கள் உங்கள் வஸ்திரங்களையல்ல, உங்கள் இருதயங்களைக் கிழித்து, உங்கள் தேவனாகிய கர்த்தர் இடத்தில் திரும்புங்கள்; அவர் இரக்கமும், மன உருக்கமும், நீடிய சாந்தமும், மிகுந்த கிருபையுமுள்ளவர்; அவர் தீங்குக்கு மனஸ்தாபப்படுகிறவருமாயிருக்கிறார்.
	<br /><br />
	14. ஒருவேளை அவர் திரும்பி மனஸ்தாபப்பட்டு, உங்கள் தேவனாகிய கர்த்தருக்குப் போஜனபலிகளையும் பானபலிகளையும் செலுத்துகிறதற்கான ஆசீர்வாதத்தைத் தந்தருளுவார்.
	<br /><br />
	15. சீயோனிலே எக்காளம் ஊதுங்கள், பரிசுத்த உபவாசநாளை நியமியுங்கள், விசேஷித்த ஆசரிப்பைக் கூறுங்கள்.
	<br /><br />
	16. ஜனத்தைக் கூட்டுங்கள், சபையைப் பரிசுத்தப்படுத்துங்கள்; முதியோரைச் சேருங்கள், பிள்ளைகளையும் பாலுண்கிற குழந்தைகளையும் கூட்டுங்கள்; மணவாளன் தன் அறையையும், மணவாட்டி தன் மறைவையும் விட்டுப் புறப்படுவார்களாக.
	<br /><br />
	17. கர்த்தரின் பணிவிடைக்காரராகிய ஆசாரியர்கள் மண்டபத்துக்கும் பலிபீடத்துக்கும் நடுவே அழுது: கர்த்தாவே, நீர் உமது ஜனத்தைத் தப்பவிட்டுப் புறஜாதிகள் அவர்களைப் பழிக்கும் நிந்தைக்கு உமது சுதந்தரத்தை ஒப்புக்கொடாதிரும்; உங்கள் தேவன் எங்கே என்று புறஜாதிகளுக்குள்ளே சொல்லப்படுவானேன் என்பார்களாக.
	<br /><br />
	18. அப்பொழுது கர்த்தர் தமது தேசத்துக்காக வைராக்கியங்கொண்டு, தமது ஜனத்தைக் கடாட்சிப்பார்.
	<br /><br />
	19. கர்த்தர் மறுமொழி கொடுத்து, தமது ஜனத்தை நோக்கி: இதோ, நான் உங்களை இனிப் புறஜாதிகளுக்குள்ளே நிந்தையாக வைக்காமல், உங்களுக்குத் தானியத்தையும் திராட்சரசத்தையும் எண்ணெயையும் கொடுத்தேன், நீங்கள் அதினால் திருப்தியாவீர்கள்.
	<br /><br />
	20. வடதிசைச்சேனையை உங்களுக்குத் தூரமாக விலக்கி, அதின் முன்தண்டு கீழ்க்கடலுக்கும், அதின் பின்தண்டு மேற்கடலுக்கும் நேராக அதை வறட்சியும் பாழுமான தேசத்துக்குத் துரத்திவிடுவேன்; அங்கே அதின் நாற்றம் எழும்பி, அதின் துர்க்கந்தம் வீசும்; அது பெரிய காரியங்களைச் செய்தது.
	<br /><br />
	21. தேசமே, பயப்படாதே, மகிழ்ந்து களிகூரு; கர்த்தர் பெரிய காரியங்களைச் செய்வார்.
	<br /><br />
	22. வெளியின் மிருகங்களே, பயப்படாதேயுங்கள்; வனாந்தரத்திலே மேய்ச்சல்கள் உண்டாகும்; விருட்சங்கள் காய்களைக் காய்க்கும்; அத்திமரமும் திராட்சச்செடியும் பலனைத் தரும்.
	<br /><br />
	23. சீயோன் குமாரரே, உங்கள் தேவனாகிய கர்த்தருக்குள் மகிழ்ந்து களிகூருங்கள்; அவர் தக்கபடி உங்களுக்கு முன்மாரியைக் கொடுத்து, உங்களுக்கு முன்மாரியையும் பின்மாரியையும் ஏற்கனவே வருஷிக்கப்பண்ணுவார்.
	<br /><br />
	24. களங்கள் தானியத்தினால் நிரம்பும்; ஆலைகளில் திராட்சரசமும் எண்ணெயும் வழிந்தோடும்.
	<br /><br />
	25. நான் உங்களிடத்தில் அனுப்பின என் பெரிய சேனையாகிய வெட்டுக்கிளிகளும், பச்சைக்கிளிகளும், முசுக்கட்டைப் பூச்சிகளும், பச்சைப் புழுக்களும் பட்சித்த வருஷங்களின் விளைவை உங்களுக்குத் திரும்ப அளிப்பேன்.
	<br /><br />
	26. நீங்கள் சம்பூரணமாகச் சாப்பிட்டு, திருப்தியடைந்து, உங்களை அதிசயமாய் நடத்திவந்த உங்கள் தேவனாகிய கர்த்தருடைய நாமத்தைத் துதிப்பீர்கள்; என் ஜனங்கள் ஒருபோதும் வெட்கப்பட்டுப்போவதில்லை.
	<br /><br />
	27. நான் இஸ்ரவேலின் நடுவில் இருக்கிறவரென்றும், நானே உங்கள் தேவனாகிய கர்த்தர், வேறொருவர் இல்லையென்றும் அறிந்துகொள்வீர்கள்; என் ஜனங்கள் ஒருபோதும் வெட்கப்பட்டுப்போவதில்லை.
	<br /><br />
	28. அதற்குப் பின்பு நான் மாம்சமான யாவர்மேலும் என் ஆவியை ஊற்றுவேன்; அப்பொழுது உங்கள் குமாரரும் உங்கள் குமாரத்திகளும் தீர்க்கதரிசனஞ் சொல்லுவார்கள்; உங்கள் மூப்பர் சொப்பனங்களையும், உங்கள் வாலிபர் தரிசனங்களையும் காண்பார்கள்.
	<br /><br />
	29. ஊழியக்காரர்மேலும் ஊழியக்காரிகள்மேலும், அந்நாட்களிலே என் ஆவியை ஊற்றுவேன்.
	<br /><br />
	30. வானத்திலும் பூமியிலும் இரத்தம் அக்கினி புகைஸ்தம்பங்களாகிய அதிசயங்களைக் காட்டுவேன்.
	<br /><br />
	31. கர்த்தருடைய பெரிதும் பயங்கரமுமான நாள் வருமுன்னே சூரியன் இருளாகவும், சந்திரன் இரத்தமாகவும் மாறும்.
	<br /><br />
	32. அப்பொழுது கர்த்தருடைய நாமத்தைத் தொழுதுகொள்ளுகிறவனெவனோ அவன் இரட்சிக்கப்படுவான்; கர்த்தர் சொன்னபடி, சீயோன் பர்வதத்திலும் எருசலேமிலும் கர்த்தர் வரவழைக்கும் மீதியாயிருப்பவர்களிடத்திலும் இரட்சிப்பு உண்டாயிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Joel2