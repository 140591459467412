import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 7 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 7
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நியாயப்பிரமாணத்தை அறிந்திருக்கிறவர்களுடனே பேசுகிறேன். சகோதரரே, ஒரு மனுஷன் உயிரோடிருக்குமளவும் நியாயப்பிரமாணம் அவனை ஆளுகிறதென்று அறியாமலிருக்கிறீர்களா?
	<br /><br />
	2. அதெப்படியென்றால், புருஷனையுடைய ஸ்திரீ தன் புருஷன் உயிரோடிருக்குமளவும் நியாயப்பிரமாணத்தின்படியே அவனுடைய நிபந்தனைக்கு உட்பட்டிருக்கிறாள்; புருஷன் மரித்த பின்பு புருஷனைப்பற்றிய பிரமாணத்தினின்று விடுதலையாயிருக்கிறாள்.
	<br /><br />
	3. ஆகையால், புருஷன் உயிரோடிருக்கையில் அவள் வேறொரு புருஷனை விவாகம்பண்ணினால் விபசாரியென்னப்படுவாள்; புருஷன் மரித்தபின்பு அவள் அந்தப் பிரமாணத்தினின்று விடுதலையானபடியால், வேறொரு புருஷனை விவாகம்பண்ணினாலும் விபசாரியல்ல.
	<br /><br />
	4. அப்படிப்போல, என் சகோதரரே, நீங்கள் மரித்தோரிலிருந்து எழுந்த கிறிஸ்து என்னும் வேறொருவருடையவர்களாகி, தேவனுக்கென்று கனிகொடுக்கும்படி கிறிஸ்துவின் சரீரத்தினாலே நியாயப்பிரமாணத்துக்கு மரித்தவர்களானீர்கள்.
	<br /><br />
	5. நாம் மாம்சத்திற்கு உட்பட்டிருந்த காலத்தில் நியாயப்பிரமாணத்தினாலே தோன்றிய பாவ இச்சைகள் மரணத்திற்கு ஏதுவான கனிகளைக் கொடுக்கத்தக்கதாக நம்முடைய அவயவங்களிலே பெலன்செய்தது.
	<br /><br />
	6. இப்பொழுதோ நாம் பழமையான எழுத்தின்படியல்ல, புதுமையான ஆவியின்படி ஊழியஞ்செய்யத்தக்கதாக, நம்மைக் கட்டியிருந்த நியாயப்பிரமாணத்துக்கு நாம் மரித்தவர்களாகி, அதினின்று விடுதலையாக்கப்பட்டிருக்கிறோம்.
	<br /><br />
	7. ஆகையால் என்ன சொல்லுவோம்? நியாயப்பிரமாணம் பாவமோ? அல்லவே. பாவம் இன்னதென்று நியாயப்பிரமாணத்தினால் நான் அறிந்தேனேயன்றி மற்றப்படி அறியவில்லை; இச்சியாதிருப்பாயாக என்று நியாயப்பிரமாணம் சொல்லாதிருந்தால், இச்சை பாவம் என்று நான் அறியாமலிருப்பேனே.
	<br /><br />
	8. பாவமானது கற்பனையினாலே சமயம் பெற்றுச் சகலவித இச்சைகளையும் என்னில் நடப்பித்தது. நியாயப்பிரமாணம் இல்லாவிட்டால் பாவம் செத்ததாயிருக்குமே.
	<br /><br />
	9. முன்னே நியாயப்பிரமாணமில்லாதவனாயிருந்தபோது நான் ஜீவனுள்ளவனாயிருந்தேன்; கற்பனை வந்தபோது பாவம் உயிர்கொண்டது, நான் மரித்தவனானேன்.
	<br /><br />
	10. இப்படியிருக்க, ஜீவனுக்கேதுவான கற்பனையே எனக்கு மரணத்துக்கேதுவாயிருக்கக்கண்டேன்.
	<br /><br />
	11. பாவமானது கற்பனையினாலே சமயம்பெற்று, என்னை வஞ்சித்து, அதினாலே என்னைக் கொன்றது.
	<br /><br />
	12. ஆகையால் நியாயப்பிரமாணம் பரிசுத்தமுள்ளதுதான், கற்பனையும் பரிசுத்தமாயும் நீதியாயும் நன்மையாயும் இருக்கிறது.
	<br /><br />
	13. இப்படியிருக்க, நன்மையானது எனக்கு மரணமாயிற்றோ? அப்படியல்ல; பாவமே எனக்கு மரணமாயிற்று; பாவம் கற்பனையினாலே மிகுந்த பாவமுள்ளதாகும்படிக்கும், அது நன்மையானதைக் கொண்டு எனக்கு மரணத்தை உண்டாக்கினதினாலே, பாவமாகவே விளங்கும்படிக்கும் அப்படியாயிற்று.
	<br /><br />
	14. மேலும், நமக்குத் தெரிந்திருக்கிறபடி, நியாயப்பிரமாணம் ஆவிக்குரியதாயிருக்கிறது, நானோ பாவத்துக்குக் கீழாக விற்கப்பட்டு, மாம்சத்துக்குரியவனாயிருக்கிறேன்.
	<br /><br />
	15. எப்படியெனில், நான் செய்கிறது எனக்கே சம்மதியில்லை; நான் விரும்புகிறதைச் செய்யாமல், நான் வெறுக்கிறதையே செய்கிறேன்.
	<br /><br />
	16. இப்படி நான் விரும்பாததைச் செய்கிறவனாயிருக்க, நியாயப்பிரமாணம் நல்லதென்று ஒத்துக்கொள்ளுகிறேனே.
	<br /><br />
	17. ஆதலால் நான் அல்ல, எனக்குள் வாசமாயிருக்கிற பாவமே அப்படிச் செய்கிறது.
	<br /><br />
	18. அதெப்படியெனில், என்னிடத்தில், அதாவது, என் மாம்சத்தில், நன்மை வாசமாயிருக்கிறதில்லையென்று நான் அறிந்திருக்கிறேன்; நன்மைசெய்ய வேண்டுமென்கிற விருப்பம் என்னிடத்திலிருக்கிறது, நன்மைசெய்வதோ என்னிடத்திலில்லை.
	<br /><br />
	19. ஆதலால் நான் விரும்புகிற நன்மையைச் செய்யாமல், விரும்பாத தீமையையே செய்கிறேன்.
	<br /><br />
	20. அந்தப்படி நான் விரும்பாததை நான் செய்தால், நான் அல்ல, எனக்குள்ளே வாசமாயிருக்கிற பாவமே அப்படிச் செய்கிறது.
	<br /><br />
	21. ஆனபடியால் நன்மைசெய்ய விரும்புகிற என்னிடத்தில் தீமையுண்டென்கிற ஒரு பிரமாணத்தைக் காண்கிறேன்.
	<br /><br />
	22. உள்ளான மனுஷனுக்கேற்றபடி தேவனுடைய நியாயப்பிரமாணத்தின்மேல் பிரியமாயிருக்கிறேன்.
	<br /><br />
	23. ஆகிலும் என் மனதின் பிரமாணத்துக்கு விரோதமாய்ப் போராடுகிற வேறொரு பிரமாணத்தை என் அவயவங்களில் இருக்கக் காண்கிறேன்; அது என் அவயவங்களில் உண்டாயிருக்கிற பாவப்பிரமாணத்துக்கு என்னைச் சிறையாக்கிக் கொள்ளுகிறது.
	<br /><br />
	24. நிர்ப்பந்தமான மனுஷன் நான்! இந்த மரணசரீரத்தினின்று யார் என்னை விடுதலையாக்குவார்?
	<br /><br />
	25. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துமூலமாய்த் தேவனை ஸ்தோத்திரிக்கிறேன். ஆதலால் நானே என் மனதினாலே தேவனுடைய நியாயப்பிரமாணத்துக்கும், மாம்சத்தினாலேயோ பாவப்பிரமாணத்துக்கும் ஊழியஞ்செய்கிறேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans7