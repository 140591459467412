import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 18 </title>
	<meta
          name="description"
          content="John Chapter 18 | யோவான் அதிகாரம் 18 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு இவைகளைச் சொன்னபின்பு, தம்முடைய சீஷருடனேகூட கெதரோன் என்னும் ஆற்றுக்கு அப்புறம் போனார்; அங்கே ஒரு தோட்டம் இருந்தது, அதிலே அவரும் அவருடைய சீஷரும் பிரவேசித்தார்கள்.
	<br /><br />
	2. இயேசு தம்முடைய சீஷருடனேகூட அடிக்கடி அங்கே போயிருந்தபடியினால், அவரைக் காட்டிக்கொடுக்கிற யூதாசும் அந்த இடத்தை அறிந்திருந்தான்.
	<br /><br />
	3. யூதாஸ் போர்ச்சேவகரின் கூட்டத்தையும் பிரதான ஆசாரியர் பரிசேயர் என்பவர்களால் அனுப்பப்பட்ட ஊழியக்காரரையும் கூட்டிக்கொண்டு, பந்தங்களோடும் தீவட்டிகளோடும் ஆயுதங்களோடும் அவ்விடத்திற்கு வந்தான்.
	<br /><br />
	4. இயேசு தமக்கு நேரிடப்போகிற எல்லாவற்றையும் அறிந்து, எதிர்கொண்டுபோய், அவர்களை நோக்கி: யாரைத் தேடுகிறீர்கள் என்றார்.
	<br /><br />
	5. அவருக்கு அவர்கள் பிரதியுத்தரமாக: நசரேயனாகிய இயேசுவைத் தேடுகிறோம் என்றார்கள். அதற்கு இயேசு: நான்தான் என்றார். அவரைக் காட்டிக்கொடுத்த யூதாசும் அவர்களுடனேகூட நின்றான்.
	<br /><br />
	6. நான்தான் என்று அவர் அவர்களிடத்தில் சொன்னவுடனே, அவர்கள் பின்னிட்டுத் தரையிலே விழுந்தார்கள்.
	<br /><br />
	7. அவர் மறுபடியும் அவர்களை நோக்கி: யாரைத் தேடுகிறீர்கள் என்று கேட்டார். அவர்கள்: நசரேயனாகிய இயேசுவைத் தேடுகிறோம் என்றார்கள்.
	<br /><br />
	8. இயேசு பிரதியுத்தரமாக: நான்தானென்று உங்களுக்குச் சொன்னேனே; என்னைத் தேடுகிறதுண்டானால், இவர்களைப் போகவிடுங்கள் என்றார்.
	<br /><br />
	9. நீர் எனக்குத் தந்தவர்களில் ஒருவனையும் நான் இழந்துபோகவில்லை என்று அவர் சொல்லிய வசனம் நிறைவேறத்தக்கதாக இப்படி நடந்தது.
	<br /><br />
	10. அப்பொழுது சீமோன்பேதுரு, தன்னிடத்திலிருந்த பட்டயத்தை உருவி, பிரதான ஆசாரியனுடைய வேலைக்காரனை வலதுகாதற வெட்டினான்; அந்த வேலைக்காரனுக்கு மல்குஸ் என்று பெயர்.
	<br /><br />
	11. அப்பொழுது இயேசு பேதுருவை நோக்கி: உன் பட்டயத்தை உறையிலே போடு; பிதா எனக்குக் கொடுத்த பாத்திரத்தில் நான் பானம்பண்ணாதிருப்பேனோ என்றார்.
	<br /><br />
	12. அப்பொழுது போர்ச்சேவகரும், ஆயிரம் போர்ச்சேவகருக்குத் தலைவனும், யூதருடைய ஊழியக்காரரும் இயேசுவைப்பிடித்து, அவரைக் கட்டி,
	<br /><br />
	13. முதலாவது அவரை அன்னா என்பவனிடத்திற்குக் கொண்டுபோனார்கள்; அவன் அந்த வருஷத்துப் பிரதான ஆசாரியனாகிய காய்பாவுக்கு மாமனாயிருந்தான்.
	<br /><br />
	14. ஜனங்களுக்காக ஒரே மனுஷன் சாகிறது நலமாயிருக்குமென்று யூதருக்கு ஆலோசனை சொன்னவன் இந்தக் காய்பாவே.
	<br /><br />
	15. சீமோன்பேதுருவும் வேறொரு சீஷனும் இயேசுவுக்குப் பின்சென்றார்கள். அந்தச் சீஷன் பிரதான ஆசாரியனுக்கு அறிமுகமானவனாயிருந்ததினால், இயேசுவுடனேகூடப் பிரதான ஆசாரியனுடைய அரமனைக்குள் பிரவேசித்தான்.
	<br /><br />
	16. பேதுரு வாசலருகே வெளியே நின்றான். அப்பொழுது பிரதான ஆசாரியனுக்கு அறிமுகமானவனாயிருந்த மற்றச் சீஷன் வெளியே வந்து, வாசல்காக்கிறவர்களுடனே பேசி, பேதுருவை உள்ளே அழைத்துக்கொண்டுபோனான்.
	<br /><br />
	17. அப்பொழுது வாசல்காக்கிற வேலைக்காரி பேதுருவை நோக்கி: நீயும் அந்த மனுஷனுடைய சீஷரில் ஒருவனல்லவா என்றாள். அவன்: நான் அல்ல என்றான்.
	<br /><br />
	18. குளிர்காலமானபடியினாலே ஊழியக்காரரும் சேவகரும் கரிநெருப்புண்டாக்கி, நின்று, குளிர்காய்ந்துகொண்டிருந்தார்கள்; அவர்களுடனேகூடப் பேதுருவும் நின்று குளிர்காய்ந்துகொண்டிருந்தான்.
	<br /><br />
	19. பிரதான ஆசாரியன் இயேசுவினிடத்தில் அவருடைய சீஷரைக்குறித்தும் போதகத்தைக்குறித்தும் விசாரித்தான்.
	<br /><br />
	20. இயேசு அவனுக்குப் பிரதியுத்தரமாக: நான் வெளியரங்கமாய் உலகத்துடனே பேசினேன்; ஜெப ஆலயங்களிலேயும் யூதர்களெல்லாரும் கூடிவருகிற தேவாலயத்திலேயும் எப்பொழுதும் உபதேசித்தேன்; அந்தரங்கத்திலே நான் ஒன்றும் பேசவில்லை.
	<br /><br />
	21. நீர் என்னிடத்தில் விசாரிக்கவேண்டியதென்ன? நான் சொன்னவைகளைக் கேட்டவர்களிடத்தில் விசாரியும்; நான் பேசினவைகளை அவர்கள் அறிந்திருக்கிறார்களே என்றார்.
	<br /><br />
	22. இப்படி அவர் சொன்னபொழுது, சமீபத்தில் நின்ற சேவகரில் ஒருவன்: பிரதான ஆசாரியனுக்கு இப்படியா உத்தரவு சொல்லுகிறது என்று, இயேசுவை ஒரு அறை அறைந்தான்.
	<br /><br />
	23. இயேசு அவனை நோக்கி: நான் தகாதவிதமாய்ப் பேசினதுண்டானால், தகாததை ஒப்புவி; நான் தகுதியாய்ப் பேசினேனேயாகில், என்னை ஏன் அடிக்கிறாய் என்றார்.
	<br /><br />
	24. பின்பு அன்னா என்பவன் பிரதான ஆசாரியனாகிய காய்பாவினிடத்திற்கு அவரைக் கட்டுண்டவராக அனுப்பினான்.
	<br /><br />
	25. சீமோன்பேதுரு நின்று குளிர்காய்ந்துகொண்டிருந்தான். அப்பொழுது சிலர் அவனை நோக்கி: நீயும் அவனுடைய சீஷரில் ஒருவனல்லவா என்றார்கள். அவன்: நான் அல்ல என்று மறுதலித்தான்.
	<br /><br />
	26. பிரதான ஆசாரியனுடைய வேலைக்காரரில் பேதுரு காதறவெட்டினவனுக்கு இனத்தானாகிய ஒருவன் அவனை நோக்கி: நான் உன்னை அவனுடனேகூடத் தோட்டத்திலே காணவில்லையா என்றான்.
	<br /><br />
	27. அப்பொழுது பேதுரு மறுபடியும் மறுதலித்தான்; உடனே சேவல் கூவிற்று.
	<br /><br />
	28. அவர்கள் காய்பாவினிடத்திலிருந்து இயேசுவைத் தேசாதிபதியின் அரமனைக்குக் கொண்டுபோனார்கள்; அப்பொழுது விடியற்காலமாயிருந்தது. தீட்டுப்படாமல் பஸ்காவைப் புசிக்கத்தக்கதாக, அவர்கள் தேசாதிபதியின் அரமனைக்குள் பிரவேசியாதிருந்தார்கள்.
	<br /><br />
	29. ஆதலால் பிலாத்து அவர்களிடத்தில் வெளியே வந்து: இந்த மனுஷன்மேல் என்ன குற்றஞ்சாட்டுகிறீர்கள் என்றான்.
	<br /><br />
	30. அவர்கள் அவனுக்குப் பிரதியுத்தரமாக: இவன் குற்றவாளியாயிராவிட்டால், இவனை உம்மிடத்தில் ஒப்புக்கொடுக்கமாட்டோம் என்றார்கள்.
	<br /><br />
	31. அப்பொழுது பிலாத்து அவர்களை நோக்கி: இவனை நீங்களே கொண்டுபோய், உங்கள் நியாயப்பிரமாணத்தின்படி நியாயந்தீருங்கள் என்றான். அதற்கு யூதர்கள்: ஒருவனையும் மரண ஆக்கினை செய்ய எங்களுக்கு அதிகாரமில்லை என்றார்கள்.
	<br /><br />
	32. தாம் இன்னவிதமான மரணமாய் மரிக்கப்போகிறாரென்பதைக் குறித்து இயேசு குறிப்பாய்ச் சொல்லியிருந்த வார்த்தை நிறைவேறத்தக்கதாக இப்படிச் சொன்னார்கள்.
	<br /><br />
	33. அப்பொழுது பிலாத்து மறுபடியும் அரமனைக்குள் பிரவேசித்து, இயேசுவை அழைத்து: நீ யூதருடைய ராஜாவா என்று கேட்டான்.
	<br /><br />
	34. இயேசு அவனுக்குப் பிரதியுத்தரமாக: நீராய் இப்படிச் சொல்லுகிறீரோ? அல்லது மற்றவர்கள் என்னைக்குறித்து இப்படி உமக்குச் சொன்னார்களோ என்றார்.
	<br /><br />
	35. பிலாத்து பிரதியுத்தரமாக: நான் யூதனா? உன் ஜனங்களும் பிரதான ஆசாரியரும் உன்னை என்னிடத்தில் ஒப்புக்கொடுத்தார்கள், நீ என்ன செய்தாய் என்றான்.
	<br /><br />
	36. இயேசு பிரதியுத்தரமாக: என் ராஜ்யம் இவ்வுலகத்திற்குரியதல்ல, என் ராஜ்யம் இவ்வுலகத்திற்குரியதானால் நான் யூதரிடத்தில் ஒப்புக்கொடுக்கப்படாதபடிக்கு என் ஊழியக்காரர் போராடியிருப்பார்களே; இப்படியிருக்க என் ராஜ்யம் இவ்விடத்திற்குரியதல்ல என்றார்.
	<br /><br />
	37. அப்பொழுது பிலாத்து அவரை நோக்கி: அப்படியானால் நீ ராஜாவோ என்றான். இயேசு பிரதியுத்தரமாக: நீர் சொல்லுகிறபடி நான் ராஜாதான்; சத்தியத்தைக்குறித்துச் சாட்சிகொடுக்க நான் பிறந்தேன், இதற்காகவே இந்த உலகத்தில் வந்தேன்; சத்தியவான் எவனும் என் சத்தம் கேட்கிறான் என்றார்.
	<br /><br />
	38. அதற்குப் பிலாத்து: சத்தியமாவது என்ன என்றான். மறுபடியும் அவன் யூதர்களிடத்தில் வெளியே வந்து: நான் அவனிடத்தில் ஒரு குற்றமும் காணேன்.
	<br /><br />
	39. பஸ்காபண்டிகையில் நான் உங்களுக்கு ஒருவனை விடுதலைபண்ணுகிற வழக்கமுண்டே; ஆகையால் யூதருடைய ராஜாவை நான் உங்களுக்காக விடுதலைபண்ண உங்களுக்கு மனதுண்டா என்றான்.
	<br /><br />
	40. அப்பொழுது: அவர்களெல்லாரும் இவனையல்ல, பரபாசை விடுதலைபண்ணவேண்டும் என்று மறுபடியும் சத்தமிட்டார்கள்; அந்தப் பரபாசென்பவன் கள்ளனாயிருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John18