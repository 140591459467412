import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 1 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 1
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசுகிறிஸ்துவின் ஊழியக்காரனும், அப்போஸ்தலனாகும்படி அழைக்கப்பட்டவனும், தேவனுடைய சுவிசேஷத்திற்காகப் பிரித்தெடுக்கப்பட்டவனுமாகிய பவுல்,
	<br /><br />
	2. ரோமாபுரியிலுள்ள தேவப்பிரியரும் பரிசுத்தவான்களாகும்படி அழைக்கப்பட்டவர்களுமாகிய அனைவருக்கும் எழுதுகிறதாவது;
	<br /><br />
	3. நம்முடைய பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	4. இயேசுகிறிஸ்துவைக்குறித்துத் தேவன் தம்முடைய தீர்க்கதரிசிகள் மூலமாய்ப் பரிசுத்த வேதாகமங்களில் முன்னே தம்முடைய சுவிசேஷத்தைப்பற்றி வாக்குத்தத்தம்பண்ணினபடி கர்த்தராகிய இயேசுகிறிஸ்துவானவர்,
	<br /><br />
	5. மாம்சத்தின்படி தாவீதின் சந்ததியில் பிறந்தவரும், பரிசுத்தமுள்ள ஆவியின்படி தேவனுடைய சுதனென்று மரித்தோரிலிருந்து உயிர்த்தெழுந்ததினாலே பலமாய் ரூபிக்கப்பட்ட தேவகுமாரனுமாயிருக்கிறார்.
	<br /><br />
	6. அவர் சகல ஜாதிகளையும், அவர்களுக்குள் இயேசுகிறிஸ்துவினால் அழைக்கப்பட்டவர்களாகிய உங்களையும்,
	<br /><br />
	7. தமது நாமத்தினிமித்தம் விசுவாசத்துக்குக் கீழ்ப்படியப்பண்ணும்பொருட்டு, எங்களுக்குக் கிருபையையும் அப்போஸ்தல ஊழியத்தையும் அருளிச்செய்திருக்கிறார்.
	<br /><br />
	8. உங்கள் விசுவாசம் உலகமெங்கும் பிரசித்தமாகிறபடியினாலே, முதலாவது நான் உங்களெல்லாருக்காகவும் இயேசுகிறிஸ்து மூலமாய் என் தேவனை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	9. நான் ஜெபம்பண்ணும்போதெல்லாம் இடைவிடாமல் உங்களை நினைத்துக்கொண்டிருக்கிறதைக்குறித்துத் தமது குமாரனுடைய சுவிசேஷத்தினாலே என் ஆவியோடு நான் சேவிக்கிற தேவன் எனக்குச் சாட்சியாயிருக்கிறார்.
	<br /><br />
	10. நீங்கள் ஸ்திரப்படுவதற்காக ஆவிக்குரிய சில வரங்களை உங்களுக்குக் கொடுக்கும்படிக்கும்,
	<br /><br />
	11. உங்களிலும் என்னிலுமுள்ள விசுவாசத்தினால் உங்களோடேகூட நானும் ஆறுதலடையும்படிக்கும், உங்களைக் காண வாஞ்சையாயிருக்கிறபடியினாலே,
	<br /><br />
	12. எவ்விதத்திலாவது நான் உங்களிடத்தில் வருகிறதற்குத் தேவனுடைய சித்தத்தினாலே எனக்கு நல்ல பிரயாணம் சீக்கிரத்தில் கிடைக்கவேண்டுமென்று வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	13. சகோதரரே, புறஜாதிகளான மற்றவர்களுக்குள்ளே நான் பலனை அடைந்தது போல உங்களுக்குள்ளும் சில பலனை அடையும்படிக்கு, உங்களிடத்தில் வர பலமுறை யோசனையாயிருந்தேன், ஆயினும் இதுவரைக்கும் எனக்குத் தடையுண்டாயிற்று என்று நீங்கள் அறியாதிருக்க எனக்கு மனதில்லை.
	<br /><br />
	14. கிரேக்கருக்கும், மற்ற அந்நியர்களுக்கும், ஞானிகளுக்கும், மூடருக்கும் நான் கடனாளியாயிருக்கிறேன்.
	<br /><br />
	15. ஆகையால் ரோமாபுரியிலிருக்கிற உங்களுக்கும் என்னால் இயன்றமட்டும் சுவிசேஷத்தைப் பிரசங்கிக்க விரும்புகிறேன்.
	<br /><br />
	16. கிறிஸ்துவின் சுவிசேஷத்தைக் குறித்து நான் வெட்கப்படேன்; முன்பு யூதரிலும் பின்பு கிரேக்கரிலும் விசுவாசிக்கிறவனெவனோ அவனுக்கு இரட்சிப்பு உண்டாவதற்கு அது தேவபெலனாயிருக்கிறது.
	<br /><br />
	17. விசுவாசத்தினாலே நீதிமான் பிழைப்பான் என்று எழுதியிருக்கிறபடி, விசுவாசத்தினால் உண்டாகும் தேவநீதி விசுவாசத்திற்கென்று அந்தச் சுவிசேஷத்தினால் வெளிப்படுத்தப்படுகிறது.
	<br /><br />
	18. சத்தியத்தை அநியாயத்தினாலே அடக்கிவைக்கிற மனுஷருடைய எல்லாவித அவபக்திக்கும் அநியாயத்துக்கும் விரோதமாய், தேவகோபம் வானத்திலிருந்து வெளிப்படுத்தப்பட்டிருக்கிறது.
	<br /><br />
	19. தேவனைக்குறித்து அறியப்படுவது அவர்களுக்குள்ளே வெளிப்பட்டிருக்கிறது; தேவனே அதை அவர்களுக்கு வெளிப்படுத்தியிருக்கிறார்.
	<br /><br />
	20. எப்படியென்றால், காணப்படாதவைகளாகிய அவருடைய நித்திய வல்லமை தேவத்துவம் என்பவைகள், உண்டாக்கப்பட்டிருக்கிறவைகளினாலே, உலகமுண்டானதுமுதற்கொண்டு, தெளிவாய்க் காணப்படும்; ஆதலால் அவர்கள் போக்குச்சொல்ல இடமில்லை.
	<br /><br />
	21. அவர்கள் தேவனை அறிந்தும், அவரைத் தேவனென்று மகிமைப்படுத்தாமலும், ஸ்தோத்திரியாமலுமிருந்து, தங்கள் சிந்தனைகளினாலே வீணரானார்கள்; உணர்வில்லாத அவர்களுடைய இருதயம் இருளடைந்தது.
	<br /><br />
	22. அவர்கள் தங்களை ஞானிகளென்று சொல்லியும் பயித்தியக்காரராகி,
	<br /><br />
	23. அழிவில்லாத தேவனுடைய மகிமையை அழிவுள்ள மனுஷர்கள் பறவைகள் மிருகங்கள் ஊரும் பிராணிகள் ஆகிய இவைகளுடைய ரூபங்களுக்கு ஒப்பாக மாற்றினார்கள்.
	<br /><br />
	24. இதினிமித்தம் அவர்கள் தங்கள் இருதயத்திலுள்ள இச்சைகளினாலே ஒருவரோடொருவர் தங்கள் சரீரங்களை அவமானப்படுத்தத்தக்கதாக, தேவன் அவர்களை அசுத்தத்திற்கு ஒப்புக்கொடுத்தார்.
	<br /><br />
	25. தேவனுடைய சத்தியத்தை அவர்கள் பொய்யாக மாற்றி, சிருஷ்டிகரைத் தொழுது சேவியாமல் சிருஷ்டியைத் தொழுது சேவித்தார்கள், அவரே என்றென்றைக்கும் ஸ்தோத்திரிக்கப்பட்டவர். ஆமென்.
	<br /><br />
	26. இதினிமித்தம் தேவன் அவர்களை இழிவான இச்சைரோகங்களுக்கு ஒப்புக்கொடுத்தார்; அந்தப்படியே அவர்களுடைய பெண்கள் சுபாவ அநுபோகத்தைச் சுபாவத்துக்கு விரோதமான அநுபோகமாக மாற்றினார்கள்.
	<br /><br />
	27. அப்படியே ஆண்களும் பெண்களைச் சுபாவப்படி அநுபவியாமல், ஒருவர்மேலொருவர் விரகதாபத்தினாலே பொங்கி, ஆணோடே ஆண் அவலட்சணமானதை நடப்பித்து, தங்கள் தப்பிதத்திற்குத் தகுதியான பலனைத் தங்களுக்குள் அடைந்தார்கள்.
	<br /><br />
	28. தேவனை அறியும் அறிவைப் பற்றிக்கொண்டிருக்க அவர்களுக்கு மனதில்லாதிருந்தபடியால், தகாதவைகளைச் செய்யும்படி, தேவன் அவர்களைக் கேடான சிந்தைக்கு ஒப்புக்கொடுத்தார்.
	<br /><br />
	29. அவர்கள் சகலவித அநியாயத்தினாலும், வேசித்தனத்தினாலும், துரோகத்தினாலும், பொருளாசையினாலும், குரோதத்தினாலும் நிறையப்பட்டு; பொறாமையினாலும், கொலையினாலும், வாக்குவாதத்தினாலும், வஞ்சகத்தினாலும், வன்மத்தினாலும் நிறைந்தவர்களுமாய்,
	<br /><br />
	30. புறங்கூறுகிறவர்களுமாய், அவதூறுபண்ணுகிறவர்களுமாய், தேவபகைஞருமாய், துராகிருதம்பண்ணுகிறவர்களுமாய், அகந்தையுள்ளவர்களுமாய், வீம்புக்காரருமாய், பொல்லாதவைகளை யோசித்துப் பிணைக்கிறவர்களுமாய், பெற்றாருக்குக் கீழ்ப்படியாதவர்களுமாய்,
	<br /><br />
	31. உணர்வில்லாதவர்களுமாய், உடன்படிக்கைகளை மீறுகிறவர்களுமாய், சுபாவ அன்பில்லாதவர்களுமாய், இணங்காதவர்களுமாய், இரக்கமில்லாதவர்களுமாய் இருக்கிறார்கள்.
	<br /><br />
	32. இப்படிப்பட்டவைகளைச் செய்கிறவர்கள் மரணத்திற்குப் பாத்திரராயிருக்கிறார்களென்று தேவன் தீர்மானித்த நீதியான தீர்ப்பை அவர்கள் அறிந்திருந்தும், அவைகளைத் தாங்களே செய்கிறதுமல்லாமல், அவைகளைச் செய்கிற மற்றவர்களிடத்தில் பிரியப்படுகிறவர்களுமாயிருக்கிறார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans1