import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 21 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் கண்ணேறிட்டுப் பார்த்தபோது, ஐசுவரியவான்கள் காணிக்கைப் பெட்டியிலே தங்கள் காணிக்கைகளைப் போடுகிறதைக் கண்டார்.
	<br /><br />
	2. ஒரு ஏழை விதவை அதிலே இரண்டு காசைப் போடுகிறதையும் கண்டு:
	<br /><br />
	3. இந்த ஏழை விதவை மற்றெல்லாரைப் பார்க்கிலும் அதிகமாகப் போட்டாள் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	4. அவர்களெல்லாரும் தங்கள் பரிபூரணத்திலிருந்தெடுத்துத் தேவனுக்கென்று காணிக்கை போட்டார்கள்; இவளோ தன் வறுமையிலிருந்து தன் ஜீவனத்துக்கு உண்டாயிருந்ததெல்லாம் போட்டுவிட்டாள் என்றார்.
	<br /><br />
	5. பின்பு, சிறந்த கற்களினாலும் காணிக்கைகளினாலும் தேவாலயம் அலங்கரிக்கப்பட்டிருக்கிறதைக்குறித்துச் சிலர் சொன்னபோது,
	<br /><br />
	6. அவர்: நீங்கள் பார்க்கிற இவைகளில் ஒரு கல் மற்றொரு கல்லின்மேலிராதபடிக்கு எல்லாம் இடிக்கப்படும் நாட்கள் வரும் என்றார்.
	<br /><br />
	7. அவர்கள் அவரை நோக்கி: போதகரே, இவைகள் எப்பொழுது சம்பவிக்கும், இவைகள் சம்பவிக்கும் காலத்துக்கு அடையாளம் என்ன என்று கேட்டார்கள்.
	<br /><br />
	8. அதற்கு அவர்: நீங்கள் வஞ்சிக்கப்படாதபடிக்கு எச்சரிக்கையாயிருங்கள்; ஏனெனில் அநேகர் வந்து என் நாமத்தைத் தரித்துக்கொண்டு: நான்தான் கிறிஸ்து என்றும், காலம் சமீபித்தது என்றும் சொல்லுவார்கள்; அவர்களைப் பின்பற்றாதிருங்கள்.
	<br /><br />
	9. யுத்தங்களையும் கலகங்களையுங்குறித்து நீங்கள் கேள்விப்படும்போது, பயப்படாதிருங்கள்; இவைகள் முன்னதாகச் சம்பவிக்கவேண்டியதே; ஆனாலும் முடிவு உடனே வராது என்றார்.
	<br /><br />
	10. அப்பொழுது அவர் அவர்களை நோக்கி: ஜனத்துக்கு விரோதமாய் ஜனமும், ராஜ்யத்துக்கு விரோதமாய் ராஜ்யமும் எழும்பும்.
	<br /><br />
	11. பல இடங்களில் மகா பூமியதிர்ச்சிகளும், பஞ்சங்களும், கொள்ளைநோய்களும் உண்டாகும்; வானத்திலிருந்து பயங்கரமான தோற்றங்களும் பெரிய அடையாளங்களும் உண்டாகும்.
	<br /><br />
	12. இவைகளெல்லாம் நடப்பதற்கு முன்னே என் நாமத்தினிமித்தம், அவர்கள் உங்களைப் பிடித்து, ஜெப ஆலயங்களுக்கும் சிறைச்சாலைகளுக்கும் ஒப்புக்கொடுத்து, ராஜாக்கள் முன்பாகவும் அதிபதிகள் முன்பாகவும் உங்களை இழுத்துத் துன்பப்படுத்துவார்கள்.
	<br /><br />
	13. ஆனாலும் அது உங்களுக்குச் சாட்சியாவதற்கு ஏதுவாயிருக்கும்.
	<br /><br />
	14. ஆகையால் என்ன உத்தரவு சொல்லுவோமென்று கவலைப்படாதிருக்கும்படி உங்கள் மனதிலே நிர்ணயம்பண்ணிக்கொள்ளுங்கள்.
	<br /><br />
	15. உங்களை விரோதிக்கிறவர்கள் ஒருவரும் எதிர்பேசவும் எதிர்நிற்கவும் கூடாத வாக்கையும் ஞானத்தையும் நான் உங்களுக்குக் கொடுப்பேன்.
	<br /><br />
	16. பெற்றாராலும், சகோதரராலும், பந்துஜனங்களாலும், சிநேகிதராலும் காட்டிக்கொடுக்கப்படுவீர்கள்; உங்களில் சிலரைக் கொலைசெய்வார்கள்.
	<br /><br />
	17. என் நாமத்தினிமித்தம் எல்லாராலும் பகைக்கப்படுவீர்கள்.
	<br /><br />
	18. ஆனாலும் உங்கள் தலைமயிரில் ஒன்றாகிலும் அழியாது.
	<br /><br />
	19. உங்கள் பொறுமையினால் உங்கள் ஆத்துமாக்களைக் காத்துக்கொள்ளுங்கள்.
	<br /><br />
	20. எருசலேம் சேனைகளால் சூழப்பட்டிருப்பதை நீங்கள் காணும்போது, அதின் அழிவு சமீபமாயிற்றென்று அறியுங்கள்.
	<br /><br />
	21. அப்பொழுது யூதேயாவிலிருக்கிறவர்கள் மலைகளுக்கு ஓடிப்போகவும், எருசலேமிலிருக்கிறவர்கள் வெளியே புறப்படவும், நாட்டுப்புறங்களிலிருக்கிறவர்கள் நகரத்தில் பிரவேசியாமலிருக்கவும் கடவர்கள்.
	<br /><br />
	22. எழுதியிருக்கிற யாவும் நிறைவேறும்படி நீதியைச் சரிக்கட்டும் நாட்கள் அவைகளே.
	<br /><br />
	23. அந்நாட்களில் கர்ப்பவதிகளுக்கும் பால்கொடுக்கிறவர்களுக்கும் ஐயோ, பூமியின்மேல் மிகுந்த இடுக்கணும் இந்த ஜனத்தின்மேல் கோபாக்கினையும் உண்டாகும்.
	<br /><br />
	24. பட்டயக்கருக்கினாலே விழுவார்கள், சகல புறஜாதிகளுக்குள்ளும் சிறைப்பட்டுப்போவார்கள்; புறஜாதியாரின் காலம் நிறைவேறும்வரைக்கும் எருசலேம் புறஜாதியாரால் மிதிக்கப்படும்.
	<br /><br />
	25. சூரியனிலும் சந்திரனிலும் நட்சத்திரங்களிலும் அடையாளங்கள் தோன்றும்; பூமியின்மேலுள்ள ஜனங்களுக்குத் தத்தளிப்பும் இடுக்கணும் உண்டாகும்; சமுத்திரமும் அலைகளும் முழக்கமாயிருக்கும்.
	<br /><br />
	26. வானத்தின் சத்துவங்கள் அசைக்கப்படும்; ஆதலால் பூமியின்மேல் வரும் ஆபத்துகளுக்குப் பயந்து எதிர்பார்த்திருக்கிறதினால் மனுஷருடைய இருதயம் சோர்ந்துபோம்.
	<br /><br />
	27. அப்பொழுது மனுஷகுமாரன் மிகுந்த வல்லமையோடும் மகிமையோடும் மேகத்தின்மேல் வருகிறதைக் காண்பார்கள்.
	<br /><br />
	28. இவைகள் சம்பவிக்கத் தொடங்கும்போது, உங்கள் மீட்பு சமீபமாயிருப்பதால், நீங்கள் நிமிர்ந்து பார்த்து, உங்கள் தலைகளை உயர்த்துங்கள் என்றார்.
	<br /><br />
	29. அன்றியும் அவர்களுக்கு ஒரு உவமையைச் சொன்னார்: அத்திமரத்தையும் மற்றெல்லா மரங்களையும் பாருங்கள்.
	<br /><br />
	30. அவைகள் துளிர்க்கிறதை நீங்கள் காணும்போது வசந்தகாலம் சமீபமாயிற்றென்று அறிகிறீர்கள்.
	<br /><br />
	31. அப்படியே இவைகள் சம்பவிக்கிறதை நீங்கள் காணும்போது, தேவனுடைய ராஜ்யம் சமீபமாயிற்றென்று அறியுங்கள்.
	<br /><br />
	32. இவையெல்லாம் சம்பவிக்கும் முன் இந்தச் சந்ததி ஒழிந்துபோகாதென்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	33. வானமும் பூமியும் ஒழிந்துபோம், என் வார்த்தைகளோ ஒழிந்து போவதில்லை.
	<br /><br />
	34. உங்கள் இருதயங்கள் பெருந்திண்டியினாலும் வெறியினாலும் லவுகீக கவலைகளினாலும் பாரமடையாதபடிக்கும், நீங்கள் நினையாத நேரத்தில் அந்த நாள் உங்கள்மேல் வராதபடிக்கும் எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	35. பூமியிலெங்கும் குடியிருக்கிற அனைவர்மேலும் அது ஒரு கண்ணியைப்போல வரும்.
	<br /><br />
	36. ஆகையால் இனிச் சம்பவிக்கப்போகிற இவைகளுக்கெல்லாம் நீங்கள் தப்பி, மனுஷகுமாரனுக்கு முன்பாக நிற்கப் பாத்திரவான்களாக எண்ணப்படுவதற்கு, எப்பொழுதும் ஜெபம்பண்ணி விழித்திருங்கள் என்றார்.
	<br /><br />
	37. அவர் பகற்காலங்களில் தேவாலயத்திலே உபதேசம்பண்ணிக்கொண்டிருந்து, இராக்காலங்களில் வெளியே போய், ஒலிவமலை என்னப்பட்ட மலையிலே தங்கிவந்தார்.
	<br /><br />
	38. ஜனங்களெல்லாரும் அவருடைய உபதேசத்தைக் கேட்கும்படி அதிகாலமே தேவாலயத்தில் அவரிடத்திற்கு வருவார்கள்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke21