import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 22 </title>
	<meta
          name="description"
          content="Jeremiah 22 | எரேமியா 22 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொன்னது: நீ யூதா ராஜாவின் அரமனைக்குப் போய், அங்கே சொல்லவேண்டிய வசனம் என்னவென்றால்:
	<br /><br />
	2. தாவீதின் சிங்காசனத்தில் வீற்றிருக்கிற யூதாவின் ராஜாவே, நீரும் உம்முடைய ஊழியக்காரரும் இந்த வாசல்களுக்குள் பிரவேசிக்கிற உம்முடைய ஜனமும் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	3. நீங்கள் நியாயமும் நீதியும் செய்து, பறிகொடுத்தவனை ஒடுக்குகிறவனுடைய கைக்குத் தப்புவியுங்கள்; நீங்கள் பரதேசியையும் திக்கற்றவனையும் விதவையையும் ஒடுக்காமலும், கொடுமைசெய்யாமலும், இவ்விடத்தில் குற்றமில்லாத இரத்தத்தைச் சிந்தாமலும் இருங்கள்.
	<br /><br />
	4. இந்த வார்த்தையின்படியே நீங்கள் மெய்யாய்ச் செய்வீர்களாகில், தாவீதின் சிங்காசனத்தில் உட்கார்ந்திருக்கிற ராஜாக்கள் இரதங்கள்மேலும் குதிரைகள்மேலும் ஏறி, அவனும் அவன் ஊழியக்காரரும் அவன் ஜனமுமாக இந்த அரமனை வாசல்களின் வழியாய் உட்பிரவேசிப்பார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. நீங்கள் இந்த வார்த்தைகளைக் கேளாமற்போனீர்களேயாகில் இந்த அரமனை பாழாய்ப்போம் என்று என்பேரில் ஆணையிட்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. யூதா ராஜாவின் அரமனையைக் குறித்துக் கர்த்தர்: நீ எனக்குக் கீலேயாத்தைப்போலவும் லீபனோனின் கொடுமுடியைப்போலவும் இருக்கிறாய்; ஆனாலும் மெய்யாகவே நான் உன்னை வனாந்தரத்தைப்போலவும், குடியில்லாத பட்டணங்களைப்போலவும் ஆக்கிவிடுவேன்.
	<br /><br />
	7. சங்காரக்காரரை அவரவர் ஆயுதங்களோடுங்கூட நான் உனக்கு விரோதமாக ஆயத்தப்படுத்துவேன்; உன் உச்சிதமான கேதுருக்களை அவர்கள் வெட்டி, அக்கினியிலே போடுவார்கள்.
	<br /><br />
	8. அநேகம் ஜாதிகள் இந்த நகரத்தைக் கடந்துவந்து, அவனவன் தன் தன் அயலானை நோக்கி: இந்தப் பெரிய நகரத்துக்குக் கர்த்தர் இப்படிச் செய்தது என்னவென்று கேட்பார்கள்.
	<br /><br />
	9. அதற்குப் பிரதியுத்தரமாக: அவர்கள் தங்கள் தேவனாகிய கர்த்தரின் உடன்படிக்கையை விட்டுவிட்டு, அந்நிய தேவர்களைப் பணிந்துகொண்டு, அவைகளுக்கு ஆராதனைசெய்தபடியினால் இப்படியாயிற்று என்பார்கள் என்று சொல்லுகிறார்.
	<br /><br />
	10. மரித்தவனுக்காக அழவேண்டாம், அவனுக்காகப் பரிதபிக்கவும்வேண்டாம், சிறைப்பட்டுப்போனவனுக்காகவே அழுங்கள்; அவன் இனித் திரும்பிவருவதுமில்லை, தன் ஜநந பூமியைக் காண்பதுமில்லை.
	<br /><br />
	11. தன் தகப்பனாகிய யோசியாவின் பட்டத்துக்கு வந்து, அரசாண்டு, இவ்விடத்திலிருந்து புறப்பட்டுப்போன யூதாவின் ராஜாவாயிருந்த யோசியாவின் குமாரனாகிய சல்லூமைக்குறித்து: அவன் இனி இங்கே திரும்ப வராமல்,
	<br /><br />
	12. தான் கொண்டுபோகப்பட்ட ஸ்தலத்திலே மரிப்பான்; இந்தத் தேசத்தை அவன் இனிக் காண்பதில்லையென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. தனக்கு விஸ்தாரமான வீட்டையும், காற்று வீசும் விசாலமான மேலறைகளையும் கட்டுவேனென்று சொல்லி, பலகணிகளைத் தனக்குத் திறந்து, கேதுரு பலகைகளை வைத்து, ஜாதிலிங்கவருணம் பூசி,
	<br /><br />
	14. அநீதியினாலே தன் வீட்டையும், அநியாயத்தினாலே தன் மேலறைகளையும் கட்டி, தன் அயலான் செய்யும் வேலைக்குக் கூலிகொடாமல், அவனைச் சும்மா வேலைகொள்ளுகிறவனுக்கு ஐயோ!
	<br /><br />
	15. நீ கேதுருமர மாளிகைகளில் உலாவுகிறபடியினாலே ராஜாவாயிருப்பாயோ? உன் தகப்பன் போஜனபானம்பண்ணி, நியாயமும் நீதியுஞ் செய்தபோது அவன் சுகமாய் வாழ்ந்திருக்கவில்லையோ?
	<br /><br />
	16. அவன் சிறுமையும் எளிமையுமானவனுடைய நியாயத்தை விசாரித்தான்; அப்பொழுது சுகமாய் வாழ்ந்தான்; அப்படிச் செய்வதல்லவோ என்னை அறிகிற அறிவு என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. உன் கண்களும் உன் மனதுமோவென்றால் தற்பொழிவின்மேலும், குற்றமில்லாத இரத்தத்தைச் சிந்துவதின்மேலும், இடுக்கமும் நொறுக்குதலும் செய்வதின்மேலுமே அல்லாமல் வேறொன்றின்மேலும் வைக்கப்படவில்லை.
	<br /><br />
	18. ஆகையால் கர்த்தர் யோசியாவின் குமாரனாகிய யோயாக்கீம் என்கிற யூதாவின் ராஜாவைக்குறித்து: ஐயோ! என் சகோதரனே, ஐயோ! சகோதரியே, என்று அவனுக்காகப் புலம்புவதில்லை; ஐயோ! ஆண்டவனே, ஐயோ! அவருடைய மகத்துவமே, என்று அவனுக்காகப் புலம்புவதில்லை.
	<br /><br />
	19. ஒரு கழுதை புதைக்கப்படுகிற வண்ணமாய் அவன் எருசலேமின் வாசல்களுக்கு வெளியே இழுத்தெறிந்து புதைக்கப்படுவான் என்று சொல்லுகிறார்.
	<br /><br />
	20. லீபனோனின்மேலேறிப் புலம்பு, பாசானில் உரத்த சத்தமிடு, ஆபரீமிலிருந்து கூப்பிட்டுக்கொண்டிரு; உன் நேசர் அனைவரும் முறிந்தார்கள்.
	<br /><br />
	21. நீ சுகமாய் வாழ்ந்திருக்கையில் நான் உனக்குச் சொன்னேன், நீ கேளேன் என்றாய்; உன் சிறுவயதுமுதல் நீ என் சத்தத்தைக் கேளாமற்போகிறதே உன் வழக்கம்.
	<br /><br />
	22. உன் மேய்ப்பர்கள் எல்லாரையும் காற்று அடித்துக்கொண்டுபோகும்; உன் நேசர் சிறைப்பட்டுப்போவார்கள்; அப்போதல்லவோ உன் எல்லாப் பொல்லாப்பினிமித்தமும் நீ வெட்கப்பட்டு இலச்சையடைவாய்.
	<br /><br />
	23. லீபனோனில் வாசமாயிருந்து, கேதுருமரங்களில் கூடுகட்டிக்கொண்டிருக்கிறவளே, வேதனைகளும் பிள்ளைப் பெறுகிறவளுக்கொத்த வாதையும் உனக்கு வருகையில், நீ எவ்வளவு பரிதபிக்கப்படத்தக்கவளாயிருப்பாய்!
	<br /><br />
	24. யூதாவின் ராஜாவாகிய யோயாக்கீமின் குமாரன் கோனியா, என் வலதுகையின் முத்திரை மோதிரமாயிருந்தாலும், அதிலிருந்து உன்னைக் கழற்றி எறிந்துபோடுவேன் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	25. உன் பிராணனை வாங்கத்தேடுகிறவர்களின் கையிலும், நீ பயப்படுகிறவர்களின் கையிலும் உன்னை ஒப்புக்கொடுப்பேன்; பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரின் கையிலும் கல்தேயரின் கையிலும் ஒப்புக்கொடுப்பேன்.
	<br /><br />
	26. உன்னையும், உன்னைப் பெற்ற தாயையும், உங்கள் ஜநந பூமியல்லாத அந்நிய தேசத்திலே துரத்திவிடுவேன். அங்கே சாவீர்கள்.
	<br /><br />
	27. திரும்புவதற்குத் தங்கள் ஆத்துமா வாஞ்சிக்கும் தேசத்துக்கு அவர்கள் திரும்பிவருவதில்லை.
	<br /><br />
	28. கோனியா என்கிற இந்த மனுஷன் அவமதிக்கப்பட்ட உடைந்த சிலையோ? ஒருவரும் விரும்பாத பாத்திரமோ? அவனும் அவன் சந்ததியும் தள்ளுண்டதும், தாங்கள் அறியாத தேசத்திலே துரத்திவிடப்பட்டதும் ஏன்?
	<br /><br />
	29. தேசமே! தேசமே! தேசமே! கர்த்தருடைய வார்த்தையைக் கேள்.
	<br /><br />
	30. இந்தப் புருஷன் சந்தானமற்றவன், தன் நாட்களில் வாழ்வடையாதவன் என்று இவனைக்குறித்து எழுதுங்கள்; அவன் வித்தில் ஒருவனாகிலும் வாழ்வடைந்து, தாவீதின் சிங்காசனத்தில் வீற்றிருந்து, யூதாவில் அரசாளப்போகிறதில்லை என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah22