import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 12 </title>
	<meta
          name="description"
          content="Hosea 12 | ஓசியா 12 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எப்பிராயீம் காற்றை மேய்ந்து, கொண்டற்காற்றைப் பின்தொடருகிறான்; அவன் நாள்தோறும் பொய்யையும் கேட்டையும் வர்த்திக்கப்பண்ணி, அசீரியரோடே உடன்படிக்கைபண்ணுகிறான்; எகிப்துக்கு எண்ணெய் கொண்டுபோகப்படுகிறது.
	<br /><br />
	2. யூதாவோடும் கர்த்தருக்கு வழக்கு இருக்கிறது; அவர் யாக்கோபை அவன் வழிகளுக்குத்தக்கதாக விசாரிக்கப்போகிறார்; அவன் கிரியைகளுக்குத்தக்கதாக அவனுக்கு நீதியைச் சரிக்கட்டுவார்.
	<br /><br />
	3. அவன் தாயின் கர்ப்பத்திலே தன் சகோதரனுடைய குதிகாலைப் பிடித்தான்; தன் பெலத்தினால் தேவனோடே போராடினான்.
	<br /><br />
	4. அவன் தூதனானவரோடே போராடி மேற்கொண்டான், அழுது அவரை நோக்கிக் கெஞ்சினான்; பெத்தேலிலே அவர் அவனைக் கண்டு சந்தித்து, அவ்விடத்திலும் நம்மோடே பேசினார்.
	<br /><br />
	5. கர்த்தராகிய அவர் சேனைகளின் தேவன்; யேகோவா என்பது அவருடைய நாமசங்கீர்த்தனம்.
	<br /><br />
	6. இப்போதும் நீ உன் தேவனிடத்தில் திரும்பு; தயவையும் நியாயத்தையும் கைக்கொண்டு, இடைவிடாமல் உன் தேவனை நம்பிக்கொண்டிரு.
	<br /><br />
	7. அவன் வியாபாரி, கள்ளத்தராசு அவன் கையில் இருக்கிறது; அநியாயஞ்செய்ய விரும்புகிறான்.
	<br /><br />
	8. எப்பிராயீம்: நான் ஐசுவரியவானானேன்; நான் பொருளைச் சம்பாதித்தேன்; நான் பிரயாசப்பட்டுத் தேடின எல்லாவற்றிலும் பாவமாகிய அக்கிரமம் என்னிடத்தில் கண்டுபிடிக்கப்படுவதில்லையென்று சொல்லுகிறான்.
	<br /><br />
	9. உன்னை எகிப்துதேசத்திலிருந்து அழைத்துவந்தது முதற்கொண்டு உன் தேவனாகிய கர்த்தராயிருக்கிற நான், பண்டிகை நாட்களில் செய்யப்படுகிறதுபோல், திரும்பவும் உன்னைக் கூடாரங்களில் தாபரிக்கப்பண்ணுவேன்.
	<br /><br />
	10. அப்படியே தீர்க்கதரிசிகளோடே சொன்னேன்; நான் அநேகம் தரிசனங்களை அருளினேன்; தீர்க்கதரிசிகளைக்கொண்டு உவமைகளால் பேசினேன்.
	<br /><br />
	11. கீலேயாத் அக்கிரம ஸ்தலமோ? ஆம், அவர்கள் அபத்தரானார்கள்; கில்காலிலே காளைகளைப் பலியிடுகிறார்கள்; அவர்களுடைய பீடங்கள் வயல்வரப்புகளில் இருக்கிற கற்குவியல்களைப்போல் இருக்கிறது.
	<br /><br />
	12. யாக்கோபு சீரியாதேசத்துக்கு ஓடிப்போய், இஸ்ரவேல் ஒரு பெண்ணுக்காக ஊழியஞ்செய்து, ஒரு பெண்ணுக்காக ஆடு மேய்த்தான்.
	<br /><br />
	13. கர்த்தர் ஒரு தீர்க்கதரிசியைக் கொண்டு இஸ்ரவேலை எகிப்திலிருந்து புறப்படப்பண்ணினார்; தீர்க்கதரிசியினால் காக்கப்பட்டான்.
	<br /><br />
	14. எப்பிராயீமோ அவரை மிகவும் கோபப்படுத்தினான்; ஆகையால் அவனுடைய ஆண்டவர் அவனுடைய இரத்தப்பழிகளை அவன்மேல் சுமத்தி, அவன் செய்த நிந்தையை அவன்மேல் திருப்புவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea12