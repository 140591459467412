import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title> Jesus is Perfect High Priest Hebrews Tamil Bible | எபிரெயர் -5 </title>
	<meta
          name="description"
          content="Jesus is the Perfect High Priest Hebrews Chapter-5 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-5 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* இயேசுவே சரியான பிரதான ஆசாரியர் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. அன்றியும், மனுஷரில் தெரிந்துகொள்ளப்பட்ட எந்தப் பிரதான ஆசாரியனும் பாவங்களுக்காகக் காணிக்கைகளையும் பலிகளையும் செலுத்தும்படி, மனுஷருக்காகத் தேவகாரியங்களைப்பற்றி நியமிக்கப்படுகிறான்.
	<br /><br />
	2. தானும் பலவீனமுள்ளவனானபடியினாலே, அறியாதவர்களுக்கும் நெறிதப்பிப்போனவர்களுக்கும் இரங்கத்தக்கவனாயிருக்கிறான்.
	<br /><br />
	3. அதினிமித்தம் அவன் ஜனங்களுடைய பாவங்களுக்காகப் பலியிடவேண்டியதுபோல, தன்னுடைய பாவங்களுக்காகவும் பலியிடவேண்டியதாயிருக்கிறது.
	<br /><br />
	4. மேலும், ஆரோனைப்போலத் தேவனால் அழைக்கப்பட்டாலொழிய, ஒருவனும் இந்தக் கனமான ஊழியத்துக்குத் தானாய் ஏற்படுகிறதில்லை.
	<br /><br />
	5. அந்தப்படியே கிறிஸ்துவும் பிரதான ஆசாரியராகிறதற்குத் தம்மைத்தாமே உயர்த்தவில்லை; நீர் என்னுடைய குமாரன், இன்று நான் உம்மை ஜெநிப்பித்தேன் என்று அவரோடே சொன்னவரே அவரை உயர்த்தினார்.
	<br /><br />
	6. அப்படியே வேறொரு இடத்திலும்: நீர் மெல்கிசேதேக்கின் முறைமையின்படி என்றென்றைக்கும் ஆசாரியராயிருக்கிறீர் என்று சொல்லியிருக்கிறார்.
	<br /><br />
	7. அவர் மாம்சத்திலிருந்த நாட்களில், தம்மை மரணத்தினின்று இரட்சிக்க வல்லமையுள்ளவரை நோக்கி, பலத்த சத்தத்தோடும் கண்ணீரோடும் விண்ணப்பம்பண்ணி, வேண்டுதல்செய்து, தமக்கு உண்டான பயபக்தியினிமித்தம் கேட்கப்பட்டு,
	<br /><br />
	8. அவர் குமாரனாயிருந்தும் பட்டபாடுகளினாலே கீழ்ப்படிதலைக் கற்றுக்கொண்டு,
	<br /><br />
	9. தாம் பூரணரானபின்பு, தமக்குக் கீழ்ப்படிகிற யாவரும் நித்திய இரட்சிப்பை அடைவதற்குக் காரணராகி,
	<br /><br />
	10. மெல்கிசேதேக்கின் முறைமையின்படியான பிரதான ஆசாரியர் என்று தேவனாலே நாமம் தரிக்கப்பட்டார்.
	<br /><br />
	11. இந்த மெல்கிசேதேக்கைப்பற்றி நாம் விஸ்தாரமாய்ப் பேசலாம்; நீங்கள் கேள்வியில் மந்தமுள்ளவர்களானபடியால், அதை விளங்கப்பண்ணுகிறது அரிதாயிருக்கும்.
	<br /><br />
	12. காலத்தைப் பார்த்தால், போதகராயிருக்கவேண்டிய உங்களுக்குத் தேவனுடைய வாக்கியங்களின் மூல உபதேசங்களை மறுபடியும் உபதேசிக்கவேண்டியதாயிருக்கிறது; நீங்கள் பலமான ஆகாரத்தையல்ல, பாலை உண்ணத்தக்கவர்களானீர்கள்.
	<br /><br />
	13. பாலுண்கிறவன் குழந்தையாயிருக்கிறபடியினாலே நீதியின் வசனத்தில் பழக்கமில்லாதவனாயிருக்கிறான்.
	<br /><br />
	14. பலமான ஆகாரமானது நன்மை தீமையின்னதென்று பயிற்சியினால் பகுத்தறியத்தக்கதாக முயற்சிசெய்யும் ஞானேந்திரியங்களையுடையவர்களாகிய பூரண வயதுள்ளவர்களுக்கே தகும்
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* இயேசுவை கடவுளால் நியமிக்கப்பட்ட பிரதான ஆசாரியராகக் காட்டுகிறது, அவர் விசுவாசிக்கும் அனைவருக்கும் நித்திய இரட்சிப்பின் மூலமாக ஆனார். அவர் மாம்சத்தின் பலவீனத்திற்கு உட்பட்டார், இன்னும் பாவம் இல்லாமல் இருந்தார். அவர் அனுபவித்த துன்பங்களின் மூலம் கீழ்ப்படிதலைக் கற்றுக்கொண்டார், அதே வழியில் அவர் இரட்சிப்பைப் பெறுபவர்களுக்கு உதவ முடியும். மெல்கிசேதேக்கின் வரிசையில் என்றென்றும் நம்முடைய பிரதான ஆசாரியராக இருக்க இயேசு கடவுளால் வடிவமைக்கப்பட்டார். *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews5