import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 11 </title>
	<meta
          name="description"
          content="Zechariah 11 | சகரியா 11 | Sageria 11 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. லீபனோனே, அக்கினி உன் கேதுருமரங்களைப் பட்சிக்கும்படி உன் வாசல்களைத் திற.
	<br /><br />
	2. தேவதாரு விருட்சங்களே, புலம்புங்கள்; கேதுருமரங்கள் விழுந்ததே; பிரபலமானவைகள் பாழாக்கப்பட்டன. பாசானின் கர்வாலிமரங்களே, புலம்புங்கள்; அரணுள்ள சோலை கீழே தள்ளப்பட்டது.
	<br /><br />
	3. மேய்ப்பர்களின் மகிமை அழிந்துபோனபடியால், அவர்கள் அலறுகிற சத்தம் கேட்கப்படுகிறது; யோர்தானின் பெருமை அழிந்துபோனபடியால், பாலசிங்கங்கள் கர்ச்சிக்கிற சத்தம் கேட்கப்படுகிறது.
	<br /><br />
	4. என் தேவனாகிய கர்த்தர் சொல்லுகிறது என்னவென்றால், கொலையுண்கிற ஆடுகளை மேய்க்கக்கடவாய்.
	<br /><br />
	5. அவைகளை உடையவர்கள், அவைகளைக் கொன்றுபோட்டுத் தங்களுக்குக் குற்றமில்லையென்று எண்ணுகிறார்கள். அவைகளை விற்கிறவர்கள், கர்த்தருக்கு ஸ்தோத்திரம், நாங்கள் ஐசுவரியமுள்ளவர்களானோம் என்கிறார்கள்; அவைகளை மேய்க்கிறவர்கள், அவைகள்மேல் இரக்கம்வைக்கிறதில்லை.
	<br /><br />
	6. நான் இனித் தேசத்துக் குடிகளின்மேல் இரக்கம்வையாமல் மனுஷரில் யாவரையும் அவனவனுடைய அயலான் கையிலும், அவனவனுடைய ராஜாவின் கையிலும் அகப்படப்பண்ணுவேன்; அவர்கள் தேசத்தை அழித்தும், நான் இவர்களை அவர்கள் கைக்குத் தப்புவிப்பதில்லையென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. கொலையுண்கிற மந்தையாகிய சிறுமைப்பட்ட உங்களை நான் மேய்ப்பேன்; நான் இரண்டு கோல்களை எடுத்து, ஒன்றிற்கு அநுக்கிரகம் என்றும், ஒன்றிற்கு நிக்கிரகம் என்றும் பேரிட்டு மந்தையை மேய்த்து,
	<br /><br />
	8. ஒரே மாதத்திலே மூன்று மேய்ப்பரையும் அதம்பண்ணினேன்; என் ஆத்துமா அவர்களை அரோசித்தது; அவர்கள் ஆத்துமா என்னையும் வெறுத்தது.
	<br /><br />
	9. இனி நான் உங்களை மேய்ப்பதில்லை; சாகிறது சாகட்டும், அதமாகிறது அதமாகட்டும்; மீதியானவைகளோவென்றால், ஒன்றின் மாம்சத்தை ஒன்று தின்னக்கடவது என்று நான் சொல்லி,
	<br /><br />
	10. அநுக்கிரகம் என்னப்பட்ட என் கோலை எடுத்து, நான் அந்த ஜனங்களெல்லாரோடும் பண்ணியிருந்த என் உடன்படிக்கை அற்றுப்போகும்படிக்கு அதை முறித்துப்போட்டேன்.
	<br /><br />
	11. அந்நாளிலே அது அற்றுப்போயிற்று; அப்படியே மந்தையில் எனக்குக் காத்திருந்த சிறுமைப்பட்டவைகள் அது கர்த்தருடைய வார்த்தையென்று அறிந்துகொண்டன.
	<br /><br />
	12. உங்கள் பார்வைக்கு நன்றாய்க் கண்டால், என் கூலியைத் தாருங்கள்; இல்லாவிட்டால் இருக்கட்டும் என்று அவர்களோடே சொன்னேன்; அப்பொழுது எனக்குக் கூலியாக முப்பது வெள்ளிக்காசை நிறுத்தார்கள்.
	<br /><br />
	13. கர்த்தர் என்னை நோக்கி: அதைக் குயவனிடத்தில் எறிந்துவிடு என்றார்; இதுவே நான் அவர்களால் மதிக்கப்பட்ட மேன்மையான மதிப்பு; நான் அந்த முப்பது வெள்ளிக்காசை எடுத்து, அவைகளைக் குயவனுக்கென்று கர்த்தருடைய ஆலயத்திலே எறிந்துவிட்டேன்.
	<br /><br />
	14. நான் யூதாவுக்கும் இஸ்ரவேலுக்கும் இருக்கிற சகோதரக்கட்டை அற்றுப்போகப்பண்ணும்படிக்கு, நிக்கிரகம் என்னப்பட்ட என் இரண்டாம் கோலையும் முறித்தேன்.
	<br /><br />
	15. கர்த்தர் என்னை நோக்கி: நீ மதியற்ற ஒரு மேய்ப்பனுடைய ஆயுதங்களை இன்னும் எடுத்துக்கொள்.
	<br /><br />
	16. இதோ, நான் தேசத்திலே ஒரு மேய்ப்பனை எழும்பப்பண்ணுவேன்; அவன் அதமாகிறவைகளைப் பராமரிக்காமலும், சிதறுண்டதைத் தேடாமலும், நொறுங்குண்டதைக் குணமாக்காமலும், இளைத்திருக்கிறதை ஆதரிக்காமலும், கொழுத்ததின் மாம்சத்தைத் தின்று, அவைகளுடைய குளம்புகளை உடைத்துப்போடுவான்.
	<br /><br />
	17. மந்தையைக் கைவிடுகிற அபத்தமான மேய்ப்பனுக்கு ஐயோ! பட்டயம் அவன் புயத்தின்மேலும் அவன் வலதுகண்ணின்மேலும் வரும்; அவன் புயமுழுதும் சூம்பிப்போம்; அவன் வலதுகண் முற்றிலும் இருள் அடையும் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah11