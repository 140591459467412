import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Haggai1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆகாய் 1 </title>
	<meta
          name="description"
          content="Haggai 1 | ஆகாய் 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ராஜாவாகிய தரியு அரசாண்ட இரண்டாம் வருஷம் ஆறாம் மாதம் முதலாந்தேதியிலே, கர்த்தருடைய வார்த்தை ஆகாய் என்னும் தீர்க்கதரிசியின் மூலமாய் செயல்த்தியேலின் குமாரனாகிய செருபாபேல் என்னும் யூதாவின் தலைவனுக்கும், யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனுக்கும் உண்டாகி, அவர் சொன்னது என்னவென்றால்:
	<br /><br />
	2. இந்த ஜனங்கள் கர்த்தருடைய ஆலயத்தைக் கட்டுகிறதற்கு ஏற்றகாலம் இன்னும் வரவில்லை என்கிறார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. ஆனாலும் ஆகாய் என்னும் தீர்க்கதரிசியின் மூலமாய்க் கர்த்தருடைய வார்த்தை உண்டாகி, அவர் சொல்லுகிறார்:
	<br /><br />
	4. இந்த வீடு பாழாய்க் கிடக்கும்போது, நீங்கள் மச்சுப்பாவப்பட்ட உங்கள் வீடுகளில் குடியிருக்கும்படியான காலம் இதுவோ?
	<br /><br />
	5. இப்போதும் சேனைகளின் கர்த்தர் சொல்லுகிறார்: உங்கள் வழிகளைச் சிந்தித்துப்பாருங்கள்.
	<br /><br />
	6. நீங்கள் திரளாய் விதைத்தும் கொஞ்சமாய் அறுத்துக்கொண்டுவருகிறீர்கள்; நீங்கள் புசித்தும் திருப்தியாகவில்லை; குடித்தும் பரிபூரணமடையவில்லை; நீங்கள் வஸ்திரம் உடுத்தியும் ஒருவனுக்கும் குளிர்விடவில்லை; கூலியைச் சம்பாதிக்கிறவன் பொத்தலான பையிலே போடுகிறவனாய் அதைச் சம்பாதிக்கிறான்.
	<br /><br />
	7. உங்கள் வழிகளைச் சிந்தித்துப் பாருங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	8. நீங்கள் மலையின்மேல் ஏறிப்போய், மரங்களை வெட்டிக்கொண்டுவந்து, ஆலயத்தைக் கட்டுங்கள்; அதின்பேரில் நான் பிரியமாயிருப்பேன், அதினால் என் மகிமை விளங்கும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. அதிகமாய் வருமென்று நீங்கள் எதிர்பார்த்திருந்தும், இதோ, கொஞ்சம் கிடைத்தது; நீங்கள் அறுத்து வீட்டுக்குக் கொண்டுவந்தும், நான் அதை ஊதிப்போடுகிறேன்; எதினிமித்தமென்றால், என் வீடு பாழாய்க்கிடக்கும்போது, நீங்கள் எல்லாரும் அவனவன் தன் தன் வீட்டிற்கு ஓடிப்போகிறீர்களே, இதினிமித்தமே என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. ஆதலால் உங்கள்மேல் இருக்கிற வானம் பனியைப் பெய்யாமலும், பூமி பலனைக் கொடாமலும் போயிற்று.
	<br /><br />
	11. நான் நிலத்தின்மேலும், மலைகளின்மேலும், தானியத்தின்மேலும், புது திராட்சரசத்தின்மேலும், எண்ணெயின்மேலும், பூமியில் விளைகிற எல்லாவற்றின்மேலும், மனுஷரின்மேலும், மிருகங்களின்மேலும், கைப்பாடு அனைத்தின்மேலும் வறட்சியை வருவித்தேன் என்றார்.
	<br /><br />
	12. அப்பொழுது செயல்த்தியேலின் குமாரனாகிய செருபாபேலும், யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனும், ஜனத்தில் மீதியான அனைவரும் தங்கள் தேவனாகிய கர்த்தருடைய சத்தத்துக்கும், தங்கள் தேவனாகிய கர்த்தர் அனுப்பின ஆகாய் என்னும் தீர்க்கதரிசியினுடைய வார்த்தைகளுக்கும் செவிகொடுத்தார்கள், ஜனங்கள் கர்த்தருக்கு முன்பாகப் பயந்திருந்தார்கள்.
	<br /><br />
	13. அப்பொழுது கர்த்தருடைய தூதனாகிய ஆகாய், கர்த்தர் தூதனுப்பிய வார்த்தையின்படி ஜனங்களை நோக்கி: நான் உங்களோடே இருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார் என்றான்.
	<br /><br />
	14. பின்பு கர்த்தர் செயல்த்தியேலின் குமாரனாகிய செருபாபேல் என்னும் யூதாவின் தலைவனுடைய ஆவியையும், யோத்சதாக்கின் குமாரனாகிய யோசுவா என்னும் பிரதான ஆசாரியனுடைய ஆவியையும், ஜனத்தில் மீதியான எல்லாருடைய ஆவியையும் எழுப்பினார்; அவர்கள் வந்து, தங்கள் தேவனாகிய சேனைகளுடைய கர்த்தரின் ஆலயத்திலே வேலைசெய்தார்கள்.
	<br /><br />
	15. தரியு ராஜாவின் இரண்டாம் வருஷம் ஆறாம் மாதம் இருபத்துநாலாந்தேதியிலே இது நடந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Haggai1