import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 18 </title>
	<meta
          name="description"
          content="Jeremiah 18 | எரேமியா 18 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தரால் எரேமியாவுக்கு உண்டான வசனம்:
	<br /><br />
	2. நீ எழுந்து, குயவன் வீட்டிற்குப்போ; அங்கே என் வார்த்தைகளை உனக்குத் தெரிவிப்பேன் என்றார்.
	<br /><br />
	3. அப்படியே நான் குயவன் வீட்டிற்குப் போனேன்; இதோ, அவன் திரிகையினாலே வனைந்துகொண்டிருந்தான்.
	<br /><br />
	4. குயவன் வனைந்துகொண்டிருந்த மண்பாண்டம் அவன் கையிலே கெட்டுப்போயிற்று; அப்பொழுது அதைத் திருத்தமாய்ச் செய்யும்படிக்கு, தன் பார்வைக்குச் சரியாய்க் கண்டபடி குயவன் அதைத் திரும்ப வேறேபாண்டமாக வனைந்தான்.
	<br /><br />
	5. அப்பொழுது கர்த்தருடைய வசனம் எனக்கு உண்டாகி, அவர்:
	<br /><br />
	6. இஸ்ரவேல் குடும்பத்தாரே, இந்தக் குயவன் செய்ததுபோல நான் உங்களுக்குச் செய்யக்கூடாதோ என்று கர்த்தர் சொல்லுகிறார்; இதோ, இஸ்ரவேல் வீட்டாரே, களிமண் குயவன் கையில் இருக்கிறதுபோல நீங்கள் என் கையில் இருக்கிறீர்கள்.
	<br /><br />
	7. பிடுங்குவேன், இடிப்பேன், அழிப்பேன் என்று நான் ஒரு ஜாதிக்கு விரோதமாகவும், ஒரு ராஜ்யத்துக்கு விரோதமாகவும் சொன்னமாத்திரத்தில்,
	<br /><br />
	8. நான் விரோதமாய்ப் பேசின அந்த ஜாதியார் தங்கள் தீங்கைவிட்டுத் திரும்பினால், நானும் அவர்களுக்குச் செய்ய நினைத்த தீங்கைச் செய்யாதபடிக்கு, மனம் மாறுவேன்.
	<br /><br />
	9. கட்டுவேன், நாட்டுவேன் என்றும், ஒரு ஜாதியையும் ஒரு ராஜ்யத்தையும் குறித்து நான் சொல்லுகிறதுமுண்டு.
	<br /><br />
	10. அவர்கள் என் சத்தத்தைக்கேளாமல், என் பார்வைக்குப் பொல்லாப்பானதைச் செய்வார்களானால், நானும் அவர்களுக்கு அருள்செய்வேன் என்று சொன்ன நன்மையைச் செய்யாதபடிக்கு, மனம் மாறுவேன்.
	<br /><br />
	11. இப்பொழுதும், நீ யூதாவின் மனுஷரையும் எருசலேமின் குடிகளையும் நோக்கி: இதோ, நான் உங்களுக்கு விரோதமாக ஒரு தீங்கை உருப்படுத்தி, உங்களுக்கு விரோதமாக ஒரு காரியத்தை யோசிக்கிறேன்; ஆகையால், உங்களில் ஒவ்வொருவரும் தன் தன் பொல்லாத வழியைவிட்டுத் திரும்பி, உங்கள் வழிகளையும், உங்கள் கிரியைகளையும் சீர்ப்படுத்துங்களென்று கர்த்தர் உரைக்கிறார் என்று சொல்.
	<br /><br />
	12. ஆனாலும் அவர்கள்: அது கூடாதகாரியம், நாங்கள் எங்கள் யோசனைகளின்படியே நடந்து, அவரவர் தம்தம் பொல்லாத இருதயத்தினுடைய கடினத்தின்படியே செய்வோம் என்கிறார்கள்.
	<br /><br />
	13. ஆகையால் கர்த்தர் சொல்லுகிறார்: இப்படிப்பட்டவைகளைக் கேள்விப்பட்டவன் யார் என்று புறஜாதிகளுக்குள்ளே விசாரித்துப்பாருங்கள்; மிகவும் திடுக்கிடத்தக்க காரியத்தை இஸ்ரவேல் என்னும் கன்னிகை செய்கிறாள்.
	<br /><br />
	14. லீபனோனின் உறைந்த மழை வயல்வெளியின் கன்மலையிலிருந்து அற்றுப்போகிறதுண்டோ? ஓடிவருகிற அந்நியதேசத்துக் குளிர்ந்த தண்ணீர்கள் வடிந்துபோகிறதுண்டோ?
	<br /><br />
	15. என் ஜனங்களோ என்னை மறந்து, மாயையான விக்கிரகங்களுக்குத் தூபங்காட்டுகிறார்கள்; செப்பனிடப்படாத பாதைகளிலும் வழியிலும் அவர்கள் நடக்கும்படி, அவைகள் அவர்களைப் பூர்வ பாதைகளாகிய அவர்களுடைய வழிகளிலிருந்து இடறும்படி செய்கிறது.
	<br /><br />
	16. நான் அவர்களுடைய தேசத்தைப் பாழாக்கவும், என்றென்றைக்கும் ஈசலிட்டு நிந்திக்கும் நிந்தையாக்கவும் செய்யும்படி இப்படிச் செய்கிறார்கள்; அதைக் கடந்துபோகிறவன் எவனும் பிரமித்து, தன் தலையைத் துலுக்குவான்.
	<br /><br />
	17. கொண்டல்காற்றுப் பறக்கடிக்குமாப்போல் நான் அவர்களை அவர்கள் சத்துருக்களுக்கு முன்பாகப் பறக்கடிப்பேன்; அவர்களுடைய ஆபத்தின் நாளிலே என் முகத்தையல்ல, என் முதுகை அவர்களுக்குக் காட்டுவேன் என்று சொல் என்றார்.
	<br /><br />
	18. அதற்கு அவர்கள்: எரேமியாவுக்கு விரோதமாக ஆலோசனை செய்வோம் வாருங்கள்; ஆசாரியரிடத்திலே வேதமும், ஞானிகளிடத்திலே ஆலோசனையும், தீர்க்கதரிசிகளிடத்திலே வசனமும் ஒழிந்துபோவதில்லை. இவன் வார்த்தைகளை நாம் கவனியாமல், இவனை நாவினாலே வெட்டிப்போடுவோம் வாருங்கள் என்றார்கள்.
	<br /><br />
	19. கர்த்தாவே, நீர் என்னைக் கவனித்து, என்னோடே வழக்காடுகிறவர்களின் சத்தத்தைக் கேளும்.
	<br /><br />
	20. நன்மைக்குத் தீமையைச் சரிக்கட்டலாமோ? என் ஆத்துமாவுக்குப் படுகுழியை வெட்டுகிறார்களே; உம்முடைய உக்கிரத்தை அவர்களைவிட்டுத் திருப்பும்படிக்கு நான் அவர்களுக்காக நன்மையைப் பேச உமக்கு முன்பாக நின்றதை நினைத்தருளும்.
	<br /><br />
	21. ஆகையால், அவர்களுடைய பிள்ளைகளைப் பஞ்சத்துக்கு ஒப்புக்கொடுத்து, அவர்களைப் பட்டயத்துக்கு இரையாக்கிவிடும்; அவர்கள் மனைவிகள் பிள்ளைகளற்றவர்களும் விதவைகளுமாகி, அவர்கள் புருஷர்கள் கொலைசெய்யப்பட்டு, அவர்கள் வாலிபர்கள் யுத்தத்திலே பட்டயவெட்டால் மடியக்கடவர்கள்.
	<br /><br />
	22. நீர் சடிதியில் அவர்கள்மேல் இராணுவத்தை வரப்பண்ணுகையால், கூக்குரல் அவர்கள் வீடுகளிலிருந்து கேட்கப்படக்கடவது; என்னைப் பிடிக்கப் படுகுழியை வெட்டி, என் கால்களுக்குக் கண்ணிகளை வைத்தார்களே.
	<br /><br />
	23. ஆனாலும் கர்த்தாவே, அவர்கள் எனக்கு விரோதமாய்ச் செய்யும் கொலைபாதக யோசனையையெல்லாம் நீர் அறிவீர்; அவர்களுடைய அக்கிரமத்தை உமது கண்ணுக்கு மறைவாக மூடாமலும், அவர்கள் பாவத்தைக் குலைக்காமலும் இருப்பீராக; அவர்கள் உமக்கு முன்பாகக் கவிழ்க்கப்படக்கடவர்கள்; உமது கோபத்தின் காலத்திலே இப்படி அவர்களுக்குச் செய்யும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah18