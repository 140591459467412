import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah42 = () => {
  const verseNumber = 42;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 42 </title>
	<meta
          name="description"
          content="Isaiah 42 | ஏசாயா 42 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, நான் ஆதரிக்கிற என் தாசன், நான் தெரிந்துகொண்டவரும், என் ஆத்துமாவுக்குப் பிரியமானவரும் இவரே; என் ஆவியை அவர்மேல் அமரப்பண்ணினேன்; அவர் புறஜாதிகளுக்கு நியாயத்தை வெளிப்படுத்துவார்.
	<br /><br />
	2. அவர் கூக்குரலிடவுமாட்டார், தம்முடைய சத்தத்தை உயர்த்தவும் அதை வீதியிலே கேட்கப்பண்ணவுமாட்டார்.
	<br /><br />
	3. அவர் நெரிந்தநாணலை முறியாமலும், மங்கியெரிகிற திரியை அணையாமலும், நியாயத்தை உண்மையாக வெளிப்படுத்துவார்.
	<br /><br />
	4. அவர் நியாயத்தைப் பூமியிலே நிலைப்படுத்துமட்டும் இளக்கரிப்பதுமில்லை, பதறுவதுமில்லை; அவருடைய வேதத்துக்குத் தீவுகள் காத்திருக்கும்.
	<br /><br />
	5. வானங்களைச் சிருஷ்டித்து, அவைகளை விரித்து, பூமியையும், அதிலே உற்பத்தியாகிறவைகளையும் பரப்பினவரும், அதில் இருக்கிற ஜனத்துக்குச் சுவாசத்தையும், அதில் நடமாடுகிறவர்களுக்கு ஆவியையும் கொடுக்கிறவருமான கர்த்தராகிய தேவன் சொல்லுகிறதாவது:
	<br /><br />
	6. நீர் குருடருடைய கண்களைத் திறக்கவும், கட்டுண்டவர்களைக் காவலிலிருந்தும், இருளில் இருக்கிறவர்களைச் சிறைச்சாலையிலிருந்தும் விடுவிக்கவும்,
	<br /><br />
	7. கர்த்தராகிய நான் நீதியின்படி உம்மை அழைத்து, உம்முடைய கையைப் பிடித்து, உம்மைத் தற்காத்து, உம்மை ஜனத்திற்கு உடன்படிக்கையாகவும், ஜாதிகளுக்கு ஒளியாகவும் வைக்கிறேன்.
	<br /><br />
	8. நான் கர்த்தர், இது என் நாமம்; என் மகிமையை வேறொருவனுக்கும், என் துதியை விக்கிரகங்களுக்கும் கொடேன்.
	<br /><br />
	9. பூர்வகாலத்தில் தெரிவிக்கப்பட்டவைகள், இதோ, நிறைவேறலாயின; புதியவைகளையும் நானே அறிவிக்கிறேன்; அவைகள் தோன்றாததற்கு முன்னே, அவைகளை உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	10. சமுத்திரத்தில் யாத்திரை பண்ணுகிறவர்களே, அதிலுள்ளவைகளே, தீவுகளே, அவைகளின் குடிகளே, கர்த்தருக்குப் புதுப்பாட்டைப் பாடுங்கள்; பூமியின் கடையாந்தரத்திலிருந்து அவருடைய துதியைப் பாடுங்கள்.
	<br /><br />
	11. வனாந்தரமும், அதின் ஊர்களும், கேதாரியர் குடியிருக்கிற கிராமங்களும் உரத்த சத்தமிடக்கடவது; கன்மலைகளிலே குடியிருக்கிறவர்கள் கெம்பீரித்து, பர்வதங்களின் கொடுமுடியிலிருந்து ஆர்ப்பரிப்பார்களாக.
	<br /><br />
	12. கர்த்தருக்கு மகிமையைச் செலுத்தி, அவர் துதியைத் தீவுகளில் அறிவிப்பார்களாக.
	<br /><br />
	13. கர்த்தர் பராக்கிரமசாலியைப்போல் புறப்பட்டு, யுத்தவீரனைப்போல் வைராக்கியமூண்டு, முழங்கிக் கெர்ச்சித்து, தம்முடைய சத்துருக்களை மேற்கொள்ளுவார்.
	<br /><br />
	14. நான் வெகுகாலம் மவுனமாயிருந்தேன்; சும்மாயிருந்து எனக்குள்ளே அடக்கிக்கொண்டிருந்தேன்; இப்பொழுது பிள்ளை பெறுகிறவளைப்போலச் சத்தமிட்டு, அவர்களைப் பாழாக்கி விழுங்குவேன்.
	<br /><br />
	15. நான் மலைகளையும் குன்றுகளையும் பாழாக்கி, அவைகளிலுள்ள பூண்டுகளையெல்லாம் வாடப்பண்ணி, ஆறுகளைத் திட்டுகளாக்கி, ஏரிகளை வற்றிப்போகப்பண்ணுவேன்.
	<br /><br />
	16. குருடரை அவர்கள் அறியாத வழியிலே நடத்தி, அவர்களுக்குத் தெரியாத பாதைகளில் அவர்களை அழைத்துக்கொண்டுவந்து, அவர்களுக்கு முன்பாக இருளை வெளிச்சமும், கோணலைச் செவ்வையுமாக்குவேன்; இந்தக் காரியங்களை நான் அவர்களுக்குச் செய்து, அவர்களைக் கைவிடாதிருப்பேன்.
	<br /><br />
	17. சித்திரவேலையான விக்கிரகங்களை நம்பி, வார்ப்பிக்கப்பட்ட சுரூபங்களை நோக்கி: நீங்கள் எங்கள் தேவர்கள் என்று சொல்லுகிறவர்கள் பின்னிடைந்து மிகவும் வெட்கப்படுவார்கள்.
	<br /><br />
	18. செவிடரே, கேளுங்கள்; குருடரே, நீங்கள் காணும்படி நோக்கிப்பாருங்கள்.
	<br /><br />
	19. என் தாசனையல்லாமல் குருடன் யார்? நான் அனுப்பிய தூதனையல்லாமல் செவிடன் யார்? உத்தமனையல்லாமல் குருடன் யார்? கர்த்தருடைய ஊழியக்காரனையல்லாமல் அந்தகன் யார்?
	<br /><br />
	20. நீ அநேக காரியங்களைக் கண்டும் கவனியாதிருக்கிறாய்; அவனுக்குச் செவிகளைத் திறந்தாலும் கேளாதே போகிறான்.
	<br /><br />
	21. கர்த்தர் தமது நீதியினிமித்தம் அவன்மேல் பிரியம் வைத்திருந்தார்; அவர் வேதத்தை முக்கியப்படுத்தி அதை மகிமையுள்ளதாக்குவார்.
	<br /><br />
	22. இந்த ஜனமோ கொள்ளையிடப்பட்டும், சூறையாடப்பட்டும் இருக்கிறார்கள்; அவர்கள் அனைவரும் கெபிகளிலே அகப்பட்டு, காவலறைகளிலே அடைக்கப்பட்டிருக்கிறார்கள்; தப்புவிப்பார் இல்லாமல் கொள்ளையாகி, விட்டுவிடு என்பார் இல்லாமல் சூறையாவார்கள்.
	<br /><br />
	23. உங்களில் இதற்குச் செவிகொடுத்துப் பின்வருகிறதைக் கவனித்துக் கேட்கிறவன் யார்?
	<br /><br />
	24. யாக்கோபைச் சூறையிட்டு இஸ்ரவேலைக் கொள்ளைக்காரருக்கு ஒப்புக்கொடுக்கிறவர் யார்? அவர்கள் பாவஞ்செய்து விரோதித்த கர்த்தர் அல்லவோ? அவருடைய வழிகளில் நடக்க மனதாயிராமலும், அவருடைய வேதத்துக்குச் செவிகொடாமலும் போனார்களே.
	<br /><br />
	25. இவர்கள்மேல் அவர் தமது கோபத்தின் உக்கிரத்தையும், யுத்தத்தின் வலிமையையும் வரப்பண்ணி, அவர்களைச்சூழ அக்கினிஜுவாலைகளைக் கொளுத்தியிருந்தும் உணராதிருந்தார்கள்; அது அவர்களைத் தகித்தும், அதை மனதிலே வைக்காதேபோனார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah42