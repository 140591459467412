import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 4 </title>
	<meta
          name="description"
          content="Micah 4 | மீகா 4 | Meega 4|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆனாலும், கடைசி நாட்களில் கர்த்தருடைய ஆலயமாகிய பர்வதம் பர்வதங்களின் கொடுமுடியில் ஸ்தாபிக்கப்பட்டு, மலைகளுக்கு மேலாய் உயர்த்தப்பட்டிருக்கும், எல்லா ஜாதிகளும் அதினிடத்திற்கு ஓடிவருவார்கள்.
	<br /><br />
	2. திரளான ஜாதிகள் புறப்பட்டுவந்து: நாம் கர்த்தரின் பர்வதத்துக்கும், யாக்கோபின் தேவனுடைய ஆலயத்துக்கும் போவோம் வாருங்கள்; அவர் தமது வழிகளை நமக்குப் போதிப்பார், நாம் அவர் பாதைகளில் நடப்போம் என்பார்கள்; ஏனெனில் சீயோனிலிருந்து வேதமும், எருசலேமிலிருந்து கர்த்தரின் வசனமும் வெளிப்படும்.
	<br /><br />
	3. அவர் திரளான ஜனங்களுக்குள் நியாயந்தீர்த்து, தூரத்திலுள்ள பலத்த ஜாதிகளைக் கடிந்துகொள்ளுவார்; அப்பொழுது அவர்கள் தங்கள் பட்டயங்களை மண்வெட்டிகளாகவும், தங்கள் ஈட்டிகளை அறிவாள்களாகவும் அடிப்பார்கள்; ஒரு ஜாதிக்கு விரோதமாய் மறுஜாதி பட்டயம் எடுப்பதில்லை; இனி அவர்கள் யுத்தத்தைக் கற்பதுமில்லை.
	<br /><br />
	4. அவனவன் தன்தன் திராட்சச்செடியின் நிழலிலும், தன்தன் அத்திமரத்தின் நிழலிலும் பயப்படுத்துவார் இல்லாமல் உட்காருவான்; சேனைகளுடைய கர்த்தரின் வாய் இதைச் சொல்லிற்று.
	<br /><br />
	5. சகல ஜனங்களும் தங்கள் தங்கள் தேவனுடைய நாமத்தைப் பற்றிக்கொண்டு நடப்பார்கள்; நாங்களும் எங்கள் தேவனாகிய கர்த்தருடைய நாமத்தைப் பற்றிக்கொண்டு என்றென்றைக்குமுள்ள சதாகாலங்களிலும் நடப்போம்.
	<br /><br />
	6. கர்த்தர் சொல்லுகிறது என்னவென்றால்: அந்நாளிலே நான் நொண்டியானவளைச் சேர்த்து, தள்ளுண்டவளையும் தீங்கு அனுபவித்தவளையும் கூட்டிக்கொண்டு,
	<br /><br />
	7. நொண்டியானவளை மீதியான ஜனமாகவும், தூரமாய்த் தள்ளுண்டுபோனவளைப் பலத்த ஜாதியாகவும் வைப்பேன்; அவர்கள்பேரில் கர்த்தர் சீயோன் பர்வதத்திலே இதுமுதல் என்றென்றைக்கும் ராஜாவாயிருப்பார்.
	<br /><br />
	8. மந்தையின் துருக்கமே, சீயோன் குமாரத்தியின் அரணே, முந்தின ஆளுகை உன்னிடத்தில் வரும்; ராஜரிகம் எருசலேம் குமாரத்தியினிடத்தில் வரும்.
	<br /><br />
	9. இப்போதும் நீ சத்தமிட்டுக் கதறுவானேன்? ராஜாவானவர் உன்னிடத்தில் இல்லையோ? உன் ஆலோசனைக்காரர் அழிந்துபோனார்களோ? பிரசவிக்கிற ஸ்திரீக்கு ஒத்த வேதனை உனக்கு உண்டாகும்.
	<br /><br />
	10. சீயோன் குமாரத்தியே, பிரசவ ஸ்திரீயைப்போல அம்பாயப்பட்டு வேதனைப்படு; நீ இப்போது நகரத்திலிருந்து புறப்பட்டு, வெளிகளில் தங்கி, பாபிலோன்வரைக்கும் போவாய், அங்கே விடுவிக்கப்படுவாய்; அங்கே கர்த்தர் உன்னை உன் சத்துருக்களின் கைக்கு நீங்கலாக்கி மீட்பார்.
	<br /><br />
	11. சீயோன் தீட்டுப்படுவாளாக, எங்கள் கண் அவளைக் காண்பதாக என்று சொல்லி, அநேக ஜாதியார் உனக்கு விரோதமாகக் கூடியிருக்கிறார்கள்.
	<br /><br />
	12. ஆனாலும் அவர்கள் கர்த்தருடைய நினைவுகளை அறியாமலும், அவருடைய யோசனையை உணராமலும் இருக்கிறார்கள்; அவர் அரிக்கட்டுகளைப்போல அவர்களைக் களத்திலே சேர்ப்பார்.
	<br /><br />
	13. சீயோன் குமாரத்தியே, நீ எழுந்து போரடி; நான் உன் கொம்புகளை இரும்பும், உன் குளம்புகளை வெண்கலமுமாக்குவேன்; நீ அநேக ஜனங்களை நொறுக்கிப்போடுவாய்; அவர்கள் தேடிச் சேர்த்ததை நீ கர்த்தருக்கென்றும், அவர்களுடைய ஆஸ்தியைப் பூமிக்கெல்லாம் ஆண்டவராயிருக்கிறவருக்கென்றும் நியமிப்பாய்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah4