import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah35 = () => {
  const verseNumber = 35;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 35 </title>
	<meta
          name="description"
          content="Jeremiah 35 | எரேமியா 35 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோசியாவின் குமாரனாகிய யோயாக்கீம் என்னும் யூதா ராஜாவின் நாட்களில் எரேமியாவுக்குக் கர்த்தரால் உண்டான வார்த்தை:
	<br /><br />
	2. நீ ரேகாபியருடைய வீட்டுக்குப் போய், அவர்களோடே பேசி, அவர்களைக் கர்த்தருடைய ஆலயத்தின் அறைகளில் ஒன்றிலே அழைத்துவந்து, அவர்களுக்குத் திராட்சரசம் குடிக்கக்கொடு என்றார்.
	<br /><br />
	3. அப்பொழுது நான் அபசினியாவின் குமாரனாகிய எரேமியாவுக்கு மகனான யசினியாவையும், அவனுடைய சகோதரரையும், அவனுடைய குமாரர் எல்லாரையும், ரேகாபியருடைய குடும்பத்தார் அனைவரையும் அழைத்து;
	<br /><br />
	4. கர்த்தருடைய ஆலயத்திலே பிரபுக்களுடைய அறையின் அருகேயும், வாசலைக்காக்கிற சல்லூமின் குமாரனாகிய மாசெயாவினுடைய அறையின்மேலுமுள்ள இத்தலியாவின் குமாரனும் தேவனுடைய மனுஷனுமாகிய ஆனான் என்னும் புத்திரருடைய அறையிலே அவர்களைக் கூட்டிக்கொண்டுவந்து,
	<br /><br />
	5. திராட்சரசத்தினால் நிரப்பப்பட்ட குடங்களையும் கிண்ணங்களையும் ரேகாபியருடைய குடும்பத்தைச் சேர்ந்த புத்திரரின் முன்னே வைத்து, அவர்களை நோக்கி: திராட்சரசம் குடியுங்கள் என்றேன்.
	<br /><br />
	6. அதற்கு அவர்கள்: நாங்கள் திராட்சரசம் குடிக்கிறதில்லை; ஏனென்றால், ரேகாபின் குமாரனாகிய எங்களுடைய தகப்பன் யோனதாப், நீங்கள் பரதேசிகளாய்த் தங்குகிற தேசத்தில் நீடித்திருக்கும்படிக்கு,
	<br /><br />
	7. நீங்களும் உங்கள் பிள்ளைகளும் என்றென்றைக்கும் திராட்சரசம் குடியாமலும், வீட்டைக் கட்டாமலும், விதையை விதையாமலும், திராட்சத்தோட்டத்தை நாட்டாமலும், அதைக் கையாளாமலும், உங்களுடைய எல்லா நாட்களிலும் கூடாரங்களிலே குடியிருப்பீர்களாக என்று எங்களுக்குக் கட்டளையிட்டார்.
	<br /><br />
	8. அப்படியே எங்களுடைய எல்லா நாட்களிலும் நாங்களும் எங்கள் ஸ்திரீகளும் எங்கள் குமாரரும் எங்கள் குமாரத்திகளும் திராட்சரசம் குடியாமலும்,
	<br /><br />
	9. நாங்கள் குடியிருக்க வீடுகளைக்கட்டாமலும், ரேகாபின் குமாரனாகிய எங்களுடைய தகப்பன் யோனதாபின் சத்தத்துக்குக் கீழ்ப்படிந்திருக்கிறோம்; எங்களுக்குத் திராட்சத்தோட்டமும் வயலும் விதைப்பாடுமில்லை.
	<br /><br />
	10. நாங்கள் கூடாரங்களில் குடியிருந்து, எங்கள் தகப்பனாகிய யோனதாப் எங்களுக்குக் கட்டளையிட்டபடியெல்லாம் கீழ்ப்படிந்து செய்துவந்தோம்.
	<br /><br />
	11. ஆனாலும் பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் இந்தத் தேசத்தில் வந்தபோது, நாம் கல்தேயருடைய இராணுவத்துக்கும் சீரியருடைய இராணுவத்துக்கும் தப்பும்படி எருசலேமுக்குப் போவோம் வாருங்கள் என்று சொன்னோம்; அப்படியே எருசலேமில் தங்கியிருக்கிறோம் என்றார்கள்.
	<br /><br />
	12. அப்பொழுது கர்த்தருடைய வார்த்தை எரேமியாவுக்கு உண்டாகி, அவர்:
	<br /><br />
	13. இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால், நீ போய், யூதாவின் மனுஷரையும் எருசலேமின் குடிகளையும் நோக்கி: நீங்கள் என் வார்த்தைகளைக் கேட்டு, புத்தியை ஏற்றுக்கொள்ளுகிறதில்லையோ என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. திராட்சரசம் குடியாதபடிக்கு, ரேகாபின் குமாரனாகிய யோனதாப் தன் புத்திரருக்குக் கட்டளையிட்ட வார்த்தைகள் கைக்கொள்ளப்பட்டுவருகிறது; அவர்கள் இந்நாள்மட்டும் அதைக் குடியாமல், தங்கள் தகப்பனுடைய கட்டளைக்குக் கீழ்ப்படிகிறார்கள்; ஆனாலும் நான் உங்களுக்கு ஏற்கனவே சொல்லிக்கொண்டேயிருந்தும், எனக்குக் கீழ்ப்படியாமற்போனீர்கள்.
	<br /><br />
	15. நீங்கள் அந்நிய தேவர்களைச் சேவிக்கும்படி அவர்களைப் பின்பற்றாமல், அவனவன் தன் பொல்லாத வழியை விட்டுத் திரும்பி, உங்கள் நடக்கையைச் சீர்திருத்துங்கள், அப்பொழுது உங்களுக்கும் உங்கள் பிதாக்களுக்கும் நான் கொடுத்த தேசத்திலே குடியிருப்பீர்கள் என்று சொல்லி, தீர்க்கதரிசிகளாகிய என் ஊழியக்காரரையெல்லாம் நான் உங்களிடத்துக்கு ஏற்கனவே அனுப்பிக்கொண்டிருந்தும், நீங்கள் உங்கள் செவியைச் சாயாமலும் எனக்குக் கீழ்ப்படியாமலும்போனீர்கள்.
	<br /><br />
	16. இப்போதும், ரேகாபின் குமாரனாகிய யோனதாபின் புத்திரர் தங்கள் தகப்பன் தங்களுக்குக் கட்டளையிட்ட கற்பனையைக் கைக்கொண்டிருக்கும்போது, இந்த ஜனங்கள் எனக்குக் கீழ்ப்படியாமற்போனபடியினாலும்,
	<br /><br />
	17. இதோ, நான் அவர்களிடத்தில் பேசியும் அவர்கள் கேளாமலும், நான் அவர்களை நோக்கிக் கூப்பிட்டும் அவர்கள் மறுஉத்தரவு கொடாமலும் போனபடியினாலும், யூதாவின்மேலும் எருசலேமின் குடிகள் எல்லாரின்மேலும் நான் அவர்களுக்கு விரோதமாகச் சொன்ன எல்லாத் தீங்கையும் வரப்பண்ணுவேன் என்று இஸ்ரவேலின் தேவனும் சேனைகளின் தேவனுமாகிய கர்த்தர் சொல்லுகிறார் என்று சொல் என்றார்.
	<br /><br />
	18. பின்னும் எரேமியா ரேகாபியருடைய குடும்பத்தாரை நோக்கி: நீங்கள் உங்கள் தகப்பனாகிய யோனதாபின் கட்டளைக்குக் கீழ்ப்படிந்து, அவனுடைய கற்பனைகளையெல்லாம் கைக்கொண்டு, அவன் உங்களுக்குக் கட்டளையிட்டபடியெல்லாம் செய்துவந்தீர்களென்று, இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	19. ஆதலால் சகல நாட்களிலும் எனக்கு முன்பாக நிற்கத்தக்க புருஷன் ரேகாபின் குமாரனாகிய யோனதாபுக்கு இல்லாமற்போவதில்லையென்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah35