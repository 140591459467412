import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneJohn4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title> Need For Sound Doctrine 1 John 4 Tamil Bible | 1 யோவான் - 4 </title>
	<meta
          name="description"
          content="The Need for Sound Doctrine | 1 -John in Tamil Bible Verse | 1-யோவான்அதிகாரம் - 4 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible "
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஒலிக் கோட்பாட்டின் தேவை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பிரியமானவர்களே, உலகத்தில் அநேகங் கள்ளத்தீர்க்கதரிசிகள் தோன்றியிருப்பதினால், நீங்கள் எல்லா ஆவிகளையும் நம்பாமல், அந்த ஆவிகள் தேவனால் உண்டானவைகளோ என்று சோதித்தறியுங்கள்.
	<br /><br />
	2. தேவஆவியை நீங்கள் எதினாலே அறியலாமென்றால்: மாம்சத்தில் வந்த இயேசுகிறிஸ்துவை அறிக்கைபண்ணுகிற எந்த ஆவியும் தேவனால் உண்டாயிருக்கிறது.
	<br /><br />
	3. மாம்சத்தில் வந்த இயேசுகிறிஸ்துவை அறிக்கைபண்ணாத எந்த ஆவியும் தேவனால் உண்டானதல்ல; வருமென்று நீங்கள் கேள்விப்பட்ட அந்திக்கிறிஸ்துவினுடைய ஆவி அதுவே, அது இப்பொழுதும் உலகத்தில் இருக்கிறது.
	<br /><br />
	4. பிள்ளைகளே, நீங்கள் தேவனால் உண்டாயிருந்து, அவர்களை ஜெயித்தீர்கள்; ஏனெனில் உலகத்திலிருக்கிறவனிலும் உங்களிலிருக்கிறவர் பெரியவர்.
	<br /><br />
	5. அவர்கள் உலகத்துக்குரியவர்கள், ஆகையால் உலகத்துக்குரியவைகளைப் பேசுகிறார்கள், உலகமும் அவர்களுக்குச் செவிகொடுக்கும்.
	<br /><br />
	6. நாங்கள் தேவனால் உண்டானவர்கள்; தேவனை அறிந்தவன் எங்களுக்குச் செவிகொடுக்கிறான்; தேவனால் உண்டாயிராதவன் எங்களுக்குச் செவிகொடுக்கிறதில்லை; இதினாலே சத்திய ஆவி இன்னதென்றும், வஞ்சகஆவி இன்னதென்றும் அறிந்திருக்கிறோம்.
	<br /><br />
	7. பிரியமானவர்களே, ஒருவரிலொருவர் அன்பாயிருக்கக்கடவோம்; ஏனெனில் அன்பு தேவனால் உண்டாயிருக்கிறது; அன்புள்ள எவனும் தேவனால் பிறந்து, அவரை அறிந்திருக்கிறான்.
	<br /><br />
	8. அன்பில்லாதவன் தேவனை அறியான்; தேவன் அன்பாகவே இருக்கிறார்.
	<br /><br />
	9. தம்முடைய ஒரே பேறான குமாரனாலே நாம் பிழைக்கும்படிக்குத் தேவன் அவரை இவ்வுலகத்திலே அனுப்பினதினால் தேவன் நம்மேல் வைத்த அன்பு வெளிப்பட்டது.
	<br /><br />
	10. நாம் தேவனிடத்தில் அன்புகூர்ந்ததினால் அல்ல, அவர் நம்மிடத்தில் அன்புகூர்ந்து, நம்முடைய பாவங்களை நிவிர்த்தி செய்கிற கிருபாதாரபலியாகத் தம்முடைய குமாரனை அனுப்பினதினாலே அன்பு உண்டாயிருக்கிறது.
	<br /><br />
	11. பிரியமானவர்களே, தேவன் இவ்விதமாய் நம்மிடத்தில் அன்புகூர்ந்திருக்க, நாமும் ஒருவரிடத்தில் ஒருவர் அன்புகூரக் கடனாளிகளாயிருக்கிறோம்.
	<br /><br />
	12. தேவனை ஒருவரும் ஒருபோதும் கண்டதில்லை; நாம் ஒருவரிடத்தில் ஒருவர் அன்புகூர்ந்தால் தேவன் நமக்குள் நிலைத்திருக்கிறார்; அவருடைய அன்பும் நமக்குள் பூரணப்படும்.
	<br /><br />
	13. அவர் தம்முடைய ஆவியில் நமக்குத் தந்தருளினதினாலே நாம் அவரிலும் அவர் நம்மிலும் நிலைத்திருக்கிறதை அறிந்திருக்கிறோம்.
	<br /><br />
	14. பிதாவானவர் குமாரனை உலகரட்சகராக அனுப்பினாரென்று நாங்கள் கண்டு சாட்சியிடுகிறோம்.
	<br /><br />
	15. இயேசுவானவர் தேவனுடைய குமாரனென்று அறிக்கைபண்ணுகிறவன் எவனோ அவனில் தேவன் நிலைத்திருக்கிறார், அவனும் தேவனில் நிலைத்திருக்கிறான்.
	<br /><br />
	16. தேவன் நம்மேல் வைத்திருக்கிற அன்பை நாம் அறிந்து விசுவாசித்திருக்கிறோம். தேவன் அன்பாகவே இருக்கிறார்; அன்பில் நிலைத்திருக்கிறவன் தேவனில் நிலைத்திருக்கிறான், தேவனும் அவனில் நிலைத்திருக்கிறார்.
	<br /><br />
	17. நியாயத்தீர்ப்புநாளிலே நமக்குத் தைரியமுண்டாயிருக்கத்தக்கதாக அன்பு நம்மிடத்தில் பூரணப்படுகிறது; ஏனென்றால், அவர் இருக்கிறபிரகாரமாக நாமும் இவ்வுலகத்தில் இருக்கிறோம்.
	<br /><br />
	18. அன்பிலே பயமில்லை; பூரண அன்பு பயத்தைப் புறம்பே தள்ளும்; பயமானது வேதனையுள்ளது, பயப்படுகிறவன் அன்பில் பூரணப்பட்டவன் அல்ல.
	<br /><br />
	19. அவர் முந்தி நம்மிடத்தில் அன்புகூர்ந்தபடியால் நாமும் அவரிடத்தில் அன்புகூருகிறோம்.
	<br /><br />
	20. தேவனிடத்தில் அன்புகூருகிறேனென்று ஒருவன் சொல்லியும், தன் சகோதரனைப் பகைத்தால், அவன் பொய்யன்; தான் கண்ட சகோதரனிடத்தில் அன்புகூராமலிருக்கிறவன், தான் காணாத தேவனிடத்தில் எப்படி அன்புகூருவான்?
	<br /><br />
	21. தேவனிடத்தில் அன்புகூருகிறவன் தன் சகோதரனிடத்திலும் அன்புகூரவேண்டுமென்கிற இந்தக் கற்பனையை அவராலே பெற்றிருக்கிறோம்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 1 யோவான் 4 ஒவ்வொரு ஆவியும் கடவுளிடமிருந்து வந்ததா என்பதைப் பரிசோதிக்க ஊக்குவிக்கிறது. இந்த தேவாலயத்திற்குள் ஊடுருவி, இயேசுவை அவருடைய சரியான இடத்திலிருந்து அகற்றுவதுதான் ஞானவாதம் என்று பின்னர் அறியப்பட்டது. இந்த போதனைகளை சோதிக்க யோவான் ஊக்குவிக்கிறார், சத்தியத்தின் ஆவியையும் பொய்யின் ஆவியையும் அங்கீகரிக்க கற்றுக்கொள்கிறார். ஒருவரையொருவர் நேசிப்பதை நினைவூட்டும் கியர்களை அவர் மாற்றுகிறார், இது கடவுள் இயேசுவில் நம்மீது கொண்ட அன்பை நிரூபிப்பதில் இருந்து உருவாகிறது. *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OneJohn4