import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneTimothy3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> 1 Timothy in Tamil Bible Verse | 1 தீமோத்தேயு அதிகாரம்-3 </title>
	<meta
          name="description"
          content="FIRST LETTER TO THE Timothy Chapter 3 | 1 தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* தேர்வு மேற்பார்வையாளர்கள் மற்றும் டீக்கன்கள் விஸ்லி *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


	  <p>
	1. கண்காணிப்பை விரும்புகிறவன் நல்ல வேலையை விரும்புகிறான், இது உண்மையான வார்த்தை.
	<br /><br />
	2. ஆகையால் கண்காணியானவன் குற்றஞ்சாட்டப்படாதவனும், ஒரே மனைவியை உடைய புருஷனும், ஜாக்கிரதையுள்ளவனும், தெளிந்த புத்தியுள்ளவனும், யோக்கியதையுள்ளவனும், அந்நியரை உபசரிக்கிறவனும், போதகசமர்த்தனுமாய் இருக்கவேண்டும்.
	<br /><br />
	3. அவன் மதுபானப்பிரியனும், அடிக்கிறவனும், இழிவான ஆதாயத்தை இச்சிக்கிறவனுமாயிராமல், பொறுமையுள்ளவனும், சண்டைபண்ணாதவனும், பண ஆசையில்லாதவனுமாயிருந்து,
	<br /><br />
	4. தன் சொந்தக் குடும்பத்தை நன்றாய் நடத்துகிறவனும், தன் பிள்ளைகளைச் சகல நல்லொழுக்கமுள்ளவர்களாகக் கீழ்ப்படியப்பண்ணுகிறவனுமாயிருக்கவேண்டும்.
	<br /><br />
	5. ஒருவன் தன் சொந்தக் குடும்பத்தை நடத்த அறியாதிருந்தால், தேவனுடைய சபையை எப்படி விசாரிப்பான்?
	<br /><br />
	6. அவன் இறுமாப்படைந்து, பிசாசு அடைந்த ஆக்கினையிலே விழாதபடிக்கு, நூதன சீஷனாயிருக்கக்கூடாது.
	<br /><br />
	7. அவன் நிந்தனையிலும், பிசாசின் கண்ணியிலும் விழாதபடிக்கு, புறம்பானவர்களால் நற்சாட்சி பெற்றவனாயுமிருக்கவேண்டும்.
	<br /><br />
	8. அந்தப்படியே, உதவிக்காரரும் இருநாக்குள்ளவர்களாயும், மதுபானப்பிரியராயும், இழிவான ஆதாயத்தை இச்சிக்கிறவர்களாயும் இராமல், நல்லொழுக்கமுள்ளவர்களாயும்,
	<br /><br />
	9. விசுவாசத்தின் இரகசியத்தைச் சுத்த மனச்சாட்சியிலே காத்துக்கொள்ளுகிறவர்களாயும் இருக்கவேண்டும்.
	<br /><br />
	10. மேலும், இவர்கள் முன்னதாகச் சோதிக்கப்படவேண்டும்; குற்றஞ்சாட்டப்படாதவர்களானால் உதவிக்காரராக ஊழியஞ்செய்யலாம்.
	<br /><br />
	11. அந்தப்படியே ஸ்திரீகளும் நல்லொழுக்கமுள்ளவர்களும், அவதூறுபண்ணாதவர்களும், தெளிந்த புத்தியுள்ளவர்களும், எல்லாவற்றிலேயும் உண்மையுள்ளவர்களுமாயிருக்கவேண்டும்.
	<br /><br />
	12. மேலும், உதவிக்காரரானவர்கள் ஒரே மனைவியையுடைய புருஷருமாய், தங்கள் பிள்ளைகளையும் சொந்தக் குடும்பங்களையும் நன்றாய் நடத்துகிறவர்களுமாயிருக்கவேண்டும்.
	<br /><br />
	13. இப்படி உதவிக்காரருடைய ஊழியத்தை நன்றாய்ச் செய்கிறவர்கள் தங்களுக்கு நல்ல நிலையையும், கிறிஸ்து இயேசுவைப்பற்றும் விசுவாசத்தில் மிகுந்த தைரியத்தையும் அடைவார்கள்.
	<br /><br />
	14. நான் உன்னிடத்திற்குச் சீக்கிரமாய் வருவேனென்று நம்பியிருக்கிறேன்.
	<br /><br />
	15. தாமதிப்பேனாகில், தேவனுடைய வீட்டிலே நடக்கவேண்டிய வகையை நீ அறியும்படி இவைகளை உனக்கு எழுதுகிறேன்; அந்த வீடு ஜீவனுள்ள தேவனுடைய சபையாய்ச் சத்தியத்துக்குத் தூணும் ஆதாரமுமாயிருக்கிறது.
	<br /><br />
	16. அன்றியும், தேவபக்திக்குரிய இரகசியமானது யாவரும் ஒப்புக்கொள்ளுகிறபடியே மகா மேன்மையுள்ளது. தேவன் மாம்சத்திலே வெளிப்பட்டார், ஆவியிலே நீதியுள்ளவரென்று விளங்கப்பட்டார், தேவதூதர்களால் காணப்பட்டார், புறஜாதிகளிடத்தில் பிரசங்கிக்கப்பட்டார், உலகத்திலே விசுவாசிக்கப்பட்டார், மகிமையிலே ஏறெடுத்துக்கொள்ளப்பட்டார்.
</p>


      {/* End of verse */}  

	  <br /><br />
		<br /><br />
<h2>
* I தீமோத்தேயு 3 என்பது தேவாலயத்தில் உள்ள கண்காணிகள் மற்றும் டீக்கன்கள் இருவரும் கொண்டிருக்க வேண்டிய பண்புகளின் பட்டியலாகும். அதுபோலவே கடவுளின் வீட்டையும் நன்றாக நிர்வகிக்க அவர்கள் தங்கள் வீடுகளை நன்றாக நிர்வகிக்க வேண்டும். பவுல் இந்த அறிவுரைகளை கொடுக்கிறார், அதனால் அவர்கள் கடவுளுடைய வீட்டில் எப்படி நடந்துகொள்வது என்பதை அறிவார்கள்.*
			</h2>
<br /><br />
			<br /><br />
      </div>
    </div>
    </>
  );
}

export default OneTimothy3