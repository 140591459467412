import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah59 = () => {
  const verseNumber = 59;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 59 </title>
	<meta
          name="description"
          content="Isaiah 59 | ஏசாயா 59 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, இரட்சிக்கக்கூடாதபடிக்குக் கர்த்தருடைய கை குறுகிப்போகவுமில்லை; கேட்கக்கூடாதபடிக்கு அவருடைய செவி மந்தமாகவுமில்லை.
	<br /><br />
	2. உங்கள் அக்கிரமங்களே உங்களுக்கும் உங்கள் தேவனுக்கும் நடுவாகப் பிரிவினை உண்டாக்குகிறது; உங்கள் பாவங்களே அவர் உங்களுக்குச் செவிகொடாதபடிக்கு அவருடைய முகத்தை உங்களுக்கு மறைக்கிறது.
	<br /><br />
	3. ஏனென்றால், உங்கள் கைகள் இரத்தத்தாலும், உங்கள் விரல்கள் அக்கிரமத்தாலும் கறைப்பட்டிருக்கிறது; உங்கள் உதடுகள் பொய்யைப் பேசி, உங்கள் நாவு நியாயக்கேட்டை வசனிக்கிறது.
	<br /><br />
	4. நீதியைத் தேடுகிறவனுமில்லை, சத்தியத்தின்படி வழக்காடுகிறவனுமில்லை; மாயையை நம்பி, அபத்தமானதைப் பேசுகிறார்கள்; தீமையைக் கர்ப்பந்தரித்து, அக்கிரமத்தைப் பெறுகிறார்கள்.
	<br /><br />
	5. கட்டுவிரியனின் முட்டைகளை அடைகாத்து, சிலந்தியின் நெசவுகளை நெய்கிறார்கள்; அவைகளின் முட்டைகளைச் சாப்பிடுகிறவன் சாவான்; அவைகள் நெருக்கப்பட்டதேயானால் விரியன் புறப்படும்.
	<br /><br />
	6. அவைகளின் நெசவுகள் வஸ்திரங்களுக்கேற்றவைகள் அல்ல; தங்கள் கிரியைகளாலே தங்களை மூடிக்கொள்ளமாட்டார்கள்; அவர்கள் கிரியைகள் அக்கிரமக்கிரியைகள்; கொடுமையான செய்கை அவர்கள் கைகளிலிருக்கிறது.
	<br /><br />
	7. அவர்கள் கால்கள் பொல்லாப்புச் செய்ய ஓடி, குற்றமில்லாத இரத்தத்தைச் சிந்தத் தீவிரிக்கிறது; அவர்கள் நினைவுகள் அக்கிரமநினைவுகள்; பாழ்க்கடிப்பும் அழிவும் அவர்கள் வழிகளிலிருக்கிறது.
	<br /><br />
	8. சமாதான வழியை அறியார்கள்; அவர்கள் நடைகளில் நியாயமில்லை; தங்கள் பாதைகளைத் தாங்களே கோணலாக்கிக்கொண்டார்கள்; அவைகளில் நடக்கிற ஒருவனும் சமாதானத்தை அறியான்.
	<br /><br />
	9. ஆதலால், நியாயம் எங்களுக்குத் தூரமாயிருக்கிறது; நீதி எங்களைத் தொடர்ந்து பிடிக்காது; வெளிச்சத்துக்குக் காத்திருந்தோம், இதோ, இருள்; பிரகாசத்துக்குக் காத்திருந்தோம், ஆனாலும் அந்தகாரத்திலே நடக்கிறோம்.
	<br /><br />
	10. நாங்கள் குருடரைப்போல் சுவரைப் பிடித்து, கண்ணில்லாதவர்களைப்போல் தடவுகிறோம்; இரவில் இடறுகிறதுபோலப் பட்டப்பகலிலும் இடறுகிறோம்; செத்தவர்களைப்போல் பாழிடங்களில் இருக்கிறோம்.
	<br /><br />
	11. நாங்கள் அனைவரும் கரடிகளைப்போல உறுமி, புறாக்களைப்போலக் கூவிக்கொண்டிருக்கிறோம், நியாயத்துக்குக் காத்திருந்தோம், அதைக் காணோம்; இரட்சிப்புக்குக் காத்திருந்தோம், அது எங்களுக்குத் தூரமாயிற்று.
	<br /><br />
	12. எங்கள் மீறுதல்கள் உமக்கு முன்பாக மிகுதியாயிருந்து, எங்கள் பாவங்கள் எங்களுக்கு விரோதமாய்ச் சாட்சி சொல்லுகிறது; எங்கள் மீறுதல்கள் எங்களோடே இருக்கிறது; எங்கள் அக்கிரமங்களை அறிந்திருக்கிறோம்.
	<br /><br />
	13. கர்த்தருக்கு விரோதமாய்த் துரோகம்பண்ணி, பொய்பேசி, எங்கள் தேவனைவிட்டுப் பின்வாங்கினோம்; கொடுமையாகவும் கலகமாகவும் பேசினோம்; கள்ளவார்த்தைகளைக் கர்ப்பந்தரித்து, இருதயத்திலிருந்து பிறப்பிக்கப்பண்ணினோம்.
	<br /><br />
	14. நியாயம் பின்னிட்டு அகன்றது; நீதி தூரமாய் நின்றது; சத்தியம் வீதியிலே இடறி, யதார்த்தம் வந்து சேரமாட்டாமற்போகிறது.
	<br /><br />
	15. சத்தியம் தள்ளுபடியாயிற்று; பொல்லாப்பை விட்டு விலகுகிறவன் கொள்ளையாகிறான்; இதைக் கர்த்தர் பார்த்து நியாயமில்லையென்று விசனமுள்ளவரானார்.
	<br /><br />
	16. ஒருவரும் இல்லையென்று கண்டு, விண்ணப்பம் பண்ணுகிறவன் இல்லையென்று ஆச்சரியப்பட்டார்; ஆதலால் அவருடைய புயமே அவருக்கு இரட்சிப்பாகி, அவருடைய நீதியே அவரைத் தாங்குகிறது.
	<br /><br />
	17. அவர் நீதியை மார்க்கவசமாக அணிந்து, இரட்சிப்பென்னும் சீராவைத் தமது சிரசில் தரித்து, நீதிசரிக்கட்டுதலென்னும் வஸ்திரங்களை உடுப்பாக உடுத்து, வைராக்கியத்தைச் சால்வையாகப் போர்த்துக்கொண்டார்.
	<br /><br />
	18. கிரியைகளுக்குத்தக்க பலனை அளிப்பார்; தம்முடைய சத்துருக்களிடத்தில் உக்கிரத்தைச் சரிக்கட்டி, தம்முடைய பகைஞருக்குத்தக்க பலனையும், தீவுகளுக்குத்தக்க பலனையும் சரிக்கட்டுவார்.
	<br /><br />
	19. அப்பொழுது சூரியன் அஸ்தமிக்குந்திசைதொடங்கி கர்த்தரின் நாமத்துக்கும், சூரியன் உதிக்குந்திசைதொடங்கி அவருடைய மகிமைக்கும் பயப்படுவார்கள்; வெள்ளம்போல் சத்துரு வரும்போது, கர்த்தருடைய ஆவியானவர் அவனுக்கு விரோதமாய்க் கொடியேற்றுவார்.
	<br /><br />
	20. மீட்பர் சீயோனுக்கும், யாக்கோபிலே மீறுதலைவிட்டுத் திரும்புகிறவர்களுக்கும், வருவார் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	21. உன்மேலிருக்கிற என் ஆவியும், நான் உன் வாயில் அருளிய என் வார்த்தைகளும், இதுமுதல் என்றென்றைக்கும் உன் வாயிலிருந்தும், உன் சந்ததியின் வாயிலிருந்தும், உன் சந்ததியினுடைய சந்ததியின் வாயிலிருந்தும் நீங்குவதில்லையென்று கர்த்தர் சொல்லுகிறார்; இது எனக்கு அவர்களோடிருக்கும் என் உடன்படிக்கையென்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah59