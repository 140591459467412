import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 20 </title>
	<meta
          name="description"
          content="Ezekiel 20 | எசேக்கியேல்  20 |
          Ezekiel 20 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஏழாம் வருஷத்து ஐந்தாம் மாதம் பத்தாந்தேதியிலே இஸ்ரவேலின் மூப்பரில் சிலர் கர்த்தரிடத்தில் விசாரிக்கும்படிவந்து, எனக்கு முன்பாக உட்கார்ந்தார்கள்.
	<br /><br />
	2. அப்பொழுது கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	3. மனுபுத்திரனே, நீ இஸ்ரவேல் மூப்பரோடே பேசி, அவர்களை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், நீங்கள் என்னிடத்தில் விசாரிக்க வந்தீர்களோ? நீங்கள் என்னிடத்தில் விசாரிக்க இடங்கொடேன் என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல்.
	<br /><br />
	4. மனுபுத்திரனே, நீ அவர்களுக்காக வழக்காடுவாயோ? வழக்காட மனதானால், நீ அவர்கள் பிதாக்களின் அருவருப்புகளை அவர்களுக்குத் தெரியக்காட்டி, அவர்களை நோக்கி:
	<br /><br />
	5. கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், நான் இஸ்ரவேலைத் தெரிந்துகொண்ட நாளிலே யாக்கோபு வம்சத்து ஜனங்களுக்கு நான் ஆணையிட்டு, எகிப்துதேசத்தில் என்னை அவர்களுக்குத் தெரியப்படுத்தி, நான் உங்கள் தேவனாகிய கர்த்தர் என்று ஆணையிட்டேன்.
	<br /><br />
	6. நான் அவர்களை எகிப்துதேசத்திலிருந்து அழைப்பேன் என்றும், அவர்களுக்காக நான் பார்த்துவைத்ததும், பாலும் தேனும் ஓடுகிறதும் எல்லாத் தேசங்களின் சிங்காரமுமான தேசத்திலே அவர்களைக் கொண்டுவந்து விடுவேன் என்றும் அந்நாளிலே ஆணையிட்டு,
	<br /><br />
	7. உங்களில் அவரவர் தங்கள் கண்களால் நோக்கின அருவருப்புகளைத் தள்ளிவிட்டு, எகிப்தின் நரகலான விக்கிரகங்களால் உங்களைத் தீட்டுப்படுத்தாதிருப்பீர்களாக; உங்கள் தேவனாகிய கர்த்தர் நான் என்று அவர்களோடே சொன்னேன்.
	<br /><br />
	8. அவர்களோ, என் சொல்லைக் கேட்க மனதில்லாமல் எனக்கு விரோதமாய் இரண்டகம்பண்ணினார்கள்; அவரவர் தங்கள் கண்களால் நோக்கின அருவருப்புகளைத் தள்ளிப்போடாமலும், எகிப்தின் நரகலான விக்கிரகங்களை விடாமலும் இருந்தார்கள்; ஆதலால் எகிப்து தேசத்தின் நடுவிலே என் கோபத்தை அவர்களிலே தீர்த்துக்கொள்ளும்படிக்கு என் உக்கிரத்தை அவர்கள்மேல் ஊற்றுவேன் என்றேன்.
	<br /><br />
	9. ஆகிலும் நான் என்னை இவர்களுக்கு வெளிப்படுத்தி, இவர்களுக்கு முன்பாக என் நாமம் பரிசுத்தக்குலைச்சலாகாதபடிக்கு, இவர்களை எகிப்துதேசத்திலிருந்து புறப்படப்பண்ணி, என் நாமத்தினிமித்தம் கிருபைசெய்தேன்.
	<br /><br />
	10. ஆகையால் நான் அவர்களை எகிப்துதேசத்திலிருந்து புறப்படப்பண்ணி, அவர்களை வனாந்தரத்தில் அழைத்துவந்து,
	<br /><br />
	11. என் கட்டளைகளை அவர்களுக்குக் கொடுத்து, என் நியாயங்களை அவர்களுக்குத் தெரியப்படுத்தினேன்; அவைகளின்படி செய்கிற மனுஷன் அவைகளால் பிழைப்பான்.
	<br /><br />
	12. நான் தங்களைப் பரிசுத்தம்பண்ணுகிற கர்த்தர் என்று அவர்கள் அறியும்படிக்கு, எனக்கும் அவர்களுக்கும் அடையாளமாய் இருப்பதற்கான என் ஓய்வுநாட்களையும் அவர்களுக்குக் கட்டளையிட்டேன்.
	<br /><br />
	13. ஆனாலும் இஸ்ரவேல் வம்சத்தார் வனாந்தரத்தில் எனக்கு விரோதமாய் இரண்டகம்பண்ணினார்கள்; என் கட்டளைகளின்படியே மனுஷன் செய்தால் அவைகளால் பிழைப்பான்; அவர்களோ அவைகளில் நடவாமல், என் நியாயங்களை வெறுத்து, என் ஓய்வுநாட்களின் பரிசுத்தத்தை மிகவும் குலைத்துப்போட்டார்கள்; ஆதலால் அவர்களை நிர்மூலமாக்கும்படி வனாந்தரத்திலே என் உக்கிரத்தை அவர்கள்மேல் ஊற்றுவேன் என்றேன்.
	<br /><br />
	14. ஆகிலும் நான் இவர்களைப் புறப்படப்பண்ணினதைக் கண்ட புறஜாதிகளுடைய கண்களுக்கு முன்பாக என் நாமம் பரிசுத்தக்குலைச்சலாகாதபடிக்கு, என் நாமத்தினிமித்தம் கிருபைசெய்தேன்.
	<br /><br />
	15. ஆனாலும் அவர்களுடைய இருதயம் அவர்களுடைய நரகலான விக்கிரகங்களைப் பின்பற்றி, அவர்கள் என் நியாயங்களை வெறுத்து, என் கட்டளைகளில் நடவாமற்போய், என் ஓய்வுநாட்களைப் பரிசுத்தக்குலைச்சலாக்கினபடியால்,
	<br /><br />
	16. நான் வாக்குத்தத்தம்பண்ணினதும், பாலும் தேனும் ஓடுகிறதும், எல்லா தேசங்களின் சிங்காரமாயிருக்கிறதுமான தேசத்திலே அவர்களைக் கொண்டுவந்து விடுவதில்லை என்று வனாந்தரத்தில் ஆணையிட்டேன்.
	<br /><br />
	17. ஆகிலும் அவர்களை அழிக்காதபடிக்கு, என் கண் அவர்களைத் தப்பவிட்டது; நான் அவர்களை வனாந்தரத்தில் நிர்மூலமாக்கவில்லை.
	<br /><br />
	18. வனாந்தரத்தில் அவர்களுடைய பிள்ளைகளை நோக்கி: நீங்கள் உங்கள் பிதாக்களின் முறைமைகளில் நடவாமலும் அவர்களுடைய நியாயங்களைக் கைக்கொள்ளாமலும், அவர்களுடைய நரகலான விக்கிரகங்களால் உங்களைத் தீட்டுப்படுத்தாமலும் இருங்கள்.
	<br /><br />
	19. உங்கள் தேவனாகிய கர்த்தர் நானே; நீங்கள் என் கட்டளைகளில் நடந்து, என் நியாயங்களைக் கைக்கொண்டு, அவைகளின்படியே செய்து,
	<br /><br />
	20. என் ஓய்வுநாட்களைப் பரிசுத்தமாக்குங்கள்; நான் உங்கள் தேவனாகிய கர்த்தர் என்று நீங்கள் அறியும்படிக்கு அவைகள் எனக்கும் உங்களுக்கும் அடையாளமாயிருக்கும் என்றேன்.
	<br /><br />
	21. ஆனாலும் பிள்ளைகளும் எனக்கு விரோதமாய் எழும்பினார்கள்; என் கட்டளைகளின்படியே மனுஷன் செய்தால் அவைகளால் பிழைப்பானே; அவர்களோ அவைகளில் நடவாமலும், என் நியாயங்களைக் கைக்கொண்டு அவைகளின்படி செய்யாமலும், என் ஓய்வுநாட்களைப் பரிசுத்தக்குலைச்சலாக்கிப்போட்டார்கள்; ஆகையால் வனாந்தரத்திலே என் கோபத்தை அவர்களில் தீர்த்துக்கொள்ளும்படி என் உக்கிரத்தை அவர்கள்மேல் ஊற்றுவேன் என்றேன்.
	<br /><br />
	22. ஆகிலும் நான் என் கையைத் திருப்பி, நான் இவர்களைப் புறப்படப்பண்ணினதைக் கண்ட புறஜாதிகளுடைய கண்களுக்கு முன்பாக என் நாமம் பரிசுத்தக்குலைச்சலாகாதபடிக்கு, என் நாமத்தினிமித்தம் கிருபைசெய்தேன்.
	<br /><br />
	23. ஆனாலும் அவர்கள் என் நியாயங்களின்படி செய்யாமல், என் கட்டளைகளை வெறுத்து, என் ஓய்வுநாட்களை பரிசுத்தக் குலைச்சலாக்கினபடியாலும், அவர்களுடைய கண்கள் அவர்கள் பிதாக்களின் நரகலான விக்கிரகங்களின்மேல் நோக்கமாயிருந்தபடியாலும்,
	<br /><br />
	24. நான் அவர்களைப் புறஜாதிகளுக்குள்ளே சிதறடித்து, அவர்களைத் தேசங்களிலே தூற்றிப்போடுகிறதற்கு வனாந்தரத்திலே ஆணையிட்டேன்.
	<br /><br />
	25. ஆகையால் நன்மைக்கேதுவல்லாத கட்டளைகளையும் ஜீவனுக்கேதுவல்லாத நியாயங்களையும் நான் அவர்களுக்குக் கொடுத்தேன்.
	<br /><br />
	26. நான் கர்த்தர் என்று அவர்கள் அறியத்தக்கதாக, நான் அவர்களைப் பாழாக்கும்படி, அவர்கள் கர்ப்பந்திறந்து பிறக்கிறதையெல்லாம் தீக்கடக்கப்பண்ணி, இவ்விதமாய் அவர்களுடைய பலிகளினாலே அவர்களைத் தீட்டுப்படப்பண்ணினேன்.
	<br /><br />
	27. ஆகையால் மனுபுத்திரனே, நீ இஸ்ரவேல் வம்சத்தாரோடே பேசி, அவர்களை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், உங்கள் பிதாக்கள் இன்னும் எனக்கு விரோதமாய்த் துரோகம்பண்ணி, என்னைத் தூஷித்தார்கள்.
	<br /><br />
	28. அவர்களுக்குக் கொடுப்பேன் என்று ஆணையிட்ட தேசத்திலே நான் அவர்களைப் பிரவேசிக்கப்பண்ணினபின்பு, அவர்கள் உயர்ந்த ஒரு மேட்டையும் தழைத்த ஒரு விருட்சத்தையும் எங்கெங்கே கண்டார்களோ, அங்கங்கே தங்கள் பலிகளைச் செலுத்தி, அவ்விடங்களிலெல்லாம் எனக்கு எரிச்சல் உண்டாக்குகிற தங்கள் காணிக்கைகளைப் படைத்து, சுகந்த வாசனையான தங்கள் தூபங்களைக்காட்டி, தங்கள் பானபலிகளை வார்த்தார்கள்.
	<br /><br />
	29. அப்பொழுது நான் அவர்களை நோக்கி: நீங்கள் போகிற அந்த மேடு என்னவென்று கேட்டேன்; அதினால் இந்நாள்வரைக்கும் அதற்குப் பாமா என்று பேர்.
	<br /><br />
	30. ஆகையால் நீ இஸ்ரவேல் வம்சத்தாரை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், உங்கள் பிதாக்களுடைய மார்க்கத்தின்படியே நீங்களும் தீட்டுப்பட்டவர்கள் அல்லவோ? அவர்களுடைய அருவருப்புகளை நீங்களும் பின்பற்றிச் சோரம்போகிறீர்கள் அல்லவோ?
	<br /><br />
	31. நீங்கள் உங்கள் பிள்ளைகளைத் தீக்கடக்கப்பண்ணி, உங்கள் பலிகளைச் செலுத்துகிறபோது, இந்நாள்வரைக்கும் அவர்களுடைய எல்லா நரகலான விக்கிரகங்களாலும் நீங்கள் தீட்டுப்படுகிறீர்களே; நீங்கள் என்னிடத்தில் விசாரிக்க இடங்கொடுப்பேனோ? இஸ்ரவேல் வம்சத்தாரே, நீங்கள் என்னிடத்தில் விசாரிக்கும்படி இடங்கொடுப்பதில்லை என்று என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	32. மரத்துக்கும் கல்லுக்கும் ஆராதனைசெய்து, அஞ்ஞானிகளைப்போலவும் தேசத்து ஜனங்களின் ஜாதிகளைப்போலவும் இருப்போம் என்று சொல்லுகிறீர்களே; உங்கள் மனதில் எழும்புகிற இந்த நினைவின்படி ஆவதே இல்லை.
	<br /><br />
	33. பலத்த கையினாலும், ஓங்கிய புயத்தினாலும், ஊற்றப்பட்ட உக்கிரத்தினாலும், உங்களை ஆளுவேன் என்பதை என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	34. நீங்கள் ஜனங்களுக்குள் இராதபடிக்கு நான் உங்களைப் புறப்படப்பண்ணி, நீங்கள் சிதறியிருக்கிற தேசங்களில் இராதபடிக்கு நான் உங்களைப் பலத்தகையினாலும், ஓங்கிய புயத்தினாலும், ஊற்றப்பட்ட உக்கிரத்தினாலும் கூடிவரச்செய்து,
	<br /><br />
	35. உங்களை ஜனசதளங்களின் வனாந்தரத்திலே கொண்டுபோய், அங்கே உங்களோடே முகமுகமாய் வழக்காடுவேன்.
	<br /><br />
	36. நான் எகிப்துதேசத்தின் வனாந்தரத்தில் உங்கள் பிதாக்களோடு வழக்காடினதுபோல உங்களோடும் வழக்காடுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	37. நான் உங்களைக் கோலின்கீழ் செல்லும்படி செய்து, உங்களை உடன்படிக்கையின் கட்டுக்குட்படுத்தி,
	<br /><br />
	38. கலகக்காரரையும் துரோகிகளையும் உங்களைவிட்டுப் பிரித்துப்போடுவேன்; அவர்களைத் தாங்கள் தங்கும் தேசத்திலிருந்து புறப்படப்பண்ணுவேன்; ஆனாலும் அவர்கள் இஸ்ரவேல் தேசத்தில் பிரவேசிப்பதில்லை; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	39. இப்போதும் இஸ்ரவேல் வம்சத்தாரே, நீங்கள் என் சொல்லைக்கேட்க மனதில்லாதிருந்தால், நீங்கள் போய், அவனவன் தன் தன் நரகலான விக்கிரகங்களை இன்னும் சேவியுங்கள்; ஆனாலும் என் பரிசுத்த நாமத்தை உங்கள் காணிக்கைகளாலும் உங்கள் நரகலான விக்கிரகங்களாலும் இனிப் பரிசுத்தக்குலைச்சலாக்காதிருங்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	40. இஸ்ரவேலின் உயரமான மலையாகிய என் பரிசுத்த மலையிலே இஸ்ரவேலுடைய எல்லா வம்சத்தாருமாகிய தேசத்திலுள்ள அனைவரும் என்னைச் சேவிப்பார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; அங்கே அவர்கள்மேல் பிரியம் வைப்பேன்; அங்கே நீங்கள் பரிசுத்தம்பண்ணுகிற எல்லாவற்றிலும் உங்கள் காணிக்கைகளையும் உங்கள் முதற்பலன்களையும் செலுத்தும்படி கேட்பேன்.
</p>
<p>
	41. நான் உங்களை ஜனங்களிலிருந்து புறப்படப்பண்ணி, நீங்கள் சிதறுண்டிருக்கிற தேசங்களிலிருந்து உங்களைச் சேர்த்துக்கொள்ளும்போது, சுகந்த வாசனையினிமித்தம் நான் உங்கள்பேரில் பிரியமாயிருப்பேன்; அப்பொழுது புறஜாதிகளின் கண்களுக்கு முன்பாக உங்களால் பரிசுத்தம்பண்ணப்படுவேன்.
	<br /><br />
	42. உங்கள் பிதாக்களுக்குக் கொடுப்பேன் என்று ஆணையிட்ட தேசமாகிய இஸ்ரவேல் தேசத்திலே நான் உங்களைத் திரும்பிவரப்பண்ணும்போது, நான் கர்த்தர் என்று நீங்கள் அறிந்துகொண்டு,
	<br /><br />
	43. அங்கே உங்களுடைய எல்லா வழிகளையும் உங்களைத் தீட்டுப்படுத்தின உங்களுடைய எல்லாக் கிரியைகளையும் நினைத்து, நீங்கள் செய்திருந்த உங்களுடைய எல்லாப் பொல்லாப்புகளினிமித்தமும் உங்களை நீங்களே அருவருப்பீர்கள்.
	<br /><br />
	44. இஸ்ரவேல் வம்சத்தாரே, உங்கள் பொல்லாத வழிகளுக்குத் தக்கதாகவும், உங்கள் கெட்ட கிரியைகளுக்குத்தக்கதாகவும் நான் உங்களுக்குச் செய்யாமல், என் நாமத்தினிமித்தம் உங்களுக்குக் கிருபைசெய்யும்போது, நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள் என்கிறதைக் கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	45. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	46. மனுபுத்திரனே, நீ உன் முகத்தைத் தென்திசைக்கு நேரே திருப்பி, தெற்குக்கு விரோதமாக உன் வசனத்தைப்பொழிந்து, தென்புறமான வயல்வெளியின் காட்டுக்கு விரோதமாகத் தீர்க்கதரிசனம் உரைத்து,
	<br /><br />
	47. தென்திசைக் காட்டை நோக்கி: கர்த்தருடைய வார்த்தையைக் கேள், கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், இதோ, நான் உன்னில் அக்கினியைக் கொளுத்துவேன்; அது உன்னில் பச்சையான சகல மரங்களையும் பட்டுப்போன சகல மரங்களையும் பட்சிக்கும்; ஜுவாலிக்கிற ஜுவாலை அவிக்கப்படமாட்டாது; தெற்கு துவக்கி வடக்குமட்டுமுள்ள தேசமெங்கும் அதினால் வெந்துபோகும்.
	<br /><br />
	48. கர்த்தராகிய நான் அதைக் கொளுத்தினேன் என்பதை எல்லா மாம்சமும் காணும்; அது அவிக்கப்படுவதில்லை என்று சொல் என்றார்.
	<br /><br />
	49. அப்பொழுது நான்: ஆ, கர்த்தராகிய ஆண்டவரே, இவன் உவமைகளையல்லவோ சொல்லுகிறான் என்று அவர்கள் என்னைக்குறித்துச் சொல்லுகிறார்கள் என்றேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial20