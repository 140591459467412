import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Nahum2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | நாகூம் 2 </title>
	<meta
          name="description"
          content="Nahum 2 | நாகூம் 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சிதறடிக்கிறவன் உன் முகத்துக்கு முன்பாக வருகிறான்; அரணைக் காத்துக்கொள், வழியைக் காவல்பண்ணு, அரையைக் கெட்டியாய்க் கட்டிக்கொள், உன் பெலனை மிகவும் ஸ்திரப்படுத்து.
	<br /><br />
	2. வெறுமையாக்குகிறவர்கள் அவர்களை வெறுமையாக்கி, அவர்களுடைய திராட்சக்கொடிகளைக் கெடுத்துப்போட்டாலும், கர்த்தர் யாக்கோபின் மகிமையைத் திரும்பிவரப்பண்ணுவது போல், இஸ்ரவேலின் மகிமையையும் திரும்பிவரப்பண்ணுவார்.
	<br /><br />
	3. அவனுடைய பராக்கிரமசாலிகளின் கேடகம் இரத்தமயமாகும்; அவனுடைய யுத்தவீரர் இரத்தாம்பரந் தரித்துக்கொண்டிருக்கிறார்கள்; அவன் தன்னை ஆயத்தம்பண்ணும் நாளிலே இரதங்கள் ஜுவாலிக்கிற கட்கங்களை உடையதாயிருக்கும்; ஈட்டிகள் குலுங்கும்.
	<br /><br />
	4. இரதங்கள் தெருக்களில் கடகடவென்றோடி, வீதிகளில் இடசாரி வலசாரி வரும்; அவைகள் தீவட்டிகளைப்போல விளங்கி, மின்னல்களைப்போல வேகமாய்ப் பறக்கும்.
	<br /><br />
	5. அவன் தன் பிரபலஸ்தரை நினைவுகூருவான்; அவர்கள் தங்கள் நடைகளில் இடறி, அலங்கத்துக்கு விரைந்து ஓடுவார்கள்; மறைவிடம் ஆயத்தப்படுத்தப்படும்.
	<br /><br />
	6. ஆறுகளின் மதகுகள் திறக்கப்படும், அரமனை கரைந்துபோம்.
	<br /><br />
	7. அவன் சிறைப்பட்டுப்போகத் தீர்மானமாயிற்று; அவளுடைய தாதிமார்கள் தங்கள் மார்பிலே அடித்துக்கொண்டு, புறாக்களைப்போலச் சத்தமிட்டுக் கூடப்போவார்கள்.
	<br /><br />
	8. நினிவே பூர்வகாலமுதல் தண்ணீர்த் தடாகம்போல் இருந்தது; இப்போதோ அவர்கள் ஓடிப்போகிறார்கள்; நில்லுங்கள் நில்லுங்கள் என்றாலும், திரும்பிப்பார்க்கிறவன் இல்லை.
	<br /><br />
	9. வெள்ளியைக் கொள்ளையிடுங்கள், பொன்னையும் கொள்ளையிடுங்கள்; சம்பத்துக்கு முடிவில்லை; இச்சிக்கப்படத்தக்க சகலவித பொருள்களும் இருக்கிறது.
	<br /><br />
	10. அவள் வெறுமையும் வெளியும் பாழுமாவாள்; மனம் கரைந்துபோகிறது; முழங்கால்கள் தள்ளாடுகிறது; எல்லா இடுப்புகளிலும் மிகுந்த வேதனை உண்டு; எல்லாருடைய முகங்களும் கருகிப்போகிறது.
	<br /><br />
	11. சிங்கங்களின் வாசஸ்தலம் எங்கே? பாலசிங்கம் இரைதின்கிற இடம் எங்கே? கிழச்சிங்கமாகிய சிங்கமும், சிங்கக்குட்டிகளும் பயப்படுத்துவாரில்லாமல் சஞ்சரிக்கிற ஸ்தானம் எங்கே?
	<br /><br />
	12. சிங்கம் தன் குட்டிகளுக்குத் தேவையானதைப் பீறி, தன் பெண் சிங்கங்களுக்கு வேண்டியதைத் தொண்டையைப் பிடித்துக் கொன்று, இரைகளினால் தன் கெபிகளையும், பீறிப்போட்டவைகளினால் தன் தாபரங்களையும் நிரப்பிற்று.
	<br /><br />
	13. இதோ, நான் உனக்கு விரோதமாக வந்து, இரதங்களைப் புகையெழும்ப எரித்துப்போடுவேன்; பட்டயம் உன் பாலசிங்கங்களைப் பட்சிக்கும்; நீ இரைக்காகப் பிடிக்கும் வேட்டையைத் தேசத்தில் அற்றுப்போகப்பண்ணுவேன்; உன் ஸ்தானாபதிகளின் சத்தம் இனிக் கேட்கப்படுவதில்லை என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Nahum2