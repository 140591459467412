import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah51 = () => {
  const verseNumber = 51;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 51 </title>
	<meta
          name="description"
          content="Isaiah 51 | ஏசாயா 51 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீதியைப் பின்பற்றி, கர்த்தரைத் தேடுகிற நீங்கள் எனக்குச் செவிகொடுங்கள்; நீங்கள் வெட்டி எடுக்கப்பட்ட கன்மலையையும், நீங்கள் தோண்டி எடுக்கப்பட்ட துரவின் குழியையும் நோக்கிப்பாருங்கள்.
	<br /><br />
	2. உங்கள் தகப்பனாகிய ஆபிரகாமையும், உங்களைப் பெற்ற சாராளையும் நோக்கிப்பாருங்கள்; அவன் ஒருவனாயிருக்கையில் நான் அவனை அழைத்து, அவனை ஆசீர்வதித்து, அவனைப் பெருகப்பண்ணினேன்.
	<br /><br />
	3. கர்த்தர் சீயோனுக்கு ஆறுதல் செய்வார்; அவர் அதின் பாழான ஸ்தலங்களையெல்லாம் தேறுதலடையச் செய்து, அதின் வனாந்தரத்தை ஏதேனைப்போலவும், அதின் அவாந்தரவெளியைக் கர்த்தரின் தோட்டத்தைப்போலவும் ஆக்குவார்; சந்தோஷமும் மகிழ்ச்சியும் துதியும் கீதசத்தமும் அதில் உண்டாயிருக்கும்.
	<br /><br />
	4. என் ஜனங்களே, எனக்குச் செவிகொடுங்கள்; என் ஜாதியாரே, என் வாக்கைக் கவனியுங்கள்; வேதம் என்னிலிருந்து வெளிப்படும்; என் பிரமாணத்தை ஜனங்களின் வெளிச்சமாக ஸ்தாபிப்பேன்.
	<br /><br />
	5. என் நீதி சமீபமாயிருக்கிறது; என் இரட்சிப்பு வெளிப்படும்; என் புயங்கள் ஜனங்களை நியாயந்தீர்க்கும்; தீவுகள் எனக்குக் காத்திருந்து, என் புயத்தின்மேல் நம்பிக்கையாயிருக்கும்.
	<br /><br />
	6. உங்கள் கண்களை வானத்துக்கு ஏறெடுங்கள், கீழே இருக்கிற பூமியையும் நோக்கிப்பாருங்கள்; வானம் புகையைப்போல் ஒழிந்துபோம், பூமி வஸ்திரத்தைப்போல் பழசாய்ப்போம்; அதின் குடிகளும் அப்படியே ஒழிந்துபோவார்கள்; என் இரட்சிப்போ என்றென்றைக்கும் இருக்கும்; என் நீதி அற்றுப்போவதில்லை.
	<br /><br />
	7. நீதியை அறிந்தவர்களே, என் வேதத்தை இருதயத்தில் பதித்திருக்கிற ஜனங்களே, எனக்குச் செவிகொடுங்கள்; மனுஷரின் நிந்தனைக்குப் பயப்படாமலும், அவர்கள் தூஷணங்களால் கலங்காமலும் இருங்கள்.
	<br /><br />
	8. பொட்டுப்பூச்சி அவர்களை வஸ்திரத்தைப்போல் அரித்து, புழு அவர்களை ஆட்டுமயிரைப்போல் தின்னும்; என்னுடைய நீதியோ என்றென்றைக்கும் நிலைக்கும், என் இரட்சிப்புத் தலைமுறை தலைமுறைதோறும் இருக்கும்.
	<br /><br />
	9. எழும்பு, எழும்பு, பெலன்கொள்; கர்த்தரின் புயமே, முந்தினநாட்களிலும் பூர்வ தலைமுறைகளிலும் எழும்பினபடி எழும்பு; இராகாபைத் துண்டித்ததும் வலுசர்ப்பத்தை வதைத்ததும் நீதானல்லவோ?
	<br /><br />
	10. மகா ஆழத்தின் தண்ணீர்களாகிய சமுத்திரத்தை வற்றிப்போகப்பண்ணினதும், மீட்கப்பட்டவர்கள் கடந்துபோகக் கடலின் பள்ளங்களை வழியாக்கினதும் நீதானல்லவோ?
	<br /><br />
	11. அப்படியே கர்த்தரால் மீட்கப்பட்டவர்கள் ஆனந்தக்களிப்புடன் பாடி சீயோனுக்குத் திரும்பிவருவார்கள்; நித்திய மகிழ்ச்சி அவர்கள் தலையின்மேல் இருக்கும்; சந்தோஷமும் மகிழ்ச்சியும் அடைவார்கள்; சஞ்சலமும் தவிப்பும் ஓடிப்போம்.
	<br /><br />
	12. நான், நானே உங்களுக்கு ஆறுதல் செய்கிறவர்; சாகப்போகிற மனுஷனுக்கும், புல்லுக்கொப்பாகிற மனுபுத்திரனுக்கும் பயப்படுகிறதற்கும், வானங்களை விரித்து, பூமியை அஸ்திபாரப்படுத்தி, உன்னை உண்டாக்கின கர்த்தரை மறக்கிறதற்கும் நீ யார்?
	<br /><br />
	13. இடுக்கண்செய்கிறவன் அழிக்க ஆயத்தமாகிறபோது, நீ அவனுடைய உக்கிரத்துக்கு நித்தம் இடைவிடாமல் பயப்படுகிறதென்ன? இடுக்கண்செய்கிறவனுடைய உக்கிரம் எங்கே?
	<br /><br />
	14. சிறைப்பட்டுப்போனவன் தீவிரமாய் விடுதலையாவான்; அவன் கிடங்கிலே சாவதுமில்லை, அவனுடைய அப்பம் குறைவுபடுவதுமில்லை.
	<br /><br />
	15. உன் தேவனாயிருக்கிற கர்த்தர் நானே; அலைகள் கொந்தளிக்கத்தக்கதாய்ச் சமுத்திரத்தைக் குலுக்குகிற சேனைகளின் கர்த்தர் என்கிற நாமமுள்ளவர்.
	<br /><br />
	16. நான் வானத்தை நிலைப்படுத்தி, பூமியை அஸ்திபாரப்படுத்தி, சீயோனை நோக்கி: நீ என் ஜனமென்று சொல்வதற்காக, நான் என் வார்த்தையை உன் வாயிலே அருளி, என் கரத்தின் நிழலினால் உன்னை மறைக்கிறேன்.
	<br /><br />
	17. எழும்பு, எழும்பு, கர்த்தருடைய உக்கிரத்தின் பாத்திரத்தை அவர் கையில் வாங்கிக் குடித்திருக்கிற எருசலேமே, எழுந்துநில், தத்தளிக்கச்செய்யும் பாத்திரத்தின் வண்டல்களை உறிஞ்சிக் குடித்தாய்.
	<br /><br />
	18. அவள் பெற்ற புத்திரரெல்லாரிலும் அவளை நடத்துவார் ஒருவருமில்லை; அவள் வளர்த்த குமாரரெல்லாரிலும் அவளைக் கைகொடுத்து அழைப்பார் ஒருவருமில்லை.
	<br /><br />
	19. இவ்விரண்டும் உனக்குச் சம்பவித்தது; உனக்குப் பரிதபிக்கிறவன் யார்? பாழ்க்கடிப்பும், சங்காரமும், பஞ்சமும், பட்டயமும் வந்தன; யாரைக்கொண்டு உனக்கு ஆறுதல் செய்வேன்?
	<br /><br />
	20. உன் குமாரர் மூர்ச்சித்து விழுந்தார்கள்; அவர்கள், வலையிலே சிக்குண்ட கலைமானைப்போல, எல்லா வீதிகளின் முனையிலும், கர்த்தருடைய உக்கிரத்தினாலும், உன் தேவனுடைய கண்டிதத்தினாலும் நிறைந்தவர்களாய்க் கிடக்கிறார்கள்.
	<br /><br />
	21. ஆகையால் சிறுமைப்பட்டவளே, மதுபானங்குடியாமல் வெறிகொண்டவளே, நீ கேள்.
	<br /><br />
	22. கர்த்தராகிய உன் ஆண்டவரும் தம்முடைய ஜனத்துக்காக வழக்காடப்போகிற உன் தேவனுமானவர் சொல்லுகிறது என்னவென்றால்: இதோ, தத்தளிப்பின் பாத்திரத்தை உன் கையிலிருந்து நீக்கிப்போடுகிறேன், இனி என் உக்கிரத்தினுடைய பாத்திரத்தின் வண்டல்களை நீ குடிப்பதில்லை.
	<br /><br />
	23. உன்னை நோக்கி: நாங்கள் கடந்துபோகும்படிக்குக் குனியென்றுசொல்லி, கடந்துபோகிறவர்களுக்கு நீ உன் முதுகைத் தரையும் வீதியுமாக்கும்படி, உன்னைச் சஞ்சலப்படுத்தினவர்களின் கையில் அதைக் கொடுப்பேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah51