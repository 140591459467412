import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 3 </title>
	<meta
          name="description"
          content="John Chapter 3 | யோவான் அதிகாரம் 3 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதருக்குள்ளே அதிகாரியான நிக்கொதேமு என்னப்பட்ட பரிசேயன் ஒருவன் இருந்தான்.
	<br /><br />
	2. அவன் இராக்காலத்திலே இயேசுவினிடத்தில் வந்து: ரபீ, நீர் தேவனிடத்திலிருந்து வந்த போதகர் என்று அறிந்திருக்கிறோம், ஏனெனில் ஒருவனும் தன்னுடனே தேவன் இராவிட்டால் நீர் செய்கிற இப்படிப்பட்ட அற்புதங்களைச் செய்யமாட்டான் என்றான்.
	<br /><br />
	3. இயேசு அவனுக்குப் பிரதியுத்தரமாக: ஒருவன் மறுபடியும் பிறவாவிட்டால் தேவனுடைய ராஜ்யத்தைக் காணமாட்டான் என்று மெய்யாகவே மெய்யாகவே உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	4. அதற்கு நிக்கொதேமு: ஒரு மனுஷன் முதிர்வயதாயிருக்கையில் எப்படிப் பிறப்பான்? அவன் தன் தாயின் கர்ப்பத்தில் இரண்டாந்தரம் பிரவேசித்துப் பிறக்கக்கூடுமோ என்றான்.
	<br /><br />
	5. இயேசு பிரதியுத்தரமாக: ஒருவன் ஜலத்தினாலும் ஆவியினாலும் பிறவாவிட்டால் தேவனுடைய ராஜ்யத்தில் பிரவேசிக்கமாட்டான் என்று மெய்யாகவே மெய்யாகவே உனக்குச் சொல்லுகிறேன்.
	<br /><br />
	6. மாம்சத்தினால் பிறப்பது மாம்சமாயிருக்கும், ஆவியினால் பிறப்பது ஆவியாயிருக்கும்.
	<br /><br />
	7. நீங்கள் மறுபடியும் பிறக்கவேண்டும் என்று நான் உனக்குச் சொன்னதைக் குறித்து அதிசயப்படவேண்டாம்.
	<br /><br />
	8. காற்றானது தனக்கு இஷ்டமான இடத்திலே வீசுகிறது, அதின் சத்தத்தைக் கேட்கிறாய், ஆகிலும் அது இன்ன இடத்திலிருந்து வருகிறதென்றும், இன்ன இடத்துக்குப் போகிறதென்றும் உனக்குத் தெரியாது; ஆவியினால் பிறந்தவனெவனோ அவனும் அப்படியே இருக்கிறான் என்றார்.
	<br /><br />
	9. அதற்கு நிக்கொதேமு: இவைகள் எப்படி ஆகும் என்றான்.
	<br /><br />
	10. இயேசு அவனை நோக்கி: நீ இஸ்ரவேலில் போதகனாயிருந்தும் இவைகளை அறியாமலிருக்கிறாயா?
	<br /><br />
	11. மெய்யாகவே மெய்யாகவே நான் உனக்குச் சொல்லுகிறேன், நாங்கள் அறிந்திருக்கிறதைச் சொல்லி, நாங்கள் கண்டதைக்குறித்துச் சாட்சிகொடுக்கிறோம்; நீங்களோ எங்கள் சாட்சியை ஏற்றுக் கொள்ளுகிறதில்லை.
	<br /><br />
	12. பூமிக்கடுத்த காரியங்களை நான் உங்களுக்குச் சொல்லியும் நீங்கள் விசுவாசிக்கவில்லையே, பரமகாரியங்களை உங்களுக்குச் சொல்வேனானால் எப்படி விசுவாசிப்பீர்கள்?
	<br /><br />
	13. பரலோகத்திலிருந்திறங்கினவரும் பரலோகத்திலிருக்கிறவருமான மனுஷகுமாரனேயல்லாமல் பரலோகத்துக்கு ஏறினவன் ஒருவனுமில்லை.
	<br /><br />
	14. சர்ப்பமானது மோசேயினால் வனாந்தரத்திலே உயர்த்தப்பட்டதுபோல மனுஷகுமாரனும்,
	<br /><br />
	15. தன்னை விசுவாசிக்கிறவன் எவனோ அவன் கெட்டுப்போகாமல் நித்தியஜீவனை அடையும்படிக்கு, உயர்த்தப்படவேண்டும்.
	<br /><br />
	16. தேவன், தம்முடைய ஒரேபேறான குமாரனை விசுவாசிக்கிறவன் எவனோ அவன் கெட்டுப்போகாமல் நித்தியஜீவனை அடையும்படிக்கு, அவரைத் தந்தருளி, இவ்வளவாய் உலகத்தில் அன்பு கூர்ந்தார்.
	<br /><br />
	17. உலகத்தை ஆக்கினைக்குள்ளாகத் தீர்க்கும்படி தேவன் தம்முடைய குமாரனை உலகத்தில் அனுப்பாமல், அவராலே உலகம் இரட்சிக்கப்படுவதற்காகவே அவரை அனுப்பினார்.
	<br /><br />
	18. அவரை விசுவாசிக்கிறவன் ஆக்கினைக்குள்ளாகத் தீர்க்கப்படான்; விசுவாசியாதவனோ, தேவனுடைய ஒரேபேறான குமாரனுடைய நாமத்தில் விசுவாசமுள்ளவனாயிராதபடியினால், அவன் ஆக்கினைத் தீர்ப்புக்குட்பட்டாயிற்று.
	<br /><br />
	19. ஒளியானது உலகத்திலே வந்திருந்தும் மனுஷருடைய கிரியைகள் பொல்லாதவைகளாயிருக்கிற படியினால் அவர்கள் ஒளியைப்பார்க்கிலும் இருளை விரும்புகிறதே அந்த ஆக்கினைத்தீர்ப்புக்குக் காரணமாயிருக்கிறது.
	<br /><br />
	20. பொல்லாங்கு செய்கிற எவனும் ஒளியைப் பகைக்கிறான், தன் கிரியைகள் கண்டிக்கப்படாதபடிக்கு, ஒளியினிடத்தில் வராதிருக்கிறான்.
	<br /><br />
	21. சத்தியத்தின்படி செய்கிறவனோ, தன் கிரியைகள் தேவனுக்குள்ளாய்ச் செய்யப்படுகிறதென்று வெளியாகும்படிக்கு, ஒளியினிடத்தில் வருகிறான் என்றார்.
	<br /><br />
	22. இவைகளுக்குப்பின்பு, இயேசுவும் அவருடைய சீஷரும் யூதேயா தேசத்திற்கு வந்தார்கள்; அங்கே அவர் அவர்களோடே சஞ்சரித்து, ஞானஸ்நானங்கொடுத்துவந்தார்.
	<br /><br />
	23. சாலிம் ஊருக்குச் சமீபமான அயினோன் என்னும் இடத்திலே தண்ணீர் மிகுதியாயிருந்தபடியினால், யோவானும் அங்கே ஞானஸ்நானங்கொடுத்துவந்தான்; ஜனங்கள் அவனிடத்தில் வந்து ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	24. அக்காலத்தில் யோவான் காவலில் வைக்கப்பட்டிருக்கவில்லை.
	<br /><br />
	25. அப்பொழுது யோவானுடைய சீஷரில் சிலருக்கும் யூதருக்கும் சுத்திகரிப்பைக்குறித்து வாக்குவாதமுண்டாயிற்று.
	<br /><br />
	26. அவர்கள் யோவானிடத்தில் வந்து: ரபீ, உம்முடனேகூட யோர்தானுக்கு அக்கரையில் ஒருவர் இருந்தாரே; அவரைக் குறித்து நீரும் சாட்சிகொடுத்தீரே, இதோ, அவர் ஞானஸ்நானங்கொடுக்கிறார், எல்லாரும் அவரிடத்தில் போகிறார்கள் என்றார்கள்.
	<br /><br />
	27. யோவான் பிரதியுத்தரமாக: பரலோகத்திலிருந்து ஒருவனுக்குக் கொடுக்கப்பட்டாலொழிய, அவன் ஒன்றையும் பெற்றுக்கொள்ளமாட்டான்.
	<br /><br />
	28. நான் கிறிஸ்துவல்ல, அவருக்கு முன்னாக அனுப்பப்பட்டவன் என்று நான் சொன்னதற்கு நீங்களே சாட்சிகள்.
	<br /><br />
	29. மணவாட்டியை உடையவனே மணவாளன்; மணவாளனுடைய தோழனோ, அருகே நின்று, அவருடைய சொல்லைக் கேட்கிறவனாய் மணவாளனுடைய சத்தத்தைக்குறித்து மிகவும் சந்தோஷப்படுகிறான்; இந்தச் சந்தோஷம் இப்பொழுது எனக்குச் சம்பூரணமாயிற்று.
	<br /><br />
	30. அவர் பெருகவும் நான் சிறுகவும் வேண்டும்.
	<br /><br />
	31. உன்னதத்திலிருந்து வருகிறவர் எல்லாரிலும் மேலானவர்; பூமியிலிருந்துண்டானவன் பூமியின் தன்மையுள்ளவனாயிருந்து, பூமிக்கடுத்தவைகளைப் பேசுகிறான்; பரலோகத்திலிருந்து வருகிறவர் எல்லாரிலும் மேலானவர்.
	<br /><br />
	32. தாம் கண்டதையும் கேட்டதையும் சாட்சியாகச் சொல்லுகிறார்; அவருடைய சாட்சியை ஒருவனும் ஏற்றுக்கொள்ளுகிறதில்லை.
	<br /><br />
	33. அவருடைய சாட்சியை ஏற்றுக்கொள்ளுகிறவன் தேவன் சத்தியமுள்ளவரென்று முத்திரைபோட்டு நிச்சயப்படுத்துகிறான்.
	<br /><br />
	34. தேவனால் அனுப்பப்பட்டவர் தேவனுடைய வார்த்தைகளைப் பேசுகிறார்; தேவன் அவருக்குத் தமது ஆவியை அளவில்லாமல் கொடுத்திருக்கிறார்.
	<br /><br />
	35. பிதாவானவர் குமாரனில் அன்பாயிருந்து எல்லாவற்றையும் அவர் கையில் ஒப்புக்கொடுத்திருக்கிறார்.
	<br /><br />
	36. குமாரனிடத்தில் விசுவாசமாயிருக்கிறவன் நித்தியஜீவனை உடையவனாயிருக்கிறான்; குமாரனை விசுவாசியாதவனோ ஜீவனைக் காண்பதில்லை, தேவனுடைய கோபம் அவன்மேல் நிலைநிற்கும் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John3