import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 7 </title>
	<meta
          name="description"
          content="Acts 7 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 7 |
          Acts of Apostles Chapter-7 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பிரதான ஆசாரியன் அவனை நோக்கி: காரியம் இப்படியாயிருக்கிறது என்று கேட்டான்.
	<br /><br />
	2. அதற்கு அவன்: சகோதரரே, பிதாக்களே, கேளுங்கள். நம்முடைய பிதாவாகிய ஆபிரகாம் காரானூரிலே குடியிருக்கிறதற்கு முன்னமே மெசொப்பொத்தாமியா நாட்டிலே இருக்கும்போது மகிமையின் தேவன் அவனுக்குத் தரிசனமாகி:
	<br /><br />
	3. நீ உன் தேசத்தையும் உன் இனத்தையும் விட்டுப் புறப்பட்டு, நான் உனக்குக் காண்பிக்கும் தேசத்துக்கு வா என்றார்.
	<br /><br />
	4. அப்பொழுது அவன் கல்தேயர் தேசத்தைவிட்டுப் புறப்பட்டு, காரானூரிலே வாசம்பண்ணினான். அவனுடைய தகப்பன் மரித்தபின்பு, அவ்விடத்தை விட்டு நீங்கள் இப்பொழுது குடியிருக்கிற இத்தேசத்திற்கு அவனை அழைத்துக்கொண்டுவந்து குடியிருக்கும்படி செய்தார்.
	<br /><br />
	5. இதிலே ஒரு அடி நிலத்தையாகிலும் அவனுடைய கையாட்சிக்குக் கொடாமலிருக்கையில், அவனுக்குப் பிள்ளையில்லாதிருக்கும்போது: உனக்கும் உனக்குப் பின்வரும் உன் சந்ததிக்கும் இதைச் சுதந்தரமாகத் தருவேன் என்று அவனுக்கு வாக்குத்தத்தம்பண்ணினார்.
	<br /><br />
	6. அந்தப்படி தேவன் அவனை நோக்கி: உன் சந்ததியார் அந்நிய தேசத்தில் சஞ்சரிப்பார்கள்; அத்தேசத்தார் அவர்களை அடிமைகளாக்கி, நானூறு வருஷம் துன்பப்படுத்துவார்கள்.
	<br /><br />
	7. அவர்களை அடிமைப்படுத்தும் ஜனத்தையோ நான் ஆக்கினைக்குட்படுத்துவேன். அதற்குப்பின்பு அவர்கள் புறப்பட்டுவந்து இவ்விடத்திலே எனக்கு ஆராதனை செய்வார்கள் என்றார்.
	<br /><br />
	8. மேலும் விருத்தசேதன உடன்படிக்கையையும் அவனுக்கு ஏற்படுத்தினார். அந்தப்படியே அவன் ஈசாக்கைப் பெற்றபோது, எட்டாம் நாளிலே அவனை விருத்தசேதனம்பண்ணினான். ஈசாக்கு யாக்கோபையும், யாக்கோபு பன்னிரண்டு கோத்திரப்பிதாக்களையும் பெற்றார்கள்.
	<br /><br />
	9. அந்தக் கோத்திரப்பிதாக்கள் பொறாமைகொண்டு யோசேப்பை எகிப்துக்குக் கொண்டுபோகும்படியாக விற்றுப்போட்டார்கள்.
	<br /><br />
	10. தேவனோ அவனுடனேகூட இருந்து, எல்லா உபத்திரவங்களினின்றும் அவனை விடுவித்து, எகிப்தின் ராஜாவாகிய பார்வோன் சமுகத்திலே அவனுக்குக் கிருபையையும் ஞானத்தையும் அருளினார்; அந்த ராஜா அவனை எகிப்துதேசத்திற்கும் தன் வீடனைத்திற்கும் அதிகாரியாக ஏற்படுத்தினான்.
	<br /><br />
	11. பின்பு எகிப்து கானான் என்னும் தேசங்களிலெங்கும் பஞ்சமும் மிகுந்த வருத்தமும் உண்டாகி, நம்முடைய பிதாக்களுக்கு ஆகாரம் கிடையாமற்போயிற்று.
	<br /><br />
	12. அப்பொழுது எகிப்திலே தானியம் உண்டென்று யாக்கோபு கேள்விப்பட்டு, நம்முடைய பிதாக்களை முதலாந்தரம் அனுப்பினான்.
	<br /><br />
	13. இரண்டாந்தரம் யோசேப்பு தன்னுடைய சகோதரருக்குத் தன்னைத் தெரியப்படுத்தினான். யோசேப்புடைய வம்சமும் பார்வோனுக்குத் தெரியவந்தது.
	<br /><br />
	14. பின்பு யோசேப்பு, தன்னுடைய தகப்பன் யாக்கோபும் தன்னுடைய இனத்தார் யாவருமாகிய, எழுபத்தைந்துபேரை அழைக்க அனுப்பினான்.
	<br /><br />
	15. அந்தப்படி யாக்கோபு எகிப்துக்குப் போனான். அவனும் நம்முடைய பிதாக்களும் மரித்து,
	<br /><br />
	16. அங்கேயிருந்து சீகேமுக்குக் கொண்டுவரப்பட்டு, ஆபிரகாம் சீகேமின் தகப்பனாகிய ஏமோருடைய சந்ததியாரிடத்தில் ரொக்கக்கிரயத்துக்கு வாங்கியிருந்த கல்லறையில் வைக்கப்பட்டார்கள்.
	<br /><br />
	17. ஆபிரகாமுக்குத் தேவன் ஆணையிட்டு அருளின வாக்குத்தத்தம் நிறைவேறுங்காலம் சமீபித்தபோது,
	<br /><br />
	18. யோசேப்பை அறியாத வேறொரு ராஜா தோன்றின காலமளவும், ஜனங்கள் எகிப்திலே பலுகிப் பெருகினார்கள்.
	<br /><br />
	19. அவன் நம்முடைய ஜனங்களை வஞ்சனையாய் நடப்பித்து, நம்முடைய பிதாக்களின் குழந்தைகள் உயிரோடிராதபடிக்கு அவர்கள் அவைகளை வெளியே போட்டுவிடும்படி செய்து, அவர்களை உபத்திரவப்படுத்தினான்.
	<br /><br />
	20. அக்காலத்திலே மோசே பிறந்து, திவ்விய சவுந்தரியமுள்ளவனாயிருந்து, மூன்று மாதமளவும் தன் தகப்பன் வீட்டிலே வளர்க்கப்பட்டான்.
	<br /><br />
	21. அவன் வெளியே போட்டுவிடப்பட்டபோது, பார்வோனுடைய குமாரத்தி அவனை எடுத்துத் தனக்குப் பிள்ளையாக வளர்த்தாள்.
	<br /><br />
	22. மோசே எகிப்தியருடைய சகல சாஸ்திரங்களிலும் கற்பிக்கப்பட்டு, வாக்கிலும் செய்கையிலும் வல்லவனானான்.
	<br /><br />
	23. அவனுக்கு நாற்பது வயதானபோது, இஸ்ரவேல் புத்திரராகிய தன்னுடைய சகோதரரைக் கண்டு சந்திக்கும்படி அவனுடைய இருதயத்தில் எண்ணமுண்டாயிற்று.
	<br /><br />
	24. அப்பொழுது அவர்களில் ஒருவன் அநியாயமாய் நடத்தப்படுகிறதை அவன் கண்டு, அவனுக்குத் துணைநின்று, எகிப்தியனை வெட்டி, துன்பப்பட்டவனுக்கு நியாயஞ்செய்தான்.
	<br /><br />
	25. தன்னுடைய கையினாலே தேவன் தங்களுக்கு இரட்சிப்பைத் தருவாரென்பதைத் தன்னுடைய சகோதரர் அறிந்துகொள்வார்களென்று அவன் நினைத்தான்; அவர்களோ அதை அறியவில்லை.
	<br /><br />
	26. மறுநாளிலே சண்டைபண்ணிகொண்டிருக்கிற இரண்டுபேருக்கு அவன் எதிர்ப்பட்டு: மனுஷரே, நீங்கள் சகோதரராயிருக்கிறீர்கள்: ஒருவருக்கொருவர் அநியாயஞ்செய்கிறதென்ன என்று, அவர்களைச் சமாதானப்படுத்தும்படி பேசினான்.
	<br /><br />
	27. பிறனுக்கு அநியாயஞ்செய்தவன் அவனைப் பிடித்துத் தள்ளி: எங்கள்மேல் அதிகாரியாகவும் நியாயாதிபதியாகவும் உன்னை ஏற்படுத்தினவன் யார்?
	<br /><br />
	28. நேற்று நீ அந்த எகிப்தியனைக் கொன்றதுபோல என்னையும் கொன்றுபோட மனதாயிருக்கிறாயோ என்றான்.
	<br /><br />
	29. இந்த வார்த்தையினிமித்தம் மோசே ஓடிப்போய், மீதியான் தேசத்திலே சஞ்சரித்துக்கொண்டிருந்தான்; அங்கே இருக்கும்போது அவனுக்கு இரண்டு குமாரர்கள் பிறந்தார்கள்.
	<br /><br />
	30. நாற்பது வருஷம் சென்றபின்பு, சீனாய்மலையின் வனாந்தரத்திலே கர்த்தருடைய தூதனானவர் முட்செடி எரிகிற அக்கினிஜுவாலையிலே அவனுக்குத் தரிசனமானார்.
	<br /><br />
	31. மோசே அந்தத் தரிசனத்தைக் கண்டு, அதிசயப்பட்டு, அதை உற்றுப்பார்க்கும்படி சமீபித்துவருகையில்:
	<br /><br />
	32. நான் ஆபிரகாமின் தேவனும் ஈசாக்கின் தேவனும் யாக்கோபின் தேவனுமாகிய உன் பிதாக்களுடைய தேவனாயிருக்கிறேன் என்று கர்த்தர் திருவுளம்பற்றின சத்தம் அவனுக்கு உண்டாயிற்று. அப்பொழுது மோசே நடுக்கமடைந்து, உற்றுப்பார்க்கத் துணியாமலிருந்தான்.
	<br /><br />
	33. பின்னும் கர்த்தர் அவனை நோக்கி: உன் பாதங்களிலிருக்கிற பாதரட்சைகளைக் கழற்றிப்போடு; நீ நிற்கிற இடம் பரிசுத்த பூமியாயிருக்கிறது.
	<br /><br />
	34. எகிப்திலிருக்கிற என் ஜனத்தின் உபத்திரவத்தை நான் பார்க்கவே பார்த்து, அவர்கள் பெருமூச்சைக்கேட்டு, அவர்களை விடுவிக்கும்படி இறங்கினேன்; ஆகையால், நீ வா, நான் உன்னை எகிப்திற்கு அனுப்புவேன் என்றார்.
	<br /><br />
	35. உன்னை அதிகாரியாகவும் நியாயாதிபதியாகவும் ஏற்படுத்தினவன் யார் என்று சொல்லி அவர்கள் மறுதலித்திருந்த இந்த மோசேயைத்தானே தேவன், முட்செடியில் அவனுக்குத் தரிசனமான தூதனாலே, தலைவனாகவும் மீட்பனாகவும் அனுப்பினார்.
	<br /><br />
	36. இவனே அவர்களை அங்கேயிருந்து அழைத்துக்கொண்டுவந்து, எகிப்து தேசத்திலேயும் சிவந்த சமுத்திரத்திலேயும், நாற்பது வருஷகாலமாய் வனாந்தரத்திலேயும், அற்புதங்களையும் அடையாளங்களையும் செய்தான்.
	<br /><br />
	37. இஸ்ரவேல் புத்திரரை நோக்கி: உங்கள் தேவனாகிய கர்த்தர் உங்கள் சகோதரரிலிருந்து என்னைப்போல ஒரு தீர்க்கதரிசியை உங்களுக்காக எழும்பப்பண்ணுவார், அவருக்குச் செவிகொடுப்பீர்களாக என்று சொன்னவன் இந்த மோசேயே.
	<br /><br />
	38. சீனாய்மலையில் தன்னுடனே பேசின தூதனோடும் நம்முடைய பிதாக்களோடுங்கூட வனாந்தரத்திலே சபைக்குள்ளிருந்தவனும், நமக்குக் கொடுக்கும்படி ஜீவவாக்கியங்களைப் பெற்றவனும் இவனே.
	<br /><br />
	39. இவனுக்கு நம்முடைய பிதாக்கள் கீழ்ப்படிய மனதாயிராமல், இவனைத் தள்ளிவிட்டு, தங்கள் இருதயங்களில் எகிப்துக்குத் திரும்பி,
	<br /><br />
	40. ஆரோனை நோக்கி: எகிப்துதேசத்திலிருந்து எங்களை அழைத்துக்கொண்டுவந்த அந்த மோசேக்கு என்ன சம்பவித்ததோ அறியோம்; ஆதலால் எங்களுக்கு முன்செல்லும் தெய்வங்களை எங்களுக்கு உண்டுபண்ணும் என்று சொல்லி;
	<br /><br />
	41. அந்நாட்களில் ஒரு கன்றுக்குட்டியை உண்டுபண்ணி, அந்த விக்கிரகத்திற்குப் பலியிட்டு, தங்கள் கையின் கிரியைகளில் களிகூர்ந்தார்கள்.
	<br /><br />
	42. அப்பொழுது தேவன் அவர்களை விட்டு விலகி, வானசேனைக்கு ஆராதனைசெய்ய அவர்களை ஒப்புக்கொடுத்தார். அதைக்குறித்து: இஸ்ரவேல் வம்சத்தாரே, நீங்கள் வனாந்தரத்திலிருந்த நாற்பது வருஷம்வரையில் காணிக்கைகளையும் பலிகளையும் எனக்குச் செலுத்தினீர்களோ என்றும்,
	<br /><br />
	43. பணிந்துகொள்ளும்படி நீங்கள் உண்டாக்கின சொரூபங்களாகிய மோளோகினுடைய கூடாரத்தையும், உங்கள் தேவனாகிய ரெம்பான் என்னும் நட்சத்திர சொரூபத்தையும் சுமந்தீர்களே; ஆகையால் உங்களைப் பாபிலோனுக்கு அப்புறத்திலே குடிபோகப்பண்ணுவேன் என்றும், தீர்க்கதரிசிகளின் புஸ்தகத்தில் எழுதியிருக்கிறதே.
	<br /><br />
	44. மேலும் நீ பார்த்த மாதிரியின்படியே சாட்சியின் கூடாரத்தை உண்டுபண்ணுவாயாக என்று மோசேயுடனே பேசினவர் கட்டளையிட்ட பிரகாரமாக, அந்தக் கூடாரம் வனாந்தரத்திலே நம்முடைய பிதாக்களோடு இருந்தது.
	<br /><br />
	45. மேலும், யோசுவாவுடனேகூட நம்முடைய பிதாக்கள் அதைப் பெற்றுக்கொண்டு, தேவன் அவர்களுக்கு முன்பாகத் துரத்திவிட்ட புறஜாதிகளுடைய தேசத்தை அவர்கள் கட்டிக்கொள்ளுகையில், அதை அந்தத் தேசத்தில் கொண்டுவந்து, தாவீதின் நாள்வரைக்கும் வைத்திருந்தார்கள்.
	<br /><br />
	46. இவன் தேவனிடத்தில் தயவு பெற்றபடியினால், யாக்கோபின் தேவனுக்கு ஒரு வாசஸ்தலத்தைத் தான் கட்டவேண்டுமென்று விண்ணப்பம்பண்ணினான்.
	<br /><br />
	47. சாலொமோனோ அவருக்கு ஆலயத்தைக் கட்டினான்.
	<br /><br />
	48. ஆகிலும் உன்னதமானவர் கைகளினால் செய்யப்பட்ட ஆலயங்களில் வாசமாயிரார்.
	<br /><br />
	49. வானம் எனக்குச் சிங்காசனமும் பூமி எனக்குப் பாதபடியுமாயிருக்கிறது; எனக்காக நீங்கள் எப்படிப்பட்ட வீட்டைக் கட்டுவீர்கள்; நான் தங்கியிருக்கத்தக்க ஸ்தலம் எது;
	<br /><br />
	50. இவைகள் எல்லாவற்றையும் என்னுடைய கரம் உண்டாக்கவில்லையா என்று கர்த்தர் உரைக்கிறார் என்று தீர்க்கதரிசி சொல்லியிருக்கிறானே.
	<br /><br />
	51. வணங்காக் கழுத்துள்ளவர்களே, இருதயத்திலும் செவிகளிலும் விருத்தசேதனம் பெறாதவர்களே, உங்கள் பிதாக்களைப்போல நீங்களும் பரிசுத்தஆவிக்கு எப்பொழுதும் எதிர்த்துநிற்கிறீர்கள்.
	<br /><br />
	52. தீர்க்கதரிசிகளில் யாரை உங்கள் பிதாக்கள் துன்பப்படுத்தாமலிருந்தார்கள்? நீதிபரருடைய வருகையை முன்னறிவித்தவர்களையும் அவர்கள் கொலைசெய்தார்கள். இப்பொழுது நீங்கள் அவருக்குத் துரோகிகளும் அவரைக் கொலைசெய்த பாதகருமாயிருக்கிறீர்கள்.
	<br /><br />
	53. தேவதூதரைக்கொண்டு நீங்கள் நியாயப்பிரமாணத்தைப் பெற்றிருந்தும், அதைக் கைக்கொள்ளாமற்போனீர்கள் என்றான்.
	<br /><br />
	54. இவைகளை அவர்கள் கேட்டபொழுது, மூர்க்கமடைந்து, அவனைப் பார்த்துப் பல்லைக் கடித்தார்கள்.
	<br /><br />
	55. அவன் பரிசுத்தஆவியினாலே நிறைந்தவனாய், வானத்தை அண்ணாந்துபார்த்து, தேவனுடைய மகிமையையும், தேவனுடைய வலதுபாரிசத்தில் இயேசுவானவர் நிற்கிறதையும் கண்டு:
	<br /><br />
	56. அதோ வானங்கள் திறந்திருக்கிறதையும், மனுஷகுமாரன் தேவனுடைய வலதுபாரிசத்தில் நிற்கிறதையும் காண்கிறேன் என்றான்.
	<br /><br />
	57. அப்பொழுது அவர்கள் உரத்தசத்தமாய்க் கூக்குரலிட்டுத் தங்கள் காதுகளை அடைத்துக்கொண்டு, ஒருமனப்பட்டு அவன்மேல் பாய்ந்து,
	<br /><br />
	58. அவனை நகரத்துக்குப் புறம்பே தள்ளி, அவனைக் கல்லெறிந்தார்கள். சாட்சிக்காரர் தங்கள் வஸ்திரங்களைக் கழற்றி, சவுல் என்னப்பட்ட ஒரு வாலிபனுடைய பாதத்தினருகே வைத்தார்கள்.
	<br /><br />
	59. அப்பொழுது: கர்த்தராகிய இயேசுவே, என் ஆவியை ஏற்றுக்கொள்ளும் என்று ஸ்தேவான் தொழுதுகொள்ளுகையில், அவனைக் கல்லெறிந்தார்கள்.
	<br /><br />
	60. அவனோ, முழங்காற்படியிட்டு: ஆண்டவரே, இவர்கள்மேல் இந்தப் பாவத்தைச் சுமத்தாதிரும் என்று மிகுந்த சத்தமிட்டுச் சொன்னான். இப்படிச் சொல்லி, நித்திரையடைந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts7