import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 15 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 15
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அன்றியும், பலமுள்ளவர்களாகிய நாம் நமக்கே பிரியமாய் நடவாமல், பலவீனருடைய பலவீனங்களைத் தாங்கவேண்டும்.
	<br /><br />
	2. நம்மில் ஒவ்வொருவனும் பிறனுடைய பக்திவிருத்திக்கேதுவான நன்மையுண்டாகும்படி அவனுக்குப் பிரியமாய் நடக்கக்கடவன்.
	<br /><br />
	3. கிறிஸ்துவும் தமக்கே பிரியமாய் நடவாமல்: உம்மை நிந்திக்கிறவர்களுடைய நிந்தைகள் என்மேல் விழுந்தது என்று எழுதியிருக்கிறபடியே நடந்தார்.
	<br /><br />
	4. தேவவசனத்தினால் உண்டாகும் பொறுமையினாலும் ஆறுதலினாலும் நாம் நம்பிக்கையுள்ளவர்களாகும்படிக்கு, முன்பு எழுதியிருக்கிறவைகளெல்லாம் நமக்குப் போதனையாக எழுதியிருக்கிறது.
	<br /><br />
	5. நீங்கள் ஒருமனப்பட்டு நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் பிதாவாகிய தேவனை ஒரே வாயினால் மகிமைப்படுத்தும்படிக்கு,
	<br /><br />
	6. பொறுமையையும் ஆறுதலையும் அளிக்கும் தேவன், கிறிஸ்து இயேசுவினுடைய மாதிரியின்படியே, நீங்கள் ஏகசிந்தையுள்ளவர்களாயிருக்கும்படி உங்களுக்கு அநுக்கிரகஞ்செய்வாராக.
	<br /><br />
	7. ஆதலால் தேவனுக்கு மகிமையுண்டாக, கிறிஸ்து நம்மை ஏற்றுக்கொண்டதுபோல, நீங்களும் ஒருவரையொருவர் ஏற்றுக்கொள்ளுங்கள்.
	<br /><br />
	8. மேலும், பிதாக்களுக்குப் பண்ணப்பட்ட வாக்குத்தத்தங்களை உறுதியாக்கும்படிக்கு, தேவனுடைய சத்தியத்தினிமித்தம் இயேசுகிறிஸ்து விருத்தசேதனமுள்ளவர்களுக்கு ஊழியக்காரரானாரென்றும்;
	<br /><br />
	9. புறஜாதியாரும் இரக்கம் பெற்றதினிமித்தம் தேவனை மகிமைப்படுத்துகிறார்களென்றும் சொல்லுகிறேன். அந்தப்படி: இதினிமித்தம் நான் புறஜாதிகளுக்குள்ளே உம்மை அறிக்கைபண்ணி, உம்முடைய நாமத்தைச் சொல்லி, சங்கீதம் பாடுவேன் என்று எழுதியிருக்கிறது.
	<br /><br />
	10. மேலும், புறஜாதிகளே, அவருடைய ஜனங்களோடேகூடக் களிகூருங்கள் என்கிறார்.
	<br /><br />
	11. மேலும், புறஜாதிகளே, எல்லாரும் கர்த்தரைத் துதியுங்கள்; ஜனங்களே, எல்லாரும் அவரைப் புகழுங்கள் என்றும் சொல்லுகிறார்.
	<br /><br />
	12. மேலும், ஈசாயின் வேரும் புறஜாதியாரை ஆளும்படிக்கு எழும்புகிறவருமாகிய ஒருவர் தோன்றுவார்; அவரிடத்தில் புறஜாதியார் நம்பிக்கைவைப்பார்கள் என்று ஏசாயா சொல்லுகிறான்.
	<br /><br />
	13. பரிசுத்தஆவியின் பலத்தினாலே உங்களுக்கு நம்பிக்கை பெருகும்படிக்கு, நம்பிக்கையின் தேவன் விசுவாசத்தினால் உண்டாகும் எல்லாவித சந்தோஷத்தினாலும் சமாதானத்தினாலும் உங்களை நிரப்புவாராக.
	<br /><br />
	14. என் சகோதரரே, நீங்கள் நற்குணத்தினால் நிறைந்தவர்களும், சகல அறிவினாலும் நிரப்பப்பட்டவர்களும், ஒருவருக்கொருவர் புத்திசொல்ல வல்லவர்களுமாயிருக்கிறீர்களென்று நானும் உங்களைக் குறித்து நிச்சயித்திருக்கிறேன்.
	<br /><br />
	15. அப்படியிருந்தும், சகோதரரே, புறஜாதியாராகிய பலி பரிசுத்தஆவியினாலே பரிசுத்தமாக்கப்பட்டு, தேவனுக்குப் பிரியமான பலியாகும்படிக்கு, நான் தேவனுடைய சுவிசேஷ ஊழியத்தை நடத்தும் ஆசாரியனாயிருந்து புறஜாதிகளுக்கு இயேசுகிறிஸ்துவினுடைய ஊழியக்காரனாகும்பொருட்டு,
	<br /><br />
	16. தேவன் எனக்கு அளித்த கிருபையினாலே உங்களுக்கு ஞாபகப்படுத்தும்படிக்கு இவைகளை நான் அதிக தைரியமாய் எழுதினேன்.
	<br /><br />
	17. ஆதலால் நான் தேவனுக்குரியவைகளைக்குறித்து இயேசுகிறிஸ்துவைக்கொண்டு மேன்மைபாராட்ட எனக்கு இடமுண்டு.
	<br /><br />
	18. புறஜாதியாரை வார்த்தையினாலும் செய்கையினாலும் கீழ்ப்படியப்பண்ணும்படிக்கு, அற்புத அடையாளங்களின் பலத்தினாலும், தேவ ஆவியின் பலத்தினாலும், கிறிஸ்துவானவர் என்னைக்கொண்டு நடப்பித்தவைகளைச் சொல்வதல்லாமல் வேறொன்றையும் சொல்ல நான் துணிவதில்லை.
	<br /><br />
	19. இப்படி எருசலேம் துவக்கிச் சுற்றிலும், இல்லிரிக்கம் தேசம்வரைக்கும், கிறிஸ்துவின் சுவிசேஷத்தைப் பூரணமாய்ப் பிரசங்கித்திருக்கிறேன்.
	<br /><br />
	20. மேலும் அவருடைய செய்தியை அறியாதிருந்தவர்கள் காண்பார்களென்றும், கேள்விப்படாதிருந்தவர்கள் உணர்ந்துகொள்வார்களென்றும் எழுதியிருக்கிறபடியே,
	<br /><br />
	21. நான் மற்றொருவனுடைய அஸ்திபாரத்தின்மேல் கட்டாதபடிக்கு கிறிஸ்துவினுடைய நாமம் சொல்லப்பட்டிராத இடங்களில் சுவிசேஷத்தை அறிவிக்கும்படி நாடுகிறேன்.
	<br /><br />
	22. உங்களிடத்தில் வருகிறதற்கு இதினாலே அநேகந்தரம் தடைபட்டேன்.
	<br /><br />
	23. இப்பொழுது இந்தத் திசைகளிலே எனக்கு இடமில்லாதபடியினாலும், உங்களிடத்தில் வரும்படி அநேக வருஷமாய் எனக்கு மிகுந்த விருப்பம் உண்டாயிருக்கிறபடியினாலும்,
	<br /><br />
	24. நான் ஸ்பானியா தேசத்திற்குப் பிரயாணம்பண்ணுகையில் உங்களிடத்தில் வந்து, உங்களைக் கண்டுகொள்ளவும், உங்களிடத்தில் சற்றுத் திருப்தியடைந்தபின்பு, அவ்விடத்திற்கு உங்களால் வழிவிட்டனுப்பப்படவும், எனக்குச் சமயங் கிடைக்குமென்று நம்பியிருக்கிறேன்.
	<br /><br />
	25. இப்பொழுதோ பரிசுத்தவான்களுக்கு உதவிசெய்யுங்காரியமாக நான் எருசலேமுக்குப் பிரயாணம்பண்ண எத்தனமாயிருக்கிறேன்.
	<br /><br />
	26. மக்கெதோனியாவிலும் அகாயாவிலுமுள்ளவர்கள் எருசலேமிலுள்ள பரிசுத்தவான்களுக்குள்ளே இருக்கிற தரித்திரருக்காகச் சில பொருள்சகாயம் செய்ய விருப்பங்கொண்டிருக்கிறார்கள்;
	<br /><br />
	27. இப்படிச்செய்வது நல்லதென்று எண்ணினார்கள்; இப்படிச் செய்கிறதற்கு அவர்கள் கடனாளிகளாயும் இருக்கிறார்கள். எப்படியென்றால் புறஜாதியார் அவர்களுடைய ஞானநன்மைகளில் பங்குபெற்றிருக்க, சரீரநன்மைகளால் அவர்களுக்கு உதவிசெய்ய இவர்கள் கடனாளிகளாயிருக்கிறார்களே.
	<br /><br />
	28. இந்தக் காரியத்தை நான் நிறைவேற்றி, இந்தப் பலனை அவர்கள் கையிலே பத்திரமாய் ஒப்புவித்தபின்பு, உங்கள் ஊர் வழியாய் ஸ்பானியாவுக்குப் போவேன்.
	<br /><br />
	29. நான் உங்களிடத்தில் வரும்போது கிறிஸ்துவினுடைய சுவிசேஷத்தின் சம்பூரணமான ஆசீர்வாதத்தோடே வருவேனென்று அறிந்திருக்கிறேன்.
	<br /><br />
	30. மேலும் சகோதரரே, தேவசித்தத்தினாலே நான் சந்தோஷத்துடனே உங்களிடத்தில் வந்து உங்களோடு இளைப்பாறும்படியாக,
	<br /><br />
	31. யூதேயாவிலிருக்கிற அவிசுவாசிகளுக்கு நான் தப்புவிக்கப்படும்படிக்கும், நான் எருசலேமிலுள்ள பரிசுத்தவான்களுக்குச் செய்யப்போகிற தர்மசகாயம் அவர்களால் அங்கிகரிக்கப்படும்படிக்கும்,
	<br /><br />
	32. நீங்கள் தேவனை நோக்கிச் செய்யும் ஜெபங்களில், நான் போராடுவதுபோல நீங்களும் என்னோடுகூடப் போராடவேண்டுமென்று நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினிமித்தமும், ஆவியானவருடைய அன்பினிமித்தமும், உங்களை வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	33. சமாதானத்தின் தேவன் உங்களனைவரோடுங்கூட இருப்பாராக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans15