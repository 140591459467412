import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneJohn2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Christ is Our Advocate | 1 John in Tamil Bible | 1 யோவான்- 2 </title>
	<meta
          name="description"
          content="Christ is Our Advocate | 1st John in Tamil Bible Verse | 1-யோவான்அதிகாரம் - 2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கிறிஸ்து நமது வக்கீல் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. என் பிள்ளைகளே, நீங்கள் பாவஞ்செய்யாதபடிக்கு இவைகளை உங்களுக்கு எழுதுகிறேன்; ஒருவன் பாவஞ்செய்வானானால் நீதிபரராயிருக்கிற இயேசுகிறிஸ்து நமக்காகப் பிதாவினிடத்தில் பரிந்து பேசுகிறவராயிருக்கிறார்.
	<br /><br />
	2. நம்முடைய பாவங்களை நிவிர்த்தி செய்கிற கிருபாதாரபலி அவரே; நம்முடைய பாவங்களை மாத்திரம் அல்ல, சர்வலோகத்தின் பாவங்களையும் நிவிர்த்தி செய்கிற பலியாயிருக்கிறார்.
	<br /><br />
	3. அவருடைய கற்பனைகளை நாம் கைக்கொள்ளுகிறவர்களானால், அவரை அறிந்திருக்கிறோமென்பதை அதினால் அறிவோம்.
	<br /><br />
	4. அவரை அறிந்திருக்கிறேனென்று சொல்லியும், அவருடைய கற்பனைகளைக் கைக்கொள்ளாதவன் பொய்யனாயிருக்கிறான், அவனுக்குள் சத்தியமில்லை.
	<br /><br />
	5. அவருடைய வசனத்தைக் கைக்கொள்ளுகிறவனிடத்தில் தேவ அன்பு மெய்யாகப் பூரணப்பட்டிருக்கும்; நாம் அவருக்குள் இருக்கிறோமென்பதை அதினாலே அறிந்திருக்கிறோம்.
	<br /><br />
	6. அவருக்குள் நிலைத்திருக்கிறேனென்று சொல்லுகிறவன், அவர் நடந்தபடியே தானும் நடக்கவேண்டும்.
	<br /><br />
	7. சகோதரரே, நான் உங்களுக்குப் புதிய கற்பனையை அல்ல, ஆதிமுதல் நீங்கள் பெற்றிருக்கிற பழைய கற்பனையையே எழுதுகிறேன்; அந்தப் பழைய கற்பனை நீங்கள் ஆதிமுதல் கேட்டிருக்கிற வசனந்தானே.
	<br /><br />
	8. மேலும், நான் புதிய கற்பனையையும் உங்களுக்கு எழுதுகிறேன், இது அவருக்குள்ளும் உங்களுக்குள்ளும் மெய்யாயிருக்கிறது; ஏனென்றால், இருள் நீங்கிப்போகிறது, மெய்யான ஒளி இப்பொழுது பிரகாசிக்கிறது.
	<br /><br />
	9. ஒளியிலே இருக்கிறேன் என்று சொல்லியும் தன் சகோதரனைப் பகைக்கிறவன் இதுவரைக்கும் இருளிலே இருக்கிறான்.
	<br /><br />
	10. தன் சகோதரனிடத்தில் அன்புகூருகிறவன் ஒளியிலே நிலைகொண்டிருக்கிறான்; அவனிடத்தில் இடறல் ஒன்றுமில்லை.
	<br /><br />
	11. தன் சகோதரனைப் பகைக்கிறவன் இருளிலே இருந்து இருளிலே நடக்கிறான்; இருளானது அவன் கண்களைக் குருடாக்கினபடியால் தான் போகும் இடம் இன்னதென்று அறியாதிருக்கிறான்.
	<br /><br />
	12. பிள்ளைகளே, அவருடைய நாமத்தினிமித்தம் உங்கள் பாவங்கள் மன்னிக்கப்பட்டிருக்கிறதினால் உங்களுக்கு எழுதுகிறேன்.
	<br /><br />
	13. பிதாக்களே, ஆதிமுதலிருக்கிறவரை நீங்கள் அறிந்திருக்கிறதினால் உங்களுக்கு எழுதுகிறேன். வாலிபரே, பொல்லாங்கனை நீங்கள் ஜெயித்ததினால் உங்களுக்கு எழுதுகிறேன். பிள்ளைகளே, நீங்கள் பிதாவை அறிந்திருக்கிறதினால் உங்களுக்கு எழுதுகிறேன்.
	<br /><br />
	14. பிதாக்களே, ஆதிமுதலிருக்கிறவரை நீங்கள் அறிந்திருக்கிறதினால் உங்களுக்கு எழுதியிருக்கிறேன். வாலிபரே, நீங்கள் பலவான்களாயிருக்கிறதினாலும், தேவவசனம் உங்களில் நிலைத்திருக்கிறதினாலும், நீங்கள் பொல்லாங்கனை ஜெயித்ததினாலும், உங்களுக்கு எழுதியிருக்கிறேன்.
	<br /><br />
	15. உலகத்திலும் உலகத்திலுள்ளவைகளிலும் அன்புகூராதிருங்கள்; ஒருவன் உலகத்தில் அன்புகூர்ந்தால் அவனிடத்தில் பிதாவின் அன்பில்லை.
	<br /><br />
	16. ஏனெனில், மாம்சத்தின் இச்சையும், கண்களின் இச்சையும், ஜீவனத்தின் பெருமையுமாகிய உலகத்திலுள்ளவைகளெல்லாம் பிதாவினாலுண்டானவைகளல்ல, அவைகள் உலகத்தினாலுண்டானவைகள்.
	<br /><br />
	17. உலகமும் அதின் இச்சையும் ஒழிந்துபோம்; தேவனுடைய சித்தத்தின்படி செய்கிறவனோ என்றென்றைக்கும் நிலைத்திருப்பான்.
	<br /><br />
	18. பிள்ளைகளே, இது கடைசிக்காலமாயிருக்கிறது; அந்திக்கிறிஸ்து வருகிறானென்று நீங்கள் கேள்விப்பட்டபடி இப்பொழுதும் அநேக அந்திக்கிறிஸ்துகள் இருக்கிறார்கள்; அதினாலே இது கடைசிக்காலமென்று அறிகிறோம்.
	<br /><br />
	19. அவர்கள் நம்மைவிட்டுப் பிரிந்துபோனார்கள், ஆகிலும் அவர்கள் நம்முடையவர்களாயிருக்கவில்லை; நம்முடையவர்களாயிருந்தார்களானால் நம்முடனே நிலைத்திருப்பார்களே; எல்லாரும் நம்முடையவர்களல்லவென்று வெளியாகும்படிக்கே பிரிந்துபோனார்கள்.
	<br /><br />
	20. நீங்கள் பரிசுத்தராலே அபிஷேகம் பெற்றுச் சகலத்தையும் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	21. சத்தியத்தை நீங்கள் அறியாததினாலல்ல, நீங்கள் சத்தியத்தை அறிந்திருக்கிறதினாலும், சத்தியத்தினால் ஒரு பொய்யுமுண்டாயிராதென்பதை நீங்கள் அறிந்திருக்கிறதினாலும், உங்களுக்கு எழுதியிருக்கிறேன்.
	<br /><br />
	22. இயேசுவைக் கிறிஸ்து அல்ல என்று மறுதலிக்கிறவனேயல்லாமல் வேறே யார் பொய்யன்? பிதாவையும் குமாரனையும் மறுதலிக்கிறவனே அந்திக்கிறிஸ்து.
	<br /><br />
	23. குமாரனை மறுதலிக்கிறவன் பிதாவையுடையவனல்ல, குமாரனை அறிக்கையிடுகிறவன் பிதாவையும் உடையவனாயிருக்கிறான்.
	<br /><br />
	24. ஆகையால் ஆதிமுதல் நீங்கள் கேள்விப்பட்டது உங்களில் நிலைத்திருக்கக்கடவது; ஆதிமுதல் நீங்கள் கேள்விப்பட்டது உங்களில் நிலைத்திருந்தால், நீங்களும் குமாரனிலும் பிதாவிலும் நிலைத்திருப்பீர்கள்.
	<br /><br />
	25. நித்தியஜீவனை அளிப்பேன் என்பதே அவர் நமக்குச் செய்த வாக்குத்தத்தம்.
	<br /><br />
	26. உங்களை வஞ்சிக்கிறவர்களைக்குறித்து இவைகளை உங்களுக்கு எழுதியிருக்கிறேன்.
	<br /><br />
	27. நீங்கள் அவராலே பெற்ற அபிஷேகம் உங்களில் நிலைத்திருக்கிறது, ஒருவரும் உங்களுக்குப் போதிக்கவேண்டுவதில்லை; அந்த அபிஷேகம் சகலத்தையுங்குறித்து உங்களுக்குப் போதிக்கிறது; அது சத்தியமாயிருக்கிறது, பொய்யல்ல, அது உங்களுக்குப் போதித்தபடியே அவரில் நிலைத்திருப்பீர்களாக.
	<br /><br />
	28. இப்படியிருக்க, பிள்ளைகளே, அவர் வெளிப்படும்போது நாம் அவர் வருகையில் அவருக்கு முன்பாக வெட்கப்பட்டுப்போகாமல் தைரியமுள்ளவர்களாயிருக்கும்படிக்கு அவரில் நிலைத்திருங்கள்.
	<br /><br />
	29. அவர் நீதியுள்ளவராயிருக்கிறாரென்று உங்களுக்குத் தெரிந்திருப்பதினால், நீதியைச் செய்கிறவனெவனும் அவரில் பிறந்தவனென்று அறிந்திருக்கிறீர்கள்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* 1 யோவான் 2 பாவத்தைப் பற்றிய சிந்தனையை முடிக்கிறார், முழு உலகத்தின் பாவங்களுக்காகப் பரிகார பலியாக இருந்த இயேசுவில் நம் வழக்கறிஞரை நினைவூட்டுகிறது. இதன் காரணமாக, உண்மையான விசுவாசிகள் கடவுளுடைய வார்த்தைக்குக் கீழ்ப்படிதல் மற்றும் ஒருவருக்கொருவர் அன்பு செலுத்துதல் ஆகிய இரண்டிலும் பதிலளிக்கின்றனர். அடுத்ததாக, உலகப் பொருட்களை நேசிக்க வேண்டாம் என்று நமக்கு நினைவூட்டப்படுகிறது, ஏனென்றால் அவைகள் கடந்து போகும். இயேசுவை கிறிஸ்து என்று மறுத்த, வந்திருக்கும் அந்திக்கிறிஸ்துகளுக்கு எதிரான எச்சரிக்கையுடன் இந்த அத்தியாயம் முடிவடைகிறது. சத்தியத்தை நினைவூட்டுவதற்கும் எல்லாவற்றிலும் நமக்குக் கற்பிப்பதற்கும் விசுவாசிகள் பரிசுத்த ஆவியானவர் என்று யோவான் இருமுறை நினைவூட்டுகிறார். *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OneJohn2