import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title> Jesus Our Example Hebrews Tamil Bible Verse | எபிரெயர் - 12 </title>
	<meta
          name="description"
          content="Jesus is Our Example Hebrews Chapter-12 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-12 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* இயேசுவே நமக்கு முன்மாதிரி *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. ஆகையால், மேகம்போன்ற இத்தனை திரளான சாட்சிகள் நம்மைச் சூழ்ந்துகொண்டிருக்க, பாரமான யாவற்றையும், நம்மைச் சுற்றி நெருங்கிநிற்கிற பாவத்தையும் தள்ளிவிட்டு, விசுவாசத்தைத் துவக்குகிறவரும் முடிக்கிறவருமாயிருக்கிற இயேசுவை நோக்கி, நமக்கு நியமித்திருக்கிற ஓட்டத்தில் பொறுமையோடே ஓடக்கடவோம்;
	<br /><br />
	2. அவர் தமக்குமுன் வைத்திருந்த சந்தோஷத்தின்பொருட்டு, அவமானத்தை எண்ணாமல், சிலுவையைச் சகித்து, தேவனுடைய சிங்காசனத்தின் வலதுபாரிசத்தில் வீற்றிருக்கிறார்.
	<br /><br />
	3. ஆகையால் நீங்கள் இளைப்புள்ளவர்களாய் உங்கள் ஆத்துமாக்களில் சோர்ந்துபோகாதபடிக்கு, தமக்கு விரோதமாய்ப் பாவிகளால் செய்யப்பட்ட இவ்விதமான விபரீதங்களைச் சகித்த அவரையே நினைத்துக்கொள்ளுங்கள்.
	<br /><br />
	4. பாவத்திற்கு விரோதமாய்ப் போராடுகிறதில் இரத்தஞ்சிந்தப்படத்தக்கதாக நீங்கள் இன்னும் எதிர்த்துநிற்கவில்லையே.
	<br /><br />
	5. அன்றியும்: என் மகனே, கர்த்தருடைய சிட்சையை அற்பமாக எண்ணாதே, அவரால் கடிந்துகொள்ளப்படும்போது சோர்ந்துபோகாதே.
	<br /><br />
	6. கர்த்தர் எவனிடத்தில் அன்புகூருகிறாரோ அவனை அவர் சிட்சித்து, தாம் சேர்த்துக்கொள்ளுகிற எந்த மகனையும் தண்டிக்கிறார் என்று பிள்ளைகளுக்குச் சொல்லுகிறதுபோல உங்களுக்குச் சொல்லியிருக்கிற புத்திமதியை மறந்தீர்கள்.
	<br /><br />
	7. நீங்கள் சிட்சையைச் சகிக்கிறவர்களாயிருந்தால் தேவன் உங்களைப் புத்திரராக எண்ணி நடத்துகிறார்; தகப்பன் சிட்சியாத புத்திரனுண்டோ?
	<br /><br />
	8. எல்லாருக்கும் கிடைக்கும் சிட்சை உங்களுக்குக் கிடையாதிருந்தால் நீங்கள் புத்திரராயிராமல் வேசிப்பிள்ளைகளாயிருப்பீர்களே.
	<br /><br />
	9. அன்றியும், நம்முடைய சரீரத்தின் தகப்பன்மார்கள் நம்மைச் சிட்சிக்கும்போது, அவர்களுக்கு நாம் அஞ்சி நடந்திருக்க, நாம் பிழைக்கத்தக்கதாக ஆவிகளின் பிதாவுக்கு வெகு அதிகமாய் அடங்கி நடக்கவேண்டுமல்லவா?
	<br /><br />
	10. அவர்கள் தங்களுக்கு நலமென்று தோன்றினபடி கொஞ்சக்காலம் சிட்சித்தார்கள்; இவரோ தம்முடைய பரிசுத்தத்துக்கு நாம் பங்குள்ளவர்களாகும்பொருட்டு நம்முடைய பிரயோஜனத்துக்காகவே நம்மைச் சிட்சிக்கிறார்.
	<br /><br />
	11. எந்தச் சிட்சையும் தற்காலத்தில் சந்தோஷமாய்க் காணாமல் துக்கமாய்க் காணும்; ஆகிலும் பிற்காலத்தில் அதில் பழகினவர்களுக்கு அது நீதியாகிய சமாதான பலனைத் தரும்.
	<br /><br />
	12. ஆகையினால், நெகிழ்ந்த கைகளையும் தளர்ந்த முழங்கால்களையும் நீங்கள் திரும்ப நிமிர்த்தி,
	<br /><br />
	13. முடமாயிருக்கிறது பிசகிப்போகாமல் சொஸ்தமாகும்படிக்கு, உங்கள் பாதங்களுக்கு வழிகளைச் செவ்வைப்படுத்துங்கள்.
	<br /><br />
	14. யாவரோடும் சமாதானமாயிருக்கவும், பரிசுத்தமுள்ளவர்களாயிருக்கவும் நாடுங்கள்; பரிசுத்தமில்லாமல் ஒருவனும் கர்த்தரைத் தரிசிப்பதில்லையே.
	<br /><br />
	15. ஒருவனும் தேவனுடைய கிருபையை இழந்துபோகாதபடிக்கும் யாதொரு கசப்பான வேர் முளைத்தெழும்பிக் கலக்கமுண்டாக்குகிறதினால் அநேகர் தீட்டுப்படாதபடிக்கும்,
	<br /><br />
	16. ஒருவனும் வேசிக்கள்ளனாகவும், ஒருவேளைப் போஜனத்துக்காகத் தன் சேஷ்டபுத்திரபாகத்தை விற்றுப்போட்ட ஏசாவைப்போலச் சீர்கெட்டவனாகவும் இராதபடிக்கும் எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	17. ஏனென்றால், பிற்பாடு அவன் ஆசீர்வாதத்தைச் சுதந்தரித்துக்கொள்ள விரும்பியும் ஆகாதவனென்று தள்ளப்பட்டதை அறிவீர்கள்; அவன் கண்ணீர்விட்டு, கவலையோடே தேடியும் மனம் மாறுதலைக் காணாமற்போனான்.
	<br /><br />
	18. அன்றியும், தொடக்கூடியதும், அக்கினி பற்றியெரிகிறதுமான மலையினிடத்திற்கும், மந்தாரம் இருள் பெருங்காற்று ஆகிய இவைகளினிடத்திற்கும்,
	<br /><br />
	19. எக்காளமுழக்கத்தினிடத்திற்கும், வார்த்தைகளுடைய சத்தத்தினிடத்திற்கும், நீங்கள் வந்து சேரவில்லை; அந்தச் சத்தத்தைக் கேட்டவர்கள் பின்னும் தங்களுக்கு வார்த்தை சொல்லப்படாதபடிக்கு வேண்டிக்கொண்டார்கள்.
	<br /><br />
	20. ஏனெனில் ஒரு மிருகமாகிலும் மலையைத் தொட்டால், அது கல்லெறியுண்டு, அல்லது அம்பினால் எய்யுண்டு சாகவேண்டுமென்று சொல்லப்பட்ட கட்டளையைச் சகிக்கமாட்டாதிருந்தார்கள்.
	<br /><br />
	21. மோசேயும்: நான் மிகவும் பயந்து நடுங்குகிறேன் என்று சொல்லத்தக்கதாக அந்தக் காட்சி அவ்வளவு பயங்கரமாயிருந்தது.
	<br /><br />
	22. நீங்களோ சீயோன் மலையினிடத்திற்கும், ஜீவனுள்ள தேவனுடைய நகரமாகிய பரம எருசலேமினிடத்திற்கும், ஆயிரம் பதினாயிரமான தேவதூதர்களினிடத்திற்கும்,
	<br /><br />
	23. பரலோகத்தில் பேரெழுதியிருக்கிற முதற்பேறானவர்களின் சர்வசங்கமாகிய சபையினிடத்திற்கும், யாவருக்கும் நியாயாதிபதியாகிய தேவனிடத்திற்கும், பூரணராக்கப்பட்ட நீதிமான்களுடைய ஆவிகளினிடத்திற்கும்,
	<br /><br />
	24. புது உடன்படிக்கையின் மத்தியஸ்தராகிய இயேசுவினிடத்திற்கும், ஆபேலினுடைய இரத்தம் பேசினதைப்பார்க்கிலும் நன்மையானவைகளைப் பேசுகிற இரத்தமாகிய தெளிக்கப்படும் இரத்தத்தினிடத்திற்கும் வந்து சேர்ந்தீர்கள்.
	<br /><br />
	25. பேசுகிறவருக்கு நீங்கள் செவிகொடுக்கமாட்டோமென்று விலகாதபடி எச்சரிக்கையாயிருங்கள்; ஏனெனில், பூமியிலே பேசினவருக்குச் செவிகொடுக்கமாட்டோமென்று விலகினவர்கள் தப்பிப்போகாமலிருக்க, பரலோகத்திலிருந்து பேசுகிறவரை நாம் விட்டுவிலகினால் எப்படித் தப்பிப்போவோம்?
	<br /><br />
	26. அவருடைய சத்தம் அப்பொழுது பூமியை அசையப்பண்ணிற்று; இன்னும் ஒருதரம் நான் பூமியை மாத்திரமல்ல, வானத்தையும் அசையப்பண்ணுவேன் என்று இப்பொழுது வாக்குத்தத்தஞ் செய்திருக்கிறார்.
	<br /><br />
	27. இன்னும் ஒருதரம் என்கிற சொல்லானது அசையாதவைகள் நிலைத்திருக்கத்தக்கதாக, அசைவுள்ளவைகள் உண்டாக்கப்பட்டவைகள்போல மாறிப்போம் என்பதைக் குறிக்கிறது.
	<br /><br />
	28. ஆதலால், அசைவில்லாத ராஜ்யத்தைப் பெறுகிறவர்களாகிய நாம் பயத்தோடும் பக்தியோடும் தேவனுக்குப் பிரியமாய் ஆராதனை செய்யும்படி கிருபையைப் பற்றிக்கொள்ளக்கடவோம்.
	<br /><br />
	29. நம்முடைய தேவன் பட்சிக்கிற அக்கினியாயிருக்கிறாரே.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* எபிரெயர் 12 என்பது கிறிஸ்துவை நம் முன்மாதிரியாகப் பார்த்து விடாமுயற்சியுடன் இருப்பதற்கு ஒரு அழைப்பு. அவர் சிலுவையின் அவமானத்தை சகித்துக்கொண்டு நம்மை தேவனோடு ஐக்கியப்படுத்தினார், அதேபோல் நாம் வாழ்க்கையின் ஓட்டத்தில் ஓடும்போது நாம் அவர்மீது நம் கண்களை வைத்திருக்க வேண்டும். அசைக்க முடியாத ராஜ்ஜியத்தில் குடியுரிமை பெற்றுள்ளோம், எனவே அவருக்கு நன்றியுணர்வைக் காட்டுவோம், ஏற்றுக்கொள்ளக்கூடிய சேவையில் நம் வாழ்க்கையை அர்ப்பணிப்போம். *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews12