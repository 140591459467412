import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 16 </title>
	<meta
          name="description"
          content="Acts 16 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 16 |
          Acts of Apostles Chapter-16 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அதன்பின்பு அவன் தெர்பைக்கும் லீஸ்திராவுக்கும் போனான். அங்கே தீமோத்தேயு என்னப்பட்ட ஒரு சீஷன் இருந்தான்; அவன் தாய் விசுவாசமுள்ள யூதஸ்திரீ, அவன் தகப்பன் கிரேக்கன்.
	<br /><br />
	2. அவன் லீஸ்திராவிலும் இக்கோனியாவிலுமுள்ள சகோதரராலே நற்சாட்சி பெற்றவனாயிருந்தான்.
	<br /><br />
	3. அவனைப் பவுல் தன்னுடனே கூட்டிக்கொண்டு போகவேண்டுமென்று விரும்பி, அவனுடைய தகப்பன் கிரேக்கன் என்று அவ்விடங்களிலிருக்கும் யூதர்களெல்லாரும் அறிந்திருந்தபடியால், அவர்கள் நிமித்தம் அவனுக்கு விருத்தசேதனம்பண்ணினான்.
	<br /><br />
	4. அவர்கள் பட்டணங்கள்தோறும் போகையில், எருசலேமிலிருக்கும் அப்போஸ்தலராலும் மூப்பராலும் விதிக்கப்பட்ட சட்டங்களைக் கைக்கொள்ளும்படி அவர்களுக்கு ஒப்புவித்தார்கள்.
	<br /><br />
	5. அதினாலே சபைகள் விசுவாசத்தில் ஸ்திரப்பட்டு, நாளுக்குநாள் பெருகின.
	<br /><br />
	6. அவர்கள் பிரிகியா கலாத்தியா நாடுகளைக் கடந்துபோனபோது, ஆசியாவிலே வசனத்தைச் சொல்லாதபடிக்குப் பரிசுத்தஆவியினாலே தடைபண்ணப்பட்டு,
	<br /><br />
	7. மீசியா தேசமட்டும் வந்து, பித்தினியா நாட்டுக்குப் போகப் பிரயத்தனம் பண்ணினார்கள்; ஆவியானவரோ அவர்களைப் போகவொட்டாதிருந்தார்.
	<br /><br />
	8. அப்பொழுது அவர்கள் மீசியா பக்கமாய்ப் போய், துரோவாவுக்கு வந்தார்கள்.
	<br /><br />
	9. அங்கே இராத்திரியிலே பவுலுக்கு ஒரு தரிசனம் உண்டாயிற்று; அதென்னவெனில், மக்கெதோனியா தேசத்தான் ஒருவன் வந்துநின்று: நீர் மக்கெதோனியாவுக்கு வந்து எங்களுக்கு உதவிசெய்ய வேண்டுமென்று தன்னை வேண்டிக்கொண்டதாக இருந்தது.
	<br /><br />
	10. அந்தத் தரிசனத்தை அவன் கண்டபோது, அவர்களுக்குச் சுவிசேஷத்தை அறிவிக்கும்படி கர்த்தர் எங்களை அழைத்தாரென்று நாங்கள் நிச்சயித்துக்கொண்டு, உடனே மக்கெதோனியாவுக்குப் புறப்பட்டுப்போகப் பிரயத்தனம்பண்ணி,
	<br /><br />
	11. துரோவாவில் கப்பல் ஏறி, சாமோத்திராக்கே தீவுக்கும், மறுநாளிலே நெயாப்போலி பட்டணத்துக்கும் நேராய் ஓடி,
	<br /><br />
	12. அங்கேயிருந்து மக்கெதோனியா தேசத்து நாடுகளில் ஒன்றிற்குத் தலைமையானதும் ரோமர் குடியேறினதுமான பிலிப்பி பட்டணத்துக்கு வந்து, அந்தப் பட்டணத்திலே சிலநாள் தங்கியிருந்தோம்.
	<br /><br />
	13. ஓய்வுநாளில் நாங்கள் பட்டணத்துக்கு வெளியே போய், ஆற்றினருகே வழக்கமாய் ஜெபம்பண்ணுகிற இடத்தில் உட்கார்ந்து, அங்கே கூடிவந்த ஸ்திரீகளுக்கு உபதேசித்தோம்.
	<br /><br />
	14. அப்பொழுது தியத்தீரா ஊராளும் இரத்தாம்பரம் விற்கிறவளும் தேவனை வணங்குகிறவளுமாகிய லீதியாள் என்னும் பேருள்ள ஒரு ஸ்திரீ கேட்டுக்கொண்டிருந்தாள்; பவுல் சொல்லியவைகளைக் கவனிக்கும்படி கர்த்தர் அவள் இருதயத்தைத் திறந்தருளினார்.
	<br /><br />
	15. அவளும் அவள் வீட்டாரும் ஞானஸ்நானம் பெற்றபின்பு, அவள் எங்களை நோக்கி: நீங்கள் என்னைக் கர்த்தரிடத்தில் விசுவாசமுள்ளவளென்று எண்ணினால், என் வீட்டிலே வந்து தங்கியிருங்கள் என்று எங்களை வருந்திக் கேட்டுக்கொண்டாள்.
	<br /><br />
	16. நாங்கள் ஜெபம்பண்ணுகிற இடத்துக்குப் போகையில் குறிசொல்ல ஏவுகிற ஆவியைக்கொண்டிருந்து, குறிசொல்லுகிறதினால் தன் எஜமான்களுக்கு மிகுந்த ஆதாயத்தை உண்டாக்கின ஒரு பெண் எங்களுக்கு எதிர்ப்பட்டாள்.
	<br /><br />
	17. அவள் பவுலையும் எங்களையும் பின்தொடர்ந்து வந்து: இந்த மனுஷர் உன்னதமான தேவனுடைய ஊழியக்காரர், இரட்சிப்பின் வழியை நமக்கு அறிவிக்கிறவர்கள் என்று சத்தமிட்டாள்.
	<br /><br />
	18. இப்படி அநேகநாள் செய்துகொண்டுவந்தாள். பவுல் சினங்கொண்டு, திரும்பிப்பார்த்து: நீ இவளை விட்டுப் புறப்படும்படி இயேசுகிறிஸ்துவின் நாமத்தினாலே உனக்குக் கட்டளையிடுகிறேன் என்று அந்த ஆவியுடனே சொன்னான்; அந்நேரமே அது புறப்பட்டுப்போயிற்று.
	<br /><br />
	19. அவளுடைய எஜமான்கள் தங்கள் ஆதாயத்து நம்பிக்கை அற்றுப்போயிற்றென்று கண்டு, பவுலையும் சீலாவையும் பிடித்து, சந்தைவெளியிலுள்ள அதிகாரிகளிடத்தில் இழுத்துக்கொண்டு போனார்கள்.
	<br /><br />
	20. அவர்களை அதிகாரிகளிடத்தில் ஒப்புவித்து: யூதர்களாகிய இந்த மனுஷர் நம்முடைய பட்டணத்தில் கலகம்பண்ணி,
	<br /><br />
	21. ரோமராகிய நாம் ஏற்றுக்கொள்ளவும் அநுசரிக்கவும் தகாத முறைமைகளைப் போதிக்கிறார்கள் என்றார்கள்.
	<br /><br />
	22. அப்பொழுது ஜனங்கள் கூட்டங்கூடி, அவர்களுக்கு விரோதமாய் எழும்பினார்கள். அதிகாரிகள் அவர்கள் வஸ்திரங்களைக் கிழித்துப்போடவும், அவர்களை அடிக்கவும் சொல்லி;
	<br /><br />
	23. அவர்களை அநேக அடி அடித்தபின்பு, சிறைச்சாலையிலே வைத்து அவர்களைப் பத்திரமாய்க் காக்கும்படி சிறைச்சாலைக்காரனுக்குக் கட்டளையிட்டார்கள்.
	<br /><br />
	24. அவன் இப்படிப்பட்ட கட்டளையைப் பெற்று, அவர்களை உட்காவலறையிலே அடைத்து, அவர்கள் கால்களைத் தொழுமரத்தில் மாட்டிவைத்தான்.
	<br /><br />
	25. நடுராத்திரியிலே பவுலும் சீலாவும் ஜெபம்பண்ணி, தேவனைத் துதித்துப் பாடினார்கள்; காவலில் வைக்கப்பட்டவர்கள் அதைக் கேட்டுக்கொண்டிருந்தார்கள்.
	<br /><br />
	26. சடிதியிலே சிறைச்சாலையின் அஸ்திபாரங்கள் அசையும்படியாகப் பூமி மிகவும் அதிர்ந்தது; உடனே கதவுகளெல்லாம் திறவுண்டது; எல்லாருடைய கட்டுகளும் கழன்றுபோயிற்று.
	<br /><br />
	27. சிறைச்சாலைக்காரன் நித்திரை தெளிந்து, சிறைச்சாலையின் கதவுகள் திறந்திருக்கிறதைக் கண்டு, கட்டுண்டவர்கள் ஓடிப்போனார்களென்று எண்ணி, பட்டயத்தை உருவித் தன்னைக் கொலைசெய்து கொள்ளப்போனான்.
	<br /><br />
	28. பவுல் மிகுந்த சத்தமிட்டு: நீ உனக்குக் கெடுதி ஒன்றுஞ் செய்துகொள்ளாதே; நாங்கள் எல்லாரும் இங்கேதான் இருக்கிறோம் என்றான்.
	<br /><br />
	29. அப்பொழுது அவன் தீபங்களைக் கொண்டுவரச்சொல்லி, உள்ளே ஓடி, நடுநடுங்கி, பவுலுக்கும் சீலாவுக்கும் முன்பாக விழுந்து,
	<br /><br />
	30. அவர்களை வெளியே அழைத்துவந்து: ஆண்டவன்மாரே, இரட்சிக்கப்படுவதற்கு நான் என்ன செய்யவேண்டும் என்றான்.
	<br /><br />
	31. அதற்கு அவர்கள்: கர்த்தராகிய இயேசுகிறிஸ்துவை விசுவாசி, அப்பொழுது நீயும் உன் வீட்டாரும் இரட்சிக்கப்படுவீர்கள் என்று சொல்லி,
	<br /><br />
	32. அவனுக்கும் அவன் வீட்டிலிருந்த யாவருக்கும் கர்த்தருடைய வசனத்தைப் போதித்தார்கள்.
	<br /><br />
	33. மேலும் இராத்திரியில் அந்நேரத்திலேதானே அவன் அவர்களை அழைத்துக்கொண்டுபோய், அவர்களுடைய காயங்களைக் கழுவினான். அவனும் அவனுடையவர்கள் அனைவரும் உடனே ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	34. பின்பு அவன் அவர்களைத் தன் வீட்டிற்குக் கூட்டிக்கொண்டுபோய், அவர்களுக்குப் போஜனங்கொடுத்து, தன் வீட்டார் அனைவரோடுங்கூடத் தேவனிடத்தில் விசுவாசமுள்ளவனாகி மனமகிழ்ச்சியாயிருந்தான்.
	<br /><br />
	35. பொழுது விடிந்தபின்பு: அந்த மனுஷரை விட்டுவிடுங்கள் என்று சொல்ல அதிகாரிகள் சேவகர்களை அனுப்பினார்கள்.
	<br /><br />
	36. சிறைச்சாலைக்காரன் பவுலுக்கு இந்த வார்த்தைகளை அறிவித்து: உங்களை விடுதலையாக்கும்படிக்கு அதிகாரிகள் கட்டளை அனுப்பினார்கள்; ஆகையால் நீங்கள் இப்பொழுது புறப்பட்டுச் சமாதானத்துடனே போங்கள் என்றான்.
	<br /><br />
	37. அதற்குப் பவுல்: ரோமராகிய எங்களை அவர்கள் நியாயம் விசாரியாமல், வெளியரங்கமாய் அடித்து, சிறைச்சாலையிலே போட்டார்கள்; இப்பொழுது இரகசியமாய் எங்களை விடுதலையாக்குகிறார்களோ? அப்படியல்ல, அவர்களே வந்து, எங்களை வெளியே அழைத்து அனுப்பி விடட்டும் என்றான்.
	<br /><br />
	38. சேவகர் இந்த வார்த்தைகளை அதிகாரிகளுக்கு அறிவித்தார்கள். ரோமராயிருக்கிறார்களென்று அவர்கள் கேட்டபொழுது பயந்துவந்து,
	<br /><br />
	39. அவர்களுடனே தயவாய்ப் பேசி, அவர்களை வெளியே அழைத்துக்கொண்டுபோய், பட்டணத்தை விட்டுப் புறப்பட்டுப்போகும்படி கேட்டுக்கொண்டார்கள்.
	<br /><br />
	40. அந்தப்படி அவர்கள் சிறைச்சாலையிலிருந்து புறப்பட்டு லீதியாளிடத்திற்குப்போய், சகோதரரைக் கண்டு, அவர்களுக்கு ஆறுதல் சொல்லிப் போய்விட்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts16