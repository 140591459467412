import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Role of Christ in Salvation Hebrews in Tamil Bible | எபிரெயர் 2 </title>
	<meta
          name="description"
          content="The Role of Christ in Salvation Hebrews Chapter-2 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* இரட்சிப்பில் கிறிஸ்துவின் பங்கு *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. ஆகையால், நாம் கேட்டவைகளை விட்டு விலகாதபடிக்கு, அவைகளை மிகுந்த ஜாக்கிரதையாய்க் கவனிக்கவேண்டும்.
	<br /><br />
	2. ஏனெனில், தேவதூதர் மூலமாய்ச் சொல்லப்பட்ட வசனத்திற்கு விரோதமான எந்தச் செய்கைக்கும் கீழ்ப்படியாமைக்கும் நீதியான தண்டனை வரத்தக்கதாக அவர்களுடைய வசனம் உறுதிப்படுத்தப்பட்டிருக்க,
	<br /><br />
	3. முதலாவது கர்த்தர் மூலமாய் அறிவிக்கப்பட்டு, பின்பு அவரிடத்தில் கேட்டவர்களாலே நமக்கு உறுதியாக்கப்பட்டதும்,
	<br /><br />
	4. அடையாளங்களினாலும் அற்புதங்களினாலும் பலவிதமான பலத்த செய்கைகளினாலும், தம்முடைய சித்தத்தின்படி பகிர்ந்துகொடுத்த பரிசுத்த ஆவியின் வரங்களினாலும், தேவன் தாமே சாட்சிகொடுத்ததுமாயிருக்கிற இவ்வளவு பெரிதான இரட்சிப்பைக்குறித்து நாம் கவலையற்றிருப்போமானால் தண்டனைக்கு எப்படித் தப்பித்துக்கொள்ளுவோம்.
	<br /><br />
	5. இனிவரும் உலகத்தைக்குறித்துப் பேசுகிறோமே, அதை அவர் தூதர்களுக்குக் கீழ்ப்படுத்தவில்லை.
	<br /><br />
	6. ஒரு இடத்திலே ஒருவன் சாட்சியாக: மனுஷனை நீர் நினைக்கிறதற்கும், மனுஷனுடைய குமாரனை நீர் விசாரிக்கிறதற்கும் அவன் எம்மாத்திரம்?
	<br /><br />
	7. அவனை தேவதூதரிலும் சற்றுச் சிறியவனாக்கினீர்; மகிமையினாலும் கனத்தினாலும் அவனுக்கு முடிசூட்டி, உம்முடைய கரத்தின் கிரியைகளின்மேல் அவனை அதிகாரியாக வைத்து, சகலத்தையும் அவனுடைய பாதங்களுக்குக் கீழ்ப்படுத்தினீர் என்று சொன்னான்.
	<br /><br />
	8. சகலத்தையும் அவனுக்குக் கீழ்ப்படுத்தினார் என்கிற விஷயத்தில், அவர் அவனுக்குக் கீழ்ப்படுத்தாத பொருள் ஒன்றுமில்லை; அப்படியிருந்தும், இன்னும் அவனுக்குச் சகலமும் கீழ்ப்பட்டிருக்கக் காணோம்.
	<br /><br />
	9. என்றாலும், தேவனுடைய கிருபையினால் ஒவ்வொருவருக்காகவும், மரணத்தை ருசிபார்க்கும்படிக்கு தேவதூதரிலும் சற்றுச் சிறியவராக்கப்பட்டிருந்த இயேசு மரணத்தை உத்தரித்ததினிமித்தம் மகிமையினாலும் கனத்தினாலும் முடிசூட்டப்பட்டதைக் காண்கிறோம்.
	<br /><br />
	10. ஏனென்றால் தமக்காகவும் தம்மாலேயும் சகலத்தையும் உண்டாக்கினவர், அநேகம் பிள்ளைகளை மகிமையில் கொண்டுவந்து சேர்க்கையில் அவர்களுடைய இரட்சிப்பின் அதிபதியை உபத்திரவங்களினாலே பூரணப்படுத்துகிறது அவருக்கேற்றதாயிருந்தது.
	<br /><br />
	11. எப்படியெனில், பரிசுத்தஞ்செய்கிறவரும் பரிசுத்தஞ்செய்யப்படுகிறவர்களுமாகிய யாவரும் ஒருவராலே உண்டாயிருக்கிறார்கள்; இதினிமித்தம் அவர்களைச் சகோதரரென்று சொல்ல அவர் வெட்கப்படாமல்:
	<br /><br />
	12. உம்முடைய நாமத்தை என் சகோதரருக்கு அறிவித்து, சபை நடுவில் உம்மைத் துதித்துப் பாடுவேன் என்றும்;
	<br /><br />
	13. நான் அவரிடத்தில் நம்பிக்கையாயிருப்பேன் என்றும்; இதோ, நானும், தேவன் எனக்குக் கொடுத்த பிள்ளைகளும் என்றும் சொல்லியிருக்கிறார்.
	<br /><br />
	14. ஆதலால், பிள்ளைகள் மாம்சத்தையும் இரத்தத்தையும் உடையவர்களாயிருக்க, அவரும் அவர்களைப்போல மாம்சத்தையும் இரத்தத்தையும் உடையவரானார்; மரணத்துக்கு அதிகாரியாகிய பிசாசானவனைத் தமது மரணத்தினாலே அழிக்கும்படிக்கும்,
	<br /><br />
	15. ஜீவகாலமெல்லாம் மரணபயத்தினாலே அடிமைத்தனத்திற்குள்ளானவர்கள் யாவரையும் விடுதலைபண்ணும்படிக்கும் அப்படியானார்.
	<br /><br />
	16. ஆதலால், அவர் தேவதூதருக்கு உதவியாகக் கைகொடாமல், ஆபிரகாமின் சந்ததிக்கு உதவியாகக் கைகொடுத்தார்.
	<br /><br />
	17. அன்றியும், அவர் ஜனத்தின் பாவங்களை நிவிர்த்தி செய்வதற்கேதுவாக, தேவகாரியங்களைக்குறித்து இரக்கமும் உண்மையுமுள்ள பிரதான ஆசாரியராயிருக்கும்படிக்கு எவ்விதத்திலும் தம்முடைய சகோதரருக்கு ஒப்பாகவேண்டியதாயிருந்தது.
	<br /><br />
	18. ஆதலால், அவர்தாமே சோதிக்கப்பட்டுப் பாடுபட்டதினாலே, அவர் சோதிக்கப்படுகிறவர்களுக்கு உதவிசெய்ய வல்லவராயிருக்கிறார்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* எபிரேயர் 2 இரட்சிப்பில் கிறிஸ்துவின் பங்கைப் பற்றி பேசுகிறது. இயேசு சுருக்கமாகத் தாழ்த்தப்பட்டு மனித வடிவத்தை எடுத்தார், அதனால் அவர் அனைவருக்கும் மரணத்தை சுவைத்தார். இயேசு, யாரின் மூலம் எல்லாம் இருக்கிறாரோ, அவர் இரட்சிப்பின் ஆசிரியராக இருந்தார் என்பது பொருத்தமாக இருந்தது. அவர் மரணத்தின் மூலம் மனிதகுலத்தில் பங்குகொண்டார், அதனால் மரணத்தின் அதிகாரத்தை வைத்திருப்பவரை அவர் அழிக்கிறார். இவ்வளவு பெரிய இரட்சிப்பை நாம் புறக்கணிக்காமல், இதை உன்னிப்பாகக் கவனிக்க வேண்டும். *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews2