import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 12 </title>
	<meta
          name="description"
          content="Daniel 12 | தானியேல் தீர்க்கதரிசி | தானியேல் 12 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உன் ஜனத்தின் புத்திரருக்காக நிற்கிற பெரிய அதிபதியாகிய மிகாவேல் அக்காலத்திலே எழும்புவான்; யாதொரு ஜாதியாரும் தோன்றினதுமுதல் அக்காலமட்டும் உண்டாயிராத ஆபத்துக்காலம் வரும்; அக்காலத்திலே புஸ்தகத்தில் எழுதியிருக்கிறவர்களாகக் காணப்படுகிற உன் ஜனங்கள் அனைவரும் விடுவிக்கப்படுவார்கள்.
	<br /><br />
	2. பூமியின் தூளிலே நித்திரைபண்ணுகிறவர்களாகிய அநேகரில் சிலர் நித்தியஜீவனுக்கும், சிலர் நித்திய நிந்தைக்கும் இகழ்ச்சிக்கும் விழித்து எழுந்திருப்பார்கள்.
	<br /><br />
	3. ஞானவான்கள் ஆகாயமண்டலத்தின் ஒளியைப்போலவும், அநேகரை நீதிக்குட்படுத்துகிறவர்கள் நட்சத்திரங்களைப்போலவும் என்றென்றைக்குமுள்ள சதாகாலங்களிலும் பிரகாசிப்பார்கள்.
	<br /><br />
	4. தானியேலாகிய நீயோவென்றால், முடிவுகாலமட்டும் இந்த வார்த்தைகளைப் புதைபொருளாக வைத்து வைத்து, இந்தப்புஸ்தகத்தை முத்திரைபோடு; அப்பொழுது அநேகர் இங்கும் அங்கும் ஓடி ஆராய்வார்கள், அறிவும் பெருகிப்போம் என்றான்.
	<br /><br />
	5. அப்பொழுது, தானியேலாகிய நான் ஆற்றுக்கு இக்கரையில் ஒருவனும் ஆற்றுக்கு அக்கரையில் ஒருவனுமாகிய வேறே இரண்டுபேர் நிற்கக்கண்டேன்.
	<br /><br />
	6. சணல்வஸ்திரம் தரித்தவரும், ஆற்றின் தண்ணீர்களின்மேல் நிற்கிறவருமாகிய புருஷனை ஒருவன் நோக்கி: இந்த ஆச்சரியமானவைகளின் முடிவு வர எவ்வளவுகாலம் செல்லும் என்று கேட்டான்.
	<br /><br />
	7. அப்பொழுது சணல்வஸ்திரம் தரித்தவரும் ஆற்றின் தண்ணீர்களின்மேல் நிற்கிறவருமாகிய புருஷன் தம்முடைய வலதுகரத்தையும் தம்முடைய இடதுகரத்தையும் வானத்துக்கு நேராக ஏறெடுத்து, ஒரு காலமும், காலங்களும், அரைக்காலமும் செல்லும் என்றும்; பரிசுத்த ஜனங்களின் வல்லமையைச் சிதறடித்தல் முடிவுபெறும்போதே இவைகளெல்லாம் நிறைவேறித் தீருமென்றும் என்றென்றைக்கும் ஜீவித்திருக்கிறவர்பேரில் ஆணையிடக் கேட்டேன்.
	<br /><br />
	8. நான் அதைக் கேட்டும், அதின் பொருளை அறியவில்லை; ஆகையால்: என் ஆண்டவனே, இவைகளின் முடிவு என்னமாயிருக்கும் என்று கேட்டேன்.
	<br /><br />
	9. அதற்கு அவன்: தானியேலே, போகலாம்; இந்த வார்த்தைகள் முடிவுகாலமட்டும் புதைபொருளாக வைக்கப்பட்டும் முத்திரிக்கப்பட்டும் இருக்கும்.
	<br /><br />
	10. அநேகர் சுத்தமும் வெண்மையுமாக்கப்பட்டு, புடமிடப்பட்டவர்களாய் விளங்குவார்கள்; துன்மார்க்கரோ துன்மார்க்கமாய் நடப்பார்கள்; துன்மார்க்கரில் ஒருவனும் உணரான், ஞானவான்களோ உணர்ந்துகொள்ளுவார்கள்.
	<br /><br />
	11. அன்றாடபலி நீக்கப்பட்டு, பாழாக்கும் அருவருப்பு ஸ்தாபிக்கப்படுங்காலமுதல் ஆயிரத்திருநூற்றுத் தொண்ணூறுநாள் செல்லும்.
	<br /><br />
	12. ஆயிரத்து முந்நூற்று முப்பத்தைந்து நாள்மட்டும் காத்திருந்து சேருகிறவன் பாக்கியவான்.
	<br /><br />
	13. நீயோவென்றால் முடிவுவருமட்டும் போயிரு; நீ இளைப்பாறிக்கொண்டிருந்து, நாட்களின் முடிவிலே உன் சுதந்தர வீதத்துக்கு எழுந்திருப்பாய் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel12