import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 2 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அந்நாட்களில் உலகமெங்கும் குடிமதிப்பு எழுதப்படவேண்டுமென்று அகுஸ்துராயனால் கட்டளை பிறந்தது.
	<br /><br />
	2. சீரியாநாட்டிலே சிரேனியு என்பவன் தேசாதிபதியாயிருந்தபோது இந்த முதலாம் குடிமதிப்பு உண்டாயிற்று.
	<br /><br />
	3. அந்தப்படி குடிமதிப்பெழுதப்படும்படிக்கு எல்லாரும் தங்கள் தங்கள் ஊர்களுக்குப் போனார்கள்.
	<br /><br />
	4. அப்பொழுது யோசேப்பும், தான் தாவீதின் வம்சத்தானும் குடும்பத்தானுமாயிருந்தபடியினாலே, தனக்கு மனைவியாக நியமிக்கப்பட்டுக் கர்ப்பவதியான மரியாளுடனே குடிமதிப்பெழுதப்படும்படி,
	<br /><br />
	5. கலிலேயா நாட்டிலுள்ள நாசரேத்தூரிலிருந்து யூதேயா நாட்டிலுள்ள பெத்லெகேம் என்னும் தாவீதின் ஊருக்குப் போனான்.
	<br /><br />
	6. அவ்விடத்திலே அவர்கள் இருக்கையில், அவளுக்குப் பிரசவகாலம் நேரிட்டது.
	<br /><br />
	7. அவள் தன் முதற்பேறான குமாரனைப்பெற்று, சத்திரத்திலே அவர்களுக்கு இடமில்லாதிருந்தபடியினால், பிள்ளையைத் துணிகளில் சுற்றி, முன்னணையிலே கிடத்தினாள்.
	<br /><br />
	8. அப்பொழுது அந்த நாட்டிலே மேய்ப்பர்கள் வயல்வெளியில் தங்கி, இராத்திரியிலே தங்கள் மந்தையைக் காத்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	9. அவ்வேளையில் கர்த்தருடைய தூதன் அவர்களிடத்தில் வந்து நின்றான், கர்த்தருடைய மகிமை அவர்களைச் சுற்றிலும் பிரகாசித்தது; அவர்கள் மிகவும் பயந்தார்கள்.
	<br /><br />
	10. தேவதூதன் அவர்களை நோக்கி: பயப்படாதிருங்கள்; இதோ, எல்லா ஜனத்துக்கும் மிகுந்த சந்தோஷத்தை உண்டாக்கும் நற்செய்தியை உங்களுக்கு அறிவிக்கிறேன்.
	<br /><br />
	11. இன்று கர்த்தராகிய கிறிஸ்து என்னும் இரட்சகர் உங்களுக்குத் தாவீதின் ஊரிலே பிறந்திருக்கிறார்.
	<br /><br />
	12. பிள்ளையைத் துணிகளில் சுற்றி, முன்னணையிலே கிடத்தியிருக்கக் காண்பீர்கள்; இதுவே உங்களுக்கு அடையாளம் என்றான்.
	<br /><br />
	13. அந்தக்ஷணமே பரமசேனையின் திரள் அந்தத் தூதனுடனே தோன்றி:
	<br /><br />
	14. உன்னதத்திலிருக்கிற தேவனுக்கு மகிமையும், பூமியிலே சமாதானமும், மனுஷர்மேல் பிரியமும் உண்டாவதாக என்று சொல்லி, தேவனைத் துதித்தார்கள்.
	<br /><br />
	15. தேவதூதர்கள் அவர்களை விட்டுப் பரலோகத்துக்குப் போனபின்பு, மேய்ப்பர்கள் ஒருவரையொருவர் நோக்கி: நாம் பெத்லெகேம் ஊருக்குப் போய், நடந்ததாகக் கர்த்தரால் நமக்கு அறிவிக்கப்பட்ட இந்தக் காரியத்தைப் பார்ப்போம் வாருங்கள் என்று சொல்லி,
	<br /><br />
	16. தீவிரமாய் வந்து, மரியாளையும், யோசேப்பையும், முன்னணையிலே கிடத்தியிருக்கிற பிள்ளையையும் கண்டார்கள்.
	<br /><br />
	17. கண்டு, அந்தப் பிள்ளையைக் குறித்துத் தங்களுக்குச் சொல்லப்பட்ட சங்கதியைப் பிரசித்தம்பண்ணினார்கள்.
	<br /><br />
	18. மேய்ப்பராலே தங்களுக்குச் சொல்லப்பட்டதைக் கேட்ட யாவரும் அவைகளைக்குறித்து ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	19. மரியாளோ அந்தச் சங்கதிகளையெல்லாம் தன் இருதயத்திலே வைத்து, சிந்தனைபண்ணினாள்.
	<br /><br />
	20. மேய்ப்பர்களும் தங்களுக்குச் சொல்லப்பட்டதின்படியே கேட்டு கண்ட எல்லாவற்றிற்காகவும் தேவனை மகிமைப்படுத்தி, துதித்துக்கொண்டு திரும்பிப்போனார்கள்.
	<br /><br />
	21. பிள்ளைக்கு விருத்தசேதனம் பண்ணவேண்டிய எட்டாம் நாளிலே, அது கர்ப்பத்திலே உற்பவிக்கிறதற்கு முன்னே தேவதூதனால் சொல்லப்பட்டபடியே, அதற்கு இயேசு என்று பேரிட்டார்கள்.
	<br /><br />
	22. மோசேயின் நியாயப்பிரமாணத்தின்படியே அவர்களுடைய சுத்திகரிப்பின் நாட்கள் நிறைவேறினபோது,
	<br /><br />
	23. முதற்பேறான எந்த ஆண்பிள்ளையும் கர்த்தருக்குப் பரிசுத்தமானதென்னப்படும் என்று கர்த்தருடைய நியாயப்பிரமாணத்தில் எழுதியிருக்கிறபடி அவரைக் கர்த்தருக்கென்று ஒப்புக்கொடுக்கவும்,
	<br /><br />
	24. கர்த்தருடைய நியாயப்பிரமாணத்தில் சொல்லியிருக்கிறபடி, ஒரு ஜோடு காட்டுப்புறாவையாவது இரண்டு புறாக்குஞ்சுகளையாவது பலியாகச் செலுத்தவும், அவரை எருசலேமுக்குக் கொண்டுபோனார்கள்.
	<br /><br />
	25. அப்பொழுது சிமியோன் என்னும் பேர்கொண்ட ஒரு மனுஷன் எருசலேமில் இருந்தான்; அவன் நீதியும் தேவபக்தியும் உள்ளவனாயும், இஸ்ரவேலின் ஆறுதல்வரக் காத்திருக்கிறவனாயும் இருந்தான்; அவன்மேல் பரிசுத்தஆவி இருந்தார்.
	<br /><br />
	26. கர்த்தருடைய கிறிஸ்துவை நீ காணுமுன்னே மரணமடையமாட்டாய் என்று பரிசுத்த ஆவியினாலே அவனுக்கு அறிவிக்கப்பட்டுமிருந்தது.
	<br /><br />
	27. அவன் ஆவியின் ஏவுதலினால் தேவாலயத்திலே வந்திருந்தான். இயேசு என்னும் பிள்ளைக்காக நியாயப்பிரமாண முறைமையின்படி செய்வதற்குத் தாய் தகப்பன்மார் அவரை உள்ளே கொண்டுவருகையில்,
	<br /><br />
	28. அவன் அவரைத் தன் கைகளில் ஏந்திக்கொண்டு, தேவனை ஸ்தோத்திரித்து:
	<br /><br />
	29. ஆண்டவரே, உமது வார்த்தையின்படி உமது அடியேனை இப்பொழுது சமாதானத்தோடே போகவிடுகிறீர்;
	<br /><br />
	30. புறஜாதிகளுக்குப் பிரகாசிக்கிற ஒளியாகவும், உம்முடைய ஜனமாகிய இஸ்ரவேலுக்கு மகிமையாகவும்,
	<br /><br />
	31. தேவரீர் சகல ஜனங்களுக்கும் முன்பாக ஆயத்தம்பண்ணின
	<br /><br />
	32. உம்முடைய இரட்சணியத்தை என் கண்கள் கண்டது என்றான்.
	<br /><br />
	33. அவரைக்குறித்துச் சொல்லப்பட்டவைகளுக்காக யோசேப்பும் அவருடைய தாயாரும் ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	34. பின்னும் சிமியோன் அவர்களை ஆசீர்வதித்து, அவருடைய தாயாகிய மரியாளை நோக்கி: இதோ, அநேகருடைய இருதய சிந்தனைகள் வெளிப்படத்தக்கதாக, இஸ்ரவேலில் அநேகர் விழுகிறதற்கும் எழுந்திருக்கிறதற்கும், விரோதமாகப் பேசப்படும் அடையாளமாவதற்கும், இவர் நியமிக்கப்பட்டிருக்கிறார்.
	<br /><br />
	35. உன் ஆத்துமாவையும் ஒரு பட்டயம் உருவிப்போகும் என்றான்.
	<br /><br />
	36. ஆசேருடைய கோத்திரத்தாளும், பானுவேலின் குமாரத்தியுமாகிய அன்னாள் என்னும் ஒரு தீர்க்கதரிசி இருந்தாள்; அவள் கன்னிப்பிராயத்தில் விவாகமானதுமுதல் ஏழுவருஷம் புருஷனுடனே வாழ்ந்தவளும், அதிக வயதுசென்றவளுமாயிருந்தாள்.
	<br /><br />
	37. ஏறக்குறைய எண்பத்துநாலு வயதுள்ள அந்த விதவை தேவாலயத்தை விட்டு நீங்காமல், இரவும் பகலும் உபவாசித்து, ஜெபம்பண்ணி, ஆராதனை செய்துகொண்டிருந்தாள்.
	<br /><br />
	38. அவளும் அந்நேரத்திலே வந்து நின்று, கர்த்தரைப் புகழ்ந்து, எருசலேமிலே மீட்புண்டாகக் காத்திருந்த யாவருக்கும் அவரைக்குறித்துப் பேசினாள்.
	<br /><br />
	39. கர்த்தருடைய நியாயப்பிரமாணத்தின்படி சகலத்தையும் அவர்கள் செய்து முடித்தபின்பு, கலிலேயாநாட்டிலுள்ள தங்கள் ஊராகிய நாசரேத்துக்குத் திரும்பிப்போனார்கள்.
	<br /><br />
	40. பிள்ளை வளர்ந்து, ஆவியிலே பெலன்கொண்டு, ஞானத்தினால் நிறைந்தது. தேவனுடைய கிருபையும் அவர்மேல் இருந்தது.
	<br /><br />
	41. அவருடைய தாய் தகப்பன்மார் வருஷந்தோறும் பஸ்கா பண்டிகையில் எருசலேமுக்குப் போவார்கள்.
	<br /><br />
	42. அவருக்குப் பன்னிரண்டு வயதானபோது, அவர்கள் அந்தப் பண்டிகை முறைமையின்படி எருசலேமுக்குப்போய்,
	<br /><br />
	43. பண்டிகைநாட்கள் முடிந்து, திரும்பிவருகிறபோது, பிள்ளையாகிய இயேசு எருசலேமிலே இருந்துவிட்டார்; இது அவருடைய தாயாருக்கும் யோசேப்புக்கும் தெரியாதிருந்தது.
	<br /><br />
	44. அவர் பிரயாணக்காரரின் கூட்டத்திலே இருப்பாரென்று அவர்கள் நினைத்து, ஒருநாள் பிரயாணம் வந்து, உறவின் முறையாரிடத்திலும் அறிமுகமானவர்களிடத்திலும் அவரைத் தேடினார்கள்.
	<br /><br />
	45. காணாததினாலே அவரைத் தேடிக்கொண்டே எருசலேமுக்குத் திரும்பிப் போனார்கள்.
	<br /><br />
	46. மூன்றுநாளைக்குப் பின்பு, அவர் தேவாலயத்தில் போதகர் நடுவில் உட்கார்ந்திருக்கவும், அவர்கள் பேசுகிறதைக் கேட்கவும், அவர்களை வினாவவும் கண்டார்கள்.
	<br /><br />
	47. அவர் பேசக்கேட்ட யாவரும் அவருடைய புத்தியையும் அவர் சொன்ன மாறுத்தரங்களையுங்குறித்துப் பிரமித்தார்கள்.
	<br /><br />
	48. தாய் தகப்பன்மாரும் அவரைக் கண்டு ஆச்சரியப்பட்டார்கள். அப்பொழுது அவருடைய தாயார் அவரை நோக்கி: மகனே! ஏன் எங்களுக்கு இப்படிச் செய்தாய்? இதோ, உன் தகப்பனும் நானும் விசாரத்தோடே உன்னைத் தேடினோமே என்றாள்.
	<br /><br />
	49. அதற்கு அவர்: நீங்கள் ஏன் என்னைத் தேடினீர்கள்? என் பிதாவுக்கடுத்தவைகளில் நான் இருக்கவேண்டியதென்று அறியீர்களா என்றார்.
	<br /><br />
	50. தங்களுக்கு அவர் சொன்ன வார்த்தையை அவர்கள் உணர்ந்துகொள்ளவில்லை.
	<br /><br />
	51. பின்பு அவர் அவர்களுடனே கூடப்போய், நாசரேத்தூரில் சேர்ந்து, அவர்களுக்குக் கீழ்ப்படிந்திருந்தார். அவருடைய தாயார் இந்தச் சங்கதிகளையெல்லாம் தன் இருதயத்திலே வைத்துக்கொண்டாள்.
	<br /><br />
	52. இயேசுவானவர் ஞானத்திலும், வளர்த்தியிலும், தேவகிருபையிலும், மனுஷர் தயவிலும் அதிகமதிகமாய் விருத்தியடைந்தார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	The Birth of Jesus
</h2>
<p>
	<br /><br />
	1 In those days Caesar Augustus issued a decree that a census should be taken of the entire Roman world.
	<br /><br />
	2 (This was the first census that took place while Quirinius was governor of Syria.)
	<br /><br />
	3 And everyone went to their own town to register.
	<br /><br />
	4 So Joseph also went up from the town of Nazareth in Galilee to Judea, to Bethlehem the town of David, because he belonged to the house and line of David.
	<br /><br />
	5 He went there to register with Mary, who was pledged to be married to him and was expecting a child.
	<br /><br />
	6 While they were there, the time came for the baby to be born,
	<br /><br />
	7 and she gave birth to her firstborn, a son. She wrapped him in cloths and placed him in a manger, because there was no guest room available for them.
	<br /><br />
	8 And there were shepherds living out in the fields nearby, keeping watch over their flocks at night.
	<br /><br />
	9 An angel of the Lord appeared to them, and the glory of the Lord shone around them, and they were terrified.
	<br /><br />
	10 But the angel said to them, &ldquo;Do not be afraid. I bring you good news that will cause great joy for all the people.
	<br /><br />
	11 Today in the town of David a Savior has been born to you; he is the Messiah, the Lord.
	<br /><br />
	12 This will be a sign to you: You will find a baby wrapped in cloths and lying in a manger.&rdquo;
	<br /><br />
	13 Suddenly a great company of the heavenly host appeared with the angel, praising God and saying,
	<br /><br />
	14 &ldquo;Glory to God in the highest heaven, and on earth peace to those on whom his favor rests.&rdquo;
	<br /><br />
	15 When the angels had left them and gone into heaven, the shepherds said to one another, &ldquo;Let&rsquo;s go to Bethlehem and see this thing that has happened, which the Lord has told us about.&rdquo;
	<br /><br />
	16 So they hurried off and found Mary and Joseph, and the baby, who was lying in the manger.
	<br /><br />
	17 When they had seen him, they spread the word concerning what had been told them about this child,
	<br /><br />
	18 and all who heard it were amazed at what the shepherds said to them.
	<br /><br />
	19 But Mary treasured up all these things and pondered them in her heart.
	<br /><br />
	20 The shepherds returned, glorifying and praising God for all the things they had heard and seen, which were just as they had been told.
	<br /><br />
	21 On the eighth day, when it was time to circumcise the child, he was named Jesus, the name the angel had given him before he was conceived.
</p>
<h2>
	<br /><br />
	Jesus Presented in the Temple
</h2>
<p>
	<br /><br />
	22 When the time came for the purification rites required by the Law of Moses, Joseph and Mary took him to Jerusalem to present him to the Lord
	<br /><br />
	23 (as it is written in the Law of the Lord, &ldquo;Every firstborn male is to be consecrated to the Lord&rdquo; ),
	<br /><br />
	24 and to offer a sacrifice in keeping with what is said in the Law of the Lord: &ldquo;a pair of doves or two young pigeons.&rdquo;
	<br /><br />
	25 Now there was a man in Jerusalem called Simeon, who was righteous and devout. He was waiting for the consolation of Israel, and the Holy Spirit was on him.
	<br /><br />
	26 It had been revealed to him by the Holy Spirit that he would not die before he had seen the Lord&rsquo;s Messiah.
	<br /><br />
	27 Moved by the Spirit, he went into the temple courts. When the parents brought in the child Jesus to do for him what the custom of the Law required,
	<br /><br />
	28 Simeon took him in his arms and praised God, saying:
	<br /><br />
	29 &ldquo;Sovereign Lord, as you have promised, you may now dismiss your servant in peace.
	<br /><br />
	30 For my eyes have seen your salvation,
	<br /><br />
	31 which you have prepared in the sight of all nations:
	<br /><br />
	32 a light for revelation to the Gentiles, and the glory of your people Israel.&rdquo;
	<br /><br />
	33 The child&rsquo;s father and mother marveled at what was said about him.
	<br /><br />
	34 Then Simeon blessed them and said to Mary, his mother: &ldquo;This child is destined to cause the falling and rising of many in Israel, and to be a sign that will be spoken against,
	<br /><br />
	35 so that the thoughts of many hearts will be revealed. And a sword will pierce your own soul too.&rdquo;
	<br /><br />
	36 There was also a prophet, Anna, the daughter of Penuel, of the tribe of Asher. She was very old; she had lived with her husband seven years after her marriage,
	<br /><br />
	37 and then was a widow until she was eighty-four. She never left the temple but worshiped night and day, fasting and praying.
	<br /><br />
	38 Coming up to them at that very moment, she gave thanks to God and spoke about the child to all who were looking forward to the redemption of Jerusalem.
	<br /><br />
	39 When Joseph and Mary had done everything required by the Law of the Lord, they returned to Galilee to their own town of Nazareth.
	<br /><br />
	40 And the child grew and became strong; he was filled with wisdom, and the grace of God was on him.
</p>
<h2>
	<br /><br />
	The Boy Jesus at the Temple
</h2>
<p>
	<br /><br />
	41 Every year Jesus&rsquo; parents went to Jerusalem for the Festival of the Passover.
	<br /><br />
	42 When he was twelve years old, they went up to the festival, according to the custom.
	<br /><br />
	43 After the festival was over, while his parents were returning home, the boy Jesus stayed behind in Jerusalem, but they were unaware of it.
	<br /><br />
	44 Thinking he was in their company, they traveled on for a day. Then they began looking for him among their relatives and friends.
	<br /><br />
	45 When they did not find him, they went back to Jerusalem to look for him.
	<br /><br />
	46 After three days they found him in the temple courts, sitting among the teachers, listening to them and asking them questions.
	<br /><br />
	47 Everyone who heard him was amazed at his understanding and his answers.
	<br /><br />
	48 When his parents saw him, they were astonished. His mother said to him, &ldquo;Son, why have you treated us like this? Your father and I have been anxiously searching for you.&rdquo;
	<br /><br />
	49 &ldquo;Why were you searching for me?&rdquo; he asked. &ldquo;Didn&rsquo;t you know I had to be in my Father&rsquo;s house?&rdquo;
	<br /><br />
	50 But they did not understand what he was saying to them.
	<br /><br />
	51 Then he went down to Nazareth with them and was obedient to them. But his mother treasured all these things in her heart.
	<br /><br />
	52 And Jesus grew in wisdom and stature, and in favor with God and man.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke2