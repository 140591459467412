import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Colossians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Book of Colossians Bible in Tamil | கொலோசெயர் 1 </title>
	<meta
          name="description"
          content="Colossians 1 | கொலோசெயர் 1 |
          Book of Colossians | Tamil Bible Verse | Tamil Bible | கொலோசெயருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

	  <p>
	1. தேவனுடைய சித்தத்தினாலே இயேசுகிறிஸ்துவின் அப்போஸ்தலனாகிய பவுலும், சகோதரனாகிய தீமோத்தேயும்,
	<br /><br />
	2. கொலோசெ பட்டணத்தில் கிறிஸ்துவுக்குள் பரிசுத்தவான்களும் விசுவாசிகளுமாயிருக்கிற சகோதரர்களுக்கு எழுதுகிறதாவது: நம்முடைய பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	3. கிறிஸ்து இயேசுவின்மேலுள்ள உங்கள் விசுவாசத்தையும், பரிசுத்தவான்களெல்லார்மேலுமுள்ள உங்கள் அன்பையுங்குறித்து நாங்கள் கேள்விப்பட்டு,
	<br /><br />
	4. பரலோகத்தில் உங்களுக்காக வைத்திருக்கிற நம்பிக்கையினிமித்தம்,
	<br /><br />
	5. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் பிதாவாகிய தேவனுக்கு ஸ்தோத்திரஞ்செலுத்தி, எப்பொழுதும் உங்களுக்காக வேண்டுதல் செய்கிறோம்.
	<br /><br />
	6. அந்த நம்பிக்கையைக்குறித்து, நீங்கள் முன்னமே சத்தியவசனமாகிய சுவிசேஷத்தினாலே கேள்விப்பட்டீர்கள்; அந்தச் சுவிசேஷம் உலகமெங்கும் பரம்பிப் பலன்தருகிறதுபோல, உங்களிடத்திலும் வந்து, நீங்கள் அதைக் கேட்டு, தேவகிருபையைச் சத்தியத்தின்படி அறிந்துகொண்ட நாள்முதல், அது உங்களுக்குள்ளும் பலன்தருகிறதாயிருக்கிறது;
	<br /><br />
	7. அதை எங்களுக்குப் பிரியமான உடன்வேலையாளும், உங்களுக்காகக் கிறிஸ்துவின் உண்மையான ஊழியக்காரனுமாயிருக்கிற எப்பாப்பிராவினிடத்தில் நீங்கள் கற்றறிந்திருக்கிறீர்கள்;
	<br /><br />
	8. ஆவிக்குள்ளான உங்கள் அன்பையும் அவனே எங்களுக்குத் தெரியப்படுத்தினான்.
	<br /><br />
	9. இதினிமித்தம், நாங்கள் அதைக் கேட்ட நாள்முதல் உங்களுக்காக இடைவிடாமல் ஜெபம்பண்ணுகிறோம்; நீங்கள் எல்லா ஞானத்தோடும், ஆவிக்குரிய விவேகத்தோடும் அவருடைய சித்தத்தை அறிகிற அறிவினாலே நிரப்பப்படவும்,
	<br /><br />
	10. சகலவித நற்கிரியைகளுமாகிய கனிகளைத் தந்து, தேவனை அறிகிற அறிவில் விருத்தியடைந்து, கர்த்தருக்குப் பிரியமுண்டாக அவருக்குப் பாத்திரராய் நடந்துகொள்ளவும்,
	<br /><br />
	11. சந்தோஷத்தோடே கூடிய எல்லாப் பொறுமையும் நீடியசாந்தமும் உண்டாவதற்கு, மகிமையான அவருடைய வல்லமையின்படி, எல்லா வல்லமையாலும் பலப்படுத்தப்படவும், உங்களுக்காக வேண்டுதல்செய்கிறோம்.
	<br /><br />
	12. ஒளியிலுள்ள பரிசுத்தவான்களுடைய சுதந்தரத்தில் பங்கடைவதற்கு, நம்மைத் தகுதியுள்ளவர்களாக்கினவரும்,
	<br /><br />
	13. இருளின் அதிகாரத்தினின்று நம்மை விடுதலையாக்கி, தமது அன்பின் குமாரனுடைய ராஜ்யத்திற்கு உட்படுத்தினவருமாயிருக்கிற பிதாவை ஸ்தோத்திரிக்கிறோம்.
	<br /><br />
	14. [குமாரனாகிய] அவருக்குள், அவருடைய இரத்தத்தினாலே, பாவமன்னிப்பாகிய மீட்பு நமக்கு உண்டாயிருக்கிறது.
	<br /><br />
	15. அவர் அதரிசனமான தேவனுடைய தற்சுரூபமும், சர்வ சிருஷ்டிக்கும் முந்தின பேறுமானவர்.
	<br /><br />
	16. ஏனென்றால் அவருக்குள் சகலமும் சிருஷ்டிக்கப்பட்டது; பரலோகத்திலுள்ளவைகளும் பூலோகத்திலுள்ளவைகளுமாகிய காணப்படுகிறவைகளும் காணப்படாதவைகளுமான சகல வஸ்துக்களும், சிங்காசனங்களானாலும், கர்த்தத்துவங்களானாலும், துரைத்தனங்களானாலும், அதிகாரங்களானாலும், சகலமும் அவரைக்கொண்டும் அவருக்கென்றும் சிருஷ்டிக்கப்பட்டது.
	<br /><br />
	17. அவர் எல்லாவற்றிற்கும் முந்தினவர், எல்லாம் அவருக்குள் நிலைநிற்கிறது.
	<br /><br />
	18. அவரே சபையாகிய சரீரத்துக்குத் தலையானவர்; எல்லாவற்றிலும் முதல்வராயிருக்கும்படி, அவரே ஆதியும் மரித்தோரிலிருந்து எழுந்த முதற்பேறுமானவர்.
	<br /><br />
	19. சகல பரிபூரணமும் அவருக்குள்ளே வாசமாயிருக்கவும்,
	<br /><br />
	20. அவர் சிலுவையில் சிந்தின இரத்தத்தினாலே சமாதானத்தை உண்டாக்கி, பூலோகத்திலுள்ளவைகள் பரலோகத்திலுள்ளவைகள் யாவையும் அவர் மூலமாய்த் தமக்கு ஒப்புரவாக்கிக்கொள்ளவும் அவருக்குப் பிரியமாயிற்று.
	<br /><br />
	21. முன்னே அந்நியராயும் துர்க்கிரியைகளினால் மனதிலே சத்துருக்களாயும் இருந்த உங்களையும் பரிசுத்தராகவும் குற்றமற்றவர்களாகவும் கண்டிக்கப்படாதவர்களாகவும் தமக்கு முன் நிறுத்தும்படியாக அவருடைய மாம்ச சரீரத்தில் அடைந்த மரணத்தினாலே இப்பொழுது ஒப்புரவாக்கினார்.
	<br /><br />
	22. நீங்கள் கேட்ட சுவிசேஷத்தினால் உண்டாகும் நம்பிக்கையைவிட்டு அசையாமல், ஸ்திரமாயும் உறுதியாயும் விசுவாசத்திலே நிலைத்திருப்பீர்களானால் அப்படியாகும்.
	<br /><br />
	23. அந்தச் சுவிசேஷம் வானத்தின் கீழிருக்கிற சகல சிருஷ்டிகளுக்கும் பிரசங்கிக்கப்பட்டுவருகிறது; அதற்கென்றே பவுலாகிய நான் ஊழியக்காரனானேன்.
	<br /><br />
	24. இப்பொழுது நான் உங்கள்நிமித்தம் அநுபவிக்கிற பாடுகளில் சந்தோஷமடைந்து, கிறிஸ்துவினுடைய உபத்திரவங்களில் குறைவானதை அவருடைய சரீரமாகிய சபைக்காக, என் மாம்சத்திலே நிறைவேற்றுகிறேன்.
	<br /><br />
	25. ஆதிகாலங்களுக்கும் தலைமுறை தலைமுறைகளுக்கும் மறைவாயிருந்து, இப்பொழுது அவருடைய பரிசுத்தவான்களுக்கு வெளியாக்கப்பட்ட இரகசியமாகிய தேவவசனத்தைப் பூரணமாய்த் தெரியப்படுத்துகிறதற்கு,
	<br /><br />
	26. உங்கள்பொருட்டுத் தேவனால் எனக்கு அளிக்கப்பட்ட உத்தியோகத்தின்படியே நான் அந்தச் சபைக்கு ஊழியக்காரனானேன்.
	<br /><br />
	27. புறஜாதிகளுக்குள்ளே விளங்கிய இந்த இரகசியத்திலுள்ள மகிமையின் ஐசுவரியம் இன்னதென்று, தேவன் தம்முடைய பரிசுத்தவான்களுக்குத் தெரியப்படுத்தச் சித்தமானார்; கிறிஸ்துவானவர் மகிமையின் நம்பிக்கையாக உங்களுக்குள் இருப்பதே அந்த இரகசியம்.
	<br /><br />
	28. எந்த மனுஷனையும் கிறிஸ்து இயேசுவுக்குள் தேறினவனாக நிறுத்தும்படிக்கு, அவரையே நாங்கள் அறிவித்து, எந்த மனுஷனுக்கும் புத்திசொல்லி, எந்த மனுஷனுக்கும் எல்லா ஞானத்தோடும் உபதேசம்பண்ணுகிறோம்.
	<br /><br />
	29. அதற்காக நான் எனக்குள்ளே வல்லமையாய்க் கிரியை நடப்பிக்கிற அவருடைய பலத்தின்படி போராடிப் பிரயாசப்படுகிறேன்.
</p>


      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Colossians1