import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 11 </title>
	<meta
          name="description"
          content="Jeremiah 11 | எரேமியா 11 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தராலே எரேமியாவுக்கு உண்டான வசனம்:
	<br /><br />
	2. நீங்கள் கேட்டு யூதாவின் மனுஷருக்கும் எருசலேமின் குடிகளுக்கும் சொல்லவேண்டிய உடன்படிக்கையின் வார்த்தைகளாவன:
	<br /><br />
	3. என் சத்தத்தைக் கேட்டு, நான் உங்களுக்குக் கற்பிக்கிறபடியே எல்லாக் காரியங்களையும் செய்யுங்கள்; அப்பொழுது நீங்கள் என் ஜனமாயிருப்பீர்கள், நான் உங்கள் தேவனாயிருப்பேன்;
	<br /><br />
	4. நான் உங்கள் பிதாக்களை இருப்புக்காளவாயாகிய எகிப்துதேசத்திலிருந்து புறப்படப்பண்ணின நாளிலே அவர்களுக்குக் கற்பித்த இந்த உடன்படிக்கையின் வார்த்தைகளைக் கேளாத மனுஷன் சபிக்கப்பட்டவனென்று, இஸ்ரவேலின் தேவனாகிய கர்த்தர் உரைக்கிறார் என்று அவர்களுக்குச் சொல்லு.
	<br /><br />
	5. இன்றையதினம் இருக்கிறபடி, பாலும் தேனும் ஓடுகிற தேசத்தை உங்கள் பிதாக்களுக்குக் கொடுப்பேன் என்று நான் அவர்களுக்கு இட்ட ஆணையை நான் திடப்படுத்தும்படி இப்படி ஆகும் என்றார்; அதற்கு நான் பிரதியுத்தரமாக, அப்படியே ஆகக்கடவது கர்த்தாவே என்றேன்.
	<br /><br />
	6. அப்பொழுது கர்த்தர் என்னை நோக்கி: நீ யூதாவின் பட்டணங்களிலும் எருசலேமின் வீதிகளிலும் இந்த வார்த்தைகளையெல்லாம் கூறி: இந்த உடன்படிக்கையின் வார்த்தைகளை நீங்கள் கேட்டு, அவைகளின்படியே செய்யுங்கள்.
	<br /><br />
	7. நான் உங்கள் பிதாக்களை எகிப்து தேசத்திலிருந்து வரப்பண்ணின நாள்முதல், இந்நாள்மட்டும் நான் அவர்களுக்குத் திடச்சாட்சியாய் என் சத்தத்தைக் கேளுங்களென்று ஏற்கனவே சாட்சி விளங்கத்தக்கவிதமாய் எச்சரித்துவந்தேன்.
	<br /><br />
	8. ஆனாலும் அவர்கள் கேளாமலும், தங்கள் செவியைச் சாயாமலும் போய், அவரவர் தம்தம் பொல்லாத இருதயகடினத்தின்படி நடந்தார்கள்; ஆதலால் நான் அவர்கள் செய்யும்படி கட்டளையிட்டதும், அவர்கள் செய்யாமற்போனதுமான இந்த உடன்படிக்கையின் வார்த்தைகளையெல்லாம் அவர்களுக்குப் பலிக்கப்பண்ணுவேன் என்று சொல் என்றார்.
	<br /><br />
	9. பின்னையும் கர்த்தர் என்னை நோக்கி: யூதாவின் மனுஷருக்குள்ளும் எருசலேமின் குடிகளுக்குள்ளும் ஒரு கட்டுப்பாடு காணப்படுகிறது.
	<br /><br />
	10. அவர்கள் என் வார்த்தைகளைக் கேட்கமாட்டோமென்று அந்நிய தேவர்களைச் சேவிக்க அவைகளைப் பின்பற்றி, தங்களுடைய முன்னோர்களின் அக்கிரமங்களுக்குத் திரும்பினார்கள்; நான் தங்கள் பிதாக்களோடே பண்ணின உடன்படிக்கையை இஸ்ரவேல் குடும்பத்தாரும் யூதா குடும்பத்தாரும் மீறிப்போட்டார்கள்.
	<br /><br />
	11. ஆகையினால் இதோ, அவர்கள் தப்பித்துக்கொள்ளமாட்டாத தீங்கை அவர்கள்மேல் வரப்பண்ணுவேன்; அப்பொழுது என்னை நோக்கிக் கூப்பிடுவார்கள்; நான் அவர்களைக் கேளாதிருப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. அப்பொழுது யூதாபட்டணங்களின் மனுஷரும், எருசலேமின் குடிகளும் போய்த் தாங்கள் தூபங்காட்டியிருந்த தேவர்களை நோக்கிக் கூப்பிட்டும், அவைகள் அவர்களுடைய ஆபத்துக்காலத்தில் அவர்களை இரட்சிப்பதில்லை.
	<br /><br />
	13. யூதாவே, உன் பட்டணங்களின் இலக்கமும் உன் தேவர்களின் இலக்கமும் சரி; எருசலேமுடைய வீதிகளின் இலக்கமும், நீங்கள் பாகாலுக்குத் தூபங்காட்டும்படி அந்த இலச்சையான காரியத்துக்கு ஸ்தாபித்த பீடங்களின் இலக்கமும் சரி.
	<br /><br />
	14. ஆதலால் நீ இந்த ஜனத்துக்காக விண்ணப்பம்பண்ணவேண்டாம், அவர்களுக்காக மன்றாடவும் கெஞ்சவும்வேண்டாம்; அவர்கள் தங்கள் ஆபத்தினிமித்தம் என்னை நோக்கிக் கூப்பிடுங்காலத்திலே நான் அவர்களைக் கேளாதிருப்பேன்.
	<br /><br />
	15. துர்ச்சனரோடு மகா தீவினை செய்யும்போது, என் பிரியமானவளுக்கு என் வீட்டில் என்ன இருக்கிறது? பரிசுத்த மாம்சத்தை உன்னைவிட்டுத் தாண்டிப்போகப்பண்ணுவார்கள்; உன் பொல்லாப்பு நடக்கும்போது நீ களிகூருகிறாயே.
	<br /><br />
	16. நல்ல கனி உண்டாயிருக்கிற நேர்த்தியும் பச்சையுமான ஒலிவமரமென்னும் பேரைக் கர்த்தர் உனக்கு இட்டார்; ஆனால் மகா அமளியின் சத்தமாய் அதைச் சுற்றிலும் நெருப்பைக் கொளுத்துகிறார், அதின் கொம்புகள் முறிக்கப்பட்டது.
	<br /><br />
	17. பாகாலுக்குத் தூபங்காட்டுகிறதினாலே எனக்குக் கோபமுண்டாக்க இஸ்ரவேல் குடும்பத்தாரும், யூதா குடும்பத்தாரும் தங்களுக்குக் கேடாகச் செய்த பொல்லாப்பினிமித்தம் உன்மேல் தீங்கை வரப்பண்ணுவேன் என்று உன்னை நாட்டின சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. அதைக் கர்த்தர் எனக்கு அறிவித்ததினாலே அறிந்துகொண்டேன்; அவர்களுடைய செய்கைகளை அப்பொழுது எனக்குத் தெரியக்காட்டினீர்.
	<br /><br />
	19. மரத்தை அதின் கனிகளோடுங்கூட அழித்துப்போடுவோமென்றும், அவன் ஜீவனுள்ளோருடைய தேசத்திலிராமலும், அவன் பேர் இனி நினைக்கப்படாமலும்போக அவனைச் சங்கரிப்போமென்றும், எனக்கு விரோதமாய் ஆலோசனைபண்ணினார்கள் என்பதை அறியாதிருந்து, நான் அடிக்கப்படுவதற்குக் கொண்டுபோகப்படும் சாதுவான ஆட்டுக்குட்டியைப்போல இருந்தேன்.
	<br /><br />
	20. சேனைகளின் கர்த்தாவே, உள்ளிந்திரியங்களையும் இருதயத்தையும் சோதித்தறிகிற நீதியுள்ள நியாயாதிபதியே, நீர் அவர்களுக்கு நீதியைச் சரிக்கட்டுகிறதைப் பார்ப்பேனாக; என் வழக்கை உமக்கு வெளிப்படுத்திவிட்டேன் என்றேன்.
	<br /><br />
	21. ஆதலால் நீ எங்கள் கையினாலே சாகாதபடிக்குக் கர்த்தருடைய நாமத்தினாலே தீர்க்கதரிசனம் சொல்லவேண்டாம் என்று சொல்லி, உன் பிராணனை வாங்கத்தேடுகிற ஆனதோத்தின் மனுஷரைக்குறித்துக் கர்த்தர் சொல்லுகிறார்:
	<br /><br />
	22. இதோ, இதினிமித்தம் உங்களை விசாரிப்பேன்; இளவயதுள்ளவர்கள் பட்டயத்தாலே சாவார்கள்; அவர்கள் குமாரரும் அவர்கள் குமாரத்திகளும் பஞ்சத்தாலே சாவார்கள்.
	<br /><br />
	23. அவர்களில் மீதியாய் இருப்பவர்களில்லை; நான் ஆனதோத்தின் மனுஷரை விசாரிக்கும் வருஷத்திலே அவர்கள்மேல் ஆபத்தை வரப்பண்ணுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah11