import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah47 = () => {
  const verseNumber = 47;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 47 </title>
	<meta
          name="description"
          content="Jeremiah 47 | எரேமியா 47 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பார்வோன் காத்சாவை அழிக்குமுன்னே, பெலிஸ்தருக்கு விரோதமாய்த் தீர்க்கதரிசியாகிய எரேமியாவுக்கு உண்டான கர்த்தருடைய வசனம்:
	<br /><br />
	2. கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, வடக்கேயிருந்து ஜலம் பொங்கிப் பிரவாகமாகித் தேசத்தின்மேலும், அதிலுள்ள எல்லாவற்றின்மேலும், நகரத்தின்மேலும், அதில் குடியிருக்கிறவர்களின்மேலும் புரண்டு ஓடும்; அப்பொழுது மனுஷர் கூக்குரலிட்டு, தேசத்தின் குடிகளெல்லாரும் அலறுவார்கள்.
	<br /><br />
	3. அவர்களுடைய பலத்த குதிரைகளுடைய குளம்புகளின் சத்தத்தையும், அவர்களுடைய இரதங்களின் கடகடப்பையும், அவர்களுடைய உருளைகளின் இரைச்சலையும் கேட்டு, தகப்பன்மார் தங்கள் கை அயர்ந்துபோனதினால் தங்கள் பிள்ளைகளையும் நோக்கிப் பாராதிருப்பார்கள்.
	<br /><br />
	4. பெலிஸ்தரையெல்லாம் பாழாக்கவும், தீருவுக்கும் சீதோனுக்கும் மீதியான சகாயரையெல்லாம் சங்காரம்பண்ணவும் வருகிற நாளிலே இப்படியாகும்; கப்தோர் என்னும் கடற்கரையான தேசத்தாரில் மீதியாகிய பெலிஸ்தரையும் கர்த்தர் பாழாக்குவார்.
	<br /><br />
	5. காத்சா மொட்டையடிக்கப்படும்; அவர்களுடைய பள்ளத்தாக்கிலே மீதியாகிய அஸ்கலோன் சங்காரமாகும்; நீ எந்தமட்டுந்தான் உன்னைக் கீறிக்கொள்ளுவாய்.
	<br /><br />
	6. ஆ கர்த்தரின் பட்டயமே, எந்தமட்டும் அமராதிருப்பாய்? உன் உறைக்குள் திரும்பிவந்து, ஓய்ந்து அமர்ந்திரு.
	<br /><br />
	7. அது எப்படி அமர்ந்திருக்கும்? அஸ்கலோனுக்கு விரோதமாகவும் கடல்துறை தேசத்துக்கு விரோதமாகவும் கர்த்தர் அதற்குக் கட்டளைகொடுத்து, அவ்விடங்களுக்கென்று அதைக் குறித்தாரே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah47