import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah44 = () => {
  const verseNumber = 44;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 44 </title>
	<meta
          name="description"
          content="Isaiah 44 | ஏசாயா 44 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இப்போதும், என் தாசனாகிய யாக்கோபே, நான் தெரிந்துகொண்ட இஸ்ரவேலே, கேள்.
	<br /><br />
	2. உன்னை உண்டாக்கினவரும், தாயின் கர்ப்பத்தில் உன்னை உருவாக்கினவரும், உனக்குத் துணைசெய்கிறவருமாகிய கர்த்தர் சொல்லுகிறதாவது: என் தாசனாகிய யாக்கோபே, நான் தெரிந்துகொண்ட யெஷூரனே, பயப்படாதே.
	<br /><br />
	3. தாகமுள்ளவன்மேல் தண்ணீரையும், வறண்ட நிலத்தின்மேல் ஆறுகளையும் ஊற்றுவேன்; உன் சந்ததியின்மேல் என் ஆவியையும், உன் சந்தானத்தின்மேல் என் ஆசீர்வாதத்தையும் ஊற்றுவேன்.
	<br /><br />
	4. அதினால் அவர்கள் புல்லின் நடுவே நீர்க்கால்களின் ஓரத்திலுள்ள அலரிச்செடிகளைப்போல வளருவார்கள்.
	<br /><br />
	5. ஒருவன், நான் கர்த்தருடையவன் என்பான்; ஒருவன், யாக்கோபின்பேரைத் தரித்துக்கொள்வான்; ஒருவன், தான் கர்த்தருடையவன் என்று கையெழுத்துப்போட்டு, இஸ்ரவேலின் நாமத்தைத் தரித்துக்கொள்வான்.
	<br /><br />
	6. நான் முந்தினவரும், நான் பிந்தினவருந்தானே; என்னைத்தவிரத் தேவன் இல்லையென்று, இஸ்ரவேலின் ராஜாவாகிய கர்த்தரும், சேனைகளின் கர்த்தராகிய அவனுடைய மீட்பரும் சொல்லுகிறார்.
	<br /><br />
	7. பூர்வகாலத்து ஜனங்களை நான் ஸ்தாபித்தது முதற்கொண்டு, என்னைப்போல எதையாகிலும் வரவழைத்து, இன்னின்னதென்று முன்னறிவித்து, எனக்கு முன்னே வரிசையாக நிறுத்தத்தக்கவன் யார்? நிகழ்காரியங்களையும் வருங்காரியங்களையும் தங்களுக்கு அவர்கள் அறிவிக்கட்டும்.
	<br /><br />
	8. நீங்கள் கலங்காமலும் பயப்படாமலும் இருங்கள்; அக்காலமுதற்கொண்டு நான் அதை உனக்கு விளங்கப்பண்ணினதும் முன்னறிவித்ததும் இல்லையோ? இதற்கு நீங்களே என் சாட்சிகள்; என்னைத்தவிரத் தேவனுண்டோ? வேறொரு கன்மலையும் இல்லையே; ஒருவனையும் அறியேன்.
	<br /><br />
	9. விக்கிரகங்களை உருவாக்குகிற யாவரும் வீணர்; அவர்களால் இச்சிக்கப்பட்டவைகள் ஒன்றுக்கும் உதவாது; அவைகள் ஒன்றும் காணாமலும், ஒன்றும் அறியாமலும் இருக்கிறதென்று தங்களுக்கு வெட்கமுண்டாக அவைகளுக்குத் தாங்களே சாட்சிகளாயிருக்கிறார்கள்.
	<br /><br />
	10. ஒன்றுக்கும் உதவாத தெய்வத்தை உருவாக்கி, விக்கிரகத்தை வார்ப்பிக்கிறவன் எப்படிப்பட்டவன்?
	<br /><br />
	11. இதோ, அவனுடைய கூட்டாளிகளெல்லாரும் வெட்கமடைவார்கள்; தொழிலாளிகள் நரஜீவன்கள்தானே; அவர்கள் எல்லாரும் கூடிவந்து நிற்கட்டும்; அவர்கள் ஏகமாய்த் திகைத்து வெட்கப்படுவார்கள்.
	<br /><br />
	12. கொல்லன் இரும்பைக் குறட்டால் இடுக்கி, உலையிலே காயவைத்து, சுத்திகளால் அதை உருவாக்கி, தன் புயபலத்தினால் அதைப் பண்படுத்துகிறான்; பட்டினியாயிருந்து பெலனற்றுப்போகிறான்; தண்ணீர் குடியாமல் களைத்துப்போகிறான்.
	<br /><br />
	13. தச்சன் நூல்பிடித்து, மட்டப்பலகையால் மரத்துக்குக் குறிபோட்டு, உளிகளினால் உருப்படுத்தி, கவராசத்தினால் அதை வகுத்து, மனுஷசாயலாக மனுஷரூபத்தின்படி உருவமாக்குகிறான்; அதைக் கோவிலிலே நாட்டி வைக்கிறான்.
	<br /><br />
	14. அவன் தனக்குக் கேதுருக்களை வெட்டுகிறான்; ஒரு மருதமரத்தையாவது ஒரு கர்வாலிமரத்தையாவது, தெரிந்துகொண்டு, காட்டுமரங்களிலே பெலத்த மரத்தைத் தன் காரியத்துக்காக வளர்க்கிறான்; அல்லது அசோகமரத்தை நடுகிறான், மழை அதை வளரச்செய்யும்.
	<br /><br />
	15. மனுஷனுக்கு அவைகள் அடுப்புக்காகும்போது, அவன் அவைகளில் எடுத்துக் குளிர்காய்கிறான்; நெருப்பைமூட்டி அப்பமும் சுடுகிறான்; அதினால் ஒரு தெய்வத்தையும் உண்டுபண்ணி, அதைப் பணிந்துகொள்ளுகிறான்; ஒரு விக்கிரகத்தையும் அதினால் செய்து, அதை வணங்குகிறான்.
	<br /><br />
	16. அதில் ஒரு துண்டை அடுப்பில் எரிக்கிறான்; ஒரு துண்டினால் இறைச்சியைச் சமைத்துப் புசித்து, பொரியலைப் பொரித்து திருப்தியாகி, குளிருங்காய்ந்து: ஆஆ, அனலானேன்; நெருப்பைக் கண்டேன் என்று சொல்லி;
	<br /><br />
	17. அதில் மீதியான துண்டைத் தனக்கு விக்கிரகதெய்வமாகச் செய்து, அதற்குமுன் விழுந்து, அதை வணங்கி: நீ என் தெய்வம், என்னை இரட்சிக்கவேண்டும் என்று அதை நோக்கி மன்றாடுகிறான்.
	<br /><br />
	18. அறியாமலும் உணராமலும் இருக்கிறார்கள்; காணாதபடிக்கு அவர்கள் கண்களும், உணராதபடிக்கு அவர்கள் இருதயமும் அடைக்கப்பட்டிருக்கிறது.
	<br /><br />
	19. அதில் பாதியை அடுப்பில் எரித்தேன்; அதின் தழலின்மேல் அப்பத்தையும் சுட்டு, இறைச்சியையும் பொரித்துப் புசித்தேன்; அதில் மீதியான துண்டை நான் அருவருப்பான விக்கிரகமாக்கலாமா? ஒரு மரக்கட்டையை வணங்கலாமா என்று சொல்ல, தன் மனதில் அவனுக்குத் தோன்றவில்லை; அம்மாத்திரம் அறிவும் சொரணையும் இல்லை.
	<br /><br />
	20. அவன் சாம்பலை மேய்கிறான்; வஞ்சிக்கப்பட்ட மனம் அவனை மோசப்படுத்தினது; அவன் தன் ஆத்துமாவைத் தப்புவிக்காமலும்: என் வலது கையிலே அபத்தம் அல்லவோ இருக்கிறதென்று சொல்லாமலும் இருக்கிறான்.
	<br /><br />
	21. யாக்கோபே, இஸ்ரவேலே, இவைகளை நினை; நீ என் தாசன்; நான் உன்னை உருவாக்கினேன்; நீ என் தாசன்; இஸ்ரவேலே, நீ என்னால் மறக்கப்படுவதில்லை.
	<br /><br />
	22. உன் மீறுதல்களை மேகத்தைப்போலவும், உன் பாவங்களைக் கார்மேகத்தைப்போலவும் அகற்றிவிட்டேன்; என்னிடத்தில் திரும்பு; உன்னை நான் மீட்டுக்கொண்டேன்.
	<br /><br />
	23. வானங்களே, களித்துப் பாடுங்கள்; கர்த்தர் இதைச் செய்தார்; பூதலத்தின் தாழ்விடங்களே, ஆர்ப்பரியுங்கள்; பர்வதங்களே, காடுகளே, காட்டிலுள்ள சகல மரங்களே, கெம்பீரமாய் முழங்குங்கள்; கர்த்தர் யாக்கோபை மீட்டு, இஸ்ரவேலிலே மகிமைப்படுகிறார்.
	<br /><br />
	24. உன் மீட்பரும், தாயின் கர்ப்பத்தில் உன்னை உருவாக்கினவருமான கர்த்தர் சொல்லுகிறதாவது: நானே எல்லாவற்றையும் செய்கிற கர்த்தர்; நான் ஒருவராய் வானங்களை விரித்து, நானே பூமியைப் பரப்பினவர்.
	<br /><br />
	25. நான் கட்டுக்கதைக்காரரின் குறிகளை அபத்தமாக்கி, குறிசொல்லுகிறவர்களை நிர்மூடராக்கி, ஞானிகளை வெட்கப்படுத்தி, அவர்கள் அறிவைப் பைத்தியமாகப் பண்ணுகிறவர்.
	<br /><br />
	26. நான் என் ஊழியக்காரரின் வார்த்தையை நிலைப்படுத்தி, என் ஸ்தானாபதிகளின் ஆலோசனையை நிறைவேற்றி: குடியேறுவாய் என்று எருசலேமுக்கும், கட்டப்படுவீர்கள் என்று யூதாவின் பட்டணங்களுக்கும் சொல்லி, அவைகளின் பாழான ஸ்தலங்களை எடுப்பிப்பவர்.
	<br /><br />
	27. நான் ஆழத்தை நோக்கி: வற்றிப்போ என்றும், உன் நதிகளை வெட்டாந்தரையாக்குவேன் என்றும் சொல்லுகிறவர்.
	<br /><br />
	28. கோரேசைக் குறித்து: அவன் என் மேய்ப்பன்; அவன் எருசலேமை நோக்கி: நீ கட்டப்படு என்றும்; தேவாலயத்தை நோக்கி: நீ அஸ்திபாரப்படு என்றும் சொல்லி, எனக்குப் பிரியமானதையெல்லாம் நிறைவேற்றுவான் என்று சொல்லுகிறவர் நான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah44