import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Lamentations3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible News | புலம்பல் 3 </title>
    <meta
          name="description"
          content="Lamentations 3 | புலம்பல் 3 |
          Pulambal | Tamil Bible News | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆண்டவருடைய சினத்தின் மிலாற்றினால் உண்டான சிறுமையைக் கண்ட புருஷன் நான்.
	<br /><br />
	2. அவர் என்னை வெளிச்சத்திலே அல்ல, இருளிலே அழைத்து நடத்திவந்தார்.
	<br /><br />
	3. அவர் தமது கையை எனக்கு விரோதமாகவே நித்தமும் திருப்பினார்.
	<br /><br />
	4. என் சதையையும் என் தோலையும் முற்றலாக்கினார்; என் எலும்புகளை நொறுக்கினார்.
	<br /><br />
	5. அவர் எனக்கு விரோதமாகக் கொத்தளங்கட்டி, கசப்பினாலும் வருத்தத்தினாலும் என்னை வளைந்துகொண்டார்.
	<br /><br />
	6. பூர்வகாலத்தில் செத்துக்கிடக்கிறவர்களைப்போல என்னை இருளான இடங்களில் கிடக்கப்பண்ணினார்.
	<br /><br />
	7. நான் புறப்படக்கூடாதபடி என்னைச் சூழ வேலியடைத்தார்; என் விலங்கைப் பாரமாக்கினார்.
	<br /><br />
	8. நான் சத்தமிட்டுக் கூப்பிட்டாலும், என் ஜெபத்துக்கு வழியை அடைத்துப்போட்டார்.
	<br /><br />
	9. வெட்டின கற்களின் சுவரால் என் வழிகளை அடைத்துப்போட்டார், என் பாதைகளைத் தாறுமாறாக்கினார்.
	<br /><br />
	10. அவர் எனக்குப் பதிவிருக்கிற கரடியும், மறைவிடங்களில் தங்குகிற சிங்கமுமாயிருக்கிறார்.
	<br /><br />
	11. என் வழிகளை அப்புறப்படுத்தி, என்னைத் துண்டித்துப்போட்டார்; என்னைப் பாழாக்கிவிட்டார்.
	<br /><br />
	12. தமது வில்லை நாணேற்றி, என்னை அம்புக்கு இலக்காக வைத்தார்.
	<br /><br />
	13. தம்முடைய அம்பறாத்தூணியின் அம்புகளை என் உள்ளிந்திரியங்களில் படப்பண்ணினார்.
	<br /><br />
	14. நான் என் ஜனத்தார் யாவருக்கும் பரியாசமும், நித்தம் அவர்கள் கின்னரப் பாடலுமானேன்.
	<br /><br />
	15. கசப்பினால் என்னை நிரப்பி, எட்டியினால் என்னை வெறிக்கச்செய்தார்.
	<br /><br />
	16. அவர் பருக்கைக்கற்களால் என் பற்களை நொறுக்கி, என்னைச் சாம்பலில் புரளப்பண்ணினார்.
	<br /><br />
	17. என் ஆத்துமாவைச் சமாதானத்துக்குத் தூரமாக்கினார்; சுகத்தை மறந்தேன்.
	<br /><br />
	18. என் பெலனும் நான் கர்த்தருக்குக் காத்திருந்த நம்பிக்கையும் அழிந்துபோயிற்று என்றேன்.
	<br /><br />
	19. எட்டியும் பிச்சுமாகிய என் சிறுமையையும் என் தவிப்பையும் நினைத்தருளும்.
	<br /><br />
	20. என் ஆத்துமா அவைகளை நினைத்து நினைத்து எனக்குள் முறிந்துபோகிறது.
	<br /><br />
	21. இதை என் மனதிலே வைத்து, நம்பிக்கைகொண்டிருப்பேன்.
	<br /><br />
	22. நாம் நிர்மூலமாகாதிருக்கிறது கர்த்தருடைய கிருபையே, அவருடைய இரக்கங்களுக்கு முடிவில்லை.
	<br /><br />
	23. அவைகள் காலைதோறும் புதியவைகள்; உமது உண்மை பெரிதாயிருக்கிறது.
	<br /><br />
	24. கர்த்தர் என் பங்கு என்று என் ஆத்துமா சொல்லும்; ஆகையால் அவரிடத்தில் நம்பிக்கைகொண்டிருப்பேன்.
	<br /><br />
	25. தமக்குக் காத்திருக்கிறவர்களுக்கும் தம்மைத் தேடுகிற ஆத்துமாவுக்கும் கர்த்தர் நல்லவர்.
	<br /><br />
	26. கர்த்தருடைய இரட்சிப்புக்கு நம்பிக்கையோடு காத்திருக்கிறது நல்லது.
	<br /><br />
	27. தன் இளம்பிராயத்தில் நுகத்தைச் சுமக்கிறது மனுஷனுக்கு நல்லது.
	<br /><br />
	28. அவரே அதைத் தன்மேல் வைத்தாரென்று அவன் தனித்திருந்து மௌனமாயிருக்கக்கடவன்.
	<br /><br />
	29. நம்பிக்கைக்கு இடமுண்டோ என்று தன் வாயைத் தூளில் நுழுந்துவானாக.
	<br /><br />
	30. தன்னை அடிக்கிறவனுக்குத் தன் கன்னத்தைக் காட்டி, நிந்தையால் நிறைந்திருப்பானாக.
	<br /><br />
	31. ஆண்டவர் என்றென்றைக்கும் கைவிடமாட்டார்.
	<br /><br />
	32. அவர் சஞ்சலப்படுத்தினாலும் தமது மிகுந்த கிருபையின்படி இரங்குவார்.
	<br /><br />
	33. அவர் மனப்பூர்வமாய் மனுபுத்திரரைச் சிறுமையாக்கிச் சஞ்சலப்படுத்துகிறதில்லை.
	<br /><br />
	34. ஒருவன் பூமியில் சிறைப்பட்டவர்கள் யாவரையும் தன் கால்களின்கீழ் நசுக்குகிறதையும்,
	<br /><br />
	35. உன்னதமானவரின் சமுகத்தில் மனுஷருடைய நியாயத்தைப் புரட்டுகிறதையும்,
	<br /><br />
	36. மனுஷனை அவனுடைய வழக்கிலே மாறுபாடாக்குகிறதையும், ஆண்டவர் காணாதிருப்பாரோ?
	<br /><br />
	37. ஆண்டவர் கட்டளையிடாதிருக்கக் காரியம் சம்பவிக்கும் என்று சொல்லுகிறவன் யார்?
	<br /><br />
	38. உன்னதமானவருடைய வாயிலிருந்து தீமையும் நன்மையும் புறப்படுகிறதில்லையோ?
	<br /><br />
	39. உயிருள்ள மனுஷன் முறையிடுவானேன்? அவன் தன் பாவத்துக்கு வரும் தண்டனையைக்குறித்து முறையிடுகிறதென்ன?
	<br /><br />
	40. நாம் நம்முடைய வழிகளைச் சோதித்து ஆராய்ந்து, கர்த்தரிடத்தில் திரும்பக்கடவோம்.
	<br /><br />
	41. நாம் நம்முடைய கைகளோடுங்கூட நம்முடைய இருதயத்தையும் பரலோகத்திலிருக்கிற தேவனிடத்திற்கு ஏறெடுக்கக்கடவோம்.
	<br /><br />
	42. நாங்கள் துரோகஞ்செய்து, கலகம்பண்ணினோம்; ஆகையால் தேவரீர் மன்னியாதிருந்தீர்.
	<br /><br />
	43. தேவரீர் கோபத்தால் மூடிக்கொண்டு, எங்களைத் தப்பவிடாமல் பின்தொடர்ந்து கொன்றீர்.
	<br /><br />
	44. ஜெபம் உட்பிரவேசிக்கக்கூடாதபடிக்கு உம்மை மேகத்தால் மூடிக்கொண்டீர்.
	<br /><br />
	45. ஜனங்களுக்குள்ளே எங்களைக் குப்பையும் அருவருப்புமாக்கினீர்.
	<br /><br />
	46. எங்கள் பகைஞர் எல்லாரும் எங்களுக்கு விரோதமாய்த் தங்கள் வாயைத் திறந்தார்கள்.
	<br /><br />
	47. திகிலும் படுகுழியும் பாழ்க்கடிப்பும் சங்காரமும் எங்களுக்கு நேரிட்டது.
	<br /><br />
	48. என் ஜனமாகிய குமாரத்தி அடைந்த கேட்டினிமித்தம் என் கண்களிலிருந்து நீர்க்கால்கள் பாய்கிறது.
	<br /><br />
	49. கர்த்தர் பரலோகத்திலிருந்து நோக்கிப்பார்க்குமட்டும்,
	<br /><br />
	50. என் கண் இடைவிடாமல் ஓய்வின்றிச் சொரிகிறது.
	<br /><br />
	51. என் நகரத்தினுடைய குமாரத்திகள் அனைவரினிமித்தமும், என் கண் என் ஆத்துமாவுக்கு நோவுண்டாக்குகிறது.
	<br /><br />
	52. முகாந்தரம் இல்லாமல் என்னைப் பகைக்கிறவர்கள் என்னை ஒரு பட்சியைப்போல வேட்டையாடினார்கள்.
	<br /><br />
	53. காவற்கிடங்கிலே என் பிராணனை ஒடுக்கி, என்மீதில் கல்லை வைத்தார்கள்.
	<br /><br />
	54. தண்ணீர் என் தலையின்மேல் புரண்டது; நாசமானேன் என்றேன்.
	<br /><br />
	55. மகா ஆழமான கிடங்கிலிருந்து, கர்த்தாவே, உம்முடைய நாமத்தைப்பற்றிக் கூப்பிட்டேன்.
	<br /><br />
	56. என் சத்தத்தைக் கேட்டீர்; என் பெருமூச்சுக்கும் என் கூப்பிடுதலுக்கும் உமது செவியை அடைத்துக்கொள்ளாதேயும்.
	<br /><br />
	57. நான் உம்மை நோக்கிக் கூப்பிட்ட நாளிலே நீர் அணுகி: பயப்படாதே என்றீர்.
	<br /><br />
	58. ஆண்டவரே, என் ஆத்துமாவின் வழக்கை நடத்தினீர்; என் பிராணனை மீட்டுக்கொண்டீர்.
	<br /><br />
	59. கர்த்தாவே, எனக்கு உண்டான அநியாயத்தைக் கண்டீர்; என் நியாயத்தைத் தீரும்.
	<br /><br />
	60. அவர்களுடைய எல்லாக் குரோதத்தையும், அவர்கள் எனக்கு விரோதமாக நினைத்த எல்லா நினைவுகளையும் கண்டீர்.
	<br /><br />
	61. கர்த்தாவே, அவர்கள் நிந்திக்கும் நிந்தையையும், அவர்கள் எனக்கு விரோதமாய் நினைத்த எல்லா நினைவுகளையும்,
	<br /><br />
	62. எனக்கு விரோதமாய் எழும்பினவர்களின் வாய்மொழிகளையும், அவர்கள் நாள்முழுதும் எனக்கு விரோதமாய் யோசிக்கும் யோசனைகளையும் கேட்டீர்.
	<br /><br />
	63. அவர்கள் உட்கார்ந்திருப்பதையும் அவர்கள் எழுந்திருப்பதையும் நோக்கிப்பாரும்; நான் அவர்களுடைய பாடலாயிருக்கிறேன்.
	<br /><br />
	64. கர்த்தாவே, அவர்கள் கைகள் செய்த கிரியைகளுக்குத்தக்கதாக அவர்களுக்குப் பலன் அளிப்பீர்.
	<br /><br />
	65. அவர்களுக்கு இருதய வேதனையைக் கொடுப்பீர், உம்முடைய சாபம் அவர்கள்மேல் இருக்கும்.
	<br /><br />
	66. கோபமாய் அவர்களைப் பின்தொடர்ந்து கர்த்தருடைய வானங்களின் கீழ் இராதபடிக்கு அவர்களை அழித்துவிடுவீர்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Lamentations3