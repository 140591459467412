import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OnePeter3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title> Godly Living Peter in Tamil Bible Verse Chapter -3 | 1 பேதுரு - 3 </title>
	<meta
          name="description"
          content="Godly Living in Tamil Bible Verse | 1-பேதுரு அதிகாரம் - 3 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil | தமிழ் வேதாகமம்"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* தெய்வீக வாழ்க்கை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. அந்தப்படி மனைவிகளே, உங்கள் சொந்தப் புருஷர்களுக்குக் கீழ்ப்படிந்திருங்கள்; அப்பொழுது அவர்களில் யாராவது திருவசனத்திற்குக் கீழ்ப்படியாதவர்களாயிருந்தால், பயபக்தியோடு கூடிய உங்கள் கற்புள்ள நடக்கையை அவர்கள் பார்த்து,
	<br /><br />
	2. போதனையின்றி, மனைவிகளின் நடக்கையினாலேயே ஆதாயப்படுத்திக்கொள்ளப்படுவார்கள்.
	<br /><br />
	3. மயிரைப் பின்னி, பொன்னாபரணங்களை அணிந்து, உயர்ந்த வஸ்திரங்களை உடுத்திக்கொள்ளுதலாகிய புறம்பான அலங்கரிப்பு உங்களுக்கு அலங்காரமாயிராமல்,
	<br /><br />
	4. அழியாத அலங்கரிப்பாயிருக்கிற சாந்தமும் அமைதலுமுள்ள ஆவியாகிய இருதயத்தில் மறைந்திருக்கிற குணமே உங்களுக்கு அலங்காரமாயிருக்கக்கடவது; அதுவே தேவனுடைய பார்வையில் விலையேறப்பெற்றது.
	<br /><br />
	5. இப்படியே பூர்வத்தில் தேவனிடத்தில் நம்பிக்கையாயிருந்த பரிசுத்த ஸ்திரீகளும் தங்களுடைய புருஷர்களுக்குக் கீழ்ப்படிந்து தங்களை அலங்கரித்தார்கள்.
	<br /><br />
	6. அந்தப்படியே சாராள் ஆபிரகாமை ஆண்டவன் என்று சொல்லி, அவனுக்குக் கீழ்ப்படிந்திருந்தாள்; நீங்கள் நன்மைசெய்து ஒரு ஆபத்துக்கும் பயப்படாதிருந்தீர்களானால் அவளுக்குப் பிள்ளைகளாயிருப்பீர்கள்.
	<br /><br />
	7. அந்தப்படி புருஷர்களே, மனைவியானவள் பெலவீன பாண்டமாயிருக்கிறபடியினால், உங்கள் ஜெபங்களுக்குத் தடைவராதபடிக்கு, நீங்கள் விவேகத்தோடு அவர்களுடனே வாழ்ந்து, உங்களுடனேகூட அவர்களும் நித்திய ஜீவனாகிய கிருபையைச் சுதந்தரித்துக்கொள்ளுகிறவர்களானபடியினால், அவர்களுக்குச் செய்யவேண்டிய கனத்தைச் செய்யுங்கள்.
	<br /><br />
	8. மேலும், நீங்களெல்லாரும் ஒருமனப்பட்டவர்களும், இரக்கமுள்ளவர்களும், சகோதர சிநேகமுள்ளவர்களும், மனஉருக்கமுள்ளவர்களும், இணக்கமுள்ளவர்களுமாயிருந்து,
	<br /><br />
	9. தீமைக்குத் தீமையையும், உதாசனத்துக்கு உதாசனத்தையும் சரிக்கட்டாமல், அதற்குப் பதிலாக, நீங்கள் ஆசீர்வாதத்தைச் சுதந்தரித்துக்கொள்ளும்படி அழைக்கப்பட்டிருக்கிறவர்களென்று அறிந்து, ஆசீர்வதியுங்கள்.
	<br /><br />
	10. ஜீவனை விரும்பி, நல்ல நாட்களைக் காணவேண்டுமென்றிருக்கிறவன் பொல்லாப்புக்குத் தன் நாவையும், கபடத்துக்குத் தன் உதடுகளையும் விலக்கிக்காத்து,
	<br /><br />
	11. பொல்லாப்பைவிட்டு நீங்கி, நன்மைசெய்து, சமாதானத்தைத் தேடி, அதைப் பின்தொடரக்கடவன்.
	<br /><br />
	12. கர்த்தருடைய கண்கள் நீதிமான்கள்மேல் நோக்கமாயிருக்கிறது, அவருடைய செவிகள் அவர்கள் வேண்டுதலுக்குக் கவனமாயிருக்கிறது; தீமைசெய்கிறவர்களுக்கோ கர்த்தருடைய முகம் விரோதமாயிருக்கிறது.
	<br /><br />
	13. நீங்கள் நன்மையைப் பின்பற்றுகிறவர்களானால், உங்களுக்குத் தீமைசெய்கிறவன் யார்?
	<br /><br />
	14. நீதியினிமித்தமாக நீங்கள் பாடுபட்டால் பாக்கியவான்களாயிருப்பீர்கள்; அவர்களுடைய பயமுறுத்தலுக்கு நீங்கள் பயப்படாமலும் கலங்காமலும் இருந்து;
	<br /><br />
	15. கர்த்தராகிய தேவனை உங்கள் இருதயங்களில் பரிசுத்தம்பண்ணுங்கள்; உங்களிலிருக்கிற நம்பிக்கையைக்குறித்து உங்களிடத்தில் விசாரித்துக் கேட்கிற யாவருக்கும் சாந்தத்தோடும் வணக்கத்தோடும் உத்தரவுசொல்ல எப்பொழுதும் ஆயத்தமாயிருங்கள்.
	<br /><br />
	16. கிறிஸ்துவுக்கேற்ற உங்கள் நல்ல நடக்கையைத் தூஷிக்கிறவர்கள் உங்களை அக்கிரமக்காரரென்று உங்களுக்கு விரோதமாய்ச் சொல்லுகிற விஷயத்தில் வெட்கப்படும்படிக்கு நல்மனச்சாட்சியுடையவர்களாயிருங்கள்.
	<br /><br />
	17. தீமைசெய்து பாடநுபவிப்பதிலும், தேவனுக்குச் சித்தமானால், நன்மைசெய்து பாடநுபவிப்பதே மேன்மையாயிருக்கும்.
	<br /><br />
	18. ஏனெனில், கிறிஸ்துவும் நம்மைத் தேவனிடத்தில் சேர்க்கும்படி அநீதியுள்ளவர்களுக்குப் பதிலாக நீதியுள்ளவராய்ப் பாவங்களினிமித்தம் ஒருதரம் பாடுபட்டார்; அவர் மாம்சத்திலே கொலையுண்டு, ஆவியிலே உயிர்ப்பிக்கப்பட்டார்.
	<br /><br />
	19. அந்த ஆவியிலே அவர் போய்க் காவலிலுள்ள ஆவிகளுக்குப் பிரசங்கித்தார்.
	<br /><br />
	20. அந்த ஆவிகள், பூர்வத்திலே நோவா பேழையை ஆயத்தம்பண்ணும் நாட்களிலே, தேவன் நீடிய பொறுமையோடே காத்திருந்தபோது, கீழ்ப்படியாமற் போனவைகள்; அந்தப் பேழையிலே சிலராகிய எட்டுப்பேர்மாத்திரம் பிரவேசித்து ஜலத்தினாலே காக்கப்பட்டார்கள்.
	<br /><br />
	21. அதற்கு ஒப்பனையான ஞானஸ்நானமானது, மாம்ச அழுக்கை நீக்குதலாயிராமல், தேவனைப்பற்றும் நல்மனச்சாட்சியின் உடன்படிக்கையாயிருந்து, இப்பொழுது நம்மையும் இயேசுகிறிஸ்துவினுடைய உயிர்த்தெழுதலினால் இரட்சிக்கிறது;
	<br /><br />
	22. அவர் பரலோகத்திற்குப் போய், தேவனுடைய வலதுபாரிசத்தில் இருக்கிறார்; தேவதூதர்களும் அதிகாரங்களும் வல்லமைகளும் அவருக்குக் கீழ்ப்பட்டிருக்கிறது.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* 1 பேதுரு 3 கணவன் மற்றும் மனைவிக்கான ஆலோசனையுடன் தொடங்குகிறது. மனைவிகள் தங்களுடைய உள்ளார்ந்த அழகு பிரகாசிக்கும் வகையில் வாழ வேண்டும். கணவன்மார்கள் தங்கள் மனைவிகளிடம் கரிசனையோடும் மரியாதையோடும் இருக்க வேண்டும், அதனால் பிரார்த்தனைக்கு எதுவும் தடையாக இருக்காது. ஒருவரையொருவர் இணக்கமாக வாழ ஊக்குவிப்பதன் மூலம் பேதுரு சுருக்கமாக கூறுகிறார். கிறிஸ்து இயேசுவை ஆண்டவராக ஒதுக்கி வைத்துவிட்டு, அவர் மீது நாம் வைத்திருக்கும் இந்த நம்பிக்கைக்கு பதில் அளிக்க எப்போதும் தயாராக இருக்க வேண்டும். பிறகு, அநியாயமாக துன்பப்படும்போதும் மனசாட்சியை எப்பொழுதும் தெளிவாக வைத்திருக்க வேண்டும் என்பதை நினைவுபடுத்துகிறோம். *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OnePeter3