import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Titus2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Sound Behavior | Titus in Tamil Bible Verse | தீத்து அதிகாரம்2</title>
	<meta
          name="description"
          content="Sound Doctrine and Sound Behavior | Titus Chapter-2 | தீத்துக்கு எழுதின நிருபம்  | அதிகாரம்-2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
<h2>
* பேச்சு கோட்பாடு மற்றும் பேச்சு நடத்தை *
			</h2>
<br /><br />

      {/* Vesrse of Tamilbible */}

	  <p>
	1. நீயோ ஆரோக்கியமான உபதேசத்துக்கேற்றவைகளைப் பேசு.
	<br /><br />
	2. முதிர்வயதுள்ள புருஷர்கள் ஜாக்கிரதையுள்ளவர்களும், நல்லொழுக்கமுள்ளவர்களும், தெளிந்த புத்தியுள்ளவர்களும், விசுவாசத்திலும் அன்பிலும் பொறுமையிலும் ஆரோக்கியமுள்ளவர்களுமாயிருக்கும்படி புத்திசொல்லு.
	<br /><br />
	3. முதிர்வயதுள்ள ஸ்திரீகளும் அப்படியே பரிசுத்தத்துக்கேற்றவிதமாய் நடக்கிறவர்களும், அவதூறுபண்ணாதவர்களும், மதுபானத்துக்கு அடிமைப்படாதவர்களுமாயிருக்கவும்,
	<br /><br />
	4. தேவவசனம் தூஷிக்கப்படாதபடிக்குப் பாலிய ஸ்திரீகள் தங்கள் புருஷரிடத்திலும், தங்கள் பிள்ளைகளிடத்திலும் அன்புள்ளவர்களும்,
	<br /><br />
	5. தெளிந்த புத்தியுள்ளவர்களும், கற்புள்ளவர்களும், வீட்டில் தரித்திருக்கிறவர்களும், நல்லவர்களும், தங்கள் புருஷருக்குக் கீழ்ப்படிகிறவர்களுமாயிருக்கும்படி, அவர்களுக்குப் படிப்பிக்கத்தக்க நற்காரியங்களைப் போதிக்கிறவர்களுமாயிருக்கவும் முதிர்வயதுள்ள ஸ்திரீகளுக்குப் புத்திசொல்லு.
	<br /><br />
	6. அப்படியே, பாலிய புருஷரும் தெளிந்த புத்தியுள்ளவர்களாயிருக்கவும் நீ புத்திசொல்லி,
	<br /><br />
	7. நீயே எல்லாவற்றிலும் உன்னை நற்கிரியைகளுக்கு மாதிரியாகக் காண்பித்து,
	<br /><br />
	8. எதிரியானவன் உங்களைக்குறித்துப் பொல்லாங்கு சொல்லுகிறதற்கு ஒன்றுமில்லாமல் வெட்கப்படத்தக்கதாக, உபதேசத்திலே விகற்பமில்லாதவனும், நல்லொழுக்கமுள்ளவனும் குற்றம்பிடிக்கப்படாத ஆரோக்கியமான வசனத்தைப் பேசுகிறவனுமாயிருப்பாயாக.
	<br /><br />
	9. வேலைக்காரர் நம்முடைய இரட்சகராகிய தேவனுடைய உபதேசத்தை எல்லாவற்றிலும் அலங்கரிக்கத்தக்கதாக,
	<br /><br />
	10. தங்கள் எஜமான்களுக்குக் கீழ்ப்படிந்திருந்து எதிர்த்துப்பேசாமல், எல்லாவற்றிலும் பிரியமுண்டாக நடந்துகொள்ளவும், திருடாமலிருந்து, சகலவிதத்திலும் நல்லுண்மையைக் காண்பிக்கவும் புத்திசொல்லு.
	<br /><br />
	11. ஏனெனில் எல்லா மனுஷருக்கும் இரட்சிப்பை அளிக்கத்தக்க தேவகிருபையானது பிரசன்னமாகி,
	<br /><br />
	12. நாம் அவபக்தியையும் லௌகிக இச்சைகளையும் வெறுத்து, தெளிந்த புத்தியும் நீதியும் தேவபக்தியும் உள்ளவர்களாய் இவ்வுலகத்திலே ஜீவனம்பண்ணி,
	<br /><br />
	13. நாம் நம்பியிருக்கிற ஆனந்த பாக்கியத்துக்கும், மகா தேவனும் நமது இரட்சகருமாகிய இயேசுகிறிஸ்துவினுடைய மகிமையின் பிரசன்னமாகுதலுக்கும் எதிர்பார்த்துக்கொண்டிருக்கும்படி நமக்குப் போதிக்கிறது.
	<br /><br />
	14. அவர் நம்மைச் சகல அக்கிரமங்களினின்று மீட்டுக்கொண்டு, தமக்குரிய சொந்த ஜனங்களாகவும், நற்கிரியைகளைச் செய்யப் பக்திவைராக்கியமுள்ளவர்களாகவும் நம்மைச் சுத்திகரிக்கும்படி, நமக்காகத் தம்மைத்தாமே ஒப்புக்கொடுத்தார்.
	<br /><br />
	15. இவைகளை நீ பேசி, போதித்து, சகல அதிகாரத்தோடும் கடிந்துகொள். ஒருவனும் உன்னை அசட்டைபண்ண இடங்கொடாதிருப்பாயாக.
</p>




      {/* End of verse */} 

	  <br />
<h4>
 <p>
* தீத்து அதிகாரம்-2  பேச்சுக் கோட்பாட்டின்படி கற்பிப்பதற்கான கட்டணத்துடன் தொடங்குகிறது. பிறகு, வெவ்வேறு குழுக்களுக்கு அவர்கள் எவ்வாறு செயல்பட வேண்டும் மற்றும் நடந்து கொள்ள வேண்டும் என்பதற்கான வழிமுறைகளை பால் வழங்குகிறார். ஒவ்வொரு அறிவுறுத்தலுக்குப் பிறகும், அவர்களின் நடத்தையால் யாரும் கடவுளுடைய வார்த்தையைக் கேவலப்படுத்தக்கூடாது என்பதே நோக்கம் என்பது தெளிவாகிறது. எல்லா விதத்திலும் கடவுளைப் பற்றிய போதனைகள் சாட்சி கொடுப்பவர்களுக்கு கவர்ச்சிகரமானதாக இருக்கும், ஏனென்றால் கிறிஸ்து நம்மை எல்லா அக்கிரமங்களிலிருந்தும் மீட்டு, நன்மை செய்ய ஆர்வமுள்ள மக்களைத் தூய்மைப்படுத்தினார். *
</p>
			</h4>
<br /><br />
      </div>
    </div>
    </>
  );
}

export default Titus2