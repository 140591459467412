import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 5 </title>
	<meta
          name="description"
          content="Matthew 5 | மத்தேயு 5 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் திரளான ஜனங்களைக் கண்டு மலையின்மேல் ஏறினார்; அவர் உட்கார்ந்தபொழுது, அவருடைய சீஷர்கள் அவரிடத்தில் வந்தார்கள்.
	<br /><br />
	2. அப்பொழுது அவர் தமது வாயைத் திறந்து அவர்களுக்கு உபதேசித்துச் சொன்னது என்னவென்றால்:
	<br /><br />
	3. ஆவியில் எளிமையுள்ளவர்கள் பாக்கியவான்கள்; பரலோகராஜ்யம் அவர்களுடையது.
	<br /><br />
	4. துயரப்படுகிறவர்கள் பாக்கியவான்கள்; அவர்கள் ஆறுதலடைவார்கள்.
	<br /><br />
	5. சாந்தகுணமுள்ளவர்கள் பாக்கியவான்கள்; அவர்கள் பூமியைச் சுதந்தரித்துக்கொள்ளுவார்கள்.
	<br /><br />
	6. நீதியின்மேல் பசிதாகமுள்ளவர்கள் பாக்கியவான்கள்; அவர்கள் திருப்தியடைவார்கள்.
	<br /><br />
	7. இரக்கமுள்ளவர்கள் பாக்கியவான்கள்; அவர்கள் இரக்கம் பெறுவார்கள்.
	<br /><br />
	8. இருதயத்தில் சுத்தமுள்ளவர்கள் பாக்கியவான்கள்; அவர்கள் தேவனைத் தரிசிப்பார்கள்.
	<br /><br />
	9. சமாதானம்பண்ணுகிறவர்கள் பாக்கியவான்கள்; அவர்கள் தேவனுடைய புத்திரர் என்னப்படுவார்கள்.
	<br /><br />
	10. நீதியினிமித்தம் துன்பப்படுகிறவர்கள் பாக்கியவான்கள்; பரலோகராஜ்யம் அவர்களுடையது.
	<br /><br />
	11. என்னிமித்தம் உங்களை நிந்தித்துத் துன்பப்படுத்தி, பலவித தீமையான மொழிகளையும் உங்கள்பேரில் பொய்யாய்ச் சொல்வார்களானால் பாக்கியவான்களாயிருப்பீர்கள்;
	<br /><br />
	12. சந்தோஷப்பட்டு, களிகூருங்கள்; பரலோகத்தில் உங்கள் பலன் மிகுதியாயிருக்கும்; உங்களுக்கு முன்னிருந்த தீர்க்கதரிசிகளையும் அப்படியே துன்பப்படுத்தினார்களே.
	<br /><br />
	13. நீங்கள் பூமிக்கு உப்பாயிருக்கிறீர்கள்; உப்பானது சாரமற்றுப்போனால், எதினால் சாரமாக்கப்படும்? வெளியே கொட்டப்படுவதற்கும், மனுஷரால் மிதிக்கப்படுவதற்குமே ஒழிய வேறொன்றுக்கும் உதவாது.
	<br /><br />
	14. நீங்கள் உலகத்துக்கு வெளிச்சமாயிருக்கிறீர்கள்; மலையின்மேல் இருக்கிற பட்டணம் மறைந்திருக்கமாட்டாது.
	<br /><br />
	15. விளக்கைக் கொளுத்தி மரக்காலால் மூடிவைக்காமல், விளக்குத் தண்டின்மேல் வைப்பார்கள்; அப்பொழுது அது வீட்டிலுள்ள யாவருக்கும் வெளிச்சம் கொடுக்கும்.
	<br /><br />
	16. இவ்விதமாய், மனுஷர் உங்கள் நற்கிரியைகளைக் கண்டு, பரலோகத்திலிருக்கிற உங்கள் பிதாவை மகிமைப்படுத்தும்படி, உங்கள் வெளிச்சம் அவர்கள் முன்பாகப் பிரகாசிக்கக்கடவது.
	<br /><br />
	17. நியாயப்பிரமாணத்தையானாலும் தீர்க்கதரிசனங்களையானாலும் அழிக்கிறதற்கு வந்தேன் என்று எண்ணிக்கொள்ளாதேயுங்கள்; அழிக்கிறதற்கு அல்ல, நிறைவேற்றுகிறதற்கே வந்தேன்.
	<br /><br />
	18. வானமும் பூமியும் ஒழிந்துபோனாலும், நியாயப்பிரமாணத்திலுள்ளதெல்லாம் நிறைவேறுமளவும், அதில் ஒரு சிறு எழுத்தாகிலும், ஒரு எழுத்தின் உறுப்பாகிலும் ஒழிந்துபோகாது என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	19. ஆகையால், இந்தக் கற்பனைகள் எல்லாவற்றிலும் சிறிதானதொன்றையாகிலும் மீறி, அவ்விதமாய் மனுஷருக்குப் போதிக்கிறவன் பரலோகராஜ்யத்தில் எல்லாரிலும் சிறியவன் என்னப்படுவான்; இவைகளைக் கைக்கொண்டு போதிக்கிறவனோ, பரலோகராஜ்யத்தில் பெரியவன் என்னப்படுவான்.
	<br /><br />
	20. வேதபாரகர் பரிசேயர் என்பவர்களுடைய நீதியிலும் உங்கள் நீதி அதிகமாயிராவிட்டால், பரலோகராஜ்யத்தில் பிரவேசிக்கமாட்டீர்கள் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	21. கொலை செய்யாதிருப்பாயாக என்பதும், கொலைசெய்கிறவன் நியாயத்தீர்ப்புக்கு ஏதுவாயிருப்பான் என்பதும், பூர்வத்தாருக்கு உரைக்கப்பட்டதென்று கேள்விப்பட்டிருக்கிறீர்கள்.
	<br /><br />
	22. நான் உங்களுக்குச் சொல்லுகிறேன்; தன் சகோதரனை நியாயமில்லாமல் கோபித்துக்கொள்பவன் நியாயத்தீர்ப்புக்கு ஏதுவாயிருப்பான்; தன் சகோதரனை வீணனென்று சொல்லுகிறவன் ஆலோசனைச் சங்கத்தீர்ப்புக்கு ஏதுவாயிருப்பான்; மூடனே என்று சொல்லுகிறவன் எரிநரகத்துக்கு ஏதுவாயிருப்பான்.
	<br /><br />
	23. ஆகையால், நீ பலிபீடத்தினிடத்தில் உன் காணிக்கையைச் செலுத்த வந்து, உன்பேரில் உன் சகோதரனுக்குக் குறைஉண்டென்று அங்கே நினைவுகூருவாயாகில்,
	<br /><br />
	24. அங்கேதானே பலிபீடத்தின் முன் உன் காணிக்கையை வைத்துவிட்டுப் போய், முன்பு உன் சகோதரனோடே ஒப்புரவாகி, பின்பு வந்து உன் காணிக்கையைச் செலுத்து.
	<br /><br />
	25. எதிராளி உன்னை நியாயாதிபதியினிடத்தில் ஒப்புக்கொடாமலும், நியாயாதிபதி உன்னைச் சேவகனிடத்தில் ஒப்புக்கொடாமலும், நீ சிறைச்சாலையில் வைக்கப்படாமலும் இருக்கும்படியாக, நீ உன் எதிராளியோடு வழியில் இருக்கும்போதே சீக்கிரமாய் அவனுடனே நல்மனம் பொருந்து.
	<br /><br />
	26. பொருந்தாவிட்டால், நீ ஒரு காசும் குறைவின்றிக் கொடுத்துத் தீர்க்குமட்டும் அவ்விடத்திலிருந்து புறப்படமாட்டாய் என்று, மெய்யாகவே உனக்குச் சொல்லுகிறேன்.
	<br /><br />
	27. விபசாரஞ் செய்யாதிருப்பாயாக என்பது பூர்வத்தாருக்கு உரைக்கப்பட்டதென்று கேள்விப்பட்டிருக்கிறீர்கள்.
	<br /><br />
	28. நான் உங்களுக்குச் சொல்லுகிறேன்; ஒரு ஸ்திரீயை இச்சையோடு பார்க்கிற எவனும் தன் இருதயத்தில் அவளோடே விபசாரஞ்செய்தாயிற்று.
	<br /><br />
	29. உன் வலது கண் உனக்கு இடறலுண்டாக்கினால், அதைப் பிடுங்கி எறிந்துபோடு; உன் சரீரம் முழுவதும் நரகத்தில் தள்ளப்படுவதைப்பார்க்கிலும், உன் அவயவங்களில் ஒன்று கெட்டுப்போவது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	30. உன் வலதுகை உனக்கு இடறலுண்டாக்கினால், அதைத் தறித்து எறிந்துபோடு; உன் சரீரம் முழுவதும் நரகத்தில் தள்ளப்படுவதைப்பார்க்கிலும், உன் அவயவங்களில் ஒன்று கெட்டுப்போவது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	31. தன் மனைவியைத் தள்ளிவிடுகிற எவனும், தள்ளுதற்சீட்டை அவளுக்குக் கொடுக்கக்கடவன் என்று உரைக்கப்பட்டது.
	<br /><br />
	32. நான் உங்களுக்குச் சொல்லுகிறேன்; வேசித்தன முகாந்தரத்தினாலொழிய தன் மனைவியைத் தள்ளிவிடுகிறவன், அவளை விபசாரஞ்செய்யப்பண்ணுகிறவனாயிருப்பான்; அப்படித் தள்ளிவிடப்பட்டவளை விவாகம்பண்ணுகிறவனும் விபசாரஞ்செய்கிறவனாயிருப்பான்.
	<br /><br />
	33. அன்றியும், பொய்யாணையிடாமல், உன் ஆணைகளைக் கர்த்தர் முன்னிலையாய்ச் செலுத்துவாயாக என்று பூர்வத்தாருக்கு உரைக்கப்பட்டதென்று கேள்விப்பட்டிருக்கிறீர்கள்.
	<br /><br />
	34. நான் உங்களுக்குச் சொல்லுகிறேன்; பரிச்சேதம் சத்தியம்பண்ணவேண்டாம்; வானத்தின்பேரில் சத்தியம்பண்ணவேண்டாம், அது தேவனுடைய சிங்காசனம்.
	<br /><br />
	35. பூமியின்பேரிலும் சத்தியம்பண்ணவேண்டாம், அது அவருடைய பாதபடி; எருசலேமின்பேரிலும் சத்தியம்பண்ணவேண்டாம், அது மகாராஜாவினுடைய நகரம்.
	<br /><br />
	36. உன் சிரசின்பேரிலும் சத்தியம்பண்ணவேண்டாம், அதின் ஒரு மயிரையாவது வெண்மையாக்கவும் கறுப்பாக்கவும் உன்னால் கூடாதே.
	<br /><br />
	37. உள்ளதை உள்ளதென்றும், இல்லதை இல்லதென்றும் சொல்லுங்கள்; இதற்கு மிஞ்சினது தீமையினால் உண்டாயிருக்கும்.
	<br /><br />
	38. கண்ணுக்குக் கண், பல்லுக்குப் பல் என்று, உரைக்கப்பட்டதைக் கேள்விப்பட்டிருக்கிறீர்கள்.
	<br /><br />
	39. நான் உங்களுக்குச் சொல்லுகிறேன்; தீமையோடு எதிர்த்து நிற்கவேண்டாம்; ஒருவன் உன்னை வலது கன்னத்தில் அறைந்தால், அவனுக்கு மறு கன்னத்தையும் திருப்பிக்கொடு.
	<br /><br />
	40. உன்னோடு வழக்காடி உன் வஸ்திரத்தை எடுத்துக்கொள்ளவேண்டுமென்றிருக்கிறவனுக்கு உன் அங்கியையும் விட்டுவிடு.
	<br /><br />
	41. ஒருவன் உன்னை ஒரு மைல் தூரம்வரப் பலவந்தம்பண்ணினால், அவனோடு இரண்டு மைல் தூரம் போ.
	<br /><br />
	42. உன்னிடத்தில் கேட்கிறவனுக்குக் கொடு, உன்னிடத்தில் கடன்வாங்க விரும்புகிறவனுக்கு முகங்கோணாதே.
	<br /><br />
	43. உனக்கடுத்தவனைச் சிநேகித்து, உன் சத்துருவைப் பகைப்பாயாக என்று சொல்லப்பட்டதைக் கேள்விப்பட்டிருக்கிறீர்கள்.
	<br /><br />
	44. நான் உங்களுக்குச் சொல்லுகிறேன்; உங்கள் சத்துருக்களைச் சிநேகியுங்கள்; உங்களைச் சபிக்கிறவர்களை ஆசீர்வதியுங்கள்; உங்களைப் பகைக்கிறவர்களுக்கு நன்மை செய்யுங்கள்; உங்களை நிந்திக்கிறவர்களுக்காகவும் உங்களைத் துன்பப்படுத்துகிறவர்களுக்காகவும் ஜெபம்பண்ணுங்கள்.
	<br /><br />
	45. இப்படிச் செய்வதினால் நீங்கள் பரலோகத்திலிருக்கிற உங்கள் பிதாவுக்குப் புத்திரராயிருப்பீர்கள்; அவர் தீயோர்மேலும் நல்லோர்மேலும் தமது சூரியனை உதிக்கப்பண்ணி, நீதியுள்ளவர்கள்மேலும் அநீதியுள்ளவர்கள்மேலும் மழையைப் பெய்யப்பண்ணுகிறார்.
	<br /><br />
	46. உங்களைச் சிநேகிக்கிறவர்களையே நீங்கள் சிநேகிப்பீர்களானால், உங்களுக்குப் பலன் என்ன? ஆயக்காரரும் அப்படியே செய்கிறார்கள் அல்லவா?
	<br /><br />
	47. உங்கள் சகோதரரைமாத்திரம் வாழ்த்துவீர்களானால், நீங்கள் விசேஷித்துச் செய்கிறது என்ன? ஆயக்காரரும் அப்படிச் செய்கிறார்கள் அல்லவா?
	<br /><br />
	48. ஆகையால், பரலோகத்திலிருக்கிற உங்கள் பிதா பூரண சற்குணராயிருக்கிறதுபோல, நீங்களும் பூரண சற்குணராயிருக்கக்கடவீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew5