import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 8 </title>
	<meta
          name="description"
          content="Matthew 8 | மத்தேயு 8 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் மலையிலிருந்து இறங்கினபோது, திரளான ஜனங்கள் அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	2. அப்பொழுது குஷ்டரோகி ஒருவன் வந்து அவரைப் பணிந்து: ஆண்டவரே! உமக்குச் சித்தமானால், என்னைச் சுத்தமாக்க உம்மால் ஆகும் என்றான்.
	<br /><br />
	3. இயேசு தமது கையை நீட்டி அவனைத் தொட்டு: எனக்குச் சித்தமுண்டு, சுத்தமாகு என்றார். உடனே குஷ்டரோகம் நீங்கி அவன் சுத்தமானான்.
	<br /><br />
	4. இயேசு அவனை நோக்கி: இதை நீ ஒருவருக்கும் சொல்லாதபடி எச்சரிக்கையாயிரு; ஆயினும், அவர்களுக்குச் சாட்சியாக நீ போய் ஆசாரியனுக்கு உன்னைக் காண்பித்து, மோசே கட்டளையிட்ட காணிக்கையைச் செலுத்து என்றார்.
	<br /><br />
	5. இயேசு கப்பர்நகூமில் பிரவேசித்தபோது, நூற்றுக்கு அதிபதி ஒருவன் அவரிடத்தில் வந்து:
	<br /><br />
	6. ஆண்டவரே! என் வேலைக்காரன் வீட்டிலே திமிர்வாதமாய்க் கிடந்து கொடிய வேதனைப்படுகிறான் என்று அவரை வேண்டிக்கொண்டான்.
	<br /><br />
	7. அதற்கு இயேசு: நான் வந்து அவனைச் சொஸ்தமாக்குவேன் என்றார்.
	<br /><br />
	8. நூற்றுக்கு அதிபதி பிரதியுத்தரமாக: ஆண்டவரே! நீர் என் வீட்டுக்குள் பிரவேசிக்க நான் பாத்திரன் அல்ல; ஒரு வார்த்தைமாத்திரம் சொல்லும், அப்பொழுது என் வேலைக்காரன் சொஸ்தமாவான்.
	<br /><br />
	9. நான் அதிகாரத்துக்குக் கீழ்ப்பட்டவனாயிருந்தும், எனக்குக் கீழ்ப்பட்டிருக்கிற சேவகருமுண்டு; நான் ஒருவனைப் போவென்றால் போகிறான், மற்றொருவனை வாவென்றால் வருகிறான், என் வேலைக்காரனை, இதைச் செய்யென்றால் செய்கிறான் என்றான்.
	<br /><br />
	10. இயேசு இதைக் கேட்டு ஆச்சரியப்பட்டு, தமக்குப் பின்செல்லுகிறவர்களை நோக்கி: இஸ்ரவேலருக்குள்ளும் நான் இப்படிப்பட்ட விசுவாசத்தைக் காணவில்லை என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	11. அநேகர் கிழக்கிலும் மேற்கிலுமிருந்து வந்து, பரலோகராஜ்யத்தில் ஆபிரகாம் ஈசாக்கு யாக்கோபு என்பவர்களோடே பந்தியிருப்பார்கள்.
	<br /><br />
	12. ராஜ்யத்தின் புத்திரரோ புறம்பான இருளிலே தள்ளப்படுவார்கள்; அங்கே அழுகையும் பற்கடிப்பும் உண்டாயிருக்குமென்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	13. பின்பு இயேசு நூற்றுக்கு அதிபதியை நோக்கி: நீ போகலாம், நீ விசுவாசித்தபடியே உனக்கு ஆகக்கடவது என்றார். அந்த நாழிகையிலே அவன் வேலைக்காரன் சொஸ்தமானான்.
	<br /><br />
	14. இயேசு பேதுருவின் வீட்டிலே வந்து, அவன் மாமி ஜுரமாய்க் கிடக்கிறதைக் கண்டார்.
	<br /><br />
	15. அவர் அவள் கையைத் தொட்டவுடனே ஜுரம் அவளைவிட்டு நீங்கிற்று; அவள் எழுந்திருந்து, அவர்களுக்குப் பணிவிடை செய்தாள்.
	<br /><br />
	16. அஸ்தமனமானபோது, பிசாசு பிடித்திருந்த அநேகரை அவரிடத்தில் கொண்டுவந்தார்கள்; அவர் அந்த ஆவிகளைத் தமது வார்த்தையினாலே துரத்தி, பிணியாளிகளெல்லாரையும் சொஸ்தமாக்கினார்.
	<br /><br />
	17. அவர் தாமே நம்முடைய பெலவீனங்களை ஏற்றுக்கொண்டு, நம்முடைய நோய்களைச் சுமந்தார் என்று, ஏசாயா தீர்க்கதரிசியினால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது.
	<br /><br />
	18. பின்பு, திரளான ஜனங்கள் தம்மைச் சூழ்ந்திருக்கிறதை இயேசு கண்டு, அக்கரைக்குப் போகக் கட்டளையிட்டார்.
	<br /><br />
	19. அப்பொழுது, வேதபாரகன் ஒருவன் வந்து: போதகரே! நீர் எங்கே போனாலும் உம்மைப் பின்பற்றி வருவேன் என்றான்.
	<br /><br />
	20. அதற்கு இயேசு: நரிகளுக்குக் குழிகளும் ஆகாயத்துப் பறவைகளுக்குக் கூடுகளும் உண்டு; மனுஷகுமாரனுக்கோ தலைசாய்க்க இடமில்லை என்றார்.
	<br /><br />
	21. அவருடைய சீஷர்களில் வேறொருவன் அவரை நோக்கி: ஆண்டவரே! முன்பு நான் போய், என் தகப்பனை அடக்கம்பண்ண எனக்கு உத்தரவு கொடுக்கவேண்டும் என்றான்.
	<br /><br />
	22. அதற்கு இயேசு: மரித்தோர் தங்கள் மரித்தோரை அடக்கம்பண்ணட்டும், நீ என்னைப் பின்பற்றி வா என்றார்.
	<br /><br />
	23. அவர் படவில் ஏறினபோது அவருடைய சீஷர்கள் அவருக்குப் பின்சென்று ஏறினார்கள்.
	<br /><br />
	24. அப்பொழுது படவு அலைகளினால் மூடப்படத்தக்கதாய்க் கடலில் பெருங்காற்று உண்டாயிற்று. அவரோ நித்திரையாயிருந்தார்.
	<br /><br />
	25. அப்பொழுது, அவருடைய சீஷர்கள் வந்து, அவரை எழுப்பி: ஆண்டவரே! எங்களை இரட்சியும், மடிந்துபோகிறோம் என்றார்கள்.
	<br /><br />
	26. அதற்கு அவர்: அற்பவிசுவாசிகளே! ஏன் பயப்படுகிறீர்கள் என்று சொல்லி; எழுந்து, காற்றையும் கடலையும் அதட்டினார். உடனே, மிகுந்த அமைதல் உண்டாயிற்று.
	<br /><br />
	27. அந்த மனுஷர்கள் ஆச்சரியப்பட்டு: இவர் எப்படிப்பட்டவரோ, காற்றும் கடலும் இவருக்குக் கீழ்ப்படிகிறதே என்றார்கள்.
	<br /><br />
	28. அவர் அக்கரையிலே கெர்கெசேனர் நாட்டில் வந்தபோது, பிசாசு பிடித்திருந்த இரண்டுபேர் பிரேதக்கல்லறைகளிலிருந்து புறப்பட்டு, அவருக்கு எதிராக வந்தார்கள்; அவர்கள் மிகவும் கொடியராயிருந்தபடியால், அந்த வழியாக ஒருவனும் நடக்கக்கூடாதிருந்தது.
	<br /><br />
	29. அவர்கள் அவரை நோக்கி: இயேசுவே, தேவனுடைய குமாரனே, எங்களுக்கும் உமக்கும் என்ன? காலம் வருமுன்னே எங்களை வேதனைப்படுத்த இங்கே வந்தீரோ என்று கூப்பிட்டார்கள்.
	<br /><br />
	30. அவர்களுக்குக் கொஞ்சதூரத்தில் அநேகம் பன்றிகள் கூட்டமாக மேய்ந்துகொண்டிருந்தன.
	<br /><br />
	31. அப்பொழுது, பிசாசுகள்: நீர் எங்களைத் துரத்துவீரானால், நாங்கள் அந்தப் பன்றிக்கூட்டத்தில் போகும்படி உத்தரவு கொடும் என்று அவரை வேண்டிக்கொண்டன.
	<br /><br />
	32. அதற்கு அவர்: போங்கள் என்றார். அவைகள் புறப்பட்டு, பன்றிக்கூட்டத்தில் போயின; அப்பொழுது, பன்றிக்கூட்டமெல்லாம் உயர்ந்த மேட்டிலிருந்து கடலிலே பாய்ந்து, ஜலத்தில் மாண்டுபோயின.
	<br /><br />
	33. அவைகளை மேய்த்தவர்கள் ஓடி, பட்டணத்தில் சென்று, இந்தச் சங்கதிகள் எல்லாவற்றையும், பிசாசு பிடித்திருந்தவர்களுக்குச் சம்பவித்தவைகளையும் அறிவித்தார்கள்.
	<br /><br />
	34. அப்பொழுது, அந்தப் பட்டணத்தார் யாவரும் இயேசுவுக்கு எதிர்கொண்டுவந்து, அவரைக் கண்டு, தங்கள் எல்லைகளைவிட்டுப் போகும்படி அவரை வேண்டிக்கொண்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew8