import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial27 = () => {
  const verseNumber = 27;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 27 </title>
	<meta
          name="description"
          content="Ezekiel 27 | எசேக்கியேல்  27 |
          Ezekiel 27 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனாகிய நீ இப்போது தீருவின்பேரிலே புலம்பி,
	<br /><br />
	3. சமுத்திரக் கரைதுறையிலே குடியிருந்து, அநேகம் தீவுகளின் ஜனங்களோடே வியாபாரம்பண்ணுகிற தீருவை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், தீருவே, நீ உன்னைப் பூரணசௌந்தரியவதி என்கிறாய்.
	<br /><br />
	4. கடல்களின் நடுமையத்திலே உன் தாபரம் இருக்கிறது; உன்னைக் கட்டினவர்கள் உன்னைப் பூரணவடிவாய்க் கட்டினார்கள்.
	<br /><br />
	5. சேனீரிலிருந்து வந்த தேவதாருமரத்தால் உன் கப்பற் பலகைகளைச் செய்தார்கள்; பாய்மரங்களைச் செய்யும்படிக்கு லீபனோனிலிருந்து கேதுருமரங்களைக் கொண்டுவந்தார்கள்.
	<br /><br />
	6. பாசானின் கர்வாலிமரங்களினாலே உன் துடுப்புகளைச் செய்தார்கள்; கித்தீம் தீவுகளிலிருந்து வந்த ஆஷூர் மரத்தால் உன் வாரிபலகைகளைச் செய்து, அதிலே யானைத்தந்தம் அழுத்தியிருந்தார்கள்.
	<br /><br />
	7. எகிப்திலிருந்து வந்த சித்திரத்தையலுள்ள சணல்நூல் புடவை நீ விரித்த பாயாயிருந்தது; எலீசா தீவுகளின் இளநீலமும் இரத்தாம்பரமும் உன் விதானமாயிருந்தது.
	<br /><br />
	8. சீதோன் அர்வாத் என்னும் பட்டணங்களின் குடிகள் உனக்குத் தண்டுவலிக்கிறவர்களாயிருந்தார்கள். தீருவே, உன்னிடத்திலிருந்த உன் சாஸ்திரிகள் உன் மாலுமிகளாயிருந்தார்கள்.
	<br /><br />
	9. கேபாரின் முதியோரும் அதின் சாஸ்திரிகளும் உன்னில் கம்பத்துப்பார்க்கிறவர்களாயிருந்தார்கள்; சமுத்திரத்தின் சகல கப்பல்களும் அவைகளிலுள்ள கப்பற்காரரும் உன்னோடே தொழில்துறை வியாபாரம் பண்ணுகிறதற்காக உன்னிடத்தில் இருந்தார்கள்.
	<br /><br />
	10. பெர்சியரும், லூதியரும், பூத்தியரும் உன் இராணுவத்தில் யுத்தவீரராயிருந்து உனக்குள் கேடகமும் தலைச்சீராவும் தூக்கிவைத்து, உன்னை அலங்கரித்தார்கள்.
	<br /><br />
	11. அர்வாத் புத்திரரும் உன் இராணுவ மனுஷரும் உன் மதில்கள்மேல் சுற்றிலும், கம்மாத்தியர் உன் கொத்தளங்களிலும் இருந்தார்கள்; இவர்கள் உன் மதில்கள்மேல் சுற்றிலும் தங்கள் பரிசைகளைத் தூக்கிவைத்து, உன் வடிவத்தைப் பூரணப்படுத்தினார்கள்.
	<br /><br />
	12. சகலவித பொருள்களின் திரளினாலும் தர்ஷீஸ் ஊரார் உன்னோடே வியாபாரம் பண்ணினார்கள்; வெள்ளியையும் இரும்பையும் தகரத்தையும் ஈயத்தையும் உன் சந்தைகளில் விற்கவந்தார்கள்.
	<br /><br />
	13. யாவான், தூபால், மேசேக் என்னும் ஜாதியார் உன் வியாபாரிகளாயிருந்து, மனுஷர்களையும் வெண்கலப்பாத்திரங்களையும் உன் தொழில்துறைக்குக் கொண்டுவந்தார்கள்.
	<br /><br />
	14. தொகர்மா வம்சத்தார் குதிரைகளையும் குதிரைவீரரையும் கோவேறு கழுதைகளையும் உன் சந்தைகளுக்குக் கொண்டுவந்தார்கள்.
	<br /><br />
	15. தேதான் புத்திரர் உன் வியாபாரிகளாயிருந்தார்கள்; அநேகம் தீவுகளின் வர்த்தகம் உன் வசமாகச் சேர்ந்தது; யானைத்தந்தங்களையும் கருங்காலி மரங்களையும் அவைகளுக்குப் பதிலாகக் கொண்டுவந்தார்கள்.
	<br /><br />
	16. சீரியர் உன் வேலைப்பாடான பற்பல பொருள்களினிமித்தம் உன்னோடே வியாபாரம்பண்ணி, மரகதங்களையும், இரத்தாம்பரங்களையும், சித்திரத்தையலாடைகளையும், உயர்ந்த வஸ்திரங்களையும், பவளங்களையும், பளிங்கையும் உன் சந்தைகளில் விற்கவந்தார்கள்.
	<br /><br />
	17. யூதரும் இஸ்ரவேல் தேசத்தாரும் உன்னோடே வியாபாரம்பண்ணி, மின்னீத், பன்னாக் என்கிற ஊர்களின் கோதுமையையும், தேனையும், எண்ணெயையும், பிசின்தைலத்தையும் உன் தொழில்துறைக்குக் கொண்டுவந்தார்கள்.
	<br /><br />
	18. தமஸ்கு உன் வேலைப்பாடான பற்பல பொருள்களினிமித்தமும், சகலவிதப் பொருள்களின் திரட்சியினிமித்தமும் உன்னோடே வர்த்தகம்பண்ணி, கெல்போனின் திராட்சரசத்தையும் வெண்மையான ஆட்டுமயிரையும் உனக்கு விற்றார்கள்.
	<br /><br />
	19. தாண் நாட்டாரும், போக்கும் வரத்துமான யாவானரும் துலக்கப்பட்ட இரும்பையும் இலவங்கத்தையும் வசம்பையும் உன் சந்தைகளில் கொண்டுவந்து உன் தொழில்துறையில் விற்றார்கள்.
	<br /><br />
	20. இரதங்களுக்குப் போடுகிற மேன்மையான இரத்தினக்கம்பளங்களைத் தேதானின் மனுஷர் கொண்டுவந்து, உன்னோடே வியாபாரம்பண்ணினார்கள்.
	<br /><br />
	21. அரபியரும், கேதாரின் சகல பிரபுக்களும் உனக்கு வாடிக்கையான வர்த்தகராகி, ஆட்டுக்குட்டிகளையும் ஆட்டுக்கடாக்களையும் வெள்ளாட்டுக்கடாக்களையும் கொண்டுவந்து, உன்னோடே வியாபாரம்பண்ணினார்கள்.
	<br /><br />
	22. சேபா, ராமா பட்டணங்களின் வியாபாரிகள் உன்னோடே வர்த்தகம்பண்ணி, மேல்தரமான சகலவித சம்பாரங்களையும், சகலவித இரத்தினக்கற்களையும், பொன்னையும் உன் சந்தைகளில் கொண்டுவந்தார்கள்.
	<br /><br />
	23. ஆரான், கன்னே, ஏதேன் என்னும் பட்டணத்தாரும், சேபாவின் வியாபாரிகளும், அசீரியரும், கில்மாத் பட்டணத்தாரும் உன்னோடே வியாபாரம்பண்ணினார்கள்.
	<br /><br />
	24. இவர்கள் சகலவித உயர்ந்த சரக்குகளையும், இளநீலப் பட்டுகளும் விசித்திரத்தையலாடைகளும் அடங்கிய புடவைக்கட்டுகளையும், விலை உயர்ந்த வஸ்திரங்கள் வைக்கப்பட்டு, கயிறுகளால் கட்டியிருக்கும் கேதுருமரப்பெட்டிகளையும் கொண்டுவந்து, உன்னோடே வியாபாரம்பண்ணினார்கள்.
	<br /><br />
	25. உன் தொழில்துறையில் தர்ஷீசின் கப்பலாட்கள் உன்னைப் போற்றிப் பாடினார்கள்; நீ சமுத்திரத்தின் நடுவிலே உன்னைப் பூரணப்படுத்தி, உன்னை மிகவும் மகிமைப்படுத்தினாய்.
	<br /><br />
	26. தண்டுவலிக்கிறவர்கள் ஆழமான தண்ணீர்களில் உன்னை வலித்துக்கொண்டுபோனார்கள்; நடுச்சமுத்திரத்திலே கொண்டற்காற்று உன்னை உடைத்துப்போட்டது.
	<br /><br />
	27. நீ நாசமடையும் நாளிலே உன் ஆஸ்தியோடும், உன் சந்தைகளோடும், உன் தொழில்துறையோடுங்கூட உன் கப்பலாட்களும், உன் மாலுமிகளும், உன்னில் கம்பத்துப்பார்க்கிறவர்களும், உன் வியாபாரிகளும், உன்னிலுள்ள எல்லா யுத்தவீரரும், உன் நடுவில் இருக்கிற எல்லாக் கூட்டத்தாரும் நடுச்சமுத்திரத்திலே விழுவார்கள்.
	<br /><br />
	28. உன் மாலுமிகள் ஓலமிடும் சத்தத்தினால் சுற்றுப்புறங்கள் அதிரும்.
	<br /><br />
	29. தண்டுவலிக்கிற யாவரும், கப்பலாட்களும், கடல் மாலுமிகள் அனைவரும், தங்கள் கப்பல்களை விட்டுஇறங்கி, கரையிலே நின்று,
	<br /><br />
	30. உன்னிமித்தம் சத்தமிட்டுப்புலம்பி, மனங்கசந்து அழுது, தங்கள் தலைகளின்மேல் புழுதியைப் போட்டுக்கொண்டு, சாம்பலில் புரண்டு,
	<br /><br />
	31. உனக்காக மொட்டையிட்டு இரட்டுடுத்திக்கொண்டு, உனக்காக மனங்கசந்து அழுது, துக்கங்கொண்டாடுவார்கள்.
	<br /><br />
	32. அவர்கள் உனக்காகத் தங்கள் துக்கத்திலே ஓலமிட்டு, உன்னிமித்தம் புலம்பி, உன்னைக்குறித்து: சமுத்திரத்தின் நடுவிலே அழிந்துபோன தீருவுக்குச் சமான நகரம் உண்டோ?
	<br /><br />
	33. உன் சந்தைச்சரக்குகள் சமுத்திரங்கள் வழியாய்க் கொண்டுவரப்படுகையில், அநேக ஜனங்களைத் திருப்தியாக்கினாய்; உன் ஆஸ்தியின் திரளினாலும் உன் வியாபாரத்தினாலும் பூமியின் ராஜாக்களை ஐசுவரியவான்களாக்கினாய்.
	<br /><br />
	34. நீ சமுத்திரத் திரைகளினாலே ஆழங்களில் உடைக்கப்பட்டபோது, உன் தொழில் துறையும் உன் நடுவிலுள்ள கூட்டம் அனைத்தும் அழிந்துபோயின என்பார்கள்.
	<br /><br />
	35. தீவுகளின் குடிகள் எல்லாம் உன்னிமித்தம் திகைப்பார்கள்; அவர்களுடைய ராஜாக்கள் மிகவும் பிரமித்து, கலங்கின முகமாயிருப்பார்கள்.
	<br /><br />
	36. சகல ஜனங்களிலுமுள்ள வர்த்தகர் உன்பேரில் ஈசல்போடுவார்கள்; நீ பயங்கரமாவாய்; இனி ஒருபோதும் இருக்கமாட்டாய் என்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial27