import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 1 </title>
	<meta
          name="description"
          content="Ezekiel 1 | எசேக்கியேல்  1 |
          Ezekiel 1 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. முப்பதாம் வருஷம் நாலாம் மாசம் ஐந்தாந்தேதியிலே, நான் கேபார் நதியண்டையிலே சிறைப்பட்டவர்கள் நடுவில் இருக்கும்போது, சம்பவித்தது என்னவென்றால், வானங்கள் திறக்கப்பட, நான் தேவதரிசனங்களைக் கண்டேன்.
	<br /><br />
	2. அது யோயாக்கீன் ராஜாவுடைய சிறையிருப்பின் ஐந்தாம் வருஷமாயிருந்தது.
	<br /><br />
	3. அந்த ஐந்தாந்தேதியிலே, கல்தேயர் தேசத்திலுள்ள கேபார் நதியண்டையிலே பூசியென்னும் ஆசாரியனுடைய குமாரனாகிய எசேக்கியேலுக்குக் கர்த்தருடைய வார்த்தை உண்டாகி, அங்கே கர்த்தருடைய கரம் அவன்மேல் அமர்ந்தது.
	<br /><br />
	4. இதோ, வடக்கேயிருந்து புசல்காற்றும் பெரிய மேகமும், அத்தோடே கலந்த அக்கினியும் வரக்கண்டேன்; அதைச் சுற்றிலும் பிரகாசமும், அதின் நடுவில் அக்கினிக்குள்ளிருந்து விளங்கிய சொகுசாவின் நிறமும் உண்டாயிருந்தது.
	<br /><br />
	5. அதின் நடுவிலிருந்து நாலு ஜீவன்கள் தோன்றின; அவைகளின் சாயல் மனுஷசாயலாயிருந்தது.
	<br /><br />
	6. அவைகளில் ஒவ்வொன்றுக்கும் நந்நான்கு முகங்களும், ஒவ்வொன்றுக்கும் நந்நான்கு செட்டைகளும் இருந்தன.
	<br /><br />
	7. அவைகளுடைய கால்கள் நிமிர்ந்த கால்களாயிருந்தன; அவைகளுடைய உள்ளங்கால்கள் கன்றுக்குட்டியின் உள்ளங்கால்களுக்கு ஒப்பாயிருந்தன; அவைகள் துலக்கப்பட்ட வெண்கலத்தின் வருணமாய் மின்னிக்கொண்டிருந்தன.
	<br /><br />
	8. அவைகளுடைய செட்டைகளின்கீழ் அவைகளின் நாலு பக்கங்களிலும் மனுஷகைகள் இருந்தன; அந்த நாலுக்கும் அதினதின் முகங்களும் அதினதின் செட்டைகளும் உண்டாயிருந்தன.
	<br /><br />
	9. அவைகள் ஒவ்வொன்றின் செட்டைகளும் மற்றதின் செட்டைகளோடே சேர்ந்திருந்தன; அவைகள் செல்லுகையில் திரும்பாமல் ஒவ்வொன்றும் தன்தன் திசைக்கு நேர்முகமாய்ச் சென்றன.
	<br /><br />
	10. அவைகளுடைய முகங்களின் சாயலாவது, வலதுபக்கத்தில் நாலும் மனுஷமுகமும் சிங்கமுகமும், இடதுபக்கத்தில் நாலும் எருது முகமும் கழுகு முகமுமாயிருந்தன.
	<br /><br />
	11. அவைகளுடைய முகங்கள் இப்படியிருக்க, அவைகளுடைய செட்டைகள் மேலே பிரிந்திருந்தன, ஒவ்வொன்றுக்குமுள்ள இரண்டிரண்டு செட்டைகள் ஒன்றோடொன்று சேர்ந்திருந்தன; மற்ற இரண்டிரண்டு செட்டைகள் அவைகளுடைய உடல்களை மூடின.
	<br /><br />
	12. அவைகள் ஒவ்வொன்றும் தன்தன் திசைக்கு நேர்முகமாய்ச் சென்றது; ஆவி போகவேண்டுமென்றிருந்த எவ்விடத்துக்கும் அவைகள் போயின; போகையில் அவைகள் திரும்பிப்பார்க்கவில்லை.
	<br /><br />
	13. ஜீவன்களுடைய சாயல் எப்படியிருந்ததென்றால், அவைகள் எரிகிற அக்கினித்தழலின் தோற்றமும் தீவர்த்திகளின் தோற்றமுமாயிருந்தது; அந்த அக்கினி ஜீவன்களுக்குள்ளே உலாவிப் பிரகாசமாயிருந்தது; அக்கினியிலிருந்து மின்னல் புறப்பட்டது.
	<br /><br />
	14. அந்த ஜீவன்கள் மின்னலின் தோற்றம்போல ஓடித்திரிந்தன.
	<br /><br />
	15. நான் அந்த ஜீவன்களைப் பார்த்துக்கொண்டிருக்கையில், இதோ, பூமியில் ஜீவன்களண்டையிலே நாலு முகங்களையுடைய ஒரு சக்கரத்தைக் கண்டேன்.
	<br /><br />
	16. சக்கரங்களின் ரூபமும் அவைகளின் வேலையும் படிகப்பச்சை வருணமாயிருந்தது; அவைகள் நாலுக்கும் ஒரேவித சாயல் இருந்தது; அவைகளின் ரூபமும் அவைகளின் வேலையும் சக்கரத்துக்குள் சக்கரம் இருக்குமாபோல் இருந்தது.
	<br /><br />
	17. அவைகள் ஓடுகையில் தங்கள் நாலு பக்கங்களிலும் ஓடும், ஓடுகையில் அவைகள் திரும்புகிறதில்லை.
	<br /><br />
	18. அவைகளின் வட்டங்கள் பயங்கரப்படத்தக்க உயரமாயிருந்தன; அந்த நாலு வட்டங்களும் சுற்றிலும் கண்களால் நிறைந்திருந்தன.
	<br /><br />
	19. அந்த ஜீவன்கள் செல்லும்போது, அந்தச் சக்கரங்கள் அவைகள் அருகே ஓடின; அந்த ஜீவன்கள் பூமியிலிருந்து எழும்பும்போது சக்கரங்களும் எழும்பின.
	<br /><br />
	20. ஆவி போகவேண்டுமென்றிருந்த எவ்விடத்துக்கும் அவைகள் போயின; அவ்விடத்துக்கு அவைகளின் ஆவியும் போகவேண்டுமென்றிருந்தது; சக்கரங்களும் அவைகளின் அருகே எழும்பின; ஜீவனுடைய ஆவி சக்கரங்களில் இருந்தது.
	<br /><br />
	21. அவைகள் செல்லும்போது இவைகளும் சென்றன; அவைகள் நிற்கும்போது இவைகளும் நின்றன; அவைகள் பூமியிலிருந்து எழும்பும்போது, சக்கரங்களும் அவைகள் அருகே எழும்பின; ஜீவனுடைய ஆவி சக்கரங்களில் இருந்தது.
	<br /><br />
	22. ஜீவனுடைய தலைகளின்மேல் பிரமிக்கத்தக்க வச்சிரப்பிரகாசமான ஒரு மண்டலம் இருந்தது; அது அவைகளுடைய தலைகளின்மேல் உயர விரிந்திருந்தது.
	<br /><br />
	23. மண்டலத்தின்கீழ் அவைகளுடைய செட்டைகள் ஒன்றுக்கொன்று எதிர்நேராய் விரிந்திருந்தன; தங்கள்தங்கள் உடல்களை மூடிக்கொள்ளுகிற இரண்டிரண்டு செட்டைகள் இருபக்கத்திலும் இருக்கிற ஒவ்வொன்றுக்கும் இருந்தன.
	<br /><br />
	24. அவைகள் செல்லும்போது அவைகளுடைய செட்டைகளின் இரைச்சலைக் கேட்டேன்; அது பெருவெள்ளத்தின் இரைச்சல்போலவும், சர்வவல்லவருடைய சத்தம்போலவும், ஒரு இராணுவத்தின் இரைச்சலுக்கு ஒத்த ஆரவாரத்தின் சத்தம்போலவுமிருந்தது; அவைகள் நிற்கும்போது தங்கள் செட்டைகளைத் தளரவிட்டிருந்தன.
	<br /><br />
	25. அவைகள் நின்று தங்கள் செட்டைகளைத் தளரவிட்டிருக்கையில், அவைகளுடைய தலைகளுக்கு மேலான மண்டலத்தின்மேலிருந்து ஒரு சத்தம் பிறந்தது.
	<br /><br />
	26. அவைகளின் தலைகளுக்குமேலுள்ள மண்டலத்தின்மீதில் நீலரத்தினம்போல விளங்கும் ஒரு சிங்காசனத்தின் சாயலும், அந்தச் சிங்காசனத்தின் சாயலின்மேல் மனுஷசாயலுக்கு ஒத்த ஒரு சாயலும் இருந்தது.
	<br /><br />
	27. அவருடைய இடுப்பாகக் காணப்பட்டதுமுதல் மேலெல்லாம் உட்புறம் சுற்றிலும் அக்கினிமயமான சொகுசாவின் நிறமாயிருக்கக்கண்டேன்; அவருடைய இடுப்பாகக் காணப்பட்டதுமுதல் கீழெல்லாம் அக்கினிமயமாகவும், அதைச் சுற்றிலும் பிரகாசமாகவும் இருக்கக்கண்டேன்.
	<br /><br />
	28. மழைபெய்யும் நாளில் மேகத்தில் வானவில் எப்படிக் காணப்படுகிறதோ, அப்படியே சுற்றிலுமுள்ள அந்தப் பிரகாசம் காணப்பட்டது; இதுவே கர்த்தருடைய மகிமையின் சாயலுக்குரிய தரிசனமாயிருந்தது; அதை நான் கண்டபோது முகங்குப்புற விழுந்தேன்; அப்பொழுது பேசுகிற ஒருவருடைய சத்தத்தைக் கேட்டேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial1