import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 3 </title>
	<meta
          name="description"
          content="Acts 3 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 3 |
          Acts of Apostles Chapter-3 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஜெபவேளையாகிய ஒன்பதாம்மணி நேரத்திலே பேதுருவும் யோவானும் தேவாலயத்துக்குப் போனார்கள்.
	<br /><br />
	2. அப்பொழுது தன் தாயின் வயிற்றிலிருந்து சப்பாணியாய்ப் பிறந்த ஒரு மனுஷனைச் சுமந்துகொண்டுவந்தார்கள்; தேவாலயத்திலே பிரவேசிக்கிறவர்களிடத்தில் பிச்சைகேட்கும்படி, நாடோறும் அவனை அலங்கார வாசல் என்னப்பட்ட தேவாலய வாசலண்டையிலே வைப்பார்கள்.
	<br /><br />
	3. தேவாலயத்திலே பிரவேசிக்கப்போகிற பேதுருவையும் யோவானையும் அவன் கண்டு பிச்சைகேட்டான்.
	<br /><br />
	4. பேதுருவும் யோவானும் அவனை உற்றுப்பார்த்து: எங்களை நோக்கிப்பார் என்றார்கள்.
	<br /><br />
	5. அவன் அவர்களிடத்தில் ஏதாகிலும் கிடைக்குமென்று எண்ணி, அவர்களை நோக்கிப்பார்த்தான்.
	<br /><br />
	6. அப்பொழுது பேதுரு: வெள்ளியும் பொன்னும் என்னிடத்திலில்லை; என்னிடத்திலுள்ளதை உனக்குத் தருகிறேன்; நசரேயனாகிய இயேசுகிறிஸ்துவின் நாமத்தினாலே நீ எழுந்து நட என்று சொல்லி;
	<br /><br />
	7. வலதுகையினால் அவனைப் பிடித்துத் தூக்கிவிட்டான்; உடனே அவனுடைய கால்களும் கரடுகளும் பெலன்கொண்டது.
	<br /><br />
	8. அவன் குதித்தெழுந்து நின்று நடந்தான்; நடந்து, குதித்து, தேவனைத் துதித்துக்கொண்டு, அவர்களுடனேகூடத் தேவாலயத்திற்குள் பிரவேசித்தான்.
	<br /><br />
	9. அவன் நடக்கிறதையும், தேவனைத் துதிக்கிறதையும், ஜனங்களெல்லாரும் கண்டு:
	<br /><br />
	10. தேவாலயத்தின் அலங்கார வாசலண்டையிலே பிச்சைகேட்க உட்கார்ந்திருந்தவன் இவன்தான் என்று அறிந்து, அவனுக்குச் சம்பவித்ததைக்குறித்து மிகவும் ஆச்சரியப்பட்டுப் பிரமித்தார்கள்.
	<br /><br />
	11. குணமாக்கப்பட்ட சப்பாணி பேதுருவையும் யோவானையும் பற்றிக்கொண்டிருக்கையில், ஜனங்களெல்லாரும் பிரமித்து, சாலொமோன் மண்டபம் என்னப்பட்ட மண்டபத்திலே அவர்களிடத்திற்கு ஓடிவந்தார்கள்.
	<br /><br />
	12. பேதுரு அதைக் கண்டு ஜனங்களை நோக்கி: இஸ்ரவேலரே, இதைக்குறித்து நீங்கள் ஆச்சரியப்படுகிறதென்ன? நாங்கள் எங்கள் சுயசக்தியினாலாவது, எங்கள் சுயபக்தியினாலாவது, இவனை நடக்கப்பண்ணினோமென்று நீங்கள் எங்களை நோக்கிப் பார்க்கிறதென்ன?
	<br /><br />
	13. ஆபிரகாம் ஈசாக்கு யாக்கோபு என்பவர்களுடைய தேவனாகிய நம்முடைய பிதாக்களின் தேவன் தம்முடைய பிள்ளையாகிய இயேசுவை மகிமைப்படுத்தினார்; அவரை நீங்கள் ஒப்புக்கொடுத்தீர்கள்; பிலாத்து அவரை விடுதலையாக்கத் தீர்மானித்தபோது, அவனுக்குமுன்பாக அவரை மறுதலித்தீர்கள்.
	<br /><br />
	14. பரிசுத்தமும் நீதியுமுள்ளவரை நீங்கள் மறுதலித்து, கொலைபாதகனை உங்களுக்காக விடுதலைபண்ணவேண்டுமென்று கேட்டு,
	<br /><br />
	15. ஜீவாதிபதியைக் கொலைசெய்தீர்கள்; அவரைத் தேவன் மரித்தோரிலிருந்தெழுப்பினார்; அதற்கு நாங்கள் சாட்சிகளாயிருக்கிறோம்.
	<br /><br />
	16. அவருடைய நாமத்தைப்பற்றும் விசுவாசத்தினாலே அவருடைய நாமமே நீங்கள் பார்த்து அறிந்திருக்கிற இவனைப் பெலப்படுத்தினது; அவரால் உண்டாகிய விசுவாசமே உங்களெல்லாருக்கும் முன்பாக, இந்தச் சர்வாங்க சுகத்தை இவனுக்குக் கொடுத்தது.
	<br /><br />
	17. சகோதரரே, நீங்களும் உங்கள் அதிகாரிகளும் அறியாமையினாலே இதைச்செய்தீர்களென்று அறிந்திருக்கிறேன்.
	<br /><br />
	18. கிறிஸ்து பாடுபடவேண்டுமென்று தேவன் தம்முடைய தீர்க்கதரிசிகளெல்லாருடைய வாக்கினாலும் முன்னறிவித்தவைகளை இவ்விதமாய் நிறைவேற்றினார்.
	<br /><br />
	19. ஆனபடியினாலே கர்த்தருடைய சந்நிதானத்திலிருந்து இளைப்பாறுதலின் காலங்கள் வரும்படிக்கும், முன்னே குறிக்கப்பட்ட இயேசுகிறிஸ்துவை அவர் உங்களிடத்தில் அனுப்பும்படிக்கும்,
	<br /><br />
	20. உங்கள் பாவங்கள் நிவிர்த்திசெய்யப்படும்பொருட்டு நீங்கள் மனந்திரும்பிக் குணப்படுங்கள்.
	<br /><br />
	21. உலகத்தோற்றமுதல் தேவன் தம்முடைய பரிசுத்த தீர்க்கதரிசிகளெல்லாருடைய வாக்கினாலும் உரைத்தவைகள் எல்லாம் நிறைவேறித் தீருங்காலங்கள் வருமளவும் பரலோகம் அவரை ஏற்றுக்கொள்ளவேண்டும்.
	<br /><br />
	22. மோசே பிதாக்களை நோக்கி: உங்கள் தேவனாகிய கர்த்தர் என்னைப்போல ஒரு தீர்க்கதரிசியை உங்களுக்காக உங்கள் சகோதரரிலிருந்து எழும்பப்பண்ணுவார்; அவர் உங்களுக்குச்சொல்லும் எல்லாவற்றிலும் அவருக்குச் செவிகொடுப்பீர்களாக.
	<br /><br />
	23. அந்தத் தீர்க்கதரிசியின் சொற்கேளாதவனெவனோ, அவன் ஜனத்திலிராதபடிக்கு நிர்மூலமாக்கப்படுவான் என்றான்.
	<br /><br />
	24. சாமுவேல் முதற்கொண்டு, எத்தனைபேர் தீர்க்கதரிசனம் உரைத்தார்களோ, அத்தனைபேரும் இந்த நாட்களை முன்னறிவித்தார்கள்.
	<br /><br />
	25. நீங்கள் அந்தத் தீர்க்கதரிசிகளுக்குப் புத்திரராயிருக்கிறீர்கள்; உன் சந்ததியினாலே பூமியிலுள்ள வம்சங்களெல்லாம் ஆசீர்வதிக்கப்படும் என்று தேவன் ஆபிரகாமுக்குச் சொல்லி, நம்முடைய முன்னோர்களோடே பண்ணின உடன்படிக்கைக்கும் புத்திரராயிருக்கிறீர்கள்.
	<br /><br />
	26. அவர் உங்களெல்லாரையும் உங்கள் பொல்லாங்குகளிலிருந்து விலக்கி, உங்களை ஆசீர்வதிக்கும்படி தேவன் தம்முடைய பிள்ளையாகிய இயேசுவை எழுப்பி, முதலாவது உங்களிடத்திற்கே அவரை அனுப்பினார் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts3