import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah49 = () => {
  const verseNumber = 49;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 49 </title>
	<meta
          name="description"
          content="Jeremiah 49 | எரேமியா 49 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அம்மோன் புத்திரரைக்குறித்துக் கர்த்தர் சொல்லுகிறது என்னவென்றால்: இஸ்ரவேலுக்குக் குமாரர் இல்லையோ? அவனுக்குச் சுதந்தரவாளி இல்லையோ? அவர்கள் ராஜா காத்தேசத்தைச் சுதந்தரித்துக்கொண்டு, அதின் ஜனம் இவன் பட்டணங்களில் குடியிருப்பானேன்?
	<br /><br />
	2. ஆகையால், இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது அம்மோன் புத்திரரின் பட்டணமாகிய ரப்பாவிலே யுத்தத்தின் ஆர்ப்பரிப்பைக் கேட்கப்பண்ணுவேன்; அது பாழான மண்மேடாகும்; அதற்கடுத்த ஊர்களும் அக்கினியால் சுட்டெரிக்கப்படும்; ஆனாலும் இஸ்ரவேல் தன் தேசத்தைச் சுதந்தரித்துக் கொண்டவர்களின் தேசத்தைச் சுதந்தரித்துக்கொள்ளும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. எஸ்போனே, அலறு; ஆயி பாழாக்கப்பட்டது; ரப்பாவின் குமாரத்திகளே, ஓலமிடுங்கள்; இரட்டை உடுத்திக்கொண்டு, புலம்பி, வேலிகளில் சுற்றித்திரியுங்கள்; அவர்கள் ராஜா அதின் ஆசாரியர்களோடும் அதின் பிரபுக்களோடுங்கூடச் சிறைப்பட்டுப்போவான்.
	<br /><br />
	4. எனக்கு விரோதமாய் வருகிறவன் யார் என்று சொல்லி, உன் செல்வத்தை நம்பின சீர்கெட்ட குமாரத்தியே, நீ பள்ளத்தாக்குகளைப்பற்றிப் பெருமைபாராட்டுவானேன்? உன் பள்ளத்தாக்குக் கரைந்துபோகிறது.
	<br /><br />
	5. இதோ, உன் சுற்றுப்புறத்தார் எல்லாராலும் உன்மேல் திகிலை வரப்பண்ணுவேன் என்று சேனைகளின் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்; நீங்கள் அவரவர் தம்தம் முன் இருக்கும் வழியே ஓடத் துரத்தப்படுவீர்கள்; வலசைவாங்கி ஓடுகிறவர்களைச் சேர்ப்பார் ஒருவருமில்லை.
	<br /><br />
	6. அதற்குப்பின்பு அம்மோன் புத்திரருடைய சிறையிருப்பைத் திருப்புவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. ஏதோமைக்குறித்துச் சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: தேமானிலே இனி ஞானமில்லையோ? ஆலோசனை விவேகிகளைவிட்டு அழிந்ததோ? அவர்களுடைய ஞானம் கெட்டுப்போயிற்றோ?
	<br /><br />
	8. தேதானின் குடிகளே, ஓடுங்கள், முதுகைக் காட்டுங்கள், பள்ளங்களில் பதுங்குங்கள்; ஏசாவை விசாரிக்குங்காலத்தில் அவன் ஆபத்தை அவன்மேல் வரப்பண்ணுவேன்.
	<br /><br />
	9. திராட்சப்பழங்களை அறுக்கிறவர்கள் உன்னிடத்திலே வந்தார்களாகில், பின்பறிக்கிறதற்குக் கொஞ்சம் வையார்களோ? இராத்திரியில் திருடர் வந்தார்களாகில், தங்களுக்குப் போதுமென்கிறமட்டும் கொள்ளையடிப்பார்கள் அல்லவோ?
	<br /><br />
	10. நானோ ஏசாவை வெறுமையாக்கி, அவன் ஒளித்துக்கொள்ளக் கூடாதபடிக்கு அவனுடைய மறைவிடங்களை வெளிப்படுத்திப்போடுவேன்; அவனுடைய சந்ததியாரும் அவனுடைய சகோதரரும் அவனுடைய அயலாரும் அழிக்கப்படுவார்கள்; அவன் இனி இரான்.
	<br /><br />
	11. திக்கற்றவர்களாய்ப்போகும் உன் பிள்ளைகளை ஒப்புவி, நான் அவர்களை உயிரோடே காப்பாற்றுவேன்; உன் விதவைகள் என்னை நம்புவார்களாக.
	<br /><br />
	12. கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, பாத்திரத்தில் குடிக்கவேண்டுமென்கிற நியாயத்தீர்ப்புக்கு உள்ளாயிராதவர்கள் அதில் குடித்தார்கள்; நீ குற்றமற்று நீங்கலாயிருப்பாயோ? நீ நீங்கலாயிராமல் அதில் நிச்சயமாய்க் குடிப்பாய்.
	<br /><br />
	13. போஸ்றா பாழும் நிந்தையும் அவாந்தரமும் சாபமுமாக இருக்குமென்றும், அதின் பட்டணங்கள் எல்லாம் நித்திய வனாந்தரங்களாயிருக்குமென்றும் என்னைக்கொண்டு ஆணையிட்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. நீங்கள் கூடிக்கொண்டு, அதற்கு விரோதமாக வந்து, யுத்தம்பண்ணுகிறதற்கு எழும்புங்கள் என்று சொல்ல, ஜாதிகளிடத்தில் ஸ்தானாதிபதியை அனுப்புகிற செய்தியைக் கர்த்தரிடத்திலே கேள்விப்பட்டேன்.
	<br /><br />
	15. இதோ, உன்னை ஜாதிகளுக்குள்ளே சிறியதும், மனுஷருக்குள்ளே அசட்டைபண்ணப்பட்டதுமாக்குகிறேன் என்கிறார்.
	<br /><br />
	16. கன்மலை வெடிப்புகளில் வாசம்பண்ணி, மேடுகளின் உச்சியைப் பிடித்திருக்கிறவனே உன்னால் உன் பயங்கரமும் உன் இருதயத்தின் அகந்தையும் உன்னை மோசம் போக்கிற்று; நீ கழுகைப்போல் உயரத்தில் உன் கூட்டைக் கட்டினாலும் அங்கேயிருந்து உன்னை விழப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. அப்படியே ஏதோம் பாழாகும்; அதைக் கடந்துபோகிறவன் எவனும் அதின் எல்லா வாதைகளினிமித்தமும் பிரமித்து ஈசல்போடுவான்.
	<br /><br />
	18. சோதோமும் கொமோராவும் அவைகளின் சுற்றுப்புறங்களும் கவிழ்க்கப்பட்டதுபோல இதுவும் கவிழ்க்கப்படும் என்று கர்த்தர் சொல்லுகிறார்; அங்கே ஒருவனும் குடியிருப்பதில்லை, அதில் ஒரு மனுபுத்திரனும் தங்குவதில்லை.
	<br /><br />
	19. இதோ, புரண்டு ஓடுகிற யோர்தானிடத்திலிருந்து சிங்கம் வருவதுபோல் பலவானுடைய தாபரத்துக்கு விரோதமாக வருகிறான்; அவனைச் சடிதியிலே அங்கேயிருந்து ஓடிவரப்பண்ணுவேன்; நான் அதற்கு விரோதமாய்க் கட்டளையிட்டு அனுப்பத் தெரிந்துகொள்ளப்பட்டவன் யார்? எனக்குச் சமானமானவன் யார்? எனக்கு மட்டுக்கட்டுகிறவன் யார்? எனக்கு முன்பாக நிலைநிற்கப்போகிற மேய்ப்பன் யார்?
	<br /><br />
	20. ஆகையால் கர்த்தர் ஏதோமுக்கு விரோதமாக யோசித்த ஆலோசனையையும், அவர் தேமானின் குடிகளுக்கு விரோதமாக நினைத்திருக்கிற நினைவுகளையும் கேளுங்கள்; மந்தையில் சிறியவர்கள் மெய்யாகவே அவர்களைப் பிடித்திழுப்பார்கள், அவர்கள் இருக்கிற தாபரங்களை அவர் மெய்யாகவே பாழாக்குவார்.
	<br /><br />
	21. அவைகளுக்குள் இடிந்துவிழும் சத்தத்தினாலே பூமி அதிரும்; கூக்குரலின் சத்தம் சிவந்த சமுத்திரமட்டும் கேட்கப்படும்.
	<br /><br />
	22. இதோ, ஒருவன் கழுகைப்போல எழும்பி, பறந்துவந்து, தன் செட்டைகளைப் போஸ்றாவின்மேல் விரிப்பான்; அந்நாளிலே ஏதோமுடைய பராக்கிரமசாலிகளின் இருதயம் பிரசவவேதனைப்படுகிற ஸ்திரீயின் இருதயம்போல இருக்கும் என்கிறார்.
	<br /><br />
	23. தமஸ்குவைக் குறித்துச் சொல்வது: ஆமாத்தும் அர்ப்பாத்தும் கலங்குகிறது; பொல்லாத செய்தியை அவர்கள் கேட்டபடியினால் கரைந்துபோகிறார்கள்; சமுத்திரத்தோரமாய்ச் சஞ்சலமுண்டு; அதற்கு அமைதலில்லை.
	<br /><br />
	24. தமஸ்கு தளர்ந்துபோம், புறங்காட்டி ஓடிப்போம்; திகில் அதைப் பிடித்தது; பிரசவ ஸ்திரீயைப்போல இடுக்கமும் வேதனைகளும் அதைப் பிடித்தது.
	<br /><br />
	25. சந்தோஷமான என் ஊராகிய அந்தப் புகழ்ச்சியுள்ள நகரம் தப்பவிடப்படாமற்போயிற்றே!
	<br /><br />
	26. ஆதலால் அதின் வாலிபர் அதின் வீதிகளில் விழுந்து, யுத்தமனுஷர் எல்லாரும் அந்நாளிலே சங்காரமாவார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	27. தமஸ்குவின் மதில்களில் தீக்கொளுத்துவேன்; அது பெனாதாத்தின் அரமனைகளைப் பட்சிக்கும் என்கிறார்.
	<br /><br />
	28. பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் முறியடிக்கும் கேதாரையும் காத்சோருடைய ராஜ்யங்களையும் குறித்துக் கர்த்தர் சொல்லுகிறது என்னவென்றால்: எழும்பி, கேதாருக்கு விரோதமாகப் போய், கீழ்த்திசைப் புத்திரரைப் பாழாக்குங்கள்.
	<br /><br />
	29. அவர்களுடைய கூடாரங்களையும் அவர்களுடைய மந்தைகளையும் வாங்கி, அவர்களுடைய திரைகளையும் அவர்களுடைய எல்லாத் தட்டுமுட்டுகளையும் அவர்களுடைய ஒட்டகங்களையும் தங்களுக்கென்று கொண்டுபோய், எத்திசையும் பயம் என்று சொல்லி, அவர்கள்மேல் ஆர்ப்பரிப்பார்கள்.
	<br /><br />
	30. காத்சோரின் குடிகளே, ஓடி, தூரத்தில் அலையுங்கள்; பள்ளத்தில் ஒதுங்கிப் பதுங்குங்கள் என்று கர்த்தர் சொல்லுகிறார்; பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் உங்களுக்கு விரோதமாக ஆலோசனைசெய்து, உங்களுக்கு விரோதமாக உபாயங்களைச் சிந்தித்திருக்கிறான்.
	<br /><br />
	31. அஞ்சாமல் நிர்விசாரமாய்க் குடியிருக்கிற ஜாதிக்கு விரோதமாக எழும்பிப்போங்கள் என்று கர்த்தர் சொல்லுகிறார்; அதற்கு வாசல்களுமில்லை, தாழ்ப்பாள்களுமில்லை; அவர்கள் தனிப்படத் தங்கியிருக்கிறார்கள்.
	<br /><br />
	32. அவர்களுடைய ஒட்டகங்கள் கொள்ளையும், அவர்களுடைய ஆடுமாடுகளின் ஏராளம் சூறையுமாகும்; நான் அவர்களைச் சகல திசைகளுமான கடையாந்தர மூலைகளில் இருக்கிறவர்களிடத்துக்குச் சிதறடித்துவிட்டு, அதினுடைய சகல பக்கங்களிலுமிருந்து அவர்களுக்கு ஆபத்தை வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	33. ஆத்சோர் வலுசர்ப்பங்களின் தாபரமாகி, என்றென்றைக்கும் பாழாய்க்கிடக்கும்; ஒருவனும் அங்கே குடியிருப்பதில்லை, ஒரு மனுபுத்திரனும் அதிலே தங்குவதுமில்லையென்கிறார்.
	<br /><br />
	34. யூதா ராஜாவாகிய சிதேக்கியாவினுடைய ராஜ்யபாரத்தின் துவக்கத்திலே, ஏலாமுக்கு விரோதமாக எரேமியா என்னும் தீர்க்கதரிசிக்கு உண்டான கர்த்தருடைய வசனம்:
	<br /><br />
	35. சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் ஏலாமின் வில்லென்னும் அவர்களுடைய பிரதான வல்லமையை முறித்துப்போட்டு,
	<br /><br />
	36. வானத்தின் நாலு திசைகளிலுமிருந்து நாலு காற்றுகளை ஏலாமின்மேல் வரப்பண்ணி, அவர்களை இந்த எல்லாத் திசைகளிலும் சிதறடிப்பேன்; ஏலாம் தேசத்திலிருந்து துரத்துண்டவர்கள் சகல ஜாதிகளிலும் சிதறப்படுவார்கள்.
	<br /><br />
	37. நான் ஏலாமியரை அவர்கள் சத்துருக்களுக்கு முன்பாகவும், அவர்கள் பிராணனை வாங்கத் தேடுகிறவர்களுக்கு முன்பாகவும் கலங்கப்பண்ணி, என் கோபத்தின் உக்கிரமாகிய தீங்கை அவர்கள்மேல் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்; நான் அவர்களை நிர்மூலமாக்குமட்டும் பட்டயத்தை அவர்களுக்குப் பின்னாக அனுப்பி,
	<br /><br />
	38. என் சிங்காசனத்தை ஏலாமிலே வைத்து, அங்கேயிருந்து ராஜாவையும் பிரபுக்களையும் அழித்துப்போடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	39. ஆனாலும் கடைசிநாட்களிலே நான் ஏலாமின் சிறையிருப்பைத் திருப்புவேன் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah49