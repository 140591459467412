import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts18 = () => {
  const verseNumber = 18;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 18 </title>
	<meta
          name="description"
          content="Acts 18 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 18 |
          Acts of Apostles Chapter-18 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அதன்பின்பு பவுல் அத்தேனே பட்டணத்தை விட்டு, கொரிந்து பட்டணத்துக்கு வந்து;
	<br /><br />
	2. யூதரெல்லாரும் ரோமாபுரியை விட்டுப் போகும்படி கிலவுதியுராயன் கட்டளையிட்டபடியினாலே, இத்தாலியாவிலிருந்து புதிதாய் வந்திருந்த பொந்துதேசத்தானாகிய ஆக்கில்லா என்னும் நாமமுள்ள ஒரு யூதனையும் அவன் மனைவியாகிய பிரிஸ்கில்லாளையும் அங்கே கண்டு, அவர்களிடத்திற்குப் போனான்.
	<br /><br />
	3. அவர்கள் கூடாரம்பண்ணுகிற தொழிலாளிகளாயிருந்தார்கள்; தானும் அந்தத் தொழில் செய்கிறவனானபடியினாலே அவர்களிடத்தில் தங்கி, வேலைசெய்துகொண்டு வந்தான்.
	<br /><br />
	4. ஓய்வுநாள்தோறும் அவன் ஜெபஆலயத்திலே சம்பாஷணைபண்ணி, யூதருக்கும் கிரேக்கருக்கும் புத்திசொன்னான்.
	<br /><br />
	5. மக்கெதோனியாவிலிருந்து சீலாவும் தீமோத்தேயும் வந்தபோது, பவுல் ஆவியில் வைராக்கியங்கொண்டு, இயேசுவே கிறிஸ்து என்று யூதருக்குத் திருஷ்டாந்தப்படுத்தினான்.
	<br /><br />
	6. அவர்கள் எதிர்த்துநின்று தூஷித்தபோது, அவன் தன் வஸ்திரங்களை உதறி: உங்கள் இரத்தப்பழி உங்கள் தலையின்மேல் இருக்கும்; நான் சுத்தமாயிருக்கிறேன்; இதுமுதல் புறஜாதியாரிடத்திற்குப் போகிறேனென்று அவர்களுடனே சொல்லி,
	<br /><br />
	7. அவ்விடத்தைவிட்டு, தேவனை வணங்குகிறவனாகிய யுஸ்து என்னும் பேருள்ள ஒருவனுடைய வீட்டிற்கு வந்தான்; அவன் வீடு ஜெபஆலயத்திற்கு அடுத்ததாயிருந்தது.
	<br /><br />
	8. ஜெபஆலயத்தலைவனாகிய கிறிஸ்பு என்பவன் தன் வீட்டார் அனைவரோடும் கர்த்தரிடத்தில் விசுவாசமுள்ளவனானான். கொரிந்தியரில் அநேகரும் சுவிசேஷத்தைக் கேட்டு, விசுவாசித்து, ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	9. இராத்திரியிலே கர்த்தர் பவுலுக்குத் தரிசனமாகி: நீ பயப்படாமல் பேசு, மவுனமாயிராதே;
	<br /><br />
	10. நான் உன்னுடனேகூட இருக்கிறேன், உனக்குத் தீங்குசெய்யும்படி ஒருவனும் உன்மேல் கைபோடுவதில்லை; இந்தப் பட்டணத்தில் எனக்கு அநேக ஜனங்கள் உண்டு என்றார்.
	<br /><br />
	11. அவன் ஒரு வருஷமும் ஆறு மாதமும் அங்கே தங்கி, தேவவசனத்தை அவர்களுக்குள்ளே உபதேசம்பண்ணிக்கொண்டுவந்தான்.
	<br /><br />
	12. கல்லியோன் என்பவன் அகாயா நாட்டிற்கு அதிபதியானபோது, யூதர்கள் ஒருமனப்பட்டு, பவுலுக்கு விரோதமாய் எழும்பி, அவனை நியாயாசனத்துக்கு முன்பாகக் கொண்டுபோய்:
	<br /><br />
	13. இவன் வேதப்பிரமாணத்துக்கு விகற்பமாய்த் தேவனைச் சேவிக்கும்படி மனுஷருக்குப் போதிக்கிறான் என்றார்கள்.
	<br /><br />
	14. பவுல் பேசுவதற்கு எத்தனப்படுகையில், கல்லியோன் யூதரை நோக்கி: யூதர்களே, இது ஒரு அநியாயமாய், அல்லது பொல்லாத நடக்கையாயிருக்குமேயானால் நான் உங்களுக்குப் பொறுமையாய்ச் செவிகொடுப்பது நியாயமாயிருக்கும்.
	<br /><br />
	15. இது சொற்களுக்கும், நாமங்களுக்கும், உங்கள் வேதத்துக்கும் அடுத்த தர்க்கமானபடியினாலே, இப்படிப்பட்டவைகளைக்குறித்து விசாரணைசெய்ய எனக்கு மனதில்லை, நீங்களே பார்த்துக்கொள்ளுங்கள் என்று சொல்லி,
	<br /><br />
	16. அவர்களை நியாயாசனத்தினின்று துரத்திவிட்டான்.
	<br /><br />
	17. அப்பொழுது கிரேக்கரெல்லாரும் ஜெபஆலயத்தலைவனாகிய சொஸ்தேனேயைப் பிடித்து, நியாயாசனத்துக்கு முன்பாக அடித்தார்கள். இவைகளில் ஒன்றையுங்குறித்துக் கல்லியோன் கவலைப்படவில்லை.
	<br /><br />
	18. பவுல் அநேகநாள் அங்கே தரித்திருந்தபின்பு, சகோதரரிடத்தில் உத்தரவு பெற்றுக்கொண்டு, தனக்கு ஒரு பிரார்த்தனை உண்டாயிருந்தபடியினால் கெங்கிரேயா பட்டணத்தில் தலைச்சவரம் பண்ணிக்கொண்டு, சீரியாதேசத்துக்குப் போகக் கப்பல் ஏறினான். பிரிஸ்கில்லாளும் ஆக்கில்லாவும் அவனுடனே கூடப்போனார்கள்.
	<br /><br />
	19. அவன் எபேசுபட்டணத்துக்கு வந்தபோது, அங்கே அவர்களை விட்டு நீங்கி, ஜெபஆலயத்தில் பிரவேசித்து, யூதருடனே சம்பாஷணைபண்ணினான்.
	<br /><br />
	20. அவன் இன்னுஞ் சிலகாலம் தங்களுடனே இருக்கவேண்டுமென்று அவர்கள் கேட்டுக்கொண்டபோது, அவன் சம்மதியாமல்,
	<br /><br />
	21. வருகிற பண்டிகையிலே எப்படியாயினும் நான் எருசலேமில் இருக்கவேண்டும், தேவனுக்குச் சித்தமானால் திரும்பி உங்களிடத்திற்கு வருவேனென்று சொல்லி, அவர்களிடத்தில் உத்தரவு பெற்றுக்கொண்டு, கப்பல் ஏறி, எபேசுவை விட்டுப் புறப்பட்டு,
	<br /><br />
	22. செசரியா பட்டணத்துக்கு வந்து, எருசலேமுக்குப் போய், சபையைச் சந்தித்து, அந்தியோகியாவுக்குப் போனான்.
	<br /><br />
	23. அங்கே சிலகாலம் சஞ்சரித்தபின்பு, புறப்பட்டு, கிரமமாய்க் கலாத்தியா நாட்டிலேயும் பிரிகியா நாட்டிலேயும் சுற்றித்திரிந்து, சீஷரெல்லாரையும் திடப்படுத்தினான்.
	<br /><br />
	24. அப்பொழுது அலெக்சந்திரியா பட்டணத்தில் பிறந்தவனும் சாதுரியவானும் வேதாகமங்களில் வல்லவனுமான அப்பொல்லோ என்னும் பேர்கொண்ட ஒரு யூதன் எபேசுபட்டணத்துக்கு வந்தான்.
	<br /><br />
	25. அவன் கர்த்தருடைய மார்க்கத்திலே உபதேசிக்கப்பட்டு, யோவான் கொடுத்த ஸ்நானத்தைமாத்திரம் அறிந்தவனாயிருந்து, ஆவியில் அனலுள்ளவனாய்க் கர்த்தருக்கு அடுத்தவைகளைத் திட்டமாய்ப் போதகம்பண்ணிக்கொண்டுவந்தான்.
	<br /><br />
	26. அவன் ஜெபஆலயத்தில் தைரியமாய்ப் பேசத்தொடங்கினபோது ஆக்கில்லாவும் பிரிஸ்கில்லாளும் அவன் பேசுகிறதைக் கேட்டு, அவனைச் சேர்த்துக்கொண்டு, தேவனுடைய மார்க்கத்தை அதிக திட்டமாய் அவனுக்கு விவரித்துக்காண்பித்தார்கள்.
	<br /><br />
	27. பின்பு அவன் அகாயா நாட்டிற்குப் போகவேண்டுமென்றிருக்கையில், சீஷர்கள் அவனை ஏற்றுக்கொள்ளும்படி சகோதரர் அவர்களுக்கு எழுதினார்கள்.
	<br /><br />
	28. அவன் அங்கே வந்தபின்பு வெளியரங்கமாக யூதர்களுடனே பலமாய்த் தர்க்கம்பண்ணி, இயேசுவே கிறிஸ்து என்று வேதவாக்கியங்களைக்கொண்டு திருஷ்டாந்தப்படுத்தினபடியால், கிருபையினாலே விசுவாசிகளானவர்களுக்கு மிகவும் உதவியாயிருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts18