import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 11 </title>
	<meta
          name="description"
          content="John Chapter 11 | யோவான் அதிகாரம் 11 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மரியாளும் அவள் சகோதரியாகிய மார்த்தாளும் இருந்த பெத்தானியா கிராமத்திலுள்ளவனாகிய லாசரு என்னும் ஒருவன் வியாதிப்பட்டிருந்தான்.
	<br /><br />
	2. கர்த்தருக்குப் பரிமளதைலம் பூசி, தன் தலைமயிரால் அவருடைய பாதங்களைத் துடைத்தவள் அந்த மரியாளே; அவளுடைய சகோதரனாகிய லாசரு வியாதியாயிருந்தான்.
	<br /><br />
	3. அப்பொழுது அவனுடைய சகோதரிகள்: ஆண்டவரே, நீர் சிநேகிக்கிறவன் வியாதியாயிருக்கிறான் என்று சொல்ல, அவரிடத்திற்கு ஆள் அனுப்பினார்கள்.
	<br /><br />
	4. இயேசு அதைக் கேட்டபொழுது: இந்த வியாதி மரணத்துக்கு ஏதுவாயிராமல் தேவனுடைய மகிமை விளங்குவதற்கு ஏதுவாயிருக்கிறது; தேவனுடைய குமாரனும் அதினால் மகிமைப்படுவார் என்றார்.
	<br /><br />
	5. இயேசு மார்த்தாளிடத்திலும் அவளுடைய சகோதரியினிடத்திலும் லாசருவினிடத்திலும் அன்பாயிருந்தார்.
	<br /><br />
	6. அவன் வியாதியாயிருக்கிறதாக அவர் கேள்விப்பட்டபொழுது, தாம் இருந்த இடத்திலே பின்னும் இரண்டுநாள் தங்கினார்.
	<br /><br />
	7. அதன்பின்பு அவர் தம்முடைய சீஷரை நோக்கி: நாம் மறுபடியும் யூதேயாவுக்குப் போவோம் வாருங்கள் என்றார்.
	<br /><br />
	8. அதற்குச் சீஷர்கள்: ரபீ, இப்பொழுதுதான் யூதர் உம்மைக் கல்லெறியத் தேடினார்களே, மறுபடியும் நீர் அவ்விடத்திற்குப் போகலாமா என்றார்கள்.
	<br /><br />
	9. இயேசு பிரதியுத்தரமாக: பகலுக்குப் பன்னிரண்டு மணிநேரம் இல்லையா? ஒருவன் பகலிலே நடந்தால் அவன் இந்த உலகத்தின் வெளிச்சத்தைக் காண்கிறபடியினால் இடறமாட்டான்.
	<br /><br />
	10. ஒருவன் இரவிலே நடந்தால் தன்னிடத்தில் வெளிச்சம் இல்லாதபடியினால் இடறுவான் என்றார்.
	<br /><br />
	11. இவைகளை அவர் சொல்லியபின்பு அவர்களை நோக்கி: நம்முடைய சிநேகிதனாகிய லாசரு நித்திரையடைந்திருக்கிறான், நான் அவனை எழுப்பப்போகிறேன் என்றார்.
	<br /><br />
	12. அதற்கு அவருடைய சீஷர்கள்: ஆண்டவரே, நித்திரையடைந்திருந்தால் சுகமடைவான் என்றார்கள்.
	<br /><br />
	13. இயேசுவானவர் அவனுடைய மரணத்தைக்குறித்து அப்படிச் சொன்னார்; அவர்களோ நித்திரைசெய்து இளைப்பாறுகிறதைக்குறித்துச் சொன்னாரென்று நினைத்தார்கள்.
	<br /><br />
	14. அப்பொழுது இயேசு அவர்களை நோக்கி: லாசரு மரித்துப்போனான் என்று வெளிப்படையாய்ச் சொல்லி;
	<br /><br />
	15. நான் அங்கே இராததினால் நீங்கள் விசுவாசமுள்ளவர்களாகிறதற்கு ஏதுவுண்டென்று உங்கள்நிமித்தம் சந்தோஷப்படுகிறேன்; இப்பொழுது அவனிடத்திற்குப் போவோம் வாருங்கள் என்றார்.
	<br /><br />
	16. அப்பொழுது திதிமு என்னப்பட்ட தோமா மற்றச் சீஷர்களை நோக்கி: அவரோடேகூட மரிக்கும்படி நாமும் போவோம் வாருங்கள் என்றான்.
	<br /><br />
	17. இயேசு வந்தபோது அவன் கல்லறையில் வைக்கப்பட்டு நாலுநாளாயிற்றென்று கண்டார்.
	<br /><br />
	18. பெத்தானியா ஊர் எருசலேமுக்குச் சமீபமாய் ஏறக்குறைய இரண்டுமைல் தூரத்திலிருந்தது.
	<br /><br />
	19. யூதரில் அநேகர் மார்த்தாள் மரியாள் என்பவர்களுடைய சகோதரனைக் குறித்து அவர்களுக்கு ஆறுதல் சொல்லும்படி அவர்களிடத்தில் வந்திருந்தார்கள்.
	<br /><br />
	20. இயேசு வருகிறார் என்று மார்த்தாள் கேள்விப்பட்டபோது, அவருக்கு எதிர்கொண்டுபோனாள்; மரியாளோ வீட்டிலே உட்கார்ந்திருந்தாள்.
	<br /><br />
	21. மார்த்தாள் இயேசுவினிடத்தில் வந்து: ஆண்டவரே, நீர் இங்கேயிருந்தீரானால் என் சகோதரன் மரிக்கமாட்டான்.
	<br /><br />
	22. இப்பொழுதும் நீர் தேவனிடத்தில் கேட்டுக்கொள்ளுவதெதுவோ அதைத் தேவன் உமக்குத் தந்தருளுவாரென்று அறிந்திருக்கிறேன் என்றாள்.
	<br /><br />
	23. இயேசு அவளை நோக்கி: உன் சகோதரன் உயிர்த்தெழுந்திருப்பான் என்றார்.
	<br /><br />
	24. அதற்கு மார்த்தாள்: உயிர்த்தெழுதல் நடக்கும் கடைசிநாளிலே அவனும் உயிர்த்தெழுந்திருப்பான் என்று அறிந்திருக்கிறேன் என்றாள்.
	<br /><br />
	25. இயேசு அவளை நோக்கி: நானே உயிர்த்தெழுதலும் ஜீவனுமாயிருக்கிறேன், என்னை விசுவாசிக்கிறவன் மரித்தாலும் பிழைப்பான்;
	<br /><br />
	26. உயிரோடிருந்து என்னை விசுவாசிக்கிறவனெவனும் என்றென்றைக்கும் மரியாமலும் இருப்பான்; இதை விசுவாசிக்கிறாயா என்றார்.
	<br /><br />
	27. அதற்கு அவள்: ஆம், ஆண்டவரே, நீர் உலகத்தில் வருகிறவரான தேவகுமாரனாகிய கிறிஸ்து என்று நான் விசுவாசிக்கிறேன் என்றாள்.
	<br /><br />
	28. இவைகளைச் சொன்னபின்பு, அவள்போய், தன் சகோதரியாகிய மரியாளை இரகசியமாய் அழைத்து: போதகர் வந்திருக்கிறார், உன்னை அழைக்கிறார் என்றாள்.
	<br /><br />
	29. அவள் அதைக் கேட்டவுடனே, சீக்கிரமாய் எழுந்து, அவரிடத்தில் வந்தாள்.
	<br /><br />
	30. இயேசு இன்னும் கிராமத்துக்குள் வராமல், மார்த்தாள் தம்மைச் சந்தித்த இடத்திலே இருந்தார்.
	<br /><br />
	31. அப்பொழுது, வீட்டிலே அவளுடனேகூட இருந்து அவளுக்கு ஆறுதல் சொல்லிக்கொண்டிருந்த யூதர்கள், மரியாள் சீக்கிரமாய் எழுந்துபோகிறதைக் கண்டு: அவள் கல்லறையினிடத்தில் அழுகிறதற்குப் போகிறாள் என்று சொல்லி, அவளுக்குப் பின்னே போனார்கள்.
	<br /><br />
	32. இயேசு இருந்த இடத்தில் மரியாள் வந்து, அவரைக் கண்டவுடனே, அவர் பாதத்தில் விழுந்து: ஆண்டவரே, நீர் இங்கே இருந்தீரானால் என் சகோதரன் மரிக்கமாட்டான் என்றாள்.
	<br /><br />
	33. அவள் அழுகிறதையும் அவளோடேகூட வந்த யூதர்கள் அழுகிறதையும் இயேசு கண்டபோது ஆவியிலே கலங்கித் துயரமடைந்து:
	<br /><br />
	34. அவனை எங்கே வைத்தீர்கள் என்றார். ஆண்டவரே, வந்து பாரும் என்றார்கள்.
	<br /><br />
	35. இயேசு கண்ணீர் விட்டார்.
	<br /><br />
	36. அப்பொழுது யூதர்கள்: இதோ, இவர் அவனை எவ்வளவாய்ச் சிநேகித்தார் என்றார்கள்!
	<br /><br />
	37. அவர்களில் சிலர்: குருடனுடைய கண்களைத் திறந்த இவர், இவனைச் சாகாமலிருக்கப்பண்ணவும் கூடாதா என்றார்கள்.
	<br /><br />
	38. அப்பொழுது இயேசு மறுபடியும் தமக்குள்ளே கலங்கிக் கல்லறையினிடத்திற்கு வந்தார். அது ஒரு குகையாயிருந்தது; அதின்மேல் ஒரு கல் வைக்கப்பட்டிருந்தது.
	<br /><br />
	39. இயேசு: கல்லை எடுத்துப்போடுங்கள் என்றார். மரித்தவனுடைய சகோதரியாகிய மார்த்தாள் அவரை நோக்கி: ஆண்டவரே, இப்பொழுது நாறுமே, நாலுநாளாயிற்றே என்றாள்.
	<br /><br />
	40. இயேசு அவளை நோக்கி: நீ விசுவாசித்தால் தேவனுடைய மகிமையைக் காண்பாய் என்று நான் உனக்குச் சொல்லவில்லையா என்றார்.
	<br /><br />
	41. அப்பொழுது மரித்தவன் வைக்கப்பட்ட இடத்திலிருந்த கல்லை எடுத்துப்போட்டார்கள். இயேசு தம்முடைய கண்களை ஏறெடுத்து: பிதாவே, நீர் எனக்குச் செவிகொடுத்தபடியினால் உம்மை ஸ்தோத்திரிக்கிறேன்.
	<br /><br />
	42. நீர் எப்பொழுதும் எனக்குச் செவிகொடுக்கிறீர் என்று நான் அறிந்திருக்கிறேன்; ஆனாலும் நீர் என்னை அனுப்பினதைச் சூழ்ந்துநிற்கும் ஜனங்கள் விசுவாசிக்கும்படியாக அவர்கள் நிமித்தம் இதைச் சொன்னேன் என்றார்.
	<br /><br />
	43. இவைகளைச் சொன்னபின்பு: லாசருவே, வெளியே வா என்று, உரத்த சத்தமாய்க் கூப்பிட்டார்.
	<br /><br />
	44. அப்பொழுது, மரித்தவன் வெளியே வந்தான். அவன் கால்களும் கைகளும் பிரேதச் சீலைகளினால் கட்டப்பட்டிருந்தது, அவன் முகமும் சீலையால் சுற்றப்பட்டிருந்தது. இயேசு அவர்களை நோக்கி: இவனைக் கட்டவிழ்த்துவிடுங்கள் என்றார்.
	<br /><br />
	45. அப்பொழுது மரியாளிடத்தில் வந்திருந்து, இயேசு செய்தவைகளைக் கண்டவர்களாகிய யூதர்களில் அநேகர் அவரிடத்தில் விசுவாசமுள்ளவர்களானார்கள்.
	<br /><br />
	46. அவர்களில் சிலர் பரிசேயரிடத்தில் போய், இயேசு செய்தவைகளை அவர்களுக்கு அறிவித்தார்கள்.
	<br /><br />
	47. அப்பொழுது பிரதான ஆசாரியரும் பரிசேயரும் ஆலோசனைச் சங்கத்தைக் கூடிவரச்செய்து, நாம் என்னசெய்கிறது? இந்த மனுஷன் அநேக அற்புதங்களைச் செய்கிறானே.
	<br /><br />
	48. நாம் இவனை இப்படி விட்டுவிட்டால், எல்லாரும் இவனை விசுவாசிப்பார்கள்; அப்பொழுது ரோமர் வந்து நம்முடைய ஸ்தானத்தையும் ஜனத்தையும் அழித்துப் போடுவார்களே என்றார்கள்.
	<br /><br />
	49. அப்பொழுது அவர்களில் ஒருவனும் அந்த வருஷத்துப் பிரதான ஆசாரியனுமாகிய காய்பா என்பவன் அவர்களை நோக்கி: உங்களுக்கு ஒன்றுந் தெரியாது;
	<br /><br />
	50. ஜனங்களெல்லாரும் கெட்டுப்போகாதபடிக்கு ஒரே மனுஷன் ஜனங்களுக்காக மரிப்பது நமக்கு நலமாயிருக்குமென்று நீங்கள் சிந்தியாமலிருக்கிறீர்கள் என்றான்.
	<br /><br />
	51. இதை அவன் சுயமாய்ச் சொல்லாமல், அந்த வருஷத்துப் பிரதான ஆசாரியனானபடியினாலே இயேசு யூதஜனங்களுக்காக மரிக்கப்போகிறாரென்றும்,
	<br /><br />
	52. அந்த ஜனங்களுக்காகமாத்திரமல்ல, சிதறியிருக்கிற தேவனுடைய பிள்ளைகளை ஒன்றாகச் சேர்க்கிறதற்காகவும் மரிக்கப்போகிறாரென்றும், தீர்க்கதரிசனமாய்ச் சொன்னான்.
	<br /><br />
	53. அந்நாள்முதல் அவரைக் கொலைசெய்யும்படிக்கு ஆலோசனைபண்ணினார்கள்.
	<br /><br />
	54. ஆகையால் இயேசு அதன்பின்பு வெளியரங்கமாய் யூதருக்குள்ளே சஞ்சரியாமல், அவ்விடம்விட்டு வனாந்தரத்துக்குச் சமீபமான இடமாகிய எப்பிராயீம் என்னப்பட்ட ஊருக்குப்போய், அங்கே தம்முடைய சீஷருடனேகூடத் தங்கியிருந்தார்.
	<br /><br />
	55. யூதருடைய பஸ்காபண்டிகை சமீபமாயிருந்தது. அதற்கு முன்னே அநேகர் தங்களைச் சுத்திகரித்துக்கொள்ளும்பொருட்டு நாட்டிலிருந்து எருசலேமுக்குப் போனார்கள்.
	<br /><br />
	56. அங்கே அவர்கள் இயேசுவைத் தேடிக்கொண்டு தேவாலயத்தில் நிற்கையில், ஒருவரையொருவர் நோக்கி: உங்களுக் கெப்படித் தோன்றுகிறது, அவர் பண்டிகைக்கு வரமாட்டாரோ என்று பேசிக்கொண்டார்கள்.
	<br /><br />
	57. பிரதான ஆசாரியரும் பரிசேயரும் அவரைப் பிடிக்கும்படி யோசித்து, அவர் இருக்கிற இடத்தை எவனாவது அறிந்திருந்தால், அதை அறிவிக்கவேண்டுமென்று கட்டளையிட்டிருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John11