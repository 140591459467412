import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John16 = () => {
  const verseNumber = 16;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 16 </title>
	<meta
          name="description"
          content="John Chapter 16 | யோவான் அதிகாரம் 16 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீங்கள் இடறலடையாதபடிக்கு இவைகளை உங்களுக்குச் சொன்னேன்.
	<br /><br />
	2. அவர்கள் உங்களை ஜெபஆலயங்களுக்குப் புறம்பாக்குவார்கள்; மேலும் உங்களைக் கொலைசெய்கிறவன் தான் தேவனுக்குத் தொண்டுசெய்கிறவனென்று நினைக்குங் காலம் வரும்.
	<br /><br />
	3. அவர்கள் பிதாவையும் என்னையும் அறியாதபடியினால் இவைகளை உங்களுக்குச் செய்வார்கள்.
	<br /><br />
	4. அந்தக் காலம் வரும்போது நான் இவைகளை உங்களுக்குச் சொன்னேனென்று நீங்கள் நினைக்கும்படி இவைகளை உங்களுக்குச் சொல்லியிருக்கிறேன்; நான் உங்களுடனேகூட இருந்தபடியினால் ஆரம்பத்திலே இவைகளை உங்களுக்குச் சொல்லவில்லை.
	<br /><br />
	5. இப்பொழுது நான் என்னை அனுப்பினவரிடத்திற்குப் போகிறேன்; எங்கே போகிறீரென்று உங்களில் ஒருவனும் என்னைக் கேட்கவில்லை.
	<br /><br />
	6. ஆனாலும் நான் இவைகளை உங்களுக்குச் சொன்னதினால் உங்கள் இருதயம் துக்கத்தால் நிறைந்திருக்கிறது.
	<br /><br />
	7. நான் உங்களுக்கு உண்மையைச் சொல்லுகிறேன்; நான் போகிறது உங்களுக்குப் பிரயோஜனமாயிருக்கும்; நான் போகாதிருந்தால், தேற்றரவாளன் உங்களிடத்தில் வரார்; நான் போவேனேயாகில் அவரை உங்களிடத்திற்கு அனுப்புவேன்.
	<br /><br />
	8. அவர் வந்து, பாவத்தைக்குறித்தும், நீதியைக்குறித்தும், நியாயத்தீர்ப்பைக்குறித்தும், உலகத்தைக் கண்டித்து உணர்த்துவார்.
	<br /><br />
	9. அவர்கள் என்னை விசுவாசியாதபடியினாலே பாவத்தைக்குறித்தும்,
	<br /><br />
	10. நீங்கள் இனி என்னைக் காணாதபடிக்கு நான் என் பிதாவினிடத்திற்குப் போகிறபடியினாலே நீதியைக்குறித்தும்,
	<br /><br />
	11. இந்த உலகத்தின் அதிபதி நியாயந்தீர்க்கப்பட்டதினாலே நியாயத்தீர்ப்பைக்குறித்தும், கண்டித்து உணர்த்துவார்.
	<br /><br />
	12. இன்னும் அநேகங்காரியங்களை நான் உங்களுக்குச் சொல்லவேண்டியதாயிருக்கிறது, அவைகளை நீங்கள் இப்பொழுது தாங்கமாட்டீர்கள்.
	<br /><br />
	13. சத்திய ஆவியாகிய அவர் வரும்போது, சகல சத்தியத்திற்குள்ளும் உங்களை நடத்துவார்; அவர் தம்முடைய சுயமாய்ப் பேசாமல், தாம் கேள்விப்பட்டவைகள் யாவையுஞ்சொல்லி, வரப்போகிற காரியங்களை உங்களுக்கு அறிவிப்பார்.
	<br /><br />
	14. அவர் என்னுடையதில் எடுத்து உங்களுக்கு அறிவிப்பதினால் என்னை மகிமைப்படுத்துவார்.
	<br /><br />
	15. பிதாவினுடையவைகள் யாவும் என்னுடையவைகள்; அதினாலே அவர் என்னுடையதில் எடுத்து உங்களுக்கு அறிவிப்பார் என்றேன்.
	<br /><br />
	16. நான் பிதாவினிடத்திற்குப் போகிறபடியினால் கொஞ்சக்காலத்திலே என்னைக் காணாதிருப்பீர்கள், மறுபடியும் கொஞ்சக்காலத்திலே என்னைக் காண்பீர்கள் என்றார்.
	<br /><br />
	17. அப்பொழுது அவருடைய சீஷரில் சிலர்: நான் பிதாவினிடத்திற்குப் போகிறபடியினால், கொஞ்சக்காலத்திலே என்னைக் காணாதிருப்பீர்கள் என்றும், மறுபடியும் கொஞ்சக்காலத்திலே என்னைக் காண்பீர்கள் என்றும் அவர் நம்முடனே சொல்லுகிறதின் கருத்தென்ன என்று தங்களுக்குள்ளே பேசிக்கொண்டதுமன்றி:
	<br /><br />
	18. கொஞ்சக்காலம் என்கிறாரே, இதென்ன? அவர் சொல்லுகிறது இன்னதென்று நமக்கு விளங்கவில்லையே என்றார்கள்.
	<br /><br />
	19. அதைக்குறித்துத் தம்மிடத்தில் கேட்கும்படி அவர்கள் விரும்புகிறதை இயேசு அறிந்து, அவர்களை நோக்கி: கொஞ்சக்காலத்திலே என்னைக் காணாதிருப்பீர்கள், மறுபடியும் கொஞ்சக்காலத்திலே என்னைக் காண்பீர்கள் என்று நான் சொன்னதைக்குறித்து நீங்கள் உங்களுக்குள்ளே விசாரிக்கிறீர்களோ?
	<br /><br />
	20. மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன்: நீங்கள் அழுது புலம்புவீர்கள், உலகமோ சந்தோஷப்படும்; நீங்கள் துக்கப்படுவீர்கள், ஆனாலும் உங்கள் துக்கம் சந்தோஷமாக மாறும்.
	<br /><br />
	21. ஸ்திரீயானவளுக்குப் பிரசவகாலம் வந்திருக்கும்போது அவள் துக்கமடைகிறாள்; பிள்ளைபெற்றவுடனே ஒரு மனுஷன் உலகத்தில் பிறந்தானென்கிற சந்தோஷத்தினால் அப்புறம் உபத்திரவத்தை நினையாள்.
	<br /><br />
	22. அதுபோல நீங்களும் இப்பொழுது துக்கமடைந்திருக்கிறீர்கள். நான் மறுபடியும் உங்களைக் காண்பேன், அப்பொழுது உங்கள் இருதயம் சந்தோஷப்படும், உங்கள் சந்தோஷத்தை ஒருவனும் உங்களிடத்திலிருந்து எடுத்துப்போடமாட்டான்.
	<br /><br />
	23. அந்த நாளிலே நீங்கள் என்னிடத்தில் ஒன்றுங் கேட்கமாட்டீர்கள். மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன், நீங்கள் என் நாமத்தினாலே பிதாவினிடத்தில் கேட்டுக் கொள்வதெதுவோ அதை அவர் உங்களுக்குத் தருவார்.
	<br /><br />
	24. இதுவரைக்கும் நீங்கள் என் நாமத்தினாலே ஒன்றும் கேட்கவில்லை; கேளுங்கள், அப்பொழுது உங்கள் சந்தோஷம் நிறைவாயிருக்கும்படி பெற்றுக் கொள்வீர்கள்.
	<br /><br />
	25. இவைகளை நான் உவமைகளாய் உங்களுடனே பேசுகிறேன்; காலம் வரும், அப்பொழுது நான் உவமைகளாய் உங்களுடனே பேசாமல், பிதாவைக்குறித்து வெளிப்படையாக உங்களுக்கு அறிவிப்பேன்.
	<br /><br />
	26. அந்த நாளில் நீங்கள் என் நாமத்தினாலே வேண்டிக் கொள்வீர்கள். உங்களுக்காகப் பிதாவை நான் கேட்டுக் கொள்வேனென்று உங்களுக்குச் சொல்லவேண்டியதில்லை.
	<br /><br />
	27. நீங்கள் என்னைச் சிநேகித்து, நான் தேவனிடத்திலிருந்து புறப்பட்டுவந்தேனென்று விசுவாசிக்கிறபடியினால் பிதாதாமே உங்களைச் சிநேகிக்கிறார்.
	<br /><br />
	28. நான் பிதாவினிடத்திலிருந்து புறப்பட்டு உலகத்திலே வந்தேன்; மறுபடியும் உலகத்தைவிட்டுப் பிதாவினிடத்திற்குப் போகிறேன் என்றார்.
	<br /><br />
	29. அவருடைய சீஷர்கள் அவரை நோக்கி: இதோ, இப்பொழுது நீர் உவமையாய்ப் பேசாமல், வெளிப்படையாய்ப் பேசுகிறீர்.
	<br /><br />
	30. நீர் எல்லாவற்றையும் அறிந்திருக்கிறீர் என்றும், ஒருவன் உம்மை வினாவ வேண்டுவதில்லையென்றும், இப்பொழுது அறிந்திருக்கிறோம்; இதினாலே நீர் தேவனிடத்திலிருந்து வந்தீரென்று விசுவாசிக்கிறோம் என்றார்கள்.
	<br /><br />
	31. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: இப்பொழுது நீங்கள் விசுவாசிக்கிறீர்கள்.
	<br /><br />
	32. இதோ, நீங்கள் சிதறுண்டு, அவனவன் தன் தன் இடத்துக்குப் போய், என்னைத் தனியே விட்டுவிடுங்காலம் வரும்; அது இப்பொழுது வந்திருக்கிறது; ஆனாலும் நான் தனித்திரேன், பிதா என்னுடனேகூட இருக்கிறார்.
	<br /><br />
	33. என்னிடத்தில் உங்களுக்குச் சமாதானம் உண்டாயிருக்கும்பொருட்டு இவைகளை உங்களுக்குச் சொன்னேன். உலகத்தில் உங்களுக்கு உபத்திரவம் உண்டு, ஆனாலும் திடன்கொள்ளுங்கள்; நான் உலகத்தை ஜெயித்தேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John16