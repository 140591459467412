import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah34 = () => {
  const verseNumber = 34;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 34 </title>
	<meta
          name="description"
          content="Isaiah 34 | ஏசாயா 34 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஜாதிகளே, கேட்கிறதற்குக் கிட்டிவாருங்கள்; ஜனங்களே, கவனியுங்கள்; பூமியும் அதின் நிறைவும், பூச்சக்கரமும் அதில் உற்பத்தியான யாவும் கேட்கக்கடவது.
	<br /><br />
	2. சகல ஜாதிகளின்மேலும் கர்த்தருடைய கடுங்கோபமும், அவைகளுடைய சகல சேனைகளின்மேலும் அவருடைய உக்கிரமும் மூளுகிறது; அவர்களைச் சங்காரத்துக்கு நியமித்து, கொலைக்கு ஒப்புக்கொடுக்கிறார்.
	<br /><br />
	3. அவர்களிலே கொலைசெய்யப்பட்டவர்கள் வெளியே எறியுண்டுகிடப்பார்கள்; அவர்களுடைய பிரேதங்கள் நாற்றமெடுக்கும்; அவர்களுடைய இரத்தத்தினாலே மலைகளும் கரைந்துபோம்.
	<br /><br />
	4. வானத்தின் சர்வ சேனையும் கரைந்து, வானங்கள் புஸ்தகச்சுருளைப்போல் சுருட்டப்பட்டு, அவைகளின் சர்வசேனையும் திராட்சச்செடியின் இலைகள் உதிருகிறதுபோலவும், அத்திமரத்தின் காய்கள் உதிருகிறதுபோலவும் உதிர்ந்து விழும்.
	<br /><br />
	5. வானங்களில் என் பட்டயம் வெறிகொண்டது; இதோ, ஏதோமின்மேலும், நான் சங்காரத்துக்கு நியமித்த ஜனத்தின்மேலும், அது நியாயஞ்செய்ய இறங்கும்.
	<br /><br />
	6. போஸ்றாவிலே கர்த்தருக்கு ஒரு யாகமும், ஏதோம் தேசத்திலே மகா சங்காரமும் உண்டு; கர்த்தருடைய பட்டயம் இரத்தத்தால் திருப்தியாகி, நிணத்தினால் பூரிக்கின்றது; ஆட்டுக்குட்டிகள் கடாக்களுடைய இரத்தத்தினாலும், ஆட்டுக்கடாக்களுடைய குண்டிக்காய்களின் கொழுப்பினாலும் திருப்தியாகும்.
	<br /><br />
	7. அவைகளோடே காண்டாமிருகங்களும், ரிஷபங்களோடே காளைகளும் வந்து மடியும்; அவர்கள் தேசம் இரத்தவெறிகொண்டு, அவர்கள் மண் நிணத்தினால் கொழுத்துப்போம்.
	<br /><br />
	8. அது கர்த்தர் பழிவாங்கும் நாள், சீயோனுடைய வழக்கினிமித்தம் பதிலளிக்கும் வருஷம்.
	<br /><br />
	9. அதின் ஆறுகள் பிசினாகவும், அதின் மண் கந்தகமாகவும் மாறி, அதின் நிலம் எரிகிற கீலாய்ப்போம்.
	<br /><br />
	10. இரவும் பகலும் அது அவியாது; அதின் புகை என்றென்றைக்கும் எழும்பும்; தலைமுறை தலைமுறையாக அது பாழாயிருக்கும்; சதாகாலம் சதாகாலமாக அதை ஒருவரும் கடந்துபோவதில்லை.
	<br /><br />
	11. நாரையும் முள்ளம்பன்றியும் அதைச் சுதந்தரிக்கும், ஆந்தையும் காக்கையும் அதிலே குடியிருக்கும்; அதின்மேல் வெட்டவெளியின் நூலையும், வெறுமையின் தூக்கையும் பிடிப்பார்.
	<br /><br />
	12. ராஜ்யபாரம்பண்ண அதின் மேன்மக்களை அழைத்தால், அங்கே அவர்களில் ஒருவரும் இரார்கள்; அதின் பிரபுக்கள் அனைவரும் இல்லாமற்போவார்கள்.
	<br /><br />
	13. அதின் அரமனைகளில் முட்செடிகளும், அதின் கோட்டைகளில் காஞ்சொறிகளும் முட்பூண்டுகளும் முளைக்கும்; அது வலுசர்ப்பங்களின் தாபரமும், கோட்டான்களின் மாளிகையுமாயிருக்கும்.
	<br /><br />
	14. அங்கே காட்டுமிருகங்களும் ஓரிகளும் ஒன்றையொன்று சந்தித்து, காட்டாட்டைக் காட்டாடு கூப்பிடும்; அங்கே சாக்குருவிகளும் தங்கி, இளைப்பாறும் இடத்தைக் கண்டடையும்.
	<br /><br />
	15. அங்கே வல்லூறும் கூடுகட்டி, முட்டையிட்டு, குஞ்சுபொரித்து, அவைகளைத் தன் நிழலிலே கூட்டிக்கொள்ளும்; அங்கே கூளிகளும் ஜோடுஜோடாகச் சேரும்.
	<br /><br />
	16. கர்த்தருடைய புஸ்தகத்திலே தேடி வாசியுங்கள்; இவைகளில் ஒன்றும் குறையாது; இவைகளில் ஒன்றும் ஜோடில்லாதிராது; அவருடைய வாய் இதைச் சொல்லிற்று; அவருடைய ஆவி அவைகளைச் சேர்க்கும்.
	<br /><br />
	17. அவரே அவைகளுக்குச் சீட்டுப்போட்டார்; அவருடைய கையே அதை அவைகளுக்கு அளவுநூலால் பகிர்ந்துகொடுத்தது; அவைகள் என்றைக்கும் அதைச் சுதந்தரித்துத் தலைமுறை தலைமுறையாக அதிலே சஞ்சரிக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah34