import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 12 </title>
	<meta
          name="description"
          content="Matthew 12 | மத்தேயு 12 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே, இயேசு ஓய்வுநாளில் பயிர்வழியே போனார்; அவருடைய சீஷர்கள் பசியாயிருந்து, கதிர்களைக் கொய்து, தின்னத் தொடங்கினார்கள்.
	<br /><br />
	2. பரிசேயர் அதைக்கண்டு, அவரை நோக்கி: இதோ, ஓய்வுநாளில் செய்யத்தகாததை உம்முடைய சீஷர்கள் செய்கிறார்களே என்றார்கள்.
	<br /><br />
	3. அதற்கு அவர்: தாவீதும் அவனோடிருந்தவர்களும் பசியாயிருந்தபோது செய்ததை நீங்கள் வாசிக்கவில்லையா?
	<br /><br />
	4. அவன் தேவனுடைய வீட்டில் பிரவேசித்து, ஆசாரியர் தவிர வேறொருவரும் புசிக்கத்தகாத தேவசமுகத்து அப்பங்களைத் தானும் தன்னோடிருந்தவர்களும் புசித்தார்களே.
	<br /><br />
	5. அன்றியும், ஓய்வுநாட்களில் ஆசாரியர்கள் தேவாலயத்தில் ஓய்ந்திராமல், ஓய்வுநாளை வேலை நாளாக்கினாலும், குற்றமில்லாதிருக்கிறார்கள் என்று நீங்கள் வேதத்தில் வாசிக்கவில்லையா?
	<br /><br />
	6. தேவாலயத்திலும் பெரியவர் இங்கே இருக்கிறார் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	7. பலியையல்ல இரக்கத்தையே விரும்புகிறேன் என்பதின் கருத்து இன்னதென்று அறிந்தீர்களானால், குற்றமில்லாதவர்களை நீங்கள் குற்றப்படுத்தமாட்டீர்கள்.
	<br /><br />
	8. மனுஷகுமாரன் ஓய்வுநாளுக்கும் ஆண்டவராய் இருக்கிறார் என்றார்.
	<br /><br />
	9. அவர் அவ்விடம் விட்டுப்போய், அவர்களுடைய ஜெப ஆலயத்தில் பிரவேசித்தார்.
	<br /><br />
	10. அங்கே சூம்பின கையையுடைய மனுஷன் ஒருவன் இருந்தான். அப்பொழுது, அவர்மேல் குற்றஞ்சாட்டும்படிக்கு: ஓய்வுநாளில் சொஸ்தமாக்குகிறது நியாயமா என்று கேட்டார்கள்.
	<br /><br />
	11. அதற்கு அவர்: உங்களில் எந்த மனுஷனுக்காகிலும் ஒரு ஆடு இருந்து, அது ஓய்வுநாளில் குழியிலே விழுந்தால், அதைப் பிடித்துத் தூக்கிவிடமாட்டானோ?
	<br /><br />
	12. ஆட்டைப்பார்க்கிலும் மனுஷனானவன் எவ்வளவோ விசேஷித்திருக்கிறான்! ஆதலால், ஓய்வுநாளிலே நன்மைசெய்வது நியாயந்தான் என்று சொன்னார்.
	<br /><br />
	13. பின்பு அந்த மனுஷனை நோக்கி: உன் கையை நீட்டு என்றார். அவன் நீட்டினான்; அது மறுகையைப்போல் சொஸ்தமாயிற்று.
	<br /><br />
	14. அப்பொழுது, பரிசேயர் வெளியேபோய், அவரைக் கொலைசெய்யும்படி அவருக்கு விரோதமாய் ஆலோசனைபண்ணினார்கள்.
	<br /><br />
	15. இயேசு அதை அறிந்து, அவ்விடம் விட்டு விலகிப்போனார். திரளான ஜனங்கள் அவருக்குப் பின்சென்றார்கள்; அவர்களெல்லாரையும் அவர் சொஸ்தமாக்கி,
	<br /><br />
	16. தம்மைப் பிரசித்தம்பண்ணாதபடி அவர்களுக்குக் கண்டிப்பாய்க் கட்டளையிட்டார்.
	<br /><br />
	17. ஏசாயா தீர்க்கதரிசியால் உரைக்கப்பட்டது நிறைவேறும்படி இப்படி நடந்தது. அவன் உரைத்ததாவது:
	<br /><br />
	18. இதோ, நான் தெரிந்துகொண்ட என்னுடைய தாசன், என் ஆத்துமாவுக்குப் பிரியமாயிருக்கிற என்னுடைய நேசன்; என் ஆவியை அவர்மேல் அமரப்பண்ணுவேன், அவர் புறஜாதியாருக்கு நியாயத்தை அறிவிப்பார்.
	<br /><br />
	19. வாக்குவாதம் செய்யவுமாட்டார், கூக்குரலிடவுமாட்டார்; அவருடைய சத்தத்தை ஒருவனும் வீதிகளில் கேட்பதுமில்லை.
	<br /><br />
	20. அவர் நியாயத்திற்கு ஜெயங்கிடைக்கப்பண்ணுகிறவரைக்கும், நெரிந்தநாணலை முறிக்காமலும், மங்கியெரிகிற திரியை அணைக்காமலும் இருப்பார்.
	<br /><br />
	21. அவருடைய நாமத்தின்மேல் புறஜாதியார் நம்பிக்கையாயிருப்பார்கள் என்பதே.
	<br /><br />
	22. அப்பொழுது, பிசாசு பிடித்த குருடும் ஊமையுமான ஒருவன் அவரிடத்தில் கொண்டுவரப்பட்டான்; குருடும் ஊமையுமானவன் பேசவுங் காணவுந்தக்கதாக அவனைச் சொஸ்தமாக்கினார்.
	<br /><br />
	23. ஜனங்களெல்லாரும் ஆச்சரியப்பட்டு: தாவீதின் குமாரன் இவர்தானோ? என்றார்கள்.
	<br /><br />
	24. பரிசேயர் அதைக்கேட்டு: இவன் பிசாசுகளின் தலைவனாகிய பெயெல்செபூலினாலே பிசாசுகளைத் துரத்துகிறானேயல்லாமல் மற்றப்படியல்ல என்றார்கள்.
	<br /><br />
	25. இயேசு அவர்கள் சிந்தனைகளை அறிந்து, அவர்களை நோக்கி: தனக்குத்தானே விரோதமாய்ப் பிரிந்திருக்கிற எந்த ராஜ்யமும் பாழாய்ப்போம்; தனக்குத்தானே விரோதமாய்ப் பிரிந்திருக்கிற எந்தப் பட்டணமும் எந்த வீடும் நிலைநிற்கமாட்டாது.
	<br /><br />
	26. சாத்தானைச் சாத்தான் துரத்தினால் தனக்கு விரோதமாகத் தானே பிரிவினை செய்கிறதாயிருக்குமே; அப்படிச் செய்தால் அவன் ராஜ்யம் எப்படி நிலைநிற்கும்?
	<br /><br />
	27. நான் பெயெல்செபூலினாலே பிசாசுகளைத் துரத்தினால், உங்கள் பிள்ளைகள் அவைகளை யாராலே துரத்துகிறார்கள்? ஆகையால், அவர்களே உங்களை நியாயந்தீர்க்கிறவர்களாயிருப்பார்கள்.
	<br /><br />
	28. நான் தேவனுடைய ஆவியினாலே பிசாசுகளைத் துரத்துகிறபடியால், தேவனுடைய ராஜ்யம் உங்களிடத்தில் வந்திருக்கிறதே.
	<br /><br />
	29. அன்றியும், பலவானை முந்திக் கட்டினாலொழியப் பலவானுடைய வீட்டுக்குள் ஒருவன் புகுந்து, அவன் உடைமைகளை எப்படிக் கொள்ளையிடக்கூடும்? கட்டினானேயாகில், அவன் வீட்டைக் கொள்ளையிடலாம்.
	<br /><br />
	30. என்னோடே இராதவன் எனக்கு விரோதியாயிருக்கிறான்; என்னோடே சேர்க்காதவன் சிதறடிக்கிறான்.
	<br /><br />
	31. ஆதலால், நான் உங்களுக்குச் சொல்லுகிறேன்: எந்தப் பாவமும் எந்தத் தூஷணமும் மனுஷருக்கு மன்னிக்கப்படும்; ஆவியானவருக்கு விரோதமான தூஷணமோ மனுஷருக்கு மன்னிக்கப்படுவதில்லை.
	<br /><br />
	32. எவனாகிலும் மனுஷகுமாரனுக்கு விரோதமான வார்த்தை சொன்னால் அது அவனுக்கு மன்னிக்கப்படும்; எவனாகிலும் பரிசுத்த ஆவிக்கு விரோதமாகப் பேசினால் அது இம்மையிலும் மறுமையிலும் அவனுக்கு மன்னிக்கப்படுவதில்லை.
	<br /><br />
	33. மரம் நல்லதென்றால், அதின் கனியும் நல்லதென்று சொல்லுங்கள்; மரம் கெட்டதென்றால், அதின் கனியும் கெட்டதென்று சொல்லுங்கள்; மரமானது அதின் கனியினால் அறியப்படும்.
	<br /><br />
	34. விரியன்பாம்புக் குட்டிகளே, நீங்கள் பொல்லாதவர்களாயிருக்க, நலமானவைகளை எப்படிப் பேசுவீர்கள்? இருதயத்தின் நிறைவினால் வாய் பேசும்.
	<br /><br />
	35. நல்ல மனுஷன் இருதயமாகிய நல்ல பொக்கிஷத்திலிருந்து நல்லவைகளை எடுத்துக்காட்டுகிறான், பொல்லாத மனுஷன் பொல்லாத பொக்கிஷத்திலிருந்து பொல்லாதவைகளை எடுத்துக்காட்டுகிறான்.
	<br /><br />
	36. மனுஷர் பேசும் வீணான வார்த்தைகள் யாவையும் குறித்து நியாயத்தீர்ப்புநாளிலே கணக்கொப்புவிக்கவேண்டும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	37. ஏனெனில், உன் வார்த்தைகளினாலே நீதிமான் என்று தீர்க்கப்படுவாய்; அல்லது உன் வார்த்தைகளினாலே குற்றவாளி என்று தீர்க்கப்படுவாய் என்றார்.
	<br /><br />
	38. அப்பொழுது, வேதபாரகரிலும் பரிசேயரிலும் சிலர் அவரை நோக்கி: போதகரே, உம்மால் ஒரு அடையாளத்தைக் காண விரும்புகிறோம் என்றார்கள்.
	<br /><br />
	39. அவர்களுக்கு அவர் பிரதியுத்தரமாக: இந்தப் பொல்லாத விபசாரச் சந்ததியார் அடையாளத்தைத் தேடுகிறார்கள்; ஆனாலும் யோனா தீர்க்கதரிசியின் அடையாளமேயன்றி வேறே அடையாளம் இவர்களுக்குக் கொடுக்கப்படுவதில்லை.
	<br /><br />
	40. யோனா இரவும் பகலும் மூன்றுநாள் ஒரு பெரிய மீனின் வயிற்றில் இருந்தது போல, மனுஷகுமாரனும் இரவும் பகலும் மூன்றுநாள் பூமியின் இருதயத்தில் இருப்பார்.
	<br /><br />
	41. யோனாவின் பிரசங்கத்தைக் கேட்டு நினிவே பட்டணத்தார் மனந்திரும்பினார்கள். இதோ, யோனாவிலும் பெரியவர் இங்கே இருக்கிறார். ஆதலால் நியாயத்தீர்ப்புநாளிலே நினிவே பட்டணத்தார் இந்தச் சந்ததியாரோடெழுந்து நின்று இவர்கள்மேல் குற்றஞ்சுமத்துவார்கள்.
	<br /><br />
	42. தென்தேசத்து ராஜஸ்திரீ பூமியின் எல்லைகளிலிருந்து சாலொமோனுடைய ஞானத்தைக் கேட்க வந்தாள். இதோ, சாலொமோனிலும் பெரியவர் இங்கே இருக்கிறார். ஆதலால் நியாயத்தீர்ப்புநாளிலே அந்த ராஜஸ்திரீ இந்தச் சந்ததியாரோடெழுந்து நின்று இவர்கள்மேல் குற்றஞ்சுமத்துவாள்.
	<br /><br />
	43. அசுத்த ஆவி ஒரு மனுஷனை விட்டுப் புறப்படும்போது, வறண்ட இடங்களில் அலைந்து, இளைப்பாறுதல் தேடியும் கண்டடையாமல்:
	<br /><br />
	44. நான் விட்டுவந்த என் வீட்டுக்குத் திரும்பிப்போவேன் என்று சொல்லி; அங்கே வந்து, அந்த வீடு வெறுமையாகவும், பெருக்கி, ஜோடிக்கப்பட்டதாகவும் இருக்கக்கண்டு,
	<br /><br />
	45. திரும்பிப்போய், தன்னிலும் பொல்லாத வேறு ஏழு ஆவிகளைத் தன்னோடே கூட்டிக்கொண்டுவந்து, உட்புகுந்து, அங்கே குடியிருக்கும்; அப்பொழுது, அந்த மனுஷனுடைய முன்னிலைமையிலும் அவன் பின்னிலைமை அதிக கேடுள்ளதாயிருக்கும்; அப்படியே இந்தப் பொல்லாத சந்ததியாருக்கும் சம்பவிக்கும் என்றார்.
	<br /><br />
	46. இப்படி அவர் ஜனங்களோடே பேசுகையில், அவருடைய தாயாரும் சகோதரரும் அவரிடத்தில் பேசவேண்டுமென்று வெளியே நின்றார்கள்.
	<br /><br />
	47. அப்பொழுது, ஒருவன் அவரை நோக்கி: உம்முடைய தாயாரும் உம்முடைய சகோதரரும் உம்மோடே பேசவேண்டுமென்று வெளியே நிற்கிறார்கள் என்றான்.
	<br /><br />
	48. தம்மிடத்தில் இப்படிச் சொன்னவனுக்கு அவர் பிரதியுத்தரமாக: என் தாயார் யார்? என் சகோதரர் யார்? என்று சொல்லி,
	<br /><br />
	49. தம்முடைய கையைத் தமது சீஷர்களுக்கு நேரே நீட்டி: இதோ, என் தாயும் என் சகோதரரும் இவர்களே!
	<br /><br />
	50. பரலோகத்திலிருக்கிற என் பிதாவின் சித்தத்தின்படி செய்கிறவன் எவனோ, அவனே எனக்குச் சகோதரனும் சகோதரியும் தாயுமாய் இருக்கிறான் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew12