import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah62 = () => {
  const verseNumber = 62;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 62 </title>
	<meta
          name="description"
          content="Isaiah 62 | ஏசாயா 62 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சீயோனினிமித்தமும் எருசலேமினிமித்தமும் நான் மவுனமாயிராமலும், அதின் நீதி பிரகாசத்தைப்போலவும், அதின் இரட்சிப்பு எரிகிற தீவட்டியைப்போலவும் வெளிப்படுமட்டும் அமராமலும் இருப்பேன்.
	<br /><br />
	2. ஜாதிகள் உன் நீதியையும், சகல ராஜாக்களும் உன் மகிமையையும் காண்பார்கள்; கர்த்தருடைய வாய் சொல்லும் புது நாமத்தால் நீ அழைக்கப்படுவாய்.
	<br /><br />
	3. நீ கர்த்தருடைய கையில் அலங்காரமான கிரீடமும், உன் தேவனுடைய கரத்தில் ராஜமுடியுமாயிருப்பாய்.
	<br /><br />
	4. நீ இனிக் கைவிடப்பட்டவள் என்னப்படாமலும், உன் தேசம் இனிப் பாழான தேசமென்னப்படாமலும், நீ எப்சிபா என்றும், உன் தேசம் பியூலா என்றும் சொல்லப்படும்; கர்த்தர் உன்மேல் பிரியமாயிருக்கிறார்; உன் தேசம் வாழ்க்கைப்படும்.
	<br /><br />
	5. வாலிபன் கன்னிகையை விவாகம்பண்ணுவதுபோல, உன் மக்கள் உன்னை விவாகம்பண்ணுவார்கள்; மணவாளன் மணவாட்டியின்மேல் மகிழ்ச்சியாயிருப்பதுபோல, உன் தேவன் உன்மேல் மகிழ்ச்சியாயிருப்பார்.
	<br /><br />
	6. எருசலேமே, உன் மதில்களின்மேல் பகல்முழுதும் இராமுழுதும் ஒருக்காலும் மவுனமாயிராத ஜாமக்காரரைக் கட்டளையிடுகிறேன். கர்த்தரைப் பிரஸ்தாபம்பண்ணுகிறவர்களே, நீங்கள் அமரிக்கையாயிருக்கலாகாது.
	<br /><br />
	7. அவர் எருசலேமை ஸ்திரப்படுத்தி, பூமியிலே அதைப் புகழ்ச்சியாக்கும்வரைக்கும் அவரை அமர்ந்திருக்கவிடாதிருங்கள்.
	<br /><br />
	8. இனி நான் உன் தானியத்தை உன் சத்துருக்களுக்கு ஆகாரமாகக்கொடேன்; உன் பிரயாசத்தினாலாகிய உன் திராட்சரசத்தை அந்நிய புத்திரர் குடிப்பதுமில்லையென்று கர்த்தர் தமது வலதுகரத்தின்மேலும் தமது வல்லமையுள்ள புயத்தின்மேலும் ஆணையிட்டார்.
	<br /><br />
	9. அதைச் சேர்த்தவர்களே அதைப் புசித்துக் கர்த்தரைத் துதிப்பார்கள்; அதைக் கூட்டிவைத்தவர்களே என் பரிசுத்த ஸ்தலத்தின் பிராகாரங்களில் அதைக் குடிப்பார்கள்.
	<br /><br />
	10. வாசல்கள் வழியாய்ப் பிரவேசியுங்கள், பிரவேசியுங்கள்; ஜனத்துக்கு வழியைச் செவ்வைப்படுத்துங்கள்; பாதையை உயர்த்துங்கள், உயர்த்துங்கள்; அதிலுள்ள கற்களைப் பொறுக்கிப்போடுங்கள்; ஜனங்களுக்காகக் கொடியை ஏற்றுங்கள்.
	<br /><br />
	11. நீங்கள் சீயோன் குமாரத்தியை நோக்கி: இதோ, உன் இரட்சிப்பு வருகிறது; இதோ, அவர் அருளும் பலன் அவரோடும், அவர் செய்யும் பிரதிபலன் அவர் முன்பாகவும் வருகிறது என்று சொல்லுங்கள் என்று, கர்த்தர் பூமியின் கடையாந்தரம்வரைக்கும் கூறுகிறார்.
	<br /><br />
	12. அவர்களைப் பரிசுத்த ஜனமென்றும், கர்த்தரால் மீட்கப்பட்டவர்களென்றும் சொல்லுவார்கள்; நீ தேடிக்கொள்ளப்பட்டதென்றும், கைவிடப்படாத நகரமென்றும் பெயர்பெறுவாய்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah62