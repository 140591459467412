import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Lamentations1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible News | புலம்பல் 1 </title>
    <meta
          name="description"
          content="Lamentations 1 | புலம்பல் 1 |
          Pulambal | Tamil Bible News | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஐயோ! ஜனம்பெருத்த நகரி தனிமையாக உட்கார்ந்திருக்கிறாளே! விதவைக்கு ஒப்பானாளே! ஜாதிகளில் பெரியவளும், சீமைகளில் நாயகியுமாயிருந்தவள் கப்பங்கட்டுகிறவளானாளே!
	<br /><br />
	2. இராக்காலத்திலே அழுதுகொண்டிருக்கிறாள், அவளுடைய கண்ணீர் அவள் கன்னங்களில் வடிகிறது; அவளுடைய நேசர் எல்லாருக்குள்ளும் அவளைத் தேற்றுவார் ஒருவரும் இல்லை; அவளுடைய சிநேகிதர் எல்லாரும் அவளுக்குத் துரோகிகளும் சத்துருக்களுமானார்கள்.
	<br /><br />
	3. யூதா ஜனங்கள் உபத்திரவப்படவும், கொடுமையான அடிமைவேலைசெய்யவும் சிறைப்பட்டுப்போனார்கள். அவள் புறஜாதிகளுக்குள்ளே தங்குகிறாள், இளைப்பாறுதல் அடையாள்; அவளைத் துன்பப்படுத்துகிற யாவரும் அவளை இடுக்கமான இடங்களிலே தொடர்ந்துபிடித்தார்கள்.
	<br /><br />
	4. பண்டிகைக்கு வருவார் இல்லாததினால், சீயோனுக்குப் போகிற வழிகள் புலம்புகிறது; அவள் வாசல்கள் எல்லாம் பாழாய்க்கிடக்கிறது; அவள் ஆசாரியர்கள் தவிக்கிறார்கள்; அவள் கன்னிகைகள் சஞ்சலப்படுகிறார்கள்; அவளுக்குக் கசப்பே உண்டாயிருக்கிறது.
	<br /><br />
	5. அவள் சத்துருக்கள் தலைமையானார்கள், அவள் பகைஞர் சுகித்திருக்கிறார்கள்; அவளுடைய திரளான பாதகங்களினிமித்தம் கர்த்தர் அவளைச் சஞ்சலப்படுத்தினார்; அவள் பிள்ளைகள் சத்துருவுக்கு முன்பாகச் சிறைப்பட்டுப்போனார்கள்.
	<br /><br />
	6. சீயோன் குமாரத்தியின் அழகெல்லாம் அவளை விட்டுப்போயிற்று; அவள் பிரபுக்கள் மேய்ச்சலைக் காணாத மான்களுக்கு ஒப்பாகி, தொடருகிறவனுக்கு முன்பாகச் சத்துவமில்லாமல் நடந்துபோனார்கள்.
	<br /><br />
	7. தனக்குச் சிறுமையும் தவிப்பும் உண்டாகிய நாட்களிலே எருசலேம் பூர்வநாட்கள் முதற்கொண்டு தனக்கு உண்டாயிருந்த இன்பமானவைகளையெல்லாம் நினைக்கிறாள்; அவளுக்கு உதவிசெய்வார் இல்லாமல் அவளுடைய ஜனங்கள் சத்துருவின் கையிலே விழுகையில், பகைஞர் அவளைப் பார்த்து, அவளுடைய ஓய்வுநாட்களைக் குறித்துப் பரியாசம்பண்ணினார்கள்.
	<br /><br />
	8. எருசலேம் மிகுதியாய்ப் பாவஞ்செய்தாள்; ஆதலால் தூரஸ்திரீயைப்போலானாள்; அவளைக் கனம்பண்ணினவர்கள் எல்லாரும் அவளை அசட்டைபண்ணுகிறார்கள்; அவளுடைய மானத்தைக் கண்டார்கள்; அவளும் பெருமூச்சுவிட்டுப் பின்னிட்டுத் திரும்பினாள்.
	<br /><br />
	9. அவளுடைய அசூசம் அவள் வஸ்திர ஓரங்களில் இருந்தது; தனக்கு வரப்போகிற முடிவை நினையாதிருந்தாள்; ஆகையால் அதிசயமாய்த் தாழ்த்தப்பட்டுப்போனாள்; தேற்றுவார் இல்லை; கர்த்தாவே, என் சிறுமையைப் பாரும்; பகைஞன் பெருமைபாராட்டினானே.
	<br /><br />
	10. அவளுடைய இன்பமான எல்லாவற்றின்மேலும் சத்துரு தன் கையை நீட்டினான்; உம்முடைய சபையிலே வரலாகாதென்று தேவரீர் விலக்கிய புறஜாதியார் உமது பரிசுத்த ஸ்தலத்துக்குள் பிரவேசித்ததைக் கண்டாள்.
	<br /><br />
	11. அவளுடைய ஜனங்களெல்லாரும் அப்பந்தேடித் தவிக்கிறார்கள்; தங்கள் உயிரைக் காப்பாற்றத் தங்களுடைய இன்பமானவைகளை ஆகாரத்துக்கென்று கொடுத்துவிட்டார்கள்; கர்த்தாவே, நோக்கிப்பாரும்; எண்ணமற்றவளானேனே.
	<br /><br />
	12. வழியில் நடந்துபோகிற சகல ஜனங்களே, இதைக்குறித்து உங்களுக்குக் கவையில்லையா? கர்த்தர் தமது உக்கிரமான கோபமூண்ட நாளிலே என்னைச் சஞ்சலப்படுத்தினதினால் எனக்கு உண்டான என் துக்கத்துக்குச் சரியான துக்கம் உண்டோ என்று என்னை நோக்கிப்பாருங்கள்.
	<br /><br />
	13. உயரத்திலிருந்து என் எலும்புகளில் அக்கினியை அனுப்பினார், அது அவைகளில் பற்றியெரிகிறது; என் கால்களுக்கு வலையை வீசினார்; என்னைப் பின்னிட்டு விழப்பண்ணினார்; என்னைப் பாழாக்கினார்; நித்தம் நான் பலட்சயப்பட்டுப் போகிறேன்.
	<br /><br />
	14. என் பாதகங்களின் நுகம் அவருடைய கையால் பூட்டப்பட்டிருக்கிறது; அவைகள் பிணைக்கப்பட்டு என் கழுத்தைச் சுற்றிக்கொண்டது; என் பெலனை விழப்பண்ணினார்; நான் எழுந்திருக்கக் கூடாதபடிக்கு ஆண்டவர் என்னை ஒடுக்குகிறவர்களின் கையில் ஒப்புக்கொடுத்தார்.
	<br /><br />
	15. என்னிலுள்ள பராக்கிரமசாலிகளாகிய என்னுடையவர்களெல்லாரையும் ஆண்டவர் மிதித்துப்போட்டார்; என் வாலிபரை நொறுக்கும்படி எனக்கு விரோதமாய் ஒரு கூட்டத்தை வரவழைத்தார்; திராட்சப்பழத்தை ஆலையில் மிதிக்கிறதுபோல, ஆண்டவர் யூதா குமாரத்தியாகிய கன்னிகையை மிதித்தார்.
	<br /><br />
	16. இவைகளினிமித்தம் நான் அழுகிறேன்; என் கண், என் கண்ணே நீராய்ச் சொரிகிறது; என் உயிரைக் காப்பாற்றித் தேற்றுகிறவர் என்னை விட்டுத் தூரமானார்; பகைஞன் மேற்கொண்டதினால் என் பிள்ளைகள் பாழாய்ப்போனார்கள்.
	<br /><br />
	17. சீயோன் தன் கைகளை விரிக்கிறாள்; அவளைத் தேற்றுவார் ஒருவருமில்லை; கர்த்தர் யாக்கோபின் சுற்றுப்புறத்தாரை அவனுக்குச் சத்துருக்களாகக் கட்டளையிட்டார்; அவர்களுக்குள்ளே எருசலேம் தூரஸ்திரீக்கு ஒப்பானாள்.
	<br /><br />
	18. கர்த்தர் நீதிபரர்; அவருடைய வாக்குக்கு விரோதமாய் நான் எழும்பினேன்; ஜனங்களே, நீங்கள் எல்லாரும் இதைக் கேட்டு என் துக்கத்தைப் பாருங்கள்; என் கன்னிகைகளும் என் வாலிபரும் சிறைப்பட்டுப்போனார்கள்.
	<br /><br />
	19. என்னைச் சிநேகித்தவர்களைக் கூப்பிட்டேன், அவர்களோ என்னை மோசம்போக்கினார்கள்; என் ஆசாரியர்களும் என் மூப்பர்களும் தங்கள் உயிரைக் காப்பாற்றத் தங்களுக்கு அப்பந்தேடுகையில் நகரத்தில் மூச்சொடுங்கி மாண்டார்கள்.
	<br /><br />
	20. கர்த்தாவே, பாரும், நான் நெருக்கப்படுகிறேன்; என் குடல் கொதிக்கிறது; நான் கடுந்துரோகம்பண்ணினபடியினால் என் இருதயம் வியாகுலப்படுகிறது; வெளியிலே பட்டயம் என்னைப் பிள்ளையற்றவளாக்கிற்று, வீட்டுக்குள்ளே மரணம் வந்திருக்கிறது.
	<br /><br />
	21. நான் தவிக்கிறதை அவர்கள் கேட்டாலும் என்னைத் தேற்றுவார் ஒருவரும் இல்லை; என் பகைஞர் எல்லாரும் எனக்கு வந்த ஆபத்தைக் கேட்டு, தேவரீர் அதைச் செய்தபடியால் சந்தோஷமாயிருக்கிறார்கள்; நீர் கூறின நாளை வரப்பண்ணுவீர், அப்பொழுது அவர்களும் என்னைப்போலாவார்கள்.
	<br /><br />
	22. அவர்களுடைய பொல்லாப்பெல்லாம் உமது முகத்துக்கு முன்பாக வரக்கடவது. என் சகல பாதகங்களினிமித்தமும் நீர் எனக்குச் செய்ததுபோல அவர்களுக்கும் செய்யும்; என் பெருமூச்சுகள் மிகுதியாயின, என் இருதயம் பலட்சயமாயிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Lamentations1