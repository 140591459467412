import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title> Warning against falling away Hebrews Tamil Bible | எபிரெயர் 6 </title>
	<meta
          name="description"
          content="Warning against falling away Hebrews Chapter-6 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-6 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* விழுந்து விடாமல் எச்சரிக்கை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. ஆகையால், கிறிஸ்துவைப்பற்றிச் சொல்லிய மூல உபதேச வசனங்களை நாம் விட்டு, செத்த கிரியைகளுக்கு நீங்கலாகும் மனந்திரும்புதல், தேவன்பேரில் வைக்கும் விசுவாசம்,
	<br /><br />
	2. ஸ்நானங்களுக்கடுத்த உபதேசம், கைகளை வைக்குதல், மரித்தோரின் உயிர்த்தெழுதல், நித்திய நியாயத்தீர்ப்பு என்பவைகளாகிய அஸ்திபாரத்தை மறுபடியும் போடாமல், பூரணராகும்படி கடந்துபோவோமாக.
	<br /><br />
	3. தேவனுக்குச் சித்தமானால் இப்படியே செய்வோம்.
	<br /><br />
	4. ஏனெனில், ஒருதரம் பிரகாசிப்பிக்கப்பட்டும், பரமஈவை ருசிபார்த்தும், பரிசுத்த ஆவியைப் பெற்றும்,
	<br /><br />
	5. தேவனுடைய நல்வார்த்தையையும் இனிவரும் உலகத்தின் பெலன்களையும் ருசிபார்த்தும்,
	<br /><br />
	6. மறுதலித்துப்போனவர்கள், தேவனுடைய குமாரனைத் தாங்களே மறுபடியும் சிலுவையில் அறைந்து அவமானப்படுத்துகிறபடியால், மனந்திரும்புதற்கேதுவாய் அவர்களை மறுபடியும் புதுப்பிக்கிறது கூடாதகாரியம்.
	<br /><br />
	7. எப்படியெனில், தன்மேல் அடிக்கடி பெய்கிற மழையைக் குடித்து, தன்னிடத்தில் பயிரிடுகிறவர்களுக்கேற்ற பயிரை முளைப்பிக்கும் நிலமானது தேவனால் ஆசீர்வாதம் பெறும்.
	<br /><br />
	8. முள்செடிகளையும் முள்பூண்டுகளையும் முளைப்பிக்கிற நிலமோ தகாததாயும் சபிக்கப்படுகிறதற்கேற்றதாயுமிருக்கிறது; சுட்டெரிக்கப்படுவதே அதின் முடிவு.
	<br /><br />
	9. பிரியமானவர்களே, நாங்கள் இப்படிச் சொன்னாலும், நன்மையானவைகளும் இரட்சிப்புக்குரியவைகளுமான காரியங்கள் உங்களிடத்தில் உண்டாயிருக்கிறதென்று நம்பியிருக்கிறோம்.
	<br /><br />
	10. ஏனென்றால், உங்கள் கிரியையையும், நீங்கள் பரிசுத்தவான்களுக்கு ஊழியஞ்செய்ததினாலும் செய்து வருகிறதினாலும் தமது நாமத்திற்காகக் காண்பித்த அன்புள்ள பிரயாசத்தையும் மறந்துவிடுகிறதற்குத் தேவன் அநீதியுள்ளவரல்லவே.
	<br /><br />
	11. நீங்கள் அசதியாயிராமல், வாக்குத்தத்தமான ஆசீர்வாதங்களை விசுவாசத்தினாலும் நீடிய பொறுமையினாலும் சுதந்தரித்துக்கொள்ளுகிறவர்களைப் பின்பற்றுகிறவர்களாயிருந்து,
	<br /><br />
	12. உங்களுக்கு நம்பிக்கையின் பூரண நிச்சயமுண்டாகும்படி நீங்கள் யாவரும் முடிவுபரியந்தம் அப்படியே ஜாக்கிரதையைக் காண்பிக்கவேண்டுமென்று ஆசையாயிருக்கிறோம்.
	<br /><br />
	13. ஆபிரகாமுக்குத் தேவன் வாக்குத்தத்தம்பண்ணினபோது, ஆணையிடும்படி தம்மிலும் பெரியவர் ஒருவருமில்லாதபடியினாலே தமதுபேரிலே தானே ஆணையிட்டு:
	<br /><br />
	14. நிச்சயமாக நான் உன்னை ஆசீர்வதிக்கவே ஆசீர்வதித்து, உன்னைப் பெருகவே பெருகப்பண்ணுவேன் என்றார்.
	<br /><br />
	15. அந்தப்படியே, அவன் பொறுமையாய்க் காத்திருந்து, வாக்குத்தத்தம்பண்ணப்பட்டதைப் பெற்றான்.
	<br /><br />
	16. மனுஷர் தங்களிலும் பெரியவர்பேரில் ஆணையிடுவார்கள்; உறுதிபண்ணும்படிக்கு ஆணையிடுதலே சகல விவாதத்திற்கும் முடிவு.
	<br /><br />
	17. அந்தப்படி, தேவனும் வாக்குத்தத்தம்பண்ணப்பட்டவைகளைச் சுதந்தரித்துக்கொள்ளுகிறவர்களுக்குத் தமது ஆலோசனையின் மாறாத நிச்சயத்தைப் பரிபூரணமாய்க் காண்பிக்கும்படி சித்தமுள்ளவராய், ஓர் ஆணையினாலே அதை ஸ்திரப்படுத்தினார்.
	<br /><br />
	18. நமக்கு முன் வைக்கப்பட்ட நம்பிக்கையைப் பற்றிக்கொள்ளும்படி அடைக்கலமாய் ஓடிவந்த நமக்கு இரண்டு மாறாத விசேஷங்களினால் நிறைந்த ஆறுதலுண்டாகும்படிக்கு எவ்வளவேனும் பொய்யுரையாத தேவன் அப்படிச் செய்தார்.
	<br /><br />
	19. அந்த நம்பிக்கை நமக்கு நிலையும் உறுதியும் திரைக்குள்ளாகப் போகிறதுமான ஆத்தும நங்கூரமாயிருக்கிறது.
	<br /><br />
	20. நமக்கு முன்னோடினவராகிய இயேசு மெல்கிசேதேக்கின் முறைமையின்படி நித்திய பிரதான ஆசாரியராய் நமக்காக அந்தத் திரைக்குள் பிரவேசித்திருக்கிறார்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* சத்தியத்தை விட்டு விலகி நிந்திக்கப்படுவதற்கு எதிராக ஒரு எச்சரிக்கையாக செயல்படுகிறது. கீழே விழுந்து மனந்திரும்புவதற்கு அது சாத்தியமற்றது, ஏனென்றால் அது கிறிஸ்துவை மீண்டும் அவமானத்திற்கு உட்படுத்துகிறது. இருப்பினும், கிறிஸ்துவுடன் தொடர்ந்து வாழும் நமக்கு இரட்சிப்புடன் சிறந்த விஷயங்கள் உள்ளன. கிறிஸ்து நமக்குத் திறந்துவிட்ட திரையின் வழியாக அந்த உறுதியான நம்பிக்கையை நோக்கி நாம் செல்லலாம். *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews6