import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Galatians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | கலாத்தியருக்கு எழுதின நிருபம் 2 </title>
	<meta
          name="description"
          content="Galatians | கலாத்தியர் அதிகாரம் 2 | கலாத்தியருக்கு எழுதின நிருபம் |
          Book of Galatians | Chapter 2 | Tamil Bible Verse | Tamil Bible | புதிய ஏற்பாடு | TamilBible | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பதினாலு வருஷம் சென்றபின்பு, நான் தீத்துவைக் கூட்டிக்கொண்டு பர்னபாவுடனேகூட மறுபடியும் எருசலேமுக்குப் போனேன்.
	<br /><br />
	2. நான் தேவ அறிவிப்பினாலே போய், புறஜாதிகளிடத்தில் நான் பிரசங்கிக்கிற சுவிசேஷத்தை அவர்களுக்கு விவரித்துக் காண்பித்தேன்; ஆயினும் நான் ஓடுகிறதும், ஓடினதும் வீணாகாதபடிக்கு எண்ணிக்கையுள்ளவர்களுக்கே தனிமையாய் விவரித்துக் காண்பித்தேன்.
	<br /><br />
	3. ஆனாலும் என்னுடனேகூட இருந்த தீத்து கிரேக்கனாயிருந்தும் விருத்தசேதனம்பண்ணிக்கொள்ளும்படிக்குக் கட்டாயம்பண்ணப்படவில்லை.
	<br /><br />
	4. கிறிஸ்து இயேசுவுக்குள் நமக்கு உண்டான சுயாதீனத்தை உளவுபார்த்து நம்மை நியாயப்பிரமாணத்திற்கு அடிமைகளாக்கும்பொருட்டாகப் பக்கவழியாய் நுழைந்த கள்ளச் சகோதரர் நிமித்தம் அப்படியாயிற்று.
	<br /><br />
	5. சுவிசேஷத்தின் சத்தியம் உங்களிடத்திலே நிலைத்திருக்கும்படி, நாங்கள் ஒரு நாழிகையாகிலும் அவர்களுக்குக் கீழ்ப்படிந்து இணங்கவில்லை.
	<br /><br />
	6. அல்லாமலும் எண்ணிக்கையுள்ளவர்களாயிருந்தவர்கள் எனக்கு ஒன்றும் போதிக்கவில்லை; அவர்கள் எப்படிப்பட்டவர்களாயிருந்தாலும் எனக்குக் கவலையில்லை, தேவன் மனுஷரிடத்தில் பட்சபாதமுள்ளவரல்லவே.
	<br /><br />
	7. அதுவுமல்லாமல், விருத்தசேதனமுள்ளவர்களுக்கு அப்போஸ்தலனாயிருக்கும்படி பேதுருவைப் பலப்படுத்தினவர் புறஜாதிகளுக்கு அப்போஸ்தலனாயிருக்கும்படி என்னையும் பலப்படுத்தினபடியால்,
	<br /><br />
	8. விருத்தசேதனமுள்ளவர்களுக்குச் சுவிசேஷத்தைப் பிரசங்கிக்கும்படி பேதுருவுக்குக் கையளிக்கப்பட்டதுபோல, விருத்தசேதனம் இல்லாதவர்களுக்குப் பிரசங்கிக்கும்படி அது எனக்கும் கையளிக்கப்பட்டதென்று அவர்கள் கண்டு;
	<br /><br />
	9. எனக்கு அளிக்கப்பட்ட கிருபையை அறிந்தபோது, தூண்களாக எண்ணப்பட்ட யாக்கோபும், கேபாவும், யோவானும், தாங்கள் விருத்தசேதனமுள்ளவர்களுக்கும், நாங்கள் புறஜாதிகளுக்கும் பிரசங்கிக்கும்படி, அந்நியோந்நிய ஐக்கியத்திற்கு அடையாளமாக எனக்கும் பர்னபாவுக்கும் வலதுகை கொடுத்து,
	<br /><br />
	10. தரித்திரரை நினைத்துக்கொள்ளும்படிக்குமாத்திரம் சொன்னார்கள்; அப்படிச் செய்யும்படி அதற்கு முன்னமே நானும் கருத்துள்ளவனாயிருந்தேன்.
	<br /><br />
	11. மேலும், பேதுரு அந்தியோகியாவுக்கு வந்தபோது, அவன்மேல் குற்றஞ் சுமந்ததினால், நான் முகமுகமாய் அவனோடே எதிர்த்தேன்.
	<br /><br />
	12. எப்படியெனில், யாக்கோபினிடத்திலிருந்து சிலர் வருகிறதற்குமுன்னே அவன் புறஜாதியாருடனே சாப்பிட்டான்; அவர்கள் வந்தபோதோ, விருத்தசேதனமுள்ளவர்களுக்குப் பயந்து, விலகிப் பிரிந்தான்.
	<br /><br />
	13. மற்ற யூதரும் அவனுடனேகூட மாயம்பண்ணினார்கள்; அவர்களுடைய மாயத்தினாலே பர்னபாவும் இழுப்புண்டான்.
	<br /><br />
	14. இப்படி அவர்கள் சுவிசேஷத்தின் சத்தியத்திற்கேற்றபடி சரியாய் நடவாததை நான் கண்டபோது, எல்லாருக்கும் முன்பாக நான் பேதுருவை நோக்கிச் சொன்னது என்னவென்றால்: யூதனாயிருக்கிற நீர் யூதர் முறைமையாக நடவாமல், புறஜாதியார் முறைமையாக நடந்துகொண்டிருக்க, புறஜாதியாரை யூதர் முறைமையாக நடக்கும்படி நீர் எப்படிக் கட்டாயம்பண்ணலாம்?
	<br /><br />
	15. புறஜாதியாரில் பிறந்த பாவிகளாயிராமல், சுபாவத்தின்படி யூதராயிருக்கிற நாமும் இயேசுகிறிஸ்துவைப்பற்றும் விசுவாசத்தினாலேயன்றி, நியாயப்பிரமாணத்தின் கிரியைகளினாலே மனுஷன் நீதிமானாக்கப்படுவதில்லையென்று அறிந்து, நியாயப்பிரமாணத்தின் கிரியைகளினாலல்ல, கிறிஸ்துவைப்பற்றும் விசுவாசத்தினாலே நீதிமான்களாக்கப்படும்படிக்குக் கிறிஸ்து இயேசுவின்மேல் விசுவாசிகளானோம்.
	<br /><br />
	16. நியாயப்பிரமாணத்தின் கிரியைகளினாலே எந்த மனுஷனும் நீதிமானாக்கப்படுவதில்லையே.
	<br /><br />
	17. கிறிஸ்துவுக்குள் நீதிமான்களாக்கப்படும்படி நாடுகிற நாமும் பாவிகளாகக் காணப்படுவோமானால், கிறிஸ்து பாவத்திற்குக் காரணரோ? அல்லவே.
	<br /><br />
	18. நான் இடித்துப்போட்டவைகளையே நான் மறுபடியும் கட்டினால், பிரமாணத்தை மீறுகிறவனென்று காணப்படுவேன்.
	<br /><br />
	19. தேவனுக்கென்று பிழைக்கும்படி நான் நியாயப்பிரமாணத்தினாலே நியாயப்பிரமாணத்திற்கு மரித்தேனே.
	<br /><br />
	20. கிறிஸ்துவுடனேகூடச் சிலுவையிலறையப்பட்டேன்; ஆயினும், பிழைத்திருக்கிறேன்; இனி நான் அல்ல, கிறிஸ்துவே எனக்குள் பிழைத்திருக்கிறார்; நான் இப்பொழுது மாம்சத்தில் பிழைத்திருக்கிறதோ, என்னில் அன்புகூர்ந்து எனக்காகத் தம்மைத்தாமே ஒப்புக்கொடுத்த தேவனுடைய குமாரனைப்பற்றும் விசுவாசத்தினாலே பிழைத்திருக்கிறேன்.
	<br /><br />
	21. நான் தேவனுடைய கிருபையை விருதாவாக்குகிறதில்லை; நீதியானது நியாயப்பிரமாணத்தினாலே வருமானால், கிறிஸ்து மரித்தது வீணாயிருக்குமே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Galatians2