import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 21 </title>
	<meta
          name="description"
          content="Matthew 21 | மத்தேயு 21 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர்கள் எருசலேமுக்குச் சமீபமாய்ச் சேர்ந்து, ஒலிவமலைக்கு அருகான பெத்பகேயுக்கு வந்தபோது, இயேசுவானவர் சீஷரில் இரண்டுபேரை நோக்கி:
	<br /><br />
	2. உங்களுக்கு எதிரே இருக்கிற கிராமத்துக்குப் போங்கள்; போனவுடனே, அங்கே ஒரு கழுதையையும் அதனோடே ஒரு குட்டியையும் கட்டியிருக்கக் காண்பீர்கள்; அவைகளை அவிழ்த்து என்னிடத்தில் கொண்டுவாருங்கள்.
	<br /><br />
	3. ஒருவன் உங்களுக்கு ஏதாகிலும் சொன்னால்: இவைகள் ஆண்டவருக்கு வேண்டுமென்று சொல்லுங்கள்; உடனே அவைகளை அனுப்பிவிடுவான் என்று சொல்லி, அவர்களை அனுப்பினார்.
	<br /><br />
	4. இதோ, உன் ராஜா சாந்தகுணமுள்ளவராய், கழுதையின்மேலும் கழுதைக்குட்டியாகிய மறியின்மேலும் ஏறிக்கொண்டு, உன்னிடத்தில் வருகிறார் என்று சீயோன் குமாரத்திக்குச் சொல்லுங்கள் என்று,
	<br /><br />
	5. தீர்க்கதரிசியினால் உரைக்கப்பட்டது நிறைவேறும்படி இதெல்லாம் நடந்தது.
	<br /><br />
	6. சீஷர்கள் போய், இயேசு தங்களுக்குக் கட்டளையிட்டபடியே செய்து,
	<br /><br />
	7. கழுதையையும் குட்டியையும் கொண்டுவந்து, அவைகள்மேல் தங்கள் வஸ்திரங்களைப் போட்டு, அவரை ஏற்றினார்கள்.
	<br /><br />
	8. திரளான ஜனங்கள் தங்கள் வஸ்திரங்களை வழியிலே விரித்தார்கள்; வேறு சிலர் மரக்கிளைகளைத் தறித்து வழியிலே பரப்பினார்கள்.
	<br /><br />
	9. முன்நடப்பாரும் பின்நடப்பாருமாகிய திரளான ஜனங்கள்: தாவீதின் குமாரனுக்கு ஓசன்னா! கர்த்தரின் நாமத்தினாலே வருகிறவர் ஸ்தோத்திரிக்கப்பட்டவர், உன்னதத்திலே ஓசன்னா என்று சொல்லி ஆர்ப்பரித்தார்கள்.
	<br /><br />
	10. அவர் எருசலேமுக்குள் பிரவேசிக்கையில், நகரத்தார் யாவரும் ஆச்சரியப்பட்டு, இவர் யார்? என்று விசாரித்தார்கள்.
	<br /><br />
	11. அதற்கு ஜனங்கள்: இவர் கலிலேயாவிலுள்ள நாசரேத்திலிருந்து வந்த தீர்க்கதரிசியாகிய இயேசு என்றார்கள்.
	<br /><br />
	12. இயேசு தேவாலயத்தில் பிரவேசித்து, ஆலயத்திலே விற்கிறவர்களும் கொள்ளுகிறவர்களுமாகிய யாவரையும் வெளியே துரத்தி, காசுக்காரருடைய பலகைகளையும் புறா விற்கிறவர்களின் ஆசனங்களையும் கவிழ்த்து:
	<br /><br />
	13. என்னுடைய வீடு ஜெபவீடு என்னப்படும் என்று எழுதியிருக்கிறது; நீங்களோ அதைக் கள்ளர் குகையாக்கினீர்கள் என்றார்.
	<br /><br />
	14. அப்பொழுது, குருடரும் சப்பாணிகளும் தேவாலயத்திலே அவரிடத்திற்கு வந்தார்கள், அவர்களைச் சொஸ்தமாக்கினார்.
	<br /><br />
	15. அவர் செய்த அதிசயங்களையும், தாவீதின் குமாரனுக்கு ஓசன்னா! என்று தேவாலயத்திலே ஆர்ப்பரிக்கிற பிள்ளைகளையும், பிரதான ஆசாரியரும் வேதபாரகரும் கண்டு, கோபமடைந்து,
	<br /><br />
	16. அவரை நோக்கி: இவர்கள் சொல்லுகிறதைக் கேட்கிறீரோ என்றார்கள். அதற்கு இயேசு: ஆம், கேட்கிறேன். குழந்தைகளுடைய வாயினாலும் பாலகருடைய வாயினாலும் துதி உண்டாகும்படி செய்தீர் என்பதை நீங்கள் ஒருக்காலும் வாசிக்கவில்லையா என்றார்.
	<br /><br />
	17. அவர்களை விட்டு நகரத்திலிருந்து புறப்பட்டு, பெத்தானியாவுக்குப் போய், அங்கே இராத்தங்கினார்.
	<br /><br />
	18. காலையிலே அவர் நகரத்துக்குத் திரும்பிவருகையில், அவருக்குப் பசியுண்டாயிற்று.
	<br /><br />
	19. அப்பொழுது வழியருகே ஒரு அத்திமரத்தைக் கண்டு, அதனிடத்திற் போய், அதிலே இலைகளையன்றி வேறொன்றையுங் காணாமல்: இனி ஒருக்காலும் உன்னிடத்தில் கனி உண்டாகாதிருக்கக்கடவது என்றார்; உடனே அத்திமரம் பட்டுப்போயிற்று.
	<br /><br />
	20. சீஷர்கள் அதைக் கண்டு: இந்த அத்திமரம் எத்தனை சீக்கிரமாய்ப் பட்டுப்போயிற்று! என்று சொல்லி ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	21. இயேசு அவர்களை நோக்கி: நீங்கள் சந்தேகப்படாமல் விசுவாசமுள்ளவர்களாயிருந்தால், இந்த அத்திமரத்திற்குச் செய்ததை நீங்கள் செய்வதுமல்லாமல், இந்த மலையைப்பார்த்து: நீ பெயர்ந்து சமுத்திரத்திலே தள்ளுண்டுபோ என்று சொன்னாலும் அப்படியாகும் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	22. மேலும், நீங்கள் விசுவாசமுள்ளவர்களாய் ஜெபத்திலே எவைகளைக் கேட்பீர்களோ அவைகளையெல்லாம் பெறுவீர்கள் என்றார்.
	<br /><br />
	23. அவர் தேவாலயத்தில் வந்து, உபதேசம்பண்ணுகையில், பிரதான ஆசாரியரும் ஜனத்தின் மூப்பரும் அவரிடத்தில் வந்து: நீர் எந்த அதிகாரத்தினால் இவைகளைச் செய்கிறீர்? இந்த அதிகாரத்தை உமக்குக் கொடுத்தவர் யார்? என்று கேட்டார்கள்.
	<br /><br />
	24. இயேசு பிரதியுத்தரமாக: நானும் உங்களிடத்தில் ஒரு காரியத்தைக் கேட்கிறேன், அதை எனக்குச் சொல்லுவீர்களானால், நானும் இன்ன அதிகாரத்தினாலே இவைகளைச் செய்கிறேனென்று உங்களுக்குச் சொல்லுவேன்.
	<br /><br />
	25. யோவான் கொடுத்த ஸ்நானம் தேவனால் உண்டாயிற்றோ? மனுஷரால் உண்டாயிற்றோ? யாரால் உண்டாயிற்று? என்று கேட்டார். அதற்கு அவர்கள்: தேவனால் உண்டாயிற்றென்று சொல்வோமானால், பின்னை ஏன் அவனை விசுவாசிக்கவில்லை என்று நம்மைக் கேட்பார்;
	<br /><br />
	26. மனுஷரால் உண்டாயிற்றென்று சொல்வோமானால், ஜனங்களுக்குப் பயப்படுகிறோம், எல்லாரும் யோவானைத் தீர்க்கதரிசியென்று எண்ணுகிறார்களே என்று, தங்களுக்குள்ளே ஆலோசனைபண்ணி,
	<br /><br />
	27. இயேசுவுக்குப் பிரதியுத்தரமாக: எங்களுக்குத் தெரியாது என்றார்கள். அப்பொழுது, அவர்: நானும் இன்ன அதிகாரத்தினால் இவைகளைச் செய்கிறேனென்று உங்களுக்குச் சொல்லேன் என்றார்.
	<br /><br />
	28. ஆயினும் உங்களுக்கு எப்படித் தோன்றுகிறது? ஒரு மனுஷனுக்கு இரண்டு குமாரர் இருந்தார்கள்; மூத்தவனிடத்தில் அவன் வந்து: மகனே, நீ போய் இன்றைக்கு என் திராட்சத்தோட்டத்தில் வேலைசெய் என்றான்.
	<br /><br />
	29. அதற்கு அவன்: மாட்டேன் என்றான்; ஆகிலும், பின்பு அவன் மனஸ்தாபப்பட்டுப் போனான்.
	<br /><br />
	30. இளையவனிடத்திலும் அவன் வந்து, அப்படியே சொன்னான்; அதற்கு அவன்: போகிறேன் ஐயா, என்று சொல்லியும், போகவில்லை.
	<br /><br />
	31. இவ்விருவரில் எவன் தகப்பனுடைய சித்தத்தின்படி செய்தவன் என்று கேட்டார்; அதற்கு அவர்கள்: மூத்தவன்தான் என்றார்கள். அப்பொழுது, இயேசு அவர்களை நோக்கி: ஆயக்காரரும் வேசிகளும் உங்களுக்கு முன்னே தேவனுடைய ராஜ்யத்தில் பிரவேசிக்கிறார்கள் என்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	32. ஏனென்றால், யோவான் நீதிமார்க்கமாய் உங்களிடத்தில் வந்திருந்தும், நீங்கள் அவனை விசுவாசிக்கவில்லை; ஆயக்காரரும் வேசிகளுமோ அவனை விசுவாசித்தார்கள்; அதை நீங்கள் கண்டும், அவனை விசுவாசிக்கும்படியாகப் பின்பாகிலும் மனஸ்தாபப்படவில்லை என்றார்.
	<br /><br />
	33. வேறொரு உவமையைக் கேளுங்கள்: வீட்டெஜமானாகிய ஒரு மனுஷன் இருந்தான், அவன் ஒரு திராட்சத்தோட்டத்தை உண்டாக்கி, அதைச் சுற்றிலும் வேலியடைத்து, அதில் ஒரு ஆலையை நாட்டி, கோபுரத்தையும் கட்டி, தோட்டக்காரருக்கு அதைக் குத்தகையாக விட்டு, புறதேசத்துக்குப் போயிருந்தான்.
	<br /><br />
	34. கனிகாலம் சமீபித்தபோது, அதின் கனிகளை வாங்கிக்கொண்டுவரும்படி தன் ஊழியக்காரரைத் தோட்டக்காரரிடத்தில் அனுப்பினான்.
	<br /><br />
	35. தோட்டக்காரர் அந்த ஊழியக்காரரைப்பிடித்து, ஒருவனை அடித்து, ஒருவனைக் கொலைசெய்து, ஒருவனைக் கல்லெறிந்து கொன்றார்கள்.
	<br /><br />
	36. பின்னும் அவன் முந்தினவர்களிலும் அதிகமான வேறே ஊழியக்காரரை அனுப்பினான்; அவர்களையும் அப்படியே செய்தார்கள்.
	<br /><br />
	37. கடைசியிலே அவன்: என் குமாரனுக்கு அஞ்சுவார்கள் என்று சொல்லி, தன் குமாரனை அவர்களிடத்தில் அனுப்பினான்.
	<br /><br />
	38. தோட்டக்காரர் குமாரனைக் கண்டபோது: இவன் சுதந்தரவாளி; இவனைக் கொன்று, இவன் சுதந்தரத்தைக் கட்டிக்கொள்வோம் வாருங்கள் என்று ஒருவரோடொருவர் சொல்லிக்கொண்டு;
	<br /><br />
	39. அவனைப் பிடித்துத் திராட்சத்தோட்டத்திற்குப் புறம்பே தள்ளிக் கொலைசெய்தார்கள்.
	<br /><br />
	40. அப்படியிருக்க, திராட்சத்தோட்டத்தின் எஜமான் வரும்போது, அந்தத் தோட்டக்காரரை என்ன செய்வான் என்று கேட்டார்.
	<br /><br />
	41. அதற்கு அவர்கள்: அந்தக் கொடியரைக் கொடுமையாய் அழித்து, ஏற்றகாலங்களில் தனக்குக் கனிகளைக் கொடுக்கத்தக்க வேறே தோட்டக்காரரிடத்தில் திராட்சத்தோட்டத்தைக் குத்தகையாகக் கொடுப்பான் என்றார்கள்.
	<br /><br />
	42. இயேசு அவர்களை நோக்கி: வீடுகட்டுகிறவர்கள் ஆகாதென்று தள்ளின கல்லே மூலைக்குத் தலைக்கல்லாயிற்று, அது கர்த்தராலே ஆயிற்று, அது நம்முடைய கண்களுக்கு ஆச்சரியமாயிருக்கிறது என்று நீங்கள் வேதத்தில் ஒருக்காலும் வாசிக்கவில்லையா?
	<br /><br />
	43. ஆகையால், தேவனுடைய ராஜ்யம் உங்களிடத்திலிருந்து நீக்கப்பட்டு, அதற்கேற்ற கனிகளைத் தருகிற ஜனங்களுக்குக் கொடுக்கப்படும்.
	<br /><br />
	44. இந்தக் கல்லின்மேல் விழுகிறவன் நொறுங்கிப்போவான்; இது எவன்மேல் விழுமோ அவனை நசுக்கிப்போடும் என்று நான் உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	45. பிரதான ஆசாரியரும் பரிசேயரும் அவருடைய உவமைகளைக் கேட்டு, தங்களைக்குறித்துச் சொல்லுகிறார் என்று அறிந்து,
	<br /><br />
	46. அவரைப் பிடிக்க வகைதேடினார்கள்; ஆகிலும் ஜனங்கள் அவரைத் தீர்க்கதரிசியென்று எண்ணினபடியினால் அவர்களுக்குப் பயந்திருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew21