import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Romans4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | Book of Romans 4 </title>
	<meta
          name="description"
          content="Book of Romans | Holy Bible Tamil | ரோமாபுரியாருக்கு எழுதின நிருபம் | அதிகாரம் 4
          Tamil Bible Verse | Tamil Bible | Bible | Bible Verse | புதிய ஏற்பாடு | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அப்படியானால், நம்முடைய தகப்பனாகிய ஆபிரகாம் மாம்சத்தின்படி என்னத்தைக் கண்டடைந்தான் என்று சொல்லுவோம்?
	<br /><br />
	2. ஆபிரகாம் கிரியைகளினாலே நீதிமானாக்கப்பட்டானாகில் மேன்மைபாராட்ட அவனுக்கு ஏதுவுண்டு; ஆகிலும் தேவனுக்குமுன்பாக மேன்மைபாராட்ட ஏதுவில்லை.
	<br /><br />
	3. வேதவாக்கியம் என்ன சொல்லுகிறது? ஆபிரகாம் தேவனை விசுவாசித்தான், அது அவனுக்கு நீதியாக எண்ணப்பட்டது என்று சொல்லுகிறது.
	<br /><br />
	4. கிரியை செய்கிறவனுக்கு வருகிற கூலி கிருபையென்றெண்ணப்படாமல், கடனென்றெண்ணப்படும்.
	<br /><br />
	5. ஒருவன் கிரியை செய்யாமல் பாவியை நீதிமானாக்குகிறவரிடத்தில் விசுவாசம் வைக்கிறவனாயிருந்தால், அவனுடைய விசுவாசமே அவனுக்கு நீதியாக எண்ணப்படும்.
	<br /><br />
	6. அந்தப்படி, கிரியைகளில்லாமல் தேவனாலே நீதிமானென்றெண்ணப்படுகிற மனுஷனுடைய பாக்கியத்தைக் காண்பிக்கும் பொருட்டு:
	<br /><br />
	7. எவர்களுடைய அக்கிரமங்கள் மன்னிக்கப்பட்டதோ, எவர்களுடைய பாவங்கள் மூடப்பட்டதோ, அவர்கள் பாக்கியவான்கள்.
	<br /><br />
	8. எவனுடைய பாவத்தைக் கர்த்தர் எண்ணாதிருக்கிறாரோ, அவன் பாக்கியவான் என்று தாவீது சொல்லியிருக்கிறான்.
	<br /><br />
	9. இந்தப் பாக்கியம் விருத்தசேதனமுள்ளவனுக்குமாத்திரம் வருமோ, விருத்தசேதனமில்லாதவனுக்கும் வருமோ? ஆபிரகாமுக்கு விசுவாசம் நீதியாக எண்ணப்பட்டது என்று சொல்லுகிறோமே.
	<br /><br />
	10. அது எப்பொழுது அவனுக்கு அப்படி எண்ணப்பட்டது? அவன் விருத்தசேதனமுள்ளவனாயிருந்தபோதோ, விருத்தசேதனமில்லாதவனாயிருந்தபோதோ? விருத்தசேதனமுள்ளவனாயிருந்தபோதல்ல, விருத்தசேதனமில்லாதவனாயிருந்தபோதே.
	<br /><br />
	11. மேலும், விருத்தசேதனமில்லாத காலத்தில் அவன் விசுவாசத்தினாலே அடைந்த நீதிக்கு முத்திரையாக விருத்தசேதனமாகிய அடையாளத்தைப் பெற்றான். விருத்தசேதனமில்லாதவர்களாய் விசுவாசிக்கிற யாவருக்கும் நீதி எண்ணப்படும்பொருட்டாக அவர்களுக்கு அவன் தகப்பனாயிருக்கும்படிக்கும்,
	<br /><br />
	12. விருத்தசேதனத்தைப் பெற்றவர்களாய்மாத்திரமல்ல, நம்முடைய தகப்பனாகிய ஆபிரகாம் விருத்தசேதனமில்லாத காலத்தில் அடைந்த விசுவாசமாகிய அடிச்சுவடுகளில் நடக்கிறவர்களாயுமிருக்கிறவர்களுக்குத் தகப்பனாயிருக்கும்படிக்கும், அந்த அடையாளத்தைப் பெற்றான்.
	<br /><br />
	13. அன்றியும், உலகத்தைச் சுதந்தரிப்பான் என்கிற வாக்குத்தத்தம் ஆபிரகாமுக்காவது அவன் சந்ததிக்காவது நியாயப்பிரமாணத்தினால் கிடையாமல் விசுவாசத்தினால் வருகிற நீதியினால் கிடைத்தது.
	<br /><br />
	14. நியாயப்பிரமாணத்தைச் சார்ந்தவர்கள் சுதந்தரவாளிகளானால் விசுவாசம் வீணாய்ப்போம், வாக்குத்தத்தமும் அவமாகும்.
	<br /><br />
	15. மேலும் நியாயப்பிரமாணம் கோபாக்கினையை உண்டாக்குகிறது, நியாயப்பிரமாணமில்லாவிட்டால் மீறுதலுமில்லை.
	<br /><br />
	16. ஆதலால், சுதந்தரமானது கிருபையினால் உண்டாகிறதாயிருக்கும்படிக்கு அது விசுவாசத்தினாலே வருகிறது; நியாயப்பிரமாணத்தைச் சார்ந்தவர்களாகிய சந்ததியாருக்குமாத்திரமல்ல, நம்மெல்லாருக்கும் தகப்பனாகிய ஆபிரகாமுடைய விசுவாசத்தைச் சார்ந்தவர்களான எல்லாச் சந்ததியாருக்கும் அந்த வாக்குத்தத்தம் நிச்சயமாயிருக்கும்படிக்கு அப்படி வருகிறது.
	<br /><br />
	17. அநேக ஜாதிகளுக்கு உன்னைத் தகப்பனாக ஏற்படுத்தினேன் என்று எழுதியிருக்கிறபடி, அவன் தான் விசுவாசித்தவருமாய், மரித்தோரை உயிர்ப்பித்து, இல்லாதவைகளை இருக்கிறவைகளைப்போல் அழைக்கிறவருமாயிருக்கிற தேவனுக்கு முன்பாக நம்மெல்லாருக்கும் தகப்பனானான்.
	<br /><br />
	18. உன் சந்ததி இவ்வளவாயிருக்கும் என்று சொல்லப்பட்டபடியே, தான் அநேக ஜாதிகளுக்குத் தகப்பனாவதை நம்புகிறதற்கு ஏதுவில்லாதிருந்தும், அதை நம்பிக்கையோடே விசுவாசித்தான்.
	<br /><br />
	19. அவன் விசுவாசத்திலே பலவீனமாயிருக்கவில்லை; அவன் ஏறக்குறைய நூறுவயதுள்ளவனாயிருக்கும்போது, தன் சரீரம் செத்துப்போனதையும், சாராளுடைய கர்ப்பம் செத்துப்போனதையும் எண்ணாதிருந்தான்.
	<br /><br />
	20. தேவனுடைய வாக்குத்தத்தத்தைக்குறித்து அவன் அவிசுவாசமாய்ச் சந்தேகப்படாமல்,
	<br /><br />
	21. தேவன் வாக்குத்தத்தம்பண்ணினதை நிறைவேற்ற வல்லவராயிருக்கிறாரென்று முழு நிச்சயமாய் நம்பி, தேவனை மகிமைப்படுத்தி, விசுவாசத்தில் வல்லவனானான்.
	<br /><br />
	22. ஆகையால் அது அவனுக்கு நீதியாக எண்ணப்பட்டது.
	<br /><br />
	23. அது அவனுக்கு நீதியாக எண்ணப்பட்டதென்பது, அவனுக்காக மாத்திரமல்ல, நமக்காகவும் எழுதப்பட்டிருக்கிறது.
	<br /><br />
	24. நம்முடைய கர்த்தராகிய இயேசுவை மரித்தோரிலிருந்து எழுப்பினவரை விசுவாசிக்கிற நமக்கும் அப்படியே எண்ணப்படும்.
	<br /><br />
	25. அவர் நம்முடைய பாவங்களுக்காக ஒப்புக்கொடுக்கப்பட்டும், நாம் நீதிமான்களாக்கப்படுவதற்காக எழுப்பப்பட்டும் இருக்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Romans4