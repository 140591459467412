import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 11 </title>
	<meta
          name="description"
          content="Isaiah 11 | ஏசாயா 11 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஈசாயென்னும் அடிமரத்திலிருந்து ஒரு துளிர் தோன்றி, அவன் வேர்களிலிருந்து ஒரு கிளை எழும்பிச் செழிக்கும்.
	<br /><br />
	2. ஞானத்தையும் உணர்வையும் அருளும் ஆவியும், ஆலோசனையையும் பெலனையும் அருளும் ஆவியும், அறிவையும் கர்த்தருக்குப் பயப்படுகிற பயத்தையும் அருளும் ஆவியுமாகிய கர்த்தருடைய ஆவியானவர் அவர்மேல் தங்கியிருப்பார்.
	<br /><br />
	3. கர்த்தருக்குப் பயப்படுதல் அவருக்கு உகந்த வாசனையாயிருக்கும்; அவர் தமது கண் கண்டபடி நியாயந்தீர்க்காமலும், தமது காது கேட்டபடி தீர்ப்புச்செய்யாமலும்,
	<br /><br />
	4. நீதியின்படி ஏழைகளை நியாயம் விசாரித்து, யதார்த்தத்தின்படி பூமியிலுள்ள சிறுமையானவர்களுக்குத் தீர்ப்புச்செய்து, பூமியைத் தமது வாக்கின் கோலால் அடித்து, தமது வாயின் சுவாசத்தால் துன்மார்க்கரைச் சங்கரிப்பார்.
	<br /><br />
	5. நீதி அவருக்கு அரைக்கட்டும், சத்தியம் அவருக்கு இடைக்கச்சையுமாயிருக்கும்.
	<br /><br />
	6. அப்பொழுது ஓனாய் ஆட்டுக்குட்டியோடே தங்கும், புலி வெள்ளாட்டுக்குட்டியோடே படுத்துக்கொள்ளும்; கன்றுக்குட்டியும், பாலசிங்கமும், காளையும், ஒருமித்திருக்கும்; ஒரு சிறு பையன் அவைகளை நடத்துவான்.
	<br /><br />
	7. பசுவும் கரடியும் கூடிமேயும், அவைகளின் குட்டிகள் ஒருமித்துப் படுத்துக்கொள்ளும்; சிங்கம் மாட்டைப்போல் வைக்கோல் தின்னும்.
	<br /><br />
	8. பால் குடிக்குங்குழந்தை விரியன்பாம்பு வளையின்மேல் விளையாடும், பால் மறந்த பிள்ளை கட்டுவிரியன் புற்றிலே தன் கையை வைக்கும்.
	<br /><br />
	9. என் பரிசுத்த பர்வதமெங்கும் தீங்குசெய்வாருமில்லை; கேடுசெய்வாருமில்லை; சமுத்திரம் ஜலத்தினால் நிறைந்திருக்கிறதுபோல், பூமி கர்த்தரை அறிகிற அறிவினால் நிறைந்திருக்கும்.
	<br /><br />
	10. அக்காலத்திலே, ஜனங்களுக்குக் கொடியாக நிற்கும் ஈசாயின் வேருக்காக ஜாதிகள் விசாரித்துக் கேட்பார்கள்; அவருடைய தாபரஸ்தலம் மகிமையாயிருக்கும்.
	<br /><br />
	11. அக்காலத்திலே, ஆண்டவர் அசீரியாவிலும், எகிப்திலும், பத்ரோசிலும், எத்தியோப்பியாவிலும், பெர்சியாவிலும், சிநேயாரிலும், ஆமாத்திலும், சமுத்திரத் தீவுகளிலும், தம்முடைய ஜனத்தில் மீதியானவர்களை மீட்டுக்கொள்ளத் திரும்ப இரண்டாம்விசை தமது கரத்தை நீட்டி,
	<br /><br />
	12. ஜாதிகளுக்கு ஒரு கொடியை ஏற்றி, இஸ்ரவேலில் துரத்துண்டவர்களைச் சேர்த்து, யூதாவில் சிதறடிக்கப்பட்டவர்களை பூமியின் நான்கு திசைகளிலுமிருந்து கூட்டுவார்.
	<br /><br />
	13. எப்பிராயீமின் பொறாமை நீங்கும், யூதாவின் சத்துருக்கள் சங்கரிக்கப்படுவார்கள்; எப்பிராயீம் யூதாவின்மேல் பொறாமையாயிரான், யூதா எப்பிராயீமைத் துன்பப்படுத்தான்.
	<br /><br />
	14. அவர்கள் இருவரும் ஏகமாய்க் கூடி மேற்கேயிருக்கிற பெலிஸ்தருடைய எல்லைகளின்மேல் பாய்ந்து, கீழ்த்திசையாரைக் கொள்ளையிட்டு, ஏதோமின்மேலும் மோவாபின்மேலும் கைபோடுவார்கள்; அம்மோன் புத்திரர் அவர்களுக்குக் கீழ்ப்படிவார்கள்.
	<br /><br />
	15. எகிப்தின் சமுத்திரமுனையைக் கர்த்தர் முற்றிலும் அழித்து, தம்முடைய காற்றின் வலுமையினால் நதியின்மேல் தமது கையை நீட்டி, ஏழாறுகளாகப் பிரித்து, ஜனங்கள் கால்நனையாமல் கடந்துபோகும்படி பண்ணுவார்.
	<br /><br />
	16. இஸ்ரவேலர் எகிப்து தேசத்திலிருந்து புறப்பட்டநாளில் அவர்களுக்கு இருந்ததுபோல, அசீரியாவிலே அவருடைய ஜனத்தில் மீதியானவர்களுக்கு ஒரு பெரும்பாதையிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah11