import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 8 </title>
	<meta
          name="description"
          content="Zechariah 8 | சகரியா 8 | Sageria 8 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சேனைகளுடைய கர்த்தரின் வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	2. நான் சீயோனுக்காக கடும் வைராக்கியங்கொண்டேன்; அதற்காக மகா உக்கிரமான வைராக்கியங்கொண்டேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. நான் சீயோனிடத்தில் திரும்பி, எருசலேமின் நடுவிலே வாசம்பண்ணுவேன்; எருசலேம் சத்திய நகரம் என்றும், சேனைகளுடைய கர்த்தரின் பர்வதம் பரிசுத்த பர்வதம் என்றும் அழைக்கப்படும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. திரும்பவும் எருசலேமின் வீதிகளில் முதிர்வயதினாலே தங்கள் கைகளில் கோலைப்பிடித்து நடக்கிற கிழவரும் கிழவிகளும் குடியிருப்பார்கள்.
	<br /><br />
	5. நகரத்தின் தெருக்களிலே விளையாடுகிற ஆண்பிள்ளைகளும் பெண்பிள்ளைகளும் அதின் வீதிகளில் நிறைந்திருக்கும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: அது இந்த ஜனத்தில் மீதியானவர்களின் பார்வைக்கு இந்நாட்களில் ஆச்சரியமாயிருந்தாலும், என் பார்வைக்கும் ஆச்சரியமாயிருக்குமோ என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. இதோ, கிழக்குதேசத்திலும் மேற்கு தேசத்திலுமிருந்து என் ஜனங்களை நான் இரட்சித்து,
	<br /><br />
	8. அவர்களை அழைத்துக்கொண்டுவருவேன்; அவர்கள் எருசலேமின் நடுவிலே குடியிருப்பார்கள்; அவர்கள் எனக்கு உண்மையும் நீதியுமான ஜனமாயிருப்பார்கள், நான் அவர்களுக்குத் தேவனாயிருப்பேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. சேனைகளுடைய கர்த்தரின் வீடாகிய ஆலயம் கட்டப்படும்படிக்கு அதின் அஸ்திபாரங்கள் போடப்பட்ட நாள்முதற் கொண்டிருக்கிற தீர்க்கதரிசிகளின் வாயினால் இந்த வார்த்தைகளை இந்நாட்களில் கேட்டுவருகிறவர்களே, உங்கள் கைகள் திடப்படக்கடவது என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. இந்நாட்களுக்கு முன்னே மனுஷனுடைய வேலையால் பலனுமில்லை, மிருகஜீவனுடைய வேலையால் பலனுமில்லை; போகிறவனுக்கும் வருகிறவனுக்கும் நெருக்கிடையினிமித்தம் சமாதானமுமில்லை; எல்லா மனுஷரையும் ஒருவரையொருவர் விரோதிக்கச்செய்தேன்.
	<br /><br />
	11. இப்போதோ இந்த ஜனத்தில் மீதியானவர்களுக்கு நான் முந்தினநாட்களில் இருந்ததுபோல இருக்கமாட்டேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	12. விதைப்புச் சமாதானமுள்ளதாயிருக்கும்; திராட்சச்செடி தன் கனியைத் தரும்; பூமி தன் பலனைத் தரும்; வானம் தன் பனியைத் தரும்; இந்த ஜனத்தில் மீதியானவர்கள் இதையெல்லாம் சுதந்தரிக்கக் கட்டளையிடுவேன்.
	<br /><br />
	13. சம்பவிப்பதென்னவென்றால்: யூதா வம்சத்தாரே, இஸ்ரவேல் வம்சத்தாரே, நீங்கள் புறஜாதிகளுக்குள்ளே சாபமாயிருந்ததுபோலவே, ஆசீர்வாதமாயிருக்கும்படி நான் உங்களை இரட்சிப்பேன்; பயப்படாதேயுங்கள், உங்கள் கைகள் திடப்படக்கடவது.
	<br /><br />
	14. சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால்: உங்கள் பிதாக்கள் எனக்குக் கோபமூட்டினபோது நான் உங்களைத் தண்டிக்க நினைத்து, மனம் மாறாமல் இருந்ததுபோல,
	<br /><br />
	15. இந்நாட்களில் எருசலேமுக்கும் யூதாவுக்கும் நன்மைசெய்யும்படித் திரும்ப நினைத்தேன்; பயப்படாதேயுங்கள்.
	<br /><br />
	16. நீங்கள் செய்யவேண்டிய காரியங்கள் என்னவென்றால்: அவனவன் பிறனோடே உண்மையைப் பேசுங்கள்; உங்கள் வாசல்களில் சத்தியத்துக்கும் சமாதானத்துக்கும் ஏற்க நியாயந்தீருங்கள்.
	<br /><br />
	17. ஒருவனும் பிறனுக்கு விரோதமாய்த் தன் இருதயத்தில் தீங்கு நினையாமலும், பொய்யாணையின்மேல் பிரியப்படாமலும் இருங்கள்; இவைகளெல்லாம் நான் வெறுக்கிற காரியங்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	18. சேனைகளுடைய கர்த்தரின் வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	19. நாலாம் மாதத்தின் உபவாசமும், ஐந்தாம் மாதத்தின் உபவாசமும், ஏழாம் மாதத்தின் உபவாசமும், பத்தாம் மாதத்தின் உபவாசமும், யூதா வம்சத்தாருக்கு மகிழ்ச்சியாகவும் சந்தோஷமாகவும் நல்ல பண்டிகைகளாகவும் மாறிப்போகும்; ஆகையால் சத்தியத்தையும் சமாதானத்தையும் சிநேகியுங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	20. இன்னும் ஜனங்களும் அநேகம் பட்டணங்களின் குடிகளும் வருவார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	21. ஒரு பட்டணத்தின் குடிகள் மறுபட்டணத்தின் குடிகளிடத்தில் போய், நாம் கர்த்தருடைய சமுகத்தில் விண்ணப்பம்பண்ணவும் சேனைகளின் கர்த்தரைத் தேடவும் தீவிரித்துப்போவோம் வாருங்கள்; நாங்களும் போவோம் என்று சொல்லுவார்கள்.
	<br /><br />
	22. அநேக ஜனங்களும் பலத்த ஜாதிகளும் எருசலேமிலே சேனைகளின் கர்த்தரைத் தேடவும், கர்த்தருடைய சமுகத்தில் விண்ணப்பம்பண்ணவும் வருவார்கள்.
	<br /><br />
	23. அந்நாட்களில் பலவித பாஷைக்காரராகிய புறஜாதியாரில் பத்து மனுஷர் ஒரு யூதனுடைய வஸ்திரத்தொங்கலைப் பிடித்துக்கொண்டு: தேவன் உங்களோடே இருக்கிறார் என்று கேள்விப்பட்டோம்; ஆகையால் உங்களோடேகூடப் போவோம் என்று சொல்லி, அவனைப் பற்றிக்கொள்வார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah8