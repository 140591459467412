import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 6 </title>
	<meta
          name="description"
          content="Amos 6 | ஆமோஸ் 6 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சீயோனிலே நிர்விசாரமாயிருக்கிறவர்களும் சமாரியாவின் பர்வதத்தை நம்பிக்கொண்டிருக்கிறவர்களும், ஜாதிகளின் தலைமையென்னப்பட்டு, இஸ்ரவேல் வம்சத்தார் தேடிவருகிறவர்களுமாகிய உங்களுக்கு ஐயோ!
	<br /><br />
	2. நீங்கள் கல்னேமட்டும் சென்று, அவ்விடத்திலிருந்து ஆமாத் என்னும் பெரிய பட்டணத்துக்குப் போய், பெலிஸ்தருடைய காத்பட்டணத்துக்கு இறங்கி, அவைகள் இந்த ராஜ்யங்களைப்பார்க்கிலும் நல்லவைகளோ என்றும், அவைகளின் எல்லைகள் உங்கள் எல்லைகளைப்பார்க்கிலும் விஸ்தாரமானவைகளோ என்றும் பாருங்கள்.
	<br /><br />
	3. தீங்குநாள் தூரமென்றெண்ணிக் கொடுமையின் ஆசனம் கிட்டிவரும்படி செய்து,
	<br /><br />
	4. தந்தக் கட்டில்களில் படுத்துக்கொண்டு, தங்கள் மஞ்சங்களின்மேல் சவுக்கியமாய்ச் சயனித்து, மந்தையிலுள்ள ஆட்டுக்குட்டிகளையும், மாட்டுத்தொழுவத்திலுள்ள கன்றுக்குட்டிகளையும் தின்று,
	<br /><br />
	5. தம்புரை வாசித்துப் பாடி, தாவீதைப்போல் கீதவாத்தியங்களைத் தங்களுக்கு உண்டுபண்ணி,
	<br /><br />
	6. பெரிய பாத்திரங்களில் மதுபானத்தைக் குடித்து, சிறந்த பரிமளதைலங்களைப் பூசிக்கொள்ளுகிறார்கள்; ஆனாலும் யோசேப்புக்கு நேரிட்ட ஆபத்துக்குக் கவலைப்படாமற்போகிறார்கள்.
	<br /><br />
	7. ஆகையால் அவர்கள் சிறையிருப்புக்குப் போகிறவர்களின் முன்னணியிலே போவார்கள்; இப்படியே செல்வமாய்ச் சயனித்தவர்களின் விருந்துகொண்டாடல் நின்றுபோகும்.
	<br /><br />
	8. நான் யாக்கோபுடைய மேன்மையை வெறுத்து, அவனுடைய அரமனைகளைப் பகைக்கிறேன்; நான் நகரத்தையும் அதின் நிறைவையும் ஒப்புக்கொடுத்துவிடுவேன் என்று கர்த்தராகிய ஆண்டவர் தம்முடைய ஜீவனைக்கொண்டு ஆணையிட்டார் என்பதைச் சேனைகளின் தேவனாகிய கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. ஒரு வீட்டிலே பத்துப்பேர் மீதியாயிருந்தாலும் அவர்கள் செத்துப்போவார்கள்.
	<br /><br />
	10. அவர்களுடைய இனத்தானாவது, பிரேதத்தைத் தகிக்கிறவனாவது எலும்புகளை வீட்டிலிருந்து வெளியே கொண்டுபோகும்படிக்கு, அவைகளை எடுத்து, வீட்டின் உட்புறத்திலே இருக்கிறவனை நோக்கி: உன்னிடத்தில் இன்னும் யாராயினும் உண்டோ என்று கேட்பான், அவன் இல்லையென்பான்; அப்பொழுது இவன்: நீ மௌனமாயிரு; கர்த்தருடைய நாமத்தைச் சொல்லலாகாது என்பான்.
	<br /><br />
	11. இதோ, கர்த்தர் கட்டளையிட்டிருக்கிறார்; பெரிய வீட்டைத் திறப்புகள் உண்டாகவும், சிறிய வீட்டை வெடிப்புகள் உண்டாகவும் அடிப்பார்.
	<br /><br />
	12. கன்மலையின்மேல் குதிரைகள் ஓடுமோ? அங்கே ஒருவன் மாடுகளால் உழுவானோ? நியாயத்தை நஞ்சாகவும், நீதியின் கனியை எட்டியாகவும் மாற்றினீர்கள்.
	<br /><br />
	13. நாங்கள் எங்கள் பலத்தினாலே எங்களுக்குக் கொம்புகளை உண்டாக்கிக்கொள்ளவில்லையோ என்று சொல்லி, வீண்காரியத்தில் மகிழுகிறார்கள்.
	<br /><br />
	14. இஸ்ரவேல் வம்சத்தாரே, இதோ, நான் ஒரு ஜாதியை உங்களுக்கு விரோதமாக எழுப்புவேன்; அவர்கள் ஆமாத்துக்குள் பிரவேசிக்கிற வழிதொடங்கிச் சமனான நாட்டின் ஆறுமட்டாக உங்களை ஒடுக்குவார்கள் என்று சேனைகளின் தேவனாகிய கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos6