import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title> Superiority of New Covenant Hebrews Tamil Bible | எபிரெயர் 8 </title>
	<meta
          name="description"
          content="Superiority of the New Covenant Hebrews Chapter-8 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-8 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* புதிய உடன்படிக்கையின் மேன்மை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. மேற்சொல்லியவைகளின் முக்கியமான பொருளென்னவெனில்; பரலோகத்திலுள்ள மகத்துவ ஆசனத்தின் வலதுபாரிசத்திலே உட்கார்ந்திருக்கிறவருமாய்,
	<br /><br />
	2. பரிசுத்த ஸ்தலத்திலும், மனுஷரால் அல்ல, கர்த்தரால் ஸ்தாபிக்கப்பட்ட மெய்யான கூடாரத்திலும் ஆசாரிய ஊழியஞ் செய்கிறவருமாயிருக்கிற பிரதான ஆசாரியர் நமக்கு உண்டு.
	<br /><br />
	3. ஒவ்வொரு பிரதான ஆசாரியனும் காணிக்கைகளையும் பலிகளையும் செலுத்தும்படி நியமிக்கப்படுகிறான்; ஆதலால் செலுத்தும்படிக்கு ஏதோ ஒன்று இவருக்கும் அவசியம் வேண்டியதாயிருக்கிறது.
	<br /><br />
	4. பூமியிலே அவர் இருப்பாரானால் ஆசாரியராயிருக்கமாட்டார்; ஏனெனில், நியாயப்பிரமாணத்தின்படி காணிக்கைகளைச் செலுத்துகிற ஆசாரியர்கள் இருக்கிறார்களே;
	<br /><br />
	5. இவர்கள் செய்யும் ஆராதனை பரலோகத்திலுள்ளவைகளின் சாயலுக்கும் நிழலுக்கும் ஒத்திருக்கிறது; அப்படியே, மோசே கூடாரத்தை உண்டுபண்ணப் போகையில்: மலையிலே உனக்குக் காண்பிக்கப்பட்ட மாதிரியின்படியே நீ எல்லாவற்றையும் செய்ய எச்சரிக்கையாயிரு என்று தேவன் அவனுக்குக் கட்டளையிட்டார்.
	<br /><br />
	6. இவரோ விசேஷித்த வாக்குத்தத்தங்களின்பேரில் ஸ்தாபிக்கப்பட்ட விசேஷித்த உடன்படிக்கைக்கு எப்படி மத்தியஸ்தராயிருக்கிறாரோ, அப்படியே முக்கியமான ஆசாரிய ஊழியத்தையும் பெற்றிருக்கிறார்.
	<br /><br />
	7. அந்த முதலாம் உடன்படிக்கை பிழையில்லாதிருந்ததானால், இரண்டாம் உடன்படிக்கைக்கு இடம் தேடவேண்டுவதில்லையே.
	<br /><br />
	8. அவர்களைக் குற்றப்படுத்தி, அவர்களை நோக்கி: இதோ, கர்த்தர் சொல்லுகிறதென்னவெனில்: இஸ்ரவேல் குடும்பத்தோடும் யூதா குடும்பத்தோடும் நான் புது உடன்படிக்கையை ஏற்படுத்துங்காலம் வருகிறது.
	<br /><br />
	9. அவர்களுடைய பிதாக்களை எகிப்துதேசத்திலிருந்து கொண்டுவரும்படிக்கு நான் அவர்களுடைய கையைப் பிடித்தநாளிலே அவர்களோடு பண்ணின உடன்படிக்கையைப்போல இது இருப்பதில்லை; அந்த உடன்படிக்கையிலே அவர்கள் நிலைநிற்கவில்லையே, நானும் அவர்களைப் புறக்கணித்தேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. அந்த நாட்களுக்குப்பின்பு நான் இஸ்ரவேல் குடும்பத்தாரோடே பண்ணும் உடன்படிக்கையாவது: என்னுடைய பிரமாணங்களை அவர்களுடைய மனதிலே வைத்து, அவர்களுடைய இருதயங்களில் அவைகளை எழுதுவேன்; நான் அவர்கள் தேவனாயிருப்பேன், அவர்கள் என் ஜனமாயிருப்பார்கள்.
	<br /><br />
	11. அப்பொழுது சிறியவன் முதற்கொண்டு பெரியவன்வரைக்கும் எல்லாரும் என்னை அறிவார்கள்; ஆகையால், கர்த்தரை அறிந்துகொள் என்று ஒருவன் தன் அயலானுக்கும், ஒருவன் தன் சகோதரனுக்கும் போதிக்கவேண்டுவதில்லை.
	<br /><br />
	12. ஏனெனில் நான் அவர்கள் அநியாயங்களைக் கிருபையாய் மன்னித்து, அவர்கள் பாவங்களையும் அக்கிரமங்களையும் இனி நினையாமலிருப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. புது உடன்படிக்கை என்று அவர் சொல்லுகிறதினாலே முந்தினதைப் பழமையாக்கினார்; பழமையானதும் நாள்பட்டதுமாயிருக்கிறது உருவழிந்துபோகக் காலம் சமீபித்திருக்கிறது.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h4>
* எபிரெயர் 8, இயேசு ஒரு புதிய மற்றும் சிறந்த உடன்படிக்கையின் மத்தியஸ்தர் என்பதை காட்டுகிறது, இது சிறந்த வாக்குறுதிகளின் மீது இயற்றப்பட்டது. இயேசு கடவுளின் வலது பாரிசத்தில் உள்ள பரலோக வாசஸ்தலத்தில் ஊழியம் செய்கிறார் (இது பரலோக விஷயங்களின் நகலாகவும் நிழலாகவும் இருந்தது). புதிய உடன்படிக்கை நம் இதயங்களில் எழுதப்பட்டுள்ளது மற்றும் இறைவனை ஒரு நெருக்கமான வழியில் அறிய உதவுகிறது. நம்முடைய பாவங்களை மன்னிப்பதாகவும், இனி அவற்றை நினைவில் கொள்வதில்லை என்றும் இயேசு வாக்குக் கொடுத்திருக்கிறார். புதிய உடன்படிக்கை பழையதை வழக்கற்றுப் போனது. *
			</h4>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews8