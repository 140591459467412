import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 7 </title>
	<meta
          name="description"
          content="Ezekiel 7 | எசேக்கியேல்  7 |
          Ezekiel 7 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, இஸ்ரவேல் தேசத்தைக்குறித்துக் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: முடிவு வருகிறது, தேசத்தின் நாலு முனைகளின்மேலும் முடிவு வருகிறது.
	<br /><br />
	3. இப்போதே உன்மேல் முடிவு வருகிறது; நான் என் கோபத்தை உன்மேல் வருவித்து, உன் வழிகளுக்குத்தக்கதாக உன்னை நியாயந்தீர்த்து, உன் எல்லா அருவருப்புகளின் பலனையும் உன்மேல் வரப்பண்ணுவேன்.
	<br /><br />
	4. என் கண் உன்னைத் தப்பவிடாது; நான் இரங்காமல் உன் வழிகளுக்குத்தக்கதை உன்மேல் வரப்பண்ணுவேன்; உன் அருவருப்புகளுக்குத்தக்கது உன் நடுவில் வந்திருக்கும்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	5. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: தீங்கு வருகிறது; இதோ, ஏகமான தீங்கு வருகிறது.
	<br /><br />
	6. முடிவு வருகிறது, முடிவு வருகிறது, அது உன்மேல் நோக்கமாயிருக்கிறது; இதோ, வருகிறது.
	<br /><br />
	7. தேசத்தில் குடியிருக்கிறவனே, அந்நாளின் விடியற்காலம் வருகிறது, காலம் வருகிறது, அமளியின் நாள் சமீபித்திருக்கிறது, மலைகளில் சந்தோஷசத்தம் இல்லை.
	<br /><br />
	8. இப்பொழுது விரைவில் என் உக்கிரத்தை உன்மேல் ஊற்றி, என் கோபத்தை உன்னில் தீர்த்துக்கொண்டு, உன்னை உன் வழிகளுக்குத்தக்கதாக நியாயந்தீர்த்து, உன் எல்லா அருவருப்புகளின் பலனையும் உன்மேல் வரப்பண்ணுவேன்.
	<br /><br />
	9. என் கண் உன்னைத் தப்பவிடாது; நான் இரங்காமல் உன் வழிகளுக்குத்தக்கதை உன்மேல் வரப்பண்ணுவேன்; உன் அருவருப்புகளுக்குத்தக்கது உன் நடுவில் வந்திருக்கும்; அப்பொழுது அடிக்கிறவராகிய நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	10. இதோ, அந்த நாள், இதோ, வருகிறது, அந்நாளின் விடியற்காலம் உதிக்கிறது, மிலாறு பூக்கிறது, அகந்தை செழிக்கிறது.
	<br /><br />
	11. அக்கிரமத்துக்கு மிலாறாகக் கொடுமை எழும்புகிறது; அவர்களிலும் அவர்களுடைய திரளான கும்பிலும் அவர்களுடைய அமளியிலும் ஒன்றும் மீதியாயிருப்பதில்லை; அவர்கள் நிமித்தம் புலம்பல் உண்டாயிருப்பதுமில்லை.
	<br /><br />
	12. அந்தக் காலம் வருகிறது, அந்த நாள் கிட்டுகிறது; கொள்ளுகிறவன் சந்தோஷப்படாமலும், விற்கிறவன் துக்கப்படாமலும் இருப்பானாக; அதின் திரளான கும்பின்மேலும் உக்கிரம் இறங்கும்.
	<br /><br />
	13. அவர்கள் ஜீவனுள்ளோருக்குள்ளே இன்னும் உயிரோடிருந்தாலும், விற்றவன் விற்கப்பட்டதற்குத் திரும்பிவருவதில்லை; அதின் திரளான கும்பின்மேலும் உண்டான தரிசனம் திரும்பாது; தன் அக்கிரமத்திலே வாழுகிற எவனும் தன்னைத் திடப்படுத்தமாட்டான்.
	<br /><br />
	14. அவர்கள் எக்காளம் ஊதி, எல்லாவற்றையும் ஆயத்தம்பண்ணியும், யுத்தத்துக்குப் போகிறவனில்லை; என் உக்கிரம் அதின் திரளான கும்பின்மேலும் இறங்குகிறது.
	<br /><br />
	15. வெளியே பட்டயமும் உள்ளே கொள்ளைநோயும் பஞ்சமும் உண்டு; வயல்வெளியில் இருக்கிறவன் பட்டயத்தால் சாவான்; நகரத்தில் இருக்கிறவனையோ பஞ்சமும் கொள்ளைநோயும் பட்சிக்கும்.
	<br /><br />
	16. அவர்களில் தப்புகிறவர்கள் தப்புவார்கள்; ஆனாலும் அவர்கள் அனைவரும் அவனவன் தன் தன் அக்கிரமத்தினிமித்தம் துக்கித்துக் கூப்பிடுகிற பள்ளத்தாக்குகளின் புறாக்களைப்போல மலைகளில் இருப்பார்கள்.
	<br /><br />
	17. எல்லாக் கைகளும் சலித்து, எல்லா முழங்கால்களும் தண்ணீரைப்போல் தத்தளிக்கும்.
	<br /><br />
	18. இரட்டை உடுத்திக்கொள்வார்கள்; தத்தளிப்பு அவர்களை மூடும்; எல்லா முகங்களும் வெட்கப்படும், எல்லாத் தலைகளும் மொட்டையிடப்படும்.
	<br /><br />
	19. தங்கள் வெள்ளியைத் தெருக்களில் எறிந்துவிடுவார்கள்; அவர்களுடைய பொன் வேண்டாவெறுப்பாயிருக்கும்; கர்த்தருடைய சினத்தின் நாளிலே அவர்கள் வெள்ளியும் அவர்கள் பொன்னும் அவர்களை விடுவிக்கமாட்டாது; அவர்கள் அதினால் தங்கள் ஆத்துமாக்களைத் திருப்தியாக்குவதும் இல்லை, தங்கள் வயிறுகளை நிரப்புவதும் இல்லை; அவர்கள் அக்கிரமமே அவர்களுக்கு இடறலாயிருந்தது.
	<br /><br />
	20. அவருடைய சிங்காரத்தின் மகிமையை அகந்தைக்கென்று வைத்து, அதிலே அருவருக்கப்படத்தக்கதும் சீயென்றிகழப்படத்தக்கதுமான காரியங்களின் விக்கிரகங்களை உண்டுபண்ணினார்கள்; ஆகையால் நான் அவைகளை அவர்களுக்கு வேண்டாவெறுப்பாக்கி,
	<br /><br />
	21. அதை அந்நியர் கையிலே கொள்ளையாகவும், பூமியில் துஷ்டர்களுக்குச் சூறையாகவும் கொடுப்பேன்; அவர்கள் அதைப் பரிசுத்தக்குலைச்சலாக்குவார்கள்.
	<br /><br />
	22. என் முகத்தை அவர்களை விட்டுத் திருப்புவேன்; அதினால் என் அந்தரங்க ஸ்தலத்தைப் பரிசுத்தக்குலைச்சலாக்குவார்கள்; பறிகாரர் அதற்குள் பிரவேசித்து, அதைப் பரிசுத்தக்குலைச்சலாக்குவார்கள்.
	<br /><br />
	23. ஒரு சங்கிலியைப் பண்ணிவை; தேசம் நியாயத்தீர்ப்புக்குள்ளான இரத்தப்பழிகளால் நிறைந்திருக்கிறது; நகரம் கொடுமையால் நிறைந்திருக்கிறது.
	<br /><br />
	24. ஆகையால் புறஜாதிகளின் துஷ்டர்களை வரப்பண்ணுவேன், அவர்கள் இவர்களுடைய வீடுகளைச் சுதந்தரித்துக்கொள்வார்கள்; பலவான்களின் பெருமையை ஒழியப்பண்ணுவேன், அவர்கள் பரிசுத்த ஸ்தலங்கள் பரிசுத்தக்குலைச்சலாகும்.
	<br /><br />
	25. சங்காரம் வருகிறது; அப்பொழுது சமாதானத்தைத் தேடுவார்கள்; ஆனாலும் அது கிடையாது.
	<br /><br />
	26. விக்கினத்தின்மேல் விக்கினம் வரும்; துர்ச்செய்தியின்மேல் துர்ச்செய்தி பிறக்கும்; அப்பொழுது தீர்க்கதரிசியினிடத்திலே தரிசனத்தைத் தேடுவார்கள்; ஆனாலும் ஆசாரியனிடத்திலே வேதமும் மூப்பரிடத்திலே ஆலோசனையும் இராமல் ஒழிந்துபோகும்.
	<br /><br />
	27. ராஜா துக்கித்துக்கொண்டிருப்பான்; பிரபுவைத் திகில் மூடிக்கொண்டிருக்கும்; தேசத்து ஜனங்களின் கைகள் தளர்ந்துபோகும்; நான் அவர்கள் வழிகளின்படியே அவர்களுக்குச் செய்து, அவர்கள் நியாயங்களின்படியே அவர்களை நியாயந்தீர்ப்பேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial7