import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 8 </title>
	<meta
          name="description"
          content="Acts 8 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 8 |
          Acts of Apostles Chapter-8 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவனைக் கொலைசெய்கிறதற்குச் சவுலும் சம்மதித்திருந்தான். அக்காலத்திலே எருசலேமிலுள்ள சபைக்கு மிகுந்த துன்பம் உண்டாயிற்று. அப்போஸ்தலர்தவிர, மற்ற யாவரும் யூதேயா சமாரியா தேசங்களில் சிதறப்பட்டுப்போனார்கள்.
	<br /><br />
	2. தேவபக்தியுள்ள மனுஷர் ஸ்தேவானை எடுத்து அடக்கம்பண்ணி, அவனுக்காக மிகவும் துக்கங்கொண்டாடினார்கள்.
	<br /><br />
	3. சவுல் வீடுகள்தோறும் நுழைந்து, புருஷரையும் ஸ்திரீகளையும் இழுத்துக்கொண்டுபோய், காவலில் போடுவித்து, சபையைப் பாழாக்கிக்கொண்டிருந்தான்.
	<br /><br />
	4. சிதறிப்போனவர்கள் எங்குந்திரிந்து, சுவிசேஷவசனத்தைப் பிரசங்கித்தார்கள்.
	<br /><br />
	5. அப்பொழுது பிலிப்பென்பவன் சமாரியாவிலுள்ள ஒரு பட்டணத்திற்குப் போய், அங்குள்ளவர்களுக்குக் கிறிஸ்துவைக்குறித்துப் பிரசங்கித்தான்.
	<br /><br />
	6. பிலிப்பு செய்த அதிசயங்களை ஜனங்கள் கேள்விப்பட்டுக் கண்டு, அவனால் சொல்லப்பட்டவைகளை ஒருமனப்பட்டுக் கவனித்தார்கள்.
	<br /><br />
	7. அநேகரிலிருந்த அசுத்தஆவிகள் மிகுந்த சத்தத்தோடே கூப்பிட்டு அவர்களை விட்டுப் புறப்பட்டது. அநேகந் திமிர்வாதக்காரரும் சப்பாணிகளும் குணமாக்கப்பட்டார்கள்.
	<br /><br />
	8. அந்தப் பட்டணத்திலே மிகுந்த சந்தோஷம் உண்டாயிற்று.
	<br /><br />
	9. சீமோன் என்று பேர்கொண்ட ஒரு மனுஷன் அந்தப் பட்டணத்திலே மாயவித்தைக்காரனாயிருந்து, தன்னை ஒரு பெரியவனென்று சொல்லி, சமாரியாநாட்டு ஜனங்களைப் பிரமிக்கப்பண்ணிக்கொண்டிருந்தான்.
	<br /><br />
	10. தேவனுடைய பெரிதான சக்தி இவன்தான் என்று எண்ணி, சிறியோர் பெரியோர் யாவரும் அவனுக்குச் செவிகொடுத்துவந்தார்கள்.
	<br /><br />
	11. அவன் அநேக காலமாய்த் தன்னுடைய மாயவித்தைகளினாலே அவர்களைப் பிரமிக்கப்பண்ணினதினால் அவனை மதித்துவந்தார்கள்.
	<br /><br />
	12. தேவனுடைய ராஜ்யத்துக்கும் இயேசுகிறிஸ்துவினுடைய நாமத்துக்கும் ஏற்றவைகளைக்குறித்து, பிலிப்பு பிரசங்கித்ததை அவர்கள் விசுவாசித்தபோது, புருஷரும் ஸ்திரீகளும் ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	13. அப்பொழுது சீமோனும் விசுவாசித்து ஞானஸ்நானம் பெற்று, பிலிப்பைப் பற்றிக்கொண்டு, அவனால் நடந்த அடையாளங்களையும் பெரிய அற்புதங்களையும் கண்டு பிரமித்தான்.
	<br /><br />
	14. சமாரியர் தேவவசனத்தை ஏற்றுக்கொண்டதை எருசலேமிலுள்ள அப்போஸ்தலர்கள் கேள்விப்பட்டு, பேதுருவையும் யோவானையும் அவர்களிடத்திற்கு அனுப்பினார்கள்.
	<br /><br />
	15. இவர்கள் வந்தபொழுது அவர்களில் ஒருவனும் பரிசுத்தஆவியைப் பெறாமல் கர்த்தராகிய இயேசுவின் நாமத்தினாலே ஞானஸ்நானத்தை மாத்திரம் பெற்றிருந்தவர்களாகக் கண்டு,
	<br /><br />
	16. அவர்கள் பரிசுத்தஆவியைப் பெற்றுக்கொள்ளும்படி அவர்களுக்காக ஜெபம்பண்ணி,
	<br /><br />
	17. அவர்கள்மேல் கைகளை வைத்தார்கள், அப்பொழுது அவர்கள் பரிசுத்தஆவியைப் பெற்றார்கள்.
	<br /><br />
	18. அப்போஸ்தலர் தங்கள் கைகளை அவர்கள்மேல் வைத்ததினால் பரிசுத்தஆவி தந்தருளப்படுகிறதைச் சீமோன் கண்டபோது, அவர்களிடத்தில் பணத்தைக் கொண்டுவந்து:
	<br /><br />
	19. நான் எவன்மேல் என் கைகளை வைக்கிறேனோ, அவன் பரிசுத்தஆவியைப் பெறத்தக்கதாக எனக்கும் இந்த அதிகாரத்தைக் கொடுக்கவேண்டும் என்றான்.
	<br /><br />
	20. பேதுரு அவனை நோக்கி: தேவனுடைய வரத்தைப் பணத்தினாலே சம்பாதித்துக்கொள்ளலாமென்று நீ நினைத்தபடியால் உன் பணம் உன்னோடேகூட நாசமாய்ப் போகக்கடவது.
	<br /><br />
	21. உன் இருதயம் தேவனுக்குமுன்பாகச் செம்மையாயிராதபடியால், இந்த விஷயத்திலே உனக்குப் பங்குமில்லை பாகமுமில்லை.
	<br /><br />
	22. ஆகையால் நீ உன் துர்க்குணத்தை விட்டு மனந்திரும்பி, தேவனை நோக்கி வேண்டிக்கொள்; ஒருவேளை உன் இருதயத்தின் எண்ணம் உனக்கு மன்னிக்கப்படலாம்.
	<br /><br />
	23. நீ கசப்பான பிச்சிலும் பாவக்கட்டிலும் அகப்பட்டிருக்கிறதாகக் காண்கிறேன் என்றான்.
	<br /><br />
	24. அதற்குச் சீமோன்: நீங்கள் சொன்ன காரியங்களில் ஒன்றும் எனக்கு நேரிடாதபடிக்கு, எனக்காகக் கர்த்தரை வேண்டிக்கொள்ளுங்கள் என்றான்.
	<br /><br />
	25. இவ்விதமாய் அவர்கள் கர்த்தருடைய வசனத்தைச் சாட்சியாய் அறிவித்துச் சொன்னபின்பு, சமாரியருடைய அநேக கிராமங்களில் சுவிசேஷத்தைப் பிரசங்கித்து, எருசலேமுக்குத் திரும்பி வந்தார்கள்.
	<br /><br />
	26. பின்பு கர்த்தருடைய தூதன் பிலிப்பை நோக்கி: நீ எழுந்து, தெற்குமுகமாய் எருசலேமிலிருந்து காசா பட்டணத்துக்குப் போகிற வனாந்தரமார்க்கமாய்ப் போ என்றான்.
	<br /><br />
	27. அந்தப்படி அவன் எழுந்துபோனான். அப்பொழுது எத்தியோப்பியருடைய ராஜஸ்திரீயாகிய கந்தாகே என்பவளுக்கு மந்திரியும் அவளுடைய பொக்கிஷமெல்லாவற்றிற்கும் தலைவனுமாயிருந்த எத்தியோப்பியனாகிய ஒருவன் பணிந்துகொள்ளும்படி எருசலேமுக்கு வந்திருந்து;
	<br /><br />
	28. ஊருக்குத் திரும்பிப்போகும்போது, தன் இரதத்திலே உட்கார்ந்து, ஏசாயா தீர்க்கதரிசியின் ஆகமத்தை வாசித்துக்கொண்டிருந்தான்.
	<br /><br />
	29. ஆவியானவர்: நீ போய், அந்த இரதத்துடனே சேர்ந்துகொள் என்று பிலிப்புடனே சொன்னார்.
	<br /><br />
	30. அப்பொழுது பிலிப்பு ஓடிப்போய்ச்சேர்ந்து, அவன் ஏசாயா தீர்க்கதரிசியின் ஆகமத்தை வாசிக்கிறதைக் கேட்டு: நீர் வாசிக்கிறவைகளின் கருத்து உமக்குத் தெரியுமா என்றான்.
	<br /><br />
	31. அதற்கு அவன்: ஒருவன் எனக்குத் தெரிவிக்காவிட்டால் அது எனக்கு எப்படித் தெரியும் என்று சொல்லி; பிலிப்பு ஏறி, தன்னோடே உட்காரும்படி அவனை வேண்டிக்கொண்டான்.
	<br /><br />
	32. அவன் வாசித்த வேதவாக்கியம் என்னவென்றால்: அவர் ஒரு ஆட்டைப்போல அடிக்கப்படுவதற்குக் கொண்டு போகப்பட்டார்; மயிர்கத்தரிக்கிறவனுக்கு முன்பாகச் சத்தமிடாதிருக்கிற ஆட்டுக்குட்டியைப்போல அவர் தமது வாயைத் திறவாதிருந்தார்.
	<br /><br />
	33. அவர் தம்மைத் தாழ்த்தினபோது அவருடைய நியாயம் எடுத்துப்போடப்பட்டது; அவருடைய ஜீவன் பூமியிலிருந்து எடுபட்டுப்போயிற்று; அவருடைய வம்சத்தை யாராலே சொல்லிமுடியும் என்பதே.
	<br /><br />
	34. மந்திரி பிலிப்பை நோக்கி: தீர்க்கதரிசி யாரைக்குறித்து இதைச் சொல்லுகிறார்? தம்மைக்குறித்தோ, வேறொருவரைக்குறித்தோ? எனக்குச் சொல்லவேண்டும் என்று கேட்டுக்கொண்டான்.
	<br /><br />
	35. அப்பொழுது பிலிப்பு பேசத்தொடங்கி, இந்த வேதவாக்கியத்தை முன்னிட்டு இயேசுவைக்குறித்து அவனுக்குப் பிரசங்கித்தான்.
	<br /><br />
	36. இவ்விதமாய் அவர்கள் வழிநடந்துபோகையில், தண்ணீருள்ள ஓரிடத்திற்கு வந்தார்கள். அப்பொழுது மந்திரி: இதோ, தண்ணீர் இருக்கிறதே, நான் ஞானஸ்நானம் பெறுகிறதற்குத் தடையென்ன என்றான்.
	<br /><br />
	37. அதற்குப் பிலிப்பு: நீர் முழு இருதயத்தோடும் விசுவாசித்தால் தடையில்லையென்றான். அப்பொழுது அவன்: இயேசுகிறிஸ்துவைத் தேவனுடைய குமாரனென்று விசுவாசிக்கிறேன் என்று சொல்லி;
	<br /><br />
	38. இரதத்தை நிறுத்தச்சொன்னான். அப்பொழுது பிலிப்பும் மந்திரியும் ஆகிய இருவரும் தண்ணீரில் இறங்கினார்கள்; பிலிப்பு அவனுக்கு ஞானஸ்நானங் கொடுத்தான்.
	<br /><br />
	39. அவர்கள் தண்ணீரிலிருந்து கரையேறினபொழுது, கர்த்தருடைய ஆவியானவர் பிலிப்பைக் கொண்டுபோய்விட்டார். மந்திரி அப்புறம் அவனைக் காணாமல், சந்தோஷத்தோடே தன் வழியே போனான்.
	<br /><br />
	40. பிலிப்பு ஆசோத்திலே காணப்பட்டு, அவ்விடத்திலிருந்து பிரயாணம்பண்ணி, செசரியாவுக்கு வருகிறவரையில் சகல பட்டணங்களிலும் சுவிசேஷத்தைப் பிரசங்கித்துக்கொண்டுவந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts8