import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah36 = () => {
  const verseNumber = 36;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 36 </title>
	<meta
          name="description"
          content="Jeremiah 36 | எரேமியா 36 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யோசியாவின் குமாரனாகிய யோயாக்கீம் என்னும் யூதா ராஜாவின் நாலாம் வருஷத்திலே கர்த்தரால் எரேமியாவுக்கு உண்டான வார்த்தை என்னவென்றால்:
	<br /><br />
	2. நீ ஒரு புஸ்தகச்சுருளை எடுத்து, யோசியாவின் நாட்களிலே நான் உன்னுடனே பேசின நாள் முதற்கொண்டு இந்நாள்மட்டும் இஸ்ரவேலைக்குறித்தும், யூதாவைக்குறித்தும், சகல ஜாதிகளைக்குறித்தும் உன்னோடே சொன்ன எல்லா வார்த்தைகளையும் அதிலே எழுது.
	<br /><br />
	3. யூதாவின் குடும்பத்தார், அவரவர் தங்கள் பொல்லாத வழியைவிட்டுத் திரும்பும்படியாகவும், தங்கள் அக்கிரமத்தையும் தங்கள் பாவத்தையும் நான் மன்னிக்கும்படியாகவும், தங்களுக்கு நான் செய்ய நினைத்திருக்கிற தீங்குகளைக்குறித்து ஒருவேளை அவர்கள் கேட்பார்கள் என்றார்.
	<br /><br />
	4. அப்பொழுது எரேமியா நேரியாவின் குமாரனாகிய பாருக்கை அழைத்தான்; பாருக்கு என்பவன் கர்த்தர் எரேமியாவுடனே சொல்லிவந்த எல்லா வார்த்தைகளையும் அவன் வாய் சொல்ல ஒரு புஸ்தகச்சுருளில் எழுதினான்.
	<br /><br />
	5. பின்பு எரேமியா பாருக்கை நோக்கி: நான் அடைக்கப்பட்டவன்; நான் கர்த்தருடைய ஆலயத்துக்குள் பிரவேசிக்கக்கூடாது.
	<br /><br />
	6. நீ உள்ளே பிரவேசித்து, என் வாய் சொல்ல நீ எழுதின சுருளிலுள்ள கர்த்தருடைய வார்த்தைகளைக் கர்த்தருடைய ஆலயத்தில் உபவாச நாளிலே ஜனங்களுடைய செவிகள் கேட்க வாசிப்பதுமன்றி, தங்கள் பட்டணங்களிலிருந்துவருகிற எல்லா யூதா கோத்திரத்தாரும் கேட்கும்படி அவைகளை வாசிப்பாயாக.
	<br /><br />
	7. ஒருவேளை அவர்கள் கர்த்தருடைய முகத்துக்கு முன்பாகப் பணிந்து விண்ணப்பம்பண்ணி, அவரவர் தங்கள் பொல்லாத வழியை விட்டுத் திரும்புவார்கள்; கர்த்தர் இந்த ஜனத்துக்கு விரோதமாகக் கூறியிருக்கிற கோபமும் உக்கிரமும் பெரியது என்று சொன்னான்.
	<br /><br />
	8. அப்படியே நேரியாவின் குமாரனாகிய பாருக்கு அந்தப் புஸ்தகத்தில், கர்த்தருடைய ஆலயத்தில் கர்த்தருடைய வார்த்தைகளை வாசிக்கும்படி எரேமியா தீர்க்கதரிசி தனக்குக் கற்பித்தபடியெல்லாம் செய்தான்.
	<br /><br />
	9. யோசியாவின் குமாரனாகிய யோயாக்கீம் என்னும் யூதா ராஜாவின் ஐந்தாம் வருஷத்து ஒன்பதாம் மாதத்திலே, எருசலேமிலிருக்கிற எல்லா ஜனத்துக்கும், யூதாவின் பட்டணங்களிலிருந்து எருசலேமுக்கு வருகிற எல்லா ஜனத்துக்கும், கர்த்தருக்கு முன்பாக உபவாசம் செய்யவேண்டுமென்று கூறப்பட்டது.
	<br /><br />
	10. அப்பொழுது பாருக்கு கர்த்தருடைய ஆலயத்தின் மேற்பிராகாரத்தில், கர்த்தருடைய ஆலயத்து வாசலின் நடைக்கு அருகான சாப்பானுடைய குமாரனாகிய கெமரியா என்னும் சம்பிரதியின் அறையிலே, அந்தப் புஸ்தகத்திலுள்ள எரேமியாவின் வார்த்தைகளை ஜனங்கள் எல்லாரும் கேட்க வாசித்தான்.
	<br /><br />
	11. சாப்பானுடைய குமாரனாகிய கெமரியாவின் மகன் மிகாயா அந்தப் புஸ்தகத்திலுள்ள கர்த்தருடைய வார்த்தைகளையெல்லாம் வாசிக்கக்கேட்டபோது,
	<br /><br />
	12. அவன் ராஜாவின் அரமனைக்குப் போய், சம்பிரதியின் அறையில் பிரவேசித்தான்; இதோ, அங்கே எல்லாப் பிரபுக்களும் சம்பிரதியாகிய எலிசாமாவும் செமாயாவின் குமாரனாகிய தெலாயாவும், அக்போரின் குமாரனாகிய எல்நாத்தானும், சாப்பானின் குமாரனாகிய கெமரியாவும், அனனியாவின் குமாரனாகிய சிதேக்கியாவும், மற்ற எல்லாப் பிரபுக்களும் உட்கார்ந்திருந்தார்கள்.
	<br /><br />
	13. பாருக்கு ஜனத்தின் செவிகள் கேட்கப் புஸ்தகத்திலுள்ளவைகளை வாசிக்கையில், தான் கேட்ட எல்லா வார்த்தைகளையும் மிகாயா அவர்களுக்குத் தெரிவித்தான்.
	<br /><br />
	14. அப்பொழுது எல்லாப் பிரபுக்களும் கூஷியின் குமாரனாகிய செலேமியாவின் மகனான நெத்தானியாவினுடைய குமாரனாயிருக்கிற யெகுதியைப் பாருக்கினிடத்தில் அனுப்பி, ஜனங்கள் கேட்க நீ வாசித்துக்கொண்டிருந்த சுருளை உன் கையில் எடுத்துக்கொண்டுவா என்று சொல்லச்சொன்னார்கள்; ஆகையால், நேரியாவின் குமாரனாகிய பாருக்கு சுருளைத் தன் கையில் எடுத்துக்கொண்டு, அவர்களிடத்துக்கு வந்தான்.
	<br /><br />
	15. அவர்கள் அவனை நோக்கி: நீ உட்கார்ந்துகொண்டு, நாங்கள் கேட்க வாசியென்றார்கள்; அவர்கள் கேட்க வாசித்தான்.
	<br /><br />
	16. அப்பொழுது அவர்கள் எல்லா வார்த்தைகளையும் கேட்கையில் பயமுற்றவர்களாய் ஒருவரையொருவர் பார்த்து, பாருக்கை நோக்கி: இந்த எல்லா வார்த்தைகளையும் ராஜாவுக்கு நிச்சயமாய் அறிவிப்போம் என்றார்கள்.
	<br /><br />
	17. அவன் வாய் சொல்ல, நீ இந்த வார்த்தைகளையெல்லாம் எவ்விதமாய் எழுதினாய் அதை எங்களுக்குச் சொல் என்று பாருக்கைக் கேட்டார்கள்.
	<br /><br />
	18. அதற்குப் பாருக்கு: அவர் தமது வாயினால் இந்த எல்லா வார்த்தைகளையும் உரைத்து, என்னுடனே சொன்னார், நான் மையினால் புஸ்தகத்தில் எழுதினேன் என்றான்.
	<br /><br />
	19. அப்பொழுது பிரபுக்கள் பாருக்கை நோக்கி: நீயும் எரேமியாவும் போய் ஒளித்துக்கொள்ளுங்கள்; நீங்கள் இருக்கும் இடத்தை ஒருவரும் அறியப்படாது என்று சொல்லி,
	<br /><br />
	20. சுருளைச் சம்பிரதியாகிய எலிசாமாவின் அறையிலே வைத்து, ராஜாவினிடத்துக்கு அரமனையிலே போய், ராஜாவின் செவிகளுக்கு இந்த வார்த்தைகளையெல்லாம் அறிவித்தார்கள்.
	<br /><br />
	21. அப்பொழுது ராஜா அந்தச் சுருளை எடுத்துக்கொண்டுவர யெகுதியை அனுப்பினான்; யெகுதி அதைச் சம்பிரதியாகிய எலிசாமாவின் அறையிலிருந்து எடுத்துக்கொண்டுவந்து, ராஜா கேட்கவும், ராஜாவினிடத்தில் நின்ற எல்லாப் பிரபுக்களும் கேட்கவும் வாசித்தான்.
	<br /><br />
	22. ஒன்பதாம் மாதத்திலே ராஜா, குளிர்காலத்துக்குத் தங்கும் வீட்டில் உட்கார்ந்திருந்தான்; அவனுக்கு முன்பாகக் கணப்பு மூட்டியிருந்தது.
	<br /><br />
	23. யெகுதி மூன்று நாலு பத்திகளை வாசித்த பின்பு, ராஜா ஒரு சூரிக்கத்தியினால் அதை அறுத்து, சுருளனைத்தும் கணப்பிலுள்ள அக்கினியிலே வெந்துபோகும்படி, கணப்பிலிருந்த அக்கினியில் எறிந்துபோட்டான்.
	<br /><br />
	24. ராஜாவாயினும், அந்த வார்த்தைகளையெல்லாம் கேட்ட அவனுடைய சகல ஊழியக்காரராயினும் பயப்படவுமில்லை, தங்கள் வஸ்திரங்களைக் கிழித்துக்கொள்ளவுமில்லை.
	<br /><br />
	25. எல்நாத்தானும், தெலாயாவும், கெமரியாவுமோ: அந்தச் சுருளைச் சுட்டெரிக்கவேண்டாம் என்று ராஜாவினிடத்தில் விண்ணப்பம்பண்ணினார்கள்; ஆனாலும், அவர்களுக்கு அவன் செவிகொடாமல்,
	<br /><br />
	26. பாருக்கு என்னும் சம்பிரதியையும் எரேமியா தீர்க்கதரிசியையும் பிடிக்கும்படிக்கு, ராஜா அம்மெலேகின் குமாரனாகிய யெரமெயேலுக்கும், அஸ்ரியேலின் குமாரனாகிய செராயாவுக்கும், அப்தெயேலின் குமாரனாகிய செலேமியாவுக்கும் கட்டளைகொடுத்தான்; ஆனாலும் கர்த்தர் அவர்களை மறைத்தார்.
	<br /><br />
	27. ராஜா அந்தச் சுருளையும், அதிலே எரேமியாவின் வாய்சொல்லப் பாருக்கு எழுதியிருந்த வார்த்தைகளையும் சுட்டெரித்தபின்பு, எரேமியாவுக்குக் கர்த்தருடைய வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	28. நீ திரும்ப வேறொரு சுருளை எடுத்து, யூதாவின் ராஜாவாகிய யோயாக்கீம் சுட்டெரித்த முதலாம் சுருளிலிருந்த முந்தின வார்த்தைகளையெல்லாம் அதிலே எழுது என்றார்.
	<br /><br />
	29. மேலும் நீ யூதாவின் ராஜாவாகிய யோயாக்கீமை நோக்கி: பாபிலோன் ராஜா நிச்சயமாய் வருவான் என்பதையும், அவன் இந்தத் தேசத்தை அழித்து இதிலிருந்து மனுஷரையும் மிருகங்களையும் ஒழியப்பண்ணுவான் என்பதையும் நீ அதில் எழுதினதேதென்று சொல்லி, அந்தச் சுருளை நீ சுட்டெரித்தாயே என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	30. ஆகையால் யூதாவின் ராஜாவாகிய யோயாக்கீமைக்குறித்து: தாவீதின் சிங்காசனத்தின்மேல் உட்காரும்படி அவன் வம்சத்தில் ஒருவனும் இரான்; அவனுடைய பிரேதமோவென்றால், பகலின் உஷ்ணத்துக்கும் இரவின் குளிருக்கும் எறிந்துவிடப்பட்டுக் கிடக்கும்.
	<br /><br />
	31. நான் அவனிடத்திலும், அவன் சந்ததியினிடத்திலும், அவன் பிரபுக்களினிடத்திலும் அவர்கள் அக்கிரமத்தை விசாரித்து, அவன்மேலும் எருசலேமின் குடிகள்மேலும், யூதா மனுஷர்மேலும், நான் அவர்களுக்குச் சொன்னதும், அவர்கள் கேளாமற்போனதுமான தீங்கனைத்தையும் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார் என்று சொல் என்றார்.
	<br /><br />
	32. அப்பொழுது எரேமியா வேறொரு சுருளை எடுத்து, அதை நேரியாவின் குமாரனாகிய பாருக்கு என்னும் சம்பிரதியினிடத்தில் கொடுத்தான்; அவன் யூதாவின் ராஜாவாகிய யோயாக்கீம் அக்கினியால் சுட்டெரித்த புஸ்தகத்தின் வார்த்தைகளையெல்லாம், அதிலே எரேமியாவின் வாய் சொல்ல எழுதினான்; இன்னும் அவைகளுக்கொத்த அநேகம் வார்த்தைகளும் அவைகளோடே சேர்க்கப்பட்டது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah36