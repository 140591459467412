import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மாற்கு 1 </title>
	<meta
          name="description"
          content="Mark 1 | மாற்கு 1 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தேவனுடைய குமாரனாகிய இயேசு கிறிஸ்துவினுடைய சுவிசேஷத்தின் ஆரம்பம்.
	<br /><br />
	2. இதோ, நான் என் தூதனை உமக்கு முன்பாக அனுப்புகிறேன், அவன் உமக்கு முன்னே போய், உமக்கு வழியை ஆயத்தம்பண்ணுவான் என்றும்;
	<br /><br />
	3. கர்த்தருக்கு வழியை ஆயத்தப்படுத்துங்கள், அவருக்குப் பாதைகளைச் செவ்வைபண்ணுங்கள் என்று வனாந்தரத்திலே கூப்பிடுகிறவனுடைய சத்தம் உண்டாகும் என்றும், தீர்க்கதரிசன ஆகமங்களில் எழுதியிருக்கிற பிரகாரமாய்;
	<br /><br />
	4. யோவான் வனாந்தரத்தில் ஞானஸ்நானங்கொடுத்து, பாவமன்னிப்புக்கென்று மனந்திரும்புதலுக்கேற்ற ஞானஸ்நானத்தைக்குறித்துப் பிரசங்கம்பண்ணிக்கொண்டிருந்தான்.
	<br /><br />
	5. அப்பொழுது யூதேயா தேசத்தார் அனைவரும் எருசலேம் நகரத்தார் யாவரும், அவனிடத்திற்குப்போய், தங்கள் பாவங்களை அறிக்கையிட்டு, யோர்தான் நதியில் அவனால் ஞானஸ்நானம் பெற்றார்கள்.
	<br /><br />
	6. யோவான் ஒட்டகமயிர் உடையைத் தரித்து, தன் அரையில் வார்க்கச்சையைக் கட்டிக்கொண்டவனாயும், வெட்டுக்கிளியையும் காட்டுத்தேனையும் புசிக்கிறவனாயும் இருந்தான்.
	<br /><br />
	7. அவன்: என்னிலும் வல்லவர் ஒருவர் எனக்குப்பின் வருகிறார், அவருடைய பாதரட்சைகளின் வாரைக் குனிந்து அவிழ்க்கிறதற்கும் நான் பாத்திரன் அல்ல.
	<br /><br />
	8. நான் ஜலத்தினால் உங்களுக்கு ஞானஸ்நானம் கொடுத்தேன்; அவரோ பரிசுத்த ஆவியினால் உங்களுக்கு ஞானஸ்நானம் கொடுப்பார் என்று பிரசங்கித்தான்.
	<br /><br />
	9. அந்த நாட்களில், இயேசு கலிலேயாவிலுள்ள நாசரேத்தூரிலிருந்து வந்து, யோர்தான் நதியில் யோவானால் ஞானஸ்நானம் பெற்றார்.
	<br /><br />
	10. அவர் ஜலத்திலிருந்து கரையேறினவுடனே, வானம் திறக்கப்பட்டதையும், ஆவியானவர் புறாவைப்போல் தம்மேல் இறங்குகிறதையும் கண்டார்.
	<br /><br />
	11. அன்றியும், நீர் என்னுடைய நேசகுமாரன், உம்மில் பிரியமாயிருக்கிறேன் என்று, வானத்திலிருந்து ஒரு சத்தம் உண்டாயிற்று.
	<br /><br />
	12. உடனே ஆவியானவர் அவரை வனாந்தரத்திற்குப் போகும்படி ஏவினார்.
	<br /><br />
	13. அவர் வனாந்தரத்திலே நாற்பதுநாள் இருந்து, சாத்தானால் சோதிக்கப்பட்டு, அங்கே காட்டுமிருகங்களின் நடுவிலே சஞ்சரித்துக்கொண்டிருந்தார். தேவதூதர்கள் அவருக்கு ஊழியஞ்செய்தார்கள்.
	<br /><br />
	14. யோவான் காவலில் வைக்கப்பட்ட பின்பு, இயேசு கலிலேயாவிலே வந்து, தேவனுடைய ராஜ்யத்தின் சுவிசேஷத்தைப் பிரசங்கித்து:
	<br /><br />
	15. காலம் நிறைவேறிற்று, தேவனுடைய ராஜ்யம் சமீபமாயிற்று; மனந்திரும்பி, சுவிசேஷத்தை விசுவாசியுங்கள் என்றார்.
	<br /><br />
	16. அவர் கலிலேயாக் கடலோரமாய் நடந்துபோகையில், மீன்பிடிக்கிறவர்களாயிருந்த சீமோனும், அவன் சகோதரன் அந்திரேயாவும் கடலில் வலைபோட்டுக்கொண்டிருக்கிறபோது அவர்களைக் கண்டார்.
	<br /><br />
	17. இயேசு அவர்களை நோக்கி: என் பின்னே வாருங்கள், உங்களை மனுஷரைப் பிடிக்கிறவர்களாக்குவேன் என்றார்.
	<br /><br />
	18. உடனே அவர்கள் தங்கள் வலைகளை விட்டு, அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	19. அவர் அவ்விடம் விட்டுச் சற்று அப்புறம் போனபோது, செபெதேயுவின் குமாரன் யாக்கோபும் அவன் சகோதரன் யோவானும் படவிலே வலைகளைப் பழுதுபார்த்துக்கொண்டிருக்கிறதைக் கண்டு,
	<br /><br />
	20. உடனே அவர்களையும் அழைத்தார்; அப்பொழுது அவர்கள் தங்கள் தகப்பனாகிய செபெதேயுவைக் கூலியாட்களோடு படவிலே விட்டு, அவருக்குப் பின்சென்றார்கள்.
	<br /><br />
	21. பின்பு கப்பர்நகூமுக்குப் போனார்கள். உடனே அவர் ஓய்வுநாளில் ஜெபஆலயத்திலே பிரவேசித்து, போதகம் பண்ணினார்.
	<br /><br />
	22. அவர் வேதபாரகரைப்போலப் போதியாமல், அதிகாரமுடையவராய் அவர்களுக்குப் போதித்தபடியினால், அவருடைய போதகத்தைக்குறித்து ஜனங்கள் ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	23. அவர்களுடைய ஜெபஆலயத்திலே அசுத்த ஆவியுள்ள ஒரு மனுஷன் இருந்தான்.
	<br /><br />
	24. அவன்: ஐயோ! நசரேயனாகிய இயேசுவே, எங்களுக்கும் உமக்கும் என்ன? எங்களைக் கெடுக்கவா வந்தீர்? உம்மை இன்னார் என்று அறிவேன், நீர் தேவனுடைய பரிசுத்தர் என்று சத்தமிட்டான்.
	<br /><br />
	25. அதற்கு இயேசு: நீ பேசாமல் இவனை விட்டுப் புறப்பட்டுப்போ என்று அதை அதட்டினார்.
	<br /><br />
	26. உடனே அந்த அசுத்த ஆவி அவனை அலைக்கழித்து, மிகுந்த சத்தமிட்டு, அவனைவிட்டுப் போய்விட்டது.
	<br /><br />
	27. எல்லாரும் ஆச்சரியப்பட்டு: இது என்ன? இந்தப் புதிய உபதேசம் எப்படிப்பட்டது? இவர் அதிகாரத்தோடே அசுத்த ஆவிகளுக்கும் கட்டளையிடுகிறார், அவைகள் இவருக்குக் கீழ்ப்படிகிறதே என்று தங்களுக்குள்ளே ஒருவரோடொருவர் சொல்லிக்கொண்டார்கள்.
	<br /><br />
	28. அதுமுதல் அவருடைய கீர்த்தி கலிலேயா நாடெங்கும் பிரசித்தமாயிற்று.
	<br /><br />
	29. உடனே அவர்கள் ஜெபஆலயத்தை விட்டுப் புறப்பட்டு, யாக்கோபோடும் யோவானோடுங்கூட, சீமோன் அந்திரேயா என்பவர்களுடைய வீட்டில் பிரவேசித்தார்கள்.
	<br /><br />
	30. அங்கே சீமோனுடைய மாமி ஜுரமாய்க் கிடந்தாள்; உடனே அவர்கள் அவளைக்குறித்து அவருக்குச் சொன்னார்கள்.
	<br /><br />
	31. அவர் கிட்டப்போய், அவள் கையைப் பிடித்து, அவளைத் தூக்கிவிட்டார்; உடனே ஜுரம் அவளை விட்டு நீங்கிற்று; அப்பொழுது அவள் அவர்களுக்குப் பணிவிடைசெய்தாள்.
	<br /><br />
	32. சாயங்காலமாகிச் சூரியன் அஸ்தமித்தபோது, சகல பிணியாளிகளையும், பிசாசுபிடித்தவர்களையும், அவரிடத்தில் கொண்டுவந்தார்கள்.
	<br /><br />
	33. பட்டணத்தார் எல்லாரும் வீட்டு வாசலுக்கு முன்பாகக் கூடிவந்தார்கள்.
	<br /><br />
	34. பலவிதமான வியாதிகளினால் உபத்திரவப்பட்டிருந்த அநேகரை அவர் சொஸ்தமாக்கி, அநேகம் பிசாசுகளையும் துரத்திவிட்டார்; அந்தப் பிசாசுகள் தம்மை அறிந்திருந்தபடியால், அவைகள் பேசுகிறதற்கு அவர் இடங்கொடுக்கவில்லை.
	<br /><br />
	35. அவர் அதிகாலையில், இருட்டோடே எழுந்து புறப்பட்டு, வனாந்தரமான ஓரிடத்திற்குப்போய், அங்கே ஜெபம்பண்ணினார்.
	<br /><br />
	36. சீமோனும் அவனோடே இருந்தவர்களும் அவரைப் பின்தொடர்ந்துபோய்,
	<br /><br />
	37. அவரைக் கண்டபோது: உம்மை எல்லாரும் தேடுகிறார்கள் என்று சொன்னார்கள்.
	<br /><br />
	38. அவர்களை அவர் நோக்கி: அடுத்த ஊர்களிலும் நான் பிரசங்கம்பண்ண வேண்டுமாதலால், அவ்விடங்களுக்குப் போவோம் வாருங்கள்; இதற்காகவே புறப்பட்டுவந்தேன் என்று சொல்லி;
	<br /><br />
	39. கலிலேயா நாடெங்கும் அவர்களுடைய ஜெபஆலயங்களில் அவர் பிரசங்கம் பண்ணிக்கொண்டும், பிசாசுகளைத் துரத்திக்கொண்டும் இருந்தார்.
	<br /><br />
	40. அப்பொழுது குஷ்டரோகி ஒருவன் அவரிடத்தில் வந்து, அவர் முன்பாக முழங்கால்படியிட்டு: உமக்குச் சித்தமானால் என்னைச் சுத்தமாக்க உம்மால் ஆகும் என்று வேண்டிக்கொண்டான்.
	<br /><br />
	41. இயேசு மனதுருகி, கையை நீட்டி, அவனைத் தொட்டு: எனக்குச் சித்தமுண்டு, சுத்தமாகு என்றார்.
	<br /><br />
	42. இப்படி அவர் சொன்னவுடனே, குஷ்டரோகம் அவனை விட்டு நீங்கிற்று, அவன் சுத்தமானான்.
	<br /><br />
	43. அப்பொழுது அவர் அவனை நோக்கி: நீ இதை ஒருவருக்கும் சொல்லாதபடிக்கு எச்சரிக்கையாயிரு;
	<br /><br />
	44. ஆயினும் நீ போய், ஆசாரியனுக்கு உன்னைக் காண்பித்து, நீ சுத்தமானதினிமித்தம், மோசே கட்டளையிட்டிருக்கிறவைகளை அவர்களுக்குச் சாட்சியாகச் செலுத்து என்று கண்டிப்பாய்ச் சொல்லி, உடனே அவனை அனுப்பிவிட்டார்.
	<br /><br />
	45. அவனோ புறப்பட்டுப்போய், இந்தச் சங்கதி எங்கும் விளங்கும்படியாகப் பிரசித்தம்பண்ணத்தொடங்கினான். அதினால் அவர் வெளியரங்கமாய்ப் பட்டணத்தில் பிரவேசிக்கக்கூடாமல், வெளியே வனாந்தரமான இடங்களில் தங்கியிருந்தார்; எத்திசையிலுமிருந்து ஜனங்கள் அவரிடத்திற்கு வந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark1