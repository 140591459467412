import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 12 </title>
	<meta
          name="description"
          content="Zechariah 12 | சகரியா 12 | Sageria 12 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேலைக்குறித்துக் கர்த்தர் சொன்ன வார்த்தையின் பாரம்; வானங்களை விரித்து, பூமியை அஸ்திபாரப்படுத்தி, மனுஷனுடைய ஆவியை அவனுக்குள் உண்டாக்குகிற கர்த்தர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	2. இதோ, சுற்றிலும் இருக்கிற எல்லா ஜனங்களுக்கும் நான் எருசலேமைத் தத்தளிப்பின் பாத்திரமாக்குகிறேன்; எருசலேமுக்கு விரோதமாய்ப் போடப்படும் முற்றிகையிலே யூதாவும் அப்படியேயாகும்.
	<br /><br />
	3. அந்நாளிலே நான் எருசலேமைச் சகல ஜனங்களுக்கும் பாரமான கல்லாக்குவேன்; அதைக் கிளப்புகிற யாவரும் சிதைக்கப்படுவார்கள்; பூமியிலுள்ள ஜாதிகளெல்லாம் அதற்கு விரோதமாய்க் கூடிக்கொள்வார்கள்.
	<br /><br />
	4. அந்நாளிலே நான் குதிரைகளுக்கெல்லாம் திகைப்பையும், அவைகளின்மேல் ஏறியிருக்கிறவர்களுக்கெல்லாம் புத்திமயக்கத்தையும் வரப்பண்ணி, யூதாவம்சத்தின்மேல் என் கண்களைத் திறந்துவைத்து, ஜனங்களுடைய எல்லாக் குதிரைகளுக்கும் குருட்டாட்டத்தை உண்டுபண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. எருசலேமின் குடிகள், சேனைகளின் கர்த்தராகிய தங்கள் தேவனுடைய துணையினால் எங்களுக்குப் பெலனானவர்கள் என்று அப்போது யூதாவின் தலைவர் தங்கள் இருதயத்திலே சொல்லுவார்கள்.
	<br /><br />
	6. அந்நாளிலே யூதாவின் தலைவரை விறகுகளுக்குள்ளே எரிகிற அக்கினி அடுப்புக்கும், வைக்கோல் கட்டுகளுக்குள்ளே எரிகிற தீவட்டிக்கும் ஒப்பாக்குவேன்; அவர்கள் வலதுபுறமும் இடதுபுறமுமாகப் புறப்பட்டு, சுற்றிலும் இருக்கிற எல்லா ஜனங்களையும் பட்சிப்பார்கள்; எருசலேம் திரும்பவும் தன் ஸ்தானமாகிய எருசலேமிலே குடியேற்றப்பட்டிருக்கும்.
	<br /><br />
	7. தாவீது வம்சத்தாரின் மகிமையும், எருசலேமின் குடிகளுடைய மகிமையும், யூதாவின்மேல் தன்னை உயர்த்தாதபடிக்கு, கர்த்தர் யூதாவின் கூடாரங்களை முதல்முதல் இரட்சிப்பார்.
	<br /><br />
	8. அந்நாளிலே கர்த்தர் எருசலேமின் குடிகளைக் காப்பாற்றுவார்; அவர்களில் தள்ளாடினவன் அந்நாளிலே தாவீதைப்போல இருப்பான்; தாவீது குடும்பத்தார் அவர்களுக்கு முன்பாகத் தேவனைப்போலும் கர்த்தருடைய தூதனைப்போலும் இருப்பார்கள்.
	<br /><br />
	9. அந்நாளிலே எருசலேமுக்கு விரோதமாய் வருகிற எல்லா ஜாதிகளையும் அழிக்கப் பார்ப்பேன்.
	<br /><br />
	10. நான் தாவீது குடும்பத்தாரின்மேலும் எருசலேம் குடிகளின்மேலும் கிருபையின் ஆவியையும் விண்ணப்பங்களின் ஆவியையும் ஊற்றுவேன்; அப்பொழுது அவர்கள் தாங்கள் குத்தின என்னை நோக்கிப்பார்த்து, ஒருவன் தன் ஒரே பேறானவனுக்காகப் புலம்புகிறதுபோல எனக்காகப் புலம்பி, ஒருவன் தன் தலைச்சன் பிள்ளைக்காகத் துக்கிக்கிறதுபோல எனக்காக மனங்கசந்து துக்கிப்பார்கள்.
	<br /><br />
	11. அந்நாளிலே மெகிதோன் பட்டணத்துப் பள்ளத்தாக்கின் ஊராகிய ஆதாத்ரிம்மோனின் புலம்பலைப்போல எருசலேமின் புலம்பல் பெரிதாயிருக்கும்.
	<br /><br />
	12. தேசம் புலம்பிக்கொண்டிருக்கும்; ஒவ்வொரு வம்சமும் தனித்தனியாகப் புலம்பும்; தாவீது குடும்பத்தார் தனியேயும், அவர்கள் ஸ்திரீகள் தனியேயும், நாத்தான் குடும்பத்தார் தனியேயும், அவர்கள் ஸ்திரீகள் தனியேயும்,
	<br /><br />
	13. லேவி குடும்பத்தார் தனியேயும், அவர்கள் ஸ்திரீகள் தனியேயும், சீமேயி குடும்பத்தார் தனியேயும், அவர்கள் ஸ்திரீகள் தனியேயும்,
	<br /><br />
	14. மற்றுமுண்டான சகல குடும்பங்களிலும் ஒவ்வொரு குடும்பத்தின் மனுஷர் தனித்தனியேயும் அவர்களுடைய ஸ்திரீகள் தனித்தனியேயும் புலம்புவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah12