import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah61 = () => {
  const verseNumber = 61;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 61 </title>
	<meta
          name="description"
          content="Isaiah 61 | ஏசாயா 61 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தராகிய தேவனுடைய ஆவியானவர் என்மேல் இருக்கிறார்; சிறுமைப்பட்டவர்களுக்குச் சுவிசேஷத்தை அறிவிக்கக் கர்த்தர் என்னை அபிஷேகம்பண்ணினார்; இருதயம் நொறுங்குண்டவர்களுக்குக் காயங்கட்டுதலையும், சிறைப்பட்டவர்களுக்கு விடுதலையையும், கட்டுண்டவர்களுக்குக் கட்டவிழ்த்தலையும் கூறவும்,
	<br /><br />
	2. கர்த்தருடைய அநுக்கிரக வருஷத்தையும், நம்முடைய தேவன் நீதியைச்சரிக்கட்டும் நாளையும் கூறவும், துயரப்பட்ட அனைவருக்கும் ஆறுதல் செய்யவும்,
	<br /><br />
	3. சீயோனிலே துயரப்பட்டவர்களைச் சீர்ப்படுத்தவும், அவர்களுக்குச் சாம்பலுக்குப் பதிலாகச் சிங்காரத்தையும், துயரத்துக்குப் பதிலாக ஆனந்த தைலத்தையும், ஒடுங்கின ஆவிக்குப் பதிலாகத் துதியின் உடையையும் கொடுக்கவும், அவர் என்னை அனுப்பினார்; அவர்கள் கர்த்தர் தம்முடைய மகிமைக்கென்று நாட்டின நீதியின் விருட்சங்களென்னப்படுவார்கள்.
	<br /><br />
	4. அவர்கள் நெடுங்காலம் பாழாய்க் கிடந்தவைகளைக் கட்டி, பூர்வத்தில் நிர்மூலமானவைகளை எடுப்பித்து, தலைமுறை தலைமுறையாய் இடிந்துகிடந்த பாழான பட்டணங்களைப் புதிதாய்க் கட்டுவார்கள்.
	<br /><br />
	5. மறுஜாதியார் நின்றுகொண்டு உங்கள் மந்தைகளை மேய்த்து, அந்நிய புத்திரர் உங்கள் பண்ணையாட்களும், உங்கள் திராட்சத்தோட்டக்காரருமாயிருப்பார்கள்.
	<br /><br />
	6. நீங்களோ கர்த்தரின் ஆசாரியரென்று சொல்லப்படுவீர்கள்; உங்களை நமது தேவனுடைய பணிவிடைக்காரரென்பார்கள்; நீங்கள் ஜாதிகளின் செல்வத்தை அநுபவித்து, அவர்கள் மகிமையைக்கொண்டு மேன்மைபாராட்டுவீர்கள்.
	<br /><br />
	7. உங்கள் வெட்கத்துக்குப் பதிலாக இரண்டத்தனையாய்ப் பலன் வரும்; இலச்சைக்குப் பதிலாகத் தங்கள் பாகத்தில் சந்தோஷப்படுவார்கள்; அதினிமித்தம் தங்கள் தேசத்தில் இரட்டிப்பான சுதந்தரம் அடைவார்கள்; நித்திய மகிழ்ச்சி அவர்களுக்கு உண்டாகும்.
	<br /><br />
	8. கர்த்தராகிய நான் நியாயத்தை விரும்பி, கொள்ளைப்பொருளினால் இடப்பட்ட தகனபலியை வெறுக்கிறேன்; நான் அவர்கள் கிரியையை உண்மையாக்கி, அவர்களோடே நித்திய உடன்படிக்கை பண்ணுவேன்.
	<br /><br />
	9. அவர்கள் சந்ததியானது ஜாதிகளின் நடுவிலும், அவர்கள் சந்தானமானது ஜனங்களின் நடுவிலும் அறியப்பட்டிருக்கும்; அவர்களைப் பார்க்கிற யாவரும் அவர்கள் கர்த்தரால் ஆசீர்வாதம் பெற்ற சந்ததியென்று அறிந்துகொள்வார்கள்.
	<br /><br />
	10. கர்த்தருக்குள் பூரிப்பாய் மகிழுகிறேன்; என் தேவனுக்குள் என் ஆத்துமா களிகூர்ந்திருக்கிறது; மணவாளன் ஆபரணங்களினால் தன்னை அலங்கரித்துக்கொள்ளுகிறதற்கும், மணவாட்டி நகைகளினால் தன்னைச் சிங்காரித்துக்கொள்ளுகிறதற்கும் ஒப்பாக, அவர் இரட்சிப்பின் வஸ்திரங்களை எனக்கு உடுத்தி, நீதியின் சால்வையை எனக்குத் தரித்தார்.
	<br /><br />
	11. பூமி தன் பூண்டுகளை விளைவிப்பதுபோலவும், தோட்டம் தன்னில் விதைக்கப்பட்டவைகளை முளைவிப்பதுபோலவும், கர்த்தராகிய ஆண்டவர் எல்லா ஜாதிகளுக்கும் முன்பாக நீதியையும் துதியையும் முளைக்கப்பண்ணுவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah61