import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 13 </title>
	<meta
          name="description"
          content="Mark 13 | மாற்கு 13 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் தேவாலயத்தை விட்டுப் புறப்படும்போது, அவருடைய சீஷர்களில் ஒருவன் அவரை நோக்கி: போதகரே, இதோ, இந்தக் கல்லுகள் எப்படிப்பட்டது! இந்தக் கட்டடங்கள் எப்படிப்பட்டது! பாரும் என்றான்.
	<br /><br />
	2. இயேசு அவனுக்குப் பிரதியுத்தரமாக: இந்தப் பெரிய கட்டடங்களைக் காண்கிறாயே, ஒரு கல்லின்மேல் ஒரு கல்லிராதபடிக்கு எல்லாம் இடிக்கப்பட்டுப்போகும் என்றார்.
	<br /><br />
	3. பின்பு, அவர் தேவாலயத்துக்கு எதிராக ஒலிவமலையின்மேல் உட்கார்ந்திருக்கையில், பேதுருவும் யாக்கோபும் யோவானும் அந்திரேயாவும் அவரிடத்தில் தனித்துவந்து:
	<br /><br />
	4. இவைகள் எப்பொழுது சம்பவிக்கும்? இவைகளெல்லாம் நிறைவேறுங்காலத்துக்கு அடையாளம் என்ன? எங்களுக்குச் சொல்லவேண்டும் என்றார்கள்.
	<br /><br />
	5. இயேசு அவர்களுக்குப் பிரதியுத்தரமாக: ஒருவனும் உங்களை வஞ்சியாதபடிக்கு எச்சரிக்கையாயிருங்கள்.
	<br /><br />
	6. ஏனெனில் அநேகர் வந்து, என் நாமத்தைக்கொண்டு: நானே கிறிஸ்து என்று சொல்லி, அநேகரை வஞ்சிப்பார்கள்.
	<br /><br />
	7. யுத்தங்களையும் யுத்தங்களின் செய்திகளையும் கேள்விப்படும்போது கலங்காதேயுங்கள்; இவைகள் சம்பவிக்கவேண்டியதே. ஆனாலும், முடிவு உடனே வராது.
	<br /><br />
	8. ஜனத்துக்கு விரோதமாய் ஜனமும், ராஜ்யத்துக்கு விரோதமாய் ராஜ்யமும் எழும்பும்; பூமியதிர்ச்சிகளும் பல இடங்களில் உண்டாகும், பஞ்சங்களும் கலகங்களும் உண்டாகும்; இவைகள் வேதனைகளுக்கு ஆரம்பம்.
	<br /><br />
	9. நீங்களோ எச்சரிக்கையாயிருங்கள், ஏனெனில் உங்களை ஆலோசனைச் சங்கங்களுக்கு ஒப்புக்கொடுப்பார்கள், நீங்கள் ஜெபஆலயங்களில் அடிக்கப்படுவீர்கள்; என்னிமித்தம் தேசாதிபதிகளுக்கும் ராஜாக்களுக்கும் சாட்சியாக அவர்களுக்கு முன்பாக நிறுத்தப்படுவீர்கள்.
	<br /><br />
	10. சகல ஜாதிகளுக்கும் சுவிசேஷம் முந்திப் பிரசங்கிக்கப்படவேண்டும்.
	<br /><br />
	11. அவர்கள் உங்களைக் கொண்டுபோய் ஒப்புக்கொடுக்கும்போது, நீங்கள் என்ன பேசுவோமென்று முன்னதாகக் கவலைப்படாமலும் சிந்தியாமலுமிருங்கள்; அந்த நாழிகையிலே உங்களுக்கு எது அருள் செய்யப்படுமோ அதையே பேசுங்கள்; ஏனெனில் பேசுகிறவர்கள் நீங்களல்ல, பரிசுத்த ஆவியே பேசுகிறவர்.
	<br /><br />
	12. அன்றியும் சகோதரன் சகோதரனையும், தகப்பன் பிள்ளையையும் மரணத்துக்கு ஒப்புக்கொடுப்பார்கள்; பெற்றாருக்கு விரோதமாகப் பிள்ளைகள் எழும்பி, அவர்களைக் கொலைசெய்வார்கள்.
	<br /><br />
	13. என் நாமத்தினிமித்தம் எல்லாராலும் பகைக்கப்படுவீர்கள். முடிவுபரியந்தம் நிலைநிற்பவனே இரட்சிக்கப்படுவான்.
	<br /><br />
	14. மேலும் பாழாக்குகிற அருவருப்பைக்குறித்துத் தானியேல் தீர்க்கதரிசி சொல்லியிருக்கிறானே; வாசிக்கிறவன் சிந்திக்கக்கடவன்; அது நிற்கத்தகாத இடத்திலே நீங்கள் அதை நிற்கக் காணும்போது, யூதேயாவில் இருக்கிறவர்கள் மலைகளுக்கு ஓடிப்போகக்கடவர்கள்.
	<br /><br />
	15. வீட்டின்மேல் இருக்கிறவன் தன் வீட்டுக்குள் இறங்காமலும், தன் வீட்டில் எதையாகிலும் எடுத்துக்கொள்ள உள்ளே போகாமலும் இருக்கக்கடவன்.
	<br /><br />
	16. வயலில் இருக்கிறவன் தன் வஸ்திரத்தை எடுப்பதற்குப் பின்னிட்டுத் திரும்பாதிருக்கக்கடவன்.
	<br /><br />
	17. அந்நாட்களிலே கர்ப்பவதிகளுக்கும் பால்கொடுக்கிறவர்களுக்கும் ஐயோ!
	<br /><br />
	18. நீங்கள் ஓடிப்போவது மாரிகாலத்திலே சம்பவியாதபடிக்கு வேண்டிக்கொள்ளுங்கள்.
	<br /><br />
	19. ஏனெனில் தேவன் உலகத்தைச் சிருஷ்டித்ததுமுதல் இதுவரைக்கும் சம்பவித்திராததும், இனிமேலும் சம்பவியாததுமான உபத்திரவம் அந்நாட்களில் உண்டாயிருக்கும்.
	<br /><br />
	20. கர்த்தர் அந்நாட்களைக் குறைத்திராவிட்டால், ஒருவனாகிலும் தப்பிப்போவதில்லை; தாம் தெரிந்துகொண்டவர்களினிமித்தமோ, அவர் அந்த நாட்களைக் குறைத்திருக்கிறார்.
	<br /><br />
	21. அப்பொழுது: இதோ, கிறிஸ்து இங்கே இருக்கிறார், அதோ, அங்கேயிருக்கிறார் என்று எவனாகிலும் சொன்னால், நம்பாதேயுங்கள்.
	<br /><br />
	22. ஏனெனில் கள்ளக்கிறிஸ்துக்களும் கள்ளத்தீர்க்கதரிசிகளும் எழும்பி, கூடுமானால் தெரிந்துகொள்ளப்பட்டவர்களையும் வஞ்சிக்கத்தக்கதாக அடையாளங்களையும் அற்புதங்களையும் செய்வார்கள்.
	<br /><br />
	23. நீங்களோ எச்சரிக்கையாயிருங்கள்; இதோ, எல்லாவற்றையும் முன்னதாக உங்களுக்கு அறிவித்திருக்கிறேன்.
	<br /><br />
	24. அந்நாட்களிலே, அந்த உபத்திரவத்திற்குப் பின்பு, சூரியன் அந்தகாரப்படும், சந்திரன் ஒளியைக் கொடாதிருக்கும்;
	<br /><br />
	25. வானத்தின் நட்சத்திரங்கள் விழும், வானங்களிலுள்ள சத்துவங்களும் அசைக்கப்படும்.
	<br /><br />
	26. அப்பொழுது மனுஷகுமாரன் மிகுந்த வல்லமையோடும் மகிமையோடும் மேகங்களின்மேல் வருகிறதைக் காண்பார்கள்.
	<br /><br />
	27. அப்பொழுது அவர் தம்முடைய தூதரை அனுப்பி, தாம் தெரிந்துகொண்டவர்களைப் பூமியின் கடைமுனை முதற்கொண்டு, வானத்தின் கடைமுனைமட்டுமுள்ள நாலு திசைகளிலுமிருந்து கூட்டிச் சேர்ப்பார்.
	<br /><br />
	28. அத்திமரத்தினால் ஒரு உவமையைக் கற்றுக்கொள்ளுங்கள்; அதிலே இளங்கிளை தோன்றி, துளிர்விடும்போது, வசந்தகாலம் சமீபமாயிற்று என்று அறிவீர்கள்.
	<br /><br />
	29. அப்படியே இவைகள் சம்பவிக்கிறதை நீங்கள் காணும்போது, அவர் சமீபமாய் வாசலருகே வந்திருக்கிறார் என்று அறியுங்கள்.
	<br /><br />
	30. இவைகளெல்லாம் சம்பவிக்கும் முன்னே இந்தச் சந்ததி ஒழிந்துபோகாதென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	31. வானமும் பூமியும் ஒழிந்துபோம், என் வார்த்தைகளோ ஒழிந்துபோவதில்லை.
	<br /><br />
	32. அந்த நாளையும் அந்த நாழிகையையும் பிதா ஒருவர் தவிர மற்றொருவனும் அறியான், பரலோகத்திலுள்ள தூதர்களும் அறியார்கள், குமாரனும் அறியார்.
	<br /><br />
	33. அக்காலத்தை நீங்கள் அறியாதபடியால் எச்சரிக்கையாயிருங்கள், விழித்திருந்து ஜெபம்பண்ணுங்கள்.
	<br /><br />
	34. ஒரு மனுஷன் தன் வீட்டைவிட்டு, புறத்தேசத்துக்குப் பிரயாணம்போக எத்தனிக்கும்போது, தன் ஊழியக்காரருக்கு அதிகாரங்கொடுத்து, அவனவனுக்குத் தன் தன் வேலையையும் நியமித்து, விழித்திருக்கும்படிக்குக் காவல்காக்கிறவனுக்குக் கற்பிப்பான்.
	<br /><br />
	35. அப்படியே நீங்களும் விழித்திருங்கள்; ஏனெனில், வீட்டெஜமான் சாயங்காலத்திலோ, நடுராத்திரியிலோ, சேவல் கூவும் நேரத்திலோ, காலையிலோ, எப்பொழுது வருவான் என்று நீங்கள் அறியீர்கள்.
	<br /><br />
	36. நீங்கள் நினையாதவேளையில் அவன் வந்து, உங்களைத் தூங்குகிறவர்களாகக் கண்டுபிடியாதபடிக்கு விழித்திருங்கள்.
	<br /><br />
	37. நான் உங்களுக்குச் சொல்லுகிறதை எல்லாருக்கும் சொல்லுகிறேன், விழித்திருங்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark13