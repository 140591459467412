import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 6 | Tamil Bible Verse | வெளிப்படுத்தல் - 6 </title>
	<meta
          name="description"
          content="The Book of Revelation - 6 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 7 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஆட்டுக்குட்டி நான்கு முத்திரைகளைத் திறந்தார், குதிரைவீரர்கள் அழிக்கப் புறப்பட்டனர். ஐந்தாவது முத்திரையுடன் நான் தியாகிகளைப் பார்த்தேன். ஆறாவது உடன் நட்சத்திரங்கள் விழுந்தன. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. ஆட்டுக்குட்டியானவர் முத்திரைகளில் ஒன்றை உடைக்கக்கண்டேன். அப்பொழுது நான்கு ஜீவன்களில் ஒன்று என்னை நோக்கி: நீ வந்து பார் என்று இடிமுழக்கம்போன்ற சத்தமாய்ச் சொல்லக்கேட்டேன்.
	<br /><br />
	2. நான் பார்த்தபோது, இதோ, ஒரு வெள்ளைக்குதிரையைக் கண்டேன்; அதின்மேல் ஏறியிருந்தவன் வில்லைப் பிடித்திருந்தான்; அவனுக்கு ஒரு கிரீடங் கொடுக்கப்பட்டது; அவன் ஜெயிக்கிறவனாகவும் ஜெயிப்பவனாகவும் புறப்பட்டான்.
	<br /><br />
	3. அவர் இரண்டாம் முத்திரையை உடைத்தபோது, இரண்டாம் ஜீவனானது: நீ வந்துபார் என்று சொல்லக்கேட்டேன்.
	<br /><br />
	4. அப்பொழுது சிவப்பான வேறொரு குதிரை புறப்பட்டது; அதின்மேல் ஏறியிருந்தவனுக்கு, பூமியிலுள்ளவர்கள் ஒருவரையொருவர் கொலைசெய்யத்தக்கதாகச் சமாதானத்தைப் பூமியிலிருந்தெடுத்துப் போடும்படியான அதிகாரம் கொடுக்கப்பட்டது; ஒரு பெரிய பட்டயமும் அவனுக்குக் கொடுக்கப்பட்டது.
	<br /><br />
	5. அவர் மூன்றாம் முத்திரையை உடைத்தபோது, மூன்றாம் ஜீவனானது: நீ வந்துபார் என்று சொல்லக்கேட்டேன். நான் பார்த்தபோது, இதோ, ஒரு கறுப்புக்குதிரையைக் கண்டேன்; அதின்மேல் ஏறியிருந்தவன் ஒரு தராசைத் தன் கையிலே பிடித்திருந்தான்.
	<br /><br />
	6. அப்பொழுது, ஒரு பணத்துக்கு ஒருபடி கோதுமையென்றும், ஒரு பணத்துக்கு மூன்றுபடி வாற்கோதுமையென்றும், எண்ணெயையும் திராட்சரசத்தையும் சேதப்படுத்தாதே என்றும், நான்கு ஜீவன்களின் மத்தியிலிருந்து உண்டான சத்தத்தைக் கேட்டேன்.
	<br /><br />
	7. அவர் நாலாம் முத்திரையை உடைத்தபோது, நாலாம் ஜீவனானது: நீ வந்துபார் என்று சொல்லுஞ் சத்தத்தைக் கேட்டேன்.
	<br /><br />
	8. நான் பார்த்தபோது, இதோ, மங்கின நிறமுள்ள ஒரு குதிரையைக் கண்டேன்; அதின்மேல் ஏறியிருந்தவனுக்கு மரணம் என்று பெயர்; பாதாளம் அவன்பின் சென்றது. பட்டயத்தினாலும், பஞ்சத்தினாலும், சாவினாலும், பூமியின் துஷ்ட மிருகங்களினாலும், பூமியின் காற்பங்கிலுள்ளவர்களைக் கொலைசெய்யும்படியான அதிகாரம் அவைகளுக்குக் கொடுக்கப்பட்டது.
	<br /><br />
	9. அவர் ஐந்தாம் முத்திரையை உடைத்தபோது, தேவவசனத்தினிமித்தமும் தாங்கள் கொடுத்த சாட்சியினிமித்தமும் கொல்லப்பட்டவர்களுடைய ஆத்துமாக்களைப் பலிபீடத்தின்கீழே கண்டேன்.
	<br /><br />
	10. அவர்கள்: பரிசுத்தமும் சத்தியமுமுள்ள ஆண்டவரே, தேவரீர் பூமியின்மேல் குடியிருக்கிறவர்களிடத்தில் எங்கள் இரத்தத்தைக்குறித்து எதுவரைக்கும் நியாயத்தீர்ப்புச்செய்யாமலும் பழிவாங்காமலும் இருப்பீர் என்று மகா சத்தமிட்டுக் கூப்பிட்டார்கள்.
	<br /><br />
	11. அப்பொழுது அவர்கள் ஒவ்வொருவருக்கும் வெள்ளை அங்கிகள் கொடுக்கப்பட்டது; அன்றியும், அவர்கள் தங்களைப்போலக் கொலைசெய்யப்படப்போகிறவர்களாகிய தங்கள் உடன்பணிவிடைக்காரரும் தங்கள் சகோதரருமானவர்களின் தொகை நிறைவாகுமளவும் இன்னுங்கொஞ்சக்காலம் இளைப்பாறவேண்டும் என்று அவர்களுக்குச் சொல்லப்பட்டது.
	<br /><br />
	12. அவர் ஆறாம் முத்திரையை உடைக்கக்கண்டேன்; இதோ, பூமி மிகவும் அதிர்ந்தது; சூரியன் கறுப்புக் கம்பளியைப்போலக் கறுத்தது; சந்திரன் இரத்தம் போலாயிற்று.
	<br /><br />
	13. அத்திமரமானது பெருங்காற்றினால் அசைக்கப்படும்போது, அதின் காய்கள் உதிருகிறதுபோல, வானத்தின் நட்சத்திரங்களும் பூமியிலே விழுந்தது.
	<br /><br />
	14. வானமும் சுருட்டப்பட்ட புஸ்தகம் போலாகி விலகிப்போயிற்று; மலைகள் தீவுகள் யாவும் தங்கள் இடங்களைவிட்டு அகன்றுபோயின.
	<br /><br />
	15. பூமியின் ராஜாக்களும், பெரியோர்களும், ஐசுவரியவான்களும், சேனைத்தலைவர்களும், பலவான்களும், அடிமைகள் யாவரும், சுயாதீனர் யாவரும், பர்வதங்களின் குகைகளிலும் கன்மலைகளிலும் ஒளித்துக்கொண்டு,
	<br /><br />
	16. பர்வதங்களையும் கன்மலைகளையும் நோக்கி: நீங்கள் எங்கள்மேல் விழுந்து, சிங்காசனத்தின்மேல் வீற்றிருக்கிறவருடைய முகத்திற்கும், ஆட்டுக்குட்டியானவருடைய கோபத்திற்கும் எங்களை மறைத்துக்கொள்ளுங்கள்;
	<br /><br />
	17. அவருடைய கோபாக்கினையின் மகாநாள் வந்துவிட்டது, யார் நிலைநிற்கக்கூடும் என்றார்கள்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h6>
*  *
			</h6>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation6