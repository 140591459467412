import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 9 | Tamil Bible Verse | வெளிப்படுத்தல் - 9 </title>
	<meta
          name="description"
          content="The Book of Revelation - 9 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 9 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஐந்தாவது எக்காளத்தில், குழியிலிருந்து வெட்டுக்கிளிகள் பூமியைத் துன்புறுத்தியது. ஆறாவது எக்காளத்தில், நான்கு தேவதூதர்கள் மனிதகுலத்தின் மூன்றில் ஒரு பகுதியை நெருப்பால் கொன்றனர். *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. ஐந்தாம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது வானத்திலிருந்து பூமியின்மேல் விழுந்த ஒரு நட்சத்திரத்தைக் கண்டேன்; அவனுக்குப் பாதாளக்குழியின் திறவுகோல் கொடுக்கப்பட்டது.
	<br /><br />
	2. அவன் பாதாளக்குழியைத் திறந்தான்; உடனே பெருஞ்சூளையின் புகையைப்போல அந்தக் குழியிலிருந்து புகைஎழும்பிற்று; அந்தக் குழியின் புகையினால் சூரியனும் ஆகாயமும் அந்தகாரப்பட்டது.
	<br /><br />
	3. அந்தப் புகையிலிருந்து வெட்டுக்கிளிகள் புறப்பட்டுப் பூமியின்மேல் வந்தது; அவைகளுக்குப் பூமியிலுள்ள தேள்களின் வல்லமைக்கொப்பான வல்லமை கொடுக்கப்பட்டது.
	<br /><br />
	4. பூமியின் புல்லையும் பசுமையான எந்தப் பூண்டையும் எந்த மரத்தையும் சேதப்படுத்தாமல், தங்கள் நெற்றிகளில் தேவனுடைய முத்திரையைத் தரித்திராத மனுஷரைமாத்திரம் சேதப்படுத்த அவைகளுக்கு உத்தரவு கொடுக்கப்பட்டது.
	<br /><br />
	5. மேலும் அவர்களைக் கொலைசெய்யும்படிக்கு அவைகளுக்கு உத்தரவு கொடுக்கப்படாமல், ஐந்துமாதமளவும் அவர்களை வேதனைப்படுத்தும்படிக்கு உத்தரவு கொடுக்கப்பட்டது; அவைகள் செய்யும் வேதனை தேளானது மனுஷரைக் கொட்டும்போது உண்டாகும் வேதனையைப்போலிருக்கும்.
	<br /><br />
	6. அந்நாட்களில் மனுஷர்கள் சாவைத்தேடியும் அதைக் காணாதிருப்பார்கள், சாகவேண்டுமென்று ஆசைப்படுவார்கள், சாவோ அவர்களுக்கு விலகி ஓடிப்போம்.
	<br /><br />
	7. அந்த வெட்டுக்கிளிகளின் உருவம் யுத்தத்திற்கு ஆயத்தம்பண்ணப்பட்ட குதிரைகளுக்கு ஒப்பாயிருந்தது; அவைகளுடைய தலைகளின்மேல் பொன்மயமான கிரீடம் போன்றவைகளிருந்தன; அவைகளின் முகங்கள் மனுஷருடைய முகங்கள்போலிருந்தன.
	<br /><br />
	8. அவைகளுடைய கூந்தல் ஸ்திரீகளுடைய கூந்தல்போலிருந்தது; அவைகளின் பற்கள் சிங்கங்களின் பற்கள்போலிருந்தன.
	<br /><br />
	9. இருப்புக் கவசங்களைப்போல மார்க்கவசங்கள் அவைகளுக்கு இருந்தன; அவைகளுடைய சிறகுகளின் இரைச்சல் யுத்தத்திற்கு ஓடுகிற அநேகங் குதிரைகள் பூண்ட இரதங்களின் இரைச்சலுக்கு ஒப்பாயிருந்தன.
	<br /><br />
	10. அவைகள் தேள்களின் வால்களுக்கு ஒப்பான வால்களையும், அந்த வால்களில் கொடுக்குகளையும் உடையவைகளாயிருந்தன; அவைகள் ஐந்து மாதமளவும் மனுஷரைச் சேதப்படுத்துவதற்கு அதிகாரம் உடையவைகளாயிருந்தன.
	<br /><br />
	11. அவைகளுக்கு ஒரு ராஜன் உண்டு, அவன் பாதாளத்தின் தூதன்; எபிரெயு பாஷையிலே அபெத்தோன் என்றும், கிரேக்கு பாஷையிலே அப்பொல்லியோன் என்றும் அவனுக்குப் பெயர்.
	<br /><br />
	12. முதலாம் ஆபத்து கடந்துபோயிற்று; இவைகளுக்குப்பின்பு இன்னும் இரண்டு ஆபத்துகள், இதோ, வருகிறது.
	<br /><br />
	13. ஆறாம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது தேவனுக்கு முன்பாக இருந்த பொற்பீடத்தின் நான்கு கொம்புகளிலுமிருந்து ஒரு சத்தந்தோன்றி,
	<br /><br />
	14. எக்காளத்தைப் பிடித்திருந்த ஆறாம் தூதனை நோக்கி: ஐபிராத்தென்னும் பெரிய நதியண்டையிலே கட்டப்பட்டிருக்கிற நான்கு தூதர்களையும் அவிழ்த்துவிடு என்று சொல்லக்கேட்டேன்.
	<br /><br />
	15. அப்பொழுது மனுஷரில் மூன்றிலொருபங்கைக் கொல்லும்படிக்கு ஒருமணிநேரத்திற்கும், ஒரு நாளுக்கும், ஒரு மாதத்திற்கும், ஒரு வருஷத்திற்கும் ஆயத்தமாக்கப்பட்டிருந்த அந்த நான்கு தூதர்களும் அவிழ்த்துவிடப்பட்டார்கள்.
	<br /><br />
	16. குதிரைச்சேனைகளாகிய இராணுவங்களின் தொகை இருபது கோடியாயிருந்தது; அவைகளின் தொகையைச் சொல்லக்கேட்டேன்.
	<br /><br />
	17. குதிரைகளையும் அவைகளின்மேல் ஏறியிருந்தவர்களையும் நான் தரிசனத்தில் கண்டவிதமாவது; அவர்கள் அக்கினிநிறமும் நீலநிறமும் கந்தகநிறமுமான மார்க்கவசங்களையுடையவர்களாயிருந்தார்கள்; குதிரைகளுடைய தலைகள் சிங்கங்களின் தலைகளைப்போலிருந்தன; அவைகளுடைய வாய்களிலிருந்து அக்கினியும் புகையும் கந்தகமும் புறப்பட்டன.
	<br /><br />
	18. அவைகளுடைய வாய்களிலிருந்து புறப்பட்ட அக்கினி புகை கந்தகம் என்னும் இம்மூன்றினாலும் மனுஷரில் மூன்றிலொருபங்கு கொல்லப்பட்டார்கள்.
	<br /><br />
	19. அந்தக் குதிரைகளின் வல்லமை அவைகளுடைய வாயிலேயும் வால்களிலேயும் இருக்கிறது; அவைகளுடைய வால்கள் பாம்புகளுக்கு ஒப்பானவைகளாயும், தலைகளுள்ளவைகளாயுமிருக்கிறது, அவைகளாலே சேதப்படுத்துகிறது.
	<br /><br />
	20. அப்படியிருந்தும், அந்த வாதைகளால் கொல்லப்படாத மற்ற மனுஷர்கள் பேய்களையும், பொன் வெள்ளி செம்பு கல் மரம் என்பவைகளால் செய்யப்பட்டவைகளாயும் காணவும் கேட்கவும் நடக்கவுமாட்டாதவைகளாயுமிருக்கிற விக்கிரகங்களையும் வணங்காதபடிக்குத் தங்கள் கைகளின் கிரியைகளைவிட்டு மனந்திரும்பவுமில்லை;
	<br /><br />
	21. தங்கள் கொலைபாதகங்களையும், தங்கள் சூனியங்களையும், தங்கள் வேசித்தனங்களையும், தங்கள் களவுகளையும் விட்டு மனந்திரும்பவுமில்லை.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* ஐந்தாவது எக்காளத்தில், குழியிலிருந்து வெட்டுக்கிளிகள் பூமியைத் துன்புறுத்தியது. ஆறாவது எக்காளத்தில், நான்கு தேவதூதர்கள் மனிதகுலத்தின் மூன்றில் ஒரு பகுதியை நெருப்பால் கொன்றனர். *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation9