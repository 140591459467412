import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneThessalonians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO THESSALONIANS 3 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE THESSALONIANS Chapter 3 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 3 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. ஆகையால், நாங்கள் இனிப் பொறுத்திருக்கக்கூடாமல், அத்தேனே பட்டணத்தில் தனித்துவிடப்படுகிறது நன்மையென்று நினைத்து,
	<br /><br />
	2. இந்த உபத்திரவங்களினாலே ஒருவனும் அசைக்கப்படாதபடிக்கு உங்களைத் திடப்படுத்தவும், உங்கள் விசுவாசத்தைப்பற்றி உங்களுக்குப் புத்திசொல்லவும், நம்முடைய சகோதரனும் தேவ ஊழியக்காரனும் கிறிஸ்துவின் சுவிசேஷத்தில் எங்கள் உடன்வேலையாளுமாகிய தீமோத்தேயுவை அனுப்பினோம்.
	<br /><br />
	3. இப்படிப்பட்ட உபத்திரவங்களைச் சகிக்க நாம் நியமிக்கப்பட்டிருக்கிறோமென்று நீங்கள் அறிந்திருக்கிறீர்களே.
	<br /><br />
	4. நமக்கு உபத்திரவம் வருமென்று நாங்கள் உங்களிடத்திலிருந்தபோது, உங்களுக்கு முன்னறிவித்தோம்; அப்படியே வந்து நேரிட்டதென்றும் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	5. ஆகையால், நான் இனிப் பொறுத்திருக்கக்கூடாமல், எங்கள் வேலை வீணாய்ப் போகத்தக்கதாகச் சோதனைக்காரன் உங்களைச் சோதனைக்குட்படுத்தினதுண்டோவென்று, உங்கள் விசுவாசத்தை அறியும்படிக்கு, அவனை அனுப்பினேன்.
	<br /><br />
	6. இப்பொழுது தீமோத்தேயு உங்களிடத்திலிருந்து எங்களிடத்தில் வந்து, உங்கள் விசுவாசத்தையும் அன்பையும் குறித்தும், நீங்கள் எப்பொழுதும் எங்களைப் பட்சமாய் நினைத்துக்கொண்டு, நாங்கள் உங்களைக் காண வாஞ்சையாயிருக்கிறதுபோல நீங்களும் எங்களைக் காண வாஞ்சையாயிருக்கிறீர்களென்பதைக்குறித்தும், எங்களுக்கு நற்செய்தி சொன்னதினாலே,
	<br /><br />
	7. சகோதரரே, எங்களுக்கு நேரிட்ட எல்லா இக்கட்டிலும் உபத்திரவத்திலும் உங்கள் விசுவாசத்தினாலே உங்களைக்குறித்து ஆறுதலடைந்தோம்.
	<br /><br />
	8. நீங்கள் கர்த்தருக்குள் நிலைத்திருந்தால் நாங்கள் பிழைத்திருப்போம்.
	<br /><br />
	9. மேலும், நம்முடைய தேவனுக்கு முன்பாக நாங்கள் உங்களைக்குறித்து அடைந்திருக்கிற மிகுந்த சந்தோஷத்திற்காக, நாங்கள் தேவனுக்கு எவ்விதமாய் ஸ்தோத்திரம் செலுத்துவோம்?
	<br /><br />
	10. உங்கள் முகத்தைக் கண்டு, உங்கள் விசுவாசத்தின் குறைவுகளை நிறைவாக்கும்பொருட்டு, இரவும் பகலும் மிகவும் வேண்டிக்கொள்ளுகிறோமே.
	<br /><br />
	11. நம்முடைய பிதாவாகிய தேவனும் நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவும் உங்களிடத்திற்கு எங்களை நேராக வழிநடத்துவாராக.
	<br /><br />
	12. நாங்கள் உங்களிடத்தில் வைத்திருக்கிற அன்புக்கொப்பாய், நீங்களும் ஒருவரிடத்தில் ஒருவர் வைக்கும் அன்பிலும் மற்றெல்லா மனுஷரிடத்தில் வைக்கும் அன்பிலும் கர்த்தர் உங்களைப் பெருகவும் நிலைத்தோங்கவும் செய்து,
	<br /><br />
	13. இவ்விதமாய் நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து தமது பரிசுத்தவான்கள் அனைவரோடுங்கூட வரும்போது, நீங்கள் நம்முடைய பிதாவாகிய தேவனுக்கு முன்பாகப் பிழையற்ற பரிசுத்தமுள்ளவர்களாயிருக்கும்படி உங்கள் இருதயங்களை ஸ்திரப்படுத்துவாராக.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneThessalonians3