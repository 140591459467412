import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Habakkuk2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆபகூக் 2 </title>
	<meta
          name="description"
          content="Habakkuk 2 | ஆபகூக் 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் என் காவலிலே தரித்து, அரணிலே நிலைகொண்டிருந்து, அவர் எனக்கு என்ன சொல்லுவாரென்றும், அவர் என்னைக் கண்டிக்கும்போது நான் என்ன உத்தரவு சொல்லுவேனென்றும் கவனித்துப் பார்ப்பேன் என்றேன்.
	<br /><br />
	2. அப்பொழுது கர்த்தர் எனக்குப் பிரதியுத்தரமாக: நீ தரிசனத்தை எழுதி, அதைக் கடந்தோடுகிறவன் வாசிக்கும்படிப் பலகைகளிலே தீர்க்கமாக வரை.
	<br /><br />
	3. குறித்தகாலத்துக்குத் தரிசனம் இன்னும் வைக்கப்பட்டிருக்கிறது; முடிவிலே அது விளங்கும், அது பொய் சொல்லாது; அது தாமதித்தாலும் அதற்குக் காத்திரு; அது நிச்சயமாய் வரும், அது தாமதிப்பதில்லை.
	<br /><br />
	4. இதோ, அகங்காரியாயிருக்கிறானே, அவனுடைய ஆத்துமா அவனுக்குள் செம்மையானதல்ல; தன் விசுவாசத்தினாலே நீதிமான் பிழைப்பான்.
	<br /><br />
	5. அவன் மதுபானத்தினால் அக்கிரமஞ்செய்து அகங்காரியாகி, வீட்டிலே தரியாமல், அவன் தன் ஆத்துமாவைப் பாதாளத்தைப்போல விரிவாக்கித் திருப்தியாகாமல், மரணத்துக்குச் சமானமாய்ச் சகல ஜாதிகளையும் தன் வசமாகச் சேர்த்து, சகல ஜனங்களையும் தன்னிடமாகக் கூட்டிக்கொண்டாலும்,
	<br /><br />
	6. இவர்களெல்லாரும் அவன்பேரில் ஒரு பழமொழியையும், அவனுக்கு விரோதமான வசைச்சொல்லையும் வசனித்து, தன்னுடையதல்லாததைத் தனக்காகச் சேர்த்துக்கொள்ளுகிறவனுக்கு ஐயோ என்றும், அது எந்தமட்டும் நிற்கும் என்றும், அவன் தன்மேல் களிமண் சுமையையல்லவா சுமத்திக்கொள்ளுகிறான் என்றும் சொல்லுவார்கள்.
	<br /><br />
	7. உன்னைக் கடிப்பவர்கள் சடிதியாய் எழும்புவதும், உன்னை அலைக்கழிப்பவர்கள் விழிப்பதுமில்லையோ? நீ அவர்களுக்குச் சூறையாகாயோ?
	<br /><br />
	8. நீ அநேகம் ஜாதிகளைக் கொள்ளையிட்டபடியினால் ஜனங்களில் மீதியான யாவரும் நீ சிந்தின மனுஷரத்தத்தினிமித்தமும் நீ செய்த கொடுமையினிமித்தமும் உன்னைக் கொள்ளையிடுவார்கள்.
	<br /><br />
	9. தீமையின் வல்லமைக்குத் தப்பவேண்டுமென்று தன் கூட்டை உயரத்திலே வைக்கும்படிக்கு, தன் வீட்டுக்குப் பொல்லாத ஆதாயத்தைத் தேடுகிறவனுக்கு ஐயோ!
	<br /><br />
	10. அநேக ஜனங்களை வெட்டிப்போட்டதினால் உன் வீட்டுக்கு வெட்கமுண்டாக ஆலோசனைபண்ணினாய்; உன் ஆத்துமாவுக்கு விரோதமாய்ப் பாவஞ்செய்தாய்.
	<br /><br />
	11. கல்லு சுவரிலிருந்து கூப்பிடும், உத்திரம் மச்சிலிருந்து சாட்சியிடும்.
	<br /><br />
	12. இரத்தப்பழிகளாலே பட்டணத்தைக் கட்டி, அநியாயத்தினாலே நகரத்தைப் பலப்படுத்துகிறவனுக்கு ஐயோ!
	<br /><br />
	13. இதோ, ஜனங்கள் அக்கினிக்கு இரையாக உழைத்து, ஜனங்கள் விருதாவாக இளைத்துப்போகிறது கர்த்தருடைய செயல் அல்லவோ?
	<br /><br />
	14. சமுத்திரம் ஜலத்தினால் நிறைந்திருக்கிறதுபோல், பூமி கர்த்தருடைய மகிமையை அறிகிற அறிவினால் நிறைந்திருக்கும்.
	<br /><br />
	15. தன் தோழருக்குக் குடிக்கக்கொடுத்துத் தன் துருத்தியை அவர்களண்டையிலே வைத்து, அவர்களுடைய நிர்வாணங்களைப் பார்க்கும்படிக்கு, அவர்களை வெறிக்கப்பண்ணுகிறவனுக்கு ஐயோ!
	<br /><br />
	16. நீ மகிமையினால் அல்ல, இலச்சையினால் நிரப்பப்படுவாய்; நீயும் குடித்து விருத்தசேதனமில்லாதவன் என்று விளங்கு; கர்த்தருடைய வலதுகையில் இருக்கிற பாத்திரம் உன்னிடத்திற்குத் திரும்பும்; உன் மகிமையின்மேல் இலச்சையான வாந்திபண்ணுவாய்.
	<br /><br />
	17. லீபனோனுக்குச் செய்த கொடுமை உன்னை மூடும்; சிந்தின மனுஷரத்தத்தினிமித்தமும் தேசத்திற்கும் பட்டணத்திற்கும் அதின் குடிகள் எல்லாருக்கும் செய்த கொடுமையினிமித்தமும் மிருகங்கள் செய்யும் பாழ்க்கடிப்பு உன்னைக் கலங்கப்பண்ணும்.
	<br /><br />
	18. சித்திரக்காரனுக்கு அவன் செய்த சுரூபமும், ஊமையான தெய்வங்களை உண்டுபண்ணித் தான் உருவாக்கின சுரூபத்தை நம்பினவனுக்கு வார்க்கப்பட்டதும் பொய்ப்போதகம் பண்ணுகிறதுமான விக்கிரகமும் என்னத்துக்கு உதவும்?
	<br /><br />
	19. மரத்தைப்பார்த்து விழியென்றும், ஊமையான கல்லைப்பார்த்து எழும்பு என்றும் சொல்லுகிறவனுக்கு ஐயோ! அது போதிக்குமோ? இதோ, அது பொன்னும் வெள்ளியுமான தகட்டால் மூடப்பட்டிருக்கிறது; அதற்குள்ளே சுவாசம் இல்லையே?
	<br /><br />
	20. கர்த்தரோவென்றால், தமது பரிசுத்த ஆலயத்தில் இருக்கிறார்; பூமியெல்லாம் அவருக்கு முன்பாக மௌனமாயிருக்கக்கடவது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Habakkuk2