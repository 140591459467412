import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 1 </title>
	<meta
          name="description"
          content="Amos 1 | ஆமோஸ் 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தெக்கோவா ஊர் மேய்ப்பருக்குள் இருந்த ஆமோஸ், யூதாவின் ராஜாவாகிய உசியாவின் நாட்களிலும், இஸ்ரவேலின் ராஜாவாகிய யோவாசுடைய குமாரனாகிய எரொபெயாமின் நாட்களிலும், பூமி அதிர்ச்சி உண்டாக இரண்டு வருஷத்துக்கு முன்னே, இஸ்ரவேலைக்குறித்துத் தரிசனங்கண்டு சொன்ன வார்த்தைகள்.
	<br /><br />
	2. கர்த்தர் சீயோனிலிருந்து கெர்ச்சித்து, எருசலேமிலிருந்து சத்தமிடுவார்; அதினால் மேய்ப்பரின் தாபரங்கள் துக்கங்கொண்டாடும்; கர்மேலின் கொடுமுடியும் காய்ந்துபோகும்.
	<br /><br />
	3. கர்த்தர் சொல்லுகிறது என்னவென்றால்: தமஸ்குவினுடைய மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அதின் ஆக்கினையைத் திருப்பமாட்டேன்; அவர்கள் கீலேயாத்தை இருப்புக் கருவிகளினால் போரடித்தார்களே.
	<br /><br />
	4. ஆசகேலின் வீட்டிலே தீக்கொளுத்துவேன்; அது பெனாதாதின் அரமனைகளைப் பட்சிக்கும்.
	<br /><br />
	5. நான் தமஸ்குவின் தாழ்ப்பாளை உடைத்து, குடிகளை ஆவேன் என்னும் பள்ளத்தாக்கிலும், செங்கோல் செலுத்துகிறவனைப் பெத்ஏதேனிலும் இராதபடிக்குச் சங்காரம்பண்ணுவேன்; அப்பொழுது சீரியாவின் ஜனங்கள் கீருக்குச் சிறைப்பட்டுப்போவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. கர்த்தர் சொல்லுகிறது என்னவென்றால்: காத்சாவினுடைய மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அதின் ஆக்கினையைத் திருப்பமாட்டேன்; அவர்கள் சிறைப்பட்டவர்களை ஏதோமியரிடத்தில் ஒப்புவிக்கும்படி முழுதும் சிறையாக்கினார்களே.
	<br /><br />
	7. காத்சாவின் மதிலுக்குள் தீக்கொளுத்துவேன்; அது அதினுடைய அரமனைகளைப் பட்சிக்கும்.
	<br /><br />
	8. நான் குடிகளை அஸ்தோத்திலும், செங்கோல் செலுத்துகிறவனை அஸ்கலோனிலும் இராதபடிக்குச் சங்காரம்பண்ணி, பெலிஸ்தரில் மீதியானவர்கள் அழியும்படிக்கு என் கையை எக்ரோனுக்கு விரோதமாகத் திருப்புவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	9. மேலும்: தீருவினுடைய மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அதின் ஆக்கினையைத் திருப்பமாட்டேன்; அவர்கள் சகோதரரின் உடன்படிக்கையை நினையாமல், சிறைப்பட்டவர்களை முழுதும் ஏதோமியர் கையில் ஒப்பித்தார்களே.
	<br /><br />
	10. தீருவின் மதிலுக்குள் தீக்கொளுத்துவேன்; அது அதின் அரமனைகளைப் பட்சிக்கும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. மேலும்: ஏதோமுடைய மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அவன் ஆக்கினையைத் திருப்பமாட்டேன்; அவன் தன் சகோதரனைப் பட்டயத்தோடே தொடர்ந்து, தன் மனதை இரக்கமற்றதாக்கி, தன் கோபத்தினாலே என்றைக்கும் அவனைப் பீறிப்போட்டு, தன் மூர்க்கத்தை நித்தியகாலமாக வைத்திருக்கிறானே.
	<br /><br />
	12. தேமானிலே தீக்கொளுத்துவேன்; அது போஸ்றாவின் அரமனைகளைப் பட்சிக்கும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. கர்த்தர் சொல்லுகிறது என்னவென்றால்: அம்மோன் புத்திரரின் மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அவர்கள் ஆக்கினையைத் திருப்பமாட்டேன்; அவர்கள் தங்கள் எல்லைகளை விஸ்தாரமாக்கும்படிக்குக் கீலேயாத் தேசத்தின் கர்ப்பஸ்திரீகளைக் கீறிப்போட்டார்களே.
	<br /><br />
	14. ரப்பாவின் மதிலுக்குள் தீக்கொளுத்துவேன்; அது யுத்தநாளின் முழக்கமாகவும், பெருங்காற்றின் புசலாகவும் அதின் அரமனைகளைப் பட்சிக்கும்.
	<br /><br />
	15. அவர்களுடைய ராஜாவும், அவனுடைய அதிபதிகளும் சிறைப்பட்டுப்போவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos1