import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-2 </title>
	<meta
          name="description"
          content="Ecclesiastes 2 | பிரசங்கி 2 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் என் உள்ளத்திலே சொல்லிக்கொண்டது என்னவென்றால்: வா, இப்பொழுது உன்னைச் சந்தோஷத்தினாலே சோதித்துப்பார்ப்பேன், இன்பத்தை அநுபவி என்றேன்; இதோ, இதுவும் மாயையாயிருந்தது.
	<br /><br />
	2. நகைப்பைக்குறித்து, அது பைத்தியம் என்றும், சந்தோஷத்தைக்குறித்து, அது என்ன செய்யும்? என்றும் சொன்னேன்.
	<br /><br />
	3. வானத்தின்கீழ் மனுபுத்திரர் உயிரோடிருக்கும் நாளளவும் பெற்று அநுபவிக்கத்தக்கது இன்னதென்று அறியும்பொருட்டு, என் இருதயத்தை ஞானத்தால் தேற்றிக்கொண்டிருக்கும்போதே, நான் என் தேகத்தை மதுபானத்தால் சீராட்டிக்கொண்டிருக்கவும், மதியீனத்தைப் பற்றிக்கொண்டிருக்கவும் என் உள்ளத்தில் வகைதேடினேன்.
	<br /><br />
	4. நான் பெரிய வேலைகளைச் செய்தேன்; எனக்காக வீடுகளைக் கட்டினேன், திராட்சத்தோட்டங்களை நாட்டினேன்.
	<br /><br />
	5. எனக்காகத் தோட்டங்களையும் சிங்காரவனங்களையும் உண்டாக்கி, அவைகளில் சகலவகைக் கனிவிருட்சங்களையும் நாட்டினேன்.
	<br /><br />
	6. மரங்கள் பயிராகும் தோப்புக்கு நீர்ப்பாய்ச்சுகிறதற்குக் குளங்களை உண்டுபண்ணினேன்.
	<br /><br />
	7. வேலைக்காரரையும் வேலைக்காரிகளையும் சம்பாதித்தேன்; வீட்டிலும் வேலைக்காரர் பிறந்தார்கள்; எனக்குமுன் எருசலேமிலிருந்த எல்லாரைப்பார்க்கிலும் ஆடுமாடு முதலான திரண்ட ஆஸ்திகள் எனக்கு இருந்தது.
	<br /><br />
	8. வெள்ளியையும் பொன்னையும், ராஜசம்பத்தையும் மாகாணங்களிலுள்ள பொருள்களையும் சேகரித்தேன்; சங்கீதக்காரரையும் சங்கீதக்காரிகளையும், மனுபுத்திரருக்கு இன்பமான பலவித வாத்தியங்களையும் சம்பாதித்தேன்.
	<br /><br />
	9. எனக்குமுன் எருசலேமிலிருந்த எல்லாரைப்பார்க்கிலும் நான் பெரியவனும் திரவிய சம்பன்னனுமானேன்; என் ஞானமும் என்னோடேகூட இருந்தது.
	<br /><br />
	10. என் கண்கள் இச்சித்தவைகளில் ஒன்றையும் நான் அவைகளுக்குத் தடைபண்ணவில்லை; என் இருதயத்துக்கு ஒரு சந்தோஷத்தையும் நான் வேண்டாமென்று விலக்கவில்லை; நான் செய்த முயற்சிகளிலெல்லாம் என் மனம் மகிழ்ச்சிகொண்டிருந்தது; இதுவே என் பிரயாசங்கள் எல்லாவற்றினாலும் எனக்கு வந்த பலன்.
	<br /><br />
	11. என் கைகள் செய்த சகல வேலைகளையும், நான் பட்ட எல்லாப் பிரயாசத்தையும் கண்ணோக்கிப் பார்த்தேன்; இதோ, எல்லாம் மாயையும், மனதுக்குச் சஞ்சலமுமாயிருந்தது; சூரியனுக்குக் கீழே பலன் ஒன்றுமில்லை.
	<br /><br />
	12. பின்பு நான், ஞானமானது என்ன என்றும், பைத்தியமும் மதியீனமும் என்ன என்றும் பார்த்து அறியும்படி திரும்பினேன்; ராஜாவுக்குப் பின்வரும் மனுஷன் என்ன செய்யக்கூடும்? செய்ததையே செய்வான்.
	<br /><br />
	13. இருளைப்பார்க்கிலும் வெளிச்சம் எவ்வளவு உத்தமமோ, அவ்வளவாய் மதியீனத்தைப்பார்க்கிலும் ஞானம் உத்தமமென்று கண்டேன்.
	<br /><br />
	14. ஞானியின் கண்கள் அவன் முகத்திலே இருக்கிறது; மூடனோ இருளிலே நடக்கிறான்; ஆகிலும் அவர்களெல்லாருக்கும் ஒரேவிதமாய்ச் சம்பவிக்கிறது என்று கண்டேன்.
	<br /><br />
	15. மூடனுக்குச் சம்பவிக்கிறதுபோல எனக்கும் சம்பவிக்கிறதே; அப்படியிருக்க நான் அதிக ஞானமடைந்ததினால் காரியமென்ன என்று சிந்தித்தேன்; இதுவும் மாயை என்று என் உள்ளத்தில் எண்ணினேன்.
	<br /><br />
	16. மூடன் என்றாலும் ஞானியென்றாலும் என்றைக்கும் நினைவில் இருப்பதில்லை; இப்பொழுது இருக்கிறதெல்லாம் வருங்காலத்தில் மறக்கப்பட்டுப்போம்; மூடன் எப்படிச் சாகிறானோ அப்படியே ஞானியும் சாகிறான்.
	<br /><br />
	17. ஆகையால் இந்த ஜீவனை வெறுத்தேன்; சூரியனுக்குக்கீழே செய்யப்படும் கிரியையெல்லாம் எனக்கு விசனமாயிருந்தது; எல்லாம் மாயையும், மனதுக்குச் சஞ்சலமுமாயிருக்கிறது.
	<br /><br />
	18. சூரியனுக்குக்கீழே நான் பட்ட பிரயாசத்தையெல்லாம் வெறுத்தேன்; எனக்குப் பின்வரப்போகிறவனுக்கு அதை நான் வைத்துப்போகவேண்டியதாகுமே.
	<br /><br />
	19. அவன் புத்திமானாயிருப்பானோ, மூடனாயிருப்பானோ, அதை யார் அறிவார்? ஆகிலும் சூரியனுக்குக்கீழே நான் பிரயாசப்பட்டு ஞானமாய்ச் சம்பாதித்த சகல வஸ்துக்களின்பேரிலும் அவன் அதிகாரியாவான்; இதுவும் மாயையே.
	<br /><br />
	20. ஆகையால் சூரியனுக்குக்கீழே நான் பட்ட எல்லாப் பிரயாசத்தின்மேலுமுள்ள ஆசையை விட்டுவிட வகைபார்த்தேன்.
	<br /><br />
	21. ஒருவன் புத்தி, யுக்தி, நிதானத்தோடு பிரயாசப்படுகிறான்; ஆகிலும் அப்படிப் பிரயாசப்படாதிருந்த வேறொருவனுக்கு அவன் அதைச் சொந்தமாக விட்டுவிடவேண்டியதாகும்; இதுவும் மாயையும் பெரிய தீங்குமாய் இருக்கிறது.
	<br /><br />
	22. மனுஷன் சூரியனுக்குக்கீழே படுகிற எல்லாப் பிரயாசத்தினாலும் அவனுடைய இருதயத்தின் எண்ணங்களினாலும் அவனுக்குப் பலன் என்ன?
	<br /><br />
	23. அவன் நாட்களெல்லாம் அலுப்புள்ளது, அவன் வேலைகள் வருத்தமுள்ளது; இராத்திரியிலும் அவன் மனதுக்கு இளைப்பாறுதலில்லை; இதுவும் மாயையே.
	<br /><br />
	24. மனுஷன் புசித்துக் குடித்து, தன் பிரயாசத்தின் பலனை அநுபவிப்பதைப்பார்க்கிலும், அவனுக்கு ஒரு நன்மையும் இல்லை; இதுவும் தேவனுடைய கரத்திலிருந்து வருகிறது என்று நான் கண்டேன்.
	<br /><br />
	25. என்னைப்பார்க்கிலும் சம்பிரமமாய்ச் சாப்பிடத்தக்கவன் யார்? என்னைப்பார்க்கிலும் துரிதமாய்ச் சம்பாதிக்கத்தக்கவன் யார்?
	<br /><br />
	26. தேவன் தமது பார்வைக்கு நல்லவனாயிருக்கிறவனுக்கு ஞானத்தையும் அறிவையும் இன்பத்தையும் அளிக்கிறார்; பாவஞ்செய்கிறவனுக்கோ தமது பார்வைக்கு நல்லவனாயிருக்கிறவன் வசமாய் வைத்துவிட்டுப் போகும்பொருட்டுச் சேர்த்துக் குவித்துவைக்கும் தொல்லையை அவர் நியமித்திருக்கிறார்; இதுவும் மாயையும், மனதுக்குச் சஞ்சலமுமாயிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes2