import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah56 = () => {
  const verseNumber = 56;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 56 </title>
	<meta
          name="description"
          content="Isaiah 56 | ஏசாயா 56 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொல்லுகிறார்: நீங்கள் நியாயத்தைக் கைக்கொண்டு, நீதியைச் செய்யுங்கள்; என் இரட்சிப்பு வரவும், என் நீதி வெளிப்படவும் சமீபமாயிருக்கிறது.
	<br /><br />
	2. இப்படிச் செய்கிற மனுஷனும், இதைப் பற்றிக்கொண்டிருந்து, ஓய்வுநாளைப் பரிசுத்தக் குலைச்சலாக்காதபடி ஆசரித்து, ஒரு பொல்லாப்பையும் செய்யாதபடி தன் கையைக் காத்துக்கொண்டிருக்கிற மனுபுத்திரனும் பாக்கியவான்.
	<br /><br />
	3. கர்த்தரைச் சேர்ந்த அந்நியபுத்திரன்: கர்த்தர் என்னைத் தம்முடைய ஜனத்தைவிட்டு முற்றிலும் பிரித்துப்போடுவாரென்று சொல்லானாக; அண்ணகனும்: இதோ, நான் பட்டமரமென்று சொல்லானாக.
	<br /><br />
	4. என் ஓய்வுநாட்களை ஆசரித்து, எனக்கு இஷ்டமானவைகளைத் தெரிந்துகொண்டு, என் உடன்படிக்கையைப் பற்றிக்கொள்ளுகிற அண்ணகர்களைக் குறித்துக் கர்த்தர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	5. நான் அவர்களுக்கு என் ஆலயத்திலும், என் மதில்களுக்குள்ளும் குமாரருக்கும் குமாரத்திகளுக்குமுரிய இடத்தையும் கீர்த்தியையும்பார்க்கிலும், உத்தம இடத்தையும் கீர்த்தியையும் கொடுப்பேன், என்றும் அழியாத நித்திய நாமத்தை அவர்களுக்கு அருளுவேன்.
	<br /><br />
	6. கர்த்தரைச் சேவிக்கவும், கர்த்தருடைய நாமத்தை நேசிக்கவும், அவருக்கு ஊழியக்காரராயிருக்கவும், அவரைச் சேர்ந்து, ஓய்வுநாளைப் பரிசுத்தக்குலைச்சலாக்காதபடி ஆசரித்து, என் உடன்படிக்கையைப் பற்றிக்கொண்டிருக்கிற அந்நியபுத்திரர் அனைவரையும்,
	<br /><br />
	7. நான் என் பரிசுத்த பர்வதத்துக்குக் கொண்டுவந்து: என் ஜெபவீட்டிலே அவர்களை மகிழப்பண்ணுவேன்; அவர்களுடைய சர்வாங்கதகனங்களும், அவர்களுடைய பலிகளும், என் பலிபீடத்தின்மேல் அங்கிகரிக்கப்பட்டிருக்கும்; என்னுடைய வீடு சகல ஜனங்களுக்கும் ஜெபவீடு என்னப்படும்.
	<br /><br />
	8. இஸ்ரவேலில் தள்ளுண்டவர்களைச் சேர்க்கிற கர்த்தராகிய ஆண்டவர்: அவனிடத்தில் சேர்க்கப்பட்டவர்களையல்லாமல் இன்னும் அவனிடத்தில் சேர்ப்பேன் என்கிறார்.
	<br /><br />
	9. வெளியில் சஞ்சரிக்கிற சகல மிருகங்களே, காட்டிலுள்ள சகல மிருகங்களே, பட்சிக்க வாருங்கள்.
	<br /><br />
	10. அவனுடைய காவற்காரர் எல்லாரும் ஒன்றும் அறியாத குருடர்; அவர்களெல்லாரும் குலைக்கமாட்டாத ஊமையான நாய்கள்; தூக்கமயக்கமாய்ப் புலம்புகிறவர்கள், படுத்துக்கொள்ளுகிறவர்கள், நித்திரைப் பிரியர்;
	<br /><br />
	11. திருப்தியடையாமலிருக்கும் பெருவயிற்று நாய்கள்; பகுத்தறிவில்லாத மேய்ப்பர்; அவர்களில் ஒவ்வொருவனும் தன் தன் வழியையும், அவனவன் தன் தன் மூலையிலிருந்து தன் தன் பொழிவையும் நோக்கிக்கொண்டிருக்கிறான்.
	<br /><br />
	12. வாருங்கள், திராட்சரசத்தைக் கொண்டுவருவேன், மதுவைக் குடிப்போம்; நாளையத்தினம் இன்றையத்தினம்போலவும், இதற்கு அதிகமாகவும் இருக்கும் என்பார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah56