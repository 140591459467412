import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 15 </title>
	<meta
          name="description"
          content="Luke 15 | லூக்கா 15 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சகல ஆயக்காரரும் பாவிகளும் அவருடைய வசனங்களைக் கேட்கும்படி அவரிடத்தில் வந்து சேர்ந்தார்கள்.
	<br /><br />
	2. அப்பொழுது பரிசேயரும் வேதபாரகரும் முறுமுறுத்து: இவர் பாவிகளை ஏற்றுக்கொண்டு அவர்களோடே சாப்பிடுகிறார் என்றார்கள்.
	<br /><br />
	3. அவர்களுக்கு அவர் சொன்ன உவமையாவது:
	<br /><br />
	4. உங்களில் ஒரு மனுஷன் நூறு ஆடுகளை உடையவனாயிருந்து, அவைகளில் ஒன்று காணாமற்போனால், தொண்ணூற்றொன்பது ஆடுகளையும் வனாந்தரத்திலே விட்டு, காணாமற்போன ஆட்டைக் கண்டுபிடிக்குமளவும் தேடித்திரியானோ?
	<br /><br />
	5. கண்டுபிடித்தபின்பு, அவன் சந்தோஷத்தோடே அதைத் தன் தோள்களின்மேல் போட்டுக்கொண்டு,
	<br /><br />
	6. வீட்டுக்கு வந்து, சிநேகிதரையும் அயலகத்தாரையும் கூட வரவழைத்து: காணாமற்போன என் ஆட்டைக் கண்டுபிடித்தேன் என்னோடுகூடச் சந்தோஷப்படுங்கள் என்பான் அல்லவா?
	<br /><br />
	7. அதுபோல, மனந்திரும்ப அவசியமில்லாத தொண்ணூற்றொன்பது நீதிமான்களைக்குறித்துச் சந்தோஷம் உண்டாகிறதைப்பார்க்கிலும் மனந்திரும்புகிற ஒரே பாவியினிமித்தம் பரலோகத்தில் மிகுந்த சந்தோஷம் உண்டாயிருக்கும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	8. அன்றியும், ஒரு ஸ்திரீ பத்து வெள்ளிக்காசை உடையவளாயிருந்து, அதில் ஒரு வெள்ளிக்காசு காணாமற்போனால், விளக்கைக் கொளுத்தி, வீட்டைப் பெருக்கி, அதைக் கண்டுபிடிக்கிறவரைக்கும் ஜாக்கிரதையாய்த் தேடாமலிருப்பாளோ?
	<br /><br />
	9. கண்டுபிடித்தபின்பு, தன் சிநேகிதிகளையும் அயல் வீட்டுக்காரிகளையும் கூட வரவழைத்து: காணாமற்போன வெள்ளிக்காசைக் கண்டுபிடித்தேன், என்னோடுகூடச் சந்தோஷப்படுங்கள் என்பாள் அல்லவா?
	<br /><br />
	10. அதுபோல மனந்திரும்புகிற ஒரே பாவியினிமித்தம் தேவனுடைய தூதர்களுக்கு முன்பாகச் சந்தோஷமுண்டாயிருக்கிறது என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	11. பின்னும் அவர் சொன்னது: ஒரு மனுஷனுக்கு இரண்டு குமாரர் இருந்தார்கள்.
	<br /><br />
	12. அவர்களில் இளையவன் தகப்பனை நோக்கி: தகப்பனே, ஆஸ்தியில் எனக்கு வரும் பங்கை எனக்குத் தரவேண்டும் என்றான். அந்தப்படி அவன் அவர்களுக்குத் தன் ஆஸ்தியைப் பங்கிட்டுக்கொடுத்தான்.
	<br /><br />
	13. சில நாளைக்குப்பின்பு, இளையமகன் எல்லாவற்றையும் சேர்த்துக்கொண்டு, தூரதேசத்துக்குப் புறப்பட்டுப்போய், அங்கே துன்மார்க்கமாய் ஜீவனம்பண்ணி, தன் ஆஸ்தியை அழித்துப்போட்டான்.
	<br /><br />
	14. எல்லாவற்றையும் அவன் செலவழித்தபின்பு, அந்தத் தேசத்திலே கொடிய பஞ்சமுண்டாயிற்று. அப்பொழுது அவன் குறைவுபடத்தொடங்கி,
	<br /><br />
	15. அந்தத் தேசத்துக் குடிகளில் ஒருவனிடத்தில் போய் ஒட்டிக்கொண்டான். அந்தக் குடியானவன் அவனைத் தன் வயல்களில் பன்றிகளை மேய்க்கும்படி அனுப்பினான்.
	<br /><br />
	16. அப்பொழுது பன்றிகள் தின்கிற தவிட்டினாலே தன் வயிற்றை நிரப்ப ஆசையாயிருந்தான், ஒருவனும் அதை அவனுக்குக் கொடுக்கவில்லை.
	<br /><br />
	17. அவனுக்குப் புத்தி தெளிந்தபோது, அவன்: என் தகப்பனுடைய கூலிக்காரர் எத்தனையோ பேருக்குப் பூர்த்தியான சாப்பாடு இருக்கிறது, நானோ பசியினால் சாகிறேன்.
	<br /><br />
	18. நான் எழுந்து, என் தகப்பனிடத்திற்குப் போய்: தகப்பனே, பரத்துக்கு விரோதமாகவும் உமக்கு முன்பாகவும் பாவஞ்செய்தேன்.
	<br /><br />
	19. இனிமேல் உம்முடைய குமாரன் என்று சொல்லப்படுவதற்கு நான் பாத்திரனல்ல, உம்முடைய கூலிக்காரரில் ஒருவனாக என்னை வைத்துக்கொள்ளும் என்பேன் என்று சொல்லி;
	<br /><br />
	20. எழுந்து புறப்பட்டு, தன் தகப்பனிடத்தில் வந்தான். அவன் தூரத்தில் வரும்போதே, அவனுடைய தகப்பன் அவனைக் கண்டு, மனதுருகி, ஓடி, அவன் கழுத்தைக் கட்டிக்கொண்டு, அவனை முத்தஞ்செய்தான்.
	<br /><br />
	21. குமாரன் தகப்பனை நோக்கி: தகப்பனே, பரத்துக்கு விரோதமாகவும், உமக்கு முன்பாகவும் பாவஞ்செய்தேன், இனிமேல் உம்முடைய குமாரன் என்று சொல்லப்படுவதற்கு நான் பாத்திரன் அல்ல என்று சொன்னான்.
	<br /><br />
	22. அப்பொழுது தகப்பன் தன் ஊழியக்காரரை நோக்கி: நீங்கள் உயர்ந்த வஸ்திரத்தைக் கொண்டுவந்து, இவனுக்கு உடுத்தி, இவன் கைக்கு மோதிரத்தையும் கால்களுக்குப் பாதரட்சைகளையும் போடுங்கள்.
	<br /><br />
	23. கொழுத்த கன்றைக் கொண்டுவந்து அடியுங்கள். நாம் புசித்து, சந்தோஷமாயிருப்போம்.
	<br /><br />
	24. என் குமாரனாகிய இவன் மரித்தான், திரும்பவும் உயிர்த்தான்; காணாமற்போனான், திரும்பவும் காணப்பட்டான் என்றான். அப்படியே அவர்கள் சந்தோஷப்படத் தொடங்கினார்கள்.
	<br /><br />
	25. அவனுடைய மூத்தகுமாரன் வயலிலிருந்தான். அவன் திரும்பி வீட்டுக்குச் சமீபமாய் வருகிறபோது, கீதவாத்தியத்தையும் நடனக்களிப்பையும் கேட்டு;
	<br /><br />
	26. ஊழியக்காரரில் ஒருவனை அழைத்து: இதென்ன என்று விசாரித்தான்.
	<br /><br />
	27. அதற்கு அவன்: உம்முடைய சகோதரன் வந்தார், அவர் மறுபடியும் சுகத்துடனே உம்முடைய தகப்பனிடத்தில் வந்து சேர்ந்தபடியினாலே அவருக்காகக் கொழுத்த கன்றை அடிப்பித்தார் என்றான்.
	<br /><br />
	28. அப்பொழுது அவன் கோபமடைந்து, உள்ளே போக மனதில்லாதிருந்தான். தகப்பனோ வெளியே வந்து, அவனை வருந்தியழைத்தான்.
	<br /><br />
	29. அவன் தகப்பனுக்குப் பிரதியுத்தரமாக: இதோ, இத்தனை வருஷகாலமாய் நான் உமக்கு ஊழியஞ்செய்து, ஒருக்காலும் உம்முடைய கற்பனையை மீறாதிருந்தும், என் சிநேகிதரோடே நான் சந்தோஷமாயிருக்கும்படி நீர் ஒருக்காலும் எனக்கு ஒரு ஆட்டுக்குட்டியையாவது கொடுக்கவில்லை.
	<br /><br />
	30. வேசிகளிடத்தில் உம்முடைய ஆஸ்தியை அழித்துப்போட்ட உம்முடைய குமாரனாகிய இவன் வந்தவுடனே கொழுத்த கன்றை இவனுக்காக அடிப்பித்தீரே என்றான்.
	<br /><br />
	31. அதற்குத் தகப்பன்: மகனே, நீ எப்போதும் என்னோடிருக்கிறாய், எனக்குள்ளதெல்லாம் உன்னுடையதாயிருக்கிறது.
	<br /><br />
	32. உன் சகோதரனாகிய இவனோ மரித்தான், திரும்பவும் உயிர்த்தான்; காணாமற்போனான், திரும்பவும் காணப்பட்டான்; ஆனபடியினாலே, நாம் சந்தோஷப்பட்டு மகிழ்ச்சியாயிருக்கவேண்டுமே என்று சொன்னான் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke15