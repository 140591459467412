import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation12 = () => {
  const verseNumber = 12;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 12 </title>
	<meta
          name="description"
          content="The Book of Revelation - 12 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 12 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
*  ​​பிரசவிக்கும் ஒரு பெண் மற்றும் ஒரு பெரிய வலு சர்ப்பம் தோன்றியது. மிகாவேலும் அவனது தேவதூதர்களும் வலு சர்ப்பத்துடன் சண்டையிட்டனர். வலு சர்ப்பம் பூமியில் வீசப்பட்டது. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. அன்றியும் ஒரு பெரிய அடையாளம் வானத்திலே காணப்பட்டது; ஒரு ஸ்திரீ சூரியனை அணிந்திருந்தாள், அவள் பாதங்களின் கீழே சந்திரனும், அவள் சிரசின்மேல் பன்னிரண்டு நட்சத்திரங்களுள்ள கிரீடமும் இருந்தன.
	<br /><br />
	2. அவள் கர்ப்பவதியாயிருந்து, பிரசவவேதனையடைந்து, பிள்ளைபெறும்படி வருத்தப்பட்டு அலறினாள்.
	<br /><br />
	3. அப்பொழுது வேறொரு அடையாளம் வானத்திலே காணப்பட்டது; ஏழு தலைகளையும், பத்துக் கொம்புகளையும், தன் தலைகளின்மேல் ஏழு முடிகளையுமுடைய சிவப்பான பெரிய வலுசர்ப்பமிருந்தது.
	<br /><br />
	4. அதின் வால் வானத்தின் நட்சத்திரங்களில் மூன்றிலொருபங்கை இழுத்து, அவைகளைப் பூமியில் விழத்தள்ளிற்று; பிரசவவேதனைப்படுகிற அந்த ஸ்திரீ பிள்ளை பெற்றவுடனே, அவளுடைய பிள்ளையைப் பட்சித்துப்போடும்படிக்கு அந்த வலுசர்ப்பம் அவளுக்கு முன்பாக நின்றது.
	<br /><br />
	5. சகல ஜாதிகளையும் இருப்புக்கோலால் ஆளுகைசெய்யும் ஆண்பிள்ளையை அவள் பெற்றாள்; அவளுடைய பிள்ளை தேவனிடத்திற்கும் அவருடைய சிங்காசனத்தினிடத்திற்கும் எடுத்துக்கொள்ளப்பட்டது.
	<br /><br />
	6. ஸ்திரீயானவள் வனாந்தரத்திற்கு ஓடிப்போனாள்; அங்கே ஆயிரத்திருநூற்றறுபதுநாளளவும் அவளைப் போஷிப்பதற்காகத் தேவனால் ஆயத்தமாக்கப்பட்ட இடம் அவளுக்கு உண்டாயிருந்தது.
	<br /><br />
	7. வானத்திலே யுத்தமுண்டாயிற்று; மிகாவேலும் அவனைச் சேர்ந்த தூதர்களும் வலுசர்ப்பத்தோடே யுத்தம்பண்ணினார்கள்; வலுசர்ப்பமும் அதைச்சேர்ந்த தூதரும் யுத்தம்பண்ணியும் ஜெயங்கொள்ளவில்லை.
	<br /><br />
	8. வானத்தில் அவர்கள் இருந்த இடமும் காணப்படாமற்போயிற்று.
	<br /><br />
	9. உலகமனைத்தையும் மோசம்போக்குகிற பிசாசு என்றும் சாத்தான் என்றும் சொல்லப்பட்ட பழைய பாம்பாகிய பெரிய வலுசர்ப்பம் தள்ளப்பட்டது; அது பூமியிலே விழத்தள்ளப்பட்டது, அதனோடேகூட அதைச்சேர்ந்த தூதரும் தள்ளப்பட்டார்கள்.
	<br /><br />
	10. அப்பொழுது வானத்திலே ஒரு பெரிய சத்தமுண்டாகி: இப்பொழுது இரட்சிப்பும் வல்லமையும் நமது தேவனுடைய ராஜ்யமும், அவருடைய கிறிஸ்துவின் அதிகாரமும் உண்டாயிருக்கிறது; இரவும் பகலும் நம்முடைய தேவனுக்கு முன்பாக நம்முடைய சகோதரர்மேல் குற்றஞ் சுமத்தும்பொருட்டு அவர்கள்மேல் குற்றஞ்சாட்டுகிறவன் தாழத் தள்ளப்பட்டுப்போனான்.
	<br /><br />
	11. மரணம் நேரிடுகிறதாயிருந்தாலும் அதற்குத் தப்பும்படி தங்கள் ஜீவனையும் பாராமல், ஆட்டுக்குட்டியின் இரத்தத்தினாலும் தங்கள் சாட்சியின் வசனத்தினாலும் அவனை ஜெயித்தார்கள்.
	<br /><br />
	12. ஆகையால் பரலோகங்களே! அவைகளில் வாசமாயிருக்கிறவர்களே! களிகூருங்கள். பூமியிலும் சமுத்திரத்திலும் குடியிருக்கிறவர்களே! ஐயோ, பிசாசானவன் தனக்குக் கொஞ்சக்காலமாத்திரம் உண்டென்று அறிந்து, மிகுந்த கோபங்கொண்டு, உங்களிடத்தில் இறங்கினபடியால், உங்களுக்கு ஆபத்துவரும் என்று சொல்லக்கேட்டேன்.
	<br /><br />
	13. வலுசர்ப்பமானது தான் பூமியிலே தள்ளப்பட்டதை அறிந்து, அந்த ஆண்பிள்ளையைப்பெற்ற ஸ்திரீயைத் துன்பப்படுத்தினது.
	<br /><br />
	14. ஸ்திரீயானவள் அந்தப் பாம்பின் முகத்திற்கு விலகி, ஒரு காலமும், காலங்களும், அரைக்காலமுமாகப் போஷிக்கப்படத்தக்கதாய் வனாந்தரத்திலுள்ள தன் இடத்திற்குப் பறந்துபோகும்படி பெருங்கழுகின் இரண்டு சிறகுகள் அவளுக்குக் கொடுக்கப்பட்டது.
	<br /><br />
	15. அப்பொழுது அந்த ஸ்திரீயை வெள்ளங்கொண்டுபோகும்படிக்குப் பாம்பானது தன் வாயிலிருந்து ஒரு நதிபோன்ற வெள்ளத்தை அவளுக்குப் பின்னாக ஊற்றிவிட்டது.
	<br /><br />
	16. பூமியானது ஸ்திரீக்கு உதவியாகத் தன் வாயைத் திறந்து, வலுசர்ப்பம் தன் வாயிலிருந்து ஊற்றின வெள்ளத்தை விழுங்கினது.
	<br /><br />
	17. அப்பொழுது வலுசர்ப்பமானது ஸ்திரீயின்மேல் கோபங்கொண்டு, தேவனுடைய கற்பனைகளைக் கைக்கொள்ளுகிறவர்களும், இயேசுகிறிஸ்துவைக்குறித்துச் சாட்சியை உடையவர்களுமாகிய அவளுடைய சந்ததியான மற்றவர்களுடனே யுத்தம்பண்ணப்போயிற்று.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h12>
*  *
			</h12>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation12