import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 1 </title>
	<meta
          name="description"
          content="Hosea 1 | ஓசியா 1 | 
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதா தேசத்து ராஜாக்களாகிய உசியா, யோதாம், ஆகாஸ், எசேக்கியா என்பவர்களின் நாட்களிலும், யோவாசின் குமாரனாகிய யெஸ்ரயேலின் ராஜாவாகிய யெரொபெயாம் என்பவனின் நாட்களிலும், பெயேரியின் குமாரனாகிய ஓசியாவுக்கு உண்டான கர்த்தருடைய வசனம்.
	<br /><br />
	2. கர்த்தர் ஓசியாவைக்கொண்டு உரைக்கத்தொடங்கினபோது, கர்த்தர் ஓசியாவை நோக்கி: நீ போய், ஒரு சோரஸ்திரீயையும் சோரப்பிள்ளைகளையும் உன்னிடமாகச் சேர்த்துக்கொள்; தேசம் கர்த்தரை விட்டு விலகிச் சோரம்போயிற்று என்றார்.
	<br /><br />
	3. அவன் போய், திப்லாயிமின் குமாரத்தியாகிய கோமேரைச் சேர்த்துக்கொண்டான்; அவள் கர்ப்பந்தரித்து, அவனுக்கு ஒரு குமாரனைப் பெற்றாள்.
	<br /><br />
	4. அப்பொழுது கர்த்தர் அவனை நோக்கி: இவனுக்கு யெஸ்ரயேல் என்னும் பேரிடு; ஏனெனில் இன்னும் கொஞ்சக்காலத்திலே நான் ஏகூவின் வம்சத்தாரிடத்திலே யெஸ்ரயேலின் இரத்தப்பழியை விசாரித்து, இஸ்ரவேல் வம்சத்தாரின் ராஜ்யபாரத்தை ஒழியப்பண்ணுவேன்.
	<br /><br />
	5. அந்நாளில் யெஸ்ரயேலின் பள்ளத்தாக்கிலே இஸ்ரவேலின் வில்லை முறிப்பேன் என்றார்.
	<br /><br />
	6. அவள் திரும்பக் கர்ப்பந்தரித்து, ஒரு குமாரத்தியைப் பெற்றாள்; அப்பொழுது அவர் அவனை நோக்கி: இவளுக்கு லோருகாமா என்னும் பேரிடு; ஏனெனில் நான் இனி இஸ்ரவேல் வம்சத்தாருக்கு இரக்கஞ்செய்வதில்லை, நான் அவர்களை முழுவதும் அகற்றிவிடுவேன்.
	<br /><br />
	7. யூதாவின் வம்சத்தாருக்கோ நான் இரக்கஞ்செய்வேன்; வில்லினாலும், பட்டயத்தினாலும், யுத்தத்தினாலும், குதிரைகளினாலும், குதிரைவீரரினாலும் நான் அவர்களை இரட்சியாமல், அவர்களுடைய தேவனாகிய கர்த்தராலே அவர்களை இரட்சிப்பேன் என்றார்.
	<br /><br />
	8. அவள் லோருகாமாவை முலை மறக்கப்பண்ணினபிற்பாடு, கர்ப்பந்தரித்து ஒரு குமாரனைப் பெற்றாள்.
	<br /><br />
	9. அப்பொழுது அவர்: இவனுக்கு லோகம்மீ என்னும் பேரிடு; ஏனெனில் நீங்கள் என் ஜனமல்ல, நான் உங்கள் தேவனாயிருப்பதில்லை.
	<br /><br />
	10. என்றாலும், இஸ்ரவேல் புத்திரரின் தொகை அளக்கவும் எண்ணவுங்கூடாத கடற்கரை மணலைப்போலிருக்கும்; நீங்கள் என் ஜனமல்ல என்று அவர்களுக்குச் சொல்வதற்குப் பதிலாக நீங்கள் ஜீவனுள்ள தேவனுடைய பிள்ளைகள் என்று அவர்களுக்குச் சொல்லப்படும்.
	<br /><br />
	11. அப்பொழுது யூதா புத்திரரும் இஸ்ரவேல் புத்திரரும் ஏகமாய்க் கூட்டப்பட்டு, தங்களுக்கு ஒரே அதிபதியை ஏற்படுத்தி, தேசத்திலிருந்து புறப்பட்டு வருவார்கள்; யெஸ்ரயேலின் நாள் பெரிதாயிருக்கும்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea1