import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah49 = () => {
  const verseNumber = 49;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 49 </title>
	<meta
          name="description"
          content="Isaiah 49 | ஏசாயா 49 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தீவுகளே, எனக்குச் செவிகொடுங்கள்; தூரத்திலிருக்கிற ஜனங்களே, கவனியுங்கள்; தாயின் கர்ப்பத்திலிருந்ததுமுதல் கர்த்தர் என்னை அழைத்து, நான் என் தாயின் வயிற்றில் இருக்கையில் என் நாமத்தைப் பிரஸ்தாபப்படுத்தினார்.
	<br /><br />
	2. அவர் என் வாயைக் கூர்மையான பட்டயமாக்கி, தமது கரத்தின் நிழலினால் என்னை மறைத்து, என்னைத் துலக்கமான அம்பாக்கி, என்னைத் தமது அம்பறாத்தூணியிலே மூடிவைத்தார்.
	<br /><br />
	3. அவர் என்னை நோக்கி: நீ என் தாசன்; இஸ்ரவேலே, நான் உன்னில் மகிமைப்படுவேன் என்றார்.
	<br /><br />
	4. அதற்கு நான்: விருதாவாய் உழைக்கிறேன், வீணும் வியர்த்தமுமாய் என் பெலனைச் செலவழிக்கிறேன்; ஆகிலும் என் நியாயம் கர்த்தரிடத்திலும், என் பலன் என் தேவனிடத்திலும் இருக்கிறது என்று சொன்னேன்.
	<br /><br />
	5. யாக்கோபைத் தம்மிடத்தில் திருப்பும்படி நான் தாயின் கர்ப்பத்திலிருந்ததுமுதல் கர்த்தர் தமக்குத் தாசனாக என்னை உருவாக்கினார்; இஸ்ரவேலோ சேராதேபோகிறது; ஆகிலும் கர்த்தருடைய பார்வையில் கனமடைவேன், என் தேவன் என் பெலனாயிருப்பார்.
	<br /><br />
	6. யாக்கோபின் கோத்திரங்களை எழுப்பவும், இஸ்ரவேலில் காக்கப்பட்டவர்களைத் திருப்பவும், நீர் எனக்குத் தாசனாயிருப்பது அற்பகாரியமாயிருக்கிறது; நீர் பூமியின் கடைசிபரியந்தமும் என்னுடைய இரட்சிப்பாயிருக்கும்படி, உம்மை ஜாதிகளுக்கு ஒளியாகவும் வைப்பேன் என்கிறார்.
	<br /><br />
	7. இஸ்ரவேலின் மீட்பரும் அதின் பரிசுத்தருமாகிய கர்த்தர், மனுஷரால் அசட்டைபண்ணப்பட்டவரும், ஜாதியாரால் அருவருக்கப்பட்டவரும், அதிகாரிகளுக்கு ஊழியக்காரனுமாயிருக்கிறவரை நோக்கி, உண்மையுள்ள கர்த்தர் நிமித்தமும், உம்மைத் தெரிந்துகொண்ட இஸ்ரவேலின் பரிசுத்தர் நிமித்தமும், ராஜாக்கள் கண்டு எழுந்திருந்து, பிரபுக்கள் பணிந்துகொள்வார்கள் என்று சொல்லுகிறார்.
	<br /><br />
	8. பின்னும் கர்த்தர்: அநுக்கிரக காலத்திலே நான் உமக்குச் செவிகொடுத்து, இரட்சணியநாளிலே உமக்கு உதவிசெய்தேன்; நீர் பூமியைச் சீர்ப்படுத்தி, பாழாய்க்கிடக்கிற இடங்களைச் சுதந்தரிக்கப்பண்ணவும்;
	<br /><br />
	9. கட்டுண்டவர்களை நோக்கி: புறப்பட்டுப்போங்கள் என்றும்; இருளில் இருக்கிறவர்களை நோக்கி: வெளிப்படுங்கள் என்றும் சொல்லவும், நான் உம்மைக் காப்பாற்றி, உம்மை ஜனங்களுக்கு உடன்படிக்கையாக ஏற்படுத்துவேன்; அவர்கள் வழியோரங்களிலே மேய்வார்கள்; சகல மேடுகளிலும் அவர்களுக்கு மேய்ச்சல் உண்டாயிருக்கும்.
	<br /><br />
	10. அவர்கள் பசியாயிருப்பதுமில்லை, தாகமாயிருப்பதுமில்லை; உஷ்ணமாகிலும், வெயிலாகிலும் அவர்கள்மேல் படுவதுமில்லை; அவர்களுக்கு இரங்குகிறவர் அவர்களை நடத்தி, அவர்களை நீரூற்றுகளிடத்திற்குக் கொண்டுபோய்விடுவார்.
	<br /><br />
	11. என் மலைகளையெல்லாம் வழிகளாக்குவேன்; என் பாதைகள் உயர்த்தப்படும்.
	<br /><br />
	12. இதோ, இவர்கள் தூரத்திலிருந்து வருவார்கள்; இதோ, அவர்கள் வடக்கிலும் மேற்கிலுமிருந்து வருவார்கள்; இவர்கள் சீனீம் தேசத்திலுமிருந்து வருவார்கள் என்கிறார்.
	<br /><br />
	13. வானங்களே, கெம்பீரித்துப்பாடுங்கள்; பூமியே, களிகூரு; பர்வதங்களே, கெம்பீரமாய் முழங்குங்கள்; கர்த்தர் தம்முடைய ஜனத்துக்கு ஆறுதல் செய்தார்; சிறுமைப்பட்டிருக்கிற தம்முடையவர்கள்மேல் இரக்கமாயிருப்பார்.
	<br /><br />
	14. சீயோனோ: கர்த்தர் என்னைக் கைவிட்டார், ஆண்டவர் என்னை மறந்தார் என்று சொல்லுகிறாள்.
	<br /><br />
	15. ஸ்திரீயானவள் தன் கர்ப்பத்தின் பிள்ளைக்கு இரங்காமல், தன் பாலகனை மறப்பாளோ? அவர்கள் மறந்தாலும், நான் உன்னை மறப்பதில்லை.
	<br /><br />
	16. இதோ, என் உள்ளங்கைகளில் உன்னை வரைந்திருக்கிறேன்; உன் மதில்கள் எப்போதும் என்முன் இருக்கிறது.
	<br /><br />
	17. உன் குமாரர் தீவிரித்துவருவார்கள்; உன்னை நிர்மூலமாக்கினவர்களும் உன்னைப் பாழாக்கினவர்களும் உன்னைவிட்டுப் புறப்பட்டுப்போவார்கள்.
	<br /><br />
	18. உன் கண்களை ஏறெடுத்துச் சுற்றிலும் பார்; அவர்களெல்லாரும் ஏகமாய்க் கூடி உன்னிடத்தில் வருகிறார்கள்; நீ அவர்களெல்லாரையும் ஆபரணமாகத் தரித்து, மணமகள் அணிந்துகொள்வதுபோல, நீ அவர்களை அணிந்துகொள்வாய் என்று, என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தர் உரைக்கிறார்.
	<br /><br />
	19. அப்பொழுது உன் வனாந்தரங்களும், உன் பாழிடங்களும், நிர்மூலமான உன் தேசமும், இனிக் குடிகளின் திரளினாலே உனக்கு நெருக்கமாயிருக்கும்; உன்னை விழுங்கினவர்கள் தூரமாவார்கள்.
	<br /><br />
	20. பிள்ளைகளற்றிருந்த உனக்கு உண்டாயிருக்கப்போகிற பிள்ளைகள்: இடம் எங்களுக்கு நெருக்கமாயிருக்கிறது; நாங்கள் குடியிருக்கும்படிக்கு விலகியிரு என்று, உன் காதுகள் கேட்கச் சொல்லுவார்கள்.
	<br /><br />
	21. அப்பொழுது நீ: இவர்களை எனக்குப் பிறப்பித்தவர் யார்? நான் பிள்ளைகளற்றும், தனித்தும், சிறைப்பட்டும், நிலையற்றும் இருந்தேனே; இவர்களை எனக்கு வளர்த்தவர் யார்? இதோ, நான் ஒன்றியாய் விடப்பட்டிருந்தேனே; இவர்கள் எங்கேயிருந்தவர்கள்? என்று உன் இருதயத்தில் சொல்லுவாய்.
	<br /><br />
	22. இதோ, ஜாதிகளுக்கு நேராக என் கரத்தை உயர்த்தி, ஜனங்களுக்கு நேராக என் கொடியை ஏற்றுவேன்; அப்பொழுது உன் குமாரரைக் கொடுங்கைகளில் ஏந்திக்கொண்டு வருவார்கள்; உன் குமாரத்திகள் தோளின்மேல் எடுத்துக்கொண்டு வரப்படுவார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	23. ராஜாக்கள் உன்னை வளர்க்கும் தந்தைகளும், அவர்களுடைய நாயகிகள் உன் கைத்தாய்களுமாயிருப்பார்கள்; தரையிலே முகங்குப்புற விழுந்து உன்னைப் பணிந்து, உன் கால்களின் தூளை நக்குவார்கள்; நான் கர்த்தர், எனக்குக் காத்திருக்கிறவர்கள் வெட்கப்படுவதில்லை என்பதை அப்பொழுது அறிந்துகொள்வாய்.
	<br /><br />
	24. பராக்கிரமன் கையிலிருந்து கொள்ளைப்பொருளைப் பறிக்கக்கூடுமோ? அல்லது நீதியாய்ச் சிறைப்பட்டுப்போனவர்களை விடுவிக்கக்கூடுமோ?
	<br /><br />
	25. என்றாலும் இதோ, பராக்கிரமனால் சிறைப்படுத்தப்பட்டவர்களும் விடுவிக்கப்படுவார்கள்; பெலவந்தனால் கொள்ளையிடப்பட்டதும் விடுதலையாக்கப்படும்; உன்னோடு வழக்காடுகிறவர்களோடே நான் வழக்காடி, உன் பிள்ளைகளை இரட்சித்துக்கொள்ளுவேன்;
	<br /><br />
	26. உன்னை ஒடுக்கினவர்களுடைய மாம்சத்தை அவர்களுக்கே தின்னக்கொடுப்பேன்; மதுபானத்தால் வெறிகொள்வதுபோல் தங்களுடைய இரத்தத்தினால் வெறிகொள்வார்கள்; கர்த்தரும் யாக்கோபின் வல்லவருமாகிய நான் உன் இரட்சகரும் உன் மீட்பருமாயிருக்கிறதை மாம்சமான யாவரும் அறிந்துகொள்வார்களென்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah49