import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 15 </title>
	<meta
          name="description"
          content="Acts 15 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 15 |
          Acts of Apostles Chapter-15 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சிலர் யூதேயாவிலிருந்து வந்து: நீங்கள் மோசேயினுடைய முறைமையின்படியே விருத்தசேதனமடையாவிட்டால், இரட்சிக்கப்படமாட்டீர்கள் என்று சகோதரருக்குப் போதகம்பண்ணினார்கள்.
	<br /><br />
	2. அதினாலே அவர்களுக்கும் பவுல் பர்னபா என்பவர்களுக்கும் மிகுந்த வாக்குவாதமும் தர்க்கமும் உண்டானபோது, அந்த விஷயத்தினிமித்தம் பவுலும் பர்னபாவும் அவர்களைச் சேர்ந்த வேறு சிலரும் எருசலேமிலிருக்கிற அப்போஸ்தலரிடத்திற்கும் மூப்பரிடத்திற்கும் போகவேண்டுமென்று தீர்மானித்தார்கள்.
	<br /><br />
	3. அந்தப்படி அவர்கள் சபையாரால் வழிவிட்டனுப்பப்பட்டு, பெனிக்கே சமாரியா நாடுகளின் வழியாய்ப் போய், புறஜாதியார் மனந்திரும்பின செய்தியை அறிவித்து, சகோதரர் எல்லாருக்கும் மிகுந்த சந்தோஷத்தை உண்டாக்கினார்கள்.
	<br /><br />
	4. அவர்கள் எருசலேமுக்கு வந்து, சபையாராலும் அப்போஸ்தலராலும் மூப்பராலும் ஏற்றுக்கொள்ளப்பட்டபோது, தேவன் தங்களைக்கொண்டு செய்தவைகளையெல்லாம் அறிவித்தார்கள்.
	<br /><br />
	5. அப்பொழுது பரிசேய சமயத்தாரில் விசுவாசிகளான சிலர் எழுந்து, அவர்களை விருத்தசேதனம்பண்ணுகிறதும் மோசேயின் நியாயப்பிரமாணத்தைக் கைக்கொள்ளும்படி அவர்களுக்குக் கற்பிக்கிறதும் அவசியம் என்றார்கள்.
	<br /><br />
	6. அப்போஸ்தலரும் மூப்பரும் இந்தக் காரியத்தைக் குறித்து ஆலோசனைபண்ணும்படி கூடினார்கள்.
	<br /><br />
	7. மிகுந்த தர்க்கம் உண்டானபோது, பேதுரு எழுந்து, அவர்களை நோக்கி: சகோதரரே, நீங்கள் அறிந்திருக்கிறபடி புறஜாதியார் என்னுடைய வாயினாலே சுவிசேஷ வசனத்தைக் கேட்டு விசுவாசிக்கும்படி தேவன் அநேக நாட்களுக்கு முன்னே உங்களில் ஒருவனாகிய என்னைத் தெரிந்துகொண்டார்.
	<br /><br />
	8. இருதயங்களை அறிந்திருக்கிற தேவன் நமக்குப் பரிசுத்தஆவியைத் தந்தருளினதுபோல அவர்களுக்கும் தந்தருளி, அவர்களைக்குறித்துச் சாட்சிகொடுத்தார்;
	<br /><br />
	9. விசுவாசத்தினாலே அவர்கள் இருதயங்களை அவர் சுத்தமாக்கி, நமக்கும் அவர்களுக்கும் யாதொரு வித்தியாசமுமிராதபடி செய்தார்.
	<br /><br />
	10. இப்படியிருக்க, நம்முடைய பிதாக்களாலும் நம்மாலும் சுமக்கக்கூடாதிருந்த நுகத்தடியைச் சீஷர் கழுத்தின்மேல் சுமத்துவதினால், நீங்கள் தேவனைச் சோதிப்பானேன்?
	<br /><br />
	11. கர்த்தராகிய இயேசுகிறிஸ்துவின் கிருபையினாலே அவர்கள் இரட்சிக்கப்படுகிறது எப்படியோ, அப்படியே நாமும் இரட்சிக்கப்படுவோமென்று நம்பியிருக்கிறோமே என்றான்.
	<br /><br />
	12. அப்பொழுது கூடிவந்திருந்த யாவரும் அமைந்திருந்து, பர்னபாவும் பவுலும் தங்களைக்கொண்டு தேவன் புறஜாதிகளுக்குள்ளே செய்த அடையாளங்கள் அற்புதங்கள் யாவையும் விவரித்துச் சொல்லக்கேட்டார்கள்.
	<br /><br />
	13. அவர்கள் பேசி முடிந்தபின்பு, யாக்கோபு அவர்களை நோக்கி: சகோதரரே, எனக்குச் செவிகொடுங்கள்.
	<br /><br />
	14. தேவன் புறஜாதிகளினின்று தமது நாமத்திற்காக ஒரு ஜனத்தைத் தெரிந்துகொள்ளும்படி முதல்முதல் அவர்களுக்குக் கடாட்சித்தருளின விதத்தைச் சிமியோன் விவரித்துச் சொன்னாரே.
	<br /><br />
	15. அதற்குத் தீர்க்கதரிசிகளுடைய வாக்கியங்களும் ஒத்திருக்கிறது.
	<br /><br />
	16. எப்படியெனில், மற்ற மனுஷரும், என்னுடைய நாமந்தரிக்கப்படும் சகல ஜாதிகளும், கர்த்தரைத் தேடும்படிக்கு,
	<br /><br />
	17. நான் இதற்குப்பின்பு திரும்பிவந்து, விழுந்துபோன தாவீதின் கூடாரத்தை மறுபடியும் எடுப்பித்து, அதிலே பழுதாய்ப்போனவைகளை மறுபடியும் சீர்ப்படுத்தி, அதைச் செவ்வையாக நிறுத்துவேன் என்று இவைகளையெல்லாஞ் செய்கிற கர்த்தர் சொல்லுகிறார் என்று எழுதியிருக்கிறது.
	<br /><br />
	18. உலகத்தோற்றமுதல் தேவனுக்குத் தம்முடைய கிரியைகளெல்லாம் தெரிந்திருக்கிறது.
	<br /><br />
	19. ஆதலால் புறஜாதிகளில் தேவனிடத்தில் திரும்புகிறவர்களைக் கலங்கப்பண்ணலாகாதென்றும்,
	<br /><br />
	20. விக்கிரகங்களுக்குப் படைத்த அசுசியானவைகளுக்கும், வேசித்தனத்திற்கும், நெருக்குண்டு செத்ததிற்கும், இரத்தத்திற்கும், விலகியிருக்கும்படி அவர்களுக்கு நாம் எழுதவேண்டுமென்றும் நான் தீர்மானிக்கிறேன்.
	<br /><br />
	21. மோசேயின் ஆகமங்கள் ஓய்வுநாள்தோறும் ஜெபஆலயங்களில் வாசிக்கப்பட்டு வருகிறபடியால், பூர்வகாலந்தொடங்கிச் சகல பட்டணங்களிலும் அந்த ஆகமங்களைப் பிரசங்கிக்கிறவர்களும் உண்டே என்றான்.
	<br /><br />
	22. அப்பொழுது தங்களில் சிலரைத் தெரிந்துகொண்டு பவுலோடும் பர்னபாவோடும் அந்தியோகியாவுக்கு அனுப்புகிறது அப்போஸ்தலருக்கும் மூப்பருக்கும் சபையாரெல்லாருக்கும் நலமாகக்கண்டது. அவர்கள் யாரென்றால், சகோதரரில் விசேஷித்தவர்களாகிய பர்சபா என்று மறுபேர்கொண்ட யூதாவும் சீலாவுமே.
	<br /><br />
	23. இவர்கள் கையில் அவர்கள் எழுதிக்கொடுத்தனுப்பின நிருபமாவது: அப்போஸ்தலரும் மூப்பரும் சகோதரருமாகிய நாங்கள் அந்தியோகியாவிலும் சீரியாவிலும் சிலிசியாவிலும் இருக்கும் புறஜாதியாராகிய சகோதரருக்கு வாழ்த்துதல் சொல்லி எழுதிய நிருபம் என்னவென்றால்:
	<br /><br />
	24. எங்களால் கட்டளைபெறாத சிலர் எங்களிடத்திலிருந்து புறப்பட்டு, நீங்கள் விருத்தசேதனமடைய வேண்டுமென்றும், நியாயப்பிரமாணத்தைக் கைக்கொள்ளவேண்டுமென்றும் சொல்லி, இப்படிப்பட்ட வார்த்தைகளால் உங்களைக் கலக்கி, உங்கள் ஆத்துமாக்களைப் புரட்டினார்கள் என்று நாங்கள் கேள்விப்பட்டபடியினாலே,
	<br /><br />
	25. நம்முடைய கர்த்தராகிய இயேசு கிறிஸ்துவின் நாமத்திற்காகத் தங்கள் பிராணனையும் ஒப்புக்கொடுக்கத் துணிந்தவர்களும் எங்களுக்குப் பிரியமானவர்களுமாயிருக்கிற பர்னபா பவுல் என்பவர்களோடுங்கூட,
	<br /><br />
	26. எங்களால் தெரிந்துகொள்ளப்பட்ட சில மனுஷரை உங்களிடத்திற்கு அனுப்புகிறது ஒருமனப்பட்டுக் கூடின எங்களுக்கு நலமாகக் கண்டது.
	<br /><br />
	27. அந்தப்படியே யூதாவையும் சீலாவையும் அனுப்பியிருக்கிறோம். அவர்களும் இவைகளை வாய்மொழியாக உங்களுக்கு அறிவிப்பார்கள்.
	<br /><br />
	28. எவையெனில், விக்கிரகங்களுக்குப் படைத்தவைகளுக்கும், இரத்தத்திற்கும், நெருக்குண்டு செத்ததிற்கும், வேசித்தனத்திற்கும், நீங்கள் விலகியிருக்கவேண்டுமென்பதே.
	<br /><br />
	29. அவசியமான இவைகளையல்லாமல் பாரமான வேறொன்றையும் உங்கள்மேல் சுமத்தாமலிருப்பது பரிசுத்தஆவிக்கும் எங்களுக்கும் நலமாகக் கண்டது; இவைகளுக்கு விலகி நீங்கள் உங்களைக் காத்துக்கொள்வது நலமாயிருக்கும். சுகமாயிருப்பீர்களாக என்று எழுதினார்கள்.
	<br /><br />
	30. அவர்கள் அனுப்பிவிடப்பட்டு, அந்தியோகியாவுக்கு வந்து, சபையைக் கூடிவரச்செய்து, நிருபத்தை ஒப்புவித்தார்கள்.
	<br /><br />
	31. அதை அவர்கள் வாசித்து, அதனாலுண்டாகிய ஆறுதலுக்காகச் சந்தோஷப்பட்டார்கள்.
	<br /><br />
	32. யூதா சீலா என்பவர்களும் தீர்க்கதரிசிகளாயிருந்தபடியினாலே அநேக வார்த்தைகளினால் சகோதரருக்குப் புத்திசொல்லி, அவர்களைத் திடப்படுத்தி;
	<br /><br />
	33. சிலகாலம் அங்கேயிருந்து, பின்பு சகோதரரால் சமாதானத்தோடே அப்போஸ்தலரிடத்திற்கு அனுப்பிவிடப்பட்டார்கள்.
	<br /><br />
	34. ஆகிலும் சீலாவுக்கு அங்கே தரித்திருக்கிறது நலமாய்க் கண்டது.
	<br /><br />
	35. பவுலும் பர்னபாவும் அந்தியோகியாவிலே சஞ்சரித்து, வேறே அநேகரோடுங்கூடக் கர்த்தருடைய வசனத்தை உபதேசித்துப் பிரசங்கித்துக்கொண்டிருந்தார்கள்.
	<br /><br />
	36. சிலநாளைக்குப்பின்பு பவுல் பர்னபாவை நோக்கி: நாம் கர்த்தருடைய வசனத்தை அறிவித்திருக்கிற சகல பட்டணங்களிலுமுள்ள சகோதரர்கள் எப்படியிருக்கிறார்களென்று போய்ப்பார்ப்போம் வாரும் என்றான்.
	<br /><br />
	37. அப்பொழுது பர்னபா என்பவன் மாற்கு என்னும் பேர்கொண்ட யோவானைக்கூட அழைத்துக்கொண்டு போகவேண்டும் என்றான்.
	<br /><br />
	38. பவுலோ: அவன் பம்பிலியா நாட்டிலே நம்மை விட்டுப் பிரிந்து நம்மோடேகூட ஊழியத்துக்கு வராததினாலே, அவனை அழைத்துக்கொண்டு போகக்கூடாது என்றான்.
	<br /><br />
	39. இதைப்பற்றி அவர்களுக்குள்ளே கடுங்கோபமூண்டபடியினால் அவர்கள் ஒருவரையொருவர் விட்டுப் பிரிந்தார்கள். பர்னபா மாற்குவைக் கூட்டிக்கொண்டு கப்பல் ஏறிச் சீப்புருதீவுக்குப் போனான்.
	<br /><br />
	40. பவுலோ சீலாவைத் தெரிந்துகொண்டு, சகோதரராலே தேவனுடைய கிருபைக்கு ஒப்புவிக்கப்பட்டு, புறப்பட்டு,
	<br /><br />
	41. சீரியாவிலும் சிலிசியாவிலும் திரிந்து, சபைகளைத் திடப்படுத்தினான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts15