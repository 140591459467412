import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 10 </title>
	<meta
          name="description"
          content="Daniel 10 | தானியேல் தீர்க்கதரிசி | தானியேல் 10 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பெர்சியாவின் ராஜாவாகிய கோரேஸ் அரசாண்ட மூன்றாம் வருஷத்திலே பெல்தெஷாத்சார் என்று பெயரிடப்பட்ட தானியேலுக்கு ஒரு காரியம் வெளியாக்கப்பட்டது; அந்தக் காரியம் சத்தியமும் நீடிய யுத்தத்துக்கு அடுத்ததுமாயிருக்கிறது; அந்தக் காரியத்தை அவன் கவனித்து, தரிசனத்தின் பொருளை அறிந்துகொண்டான்.
	<br /><br />
	2. அந்த நாட்களில் தானியேலாகிய நான் மூன்று வாரமுழுவதும் துக்கித்துக்கொண்டிருந்தேன்.
	<br /><br />
	3. அந்த மூன்று வாரங்களாகிய நாட்கள் நிறைவேறுமட்டும் ருசிகரமான அப்பத்தை நான் புசிக்கவுமில்லை, இறைச்சியும் திராட்சரசமும் என் வாய்க்குள் போகவுமில்லை, நான் பரிமளதைலம் பூசிக்கொள்ளவுமில்லை.
	<br /><br />
	4. முதலாம் மாதம் இருபத்துநாலாந் தேதியிலே நான் இதெக்கேல் என்னும் பெரிய ஆற்றங்கரையில் இருந்து,
	<br /><br />
	5. என் கண்களை ஏறெடுக்கையில், சணல் வஸ்திரந்தரித்து, தமது அரையில் ஊப்பாசின் தங்கக்கச்சையைக் கட்டிக்கொண்டிருக்கிற ஒரு புருஷனைக் கண்டேன்.
	<br /><br />
	6. அவருடைய சரீரம் படிகப்பச்சையைப்போலவும், அவருடைய முகம் மின்னலின் பிரகாசத்தைப்போலவும், அவருடைய கண்கள் எரிகிற தீபங்களைப்போலவும், அவருடைய புயங்களும் அவருடைய கால்களும் துலக்கப்பட்ட வெண்கல நிறத்தைப்போலவும், அவருடைய வார்த்தைகளின் சத்தம் ஜனக்கூட்டத்தின் ஆரவாரத்தைப்போலவும் இருந்தது.
	<br /><br />
	7. தானியேலாகிய நான் மாத்திரம் அந்தத் தரிசனத்தைக் கண்டேன்; என்னோடே இருந்த மனுஷரோ அந்தத் தரிசனத்தைக் காணவில்லை; அவர்கள் மிகவும் நடுநடுங்கி, ஓடி ஒளித்துக்கொண்டார்கள்.
	<br /><br />
	8. நான் தனித்துவிடப்பட்டு அந்தப் பெரிய தரிசனத்தைக் கண்டேன்; என் பெலனெல்லாம் போயிற்று; என் உருவம் மாறி வாடிப்போயிற்று; திடனற்றுப்போனேன்.
	<br /><br />
	9. அவருடைய வார்த்தைகளின் சத்தத்தைக் கேட்டேன்; அவருடைய வார்த்தைகளின் சத்தத்தை நான் கேட்கும்போது, நான் முகங்கவிழ்ந்து, நித்திரை போகிறவனாய்த் தரையிலே முகங்குப்புற விழுந்துகிடந்தேன்.
	<br /><br />
	10. இதோ, ஒருவன் கை என்னைத் தொட்டு, என் முழங்கால்களும் என் உள்ளங்கைகளும் தரையை ஊன்றியிருக்க என்னைத் தூக்கிவைத்தது.
	<br /><br />
	11. அவன் என்னை நோக்கி: பிரியமான புருஷனாகிய தானியேலே, நான் இப்போது உன்னிடத்திற்கு அனுப்பப்பட்டு வந்தேன்; ஆதலால், நான் உனக்குச் சொல்லும் வார்த்தைகளின்பேரில் நீ கவனமாயிருந்து, கால் ஊன்றி நில் என்றான்; இந்த வார்த்தையை அவன் என்னிடத்தில் சொல்லுகையில் நடுக்கத்தோடே எழுந்து நின்றேன்.
	<br /><br />
	12. அப்பொழுது அவன் என்னை நோக்கி: தானியேலே, பயப்படாதே; நீ அறிவை அடைகிறதற்கும், உன்னை உன்னுடைய தேவனுக்கு முன்பாகச் சிறுமைப்படுத்துகிறதற்கும், உன் மனதைச் செலுத்தின முதல்நாள் துவக்கி உன் வார்த்தைகள் கேட்கப்பட்டது; உன் வார்த்தைகளினிமித்தம் நான் வந்தேன்.
	<br /><br />
	13. பெர்சியா ராஜ்யத்தின் அதிபதி இருபத்தொரு நாள்மட்டும் என்னோடு எதிர்த்து நின்றான்; ஆனாலும் பிரதான அதிபதிகளில் ஒருவனாகிய மிகாவேல் எனக்கு உதவியாக வந்தான்; ஆதலால் நான் அங்கே பெர்சியாவின் ராஜாக்களிடத்தில் தரித்திருந்தேன்.
	<br /><br />
	14. இப்போதும் கடைசிநாட்களில் உன் ஜனங்களுக்குச் சம்பவிப்பதை உனக்குத் தெரியப்பண்ணும்படிக்கு வந்தேன்; இந்தத் தரிசனம் நிறைவேற இன்னும் நாள் செல்லும் என்றான்.
	<br /><br />
	15. அவன் இந்த வார்த்தைகளை என்னோடே சொல்லுகையில், நான் தலைகவிழ்ந்து, தரையை நோக்கி, பேச்சற்றுப்போனேன்.
	<br /><br />
	16. அப்பொழுது மனுபுத்திரரின் சாயலாகிய ஒருவன் என் உதடுகளைத் தொட்டான்; உடனே நான் என் வாயைத் திறந்து பேசி, எனக்கு எதிரே நின்றவனை நோக்கி: என் ஆண்டவனே, தரிசனத்தினால் என் மூட்டுகள் புரண்டன, பெலனற்றுப்போனேன்.
	<br /><br />
	17. ஆகையால் என் ஆண்டவனுடைய அடியேன் என் ஆண்டவனோடே பேசக்கூடுவதெப்படி? இனி என்னில் பெலனில்லை, என்னில் மூச்சுமில்லை என்றேன்.
	<br /><br />
	18. அப்பொழுது மனுஷ ரூபமான ஒருவன் திரும்ப என்னைத் தொட்டு, என்னைத் திடப்படுத்தி,
	<br /><br />
	19. பிரியமான புருஷனே, பயப்படாதே, உனக்குச் சமாதானமுண்டாவதாக, திடங்கொள், திடங்கொள் என்றான்; இப்படி அவன் என்னோடே பேசுகையில் நான் திடங்கொண்டு அவனை நோக்கி: என் ஆண்டவன் பேசுவாராக; என்னைத் திடப்படுத்தினீரே என்றேன்.
	<br /><br />
	20. அப்பொழுது அவன்: நான் உன்னிடத்திற்கு வந்த காரணம் இன்னதென்று உனக்குத் தெரியுமா? இப்போது நான் பெர்சியாவின் பிரபுவோடே யுத்தம்பண்ணத் திரும்பிப்போகிறேன்; நான் போனபின்பு, கிரேக்கு தேசத்தின் அதிபதி வருவான்.
	<br /><br />
	21. சத்திய எழுத்திலே கண்டிருக்கிறதை நான் உனக்குத் தெரிவிப்பேன்; உங்கள் அதிபதியாகிய மிகாவேலைத்தவிர என்னோடேகூட அவர்களுக்கு விரோதமாய்ப் பலங்கொள்ளுகிற வேறொருவரும் இல்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel10