import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 14 </title>
	<meta
          name="description"
          content="Luke 14 | லூக்கா 14 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஒரு ஓய்வுநாளிலே பரிசேயரில் தலைவனாகிய ஒருவனுடைய வீட்டிலே அவர் போஜனம் பண்ணும்படிக்குப் போயிருந்தார்.
	<br /><br />
	2. அப்பொழுது நீர்க்கோவை வியாதியுள்ள ஒரு மனுஷன் அவருக்கு முன்பாக இருந்தான். என்ன செய்வாரோவென்று ஜனங்கள் அவர்மேல் நோக்கமாயிருந்தார்கள்.
	<br /><br />
	3. இயேசு நியாயசாஸ்திரிகளையும் பரிசேயரையும் பார்த்து: ஓய்வு நாளிலே சொஸ்தமாக்குகிறது நியாயமா என்று கேட்டார்.
	<br /><br />
	4. அதற்கு அவர்கள் பேசாமலிருந்தார்கள். அப்பொழுது அவர் அவனை அழைத்து, சொஸ்தமாக்கி, அனுப்பிவிட்டு,
	<br /><br />
	5. அவர்களை நோக்கி: உங்களில் ஒருவனுடைய கழுதையாவது எருதாவது ஓய்வுநாளில் துரவிலே விழுந்தால், அவன் அதை உடனே தூக்கிவிடானோ என்றார்.
	<br /><br />
	6. அதற்கு உத்தரவுசொல்ல அவர்களால் கூடாமற்போயிற்று.
	<br /><br />
	7. விருந்துக்கு அழைக்கப்பட்டவர்கள் பந்தியில் முதன்மையான இடங்களைத் தெரிந்துகொண்டதை அவர் பார்த்து, அவர்களுக்கு ஒரு உவமையைச் சொன்னார்:
	<br /><br />
	8. ஒருவனால் கலியாணத்துக்கு நீ அழைக்கப்பட்டிருக்கும்போது, பந்தியில் முதன்மையான இடத்தில் உட்காராதே; உன்னிலும் கனமுள்ளவன் ஒருவேளை அவனால் அழைக்கப்பட்டிருப்பான்.
	<br /><br />
	9. அப்பொழுது உன்னையும் அவனையும் அழைத்தவன் உன்னிடத்தில் வந்து: இவருக்கு இடங்கொடு என்பான்; அப்பொழுது நீ வெட்கத்தோடே தாழ்ந்த இடத்திற்குப் போகவேண்டியதாயிருக்கும்.
	<br /><br />
	10. நீ அழைக்கப்பட்டிருக்கும்போது, போய், தாழ்ந்த இடத்தில் உட்காரு; அப்பொழுது உன்னை அழைத்தவன் வந்து: சிநேகிதனே, உயர்ந்த இடத்தில் வாரும் என்று சொல்லும்போது, உன்னுடனேகூடப் பந்தியிருக்கிறவர்களுக்கு முன்பாக உனக்குக் கனமுண்டாகும்.
	<br /><br />
	11. தன்னைத்தான் உயர்த்துகிறவனெவனும் தாழ்த்தப்படுவான், தன்னைத்தான் தாழ்த்துகிறவன் உயர்த்தப்படுவான் என்றார்.
	<br /><br />
	12. அன்றியும் அவர் தம்மை விருந்துக்கு அழைத்தவனை நோக்கி: நீ பகல்விருந்தாவது இராவிருந்தாவது பண்ணும்போது, உன் சிநேகிதரையாகிலும் உன் சகோதரரையாகிலும், உன் பந்து ஜனங்களையாகிலும், ஐசுவரியமுள்ள அயலகத்தாரையாகிலும் அழைக்கவேண்டாம்; அழைத்தால் அவர்களும் உன்னை அழைப்பார்கள், அப்பொழுது உனக்குப் பதிலுக்குப் பதில் செய்ததாகும்.
	<br /><br />
	13. நீ விருந்துபண்ணும்போது ஏழைகளையும் ஊனரையும் சப்பாணிகளையும் குருடரையும் அழைப்பாயாக.
	<br /><br />
	14. அப்பொழுது நீ பாக்கியவானாயிருப்பாய்; அவர்கள் உனக்குப் பதில் செய்யமாட்டார்கள்; நீதிமான்களின் உயிர்த்தெழுதலில் உனக்குப் பதில் செய்யப்படும் என்றார்.
	<br /><br />
	15. அவரோடேகூடப் பந்தியிருந்தவர்களில் ஒருவன் இவைகளைக் கேட்டபொழுது, அவரை நோக்கி: தேவனுடைய ராஜ்யத்தில் போஜனம்பண்ணுகிறவன் பாக்கியவான் என்றான்.
	<br /><br />
	16. அதற்கு அவர்: ஒரு மனுஷன் பெரியவிருந்தை ஆயத்தம்பண்ணி, அநேகரை அழைப்பித்தான்.
	<br /><br />
	17. விருந்து வேளையில் தன் ஊழியக்காரனை நோக்கி: நீ அழைக்கப்பட்டவர்களிடத்தில் போய், எல்லாம் ஆயத்தமாயிருக்கிறது, வாருங்கள், என்று சொல்லென்று அவனை அனுப்பினான்.
	<br /><br />
	18. அவர்களெல்லாரும் போக்குச்சொல்லத் தொடங்கினார்கள். ஒருவன்: ஒரு வயலைக்கொண்டேன், நான் அகத்தியமாய்ப்போய், அதைப் பார்க்கவேண்டும், என்னை மன்னிக்கும்படி வேண்டிக்கொள்ளுகிறேன் என்றான்.
	<br /><br />
	19. வேறொருவன்: ஐந்தேர்மாடு கொண்டேன், அதைச் சோதித்துப் பார்க்கப் போகிறேன், என்னை மன்னிக்கும்படி வேண்டிக்கொள்ளுகிறேன் என்றான்.
	<br /><br />
	20. வேறொருவன்: பெண்ணை விவாகம்பண்ணினேன், அதினால் நான் வரக்கூடாது என்றான்.
	<br /><br />
	21. அந்த ஊழியக்காரன் வந்து, இவைகளைத் தன் எஜமானுக்கு அறிவித்தான். அப்பொழுது வீட்டெஜமான் கோபமடைந்து, தன் ஊழியக்காரனை நோக்கி: நீ பட்டணத்தின் தெருக்களிலும் வீதிகளிலும் சீக்கிரமாய்ப் போய், ஏழைகளையும் ஊனரையும் சப்பாணிகளையும் குருடரையும் இங்கே கூட்டிக்கொண்டுவா என்றான்.
	<br /><br />
	22. ஊழியக்காரன் அப்படியே செய்து: ஆண்டவரே, நீர் கட்டளையிட்டபடி செய்தாயிற்று, இன்னும் இடம் இருக்கிறது என்றான்.
	<br /><br />
	23. அப்பொழுது எஜமான் ஊழியக்காரனை நோக்கி: நீ பெருவழிகளிலும் வேலிகளருகிலும் போய், என் வீடு நிறையும்படியாக ஜனங்களை உள்ளே வரும்படி வருந்திக் கூட்டிக்கொண்டுவா;
	<br /><br />
	24. அழைக்கப்பட்டிருந்த அந்த மனுஷரில் ஒருவனாகிலும் என் விருந்தை ருசிபார்ப்பதில்லை யென்று உங்களுக்குச் சொல்லுகிறேன் என்றான் என்று சொன்னார்.
	<br /><br />
	25. பின்பு அநேக ஜனங்கள் அவரோடேகூடப் பிரயாணமாய்ப் போகையில், அவர்களிடமாய் அவர் திரும்பிப்பார்த்து:
	<br /><br />
	26. யாதொருவன் என்னிடத்தில் வந்து, தன் தகப்பனையும் தாயையும் மனைவியையும் பிள்ளைகளையும் சகோதரரையும் சகோதரிகளையும், தன் ஜீவனையும் வெறுக்காவிட்டால் எனக்குச் சீஷனாயிருக்கமாட்டான்.
	<br /><br />
	27. தன் சிலுவையைச் சுமந்துகொண்டு எனக்குப் பின்செல்லாதவன் எனக்குச் சீஷனாயிருக்கமாட்டான்.
	<br /><br />
	28. உங்களில் ஒருவன் ஒரு கோபுரத்தைக் கட்ட மனதாயிருந்து,
	<br /><br />
	29. அஸ்திபாரம் போட்டபின்பு முடிக்கத் திராணியில்லாமற்போனால், பார்க்கிறவர்களெல்லாரும்:
	<br /><br />
	30. இந்த மனுஷன் கட்டத்தொடங்கி, முடிக்கத் திராணியில்லாமற்போனான் என்று சொல்லித் தன்னைப் பரியாசம்பண்ணாதபடிக்கு, அதைக் கட்டித் தீர்க்கிறதற்குத் தனக்கு நிர்வாகமுண்டோ இல்லையோ என்று முன்பு அவன் உட்கார்ந்து செல்லுஞ்செலவைக் கணக்குப் பாராமலிருப்பானோ?
	<br /><br />
	31. அன்றியும் ஒரு ராஜா மற்றொரு ராஜாவோடே யுத்தஞ்செய்யப் போகிறபோது, தன்மேல் இருபதினாயிரம் சேவகரோடே வருகிற அவனைத் தான் பதினாயிரம் சேவகரைக்கொண்டு எதிர்க்கக் கூடுமோ கூடாதோ என்று முன்பு உட்கார்ந்து ஆலோசனைபண்ணாமலிருப்பானோ?
	<br /><br />
	32. கூடாதென்று கண்டால், மற்றவன் இன்னும் தூரத்திலிருக்கும்போதே, ஸ்தானாபதிகளை அனுப்பி, சமாதானத்துக்கானவைகளைக் கேட்டுக்கொள்வானே.
	<br /><br />
	33. அப்படியே உங்களில் எவனாகிலும் தனக்கு உண்டானவைகளையெல்லாம் வெறுத்துவிடாவிட்டால் அவன் எனக்குச் சீஷனாயிருக்கமாட்டான்.
	<br /><br />
	34. உப்பு நல்லதுதான், உப்பு சாரமற்றுப்போனால் எதினால் சாரமாக்கப்படும்?
	<br /><br />
	35. அது நிலத்துக்காகிலும் எருவுக்காகிலும் உதவாது, அதை வெளியே கொட்டிப் போடுவார்கள். கேட்கிறதற்குக் காதுள்ளவன் கேட்கக்கடவன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke14