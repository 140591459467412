import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 9 </title>
	<meta
          name="description"
          content="John Chapter 9 | யோவான் அதிகாரம் 9 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் அப்புறம் போகையில் பிறவிக் குருடனாகிய ஒரு மனுஷனைக் கண்டார்.
	<br /><br />
	2. அப்பொழுது அவருடைய சீஷர்கள் அவரை நோக்கி: ரபீ, இவன் குருடனாய்ப் பிறந்தது யார் செய்த பாவம், இவன் செய்த பாவமோ, இவனைப் பெற்றவர்கள் செய்த பாவமோ என்று கேட்டார்கள்.
	<br /><br />
	3. இயேசு பிரதியுத்தரமாக: அது இவன் செய்த பாவமுமல்ல, இவனைப் பெற்றவர்கள் செய்த பாவமுமல்ல, தேவனுடைய கிரியைகள் இவனிடத்தில் வெளிப்படும்பொருட்டு இப்படிப் பிறந்தான்.
	<br /><br />
	4. பகற்காலமிருக்குமட்டும் நான் என்னை அனுப்பினவருடைய கிரியைகளைச் செய்யவேண்டும்; ஒருவனும் கிரியை செய்யக்கூடாத இராக்காலம் வருகிறது.
	<br /><br />
	5. நான் உலகத்திலிருக்கையில் உலகத்திற்கு ஒளியாயிருக்கிறேன் என்றார்.
	<br /><br />
	6. இவைகளைச் சொல்லி, அவர் தரையிலே துப்பி, உமிழ்நீரினால் சேறுண்டாக்கி, அந்தச் சேற்றைக் குருடனுடைய கண்களின்மேல் பூசி:
	<br /><br />
	7. நீ போய், சீலோவாம் குளத்திலே கழுவு என்றார். சீலோவாம் என்பதற்கு அனுப்பப்பட்டவன் என்று அர்த்தமாம். அப்படியே அவன் போய்க் கழுவி, பார்வையடைந்தவனாய்த் திரும்பிவந்தான்.
	<br /><br />
	8. அப்பொழுது அயலகத்தாரும், அவன் குருடனாயிருக்கையில் அவனைக் கண்டிருந்தவர்களும்: இவன் உட்கார்ந்து பிச்சை கேட்டுக்கொண்டிருந்தவனல்லவா என்றார்கள்.
	<br /><br />
	9. சிலர்: அவன்தான் என்றார்கள். வேறுசிலர்: அவனுடைய சாயலாயிருக்கிறான் என்றார்கள். அவனோ: நான்தான் அவன் என்றான்.
	<br /><br />
	10. அப்பொழுது அவர்கள் அவனை நோக்கி: உன் கண்கள் எப்படித் திறக்கப்பட்டது என்றார்கள்.
	<br /><br />
	11. அவன் பிரதியுத்தரமாக: இயேசு என்னப்பட்ட ஒருவர் சேறுண்டாக்கி, என் கண்களின்மேல் பூசி, நீ போய் சீலோவாம் குளத்திலே கழுவு என்றார். அப்படியே நான் போய்க் கழுவி, பார்வையடைந்தேன் என்றான்.
	<br /><br />
	12. அப்பொழுது அவர்கள்: அவர் எங்கே என்றார்கள். அவன்: எனக்குத் தெரியாது என்றான்.
	<br /><br />
	13. குருடனாயிருந்த அவனைப் பரிசேயரிடத்திற்குக் கொண்டுபோனார்கள்.
	<br /><br />
	14. இயேசு சேறுண்டாக்கி, அவன் கண்களைத் திறந்த நாள் ஓய்வுநாளாயிருந்தது.
	<br /><br />
	15. ஆகையால் பரிசேயரும் அவனை நோக்கி: நீ எப்படிப் பார்வையடைந்தாய் என்று மறுபடியும் கேட்டார்கள். அதற்கு அவன்: அவர் என் கண்களின்மேல் சேற்றைப் பூசினார், நான் கழுவினேன், காண்கிறேன் என்றான்.
	<br /><br />
	16. அப்பொழுது பரிசேயரில் சிலர்: அந்த மனுஷன் ஓய்வுநாளைக் கைக்கொள்ளாததினால் அவன் தேவனிடத்திலிருந்து வந்தவனல்ல என்றார்கள். வேறுசிலர்: பாவியாயிருக்கிற மனுஷன் இப்படிப்பட்ட அற்புதங்களை எப்படிச் செய்வான் என்றார்கள். இவ்விதமாய் அவர்களுக்குள்ளே பிரிவினையுண்டாயிற்று.
	<br /><br />
	17. மறுபடியும் அவர்கள் குருடனை நோக்கி: உன் கண்களைத் திறந்தானே, அவனைக்குறித்து நீ என்ன சொல்லுகிறாய் என்றார்கள். அதற்கு அவன்: அவர் தீர்க்கதரிசி என்றான்.
	<br /><br />
	18. அவன் குருடனாயிருந்து பார்வையடைந்ததை யூதர்கள் நம்பாமல், பார்வையடைந்தவனுடைய தாய்தகப்பன்மாரை அழைப்பித்து,
	<br /><br />
	19. அவர்களை நோக்கி: உங்கள் குமாரன் குருடனாய்ப் பிறந்தான் என்று சொல்லுகிறீர்களே, அவன் இவன்தானா? இவனானால், இப்பொழுது இவன் எப்படிப் பார்வையடைந்தான் என்று கேட்டார்கள்.
	<br /><br />
	20. தாய்தகப்பன்மார் பிரதியுத்தரமாக: இவன் எங்கள் குமாரன்தான் என்றும், குருடனாய்ப் பிறந்தான் என்றும் எங்களுக்குத் தெரியும்.
	<br /><br />
	21. இப்பொழுது இவன் பார்வையடைந்த வகை எங்களுக்குத் தெரியாது; இவன் கண்களைத் திறந்தவன் இன்னான் என்பதும் எங்களுக்குத் தெரியாது; இவன் வயதுள்ளவனாயிருக்கிறான், இவனைக் கேளுங்கள், இவனே சொல்லுவான் என்றார்கள்.
	<br /><br />
	22. அவனுடைய தாய்தகப்பன்மார் யூதர்களுக்குப் பயந்ததினால் இப்படிச் சொன்னார்கள். ஏனெனில் இயேசுவைக் கிறிஸ்து என்று எவனாவது அறிக்கைபண்ணினால் அவனை ஜெபஆலயத்துக்குப் புறம்பாக்கவேண்டுமென்று யூதர்கள் அதற்குமுன்னமே கட்டுப்பாடு செய்திருந்தார்கள்.
	<br /><br />
	23. அதினிமித்தம்: இவன் வயதுள்ளவனாயிருக்கிறான், இவனையே கேளுங்கள் என்று அவன் தாய்தகப்பன்மார் சொன்னார்கள்.
	<br /><br />
	24. ஆதலால் அவர்கள் குருடனாயிருந்த மனுஷனை இரண்டாந்தரம் அழைத்து: நீ தேவனை மகிமைப்படுத்து; இந்த மனுஷன் பாவியென்று நாங்கள் அறிந்திருக்கிறோம் என்றார்கள்.
	<br /><br />
	25. அவன் பிரதியுத்தரமாக: அவர் பாவியென்று எனக்குத் தெரியாது; நான் குருடனாயிருந்தேன், இப்பொழுது காண்கிறேன்; இது ஒன்றுதான் எனக்குத் தெரியும் என்றான்.
	<br /><br />
	26. அவர்கள் மறுபடியும் அவனை நோக்கி: உனக்கு என்னசெய்தான், உன் கண்களை எப்படித் திறந்தான் என்றார்கள்.
	<br /><br />
	27. அவன் பிரதியுத்தரமாக: முன்னமே உங்களுக்குச் சொன்னேன், நீங்கள் கேளாமற்போனீர்கள்; மறுபடியும் கேட்க வேண்டியதென்ன? அவருக்குச் சீஷராக உங்களுக்கும் மனதுண்டோ என்றான்.
	<br /><br />
	28. அப்பொழுது அவர்கள் அவனை வைது: நீ அவனுடைய சீஷன், நாங்கள் மோசேயினுடைய சீஷர்.
	<br /><br />
	29. மோசேயுடனே தேவன் பேசினாரென்று அறிவோம், இவன் எங்கேயிருந்து வந்தவனென்று அறியோம் என்றார்கள்.
	<br /><br />
	30. அதற்கு அந்த மனுஷன்: அவர் என் கண்களைத் திறந்திருந்தும், அவர் எங்கேயிருந்து வந்தவரென்று நீங்கள் அறியாதிருக்கிறது ஆச்சரியமான காரியம்.
	<br /><br />
	31. பாவிகளுக்குத் தேவன் செவிகொடுக்கிறதில்லையென்று அறிந்திருக்கிறோம்; ஒருவன் தேவபக்தியுள்ளவனாயிருந்து அவருக்குச் சித்தமானதைச் செய்தால் அவனுக்குச் செவிகொடுப்பார்.
	<br /><br />
	32. பிறவிக்குருடனுடைய கண்களை ஒருவன் திறந்தானென்று உலகமுண்டானதுமுதல் கேள்விப்பட்டதில்லையே.
	<br /><br />
	33. அவர் தேவனிடத்திலிருந்து வராதிருந்தால் ஒன்றும் செய்யமாட்டாரே என்றான்.
	<br /><br />
	34. அவர்கள் அவனுக்குப் பிரதியுத்தரமாக: முழுவதும் பாவத்தில் பிறந்த நீ எங்களுக்குப் போதிக்கிறாயோ என்று சொல்லி, அவனைப் புறம்பே தள்ளிவிட்டார்கள்.
	<br /><br />
	35. அவனை அவர்கள் புறம்பே தள்ளிவிட்டதை இயேசு கேள்விப்பட்டு, அவனைக் கண்டபோது: நீ தேவனுடைய குமாரனிடத்தில் விசுவாசமாயிருக்கிறாயா என்றார்.
	<br /><br />
	36. அதற்கு அவன்: ஆண்டவரே, அவரிடத்தில் நான் விசுவாசமாயிருக்கும்படிக்கு அவர் யார் என்றான்.
	<br /><br />
	37. இயேசு அவனை நோக்கி: நீ அவரைக் கண்டிருக்கிறாய், உன்னுடனே பேசுகிறவர் அவர்தான் என்றார்.
	<br /><br />
	38. உடனே அவன்: ஆண்டவரே, விசுவாசிக்கிறேன் என்று சொல்லி, அவரைப் பணிந்துகொண்டான்.
	<br /><br />
	39. அப்பொழுது இயேசு: காணாதவர்கள் காணும்படியாகவும், காண்கிறவர்கள் குருடராகும்படியாகவும் நியாயத்தீர்ப்புக்கு நான் இந்த உலகத்தில் வந்தேன் என்றார்.
	<br /><br />
	40. அவருடனேகூட இருந்த பரிசேயரில் சிலர் இவைகளைக் கேட்டபொழுது: நாங்களும் குருடரோ என்றார்கள்.
	<br /><br />
	41. இயேசு அவர்களை நோக்கி: நீங்கள் குருடராயிருந்தால் உங்களுக்குப் பாவமிராது; நீங்கள் காண்கிறோம் என்று சொல்லுகிறபடியினால் உங்கள் பாவம் நிலைநிற்கிறது என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John9