import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts26 = () => {
  const verseNumber = 26;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 26 </title>
	<meta
          name="description"
          content="Acts 26 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 26 |
          Acts of Apostles Chapter-26 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அகிரிப்பா பவுலை நோக்கி: நீ உனக்காகப் பேச உனக்கு உத்தரவாகிறது என்றான். அப்பொழுது பவுல் கையை நீட்டி, தனக்காக உத்தரவு சொல்லத்தொடங்கினான்.
	<br /><br />
	2. அகிரிப்பா ராஜாவே, யூதர்கள் என்மேல் சாட்டுகிற சகல காரியங்களைக்குறித்தும் நான் இன்றைக்கு உமக்கு முன்பாக உத்தரவு சொல்லப்போகிறபடியினாலே என்னைப் பாக்கியவான் என்றெண்ணுகிறேன்.
	<br /><br />
	3. விசேஷமாய் நீர் யூதருடைய சகல முறைமைகளையும் தர்க்கங்களையும் அறிந்தவரானதால் அப்படி எண்ணுகிறேன்; ஆகையால் நான் சொல்வதைப் பொறுமையோடே கேட்கும்படி உம்மை வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	4. நான் என் சிறுவயதுமுதற்கொண்டு, எருசலேமிலே என் ஜனத்தாருக்குள்ளே இருந்தபடியால், ஆதிமுதல் நான் நடந்த நடக்கையை யூதரெல்லாரும் அறிந்திருக்கிறார்கள்.
	<br /><br />
	5. நம்முடைய மார்க்கத்திலுள்ள சமயபேதங்களில் மிகவும் கண்டிப்பான சமயத்துக்கு இசைந்தபடி பரிசேயனாய் நடந்தேனென்று சாட்சிசொல்ல அவர்களுக்கு மனதிருந்தால் சொல்லலாம்.
	<br /><br />
	6. தேவன் நம்முடைய பிதாக்களுக்கு அருளிச்செய்த வாக்குத்தத்தம் நிறைவேறுமென்கிற நம்பிக்கைக்காகவே நான் இப்பொழுது நியாயத்தீர்ப்படைகிறவனாய் நிற்கிறேன்.
	<br /><br />
	7. இரவும் பகலும் இடைவிடாமல் ஆராதனை செய்துவருகிற நம்முடைய பன்னிரண்டு கோத்திரத்தாரும் அந்த வாக்குத்தத்தம் நிறைவேறுமென்று நம்பியிருக்கிறார்கள். அகிரிப்பா ராஜாவே, அந்த நம்பிக்கையினிமித்தமே யூதர்கள் என்மேல் குற்றஞ்சாட்டுகிறார்கள்.
	<br /><br />
	8. தேவன் மரித்தோரை எழுப்புகிறது நம்பப்படாத காரியமென்று நீங்கள் எண்ணுகிறதென்ன?
	<br /><br />
	9. முன்னே நானும் நசரேயனாகிய இயேசுவின் நாமத்திற்கு விரோதமாய் அநேக காரியங்களை நடப்பிக்கவேண்டுமென்று நினைத்திருந்தேன்.
	<br /><br />
	10. அப்படியே நான் எருசலேமிலும் செய்தேன். நான் பிரதான ஆசாரியர்களிடத்தில் அதிகாரம் பெற்று, பரிசுத்தவான்களில் அநேகரைச் சிறைச்சாலைகளில் அடைத்தேன்; அவர்கள் கொலைசெய்யப்படுகையில் நானும் சம்மதித்திருந்தேன்.
	<br /><br />
	11. சகல ஜெபஆலயங்களிலும் நான் அவர்களை அநேகந்தரம் தண்டித்து, தேவதூஷணஞ் சொல்லக் கட்டாயப்படுத்தினேன்; அவர்கள் பேரில் மூர்க்கவெறி கொண்டவனாய் அந்நியபட்டணங்கள்வரைக்கும் அவர்களைத் துன்பப்படுத்தினேன்.
	<br /><br />
	12. இப்படிச் செய்துவருகையில், நான் பிரதான ஆசாரியர்களிடத்தில் அதிகாரமும் உத்தரவும் பெற்று, தமஸ்குவுக்குப் போகும்போது,
	<br /><br />
	13. மத்தியான வேளையில், ராஜாவே, நான் வழியிலே சூரியனுடைய பிரகாசத்திலும் அதிகமான ஒளி வானத்திலிருந்து என்னையும் என்னுடனேகூடப் பிரயாணம்பண்ணினவர்களையும் சுற்றிப் பிரகாசிக்கக்கண்டேன்.
	<br /><br />
	14. நாங்களெல்லாரும் தரையிலே விழுந்தபோது: சவுலே, சவுலே, நீ ஏன் என்னைத் துன்பப்படுத்துகிறாய்? முள்ளில் உதைக்கிறது உனக்குக் கடினமாம் என்று எபிரெயு பாஷையிலே என்னுடனே சொல்லுகிற ஒரு சத்தத்தைக் கேட்டேன்.
	<br /><br />
	15. அப்பொழுது நான்: ஆண்டவரே, நீர் யார் என்றேன். அதற்கு அவர்: நீ துன்பப்படுத்துகிற இயேசு நானே.
	<br /><br />
	16. இப்பொழுது நீ எழுந்து, காலுன்றி நில். நீ கண்டவைகளையும் நான் உனக்குத் தரிசனமாகிக் காண்பிக்கப்போகிறவைகளையும் குறித்து உன்னை ஊழியக்காரனாகவும் சாட்சியாகவும் ஏற்படுத்துகிறதற்காக உனக்குத் தரிசனமானேன்.
	<br /><br />
	17. உன் சுயஜனத்தாரிடத்தினின்றும் அந்நிய ஜனத்தாரிடத்தினின்றும் உன்னை விடுதலையாக்கி,
	<br /><br />
	18. அவர்கள் என்னைப் பற்றும் விசுவாசத்தினாலே பாவமன்னிப்பையும் பரிசுத்தமாக்கப்பட்டவர்களுக்குரிய சுதந்தரத்தையும் பெற்றுக்கொள்ளும்படியாக, அவர்கள் இருளைவிட்டு ஒளியினிடத்திற்கும், சாத்தானுடைய அதிகாரத்தைவிட்டுத் தேவனிடத்திற்கும் திரும்பும்படிக்கு நீ அவர்களுடைய கண்களைத் திறக்கும்பொருட்டு, இப்பொழுது உன்னை அவர்களிடத்திற்கு அனுப்புகிறேன் என்றார்.
	<br /><br />
	19. ஆகையால், அகிரிப்பா ராஜாவே, நான் அந்தப் பரமதரிசனத்துக்குக் கீழ்ப்படியாதவனாயிருக்கவில்லை.
	<br /><br />
	20. முன்பு தமஸ்குவிலும் எருசலேமிலும் யூதேயா தேசமெங்குமுள்ளவர்களிடத்திலும், பின்பு புறஜாதியாரிடத்திலும் நான் போய், அவர்கள் தேவனிடத்திற்கு மனந்திரும்பிக் குணப்படவும், மனந்திரும்புதலுக்கேற்ற கிரியைகளைச் செய்யவும் வேண்டுமென்று அறிவித்தேன்.
	<br /><br />
	21. இதினிமித்தமே யூதர்கள் தேவாலயத்திலே என்னைப் பிடித்துக் கொலைசெய்யப் பிரயத்தனம்பண்ணினார்கள்.
	<br /><br />
	22. ஆனாலும் தேவ அநுக்கிரகம் பெற்று, நான் இந்நாள்வரைக்கும் சிறியோருக்கும் பெரியோருக்கும் சாட்சிகூறி வருகிறேன்.
	<br /><br />
	23. தீர்க்கதரிசிகளும் மோசேயும் முன்னமே சொல்லியிருந்தபடியே, கிறிஸ்து பாடுபடவேண்டியதென்றும், மரித்தோர் உயிர்த்தெழுதலில் அவர் முதல்வராகி, சுய ஜனங்களுக்கும் அந்நிய ஜனங்களுக்கும் ஒளியை வெளிப்படுத்துகிறவரென்றும் சொல்லுகிறேனேயன்றி, வேறொன்றையும் நான் சொல்லுகிறதில்லை என்றான்.
	<br /><br />
	24. இவ்விதமாய் அவன் தனக்காக உத்தரவு சொல்லுகையில், பெஸ்து உரத்த சத்தமாய்: பவுலே, நீ பிதற்றுகிறாய், அதிகக் கல்வி உனக்குப் பயித்தியமுண்டாக்குகிறது என்றான்.
	<br /><br />
	25. அதற்கு அவன்: கனம்பொருந்திய பெஸ்துவே, நான் பயித்தியக்காரனல்ல, சத்தியமும் சொஸ்தபுத்தியுமுள்ள வார்த்தைகளைப் பேசுகிறேன்.
	<br /><br />
	26. இந்தச் சங்கதிகளை ராஜா அறிந்திருக்கிறார்; ஆகையால் தைரியமாய் அவருக்கு முன்பாகப் பேசுகிறேன்; இவைகளில் ஒன்றும் அவருக்கு மறைவானதல்லவென்று எண்ணுகிறேன்; இது ஒரு மூலையிலே நடந்த காரியமல்ல.
	<br /><br />
	27. அகிரிப்பா ராஜாவே, தீர்க்கதரிசிகளை விசுவாசிக்கிறீரா? விசுவாசிக்கிறீர் என்று அறிவேன் என்றான்.
	<br /><br />
	28. அப்பொழுது அகிரிப்பா பவுலை நோக்கி: நான் கிறிஸ்தவனாகிறதற்குக் கொஞ்சங்குறைய நீ என்னைச் சம்மதிக்கப்பண்ணுகிறாய் என்றான்.
	<br /><br />
	29. அதற்குப் பவுல்: நீர் மாத்திரமல்ல, இன்று என் வசனத்தைக் கேட்கிற யாவரும், கொஞ்சங்குறையமாத்திரம் அல்ல, இந்தக் கட்டுகள் தவிர, முழுவதும் என்னைப்போலாகும்படி தேவனை வேண்டிக்கொள்ளுகிறேன் என்றான்.
	<br /><br />
	30. இவைகளை அவன் சொன்னபோது, ராஜாவும் தேசாதிபதியும் பெர்னீக்கேயாளும் அவர்களுடனேகூட உட்கார்ந்திருந்தவர்களும் எழுந்து,
	<br /><br />
	31. தனியே போய்: இந்த மனுஷன் மரணத்துக்காவது கட்டுகளுக்காவது பாத்திரமானதொன்றையும் செய்யவில்லையென்று தங்களுக்குள்ளே பேசிக்கொண்டார்கள்.
	<br /><br />
	32. அகிரிப்பா பெஸ்துவை நோக்கி: இந்த மனுஷன் இராயனுக்கு அபயமிடாதிருந்தானானால், இவனை விடுதலைபண்ணலாகும் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts26