import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 10 </title>
	<meta
          name="description"
          content="Jeremiah 10 | எரேமியா 10 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேல் வீட்டாரே, கர்த்தர் உங்களுக்குச் சொல்லுகிற வசனத்தைக் கேளுங்கள்:
	<br /><br />
	2. புறஜாதிகளுடைய மார்க்கத்தைக் கற்றுக்கொள்ளாதிருங்கள்; வானத்தின் அடையாளங்களாலே புறஜாதிகள் கலங்குகிறார்களே என்று சொல்லி, நீங்கள் அவைகளாலே கலங்காதிருங்கள்.
	<br /><br />
	3. ஜனங்களின் வழிபாடுகள் வீணாயிருக்கிறது; காட்டில் ஒரு மரத்தை வெட்டுகிறார்கள்; அது தச்சன் கையாடுகிற வாச்சியால் பணிப்படும்.
	<br /><br />
	4. வெள்ளியினாலும் பொன்னினாலும் அதை அலங்கரித்து, அது அசையாதபடிக்கு அதை ஆணிகளாலும் சுத்திகளாலும் உறுதியாக்குகிறார்கள்.
	<br /><br />
	5. அவைகள் பனையைப்போல நெட்டையாய் நிற்கிறது, அவைகள் பேசமாட்டாதவைகள், அவைகள் நடக்கமாட்டாததினால் சுமக்கப்படவேண்டும்; அவைகளுக்குப் பயப்படவேண்டாம்; அவைகள் தீமைசெய்யக்கூடாது, நன்மைசெய்யவும் அவைகளுக்குச் சக்தி இல்லையென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. கர்த்தாவே, உமக்கு ஒப்பானவன் இல்லை; நீரே பெரியவர்; உமது நாமமே வல்லமையில் பெரியது.
	<br /><br />
	7. ஜாதிகளின் ராஜாவே, உமக்குப் பயப்படாதிருப்பவன் யார்? தேவரீருக்கே பயப்படவேண்டியது; ஜாதிகளுடைய எல்லா ஞானிகளிலும், அவர்களுடைய எல்லா ராஜ்யத்திலும் உமக்கு ஒப்பானவன் இல்லை.
	<br /><br />
	8. அவர்கள் யாவரும் மிருககுணமும் மதியீனமுமுள்ளவர்கள்; அந்தக் கட்டை மாயையான போதகமாயிருக்கிறது.
	<br /><br />
	9. தகடாக்கப்பட்ட வெள்ளி தர்ஷீசிலும், பொன் ஊப்பாசிலுமிருந்து கொண்டுவரப்பட்டு, அவைகள் தொழிலாளியினாலும், தட்டான் கைகளினாலும் செய்யப்படுகிறது; இளநீலமும் இரத்தாம்பரமும் அவைகளின் உடுமானம்; அவைகளெல்லாம் தொழிலாளிகளின் கைவேலையாயிருக்கிறது.
	<br /><br />
	10. கர்த்தரோ மெய்யான தெய்வம்; அவர் ஜீவனுள்ள தேவன், நித்திய ராஜா; அவருடைய கோபத்தினால் பூமி அதிரும்; அவருடைய உக்கிரத்தை ஜாதிகள் சகிக்கமாட்டார்கள்.
	<br /><br />
	11. வானத்தையும் பூமியையும் உண்டாக்காத தெய்வங்கள், பூமியிலும் இந்த வானத்தின்கீழும் இராதபடிக்கு அழிந்துபோகும் என்பதை அவர்களுக்குச் சொல்லுங்கள்.
	<br /><br />
	12. அவரே பூமியைத் தம்முடைய வல்லமையினால் உண்டாக்கி, பூச்சக்கரத்தைத் தம்முடைய ஞானத்தினால் படைத்து, வானத்தைத் தம்முடைய அறிவினால் விரித்தார்.
	<br /><br />
	13. அவர் சத்தமிடுகையில் வானத்திலே திரளான தண்ணீர் உண்டாகிறது; அவர் பூமியின் கடையாந்தரத்திலிருந்து மேகங்களை எழும்பப்பண்ணி, மழையுடனே மின்னல்களை உண்டாக்கி, காற்றைத் தமது பண்டகசாலைகளிலிருந்து புறப்படப்பண்ணுகிறார்.
	<br /><br />
	14. மனுஷர் அனைவரும் அறிவில்லாமல் மிருககுணமுள்ளவர்களானார்கள்; தட்டார் அனைவரும் வார்ப்பித்த சுரூபங்களாலே வெட்கிப்போகிறார்கள்; அவர்கள் வார்ப்பித்த விக்கிரகம் பொய்யே, அவைகளில் ஆவி இல்லை.
	<br /><br />
	15. அவைகள் மாயையும், மகா எத்தான கிரியையுமாயிருக்கிறது; அவைகள் விசாரிக்கப்படும் நாளிலே அழியும்.
	<br /><br />
	16. யாக்கோபின் பங்காயிருக்கிறவர் அவைகளைப்போல் அல்ல, அவர் சர்வத்தையும் உருவாக்கினவர்; இஸ்ரவேல் அவருடைய சுதந்தரமான கோத்திரம்; சேனைகளின் கர்த்தர் என்பது அவருடைய நாமம்.
	<br /><br />
	17. அரணில் குடியிருக்கிறவளே, தேசத்திலிருந்து உன் பொருள்களைச் சேர்த்துக்கொள்.
	<br /><br />
	18. இதோ, நான் இந்த முறை தேசத்தின் குடிகளைக் கவண்கொண்டெறிந்து, அவர்கள் கண்டு உணரும்படி அவர்களுக்கு நெருக்கமுண்டாக்குவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	19. ஐயோ! நான் நொறுக்கப்பட்டேன்; என் காயம் கொடிதாயிருக்கிறது; ஆனாலும் இது நான் சகிக்கவேண்டிய என் நோய் என்று நான் சொல்லுவேன்.
	<br /><br />
	20. என் கூடாரம் அழிந்துபோயிற்று, என் கயிறுகளெல்லாம் அறுப்புண்டுபோயின; என் பிள்ளைகள் என்னைவிட்டுப் போய்விட்டார்கள்; அவர்களில் ஒருவனுமில்லை; இனி என் கூடாரத்தை விரித்து, என் திரைகளைத் தூக்கிக் கட்டுவாரில்லை.
	<br /><br />
	21. மேய்ப்பர்கள் மிருககுணமுள்ளவர்களாகி, கர்த்தரைத் தேடாமற் போனார்கள்; ஆகையால், அவர்கள் காரியம் வாய்க்காமற்போய், அவர்கள் மந்தையெல்லாம் சிதறடிக்கப்பட்டது.
	<br /><br />
	22. இதோ, யூதாவின் பட்டணங்களைப் பாழும் வலுசர்ப்பங்களின் தாவுமாக்கிப்போடும் செய்தியின் சத்தமும், வடதேசத்திலிருந்து பெரிய கொந்தளிப்பும் வருகிறது.
	<br /><br />
	23. கர்த்தாவே, மனுஷனுடைய வழி அவனாலே ஆகிறதல்லவென்றும், தன் நடைகளை நடத்துவது நடக்கிறவனாலே ஆகிறதல்லவென்றும் அறிவேன்.
	<br /><br />
	24. கர்த்தாவே, என்னைத் தண்டியும்; ஆனாலும் நான் அவமாய்ப்போகாதபடிக்கு உம்முடைய கோபத்தினாலே அல்ல, மட்டாய்த் தண்டியும்.
	<br /><br />
	25. உம்மை அறியாத ஜாதிகளின்மேலும், உமது நாமத்தைத் தொழுதுகொள்ளாத வம்சங்களின்மேலும், உம்முடைய உக்கிரத்தை ஊற்றிவிடும்; அவர்கள் யாக்கோபைப் பட்சித்து, அவனை விழுங்கி, அவனை நிர்மூலமாக்கி, அவன் வாசஸ்தலத்தைப் பாழாக்கினார்களே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah10