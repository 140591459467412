import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 5 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 5 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 5 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பூமிக்குரிய கூடாரமாகிய நம்முடைய வீடு அழிந்துபோனாலும், தேவனால் கட்டப்பட்ட கைவேலையல்லாத நித்திய வீடு பரலோகத்திலே நமக்கு உண்டென்று அறிந்திருக்கிறோம்.
	<br /><br />
	2. ஏனெனில், இந்தக் கூடாரத்திலே நாம் தவித்து, நம்முடைய பரம வாசஸ்தலத்தைத் தரித்துக்கொள்ள மிகவும் வாஞ்சையுள்ளவர்களாயிருக்கிறோம்;
	<br /><br />
	3. தரித்துக்கொண்டவர்களானால், நிர்வாணிகளாய்க் காணப்படமாட்டோம்.
	<br /><br />
	4. இந்தக் கூடாரத்திலிருக்கிற நாம் பாரஞ்சுமந்து தவிக்கிறோம்; இந்தப் போர்வையைக் களைந்துபோடவேண்டுமென்று விரும்பாமல், மரணமானது ஜீவனாலே விழுங்கப்படுவதற்காகப் போர்வை தரித்தவர்களாயிருக்கவேண்டுமென்று விரும்புகிறோம்.
	<br /><br />
	5. இதற்கு நம்மை ஆயத்தப்படுத்துகிறவர் தேவனே; ஆவியென்னும் அச்சாரத்தை நமக்குத் தந்தவரும் அவரே.
	<br /><br />
	6. நாம் தரிசித்து நடவாமல், விசுவாசித்து நடக்கிறோம்.
	<br /><br />
	7. இந்தத் தேகத்தில் குடியிருக்கையில் கர்த்தரிடத்தில் குடியிராதவர்களாயிருக்கிறோமென்று அறிந்தும், எப்பொழுதும் தைரியமாயிருக்கிறோம்.
	<br /><br />
	8. நாம் தைரியமாகவேயிருந்து, இந்தத் தேகத்தை விட்டுக் குடிபோகவும் கர்த்தரிடத்தில் குடியிருக்கவும் அதிகமாய் விரும்புகிறோம்.
	<br /><br />
	9. அதினிமித்தமே நாம் சரீரத்தில் குடியிருந்தாலும் குடியிராமற்போனாலும் அவருக்குப் பிரியமானவர்களாயிருக்க நாடுகிறோம்.
	<br /><br />
	10. ஏனென்றால், சரீரத்தில் அவனவன் செய்த நன்மைக்காவது தீமைக்காவது தக்க பலனை அடையும்படிக்கு, நாமெல்லாரும் கிறிஸ்துவின் நியாயாசனத்திற்கு முன்பாக வெளிப்படவேண்டும்.
	<br /><br />
	11. ஆகையால் கர்த்தருக்குப் பயப்படத்தக்கதென்று அறிந்து, மனுஷருக்குப் புத்திசொல்லுகிறோம்; தேவனுக்கு முன்பாக வெளியரங்கமாயிருக்கிறோம்; உங்கள் மனச்சாட்சிக்கும் வெளியரங்கமாயிருக்கிறோம் என்று நம்புகிறேன்.
	<br /><br />
	12. இதனாலே நாங்கள் உங்களுக்கு முன்பாக எங்களை மறுபடியும் மெச்சிக்கொள்ளாமல், இருதயத்திலல்ல, வெளிவேஷத்தில் மேன்மைபாராட்டுகிறவர்களுக்கு எதிரே எங்களைக்குறித்து நீங்கள் மேன்மைபாராட்டும்படிக்கு ஏதுவுண்டாக்குகிறோம்.
	<br /><br />
	13. நாங்கள் பைத்தியங்கொண்டவர்களென்றால் தேவனுக்காக அப்படியிருக்கும்; தெளிந்தபுத்தியுள்ளவர்களென்றால் உங்களுக்காக அப்படியிருக்கும்.
	<br /><br />
	14. கிறிஸ்துவினுடைய அன்பு எங்களை நெருக்கி ஏவுகிறது; ஏனென்றால், எல்லாருக்காகவும் ஒருவரே மரித்திருக்க, எல்லாரும் மரித்தார்கள் என்றும்;
	<br /><br />
	15. பிழைத்திருக்கிறவர்கள் இனித் தங்களுக்கென்று பிழைத்திராமல், தங்களுக்காக மரித்து எழுந்தவருக்கென்று பிழைத்திருக்கும்படி, அவர் எல்லாருக்காகவும் மரித்தாரென்றும் நிதானிக்கிறோம்.
	<br /><br />
	16. ஆகையால், இதுமுதற்கொண்டு, நாங்கள் ஒருவனையும் மாம்சத்தின்படி அறியோம்; நாங்கள் கிறிஸ்துவையும் மாம்சத்தின்படி அறிந்திருந்தாலும், இனி ஒருபோதும் அவரை மாம்சத்தின்படி அறியோம்.
	<br /><br />
	17. இப்படியிருக்க, ஒருவன் கிறிஸ்துவுக்குள்ளிருந்தால் புதுச்சிருஷ்டியாயிருக்கிறான்; பழையவைகள் ஒழிந்துபோயின, எல்லாம் புதிதாயின.
	<br /><br />
	18. இவையெல்லாம் தேவனாலே உண்டாயிருக்கிறது; அவர் இயேசுகிறிஸ்துவைக்கொண்டு நம்மைத் தம்மோடே ஒப்புரவாக்கி, ஒப்புரவாக்குதலின் ஊழியத்தை எங்களுக்கு ஒப்புக்கொடுத்தார்.
	<br /><br />
	19. அதென்னவெனில், தேவன் உலகத்தாருடைய பாவங்களை எண்ணாமல், கிறிஸ்துவுக்குள் அவர்களைத் தமக்கு ஒப்புரவாக்கி, ஒப்புரவாக்குதலின் உபதேசத்தை எங்களிடத்தில் ஒப்புவித்தார்.
	<br /><br />
	20. ஆனபடியினாலே, தேவனானவர் எங்களைக்கொண்டு புத்திசொல்லுகிறதுபோல, நாங்கள் கிறிஸ்துவுக்காக ஸ்தானாபதிகளாயிருந்து, தேவனோடே ஒப்புரவாகுங்கள் என்று, கிறிஸ்துவினிமித்தம் உங்களை வேண்டிக்கொள்ளுகிறோம்.
	<br /><br />
	21. நாம் அவருக்குள் தேவனுடைய நீதியாகும்படிக்கு, பாவம் அறியாத அவரை நமக்காகப் பாவமாக்கினார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians5