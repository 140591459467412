import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Nahum3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | நாகூம் 3 </title>
	<meta
          name="description"
          content="Nahum 3 | நாகூம் 3 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இரத்தப்பழிகளின் நகரத்திற்கு ஐயோ! அது வஞ்சகத்தினாலும் கொடுமையினாலும் நிறைந்திருக்கிறது; கொள்ளை ஓயாமல் நடக்கிறது.
	<br /><br />
	2. சவுக்குகளின் ஓசையும், உருளைகளின் அதிர்ச்சியும், குதிரைகளின் பாய்ச்சலும், இரதங்கள் கடகடவென்று ஓடுகிற சத்தமும்,
	<br /><br />
	3. வீரர் குதிரை ஏறுகிறதும், பட்டயங்கள் துலங்குகிறதும், ஈட்டிகள் மின்னுகிறதும், வெட்டுண்டவர்களின் திரளும், பிரேதங்களின் ஏராளமும் அங்கே உண்டாயிருக்கும்; பிணங்களுக்குத் தொகையில்லை; அவர்கள் பிணங்களில் இடறிவிழுகிறார்கள்.
	<br /><br />
	4. தன் வேசித்தனங்களினால் ஜாதிகளையும், தன் சூனியங்களினால் வம்சங்களையும் விற்கிற மகா சூனியக்காரியும் ரூபவதியுமாயிருக்கிற வேசியினுடைய திரளான வேசித்தனங்களினிமித்தம்,
	<br /><br />
	5. இதோ, நான் உனக்கு விரோதமாக வந்து உன் வஸ்திரத்து ஓரங்களை உன் முகமட்டும் தூக்கியெடுத்து, ஜாதிகளுக்கு உன் நிர்வாணத்தையும், ராஜ்யங்களுக்கு உன் மானத்தையும் தெரியப்பண்ணி,
	<br /><br />
	6. உன்மேல் தீட்டானவைகளை எறிந்து, உன்னைக் கனவீனப்படுத்தி, உன்னை வேடிக்கையாக்கிப்போடுவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	7. அப்பொழுது உன்னைக் காண்கிறவனெல்லாம் நினிவே பாழாய்ப்போயிற்று, அதற்காகப் புலம்புகிறவர் யார்? ஆறுதல் சொல்லுகிறவர்களை உனக்கு எங்கே தேடுவேனென்று சொல்லி, உன்னைவிட்டோடிப் போவான்.
	<br /><br />
	8. நதிகள் மத்தியிலிருந்த நோ அம்மோனைப்பார்க்கிலும் நீ சிரேஷ்டமோ? அதைச் சுற்றிலும் தண்ணீர் இருந்தது; சமுத்திரம் அதின் அரணும், சமுத்திரக்கால் அதின் மதிலுமாயிருந்தது.
	<br /><br />
	9. எத்தியோப்பியாவும் எகிப்தும் எண்ணிறந்த சேனையால் அதற்குப் பெலனாக இருந்தது; பூத்தும் லூபீமும் அதற்குச் சகாயமாயிருந்தது.
	<br /><br />
	10. ஆயினும் அவள் குடிவிலக்கப்பட்டுச் சிறையிருப்பிலே கொண்டுபோகப்பட்டாள்; அவள் குழந்தைகள் எல்லா வீதிகளின் முகனைகளிலும் மோதியடிக்கப்பட்டார்கள்; அவளுடைய கனவான்கள்பேரில் சீட்டுப் போட்டார்கள்; அவளுடைய பெரியவர்கள் எல்லாரும் சங்கிலிகளால் கட்டப்பட்டார்கள்.
	<br /><br />
	11. நீயும் வெறிகொண்டு ஒளித்துக்கொள்வாய்; நீயும் உன் சத்துருவுக்குத் தப்ப அரணான கோட்டையைத் தேடுவாய்.
	<br /><br />
	12. உன் அரண்களெல்லாம் முதல் பழுக்கும் பழங்களுள்ள அத்திமரங்களைப்போல் இருக்கும்; அவைகள் குலுக்கப்பட்டால் அவைகளின் பழம் தின்கிறவன் வாயிலே விழும்.
	<br /><br />
	13. இதோ, உன் நடுவில் இருக்கிற ஜனங்கள் பேடிகள்; உன் தேசத்தின் வாசல்கள் உன் சத்துருவுக்குமுன் திறவுண்டுபோகும்; அக்கினி உன் தாழ்ப்பாள்களைப் பட்சிக்கும்.
	<br /><br />
	14. முற்றிகைக்குத் தண்ணீர் மொண்டு வை, உன் அரண்களைப் பலப்படுத்து; சேற்றிலே போய்க் களிமண்ணை மிதி, சூளையைக் கெட்டிப்படுத்து.
	<br /><br />
	15. அங்கே அக்கினி உன்னைப் பட்சிக்கும், பட்டயம் உன்னைச் சங்கரிக்கும்; அது பச்சைக்கிளிகளைப்போல் உன்னைப் பட்சித்துப்போடும்; உன்னைப் பச்சைக்கிளிகளத்தனையாக்கிக்கொள், உன்னை வெட்டுக்கிளிகளத்தனையாக்கிக்கொள்.
	<br /><br />
	16. உன் வர்த்தகரை வானத்து நட்சத்திரங்களிலும் அதிகமாக்கினாய்; இந்தப் பச்சைக்கிளிகள் பரவிப் பறந்துபோகும்.
	<br /><br />
	17. உன் மகுடவர்த்தனர் வெட்டுக்கிளிகளுக்கும், உன் தளகர்த்தர் பெருங்கிளிகளுக்கும் சமானமாயிருக்கிறார்கள்; அவைகள் குளிர்ச்சியான நாளில் வேலிகளில் பாளையமிறங்கி, சூரியன் உதித்தமாத்திரத்தில் பறந்துபோம்; பின்பு அவைகள் இருக்கும் இடம் இன்னதென்று தெரியாது.
	<br /><br />
	18. அசீரியா ராஜாவே, உன் மேய்ப்பர்கள் உறங்குவார்கள்; உன் பிரபலஸ்தர் படுத்திருப்பார்கள்; உன் ஜனங்கள் பர்வதங்களின்மேல் சிதறியிருக்கிறார்கள், அவைகளைக் கூட்டிச் சேர்ப்பவன் இல்லை.
	<br /><br />
	19. உன் நொறுங்குதலுக்குப் பரிகாரம் இல்லை; உன் காயம் கொடியது; உன் செய்தியைக் கேட்பவர் யாவரும் உன்பேரில் கைகொட்டுவார்கள்; உன் பொல்லாப்பு எந்நேரமும் யார்பேரிலேதான் பாயாமற்போயிற்று?
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Nahum3