import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hebrews10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title> Christ Sacrifice for All Hebrews Tamil Bible Verse  எபிரெயர் 10 </title>
	<meta
          name="description"
          content="Christ's Sacrifice Once for All Hebrews Chapter-10 in Tamil Bible Verse | எபிரெயர் அதிகாரம்-10 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* அனைவருக்கும் ஒருமுறை கிறிஸ்துவின் தியாகம் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. இப்படியிருக்க, நியாயப்பிரமாணமானது வரப்போகிற நன்மைகளின் பொருளாயிராமல், அவைகளின் நிழலாய்மாத்திரம் இருக்கிறபடியால், வருஷந்தோறும் இடைவிடாமல் செலுத்தப்பட்டுவருகிற ஒரேவிதமான பலிகளினாலே அவைகளைச் செலுத்த வருகிறவர்களை ஒருக்காலும் பூரணப்படுத்தமாட்டாது.
	<br /><br />
	2. பூரணப்படுத்துமானால், ஆராதனை செய்கிறவர்கள் ஒருதரம் சுத்தமாக்கப்பட்டபின்பு, இன்னும் பாவங்களுண்டென்று உணர்த்தும் மனச்சாட்சி அவர்களுக்கு இல்லாதிருப்பதினால், அந்தப் பலிகளைச் செலுத்துகிறது நிறுத்தப்படுமல்லவா?
	<br /><br />
	3. அப்படி நிறுத்தப்படாதபடியால், பாவங்கள் உண்டென்று அவைகளினாலே வருஷந்தோறும் நினைவுகூருதல் உண்டாயிருக்கிறது.
	<br /><br />
	4. அல்லாமலும், காளை வெள்ளாட்டுக்கடா இவைகளுடைய இரத்தம் பாவங்களை நிவிர்த்திசெய்யமாட்டாதே.
	<br /><br />
	5. ஆகையால் அவர் உலகத்தில் பிரவேசிக்கும்போது: பலியையும் காணிக்கையையும் நீர் விரும்பவில்லை, ஒரு சரீரத்தை எனக்கு ஆயத்தம்பண்ணினீர்;
	<br /><br />
	6. சர்வாங்க தகனபலிகளும், பாவநிவாரணபலிகளும் உமக்குப் பிரியமானதல்ல என்றீர்.
	<br /><br />
	7. அப்பொழுது நான்: தேவனே, உம்முடைய சித்தத்தின்படி செய்ய, இதோ, வருகிறேன், புஸ்தகச்சுருளில் என்னைக்குறித்து எழுதியிருக்கிறது என்று சொன்னேன் என்றார்.
	<br /><br />
	8. நியாயப்பிரமாணத்தின்படி செலுத்தப்பட்டுவருகிற பலிகளைக்குறித்து மேற்சொல்லியபடி: பலியையும், காணிக்கையையும், சர்வாங்க தகனபலிகளையும், பாவநிவாரணபலிகளையும் நீர் விரும்பவில்லை, அவைகள் உமக்குப் பிரியமானதல்ல என்று சொன்னபின்பு:
	<br /><br />
	9. தேவனே, உம்முடைய சித்தத்தின்படி செய்ய, இதோ, வருகிறேன் என்று சொன்னார். இரண்டாவதை நிலைநிறுத்துவதற்கு முதலாவதை நீக்கிப்போடுகிறார்.
	<br /><br />
	10. இயேசுகிறிஸ்துவினுடைய சரீரம் ஒரேதரம் பலியிடப்பட்டதினாலே, அந்தச் சித்தத்தின்படி நாம் பரிசுத்தமாக்கப்பட்டிருக்கிறோம்.
	<br /><br />
	11. அன்றியும், எந்த ஆசாரியனும் நாடோறும் ஆராதனை செய்கிறவனாயும், பாவங்களை ஒருக்காலும் நிவிர்த்திசெய்யக்கூடாத ஒரேவித பலிகளை அநேகந்தரம் செலுத்திவருகிறவனாயும் நிற்பான்.
	<br /><br />
	12. இவரோ, பாவங்களுக்காக ஒரே பலியைச் செலுத்தி, என்றென்றைக்கும் தேவனுடைய வலதுபாரிசத்தில் உட்கார்ந்து,
	<br /><br />
	13. இனித் தம்முடைய சத்துருக்களைத் தமது பாதபடியாக்கிப் போடும்வரைக்கும் காத்துக்கொண்டிருக்கிறார்.
	<br /><br />
	14. ஏனெனில் பரிசுத்தமாக்கப்படுகிறவர்களை ஒரே பலியினாலே இவர் என்றென்றைக்கும் பூரணப்படுத்தியிருக்கிறார்.
	<br /><br />
	15. இதைக்குறித்துப் பரிசுத்த ஆவியானவரும் நமக்குச் சாட்சிசொல்லுகிறார்; எப்படியெனில்:
	<br /><br />
	16. அந்த நாட்களுக்குப்பின்பு நான் அவர்களோடே பண்ணும் உடன்படிக்கையாவது: நான் என்னுடைய பிரமாணங்களை அவர்களுடைய இருதயங்களில் வைத்து, அவைகளை அவர்களுடைய மனதில் எழுதுவேன் என்று கர்த்தர் சொல்லுகிறாரென்பதை உரைத்தபின்பு,
	<br /><br />
	17. அவர்களுடைய பாவங்களையும் அவர்களுடைய அக்கிரமங்களையும் நான் இனி நினைப்பதில்லை என்பதைச் சொல்லுகிறார்.
	<br /><br />
	18. இவைகள் மன்னிக்கப்பட்டதுண்டானால், இனிப் பாவத்தினிமித்தம் பலி செலுத்தப்படுவதில்லையே.
	<br /><br />
	19. ஆகையால், சகோதரரே, நாம் பரிசுத்தஸ்தலத்தில் பிரவேசிப்பதற்கு இயேசுவானவர் தமது மாம்சமாகிய திரையின் வழியாய்ப் புதிதும் ஜீவனுமான மார்க்கத்தை நமக்கு உண்டுபண்ணினபடியால்,
	<br /><br />
	20. அந்த மார்க்கத்தின்வழியாய்ப் பிரவேசிப்பதற்கு அவருடைய இரத்தத்தினாலே நமக்குத் தைரியம் உண்டாயிருக்கிறபடியினாலும்,
	<br /><br />
	21. தேவனுடைய வீட்டின்மேல் அதிகாரியான மகா ஆசாரியர் நமக்கு ஒருவர் இருக்கிறபடியினாலும்,
	<br /><br />
	22. துர்மனச்சாட்சி நீங்கத் தெளிக்கப்பட்ட இருதயமுள்ளவர்களாயும், சுத்த ஜலத்தால் கழுவப்பட்ட சரீரமுள்ளவர்களாயும், உண்மையுள்ள இருதயத்தோடும் விசுவாசத்தின் பூரண நிச்சயத்தோடும் சேரக்கடவோம்.
	<br /><br />
	23. அல்லாமலும், நம்முடைய நம்பிக்கையை அறிக்கையிடுகிறதில் அசைவில்லாமல் உறுதியாயிருக்கக்கடவோம்; வாக்குத்தத்தம்பண்ணினவர் உண்மையுள்ளவராயிருக்கிறாரே.
	<br /><br />
	24. மேலும், அன்புக்கும் நற்கிரியைகளுக்கும் நாம் ஏவப்படும்படி ஒருவரையொருவர் கவனித்து;
	<br /><br />
	25. சபை கூடிவருதலைச் சிலர் விட்டுவிடுகிறதுபோல நாமும் விட்டுவிடாமல், ஒருவருக்கொருவர் புத்திசொல்லக்கடவோம்; நாளானது சமீபித்துவருகிறதை எவ்வளவாய்ப் பார்க்கிறீர்களோ அவ்வளவாய்ப் புத்திசொல்லவேண்டும்.
	<br /><br />
	26. சத்தியத்தை அறியும் அறிவை அடைந்தபின்பு நாம் மனப்பூர்வமாய்ப் பாவஞ்செய்கிறவர்களாயிருந்தால், பாவங்களினிமித்தம் செலுத்தத்தக்க வேறொருபலி இனியிராமல்,
	<br /><br />
	27. நியாயத்தீர்ப்பு வருமென்று பயத்தோடே எதிர்பார்க்குதலும், விரோதிகளைப் பட்சிக்கும் கோபாக்கினையுமே இருக்கும்.
	<br /><br />
	28. மோசேயினுடைய பிரமாணத்தைத் தள்ளுகிறவன் இரக்கம்பெறாமல் இரண்டு மூன்று சாட்சிகளின் வாக்கினாலே சாகிறானே;
	<br /><br />
	29. தேவனுடைய குமாரனைக் காலின்கீழ் மிதித்து, தன்னைப் பரிசுத்தஞ்செய்த உடன்படிக்கையின் இரத்தத்தை அசுத்தமென்றெண்ணி, கிருபையின் ஆவியை நிந்திக்கிறவன் எவ்வளவு கொடிதான ஆக்கினைக்குப் பாத்திரவானாயிருப்பான் என்பதை யோசித்துப்பாருங்கள்.
	<br /><br />
	30. பழிவாங்குதல் எனக்குரியது, நானே பதிற்செய்வேன் என்று கர்த்தர் சொல்லுகிறார் என்றும், கர்த்தர் தம்முடைய ஜனங்களை நியாயந்தீர்ப்பார் என்றும் சொன்னவர் இன்னாரென்று அறிவோம்.
	<br /><br />
	31. ஜீவனுள்ள தேவனுடைய கைகளில் விழுகிறது பயங்கரமாயிருக்குமே.
	<br /><br />
	32. முந்தின நாட்களை நினைத்துக்கொள்ளுங்கள்; நீங்கள் பிரகாசமாக்கப்பட்டிருந்த அந்த நாட்களில் உபத்திரவங்களாகிய மிகுந்த போராட்டத்தைச் சகித்தீர்களே.
	<br /><br />
	33. நிந்தைகளாலும் உபத்திரவங்களாலும் நீங்கள் வேடிக்கையாக்கப்பட்டதுமல்லாமல், அப்படி நடத்தப்பட்டவர்களுக்குப் பங்காளிகளுமானீர்கள்.
	<br /><br />
	34. நான் கட்டப்பட்டிருக்கையில் நீங்கள் என்னைக்குறித்துப் பரிதபித்ததுமன்றி, பரலோகத்தில் அதிக மேன்மையும் நிலையுள்ளதுமான சுதந்தரம் உங்களுக்கு உண்டென்று அறிந்து, உங்கள் ஆஸ்திகளையும் சந்தோஷமாய்க் கொள்ளையிடக்கொடுத்தீர்கள்.
	<br /><br />
	35. ஆகையால், மிகுந்த பலனுக்கேதுவான உங்கள் தைரியத்தை விட்டுவிடாதிருங்கள்.
	<br /><br />
	36. நீங்கள் தேவனுடைய சித்தத்தின்படி செய்து, வாக்குத்தத்தம்பண்ணப்பட்டதைப் பெறும்படிக்குப் பொறுமை உங்களுக்கு வேண்டியதாயிருக்கிறது.
	<br /><br />
	37. வருகிறவர் இன்னுங் கொஞ்சக்காலத்தில் வருவார், தாமதம்பண்ணார்.
	<br /><br />
	38. விசுவாசத்தினாலே நீதிமான் பிழைப்பான், பின்வாங்கிப்போவானானால் அவன்மேல் என் ஆத்துமா பிரியமாயிராது என்கிறார்.
	<br /><br />
	39. நாமோ கெட்டுப்போகப் பின்வாங்குகிறவர்களாயிராமல், ஆத்துமா ஈடேற விசுவாசிக்கிறவர்களாயிருக்கிறோம்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h3>
* எபிரெயர் 10, நியாயப்பிரமாணம் நெருங்கி வருபவர்களை ஒருபோதும் பரிபூரணமாக்க முடியவில்லை, ஏனென்றால் அது வரவிருக்கும் நல்ல விஷயங்களின் நிழலாக இருந்தது, அது ஒரு நிஜம் அல்ல. இல்லையெனில் பலி கொடுப்பது நின்று போயிருக்கும். மாறாக அவை பாவத்தின் வருடாந்திர நினைவூட்டலாக செயல்பட்டன. ஆனால் இயேசு வந்ததும் பாவத்திற்காக ஒரே பலியைச் செலுத்தி, பிதாவின் வலது பாரிசத்தில் அமர்ந்தார். இப்போது நாம் கிறிஸ்துவின் உடலின் திரை மூலம் கடவுளிடம் நெருங்கி வரலாம், நம் இதயங்கள் தெளிக்கப்படுகின்றன, நம் மனசாட்சி தெளிவாக உள்ளது. *
			</h3>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Hebrews10