import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-4 </title>
	<meta
          name="description"
          content="Ecclesiastes 4 | பிரசங்கி 4 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதற்குப்பின்பு நான் சூரியனுக்குக்கீழே செய்யப்படும் கொடுமைகளையெல்லாம் சிந்தித்துப்பார்த்தேன்; இதோ, ஒடுக்கப்பட்டவர்களின் கண்ணீரைக் கண்டேன், அவர்களைத் தேற்றுவாரில்லை; ஒடுக்குகிறவர்கள் பட்சத்தில் பெலம் இருந்தது, அப்படியிருந்தும் தேற்றுவாரில்லை.
	<br /><br />
	2. ஆதலால் இன்னும் உயிரோடிருந்து பிழைக்கிறவர்களைப்பார்க்கிலும் முன்னமே காலஞ்சென்று மரித்தவர்களையே பாக்கியவான்கள் என்றேன்.
	<br /><br />
	3. இவ்விருதிறத்தாருடைய நிலைமையைப்பார்க்கிலும் இன்னும் பிறவாதவனுடைய நிலைமையே வாசி; அவன் சூரியனுக்குக் கீழே செய்யப்படும் துர்ச்செய்கைகளைக் காணவில்லையே.
	<br /><br />
	4. மனுஷன் படும் எல்லாப் பிரயாசமும், பயன்படும் எல்லாக் கிரியையும், அயலானுடைய பொறாமைக்கு ஏதுவாயிருக்கிறதை நான் கண்டேன்; இதுவும் மாயையும், மனதுக்குச் சஞ்சலமுமாயிருக்கிறது.
	<br /><br />
	5. மூடன் தன் கைகளைக் கட்டிக்கொண்டு, தன் சதையையே தின்கிறான்.
	<br /><br />
	6. வருத்தத்தோடும் மனச்சஞ்சலத்தோடும் இரண்டு கைப்பிடியும் நிறையக்கொண்டிருப்பதைப்பார்க்கிலும், அமைச்சலோடு ஒரு கைப்பிடி நிறையக் கொண்டிருப்பதே நலம்.
	<br /><br />
	7. பின்பு நான் திரும்பிக்கொண்டு சூரியனுக்குக் கீழே மாயையான வேறொரு காரியத்தைக் கண்டேன்.
	<br /><br />
	8. ஒருவன் ஒண்டிக்காரனாயிருக்கிறான்; அவனுக்கு உடனாளியுமில்லை, அவனுக்குப் பிள்ளையும் சகோதரனுமில்லை; அப்படியிருந்தும் அவன் படும் பிரயாசத்துக்கு முடிவில்லை; அவன் கண் ஐசுவரியத்தால் திருப்தியாகிறதுமில்லை; நான் ஒரு நன்மையையும் அநுபவியாமல் யாருக்காகப் பிரயாசப்படுகிறேன் என்று அவன் சிந்திக்கிறதுமில்லை; இதுவும் மாயை, தீராத தொல்லை.
	<br /><br />
	9. ஒண்டியாயிருப்பதிலும் இருவர் கூடியிருப்பது நலம்; அவர்களுடைய பிரயாசத்தினால் அவர்களுக்கு நல்ல பலனுண்டாகும்.
	<br /><br />
	10. ஒருவன் விழுந்தால் அவன் உடனாளி அவனைத் தூக்கிவிடுவான்; ஒண்டியாயிருந்து விழுகிறவனுக்கு ஐயோ, அவனைத் தூக்கிவிடத் துணையில்லையே.
	<br /><br />
	11. இரண்டுபேராய்ப் படுத்துக்கொண்டிருந்தால் அவர்களுக்குச் சூடுண்டாகும்; ஒண்டியாயிருக்கிறவனுக்குச் சூடுண்டாவது எப்படி?
	<br /><br />
	12. ஒருவனை யாதாமொருவன் மேற்கொள்ள வந்தால் இருவரும் அவனுக்கு எதிர்த்துநிற்கலாம்; முப்புரிநூல் சீக்கிரமாய் அறாது.
	<br /><br />
	13. இனி ஆலோசனையைக் கேளாத கிழவனும் மூடனுமாகிய ராஜாவைப்பார்க்கிலும், ஏழையும் ஞானியுமாகிய இளைஞனே வாசி.
	<br /><br />
	14. அரசாளச் சிறைச்சாலையிலிருந்து புறப்படுவாருமுண்டு; ராஜாங்கத்தில் பிறந்து ஏழையாவாருமுண்டு.
	<br /><br />
	15. சூரியனுக்குக்கீழே ஜீவனுள்ளோர் யாவரும் ராஜாவின் பட்டத்திற்கு வரப்போகிற பிள்ளையின் பட்சத்தில் சார்ந்திருப்பதைக் கண்டேன்.
	<br /><br />
	16. அவர்களுக்குமுன் அப்படிச் செய்த ஜனங்களின் இலக்கத்திற்கு முடிவில்லை; இனி இருப்பவர்கள் இவன்மேலும் பிரியம் வைக்காமற்போவார்கள்; இதுவும் மாயையும், மனதுக்குச் சஞ்சலமுமாயிருக்கிறது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes4