import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 9 </title>
	<meta
          name="description"
          content="Hosea 9 | ஓசியா 9 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேலே, மகிழ்ச்சியாயிராதே; மற்ற ஜனங்களைப்போல் களிகூராதே; உன் தேவனைவிட்டு நீ சோரம்போனாய்; தானியம் போரடிக்கிற சகல களங்களிலும் பணையத்தை நாடுகிறாய்.
	<br /><br />
	2. தானியக்களமும் திராட்சத்தொட்டியும் அவர்களைப் பிழைப்பூட்டுவதில்லை; அவர்களுக்குத் திராட்சரசம் ஒழிந்துபோகும்.
	<br /><br />
	3. அவர்கள் கர்த்தருடைய தேசத்தில் குடியிருப்பதில்லை; எப்பிராயீமர் திரும்ப எகிப்துக்குப் போவார்கள்; அசீரியாவில் தீட்டுள்ளதைப் புசிப்பார்கள்.
	<br /><br />
	4. அவர்கள் கர்த்தருக்குத் திராட்சரசத்தின் பானபலியை வார்ப்பதுமில்லை, அவருக்கு அங்கிகரிப்பாயிருப்பதுமில்லை; அவர்களுடைய பலிகள் அவர்களுக்குத் துக்கங்கொண்டாடுகிறவர்களின் அப்பத்தைபோல இருக்கும்; அதைப் புசிக்கிற யாவரும் தீட்டுப்படுவார்கள்; அவர்களுடைய அப்பம் அவர்களுக்கேயாகும், அது கர்த்தருடைய ஆலயத்தில் வருவதில்லை.
	<br /><br />
	5. ஆசரிப்புநாளிலும், கர்த்தருடைய பண்டிகைநாளிலும் என்னசெய்வீர்கள்?
	<br /><br />
	6. இதோ, அவர்கள் பாழ்க்கடிப்புக்குத் தப்பும்படி போய்விட்டார்கள்; எகிப்து அவர்களைச் சேர்த்துக்கொள்ளும், மோப் பட்டணம் அவர்களை அடக்கம்பண்ணும்; அவர்களுடைய வெள்ளியிருந்த விருப்பமான இடங்கள் காஞ்சொறிகளுக்குச் சுதந்தரமாகும்; அவர்களுடைய வாசஸ்தலங்களில் முட்செடிகள் முளைக்கும்.
	<br /><br />
	7. விசாரிப்பின் நாட்கள் வரும், நீதி சரிக்கட்டும் நாட்கள் வரும் என்பதை இஸ்ரவேலர் அறிந்துகொள்வார்கள்; உன் மிகுதியான அக்கிரமத்தினாலேயும், மிகுதியான பகையினாலேயும் தீர்க்கதரிசிகள் மூடரும், ஆவியைப் பெற்ற மனுஷர்கள் பித்தங்கொண்டவர்களுமாயிருக்கிறார்கள்.
	<br /><br />
	8. எப்பிராயீமின் காவற்காரர் என் தேவனோடு எதிர்த்துநிற்கிறார்கள்; தீர்க்கதரிசி தன் வழிகளிலெல்லாம் குருவிபிடிக்கிறவனுடைய கண்ணியாகவும், தன் தேவனுடைய ஆலயத்திலே பகையாளியாகவும் இருக்கிறான்.
	<br /><br />
	9. கிபியாவின் நாட்களில் நடந்ததுபோல, அவர்கள் தங்களை மிகவும் கெடுத்துக்கொண்டார்கள்; அவர்களுடைய அக்கிரமத்தை அவர் நினைப்பார், அவர்களுடைய பாவங்களை விசாரிப்பார்.
	<br /><br />
	10. வனாந்தரத்தில் திராட்சக்குலைகளைக் கண்டுபிடிப்பதுபோல இஸ்ரவேலைக் கண்டுபிடித்தேன்; அத்திமரத்தில் முதல்தரம் பழுத்த கனிகளைப்போல உங்கள் பிதாக்களைக் கண்டுபிடித்தேன்; ஆனாலும் அவர்கள் பாகால்பேயோர் அண்டைக்குப்போய், இலச்சையானதற்குத் தங்களை ஒப்புவித்து, தாங்கள் நேசித்தவைகளைப் போலத் தாங்களும் அருவருப்புள்ளவர்களானார்கள்.
	<br /><br />
	11. எப்பிராயீமின் மகிமை ஒரு பறவையைப்போல் பறந்துபோம்; அது பிறப்பதுமில்லை, வயிற்றிலிருப்பதுமில்லை, கர்ப்பந்தரிப்பதுமில்லை.
	<br /><br />
	12. அவர்கள் தங்கள் பிள்ளைகளை வளர்த்தாலும், அவர்களுக்கு மனுஷர் இராதபடிக்கு அவர்களைப் பிள்ளைகள் அற்றவர்களாக்குவேன்; நான் அவர்களை விட்டுப்போகையில் அவர்களுக்கு ஐயோ!
	<br /><br />
	13. நான் எப்பிராயீமைத் தீருவின் திசைமட்டும் இருக்கிறதைப் பார்க்கிறபோது, அது நல்ல வசதியான ஸ்தலத்திலே நாட்டப்பட்டிருக்கிறது; ஆனாலும் எப்பிராயீமர் தங்கள் குமாரரைக் கொலைசெய்கிறவனிடத்தில் வெளியே கொண்டுபோய் விடுவார்கள்.
	<br /><br />
	14. கர்த்தாவே, நீர் அவர்களுக்குக் கொடுப்பதைக் கொடும்; அவர்களுக்கு விழுந்துபோகிற கர்ப்பத்தையும் பாலற்ற முலைகளையும் கொடும்.
	<br /><br />
	15. அவர்களுடைய பொல்லாப்பெல்லாம் கில்காலிலே நடக்கும்; அங்கே நான் அவர்களை வெறுத்தேன்; அவர்களுடைய பொல்லாப்பினிமித்தம் அவர்களை நான் என் சமுகத்தைவிட்டுத் துரத்துவேன்; இனி அவர்களை நேசிக்கமாட்டேன்; அவர்களுடைய அதிபதிகள் எல்லாரும் துரோகிகள்.
	<br /><br />
	16. எப்பிராயீமர் வெட்டுண்டுபோனார்கள்; அவர்கள் வேர் உலர்ந்துபோயிற்று, கனிகொடுக்கமாட்டார்கள்; அவர்கள் பிள்ளைகளைப் பெற்றாலும், அவர்களுடைய கர்ப்பத்தின் பிரியமான கனிகளை அதம்பண்ணுவேன்.
	<br /><br />
	17. அவர்கள் அவருக்குச் செவிகொடாமற்போனபடியால் என் தேவன் அவர்களை வெறுத்துவிடுவார்; அவர்கள் அந்நியஜாதிகளுக்குள்ளே அலைந்து திரிவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea9