import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 11 </title>
	<meta
          name="description"
          content="Mark 11 | மாற்கு 11 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர்கள் எருசலேமுக்குச் சமீபமாய்ச் சேர்ந்து, ஒலிவமலைக்கு அருகான பெத்பகே பெத்தானியா என்னும் ஊர்களுக்கு வந்தபோது, அவர் தம்முடைய சீஷரில் இரண்டுபேரை நோக்கி:
	<br /><br />
	2. உங்களுக்கு எதிரேயிருக்கிற கிராமத்துக்குப் போங்கள்; அதில் பிரவேசித்தவுடனே, மனுஷர் ஒருவரும் ஒருக்காலும் ஏறியிராத ஒரு கழுதைக்குட்டி கட்டியிருக்கக் காண்பீர்கள், அதை அவிழ்த்துக் கொண்டு வாருங்கள்.
	<br /><br />
	3. ஏன் இப்படிச் செய்கிறீர்கள் என்று ஒருவன் உங்களிடத்தில் கேட்டால்: இது ஆண்டவருக்கு வேண்டுமென்று சொல்லுங்கள்; உடனே அதை இவ்விடத்திற்கு அனுப்பிவிடுவான் என்று சொல்லி, அவர்களை அனுப்பினார்.
	<br /><br />
	4. அவர்கள் போய், வெளியே இருவழிச்சந்தியில் ஒரு வாசலருகே கட்டியிருந்த அந்தக் குட்டியைக் கண்டு, அதை அவிழ்த்தார்கள்.
	<br /><br />
	5. அப்பொழுது அங்கே நின்றவர்களில் சிலர்: நீங்கள் குட்டியை அவிழ்க்கிறது என்னவென்றார்கள்.
	<br /><br />
	6. இயேசு கற்பித்தபடியே அவர்களுக்கு உத்தரவு சொன்னார்கள். அப்பொழுது, அவர்களைப் போகவிட்டார்கள்.
	<br /><br />
	7. அவர்கள் அந்தக் குட்டியை இயேசுவினிடத்தில் கொண்டுவந்து, அதின்மேல் தங்கள் வஸ்திரங்களைப் போட்டார்கள்; அவர் அதின்மேல் ஏறிப்போனார்.
	<br /><br />
	8. அநேகர் தங்கள் வஸ்திரங்களை வழியிலே விரித்தார்கள்; வேறு சிலர் மரக்கிளைகளைத் தறித்து வழியிலே பரப்பினார்கள்.
	<br /><br />
	9. முன்நடப்பாரும் பின்நடப்பாரும்: ஓசன்னா! கர்த்தரின் நாமத்தினாலே வருகிறவர் ஸ்தோத்திரிக்கப்பட்டவர்;
	<br /><br />
	10. கர்த்தரின் நாமத்தினாலே வருகிற நம்முடைய பிதாவாகிய தாவீதின் ராஜ்யம் ஆசீர்வதிக்கப்படுவதாக; உன்னதத்திலே ஓசன்னா என்று சொல்லி ஆர்ப்பரித்தார்கள்.
	<br /><br />
	11. அப்பொழுது, இயேசு எருசலேமுக்கு வந்து, தேவாலயத்தில் பிரவேசித்து, எல்லாவற்றையும் சுற்றிப்பார்த்து, சாயங்காலமானபோது, பன்னிருவரோடுங்கூடப் பெத்தானியாவுக்குப் போனார்.
	<br /><br />
	12. மறுநாளிலே அவர்கள் பெத்தானியாவிலிருந்து புறப்பட்டுவருகையில், அவருக்குப் பசியுண்டாயிற்று.
	<br /><br />
	13. அப்பொழுது இலைகளுள்ள ஒரு அத்திமரத்தைத் தூரத்திலே கண்டு, அதில் ஏதாகிலும் அகப்படுமோ என்று பார்க்கவந்தார். அத்திப்பழக் காலமாயிராதபடியால், அவர் அதினிடத்தில் வந்தபோது அதில் இலைகளையல்லாமல், வேறொன்றையும் காணவில்லை.
	<br /><br />
	14. அப்பொழுது இயேசு அதைப் பார்த்து: இதுமுதல் ஒருக்காலும் ஒருவனும் உன்னிடத்தில் கனியைப் புசியாதிருக்கக்கடவன் என்றார்; அதை அவருடைய சீஷர்கள் கேட்டார்கள்.
	<br /><br />
	15. அவர்கள் எருசலேமுக்கு வந்தார்கள். இயேசு தேவாலயத்தில் பிரவேசித்து, ஆலயத்தில் விற்கிறவர்களையும் கொள்ளுகிறவர்களையும் துரத்திவிட்டு, காசுக்காரருடைய பலகைகளையும், புறா விற்கிறவர்களுடைய ஆசனங்களையும் கவிழ்த்து,
	<br /><br />
	16. ஒருவனும் தேவாலயத்தின் வழியாக யாதொரு பண்டத்தையும் கொண்டுபோகவிடாமல்:
	<br /><br />
	17. என்னுடைய வீடு எல்லா ஜனங்களுக்கும் ஜெபவீடு என்னப்படும் என்று எழுதியிருக்கவில்லையா? நீங்களோ அதைக் கள்ளர் குகையாக்கினீர்கள் என்று அவர்களுக்குச் சொல்லி உபதேசித்தார்.
	<br /><br />
	18. அதை வேதபாரகரும் பிரதான ஆசாரியரும் கேட்டு, அவரைக் கொலைசெய்ய வகைதேடினார்கள்; ஆகிலும் ஜனங்களெல்லாரும் அவருடைய உபதேசத்தைக்குறித்து ஆச்சரியப்பட்டபடியினாலே அவருக்குப் பயந்திருந்தார்கள்.
	<br /><br />
	19. சாயங்காலமானபோது அவர் நகரத்திலிருந்து புறப்பட்டுப்போனார்.
	<br /><br />
	20. மறுநாள் காலையிலே அவர்கள் அவ்வழியாய்ப் போகும்போது, அந்த அத்திமரம் வேரோடே பட்டுப்போயிருக்கிறதைக் கண்டார்கள்.
	<br /><br />
	21. பேதுரு நினைவுகூர்ந்து, அவரை நோக்கி: ரபீ, இதோ, நீர் சபித்த அத்திமரம் பட்டுப்போயிற்று என்றான்.
	<br /><br />
	22. இயேசு அவர்களை நோக்கி: தேவனிடத்தில் விசுவாசமுள்ளவர்களாயிருங்கள்.
	<br /><br />
	23. எவனாகிலும் இந்த மலையைப் பார்த்து: நீ பெயர்ந்து, சமுத்திரத்திலே தள்ளுண்டுபோ என்று சொல்லி, தான் சொன்னபடியே நடக்கும் என்று தன் இருதயத்தில் சந்தேகப்படாமல் விசுவாசித்தால், அவன் சொன்னபடியே ஆகும் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	24. ஆதலால், நீங்கள் ஜெபம்பண்ணும்போது எவைகளைக் கேட்டுக்கொள்வீர்களோ, அவைகளைப் பெற்றுக்கொள்வோம் என்று விசுவாசியுங்கள், அப்பொழுது அவைகள் உங்களுக்கு உண்டாகும் என்று சொல்லுகிறேன்.
	<br /><br />
	25. நீங்கள் நின்று ஜெபம்பண்ணும்போது, ஒருவன்பேரில் உங்களுக்கு யாதொரு குறை உண்டாயிருக்குமானால், பரலோகத்திலிருக்கிற உங்கள் பிதா உங்கள் தப்பிதங்களை உங்களுக்கு மன்னிக்கும்படி, அந்தக் குறையை அவனுக்கு மன்னியுங்கள்.
	<br /><br />
	26. நீங்கள் மன்னியாதிருப்பீர்களானால், பரலோகத்திலிருக்கிற உங்கள் பிதாவும் உங்கள் தப்பிதங்களை மன்னியாதிருப்பார் என்றார்.
	<br /><br />
	27. அவர்கள் மறுபடியும் எருசலேமுக்கு வந்தார்கள். அவர் தேவாலயத்திலே உலாவிக்கொண்டிருக்கையில், பிரதான ஆசாரியரும் வேதபாரகரும் மூப்பரும் அவரிடத்தில் வந்து:
	<br /><br />
	28. நீர் எந்த அதிகாரத்தினால் இவைகளைச் செய்கிறீர்? இவைகளைச் செய்கிறதற்கு அதிகாரத்தை உமக்குக் கொடுத்தவர் யார் என்று கேட்டார்கள்.
	<br /><br />
	29. இயேசு பிரதியுத்தரமாக: நானும் உங்களிடத்தில் ஒரு காரியத்தைக் கேட்கிறேன், நீங்கள் எனக்கு உத்தரவு சொல்லுங்கள், அப்பொழுது நானும் இன்ன அதிகாரத்தினாலே இவைகளைச் செய்கிறேனென்று உங்களுக்குச் சொல்லுவேன்.
	<br /><br />
	30. யோவான் கொடுத்த ஸ்நானம் தேவனால் உண்டாயிற்றோ, மனுஷரால் உண்டாயிற்றோ, எனக்கு உத்தரவு சொல்லுங்கள் என்றார்.
	<br /><br />
	31. அதற்கு அவர்கள்: தேவனால் உண்டாயிற்றென்று சொல்வோமானால், பின்னை ஏன் அவனை விசுவாசிக்கவில்லையென்று கேட்பார்.
	<br /><br />
	32. மனுஷரால் உண்டாயிற்றென்று சொல்வோமானால், ஜனங்களுக்குப் பயப்படவேண்டியதாயிருக்கும்; எல்லாரும் யோவானை மெய்யாகத் தீர்க்கதரிசியென்று எண்ணுகிறார்களே என்று தங்களுக்குள்ளே ஆலோசனைபண்ணி;
	<br /><br />
	33. இயேசுவுக்குப் பிரதியுத்தரமாக: எங்களுக்குத் தெரியாது என்றார்கள். அப்பொழுது இயேசு: நானும் இன்ன அதிகாரத்தினாலே இவைகளைச் செய்கிறேன் என்று உங்களுக்குச் சொல்லேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark11