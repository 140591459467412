import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark9 = () => {
  const verseNumber = 9;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 9 </title>
	<meta
          name="description"
          content="Mark 9 | மாற்கு 9 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அன்றியும், அவர் அவர்களை நோக்கி: இங்கே நிற்கிறவர்களில் சிலர் தேவனுடைய ராஜ்யம் பலத்தோடே வருவதைக் காணுமுன், மரணத்தை ருசிபார்ப்பதில்லையென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	2. ஆறுநாளைக்குப் பின்பு, இயேசு பேதுருவையும் யாக்கோபையும் யோவானையும் அழைத்து, உயர்ந்த மலையின்மேல் அவர்களைத் தனியே கூட்டிக்கொண்டுபோய், அவர்களுக்கு முன்பாக மறுரூபமானார்.
	<br /><br />
	3. அவருடைய வஸ்திரம் உறைந்த மழையைப்போல் பூமியிலே எந்த வண்ணானும் வெளுக்கக்கூடாத வெண்மையாய்ப் பிரகாசித்தது.
	<br /><br />
	4. அப்பொழுது மோசேயும் எலியாவும் இயேசுவுடனே பேசுகிறவர்களாக அவர்களுக்குக் காணப்பட்டார்கள்.
	<br /><br />
	5. அப்பொழுது பேதுரு இயேசுவை நோக்கி: ரபீ, நாம் இங்கே இருக்கிறது நல்லது; உமக்கு ஒரு கூடாரமும், மோசேக்கு ஒரு கூடாரமும், எலியாவுக்கு ஒரு கூடாரமுமாக, மூன்று கூடாரங்களைப் போடுவோம் என்றான்.
	<br /><br />
	6. அவர்கள் மிகவும் பயந்திருந்தபடியால், தான் பேசுகிறது இன்னதென்று அறியாமல் இப்படிச் சொன்னான்.
	<br /><br />
	7. அப்பொழுது, ஒரு மேகம் அவர்கள்மேல் நிழலிட்டது: இவர் என்னுடைய நேசகுமாரன், இவருக்குச் செவிகொடுங்கள் என்று அந்த மேகத்திலிருந்து ஒரு சத்தம் உண்டாயிற்று.
	<br /><br />
	8. உடனே அவர்கள் சுற்றிலும் பார்த்தபோது, இயேசு ஒருவரைத்தவிர வேறொருவரையும் காணவில்லை.
	<br /><br />
	9. அவர்கள் மலையிலிருந்து இறங்குகிறபோது, அவர் அவர்களை நோக்கி: மனுஷகுமாரன் மரித்தோரிலிருந்து எழுந்திருக்கும்வரைக்கும், நீங்கள் கண்டவைகளை ஒருவருக்கும் சொல்லவேண்டாம் என்று கட்டளையிட்டார்.
	<br /><br />
	10. மரித்தோரிலிருந்து எழுந்திருப்பது என்னவென்று அவர்கள் ஒருவரிடத்தில் ஒருவர் விசாரித்து, அந்த வார்த்தையைத் தங்களுக்குள்ளே அடக்கிக்கொண்டு:
	<br /><br />
	11. எலியா முந்தி வரவேண்டுமென்று வேதபாரகர் சொல்லுகிறார்களே, அதெப்படியென்று அவரிடத்தில் கேட்டார்கள்.
	<br /><br />
	12. அவர் பிரதியுத்தரமாக: எலியா முந்திவந்து எல்லாவற்றையும் சீர்ப்படுத்துவது மெய்தான்; அல்லாமலும், மனுஷகுமாரன் பல பாடுகள்பட்டு, அவமதிக்கப்படுவாரென்று, அவரைக்குறித்து எழுதியிருக்கிறதே அது எப்படி என்றார்.
	<br /><br />
	13. ஆனாலும் எலியா வந்தாயிற்று, அவனைக்குறித்து எழுதியிருக்கிற பிரகாரம் தங்களுக்கு இஷ்டமானபடி அவனுக்குச் செய்தார்களென்று, உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	14. பின்பு அவர் சீஷரிடத்தில் வந்தபோது, அவர்களைச் சுற்றித் திரளான ஜனங்கள் நிற்கிறதையும், அவர்களோடே வேதபாரகர் தர்க்கிக்கிறதையும் கண்டார்.
	<br /><br />
	15. ஜனங்களெல்லாரும் அவரைக் கண்டவுடனே மிகவும் ஆச்சரியப்பட்டு, ஓடிவந்து, அவருக்கு வந்தனஞ்செய்தார்கள்.
	<br /><br />
	16. அவர் வேதபாரகரை நோக்கி: நீங்கள் இவர்களோடே என்னத்தைக் குறித்துத் தர்க்கம்பண்ணுகிறீர்கள் என்று கேட்டார்.
	<br /><br />
	17. அப்பொழுது ஜனக்கூட்டத்தில் ஒருவன் அவரை நோக்கி: போதகரே, ஊமையான ஒரு ஆவி பிடித்த என் மகனை உம்மிடத்தில் கொண்டுவந்தேன்.
	<br /><br />
	18. அது அவனை எங்கே பிடித்தாலும் அங்கே அவனை அலைக்கழிக்கிறது; அப்பொழுது அவன் நுரைதள்ளி, பல்லைக்கடித்து, சோர்ந்துபோகிறான். அதைத் துரத்திவிடும்படி உம்முடைய சீஷரிடத்தில் கேட்டேன்; அவர்களால் கூடாமற்போயிற்று என்றான்.
	<br /><br />
	19. அவர் பிரதியுத்தரமாக: விசுவாசமில்லாத சந்ததியே, எதுவரைக்கும் நான் உங்களோடு இருப்பேன்? எதுவரைக்கும் உங்களிடத்தில் பொறுமையாய் இருப்பேன்? அவனை என்னிடத்தில் கொண்டுவாருங்கள் என்றார்.
	<br /><br />
	20. அவனை அவரிடத்தில் கொண்டுவந்தார்கள். அவரைக் கண்டவுடனே, அந்த ஆவி அவனை அலைக்கழித்தது; அவன் தரையிலே விழுந்து, நுரைதள்ளிப் புரண்டான்.
	<br /><br />
	21. அவர் அவனுடைய தகப்பனை நோக்கி: இது இவனுக்கு உண்டாகி எவ்வளவு காலமாயிற்று என்று கேட்டார். அதற்கு அவன்: சிறுவயதுமுதற்கொண்டே உண்டாயிருக்கிறது;
	<br /><br />
	22. இவனைக் கொல்லும்படிக்கு அது அநேகந்தரம் தீயிலும் தண்ணீரிலும் தள்ளிற்று. நீர் ஏதாகிலும் செய்யக்கூடுமானால், எங்கள்மேல் மனதிரங்கி, எங்களுக்கு உதவிசெய்யவேண்டும் என்றான்.
	<br /><br />
	23. இயேசு அவனை நோக்கி: நீ விசுவாசிக்கக்கூடுமானால் ஆகும், விசுவாசிக்கிறவனுக்கு எல்லாம் கூடும் என்றார்.
	<br /><br />
	24. உடனே பிள்ளையின் தகப்பன்: விசுவாசிக்கிறேன் ஆண்டவரே, என் அவிசுவாசம் நீங்கும்படி உதவிசெய்யும் என்று கண்ணீரோடே சத்தமிட்டுச் சொன்னான்.
	<br /><br />
	25. அப்பொழுது ஜனங்கள் கூட்டமாய் ஓடிவருகிறதை இயேசு கண்டு, அந்த அசுத்த ஆவியை நோக்கி: ஊமையும் செவிடுமான ஆவியே, இவனை விட்டுப் புறப்பட்டுப்போ, இனி இவனுக்குள் போகாதே என்று நான் உனக்குக் கட்டளையிடுகிறேன் என்று அதை அதட்டினார்.
	<br /><br />
	26. அப்பொழுது அது சத்தமிட்டு, அவனை மிகவும் அலைக்கழித்துப் புறப்பட்டுப்போயிற்று. அவன் செத்துப்போனான் என்று அநேகர் சொல்லத்தக்கதாகச் செத்தவன்போல் கிடந்தான்.
	<br /><br />
	27. இயேசு அவன் கையைப்பிடித்து, அவனைத் தூக்கினார்; உடனே அவன் எழுந்திருந்தான்.
	<br /><br />
	28. வீட்டில் அவர் பிரவேசித்தபொழுது, அவருடைய சீஷர்கள்: அதைத் துரத்திவிட எங்களால் ஏன் கூடாமற்போயிற்று என்று அவரிடத்தில் தனித்துக் கேட்டார்கள்.
	<br /><br />
	29. அதற்கு அவர்: இவ்வகைப் பிசாசு ஜெபத்தினாலும் உபவாசத்தினாலுமேயன்றி மற்றெவ்விதத்தினாலும் புறப்பட்டுப்போகாது என்றார்.
	<br /><br />
	30. பின்பு அவ்விடம் விட்டுப் புறப்பட்டு, கலிலேயாவைக் கடந்துபோனார்கள்; அதை ஒருவரும் அறியாதிருக்க வேண்டுமென்று விரும்பினார்.
	<br /><br />
	31. ஏனெனில், மனுஷகுமாரன் மனுஷர் கைகளில் ஒப்புக்கொடுக்கப்படுவார் என்றும், அவர்கள் அவரைக் கொன்றுபோடுவார்கள் என்றும்; கொல்லப்பட்டு, மூன்றாம் நாளிலே உயிர்த்தெழுந்திருப்பார் என்றும் அவர் தம்முடைய சீஷர்களுக்குப் போதகம்பண்ணிச் சொல்லியிருந்தார்.
	<br /><br />
	32. அவர்களோ அந்த வார்த்தையை அறிந்துகொள்ளவில்லை, அதைக்குறித்து அவரிடத்தில் கேட்கவும் பயந்தார்கள்.
	<br /><br />
	33. அவர் கப்பர்நகூமுக்கு வந்து, வீட்டிலே இருக்கும்போது, அவர்களை நோக்கி: நீங்கள் வழியிலே எதைக்குறித்து உங்களுக்குள்ளே தர்க்கம்பண்ணினீர்கள் என்று கேட்டார்.
	<br /><br />
	34. அதற்கு அவர்கள் பேசாமல் இருந்தார்கள்; ஏனெனில் அவர்கள் தங்களுக்குள்ளே எவன் பெரியவன் என்று வழியில் தர்க்கம்பண்ணினார்கள்.
	<br /><br />
	35. அப்பொழுது அவர் உட்கார்ந்து, பன்னிருவரையும் அழைத்து: எவனாகிலும் முதல்வனாயிருக்க விரும்பினால் அவன் எல்லாருக்கும் கடையானவனும், எல்லாருக்கும் ஊழியக்காரனுமாயிருக்கக்கடவன் என்று சொல்லி;
	<br /><br />
	36. ஒரு சிறு பிள்ளையை எடுத்து, அதை அவர்கள் நடுவிலே நிறுத்தி, அதை அணைத்துக்கொண்டு:
	<br /><br />
	37. இப்படிப்பட்ட சிறு பிள்ளைகளில் ஒன்றை என் நாமத்தினாலே ஏற்றுக்கொள்ளுகிறவன் என்னை ஏற்றுக்கொள்ளுகிறான்; என்னை ஏற்றுக்கொள்ளுகிறவன் என்னை அல்ல, என்னை அனுப்பினவரை ஏற்றுக்கொள்ளுகிறான் என்றார்.
	<br /><br />
	38. அப்பொழுது யோவான் அவரை நோக்கி: போதகரே, நம்மைப் பின்பற்றாதவன் ஒருவன் உமது நாமத்தினாலே பிசாசுகளைத் துரத்துகிறதைக் கண்டோம்; அவன் நம்மைப் பின்பற்றாதவனானதால், அவனைத் தடுத்தோம் என்றான்.
	<br /><br />
	39. அதற்கு இயேசு: அவனைத் தடுக்கவேண்டாம்; என் நாமத்தினாலே அற்புதஞ் செய்கிறவன் எளிதாய் என்னைக்குறித்துத் தீங்கு சொல்லமாட்டான்.
	<br /><br />
	40. நமக்கு விரோதமாயிராதவன் நமது பட்சத்திலிருக்கிறான்.
	<br /><br />
	41. நீங்கள் கிறிஸ்துவினுடையவர்களாயிருக்கிறபடியினாலே, என் நாமத்தினிமித்தம் உங்களுக்கு ஒரு கலசம் தண்ணீர் குடிக்கக் கொடுக்கிறவன் தன் பலனை அடையாமற்போவதில்லை என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	42. என்னிடத்தில் விசுவாசமாயிருக்கிற இந்தச் சிறியரில் ஒருவனுக்கு இடறல் உண்டாக்குகிறவன் எவனோ, அவனுடைய கழுத்தில் ஏந்திரக்கல்லைக் கட்டி, சமுத்திரத்தில் அவனைத் தள்ளிப்போடுகிறது அவனுக்கு நலமாயிருக்கும்.
	<br /><br />
	43. உன் கை உனக்கு இடறல் உண்டாக்கினால், அதைத் தறித்துப்போடு; நீ இரண்டு கையுடையவனாய் அவியாத அக்கினியுள்ள நரகத்திலே போவதைப்பார்க்கிலும், ஊனனாய் ஜீவனுக்குள் பிரவேசிப்பது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	44. அங்கே அவர்கள் புழு சாவாமலும் அக்கினி அவியாமலுமிருக்கும்.
	<br /><br />
	45. உன் கால் உனக்கு இடறல் உண்டாக்கினால் அதைத் தறித்துப்போடு; நீ இரண்டு காலுடையவனாய் அவியாத அக்கினியுள்ள நரகத்திலே தள்ளப்படுவதைப்பார்க்கிலும், சப்பாணியாய் ஜீவனுக்குள் பிரவேசிப்பது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	46. அங்கே அவர்கள் புழு சாவாமலும் அக்கினி அவியாமலுமிருக்கும்.
	<br /><br />
	47. உன் கண் உனக்கு இடறல் உண்டாக்கினால், அதைப் பிடுங்கிப்போடு; நீ இரண்டு கண்ணுடையவனாய் நரக அக்கினியிலே தள்ளப்படுவதைப்பார்க்கிலும், ஒற்றைக் கண்ணனாய் தேவனுடைய ராஜ்யத்தில் பிரவேசிப்பது உனக்கு நலமாயிருக்கும்.
	<br /><br />
	48. அங்கே அவர்கள் புழு சாவாமலும் அக்கினி அவியாமலுமிருக்கும்.
	<br /><br />
	49. எந்தப் பலியும் உப்பினால் உப்பிடப்படுவதுபோல, எந்த மனுஷனும் அக்கினியினால் உப்பிடப்படுவான்.
	<br /><br />
	50. உப்பு நல்லதுதான், உப்பு சாரமற்றுப்போனால், அதற்கு எதினாலே சாரமுண்டாக்குவீர்கள்? உங்களுக்குள்ளே உப்புடையவர்களாயிருங்கள், ஒருவரோடொருவர் சமாதானமுள்ளவர்களாயும் இருங்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark9