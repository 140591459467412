import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah27 = () => {
  const verseNumber = 27;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 27 </title>
	<meta
          name="description"
          content="Isaiah 27 | ஏசாயா 27 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்திலே கர்த்தர் லிவியாதான் என்னும் நீண்ட பாம்பை, லிவியாதான் என்னும் கோணலான சர்ப்பத்தையே, கடிதும் பெரிதும் பலத்ததுமான தமது பட்டயத்தால் தண்டிப்பார்; சமுத்திரத்தில் இருக்கிற வலுசர்ப்பத்தைக் கொன்றுபோடுவார்.
	<br /><br />
	2. அக்காலத்திலே நல்ல திராட்சரசத்தைத் தரும் திராட்சத்தோட்டம் உண்டாயிருக்கும்; அதைக்குறித்துப் பாடுங்கள்.
	<br /><br />
	3. கர்த்தராகிய நான் அதைக் காப்பாற்றி, அடிக்கடி அதற்குத் தண்ணீர்ப்பாய்ச்சி, ஒருவரும் அதைச் சேதப்படுத்தாதபடிக்கு அதை இரவும்பகலும் காத்துக்கொள்வேன்.
	<br /><br />
	4. உக்கிரம் என்னிடத்தில் இல்லை; முட்செடியையும் நெரிஞ்சிலையும் எனக்கு விரோதமாய் யுத்தத்தில் கொண்டுவருகிறவன் யார்? நான் அவைகள்மேல் வந்து, அவைகளை ஏகமாய்க் கொளுத்திவிடுவேன்;
	<br /><br />
	5. இல்லாவிட்டால் அவன் என் பெலனைப் பற்றிக்கொண்டு என்னோடே ஒப்புரவாகட்டும்; அவன் என்னோடே ஒப்புரவாவான்.
	<br /><br />
	6. யாக்கோபு வேர்பற்றி, இஸ்ரவேல் பூத்துக்காய்த்து உலகத்தைப் பலனால் நிரப்பும் நாட்கள் வரும்.
	<br /><br />
	7. அவர் அவனை அடித்தவர்களை அடித்ததுபோல இவனை அடிக்கிறாரோ? அவர்கள் கொல்லப்படும் கொலையாய் இவன் கொல்லப்படுகிறானோ?
	<br /><br />
	8. தேவரீர் ஜனத்தைத் துரத்திவிடுகையில் மட்டாய் அதனோடே வழக்காடுகிறீர்; கொண்டல்காற்றடிக்கிற நாளிலே அவர் தம்முடைய கடுங்காற்றினால் அதை விலக்கிவிடுகிறார்.
	<br /><br />
	9. ஆகையால், அதினால் யாக்கோபின் அக்கிரமம் நிக்கிரகம்பண்ணப்படும்; தோப்புவிக்கிரகங்களும் சிலைகளும் இனி நிற்காதபடி அவர்கள் பலிபீடங்களின் கல்லுகளையெல்லாம் நொறுக்கப்பட்ட சுண்ணாம்பு கல்லுகளாக்கிவிடும்போது, அவர்களுடைய பாவத்தை அவர் அகற்றுவாரென்பதே அதினால் உண்டாகும் பலன்.
	<br /><br />
	10. அரணான நகரம் அவாந்தரையாகும், அந்த வாசஸ்தலம் தள்ளுண்டு வனாந்தரத்தைப்போல விட்டுவிடப்பட்டதாயிருக்கும்; கன்றுக்குட்டிகள் அங்கே மேய்ந்து, அங்கே படுத்துக்கொண்டு, அதின் தழைகளைத் தின்னும்.
	<br /><br />
	11. அதின் கொப்புகள் உலரும்போது ஒடிந்துபோம்; ஸ்திரீகள் வந்து அவைகளைக் கொளுத்திவிடுவார்கள்; அது உணர்வுள்ள ஜனமல்ல; ஆகையால் அதை உண்டாக்கினவர் அதற்கு இரங்காமலும், அதை உருவாக்கினவர் அதற்குக் கிருபைசெய்யாமலும் இருப்பார்.
	<br /><br />
	12. அக்காலத்திலே, கர்த்தர் ஆற்றங்கரையின் விளைவுதொடங்கி எகிப்தின் நதிமட்டும் போரடிப்பார்; இஸ்ரவேல் புத்திரரே, நீங்கள் ஒவ்வொருவராய்ச் சேர்க்கப்படுவீர்கள்.
	<br /><br />
	13. அக்காலத்திலே பெரிய எக்காளம் ஊதப்படும்; அப்பொழுது, அசீரியா தேசத்திலே சிதறடிக்கப்பட்டவர்களும், எகிப்துதேசத்திலே துரத்திவிடப்பட்டவர்களும் வந்து, எருசலேமிலுள்ள பரிசுத்த பர்வதத்திலே கர்த்தரைப் பணிந்துகொள்ளுவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah27