import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 2 </title>
	<meta
          name="description"
          content="Ezekiel 2 | எசேக்கியேல்  2 |
          Ezekiel 2 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் என்னை நோக்கி: மனுபுத்திரனே, உன் காலூன்றி நில்; உன்னுடனே பேசுவேன் என்றார்.
	<br /><br />
	2. இப்படி அவர் என்னோடே பேசும்போது, ஆவி எனக்குள் வந்து, என்னைக் காலூன்றி நிற்கும்படி செய்தது; அப்பொழுது அவர் என்னுடனே பேசுகிறதைக் கேட்டேன்.
	<br /><br />
	3. அவர் என்னை நோக்கி: மனுபுத்திரனே, எனக்கு விரோதமாய் எழும்பின கலகக்கார ஜாதியாகிய இஸ்ரவேல் புத்திரரிடத்திற்கு நான் உன்னை அனுப்புகிறேன்; அவர்களும் அவர்கள் பிதாக்களும் இந்நாள்வரைக்கும் எனக்கு விரோதமாய்த் துரோகஞ்செய்தார்கள்.
	<br /><br />
	4. அவர்கள் கடினமுகமும் முரட்டாட்ட இருதயமுமுள்ள புத்திரர்; அவர்களிடத்திற்கு நான் உன்னை அனுப்புகிறேன்; கர்த்தராகிய ஆண்டவர் இன்னின்னதை உரைக்கிறார் என்று அவர்களோடே சொல்லு.
	<br /><br />
	5. கலகவீட்டாராகிய அவர்கள் கேட்டாலும் சரி, கேளாவிட்டாலும் சரி, தங்களுக்குள்ளே ஒரு தீர்க்கதரிசி உண்டென்கிறதை அவர்கள் அறியவேண்டும்.
	<br /><br />
	6. மனுபுத்திரனே, நீ அவர்களுக்குப் பயப்படவேண்டாம்; அவர்கள் வார்த்தைகளுக்கும் அஞ்சவேண்டாம்; நெரிஞ்சில்களுக்குள்ளும் முள்ளுகளுக்குள்ளும் நீ தங்கியிருந்தாலும், நீ தேள்களுக்குள் வாசம்பண்ணினாலும், நீ அவர்கள் வார்த்தைகளுக்குப் பயப்படாமலும் அவர்கள் முகத்துக்குக் கலங்காமலுமிரு; அவர்கள் கலகவீட்டார்.
	<br /><br />
	7. கலகக்காரராகிய அவர்கள் கேட்டாலும் சரி, கேளாவிட்டாலும் சரி, நீ என் வார்த்தைகளை அவர்களுக்குச் சொல்லு.
	<br /><br />
	8. மனுபுத்திரனே, நீ அந்தக் கலகவீட்டாரைப்போலக் கலகக்காரனாயிராமல், நான் உன்னோடே சொல்லுகிறதைக் கேள்; உன் வாயைத் திறந்து நான் உனக்குக் கொடுக்கிறதைப் புசி என்றார்.
	<br /><br />
	9. அப்பொழுது இதோ, என்னிடத்திற்கு நீட்டப்பட்ட ஒரு கையைக் கண்டேன்; அந்தக் கையிலே ஒரு புஸ்தகச்சுருள் இருந்தது.
	<br /><br />
	10. அவர் அதை எனக்குமுன்பாக விரித்தார்; அதில் உள்ளும் புறம்பும் எழுதப்பட்டிருந்தது; அதிலே புலம்பல்களும், தவிப்பும், ஐயோ என்பதும் எழுதியிருந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial2