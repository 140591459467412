import React from 'react';
import '../Bible.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

const NEWTESTAMENT = () => {
  return (
    <div>
    <h1 align="center">Summery of New Testament</h1>
    <div class="tes-glass-container">
        <div className='summery'>
            <h2>மத்தேயு</h2>
            <p>
            மத்தேயு தனது நற்செய்தியை ("நற்செய்தி") எழுதுவதன் முக்கிய நோக்கம், அவருடைய யூத வாசகர்களுக்கு இயேசு அவர்களின் மேசியா என்பதை நிரூபிப்பதாகும். இயேசு தனது வாழ்க்கையிலும் ஊழியத்திலும் பழைய ஏற்பாட்டு வேதாகமத்தை எவ்வாறு நிறைவேற்றினார் என்பதைக் காட்டுவதன் மூலம் அவர் முதன்மையாக இதைச் செய்கிறார்.
            </p>
            <Link to="/Matthew">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>மாற்கு</h2>
            <p>
            மார்க்கின் நற்செய்தி ("நற்செய்தி") பாரம்பரியமாக ரோமுடன் தொடர்புடையது என்பதால், இது ரோமானிய தேவாலயத்தின் துன்புறுத்தல்களால் கி.பி. கிபி 64-67. இப்படிப்பட்ட துன்பங்களுக்குத் தன் வாசகர்களைத் தயார்படுத்துவதற்காகவே, நம் ஆண்டவரின் வாழ்க்கையை அவர்களுக்கு முன் வைத்து மார்க் எழுதிக்கொண்டிருக்கலாம்.
            </p>
            <Link to="/Mark">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>லூக்கா</h2>
            <p>
            லூக்காவின் நற்செய்தி ("நற்செய்தி") அனைத்து விசுவாசிகளின் விசுவாசத்தையும் பலப்படுத்தவும், அவிசுவாசிகளின் தாக்குதல்களுக்கு பதிலளிக்கவும் எழுதப்பட்டது. இயேசுவைப் பற்றிய சில துண்டிக்கப்பட்ட மற்றும் தவறான ஆதாரமற்ற அறிக்கைகளை நீக்குவதற்காக இது வழங்கப்பட்டது. கடவுளின் ராஜ்யத்தில் புறஜாதியார் (யூதர் அல்லாத) கிறிஸ்தவர்களின் இடம் இயேசுவின் போதனையின் அடிப்படையில் உள்ளது என்பதைக் காட்ட லூக்கா விரும்பினார்.
            </p>
            <Link to="/Luke">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யோவான்</h2>
            <p>
            யோவான் நற்செய்தி ("நற்செய்தி") மற்ற மூன்றில் இருந்து வேறுபட்டது, மற்றவற்றில் விவரிக்கப்படாத நிகழ்வுகளை எடுத்துக்காட்டுகிறது. 20:31ல் தனது முக்கிய நோக்கத்தை ஆசிரியர் தாமே தெளிவாகக் கூறுகிறார்: "இயேசுவே கிறிஸ்து, தேவனுடைய குமாரன் என்று நீங்கள் விசுவாசிக்க வேண்டும், மேலும் விசுவாசிப்பதன் மூலம் நீங்கள் அவருடைய நாமத்தில் ஜீவனைப் பெறலாம்."
            </p>
            <Link to="/John">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>அப்போஸ்தலர்</h2>
            <p>
            அப்போஸ்தலர் புத்தகம் புதிய ஏற்பாட்டின் எழுத்துக்களுக்கு ஒரு பாலத்தை வழங்குகிறது. லூக்காவின் நற்செய்தியின் இரண்டாவது தொகுதியாக, அது இயேசு "செய்யவும் கற்பிக்கவும் தொடங்கினார்" என்பதோடு, அப்போஸ்தலர்களின் பிரசங்கம் மற்றும் தேவாலயத்தை ஸ்தாபித்தல் மூலம் அவர் தொடர்ந்து என்ன செய்தார் மற்றும் கற்பித்தார் என்பதோடு நற்செய்திகளில் சொல்லப்பட்டதையும் இணைக்கிறது.
            </p>
            <Link to="/Acts">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>ரோமர்</h2>
            <p>
            ரோமர்களில் பவுலின் முதன்மைக் கருப்பொருள் நற்செய்தியை ("நற்செய்தி"), யூதர் மற்றும் யூதர் அல்லாத மனிதர்கள் அனைவருக்கும் இரட்சிப்பு மற்றும் நீதிக்கான கடவுளின் திட்டம்
            </p>
            <Link to="/Romans">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 கொரிந்தியர்</h2>
            <p>
            கொரிந்தியர்களுக்கான முதல் கடிதம் தேவாலயத்தில் கிறிஸ்தவ நடத்தையில் உள்ள பிரச்சனைகளின் கருப்பொருளைச் சுற்றி வருகிறது. இது முற்போக்கான பரிசுத்தமாக்குதலுடன் தொடர்புடையது, ஒரு புனித தன்மையின் தொடர்ச்சியான வளர்ச்சி. வெளிப்படையாக பவுல் கொரிந்தியர்களின் பிரச்சனைகளில் தனிப்பட்ட முறையில் அக்கறை கொண்டிருந்தார், உண்மையான போதகரின் (மேய்ப்பனின்) இதயத்தை வெளிப்படுத்தினார்
            </p>
            <Link to="/1-Corinthians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 கொரிந்தியர்</h2>
            <p>
            இந்தக் கடிதத்தைத் தூண்டிய சந்தர்ப்பத்தின் காரணமாக, பவுலின் மனதில் பல நோக்கங்கள் இருந்தன: கொரிந்தியர்கள் அவருடைய வலிமிகுந்த கடிதத்திற்குச் சாதகமாகப் பதிலளித்ததால், பவுல் உணர்ந்த ஆறுதலையும் மகிழ்ச்சியையும் வெளிப்படுத்துவதற்காக; ஆசிய மாகாணத்தில் அவர் கடந்து வந்த பிரச்சனையைப் பற்றி அவர்களுக்குத் தெரியப்படுத்துவதற்காக; மேலும் கிறிஸ்தவ ஊழியத்தின் உண்மையான இயல்பு (அதன் மகிழ்ச்சிகள், துன்பங்கள் மற்றும் வெகுமதிகள்) மற்றும் உயர்ந்த அழைப்பு ஆகியவற்றை அவர்களுக்கு விளக்கவும்
            </p>
            <Link to="/2-Corinthians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>கலாத்தியர்</h2>
            <p>
            கலாத்தியர்கள் புதிய ஏற்பாட்டின் அத்தியாவசியமான புதிய ஏற்பாட்டு உண்மைக்கு ஒரு சொற்பொழிவாளர் மற்றும் வலிமையான மன்னிப்புக் கேட்கிறார்கள், மக்கள் இயேசு கிறிஸ்துவில் உள்ள விசுவாசத்தால் நியாயப்படுத்தப்படுகிறார்கள் - மேலும் எதுவும் இல்லை - மேலும் அவர்கள் சட்டபூர்வமான செயல்களால் அல்ல, ஆனால் கடவுளின் விசுவாசத்தால் வரும் கீழ்ப்படிதலால் புனிதப்படுத்தப்படுகிறார்கள். அவர்களுக்காக வேலை.
            </p>
            <Link to="/Galatians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எபேசியர்</h2>
            <p>
            பவுல் எழுதிய பல கடிதங்களைப் போலல்லாமல், எபேசியர் எந்த குறிப்பிட்ட பிழை அல்லது மதங்களுக்கு எதிரான கொள்கையைக் குறிப்பிடவில்லை. பவுல் தனது வாசகர்களின் எல்லைகளை விரிவுபடுத்துவதற்காக எழுதினார், இதனால் அவர்கள் கடவுளின் நித்திய நோக்கம் மற்றும் கிருபையின் பரிமாணங்களை நன்கு புரிந்துகொள்வதற்கும், தேவாலயத்திற்காக கடவுள் வைத்திருக்கும் உயர்ந்த இலக்குகளைப் பாராட்டுவதற்கும் வருவார்கள்.
            </p>
            <Link to="/Ephesians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>பிலிப்பியர்</h2>
            <p>
            இந்த கடிதத்தை எழுதுவதில் பவுலின் முதன்மை நோக்கம், ரோமில் தான் காவலில் வைக்கப்பட்டிருப்பதை அறிந்ததும், பிலிப்பியர்கள் அவருக்கு அனுப்பிய பரிசுக்காக அவர்களுக்கு நன்றி தெரிவிப்பதாகும். இருப்பினும், வேறு பல ஆசைகளை நிறைவேற்றுவதற்கு அவர் இந்த சந்தர்ப்பத்தைப் பயன்படுத்துகிறார்: (1) தனது சொந்த சூழ்நிலைகளைப் பற்றி புகாரளிக்க; (2) துன்புறுத்தலின் முகத்தில் உறுதியாக நிற்கவும், சூழ்நிலைகளைப் பொருட்படுத்தாமல் மகிழ்ச்சியடையவும் பிலிப்பியர்களை ஊக்குவித்தல்; மற்றும் (3) பணிவு மற்றும் ஒற்றுமைக்கு அவர்களைப் போதிக்க வேண்டும்.
            </p>
            <Link to="/Colossians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>கொலோசெயர்</h2>
            <p>
            கொலோசெயர் மதங்களுக்கு எதிரான கொள்கையை மறுப்பதே பவுலின் நோக்கம். இந்த இலக்கை நிறைவேற்ற, அவர் கிறிஸ்துவை கடவுளின் சாயலாகவும், படைப்பாளராகவும், எல்லாவற்றின் முன்னோடியாகவும், தேவாலயத்தின் தலைவராகவும், முதலில் உயிர்த்தெழுப்பப்பட்டவராகவும், உடல் வடிவத்தில் முழு தெய்வமாகவும் (கடவுள்) உயர்த்துகிறார். சமரசம் செய்பவர்.
            </p>
            <Link to="/1-Thessalonians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 தெசலோனிக்கேயர்</h2>
            <p>
            கடிதத்தின் உந்துதல் வேறுபட்டது என்றாலும், இரண்டு தெசலோனியன் கடிதங்களிலும் எஸ்காடாலஜி (கடைசி விஷயங்களின் கோட்பாடு) பொருள் முதன்மையாகத் தெரிகிறது. 1 தெசலோனிக்கேயர்களின் ஒவ்வொரு அத்தியாயமும் கிறிஸ்துவின் இரண்டாம் வருகையைப் பற்றிய குறிப்புடன் முடிவடைகிறது.
            </p>
            <Link to="/2-Thessalonians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 தெசலோனிக்கேயர்</h2>
            <p>
            தெசலோனிக்கேய தேவாலயத்தில் நிலைமை கணிசமாக மாறவில்லை என்பதால், பவுலின் எழுத்தின் நோக்கம் அவர்களுக்கு அவர் எழுதிய முதல் கடிதத்தில் இருந்ததைப் போலவே உள்ளது. அவர் (1) துன்புறுத்தப்பட்ட விசுவாசிகளை ஊக்குவிப்பதற்காகவும், (2) கர்த்தர் திரும்புவதைப் பற்றிய தவறான புரிதலை சரிசெய்யவும், (3) தெசலோனிக்கேயர்களை உறுதியுடன் இருக்கவும், வாழ்க்கைக்காக உழைக்கவும் அறிவுறுத்துகிறார்.
            </p>
            <Link to="/2-Thessalonians">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 தீமோத்தேயு</h2>
            <p>
            பவுல் தனது நான்காவது மிஷனரி பயணத்தின்போது, ​​மாசிடோனியாவுக்குச் சென்றபோது எபேசஸ் தேவாலயத்தைக் கவனித்துக்கொள்ள தீமோத்தேயுவுக்கு அறிவுறுத்தினார். எதிர்காலத்தில் அவர் எபேசஸுக்குத் திரும்ப முடியாது என்பதை உணர்ந்தபோது, ​​அவர் தனது இளம் உதவியாளரிடம் கொடுத்த பொறுப்பை வளர்க்க தீமோத்தேயுவுக்கு இந்த முதல் கடிதத்தை எழுதினார். இதுவே "ஆயர் நிருபங்களில்" முதன்மையானது
            </p>
            <Link to="/1-Timothy">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 தீமோத்தேயு</h2>
            <p>
            நீரோவின் கீழ் துன்புறுத்தப்பட்ட இந்த நேரத்தில் தேவாலயங்களின் நலனில் பவுல் அக்கறை கொண்டிருந்தார், மேலும் நற்செய்தியைப் பாதுகாக்கவும், அதில் விடாமுயற்சியுடன் இருக்கவும், அதைப் பிரசங்கிப்பதைத் தொடரவும், தேவைப்பட்டால், அதற்காக துன்பப்படவும் அவர் தீமோத்தேயுவுக்கு அறிவுறுத்துகிறார். இது இரண்டாவது "ஆயர் நிருபம்" ஆகும்
            </p>
            <Link to="/2-Timothy">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>தீத்து</h2>
            <p>
            அவரும் தீத்தும் தீவுக்குச் சென்றபோது பவுல் கிரீட்டில் கிறிஸ்தவத்தை அறிமுகப்படுத்தினார், அதன் பிறகு அவர் மதம் மாறியவர்களை ஒழுங்கமைக்க டைட்டஸை அங்கேயே விட்டுவிட்டார். கிரீட் வழியாக அவர்களை அழைத்துச் சென்ற பயணத்தில் இருந்த ஜெனாஸ் மற்றும் அப்பொல்லோ ஆகியோருடன் பவுல் கடிதத்தை அனுப்பினார், எதிர்ப்பைச் சந்திப்பதில் தீத்துவுக்கு தனிப்பட்ட அங்கீகாரம் மற்றும் வழிகாட்டுதல், நம்பிக்கை மற்றும் நடத்தை பற்றிய அறிவுரைகள் மற்றும் தவறான போதகர்களைப் பற்றிய எச்சரிக்கைகள் ஆகியவற்றை வழங்குவதற்காக. "ஆயர் நிருபங்களில்" இதுவே கடைசி.
            </p>
            <Link to="/Titus">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>பிலேமோன்</h2>
            <p>
            ஓடிப்போன அடிமையான ஒனேசிமஸை ஃபிலேமோன் விருப்பத்துடன் ஏற்றுக்கொள்வதைப் பெற, பால் மிகவும் சாதுர்யமாகவும், இலகுவான தொனியிலும் எழுதுகிறார், அதை அவர் சொற்களஞ்சியத்துடன் உருவாக்குகிறார். இந்த முறையீடு பண்டைய கிரேக்க மற்றும் ரோமானிய ஆசிரியர்களால் பரிந்துரைக்கப்பட்ட முறையில் ஒழுங்கமைக்கப்பட்டுள்ளது: நல்லுறவை உருவாக்கவும், மனதை வற்புறுத்தவும், உணர்ச்சிகளை நகர்த்தவும்.
            </p>
            <Link to="/Philemon">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>எபிரெயர்</h2>
            <p>
            எபிரேயர்களின் கருப்பொருள் இயேசு கிறிஸ்துவின் முழுமையான மேலாதிக்கம் மற்றும் போதுமானது என்பது வெளிப்படுத்துபவர் மற்றும் கடவுளின் கிருபையின் மத்தியஸ்தராகும். நற்செய்தியின் இந்த விளக்கக்காட்சியின் ஒரு குறிப்பிடத்தக்க அம்சம், பழைய ஏற்பாட்டு வேதாகமத்தின் எட்டு குறிப்பிட்ட பத்திகளின் விளக்கங்களை ஆசிரியர் பயன்படுத்திய தனித்துவமான விதம் ஆகும்.
            </p>
            <Link to="/Hebrews">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யாக்கோபு</h2>
            <p>
            எழுத்தை தனித்துவமாக்கும் சிறப்பியல்புகள்: (1) அதன் யூத இயல்பு; (2) முக்கிய கிறிஸ்தவத்தின் மீது அதன் முக்கியத்துவம், நற்செயல்கள் மற்றும் செயல்படும் நம்பிக்கை (உண்மையான விசுவாசம் ஒரு சீரான வாழ்க்கை முறையுடன் இருக்க வேண்டும் மற்றும் இருக்க வேண்டும்); (3) அதன் எளிய அமைப்பு; (4) மற்றும் மலைப் பிரசங்கத்தில் பாதுகாக்கப்பட்ட இயேசுவின் போதனைகளுடன் அதன் பரிச்சயம்.
            </p>
            <Link to="/James">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 பேதுரு</h2>
            <p>
            1 பேதுரு ஒரு சிறிய கடிதம் என்றாலும், அது பல்வேறு கோட்பாடுகளைத் தொடுகிறது மற்றும் கிறிஸ்தவ வாழ்க்கை மற்றும் கடமைகளைப் பற்றி அதிகம் கூறுகிறது. வெவ்வேறு வாசகர்கள் வெவ்வேறு முக்கிய கருப்பொருள்களைக் கொண்டிருப்பதைக் கண்டறிந்ததில் ஆச்சரியமில்லை. எடுத்துக்காட்டாக, இது பிரிவினையின் கடிதம், துன்பம் மற்றும் துன்புறுத்தல், துன்பம் மற்றும் மகிமை, நம்பிக்கை, யாத்திரை, தைரியம் மற்றும் கடவுளின் உண்மையான கிருபையைக் கையாளும் கடிதமாக வகைப்படுத்தப்பட்டுள்ளது.
            </p>
            <Link to="/1-Peter">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 பேதுரு</h2>
            <p>
            பேதுரு தனது முதல் கடிதத்தில் கிறிஸ்துவின் ஆடுகளுக்கு தேவாலயத்திற்கு வெளியில் இருந்து வரும் துன்புறுத்தலை எவ்வாறு கையாள்வது என்று அறிவுறுத்துகிறார்; இந்த இரண்டாவது கடிதத்தில், தேவாலயத்திற்குள் வந்திருக்கும் தவறான போதகர்கள் மற்றும் தீமை செய்பவர்களை எவ்வாறு கையாள்வது என்று அவர்களுக்குக் கற்றுக்கொடுக்கிறார்.
            </p>
            <Link to="/2-Peter">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>1 யோவான்</h2>
            <p>
            யோவானின் வாசகர்கள் செரிந்தியன் வகையின் ஞான போதனையின் ஆரம்ப வடிவத்தை எதிர்கொண்டனர். இந்த மதவெறியும் சுதந்திரமாக இருந்தது, எல்லா தார்மீக கட்டுப்பாடுகளையும் தூக்கி எறிந்தது. இதன் விளைவாக, ஜான் இந்த கடிதத்தை இரண்டு அடிப்படை நோக்கங்களை மனதில் கொண்டு எழுதினார்: (1) பொய்யான போதகர்களை அம்பலப்படுத்துவது மற்றும் (2) விசுவாசிகளுக்கு இரட்சிப்பின் உறுதியை அளிப்பது.
            </p>
            <Link to="/1-John">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>2 யோவான்</h2>
            <p>
            முதல் இரண்டு நூற்றாண்டுகளில் சுவிசேஷம் சுவிசேஷகர்கள் மற்றும் ஆசிரியர்களால் இடத்திலிருந்து இடத்திற்கு எடுத்துச் செல்லப்பட்டது. விசுவாசிகள் வழக்கமாக இந்த மிஷனரிகளை தங்கள் வீடுகளுக்கு அழைத்துச் சென்று, அவர்கள் வெளியேறும்போது அவர்களின் பயணத்திற்கான ஏற்பாடுகளை வழங்கினர். நாஸ்டிக் ஆசிரியர்களும் இந்த நடைமுறையை நம்பியிருந்ததால், 2 ஜான் பயண ஆசிரியர்களை ஆதரிப்பதில் விவேகத்தை வலியுறுத்துவதற்காக எழுதப்பட்டது.
            </p>
            <Link to="/2-John">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>3 யோவான்</h2>
            <p>
            யோவான் அனுப்பிய பயண ஆசிரியர்கள் ஆசிய மாகாணத்தில் உள்ள சர்ச் ஒன்றில் சர்வாதிகாரத் தலைவரான டியோட்ரெப்ஸால் நிராகரிக்கப்பட்டனர், அவர் ஜானின் தூதர்களுக்கு விருந்தோம்பல் காட்டிய உறுப்பினர்களைக் கூட வெளியேற்றினார். ஆசிரியர்களை ஆதரித்ததற்காக கயஸைப் பாராட்டவும், மறைமுகமாக, தியோட்ரெபீஸை எச்சரிக்கவும் ஜான் இந்தக் கடிதத்தை எழுதினார்.
            </p>
            <Link to="/3-John">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>யூதா</h2>
            <p>
            யூதா இரட்சிப்பைப் பற்றி தனது வாசகர்களுக்கு எழுத மிகவும் ஆர்வமாக இருந்தபோதிலும், கடவுளின் கிருபையை சிதைக்கும் சில ஒழுக்கக்கேடான மனிதர்களைப் பற்றி அவர்களுக்கு எச்சரிக்க வேண்டும் என்று அவர் உணர்ந்தார். கிருபையால் இரட்சிக்கப்படுவது, பாவம் செய்ய அவர்களுக்கு உரிமம் கொடுத்தது என்று விசுவாசிகளை நம்பவைக்க இந்த பொய்யான ஆசிரியர்கள் முயன்றனர், ஏனெனில் அவர்களின் பாவங்கள் இனி அவர்களுக்கு எதிராக நடத்தப்படாது.
            </p>
            <Link to="/Jude">மேலும் </Link>
        </div>
        <div className='summery'>
            <h2>வெளி</h2>
            <p>
            பேரரசர் வழிபாட்டின் கோரிக்கைகளை உறுதியாக எதிர்க்க விசுவாசிகளை ஊக்குவிக்க வெளி எழுதுகிறார். கடவுளுக்கும் சாத்தானுக்கும் இடையிலான இறுதி மோதல் நெருங்கிவிட்டது என்று அவர் தனது வாசகர்களுக்குத் தெரிவிக்கிறார். சாத்தான் விசுவாசிகளைத் துன்புறுத்துவதை அதிகப்படுத்துவான், ஆனால் அவர்கள் மரணம் வரை உறுதியாக நிற்க வேண்டும். அவர்கள் எந்த ஆவிக்குரிய தீங்கும் எதிராக முத்திரையிடப்பட்டு, கிறிஸ்து திரும்பி வரும்போது, ​​துன்மார்க்கர்கள் என்றென்றும் அழிக்கப்படும்போது, ​​கடவுளுடைய மக்கள் மகிமை மற்றும் ஆசீர்வாதத்தின் நித்தியத்திற்குள் நுழையும்போது விரைவில் நிரூபிக்கப்படுவார்கள்.
            </p>
            <Link to="/Revelation">மேலும் </Link>
        </div>
        </div>
    </div>
  )
}

export default NEWTESTAMENT