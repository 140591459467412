import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 2 </title>
	<meta
          name="description"
          content="Hosea 2 | ஓசியா 2 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உங்கள் சகோதரரைப்பார்த்து அம்மீ என்றும், உங்கள் சகோதரிகளைப்பார்த்து ருகாமா என்றும் சொல்லுங்கள்.
	<br /><br />
	2. உங்கள் தாயோடே வழக்காடுங்கள்; அவள் எனக்கு மனைவியுமல்ல, நான் அவளுக்குப் புருஷனுமல்ல; அவள் தன் வேசித்தனங்களைத் தன் முகத்தினின்றும், தன் விபசாரங்களைத் தன் ஸ்தனங்களின் நடுவினின்றும் விலக்கிப்போடக்கடவள்.
	<br /><br />
	3. இல்லாவிட்டால் நான் அவளை நிர்வாணமாக உரிந்து, அவள் பிறந்தநாளில் இருந்தவண்ணமாக அவளை நிறுத்தி, அவளை அந்தரவெளியைப்போலாக்கி, அவளை வறண்டபூமியைப்போல் விட்டு, அவளைத் தாகத்தால் சாகப்பண்ணுவேன்;
	<br /><br />
	4. அவளுடைய பிள்ளைகள் சோரப்பிள்ளைகளாகையால் அவர்களுக்கு இரங்காதிருப்பேன்.
	<br /><br />
	5. அவர்களுடைய தாய் சோரம்போனாள், அவர்களைக் கர்ப்பந்தரித்தவள் இலச்சையான காரியங்களைச் செய்தாள்; அப்பத்தையும், தண்ணீரையும், ஆட்டுமயிரையும், பஞ்சையும், எண்ணெயையும், பானங்களையும் கொடுத்துவருகிற என் நேசர்களைப் பின்பற்றிப்போவேன் என்றாள்.
	<br /><br />
	6. ஆகையால், இதோ, நான் உன் வழியை முள்ளுகளினால் அடைப்பேன்; அவள் தன் பாதைகளைக் கண்டுபிடிக்கக் கூடாதபடிக்கு மதிலை எழுப்புவேன்.
	<br /><br />
	7. அவள் தன் நேசர்களைப் பின்தொடர்ந்தும் அவர்களைச் சேருவதில்லை, அவர்களைத் தேடியும் கண்டுபிடிப்பதில்லை; அப்பொழுது அவள்: நான் என் முந்தின புருஷனிடத்துக்குத் திரும்பிப்போவேன்; இப்பொழுது இருக்கிறதைப்பார்க்கிலும் அப்பொழுது எனக்கு நன்மையாயிருந்தது என்பாள்.
	<br /><br />
	8. தனக்கு நான் தானியத்தையும் திராட்சரசத்தையும் எண்ணெயையும் கொடுத்தவரென்றும், தனக்கு நான் வெள்ளியையும் பொன்னையும் பெருகப்பண்ணினவரென்றும் அவள் அறியாமற்போனாள்; அவைகளை அவர்கள் பாகாலுடையதாக்கினார்கள்.
	<br /><br />
	9. ஆதலால் நான் என் தானியத்தை அதின் காலத்திலும், என் திராட்சரசத்தை அதின் காலத்திலும் திரும்ப எடுத்துக்கொண்டு, அவளுடைய நிர்வாணத்தை மூடுகிறதற்கு நான் கொடுத்திருந்த ஆட்டுமயிரையும் சணலையும் திரும்பப் பிடுங்கிக்கொள்ளுவேன்.
	<br /><br />
	10. இப்போதும் அவளுடைய நேசர்களின் கண்களுக்கு முன்பாக அவளுடைய அவலட்சணத்தை வெளிப்படுத்துவேன்; ஒருவரும் அவளை என் கைக்கு நீங்கலாக்கி விடுவிப்பதில்லை.
	<br /><br />
	11. அவளுடைய எல்லா மகிழ்ச்சியையும், அவளுடைய பண்டிகைகளையும், அவளுடைய மாதப்பிறப்புகளையும், அவளுடைய ஓய்வுநாட்களையும், சபைகூடுகிற அவளுடைய எல்லா ஆசரிப்புகளையும் ஒழியப்பண்ணுவேன்.
	<br /><br />
	12. என் நேசர்கள் எனக்குக் கொடுத்த பணையம் என்று அவள் சொன்ன அவளுடைய திராட்சச்செடிகளையும், அவளுடைய அத்திமரங்களையும் நான் பாழாக்கி, அவைகளைக் காடாய்ப்போகப்பண்ணுவேன்; காட்டுமிருகங்கள் அவைகளைத் தின்னும்.
	<br /><br />
	13. அவள் பாகால்களுக்குத் தூபங்காட்டி, தன் நெற்றிப்பட்டங்களினாலும் தன் ஆபரணங்களினாலும் தன்னைச் சிங்காரித்துக்கொண்டு, தன் நேசரைப் பின்தொடர்ந்து, என்னை மறந்துபோன நாட்களினிமித்தம் அவளை விசாரிப்பேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	14. ஆயினும், இதோ, நான் அவளுக்கு நயங்காட்டி, அவளை வனாந்தரத்தில் அழைத்துக்கொண்டுபோய், அவளோடே பட்சமாய்ப் பேசி,
	<br /><br />
	15. அவ்விடத்திலிருந்து அவளுக்கு அவளுடைய திராட்சத்தோட்டங்களையும், நம்பிக்கையின் வாசலாக ஆகோரின் பள்ளத்தாக்கையும் கொடுப்பேன்; அப்பொழுது அவள் அங்கே, தன் இளவயதின் நாட்களிலும் தான் எகிப்துதேசத்திலிருந்து வந்த நாளிலும் பாடினதுபோல் பாடுவாள்.
	<br /><br />
	16. அக்காலத்தில் நீ என்னை இனி பாகாலி என்று சொல்லாமல், ஈஷி என்று சொல்லுவாய் என்று கர்த்தர் உரைக்கிறார்.
	<br /><br />
	17. பாகால்களுடைய நாமங்களை அவள் வாயிலிருந்து அற்றுப்போகப்பண்ணுவேன்; இனி அவைகளின் பேரைச்சொல்லி, அவைகளை நினைக்கிற நினைப்பும் இல்லாமற்போகும்.
	<br /><br />
	18. அக்காலத்தில் நான் அவர்களுக்காகக் காட்டு மிருகங்களோடும், ஆகாயத்துப் பறவைகளோடும், பூமியிலே ஊரும் பிராணிகளோடும், ஒரு உடன்படிக்கைபண்ணி, வில்லையும் பட்டயத்தையும் யுத்தத்தையும் தேசத்திலே இராதபடிக்கு முறித்து, அவர்களைச் சுகமாய்ப் படுத்துக் கொண்டிருக்கப்பண்ணுவேன்.
	<br /><br />
	19. நித்திய விவாகத்துக்கென்று உன்னை எனக்கு நியமித்துக்கொள்ளுவேன்; நீதியும் நியாயமும் கிருபையும் உருக்க இரக்கமுமாய் உன்னை எனக்கு நியமித்துக்கொள்ளுவேன்.
	<br /><br />
	20. உண்மையாய் உன்னை எனக்கு நியமித்துக்கொள்ளுவேன்; நீ கர்த்தரை அறிந்துகொள்ளுவாய்.
	<br /><br />
	21. அக்காலத்தில் நான் மறுமொழி கொடுப்பேன் என்று கர்த்தர் சொல்லுகிறார்; நான் வானங்களுக்கு மறுமொழி கொடுப்பேன், அவைகள் பூமிக்கு மறுமொழி கொடுக்கும்.
	<br /><br />
	22. பூமி தானியத்துக்கும் திராட்சரசத்துக்கும் எண்ணெய்க்கும் மறுமொழி கொடுக்கும், இவைகள் யெஸ்ரயேலுக்கும் மறுமொழி கொடுக்கும்.
	<br /><br />
	23. நான் அவளை எனக்கென்று பூமியிலே விதைத்து, இரக்கம் பெறாதிருந்தவளுக்கு இரங்குவேன்; என் ஜனமல்லாதிருந்தவர்களை நோக்கி நீ என் ஜனமென்று சொல்லுவேன்; அவர்கள் என் தேவனே என்பார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea2