import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation21 = () => {
  const verseNumber = 21;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 21 </title>
	<meta
          name="description"
          content="The Book of Revelation - 21 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 21 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* நான் ஒரு புதிய வானத்தையும் பூமியையும் கண்டேன். ஒரு குரல், "மனுஷர்களிடத்திலே அவை வாசமாயிருப்பார்" என்று கூறியது. ஒரு தூதன் எனக்கு புதிய ஜெருசலேமைக் காட்டினார். அதன் விளக்கு ஆட்டுக்குட்டி. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பின்பு, நான் புதிய வானத்தையும் புதிய பூமியையும் கண்டேன்; முந்தின வானமும் முந்தின பூமியும் ஒழிந்துபோயின; சமுத்திரமும் இல்லாமற்போயிற்று.
	<br /><br />
	2. யோவானாகிய நான், புதிய எருசலேமாகிய பரிசுத்த நகரத்தைத் தேவனிடத்தினின்று பரலோகத்தைவிட்டு இறங்கி வரக்கண்டேன்; அது தன் புருஷனுக்காக அலங்கரிக்கப்பட்ட மணவாட்டியைப்போல ஆயத்தமாக்கப்பட்டிருந்தது.
	<br /><br />
	3. மேலும், பரலோகத்திலிருந்து உண்டான ஒரு பெருஞ்சத்தத்தைக் கேட்டேன்; அது: இதோ, மனுஷர்களிடத்திலே தேவனுடைய வாசஸ்தலமிருக்கிறது, அவர்களிடத்திலே அவர் வாசமாயிருப்பார்; அவர்களும் அவருடைய ஜனங்களாயிருப்பார்கள், தேவன்தாமே அவர்களோடேகூட இருந்து அவர்களுடைய தேவனாயிருப்பார்.
	<br /><br />
	4. அவர்களுடைய கண்ணீர் யாவையும் தேவன் துடைப்பார்; இனி மரணமுமில்லை, துக்கமுமில்லை, அலறுதலுமில்லை, வருத்தமுமில்லை; முந்தினவைகள் ஒழிந்துபோயின என்று விளம்பினது.
	<br /><br />
	5. சிங்காசனத்தின்மேல் வீற்றிருந்தவர்: இதோ, நான் சகலத்தையும் புதிதாக்குகிறேன் என்றார். பின்னும், அவர்: இந்த வசனங்கள் சத்தியமும் உண்மையுமானவைகள், இவைகளை எழுது என்றார்.
	<br /><br />
	6. அன்றியும், அவர் என்னை நோக்கி: ஆயிற்று, நான் அல்பாவும் ஓமெகாவும், ஆதியும் அந்தமுமாயிருக்கிறேன். தாகமாயிருக்கிறவனுக்கு நான் ஜீவத்தண்ணீரூற்றில் இலவசமாய்க் கொடுப்பேன்.
	<br /><br />
	7. ஜெயங்கொள்ளுகிறவன் எல்லாவற்றையும் சுதந்தரித்துக்கொள்ளுவான்; நான் அவன் தேவனாயிருப்பேன், அவன் என் குமாரனாயிருப்பான்.
	<br /><br />
	8. பயப்படுகிறவர்களும், அவிசுவாசிகளும், அருவருப்பானவர்களும், கொலைபாதகரும், விபசாரக்காரரும், சூனியக்காரரும், விக்கிரகாராதனைக்காரரும், பொய்யர் அனைவரும் இரண்டாம் மரணமாகிய அக்கினியும் கந்தகமும் எரிகிற கடலிலே பங்கடைவார்கள் என்றார்.
	<br /><br />
	9. பின்பு, கடைசியான ஏழு வாதைகளால் நிறைந்த ஏழு கலசங்களையுடைய அந்த ஏழு தூதரில் ஒருவன் என்னிடத்தில் வந்து: நீ இங்கே வா, ஆட்டுக்குட்டியானவருடைய மனைவியாகிய மணவாட்டியை உனக்குக் காண்பிக்கிறேன் என்று சொல்லி,
	<br /><br />
	10. பெரிதும் உயரமுமான ஒரு பர்வதத்தின்மேல் என்னை ஆவியில் கொண்டுபோய், தேவனுடைய மகிமையை அடைந்த எருசலேமாகிய பரிசுத்தநகரம் பரலோகத்தைவிட்டுத் தேவனிடத்திலிருந்து இறங்கிவருகிறதை எனக்குக் காண்பித்தான்.
	<br /><br />
	11. அதின் பிரகாசம் மிகவும் விலையுயர்ந்த இரத்தினக்கல்லைப்போலவும், பளிங்கினொளியுள்ள வச்சிரக்கல்லைப்போலவும் இருந்தது.
	<br /><br />
	12. அதற்குப் பெரிதும் உயரமுமான மதிலும், கிழக்கே மூன்று வாசல்கள், வடக்கே மூன்று வாசல்கள், தெற்கே மூன்று வாசல்கள், மேற்கே மூன்று வாசல்கள் ஆகப் பன்னிரண்டு வாசல்களும் இருந்தன.
	<br /><br />
	13. வாசல்களின் அருகே பன்னிரண்டு தூதர்களிருந்தார்கள்; அந்த வாசல்களின்மேல் இஸ்ரவேல் சந்ததியாராகிய பன்னிரண்டு கோத்திரத்தாருடைய நாமங்களும் எழுதப்பட்டிருந்தன.
	<br /><br />
	14. நகரத்தின் மதிலுக்குப் பன்னிரண்டு அஸ்திபாரக் கற்களிருந்தன; அவைகள்மேல் ஆட்டுக்குட்டியானவருடைய பன்னிரண்டு அப்போஸ்தலரின் பன்னிரண்டு நாமங்களும் பதிந்திருந்தன.
	<br /><br />
	15. என்னுடனே பேசினவன், நகரத்தையும் அதின் வாசல்களையும் அதின் மதிலையும் அளக்கிறதற்கு ஒரு பொற்கோலைப் பிடித்திருந்தான்.
	<br /><br />
	16. அந்த நகரம் சதுரமாயிருந்தது, அதின் அகலமும் நீளமும் சமமாயிருந்தது. அவன் அந்தக் கோலினால் நகரத்தை அளந்தான்; அது பன்னீராயிரம் ஸ்தாதி அளவாயிருந்தது; அதின் நீளமும் அகலமும் உயரமும் சமமாயிருந்தது.
	<br /><br />
	17. அவன் அதின் மதிலை அளந்தபோது, அது தூதனுடைய அளவாகிய மனுஷ அளவின்படியே நூற்றுநாற்பத்துநான்கு முழமாயிருந்தது.
	<br /><br />
	18. அதின் மதில் வச்சிரக்கல்லால் கட்டப்பட்டிருந்தது; நகரம் தெளிந்த பளிங்குக்கு ஒப்பான சுத்தப்பொன்னாயிருந்தது.
	<br /><br />
	19. நகரத்து மதில்களின் அஸ்திபாரங்கள் சகலவித இரத்தினங்களினாலும் அலங்கரிக்கப்பட்டிருந்தன; முதலாம் அஸ்திபாரம் வச்சிரக்கல், இரண்டாவது இந்திரநீலம், மூன்றாவது சந்திரகாந்தம், நான்காவது மரகதம்,
	<br /><br />
	20. ஐந்தாவது கோமேதகம், ஆறாவது பதுமராகம், ஏழாவது சுவர்ணரத்தினம், எட்டாவது படிகப்பச்சை, ஒன்பதாவது புஷ்பராகம், பத்தாவது வைடூரியம், பதினோராவது சுநீரம், பன்னிரண்டாவது சுகந்தி இவைகளே.
	<br /><br />
	21. பன்னிரண்டு வாசல்களும் பன்னிரண்டு முத்துக்களாயிருந்தன; ஒவ்வொரு வாசலும் ஒவ்வொரு முத்தாயிருந்தது. நகரத்தின் வீதி தெளிவுள்ள பளிங்குபோலச் சுத்தப்பொன்னாயிருந்தது.
	<br /><br />
	22. அதிலே தேவாலயத்தை நான் காணவில்லை; சர்வவல்லமையுள்ள தேவனாகிய கர்த்தரும் ஆட்டுக்குட்டியானவருமே அதற்கு ஆலயம்.
	<br /><br />
	23. நகரத்திற்கு வெளிச்சங்கொடுக்கச் சூரியனும் சந்திரனும் அதற்கு வேண்டுவதில்லை; தேவனுடைய மகிமையே அதைப் பிரகாசிப்பித்தது, ஆட்டுக்குட்டியானவரே அதற்கு விளக்கு.
	<br /><br />
	24. இரட்சிக்கப்படுகிற ஜனங்கள் அதின் வெளிச்சத்திலே நடப்பார்கள். பூமியின் ராஜாக்கள் தங்கள் மகிமையையும் கனத்தையும் அதற்குள்ளே கொண்டுவருவார்கள்.
	<br /><br />
	25. அங்கே இராக்காலம் இல்லாதபடியால், அதின் வாசல்கள் பகலில் அடைக்கப்படுவதே இல்லை.
	<br /><br />
	26. உலகத்தாருடைய மகிமையையும் கனத்தையும் அதற்குள்ளே கொண்டுவருவார்கள்.
	<br /><br />
	27. தீட்டுள்ளதும் அருவருப்பையும் பொய்யையும் நடப்பிக்கிறதுமாகிய ஒன்றும் அதில் பிரவேசிப்பதில்லை; ஆட்டுக்குட்டியானவரின் ஜீவபுஸ்தகத்தில் எழுதப்பட்டவர்கள் மாத்திரம் அதில் பிரவேசிப்பார்கள்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h21>
*  *
			</h21>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation21