import React from 'react';
import './bibleindex.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { IonIcon } from '@ionic/react';
import { logoFacebook } from 'ionicons/icons';
import { logoTwitter } from 'ionicons/icons';
import { logoInstagram } from 'ionicons/icons';
import { logoLinkedin } from 'ionicons/icons';
import { logoYoutube } from 'ionicons/icons';
import { logoPinterest } from 'ionicons/icons';
import { logoWhatsapp } from 'ionicons/icons';
import { logoReddit } from 'ionicons/icons';
import Logo from '../logo.png';

function BibleIndex (){
	return( 
		<>
		<Helmet>
			<title> INDEX OF TAMIL BIBLE VERSE  ✞  தமிழில் வேத வசனங்கள் </title>
			<meta
			  name="description"
			  content="Bible Index  ✞  Digital Index of a Tamil bible  ✞  Tamil Bible Verse  ✞  பழைய ஏற்பாடு  ✞  புதிய ஏற்பாடு  ✞  OLD Testament  ✞  NEW Testament  ✞ Holy Bible in Tamil"
			/>
		  </Helmet>
		<div className='bible'>
			
			<div className='testiment'>
				<div className='old'>
				<h2 className='tes' align="center">
				<Link to="/old-testament" target="_self">OLD Testament  ✞  பழைய ஏற்பாடு</Link>
						
				</h2>
				<ul>
<li>
<Link to="/Aathiyagamam" target="_self">ஆதியாகமம்  ✞  Genesis</Link>
</li>
<li>
<Link to="/Yathiragamam" target="_self">யாத்திராகமம்  ✞  Exodus</Link>
</li>
<li>
<Link to="/Leviaragamam" target="_self">லேவியராகமம்  ✞  Leviticus</Link>
</li>
<li>
<Link to="/Ennagamam" target="_self">எண்ணாகமம்  ✞  Numbers</Link>
</li>
<li>
<Link to="/Ubagamam" target="_self">உபாகமம்  ✞  Deuteronomy</Link>
</li>
<li>
<Link to="/Joshua" target="_self">யோசுவா  ✞  Joshua</Link>
</li>
<li>
<Link to="/Niyayathibathigal" target="_self">நியாயாதிபதிகள்  ✞  Judges</Link>
</li>
<li>
<Link to="/Ruth" target="_self">ரூத்  ✞  Ruth</Link>
</li>
<li>
<Link to="/1Samuel" target="_self">1 சாமுவேல்  ✞  1 Samuel</Link>
</li>
<li>
<Link to="/2Samuel" target="_self">2 சாமுவேல்  ✞  2 Samuel</Link>
</li>
<li>
<Link to="/1Rajakkal" target="_self">1 இராஜாக்கள் ✞  1 Kings</Link>
</li>
<li>
<Link to="/2Rajakkal" target="_self">2 இராஜாக்கள்  ✞  2 Kings</Link>
</li>
<li>
<Link to="/1Nalagamam" target="_self">1 நாளாகமம்  ✞  1 Chronicles</Link>
</li>
<li>
<Link to="/2Nalagamam" target="_self">2 நாளாகமம்  ✞  2 Chronicles</Link>
</li>
<li>
<Link to="/Ezra" target="_self">எஸ்றா  ✞  Ezra</Link>
</li>
<li>
<Link to="/Nehemiah" target="_self">நெகேமியா  ✞  Nehemiah</Link>
</li>
<li>
<Link to="/Esther" target="_self">எஸ்தர்  ✞  Esther</Link>
</li>
<li>
<Link to="/Job" target="_self">யோபு  ✞  Job</Link>
</li>
<li>
<Link to="/Sangeetham" target="_self">சங்கீதம்  ✞  Psalms</Link>
</li>
<li>
<Link to="/Neethimozhigal">நீதிமொழிகள்  ✞  Proverbs</Link>
</li>
<li>
<Link to="/Prasangi">பிரசங்கி  ✞  Ecclesiastes</Link>
</li>
<li>
<Link to="/Unnathapattu">உன்னதப்பாட்டு  ✞  Song of Solomon</Link>
</li>
<li>
<Link to="/Yesaya">ஏசாயா  ✞  Isaiah</Link>
</li>
<li>
<Link to="/Eramia">எரேமியா  ✞  Jeremiah</Link>
</li>
<li>
<Link to="/Pulambal">புலம்பல்  ✞  Lamentations</Link>
</li>
<li>
<Link to="/Ezekiel">எசேக்கியேல்  ✞  Ezekiel</Link>
</li>
<li>
<Link to="/Daniel">தானியேல்  ✞  Daniel</Link>
</li>
<li>
<Link to="/Osiya">ஓசியா  ✞  Hosea</Link>
</li>
<li>
<Link to="/Yovel">யோவேல்  ✞  Joel</Link>
</li>
<li>
<Link to="/Aamos">ஆமோஸ்  ✞  Amos</Link>
</li>
<li>
<Link to="/Obadiah">ஒபதியா  ✞  Obadiah</Link>
</li>
<li>
<Link to="/Yona">யோனா  ✞  Jonah</Link>
</li>
<li>
<Link to="/Meega">மீகா  ✞  Micah</Link>
</li>
<li>
<Link to="/Nahum">நாகூம்  ✞  Nahum</Link>
</li>
<li>
<Link to="/Habakkuk">ஆபகூக்  ✞  Habakkuk</Link>
</li>
<li>
<Link to="/Seppaniya">செப்பனியா  ✞  Zephaniah</Link>
</li>
<li>
<Link to="/Haggai">ஆகாய்  ✞  Haggai</Link>
</li>
<li>
<Link to="/Sagariya">சகரியா  ✞  Zechariah</Link>
</li>
<li>
<Link to="/Malgiya">மல்கியா  ✞  Malachi</Link>
</li>
</ul>
				</div>
				<div className='new'> 
					<h2 className='tes' align="center">
					<Link to="/new-testament" target="_self">NEW Testament  ✞  புதிய ஏற்பாடு</Link>
					</h2>
					<ul>
	<li>
		<Link to="/Matthew">
					மத்தேயு  ✞  Matthew
		</Link>
	</li>
	<li>
		<Link to="/Mark">
					மாற்கு  ✞  Mark
		</Link>
	</li>
	<li>
		<Link to="/Luke">
					லூக்கா  ✞  Luke
			</Link>
	</li>
	<li>
		<Link to="/John">
					யோவான்  ✞  John
			</Link>
	</li>
	<li>
		<Link to="/Acts">
					அப்போஸ்தலர்  ✞  Acts
		</Link>
	</li>
	<li>
		<Link to="/Romans">
					ரோமர்  ✞  Romans
			</Link>
	</li>
	<li>
		<Link to="/1-Corinthians">
					1 கொரிந்தியர்  ✞  1 Corinthians
		</Link>
	</li>
	<li>
		<Link to="/2-Corinthians">
					2 கொரிந்தியர்  ✞  2 Corinthians
		</Link>
	</li>
	<li>
		<Link to="/Galatians">
					கலாத்தியர்  ✞  Galatians
		</Link>
	</li>
	<li>
		<Link to="/Ephesians">
					எபேசியர்  ✞  Ephesians
		</Link>
	</li>
	<li>
		<Link to="/Philippians">
					பிலிப்பியர்  ✞  Philippians
		</Link>
	</li>
	<li>
		<Link to="/Colossians">
					கொலோசெயர்  ✞  Colossians
		</Link>
	</li>
	<li>
		<Link to="/1-Thessalonians">
					1 தெசலோனிக்கேயர்  ✞  1 Thessalonians
		</Link>
	</li>
	<li>
		<Link to="/2-Thessalonians">
					2 தெசலோனிக்கேயர்  ✞  2 Thessalonians
		</Link>
	</li>
	<li>
		<Link to="/1-Timothy">
					1 தீமோத்தேயு  ✞  1 Timothy
		</Link>
	</li>
	<li>
		<Link to="/2-Timothy">
					2 தீமோத்தேயு  ✞  2 Timothy
			</Link>
	</li>
	<li>
		<Link to="/Titus">
					தீத்து  ✞  Titus
			</Link>
	</li>
	<li>
		<Link to="/Philemon">
					பிலேமோன்  ✞  Philemon
			</Link>
	</li>
	<li>
		<Link to="/Hebrews">
					எபிரெயர்  ✞  Hebrews
		</Link>
	</li>
	<li>
		<Link to="/James">
					யாக்கோபு  ✞  James
		</Link>
	</li>
	<li>
		<Link to="/1-Peter">
					1 பேதுரு ✞  1 Peter
		</Link>
	</li>
	<li>
		<Link to="/2-Peter">
					2 பேதுரு  ✞  2 Peter
		</Link>
	</li>
	<li>
		<Link to="/1-John">
					1 யோவான்  ✞  1 John
		</Link>
	</li>
	<li>
		<Link to="/2-John">
					2 யோவான்  ✞  2 John
		</Link>
	</li>
	<li>
		<Link to="/3-John">
					3 யோவான்  ✞  3 John
		</Link>
	</li>
	<li>
		<Link to="/Jude">
					யூதா  ✞  Jude
		</Link>
	</li>
	<li>
		<Link to="/Revelation">
					வெளி  ✞  Revelation
		</Link>
	</li>
</ul>

<div className="Bio">
				<div className='Bio-Head'>
					<img width='150px'alt='Bio-Avatar' src={Logo} className='Bio-Avatar'/>
					<br/>
					<h2>TAMIL BIBLE</h2>
					<br/>
					<div className='Bio-Share'>Share</div>
					<br/>
				</div>
				<a href="https://www.youtube.com/@tamilbibleverse" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoYoutube} /> Youtube
				</a>
				<a href="https://www.instagram.com/tamilbible/" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoInstagram} /> Instagram
				</a>
				<a href="https://in.pinterest.com/tamilbibleverse/" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoPinterest} /> Pinterest
				</a>
				<a href="https://www.facebook.com/tamilbibleverse/" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoFacebook} /> Face Book
				</a>
				<a href="https://www.twitter.com/tamilbiblewords/" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoTwitter} /> Twitter
				</a>
				<a href="https://www.linkedin.com/in/tamilbible/" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoLinkedin} /> Linkedin
				</a>
				<a href="https://whatsapp.com/channel/0029Va4izKQCMY0JLgsQtl1h" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoWhatsapp} /> What's App
				</a>
				<a href="https://www.reddit.com/u/TamilBible" target="_blank" className="Bio-Link">
				<IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoReddit} /> Reddit
				</a>
</div>
				</div>
			</div>
			
			
		</div>
		<br />
		<br />
		<br />
		<div className='article content'>
		<h1>
	FINDING INSPIRATION IN TAMIL BIBLE VERSES
</h1>
<br/>
<br />
<h2>
	&nbsp;தமிழ் வேதாகம வசனங்களில் ஆன்மீக ஞானம்
</h2>
      <br />
<ul>
	<p>
	 தமிழ் வேதாகம வசனங்கள் ஆழ்ந்த ஆன்மீக ஞானத்தால் நிரம்பியுள்ளன, அவை தனிநபர்களை அவர்களின் அன்றாட வாழ்க்கையில் வழிநடத்தவும் ஊக்குவிக்கவும் முடியும்.
		அன்பு, மன்னிப்பு, விசுவாசம் மற்றும் நம்பிக்கை போன்ற மனித இருப்பின் பல்வேறு அம்சங்களை அவை பேசுகின்றன.
		இந்த வசனங்கள் கஷ்ட காலங்களில் ஆறுதலையும் ஊக்கத்தையும் வழங்குகின்றன, மேலும் விசுவாசிகளுக்கு வலிமையின் ஆதாரமாக செயல்படுகின்றன.
	</p>
</ul>
<h3>
      <br />
	தமிழ் வேதாகம வசனங்களில் உலகளாவிய செய்திகள்
      <br />
</h3>
<ul>
	<p>
	தமிழில் எழுதப்பட்டிருந்தாலும், இந்த வசனங்களில் சொல்லப்படும் செய்திகள் உலகளாவியவை, மொழித் தடைகளைக் கடந்தவை.
		<br />
		அவை மனித நிலைமையுடன் பேசுகின்றன மற்றும் அனைத்து
		கலாச்சாரங்கள் மற்றும் பின்னணியைச் சேர்ந்தவர்களுக்கு பொருத்தமான கருப்பொருள்களைத் தொடுகின்றன.
		பகிரப்பட்ட ஆன்மீக சத்தியங்களின் மூலம் வாழ்க்கையின் பல்வேறு தரப்பு மக்களை ஒன்றிணைக்கும் சக்தி தமிழ் வேதாகம வசனங்களுக்கு உண்டு.
	</p>
</ul>
<h2>
      <br />
	தமிழ் வேதாகம வசனங்களின் தாக்கம்
      <br />
</h2>

<br /><h3>
	தமிழ் வேதாகம வசனங்கள் மூலம் தனிப்பட்ட மாற்றம்
</h3>
      <br />
<ul>
	<p>
		தமிழ் வேதாகம வசனங்களைப் படிப்பதன் மூலமும் தியானிப்பதன் மூலமும் பலர் தனிப்பட்ட மாற்றத்தை அனுபவித்திருக்கிறார்கள்.
		ஒருவரின் கண்ணோட்டத்தை சவாலுக்கு உட்படுத்தும் மற்றும் மாற்றும் சக்தி வசனங்களுக்கு உள்ளது, இது தனிப்பட்ட வளர்ச்சிக்கும் ஆன்மீக வளர்ச்சிக்கும் வழிவகுக்கிறது.
		அவை வழிகாட்டுதல் மற்றும் வழிகாட்டலை வழங்குகின்றன, தனிநபர்கள் நம்பிக்கை மற்றும் பின்னடைவுடன் வாழ்க்கையின் சிக்கல்களை வழிநடத்த உதவுகின்றன.
	</p>
</ul>

<br /><h3>
	தமிழ் வேதாகம வசனங்கள் மூலம் நம்பிக்கையையும் ஊக்கத்தையும் பரப்புதல்
</h3>
      <br />
<ul>
	<p>
	தமிழ் வேதாகம வசனங்கள் கடினமான காலங்களை கடந்து செல்பவர்களுக்கு நம்பிக்கையையும் ஊக்கத்தையும் அளிக்கும் வல்லமை கொண்டவை.
	அவர்கள் ஆறுதல் மற்றும் உத்தரவாத வார்த்தைகளை வழங்குகிறார்கள், தனிநபர்கள் தங்கள் போராட்டங்களில் அவர்கள் தனியாக இல்லை என்பதை நினைவூட்டுகிறார்கள்.
		இந்த வசனங்களை மற்றவர்களுடன் பகிர்ந்துகொள்வது நேர்மறையைப் பரப்புவதற்கும் தேவைப்படுபவர்களை உயர்த்துவதற்கும் ஒரு சக்திவாய்ந்த வழியாகும்.
	</p>
</ul>
<p>
	தமிழ் வேதாகம வசனங்கள் ஆன்மீக ஞானம், பண்பாட்டு முக்கியத்துவம், மொழி அழகு ஆகியவற்றின் பொக்கிஷம். இதயங்களைத் தொடவும், வாழ்க்கையை மாற்றவும், வார்த்தைகளில் ஆறுதல் தேடுபவர்களுக்கு நம்பிக்கையைக் கொண்டு வரவும் அவை சக்தி வாய்ந்தவை. நீங்கள் தமிழ் பேசும் கிறிஸ்தவராக இருந்தாலும் சரி அல்லது மத நூல்களின் ஆழத்தை ஆராய்வதில் ஆர்வமுள்ளவராக இருந்தாலும் சரி, தமிழ் பைபிள் வசனங்கள் ஒரு தனித்துவமான மற்றும் செழுமையான அனுபவத்தை வழங்குகின்றன. இந்த வசனங்களின் சக்தியை ஆராய்ந்து, அவை நம் வாழ்க்கையில் ஏற்படுத்தும் ஆழமான தாக்கத்தை கண்டுபிடிப்போம்.
</p>



		</div>
		</>
	); 
}

export default BibleIndex;
