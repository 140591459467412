import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial31 = () => {
  const verseNumber = 31;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 31 </title>
	<meta
          name="description"
          content="Ezekiel 31 | எசேக்கியேல்  31 |
          Ezekiel 31 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பதினோராம் வருஷம் மூன்றாம் மாதம் முதலாந்தேதியிலே கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, எகிப்தின் ராஜாவாகிய பார்வோனோடும் அவனுடைய திரளான ஜனத்தோடும் நீ சொல்லவேண்டியது என்னவென்றால்: நீ உன் மகத்துவத்திலே யாருக்கு ஒப்பாயிருக்கிறாய்?
	<br /><br />
	3. இதோ, அசீரியன் லீபனோனிலே அலங்காரக் கொப்புகளோடும், நிழலிடும் தழைகளோடும், வளர்ந்தோங்கிய கேதுரு விருட்சமாயிருந்தான்; அதின் கிளைகளின் தழைகளுக்குள்ளே அதின் நுனிக்கொழுந்து உயர்ந்திருந்தது.
	<br /><br />
	4. தண்ணீர்கள் அதைப் பெரிதும், ஆழம் அதை உயர்த்தியும் ஆக்கின; அதின் ஆறுகள் அதின் அடிமரத்தைச் சுற்றிலும் ஓடின; தன் நீர்க்கால்களை வெளியின் விருட்சங்களுக்கெல்லாம் பாயவிட்டது.
	<br /><br />
	5. ஆகையால் வெளியின் சகல விருட்சங்களிலும் அது மிகவும் உயர்ந்தது; அது துளிர்விடுகையில் திரளான தண்ணீரினால் அதின் கிளைகள் பெருகி, அதின் கொப்புகள் நீளமாயின.
	<br /><br />
	6. அதின் கொப்புகளில் ஆகாயத்தின் பறவைகளெல்லாம் கூடுகட்டின; அதின் கொப்புகளின்கீழ் வெளியின் மிருகங்களெல்லாம் குட்டிகளைப்போட்டன; பெரிதான சகல ஜாதிகளும் அதின் நிழலிலே குடியிருந்தார்கள்.
	<br /><br />
	7. அப்படியே அதின் வேர் திரளான தண்ணீர்களருகே இருந்ததினால் அது தன் செழிப்பினாலும் தன் கொப்புகளின் நீளத்தினாலும் அலங்காரமாயிருந்தது.
	<br /><br />
	8. தேவனுடைய வனத்திலுள்ள கேதுருக்கள் அதை மறைக்கக்கூடாதிருந்தது; தேவதாரு விருட்சங்கள் அதின் கொப்புகளுக்குச் சமானமல்ல; அர்மோன் மரங்கள் அதின் கிளைகளுக்கு நிகரல்ல; தேவனுடைய வனத்திலுள்ள ஒரு விருட்சமும் அலங்காரத்திலே அதற்கு ஒப்பல்ல.
	<br /><br />
	9. அதின் கிளைகளின் திரளினால் அதை அலங்கரித்தேன்; தேவனுடைய வனமாகிய ஏதேனின் விருட்சங்களெல்லாம் அதின்பேரில் பொறாமைகொண்டன.
	<br /><br />
	10. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: அது தன் வளர்த்தியிலே மேட்டிமையாகி, கொப்புகளின் தழைகளுக்குள்ளே தன் நுனிக்கிளையை ஓங்கவிட்டபடியினாலும், அதின் இருதயம் தன் மேட்டிமையினால் உயர்ந்துபோனபடியினாலும்,
	<br /><br />
	11. நான் அதை ஜாதிகளில் மகா வல்லமையுள்ளவன் கையிலே ஒப்புக்கொடுத்தேன்; அவன் தனக்கு இஷ்டமானபடி அதற்குச் செய்வான்; அதினுடைய அக்கிரமத்தினிமித்தம் அதைத் தள்ளிப்போட்டேன்.
	<br /><br />
	12. ஜாதிகளில் வல்லவராகிய அந்நியதேசத்தார் அதை வெட்டிப்போட்டு, விட்டுப்போனார்கள்; அதின் கொப்புகள் மலைகளின்மேலும் சகல பள்ளத்தாக்குகளிலும் விழுந்தன; அதின் கிளைகள் தேசத்தினுடைய எல்லா ஆறுகளினருகே முறிந்தன; பூமியிலுள்ள ஜனங்களெல்லாரும் அதின் நிழலைவிட்டுக் கலைந்துபோனார்கள்.
	<br /><br />
	13. விழுந்துகிடக்கிற அதின்மேல் ஆகாயத்துப் பறவைகளெல்லாம் தாபரித்தன; அதின் கொம்புகளின்மேல் வெளியின் மிருகங்களெல்லாம் தங்கின.
	<br /><br />
	14. தண்ணீரின் ஓரமாய் வளருகிற எந்த விருட்சங்களும் தங்கள் உயர்த்தியினாலே மேட்டிமைகொள்ளாமலும், தங்கள் கொப்புகளின் தழைக்குள்ளே தங்கள் நுனிக்கிளையை ஓங்கவிடாமலும், தண்ணீரைக் குடிக்கிற எந்த மரங்களும் தங்கள் உயர்த்தியினாலே தங்கள்மேல் நம்பிக்கைவைக்காமலும் இருக்கும்பொருட்டு இப்படிச் செய்வேன்; மனுபுத்திரரின் நடுவே அவர்கள் எல்லாரும் குழியில் இறங்குகிறவர்களோடேகூட மரணத்துக்கு ஒப்புக்கொடுக்கப்பட்டு, பூமியின் தாழ்விடங்களில் போனார்கள்.
	<br /><br />
	15. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: அவன் பாதாளத்தில் இறங்குகிற நாளிலே புலம்பலை வருவித்தேன்; நான் அவனிமித்தம் ஆழத்தை மூடிப்போட்டு, திரளான தண்ணீர்கள் ஓடாதபடிக்கு அதின் ஆறுகளை அடைத்து, அவனிமித்தம் லீபனோனை இருளடையப்பண்ணினேன்; வெளியின் விருட்சங்களெல்லாம் அவனிமித்தம் பட்டுப்போயின.
	<br /><br />
	16. நான் அவனைக் குழியில் இறங்குகிறவர்களோடேகூடப் பாதாளத்தில் இறங்கப்பண்ணுகையில், அவன் விழுகிற சத்தத்தினால் ஜாதிகளை அதிரப்பண்ணினேன்; அப்பொழுது பூமியின் தாழ்விடங்களில் ஏதேனின் விருட்சங்களும், லீபனோனின் மேன்மையான சிறந்த விருட்சங்களும், தண்ணீர் குடிக்கும் சகல மரங்களும் ஆறுதல் அடைந்தன.
	<br /><br />
	17. அவனோடேகூட இவர்களும், ஜாதிகளின் நடுவே அவன் நிழலில் குடியிருந்து அவனுக்குப் புயபலமாயிருந்தவர்களும், பட்டயத்தால் வெட்டுண்டவர்களண்டையிலே பாதாளத்தில் இறங்கினார்கள்.
	<br /><br />
	18. இப்படிப்பட்ட மகிமையிலும் மகத்துவத்திலும் ஏதேனின் விருட்சங்களில் நீ எதற்கு ஒப்பானவன்? ஏதேனின் விருட்சங்களோடேகூட நீயும் பூமியின் தாழ்விடங்களில் இறக்கப்பட்டு, பட்டயத்தாலே வெட்டுண்டவர்களோடேகூட விருத்தசேதனமில்லாதவர்களின் நடுவிலே கிடப்பாய்; பார்வோனும் அவன் கூட்டமும் இதுவே என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial31