import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial45 = () => {
  const verseNumber = 45;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 45 </title>
	<meta
          name="description"
          content="Ezekiel 45 | எசேக்கியேல்  45 |
          Ezekiel 45 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீங்கள் சுதந்தரித்துக்கொள்ளும்படி தேசத்தைச் சீட்டுப்போட்டுப் பங்கிடும்போது, தேசத்தில் இருபத்தையாயிரங்கோல் நீளமும், பதினாயிரங்கோல் அகலமுமான பரிசுத்த பங்கைக் கர்த்தருக்கு அர்ப்பிதமாகப் பிரித்து வைக்கக்கடவீர்கள்; இது தன் சுற்றெல்லை எங்கும் பரிசுத்தமாயிருக்கும்.
	<br /><br />
	2. இதிலே பரிசுத்த ஸ்தலத்துக்கென்று ஐந்நூறு கோல் நீளமும் ஐந்நூறு கோல் அகலமுமான நாற்சதுரம் அளக்கப்படக்கடவது; அதற்குச் சுற்றிலும் ஐம்பது முழமான வெளிநிலம் இருக்கவேண்டும்.
	<br /><br />
	3. இந்த அளவு உட்பட இருபத்தையாயிரங்கோல் நீளத்தையும் பதினாயிரங்கோல் அகலத்தையும் அளப்பாயாக; அதற்குள் பரிசுத்த ஸ்தலமும் மகா பரிசுத்த ஸ்தலமும் இருக்கவேண்டும்.
	<br /><br />
	4. தேசத்தில் பரிசுத்த பங்காகிய இது கர்த்தருக்கு ஆராதனைசெய்யச் சேருகிறவர்களும், பரிசுத்த ஸ்தலத்தில் ஆராதனை செய்கிறவர்களுமான ஆசாரியருடையது; இது அவர்களுக்கு வீடுகளுக்கான இடமும், பரிசுத்த ஸ்தலத்துக்கு அடுத்த இடமுமாயிருக்கவேண்டும்.
	<br /><br />
	5. பின்னும் இருபத்தையாயிரங்கோல் நீளமும் பதினாயிரங்கோல் அகலமுமான இடம் ஆலயத்தின் பணிவிடைக்காரராகிய லேவியருடையதாயிருக்கும்; அது அவர்களுடைய காணியாட்சி; அதில் இருபது அறைவீடுகள் இருக்கவேண்டும்.
	<br /><br />
	6. பரிசுத்த பங்காகப் படைக்கப்பட்டதற்கு எதிரே நகரத்தின் காணியாட்சியாக ஐயாயிரங்கோல் அகலத்தையும் இருபத்தையாயிரங்கோல் நீளத்தையும் அளந்து கொடுப்பீர்களாக; அது இஸ்ரவேல் வம்சத்தார் அனைவருக்கும் சொந்தமாயிருக்கும்.
	<br /><br />
	7. பரிசுத்த பங்காகப் படைக்கப்பட்டதற்கும் நகரத்தின் காணியாட்சிக்கும் இந்தப்புறத்திலும் அந்தப்புறத்திலும், பரிசுத்த படைப்புக்கு முன்பாகவும், நகரத்தின் காணிக்கு முன்பாகவும், அதிபதியினுடைய பங்கு மேற்கிலே மேற்புறமாகவும் கிழக்கிலே கீழ்ப்புறமாகவும் இருப்பதாக; அதின் நீளம் மேல் எல்லை துவக்கிக் கீழ் எல்லைமட்டும் பங்குகளில் ஒவ்வொன்றுக்கும் எதிராயிருக்கவேண்டும்.
	<br /><br />
	8. இது அவனுக்கு இஸ்ரவேலிலே காணிபூமியாக இருக்கக்கடவது; என் அதிபதிகள் இனி என் ஜனத்தை ஒடுக்காமல் தேசத்தை இஸ்ரவேல் வம்சத்தாருக்கு அவர்களுடைய கோத்திரங்களுக்குத்தக்கதாக விட்டுவிடுவார்களாக.
	<br /><br />
	9. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இஸ்ரவேலின் அதிபதிகளே, நீங்கள் செய்ததுபோதும்; நீங்கள் கொடுமையையும் கொள்ளையிடுதலையும் தவிர்த்து, நியாயத்தையும் நீதியையும் செய்யுங்கள்; உங்கள் உத்தண்டங்களை என் ஜனத்தைவிட்டு அகற்றுங்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	10. சுமுத்திரையான தராசும், சுமுத்திரையான மரக்காலும், சுமுத்திரையான அளவுகுடமும் உங்களுக்கு இருக்கக்கடவது.
	<br /><br />
	11. மரக்காலும் அளவுகுடமும் ஒரே அளவாயிருந்து, மரக்கால் கலத்திலே பத்தில் ஒரு பங்கும், அளவுகுடம் கலத்திலே பத்தில் ஒரு பங்கும் பிடிக்கக்கடவது; கலத்தின்படியே அதின் அளவு நிருணயிக்கப்படுவதாக.
	<br /><br />
	12. சேக்கலானது இருபது கேரா; இருபது சேக்கலும் இருபத்தைந்து சேக்கலும் பதினைந்து சேக்கலும் உங்களுக்கு ஒரு இராத்தலாகும்.
	<br /><br />
	13. நீங்கள் செலுத்தவேண்டிய காணிக்கையாவது: ஒரு கலம் கோதுமையிலே ஒரு மரக்காலில் ஆறிலொரு பங்கையும், ஒரு கலம் வாற்கோதுமையிலே ஒரு மரக்காலில் ஆறிலொருபங்கையும் படைக்கக்கடவீர்கள்.
	<br /><br />
	14. அளவுகுடத்தால் அளக்கிற எண்ணெயின் கட்டளையாவது: பத்துக்குடம் பிடிக்கிற கலத்துக்குச் சரியான ஒரு ஜாடி எண்ணெயிலே பத்தில் ஒரு பங்கைப் படைப்பீர்களாக; பத்து அளவுகுடம் ஒரு கலமாகும்.
	<br /><br />
	15. இஸ்ரவேல் தேசத்திலே நல்லமேய்ச்சலை மேய்கிற மந்தையிலே இருநூறு ஆடுகளில் ஒரு ஆடும், அவர்களுடைய பாவநிவாரணத்திற்காகப் போஜனபலியாகவும் தகனபலியாகவும் சமாதானபலியாகவும் செலுத்தப்படக்கடவதென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	16. இஸ்ரவேலின் அதிபதிக்கு முன்பாகத் தேசத்தின் ஜனங்களெல்லாரும் இந்தக் காணிக்கையைச் செலுத்தக் கடனாளிகளாயிருக்கிறார்கள்.
	<br /><br />
	17. இஸ்ரவேல் வம்சத்தார் கூடிவரக் குறிக்கப்பட்ட சகல பண்டிகைகளிலும் மாதப்பிறப்புகளிலும் ஓய்வுநாட்களிலும் தகனபலிகளையும் போஜனபலிகளையும் பானபலிகளையும் செலுத்துவது அதிபதியின்மேல் சுமந்த கடனாயிருக்கும்; அவன் இஸ்ரவேல் வம்சத்தாருக்காகப் பாவநிவாரணம்பண்ணும்படிக்குப் பாவநிவாரணபலியையும் போஜனபலியையும் தகனபலியையும் சமாதானபலியையும் படைப்பானாக.
	<br /><br />
	18. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: முதலாம் மாதம் முதலாந்தேதியிலே நீ பழுதற்ற ஒரு காளையைக் கொண்டுவந்து, பரிசுத்த ஸ்தலத்துக்குப் பாவநிவிர்த்தி செய்வாயாக.
	<br /><br />
	19. பாவநிவாரணபலியின் இரத்தத்திலே கொஞ்சம் ஆசாரியன் எடுத்து, ஆலயத்தின் வாசல் நிலைகளிலும், பலிபீடத்துச் சட்டத்தின் நாலு கோடிகளிலும், உட்பிராகாரத்தின் வாசல்நிலைகளிலும் பூசக்கடவன்.
	<br /><br />
	20. பிழைசெய்தவனுக்காகவும், அறியாமல் தப்பிதம் செய்தவனுக்காகவும் அந்தப்பிரகாரமாக ஏழாந்தேதியிலும் செய்வாயாக; இவ்விதமாய் ஆலயத்துக்குப் பாவநிவர்த்தி செய்வாயாக.
	<br /><br />
	21. முதலாம் மாதம் பதினாலாந்தேதியிலே புளிப்பில்லாத அப்பம் புசிக்கப்படுகிற ஏழுநாள் பண்டிகையாகிய பஸ்கா ஆரம்பமாகும்.
	<br /><br />
	22. அந்நாளிலே அதிபதி தன்னிமித்தமும் தேசத்து எல்லா ஜனங்களிநிமித்தமும் பாவநிவாரணத்துக்காக ஒரு காளையைப் படைப்பானாக.
	<br /><br />
	23. ஏழுநாள் பண்டிகையில், அவன் அந்த ஏழுநாளும் தினந்தோறும் கர்த்தருக்குத் தகனபலியாகப் பழுதற்ற ஏழு காளைகளையும் ஏழு ஆட்டுக்கடாக்களையும், பாவநிவாரணபலியாக ஒரு வெள்ளாட்டுக்கடாவையும் தினந்தோறும் படைப்பானாக.
	<br /><br />
	24. ஒவ்வொரு காளையோடே ஒரு மரக்கால் மாவும் ஒவ்வொரு ஆட்டுக்கடாவோடே ஒரு மரக்கால் மாவுமான போஜனபலியையும், ஒவ்வொரு மரக்கால் மாவோடே ஒருபடி எண்ணெயையும் படைப்பானாக.
	<br /><br />
	25. ஏழாம் மாதம் பதினைந்தாந்தேதியில் ஆரம்பமாகிற பண்டிகையிலே அவன் அப்படியே ஏழுநாளும் அதற்குச் சரியானபிரகாரமாகப் பாவநிவாரணபலிகளையும் தகனபலிகளையும் போஜனபலிகளையும், எண்ணெயையும் படைக்கக்கடவன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial45