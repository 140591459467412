import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 2 </title>
	<meta
          name="description"
          content="Micah 2 | மீகா 2 | Meega 2|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்கிரமத்தை யோசித்து, தங்கள் படுக்கைகளின்மேல் பொல்லாப்பு செய்ய எத்தனம்பண்ணித் தங்கள் கையில் வல்லமை இருக்கிறபடியினால், விடியற்காலமாகிறபோது அதை நடப்பித்து,
	<br /><br />
	2. வயல்களை இச்சித்துப் பறித்துக்கொண்டு, வீடுகளை இச்சித்து எடுத்துக்கொண்டு, புருஷனையும் அவன் வீட்டையும், மனுஷனையும் அவன் சுதந்தரத்தையும் ஒடுக்குகிறவர்களுக்கு ஐயோ!
	<br /><br />
	3. ஆகையால் கர்த்தர்: நான் இந்த வம்சத்தாருக்கு விரோதமாய்த் தீமையை வருவிக்க நினைக்கிறேன்; அதினின்று நீங்கள் கழுத்தை நீக்கமாட்டீர்கள்; நீங்கள் மேட்டிமையாய் நடப்பதில்லை; அது தீமையான காலம்.
	<br /><br />
	4. அந்நாளில் உங்கள்பேரில் ஒப்புச்சொல்லி, நாம் முற்றிலும் பாழானோம்; நமது ஜனத்தின் சுதந்தரத்தை மாற்றிப்போட்டார்; என்னமாய் அதை என்னைவிட்டு நீக்கிப்போட்டார்! நமது வயல்களைப் பிடுங்கிப் பகிர்ந்துகொடுத்தாரே என்று துயரமான புலம்பலாய்ப் புலம்புவார்கள்.
	<br /><br />
	5. கர்த்தரின் சபையில் சுதந்தர வீதங்களை அளந்துகொடுக்கிறவர்கள் உனக்கு இல்லாதிருப்பார்கள்.
	<br /><br />
	6. தீர்க்கதரிசனஞ் சொல்லாதிருப்பீர்களாக என்கிறார்கள்; அவர்கள் தீர்க்கதரிசனஞ் சொல்லுவார்கள், இந்தப்பிரகாரமாய் அவர்கள் தீர்க்கதரிசனஞ் சொல்லாவிட்டால் நிந்தை நீங்காதே.
	<br /><br />
	7. யாக்கோபு வம்சம் என்று பேர்பெற்றவர்களே, கர்த்தரின் ஆவி குறுகியிருக்கிறதோ? அவருடைய கிரியைகள் இவைகள்தானோ? செம்மையாய் நடக்கிறவனுக்கு என் வார்த்தைகள் நன்மை செய்யாதோ?
	<br /><br />
	8. என் ஜனங்கள் பூர்வமுதல் சத்துருவைப்போல் எழும்பினார்கள். யுத்தத்திலிருந்து திரும்பிவந்து வழியில் அஞ்சாமல் கடந்துபோகிறவர்களுடைய மேலங்கியையும் வஸ்திரத்தையும் உரிந்துகொண்டீர்கள்.
	<br /><br />
	9. என் ஜனத்தின் ஸ்திரீகளை அவர்களுடைய சௌக்கியமான வீடுகளிலிருந்து துரத்திவிட்டீர்கள்; அவர்களுடைய குழந்தைகளுக்கு இருந்த என் அலங்காரத்தை என்றைக்கும் இல்லாதபடிக்குப் பறித்துக்கொண்டீர்கள்.
	<br /><br />
	10. எழுந்திருந்து போங்கள்; இது நீங்கள் இளைப்பாறும் இடம் அல்ல, இது தீட்டுப்பட்டது, இது உங்களை நாசப்படுத்தும், அந்த நாசம் மிகவும் கொடியதாயிருக்கும்.
	<br /><br />
	11. மனம்போகிற போக்கின்படிபோய், அபத்தமானதை உரைக்கிற ஒருவன், திராட்சரசத்தையும் மதுபானத்தையுங்குறித்து நான் உனக்குப் பிரசங்கிப்பேனென்றால், அவனே இந்த ஜனத்திற்கு ஏற்ற பிரசங்கியாயிருப்பான்.
	<br /><br />
	12. யாக்கோபின் ஜனங்களே, உங்களெல்லாரையும் நான் நிச்சயமாய்க் கூட்டுவேன், இஸ்ரவேலில் மீதியானவர்களை நிச்சயமாய்ச் சேர்ப்பேன்; போஸ்றாவின் ஆடுகளைப்போல் அவர்களை ஏகக்கூட்டமாக்குவேன்; தன் தொழுவத்துக்குள்ளே சேர்ந்த மந்தைக்குச் சமானமாய் ஜனத்திரளினாலே இரைச்சல் உண்டாகும்.
	<br /><br />
	13. தடைகளை நீக்கிப்போடுகிறவர் அவர்களுக்கு முன்பாக நடந்துபோகிறார்; அவர்கள் தடைகளை நீக்கி, வாசலால் உட்பிரவேசித்துக் கடந்துபோவார்கள்; அவர்கள் ராஜா அவர்களுக்கு முன்பாகப் போவார், கர்த்தர் அவர்கள் முன்னணியில் நடந்துபோவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah2