import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 3 </title>
	<meta
          name="description"
          content="Isaiah 3 | ஏசாயா 3 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இதோ, சேனைகளின் கர்த்தராகிய ஆண்டவர் எருசலேமிலிருந்தும் யூதாவிலிருந்தும் சகலவிதமான ஆதரவுகளாகிய அப்பமென்கிற எல்லா ஆதரவையும், தண்ணீரென்கிற எல்லா ஆதரவையும்;
	<br /><br />
	2. பராக்கிரமசாலியையும், யுத்தவீரனையும், நியாயாதிபதியையும், தீர்க்கதரிசியையும், சாஸ்திரியையும், மூப்பனையும்;
	<br /><br />
	3. ஐம்பதுபேருக்கு அதிபதியையும், கனம்பொருந்தினவனையும், ஆலோசனைக்காரனையும், தொழில்களில் சாமர்த்தியமுள்ளவனையும், சாதுரியனையும் விலக்குவார்.
	<br /><br />
	4. வாலிபரை அவர்களுக்கு அதிபதிகளாகத் தருவேன் என்கிறார்; பிள்ளைகள் அவர்களை ஆளுவார்கள்.
	<br /><br />
	5. ஜனங்கள் ஒடுக்கப்படுவார்கள்; ஒருவருக்கொருவரும், அயலானுக்கு அயலானும் விரோதமாயிருப்பார்கள்; வாலிபன் முதிர்வயதுள்ளவனுக்கும், கீழ்மகன் மேன்மகனுக்கும் இடும்பு செய்வான்.
	<br /><br />
	6. அப்பொழுது ஒருவன் தன் தகப்பன் வீட்டானாகிய தன் சகோதரனைப் பிடித்து: உனக்கு வஸ்திரம் இருக்கிறது, நீ எங்களுக்கு அதிபதியாயிரு; கேட்டுக்கு இனமான இந்தக் காரியம் உன் கையின் கீழாவதாக என்று சொல்ல;
	<br /><br />
	7. அவன் அந்நாளிலே தன் கையை உயர்த்தி: நான் சீர்ப்படுத்துகிறவனாயிருக்கமாட்டேன்; என் வீட்டிலே அப்பமுமில்லை, வஸ்திரமுமில்லை; என்னை ஜனங்களுக்கு அதிபதியாக வைக்கவேண்டாம் என்பான்.
	<br /><br />
	8. ஏனென்றால் எருசலேம் பாழாக்கப்பட்டது, யூதா விழுந்துபோயிற்று; அவர்கள் நாவும், அவர்கள் கிரியைகளும், கர்த்தருடைய மகிமையின் கண்களுக்கு எரிச்சல் உண்டாக்கத்தக்கதாக அவருக்கு விரோதமாயிருக்கிறது.
	<br /><br />
	9. அவர்கள் முகப்பார்வை அவர்களுக்கு விரோதமாய்ச் சாட்சியிடும்; அவர்கள் தங்கள் பாவத்தை மறைக்காமல், சோதோம் ஊராரைப்போல வெளிப்படுத்துகிறார்கள்; அவர்கள் ஆத்துமாவுக்கு ஐயோ! தங்களுக்கே தீமையை வருவித்துக்கொள்ளுகிறார்கள்.
	<br /><br />
	10. உங்களுக்கு நன்மையுண்டாகும் என்று நீதிமான்களுக்குச் சொல்லுங்கள்; அவர்கள் தங்கள் கிரியைகளின் பலனை அநுபவிப்பார்கள்.
	<br /><br />
	11. துன்மார்க்கனுக்கு ஐயோ! அவனுக்குக் கேடு உண்டாகும்; அவன் கைகளின் பலன் அவனுக்குக் கிடைக்கும்.
	<br /><br />
	12. பிள்ளைகள் என் ஜனங்களை ஒடுக்குகிறவர்களாயிருக்கிறார்கள்; ஸ்திரீகள் அவர்களை ஆளுகிறார்கள். என் ஜனமே, உன்னை நடத்துகிறவர்கள் உன்னை மோசம்போக்கி, நீ நடக்கவேண்டிய வழியை அழித்துப்போடுகிறார்கள்.
	<br /><br />
	13. கர்த்தர் வழக்காட எழுந்திருந்து, ஜனங்களை நியாயந்தீர்க்க நிற்கிறார்.
	<br /><br />
	14. கர்த்தர் தமது ஜனத்தின் மூப்பரையும், அதின் பிரபுக்களையும் நியாயம் விசாரிப்பார். நீங்களே இந்தத் திராட்சத்தோட்டத்தைப் பட்சித்துப்போட்டீர்கள்; சிறுமையானவனிடத்தில் கொள்ளையிட்ட பொருள் உங்கள் வீடுகளில் இருக்கிறது.
	<br /><br />
	15. நீங்கள் என் ஜனத்தை நொறுக்கிச் சிறுமையானவர்களின் முகத்தை நெரிக்கிறது என்னவென்று சேனைகளின் கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	16. பின்னும் கர்த்தர் சொல்லுகிறதாவது: சீயோன் குமாரத்திகள் அகந்தையாயிருந்து, கழுத்தை நெறித்து நடந்து, கண்களால் மருட்டிப்பார்த்து, ஒய்யாரமாய் நடந்து, தங்கள் கால்களில் சிலம்பு ஒலிக்கத் திரிகிறார்கள்.
	<br /><br />
	17. ஆதலால் ஆண்டவர் சீயோன் குமாரத்திகளின் உச்சந்தலையை மொட்டையாக்குவார்; கர்த்தர் அவர்கள் மானத்தைக் குலைப்பார்.
	<br /><br />
	18. அந்நாளிலே ஆண்டவர் அவர்களுடைய ஆபரணங்களாகிய சிலம்புகளையும், சுட்டிகளையும், பிறைச்சிந்தாக்குகளையும்,
	<br /><br />
	19. ஆரங்களையும், அஸ்தகடகங்களையும், தலைமுக்காடுகளையும்,
	<br /><br />
	20. சிரபூஷணங்களையும், பாதசரங்களையும், மார்க்கச்சைகளையும், சுகந்தபரணிகளையும்,
	<br /><br />
	21. தாயித்துகளையும், மோதிரங்களையும், மூக்குத்திகளையும்,
	<br /><br />
	22. விநோத வஸ்திரங்களையும், சால்வைகளையும், போர்வைகளையும், குப்பிகளையும்,
	<br /><br />
	23. கண்ணாடிகளையும், சல்லாக்களையும், குல்லாக்களையும், துப்பட்டாக்களையும் உரிந்துபோடுவார்.
	<br /><br />
	24. அப்பொழுது, சுகந்தத்துக்குப் பதிலாகத் துர்க்கந்தமும், கச்சைக்குப் பதிலாகக் கயிறும், மயிர்ச்சுருளுக்குப் பதிலாக மொட்டையும், ஆடம்பரமான வஸ்திரங்களுக்குப் பதிலாக இரட்டுக்கச்சும், அழகுக்குப் பதிலாகக் கருகிப்போகுதலும் இருக்கும்.
	<br /><br />
	25. உன் புருஷர் கட்கத்தினாலும், உன் பெலசாலிகள் யுத்தத்திலும் விழுவார்கள்.
	<br /><br />
	26. அவளுடைய வாசல்கள் துக்கித்துப் புலம்பும்; அவள் வெறுமையாக்கப்பட்டுத் தரையிலே உட்காருவாள் என்கிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah3