import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 8 | Tamil Bible Verse | வெளிப்படுத்தல் - 8 </title>
	<meta
          name="description"
          content="Book of Revelation Chapter 8 | வெளிப்படுத்தின விசேஷம்  | அதிகாரம்-8 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஏழாவது முத்திரையுடன் அமைதி நிலவியது. நான் ஏழு தேவதூதர்களைக் கண்டேன். நான்கு தேவதூதர்கள் தங்கள் எக்காளங்களை ஊதினார்கள், பூமியின் மூன்றில் ஒரு பங்கு அழிக்கப்பட்டது. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. அவர் ஏழாம் முத்திரையை உடைத்தபோது, பரலோகத்தில் ஏறக்குறைய அரைமணிநேரமளவும் அமைதல் உண்டாயிற்று.
	<br /><br />
	2. பின்பு, தேவனுக்குமுன்பாக நிற்கிற ஏழு தூதர்களையுங் கண்டேன்; அவர்களுக்கு ஏழு எக்காளங்கள் கொடுக்கப்பட்டது.
	<br /><br />
	3. வேறொரு தூதனும் வந்து, தூபங்காட்டும் பொற்கலசத்தைப் பிடித்துப் பலிபீடத்தின் படியிலே நின்றான்; சிங்காசனத்திற்குமுன்பாக இருந்த பொற்பீடத்தின்மேல் சகல பரிசுத்தவான்களுடைய ஜெபங்களோடும் செலுத்தும்படி மிகுந்த தூபவர்க்கம் அவனுக்குக் கொடுக்கப்பட்டது.
	<br /><br />
	4. அப்படியே பரிசுத்தவான்களுடைய ஜெபங்களோடும் செலுத்தப்பட்ட தூபவர்க்கத்தின் புகையானது தூதனுடைய கையிலிருந்து தேவனுக்குமுன்பாக எழும்பிற்று.
	<br /><br />
	5. பின்பு, அந்தத் தூதன் தூபகலசத்தை எடுத்து, அதைப் பலிபீடத்து நெருப்பினால் நிரப்பி, பூமியிலே கொட்டினான்; உடனே சத்தங்களும், இடிமுழக்கங்களும், மின்னல்களும், பூமியதிர்ச்சியும் உண்டாயின.
	<br /><br />
	6. அப்பொழுது, ஏழு எக்காளங்களையுடைய ஏழு தூதர்கள் எக்காளம் ஊதுகிறதற்குத் தங்களை ஆயத்தப்படுத்தினார்கள்.
	<br /><br />
	7. முதலாம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது இரத்தங்கலந்த கல்மழையும் அக்கினியும் உண்டாகி, பூமியிலே கொட்டப்பட்டது; அதினால் மரங்களில் மூன்றிலொருபங்கு வெந்துபோயிற்று, பசும்புல்லெல்லாம் எரிந்துபோயிற்று.
	<br /><br />
	8. இரண்டாம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது அக்கினியால் எரிகிற பெரிய மலைபோன்றதொன்று சமுத்திரத்திலே போடப்பட்டது; அதினால் சமுத்திரத்தில் மூன்றிலொருபங்கு இரத்தமாயிற்று.
	<br /><br />
	9. சமுத்திரத்திலிருந்த ஜீவனுள்ள சிருஷ்டிகளில் மூன்றிலொருபங்கு செத்துப்போயிற்று; கப்பல்களில் மூன்றிலொருபங்கு சேதமாயிற்று.
	<br /><br />
	10. மூன்றாம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது ஒரு பெரிய நட்சத்திரம் தீவட்டியைப்போல எரிந்து, வானத்திலிருந்து விழுந்தது; அது ஆறுகளில் மூன்றிலொருபங்கின்மேலும், நீரூற்றுகளின்மேலும் விழுந்தது.
	<br /><br />
	11. அந்த நட்சத்திரத்திற்கு எட்டியென்று பெயர்; அதினால் தண்ணீரில் மூன்றிலொருபங்கு எட்டியைப்போலக் கசப்பாயிற்று; இப்படிக் கசப்பான தண்ணீரினால் மனுஷரில் அநேகர் செத்தார்கள்.
	<br /><br />
	12. நான்காம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது சூரியனில் மூன்றிலொரு பங்கும், சந்திரனில் மூன்றிலொருபங்கும், நட்சத்திரங்களில் மூன்றிலொருபங்கும் சேதப்பட்டது, அவற்றவற்றில் மூன்றிலொருபங்கு இருளடைந்தது; பகலிலும் மூன்றிலொருபங்கு பிரகாசமில்லாமற்போயிற்று, இரவிலும் அப்படியேயாயிற்று.
	<br /><br />
	13. பின்பு, ஒரு தூதன் வானத்தின் மத்தியிலே பறந்துவரக்கண்டேன்; அவன் மகா சத்தமிட்டு: இனி எக்காளம் ஊதப்போகிற மற்ற மூன்று தூதருடைய எக்காளசத்தங்களினால் பூமியில் குடியிருக்கிறவர்களுக்கு ஐயோ, ஐயோ, ஐயோ, (ஆபத்துவரும்) என்று சொல்லக்கேட்டேன்.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* ஏழாவது முத்திரையுடன் அமைதி நிலவியது. நான் ஏழு தேவதைகளைக் கண்டேன். நான்கு தேவதூதர்கள் தங்கள் எக்காளங்களை ஊதினார்கள், பூமியின் மூன்றில் ஒரு பங்கு அழிக்கப்பட்டது. *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation8