import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 7 </title>
	<meta
          name="description"
          content="Micah 7 | மீகா 7 | Meega 7|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஐயோ! உஷ்ணகாலத்துக் கனிகளைச் சேர்த்து, திராட்சப்பழங்களை அறுத்தபின்பு வருகிறவனைப்போல் இருக்கிறேன்; புசிக்கிறதற்கு ஒரு திராட்சக்குலையும் என் ஆத்துமா இச்சித்த முதல் அறுப்பின் கனியும் இல்லை.
	<br /><br />
	2. தேசத்தில் பக்தியுள்ளவன் அற்றுப்போனான்; மனுஷரில் செம்மையானவன் இல்லை; அவர்களெல்லாரும் இரத்தஞ்சிந்தப் பதிவிருக்கிறார்கள்; அவனவன் தன்தன் சகோதரனை வலையிலே பிடிக்க வேட்டையாடுகிறான்.
	<br /><br />
	3. பொல்லாப்புச் செய்ய அவர்கள் இரண்டு கைகளும் நன்றாய்க் கூடும்; அதிபதி கொடு என்கிறான்; நியாயாதிபதி கைக்கூலி கேட்கிறான்; பெரியவன் தன் துராசையைத் தெரிவிக்கிறான்; இவ்விதமாய்ப் புரட்டுகிறார்கள்.
	<br /><br />
	4. அவர்களில் நல்லவன் முட்செடிக்கொத்தவன், செம்மையானவன் நெரிஞ்சிலைப்பார்க்கிலும் கடுங்கூர்மையானவன்; உன் காவற்காரர் அறிவித்த உன் தண்டனையின் நாள் வருகிறது; இப்பொழுதே அவர்களுக்குக் கலக்கம் உண்டு.
	<br /><br />
	5. சிநேகிதனை விசுவாசிக்கவேண்டாம், வழிகாட்டியை நம்பவேண்டாம்; உன் மடியிலே படுத்துக்கொள்ளுகிறவளுக்கு முன்பாக உன் வாயைத் திறவாமல் எச்சரிக்கையாயிரு.
	<br /><br />
	6. மகன் தகப்பனைக் கனவீனப்படுத்துகிறான்; மகள் தன் தாய்க்கு விரோதமாகவும், மருமகள் தன் மாமிக்கு விரோதமாகவும் எழும்புகிறார்கள்; மனுஷனுடைய சத்துருக்கள் அவன் வீட்டார்தானே.
	<br /><br />
	7. நானோவென்றால் கர்த்தரை நோக்கிக்கொண்டு, என் இரட்சிப்பின் தேவனுக்குக் காத்திருப்பேன்; என் தேவன் என்னைக் கேட்டருளுவார்.
	<br /><br />
	8. என் சத்துருவே, எனக்கு விரோதமாய்ச் சந்தோஷப்படாதே; நான் விழுந்தாலும் எழுந்திருப்பேன்; நான் இருளிலே உட்கார்ந்தால், கர்த்தர் எனக்கு வெளிச்சமாயிருப்பார்.
	<br /><br />
	9. நான் கர்த்தருக்கு விரோதமாகப் பாவஞ்செய்தேன்; அவர் எனக்காக வழக்காடி என் நியாயத்தை விசாரிக்குமட்டும் அவருடைய கோபத்தைச் சுமப்பேன்; அவர் என்னை வெளிச்சத்திலே கொண்டுவருவார், அவருடைய நீதியைப் பார்ப்பேன்.
	<br /><br />
	10. உன் தேவனாகிய கர்த்தர் எங்கே என்று என்னோடே சொல்லுகிற என் சத்துருவானவள் அதைப் பார்க்கும்போது வெட்கம் அவளை மூடும்; என் கண்கள் அவளைக் காணும்; இனி வீதிகளின் சேற்றைப்போல மிதிக்கப்படுவாள்.
	<br /><br />
	11. உன் மதில்களை எடுப்பிக்கும் நாள் வருகிறது; அந்நாளிலே பிரமாணம் வெகுதூரம் பரவிப்போம்.
	<br /><br />
	12. அந்நாளிலே அசீரியாமுதல் எகிப்தின் பட்டணங்கள்வரைக்கும், எகிப்துமுதல் நதிவரைக்கும், ஒரு சமுத்திரமுதல் மறு சமுத்திரம்வரைக்கும், ஒரு பர்வதமுதல் மறு பர்வதம்வரைக்குமுள்ள ஜனங்கள் உன்னிடத்திற்கு வருவார்கள்.
	<br /><br />
	13. ஆனாலும் தன் குடிகளினிமித்தமும் அவர்கள் கிரியைகளுடைய பலன்களினிமித்தமும் தேசம் பாழாயிருக்கும்.
	<br /><br />
	14. கர்மேலின் நடுவிலே தனித்து வனவாசமாயிருக்கிற உமது சுதந்தரமான மந்தையாகிய உம்முடைய ஜனத்தை உமது கோலினால் மேய்த்தருளும்; பூர்வநாட்களில் மேய்ந்ததுபோலவே அவர்கள் பாசானிலும் கீலேயாத்திலும் மேய்வார்களாக.
	<br /><br />
	15. நீ எகிப்து தேசத்திலிருந்து புறப்பட்டநாளில் நடந்ததுபோலவே உன்னை அதிசயங்களைக் காணப்பண்ணுவேன்.
	<br /><br />
	16. புறஜாதிகள் கண்டு தங்களுடைய எல்லாப் பராக்கிரமத்தையுங்குறித்து வெட்கப்படுவார்கள்; கையை வாயின்மேல் வைத்துக்கொள்வார்கள்; அவர்கள் காதுகள் செவிடாய்ப் போகும்.
	<br /><br />
	17. பாம்பைப்போல மண்ணை நக்குவார்கள்; பூமியின் ஊர்வனவற்றைப் போலத் தங்கள் மறைவிடங்களிலிருந்து நடுநடுங்கிப் புறப்படுவார்கள்; நமது தேவனாகிய கர்த்தரிடத்திற்குத் திகிலோடே சேர்ந்து, உனக்குப் பயப்படுவார்கள்.
	<br /><br />
	18. தமது சுதந்தரத்தில் மீதியானவர்களுடைய அக்கிரமத்தைப் பொறுத்து, மீறுதலை மன்னிக்கிற தேவரீருக்கு ஒப்பான தேவன் யார்? அவர் கிருபைசெய்ய விரும்புகிறபடியால் அவர் என்றென்றைக்கும் கோபம் வையார்.
	<br /><br />
	19. அவர் திரும்ப நம்மேல் இரங்குவார்; நம்முடைய அக்கிரமங்களை அடக்கி, நம்முடைய பாவங்களையெல்லாம் சமுத்திரத்தின் ஆழங்களில் போட்டுவிடுவார்.
	<br /><br />
	20. தேவரீர் பூர்வநாட்கள்முதல் எங்கள் பிதாக்களுக்கு ஆணையிட்ட சத்தியத்தை யாக்கோபுக்கும், கிருபையை ஆபிரகாமுக்கும் கட்டளையிடுவீராக.
</p>
<p>
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah7