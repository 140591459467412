import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew23 = () => {
  const verseNumber = 23;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 23 </title>
	<meta
          name="description"
          content="Matthew 23 | மத்தேயு 23 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு இயேசு ஜனங்களையும் தம்முடைய சீஷர்களையும் நோக்கி:
	<br /><br />
	2. வேதபாரகரும் பரிசேயரும் மோசேயினுடைய ஆசனத்தில் உட்கார்ந்திருக்கிறார்கள்;
	<br /><br />
	3. ஆகையால், நீங்கள் கைக்கொள்ளும்படி அவர்கள் உங்களுக்குச் சொல்லுகிற யாவையும் கைக்கொண்டு செய்யுங்கள்; அவர்கள் செய்கையின்படியோ செய்யாதிருங்கள்; ஏனெனில், அவர்கள் சொல்லுகிறார்கள், சொல்லியும் செய்யாதிருக்கிறார்கள்.
	<br /><br />
	4. சுமப்பதற்கரிய பாரமான சுமைகளைக்கட்டி மனுஷர் தோள்களின்மேல் சுமத்துகிறார்கள்; தாங்களோ ஒரு விரலினாலும் அவைகளைத் தொடமாட்டார்கள்.
	<br /><br />
	5. தங்கள் கிரியைகளையெல்லாம் மனுஷர் காணவேண்டுமென்று செய்கிறார்கள்; தங்கள் காப்புநாடாக்களை அகலமாக்கி, தங்கள் வஸ்திரங்களின் தொங்கல்களைப் பெரிதாக்கி,
	<br /><br />
	6. விருந்துகளில் முதன்மையான இடங்களையும், ஜெபஆலயங்களில் முதன்மையான ஆசனங்களையும்,
	<br /><br />
	7. சந்தைவெளிகளில் வந்தனங்களையும், மனுஷரால் ரபீ, ரபீ, என்று அழைக்கப்படுவதையும் விரும்புகிறார்கள்.
	<br /><br />
	8. நீங்களோ ரபீ என்றழைக்கப்படாதிருங்கள்; கிறிஸ்து ஒருவரே உங்களுக்குப் போதகராயிருக்கிறார், நீங்கள் எல்லாரும் சகோதரராயிருக்கிறீர்கள்.
	<br /><br />
	9. பூமியிலே ஒருவனையும் உங்கள் பிதா என்று சொல்லாதிருங்கள்; பரலோகத்திலிருக்கிற ஒருவரே உங்களுக்குப் பிதாவாயிருக்கிறார்.
	<br /><br />
	10. நீங்கள் குருக்கள் என்றும் அழைக்கப்படாதிருங்கள்; கிறிஸ்து ஒருவரே உங்களுக்குக் குருவாயிருக்கிறார்.
	<br /><br />
	11. உங்களில் பெரியவனாயிருக்கிறவன் உங்களுக்கு ஊழியக்காரனாயிருக்கக்கடவன்.
	<br /><br />
	12. தன்னை உயர்த்துகிறவன் தாழ்த்தப்படுவான், தன்னைத் தாழ்த்துகிறவன் உயர்த்தப்படுவான்.
	<br /><br />
	13. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, மனுஷர் பிரவேசியாதபடி பரலோகராஜ்யத்தைப் பூட்டிப்போடுகிறீர்கள்; நீங்கள் அதில் பிரவேசிக்கிறதுமில்லை, பிரவேசிக்கப் போகிறவர்களைப் பிரவேசிக்க விடுகிறதுமில்லை.
	<br /><br />
	14. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, பார்வைக்காக நீண்ட ஜெபம்பண்ணி, விதவைகளின் வீடுகளைப் பட்சித்துப்போடுகிறீர்கள்; இதினிமித்தம் அதிக ஆக்கினையை அடைவீர்கள்.
	<br /><br />
	15. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, ஒருவனை உங்கள் மார்க்கத்தானாக்கும்படி சமுத்திரத்தையும் பூமியையும் சுற்றித்திரிகிறீர்கள்; அவன் உங்கள் மார்க்கத்தானானபோது அவனை உங்களிலும் இரட்டிப்பாய் நரகத்தின் மகனாக்குகிறீர்கள்.
	<br /><br />
	16. குருடரான வழிகாட்டிகளே! உங்களுக்கு ஐயோ, எவனாகிலும் தேவாலயத்தின்பேரில் சத்தியம்பண்ணினால் அதினால் ஒன்றுமில்லையென்றும், எவனாகிலும் தேவாலயத்தின் பொன்னின்பேரில் சத்தியம்பண்ணினால் அவன் கடனாளியென்றும் சொல்லுகிறீர்கள்.
	<br /><br />
	17. மதிகேடரே, குருடரே! எது முக்கியம்? பொன்னோ, பொன்னைப் பரிசுத்தமாக்குகிற தேவாலயமோ?
	<br /><br />
	18. மேலும், எவனாகிலும் பலிபீடத்தின்பேரில் சத்தியம்பண்ணினால் அதினால் ஒன்றுமில்லையென்றும், எவனாகிலும் அதின்மேல் இருக்கிற காணிக்கையின்பேரில் சத்தியம்பண்ணினால், அவன் கடனாளியென்றும் சொல்லுகிறீர்கள்.
	<br /><br />
	19. மதிகேடரே, குருடரே! எது முக்கியம்? காணிக்கையோ, காணிக்கையைப் பரிசுத்தமாக்குகிற பலிபீடமோ?
	<br /><br />
	20. ஆகையால், பலிபீடத்தின்பேரில் சத்தியம்பண்ணுகிறவன் அதின்பேரிலும் அதின்மேலுள்ள எல்லாவற்றின்பேரிலும் சத்தியம்பண்ணுகிறான்.
	<br /><br />
	21. தேவாலயத்தின்பேரில் சத்தியம்பண்ணுகிறவன் அதின்பேரிலும் அதில் வாசமாயிருக்கிறவர்பேரிலும் சத்தியம்பண்ணுகிறான்.
	<br /><br />
	22. வானத்தின்பேரில் சத்தியம்பண்ணுகிறவன் தேவனுடைய சிங்காசனத்தின்பேரிலும் அதில் வீற்றிருக்கிறவர்பேரிலும் சத்தியம்பண்ணுகிறான்.
	<br /><br />
	23. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, நீங்கள் ஒற்தலாமிலும் வெந்தையத்திலும் சீரகத்திலும் தசமபாகம் செலுத்தி, நியாயப்பிரமாணத்தில் கற்பித்திருக்கிற விசேஷித்தவைகளாகிய நீதியையும் இரக்கத்தையும் விசுவாசத்தையும் விட்டுவிட்டீர்கள், இவைகளையும் செய்யவேண்டும், அவைகளையும் விடாதிருக்கவேண்டுமே.
	<br /><br />
	24. குருடரான வழிகாட்டிகளே, கொசுயில்லாதபடி வடிகட்டி, ஒட்டகத்தை விழுங்குகிறவர்களாயிருக்கிறீர்கள்.
	<br /><br />
	25. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, போஜனபானபாத்திரங்களின் வெளிப்புறத்தைச் சுத்தமாக்குகிறீர்கள்; உட்புறத்திலோ அவைகள் கொள்ளையினாலும் அநீதத்தினாலும் நிறைந்திருக்கிறது.
	<br /><br />
	26. குருடனான பரிசேயனே! போஜனபானபாத்திரங்களின் வெளிப்புறம் சுத்தமாகும்படி அவைகளின் உட்புறத்தை முதலாவது சுத்தமாக்கு.
	<br /><br />
	27. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, வெள்ளையடிக்கப்பட்ட கல்லறைகளுக்கு ஒப்பாயிருக்கிறீர்கள், அவைகள் புறம்பே அலங்காரமாய்க் காணப்படும், உள்ளேயோ மரித்தவர்களின் எலும்புகளினாலும் சகல அசுத்தத்தினாலும் நிறைந்திருக்கும்.
	<br /><br />
	28. அப்படியே நீங்களும் மனுஷருக்கு நீதிமான்கள் என்று புறம்பே காணப்படுகிறீர்கள்; உள்ளத்திலோ மாயத்தினாலும் அக்கிரமத்தினாலும் நிறைந்திருக்கிறீர்கள்.
	<br /><br />
	29. மாயக்காரராகிய வேதபாரகரே! பரிசேயரே! உங்களுக்கு ஐயோ, நீங்கள் தீர்க்கதரிசிகளின் கல்லறைகளைக் கட்டி, நீதிமான்களின் சமாதிகளைச் சிங்காரித்து:
	<br /><br />
	30. எங்கள் பிதாக்களின் நாட்களில் இருந்தோமானால், அவர்களோடே நாங்கள் தீர்க்கதரிசிகளின் இரத்தப்பழிக்கு உடன்பட்டிருக்கமாட்டோம் என்கிறீர்கள்.
	<br /><br />
	31. ஆகையால், தீர்க்கதரிசிகளைக் கொலைசெய்தவர்களுக்குப் புத்திரராயிருக்கிறீர்கள் என்று உங்களைக்குறித்து நீங்களே சாட்சிகளாயிருக்கிறீர்கள்.
	<br /><br />
	32. நீங்களும் உங்கள் பிதாக்களின் அக்கிரம அளவை நிரப்புங்கள்.
	<br /><br />
	33. சர்ப்பங்களே, விரியன்பாம்புக் குட்டிகளே! நரகாக்கினைக்கு எப்படித் தப்பித்துக்கொள்வீர்கள்?
	<br /><br />
	34. ஆகையால், இதோ, தீர்க்கதரிசிகளையும் ஞானிகளையும் வேதபாரகரையும் உங்களிடத்தில் அனுப்புகிறேன்; அவர்களில் சிலரைக் கொன்று சிலுவைகளில் அறைவீர்கள், சிலரை உங்கள் ஆலயங்களில் வாரினால் அடித்து, ஊருக்கு ஊர் துன்பப்படுத்துவீர்கள்;
	<br /><br />
	35. நீதிமானாகிய ஆபேலின் இரத்தம் முதல் தேவாலயத்துக்கும் பலிபீடத்துக்கும் நடுவே நீங்கள் கொலைசெய்த பரகியாவின் குமாரனாகிய சகரியாவின் இரத்தம்வரைக்கும், பூமியின்மேல் சிந்தப்பட்ட நீதிமான்களின் இரத்தப்பழியெல்லாம் உங்கள்மேல் வரும்படியாக இப்படிச் செய்வீர்கள்.
	<br /><br />
	36. இவைகளெல்லாம் இந்தச் சந்ததியின்மேல் வருமென்று, மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	37. எருசலேமே, எருசலேமே, தீர்க்கதரிசிகளைக் கொலைசெய்து, உன்னிடத்தில் அனுப்பப்பட்டவர்களைக் கல்லெறிகிறவளே! கோழி தன் குஞ்சுகளைத் தன் சிறகுகளின்கீழே கூட்டிச் சேர்த்துக் கொள்ளும்வண்ணமாக நான் எத்தனை தரமோ உன் பிள்ளைகளைக் கூட்டிச் சேர்த்துக்கொள்ள மனதாயிருந்தேன்; உங்களுக்கோ மனதில்லாமற்போயிற்று.
	<br /><br />
	38. இதோ, உங்கள் வீடு உங்களுக்குப் பாழாக்கிவிடப்படும்.
	<br /><br />
	39. கர்த்தருடைய நாமத்தினாலே வருகிறவர் ஸ்தோத்திரிக்கப்பட்டவர் என்று நீங்கள் சொல்லுமளவும், இதுமுதல் என்னைக் காணாதிருப்பீர்கள் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew23