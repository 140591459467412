import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 3 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 3 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 3 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. எங்களை நாங்களே மறுபடியும் மெச்சிக்கொள்ளத் தொடங்குகிறோமோ? அல்லது சிலருக்கு வேண்டியதாயிருக்கிறதுபோல, உங்களுக்கு உபசார நிருபங்களை அனுப்பவும், உங்களிடத்தில் உபசார நிருபங்களைப் பெற்றுக்கொள்ளவும் எங்களுக்கு வேண்டியதோ?
	<br /><br />
	2. எங்கள் இருதயங்களில் எழுதப்பட்டும், சகல மனுஷராலும் அறிந்து வாசிக்கப்பட்டும் இருக்கிற எங்கள் நிருபம் நீங்கள்தானே.
	<br /><br />
	3. ஏனெனில் நீங்கள் எங்கள் ஊழியத்தினால் உண்டாகிய கிறிஸ்துவின் நிருபமாயிருக்கிறீர்களென்று வெளியரங்கமாயிருக்கிறது; அது மையினாலல்ல, ஜீவனுள்ள தேவனுடைய ஆவியினாலும்; கற்பலகைகளிலல்ல, இருதயங்களாகிய சதையான பலகைகளிலேயும் எழுதப்பட்டிருக்கிறது.
	<br /><br />
	4. நாங்கள் தேவனுக்கு முன்பாகக் கிறிஸ்துவின் மூலமாய் இப்படிப்பட்ட நம்பிக்கையைக் கொண்டிருக்கிறோம்.
	<br /><br />
	5. எங்களால் ஏதாகிலும் ஆகும் என்பதுபோல ஒன்றை யோசிக்கிறதற்கு நாங்கள் எங்களாலே தகுதியானவர்கள் அல்ல; எங்களுடைய தகுதி தேவனால் உண்டாயிருக்கிறது.
	<br /><br />
	6. புது உடன்படிக்கையின் ஊழியக்காரராயிருக்கும்படி, அவரே எங்களைத் தகுதியுள்ளவர்களாக்கினார்; அந்த உடன்படிக்கை எழுத்திற்குரியதாயிராமல், ஆவிக்குரியதாயிருக்கிறது; எழுத்து கொல்லுகிறது, ஆவியோ உயிர்ப்பிக்கிறது.
	<br /><br />
	7. எழுத்துக்களினால் எழுதப்பட்டுக் கற்களில் பதிந்திருந்த மரணத்துக்கேதுவான ஊழியத்தைச் செய்த மோசேயினுடைய முகத்திலே மகிமைப்பிரகாசம் உண்டானபடியால், இஸ்ரவேல் புத்திரர் அவன் முகத்தை நோக்கிப் பார்க்கக்கூடாதிருந்தார்களே.
	<br /><br />
	8. ஒழிந்துபோகிற மகிமையையுடைய அந்த ஊழியம் அப்படிப்பட்ட மகிமையுள்ளதாயிருந்தால், ஆவிக்குரிய ஊழியம் எவ்வளவு அதிக மகிமையுள்ளதாயிருக்கும்?
	<br /><br />
	9. ஆக்கினைத்தீர்ப்புக் கொடுக்கும் ஊழியம் மகிமையுள்ளதாயிருந்தால், நீதியைக் கொடுக்கும் ஊழியம் அதிக மகிமையுள்ளதாயிருக்குமே.
	<br /><br />
	10. இப்படியாக, மகிமைப்பட்டிருந்த அந்த ஊழியம் இந்த ஊழியத்திற்கு உண்டாயிருக்கிற சிறந்த மகிமைக்குமுன்பாக மகிமைப்பட்டதல்ல.
	<br /><br />
	11. அன்றியும் ஒழிந்துபோவதே மகிமையுள்ளதாயிருந்ததானால், நிலைத்திருப்பது அதிக மகிமையுள்ளதாயிருக்குமே.
	<br /><br />
	12. நாங்கள் இப்படிப்பட்ட நம்பிக்கையை உடையவர்களாதலால், மிகவும் தாராளமாய்ப் பேசுகிறோம்.
	<br /><br />
	13. மேலும் ஒழிந்துபோவதின் முடிவை இஸ்ரவேல் புத்திரர் நோக்கிப்பாராதபடிக்கு, மோசே தன் முகத்தின்மேல் முக்காடு போட்டுக்கொண்டதுபோல நாங்கள் போடுகிறதில்லை.
	<br /><br />
	14. அவர்களுடைய மனது கடினப்பட்டது; இந்நாள்வரைக்கும் பழைய ஏற்பாடு வாசிக்கப்படுகையில், அந்த முக்காடு நீங்காமலிருக்கிறது; அது கிறிஸ்துவினாலே நீக்கப்படுகிறது.
	<br /><br />
	15. மோசேயின் ஆகமங்கள் வாசிக்கப்படும்போது, இந்நாள்வரைக்கும் முக்காடு அவர்கள் இருதயத்தின்மேல் இருக்கிறதே.
	<br /><br />
	16. அவர்கள் கர்த்தரிடத்தில் மனந்திரும்பும்போது, அந்த முக்காடு எடுபட்டுப்போம்.
	<br /><br />
	17. கர்த்தரே ஆவியானவர்; கர்த்தருடைய ஆவி எங்கேயோ அங்கே விடுதலையுமுண்டு.
	<br /><br />
	18. நாமெல்லாரும் திறந்த முகமாய்க் கர்த்தருடைய மகிமையைக் கண்ணாடியிலே காண்கிறதுபோலக் கண்டு, ஆவியாயிருக்கிற கர்த்தரால் அந்தச் சாயலாகத்தானே மகிமையின்மேல் மகிமையடைந்து மறுரூபப்படுகிறோம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians3