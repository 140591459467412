import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial36 = () => {
  const verseNumber = 36;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 36 </title>
	<meta
          name="description"
          content="Ezekiel 36 | எசேக்கியேல்  36 |
          Ezekiel 36 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மனுபுத்திரனே, நீ இஸ்ரவேல் மலைகளை நோக்கித் தீர்க்கதரிசனம் உரைத்து, சொல்லவேண்டியது என்னவென்றால்: இஸ்ரவேல் மலைகளே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	2. கர்த்தராகிய ஆண்டவர் உரைக்கிறார்; பகைஞன் உங்களைக்குறித்து ஆ ஆ, நித்திய மேடுகள் எங்கள் வசமாயிற்று என்று சொல்லுகிறபடியினால்,
	<br /><br />
	3. நீ தீர்க்கதரிசனம் உரைத்துச் சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், நீங்கள் புறஜாதிகளில் மீதியானவர்களுக்குச் சுதந்தரமாயிருக்கும்படி அவர்கள் உங்களைப் பாழாக்கி, உங்களைச் சுற்றிலுமிருந்து விழுங்கினபடியினாலும், நீங்கள் வாயாடிகளுக்குப்பேச்சும் ஜனங்களுக்கு அவதூறுமானவர்களானபடியினாலும்,
	<br /><br />
	4. இஸ்ரவேல் மலைகளே, நீங்கள் கர்த்தராகிய ஆண்டவருடைய வார்த்தையைக் கேளுங்கள்; மலைகளுக்கும், மேடுகளுக்கும், ஆறுகளுக்கும், பள்ளத்தாக்குகளுக்கும், பாழாக்கப்பட்ட அவாந்தர இடங்களுக்கும், வெறுமையாய் விடப்பட்ட பட்டணங்களுக்கும் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்: உங்களைச் சுற்றிலும் மீதியான புறஜாதிகளுக்கு நீங்கள் கொள்ளையும் பரியாசமுமாய்ப்போனபடியினால்,
	<br /><br />
	5. கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்: என் தேசத்தைக் கொள்ளையிடப்பட்ட வெளியாக்கும்படிக்கு அதை முழு இருதயத்தின் சந்தோஷத்தோடும் கர்வமான மனதோடும் தங்களுக்குச் சுதந்தரமாக நியமித்துக்கொண்ட புறஜாதிகளில் மீதியானவர்களுக்கு விரோதமாகவும் ஏதோம் அனைத்துக்கும் விரோதமாகவும், என் அக்கினியான எரிச்சலினால் பேசினேன் என்று நிச்சயமாய்ச் சொல்லுகிறேன்.
	<br /><br />
	6. ஆகையால், நீ இஸ்ரவேல் தேசத்தைக்குறித்துத் தீர்க்கதரிசனம் உரைத்து, மலைகளுக்கும், மேடுகளுக்கும், ஆறுகளுக்கும், பள்ளத்தாக்குகளுக்கும் சொல்லவேண்டியது என்னவென்றால்: கர்த்தராகிய ஆண்டவர் உரைக்கிறார், இதோ, நீங்கள் புறஜாதிகள் செய்யும் அவமானத்தைச் சுமந்தபடியினால் நான் என் எரிச்சலினாலும் என் உக்கிரத்தினாலும் பேசினேன்,
	<br /><br />
	7. ஆதலால், கர்த்தராகிய ஆண்டவராயிருக்கிற நான் என் கரத்தை உயர்த்துவேன், உங்களைச் சுற்றிலும் இருக்கிற புறஜாதிகள் தங்களுடைய அவமானத்தை நிச்சயமாய்ச் சுமப்பார்கள் என்று சொல்லுகிறேன்.
	<br /><br />
	8. இஸ்ரவேல் மலைகளே, நீங்கள் உங்கள் கொப்புகளை விட்டு, என் ஜனமாகிய இஸ்ரவேலுக்கு உங்கள் கனிகளைக் கொடுப்பீர்கள்; அவர்கள் சமீபமாய் வந்துவிட்டார்கள்.
	<br /><br />
	9. இதோ, நான் உங்கள் பட்சத்திலிருந்து, உங்களைக் கண்ணோக்குவேன்; நீங்கள் பண்படுத்தப்பட்டு விதைக்கப்படுவீர்கள்.
	<br /><br />
	10. நான் உங்கள்மேல் இஸ்ரவேல் வம்சமாகிய மனுஷர் யாவரையும் வர்த்திக்கப்பண்ணுவேன்; பட்டணங்கள் குடியேற்றப்படும், அவாந்தரமான ஸ்தலங்கள் கட்டப்படும்.
	<br /><br />
	11. உங்கள்மேல் மனுஷரையும் மிருகஜீவன்களையும் பெருகிப் பலுகும்படி வர்த்திக்கப்பண்ணுவேன்; பூர்வநாட்களில் நீங்கள் இருந்த நிலைமையில் நான் உங்களை ஸ்தாபித்து, உங்கள் முந்தின சீரைப்பார்க்கிலும் உங்களுக்கு நற்சீர் உண்டாகச்செய்வேன்; அதினால் நான் கர்த்தர் என்று அறிந்துகொள்வீர்கள்.
	<br /><br />
	12. நான் உங்கள்மேல் என் ஜனமாகிய இஸ்ரவேலின் மனுஷரை நடமாடப்பண்ணுவேன், அவர்கள் உன்னைக் கையாளுவார்கள்; அவர்களுக்குச் சுதந்தரமாயிருப்பாய்; நீ இனிமேல் அவர்களைச் சாகக்கொடுப்பதில்லை.
	<br /><br />
	13. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், ஜனங்கள் உன்னைப்பார்த்து: நீ மனுஷரைப் பட்சிக்கிற தேசமென்றும், நீ உன் ஜனங்களைச் சாகக்கொடுக்கிற தேசமென்றும் சொல்லுகிறபடியினால்,
	<br /><br />
	14. நீ இனிமேல் மனுஷரைப் பட்சிப்பதுமில்லை, இனிமேல் உன் ஜனங்களைச் சாகக்கொடுப்பதுமில்லை என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	15. நான் இனிமேல் புறஜாதிகள் செய்யும் அவமானத்தை உன்னிடத்திலே கேட்கப்பண்ணுவதுமில்லை, நீ ஜனங்களின் நிந்தையை இனிமேல் சுமப்பதுமில்லை; நீ இனிமேல் உன் ஜாதிகளைச் சாகக்கொடுப்பதுமில்லையென்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார் என்று சொல் என்றார்.
	<br /><br />
	16. பின்னும் கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	17. மனுபுத்திரனே, இஸ்ரவேல் வம்சத்தார் தங்கள் சுயதேசத்திலே குடியிருக்கையில் அதைத் தங்கள் நடக்கையினாலும் தங்கள் கிரியைகளினாலும் தீட்டுப்படுத்தினார்கள்; அவர்களுடைய நடக்கை என் முகத்துக்கு முன்பாகத் தூரஸ்திரீயின் தீட்டைப்போல் இருந்தது.
	<br /><br />
	18. ஆகையினால் தேசத்திலே அவர்கள் சிந்தின இரத்தத்தினிமித்தமும், அதை அவர்கள் தங்கள் நரகலான விக்கிரகங்களால் தீட்டுப்படுத்தினதினிமித்தமும் நான் என் உக்கிரத்தை அவர்கள்மேல் ஊற்றி,
	<br /><br />
	19. அவர்களைப் புறஜாதிகளுக்குள்ளே சிதறடித்தேன்; தேசங்களில் தூற்றிப்போடப்பட்டார்கள்; அவர்களுடைய நடக்கையின்படியேயும் அவர்களுடைய கிரியைகளின்படியேயும் அவர்களை நியாயந்தீர்த்தேன்.
	<br /><br />
	20. அவர்கள் புறஜாதிகளிடத்தில் போனபோது அந்த ஜனங்கள் இவர்களைக்குறித்து: இவர்கள் கர்த்தருடைய ஜனங்கள், அவருடைய தேசத்திலிருந்து வந்தார்கள் என்று சொன்னதினால், இவர்கள் என் பரிசுத்த நாமத்தைப் பரிசுத்தக்குலைச்சலாக்கினார்கள்.
	<br /><br />
	21. ஆனாலும் இஸ்ரவேல் வம்சத்தார் தாங்கள் வந்துசேர்ந்த புறஜாதிகளிடத்திலே பரிசுத்தக்குலைச்சலாக்கின என் பரிசுத்த நாமத்தினிமித்தமாகவே இரங்குகிறேன்.
	<br /><br />
	22. ஆதலால், நீ இஸ்ரவேல் வம்சத்தாரை நோக்கி: கர்த்தராகிய ஆண்டவர் உரைக்கிறது என்னவென்றால், இஸ்ரவேல் வம்சத்தாரே, உங்கள் நிமித்தம் அல்ல, நீங்கள் வந்து சேர்ந்த புறஜாதிகளிடத்தில் பரிசுத்தக்குலைச்சலாக்கின என் பரிசுத்த நாமத்தினிமித்தமே நான் இப்படிச் செய்கிறேன்.
	<br /><br />
	23. புறஜாதிகளின் நடுவே நீங்கள் பரிசுத்தக்குலைச்சலாக்கினதும் அவர்களுக்குள் உங்களால் பரிசுத்தக்குலைச்சலாக்கப்பட்டதுமான என் மகத்தான நாமத்தை நான் பரிசுத்தம்பண்ணுவேன்; அப்பொழுது புறஜாதிகள் தங்கள் கண்களுக்கு முன்பாக நான் உங்களுக்குள் பரிசுத்தம்பண்ணப்படுகையில், நான் கர்த்தர் என்பதை அறிந்துகொள்வார்கள் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	24. நான் உங்களைப் புறஜாதிகளிடத்திலிருந்து அழைத்து, உங்களைச் சகல தேசங்களிலுமிருந்து சேர்த்து, உங்கள் சுயதேசத்திற்கு உங்களைக் கொண்டுவருவேன்.
	<br /><br />
	25. அப்பொழுது நான் உங்கள்மேல் சுத்தமான ஜலம் தெளிப்பேன்; நான் உங்களுடைய எல்லா அசுத்தங்களையும் உங்களுடைய எல்லா நரகலான விக்கிரகங்களையும் நீக்கி உங்களைச் சுத்தமாக்குவேன், நீங்கள் சுத்தமாவீர்கள்.
	<br /><br />
	26. உங்களுக்கு நவமான இருதயத்தைக் கொடுத்து, உங்கள் உள்ளத்திலே புதிதான ஆவியைக் கட்டளையிட்டு, கல்லான இருதயத்தை உங்கள் மாம்சத்திலிருந்து எடுத்துப்போட்டு, சதையான இருதயத்தை உங்களுக்குக் கொடுப்பேன்.
	<br /><br />
	27. உங்கள் உள்ளத்திலே என் ஆவியை வைத்து, உங்களை என் கட்டளைகளில் நடக்கவும் என் நியாயங்களைக் கைக்கொள்ளவும் அவைகளின்படி செய்யவும்பண்ணுவேன்.
	<br /><br />
	28. உங்கள் பிதாக்களுக்கு நான் கொடுத்த தேசத்திலே நீங்கள் குடியிருப்பீர்கள்; நீங்கள் என் ஜனமாயிருப்பீர்கள், நான் உங்கள் தேவனாயிருந்து,
	<br /><br />
	29. உங்கள் அசுத்தங்களையெல்லாம் நீக்கி, உங்களை இரட்சித்து, உங்கள்மேல் பஞ்சத்தைக் கட்டளையிடாமல், கோதுமையை வரவழைத்து, அதைப்பெருகப்பண்ணி,
	<br /><br />
	30. நீங்கள் இனிமேல் ஜாதிகளுக்குள்ளே பஞ்சத்தினாலுண்டாகும் நிந்தையை அடையாதபடிக்கு, விருட்சத்தின் கனிகளையும் வயலின் பலன்களையும் பெருகப்பண்ணுவேன்.
	<br /><br />
	31. அப்பொழுது நீங்கள் உங்கள் பொல்லாத மார்க்கங்களையும் உங்கள் தகாத கிரியைகளையும் நினைத்து, உங்கள் அக்கிரமங்களினிமித்தமும் உங்கள் அருவருப்புகளினிமித்தமும் உங்களையே அரோசிப்பீர்கள்.
	<br /><br />
	32. நான் இப்படிச் செய்வது உங்கள் நிமித்தமாக அல்லவென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார், இது உங்களுக்கு அறியப்பட்டிருக்கக்கடவது; இஸ்ரவேல் வம்சத்தாரே, உங்கள் வழிகளினிமித்தம் வெட்கி நாணுங்கள்.
	<br /><br />
	33. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நான் உங்கள் அக்கிரமங்களையெல்லாம் நீக்கி, உங்களைச் சுத்தமாக்கும் காலத்திலே பட்டணங்களில் குடியேற்றுவிப்பேன்; அவாந்தரமான ஸ்தலங்களும் கட்டப்படும்.
	<br /><br />
	34. பாழாக்கப்பட்ட தேசம் கடந்துபோகிற யாவருடைய பார்வைக்கும் பாழாய்க்கிடந்ததற்குப் பதிலாக பயிரிடப்படும்.
	<br /><br />
	35. பாழாய்க்கிடந்த இத்தேசம், ஏதேன் தோட்டத்தைப்போலாயிற்றென்றும், அவாந்தரமும் பாழும் நிர்மூலமுமாயிருந்த பட்டணங்கள் அரணிப்பானவைகளும் குடியேற்றப்பட்டவைகளுமாய் இருக்கிறது என்றும் சொல்லுவார்கள்.
	<br /><br />
	36. கர்த்தராகிய நான் நிர்மூலமானவைகளைக் கட்டுகிறேன் என்றும், பாழானதைப் பயிர்நிலமாக்குகிறேன் என்றும், அப்பொழுது உங்களைச் சுற்றிலுமுள்ள மீதியான ஜாதிகள் அறிந்துகொள்வார்கள்; கர்த்தராகிய நான் இதைச் சொன்னேன், இதைச் செய்வேன்.
	<br /><br />
	37. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இஸ்ரவேல் வம்சத்தாருக்காக நான் இதை அநுக்கிரகஞ்செய்யும்படி அவர்கள் என்னிடத்தில் விண்ணப்பம் பண்ணவேண்டும்; மந்தை பெருகுகிறதுபோல் அவர்களில் மனிதரைப் பெருகப்பண்ணுவேன்.
	<br /><br />
	38. பண்டிகை காலங்களில் எருசலேமிலே பரிசுத்தம்பண்ணப்பட்டு வருகிற மந்தைகள் எப்படித் திரளாயிருக்கிறதோ, அப்படியே அவாந்தரமாயிருந்த பட்டணங்கள் மனுஷரின் மந்தையால் நிரம்பியிருக்கும்; அதினால் நான் கர்த்தர் என்பதை அறிந்துகொள்வார்கள் என்று சொல் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial36