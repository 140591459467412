import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 2 </title>
	<meta
          name="description"
          content="Amos 2 | ஆமோஸ் 2 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொல்லுகிறது என்னவென்றால்: மோவாபின் மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அவன் ஆக்கினையைத் திருப்பமாட்டேன்; அவன் ஏதோமுடைய ராஜாவின் எலும்புகளை நீறாகச் சுட்டுப்போட்டானே.
	<br /><br />
	2. மோவாப்தேசத்தில் தீக்கொளுத்துவேன்; அது கீரியோத்தின் அரமனைகளைப் பட்சிக்கும்; மோவாபியர் அமளியோடும் ஆர்ப்பரிப்போடும் எக்காள சத்தத்தோடும் சாவார்கள்.
	<br /><br />
	3. நியாயாதிபதியை அவர்கள் நடுவில் இராதபடிக்கு நான் சங்காரம்பண்ணி, அவனோடேகூட அவர்களுடைய பிரபுக்களையெல்லாம் கொன்றுபோடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. மேலும்: யூதாவின் மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அவர்கள் ஆக்கினையைத் திருப்பமாட்டேன்; அவர்கள் கர்த்தருடைய வேதத்தை வெறுத்து, அவருடைய கட்டளைகளைக் கைக்கொள்ளாமல், தங்கள் பிதாக்கள் பின்பற்றின பொய்களினால் மோசம்போனார்களே.
	<br /><br />
	5. யூதாவிலே நான் தீக்கொளுத்துவேன்; அது எருசலேமின் அரமனைகளைப் பட்சிக்கும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	6. மேலும்: இஸ்ரவேலின் மூன்று பாதகங்களினிமித்தமும், நாலு பாதகங்களினிமித்தமும், நான் அவர்களுடைய ஆக்கினையைத் திருப்பமாட்டேன்; அவர்கள் நீதிமானைப் பணத்துக்கும், எளியவனை ஒரு ஜோடு பாதரட்சைக்கும் விற்றுப்போட்டார்களே.
	<br /><br />
	7. அவர்கள் தரித்திரருடைய தலையின்மேல் மண்ணைவாரி இறைத்து, சிறுமையானவர்களின் வழியைப் புரட்டுகிறார்கள்; என் பரிசுத்த நாமத்தைக் குலைச்சலாக்கும்படிக்கு மகனும் தகப்பனும் ஒரு பெண்ணிடத்தில் பிரவேசிக்கிறார்கள்.
	<br /><br />
	8. அவர்கள் சகல பீடங்களருகிலும் அடைமானமாய் வாங்கின வஸ்திரங்களின்மேல் படுத்துக்கொண்டு, தெண்டம் பிடிக்கப்பட்டவர்களுடைய மதுபானத்தைத் தங்கள் தேவர்களின் கோவிலிலே குடிக்கிறார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	9. நானோ: கேதுருமரங்களைப்போல் உயரமும், கர்வாலி மரங்களைப்போல் வைரமுமாயிருந்த எமோரியனை அவர்களுக்கு முன்பாக அழித்தேன்; உயர இருந்த அவனுடைய கனியையும், தாழ இருந்த அவனுடைய வேர்களையும் அழித்துப்போட்டு,
	<br /><br />
	10. நீங்கள் எமோரியனுடைய தேசத்தைக் கட்டிக்கொள்ளும்படிக்கு உங்களை நான் எகிப்துதேசத்திலிருந்து வரப்பண்ணி, உங்களை நாற்பது வருஷமாக வனாந்தரத்திலே வழிநடத்தி,
	<br /><br />
	11. உங்கள் குமாரரில் சிலரைத் தீர்க்கதரிசிகளாகவும், உங்கள் வாலிபரில் சிலரை நசரேயராகவும் எழும்பப்பண்ணினேன்; இஸ்ரவேல் புத்திரரே, இப்படி நான் செய்யவில்லையா என்று கர்த்தர் கேட்கிறார்.
	<br /><br />
	12. நீங்களோ நசரேயருக்குத் திராட்சரசம் குடிக்கக் கொடுத்து, தீர்க்கதரிசிகளை நோக்கி: நீங்கள் தீர்க்கதரிசனஞ்சொல்லவேண்டாம் என்று கற்பித்தீர்கள்.
	<br /><br />
	13. இதோ, கோதுமைக்கட்டுகள் நிறைபாரமாக ஏற்றப்பட்ட வண்டியில் இருத்துகிறதுபோல, நான் உங்களை நீங்கள் இருக்கிற ஸ்தலத்தில் இருத்துவேன்.
	<br /><br />
	14. அப்பொழுது வேகமானவன் ஓடியும் புகலிடமில்லை; பலவான் தன் பலத்தினால் பலப்படுவதுமில்லை; பராக்கிரமசாலி தன் பிராணனைத் தப்புவிப்பதுமில்லை.
	<br /><br />
	15. வில்லைப் பிடிக்கிறவன் நிற்பதுமில்லை; வேகமானவன் தன் கால்களால் தப்பிப்போவதுமில்லை; குதிரையின்மேல் ஏறுகிறவன் தன் பிராணனை இரட்சிப்பதுமில்லை.
	<br /><br />
	16. பலசாலிகளுக்குள்ளே தைரியவான் அந்நாளிலே நிர்வாணியாய் ஓடிப்போவான் என்று கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos2