import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 10 </title>
	<meta
          name="description"
          content="Acts 10 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 10 |
          Acts of Apostles Chapter-10 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இத்தாலியா பட்டாளம் என்னப்பட்ட பட்டாளத்திலே நூற்றுக்கு அதிபதியாகிய கொர்நேலியு என்னும் பேர்கொண்ட ஒரு மனுஷன் செசரியா பட்டணத்திலே இருந்தான்.
	<br /><br />
	2. அவன் தேவபக்தியுள்ளவனும் தன்வீட்டாரனைவரோடும் தேவனுக்குப் பயந்தவனுமாயிருந்து, ஜனங்களுக்கு மிகுந்த தருமங்களைச் செய்து, எப்பொழுதும் தேவனை நோக்கி ஜெபம்பண்ணிக்கொண்டிருந்தான்.
	<br /><br />
	3. பகலில் ஏறக்குறைய ஒன்பதாம்மணி நேரத்திலே தேவனுடைய தூதன் தன்னிடத்தில் வரவும், கொர்நேலியுவே, என்று அழைக்கவும் பிரத்தியட்சமாய்த் தரிசனங்கண்டு,
	<br /><br />
	4. அவனை உற்றுப்பார்த்து, பயந்து: ஆண்டவரே, என்ன என்றான். அப்பொழுது அவன்: உன் ஜெபங்களும் உன் தருமங்களும் தேவனுக்கு நினைப்பூட்டுதலாக அவர் சந்நிதியில் வந்தெட்டியிருக்கிறது.
	<br /><br />
	5. இப்பொழுது நீ யோப்பா பட்டணத்துக்கு மனுஷரை அனுப்பி, பேதுரு என்று மறுபேர்கொண்ட சீமோனை அழைப்பி.
	<br /><br />
	6. அவன் தோல் பதனிடுகிறவனாகிய சீமோன் என்னும் ஒருவனிடத்திலே தங்கியிருக்கிறான்; அவனுடைய வீடு கடலோரத்திலிருக்கிறது. நீ செய்யவேண்டியதை அவன் உனக்குச் சொல்லுவான் என்றான்.
	<br /><br />
	7. கொர்நேலியு தன்னுடனே பேசின தேவதூதன் போனபின்பு, தன் வீட்டு மனுஷரில் இரண்டுபேரையும் தன்னிடத்தில் சேவிக்கிற போர்ச்சேவகரில் தேவபக்தியுள்ள ஒருவனையும் அழைத்து,
	<br /><br />
	8. எல்லாவற்றையும் அவர்களுக்கு விவரித்துச் சொல்லி, அவர்களை யோப்பா பட்டணத்துக்கு அனுப்பினான்.
	<br /><br />
	9. மறுநாளிலே அவர்கள் பிரயாணப்பட்டு, அந்தப் பட்டணத்துக்குச் சமீபித்து வருகையில், பேதுரு ஆறாம்மணி நேரத்திலே ஜெபம்பண்ணும்படி மேல்வீட்டில் ஏறினான்.
	<br /><br />
	10. அவன் மிகுந்த பசியடைந்து சாப்பிட மனதாயிருந்தான்; அதற்கு அவர்கள் ஆயத்தம்பண்ணுகையில், அவன் ஞானதிருஷ்டியடைந்து,
	<br /><br />
	11. வானம் திறந்திருக்கிறதாகவும், நாலுமுனைகளும் கட்டப்பட்ட பெரிய துப்பட்டியைப்போல ஒருவிதமான கூடு தன்னிடத்தில் இறங்கித் தரையில் விடப்பட்டிருக்கிறதாகவும்,
	<br /><br />
	12. அதிலே பூமியிலுள்ள சகலவிதமான நாலுகால் ஜீவன்களும், காட்டுமிருகங்களும், ஊரும் பிராணிகளும், ஆகாயத்துப் பறவைகளும் இருக்கிறதாகவும் கண்டான்.
	<br /><br />
	13. அல்லாமலும்: பேதுருவே, எழுந்திரு, அடித்துப் புசி என்று அவனுக்குச் சொல்லும் ஒரு சத்தம் உண்டாயிற்று.
	<br /><br />
	14. அதற்குப் பேதுரு: அப்படியல்ல, ஆண்டவரே, தீட்டும் அசுத்தமுமாயிருக்கிற யாதொன்றையும் நான் ஒருக்காலும் புசித்ததில்லை என்றான்.
	<br /><br />
	15. அப்பொழுது: தேவன் சுத்தமாக்கினவைகளை நீ தீட்டாக எண்ணாதே என்று இரண்டாந்தரமும் சத்தம் அவனுக்கு உண்டாயிற்று.
	<br /><br />
	16. மூன்றாந்தரமும் அப்படியே உண்டாயிற்று. பின்பு அந்தக் கூடு திரும்ப வானத்துக்கு எடுத்துக்கொள்ளப்பட்டது.
	<br /><br />
	17. அப்பொழுது பேதுரு, தான் கண்ட தரிசனத்தைக்குறித்துத் தனக்குள்ளே சந்தேகப்படுகையில், இதோ, கொர்நேலியுவினால் அனுப்பப்பட்ட மனுஷர்கள் சீமோனுடைய வீட்டை விசாரித்துக்கொண்டு வாசற்படியிலே வந்து நின்று:
	<br /><br />
	18. பேதுரு என்று மறுபேர்கொண்ட சீமோன் இங்கே தங்கியிருக்கிறாரா என்று கேட்டார்கள்.
	<br /><br />
	19. பேதுரு அந்தத் தரிசனத்தைக் குறித்துச் சிந்தனை பண்ணிக்கொண்டிருக்கையில், ஆவியானவர்: இதோ, மூன்று மனுஷர் உன்னைத் தேடுகிறார்கள்.
	<br /><br />
	20. நீ எழுந்து, இறங்கி, ஒன்றுக்குஞ் சந்தேகப்படாமல், அவர்களுடனே கூடப்போ; நானே அவர்களை அனுப்பினேன் என்று அவனுக்குச் சொன்னார்.
	<br /><br />
	21. அப்பொழுது பேதுரு கொர்நேலியுவினால் தன்னிடத்தில் அனுப்பப்பட்ட மனுஷரிடத்திற்கு இறங்கிப்போய்: இதோ, நீங்கள் தேடுகிறவன் நான்தான், நீங்கள் வந்திருக்கிற காரியம் என்ன என்றான்.
	<br /><br />
	22. அதற்கு அவர்கள்: நீதிமானும், தேவனுக்குப் பயப்படுகிறவரும், யூதஜனங்களெல்லாராலும் நல்லவரென்று சாட்சி பெற்றவருமாகிய கொர்நேலியு என்னும் நூற்றுக்கு அதிபதி உம்மைத் தம்முடைய வீட்டுக்கு அழைப்பித்து, உம்மால் சொல்லப்படும் வார்த்தைகளைக் கேட்கும்படி பரிசுத்த தூதனாலே தேவயத்தனமாய்க் கட்டளைபெற்றார் என்றார்கள்.
	<br /><br />
	23. அப்பொழுது பேதுரு அவர்களை உள்ளே அழைத்து, அவர்களுக்கு உபசாரஞ்செய்து, மறுநாளிலே அவர்களுடனேகூடப் புறப்பட்டான்; யோப்பா பட்டணத்தாராகிய சகோதரரில் சிலரும் அவனுடனேகூடப் போனார்கள்.
	<br /><br />
	24. மறுநாளிலே செசரியா பட்டணத்தில் பிரவேசித்தார்கள். கொர்நேலியு தன் உறவின்முறையாரையும் தன்னுடைய விசேஷித்த சிநேகிதரையும் கூடவரவழைத்து, அவர்களுக்காகக் காத்திருந்தான்.
	<br /><br />
	25. பேதுரு உள்ளே பிரவேசிக்கிறபொழுது, கொர்நேலியு அவனுக்கு எதிர்கொண்டுபோய், அவன் பாதத்தில் விழுந்து, பணிந்துகொண்டான்.
	<br /><br />
	26. பேதுரு அவனைத் தூக்கியெடுத்து: எழுந்திரும், நானும் ஒரு மனுஷன்தான் என்றான்.
	<br /><br />
	27. அவனுடனே பேசிக்கொண்டு, உள்ளே போய், அநேகர் கூடிவந்திருக்கிறதைக் கண்டு,
	<br /><br />
	28. அவர்களை நோக்கி: அந்நிய ஜாதியானோடே கலந்து அவனிடத்தில் போக்குவரவாயிருப்பது யூதனானவனுக்கு விலக்கப்பட்டிருக்கிறதென்று நீங்கள் அறிந்திருக்கிறீர்கள்; அப்படியிருந்தும், எந்த மனுஷனையும் தீட்டுள்ளவனென்றும் அசுத்தனென்றும் நான் சொல்லாதபடிக்கு தேவன் எனக்குக் காண்பித்திருக்கிறார்.
	<br /><br />
	29. ஆகையால் நீங்கள் என்னை அழைப்பித்தபோது நான் எதிர்பேசாமல் வந்தேன். இப்போதும் என்ன காரியத்துக்காக என்னை அழைப்பித்தீர்கள் என்று கேட்கிறேன் என்றான்.
	<br /><br />
	30. அதற்குக் கொர்நேலியு: நாலு நாளைக்கு முன்னே இந்நேரத்திலே நான் உபவாசித்து, ஒன்பதாம்மணி நேரத்தில் என் வீட்டிலே ஜெபம்பண்ணிக்கொண்டிருந்தேன்; அப்பொழுது பிரகாசமுள்ள வஸ்திரந்தரித்த மனுஷன் ஒருவன் எனக்கு முன்பாக நின்று:
	<br /><br />
	31. கொர்நேலியுவே, உன் ஜெபம் கேட்கப்பட்டது, உன் தானதருமங்கள் தேவசந்நிதியில் நினைத்தருளப்பட்டது.
	<br /><br />
	32. யோப்பா பட்டணத்துக்கு ஆள் அனுப்பி, பேதுரு என்று மறுபேர்கொண்ட சீமோனை வரவழைப்பாயாக, அவன் கடலோரத்திலே தோல்பதனிடுகிறவனாகிய சீமோனுடைய வீட்டிலே தங்கியிருக்கிறான்; அவன் வந்து உன்னிடத்தில் பேசுவான் என்றார்.
	<br /><br />
	33. அந்தப்படியே நான் உடனே உம்மிடத்திற்கு ஆள் அனுப்பினேன்; நீர் வந்தது நல்ல காரியம்; தேவனாலே உமக்குக் கட்டளையிடப்பட்ட யாவையும் கேட்கும்படிக்கு நாங்கள் எல்லாரும் இப்பொழுது இங்கே தேவசமுகத்தில் கூடியிருக்கிறோம் என்றான்.
	<br /><br />
	34. அப்பொழுது பேதுரு பேசத்தொடங்கி: தேவன் பட்சபாதமுள்ளவரல்ல என்றும்,
	<br /><br />
	35. எந்த ஜனத்திலாயினும் அவருக்குப் பயந்திருந்து நீதியைச் செய்கிறவன் எவனோ அவனே அவருக்கு உகந்தவன் என்றும் நிச்சயமாய் அறிந்திருக்கிறேன்.
	<br /><br />
	36. எல்லாருக்கும் கர்த்தராயிருக்கிற இயேசுகிறிஸ்துவைக்கொண்டு அவர் சமாதானத்தைச் சுவிசேஷமாய்க் கூறி, இஸ்ரவேல் புத்திரருக்கு அனுப்பின வார்த்தையை அறிந்திருக்கிறீர்களே.
	<br /><br />
	37. யோவான் ஞானஸ்நானத்தைக்குறித்துப் பிரசங்கித்தபின்பு, கலிலேயா நாடு முதற்கொண்டு யூதேயா தேசமெங்கும் நடந்த சங்கதி இதுவே.
	<br /><br />
	38. நசரேயனாகிய இயேசுவைத் தேவன் பரிசுத்தஆவியினாலும் வல்லமையினாலும் அபிஷேகம்பண்ணினார்; தேவன் அவருடனேகூட இருந்தபடியினாலே அவர் நன்மைசெய்கிறவராயும் பிசாசின் வல்லமையில் அகப்பட்ட யாவரையும் குணமாக்குகிறவராயும் சுற்றித்திரிந்தார்.
	<br /><br />
	39. யூதருடைய தேசத்திலும் எருசலேமிலும் அவர் செய்தவைகளெல்லாவற்றிற்கும் நாங்கள் சாட்சிகளாயிருக்கிறோம். அவரை மரத்திலே தூக்கிக் கொலைசெய்தார்கள்.
	<br /><br />
	40. மூன்றாம் நாளிலே தேவன் அவரை எழுப்பிப் பிரத்தியட்சமாய்க் காணும்படி செய்தார்.
	<br /><br />
	41. ஆயினும் எல்லா ஜனங்களுக்கும் பிரத்தியட்சமாகும்படி செய்யாமல், அவர் மரித்தோரிலிருந்து எழுந்தபின்பு அவரோடே புசித்துக் குடித்தவர்களும் தேவனால் முன்பு நியமிக்கப்பட்ட சாட்சிகளுமாகிய எங்களுக்கே பிரத்தியட்சமாகும்படி செய்தார்.
	<br /><br />
	42. அன்றியும் அவரே உயிரோடிருக்கிறவர்களுக்கும் மரித்தோர்களுக்கும் தேவனால் ஏற்படுத்தப்பட்ட நியாயாதிபதியென்று ஜனங்களுக்குப் பிரசங்கிக்கவும், சாட்சியாக ஒப்புவிக்கவும், அவர் எங்களுக்குக் கட்டளையிட்டார்.
	<br /><br />
	43. அவரை விசுவாசிக்கிறவன் எவனோ அவன் அவருடைய நாமத்தினாலே பாவமன்னிப்பைப் பெறுவானென்று தீர்க்கதரிசிகளெல்லாரும் அவரைக்குறித்தே சாட்சிகொடுக்கிறார்கள் என்றான்.
	<br /><br />
	44. இந்த வார்த்தைகளைப் பேதுரு பேசிக்கொண்டிருக்கையில் வசனத்தைக்கேட்டவர்கள் யாவர்மேலும் பரிசுத்த ஆவியானவர் இறங்கினார்.
	<br /><br />
	45. அவர்கள் பல பாஷைகளைப் பேசுகிறதையும், தேவனைப் புகழுகிறதையும்,
	<br /><br />
	46. பேதுருவோடேகூட வந்திருந்த விருத்தசேதனமுள்ள விசுவாசிகள் கேட்கும்போது, பரிசுத்தஆவியின் வரம் புறஜாதிகள்மேலும் பொழிந்தருளப்பட்டதைக்குறித்துப் பிரமித்தார்கள்.
	<br /><br />
	47. அப்பொழுது பேதுரு: நம்மைப்போலப் பரிசுத்தஆவியைப் பெற்ற இவர்களும் ஞானஸ்நானம் பெறாதபடிக்கு எவனாகிலும் தண்ணீரை விலக்கலாமா என்று சொல்லி,
	<br /><br />
	48. கர்த்தருடைய நாமத்தினாலே அவர்களுக்கு ஞானஸ்நானங்கொடுக்கும்படி கட்டளையிட்டான். அப்பொழுது சிலநாள் அங்கே தங்கும்படி அவனை வேண்டிக்கொண்டார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts10