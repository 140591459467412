import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Lamentations5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible News | புலம்பல் 5 </title>
    <meta
          name="description"
          content="Lamentations 5 | புலம்பல் 5 |
          Pulambal | Tamil Bible News | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தாவே, எங்களுக்கு நேரிட்டதை நினைத்தருளும்; எங்கள் நிந்தையை நோக்கிப்பாரும்.
	<br /><br />
	2. எங்கள் சுதந்தரம் அந்நியர் வசமாகவும், எங்கள் வீடுகள் புறத்தேசத்தார்வசமாகவும் தாண்டிப்போயின.
	<br /><br />
	3. திக்கற்றவர்களானோம், தகப்பன் இல்லை; எங்கள் தாய்கள் விதவைகளைப்போல இருக்கிறார்கள்.
	<br /><br />
	4. எங்கள் தண்ணீரைப் பணத்துக்கு வாங்கிக் குடிக்கிறோம்; எங்கள் விறகு விலைக்கிரயமாய் வருகிறது.
	<br /><br />
	5. பாரஞ்சுமந்து எங்கள் கழுத்து நோகிறது; நாங்கள் உழைக்கிறோம், எங்களுக்கு இளைப்பாறுதல் இல்லை.
	<br /><br />
	6. அப்பத்தால் திருப்தியாகும்படி எகிப்தியருக்கும் அசீரியருக்கும் எங்களைக் கையளித்தோம்.
	<br /><br />
	7. எங்கள் பிதாக்கள் பாவஞ்செய்து மாண்டுபோனார்கள்; நாங்கள் அவர்களுடைய அக்கிரமங்களைச் சுமக்கிறோம்.
	<br /><br />
	8. அடிமைகள் எங்களை ஆளுகிறார்கள்; எங்களை அவர்கள் கையிலிருந்து விடுவிப்பாரில்லை.
	<br /><br />
	9. வனாந்தரத்தில் இருக்கிற பட்டயத்தினால் பிராணமோசத்துக்கு ஏதுவானவர்களாய் எங்கள் அப்பத்தைத் தேடுகிறோம்.
	<br /><br />
	10. பஞ்சத்தின் கொடுமையினால் எங்கள் தோல் அடுப்பங்கரையைப்போல் கறுத்துப்போயிற்று.
	<br /><br />
	11. சீயோனில் இருந்த ஸ்திரீகளையும் யூதா பட்டணங்களில் இருந்த கன்னிகைகளையும் அவமானப்படுத்தினார்கள்.
	<br /><br />
	12. பிரபுக்களுடைய கைகளை அவர்கள் கட்டி, அவர்களை ஏறத்தூக்கினார்கள்; முதியோரின் முகங்கள் மதிக்கப்படவில்லை.
	<br /><br />
	13. வாலிபரை ஏந்திரம் அரைக்கக் கொண்டுபோகிறார்கள்; இளைஞர் விறகு சுமந்து இடறிவிழுகிறார்கள்.
	<br /><br />
	14. முதியோர்கள் வாசல்களில் உட்காருகிறதும், வாலிபர் கின்னரங்களை வாசிக்கிறதும் நின்றுபோயிற்று.
	<br /><br />
	15. எங்கள் இருதயத்தின் களிகூருதல் ஒழிந்துபோயிற்று; எங்கள் சந்தோஷம் துக்கமாய் மாறிற்று.
	<br /><br />
	16. எங்கள் தலையிலிருந்து கிரீடம் விழுந்தது; ஐயோ! நாங்கள் பாவஞ்செய்தோமே.
	<br /><br />
	17. அதினால் எங்கள் இருதயம் பலட்சயமாயிற்று; அதினால் எங்கள் கண்கள் இருண்டுபோயின.
	<br /><br />
	18. பாழாய்க்கிடக்கிற சீயோன் மலையின்மேல் நரிகள் ஓடித்திரிகிறது.
	<br /><br />
	19. கர்த்தாவே, நீர் என்றென்றைக்கும் இருக்கிறீர்; உம்முடைய சிங்காசனம் தலைமுறை தலைமுறையாக நிலைநிற்கும்.
	<br /><br />
	20. தேவரீர் என்றைக்கும் எங்களை மறந்து, நெடுங்காலமாக எங்களைக் கைவிட்டிருப்பதென்ன?
	<br /><br />
	21. கர்த்தாவே, எங்களை உம்மிடத்தில் திருப்பிக்கொள்ளும், அப்பொழுது திரும்புவோம்; பூர்வகாலத்திலிருந்ததுபோல எங்கள் நாட்களைப் புதியவைகளாக்கும்.
	<br /><br />
	22. எங்களை முற்றிலும் வெறுத்துவிடுவீரோ? எங்கள்பேரில் கடுங்கோபமாயிருக்கிறீரே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Lamentations5