import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel8 = () => {
  const verseNumber = 8;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 8 </title>
	<meta
          name="description"
          content="Daniel 8 | தானியேல் தீர்க்கதரிசி | தானியேல் 8 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தானியேலாகிய எனக்கு முதலில் காண்பிக்கப்பட்ட தரிசனத்துக்குப் பின்பு, ராஜாவாகிய பெல்ஷாத்சார் ராஜ்யபாரம்பண்ணின மூன்றாம் வருஷத்திலே வேறொரு தரிசனம் எனக்குக் காண்பிக்கப்பட்டது.
	<br /><br />
	2. தரிசனத்திலே நான் கண்டது என்னவென்றால்: நான் பார்க்கையில் ஏலாம் தேசத்திலுள்ள சூசான் அரமனையில் இருந்தேன்; அங்கே நான் ஊலாய் என்னும் ஆற்றங்கரையில் இருந்ததாகத் தரிசனத்திலே கண்டேன்.
	<br /><br />
	3. நான் என் கண்களை ஏறெடுத்துப் பார்த்தேன்; இதோ, இரண்டு கொம்புகளுள்ள ஒரு ஆட்டுக்கடா ஆற்றிற்கு முன்பாக நின்றது; அதின் இரண்டு கொம்புகளும் உயர்ந்தவைகளாயிருந்தது; ஆகிலும் அவைகளில் ஒன்று மற்றதைப்பார்க்கிலும் உயர்ந்திருந்தது; உயர்ந்தகொம்பு பிந்தி முளைத்தெழும்பிற்று.
	<br /><br />
	4. அந்த ஆட்டுக்கடா மேற்கும் வடக்கும் தெற்கும் பாய்கிறதைக் கண்டேன்; ஒரு மிருகமும் அதின் முன்னே நிற்கக்கூடாதிருந்தது; அதின் கைக்குத் தப்புவிப்பாருமில்லை; அது தன் இஷ்டப்படியே செய்து வல்லமைகொண்டது.
	<br /><br />
	5. நான் அதைக் கவனித்துக்கொண்டிருக்கையில், இதோ, மேற்கேயிருந்து ஒரு வெள்ளாட்டுக்கடா நிலத்திலே கால் பாவாமல் தேசத்தின்மீதெங்கும் சென்றது; அந்த வெள்ளாட்டுக்கடாவின் கண்களுக்கு நடுவே விசேஷித்த ஒரு கொம்பு இருந்தது.
	<br /><br />
	6. நான் ஆற்றின் முன்பாக நிற்கக்கண்ட இரண்டு கொம்புகளுள்ள ஆட்டுக்கடாவினிடமட்டும் அது வந்து, தன் பலத்தின் உக்கிரத்தோடே அதற்கு எதிராகப் பாய்ந்தது.
	<br /><br />
	7. அது ஆட்டுக்கடாவின் கிட்டச் சேரக்கண்டேன்; அது ஆட்டுக்கடாவின்மேல் கடுங்கோபங்கொண்டு, அதை முட்டி, அதின் இரண்டு கொம்புகளையும் முறித்துப்போட்டது; அதின் முன் நிற்க ஆட்டுக்கடாவுக்குப் பலமில்லாமையால், வெள்ளாட்டுக்கடா அதைத் தரையிலே தள்ளி மிதித்துப்போட்டது; அதின் கைக்கு ஆட்டுக்கடாவைத் தப்புவிப்பார் இல்லை.
	<br /><br />
	8. அப்பொழுது வெள்ளாட்டுக்கடா மிகுதியும் வல்லமைகொண்டது; அது பலங்கொண்டிருக்கையில், அந்தப் பெரிய கொம்பு முறிந்துபோயிற்று; அதற்குப் பதிலாக ஆகாயத்தின் நாலு திசைகளுக்கும் எதிராய் விசேஷித்த நாலுகொம்புகள் முளைத்தெழும்பினது.
	<br /><br />
	9. அவைகளில் ஒன்றிலிருந்து சின்னதான ஒரு கொம்பு புறப்பட்டு, தெற்குக்கும், கிழக்குக்கும் எதிராகவும், சிங்காரமான தேசத்துக்கு நேராகவும் மிகவும் பெரியதாயிற்று.
	<br /><br />
	10. அது வானத்தின் சேனைபரியந்தம் வளர்ந்து, அதின் சேனையாகிய நட்சத்திரங்களில் சிலவற்றைப் பூமியிலே விழப்பண்ணி, அவைகளை மிதித்தது.
	<br /><br />
	11. அது சேனையினுடைய அதிபதி பரியந்தமும் தன்னை உயர்த்தி, அவரிடத்திலிருந்து அன்றாட பலியை நீக்கிற்று; அவருடைய பரிசுத்த ஸ்தானம் தள்ளுண்டது.
	<br /><br />
	12. பாதகத்தினிமித்தம் அன்றாட பலியோடுங்கூடச் சேனையும் அதற்கு ஒப்புக்கொடுக்கப்பட்டது; அது சத்தியத்தைத் தரையிலே தள்ளிற்று; அது கிரியைசெய்து அநுகூலமடைந்தது.
	<br /><br />
	13. பரிசுத்தவானாகிய ஒருவன் பேசக்கேட்டேன்; அப்பொழுது வேறொரு பரிசுத்தவான் பேசினவரை நோக்கி: அன்றாட பலியைக்குறித்தும், பாழ்க்கடிப்பை உண்டாக்கும் பாதகத்தைக்குறித்தும், பரிசுத்த ஸ்தலமும் சேனையும் மிதிபட ஒப்புக்கொடுக்கப்படுவதைக்குறித்தும், உண்டான தரிசனம் எதுவரைக்கும் இருக்கும் என்று கேட்டான்.
	<br /><br />
	14. அவன் என்னை நோக்கி: இரண்டாயிரத்து முந்நூறு இராப்பகல் செல்லும்வரைக்கும் இருக்கும்; பின்பு பரிசுத்தஸ்தலம் சுத்திகரிக்கப்படும் என்றான்.
	<br /><br />
	15. தானியேலாகிய நான் இந்தத் தரிசனத்தைக்கண்டு, அதின் பொருளை அறிய வகைதேடுகையில், இதோ, மனுஷசாயலான ஒருவன் எனக்கு எதிரே நின்றான்.
	<br /><br />
	16. அன்றியும் காபிரியேலே, இவனுக்குத் தரிசனத்தை விளங்கப்பண்ணென்று ஊலாயின் மத்தியிலே கூப்பிட்டுச் சொல்லுகிற ஒரு மனுஷ சத்தத்தையும் கேட்டேன்.
	<br /><br />
	17. அப்பொழுது அவன் நான் நின்ற இடத்துக்கு வந்தான்; அவன் வருகையில் நான் திடுக்கிட்டு முகங்குப்புற விழுந்தேன்; அவன் என்னை நோக்கி: மனுபுத்திரனே கவனி; இந்தத் தரிசனம் முடிவுகாலத்துக்கு அடுத்தது என்றான்.
	<br /><br />
	18. அவன் என்னோடே பேசுகையில், நான் தரையில் முகங்குப்புறக் கிடந்து, அயர்ந்த நித்திரைகொண்டேன்; அவனோ என்னைத் தொட்டு, நான் காலூன்றி நிற்கும்படி செய்து:
	<br /><br />
	19. இதோ, கோபத்தின் முடிவுகாலத்திலே சம்பவிப்பதை உனக்குத் தெரிவிப்பேன்; இது குறிக்கப்பட்ட முடிவுகாலத்துக்கு அடுத்தது.
	<br /><br />
	20. நீ கண்ட இரண்டு கொம்புள்ள ஆட்டுக்கடா மேதியா பெர்சியா தேசங்களின் ராஜாக்கள்;
	<br /><br />
	21. ரோமமுள்ள அந்த வெள்ளாட்டுக்கடா கிரேக்கு தேசத்தின் ராஜா; அதின் கண்களுக்கு நடுவே இருந்த பெரிய கொம்பு அதின் முதலாம் ராஜா;
	<br /><br />
	22. அது முறிந்துபோனபின்பு அதற்குப் பதிலாக நாலு கொம்புகள் எழும்பினது என்னவென்றால், அந்த ஜாதியிலே நாலு ராஜ்யங்கள் எழும்பும்; ஆனாலும் அவனுக்கு இருந்த வல்லமை அவைகளுக்கு இராது.
	<br /><br />
	23. அவர்களுடைய ராஜ்யபாரத்தின் கடைசிக்காலத்திலோவென்றால், பாதகருடைய பாதகம் நிறைவேறும்போது, மூர்க்க முகமும் சூதான பேச்சுமுள்ள சாமர்த்தியமான ஒரு ராஜா எழும்புவான்.
	<br /><br />
	24. அவனுடைய வல்லமை பெருகும்; ஆனாலும் அவனுடைய சுயபலத்தினால் அல்ல, அவன் அதிசயமானவிதமாக அழிம்புண்டாக்கி, அநுகூலம் பெற்றுக்கிரியைசெய்து, பலவான்களையும் பரிசுத்த ஜனங்களையும் அழிப்பான்.
	<br /><br />
	25. அவன் தன் உபாயத்தினால் வஞ்சகத்தைக் கைகூடிவரப்பண்ணி, தன் இருதயத்தில் பெருமைகொண்டு, நிர்விசாரத்தோடிருக்கிற அநேகரை அழித்து, அதிபதிகளுக்கு அதிபதியாயிருக்கிறவருக்கு விரோதமாய் எழும்புவான்; ஆனாலும் அவன் கையினாலல்ல வேறுவிதமாய் முறித்துப்போடப்படுவான்.
	<br /><br />
	26. சொல்லப்பட்ட இராப்பகல்களின் தரிசனம் சத்தியமாயிருக்கிறது; ஆதலால் இந்தத் தரிசனத்தை நீ மறைத்துவை; அதற்கு இன்னும் அநேகநாள் செல்லும் என்றான்.
	<br /><br />
	27. தானியேலாகிய நான் சோர்வடைந்து, சிலநாள் வியாதிப்பட்டிருந்தேன்; பின்பு நான் எழுந்திருந்து, ராஜாவின் வேலையைச் செய்து, அந்தத் தரிசனத்தினால் திகைத்துக்கொண்டிருந்தேன்; ஒருவரும் அதை அறியவில்லை.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel8