import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 2 </title>
	<meta
          name="description"
          content="Acts 2 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 2 |
          Acts of Apostles Chapter-2 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பெந்தெகொஸ்தே என்னும் நாள் வந்தபோது, அவர்களெல்லாரும் ஒருமனப்பட்டு ஓரிடத்திலே வந்திருந்தார்கள்.
	<br /><br />
	2. அப்பொழுது பலத்த காற்று அடிக்கிற முழக்கம்போல, வானத்திலிருந்து சடிதியாய் ஒரு முழக்கமுண்டாகி, அவர்கள் உட்கார்ந்திருந்த வீடு முழுவதையும் நிரப்பிற்று.
	<br /><br />
	3. அல்லாமலும் அக்கினிமயமான நாவுகள்போலப் பிரிந்திருக்கும் நாவுகள் அவர்களுக்குக் காணப்பட்டு, அவர்கள் ஒவ்வொருவர்மேலும் வந்து அமர்ந்தது.
	<br /><br />
	4. அவர்களெல்லாரும் பரிசுத்தஆவியினாலே நிரப்பப்பட்டு, ஆவியானவர் தங்களுக்குத் தந்தருளின வரத்தின்படியே வெவ்வேறு பாஷைகளிலே பேசத்தொடங்கினார்கள்.
	<br /><br />
	5. வானத்தின்கீழிருக்கிற சகல தேசத்தாரிலுமிருந்துவந்த தேவபக்தியுள்ள யூதர்கள் அப்பொழுது எருசலேமிலே வாசம்பண்ணினார்கள்.
	<br /><br />
	6. அந்தச் சத்தம் உண்டானபோது, திரளான ஜனங்கள் கூடிவந்து, தங்கள் தங்கள் பாஷையிலே அவர்கள் பேசுகிறதை அவரவர்கள் கேட்டபடியினாலே கலக்கமடைந்தார்கள்.
	<br /><br />
	7. எல்லாரும் பிரமித்து ஆச்சரியப்பட்டு, ஒருவரையொருவர் பார்த்து: இதோ, பேசுகிற இவர்களெல்லாரும் கலிலேயரல்லவா?
	<br /><br />
	8. அப்படியிருக்க, நம்மில் அவரவர்களுடைய ஜென்மபாஷைகளிலே இவர்கள் பேசக் கேட்கிறோமே, இதெப்படி?
	<br /><br />
	9. பார்த்தரும், மேதரும், எலாமீத்தரும், மெசொப்பொத்தாமியா, யூதேயா, கப்பத்தோக்கியா, பொந்து, ஆசியா, பிரிகியா,
	<br /><br />
	10. பம்பிலியா, எகிப்து என்னும் தேசத்தார்களும், சிரேனேபட்டணத்தைச் சுற்றியிருக்கிற லீபியாவின் திசைகளிலே குடியிருக்கிறவர்களும், இங்கே சஞ்சரிக்கிற ரோமாபுரியாரும், யூதரும், யூதமார்க்கத்தமைந்தவர்களும்,
	<br /><br />
	11. கிரேத்தரும், அரபியருமாகிய நாம் நம்முடைய பாஷைகளிலே இவர்கள் தேவனுடைய மகத்துவங்களைப் பேசக்கேட்கிறோமே என்றார்கள்.
	<br /><br />
	12. எல்லாரும் பிரமித்துச் சந்தேகப்பட்டு, இதென்னமாய் முடியுமோ என்று ஒருவரோடொருவர் சொல்லிக்கொண்டார்கள்.
	<br /><br />
	13. மற்றவர்களோ: இவர்கள் மதுபானத்தினால் நிறைந்திருக்கிறார்களென்று பரியாசம்பண்ணினார்கள்.
	<br /><br />
	14. அப்பொழுது பேதுரு பதினொருவரோடுங்கூட நின்று, அவர்களை நோக்கி, உரத்த சத்தமாய்: யூதர்களே, எருசலேமில் வாசம்பண்ணுகிற ஜனங்களே, நீங்களெல்லாரும் அறிந்துகொள்வீர்களாக, என் வார்த்தைகளுக்குச் செவிகொடுங்கள்.
	<br /><br />
	15. நீங்கள் நினைக்கிறபடி இவர்கள் வெறிகொண்டவர்களல்ல, பொழுது விடிந்து மூன்றாம் மணி வேளையாயிருக்கிறதே.
	<br /><br />
	16. தீர்க்கதரிசியாகிய யோவேலினால் உரைக்கப்பட்டபடியே இது நடந்தேறுகிறது.
	<br /><br />
	17. கடைசிநாட்களில் நான் மாம்சமான யாவர்மேலும் என் ஆவியை ஊற்றுவேன், அப்பொழுது உங்கள் குமாரரும் உங்கள் குமாரத்திகளும் தீர்க்கதரிசனஞ்சொல்லுவார்கள்; உங்கள் வாலிபர் தரிசனங்களை அடைவார்கள்; உங்கள் மூப்பர் சொப்பனங்களைக் காண்பார்கள்;
	<br /><br />
	18. என்னுடைய ஊழியக்காரர்மேலும், என்னுடைய ஊழியக்காரிகள்மேலும் அந்நாட்களில் என் ஆவியை ஊற்றுவேன், அப்பொழுது அவர்கள் தீர்க்கதரிசனஞ்சொல்லுவார்கள்.
	<br /><br />
	19. அல்லாமலும் உயர வானத்திலே அற்புதங்களையும், தாழப் பூமியிலே இரத்தம், அக்கினி, புகைக்காடாகிய அதிசயங்களையும் காட்டுவேன்.
	<br /><br />
	20. கர்த்தருடைய பெரிதும் பிரகாசமுமான நாள் வருமுன்னே சூரியன் இருளாகவும், சந்திரன் இரத்தமாகவும் மாறும்.
	<br /><br />
	21. அப்பொழுது கர்த்தருடைய நாமத்தைத் தொழுதுகொள்ளுகிறவனெவனோ அவன் இரட்சிக்கப்படுவான் என்று தேவன் உரைத்திருக்கிறார்.
	<br /><br />
	22. இஸ்ரவேலரே, நான் சொல்லும் வார்த்தைகளைக் கேளுங்கள்; நீங்கள் அறிந்திருக்கிறபடி நசரேயனாகிய இயேசுவைக்கொண்டு தேவன் உங்களுக்குள்ளே பலத்த செய்கைகளையும், அற்புதங்களையும், அடையாளங்களையும் நடப்பித்து, அவைகளினாலே அவரை உங்களுக்கு வெளிப்படுத்தினார்.
	<br /><br />
	23. அப்படியிருந்தும், தேவன் நிர்ணயித்திருந்த ஆலோசனையின்படியேயும், அவருடைய முன்னறிவின்படியேயும் ஒப்புக்கொடுக்கப்பட்ட அந்த இயேசுவை நீங்கள் பிடித்து, அக்கிரமக்காரருடைய கைகளினாலே சிலுவையில் ஆணியடித்துக் கொலைசெய்தீர்கள்.
	<br /><br />
	24. தேவன் அவருடைய மரண உபாதிகளின் கட்டை அவிழ்த்து, அவரை எழுப்பினார்; அவர் மரணத்தினால் கட்டப்பட்டிருக்கக்கூடாதிருந்தது.
	<br /><br />
	25. அவரைக்குறித்துத் தாவீது: கர்த்தரை எப்பொழுதும் எனக்கு முன்பாக நிறுத்தி நோக்கிக்கொண்டிருக்கிறேன்; நான் அசைக்கப்படாதபடி அவர் என் வலதுபாரிசத்திலே இருக்கிறார்;
	<br /><br />
	26. அதினாலே என் இருதயம் மகிழ்ந்தது, என் நாவு களிகூர்ந்தது, என் மாம்சமும் நம்பிக்கையோடே தங்கியிருக்கும்;
	<br /><br />
	27. என் ஆத்துமாவைப் பாதாளத்தில் விடீர், உம்முடைய பரிசுத்தர் அழிவைக்காணவொட்டீர்;
	<br /><br />
	28. ஜீவமார்க்கங்களை எனக்குத் தெரியப்படுத்தினீர்; உம்முடைய சந்நிதானத்திலே என்னைச் சந்தோஷத்தினால் நிரப்புவீர் என்று சொல்லுகிறான்.
	<br /><br />
	29. சகோதரரே, கோத்திரத்தலைவனாகிய தாவீதைக்குறித்து நான் உங்களுடனே தைரியமாய்ப் பேசுகிறதற்கு இடங்கொடுங்கள்; அவன் மரணமடைந்து அடக்கம்பண்ணப்பட்டான்; அவனுடைய கல்லறை இந்நாள்வரைக்கும் நம்மிடத்திலிருக்கிறது.
	<br /><br />
	30. அவன் தீர்க்கதரிசியாயிருந்து: உன் சிங்காசனத்தில் வீற்றிருக்க மாம்சத்தின்படி உன் சந்ததியிலே கிறிஸ்துவை எழும்பப்பண்ணுவேன் என்று தேவன் தனக்குச் சத்தியம்பண்ணினதை அறிந்தபடியால்,
	<br /><br />
	31. அவன் கிறிஸ்துவினுடைய ஆத்துமா பாதாளத்திலே விடப்படுவதில்லையென்றும், அவருடைய மாம்சம் அழிவைக் காண்பதில்லையென்றும் முன்னறிந்து, அவர் உயிர்த்தெழுதலைக்குறித்து இப்படிச் சொன்னான்.
	<br /><br />
	32. இந்த இயேசுவைத் தேவன் எழுப்பினார்; இதற்கு நாங்களெல்லாரும் சாட்சிகளாயிருக்கிறோம்.
	<br /><br />
	33. அவர் தேவனுடைய வலதுகரத்தினாலே உயர்த்தப்பட்டு, பிதா அருளிய வாக்குத்தத்தத்தின்படி பரிசுத்தஆவியைப் பெற்று, நீங்கள் இப்பொழுது காண்கிறதும் கேட்கிறதுமாகிய இதைப் பொழிந்தருளினார்.
	<br /><br />
	34. தாவீது பரலோகத்திற்கு எழுந்து போகவில்லையே. நான் உம்முடைய சத்துருக்களை உமக்குப் பாதபடியாக்கிப் போடும்வரைக்கும்,
	<br /><br />
	35. நீர் என் வலதுபாரிசத்தில் உட்காருமென்று கர்த்தர் என் ஆண்டவருடனே சொன்னார் என்று அவனே சொல்லியிருக்கிறான்.
	<br /><br />
	36. ஆகையினால், நீங்கள் சிலுவையில் அறைந்த இந்த இயேசுவையே தேவன் ஆண்டவரும் கிறிஸ்துவுமாக்கினாரென்று இஸ்ரவேல் குடும்பத்தார் யாவரும் நிச்சயமாய் அறியக்கடவர்கள் என்றான்.
	<br /><br />
	37. இதை அவர்கள் கேட்டபொழுது, இருதயத்திலே குத்தப்பட்டவர்களாகி, பேதுருவையும் மற்ற அப்போஸ்தலரையும் பார்த்து: சகோதரரே, நாங்கள் என்னசெய்யவேண்டும் என்றார்கள்.
	<br /><br />
	38. பேதுரு அவர்களை நோக்கி: நீங்கள் மனந்திரும்பி, ஒவ்வொருவரும் பாவமன்னிப்புக்கென்று இயேசுகிறிஸ்துவின் நாமத்தினாலே ஞானஸ்நானம் பெற்றுக்கொள்ளுங்கள், அப்பொழுது பரிசுத்தஆவியின் வரத்தைப் பெறுவீர்கள்.
	<br /><br />
	39. வாக்குத்தத்தமானது உங்களுக்கும், உங்கள் பிள்ளைகளுக்கும், நம்முடைய தேவனாகிய கர்த்தர் வரவழைக்கும் தூரத்திலுள்ள யாவருக்கும் உண்டாயிருக்கிறது என்று சொல்லி;
	<br /><br />
	40. இன்னும் அநேக வார்த்தைகளாலும் சாட்சிகூறி, மாறுபாடுள்ள இந்தச் சந்ததியை விட்டு விலகி உங்களை இரட்சித்துக்கொள்ளுங்கள் என்றும் புத்திசொன்னான்.
	<br /><br />
	41. அவனுடைய வார்த்தையைச் சந்தோஷமாய் ஏற்றுக்கொண்டவர்கள் ஞானஸ்நானம் பெற்றார்கள். அன்றையத்தினம் ஏறக்குறைய மூவாயிரம்பேர் சேர்த்துக்கொள்ளப்பட்டார்கள்.
	<br /><br />
	42. அவர்கள் அப்போஸ்தலருடைய உபதேசத்திலும், அந்நியோந்நியத்திலும், அப்பம் பிட்குதலிலும், ஜெபம்பண்ணுதலிலும் உறுதியாய்த் தரித்திருந்தார்கள்.
	<br /><br />
	43. எல்லாருக்கும் பயமுண்டாயிற்று. அப்போஸ்தலர்களாலே அநேக அற்புதங்களும் அடையாளங்களும் செய்யப்பட்டது.
	<br /><br />
	44. விசுவாசிகளெல்லாரும் ஒருமித்திருந்து, சகலத்தையும் பொதுவாய் வைத்து அநுபவித்தார்கள்.
	<br /><br />
	45. காணியாட்சிகளையும் ஆஸ்திகளையும் விற்று, ஒவ்வொருவனுக்கும் தேவையானதற்குத்தக்கதாக அவைகளில் எல்லாருக்கும் பகிர்ந்துகொடுத்தார்கள்.
	<br /><br />
	46. அவர்கள் ஒருமனப்பட்டவர்களாய்த் தேவாலயத்திலே அநுதினமும் தரித்திருந்து, வீடுகள்தோறும் அப்பம்பிட்டு மகிழ்ச்சியோடும் கபடமில்லாத இருதயத்தோடும் போஜனம்பண்ணி,
	<br /><br />
	47. தேவனைத் துதித்து, ஜனங்களெல்லாரிடத்திலும் தயவுபெற்றிருந்தார்கள். இரட்சிக்கப்படுகிறவர்களைக் கர்த்தர் அநுதினமும் சபையிலே சேர்த்துக்கொண்டு வந்தார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts2