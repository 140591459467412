import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zechariah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | சகரியா 1 </title>
	<meta
          name="description"
          content="Zechariah 1 | சகரியா 1 | Sageria 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தரியு அரசாண்ட இரண்டாம் வருஷம் எட்டாம் மாதத்திலே இத்தோவின் மகனான பெரகியாவின் குமாரனாகிய சகரியாவுக்கு உண்டான கர்த்தருடைய வார்த்தை:
	<br /><br />
	2. கர்த்தர் உங்கள் பிதாக்களின்மேல் கடுங்கோபமாயிருந்தார்.
	<br /><br />
	3. ஆகையால் நீ அவர்களை நோக்கி: சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால்: என்னிடத்தில் திரும்புங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்; அப்பொழுது நான் உங்களிடத்துக்குத் திரும்புவேன் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. உங்கள் பிதாக்களைப்போலிராதேயுங்கள்; முந்தின தீர்க்கதரிசிகள் அவர்களை நோக்கி: உங்கள் பொல்லாத வழிகளையும் உங்கள் பொல்லாத கிரியைகளையும் விட்டுத் திரும்புங்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்று கூப்பிட்டார்கள்; ஆனாலும் எனக்குச் செவிகொடாமலும் என்னைக் கவனியாமலும் போனார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	5. உங்கள் பிதாக்கள் எங்கே? தீர்க்கதரிசிகள் என்றென்றைக்கும் உயிரோடிருப்பார்களோ?
	<br /><br />
	6. இராமற்போனாலும், தீர்க்கதரிசிகளாகிய என் ஊழியக்காரருக்கு நான் கட்டளையிட்ட என் வார்த்தைகளும் என் தீர்மானங்களும் உங்கள் பிதாக்களிடத்தில் பலிக்கவில்லையோ? எங்கள் வழிகளின்படியேயும், எங்கள் கிரியைகளின்படியேயும் சேனைகளின் கர்த்தர் எங்களுக்குச் செய்ய நிர்ணயித்தபடியே எங்களுக்குச் செய்தாரென்று அவர்கள் திரும்பவந்து சொன்னதில்லையோ என்று சொல் என்றார்.
	<br /><br />
	7. தரியு அரசாண்ட இரண்டாம் வருஷம், சேபாத் மாதமாகிய பதினோராம் மாதம் இருபத்துநாலாந்தேதியிலே, கர்த்தருடைய வார்த்தை இத்தோவின் மகனான பெரகியாவின் குமாரன் சகரியா என்னும் தீர்க்கதரிசிக்கு உண்டாயிற்று; அவன் சொன்னது:
	<br /><br />
	8. இதோ, இன்று ராத்திரி சிவப்புக் குதிரையின்மேல் ஏறியிருந்த ஒரு புருஷனைக் கண்டேன்; அவர் பள்ளத்தாக்கில் இருக்கிற மிருதுச்செடிகளுக்குள்ளே நின்றார்; அவருக்குப் பின்னாலே சிவப்பும் மங்கின நிறமும் வெண்மையுமான குதிரைகள் இருந்தன.
	<br /><br />
	9. அப்பொழுது நான்: என் ஆண்டவரே, இவர்கள் யாரென்று கேட்டேன்; என்னோடே பேசுகிற தூதனானவர்: இவர்கள் யாரென்று நான் உனக்குக் காண்பிப்பேன் என்று சொன்னார்.
	<br /><br />
	10. அப்பொழுது மிருதுச்செடிகளுக்குள்ளே நின்ற அந்தப் புருஷன் பிரதியுத்தரமாக: இவர்கள் பூமியெங்கும் சுற்றிப்பார்க்கக் கர்த்தர் அனுப்பினவர்கள் என்றார்.
	<br /><br />
	11. பின்பு அவர்கள் மிருதுச்செடிகளுக்குள்ளே நின்ற கர்த்தருடைய தூதனை நோக்கி: நாங்கள் பூமியெங்கும் சுற்றிப்பார்த்தோம்; இதோ, பூமி முழுவதும் அமைதலும் அமரிக்கையுமாய் இருக்கிறது என்றார்கள்.
	<br /><br />
	12. அப்பொழுது கர்த்தருடைய தூதன் மறுமொழியாக: சேனைகளின் கர்த்தாவே, இந்த எழுபது வருஷமாய் நீர் கோபங்கொண்டிருக்கிற எருசலேமின்மேலும் யூதா பட்டணங்களின்மேலும் எந்தமட்டும் இரங்காதிருப்பீர் என்று சொல்ல,
	<br /><br />
	13. அப்பொழுது கர்த்தர், என்னோடே பேசின தூதனுக்கு நல்வார்த்தைகளையும் ஆறுதலான வார்த்தைகளையும் பிரதியுத்தரமாகச் சொன்னார்.
	<br /><br />
	14. அப்பொழுது என்னோடே பேசின தூதன் என்னை நோக்கி: சேனைகளின் கர்த்தர் உரைக்கிறது என்னவென்றால்: நான் எருசலேமுக்காகவும் சீயோனுக்காகவும் மகா வைராக்கியம் கொண்டிருக்கிறேன்.
	<br /><br />
	15. நான் கொஞ்சங் கோபங்கொண்டிருந்தபோது அவர்கள் தங்கள் கேட்டை அதிகரிக்கத் தேடினபடியினால், சுகமாய் வாழுகிற புறஜாதிகள்பேரில் நான் கடுங்கோபங்கொண்டேன்.
	<br /><br />
	16. ஆகையால் மனஉருக்கத்தோடே எருசலேமினிடத்தில் திரும்பினேன் என்று கர்த்தர் சொல்லுகிறார்; என் ஆலயம் அதிலே கட்டப்படும்; எருசலேமின்மேல் அளவுநூல் பிடிக்கப்படும் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்று கூறு என்றார்.
	<br /><br />
	17. இன்னும் என் பட்டணங்கள் நன்மையினால் பரம்பியிருக்கும்; இன்னும் கர்த்தர் சீயோனைத் தேற்றரவுபண்ணுவார்; இன்னும் எருசலேமைத் தெரிந்துகொள்ளுவார் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார் என்று பின்னும் கூறு என்றார்.
	<br /><br />
	18. நான் என் கண்களை ஏறெடுத்துப் பார்த்தபோது, இதோ, நாலு கொம்புகளைக் கண்டேன்.
	<br /><br />
	19. அவைகள் என்னவென்று என்னோடே பேசின தூதனைக் கேட்டேன்; அதற்கு அவர்: இவைகள் யூதாவையும் இஸ்ரவேலையும் எருசலேமையும் சிதறடித்த கொம்புகள் என்றார்.
	<br /><br />
	20. பின்பு கர்த்தர் எனக்கு நாலு தொழிலாளிகளைக் காண்பித்தார்.
	<br /><br />
	21. இவர்கள் என்னசெய்ய வருகிறார்களென்று கேட்டேன்; அதற்கு அவர்: ஒருவனும் தன் தலையை ஏறெடுக்கக்கூடாதபடி அந்தக் கொம்புகள் யூதாவைச் சிதறடித்ததே, அவைகளுக்குப் பயமுறுத்துகிறதற்கும், யூதாவின் தேசத்தைப் பாழாக்கத் தங்கள் கொம்பை எடுத்த ஜாதிகளுடைய கொம்புகளை விழத்தள்ளுகிறதற்கும் இவர்கள் வந்தார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zechariah1