import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah40 = () => {
  const verseNumber = 40;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 40 </title>
	<meta
          name="description"
          content="Jeremiah 40 | எரேமியா 40 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதாவின் ராஜாவாகிய சிதேக்கியா அரசாண்ட ஒன்பதாம் வருஷம் பத்தாம் மாதத்திலே பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரும் அவனுடைய எல்லா இராணுவமும் எருசலேமுக்கு விரோதமாய் வந்து, அதை முற்றிக்கைபோட்டார்கள்.
	<br /><br />
	2. சிதேக்கியா அரசாண்ட பதினோராம் வருஷம் நாலாம் மாதம், ஒன்பதாம் தேதியிலே நகரத்து மதிலில் திறப்புக்கண்டது.
	<br /><br />
	3. அப்பொழுது பாபிலோன் ராஜாவின் பிரபுக்களாகிய நெர்கல்சரேத்சேர், சம்கார்நேபோ, சர்சேகிம், ரப்சாரீஸ், நெர்கல்சரேத்சேர், ரப்மாக் என்பவர்களும், பாபி1. பாபிலோனுக்குக் கொண்டுபோகும்படி எருசலேமிலும் யூதாவிலும் சிறைகளாய்ப் பிடித்துவைக்கப்பட்ட ஜனங்களுக்குள் விலங்கிடப்பட்டிருந்த எரேமியாவைக் காவற்சேனாதிபதியாகிய நேபுசராதான் விடுதலையாக்கி ராமாவிலிருந்து அனுப்பிவிட்ட பின்பு, எரேமியாவுக்குக் கர்த்தரால் உண்டான வசனம்:
	<br /><br />
	2. காவற்சேனாதிபதி எரேமியாவை அழைப்பித்து, அவனை நோக்கி: உன் தேவனாகிய கர்த்தர் இந்த ஸ்தலத்துக்கு இந்தத் தீங்கு வருமென்று சொல்லியிருந்தார்.
	<br /><br />
	3. தாம் சொன்னபடியே கர்த்தர் வரப்பண்ணியுமிருக்கிறார்; நீங்கள் கர்த்தருக்கு விரோதமாய்ப் பாவஞ்செய்து, அவருடைய சத்தத்துக்குச் செவிகொடாமற்போனீர்கள்; ஆகையால் உங்களுக்கு இந்தக் காரியம் வந்தது.
	<br /><br />
	4. இப்போதும், இதோ, உன் கைகளிலிடப்பட்ட விலங்குகளை இன்று நீக்கிப்போட்டேன்; என்னோடேகூடப் பாபிலோனுக்கு வர உனக்கு நன்மையாய்த் தோன்றினால் வா, நான் உன்னைப் பத்திரமாய்ப் பார்த்துக்கொள்வேன்; என்னோடேகூடப் பாபிலோனுக்கு வர உனக்கு நன்றாய்த் தோன்றாவிட்டால், இருக்கட்டும்; இதோ, தேசமெல்லாம் உனக்கு முன்பாக இருக்கிறது, எவ்விடத்துக்குப்போக உனக்கு நன்மையும் செவ்வையுமாய்க் காண்கிறதோ அவ்விடத்துக்குப் போ என்றான்.
	<br /><br />
	5. அவன் இன்னும் போகாமலிருக்கும்போது, அவன் இவனை நோக்கி: நீ பாபிலோன் ராஜா யூதா பட்டணங்களின்மேல் அதிகாரியாக வைத்த சாப்பானுடைய குமாரனாகிய அகிக்காமின் மகனான கெதலியாவினிடத்துக்குத் திரும்பிப்போய், அவனோடே ஜனங்களுக்குள்ளே தங்கியிரு; இல்லாவிட்டால், எவ்விடத்துக்குப் போக உனக்குச் செவ்வையாய்த் தோன்றுகிறதோ, அவ்விடத்துக்குப் போ என்று சொல்லி, காவற்சேனாதிபதி அவனுக்கு வழிச்செலவையும் வெகுமதியையும் கொடுத்து அவனை அனுப்பிவிட்டான்.
	<br /><br />
	6. அப்படியே எரேமியா மிஸ்பாவுக்கு அகிக்காமின் குமாரனாகிய கெதலியாவினிடத்தில் போய், தேசத்தில் மீதியான ஜனங்களுக்குள் அவனோடே தங்கியிருந்தான்.
	<br /><br />
	7. பாபிலோன் ராஜா அகிக்காமின் குமாரனாகிய கெதலியாவைத் தேசத்தின்மேல் அதிகாரியாக்கினான் என்றும், பாபிலோனுக்குச் சிறைகளாகக் கொண்டுபோகப்பட்டிராத குடிகளில் ஏழைகளான புருஷரையும் ஸ்திரீகளையும் குழந்தைகளையும் அவனுடைய விசாரிப்புக்கு ஒப்புவித்தான் என்றும், வெளியிலிருக்கிற இராணுவச் சேர்வைக்காரர் அனைவரும் அவர்களுடைய மனுஷரும் கேட்டபோது,
	<br /><br />
	8. அவர்கள் மிஸ்பாவுக்குக் கெதலியாவினிடத்தில் வந்தார்கள்; யாரெனில், நெத்தானியாவின் குமாரனாகிய இஸ்மவேலும், கரேயாவின் குமாரராகிய யோகனானும், யோனத்தானும், தன்கூமேத்தின் குமாரனாகிய செராயாவும், நெத்தோபாத்தியனாகிய ஏப்பாயின் குமாரரும், மாகாத்தியனான ஒருவனுடைய குமாரனாகிய யெசனியாவும் ஆகிய இவர்களும் இவர்களைச் சேர்ந்தவர்களுமே.
	<br /><br />
	9. அப்பொழுது சாப்பானுடைய குமாரனாகிய அகிக்காமின் மகன் கெதலியா அவர்களையும் அவர்கள் மனுஷரையும் நோக்கி: நீங்கள் கல்தேயரைச் சேவிக்கப் பயப்படவேண்டாம், நீங்கள் தேசத்திலிருந்து பாபிலோன் ராஜாவைச் சேவியுங்கள்; அப்பொழுது உங்களுக்கு நன்மையுண்டாகும்.
	<br /><br />
	10. நானோவெனில், இதோ, நம்மிடத்திலே வருகிற கல்தேயரிடத்தில் சேவிக்கும்படி மிஸ்பாவிலே குடியிருக்கிறேன்; நீங்களோ போய், திராட்சரசத்தையும் பழங்களையும் எண்ணெயையும் சேர்த்து, உங்கள் பாண்டங்களில் வைத்து, உங்கள் வசமாயிருக்கிற ஊர்களில் குடியிருங்கள் என்று ஆணையிட்டுச் சொன்னான்.
	<br /><br />
	11. மோவாபிலும் அம்மோன் புத்திரரிடத்திலும் ஏதோமிலும் சகல தேசங்களிலும் இருக்கிற யூதரும், பாபிலோன் ராஜா யூதாவில் சிலர் மீதியாயிருக்கக் கட்டளையிட்டானென்றும், சாப்பானுடைய குமாரனாகிய அகிக்காமின் மகனான கெதலியாவை அவர்கள்மேல் அதிகாரியாக்கினானென்றும், கேட்டபோது,
	<br /><br />
	12. எல்லா யூதரும் தாங்கள் துரத்துண்ட எல்லா இடங்களிலுமிருந்து, யூதாதேசத்தில் கெதலியாவினிடத்தில் மிஸ்பாவுக்கு வந்து, திராட்சரசத்தையும் பழங்களையும் மிகுதியாய்ச் சேர்த்துவைத்தார்கள்.
	<br /><br />
	13. அப்பொழுது கரேயாவின் குமாரனாகிய யோகனானும் வெளியிலே இருந்த சகல இராணுவச் சேர்வைக்காரரும் மிஸ்பாவுக்குக் கெதலியாவினிடத்தில் வந்து,
	<br /><br />
	14. உம்மைக் கொன்றுபோடும்படிக்கு, அம்மோன் புத்திரரின் ராஜாவாகிய பாலிஸ் என்பவன், நெத்தானியாவின் குமாரனாகிய இஸ்மவேலை அனுப்பினானென்பதை நீர் அறியவில்லையோ என்றார்கள்; ஆனாலும் அகிக்காமின் குமாரனாகிய கெதலியா அவர்கள் வார்த்தையை நம்பவில்லை.
	<br /><br />
	15. பின்னும் கரேயாவின் குமாரனாகிய யோகனான் மிஸ்பாவிலே கெதலியாவோடே இரகசியமாய்ப் பேசி: நான் போய் ஒருவரும் அறியாமல் நெத்தானியாவின் குமாரனாகிய இஸ்மவேலை வெட்டிப்போட உத்தரவாகவேண்டும்; உம்மிடத்தில் சேர்ந்த யூதரெல்லாரும் சிதறுண்டுபோகவும் யூதாவில் மீந்தவர்கள் அழியவும் அவன் உம்மைக் கொன்றுபோடவேண்டியதென்ன என்றான்.
	<br /><br />
	16. ஆனாலும் அகிக்காமின் குமாரனாகிய கெதலியா கரேயாவின் குமாரனாகிய யோகனானை நோக்கி: நீ இந்தக் காரியத்தைச் செய்யாதே; இஸ்மவேலின்மேல் நீ பொய் சொல்லுகிறாய் என்றான்.லோன் ராஜாவின் மற்ற எல்லாப் பிரபுக்களும் உட்பிரவேசித்து, நடுவாசலில் இருந்தார்கள்.
	<br /><br />
	4. அப்பொழுது யூதாவின் ராஜாவாகிய சிதேக்கியாவும் சகல யுத்த மனுஷரும் அவர்களைக் கண்டபோது, ஓடி, இராத்திரிகாலத்தில் ராஜாவுடைய தோட்டத்துவழியே, இரண்டு மதில்களுக்கு நடுவான வாசலால் நகரத்திலிருந்து புறப்பட்டுப்போனார்கள்; அவன் வயல்வெளியின் வழியே போய்விட்டான்.
	<br /><br />
	5. ஆனாலும், கல்தேயருடைய இராணுவம் அவர்களைப் பின்தொடர்ந்து, எரிகோவின் சமபூமியில் சிதேக்கியாவைக் கிட்டி, அவனைப் பிடித்து, அவனை ஆமாத்தேசத்தின் ஊராகிய ரிப்லாவுக்கு, பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரிடத்தில் கொண்டுபோனார்கள்; அங்கே இவன் அவனைக் குறித்துத் தீர்ப்புச்செய்தான்.
	<br /><br />
	6. பின்பு பாபிலோன் ராஜா ரிப்லாவிலே, சிதேக்கியாவின் குமாரரை அவன் கண்களுக்கு முன்பாக வெட்டுவித்தான்; யூதா பிரபுக்கள் அனைவரையும் பாபிலோன் ராஜா வெட்டி,
	<br /><br />
	7. சிதேக்கியாவின் கண்களைக் கெடுத்து, அவனைப் பாபிலோனுக்குக் கொண்டுபோக அவனுக்கு இரண்டு வெண்கல விலங்குகளைப் போட்டான்.
	<br /><br />
	8. கல்தேயர் ராஜாவின் அரமனையையும் ஜனத்தின் வீடுகளையும் அக்கினியால் சுட்டெரித்து, எருசலேமின் மதில்களை இடித்துப்போட்டார்கள்.
	<br /><br />
	9. நகரத்தில் தங்கியிருந்த ஜனங்களையும், தன் பட்சத்தில் ஓடிவந்துவிட்டவர்களையும், மீதியான மற்ற ஜனங்களையும், காவற் சேனாதிபதியாகிய நேபுசராதான் பாபிலோனுக்குச் சிறைகளாகக் கொண்டுபோனான்.
	<br /><br />
	10. காவற்சேனாதிபதியாகிய நேபுசராதான் ஒன்றுமில்லாத ஏழைகளில் சிலரை யூதா தேசத்திலே வைத்து, அவர்களுக்கு அந்நாளிலே திராட்சத்தோட்டங்களையும் வயல்நிலங்களையும் கொடுத்தான்.
	<br /><br />
	11. ஆனாலும் எரேமியாவைக் குறித்து, பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் காவற்சேனாதிபதியாகிய நேபுசராதானை நோக்கி:
	<br /><br />
	12. நீ அவனை அழைப்பித்து, அவனுக்கு ஒரு பொல்லாப்பும் செய்யாமல், அவனைப் பத்திரமாய்ப் பார்த்து, அவன் உன்னோடே சொல்லுகிறபடியெல்லாம் அவனை நடத்தென்று கட்டளைகொடுத்தான்.
	<br /><br />
	13. அப்படியே காவற்சேனாதிபதியாகிய நேபுசராதானும், நேபுசஸ்பான், ரப்சாரீஸ், நெர்கல்சரேத்சேர், ரப்மாக் என்பவர்களும், பாபிலோன் ராஜாவின் எல்லாப் பிரபுக்களும்,
	<br /><br />
	14. எரேமியாவைக் காவற்சாலையின் முற்றத்திலிருந்து வரவழைத்து, அவனை வெளியே வீட்டுக்கு அழைத்துக்கொண்டுபோகும்படிக்கு அவனைச் சாப்பானுடைய குமாரனாகிய அகிக்காமின் மகனான கெதலியாவினிடத்தில் ஒப்புவித்தார்கள்; அப்படியே அவன் ஜனத்துக்குள்ளே தங்கியிருந்தான்.
	<br /><br />
	15. இதுவுமல்லாமல், எரேமியா இன்னும் காவற்சாலையின் முற்றத்திலே அடைக்கப்பட்டிருக்கையில், அவனுக்குக் கர்த்தரால் உண்டான வசனம்:
	<br /><br />
	16. நீ போய், எத்தியோப்பியனாகிய எபெத்மெலேக்குக்குச் சொல்லவேண்டியது என்னவென்றால், இதோ, என்னுடைய வார்த்தைகளை இந்த நகரத்தின்மேல் நன்மையாக அல்ல, தீமையாகவே வரப்பண்ணுவேன்; அவைகள் அந்நாளிலே உன் கண்களுக்கு முன்பாக நிறைவேறும் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. ஆனால் அந்நாளிலே உன்னைத் தப்புவிப்பேன் என்று கர்த்தர் சொல்லுகிறார்; நீ பயப்படுகிற மனுஷரின் கையிலே ஒப்புக்கொடுக்கப்படுவதில்லை.
	<br /><br />
	18. உன்னை நிச்சயமாக விடுவிப்பேன், நீ பட்டயத்துக்கு இரையாவதில்லை; நீ என்னை நம்பினபடியினால் உன் பிராணன் உனக்குக் கிடைத்த கொள்ளைப்பொருளைப்போல இருக்குமென்று கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah40