import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Amos7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | ஆமோஸ் 7 </title>
	<meta
          name="description"
          content="Amos 7 | ஆமோஸ் 7 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தராகிய ஆண்டவர் எனக்குக் காண்பித்ததாவது: இதோ, ராஜாவினுடைய புல்லறுப்புக்குப்பின்பு இரண்டாம் கந்தாயத்துப் புல் முளைக்கத் தொடங்குகையில் அவர் வெட்டுக்கிளிகளை உண்டாக்கினார்.
	<br /><br />
	2. அவைகள் தேசத்தின் புல்லைத்தின்று தீர்ந்தபோது, நான்: கர்த்தராகிய ஆண்டவரே, மன்னித்தருளும்; யாக்கோபு திரும்ப யாராலே எழுந்திருப்பான்? அவன் சிறுத்துப்போனான் என்றேன்.
	<br /><br />
	3. கர்த்தர் அதற்கு மனஸ்தாபப்பட்டு, அப்படி ஆவதில்லை என்றார்.
	<br /><br />
	4. கர்த்தராகிய ஆண்டவர் எனக்குக் காண்பித்ததாவது: இதோ, அக்கினியாலே நியாயம் விசாரிப்பேன் என்று கர்த்தராகிய ஆண்டவர் ஏற்பட்டார்; அது மகா ஆழியைப் பட்சித்தது, அதில் ஒரு பங்கைப் பட்சித்துத் தீர்ந்தது.
	<br /><br />
	5. அப்பொழுது நான்: கர்த்தராகிய ஆண்டவரே, நிறுத்துமே; யாக்கோபு திரும்ப யாராலே எழுந்திருப்பான்? அவன் சிறுத்துப்போனான் என்றேன்.
	<br /><br />
	6. கர்த்தர் அதற்கு மனஸ்தாபப்பட்டு, அப்படி ஆவதில்லை என்றார்.
	<br /><br />
	7. பின்பு அவர் எனக்குக் காண்பித்ததாவது: இதோ, தூக்குநூல் பிரமாணத்தினால் கட்டப்பட்ட ஒரு மதிலின்மேல் நின்றார்; அவர் கையில் தூக்குநூல் இருந்தது.
	<br /><br />
	8. கர்த்தர் என்னை நோக்கி: ஆமோசே, நீ என்னத்தைக் காண்கிறாய் என்றார்; தூக்குநூலைக் காண்கிறேன் என்றேன்; அப்பொழுது ஆண்டவர்: இதோ, இஸ்ரவேலென்னும் என் ஜனத்தின் நடுவே தூக்குநூலை விடுவேன்; இனி அவர்களை மன்னிக்கமாட்டேன்.
	<br /><br />
	9. ஈசாக்கின் மேடைகள் பாழும், இஸ்ரவேலின் பரிசுத்த ஸ்தலங்கள் அவாந்தரமுமாக்கப்படும்; நான் எரொபெயாம் வீட்டாருக்கு விரோதமாய்ப் பட்டயத்தோடே எழும்பிவருவேன் என்றார்.
	<br /><br />
	10. அப்பொழுது பெத்தேலில் ஆசாரியனான அமத்சியா இஸ்ரவேலின் ராஜாவாகிய எரொபெயாமுக்கு ஆள் அனுப்பி: ஆமோஸ் இஸ்ரவேல் வம்சத்தாரின் நடுவே உமக்கு விரோதமாகக் கட்டுப்பாடு பண்ணுகிறான்; தேசம் அவன் வார்த்தைகளையெல்லாம் சகிக்கமாட்டாது.
	<br /><br />
	11. எரொபெயாம் பட்டயத்தினால் சாவான் என்றும், இஸ்ரவேல் தன் தேசத்திலிருந்து சிறைபிடித்துக் கொண்டுபோகப்படுவான் என்றும் ஆமோஸ் சொல்லுகிறான் என்று சொல்லச்சொன்னான்.
	<br /><br />
	12. அமத்சியா ஆமோசை நோக்கி: தரிசனம் பார்க்கிறவனே, போ; நீ யூதாதேசத்துக்கு ஓடிப்போ, அங்கே அப்பம் தின்று, அங்கே தீர்க்கதரிசனம் சொல்லு.
	<br /><br />
	13. பெத்தேலிலே இனித் தீர்க்கதரிசனம் சொல்லாதே; அது ராஜாவின் பரிசுத்த ஸ்தலமும் ராஜ்யத்தின் அரமனையுமாயிருக்கிறது என்றான்.
	<br /><br />
	14. ஆமோஸ் அமத்சியாவுக்குப் பிரதியுத்தரமாக: நான் தீர்க்கதரிசியுமல்ல, தீர்க்கதரிசியின் புத்திரனுமல்ல; நான் மந்தை மேய்க்கிறவனும், காட்டத்திப்பழங்களைப் பொறுக்குகிறவனுமாயிருந்தேன்.
	<br /><br />
	15. ஆனால் மந்தையின் பின்னாலே போகிறபோது என்னைக் கர்த்தர் அழைத்து, நீ போய் என் ஜனமாகிய இஸ்ரவேலுக்கு விரோதமாகத் தீர்க்கதரிசனம் சொல்லு என்று கர்த்தர் உரைத்தார்.
	<br /><br />
	16. இப்போதும் நீ கர்த்தருடைய வார்த்தையைக் கேள்; இஸ்ரவேலுக்கு விரோதமாய்த் தீர்க்கதரிசனம் சொல்லாதே, ஈசாக்கின் வம்சத்தாருக்கு விரோதமாக ஒன்றையும் சொல்லாதே என்று சொல்லுகிறாயே.
	<br /><br />
	17. இதினிமித்தம்: உன் பெண்ஜாதி நகரத்தில் வேசியாவாள்; உன் குமாரரும் உன் குமாரத்திகளும் பட்டயத்தால் விழுவார்கள்; உன் வயல் அளவுநூலால் பங்கிட்டுக்கொள்ளப்படும்; நீயோவெனில் அசுத்தமான தேசத்திலே செத்துப்போவாய்; இஸ்ரவேலும் தன் தேசத்திலிருந்து சிறைபிடிக்கப்பட்டுக் கொண்டுபோகப்படுவான் என்று கர்த்தர் சொல்லுகிறார் என்றான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Amos7