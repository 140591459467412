import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Nahum1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | நாகூம் 1 </title>
	<meta
          name="description"
          content="Nahum 1 | நாகூம் 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நினிவேயின் பாரம். எல்கோசானாகிய நாகூமின் தரிசனப் புஸ்தகம்.
	<br /><br />
	2. கர்த்தர் எரிச்சலுள்ளவரும் நீதியைச் சரிக்கட்டுகிறவருமான தேவன்; கர்த்தர் நீதியைச் சரிக்கட்டுகிறவர், உக்கிரகோபமுள்ளவர்; கர்த்தர் தம்முடைய சத்துருக்களுக்குப் பிரதிபலன் அளிக்கிறவர், அவர் தம்முடைய பகைஞருக்காகக் கோபத்தை வைத்துவைக்கிறவர்.
	<br /><br />
	3. கர்த்தர் நீடிய சாந்தமும், மிகுந்த வல்லமையுமுள்ளவர்; அவர்களை ஆக்கினையில்லாமல் தப்புவிக்கமாட்டார்; கர்த்தருடைய வழி சுழல்காற்றிலும் பெருங்காற்றிலும் இருக்கிறது; மேகங்கள் அவருடைய பாதத்தூளாயிருக்கிறது.
	<br /><br />
	4. அவர் சமுத்திரத்தை அதட்டி, அதை வற்றிப்போகப்பண்ணி, சகல ஆறுகளையும் வறட்சியாக்குகிறார்; பாசானும் கர்மேலும் சோர்ந்து, லீபனோனின் செழிப்பு வாடிப்போகும்.
	<br /><br />
	5. அவர் சமுகத்தில் பர்வதங்கள் அதிர்ந்து மலைகள் கரைந்துபோகும்; அவர் பிரசன்னத்தினால் பூமியும் பூச்சக்கரமும் அதில் குடியிருக்கிற அனைவரோடும் எடுபட்டுப்போம்.
	<br /><br />
	6. அவருடைய கோபத்துக்கு முன்பாக நிற்பவன் யார்? அவருடைய உக்கிரகோபத்திலே தரிப்பவன் யார்? அவருடைய எரிச்சல் அக்கினியைப்போல இறைக்கப்படுகிறது; அவராலே கன்மலைகள் பேர்க்கப்படும்.
	<br /><br />
	7. கர்த்தர் நல்லவர், இக்கட்டுநாளிலே அரணான கோட்டை; தம்மை நம்புகிறவர்களை அறிந்திருக்கிறார்.
	<br /><br />
	8. ஆனாலும் நினிவேயின் ஸ்தானத்தை, புரண்டுவருகிற வெள்ளத்தினால் சர்வசங்காரம்பண்ணுவார்; இருள் அவர் சத்துருக்களைப் பின்தொடரும்.
	<br /><br />
	9. நீங்கள் கர்த்தருக்கு விரோதமாகச் செய்ய நினைக்கிறதென்ன? அவர் சர்வசங்காரம்பண்ணுவார்; இடுக்கம் மறுபடியும் உண்டாகாது.
	<br /><br />
	10. அவர்கள் சன்னபின்னலாயிருக்கிற முட்செடிகளுக்கு ஒப்பாயிருக்கையிலும், தங்கள் மதுபானத்தினால் வெறிகொண்டிருக்கையிலும், அவர்கள் முழுதும் காய்ந்துபோன செத்தையைப்போல எரிந்துபோவார்கள்.
	<br /><br />
	11. கர்த்தருக்கு விரோதமாகப் பொல்லாத நினைவுகொண்டிருக்கிற துராலோசனைக்காரன் ஒருவன் உன்னிடத்திலிருந்து புறப்பட்டான்.
	<br /><br />
	12. கர்த்தர் சொல்லுகிறது என்னவென்றால்: அவர்கள் சம்பூரணமடைந்து அநேகராயிருந்தாலும் அறுப்புண்டுபோவார்கள்; அவன் ஒழிந்துபோவான்; உன்னை நான் சிறுமைப்படுத்தினேன், இனி உன்னைச் சிறுமைப்படுத்தாதிருப்பேன்.
	<br /><br />
	13. இப்போதும் நான் உன்மேல் இருக்கிற அவன் நுகத்தை முறித்து, உன் கட்டுகளை அறுப்பேன்.
	<br /><br />
	14. உன்னைக்குறித்துக் கர்த்தர் கட்டளைகொடுத்திருக்கிறார்; இனி உன் பேருக்கு வித்துவிதைக்கப்படுவதில்லை; உன் தேவர்களின் கோவிலில் இருக்கிற வெட்டப்பட்ட விக்கிரகத்தையும், வார்க்கப்பட்ட விக்கிரகத்தையும் நான் நிர்மூலம்பண்ணுவேன்; நீ கனவீனனானபடியால் அதை உனக்குப் பிரேதக்குழியாக்குவேன்.
	<br /><br />
	15. இதோ, சமாதானத்தைக் கூறுகிற சுவிசேஷகனுடைய கால்கள் மலைகளின்மேல் வருகிறது; யூதாவே, உன் பண்டிகைகளை ஆசரி; உன் பொருத்தனைகளைச் செலுத்து; துஷ்டன் இனி உன் வழியாய்க் கடந்துவருவதில்லை, அவன் முழுவதும் சங்கரிக்கப்பட்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Nahum1