import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Galatians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | கலாத்தியருக்கு எழுதின நிருபம் 4 </title>
	<meta
          name="description"
          content="Galatians | கலாத்தியர் அதிகாரம் 4 | கலாத்தியருக்கு எழுதின நிருபம் |
          Book of Galatians | Chapter 4 | Tamil Bible Verse | Tamil Bible | புதிய ஏற்பாடு | TamilBible | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்னும் நான் சொல்லுகிறதென்னவெனில், சுதந்தரவாளியானவன் எல்லாவற்றிற்கும் எஜமானாயிருந்தும், அவன் சிறுபிள்ளையாயிருக்குங்காலமளவும், அவனுக்கும் அடிமையானவனுக்கும் வித்தியாசமில்லை.
	<br /><br />
	2. தகப்பன் குறித்த காலம்வரைக்கும் அவன் காரியக்காரருக்கும் வீட்டு விசாரணைக்காரருக்கும் கீழ்ப்பட்டிருக்கிறான்.
	<br /><br />
	3. அப்படியே நாமும் சிறுபிள்ளைகளாயிருந்த காலத்தில் இவ்வுலகத்தின் வழிபாடுகளுக்கு அடிமைப்பட்டவர்களாயிருந்தோம்.
	<br /><br />
	4. நாம் புத்திரசுவிகாரத்தையடையும்படி நியாயப்பிரமாணத்திற்குக் கீழ்ப்பட்டவர்களை மீட்டுக்கொள்ளத்தக்கதாக,
	<br /><br />
	5. காலம் நிறைவேறினபோது, ஸ்திரீயினிடத்திற் பிறந்தவரும் நியாயப்பிரமாணத்திற்குக் கீழானவருமாகிய தம்முடைய குமாரனைத் தேவன் அனுப்பினார்.
	<br /><br />
	6. மேலும் நீங்கள் புத்திரராயிருக்கிறபடியினால், அப்பா, பிதாவே! என்று கூப்பிடத்தக்கதாகத் தேவன் தமது குமாரனுடைய ஆவியை உங்கள் இருதயங்களில் அனுப்பினார்.
	<br /><br />
	7. ஆகையால் இனி நீ அடிமையாயிராமல் புத்திரனாயிருக்கிறாய்; நீ புத்திரனேயானால், கிறிஸ்துமூலமாய்த் தேவனுடைய சுதந்தரனாயுமிருக்கிறாய்.
	<br /><br />
	8. நீங்கள் தேவனை அறியாமலிருந்தபோது, சுபாவத்தின்படி தேவர்களல்லாதவர்களுக்கு அடிமைகளாயிருந்தீர்கள்.
	<br /><br />
	9. இப்பொழுதோ நீங்கள் தேவனை அறிந்திருக்க, அல்லது தேவனாலே அறியப்பட்டிருக்க, பெலனற்றதும் வெறுமையானதுமான அவ்வழிபாடுகளுக்கு நீங்கள் மறுபடியும் திரும்பி, மறுபடியும் அவைகளுக்கு அடிமைப்படும்படி விரும்புகிறதெப்படி?
	<br /><br />
	10. நாட்களையும், மாதங்களையும், காலங்களையும், வருஷங்களையும் பார்க்கிறீர்களே.
	<br /><br />
	11. நான் உங்களுக்காகப் பிரயாசப்பட்டது வீணாய்ப்போயிற்றோ என்று உங்களைக்குறித்துப் பயந்திருக்கிறேன்.
	<br /><br />
	12. சகோதரரே, என்னைப்போலாகுங்கள் என்று உங்களை வேண்டிக்கொள்ளுகிறேன்; நான் உங்களைப்போலுமானேனே. எனக்கு நீங்கள் அநியாயம் ஒன்றும் செய்யவில்லை.
	<br /><br />
	13. உங்களுக்குத் தெரிந்திருக்கிறபடி, நான் சரீர பலவீனத்தோடு முதலாந்தரம் உங்களுக்குச் சுவிசேஷத்தைப் பிரசங்கித்தேன்.
	<br /><br />
	14. அப்படியிருந்தும், என் சரீரத்தில் உண்டாயிருக்கிற சோதனையை நீங்கள் அசட்டைபண்ணாமலும், அரோசியாமலும், தேவதூதனைப்போலவும், கிறிஸ்து இயேசுவைப்போலவும், என்னை ஏற்றுக்கொண்டீர்கள்.
	<br /><br />
	15. அப்பொழுது நீங்கள் கொண்டிருந்த ஆனந்த பாக்கியம் எங்கே? உங்கள் கண்களைப் பிடுங்கி எனக்குக் கொடுக்கக்கூடுமானால், அப்படியும் செய்திருப்பீர்கள் என்று உங்களுக்குச் சாட்சியாயிருக்கிறேன்.
	<br /><br />
	16. நான் உங்களுக்குச் சத்தியத்தைச் சொன்னதினாலே உங்களுக்குச் சத்துருவானேனோ?
	<br /><br />
	17. அவர்கள் உங்களை நாடி வைராக்கியம் பாராட்டுகிறார்கள்; ஆகிலும் நல்மனதோடே அப்படிச் செய்யாமல், நீங்கள் அவர்களை நாடி வைராக்கியம் பாராட்டும்பொருட்டு உங்களைப் புறம்பாக்க விரும்புகிறார்கள்.
	<br /><br />
	18. நல்விஷயத்தில் வைராக்கியம் பாராட்டுவது நல்லதுதான்; அதை நான் உங்களிடத்தில் இருக்கும்பொழுதுமாத்திரமல்ல, எப்பொழுதும் பாராட்டவேண்டும்.
	<br /><br />
	19. என் சிறுபிள்ளைகளே, கிறிஸ்து உங்களிடத்தில் உருவாகுமளவும் உங்களுக்காக மறுபடியும் கர்ப்பவேதனைப்படுகிறேன்;
	<br /><br />
	20. உங்களைக்குறித்து நான் சந்தேகப்படுகிறபடியால், நான் இப்பொழுது உங்களிடத்தில் வந்திருந்து, வேறுவகையாகப் பேச விரும்புகிறேன்.
	<br /><br />
	21. நியாயப்பிரமாணத்திற்குக் கீழ்ப்பட்டிருக்க விரும்புகிற நீங்கள் நியாயப்பிரமாணம் சொல்லுகிறதைக் கேட்கவில்லையா? இதை எனக்குச் சொல்லுங்கள்.
	<br /><br />
	22. ஆபிரகாமுக்கு இரண்டு குமாரர் இருந்தார்கள் என்று எழுதியிருக்கிறது; ஒருவன் அடிமையானவளிடத்தில் பிறந்தவன், ஒருவன் சுயாதீனமுள்ளவளிடத்தில் பிறந்தவன்.
	<br /><br />
	23. அடிமையானவளிடத்தில் பிறந்தவன் மாம்சத்தின்படி பிறந்தான், சுயாதீனமுள்ளவளிடத்தில் பிறந்தவன் வாக்குத்தத்தத்தின்படி பிறந்தான்.
	<br /><br />
	24. இவைகள் ஞான அர்த்தமுள்ளவைகள்; அந்த ஸ்திரீகள் இரண்டு ஏற்பாடுகளாம்; ஒன்று சீனாய்மலையிலுண்டான ஏற்பாடு, அது அடிமைத்தனத்திற்குள்ளாகப் பிள்ளைபெறுகிறது, அது ஆகார் என்பவள்தானே.
	<br /><br />
	25. ஆகார் என்பது அரபிதேசத்திலுள்ள சீனாய்மலை; அந்த ஆகார் இப்பொழுதிருக்கிற எருசலேமுக்குச் சரி; இவள் தன் பிள்ளைகளோடேகூட அடிமைப்பட்டிருக்கிறாளே.
	<br /><br />
	26. மேலான எருசலேமோ சுயாதீனமுள்ளவள், அவளே நம்மெல்லாருக்கும் தாயானவள்.
	<br /><br />
	27. அந்தப்படி பிள்ளைபெறாத மலடியே, மகிழ்ந்திரு; கர்ப்பவேதனைப்படாதவளே, களிப்பாய் எழும்பி ஆர்ப்பரி; புருஷனுள்ளவளைப்பார்க்கிலும் அநாத ஸ்திரீக்கே அதிக பிள்ளைகளுண்டு என்று எழுதியிருக்கிறது.
	<br /><br />
	28. சகோதரரே, நாம் ஈசாக்கைப்போல வாக்குத்தத்தத்துப் பிள்ளைகளாயிருக்கிறோம்.
	<br /><br />
	29. ஆகிலும் மாம்சத்தின்படி பிறந்தவன் ஆவியின்படி பிறந்தவனை அப்பொழுது துன்பப்படுத்தினதுபோல, இப்பொழுதும் நடந்துவருகிறது.
	<br /><br />
	30. அதைக்குறித்து வேதம் என்ன சொல்லுகிறது: அடிமையானவளின் மகன் சுயாதீனமுள்ளவளுடைய குமாரனோடே சுதந்தரவாளியாயிருப்பதில்லை; ஆகையால் அடிமையானவளையும், அவளுடைய மகனையும் புறம்பே தள்ளு என்று சொல்லுகிறது.
	<br /><br />
	31. இப்படியிருக்க, சகோதரரே, நாம் அடிமையானவளுக்குப் பிள்ளைகளாயிராமல், சுயாதீனமுள்ளவளுக்கே பிள்ளைகளாயிருக்கிறோம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Galatians4