import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 22 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பஸ்கா என்னப்பட்ட புளிப்பில்லாத அப்பப்பண்டிகை சமீபமாயிற்று.
	<br /><br />
	2. அப்பொழுது பிரதான ஆசாரியரும் வேதபாரகரும் அவரைக் கொலைசெய்யும்படி யோசித்து, ஜனங்களுக்குப் பயப்பட்டபடியினால், எவ்விதமாய் அப்படிச் செய்யலாமென்று வகைதேடினார்கள்.
	<br /><br />
	3. அப்பொழுது பன்னிருவரில் ஒருவனாகிய ஸ்காரியோத்தென்னும் மறுபேர்கொண்ட யூதாசுக்குள் சாத்தான் புகுந்தான்.
	<br /><br />
	4. அவன் பிரதான ஆசாரியர்களிடத்திலும் சேனைத்தலைவர்களிடத்திலும் போய், அவரைக் காட்டிக்கொடுக்கும் வகையைக் குறித்து அவர்களோடே ஆலோசனை பண்ணினான்.
	<br /><br />
	5. அவர்கள் சந்தோஷப்பட்டு, அவனுக்குப் பணங்கொடுக்க உடன்பட்டார்கள்.
	<br /><br />
	6. அதற்கு அவன் சம்மதித்து, ஜனக்கூட்டமில்லாத வேளையில் அவரை அவர்களுக்குக் காட்டிக்கொடுக்கும்படி சமயந்தேடினான்.
	<br /><br />
	7. பஸ்காவைப் பலியிடவேண்டிய புளிப்பில்லாத அப்பப்பண்டிகை நாள் வந்தது.
	<br /><br />
	8. அப்பொழுது அவர் பேதுருவையும் யோவானையும் அழைத்து: நாம் பஸ்காவைப் புசிக்கும்படிக்கு நீங்கள் போய், அதை நமக்கு ஆயத்தம்பண்ணுங்கள் என்றார்.
	<br /><br />
	9. அதற்கு அவர்கள்: நாங்கள் அதை எங்கே ஆயத்தம்பண்ணும்படி சித்தமாயிருக்கிறீர் என்று கேட்டார்கள்.
	<br /><br />
	10. அதற்கு அவர்: நீங்கள் நகரத்தில் பிரவேசிக்கும்போது, தண்ணீர்க்குடம் சுமந்துவருகிற ஒரு மனுஷன் உங்களுக்கு எதிர்ப்படுவான்; நீங்கள் அவனுக்குப் பின்சென்று, அவன் போகும் வீட்டிற்குள் நீங்களும் போய்,
	<br /><br />
	11. அந்த வீட்டெஜமானை நோக்கி: நான் என் சீஷரோடுகூடப் பஸ்காவைப் புசிக்கிறதற்குத் தகுதியான இடம் எங்கே என்று போதகர் உம்மிடத்தில் கேட்கச் சொன்னார் என்று சொல்லுங்கள்.
	<br /><br />
	12. அவன் கம்பளமுதலானவைகள் விரித்திருக்கிற மேல்வீட்டிலுள்ள ஒரு பெரிய அறையை உங்களுக்குக் காண்பிப்பான்; அங்கே ஆயத்தம்பண்ணுங்கள் என்று சொல்லி அனுப்பினார்.
	<br /><br />
	13. அவர்கள் போய், தங்களிடத்தில் அவர் சொன்னபடியே கண்டு, பஸ்காவை ஆயத்தம்பண்ணினார்கள்.
	<br /><br />
	14. வேளைவந்தபோது, அவரும் அவருடனேகூடப் பன்னிரண்டு அப்போஸ்தலரும் பந்தியிருந்தார்கள்.
	<br /><br />
	15. அப்பொழுது அவர் அவர்களை நோக்கி: நான் பாடுபடுகிறதற்கு முன்னே உங்களுடனேகூட இந்தப் பஸ்காவைப் புசிக்க மிகவும் ஆசையாயிருந்தேன்.
	<br /><br />
	16. தேவனுடைய ராஜ்யத்திலே இது நிறைவேறுமளவும் நான் இனி இதைப் புசிப்பதில்லையென்று உங்களுக்குச் சொல்லுகிறேன் என்று சொல்லி,
	<br /><br />
	17. அவர் பாத்திரத்தை எடுத்து, ஸ்தோத்திரம்பண்ணி: நீங்கள் இதை வாங்கி, உங்களுக்குள்ளே பங்கிட்டுக் கொள்ளுங்கள்;
	<br /><br />
	18. தேவனுடைய ராஜ்யம் வருமளவும் நான் திராட்சப்பழரசத்தைப் பானம்பண்ணுவதில்லையென்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	19. பின்பு அவர் அப்பத்தை எடுத்து, ஸ்தோத்திரம்பண்ணி, அதைப் பிட்டு, அவர்களுக்குக் கொடுத்து: இது உங்களுக்காகக் கொடுக்கப்படுகிற என்னுடைய சரீரமாயிருக்கிறது; என்னை நினைவுகூரும்படி இதைச் செய்யுங்கள் என்றார்.
	<br /><br />
	20. போஜனம்பண்ணினபின்பு அவர் அந்தப்படியே பாத்திரத்தையும் கொடுத்து: இந்தப் பாத்திரம் உங்களுக்காகச் சிந்தப்படுகிற என்னுடைய இரத்தத்தினாலாகிய புதிய உடன்படிக்கையாயிருக்கிறது என்றார்.
	<br /><br />
	21. பின்பு: இதோ, என்னைக் காட்டிக்கொடுக்கிறவனுடைய கை என்னுடனேகூடப் பந்தியிலிருக்கிறது.
	<br /><br />
	22. தீர்மானிக்கப்பட்டபடியே மனுஷகுமாரன் போகிறார், ஆனாலும் அவரைக் காட்டிக்கொடுக்கிற மனுஷனுக்கு ஐயோ என்றார்.
	<br /><br />
	23. அப்பொழுது அவர்கள் நம்மில் யார் அப்படிச் செய்வான் என்று தங்களுக்குள்ளே விசாரிக்கத் தொடங்கினார்கள்.
	<br /><br />
	24. அன்றியும் தங்களில் எவன் பெரியவனாயிருப்பான் என்று அவர்களுக்குள்ளே வாக்குவாதம் உண்டாயிற்று.
	<br /><br />
	25. அவர் அவர்களை நோக்கி: புறஜாதியாரின் ராஜாக்கள் அவர்களை ஆளுகிறார்கள்; அவர்கள்மேல் அதிகாரம் செலுத்துகிறவர்களும் உபகாரிகள் என்னப்படுகிறார்கள்.
	<br /><br />
	26. உங்களுக்குள்ளே அப்படியிருக்கக்கூடாது; உங்களில் பெரியவன் சிறியவனைப்போலவும், தலைவன் பணிவிடைக்காரனைப்போலவும் இருக்கக்கடவன்.
	<br /><br />
	27. பந்தியிருக்கிறவனோ, பணிவிடைசெய்கிறவனோ, எவன் பெரியவன்? பந்தியிருக்கிறவன் அல்லவா? அப்படியிருந்தும், நான் உங்கள் நடுவிலே பணிவிடைக்காரனைப்போல் இருக்கிறேன்.
	<br /><br />
	28. மேலும் எனக்கு நேரிட்ட சோதனைகளில் என்னோடேகூட நிலைத்திருந்தவர்கள் நீங்களே.
	<br /><br />
	29. ஆகையால், என் பிதா எனக்கு ஒரு ராஜ்யத்தை ஏற்படுத்தினதுபோல, நானும் உங்களுக்கு ஏற்படுத்துகிறேன்.
	<br /><br />
	30. நீங்கள் என் ராஜ்யத்திலே என் பந்தியில் போஜனபானம்பண்ணி, இஸ்ரவேலின் பன்னிரண்டு கோத்திரங்களையும் நியாயந்தீர்க்கிறவர்களாய்ச் சிங்காசனங்களின்மேல் உட்காருவீர்கள் என்றார்.
	<br /><br />
	31. பின்னும் கர்த்தர்: சீமோனே, சீமோனே, இதோ, கோதுமையைச் சுளகினால் புடைக்கிறதுபோலச் சாத்தான் உங்களைப் புடைக்கிறதற்கு உத்தரவு கேட்டுக்கொண்டான்.
	<br /><br />
	32. நானோ உன் விசுவாசம் ஒழிந்துபோகாதபடிக்கு உனக்காக வேண்டிக்கொண்டேன்; நீ குணப்பட்டபின்பு உன் சகோதரரை ஸ்திரப்படுத்து என்றார்.
	<br /><br />
	33. அதற்கு அவன்: ஆண்டவரே, காவலிலும் சாவிலும் உம்மைப் பின்பற்றிவர, ஆயத்தமாயிருக்கிறேன் என்றான்.
	<br /><br />
	34. அவர் அவனை நோக்கி: பேதுருவே, இன்றைக்குச் சேவல் கூவுகிறதற்கு முன்னே நீ என்னை அறிந்திருக்கிறதை மூன்றுதரம் மறுதலிப்பாய் என்று உனக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	35. பின்னும் அவர் அவர்களை நோக்கி: நான் உங்களைப் பணப்பையும் சாமான்பையும் பாதரட்சைகளும் இல்லாமல் அனுப்பினபோது, ஏதாகிலும் உங்களுக்குக் குறைவாயிருந்ததா என்றார். அவர்கள், ஒன்றும் குறைவாயிருந்ததில்லை என்றார்கள்.
	<br /><br />
	36. அதற்கு அவர்: இப்பொழுதோ பணப்பையும் சாமான்பையும் உடையவன் அவைகளை எடுத்துக்கொள்ளக்கடவன்; பட்டயம் இல்லாதவன் தன் வஸ்திரத்தை விற்று ஒன்றைக் கொள்ளக்கடவன்.
	<br /><br />
	37. அக்கிரமக்காரரில் ஒருவனாக எண்ணப்பட்டார் என்று எழுதியிருக்கிற வாக்கியம் என்னிடத்தில் நிறைவேறவேண்டியதென்று உங்களுக்குச் சொல்லுகிறேன். என்னைப்பற்றிய காரியங்கள் முடிவு பெறுங்காலம் வந்திருக்கிறது என்றார்.
	<br /><br />
	38. அதற்கு அவர்கள்: ஆண்டவரே, இதோ, இங்கே இரண்டு பட்டயம் இருக்கிறது என்றார்கள். அவர்: போதும் என்றார்.
	<br /><br />
	39. பின்பு அவர் புறப்பட்டு, வழக்கத்தின்படியே ஒலிவமலைக்குப் போனார், அவருடைய சீஷரும் அவரோடேகூடப் போனார்கள்.
	<br /><br />
	40. அவ்விடத்தில் சேர்ந்தபொழுது அவர் அவர்களை நோக்கி: நீங்கள் சோதனைக்குட்படாதபடிக்கு ஜெபம்பண்ணுங்கள் என்று சொல்லி,
	<br /><br />
	41. அவர்களை விட்டுக் கல்லெறிதூரம் அப்புறம்போய், முழங்கால்படியிட்டு:
	<br /><br />
	42. பிதாவே, உமக்குச் சித்தமானால் இந்தப் பாத்திரம் என்னைவிட்டு நீங்கும்படி செய்யும்; ஆயினும் என்னுடைய சித்தத்தின்படியல்ல, உம்முடைய சித்தத்தின்படியே ஆகக்கடவது என்று ஜெபம்பண்ணினார்.
	<br /><br />
	43. அப்பொழுது வானத்திலிருந்து ஒரு தூதன் தோன்றி, அவரைப் பலப்படுத்தினான்.
	<br /><br />
	44. அவர் மிகவும் வியாகுலப்பட்டு, அதிக ஊக்கத்தோடே ஜெபம்பண்ணினார். அவருடைய வேர்வை இரத்தத்தின் பெருந்துளிகளாய்த் தரையிலே விழுந்தது.
	<br /><br />
	45. அவர் ஜெபம்பண்ணி முடித்து, எழுந்திருந்து, தம்முடைய சீஷரிடத்தில் வந்து, அவர்கள் துக்கத்தினாலே நித்திரை பண்ணுகிறதைக் கண்டு:
	<br /><br />
	46. நீங்கள் நித்திரைபண்ணுகிறதென்ன? சோதனைக்குட்படாதபடிக்கு, எழுந்திருந்து ஜெபம்பண்ணுங்கள் என்றார்.
	<br /><br />
	47. அவர் அப்படிப் பேசுகையில் ஜனங்கள் கூட்டமாய் வந்தார்கள். அவர்களுக்கு முன்னே பன்னிருவரில் ஒருவனாகிய யூதாஸ் என்பவனும் வந்து, இயேசுவை முத்தஞ்செய்யும்படி அவரிடத்தில் சேர்ந்தான்.
	<br /><br />
	48. இயேசு அவனை நோக்கி: யூதாசே, முத்தத்தினாலேயா மனுஷகுமாரனைக் காட்டிக்கொடுக்கிறாய் என்றார்.
	<br /><br />
	49. அவரைச் சூழநின்றவர்கள் நடக்கப்போகிறதைக் கண்டு: ஆண்டவரே, பட்டயத்தினாலே வெட்டுவோமா என்றார்கள்.
	<br /><br />
	50. அந்தப்படியே அவர்களில் ஒருவன் பிரதான ஆசாரியனுடைய வேலைக்காரனை வலதுகாதற வெட்டினான்.
</p>
<p>
	51. அப்பொழுது இயேசு: இம்மட்டில் நிறுத்துங்கள் என்று சொல்லி, அவனுடைய காதைத்தொட்டு, அவனைச் சொஸ்தப்படுத்தினார்.
	<br /><br />
	52. பின்பு இயேசு தமக்கு விரோதமாய் வந்த பிரதான ஆசாரியர்களையும் தேவாலயத்துச் சேனைத்தலைவர்களையும் மூப்பர்களையும் நோக்கி: ஒரு கள்ளனைப் பிடிக்கப் புறப்பட்டு வருகிறதுபோல, நீங்கள் பட்டயங்களையும் தடிகளையும் எடுத்துப் புறப்பட்டு வந்தீர்களே.
	<br /><br />
	53. நான் தினந்தோறும் தேவாலயத்தில் உங்களுடனேகூட இருக்கையில் நீங்கள் என்னைப் பிடிக்கக் கைநீட்டவில்லை; இதுவோ உங்களுடைய வேளையும் அந்தகாரத்தின் அதிகாரமுமாயிருக்கிறது என்றார்.
	<br /><br />
	54. அவர்கள் அவரைப் பிடித்தபின்பு, பிரதான ஆசாரியனுடைய வீட்டில் கொண்டுபோய் விட்டார்கள். பேதுருவும் தூரத்திலே பின்சென்றான்.
	<br /><br />
	55. அவர்கள் முற்றத்தின் நடுவிலே நெருப்பை மூட்டி, அதைச் சுற்றி உட்கார்ந்தபோது, பேதுருவும் அவர்கள் நடுவிலே உட்கார்ந்தான்.
	<br /><br />
	56. அப்பொழுது ஒரு வேலைக்காரி அவனை நெருப்பண்டையிலே உட்கார்ந்திருக்கக்கண்டு, அவனை உற்றுப்பார்த்து: இவனும் அவனோடிருந்தான் என்றாள்.
	<br /><br />
	57. அதற்கு அவன்: ஸ்திரீயே, அவனை அறியேன் என்று மறுதலித்தான்.
	<br /><br />
	58. சற்றுநேரத்துக்குப்பின்பு வேறொருவன் அவனைக் கண்டு: நீயும் அவர்களில் ஒருவன் என்றான். அதற்குப் பேதுரு: மனுஷனே, நான் அல்ல என்றான்.
	<br /><br />
	59. ஏறக்குறைய ஒருமணி நேரத்துக்குப்பின்பு வேறொருவன் அவனைப் பார்த்து: மெய்யாகவே இவனும் அவனோடிருந்தான், இவன் கலிலேயன்தான் என்று சாதித்தான்.
	<br /><br />
	60. அதற்குப் பேதுரு: மனுஷனே, நீ சொல்லுகிறதை அறியேன் என்றான். அவன் இப்படிச் சொன்னவுடனே சேவல் கூவிற்று.
	<br /><br />
	61. அப்பொழுது கர்த்தர் திரும்பி, பேதுருவை நோக்கிப் பார்த்தார். சேவல் கூவுகிறதற்கு முன்னே நீ என்னை மூன்றுதரம் மறுதலிப்பாய் என்று கர்த்தர் தன்னிடத்தில் சொன்ன வசனத்தை உடனே பேதுரு நினைவுகூர்ந்து,
	<br /><br />
	62. வெளியே போய், மனங்கசந்து அழுதான்.
	<br /><br />
	63. இயேசுவைப் பிடித்துக்கொண்ட மனுஷர் அவரைப் பரியாசம்பண்ணி, அடித்து,
	<br /><br />
	64. அவருடைய கண்களைக் கட்டி, அவருடைய முகத்தில் அறைந்து: உன்னை அடித்தவன் யார், அதை ஞானதிருஷ்டியினால் சொல் என்று அவரைக் கேட்டதுமன்றி,
	<br /><br />
	65. மற்றும் அநேக தூஷணவார்த்தைகளையும் அவருக்கு விரோதமாகச் சொன்னார்கள்.
	<br /><br />
	66. விடியற்காலமானபோது ஜனத்தின் மூப்பரும் பிரதான ஆசாரியரும் வேதபாரகரும் கூடிவந்து, தங்கள் ஆலோசனைச் சங்கத்தில் அவரைக் கொண்டுவந்து நிறுத்தி:
	<br /><br />
	67. நீ கிறிஸ்துவா? அதை எங்களுக்குச் சொல் என்றார்கள். அதற்கு அவர்: நான் உங்களுக்குச் சொன்னாலும் நம்பமாட்டீர்கள்.
	<br /><br />
	68. நான் உங்களிடத்தில் வினாவினாலும் எனக்கு மாறுத்தரம் சொல்லமாட்டீர்கள், என்னை விடுதலைபண்ணவுமாட்டீர்கள்.
	<br /><br />
	69. இதுமுதல் மனுஷகுமாரன் சர்வவல்லமையுள்ள தேவனுடைய வலதுபாரிசத்தில் வீற்றிருப்பார் என்றார்.
	<br /><br />
	70. அதற்கு அவர்களெல்லாரும்: அப்படியானால், நீ தேவனுடைய குமாரனா என்று கேட்டார்கள். அதற்கு அவர்: நீங்கள் சொல்லுகிறபடியே நான் அவர்தான் என்றார்.
	<br /><br />
	71. அப்பொழுது அவர்கள்: இனி வேறு சாட்சி நமக்கு வேண்டுவதென்ன? நாமே இவனுடைய வாயினாலே கேட்டோமே என்றார்கள்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke22