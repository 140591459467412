import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneJohn1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> The Word of Life | 1 John in Tamil Bible Verse | 1 யோவான்- 1 </title>
	<meta
          name="description"
          content="The Word of Life | 1st John in Tamil Bible Verse | 1-யோவான்அதிகாரம் - 1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible Tamil"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* வாழ்க்கையின் வார்த்தை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	. ஆதிமுதல் இருந்ததும், நாங்கள் கேட்டதும், எங்கள் கண்களினாலே கண்டதும், நாங்கள் நோக்கிப்பார்த்ததும், எங்கள் கைகளினாலே தொட்டதுமாயிருக்கிற ஜீவவார்த்தையைக்குறித்து உங்களுக்கு அறிவிக்கிறோம்.
	<br /><br />
	2. அந்த ஜீவன் வெளிப்பட்டது; பிதாவினிடத்திலிருந்ததும், எங்களுக்கு வெளிப்பட்டதுமான நித்தியமாயிருக்கிற அந்த ஜீவனை நாங்கள் கண்டு, அதைக்குறித்துச் சாட்சிகொடுத்து, அதை உங்களுக்கு அறிவிக்கிறோம்.
	<br /><br />
	3. நீங்களும் எங்களோடே ஐக்கியமுள்ளவர்களாகும்படி, நாங்கள் கண்டும் கேட்டும் இருக்கிறதை உங்களுக்கும் அறிவிக்கிறோம்; எங்களுடைய ஐக்கியம் பிதாவோடும் அவருடைய குமாரனாகிய இயேசுகிறிஸ்துவோடும் இருக்கிறது.
	<br /><br />
	4. உங்கள் சந்தோஷம் நிறைவாயிருக்கும்படி இவைகளை உங்களுக்கு எழுதுகிறோம்.
	<br /><br />
	5. தேவன் ஒளியாயிருக்கிறார், அவரில் எவ்வளவேனும் இருளில்லை; இது நாங்கள் அவரிடத்தில் கேட்டு உங்களுக்கு அறிவிக்கிற விசேஷமாயிருக்கிறது.
	<br /><br />
	6. நாம் அவரோடே ஐக்கியப்பட்டவர்களென்று சொல்லியும், இருளிலே நடக்கிறவர்களாயிருந்தால், சத்தியத்தின்படி நடவாமல் பொய்சொல்லுகிறவர்களாயிருப்போம்.
	<br /><br />
	7. அவர் ஒளியிலிருக்கிறதுபோல நாமும் ஒளியிலே நடந்தால் ஒருவரோடொருவர் ஐக்கியப்பட்டிருப்போம்; அவருடைய குமாரனாகிய இயேசுகிறிஸ்துவின் இரத்தம் சகல பாவங்களையும் நீக்கி, நம்மைச் சுத்திகரிக்கும்.
	<br /><br />
	8. நமக்குப் பாவமில்லையென்போமானால், நம்மை நாமே வஞ்சிக்கிறவர்களாயிருப்போம், சத்தியம் நமக்குள் இராது.
	<br /><br />
	9. நம்முடைய பாவங்களை நாம் அறிக்கையிட்டால், பாவங்களை நமக்கு மன்னித்து எல்லா அநியாயத்தையும் நீக்கி நம்மைச் சுத்திகரிப்பதற்கு அவர் உண்மையும் நீதியும் உள்ளவராயிருக்கிறார்.
	<br /><br />
	10. நாம் பாவஞ்செய்யவில்லையென்போமானால், நாம் அவரைப் பொய்யராக்குகிறவர்களாயிருப்போம், அவருடைய வார்த்தை நமக்குள் இராது.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* 1 யோவான் 1, வாழ்க்கையின் வார்த்தையான இயேசுவைப் பற்றிய அனைத்தையும் எழுத்தாளன் நேரில் கண்டான் என்பதை ஒரு அழகான நினைவூட்டலுடன் ஆரம்பிக்கிறது. இந்த கடிதத்தின் நோக்கம், நித்திய ஜீவன் ஆரம்பத்திலேயே பிதாவோடு இருந்த குமாரன் மூலமாக மட்டுமே என்பதை நினைவூட்டுவதாகும். உண்மையான விசுவாசிகள் வெளிச்சத்தில் நடக்கிறார்கள், இருளில் இருக்க முடியாது என்று யோவான் நினைவூட்டுகிறார், ஏனென்றால் குமாரன் நம்மில் வாழ்கிறார், எல்லா பாவங்களையும் நீக்குகிறார். இந்த அத்தியாயம் பைபிளின் பாவத்தின் பார்வையுடன் முடிவடைகிறது மற்றும் நாம் ஒப்புக்கொள்ளும்போது பாவம் மற்றும் அநீதியிலிருந்து நம்மைச் சுத்திகரிக்க கடவுள் உண்மையுள்ளவர் என்பதை நினைவூட்டுகிறது. *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default OneJohn1