import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation13 = () => {
  const verseNumber = 13;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 13 </title>
	<meta
          name="description"
          content="The Book of Revelation - 13 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 13 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* கடலில் இருந்து ஒரு மிருகம் எழுவதைக் கண்டேன். அது கடவுளுக்கு எதிராக அவதூறு பேசியது. மற்றொரு மிருகம் எழுந்து அனைவருக்கும் ஒரு அடையாளம் கொடுத்தது. அதன் எண் 666. *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. பின்பு நான் கடற்கரை மணலின்மேல் நின்றேன். அப்பொழுது சமுத்திரத்திலிருந்து ஒரு மிருகம் எழும்பிவரக் கண்டேன்; அதற்கு ஏழு தலைகளும் பத்துக் கொம்புகளும் இருந்தன; அதின் கொம்புகளின்மேல் பத்து முடிகளும், அதின் தலைகளின்மேல் தூஷணமான நாமமும் இருந்தன.
	<br /><br />
	2. நான் கண்ட மிருகம் சிறுத்தையைப்போலிருந்தது; அதின் கால்கள் கரடியின் கால்களைப்போலவும், அதின் வாய் சிங்கத்தின் வாயைப்போலவும் இருந்தன; வலுசர்ப்பமானது தன் பலத்தையும் தன் சிங்காசனத்தையும் மிகுந்த அதிகாரத்தையும் அதற்குக் கொடுத்தது.
	<br /><br />
	3. அதின் தலைகளிலொன்று சாவுக்கேதுவாய்க் காயப்பட்டிருக்கக் கண்டேன்; ஆனாலும் சாவுக்கேதுவான அந்தக் காயம் சொஸ்தமாக்கப்பட்டது. பூமியிலுள்ள யாவரும் ஆச்சரியத்தோடே அந்த மிருகத்தைப் பின்பற்றி,
	<br /><br />
	4. அந்த மிருகத்திற்கு அப்படிப்பட்ட அதிகாரங்கொடுத்த வலுசர்ப்பத்தை வணங்கினார்கள். அல்லாமலும்: மிருகத்திற்கு ஒப்பானவன் யார்? அதினோடே யுத்தம்பண்ணத்தக்கவன் யார்? என்று சொல்லி, மிருகத்தையும் வணங்கினார்கள்.
	<br /><br />
	5. பெருமையானவைகளையும் தூஷணங்களையும் பேசும் வாய் அதற்குக் கொடுக்கப்பட்டது; அல்லாமலும், நாற்பத்திரண்டு மாதம் யுத்தம்பண்ண அதற்கு அதிகாரங் கொடுக்கப்பட்டது.
	<br /><br />
	6. அது தேவனைத் தூஷிக்கும்படி தன் வாயைத் திறந்து, அவருடைய நாமத்தையும், அவருடைய வாசஸ்தலத்தையும், பரலோகத்தில் வாசமாயிருக்கிறவர்களையும் தூஷித்தது.
	<br /><br />
	7. மேலும், பரிசுத்தவான்களோடே யுத்தம்பண்ணி அவர்களை ஜெயிக்கும்படிக்கு அதற்கு அதிகாரங்கொடுக்கப்பட்டதுமல்லாமல், ஒவ்வொரு கோத்திரத்தின்மேலும் பாஷைக்காரர்மேலும் ஜாதிகள்மேலும் அதற்கு அதிகாரங்கொடுக்கப்பட்டது.
	<br /><br />
	8. உலகத்தோற்றமுதல் அடிக்கப்பட்ட ஆட்டுக்குட்டியினுடைய ஜீவபுஸ்தகத்தில் பேரெழுதப்பட்டிராத பூமியின் குடிகள் யாவரும் அதை வணங்குவார்கள்.
	<br /><br />
	9. காதுள்ளவனெவனோ அவன் கேட்கக்கடவன்.
	<br /><br />
	10. சிறைப்படுத்திக்கொண்டு போகிறவன் சிறைப்பட்டுப்போவான்; பட்டயத்தினாலே கொல்லுகிறவன் பட்டயத்தினாலே கொல்லப்படவேண்டும். பரிசுத்தவான்களுடைய பொறுமையும் விசுவாசமும் இதிலே விளங்கும்.
	<br /><br />
	11. பின்பு, வேறொரு மிருகம் பூமியிலிருந்து எழும்பக் கண்டேன்; அது ஒரு ஆட்டுக்குட்டிக்கு ஒப்பாக இரண்டு கொம்புகளையுடையதாயிருந்து, வலுசர்ப்பத்தைப்போலப் பேசினது.
	<br /><br />
	12. அது முந்தின மிருகத்தின் அதிகாரம் முழுவதையும் அதின் முன்பாக நடப்பித்து, சாவுக்கேதுவான காயம் ஆறச் சொஸ்தமடைந்த முந்தின மிருகத்தைப் பூமியும் அதின் குடிகளும் வணங்கும்படி செய்தது.
	<br /><br />
	13. அன்றியும், அது மனுஷருக்குமுன்பாக வானத்திலிருந்து பூமியின்மேல் அக்கினியை இறங்கப்பண்ணத்தக்கதாகப் பெரிய அற்புதங்களை நடப்பித்து,
	<br /><br />
	14. மிருகத்தின்முன்பாக அந்த அற்புதங்களைச் செய்யும்படி தனக்குக் கொடுக்கப்பட்ட சத்துவத்தினாலே பூமியின் குடிகளை மோசம்போக்கி, பட்டயத்தினாலே காயப்பட்டுப் பிழைத்த மிருகத்திற்கு ஒரு சொரூபம் பண்ணவேண்டுமென்று பூமியின் குடிகளுக்குச் சொல்லிற்று.
	<br /><br />
	15. மேலும் அம்மிருகத்தின் சொரூபம் பேசத்தக்கதாகவும், மிருகத்தின் சொரூபத்தை வணங்காத யாவரையும் கொலைசெய்யத்தக்கதாகவும், மிருகத்தின் சொரூபத்திற்கு ஆவியைக் கொடுக்கும்படி அதற்குச் சத்துவங்கொடுக்கப்பட்டது.
	<br /><br />
	16. அது சிறியோர், பெரியோர், ஐசுவரியவான்கள், தரித்திரர், சுயாதீனர், அடிமைகள், இவர்கள் யாவரும் தங்கள் தங்கள் வலதுகைகளிலாவது நெற்றிகளிலாவது ஒரு முத்திரையைப் பெறும்படிக்கும்,
	<br /><br />
	17. அந்த மிருகத்தின் முத்திரையையாவது அதின் நாமத்தையாவது அதின் நாமத்தின் இலக்கத்தையாவது தரித்துக்கொள்ளுகிறவன் தவிர வேறொருவனும் கொள்ளவும் விற்கவுங் கூடாதபடிக்கும் செய்தது.
	<br /><br />
	18. இதிலே ஞானம் விளங்கும்; அந்த மிருகத்தின் இலக்கத்தைப் புத்தியுடையவன் கணக்குப்பார்க்கக்கடவன்; அது மனுஷனுடைய இலக்கமாயிருக்கிறது; அதினுடைய இலக்கம் அறுநூற்றறுபத்தாறு.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h13>
*  *
			</h13>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation13