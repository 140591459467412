import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah31 = () => {
  const verseNumber = 31;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 31 </title>
	<meta
          name="description"
          content="Isaiah 31 | ஏசாயா 31 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சகாயமடையும்படி இஸ்ரவேலுடைய பரிசுத்தரை நோக்காமலும், கர்த்தரைத் தேடாமலும், எகிப்துக்குப்போய், குதிரைகள்மேல் நம்பிக்கைவைத்து, இரதங்கள் அநேகமாயிருப்பதினால் அவைகளை நாடி, குதிரைவீரர் மகா பெலசாலிகளாயிருப்பதினால் அவர்களை நம்பிக்கொண்டிருக்கிறவர்களுக்கு ஐயோ!
	<br /><br />
	2. அவரும் ஞானமுள்ளவர்; அவர் தம்முடைய வார்த்தைகளை மறுக்காமல், தீங்கு வரப்பண்ணி, தீமை செய்கிறவர்களின் வீட்டுக்கும், அக்கிரமக்காரருக்குச் சகாயஞ்செய்கிறவர்களுக்கும் விரோதமாக எழும்புவார்.
	<br /><br />
	3. எகிப்தியர் தெய்வம் அல்ல, மனுஷர்தானே; அவர்களுடைய குதிரைகள் ஆவியல்ல, மாம்சந்தானே; கர்த்தர் தமது கரத்தை நீட்டுவார், அப்பொழுது சகாயம் செய்கிறவனும் இடறி, சகாயம் பெறுகிறவனும் விழுந்து, அனைவரும் ஏகமாய் அழிந்துபோவார்கள்.
	<br /><br />
	4. கர்த்தர் என்னுடனே சொன்னது: சிங்கமும் பாலசிங்கமும் தங்கள் இரையைப் பிடித்திருக்கும்போது கெர்ச்சித்து, தங்களுக்கு விரோதமாய்க் கூப்பிடுகிற திரளான மேய்ப்பரின் சத்தத்தினாலே கலங்காமலும், அவர்கள் அமளியினாலே பணியாமலும் இருக்கிறதுபோல, சேனைகளின் கர்த்தர் சீயோன்மலைக்காகவும், அதின் மேட்டுக்காகவும் யுத்தம்பண்ண இறங்குவார்.
	<br /><br />
	5. பறந்து காக்கிற பட்சிகளைப்போல, சேனைகளின் கர்த்தர் எருசலேமின்மேல் ஆதரவாக இருப்பார்; அவர் அதைக் காத்துத் தப்பப்பண்ணுவார்; அவர் கடந்துவந்து அதை விடுவிப்பார்.
	<br /><br />
	6. இஸ்ரவேல் புத்திரரே, நீங்கள் முற்றிலும் விட்டுவிலகினவரிடத்தில் திரும்புங்கள்.
	<br /><br />
	7. உங்களுக்குப் பாவமாக உங்கள் கைகள் செய்திருந்த வெள்ளி விக்கிரகங்களையும், பொன் விக்கிரகங்களையும், உங்களில் ஒவ்வொருவரும் அக்காலத்திலே வெறுத்துவிடுவீர்கள்.
	<br /><br />
	8. அப்பொழுது வீரனுடைய பட்டயம் அல்லாத பட்டயத்தாலே அசீரியன் விழுவான்; நீசனுடைய பட்டயம் அல்லாத பட்டயமே அவனைப் பட்சிக்கும்; அவன் பட்டயத்துக்குத் தப்ப ஓடுவான்; அவன் வாலிபர் கலைந்துபோவார்கள்.
	<br /><br />
	9. அவனுடைய கன்மலை பயத்தினால் ஒழிந்துபோம், அவர்களுடைய பிரபுக்கள் கொடியைக் கண்டு கலங்குவார்கள் என்பதை, சீயோனில் நெருப்பையும் எருசலேமில் சூளையையுமுடைய கர்த்தர் சொல்லுகிறார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah31