import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 22 </title>
	<meta
          name="description"
          content="The Book of Revelation - 22 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 22 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* ஜீவ நதி தேவனுடைய சிங்காசனத்திலிருந்து பாய்கிறது. "இதோ, நான் விரைவில் வருகிறேன். ஆரம்பமும் முடிவும் நானே." ஆமென். வாருங்கள், ஆண்டவர் இயேசுவே! *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. பின்பு, பளிங்கைப்போல் தெளிவான ஜீவத்தண்ணீருள்ள சுத்தமான நதி தேவனும் ஆட்டுக்குட்டியானவரும் இருக்கிற சிங்காசனத்திலிருந்து புறப்பட்டுவருகிறதை எனக்குக் காண்பித்தான்.
	<br /><br />
	2. நகரத்து வீதியின் மத்தியிலும், நதியின் இருகரையிலும், பன்னிரண்டுவிதமான கனிகளைத்தரும் ஜீவவிருட்சம் இருந்தது, அது மாதந்தோறும் தன் கனியைக் கொடுக்கும்; அந்த விருட்சத்தின் இலைகள் ஜனங்கள் ஆரோக்கியமடைகிறதற்கு ஏதுவானவைகள்.
	<br /><br />
	3. இனி ஒரு சாபமுமிராது. தேவனும் ஆட்டுக்குட்டியானவரும் இருக்கிற சிங்காசனம் அதிலிருக்கும்.
	<br /><br />
	4. அவருடைய ஊழியக்காரர் அவரைச் சேவித்து, அவருடைய சமுகத்தைத் தரிசிப்பார்கள்; அவருடைய நாமம் அவர்களுடைய நெற்றிகளில் இருக்கும்.
	<br /><br />
	5. அங்கே இராக்காலமிராது; விளக்கும் சூரியனுடைய வெளிச்சமும் அவர்களுக்கு வேண்டுவதில்லை; தேவனாகிய கர்த்தரே அவர்கள்மேல் பிரகாசிப்பார். அவர்கள் சதாகாலங்களிலும் அரசாளுவார்கள்.
	<br /><br />
	6. பின்பு, அவர் என்னை நோக்கி: இந்த வசனங்கள் உண்மையும் சத்தியமுமானவைகள். சீக்கிரமாய்ச் சம்பவிக்கவேண்டியவைகளைத் தம்முடைய ஊழியக்காரருக்குக் காண்பிக்கும்பொருட்டு, பரிசுத்த தீர்க்கதரிசிகளின் கர்த்தராகிய தேவனானவர் தம்முடைய தூதனை அனுப்பினார்.
	<br /><br />
	7. இதோ, சீக்கிரமாய் வருகிறேன்; இந்தப் புஸ்தகத்திலுள்ள தீர்க்கதரிசன வசனங்களைக் கைக்கொள்ளுகிறவன் பாக்கியவான் என்றார்.
	<br /><br />
	8. யோவானாகிய நானே இவைகளைக் கண்டும் கேட்டும் இருந்தேன். நான் கேட்டுக் கண்டபோது, இவைகளை எனக்குக் காண்பித்த தூதனை வணங்கும்படி அவன் பாதத்தில் விழுந்தேன்.
	<br /><br />
	9. அதற்கு அவன்: நீ இப்படிச் செய்யாதபடிக்குப் பார்; உன்னோடும் உன் சகோதரரோடும் தீர்க்கதரிசிகளோடும், இந்தப் புஸ்தகத்தின் வசனங்களைக் கைக்கொள்ளுகிறவர்களோடுங்கூட நானும் ஒரு ஊழியக்காரன்; தேவனைத் தொழுதுகொள் என்றான்.
	<br /><br />
	10. பின்னும், அவர் என்னை நோக்கி: இந்தப் புஸ்தகத்திலுள்ள தீர்க்கதரிசன வசனங்களை முத்திரைபோடவேண்டாம்; காலம் சமீபமாயிருக்கிறது.
	<br /><br />
	11. அநியாயஞ்செய்கிறவன் இன்னும் அநியாயஞ்செய்யட்டும்; அசுத்தமாயிருக்கிறவன் இன்னும் அசுத்தமாயிருக்கட்டும்; நீதியுள்ளவன் இன்னும் நீதிசெய்யட்டும்; பரிசுத்தமுள்ளவன் இன்னும் பரிசுத்தமாகட்டும்.
	<br /><br />
	12. இதோ, சீக்கிரமாய் வருகிறேன்; அவனவனுடைய கிரியைகளின்படி அவனவனுக்கு நான் அளிக்கும் பலன் என்னோடேகூட வருகிறது.
	<br /><br />
	13. நான் அல்பாவும் ஓமெகாவும், ஆதியும் அந்தமும், முந்தினவரும் பிந்தினவருமாயிருக்கிறேன்.
	<br /><br />
	14. ஜீவவிருட்சத்தின்மேல் அதிகாரமுள்ளவர்களாவதற்கும், வாசல்கள் வழியாய் நகரத்திற்குள் பிரவேசிப்பதற்கும் அவருடைய கற்பனைகளின்படி செய்கிறவர்கள் பாக்கியவான்கள்.
	<br /><br />
	15. நாய்களும், சூனியக்காரரும், விபசாரக்காரரும், கொலைபாதகரும், விக்கிரகாராதனைக்காரரும், பொய்யை விரும்பி அதின்படி செய்கிற யாவரும் புறம்பே இருப்பார்கள்.
	<br /><br />
	16. சபைகளில் இவைகளை உங்களுக்குச் சாட்சியாக அறிவிக்கும்படிக்கு இயேசுவாகிய நான் என் தூதனை அனுப்பினேன். நான் தாவீதின் வேரும் சந்ததியும், பிரகாசமுள்ள விடிவெள்ளி நட்சத்திரமுமாயிருக்கிறேன் என்றார்.
	<br /><br />
	17. ஆவியும் மணவாட்டியும் வா என்கிறார்கள்; கேட்கிறவனும் வா என்பானாக; தாகமாயிருக்கிறவன் வரக்கடவன்; விருப்பமுள்ளவன் ஜீவத்தண்ணீரை இலவசமாய் வாங்கிக்கொள்ளக்கடவன்.
	<br /><br />
	18. இந்தப் புஸ்தகத்திலுள்ள தீர்க்கதரிசன வசனங்களைக் கேட்கிற யாவருக்கும் நான் சாட்சியாக எச்சரிக்கிறதாவது: ஒருவன் இவைகளோடே எதையாகிலும் கூட்டினால், இந்தப் புஸ்தகத்தில் எழுதியிருக்கிற வாதைகளை தேவன் அவன்மேல் கூட்டுவார்.
	<br /><br />
	19. ஒருவன் இந்தத் தீர்க்கதரிசன புஸ்தகத்தின் வசனங்களிலிருந்து எதையாகிலும் எடுத்துப்போட்டால், ஜீவபுஸ்தகத்திலிருந்தும், பரிசுத்த நகரத்திலிருந்தும், இந்தப் புஸ்தகத்தில் எழுதப்பட்டவைகளிலிருந்தும், அவனுடைய பங்கை தேவன் எடுத்துப்போடுவார்.
	<br /><br />
	20. இவைகளைச் சாட்சியாக அறிவிக்கிறவர்: மெய்யாகவே நான் சீக்கிரமாய் வருகிறேன் என்கிறார். ஆமென், கர்த்தராகிய இயேசுவே, வாரும்.
	<br /><br />
	21. நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் கிருபை உங்கள் அனைவரோடுங்கூட இருப்பதாக. ஆமென்.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h22>
*  *
			</h22>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation22