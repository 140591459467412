import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 11 </title>
	<meta
          name="description"
          content="Acts 11 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 11 |
          Acts of Apostles Chapter-11 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. புறஜாதியாரும் தேவவசனத்தை ஏற்றுக்கொண்டார்களென்று யூதேயாவிலிருக்கிற அப்போஸ்தலரும் சகோதரரும் கேள்விப்பட்டார்கள்.
	<br /><br />
	2. பேதுரு எருசலேமுக்குத் திரும்பிவந்தபோது, விருத்தசேதனமுள்ளவர்கள் அவனை நோக்கி:
	<br /><br />
	3. விருத்தசேதனமில்லாத மனுஷரிடத்தில் நீர் போய், அவர்களோடே போஜனம்பண்ணினீர் என்று, அவனோடே வாக்குவாதம்பண்ணினார்கள்.
	<br /><br />
	4. அதற்குப் பேதுரு காரியத்தை முதலிலிருந்து வரிசையாய் அவர்களுக்கு விவரிக்கத் தொடங்கி:
	<br /><br />
	5. நான் யோப்பா பட்டணத்தில் ஜெபம்பண்ணிக்கொண்டிருந்தபோது ஞானதிருஷ்டியடைந்து, ஒரு தரிசனத்தைக்கண்டேன்; அதென்னவென்றால், நாலு முனைகளும் கட்டப்பட்ட பெரிய துப்பட்டியைப்போல ஒரு கூடு வானத்திலிருந்து என்னிடத்தில் இறங்கிவந்தது.
	<br /><br />
	6. அதிலே நான் உற்றுப்பார்த்துக் கவனிக்கிறபோது, பூமியிலுள்ள நாலுகால் ஜீவன்களையும், காட்டுமிருகங்களையும், ஊரும் பிராணிகளையும், ஆகாயத்துப் பறவைகளையும் கண்டேன்.
	<br /><br />
	7. அல்லாமலும்: பேதுருவே, எழுந்திரு, அடித்துப் புசி என்று என்னுடனே சொல்லுகிற சத்தத்தையும் கேட்டேன்.
	<br /><br />
	8. அதற்கு நான்: ஆண்டவரே, அப்படியல்ல, தீட்டும் அசுத்தமுமாயிருக்கிற யாதொன்றும் ஒருக்காலும் என் வாய்க்குள்ளே போனதில்லை என்றேன்.
	<br /><br />
	9. இரண்டாந்தரமும் வானத்திலிருந்து சத்தம் உண்டாகி: தேவன் சுத்தமாக்கினவைகளை நீ தீட்டாக எண்ணாதேயென்று மறுமொழி சொல்லிற்று.
	<br /><br />
	10. இப்படி மூன்றுதரம் சம்பவித்த பின்பு, எல்லாம் வானத்திற்குத் திரும்ப எடுத்துக்கொள்ளப்பட்டது.
	<br /><br />
	11. உடனே செசரியாவிலிருந்து என்னிடத்திற்கு அனுப்பப்பட்ட மூன்று மனுஷர் நான் இருந்த வீட்டுக்குமுன்னே வந்து நின்றார்கள்.
	<br /><br />
	12. நான் ஒன்றுக்கும் சந்தேகப்படாமல் அவர்களோடேகூடப் போகும்படி ஆவியானவர் எனக்குக் கட்டளையிட்டார். சகோதரராகிய இந்த ஆறுபேரும் என்னோடேகூட வந்தார்கள்; அந்த மனுஷனுடைய வீட்டுக்குள் பிரவேசித்தோம்.
	<br /><br />
	13. அவனோ தன் வீட்டிலே ஒரு தேவதூதன் நிற்கிறதைக் கண்டதாகவும், யோப்பா பட்டணத்திலிருக்கிற பேதுரு என்று மறுபேர்கொண்ட சீமோனை அழைக்கும்படிக்கு மனுஷரை அவ்விடத்திற்கு அனுப்பு;
	<br /><br />
	14. நீயும் உன் வீட்டாரனைவரும் இரட்சிக்கப்படுவதற்கேதுவான வார்த்தைகளை அவன் உனக்குச் சொல்லுவான் என்று அந்தத் தூதன் தனக்குச் சொன்னதாகவும் எங்களுக்கு அறிவித்தான்.
	<br /><br />
	15. நான் பேசத்தொடங்கினபோது, பரிசுத்த ஆவியானவர் ஆதியிலே நம்மேல் இறங்கினதுபோலவே, அவர்கள்மேலும் இறங்கினார்.
	<br /><br />
	16. யோவான் ஜலத்தினாலே ஞானஸ்நானங் கொடுத்தான், நீங்களோ பரிசுத்த ஆவியினாலே ஞானஸ்நானம் பெறுவீர்கள் என்று கர்த்தர் சொன்ன வார்த்தையை அப்பொழுது நினைவுகூர்ந்தேன்.
	<br /><br />
	17. ஆதலால் கர்த்தராகிய இயேசுகிறிஸ்துவை விசுவாசித்திருக்கிற நமக்குத் தேவன் வரத்தை அநுக்கிரகம்பண்ணினதுபோல அவர்களுக்கும் அந்த வரத்தையே அநுக்கிரகம்பண்ணியிருக்கும்போது தேவனைத் தடுக்கிறதற்கு நான் எம்மாத்திரம் என்றான்.
	<br /><br />
	18. இவைகளை அவர்கள் கேட்டபொழுது அமர்ந்திருந்து: அப்படியானால் ஜீவனுக்கேதுவான மனந்திரும்புதலைத் தேவன் புறஜாதியாருக்கும் அருளிச்செய்தார் என்று சொல்லி, தேவனை மகிமைப்படுத்தினார்கள்.
	<br /><br />
	19. ஸ்தேவான் நிமித்தமாய் எழும்பின உபத்திரவத்தினாலே சிதறப்பட்டவர்கள் சுவிசேஷ வசனத்தை யூதர்களுக்கேயன்றி மற்ற ஒருவருக்கும் அறிவியாமல், பெனிக்கேநாடு, சீப்புருதீவு, அந்தியோகியா பட்டணம்வரைக்கும் சுற்றித்திரிந்தார்கள்.
	<br /><br />
	20. அவர்களில் சீப்புருதீவாரும் சிரேனே பட்டணத்தாருமாகிய சிலர் அந்தியோகியா பட்டணத்துக்கு வந்து, கிரேக்கருடனே பேசிக் கர்த்தராகிய இயேசுவைக்குறித்துப் பிரசங்கித்தார்கள்.
	<br /><br />
	21. கர்த்தருடைய கரம் அவர்களோடே இருந்தது; அநேக ஜனங்கள் விசுவாசிகளாகி, கர்த்தரிடத்தில் திரும்பினார்கள்.
	<br /><br />
	22. எருசலேமிலுள்ள சபையார் இந்தக் காரியங்களைக்குறித்துக் கேள்விப்பட்டபோது, அந்தியோகியாவரைக்கும் போகும்படிக்குப் பர்னபாவை அனுப்பினார்கள்.
	<br /><br />
	23. அவன் போய்ச் சேர்ந்து, தேவனுடைய கிருபையைக் கண்டபோது, சந்தோஷப்பட்டு, கர்த்தரிடத்தில் மனநிர்ணயமாய் நிலைத்திருக்கும்படி எல்லாருக்கும் புத்திசொன்னான்.
	<br /><br />
	24. அவன் நல்லவனும், பரிசுத்தஆவியினாலும் விசுவாசத்தினாலும் நிறைந்தவனுமாயிருந்தான்; அநேக ஜனங்கள் கர்த்தரிடமாய்ச் சேர்க்கப்பட்டார்கள்.
	<br /><br />
	25. பின்பு பர்னபா சவுலைத் தேடும்படி, தர்சுவுக்குப் புறப்பட்டுப்போய், அவனைக்கண்டு, அந்தியோகியாவுக்கு அழைத்துக்கொண்டுவந்தான்.
	<br /><br />
	26. அவர்கள் ஒரு வருஷகாலமாய்ச் சபையோடே கூடியிருந்து, அநேக ஜனங்களுக்கு உபதேசம்பண்ணினார்கள். முதல் முதல் அந்தியோகியாவிலே சீஷர்களுக்குக் கிறிஸ்தவர்கள் என்கிற பேர் வழங்கிற்று.
	<br /><br />
	27. அந்நாட்களிலே எருசலேமிலிருந்து சில தீர்க்கதரிசிகள் அந்தியோகியாவுக்கு வந்தார்கள்.
	<br /><br />
	28. அவர்களில் ஒருவனாகிய அகபு என்பவன் எழுந்து, உலகமெங்கும் கொடிய பஞ்சம் உண்டாகும் என்று ஆவியானவராலே அறிவித்தான்; அது அப்படியே கிலவுதியு ராயனுடைய நாட்களிலே உண்டாயிற்று.
	<br /><br />
	29. அப்பொழுது சீஷரில் அவரவர் தங்கள் தங்கள் திராணிக்குத்தக்கதாக யூதேயாவில் குடியிருக்கிற சகோதரருக்கு உதவியாகப் பணஞ் சேகரித்து அனுப்பவேண்டுமென்று தீர்மானம்பண்ணினார்கள்.
	<br /><br />
	30. அப்படியே அவர்கள் சேகரித்து, பர்னபா சவுல் என்பவர்களுடைய கையிலே கொடுத்து, மூப்பரிடத்திற்கு அனுப்பினார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts11