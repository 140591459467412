import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoPeter2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Beware of False Teachers 2Peter -2 in Tamil Bible | 2 பேதுரு - 2 </title>
	<meta
          name="description"
          content="Beware of False Teachers | 2nd Peter in Tamil Bible Verse | 2-பேதுரு அதிகாரம் - 2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Holy Bible "
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* தவறான போதகர்களிடம் ஜாக்கிரதை *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. கள்ளத்தீர்க்கதரிசிகளும் ஜனங்களுக்குள்ளே இருந்தார்கள், அப்படியே உங்களுக்குள்ளும் கள்ளப்போதகர்கள் இருப்பார்கள்; அவர்கள் கேட்டுக்கேதுவான வேதப்புரட்டுகளைத் தந்திரமாய் நுழையப்பண்ணி, தங்களைக் கிரயத்துக்குக்கொண்ட ஆண்டவரை மறுதலித்து, தங்களுக்குத் தீவிரமான அழிவை வருவித்துக்கொள்ளுவார்கள்.
	<br /><br />
	2. அவர்களுடைய கெட்ட நடக்கைகளை அநேகர் பின்பற்றுவார்கள்; அவர்கள்நிமித்தம் சத்தியமார்க்கம் தூஷிக்கப்படும்.
	<br /><br />
	3. பொருளாசையுடையவர்களாய், தந்திரமான வார்த்தைகளால் உங்களைத் தங்களுக்கு ஆதாயமாக வசப்படுத்திக்கொள்ளுவார்கள்; பூர்வகாலமுதல் அவர்களுக்கு விதிக்கப்பட்ட ஆக்கினை அயர்ந்திராது, அவர்களுடைய அழிவு உறங்காது.
	<br /><br />
	4. பாவஞ்செய்த தூதர்களைத் தேவன் தப்பவிடாமல், அந்தகாரச் சங்கிலிகளினாலே கட்டி நரகத்திலே தள்ளி நியாயத்தீர்ப்புக்கு வைக்கப்பட்டவர்களாக ஒப்புக்கொடுத்து;
	<br /><br />
	5. பூர்வ உலகத்தையும் தப்பவிடாமல், நீதியைப் பிரசங்கித்தவனாகிய நோவா முதலான எட்டுப்பேரைக் காப்பாற்றி, அவபக்தியுள்ளவர்கள் நிறைந்த உலகத்தின்மேல் ஜலப்பிரளயத்தை வரப்பண்ணி;
	<br /><br />
	6. சோதோம் கொமோரா என்னும் பட்டணங்களையும் சாம்பலாக்கிக் கவிழ்த்துப்போட்டு, ஆக்கினைக்குள்ளாகத் தீர்த்து, பிற்காலத்திலே அவபக்தியாய் நடப்பவர்களுக்கு அவைகளைத் திருஷ்டாந்தமாக வைத்து;
	<br /><br />
	7. அக்கிரமக்காரருக்குள் வாசமாயிருக்கையில் அவர்களுடைய காமவிகார நடக்கையால் வருத்தப்பட்டு;
	<br /><br />
	8. நாள்தோறும் அவர்களுடைய அக்கிரமக்கிரியைகளைக் கண்டு கேட்டு நீதியுள்ள தன்னுடைய இருதயத்தில் வாதிக்கப்பட்ட நீதிமானாகிய லோத்தை அவர் இரட்சித்திருக்க;
	<br /><br />
	9. கர்த்தர் தேவபக்தியுள்ளவர்களைச் சோதனையினின்று இரட்சிக்கவும், அக்கிரமக்காரரை ஆக்கினைக்குள்ளானவர்களாக நியாயத்தீர்ப்பு நாளுக்கு வைக்கவும் அறிந்திருக்கிறார்.
	<br /><br />
	10. விசேஷமாக அசுத்த இச்சையோடே மாம்சத்திற்கேற்றபடி நடந்து, கர்த்தத்துவத்தை அசட்டைபண்ணுகிறவர்களை அப்படிச் செய்வார். இவர்கள் துணிகரக்காரர், அகங்காரிகள், மகத்துவங்களைத் தூஷிக்க அஞ்சாதவர்கள்.
	<br /><br />
	11. அதிக பெலனையும் வல்லமையையுமுடைய தேவதூதர்கள் முதலாய்க் கர்த்தருக்கு முன்பாக அவர்களைத் தூஷணமாய்க் குற்றப்படுத்தமாட்டார்களே.
	<br /><br />
	12. இவர்களோ பிடிபட்டழிக்கப்படுவதற்கு உண்டான புத்தியற்ற மிருகஜீவன்களைப்போலத் தங்களுக்குத் தெரியாதவைகளைத் தூஷித்து, தங்கள் கேட்டிலே கெட்டழிந்து, அநீதத்தின் பலனை அடைவார்கள்.
	<br /><br />
	13. இவர்கள் ஒருநாள் வாழ்வை இன்பமென்றெண்ணி, கறைகளும் இலச்சைகளுமாயிருந்து; உங்களோடே விருந்துண்கையில் தங்கள் வஞ்சனைகளில் உல்லாசமாய் வாழ்கிறவர்கள்;
	<br /><br />
	14. விபசாரமயக்கத்தால் நிறைந்தவைகளும், பாவத்தை விட்டோயாதவைகளுமாயிருக்கிற கண்களையுடையவர்கள்; உறுதியில்லாத ஆத்துமாக்களைத் தந்திரமாய்ப் பிடித்து, பொருளாசைகளில் பழகின இருதயத்தையுடைய சாபத்தின் பிள்ளைகள்.
	<br /><br />
	15. செம்மையான வழியைவிட்டுத் தப்பிநடந்து, பேயோரின் குமாரனாகிய பிலேயாமின் வழியைப் பின்பற்றிப்போனவர்கள்; அவன் அநீதத்தின் கூலியை விரும்பி,
	<br /><br />
	16. தன்னுடைய அக்கிரமத்தினிமித்தம் கடிந்துகொள்ளப்பட்டான்; பேசாத மிருகம் மனுஷர்பேச்சைப் பேசித் தீர்க்கதரிசியினுடைய மதிகேட்டைத் தடுத்தது.
	<br /><br />
	17. இவர்கள் தண்ணீரில்லாத கிணறுகளும், சுழல்காற்றினால் அடியுண்டோடுகிற மேகங்களுமாயிருக்கிறார்கள்; என்றென்றைக்குமுள்ள காரிருளே இவர்களுக்கு வைக்கப்பட்டிருக்கிறது.
	<br /><br />
	18. வஞ்சகமாய் நடக்கிறவர்களிடத்திலிருந்து அரிதாய்த் தப்பினவர்களிடத்தில் இவர்கள் அகந்தையான வீண்வார்த்தைகளைப் பேசி, மாம்ச இச்சைகளினாலும் காமவிகாரங்களினாலும் அவர்களைத் தந்திரமாய்ப் பிடிக்கிறார்கள்.
	<br /><br />
	19. தாங்களே கேட்டுக்கு அடிமைகளாயிருந்தும், அவர்களுக்குச் சுயாதீனத்தை வாக்குத்தத்தம்பண்ணுகிறார்கள்; எதினால் ஒருவன் ஜெயிக்கப்பட்டிருக்கிறானோ அதற்கு அவன் அடிமைப்பட்டிருக்கிறானே.
	<br /><br />
	20. கர்த்தரும் இரட்சகருமாயிருக்கிற இயேசுகிறிஸ்துவை அறிகிற அறிவினாலே உலகத்தின் அசுத்தங்களுக்குத் தப்பினவர்கள் மறுபடியும் அவைகளில் சிக்கிக்கொண்டு ஜெயிக்கப்பட்டால், அவர்களுடைய பின்னிலைமை முன்னிலைமையிலும் கேடுள்ளதாயிருக்கும்.
	<br /><br />
	21. அவர்கள் நீதியின் மார்க்கத்தை அறிந்தபின்பு தங்களுக்கு ஒப்புவிக்கப்பட்ட பரிசுத்த கற்பனையை விட்டு விலகுவதைப்பார்க்கிலும் அதை அறியாதிருந்தார்களானால் அவர்களுக்கு நலமாயிருக்கும்.
	<br /><br />
	22. நாய் தான் கக்கினதைத் தின்னவும், கழுவப்பட்ட பன்றி சேற்றிலே புரளவும் திரும்பினது என்று சொல்லப்பட்ட மெய்யான பழமொழியின்படியே அவர்களுக்குச் சம்பவித்தது.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
* 2 பேதுரு 2 பொய்யான தீர்க்கதரிசிகளும் போதகர்களும் அழிவுகரமான மதவெறிகளை இரகசியமாக அறிமுகப்படுத்த முயற்சிப்பார்கள் என்பதற்கான எச்சரிக்கையாக செயல்படுகிறது. நியாயத்தீர்ப்பு நாளுக்காக அநீதியானவர்களைக் கடவுள் எப்படிக் காப்பாற்றுகிறார் என்பதைப் பற்றிய பல கதைகளை பேதுரு விவரிக்கிறார். பொய்யான போதகர்கள் சுதந்திரம் தருவதாக வாக்குறுதி அளிக்கிறார்கள், ஆனால் அவர்களே சீரழிவுக்கு அடிமைகள் என்று எச்சரிக்கிறார். எச்சரிக்கையுடன் இருக்குமாறு எச்சரிக்கிறோம். *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default TwoPeter2