import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Philippians4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Book of Philippians Bible in Tamil | பிலிப்பியர் 4 </title>
	<meta
          name="description"
          content="Philippians 4 | பிலிப்பியர் 4 |
          Book of Philippians | Tamil Bible Verse | Tamil Bible | பிலிப்பியருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆதலால், எனக்குப் பிரியமும் வாஞ்சையுமான சகோதரரே, எனக்குச் சந்தோஷமும் கிரீடமுமானவர்களே, பிரியமானவர்களே, இந்தப்படியே கர்த்தருக்குள் நிலைத்திருங்கள்.
	<br /><br />
	2. கர்த்தருக்குள் ஒரே சிந்தையாயிருக்க எயோதியாளுக்கும் சிந்திகேயாளுக்கும் புத்திசொல்லுகிறேன்.
	<br /><br />
	3. அன்றியும், என் உத்தம கூட்டாளியே, அவர்களுக்கு உதவியாயிருக்கும்படி உன்னையும் வேண்டிக்கொள்ளுகிறேன்; அவர்கள் கிலேமெந்தோடும் மற்ற என் உடன்வேலையாட்களோடுங்கூடச் சுவிசேஷ விஷயத்தில் என்னோடேகூட மிகவும் பிரயாசப்பட்டார்கள், அவர்களுடைய நாமங்கள் ஜீவபுஸ்தகத்தில் இருக்கிறது.
	<br /><br />
	4. கர்த்தருக்குள் எப்பொழுதும் சந்தோஷமாயிருங்கள்; சந்தோஷமாயிருங்கள் என்று மறுபடியும் சொல்லுகிறேன்.
	<br /><br />
	5. உங்கள் சாந்தகுணம் எல்லா மனுஷருக்கும் தெரிந்திருப்பதாக. கர்த்தர் சமீபமாயிருக்கிறார்.
	<br /><br />
	6. நீங்கள் ஒன்றுக்குங் கவலைப்படாமல், எல்லாவற்றையுங்குறித்து உங்கள் விண்ணப்பங்களை ஸ்தோத்திரத்தோடே கூடிய ஜெபத்தினாலும் வேண்டுதலினாலும் தேவனுக்குத் தெரியப்படுத்துங்கள்.
	<br /><br />
	7. அப்பொழுது, எல்லாப் புத்திக்கும் மேலான தேவசமாதானம் உங்கள் இருதயங்களையும் உங்கள் சிந்தைகளையும் கிறிஸ்து இயேசுவுக்குள்ளாகக் காத்துக்கொள்ளும்.
	<br /><br />
	8. கடைசியாக, சகோதரரே, உண்மையுள்ளவைகளெவைகளோ, ஒழுக்கமுள்ளவைகளெவைகளோ, நீதியுள்ளவைகளெவைகளோ, கற்புள்ளவைகளெவைகளோ, அன்புள்ளவைகளெவைகளோ, நற்கீர்த்தியுள்ளவைகளெவைகளோ, புண்ணியம் எதுவோ, புகழ் எதுவோ அவைகளையே சிந்தித்துக்கொண்டிருங்கள்.
	<br /><br />
	9. நீங்கள் என்னிடத்தில் கற்றும் அடைந்தும் கேட்டும் கண்டும் இருக்கிறவைகளெவைகளோ, அவைகளையே செய்யுங்கள்; அப்பொழுது சமாதானத்தின் தேவன் உங்களோடிருப்பார்.
	<br /><br />
	10. என்னை விசாரிப்பதற்கு நீங்கள் இப்பொழுது மறுபடியும் மனமலர்ந்தபடியினாலே கர்த்தருக்குள் மிகவும் சந்தோஷப்பட்டேன்; இப்படிச் செய்ய எண்ணங்கொண்டிருந்தீர்கள், சமயம்மாத்திரம் உங்களுக்கு நேரிடவில்லை.
	<br /><br />
	11. என் குறைச்சலினால் நான் இப்படிச் சொல்லுகிறதில்லை; ஏனெனில் நான் எந்த நிலைமையிலிருந்தாலும் மனரம்மியமாயிருக்கக் கற்றுக்கொண்டேன்.
	<br /><br />
	12. தாழ்ந்திருக்கவும் எனக்குத் தெரியும், வாழ்ந்திருக்கவும் எனக்குத் தெரியும்; எவ்விடத்திலும் எல்லாவற்றிலும் திருப்தியாயிருக்கவும் பட்டினியாயிருக்கவும், பரிபூரணமடையவும் குறைவுபடவும் போதிக்கப்பட்டேன்.
	<br /><br />
	13. என்னைப் பெலப்படுத்துகிற கிறிஸ்துவினாலே எல்லாவற்றையுஞ்செய்ய எனக்குப் பெலனுண்டு.
	<br /><br />
	14. ஆகிலும் நீங்கள் என் உபத்திரவத்தில் என்னோடே உடன்பட்டது நலமாயிருக்கிறது.
	<br /><br />
	15. மேலும், பிலிப்பியரே, சுவிசேஷத்தின் ஆரம்பத்திலே நான் மக்கெதோனியாவிலிருந்து புறப்பட்டபோது, கொடுக்கல் வாங்கல் காரியத்தில் நீங்கள்மாத்திரம் எனக்கு உடன்பட்டதேயல்லாமல், வேறொரு சபையும் உடன்படவில்லை என்று நீங்கள் அறிந்திருக்கிறீர்கள்.
	<br /><br />
	16. நான் தெசலோனிக்கேயிலிருந்தபோதும், என் குறைச்சலை நீக்கும்படி நீங்கள் இரண்டொருதரம் அனுப்பினீர்கள்.
	<br /><br />
	17. உபகாரத்தை நான் நாடாமல், உங்கள் கணக்குக்குப் பலன் பெருகும்படியே நாடுகிறேன்.
	<br /><br />
	18. எல்லாம் எனக்குக் கிடைத்தது, பரிபூரணமும் உண்டாயிருக்கிறது; உங்களால் அனுப்பப்பட்டவைகளைச் சுகந்த வாசனையும் தேவனுக்குப் பிரியமான உகந்த பலியுமாக எப்பாப்பிரோதீத்துவின் கையில் வரப்பற்றிக்கொண்டபடியால் நான் திருப்தியடைந்திருக்கிறேன்.
	<br /><br />
	19. என் தேவன் தம்முடைய ஐசுவரியத்தின்படி உங்கள் குறைவையெல்லாம் கிறிஸ்து இயேசுவுக்குள் மகிமையிலே நிறைவாக்குவார்.
	<br /><br />
	20. நம்முடைய பிதாவாகிய தேவனானவருக்கு என்றென்றைக்கும் மகிமை உண்டாவதாக. ஆமென்.
	<br /><br />
	21. கிறிஸ்து இயேசுவுக்குள்ளான பரிசுத்தவான்கள் யாவருக்கும் வாழ்த்துதல் சொல்லுங்கள். என்னோடிருக்கிற சகோதரர்கள் உங்களுக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	22. பரிசுத்தவான்கள் அனைவரும், விசேஷமாக இராயனுடைய அரமனையிலுள்ளவர்களும் உங்களுக்கு வாழ்த்துதல் சொல்லுகிறார்கள்.
	<br /><br />
	23. நமது கர்த்தராகிய இயேசுகிறிஸ்துவினுடைய கிருபை உங்களனைவரோடுங்கூட இருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Philippians4