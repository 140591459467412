import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah48 = () => {
  const verseNumber = 48;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 48 </title>
	<meta
          name="description"
          content="Jeremiah 48 | எரேமியா 48 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மோவாபைக்குறித்து இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறது என்னவென்றால், ஐயோ! நேபோ பாழாக்கப்பட்டது; கீரியாத்தாயீம் வெட்கப்பட்டு, பிடிக்கப்பட்டுப்போயிற்று; மிஸ்காப் வெட்கப்பட்டு, கலங்கிப்போயிற்று.
	<br /><br />
	2. எஸ்போனைக்குறித்து மோவாபுக்கு இருந்த பெத்தரிக்கம் இனி இராது; அது ஒரு ஜாதியாயிராதபடிக்கு அதை நிர்மூலமாக்குவேன் வாருங்களென்று அதற்கு விரோதமாகப் பொல்லாப்பை நினைத்திருக்கிறார்கள்; மத்மேனே, நீயும் சங்காரமாவாய்; பட்டயம் உன்னைத் தொடரும்.
	<br /><br />
	3. பாழ்க்கடிப்பினாலும் பெரிய நொறுக்குதலினாலும் உண்டாகிற கூப்பிடுதலின் சத்தம் ஒரொனாயிமிலிருந்து கேட்கப்படும்.
	<br /><br />
	4. மோவாப் நொறுங்குண்டது; அதிலுள்ள சிறுவர்கள் கூப்பிடும் சத்தம் கேட்கப்படுகிறது.
	<br /><br />
	5. லூகித்துக்கு ஏறிப்போகிற வழியிலே அழுகையின்மேல் அழுகை எழும்பும்; ஒரொனாயிமுக்கு இறங்கிப்போகிற வழியிலே நொறுக்குதல் செய்கிறதினால் உண்டாகிய கூக்குரலைக் சத்துருக்கள் கேட்கிறார்கள்.
	<br /><br />
	6. உங்கள் பிராணன் தப்ப ஓடிப்போங்கள்; வனாந்தரத்திலுள்ள கறளையாய்ப்போன செடியைப்போலிருப்பீர்கள்.
	<br /><br />
	7. நீ உன் சம்பத்தையும் உன் பொக்கிஷங்களையும் நம்புகிறபடியினாலே நீயும் பிடிக்கப்படுவாய், அப்பொழுது கேமோஷ் சிறையாக்கப்பட்டுப்போம்; அதின் ஆசாரியரும் பிரபுக்களும் ஏகமாய்ச் சிறைப்பட்டுப்போவார்கள்.
	<br /><br />
	8. பாழாக்குகிறவன் எல்லாப் பட்டணங்களின்மேலும் வருவான்; ஒரு பட்டணமும் தப்பிப்போவதில்லை; பள்ளத்தாக்குகளும் கெட்டுப்போகும்; சமனான பூமியும் அழிக்கப்படும் என்று கர்த்தர் சொன்னார்.
	<br /><br />
	9. மோவாபுக்குச் செட்டைகளைக் கொடுங்கள்; அது பறந்துபோகட்டும்; அதின் பட்டணங்கள் குடிகளில்லாமல் பாழாய்ப்போகும்.
	<br /><br />
	10. கர்த்தருடைய வேலையை அசதியாய்ச் செய்கிறவன் சபிக்கப்பட்டவன்; இரத்தம் சிந்தாதபடிக்குத் தன் பட்டயத்தை அடக்கிக்கொள்ளுகிறவன் சபிக்கப்பட்டவன்.
	<br /><br />
	11. மோவாப் தன் சிறுவயதுமுதல் சுகமாய் வாழ்ந்தது; அது ஒரு பாத்திரத்திலிருந்து மறு பாத்திரத்தில் வார்க்கப்படாமலும், அதின் வண்டல்களின்மேல் அசையாமலும் இருந்தது; அது சிறையிருப்புக்குப் போனதில்லை; ஆதலால் அதின் ருசி அதில் நிலைத்திருந்தது; அதின் வாசனை வேறுபடவில்லை.
	<br /><br />
	12. ஆகையால், இதோ, நாட்கள் வருமென்று கர்த்தர் சொல்லுகிறார், அப்பொழுது கவிழ்த்துப்போடுகிறவர்களை அதற்கு அனுப்புவேன்; அவர்கள் அதைக் கவிழ்த்து, அதின் பாத்திரங்களை வெறுமையாக்கி, அதின் ஜாடிகளை உடைத்துப்போடுவார்கள்.
	<br /><br />
	13. அப்பொழுது இஸ்ரவேல் சந்ததி தங்கள் நம்பிக்கையான பெத்தேலாலே வெட்கப்பட்டதுபோல, மோவாப் கேமோஷாலே வெட்கப்படும்.
	<br /><br />
	14. நாங்கள் பராக்கிரமசாலிகளென்றும், நாங்கள் யுத்தசன்னத்தரென்றும் நீங்கள் சொல்லுகிறதென்ன?
	<br /><br />
	15. மோவாப் அழிந்தது, அதின் பட்டணங்கள் எரிந்துபோயின; அதின் திறமையுள்ள வாலிபர் கொலைக்களத்துக்கு இறங்குகிறார்கள் என்று சேனைகளின் கர்த்தர் என்னும் நாமமுள்ள ராஜா சொல்லுகிறார்.
	<br /><br />
	16. மோவாபின் ஆபத்துவரச் சமீபமாயிருக்கிறது; அதின் தீங்கு மிகவும் தீவிரித்துவருகிறது.
	<br /><br />
	17. அதின் சுற்றுப்புறத்தாரும் அதின் பேரை அறிந்தவர்களுமாகிய நீங்கள் எல்லாரும் அதற்காக அங்கலாய்த்துக் கொள்ளுங்கள்; பெலனான தடியும் அலங்காரமான கோலும் எப்படி உடைந்ததென்று சொல்லுங்கள்.
	<br /><br />
	18. தீபோன் பட்டணவாசியான குமாரத்தியே, நீ உன் மகிமையை விட்டிறங்கி, தாகத்தோடே உட்கார்ந்திரு; மோவாபைப் பாழாக்குகிறவன் உனக்கு விரோதமாய் வந்து, உன் அரண்களை அழித்துப்போடுவான்.
	<br /><br />
	19. ஆரோவேரில் குடியிருக்கிறவளே, நீ வழியிலே நின்று பார்த்துக்கொண்டிரு; நடந்ததென்னவென்று ஓடிவருகிறவனையும் தப்பிவருகிறவனையும் கேள்.
	<br /><br />
	20. மோவாப் முறிய அடிக்கப்பட்டபடியினால் கலங்கிப்போயிற்று; அலறிக்கூப்பிடுங்கள்; மோவாப் பாழாக்கப்பட்டதென்று அர்னோனில் அறிவியுங்கள்.
	<br /><br />
	21. சமனான பூமியாகிய ஓலோனின்மேலும், யாத்சாவின்மேலும், மேப்காத்தின்மேலும்,
	<br /><br />
	22. தீபோனின்மேலும், நேபோவின்மேலும், பெத்திப்லாத்தாயீமின்மேலும்,
	<br /><br />
	23. கீரியாத்தாயீமின்மேலும், பேத்கமூலின்மேலும், பெத்மெயோனின்மேலும்,
	<br /><br />
	24. கீரியோத்தின்மேலும், போஸ்றாவின்மேலும், மோவாப் தேசத்திலே தூரத்திலும் சமீபத்திலும் இருக்கிற எல்லாப் பட்டணங்களின்மேலும் நியாயத்தீர்ப்பு வரும்.
	<br /><br />
	25. மோவாபின் கொம்பு வெட்டுண்டது; அவன் புயம் முறிக்கப்பட்டது என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	26. அவனை வெறிகொள்ளச் செய்யுங்கள்; கர்த்தருக்கு விரோதமாய்ப் பெருமைபாராட்டினான்; மோவாப் தான் வாந்திபண்ணி அதிலே புரளுவான்; அவன் பரியாசத்துக்கிடமாவான்.
	<br /><br />
	27. இஸ்ரவேல் உனக்குப் பரியாசமாயிருந்தான் அல்லவோ? அவன் திருடருக்குள் கண்டுபிடிக்கப்பட்டானோ? நீ அவனைக்குறித்துப் பேசுகிறபோதெல்லாம், தலையைத் துலுக்குகிறாயே.
	<br /><br />
	28. மோவாப் தேசத்தின் குடிகளே, நீங்கள் பட்டணங்களை விட்டுப்போய், கன்மலையில் தங்கி, குகையின் வாய் ஓரங்களில் கூடுகட்டுகிற புறாவுக்கு ஒப்பாயிருங்கள்.
	<br /><br />
	29. அவன் மெத்தப் பெருமைக்காரன், மோவாபின் பெருமையையும், அவன் மேட்டிமையையும், அவன் அகந்தையையும், அவன் பெத்தரிக்கத்தையும், அவன் இருதயத்தின் மேட்டிமையையும் குறித்துக் கேட்டேன்.
	<br /><br />
	30. அவன் மூர்க்கத்தை நான் அறிவேன் என்று கர்த்தர் சொல்லுகிறார்; அப்படியாகாது, அவன் வீம்பு செல்லாது என்கிறார்.
	<br /><br />
	31. ஆகையால் மோவாபினிமித்தம் நான் அலறி, மோவாப் தேசம் அனைத்தினிமித்தமும் கூக்குரலிடுவேன்; கீராரேஸ் மனுஷரினிமித்தம் பெருமூச்சு விடப்படும்.
	<br /><br />
	32. சிப்மாவூரின் திராட்சச்செடியே, யாசேருக்காக நான் அழுததுபோல உனக்காகவும் அழுவேன்; உன் கொடிகள் கடலைக் கடந்துபோயின; அவைகள் யாசேர் கடல்மட்டும் போய் எட்டின; பாழாக்குகிறவன் உன் வசந்த காலத்துப் பழங்களின்மேலும், உன் திராட்சப்பழ அறுப்பின்மேலும் விழுந்தான்.
	<br /><br />
	33. பயிர்வெளியிலும் மோவாப் தேசத்திலுமிருந்து சந்தோஷமும் களிப்பும் நீங்கிப்போயிற்று; திராட்சரசம் ஆலைகளிலிருந்து பொழிகிறதை ஓயப்பண்ணினேன்; ஆலையை மிதிக்கிறவர்களின் பாடல் இல்லை; அது ஆரவாரமேயல்லாமல் ஆலை மிதிக்கும் பாடலல்ல.
	<br /><br />
	34. எஸ்போன்துவக்கி எலெயாலேமட்டும் யாகாஸ்வரைக்கும் உண்டாகும் கூக்குரலினிமித்தம் அவர்கள் மூன்றுவயதுக் கடாரியைப்போல், சோவார்துவக்கி ஒரொனாயிம்மட்டும் சத்தமிடுவார்கள்; நிம்ரீமின் ஜலங்களும் வற்றிப்போகும்.
	<br /><br />
	35. மோவாப்தேசத்து மேடைகளில் பலியிடுகிறவனையும் தன் தேவர்களுக்குத் தூபங்காட்டுகிறவனையும் ஓயப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	36. ஆகையால், மோவாபினிமித்தம் என் இருதயம் நாகசுரம்போல் துயரமாய்த் தொனிக்கும்; கீராரேஸ் மனுஷரினிமித்தமும், என் இருதயம் நாகசுரம்போல் துயரமாய்த் தொனிக்கும்; அவர்கள் சம்பாதித்த ஐசுவரியம் அழிந்து போகிறபடியினால் அப்படித் தொனிக்கும்.
	<br /><br />
	37. தலைகள் எல்லாம் மொட்டையிடப்பட்டும், தாடிகள் எல்லாம் கத்தரிக்கப்பட்டும் இருக்கும்; கைகளில் எல்லாம் கீறுதல்களும், அரைகளில் இரட்டுடுப்பும் உண்டு.
	<br /><br />
	38. மோவாபின் சகல வீடுகளின்மேலும் அதின் தெருக்களிலேயும் ஏகப்புலம்பல் உண்டாகும்; ஒருவரும் விரும்பப்படாத பாத்திரம்போல மோவாபை உடைத்துப்போட்டேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	39. மோவாப் எவ்வளவாய் முறிந்துபோயிற்றென்று அலறுகிறார்கள்; அது முதுகைக்காட்டி எவ்வளவாய் வெட்கப்படும்? இப்படி மோவாப் தன் சுற்றுப்புறத்தார் அனைவருக்கும் பரியாசமும் திகைப்புமாயிருக்கும்.
	<br /><br />
	40. இதோ, ஒருவன் கழுகைப்போல் பறந்துவந்து, மோவாபின்மேல் தன் செட்டைகளை விரிப்பான்.
	<br /><br />
	41. கீரியோத் பிடிக்கப்படும், கோட்டைகள் கைவசமாகும்; அந்நாளிலே மோவாபின் பராக்கிரமசாலிகளுடைய இருதயம் பிரசவவேதனைப்படுகிற ஸ்திரீயின் இருதயம்போல இருக்கும்.
	<br /><br />
	42. மோவாப் கர்த்தருக்கு விரோதமாய்ப் பெருமைபாராட்டினபடியால், அது ஒரு ஜனமாயிராதபடிக்கு அழிக்கப்படும்.
	<br /><br />
	43. மோவாப் தேசத்தின் குடியானவனே, திகிலும், படுகுழியும், கண்ணியும் உன்மேல் வரும் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	44. திகிலுக்கு விலக ஓடுகிறவன் படுகுழியிலே விழுவான்; படுகுழியிலிருந்து ஏறுகிறவனோ கண்ணியிலே பிடிபடுவான்; அவர்கள் விசாரிக்கப்படும் வருஷத்தை அதின்மேல், அதாவது, மோவாபின்மேல் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	45. வல்லடிக்குத் தப்ப ஓடிப்போகிறவர்கள் எஸ்போனின் நிழலில் தரித்து நின்றார்கள், ஆனாலும் நெருப்பு எஸ்போனிலும், அக்கினிஜுவாலை சீகோன் நடுவிலுமிருந்து புறப்பட்டு, மோவாப் தேசத்தின் எல்லைகளையும், கலகஞ்செய்கிறவர்களின் உச்சந்தலையையும் பட்சிக்கும்.
	<br /><br />
	46. மோவாபே, உனக்கு ஐயோ! கேமோஷ் விக்கிரகத்தையடுத்த ஜனம் அழியும், உன் குமாரரும் சிறைபிடிக்கப்படுகிறார்கள், உன் குமாரத்திகளும் சிறைபிடிக்கப்பட்டுப்போகிறார்கள்.
	<br /><br />
	47. ஆனாலும் கடைசிநாட்களில் மோவாபின் சிறையிருப்பைத் திருப்புவேன் என்று கர்த்தர் சொல்லுகிறார். மோவாபின்மேல் வரும் நியாயத்தீர்ப்பின் செய்தி இத்தோடே முடிந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah48