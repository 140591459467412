import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 5 </title>
	<meta
          name="description"
          content="Daniel 5 | தானியேல் தீர்க்கதரிசி | தானியேல் 5 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பெல்ஷாத்சார் என்னும் ராஜா தன் பிரபுக்களில் ஆயிரம்பேருக்கு ஒரு பெரிய விருந்துசெய்து, அந்த ஆயிரம்பேருக்கு முன்பாகத் திராட்சரசம் குடித்தான்.
	<br /><br />
	2. பெல்ஷாத்சார் திராட்சரசத்தை ருசித்துக்கொண்டிருக்கையில், அவன் தன் தகப்பனாகிய நேபுகாத்நேச்சார் எருசலேம் தேவாலயத்திலிருந்து கொண்டுவந்த பொன் வெள்ளி பாத்திரங்களில், ராஜாவாகிய தானும் தன் பிரபுக்களும் தன் மனைவிகளும் தன் வைப்பாட்டிகளும் குடிக்கிறதற்காக அவைகளைக் கொண்டுவரும்படி கட்டளையிட்டான்.
	<br /><br />
	3. அப்பொழுது எருசலேமிலுள்ள தேவனுடைய வீடாகிய ஆலயத்திலிருந்து எடுக்கப்பட்ட பொற்பாத்திரங்களைக் கொண்டுவந்தார்கள்; அவைகளில் ராஜாவும் அவனுடைய பிரபுக்களும் அவனுடைய மனைவிகளும் அவனுடைய வைப்பாட்டிகளும் குடித்தார்கள்.
	<br /><br />
	4. அவர்கள் திராட்சரசம் குடித்து, பொன்னும் வெள்ளியும் வெண்கலமும் இரும்பும் மரமும் கல்லுமாகிய தேவர்களைப் புகழ்ந்தார்கள்.
	<br /><br />
	5. அந்நேரத்திலே மனுஷ கைவிரல்கள் தோன்றி, விளக்குக்கு எதிராக ராஜ அரமனையின் சாந்து பூசப்பட்ட சுவரிலே எழுதிற்று; எழுதின அந்தக் கையுறுப்பை ராஜா கண்டான்.
	<br /><br />
	6. அப்பொழுது ராஜாவின் முகம் வேறுபட்டது; அவனுடைய நினைவுகள் அவனைக் கலங்கப்பண்ணினது; அவனுடைய இடுப்பின் கட்டுகள் தளர்ந்தது, அவனுடைய முழங்கால்கள் ஒன்றோடொன்று மோதிக்கொண்டது.
	<br /><br />
	7. ராஜா உரத்த சத்தமிட்டு; ஜோசியரையும் கல்தேயரையும் குறிசொல்லுகிறவர்களையும் உள்ளே அழைத்துவரும்படி சொன்னான். ராஜா பாபிலோன் ஞானிகளை நோக்கி: இந்த எழுத்தை வாசித்து, இதின் அர்த்தத்தை எனக்கு வெளிப்படுத்துகிறவன் எவனோ, அவன் இரத்தாம்பரமும் கழுத்திலே பொற்சரப்பணியும் தரிக்கப்பட்டு, ராஜ்யத்திலே மூன்றாம் அதிபதியாய் இருப்பான் என்று சொன்னான்.
	<br /><br />
	8. அப்பொழுது ராஜாவின் ஞானிகளெல்லாரும் வந்து சேர்ந்தார்கள்; ஆனாலும் அவர்கள் அந்த எழுத்தை வாசிக்கவும், அதின் அர்த்தத்தை ராஜாவுக்குத் தெரிவிக்கவும் கூடாதிருந்தது.
	<br /><br />
	9. அப்பொழுது ராஜாவாகிய பெல்ஷாத்சார் மிகவும் கலங்கினான்; அவனுடைய முகம் வேறுபட்டது; அவனுடைய பிரபுக்கள் திகைத்தார்கள்.
	<br /><br />
	10. ராஜாவும் அவனுடைய பிரபுக்களும் சொன்னவைகளை ராஜாத்தி கேள்விப்பட்டு விருந்துசாலைக்குள் பிரவேசித்தாள். அப்பொழுது ராஜாத்தி: ராஜாவே, நீர் என்றும் வாழ்க; உமது நினைவுகள் உம்மைக் கலங்கப்பண்ணவும், உமது முகம் வேறுபடவும் வேண்டியதில்லை.
	<br /><br />
	11. உம்முடைய ராஜ்யத்திலே ஒரு புருஷன் இருக்கிறான், அவனுக்குள் பரிசுத்த தேவர்களுடைய ஆவி இருக்கிறது; உம்முடைய பிதாவின் நாட்களில் வெளிச்சமும் விவேகமும் தேவர்களின் ஞானத்துக்கு ஒத்த ஞானமும் அவனிடத்தில் காணப்பட்டது; ஆகையால் உம்முடைய பிதாவாகிய நேபுகாத்நேச்சாரென்னும் ராஜாவானவர் அவனைச் சாஸ்திரிகளுக்கும் ஜோசியருக்கும் கல்தேயருக்கும் குறிசொல்லுகிறவர்களுக்கும் அதிபதியாக வைத்தார்.
	<br /><br />
	12. ராஜாவினால் பெல்தெஷாத்சாரென்னும் பெயரிடப்பட்ட அந்தத் தானியேலுக்குள் சொப்பனங்களை வியார்த்திபண்ணுகிறதும், புதைபொருள்களை வெளிப்படுத்துகிறதும், கருகலானவைகளைத் தெளிவிக்கிறதுமான அறிவும் புத்தியும் விசேஷித்த ஆவியும் உண்டென்று காணப்பட்டது; இப்போதும் தானியேல் அழைக்கப்படட்டும், அவன் அர்த்தத்தை வெளிப்படுத்துவான் என்றாள்.
	<br /><br />
	13. அப்பொழுது தானியேல் ராஜாவின்முன் உள்ளே அழைத்துவந்து விடப்பட்டான்; ராஜா தானியேலைப் பார்த்து: நீ என் பிதாவாகிய ராஜா யூதாவிலிருந்து சிறைபிடித்துவந்த யூதரில் ஒருவனாகிய தானியேல் அல்லவா?
	<br /><br />
	14. உனக்குள்ளே தேவர்களின் ஆவி உண்டென்றும், வெளிச்சமும் புத்தியும் விசேஷித்த ஞானமும் உன்னிடத்தில் காணப்பட்டதென்றும் உன்னைக்குறித்துக் கேள்விப்பட்டேன்.
	<br /><br />
	15. இப்போதும் இந்த எழுத்தை வாசிக்கிறதற்கும், இதின் அர்த்தத்தை எனக்குத் தெரிவிக்கிறதற்கும் சாஸ்திரிகளும் ஜோசியரும் எனக்கு முன்பாக அழைத்துக்கொண்டுவரப்பட்டார்கள்; ஆனாலும் இந்த வசனத்தின் அர்த்தத்தை வெளிப்படுத்த அவர்களால் கூடாமற்போயிற்று.
	<br /><br />
	16. பொருளை வெளிப்படுத்தவும், கருகலானவைகளைத் தெளிவிக்கவும் உன்னாலே கூடுமென்று உன்னைக்குறித்துக் கேள்விப்பட்டேன்; இப்போதும் நீ இந்த எழுத்தை வாசிக்கவும், இதின் அர்த்தத்தை எனக்குத் தெரிவிக்கவும் உன்னாலே கூடுமானால், நீ இரத்தாம்பரமும் கழுத்திலே பொற்சரப்பணியும் தரிக்கப்பட்டு, ராஜ்யத்திலே மூன்றாம் அதிபதியாய் இருப்பாய் என்றான்.
	<br /><br />
	17. அப்பொழுது தானியேல் ராஜசமுகத்தில் பிரதியுத்தரமாக: உம்முடைய வெகுமானங்கள் உம்மிடத்திலேயே இருக்கட்டும்; உம்முடைய பரிசுகளை வேறொருவனுக்குக் கொடும்; இந்த எழுத்தை நான் ராஜாவுக்கு வாசித்து, இதின் அர்த்தத்தைத் தெரிவிப்பேன்.
	<br /><br />
	18. ராஜாவே, உன்னதமான தேவன் உம்முடைய பிதாவாகிய நேபுகாத்நேச்சாருக்கு ராஜ்யத்தையும் மகத்துவத்தையும் கனத்தையும் மகிமையையும் கொடுத்தார்.
	<br /><br />
	19. அவருக்குக் கொடுக்கப்பட்ட மகத்துவத்தினாலே சகல ஜனங்களும் ஜாதியாரும் பாஷைக்காரரும் அவருக்கு முன்பாக நடுங்கிப் பயந்திருந்தார்கள்; அவர் தமக்குச் சித்தமானவனைக் கொன்றுபோடுவார், தமக்குச் சித்தமானவனை உயிரோடே வைப்பார்; தமக்குச் சித்தமானவனை உயர்த்துவார், தமக்குச் சித்தமானவனைத் தாழ்த்துவார்.
	<br /><br />
	20. அவருடைய இருதயம் மேட்டிமையாகி, அவருடைய ஆவி கர்வத்தினாலே கடினப்பட்டபோது, அவர் தமது சிங்காசனத்திலிருந்து தள்ளப்பட்டார்; அவருடைய மகிமை அவரைவிட்டு அகன்றுபோயிற்று.
	<br /><br />
	21. அவர் மனுஷரினின்று தள்ளப்பட்டார்; அவருடைய இருதயம் மிருகங்களுடைய இருதயம்போலாயிற்று; காட்டுக்கழுதைகளோடே சஞ்சரித்தார்; உன்னதமான தேவன் மனுஷரின் ராஜ்யத்தில் ஆளுகைசெய்து, தமக்குச் சித்தமானவனை அதின்மேல் அதிகாரியாக்குகிறார் என்று அவர் உணர்ந்துகொள்ளுமட்டும் மாடுகளைப்போல் புல்லை மேய்ந்தார்; அவருடைய சரீரம் ஆகாயத்துப் பனியிலே நனைந்தது.
	<br /><br />
	22. அவருடைய குமாரனாகிய பெல்ஷாத்சார் என்னும் நீரோவென்றால், இதையெல்லாம் அறிந்திருந்தும், உமது இருதயத்தைத் தாழ்த்தாமல்,
	<br /><br />
	23. பரலோகத்தின் ஆண்டவருக்கு விரோதமாக உம்மை உயர்த்தினீர்; அவருடைய ஆலயத்தின் பாத்திரங்களை உமக்கு முன்பாகக் கொண்டுவந்தார்கள்; நீரும், உம்முடைய பிரபுக்களும், உம்முடைய மனைவிகளும், உம்முடைய வைப்பாட்டிகளும் அவைகளில் திராட்சரசம் குடித்தீர்கள்; இதுவுமன்றி, தம்முடைய கையில் உமது சுவாசத்தை வைத்திருக்கிறவரும், உமது வழிகளுக்கு எல்லாம் அதிகாரியுமாகிய தேவனை நீர் மகிமைப்படுத்தாமல் காணாமலும் கேளாமலும் உணராமலும் இருக்கிற வெள்ளியும் பொன்னும் வெண்கலமும் இரும்பும் மரமும் கல்லுமாகிய தேவர்களைப் புகழ்ந்தீர்.
	<br /><br />
	24. அப்பொழுது அந்தக் கையுறுப்பு அவரால் அனுப்பப்பட்டு, இந்த எழுத்து எழுதப்பட்டது.
	<br /><br />
	25. எழுதப்பட்ட எழுத்து என்னவென்றால்: மெனே, மெனே, தெக்கேல், உப்பார்சின் என்பதே.
	<br /><br />
	26. இந்த வசனத்தின் அர்த்தமாவது: மெனே என்பதற்கு, தேவன் உன் ராஜ்யத்தை மட்டிட்டு, அதற்கு முடிவுண்டாக்கினார் என்றும்,
	<br /><br />
	27. தெக்கேல் என்பதற்கு, நீ தராசிலே நிறுக்கப்பட்டு, குறையக் காணப்பட்டாய் என்றும்,
	<br /><br />
	28. பெரேஸ் என்பதற்கு, உன் ராஜ்யம் பிரிக்கப்பட்டு, மேதியருக்கும் பெர்சியருக்கும் கொடுக்கப்பட்டது என்றும் அர்த்தமாம் என்றான்.
	<br /><br />
	29. அப்பொழுது பெல்ஷாத்சார் தானியேலுக்கு இரத்தாம்பரத்தையும், அவனுடைய கழுத்தில் பொற்சரப்பணியையும் தரிப்பிக்கவும், ராஜ்யத்திலே அவன் மூன்றாம் அதிகாரியாயிருப்பவன் என்று அவனைக்குறித்துப் பறைமுறையிடவும் கட்டளையிட்டான்.
	<br /><br />
	30. அன்று இராத்திரியிலே கல்தேயரின் ராஜாவாகிய பெல்ஷாத்சார் கொலைசெய்யப்பட்டான்.
	<br /><br />
	31. மேதியனாகிய தரியு தன் அறுபத்திரண்டாம் வயதில் ராஜ்யத்தைக் கட்டிக்கொண்டான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel5