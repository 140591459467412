import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 3 </title>
	<meta
          name="description"
          content="Mark 3 | மாற்கு 3 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மறுபடியும் அவர் ஜெபஆலயத்தில் பிரவேசித்தார். அங்கே சூம்பின கையையுடைய ஒரு மனுஷன் இருந்தான்.
	<br /><br />
	2. அவர் ஓய்வுநாளில் அவனைச் சொஸ்தமாக்கினால் அவர்பேரில் குற்றஞ்சாட்டலாமென்று அவர்மேல் நோக்கமாயிருந்தார்கள்.
	<br /><br />
	3. அப்பொழுது அவர் சூம்பின கையையுடைய மனுஷனை நோக்கி: எழுந்து நடுவே நில் என்று சொல்லி;
	<br /><br />
	4. அவர்களைப் பார்த்து: ஓய்வுநாட்களில் நன்மைசெய்வதோ, தீமைசெய்வதோ, ஜீவனைக் காப்பதோ அழிப்பதோ, எது நியாயம் என்றார். அதற்கு அவர்கள் பேசாமலிருந்தார்கள்.
	<br /><br />
	5. அவர்களுடைய இருதயகடினத்தினிமித்தம் அவர் விசனப்பட்டு, கோபத்துடனே சுற்றிலும் இருந்தவர்களைப் பார்த்து, அந்த மனுஷனை நோக்கி: உன் கையை நீட்டு என்றார்; அவன் நீட்டினான்; அவன் கை மறுகையைப்போலச் சொஸ்தமாயிற்று.
	<br /><br />
	6. உடனே பரிசேயர் புறப்பட்டுப்போய், அவரைக் கொலைசெய்யும்படி, அவருக்கு விரோதமாய் ஏரோதியரோடேகூட ஆலோசனைபண்ணினார்கள்.
	<br /><br />
	7. இயேசு தம்முடைய சீஷர்களோடே அவ்விடம்விட்டு, கடலோரத்துக்குப் போனார்.
	<br /><br />
	8. கலிலேயாவிலும், யூதேயாவிலும், எருசலேமிலும், இதுமேயாவிலும், யோர்தானுக்கு அக்கரையிலுமிருந்து திரளான ஜனங்கள் வந்து, அவருக்குப் பின்சென்றார்கள். அல்லாமலும் தீரு சீதோன் பட்டணங்களின் திசைகளிலுமிருந்து திரளான ஜனங்கள் அவர் செய்த அற்புதங்களைக்குறித்துக் கேள்விப்பட்டு, அவரிடத்தில் வந்தார்கள்.
	<br /><br />
	9. அவர் அநேகரைச் சொஸ்தமாக்கினார். நோயாளிகளெல்லாரும் அதை அறிந்து அவரைத் தொடவேண்டுமென்று அவரிடத்தில் நெருங்கிவந்தார்கள்.
	<br /><br />
	10. ஜனங்கள் திரளாயிருந்தபடியால் அவர்கள் தம்மை நெருக்காதபடிக்கு, தமக்காக ஒரு படவை ஆயத்தம்பண்ணவேண்டுமென்று, தம்முடைய சீஷர்களுக்குச் சொன்னார்.
	<br /><br />
	11. அசுத்த ஆவிகளும் அவரைக் கண்டபோது, அவர் முன்பாக விழுந்து: நீர் தேவனுடைய குமாரன் என்று சத்தமிட்டன.
	<br /><br />
	12. தம்மைப் பிரசித்தம்பண்ணாதபடி அவைகளுக்குக் கண்டிப்பாய்க் கட்டளையிட்டார்.
	<br /><br />
	13. பின்பு அவர் ஒரு மலையின்மேல் ஏறி, தமக்குச் சித்தமானவர்களைத் தம்மிடத்தில் வரவழைத்தார்; அவர்கள் அவரிடத்திற்கு வந்தார்கள்.
	<br /><br />
	14. அப்பொழுது அவர் பன்னிரண்டு பேரைத் தெரிந்துகொண்டு, அவர்கள் தம்மோடுகூட இருக்கவும், பிரசங்கம்பண்ணும்படியாகத் தாம் அவர்களை அனுப்பவும்,
	<br /><br />
	15. வியாதிகளைக் குணமாக்கிப் பிசாசுகளைத் துரத்தும்படி அவர்கள் அதிகாரமுடையவர்களாயிருக்கவும், அவர்களை ஏற்படுத்தினார்.
	<br /><br />
	16. அவர்கள் யாரெனில், சீமோன், இவனுக்குப் பேதுரு என்கிற பெயரிட்டார்.
	<br /><br />
	17. செபெதேயுவின் குமாரனாகிய யாக்கோபு, யாக்கோபின் சகோதரனாகிய யோவான், இவ்விருவருக்கும் இடிமுழக்க மக்களென்று அர்த்தங்கொள்ளும் பொவனெர்கேஸ் என்கிற பெயரிட்டார்,
	<br /><br />
	18. அந்திரேயா, பிலிப்பு, பற்தொலொமேயு, மத்தேயு, தோமா, அல்பேயுவின் குமாரன் யாக்கோபு, ததேயு, கானானியனாகிய சீமோன்,
	<br /><br />
	19. அவரைக் காட்டிக்கொடுத்த யூதாஸ்காரியோத்து என்பவர்களே.
	<br /><br />
	20. பின்பு வீட்டுக்குப் போனார்கள்; அங்கே அவர்கள் சாப்பிடுவதற்கும் சமயமில்லாதபடிக்கு அநேக ஜனங்கள் மறுபடியும் கூடிவந்தார்கள்.
	<br /><br />
	21. அவருடைய இனத்தார் இதைக்கேட்டபோது, அவர் மதிமயங்கியிருக்கிறார் என்று சொல்லி, அவரைப் பிடித்துக்கொள்ளும்படி வந்தார்கள்.
	<br /><br />
	22. எருசலேமிலிருந்து வந்த வேதபாரகர்: இவன் பெயெல்செபூலைக்கொண்டிருக்கிறான், பிசாசுகளின் தலைவனாலே பிசாசுகளைத் துரத்துகிறான் என்றார்கள்.
	<br /><br />
	23. அவர்களை அவர் அழைத்து, உவமைகளாய் அவர்களுக்குச் சொன்னதாவது: சாத்தானைச் சாத்தான் துரத்துவது எப்படி?
	<br /><br />
	24. ஒரு ராஜ்யம் தனக்குத்தானே விரோதமாகப் பிரிந்திருந்தால், அந்த ராஜ்யம் நிலைநிற்கமாட்டாதே.
	<br /><br />
	25. ஒரு வீடு தனக்குத்தானே விரோதமாகப் பிரிந்திருந்தால், அந்த வீடு நிலைநிற்கமாட்டாதே.
	<br /><br />
	26. சாத்தான் தனக்குத்தானே விரோதமாக எழும்பிப் பிரிந்திருந்தால், அவன் நிலைநிற்கமாட்டாமல், அழிந்துபோவானே.
	<br /><br />
	27. பலவானை முந்திக் கட்டினாலொழிய, ஒருவனும் பலவானுடைய வீட்டுக்குள் புகுந்து, அவன் உடைமைகளைக் கொள்ளையிடக்கூடாது; கட்டினானேயாகில், அவன் வீட்டைக் கொள்ளையிடுவான்.
	<br /><br />
	28. மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன்: மனுஷர்கள் செய்யும் எல்லாப் பாவங்களும், அவர்கள் தூஷிக்கும் எந்தத் தூஷணங்களும், அவர்களுக்கு மன்னிக்கப்படும்;
	<br /><br />
	29. ஒருவன் பரிசுத்த ஆவிக்கு விரோதமாகத் தூஷணஞ்சொல்வானாகில், அவன் என்றென்றைக்கும் மன்னிப்படையாமல் நித்திய ஆக்கினைக்குள்ளாயிருப்பான் என்றார்.
	<br /><br />
	30. அசுத்த ஆவியைக் கொண்டிருக்கிறானென்று அவர்கள் சொன்னபடியினாலே அவர் இப்படிச் சொன்னார்.
	<br /><br />
	31. அப்பொழுது அவருடைய சகோதரரும் தாயாரும் வந்து, வெளியே நின்று, அவரை அழைக்கும்படி அவரிடத்தில் ஆள் அனுப்பினார்கள்.
	<br /><br />
	32. அவரைச் சுற்றிலும் உட்கார்ந்திருந்த ஜனங்கள் அவரை நோக்கி: இதோ, உம்முடைய தாயாரும் உம்முடைய சகோதரரும் வெளியே நின்று உம்மைத் தேடுகிறார்கள் என்றார்கள்.
	<br /><br />
	33. அவர்களுக்கு அவர் பிரதியுத்தரமாக: என் தாயார் யார்? என் சகோதரர் யார்? என்று சொல்லி;
	<br /><br />
	34. தம்மைச் சூழ உட்கார்ந்திருந்தவர்களைச் சுற்றிப்பார்த்து: இதோ, என் தாயும், என் சகோதரரும் இவர்களே!
	<br /><br />
	35. தேவனுடைய சித்தத்தின்படி செய்கிறவன் எவனோ அவனே எனக்குச் சகோதரனும், எனக்குச் சகோதரியும், எனக்குத் தாயுமாய் இருக்கிறான் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark3