import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation11 = () => {
  const verseNumber = 11;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation | Tamil Bible Verse | வெளிப்படுத்தல் - 11 </title>
	<meta
          name="description"
          content="The Book of Revelation - 11 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 11 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* மிருகம் அவர்களைக் கொல்லும் வரை இரண்டு சாட்சிகளும் தீர்க்கதரிசனம் சொல்வார்கள். ஏழாவது எக்காளத்தில், உரத்த குரல்கள், "எங்கள் கடவுள் என்றென்றும் ஆட்சி செய்வார்" என்று கூறினார். *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}


      <p>
	1. பின்பு கைக்கோலுக்கு ஒப்பான ஒரு அளவுகோல் என்னிடத்தில் கொடுக்கப்பட்டது. அப்பொழுது தேவதூதன் நின்று, என்னை நோக்கி: நீ எழுந்து, தேவனுடைய ஆலயத்தையும், பலிபீடத்தையும், அதில் தொழுதுகொள்ளுகிறவர்களையும் அளந்துபார்.
	<br /><br />
	2. ஆலயத்திற்குப் புறம்பே இருக்கிற பிராகாரம் புறஜாதியாருக்குக் கொடுக்கப்பட்டபடியால் அதை அளவாமல் புறம்பாக்கிப்போடு; பரிசுத்த நகரத்தை அவர்கள் நாற்பத்திரண்டு மாதமளவும் மிதிப்பார்கள்.
	<br /><br />
	3. என்னுடைய இரண்டு சாட்சிகளும் இரட்டு வஸ்திரமுடுத்திக்கொண்டிருக்கிறவர்களாய், ஆயிரத்திருநூற்றறுபது நாளளவும் தீர்க்கதரிசனஞ்சொல்லும்படி அவர்களுக்கு அதிகாரம் கொடுப்பேன்.
	<br /><br />
	4. பூலோகத்தின் ஆண்டவருக்குமுன்பாக நிற்கிற இரண்டு ஒலிவமரங்களும் இரண்டு விளக்குத்தண்டுகளும் இவர்களே.
	<br /><br />
	5. ஒருவன் அவர்களைச் சேதப்படுத்த மனதாயிருந்தால், அவர்களுடைய வாயிலிருந்து அக்கினி புறப்பட்டு, அவர்களுடைய சத்துருக்களைப் பட்சிக்கும்; அவர்களைச் சேதப்படுத்த மனதாயிருக்கிறவன் எவனோ அவன் அப்படியே கொல்லப்படவேண்டும்.
	<br /><br />
	6. அவர்கள் தீர்க்கதரிசனஞ் சொல்லிவருகிற நாட்களிலே மழைபெய்யாதபடிக்கு வானத்தை அடைக்க அவர்களுக்கு அதிகாரமுண்டு; அவர்கள் தண்ணீர்களை இரத்தமாக மாற்றவும், தங்களுக்கு வேண்டும்போதெல்லாம் பூமியைச் சகலவித வாதைகளாலும் வாதிக்கவும் அவர்களுக்கு அதிகாரமுண்டு.
	<br /><br />
	7. அவர்கள் தங்கள் சாட்சியைச் சொல்லி முடித்திருக்கும்போது, பாதாளத்திலிருந்தேறுகிற மிருகம் அவர்களோடே யுத்தம்பண்ணி, அவர்களை ஜெயித்து, அவர்களைக் கொன்றுபோடும்.
	<br /><br />
	8. அவர்களுடைய உடல்கள் மகா நகரத்தின் விசாலமான வீதியிலே கிடக்கும். அந்த நகரம் சோதோம் என்றும் எகிப்து என்றும் ஞானார்த்தமாய்ச் சொல்லப்படும்; அதிலே நம்முடைய கர்த்தரும் சிலுவையிலே அறையப்பட்டார்.
	<br /><br />
	9. ஜனங்களிலும், கோத்திரங்களிலும், பாஷைக்காரரிலும், ஜாதிகளிலுமுள்ளவர்கள் அவர்களுடைய உடல்களை மூன்றரை நாள்வரைக்கும் பார்ப்பார்கள், அவர்களுடைய உடல்களைக் கல்லறைகளில் வைக்கவொட்டார்கள்.
	<br /><br />
	10. அவ்விரண்டு தீர்க்கதரிசிகளும் பூமியின் குடிகளை வேதனைப்படுத்தினபடியால் அவர்கள் நிமித்தம் பூமியில் குடியிருக்கிறவர்கள் சந்தோஷப்பட்டுக் களிகூர்ந்து, ஒருவருக்கொருவர் வெகுமதிகளை அனுப்புவார்கள்.
	<br /><br />
	11. மூன்றரை நாளைக்குப்பின்பு தேவனிடத்திலிருந்து ஜீவஆவி அவர்களுக்குள் பிரவேசித்தது, அப்பொழுது அவர்கள் காலூன்றி நின்றார்கள்; அவர்களைப் பார்த்தவர்களுக்கு மிகுந்த பயமுண்டாயிற்று.
	<br /><br />
	12. இங்கே ஏறிவாருங்கள் என்று வானத்திலிருந்து தங்களுக்கு உண்டான பெரிய சத்தத்தை அவர்கள் கேட்டு, மேகத்தில் ஏறி வானத்திற்குப் போனார்கள்; அவர்களுடைய சத்துருக்கள் அவர்களைப் பார்த்தார்கள்.
	<br /><br />
	13. அந்நேரத்திலே பூமி மிகவும் அதிர்ந்தது, உடனே அந்த நகரத்தில் பத்திலொருபங்கு இடிந்து விழுந்தது; மனுஷரில் ஏழாயிரம்பேர் பூமியதிர்ச்சியினால் அழிந்தார்கள்; மீதியானவர்கள் பயமடைந்து பரலோகத்தின் தேவனை மகிமைப்படுத்தினார்கள்.
	<br /><br />
	14. இரண்டாம் ஆபத்து கடந்துபோயிற்று; இதோ, மூன்றாம் ஆபத்து சீக்கிரமாய் வருகிறது.
	<br /><br />
	15. ஏழாம் தூதன் எக்காளம் ஊதினான்; அப்பொழுது உலகத்தின் ராஜ்யங்கள் நம்முடைய கர்த்தருக்கும், அவருடைய கிறிஸ்துவுக்குமுரிய ராஜ்யங்களாயின; அவர் சதாகாலங்களிலும் ராஜ்யபாரம் பண்ணுவார் என்னும் கெம்பீர சத்தங்கள் வானத்தில் உண்டாயின.
	<br /><br />
	16. அப்பொழுது தேவனுக்கு முன்பாகத் தங்கள் சிங்காசனங்கள்மேல் உட்கார்ந்திருந்த இருபத்துநான்கு மூப்பர்களும் முகங்குப்புற விழுந்து:
	<br /><br />
	17. இருக்கிறவரும் இருந்தவரும் வருகிறவருமாகிய சர்வவல்லமையுள்ள கர்த்தராகிய தேவனே, உம்மை ஸ்தோத்திரிக்கிறோம்; தேவரீர் உமது மகா வல்லமையைக்கொண்டு ராஜ்யபாரம்பண்ணுகிறீர்.
	<br /><br />
	18. ஜாதிகள் கோபித்தார்கள், அப்பொழுது உம்முடைய கோபம் மூண்டது; மரித்தோர் நியாயத்தீர்ப்படைகிறதற்கும், தீர்க்கதரிசிகளாகிய உம்முடைய ஊழியக்காரருக்கும் பரிசுத்தவான்களுக்கும் உமது நாமத்தின்மேல் பயபக்தியாயிருந்த சிறியோர் பெரியோருக்கும் பலனளிக்கிறதற்கும், பூமியைக் கெடுத்தவர்களைக் கெடுக்கிறதற்கும், காலம் வந்தது என்று சொல்லி, தேவனைத் தொழுதுகொண்டார்கள்.
	<br /><br />
	19. அப்பொழுது பரலோகத்தில் தேவனுடைய ஆலயம் திறக்கப்பட்டது, அவருடைய ஆலயத்திலே அவருடைய உடன்படிக்கையின் பெட்டி காணப்பட்டது; அப்பொழுது மின்னல்களும், சத்தங்களும், இடிமுழக்கங்களும், பூமியதிர்ச்சியும், பெருங்கல்மழையும் உண்டாயின.
</p>


      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h11>
*  *
			</h11>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation11