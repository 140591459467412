import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Daniel4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | தானியேல் 4 </title>
	<meta
          name="description"
          content="Daniel 4 | தானியேல் தீர்க்கதரிசி | தானியேல் 4 
          Daniel Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ராஜாவாகிய நேபுகாத்நேச்சார் பூமி எங்கும் குடியிருக்கிற சகல ஜனத்தாருக்கும் ஜாதியாருக்கும் பாஷைக்காரருக்கும் எழுதுகிறது என்னவென்றால்: உங்களுக்குச் சமாதானம் பெருகக்கடவது.
	<br /><br />
	2. உன்னதமான தேவன் என்னிடத்தில் செய்த அடையாளங்களையும் அற்புதங்களையும் பிரசித்தப்படுத்துவது எனக்கு நன்மையாய்க் கண்டது.
	<br /><br />
	3. அவருடைய அடையாளங்கள் எவ்வளவு மகத்துவமும், அவருடைய அற்புதங்கள் எவ்வளவு வல்லமையுமாயிருக்கிறது; அவருடைய ராஜ்யம் நித்திய ராஜ்யம்; அவருடைய ஆளுகை தலைமுறை தலைமுறையாக நிற்கும்.
	<br /><br />
	4. நேபுகாத்நேச்சாராகிய நான் என் வீட்டிலே சவுக்கியமுள்ளவனாயிருந்து என் அரமனையிலே வாழ்ந்துகொண்டிருந்தேன்.
	<br /><br />
	5. நான் ஒரு சொப்பனத்தைக் கண்டேன்; அது எனக்குத் திகிலை உண்டாக்கிற்று; என் படுக்கையின்மேல் எனக்குள் உண்டான நினைவுகளும், என் தலையில் தோன்றின தரிசனங்களும் என்னைக் கலங்கப்பண்ணிற்று.
	<br /><br />
	6. ஆகையால் சொப்பனத்தின் அர்த்தத்தை எனக்குத் தெரிவிக்கிறதற்காகப் பாபிலோன் ஞானிகளையெல்லாம் என்னிடத்தில் கொண்டுவரும்படி கட்டளையிட்டேன்.
	<br /><br />
	7. அப்பொழுது சாஸ்திரிகளும், ஜோசியரும், கல்தேயரும், குறிசொல்லுகிறவர்களும் என்னிடத்திலே வந்தார்கள்; சொப்பனத்தை நான் அவர்களுக்குச் சொன்னேன்; ஆனாலும் அதின் அர்த்தத்தை எனக்குத் தெரிவிக்கமாட்டாமற்போனார்கள்.
	<br /><br />
	8. கடைசியிலே என் தேவனுடைய நாமத்தின்படியே பெல்தெஷாத்சார் என்னும் பெயரிடப்பட்டு, பரிசுத்த தேவர்களின் ஆவியையுடைய தானியேல் என்னிடத்தில் கொண்டுவரப்பட்டான்; அவனிடத்தில் நான் சொப்பனத்தை விவரித்துச் சொன்னதாவது:
	<br /><br />
	9. சாஸ்திரிகளின் அதிபதியாகிய பெல்தெஷாத்சாரே, பரிசுத்த தேவர்களுடைய ஆவி உனக்குள் இருக்கிறதென்றும், எந்த மறைபொருளையும் அறிவது உனக்கு அரிதல்லவென்றும் நான் அறிவேன்; நான் கண்ட என் சொப்பனத்தின் தரிசனங்களையும் அதின் அர்த்தத்தையும் சொல்லு.
	<br /><br />
	10. நான் படுத்திருந்தபோது என் தலையில் தோன்றின தரிசனங்கள் என்னவென்றால்: இதோ, தேசத்தின் மத்தியிலே மிகவும் உயரமான ஒரு விருட்சத்தைக் கண்டேன்.
	<br /><br />
	11. அந்த விருட்சம் வளர்ந்து பலத்து, தேசத்தின் எல்லைபரியந்தமும் காணப்படத்தக்கதாக அதின் உயரம் வானபரியந்தம் எட்டினது.
	<br /><br />
	12. அதின் இலைகள் நேர்த்தியும், அதின் கனி மிகுதியுமாயிருந்தது; எல்லா ஜீவனுக்கும் அதில் ஆகாரம் உண்டாயிருந்தது; அதின் கீழே வெளியின் மிருகங்கள் நிழலுக்கு ஒதுங்கினது; அதின் கொப்புகளில் ஆகாயத்துப் பட்சிகள் தாபரித்துச் சகல பிராணிகளும் அதினால் போஷிக்கப்பட்டது.
	<br /><br />
	13. நான் படுத்திருக்கையில் என் தலையில் தோன்றின தரிசனங்களைக் காணும்போது, காவலாளனாகிய பரிசுத்தவான் ஒருவன் வானத்திலிருந்து இறங்கக்கண்டேன்.
	<br /><br />
	14. அவன் உரத்த சத்தமிட்டு: இந்த விருட்சத்தை வெட்டி, இதின் கொப்புகளைத் தறித்துப்போடுங்கள்; இதின் இலைகளை உதிர்த்து, இதின் கனிகளைச் சிதறடியுங்கள்; இதின் கீழுள்ள மிருகங்களும் இதின் கொப்புகளிலுள்ள பட்சிகளும் போய்விடட்டும்.
	<br /><br />
	15. ஆனாலும் இதின் வேர்களாகிய அடிமரம் பூமியில் இருக்கட்டும்; இரும்பும் வெண்கலமுமான விலங்கு இடப்பட்டு, வெளியின் பசும்புல்லிலே தங்கி, ஆகாயத்துப் பனியிலே நனைவதாக; மிருகங்களோடே பூமியின் பூண்டிலே அவனுக்குப் பங்கு இருக்கக்கடவது.
	<br /><br />
	16. அவனுடைய இருதயம் மனுஷ இருதயமாயிராமல் மாறும்படி, மிருக இருதயம் அவனுக்குக் கொடுக்கப்படக்கடவது; இப்படியிருக்கிற அவன்மேல் ஏழு காலங்கள் கடந்துபோகவேண்டும்.
	<br /><br />
	17. உன்னதமானவர் மனுஷருடைய ராஜ்யத்தில் ஆளுகைசெய்து தமக்குச் சித்தமானவனுக்கு அதைக் கொடுத்து, மனுஷரில் தாழ்ந்தவனையும் அதின்மேல் அதிகாரியாக்குகிறார் என்று நரஜீவன்கள் அறியும்படிக்குக் காவலாளரின் தீர்ப்பினால் இந்தக் காரியமும் பரிசுத்தவான்களின் மொழியினால் இந்த விசாரணையும் தீர்மானிக்கப்பட்டது என்றான்.
	<br /><br />
	18. நேபுகாத்நேச்சார் என்னும் ராஜாவாகிய நான் கண்ட சொப்பனம் இதுவே; இப்போது பெல்தெஷாத்சாரே, நீ இதின் அர்த்தத்தைச் சொல்லு; என் ராஜ்யத்திலுள்ள ஞானிகள் எல்லாராலும் இதின் அர்த்தத்தை எனக்குத் தெரிவிக்கக்கூடாமற்போயிற்று; நீயோ இதைத் தெரிவிக்கத்தக்கவன்; பரிசுத்த தேவர்களுடைய ஆவி உனக்குள் இருக்கிறதே என்றான்.
	<br /><br />
	19. அப்பொழுது பெல்தெஷாத்சாரென்னும் பெயருள்ள தானியேல் ஒரு நாழிகைமட்டும் திகைத்துச் சிந்தித்துக் கலங்கினான். ராஜா அவனை நோக்கி: பெல்தெஷாத்சாரே, சொப்பனமும் அதின் அர்த்தமும் உன்னைக் கலங்கப்பண்ணவேண்டியதில்லை என்றான்; அப்பொழுது பெல்தெஷாத்சார் பிரதியுத்தரமாக: என் ஆண்டவனே, அந்தச் சொப்பனம் உம்முடைய பகைவரிடத்திலும், அதின் அர்த்தம் உம்முடைய சத்துருக்களிடத்திலும் பலிக்கக்கடவது.
	<br /><br />
	20. நீர் கண்ட விருட்சம் வளர்ந்து பலத்து, தேசத்தின் எல்லைபரியந்தம் காணப்படத்தக்கதாக அதின் உயரம் வானபரியந்தம் எட்டினது.
	<br /><br />
	21. அதின் இலைகள் நேர்த்தியும், அதின் கனி மிகுதியுமாயிருந்தது; எல்லா ஜீவனுக்கும் அதில் ஆகாரம் உண்டாயிருந்தது; அதின் கீழே வெளியின் மிருகங்கள் தங்கினது, அதின் கொப்புகளில் ஆகாயத்துப்பட்சிகள் தாபரித்தது.
	<br /><br />
	22. அது பெரியவரும் பலத்தவருமாயிருக்கிற ராஜாவாகிய நீர்தாமே; உமது மகத்துவம் பெருகி வானபரியந்தமும், உமது கர்த்தத்துவம் பூமியின் எல்லைபரியந்தமும் எட்டியிருக்கிறது.
	<br /><br />
	23. இந்த விருட்சத்தை வெட்டி, இதை அழித்துப்போடுங்கள்; ஆனாலும் இதின் வேர்களாகிய அடிமரம் தரையில் இருக்கட்டுமென்றும், இரும்பும் வெண்கலமுமான விலங்கு இடப்பட்டு, வெளியின் பசும்புல்லிலே தங்கி, ஆகாயத்துப் பனியிலே நனைவதாக; ஏழு காலங்கள் அவன்மேல் கடந்துபோகுமட்டும் மிருகங்களோடே அவனுடைய பங்கு இருக்கக்கடவதென்றும், வானத்திலிருந்து இறங்கிச் சொன்ன பரிசுத்த காவலாளனை ராஜாவாகிய நீர் கண்டீரே.
	<br /><br />
	24. ராஜாவே, அதின் அர்த்தமும் ராஜாவாகிய என் ஆண்டவன்பேரில் வந்த உன்னதமானவருடைய தீர்மானமும் என்னவென்றால்: மனுஷரினின்று நீர் தள்ளிவிடப்படுவீர்; வெளியின் மிருகங்களோடே சஞ்சரிப்பீர்; மாடுகளைப்போலப் புல்லைமேய்ந்து, ஆகாயத்துப் பனியிலே நனைவீர்.
	<br /><br />
	25. உன்னதமானவர் மனுஷருடைய ராஜ்யத்தில் ஆளுகைசெய்து, தமக்குச் சித்தமாயிருக்கிறவனுக்கு அதைக் கொடுக்கிறார் என்பதை நீர் அறிந்துகொள்ளுமட்டும் ஏழு காலங்கள் உம்முடையபேரில் கடந்துபோகவேண்டும்.
	<br /><br />
	26. ஆனாலும் விருட்சத்தின் வேர்களாகிய அடிமரம் தரையில் இருக்கட்டும் என்று சொல்லப்பட்டது என்னவென்றால்: நீர் பரம அதிகாரத்தை அறிந்தபின், ராஜ்யம் உமக்கு நிலைநிற்கும்.
	<br /><br />
	27. ஆகையால் ராஜாவே, நான் சொல்லும் ஆலோசனையை நீர் அங்கீகரித்துக்கொண்டு நீதியைச் செய்து உமது பாவங்களையும், சிறுமையானவர்களுக்கு இரங்கி உமது அக்கிரமங்களையும் அகற்றிவிடும்; அப்பொழுது உம்முடைய வாழ்வு நீடித்திருக்கலாம் என்றான்.
	<br /><br />
	28. இதெல்லாம் ராஜாவாகிய நேபுகாத்நேச்சாரின்மேல் வந்தது.
	<br /><br />
	29. பன்னிரண்டு மாதம் சென்றபின்பு, ராஜா பாபிலோன் ராஜ்யத்தின் அரமனைமேல் உலாவிக்கொண்டிருக்கும்போது:
	<br /><br />
	30. இது என் வல்லமையின் பராக்கிரமத்தினால், என் மகிமைப்பிரதாபத்துக்கென்று, ராஜ்யத்துக்கு அரமனையாக நான் கட்டின மகா பாபிலோன் அல்லவா என்று சொன்னான்.
	<br /><br />
	31. இந்த வார்த்தை ராஜாவின் வாயில் இருக்கும்போதே, வானத்திலிருந்து ஒரு சத்தம் உண்டாகி: ராஜாவாகிய நேபுகாத்நேச்சாரே, ராஜ்யபாரம் உன்னைவிட்டு நீங்கிற்று.
	<br /><br />
	32. மனுஷரினின்று தள்ளப்படுவாய்; வெளியின் மிருகங்களோடே சஞ்சரிப்பாய்; மாடுகளைப்போல் புல்லை மேய்வாய்; இப்படியே உன்னதமானவர் மனுஷருடைய ராஜ்யத்தில் ஆளுகைசெய்து, தமக்குச் சித்தமாயிருக்கிறவனுக்கு அதைக் கொடுக்கிறாரென்பதை நீ அறிந்துகொள்ளுமட்டும் ஏழு காலங்கள் உன்மேல் கடந்துபோகும் என்று உனக்குச் சொல்லப்படுகிறது என்று விளம்பினது.
	<br /><br />
	33. அந்நேரமே இந்த வார்த்தை நேபுகாத்நேச்சாரிடத்தில் நிறைவேறிற்று; அவன் மனுஷரினின்று தள்ளப்பட்டு, மாடுகளைப்போல் புல்லை மேய்ந்தான்; அவனுடைய தலைமயிர் கழுகுகளுடைய இறகுகளைப்போலவும், அவனுடைய நகங்கள் பட்சிகளுடைய நகங்களைப்போலவும் வளருமட்டும் அவன் சரீரம் ஆகாயத்துப் பனியிலே நனைந்தது.
	<br /><br />
	34. அந்த நாட்கள் சென்றபின்பு, நேபுகாத்நேச்சாராகிய நான் என் கண்களை வானத்துக்கு ஏறெடுத்தேன்; என் புத்தி எனக்குத் திரும்பி வந்தது; அப்பொழுது நான் உன்னதமானவரை ஸ்தோத்திரித்து, என்றென்றைக்கும் ஜீவித்திருக்கிறவரைப் புகழ்ந்து மகிமைப்படுத்தினேன்; அவருடைய கர்த்தத்துவமே நித்திய கர்த்தத்துவம், அவருடைய ராஜ்யமே தலைமுறை தலைமுறையாக நிற்கும்.
	<br /><br />
	35. பூமியின் குடிகள் எல்லாம் ஒன்றுமில்லையென்று எண்ணப்படுகிறார்கள்; அவர் தமது சித்தத்தின்படியே வானத்தின் சேனையையும் பூமியின் குடிகளையும் நடத்துகிறார்; அவருடைய கையைத் தடுத்து, அவரை நோக்கி: என்ன செய்கிறீரென்று சொல்லத்தக்கவன் ஒருவனும் இல்லை என்றேன்.
	<br /><br />
	36. அவ்வேளையில் என் புத்தி எனக்குத் திரும்பி வந்தது; என் ராஜ்யபாரத்தின் மேன்மைக்காக என் மகிமையும் என் முகக்களையும் எனக்குத் திரும்பி வந்தது; என் மந்திரிமாரும் என் பிரபுக்களும் என்னைத் தேடிவந்தார்கள்; என் ராஜ்யத்திலே நான் ஸ்திரப்படுத்தப்பட்டேன்; அதிக மகத்துவமும் எனக்குக் கிடைத்தது.
	<br /><br />
	37. ஆகையால் நேபுகாத்நேச்சாராகிய நான் பரலோகத்தின் ராஜாவைப் புகழ்ந்து, உயர்த்தி, மகிமைப்படுத்துகிறேன்; அவருடைய கிரியைகளெல்லாம் சத்தியமும், அவருடைய வழிகள் நியாயமுமானவைகள்; அகந்தையாய் நடக்கிறவர்களைத் தாழ்த்த அவராலே ஆகும் என்று எழுதினான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Daniel4