import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Hosea4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Shop | ஓசியா 4 </title>
	<meta
          name="description"
          content="Hosea 4 | ஓசியா 4 |
          Osiya Shop | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இஸ்ரவேல் புத்திரரே, கர்த்தருடைய வார்த்தையைக் கேளுங்கள்; தேசத்துக் குடிகளோடே கர்த்தருக்கு வழக்கு இருக்கிறது; அதேனென்றால் தேசத்திலே உண்மையும் இல்லை, இரக்கமும் இல்லை; தேவனைப்பற்றிய அறிவும் இல்லை.
	<br /><br />
	2. பொய்யாணையிட்டு, பொய்சொல்லி, கொலைசெய்து, திருடி, விபசாரம்பண்ணி, மிஞ்சி மிஞ்சிப்போகிறார்கள்; இரத்தப்பழிகளோடே இரத்தப்பழிகள் சேருகிறது.
	<br /><br />
	3. இதினிமித்தம் தேசம் புலம்பும்; அதில் குடியிருக்கிற அனைவரோடுங்கூட மிருகஜீவன்களும் ஆகாயத்துப் பறவைகளும் தொய்ந்துபோகும்; கடலின் மச்சங்களும் வாரிக்கொள்ளப்படும்.
	<br /><br />
	4. ஆகிலும் ஒருவனும் நியாயத்தைக் காண்பிக்கவும், ஒருவனும் அவர்களைக் கடிந்துகொள்ளவும் கூடாது; உன் ஜனங்கள் ஆசாரியனோடே வழக்காடுகிறவர்களைப்போல இருக்கிறார்கள்.
	<br /><br />
	5. ஆகையால் நீ பகலிலே இடறிவிழுவாய்; இரவிலே உன்னோடேகூடத் தீர்க்கதரிசியும் இடறிவிழுவான்; உன் தாயை நான் சங்காரம்பண்ணுவேன்.
	<br /><br />
	6. என் ஜனங்கள் அறிவில்லாமையினால் சங்காரமாகிறார்கள்; நீ அறிவை வெறுத்தாய், ஆகையால் நீ என் ஆசாரியனாயிராதபடிக்கு நானும் உன்னை வெறுத்துவிடுவேன்; நீ உன் தேவனுடைய வேதத்தை மறந்தாய், ஆகையால் நானும் உன் பிள்ளைகளை மறந்துவிடுவேன்.
	<br /><br />
	7. அவர்கள் எவ்வளவாய்ப் பெருகினார்களோ, அவ்வளவாய் எனக்கு விரோதமாய்ப் பாவஞ்செய்தார்கள்; அவர்களுடைய மகிமையை இலச்சையாக மாறப்பண்ணுவேன்.
	<br /><br />
	8. அவர்கள் என் ஜனத்தின் பாவத்தைத் தின்று, அவர்களுடைய அக்கிரமத்தின்பேரில் பசிதாகமாயிருக்கிறார்கள்.
	<br /><br />
	9. ஆதலால் ஜனங்களுக்கு எப்படியோ ஆசாரியனுக்கும் அப்படியே; அவர்கள் வழிகளின்படி நான் அவர்களை விசாரித்து, அவர்கள் கிரியைகளின்படி அவர்களுக்குப் பலனளிப்பேன்.
	<br /><br />
	10. அவர்கள் கர்த்தரை மதியாமலிருக்கிறபடியினால் அவர்கள் தின்றாலும் திருப்தியடையாதிருப்பார்கள்; அவர்கள் வேசித்தனம்பண்ணினாலும் பலுகாதிருப்பார்கள்.
	<br /><br />
	11. வேசித்தனமும் திராட்சரசமும் மதுபானமும் இருதயத்தை மயக்கும்.
	<br /><br />
	12. என் ஜனங்கள் கட்டையினிடத்தில் ஆலோசனை கேட்கிறார்கள்; அவர்களுடைய கோல் அவர்களுக்குச் செய்தியை அறிவிக்குமென்றிருக்கிறார்கள்; வேசித்தன ஆவி அவர்களை வழிதப்பித் திரியப்பண்ணிற்று; அவர்கள் தங்கள் தேவனுக்குக் கீழ்ப்பட்டிராமல் சோரமார்க்கம் போனார்கள்.
	<br /><br />
	13. அவர்கள் மலையுச்சியில் பலியிட்டு, மேடுகளிலே கர்வாலிமரங்களின் கீழும், புன்னைமரங்களின் கீழும், அரசமரங்களின் கீழும், அவைகளின் நிழல் நல்லதென்று, தூபங்காட்டுகிறார்கள்; இதினிமித்தம் உங்கள் குமாரத்திகள் வேசித்தனமும், உங்கள் மருமக்கள்மார் விபசாரமும் செய்கிறார்கள்.
	<br /><br />
	14. உங்கள் குமாரத்திகள் வேசித்தனம் செய்கிறதினிமித்தமும், உங்கள் மருமக்கள்மார் விபசாரம் செய்கிறதினிமித்தமும், நான் அவர்களைத் தண்டியாமலிருப்பேனோ? அவர்கள் விலகி வேசிகளோடே கூடப்போய்த் தாசிகளோடே பலியிடுகிறார்கள்; உணர்வில்லாத ஜனங்கள் அதினால் சிக்குண்டு விழுவார்கள்.
	<br /><br />
	15. இஸ்ரவேலே, நீ சோரம்போனாலும், யூதாவாகிலும் அந்தப் பாவத்துக்குள்ளாகாதிருப்பதாக; கில்காலுக்கு வராமலும், பெத்தாவேனுக்குப் போகாமலும் கர்த்தருடைய ஜீவனாணை என்று ஆணையிடாமலும் இருப்பீர்களாக.
	<br /><br />
	16. இஸ்ரவேல் அடங்காத கிடாரியைப்போல் அடங்காதிருக்கிறது; இப்போது கர்த்தர் அவர்களை விஸ்தாரமான வெளியிலே ஆட்டுக்குட்டியைப்போல் மேய்ந்து அலையப்பண்ணுவார்.
	<br /><br />
	17. எப்பிராயீம் விக்கிரகங்களோடு இணைந்திருக்கிறான், அவனைப் போகவிடு.
	<br /><br />
	18. அவர்களுடைய மதுபானம் புளித்தது, அவர்கள் எப்போதும் சோரம்போகிறார்கள்; அவர்களுடைய அதிபதிகள் தாருங்களென்று இலச்சையானதை நாடுகிறார்கள்.
	<br /><br />
	19. காற்று அவர்களைத் தன் செட்டைகளில் இறுகப் பிடிக்கும்; அவர்கள் தங்கள் பலிகளால் வெட்கப்படுவார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Hosea4