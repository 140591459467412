import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 3 </title>
	<meta
          name="description"
          content="Song of Solomon 3 | உன்னதப்பாட்டு 3 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இராக்காலங்களில் என் படுக்கையிலே என் ஆத்தும நேசரைத் தேடினேன்; தேடியும் நான் அவரைக் காணவில்லை.
	<br /><br />
	2. நான் எழுந்து நகரத்தின் வீதிகளிலும் தெருக்களிலும் திரிந்து, என் ஆத்தும நேசரைத் தேடுவேன் என்றேன்; தேடியும் நான் அவரைக் காணவில்லை.
	<br /><br />
	3. நகரத்திலே திரிகிற காவலாளர் என்னைக் கண்டார்கள்: என் ஆத்தும நேசரைக் கண்டீர்களா என்று அவர்களைக் கேட்டேன்.
	<br /><br />
	4. நான் அவர்களை விட்டுக் கொஞ்சதூரம் கடந்துபோனவுடனே, என் ஆத்தும நேசரைக் கண்டேன்; அவரை நான் என் தாயின் வீட்டிலும் என்னைப் பெற்றவளின் அறையிலும் கொண்டுவந்து விடுமட்டும் விடாமல் பற்றிக்கொண்டேன்.
	<br /><br />
	5. எருசலேமின் குமாரத்திகளே! எனக்குப் பிரியமானவளுக்கு மனதாகுமட்டும் நீங்கள் அவளை விழிக்கப்பண்ணாமலும் எழுப்பாமலுமிருக்கும்படி, வெளிமான்கள்மேலும் வெளியின் மரைகள்மேலும் உங்களை ஆணையிடுகிறேன்.
	<br /><br />
	6. வெள்ளைப்போளத்தினாலும் சாம்பிராணியினாலும் வர்த்தகருடைய சகலவித கந்தப்பொடியினாலும் உண்டாகிய வாசனையை வீசி, தூபஸ்தம்பங்களைப்போல் வனாந்தரத்திலிருந்து வருகிற இவர் யார்?
	<br /><br />
	7. இதோ, சாலொமோனுடைய மஞ்சம்; இஸ்ரவேலின் சவுரியவான்களில் அறுபது சவுரியவான்கள் அதைச் சுற்றி நிற்கிறார்கள்.
	<br /><br />
	8. இவர்களெல்லாரும் பட்டயம் பிடித்து, யுத்தத்துக்குப் பழகினவர்களாயிருக்கிறார்கள்; இராக்கால பயத்தினிமித்தம் அவனவனுடைய பட்டயம் அவனவன் அரையிலிருக்கிறது.
	<br /><br />
	9. சாலொமோன் ராஜா தனக்கு லீபனோனின் மரத்தினால் ஒரு இரதத்தைப் பண்ணுவித்தார்.
	<br /><br />
	10. அதின் தூண்களை வெள்ளியினாலும், அதின் தட்டைப் பொன்னினாலும், அதின் ஆசனத்தை இரத்தாம்பரத்தினாலும் பண்ணுவித்தார்; அதின் உட்புறத்திலே எருசலேமின் குமாரத்திகளினிமித்தம் நேசம் என்னும் சமுக்காளம் விரித்திருந்தது.
	<br /><br />
	11. சீயோன் குமாரத்திகளே! நீங்கள் புறப்பட்டுப்போய், ராஜாவாகிய சாலொமோனின் கலியாணநாளிலும், மனமகிழ்ச்சியின் நாளிலும், அவருடைய தாயார் அவருக்குச் சூட்டின முடியோடிருக்கிற அவரைப் பாருங்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs3