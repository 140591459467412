import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 5 </title>
	<meta
          name="description"
          content="Song of Solomon 5 | உன்னதப்பாட்டு 5 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. என் சகோதரியே! என் மணவாளியே! நான் என் தோட்டத்தில் வந்தேன், என் வெள்ளைப்போளத்தையும் என் கந்தவர்க்கங்களையும் சேர்த்தேன்; என் தேன்கூட்டை என் தேனோடு புசித்தேன்; என் திராட்சரசத்தை என் பாலோடும் குடித்தேன். சிநேகிதரே! புசியுங்கள்; பிரியமானவர்களே! குடியுங்கள், பூர்த்தியாய்க் குடியுங்கள்.
	<br /><br />
	2. நான் நித்திரைபண்ணினேன், என் இதயமோ விழித்திருந்தது; கதவைத் தட்டுகிற என் நேசரின் சத்தத்தைக் கேட்டேன்: என் சகோதரியே! என் பிரியமே! என் புறாவே! என் உத்தமியே! கதவைத் திற; என் தலை பனியினாலும், என் தலைமயிர் இரவில் பெய்யும் தூறலினாலும் நனைந்திருக்கிறது என்றார்.
	<br /><br />
	3. என் வஸ்திரத்தைக் கழற்றிப் போட்டேன்; நான் எப்படி அதைத் திரும்பவும் உடுப்பேன், என் பாதங்களைக் கழுவினேன், நான் எப்படி அவைகளைத் திரும்பவும் அழுக்காக்குவேன் என்றேன்.
	<br /><br />
	4. என் நேசர் தமது கையைக் கதவுத் துவாரத்தின் வழியாய் நீட்டினார், அப்பொழுது என் உள்ளம் அவர் நிமித்தம் பொங்கினது.
	<br /><br />
	5. என் நேசருக்குக் கதவைத்திறக்க நான் எழுந்தேன்; பூட்டின கைப்பிடிகள்மேல் என் கைகளிலிருந்து வெள்ளைப்போளமும், என் விரல்களிலிருந்து வாசனையுள்ள வெள்ளைப்போளமும் வடிந்தது.
	<br /><br />
	6. என் நேசருக்குக் கதவைத் திறந்தேன்; என் நேசரோ இல்லை, போய்விட்டார்; அவர் சொன்ன வார்த்தையால், என் ஆத்துமா சோர்ந்துபோயிற்று. அவரைத் தேடினேன், அவரைக் காணவில்லை; அவரைக் கூப்பிட்டேன், அவர் எனக்கு மறுஉத்தரவு கொடுக்கவில்லை.
	<br /><br />
	7. நகரத்தில் திரிகிற காவலாளர் என்னைக் கண்டு, என்னை அடித்து, என்னைக் காயப்படுத்தினார்கள்; அலங்கத்தின் காவற்காரர் என்மேலிருந்த என் போர்வையை எடுத்துக்கொண்டார்கள்.
	<br /><br />
	8. எருசலேமின் குமாரத்திகளே! என் நேசரைக் கண்டீர்களானால், நான் நேசத்தால் சோகமடைந்திருக்கிறேன் என்று அவருக்குச் சொல்லும்படி உங்களை ஆணையிடுகிறேன்.
	<br /><br />
	9. ஸ்திரீகளுக்குள் ரூபவதியே! மற்ற நேசரைப்பார்க்கிலும் உன் நேசர் எதினால் விசேஷித்தவர்? நீ இப்படி எங்களை ஆணையிட, மற்ற நேசரைப்பார்க்கிலும் உன் நேசர் எதினால் விசேஷித்தவர்?
	<br /><br />
	10. என் நேசர் வெண்மையும் சிவப்புமானவர்; பதினாயிரம்பேர்களில் சிறந்தவர்.
	<br /><br />
	11. அவர் தலை தங்கமயமாயிருக்கிறது; அவர் தலைமயிர் சுருள் சுருளாயும், காகத்தைப்போல் கருமையாயுமிருக்கிறது.
	<br /><br />
	12. அவருடைய கண்கள் தண்ணீர் நிறைந்த நதிகளின் ஓரமாய்த் தங்கும் புறாக்கண்களுக்கு ஒப்பானவைகளும், பாலில் கழுவப்பட்டவைகளும், நேர்த்தியாய்ப் பதிக்கப்பட்டவைகளுமாயிருக்கிறது.
	<br /><br />
	13. அவர் கன்னங்கள் கந்தவர்க்கப் பாத்திகளைப்போலவும், வாசனையுள்ள புஷ்பங்களைப்போலவுமிருக்கிறது; அவர் உதடுகள் லீலிபுஷ்பங்களைப் போன்றது, வாசனையுள்ள வெள்ளைப்போளம் அதிலிருந்து வடிகிறது.
	<br /><br />
	14. அவர் கரங்கள் படிகப்பச்சை பதித்த பொன் வளையல்களைப்போலிருக்கிறது; அவர் அங்கம் இந்திரநீல இரத்தினங்கள் இழைத்த பிரகாசமான யானைத் தந்தத்தைப்போலிருக்கிறது.
	<br /><br />
	15. அவர் கால்கள் பசும்பொன் ஆதாரங்களின்மேல் நிற்கிற வெள்ளைக்கல் தூண்களைப்போலிருக்கிறது; அவர் ரூபம் லீபனோனைப்போலவும் கேதுருக்களைப்போலவும் சிறப்பாயிருக்கிறது.
	<br /><br />
	16. அவர் வாய் மிகவும் மதுரமாயிருக்கிறது; அவர் முற்றிலும் அழகுள்ளவர். இவரே என் நேசர்; எருசலேமின் குமாரத்திகளே! இவரே என் சிநேகிதர்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs5