import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Mark15 = () => {
  const verseNumber = 15;

  
  return (
    <>
    <Helmet>
	<title>Gospel of Mark in Tamil Bible | மாற்கு 15 </title>
	<meta
          name="description"
          content="Mark 15 | மாற்கு 15 |
          Gospel of Mark | Tamil Bible Verse | Tamil Bible | மார்க்கு எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பொழுது விடிந்தவுடனே, பிரதான ஆசாரியரும் மூப்பரும் வேதபாரகரும் ஆலோசனைச் சங்கத்தாரனைவரும் கூடி ஆலோசனைபண்ணி, இயேசுவைக் கட்டிக் கொண்டுபோய், பிலாத்துவினிடத்தில் ஒப்புக்கொடுத்தார்கள்.
	<br /><br />
	2. பிலாத்து அவரை நோக்கி: நீ யூதருடைய ராஜாவா என்று கேட்டான். அதற்கு அவர்: நீர் சொல்லுகிறபடிதான் என்றார்.
	<br /><br />
	3. பிரதான ஆசாரியர்கள் அவர்மேல் அநேகங் குற்றங்களைச் சாட்டினார்கள். அவரோ மாறுத்தரம் ஒன்றும் சொல்லவில்லை.
	<br /><br />
	4. அப்பொழுது பிலாத்து மறுபடியும் அவரை நோக்கி: இதோ, இவர்கள் உன்மேல் எத்தனையோ குற்றங்களைச் சாட்டுகிறார்களே, அதற்கு நீ உத்தரவு ஒன்றும் சொல்லுகிறதில்லையா என்று கேட்டான்.
	<br /><br />
	5. இயேசுவோ அப்பொழுதும் உத்தரவு ஒன்றும் சொல்லவில்லை; அதினால் பிலாத்து ஆச்சரியப்பட்டான்.
	<br /><br />
	6. காவல்பண்ணப்பட்டவர்களில் எவனை விடுதலையாக்க வேண்டுமென்று ஜனங்கள் கேட்டுக்கொள்வார்களோ, அவனை அவர்களுக்காக விடுதலையாக்குவது பண்டிகைதோறும் பிலாத்துவுக்கு வழக்கமாயிருந்தது.
	<br /><br />
	7. கலகம்பண்ணி அந்தக் கலகத்தில் கொலைசெய்து, அதற்காகக் காவல்பண்ணப்பட்டவர்களில் பரபாஸ் என்னப்பட்ட ஒருவன் இருந்தான்.
	<br /><br />
	8. ஜனங்கள், வழக்கத்தின்படியே தங்களுக்கு ஒருவனை விடுதலையாக்கவேண்டுமென்று சத்தமிட்டுக் கேட்டுக்கொள்ளத் தொடங்கினார்கள்.
	<br /><br />
	9. பொறாமையினாலே பிரதான ஆசாரியர்கள் அவரை ஒப்புக்கொடுத்தார்களென்று பிலாத்து அறிந்து,
	<br /><br />
	10. அவர்களை நோக்கி: நான் யூதருடைய ராஜாவை உங்களுக்கு விடுதலையாக்கவேண்டுமென்றிருக்கிறீர்களா என்று கேட்டான்.
	<br /><br />
	11. பரபாசைத் தங்களுக்கு விடுதலையாக்கவேண்டுமென்று ஜனங்கள் கேட்டுக்கொள்ளும்படி, பிரதான ஆசாரியர்கள் அவர்களை ஏவிவிட்டார்கள்.
	<br /><br />
	12. பிலாத்து மறுபடியும் அவர்களை நோக்கி: அப்படியானால், யூதருடைய ராஜாவென்று நீங்கள் சொல்லுகிறவனை நான் என்ன செய்யவேண்டும் என்று கேட்டான்.
	<br /><br />
	13. அவனைச் சிலுவையில் அறையும் என்று மறுபடியும் சத்தமிட்டுச் சொன்னார்கள்.
	<br /><br />
	14. அதற்குப் பிலாத்து: ஏன், என்ன பொல்லாப்புச் செய்தான் என்றான். அவர்களோ: அவனைச் சிலுவையில் அறையும் என்று பின்னும் அதிகமாய்க் கூக்குரலிட்டுச் சொன்னார்கள்.
	<br /><br />
	15. அப்பொழுது பிலாத்து ஜனங்களைப் பிரியப்படுத்த மனதுள்ளவனாய், பரபாசை அவர்களுக்கு விடுதலையாக்கி, இயேசுவையோ வாரினால் அடிப்பித்து, சிலுவையில் அறையும்படிக்கு ஒப்புக்கொடுத்தான்.
	<br /><br />
	16. அப்பொழுது போர்ச்சேவகர் அவரைத் தேசாதிபதியின் அரமனையாகிய மாளிகையில் கொண்டுபோய், அவ்விடத்தில் போர்ச்சேவகருடைய கூட்டமுழுவதையும் கூடிவரச்செய்து,
	<br /><br />
	17. சிவப்பான மேலங்கியை அவருக்கு உடுத்தி, முள்முடியைப் பின்னி அவருக்குச் சூட்டி:
	<br /><br />
	18. யூதருடைய ராஜாவே, வாழ்க என்று அவரை வாழ்த்தி,
	<br /><br />
	19. அவரைச் சிரசில் கோலால் அடித்து, அவர்மேல் துப்பி, முழங்கால்படியிட்டு அவரை வணங்கினார்கள்.
	<br /><br />
	20. அவரைப் பரியாசம்பண்ணினபின்பு, சிவப்பான அங்கியைக் கழற்றி, அவருடைய வஸ்திரங்களை அவருக்கு உடுத்தி, அவரைச் சிலுவையில் அறையும்படி வெளியே கொண்டுபோனார்கள்.
	<br /><br />
	21. சிரேனே ஊரானும், அலெக்சந்தருக்கும் ரூப்புக்கும் தகப்பனுமாகிய சீமோன் என்னப்பட்ட ஒருவன் நாட்டிலிருந்து அவ்வழியே வருகையில், அவருடைய சிலுவையைச் சுமக்கும்படி அவனைப் பலவந்தம்பண்ணினார்கள்.
	<br /><br />
	22. கபாலஸ்தலம் என்று அர்த்தங்கொள்ளும் கொல்கொதா என்னும் இடத்துக்கு அவரைக் கொண்டுபோய்,
	<br /><br />
	23. வெள்ளைப்போளம் கலந்த திராட்சரசத்தை அவருக்குக் குடிக்கக் கொடுத்தார்கள்; அவர் அதை ஏற்றுக்கொள்ளவில்லை.
	<br /><br />
	24. அப்பொழுது அவரைச் சிலுவையில் அறைந்தார்கள். அதன்பின்பு அவருடைய வஸ்திரங்களைப் பங்கிட்டு, ஒவ்வொருவன் ஒவ்வொரு பங்கை எடுத்துக்கொள்ளும்படி அவைகளைக்குறித்துச் சீட்டுப்போட்டார்கள்.
	<br /><br />
	25. அவரைச் சிலுவையில் அறைந்தபோது மூன்றாம்மணி வேளையாயிருந்தது.
	<br /><br />
	26. அவர் அடைந்த ஆக்கினையின் முகாந்தரத்தைக் காண்பிக்கும்பொருட்டு, யூதருடைய ராஜா என்று எழுதி, சிலுவையின்மேல் கட்டினார்கள்.
	<br /><br />
	27. அல்லாமலும், அவருடைய வலதுபக்கத்தில் ஒருவனும் அவருடைய இடதுபக்கத்தில் ஒருவனுமாக, இரண்டு கள்ளரை அவரோடேகூடச் சிலுவைகளில் அறைந்தார்கள்.
	<br /><br />
	28. அக்கிரமக்காரரில் ஒருவனாக எண்ணப்பட்டார் என்கிற வேதவாக்கியம் அதனாலே நிறைவேறிற்று.
	<br /><br />
	29. அந்த வழியாய் நடந்துபோகிறவர்கள் தங்கள் தலைகளைத் துலுக்கி: ஆ! ஆ! தேவாலயத்தை இடித்து, மூன்று நாளைக்குள்ளே கட்டுகிறவனே,
	<br /><br />
	30. உன்னை நீயே இரட்சித்துக்கொள்; சிலுவையிலிருந்திறங்கிவா என்று அவரைத் தூஷித்தார்கள்.
	<br /><br />
	31. அப்படியே பிரதான ஆசாரியரும் வேதபாரகரும் தங்களுக்குள்ளே பரியாசம்பண்ணி: மற்றவர்களை இரட்சித்தான், தன்னைத்தான் இரட்சித்துக்கொள்ளத் திராணியில்லை.
	<br /><br />
	32. நாம் கண்டு விசுவாசிக்கத்தக்கதாக இஸ்ரவேலுக்கு ராஜாவாகிய கிறிஸ்து இப்பொழுது சிலுவையிலிருந்திறங்கட்டும் என்று சொல்லிக்கொண்டார்கள். அவரோடேகூடச் சிலுவைகளில் அறையப்பட்டவர்களும் அவரை நிந்தித்தார்கள்.
	<br /><br />
	33. ஆறாம்மணி நேரமுதல் ஒன்பதாம்மணி நேரம்வரைக்கும் பூமியெங்கும் அந்தகாரம் உண்டாயிற்று.
	<br /><br />
	34. ஒன்பதாம்மணி நேரத்திலே, இயேசு: எலோயீ! எலோயீ! லாமா சபக்தானி, என்று மிகுந்த சத்தமிட்டுக் கூப்பிட்டார்; அதற்கு: என் தேவனே! என் தேவனே! ஏன் என்னைக் கைவிட்டீர் என்று அர்த்தமாம்.
	<br /><br />
	35. அங்கே நின்றவர்களில் சிலர் அதைக் கேட்டபொழுது: இதோ, எலியாவைக் கூப்பிடுகிறான் என்றார்கள்.
	<br /><br />
	36. ஒருவன் ஓடி, கடற்காளானைக் காடியிலே தோய்த்து, அதை ஒரு கோலில் மாட்டி, அவருக்குக் குடிக்கக்கொடுத்து: பொறுங்கள், எலியா இவனை இறக்க வருவானோ பார்ப்போம் என்றான்.
	<br /><br />
	37. இயேசு மகா சத்தமாய்க் கூப்பிட்டு ஜீவனை விட்டார்.
	<br /><br />
	38. அப்பொழுது, தேவாலயத்தின் திரைச்சீலை மேல்தொடங்கிக் கீழ்வரைக்கும் இரண்டாகக் கிழிந்தது.
	<br /><br />
	39. அவருக்கு எதிரே நின்ற நூற்றுக்கு அதிபதி அவர் இப்படிக் கூப்பிட்டு ஜீவனை விட்டதைக் கண்டபோது: மெய்யாகவே இந்த மனுஷன் தேவனுடைய குமாரன் என்றான்.
	<br /><br />
	40. சில ஸ்திரீகளும் தூரத்திலிருந்து பார்த்துக்கொண்டிருந்தார்கள். அவர் கலிலேயாவிலிருந்தபோது அவருக்குப் பின்சென்று ஊழியஞ்செய்துவந்த மகதலேனா மரியாளும், சின்ன யாக்கோபுக்கும் யோசேக்கும் தாயாகிய மரியாளும், சலோமே என்பவளும்,
	<br /><br />
	41. அவருடனேகூட எருசலேமுக்கு வந்திருந்த வேறே அநேக ஸ்திரீகளும் அவர்களோடே இருந்தார்கள்.
	<br /><br />
	42. ஓய்வுநாளுக்கு முந்தினநாள் ஆயத்தநாளாயிருந்தபடியால், சாயங்காலமானபோது,
	<br /><br />
	43. கனம்பொருந்திய ஆலோசனைக்காரனும் அரிமத்தியா ஊரானும் தேவனுடைய ராஜ்யம் வரக் காத்திருந்தவனுமாகிய யோசேப்பு என்பவன் வந்து, பிலாத்துவினிடத்தில் துணிந்துபோய், இயேசுவின் சரீரத்தைக் கேட்டான்.
	<br /><br />
	44. அவர் இத்தனை சீக்கிரத்தில் மரித்துப்போனாரா என்று பிலாத்து ஆச்சரியப்பட்டு, நூற்றுக்கு அதிபதியை அழைப்பித்து: அவர் இதற்குள்ளே மரித்தது நிச்சயமா என்று கேட்டான்.
	<br /><br />
	45. நூற்றுக்கு அதிபதியினாலே அதை அறிந்துகொண்டபின்பு, சரீரத்தை யோசேப்பினிடத்தில் கொடுத்தான்.
	<br /><br />
	46. அவன் போய், மெல்லிய துப்பட்டியை வாங்கிக்கொண்டுவந்து, அவரை இறக்கி, அந்தத் துப்பட்டியிலே சுற்றி, கன்மலையில் வெட்டியிருந்த கல்லறையிலே அவரை வைத்து, கல்லறையின் வாசலில் ஒரு கல்லைப் புரட்டிவைத்தான்.
	<br /><br />
	47. அவரை வைத்த இடத்தை மகதலேனா மரியாளும் யோசேயின் தாயாகிய மரியாளும் பார்த்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Mark15