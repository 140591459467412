import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const James2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Faith and Deeds | James 2 in Tamil Bible Verse | யாக்கோபு - 2 </title>
	<meta
          name="description"
          content="Faith and Deeds | James Chapter - 2 in Tamil Bible Verse | யாக்கோபு அதிகாரம்-2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament | Tamil Verse"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* நம்பிக்கை மற்றும் செயல்கள் *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. என் சகோதரரே, மகிமையுள்ள நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின்மேலுள்ள விசுவாசத்தைப் பட்சபாதத்தோடே பற்றிக்கொள்ளாதிருப்பீர்களாக.
	<br /><br />
	2. ஏனெனில், பொன்மோதிரமும் மினுக்குள்ள வஸ்திரமும் தரித்திருக்கிற ஒரு மனுஷனும், கந்தையான வஸ்திரம் தரித்திருக்கிற ஒரு தரித்திரனும் உங்கள் ஆலயத்தில் வரும்போது,
	<br /><br />
	3. மினுக்குள்ள வஸ்திரந்தரித்தவனைக் கண்ணோக்கி: நீர் இந்த நல்ல இடத்தில் உட்காரும் என்றும்; தரித்திரனைப் பார்த்து: நீ அங்கே நில்லு, அல்லது இங்கே என் பாதபடியண்டையிலே உட்காரு என்றும் நீங்கள் சொன்னால்,
	<br /><br />
	4. உங்களுக்குள்ளே பேதகம்பண்ணி, தகாத சிந்தனைகளோடே நிதானிக்கிறவர்களாயிருப்பீர்களல்லவா?
	<br /><br />
	5. என் பிரியமான சகோதரரே, கேளுங்கள்; தேவன் இவ்வுலகத்தின் தரித்திரரை விசுவாசத்தில் ஐசுவரியவான்களாகவும், தம்மிடத்தில் அன்புகூருகிறவர்களுக்குத் தாம் வாக்குத்தத்தம்பண்ணின ராஜ்யத்தைச் சுதந்தரிக்கிறவர்களாகவும் தெரிந்துகொள்ளவில்லையா?
	<br /><br />
	6. நீங்களோ தரித்திரரைக் கனவீனம் பண்ணுகிறீர்கள். ஐசுவரியவான்களல்லவோ உங்களை ஒடுக்குகிறார்கள்? அவர்களல்லவோ உங்களை நியாயாசனங்களுக்கு முன்பாக இழுக்கிறார்கள்?
	<br /><br />
	7. உங்களுக்குத் தரிக்கப்பட்ட நல்ல நாமத்தை அவர்களல்லவோ தூஷிக்கிறார்கள்?
	<br /><br />
	8. உன்னிடத்தில் நீ அன்புகூருகிறதுபோலப் பிறனிடத்திலும் அன்புகூருவாயாக என்று வேதவாக்கியம் சொல்லுகிற ராஜரிக பிரமாணத்தை நீங்கள் நிறைவேற்றினால் நன்மைசெய்வீர்கள்.
	<br /><br />
	9. பட்சபாதமுள்ளவர்களாயிருப்பீர்களானால், பாவஞ்செய்து, மீறினவர்களென்று நியாயப்பிரமாணத்தால் தீர்க்கப்படுவீர்கள்.
	<br /><br />
	10. எப்படியெனில், ஒருவன் நியாயப்பிரமாணம் முழுவதையும் கைக்கொண்டிருந்தும், ஒன்றிலே தவறினால் எல்லாவற்றிலும் குற்றவாளியாயிருப்பான்.
	<br /><br />
	11. ஏனென்றால், விபசாரஞ்செய்யாதிருப்பாயாக என்று சொன்னவர் கொலைசெய்யாதிருப்பாயாக என்றும் சொன்னார்; ஆதலால், நீ விபசாரஞ்செய்யாமலிருந்தும் கொலைசெய்தாயானால் நியாயப்பிரமாணத்தை மீறினவனாவாய்.
	<br /><br />
	12. சுயாதீனப்பிரமாணத்தினால் நியாயத்தீர்ப்படையப்போகிறவர்களாய் அதற்கேற்றபடி பேசி, அதற்கேற்றபடி செய்யுங்கள்.
	<br /><br />
	13. ஏனென்றால், இரக்கஞ்செய்யாதவனுக்கு இரக்கமில்லாத நியாயத்தீர்ப்புக் கிடைக்கும்; நியாயத்தீர்ப்புக்குமுன்பாக இரக்கம் மேன்மைபாராட்டும்.
	<br /><br />
	14. என் சகோதரரே, ஒருவன் தனக்கு விசுவாசமுண்டென்று சொல்லியும், கிரியைகளில்லாதவனானால் அவனுக்குப் பிரயோஜனமென்ன? அந்த விசுவாசம் அவனை இரட்சிக்குமா?
	<br /><br />
	15. ஒரு சகோதரனாவது சகோதரியாவது வஸ்திரமில்லாமலும் அநுதின ஆகாரமில்லாமலும் இருக்கும்போது,
	<br /><br />
	16. உங்களில் ஒருவன் அவர்களை நோக்கி: நீங்கள் சமாதானத்தோடே போங்கள், குளிர்காய்ந்து பசியாறுங்கள் என்று சொல்லியும், சரீரத்திற்கு வேண்டியவைகளை அவர்களுக்குக் கொடாவிட்டால் பிரயோஜனமென்ன?
	<br /><br />
	17. அப்படியே விசுவாசமும் கிரியைகளில்லாதிருந்தால் தன்னிலேதானே செத்ததாயிருக்கும்.
	<br /><br />
	18. ஒருவன்: உனக்கு விசுவாசமுண்டு, எனக்குக் கிரியைகளுண்டு; கிரியைகளில்லாமல் உன் விசுவாசத்தை எனக்குக் காண்பி, நான் என் விசுவாசத்தை என் கிரியைகளினாலே உனக்குக் காண்பிப்பேன் என்பானே.
	<br /><br />
	19. தேவன் ஒருவர் உண்டென்று விசுவாசிக்கிறாய், அப்படிச் செய்கிறது நல்லதுதான்; பிசாசுகளும் விசுவாசித்து, நடுங்குகின்றன.
	<br /><br />
	20. வீணான மனுஷனே, கிரியைகளில்லாத விசுவாசம் செத்ததென்று நீ அறியவேண்டுமோ?
	<br /><br />
	21. நம்முடைய பிதாவாகிய ஆபிரகாம் தன் குமாரன் ஈசாக்கைப் பலிபீடத்தின்மேல் செலுத்தினபோது, கிரியைகளினாலே அல்லவோ நீதிமானாக்கப்பட்டான்?
	<br /><br />
	22. விசுவாசம் அவனுடைய கிரியைகளோடேகூட முயற்சிசெய்து, கிரியைகளினாலே விசுவாசம் பூரணப்பட்டதென்று காண்கிறாயே.
	<br /><br />
	23. அப்படியே ஆபிரகாம் தேவனை விசுவாசித்தான், அது அவனுக்கு நீதியாக எண்ணப்பட்டது என்கிற வேதவாக்கியம் நிறைவேறிற்று; அவன் தேவனுடைய சிநேகிதனென்னப்பட்டான்.
	<br /><br />
	24. ஆதலால், மனுஷன் விசுவாசத்தினாலேமாத்திரமல்ல, கிரியைகளினாலேயும் நீதிமானாக்கப்படுகிறானென்று நீங்கள் காண்கிறீர்களே.
	<br /><br />
	25. அந்தப்படி ராகாப் என்னும் வேசியும் தூதர்களை ஏற்றுக்கொண்டு வேறுவழியாய் அனுப்பிவிட்டபோது, கிரியைகளினாலே அல்லவோ நீதியுள்ளவளாக்கப்பட்டாள்?
	<br /><br />
	26. அப்படியே, ஆவியில்லாத சரீரம் செத்ததாயிருக்கிறதுபோல, கிரியைகளில்லாத விசுவாசமும் செத்ததாயிருக்கிறது.
</p>



      {/* End of verse */} 

	  <br />
<h2>
* யாக்கோபு 2 மக்களிடம் பாரபட்சமின்றி இருப்பது பற்றிய அறிவுறுத்தல்களுடன் தொடங்குகிறது. உலகத்தின் பார்வையில் ஏழையாக இருப்பவர்களை விசுவாசத்தில் ஐசுவரியவான்களாக கடவுள் தேர்ந்தெடுத்திருப்பதால், செல்வந்தர்களிடம் தயவு காட்ட வேண்டாம் என்று யாக்கோபு அறிவுறுத்துகிறார். ஒருவருடைய விசுவாசத்திலிருந்து வளரும் செயல்களான விசுவாசத்தின் செயல்களுக்கு அவர் ஒரு நல்ல வழக்கை உருவாக்குகிறார். ஆபிரகாம் மற்றும் ரபாவின் உதாரணங்களை அவர் மேற்கோள் காட்டுகிறார், அவர்கள் செய்த காரியம் அவர்களுடைய விசுவாசத்தை நிரூபித்தது என்பதைக் காட்டுகிறது. "ஆவி இல்லாத சரீரம் மரித்ததுபோல, கிரியைகளில்லாத விசுவாசமும் மரித்ததாயிருக்கிறது" (வச. 2:26) என்ற இந்த அறிக்கையுடன் அவர் அத்தியாயத்தை முடிக்கிறார் . *
			</h2>
<br />
      </div>
    </div>
    </>
  );
}

export default James2