import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes7 = () => {
  const verseNumber = 7;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-7 </title>
	<meta
          name="description"
          content="Ecclesiastes 7 | பிரசங்கி 7 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பரிமளதைலத்தைப்பார்க்கிலும் நற்கீர்த்தியும், ஒருவனுடைய ஜநநநாளைப்பார்க்கிலும் மரணநாளும் நல்லது.
	<br /><br />
	2. விருந்துவீட்டுக்குப் போவதிலும் துக்கவீட்டுக்குப் போவது நலம்; இதிலே எல்லா மனுஷரின் முடிவும் காணப்படும்; உயிரோடிருக்கிறவன் இதைத் தன் மனதிலே சிந்திப்பான்.
	<br /><br />
	3. நகைப்பைப் பார்க்கிலும் துக்கிப்பு நலம்; முகதுக்கத்தினாலே இருதயம் சீர்ப்படும்.
	<br /><br />
	4. ஞானிகளின் இருதயம் துக்கவீட்டிலே இருக்கும்; மூடரின் இருதயம் களிப்புவீட்டிலே இருக்கும்.
	<br /><br />
	5. ஒருவன் மூடரின் பாட்டைக் கேட்பதிலும், ஞானியின் கடிந்துகொள்ளுதலைக் கேட்பது நலம்.
	<br /><br />
	6. மூடனின் நகைப்பு பானையின்கீழ் எரிகிற முள்ளுகளின் படபடப்பைப்போலிருக்கும்; இதுவும் மாயையே.
	<br /><br />
	7. இடுக்கணானது ஞானியையும் பைத்தியக்காரனாக்கும்; பரிதானம் இருதயத்தைக் கெடுக்கும்.
	<br /><br />
	8. ஒரு காரியத்தின் துவக்கத்தைப்பார்க்கிலும் அதின் முடிவு நல்லது; பெருமையுள்ளவனைப்பார்க்கிலும் பொறுமையுள்ளவன் உத்தமன்.
	<br /><br />
	9. உன் மனதில் சீக்கிரமாய்க் கோபங்கொள்ளாதே; மூடரின் நெஞ்சிலே கோபம் குடிகொள்ளும்.
	<br /><br />
	10. இந்நாட்களைப்பார்க்கிலும் முன்நாட்கள் நலமாயிருந்தது என்று சொல்லாதே; நீ இதைக்குறித்துக் கேட்பது ஞானமல்ல.
	<br /><br />
	11. சுதந்தரத்தோடே ஞானம் நல்லது; சூரியனைக் காண்கிறவர்களுக்கு இதினாலே பிரயோஜனமுண்டு.
	<br /><br />
	12. ஞானம் கேடகம், திரவியமும் கேடகம்; ஞானம் தன்னை உடையவர்களுக்கு ஜீவனைத் தரும்; இதுவே அறிவின் மேன்மை.
	<br /><br />
	13. தேவனுடைய செயலைக் கவனித்துப்பார்; அவர் கோணலாக்கினதை நேர்மையாக்கத்தக்கவன் யார்?
	<br /><br />
	14. வாழ்வுகாலத்தில் நன்மையை அநுபவித்திரு, தாழ்வுகாலத்தில் சிந்தனைசெய்; மனுஷன் தனக்குப்பின் வருவதொன்றையும் கண்டுபிடியாதபடிக்குத் தேவன் இவ்விரண்டையும் ஒன்றுக்கொன்று எதிரிடையாக வைத்திருக்கிறார்.
	<br /><br />
	15. இவை எல்லாவற்றையும் என் மாயையின் நாட்களில் கண்டேன்; தன் நீதியிலே கெட்டுப்போகிற நீதிமானுமுண்டு, தன் பாவத்திலே நீடித்திருக்கிற பாவியுமுண்டு.
	<br /><br />
	16. மிஞ்சின நீதிமானாயிராதே, உன்னை அதிக ஞானியுமாக்காதே; உன்னை நீ ஏன் கெடுத்துக்கொள்ளவேண்டும்?
	<br /><br />
	17. மிஞ்சின துஷ்டனாயிராதே, அதிக பேதையுமாயிராதே; உன் காலத்துக்குமுன்னே நீ ஏன் சாகவேண்டும்?
	<br /><br />
	18. நீ இதைப் பற்றிக்கொள்வதும் அதைக் கைவிடாதிருப்பதும் நலம்; தேவனுக்குப் பயப்படுகிறவன் இவைகள் எல்லாவற்றினின்றும் காக்கப்படுவான்.
	<br /><br />
	19. நகரத்திலுள்ள பத்து அதிபதிகளைப்பார்க்கிலும், ஞானம் ஞானியை அதிக பெலவானாக்கும்.
	<br /><br />
	20. ஒரு பாவமும் செய்யாமல், நன்மையே செய்யத்தக்க நீதிமான் பூமியிலில்லை.
	<br /><br />
	21. சொல்லப்படும் எல்லா வார்த்தைகளையும் கவனியாதே; கவனித்தால் உன் வேலைக்காரன் உன்னை நிந்திப்பதைக் கேள்விப்படவேண்டியதாகும்.
	<br /><br />
	22. அநேகந்தரம் நீயும் பிறரை நிந்தித்தாயென்று, உன் மனதுக்குத் தெரியுமே.
	<br /><br />
	23. இவை எல்லாவற்றையும் ஞானத்தினால் சோதித்துப்பார்த்தேன்: நான் ஞானவானாவேன் என்றேன், அது எனக்குத் தூரமாயிற்று.
	<br /><br />
	24. தூரமும் மகா ஆழமுமாயிருக்கிறதைக் கண்டடைகிறவன் யார்?
	<br /><br />
	25. ஞானத்தையும், காரணகாரியத்தையும் விசாரித்து ஆராய்ந்து அறியவும், மதிகேட்டின் ஆகாமியத்தையும் புத்திமயக்கத்தின் பைத்தியத்தையும் அறியவும் என் மனதைச் செலுத்தினேன்.
	<br /><br />
	26. கண்ணிகளும் வலைகளுமாகிய நெஞ்சமும், கயிறுகளுமாகிய கைகளுமுடைய ஸ்திரீயானவள், சாவிலும் அதிக கசப்புள்ளவளென்று கண்டேன்; தேவனுக்கு முன்பாகச் சற்குணனாயிருக்கிறவன் அவளுக்குத் தப்புவான்; பாவியோ அவளால் பிடிபடுவான்.
	<br /><br />
	27. காரியத்தை அறியும்படிக்கு ஒவ்வொன்றாய் விசாரணைபண்ணி, இதோ, இதைக் கண்டுபிடித்தேன் என்று பிரசங்கி சொல்லுகிறான்:
	<br /><br />
	28. என் மனம் இன்னும் ஒன்றைத் தேடுகிறது, அதை நான் கண்டுபிடிக்கவில்லை; ஆயிரம்பேருக்குள்ளே ஒரு புருஷனைக் கண்டேன்; இவர்களெல்லாருக்குள்ளும் ஒரு ஸ்திரீயை நான் காணவில்லை.
	<br /><br />
	29. இதோ, தேவன் மனுஷனைச் செம்மையானவனாக உண்டாக்கினார்; அவர்களோ அநேக உபாயதந்திரங்களைத் தேடிக்கொண்டார்கள்; இதைமாத்திரம் கண்டேன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes7