import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 6 </title>
	<meta
          name="description"
          content="Jeremiah 6 | எரேமியா 6 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பென்யமீன் புத்திரரே, நீங்கள் எருசலேமின் நடுவிலிருந்து ஏகமாய்க் கூடியோடி, தெக்கோவாவில் எக்காளம் ஊதி, பெத்கேரேமின்மேல் அடையாளமாகத் தீவெளிச்சங் காட்டுங்கள்; ஒரு தீங்கும் மகா சங்காரமும் வடக்கேயிருந்து தோன்றுகிறதாயிருக்கிறது.
	<br /><br />
	2. செல்வமாய் வளர்ந்த ரூபவதியான சீயோன் குமாரத்தியைப் பாழாக்குவேன்.
	<br /><br />
	3. மேய்ப்பர் தங்கள் மந்தைகளோடே அவளிடத்துக்கு வருவார்கள்; அவர்கள் அவளுக்கு விரோதமாய்ச் சுற்றிலும் கூடாரம்போட்டு, அவனவன் தன் தன் ஸ்தலத்தில் மேய்த்து,
	<br /><br />
	4. அவளுக்கு விரோதமாய் யுத்தஞ்செய்ய ஆயத்தம்பண்ணுங்கள் என்றும், மத்தியானத்தில்தானே நாம் போயேறும்படிக்கு எழுந்திருங்கள்; ஐயோ! பொழுது சாய்ந்து, அந்தி நிழல்கள் நீண்டுபோகிறதே;
	<br /><br />
	5. எழுந்திருங்கள், நாம் இராக்காலத்திலாகிலும் போயேறி, அவளுடைய அரமனைகளை அழிப்போம் என்றும் சொல்லுவார்கள்.
	<br /><br />
	6. சேனைகளுடைய கர்த்தர் சொல்லுகிறது என்னவென்றால், மரங்களை வெட்டி, எருசலேமுக்கு விரோதமாய்க் கொத்தளம் போடுங்கள்; அதுவே விசாரிக்கப்படவேண்டிய நகரம்; அதின் உட்புறமெல்லாம் கொடுமை.
	<br /><br />
	7. ஊற்றானது, தன் தண்ணீரைச் சுரக்கப்பண்ணுமாப்போல, அது தன் தீங்கைச் சுரக்கப்பண்ணுகிறது; அதிலே கொடுமையும் அழிம்பும் கேட்கப்படுகிறது; துக்கமும் காயங்களும் நித்தமும் எனக்கு முன்பாகக் காணப்படுகிறது.
	<br /><br />
	8. எருசலேமே, என் ஆத்துமா உன்னைவிட்டுப் பிரியாதபடிக்கும், நான் உன்னைப் பாழும் குடியற்ற தேசமும் ஆக்காதபடிக்கும் புத்திகேள்.
	<br /><br />
	9. திராட்சக்குலைகளை அறுக்கிறவனைப்போல உன் கையைத் திரும்பக் கூடைகளின்மேல் போடென்று சொல்லி, அவர்கள் இஸ்ரவேலில் மீதியாயிருந்த கனியைத் திராட்சச்செடியின் கனியைப்போல் நன்றாய்ப் பொறுக்கிக்கொண்டு போவார்கள் என்று சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. அவர்கள் கேட்கும்படி நான் யாரோடே பேசி எச்சரிப்பேன்? அவர்களுடைய செவி விருத்தசேதனமில்லாதது; அவர்கள் கேட்கமாட்டார்கள்; கர்த்தருடைய வசனம் அவர்களுக்கு நிந்தையாயிருக்கிறது; அதின்மேல் அவர்களுக்கு விருப்பமில்லை.
	<br /><br />
	11. ஆகையால் நான் கர்த்தருடைய உக்கிரத்தால் நிறைந்திருக்கிறேன்; அதை அடக்கி இளைத்துப்போனேன்; வீதிகளிலுள்ள பிள்ளைகளின்மேலும், வாலிபருடைய கூட்டத்தின்மேலும் ஏகமாய் அதை ஊற்றிவிடுவேன்; புருஷரும், ஸ்திரீகளும், கிழவரும், பூரண வயதுள்ளவர்களுங்கூடப் பிடிக்கப்படுவார்கள்.
	<br /><br />
	12. அவர்களுடைய வீடுகளும், அவர்களுடைய காணிபூமிகளும், அவர்களுடைய மனைவிகளோடே ஏகமாய் அந்நியர் வசமாகும்; என் கையை இந்தத் தேசத்தின் குடிகளுக்கு விரோதமாக நீட்டுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. அவர்களில், சிறியோர்முதல் பெரியோர்மட்டும், ஒவ்வொருவரும் பொருளாசைக்காரர்; இதுவுமல்லாமல் தீர்க்கதரிசிகள்முதல் ஆசாரியர்கள்மட்டும் ஒவ்வொருவரும் பொய்யர்.
	<br /><br />
	14. சமாதானமில்லாதிருந்தும்: சமாதானம் சமாதானம் என்று சொல்லி, என் ஜனத்தின் காயங்களை மேற்பூச்சாய்க் குணமாக்குகிறார்கள்.
	<br /><br />
	15. அவர்கள் அருவருப்பானதைச் செய்ததினிமித்தம் வெட்கப்படுகிறார்களோ? பரிச்சேதம் வெட்கப்படார்கள், நாணவும் அறியார்கள்; ஆதலால் விழுகிறவர்களுக்குள்ளே விழுவார்கள்; நான் அவர்களை விசாரிக்குங் காலத்தில் இடறுண்டுபோவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	16. வழிகளிலே நின்று, பூர்வ பாதைகள் எவையென்று கேட்டு விசாரித்து, நல்ல வழி எங்கே என்று பார்த்து, அதிலே நடவுங்கள்; அப்பொழுது உங்கள் ஆத்துமாவுக்கு இளைப்பாறுதல் கிடைக்கும் என்று கர்த்தர் சொல்லுகிறார்; அவர்களோ: நாங்கள் அதிலே நடக்கமாட்டோம் என்கிறார்கள்.
	<br /><br />
	17. நான் உங்கள்மேல் காவலாளரையும் வைத்து, எக்காள சத்தத்துக்குச் செவிகொடுங்கள் என்றும் சொன்னேன்; அவர்களோ: நாங்கள் கேட்கமாட்டோம் என்கிறார்கள்.
	<br /><br />
	18. ஆகையால் ஜாதிகளே, கேளுங்கள்; சபையே, அவர்களுக்குள் நடக்கிறதை அறிந்துகொள்.
	<br /><br />
	19. பூமியே, கேள்; இந்த ஜனங்கள் என் வார்த்தைகளைக் கேளாமலிருந்து, என் நியாயப்பிரமாணத்துக்குச் செவிகொடாமல் அதை வெறுத்துவிடுகிறார்கள்; அவர்கள்மேல் நான் அவர்கள் நினைவுகளின் பலனாகிய தீங்கை வரப்பண்ணுவேன்.
	<br /><br />
	20. சேபாவிலிருந்து வருகிற தூபவர்க்கமும், தூரதேசத்தினுடைய சுகந்தப்பட்டையும் எனக்கு என்னத்துக்கு? உங்கள் சர்வாங்கதகனங்கள் எனக்கு விருப்பமல்ல; உங்கள் பலிகள் எனக்கு இன்பமாயிராது.
	<br /><br />
	21. ஆகையால் இதோ, நான் இந்த ஜனத்துக்கு இடறல்களை வைப்பேன்; அவைகள்மேல் பிதாக்களும், பிள்ளைகளும், குடியானவனும், அவனுக்கடுத்தவனும், ஏகமாய் இடறுண்டு அழிவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	22. இதோ, வடதேசத்திலிருந்து ஒரு ஜனம் வந்து, பூமியின் கடையெல்லைகளிலிருந்து ஒரு பெரிய ஜாதி எழும்பும்.
	<br /><br />
	23. அவர்கள் வில்லும் வேலும் பிடித்து வருவார்கள்; அவர்கள் கொடியர், இரக்கம் அறியாதவர்கள்; அவர்கள் சத்தம் சமுத்திர இரைச்சலுக்குச் சமானமாயிருக்கும்; சீயோன் குமாரத்தியே, அவர்கள் உனக்கு விரோதமாக யுத்தசன்னத்தராய்க் குதிரைகளின்மேலேறி அணியணியாக வருவார்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	24. அவர்கள் வருகிற செய்தியைக் கேட்டோம்; நம்முடைய கைகள் தளர்ந்தது; இடுக்கணும், கர்ப்பவதிக்கு உண்டாகும் வேதனைக்கொப்பான வேதனையும் நம்மைப் பிடித்தது.
	<br /><br />
	25. வயல்வெளியிலே புறப்படாதிருங்கள்; வழியிலும் நடவாதிருங்கள்; சுற்றிலும் சத்துருவின் பட்டயமும் பயங்கரமுமுண்டு.
	<br /><br />
	26. என் ஜனமாகிய குமாரத்தியே, நீ இரட்டைக் கட்டிக்கொண்டு, சாம்பலிலே புரண்டு, ஒரே புத்திரனுக்காகத் துக்கிக்கிறதுபோல மனங்கசந்து புலம்பு; பாழாக்குகிறவன் சடிதியாய் நம்மேல் வருவான்.
	<br /><br />
	27. நீ என் ஜனத்தின் வழியை அறிந்துகொள்ளவும் சோதித்துப்பார்க்கவும் நான் உன்னை அவர்களுக்குள்ளே துருகமாகவும், அரணாகவும் வைத்தேன்.
	<br /><br />
	28. அவர்களெல்லாரும் முரட்டாட்டமான அகங்காரிகளும், தூற்றித்திரிகிறவர்களுமாயிருக்கிறார்கள்; அவர்கள் வெண்கலமும் இரும்புமானவர்கள்; அவர்களெல்லாரும் கெட்டவர்கள்.
	<br /><br />
	29. துருத்தி வெந்தது; ஈயம் நெருப்பினால் அழிந்தது; புடமிடுகிறவனுடைய பிரயாசம் விருதாவாய்ப்போயிற்று; பொல்லாப்புகள் அற்றுப்போகவில்லை.
	<br /><br />
	30. அவர்கள் தள்ளுபடியான வெள்ளி என்னப்படுவார்கள்; கர்த்தர் அவர்களைத் தள்ளிவிட்டார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah6