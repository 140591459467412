import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneThessalonians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>THE FIRST LETTER TO THESSALONIANS 1 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE FIRST LETTER TO THE THESSALONIANS Chapter 1 | தெசலோனிக்கேயருக்கு எழுதின நிருபம் அதிகாரம் 1 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

	  <p>
	1. பவுலும், சில்வானும், தீமோத்தேயும், பிதாவாகிய தேவனுக்குள்ளும் கர்த்தராகிய இயேசுகிறிஸ்துவுக்குள்ளும் இருக்கிற தெசலோனிக்கேயர் சபைக்கு எழுதுகிறதாவது: நம்முடைய பிதாவாகிய தேவனாலும் கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	2. தேவனுக்குப் பிரியமான சகோதரரே, உங்கள் விசுவாசத்தின் கிரியையையும், உங்கள் அன்பின் பிரயாசத்தையும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின்மேலுள்ள உங்கள் நம்பிக்கையின் பொறுமையையும், நம்முடைய பிதாவாகிய தேவனுக்குமுன்பாக நாங்கள் இடைவிடாமல் நினைவுகூர்ந்து,
	<br /><br />
	3. நீங்கள் தெரிந்துகொள்ளப்பட்டவர்களென்று நாங்கள் அறிந்து,
	<br /><br />
	4. எங்கள் ஜெபங்களில் இடைவிடாமல் உங்களைக்குறித்து விண்ணப்பம்பண்ணி, உங்களெல்லாருக்காகவும் எப்பொழுதும் தேவனை ஸ்தோத்திரிக்கிறோம்.
	<br /><br />
	5. எங்கள் சுவிசேஷம் உங்களிடத்தில் வசனத்தோடேமாத்திரமல்ல, வல்லமையோடும், பரிசுத்த ஆவியோடும், முழு நிச்சயத்தோடும் வந்தது; நாங்களும் உங்களுக்குள்ளே இருந்தபோது உங்கள்நிமித்தம் எப்படிப்பட்டவர்களாயிருந்தோமென்று அறிந்திருக்கிறீர்களே.
	<br /><br />
	6. நீங்கள் மிகுந்த உபத்திரவத்திலே, பரிசுத்த ஆவியின் சந்தோஷத்தோடே, திருவசனத்தை ஏற்றுக்கொண்டு, எங்களையும் கர்த்தரையும் பின்பற்றுகிறவர்களாகி,
	<br /><br />
	7. இவ்விதமாய் மக்கெதோனியாவிலும் அகாயாவிலுமுள்ள விசுவாசிகள் யாவருக்கும் மாதிரிகளானீர்கள்.
	<br /><br />
	8. எப்படியெனில், உங்களிடத்திலிருந்து கர்த்தருடைய வசனம் மக்கெதோனியாவிலும் அகாயாவிலும் தொனித்ததுமல்லாமல், நாங்கள் அதைக்குறித்து ஒன்றும் சொல்லவேண்டியதாயிராதபடிக்கு, தேவனைப்பற்றின உங்கள் விசுவாசம் எங்கும் பிரசித்தமாயிற்று.
	<br /><br />
	9. ஏனெனில், அவர்கள்தாமே எங்களைக்குறித்து, உங்களிடத்தில் நாங்கள் அடைந்த பிரவேசம் இன்னதென்பதையும், ஜீவனுள்ள மெய்யான தேவனுக்கு ஊழியஞ்செய்வதற்கு, நீங்கள் விக்கிரகங்களைவிட்டுத் தேவனிடத்திற்கு மனந்திரும்பினதையும்,
	<br /><br />
	10. அவர் மரித்தோரிலிருந்தெழுப்பினவரும், இனிவரும் கோபாக்கினையினின்று நம்மை நீங்கலாக்கி இரட்சிக்கிறவருமாயிருக்கிற அவருடைய குமாரனாகிய இயேசு பரலோகத்திலிருந்து வருவதை நீங்கள் எதிர்பார்த்துக்கொண்டிருக்கிறதையும், அறிவிக்கிறார்களே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default OneThessalonians1