import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke19 = () => {
  const verseNumber = 19;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 19 </title>
	<meta
          name="description"
          content="Luke | லூக்கா | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அவர் எரிகோவில் பிரவேசித்து, அதின் வழியாக நடந்துபோகையில்,
	<br /><br />
	2. ஆயக்காரருக்குத் தலைவனும் ஐசுவரியவானுமாயிருந்த சகேயு என்னப்பட்ட ஒரு மனுஷன்,
	<br /><br />
	3. இயேசு எப்படிப்பட்டவரோ என்று அவரைப் பார்க்க வகைதேடினான். அவன் குள்ளனானபடியால், ஜனக்கூட்டத்தில் அவரைக் காணக்கூடாமல்,
	<br /><br />
	4. அவர் போகும் வழியில் முன்னாக ஓடி, அவரைப் பார்க்கும்படி ஒரு காட்டத்தி மரத்தில் ஏறினான்.
	<br /><br />
	5. இயேசு அந்த இடத்தில் வந்தபோது, அண்ணாந்து பார்த்து, அவனைக் கண்டு: சகேயுவே, நீ சீக்கிரமாய் இறங்கிவா, இன்றைக்கு நான் உன் வீட்டிலே தங்கவேண்டும் என்றார்.
	<br /><br />
	6. அவன் சீக்கிரமாய் இறங்கி, சந்தோஷத்தோடே அவரை அழைத்துக்கொண்டுபோனான்.
	<br /><br />
	7. அதைக் கண்ட யாவரும்: இவர் பாவியான மனுஷனிடத்தில் தங்கும்படி போனார் என்று முறுமுறுத்தார்கள்.
	<br /><br />
	8. சகேயு நின்று, கர்த்தரை நோக்கி: ஆண்டவரே, என் ஆஸ்திகளில் பாதியை ஏழைகளுக்குக் கொடுக்கிறேன், நான் ஒருவனிடத்தில் எதையாகிலும் அநியாயமாய் வாங்கினதுண்டானால், நாலத்தனையாகத் திரும்பச் செலுத்துகிறேன் என்றான்.
	<br /><br />
	9. இயேசு அவனை நோக்கி: இன்றைக்கு இந்த வீட்டுக்கு இரட்சிப்பு வந்தது; இவனும் ஆபிரகாமுக்குக் குமாரனாயிருக்கிறானே.
	<br /><br />
	10. இழந்துபோனதைத் தேடவும் இரட்சிக்கவுமே மனுஷகுமாரன் வந்திருக்கிறார் என்றார்.
	<br /><br />
	11. அவர்கள் இவைகளைக் கேட்டுக் கொண்டிருக்கையில், அவர் எருசலேமுக்குச் சமீபித்திருந்தபடியினாலும், தேவனுடைய ராஜ்யம் சீக்கிரமாய் வெளிப்படுமென்று அவர்கள் நினைத்தபடியினாலும், அவர் ஒரு உவமையைச் சொன்னார்:
	<br /><br />
	12. பிரபுவாகிய ஒருவன் ஒரு ராஜ்யத்தைப் பெற்றுக்கொண்டு திரும்பிவரும்படி தூரதேசத்துக்குப் போகப் புறப்பட்டான்.
	<br /><br />
	13. புறப்படும்போது, அவன் தன் ஊழியக்காரரில் பத்துப்பேரை அழைத்து, அவர்களிடத்தில் பத்துராத்தல் திரவியங்கொடுத்து: நான் திரும்பிவருமளவும் இதைக்கொண்டு வியாபாரம்பண்ணுங்கள் என்று சொன்னான்.
	<br /><br />
	14. அவனுடைய ஊரார் அவனைப் பகைத்து, இவன் எங்கள்மேல் ராஜாவாயிருக்கிறது எங்களுக்கு மனதில்லையென்று சொல்லும்படி அவன் பின்னே ஸ்தானாபதிகளை அனுப்பினார்கள்.
	<br /><br />
	15. அவன் ராஜ்யத்தைப் பெற்றுக்கொண்டு திரும்பிவந்தபோது, தன்னிடத்தில் திரவியம் வாங்கியிருந்த அந்த ஊழியக்காரரில் அவனவன் வியாபாரம்பண்ணிச் சம்பாதித்தது இவ்வளவென்று அறியும்படி, அவர்களைத் தன்னிடத்தில் அழைத்துவரச் சொன்னான்.
	<br /><br />
	16. அப்பொழுது முந்தினவன் வந்து: ஆண்டவனே, உம்முடைய ராத்தலினால் பத்துராத்தல் ஆதாயம் கிடைத்தது என்றான்.
	<br /><br />
	17. எஜமான் அவனை நோக்கி: நல்லது உத்தம ஊழியக்காரனே, நீ கொஞ்சத்தில் உண்மையுள்ளவனாயிருந்தபடியால் பத்துப் பட்டணங்களுக்கு அதிகாரியாயிரு என்றான்.
	<br /><br />
	18. அப்படியே இரண்டாம் ஊழியக்காரன் வந்து: ஆண்டவனே, உம்முடைய ராத்தலினால் ஐந்துராத்தல் ஆதாயம் கிடைத்தது என்றான்.
	<br /><br />
	19. அவனையும் அவன் நோக்கி: நீயும் ஐந்து பட்டணங்களுக்கு அதிகாரியாயிரு என்றான்.
	<br /><br />
	20. பின்பு வேறொருவன் வந்து: ஆண்டவனே, இதோ, உம்முடைய ராத்தல், இதை ஒரு சீலையிலே வைத்திருந்தேன்.
	<br /><br />
	21. நீர் வைக்காததை எடுக்கிறவரும், விதைக்காததை அறுக்கிறவருமான கடினமுள்ள மனுஷனென்று அறிந்து, உமக்குப் பயந்திருந்தேன் என்றான்.
	<br /><br />
	22. அதற்கு அவன்: பொல்லாத ஊழியக்காரனே, உன் வாய்ச்சொல்லைக்கொண்டே உன்னை நியாயந்தீர்க்கிறேன். நான் வைக்காததை எடுக்கிறவனும், விதைக்காததை அறுக்கிறவனுமான கடினமுள்ள மனுஷனென்று அறிந்தாயே,
	<br /><br />
	23. பின்னை ஏன் நீ என் திரவியத்தைக் காசுக்கடையிலே வைக்கவில்லை; வைத்திருந்தால் நான் வரும்போது, அதை வட்டியோடே வரப்பற்றிக் கொள்வேனே என்று சொல்லி;
	<br /><br />
	24. சமீபமாய் நிற்கிறவர்களை நோக்கி: அந்த ராத்தலை அவன் கையிலிருந்தெடுத்து, பத்துராத்தல் உள்ளவனுக்குக் கொடுங்கள் என்றான்.
	<br /><br />
	25. அதற்கு அவர்கள்: ஆண்டவனே, அவனுக்குப் பத்துராத்தல் இருக்கிறதே என்றார்கள்.
	<br /><br />
	26. அதற்கு அவன்: உள்ளவன் எவனுக்குங் கொடுக்கப்படும், இல்லாதவனிடத்தில் உள்ளதும் எடுத்துக்கொள்ளப்படும் என்று உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	27. அன்றியும் தங்கள்மேல் நான் ராஜாவாகிறதற்கு மனதில்லாதிருந்தவர்களாகிய என்னுடைய சத்துருக்களை இங்கே கொண்டுவந்து, எனக்கு முன்பாக வெட்டிப்போடுங்கள் என்று சொன்னான் என்றார்.
	<br /><br />
	28. இவைகளை அவர் சொன்னபின்பு எருசலேமுக்குப் புறப்பட்டு, முந்திநடந்துபோனார்.
	<br /><br />
	29. அவர் ஒலிவமலையென்னப்பட்ட மலையின் அருகான பெத்பகே பெத்தானியா என்னும் ஊர்களுக்குச் சமீபித்தபோது, தம்முடைய சீஷரில் இரண்டுபேரை நோக்கி:
	<br /><br />
	30. உங்களுக்கு எதிரே இருக்கிற கிராமத்துக்குப் போங்கள், அதிலே பிரவேசிக்கும்போது மனுஷரிலொருவனும் ஒருக்காலும் ஏறியிராத கழுதைக்குட்டியைக் கட்டியிருக்கக் காண்பீர்கள்; அதை அவிழ்த்துக்கொண்டு வாருங்கள்.
	<br /><br />
	31. அதை ஏன் அவிழ்க்கிறீர்களென்று யாராவது உங்களிடத்தில் கேட்டால், அது ஆண்டவருக்கு வேண்டுமென்று சொல்லுங்கள் என்றார்.
	<br /><br />
	32. அனுப்பப்பட்டவர்கள் போய், தங்களுக்கு அவர் சொன்னபடியே கண்டார்கள்.
	<br /><br />
	33. கழுதைக்குட்டியை அவர்கள் அவிழ்க்கும்போது, அதற்கு உடையவர்கள்: குட்டியை ஏன் அவிழ்க்கிறீர்கள் என்று கேட்டார்கள்.
	<br /><br />
	34. அதற்கு அவர்கள்: அது ஆண்டவருக்கு வேண்டுமென்று சொல்லி,
	<br /><br />
	35. அதை இயேசுவினிடத்தில் கொண்டுவந்து, தங்கள் வஸ்திரங்களை அதின்மேல் போட்டு, இயேசுவை அதின்மேல் ஏற்றினார்கள்.
	<br /><br />
	36. அவர் போகையில், அவர்கள் தங்கள் வஸ்திரங்களை வழியிலே விரித்தார்கள்.
	<br /><br />
	37. அவர் ஒலிவமலையின் அடிவாரத்துக்குச் சமீபமாய் வருகையில் திரளான கூட்டமாகிய சீஷரெல்லாரும் தாங்கள் கண்ட சகல அற்புதங்களையுங்குறித்துச் சந்தோஷப்பட்டு,
	<br /><br />
	38. கர்த்தருடைய நாமத்தினாலே வருகிற ராஜா ஸ்தோத்திரிக்கப்பட்டவர், பரலோகத்திலே சமாதானமும் உன்னதத்திலே மகிமையும் உண்டாவதாக என்று மிகுந்த சத்தத்தோடே தேவனைப் புகழ்ந்தார்கள்.
	<br /><br />
	39. அப்பொழுது கூட்டத்திலிருந்த பரிசேயரில் சிலர் அவரை நோக்கி: போதகரே, உம்முடைய சீஷரை அதட்டும் என்றார்கள்.
	<br /><br />
	40. அவர்களுக்கு அவர் பிரதியுத்தரமாக: இவர்கள் பேசாமலிருந்தால் கல்லுகளே கூப்பிடும் என்று உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	41. அவர் சமீபமாய் வந்தபோது நகரத்தைப்பார்த்து, அதற்காகக் கண்ணீர் விட்டழுது,
	<br /><br />
	42. உனக்குக் கிடைத்த இந்த நாளிலாகிலும் உன் சமாதானத்துக்கு ஏற்றவைகளை நீ அறிந்திருந்தாயானால் நலமாயிருக்கும், இப்பொழுதோ அவைகள் உன் கண்களுக்கு மறைவாயிருக்கிறது.
	<br /><br />
	43. உன்னைச் சந்திக்குங்காலத்தை நீ அறியாமற்போனபடியால், உன் சத்துருக்கள் உன்னைச் சூழ மதில்போட்டு, உன்னை வளைந்துகொண்டு, எப்பக்கத்திலும் உன்னை நெருக்கி,
	<br /><br />
	44. உன்னையும் உன்னிலுள்ள உன் பிள்ளைகளையும் தரையாக்கிப்போட்டு, உன்னிடத்தில் ஒரு கல்லின்மேல் ஒரு கல்லிராதபடிக்குச் செய்யும் நாட்கள் உனக்கு வரும் என்றார்.
	<br /><br />
	45. பின்பு அவர் தேவாலயத்தில் பிரவேசித்து, அதிலே விற்கிறவர்களையும் கொள்ளுகிறவர்களையும் புறம்பே துரத்தத்தொடங்கி:
	<br /><br />
	46. என்னுடைய வீடு ஜெபவீடாயிருக்கிறதென்று எழுதியிருக்கிறது, நீங்களோ அதைக் கள்ளர்குகையாக்கினீர்கள் என்றார்.
	<br /><br />
	47. அவர் நாடோறும் தேவாலயத்தில் உபதேசம் பண்ணிக்கொண்டிருந்தார். பிரதான ஆசாரியரும் வேதபாரகரும் ஜனத்தின் மூப்பரும் அவரைக் கொலைசெய்ய வகைதேடியும்,
	<br /><br />
	48. ஜனங்களெல்லாரும் அவருக்குச் செவிகொடுத்து அவரை அண்டிக்கொண்டிருந்தபடியால், அதை இன்னபடி செய்யலாமென்று வகைகாணாதிருந்தார்கள்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
    
      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke19