import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial42 = () => {
  const verseNumber = 42;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 42 </title>
	<meta
          name="description"
          content="Ezekiel 42 | எசேக்கியேல்  42 |
          Ezekiel 42 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்பு அவர் என்னை வடதிசையின் வழியாக வெளிப்பிராகாரத்திலே புறப்படப்பண்ணி, பிரத்தியேகமான இடத்துக்கு எதிராகவும், மாளிகைக்கு எதிராகவும் வடக்கே இருந்த அறைவீடுகளுக்கு என்னை அழைத்துக்கொண்டுபோனார்.
	<br /><br />
	2. நூறு முழ நீளத்துக்கு முன்னே வடக்கு வாசல் இருந்தது; அவ்விடத்து அகலம் ஐம்பது முழம்.
	<br /><br />
	3. உட்பிராகாரத்தில் இருந்த இருபது முழத்துக்கு எதிராகவும் வெளிப்பிராகாரத்தில் இருந்த தளவரிசைக்கு எதிராகவும் ஒன்றுக்கொன்று எதிரான மூன்று நிலைகளுள்ள நடைகாவணங்கள் இருந்தது.
	<br /><br />
	4. உட்புறத்திலே அறைவீடுகளின் முன்பாகப் பத்து முழ அகலமான வழியும், ஒரு முழ அகலமான பாதையும் இருந்தது; அவைகளின் வாசல்கள் வடக்கே இருந்தது.
	<br /><br />
	5. உயர இருந்த அறைவீடுகள் அகலக்கட்டையாயிருந்தது; நடைகாவணங்கள் கீழேயிருக்கிற அறைவீடுகளுக்கும் நடுவேயிருக்கிறவைகளுக்கும் அதிக உயரமான மாளிகையாயிருந்தது.
	<br /><br />
	6. அவைகள் மூன்று அடுக்குகளாயிருந்தது; பிராகாரங்களின் தூண்களுக்கு இருந்ததுபோல, அவைகளுக்குத் தூண்களில்லை; ஆகையால் தரையிலிருந்து அளக்க, அவைகள் கீழேயும் நடுவேயும் இருக்கிறவைகளைப்பார்க்கிலும் அகலக்கட்டையாயிருந்தது.
	<br /><br />
	7. புறம்பே அறைவீடுகளுக்கு எதிரே வெளிப்பிராகாரத் திசையில் அறைவீடுகளுக்கு முன்பாக இருந்த மதிலின் நீளம் ஐம்பது முழம்.
	<br /><br />
	8. வெளிப்பிராகாரத்திலுள்ள அறைவீடுகளின் நீளம் ஐம்பது முழம், தேவாலயத்துக்கு முன்னே நூறு முழமாயிருந்தது.
	<br /><br />
	9. கிழக்கே வெளிப்பிராகாரத்திலிருந்து அந்த அறைவீடுகளுக்குள் பிரவேசிக்கிற நடை அவைகளின் கீழே இருந்தது.
	<br /><br />
	10. கீழ்த்திசையான பிராகாரத்து மதிலின் அகலத்திலே பிரத்தியேகமான இடத்துக்கு முன்பாகவும் மாளிகைக்கு முன்பாகவும் அறைவீடுகளும் இருந்தது.
	<br /><br />
	11. அவைகளுக்கு முன்னான வழியிலே அந்த அறைவீடுகள் நீளத்திலும் அகலத்திலும், எல்லா வாசற்படிகளிலும், திட்டங்களிலும், வாசல் நடைகளிலும் வடதிசையான அறைவீடுகளின் சாயலாயிருந்தது.
	<br /><br />
	12. தென்திசையான அறைவீடுகளின் வாசல் நடைக்கு ஒப்பாக ஒரு வாசல் நடைவழியின் முகப்பில் இருந்தது; கீழ்த்திசையில் அவைகளுக்குப் பிரவேசிக்கும் இடத்திலே செம்மையான மதிலின் எதிரே இருந்த வழியின் முகப்பில் ஒரு வாசலிருந்தது.
	<br /><br />
	13. அவர் என்னை நோக்கி: பிரத்தியேகமான இடத்துக்கு முன்னிருக்கிற வடபுறமான அறைவீடுகளும், தென்புறமான அறைவீடுகளும், பரிசுத்த அறைவீடுகளாயிருக்கிறது; கர்த்தரிடத்தில் சேருகிற ஆசாரியர் அங்கே மகா பரிசுத்தமானதையும், போஜனபலியையும், பாவநிவாரண பலியையும், குற்றநிவாரண பலியையும் வைப்பார்கள்; அந்த ஸ்தலம் பரிசுத்தமாயிருக்கிறது.
	<br /><br />
	14. ஆசாரியர் உட்பிரவேசிக்கும்போது, அவர்கள் பரிசுத்த ஸ்தலத்திலிருந்து வெளிப்பிராகாரத்துக்கு வராததற்குமுன்னே, அங்கே தாங்கள் ஆராதனைசெய்து, உடுத்தியிருந்த வஸ்திரங்களைக் கழற்றிவைப்பார்கள்; அவ்வஸ்திரங்கள் பரிசுத்தமானவைகள்; வேறே வஸ்திரங்களை உடுத்திக்கொண்டு, ஜனத்தின் பிராகாரத்திலே போவார்கள் என்றார்.
	<br /><br />
	15. அவர் உள்வீட்டை அளந்து தீர்ந்தபின்பு, கீழ்த்திசைக்கு எதிரான வாசல்வழியாய் என்னை வெளியே அழைத்துக்கொண்டுபோய், அதைச் சுற்றிலும் அளந்தார்.
	<br /><br />
	16. கீழ்த்திசைப் பக்கத்தை அளவுகோலால் அளந்தார்; அது அளவுகோலின்படியே சுற்றிலும் ஐந்நூறு கோலாயிருந்தது.
	<br /><br />
	17. வடதிசைப்பக்கத்தை அளவுகோலால் சுற்றிலும் ஐந்நூறு கோலாய் அளந்தார்.
	<br /><br />
	18. தென்திசைப்பக்கத்தை அளவுகோலால் ஐந்நூறு கோலாய் அளந்தார்.
	<br /><br />
	19. மேற்றிசைப்பக்கத்துக்குத் திரும்பி அதை அளவுகோலால் ஐந்நூறு கோலாய் அளந்தார்.
	<br /><br />
	20. நாலு பக்கங்களிலும் அதை அளந்தார்; பரிசுத்தமானதற்கும் பரிசுத்தமில்லாததற்கும் வித்தியாசம்பண்ணும்படிக்கு அதற்கு ஐந்நூறு கோல் நீளமும் ஐந்நூறு கோல் அகலமுமான மதில் சுற்றிலும் இருந்தது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial42