import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 2 </title>
	<meta
          name="description"
          content="Jeremiah 2 | எரேமியா 2 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. நீ போய், எருசலேமின் செவிகள் கேட்கும்படிக் கூப்பிட்டுச் சொல்லவேண்டியது என்னவென்றால்: விதைக்கப்படாத தேசமாகிய வனாந்தரத்திலே நீ என்னைப் பின்பற்றிவந்த உன் இளவயதின் பக்தியையும், நீ வாழ்க்கைப்பட்டபோது உனக்கிருந்த நேசத்தையும் நினைத்திருக்கிறேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. இஸ்ரவேல் கர்த்தருக்குப் பரிசுத்தமும், அவருடைய விளைவின் முதற்பலனுமாயிருந்தது; அதைப் பட்சித்த யாவரும் குற்றவாளிகளானார்கள்; பொல்லாப்பு அவர்கள்மேல் வந்ததென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. யாக்கோபின் குடும்பத்தாரே, இஸ்ரவேல் குடும்பத்தின் வம்சங்களே, நீங்கள் எல்லாரும் கர்த்தருடைய வார்த்தையைக் கேளுங்கள்.
	<br /><br />
	5. கர்த்தர் சொல்லுகிறது என்னவென்றால்: எங்களை எகிப்துதேசத்திலிருந்து வரப்பண்ணினவரும், அவாந்தரவெளியும், பள்ளங்களுமுள்ள தேசமும், வறட்சியும், மரண இருளுமுள்ள தேசமும், ஒருவனும் கடவாமலும் ஒரு மனுஷனும் குடியிராமலும் இருக்கிற தேசமுமான வனாந்தரத்தில் எங்களை நடத்தினவருமாகிய கர்த்தர் எங்கேயென்று உங்கள் பிதாக்கள் கேளாமல்,
	<br /><br />
	6. என்னைவிட்டுத் தூரப்பட்டு, மாயையைப் பின்பற்றி, வீணராய்ப் போகிறதற்கு என்னிடத்தில் என்ன அநியாயத்தைக் கண்டார்கள்?
	<br /><br />
	7. செழிப்பான தேசத்தின் கனியையும் நன்மையையும் சாப்பிடும்படிக்கு நான் உங்களை அவ்விடத்துக்கு அழைத்துக்கொண்டுவந்தேன்; ஆனாலும் நீங்கள் அதற்குள் பிரவேசித்தபோது, என் தேசத்தைத் தீட்டுப்படுத்தி, என் சுதந்தரத்தை அருவருப்பாக்கினீர்கள்.
	<br /><br />
	8. கர்த்தர் எங்கேயென்று ஆசாரியர்கள் சொல்லாமலும், வேதத்தைப் போதிக்கிறவர்கள் என்னை அறியாமலுமிருந்து, மேய்ப்பர்கள் எனக்குத் துரோகம்பண்ணினார்கள்; தீர்க்கதரிசிகள் பாகாலைக்கொண்டு தீர்க்கதரிசனஞ்சொல்லி, வீணானவைகளைப் பின்பற்றினார்கள்.
	<br /><br />
	9. ஆதலால் இன்னும் நான் உங்களோடே வழக்காடுவேன், உங்கள் பிள்ளைகளின் பிள்ளைகளோடும் வழக்காடுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	10. நீங்கள் கித்தீமின் தீவுகள்மட்டும் கடந்துபோய்ப் பார்த்து, கேதாருக்கு ஆள் அனுப்பி நன்றாய் விசாரித்து, இப்படிப்பட்ட காரியம் உண்டோ என்றும்,
	<br /><br />
	11. எந்த ஜாதியாவது தேவர்களல்லாத தங்கள் தேவர்களை மாற்றினது உண்டோ என்றும் பாருங்கள்; என் ஜனங்களோ வீணானவைகளுக்காகத் தங்கள் மகிமையை மாற்றினார்கள்.
	<br /><br />
	12. வானங்களே, இதினிமித்தம் பிரமித்துக் கொந்தளித்து, மிகவும் திடுக்கிடுங்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	13. என் ஜனங்கள் இரண்டு தீமைகளைச் செய்தார்கள்; ஜீவத்தண்ணீர் ஊற்றாகிய என்னை விட்டுவிட்டார்கள்; தண்ணீர் நிற்காத தொட்டிகளாகிய வெடிப்புள்ள தொட்டிகளைத் தங்களுக்கு வெட்டிக்கொண்டார்கள்.
	<br /><br />
	14. இஸ்ரவேல் ஒரு வேலைக்காரனோ? அவன் வீட்டில் பிறந்த அடிமையோ? ஏன் கொள்ளையானான்?
	<br /><br />
	15. பாலசிங்கங்கள் அவன்மேல் கெர்ச்சித்து, முழங்கி, அவன் தேசத்தைப் பாழாக்கிவிட்டன; அவன் பட்டணங்கள் குடியிராமல் சுட்டெரிக்கப்பட்டன.
	<br /><br />
	16. நோப், தகபானேஸ் என்னும் பட்டணங்களின் புத்திரரும், உன் உச்சந்தலையை நொறுக்கினார்கள்.
	<br /><br />
	17. உன் தேவனாகிய கர்த்தர் உன்னை வழியிலே நடத்திக்கொண்டுபோகுங்காலத்தில், நீ அவரை விட்டுப்போகிறதினால் அல்லவோ இதை உனக்கு நேரிடப்பண்ணினாய்?
	<br /><br />
	18. இப்போதும் சீகோரின் தண்ணீரைக் குடிப்பதற்கு எகிப்துக்குப் போகிறதினால் உனக்குப் பிரயோஜனம் என்ன? ஐப்பிராத்து நதியின் தண்ணீரைக் குடிப்பதற்கு அசீரியாவுக்குப் போகிறதினால் உனக்குப் பிரயோஜனம் என்ன?
	<br /><br />
	19. உன் தீமை உன்னைத் தண்டிக்கும், உன் மாறுபாடுகள் உன்னைக் கண்டிக்கும்; நீ உன் தேவனாகிய கர்த்தரை விடுகிறதும், என்னைப் பற்றும் பயம் உன்னிடத்தில் இல்லாமலிருக்கிறதும், எத்தனை பொல்லாப்பும் கசப்புமான காரியம் என்று உணர்ந்துகொள் என்று சேனைகளின் கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	20. பூர்வகாலந்துவக்கி நான் உன் நுகத்தடியை முறித்து, உன் கட்டுகளை அறுத்தேன்; நான் அடிமைப்படுவதில்லையென்று நீயும் சொன்னாயே; ஆகிலும், உயரமான சகல மேட்டின்மேலும், பச்சையான சகல மரத்தின்கீழும் நீ வேசியாய்த் திரிகிறாய்.
	<br /><br />
	21. நான் உன்னை முற்றிலும் நற்கனிதரும் உயர்குலத் திராட்சச்செடியாக நாட்டினேன்; நீ எனக்குக் காட்டுத் திராட்சச்செடியின் ஆகாத கொடிகளாய் மாறிப்போனது என்ன?
	<br /><br />
	22. நீ உன்னை உவர்மண்ணினாலே கழுவி, அதிக சவுக்காரத்தைக் கையாடினாலும், உன் அக்கிரமத்தின் கறைகள் எனக்கு முன்பாக இருக்குமென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	23. நான் தீட்டுப்படவில்லை; நான் பாகால்களைப் பின்பற்றவில்லை என்று நீ எப்படிச் சொல்லுகிறாய்? பள்ளத்தாக்கிலே நீ நடக்கிற மார்க்கத்தைப் பார்; நீ செய்ததை உணர்ந்துகொள்; தாறுமாறாய் ஓடுகிற வேகமான பெண்ணொட்டகம் நீ.
	<br /><br />
	24. வனாந்தரத்திலே பழகினதும், தன் இச்சையின் மதவெறியிலே காற்றை உட்கொள்ளுகிறதுமான காட்டுக்கழுதை நீ; அதின் ஆவலை நிறுத்தி, அதைத் திருப்புகிறவன் யார்? அதைத் தேடுகிறவர்கள் ஒருவரும் வருத்தப்படவேண்டியதில்லை; அதின் மாசத்தில் அதைக் கண்டுபிடிப்பார்கள்.
	<br /><br />
	25. உன் கால் வெறுங்காலாகாதபடிக்கும், உன் தொண்டை வறட்சியடையாதபடிக்கும் அடக்கிக்கொள் என்றால், நீ: அது கூடாதகாரியம்; நான் அப்படிச் செய்யமாட்டேன்; அந்நியரை நேசிக்கிறேன்; அவர்கள் பிறகே போவேன் என்கிறாய்.
	<br /><br />
	26. திருடன் அகப்படுகிறபோது, எப்படி வெட்கப்படுகிறானோ, அப்படியே இஸ்ரவேல் வம்சத்தார் வெட்கப்படுவார்கள்; கட்டையைப் பார்த்து, நீ என் தகப்பன் என்றும்; கல்லைப்பார்த்து, நீ என்னைப்பெற்றாய் என்றும் சொல்லுகிற அவர்களும், அவர்கள் ராஜாக்களும், அவர்கள் பிரபுக்களும், அவர்கள் ஆசாரியர்களும், அவர்கள் தீர்க்கதரிசிகளும் வெட்கப்படுவார்கள்.
	<br /><br />
	27. அவர்கள் தங்கள் முகத்தையல்ல, தங்கள் முதுகை எனக்குக் காட்டினார்கள்; தங்கள் ஆபத்துக்காலத்திலோ எழுந்து எங்களை இரட்சியும் என்கிறார்கள்.
	<br /><br />
	28. நீ உனக்கு உண்டுபண்ணின தேவர்கள் எங்கே? உன் ஆபத்துக்காலத்தில் உன்னை இரட்சிக்கக்கூடுமானால் அவைகள் எழும்பட்டும்; யூதாவே, உன் பட்டணங்களின் இலக்கமும், உன் தேவர்களின் இலக்கமும் சரி.
	<br /><br />
	29. என்னோடே நீங்கள் வழக்காடுவானேன்? நீங்கள் அனைவரும் எனக்கு விரோதமாய்த் துரோகம்பண்ணினீர்கள் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	30. நான் உங்கள் பிள்ளைகளை அடித்தது விருதா; சிட்சையை ஏற்றுக்கொள்ளாமற்போனார்கள்; அழிக்கிற சிங்கத்தைப்போல உங்கள் பட்டயம் உங்கள் தீர்க்கதரிசிகளைப் பட்சித்தது.
	<br /><br />
	31. சந்ததியாரே, நீங்கள் கர்த்தருடைய வார்த்தையைச் சிந்தித்துப்பாருங்கள்: நான் இஸ்ரவேலுக்கு வனாந்தரமும், காரிருளான பூமியுமாக இருந்தேனோ? பின்னை ஏன் என் ஜனங்கள்: நாங்களே எஜமான்கள், இனி உம்மிடத்தில் நாங்கள் வருவதில்லையென்று சொல்லுகிறார்கள்.
	<br /><br />
	32. ஒரு பெண் தன் ஆபரணத்தையும், ஒரு மணவாட்டி தன் ஆடைகளையும் மறப்பாளோ? என் ஜனங்களோ எண்ணிமுடியாத நாட்களாய் என்னை மறந்துவிட்டார்கள்.
	<br /><br />
	33. நேசத்தைத் தேடும்படிக்கு நீ உன் வழிகளை நயப்படுத்துகிறதென்ன? இவ்விதமாய் நீ பொல்லாத ஸ்திரீகளுக்கும் உன் வழிகளைக் கற்றுக்கொடுத்தாய்.
	<br /><br />
	34. உன் வஸ்திர ஓரங்களிலும் குற்றமில்லாத ஏழை ஆத்துமாக்களின் இரத்தம் காணப்படுகிறது; அதைத் தோண்டித் தேடுகிறதினால் அல்ல, அது எல்லாவற்றின்மேலும் வெளியாயிருக்கிறதினால் அதைக் கண்டுபிடித்தேன்.
	<br /><br />
	35. ஆகிலும்: குற்றமில்லாதிருக்கிறேன் என்றும், அவருடைய கோபம் என்னைவிட்டுத் திரும்பிற்று என்றும் சொல்லுகிறாய்; இதோ, நான் பாவஞ்செய்யவில்லையென்று நீ சொல்லுகிறதினிமித்தம் நான் உன்னோடே வழக்காடுவேன்.
	<br /><br />
	36. நீ உன் வழியை மாற்றிமாற்றி இவ்வளவாய் விலகிப்போகிறது என்ன? நீ அசீரியாவினால் வெட்கிப்போனதுபோல எகிப்தினாலும் வெட்கிப்போவாய்.
	<br /><br />
	37. நீ உன் கைகளை உன் தலையின்மேல் வைத்துக்கொண்டு இவ்விடத்திலிருந்து புறப்பட்டுப்போவாய்; ஏனெனில், உன் நம்பிக்கைகளைக் கர்த்தர் வெறுத்திருக்கிறார்; அவைகளால் உனக்குக் காரியம் வாய்க்காது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah2