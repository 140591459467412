import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Galatians6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | கலாத்தியருக்கு எழுதின நிருபம் 6 </title>
	<meta
          name="description"
          content="Galatians | கலாத்தியர் அதிகாரம் 6 | கலாத்தியருக்கு எழுதின நிருபம் |
          Book of Galatians | Chapter 6 | Tamil Bible Verse | Tamil Bible | புதிய ஏற்பாடு | TamilBible | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சகோதரரே, ஒருவன் யாதொரு குற்றத்தில் அகப்பட்டால், ஆவிக்குரியவர்களாகிய நீங்கள் சாந்தமுள்ள ஆவியோடே அப்படிப்பட்டவனைச் சீர்பொருந்தப்பண்ணுங்கள்; நீயும் சோதிக்கப்படாதபடிக்கு உன்னைக்குறித்து எச்சரிக்கையாயிரு.
	<br /><br />
	2. ஒருவர் பாரத்தை ஒருவர் சுமந்து, இப்படியே கிறிஸ்துவினுடைய பிரமாணத்தை நிறைவேற்றுங்கள்.
	<br /><br />
	3. ஒருவன், தான் ஒன்றுமில்லாதிருந்தும், தன்னை ஒரு பொருட்டென்று எண்ணினால், தன்னைத்தானே வஞ்சிக்கிறவனாவான்.
	<br /><br />
	4. அவனவன் தன்தன் சுயகிரியையைச் சோதித்துப்பார்க்கக்கடவன்; அப்பொழுது மற்றவனைப் பார்க்கும்போதல்ல, தன்னையே பார்க்கும்போது மேன்மைபாராட்ட அவனுக்கு இடமுண்டாகும்.
	<br /><br />
	5. அவனவன் தன்தன் பாரத்தைச் சுமப்பானே.
	<br /><br />
	6. மேலும், திருவசனத்தில் உபதேசிக்கப்படுகிறவன் உபதேசிக்கிறவனுக்குச் சகல நன்மைகளிலும் பகிர்ந்துகொடுக்கக்கடவன்.
	<br /><br />
	7. மோசம்போகாதிருங்கள், தேவன் தம்மைப் பரியாசம்பண்ணவொட்டார்; மனுஷன் எதை விதைக்கிறானோ அதையே அறுப்பான்.
	<br /><br />
	8. தன் மாம்சத்திற்கென்று விதைக்கிறவன் மாம்சத்தினால் அழிவை அறுப்பான்; ஆவிக்கென்று விதைக்கிறவன் ஆவியினாலே நித்தியஜீவனை அறுப்பான்.
	<br /><br />
	9. நன்மைசெய்கிறதில் சோர்ந்துபோகாமல் இருப்போமாக; நாம் தளர்ந்துபோகாதிருந்தால் ஏற்றகாலத்தில் அறுப்போம்.
	<br /><br />
	10. ஆகையால் நமக்குக் கிடைக்கும் சமயத்திற்குத்தக்கதாக, யாவருக்கும், விசேஷமாக விசுவாச குடும்பத்தார்களுக்கும், நன்மைசெய்யக்கடவோம்.
	<br /><br />
	11. என் கையெழுத்தாய் எவ்வளவு எழுதினேனென்று பாருங்கள்.
	<br /><br />
	12. மாம்சத்தின்படி நல்வேஷமாய்க் காணப்பட விரும்புகிறவர்கள் எவர்களோ, அவர்கள் தாங்கள் கிறிஸ்துவினுடைய சிலுவையினிமித்தம் துன்பப்படாதபடிக்கே உங்களை விருத்தசேதனம் பண்ணிக்கொள்ளக் கட்டாயம்பண்ணுகிறார்கள்.
	<br /><br />
	13. விருத்தசேதனம் அடைந்திருக்கிற அவர்களும் நியாயப்பிரமாணத்தைக் கைக்கொள்ளாமலிருக்கிறார்கள்; அப்படியிருந்தும், அவர்கள் உங்கள் மாம்சத்தைக்குறித்து மேன்மைபாராட்டும்படிக்கு நீங்கள் விருத்தசேதனம்பண்ணிக்கொள்ளவேண்டுமென்று விரும்புகிறார்கள்.
	<br /><br />
	14. நானோ நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் சிலுவையைக் குறித்தேயல்லாமல் வேறொன்றையுங்குறித்து மேன்மைபாராட்டாதிருப்பேனாக; அவரால் உலகம் எனக்குச் சிலுவையிலறையுண்டிருக்கிறது, நானும் உலகத்திற்குச் சிலுவையிலறையுண்டிருக்கிறேன்.
	<br /><br />
	15. கிறிஸ்து இயேசுவுக்குள் விருத்தசேதனமும் ஒன்றுமில்லை, விருத்தசேதனமில்லாமையும் ஒன்றுமில்லை; புது சிருஷ்டியே காரியம்.
	<br /><br />
	16. இந்தப் பிரமாணத்தின்படி நடந்துவருகிறவர்கள் எவர்களோ, அவர்களுக்கும், தேவனுடைய இஸ்ரவேலருக்கும், சமாதானமும் இரக்கமும் உண்டாயிருப்பதாக.
	<br /><br />
	17. இனிமேல் ஒருவனும் எனக்கு வருத்தம் உண்டாக்காதிருப்பானாக; கர்த்தராகிய இயேசுவினுடைய அச்சடையாளங்களை நான் என் சரீரத்திலே தரித்துக்கொண்டிருக்கிறேன்.
	<br /><br />
	18. சகோதரரே, நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவின் கிருபை உங்கள் ஆவியுடனேகூட இருப்பதாக. ஆமென்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Galatians6