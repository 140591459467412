import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians10 = () => {
  const verseNumber = 10;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 10 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 10 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 10 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உங்களுக்கு முன்பாக இருக்கும்போது தாழ்மையாயும், தூரத்திலே இருக்கும்போது உங்கள்மேல் கண்டிப்பாயும் இருக்கிற பவுலாகிய நான் கிறிஸ்துவின் சாந்தத்தையும் தயவையும் முன்னிட்டு உங்களுக்குப் புத்திசொல்லுகிறேன்.
	<br /><br />
	2. எங்களை மாம்சத்தின்படி நடக்கிறவர்கள் என்று எண்ணுகிற சிலரைக்குறித்து நான் கண்டிப்பாயிருக்கவேண்டுமென்று நினைத்திருக்கிற தைரியத்தோடே, உங்கள்முன்பாக இருக்கும்போது, நான் கண்டிப்புள்ளவனாயிராதபடிக்கு நீங்கள் எச்சரிக்கையாயிருக்க உங்களை வேண்டிக்கொள்ளுகிறேன்.
	<br /><br />
	3. நாங்கள் மாம்சத்தில் நடக்கிறவர்களாயிருந்தும், மாம்சத்தின்படி போர்செய்கிறவர்களல்ல.
	<br /><br />
	4. எங்களுடைய போராயுதங்கள் மாம்சத்துக்கேற்றவைகளாயிராமல், அரண்களை நிர்மூலமாக்குகிறதற்குத் தேவபலமுள்ளவைகளாயிருக்கிறது.
	<br /><br />
	5. அவைகளால் நாங்கள் தர்க்கங்களையும், தேவனை அறிகிற அறிவுக்கு விரோதமாய் எழும்புகிற எல்லா மேட்டிமையையும் நிர்மூலமாக்கி, எந்த எண்ணத்தையும் கிறிஸ்துவுக்குக் கீழ்ப்படியச் சிறைப்படுத்துகிறவர்களாயிருக்கிறோம்.
	<br /><br />
	6. உங்கள் கீழ்ப்படிதல் நிறைவேறும்போது, எல்லாக் கீழ்ப்படியாமைக்குந்தக்க நீதியுள்ள தண்டனையைச் செலுத்த ஆயத்தமாயுமிருக்கிறோம்.
	<br /><br />
	7. வெளித்தோற்றத்தின்படி பார்க்கிறீர்களா? ஒருவன் தன்னைக் கிறிஸ்துவுக்குரியவனென்று நம்பினால், தான் கிறிஸ்துவுக்குரியவனாயிருக்கிறதுபோல நாங்களும் கிறிஸ்துவுக்குரியவர்களென்று அவன் தன்னிலேதானே சிந்திக்கக்கடவன்.
	<br /><br />
	8. மேலும், உங்களை நிர்மூலமாக்குகிறதற்கல்ல, உங்களை ஊன்றக் கட்டுகிறதற்குக் கர்த்தர் எங்களுக்குக் கொடுத்த அதிகாரத்தைக்குறித்து, நான் இன்னும் சற்றே அதிகமாய் மேன்மைபாராட்டினாலும் நான் வெட்கப்படுவதில்லை.
	<br /><br />
	9. நான் நிருபங்களாலே உங்களைப் பயமுறுத்துகிறவனாய்த் தோன்றாதபடிக்கு இதைச் சொல்லுகிறேன்.
	<br /><br />
	10. அவனுடைய நிருபங்கள் பாரயோசனையும் பலமுமுள்ளவைகள்; சரீரத்தின் தோற்றமோ பலவீனமும், வசனம் அற்பமுமாயிருக்கிறதென்கிறார்களே.
	<br /><br />
	11. அப்படிச் சொல்லுகிறவன், நாங்கள் தூரத்திலிருக்கும்போது எழுதுகிற நிருபங்களால் வசனத்தில் எப்படிப்பட்டவர்களாயிருக்கிறோமோ, அப்படிப்பட்டவர்களாகவே சமீபத்திலிருக்கும்போதும் கிரியையிலும் இருப்போம் என்று சிந்திக்கக்கடவன்.
	<br /><br />
	12. ஆகிலும் தங்களைத் தாங்களே மெச்சிக்கொள்ளுகிற சிலருக்கு நாங்கள் எங்களைச் சரியாக்கவும் ஒப்பிடவும் துணியமாட்டோம்; தங்களைக்கொண்டு தங்களை அளந்துகொண்டு, தங்களுக்கே தங்களை ஒப்பிட்டுக்கொள்ளுகிற அவர்கள் புத்திமான்களல்ல.
	<br /><br />
	13. நாங்கள் அளவுக்கு மிஞ்சி மேன்மைபாராட்டாமல், உங்களிடம்வரைக்கும் வந்தெட்டத்தக்கதாக, தேவன் எங்களுக்கு அளந்து பகிர்ந்த அளவுப்பிரமாணத்தின்படியே மேன்மைபாராட்டுகிறோம்.
	<br /><br />
	14. உங்களிடத்தில் வந்தெட்டாதவர்களாய் நாங்கள் அளவுக்கு மிஞ்சிப்போகிறதில்லை; நாங்கள் கிறிஸ்துவின் சுவிசேஷத்தைப் பிரசங்கித்து உங்களிடம்வரைக்கும் வந்தோமே.
	<br /><br />
	15. எங்கள் அளவைக் கடந்து மற்றவர்களுடைய வேலைக்குட்பட்டு மேன்மைபாராட்டமாட்டோம்.
	<br /><br />
	16. ஆகிலும் உங்கள் விசுவாசம் விருத்தியாகும்போது, மற்றவர்களுடைய எல்லைகளுக்குள்ளே செய்யப்பட்டவைகளை நாங்கள் செய்ததாக மேன்மைபாராட்டாமல், உங்களுக்கு அப்புறமான இடங்களில் சுவிசேஷத்தைப் பிரசங்கிக்கத்தக்கதாக, எங்கள் அளவின்படி உங்களால் மிகவும் பெருகி விருத்தியடைவோமென்று நம்பிக்கையாயிருக்கிறோம்.
	<br /><br />
	17. மேன்மைபாராட்டுகிறவன் கர்த்தரைக்குறித்தே மேன்மைபாராட்டக்கடவன்.
	<br /><br />
	18. தன்னைத்தான் புகழுகிறவன் உத்தமனல்ல, கர்த்தரால் புகழப்படுகிறவனே உத்தமன்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians10