
import React from 'react';
import { Helmet } from 'react-helmet';
import './downloads.css'
import one from './assets/screenshots/1.jpg'
import two from './assets/screenshots/2.jpg'
import four from './assets/screenshots/4.jpg'
import six from './assets/screenshots/6.jpg'
import logo from './assets/logo.png'

function Downloads () {

	return(
	<>
	<Helmet>
        <title> The First Tamil Bible Verse App Download free in Our Website </title>
        <meta
          name="description"
          content="We are proud and happy to release the first version of Tamil Bible in android for free. This app contains both Old Testament and New Testament in Tamil"
        />
      </Helmet>
	 <div className='downloads'>
<div className="main-container">
        <div className="logo">
            <img src={logo} width='100px' alt="Logo"/>
			<br/>
			<br/>
			<div className="download-container">
        <a href="/TamilBible.apk" target='_blank' className="download-button">Download  </a>
    </div>
        </div>
        <div className="text">
            <h1>Tamil Bible Application Download</h1>
            <p>
                Simple and Friendly user interface Tamil Holy bible<br/>
                most importantly, it is free. For regular reading and references.<br/>
                Works Offline: All the Books, chapters and verses in Bible are stored on your Mobile device, so you will never need an internet connection to study and read the Holy Bible.<br/>
                Works on all sorts of phones: Our app will execute smoothly on any device without any UI/UX lag.<br/>
                We are proud and happy to release the first version of Tamil Holy Bible in windows for free. This app contains both "Old Testament" and "New Testament". Our effort in bringing you the Bible in Indian languages. We sincerely hope that this app will rejoice you.<br/>
                Material provided in this app is proposed for educational and reference use. This content of the Tamil Bible is made available under a Creative Commons.
            </p>
        </div>
    </div>
<div className='screenshots'>
		<img src={one} alt='screen shots' width="250px"/>
		<img src={two} alt='screen shots' width="250px"/>
		<img src={four} alt='screen shots' width="250px"/>
		<img src={six} alt='screen shots' width="250px"/>
</div>
<div>
    </div>
	</div>
	</>
	);
}
export default Downloads;
