import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jonah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | யோனா 1 </title>
	<meta
          name="description"
          content="Jonah 1 | யோனா 1 | Yona 1 | 
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அமித்தாயின் குமாரனாகிய யோனாவுக்குக் கர்த்தருடைய வார்த்தை உண்டாகி, அவர்:
	<br /><br />
	2. நீ எழுந்து மகா நகரமாகிய நினிவேக்குப் போய், அதற்கு விரோதமாகப் பிரசங்கி; அவர்களுடைய அக்கிரமம் என் சமுகத்தில் வந்து எட்டினது என்றார்.
	<br /><br />
	3. அப்பொழுது யோனா கர்த்தருடைய சமுகத்தினின்று விலகி, தர்ஷீசுக்கு ஓடிப்போகும்படி எழுந்து, யோப்பாவுக்குப் போய், தர்ஷீசுக்குப் போகிற ஒரு கப்பலைக்கண்டு, கூலி கொடுத்து, தான் கர்த்தருடைய சமுகத்தினின்று விலகும்படி, அவர்களோடே தர்ஷீசுக்குப் போகக் கப்பல் ஏறினான்.
	<br /><br />
	4. கர்த்தர் சமுத்திரத்தின்மேல் பெருங்காற்றை வரவிட்டார்; அதினால் கடலிலே கப்பல் உடையுமென்று நினைக்கத்தக்க பெரிய கொந்தளிப்பு உண்டாயிற்று.
	<br /><br />
	5. அப்பொழுது கப்பற்காரர் பயந்து, அவனவன் தன்தன் தேவனை நோக்கி வேண்டுதல்செய்து, பாரத்தை லேசாக்கும்படிக் கப்பலில் இருந்த சரக்குகளைச் சமுத்திரத்தில் எறிந்துவிட்டார்கள்; யோனாவோவென்றால் கப்பலின் கீழ்த்தட்டில் இறங்கிபோய்ப் படுத்துக்கொண்டு, அயர்ந்த நித்திரைபண்ணினான்.
	<br /><br />
	6. அப்பொழுது மாலுமி அவனிடத்தில் வந்து: நீ நித்திரைபண்ணுகிறது என்ன? எழுந்திருந்து உன் தேவனை நோக்கி வேண்டிக்கொள்; நாம் அழிந்துபோகாதபடிக்குச் சுவாமி ஒருவேளை நம்மை நினைத்தருளுவார் என்றான்.
	<br /><br />
	7. அவர்கள் யார்நிமித்தம் இந்த ஆபத்து நமக்கு நேரிட்டதென்று நாம் அறியும்படிக்குச் சீட்டுப்போடுவோம் வாருங்கள் என்று ஒருவரோடொருவர் சொல்லிக்கொண்டு சீட்டுப்போட்டார்கள்; யோனாவின் பேருக்குச் சீட்டு விழுந்தது.
	<br /><br />
	8. அப்பொழுது அவர்கள் அவனை நோக்கி: யார்நிமித்தம் இந்த ஆபத்து நமக்கு நேரிட்டதென்று நீ எங்களுக்குச் சொல்லவேண்டும்; உன் தொழிலென்ன? நீ எங்கேயிருந்து வருகிறாய்? உன் தேசம் எது? நீ என்ன ஜாதியான் என்று கேட்டார்கள்.
	<br /><br />
	9. அதற்கு அவன்: நான் எபிரெயன்; சமுத்திரத்தையும் பூமியையும் உண்டாக்கின பரலோகத்தின் தேவனாகிய கர்த்தர் இடத்தில் பயபக்தியுள்ளவன் என்றான்.
	<br /><br />
	10. அவன் கர்த்தருடைய சமுகத்தினின்று விலகி ஓடிப்போகிறவன் என்று தங்களுக்கு அறிவித்ததினால், அந்த மனுஷர் மிகவும் பயந்து, அவனை நோக்கி: நீ ஏன் இதைச் செய்தாய் என்றார்கள்.
	<br /><br />
	11. பின்னும் சமுத்திரம் அதிகமாய்க் கொந்தளித்துக்கொண்டிருந்தபடியால், அவர்கள் அவனை நோக்கி: சமுத்திரம் நமக்கு அமரும்படி நாங்கள் உனக்கு என்ன செய்யவேண்டும் என்று கேட்டார்கள்.
	<br /><br />
	12. அதற்கு அவன்: நீங்கள் என்னை எடுத்துச் சமுத்திரத்திலே போட்டுவிடுங்கள்; அப்பொழுது சமுத்திரம் உங்களுக்கு அமர்ந்திருக்கும்; என்னிமித்தம் இந்தப் பெரிய கொந்தளிப்பு உங்கள்மேல் வந்ததென்பதை நான் அறிவேன் என்றான்.
	<br /><br />
	13. அந்த மனுஷர் கரைசேரும்படி வேகமாய்த் தண்டுவலித்தார்கள்; ஆனாலும் சமுத்திரம் வெகு மும்முரமாய்க் கொந்தளித்துக்கொண்டேயிருந்தபடியால் அவர்களால் கூடாமற்போயிற்று.
	<br /><br />
	14. அப்பொழுது அவர்கள் கர்த்தரை நோக்கிக் கூப்பிட்டு: ஆ கர்த்தாவே, இந்த மனுஷனுடைய ஜீவனிமித்தம் எங்களை அழித்துப்போடாதேயும்; குற்றமில்லாத இரத்தப்பழியை எங்கள்மேல் சுமத்தாதேயும்; தேவரீர் கர்த்தர்; உமக்குச் சித்தமாயிருக்கிறபடி செய்கிறீர் என்று சொல்லி,
	<br /><br />
	15. யோனாவை எடுத்துச் சமுத்திரத்திலே போட்டுவிட்டார்கள்; சமுத்திரம் தன் மும்முரத்தைவிட்டு அமர்ந்தது.
	<br /><br />
	16. அப்பொழுது அந்த மனுஷர் கர்த்தருக்கு மிகவும் பயந்து, கர்த்தருக்குப் பலியிட்டுப் பொருத்தனைகளைப் பண்ணினார்கள்.
	<br /><br />
	17. யோனாவை விழுங்கும்படி ஒரு பெரிய மீனைக் கர்த்தர் ஆயத்தப்படுத்தியிருந்தார்; அந்த மீன் வயிற்றிலே யோனா இராப்பகல் மூன்றுநாள் இருந்தான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jonah1