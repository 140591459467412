import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah6 = () => {
  const verseNumber = 6;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 6 </title>
	<meta
          name="description"
          content="Micah 6 | மீகா 6 | Meega 6|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தர் சொல்லுகிறதைக் கேளுங்கள்; நீ எழுந்து, பர்வதங்களுக்குமுன் உன் வழக்கைச் சொல்; மலைகள் உன் சத்தத்தைக் கேட்கக்கடவது.
	<br /><br />
	2. பர்வதங்களே, பூமியின் உறுதியான அஸ்திபாரங்களே, கர்த்தருடைய வழக்கைக் கேளுங்கள்; கர்த்தருக்கு அவர் ஜனத்தோடே வழக்கு இருக்கிறது; இஸ்ரவேலோடே அவர் வழக்காடுவார்.
	<br /><br />
	3. என் ஜனமே, நான் உனக்கு என்ன செய்தேன்? நான் எதினால் உன்னை விசனப்படுத்தினேன்? எனக்கு எதிரே உத்தரவு சொல்.
	<br /><br />
	4. நான் உன்னை எகிப்துதேசத்திலிருந்து வரப்பண்ணி, அடிமைத்தன வீட்டிலிருந்த உன்னை மீட்டுக்கொண்டு, மோசே ஆரோன் மீரியாம் என்பவர்களை உனக்கு முன்பாக அனுப்பினேன்.
	<br /><br />
	5. என் ஜனமே, மோவாபின் ராஜாவாகிய பாலாக் பண்ணின யோசனை இன்னதென்றும், பேயோரின் குமாரனான பிலேயாம் அவனுக்குப் பிரதியுத்தரமாகச் சொன்னது இன்னதென்றும், சித்தீம் தொடங்கி கில்கால்மட்டும் நடந்தது இன்னதென்றும், நீ கர்த்தருடைய நீதிகளை அறிந்துகொள்ளும்படி நினைத்துக்கொள்.
	<br /><br />
	6. என்னத்தைக்கொண்டு நான் கர்த்தருடைய சந்நிதியில் வந்து, உன்னதமான தேவனுக்கு முன்பாகப் பணிந்துகொள்வேன்? தகனபலிகளைக்கொண்டும், ஒரு வயது கன்றுக்குட்டிகளைக்கொண்டும் அவர் சந்நிதியில் வரவேண்டுமோ?
	<br /><br />
	7. ஆயிரங்களான ஆட்டுக்கடாக்களின்பேரிலும், எண்ணெயாய் ஓடுகிற பதினாயிரங்களான ஆறுகளின்பேரிலும், கர்த்தர் பிரியமாயிருப்பாரோ? என் அக்கிரமத்தைப் போக்க என் முதற்பேறானவனையும், என் ஆத்துமாவின் பாவத்தைப் போக்க என் கர்ப்பக்கனியையும் கொடுக்கவேண்டுமோ?
	<br /><br />
	8. மனுஷனே, நன்மை இன்னதென்று அவர் உனக்கு அறிவித்திருக்கிறார்; நியாயஞ்செய்து, இரக்கத்தைச் சிநேகித்து, உன் தேவனுக்கு முன்பாக மனத்தாழ்மையாய் நடப்பதை அல்லாமல் வேறே என்னத்தைக் கர்த்தர் உன்னிடத்தில் கேட்கிறார்.
	<br /><br />
	9. கர்த்தருடைய சத்தம் நகரத்தை நோக்கிக் கூப்பிடுகிறது; ஞானமுள்ளவன் உம்முடைய நாமத்தை மதிப்பான்; மிலாற்றிற்கும் அதை நேமித்தவருக்கும் செவிகொடுங்கள்.
	<br /><br />
	10. துன்மார்க்கனுடைய வீட்டிலே துன்மார்க்கத்தினால் சம்பாதித்த பொக்கிஷங்களும், அருவருக்கப்படத்தக்க குறைந்த மரக்காலும் இன்னும் இருக்கிறதல்லவோ?
	<br /><br />
	11. கள்ளத்தராசும் கள்ளப் படிக்கற்களுள்ள பையும் இருக்கும்போது, அவர்களைச் சுத்தமுள்ளவர்களென்று எண்ணுவேனோ?
	<br /><br />
	12. அவர்களில் ஐசுவரியமுள்ளவர்கள் கொடுமையால் நிறைந்திருக்கிறார்கள்; அவர்களுக்குள் வாசமாயிருக்கிறவர்கள் பொய்பேசுகிறார்கள்; அவர்கள் வாயிலுள்ள நாவு கபடமுள்ளது.
	<br /><br />
	13. ஆகையால் நான் உன் பாவங்களினிமித்தம் உன்னை அடித்துப் பாழாக்குகிறதினால் உன்னைப் பலட்சயமாக்குவேன்.
	<br /><br />
	14. நீ புசித்தும் திருப்தியடையாதிருப்பாய்; உனக்குள்ளே சோர்வுண்டாகும்; நீ பதனம் பண்ணியும் தப்புவிப்பதில்லை; நீ தப்புவிப்பதையும் பட்டயத்துக்கு ஒப்புக்கொடுப்பேன்.
	<br /><br />
	15. நீ விதைத்தும் அறுக்காமற்போவாய்; நீ ஒலிவப்பழங்களையும் திராட்சப்பழங்களையும் ஆலையாடின போதிலும், எண்ணெய் பூசிக்கொள்வதுமில்லை, இரசம் குடிப்பதுமில்லை.
	<br /><br />
	16. நான் உன்னைப் பாழாகவும் உன் குடிகளை ஈசலிட்டு நிந்திக்கிற நிந்தையாகவும் வைக்கும்படி, உம்ரியினுடைய கட்டளைகளும் ஆகாப் வீட்டாருடைய எல்லாச் செய்கைகளும் கைக்கொள்ளப்பட்டுவருகிறது; அவர்களுடைய ஆலோசனைகளிலே நடக்கிறீர்கள்; ஆகையால் என் ஜனத்தின் நிந்தையைச் சுமப்பீர்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah6