import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial30 = () => {
  const verseNumber = 30;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 30 </title>
	<meta
          name="description"
          content="Ezekiel 30 | எசேக்கியேல்  30 |
          Ezekiel 30 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	2. மனுபுத்திரனே, நீ தீர்க்கதரிசனம் சொல்லு: கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், ஐயோ! ஆபத்துநாள் வருகிறதென்று அலறுங்கள்.
	<br /><br />
	3. நாள் சமீபமாயிருக்கிறது; ஆம், கர்த்தருடைய நாள் சமீபமாயிருக்கிறது; அது மந்தாரமான நாள், அது புறஜாதிகளுக்கு வரும் காலம்.
	<br /><br />
	4. பட்டயம் எகிப்திலே வரும்; எகிப்திலே கொலையுண்கிறவர்கள் விழும்போது எத்தியோப்பியாவிலே மகா வேதனை உண்டாயிருக்கும்; அதின் ஏராளமான ஜனத்தைப் பிடித்துக்கொண்டுபோவார்கள்; அதின் அஸ்திபாரங்கள் நிர்மூலமாக்கப்படும்.
	<br /><br />
	5. எத்தியோப்பியரும், பூத்தியரும், லூத்தியரும், கலந்த கூட்டமாகிய அனைவரும், கூபியரும், உடன்படிக்கைக்குள்ளான தேசத்தின் புத்திரரும் அவர்களோடேகூடப் பட்டயத்தால் விழுவார்கள்.
	<br /><br />
	6. எகிப்தை ஆதரிக்கிறவர்களும் விழுவார்கள்; அதினுடைய பலத்தின் முக்கியமும் தாழ்ந்துபோம் என்று கர்த்தர் சொல்லுகிறார்; அதிலே மிக்தோல்முதல் செவெனேவரைக்கும் பட்டயத்தினால் விழுவார்களென்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	7. பாழாய்ப்போன தேசங்களின் நடுவிலே பாழாய்ப்போவார்கள்; அவாந்தரமாக்கப்பட்ட பட்டணங்களில் அவர்கள் பட்டணங்களும் அவாந்தரமாகும்.
	<br /><br />
	8. நான் எகிப்திலே தீக்கொளுத்தும்போதும், உனக்குத் துணைநின்ற யாவரும் முறிக்கப்படும்போதும், நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	9. நிர்விசாரமான எத்தியோப்பியரைத் தத்தளிக்கப்பண்ண அந்நாளிலே என் கட்டளையினால் தூதாட்கள் கப்பல்களிலே போவார்கள்; அப்பொழுது எகிப்தின் நாளிலே உண்டானதுபோல அவர்களுக்குள்ளே மகா வேதனை உண்டாயிருக்கும்; இதோ, அது வருகிறது.
	<br /><br />
	10. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரைக்கொண்டு எகிப்தின் சந்ததியை ஒழியப்பண்ணுவேன்.
	<br /><br />
	11. இவனும் இவனோடேகூட ஜாதிகளில் மகா பலசாலிகளான இவனுடைய ஜனங்களும் தேசத்தை அழிப்பதற்காக ஏவப்பட்டு வந்து, தங்கள் பட்டயங்களை எகிப்துக்கு விரோதமாக உருவி, கொலையுண்டவர்களாலே தேசத்தை நிரப்புவார்கள்.
	<br /><br />
	12. அப்பொழுது நான் நதிகளை வற்றிப்போகப்பண்ணி, தேசத்தைத் துஷ்டர்களின் கையிலே விற்று, தேசத்தையும் அதிலுள்ள யாவையும் அந்நியதேசத்தாரின் கையால் பாழாக்கிப்போடுவேன்; கர்த்தராகிய நான் இதைச் சொன்னேன்.
	<br /><br />
	13. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: நான் நரகலான விக்கிரகங்களை அழித்து, நோப்பின் சிலைகளை ஒழியப்பண்ணுவேன்; இனி எகிப்துதேசத்தில் ஒரு அதிபதியும் இரான்; நான் எகிப்துதேசத்தில் பயமுண்டாக்கி,
	<br /><br />
	14. பத்ரோசைப் பாழாக்கி, சோவானிலே தீக்கொளுத்தி, நோ பட்டணத்தில் ஆக்கினைகளைச் செய்து,
	<br /><br />
	15. எகிப்தின் பெலனாகிய சீனின்மேல் என் உக்கிரத்தை ஊற்றி, நோ பட்டணத்தின் ஏராளமான ஜனத்தைச் சங்கரிப்பேன்.
	<br /><br />
	16. எகிப்தில் தீக்கொளுத்துவேன்; சீன் மகா வேதனை அடையும்; நோ பட்டணம் தகர்ந்து இடிந்துபோகும்; நோப்புக்குத் தினந்தோறும் நெருக்கங்களுண்டாகும்.
	<br /><br />
	17. ஆவென், பிபேசெத் என்கிற பட்டணங்களின் வேலைக்காரர் பட்டயத்தால் விழுவார்கள்; அவைகளின் குடிகள் சிறையிருப்புக்குப் போவார்கள்.
	<br /><br />
	18. எகிப்தின் நுகங்களை நான் முறிக்கும்போதும், அதினுடைய பெலத்தின் முக்கியம் அதிலே ஓயும்போதும், மந்தாரம் அதை மூடும்; தக்பானேசிலே பகல் இருண்டுபோகும்; அதின் குமாரத்திகள் சிறைப்பட்டுப்போவார்கள்.
	<br /><br />
	19. இப்படி எகிப்திலே நியாயத்தீர்ப்புகளைச் செய்வேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள் என்கிறார் என்று சொல் என்றார்.
	<br /><br />
	20. பதினோராம் வருஷம் முதலாம் மாதம் ஏழாந்தேதியிலே, கர்த்தருடைய வார்த்தை எனக்கு உண்டாகி, அவர்:
	<br /><br />
	21. மனுபுத்திரனே, எகிப்தின் ராஜாவாகிய பார்வோனுடைய புயத்தை முறித்துப்போடுவேன்; இதோ, அது குணமாகத்தக்கதாகக் கட்டப்படுவதில்லை; அது பட்டயத்தைப் பிடிக்கத்தக்க பெலனை அடையும்படி பத்தை வைத்துக் கட்டப்படுவதுமில்லை.
	<br /><br />
	22. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால்: இதோ, நான் எகிப்தின் ராஜாவாகிய பார்வோனுக்கு விரோதமாக வந்து, பெலனுள்ளதும் முறிந்ததுமாகிய அவனுடைய புயங்களை முறித்துப்போடுவேன்; பட்டயத்தை நான் அவன் கையிலிருந்து விழப்பண்ணி,
	<br /><br />
	23. எகிப்தியரை ஜாதிகளுக்குள்ளே சிதறடித்து, அவர்களைத் தேசங்களில் தூற்றிவிடுவேன்.
	<br /><br />
	24. பாபிலோன் ராஜாவின் புயங்களைப் பெலப்படுத்தி, அவன் கையிலே என் பட்டயத்தைக் கொடுத்து, பார்வோனின் புயங்களை முறித்துவிடுவேன்; அப்பொழுது அவன் கொலையுண்கிறவன் தவிக்கிறதுபோல அவனுக்கு முன்பாகத் தவிப்பான்.
	<br /><br />
	25. பாபிலோன் ராஜாவின் புயங்களைப் பெலப்படுத்துவேன்; பார்வோனின் புயங்களோ விழுந்துபோம்; என் பட்டயத்தை நான் பாபிலோன் ராஜாவின் கையில் கொடுக்கும்போதும், அவன் அதை எகிப்துதேசத்தின்மேல் நீட்டும்போதும், நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
	<br /><br />
	26. நான் எகிப்தியரை ஜாதிகளுக்குள்ளே சிதறடித்து, அவர்களைத் தேசங்களில் தூற்றிப்போடுவேன்; அப்பொழுது நான் கர்த்தர் என்று அறிந்துகொள்வார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial30