import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 4 </title>
	<meta
          name="description"
          content="Song of Solomon 4 | உன்னதப்பாட்டு 4 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீ ரூபவதி, என் பிரியமே! நீ ரூபவதி; உன் முக்காட்டின் நடுவே உன் கண்கள் புறாக்கண்களாயிருக்கிறது; உன் கூந்தல் கீலேயாத் மலையில் தழைமேயும் வெள்ளாட்டு மந்தையைப்போலிருக்கிறது.
	<br /><br />
	2. உன் பற்கள், மயிர் கத்தரிக்கப்பட்டபின் குளிப்பாட்டப்பட்டுக் கரையேறுகிறவைகளும், ஒன்றாகிலும் மலடாயிராமல் எல்லாம் இரட்டைக்குட்டியீன்றவைகளுமான ஆட்டுமந்தையைப்போலிருக்கிறது.
	<br /><br />
	3. உன் உதடுகள் சிவப்புநூலுக்குச் சமானமும், உன் வாக்கு இன்பமுமாயிருக்கிறது; உன் முக்காட்டின் நடுவே உன் கன்னங்கள் வெடித்த மாதளம்பழம்போலிருக்கிறது.
	<br /><br />
	4. உன் கழுத்து, பராக்கிரமசாலிகளின் கேடகங்களாகிய ஆயிரம் பரிசைகள் தூக்கியிருக்கிற ஆயுதசாலையாக்கப்பட்ட தாவீதின் கோபுரம்போலிருக்கிறது.
	<br /><br />
	5. உன் இரண்டு ஸ்தனங்களும் லீலிபுஷ்பங்களில் மேயும் வெளிமானின் இரட்டைக்குட்டிகளுக்குச் சமானம்.
	<br /><br />
	6. பகல் குளிர்ச்சியாகி நிழல் சாய்ந்துபோகும்வரைக்கும், நான் வெள்ளைப்போளமலையிலும் சாம்பிராணிமலையிலும் போயிருப்பேன்.
	<br /><br />
	7. என் பிரியமே! நீ பூரண ரூபவதி; உன்னில் பழுதொன்றுமில்லை.
	<br /><br />
	8. லீபனோனிலிருந்து என்னோடே வா, என் மணவாளியே! லீபனோனிலிருந்து என்னோடே வா. அமனாவின் கொடுமுடியிலிருந்தும், சேனீர் எர்மோனின் கொடுமுடியிலிருந்தும், சிங்கங்களின் தாபரங்களிலிருந்தும், சிவிங்கிகளின் மலைகளிலிருந்தும் கீழே பார்.
	<br /><br />
	9. என் இருதயத்தைக் கவர்ந்துகொண்டாய்; என் சகோதரியே! என் மணவாளியே! உன் கண்களிலொன்றினாலும் உன் கழுத்திலுள்ள ஒரு சரப்பணியினாலும் என் இருதயத்தைக் கவர்ந்துகொண்டாய்.
	<br /><br />
	10. உன் நேசம் எவ்வளவு இன்பமாயிருக்கிறது; என் சகோதரியே! என் மணவாளியே! திராட்சரசத்தைப்பார்க்கிலும் உன் நேசம் எவ்வளவு மதுரமாயிருக்கிறது! சகல கந்தவர்க்கங்களைப்பார்க்கிலும் உன் பரிமளதைலங்கள் எவ்வளவு வாசனையாயிருக்கிறது!
	<br /><br />
	11. என் மணவாளியே! உன் உதடுகளிலிருந்து தேன் ஒழுகுகிறது, உன் நாவின்கீழ் தேனும் பாலும் இருக்கிறது, உன் வஸ்திரங்களின் வாசனை லீபனோனின் வாசனைக்கொப்பாயிருக்கிறது.
	<br /><br />
	12. என் சகோதரியே! என் மணவாளியே! நீ அடைக்கப்பட்ட தோட்டமும், மறைவு கட்டப்பட்ட நீரூற்றும், முத்திரிக்கப்பட்ட கிணறுமாயிருக்கிறாய்.
	<br /><br />
	13. உன் தோட்டம் மாதளஞ்செடிகளும், அருமையான கனிமரங்களும், மருதோன்றிச் செடிகளும், நளதச்செடிகளும்,
	<br /><br />
	14. நளதமும், குங்குமமும், வசம்பும், லவங்கமும், சகலவித தூபவர்க்க மரங்களும், வெள்ளைப்போளச்செடிகளும், சந்தன விருட்சங்களும், சகலவித கந்தவர்க்கச்செடிகளுமுள்ள சிங்காரவனமாயிருக்கிறது.
	<br /><br />
	15. தோட்டங்களுக்கு நீரூற்றும், ஜீவத்தண்ணீரின் துரவும், லீபனோனிலிருந்து ஓடிவரும் வாய்க்கால்களும் உண்டாயிருக்கிறது.
	<br /><br />
	16. வாடையே! எழும்பு; தென்றலே! வா; கந்தப்பிசின்கள் வடிய என் தோட்டத்தில் வீசு; என் நேசர் தம்முடைய தோட்டத்துக்கு வந்து, தமது அருமையான கனிகளைப் புசிப்பாராக.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs4