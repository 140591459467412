import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ecclesiastes5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Preacher | பிரசங்கி-5 </title>
	<meta
          name="description"
          content="Ecclesiastes 5 | பிரசங்கி 5 | Tamil Bible Preacher |
          Ecclesiastes Introduction | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நீ தேவாலயத்துக்குப் போகும்போது உன் நடையைக் காத்துக்கொள்; மூடர் பலியிடுவதுபோலப் பலியிடுவதைப்பார்க்கிலும் செவிகொடுக்கச் சேர்வதே நலம். தாங்கள் செய்கிறது தீமையென்று அறியாதிருக்கிறார்கள்.
	<br /><br />
	2. தேவசமுகத்தில் நீ துணிகரமாய் உன் வாயினால் பேசாமலும், மனம்பதறி ஒரு வார்த்தையையும் சொல்லாமலும் இரு; தேவன் வானத்திலிருக்கிறார்; நீ பூமியிலிருக்கிறாய், ஆதலால் உன் வார்த்தைகள் சுருக்கமாயிருப்பதாக.
	<br /><br />
	3. தொல்லையின் திரட்சியினால் சொப்பனம் பிறக்கிறதுபோல, வார்த்தைகளின் திரட்சியினால் மூடனுடைய சத்தம் பிறக்கும்.
	<br /><br />
	4. நீ தேவனுக்கு ஒரு பொருத்தனை பண்ணிக்கொண்டால், அதைச் செலுத்தத் தாமதியாதே; அவர் மூடரில் பிரியப்படுகிறதில்லை; நீ நேர்ந்துகொண்டதைச் செய்.
	<br /><br />
	5. நீ நேர்ந்துகொண்டதைச் செய்யாமற்போவதைப்பார்க்கிலும், நேர்ந்துகொள்ளாதிருப்பதே நலம்.
	<br /><br />
	6. உன் மாம்சத்தைப் பாவத்துக்குள்ளாக்க உன் வாய்க்கு இடங்கொடாதே; அது புத்திபிசகினால் செய்தது என்று தூதனுக்குமுன் சொல்லாதே; தேவன் உன் வார்த்தைகளினாலே கோபங்கொண்டு, உன் கைகளின் கிரியையை அழிப்பானேன்?
	<br /><br />
	7. அநேக சொப்பனங்கள் மாயையாயிருப்பதுபோல, அநேக வார்த்தைகளும் வியர்த்தமாயிருக்கும்; ஆகையால் நீ தேவனுக்குப் பயந்திரு.
	<br /><br />
	8. ஒரு தேசத்தில் ஏழைகள் ஒடுக்கப்படுகிறதையும், நியாயமும் நீதியும் புரட்டப்படுகிறதையும் நீ காண்பாயானால், அதைக்குறித்து ஆச்சரியப்படாதே; உயர்ந்தவன்மேல் உயர்ந்தவன் காவலாளியாயிருக்கிறான்; அவர்கள்மேல் உயர்ந்தவரும் ஒருவருண்டு.
	<br /><br />
	9. பூமியில் விளையும் பலன் யாவருக்குமுரியது; ராஜாவும் வயலின் பலனால் ஆதரிக்கப்படுகிறான்.
	<br /><br />
	10. பணப்பிரியன் பணத்தினால் திருப்தியடைவதில்லை; செல்வப்பிரியன் செல்வப்பெருக்கினால் திருப்தியடைவதில்லை; இதுவும் மாயையே.
	<br /><br />
	11. பொருள் பெருகினால் அதைத் தின்கிறவர்களும் பெருகுகிறார்கள்; அதை உடையவர்கள் தங்கள் கண்களினால் அதைக் காண்பதேயன்றி அவர்களுக்குப் பிரயோஜனம் என்ன?
	<br /><br />
	12. வேலைசெய்கிறவன் கொஞ்சமாய்ப் புசித்தாலும் அதிகமாய்ப் புசித்தாலும் அவன் நித்திரை இன்பமாயிருக்கும்; செல்வனுடைய பெருக்கோ அவனைத் தூங்கவொட்டாது.
	<br /><br />
	13. சூரியனுக்குக் கீழே நான் கண்ட வேறொரு கொடிய தீங்குமுண்டு; அதாவது, ஐசுவரியமானது அதை உடையவர்களுக்கே கேடுண்டாகும்படி சேகரித்து வைக்கப்படுவதாம்.
	<br /><br />
	14. அந்த ஐசுவரியம் விக்கினத்தால் அழிந்துபோகிறது; அவன் ஒரு புத்திரனைப் பெறுகிறான், அவன் கையில் யாதொன்றும் இல்லை.
	<br /><br />
	15. தன் தாயின் கர்ப்பத்திலிருந்து நிர்வாணியாய் வந்தான்; வந்ததுபோலவே நிர்வாணியாய்த் திரும்பப்போவான்; அவன் தன் பிரயாசத்தினால் உண்டான பலனொன்றையும் தன் கையிலே எடுத்துக்கொண்டுபோவதில்லை.
	<br /><br />
	16. அவன் வந்தபிரகாரமே போகிறான், இதுவும் கொடுமையான தீங்கு; அவன் காற்றுக்குப் பிரயாசப்பட்டதினால் அவனுக்கு லாபம் என்ன?
	<br /><br />
	17. அவன் தன் நாட்களிலெல்லாம் இருளிலே புசித்து, மிகவும் சலித்து, நோயும் துன்பமும் அடைகிறான்.
	<br /><br />
	18. இதோ, உயிரோடிருக்கும்படி தேவன் அருளிச்செய்த நாளெல்லாம் மனுஷன் புசித்துக் குடித்து, சூரியனுக்குக் கீழே தான் படும் பிரயாசம் அனைத்தின் பலனையும் அநுபவிப்பதே நலமும் உத்தமுமான காரியமென்று நான் கண்டேன், இதுவே அவன் பங்கு.
	<br /><br />
	19. தேவன் ஐசுவரியத்தையும் சம்பத்தையும் எவனுக்குக் கொடுத்திருக்கிறாரோ, அவன் அதிலே புசிக்கவும், தன் பங்கைப் பெறவும், தன் பிரயாசத்திலே மகிழ்ச்சியாயிருக்கவும் அவனுக்கு அதிகாரம் அளிப்பது தேவனுடைய அநுக்கிரகம்.
	<br /><br />
	20. அவனுடைய இருதயத்திலே மகிழும்படி தேவன் அவனுக்கு அநுக்கிரகம் பண்ணுகிறபடியினால், அவன் தன் ஜீவனுள்ள நாட்களை அதிகமாய் நினையான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ecclesiastes5