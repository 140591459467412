import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Colossians2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title>Book of Colossians Bible in Tamil | கொலோசெயர் 2 </title>
	<meta
          name="description"
          content="Colossians 2 | கொலோசெயர் 2 |
          Book of Colossians | Tamil Bible Verse | Tamil Bible | கொலோசெயருக்கு எழுதின நிருபம் | Bible Verse | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}

      <p>
	1. உங்களுக்காகவும் லவோதிக்கேயாவிலிருக்கிறவர்களுக்காகவும், சரீரத்தில் என் முகத்தைக் காணாதிருக்கிற மற்றெல்லாருக்காகவும் மிகுந்த போராட்டம் எனக்கு உண்டென்று நீங்கள் அறிய விரும்புகிறேன்.
	<br /><br />
	2. அவர்களுடைய இருதயங்கள் தேற்றப்பட்டு, அவர்கள் அன்பினால் இணைக்கப்பட்டு, பிதாவாகிய தேவனுக்கும் கிறிஸ்துவுக்கும் உரிய இரகசியத்தை அறிந்துகொள்ளுகிற உணர்வின் பூரண நிச்சயத்தினுடைய எல்லா ஐசுவரியத்திற்கும் உரியவர்களாகவேண்டுமென்றே இப்படி விரும்புகிறேன்.
	<br /><br />
	3. அவருக்குள் ஞானம் அறிவு என்பவைகளாகிய பொக்கிஷங்களெல்லாம் அடங்கியிருக்கிறது.
	<br /><br />
	4. ஒருவனும் நயவசனிப்பினாலே உங்களை வஞ்சியாதபடிக்கு இதைச் சொல்லுகிறேன்.
	<br /><br />
	5. சரீரத்தின்படி நான் தூரமாயிருந்தும், ஆவியின்படி உங்களுடனேகூட இருந்து, உங்கள் ஒழுங்கையும், கிறிஸ்துவின்மேலுள்ள உங்கள் விசுவாசத்தின் உறுதியையும் பார்த்துச் சந்தோஷப்படுகிறேன்.
	<br /><br />
	6. ஆகையால், நீங்கள் கர்த்தராகிய கிறிஸ்து இயேசுவை ஏற்றுக்கொண்டபடியே, அவருக்குள் வேர்கொண்டவர்களாகவும், அவர்மேல் கட்டப்பட்டவர்களாகவும், அவருக்குள் நடந்துகொண்டு,
	<br /><br />
	7. நீங்கள் போதிக்கப்பட்டபடியே, விசுவாசத்தில் உறுதிப்பட்டு, ஸ்தோத்திரத்தோடே அதிலே பெருகுவீர்களாக.
	<br /><br />
	8. லௌகிக ஞானத்தினாலும், மாயமான தந்திரத்தினாலும், ஒருவனும் உங்களைக் கொள்ளைகொண்டுபோகாதபடிக்கு எச்சரிக்கையாயிருங்கள்; அது மனுஷர்களின் பாரம்பரிய நியாயத்தையும் உலகவழிபாடுகளையும் பற்றினதேயல்லாமல் கிறிஸ்துவைப் பற்றினதல்ல.
	<br /><br />
	9. ஏனென்றால், தேவத்துவத்தின் பரிபூரணமெல்லாம் சரீரப்பிரகாரமாக அவருக்குள் வாசமாயிருக்கிறது.
	<br /><br />
	10. மேலும் சகல துரைத்தனங்களுக்கும் அதிகாரத்துக்கும் தலைவராயிருக்கிற அவருக்குள் நீங்கள் பரிபூரணமுள்ளவர்களாயிருக்கிறீர்கள்.
	<br /><br />
	11. அல்லாமலும், நீங்கள் கிறிஸ்துவைப்பற்றும் விருத்தசேதனத்தினாலே மாம்சத்துக்குரிய பாவசரீரத்தைக் களைந்துவிட்டதினால், கையால் செய்யப்படாத விருத்தசேதனத்தை அவருக்குள் பெற்றீர்கள்.
	<br /><br />
	12. ஞானஸ்நானத்திலே அவரோடேகூட அடக்கம்பண்ணப்பட்டவர்களாகவும், அதிலே அவரை மரித்தோரிலிருந்தெழுப்பின தேவனுடைய செயலின்மேலுள்ள விசுவாசத்தினாலே அவரோடேகூட எழுந்தவர்களாகவும் இருக்கிறீர்கள்.
	<br /><br />
	13. உங்கள் பாவங்களினாலேயும், உங்கள் மாம்ச விருத்தசேதனமில்லாமையினாலேயும் மரித்தவர்களாயிருந்த உங்களையும் அவரோடேகூட உயிர்ப்பித்து, அக்கிரமங்களெல்லாவற்றையும் உங்களுக்கு மன்னித்து;
	<br /><br />
	14. நமக்கு எதிரிடையாகவும் கட்டளைகளால் நமக்கு விரோதமாகவும் இருந்த கையெழுத்தைக் குலைத்து, அதை நடுவிலிராதபடிக்கு எடுத்து, சிலுவையின்மேல் ஆணியடித்து;
	<br /><br />
	15. துரைத்தனங்களையும் அதிகாரங்களையும் உரிந்துகொண்டு, வெளியரங்கமான கோலமாக்கி, அவைகளின்மேல் சிலுவையிலே வெற்றிசிறந்தார்.
	<br /><br />
	16. ஆகையால், போஜனத்தையும் பானத்தையும் குறித்தாவது, பண்டிகைநாளையும் மாதப்பிறப்பையும் ஓய்வுநாட்களையுங்குறித்தாவது, ஒருவனும் உங்களைக் குற்றப்படுத்தாதிருப்பானாக.
	<br /><br />
	17. அவைகள் வருங்காரியங்களுக்கு நிழலாயிருக்கிறது; அவைகளின் பொருள் கிறிஸ்துவைப்பற்றினது.
	<br /><br />
	18. கணுக்களாலும் கட்டுகளாலும் உதவிபெற்று இணைக்கப்பட்டு, தேவவளர்ச்சியாய் வளர்ந்தேறுகிற சரீரமுழுவதையும் ஆதரிக்கிற தலையைப் பற்றிக்கொள்ளாமல்,
	<br /><br />
	19. மாயமான தாழ்மையிலும், தேவதூதர்களுக்குச் செய்யும் ஆராதனையிலும் விருப்பமுற்று, காணாதவைகளிலே துணிவாய் நுழைந்து, தன் மாம்சசிந்தையினாலே வீணாய் இறுமாப்புக்கொண்டிருக்கிற எவனும் உங்கள் பந்தயப்பொருளை நீங்கள் இழந்துபோகும்படி உங்களை வஞ்சியாதிருக்கப்பாருங்கள்.
	<br /><br />
	20. நீங்கள் கிறிஸ்துவுடனேகூட உலகத்தின் வழிபாடுகளுக்கு மரித்ததுண்டானால், இன்னும் உலக வழக்கத்தின்படி பிழைக்கிறவர்கள்போல,
	<br /><br />
	21. மனுஷருடைய கற்பனைகளின்படியும் போதனைகளின்படியும் நடந்து: தொடாதே, ருசிபாராதே, தீண்டாதே என்கிற கட்டளைகளுக்கு உட்படுகிறதென்ன?
	<br /><br />
	22. இவையெல்லாம் அநுபவிக்கிறதினால் அழிந்துபோகுமே.
	<br /><br />
	23. இப்படிப்பட்ட போதனைகள் சுய இஷ்டமான ஆராதனையையும், மாயமான தாழ்மையையும், சரீர ஒடுக்கத்தையும்பற்றி ஞானமென்கிற பேர்கொண்டிருந்தாலும், இவைகள் மாம்சத்தைப் பேணுகிறதற்கே ஒழிய மற்றொன்றிற்கும் பிரயோஜனப்படாது.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Colossians2