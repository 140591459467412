import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Luke4 = () => {
  const verseNumber = 4;

  
  return (
    <>
    <Helmet>
	<title>Gospel According to Luke in Tamil Bible | லூக்கா 4 </title>
	<meta
          name="description"
          content="Luke 4 | லூக்கா 4 | Tamil Bible Story |
          Gospel According to Luke | Tamil Bible Verse | Tamil Bible | லூக்கா எழுதின சுவிசேஷம் | TamilVerse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. இயேசு பரிசுத்த ஆவியினாலே நிறைந்தவராய் யோர்தானை விட்டுத் திரும்பி, ஆவியானவராலே வனாந்தரத்திற்குக் கொண்டுபோகப்பட்டு,
	<br /><br />
	2. நாற்பதுநாள் பிசாசினால் சோதிக்கப்பட்டார். அந்த நாட்களில் அவர் ஒன்றும் புசியாதிருந்தார்; அந்த நாட்கள் முடிந்தபின்பு அவருக்குப் பசியுண்டாயிற்று.
	<br /><br />
	3. அப்பொழுது பிசாசு அவரை நோக்கி: நீர் தேவனுடைய குமாரனேயானால், இந்தக் கல் அப்பமாகும்படி சொல்லும் என்றான்.
	<br /><br />
	4. அவர் பிரதியுத்தரமாக: மனுஷன் அப்பத்தினாலேமாத்திரமல்ல, தேவனுடைய ஒவ்வொரு வார்த்தையினாலும் பிழைப்பான் என்று எழுதியிருக்கிறதே என்றார்.
	<br /><br />
	5. பின்பு பிசாசு அவரை உயர்ந்த மலையின்மேல் கொண்டுபோய், உலகத்தின் சகல ராஜ்யங்களையும் ஒரு நிமிஷத்திலே அவருக்குக் காண்பித்து:
	<br /><br />
	6. இவைகள் எல்லாவற்றின்மேலுமுள்ள அதிகாரத்தையும் இவைகளின் மகிமையையும் உமக்குத் தருவேன், இவைகள் எனக்கு ஒப்புக்கொடுக்கப்பட்டிருக்கிறது; எனக்கு இஷ்டமானவனுக்கு இவைகளைக் கொடுக்கிறேன்.
	<br /><br />
	7. நீர் என்னைப் பணிந்துகொண்டால் எல்லாம் உம்முடையதாகும் என்று சொன்னான்.
	<br /><br />
	8. இயேசு அவனுக்குப் பிரதியுத்தரமாக: எனக்குப் பின்னாகப்போ சாத்தானே, உன் தேவனாகிய கர்த்தரைப் பணிந்துகொண்டு, அவர் ஒருவருக்கே ஆராதனைசெய்வாயாக என்று எழுதியிருக்கிறதே என்றார்.
	<br /><br />
	9. அப்பொழுது அவன் அவரை எருசலேமுக்குக் கொண்டுபோய், தேவாலயத்து உப்பரிகையின்மேல் அவரை நிறுத்தி: நீர் தேவனுடைய குமாரனேயானால், இங்கேயிருந்து தாழக்குதியும்.
	<br /><br />
	10. ஏனெனில், உம்மைக் காக்கும்படிக்குத் தம்முடைய தூதர்களுக்கு உம்மைக்குறித்துக் கட்டளையிடுவார் என்றும்,
	<br /><br />
	11. உமது பாதம் கல்லில் இடறாதபடிக்கு, அவர்கள் உம்மைக் கைகளில் ஏந்திக்கொண்டுபோவார்கள் என்றும், எழுதியிருக்கிறது என்று சொன்னான்.
	<br /><br />
	12. அதற்கு இயேசு: உன் தேவனாகிய கர்த்தரைப் பரீட்சை பாராதிருப்பாயாக என்று சொல்லியிருக்கிறதே என்றார்.
	<br /><br />
	13. பிசாசானவன் சோதனையெல்லாம் முடித்தபின்பு, சிலகாலம் அவரை விட்டு விலகிப்போனான்.
	<br /><br />
	14. பின்பு இயேசு ஆவியானவருடைய பலத்தினாலே கலிலேயாவுக்குத் திரும்பிப் போனார். அவருடைய கீர்த்தி சுற்றிலும் இருக்கிற தேசமெங்கும் பரம்பிற்று.
	<br /><br />
	15. அவர்களுடைய ஜெபஆலயங்களில் அவர் உபதேசித்து, எல்லாராலும் புகழப்பட்டார்.
	<br /><br />
	16. தாம் வளர்ந்த ஊராகிய நாசரேத்துக்கு அவர் வந்து, தம்முடைய வழக்கத்தின்படியே ஓய்வுநாளில் ஜெபஆலயத்திலே பிரவேசித்து, வாசிக்க எழுந்து நின்றார்.
	<br /><br />
	17. அப்பொழுது ஏசாயா தீர்க்கதரிசியின் புஸ்தகம் அவரிடத்தில் கொடுக்கப்பட்டது. அவர் புஸ்தகத்தை விரித்தபோது:
	<br /><br />
	18. கர்த்தருடைய ஆவியானவர் என்மேலிருக்கிறார்; தரித்திரருக்குச் சுவிசேஷத்தைப் பிரசங்கிக்கும்படி என்னை அபிஷேகம் பண்ணினார்; இருதயம் நருங்குண்டவர்களைக் குணமாக்கவும், சிறைப்பட்டவர்களுக்கு விடுதலையையும், குருடருக்குப் பார்வையையும் பிரசித்தப்படுத்தவும், நொறுங்குண்டவர்களை விடுதலையாக்கவும்,
	<br /><br />
	19. கர்த்தருடைய அநுக்கிரக வருஷத்தைப் பிரசித்தப்படுத்தவும், என்னை அனுப்பினார், என்று எழுதியிருக்கிற இடத்தை அவர் கண்டு,
	<br /><br />
	20. வாசித்து, புஸ்தகத்தைச் சுருட்டி, பணிவிடைக்காரனிடத்தில் கொடுத்து, உட்கார்ந்தார். ஜெபஆலயத்திலுள்ள எல்லாருடைய கண்களும் அவர்மேல் நோக்கமாயிருந்தது.
	<br /><br />
	21. அப்பொழுது அவர் அவர்களோடே பேசத்தொடங்கி: உங்கள் காதுகள் கேட்க இந்த வேதவாக்கியம் இன்றையத்தினம் நிறைவேறிற்று என்றார்.
	<br /><br />
	22. எல்லாரும் அவருக்கு நற்சாட்சி கொடுத்து, அவருடைய வாயிலிருந்து புறப்பட்ட கிருபையுள்ள வார்த்தைகளைக் குறித்து ஆச்சரியப்பட்டு: இவன் யோசேப்பின் குமாரன் அல்லவா என்றார்கள்.
	<br /><br />
	23. அவர் அவர்களை நோக்கி: வைத்தியனே, உன்னைத்தானே குணமாக்கிக்கொள் என்கிற பழமொழியைச் சொல்லி, நாங்கள் கேள்விப்பட்டபடி கப்பர்நகூமூரில் உன்னால் செய்யப்பட்ட கிரியைகள் எவைகளோ அவைகளை உன் ஊராகிய இவ்விடத்திலும் செய் என்று நீங்கள் என்னுடன் சொல்லுவீர்கள் என்பது நிச்சயம்.
	<br /><br />
	24. ஆனாலும் தீர்க்கதரிசி ஒருவனும் தன் ஊரிலே அங்கீகரிக்கப்படமாட்டான் என்று மெய்யாகவே உங்களுக்குச் சொல்லுகிறேன்.
	<br /><br />
	25. அன்றியும் எலியாவின் நாட்களிலே மூன்று வருஷமும் ஆறுமாதமும் வானம் அடைபட்டு, தேசமெங்கும் மிகுந்த பஞ்சம் உண்டாயிருந்தபோது, இஸ்ரவேலருக்குள் அநேகம் விதவைகள் இருந்தார்கள்.
	<br /><br />
	26. ஆயினும் எலியா சீதோன் நாட்டிலுள்ள சரெப்தா (சாறிபாத்) ஊரிலிருந்த ஒரு விதவையினிடத்திற்கு அனுப்பப்பட்டானேயல்லாமல் மற்றொருத்தியினிடத்திற்கும் அனுப்பப்படவில்லை.
	<br /><br />
	27. அல்லாமலும் எலிசா தீர்க்கதரிசியின் காலத்திலே இஸ்ரவேலருக்குள்ளே அநேகம் குஷ்டரோகிகள் இருந்தார்கள்; ஆயினும் சீரியா தேசத்தானாகிய நாகமானேயல்லாமல் அவர்களில் வேறொருவனும் சுத்தமாக்கப்படவில்லை என்று சத்தியத்தின்படியே உங்களுக்குச் சொல்லுகிறேன் என்றார்.
	<br /><br />
	28. ஜெபஆலயத்திலிருந்த எல்லாரும், இவைகளைக் கேட்டபொழுது, கோபமூண்டு,
	<br /><br />
	29. எழுந்திருந்து, அவரை ஊருக்குப் புறம்பே தள்ளி, தங்கள் ஊர் கட்டப்பட்டிருந்த செங்குத்தான மலையின் சிகரத்திலிருந்து அவரைத் தலைகீழாய்த் தள்ளிவிடும்படிக்கு அவ்விடத்திற்குக் கொண்டுபோனார்கள்.
	<br /><br />
	30. அவரோ அவர்கள் நடுவினின்று கடந்துபோய்விட்டார்.
	<br /><br />
	31. பின்பு அவர் கலிலேயாவிலுள்ள கப்பர்நகூம் பட்டணத்துக்கு வந்து, ஓய்வுநாட்களில் ஜனங்களுக்குப் போதகம் பண்ணினார்.
	<br /><br />
	32. அவருடைய வசனம் அதிகாரமுள்ளதாயிருந்தபடியால் அவருடைய போதகத்தைக்குறித்து அவர்கள் ஆச்சரியப்பட்டார்கள்.
	<br /><br />
	33. ஜெபஆலயத்திலே அசுத்த ஆவி பிடித்திருந்த ஒரு மனுஷன் இருந்தான்.
	<br /><br />
	34. அவன்: ஐயோ! நசரேயனாகிய இயேசுவே, எங்களுக்கும் உமக்கும் என்ன? எங்களைக் கெடுக்கவா வந்தீர்? உம்மை இன்னார் என்று அறிவேன்; நீர் தேவனுடைய பரிசுத்தர் என்று உரத்தசத்தமிட்டான்.
	<br /><br />
	35. அதற்கு இயேசு: நீ பேசாமல் இவனை விட்டுப் புறப்பட்டுப் போ என்று அதை அதட்டினார்; அப்பொழுது பிசாசு அவனை ஜனங்களின் நடுவே விழத்தள்ளி, அவனுக்கு ஒரு சேதமுஞ்செய்யாமல், அவனை விட்டுப் போய்விட்டது.
	<br /><br />
	36. எல்லாரும் ஆச்சரியப்பட்டு: இது என்ன வார்த்தையோ! அதிகாரத்தோடும் வல்லமையோடும் அசுத்த ஆவிகளுக்கும் கட்டளையிடுகிறார், அவைகள் புறப்பட்டுப்போகிறதே என்று ஒருவரோடொருவர் பேசிக்கொண்டார்கள்.
	<br /><br />
	37. அவருடைய கீர்த்தி சுற்றிலுமிருந்த நாடுகளிலுள்ள இடங்களிலெல்லாம் பிரசித்தமாயிற்று.
	<br /><br />
	38. பின்பு அவர் ஜெபஆலயத்தை விட்டுப் புறப்பட்டு, சீமோன் வீட்டில் பிரவேசித்தார், சீமோனுடைய மாமி கடும் ஜுரமாய்க் கிடந்தாள். அவளுக்காக அவரை வேண்டிக்கொண்டார்கள்.
	<br /><br />
	39. அவர் அவளிடத்தில் குனிந்துநின்று, ஜுரம் நீங்கும்படி கட்டளையிட்டார், அது அவளை விட்டு நீங்கிற்று; உடனே அவள் எழுந்திருந்து அவர்களுக்குப் பணிவிடை செய்தாள்.
	<br /><br />
	40. சூரியன் அஸ்தமித்தபோது, ஜனங்களெல்லாரும் தங்களுக்குள்ளே பலபல வியாதிகளால் வருத்தப்பட்டவர்களை அவரிடத்தில் கொண்டுவந்தார்கள். அவர்கள் ஒவ்வொருவர்மேலும் அவர் தம்முடைய கைகளை வைத்து, அவர்களைச் சொஸ்தமாக்கினார்.
	<br /><br />
	41. பிசாசுகளும்: நீர் தேவனுடைய குமாரனாகிய கிறிஸ்து என்று சத்தமிட்டு, அநேகரைவிட்டுப் புறப்பட்டது. அவரைக் கிறிஸ்து என்று பிசாசுகள் அறிந்திருந்தபடியால் அவர் அவைகளைப் பேசவொட்டாமல் அதட்டினார்.
	<br /><br />
	42. உதயமானபோது, அவர் புறப்பட்டு, வனாந்தரமான ஓரிடத்திற்குப் போனார். திரளான ஜனங்கள் அவரைத் தேடி, அவரிடத்தில் வந்து, தங்களை விட்டுப் போகாதபடிக்கு அவரை நிறுத்திக்கொண்டார்கள்.
	<br /><br />
	43. அவரோ அவர்களை நோக்கி: நான் மற்ற ஊர்களிலும் தேவனுடைய ராஜ்யத்தைக்குறித்துப் பிரசங்கிக்கவேண்டும், இதற்காகவே அனுப்பப்பட்டேன் என்றார்.
	<br /><br />
	44. அந்தப்படியே கலிலேயா நாட்டிலுள்ள ஜெபஆலயங்களில் பிரசங்கம் பண்ணிக்கொண்டுவந்தார்.
</p>

      {/* End of verse */}  
      </div>
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <h2>
	Jesus Is Tested in the Wilderness
</h2>
<p>
	<br />
	1 Jesus, full of the Holy Spirit, left the Jordan and was led by the Spirit into the wilderness,
	<br />
	2 where for forty days he was tempted by the devil. He ate nothing during those days, and at the end of them he was hungry.
	<br />
	3 The devil said to him, &ldquo;If you are the Son of God, tell this stone to become bread.&rdquo;
	<br />
	4 Jesus answered, &ldquo;It is written: &lsquo;Man shall not live on bread alone.&rsquo; &rdquo;
	<br />
	5 The devil led him up to a high place and showed him in an instant all the kingdoms of the world.
	<br />
	6 And he said to him, &ldquo;I will give you all their authority and splendor; it has been given to me, and I can give it to anyone I want to.
	<br />
	7 If you worship me, it will all be yours.&rdquo;
	<br />
	8 Jesus answered, &ldquo;It is written: &lsquo;Worship the Lord your God and serve him only.&rsquo; &rdquo;
	<br />
	9 The devil led him to Jerusalem and had him stand on the highest point of the temple. &ldquo;If you are the Son of God,&rdquo; he said, &ldquo;throw yourself down from here.
	<br />
	10 For it is written: &ldquo; &lsquo;He will command his angels concerning you to guard you carefully;
	<br />
	11 they will lift you up in their hands, so that you will not strike your foot against a stone.&rsquo; &rdquo;
	<br />
	12 Jesus answered, &ldquo;It is said: &lsquo;Do not put the Lord your God to the test.&rsquo; &rdquo;
	<br />
	13 When the devil had finished all this tempting, he left him until an opportune time.
</p>
<h2>
	<br />
	Jesus Rejected at Nazareth
</h2>
<p>
	<br />
	14 Jesus returned to Galilee in the power of the Spirit, and news about him spread through the whole countryside.
	<br />
	15 He was teaching in their synagogues, and everyone praised him.
	<br />
	16 He went to Nazareth, where he had been brought up, and on the Sabbath day he went into the synagogue, as was his custom. He stood up to read,
	<br />
	17 and the scroll of the prophet Isaiah was handed to him. Unrolling it, he found the place where it is written:
	<br />
	18 &ldquo;The Spirit of the Lord is on me, because he has anointed me to proclaim good news to the poor. He has sent me to proclaim freedom for the prisoners and recovery of sight for the blind, to set the oppressed free,
	<br />
	19 to proclaim the year of the Lord&rsquo;s favor.&rdquo;
	<br />
	20 Then he rolled up the scroll, gave it back to the attendant and sat down. The eyes of everyone in the synagogue were fastened on him.
	<br />
	21 He began by saying to them, &ldquo;Today this scripture is fulfilled in your hearing.&rdquo;
	<br />
	22 All spoke well of him and were amazed at the gracious words that came from his lips. &ldquo;Isn&rsquo;t this Joseph&rsquo;s son?&rdquo; they asked.
	<br />
	23 Jesus said to them, &ldquo;Surely you will quote this proverb to me: &lsquo;Physician, heal yourself!&rsquo; And you will tell me, &lsquo;Do here in your hometown what we have heard that you did in Capernaum.&rsquo; &rdquo;
	<br />
	24 &ldquo;Truly I tell you,&rdquo; he continued, &ldquo;no prophet is accepted in his hometown.
	<br />
	25 I assure you that there were many widows in Israel in Elijah&rsquo;s time, when the sky was shut for three and a half years and there was a severe famine throughout the land.
	<br />
	26 Yet Elijah was not sent to any of them, but to a widow in Zarephath in the region of Sidon.
	<br />
	27 And there were many in Israel with leprosy in the time of Elisha the prophet, yet not one of them was cleansed&mdash;only Naaman the Syrian.&rdquo;
	<br />
	28 All the people in the synagogue were furious when they heard this.
	<br />
	29 They got up, drove him out of the town, and took him to the brow of the hill on which the town was built, in order to throw him off the cliff.
	<br />
	30 But he walked right through the crowd and went on his way.
</p>
<h2>
	<br />
	Jesus Drives Out an Impure Spirit
</h2>
<p>
	<br />
	31 Then he went down to Capernaum, a town in Galilee, and on the Sabbath he taught the people.
	<br />
	32 They were amazed at his teaching, because his words had authority.
	<br />
	33 In the synagogue there was a man possessed by a demon, an impure spirit. He cried out at the top of his voice,
	<br />
	34 &ldquo;Go away! What do you want with us, Jesus of Nazareth? Have you come to destroy us? I know who you are&mdash;the Holy One of God!&rdquo;
	<br />
	35 &ldquo;Be quiet!&rdquo; Jesus said sternly. &ldquo;Come out of him!&rdquo; Then the demon threw the man down before them all and came out without injuring him.
	<br />
	36 All the people were amazed and said to each other, &ldquo;What words these are! With authority and power he gives orders to impure spirits and they come out!&rdquo;
	<br />
	37 And the news about him spread throughout the surrounding area.
	<br />
	Jesus Heals Many
	<br />
	38 Jesus left the synagogue and went to the home of Simon. Now Simon&rsquo;s mother-in-law was suffering from a high fever, and they asked Jesus to help her.
	<br />
	39 So he bent over her and rebuked the fever, and it left her. She got up at once and began to wait on them.
	<br />
	40 At sunset, the people brought to Jesus all who had various kinds of sickness, and laying his hands on each one, he healed them.
	<br />
	41 Moreover, demons came out of many people, shouting, &ldquo;You are the Son of God!&rdquo; But he rebuked them and would not allow them to speak, because they knew he was the Messiah.
	<br />
	42 At daybreak, Jesus went out to a solitary place. The people were looking for him and when they came to where he was, they tried to keep him from leaving them.
	<br />
	43 But he said, &ldquo;I must proclaim the good news of the kingdom of God to the other towns also, because that is why I was sent.&rdquo;
	<br />
	44 And he kept on preaching in the synagogues of Judea.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Luke4