import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Ezekial5 = () => {
  const verseNumber = 5;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Library | எசேக்கியேல் 5 </title>
	<meta
          name="description"
          content="Ezekiel 5 | எசேக்கியேல்  5 |
          Ezekiel 5 | Tamil Bible Library | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. பின்னும் அவர்: மனுபுத்திரனே, சவரகன் கத்தியாகிய கருக்கான கத்தியை வாங்கி, அதினால் உன் தலையையும் உன் தாடியையும் சிரைத்துக்கொண்டு, பின்பு நிறுக்கும் தராசை எடுத்து, அந்த மயிரைப் பங்கிடக்கடவாய்.
	<br /><br />
	2. மூன்றில் ஒரு பங்கை எடுத்து முற்றிக்கைபோடும் நாட்கள் முடிகிறபோது நகரத்தின் நடுவிலே அக்கினியால் சுட்டெரித்து, மூன்றில் ஒரு பங்கை எடுத்து, அதைச் சுற்றிலும் கத்தியாலே வெட்டி, மூன்றில் ஒரு பங்கை எடுத்துக் காற்றிலே தூற்றக்கடவாய்; அவைகளின் பின்னாக நான் பட்டயத்தை உருவுவேன்.
	<br /><br />
	3. அதில் கொஞ்சம்மாத்திரம் எடுத்து, அதை உன் வஸ்திரத்தோரங்களில் முடிந்துவைப்பாயாக.
	<br /><br />
	4. பின்னும் அதில் கொஞ்சம் எடுத்து, அதைத் தீயின் நடுவில் எறிந்து, அதை அக்கினியால் சுட்டெரிப்பாயாக; அதிலிருந்து இஸ்ரவேல் வம்சத்தார் அனைவருக்கும் விரோதமாக அக்கினி புறப்படும்.
	<br /><br />
	5. கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், இதுவே எருசலேம், புறஜாதிகளின் நடுவிலே நான் அதை வைத்தேன், அதைச் சுற்றிலும் தேசங்கள் இருக்கிறது.
	<br /><br />
	6. அது புறஜாதிகளைப்பார்க்கிலும் என் நியாயங்களையும், தன்னைச் சுற்றிலும் இருக்கிற தேசங்களைப்பார்க்கிலும் என் கட்டளைகளையும் அக்கிரமமாக மாற்றிப்போட்டது; அவர்கள் என் நியாயங்களை வெறுத்து, என் கட்டளைகளில் நடவாமற் போனார்கள்.
	<br /><br />
	7. ஆகையால் கர்த்தராகிய ஆண்டவர் சொல்லுகிறது என்னவென்றால், உங்களைச் சுற்றிலும் இருக்கிற புறஜாதிகளைப்பார்க்கிலும் அதிகரிக்கிறவர்களாகிய நீங்கள் என் கட்டளைகளிலே நடவாமலும், என் நியாயங்களின்படி செய்யாமலும், உங்களைச் சுற்றிலும் இருக்கிற புறஜாதிகளுடைய நீதிநியாயங்களின்படியாவது நடவாமலும் போனபடியினாலே,
	<br /><br />
	8. இதோ, நான், நானே உனக்கு விரோதமாக வந்து, புறஜாதிகளுடைய கண்களுக்கு முன்பாக உன் நடுவிலே நீதி செலுத்தி,
	<br /><br />
	9. நான் முன்பு செய்யாததும் இனிச்செய்யாதிருப்பதுமானவிதமாய் உனக்கு உன் எல்லா அருவருப்புகளினிமித்தமும் செய்வேன்.
	<br /><br />
	10. ஆதலால் உன் நடுவிலே பிதாக்கள் பிள்ளைகளைத் தின்பார்கள்; பிள்ளைகள் பிதாக்களைத் தின்பார்கள்; நான் உன்னில் நீதிசெலுத்தி உன்னில் மீதியாயிருப்பவர்களையெல்லாம் சகல திசைகளிலும் சிதறிப்போகப்பண்ணுவேன் என்று கர்த்தராகிய ஆண்டவர் சொல்லுகிறார்.
	<br /><br />
	11. ஆதலால், சீயென்றிகழப்படத்தக்கதும் அருவருக்கப்படத்தக்கதுமான உன் கிரியைகளால் நீ என் பரிசுத்த ஸ்தலத்தைத் தீட்டுப்படுத்தினபடியால் என் கண் உன்னைத் தப்பவிடாது, நான் உன்னைக் குறுகிப்போகப்பண்ணுவேன், நான் இரங்கமாட்டேன், இதை என் ஜீவனைக்கொண்டு சொல்லுகிறேன் என்று கர்த்தராகிய ஆண்டவர் உரைக்கிறார்.
	<br /><br />
	12. உன்னிலே மூன்றில் ஒரு பங்கு கொள்ளைநோயால் சாவார்கள், பஞ்சத்தாலும் உன் நடுவிலே மடிந்துபோவார்கள்; மூன்றில் ஒரு பங்கு உன்னைச் சுற்றிலும் இருக்கிற பட்டயத்தால் வெட்டுண்டு விழுவார்கள்; மூன்றில் ஒரு பங்கை நான் சகல திசைகளிலும் சிதறிப்போகப்பண்ணி, அவர்கள் பின்னே பட்டயத்தை உருவுவேன்.
	<br /><br />
	13. இப்படி என் கோபம் நிறைவேறும்; இப்படி நான் என் உக்கிரத்தை அவர்கள்மேல் தங்கப்பண்ணுகிறதினால் என்னை ஆற்றிக்கொள்வேன்; நான் என் உக்கிரத்தை அவர்களில் நிறைவேற்றும்போது, கர்த்தராகிய நான் என் வைராக்கியத்திலே இதைப் பேசினேன் என்று அறிவார்கள்.
	<br /><br />
	14. கடந்துபோகிற யாவருடைய கண்களுக்கு முன்பாகவும் உன் சுற்றுப்புறத்தாராகிய ஜாதிகளுக்குள்ளே நான் உன்னைப் பாழும் நிந்தையுமாக்குவேன்.
	<br /><br />
	15. நான் கோபத்தாலும் உக்கிரத்தாலும் கொடிய கண்டனைகளாலும், உன்னில் நீதிசெலுத்தும்போது, உன் சுற்றுப்புறத்தாராகிய ஜாதிகளுக்கு அது நிந்தையும் துர்க்கீர்த்தியும் எச்சரிப்பும் பிரமிப்புமாய் இருக்கும்; கர்த்தராகிய நான் இதைச் சொன்னேன்.
	<br /><br />
	16. உங்களை அழிப்பதற்கு நான் அனுப்பும் அழிவுக்கேதுவான பஞ்சத்தின் கொடிய அம்புகளை நான் அவர்களுக்குள்ளே எய்யும்போது, நான் பஞ்சத்தை உங்கள்மேல் அதிகரிக்கப்பண்ணி, உங்கள் அப்பம் என்னும் ஆதரவுகோலை முறித்துப்போடுவேன்.
	<br /><br />
	17. பஞ்சத்தையும், உன்னைப் பிள்ளையில்லாமற்போகப்பண்ணும் துஷ்டமிருகங்களையும் உங்களுக்கு விரோதமாக அனுப்புவேன்; கொள்ளைநோயும் இரத்தஞ்சிந்துதலும் உன்னில் சுற்றித்திரியும்; பட்டயத்தை நான் உன்மேல் வரப்பண்ணுவேன்; கர்த்தராகிய நான் இதைச் சொன்னேன் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Ezekial5