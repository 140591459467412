import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Matthew14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Story | மத்தேயு 14 </title>
	<meta
          name="description"
          content="Matthew 14 | மத்தேயு 14 |
          Matthew Life | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. அக்காலத்தில், காற்பங்கு தேசாதிபதியாகிய ஏரோது இயேசுவின் கீர்த்தியைக் கேள்விப்பட்டு,
	<br /><br />
	2. தன் ஊழியக்காரரை நோக்கி: இவன் யோவான்ஸ்நானன்; இவன் மரித்தோரிலிருந்து எழுந்தான்; ஆகையால், இவனிடத்தில் பலத்த செய்கைகள் விளங்குகிறது என்றான்.
	<br /><br />
	3. ஏரோது தன் சகோதரனாகிய பிலிப்புவின் மனைவி ஏரோதியாளினிமித்தம் யோவானைப் பிடித்துக் கட்டிக் காவலில் வைத்திருந்தான்.
	<br /><br />
	4. ஏனெனில்: நீர் அவளை வைத்துக்கொள்வது நியாயமல்லவென்று யோவான் அவனுக்குச் சொல்லியிருந்தான்.
	<br /><br />
	5. ஏரோது அவனைக் கொலைசெய்ய மனதாயிருந்தும், ஜனங்கள் அவனைத் தீர்க்கதரிசியென்று எண்ணினபடியால் அவர்களுக்குப் பயந்திருந்தான்.
	<br /><br />
	6. அப்படியிருக்க, ஏரோதின் ஜென்மநாள் கொண்டாடப்படுகிறபோது, ஏரோதியாளின் குமாரத்தி அவர்கள் நடுவே நடனம்பண்ணி ஏரோதைச் சந்தோஷப்படுத்தினாள்.
	<br /><br />
	7. அதினிமித்தம் அவன்: நீ எதைக் கேட்டாலும் தருவேன் என்று அவளுக்கு ஆணையிட்டு வாக்குக்கொடுத்தான்.
	<br /><br />
	8. அவள் தன் தாயினால் ஏவப்பட்டபடியே: யோவான்ஸ்நானனுடைய தலையை இங்கே ஒரு தாலத்திலே எனக்குத் தாரும் என்று கேட்டாள்.
	<br /><br />
	9. ராஜா துக்கமடைந்தான். ஆகிலும், ஆணையினிமித்தமும், பந்தியில் கூட இருந்தவர்களினிமித்தமும், அதைக் கொடுக்கக் கட்டளையிட்டு,
	<br /><br />
	10. ஆள் அனுப்பி, காவற்கூடத்திலே யோவானைச் சிரச்சேதம்பண்ணுவித்தான்.
	<br /><br />
	11. அவனுடைய சிரசை ஒரு தாலத்திலே கொண்டுவந்து, சிறு பெண்ணுக்குக் கொடுத்தார்கள்; அவள் அதைத் தன் தாயினிடத்தில் கொண்டுபோனாள்.
	<br /><br />
	12. அவனுடைய சீஷர்கள் வந்து உடலை எடுத்து அடக்கம்பண்ணி, பின்பு போய் அந்தச் சங்கதியை இயேசுவுக்கு அறிவித்தார்கள்.
	<br /><br />
	13. இயேசு அதைக் கேட்டு, அவ்விடம் விட்டு, படவில் ஏறி, வனாந்தரமான ஓர் இடத்துக்குத் தனியே போனார். ஜனங்கள் அதைக் கேள்விப்பட்டு, பட்டணங்களிலிருந்து கால்நடையாய் அவரிடத்திற்குப் போனார்கள்.
	<br /><br />
	14. இயேசு வந்து, திரளான ஜனங்களைக் கண்டு, அவர்கள்மேல் மனதுருகி, அவர்களில் வியாதியஸ்தர்களாயிருந்தவர்களைச் சொஸ்தமாக்கினார்.
	<br /><br />
	15. சாயங்காலமானபோது, அவருடைய சீஷர்கள் அவரிடத்தில் வந்து: இது வனாந்தரமான இடம், நேரமுமாயிற்று; ஜனங்கள் கிராமங்களுக்குப் போய்த் தங்களுக்குப் போஜனபதார்த்தங்களைக் கொள்ளும்படி அவர்களை அனுப்பிவிடவேண்டும் என்றார்கள்.
	<br /><br />
	16. இயேசு அவர்களை நோக்கி: அவர்கள் போகவேண்டுவதில்லை; நீங்களே அவர்களுக்குப் போஜனங்கொடுங்கள் என்றார்.
	<br /><br />
	17. அதற்கு அவர்கள்: இங்கே எங்களிடத்தில் ஐந்து அப்பமும் இரண்டு மீன்களுமேயல்லாமல், வேறொன்றும் இல்லை என்றார்கள்.
	<br /><br />
	18. அவைகளை என்னிடத்தில் கொண்டுவாருங்கள் என்றார்.
	<br /><br />
	19. அப்பொழுது, அவர் ஜனங்களைப் புல்லின்மேல் பந்தியிருக்கக் கட்டளையிட்டு, அந்த ஐந்து அப்பங்களையும், அந்த இரண்டு மீன்களையும் எடுத்து, வானத்தை அண்ணாந்துபார்த்து, ஆசீர்வதித்து, அப்பங்களைப் பிட்டுச் சீஷர்களிடத்தில் கொடுத்தார்; சீஷர்கள் ஜனங்களுக்குக் கொடுத்தார்கள்.
	<br /><br />
	20. எல்லாரும் சாப்பிட்டுத் திருப்தியடைந்தார்கள்; மீதியான துணிக்கைகளைப் பன்னிரண்டு கூடைநிறைய எடுத்தார்கள்.
	<br /><br />
	21. ஸ்திரீகளும் பிள்ளைகளும் தவிர, சாப்பிட்ட புருஷர்கள் ஏறக்குறைய ஐயாயிரம்பேராயிருந்தார்கள்.
	<br /><br />
	22. இயேசு ஜனங்களை அனுப்பிவிடுகையில், தம்முடைய சீஷர்கள் படவில் ஏறி, தமக்கு முன்னே அக்கரைக்குப் போகும்படி அவர்களைத் துரிதப்படுத்தினார்.
	<br /><br />
	23. அவர் ஜனங்களை அனுப்பிவிட்ட பின்பு, தனித்து ஜெபம்பண்ண ஒரு மலையின்மேல் ஏறி, சாயங்காலமானபோது அங்கே தனிமையாயிருந்தார்.
	<br /><br />
	24. அதற்குள்ளாகப் படவு நடுக்கடலிலே சேர்ந்து, எதிர்க்காற்றாயிருந்தபடியால் அலைகளினால் அலைவுபட்டது.
	<br /><br />
	25. இரவின் நாலாம் ஜாமத்திலே, இயேசு கடலின்மேல் நடந்து, அவர்களிடத்திற்கு வந்தார்.
	<br /><br />
	26. அவர் கடலின்மேல் நடக்கிறதைச் சீஷர்கள் கண்டு, கலக்கமடைந்து, ஆவேசம் என்று சொல்லி, பயத்தினால் அலறினார்கள்.
	<br /><br />
	27. உடனே இயேசு அவர்களோடே பேசி: திடன்கொள்ளுங்கள், நான்தான், பயப்படாதிருங்கள் என்றார்.
	<br /><br />
	28. பேதுரு அவரை நோக்கி: ஆண்டவரே! நீரேயானால் நான் ஜலத்தின்மேல் நடந்து உம்மிடத்தில் வரக் கட்டளையிடும் என்றான்.
	<br /><br />
	29. அதற்கு அவர்: வா என்றார். அப்பொழுது, பேதுரு படவை விட்டிறங்கி, இயேசுவினிடத்தில் போக ஜலத்தின்மேல் நடந்தான்.
	<br /><br />
	30. காற்று பலமாயிருக்கிறதைக் கண்டு, பயந்து, அமிழ்ந்துபோகையில்: ஆண்டவரே, என்னை ரட்சியும் என்று கூப்பிட்டான்.
	<br /><br />
	31. உடனே இயேசு கையை நீட்டி அவனைப் பிடித்து: அற்பவிசுவாசியே, ஏன் சந்தேகப்பட்டாய் என்றார்.
	<br /><br />
	32. அவர்கள் படவில் ஏறினவுடனே காற்று அமர்ந்தது.
	<br /><br />
	33. அப்பொழுது, படவில் உள்ளவர்கள் வந்து: மெய்யாகவே நீர் தேவனுடைய குமாரன் என்று சொல்லி, அவரைப் பணிந்துகொண்டார்கள்.
	<br /><br />
	34. பின்பு, அவர்கள் கடலைக் கடந்து, கெனேசரேத்து நாட்டில் சேர்ந்தார்கள்.
	<br /><br />
	35. அவ்விடத்து மனுஷர் அவரை இன்னார் என்று அறிந்து, சுற்றுப்புறமெங்கும் செய்தி அனுப்பி, பிணியாளிகளெல்லாரையும் அவரிடத்தில் கொண்டுவந்து,
	<br /><br />
	36. அவருடைய வஸ்திரத்தின் ஓரத்தையாகிலும் அவர்கள் தொடும்படி உத்தரவாகவேண்டுமென்று அவரை வேண்டிக்கொண்டார்கள்; தொட்ட யாவரும் சொஸ்தமானார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Matthew14