import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Revelation2 = () => {
  const verseNumber = 2;

  
  return (
    <>
    <Helmet>
	<title> Book of Revelation 2 | Tamil Bible Verse | வெளிப்படுத்தல் -2 </title>
	<meta
          name="description"
          content="The Book of Revelation-2 in Tamil Bible Verse | வெளிப்படுத்தின விசேஷம் அதிகாரம் - 2 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br /><br />
		<br /><br />
<h2>
* "எபேசஸ், உங்கள் முதல் அன்பைப் புதுப்பித்துக் கொள்ளுங்கள். ஸ்மிர்னா, சாகும்வரை உண்மையாக இருங்கள். பெர்கமுமே, சிலர் தவறான போதனைகளைக் கொண்டுள்ளனர். தியதிரா, யேசபேலைப் பொறுத்துக்கொள்." *
			</h2>
<br /><br />
			<br /><br />

      {/* Vesrse of Tamilbible */}

      <p>
	1. எபேசு சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: ஏழு நட்சத்திரங்களைத் தம்முடைய வலதுகரத்தில் ஏந்திக்கொண்டு, ஏழு பொன் குத்துவிளக்குகளின் மத்தியிலே உலாவிக்கொண்டிருக்கிறவர் சொல்லுகிறதாவது;
	<br /><br />
	2. உன் கிரியைகளையும், உன் பிரயாசத்தையும், உன் பொறுமையையும், நீ பொல்லாதவர்களைச் சகிக்கக்கூடாமலிருக்கிறதையும், அப்போஸ்தலரல்லாதவர்கள் தங்களை அப்போஸ்தலரென்று சொல்லுகிறதை நீ சோதித்து அவர்களைப் பொய்யரென்று கண்டறிந்ததையும்;
	<br /><br />
	3. நீ சகித்துக்கொண்டிருக்கிறதையும், பொறுமையாயிருக்கிறதையும், என் நாமத்தினிமித்தம் இளைப்படையாமல் பிரயாசப்பட்டதையும் அறிந்திருக்கிறேன்.
	<br /><br />
	4. ஆனாலும், நீ ஆதியில் கொண்டிருந்த அன்பை விட்டாய் என்று உன்பேரில் எனக்குக் குறை உண்டு.
	<br /><br />
	5. ஆகையால், நீ இன்ன நிலைமையிலிருந்து விழுந்தாயென்பதை நினைத்து, மனந்திரும்பி, ஆதியில் செய்த கிரியைகளைச் செய்வாயாக; இல்லாவிட்டால் நான் சீக்கிரமாய் உன்னிடத்தில் வந்து, நீ மனந்திரும்பாதபட்சத்தில், உன் விளக்குத்தண்டை அதனிடத்தினின்று நீக்கிவிடுவேன்.
	<br /><br />
	6. நான் வெறுக்கிற நிக்கொலாய் மதஸ்தரின் கிரியைகளை நீயும் வெறுக்கிறாய், இது உன்னிடத்திலுண்டு.
	<br /><br />
	7. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன்; ஜெயங்கொள்ளுகிறவனெவனோ அவனுக்குத் தேவனுடைய பரதீசின் மத்தியிலிருக்கிற ஜீவவிருட்சத்தின் கனியைப் புசிக்கக்கொடுப்பேன் என்றெழுது.
	<br /><br />
	8. சிமிர்னா சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: முந்தினவரும் பிந்தினவரும், மரித்திருந்து பிழைத்தவருமானவர் சொல்லுகிறதாவது;
	<br /><br />
	9. உன் கிரியைகளையும், உன் உபத்திரவத்தையும், நீ ஐசுவரியமுள்ளவனாயிருந்தும் உனக்கிருக்கிற தரித்திரத்தையும், தங்களை யூதரென்று சொல்லியும் யூதராயிராமல் சாத்தானுடைய கூட்டமாயிருக்கிறவர்கள் செய்யும் தூஷணத்தையும் அறிந்திருக்கிறேன்.
	<br /><br />
	10. நீ படப்போகிற பாடுகளைக்குறித்து எவ்வளவும் பயப்படாதே; இதோ, நீங்கள் சோதிக்கப்படும்பொருட்டாகப் பிசாசானவன் உங்களில் சிலரைக் காவலில் போடுவான்; பத்துநாள் உபத்திரவப்படுவீர்கள். ஆகிலும் நீ மரணபரியந்தம் உண்மையாயிரு, அப்பொழுது ஜீவகிரீடத்தை உனக்குத் தருவேன்.
	<br /><br />
	11. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன்; ஜெயங்கொள்ளுகிறவன் இரண்டாம் மரணத்தினால் சேதப்படுவதில்லை என்றெழுது.
	<br /><br />
	12. பெர்கமு சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: இருபுறமும் கருக்குள்ள பட்டயத்தை உடையவர் சொல்லுகிறதாவது;
	<br /><br />
	13. உன் கிரியைகளையும், சாத்தானுடைய சிங்காசனமிருக்கிற இடத்தில் நீ குடியிருக்கிறதையும், நீ என் நாமத்தைப் பற்றிக்கொண்டிருக்கிறதையும், சாத்தான் குடிகொண்டிருக்கிற இடத்திலே உங்களுக்குள்ளே எனக்கு உண்மையுள்ள சாட்சியான அந்திப்பா என்பவன் கொல்லப்பட்ட நாட்களிலும் என்னைப் பற்றும் விசுவாசத்தை நீ மறுதலியாமலிருந்ததையும் அறிந்திருக்கிறேன்.
	<br /><br />
	14. ஆகிலும், சில காரியங்களைக்குறித்து உன்பேரில் எனக்குக் குறை உண்டு; விக்கிரகங்களுக்குப் படைத்தவைகளைப் புசிப்பதற்கும் வேசித்தனம்பண்ணுவதற்கும் ஏதுவான இடறலை இஸ்ரவேல் புத்திரர் முன்பாகப் போடும்படி பாலாக் என்பவனுக்குப் போதனைசெய்த பிலேயாமுடைய போதகத்தைக் கைக்கொள்ளுகிறவர்கள் உன்னிடத்திலுண்டு.
	<br /><br />
	15. அப்படியே நிக்கொலாய் மதஸ்தருடைய போதகத்தைக் கைக்கொள்ளுகிறவர்களும் உன்னிடத்திலுண்டு; அதை நான் வெறுக்கிறேன்.
	<br /><br />
	16. நீ மனந்திரும்பு, இல்லாவிட்டால் நான் சீக்கிரமாய் உன்னிடத்தில் வந்து, என் வாயின் பட்டயத்தால் அவர்களோடே யுத்தம்பண்ணுவேன்.
	<br /><br />
	17. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன்; ஜெயங்கொள்ளுகிறவனுக்கு நான் மறைவான மன்னாவைப் புசிக்கக்கொடுத்து, அவனுக்கு வெண்மையான குறிக்கல்லையும், அந்தக் கல்லின்மேல் எழுதப்பட்டதும் அதைப் பெறுகிறவனேயன்றி வேறொருவனும் அறியக்கூடாததுமாகிய புதிய நாமத்தையும் கொடுப்பேன் என்றெழுது.
	<br /><br />
	18. தியத்தீரா சபையின் தூதனுக்கு நீ எழுதவேண்டியது என்னவெனில்: அக்கினிஜுவாலை போன்ற கண்களும், பிரகாசமான வெண்கலம்போன்ற பாதங்களுமுள்ள தேவகுமாரன் சொல்லுகிறதாவது;
	<br /><br />
	19. உன் கிரியைகளையும், உன் அன்பையும், உன் ஊழியத்தையும், உன் விசுவாசத்தையும், உன் பொறுமையையும், நீ முன்பு செய்த கிரியைகளிலும் பின்பு செய்த கிரியைகள் அதிகமாயிருக்கிறதையும் அறிந்திருக்கிறேன்.
	<br /><br />
	20. ஆகிலும், உன் பேரில் எனக்குக் குறை உண்டு; என்னவெனில், தன்னைத் தீர்க்கதரிசியென்று சொல்லுகிற யேசபேல் என்னும் ஸ்திரீயானவள் என்னுடைய ஊழியக்காரர் வேசித்தனம்பண்ணவும் விக்கிரகங்களுக்குப் படைத்தவைகளைப் புசிக்கவும் அவர்களுக்குப் போதித்து, அவர்களை வஞ்சிக்கும்படி நீ அவளுக்கு இடங்கொடுக்கிறாய்.
	<br /><br />
	21. அவள் மனந்திரும்பும்படியாய் அவளுக்குத் தவணைகொடுத்தேன்; தன் வேசிமார்க்கத்தை விட்டு மனந்திரும்ப அவளுக்கு விருப்பமில்லை.
	<br /><br />
	22. இதோ, நான் அவளைக் கட்டில்கிடையாக்கி, அவளுடனே விபசாரஞ்செய்தவர்கள் தங்களுடைய கிரியைகளைவிட்டு மனந்திரும்பாவிட்டால் அவர்களையும் மிகுந்த உபத்திரவத்திலே தள்ளி,
	<br /><br />
	23. அவளுடைய பிள்ளைகளையும் கொல்லவே கொல்லுவேன்; அப்பொழுது நானே உள்ளிந்திரியங்களையும் இருதயங்களையும் ஆராய்கிறவரென்று எல்லாச் சபைகளும் அறிந்துகொள்ளும்; அன்றியும் உங்களில் ஒவ்வொருவனுக்கும் உங்கள் கிரியைகளின்படியே பலனளிப்பேன்.
	<br /><br />
	24. தியத்தீராவிலே இந்தப் போதகத்தைப் பற்றிக்கொள்ளாமலும், சாத்தானுடைய ஆழங்கள் என்று அவர்கள் சொல்லுகிறார்களே, அந்த ஆழங்களை அறிந்துகொள்ளாமலுமிருக்கிற மற்றவர்களாகிய உங்களுக்கு நான் சொல்லுகிறதாவது; உங்கள்மேல் வேறொரு பாரத்தையும் சுமத்தமாட்டேன்.
	<br /><br />
	25. உங்களுக்குள்ளதை நான் வருமளவும் பற்றிக்கொண்டிருங்கள்.
	<br /><br />
	26. ஜெயங்கொண்டு முடிவுபரியந்தம் என் கிரியைகளைக் கைக்கொள்ளுகிறவனெவனோ அவனுக்கு, நான் என் பிதாவினிடத்தில் அதிகாரம் பெற்றதுபோல, ஜாதிகள்மேல் அதிகாரம் கொடுப்பேன்.
	<br /><br />
	27. அவன் இருப்புக்கோலால் அவர்களை ஆளுவான்; அவர்கள் மண்பாண்டங்களைப்போல நொறுக்கப்படுவார்கள்.
	<br /><br />
	28. விடிவெள்ளி நட்சத்திரத்தையும் அவனுக்குக் கொடுப்பேன்.
	<br /><br />
	29. ஆவியானவர் சபைகளுக்குச் சொல்லுகிறதைக் காதுள்ளவன் கேட்கக்கடவன் என்றெழுது.
</p>



      {/* End of verse */} 

	  <br /><br />
		<br /><br />
<h2>
*  *
			</h2>
<br /><br />
			<br /><br /> 
      </div>
    </div>
    </>
  );
}

export default Revelation2