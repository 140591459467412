import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Isaiah20 = () => {
  const verseNumber = 20;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | ஏசாயா 20 </title>
	<meta
          name="description"
          content="Isaiah 20 | ஏசாயா 20 |
          Yesaya | Tamil Bible Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. தர்த்தான், அசீரியா ராஜாவாகிய சர்கோனாலே அனுப்பப்பட்டு, அஸ்தோத்துக்கு வந்து, அஸ்தோத்தின்மேல் யுத்தம்பண்ணி, அதைப் பிடித்த வருஷத்திலே,
	<br /><br />
	2. கர்த்தர் ஆமோத்சின் குமாரனாகிய ஏசாயாவை நோக்கி: நீ போய் உன் அரையிலிருக்கிற இரட்டை அவிழ்த்து, உன் கால்களிலிருக்கிற பாதரட்சைகளைக் கழற்று என்றார்; அவன் அப்படியே செய்து, வஸ்திரமில்லாமலும் வெறுங்காலுமாய் நடந்தான்.
	<br /><br />
	3. அப்பொழுது கர்த்தர்: எகிப்தின்மேலும் எத்தியோப்பியாவின்மேலும் வரும் மூன்றுவருஷத்துக் காரியங்களுக்கு அடையாளமும் குறிப்புமாக என் ஊழியக்காரனாகிய ஏசாயா வஸ்திரமில்லாமலும் வெறுங்காலுமாய் நடக்கிறதுபோல,
	<br /><br />
	4. அசீரியா ராஜா, தான் சிறைபிடிக்கப்போகிற எகிப்தியரும், தான் குடிவிலக்கப்போகிற எத்தியோப்பியருமாகிய இளைஞரையும் கிழவரையும், வஸ்திரமில்லாமலும் வெறுங்காலுமாய் எகிப்தியருக்கு வெட்கமுண்டாகும்படி, இருப்பிடம் மூடப்படாதவர்களாய்க் கொண்டுபோவான்.
	<br /><br />
	5. அப்பொழுது இந்தக் கடற்கரைக் குடிகள் தாங்கள் நம்பியிருந்த எத்தியோப்பியாவைக்குறித்தும், தாங்கள் பெருமைபாராட்டின எகிப்தைக்குறித்தும் கலங்கி வெட்கி:
	<br /><br />
	6. இதோ, அசீரிய ராஜாவின் முகத்துக்குத் தப்பும்படிக்கு நாங்கள் நம்பி, சகாயத்துக்கென்று ஓடிவந்து அண்டினவன் இப்படியானானே; நாங்கள் எப்படி விடுவிக்கப்படுவோம் என்று அக்காலத்திலே சொல்லுவார்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Isaiah20