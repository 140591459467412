import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const TwoCorinthians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>THE SECOND LETTER TO CORINTHIANS 1 | TAMIL BIBLE </title>
	<meta
          name="description"
          content="THE SECOND LETTER TO THE CORINTHIANS Chapter 1 | கொருந்தியருக்கு எழுதின நிருபம் அதிகாரம் 1 
          | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse In Tamil | புதிய ஏற்பாடு | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
	  <p>
	1. தேவனுடைய சித்தத்தினாலே இயேசுகிறிஸ்துவின் அப்போஸ்தலனாகிய பவுலும், சகோதரனாகிய தீமோத்தேயும், கொரிந்துபட்டணத்திலுள்ள தேவனுடைய சபைக்கும், அகாயாநாடெங்குமுள்ள எல்லாப் பரிசுத்தவான்களுக்கும் எழுதுகிறதாவது:
	<br /><br />
	2. நம்முடைய பிதாவாகிய தேவனாலும், கர்த்தராகிய இயேசுகிறிஸ்துவினாலும், உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக.
	<br /><br />
	3. நமது கர்த்தராகிய இயேசுகிறிஸ்துவின் பிதாவாகிய தேவனும், இரக்கங்களின் பிதாவும், சகலவிதமான ஆறுதலின் தேவனுமாயிருக்கிறவருக்கு ஸ்தோத்திரம்.
	<br /><br />
	4. தேவனால் எங்களுக்கு அருளப்படுகிற ஆறுதலினாலே, எந்த உபத்திரவத்திலாகிலும் அகப்படுகிறவர்களுக்கு நாங்கள் ஆறுதல்செய்யத் திராணியுள்ளவர்களாகும்படி, எங்களுக்கு வரும் சகல உபத்திரவங்களிலேயும் அவரே எங்களுக்கு ஆறுதல்செய்கிறவர்.
	<br /><br />
	5. எப்படியெனில், கிறிஸ்துவினுடைய பாடுகள் எங்களிடத்தில் பெருகுகிறதுபோல, கிறிஸ்துவினாலே எங்களுக்கு ஆறுதலும் பெருகுகிறது.
	<br /><br />
	6. ஆதலால், நாங்கள் உபத்திரவப்பட்டாலும் அது உங்கள் ஆறுதலுக்கும் இரட்சிப்புக்கும் ஏதுவாகும்; நாங்கள் ஆறுதலடைந்தாலும் அதுவும் உங்கள் ஆறுதலுக்கும் இரட்சிப்புக்கும் ஏதுவாகும்; நாங்கள் பாடுபடுகிறதுபோல நீங்களும் பாடுபட்டுச் சகிக்கிறதினாலே அந்த இரட்சிப்பு பலன்செய்கிறது.
	<br /><br />
	7. நீங்கள் எங்களோடேகூடப் பாடுபடுகிறதுபோல, எங்களோடேகூட ஆறுதலும் அடைகிறீர்களென்று நாங்கள் அறிந்து, உங்களைக்குறித்து உறுதியான நம்பிக்கையுள்ளவர்களாயிருக்கிறோம்.
	<br /><br />
	8. ஆகையால் சகோதரரே, ஆசியாவில் எங்களுக்கு நேரிட்ட உபத்திரவத்தை நீங்கள் அறியாதிருக்க எங்களுக்கு மனதில்லை. என்னவெனில், பிழைப்போம் என்கிற நம்பிக்கை அற்றுப்போகத்தக்கதாக, எங்கள் பலத்திற்கு மிஞ்சின அதிக பாரமான வருத்தம் எங்களுக்கு உண்டாயிற்று.
	<br /><br />
	9. நாங்கள் எங்கள்மேல் நம்பிக்கையாயிராமல், மரித்தோரை எழுப்புகிற தேவன்மேல் நம்பிக்கையாயிருக்கத்தக்கதாக, மரணம் வருமென்று நாங்கள் எங்களுக்குள்ளே நிச்சயித்திருந்தோம்.
	<br /><br />
	10. அப்படிப்பட்ட மரணத்தினின்றும் அவர் எங்களைத் தப்புவித்தார், இப்பொழுதும் தப்புவிக்கிறார், இன்னும் தப்புவிப்பார் என்று அவரை நம்பியிருக்கிறோம்.
	<br /><br />
	11. அநேகர்மூலமாய் எங்களுக்கு உண்டான தயவுக்காக அநேகரால் எங்கள் நிமித்தம் ஸ்தோத்திரங்கள் செலுத்தப்படும்பொருட்டு, நீங்களும் விண்ணப்பத்தினால் எங்களுக்கு உதவிசெய்யுங்கள்.
	<br /><br />
	12. மாம்சத்திற்கேற்ற ஞானத்தோடே நடவாமல், தேவனுடைய கிருபையினால் நாங்கள் உலகத்திலேயும், விசேஷமாக உங்களிடத்திலேயும், கபடமில்லாமல் திவ்விய உண்மையோடே நடந்தோமென்று, எங்கள் மனது எங்களுக்குச் சொல்லுஞ்சாட்சியே எங்கள் புகழ்ச்சியாயிருக்கிறது.
	<br /><br />
	13. ஏனென்றால், நீங்கள் வாசித்தும் ஒத்துக்கொண்டுமிருக்கிற காரியங்களையேயன்றி, வேறொன்றையும் நாங்கள் உங்களுக்கு எழுதவில்லை; முடிவுபரியந்தமும் அப்படியே ஒத்துக்கொள்வீர்களென்று நம்பியிருக்கிறேன்.
	<br /><br />
	14. கர்த்தராகிய இயேசுவினுடைய நாளிலே நீங்கள் எங்களுக்குப் புகழ்ச்சியாயிருப்பதுபோல, நாங்களும் உங்களுக்குப் புகழ்ச்சியாயிருக்கிறதை ஒருவாறு ஒத்துக்கொண்டிருக்கிறீர்களே.
	<br /><br />
	15. நான் இப்படிப்பட்ட நம்பிக்கையைக் கொண்டிருக்கிறபடியினால், உங்களுக்கு இரண்டாந்தரமும் பிரயோஜனமுண்டாகும்படி, முதலாவது உங்களிடத்தில் வரவும்,
	<br /><br />
	16. பின்பு உங்கள் ஊர்வழியாய் மக்கெதோனியா நாட்டுக்குப் போகவும், மக்கெதோனியாவை விட்டு மறுபடியும் உங்களிடத்திற்கு வரவும், உங்களால் யூதேயாதேசத்துக்கு வழிவிட்டனுப்பப்படவும் யோசனையாயிருந்தேன்.
	<br /><br />
	17. இப்படி நான் யோசித்தது வீணாக யோசித்தேனோ? அல்லது ஆம் ஆம் என்கிறதும், அல்ல அல்ல என்கிறதும், என்னிடத்திலே இருக்கத்தக்கதாக, நான் யோசிக்கிறவைகளை மாம்சத்தின்படி யோசிக்கிறேனோ?
	<br /><br />
	18. நாங்கள் உங்களுக்குச் சொன்ன வார்த்தை ஆம் அல்ல என்று இருக்கவில்லை; அதற்கு உண்மையுள்ள தேவனே சாட்சி.
	<br /><br />
	19. என்னாலும், சில்வானுவினாலும், தீமோத்தேயுவினாலும், உங்களுக்குள்ளே பிரசங்கிக்கப்பட்ட தேவகுமாரனாகிய இயேசுகிறிஸ்துவும் ஆம் என்றும் அல்ல என்றும் இராமல், ஆம் என்றே இருக்கிறார்.
	<br /><br />
	20. எங்களால் தேவனுக்கு மகிமையுண்டாகும்படி, தேவனுடைய வாக்குத்தத்தங்களெல்லாம் இயேசுகிறிஸ்துவுக்குள் ஆம் என்றும், அவருக்குள் ஆமென் என்றும் இருக்கிறதே.
	<br /><br />
	21. உங்களோடேகூட எங்களையும் கிறிஸ்துவுக்குள் ஸ்திரப்படுத்தி, நம்மை அபிஷேகம்பண்ணினவர் தேவனே.
	<br /><br />
	22. அவர் நம்மை முத்திரித்து, நம்முடைய இருதயங்களில் ஆவியென்னும் அச்சாரத்தையும் கொடுத்திருக்கிறார்.
	<br /><br />
	23. மேலும் நான் உங்களைத் தப்பவிடும்படிக்கே இதுவரைக்கும் கொரிந்துபட்டணத்திற்கு வராதிருக்கிறேனென்று, என் ஆத்துமாவின்பேரில் தேவனையே சாட்சியாகக் கோருகிறேன்.
	<br /><br />
	24. உங்கள் விசுவாசத்திற்கு நாங்கள் அதிகாரிகளாயிராமல், உங்கள் சந்தோஷத்திற்குச் சகாயராயிருக்கிறோம்; விசுவாசத்தினாலே நிலைநிற்கிறீர்களே.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default TwoCorinthians1