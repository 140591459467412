import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Acts22 = () => {
  const verseNumber = 22;

  
  return (
    <>
    <Helmet>
	<title>Acts of Apostles Tamil Bible | அப்போஸ்தலர் 22 </title>
	<meta
          name="description"
          content="Acts 22 | அப்போஸ்தலருடைய நடபடிகள் அதிகாரம் 22 |
          Acts of Apostles Chapter-22 | Tamil Bible Verse | Tamil Bible | New Testamant | புதிய ஏற்பாடு | Tamil Online Bible | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சகோதரரே, பிதாக்களே, நான் இப்பொழுது உங்களுக்குச் சொல்லப்போகிற நியாயங்களுக்குச் செவிகொடுப்பீர்களாக என்றான்.
	<br /><br />
	2. அவன் எபிரெயு பாஷையிலே தங்களுடனே பேசுகிறதை அவர்கள் கேட்டபொழுது, அதிக அமைதலாயிருந்தார்கள். அப்பொழுது அவன்:
	<br /><br />
	3. நான் யூதன், சிலிசியாநாட்டிலுள்ள தர்சுபட்டணத்திலே பிறந்து, இந்த நகரத்திலே கமாலியேலின் பாதத்தருகே வளர்ந்து, முன்னோர்களுடைய வேதப்பிரமாணத்தின்படியே திட்டமாய்ப் போதிக்கப்பட்டு, இன்றையத்தினம் நீங்களெல்லாரும் தேவனைக்குறித்து வைராக்கியமுள்ளவர்களாயிருக்கிறதுபோல நானும் வைராக்கியமுள்ளவனாயிருந்தேன்.
	<br /><br />
	4. நான் இந்த மார்க்கத்தாராகிய புருஷரையும் ஸ்திரீகளையும் கட்டி, சிறைச்சாலைகளில் ஒப்புவித்து, மரணபரியந்தம் துன்பப்படுத்தினேன்.
	<br /><br />
	5. அதற்குப் பிரதான ஆசாரியரும் மூப்பர் யாவரும் சாட்சிகொடுப்பார்கள்; அவர்கள் கையிலே நான் சகோதரருக்கு நிருபங்களை வாங்கிக்கொண்டு, தமஸ்குவிலிருக்கிறவர்களும் தண்டிக்கப்படும்படிக்கு, அவர்களைக் கட்டி, எருசலேமுக்குக் கொண்டுவரும்படி அவ்விடத்திற்குப்போனேன்.
	<br /><br />
	6. அப்படி நான் பிரயாணப்பட்டுத் தமஸ்குவுக்குச் சமீபமானபோது, மத்தியான வேளையிலே, சடிதியாய் வானத்திலிருந்து பேரொளி உண்டாகி, என்னைச்சுற்றிப் பிரகாசித்தது.
	<br /><br />
	7. நான் தரையிலே விழுந்தேன். அப்பொழுது: சவுலே, சவுலே, நீ என்னை ஏன் துன்பப்படுத்துகிறாய் என்று என்னுடனே சொல்லுகிற ஒரு சத்தத்தைக் கேட்டேன்.
	<br /><br />
	8. அதற்கு நான்: ஆண்டவரே, நீர் யார் என்றேன். அவர்: நீ துன்பப்படுத்துகிற நசரேயனாகிய இயேசு நானே என்றார்.
	<br /><br />
	9. என்னுடனேகூட இருந்தவர்கள் வெளிச்சத்தைக் கண்டு, பயமடைந்தார்கள்; என்னுடனே பேசினவருடைய சத்தத்தையோ அவர்கள் கேட்கவில்லை.
	<br /><br />
	10. அப்பொழுது நான்: ஆண்டவரே, நான் என்னசெய்யவேண்டும் என்றேன். அதற்குக் கர்த்தர்: நீ எழுந்து, தமஸ்குவுக்குப் போ; நீ செய்யும்படி நியமிக்கப்பட்டதெல்லாம் அங்கே உனக்குச் சொல்லப்படும் என்றார்.
	<br /><br />
	11. அந்த ஒளியின் மகிமையினாலே நான் பார்வையற்றுப்போனபடியினால், என்னோடிருந்தவர்களால் கைலாகு கொடுத்து வழிநடத்தப்பட்டுத் தமஸ்குவுக்கு வந்தேன்.
	<br /><br />
	12. அப்பொழுது வேதப்பிரமாணத்தின்படியே பக்தியுள்ளவனும், அங்கே குடியிருக்கிற சகல யூதராலும் நல்லவனென்று சாட்சிபெற்றவனுமாகிய அனனியா என்னும் ஒருவன்,
	<br /><br />
	13. என்னிடத்தில் வந்துநின்று: சகோதரனாகிய சவுலே, பார்வையடைவாயாக என்றான்; அந்நேரமே நான் பார்வையடைந்து, அவனை ஏறிட்டுப்பார்த்தேன்.
	<br /><br />
	14. அப்பொழுது அவன்: நம்முடைய முன்னோர்களின் தேவனுடைய திருவுளத்தை நீ அறியவும், நீதிபரரைத் தரிசிக்கவும், அவருடைய திருவாய்மொழியைக் கேட்கவும், அவர் உன்னை முன்னமே தெரிந்துகொண்டார்.
	<br /><br />
	15. நீ கண்டவைகளையும் கேட்டவைகளையும் குறித்துச் சகல மனுஷருக்குமுன்பாக அவருக்குச் சாட்சியாயிருப்பாய்.
	<br /><br />
	16. இப்பொழுது நீ தாமதிக்கிறதென்ன? நீ எழுந்து கர்த்தருடைய நாமத்தைத் தொழுதுகொண்டு, ஞானஸ்நானம்பெற்று, உன் பாவங்கள் போகக் கழுவப்படு என்றான்.
	<br /><br />
	17. பின்பு நான் எருசலேமுக்குத் திரும்பிவந்து, தேவாலயத்திலே ஜெபம்பண்ணிக்கொண்டிருக்கையில், ஞானதிருஷ்டியடைந்து, அவரைத் தரிசித்தேன்.
	<br /><br />
	18. அவர் என்னை நோக்கி: நீ என்னைக்குறித்துச் சொல்லும் சாட்சியை இவர்கள் ஏற்றுக்கொள்ளமாட்டார்கள்; ஆதலால் நீ தாமதம்பண்ணாமல் சீக்கிரமாய் எருசலேமைவிட்டுப் புறப்பட்டுப்போ என்றார்.
	<br /><br />
	19. அதற்கு நான்: ஆண்டவரே, உம்மிடத்தில் விசுவாசமாயிருக்கிறவர்களை நான் காவலில் வைத்து ஜெபஆலயங்களிலே அடித்ததையும்,
	<br /><br />
	20. உம்முடைய சாட்சியாகிய ஸ்தேவானுடைய இரத்தம் சிந்தப்படுகிறபோது, நானும் அருகே நின்று, அவனைக் கொலைசெய்வதற்குச் சம்மதித்து, அவனைக் கொலைசெய்தவர்களின் வஸ்திரங்களைக் காத்துக்கொண்டிருந்ததையும், இவர்கள் அறிந்திருக்கிறார்களே என்றேன்.
	<br /><br />
	21. அதற்கு அவர்: நீ போ, நான் உன்னைத் தூரமாய்ப் புறஜாதிகளிடத்திலே அனுப்புவேன் என்று சொன்னார் என்றான்.
	<br /><br />
	22. இந்த வார்த்தைவரைக்கும் அவனுக்குச் செவிகொடுத்தார்கள். பின்பு: இப்படிப்பட்டவனைப் பூமியிலிருந்து அகற்றவேண்டும்; இவன் உயிரோடிருக்கிறது நியாயமல்லவென்று மிகுந்த சத்தமிட்டுச் சொன்னார்கள்.
	<br /><br />
	23. இவ்விதமாய் அவர்கள் கூக்குரலிட்டுத் தங்கள் மேல்வஸ்திரங்களை எறிந்துவிட்டு, ஆகாயத்திலே புழுதியைத் தூற்றிக்கொண்டிருக்கையில்,
	<br /><br />
	24. சேனாபதி அவனைக் கோட்டைக்குள்ளே கொண்டுவரும்படி கட்டளையிட்டு, அவர்கள் அவனுக்கு விரோதமாய் இப்படிக் கூக்குரலிட்ட முகாந்தரத்தை அறியும்படிக்கு அவனைச் சவுக்கால் அடித்து விசாரிக்கச் சொன்னான்.
	<br /><br />
	25. அந்தப்படி அவர்கள் அவனை வாரினால் அழுந்தக் கட்டும்போது, பவுல் சமீபமாய் நின்ற நூற்றுக்கு அதிபதியை நோக்கி: ரோமனும் நியாயம் விசாரிக்கப்படாதவனுமாயிருக்கிற மனுஷனை அடிக்கிறது உங்களுக்கு நியாயமா என்றான்.
	<br /><br />
	26. நூற்றுக்கு அதிபதி அதைக்கேட்டு, சேனாபதியினிடத்திற்குப் போய், அதை அறிவித்து: நீர் செய்யப்போகிறதைக்குறித்து எச்சரிக்கையாயிரும்; இந்த மனுஷன் ரோமன் என்றான்.
	<br /><br />
	27. அப்பொழுது சேனாபதி பவுலினிடத்தில் வந்து: நீ ரோமனா? எனக்குச் சொல் என்றான். அதற்கு அவன்: நான் ரோமன்தான் என்றான்.
	<br /><br />
	28. சேனாபதி பிரதியுத்தரமாக: நான் மிகுந்த திரவியத்தினாலே இந்தச் சிலாக்கியத்தைச் சம்பாதித்தேன் என்றான். அதற்குப் பவுல்: நானோ இந்தச் சிலாக்கியத்திற்குரியவனாகப் பிறந்தேன் என்றான்.
	<br /><br />
	29. அவனை அடித்து விசாரிக்கும்படி எத்தனமாயிருந்தவர்கள் உடனே அவனை விட்டுவிட்டார்கள். சேனாபதி அவன் ரோமனென்று அறிந்து, அவனைக் கட்டுவித்ததற்காகப் பயந்தான்.
	<br /><br />
	30. பவுலின்மேல் யூதராலே ஏற்படுத்தப்பட்ட குற்றம் இன்னதென்று நிச்சயமாய் அறிய விரும்பி, அவன் மறுநாளிலே அவனைக் கட்டவிழ்த்து, பிரதான ஆசாரியரையும் ஆலோசனைச் சங்கத்தார் அனைவரையும் கூடிவரும்படி கட்டளையிட்டு, அவனைக் கூட்டிக்கொண்டுபோய், அவர்களுக்கு முன்பாக நிறுத்தினான்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Acts22