import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Zephaniah1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | செப்பனியா 1 </title>
	<meta
          name="description"
          content="Zephaniah 1 | செப்பனியா 1 | Seppania 1 |
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. ஆமோனின் புத்திரனாகிய யோசியா என்னும் யூதா ராஜாவின் நாட்களிலே, எஸ்கியாவின் குமாரனாகிய ஆமரியாவுக்குக் குமாரனான கெதலியா என்பவனுடைய மகனாகிய கூஷின் குமாரன் செப்பனியாவுக்கு உண்டான கர்த்தருடைய வசனம்.
	<br /><br />
	2. தேசத்தில் உண்டானதை எல்லாம் முற்றிலும் வாரிக்கொள்ளுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	3. மனுஷரையும் மிருகஜீவன்களையும் வாரிக்கொள்ளுவேன்; நான் ஆகாயத்துப் பறவைகளையும், சமுத்திரத்து மச்சங்களையும், இடறுகிறதற்கேதுவானவைகளையும் துன்மார்க்கரோடேகூட வாரிக்கொண்டு, தேசத்தில் உண்டான மனுஷரைச் சங்காரம்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	4. நான் யூதாவின்மேலும், எருசலேமிலுள்ள எல்லாக் குடிகளின்மேலும் என் கையை நீட்டி, பாகாலில் மீதியாயிருக்கிறதையும், ஆசாரியர்களோடேகூடக் கெம்மரீம் என்பவர்களின் பேரையும்,
	<br /><br />
	5. வீடுகளின்மேல் வானசேனையைப் பணிகிறவர்களையும், கர்த்தர்பேரில் ஆணையிட்டு, மல்காமின்பேரிலும் ஆணையிட்டுப் பணிகிறவர்களையும்,
	<br /><br />
	6. கர்த்தரை விட்டுப் பின்வாங்குகிறவர்களையும், கர்த்தரைத் தேடாமலும், அவரைக்குறித்து விசாரியாமலுமிருக்கிறவர்களையும், இவ்விடத்தில் இராதபடிக்குச் சங்காரம்பண்ணுவேன்.
	<br /><br />
	7. கர்த்தராகிய ஆண்டவருக்கு முன்பாக மௌனமாயிருங்கள்; கர்த்தருடைய நாள் சமீபித்திருக்கிறது; கர்த்தர் ஒரு யாகத்தை ஆயத்தம்பண்ணி, அதற்கு விருந்தாளிகளையும் அழைத்திருக்கிறார்.
	<br /><br />
	8. கர்த்தருடைய யாகத்தின் நாளிலே நான் அதிபதிகளையும் ராஜகுமாரரையும் மறுதேசத்து வஸ்திரம் தரிக்கிற யாவரையும் தண்டிப்பேன்.
	<br /><br />
	9. வாசற்படியைத் தாண்டி, கொடுமையினாலும் வஞ்சகத்தினாலும் தங்கள் எஜமான்களின் வீடுகளை நிரப்புகிற யாவரையும் அந்நாளிலே தண்டிப்பேன்.
	<br /><br />
	10. அந்நாளிலே மீன்வாசலிலிருந்து கூக்குரலின் சத்தமும், நகரத்தின் இரண்டாம் பாகத்திலிருந்து அலறுதலும், மேடுகளிலிருந்து மகா சங்காரத்தின் இரைச்சலும் உண்டாகுமென்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	11. மக்தேஷின் குடிகளே அலறுங்கள்; வர்த்தகரெல்லாரும் சங்காரமானார்கள்; காசுக்காரர் யாவரும் வெட்டுண்டுபோனார்கள்.
	<br /><br />
	12. அக்காலத்திலே நான் எருசலேமை விளக்குக்கொளுத்திச் சோதித்து, வண்டல்போலக் குழம்பியிருக்கிறவர்களும், கர்த்தர் நன்மைசெய்வதும் இல்லை தீமைசெய்வதும் இல்லையென்று தங்கள் இருதயத்தில் சொல்லுகிறவர்களுமான மனுஷரைத் தண்டிப்பேன்.
	<br /><br />
	13. அவர்களுடைய ஆஸ்தி கொள்ளையாகும்; அவர்களுடைய வீடுகள் பாழாய்ப்போகும்; அவர்கள் வீடுகளைக் கட்டியும், அவைகளில் குடியிருக்கமாட்டார்கள்; அவர்கள் திராட்சத்தோட்டங்களை நாட்டியும், அவைகளின் பழரசத்தைக் குடிப்பதில்லை.
	<br /><br />
	14. கர்த்தருடைய பெரியநாள் சமீபித்திருக்கிறது; அது கிட்டிச்சேர்ந்து மிகவும் தீவிரித்துவருகிறது; கர்த்தருடைய நாளென்கிற சத்தத்துக்குப் பராக்கிரமசாலி முதலாய் அங்கே மனங்கசந்து அலறுவான்.
	<br /><br />
	15. அந்த நாள் உக்கிரத்தின் நாள்; அது இக்கட்டும் இடுக்கமுமான நாள்; அது அழிவும் பாழ்க்கடிப்புமான நாள்; அது இருளும் அந்தகாரமுமான நாள்; அது மப்பும் மந்தாரமுமான நாள்.
	<br /><br />
	16. அது அரணிப்பான நகரங்களுக்கும், உயரமான கொத்தளங்களுக்கும் விரோதமாக எக்காளம் ஊதுகிறதும் ஆர்ப்பரிக்கிறதுமான நாள்.
	<br /><br />
	17. மனுஷர் கர்த்தருக்கு விரோதமாய்ப் பாவஞ்செய்தபடியால், அவர்கள் குருடரைப்போல் நடக்கும்படி நான் அவர்களை வருத்தப்படுத்துவேன்; அவர்கள் இரத்தம் புழுதியைப்போல் சொரியப்படும்; அவர்கள் மாம்சம் எருவைப்போல் கிடக்கும்.
	<br /><br />
	18. கர்த்தருடைய உக்கிரத்தின் நாளிலே அவர்கள் வெள்ளியும் அவர்கள் பொன்னும் அவர்களைத் தப்புவிக்கமாட்டாது; அவருடைய எரிச்சலின் அக்கினியினால் தேசமெல்லாம் அழியும்; தேசத்தின் குடிகளையெல்லாம் சடிதியாய் நிர்மூலம்பண்ணுவார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Zephaniah1