import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Songs1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible உன்னதப்பாட்டு | உன்னதப்பாட்டு 1 </title>
	<meta
          name="description"
          content="Song of Solomon 1 | உன்னதப்பாட்டு 1 | 
          Tamil Bible Songs | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. சாலொமோன் பாடின உன்னதப்பாட்டு.
	<br /><br />
	2. அவர் தமது வாயின் முத்தங்களால் என்னை முத்தமிடுவாராக: உமது நேசம் திராட்சரசத்தைப்பார்க்கிலும் இன்பமானது.
	<br /><br />
	3. உமது பரிமளதைலங்கள் இன்பமான வாசனையுள்ளவைகள்; உமது நாமம் ஊற்றுண்ட பரிமளதைலமாயிருக்கிறது; ஆகையால் கன்னியர்கள் உம்மை நேசிக்கிறார்கள்.
	<br /><br />
	4. என்னை இழுத்துக்கொள்ளும், உமக்குப் பின்னே ஓடிவருவோம்; ராஜா என்னைத் தமது அறைகளில் அழைத்துக்கொண்டுவந்தார்; நாங்கள் உமக்குள் களிகூர்ந்து மகிழுவோம்; திராட்சரசத்தைப்பார்க்கிலும் உமது நேசத்தை நினைப்போம்; உத்தமர்கள் உம்மை நேசிக்கிறார்கள்.
	<br /><br />
	5. எருசலேமின் குமாரத்திகளே! கேதாரின் கூடாரங்களைப்போலவும், சாலொமோனின் திரைகளைப்போலவும் நான் கறுப்பாயிருந்தாலும், அழகாயிருக்கிறேன்.
	<br /><br />
	6. நான் கறுப்பாயிருக்கிறேன் என்று பாராதேயுங்கள்; வெயில் என்மேற்பட்டது; என் தாயின் பிள்ளைகள் என்மேல் கோபமாயிருந்து, என்னைத் திராட்சத்தோட்டங்களுக்குக் காவற்காரியாக வைத்தார்கள்; என் சொந்தத் திராட்சத்தோட்டத்தையோ நான் காக்கவில்லை.
	<br /><br />
	7. என் ஆத்தும நேசரே! உமது மந்தையை எங்கே மேய்த்து, அதை மத்தியானத்தில் எங்கே மடக்குகிறீர்? எனக்குச் சொல்லும்; உமது தோழரின் மந்தைகள் அருகே அலைந்துதிரிகிறவளைப்போல நான் இருக்கவேண்டியதென்ன?
	<br /><br />
	8. ஸ்திரீகளில் ரூபவதியே! அதை நீ அறியாயாகில், மந்தையின் காலடிகளைத் தொடர்ந்துபோய், மேய்ப்பர்களுடைய கூடாரங்களண்டையில் உன் ஆட்டுக்குட்டிகளை மேயவிடு.
	<br /><br />
	9. என் பிரியமே! பார்வோனுடைய இரதங்களில் பூண்டிருக்கிற பரிகள் பவுஞ்சுக்கு உன்னை ஒப்பிடுகிறேன்.
	<br /><br />
	10. ஆபரணாதிகள் பூண்ட உன் கன்னங்களும், ஆரங்கள் பூண்ட உன் கழுத்தும் அழகாயிருக்கிறது.
	<br /><br />
	11. வெள்ளிப் பொட்டுகளுள்ள பொன் ஆபரணங்களை உனக்குப் பண்ணுவோம்.
	<br /><br />
	12. ராஜா தமது பந்தியிலிருக்குந்தனையும் என்னுடைய நளததைலம் தன் வாசனையை வீசும்.
	<br /><br />
	13. என் நேசர் எனக்கு என் ஸ்தனங்களின் நடுவில் தங்கும் வெள்ளைப்போளச் செண்டு.
	<br /><br />
	14. என் நேசர் எனக்கு எங்கேதி ஊர் திராட்சத்தோட்டங்களில் முளைக்கும் மருதோன்றிப் பூங்கொத்து.
	<br /><br />
	15. என் பிரியமே! நீ ரூபவதி; நீ ரூபவதி; உன் கண்கள் புறாக்கண்கள்.
	<br /><br />
	16. நீர் ரூபமுள்ளவர்; என் நேசரே! நீர் இன்பமானவர்; நம்முடைய மஞ்சம் பசுமையானது.
	<br /><br />
	17. நம்முடைய வீட்டின் உத்திரங்கள் கேதுருமரம், நம்முடைய மச்சு தேவதாருமரம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Songs1