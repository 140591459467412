import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Galatians1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title>Holy Bible Tamil | கலாத்தியருக்கு எழுதின நிருபம் 1 </title>
	<meta
          name="description"
          content="Galatians | கலாத்தியர் அதிகாரம் 1 | கலாத்தியருக்கு எழுதின நிருபம் |
          Book of Galatians | Chapter 1 | Tamil Bible Verse | Tamil Bible | புதிய ஏற்பாடு | TamilBible | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மனுஷராலுமல்ல, மனுஷன் மூலமாயுமல்ல, இயேசுகிறிஸ்துவினாலும், அவரை மரித்தோரிலிருந்தெழுப்பின பிதாவாகிய தேவனாலும், அப்போஸ்தலனாயிருக்கிற பவுலாகிய நானும்,
	<br /><br />
	2. என்னுடனேகூட இருக்கிற சகோதரரெல்லாரும், கலாத்தியா நாட்டிலுள்ள சபைகளுக்கு எழுதுகிறதாவது:
	<br /><br />
	3. பிதாவாகிய தேவனாலும், நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்துவினாலும் உங்களுக்குக் கிருபையும் சமாதானமும் உண்டாவதாக;
	<br /><br />
	4. அவர் நம்மை இப்பொழுதிருக்கிற பொல்லாத பிரபஞ்சத்தினின்று விடுவிக்கும்படி நம்முடைய பிதாவாகிய தேவனுடைய சித்தத்தின்படியே நம்முடைய பாவங்களுக்காகத் தம்மைத்தாமே ஒப்புக்கொடுத்தார்;
	<br /><br />
	5. அவருக்கு என்றென்றைக்குமுள்ள சதாகாலங்களிலும் மகிமை உண்டாவதாக. ஆமென்.
	<br /><br />
	6. உங்களைக் கிறிஸ்துவின் கிருபையினாலே அழைத்தவரை நீங்கள் இவ்வளவு சீக்கிரமாய் விட்டு, வேறொரு சுவிசேஷத்திற்குத் திரும்புகிறதைப்பற்றி நான் ஆச்சரியப்படுகிறேன்;
	<br /><br />
	7. வேறொரு சுவிசேஷம் இல்லையே; சிலர் உங்களைக் கலகப்படுத்தி, கிறிஸ்துவினுடைய சுவிசேஷத்தைப் புரட்ட மனதாயிருக்கிறார்களேயல்லாமல் வேறல்ல.
	<br /><br />
	8. நாங்கள் உங்களுக்குப் பிரசங்கித்த சுவிசேஷத்தையல்லாமல், நாங்களாவது, வானத்திலிருந்து வருகிற ஒரு தூதனாவது, வேறொரு சுவிசேஷத்தை உங்களுக்குப் பிரசங்கித்தால், அவன் சபிக்கப்பட்டவனாயிருக்கக்கடவன்.
	<br /><br />
	9. முன் சொன்னதுபோல மறுபடியும் சொல்லுகிறேன்; நீங்கள் ஏற்றுக்கொண்ட சுவிசேஷத்தையல்லாமல் வேறொரு சுவிசேஷத்தை ஒருவன் உங்களுக்குப் பிரசங்கித்தால் அவன் சபிக்கப்பட்டவனாயிருக்கக்கடவன்.
	<br /><br />
	10. இப்பொழுது நான் மனுஷரையா, தேவனையா, யாரை நாடிப் போதிக்கிறேன்? மனுஷரையா பிரியப்படுத்தப் பார்க்கிறேன்? நான் இன்னும் மனுஷரைப் பிரியப்படுத்துகிறவனாயிருந்தால் நான் கிறிஸ்துவின் ஊழியக்காரனல்லவே.
	<br /><br />
	11. மேலும், சகோதரரே, என்னால் பிரசங்கிக்கப்பட்ட சுவிசேஷம் மனுஷருடைய யோசனையின்படியானதல்லவென்று உங்களுக்குத் தெரிவிக்கிறேன்.
	<br /><br />
	12. நான் அதை ஒரு மனுஷனால் பெற்றதுமில்லை, மனுஷனால் கற்றதுமில்லை, இயேசுகிறிஸ்துவே அதை எனக்கு வெளிப்படுத்தினார்.
	<br /><br />
	13. நான் யூதமார்க்கத்திலிருந்தபோது என்னுடைய நடக்கையைக்குறித்துக் கேள்விப்பட்டிருப்பீர்கள்; தேவனுடைய சபையை நான் மிகவும் துன்பப்படுத்தி, அதைப் பாழாக்கி;
	<br /><br />
	14. என் ஜனத்தாரில் என் வயதுள்ள அநேகரைப்பார்க்கிலும் யூதமார்க்கத்திலே தேறினவனாய், என் பிதாக்களுடைய பாரம்பரிய நியாயங்களுக்காக மிகவும் பக்திவைராக்கியமுள்ளவனாயிருந்தேன்.
	<br /><br />
	15. அப்படியிருந்தும், நான் என் தாயின் வயிற்றிலிருந்ததுமுதல், என்னைப் பிரித்தெடுத்து, தம்முடைய கிருபையினால் அழைத்த தேவன்,
	<br /><br />
	16. தம்முடைய குமாரனை நான் புறஜாதிகளிடத்தில் சுவிசேஷமாய் அறிவிக்கும்பொருட்டாக, அவரை எனக்குள் வெளிப்படுத்தப் பிரியமாயிருந்தபோது, உடனே நான் மாம்சத்தோடும் இரத்தத்தோடும் யோசனைபண்ணாமலும்;
	<br /><br />
	17. எனக்கு முன்னே அப்போஸ்தலரானவர்களிடத்திலே எருசலேமுக்குப் போகாமலும்; அரபிதேசத்திற்குப் புறப்பட்டுப்போய், மறுபடியும் தமஸ்கு ஊருக்குத் திரும்பிவந்தேன்.
	<br /><br />
	18. மூன்று வருஷம் சென்றபின்பு, பேதுருவைக் கண்டுகொள்ளும்படி நான் எருசலேமுக்குப் போய், அவனிடத்தில் பதினைந்துநாள் தங்கியிருந்தேன்.
	<br /><br />
	19. கர்த்தருடைய சகோதரனாகிய யாக்கோபைத் தவிர, அப்போஸ்தலரில் வேறொருவரையும் நான் காணவில்லை.
	<br /><br />
	20. நான் உங்களுக்கு எழுதுகிற இவைகள் பொய்யல்லவென்று தேவனுக்குமுன்பாக நிச்சயமாய்ச் சொல்லுகிறேன்.
	<br /><br />
	21. பின்பு, சீரியா சிலிசியா நாடுகளின் புறங்களில் வந்தேன்.
	<br /><br />
	22. மேலும் யூதேயாதேசத்திலே கிறிஸ்துவுக்குள்ளான சபைகளுக்கு முகமறியாதவனாயிருந்தேன்.
	<br /><br />
	23. முன்னே நம்மைத் துன்பப்படுத்தினவனே, தான் அழிக்கத்தேடின விசுவாசத்தை இப்பொழுது பிரசங்கிக்கிறான் என்பதைமாத்திரம் அவர்கள் கேள்விப்பட்டிருந்து,
	<br /><br />
	24. என்னைப்பற்றித் தேவனை மகிமைப்படுத்தினார்கள்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Galatians1