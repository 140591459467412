import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const OneTimothy1 = () => {
  const verseNumber = 1;

  
  return (
    <>
    <Helmet>
	<title> 1 Timothy in Tamil Bible Verse | 1 தீமோத்தேயு அதிகாரம்-1 </title>
	<meta
          name="description"
          content="FIRST LETTER TO THE Timothy Chapter 1 | தீமோத்தேயுக்கு எழுதின நிருபம்  | அதிகாரம்-1 | Tamil Bible Verse | புதிய ஏற்பாடு | New Testament"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
		<br />
		<br />
<h2>
* தவறான ஆசிரியர்களுக்கு எதிரான எச்சரிக்கை! *
			</h2>
<br />
			<br />

      {/* Vesrse of Tamilbible */}

	<p>
		1. நம்முடைய இரட்சகராயிருக்கிற தேவனும், நம்முடைய நம்பிக்கையாயிருக்கிற கர்த்தராகிய இயேசுகிறிஸ்துவும் கட்டளையிட்டபடியே, இயேசுகிறிஸ்துவின் அப்போஸ்தலனாயிருக்கிற பவுல்,
		<br />
		2. விசுவாசத்தில் உத்தம குமாரனாகிய தீமோத்தேயுவுக்கு எழுதுகிறதாவது: நம்முடைய பிதாவாகிய தேவனாலும் நம்முடைய கர்த்தராகிய கிறிஸ்து இயேசுவினாலும் கிருபையும் இரக்கமும் சமாதானமும் உண்டாவதாக.
		<br />
		3. வேற்றுமையான உபதேசங்களைப் போதியாதபடிக்கும், விசுவாசத்தினால் விளங்கும் தெய்வீக பக்திவிருத்திக்கு ஏதுவாயிராமல், தர்க்கங்களுக்கு ஏதுவாயிருக்கிற கட்டுக்கதைகளையும் முடிவில்லாத வம்சவரலாறுகளையும் கவனியாதபடிக்கும், நீ சிலருக்குக் கட்டளையிடும்பொருட்டாக,
		<br />
		4. நான் மக்கெதோனியாவுக்குப் போகும்போது உன்னை எபேசு பட்டணத்திலிருக்க வேண்டிக்கொண்டபடியே செய்வாயாக.
		<br />
		5. கற்பனையின் பொருள் என்னவெனில், சுத்தமான இருதயத்திலும் நல்மனச்சாட்சியிலும் மாயமற்ற விசுவாசத்திலும் பிறக்கும் அன்பே.
		<br />
		6. இவைகளைச் சிலர் நோக்காமல் வீண்பேச்சுக்கு இடங்கொடுத்து விலகிப்போனார்கள்.
		<br />
		7. தாங்கள் சொல்லுகிறது இன்னதென்றும், தாங்கள் சாதிக்கிறது இன்னதென்றும் அறியாதிருந்தும், நியாயப்பிரமாண போதகராயிருக்க விரும்புகிறார்கள்.
		<br />
		8. ஒருவன் நியாயப்பிரமாணத்தை நியாயப்படி அநுசரித்தால், நியாயப்பிரமாணம் நல்லதென்று அறிந்திருக்கிறோம்.
		<br />
		9. எங்களுக்குத் தெரிந்திருக்கிறபடி, நியாயப்பிரமாணம் நீதிமானுக்கு விதிக்கப்படாமல், அக்கிரமக்காரருக்கும், அடங்காதவர்களுக்கும், பக்தியில்லாதவர்களுக்கும், பாவிகளுக்கும், அசுத்தருக்கும், சீர்கெட்டவர்களுக்கும், தாய்தகப்பன்மாரை அடிக்கிறவர்களுக்கும், கொலைபாதகருக்கும்,
		<br />
		10. வேசிக்கள்ளருக்கும், ஆண்புணர்ச்சிக்காரருக்கும், மனுஷரைத் திருடுகிறவர்களுக்கும், பொய்யருக்கும், பொய்யாணை இடுகிறவர்களுக்கும்,
		<br />
		11. நித்தியானந்த தேவனுடைய மகிமையான சுவிசேஷத்தின்படி எனக்கு ஒப்புவிக்கப்பட்டிருக்கிற ஆரோக்கியமான உபதேசத்திற்கு எதிரிடையாயிருக்கிற மற்றெந்தச் செய்கைக்கும் விரோதமாய் விதிக்கப்பட்டிருக்கிறது.
		<br />
		12. என்னைப் பலப்படுத்துகிற நம்முடைய கர்த்தராகிய இயேசுகிறிஸ்து என்னை உண்மையுள்ளவனென்றெண்ணி, இந்த ஊழியத்திற்கு ஏற்படுத்தினபடியால், அவரை ஸ்தோத்திரிக்கிறேன்.
		<br />
		13. முன்னே நான் தூஷிக்கிறவனும், துன்பப்படுத்துகிறவனும், கொடுமை செய்கிறவனுமாயிருந்தேன்; அப்படியிருந்தும், நான் அறியாமல் அவிசுவாசத்தினாலே அப்படிச் செய்தபடியினால் இரக்கம்பெற்றேன்.
		<br />
		14. நம்முடைய கர்த்தரின் கிருபை கிறிஸ்து இயேசுவின்மேலுள்ள விசுவாசத்தோடும் அன்போடுங்கூட என்னிடத்தில் பரிபூரணமாய்ப் பெருகிற்று.
		<br />
		15. பாவிகளை இரட்சிக்க கிறிஸ்து இயேசு உலகத்தில் வந்தார் என்கிற வார்த்தை உண்மையும் எல்லா அங்கிகரிப்புக்கும் பாத்திரமுமானது; அவர்களில் பிரதான பாவி நான்.
		<br />
		16. அப்படியிருந்தும், நித்திய ஜீவனை அடையும்படி இனிமேல் இயேசுகிறிஸ்துவினிடத்தில் விசுவாசமாயிருப்பவர்களுக்குத் திருஷ்டாந்தம் உண்டாகும்பொருட்டுப் பிரதான பாவியாகிய என்னிடத்தில் அவர் எல்லா நீடிய பொறுமையையும் காண்பிக்கும்படிக்கு இரக்கம்பெற்றேன்.
		<br />
		17. நித்தியமும் அழிவில்லாமையும் அதரிசனமுமுள்ள ராஜனுமாய், தாம் ஒருவரே ஞானமுள்ள தேவனுமாயிருக்கிறவருக்கு, கனமும் மகிமையும் சதாகாலங்களிலும் உண்டாயிருப்பதாக. ஆமென்.
		<br />
		18. குமாரனாகிய தீமோத்தேயுவே, உன்னைக்குறித்து முன் உண்டான தீர்க்கதரிசனங்களின்படியே, நீ அவைகளை முன்னிட்டு நல்ல போராட்டம்பண்ணும்படி, இந்தக் கட்டளையை உனக்கு ஒப்புவிக்கிறேன்; நீ விசுவாசமும் நல்மனச்சாட்சியும் உடையவனாயிரு.
		<br />
		19. இந்த நல்மனச்சாட்சியைச் சிலர் தள்ளிவிட்டு, விசுவாசமாகிய கப்பலைச் சேதப்படுத்தினார்கள்.
		<br />
		20. இமெனேயும் அலெக்சந்தரும் அப்படிப்பட்டவர்கள்; அவர்கள் தூஷியாதபடி சிட்சிக்கப்பட அவர்களைச் சாத்தானிடத்தில் ஒப்புக்கொடுத்தேன்.
	</p>


      {/* End of verse */} 

	  <br />
		<br />
<h4>
* I தீமோத்தேயு 1 அப்போஸ்தலன் பவுலின் தனிப்பட்ட வாழ்த்துடன் தொடங்குகிறது. நியாயப்பிரமாணத்தை ஒருவர் சட்டரீதியாகப் பயன்படுத்தினால் அது நல்லது என்று பவுல் தீமோத்தேயுவுக்கு நினைவுபடுத்துகிறார், அதாவது கண்டிக்க வேண்டும். பின்னர் அவர் தன்னை ஒரு உதாரணமாகப் பயன்படுத்தி கடவுளின் கிருபையை நினைவுபடுத்துகிறார். பவுல், பாவிகள் மிக மோசமான கருணை காட்டப்பட்டது அதனால் கிறிஸ்துவின் வரம்பற்ற கிருபை காட்டப்படும். தீமோத்தேயுவை நல்ல போராட்டத்தை எதிர்த்துப் போராடவும், அவரது மனசாட்சியை தெளிவாக வைத்திருக்கவும் ஊக்குவிக்கும் வகையில் பவுல் இந்த அத்தியாயத்தை முடிக்கிறார்.*
			</h4>
<br />
			<br /> 
      </div>
    </div>
    </>
  );
}

export default OneTimothy1