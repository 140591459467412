import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const John14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Gospel of John in Tamil Bible | யோவான் 14 </title>
	<meta
          name="description"
          content="John Chapter 14 | யோவான் அதிகாரம் 14 | Tamil Bible Story |
          Gospel of John Intro | Tamil Bible Verse | Tamil Bible | யோவான் எழுதின சுவிசேஷம் | புதிய ஏற்பாடு | TamilVerse | Bible in Tamil | New Testament | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. உங்கள் இருதயம் கலங்காதிருப்பதாக; தேவனிடத்தில் விசுவாசமாயிருங்கள், என்னிடத்திலும் விசுவாசமாயிருங்கள்.
	<br /><br />
	2. என் பிதாவின் வீட்டில் அநேக வாசஸ்தலங்கள் உண்டு; அப்படியில்லாதிருந்தால், நான் உங்களுக்குச் சொல்லியிருப்பேன்; ஒரு ஸ்தலத்தை உங்களுக்காக ஆயத்தம்பண்ணப்போகிறேன்.
	<br /><br />
	3. நான் போய் உங்களுக்காக ஸ்தலத்தை ஆயத்தம்பண்ணினபின்பு, நான் இருக்கிற இடத்திலே நீங்களும் இருக்கும்படி, நான் மறுபடியும் வந்து உங்களை என்னிடத்தில் சேர்த்துக்கொள்ளுவேன்.
	<br /><br />
	4. நான் போகிற இடத்தை அறிந்திருக்கிறீர்கள், வழியையும் அறிந்திருக்கிறீர்கள் என்றார்.
	<br /><br />
	5. தோமா அவரை நோக்கி: ஆண்டவரே, நீர் போகிற இடத்தை அறியோமே, வழியை நாங்கள் எப்படி அறிவோம் என்றான்.
	<br /><br />
	6. அதற்கு இயேசு: நானே வழியும் சத்தியமும் ஜீவனுமாயிருக்கிறேன்; என்னாலேயல்லாமல் ஒருவனும் பிதாவினிடத்தில் வரான்.
	<br /><br />
	7. என்னை அறிந்தீர்களானால் என் பிதாவையும் அறிந்திருப்பீர்கள்; இதுமுதல் நீங்கள் அவரை அறிந்தும் அவரைக் கண்டும் இருக்கிறீர்கள் என்றார்.
	<br /><br />
	8. பிலிப்பு அவரை நோக்கி: ஆண்டவரே, பிதாவை எங்களுக்குக் காண்பியும், அது எங்களுக்குப் போதும் என்றான்.
	<br /><br />
	9. அதற்கு இயேசு: பிலிப்புவே, இவ்வளவுகாலம் நான் உங்களுடனேகூட இருந்தும் நீ என்னை அறியவில்லையா? என்னைக் கண்டவன் பிதாவைக் கண்டான்; அப்படியிருக்க, பிதாவை எங்களுக்குக் காண்பியும் என்று நீ எப்படிச் சொல்லுகிறாய்?
	<br /><br />
	10. நான் பிதாவிலும், பிதா என்னிலும் இருக்கிறதை நீ விசுவாசிக்கிறதில்லையா? நான் உங்களுடனே சொல்லுகிற வசனங்களை என் சுயமாய்ச் சொல்லவில்லை; என்னிடத்தில் வாசமாயிருக்கிற பிதாவானவரே இந்தக் கிரியைகளைச் செய்துவருகிறார்.
	<br /><br />
	11. நான் பிதாவிலும் பிதா என்னிலும் இருக்கிறதை நம்புங்கள்; அப்படியில்லாவிட்டாலும் என் கிரியைகளினிமித்தமாவது என்னை நம்புங்கள்.
	<br /><br />
	12. மெய்யாகவே மெய்யாகவே நான் உங்களுக்குச் சொல்லுகிறேன்; நான் என் பிதாவினிடத்திற்குப் போகிறபடியினால், என்னை விசுவாசிக்கிறவன் நான் செய்கிற கிரியைகளைத் தானும் செய்வான், இவைகளைப்பார்க்கிலும் பெரிய கிரியைகளையும் செய்வான்.
	<br /><br />
	13. நீங்கள் என் நாமத்தினாலே எதைக் கேட்பீர்களோ, குமாரனில் பிதா மகிமைப்படும்படியாக, அதைச் செய்வேன்.
	<br /><br />
	14. என் நாமத்தினாலே நீங்கள் எதைக் கேட்டாலும் அதை நான் செய்வேன்.
	<br /><br />
	15. நீங்கள் என்னிடத்தில் அன்பாயிருந்தால் என் கற்பனைகளைக் கைக்கொள்ளுங்கள்.
	<br /><br />
	16. நான் பிதாவை வேண்டிக்கொள்ளுவேன், அப்பொழுது என்றென்றைக்கும் உங்களுடனேகூட இருக்கும்படிக்குச் சத்திய ஆவியாகிய வேறொரு தேற்றரவாளனை அவர் உங்களுக்குத் தந்தருளுவார்.
	<br /><br />
	17. உலகம் அந்தச் சத்திய ஆவியானவரைக் காணாமலும் அறியாமலும் இருக்கிறபடியால் அவரைப் பெற்றுக்கொள்ளமாட்டாது; அவர் உங்களுடனே வாசம்பண்ணி உங்களுக்குள்ளே இருப்பதால், நீங்கள் அவரை அறிவீர்கள்.
	<br /><br />
	18. நான் உங்களைத் திக்கற்றவர்களாக விடேன், உங்களிடத்தில் வருவேன்.
	<br /><br />
	19. இன்னும் கொஞ்சக்காலத்திலே உலகம் என்னைக் காணாது, நீங்களோ என்னைக் காண்பீர்கள்; நான் பிழைக்கிறபடியினால் நீங்களும் பிழைப்பீர்கள்.
	<br /><br />
	20. நான் என் பிதாவிலும், நீங்கள் என்னிலும், நான் உங்களிலும் இருக்கிறதை அந்நாளிலே நீங்கள் அறிவீர்கள்.
	<br /><br />
	21. என் கற்பனைகளைப் பெற்றுக்கொண்டு அவைகளைக் கைக்கொள்ளுகிறவனே என்னிடத்தில் அன்பாயிருக்கிறான், என்னிடத்தில் அன்பாயிருக்கிறவன் என் பிதாவுக்கு அன்பாயிருப்பான்; நானும் அவனில் அன்பாயிருந்து, அவனுக்கு என்னை வெளிப்படுத்துவேன் என்றார்.
	<br /><br />
	22. ஸ்காரியோத்தல்லாத யூதா என்பவன் அவரை நோக்கி: ஆண்டவரே, நீர் உலகத்துக்கு உம்மை வெளிப்படுத்தாமல் எங்களுக்கு உம்மை வெளிப்படுத்தப்போகிற காரணமென்ன என்றான்.
	<br /><br />
	23. இயேசு அவனுக்குப் பிரதியுத்தரமாக: ஒருவன் என்னில் அன்பாயிருந்தால், அவன் என் வசனத்தைக் கைக்கொள்ளுவான், அவனில் என் பிதா அன்பாயிருப்பார்; நாங்கள் அவனிடத்தில் வந்து அவனோடே வாசம்பண்ணுவோம்.
	<br /><br />
	24. என்னில் அன்பாயிராதவன் என் வசனங்களைக் கைக்கொள்ளமாட்டான். நீங்கள் கேட்கிற வசனம் என்னுடையதாயிராமல் என்னை அனுப்பின பிதாவினுடையதாயிருக்கிறது.
	<br /><br />
	25. நான் உங்களுடனே தங்கியிருக்கையில் இவைகளை உங்களுக்குச் சொன்னேன்.
	<br /><br />
	26. என் நாமத்தினாலே பிதா அனுப்பப்போகிற பரிசுத்த ஆவியாகிய தேற்றரவாளனே எல்லாவற்றையும் உங்களுக்குப் போதித்து, நான் உங்களுக்குச் சொன்ன எல்லாவற்றையும் உங்களுக்கு நினைப்பூட்டுவார்.
	<br /><br />
	27. சமாதானத்தை உங்களுக்கு வைத்துப்போகிறேன், என்னுடைய சமாதானத்தையே உங்களுக்குக் கொடுக்கிறேன்; உலகம் கொடுக்கிறபிரகாரம் நான் உங்களுக்குக் கொடுக்கிறதில்லை. உங்கள் இருதயம் கலங்காமலும் பயப்படாமலும் இருப்பதாக.
	<br /><br />
	28. நான் போவேன் என்றும், திரும்பி உங்களிடத்தில் வருவேன் என்றும் நான் உங்களுடனே சொன்னதைக் கேட்டீர்களே. நீங்கள் என்னில் அன்புள்ளவர்களாயிருந்தால் பிதாவினிடத்திற்குப் போகிறேனென்று நான் சொன்னதைக் குறித்துச் சந்தோஷப்படுவீர்கள், ஏனெனில் என் பிதா என்னிலும் பெரியவராயிருக்கிறார்.
	<br /><br />
	29. இது நடக்கும்போது நீங்கள் விசுவாசிக்கும்படியாக, நடப்பதற்குமுன்னமே இதை உங்களுக்குச் சொன்னேன்.
	<br /><br />
	30. இனி நான் உங்களுடனே அதிகமாய்ப் பேசுவதில்லை. இந்த உலகத்தின் அதிபதி வருகிறான், அவனுக்கு என்னிடத்தில் ஒன்றுமில்லை.
	<br /><br />
	31. நான் பிதாவில் அன்பாயிருக்கிறேன் என்றும், பிதா எனக்குக் கட்டளையிட்டபடியே செய்கிறேன் என்றும், உலகம் அறியும்படிக்கு இப்படி நடக்கும். எழுந்திருங்கள், இவ்விடம்விட்டுப் போவோம் வாருங்கள் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default John14