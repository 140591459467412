import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah14 = () => {
  const verseNumber = 14;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 14 </title>
	<meta
          name="description"
          content="Jeremiah 14 | எரேமியா 14 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. மழைத்தாழ்ச்சியைக் குறித்து எரேமியாவுக்கு உண்டான கர்த்தருடைய வசனம்:
	<br /><br />
	2. யூதா துக்கிக்கிறது, அதின் வாசல்கள் பெலனற்றுக் கிடக்கிறது; தரைமட்டும் குனிந்து, கரிகறுத்துத் திரிகிறார்கள்; எருசலேமின் கூக்குரல் எழும்புகிறது.
	<br /><br />
	3. அவர்களில் பிரபலமானவர்கள் தங்கள் சிறுவர்களைத் தண்ணீருக்கு அனுப்புகிறார்கள்; இவர்கள் பள்ளங்களுக்குப் போய்த் தண்ணீரைக் காணாமல் வெறும் பாத்திரங்களோடே திரும்பிவருகிறார்கள்; வெட்கி நாணி, தங்கள் தலையை மூடிக்கொள்ளுகிறார்கள்.
	<br /><br />
	4. தேசத்தின்மேல் மழை இல்லாததினால் தரை வெடித்திருக்கிறது; பயிர் செய்கிறவர்கள் வெட்கி, தங்கள் தலையை மூடிக்கொள்ளுகிறார்கள்.
	<br /><br />
	5. வெளியின் பெண்மானும் குட்டிபோட்டு, புல்லில்லாததினால் அதை விட்டு ஓடிப்போகும்.
	<br /><br />
	6. காட்டுக்கழுதைகள் மேடுகளில் நின்று, வலுசர்ப்பங்களைப்போல் காற்றை உட்கொள்ளுகிறது; புல் இல்லாததினால் அவைகளுடைய கண்கள் பூத்துப்போகிறது என்றார்.
	<br /><br />
	7. கர்த்தாவே, எங்கள் அக்கிரமங்கள் எங்களுக்கு விரோதமாய்ச் சாட்சியிட்டாலும், உம்முடைய நாமத்தினிமித்தம் கிருபைசெய்யும்; எங்கள் சீர்கேடுகள் மிகுதியாயிருக்கிறது; உமக்கு விரோதமாய்ப் பாவஞ்செய்தோம்.
	<br /><br />
	8. இஸ்ரவேலின் நம்பிக்கையே, ஆபத்துக்காலத்தில் அதின் இரட்சகரே, நீர் தேசத்தில் பரதேசியைப்போலவும், இராத்தங்க இறங்குகிற வழிப்போக்கனைப்போலவும் இருப்பானேன்?
	<br /><br />
	9. நீர் விடாய்த்துப்போன புருஷனைப்போலவும், இரட்சிக்கமாட்டாத பராக்கிரமசாலியைப்போலவும் இருப்பானேன்? கர்த்தாவே, நீர் எங்கள் நடுவிலிருக்கிறவராமே; உம்முடைய நாமம் எங்களுக்குத் தரிக்கப்பட்டுமிருக்கிறதே; எங்களை விட்டுப் போகாதிரும்.
	<br /><br />
	10. அவர்கள் தங்கள் கால்களை அடக்கிக்கொள்ளாமல், அலைய விரும்புகிறார்களென்று கர்த்தர் இந்த ஜனத்தைக்குறித்துச் சொல்லுகிறார்; ஆகையால், கர்த்தர் அவர்கள்மேல் பிரியமாயிராமல், இப்பொழுது அவர்கள் அக்கிரமத்தை நினைத்து, அவர்கள் பாவங்களை விசாரிப்பார்.
	<br /><br />
	11. கர்த்தர் என்னை நோக்கி: நீ இந்த ஜனத்துக்கு நன்மையுண்டாக விண்ணப்பம்பண்ணவேண்டாம்.
	<br /><br />
	12. அவர்கள் உபவாசித்தாலும், நான் அவர்கள் விண்ணப்பத்தைக் கேட்பதில்லை; அவர்கள் தகனபலிகளையும் காணிக்கைகளையும் செலுத்தினாலும், நான் அவர்கள்மேல் பிரியமாயிருப்பதில்லை; பட்டயத்தினாலும் பஞ்சத்தினாலும் கொள்ளைநோயினாலும் நான் அவர்களை நிர்மூலமாக்குவேன் என்றார்.
	<br /><br />
	13. அப்பொழுது நான்: ஆ கர்த்தராகிய ஆண்டவரே, இதோ, நீங்கள் பட்டயத்தைக் காண்பதில்லை, உங்களுக்குப் பஞ்சமும் வருவதில்லை; உறுதியான சமாதானத்தையே இவ்விடத்தில் உங்களுக்குத் தருவோமென்றார் என்று தீர்க்கதரிசிகள் அவர்களுக்குச் சொல்லுகிறார்களே என்றேன்.
	<br /><br />
	14. அப்பொழுது கர்த்தர் என்னை நோக்கி: தீர்க்கதரிசிகள் என் நாமத்தைக்கொண்டு பொய்யாய்த் தீர்க்கதரிசனஞ் சொல்லுகிறார்கள். நான் அவர்களை அனுப்பினதுமில்லை, அவர்களுக்குக் கற்பித்ததுமில்லை, அவர்களோடே பேசினதுமில்லை; அவர்கள் கள்ளத் தரிசனத்தையும், கள்ள சகுனத்தையும், இல்லாத விசேஷத்தையும், தங்கள் இருதயத்தின் கபடத்தையுமே, உங்களுக்குத் தீர்க்கதரிசனமாய்ச் சொல்லுகிறார்கள்.
	<br /><br />
	15. ஆதலால், நான் அனுப்பாதிருந்தும், என் நாமத்தைக்கொண்டு தீர்க்கதரிசனஞ்சொல்லி, இந்தத் தேசத்திலே பட்டயமும் பஞ்சமும் வருவதில்லையென்கிற தீர்க்கதரிசிகளைக்குறித்து: இப்படிப்பட்ட தீர்க்கதரிசிகள் பட்டயத்தாலும் பஞ்சத்தாலும் சாவார்கள்.
	<br /><br />
	16. அவர்களிடத்தில் தீர்க்கதரிசனம் கேட்கும் ஜனங்களும், எருசலேமின் வீதிகளிலே பட்டயத்தாலும் பஞ்சத்தாலும் அழிந்து, அவர்களும் அவர்கள் மனைவிகளும், அவர்கள் குமாரரும், அவர்கள் குமாரத்திகளும் அடக்கம் பண்ணுவாரில்லாமல் கிடப்பார்கள்; அவர்களுடைய பொல்லாப்பை அவர்கள்மேல் வரப்பண்ணுவேன் என்று கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. என் கண்களிலிருந்து இரவும் பகலும் ஓயாமல் கண்ணீர் ஓடிக்கொண்டிருக்கும்; என் ஜனமென்கிற குமாரத்தியாகிய கன்னிகை மகா வேதனையுள்ள அடியினாலும் கொடிய காயத்தினாலும் சேதப்பட்டிருக்கிறாள்.
	<br /><br />
	18. நான் வெளியே போனால், இதோ, பட்டயத்தால் கொல்லப்பட்டவர்கள்; நகரத்தில் வந்தால் இதோ, பஞ்சத்தால் வருந்துகிறவர்கள்; தீர்க்கதரிசிகளும் ஆசாரியர்களும் ஒன்றும் அறியாமல் தேசத்தில் அலைகிறார்கள் என்னும் இந்த வார்த்தையை அவர்களுக்குச் சொல் என்றார்.
	<br /><br />
	19. யூதாவை முற்றிலும் வெறுத்தீரோ? சீயோன் உம்முடைய ஆத்துமாவுக்கு அரோசிகமாயிற்றோ? நாங்கள் ஆரோக்கியம் அடையக்கூடாதபடி எங்களை ஏன் அடித்தீர்? சமாதானத்துக்குக் காத்திருந்தோம், ஒரு நன்மையுமில்லை; ஆரோக்கிய காலத்துக்குக் காத்திருந்தோம், இதோ, ஆபத்து.
	<br /><br />
	20. கர்த்தாவே, எங்கள் தீமையையும் எங்கள் பிதாக்களின் அக்கிரமத்தையும் நாங்கள் அறிந்திருக்கிறோம்; உமக்கு விரோதமாய்ப் பாவஞ்செய்தோம்.
	<br /><br />
	21. உம்முடைய நாமத்தினிமித்தம் எங்களை அருவருக்காதிரும், உமது மகிமையின் சிங்காசனத்தைக் கனவீனப்படுத்தாதேயும்; எங்களோடே உமக்கு இருக்கிற உடன்படிக்கை அபத்தமாகாதபடி எங்களை நினைத்தருளும்.
	<br /><br />
	22. புறஜாதிகளுடைய வீணான தேவர்களுக்குள் மழை வருஷிக்கப்பண்ணத்தக்கவர்கள் உண்டோ? அல்லது, வானங்கள் தானாய் மழைகளைக் கொடுக்குமோ? எங்கள் தேவனாகிய கர்த்தராயிருக்கிற நீரல்லவோ அதைச் செய்கிறவர்; ஆகையால், உமக்குக் காத்திருக்கிறோம்; தேவரீர் இவைகளையெல்லாம் உண்டுபண்ணினீர்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah14