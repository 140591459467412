import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Jeremiah39 = () => {
  const verseNumber = 39;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Prophets | எரேமியா 39 </title>
	<meta
          name="description"
          content="Jeremiah 39 | எரேமியா 39 |
          Eramia Prophets | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
        />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. யூதாவின் ராஜாவாகிய சிதேக்கியா அரசாண்ட ஒன்பதாம் வருஷம் பத்தாம் மாதத்திலே பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரும் அவனுடைய எல்லா இராணுவமும் எருசலேமுக்கு விரோதமாய் வந்து, அதை முற்றிக்கைபோட்டார்கள்.
	<br /><br />
	2. சிதேக்கியா அரசாண்ட பதினோராம் வருஷம் நாலாம் மாதம், ஒன்பதாம் தேதியிலே நகரத்து மதிலில் திறப்புக்கண்டது.
	<br /><br />
	3. அப்பொழுது பாபிலோன் ராஜாவின் பிரபுக்களாகிய நெர்கல்சரேத்சேர், சம்கார்நேபோ, சர்சேகிம், ரப்சாரீஸ், நெர்கல்சரேத்சேர், ரப்மாக் என்பவர்களும், பாபிலோன் ராஜாவின் மற்ற எல்லாப் பிரபுக்களும் உட்பிரவேசித்து, நடுவாசலில் இருந்தார்கள்.
	<br /><br />
	4. அப்பொழுது யூதாவின் ராஜாவாகிய சிதேக்கியாவும் சகல யுத்த மனுஷரும் அவர்களைக் கண்டபோது, ஓடி, இராத்திரிகாலத்தில் ராஜாவுடைய தோட்டத்துவழியே, இரண்டு மதில்களுக்கு நடுவான வாசலால் நகரத்திலிருந்து புறப்பட்டுப்போனார்கள்; அவன் வயல்வெளியின் வழியே போய்விட்டான்.
	<br /><br />
	5. ஆனாலும், கல்தேயருடைய இராணுவம் அவர்களைப் பின்தொடர்ந்து, எரிகோவின் சமபூமியில் சிதேக்கியாவைக் கிட்டி, அவனைப் பிடித்து, அவனை ஆமாத்தேசத்தின் ஊராகிய ரிப்லாவுக்கு, பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சாரிடத்தில் கொண்டுபோனார்கள்; அங்கே இவன் அவனைக் குறித்துத் தீர்ப்புச்செய்தான்.
	<br /><br />
	6. பின்பு பாபிலோன் ராஜா ரிப்லாவிலே, சிதேக்கியாவின் குமாரரை அவன் கண்களுக்கு முன்பாக வெட்டுவித்தான்; யூதா பிரபுக்கள் அனைவரையும் பாபிலோன் ராஜா வெட்டி,
	<br /><br />
	7. சிதேக்கியாவின் கண்களைக் கெடுத்து, அவனைப் பாபிலோனுக்குக் கொண்டுபோக அவனுக்கு இரண்டு வெண்கல விலங்குகளைப் போட்டான்.
	<br /><br />
	8. கல்தேயர் ராஜாவின் அரமனையையும் ஜனத்தின் வீடுகளையும் அக்கினியால் சுட்டெரித்து, எருசலேமின் மதில்களை இடித்துப்போட்டார்கள்.
	<br /><br />
	9. நகரத்தில் தங்கியிருந்த ஜனங்களையும், தன் பட்சத்தில் ஓடிவந்துவிட்டவர்களையும், மீதியான மற்ற ஜனங்களையும், காவற் சேனாதிபதியாகிய நேபுசராதான் பாபிலோனுக்குச் சிறைகளாகக் கொண்டுபோனான்.
	<br /><br />
	10. காவற்சேனாதிபதியாகிய நேபுசராதான் ஒன்றுமில்லாத ஏழைகளில் சிலரை யூதா தேசத்திலே வைத்து, அவர்களுக்கு அந்நாளிலே திராட்சத்தோட்டங்களையும் வயல்நிலங்களையும் கொடுத்தான்.
	<br /><br />
	11. ஆனாலும் எரேமியாவைக் குறித்து, பாபிலோன் ராஜாவாகிய நேபுகாத்நேச்சார் காவற்சேனாதிபதியாகிய நேபுசராதானை நோக்கி:
	<br /><br />
	12. நீ அவனை அழைப்பித்து, அவனுக்கு ஒரு பொல்லாப்பும் செய்யாமல், அவனைப் பத்திரமாய்ப் பார்த்து, அவன் உன்னோடே சொல்லுகிறபடியெல்லாம் அவனை நடத்தென்று கட்டளைகொடுத்தான்.
	<br /><br />
	13. அப்படியே காவற்சேனாதிபதியாகிய நேபுசராதானும், நேபுசஸ்பான், ரப்சாரீஸ், நெர்கல்சரேத்சேர், ரப்மாக் என்பவர்களும், பாபிலோன் ராஜாவின் எல்லாப் பிரபுக்களும்,
	<br /><br />
	14. எரேமியாவைக் காவற்சாலையின் முற்றத்திலிருந்து வரவழைத்து, அவனை வெளியே வீட்டுக்கு அழைத்துக்கொண்டுபோகும்படிக்கு அவனைச் சாப்பானுடைய குமாரனாகிய அகிக்காமின் மகனான கெதலியாவினிடத்தில் ஒப்புவித்தார்கள்; அப்படியே அவன் ஜனத்துக்குள்ளே தங்கியிருந்தான்.
	<br /><br />
	15. இதுவுமல்லாமல், எரேமியா இன்னும் காவற்சாலையின் முற்றத்திலே அடைக்கப்பட்டிருக்கையில், அவனுக்குக் கர்த்தரால் உண்டான வசனம்:
	<br /><br />
	16. நீ போய், எத்தியோப்பியனாகிய எபெத்மெலேக்குக்குச் சொல்லவேண்டியது என்னவென்றால், இதோ, என்னுடைய வார்த்தைகளை இந்த நகரத்தின்மேல் நன்மையாக அல்ல, தீமையாகவே வரப்பண்ணுவேன்; அவைகள் அந்நாளிலே உன் கண்களுக்கு முன்பாக நிறைவேறும் என்று இஸ்ரவேலின் தேவனாகிய சேனைகளின் கர்த்தர் சொல்லுகிறார்.
	<br /><br />
	17. ஆனால் அந்நாளிலே உன்னைத் தப்புவிப்பேன் என்று கர்த்தர் சொல்லுகிறார்; நீ பயப்படுகிற மனுஷரின் கையிலே ஒப்புக்கொடுக்கப்படுவதில்லை.
	<br /><br />
	18. உன்னை நிச்சயமாக விடுவிப்பேன், நீ பட்டயத்துக்கு இரையாவதில்லை; நீ என்னை நம்பினபடியினால் உன் பிராணன் உனக்குக் கிடைத்த கொள்ளைப்பொருளைப்போல இருக்குமென்று கர்த்தர் சொல்லுகிறார் என்றார்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Jeremiah39