import React from 'react';
import Header from './Header';
import '../verse.css';
import { Helmet } from 'react-helmet';

const Micah3 = () => {
  const verseNumber = 3;

  
  return (
    <>
    <Helmet>
	<title>Tamil Bible Status | மீகா 3 </title>
	<meta
          name="description"
          content="Micah 3 | மீகா 3 | Meega 3|
          Tamil Status | Tamil Bible Verse | Tamil Bible | Bible | TamilBible | TamilBibleVerse | Bible Verse | TamilVerse | Bible in Tamil | தமிழ் பரிசுத்த வேதாகமம் | TAMIL HOLY BIBLE | Tamil Bible Verses"
    />
  </Helmet>
    <div className='vb'>
      <Header verseNumber={verseNumber} />
      <div  style={{"text-align": "justify"}}  className='verse'>
      {/* Vesrse of Tamilbible */}
      <p>
	1. நான் சொன்னது: யாக்கோபின் தலைவர்களே, இஸ்ரவேல் வம்சத்து அதிபதிகளே, நியாயம் இன்னதென்று அறிவது உங்களுக்கு அல்லவோ அடுத்தது.
	<br /><br />
	2. ஆனாலும் நன்மையை வெறுத்து, தீமையை விரும்பி, அவர்கள்மேல் இருக்கிற அவர்களுடைய தோலையும் அவர்கள் எலும்புகள்மேல் இருக்கிற அவர்களுடைய சதையையும் பிடுங்கி,
	<br /><br />
	3. என் ஜனத்தின் சதையைத் தின்று, அவர்கள்மேல் இருக்கிற அவர்களுடைய தோலை உரிந்துகொண்டு, அவர்கள் எலும்புகளை முறித்து, பானையிலே போடும்வண்ணமாகவும் இறைச்சியைக் கொப்பரைக்குள்ளே போடும்வண்ணமாகவும் அவைகளைத் துண்டிக்கிறார்கள்.
	<br /><br />
	4. அப்பொழுது அவர்கள் கர்த்தரை நோக்கிக் கூப்பிடுவார்கள்; ஆனாலும் அவர்கள் தங்கள் கிரியைகளில் பொல்லாதவர்களானபடியினால், அவர் அவர்களுக்கு மறுஉத்தரவு கொடாமல், தமது முகத்தை அக்காலத்திலே அவர்களுக்கு மறைத்துக்கொள்ளுவார்.
	<br /><br />
	5. தங்கள் பற்களினால் கடிக்கிறவர்களாயிருந்து, சமாதானமென்று சொல்லி, தங்கள் வாய்க்கு உணவைக் கொடாதவனுக்கு விரோதமாகச் சண்டைக்கு ஆயத்தம்பண்ணி, என் ஜனத்தை மோசம்போக்குகிற தீர்க்கதரிசிகளுக்கு விரோதமாய்க் கர்த்தர் சொல்லுகிறது என்னவென்றால்:
	<br /><br />
	6. தரிசனங்காணக்கூடாத இராத்திரியும், குறிசொல்லக்கூடாத அந்தகாரமும் உங்களுக்கு வரும்; தீர்க்கதரிசிகளின்மேல் சூரியன் அஸ்தமித்து, அவர்கள்மேல் பகல் காரிருளாய்ப் போகும்.
	<br /><br />
	7. தரிசனம் பார்க்கிறவர்கள் வெட்கி, குறிசொல்லுகிறவர்கள் நாணி, உத்தரவுகொடுக்கிற தேவன் இல்லாததினால் அவர்கள் எல்லாரும் தங்கள் வாயை மூடுவார்கள்.
	<br /><br />
	8. நானோ, யாக்கோபுக்கு அவன் மீறுதலையும், இஸ்ரவேலுக்கு அவன் பாவத்தையும் அறிவிக்கும்படி, கர்த்தருடைய ஆவி அருளிய பலத்தினாலும், நியாயத்தினாலும், பராக்கிரமத்தினாலும் நிரப்பப்பட்டிருக்கிறேன்.
	<br /><br />
	9. நியாயத்தை அருவருத்து, செம்மையானவைகளையெல்லாம் கோணலாக்கி,
	<br /><br />
	10. சீயோனை இரத்தப்பழியினாலும், எருசலேமை அநியாயத்தினாலும் கட்டுவிக்கிற யாக்கோபு வம்சத்துத் தலைவர்களே, இஸ்ரவேல் வம்சத்து அதிபதிகளே, இதைக் கேளுங்கள்.
	<br /><br />
	11. அதின் தலைவர்கள் பரிதானத்துக்கு நியாயந்தீர்க்கிறார்கள்; அதின் ஆசாரியர்கள் கூலிக்கு உபதேசிக்கிறார்கள்; அதின் தீர்க்கதரிசிகள் பணத்துக்குக் குறிசொல்லுகிறார்கள்; ஆகிலும் அவர்கள் கர்த்தரைச் சார்ந்துகொண்டு: கர்த்தர் எங்கள் நடுவில் இல்லையோ? தீங்கு எங்கள்மேல் வராது என்கிறார்கள்.
	<br /><br />
	12. ஆகையால் உங்கள்நிமித்தம் சீயோன் வயல்வெளியைப்போல உழப்பட்டு, எருசலேம் மண்மேடுகளாய்ப்போம், ஆலயத்தின் பர்வதம் காட்டு மேடுகளாய்ப்போம்.
</p>

      {/* End of verse */}  
      </div>
    </div>
    </>
  );
}

export default Micah3